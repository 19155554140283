import useHeaderFooter from "../../hooks/useHeaderFooter";
import React from "react";
import './PresentedBy.css'
import {NavLink} from "react-router-dom";

interface PresentedByProps {
    children?: React.ReactNode
}
const PresentedBy: React.FC<PresentedByProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);

    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about" />
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <img
                                        className="overlap-group-item"
                                        src="/img/PRESENTEDBY-LWC.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                <span className="recklessneue-regular-normal-white-100-1px">
                  Presented By{" "}
                </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5 pres-overlap">
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px pres-desc">
                                Learn from world-class character-based leaders from Oxford, Harvard,
                                and beyond.
                            </p>
                        </div>
                    </div>
                    <div className="tutor-list tutor-list-custom">
                        <NavLink to="/profile-guide/tadeyinka">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+106+2023+type@asset+block@290305093-e34f0068-e753-46cd-89c3-b6b82d5f6129.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Adeyinka Adewale, PhD
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/temmie">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+106+2023+type@asset+block@290305099-3ad96920-2273-4bbd-b7db-c32092a3b920.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Emmie Bidston
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tedward">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+100+2023+type@asset+block@290305097-ec9e7175-4bfe-407f-8492-40fe9c1928cf.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Edward Brooks, DPhil
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tkaty">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+104+2023+type@asset+block@290305090-dc46c72d-5b7a-4404-9ff4-6b79c563d41c.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Katy Granville-Chapman, DPhil
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tgerald">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+106+2023+type@asset+block@290305094-0283dc62-b8fa-4249-a4fe-dbce00294c57.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Gerald Chirinda
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tveronica">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+103+2023+type@asset+block@290305091-57ca89f9-8e6c-4ea2-a3a2-2b6695c8f01d.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Verónica Fernández, PhD
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tjoann">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+103+2023+type@asset+block@290305107-1729144f-8f22-4554-8ce0-800e01a66f6f.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    JoAnn Flett, PhD
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tlina">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+106+2023+type@asset+block@290305103-292d2c85-53be-4603-855b-8533d3625fa7.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Lina Tori Jan
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tmatthew">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            "url(https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+104+2023+type@asset+block@290305104-cfcaaf14-bf89-4703-8cf4-81c2f9832b1d.jpeg)"
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Matthew Lee, PhD
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tanjali">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            'url("https://learn.beta.leadingwithcharacter.com/asset-v1:Legatum+100+2023+type@asset+block@290305096-9887a801-f992-40e3-9e4d-746b21373923.jpeg")'
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Anjali Sarker
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to="/profile-guide/tluna">
                            <div className="badge-01 badge">
                                <div
                                    className="frame-30"
                                    style={{
                                        backgroundImage:
                                            'url("/img/tluna-pic.jpeg")'
                                    }}
                                ></div>
                                <div className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                    Luna Wei Wang, DPhil
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>

    )
}

export default PresentedBy