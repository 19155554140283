import {useEffect, useState} from 'react';
import {getCourseInfo} from "@iblai/ibl-web-react-common";
import {CourseData} from "./utils/interfaces";



function useCoursePreview(courseID: string | undefined) {
    const [courseInfo, setCourseInfo] = useState<CourseData | any>({});
    const [courseInfoLoaded, setCourseInfoLoaded] = useState<boolean>(false);


    useEffect(() => {
        if (courseID) {
            setCourseInfoLoaded(false);
            getCourseInfo(courseID, courseInfoCallback);
         
        }

    }, [courseID]);
    const courseInfoCallback = (data: any) => {
        setCourseInfo(data);
        setCourseInfoLoaded(true);
    };



    return {
        courseInfo ,
        courseInfoLoaded ,

    }
}

export default useCoursePreview;
