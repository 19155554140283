export const SAMPLE_USERS_LOCATION = [
    {
        country_code: "IN",
        country_name: "India",
        city: "Mumbai",
        city_gps: { lat: 19.0761, lng: 72.8775 },
    },
    {
        country_code: "JP",
        country_name: "Japan",
        city: "Tokyo",
        city_gps: { lat: 35.6897, lng: 139.6922 },
    },
    {
        country_code: "US",
        country_name: "United States",
        city: "New York",
        city_gps: { lat: 40.7128, lng: -74.006 },
    },
    {
        country_code: "CN",
        country_name: "China",
        city: "Shanghai",
        city_gps: { lat: 31.2304, lng: 121.4737 },
    },
    {
        country_code: "FR",
        country_name: "France",
        city: "Paris",
        city_gps: { lat: 48.8566, lng: 2.3522 },
    },
    {
        country_code: "BR",
        country_name: "Brazil",
        city: "São Paulo",
        city_gps: { lat: -23.5505, lng: -46.6333 },
    },
    {
        country_code: "RU",
        country_name: "Russia",
        city: "Moscow",
        city_gps: { lat: 55.7558, lng: 37.6173 },
    },
    {
        country_code: "GB",
        country_name: "United Kingdom",
        city: "London",
        city_gps: { lat: 51.5074, lng: -0.1278 },
    },
    {
        country_code: "DE",
        country_name: "Germany",
        city: "Berlin",
        city_gps: { lat: 52.52, lng: 13.405 },
    },
    {
        country_code: "AU",
        country_name: "Australia",
        city: "Sydney",
        city_gps: { lat: -33.8688, lng: 151.2093 },
    },
    {
        country_code: "CA",
        country_name: "Canada",
        city: "Toronto",
        city_gps: { lat: 43.6511, lng: -79.3839 },
    },
    {
        country_code: "IT",
        country_name: "Italy",
        city: "Rome",
        city_gps: { lat: 41.9028, lng: 12.4964 },
    },
    {
        country_code: "ES",
        country_name: "Spain",
        city: "Madrid",
        city_gps: { lat: 40.4168, lng: -3.7038 },
    },
    {
        country_code: "ZA",
        country_name: "South Africa",
        city: "Johannesburg",
        city_gps: { lat: -26.2041, lng: 28.0473 },
    },
    {
        country_code: "EG",
        country_name: "Egypt",
        city: "Cairo",
        city_gps: { lat: 30.0444, lng: 31.2357 },
    },
    {
        country_code: "TR",
        country_name: "Turkey",
        city: "Istanbul",
        city_gps: { lat: 41.0082, lng: 28.9784 },
    },
    {
        country_code: "MX",
        country_name: "Mexico",
        city: "Mexico City",
        city_gps: { lat: 19.4326, lng: -99.1332 },
    },
    {
        country_code: "KR",
        country_name: "South Korea",
        city: "Seoul",
        city_gps: { lat: 37.5665, lng: 126.978 },
    },
    {
        country_code: "ID",
        country_name: "Indonesia",
        city: "Jakarta",
        city_gps: { lat: -6.2088, lng: 106.8456 },
    },
    {
        country_code: "AR",
        country_name: "Argentina",
        city: "Buenos Aires",
        city_gps: { lat: -34.6037, lng: -58.3816 },
    },
];


export interface City {
    lat: number;
    lng: number;
}

export interface Country {
    name: string;
    cities: Record<string, City>;
}

export interface CountryListItem {
    label: string;
    value: string;
}

export interface CityListItem {
    label: string;
    value: string;
}

export function getCountriesList(): CountryListItem[] {
    const countriesList: CountryListItem[] = [];

    for (const countryCode in LOCATION_DATA) {
        if (LOCATION_DATA.hasOwnProperty(countryCode)) {
            countriesList.push({
                label: LOCATION_DATA[countryCode].name,
                value: countryCode
            });
        }
    }

    // Sort the countries list alphabetically by the label (country name)

    countriesList.sort((a, b) => a.label.localeCompare(b.label));

    return countriesList;
}

export function getCityNamesByCountryCode(
    countryCode: string
): CityListItem[] {
    const result: CityListItem[] = [];

    // Check if the country code exists in the data
    if (LOCATION_DATA.hasOwnProperty(countryCode)) {
        const cities = LOCATION_DATA[countryCode].cities;

        for (const cityName in cities) {
            if (cities.hasOwnProperty(cityName)) {
                result.push({
                    label: cityName,
                    value: cityName
                });
            }
        }
    }

    // Sort the result alphabetically by the label (city name)
    result.sort((a, b) => a.label.localeCompare(b.label));

    return result;
}

export function getCityLatLng(
    countryCode: string,
    cityName: string
): { lat: number; lng: number } | null {
    // Check if the country code exists in the data
    if (LOCATION_DATA.hasOwnProperty(countryCode)) {
        const country = LOCATION_DATA[countryCode];

        // Check if the city exists in the country's data
        if (country.cities.hasOwnProperty(cityName)) {
            return country.cities[cityName];
        }
    }

    // Return null if the country code or city name is not found
    return null;
}

export const LOCATION_DATA = {
    "JP": {
        "name": "Japan",
        "cities": {
            "Tokyo": {
                "lat": 35.6897,
                "lng": 139.6922
            },
            "\u014csaka": {
                "lat": 34.6939,
                "lng": 135.5022
            },
            "Nagoya": {
                "lat": 35.1833,
                "lng": 136.9
            },
            "Yokohama": {
                "lat": 35.4442,
                "lng": 139.6381
            },
            "Fukuoka": {
                "lat": 33.59,
                "lng": 130.4017
            },
            "Sapporo": {
                "lat": 43.0619,
                "lng": 141.3544
            },
            "Kawasaki": {
                "lat": 33.6,
                "lng": 130.815
            },
            "K\u014dbe": {
                "lat": 34.69,
                "lng": 135.1956
            },
            "Ky\u014dto": {
                "lat": 35.0117,
                "lng": 135.7683
            },
            "Saitama": {
                "lat": 35.8614,
                "lng": 139.6456
            },
            "Hiroshima": {
                "lat": 34.3914,
                "lng": 132.4519
            },
            "Sendai": {
                "lat": 38.2682,
                "lng": 140.8694
            },
            "Chiba": {
                "lat": 35.6073,
                "lng": 140.1064
            },
            "Setagaya": {
                "lat": 35.6466,
                "lng": 139.6532
            },
            "Kitaky\u016bsh\u016b": {
                "lat": 33.8833,
                "lng": 130.8833
            },
            "Sakai": {
                "lat": 36.1085,
                "lng": 139.7949
            },
            "Niigata": {
                "lat": 37.9161,
                "lng": 139.0364
            },
            "Hamamatsu": {
                "lat": 34.7108,
                "lng": 137.7275
            },
            "Nerima": {
                "lat": 35.7356,
                "lng": 139.6517
            },
            "\u014cta-ku": {
                "lat": 35.5614,
                "lng": 139.7161
            },
            "Kumamoto": {
                "lat": 32.8031,
                "lng": 130.7078
            },
            "Sagamihara": {
                "lat": 35.5667,
                "lng": 139.3667
            },
            "Okayama": {
                "lat": 34.65,
                "lng": 133.9167
            },
            "Edogawa": {
                "lat": 35.7,
                "lng": 139.8833
            },
            "Shizuoka": {
                "lat": 34.9756,
                "lng": 138.3828
            },
            "Adachi": {
                "lat": 35.7833,
                "lng": 139.8
            },
            "Honch\u014d": {
                "lat": 41.9,
                "lng": 140.7
            },
            "Kawaguchi": {
                "lat": 35.8077,
                "lng": 139.7241
            },
            "Kagoshima": {
                "lat": 31.6,
                "lng": 130.55
            },
            "Itabashi": {
                "lat": 35.7667,
                "lng": 139.6833
            },
            "Suginami-ku": {
                "lat": 35.6994,
                "lng": 139.6364
            },
            "Hachi\u014dji": {
                "lat": 35.6664,
                "lng": 139.316
            },
            "Himeji": {
                "lat": 34.8167,
                "lng": 134.6833
            },
            "K\u014dt\u014d-ku": {
                "lat": 35.6667,
                "lng": 139.8167
            },
            "Utsunomiya": {
                "lat": 36.5551,
                "lng": 139.8826
            },
            "Matsuyama": {
                "lat": 33.8333,
                "lng": 132.7667
            },
            "Matsudo": {
                "lat": 35.7876,
                "lng": 139.9032
            },
            "Ichikawa": {
                "lat": 34.9833,
                "lng": 134.7667
            },
            "Higashi-\u014dsaka": {
                "lat": 34.6794,
                "lng": 135.6008
            },
            "Nishinomiya-hama": {
                "lat": 34.7376,
                "lng": 135.3416
            },
            "Kawachich\u014d": {
                "lat": 34.6794,
                "lng": 135.6008
            },
            "\u014cita": {
                "lat": 33.2333,
                "lng": 131.6067
            },
            "Kurashiki": {
                "lat": 34.585,
                "lng": 133.7722
            },
            "Kanazawa": {
                "lat": 36.5611,
                "lng": 136.6564
            },
            "Fukuyama": {
                "lat": 34.4858,
                "lng": 133.3622
            },
            "Amagasaki": {
                "lat": 34.7333,
                "lng": 135.4
            },
            "Katsushika-ku": {
                "lat": 35.7333,
                "lng": 139.85
            },
            "Fujisawa": {
                "lat": 35.35,
                "lng": 139.4667
            },
            "Machida": {
                "lat": 35.5542,
                "lng": 139.4431
            },
            "Kashiwa": {
                "lat": 35.8676,
                "lng": 139.9758
            },
            "Aomori": {
                "lat": 40.8228,
                "lng": 140.7469
            },
            "Toyota": {
                "lat": 35.0824,
                "lng": 137.1563
            },
            "Takamatsu": {
                "lat": 34.35,
                "lng": 134.05
            },
            "Shinagawa-ku": {
                "lat": 35.6,
                "lng": 139.7333
            },
            "Toyama": {
                "lat": 36.6959,
                "lng": 137.2137
            },
            "Nagasaki": {
                "lat": 32.7447,
                "lng": 129.8736
            },
            "Toyonaka": {
                "lat": 34.7833,
                "lng": 135.4667
            },
            "Gifu": {
                "lat": 35.4232,
                "lng": 136.7608
            },
            "Miyazaki": {
                "lat": 31.9078,
                "lng": 131.4203
            },
            "Hirakata": {
                "lat": 34.8167,
                "lng": 135.65
            },
            "Yokosuka": {
                "lat": 35.2813,
                "lng": 139.6721
            },
            "Okazaki": {
                "lat": 34.9543,
                "lng": 137.1744
            },
            "Minamisuita": {
                "lat": 34.7594,
                "lng": 135.5169
            },
            "Ichinomiya": {
                "lat": 35.3667,
                "lng": 140.3667
            },
            "Takasaki": {
                "lat": 36.3219,
                "lng": 139.0033
            },
            "Toyohashi": {
                "lat": 34.7692,
                "lng": 137.3915
            },
            "Nagano": {
                "lat": 36.6486,
                "lng": 138.1947
            },
            "Kawagoe": {
                "lat": 35.0167,
                "lng": 136.6667
            },
            "Wakayama": {
                "lat": 34.2333,
                "lng": 135.1667
            },
            "Kita-ku": {
                "lat": 35.75,
                "lng": 139.7333
            },
            "Nara": {
                "lat": 34.6844,
                "lng": 135.805
            },
            "Shinjuku": {
                "lat": 35.7014,
                "lng": 139.7097
            },
            "Takatsuki": {
                "lat": 34.8461,
                "lng": 135.6175
            },
            "Nakano": {
                "lat": 36.742,
                "lng": 138.3694
            },
            "\u014ctsu": {
                "lat": 35.0167,
                "lng": 135.85
            },
            "Koshigaya": {
                "lat": 35.8911,
                "lng": 139.7909
            },
            "Tokorozawa": {
                "lat": 35.7996,
                "lng": 139.4686
            },
            "Iwaki": {
                "lat": 37.0505,
                "lng": 140.8877
            },
            "Maebashi": {
                "lat": 36.3895,
                "lng": 139.0634
            },
            "Asahikawa": {
                "lat": 43.7667,
                "lng": 142.3667
            },
            "Koriyama": {
                "lat": 37.4004,
                "lng": 140.3597
            },
            "K\u014dchi": {
                "lat": 33.5589,
                "lng": 133.5314
            },
            "Naha": {
                "lat": 26.2122,
                "lng": 127.6792
            },
            "Yokkaichi": {
                "lat": 34.965,
                "lng": 136.6244
            },
            "Kasugai": {
                "lat": 35.2475,
                "lng": 136.9722
            },
            "Akita": {
                "lat": 39.72,
                "lng": 140.1026
            },
            "Kurume": {
                "lat": 33.3192,
                "lng": 130.5083
            },
            "\u014cakashich\u014d": {
                "lat": 34.65,
                "lng": 135.0
            },
            "Toshima": {
                "lat": 35.7333,
                "lng": 139.7167
            },
            "Morioka": {
                "lat": 39.7021,
                "lng": 141.1545
            },
            "Meguro": {
                "lat": 35.6333,
                "lng": 139.6833
            },
            "Fukushima": {
                "lat": 37.7608,
                "lng": 140.4747
            },
            "Ibaraki": {
                "lat": 36.2869,
                "lng": 140.4245
            },
            "Tsu": {
                "lat": 34.7184,
                "lng": 136.5057
            },
            "Sumida": {
                "lat": 35.7,
                "lng": 139.8167
            },
            "Mito": {
                "lat": 36.3658,
                "lng": 140.4712
            },
            "Ichihara": {
                "lat": 35.4981,
                "lng": 140.1154
            },
            "Nagaoka": {
                "lat": 34.9269,
                "lng": 135.6958
            },
            "Yao": {
                "lat": 34.6269,
                "lng": 135.601
            },
            "Fukui": {
                "lat": 36.0641,
                "lng": 136.2196
            },
            "Fuch\u016b": {
                "lat": 34.3925,
                "lng": 132.5044
            },
            "Minato": {
                "lat": 35.6581,
                "lng": 139.7514
            },
            "Kakogawach\u014d-honmachi": {
                "lat": 34.75,
                "lng": 134.8333
            },
            "Hiratsuka": {
                "lat": 35.3167,
                "lng": 139.35
            },
            "Tokushima": {
                "lat": 34.0667,
                "lng": 134.55
            },
            "Shinozaki": {
                "lat": 33.9578,
                "lng": 130.9414
            },
            "Hakodate": {
                "lat": 41.7686,
                "lng": 140.7289
            },
            "S\u014dka": {
                "lat": 35.8254,
                "lng": 139.8053
            },
            "Yamagata": {
                "lat": 35.5061,
                "lng": 136.7811
            },
            "Tsukuba-kenky\u016bgakuen-toshi": {
                "lat": 36.0835,
                "lng": 140.0764
            },
            "Fuji": {
                "lat": 35.1613,
                "lng": 138.6763
            },
            "Yoshiich\u014d-shimobaru": {
                "lat": 33.18,
                "lng": 129.715
            },
            "Sasebo": {
                "lat": 33.18,
                "lng": 129.715
            },
            "Chigasaki": {
                "lat": 35.3333,
                "lng": 139.4
            },
            "Bunky\u014d-ku": {
                "lat": 35.7167,
                "lng": 139.75
            },
            "Yato": {
                "lat": 35.4833,
                "lng": 139.45
            },
            "Matsumoto": {
                "lat": 36.238,
                "lng": 137.972
            },
            "Ch\u014dfugaoka": {
                "lat": 35.6506,
                "lng": 139.5407
            },
            "Shibuya-ku": {
                "lat": 35.6594,
                "lng": 139.7006
            },
            "Saga": {
                "lat": 33.2667,
                "lng": 130.3
            },
            "Kasukabe": {
                "lat": 35.9753,
                "lng": 139.7523
            },
            "Neya": {
                "lat": 34.7667,
                "lng": 135.6333
            },
            "Ageoshimo": {
                "lat": 35.9774,
                "lng": 139.5932
            },
            "\u014cta": {
                "lat": 36.2911,
                "lng": 139.3754
            },
            "Takarazuka": {
                "lat": 34.8114,
                "lng": 135.3406
            },
            "Atsugich\u014d": {
                "lat": 35.4333,
                "lng": 139.3667
            },
            "Hachinohe": {
                "lat": 40.5123,
                "lng": 141.4884
            },
            "Arakawa": {
                "lat": 38.1241,
                "lng": 139.4438
            },
            "Isesaki": {
                "lat": 36.3114,
                "lng": 139.1968
            },
            "Kure": {
                "lat": 34.2492,
                "lng": 132.5658
            },
            "Tait\u014d": {
                "lat": 35.7125,
                "lng": 139.78
            },
            "Nishit\u014dky\u014d": {
                "lat": 35.7256,
                "lng": 139.5383
            },
            "Matsue": {
                "lat": 35.4681,
                "lng": 133.0486
            },
            "Yachiyo": {
                "lat": 36.1816,
                "lng": 139.8911
            },
            "Nagareyama": {
                "lat": 35.8563,
                "lng": 139.9029
            },
            "Higashi-Hiroshima": {
                "lat": 34.4264,
                "lng": 132.7433
            },
            "Itami": {
                "lat": 34.7833,
                "lng": 135.4
            },
            "Kodaira": {
                "lat": 35.7285,
                "lng": 139.4774
            },
            "Suzuka": {
                "lat": 34.882,
                "lng": 136.5842
            },
            "Kamirenjaku": {
                "lat": 35.6836,
                "lng": 139.5595
            },
            "Kumagaya": {
                "lat": 36.1474,
                "lng": 139.3886
            },
            "Yamaguchi": {
                "lat": 33.1833,
                "lng": 129.95
            },
            "Hino": {
                "lat": 35.0167,
                "lng": 136.25
            },
            "Odawara": {
                "lat": 35.25,
                "lng": 139.15
            },
            "Anj\u014dmachi": {
                "lat": 34.9587,
                "lng": 137.0803
            },
            "Kishiwada": {
                "lat": 34.4667,
                "lng": 135.3667
            },
            "Numazu": {
                "lat": 35.0956,
                "lng": 138.8634
            },
            "Tottori": {
                "lat": 35.5,
                "lng": 134.2333
            },
            "J\u014detsu": {
                "lat": 37.1479,
                "lng": 138.2361
            },
            "K\u014dfu": {
                "lat": 35.6621,
                "lng": 138.5682
            },
            "Toyokawa": {
                "lat": 34.8268,
                "lng": 137.3759
            },
            "Tachikawa": {
                "lat": 35.6942,
                "lng": 139.4197
            },
            "Uji": {
                "lat": 34.8844,
                "lng": 135.7997
            },
            "Narashino": {
                "lat": 35.6804,
                "lng": 140.0265
            },
            "Sakura": {
                "lat": 36.6853,
                "lng": 139.9664
            },
            "Kamakurayama": {
                "lat": 35.3197,
                "lng": 139.5525
            },
            "Hitachi": {
                "lat": 36.5991,
                "lng": 140.6515
            },
            "Izumo": {
                "lat": 35.3669,
                "lng": 132.7547
            },
            "Tomakomai": {
                "lat": 42.6333,
                "lng": 141.6
            },
            "Urayasu": {
                "lat": 35.6539,
                "lng": 139.9022
            },
            "Ch\u016b\u014d-ku": {
                "lat": 35.6667,
                "lng": 139.7667
            },
            "Nishio": {
                "lat": 34.83,
                "lng": 137.0697
            },
            "Hirosaki": {
                "lat": 40.6031,
                "lng": 140.4638
            },
            "Oyama": {
                "lat": 35.3601,
                "lng": 138.9873
            },
            "Niiza": {
                "lat": 35.7935,
                "lng": 139.5653
            },
            "Takaoka": {
                "lat": 36.7541,
                "lng": 137.0257
            },
            "Kushiro": {
                "lat": 42.98,
                "lng": 144.38
            },
            "Iwata": {
                "lat": 34.7179,
                "lng": 137.8515
            },
            "Obihiro": {
                "lat": 42.9167,
                "lng": 143.2
            },
            "Hadano": {
                "lat": 35.3667,
                "lng": 139.2167
            },
            "Ube": {
                "lat": 33.9517,
                "lng": 131.2467
            },
            "Miyakonoj\u014d": {
                "lat": 31.7194,
                "lng": 131.0617
            },
            "Matsuzaka": {
                "lat": 34.5779,
                "lng": 136.5276
            },
            "\u014cgaki": {
                "lat": 35.3594,
                "lng": 136.6129
            },
            "Daiwanishi": {
                "lat": 34.8892,
                "lng": 135.4167
            },
            "Hitachi-Naka": {
                "lat": 36.3967,
                "lng": 140.5347
            },
            "Noda": {
                "lat": 35.95,
                "lng": 139.8667
            },
            "Tochigi": {
                "lat": 36.3813,
                "lng": 139.7303
            },
            "Kariya": {
                "lat": 34.7517,
                "lng": 134.3931
            },
            "Ueda": {
                "lat": 36.4019,
                "lng": 138.2491
            },
            "Imabari": {
                "lat": 34.0667,
                "lng": 133.0
            },
            "Kawashiri": {
                "lat": 34.8333,
                "lng": 135.4167
            },
            "Higashimurayama": {
                "lat": 35.7546,
                "lng": 139.4685
            },
            "Kukich\u016b\u014d": {
                "lat": 36.0621,
                "lng": 139.6668
            },
            "Musashino": {
                "lat": 35.7177,
                "lng": 139.5661
            },
            "Sayama": {
                "lat": 35.853,
                "lng": 139.4122
            },
            "Komaki": {
                "lat": 35.291,
                "lng": 136.9121
            },
            "Tama": {
                "lat": 34.4886,
                "lng": 133.9486
            },
            "Yonago": {
                "lat": 35.4333,
                "lng": 133.3333
            },
            "Iruma": {
                "lat": 35.8358,
                "lng": 139.3911
            },
            "Fujita": {
                "lat": 34.8674,
                "lng": 138.2577
            },
            "Asaka": {
                "lat": 35.7972,
                "lng": 139.5939
            },
            "Kakamigahara": {
                "lat": 35.3988,
                "lng": 136.8484
            },
            "Ashikaga": {
                "lat": 36.3402,
                "lng": 139.4497
            },
            "Toda": {
                "lat": 35.8176,
                "lng": 139.6779
            },
            "Okinawa": {
                "lat": 26.3342,
                "lng": 127.8056
            },
            "Misato": {
                "lat": 39.4211,
                "lng": 140.5439
            },
            "Moriguchi": {
                "lat": 34.7375,
                "lng": 135.5642
            },
            "Fukayach\u014d": {
                "lat": 36.1975,
                "lng": 139.2815
            },
            "Ishizaki": {
                "lat": 38.4176,
                "lng": 141.3027
            },
            "Kuwana": {
                "lat": 35.0667,
                "lng": 136.6833
            },
            "Koga": {
                "lat": 33.7333,
                "lng": 130.4667
            },
            "Tsuchiura": {
                "lat": 36.0667,
                "lng": 140.2
            },
            "Sh\u016bnan": {
                "lat": 34.055,
                "lng": 131.8061
            },
            "Kusatsu": {
                "lat": 35.0167,
                "lng": 135.9667
            },
            "Min\u014d": {
                "lat": 34.8269,
                "lng": 135.4706
            },
            "Yaizu": {
                "lat": 34.8669,
                "lng": 138.3247
            },
            "Kisarazu": {
                "lat": 35.376,
                "lng": 139.9168
            },
            "Ebina": {
                "lat": 35.4464,
                "lng": 139.3908
            },
            "Inazawa": {
                "lat": 35.2647,
                "lng": 136.7969
            },
            "Minoo": {
                "lat": 34.8333,
                "lng": 135.4667
            },
            "Isahaya": {
                "lat": 32.85,
                "lng": 130.0667
            },
            "\u014cme": {
                "lat": 35.788,
                "lng": 139.2758
            },
            "Zama": {
                "lat": 35.4833,
                "lng": 139.4
            },
            "Narita": {
                "lat": 35.7767,
                "lng": 140.3183
            },
            "Abiko": {
                "lat": 35.8667,
                "lng": 140.0333
            },
            "Onomichi": {
                "lat": 34.4167,
                "lng": 133.2
            },
            "Kokubunji": {
                "lat": 35.7109,
                "lng": 139.4622
            },
            "\u014csaki": {
                "lat": 38.5771,
                "lng": 140.9556
            },
            "Iwakuni": {
                "lat": 34.1664,
                "lng": 132.2189
            },
            "Seto": {
                "lat": 35.2236,
                "lng": 137.0842
            },
            "\u014cmiyach\u014d": {
                "lat": 35.2221,
                "lng": 138.6216
            },
            "Koganei": {
                "lat": 35.6995,
                "lng": 139.503
            },
            "Iizuka": {
                "lat": 33.65,
                "lng": 130.6833
            },
            "Kirishima": {
                "lat": 31.7406,
                "lng": 130.7631
            },
            "Honmachi": {
                "lat": 43.9167,
                "lng": 144.6667
            },
            "Ise": {
                "lat": 34.4833,
                "lng": 136.7167
            },
            "Uruma": {
                "lat": 26.3792,
                "lng": 127.8575
            },
            "Kashiwara": {
                "lat": 34.5833,
                "lng": 135.6333
            },
            "Tsuruoka": {
                "lat": 38.7272,
                "lng": 139.8267
            },
            "Ebetsu": {
                "lat": 43.1,
                "lng": 141.5333
            },
            "Dait\u014dch\u014d": {
                "lat": 34.7167,
                "lng": 135.6167
            },
            "Kadoma": {
                "lat": 34.7333,
                "lng": 135.5833
            },
            "Aizuwakamatsu": {
                "lat": 37.4948,
                "lng": 139.9298
            },
            "Matsubara": {
                "lat": 34.5833,
                "lng": 135.55
            },
            "Nobeoka": {
                "lat": 32.5822,
                "lng": 131.665
            },
            "Handa": {
                "lat": 34.8919,
                "lng": 136.9381
            },
            "K\u014dnosu": {
                "lat": 36.0659,
                "lng": 139.5222
            },
            "Ikoma": {
                "lat": 34.7,
                "lng": 135.7
            },
            "Karatsu": {
                "lat": 33.45,
                "lng": 129.9667
            },
            "Nagahama": {
                "lat": 35.3833,
                "lng": 136.2833
            },
            "Beppu": {
                "lat": 33.2847,
                "lng": 131.4914
            },
            "Urasoe": {
                "lat": 26.2458,
                "lng": 127.7219
            },
            "Nasushiobara": {
                "lat": 36.9617,
                "lng": 140.0461
            },
            "K\u014dench\u014d": {
                "lat": 43.8031,
                "lng": 143.8947
            },
            "Niihama": {
                "lat": 33.9667,
                "lng": 133.2833
            },
            "Sano": {
                "lat": 36.3145,
                "lng": 139.5783
            },
            "Hatsukaichi": {
                "lat": 34.3483,
                "lng": 132.3317
            },
            "Kakegawa": {
                "lat": 34.7687,
                "lng": 137.9984
            },
            "Fujimino": {
                "lat": 35.8795,
                "lng": 139.5198
            },
            "Hikone": {
                "lat": 35.2667,
                "lng": 136.2667
            },
            "H\u014dfu": {
                "lat": 34.0514,
                "lng": 131.5625
            },
            "T\u014dkai": {
                "lat": 36.473,
                "lng": 140.5661
            },
            "Kazo": {
                "lat": 36.1314,
                "lng": 139.6018
            },
            "\u014csh\u016b": {
                "lat": 39.1445,
                "lng": 141.1391
            },
            "Higashi\u014dmi": {
                "lat": 35.1167,
                "lng": 136.2
            },
            "Otaru": {
                "lat": 43.1833,
                "lng": 141.0
            },
            "Akishima": {
                "lat": 35.7057,
                "lng": 139.3535
            },
            "Fujimi": {
                "lat": 35.9146,
                "lng": 138.2407
            },
            "Ichinoseki": {
                "lat": 38.9347,
                "lng": 141.1266
            },
            "Kasuga": {
                "lat": 33.5333,
                "lng": 130.4667
            },
            "Shirayamamachi": {
                "lat": 36.5166,
                "lng": 136.5656
            },
            "\u014cmuta": {
                "lat": 33.0303,
                "lng": 130.4458
            },
            "Kamagaya": {
                "lat": 35.7768,
                "lng": 140.0008
            },
            "Sandach\u014d": {
                "lat": 34.8833,
                "lng": 135.2333
            },
            "Marugame": {
                "lat": 34.2833,
                "lng": 133.8
            },
            "Tondabayashich\u014d": {
                "lat": 34.5,
                "lng": 135.6
            },
            "Komatsu": {
                "lat": 36.4083,
                "lng": 136.4455
            },
            "Habikino": {
                "lat": 34.55,
                "lng": 135.6
            },
            "Mineshita": {
                "lat": 35.1185,
                "lng": 138.9186
            },
            "Tajimi": {
                "lat": 35.3328,
                "lng": 137.1316
            },
            "Kiry\u016b": {
                "lat": 36.4052,
                "lng": 139.4973
            },
            "Kanoya": {
                "lat": 31.3831,
                "lng": 130.8519
            },
            "Ikeda": {
                "lat": 36.4212,
                "lng": 137.8746
            },
            "Toride": {
                "lat": 35.9115,
                "lng": 140.0504
            },
            "Saij\u014d": {
                "lat": 33.9167,
                "lng": 133.1833
            },
            "Chikushino": {
                "lat": 33.5,
                "lng": 130.5167
            },
            "Inzai": {
                "lat": 35.8333,
                "lng": 140.15
            },
            "H\u014dyach\u014d": {
                "lat": 35.7483,
                "lng": 139.5675
            },
            "Isehara": {
                "lat": 35.3833,
                "lng": 139.3
            },
            "Shibuya": {
                "lat": 35.6536,
                "lng": 139.7092
            },
            "\u014cnoj\u014d": {
                "lat": 33.5333,
                "lng": 130.4833
            },
            "Sakado": {
                "lat": 35.9573,
                "lng": 139.403
            },
            "Kawachinagano": {
                "lat": 34.4667,
                "lng": 135.5667
            },
            "Kani": {
                "lat": 35.4261,
                "lng": 137.0613
            },
            "Izumisano": {
                "lat": 34.4,
                "lng": 135.3333
            },
            "Ginowan": {
                "lat": 26.2817,
                "lng": 127.7783
            },
            "Sakata": {
                "lat": 38.9145,
                "lng": 139.8364
            },
            "Itoshima": {
                "lat": 33.5539,
                "lng": 130.1978
            },
            "Chikusei": {
                "lat": 36.3071,
                "lng": 139.9831
            },
            "Saku": {
                "lat": 36.2488,
                "lng": 138.4769
            },
            "Chitose": {
                "lat": 42.8167,
                "lng": 141.65
            },
            "Tsuyama": {
                "lat": 35.0692,
                "lng": 134.0044
            },
            "K\u014dnan": {
                "lat": 33.5667,
                "lng": 133.7
            },
            "Munakata": {
                "lat": 33.8,
                "lng": 130.5333
            },
            "Iida": {
                "lat": 35.515,
                "lng": 137.8214
            },
            "\u014cmura": {
                "lat": 32.9,
                "lng": 129.9583
            },
            "Kamisu": {
                "lat": 35.8899,
                "lng": 140.6645
            },
            "Shimada": {
                "lat": 40.599,
                "lng": 141.3976
            },
            "Kanuma": {
                "lat": 36.5671,
                "lng": 139.7451
            },
            "Shibata": {
                "lat": 38.0566,
                "lng": 140.7658
            },
            "Ashiya": {
                "lat": 33.9,
                "lng": 130.6667
            },
            "Azumino": {
                "lat": 36.3039,
                "lng": 137.9058
            },
            "Sanj\u014d": {
                "lat": 37.6368,
                "lng": 138.9617
            },
            "Yashio": {
                "lat": 35.8225,
                "lng": 139.8392
            },
            "Yotsukaid\u014d": {
                "lat": 35.6698,
                "lng": 140.1679
            },
            "Nisshin": {
                "lat": 35.132,
                "lng": 137.0394
            },
            "Hanamaki Onsen": {
                "lat": 39.3886,
                "lng": 141.1169
            },
            "Inagi": {
                "lat": 35.6379,
                "lng": 139.5046
            },
            "Kitakami": {
                "lat": 39.2867,
                "lng": 141.1132
            },
            "\u014cbu": {
                "lat": 35.0117,
                "lng": 136.9637
            },
            "Satsumasendai": {
                "lat": 31.8167,
                "lng": 130.3
            },
            "Higashi-Matsuyama": {
                "lat": 36.0422,
                "lng": 139.3999
            },
            "Yoshiwara": {
                "lat": 35.1633,
                "lng": 138.6866
            },
            "Imizuch\u014d": {
                "lat": 36.7306,
                "lng": 137.0756
            },
            "Mihara": {
                "lat": 34.3975,
                "lng": 133.0786
            },
            "K\u014dka": {
                "lat": 34.9667,
                "lng": 136.1667
            },
            "Mobara": {
                "lat": 35.4285,
                "lng": 140.2881
            },
            "Ama": {
                "lat": 35.2004,
                "lng": 136.7832
            },
            "Takasagoch\u014d-takasemachi": {
                "lat": 34.7667,
                "lng": 134.7833
            },
            "Fukuroi": {
                "lat": 34.7502,
                "lng": 137.9247
            },
            "Hamakita": {
                "lat": 34.7931,
                "lng": 137.79
            },
            "Gotenba": {
                "lat": 35.3087,
                "lng": 138.9346
            },
            "Settsu": {
                "lat": 34.7772,
                "lng": 135.5622
            },
            "Kitanagoya": {
                "lat": 35.2456,
                "lng": 136.8659
            },
            "Kameoka": {
                "lat": 35.0167,
                "lng": 135.5667
            },
            "Iga": {
                "lat": 34.7667,
                "lng": 136.1333
            },
            "Sekimachi": {
                "lat": 33.05,
                "lng": 130.5333
            },
            "Takayama": {
                "lat": 36.146,
                "lng": 137.2522
            },
            "Yokotemachi": {
                "lat": 39.3113,
                "lng": 140.5533
            },
            "Ushiku": {
                "lat": 35.9794,
                "lng": 140.1496
            },
            "Komae": {
                "lat": 35.6348,
                "lng": 139.5787
            },
            "Kaizuka": {
                "lat": 34.4333,
                "lng": 135.3667
            },
            "Higashiyamato": {
                "lat": 35.7454,
                "lng": 139.4265
            },
            "Ayase": {
                "lat": 35.4333,
                "lng": 139.4333
            },
            "Wak\u014d": {
                "lat": 35.7812,
                "lng": 139.6057
            },
            "Kitak\u014driyamach\u014d": {
                "lat": 34.65,
                "lng": 135.7833
            },
            "Chita": {
                "lat": 35.0,
                "lng": 136.8667
            },
            "Moriyama": {
                "lat": 35.0586,
                "lng": 135.9942
            },
            "Nakatsu": {
                "lat": 33.5983,
                "lng": 131.1883
            },
            "Owariasahi": {
                "lat": 35.2165,
                "lng": 137.0354
            },
            "Shikokuch\u016b\u014d": {
                "lat": 33.9833,
                "lng": 133.55
            },
            "\u014cmihachiman": {
                "lat": 35.1283,
                "lng": 136.0981
            },
            "Muroran": {
                "lat": 42.3167,
                "lng": 140.9667
            },
            "Kimitsu": {
                "lat": 35.3304,
                "lng": 139.9027
            },
            "Kashiwazaki": {
                "lat": 37.3719,
                "lng": 138.559
            },
            "Yonezawa": {
                "lat": 37.9222,
                "lng": 140.1168
            },
            "Echizen": {
                "lat": 35.9742,
                "lng": 136.1298
            },
            "Gamag\u014dri": {
                "lat": 34.8431,
                "lng": 137.2196
            },
            "Akiruno": {
                "lat": 35.7289,
                "lng": 139.2941
            },
            "Iwamizawa": {
                "lat": 43.2,
                "lng": 141.7833
            },
            "Natori-shi": {
                "lat": 38.1715,
                "lng": 140.8918
            },
            "Hann\u014d": {
                "lat": 35.8557,
                "lng": 139.3277
            },
            "Nakatsugawa": {
                "lat": 35.4876,
                "lng": 137.5006
            },
            "Maizuru": {
                "lat": 35.4667,
                "lng": 135.3833
            },
            "Mooka": {
                "lat": 36.4404,
                "lng": 140.0134
            },
            "Gy\u014dda": {
                "lat": 36.1389,
                "lng": 139.4556
            },
            "Kashiba": {
                "lat": 34.5333,
                "lng": 135.7
            },
            "Tanashich\u014d": {
                "lat": 35.7275,
                "lng": 139.5489
            },
            "Kizugawa": {
                "lat": 34.7372,
                "lng": 135.82
            },
            "Honj\u014d": {
                "lat": 36.2436,
                "lng": 139.1904
            },
            "Toyomamachi-teraike": {
                "lat": 38.6918,
                "lng": 141.1877
            },
            "Katano": {
                "lat": 34.7833,
                "lng": 135.6833
            },
            "Tsubame": {
                "lat": 37.6731,
                "lng": 138.8822
            },
            "Fukuchiyama": {
                "lat": 35.3,
                "lng": 135.1333
            },
            "Nikk\u014d": {
                "lat": 36.7198,
                "lng": 139.6982
            },
            "Nabari": {
                "lat": 34.6276,
                "lng": 136.1084
            },
            "Toyooka": {
                "lat": 35.55,
                "lng": 134.8167
            },
            "Aira": {
                "lat": 31.7283,
                "lng": 130.6278
            },
            "Shinkai": {
                "lat": 35.8367,
                "lng": 139.5803
            },
            "Daisen": {
                "lat": 35.5,
                "lng": 133.5
            },
            "Ry\u016bgasaki": {
                "lat": 35.9116,
                "lng": 140.1823
            },
            "Kiyose": {
                "lat": 35.7857,
                "lng": 139.5265
            },
            "Kai": {
                "lat": 35.6608,
                "lng": 138.5158
            },
            "Kunitachi": {
                "lat": 35.6839,
                "lng": 139.4414
            },
            "Warabi": {
                "lat": 35.8256,
                "lng": 139.6797
            },
            "Amakusa": {
                "lat": 32.4667,
                "lng": 130.2
            },
            "Sasagawa": {
                "lat": 37.2865,
                "lng": 140.3727
            },
            "Tosu": {
                "lat": 33.3833,
                "lng": 130.5
            },
            "Katori": {
                "lat": 35.6833,
                "lng": 140.0333
            },
            "Kandori": {
                "lat": 35.6796,
                "lng": 139.9062
            },
            "Miki": {
                "lat": 34.2667,
                "lng": 134.1333
            },
            "Izumi\u014dtsu": {
                "lat": 34.5,
                "lng": 135.4
            },
            "Okegawa": {
                "lat": 36.0057,
                "lng": 139.5427
            },
            "Tatebayashi": {
                "lat": 36.2448,
                "lng": 139.5421
            },
            "Ky\u014dtanabe": {
                "lat": 34.8144,
                "lng": 135.7678
            },
            "Tatsunoch\u014d-tominaga": {
                "lat": 34.8508,
                "lng": 134.5453
            },
            "Yurihonj\u014d": {
                "lat": 39.3859,
                "lng": 140.0488
            },
            "Kasama": {
                "lat": 36.3452,
                "lng": 140.3043
            },
            "Inuyama": {
                "lat": 35.3786,
                "lng": 136.9445
            },
            "\u014ctawara": {
                "lat": 36.8711,
                "lng": 140.0156
            },
            "Shibukawa": {
                "lat": 36.3894,
                "lng": 139.0633
            },
            "J\u014dy\u014d": {
                "lat": 34.8531,
                "lng": 135.78
            },
            "Yukuhashi": {
                "lat": 33.7289,
                "lng": 130.9831
            },
            "Hekinan": {
                "lat": 34.8847,
                "lng": 136.9934
            },
            "Dazaifu": {
                "lat": 33.5167,
                "lng": 130.5167
            },
            "Yoshikawa": {
                "lat": 35.8939,
                "lng": 139.8414
            },
            "Chiry\u016b": {
                "lat": 35.0014,
                "lng": 137.0506
            },
            "Ishioka": {
                "lat": 36.1908,
                "lng": 140.2872
            },
            "Musashimurayama": {
                "lat": 35.7548,
                "lng": 139.3874
            },
            "Tamana": {
                "lat": 32.9281,
                "lng": 130.5594
            },
            "Yawata-shimizui": {
                "lat": 34.8756,
                "lng": 135.7075
            },
            "Eniwa": {
                "lat": 42.8833,
                "lng": 141.5833
            },
            "Tsurugashima": {
                "lat": 35.9345,
                "lng": 139.3931
            },
            "Kiyosu": {
                "lat": 35.1998,
                "lng": 136.8529
            },
            "Minami-Alps": {
                "lat": 35.6083,
                "lng": 138.465
            },
            "Uwajima": {
                "lat": 33.2167,
                "lng": 132.5667
            },
            "Ritt\u014d": {
                "lat": 35.0167,
                "lng": 136.0
            },
            "S\u014dja": {
                "lat": 34.6728,
                "lng": 133.7464
            },
            "Toyoake": {
                "lat": 35.0509,
                "lng": 137.0128
            },
            "Anan": {
                "lat": 33.9167,
                "lng": 134.6667
            },
            "Moriya": {
                "lat": 35.9514,
                "lng": 139.9754
            },
            "Tanabe": {
                "lat": 33.7333,
                "lng": 135.3833
            },
            "Sabae": {
                "lat": 35.9565,
                "lng": 136.1843
            },
            "\u014cdate": {
                "lat": 40.2714,
                "lng": 140.5642
            },
            "Fuefuki": {
                "lat": 35.6473,
                "lng": 138.6397
            },
            "Shiojiri": {
                "lat": 36.115,
                "lng": 137.9534
            },
            "Kashima": {
                "lat": 33.1036,
                "lng": 130.0989
            },
            "Chiyoda-ku": {
                "lat": 35.694,
                "lng": 139.7536
            },
            "Saiki": {
                "lat": 32.9597,
                "lng": 131.9
            },
            "Yachimata": {
                "lat": 35.6667,
                "lng": 140.3167
            },
            "Hashima": {
                "lat": 35.3199,
                "lng": 136.7033
            },
            "Tsuruga": {
                "lat": 35.6452,
                "lng": 136.0555
            },
            "Fukutsu": {
                "lat": 33.7667,
                "lng": 130.4833
            },
            "Ina": {
                "lat": 35.8275,
                "lng": 137.954
            },
            "Kitamoto": {
                "lat": 36.0269,
                "lng": 139.5302
            },
            "Tomigusuku": {
                "lat": 26.1611,
                "lng": 127.6689
            },
            "Fujioka": {
                "lat": 35.15,
                "lng": 138.6167
            },
            "Yanagawa": {
                "lat": 33.1631,
                "lng": 130.4058
            },
            "Sodegaura": {
                "lat": 35.43,
                "lng": 139.9544
            },
            "Tenri": {
                "lat": 34.5967,
                "lng": 135.8372
            },
            "Kurihara": {
                "lat": 38.7301,
                "lng": 141.0215
            },
            "Asahi": {
                "lat": 36.9462,
                "lng": 137.5599
            },
            "It\u014d": {
                "lat": 34.9657,
                "lng": 139.1019
            },
            "Fujiidera": {
                "lat": 34.5667,
                "lng": 135.6
            },
            "Nago": {
                "lat": 26.5917,
                "lng": 127.9775
            },
            "Kaga": {
                "lat": 36.3028,
                "lng": 136.315
            },
            "Miyoshi": {
                "lat": 34.026,
                "lng": 133.8072
            },
            "Nagakute": {
                "lat": 35.184,
                "lng": 137.0487
            },
            "K\u014dshizuka": {
                "lat": 32.886,
                "lng": 130.7897
            },
            "Tend\u014d": {
                "lat": 38.3623,
                "lng": 140.3779
            },
            "Tagaj\u014d": {
                "lat": 38.2938,
                "lng": 141.0043
            },
            "Mitoyo": {
                "lat": 34.1825,
                "lng": 133.715
            },
            "Hita": {
                "lat": 33.3214,
                "lng": 130.9411
            },
            "Shiroi": {
                "lat": 35.7915,
                "lng": 140.0563
            },
            "Hasuda": {
                "lat": 35.9945,
                "lng": 139.6624
            },
            "Hatogaya-honch\u014d": {
                "lat": 35.8333,
                "lng": 139.7333
            },
            "Hashimoto": {
                "lat": 34.3167,
                "lng": 135.6
            },
            "Og\u014dshi": {
                "lat": 32.8833,
                "lng": 130.7833
            },
            "Aisai": {
                "lat": 35.1528,
                "lng": 136.7282
            },
            "Tsushima": {
                "lat": 34.2,
                "lng": 129.2833
            },
            "Itoman": {
                "lat": 26.1236,
                "lng": 127.6658
            },
            "Tamba": {
                "lat": 35.1833,
                "lng": 135.0333
            },
            "Yamatotakada": {
                "lat": 34.5167,
                "lng": 135.7333
            },
            "Kokawa": {
                "lat": 34.2664,
                "lng": 135.3653
            },
            "Mizusawa": {
                "lat": 39.1438,
                "lng": 141.139
            },
            "Yame": {
                "lat": 33.2119,
                "lng": 130.5578
            },
            "Towada": {
                "lat": 40.6127,
                "lng": 141.2059
            },
            "Sany\u014d-Onoda": {
                "lat": 34.0031,
                "lng": 131.1819
            },
            "Sennan": {
                "lat": 34.3628,
                "lng": 135.2761
            },
            "Date": {
                "lat": 42.4667,
                "lng": 140.8667
            },
            "Shimotsuke": {
                "lat": 36.3872,
                "lng": 139.8421
            },
            "Shirakawa": {
                "lat": 37.1263,
                "lng": 140.2109
            },
            "Kesennuma": {
                "lat": 38.9081,
                "lng": 141.5699
            },
            "J\u014ds\u014d": {
                "lat": 36.0236,
                "lng": 139.9938
            },
            "Hy\u016bga": {
                "lat": 32.4228,
                "lng": 131.6239
            },
            "Chichibu": {
                "lat": 35.9918,
                "lng": 139.0855
            },
            "Tahara": {
                "lat": 34.6688,
                "lng": 137.2809
            },
            "Chikuma": {
                "lat": 36.5339,
                "lng": 138.12
            },
            "Koja": {
                "lat": 26.3344,
                "lng": 127.8057
            },
            "\u014csakasayama": {
                "lat": 34.5,
                "lng": 135.55
            },
            "Og\u014dri": {
                "lat": 33.3964,
                "lng": 130.5556
            },
            "Murakami": {
                "lat": 38.224,
                "lng": 139.48
            },
            "Ishikari": {
                "lat": 43.1667,
                "lng": 141.3167
            },
            "Kitahiroshima": {
                "lat": 34.6744,
                "lng": 132.5383
            },
            "Kan\u2019onjich\u014d": {
                "lat": 34.1272,
                "lng": 133.6614
            },
            "Kosai": {
                "lat": 34.7184,
                "lng": 137.5316
            },
            "T\u014dgane": {
                "lat": 35.5599,
                "lng": 140.3661
            },
            "Choshi": {
                "lat": 35.7346,
                "lng": 140.8268
            },
            "Tokoname": {
                "lat": 34.8865,
                "lng": 136.8323
            },
            "Uki": {
                "lat": 32.645,
                "lng": 130.6864
            },
            "Nonoichi": {
                "lat": 36.5194,
                "lng": 136.6098
            },
            "Minokamo": {
                "lat": 35.4402,
                "lng": 137.0157
            },
            "Zushi": {
                "lat": 35.2833,
                "lng": 139.5833
            },
            "Fussa": {
                "lat": 35.7333,
                "lng": 139.3333
            },
            "Kudamatsu": {
                "lat": 34.015,
                "lng": 131.8703
            },
            "Muk\u014dch\u014d": {
                "lat": 34.9486,
                "lng": 135.6983
            },
            "Mizuho": {
                "lat": 35.772,
                "lng": 139.354
            },
            "Takizawa": {
                "lat": 39.7347,
                "lng": 141.0771
            },
            "Takaishi": {
                "lat": 34.5167,
                "lng": 135.45
            },
            "N\u014dgata": {
                "lat": 33.7417,
                "lng": 130.7322
            },
            "Toki": {
                "lat": 35.4192,
                "lng": 137.1832
            },
            "Shij\u014dnawate": {
                "lat": 34.74,
                "lng": 135.6394
            },
            "Chino": {
                "lat": 35.9955,
                "lng": 138.1588
            },
            "Narutoch\u014d-mitsuishi": {
                "lat": 34.1667,
                "lng": 134.6167
            },
            "Imarich\u014d-k\u014d": {
                "lat": 33.2667,
                "lng": 129.8833
            },
            "Minamiuonuma": {
                "lat": 37.0655,
                "lng": 138.8761
            },
            "Hidaka": {
                "lat": 42.4833,
                "lng": 142.0667
            },
            "Annaka": {
                "lat": 36.3263,
                "lng": 138.8871
            },
            "Nihonmatsu": {
                "lat": 37.5849,
                "lng": 140.4312
            },
            "Konan": {
                "lat": 35.0,
                "lng": 136.0833
            },
            "Sakurai": {
                "lat": 34.5167,
                "lng": 135.85
            },
            "Sado": {
                "lat": 38.0183,
                "lng": 138.3683
            },
            "Hany\u016b": {
                "lat": 36.1726,
                "lng": 139.5485
            },
            "Hamura": {
                "lat": 35.7672,
                "lng": 139.3109
            },
            "Funato": {
                "lat": 34.25,
                "lng": 135.3167
            },
            "Mutsu": {
                "lat": 41.2928,
                "lng": 141.1836
            },
            "T\u014dkamachi": {
                "lat": 37.1333,
                "lng": 138.75
            },
            "Naka": {
                "lat": 36.4574,
                "lng": 140.4868
            },
            "Izumi": {
                "lat": 32.0833,
                "lng": 130.35
            },
            "Usa": {
                "lat": 33.5319,
                "lng": 131.3494
            },
            "Minami-S\u014dma": {
                "lat": 37.6422,
                "lng": 140.9573
            },
            "Miyakojima": {
                "lat": 24.8056,
                "lng": 125.2811
            },
            "Shiraoka": {
                "lat": 36.0191,
                "lng": 139.6769
            },
            "Shiogama": {
                "lat": 38.3144,
                "lng": 141.022
            },
            "Tomiya": {
                "lat": 38.4,
                "lng": 140.8833
            },
            "Band\u014d": {
                "lat": 36.0484,
                "lng": 139.8887
            },
            "Tsukubamirai": {
                "lat": 35.9631,
                "lng": 140.037
            },
            "Ky\u014dtango": {
                "lat": 35.6242,
                "lng": 135.0611
            },
            "Miyako": {
                "lat": 39.6414,
                "lng": 141.9571
            },
            "Goshogawara": {
                "lat": 40.808,
                "lng": 140.4401
            },
            "Sakaidech\u014d": {
                "lat": 34.3167,
                "lng": 133.8667
            },
            "Arao": {
                "lat": 32.9833,
                "lng": 130.4353
            },
            "Nichinan": {
                "lat": 31.6019,
                "lng": 131.3786
            },
            "Susono": {
                "lat": 35.1739,
                "lng": 138.9068
            },
            "Y\u016bki": {
                "lat": 36.3055,
                "lng": 139.8766
            },
            "Nakagawa": {
                "lat": 33.9511,
                "lng": 134.6622
            },
            "Hamada": {
                "lat": 34.8992,
                "lng": 132.0964
            },
            "Yasu": {
                "lat": 35.0667,
                "lng": 136.0333
            },
            "Satte": {
                "lat": 36.0781,
                "lng": 139.7259
            },
            "Hannan": {
                "lat": 34.3597,
                "lng": 135.2394
            },
            "Nanao": {
                "lat": 37.0431,
                "lng": 136.9674
            },
            "Noshiromachi": {
                "lat": 40.2121,
                "lng": 140.0266
            },
            "Tomisato": {
                "lat": 35.7,
                "lng": 140.5667
            },
            "Kameyama": {
                "lat": 34.8558,
                "lng": 136.4517
            },
            "Suzaka": {
                "lat": 36.6511,
                "lng": 138.3073
            },
            "Asakura": {
                "lat": 33.4167,
                "lng": 130.6667
            },
            "Higashiura": {
                "lat": 34.9771,
                "lng": 136.9656
            },
            "Midori": {
                "lat": 36.3948,
                "lng": 139.2811
            },
            "Hikari": {
                "lat": 33.9619,
                "lng": 131.9422
            },
            "Yamaga": {
                "lat": 33.0169,
                "lng": 130.6828
            },
            "\u014camishirasato": {
                "lat": 35.5167,
                "lng": 140.3167
            },
            "Suwa": {
                "lat": 36.0391,
                "lng": 138.114
            },
            "Nomimachi": {
                "lat": 36.447,
                "lng": 136.5541
            },
            "Takeoch\u014d-takeo": {
                "lat": 33.2,
                "lng": 130.0167
            },
            "Shimotsuch\u014d-kominami": {
                "lat": 34.15,
                "lng": 135.2167
            },
            "Chikugo": {
                "lat": 33.2122,
                "lng": 130.5019
            },
            "Takahama": {
                "lat": 35.4903,
                "lng": 135.551
            },
            "Omitama": {
                "lat": 36.2393,
                "lng": 140.3526
            },
            "Suzukawa": {
                "lat": 35.3767,
                "lng": 139.3847
            },
            "Ishigaki": {
                "lat": 24.3406,
                "lng": 124.1556
            },
            "Sanmu": {
                "lat": 35.6,
                "lng": 140.4167
            },
            "Ami": {
                "lat": 36.0308,
                "lng": 140.2148
            },
            "Ena": {
                "lat": 35.4492,
                "lng": 137.4128
            },
            "Kasuya": {
                "lat": 33.6167,
                "lng": 130.4833
            },
            "Iwakura": {
                "lat": 35.2794,
                "lng": 136.8714
            },
            "Higashine": {
                "lat": 38.4313,
                "lng": 140.3911
            },
            "Kamidani": {
                "lat": 34.1575,
                "lng": 135.2397
            },
            "Tonami": {
                "lat": 36.6475,
                "lng": 136.9622
            },
            "Ono": {
                "lat": 35.9797,
                "lng": 136.4875
            },
            "Hitachi-ota": {
                "lat": 36.5383,
                "lng": 140.5309
            },
            "Okaya": {
                "lat": 36.0671,
                "lng": 138.0493
            },
            "Nanto": {
                "lat": 36.5878,
                "lng": 136.9194
            },
            "Gosen": {
                "lat": 37.7444,
                "lng": 139.1826
            },
            "Kikugawa": {
                "lat": 34.75,
                "lng": 138.0833
            },
            "Tomioka": {
                "lat": 36.2599,
                "lng": 138.8899
            },
            "Noboribetsu": {
                "lat": 42.4167,
                "lng": 141.1
            },
            "Sanuki": {
                "lat": 34.32,
                "lng": 134.1794
            },
            "Hioki": {
                "lat": 31.6117,
                "lng": 130.3731
            },
            "Fujiyoshida": {
                "lat": 35.4875,
                "lng": 138.8077
            },
            "Nakai": {
                "lat": 33.5833,
                "lng": 133.6333
            },
            "Kurayoshi": {
                "lat": 35.4333,
                "lng": 133.8167
            },
            "Takashima": {
                "lat": 35.35,
                "lng": 136.0333
            },
            "Izunokuni": {
                "lat": 35.0333,
                "lng": 138.9333
            },
            "Shimeo": {
                "lat": 33.5833,
                "lng": 130.4833
            },
            "Kasaoka": {
                "lat": 34.5039,
                "lng": 133.51
            },
            "Kikuchi": {
                "lat": 32.9833,
                "lng": 130.8167
            },
            "Tamagawa": {
                "lat": 33.6389,
                "lng": 130.8061
            },
            "Hokota": {
                "lat": 36.1587,
                "lng": 140.5164
            },
            "Shima": {
                "lat": 39.5544,
                "lng": 141.1555
            },
            "Hokuto": {
                "lat": 35.7765,
                "lng": 138.4236
            },
            "Ogimachi": {
                "lat": 33.25,
                "lng": 130.2
            },
            "Kitakata": {
                "lat": 37.6511,
                "lng": 139.8748
            },
            "Numata": {
                "lat": 36.646,
                "lng": 139.0442
            },
            "Inuma": {
                "lat": 36.0001,
                "lng": 139.6242
            },
            "Tateyama": {
                "lat": 36.6636,
                "lng": 137.3137
            },
            "Masuda": {
                "lat": 34.6747,
                "lng": 131.8428
            },
            "Iwanuma": {
                "lat": 38.1043,
                "lng": 140.8702
            },
            "Nanj\u014d": {
                "lat": 26.1631,
                "lng": 127.7706
            },
            "Hagi": {
                "lat": 34.4081,
                "lng": 131.3992
            },
            "T\u014dg\u014d": {
                "lat": 35.0966,
                "lng": 137.0525
            },
            "Otofuke": {
                "lat": 43.0,
                "lng": 143.2
            },
            "Goshikich\u014d-aihara-minamidani": {
                "lat": 34.3,
                "lng": 134.7833
            },
            "Sugito": {
                "lat": 36.0258,
                "lng": 139.7367
            },
            "Himi": {
                "lat": 36.8567,
                "lng": 136.9731
            },
            "Shinshiro": {
                "lat": 34.9159,
                "lng": 137.4986
            },
            "Kumatori": {
                "lat": 34.4,
                "lng": 135.35
            },
            "Kikuy\u014d": {
                "lat": 32.85,
                "lng": 130.8167
            },
            "Nagaizumi": {
                "lat": 35.1377,
                "lng": 138.8972
            },
            "Kobayashi": {
                "lat": 31.9967,
                "lng": 130.9725
            },
            "Inabe": {
                "lat": 35.1156,
                "lng": 136.5614
            },
            "Taketoyo": {
                "lat": 34.8511,
                "lng": 136.9147
            },
            "Makinohara": {
                "lat": 34.7333,
                "lng": 138.2167
            },
            "Yatomi": {
                "lat": 35.1167,
                "lng": 136.7167
            },
            "Shimabara": {
                "lat": 32.7878,
                "lng": 130.3697
            },
            "Taniyama-ch\u016b\u014d": {
                "lat": 31.5211,
                "lng": 130.5176
            },
            "Awaji": {
                "lat": 34.4333,
                "lng": 134.9167
            },
            "K\u014dta": {
                "lat": 34.8645,
                "lng": 137.1657
            },
            "Akaiwa": {
                "lat": 34.7553,
                "lng": 134.0189
            },
            "Kasai": {
                "lat": 34.9333,
                "lng": 134.8333
            },
            "Futtsu": {
                "lat": 35.3041,
                "lng": 139.857
            },
            "Maniwa": {
                "lat": 35.0758,
                "lng": 133.7525
            },
            "Uozu": {
                "lat": 36.8273,
                "lng": 137.4092
            },
            "\u014cizumi": {
                "lat": 36.2478,
                "lng": 139.4049
            },
            "Minamishimabara": {
                "lat": 32.6667,
                "lng": 130.3
            },
            "Miura": {
                "lat": 35.15,
                "lng": 139.6167
            },
            "Yuzawa": {
                "lat": 39.1641,
                "lng": 140.4948
            },
            "Shimotsuma": {
                "lat": 36.1844,
                "lng": 139.9675
            },
            "Kitaibaraki": {
                "lat": 36.8019,
                "lng": 140.751
            },
            "Obita": {
                "lat": 32.8167,
                "lng": 129.8833
            },
            "Komoro": {
                "lat": 36.3275,
                "lng": 138.4258
            },
            "Sumoto": {
                "lat": 34.35,
                "lng": 134.9
            },
            "Amami": {
                "lat": 28.3783,
                "lng": 129.4944
            },
            "Unzen": {
                "lat": 32.8353,
                "lng": 130.1875
            },
            "Nanbei": {
                "lat": 35.3167,
                "lng": 139.1
            },
            "Kat\u014d": {
                "lat": 34.9167,
                "lng": 134.9667
            },
            "Katsuren-haebaru": {
                "lat": 26.1911,
                "lng": 127.7286
            },
            "Agano": {
                "lat": 37.8344,
                "lng": 139.226
            },
            "Komono": {
                "lat": 35.0167,
                "lng": 136.5
            },
            "\u014czu": {
                "lat": 32.8667,
                "lng": 130.8667
            },
            "Itoigawa": {
                "lat": 37.039,
                "lng": 137.8627
            },
            "Kasumigaura": {
                "lat": 36.1517,
                "lng": 140.2371
            },
            "Sagae": {
                "lat": 38.3809,
                "lng": 140.276
            },
            "Yomitan": {
                "lat": 26.3961,
                "lng": 127.7444
            },
            "Aizawa": {
                "lat": 35.5289,
                "lng": 139.3217
            },
            "Shirone": {
                "lat": 37.7658,
                "lng": 139.0192
            },
            "Kurobeshin": {
                "lat": 36.8667,
                "lng": 137.45
            },
            "Masaki": {
                "lat": 33.7833,
                "lng": 132.7167
            },
            "Nakama": {
                "lat": 33.8208,
                "lng": 130.7083
            },
            "Takikawa": {
                "lat": 43.55,
                "lng": 141.9167
            },
            "Tamba-Sasayama": {
                "lat": 35.0725,
                "lng": 135.2219
            },
            "Mibu": {
                "lat": 36.4271,
                "lng": 139.804
            },
            "Mitsuke": {
                "lat": 37.5316,
                "lng": 138.9127
            },
            "Kaseda-shirakame": {
                "lat": 31.4169,
                "lng": 130.3228
            },
            "Higashimatsushima": {
                "lat": 38.4263,
                "lng": 141.2104
            },
            "Inashiki": {
                "lat": 35.9565,
                "lng": 140.3239
            },
            "Hitachiomiya": {
                "lat": 36.5425,
                "lng": 140.4109
            },
            "\u014cmagari": {
                "lat": 39.4531,
                "lng": 140.4754
            },
            "Guj\u014d": {
                "lat": 35.7486,
                "lng": 136.9643
            },
            "Yoshinogawa": {
                "lat": 34.0631,
                "lng": 134.3614
            },
            "Sakuragawa": {
                "lat": 36.3273,
                "lng": 140.0906
            },
            "Ibusuki": {
                "lat": 31.2528,
                "lng": 130.6331
            },
            "Nishiwaki": {
                "lat": 34.9903,
                "lng": 134.9722
            },
            "Miyoshidai": {
                "lat": 35.8284,
                "lng": 139.5265
            },
            "Misawa": {
                "lat": 40.6831,
                "lng": 141.3691
            },
            "Maibara": {
                "lat": 35.3167,
                "lng": 136.2833
            },
            "Ibara": {
                "lat": 34.6,
                "lng": 133.4667
            },
            "Umi": {
                "lat": 33.5667,
                "lng": 130.5167
            },
            "Mizunami": {
                "lat": 35.3618,
                "lng": 137.2545
            },
            "Katsuragi": {
                "lat": 34.4833,
                "lng": 135.7333
            },
            "Minami-B\u014ds\u014d": {
                "lat": 35.0432,
                "lng": 139.84
            },
            "Kanie": {
                "lat": 35.1322,
                "lng": 136.7868
            },
            "S\u014dma": {
                "lat": 37.7967,
                "lng": 140.9196
            },
            "Tsubata": {
                "lat": 36.6692,
                "lng": 136.7288
            },
            "Yasugich\u014d": {
                "lat": 35.4314,
                "lng": 133.2508
            },
            "Kannan": {
                "lat": 35.0889,
                "lng": 138.9534
            },
            "Fuch\u016bch\u014d": {
                "lat": 34.565,
                "lng": 133.2419
            },
            "Komatsushimach\u014d": {
                "lat": 34.0003,
                "lng": 134.5844
            },
            "Kanada": {
                "lat": 33.7758,
                "lng": 130.9806
            },
            "Tamamura": {
                "lat": 36.3044,
                "lng": 139.1149
            },
            "Seika": {
                "lat": 34.7608,
                "lng": 135.7858
            },
            "Uto": {
                "lat": 32.6828,
                "lng": 130.6669
            },
            "Usuki": {
                "lat": 33.1261,
                "lng": 131.8053
            },
            "S\u014dsa": {
                "lat": 35.7075,
                "lng": 140.5642
            },
            "Miyajima": {
                "lat": 33.1525,
                "lng": 130.4747
            },
            "Awa": {
                "lat": 34.1014,
                "lng": 134.2964
            },
            "Setouchi": {
                "lat": 34.6667,
                "lng": 134.1
            },
            "Isumi": {
                "lat": 35.2539,
                "lng": 140.3852
            },
            "Rifu": {
                "lat": 38.3329,
                "lng": 140.9772
            },
            "\u014cfunato": {
                "lat": 39.062,
                "lng": 141.7132
            },
            "Tomobe": {
                "lat": 36.345,
                "lng": 140.3042
            },
            "Morohong\u014d": {
                "lat": 35.9415,
                "lng": 139.316
            },
            "Unnan": {
                "lat": 35.3078,
                "lng": 132.9003
            },
            "Aizumi": {
                "lat": 34.1266,
                "lng": 134.4951
            },
            "Kamata": {
                "lat": 33.5632,
                "lng": 130.7118
            },
            "Kaizu": {
                "lat": 35.2206,
                "lng": 136.6365
            },
            "Kahoku": {
                "lat": 38.4264,
                "lng": 140.3144
            },
            "Iyo": {
                "lat": 33.75,
                "lng": 132.7
            },
            "Tamura": {
                "lat": 37.4333,
                "lng": 140.5667
            },
            "Seiyo": {
                "lat": 33.3628,
                "lng": 132.5108
            },
            "Abashiri": {
                "lat": 44.0167,
                "lng": 144.2667
            },
            "Ofunato": {
                "lat": 39.068,
                "lng": 141.7252
            },
            "Nishihara": {
                "lat": 26.2167,
                "lng": 127.7667
            },
            "Motosu": {
                "lat": 35.483,
                "lng": 136.6786
            },
            "Got\u014d": {
                "lat": 32.7,
                "lng": 128.8333
            },
            "Kuji": {
                "lat": 40.1905,
                "lng": 141.7757
            },
            "Shinj\u014d": {
                "lat": 34.4892,
                "lng": 135.7266
            },
            "Fus\u014d": {
                "lat": 35.3591,
                "lng": 136.9132
            },
            "Atami": {
                "lat": 35.096,
                "lng": 139.0716
            },
            "T\u014don": {
                "lat": 33.7833,
                "lng": 132.8667
            },
            "Shis\u014d": {
                "lat": 35.0,
                "lng": 134.55
            },
            "Uonuma": {
                "lat": 37.2301,
                "lng": 138.9615
            },
            "Minamishiro": {
                "lat": 36.0227,
                "lng": 139.7227
            },
            "Harima": {
                "lat": 34.7167,
                "lng": 134.8667
            },
            "Ojiya": {
                "lat": 37.3144,
                "lng": 138.7951
            },
            "\u014cno": {
                "lat": 35.4706,
                "lng": 136.6276
            },
            "K\u014dry\u014d": {
                "lat": 34.55,
                "lng": 135.75
            },
            "Sh\u014dbara": {
                "lat": 34.8544,
                "lng": 133.0192
            },
            "Asakuchi": {
                "lat": 34.5247,
                "lng": 133.5875
            },
            "Shirakawa-tsuda": {
                "lat": 38.0025,
                "lng": 140.6197
            },
            "Shiroishi": {
                "lat": 33.1808,
                "lng": 130.1431
            },
            "Taishi": {
                "lat": 34.5167,
                "lng": 135.65
            },
            "Goj\u014d": {
                "lat": 34.3486,
                "lng": 135.6964
            },
            "Minamiky\u016bsh\u016b": {
                "lat": 31.3778,
                "lng": 130.4419
            },
            "Bungo\u014dno": {
                "lat": 32.9775,
                "lng": 131.5842
            },
            "Kamaishi": {
                "lat": 39.2758,
                "lng": 141.8857
            },
            "Wakabadai": {
                "lat": 45.4157,
                "lng": 141.6731
            },
            "Shing\u016b": {
                "lat": 33.7167,
                "lng": 136.0
            },
            "Yamanashi": {
                "lat": 35.6934,
                "lng": 138.6869
            },
            "S\u014d": {
                "lat": 31.6533,
                "lng": 131.0192
            },
            "Watari": {
                "lat": 38.0378,
                "lng": 140.8526
            },
            "Yufu": {
                "lat": 33.18,
                "lng": 131.4267
            },
            "\u014csawa": {
                "lat": 33.2067,
                "lng": 130.3839
            },
            "Mashiki": {
                "lat": 32.7833,
                "lng": 130.8
            },
            "Shimanto": {
                "lat": 33.2167,
                "lng": 133.1333
            },
            "Minamisatsuma": {
                "lat": 31.4169,
                "lng": 130.3228
            },
            "Omaezaki": {
                "lat": 34.6379,
                "lng": 138.1281
            },
            "Kameda-honch\u014d": {
                "lat": 37.8678,
                "lng": 139.0942
            },
            "\u014charu": {
                "lat": 35.1751,
                "lng": 136.8201
            },
            "Namerikawa": {
                "lat": 36.7644,
                "lng": 137.3412
            },
            "Y\u014dkaichiba": {
                "lat": 35.7,
                "lng": 140.5604
            },
            "Nagato": {
                "lat": 34.3706,
                "lng": 131.1825
            },
            "Yorii": {
                "lat": 36.1183,
                "lng": 139.193
            },
            "\u014cdach\u014d-\u014dda": {
                "lat": 35.1919,
                "lng": 132.4994
            },
            "Bizen": {
                "lat": 34.7453,
                "lng": 134.1889
            },
            "Sakaiminato": {
                "lat": 35.5333,
                "lng": 133.2333
            },
            "Kanzakimachi-kanzaki": {
                "lat": 33.3167,
                "lng": 130.3667
            },
            "Namegata": {
                "lat": 35.9905,
                "lng": 140.489
            },
            "Komagane": {
                "lat": 35.7288,
                "lng": 137.9339
            },
            "Maebara": {
                "lat": 35.114,
                "lng": 140.0989
            },
            "Shimizuch\u014d": {
                "lat": 35.099,
                "lng": 138.9029
            },
            "Zentsujich\u00f3": {
                "lat": 34.2167,
                "lng": 133.7833
            },
            "Kuroishi": {
                "lat": 40.6426,
                "lng": 140.6113
            },
            "Katagami": {
                "lat": 39.8832,
                "lng": 139.9886
            },
            "Hayama": {
                "lat": 35.2725,
                "lng": 139.5861
            },
            "Ayabe": {
                "lat": 35.3,
                "lng": 135.2667
            },
            "Yawatahama-shi": {
                "lat": 33.4667,
                "lng": 132.4167
            },
            "My\u014dk\u014d": {
                "lat": 37.0252,
                "lng": 138.2535
            },
            "Hitoyoshi": {
                "lat": 32.2167,
                "lng": 130.75
            },
            "Shimotoba": {
                "lat": 34.8833,
                "lng": 135.6667
            },
            "\u014ciso": {
                "lat": 35.3086,
                "lng": 139.3128
            },
            "Yaita": {
                "lat": 36.8067,
                "lng": 139.9241
            },
            "Sasaguri": {
                "lat": 33.6167,
                "lng": 130.5333
            },
            "Nantan": {
                "lat": 35.1,
                "lng": 135.4667
            },
            "Yaese": {
                "lat": 26.1581,
                "lng": 127.7186
            },
            "Tawaramoto": {
                "lat": 34.55,
                "lng": 135.8
            },
            "Okagaki": {
                "lat": 33.85,
                "lng": 130.6167
            },
            "Kaminokawa": {
                "lat": 36.4393,
                "lng": 139.9099
            },
            "Tsuruno": {
                "lat": 40.8087,
                "lng": 140.3801
            },
            "Sekiyado": {
                "lat": 36.0967,
                "lng": 139.7803
            },
            "Ch\u016b\u014d": {
                "lat": 35.5996,
                "lng": 138.6339
            },
            "Yunoshima": {
                "lat": 35.8059,
                "lng": 137.2441
            },
            "Yanai": {
                "lat": 33.9639,
                "lng": 132.1186
            },
            "Motomiya": {
                "lat": 37.5132,
                "lng": 140.3938
            },
            "Nany\u014d": {
                "lat": 38.0551,
                "lng": 140.1476
            },
            "Hirakawach\u014d": {
                "lat": 40.5841,
                "lng": 140.5665
            },
            "Tsuru": {
                "lat": 35.5515,
                "lng": 138.9054
            },
            "Inami": {
                "lat": 36.5602,
                "lng": 136.971
            },
            "Kamisato": {
                "lat": 36.2516,
                "lng": 139.1447
            },
            "Kaminoyama": {
                "lat": 38.1496,
                "lng": 140.2679
            },
            "Kaita": {
                "lat": 34.3722,
                "lng": 132.5361
            },
            "Kitaakita": {
                "lat": 40.226,
                "lng": 140.3708
            },
            "Chikuzen": {
                "lat": 33.45,
                "lng": 130.6
            },
            "Inagawa": {
                "lat": 34.9,
                "lng": 135.3833
            },
            "Y\u014dr\u014d": {
                "lat": 35.3084,
                "lng": 136.5614
            },
            "Shibushi": {
                "lat": 31.5,
                "lng": 131.05
            },
            "K\u014dsh\u016b": {
                "lat": 35.7043,
                "lng": 138.7294
            },
            "T\u014dmi": {
                "lat": 36.3594,
                "lng": 138.3304
            },
            "Takanezawa": {
                "lat": 36.631,
                "lng": 139.9865
            },
            "Togitsu": {
                "lat": 32.8333,
                "lng": 129.85
            },
            "Hanawa": {
                "lat": 40.2158,
                "lng": 140.7884
            },
            "Takahashi": {
                "lat": 34.7914,
                "lng": 133.6164
            },
            "Hirado": {
                "lat": 33.3667,
                "lng": 129.55
            },
            "Saikaich\u014d-kobag\u014d": {
                "lat": 32.9333,
                "lng": 129.65
            },
            "Yoshida": {
                "lat": 34.7667,
                "lng": 138.25
            },
            "Asago": {
                "lat": 35.3333,
                "lng": 134.85
            },
            "Maki": {
                "lat": 37.7605,
                "lng": 138.8893
            },
            "Oyabe": {
                "lat": 36.6755,
                "lng": 136.8687
            },
            "Saito": {
                "lat": 32.1086,
                "lng": 131.4014
            },
            "Matsubushi": {
                "lat": 35.9258,
                "lng": 139.8152
            },
            "Izu": {
                "lat": 34.9765,
                "lng": 138.9468
            },
            "Sue": {
                "lat": 33.5833,
                "lng": 130.5
            },
            "Ogawa": {
                "lat": 36.0567,
                "lng": 139.2618
            },
            "Aioi": {
                "lat": 34.8,
                "lng": 134.4667
            },
            "Taiwa": {
                "lat": 38.4373,
                "lng": 140.8864
            },
            "Higashikagawa": {
                "lat": 34.25,
                "lng": 134.3667
            },
            "Agui": {
                "lat": 34.9333,
                "lng": 136.9167
            },
            "Chatan": {
                "lat": 26.32,
                "lng": 127.7639
            },
            "Nirasaki": {
                "lat": 35.7089,
                "lng": 138.4461
            },
            "Obama": {
                "lat": 35.4957,
                "lng": 135.7466
            },
            "Mima": {
                "lat": 34.05,
                "lng": 134.1667
            },
            "Tainai": {
                "lat": 38.0597,
                "lng": 139.4103
            },
            "Hiji": {
                "lat": 33.3694,
                "lng": 131.5322
            },
            "Kakuda": {
                "lat": 37.977,
                "lng": 140.7821
            },
            "Mizumaki": {
                "lat": 33.8556,
                "lng": 130.6917
            },
            "\u014cuda-yamaguchi": {
                "lat": 34.5167,
                "lng": 135.9667
            },
            "Wajimazakimachi": {
                "lat": 37.3906,
                "lng": 136.8992
            },
            "Kitsuki": {
                "lat": 33.4169,
                "lng": 131.6161
            },
            "Hikawa": {
                "lat": 35.3833,
                "lng": 132.8333
            },
            "Yahaba": {
                "lat": 39.606,
                "lng": 141.1429
            },
            "Ninomiya": {
                "lat": 35.2995,
                "lng": 139.2555
            },
            "Niimi": {
                "lat": 34.9739,
                "lng": 133.4731
            },
            "Takahagi": {
                "lat": 36.7192,
                "lng": 140.7167
            },
            "Ukiha": {
                "lat": 33.35,
                "lng": 130.75
            },
            "Itako": {
                "lat": 35.9471,
                "lng": 140.5554
            },
            "Ikaruga": {
                "lat": 34.6167,
                "lng": 135.7333
            },
            "Akitakata": {
                "lat": 34.6631,
                "lng": 132.7064
            },
            "Nemuro": {
                "lat": 43.33,
                "lng": 145.5828
            },
            "Awara": {
                "lat": 36.2113,
                "lng": 136.229
            },
            "Ichikikushikino": {
                "lat": 31.7167,
                "lng": 130.2667
            },
            "Nayoro": {
                "lat": 44.3558,
                "lng": 142.4633
            },
            "Ureshinomachi-shimojuku": {
                "lat": 33.1333,
                "lng": 130.0667
            },
            "Miyanaga": {
                "lat": 33.7167,
                "lng": 130.6667
            },
            "Tarui": {
                "lat": 35.3702,
                "lng": 136.5437
            },
            "Uchinada": {
                "lat": 36.6535,
                "lng": 136.6454
            },
            "Makubetsu": {
                "lat": 42.9167,
                "lng": 143.35
            },
            "\u014cra": {
                "lat": 36.2524,
                "lng": 139.4623
            },
            "T\u014dno": {
                "lat": 39.3279,
                "lng": 141.5334
            },
            "\u014ctake": {
                "lat": 34.2378,
                "lng": 132.2222
            },
            "\u014cmachi": {
                "lat": 36.503,
                "lng": 137.8511
            },
            "Nagai": {
                "lat": 38.1075,
                "lng": 140.0405
            },
            "Kunisakimachi-tsurugawa": {
                "lat": 33.5633,
                "lng": 131.7322
            },
            "Ginan": {
                "lat": 35.3896,
                "lng": 136.7825
            },
            "Arida": {
                "lat": 34.0833,
                "lng": 135.1333
            },
            "Tosa": {
                "lat": 33.5,
                "lng": 133.4333
            },
            "T\u014din": {
                "lat": 35.0667,
                "lng": 136.6
            },
            "Sagara": {
                "lat": 34.6863,
                "lng": 138.1987
            },
            "Kami": {
                "lat": 38.5718,
                "lng": 140.8548
            },
            "Mimasaka": {
                "lat": 35.0086,
                "lng": 134.1486
            },
            "Fujikawaguchiko": {
                "lat": 35.4973,
                "lng": 138.7549
            },
            "Mimata": {
                "lat": 31.7306,
                "lng": 131.125
            },
            "Omigawa": {
                "lat": 35.85,
                "lng": 140.6167
            },
            "Miyaki": {
                "lat": 33.3167,
                "lng": 130.45
            },
            "Aridagawa": {
                "lat": 34.05,
                "lng": 135.2167
            },
            "Ninohe": {
                "lat": 40.2713,
                "lng": 141.3048
            },
            "Nogi": {
                "lat": 36.2332,
                "lng": 139.7407
            },
            "Minowa": {
                "lat": 35.915,
                "lng": 137.9819
            },
            "Iki": {
                "lat": 33.7497,
                "lng": 129.6914
            },
            "Ishii": {
                "lat": 34.0667,
                "lng": 134.4333
            },
            "Aso": {
                "lat": 32.9483,
                "lng": 131.1239
            },
            "Oga": {
                "lat": 39.8868,
                "lng": 139.8476
            },
            "Narut\u014d": {
                "lat": 35.5864,
                "lng": 140.4136
            },
            "Etajima": {
                "lat": 34.2167,
                "lng": 132.45
            },
            "Nasukarasuyama": {
                "lat": 36.6569,
                "lng": 140.1514
            },
            "Oirase": {
                "lat": 40.5992,
                "lng": 141.3977
            },
            "Buzen": {
                "lat": 33.6114,
                "lng": 131.1303
            },
            "Gose": {
                "lat": 34.4667,
                "lng": 135.7333
            },
            "\u014cguchi": {
                "lat": 35.3325,
                "lng": 136.9078
            },
            "Isa": {
                "lat": 32.0572,
                "lng": 130.6131
            },
            "Senboku": {
                "lat": 39.7017,
                "lng": 140.7315
            },
            "Kamiamakusa": {
                "lat": 32.5,
                "lng": 130.4167
            },
            "Ishiki": {
                "lat": 34.8167,
                "lng": 137.0333
            },
            "Hachimantai": {
                "lat": 39.9561,
                "lng": 141.0711
            },
            "\u014cji": {
                "lat": 34.6,
                "lng": 135.7167
            },
            "\u014cgawara": {
                "lat": 38.0494,
                "lng": 140.7308
            },
            "Ny\u016bzen": {
                "lat": 36.9335,
                "lng": 137.5021
            },
            "Yugawara": {
                "lat": 35.15,
                "lng": 139.0667
            },
            "Sanga": {
                "lat": 34.6,
                "lng": 135.7
            },
            "Minamikarasuyama": {
                "lat": 35.6683,
                "lng": 139.6012
            },
            "Nasu": {
                "lat": 37.0198,
                "lng": 140.121
            },
            "Takehara": {
                "lat": 34.3417,
                "lng": 132.9069
            },
            "Mine": {
                "lat": 34.1631,
                "lng": 131.2083
            },
            "Nakashunbetsu": {
                "lat": 43.55,
                "lng": 144.9667
            },
            "Minamata": {
                "lat": 32.2167,
                "lng": 130.4
            },
            "Mobetsu": {
                "lat": 44.35,
                "lng": 143.35
            },
            "Nikaho": {
                "lat": 39.203,
                "lng": 139.9077
            },
            "Tadotsu": {
                "lat": 34.2667,
                "lng": 133.75
            },
            "Kumano": {
                "lat": 33.8886,
                "lng": 136.1002
            },
            "Gob\u014d": {
                "lat": 33.8833,
                "lng": 135.15
            },
            "Ayagawa": {
                "lat": 34.25,
                "lng": 133.9167
            },
            "Kitajima": {
                "lat": 34.1333,
                "lng": 134.55
            },
            "Furukawa": {
                "lat": 36.2381,
                "lng": 137.1862
            },
            "Uenohara": {
                "lat": 35.6302,
                "lng": 139.1113
            },
            "Meiwa": {
                "lat": 36.2113,
                "lng": 139.5342
            },
            "Mihama": {
                "lat": 34.7789,
                "lng": 136.9082
            },
            "Kasamatsuch\u014d": {
                "lat": 35.3672,
                "lng": 136.7632
            },
            "Yokoshibahikari": {
                "lat": 35.6667,
                "lng": 140.5
            },
            "Itsukaichi": {
                "lat": 35.7522,
                "lng": 139.2667
            },
            "Takahata": {
                "lat": 38.0027,
                "lng": 140.1891
            },
            "Murayama": {
                "lat": 38.4833,
                "lng": 140.3833
            },
            "Yabu": {
                "lat": 35.4,
                "lng": 134.7667
            },
            "Nakagusuku": {
                "lat": 26.2678,
                "lng": 127.7914
            },
            "G\u014dtsuch\u014d": {
                "lat": 35.0117,
                "lng": 132.2178
            },
            "Bungotakada": {
                "lat": 33.5561,
                "lng": 131.4469
            },
            "Shinhidaka": {
                "lat": 42.25,
                "lng": 142.5667
            },
            "\u014ctsuki": {
                "lat": 35.6106,
                "lng": 138.94
            },
            "Yoshioka": {
                "lat": 36.4474,
                "lng": 139.0097
            },
            "Kozakai-ch\u014d": {
                "lat": 34.8,
                "lng": 137.3667
            },
            "\u014ckuchi-shinohara": {
                "lat": 32.05,
                "lng": 130.6167
            },
            "Mashiko": {
                "lat": 36.4673,
                "lng": 140.0934
            },
            "Fukagawa": {
                "lat": 43.7178,
                "lng": 142.0403
            },
            "Fukuyoshi": {
                "lat": 33.6833,
                "lng": 130.78
            },
            "Matsuura": {
                "lat": 33.3333,
                "lng": 129.7167
            },
            "Kanmaki": {
                "lat": 34.5667,
                "lng": 135.7167
            },
            "Furano": {
                "lat": 43.35,
                "lng": 142.3833
            },
            "Aish\u014d": {
                "lat": 35.1667,
                "lng": 136.2167
            },
            "Sh\u014dwa": {
                "lat": 35.6333,
                "lng": 138.5333
            },
            "Ino": {
                "lat": 33.55,
                "lng": 133.4333
            },
            "Mino": {
                "lat": 35.5448,
                "lng": 136.9076
            },
            "Yanagawamachi-saiwaich\u014d": {
                "lat": 37.85,
                "lng": 140.6
            },
            "Namioka": {
                "lat": 40.7,
                "lng": 140.5833
            },
            "\u014chara": {
                "lat": 35.2508,
                "lng": 140.3886
            },
            "Nishig\u014d": {
                "lat": 37.1417,
                "lng": 140.1554
            },
            "Bibai": {
                "lat": 43.3333,
                "lng": 141.85
            },
            "Shimogamo": {
                "lat": 34.6795,
                "lng": 138.9453
            },
            "Susaki": {
                "lat": 33.3925,
                "lng": 133.2931
            },
            "Kanaya": {
                "lat": 34.8345,
                "lng": 138.1267
            },
            "Tobe": {
                "lat": 33.75,
                "lng": 132.8
            },
            "Shisui": {
                "lat": 35.7167,
                "lng": 140.2667
            },
            "Rumoi": {
                "lat": 43.9333,
                "lng": 141.6333
            },
            "Satsuma": {
                "lat": 31.9,
                "lng": 130.45
            },
            "Hakui": {
                "lat": 36.8936,
                "lng": 136.779
            },
            "Takanabe": {
                "lat": 32.1281,
                "lng": 131.5033
            },
            "Sh\u014dnai": {
                "lat": 38.8499,
                "lng": 139.9047
            },
            "Shirahama": {
                "lat": 33.6817,
                "lng": 135.3444
            },
            "Sakae": {
                "lat": 35.8333,
                "lng": 140.25
            },
            "Namegawa": {
                "lat": 36.066,
                "lng": 139.361
            },
            "Kisai": {
                "lat": 36.1,
                "lng": 139.5667
            },
            "Makurazaki": {
                "lat": 31.2728,
                "lng": 130.2969
            },
            "Hirokawa": {
                "lat": 33.2417,
                "lng": 130.5514
            },
            "Ry\u016by\u014d": {
                "lat": 34.6783,
                "lng": 137.8167
            },
            "Yonabaru": {
                "lat": 26.1994,
                "lng": 127.7547
            },
            "Yosano": {
                "lat": 35.5667,
                "lng": 135.15
            },
            "Taketa": {
                "lat": 32.9736,
                "lng": 131.3978
            },
            "Karuizawa": {
                "lat": 36.3486,
                "lng": 138.5969
            },
            "Niiyama": {
                "lat": 35.0533,
                "lng": 138.9455
            },
            "Ibigawa": {
                "lat": 35.4873,
                "lng": 136.5686
            },
            "Fukude": {
                "lat": 34.6781,
                "lng": 137.8797
            },
            "Kitatajima": {
                "lat": 35.9821,
                "lng": 139.4816
            },
            "G\u014ddo": {
                "lat": 35.4174,
                "lng": 136.6252
            },
            "Mukaiengaru": {
                "lat": 44.0667,
                "lng": 143.5333
            },
            "Kamiichi": {
                "lat": 36.7,
                "lng": 137.3667
            },
            "Sukumo": {
                "lat": 32.9333,
                "lng": 132.7167
            },
            "Iiyama": {
                "lat": 36.8516,
                "lng": 138.3655
            },
            "Fukusaki": {
                "lat": 34.95,
                "lng": 134.7667
            },
            "Ainan": {
                "lat": 32.9667,
                "lng": 132.5833
            },
            "Shimokizukuri": {
                "lat": 40.8085,
                "lng": 140.3805
            },
            "Taka": {
                "lat": 35.05,
                "lng": 134.9236
            },
            "Aizumisato": {
                "lat": 37.4599,
                "lng": 139.8411
            },
            "Tanbaichich\u014d": {
                "lat": 34.5966,
                "lng": 135.8374
            },
            "Akune": {
                "lat": 32.0167,
                "lng": 130.2167
            },
            "Motomachi": {
                "lat": 43.825,
                "lng": 144.1056
            },
            "Eiheiji": {
                "lat": 36.0922,
                "lng": 136.2987
            },
            "Utazu": {
                "lat": 34.3167,
                "lng": 133.8333
            },
            "Arita": {
                "lat": 33.2106,
                "lng": 129.849
            },
            "Shimomura": {
                "lat": 36.0696,
                "lng": 138.0802
            },
            "Onga": {
                "lat": 33.85,
                "lng": 130.6667
            },
            "Miyatoko": {
                "lat": 33.6992,
                "lng": 130.9203
            },
            "Shika": {
                "lat": 37.0062,
                "lng": 136.778
            },
            "Tatsuno": {
                "lat": 35.9824,
                "lng": 137.9876
            },
            "Kunitomi": {
                "lat": 31.9906,
                "lng": 131.3236
            },
            "Shimokodanaka": {
                "lat": 35.6616,
                "lng": 139.6666
            },
            "Memuro-minami": {
                "lat": 42.9167,
                "lng": 143.05
            },
            "Kitagata": {
                "lat": 35.4333,
                "lng": 136.6833
            },
            "Yoichi": {
                "lat": 43.2,
                "lng": 140.7833
            },
            "Taku": {
                "lat": 33.2833,
                "lng": 130.1167
            },
            "Kishi": {
                "lat": 35.3333,
                "lng": 139.1333
            },
            "Rikuzen-Takata": {
                "lat": 39.028,
                "lng": 141.6254
            },
            "Minamishibetsuch\u014d": {
                "lat": 43.55,
                "lng": 141.9167
            },
            "Yoshimi": {
                "lat": 36.0399,
                "lng": 139.4538
            },
            "Mure": {
                "lat": 34.3376,
                "lng": 134.1397
            },
            "Heguri": {
                "lat": 34.6333,
                "lng": 135.7
            },
            "Toyono": {
                "lat": 34.9189,
                "lng": 135.4942
            },
            "Ranzan": {
                "lat": 36.0565,
                "lng": 139.3205
            },
            "Miyauchi": {
                "lat": 38.0667,
                "lng": 140.1333
            },
            "Shirosato": {
                "lat": 36.4792,
                "lng": 140.3762
            },
            "Shichigahama": {
                "lat": 38.3045,
                "lng": 141.0591
            },
            "Toba": {
                "lat": 34.4813,
                "lng": 136.8434
            },
            "Mitake": {
                "lat": 35.4344,
                "lng": 137.1307
            },
            "Ebino": {
                "lat": 32.0453,
                "lng": 130.8108
            },
            "Shin-Kamigot\u014d": {
                "lat": 32.9844,
                "lng": 129.0733
            },
            "Kiyama": {
                "lat": 33.4333,
                "lng": 130.5167
            },
            "Kadogawa": {
                "lat": 32.4711,
                "lng": 131.6461
            },
            "Mori": {
                "lat": 42.1,
                "lng": 140.5833
            },
            "Mann\u014d": {
                "lat": 34.2,
                "lng": 133.8333
            },
            "Miharu": {
                "lat": 37.441,
                "lng": 140.4926
            },
            "Minakami": {
                "lat": 36.6786,
                "lng": 138.9991
            },
            "\u014ci": {
                "lat": 35.3333,
                "lng": 139.15
            },
            "Kitanakagusuku": {
                "lat": 26.3011,
                "lng": 127.7931
            },
            "Hinode": {
                "lat": 35.7421,
                "lng": 139.2546
            },
            "Kawai": {
                "lat": 35.2,
                "lng": 138.5667
            },
            "Arai": {
                "lat": 34.6833,
                "lng": 137.5667
            },
            "Shing\u016bch\u014d-shing\u016b": {
                "lat": 34.9186,
                "lng": 134.5493
            },
            "Tsuiki": {
                "lat": 33.6561,
                "lng": 131.0561
            },
            "Yabuki": {
                "lat": 37.2013,
                "lng": 140.3386
            },
            "Minamichita": {
                "lat": 34.7151,
                "lng": 136.9298
            },
            "Kushima": {
                "lat": 31.4644,
                "lng": 131.2283
            },
            "Katsuura": {
                "lat": 35.15,
                "lng": 140.3167
            },
            "Kumage": {
                "lat": 34.0495,
                "lng": 131.969
            },
            "Shiraoi": {
                "lat": 42.55,
                "lng": 141.35
            },
            "Tadaoka-higashi": {
                "lat": 34.4833,
                "lng": 135.4
            },
            "Miyazu": {
                "lat": 35.5333,
                "lng": 135.2
            },
            "Sunagawa": {
                "lat": 43.5,
                "lng": 141.9
            },
            "Shintomi": {
                "lat": 32.0689,
                "lng": 131.4881
            },
            "\u014cyodo": {
                "lat": 34.3833,
                "lng": 135.7833
            },
            "Mifune": {
                "lat": 32.7167,
                "lng": 130.8
            },
            "Nanbu": {
                "lat": 35.3333,
                "lng": 133.3333
            },
            "T\u014dhoku": {
                "lat": 40.7281,
                "lng": 141.2578
            },
            "Nakanoto": {
                "lat": 36.9894,
                "lng": 136.9015
            },
            "Aki": {
                "lat": 33.5,
                "lng": 133.9
            },
            "Shuzenji": {
                "lat": 34.9717,
                "lng": 138.9304
            },
            "Yoshinogari": {
                "lat": 33.3167,
                "lng": 130.4
            },
            "Taishach\u014d-kizukikita": {
                "lat": 35.3867,
                "lng": 132.6902
            },
            "Kasumi": {
                "lat": 35.6333,
                "lng": 134.6333
            },
            "\u014cyamazaki": {
                "lat": 34.9028,
                "lng": 135.6883
            },
            "Kotoura": {
                "lat": 35.5,
                "lng": 133.7
            },
            "Owase": {
                "lat": 34.0708,
                "lng": 136.191
            },
            "Tachiarai": {
                "lat": 33.3722,
                "lng": 130.6225
            },
            "Mikkabi": {
                "lat": 34.8031,
                "lng": 137.5561
            },
            "Yurihama": {
                "lat": 35.4833,
                "lng": 133.8667
            },
            "Ishidoriyach\u014d-eso": {
                "lat": 39.4839,
                "lng": 141.1471
            },
            "\u014carai": {
                "lat": 36.3133,
                "lng": 140.5749
            },
            "Gonohe": {
                "lat": 40.5312,
                "lng": 141.308
            },
            "Usuda": {
                "lat": 36.1955,
                "lng": 138.4792
            },
            "Toyoyama": {
                "lat": 35.2505,
                "lng": 136.9121
            },
            "Minamiminowa": {
                "lat": 35.8729,
                "lng": 137.9751
            },
            "Taima": {
                "lat": 34.5111,
                "lng": 135.7069
            },
            "Noto": {
                "lat": 37.3066,
                "lng": 137.15
            },
            "Yakumo": {
                "lat": 42.25,
                "lng": 140.2667
            },
            "Tsukumiura": {
                "lat": 33.0722,
                "lng": 131.8614
            },
            "Ochiai": {
                "lat": 35.0181,
                "lng": 133.7524
            },
            "Yazu": {
                "lat": 35.4,
                "lng": 134.25
            },
            "Ashikita": {
                "lat": 32.3,
                "lng": 130.5167
            },
            "T\u014dbetsu": {
                "lat": 43.2167,
                "lng": 141.5167
            },
            "Shizukuishi": {
                "lat": 39.6952,
                "lng": 140.9758
            },
            "Kasagi": {
                "lat": 34.3,
                "lng": 135.5
            },
            "Miyota": {
                "lat": 36.3212,
                "lng": 138.5088
            },
            "Say\u014d": {
                "lat": 35.0,
                "lng": 134.35
            },
            "Kanan": {
                "lat": 34.4833,
                "lng": 135.6333
            },
            "Daigo": {
                "lat": 36.7681,
                "lng": 140.3552
            },
            "Kanegasaki": {
                "lat": 39.1957,
                "lng": 141.1163
            },
            "Nagasu": {
                "lat": 32.9333,
                "lng": 130.45
            },
            "Shimohata": {
                "lat": 36.2,
                "lng": 137.85
            },
            "Kumiyama": {
                "lat": 34.8814,
                "lng": 135.7328
            },
            "Uchiko": {
                "lat": 33.5333,
                "lng": 132.65
            },
            "Nakanoj\u014dmachi": {
                "lat": 36.5898,
                "lng": 138.841
            },
            "Wakuya": {
                "lat": 38.5397,
                "lng": 141.1282
            },
            "Kawaminami": {
                "lat": 32.1919,
                "lng": 131.5258
            },
            "Kutchan": {
                "lat": 42.9,
                "lng": 140.7667
            },
            "Tamaki": {
                "lat": 34.4833,
                "lng": 136.6333
            },
            "Maesawa": {
                "lat": 39.05,
                "lng": 141.1167
            },
            "Kamitonda": {
                "lat": 33.7,
                "lng": 135.4333
            },
            "Tone": {
                "lat": 35.8578,
                "lng": 140.1392
            },
            "Mitane": {
                "lat": 40.1017,
                "lng": 140.005
            },
            "Aizubange": {
                "lat": 37.5615,
                "lng": 139.8216
            },
            "Kurate": {
                "lat": 33.8,
                "lng": 130.6667
            },
            "Sera": {
                "lat": 34.5869,
                "lng": 133.0567
            },
            "Haga": {
                "lat": 36.5483,
                "lng": 140.0582
            },
            "Betsukai": {
                "lat": 43.4,
                "lng": 145.1167
            },
            "Obanazawa": {
                "lat": 38.6,
                "lng": 140.4
            },
            "Hirono": {
                "lat": 40.4085,
                "lng": 141.7187
            },
            "Matsushige": {
                "lat": 34.1339,
                "lng": 134.5803
            },
            "Nishinoomote": {
                "lat": 30.7322,
                "lng": 130.9967
            },
            "Kushimoto": {
                "lat": 33.4667,
                "lng": 135.7833
            },
            "Kuj\u016bkuri": {
                "lat": 35.5333,
                "lng": 140.4333
            },
            "Misaki": {
                "lat": 35.9981,
                "lng": 133.9581
            },
            "Su\u014d-\u014cshima": {
                "lat": 33.9167,
                "lng": 132.2333
            },
            "Yokoshiba": {
                "lat": 35.65,
                "lng": 140.4833
            },
            "Tabuse": {
                "lat": 33.9547,
                "lng": 132.0414
            },
            "Fujisaki": {
                "lat": 40.6561,
                "lng": 140.5028
            },
            "Ichikawamisato": {
                "lat": 35.5653,
                "lng": 138.5025
            },
            "Asagiri": {
                "lat": 32.2333,
                "lng": 130.9
            },
            "Kihoku": {
                "lat": 34.2114,
                "lng": 136.3372
            },
            "Yamada": {
                "lat": 39.4676,
                "lng": 141.9489
            },
            "Miho": {
                "lat": 36.0045,
                "lng": 140.3019
            },
            "Hasami": {
                "lat": 33.1336,
                "lng": 129.8994
            },
            "Anpachi": {
                "lat": 35.3353,
                "lng": 136.6654
            },
            "Tsukawaki": {
                "lat": 33.2831,
                "lng": 131.1515
            },
            "Haramachida": {
                "lat": 35.5434,
                "lng": 139.4452
            },
            "Ishikawa": {
                "lat": 37.1571,
                "lng": 140.4468
            },
            "Shichinohe": {
                "lat": 40.7447,
                "lng": 141.1579
            },
            "Minamiaizu": {
                "lat": 37.2004,
                "lng": 139.7732
            },
            "Kawaii": {
                "lat": 38.0045,
                "lng": 140.0458
            },
            "Shint\u014d": {
                "lat": 36.4384,
                "lng": 138.9671
            },
            "Taki": {
                "lat": 34.4961,
                "lng": 136.5462
            },
            "Kakunodatemachi": {
                "lat": 39.5993,
                "lng": 140.5615
            },
            "Kisanuki": {
                "lat": 31.2792,
                "lng": 130.9925
            },
            "Itakura": {
                "lat": 36.2229,
                "lng": 139.6103
            },
            "Hokuei": {
                "lat": 35.4833,
                "lng": 133.7667
            },
            "Fujikawa": {
                "lat": 35.5611,
                "lng": 138.4614
            },
            "Sakaki": {
                "lat": 36.4618,
                "lng": 138.1801
            },
            "Furukawamen": {
                "lat": 33.2378,
                "lng": 129.6547
            },
            "Nachikatsuura": {
                "lat": 33.6333,
                "lng": 135.9333
            },
            "Wakasa": {
                "lat": 35.5489,
                "lng": 135.9082
            },
            "\u014cki": {
                "lat": 33.2106,
                "lng": 130.4397
            },
            "Kamig\u014dri": {
                "lat": 34.8667,
                "lng": 134.35
            },
            "Seir\u014d": {
                "lat": 37.9745,
                "lng": 139.2742
            },
            "Tako": {
                "lat": 35.7333,
                "lng": 140.4667
            },
            "Higashimiyoshi": {
                "lat": 34.0333,
                "lng": 133.9333
            },
            "Tarumizu": {
                "lat": 31.5228,
                "lng": 130.7594
            },
            "Chiran": {
                "lat": 31.3783,
                "lng": 130.4416
            },
            "Ch\u014dsei": {
                "lat": 35.4167,
                "lng": 140.35
            },
            "Sh\u014ddoshima": {
                "lat": 34.4833,
                "lng": 134.2333
            },
            "Yamanobe": {
                "lat": 38.2833,
                "lng": 140.2667
            },
            "Okinoshima": {
                "lat": 36.2,
                "lng": 133.3167
            },
            "Ugo": {
                "lat": 39.1981,
                "lng": 140.4128
            },
            "Taneichi": {
                "lat": 40.4167,
                "lng": 141.7167
            },
            "Inawashiro": {
                "lat": 37.5744,
                "lng": 140.1215
            },
            "Wake": {
                "lat": 34.8028,
                "lng": 134.1575
            },
            "Kawatana": {
                "lat": 33.0667,
                "lng": 129.8667
            },
            "Hatoyama": {
                "lat": 35.9814,
                "lng": 139.3341
            },
            "Kadena": {
                "lat": 26.3617,
                "lng": 127.7553
            },
            "Tanagura": {
                "lat": 37.0299,
                "lng": 140.3796
            },
            "Yakage": {
                "lat": 34.6275,
                "lng": 133.5872
            },
            "Shin'onsen": {
                "lat": 35.6167,
                "lng": 134.45
            },
            "Yamato": {
                "lat": 32.6833,
                "lng": 131.0333
            },
            "Hashikami": {
                "lat": 40.4525,
                "lng": 141.6211
            },
            "Matsushima": {
                "lat": 38.3802,
                "lng": 141.0673
            },
            "Itano": {
                "lat": 34.1444,
                "lng": 134.4626
            },
            "T\u014dnosh\u014d": {
                "lat": 35.8333,
                "lng": 140.6667
            },
            "Kamikawa": {
                "lat": 35.0667,
                "lng": 134.7333
            },
            "Motobu": {
                "lat": 26.6581,
                "lng": 127.8981
            },
            "Senmayach\u014d-senmaya": {
                "lat": 38.9369,
                "lng": 141.3314
            },
            "Tonosh\u014d": {
                "lat": 34.4833,
                "lng": 134.1833
            },
            "Yuza": {
                "lat": 39.0147,
                "lng": 139.9089
            },
            "Suzu": {
                "lat": 37.4363,
                "lng": 137.2605
            },
            "Keisen": {
                "lat": 33.5833,
                "lng": 130.6833
            },
            "Akayu": {
                "lat": 38.05,
                "lng": 140.1833
            },
            "Kanbara": {
                "lat": 35.1159,
                "lng": 138.5949
            },
            "Ashibetsu": {
                "lat": 43.5167,
                "lng": 142.1833
            },
            "Ky\u014dtamba": {
                "lat": 35.1667,
                "lng": 135.4167
            },
            "Itayanagi": {
                "lat": 40.6959,
                "lng": 140.4572
            },
            "Shirataka": {
                "lat": 38.1831,
                "lng": 140.0986
            },
            "Takamori": {
                "lat": 35.5138,
                "lng": 137.8739
            },
            "Saka": {
                "lat": 34.3411,
                "lng": 132.5136
            },
            "Hayashima": {
                "lat": 34.6006,
                "lng": 133.8283
            },
            "Kanra": {
                "lat": 36.243,
                "lng": 138.9218
            },
            "Kawamata": {
                "lat": 37.665,
                "lng": 140.5983
            },
            "Chikura": {
                "lat": 34.95,
                "lng": 139.95
            },
            "Matsukawa": {
                "lat": 35.5972,
                "lng": 137.9097
            },
            "Higashiagatsuma": {
                "lat": 36.5714,
                "lng": 138.8256
            },
            "Nobeji": {
                "lat": 40.8644,
                "lng": 141.1287
            },
            "Kasaishi": {
                "lat": 37.2528,
                "lng": 140.3434
            },
            "Marumori": {
                "lat": 37.9114,
                "lng": 140.7654
            },
            "Tsuruta": {
                "lat": 40.7588,
                "lng": 140.4285
            },
            "Okabech\u014d-okabe": {
                "lat": 34.9167,
                "lng": 138.2833
            },
            "Sakawa": {
                "lat": 33.5,
                "lng": 133.2833
            },
            "Minabe": {
                "lat": 33.7725,
                "lng": 135.3206
            },
            "\u014csako": {
                "lat": 31.4422,
                "lng": 130.9775
            },
            "Unebich\u014d": {
                "lat": 34.4806,
                "lng": 135.7926
            },
            "Iwate": {
                "lat": 39.9728,
                "lng": 141.2122
            },
            "H\u014ddatsushimizu": {
                "lat": 36.8627,
                "lng": 136.7976
            },
            "Urakawa": {
                "lat": 42.1667,
                "lng": 142.7667
            },
            "Shiotach\u014d-matsusaki": {
                "lat": 33.1268,
                "lng": 130.0589
            },
            "Iwanai": {
                "lat": 42.9789,
                "lng": 140.5092
            },
            "Tosashimizu": {
                "lat": 32.7833,
                "lng": 132.95
            },
            "Kagamino": {
                "lat": 35.0919,
                "lng": 133.9331
            },
            "Maisaka": {
                "lat": 34.6857,
                "lng": 137.6272
            },
            "Iwaka": {
                "lat": 40.6159,
                "lng": 140.4213
            },
            "Hirao": {
                "lat": 33.9381,
                "lng": 132.0733
            },
            "\u014ciwa": {
                "lat": 34.5254,
                "lng": 135.8498
            },
            "Yakushima": {
                "lat": 30.39,
                "lng": 130.6511
            },
            "Za\u014d": {
                "lat": 39.0981,
                "lng": 140.6587
            },
            "Ry\u016b\u014d": {
                "lat": 35.0667,
                "lng": 136.1167
            },
            "Yamamoto": {
                "lat": 37.9624,
                "lng": 140.8775
            },
            "Mitai": {
                "lat": 32.7117,
                "lng": 131.3078
            },
            "Motegi": {
                "lat": 36.5321,
                "lng": 140.1876
            },
            "Okuizumo": {
                "lat": 35.1975,
                "lng": 133.0025
            },
            "Muroto-misakicho": {
                "lat": 33.29,
                "lng": 134.1519
            },
            "Kemigawa": {
                "lat": 35.6514,
                "lng": 140.0662
            },
            "Kuriyama": {
                "lat": 43.05,
                "lng": 141.7833
            },
            "Koori": {
                "lat": 37.8494,
                "lng": 140.5164
            },
            "Kuse": {
                "lat": 35.0759,
                "lng": 133.7534
            },
            "Higashiizu": {
                "lat": 34.7667,
                "lng": 139.0333
            },
            "Kamiita": {
                "lat": 34.1167,
                "lng": 134.4
            },
            "Ichinohe": {
                "lat": 40.2129,
                "lng": 141.2954
            },
            "Kin": {
                "lat": 26.4561,
                "lng": 127.9261
            },
            "Ichikai": {
                "lat": 36.55,
                "lng": 140.1
            },
            "Tagami": {
                "lat": 37.6988,
                "lng": 139.058
            },
            "Matsuo": {
                "lat": 35.633,
                "lng": 140.467
            },
            "Yamanouchi": {
                "lat": 36.7446,
                "lng": 138.4127
            },
            "Hikawadai": {
                "lat": 32.5667,
                "lng": 130.6667
            },
            "Yuasa": {
                "lat": 34.0333,
                "lng": 135.1833
            },
            "Satosh\u014d": {
                "lat": 34.5139,
                "lng": 133.5569
            },
            "Iioka": {
                "lat": 35.7,
                "lng": 140.7167
            },
            "Sh\u014d\u014d": {
                "lat": 35.05,
                "lng": 134.1167
            },
            "Minamiise": {
                "lat": 34.35,
                "lng": 136.7
            },
            "Hakone": {
                "lat": 35.1894,
                "lng": 139.0247
            },
            "Ogose": {
                "lat": 35.9645,
                "lng": 139.2942
            },
            "Nishi": {
                "lat": 32.2,
                "lng": 130.8333
            },
            "Minamisanriku": {
                "lat": 38.6793,
                "lng": 141.4608
            },
            "Onna": {
                "lat": 26.4833,
                "lng": 127.85
            },
            "Chiyoda": {
                "lat": 36.2178,
                "lng": 139.4424
            },
            "Miyato": {
                "lat": 36.1772,
                "lng": 139.1814
            },
            "Ogano": {
                "lat": 36.0171,
                "lng": 139.0086
            },
            "Fujino": {
                "lat": 35.606,
                "lng": 139.143
            },
            "\u014ctsuchi": {
                "lat": 39.3582,
                "lng": 141.8994
            },
            "Matsuda-s\u014dry\u014d": {
                "lat": 35.35,
                "lng": 139.1333
            },
            "Iwai": {
                "lat": 35.5833,
                "lng": 134.3333
            },
            "Nakayama": {
                "lat": 38.3331,
                "lng": 140.2831
            },
            "Kanagich\u014d": {
                "lat": 40.9026,
                "lng": 140.462
            },
            "Naganuma": {
                "lat": 43.0167,
                "lng": 141.7
            },
            "Obuse": {
                "lat": 36.6975,
                "lng": 138.3121
            },
            "Murata": {
                "lat": 38.1185,
                "lng": 140.7224
            },
            "Kamifurano": {
                "lat": 43.45,
                "lng": 142.4667
            },
            "Kibich\u016b\u014d": {
                "lat": 34.8633,
                "lng": 133.6936
            },
            "H\u014dki": {
                "lat": 35.3856,
                "lng": 133.4072
            },
            "Rokunohe": {
                "lat": 40.6095,
                "lng": 141.3248
            },
            "Tokigawa": {
                "lat": 36.0086,
                "lng": 139.2968
            },
            "Mochizuki": {
                "lat": 36.2644,
                "lng": 138.3622
            },
            "Minobu": {
                "lat": 35.4675,
                "lng": 138.4425
            },
            "Kiso": {
                "lat": 35.9363,
                "lng": 137.7831
            },
            "Kih\u014d": {
                "lat": 33.7333,
                "lng": 136.0
            },
            "Nakasato": {
                "lat": 40.9649,
                "lng": 140.44
            },
            "Shirako": {
                "lat": 35.45,
                "lng": 140.3667
            },
            "Minamiaso": {
                "lat": 32.8167,
                "lng": 131.0333
            },
            "Tokunoshima": {
                "lat": 27.7672,
                "lng": 129.0017
            },
            "Shioya": {
                "lat": 36.7776,
                "lng": 139.8506
            },
            "Higashikagura": {
                "lat": 43.7,
                "lng": 142.45
            },
            "Iizuna": {
                "lat": 36.7548,
                "lng": 138.2355
            },
            "Rokkasho": {
                "lat": 40.9672,
                "lng": 141.3744
            },
            "\u014cnan": {
                "lat": 34.8939,
                "lng": 132.4378
            },
            "Kuroshio": {
                "lat": 33.0167,
                "lng": 133.0167
            },
            "Yaotsu": {
                "lat": 35.476,
                "lng": 137.1416
            },
            "Sakuho": {
                "lat": 36.161,
                "lng": 138.4834
            },
            "Nikaid\u014d-kaminosh\u014dch\u014d": {
                "lat": 34.5914,
                "lng": 135.7962
            },
            "Tsun\u014d": {
                "lat": 32.2564,
                "lng": 131.5597
            },
            "Hiranai": {
                "lat": 40.9259,
                "lng": 140.9559
            },
            "Iwashita": {
                "lat": 32.65,
                "lng": 130.8167
            },
            "Kawara": {
                "lat": 33.6681,
                "lng": 130.8472
            },
            "Iwamuro-onsen": {
                "lat": 37.7375,
                "lng": 138.8391
            },
            "Kamikita-kita": {
                "lat": 40.736,
                "lng": 140.956
            },
            "Kominato": {
                "lat": 40.9259,
                "lng": 140.9559
            },
            "Kami-kawabe": {
                "lat": 35.4866,
                "lng": 137.0707
            },
            "Biei": {
                "lat": 43.5833,
                "lng": 142.4667
            },
            "Asuke": {
                "lat": 35.134,
                "lng": 137.1454
            },
            "Akabira": {
                "lat": 43.55,
                "lng": 142.05
            },
            "Torihama": {
                "lat": 35.6006,
                "lng": 135.9406
            },
            "Kushijima": {
                "lat": 32.7333,
                "lng": 130.75
            },
            "Nakaechi": {
                "lat": 35.5499,
                "lng": 139.4476
            },
            "Yamakita": {
                "lat": 35.35,
                "lng": 139.0667
            },
            "Minano": {
                "lat": 36.0708,
                "lng": 139.0988
            },
            "Shimizu": {
                "lat": 43.0167,
                "lng": 142.8833
            },
            "Nakoushi": {
                "lat": 26.6825,
                "lng": 127.9728
            },
            "Nakao": {
                "lat": 35.3333,
                "lng": 139.2167
            },
            "Fujisawach\u014d-niinuma": {
                "lat": 38.867,
                "lng": 141.35
            },
            "Nagaoki": {
                "lat": 33.0167,
                "lng": 130.6167
            },
            "Yui": {
                "lat": 35.1,
                "lng": 138.5667
            },
            "Iijima": {
                "lat": 35.6767,
                "lng": 137.9198
            },
            "Katsuyama": {
                "lat": 35.0876,
                "lng": 133.6913
            },
            "Hirayama": {
                "lat": 33.65,
                "lng": 130.5
            },
            "Taragi": {
                "lat": 32.2667,
                "lng": 130.9333
            },
            "Soeda": {
                "lat": 33.5719,
                "lng": 130.8539
            },
            "\u014ctaki": {
                "lat": 35.2852,
                "lng": 140.2454
            },
            "\u014cwani": {
                "lat": 40.5184,
                "lng": 140.5678
            },
            "\u014chata": {
                "lat": 41.4034,
                "lng": 141.162
            },
            "\u014cuda-dait\u014d": {
                "lat": 34.4789,
                "lng": 135.9281
            },
            "Hakubach\u014d": {
                "lat": 36.6981,
                "lng": 137.8619
            },
            "Miyada": {
                "lat": 35.7689,
                "lng": 137.9443
            },
            "Iwaizumi": {
                "lat": 39.8431,
                "lng": 141.7964
            },
            "Takaharu": {
                "lat": 31.9283,
                "lng": 131.0078
            },
            "Setana": {
                "lat": 42.45,
                "lng": 139.85
            }
        }
    },
    "ID": {
        "name": "Indonesia",
        "cities": {
            "Jakarta": {
                "lat": -6.175,
                "lng": 106.8275
            },
            "Surabaya": {
                "lat": -7.2458,
                "lng": 112.7378
            },
            "Medan": {
                "lat": 3.5894,
                "lng": 98.6739
            },
            "Malang": {
                "lat": -7.98,
                "lng": 112.62
            },
            "Bekasi": {
                "lat": -6.2349,
                "lng": 106.9923
            },
            "Bekasi Kota": {
                "lat": -6.2333,
                "lng": 107.0
            },
            "Tangerang": {
                "lat": -6.1703,
                "lng": 106.6403
            },
            "Depok": {
                "lat": -6.394,
                "lng": 106.8225
            },
            "Semarang": {
                "lat": -6.99,
                "lng": 110.4225
            },
            "Palembang": {
                "lat": -2.9861,
                "lng": 104.7556
            },
            "Sangereng": {
                "lat": -6.2889,
                "lng": 106.7181
            },
            "Makassar": {
                "lat": -5.1619,
                "lng": 119.4362
            },
            "Bagam": {
                "lat": 1.13,
                "lng": 104.0531
            },
            "Sumedang": {
                "lat": -6.84,
                "lng": 107.9208
            },
            "Batam Centre": {
                "lat": 1.13,
                "lng": 104.0531
            },
            "Cilacap": {
                "lat": -7.7167,
                "lng": 109.017
            },
            "Bandar Lampung": {
                "lat": -5.45,
                "lng": 105.2667
            },
            "Bogor": {
                "lat": -6.5966,
                "lng": 106.7972
            },
            "Pekanbaru": {
                "lat": 0.5092,
                "lng": 101.4453
            },
            "Padang": {
                "lat": -0.95,
                "lng": 100.3531
            },
            "Denpasar": {
                "lat": -8.65,
                "lng": 115.2167
            },
            "Samarinda": {
                "lat": -0.5,
                "lng": 117.1378
            },
            "Tasikmalaya": {
                "lat": -7.3161,
                "lng": 108.1975
            },
            "Banjarmasin": {
                "lat": -3.32,
                "lng": 114.5925
            },
            "Jambi": {
                "lat": -1.59,
                "lng": 103.61
            },
            "Pontianak": {
                "lat": -0.0206,
                "lng": 109.3414
            },
            "Serang": {
                "lat": -6.12,
                "lng": 106.1503
            },
            "Balikpapan": {
                "lat": -1.2768,
                "lng": 116.8277
            },
            "Cimahi": {
                "lat": -6.8712,
                "lng": 107.5548
            },
            "Surakarta": {
                "lat": -7.5667,
                "lng": 110.8167
            },
            "Lembok": {
                "lat": -8.565,
                "lng": 116.351
            },
            "Kupang": {
                "lat": -10.1702,
                "lng": 123.6077
            },
            "Manado Light": {
                "lat": 1.4931,
                "lng": 124.8413
            },
            "Manado": {
                "lat": 1.4931,
                "lng": 124.8413
            },
            "Bandung": {
                "lat": -6.912,
                "lng": 107.6097
            },
            "Yogyakarta": {
                "lat": -7.8014,
                "lng": 110.3644
            },
            "Jayapura": {
                "lat": -2.533,
                "lng": 140.717
            },
            "Mataram": {
                "lat": -8.5833,
                "lng": 116.1167
            },
            "Cilegon": {
                "lat": -6.0027,
                "lng": 106.0112
            },
            "Palu": {
                "lat": -0.895,
                "lng": 119.8594
            },
            "Cibinong": {
                "lat": -6.485,
                "lng": 106.842
            },
            "Bengkulu": {
                "lat": -3.7956,
                "lng": 102.2592
            },
            "Ambon": {
                "lat": -3.7,
                "lng": 128.1667
            },
            "Dumai": {
                "lat": 1.6667,
                "lng": 101.45
            },
            "Kendari": {
                "lat": -3.9907,
                "lng": 122.5086
            },
            "Cimanggis": {
                "lat": -6.3645,
                "lng": 106.8591
            },
            "Sukabumi": {
                "lat": -6.9181,
                "lng": 106.9315
            },
            "Karawang": {
                "lat": -6.3125,
                "lng": 107.295
            },
            "Binjai": {
                "lat": 3.5986,
                "lng": 98.4803
            },
            "Jember": {
                "lat": -8.1727,
                "lng": 113.6873
            },
            "Pekalongan": {
                "lat": -6.8833,
                "lng": 109.6667
            },
            "Cirebon": {
                "lat": -6.7071,
                "lng": 108.5574
            },
            "Sorong": {
                "lat": -0.8667,
                "lng": 131.25
            },
            "Pondokaren": {
                "lat": -6.2811,
                "lng": 106.7019
            },
            "Tegal": {
                "lat": -6.8675,
                "lng": 109.1375
            },
            "Mamuju": {
                "lat": -2.6833,
                "lng": 118.9
            },
            "Pematangsiantar": {
                "lat": 2.96,
                "lng": 99.06
            },
            "Pasarkemis": {
                "lat": -6.1703,
                "lng": 106.53
            },
            "Majene": {
                "lat": -3.5403,
                "lng": 118.9707
            },
            "Kediri": {
                "lat": -7.8111,
                "lng": 112.0047
            },
            "Palangkaraya": {
                "lat": -2.21,
                "lng": 113.92
            },
            "Banda Aceh": {
                "lat": 5.55,
                "lng": 95.3175
            },
            "Purwokerto": {
                "lat": -7.4278,
                "lng": 109.2417
            },
            "Singkawang": {
                "lat": 0.9,
                "lng": 108.9833
            },
            "Padangsidempuan": {
                "lat": 1.3786,
                "lng": 99.2722
            },
            "Cikupa": {
                "lat": -6.4947,
                "lng": 106.6511
            },
            "Probolinggo": {
                "lat": -7.75,
                "lng": 113.2167
            },
            "Beji": {
                "lat": -6.3704,
                "lng": 106.8176
            },
            "Pangkalpinang": {
                "lat": -2.1333,
                "lng": 106.1167
            },
            "Bitung": {
                "lat": 1.4472,
                "lng": 125.1978
            },
            "Batu": {
                "lat": -7.872,
                "lng": 112.525
            },
            "Banjarbaru": {
                "lat": -3.4425,
                "lng": 114.8325
            },
            "Ciputat": {
                "lat": -6.3111,
                "lng": 106.7619
            },
            "Lubuklinggau": {
                "lat": -3.2967,
                "lng": 102.8617
            },
            "Banjar": {
                "lat": -7.3695,
                "lng": 108.5414
            },
            "Ternate": {
                "lat": 0.78,
                "lng": 127.3819
            },
            "Tanjungpinang": {
                "lat": 0.9188,
                "lng": 104.4554
            },
            "Tarakan": {
                "lat": 3.3,
                "lng": 117.6333
            },
            "Madiun": {
                "lat": -7.63,
                "lng": 111.5231
            },
            "Martapura": {
                "lat": -3.45,
                "lng": 114.8333
            },
            "Gorontalo": {
                "lat": 0.5333,
                "lng": 123.0667
            },
            "Prabumulih": {
                "lat": -3.4328,
                "lng": 104.2356
            },
            "Pasuruan": {
                "lat": -7.6406,
                "lng": 112.9065
            },
            "Padalarang": {
                "lat": -6.8435,
                "lng": 107.4779
            },
            "Pinrang": {
                "lat": -3.7857,
                "lng": 119.6522
            },
            "Lhokseumawe": {
                "lat": 5.1881,
                "lng": 97.1403
            },
            "Palopo": {
                "lat": -3.0,
                "lng": 120.2
            },
            "Petarukan": {
                "lat": -6.8961,
                "lng": 109.4514
            },
            "Singosari": {
                "lat": -7.8925,
                "lng": 112.6658
            },
            "Tanjungbalai": {
                "lat": 2.97,
                "lng": 99.8
            },
            "Purwakarta": {
                "lat": -6.5533,
                "lng": 107.4472
            },
            "Langsa": {
                "lat": 4.48,
                "lng": 97.9633
            },
            "Tebingtinggi": {
                "lat": 3.3194,
                "lng": 99.1522
            },
            "Cianjur": {
                "lat": -6.82,
                "lng": 107.1408
            },
            "Rantau Prapat": {
                "lat": 2.3333,
                "lng": 100.05
            },
            "Salatiga": {
                "lat": -7.3247,
                "lng": 110.5444
            },
            "Curug": {
                "lat": -6.3711,
                "lng": 106.8
            },
            "Metro": {
                "lat": -5.1167,
                "lng": 105.3
            },
            "Sampit": {
                "lat": -2.5333,
                "lng": 112.95
            },
            "Sawangan": {
                "lat": -6.4086,
                "lng": 106.7647
            },
            "Baubau": {
                "lat": -5.4667,
                "lng": 122.633
            },
            "Ungaran": {
                "lat": -7.1381,
                "lng": 110.405
            },
            "Bontang": {
                "lat": 0.1333,
                "lng": 117.5
            },
            "Bima": {
                "lat": -8.46,
                "lng": 118.7267
            },
            "Tigaraksa": {
                "lat": -6.2667,
                "lng": 106.4667
            },
            "Singaraja": {
                "lat": -8.1167,
                "lng": 115.0833
            },
            "Saumlaki": {
                "lat": -7.975,
                "lng": 131.3075
            },
            "Pageralam": {
                "lat": -4.0167,
                "lng": 103.25
            },
            "Kisaran": {
                "lat": 2.9833,
                "lng": 99.6125
            },
            "Cinere": {
                "lat": -6.3333,
                "lng": 106.7833
            },
            "Tinipuka": {
                "lat": -4.55,
                "lng": 136.89
            },
            "Subang": {
                "lat": -6.5714,
                "lng": 107.7614
            },
            "Teluknaga": {
                "lat": -6.0989,
                "lng": 106.6381
            },
            "Gunungsitoli": {
                "lat": 1.2833,
                "lng": 97.6167
            },
            "Rangkasbitung": {
                "lat": -6.3667,
                "lng": 106.25
            },
            "Batang": {
                "lat": -6.9081,
                "lng": 109.7304
            },
            "Gubeng": {
                "lat": -7.2729,
                "lng": 112.7493
            },
            "Adonara": {
                "lat": -8.25,
                "lng": 123.15
            },
            "Blitar": {
                "lat": -8.1,
                "lng": 112.15
            },
            "Mojokerto": {
                "lat": -7.4722,
                "lng": 112.4336
            },
            "Parepare": {
                "lat": -4.0167,
                "lng": 119.6236
            },
            "Garut": {
                "lat": -7.2167,
                "lng": 107.9
            },
            "Payakumbuh": {
                "lat": -0.2333,
                "lng": 100.6333
            },
            "Magelang": {
                "lat": -7.4667,
                "lng": 110.2167
            },
            "Leuwiliang": {
                "lat": -6.5742,
                "lng": 106.632
            },
            "Paleng": {
                "lat": -1.4,
                "lng": 123.1667
            },
            "Pati": {
                "lat": -6.7415,
                "lng": 111.0347
            },
            "Indramayu": {
                "lat": -6.3528,
                "lng": 108.3242
            },
            "Kotamobagu": {
                "lat": 0.7333,
                "lng": 124.3167
            },
            "Pelabuhanratu": {
                "lat": -6.9878,
                "lng": 106.5439
            },
            "Soasio": {
                "lat": 0.6833,
                "lng": 127.4
            },
            "Banyuwangi": {
                "lat": -8.2186,
                "lng": 114.3669
            },
            "Soreang": {
                "lat": -7.0372,
                "lng": 107.5167
            },
            "Tarogong": {
                "lat": -7.215,
                "lng": 107.8856
            },
            "Rawajaya": {
                "lat": -7.58,
                "lng": 108.8828
            },
            "Rawasari": {
                "lat": -7.5733,
                "lng": 108.8833
            },
            "Kuningan": {
                "lat": -6.9764,
                "lng": 108.4844
            },
            "Bukittinggi": {
                "lat": -0.3056,
                "lng": 100.3692
            },
            "Baleraja": {
                "lat": -6.5167,
                "lng": 107.9169
            },
            "Manokwari": {
                "lat": -0.8667,
                "lng": 134.0833
            },
            "Tenggarong": {
                "lat": -0.4,
                "lng": 116.9667
            },
            "Raba": {
                "lat": -8.4614,
                "lng": 118.7469
            },
            "Biak": {
                "lat": -1.18,
                "lng": 136.08
            },
            "Tanjungpandan": {
                "lat": -2.75,
                "lng": 107.75
            },
            "Sungai Penuh": {
                "lat": -2.0589,
                "lng": 101.3914
            },
            "Sungailiat": {
                "lat": -1.8561,
                "lng": 106.1192
            },
            "Nabire": {
                "lat": -3.3682,
                "lng": 135.4964
            },
            "Ciamis": {
                "lat": -7.3281,
                "lng": 108.3336
            },
            "Sumber": {
                "lat": -6.7544,
                "lng": 108.4858
            },
            "Tomohon": {
                "lat": 1.325,
                "lng": 124.8389
            },
            "Sibolga": {
                "lat": 1.74,
                "lng": 98.78
            },
            "Simeulu": {
                "lat": 2.63,
                "lng": 96.09
            },
            "Lawang": {
                "lat": -7.83,
                "lng": 112.7
            },
            "Tual": {
                "lat": -5.6368,
                "lng": 132.7508
            },
            "Tatakan": {
                "lat": -6.1116,
                "lng": 106.1036
            },
            "Sepatan": {
                "lat": -6.1143,
                "lng": 106.5784
            },
            "Cisauk": {
                "lat": -6.3333,
                "lng": 106.65
            },
            "Pamekasan": {
                "lat": -7.1574,
                "lng": 113.4718
            },
            "Maumere": {
                "lat": -8.6222,
                "lng": 122.2122
            },
            "Ende": {
                "lat": -8.8333,
                "lng": 121.65
            },
            "Karanganyar": {
                "lat": -7.6033,
                "lng": 110.9778
            },
            "Bangkalan": {
                "lat": -7.0253,
                "lng": 112.7519
            },
            "Pariaman": {
                "lat": -0.6264,
                "lng": 100.1178
            },
            "Sumenep": {
                "lat": -7.0049,
                "lng": 113.8496
            },
            "Atambua": {
                "lat": -9.1061,
                "lng": 124.8925
            },
            "Jepara": {
                "lat": -6.5333,
                "lng": 110.6667
            },
            "Watampone": {
                "lat": -4.5386,
                "lng": 120.3297
            },
            "Subulussalam": {
                "lat": 2.6422,
                "lng": 98.0042
            },
            "Toboali": {
                "lat": -2.9997,
                "lng": 106.4656
            },
            "Mandiraja Kulon": {
                "lat": -7.4722,
                "lng": 109.5133
            },
            "Tuban": {
                "lat": -6.9,
                "lng": 112.05
            },
            "Solok": {
                "lat": -0.7997,
                "lng": 100.6661
            },
            "Sentani": {
                "lat": -2.5636,
                "lng": 140.5146
            },
            "Ubud": {
                "lat": -8.5069,
                "lng": 115.2624
            },
            "Majalengka": {
                "lat": -6.8353,
                "lng": 108.2278
            },
            "Bagan Si Api-api": {
                "lat": 2.1667,
                "lng": 100.8167
            },
            "Waingapu": {
                "lat": -9.65,
                "lng": 120.2667
            },
            "Raha Tiga": {
                "lat": -4.8311,
                "lng": 122.7239
            },
            "Raha": {
                "lat": -4.838,
                "lng": 122.7209
            },
            "Kampung Tengah": {
                "lat": 1.4836,
                "lng": 101.9561
            },
            "Bengkalis": {
                "lat": 1.4897,
                "lng": 102.0797
            },
            "Lahat": {
                "lat": -3.8,
                "lng": 103.5333
            },
            "Wamena": {
                "lat": -4.0975,
                "lng": 138.9511
            },
            "Singaparna": {
                "lat": -7.3497,
                "lng": 108.1106
            },
            "Sumbawa Besar": {
                "lat": -8.5,
                "lng": 117.4167
            },
            "Meulaboh": {
                "lat": 4.1333,
                "lng": 96.1167
            },
            "Ambarawa": {
                "lat": -7.2667,
                "lng": 110.4
            },
            "Sawahlunto": {
                "lat": -0.6667,
                "lng": 100.7833
            },
            "Kresek": {
                "lat": -6.1314,
                "lng": 106.38
            },
            "Bebandem": {
                "lat": -8.4046,
                "lng": 115.5395
            },
            "Barabai": {
                "lat": -2.5833,
                "lng": 115.3833
            },
            "Kuala Kapuas": {
                "lat": -3.0013,
                "lng": 114.3916
            },
            "Taliwang": {
                "lat": -8.7336,
                "lng": 116.8667
            },
            "Kronjo": {
                "lat": -6.0667,
                "lng": 106.4167
            },
            "Dompu": {
                "lat": -8.5364,
                "lng": 118.4633
            },
            "Karak": {
                "lat": -0.5298,
                "lng": 101.5721
            },
            "Padangpanjang": {
                "lat": -0.45,
                "lng": 100.4167
            },
            "Menggala": {
                "lat": -4.475,
                "lng": 105.2414
            },
            "Gombong": {
                "lat": -7.6,
                "lng": 109.5167
            },
            "Kandangan": {
                "lat": -2.7833,
                "lng": 115.2667
            },
            "Kefamenanu": {
                "lat": -9.4467,
                "lng": 124.4781
            },
            "Tunglangan": {
                "lat": -8.4167,
                "lng": 115.3
            },
            "Berastagi": {
                "lat": 3.1833,
                "lng": 98.5167
            },
            "Luwuk": {
                "lat": -0.9396,
                "lng": 122.79
            },
            "Amahai": {
                "lat": -3.3331,
                "lng": 128.919
            },
            "Poso": {
                "lat": -1.4,
                "lng": 120.75
            },
            "Muara Teweh": {
                "lat": -0.9535,
                "lng": 114.8981
            },
            "Parigi": {
                "lat": -7.6914,
                "lng": 108.5372
            },
            "Barru": {
                "lat": -4.3587,
                "lng": 119.6399
            },
            "Pemangkat": {
                "lat": 1.1768,
                "lng": 108.9624
            },
            "Kaimana": {
                "lat": -3.6444,
                "lng": 133.6953
            },
            "Lubuk Sikaping": {
                "lat": 0.15,
                "lng": 100.1667
            },
            "Cimerak": {
                "lat": -7.7422,
                "lng": 108.4244
            },
            "Rantepao": {
                "lat": -2.969,
                "lng": 119.9
            },
            "Kotabumi": {
                "lat": -4.8333,
                "lng": 104.9
            },
            "Larantuka": {
                "lat": -8.3133,
                "lng": 123.0042
            },
            "Amuntai": {
                "lat": -2.4177,
                "lng": 115.2494
            },
            "Ruteng": {
                "lat": -8.6127,
                "lng": 120.463
            },
            "Betun": {
                "lat": -9.4953,
                "lng": 124.9603
            },
            "Soe": {
                "lat": -9.8667,
                "lng": 124.2833
            },
            "Kualaserba": {
                "lat": 4.2333,
                "lng": 97.8667
            },
            "Ampana": {
                "lat": -0.8667,
                "lng": 121.5833
            },
            "Leramatang": {
                "lat": -8.4,
                "lng": 123.57
            },
            "Jambe": {
                "lat": -6.316,
                "lng": 106.4853
            },
            "Masamba": {
                "lat": -2.5531,
                "lng": 120.3275
            },
            "Manggar": {
                "lat": -2.89,
                "lng": 108.27
            },
            "Cakung": {
                "lat": -6.2138,
                "lng": 106.9473
            },
            "Sabang": {
                "lat": 5.8942,
                "lng": 95.3192
            },
            "Mantingan": {
                "lat": -7.3682,
                "lng": 111.1644
            },
            "Namlea": {
                "lat": -3.25,
                "lng": 127.0833
            },
            "Bangkinang": {
                "lat": 0.35,
                "lng": 101.0333
            },
            "Bayur": {
                "lat": -0.261,
                "lng": 100.222
            },
            "Pande": {
                "lat": -8.684,
                "lng": 115.2264
            },
            "Saparua": {
                "lat": -3.5749,
                "lng": 128.647
            },
            "Tambolaka": {
                "lat": -9.4333,
                "lng": 119.2333
            },
            "Sungaiselam": {
                "lat": -2.3839,
                "lng": 105.98
            },
            "Tahuna": {
                "lat": 3.6119,
                "lng": 125.5008
            },
            "Praya": {
                "lat": -8.7223,
                "lng": 116.2923
            },
            "Merauke": {
                "lat": -8.4932,
                "lng": 140.4018
            },
            "Pangururan": {
                "lat": 2.6075,
                "lng": 98.6959
            },
            "Tobelo": {
                "lat": 1.7319,
                "lng": 128.0078
            },
            "Waikabubak": {
                "lat": -9.6358,
                "lng": 119.4131
            },
            "Sanana": {
                "lat": -2.0545,
                "lng": 125.9754
            },
            "Airmadidi": {
                "lat": 1.4333,
                "lng": 124.9833
            },
            "Kulat": {
                "lat": -8.8243,
                "lng": 115.0983
            },
            "Ranai": {
                "lat": 3.9333,
                "lng": 108.3833
            },
            "Rappang": {
                "lat": -3.8447,
                "lng": 119.8205
            },
            "Bungku": {
                "lat": -2.5333,
                "lng": 121.9667
            },
            "Tegalbuleud": {
                "lat": -7.3578,
                "lng": 106.622
            },
            "Bintuni": {
                "lat": -2.1167,
                "lng": 133.5333
            },
            "Tapaktuan": {
                "lat": 3.25,
                "lng": 97.1667
            },
            "Tembagapura": {
                "lat": -4.1428,
                "lng": 137.0906
            },
            "Blangmangat": {
                "lat": 5.1881,
                "lng": 97.1403
            },
            "Puruk Cahu": {
                "lat": -0.6384,
                "lng": 114.5677
            },
            "Kuala Pembuang": {
                "lat": -3.3871,
                "lng": 112.5434
            },
            "Teminabuan": {
                "lat": -1.4333,
                "lng": 132.0167
            },
            "Fakfak": {
                "lat": -2.9167,
                "lng": 132.3
            },
            "Kalabahi": {
                "lat": -8.2167,
                "lng": 124.5167
            },
            "Kasongan": {
                "lat": -1.8959,
                "lng": 113.3878
            },
            "Kolonodale": {
                "lat": -1.9833,
                "lng": 121.3333
            },
            "Buntok": {
                "lat": -1.719,
                "lng": 114.8448
            },
            "Piru": {
                "lat": -3.0811,
                "lng": 128.1411
            },
            "Ransiki": {
                "lat": -1.5,
                "lng": 134.1667
            },
            "Melonguane": {
                "lat": 4.0045,
                "lng": 126.6827
            },
            "Cipanas": {
                "lat": -6.733,
                "lng": 107.041
            },
            "Painan": {
                "lat": -1.3511,
                "lng": 100.5756
            },
            "Sungai Guntung": {
                "lat": 0.2956,
                "lng": 103.6114
            },
            "Serui": {
                "lat": -1.878,
                "lng": 136.237
            },
            "Kuala Kurun": {
                "lat": -1.1016,
                "lng": 113.8664
            },
            "Maba": {
                "lat": 0.7,
                "lng": 128.3
            },
            "Pajo": {
                "lat": -8.5167,
                "lng": 120.3333
            },
            "Putussibau": {
                "lat": 0.8575,
                "lng": 112.925
            },
            "Ban": {
                "lat": -8.2333,
                "lng": 115.4833
            },
            "Sonosari": {
                "lat": -7.8014,
                "lng": 110.3644
            },
            "Kehen": {
                "lat": -8.5589,
                "lng": 115.2869
            },
            "Tentena": {
                "lat": -1.7822,
                "lng": 120.6494
            },
            "Tibubeneng": {
                "lat": -8.6469,
                "lng": 115.1503
            },
            "Tamiang Layang": {
                "lat": -2.116,
                "lng": 115.1691
            },
            "Kelandis": {
                "lat": -8.6595,
                "lng": 115.227
            },
            "Haria": {
                "lat": -3.5833,
                "lng": 128.6167
            },
            "Makale": {
                "lat": -3.1,
                "lng": 119.85
            },
            "Karimunjawa": {
                "lat": -5.8192,
                "lng": 110.459
            },
            "Jantho": {
                "lat": 5.3,
                "lng": 95.6333
            },
            "Tlagasana": {
                "lat": -7.1786,
                "lng": 109.5057
            },
            "Galela": {
                "lat": 1.8236,
                "lng": 127.8431
            },
            "Slateng Dua": {
                "lat": -8.1324,
                "lng": 113.9836
            },
            "Sofifi": {
                "lat": 0.7244,
                "lng": 127.5806
            },
            "Tarutung": {
                "lat": 2.0167,
                "lng": 98.9667
            },
            "Buton": {
                "lat": 4.217,
                "lng": 108.2
            },
            "Tanjung Selor": {
                "lat": 2.85,
                "lng": 117.3833
            }
        }
    },
    "IN": {
        "name": "India",
        "cities": {
            "Delhi": {
                "lat": 28.61,
                "lng": 77.23
            },
            "Mumbai": {
                "lat": 19.0761,
                "lng": 72.8775
            },
            "Kolk\u0101ta": {
                "lat": 22.5675,
                "lng": 88.37
            },
            "Bangalore": {
                "lat": 12.9789,
                "lng": 77.5917
            },
            "Chennai": {
                "lat": 13.0825,
                "lng": 80.275
            },
            "Hyder\u0101b\u0101d": {
                "lat": 17.3617,
                "lng": 78.4747
            },
            "Pune": {
                "lat": 18.5203,
                "lng": 73.8567
            },
            "Ahmedabad": {
                "lat": 23.0225,
                "lng": 72.5714
            },
            "S\u016brat": {
                "lat": 21.1702,
                "lng": 72.8311
            },
            "Lucknow": {
                "lat": 26.85,
                "lng": 80.95
            },
            "Jaipur": {
                "lat": 23.4313,
                "lng": 86.1493
            },
            "Kanpur": {
                "lat": 26.4499,
                "lng": 80.3319
            },
            "Mirz\u0101pur": {
                "lat": 26.1616,
                "lng": 87.2349
            },
            "N\u0101gpur": {
                "lat": 21.1497,
                "lng": 79.0806
            },
            "Gh\u0101zi\u0101b\u0101d": {
                "lat": 28.67,
                "lng": 77.42
            },
            "Supaul": {
                "lat": 25.9469,
                "lng": 86.2497
            },
            "Vadodara": {
                "lat": 22.3,
                "lng": 73.2
            },
            "R\u0101jkot": {
                "lat": 22.3,
                "lng": 70.7833
            },
            "Vish\u0101khapatnam": {
                "lat": 17.7042,
                "lng": 83.2978
            },
            "Indore": {
                "lat": 22.7167,
                "lng": 75.8472
            },
            "Th\u0101ne": {
                "lat": 19.1972,
                "lng": 72.9722
            },
            "Bhop\u0101l": {
                "lat": 23.2599,
                "lng": 77.4126
            },
            "Pimpri-Chinchwad": {
                "lat": 18.6186,
                "lng": 73.8037
            },
            "Patna": {
                "lat": 25.594,
                "lng": 85.1376
            },
            "Bil\u0101spur": {
                "lat": 30.32,
                "lng": 77.32
            },
            "Ludhi\u0101na": {
                "lat": 30.91,
                "lng": 75.85
            },
            "\u0100gra": {
                "lat": 27.18,
                "lng": 78.02
            },
            "Madurai": {
                "lat": 9.9252,
                "lng": 78.1198
            },
            "Jamshedpur": {
                "lat": 22.7925,
                "lng": 86.1842
            },
            "Prayagraj": {
                "lat": 25.4358,
                "lng": 81.8464
            },
            "N\u0101sik": {
                "lat": 19.9975,
                "lng": 73.7898
            },
            "Far\u012bd\u0101b\u0101d": {
                "lat": 28.4211,
                "lng": 77.3078
            },
            "Meerut": {
                "lat": 28.98,
                "lng": 77.71
            },
            "Jabalpur": {
                "lat": 23.1667,
                "lng": 79.9333
            },
            "Kaly\u0101n": {
                "lat": 19.2502,
                "lng": 73.1602
            },
            "Vasai-Virar": {
                "lat": 19.3607,
                "lng": 72.7956
            },
            "Najafgarh": {
                "lat": 28.6092,
                "lng": 76.9798
            },
            "V\u0101r\u0101nasi": {
                "lat": 25.3189,
                "lng": 83.0128
            },
            "Sr\u012bnagar": {
                "lat": 25.9823,
                "lng": 86.6662
            },
            "Aurang\u0101b\u0101d": {
                "lat": 27.894,
                "lng": 77.369
            },
            "Dhanb\u0101d": {
                "lat": 23.7998,
                "lng": 86.4305
            },
            "Amritsar": {
                "lat": 31.64,
                "lng": 74.86
            },
            "Al\u012bgarh": {
                "lat": 27.88,
                "lng": 78.08
            },
            "Guw\u0101h\u0101ti": {
                "lat": 26.1722,
                "lng": 91.7458
            },
            "H\u0101ora": {
                "lat": 22.58,
                "lng": 88.3294
            },
            "R\u0101nchi": {
                "lat": 23.36,
                "lng": 85.33
            },
            "Gwalior": {
                "lat": 26.2124,
                "lng": 78.1772
            },
            "Chand\u012bgarh": {
                "lat": 30.75,
                "lng": 76.78
            },
            "Haldw\u0101ni": {
                "lat": 29.22,
                "lng": 79.52
            },
            "Vijayav\u0101da": {
                "lat": 16.5193,
                "lng": 80.6305
            },
            "Jodhpur": {
                "lat": 21.88,
                "lng": 70.03
            },
            "Raipur": {
                "lat": 25.4226,
                "lng": 86.8498
            },
            "Kota": {
                "lat": 14.0333,
                "lng": 80.05
            },
            "Bareilly": {
                "lat": 28.364,
                "lng": 79.415
            },
            "Coimbatore": {
                "lat": 11.0167,
                "lng": 76.9556
            },
            "Sol\u0101pur": {
                "lat": 17.68,
                "lng": 75.92
            },
            "Hubli": {
                "lat": 15.355,
                "lng": 75.1362
            },
            "Trichinopoly": {
                "lat": 10.7903,
                "lng": 78.7047
            },
            "Mor\u0101d\u0101b\u0101d": {
                "lat": 28.8319,
                "lng": 78.7764
            },
            "Tirupp\u016br": {
                "lat": 11.1085,
                "lng": 77.3411
            },
            "Gurgaon": {
                "lat": 28.456,
                "lng": 77.029
            },
            "Jalandhar": {
                "lat": 31.2569,
                "lng": 75.4432
            },
            "Bhubaneshwar": {
                "lat": 20.2644,
                "lng": 85.8281
            },
            "Bhayandar": {
                "lat": 19.29,
                "lng": 72.85
            },
            "Pati\u0101la": {
                "lat": 30.34,
                "lng": 76.38
            },
            "Sh\u012bshgarh": {
                "lat": 28.72,
                "lng": 79.32
            },
            "Thiruvananthapuram": {
                "lat": 8.5241,
                "lng": 76.9366
            },
            "Bhiwandi": {
                "lat": 19.2967,
                "lng": 73.0631
            },
            "Sah\u0101ranpur": {
                "lat": 29.964,
                "lng": 77.546
            },
            "Warangal": {
                "lat": 17.9689,
                "lng": 79.5941
            },
            "Shiliguri": {
                "lat": 26.71,
                "lng": 88.43
            },
            "Salem": {
                "lat": 11.65,
                "lng": 78.15
            },
            "Kochi": {
                "lat": 9.9312,
                "lng": 76.2673
            },
            "Dh\u016blia": {
                "lat": 20.8997,
                "lng": 74.7697
            },
            "Gorakhpur": {
                "lat": 29.4467,
                "lng": 75.6724
            },
            "Gunt\u016br": {
                "lat": 16.3008,
                "lng": 80.4428
            },
            "Bh\u0101ngar": {
                "lat": 22.58,
                "lng": 88.42
            },
            "Narela": {
                "lat": 28.8527,
                "lng": 77.0929
            },
            "Karaikandi": {
                "lat": 24.8309,
                "lng": 92.6143
            },
            "Bh\u0101vnagar": {
                "lat": 21.76,
                "lng": 72.15
            },
            "Noida": {
                "lat": 28.57,
                "lng": 77.32
            },
            "Bhilai": {
                "lat": 21.21,
                "lng": 81.38
            },
            "Mangalore": {
                "lat": 12.8681,
                "lng": 74.8425
            },
            "Biht\u0101": {
                "lat": 25.5619,
                "lng": 84.87
            },
            "Cuttack": {
                "lat": 20.5236,
                "lng": 85.7881
            },
            "Sal\u012bmpur": {
                "lat": 25.248,
                "lng": 86.2347
            },
            "Raurkela": {
                "lat": 22.2492,
                "lng": 84.8828
            },
            "Tumk\u016br": {
                "lat": 13.34,
                "lng": 77.1
            },
            "Tenk\u0101si": {
                "lat": 8.9564,
                "lng": 77.3152
            },
            "Dehra D\u016bn": {
                "lat": 30.345,
                "lng": 78.029
            },
            "Kottayam": {
                "lat": 9.5916,
                "lng": 76.5222
            },
            "Durg\u0101pur": {
                "lat": 23.55,
                "lng": 87.32
            },
            "\u0100sansol": {
                "lat": 23.68,
                "lng": 86.99
            },
            "Bokaro Steel City": {
                "lat": 23.67,
                "lng": 86.15
            },
            "Kolh\u0101pur": {
                "lat": 16.6917,
                "lng": 74.2333
            },
            "Nava Raipur": {
                "lat": 21.161,
                "lng": 81.787
            },
            "Ajmer": {
                "lat": 26.4499,
                "lng": 74.6399
            },
            "N\u0101nded": {
                "lat": 19.15,
                "lng": 77.3
            },
            "Amr\u0101vati": {
                "lat": 20.9258,
                "lng": 77.7647
            },
            "Nellore": {
                "lat": 14.45,
                "lng": 79.99
            },
            "Dispur": {
                "lat": 26.1397,
                "lng": 91.7925
            },
            "Gulbarga": {
                "lat": 17.329,
                "lng": 76.825
            },
            "B\u012bkaner": {
                "lat": 28.0181,
                "lng": 73.3169
            },
            "N\u0101gercoil": {
                "lat": 8.175,
                "lng": 77.4306
            },
            "Agartala": {
                "lat": 23.8314,
                "lng": 91.2869
            },
            "Loni": {
                "lat": 28.75,
                "lng": 77.28
            },
            "Ujjain": {
                "lat": 23.17,
                "lng": 75.79
            },
            "Bh\u012blw\u0101ra": {
                "lat": 25.35,
                "lng": 74.63
            },
            "Jh\u0101nsi": {
                "lat": 25.4486,
                "lng": 78.5696
            },
            "Ulh\u0101snagar": {
                "lat": 19.22,
                "lng": 73.15
            },
            "Vellore": {
                "lat": 12.9165,
                "lng": 79.1325
            },
            "Jammu": {
                "lat": 32.73,
                "lng": 74.87
            },
            "Purnea": {
                "lat": 25.778,
                "lng": 87.476
            },
            "Belgaum": {
                "lat": 15.85,
                "lng": 74.5
            },
            "M\u0101legaon": {
                "lat": 20.55,
                "lng": 74.55
            },
            "J\u0101mnagar": {
                "lat": 22.47,
                "lng": 70.07
            },
            "B\u0101rdoli": {
                "lat": 21.12,
                "lng": 73.12
            },
            "Gaya": {
                "lat": 24.75,
                "lng": 85.01
            },
            "Ambatt\u016br": {
                "lat": 13.1143,
                "lng": 80.1548
            },
            "Jalgaon": {
                "lat": 21.004,
                "lng": 75.568
            },
            "Chhatarpur": {
                "lat": 28.4565,
                "lng": 77.0032
            },
            "Kurnool": {
                "lat": 15.83,
                "lng": 78.05
            },
            "Udaipur": {
                "lat": 27.7289,
                "lng": 75.4716
            },
            "Mathura": {
                "lat": 27.4924,
                "lng": 77.6737
            },
            "S\u0101ngli": {
                "lat": 16.853,
                "lng": 74.583
            },
            "Davangere": {
                "lat": 14.4666,
                "lng": 75.9242
            },
            "Calicut": {
                "lat": 11.2588,
                "lng": 75.7804
            },
            "B\u0101r\u0101mati": {
                "lat": 18.15,
                "lng": 74.58
            },
            "Moth\u012bh\u0101ri": {
                "lat": 26.65,
                "lng": 84.9167
            },
            "Akola": {
                "lat": 27.0656,
                "lng": 77.8808
            },
            "Bettiah": {
                "lat": 26.8014,
                "lng": 84.5028
            },
            "Shor\u0101pur": {
                "lat": 16.52,
                "lng": 76.76
            },
            "Tinnevelly": {
                "lat": 8.7136,
                "lng": 77.7567
            },
            "Bellary": {
                "lat": 15.1,
                "lng": 76.9167
            },
            "Bh\u0101galpur": {
                "lat": 25.25,
                "lng": 87.0
            },
            "Padi\u0101la": {
                "lat": 30.802,
                "lng": 76.593
            },
            "Delhi Cantonment": {
                "lat": 28.6,
                "lng": 77.1333
            },
            "Salt Lake City": {
                "lat": 22.61,
                "lng": 88.4
            },
            "Et\u0101wa": {
                "lat": 24.18,
                "lng": 78.2
            },
            "Son\u012bpat": {
                "lat": 28.99,
                "lng": 77.022
            },
            "Quilon": {
                "lat": 8.8932,
                "lng": 76.6141
            },
            "Mulangodi": {
                "lat": 9.0,
                "lng": 76.6167
            },
            "Munro Turuttu": {
                "lat": 9.0,
                "lng": 76.6167
            },
            "Bairia": {
                "lat": 26.7373,
                "lng": 84.4371
            },
            "Bh\u0101tp\u0101ra": {
                "lat": 22.87,
                "lng": 88.41
            },
            "K\u016bkatpalli": {
                "lat": 17.4849,
                "lng": 78.4138
            },
            "Sir\u016br": {
                "lat": 16.0965,
                "lng": 75.7858
            },
            "K\u0101kin\u0101da": {
                "lat": 16.9661,
                "lng": 82.255
            },
            "P\u0101nih\u0101ti": {
                "lat": 22.69,
                "lng": 88.37
            },
            "Rohtak": {
                "lat": 28.8909,
                "lng": 76.5796
            },
            "M\u0101ler Kotla": {
                "lat": 30.5167,
                "lng": 75.8833
            },
            "Kolga": {
                "lat": 22.35,
                "lng": 82.68
            },
            "Korba": {
                "lat": 22.3458,
                "lng": 82.6963
            },
            "S\u012bkar": {
                "lat": 27.62,
                "lng": 75.15
            },
            "Kar\u016br": {
                "lat": 10.9601,
                "lng": 78.0766
            },
            "Sasar\u0101m": {
                "lat": 24.95,
                "lng": 84.03
            },
            "Karn\u0101l": {
                "lat": 29.686,
                "lng": 76.989
            },
            "Brahmapur": {
                "lat": 19.315,
                "lng": 84.7941
            },
            "Ch\u0101nda": {
                "lat": 19.95,
                "lng": 79.3
            },
            "Ahmadnagar": {
                "lat": 19.08,
                "lng": 74.73
            },
            "D\u0101sarhalli": {
                "lat": 13.0465,
                "lng": 77.513
            },
            "Sh\u0101hj\u0101npur": {
                "lat": 27.88,
                "lng": 79.91
            },
            "Alam\u0101di": {
                "lat": 13.1097,
                "lng": 80.0972
            },
            "\u0100vadi": {
                "lat": 13.1147,
                "lng": 80.1098
            },
            "Kh\u0101n\u0101pur": {
                "lat": 17.9004,
                "lng": 79.9277
            },
            "Hos\u016br": {
                "lat": 12.7409,
                "lng": 77.8253
            },
            "Cuddapah": {
                "lat": 14.47,
                "lng": 78.82
            },
            "R\u0101jahmundry": {
                "lat": 16.98,
                "lng": 81.78
            },
            "Alwar": {
                "lat": 27.5498,
                "lng": 76.6355
            },
            "Sambalpur": {
                "lat": 21.47,
                "lng": 83.97
            },
            "Muzaffarpur": {
                "lat": 26.1225,
                "lng": 85.3906
            },
            "K\u0101m\u0101rh\u0101ti": {
                "lat": 22.6686,
                "lng": 88.3704
            },
            "Camp\u012bernagar": {
                "lat": 27.029,
                "lng": 83.2668
            },
            "Bij\u0101pur": {
                "lat": 16.83,
                "lng": 75.71
            },
            "Thar\u0101d": {
                "lat": 24.3926,
                "lng": 71.6248
            },
            "Ratn\u0101giri": {
                "lat": 16.9944,
                "lng": 73.3
            },
            "R\u0101mpur": {
                "lat": 25.9256,
                "lng": 84.7468
            },
            "Shimoga": {
                "lat": 13.9333,
                "lng": 75.5667
            },
            "K\u0101sh\u012bpur": {
                "lat": 29.22,
                "lng": 78.95
            },
            "J\u016bn\u0101gadh": {
                "lat": 21.5222,
                "lng": 70.4579
            },
            "H\u0101pur": {
                "lat": 28.7309,
                "lng": 77.7757
            },
            "Trich\u016br": {
                "lat": 10.5276,
                "lng": 76.2144
            },
            "Barddham\u0101n": {
                "lat": 23.2333,
                "lng": 87.8667
            },
            "Panvel": {
                "lat": 18.9944,
                "lng": 73.1139
            },
            "Niz\u0101m\u0101b\u0101d": {
                "lat": 18.672,
                "lng": 78.094
            },
            "Parbhani": {
                "lat": 19.27,
                "lng": 76.78
            },
            "Hisar": {
                "lat": 26.5495,
                "lng": 85.9406
            },
            "Fatehpur": {
                "lat": 25.3032,
                "lng": 87.8623
            },
            "Oulgaret": {
                "lat": 11.957,
                "lng": 79.7737
            },
            "Ingr\u0101j B\u0101z\u0101r": {
                "lat": 25.0119,
                "lng": 88.1433
            },
            "Bada Barab\u012bl": {
                "lat": 22.12,
                "lng": 85.4
            },
            "Dod Ball\u0101pur": {
                "lat": 13.292,
                "lng": 77.543
            },
            "Bih\u0101r": {
                "lat": 25.197,
                "lng": 85.518
            },
            "Darbhanga": {
                "lat": 26.17,
                "lng": 85.9
            },
            "Sikandar\u0101b\u0101d": {
                "lat": 28.4512,
                "lng": 77.6955
            },
            "P\u0101n\u012bpat": {
                "lat": 29.3875,
                "lng": 76.97
            },
            "\u0100\u012bzawl": {
                "lat": 23.7272,
                "lng": 92.7178
            },
            "B\u0101li": {
                "lat": 22.65,
                "lng": 88.34
            },
            "Palni": {
                "lat": 10.45,
                "lng": 77.5161
            },
            "Surajgarha": {
                "lat": 25.25,
                "lng": 86.23
            },
            "Kar\u012bmnagar": {
                "lat": 18.4386,
                "lng": 79.1288
            },
            "Dew\u0101s": {
                "lat": 22.96,
                "lng": 76.06
            },
            "Hugli": {
                "lat": 22.9,
                "lng": 88.39
            },
            "Chunchura": {
                "lat": 22.9,
                "lng": 88.39
            },
            "Sonpur": {
                "lat": 25.7,
                "lng": 85.18
            },
            "Ichalkaranji": {
                "lat": 16.7,
                "lng": 74.47
            },
            "Tirupati": {
                "lat": 13.65,
                "lng": 79.42
            },
            "Bhatinda": {
                "lat": 30.23,
                "lng": 74.9519
            },
            "J\u0101lna": {
                "lat": 19.8333,
                "lng": 75.8833
            },
            "Satna": {
                "lat": 24.6005,
                "lng": 80.8322
            },
            "Sannai": {
                "lat": 24.1582,
                "lng": 80.751
            },
            "Mau": {
                "lat": 26.2658,
                "lng": 78.6711
            },
            "B\u0101r\u0101sat": {
                "lat": 22.72,
                "lng": 88.48
            },
            "Farrukh\u0101b\u0101d": {
                "lat": 27.39,
                "lng": 79.581
            },
            "Saugor": {
                "lat": 23.83,
                "lng": 78.71
            },
            "Ratl\u0101m": {
                "lat": 23.334,
                "lng": 75.037
            },
            "Chopda": {
                "lat": 21.25,
                "lng": 75.3
            },
            "Handw\u0101ra": {
                "lat": 34.3997,
                "lng": 74.2817
            },
            "Drug": {
                "lat": 21.19,
                "lng": 81.28
            },
            "Anantapur": {
                "lat": 14.68,
                "lng": 77.599
            },
            "Imph\u0101l": {
                "lat": 24.8074,
                "lng": 93.9384
            },
            "Jagdalpur": {
                "lat": 19.18,
                "lng": 81.92
            },
            "Arrah": {
                "lat": 25.5514,
                "lng": 84.6603
            },
            "Et\u0101wah": {
                "lat": 26.77,
                "lng": 79.03
            },
            "Malk\u0101jgiri": {
                "lat": 17.4519,
                "lng": 78.5362
            },
            "Tuni": {
                "lat": 17.35,
                "lng": 82.55
            },
            "R\u0101par": {
                "lat": 23.57,
                "lng": 70.63
            },
            "Sam\u0101stipur": {
                "lat": 25.8629,
                "lng": 85.7811
            },
            "Bharatpur": {
                "lat": 27.22,
                "lng": 77.48
            },
            "Begusarai": {
                "lat": 25.42,
                "lng": 86.13
            },
            "J\u016bn\u0101garh": {
                "lat": 19.8599,
                "lng": 82.9338
            },
            "Nedumana": {
                "lat": 8.9139,
                "lng": 76.6889
            },
            "New Delhi": {
                "lat": 28.6139,
                "lng": 77.2089
            },
            "Tiruvottiy\u016br": {
                "lat": 13.16,
                "lng": 80.3
            },
            "R\u0101mnagar": {
                "lat": 32.8073,
                "lng": 75.3112
            },
            "G\u0101ndh\u012bdh\u0101m": {
                "lat": 23.08,
                "lng": 70.13
            },
            "Sal\u016bmbar": {
                "lat": 24.08,
                "lng": 74.02
            },
            "Krishnar\u0101jpur": {
                "lat": 13.012,
                "lng": 77.6951
            },
            "Nelamangala": {
                "lat": 13.102,
                "lng": 77.374
            },
            "Puducherry": {
                "lat": 11.9167,
                "lng": 79.8167
            },
            "R\u0101mgundam": {
                "lat": 18.7639,
                "lng": 79.475
            },
            "P\u0101lanpur": {
                "lat": 24.1722,
                "lng": 72.4333
            },
            "Pandharpur": {
                "lat": 17.6778,
                "lng": 75.3278
            },
            "Masaurhi Buzurg": {
                "lat": 25.35,
                "lng": 85.02
            },
            "Katih\u0101r": {
                "lat": 25.53,
                "lng": 87.58
            },
            "Byatarayanpur": {
                "lat": 13.0659,
                "lng": 77.5922
            },
            "Ahor": {
                "lat": 25.367,
                "lng": 72.783
            },
            "Tuticorin": {
                "lat": 8.7642,
                "lng": 78.1348
            },
            "Gang\u0101nagar": {
                "lat": 29.92,
                "lng": 73.88
            },
            "Tinkhang": {
                "lat": 27.23,
                "lng": 95.17
            },
            "Rewa": {
                "lat": 24.53,
                "lng": 81.3
            },
            "Uluberiya": {
                "lat": 22.47,
                "lng": 88.11
            },
            "Bulandshahr": {
                "lat": 28.4069,
                "lng": 77.8497
            },
            "Chauhanpatti": {
                "lat": 25.15,
                "lng": 82.6
            },
            "Cannanore": {
                "lat": 11.8745,
                "lng": 75.3704
            },
            "R\u0101ich\u016br": {
                "lat": 16.2,
                "lng": 77.37
            },
            "Machil\u012bpatnam": {
                "lat": 16.17,
                "lng": 81.13
            },
            "P\u0101li": {
                "lat": 26.0576,
                "lng": 86.3097
            },
            "Songadh": {
                "lat": 21.167,
                "lng": 73.564
            },
            "Gadda Madiral": {
                "lat": 18.7519,
                "lng": 79.5133
            },
            "Miri\u0101lg\u016bda": {
                "lat": 16.8667,
                "lng": 79.5833
            },
            "Haridw\u0101r": {
                "lat": 29.945,
                "lng": 78.163
            },
            "Vizianagaram": {
                "lat": 18.1159,
                "lng": 83.406
            },
            "R\u0101isinghnagar": {
                "lat": 29.5342,
                "lng": 73.4477
            },
            "P\u0101thardi": {
                "lat": 19.17,
                "lng": 75.18
            },
            "Mel P\u0101laiyam": {
                "lat": 8.7,
                "lng": 77.7069
            },
            "Khat\u012bma": {
                "lat": 28.92,
                "lng": 79.97
            },
            "Petl\u0101d": {
                "lat": 22.47,
                "lng": 72.8
            },
            "Nadi\u0101d": {
                "lat": 22.69,
                "lng": 72.86
            },
            "Tanjore": {
                "lat": 10.787,
                "lng": 79.1378
            },
            "Kichha": {
                "lat": 28.92,
                "lng": 79.5
            },
            "Katri": {
                "lat": 23.48,
                "lng": 80.4
            },
            "Dagarua": {
                "lat": 25.8,
                "lng": 87.64
            },
            "Sambhal": {
                "lat": 28.58,
                "lng": 78.55
            },
            "Singrauliya": {
                "lat": 24.202,
                "lng": 82.666
            },
            "Naih\u0101ti": {
                "lat": 22.89,
                "lng": 88.42
            },
            "Porbandar": {
                "lat": 21.6417,
                "lng": 69.6293
            },
            "Yamun\u0101nagar": {
                "lat": 30.133,
                "lng": 77.288
            },
            "Pall\u0101varam": {
                "lat": 12.9675,
                "lng": 80.1491
            },
            "Khargone": {
                "lat": 21.82,
                "lng": 75.6187
            },
            "Secunder\u0101b\u0101d": {
                "lat": 17.4399,
                "lng": 78.4983
            },
            "Bhuj": {
                "lat": 23.25,
                "lng": 69.67
            },
            "Monghyr": {
                "lat": 25.381,
                "lng": 86.465
            },
            "Ch\u0101pra": {
                "lat": 25.7848,
                "lng": 84.7274
            },
            "Bhadr\u0101vati": {
                "lat": 13.8485,
                "lng": 75.705
            },
            "Panchkula": {
                "lat": 30.74,
                "lng": 76.8
            },
            "Burh\u0101npur": {
                "lat": 21.3114,
                "lng": 76.2289
            },
            "Kir\u0101tot": {
                "lat": 32.8376,
                "lng": 76.2296
            },
            "K\u0101pra": {
                "lat": 17.4859,
                "lng": 78.5651
            },
            "Amb\u0101la": {
                "lat": 17.65,
                "lng": 79.05
            },
            "Kharagpur": {
                "lat": 25.1245,
                "lng": 86.5558
            },
            "Robertsonpet": {
                "lat": 12.9563,
                "lng": 78.2754
            },
            "Dindigul": {
                "lat": 10.35,
                "lng": 77.95
            },
            "R\u0101n\u012bw\u0101ra Kal\u0101n": {
                "lat": 24.75,
                "lng": 72.2167
            },
            "Shimla": {
                "lat": 31.1033,
                "lng": 77.1722
            },
            "Hospet": {
                "lat": 15.2689,
                "lng": 76.3909
            },
            "M\u0101ldah": {
                "lat": 25.174,
                "lng": 85.7599
            },
            "Ongole": {
                "lat": 15.506,
                "lng": 80.049
            },
            "Betigeri": {
                "lat": 15.443,
                "lng": 75.6442
            },
            "Kodumur": {
                "lat": 15.6833,
                "lng": 77.7833
            },
            "Ellore": {
                "lat": 16.7117,
                "lng": 81.1031
            },
            "Deoghar": {
                "lat": 24.48,
                "lng": 86.7
            },
            "Chhindw\u0101ra": {
                "lat": 22.057,
                "lng": 78.9396
            },
            "Lingampalli": {
                "lat": 17.4865,
                "lng": 78.317
            },
            "Mokameh": {
                "lat": 25.3898,
                "lng": 85.9191
            },
            "Puri": {
                "lat": 19.8,
                "lng": 85.8167
            },
            "Haldia": {
                "lat": 22.0667,
                "lng": 88.0698
            },
            "Khandwa": {
                "lat": 21.82,
                "lng": 76.35
            },
            "Nandy\u0101l": {
                "lat": 15.48,
                "lng": 78.48
            },
            "Pulimaddi": {
                "lat": 15.5386,
                "lng": 78.4613
            },
            "Morena": {
                "lat": 26.5,
                "lng": 78.0
            },
            "Karjat": {
                "lat": 18.55,
                "lng": 75.0
            },
            "Drabar": {
                "lat": 33.43,
                "lng": 75.09
            },
            "Amroha": {
                "lat": 28.9044,
                "lng": 78.4675
            },
            "R\u0101n\u012b": {
                "lat": 9.3667,
                "lng": 76.7667
            },
            "Chakradharpur": {
                "lat": 22.7,
                "lng": 85.63
            },
            "Bhiw\u0101ni": {
                "lat": 28.78,
                "lng": 76.13
            },
            "Bhind": {
                "lat": 26.5587,
                "lng": 78.7871
            },
            "Khammam": {
                "lat": 17.2473,
                "lng": 80.1514
            },
            "Madhyamgram": {
                "lat": 22.7,
                "lng": 88.45
            },
            "Gh\u0101nd\u012bnagar": {
                "lat": 23.2231,
                "lng": 72.65
            },
            "Baharampur": {
                "lat": 24.1,
                "lng": 88.25
            },
            "Morbi": {
                "lat": 22.82,
                "lng": 70.83
            },
            "P\u0101ndhurn\u0101": {
                "lat": 21.6,
                "lng": 78.52
            },
            "R\u0101e Bareli": {
                "lat": 26.2236,
                "lng": 81.2403
            },
            "Dam\u0101n": {
                "lat": 20.42,
                "lng": 72.85
            },
            "Orai": {
                "lat": 25.98,
                "lng": 79.47
            },
            "Mahb\u016bbnagar": {
                "lat": 16.7488,
                "lng": 78.0035
            },
            "Murtaz\u0101b\u0101d": {
                "lat": 28.7111,
                "lng": 77.2688
            },
            "R\u0101jendranagar": {
                "lat": 17.3198,
                "lng": 78.4031
            },
            "Ratanpur": {
                "lat": 26.5091,
                "lng": 85.1259
            },
            "Bhus\u0101val": {
                "lat": 21.05,
                "lng": 75.77
            },
            "Bahraigh": {
                "lat": 27.575,
                "lng": 81.594
            },
            "Umreth": {
                "lat": 22.1262,
                "lng": 78.7149
            },
            "Ittikara": {
                "lat": 8.8633,
                "lng": 76.6972
            },
            "Mahes\u0101na": {
                "lat": 23.6,
                "lng": 72.4
            },
            "R\u0101iganj": {
                "lat": 25.62,
                "lng": 88.12
            },
            "Bat\u0101la": {
                "lat": 31.8186,
                "lng": 75.2028
            },
            "Sirsa": {
                "lat": 26.2667,
                "lng": 84.7403
            },
            "Morsi": {
                "lat": 21.318,
                "lng": 78.0095
            },
            "Dinapore": {
                "lat": 25.6225,
                "lng": 85.0417
            },
            "Tambaram": {
                "lat": 12.831,
                "lng": 80.0459
            },
            "K\u0101raikkudi": {
                "lat": 10.0735,
                "lng": 78.7732
            },
            "Shr\u012br\u0101mpur": {
                "lat": 22.75,
                "lng": 88.34
            },
            "Himatnagar": {
                "lat": 23.6,
                "lng": 72.95
            },
            "Guna": {
                "lat": 24.65,
                "lng": 77.32
            },
            "Naw\u0101da": {
                "lat": 25.5037,
                "lng": 85.7325
            },
            "Jaunpur": {
                "lat": 25.73,
                "lng": 82.68
            },
            "Mah\u0101d": {
                "lat": 18.083,
                "lng": 73.417
            },
            "Madanapalle": {
                "lat": 13.55,
                "lng": 78.5
            },
            "Wadlakonda": {
                "lat": 17.7736,
                "lng": 79.575
            },
            "Bhach\u0101u": {
                "lat": 23.296,
                "lng": 70.343
            },
            "Shivpuri": {
                "lat": 25.43,
                "lng": 77.65
            },
            "S\u0101t\u0101ra": {
                "lat": 17.688,
                "lng": 74.006
            },
            "Phillaur": {
                "lat": 31.03,
                "lng": 75.78
            },
            "Unn\u0101o": {
                "lat": 26.55,
                "lng": 80.49
            },
            "S\u012bt\u0101pur": {
                "lat": 27.57,
                "lng": 80.66
            },
            "Kalamboli": {
                "lat": 19.2333,
                "lng": 73.1333
            },
            "W\u0101r\u0101seon\u012b": {
                "lat": 21.7647,
                "lng": 80.0494
            },
            "Mauli": {
                "lat": 30.699,
                "lng": 76.693
            },
            "Harnaut": {
                "lat": 25.3694,
                "lng": 85.5319
            },
            "Navadw\u012bp": {
                "lat": 23.42,
                "lng": 88.37
            },
            "Kotdw\u0101ra": {
                "lat": 29.75,
                "lng": 78.53
            },
            "P\u012blibh\u012bt": {
                "lat": 28.6267,
                "lng": 79.8058
            },
            "Alleppey": {
                "lat": 9.49,
                "lng": 76.33
            },
            "Cuddalore": {
                "lat": 11.75,
                "lng": 79.75
            },
            "Gondal": {
                "lat": 21.958,
                "lng": 70.795
            },
            "Deo": {
                "lat": 24.6561,
                "lng": 84.4356
            },
            "Silchar": {
                "lat": 24.82,
                "lng": 92.8
            },
            "Ch\u012br\u0101la": {
                "lat": 15.8246,
                "lng": 80.3521
            },
            "Gadag": {
                "lat": 15.4167,
                "lng": 75.6167
            },
            "Tiruvann\u0101malai": {
                "lat": 12.13,
                "lng": 79.07
            },
            "B\u012bdar": {
                "lat": 17.912,
                "lng": 77.52
            },
            "Jal\u0101l\u0101b\u0101d": {
                "lat": 29.5972,
                "lng": 78.3153
            },
            "Nawsari": {
                "lat": 20.95,
                "lng": 72.93
            },
            "Vals\u0101d": {
                "lat": 20.61,
                "lng": 72.926
            },
            "Medin\u012bpur": {
                "lat": 22.424,
                "lng": 87.319
            },
            "Damoh": {
                "lat": 23.8331,
                "lng": 79.4419
            },
            "Har\u012bpur": {
                "lat": 26.258,
                "lng": 87.242
            },
            "Neyveli": {
                "lat": 11.6088,
                "lng": 79.4994
            },
            "B\u0101ram\u016bla": {
                "lat": 34.198,
                "lng": 74.364
            },
            "Malk\u0101pur": {
                "lat": 20.885,
                "lng": 76.2
            },
            "J\u012bnd": {
                "lat": 29.3167,
                "lng": 76.3167
            },
            "Chandannagar": {
                "lat": 22.87,
                "lng": 88.38
            },
            "\u0100doni": {
                "lat": 15.6279,
                "lng": 77.2749
            },
            "Alasandigutta": {
                "lat": 15.6244,
                "lng": 77.2731
            },
            "Udipi": {
                "lat": 13.3389,
                "lng": 74.7451
            },
            "Fyz\u0101b\u0101d": {
                "lat": 26.773,
                "lng": 82.146
            },
            "Saw\u0101i M\u0101dhopur": {
                "lat": 26.0,
                "lng": 76.35
            },
            "Ten\u0101li": {
                "lat": 16.239,
                "lng": 80.645
            },
            "Conjeeveram": {
                "lat": 12.8185,
                "lng": 79.6947
            },
            "Madhubani": {
                "lat": 26.3272,
                "lng": 87.016
            },
            "Proddat\u016br": {
                "lat": 14.75,
                "lng": 78.55
            },
            "Sult\u0101npur Mazra": {
                "lat": 28.6981,
                "lng": 77.0689
            },
            "W\u0101pi": {
                "lat": 20.372,
                "lng": 72.917
            },
            "Godhra": {
                "lat": 20.9231,
                "lng": 70.9214
            },
            "Multai": {
                "lat": 21.77,
                "lng": 78.25
            },
            "R\u0101jap\u0101laiyam": {
                "lat": 9.4204,
                "lng": 77.58
            },
            "Pangzawl": {
                "lat": 22.91,
                "lng": 92.76
            },
            "Chittoor": {
                "lat": 13.216,
                "lng": 79.098
            },
            "Sivak\u0101si": {
                "lat": 9.4533,
                "lng": 77.8024
            },
            "Moga": {
                "lat": 30.822,
                "lng": 75.174
            },
            "Budaun": {
                "lat": 28.05,
                "lng": 79.12
            },
            "Uttarp\u0101ra": {
                "lat": 22.67,
                "lng": 88.35
            },
            "Daltonganj": {
                "lat": 24.03,
                "lng": 84.07
            },
            "Bhar\u016bch": {
                "lat": 21.712,
                "lng": 72.993
            },
            "Saharsa": {
                "lat": 25.88,
                "lng": 86.6
            },
            "Vidisha": {
                "lat": 23.53,
                "lng": 77.82
            },
            "Path\u0101nkot": {
                "lat": 32.2668,
                "lng": 75.6
            },
            "Nowgong": {
                "lat": 25.0574,
                "lng": 79.4381
            },
            "Th\u0101nesar": {
                "lat": 29.9667,
                "lng": 76.8167
            },
            "Kishangarh": {
                "lat": 26.57,
                "lng": 74.87
            },
            "Rudarpur": {
                "lat": 28.98,
                "lng": 79.4
            },
            "Tindw\u0101ra": {
                "lat": 25.4833,
                "lng": 80.3333
            },
            "Nalgonda": {
                "lat": 17.05,
                "lng": 79.27
            },
            "Ambik\u0101pur": {
                "lat": 23.12,
                "lng": 83.2
            },
            "Dibrugarh": {
                "lat": 27.48,
                "lng": 95.0
            },
            "Ver\u0101val": {
                "lat": 20.9159,
                "lng": 70.3629
            },
            "Bet\u016bl Baz\u0101r": {
                "lat": 21.905,
                "lng": 77.902
            },
            "B\u0101lurgh\u0101t": {
                "lat": 25.22,
                "lng": 88.76
            },
            "Jorh\u0101t": {
                "lat": 26.75,
                "lng": 94.22
            },
            "Krishnanagar": {
                "lat": 23.4,
                "lng": 88.5
            },
            "B\u0101r\u0101kpur": {
                "lat": 22.76,
                "lng": 88.37
            },
            "Sh\u0101ntipur": {
                "lat": 23.25,
                "lng": 88.43
            },
            "Hindupur": {
                "lat": 13.83,
                "lng": 77.49
            },
            "Be\u0101war": {
                "lat": 26.1,
                "lng": 74.32
            },
            "Bh\u0101lswa Jahangirpur": {
                "lat": 28.7354,
                "lng": 77.1638
            },
            "Erode": {
                "lat": 11.3409,
                "lng": 77.7171
            },
            "N\u0101ngloi J\u0101t": {
                "lat": 28.6833,
                "lng": 77.0667
            },
            "Soro": {
                "lat": 21.29,
                "lng": 86.69
            },
            "N\u016bzv\u012bd": {
                "lat": 16.7881,
                "lng": 80.8465
            },
            "Sit\u0101rganj": {
                "lat": 28.93,
                "lng": 79.7
            },
            "H\u0101j\u012bpur": {
                "lat": 25.2657,
                "lng": 87.5019
            },
            "H\u0101bra": {
                "lat": 22.83,
                "lng": 88.63
            },
            "B\u012br": {
                "lat": 18.99,
                "lng": 75.76
            },
            "Amreli": {
                "lat": 21.6006,
                "lng": 71.2164
            },
            "N\u0101rnaul": {
                "lat": 28.0444,
                "lng": 76.1056
            },
            "Chitaldrug": {
                "lat": 14.23,
                "lng": 76.4
            },
            "Etah": {
                "lat": 27.63,
                "lng": 78.67
            },
            "Abohar": {
                "lat": 30.1334,
                "lng": 74.2001
            },
            "Kaithal": {
                "lat": 29.8015,
                "lng": 76.3998
            },
            "Balasore": {
                "lat": 21.5033,
                "lng": 86.925
            },
            "Mallapalli": {
                "lat": 9.4461,
                "lng": 76.6567
            },
            "Koiridih": {
                "lat": 24.18,
                "lng": 86.3
            },
            "Shillong": {
                "lat": 25.5822,
                "lng": 91.8944
            },
            "Rew\u0101ri": {
                "lat": 28.1833,
                "lng": 76.6167
            },
            "Haz\u0101rib\u0101gh": {
                "lat": 23.98,
                "lng": 85.35
            },
            "Bh\u012bmavaram": {
                "lat": 16.543,
                "lng": 81.523
            },
            "Mandsaur": {
                "lat": 24.03,
                "lng": 75.08
            },
            "Villupuram": {
                "lat": 11.9401,
                "lng": 79.4861
            },
            "Harchandpur": {
                "lat": 26.36,
                "lng": 81.17
            },
            "Kumbakonam": {
                "lat": 10.9602,
                "lng": 79.3845
            },
            "Aul": {
                "lat": 20.67,
                "lng": 86.64
            },
            "Kol\u0101r": {
                "lat": 13.1333,
                "lng": 78.1333
            },
            "Chicacole": {
                "lat": 18.3,
                "lng": 83.9
            },
            "B\u0101nkura": {
                "lat": 23.25,
                "lng": 87.07
            },
            "Mandya": {
                "lat": 12.52,
                "lng": 76.9
            },
            "Dehri": {
                "lat": 25.4252,
                "lng": 83.8201
            },
            "Durgauti": {
                "lat": 25.2114,
                "lng": 83.5308
            },
            "Mainpuri": {
                "lat": 27.23,
                "lng": 79.02
            },
            "Talaiv\u0101sal": {
                "lat": 11.5867,
                "lng": 78.7583
            },
            "D\u012bsa": {
                "lat": 24.2501,
                "lng": 72.1822
            },
            "Raigarh": {
                "lat": 21.8974,
                "lng": 83.395
            },
            "Siw\u0101n": {
                "lat": 26.22,
                "lng": 84.36
            },
            "Kalol": {
                "lat": 23.246,
                "lng": 72.496
            },
            "Hassan": {
                "lat": 13.012,
                "lng": 76.068
            },
            "Lalitpur": {
                "lat": 24.69,
                "lng": 78.41
            },
            "Gondi\u0101": {
                "lat": 21.4598,
                "lng": 80.195
            },
            "Dal\u016bpura": {
                "lat": 28.6004,
                "lng": 77.3194
            },
            "Nary\u0101i ka Puri": {
                "lat": 26.52,
                "lng": 78.35
            },
            "Palwal": {
                "lat": 28.1447,
                "lng": 77.3255
            },
            "P\u0101lgh\u0101t": {
                "lat": 10.775,
                "lng": 76.651
            },
            "Chittandikavundan\u016br": {
                "lat": 10.6129,
                "lng": 76.8508
            },
            "\u0100nand": {
                "lat": 22.556,
                "lng": 72.951
            },
            "Bot\u0101d": {
                "lat": 22.17,
                "lng": 71.67
            },
            "Bongaigaon": {
                "lat": 26.4769,
                "lng": 90.5583
            },
            "Mukeri\u0101n": {
                "lat": 31.95,
                "lng": 75.62
            },
            "Hanum\u0101ngarh": {
                "lat": 29.58,
                "lng": 74.32
            },
            "Jetpur": {
                "lat": 21.7542,
                "lng": 70.6222
            },
            "Arcot": {
                "lat": 12.9047,
                "lng": 79.3238
            },
            "K\u0101nchr\u0101p\u0101ra": {
                "lat": 22.9456,
                "lng": 88.4332
            },
            "Hoshang\u0101b\u0101d": {
                "lat": 22.7475,
                "lng": 77.7274
            },
            "Ch\u0101s": {
                "lat": 23.63,
                "lng": 86.17
            },
            "Khanna": {
                "lat": 30.7,
                "lng": 76.22
            },
            "Nagda": {
                "lat": 23.4564,
                "lng": 75.4131
            },
            "Hardo\u012b": {
                "lat": 27.42,
                "lng": 80.12
            },
            "Guntakal": {
                "lat": 15.17,
                "lng": 77.38
            },
            "Pithampur": {
                "lat": 22.6197,
                "lng": 75.6933
            },
            "P\u0101tan": {
                "lat": 23.2864,
                "lng": 79.6896
            },
            "Bas\u012brhat": {
                "lat": 22.6572,
                "lng": 88.8942
            },
            "H\u0101l\u012bsahar": {
                "lat": 22.9469,
                "lng": 88.4184
            },
            "Jag\u0101dhri": {
                "lat": 30.168,
                "lng": 77.301
            },
            "Sun\u0101m": {
                "lat": 30.13,
                "lng": 75.8
            },
            "Rishra": {
                "lat": 22.71,
                "lng": 88.35
            },
            "Jh\u0101rsugra": {
                "lat": 21.85,
                "lng": 84.03
            },
            "N\u0101ndgaon": {
                "lat": 20.307,
                "lng": 74.657
            },
            "Alw\u0101l": {
                "lat": 17.5047,
                "lng": 78.5038
            },
            "Dim\u0101pur": {
                "lat": 25.92,
                "lng": 93.73
            },
            "Sarang\u0101puram": {
                "lat": 18.9447,
                "lng": 78.9936
            },
            "Bhadrakh": {
                "lat": 21.0545,
                "lng": 86.5156
            },
            "Dharmavaram": {
                "lat": 18.2164,
                "lng": 83.8856
            },
            "Sivaganga": {
                "lat": 9.8433,
                "lng": 78.4809
            },
            "Puruliya": {
                "lat": 23.34,
                "lng": 86.36
            },
            "Ghaz\u012bpur": {
                "lat": 25.58,
                "lng": 83.57
            },
            "Bagaha": {
                "lat": 27.0992,
                "lng": 84.09
            },
            "Gudiy\u0101ttam": {
                "lat": 12.9476,
                "lng": 78.87
            },
            "Mahuva": {
                "lat": 21.0833,
                "lng": 71.8
            },
            "Gurd\u0101spur": {
                "lat": 32.0333,
                "lng": 75.4
            },
            "Khurda": {
                "lat": 20.18,
                "lng": 85.62
            },
            "Nokha": {
                "lat": 25.1015,
                "lng": 84.1228
            },
            "Jandrapeta": {
                "lat": 15.8246,
                "lng": 80.3521
            },
            "Hengken": {
                "lat": 24.346,
                "lng": 93.7
            },
            "Yelahanka": {
                "lat": 13.1007,
                "lng": 77.5963
            },
            "Darjeeling": {
                "lat": 27.0375,
                "lng": 88.2631
            },
            "Sopur": {
                "lat": 34.3,
                "lng": 74.47
            },
            "Chikmagal\u016br": {
                "lat": 13.313,
                "lng": 75.737
            },
            "Gudiv\u0101da": {
                "lat": 17.3974,
                "lng": 82.7908
            },
            "Phagw\u0101ra": {
                "lat": 31.22,
                "lng": 75.77
            },
            "Pudukkottai": {
                "lat": 10.6118,
                "lng": 78.0505
            },
            "Adil\u0101b\u0101d": {
                "lat": 19.6667,
                "lng": 78.5333
            },
            "Barip\u0101da": {
                "lat": 21.94,
                "lng": 86.72
            },
            "Yavatm\u0101l": {
                "lat": 20.24,
                "lng": 78.06
            },
            "Tit\u0101garh": {
                "lat": 22.74,
                "lng": 88.37
            },
            "Barn\u0101la": {
                "lat": 30.37,
                "lng": 75.54
            },
            "Chittaurgarh": {
                "lat": 24.88,
                "lng": 74.63
            },
            "Narasaraopet": {
                "lat": 16.236,
                "lng": 80.0498
            },
            "Kovilpatti": {
                "lat": 10.0283,
                "lng": 77.5809
            },
            "Bijnor": {
                "lat": 29.37,
                "lng": 78.13
            },
            "Dam Dam": {
                "lat": 22.62,
                "lng": 88.42
            },
            "Kaikkudi": {
                "lat": 9.893,
                "lng": 78.7313
            },
            "Bal\u0101ng\u012br": {
                "lat": 20.72,
                "lng": 83.48
            },
            "Shegaon": {
                "lat": 20.7944,
                "lng": 76.6944
            },
            "Mah\u0101samund": {
                "lat": 21.11,
                "lng": 82.1
            },
            "R\u0101jpura": {
                "lat": 30.484,
                "lng": 76.594
            },
            "B\u0101galkot": {
                "lat": 16.1817,
                "lng": 75.6958
            },
            "Osm\u0101n\u0101b\u0101d": {
                "lat": 18.3194,
                "lng": 76.0736
            },
            "Kali\u0101npur": {
                "lat": 26.5191,
                "lng": 80.2498
            },
            "Shuj\u0101lpur": {
                "lat": 23.4,
                "lng": 76.72
            },
            "S\u0101hibganj": {
                "lat": 25.25,
                "lng": 87.65
            },
            "Champdani": {
                "lat": 22.802,
                "lng": 88.346
            },
            "Nandurbar": {
                "lat": 21.37,
                "lng": 74.25
            },
            "Purwa Utar": {
                "lat": 26.068,
                "lng": 83.184
            },
            "Muktsar": {
                "lat": 30.4743,
                "lng": 74.5166
            },
            "Ferozepore": {
                "lat": 30.9166,
                "lng": 74.6
            },
            "Mancher\u0101l": {
                "lat": 18.8714,
                "lng": 79.4443
            },
            "Sehore": {
                "lat": 23.2,
                "lng": 77.08
            },
            "Bangaon": {
                "lat": 26.3081,
                "lng": 87.8833
            },
            "Khopoli": {
                "lat": 18.789,
                "lng": 73.343
            },
            "Isl\u0101m\u0101b\u0101d": {
                "lat": 33.7353,
                "lng": 75.1478
            },
            "Khardah": {
                "lat": 22.72,
                "lng": 88.38
            },
            "Mungeli": {
                "lat": 22.07,
                "lng": 81.68
            },
            "T\u0101dpatri": {
                "lat": 14.92,
                "lng": 78.02
            },
            "Gharbara": {
                "lat": 28.47,
                "lng": 77.51
            },
            "Sult\u0101npur": {
                "lat": 29.16,
                "lng": 79.06
            },
            "Shikoh\u0101b\u0101d": {
                "lat": 27.1,
                "lng": 78.6
            },
            "Jalp\u0101iguri": {
                "lat": 26.52,
                "lng": 88.73
            },
            "Sh\u0101mli": {
                "lat": 29.45,
                "lng": 77.32
            },
            "Bhilai Karanja": {
                "lat": 21.2198,
                "lng": 81.4575
            },
            "Suri\u0101pet": {
                "lat": 17.1415,
                "lng": 79.6236
            },
            "Wardha": {
                "lat": 20.7417,
                "lng": 78.6056
            },
            "R\u0101n\u012bbennur": {
                "lat": 14.6167,
                "lng": 75.6167
            },
            "Barnoi": {
                "lat": 32.43,
                "lng": 75.43
            },
            "S\u012bt\u0101marhi": {
                "lat": 26.6,
                "lng": 85.48
            },
            "Kishanganj": {
                "lat": 25.6844,
                "lng": 86.9509
            },
            "Hindaun": {
                "lat": 26.74,
                "lng": 77.03
            },
            "Sem\u0101ri": {
                "lat": 24.0555,
                "lng": 73.8566
            },
            "Gang\u0101wati": {
                "lat": 15.43,
                "lng": 76.53
            },
            "Nautanwa": {
                "lat": 27.43,
                "lng": 83.42
            },
            "Jam\u0101lpur": {
                "lat": 25.9549,
                "lng": 86.36
            },
            "Makr\u0101na": {
                "lat": 27.0409,
                "lng": 74.7273
            },
            "Sheopur": {
                "lat": 25.67,
                "lng": 76.7
            },
            "Ballia": {
                "lat": 25.7604,
                "lng": 84.1471
            },
            "T\u0101depalleg\u016bdem": {
                "lat": 16.815,
                "lng": 81.526
            },
            "B\u0101nsb\u0101ria": {
                "lat": 22.97,
                "lng": 88.4
            },
            "B\u016bndi": {
                "lat": 25.44,
                "lng": 75.64
            },
            "Amar\u0101vati": {
                "lat": 16.573,
                "lng": 80.358
            },
            "Negapatam": {
                "lat": 10.7672,
                "lng": 79.8449
            },
            "Buxar": {
                "lat": 25.5605,
                "lng": 83.9805
            },
            "Kurichchi": {
                "lat": 11.7276,
                "lng": 78.4102
            },
            "Kheda": {
                "lat": 22.75,
                "lng": 72.68
            },
            "Tezpur": {
                "lat": 26.63,
                "lng": 92.8
            },
            "Sundarnagar": {
                "lat": 31.53,
                "lng": 76.88
            },
            "Seoni": {
                "lat": 22.08,
                "lng": 79.53
            },
            "Rish\u012bkesh": {
                "lat": 30.1083,
                "lng": 78.2972
            },
            "Kap\u016brthala": {
                "lat": 31.38,
                "lng": 75.38
            },
            "Dhamtari": {
                "lat": 20.71,
                "lng": 81.55
            },
            "R\u0101neswar": {
                "lat": 24.0311,
                "lng": 87.4197
            },
            "Br\u0101jar\u0101jnagar": {
                "lat": 21.8167,
                "lng": 83.9167
            },
            "S\u016bj\u0101ngarh": {
                "lat": 27.7,
                "lng": 74.47
            },
            "Bhadreswar": {
                "lat": 22.82,
                "lng": 88.35
            },
            "Chilakal\u016brupet": {
                "lat": 16.0892,
                "lng": 80.1672
            },
            "Jeypore": {
                "lat": 18.8563,
                "lng": 82.5716
            },
            "K\u0101ranja": {
                "lat": 20.4833,
                "lng": 77.4833
            },
            "Chanwari": {
                "lat": 23.1917,
                "lng": 82.3542
            },
            "Port Blair": {
                "lat": 11.6683,
                "lng": 92.7378
            },
            "Kalyani": {
                "lat": 22.975,
                "lng": 88.4344
            },
            "Datia": {
                "lat": 25.67,
                "lng": 78.47
            },
            "Madhavaram": {
                "lat": 13.1482,
                "lng": 80.2314
            },
            "Gangtok": {
                "lat": 27.33,
                "lng": 88.62
            },
            "Suram\u0101la": {
                "lat": 13.76,
                "lng": 79.7
            },
            "Navalyal": {
                "lat": 16.48,
                "lng": 74.78
            },
            "Barod": {
                "lat": 23.7889,
                "lng": 75.8116
            },
            "V\u0101sco Da G\u0101ma": {
                "lat": 15.3981,
                "lng": 73.8111
            },
            "Khambh\u0101liya": {
                "lat": 22.2,
                "lng": 69.65
            },
            "Abbigeri": {
                "lat": 15.5862,
                "lng": 75.7502
            },
            "Kundli": {
                "lat": 28.99,
                "lng": 77.022
            },
            "Luckeesarai": {
                "lat": 25.1678,
                "lng": 86.0944
            },
            "Kadaiyanall\u016br": {
                "lat": 9.0743,
                "lng": 77.3419
            },
            "Tinsukia": {
                "lat": 27.5,
                "lng": 95.367
            },
            "Tellicherry": {
                "lat": 11.7481,
                "lng": 75.4929
            },
            "It\u0101rsi": {
                "lat": 22.6115,
                "lng": 77.7623
            },
            "Koh\u012bma": {
                "lat": 25.67,
                "lng": 94.1
            },
            "Khambh\u0101t": {
                "lat": 22.3,
                "lng": 72.62
            },
            "Silvassa": {
                "lat": 20.2667,
                "lng": 73.0167
            },
            "Deoni Buzurg": {
                "lat": 18.2639,
                "lng": 77.0822
            },
            "Kall\u016bru": {
                "lat": 15.8344,
                "lng": 78.0292
            },
            "Bodin\u0101yakkan\u016br": {
                "lat": 10.0101,
                "lng": 77.3511
            },
            "Harihar": {
                "lat": 14.5129,
                "lng": 75.8072
            },
            "Bachhraon": {
                "lat": 28.93,
                "lng": 78.22
            },
            "Amalner": {
                "lat": 21.05,
                "lng": 75.05
            },
            "Sard\u0101rshahr": {
                "lat": 28.4401,
                "lng": 74.4908
            },
            "Dhuli\u0101n": {
                "lat": 24.681,
                "lng": 87.954
            },
            "Paramagudi": {
                "lat": 9.5494,
                "lng": 78.5891
            },
            "Zerakpur": {
                "lat": 30.65,
                "lng": 76.82
            },
            "Tandwa": {
                "lat": 26.55,
                "lng": 82.65
            },
            "Tiruchengodu": {
                "lat": 11.379,
                "lng": 77.8949
            },
            "Pedag\u0101di": {
                "lat": 17.6615,
                "lng": 83.2104
            },
            "Nag\u012bna": {
                "lat": 29.443,
                "lng": 78.433
            },
            "Ch\u0101kdaha": {
                "lat": 23.08,
                "lng": 88.52
            },
            "Closepet": {
                "lat": 12.723,
                "lng": 77.286
            },
            "Emmigan\u016br": {
                "lat": 15.7333,
                "lng": 77.4833
            },
            "Yalamak\u016bru": {
                "lat": 15.5103,
                "lng": 77.5249
            },
            "V\u0101niyamb\u0101di": {
                "lat": 12.6825,
                "lng": 78.6167
            },
            "Tiruttani": {
                "lat": 13.18,
                "lng": 79.63
            },
            "Kangan": {
                "lat": 34.263,
                "lng": 74.903
            },
            "Bhadohi": {
                "lat": 25.42,
                "lng": 82.57
            },
            "Allinagaram": {
                "lat": 10.0119,
                "lng": 77.4786
            },
            "Marmagao": {
                "lat": 15.4,
                "lng": 73.8
            },
            "Dh\u0101r": {
                "lat": 22.5972,
                "lng": 75.3042
            },
            "Akot": {
                "lat": 21.1,
                "lng": 77.06
            },
            "Contai": {
                "lat": 21.78,
                "lng": 87.75
            },
            "Kot Kap\u016bra": {
                "lat": 30.5833,
                "lng": 74.9
            },
            "K\u0101rw\u0101r": {
                "lat": 14.8,
                "lng": 74.13
            },
            "M\u0101ndvi": {
                "lat": 21.15,
                "lng": 73.18
            },
            "R\u0101yachoti": {
                "lat": 14.0583,
                "lng": 78.7517
            },
            "P\u0101loncha": {
                "lat": 17.6018,
                "lng": 80.7051
            },
            "Lachhmangarh S\u012bkar": {
                "lat": 27.8225,
                "lng": 75.0253
            },
            "R\u0101magiri Udayagiri": {
                "lat": 19.1619,
                "lng": 84.144
            },
            "Ponn\u0101ni": {
                "lat": 10.77,
                "lng": 75.9
            },
            "J\u0101mt\u0101ra": {
                "lat": 23.9633,
                "lng": 86.8014
            },
            "Poll\u0101chi": {
                "lat": 10.659,
                "lng": 77.008
            },
            "K\u0101vali": {
                "lat": 14.913,
                "lng": 79.9929
            },
            "Sankeshwar": {
                "lat": 16.27,
                "lng": 74.48
            },
            "Kadiri": {
                "lat": 14.12,
                "lng": 78.17
            },
            "Phusro": {
                "lat": 23.77,
                "lng": 85.99
            },
            "Kair\u0101na": {
                "lat": 29.3953,
                "lng": 77.2053
            },
            "Saundatti": {
                "lat": 15.7833,
                "lng": 75.1167
            },
            "R\u0101sipuram": {
                "lat": 11.47,
                "lng": 78.17
            },
            "Naj\u012bb\u0101b\u0101d": {
                "lat": 29.6128,
                "lng": 78.3425
            },
            "Nirmal": {
                "lat": 19.1,
                "lng": 78.35
            },
            "Nir\u0101la": {
                "lat": 19.8143,
                "lng": 78.1662
            },
            "Ootacamund": {
                "lat": 11.41,
                "lng": 76.7
            },
            "Jangipur": {
                "lat": 24.4691,
                "lng": 88.1032
            },
            "Sangr\u016br": {
                "lat": 30.2506,
                "lng": 75.8442
            },
            "Mettup\u0101laiyam": {
                "lat": 11.2341,
                "lng": 76.9067
            },
            "Jumri Tilaiy\u0101": {
                "lat": 24.4289,
                "lng": 85.5355
            },
            "Aruppukkottai": {
                "lat": 9.5139,
                "lng": 78.1002
            },
            "Far\u012bdkot": {
                "lat": 30.67,
                "lng": 74.76
            },
            "Madgaon": {
                "lat": 15.2736,
                "lng": 73.9581
            },
            "Palangotu Adw\u0101r": {
                "lat": 32.7888,
                "lng": 76.0009
            },
            "Jam\u016b\u012b": {
                "lat": 24.9278,
                "lng": 86.2239
            },
            "Kum\u0101rap\u0101laiyam": {
                "lat": 11.4416,
                "lng": 77.6907
            },
            "H\u0101nsi": {
                "lat": 29.1,
                "lng": 75.97
            },
            "Shahdol": {
                "lat": 23.28,
                "lng": 81.35
            },
            "Anak\u0101palle": {
                "lat": 17.6913,
                "lng": 83.0039
            },
            "N\u012bmb\u0101hera": {
                "lat": 24.62,
                "lng": 74.68
            },
            "Nachch\u0101ndupatti": {
                "lat": 10.3238,
                "lng": 78.7233
            },
            "Seoni M\u0101lwa": {
                "lat": 22.4508,
                "lng": 77.4681
            },
            "S\u0101rni": {
                "lat": 22.104,
                "lng": 78.173
            },
            "B\u0101soda": {
                "lat": 23.8515,
                "lng": 77.9365
            },
            "Maratturai": {
                "lat": 11.1536,
                "lng": 79.5329
            },
            "Kar\u0101d": {
                "lat": 17.285,
                "lng": 74.184
            },
            "Chinnachauku": {
                "lat": 14.4732,
                "lng": 78.8451
            },
            "Garulia": {
                "lat": 22.82,
                "lng": 88.37
            },
            "Krishnagiri": {
                "lat": 12.5317,
                "lng": 78.2174
            },
            "Harda Kh\u0101s": {
                "lat": 22.3441,
                "lng": 77.0954
            },
            "Mhow": {
                "lat": 22.55,
                "lng": 75.76
            },
            "Dhor\u0101ji": {
                "lat": 21.7337,
                "lng": 70.4499
            },
            "V\u012brappanchathiram": {
                "lat": 11.3553,
                "lng": 77.6678
            },
            "L\u0101harpur": {
                "lat": 27.72,
                "lng": 80.9
            },
            "Diphu": {
                "lat": 25.83,
                "lng": 93.43
            },
            "Channapatna": {
                "lat": 12.6514,
                "lng": 77.2067
            },
            "Bhaw\u0101nipatna": {
                "lat": 19.91,
                "lng": 83.128
            },
            "Pilkhua": {
                "lat": 28.712,
                "lng": 77.6545
            },
            "Samadi\u0101la": {
                "lat": 21.337,
                "lng": 71.31
            },
            "Tiruppatt\u016br": {
                "lat": 10.13,
                "lng": 78.62
            },
            "Gokulgarh": {
                "lat": 28.2468,
                "lng": 76.6176
            },
            "Sirsilla": {
                "lat": 18.38,
                "lng": 78.83
            },
            "M\u0101nsa": {
                "lat": 23.43,
                "lng": 72.67
            },
            "Chingleput": {
                "lat": 12.6918,
                "lng": 79.9766
            },
            "Saunda": {
                "lat": 23.66,
                "lng": 85.35
            },
            "Ashoknagar": {
                "lat": 24.58,
                "lng": 77.73
            },
            "K\u0101toya": {
                "lat": 23.65,
                "lng": 88.13
            },
            "Kadi": {
                "lat": 23.3009,
                "lng": 72.3322
            },
            "Mann\u0101rgudi": {
                "lat": 11.2761,
                "lng": 79.552
            },
            "Balr\u0101mpur": {
                "lat": 27.43,
                "lng": 82.18
            },
            "Kham\u0101non Kal\u0101n": {
                "lat": 30.82,
                "lng": 76.35
            },
            "Bargarh": {
                "lat": 21.3333,
                "lng": 83.6167
            },
            "Daheg\u0101m": {
                "lat": 23.17,
                "lng": 72.82
            },
            "K\u0101m\u0101reddipet": {
                "lat": 18.3205,
                "lng": 78.337
            },
            "Arni": {
                "lat": 12.6677,
                "lng": 79.2853
            },
            "Bolpur": {
                "lat": 23.67,
                "lng": 87.72
            },
            "Peruvancha": {
                "lat": 17.1719,
                "lng": 80.5839
            },
            "Guml\u0101": {
                "lat": 23.0444,
                "lng": 84.5417
            },
            "Simdega": {
                "lat": 22.62,
                "lng": 84.52
            },
            "Manm\u0101d": {
                "lat": 20.251,
                "lng": 74.439
            },
            "Piro": {
                "lat": 25.33,
                "lng": 84.42
            },
            "Kottag\u016bdem": {
                "lat": 17.55,
                "lng": 80.63
            },
            "Dobni Para": {
                "lat": 22.8347,
                "lng": 74.2556
            },
            "Gok\u0101k": {
                "lat": 16.1667,
                "lng": 74.8333
            },
            "T\u012bkamgarh": {
                "lat": 24.7472,
                "lng": 78.85
            },
            "Ar\u0101ria": {
                "lat": 26.15,
                "lng": 87.52
            },
            "Arkonam": {
                "lat": 13.0778,
                "lng": 79.6667
            },
            "Tanuku": {
                "lat": 16.7566,
                "lng": 81.677
            },
            "Koch Bih\u0101r": {
                "lat": 26.3242,
                "lng": 89.451
            },
            "Kuniyamutt\u016br": {
                "lat": 10.9638,
                "lng": 76.9525
            },
            "Kambam": {
                "lat": 9.7375,
                "lng": 77.2823
            },
            "Bodhan": {
                "lat": 18.67,
                "lng": 77.9
            },
            "Tirk\u0101kara": {
                "lat": 10.035,
                "lng": 76.329
            },
            "Baj Baj": {
                "lat": 22.4828,
                "lng": 88.1818
            },
            "Nip\u0101ni": {
                "lat": 16.399,
                "lng": 74.3829
            },
            "F\u0101zilka": {
                "lat": 30.403,
                "lng": 74.025
            },
            "Sh\u0101j\u0101pur": {
                "lat": 23.4264,
                "lng": 76.2778
            },
            "Keshod": {
                "lat": 21.3,
                "lng": 70.25
            },
            "Chint\u0101mani": {
                "lat": 13.4,
                "lng": 78.066
            },
            "Yanam": {
                "lat": 16.7333,
                "lng": 82.2167
            },
            "Surendranagar": {
                "lat": 22.7,
                "lng": 71.6833
            },
            "Malappuram": {
                "lat": 11.042,
                "lng": 76.0815
            },
            "Garhi": {
                "lat": 24.7904,
                "lng": 86.0768
            },
            "Tindivanam": {
                "lat": 12.2267,
                "lng": 79.6504
            },
            "Sr\u012bvilliputt\u016br": {
                "lat": 9.512,
                "lng": 77.633
            },
            "Bhadarw\u0101h": {
                "lat": 32.98,
                "lng": 75.7137
            },
            "R\u0101n\u0101gh\u0101t": {
                "lat": 23.18,
                "lng": 88.58
            },
            "Dhr\u0101ngadhra": {
                "lat": 22.98,
                "lng": 71.47
            },
            "Gangammapeta": {
                "lat": 18.498,
                "lng": 83.9642
            },
            "Khejroli": {
                "lat": 27.37,
                "lng": 75.57
            },
            "Tirumangalam": {
                "lat": 9.8216,
                "lng": 77.9891
            },
            "Pardig\u016bda": {
                "lat": 17.3974,
                "lng": 78.5783
            },
            "Jaor\u0101": {
                "lat": 23.63,
                "lng": 75.13
            },
            "Tura": {
                "lat": 25.52,
                "lng": 90.22
            },
            "Amb\u0101jog\u0101i": {
                "lat": 18.73,
                "lng": 76.38
            },
            "Dabra": {
                "lat": 25.8857,
                "lng": 78.3322
            },
            "Wandiw\u0101sh": {
                "lat": 12.5,
                "lng": 79.62
            },
            "G\u016bd\u016br": {
                "lat": 14.1473,
                "lng": 79.8477
            },
            "H\u0101veri": {
                "lat": 14.7935,
                "lng": 75.4045
            },
            "Tarn T\u0101ran": {
                "lat": 31.4519,
                "lng": 74.9278
            },
            "Mangalagiri": {
                "lat": 16.43,
                "lng": 80.55
            },
            "Vriddh\u0101chalam": {
                "lat": 11.5,
                "lng": 79.33
            },
            "Kallakkurichchi": {
                "lat": 11.738,
                "lng": 78.962
            },
            "K\u0101sip\u0101laiyam": {
                "lat": 11.3156,
                "lng": 77.7092
            },
            "Pattukkottai": {
                "lat": 10.43,
                "lng": 79.32
            },
            "Gobindgarh": {
                "lat": 30.6709,
                "lng": 76.3019
            },
            "Siruguppa": {
                "lat": 15.6,
                "lng": 77.0
            },
            "Sang\u0101reddi": {
                "lat": 17.6294,
                "lng": 78.0917
            },
            "Virudunagar": {
                "lat": 9.568,
                "lng": 77.9624
            },
            "Warora": {
                "lat": 20.23,
                "lng": 79.0008
            },
            "Chakl\u0101si": {
                "lat": 22.65,
                "lng": 72.93
            },
            "Nilanga": {
                "lat": 18.1161,
                "lng": 76.7525
            },
            "Quil\u0101ndi": {
                "lat": 11.439,
                "lng": 75.695
            },
            "Mandl\u0101": {
                "lat": 22.6,
                "lng": 80.38
            },
            "R\u0101yadrug": {
                "lat": 14.6997,
                "lng": 76.8524
            },
            "Bil\u0101ra": {
                "lat": 26.1792,
                "lng": 73.7056
            },
            "R\u0101yagada": {
                "lat": 19.17,
                "lng": 83.42
            },
            "Parappanang\u0101di": {
                "lat": 11.0497,
                "lng": 75.8575
            },
            "Ratangarh": {
                "lat": 24.8167,
                "lng": 75.1167
            },
            "T\u0101nd\u016br": {
                "lat": 17.2576,
                "lng": 77.5875
            },
            "M\u0101rk\u0101pur": {
                "lat": 15.73,
                "lng": 79.26
            },
            "Malaut": {
                "lat": 30.19,
                "lng": 74.499
            },
            "Kodungall\u016br": {
                "lat": 10.2338,
                "lng": 76.1946
            },
            "Neyy\u0101ttinkara": {
                "lat": 8.4,
                "lng": 77.08
            },
            "Fateh\u0101b\u0101d": {
                "lat": 31.3811,
                "lng": 75.0996
            },
            "B\u0101patla": {
                "lat": 15.9044,
                "lng": 80.4675
            },
            "Kalamasseri": {
                "lat": 10.052,
                "lng": 76.3158
            },
            "Badvel": {
                "lat": 14.75,
                "lng": 79.05
            },
            "V\u0101lp\u0101rai": {
                "lat": 10.3276,
                "lng": 76.9553
            },
            "Sankaranayin\u0101r Kovil": {
                "lat": 9.16,
                "lng": 77.55
            },
            "S\u016bratgarh": {
                "lat": 29.3177,
                "lng": 73.8989
            },
            "Dandeli": {
                "lat": 15.2667,
                "lng": 74.6167
            },
            "N\u0101nd\u016bra Buzurg": {
                "lat": 20.8333,
                "lng": 76.4597
            },
            "Gaura": {
                "lat": 24.9643,
                "lng": 86.3739
            },
            "Bijaynagar": {
                "lat": 25.93,
                "lng": 74.6386
            },
            "Satyamangalam": {
                "lat": 11.5167,
                "lng": 77.25
            },
            "Madhipura": {
                "lat": 25.92,
                "lng": 86.792
            },
            "Kodoli": {
                "lat": 16.88,
                "lng": 74.2
            },
            "Avaniy\u0101puram": {
                "lat": 9.8818,
                "lng": 78.1125
            },
            "Basavakaly\u0101n": {
                "lat": 17.8728,
                "lng": 76.9497
            },
            "Far\u012bdpur": {
                "lat": 28.2086,
                "lng": 79.5381
            },
            "Tripunittura": {
                "lat": 9.9439,
                "lng": 76.3494
            },
            "Nowrangapur": {
                "lat": 19.23,
                "lng": 82.55
            },
            "Dharmas\u0101garam": {
                "lat": 17.9933,
                "lng": 79.4428
            },
            "Varisshiyakuni": {
                "lat": 11.6325,
                "lng": 75.584
            },
            "P\u0101lghar": {
                "lat": 19.6969,
                "lng": 72.7654
            },
            "Pakribaraw\u0101n": {
                "lat": 24.9516,
                "lng": 85.7347
            },
            "K\u0101yankulam": {
                "lat": 9.172,
                "lng": 76.501
            },
            "Par\u0101d\u012bp Garh": {
                "lat": 20.316,
                "lng": 86.61
            },
            "Ilkal": {
                "lat": 15.9592,
                "lng": 76.1135
            },
            "Nihtaur": {
                "lat": 29.33,
                "lng": 78.38
            },
            "Anjangaon": {
                "lat": 21.15,
                "lng": 77.28
            },
            "Renuk\u016bt": {
                "lat": 24.2,
                "lng": 83.03
            },
            "N\u0101bha": {
                "lat": 30.37,
                "lng": 76.15
            },
            "Siuri": {
                "lat": 23.91,
                "lng": 87.527
            },
            "R\u0101jsamand": {
                "lat": 25.07,
                "lng": 73.88
            },
            "Bishnupur": {
                "lat": 23.075,
                "lng": 87.317
            },
            "Mod\u0101sa": {
                "lat": 23.47,
                "lng": 73.3
            },
            "Gauravaram": {
                "lat": 17.1,
                "lng": 80.6
            },
            "Sausar": {
                "lat": 21.65,
                "lng": 78.78
            },
            "Dhenk\u0101n\u0101l": {
                "lat": 20.67,
                "lng": 85.6
            },
            "Urun-Isl\u0101mpur": {
                "lat": 17.05,
                "lng": 74.267
            },
            "Gop\u0101lganj": {
                "lat": 26.47,
                "lng": 84.43
            },
            "Dh\u0101r\u0101puram": {
                "lat": 10.7314,
                "lng": 77.5189
            },
            "Guruv\u0101y\u016br": {
                "lat": 10.5946,
                "lng": 76.0369
            },
            "Jagoniguda": {
                "lat": 17.32,
                "lng": 78.52
            },
            "M\u012brpeta": {
                "lat": 17.32,
                "lng": 78.52
            },
            "Phalodi": {
                "lat": 27.131,
                "lng": 72.364
            },
            "Beypore": {
                "lat": 11.18,
                "lng": 75.82
            },
            "Siddipet": {
                "lat": 18.1018,
                "lng": 78.852
            },
            "Koratla": {
                "lat": 18.8215,
                "lng": 78.7119
            },
            "Hasanpur": {
                "lat": 25.633,
                "lng": 85.5728
            },
            "Sahasw\u0101n": {
                "lat": 28.068,
                "lng": 78.751
            },
            "Ar\u0101mb\u0101gh": {
                "lat": 22.88,
                "lng": 87.78
            },
            "Pidugur\u0101lla": {
                "lat": 16.4793,
                "lng": 79.8863
            },
            "Puliyankudi": {
                "lat": 9.1725,
                "lng": 77.3956
            },
            "Sangamner": {
                "lat": 19.5678,
                "lng": 74.2115
            },
            "Gohna": {
                "lat": 29.13,
                "lng": 76.9
            },
            "Pulivendla": {
                "lat": 14.4194,
                "lng": 78.225
            },
            "K\u0101nhang\u0101d": {
                "lat": 12.3167,
                "lng": 75.0667
            },
            "Jaisalmer": {
                "lat": 26.913,
                "lng": 70.915
            },
            "Kurab\u016br": {
                "lat": 13.4,
                "lng": 78.006
            },
            "Giddarb\u0101ha": {
                "lat": 30.2,
                "lng": 74.67
            },
            "Taml\u016bk": {
                "lat": 22.3,
                "lng": 87.92
            },
            "Sinnar": {
                "lat": 19.85,
                "lng": 74.0
            },
            "Vaij\u0101pur": {
                "lat": 19.9267,
                "lng": 74.7275
            },
            "Jagraon": {
                "lat": 30.78,
                "lng": 75.48
            },
            "Al\u012bpur Du\u0101r": {
                "lat": 26.489,
                "lng": 89.527
            },
            "Dh\u016bri": {
                "lat": 30.3685,
                "lng": 75.8679
            },
            "R\u0101th": {
                "lat": 25.58,
                "lng": 79.57
            },
            "Keitumkawn": {
                "lat": 23.31,
                "lng": 92.85
            },
            "Narapalli": {
                "lat": 17.4875,
                "lng": 78.6012
            },
            "P\u0101lit\u0101na": {
                "lat": 21.52,
                "lng": 71.83
            },
            "Dharmapuri": {
                "lat": 12.1211,
                "lng": 78.1582
            },
            "Idapp\u0101di": {
                "lat": 11.5835,
                "lng": 77.8341
            },
            "Hiriy\u016br": {
                "lat": 13.9446,
                "lng": 76.6172
            },
            "Sirsi": {
                "lat": 14.6194,
                "lng": 74.8375
            },
            "Kod\u0101d": {
                "lat": 16.9978,
                "lng": 79.9653
            },
            "T\u0101depalle": {
                "lat": 16.8454,
                "lng": 81.4966
            },
            "Arm\u016br": {
                "lat": 18.79,
                "lng": 78.29
            },
            "Bankra": {
                "lat": 22.63,
                "lng": 88.3
            },
            "Nawalgarh": {
                "lat": 27.8458,
                "lng": 75.268
            },
            "Toh\u0101na": {
                "lat": 29.7,
                "lng": 75.9
            },
            "Khagaul": {
                "lat": 25.579,
                "lng": 85.0456
            },
            "Chakpi Karong": {
                "lat": 24.3167,
                "lng": 93.9833
            },
            "Sundap\u0101laiyam": {
                "lat": 11.0014,
                "lng": 76.8732
            },
            "Jatani": {
                "lat": 20.17,
                "lng": 85.7
            },
            "Chik Ball\u0101pur": {
                "lat": 13.43,
                "lng": 77.72
            },
            "Athni": {
                "lat": 16.73,
                "lng": 75.07
            },
            "Dhuburi": {
                "lat": 26.02,
                "lng": 90.0
            },
            "Gadw\u0101l": {
                "lat": 16.23,
                "lng": 77.8
            },
            "Visnagar": {
                "lat": 23.7,
                "lng": 72.55
            },
            "P\u0101m\u016bru": {
                "lat": 15.097,
                "lng": 79.409
            },
            "Sheikhpura": {
                "lat": 25.1403,
                "lng": 85.8508
            },
            "W\u0101sh\u012bm": {
                "lat": 20.1113,
                "lng": 77.133
            },
            "N\u0101np\u0101ra": {
                "lat": 27.87,
                "lng": 81.5
            },
            "B\u0101ri": {
                "lat": 25.8769,
                "lng": 86.1918
            },
            "R\u0101nipet": {
                "lat": 12.9247,
                "lng": 79.3333
            },
            "Kesamudram": {
                "lat": 17.6875,
                "lng": 79.8944
            },
            "Nagari": {
                "lat": 13.3214,
                "lng": 79.5856
            },
            "Sherkot": {
                "lat": 29.35,
                "lng": 78.58
            },
            "R\u0101ghogarh": {
                "lat": 24.4442,
                "lng": 77.1986
            },
            "Chidambaram": {
                "lat": 11.407,
                "lng": 79.6912
            },
            "Narw\u0101na": {
                "lat": 29.6167,
                "lng": 76.1117
            },
            "Okha": {
                "lat": 22.4667,
                "lng": 69.0667
            },
            "Bhabhua": {
                "lat": 25.05,
                "lng": 83.62
            },
            "Nanjang\u016bd": {
                "lat": 12.12,
                "lng": 76.68
            },
            "Siddhapur": {
                "lat": 23.9167,
                "lng": 72.3833
            },
            "Jh\u0101rgr\u0101m": {
                "lat": 22.45,
                "lng": 86.98
            },
            "R\u0101man\u0101thapuram": {
                "lat": 9.3639,
                "lng": 78.8395
            },
            "Trikonavattam": {
                "lat": 8.9,
                "lng": 76.65
            },
            "Halvad": {
                "lat": 23.02,
                "lng": 71.18
            },
            "Udamalpet": {
                "lat": 10.5855,
                "lng": 77.2513
            },
            "Wanparti": {
                "lat": 16.361,
                "lng": 78.0627
            },
            "Ch\u0101mr\u0101jnagar": {
                "lat": 11.926,
                "lng": 76.9402
            },
            "Kukarmunda": {
                "lat": 21.5167,
                "lng": 74.1333
            },
            "Dumraon": {
                "lat": 25.5526,
                "lng": 84.1515
            },
            "Panruti": {
                "lat": 11.77,
                "lng": 79.55
            },
            "Velamp\u0101laiyam": {
                "lat": 11.1376,
                "lng": 77.3106
            },
            "Tirukkoyil\u016br": {
                "lat": 11.9675,
                "lng": 79.2056
            },
            "R\u0101zampeta": {
                "lat": 14.1954,
                "lng": 79.159
            },
            "Nedumang\u0101d": {
                "lat": 8.6033,
                "lng": 77.0028
            },
            "Gunupur": {
                "lat": 19.08,
                "lng": 83.82
            },
            "Mehn\u0101jpur": {
                "lat": 25.6722,
                "lng": 83.1181
            },
            "Thatri": {
                "lat": 33.1453,
                "lng": 75.7911
            },
            "Chandralap\u0101du": {
                "lat": 16.715,
                "lng": 80.2089
            },
            "Ponn\u016bru": {
                "lat": 16.0667,
                "lng": 80.5667
            },
            "Kathua": {
                "lat": 32.3694,
                "lng": 75.5254
            },
            "North Lakhimpur": {
                "lat": 27.2414,
                "lng": 94.1056
            },
            "Vinukonda": {
                "lat": 16.05,
                "lng": 79.75
            },
            "Gohadi": {
                "lat": 26.4328,
                "lng": 78.442
            },
            "Khagaria": {
                "lat": 25.5022,
                "lng": 86.4671
            },
            "P\u0101chora": {
                "lat": 20.67,
                "lng": 75.35
            },
            "Tipt\u016br": {
                "lat": 13.26,
                "lng": 76.48
            },
            "Gopichettip\u0101laiyam": {
                "lat": 11.4549,
                "lng": 77.4365
            },
            "It\u0101nagar": {
                "lat": 27.1,
                "lng": 93.62
            },
            "Dhone": {
                "lat": 15.396,
                "lng": 77.872
            },
            "R\u0101jgarh": {
                "lat": 27.236,
                "lng": 76.622
            },
            "Panna": {
                "lat": 24.27,
                "lng": 80.17
            },
            "Zahir\u0101b\u0101d": {
                "lat": 17.6814,
                "lng": 77.6074
            },
            "Wani": {
                "lat": 20.0556,
                "lng": 78.9533
            },
            "Binn\u0101guri": {
                "lat": 26.7641,
                "lng": 89.0558
            },
            "Upleta": {
                "lat": 21.73,
                "lng": 70.28
            },
            "Narasapur": {
                "lat": 16.4361,
                "lng": 81.7016
            },
            "Sonabedha": {
                "lat": 18.73,
                "lng": 82.83
            },
            "Pipari\u0101": {
                "lat": 22.757,
                "lng": 78.355
            },
            "Una": {
                "lat": 20.82,
                "lng": 71.03
            },
            "Sardhana": {
                "lat": 29.145,
                "lng": 77.61
            },
            "Hastin\u0101pur": {
                "lat": 29.17,
                "lng": 78.02
            },
            "Sand\u012bla": {
                "lat": 27.08,
                "lng": 80.52
            },
            "Tiruv\u0101l\u016br": {
                "lat": 10.773,
                "lng": 79.637
            },
            "Sillod": {
                "lat": 20.3,
                "lng": 75.65
            },
            "Mulb\u0101gal": {
                "lat": 13.1635,
                "lng": 78.3935
            },
            "Huns\u016br": {
                "lat": 12.3036,
                "lng": 76.2927
            },
            "P\u0101nskura": {
                "lat": 22.417,
                "lng": 87.7
            },
            "Nanjakk\u0101d": {
                "lat": 9.2069,
                "lng": 76.6319
            },
            "R\u0101mpur Hat": {
                "lat": 24.17,
                "lng": 87.78
            },
            "Khand\u0101la": {
                "lat": 18.7481,
                "lng": 73.4072
            },
            "Lon\u0101vale": {
                "lat": 18.7481,
                "lng": 73.4072
            },
            "Bilimora": {
                "lat": 20.75,
                "lng": 72.95
            },
            "Koth\u0101pet": {
                "lat": 19.3333,
                "lng": 79.4833
            },
            "Maduraivayal": {
                "lat": 13.0631,
                "lng": 80.1719
            },
            "S\u012bra": {
                "lat": 13.745,
                "lng": 76.909
            },
            "Pal\u0101sa": {
                "lat": 18.7731,
                "lng": 84.4078
            },
            "Chintalapalli": {
                "lat": 18.331,
                "lng": 79.674
            },
            "Loh\u0101rdag\u0101": {
                "lat": 23.4331,
                "lng": 84.6799
            },
            "M\u0101cherla": {
                "lat": 16.48,
                "lng": 79.43
            },
            "B\u012bmgal": {
                "lat": 18.7,
                "lng": 78.4667
            },
            "Kanduk\u016br": {
                "lat": 15.2167,
                "lng": 79.9042
            },
            "Keonjhargarh": {
                "lat": 21.63,
                "lng": 85.58
            },
            "Kolleg\u0101l": {
                "lat": 12.1539,
                "lng": 77.101
            },
            "Unjha": {
                "lat": 23.8,
                "lng": 72.4
            },
            "Bail-Hongal": {
                "lat": 15.8137,
                "lng": 74.8589
            },
            "Lunglei": {
                "lat": 22.88,
                "lng": 92.73
            },
            "Nas\u012brab\u0101d": {
                "lat": 26.3,
                "lng": 74.73
            },
            "G\u016bdalur": {
                "lat": 11.5014,
                "lng": 76.4916
            },
            "S\u0101malkot": {
                "lat": 17.0531,
                "lng": 82.1695
            },
            "Kar\u012bmganj": {
                "lat": 24.87,
                "lng": 92.35
            },
            "Bobbili": {
                "lat": 18.5667,
                "lng": 83.3667
            },
            "K\u0101lna": {
                "lat": 23.22,
                "lng": 88.37
            },
            "Sattenapalle": {
                "lat": 16.3962,
                "lng": 80.1497
            },
            "Vrind\u0101van": {
                "lat": 27.58,
                "lng": 77.7
            },
            "Diglipur": {
                "lat": 13.2667,
                "lng": 93.0
            },
            "Naugaon S\u0101d\u0101t": {
                "lat": 28.9833,
                "lng": 78.4167
            },
            "Sendhwa": {
                "lat": 21.6847,
                "lng": 75.0953
            },
            "Talegaon D\u0101bh\u0101de": {
                "lat": 18.72,
                "lng": 73.68
            },
            "Poonamallee": {
                "lat": 13.0465,
                "lng": 80.11
            },
            "Gang\u0101r\u0101mpur": {
                "lat": 25.4,
                "lng": 88.52
            },
            "Tiruvall\u016br": {
                "lat": 13.1231,
                "lng": 79.912
            },
            "Mandapeta": {
                "lat": 16.87,
                "lng": 81.93
            },
            "Tirur": {
                "lat": 10.9,
                "lng": 75.92
            },
            "Pithor\u0101garh": {
                "lat": 29.58,
                "lng": 80.22
            },
            "Kengri": {
                "lat": 12.9122,
                "lng": 77.4827
            },
            "Ropar": {
                "lat": 30.9664,
                "lng": 76.5331
            },
            "Ajodhya": {
                "lat": 26.7992,
                "lng": 82.2044
            },
            "Belampalli": {
                "lat": 19.0756,
                "lng": 79.4881
            },
            "Kharar": {
                "lat": 22.7,
                "lng": 87.68
            },
            "Viramg\u0101m": {
                "lat": 23.12,
                "lng": 72.03
            },
            "Bisw\u0101n": {
                "lat": 27.5,
                "lng": 81.0
            },
            "Kannamangalam": {
                "lat": 12.7499,
                "lng": 79.1518
            },
            "Atchampeta": {
                "lat": 15.5412,
                "lng": 78.9503
            },
            "K\u0101ndi": {
                "lat": 23.95,
                "lng": 88.03
            },
            "Raxaul": {
                "lat": 26.9833,
                "lng": 84.85
            },
            "Barw\u0101ni": {
                "lat": 22.03,
                "lng": 74.9
            },
            "Erumaippatti": {
                "lat": 11.1467,
                "lng": 78.29
            },
            "Harpanahalli": {
                "lat": 14.7877,
                "lng": 75.9886
            },
            "Periya Sem\u016br": {
                "lat": 11.37,
                "lng": 77.6908
            },
            "Madhupur": {
                "lat": 24.7,
                "lng": 83.02
            },
            "Ch\u0101lil": {
                "lat": 11.7519,
                "lng": 75.5959
            },
            "Challakere": {
                "lat": 14.312,
                "lng": 76.651
            },
            "Puthiyang\u0101di": {
                "lat": 12.0189,
                "lng": 75.2588
            },
            "N\u0101makkal": {
                "lat": 11.2167,
                "lng": 78.1667
            },
            "M\u0101ngrol": {
                "lat": 25.33,
                "lng": 76.52
            },
            "Chonthrhu": {
                "lat": 32.4865,
                "lng": 75.9941
            },
            "Bh\u012bmunipatnam": {
                "lat": 17.8846,
                "lng": 83.4393
            },
            "F\u0101l\u0101k\u0101ta": {
                "lat": 26.53,
                "lng": 89.2
            },
            "Pol\u016br": {
                "lat": 12.5119,
                "lng": 79.1258
            },
            "Bhong\u012br": {
                "lat": 17.51,
                "lng": 78.89
            },
            "T\u0101sgaon": {
                "lat": 17.03,
                "lng": 74.6
            },
            "N\u0101ndod": {
                "lat": 21.8706,
                "lng": 73.5028
            },
            "Pith\u0101puram": {
                "lat": 17.1167,
                "lng": 82.2667
            },
            "Khurai": {
                "lat": 24.0437,
                "lng": 78.3301
            },
            "Pungan\u016bru": {
                "lat": 13.3667,
                "lng": 78.5833
            },
            "Gh\u0101t\u0101l": {
                "lat": 22.67,
                "lng": 87.72
            },
            "S\u0101gar": {
                "lat": 16.6249,
                "lng": 76.8015
            },
            "M\u016bndka": {
                "lat": 28.6794,
                "lng": 77.0284
            },
            "Isl\u0101mpur": {
                "lat": 24.1548,
                "lng": 88.4665
            },
            "Sidhi": {
                "lat": 24.42,
                "lng": 81.88
            },
            "R\u0101huri": {
                "lat": 19.38,
                "lng": 74.65
            },
            "K\u0101saragod": {
                "lat": 12.5,
                "lng": 75.0
            },
            "Putt\u016br": {
                "lat": 9.04,
                "lng": 76.71
            },
            "Jalor": {
                "lat": 25.35,
                "lng": 72.62
            },
            "Sam\u0101na": {
                "lat": 30.15,
                "lng": 76.19
            },
            "Kunnamkulam": {
                "lat": 10.65,
                "lng": 76.08
            },
            "Palmaner": {
                "lat": 13.2,
                "lng": 78.75
            },
            "Deol\u0101li": {
                "lat": 19.951,
                "lng": 73.834
            },
            "Umred": {
                "lat": 20.85,
                "lng": 79.33
            },
            "Sohna": {
                "lat": 28.25,
                "lng": 77.07
            },
            "P\u0101rvatipuram": {
                "lat": 18.78,
                "lng": 83.425
            },
            "Mans\u0101la": {
                "lat": 15.9417,
                "lng": 77.4281
            },
            "Ull\u0101l": {
                "lat": 12.7584,
                "lng": 74.8752
            },
            "Dholka": {
                "lat": 22.72,
                "lng": 72.47
            },
            "Ottapp\u0101lam": {
                "lat": 10.77,
                "lng": 76.38
            },
            "Manjeri": {
                "lat": 11.1202,
                "lng": 76.12
            },
            "Ashta": {
                "lat": 23.0175,
                "lng": 76.7221
            },
            "Ilindu Kothi": {
                "lat": 32.1461,
                "lng": 76.2784
            },
            "K\u0101liy\u0101ganj": {
                "lat": 25.63,
                "lng": 88.32
            },
            "Jaggayyapeta": {
                "lat": 16.892,
                "lng": 80.0976
            },
            "Go\u0101lp\u0101ra": {
                "lat": 26.17,
                "lng": 90.62
            },
            "Sh\u0101hpur": {
                "lat": 24.0312,
                "lng": 84.0543
            },
            "Amal\u0101puram": {
                "lat": 16.5787,
                "lng": 82.0061
            },
            "Bakhtiy\u0101rpur": {
                "lat": 25.459,
                "lng": 85.539
            },
            "Arsikere": {
                "lat": 13.3139,
                "lng": 76.2369
            },
            "Patancheruvu": {
                "lat": 17.5333,
                "lng": 78.2645
            },
            "Vik\u0101r\u0101b\u0101d": {
                "lat": 17.33,
                "lng": 77.9
            },
            "B\u0101ruipur": {
                "lat": 22.3654,
                "lng": 88.4325
            },
            "Manglaur": {
                "lat": 29.8,
                "lng": 77.87
            },
            "Tilhar": {
                "lat": 27.9628,
                "lng": 79.7383
            },
            "Sult\u0101nganj": {
                "lat": 25.246,
                "lng": 86.738
            },
            "Tiruvalla": {
                "lat": 9.3856,
                "lng": 76.5756
            },
            "Mandi Dabw\u0101li": {
                "lat": 29.9477,
                "lng": 74.7322
            },
            "Venkatagiri": {
                "lat": 13.9667,
                "lng": 79.5833
            },
            "Sihor": {
                "lat": 21.7,
                "lng": 71.97
            },
            "Nerkunram": {
                "lat": 13.0619,
                "lng": 80.2094
            },
            "Bad\u016bria": {
                "lat": 22.74,
                "lng": 88.79
            },
            "Sironj": {
                "lat": 24.1031,
                "lng": 77.6905
            },
            "Jangaon": {
                "lat": 17.7227,
                "lng": 79.1518
            },
            "Daudnagar": {
                "lat": 25.03,
                "lng": 84.4
            },
            "Jiyyammavalasa": {
                "lat": 18.8186,
                "lng": 83.5797
            },
            "Mandam\u0101ri": {
                "lat": 18.9822,
                "lng": 79.4811
            },
            "Lakhminia": {
                "lat": 25.4126,
                "lng": 86.3253
            },
            "Kor\u0101put": {
                "lat": 18.812,
                "lng": 82.7105
            },
            "Mudhol": {
                "lat": 15.6406,
                "lng": 75.9531
            },
            "Phaltan": {
                "lat": 17.98,
                "lng": 74.43
            },
            "Diu": {
                "lat": 20.7158,
                "lng": 70.9858
            },
            "Todupulai": {
                "lat": 9.8972,
                "lng": 76.7139
            },
            "Khattan": {
                "lat": 33.3773,
                "lng": 75.7254
            },
            "Vaisamp\u0101kkal": {
                "lat": 9.4562,
                "lng": 76.5241
            },
            "Kumarankari": {
                "lat": 9.4596,
                "lng": 76.4986
            },
            "Arwal": {
                "lat": 25.2442,
                "lng": 84.6631
            },
            "Burla": {
                "lat": 21.5098,
                "lng": 83.8726
            },
            "G\u0101darw\u0101ra": {
                "lat": 22.9235,
                "lng": 78.7849
            },
            "K\u0101lpi": {
                "lat": 26.12,
                "lng": 79.73
            },
            "Irinj\u0101lakuda": {
                "lat": 10.3424,
                "lng": 76.2112
            },
            "Tirupparangunram": {
                "lat": 9.8815,
                "lng": 78.0731
            },
            "Zang\u0101reddig\u016bdem": {
                "lat": 17.1229,
                "lng": 81.2953
            },
            "Peran\u0101mpattu": {
                "lat": 12.9387,
                "lng": 78.7028
            },
            "Rongat": {
                "lat": 12.4667,
                "lng": 92.9333
            },
            "Dabhoi": {
                "lat": 22.1376,
                "lng": 73.4162
            },
            "Bhav\u0101ni": {
                "lat": 11.4455,
                "lng": 77.6821
            },
            "Puthuppariy\u0101ram": {
                "lat": 10.8036,
                "lng": 76.6221
            },
            "Sidlaghatta": {
                "lat": 13.39,
                "lng": 77.86
            },
            "Marpalli": {
                "lat": 17.5389,
                "lng": 77.7708
            },
            "Bowringpet": {
                "lat": 12.9912,
                "lng": 78.178
            },
            "Hodal": {
                "lat": 27.894,
                "lng": 77.369
            },
            "Fatwa": {
                "lat": 25.5096,
                "lng": 85.305
            },
            "Pardw\u0101l": {
                "lat": 32.3286,
                "lng": 76.1153
            },
            "Metpalli": {
                "lat": 18.8492,
                "lng": 78.6262
            },
            "Repalle": {
                "lat": 16.0172,
                "lng": 80.8294
            },
            "Kuchaiburi": {
                "lat": 22.27,
                "lng": 86.17
            },
            "Vy\u0101ra": {
                "lat": 21.12,
                "lng": 73.4
            },
            "Sibs\u0101gar": {
                "lat": 26.98,
                "lng": 94.63
            },
            "Mahmud\u0101b\u0101d": {
                "lat": 27.3,
                "lng": 81.12
            },
            "Bikramganj": {
                "lat": 25.2107,
                "lng": 84.2551
            },
            "Chinnaman\u016br": {
                "lat": 9.8422,
                "lng": 77.3857
            },
            "\u0100naiy\u016br": {
                "lat": 9.9615,
                "lng": 78.1118
            },
            "Kosamba": {
                "lat": 21.48,
                "lng": 72.95
            },
            "Jaspur": {
                "lat": 29.2833,
                "lng": 78.8167
            },
            "Forbesganj": {
                "lat": 26.3,
                "lng": 87.265
            },
            "Laksar": {
                "lat": 29.749,
                "lng": 78.024
            },
            "Bar Bigha": {
                "lat": 25.2186,
                "lng": 85.7332
            },
            "T\u016bndla": {
                "lat": 27.2146,
                "lng": 78.2368
            },
            "B\u0101ghpat": {
                "lat": 28.95,
                "lng": 77.22
            },
            "Bhaw\u0101n\u012bpur R\u0101jdh\u0101m": {
                "lat": 25.6501,
                "lng": 87.1396
            },
            "Bhadr\u0101chalam": {
                "lat": 17.6688,
                "lng": 80.8936
            },
            "Biaora": {
                "lat": 23.9163,
                "lng": 76.9112
            },
            "Sojat": {
                "lat": 25.92,
                "lng": 73.67
            },
            "Tammampatti": {
                "lat": 11.4381,
                "lng": 78.4836
            },
            "Suj\u0101npur": {
                "lat": 32.3127,
                "lng": 75.6012
            },
            "Shiv": {
                "lat": 26.1831,
                "lng": 71.25
            },
            "Bhattu Kol\u0101n": {
                "lat": 29.3872,
                "lng": 75.3422
            },
            "Sihor\u0101": {
                "lat": 23.4871,
                "lng": 80.104
            },
            "Chatr\u0101": {
                "lat": 24.2064,
                "lng": 84.8708
            },
            "Par\u0101sia": {
                "lat": 22.1913,
                "lng": 78.759
            },
            "Hilsa": {
                "lat": 25.32,
                "lng": 85.28
            },
            "Nohar": {
                "lat": 29.18,
                "lng": 74.77
            },
            "Bhainsa": {
                "lat": 19.1,
                "lng": 77.9667
            },
            "Perintalmanna": {
                "lat": 10.9765,
                "lng": 76.2269
            },
            "Padrauna": {
                "lat": 26.9042,
                "lng": 83.9808
            },
            "Perambal\u016br": {
                "lat": 11.23,
                "lng": 78.88
            },
            "Kizhake Ch\u0101lakudi": {
                "lat": 10.3,
                "lng": 76.33
            },
            "Sulleru": {
                "lat": 18.5333,
                "lng": 83.2167
            },
            "Pedd\u0101puram": {
                "lat": 18.0289,
                "lng": 79.7269
            },
            "Payyoli": {
                "lat": 11.5333,
                "lng": 75.6667
            },
            "M\u0101jalgaon": {
                "lat": 19.15,
                "lng": 76.2333
            },
            "Prattip\u0101du": {
                "lat": 17.2333,
                "lng": 82.2
            },
            "Kapadvanj": {
                "lat": 23.02,
                "lng": 73.07
            },
            "Tennala": {
                "lat": 11.0,
                "lng": 75.9333
            },
            "Naugachhia": {
                "lat": 25.4,
                "lng": 87.1
            },
            "Niz\u0101mpur": {
                "lat": 21.1117,
                "lng": 74.33
            },
            "Jaggisettig\u016bdem": {
                "lat": 17.1167,
                "lng": 81.3
            },
            "Godda": {
                "lat": 24.827,
                "lng": 87.2125
            },
            "B\u0101durpalle": {
                "lat": 17.5468,
                "lng": 78.365
            },
            "Koduvalli": {
                "lat": 11.3594,
                "lng": 75.9111
            },
            "Guntakal Junction": {
                "lat": 15.1711,
                "lng": 77.3624
            },
            "Gooty": {
                "lat": 15.121,
                "lng": 77.634
            },
            "Bantv\u0101l": {
                "lat": 12.8905,
                "lng": 75.0349
            },
            "K\u012blvish\u0101ram": {
                "lat": 12.9143,
                "lng": 79.2854
            },
            "Khair\u0101b\u0101d": {
                "lat": 27.53,
                "lng": 80.75
            },
            "Jasdan": {
                "lat": 22.03,
                "lng": 71.2
            },
            "Upperu": {
                "lat": 16.6427,
                "lng": 77.4874
            },
            "Mangalap\u0101di": {
                "lat": 12.6431,
                "lng": 74.9194
            },
            "Vite": {
                "lat": 17.2711,
                "lng": 74.5378
            },
            "Homn\u0101b\u0101d": {
                "lat": 17.7707,
                "lng": 77.1252
            },
            "Josh\u012bmath": {
                "lat": 30.555,
                "lng": 79.565
            },
            "Gumia": {
                "lat": 23.8106,
                "lng": 85.8232
            },
            "Bok\u0101ro": {
                "lat": 23.7871,
                "lng": 85.9564
            },
            "N\u0101ngal Township": {
                "lat": 31.385,
                "lng": 76.375
            },
            "Sad\u0101seopet": {
                "lat": 17.6203,
                "lng": 77.9539
            },
            "Hoskote": {
                "lat": 13.0721,
                "lng": 77.7892
            },
            "Jalandhar Cantonment": {
                "lat": 31.286,
                "lng": 75.615
            },
            "Bhatkal": {
                "lat": 13.9853,
                "lng": 74.5553
            },
            "D\u016bngarpur": {
                "lat": 23.84,
                "lng": 73.72
            },
            "Chandr\u0101w\u0101da": {
                "lat": 21.6525,
                "lng": 69.6746
            },
            "Dumka": {
                "lat": 24.42,
                "lng": 87.34
            },
            "Changan\u0101cheri": {
                "lat": 9.4667,
                "lng": 76.55
            },
            "Umarkhed": {
                "lat": 19.6014,
                "lng": 77.6888
            },
            "Atarra": {
                "lat": 25.28,
                "lng": 80.57
            },
            "Azhikk\u014dd": {
                "lat": 11.9076,
                "lng": 75.3446
            },
            "Azhikkal": {
                "lat": 11.9076,
                "lng": 75.3446
            },
            "Vikramasingapuram": {
                "lat": 8.67,
                "lng": 77.33
            },
            "Ji\u0101ganj": {
                "lat": 24.23,
                "lng": 88.27
            },
            "Manapp\u0101rai": {
                "lat": 10.6075,
                "lng": 78.4253
            },
            "Mattan\u016br": {
                "lat": 11.931,
                "lng": 75.572
            },
            "Hagaribommanahalli": {
                "lat": 15.04,
                "lng": 76.2
            },
            "Pallip\u0101laiyam": {
                "lat": 11.3679,
                "lng": 77.7435
            },
            "Nandikotk\u016br": {
                "lat": 15.867,
                "lng": 78.267
            },
            "Selu": {
                "lat": 19.4551,
                "lng": 76.4372
            },
            "M\u0113la G\u016bdal\u016br": {
                "lat": 9.6806,
                "lng": 77.2462
            },
            "Punal\u016br": {
                "lat": 9.017,
                "lng": 76.926
            },
            "Kampli": {
                "lat": 15.4063,
                "lng": 76.6001
            },
            "Nellikkuppam": {
                "lat": 11.7667,
                "lng": 79.6833
            },
            "Padra": {
                "lat": 22.23,
                "lng": 73.08
            },
            "Obra": {
                "lat": 24.891,
                "lng": 84.3742
            },
            "Aland": {
                "lat": 17.5642,
                "lng": 76.5685
            },
            "Vuyy\u016bru": {
                "lat": 16.3667,
                "lng": 80.85
            },
            "Kadinamkulam": {
                "lat": 8.6,
                "lng": 76.8167
            },
            "M\u0101nvi": {
                "lat": 15.9833,
                "lng": 77.05
            },
            "Nilamb\u016br": {
                "lat": 11.2769,
                "lng": 76.2258
            },
            "Bh\u0101lki": {
                "lat": 18.0435,
                "lng": 77.206
            },
            "Anandpur": {
                "lat": 31.2393,
                "lng": 76.5025
            },
            "Jammalamadugu": {
                "lat": 14.85,
                "lng": 78.38
            },
            "Garhwa": {
                "lat": 24.16,
                "lng": 83.8075
            },
            "Sikandra Rao": {
                "lat": 27.7,
                "lng": 78.4
            },
            "Naw\u0101shahr": {
                "lat": 31.1167,
                "lng": 76.1333
            },
            "R\u0101n\u0101v\u0101v": {
                "lat": 21.68,
                "lng": 69.75
            },
            "Bih\u0101t": {
                "lat": 25.4253,
                "lng": 86.0208
            },
            "Coonoor": {
                "lat": 11.345,
                "lng": 76.795
            },
            "Thoub\u0101l": {
                "lat": 24.63,
                "lng": 94.02
            },
            "Dharmaragar": {
                "lat": 24.3785,
                "lng": 92.1783
            },
            "P\u0101kaur": {
                "lat": 24.63,
                "lng": 87.85
            },
            "Shertallai": {
                "lat": 9.6869,
                "lng": 76.3361
            },
            "Nawai": {
                "lat": 26.3824,
                "lng": 75.924
            },
            "Gollalagunta": {
                "lat": 17.1672,
                "lng": 82.2847
            },
            "Farrukhnagar": {
                "lat": 17.0778,
                "lng": 78.2011
            },
            "Chattamangalam": {
                "lat": 11.296,
                "lng": 75.915
            },
            "Kumta": {
                "lat": 14.4264,
                "lng": 74.4122
            },
            "Warud": {
                "lat": 21.4167,
                "lng": 78.4
            },
            "Ganapathivattam": {
                "lat": 11.67,
                "lng": 76.28
            },
            "Gob\u0101rd\u0101nga": {
                "lat": 22.87,
                "lng": 88.76
            },
            "Chalungalp\u0101dam": {
                "lat": 9.549,
                "lng": 76.3237
            },
            "Arant\u0101ngi": {
                "lat": 10.1686,
                "lng": 78.992
            },
            "N\u0101yudupet": {
                "lat": 13.9,
                "lng": 79.9
            },
            "Sundargarh": {
                "lat": 22.12,
                "lng": 84.03
            },
            "Mah\u0101lingpur": {
                "lat": 16.38,
                "lng": 75.12
            },
            "Bh\u0101yala": {
                "lat": 22.8335,
                "lng": 72.3643
            },
            "Nalegaon": {
                "lat": 18.4167,
                "lng": 76.8167
            },
            "Muddebih\u0101l": {
                "lat": 16.33,
                "lng": 76.13
            },
            "Kedgaon": {
                "lat": 18.43,
                "lng": 74.35
            },
            "Sach\u012bn": {
                "lat": 21.08,
                "lng": 72.88
            },
            "\u0100lam\u016bru": {
                "lat": 16.7833,
                "lng": 81.9
            },
            "D\u012bg": {
                "lat": 27.47,
                "lng": 77.33
            },
            "Barnagar": {
                "lat": 23.0489,
                "lng": 75.378
            },
            "Charkhi D\u0101dri": {
                "lat": 28.59,
                "lng": 76.27
            },
            "Guledagudda": {
                "lat": 16.0502,
                "lng": 75.79
            },
            "R\u0101meswaram": {
                "lat": 9.288,
                "lng": 79.313
            },
            "Mel\u016br": {
                "lat": 10.0313,
                "lng": 78.3381
            },
            "Dhupg\u0101ri": {
                "lat": 26.5889,
                "lng": 89.0069
            },
            "Dowlaiswaram": {
                "lat": 16.9556,
                "lng": 81.7931
            },
            "Sainthia": {
                "lat": 23.9451,
                "lng": 87.6803
            },
            "Jangamguda": {
                "lat": 17.6238,
                "lng": 78.6251
            },
            "Shekhupur": {
                "lat": 28.35,
                "lng": 79.02
            },
            "Ellenabad": {
                "lat": 29.45,
                "lng": 74.65
            },
            "Karv\u0101rakundu": {
                "lat": 11.1167,
                "lng": 76.3333
            },
            "R\u0101p\u016br": {
                "lat": 14.2015,
                "lng": 79.5351
            },
            "Siy\u0101na": {
                "lat": 25.117,
                "lng": 72.66
            },
            "Angul": {
                "lat": 20.8381,
                "lng": 85.0956
            },
            "R\u0101jaldesar": {
                "lat": 28.03,
                "lng": 74.47
            },
            "Dalli R\u0101jhara": {
                "lat": 20.58,
                "lng": 81.08
            },
            "Nandig\u0101ma": {
                "lat": 16.7833,
                "lng": 80.3
            },
            "Barw\u0101h": {
                "lat": 22.2539,
                "lng": 76.0385
            },
            "Medak": {
                "lat": 18.046,
                "lng": 78.263
            },
            "Talipparamba": {
                "lat": 12.0368,
                "lng": 75.3601
            },
            "Jora": {
                "lat": 26.3421,
                "lng": 77.8092
            },
            "Ananipalle": {
                "lat": 13.4902,
                "lng": 79.2895
            },
            "Majurwa": {
                "lat": 26.1036,
                "lng": 86.9193
            },
            "Raisen": {
                "lat": 23.33,
                "lng": 77.8
            },
            "J\u0101nakammapeta": {
                "lat": 15.0125,
                "lng": 79.8439
            },
            "Sihora": {
                "lat": 23.0,
                "lng": 80.12
            },
            "Murshid\u0101b\u0101d": {
                "lat": 24.18,
                "lng": 88.27
            },
            "Ahmadpur": {
                "lat": 23.8365,
                "lng": 87.6912
            },
            "Seoh\u0101ra": {
                "lat": 29.22,
                "lng": 78.58
            },
            "W\u0101nk\u0101ner": {
                "lat": 22.6161,
                "lng": 70.9531
            },
            "Addanki": {
                "lat": 15.8167,
                "lng": 79.9833
            },
            "Nidadavole": {
                "lat": 16.92,
                "lng": 81.67
            },
            "Anekal": {
                "lat": 12.7105,
                "lng": 77.6967
            },
            "Kottangara": {
                "lat": 8.9167,
                "lng": 76.6333
            },
            "Porsa": {
                "lat": 26.67,
                "lng": 78.37
            },
            "Bodup\u0101l": {
                "lat": 17.4139,
                "lng": 78.5783
            },
            "R\u0101machandrapuram": {
                "lat": 16.5689,
                "lng": 81.6645
            },
            "Chembra": {
                "lat": 11.042,
                "lng": 75.911
            },
            "Sh\u014dran\u016br": {
                "lat": 10.77,
                "lng": 76.28
            },
            "Nilothi": {
                "lat": 28.8167,
                "lng": 76.8667
            },
            "Jambusar": {
                "lat": 22.05,
                "lng": 72.8
            },
            "K\u014dtt\u014dpp\u0101dam": {
                "lat": 10.9979,
                "lng": 76.3914
            },
            "Amb\u0101samudram": {
                "lat": 8.7037,
                "lng": 77.4527
            },
            "Yeola": {
                "lat": 20.042,
                "lng": 74.489
            },
            "K\u0101yalpattanam": {
                "lat": 8.5638,
                "lng": 78.1246
            },
            "Rudauli": {
                "lat": 26.75,
                "lng": 81.75
            },
            "Dh\u0101ka": {
                "lat": 26.72,
                "lng": 85.33
            },
            "Barhiya": {
                "lat": 25.2833,
                "lng": 86.0333
            },
            "Kattiv\u0101kkam": {
                "lat": 13.2167,
                "lng": 80.3167
            },
            "Saoner": {
                "lat": 21.3858,
                "lng": 78.9201
            },
            "Periyakulam": {
                "lat": 10.1239,
                "lng": 77.5475
            },
            "Bair\u0101gnia": {
                "lat": 26.7381,
                "lng": 85.2736
            },
            "Pirth\u012bpur": {
                "lat": 25.1853,
                "lng": 78.665
            },
            "Mahb\u016bb\u0101b\u0101d": {
                "lat": 17.6167,
                "lng": 80.0167
            },
            "Limbdi": {
                "lat": 22.5685,
                "lng": 71.7949
            },
            "Thol": {
                "lat": 23.0674,
                "lng": 72.5121
            },
            "Barpeta": {
                "lat": 26.32,
                "lng": 91.0
            },
            "Kharik": {
                "lat": 25.3679,
                "lng": 87.0026
            },
            "S\u0101l\u0101r": {
                "lat": 23.7748,
                "lng": 88.1026
            },
            "J\u0101jpur": {
                "lat": 21.214,
                "lng": 86.107
            },
            "All\u016bru": {
                "lat": 15.1336,
                "lng": 78.4975
            },
            "Kadalur": {
                "lat": 11.1333,
                "lng": 75.8167
            },
            "Jambughoda": {
                "lat": 22.3667,
                "lng": 73.7167
            },
            "K\u0101rsiy\u0101ng": {
                "lat": 26.8778,
                "lng": 88.2772
            },
            "Bhatpalli": {
                "lat": 18.4293,
                "lng": 79.8635
            },
            "Th\u0101n": {
                "lat": 22.5667,
                "lng": 71.1833
            },
            "Laungow\u0101l": {
                "lat": 30.2167,
                "lng": 75.6833
            },
            "Akbarpur": {
                "lat": 24.6351,
                "lng": 83.9642
            },
            "Palladam": {
                "lat": 10.99,
                "lng": 77.2
            },
            "K\u0101rmegh": {
                "lat": 26.5307,
                "lng": 86.4263
            },
            "Anakaputtur": {
                "lat": 12.9823,
                "lng": 80.1282
            },
            "Dild\u0101rnagar": {
                "lat": 25.4309,
                "lng": 83.6665
            },
            "K\u0101lol": {
                "lat": 22.606,
                "lng": 73.463
            },
            "Prat\u0101pgarh": {
                "lat": 24.03,
                "lng": 74.78
            },
            "N\u0101thdw\u0101ra": {
                "lat": 24.93,
                "lng": 73.82
            },
            "Muttayy\u0101puram": {
                "lat": 8.7498,
                "lng": 78.1311
            },
            "N\u0101ikankudi": {
                "lat": 9.843,
                "lng": 77.1519
            },
            "Barauli": {
                "lat": 26.3815,
                "lng": 84.5872
            },
            "Diamond Harbour": {
                "lat": 22.191,
                "lng": 88.1905
            },
            "S\u016brampatti": {
                "lat": 11.3411,
                "lng": 77.6956
            },
            "N\u0101r\u0101yanpet": {
                "lat": 16.7445,
                "lng": 77.496
            },
            "Saidpur Dabra": {
                "lat": 25.3207,
                "lng": 87.1793
            },
            "Berub\u0101ri": {
                "lat": 26.3603,
                "lng": 88.7152
            },
            "R\u0101jg\u012br": {
                "lat": 25.03,
                "lng": 85.42
            },
            "Indi": {
                "lat": 17.1773,
                "lng": 75.9526
            },
            "Rajaori": {
                "lat": 33.3767,
                "lng": 74.3122
            },
            "Nalh\u0101ti": {
                "lat": 24.294,
                "lng": 87.839
            },
            "S\u0101nand": {
                "lat": 22.98,
                "lng": 72.38
            },
            "Kod\u012bnar": {
                "lat": 20.86,
                "lng": 70.8
            },
            "Sagauli": {
                "lat": 26.7639,
                "lng": 84.7434
            },
            "Gol\u0101gh\u0101t": {
                "lat": 26.52,
                "lng": 93.97
            },
            "P\u012bleru": {
                "lat": 13.6549,
                "lng": 78.9471
            },
            "Mem\u0101ri": {
                "lat": 23.2,
                "lng": 88.12
            },
            "Kendr\u0101parha": {
                "lat": 20.5,
                "lng": 86.42
            },
            "Naini Tal": {
                "lat": 29.3919,
                "lng": 79.4542
            },
            "R\u0101mganj Mandi": {
                "lat": 24.6472,
                "lng": 75.9444
            },
            "Jayamkondacholapuram": {
                "lat": 11.2127,
                "lng": 79.3637
            },
            "Cherupulassh\u0113ri": {
                "lat": 10.8793,
                "lng": 76.3148
            },
            "Trikarp\u016br North": {
                "lat": 12.1439,
                "lng": 75.1789
            },
            "Shutayil": {
                "lat": 12.1439,
                "lng": 75.1789
            },
            "Agar": {
                "lat": 23.7118,
                "lng": 76.0157
            },
            "Peddapalli": {
                "lat": 18.6162,
                "lng": 79.3832
            },
            "Bil\u0101sip\u0101ra": {
                "lat": 26.2328,
                "lng": 90.2341
            },
            "Pali\u0101 Kal\u0101n": {
                "lat": 28.45,
                "lng": 80.5833
            },
            "H\u0101lol": {
                "lat": 22.5,
                "lng": 73.47
            },
            "Kall\u0101r": {
                "lat": 9.85,
                "lng": 77.1333
            },
            "Patti": {
                "lat": 31.28,
                "lng": 74.86
            },
            "Dhamd\u0101ha": {
                "lat": 25.7435,
                "lng": 87.1835
            },
            "Talukkara": {
                "lat": 10.95,
                "lng": 75.9097
            },
            "T\u0101lcher": {
                "lat": 20.95,
                "lng": 85.23
            },
            "Q\u0101di\u0101n": {
                "lat": 31.8192,
                "lng": 75.3764
            },
            "P\u0101mban": {
                "lat": 9.279,
                "lng": 79.2109
            },
            "Vavveru": {
                "lat": 14.5594,
                "lng": 79.8725
            },
            "Buddh Gaya": {
                "lat": 24.6981,
                "lng": 84.9869
            },
            "Kad\u016br": {
                "lat": 13.5529,
                "lng": 76.0116
            },
            "Mundakkal": {
                "lat": 11.3244,
                "lng": 75.9928
            },
            "Shergh\u0101ti": {
                "lat": 24.5617,
                "lng": 84.7953
            },
            "Pil\u0101ni": {
                "lat": 28.37,
                "lng": 75.6
            },
            "J\u0101njg\u012br": {
                "lat": 22.017,
                "lng": 82.567
            },
            "Erramv\u0101rip\u0101lem": {
                "lat": 15.8495,
                "lng": 80.3861
            },
            "Krishnar\u0101j\u0101s\u0101gara": {
                "lat": 12.4398,
                "lng": 76.3828
            },
            "M\u0101r\u0101kkara": {
                "lat": 10.95,
                "lng": 76.0333
            },
            "Channar\u0101yapatna": {
                "lat": 12.902,
                "lng": 76.364
            },
            "P\u0101lampur": {
                "lat": 32.1097,
                "lng": 76.5366
            },
            "Jh\u0101bua": {
                "lat": 22.7677,
                "lng": 74.5909
            },
            "Vellakkovil": {
                "lat": 10.93,
                "lng": 77.72
            },
            "Sabalgarh": {
                "lat": 26.25,
                "lng": 77.4
            },
            "Murtaj\u0101pur": {
                "lat": 20.73,
                "lng": 77.51
            },
            "Peringalam": {
                "lat": 11.7137,
                "lng": 75.586
            },
            "Merta": {
                "lat": 26.65,
                "lng": 74.0333
            },
            "Uttark\u0101shi": {
                "lat": 30.73,
                "lng": 78.45
            },
            "Maihar": {
                "lat": 24.262,
                "lng": 80.761
            },
            "Begamganj": {
                "lat": 23.5992,
                "lng": 78.3406
            },
            "Tiruchend\u016br": {
                "lat": 8.4946,
                "lng": 78.1219
            },
            "K\u012blakkarai": {
                "lat": 9.2314,
                "lng": 78.7848
            },
            "K\u0101limpong": {
                "lat": 27.06,
                "lng": 88.47
            },
            "Phulw\u0101ria": {
                "lat": 25.4697,
                "lng": 85.9847
            },
            "Kakr\u0101la": {
                "lat": 27.8922,
                "lng": 79.1953
            },
            "Kunigal": {
                "lat": 13.0232,
                "lng": 77.0252
            },
            "Mailapur": {
                "lat": 13.021,
                "lng": 77.938
            },
            "Varkkallai": {
                "lat": 8.7433,
                "lng": 76.6968
            },
            "Mayyan\u0101d": {
                "lat": 8.8391,
                "lng": 76.6469
            },
            "Panaji": {
                "lat": 15.4989,
                "lng": 73.8278
            },
            "Chikodi": {
                "lat": 16.43,
                "lng": 74.6
            },
            "Sindgi": {
                "lat": 16.92,
                "lng": 76.234
            },
            "W\u0101degaon": {
                "lat": 20.34,
                "lng": 76.51
            },
            "Sardulgarh": {
                "lat": 29.697,
                "lng": 75.2388
            },
            "Sam\u0101lkha": {
                "lat": 29.23,
                "lng": 77.02
            },
            "Junnar": {
                "lat": 19.2,
                "lng": 73.88
            },
            "Salaiya": {
                "lat": 23.1,
                "lng": 80.12
            },
            "R\u0101mdurg": {
                "lat": 15.95,
                "lng": 75.3
            },
            "Amud\u0101lavalasa": {
                "lat": 18.4167,
                "lng": 83.9
            },
            "Atmak\u016br": {
                "lat": 15.8779,
                "lng": 78.5884
            },
            "Kovv\u016br": {
                "lat": 17.017,
                "lng": 81.731
            },
            "B\u0101sudebpur": {
                "lat": 21.8256,
                "lng": 87.619
            },
            "Pachr\u016bkha": {
                "lat": 26.7373,
                "lng": 84.8861
            },
            "L\u0101lganj": {
                "lat": 25.7297,
                "lng": 87.4799
            },
            "Kh\u0101chrod": {
                "lat": 23.4232,
                "lng": 75.2819
            },
            "B\u0101depalli": {
                "lat": 16.7549,
                "lng": 78.1443
            },
            "Sijua": {
                "lat": 23.7762,
                "lng": 86.3303
            },
            "Seram": {
                "lat": 17.1786,
                "lng": 77.29
            },
            "V\u0101d\u0101sinor": {
                "lat": 22.95,
                "lng": 73.33
            },
            "Jogbani": {
                "lat": 26.3961,
                "lng": 87.2597
            },
            "Solan": {
                "lat": 30.905,
                "lng": 77.097
            },
            "Hail\u0101k\u0101ndi": {
                "lat": 24.6839,
                "lng": 92.561
            },
            "Erattukulakkada": {
                "lat": 8.9,
                "lng": 76.8833
            },
            "Naw\u0101bganj": {
                "lat": 28.54,
                "lng": 79.633
            },
            "Ayir\u0101puram": {
                "lat": 9.88,
                "lng": 76.3
            },
            "Idangans\u0101lai": {
                "lat": 11.6272,
                "lng": 77.989
            },
            "Chittaranjan": {
                "lat": 23.87,
                "lng": 86.87
            },
            "Part\u0101pnagar": {
                "lat": 25.4145,
                "lng": 87.0787
            },
            "Revelganj": {
                "lat": 25.78,
                "lng": 84.67
            },
            "Amla": {
                "lat": 21.9248,
                "lng": 78.1279
            },
            "Betamcherla": {
                "lat": 15.4667,
                "lng": 78.1667
            },
            "Nall\u016br": {
                "lat": 14.0871,
                "lng": 75.8778
            },
            "Penum\u016br": {
                "lat": 13.3667,
                "lng": 79.1833
            },
            "W\u0101l\u0101j\u0101pet": {
                "lat": 12.9255,
                "lng": 79.3652
            },
            "Tuv\u0101gudi": {
                "lat": 10.7526,
                "lng": 78.8298
            },
            "Dw\u0101rka": {
                "lat": 22.2464,
                "lng": 68.9667
            },
            "Birmitrapur": {
                "lat": 22.4,
                "lng": 84.7667
            },
            "Pehowa": {
                "lat": 29.98,
                "lng": 76.58
            },
            "N\u016brpur": {
                "lat": 32.3,
                "lng": 75.9
            },
            "Mehidpur": {
                "lat": 23.4888,
                "lng": 75.658
            },
            "Tinnan\u016br": {
                "lat": 13.1145,
                "lng": 80.0271
            },
            "Vetap\u0101lem": {
                "lat": 15.78,
                "lng": 80.32
            },
            "Parav\u016brkambolam": {
                "lat": 8.81,
                "lng": 76.6702
            },
            "Vengat": {
                "lat": 11.8828,
                "lng": 75.5351
            },
            "R\u0101jula": {
                "lat": 21.05,
                "lng": 71.43
            },
            "Mah\u0101deopur": {
                "lat": 18.7316,
                "lng": 79.9837
            },
            "Melmadai": {
                "lat": 9.9264,
                "lng": 78.1503
            },
            "Buchireddip\u0101lem": {
                "lat": 14.538,
                "lng": 79.875
            },
            "Khairtal": {
                "lat": 27.8346,
                "lng": 76.6388
            },
            "T\u0101ki": {
                "lat": 22.59,
                "lng": 88.92
            },
            "Porur": {
                "lat": 13.0356,
                "lng": 80.1582
            },
            "Pallipram": {
                "lat": 11.15,
                "lng": 75.9
            },
            "Karuvambram": {
                "lat": 11.0158,
                "lng": 76.1131
            },
            "Shankarpur Khaw\u0101s": {
                "lat": 25.2016,
                "lng": 87.1496
            },
            "Thakraha": {
                "lat": 26.7419,
                "lng": 84.2562
            },
            "Dubr\u0101jpur": {
                "lat": 23.8,
                "lng": 87.38
            },
            "Gurais": {
                "lat": 34.6333,
                "lng": 74.8333
            },
            "Goribidn\u016br": {
                "lat": 13.6111,
                "lng": 77.5167
            },
            "Dhanera": {
                "lat": 24.52,
                "lng": 72.02
            },
            "Pen": {
                "lat": 18.75,
                "lng": 73.08
            },
            "Cheyy\u0101r": {
                "lat": 12.662,
                "lng": 79.5435
            },
            "Pathan\u0101mthitta": {
                "lat": 9.2648,
                "lng": 76.787
            },
            "Charaut": {
                "lat": 26.5322,
                "lng": 85.7942
            },
            "R\u0101ni": {
                "lat": 25.5564,
                "lng": 85.9021
            },
            "Manali": {
                "lat": 13.1667,
                "lng": 80.2667
            },
            "Mamun": {
                "lat": 32.2824,
                "lng": 75.6983
            },
            "Coondapoor": {
                "lat": 13.6313,
                "lng": 74.6902
            },
            "Gomoh": {
                "lat": 23.8735,
                "lng": 86.1516
            },
            "Aklera": {
                "lat": 24.42,
                "lng": 76.57
            },
            "R\u0101watbh\u0101ta": {
                "lat": 24.93,
                "lng": 75.58
            },
            "Sat\u0101n\u0101": {
                "lat": 20.5797,
                "lng": 74.216
            },
            "Basavana B\u0101gev\u0101di": {
                "lat": 16.5728,
                "lng": 75.9725
            },
            "Huz\u016br\u0101b\u0101d": {
                "lat": 18.2,
                "lng": 79.42
            },
            "Attingal": {
                "lat": 8.68,
                "lng": 76.83
            },
            "Hatta": {
                "lat": 24.1341,
                "lng": 79.6012
            },
            "Malavalli": {
                "lat": 12.38,
                "lng": 77.08
            },
            "S\u0101rangpur": {
                "lat": 25.7389,
                "lng": 85.6951
            },
            "Sand\u016br": {
                "lat": 15.1,
                "lng": 76.55
            },
            "Kanigiri": {
                "lat": 15.4,
                "lng": 79.5167
            },
            "Jaito": {
                "lat": 30.4509,
                "lng": 74.8829
            },
            "Phulb\u0101ni": {
                "lat": 20.47,
                "lng": 84.23
            },
            "Lah\u0101r": {
                "lat": 26.194,
                "lng": 78.9414
            },
            "Pilibangan": {
                "lat": 29.4888,
                "lng": 74.0748
            },
            "Gajendragarh": {
                "lat": 15.7363,
                "lng": 75.9698
            },
            "J\u0101le": {
                "lat": 26.38,
                "lng": 85.72
            },
            "Parav\u016br Tekkumbh\u0101gam": {
                "lat": 8.811,
                "lng": 76.669
            },
            "Vett\u016br": {
                "lat": 8.7178,
                "lng": 76.7444
            },
            "Chun\u0101r": {
                "lat": 25.13,
                "lng": 82.9
            },
            "Ratia": {
                "lat": 29.6833,
                "lng": 75.575
            },
            "Kadiyam": {
                "lat": 16.9167,
                "lng": 81.8333
            },
            "Bah\u0101durganj": {
                "lat": 26.2617,
                "lng": 87.8244
            },
            "Jagd\u012bspur": {
                "lat": 25.4674,
                "lng": 84.4204
            },
            "Sitalkuchi": {
                "lat": 26.1697,
                "lng": 89.1914
            },
            "Pujali": {
                "lat": 22.4679,
                "lng": 88.1452
            },
            "Dargot": {
                "lat": 31.2411,
                "lng": 76.9358
            },
            "D\u0101chepalle": {
                "lat": 16.6,
                "lng": 79.7333
            },
            "Nakodar": {
                "lat": 31.1275,
                "lng": 75.4781
            },
            "L\u016bn\u0101v\u0101da": {
                "lat": 23.1333,
                "lng": 73.6167
            },
            "D\u0101lkola": {
                "lat": 25.8767,
                "lng": 87.8414
            },
            "Bindki": {
                "lat": 26.03,
                "lng": 80.6
            },
            "K\u0101nkuria": {
                "lat": 24.6523,
                "lng": 87.9604
            },
            "Vavur": {
                "lat": 11.2167,
                "lng": 75.9083
            },
            "Musiri": {
                "lat": 10.9518,
                "lng": 78.4434
            },
            "P\u0101thri": {
                "lat": 19.25,
                "lng": 76.45
            },
            "Kesavapuram": {
                "lat": 8.4212,
                "lng": 76.9996
            },
            "Mah\u0113": {
                "lat": 11.7011,
                "lng": 75.5367
            },
            "Tuensang": {
                "lat": 26.2716,
                "lng": 94.8314
            },
            "Mulavana": {
                "lat": 8.9936,
                "lng": 76.6765
            },
            "Manoli": {
                "lat": 15.78,
                "lng": 75.12
            },
            "Lakshmeshwar": {
                "lat": 15.13,
                "lng": 75.47
            },
            "Z\u012bra": {
                "lat": 30.97,
                "lng": 74.99
            },
            "K\u0101ramadai": {
                "lat": 11.2428,
                "lng": 76.9617
            },
            "Robertsganj": {
                "lat": 24.7,
                "lng": 83.07
            },
            "Th\u0101na Bhawan": {
                "lat": 29.5833,
                "lng": 77.4167
            },
            "Hoj\u0101i": {
                "lat": 26.0,
                "lng": 92.87
            },
            "Kidangazhi": {
                "lat": 11.0333,
                "lng": 76.1
            },
            "Nemmara": {
                "lat": 10.5934,
                "lng": 76.6006
            },
            "Pun\u0101rakh": {
                "lat": 25.493,
                "lng": 85.8054
            },
            "Pat\u0101mundai": {
                "lat": 20.57,
                "lng": 86.57
            },
            "Kodaik\u0101nal": {
                "lat": 10.23,
                "lng": 77.48
            },
            "Chelora": {
                "lat": 11.8892,
                "lng": 75.4293
            },
            "Ichch\u0101puram": {
                "lat": 19.12,
                "lng": 84.7
            },
            "P\u016brna": {
                "lat": 19.18,
                "lng": 77.05
            },
            "Maur": {
                "lat": 30.0833,
                "lng": 75.25
            },
            "Nargund": {
                "lat": 15.72,
                "lng": 75.38
            },
            "Parappur": {
                "lat": 11.0335,
                "lng": 76.0001
            },
            "Kotma": {
                "lat": 23.2038,
                "lng": 81.979
            },
            "Nell\u0101ya": {
                "lat": 10.8729,
                "lng": 76.289
            },
            "B\u0101lar\u0101mpuram": {
                "lat": 8.3833,
                "lng": 77.0833
            },
            "D\u012bnh\u0101ta": {
                "lat": 26.13,
                "lng": 89.47
            },
            "Dighw\u0101ra": {
                "lat": 25.7443,
                "lng": 85.01
            },
            "Garh\u0101kota": {
                "lat": 23.7791,
                "lng": 79.1432
            },
            "Vempalle": {
                "lat": 13.5382,
                "lng": 78.4604
            },
            "Wai": {
                "lat": 17.95,
                "lng": 73.89
            },
            "M\u0101lpura": {
                "lat": 26.28,
                "lng": 75.38
            },
            "Pih\u0101n\u012b": {
                "lat": 27.6199,
                "lng": 80.2034
            },
            "Shirdi": {
                "lat": 19.77,
                "lng": 74.48
            },
            "N\u012bm ka Th\u0101na": {
                "lat": 27.735,
                "lng": 75.7797
            },
            "Taloda": {
                "lat": 21.5607,
                "lng": 74.22
            },
            "Naduvattam": {
                "lat": 11.4808,
                "lng": 76.5437
            },
            "Chekkal": {
                "lat": 8.3653,
                "lng": 77.1075
            },
            "Tarikere": {
                "lat": 13.7104,
                "lng": 75.8122
            },
            "Mokokch\u016bng": {
                "lat": 26.32,
                "lng": 94.5
            },
            "Majhaul": {
                "lat": 25.557,
                "lng": 86.128
            },
            "Part\u016br": {
                "lat": 19.5911,
                "lng": 76.2161
            },
            "Jal\u0101rpet": {
                "lat": 12.5703,
                "lng": 78.5732
            },
            "Tirkadav\u016br": {
                "lat": 8.9206,
                "lng": 76.5948
            },
            "Huz\u016brnagar": {
                "lat": 16.9,
                "lng": 79.8833
            },
            "Pantnagar": {
                "lat": 28.97,
                "lng": 79.41
            },
            "K\u0101ttipparutti": {
                "lat": 10.8833,
                "lng": 76.05
            },
            "Kalakk\u0101du": {
                "lat": 8.5138,
                "lng": 77.5494
            },
            "Shiggaon": {
                "lat": 14.991,
                "lng": 75.223
            },
            "Mahna": {
                "lat": 30.228,
                "lng": 74.9937
            },
            "Hole Narsipur": {
                "lat": 12.7863,
                "lng": 76.2433
            },
            "Ch\u012bt\u0101pur": {
                "lat": 17.1201,
                "lng": 77.0869
            },
            "Medchal": {
                "lat": 17.6297,
                "lng": 78.4814
            },
            "Narsampet": {
                "lat": 17.9285,
                "lng": 79.8925
            },
            "Uravakonda": {
                "lat": 14.95,
                "lng": 77.27
            },
            "Savan\u016br": {
                "lat": 14.9731,
                "lng": 75.3328
            },
            "Rafiganj": {
                "lat": 24.82,
                "lng": 84.65
            },
            "Aravankara": {
                "lat": 11.0745,
                "lng": 75.9369
            },
            "Sirohi": {
                "lat": 24.885,
                "lng": 72.8625
            },
            "Udhampur": {
                "lat": 32.916,
                "lng": 75.1419
            },
            "Umarga": {
                "lat": 17.84,
                "lng": 76.6217
            },
            "Ham\u012brpur": {
                "lat": 25.95,
                "lng": 80.15
            },
            "Ulliyil": {
                "lat": 12.0333,
                "lng": 75.65
            },
            "Guskhara": {
                "lat": 23.5,
                "lng": 87.75
            },
            "Chakapara": {
                "lat": 22.63,
                "lng": 88.35
            },
            "S\u016brandai": {
                "lat": 8.9773,
                "lng": 77.4206
            },
            "Usilampatti": {
                "lat": 9.9651,
                "lng": 77.7885
            },
            "Perun\u0101d": {
                "lat": 8.9563,
                "lng": 76.64
            },
            "Giddal\u016br": {
                "lat": 15.3764,
                "lng": 78.9251
            },
            "P\u0101ppinissh\u0113ri": {
                "lat": 11.95,
                "lng": 75.35
            },
            "T\u0101zhakara": {
                "lat": 9.2551,
                "lng": 76.5539
            },
            "Pandaul": {
                "lat": 26.2517,
                "lng": 86.0747
            },
            "Bibhutpur": {
                "lat": 25.6878,
                "lng": 85.9992
            },
            "R\u0101wats\u0101r": {
                "lat": 29.28,
                "lng": 74.38
            },
            "Pulppatta": {
                "lat": 11.1511,
                "lng": 76.0669
            },
            "Yellandu": {
                "lat": 17.6,
                "lng": 80.33
            },
            "M\u0101ranch\u0113ri": {
                "lat": 10.7383,
                "lng": 75.9736
            },
            "Wokha": {
                "lat": 26.1,
                "lng": 94.27
            },
            "B\u0101paura": {
                "lat": 28.815,
                "lng": 76.063
            },
            "Chipl\u016bn": {
                "lat": 17.53,
                "lng": 73.52
            },
            "Harra": {
                "lat": 29.1278,
                "lng": 77.4861
            },
            "Mel N\u0101riyappan\u016br": {
                "lat": 11.6495,
                "lng": 78.8599
            },
            "Dulhanganj": {
                "lat": 25.382,
                "lng": 84.858
            },
            "Karumattampatti": {
                "lat": 11.1093,
                "lng": 77.182
            },
            "Lingsug\u016br": {
                "lat": 16.17,
                "lng": 76.52
            },
            "Sh\u012by\u0101li": {
                "lat": 11.2391,
                "lng": 79.7357
            },
            "Madukkarai": {
                "lat": 10.9057,
                "lng": 76.9634
            },
            "Alandatte": {
                "lat": 12.191,
                "lng": 75.4673
            },
            "Vadigenhalli": {
                "lat": 13.29,
                "lng": 77.8
            },
            "H\u012br\u0101kud": {
                "lat": 21.525,
                "lng": 83.8727
            },
            "Munnark\u014dd": {
                "lat": 10.98,
                "lng": 76.47
            },
            "Mann\u0101rakk\u0101t": {
                "lat": 10.98,
                "lng": 76.47
            },
            "\u0100llagadda": {
                "lat": 15.1322,
                "lng": 78.5138
            },
            "Khajamahalpur": {
                "lat": 17.8,
                "lng": 77.36
            },
            "Safidon": {
                "lat": 29.42,
                "lng": 76.67
            },
            "Kolattupuzha": {
                "lat": 8.9082,
                "lng": 77.0555
            },
            "Dh\u0101mnod": {
                "lat": 23.4421,
                "lng": 74.9744
            },
            "M\u0101n\u0101madurai": {
                "lat": 9.6956,
                "lng": 78.4564
            },
            "Maruthar\u014dd": {
                "lat": 10.7737,
                "lng": 76.6973
            },
            "Banda": {
                "lat": 24.0449,
                "lng": 78.9609
            },
            "Paithan": {
                "lat": 19.4751,
                "lng": 75.3856
            },
            "D\u0101poli": {
                "lat": 17.7589,
                "lng": 73.1856
            },
            "Kulittalai": {
                "lat": 10.9357,
                "lng": 78.4241
            },
            "Ankola": {
                "lat": 14.6605,
                "lng": 74.3047
            },
            "Madakalav\u0101ripalli": {
                "lat": 14.7475,
                "lng": 79.0843
            },
            "Zaidpur": {
                "lat": 26.83,
                "lng": 81.33
            },
            "Kita Chauh\u0101ttar": {
                "lat": 25.677,
                "lng": 84.872
            },
            "Deg\u0101na": {
                "lat": 26.8951,
                "lng": 74.3222
            },
            "Kalar\u016bch": {
                "lat": 34.5731,
                "lng": 74.321
            },
            "Aiyappan K\u014dvil": {
                "lat": 9.7158,
                "lng": 77.0333
            },
            "Ved\u0101ranniyam": {
                "lat": 10.3774,
                "lng": 79.8495
            },
            "Baraidih": {
                "lat": 25.9798,
                "lng": 86.1434
            },
            "Badiadka": {
                "lat": 12.5833,
                "lng": 75.0667
            },
            "Tiruv\u016br": {
                "lat": 17.1,
                "lng": 80.6
            },
            "Kokrajhar": {
                "lat": 26.4,
                "lng": 90.27
            },
            "Risod": {
                "lat": 19.97,
                "lng": 76.78
            },
            "Almora": {
                "lat": 29.5971,
                "lng": 79.6591
            },
            "Balar\u0101mpur": {
                "lat": 23.12,
                "lng": 86.22
            },
            "Titl\u0101garh": {
                "lat": 20.2871,
                "lng": 83.1466
            },
            "W\u0101ris Al\u012bganj": {
                "lat": 25.01,
                "lng": 85.63
            },
            "J\u0101mkhed": {
                "lat": 18.72,
                "lng": 75.32
            },
            "R\u016bdarpur": {
                "lat": 26.4293,
                "lng": 83.6104
            },
            "Kallidaikurichi": {
                "lat": 8.6859,
                "lng": 77.4659
            },
            "Annigeri": {
                "lat": 15.4251,
                "lng": 75.4335
            },
            "Pulgaon": {
                "lat": 20.726,
                "lng": 78.317
            },
            "Mulav\u016br": {
                "lat": 10.0111,
                "lng": 76.6056
            },
            "Toda Bh\u012bm": {
                "lat": 26.9167,
                "lng": 76.8167
            },
            "By\u0101dgi": {
                "lat": 14.6733,
                "lng": 75.4868
            },
            "Kadungapuram": {
                "lat": 11.0167,
                "lng": 76.0667
            },
            "Nars\u012bpatnam": {
                "lat": 17.665,
                "lng": 82.614
            },
            "Kaippakanch\u0113ri": {
                "lat": 10.938,
                "lng": 75.987
            },
            "Vemalw\u0101da": {
                "lat": 18.4667,
                "lng": 78.8833
            },
            "Kasba": {
                "lat": 25.5856,
                "lng": 88.1122
            },
            "Ch\u0101vakk\u0101d": {
                "lat": 10.582,
                "lng": 76.041
            },
            "Manganam": {
                "lat": 9.7958,
                "lng": 76.45
            },
            "Chempalli": {
                "lat": 12.0451,
                "lng": 75.5074
            },
            "Dhekiajuli": {
                "lat": 26.7037,
                "lng": 92.4781
            },
            "Mangalam": {
                "lat": 11.6368,
                "lng": 79.2806
            },
            "Vettam": {
                "lat": 10.8667,
                "lng": 75.9
            },
            "Angam\u0101li": {
                "lat": 10.196,
                "lng": 76.386
            },
            "Darsi": {
                "lat": 15.7667,
                "lng": 79.6833
            },
            "Sir\u012bpur": {
                "lat": 25.997,
                "lng": 86.9599
            },
            "Madikeri": {
                "lat": 12.4209,
                "lng": 75.7397
            },
            "Kondapalle": {
                "lat": 16.6183,
                "lng": 80.5411
            },
            "B\u0101maur": {
                "lat": 26.339,
                "lng": 78.101
            },
            "Churi": {
                "lat": 23.6549,
                "lng": 85.0128
            },
            "Kidamangalam": {
                "lat": 10.3167,
                "lng": 76.1333
            },
            "Hisu\u0101": {
                "lat": 24.8336,
                "lng": 85.4173
            },
            "Zam\u0101nia": {
                "lat": 25.4194,
                "lng": 83.5569
            },
            "Kavundapp\u0101di": {
                "lat": 11.4248,
                "lng": 77.5579
            },
            "Kart\u0101rpur": {
                "lat": 31.4427,
                "lng": 75.4985
            },
            "Borne": {
                "lat": 25.5431,
                "lng": 86.6999
            },
            "Shams\u0101b\u0101d": {
                "lat": 23.8149,
                "lng": 77.4957
            },
            "Shik\u0101rp\u016br": {
                "lat": 28.2814,
                "lng": 78.0108
            },
            "Umaria": {
                "lat": 23.38,
                "lng": 81.1
            },
            "Tekkebh\u0101gam": {
                "lat": 9.4361,
                "lng": 76.5667
            },
            "Chanderi": {
                "lat": 24.72,
                "lng": 78.13
            },
            "Kareli": {
                "lat": 22.9153,
                "lng": 79.0638
            },
            "H\u0101ngal": {
                "lat": 14.7646,
                "lng": 75.1246
            },
            "Turkauliy\u0101": {
                "lat": 26.6079,
                "lng": 84.8296
            },
            "Elesvaram": {
                "lat": 17.2833,
                "lng": 82.1
            },
            "Fatehpur S\u012bkri": {
                "lat": 27.091,
                "lng": 77.661
            },
            "Patr\u0101tu": {
                "lat": 23.67,
                "lng": 85.28
            },
            "S\u0101nchor": {
                "lat": 24.7536,
                "lng": 71.7714
            },
            "Lohagaon": {
                "lat": 20.6,
                "lng": 77.6833
            },
            "Umarkot": {
                "lat": 19.6653,
                "lng": 82.2063
            },
            "\u0100rumuganeri": {
                "lat": 8.5717,
                "lng": 78.0922
            },
            "Parambil": {
                "lat": 11.5575,
                "lng": 75.7605
            },
            "Chand Chaur": {
                "lat": 25.7276,
                "lng": 85.7865
            },
            "Mankada": {
                "lat": 11.0187,
                "lng": 76.1755
            },
            "Kall\u016br": {
                "lat": 16.1405,
                "lng": 77.2081
            },
            "Vadavalli": {
                "lat": 11.0258,
                "lng": 76.9038
            },
            "Debagr\u0101m": {
                "lat": 23.6833,
                "lng": 88.2833
            },
            "N\u0101njikkottai": {
                "lat": 10.7453,
                "lng": 79.1289
            },
            "Vilakkudi": {
                "lat": 9.0167,
                "lng": 76.85
            },
            "Jamikunta": {
                "lat": 18.2864,
                "lng": 79.4761
            },
            "T\u0101r\u0101nagar": {
                "lat": 28.6689,
                "lng": 75.0406
            },
            "Belhi": {
                "lat": 26.5769,
                "lng": 86.1723
            },
            "Erraguntla": {
                "lat": 15.2821,
                "lng": 78.5106
            },
            "Ch\u0113manch\u0113ri": {
                "lat": 11.4116,
                "lng": 75.735
            },
            "M\u0101nwat": {
                "lat": 19.3,
                "lng": 76.5
            },
            "Chemmaruthi": {
                "lat": 8.7597,
                "lng": 76.7435
            },
            "Turaiy\u016br": {
                "lat": 11.1497,
                "lng": 78.5987
            },
            "Ketti": {
                "lat": 11.4,
                "lng": 76.7
            },
            "Chamtha": {
                "lat": 25.5789,
                "lng": 85.7734
            },
            "Pattanapuram": {
                "lat": 9.0927,
                "lng": 76.8612
            },
            "S\u0101sth\u0101nkotta": {
                "lat": 9.0365,
                "lng": 76.6239
            },
            "Narsinghgarh": {
                "lat": 23.7,
                "lng": 77.1
            },
            "Kalyandrug": {
                "lat": 14.55,
                "lng": 77.1
            },
            "Tattamangalam": {
                "lat": 10.7,
                "lng": 76.75
            },
            "Er\u0101mala": {
                "lat": 11.6789,
                "lng": 75.5931
            },
            "K\u0101ngayam": {
                "lat": 11.0054,
                "lng": 77.562
            },
            "Utraula": {
                "lat": 27.32,
                "lng": 82.42
            },
            "Kukshi": {
                "lat": 22.2068,
                "lng": 74.7579
            },
            "Mang\u016br": {
                "lat": 17.9373,
                "lng": 80.8185
            },
            "Kov\u016br": {
                "lat": 12.1094,
                "lng": 75.3941
            },
            "R\u0101dhanpur": {
                "lat": 23.83,
                "lng": 71.6
            },
            "Munnar": {
                "lat": 10.0892,
                "lng": 77.0597
            },
            "Akiv\u012bdu": {
                "lat": 16.5823,
                "lng": 81.3811
            },
            "Makhdumpur": {
                "lat": 25.072,
                "lng": 84.973
            },
            "Pur\u0101ini": {
                "lat": 25.1426,
                "lng": 86.9797
            },
            "Tholikuzhi": {
                "lat": 8.6526,
                "lng": 77.0704
            },
            "Palamel": {
                "lat": 9.1875,
                "lng": 76.6498
            },
            "Puratt\u016br": {
                "lat": 10.8022,
                "lng": 75.9225
            },
            "Aleng\u0101d": {
                "lat": 10.1,
                "lng": 76.3
            },
            "Maur Kal\u0101n": {
                "lat": 30.08,
                "lng": 75.25
            },
            "Sherpur": {
                "lat": 25.6539,
                "lng": 84.9793
            },
            "Jhanjh\u0101rpur": {
                "lat": 26.2647,
                "lng": 86.2799
            },
            "Alamnagar": {
                "lat": 25.561,
                "lng": 86.9014
            },
            "Bagasra": {
                "lat": 21.4833,
                "lng": 70.95
            },
            "Kottaikuppam": {
                "lat": 11.9613,
                "lng": 79.8392
            },
            "Velliy\u014dd": {
                "lat": 11.6256,
                "lng": 75.6294
            },
            "Singia": {
                "lat": 25.8424,
                "lng": 86.1622
            },
            "Tekk\u0113kara Kizhakku": {
                "lat": 9.2,
                "lng": 76.5
            },
            "Sad\u0101b\u0101d": {
                "lat": 27.45,
                "lng": 78.05
            },
            "Kochugaon": {
                "lat": 26.5518,
                "lng": 90.0762
            },
            "Tulj\u0101pur": {
                "lat": 18.0,
                "lng": 76.08
            },
            "L\u0101lgola": {
                "lat": 24.42,
                "lng": 88.25
            },
            "Masur": {
                "lat": 17.28,
                "lng": 74.2
            },
            "Nautan Dube": {
                "lat": 26.7118,
                "lng": 84.503
            },
            "Thazhamel": {
                "lat": 8.8667,
                "lng": 76.6667
            },
            "R\u0101hatgarh": {
                "lat": 23.78,
                "lng": 78.37
            },
            "Shik\u0101rpur": {
                "lat": 14.27,
                "lng": 75.35
            },
            "Kurivikod": {
                "lat": 8.3196,
                "lng": 77.1104
            },
            "Charth\u0101wal": {
                "lat": 29.55,
                "lng": 77.5833
            },
            "Samdhin": {
                "lat": 27.137,
                "lng": 79.703
            },
            "Nau\u0101garhi": {
                "lat": 25.3443,
                "lng": 86.5358
            },
            "Deori Kh\u0101s": {
                "lat": 23.3902,
                "lng": 79.0163
            },
            "Kunnatn\u0101d": {
                "lat": 10.0179,
                "lng": 76.401
            },
            "Kolachel": {
                "lat": 8.1767,
                "lng": 77.2591
            },
            "Sanha": {
                "lat": 25.402,
                "lng": 86.3592
            },
            "Vellan\u0101d": {
                "lat": 8.4461,
                "lng": 77.1971
            },
            "Sri M\u0101dhopur": {
                "lat": 27.4667,
                "lng": 75.6
            },
            "Lumding": {
                "lat": 25.75,
                "lng": 93.17
            },
            "Sherpur Khurd": {
                "lat": 25.5565,
                "lng": 83.8052
            },
            "Vadakakarai": {
                "lat": 10.1731,
                "lng": 76.2096
            },
            "N\u0101spur": {
                "lat": 18.8604,
                "lng": 79.4849
            },
            "Unchagao": {
                "lat": 16.6988,
                "lng": 74.2749
            },
            "Bramhapuri": {
                "lat": 20.6068,
                "lng": 79.8644
            },
            "Rusera": {
                "lat": 25.7544,
                "lng": 86.0258
            },
            "Podili": {
                "lat": 15.604,
                "lng": 79.608
            },
            "Shr\u012bgonda": {
                "lat": 18.616,
                "lng": 74.698
            },
            "Kalliyassh\u0113ri": {
                "lat": 11.9712,
                "lng": 75.3616
            },
            "Malaikkal": {
                "lat": 8.8058,
                "lng": 76.8301
            },
            "Tummap\u0101la": {
                "lat": 17.7166,
                "lng": 82.9965
            },
            "Mush\u0101bani": {
                "lat": 22.52,
                "lng": 86.45
            },
            "Karera": {
                "lat": 25.4581,
                "lng": 78.1358
            },
            "Malakanagiri": {
                "lat": 18.35,
                "lng": 81.9
            },
            "Tarakeswar": {
                "lat": 22.89,
                "lng": 88.02
            },
            "B\u0101d\u0101mi": {
                "lat": 15.92,
                "lng": 75.6803
            },
            "Husain\u0101b\u0101d": {
                "lat": 24.5285,
                "lng": 84.0
            },
            "Bairi Chak": {
                "lat": 24.6951,
                "lng": 84.9913
            },
            "Leh": {
                "lat": 34.1642,
                "lng": 77.5847
            },
            "Pattanakk\u0101d": {
                "lat": 9.7286,
                "lng": 76.3189
            },
            "Sholinghur": {
                "lat": 13.12,
                "lng": 79.42
            },
            "Dharampur": {
                "lat": 25.2417,
                "lng": 82.2331
            },
            "M\u0101n\u0101vadar": {
                "lat": 21.5,
                "lng": 70.13
            },
            "Palaiya \u0100yakkudi": {
                "lat": 10.456,
                "lng": 77.5595
            },
            "Chinn\u0101lapatti": {
                "lat": 10.2875,
                "lng": 77.912
            },
            "Pattittara": {
                "lat": 10.8119,
                "lng": 76.0915
            },
            "Mahemd\u0101v\u0101d": {
                "lat": 22.83,
                "lng": 72.77
            },
            "Gadarpur": {
                "lat": 29.0437,
                "lng": 79.2465
            },
            "Ulliyeri": {
                "lat": 11.4333,
                "lng": 75.7833
            },
            "Tekkali": {
                "lat": 18.6057,
                "lng": 84.2355
            },
            "Pedana": {
                "lat": 16.2667,
                "lng": 81.1667
            },
            "Mattath\u016br": {
                "lat": 10.3792,
                "lng": 76.3208
            },
            "Pandua": {
                "lat": 23.08,
                "lng": 88.28
            },
            "B\u0101bura": {
                "lat": 25.6838,
                "lng": 84.7825
            },
            "Bann\u016br": {
                "lat": 12.3329,
                "lng": 76.862
            },
            "Chengam": {
                "lat": 12.3112,
                "lng": 78.7925
            },
            "Talakk\u0101d": {
                "lat": 10.8833,
                "lng": 75.9333
            },
            "Devarshola": {
                "lat": 11.5437,
                "lng": 76.4404
            },
            "Gariadhar": {
                "lat": 21.53,
                "lng": 71.58
            },
            "Sankaramangalam": {
                "lat": 8.6561,
                "lng": 76.787
            },
            "N\u0101g\u0101waram": {
                "lat": 17.4875,
                "lng": 78.6012
            },
            "Main\u0101guri": {
                "lat": 26.5658,
                "lng": 88.8214
            },
            "Karuvakulam": {
                "lat": 9.7605,
                "lng": 77.2205
            },
            "Uran": {
                "lat": 18.89,
                "lng": 72.95
            },
            "Karjan": {
                "lat": 22.053,
                "lng": 73.1235
            },
            "M\u016bvattupula": {
                "lat": 9.9798,
                "lng": 76.5803
            },
            "Man\u0101war": {
                "lat": 22.23,
                "lng": 75.08
            },
            "K\u016btt\u0101nall\u016br": {
                "lat": 10.7069,
                "lng": 79.5235
            },
            "Dh\u0101ri": {
                "lat": 21.3261,
                "lng": 71.0272
            },
            "Rehli": {
                "lat": 23.63,
                "lng": 79.08
            },
            "Sh\u0101hpura": {
                "lat": 23.1828,
                "lng": 80.6997
            },
            "Sallimedu": {
                "lat": 11.1147,
                "lng": 77.3975
            },
            "Koni": {
                "lat": 9.241,
                "lng": 76.8784
            },
            "Chim\u0101kurti": {
                "lat": 15.5149,
                "lng": 80.0368
            },
            "T\u0101ramangalam": {
                "lat": 11.7,
                "lng": 77.98
            },
            "Anjad": {
                "lat": 22.0417,
                "lng": 75.0552
            },
            "Kaipram": {
                "lat": 10.8833,
                "lng": 76.1833
            },
            "Periyan\u0101yakkanp\u0101laiyam": {
                "lat": 11.1544,
                "lng": 76.9499
            },
            "Egra": {
                "lat": 21.9,
                "lng": 87.53
            },
            "Kanniparamba": {
                "lat": 11.3611,
                "lng": 76.0097
            },
            "Mussoorie": {
                "lat": 30.45,
                "lng": 78.08
            },
            "Sakt\u012b": {
                "lat": 22.03,
                "lng": 82.97
            },
            "El\u016br": {
                "lat": 10.0667,
                "lng": 76.3
            },
            "Rah\u012bmpur": {
                "lat": 25.4894,
                "lng": 86.4774
            },
            "Kondarangi K\u012bran\u016br": {
                "lat": 10.615,
                "lng": 77.724
            },
            "Nethirimangalam": {
                "lat": 10.8054,
                "lng": 76.1877
            },
            "M\u0101rgr\u0101m": {
                "lat": 24.1512,
                "lng": 87.8422
            },
            "Mahugaon": {
                "lat": 22.5748,
                "lng": 75.7445
            },
            "\u0100rda": {
                "lat": 32.3087,
                "lng": 76.5772
            },
            "Mund\u0101hal Khurd": {
                "lat": 28.98,
                "lng": 76.195
            },
            "Hasanparti": {
                "lat": 18.0681,
                "lng": 79.5236
            },
            "R\u0101npur": {
                "lat": 22.3752,
                "lng": 71.6898
            },
            "P\u0101tri": {
                "lat": 23.19,
                "lng": 71.8
            },
            "Sal\u0101ya": {
                "lat": 22.32,
                "lng": 69.6
            },
            "Edassh\u0113ri": {
                "lat": 10.9833,
                "lng": 75.9667
            },
            "Mundi": {
                "lat": 22.07,
                "lng": 76.5
            },
            "Padappakara": {
                "lat": 8.9667,
                "lng": 76.6333
            },
            "S\u0101gw\u0101ra": {
                "lat": 23.6681,
                "lng": 74.0244
            },
            "Tirwa": {
                "lat": 26.962,
                "lng": 79.7923
            },
            "Kaluv\u0101ya": {
                "lat": 14.5,
                "lng": 79.4167
            },
            "Masabdisa": {
                "lat": 25.9682,
                "lng": 93.4372
            },
            "M\u0101gadi": {
                "lat": 12.97,
                "lng": 77.23
            },
            "B\u0101ghm\u0101ri": {
                "lat": 20.2206,
                "lng": 85.5014
            },
            "Sarpavaram": {
                "lat": 17.0102,
                "lng": 82.225
            },
            "Dhandhuka": {
                "lat": 22.37,
                "lng": 71.98
            },
            "Saundhonw\u0101li": {
                "lat": 30.3331,
                "lng": 77.9608
            },
            "P\u0101rakadavu": {
                "lat": 10.2126,
                "lng": 76.352
            },
            "Koratgi": {
                "lat": 15.6081,
                "lng": 76.6597
            },
            "Nav\u0101pur": {
                "lat": 21.17,
                "lng": 73.78
            },
            "Riga": {
                "lat": 26.6553,
                "lng": 85.442
            },
            "Bir\u016br": {
                "lat": 13.5972,
                "lng": 75.9717
            },
            "Kott\u016bru": {
                "lat": 17.0436,
                "lng": 81.7705
            },
            "Marhaura": {
                "lat": 25.97,
                "lng": 84.87
            },
            "Triprang\u014dtt\u016br": {
                "lat": 11.7843,
                "lng": 75.6559
            },
            "P\u0101nakkudi": {
                "lat": 8.3492,
                "lng": 77.5672
            },
            "Ozh\u016br": {
                "lat": 10.9542,
                "lng": 75.9167
            },
            "Kodayatt\u016br": {
                "lat": 11.2833,
                "lng": 75.9833
            },
            "Tiruv\u0113gapra": {
                "lat": 10.873,
                "lng": 76.125
            },
            "Krishnar\u0101jpet": {
                "lat": 12.6662,
                "lng": 76.4877
            },
            "Talw\u0101ra": {
                "lat": 31.9376,
                "lng": 75.8866
            },
            "Madavur": {
                "lat": 11.2597,
                "lng": 75.9486
            },
            "Puthupalli": {
                "lat": 9.5594,
                "lng": 76.5722
            },
            "Nattam": {
                "lat": 10.2249,
                "lng": 78.231
            },
            "Devarkonda": {
                "lat": 16.7,
                "lng": 78.9333
            },
            "Sursand": {
                "lat": 26.65,
                "lng": 85.7167
            },
            "Hosdurga": {
                "lat": 13.7963,
                "lng": 76.2841
            },
            "Nep\u0101nagar": {
                "lat": 21.4558,
                "lng": 76.4494
            },
            "J\u0101mai": {
                "lat": 22.1964,
                "lng": 78.5919
            },
            "Kalpatta": {
                "lat": 11.6087,
                "lng": 76.0834
            },
            "Idar": {
                "lat": 23.839,
                "lng": 73.002
            },
            "Veliyang\u014dd": {
                "lat": 10.7296,
                "lng": 75.9471
            },
            "Kollengode": {
                "lat": 10.6139,
                "lng": 76.6908
            },
            "R\u0101r\u014dtt": {
                "lat": 11.4504,
                "lng": 75.9306
            },
            "L\u0101kheri": {
                "lat": 25.67,
                "lng": 76.17
            },
            "Chikn\u0101yakanhalli": {
                "lat": 13.4161,
                "lng": 76.6206
            },
            "Maranga": {
                "lat": 25.7592,
                "lng": 87.4665
            },
            "Behror": {
                "lat": 27.8903,
                "lng": 76.2861
            },
            "M\u012bn\u0101d": {
                "lat": 8.85,
                "lng": 76.6958
            },
            "Sankaridrug": {
                "lat": 11.4745,
                "lng": 77.8691
            },
            "Pirakk\u0101d": {
                "lat": 9.35,
                "lng": 76.35
            },
            "Ibr\u0101h\u012bmpatnam": {
                "lat": 16.583,
                "lng": 80.5143
            },
            "M\u016bdbidri": {
                "lat": 12.9101,
                "lng": 75.003
            },
            "\u0100tmak\u016br": {
                "lat": 14.6167,
                "lng": 79.6167
            },
            "Vadakku Valliy\u016br": {
                "lat": 8.3829,
                "lng": 77.6122
            },
            "Marar": {
                "lat": 25.5392,
                "lng": 86.5074
            },
            "Pilachikare": {
                "lat": 12.0855,
                "lng": 75.2545
            },
            "Pattikonda": {
                "lat": 15.4,
                "lng": 77.5167
            },
            "Nalakadoddi": {
                "lat": 15.4,
                "lng": 77.5167
            },
            "Aralam": {
                "lat": 11.9992,
                "lng": 75.764
            },
            "Khunti": {
                "lat": 23.081,
                "lng": 85.2774
            },
            "Rasr\u0101": {
                "lat": 25.85,
                "lng": 83.85
            },
            "Garaim\u0101ri": {
                "lat": 24.0217,
                "lng": 88.6263
            },
            "Maddagiri": {
                "lat": 13.66,
                "lng": 77.21
            },
            "Beld\u0101nga": {
                "lat": 24.7041,
                "lng": 87.873
            },
            "R\u0101ghopur": {
                "lat": 25.318,
                "lng": 87.008
            },
            "Pallijkarani": {
                "lat": 12.9333,
                "lng": 80.2022
            },
            "Mahendragarh": {
                "lat": 28.28,
                "lng": 76.15
            },
            "Nakrekal": {
                "lat": 17.1647,
                "lng": 79.4275
            },
            "S\u0101nkr\u0101il": {
                "lat": 22.5583,
                "lng": 88.2278
            },
            "Colgong": {
                "lat": 25.2633,
                "lng": 87.2326
            },
            "Son\u0101mukhi": {
                "lat": 23.3,
                "lng": 87.42
            },
            "K\u0101lappatti": {
                "lat": 11.0794,
                "lng": 77.0371
            },
            "Vattalkundu": {
                "lat": 10.163,
                "lng": 77.758
            },
            "Uttamap\u0101laiyam": {
                "lat": 9.8,
                "lng": 77.3333
            },
            "Deodrug": {
                "lat": 16.4167,
                "lng": 76.9341
            },
            "Vallikunnam": {
                "lat": 9.1167,
                "lng": 76.5333
            },
            "Vandiy\u016br": {
                "lat": 9.9092,
                "lng": 78.1609
            },
            "Ponmana": {
                "lat": 8.3552,
                "lng": 77.3283
            },
            "G\u016bdal\u016br": {
                "lat": 10.7898,
                "lng": 78.1285
            },
            "Plach\u0113ri": {
                "lat": 11.8361,
                "lng": 76.175
            },
            "Kail\u0101ras": {
                "lat": 26.305,
                "lng": 77.616
            },
            "Kuttuparamba": {
                "lat": 11.8278,
                "lng": 75.5659
            },
            "Ariyal\u016br": {
                "lat": 11.1372,
                "lng": 79.0778
            },
            "N\u0101han": {
                "lat": 30.55,
                "lng": 77.3
            },
            "Karippira": {
                "lat": 8.95,
                "lng": 76.7167
            },
            "L\u0101dwa": {
                "lat": 29.9958,
                "lng": 77.04
            },
            "Gidri": {
                "lat": 30.8461,
                "lng": 75.8623
            },
            "Pokhr\u0101m": {
                "lat": 25.9358,
                "lng": 86.1974
            },
            "K\u016bdligi": {
                "lat": 14.905,
                "lng": 76.3853
            },
            "V\u012brap\u0101ndi": {
                "lat": 11.1723,
                "lng": 76.9692
            },
            "Tiruvambadi": {
                "lat": 11.3608,
                "lng": 76.0089
            },
            "Narsiml\u0101pet": {
                "lat": 17.51,
                "lng": 79.8044
            },
            "Kamal\u0101puram": {
                "lat": 18.16,
                "lng": 79.5406
            },
            "Ponmala": {
                "lat": 11.0221,
                "lng": 76.0432
            },
            "Landhaura": {
                "lat": 29.82,
                "lng": 77.93
            },
            "Umrat": {
                "lat": 21.1152,
                "lng": 72.857
            },
            "Kozhinjamp\u0101ra": {
                "lat": 10.7333,
                "lng": 76.85
            },
            "Nongstoin": {
                "lat": 25.52,
                "lng": 91.27
            },
            "R\u0101ikot": {
                "lat": 30.65,
                "lng": 75.6
            },
            "Murl\u012bganj": {
                "lat": 25.9,
                "lng": 86.98
            },
            "Nauhata": {
                "lat": 25.9971,
                "lng": 86.4887
            },
            "Patt\u0101mbi": {
                "lat": 10.8057,
                "lng": 76.1957
            },
            "Paw\u0101yan": {
                "lat": 28.0663,
                "lng": 80.1031
            },
            "Neduvann\u016br": {
                "lat": 10.1167,
                "lng": 76.35
            },
            "Edayikunnam": {
                "lat": 10.1009,
                "lng": 76.3713
            },
            "Akkattettar": {
                "lat": 10.8167,
                "lng": 76.65
            },
            "Simri Bakhri\u0101rpur": {
                "lat": 25.7216,
                "lng": 86.5937
            },
            "Sottaiyamp\u0101laiyam": {
                "lat": 11.4053,
                "lng": 77.6794
            },
            "Manampizha": {
                "lat": 9.2972,
                "lng": 76.65
            },
            "Munnalam": {
                "lat": 9.3317,
                "lng": 76.6054
            },
            "B\u0101gha Pur\u0101na": {
                "lat": 30.6881,
                "lng": 75.0984
            },
            "Losal": {
                "lat": 27.4,
                "lng": 74.92
            },
            "Rajpur": {
                "lat": 21.9402,
                "lng": 75.1361
            },
            "P\u0101rdi": {
                "lat": 20.52,
                "lng": 72.95
            },
            "P\u0101vugada": {
                "lat": 14.1,
                "lng": 77.28
            },
            "Nenmem": {
                "lat": 11.6333,
                "lng": 76.2611
            },
            "Elanjivaliseri": {
                "lat": 8.95,
                "lng": 76.6667
            },
            "Har\u016br": {
                "lat": 12.051,
                "lng": 78.4827
            },
            "B\u0101nsw\u0101da": {
                "lat": 18.3833,
                "lng": 77.8833
            },
            "R\u0101manayyapeta": {
                "lat": 17.3203,
                "lng": 82.1014
            },
            "Mah\u0101r\u0101jpur": {
                "lat": 25.2087,
                "lng": 87.7458
            },
            "Mannanch\u014dri": {
                "lat": 9.5764,
                "lng": 76.3481
            },
            "Br\u0101hmana Periya Agrah\u0101ram": {
                "lat": 11.369,
                "lng": 77.7063
            },
            "Puvali": {
                "lat": 11.793,
                "lng": 76.165
            },
            "Tar\u0101na": {
                "lat": 23.33,
                "lng": 76.03
            },
            "Kizhakk\u014dtt": {
                "lat": 11.3583,
                "lng": 75.8986
            },
            "Puttankulam": {
                "lat": 8.8111,
                "lng": 76.7152
            },
            "S\u0101ho": {
                "lat": 25.9718,
                "lng": 86.1537
            },
            "Srungavarapukota": {
                "lat": 18.1167,
                "lng": 83.1667
            },
            "Karanji\u0101": {
                "lat": 21.7626,
                "lng": 85.9732
            },
            "H\u0101jipur": {
                "lat": 31.9771,
                "lng": 75.7544
            },
            "Edappalli": {
                "lat": 9.8167,
                "lng": 76.3
            },
            "Antur": {
                "lat": 11.987,
                "lng": 75.376
            },
            "Sirs\u0101ganj": {
                "lat": 27.0569,
                "lng": 78.6861
            },
            "Kotagiri": {
                "lat": 11.43,
                "lng": 76.88
            },
            "Chennamangalam": {
                "lat": 10.1797,
                "lng": 76.2097
            },
            "Bhambia Bhai": {
                "lat": 30.4812,
                "lng": 75.094
            },
            "S\u0101ngola": {
                "lat": 17.4378,
                "lng": 75.1939
            },
            "Sheohar": {
                "lat": 26.52,
                "lng": 85.3
            },
            "Kuli": {
                "lat": 24.7366,
                "lng": 87.9426
            },
            "Kalw\u0101kurti": {
                "lat": 16.65,
                "lng": 78.48
            },
            "Devanhalli": {
                "lat": 13.23,
                "lng": 77.7
            },
            "Gingee": {
                "lat": 12.2528,
                "lng": 79.4173
            },
            "S\u0101ila": {
                "lat": 25.333,
                "lng": 72.367
            },
            "K\u0101r\u0101kurisshi": {
                "lat": 10.9667,
                "lng": 76.4917
            },
            "Kushtagi": {
                "lat": 15.7562,
                "lng": 76.1911
            },
            "Kanav\u0101ikuli": {
                "lat": 9.85,
                "lng": 76.94
            },
            "\u0100ron": {
                "lat": 24.3811,
                "lng": 77.4174
            },
            "Dhabauli": {
                "lat": 25.8346,
                "lng": 86.8057
            },
            "Goriar": {
                "lat": 25.5513,
                "lng": 87.2046
            },
            "Vil\u0101ngudi": {
                "lat": 9.9458,
                "lng": 78.0935
            },
            "Malmal": {
                "lat": 26.4833,
                "lng": 86.05
            },
            "Umarg\u0101m": {
                "lat": 20.2,
                "lng": 72.75
            },
            "B\u0101rughutu": {
                "lat": 23.8038,
                "lng": 85.5555
            },
            "W\u0101si": {
                "lat": 18.5422,
                "lng": 75.7789
            },
            "Nibria": {
                "lat": 22.61,
                "lng": 88.25
            },
            "M\u0101tt\u016bl": {
                "lat": 11.958,
                "lng": 75.2942
            },
            "Vadnagar": {
                "lat": 23.785,
                "lng": 72.64
            },
            "Charkh\u0101ri": {
                "lat": 25.4,
                "lng": 79.75
            },
            "Paivalike": {
                "lat": 12.6875,
                "lng": 74.9833
            },
            "Kh\u0101tegaon": {
                "lat": 22.5957,
                "lng": 76.9133
            },
            "Tudiyal\u016br": {
                "lat": 11.0816,
                "lng": 76.9446
            },
            "Poruvakara": {
                "lat": 9.0667,
                "lng": 76.6333
            },
            "Amarpur": {
                "lat": 23.53,
                "lng": 91.64
            },
            "Tangut\u016bru": {
                "lat": 15.35,
                "lng": 80.05
            },
            "Mukher": {
                "lat": 18.7008,
                "lng": 77.3628
            },
            "Parsa": {
                "lat": 25.7721,
                "lng": 86.1754
            },
            "Pachor": {
                "lat": 23.7098,
                "lng": 76.7339
            },
            "P\u0101diyanall\u016br": {
                "lat": 13.2004,
                "lng": 80.1761
            },
            "Kantai": {
                "lat": 26.2142,
                "lng": 85.2975
            },
            "Rardhu": {
                "lat": 22.643,
                "lng": 72.7762
            },
            "R\u0101yamangalam": {
                "lat": 10.0879,
                "lng": 76.5088
            },
            "Patjirwa": {
                "lat": 26.8084,
                "lng": 84.3847
            },
            "S\u016bl\u016bru": {
                "lat": 13.7,
                "lng": 80.018
            },
            "N\u0101sriganj": {
                "lat": 25.0514,
                "lng": 84.3284
            },
            "Bandipura": {
                "lat": 34.4167,
                "lng": 74.65
            },
            "Konn\u016br": {
                "lat": 15.8595,
                "lng": 75.4955
            },
            "Vandikarai": {
                "lat": 9.9912,
                "lng": 76.805
            },
            "S\u0101dri": {
                "lat": 25.18,
                "lng": 73.43
            },
            "Ayyagarpet": {
                "lat": 17.2197,
                "lng": 80.8339
            },
            "Nalb\u0101ri": {
                "lat": 26.445,
                "lng": 91.44
            },
            "Penukonda": {
                "lat": 14.085,
                "lng": 77.596
            },
            "Balussheri": {
                "lat": 11.45,
                "lng": 75.83
            },
            "Varandarapilli": {
                "lat": 10.4167,
                "lng": 76.3333
            },
            "Bihpur": {
                "lat": 25.3889,
                "lng": 86.9337
            },
            "Pallippurattus\u0113ri": {
                "lat": 9.7566,
                "lng": 76.3607
            },
            "Bh\u0101nder": {
                "lat": 25.7358,
                "lng": 78.7456
            },
            "Haliy\u0101l": {
                "lat": 15.3294,
                "lng": 74.7633
            },
            "Debi\u0101pur": {
                "lat": 26.6358,
                "lng": 79.5733
            },
            "Ezhipram": {
                "lat": 9.8233,
                "lng": 76.31
            },
            "Digboi": {
                "lat": 27.3932,
                "lng": 95.6184
            },
            "Parimp\u016bdi": {
                "lat": 17.1171,
                "lng": 81.4342
            },
            "Afzalpur": {
                "lat": 17.2011,
                "lng": 76.3611
            },
            "Irimbiliyam": {
                "lat": 10.8667,
                "lng": 76.0833
            },
            "Kuzhittura": {
                "lat": 8.3165,
                "lng": 77.2061
            },
            "R\u0101ver": {
                "lat": 21.2431,
                "lng": 76.0333
            },
            "Malebenn\u016br": {
                "lat": 14.3537,
                "lng": 75.74
            },
            "M\u0101dhura": {
                "lat": 26.3388,
                "lng": 87.1027
            },
            "\u0100mangal": {
                "lat": 16.8499,
                "lng": 78.5327
            },
            "B\u0101gepalli": {
                "lat": 13.78,
                "lng": 77.79
            },
            "P\u0101yakar\u0101opeta": {
                "lat": 17.3627,
                "lng": 82.5572
            },
            "Basi": {
                "lat": 30.5872,
                "lng": 76.8428
            },
            "Kadappuram": {
                "lat": 10.55,
                "lng": 76.1167
            },
            "Nainijor": {
                "lat": 25.6811,
                "lng": 84.3456
            },
            "Nelliyalam": {
                "lat": 11.5255,
                "lng": 76.3589
            },
            "Margherita": {
                "lat": 27.28,
                "lng": 95.68
            },
            "Maner": {
                "lat": 25.65,
                "lng": 84.88
            },
            "Kulasekharapuram": {
                "lat": 8.15,
                "lng": 77.5
            },
            "P\u0101pp\u0101kurichchi": {
                "lat": 10.8137,
                "lng": 78.7481
            },
            "P\u0101midi": {
                "lat": 14.95,
                "lng": 77.5833
            },
            "Kadamban\u0101d": {
                "lat": 9.1167,
                "lng": 76.6833
            },
            "Karar\u0101n": {
                "lat": 30.772,
                "lng": 76.7959
            },
            "Arer\u0101j": {
                "lat": 26.5527,
                "lng": 84.6726
            },
            "Madattukkulam": {
                "lat": 10.5587,
                "lng": 77.366
            },
            "K\u0101lik\u0101vu": {
                "lat": 11.172,
                "lng": 76.3211
            },
            "Gursar\u0101i": {
                "lat": 25.62,
                "lng": 79.18
            },
            "P\u016bnch": {
                "lat": 33.7703,
                "lng": 74.0925
            },
            "V\u0101dippatti": {
                "lat": 10.0843,
                "lng": 77.9634
            },
            "Bank\u0101pur": {
                "lat": 14.923,
                "lng": 75.2622
            },
            "Shencottah": {
                "lat": 8.9733,
                "lng": 77.2464
            },
            "Magilampupuram": {
                "lat": 8.8408,
                "lng": 78.1497
            },
            "T\u016bv\u016br": {
                "lat": 11.1,
                "lng": 76.2833
            },
            "Sr\u012bniv\u0101spur": {
                "lat": 13.3378,
                "lng": 78.2122
            },
            "Trikkunnapuzha": {
                "lat": 9.25,
                "lng": 76.4
            },
            "Th\u0101tha": {
                "lat": 25.4988,
                "lng": 86.5908
            },
            "Gadhada": {
                "lat": 21.97,
                "lng": 71.57
            },
            "Mepp\u0101yy\u016br": {
                "lat": 11.5167,
                "lng": 75.7
            },
            "Soron": {
                "lat": 27.88,
                "lng": 78.75
            },
            "Alatt\u016br": {
                "lat": 10.6481,
                "lng": 76.5383
            },
            "Bhuban": {
                "lat": 20.882,
                "lng": 85.8333
            },
            "M\u0101mid\u0101lap\u0101du": {
                "lat": 15.831,
                "lng": 78.05
            },
            "Wellington": {
                "lat": 11.3655,
                "lng": 76.7844
            },
            "Nediyanad": {
                "lat": 11.1297,
                "lng": 76.0021
            },
            "Nannamukku": {
                "lat": 10.7167,
                "lng": 76.0333
            },
            "Tal\u0101ja": {
                "lat": 21.35,
                "lng": 72.05
            },
            "Kupp\u0101di": {
                "lat": 11.6792,
                "lng": 76.2625
            },
            "Dalsingh Sarai": {
                "lat": 25.668,
                "lng": 85.8364
            },
            "Chettip\u0101laiyam": {
                "lat": 10.9125,
                "lng": 77.037
            },
            "Silao": {
                "lat": 25.0836,
                "lng": 85.428
            },
            "Manih\u0101ri": {
                "lat": 25.35,
                "lng": 87.63
            },
            "Lauri": {
                "lat": 25.1396,
                "lng": 80.0011
            },
            "Ghat\u0101ro Chaturbhuj": {
                "lat": 25.8146,
                "lng": 85.1862
            },
            "Sh\u0101hganj": {
                "lat": 22.8467,
                "lng": 77.798
            },
            "Man\u0101sa": {
                "lat": 24.48,
                "lng": 75.15
            },
            "Das\u016bya": {
                "lat": 31.8168,
                "lng": 75.6531
            },
            "Perumbalam": {
                "lat": 9.85,
                "lng": 76.3667
            },
            "Ny\u0101mti": {
                "lat": 14.14,
                "lng": 75.56
            },
            "Deogarh": {
                "lat": 21.5383,
                "lng": 84.7334
            },
            "Kant\u0101b\u0101nji": {
                "lat": 20.4671,
                "lng": 82.9204
            },
            "Ahmad\u0101b\u0101d": {
                "lat": 25.3019,
                "lng": 87.7938
            },
            "Karum\u0101ndi Chellip\u0101laiyam": {
                "lat": 11.3019,
                "lng": 77.586
            },
            "Maski": {
                "lat": 15.9581,
                "lng": 76.6569
            },
            "T\u0101marakulam": {
                "lat": 9.4833,
                "lng": 76.5
            },
            "P\u0101nchla": {
                "lat": 22.54,
                "lng": 88.14
            },
            "Mandi": {
                "lat": 31.7069,
                "lng": 76.9317
            },
            "M\u0101velikara": {
                "lat": 9.267,
                "lng": 76.55
            },
            "Mauganj": {
                "lat": 24.68,
                "lng": 81.88
            },
            "Beoh\u0101ri": {
                "lat": 24.0242,
                "lng": 81.3783
            },
            "N\u0101dbai": {
                "lat": 27.23,
                "lng": 77.2
            },
            "D\u012bn\u0101nagar": {
                "lat": 32.15,
                "lng": 75.4667
            },
            "V\u012brakeralam": {
                "lat": 11.0077,
                "lng": 76.9126
            },
            "Rangia": {
                "lat": 26.47,
                "lng": 91.63
            },
            "Elak\u0101du": {
                "lat": 8.9009,
                "lng": 76.8357
            },
            "Gundlup\u0113t": {
                "lat": 11.8,
                "lng": 76.68
            },
            "Reoti": {
                "lat": 25.85,
                "lng": 84.3833
            },
            "Panch\u0101nandapur": {
                "lat": 24.9339,
                "lng": 87.9761
            },
            "Cheranall\u016br": {
                "lat": 10.1807,
                "lng": 76.4792
            },
            "Ubaidull\u0101hganj": {
                "lat": 22.9983,
                "lng": 77.5862
            },
            "Koelw\u0101r": {
                "lat": 25.5805,
                "lng": 84.7975
            },
            "Narasannapeta": {
                "lat": 18.4167,
                "lng": 84.05
            },
            "Rasi\u0101ri": {
                "lat": 26.0464,
                "lng": 86.3471
            },
            "Mirganj": {
                "lat": 26.3638,
                "lng": 84.364
            },
            "Mel Bhuvanagiri": {
                "lat": 11.438,
                "lng": 79.6328
            },
            "K\u016bdlu": {
                "lat": 12.526,
                "lng": 74.9687
            },
            "Tekkalakote": {
                "lat": 15.5348,
                "lng": 76.8785
            },
            "T\u0101l\u012bkota": {
                "lat": 16.48,
                "lng": 76.32
            },
            "Sogr\u0101ha": {
                "lat": 25.4798,
                "lng": 85.9992
            },
            "Vadakkanandal": {
                "lat": 11.7739,
                "lng": 78.8666
            },
            "Buddayyakota": {
                "lat": 13.8196,
                "lng": 78.1729
            },
            "Kottakota": {
                "lat": 17.7513,
                "lng": 82.7004
            },
            "Guruz\u0101la": {
                "lat": 16.58,
                "lng": 79.57
            },
            "Budhl\u0101da": {
                "lat": 29.93,
                "lng": 75.57
            },
            "Jirwa": {
                "lat": 26.0064,
                "lng": 86.8604
            },
            "Mank\u0101char": {
                "lat": 25.53,
                "lng": 89.87
            },
            "Dum Duma": {
                "lat": 27.5688,
                "lng": 95.5566
            },
            "Su\u0101r": {
                "lat": 29.027,
                "lng": 79.057
            },
            "G\u016bd\u016bru": {
                "lat": 15.775,
                "lng": 77.807
            },
            "R\u012bngas": {
                "lat": 27.37,
                "lng": 75.57
            },
            "Bijbi\u0101ra": {
                "lat": 33.7938,
                "lng": 75.107
            },
            "B\u0101runi": {
                "lat": 25.4751,
                "lng": 85.9681
            },
            "Vadakku Viravanallur": {
                "lat": 8.6979,
                "lng": 77.5192
            },
            "Elangunnapuzha": {
                "lat": 10.0167,
                "lng": 76.2167
            },
            "Phulera": {
                "lat": 26.874,
                "lng": 75.2417
            },
            "Terd\u0101l": {
                "lat": 16.5,
                "lng": 75.05
            },
            "Renigunta": {
                "lat": 13.65,
                "lng": 79.52
            },
            "S\u0101ndi": {
                "lat": 27.3,
                "lng": 79.95
            },
            "Gahmar": {
                "lat": 25.497,
                "lng": 83.822
            },
            "Mangaldai": {
                "lat": 26.43,
                "lng": 92.03
            },
            "Dharmapuram": {
                "lat": 8.1203,
                "lng": 77.389
            },
            "Chintalap\u016bdi": {
                "lat": 17.0417,
                "lng": 80.5901
            },
            "Masrakh": {
                "lat": 26.1054,
                "lng": 84.8026
            },
            "Mannachchanell\u016br": {
                "lat": 10.9078,
                "lng": 78.7022
            },
            "Krishn\u0101puram": {
                "lat": 9.15,
                "lng": 76.5
            },
            "Jaynagar-Majilpur": {
                "lat": 22.1752,
                "lng": 88.4201
            },
            "Gilarch\u0101t": {
                "lat": 22.0703,
                "lng": 88.4455
            },
            "Chevella": {
                "lat": 17.3067,
                "lng": 78.1353
            },
            "Dhrol": {
                "lat": 22.57,
                "lng": 70.42
            },
            "Vesala": {
                "lat": 12.162,
                "lng": 75.4956
            },
            "Jharka": {
                "lat": 26.3047,
                "lng": 85.6686
            },
            "Perunkalattu": {
                "lat": 12.9182,
                "lng": 80.0824
            },
            "Kotal": {
                "lat": 31.757,
                "lng": 76.8729
            },
            "Gokarn": {
                "lat": 14.55,
                "lng": 74.3167
            },
            "Polasara": {
                "lat": 19.6939,
                "lng": 84.814
            },
            "K\u0101rkala": {
                "lat": 13.2,
                "lng": 74.983
            },
            "Vayakkalattu": {
                "lat": 8.5285,
                "lng": 77.1035
            },
            "R\u0101man": {
                "lat": 29.9504,
                "lng": 74.9785
            },
            "Kadamalaikkundu": {
                "lat": 9.811,
                "lng": 77.5039
            },
            "L\u0101dkhed": {
                "lat": 20.32,
                "lng": 77.77
            },
            "Mungaoli": {
                "lat": 24.4084,
                "lng": 78.0959
            },
            "Chhota Udepur": {
                "lat": 22.3056,
                "lng": 74.0139
            },
            "Karavaram": {
                "lat": 8.7127,
                "lng": 76.9524
            },
            "Koba": {
                "lat": 21.2844,
                "lng": 72.8763
            },
            "Bishunpur Sundar": {
                "lat": 26.0579,
                "lng": 86.9833
            },
            "Seondha": {
                "lat": 26.1542,
                "lng": 78.7812
            },
            "Cherukolattur": {
                "lat": 11.1881,
                "lng": 75.9173
            },
            "Chinna Salem": {
                "lat": 11.6342,
                "lng": 78.8741
            },
            "Vork\u0101di": {
                "lat": 12.7598,
                "lng": 74.9311
            },
            "Saf\u012bpur": {
                "lat": 26.73,
                "lng": 80.35
            },
            "N\u0101god": {
                "lat": 24.5692,
                "lng": 80.5881
            },
            "Net\u0101pur T\u0101nda": {
                "lat": 16.8321,
                "lng": 79.4356
            },
            "Nanminda": {
                "lat": 11.4222,
                "lng": 75.8319
            },
            "K\u0101ko": {
                "lat": 25.2259,
                "lng": 85.0642
            },
            "Tarkeshwar": {
                "lat": 21.0957,
                "lng": 72.8225
            },
            "Sirumugai": {
                "lat": 11.3214,
                "lng": 77.0052
            },
            "Chainpura": {
                "lat": 25.52,
                "lng": 86.31
            },
            "Nagar": {
                "lat": 24.0898,
                "lng": 87.9902
            },
            "Raghun\u0101thpur": {
                "lat": 26.0019,
                "lng": 84.2753
            },
            "Vij\u0101pur": {
                "lat": 23.57,
                "lng": 72.75
            },
            "Niramaruth\u016br": {
                "lat": 10.9267,
                "lng": 75.9033
            },
            "Poranki": {
                "lat": 16.4743,
                "lng": 80.7128
            },
            "Nettapp\u0101kkam": {
                "lat": 11.8663,
                "lng": 79.6327
            },
            "Mannadipattu": {
                "lat": 11.9842,
                "lng": 79.6321
            },
            "Bathn\u0101ha": {
                "lat": 26.6433,
                "lng": 85.5747
            },
            "Pauri": {
                "lat": 30.15,
                "lng": 78.78
            },
            "K\u012bran\u016br": {
                "lat": 11.692,
                "lng": 79.2899
            },
            "Chengann\u016br": {
                "lat": 9.3157,
                "lng": 76.6151
            },
            "Non\u0101hi": {
                "lat": 26.7892,
                "lng": 85.6482
            },
            "Karun\u0101gapalli": {
                "lat": 9.0544,
                "lng": 76.5353
            },
            "Timurni": {
                "lat": 22.3712,
                "lng": 77.2274
            },
            "Jevargi": {
                "lat": 17.0139,
                "lng": 76.7732
            },
            "B\u0101bra": {
                "lat": 21.8442,
                "lng": 71.3042
            },
            "Merk\u0101nam": {
                "lat": 12.1942,
                "lng": 79.9435
            },
            "W\u0101di": {
                "lat": 17.07,
                "lng": 76.98
            },
            "Ilaiy\u0101nkudi": {
                "lat": 9.6271,
                "lng": 78.6247
            },
            "Mudgal": {
                "lat": 16.0119,
                "lng": 76.442
            },
            "Saranga": {
                "lat": 22.54,
                "lng": 88.21
            },
            "Uttaramer\u016br": {
                "lat": 12.616,
                "lng": 79.753
            },
            "Kola": {
                "lat": 22.43,
                "lng": 87.87
            },
            "L\u0101teh\u0101r": {
                "lat": 23.7442,
                "lng": 84.4998
            },
            "P\u0101onta S\u0101hib": {
                "lat": 30.438,
                "lng": 77.624
            },
            "Tiror\u0101": {
                "lat": 21.4072,
                "lng": 79.9326
            },
            "Banhatti": {
                "lat": 16.3853,
                "lng": 75.1494
            },
            "V\u0101sudevanall\u016br": {
                "lat": 9.2417,
                "lng": 77.4118
            },
            "Gul\u0101m": {
                "lat": 25.7965,
                "lng": 86.7416
            },
            "Kasr\u0101wad": {
                "lat": 22.1274,
                "lng": 75.611
            },
            "Pan\u0101gar": {
                "lat": 23.3,
                "lng": 79.98
            },
            "Pallappatti": {
                "lat": 10.3564,
                "lng": 77.9516
            },
            "S\u0101hibpur Kam\u0101l": {
                "lat": 25.4167,
                "lng": 86.3901
            },
            "Sohwal": {
                "lat": 26.75,
                "lng": 81.9861
            },
            "R\u0101nia": {
                "lat": 29.53,
                "lng": 74.83
            },
            "Saiha": {
                "lat": 22.48,
                "lng": 92.97
            },
            "N\u0101mrup": {
                "lat": 27.1939,
                "lng": 95.3193
            },
            "Kadav\u016br": {
                "lat": 9.3333,
                "lng": 76.5333
            },
            "Sahjanwa": {
                "lat": 26.75,
                "lng": 83.2167
            },
            "Raj\u0101kheri": {
                "lat": 23.8593,
                "lng": 78.7852
            },
            "J\u0101far\u0101b\u0101d": {
                "lat": 20.8667,
                "lng": 71.3667
            },
            "Shr\u012brangapattana": {
                "lat": 12.414,
                "lng": 76.704
            },
            "Nab\u012bnagar": {
                "lat": 24.62,
                "lng": 84.12
            },
            "Soh\u0101gpur": {
                "lat": 22.7,
                "lng": 78.2
            },
            "Barwa S\u0101gar": {
                "lat": 25.3799,
                "lng": 78.739
            },
            "Barki Ballia": {
                "lat": 25.4223,
                "lng": 86.2758
            },
            "Simria": {
                "lat": 25.9663,
                "lng": 85.8324
            },
            "Trikodi": {
                "lat": 11.4667,
                "lng": 75.6167
            },
            "Hukeri": {
                "lat": 16.23,
                "lng": 74.6
            },
            "Naubatpur": {
                "lat": 25.5,
                "lng": 84.9611
            },
            "Pathiyanikunnu": {
                "lat": 8.7733,
                "lng": 76.8801
            },
            "Attili": {
                "lat": 16.7,
                "lng": 81.6
            },
            "Khed Brahma": {
                "lat": 24.0283,
                "lng": 73.0414
            },
            "Ayir\u016brp\u0101ra": {
                "lat": 8.503,
                "lng": 76.9522
            },
            "P\u0101rner": {
                "lat": 19.003,
                "lng": 74.438
            },
            "L\u0101lru": {
                "lat": 30.4867,
                "lng": 76.8006
            },
            "Singh\u0101na": {
                "lat": 22.1902,
                "lng": 74.9702
            },
            "Kalamner": {
                "lat": 20.4444,
                "lng": 78.3256
            },
            "Soj\u012btra": {
                "lat": 22.55,
                "lng": 72.7197
            },
            "M\u0101dha": {
                "lat": 29.1966,
                "lng": 76.0653
            },
            "Ch\u0101nasma": {
                "lat": 23.72,
                "lng": 72.12
            },
            "Voduriv\u0101ndlag\u016bdem": {
                "lat": 16.4286,
                "lng": 81.133
            },
            "Khiria Jh\u0101nsi": {
                "lat": 25.4639,
                "lng": 78.5829
            },
            "L\u0101dol": {
                "lat": 23.6167,
                "lng": 72.7333
            },
            "Kali": {
                "lat": 19.9388,
                "lng": 77.7329
            },
            "Data": {
                "lat": 29.267,
                "lng": 75.995
            },
            "Malanv\u0101di": {
                "lat": 16.008,
                "lng": 73.687
            },
            "Simaria": {
                "lat": 24.795,
                "lng": 81.152
            },
            "Sidhaul\u012b": {
                "lat": 27.28,
                "lng": 80.83
            },
            "Tapa": {
                "lat": 30.2979,
                "lng": 75.3694
            },
            "Paduvari": {
                "lat": 13.8833,
                "lng": 74.6333
            },
            "Gajwel": {
                "lat": 17.8517,
                "lng": 78.6828
            },
            "Baind\u016br": {
                "lat": 13.866,
                "lng": 74.6333
            },
            "Mundargi": {
                "lat": 15.207,
                "lng": 75.884
            },
            "Nainpur": {
                "lat": 22.43,
                "lng": 80.12
            },
            "Dugda": {
                "lat": 23.7452,
                "lng": 86.1717
            },
            "Karukurti": {
                "lat": 10.2167,
                "lng": 76.3833
            },
            "K\u0101l\u0101vad": {
                "lat": 22.22,
                "lng": 70.38
            },
            "Edavanakad": {
                "lat": 10.015,
                "lng": 76.22
            },
            "Rang\u0101p\u0101ra": {
                "lat": 26.8377,
                "lng": 92.6688
            },
            "Mad\u016bru": {
                "lat": 14.7616,
                "lng": 78.6171
            },
            "Cherukara": {
                "lat": 9.4667,
                "lng": 76.6
            },
            "Valambur": {
                "lat": 11.0,
                "lng": 76.2
            },
            "Ghoti Budrukh": {
                "lat": 19.7167,
                "lng": 73.6333
            },
            "Sirmatpur": {
                "lat": 25.3235,
                "lng": 87.3836
            },
            "Banga": {
                "lat": 31.1887,
                "lng": 75.9949
            },
            "Turuttikkara": {
                "lat": 8.9438,
                "lng": 76.6039
            },
            "Nazareth": {
                "lat": 8.558,
                "lng": 77.96
            },
            "B\u0101sopatti": {
                "lat": 26.578,
                "lng": 86.0052
            },
            "Kuttampuzha": {
                "lat": 10.15,
                "lng": 76.7333
            },
            "Khal\u0101ri": {
                "lat": 23.6506,
                "lng": 85.0074
            },
            "Shivganj": {
                "lat": 25.15,
                "lng": 73.07
            },
            "Podat\u016brpeta": {
                "lat": 13.2817,
                "lng": 79.4854
            },
            "Mairwa": {
                "lat": 26.2322,
                "lng": 84.1635
            },
            "P\u0101napur": {
                "lat": 25.6729,
                "lng": 85.0715
            },
            "Patn\u0101garh": {
                "lat": 20.7083,
                "lng": 83.1326
            },
            "Narasingapuram": {
                "lat": 12.9728,
                "lng": 79.284
            },
            "Chandili": {
                "lat": 19.2467,
                "lng": 83.4058
            },
            "P\u0101sigh\u0101t": {
                "lat": 28.07,
                "lng": 95.33
            },
            "Naduvannur": {
                "lat": 11.4833,
                "lng": 75.7667
            },
            "P\u0101lakodu": {
                "lat": 12.3037,
                "lng": 78.073
            },
            "Shamgarh": {
                "lat": 24.18,
                "lng": 75.63
            },
            "Navalgund": {
                "lat": 15.57,
                "lng": 75.37
            },
            "P\u0101ta Kalidindi": {
                "lat": 16.5014,
                "lng": 81.2908
            },
            "R\u0101mdiri": {
                "lat": 25.3118,
                "lng": 86.1023
            },
            "Gorantla": {
                "lat": 13.9892,
                "lng": 77.7703
            },
            "Irug\u016br": {
                "lat": 11.0178,
                "lng": 77.0629
            },
            "Kottapeta": {
                "lat": 15.7913,
                "lng": 80.377
            },
            "Cheruv\u0101ranam": {
                "lat": 9.6522,
                "lng": 76.3179
            },
            "Sirkhandi Bhitha": {
                "lat": 26.6244,
                "lng": 85.7787
            },
            "Dungra Chhota": {
                "lat": 23.1667,
                "lng": 74.3
            },
            "Aklvidu": {
                "lat": 16.6,
                "lng": 81.3833
            },
            "Andhra Th\u0101rhi": {
                "lat": 26.3771,
                "lng": 86.3341
            },
            "Nij Khari": {
                "lat": 22.0929,
                "lng": 88.4288
            },
            "Tirumuruganp\u016bndi": {
                "lat": 11.1649,
                "lng": 77.3084
            },
            "Parambatt Kavu": {
                "lat": 11.3268,
                "lng": 75.8906
            },
            "Tuls\u012bpur": {
                "lat": 27.55,
                "lng": 82.42
            },
            "Pindw\u0101ra": {
                "lat": 24.7945,
                "lng": 73.055
            },
            "Dh\u0101mnagar": {
                "lat": 20.9141,
                "lng": 86.4468
            },
            "Khajur\u0101ho": {
                "lat": 24.85,
                "lng": 79.925
            },
            "Purw\u0101": {
                "lat": 26.47,
                "lng": 80.78
            },
            "Sahaspur": {
                "lat": 29.1208,
                "lng": 78.6208
            },
            "Sah\u0101war": {
                "lat": 27.8,
                "lng": 78.85
            },
            "Vinnam\u0101la": {
                "lat": 13.9074,
                "lng": 79.9086
            },
            "B\u016bndu": {
                "lat": 23.1609,
                "lng": 85.5901
            },
            "Channagiri": {
                "lat": 14.024,
                "lng": 75.9258
            },
            "Ashwar\u0101opeta": {
                "lat": 17.25,
                "lng": 81.1333
            },
            "Tirutturaipp\u016bndi": {
                "lat": 10.53,
                "lng": 79.65
            },
            "Garhara": {
                "lat": 25.4407,
                "lng": 85.9983
            },
            "Panmana": {
                "lat": 8.9333,
                "lng": 76.6167
            },
            "Asan": {
                "lat": 25.73,
                "lng": 74.33
            },
            "Nagarp\u0101ra": {
                "lat": 25.4155,
                "lng": 86.8832
            },
            "Paruth\u016br": {
                "lat": 10.8174,
                "lng": 76.1178
            },
            "Saidpur": {
                "lat": 25.5436,
                "lng": 86.564
            },
            "Singar\u0101yakonda": {
                "lat": 15.25,
                "lng": 80.0203
            },
            "Pallapatti": {
                "lat": 9.4664,
                "lng": 77.8219
            },
            "Iringal": {
                "lat": 11.5667,
                "lng": 75.6
            },
            "Bij\u0101war": {
                "lat": 24.6235,
                "lng": 79.4899
            },
            "Vel\u016br": {
                "lat": 11.1114,
                "lng": 78.0048
            },
            "Kolori\u0101ng": {
                "lat": 27.9167,
                "lng": 93.35
            },
            "Baheri": {
                "lat": 25.9426,
                "lng": 86.0471
            },
            "Mah\u0101r\u0101jgani": {
                "lat": 26.1075,
                "lng": 84.5025
            },
            "Ayanavelikulangara Tekku": {
                "lat": 9.0436,
                "lng": 76.5199
            },
            "Umga": {
                "lat": 24.6396,
                "lng": 84.5577
            },
            "May\u0101ng Imph\u0101l": {
                "lat": 24.6,
                "lng": 93.9
            },
            "V\u0113ttakk\u0101ranpud\u016br": {
                "lat": 10.5637,
                "lng": 76.9166
            },
            "Ponneri": {
                "lat": 12.5972,
                "lng": 78.5979
            },
            "N\u0101rav\u0101rikuppam": {
                "lat": 13.1913,
                "lng": 80.1847
            },
            "Halikner": {
                "lat": 17.85,
                "lng": 77.27
            },
            "Maksi": {
                "lat": 23.26,
                "lng": 76.1457
            },
            "Mogalturru": {
                "lat": 16.4167,
                "lng": 81.6
            },
            "Ukk\u0101yapalle": {
                "lat": 14.4898,
                "lng": 78.8927
            },
            "Kosigi": {
                "lat": 15.85,
                "lng": 77.2667
            },
            "Loha": {
                "lat": 18.9623,
                "lng": 77.1309
            },
            "Tiruvatt\u0101r": {
                "lat": 8.3307,
                "lng": 77.2673
            },
            "Sundararaopeta": {
                "lat": 16.8031,
                "lng": 81.6419
            },
            "Alwaye": {
                "lat": 10.1167,
                "lng": 76.35
            },
            "Jaisinghpur": {
                "lat": 26.6318,
                "lng": 84.7855
            },
            "Sangr\u0101mpur": {
                "lat": 25.0711,
                "lng": 86.1871
            },
            "Arttuv\u0101ttala": {
                "lat": 9.5167,
                "lng": 76.3167
            },
            "Siswa": {
                "lat": 26.6214,
                "lng": 84.6739
            },
            "S\u016bleswaranpatti": {
                "lat": 10.6388,
                "lng": 77.0084
            },
            "Y\u0101diki": {
                "lat": 15.05,
                "lng": 77.88
            },
            "Tehri": {
                "lat": 30.38,
                "lng": 78.48
            },
            "Maheshwar": {
                "lat": 22.11,
                "lng": 75.35
            },
            "Payipira": {
                "lat": 10.025,
                "lng": 76.5569
            },
            "Sikandarpur": {
                "lat": 25.3256,
                "lng": 86.5051
            },
            "Penn\u0101dam": {
                "lat": 11.4039,
                "lng": 79.2416
            },
            "Neduvatt\u016br": {
                "lat": 8.9931,
                "lng": 76.75
            },
            "Puyappalli": {
                "lat": 8.9,
                "lng": 76.75
            },
            "Usia": {
                "lat": 25.4309,
                "lng": 83.7075
            },
            "Sendamangalam": {
                "lat": 11.7431,
                "lng": 79.379
            },
            "M\u0101t\u0101bh\u0101nga": {
                "lat": 26.33,
                "lng": 89.22
            },
            "Mulgund": {
                "lat": 15.2807,
                "lng": 75.5213
            },
            "Peringuzha": {
                "lat": 9.9667,
                "lng": 76.4167
            },
            "Karian": {
                "lat": 25.8594,
                "lng": 86.0104
            },
            "Koilkuntla": {
                "lat": 15.2333,
                "lng": 78.3167
            },
            "Herohalli": {
                "lat": 12.9911,
                "lng": 77.4873
            },
            "Mulakum\u016bd": {
                "lat": 8.2681,
                "lng": 77.286
            },
            "Ann\u016br": {
                "lat": 11.2325,
                "lng": 77.1069
            },
            "Kuju": {
                "lat": 23.7254,
                "lng": 85.5102
            },
            "Hungund": {
                "lat": 16.0621,
                "lng": 76.0586
            },
            "Kumar Khad": {
                "lat": 26.5918,
                "lng": 86.264
            },
            "Sulya": {
                "lat": 12.561,
                "lng": 75.3874
            },
            "Bithar": {
                "lat": 26.6294,
                "lng": 85.7697
            },
            "D\u0101kor": {
                "lat": 22.75,
                "lng": 73.15
            },
            "Vallabh Vidyanagar": {
                "lat": 22.56,
                "lng": 72.95
            },
            "Tirumala": {
                "lat": 13.6833,
                "lng": 79.35
            },
            "Verukulambu": {
                "lat": 8.2953,
                "lng": 77.2939
            },
            "Elukone": {
                "lat": 8.9667,
                "lng": 76.7167
            },
            "L\u0101lgudi": {
                "lat": 10.87,
                "lng": 78.83
            },
            "Avanigadda": {
                "lat": 16.0197,
                "lng": 80.92
            },
            "B\u012brpur": {
                "lat": 32.6617,
                "lng": 74.9518
            },
            "Mummidivaram": {
                "lat": 16.65,
                "lng": 82.1167
            },
            "Puduva": {
                "lat": 9.977,
                "lng": 76.226
            },
            "Devikolam": {
                "lat": 10.0626,
                "lng": 77.104
            },
            "Karaval\u016br": {
                "lat": 8.9833,
                "lng": 76.925
            },
            "S\u012blapp\u0101di": {
                "lat": 10.394,
                "lng": 78.0078
            },
            "Tirukkalikkunram": {
                "lat": 12.6092,
                "lng": 80.0675
            },
            "An\u016bpshahr": {
                "lat": 28.37,
                "lng": 78.27
            },
            "Sanaur": {
                "lat": 30.3018,
                "lng": 76.4579
            },
            "\u0100langulam": {
                "lat": 8.864,
                "lng": 77.4994
            },
            "Yell\u0101pur": {
                "lat": 14.9637,
                "lng": 74.7093
            },
            "Chandrakona": {
                "lat": 22.73,
                "lng": 87.52
            },
            "Mannamturuttu": {
                "lat": 9.9008,
                "lng": 76.39
            },
            "Kulgam": {
                "lat": 33.64,
                "lng": 75.02
            },
            "Perungudi": {
                "lat": 8.2792,
                "lng": 77.6031
            },
            "Toda Rai Singh": {
                "lat": 26.0167,
                "lng": 75.4833
            },
            "P\u0113rav\u016br": {
                "lat": 11.8962,
                "lng": 75.7342
            },
            "Gollap\u016bdi": {
                "lat": 16.5412,
                "lng": 80.5784
            },
            "Dhing": {
                "lat": 26.4679,
                "lng": 92.4734
            },
            "Belsand": {
                "lat": 26.4436,
                "lng": 85.4008
            },
            "Hon\u0101var": {
                "lat": 14.2809,
                "lng": 74.445
            },
            "Arukutti": {
                "lat": 9.8309,
                "lng": 76.427
            },
            "N\u0101yanakulam": {
                "lat": 9.9611,
                "lng": 78.1371
            },
            "Koili Simra": {
                "lat": 25.4714,
                "lng": 87.2175
            },
            "Chanaur": {
                "lat": 25.851,
                "lng": 86.6739
            },
            "Gauripur": {
                "lat": 26.08,
                "lng": 89.97
            },
            "Kh\u0101rupatia": {
                "lat": 26.5184,
                "lng": 92.1472
            },
            "Madangir": {
                "lat": 28.3553,
                "lng": 76.9327
            },
            "R\u0101mpura": {
                "lat": 14.881,
                "lng": 76.7814
            },
            "Badn\u0101war": {
                "lat": 23.0218,
                "lng": 75.2327
            },
            "Kail\u0101shahar": {
                "lat": 24.33,
                "lng": 92.02
            },
            "Srvanampatti": {
                "lat": 11.0764,
                "lng": 77.0045
            },
            "Burhar": {
                "lat": 23.2149,
                "lng": 81.532
            },
            "Ponda": {
                "lat": 15.4034,
                "lng": 74.0152
            },
            "Vanim\u0113l": {
                "lat": 11.7197,
                "lng": 75.699
            },
            "Dighwa": {
                "lat": 26.2437,
                "lng": 84.7532
            },
            "Sakleshpur": {
                "lat": 12.893,
                "lng": 75.725
            },
            "S\u0101ndwa": {
                "lat": 27.75,
                "lng": 74.1667
            },
            "Tazhava": {
                "lat": 9.0833,
                "lng": 76.5333
            },
            "Madappalli": {
                "lat": 9.0833,
                "lng": 76.5333
            },
            "Piravanth\u016br": {
                "lat": 9.05,
                "lng": 76.9167
            },
            "Mand\u0101wa": {
                "lat": 28.0554,
                "lng": 75.1483
            },
            "Minja": {
                "lat": 12.7083,
                "lng": 74.95
            },
            "Ron": {
                "lat": 15.67,
                "lng": 75.73
            },
            "Kedav\u016br": {
                "lat": 11.4167,
                "lng": 75.9333
            },
            "M\u016blampilli": {
                "lat": 10.1083,
                "lng": 76.4167
            },
            "Tek\u0101ri": {
                "lat": 24.9425,
                "lng": 84.8427
            },
            "Vaikam": {
                "lat": 9.75,
                "lng": 76.3928
            },
            "K\u016bn Puh\u0101l": {
                "lat": 33.0058,
                "lng": 76.5334
            },
            "Samr\u0101la": {
                "lat": 30.836,
                "lng": 76.1932
            },
            "Kedia": {
                "lat": 23.7945,
                "lng": 85.5872
            },
            "P\u0101tak\u0101k\u0101ni": {
                "lat": 16.34,
                "lng": 80.4908
            },
            "Manamb\u016br": {
                "lat": 8.7206,
                "lng": 76.7635
            },
            "Tranquebar": {
                "lat": 11.0292,
                "lng": 79.8494
            },
            "Kazhukambalam": {
                "lat": 10.0333,
                "lng": 76.4
            },
            "Ponmundam": {
                "lat": 10.9628,
                "lng": 75.9519
            },
            "Kannamangalam Tekku": {
                "lat": 9.2167,
                "lng": 76.4833
            },
            "Sonepur": {
                "lat": 20.8333,
                "lng": 83.9167
            },
            "Ravulapalem": {
                "lat": 16.4833,
                "lng": 81.9833
            },
            "Lalganj": {
                "lat": 26.1277,
                "lng": 80.7855
            },
            "Mahthi": {
                "lat": 25.7281,
                "lng": 86.0093
            },
            "Singh\u0101ra Buzurg": {
                "lat": 25.7964,
                "lng": 85.4539
            },
            "Vuliyattara": {
                "lat": 8.5933,
                "lng": 76.9506
            },
            "Son\u0101ri": {
                "lat": 27.0246,
                "lng": 95.0163
            },
            "Engandiy\u016br": {
                "lat": 10.5,
                "lng": 76.04
            },
            "Khirkiy\u0101n": {
                "lat": 22.1673,
                "lng": 76.8614
            },
            "Vil\u0101chcheri": {
                "lat": 9.8937,
                "lng": 78.0603
            },
            "Pallikondai": {
                "lat": 12.9167,
                "lng": 78.9333
            },
            "Asaf\u0101b\u0101d": {
                "lat": 19.365,
                "lng": 79.274
            },
            "Velugodu": {
                "lat": 15.7817,
                "lng": 78.6892
            },
            "Koiri Bigha": {
                "lat": 25.2167,
                "lng": 85.0667
            },
            "Anjarkandi": {
                "lat": 11.8822,
                "lng": 75.5097
            },
            "Kulattupp\u0101laiyam": {
                "lat": 10.7613,
                "lng": 77.5843
            },
            "Khair\u0101 Tol\u0101": {
                "lat": 26.8958,
                "lng": 84.1403
            },
            "Dindori": {
                "lat": 22.9414,
                "lng": 81.0797
            },
            "Vandiperiy\u0101r": {
                "lat": 9.57,
                "lng": 77.0906
            },
            "Latauna": {
                "lat": 26.0912,
                "lng": 86.8809
            },
            "Muttupet": {
                "lat": 10.395,
                "lng": 79.4935
            },
            "Abu": {
                "lat": 24.5925,
                "lng": 72.7083
            },
            "Bhir\u0101ha": {
                "lat": 25.7978,
                "lng": 86.0736
            },
            "Vadakarai K\u012bl Pid\u0101gai": {
                "lat": 9.0401,
                "lng": 77.2741
            },
            "Vellal\u016br": {
                "lat": 10.9775,
                "lng": 77.026
            },
            "Mah\u0101debnagar": {
                "lat": 24.6896,
                "lng": 87.9384
            },
            "Bhad\u0101s": {
                "lat": 25.5184,
                "lng": 86.4434
            },
            "Nay\u0101gaon": {
                "lat": 24.8015,
                "lng": 80.8962
            },
            "Chitarpur": {
                "lat": 23.5794,
                "lng": 85.6548
            },
            "Ahwa": {
                "lat": 20.75,
                "lng": 73.6833
            },
            "Pawni": {
                "lat": 20.78,
                "lng": 79.63
            },
            "Cheri\u0101l": {
                "lat": 17.9264,
                "lng": 78.9719
            },
            "Zunheboto": {
                "lat": 26.0146,
                "lng": 94.5264
            },
            "Pulim\u0101thu": {
                "lat": 8.742,
                "lng": 76.8941
            },
            "Achhnera": {
                "lat": 27.18,
                "lng": 77.77
            },
            "Gormi": {
                "lat": 26.6003,
                "lng": 78.5119
            },
            "Pariy\u0101puram": {
                "lat": 11.0146,
                "lng": 75.8746
            },
            "Kavallemm\u0101vu": {
                "lat": 9.4361,
                "lng": 76.8819
            },
            "Kodarm\u0101": {
                "lat": 24.4675,
                "lng": 85.594
            },
            "Kaler": {
                "lat": 25.118,
                "lng": 84.4955
            },
            "Kanchika": {
                "lat": 16.6856,
                "lng": 80.3892
            },
            "Nasrull\u0101hganj": {
                "lat": 22.6837,
                "lng": 77.2707
            },
            "Vern\u0101g": {
                "lat": 33.5377,
                "lng": 75.2503
            },
            "Vayan\u016br": {
                "lat": 12.0699,
                "lng": 75.6825
            },
            "Chunakara Vadakku": {
                "lat": 9.1853,
                "lng": 76.5995
            },
            "Curchorem": {
                "lat": 15.2603,
                "lng": 74.1083
            },
            "Ker\u016br": {
                "lat": 16.0138,
                "lng": 75.5463
            },
            "Madira": {
                "lat": 16.9167,
                "lng": 80.3667
            },
            "Kittanapalli": {
                "lat": 12.5133,
                "lng": 78.1844
            },
            "Mur\u0101dpur": {
                "lat": 25.8,
                "lng": 86.0055
            },
            "Nak\u016br": {
                "lat": 29.9189,
                "lng": 77.3044
            },
            "Dal\u0101n": {
                "lat": 25.603,
                "lng": 87.5592
            },
            "Patharia": {
                "lat": 23.8992,
                "lng": 79.1939
            },
            "Mallasamudram": {
                "lat": 11.4933,
                "lng": 78.0312
            },
            "Moir\u0101ng": {
                "lat": 24.349,
                "lng": 93.69
            },
            "Dip": {
                "lat": 26.2369,
                "lng": 86.3107
            },
            "Bagul\u0101": {
                "lat": 23.335,
                "lng": 88.644
            },
            "Pullappalli": {
                "lat": 9.6833,
                "lng": 76.6167
            },
            "Chincholi": {
                "lat": 17.4651,
                "lng": 77.4187
            },
            "Manimala": {
                "lat": 9.5736,
                "lng": 77.0819
            },
            "Timm\u0101pur": {
                "lat": 19.0484,
                "lng": 78.6414
            },
            "Hirpardangal": {
                "lat": 24.0474,
                "lng": 86.8648
            },
            "Manki": {
                "lat": 14.1789,
                "lng": 74.4818
            },
            "Cholavand\u0101n": {
                "lat": 10.0167,
                "lng": 77.9667
            },
            "Talayolaparambu": {
                "lat": 9.785,
                "lng": 76.4481
            },
            "R\u0101man Mandi": {
                "lat": 29.9504,
                "lng": 74.9619
            },
            "Pokhuria": {
                "lat": 25.2379,
                "lng": 87.6459
            },
            "Vengatt\u016br": {
                "lat": 13.0999,
                "lng": 79.9321
            },
            "Melila": {
                "lat": 9.0083,
                "lng": 76.85
            },
            "Bobleshwar": {
                "lat": 16.83,
                "lng": 75.73
            },
            "Katangi": {
                "lat": 21.7737,
                "lng": 79.8051
            },
            "Jogipet": {
                "lat": 17.8356,
                "lng": 78.0681
            },
            "Hulikal": {
                "lat": 11.3193,
                "lng": 76.7732
            },
            "Chavassh\u0113ri": {
                "lat": 11.9647,
                "lng": 75.6176
            },
            "Chatayamangalam": {
                "lat": 8.8421,
                "lng": 76.8644
            },
            "Kond\u0101zhi": {
                "lat": 10.727,
                "lng": 76.3954
            },
            "Pullanpallikonam": {
                "lat": 8.6976,
                "lng": 76.9582
            },
            "Aymangala": {
                "lat": 12.0333,
                "lng": 75.7333
            },
            "Sarea Kh\u0101s": {
                "lat": 26.635,
                "lng": 84.5483
            },
            "Junn\u0101rdev": {
                "lat": 22.2,
                "lng": 78.58
            },
            "Bermo": {
                "lat": 23.7878,
                "lng": 85.9353
            },
            "Perundurai": {
                "lat": 11.2777,
                "lng": 77.5837
            },
            "Gurmatk\u0101l": {
                "lat": 16.8677,
                "lng": 77.3909
            },
            "S\u0101mbhar": {
                "lat": 26.9121,
                "lng": 75.1836
            },
            "Taulah\u0101": {
                "lat": 26.9566,
                "lng": 84.0731
            },
            "Bhaw\u0101n\u012bgarh": {
                "lat": 30.27,
                "lng": 76.04
            },
            "Panniyann\u016br": {
                "lat": 11.7538,
                "lng": 75.5528
            },
            "Pr\u0101ntij": {
                "lat": 23.4384,
                "lng": 72.8572
            },
            "Tiruttangal": {
                "lat": 9.4833,
                "lng": 77.8333
            },
            "Nagla": {
                "lat": 29.01,
                "lng": 79.51
            },
            "Ankireddikuntap\u0101lem": {
                "lat": 15.9154,
                "lng": 80.2108
            },
            "Bhatpur\u012b": {
                "lat": 29.17,
                "lng": 79.16
            },
            "Chausa": {
                "lat": 25.5149,
                "lng": 83.897
            },
            "Singampunari": {
                "lat": 10.1815,
                "lng": 78.4231
            },
            "Athiringal": {
                "lat": 9.0667,
                "lng": 76.5
            },
            "Alang\u0101yam": {
                "lat": 12.6223,
                "lng": 78.7521
            },
            "Kundgol": {
                "lat": 15.2561,
                "lng": 75.2474
            },
            "Metekora": {
                "lat": 22.1189,
                "lng": 88.5772
            },
            "Dasn\u0101pur": {
                "lat": 19.4482,
                "lng": 78.6129
            },
            "Khorabar": {
                "lat": 26.741,
                "lng": 83.888
            },
            "Tiruverumb\u016br": {
                "lat": 10.7937,
                "lng": 78.769
            },
            "Zir\u0101pur": {
                "lat": 24.0222,
                "lng": 76.3771
            },
            "Adalh\u0101t": {
                "lat": 25.282,
                "lng": 82.9563
            },
            "Kondal\u0101mpatti": {
                "lat": 11.6262,
                "lng": 78.1189
            },
            "Pattiy\u016brgr\u0101mam": {
                "lat": 9.2,
                "lng": 76.5
            },
            "Gubbi": {
                "lat": 13.3122,
                "lng": 76.941
            },
            "Bakhri": {
                "lat": 25.5989,
                "lng": 86.2607
            },
            "Vellithiruthi": {
                "lat": 10.6434,
                "lng": 76.1624
            },
            "Sarap\u0101ka": {
                "lat": 17.6922,
                "lng": 80.8614
            },
            "Vayal\u0101r": {
                "lat": 9.775,
                "lng": 76.4292
            },
            "Katkol": {
                "lat": 15.95,
                "lng": 75.3
            },
            "Bh\u0101nvad": {
                "lat": 21.93,
                "lng": 69.78
            },
            "P\u012brmed": {
                "lat": 9.3667,
                "lng": 76.8708
            },
            "Talwandi S\u0101bo": {
                "lat": 29.9838,
                "lng": 75.082
            },
            "Per\u0101v\u016brani": {
                "lat": 10.3,
                "lng": 79.18
            },
            "Howli": {
                "lat": 26.4224,
                "lng": 90.98
            },
            "Husn\u0101b\u0101d": {
                "lat": 17.0667,
                "lng": 77.65
            },
            "K\u0101lch\u012bni": {
                "lat": 26.6889,
                "lng": 89.4483
            },
            "Pars\u0101garhi": {
                "lat": 26.0935,
                "lng": 87.0672
            },
            "\u0100dampur": {
                "lat": 31.4322,
                "lng": 75.7148
            },
            "Chhanera": {
                "lat": 21.623,
                "lng": 76.372
            },
            "Asw\u0101puram": {
                "lat": 17.8348,
                "lng": 80.8236
            },
            "Khandela": {
                "lat": 27.36,
                "lng": 75.3
            },
            "J\u0101wad": {
                "lat": 24.5992,
                "lng": 74.8626
            },
            "Naregal": {
                "lat": 15.5732,
                "lng": 75.808
            },
            "Jugi\u0101l": {
                "lat": 32.3684,
                "lng": 75.6782
            },
            "Bukkar\u0101yasamudram": {
                "lat": 14.6944,
                "lng": 77.6381
            },
            "Unchahra": {
                "lat": 24.3825,
                "lng": 80.7809
            },
            "Dr\u0101s": {
                "lat": 34.4468,
                "lng": 75.7586
            },
            "Kuppam": {
                "lat": 12.75,
                "lng": 78.37
            },
            "Ch\u0101kia": {
                "lat": 26.416,
                "lng": 85.0467
            },
            "Poch\u0101ram": {
                "lat": 17.4255,
                "lng": 78.645
            },
            "R\u0101mantali": {
                "lat": 12.05,
                "lng": 75.1833
            },
            "K\u0101chh\u0101ri": {
                "lat": 22.0808,
                "lng": 88.6511
            },
            "Vanthli": {
                "lat": 21.4778,
                "lng": 70.3306
            },
            "Rajauli": {
                "lat": 24.6449,
                "lng": 85.5003
            },
            "Mangalvedha": {
                "lat": 17.5167,
                "lng": 75.4667
            },
            "Danw\u0101n": {
                "lat": 25.5292,
                "lng": 84.4598
            },
            "Puttige": {
                "lat": 13.0751,
                "lng": 74.9597
            },
            "Soy\u0101gaon": {
                "lat": 20.5514,
                "lng": 74.5072
            },
            "Kizhakkeman\u0101d": {
                "lat": 9.0174,
                "lng": 76.6509
            },
            "Pallippuram": {
                "lat": 10.8406,
                "lng": 76.1118
            },
            "Muzhakkunnu": {
                "lat": 11.9528,
                "lng": 75.6694
            },
            "Morinda": {
                "lat": 30.79,
                "lng": 76.5
            },
            "Jaynagar": {
                "lat": 26.5925,
                "lng": 86.1372
            },
            "T\u016bn\u0113ri": {
                "lat": 11.6833,
                "lng": 75.6167
            },
            "Surpur": {
                "lat": 23.1895,
                "lng": 73.8928
            },
            "Bh\u0101npura": {
                "lat": 24.5108,
                "lng": 75.7483
            },
            "Chatra Gobraura": {
                "lat": 26.4973,
                "lng": 86.2179
            },
            "Mailavaram": {
                "lat": 16.7833,
                "lng": 80.6333
            },
            "Shendurjana": {
                "lat": 21.5217,
                "lng": 78.2908
            },
            "Sarai Ranjan": {
                "lat": 25.7671,
                "lng": 85.7245
            },
            "B\u0101napur": {
                "lat": 19.7789,
                "lng": 85.1703
            },
            "Anamalais": {
                "lat": 10.583,
                "lng": 76.9344
            },
            "Kalleribh\u0101gam": {
                "lat": 9.05,
                "lng": 76.55
            },
            "V\u0101manapuram": {
                "lat": 8.7251,
                "lng": 76.9012
            },
            "Idukki": {
                "lat": 9.85,
                "lng": 76.97
            },
            "Vendram": {
                "lat": 16.562,
                "lng": 81.4696
            },
            "Ch\u0101gallu": {
                "lat": 16.9833,
                "lng": 81.6667
            },
            "Q\u0101dirganj": {
                "lat": 24.9138,
                "lng": 85.5926
            },
            "Kadiapattanam": {
                "lat": 8.1312,
                "lng": 77.3067
            },
            "P\u0101mpur": {
                "lat": 34.02,
                "lng": 74.93
            },
            "Senda": {
                "lat": 24.4017,
                "lng": 87.9875
            },
            "Nangavaram": {
                "lat": 10.8692,
                "lng": 78.5392
            },
            "P\u0101pan\u0101sam": {
                "lat": 10.9273,
                "lng": 79.2708
            },
            "Jaipur Chuhar": {
                "lat": 25.4187,
                "lng": 86.8352
            },
            "An\u016bppur": {
                "lat": 23.1034,
                "lng": 81.6908
            },
            "K\u0101nke": {
                "lat": 23.4348,
                "lng": 85.3206
            },
            "R\u0101ja P\u0101kar": {
                "lat": 25.735,
                "lng": 85.3376
            },
            "Tiruvankod": {
                "lat": 8.2452,
                "lng": 77.301
            },
            "Pushkar": {
                "lat": 26.4877,
                "lng": 74.5559
            },
            "Nerupperichchal": {
                "lat": 11.161,
                "lng": 77.374
            },
            "Amarp\u0101tan": {
                "lat": 24.3137,
                "lng": 80.977
            },
            "Mandapam": {
                "lat": 9.2822,
                "lng": 79.1436
            },
            "P\u0101k\u0101la": {
                "lat": 13.4667,
                "lng": 79.1167
            },
            "Kalpakathukonam": {
                "lat": 8.8325,
                "lng": 76.7453
            },
            "N\u0101ranamm\u0101lpuram": {
                "lat": 8.7599,
                "lng": 77.7399
            },
            "Serm\u0101devi": {
                "lat": 8.6873,
                "lng": 77.5662
            },
            "Aln\u0101var": {
                "lat": 15.4273,
                "lng": 74.7411
            },
            "North Guw\u0101h\u0101ti": {
                "lat": 26.19,
                "lng": 91.71
            },
            "Muthutala": {
                "lat": 10.78,
                "lng": 76.17
            },
            "Kodikuthi": {
                "lat": 9.4667,
                "lng": 76.95
            },
            "Madakas\u012bra": {
                "lat": 13.9369,
                "lng": 77.2694
            },
            "Ganapavaram": {
                "lat": 16.4314,
                "lng": 80.0515
            },
            "Paravai": {
                "lat": 9.969,
                "lng": 78.0613
            },
            "Pendurti": {
                "lat": 17.8278,
                "lng": 83.2022
            },
            "Bari\u0101rpur": {
                "lat": 25.292,
                "lng": 86.571
            },
            "\u0100ndippatti": {
                "lat": 9.998,
                "lng": 77.621
            },
            "M\u0101rut\u016bru": {
                "lat": 15.9862,
                "lng": 80.1041
            },
            "No\u0101mundi": {
                "lat": 22.1609,
                "lng": 85.5042
            },
            "Sr\u012bvaikuntam": {
                "lat": 8.6318,
                "lng": 77.913
            },
            "P\u0101marru": {
                "lat": 16.327,
                "lng": 80.961
            },
            "Shirhatti": {
                "lat": 15.2313,
                "lng": 75.5772
            },
            "Khowai": {
                "lat": 24.065,
                "lng": 91.605
            },
            "Mohiudd\u012bnnagar": {
                "lat": 25.7428,
                "lng": 86.0812
            },
            "Singur": {
                "lat": 22.81,
                "lng": 88.23
            },
            "Gummudip\u016bndi": {
                "lat": 13.3995,
                "lng": 80.1187
            },
            "Tiruppuvanam": {
                "lat": 9.855,
                "lng": 78.2744
            },
            "Mundgod": {
                "lat": 14.9714,
                "lng": 75.0366
            },
            "Kasumpti": {
                "lat": 31.07,
                "lng": 77.18
            },
            "Padman\u0101bhapuram": {
                "lat": 8.2446,
                "lng": 77.3367
            },
            "P\u0101mb\u0101di": {
                "lat": 9.4264,
                "lng": 76.5611
            },
            "Sr\u012bperumb\u016bd\u016br": {
                "lat": 12.968,
                "lng": 79.9473
            },
            "Nirm\u0101li": {
                "lat": 26.314,
                "lng": 86.5854
            },
            "Narippatta": {
                "lat": 11.6972,
                "lng": 75.7181
            },
            "Kaduturutti": {
                "lat": 9.7833,
                "lng": 76.3
            },
            "Kaikal\u016br": {
                "lat": 16.5509,
                "lng": 81.2
            },
            "Ch\u0101galamarri": {
                "lat": 14.9667,
                "lng": 78.5833
            },
            "Sevilimedu": {
                "lat": 12.8083,
                "lng": 79.6864
            },
            "Iruttarakonam": {
                "lat": 8.5863,
                "lng": 76.8976
            },
            "N\u0101magiripettai": {
                "lat": 11.47,
                "lng": 78.27
            },
            "Koduvil\u0101rpatti": {
                "lat": 9.9695,
                "lng": 77.4915
            },
            "Narkher": {
                "lat": 21.4383,
                "lng": 78.5683
            },
            "T\u0101dikombu": {
                "lat": 10.439,
                "lng": 77.9546
            },
            "Pillaiy\u0101rkuppam": {
                "lat": 11.8119,
                "lng": 79.7922
            },
            "Kosgi": {
                "lat": 16.9878,
                "lng": 77.7169
            },
            "Parasi": {
                "lat": 24.1908,
                "lng": 82.7817
            },
            "Chorw\u0101d": {
                "lat": 21.0167,
                "lng": 70.2333
            },
            "R\u0101tu": {
                "lat": 23.4204,
                "lng": 85.2146
            },
            "N\u0101gamangala": {
                "lat": 12.8194,
                "lng": 76.7546
            },
            "Rupauli": {
                "lat": 25.8695,
                "lng": 87.06
            },
            "Belonia": {
                "lat": 23.25,
                "lng": 91.45
            },
            "L\u0101thi": {
                "lat": 21.45,
                "lng": 71.38
            },
            "Sar\u0101ri": {
                "lat": 26.2523,
                "lng": 84.5484
            },
            "Mor\u0101sar": {
                "lat": 23.0942,
                "lng": 72.5961
            },
            "Kann\u0101nendal": {
                "lat": 9.9649,
                "lng": 78.1416
            },
            "Saurh": {
                "lat": 25.3789,
                "lng": 86.8223
            },
            "Raksaha": {
                "lat": 25.4444,
                "lng": 83.6873
            },
            "Adigaratti": {
                "lat": 11.3357,
                "lng": 76.6971
            },
            "Irukanni": {
                "lat": 11.75,
                "lng": 76.1833
            },
            "\u0100modei": {
                "lat": 26.8975,
                "lng": 84.8182
            },
            "B\u0101nki": {
                "lat": 26.1497,
                "lng": 86.3626
            },
            "Tehata": {
                "lat": 23.7,
                "lng": 88.55
            },
            "Paiganapalli": {
                "lat": 12.476,
                "lng": 78.1748
            },
            "Phulpur": {
                "lat": 25.55,
                "lng": 82.1
            },
            "Behat": {
                "lat": 26.2435,
                "lng": 86.2799
            },
            "Chinchali": {
                "lat": 16.5647,
                "lng": 74.816
            },
            "Patp\u0101ra": {
                "lat": 26.4328,
                "lng": 86.1916
            },
            "Timmar\u0101san\u0101yakkan\u016br": {
                "lat": 10.0015,
                "lng": 77.6769
            },
            "Fatehgarh Ch\u016bri\u0101n": {
                "lat": 31.8643,
                "lng": 74.9567
            },
            "M\u0101njhi": {
                "lat": 25.8384,
                "lng": 84.5788
            },
            "S\u0101mba": {
                "lat": 32.5624,
                "lng": 75.1199
            },
            "Daddi": {
                "lat": 16.0667,
                "lng": 74.4333
            },
            "Sancoale": {
                "lat": 15.4708,
                "lng": 73.8431
            },
            "Kud\u0101l": {
                "lat": 17.8356,
                "lng": 73.9175
            },
            "Tuf\u0101nganj": {
                "lat": 26.3345,
                "lng": 89.6699
            },
            "Vadakkum": {
                "lat": 9.0167,
                "lng": 76.55
            },
            "Daboh": {
                "lat": 26.0024,
                "lng": 78.8766
            },
            "Niz\u0101mpatam": {
                "lat": 15.9,
                "lng": 80.6667
            },
            "Hireker\u016br": {
                "lat": 14.4551,
                "lng": 75.3952
            },
            "Cuncolim": {
                "lat": 15.1773,
                "lng": 73.9939
            },
            "Damua": {
                "lat": 22.1929,
                "lng": 78.467
            },
            "Nambiy\u016br": {
                "lat": 11.3581,
                "lng": 77.3211
            },
            "Vakkam": {
                "lat": 8.69,
                "lng": 76.77
            },
            "Pudupattanam": {
                "lat": 12.5037,
                "lng": 80.1507
            },
            "B\u0101lug\u0101n": {
                "lat": 19.7333,
                "lng": 85.2167
            },
            "Ko\u0101th": {
                "lat": 25.3264,
                "lng": 84.2598
            },
            "Palav\u016br": {
                "lat": 8.2055,
                "lng": 77.5755
            },
            "Mud\u0101kkal": {
                "lat": 8.7011,
                "lng": 76.8589
            },
            "Aur\u0101d": {
                "lat": 18.254,
                "lng": 77.4176
            },
            "H\u0101rohalli": {
                "lat": 12.6807,
                "lng": 77.4743
            },
            "Bhainsdehi": {
                "lat": 21.6449,
                "lng": 77.6302
            },
            "Devirammanahalli": {
                "lat": 12.1922,
                "lng": 76.6653
            },
            "Mari\u0101ni": {
                "lat": 26.67,
                "lng": 94.33
            },
            "Alta": {
                "lat": 17.25,
                "lng": 74.57
            },
            "Peringan\u0101d": {
                "lat": 9.1525,
                "lng": 76.6988
            },
            "Barhampur": {
                "lat": 26.3023,
                "lng": 85.7476
            },
            "P\u0101lkonda": {
                "lat": 18.6,
                "lng": 83.75
            },
            "Puduppalli Kunnam": {
                "lat": 9.15,
                "lng": 76.4833
            },
            "Yermal": {
                "lat": 13.1675,
                "lng": 74.7619
            },
            "Valljkkod": {
                "lat": 9.23,
                "lng": 76.7711
            },
            "Banganapalle": {
                "lat": 15.3167,
                "lng": 78.2333
            },
            "Kukn\u016br": {
                "lat": 15.49,
                "lng": 75.9914
            },
            "Narwar": {
                "lat": 25.6439,
                "lng": 77.9129
            },
            "Mathukumnel": {
                "lat": 9.2167,
                "lng": 76.4583
            },
            "Sonbarsa": {
                "lat": 25.7069,
                "lng": 86.7379
            },
            "Tittagudi": {
                "lat": 11.4167,
                "lng": 79.1167
            },
            "Gannavaram": {
                "lat": 16.5333,
                "lng": 80.8
            },
            "Achampet": {
                "lat": 16.6299,
                "lng": 80.1213
            },
            "Kol\u0101ras": {
                "lat": 25.2193,
                "lng": 77.6117
            },
            "Chithara": {
                "lat": 8.8025,
                "lng": 76.9792
            },
            "Baroda": {
                "lat": 25.5,
                "lng": 76.65
            },
            "Muttat\u014ddi": {
                "lat": 12.5417,
                "lng": 75.0194
            },
            "Vinjam\u016br": {
                "lat": 14.833,
                "lng": 79.583
            },
            "K\u0101mal\u0101puram": {
                "lat": 14.5833,
                "lng": 78.65
            },
            "Hardi": {
                "lat": 26.0785,
                "lng": 86.6809
            },
            "Patuvil\u0101yi": {
                "lat": 11.8667,
                "lng": 75.5194
            },
            "Jat\u0101ra": {
                "lat": 25.0096,
                "lng": 79.0487
            },
            "S\u0101vda": {
                "lat": 21.15,
                "lng": 75.88
            },
            "Chintalapalle": {
                "lat": 15.04,
                "lng": 76.2
            },
            "Sultanpur": {
                "lat": 31.2235,
                "lng": 75.2041
            },
            "Honn\u0101li": {
                "lat": 14.2399,
                "lng": 75.6474
            },
            "Maniyamturuttu": {
                "lat": 9.7943,
                "lng": 76.4179
            },
            "Sonkach": {
                "lat": 22.9717,
                "lng": 76.3467
            },
            "Vadamadurai": {
                "lat": 10.4408,
                "lng": 78.0978
            },
            "Madipakkam": {
                "lat": 12.9623,
                "lng": 80.1986
            },
            "Chennimalai": {
                "lat": 11.1638,
                "lng": 77.6039
            },
            "Muhammad\u0101b\u0101d": {
                "lat": 16.8731,
                "lng": 77.8909
            },
            "Nellimarla": {
                "lat": 18.1667,
                "lng": 83.4333
            },
            "Nahorkatiya": {
                "lat": 27.2891,
                "lng": 95.3418
            },
            "Bhojpur Kad\u012bm": {
                "lat": 25.5841,
                "lng": 84.1265
            },
            "Nadik\u016bde": {
                "lat": 16.5941,
                "lng": 79.7167
            },
            "Kizh\u0101tt\u016br": {
                "lat": 11.05,
                "lng": 76.2167
            },
            "Keevallur": {
                "lat": 11.9167,
                "lng": 75.55
            },
            "Baud": {
                "lat": 20.84,
                "lng": 84.32
            },
            "Koip\u0101di": {
                "lat": 12.5846,
                "lng": 74.9404
            },
            "Pariy\u0101ram": {
                "lat": 12.0732,
                "lng": 75.2917
            },
            "Chokkampatti": {
                "lat": 9.1259,
                "lng": 77.3634
            },
            "Naraura": {
                "lat": 28.1967,
                "lng": 78.3814
            },
            "Barki Saria": {
                "lat": 24.1759,
                "lng": 85.8894
            },
            "R\u0101mnagar Fars\u0101hi": {
                "lat": 25.8904,
                "lng": 87.1284
            },
            "K\u016bttampala": {
                "lat": 12.1216,
                "lng": 75.4829
            },
            "Karaiy\u0101mpud\u016br": {
                "lat": 11.0077,
                "lng": 77.2942
            },
            "Harp\u0101lpur": {
                "lat": 25.2877,
                "lng": 79.3328
            },
            "Makhtal": {
                "lat": 16.5012,
                "lng": 77.5156
            },
            "Mundra": {
                "lat": 22.85,
                "lng": 69.73
            },
            "Dharmkot": {
                "lat": 30.9456,
                "lng": 75.2324
            },
            "Baihar": {
                "lat": 22.1013,
                "lng": 80.5497
            },
            "Kat\u0101kos": {
                "lat": 25.3339,
                "lng": 87.6715
            },
            "Chandragiri": {
                "lat": 13.5833,
                "lng": 79.3167
            },
            "Sh\u0101mgarh": {
                "lat": 25.8935,
                "lng": 86.8759
            },
            "Gopavaram": {
                "lat": 14.7853,
                "lng": 78.5728
            },
            "Devrukh": {
                "lat": 17.065,
                "lng": 73.6158
            },
            "Pulicat": {
                "lat": 13.4161,
                "lng": 80.3168
            },
            "Park\u0101l": {
                "lat": 18.2,
                "lng": 79.7167
            },
            "Chodavaram": {
                "lat": 17.438,
                "lng": 81.7781
            },
            "Husainpur": {
                "lat": 31.3387,
                "lng": 75.1969
            },
            "R\u0101ipura": {
                "lat": 32.788,
                "lng": 74.7785
            },
            "Samthar": {
                "lat": 25.84,
                "lng": 78.9
            },
            "Bel\u016br": {
                "lat": 11.7075,
                "lng": 78.4144
            },
            "Edakkunnam": {
                "lat": 9.5333,
                "lng": 76.8333
            },
            "Khilchipur": {
                "lat": 24.0394,
                "lng": 76.578
            },
            "Ch\u0101ndpur": {
                "lat": 22.4368,
                "lng": 88.1711
            },
            "Penn\u0101garam": {
                "lat": 12.1343,
                "lng": 77.8952
            },
            "Tondi": {
                "lat": 9.7417,
                "lng": 79.0177
            },
            "Talay\u0101zham": {
                "lat": 9.6986,
                "lng": 76.4236
            },
            "Pargi": {
                "lat": 17.1756,
                "lng": 77.8781
            },
            "Sivagiri": {
                "lat": 11.1179,
                "lng": 77.7881
            },
            "Nelkattumseval": {
                "lat": 9.2361,
                "lng": 77.4606
            },
            "Erragondap\u0101lem": {
                "lat": 16.0397,
                "lng": 79.3043
            },
            "Aur\u0101d Sh\u0101hjah\u0101ni": {
                "lat": 18.0958,
                "lng": 76.8881
            },
            "Bhitarw\u0101r": {
                "lat": 25.7922,
                "lng": 78.1108
            },
            "Kher\u0101lu": {
                "lat": 23.88,
                "lng": 72.62
            },
            "Mattigiri": {
                "lat": 12.698,
                "lng": 77.8083
            },
            "\u0100lamp\u0101laiyam": {
                "lat": 11.3635,
                "lng": 77.7677
            },
            "Teonthar": {
                "lat": 24.9821,
                "lng": 81.6419
            },
            "Karukachal": {
                "lat": 9.5,
                "lng": 76.6333
            },
            "Farakka": {
                "lat": 24.819,
                "lng": 87.911
            },
            "Vettikkavala": {
                "lat": 8.9833,
                "lng": 76.8167
            },
            "Kulpah\u0101r": {
                "lat": 25.319,
                "lng": 79.641
            },
            "Lon\u0101r": {
                "lat": 19.9853,
                "lng": 76.5205
            },
            "V\u012brar\u0101jendrapet": {
                "lat": 12.1964,
                "lng": 75.8051
            },
            "Dep\u0101lpur": {
                "lat": 22.8509,
                "lng": 75.5422
            },
            "Sidhapa": {
                "lat": 26.5355,
                "lng": 86.3355
            },
            "Dhor\u012bmanna": {
                "lat": 25.1997,
                "lng": 71.4331
            },
            "K\u0101rtikapalli": {
                "lat": 9.2603,
                "lng": 76.4498
            },
            "Kannod": {
                "lat": 22.6676,
                "lng": 76.7429
            },
            "Bhopatpur": {
                "lat": 26.4495,
                "lng": 84.8545
            },
            "Kandakkadava": {
                "lat": 9.7,
                "lng": 76.3
            },
            "Gor\u0101ya": {
                "lat": 31.1241,
                "lng": 75.7724
            },
            "Shri Mah\u0101v\u012brji": {
                "lat": 26.6833,
                "lng": 76.9167
            },
            "Tadas": {
                "lat": 15.1333,
                "lng": 75.1167
            },
            "Asarganj": {
                "lat": 25.15,
                "lng": 86.68
            },
            "Rankhandi": {
                "lat": 29.6167,
                "lng": 77.65
            },
            "Bhaluh\u0101r": {
                "lat": 24.82,
                "lng": 84.65
            },
            "Mandi Bamora": {
                "lat": 24.08,
                "lng": 78.08
            },
            "Bareja": {
                "lat": 22.85,
                "lng": 72.5833
            },
            "Sikka": {
                "lat": 22.4244,
                "lng": 69.842
            },
            "M\u016bnak": {
                "lat": 29.5203,
                "lng": 76.8401
            },
            "P\u012brbahora": {
                "lat": 28.364,
                "lng": 79.415
            },
            "Hudli": {
                "lat": 15.88,
                "lng": 74.56
            },
            "B\u0101zidpur Madhaul": {
                "lat": 25.5236,
                "lng": 85.4572
            },
            "Suroth": {
                "lat": 26.8098,
                "lng": 77.147
            },
            "Sujnipur": {
                "lat": 23.9667,
                "lng": 71.8167
            },
            "Marjamp\u0101d": {
                "lat": 16.592,
                "lng": 79.908
            },
            "Soh\u0101na": {
                "lat": 30.6833,
                "lng": 76.7
            },
            "Chimth\u0101na": {
                "lat": 21.18,
                "lng": 74.69
            },
            "Mangapet": {
                "lat": 18.25,
                "lng": 80.52
            },
            "Ankalgi": {
                "lat": 16.0333,
                "lng": 74.7
            },
            "Kothan\u016bru": {
                "lat": 16.0022,
                "lng": 81.0417
            },
            "Tuminkatti": {
                "lat": 14.4131,
                "lng": 75.6206
            },
            "N\u0101randa": {
                "lat": 19.7739,
                "lng": 79.1365
            },
            "Son\u0101imukh": {
                "lat": 24.7333,
                "lng": 92.8903
            },
            "Garhshankar": {
                "lat": 31.2154,
                "lng": 76.1415
            },
            "Sanrh Majhgawan": {
                "lat": 24.6845,
                "lng": 85.365
            },
            "Pal\u0101shi": {
                "lat": 23.8,
                "lng": 88.25
            },
            "Kum\u012bl": {
                "lat": 8.7833,
                "lng": 76.9333
            },
            "Sh\u012br\u016bru": {
                "lat": 13.908,
                "lng": 74.6058
            },
            "Valiyakum\u0101ramangalam": {
                "lat": 9.8333,
                "lng": 76.7833
            },
            "Kadakola": {
                "lat": 12.1922,
                "lng": 76.6653
            },
            "Bhas\u0101war": {
                "lat": 27.0361,
                "lng": 77.0528
            },
            "Venmani": {
                "lat": 9.2444,
                "lng": 76.6131
            },
            "Kosk\u0101pur": {
                "lat": 26.2669,
                "lng": 87.1465
            },
            "Chati\u0101": {
                "lat": 26.5396,
                "lng": 84.566
            },
            "And\u0101l": {
                "lat": 23.6,
                "lng": 87.2
            },
            "Dhanaula": {
                "lat": 30.28,
                "lng": 75.58
            },
            "Palliman": {
                "lat": 8.9,
                "lng": 76.7139
            },
            "Senapparetti": {
                "lat": 10.9625,
                "lng": 78.1132
            },
            "Pukhr\u0101y\u0101n": {
                "lat": 26.23,
                "lng": 79.85
            },
            "Gil": {
                "lat": 30.8469,
                "lng": 75.8636
            },
            "Karlap\u0101lem": {
                "lat": 15.9333,
                "lng": 80.55
            },
            "Bara Malehra": {
                "lat": 24.5681,
                "lng": 79.3069
            },
            "Umr\u0101pur": {
                "lat": 24.5863,
                "lng": 87.9294
            },
            "Ellamanda": {
                "lat": 16.1847,
                "lng": 80.0546
            },
            "Khizrpur": {
                "lat": 30.5843,
                "lng": 76.7572
            },
            "Kolavall\u00far": {
                "lat": 11.7788,
                "lng": 75.6298
            },
            "J\u0101mbai": {
                "lat": 11.4678,
                "lng": 77.6434
            },
            "Anghad": {
                "lat": 22.3913,
                "lng": 72.9946
            },
            "Mann\u016br": {
                "lat": 17.2994,
                "lng": 76.0984
            },
            "Kochas": {
                "lat": 25.234,
                "lng": 83.9215
            },
            "K\u0101m\u0101khy\u0101nagar": {
                "lat": 20.9338,
                "lng": 85.5449
            },
            "Kann\u0101nkurichchi": {
                "lat": 11.6969,
                "lng": 78.1794
            },
            "Kattanam": {
                "lat": 9.1764,
                "lng": 76.5641
            },
            "Ghatkesar": {
                "lat": 17.4494,
                "lng": 78.6853
            },
            "Bishunpur": {
                "lat": 25.5665,
                "lng": 87.4435
            },
            "Pattamadai": {
                "lat": 8.6726,
                "lng": 77.5845
            },
            "Thimiri": {
                "lat": 12.8283,
                "lng": 79.3079
            },
            "Beldaur": {
                "lat": 25.5947,
                "lng": 86.7909
            },
            "Cherakhera": {
                "lat": 25.6869,
                "lng": 86.4187
            },
            "Kanekallu": {
                "lat": 14.8864,
                "lng": 77.0317
            },
            "Payimattam": {
                "lat": 10.0255,
                "lng": 76.4507
            },
            "Leh\u0101ra": {
                "lat": 26.1515,
                "lng": 86.1034
            },
            "Dicholi": {
                "lat": 15.5932,
                "lng": 73.9457
            },
            "Mok\u0113ri": {
                "lat": 11.7432,
                "lng": 75.6178
            },
            "K\u0101tp\u0101di": {
                "lat": 12.9695,
                "lng": 79.1455
            },
            "Kanniy\u0101kum\u0101ri": {
                "lat": 8.0883,
                "lng": 77.5385
            },
            "Namb\u016bru": {
                "lat": 16.36,
                "lng": 80.52
            },
            "Udalguri": {
                "lat": 26.7537,
                "lng": 92.1021
            },
            "B\u016bdipuram": {
                "lat": 10.3606,
                "lng": 78.0106
            },
            "Pichor": {
                "lat": 25.1756,
                "lng": 78.1886
            },
            "Kond\u016br": {
                "lat": 9.6856,
                "lng": 76.7622
            },
            "Nilakkottai": {
                "lat": 10.17,
                "lng": 77.87
            },
            "Hanumana": {
                "lat": 24.7789,
                "lng": 82.096
            },
            "R\u0101mj\u012bbanpur": {
                "lat": 22.83,
                "lng": 87.62
            },
            "Mayahaura": {
                "lat": 22.1898,
                "lng": 88.5033
            },
            "S\u0101dpur": {
                "lat": 25.4758,
                "lng": 86.3786
            },
            "Punjai Puliyampatti": {
                "lat": 11.3516,
                "lng": 77.1667
            },
            "Vallam": {
                "lat": 10.7199,
                "lng": 79.0598
            },
            "Morwa": {
                "lat": 25.803,
                "lng": 85.6861
            },
            "Huseni": {
                "lat": 26.3768,
                "lng": 84.7952
            },
            "Pathrajolhania": {
                "lat": 26.1369,
                "lng": 86.6614
            },
            "T\u012brthahalli": {
                "lat": 13.6884,
                "lng": 75.2455
            },
            "Jhalid\u0101": {
                "lat": 23.37,
                "lng": 85.97
            },
            "Mihona": {
                "lat": 26.2837,
                "lng": 78.9805
            },
            "M\u0101rahra": {
                "lat": 27.75,
                "lng": 78.5667
            },
            "Rajaudha": {
                "lat": 26.7046,
                "lng": 78.4223
            },
            "Pars\u0101hi Sirsia": {
                "lat": 26.4676,
                "lng": 86.4984
            },
            "Kalayapuram": {
                "lat": 9.0545,
                "lng": 76.7696
            },
            "\u0100mta": {
                "lat": 22.5834,
                "lng": 88.0104
            },
            "Mahin\u0101w\u0101n": {
                "lat": 26.0208,
                "lng": 86.1672
            },
            "Tibba": {
                "lat": 30.4876,
                "lng": 75.6967
            },
            "Akanav\u0101ritota": {
                "lat": 16.4141,
                "lng": 81.6367
            },
            "N\u012blgiri": {
                "lat": 21.6466,
                "lng": 86.9199
            },
            "Jagal\u016br": {
                "lat": 14.5196,
                "lng": 76.3391
            },
            "Krishnapur": {
                "lat": 24.4123,
                "lng": 88.2582
            },
            "Sondiha": {
                "lat": 24.895,
                "lng": 87.0175
            },
            "R\u0101mchandrapur": {
                "lat": 22.891,
                "lng": 88.473
            },
            "Teghra English": {
                "lat": 25.4072,
                "lng": 87.741
            },
            "Nars\u0101pur": {
                "lat": 17.7374,
                "lng": 78.2805
            },
            "K\u0101rttigappalli": {
                "lat": 11.6,
                "lng": 75.6667
            },
            "S\u0101mal\u0101puram": {
                "lat": 11.0724,
                "lng": 77.198
            },
            "Salangaipp\u0101laiyam": {
                "lat": 11.426,
                "lng": 77.5707
            },
            "Yandrapalle": {
                "lat": 16.0472,
                "lng": 79.3073
            },
            "Pahsara": {
                "lat": 25.5482,
                "lng": 86.1667
            },
            "Kalghatgi": {
                "lat": 15.1832,
                "lng": 74.971
            },
            "Shikrapur": {
                "lat": 18.6936,
                "lng": 74.1381
            },
            "Manjathala": {
                "lat": 11.3624,
                "lng": 76.7789
            },
            "Abhay\u0101puri": {
                "lat": 26.3225,
                "lng": 90.6853
            },
            "K\u0101kdw\u012bp": {
                "lat": 21.8791,
                "lng": 88.1913
            },
            "Rif\u0101dpur": {
                "lat": 25.2635,
                "lng": 87.4157
            },
            "Kutty\u0101di": {
                "lat": 11.6542,
                "lng": 75.7539
            },
            "Oruvad\u0101lkotta": {
                "lat": 8.4778,
                "lng": 77.1347
            },
            "Sorada": {
                "lat": 19.7608,
                "lng": 84.43
            },
            "Bholsar": {
                "lat": 25.2133,
                "lng": 87.2147
            },
            "Per\u016bru": {
                "lat": 14.3503,
                "lng": 77.3497
            },
            "Tev\u0101ram": {
                "lat": 9.8967,
                "lng": 77.2809
            },
            "Palera": {
                "lat": 25.0201,
                "lng": 79.2282
            },
            "Panniperumthalai": {
                "lat": 10.6986,
                "lng": 76.6875
            },
            "Assamannur": {
                "lat": 10.1129,
                "lng": 76.5517
            },
            "Poh\u0101di": {
                "lat": 26.0387,
                "lng": 86.1612
            },
            "Pottanik\u0101d": {
                "lat": 10.0305,
                "lng": 76.6753
            },
            "Sirv\u0101r": {
                "lat": 16.1739,
                "lng": 77.0225
            },
            "Bhojpur Jad\u012bd": {
                "lat": 25.588,
                "lng": 84.1621
            },
            "Indargarh": {
                "lat": 25.9109,
                "lng": 78.5619
            },
            "Kudra": {
                "lat": 25.0463,
                "lng": 83.7926
            },
            "B\u0101bai": {
                "lat": 22.7026,
                "lng": 77.9349
            },
            "Sirnia": {
                "lat": 25.4903,
                "lng": 87.5892
            },
            "Holalkere": {
                "lat": 14.0429,
                "lng": 76.185
            },
            "Garot": {
                "lat": 24.3234,
                "lng": 75.652
            },
            "Ghos\u0101i": {
                "lat": 25.5562,
                "lng": 87.0304
            },
            "Eruvatti": {
                "lat": 12.05,
                "lng": 75.55
            },
            "Chetich\u0113ri": {
                "lat": 12.095,
                "lng": 75.5451
            },
            "Rangra": {
                "lat": 25.3803,
                "lng": 87.1866
            },
            "Devgadh B\u0101riya": {
                "lat": 22.7022,
                "lng": 73.915
            },
            "Binka": {
                "lat": 21.0263,
                "lng": 83.812
            },
            "Khamaria": {
                "lat": 23.2129,
                "lng": 80.0313
            },
            "Dumra": {
                "lat": 25.5907,
                "lng": 87.0614
            },
            "Pokaran": {
                "lat": 26.92,
                "lng": 71.92
            },
            "Sirpur": {
                "lat": 19.4833,
                "lng": 79.6
            },
            "Maghar": {
                "lat": 26.76,
                "lng": 83.13
            },
            "Ghogard\u012bha": {
                "lat": 26.2799,
                "lng": 86.47
            },
            "Raita": {
                "lat": 32.5495,
                "lng": 76.239
            },
            "Tiruv\u0101di": {
                "lat": 10.8805,
                "lng": 79.1049
            },
            "Biswanath Chariali": {
                "lat": 26.7278,
                "lng": 93.1517
            },
            "Sir\u0101lkoppa": {
                "lat": 14.3807,
                "lng": 75.2501
            },
            "Kodumba": {
                "lat": 10.7333,
                "lng": 76.6833
            },
            "Iramala": {
                "lat": 10.0374,
                "lng": 76.5135
            },
            "Chenn\u012brkara": {
                "lat": 9.2487,
                "lng": 76.7248
            },
            "M\u0101vinpalli": {
                "lat": 17.91,
                "lng": 77.53
            },
            "Morauna": {
                "lat": 26.1706,
                "lng": 86.4231
            },
            "Sh\u0101hgarh": {
                "lat": 24.3137,
                "lng": 79.1181
            },
            "Harpur": {
                "lat": 25.6537,
                "lng": 86.3288
            },
            "N\u012blamb\u016br": {
                "lat": 11.059,
                "lng": 77.0838
            },
            "Chaut\u0101pal": {
                "lat": 17.2508,
                "lng": 78.8972
            },
            "Perumkulam": {
                "lat": 8.7052,
                "lng": 76.7861
            },
            "Mahisi": {
                "lat": 25.8544,
                "lng": 86.465
            },
            "Simri": {
                "lat": 26.3825,
                "lng": 86.1705
            },
            "Nalua": {
                "lat": 22.1051,
                "lng": 88.4619
            },
            "Chinna Ganj\u0101m": {
                "lat": 15.7,
                "lng": 80.25
            },
            "Murugamp\u0101laiyam": {
                "lat": 11.0806,
                "lng": 77.3238
            },
            "Hindoria": {
                "lat": 23.9035,
                "lng": 79.5686
            },
            "P\u016branpur": {
                "lat": 28.52,
                "lng": 80.15
            },
            "Dharms\u0101la": {
                "lat": 32.2153,
                "lng": 76.3186
            },
            "Rahata": {
                "lat": 19.7167,
                "lng": 74.4833
            },
            "Sarauli": {
                "lat": 28.4931,
                "lng": 79.0853
            },
            "M\u0101njha": {
                "lat": 26.4061,
                "lng": 84.5002
            },
            "D\u016brpalli": {
                "lat": 18.5967,
                "lng": 78.4517
            },
            "N\u0101rapala": {
                "lat": 14.7206,
                "lng": 77.8106
            },
            "Sukh\u0101san": {
                "lat": 25.9644,
                "lng": 86.7732
            },
            "K\u0101veripatnam": {
                "lat": 12.4219,
                "lng": 78.2188
            },
            "Siripur": {
                "lat": 26.7336,
                "lng": 84.7217
            },
            "Koliakkod": {
                "lat": 8.6333,
                "lng": 76.9
            },
            "Sarso": {
                "lat": 26.2333,
                "lng": 86.1768
            },
            "Varidhanam": {
                "lat": 16.5576,
                "lng": 81.8303
            },
            "Sars\u0101wa": {
                "lat": 30.016,
                "lng": 77.4
            },
            "Makronia": {
                "lat": 23.8476,
                "lng": 78.799
            },
            "Chilk\u016bru": {
                "lat": 16.9611,
                "lng": 79.9125
            },
            "Guru Har Sah\u0101i": {
                "lat": 30.7086,
                "lng": 74.4041
            },
            "Aikaranad": {
                "lat": 10.0002,
                "lng": 76.455
            },
            "Lakhn\u0101don": {
                "lat": 22.6005,
                "lng": 79.6009
            },
            "Desavilakku": {
                "lat": 11.6476,
                "lng": 77.967
            },
            "Elam\u0101tt\u016br": {
                "lat": 9.4167,
                "lng": 76.7
            },
            "Ismailpur": {
                "lat": 25.3113,
                "lng": 87.127
            },
            "K\u014dtek\u0101ra": {
                "lat": 12.7929,
                "lng": 74.8722
            },
            "Ranb\u012brsinghpura": {
                "lat": 32.6079,
                "lng": 74.7293
            },
            "Somandepalle": {
                "lat": 14.0078,
                "lng": 77.6086
            },
            "Nay\u0101garh": {
                "lat": 20.1288,
                "lng": 85.0963
            },
            "G\u0101t\u0101da": {
                "lat": 18.4144,
                "lng": 83.5041
            },
            "Nanjan\u0101d": {
                "lat": 11.3669,
                "lng": 76.6415
            },
            "Ezhamkulam": {
                "lat": 9.1333,
                "lng": 76.7667
            },
            "Sugaon": {
                "lat": 26.7317,
                "lng": 84.7629
            },
            "Jaggampeta": {
                "lat": 17.1833,
                "lng": 82.05
            },
            "Kabb\u016br": {
                "lat": 16.42,
                "lng": 74.58
            },
            "V\u0101rapatti": {
                "lat": 10.0167,
                "lng": 76.6167
            },
            "Dukli": {
                "lat": 22.3,
                "lng": 79.4833
            },
            "Khetia": {
                "lat": 21.6712,
                "lng": 74.5853
            },
            "Sabalpur": {
                "lat": 25.6053,
                "lng": 85.1835
            },
            "Purushottampur": {
                "lat": 19.5202,
                "lng": 84.8851
            },
            "Udumanthala": {
                "lat": 12.2333,
                "lng": 75.4667
            },
            "Sompeta": {
                "lat": 18.93,
                "lng": 84.6
            },
            "Mar\u0101nchi": {
                "lat": 25.3544,
                "lng": 85.9919
            },
            "Ayy\u0101mpettai": {
                "lat": 10.8971,
                "lng": 79.1915
            },
            "Birnagar": {
                "lat": 25.9785,
                "lng": 87.111
            },
            "Nanthankulam": {
                "lat": 8.3331,
                "lng": 77.8442
            },
            "Kurumbap\u0101laiyam": {
                "lat": 11.1053,
                "lng": 76.9217
            },
            "Harihans": {
                "lat": 26.1457,
                "lng": 84.3476
            },
            "Melmuri": {
                "lat": 11.1806,
                "lng": 76.0167
            },
            "Sendamaram": {
                "lat": 9.0648,
                "lng": 77.4369
            },
            "Gunri": {
                "lat": 25.6408,
                "lng": 84.658
            },
            "Dh\u0101riw\u0101l": {
                "lat": 31.95,
                "lng": 75.32
            },
            "Vadakkangara": {
                "lat": 11.0,
                "lng": 76.1583
            },
            "Perunturuttu": {
                "lat": 9.6333,
                "lng": 76.5667
            },
            "Sonhauli": {
                "lat": 25.5108,
                "lng": 86.4707
            },
            "Lakhna": {
                "lat": 25.8865,
                "lng": 87.6001
            },
            "Almel": {
                "lat": 16.92,
                "lng": 76.24
            },
            "Rishivandiyam": {
                "lat": 11.817,
                "lng": 79.1
            },
            "Jasidih": {
                "lat": 24.5138,
                "lng": 86.6458
            },
            "Soalkuchi": {
                "lat": 26.17,
                "lng": 91.57
            },
            "Molak\u0101lumuru": {
                "lat": 14.7178,
                "lng": 76.7468
            },
            "Elumalai": {
                "lat": 9.865,
                "lng": 77.6992
            },
            "Lau\u0101l\u0101gaon": {
                "lat": 25.494,
                "lng": 87.0412
            },
            "Kangazha": {
                "lat": 9.55,
                "lng": 76.718
            },
            "Telwa": {
                "lat": 26.0877,
                "lng": 86.517
            },
            "Basatpur": {
                "lat": 26.0011,
                "lng": 85.0643
            },
            "M\u016bkkan\u016br": {
                "lat": 10.2405,
                "lng": 76.4162
            },
            "K\u012blmangalam": {
                "lat": 10.0933,
                "lng": 77.6108
            },
            "M\u0101nikkal": {
                "lat": 8.6544,
                "lng": 76.909
            },
            "Ch\u0101k\u016br": {
                "lat": 18.5131,
                "lng": 76.8753
            },
            "Manakayi": {
                "lat": 11.8611,
                "lng": 75.5625
            },
            "Ajn\u0101la": {
                "lat": 31.84,
                "lng": 74.76
            },
            "Kalugumalai": {
                "lat": 9.1494,
                "lng": 77.7057
            },
            "Majhua": {
                "lat": 26.014,
                "lng": 87.3018
            },
            "Valavan\u016br": {
                "lat": 11.9205,
                "lng": 79.5785
            },
            "Jalakand\u0101puram": {
                "lat": 11.6986,
                "lng": 77.8776
            },
            "Mohana": {
                "lat": 25.9027,
                "lng": 77.7715
            },
            "Erumakkuzhi": {
                "lat": 9.2833,
                "lng": 76.55
            },
            "Bhadaur": {
                "lat": 30.4764,
                "lng": 75.3306
            },
            "Leteri": {
                "lat": 24.0598,
                "lng": 77.4086
            },
            "Kumbhr\u0101j": {
                "lat": 24.3734,
                "lng": 77.0484
            },
            "Mellacheruvu": {
                "lat": 16.8173,
                "lng": 79.9331
            },
            "Kothia": {
                "lat": 25.7612,
                "lng": 84.8811
            },
            "Kulu": {
                "lat": 31.95,
                "lng": 77.11
            },
            "Roh\u0101r": {
                "lat": 25.9917,
                "lng": 86.2366
            },
            "Lodhwe": {
                "lat": 24.6404,
                "lng": 85.2913
            },
            "T\u0101dikonda": {
                "lat": 16.4167,
                "lng": 80.4542
            },
            "P\u0101laiyampatti": {
                "lat": 9.5392,
                "lng": 78.0984
            },
            "Sirvel": {
                "lat": 15.321,
                "lng": 78.5344
            },
            "Ajaigarh": {
                "lat": 24.8988,
                "lng": 80.2592
            },
            "Tirmalgiri": {
                "lat": 17.427,
                "lng": 79.4724
            },
            "Kotanch\u0113ri": {
                "lat": 11.4719,
                "lng": 75.969
            },
            "Kannapuram": {
                "lat": 11.9691,
                "lng": 75.3208
            },
            "Od": {
                "lat": 22.6219,
                "lng": 73.1161
            },
            "K\u0101riy\u0101patti": {
                "lat": 9.6741,
                "lng": 78.1007
            },
            "Dumjor": {
                "lat": 22.64,
                "lng": 88.22
            },
            "Mukk\u016bdal": {
                "lat": 8.7431,
                "lng": 77.5225
            },
            "Devad\u0101nappatti": {
                "lat": 10.1467,
                "lng": 77.6439
            },
            "Unhel": {
                "lat": 23.3379,
                "lng": 75.5593
            },
            "Tilothu": {
                "lat": 24.8051,
                "lng": 84.0833
            },
            "Kum\u0101rapuram": {
                "lat": 8.2922,
                "lng": 77.3215
            },
            "Srikhanda": {
                "lat": 23.5981,
                "lng": 88.0799
            },
            "H\u0101rij": {
                "lat": 23.7,
                "lng": 71.9
            },
            "Bhaw\u0101n\u012bpur": {
                "lat": 26.2385,
                "lng": 86.0995
            },
            "Qatl\u016bpur": {
                "lat": 28.7733,
                "lng": 77.1593
            },
            "Agarpur": {
                "lat": 25.1957,
                "lng": 87.0423
            },
            "Chhoti S\u0101dri": {
                "lat": 24.38,
                "lng": 74.7
            },
            "Amm\u0101pettai": {
                "lat": 11.6197,
                "lng": 77.7426
            },
            "N\u0101ri Bhadaun": {
                "lat": 26.0065,
                "lng": 86.2509
            },
            "Send\u0101rappatti": {
                "lat": 11.4373,
                "lng": 78.5215
            },
            "Dary\u0101b\u0101d": {
                "lat": 26.8922,
                "lng": 81.5556
            },
            "Karuppur": {
                "lat": 11.717,
                "lng": 78.0924
            },
            "Mannara": {
                "lat": 8.5614,
                "lng": 77.1263
            },
            "Kasba Maker": {
                "lat": 25.9591,
                "lng": 85.0316
            },
            "M\u016blan\u016br": {
                "lat": 10.7943,
                "lng": 77.7115
            },
            "Asakapalle": {
                "lat": 17.7364,
                "lng": 83.3
            },
            "Mathur\u0101pur": {
                "lat": 24.9073,
                "lng": 85.9836
            },
            "N\u0101r\u0101yankher": {
                "lat": 18.0333,
                "lng": 77.7833
            },
            "Sivandipuram": {
                "lat": 8.7811,
                "lng": 77.3428
            },
            "K\u0101np": {
                "lat": 25.8337,
                "lng": 86.7402
            },
            "Kottukal": {
                "lat": 8.8833,
                "lng": 76.9
            },
            "Mahiari": {
                "lat": 22.59,
                "lng": 88.24
            },
            "Tirun\u0101geswaram": {
                "lat": 10.9646,
                "lng": 79.4293
            },
            "S\u0101hna": {
                "lat": 30.4293,
                "lng": 75.3813
            },
            "Kudatini": {
                "lat": 15.15,
                "lng": 76.92
            },
            "Budhni": {
                "lat": 22.7825,
                "lng": 77.682
            },
            "Udaipura": {
                "lat": 23.0743,
                "lng": 78.5111
            },
            "Mohiuddinnagar": {
                "lat": 25.5737,
                "lng": 85.6694
            },
            "Tummalapenta": {
                "lat": 15.0278,
                "lng": 78.0234
            },
            "Kottapalle": {
                "lat": 17.2918,
                "lng": 81.8939
            },
            "Dumri": {
                "lat": 25.8605,
                "lng": 84.5512
            },
            "Jora Khurd": {
                "lat": 26.4893,
                "lng": 77.9758
            },
            "Pottan\u016br": {
                "lat": 11.1098,
                "lng": 77.9888
            },
            "Rais\u0101ri": {
                "lat": 26.1319,
                "lng": 87.0238
            },
            "G\u0101ndarbal": {
                "lat": 34.2262,
                "lng": 74.7748
            },
            "M\u0101nikpur": {
                "lat": 25.3129,
                "lng": 87.4388
            },
            "Pah\u0101rpur": {
                "lat": 25.7225,
                "lng": 86.6203
            },
            "Tora": {
                "lat": 21.3262,
                "lng": 83.6708
            },
            "Kopa": {
                "lat": 25.8513,
                "lng": 84.6308
            },
            "Ar\u0101jpur": {
                "lat": 25.5197,
                "lng": 87.0808
            },
            "Tottiyam": {
                "lat": 10.988,
                "lng": 78.3363
            },
            "Kott\u00e1 Kalidindi": {
                "lat": 16.5032,
                "lng": 81.2877
            },
            "B\u0101dkulla": {
                "lat": 23.3036,
                "lng": 88.5302
            },
            "Annakunnu": {
                "lat": 11.7463,
                "lng": 76.0415
            },
            "Vilav\u016br": {
                "lat": 8.2669,
                "lng": 77.3052
            },
            "Timri": {
                "lat": 12.2,
                "lng": 75.3861
            },
            "Gidi": {
                "lat": 23.6909,
                "lng": 85.3641
            },
            "Koratagere": {
                "lat": 13.522,
                "lng": 77.2373
            },
            "Lakshmaneswaram": {
                "lat": 16.4082,
                "lng": 81.6867
            },
            "Ulao": {
                "lat": 25.4165,
                "lng": 86.0936
            },
            "Sakri": {
                "lat": 26.2197,
                "lng": 86.0853
            },
            "Gondar": {
                "lat": 29.5667,
                "lng": 76.7833
            },
            "Tank\u0101ra": {
                "lat": 22.656,
                "lng": 70.748
            },
            "Ghogaon": {
                "lat": 21.91,
                "lng": 75.7
            },
            "Mahraurh": {
                "lat": 25.2378,
                "lng": 84.1017
            },
            "Ath\u0101r": {
                "lat": 26.0392,
                "lng": 86.059
            },
            "Ty\u0101gadurgam": {
                "lat": 11.7411,
                "lng": 79.077
            },
            "Sukhsena": {
                "lat": 25.6881,
                "lng": 87.1194
            },
            "R\u0101mpur Tilak": {
                "lat": 25.8513,
                "lng": 87.093
            },
            "R\u0101jmahal": {
                "lat": 25.05,
                "lng": 87.84
            },
            "Chaita": {
                "lat": 25.7671,
                "lng": 85.8792
            },
            "Payyann\u016br": {
                "lat": 12.0935,
                "lng": 75.2025
            },
            "Jadcherla": {
                "lat": 16.763,
                "lng": 78.1443
            },
            "Erum\u0101p\u0101laiyam": {
                "lat": 11.6324,
                "lng": 78.1695
            },
            "Barv\u0101la": {
                "lat": 22.15,
                "lng": 71.9
            },
            "M\u016bkondapalli": {
                "lat": 12.7514,
                "lng": 77.8017
            },
            "Yeddumail\u0101ram": {
                "lat": 17.5011,
                "lng": 78.1242
            },
            "Kuchinda": {
                "lat": 21.7436,
                "lng": 84.3485
            },
            "Manthani": {
                "lat": 18.65,
                "lng": 79.6667
            },
            "Khokha": {
                "lat": 25.9741,
                "lng": 87.4062
            },
            "Silv\u0101ni": {
                "lat": 23.3026,
                "lng": 78.4408
            },
            "Pudunagaram": {
                "lat": 10.681,
                "lng": 76.6611
            },
            "Sanghera": {
                "lat": 30.3986,
                "lng": 75.5614
            },
            "Veldurti": {
                "lat": 15.5667,
                "lng": 77.9167
            },
            "Vil\u0101ttikulam": {
                "lat": 9.1312,
                "lng": 78.1687
            },
            "Mart\u016bru": {
                "lat": 10.1694,
                "lng": 76.4292
            },
            "Pachm\u012br": {
                "lat": 25.4077,
                "lng": 86.3803
            },
            "Marwa": {
                "lat": 25.3994,
                "lng": 86.9257
            },
            "Vissannapeta": {
                "lat": 16.9398,
                "lng": 80.7828
            },
            "Shir\u0101li": {
                "lat": 14.0297,
                "lng": 74.528
            },
            "Goshaingaon": {
                "lat": 26.4395,
                "lng": 89.9631
            },
            "Bh\u012bkhi": {
                "lat": 30.07,
                "lng": 75.53
            },
            "Pariharpur": {
                "lat": 26.7114,
                "lng": 85.6797
            },
            "Dh\u0101ns\u0101ria": {
                "lat": 24.7219,
                "lng": 87.8572
            },
            "Bagahi": {
                "lat": 26.7632,
                "lng": 84.467
            },
            "Vennand\u016br": {
                "lat": 11.515,
                "lng": 78.0908
            },
            "Am\u012bnpur": {
                "lat": 17.5241,
                "lng": 78.3242
            },
            "Mankara": {
                "lat": 10.75,
                "lng": 76.4333
            },
            "Arakkap\u0101di": {
                "lat": 10.0599,
                "lng": 76.4592
            },
            "Dhamaun": {
                "lat": 25.5999,
                "lng": 85.5479
            },
            "Dergaon": {
                "lat": 26.7,
                "lng": 93.9667
            },
            "Kutt\u0101lam": {
                "lat": 11.0758,
                "lng": 79.5608
            },
            "Kodiy\u0113ri": {
                "lat": 11.7067,
                "lng": 75.5331
            },
            "Lauk\u0101ha": {
                "lat": 26.0336,
                "lng": 86.6471
            },
            "Susner": {
                "lat": 23.9467,
                "lng": 76.0882
            },
            "Kharhi\u0101l": {
                "lat": 20.2885,
                "lng": 82.7606
            },
            "Vettavalam": {
                "lat": 12.1077,
                "lng": 79.2452
            },
            "Kaly\u0101npur Bamaiya": {
                "lat": 25.714,
                "lng": 85.9212
            },
            "Marip\u0101d": {
                "lat": 17.372,
                "lng": 79.8807
            },
            "P\u016blla": {
                "lat": 16.806,
                "lng": 81.3241
            },
            "Vandal\u016br": {
                "lat": 12.8924,
                "lng": 80.0808
            },
            "Kappiyara": {
                "lat": 8.2466,
                "lng": 77.2617
            },
            "Valluvandad": {
                "lat": 11.0996,
                "lng": 76.1972
            },
            "Vettikattiri": {
                "lat": 11.1167,
                "lng": 76.1833
            },
            "Pupri": {
                "lat": 26.4708,
                "lng": 85.7031
            },
            "M\u0101nullahpatti": {
                "lat": 26.0619,
                "lng": 87.1751
            },
            "Vittal": {
                "lat": 12.766,
                "lng": 75.122
            },
            "Omall\u016br": {
                "lat": 9.2468,
                "lng": 76.751
            },
            "Manganj": {
                "lat": 26.1388,
                "lng": 86.9944
            },
            "Inkollu": {
                "lat": 15.83,
                "lng": 80.2
            },
            "Chakkuvarakal": {
                "lat": 8.9833,
                "lng": 76.85
            },
            "Peruvanth\u0101nam": {
                "lat": 9.55,
                "lng": 76.9167
            },
            "R\u0101yappanpatti": {
                "lat": 9.7997,
                "lng": 77.2372
            },
            "Krishnapuram": {
                "lat": 10.3667,
                "lng": 76.15
            },
            "Kambla": {
                "lat": 12.9607,
                "lng": 74.9332
            },
            "Bist\u0101ria": {
                "lat": 26.1262,
                "lng": 87.23
            },
            "Khut\u0101ha": {
                "lat": 25.2645,
                "lng": 86.0598
            },
            "P\u0101laiyam": {
                "lat": 10.7256,
                "lng": 78.1354
            },
            "Punnay\u016br": {
                "lat": 10.65,
                "lng": 76.0
            },
            "Aliy\u0101b\u0101d": {
                "lat": 12.6317,
                "lng": 79.2164
            },
            "Kori\u0101patti": {
                "lat": 26.1149,
                "lng": 86.9905
            },
            "Etacheri": {
                "lat": 10.6028,
                "lng": 76.6444
            },
            "V\u0101yalp\u0101d": {
                "lat": 13.65,
                "lng": 78.6333
            },
            "Turuvekere": {
                "lat": 13.1637,
                "lng": 76.6664
            },
            "Shirva": {
                "lat": 13.2465,
                "lng": 74.8444
            },
            "Attimarappatti": {
                "lat": 8.7297,
                "lng": 78.1169
            },
            "Elank\u016br": {
                "lat": 11.1333,
                "lng": 76.1667
            },
            "Eksambe": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Buguda": {
                "lat": 19.8081,
                "lng": 84.7908
            },
            "Kudav\u0101sal": {
                "lat": 10.8582,
                "lng": 79.4823
            },
            "Bhucho Mandi": {
                "lat": 30.2129,
                "lng": 75.0969
            },
            "Dharampuri": {
                "lat": 22.1495,
                "lng": 75.3444
            },
            "Anant\u0101v\u016br": {
                "lat": 10.8925,
                "lng": 75.9983
            },
            "Gautampura": {
                "lat": 22.9866,
                "lng": 75.5192
            },
            "Karkamb": {
                "lat": 17.863,
                "lng": 75.2981
            },
            "Abdullahnagar": {
                "lat": 25.7683,
                "lng": 87.5557
            },
            "Nedugula": {
                "lat": 11.4764,
                "lng": 76.8904
            },
            "Lakhnaur": {
                "lat": 26.202,
                "lng": 86.309
            },
            "N\u012bsang": {
                "lat": 29.69,
                "lng": 76.75
            },
            "Reota": {
                "lat": 25.8194,
                "lng": 86.3061
            },
            "Seoni Chhap\u0101ra": {
                "lat": 22.3939,
                "lng": 79.5424
            },
            "Nah\u0101z\u0101ri": {
                "lat": 22.4347,
                "lng": 88.2487
            },
            "Karahia": {
                "lat": 26.4054,
                "lng": 86.149
            },
            "Ummann\u016br": {
                "lat": 8.95,
                "lng": 76.7833
            },
            "Namminikara": {
                "lat": 10.4392,
                "lng": 76.268
            },
            "Jhagarua": {
                "lat": 26.0059,
                "lng": 86.3496
            },
            "Fraserpet": {
                "lat": 12.4587,
                "lng": 75.9605
            },
            "Odaipatti": {
                "lat": 9.8323,
                "lng": 77.4433
            },
            "M\u0101rtahalli": {
                "lat": 11.9919,
                "lng": 77.4972
            },
            "Bhagab\u0101npur": {
                "lat": 24.7765,
                "lng": 88.0217
            },
            "Curti": {
                "lat": 15.4167,
                "lng": 74.0167
            },
            "Banni": {
                "lat": 25.4692,
                "lng": 86.6068
            },
            "Kurugodu": {
                "lat": 15.346,
                "lng": 76.836
            },
            "Gunj\u0101palle": {
                "lat": 14.3845,
                "lng": 77.9495
            },
            "Barhan": {
                "lat": 27.3299,
                "lng": 78.1896
            },
            "S\u0101nwer": {
                "lat": 22.9742,
                "lng": 75.8271
            },
            "Kembh\u0101vi": {
                "lat": 16.65,
                "lng": 76.5333
            },
            "Ibr\u0101h\u012bmpatan": {
                "lat": 17.1017,
                "lng": 78.6294
            },
            "Kamudi": {
                "lat": 9.409,
                "lng": 78.3679
            },
            "Gothini": {
                "lat": 26.1626,
                "lng": 84.0379
            },
            "Yerrap\u0101lem": {
                "lat": 16.15,
                "lng": 80.03
            },
            "Mau Dhaneshpur": {
                "lat": 25.5899,
                "lng": 85.8337
            },
            "Mudukulatt\u016br": {
                "lat": 9.344,
                "lng": 78.5138
            },
            "Chadchan": {
                "lat": 17.3126,
                "lng": 75.6581
            },
            "Ch\u0101rip\u0101ra": {
                "lat": 20.45,
                "lng": 84.4
            },
            "S\u0101ram": {
                "lat": 23.7625,
                "lng": 85.83
            },
            "Chorhat": {
                "lat": 24.4274,
                "lng": 81.6695
            },
            "Keota": {
                "lat": 25.644,
                "lng": 85.8287
            },
            "Jawkati\u0101": {
                "lat": 26.7308,
                "lng": 84.6263
            },
            "Talwandi Bhai": {
                "lat": 30.8622,
                "lng": 74.9285
            },
            "Kukraun": {
                "lat": 25.7647,
                "lng": 87.1847
            },
            "M\u016blki": {
                "lat": 13.1,
                "lng": 74.8
            },
            "Kulasegaram": {
                "lat": 8.3667,
                "lng": 77.3
            },
            "Dehti": {
                "lat": 26.2115,
                "lng": 87.5513
            },
            "Kh\u0101npur": {
                "lat": 25.341,
                "lng": 83.074
            },
            "Dharm\u0101puri": {
                "lat": 18.9475,
                "lng": 79.094
            },
            "Baturb\u0101ri": {
                "lat": 26.2281,
                "lng": 87.4714
            },
            "Mettupp\u0101laiyam": {
                "lat": 11.4503,
                "lng": 77.5682
            },
            "Nadugadda": {
                "lat": 16.6241,
                "lng": 79.4448
            },
            "P\u0101vumba": {
                "lat": 9.0597,
                "lng": 76.8889
            },
            "Bel\u0101koba": {
                "lat": 26.59,
                "lng": 88.589
            },
            "Raiyam": {
                "lat": 26.2702,
                "lng": 86.2071
            },
            "Badantola": {
                "lat": 25.2517,
                "lng": 87.842
            },
            "Cherakara": {
                "lat": 8.8456,
                "lng": 76.7164
            },
            "Padugup\u0101du": {
                "lat": 14.4885,
                "lng": 79.9923
            },
            "Kayaralam": {
                "lat": 12.0,
                "lng": 75.4167
            },
            "Settiy\u0101rpatti": {
                "lat": 9.3935,
                "lng": 77.4935
            },
            "Maj\u012btha": {
                "lat": 31.7571,
                "lng": 74.9589
            },
            "Ayin\u012bkk\u0101d": {
                "lat": 11.4974,
                "lng": 75.72
            },
            "P\u0101nchgr\u0101m": {
                "lat": 24.1996,
                "lng": 88.0077
            },
            "Giria": {
                "lat": 24.5167,
                "lng": 88.0767
            },
            "Kutiy\u0101na": {
                "lat": 21.63,
                "lng": 69.98
            },
            "Pottassh\u0113ri": {
                "lat": 10.995,
                "lng": 76.5053
            },
            "Kolaccheri": {
                "lat": 11.9737,
                "lng": 75.4083
            },
            "T\u0101digadapa": {
                "lat": 16.4713,
                "lng": 80.697
            },
            "Chandia": {
                "lat": 23.6565,
                "lng": 80.7091
            },
            "Tol\u016bprpatti": {
                "lat": 11.0244,
                "lng": 78.3134
            },
            "Usm\u0101npur": {
                "lat": 25.3487,
                "lng": 86.9669
            },
            "Peragamanna": {
                "lat": 11.2139,
                "lng": 76.127
            },
            "Sarotar": {
                "lat": 26.4291,
                "lng": 84.7813
            },
            "Gudipallip\u0101du": {
                "lat": 14.4588,
                "lng": 80.0482
            },
            "Mannukara": {
                "lat": 9.3,
                "lng": 76.5333
            },
            "\u0100yikudi": {
                "lat": 9.0032,
                "lng": 77.3449
            },
            "Challapalle": {
                "lat": 16.1167,
                "lng": 80.9333
            },
            "M\u0101valli": {
                "lat": 14.1,
                "lng": 74.4833
            },
            "Ramnagar": {
                "lat": 22.3245,
                "lng": 88.494
            },
            "Pernamitta": {
                "lat": 15.5333,
                "lng": 80.0
            },
            "N\u0101yakanhatti": {
                "lat": 14.4644,
                "lng": 76.5451
            },
            "S\u012bt\u0101mau": {
                "lat": 24.0147,
                "lng": 75.3532
            },
            "Avitanallur": {
                "lat": 11.5002,
                "lng": 75.8059
            },
            "J\u0101yal": {
                "lat": 27.2167,
                "lng": 74.1833
            },
            "Matauna": {
                "lat": 26.6699,
                "lng": 85.487
            },
            "Alauli": {
                "lat": 25.644,
                "lng": 86.4065
            },
            "Naula": {
                "lat": 25.5535,
                "lng": 86.0636
            },
            "Kakraul": {
                "lat": 26.362,
                "lng": 86.0139
            },
            "Ambalavayal": {
                "lat": 11.619,
                "lng": 76.2102
            },
            "Shankar Saraiy\u0101": {
                "lat": 26.5967,
                "lng": 84.8526
            },
            "Sikandra": {
                "lat": 24.9564,
                "lng": 86.0344
            },
            "Sr\u012bk\u016brmam": {
                "lat": 18.2708,
                "lng": 84.0044
            },
            "K\u014dr\u014dth": {
                "lat": 11.5833,
                "lng": 75.7667
            },
            "R\u0101machandrapuran": {
                "lat": 17.4944,
                "lng": 78.2942
            },
            "Ughara": {
                "lat": 26.0563,
                "lng": 86.0013
            },
            "Meltonakkal": {
                "lat": 8.6341,
                "lng": 76.8521
            },
            "Bishunpura": {
                "lat": 26.5436,
                "lng": 84.4364
            },
            "Dirba": {
                "lat": 30.07,
                "lng": 75.98
            },
            "Kombai": {
                "lat": 10.6143,
                "lng": 78.1212
            },
            "Perup\u0101lem": {
                "lat": 16.3705,
                "lng": 81.5813
            },
            "Chavara Gr\u0101mam": {
                "lat": 8.9633,
                "lng": 76.5619
            },
            "Tekanpur": {
                "lat": 25.994,
                "lng": 78.2832
            },
            "Baikatpur": {
                "lat": 25.4939,
                "lng": 85.3792
            },
            "M\u0101lancha": {
                "lat": 24.666,
                "lng": 87.922
            },
            "N\u0101ranattenvanpatti": {
                "lat": 9.7233,
                "lng": 77.3099
            },
            "Nidgundi": {
                "lat": 16.3583,
                "lng": 75.931
            },
            "Pallikapuzha": {
                "lat": 8.8412,
                "lng": 76.8007
            },
            "R\u0101m\u0101yampet": {
                "lat": 18.1166,
                "lng": 78.4298
            },
            "Dhulkot": {
                "lat": 21.6095,
                "lng": 75.5522
            },
            "Khed": {
                "lat": 17.7178,
                "lng": 73.3939
            },
            "Ettaiy\u0101puram": {
                "lat": 9.1474,
                "lng": 77.9928
            },
            "M\u0101jra": {
                "lat": 28.667,
                "lng": 76.456
            },
            "Agaram": {
                "lat": 10.4433,
                "lng": 77.9485
            },
            "Hosakote": {
                "lat": 14.2817,
                "lng": 77.1742
            },
            "S\u0101yarpuram": {
                "lat": 8.6822,
                "lng": 78.0265
            },
            "R\u0101mpur Jal\u0101lpur": {
                "lat": 25.6712,
                "lng": 85.8606
            },
            "Pulimel": {
                "lat": 9.3,
                "lng": 76.5833
            },
            "Madanpur": {
                "lat": 24.6554,
                "lng": 84.5844
            },
            "Meiti": {
                "lat": 24.2477,
                "lng": 94.3031
            },
            "Sh\u0101hkot": {
                "lat": 31.08,
                "lng": 75.34
            },
            "Acharipallam": {
                "lat": 8.17,
                "lng": 77.3698
            },
            "Vech\u016br": {
                "lat": 9.6667,
                "lng": 76.4167
            },
            "Achalj\u0101mu": {
                "lat": 24.0243,
                "lng": 85.8306
            },
            "Bhainsoda": {
                "lat": 24.4427,
                "lng": 75.8403
            },
            "Kadikk\u0101d": {
                "lat": 10.6667,
                "lng": 75.9667
            },
            "Vazhani": {
                "lat": 10.5917,
                "lng": 76.5333
            },
            "Kodikulam": {
                "lat": 9.9932,
                "lng": 79.2007
            },
            "Puliyankunnu": {
                "lat": 9.412,
                "lng": 76.41
            },
            "K\u0101mavarapukota": {
                "lat": 17.0031,
                "lng": 81.1219
            },
            "Tsundupalle": {
                "lat": 13.9809,
                "lng": 78.9152
            },
            "Dhobauli": {
                "lat": 25.4008,
                "lng": 86.1885
            },
            "B\u0101uria": {
                "lat": 22.4521,
                "lng": 88.1853
            },
            "Attappampatti": {
                "lat": 11.482,
                "lng": 78.0248
            },
            "Nandigaon": {
                "lat": 17.119,
                "lng": 78.2564
            },
            "Kadod": {
                "lat": 21.218,
                "lng": 73.22
            },
            "Thandla": {
                "lat": 23.0096,
                "lng": 74.5775
            },
            "Arakkal": {
                "lat": 8.948,
                "lng": 76.8631
            },
            "Livinjipuram": {
                "lat": 8.1535,
                "lng": 77.5719
            },
            "Tengampud\u016br": {
                "lat": 8.1158,
                "lng": 77.4579
            },
            "Takhatgarh": {
                "lat": 25.33,
                "lng": 73.0
            },
            "Sendurai": {
                "lat": 11.253,
                "lng": 79.172
            },
            "Chal\u0101la": {
                "lat": 21.42,
                "lng": 71.17
            },
            "Iluppur": {
                "lat": 10.5137,
                "lng": 78.6216
            },
            "Kodumudi": {
                "lat": 11.0769,
                "lng": 77.8876
            },
            "Kattiyeri": {
                "lat": 11.8764,
                "lng": 75.8542
            },
            "Balighattam": {
                "lat": 17.651,
                "lng": 82.6059
            },
            "Gonegandla": {
                "lat": 15.7167,
                "lng": 77.6
            },
            "Pazhayann\u016br": {
                "lat": 10.6661,
                "lng": 76.4252
            },
            "Piriy\u0101patna": {
                "lat": 12.3365,
                "lng": 76.0792
            },
            "Erum\u0101d": {
                "lat": 11.5681,
                "lng": 76.2608
            },
            "H\u0101vi Bhau\u0101r": {
                "lat": 26.1091,
                "lng": 86.1059
            },
            "Deoria": {
                "lat": 26.1791,
                "lng": 85.0234
            },
            "Kundal": {
                "lat": 25.762,
                "lng": 86.2081
            },
            "Podalak\u016br": {
                "lat": 14.3667,
                "lng": 79.7333
            },
            "Kakkalapalle": {
                "lat": 14.6415,
                "lng": 77.5668
            },
            "Arani": {
                "lat": 13.3346,
                "lng": 80.0848
            },
            "T\u0101wargeri": {
                "lat": 15.7668,
                "lng": 76.4037
            },
            "Khirhar": {
                "lat": 26.5168,
                "lng": 85.9618
            },
            "Ittiva": {
                "lat": 8.85,
                "lng": 76.8833
            },
            "Mull\u0101nw\u0101la": {
                "lat": 31.0619,
                "lng": 74.8228
            },
            "Barg\u016br": {
                "lat": 12.5429,
                "lng": 78.3574
            },
            "Khani\u0101dh\u0101na": {
                "lat": 25.0298,
                "lng": 78.1271
            },
            "Pannaipuram": {
                "lat": 9.7908,
                "lng": 77.1578
            },
            "Bijni": {
                "lat": 26.4959,
                "lng": 90.703
            },
            "Mohan Eghu": {
                "lat": 25.4002,
                "lng": 86.145
            },
            "Barkot": {
                "lat": 30.82,
                "lng": 78.2
            },
            "Paragaticherla": {
                "lat": 16.2496,
                "lng": 79.9679
            },
            "Lakhipur": {
                "lat": 26.3281,
                "lng": 88.3863
            },
            "Bhai Rupa": {
                "lat": 30.4311,
                "lng": 75.2206
            },
            "Domch\u0101nch": {
                "lat": 24.4748,
                "lng": 85.6921
            },
            "Parali": {
                "lat": 10.8028,
                "lng": 76.5585
            },
            "Mangrauni": {
                "lat": 26.3479,
                "lng": 86.0719
            },
            "Rentachintala": {
                "lat": 16.5525,
                "lng": 79.5533
            },
            "Pil\u0101ppulli": {
                "lat": 10.7344,
                "lng": 76.7294
            },
            "Mudakkiraye": {
                "lat": 9.95,
                "lng": 76.55
            },
            "Amba Icharua": {
                "lat": 25.614,
                "lng": 86.4258
            },
            "R\u0101sivarai Tottam": {
                "lat": 9.9639,
                "lng": 77.1
            },
            "Erv\u0101di": {
                "lat": 9.2082,
                "lng": 78.7101
            },
            "R\u0101jbalh\u0101i": {
                "lat": 22.7758,
                "lng": 88.0054
            },
            "Dighaun": {
                "lat": 25.5549,
                "lng": 86.8097
            },
            "Ujre": {
                "lat": 12.9961,
                "lng": 75.3248
            },
            "Dahibh\u0101t M\u0101dhopur": {
                "lat": 26.2763,
                "lng": 86.0847
            },
            "Chopadandi": {
                "lat": 18.5833,
                "lng": 79.1667
            },
            "Mohan\u016br": {
                "lat": 11.0594,
                "lng": 78.1397
            },
            "Sakardih": {
                "lat": 25.228,
                "lng": 85.3546
            },
            "Gurh": {
                "lat": 24.5026,
                "lng": 81.5004
            },
            "R\u0101jnagar": {
                "lat": 23.95,
                "lng": 87.32
            },
            "Nilaiy\u016br": {
                "lat": 9.8572,
                "lng": 78.0598
            },
            "Baro": {
                "lat": 25.4487,
                "lng": 85.9924
            },
            "Pudu": {
                "lat": 12.8667,
                "lng": 74.9673
            },
            "R\u0101ikal": {
                "lat": 18.9,
                "lng": 78.8
            },
            "Hatti": {
                "lat": 16.1984,
                "lng": 76.6604
            },
            "Mahesh Khunt": {
                "lat": 25.45,
                "lng": 86.6333
            },
            "Kesath": {
                "lat": 25.4208,
                "lng": 84.2414
            },
            "Gokavaram": {
                "lat": 17.2667,
                "lng": 81.85
            },
            "Kharg\u0101pur": {
                "lat": 24.823,
                "lng": 79.144
            },
            "Khirpai": {
                "lat": 22.7,
                "lng": 87.62
            },
            "Phirangipuram": {
                "lat": 16.2889,
                "lng": 80.2628
            },
            "Usgao": {
                "lat": 15.4333,
                "lng": 74.0667
            },
            "Nunna": {
                "lat": 16.5788,
                "lng": 80.6841
            },
            "Mah\u0101landi": {
                "lat": 24.0738,
                "lng": 88.1214
            },
            "Chettin\u0101yakkanpatti": {
                "lat": 10.394,
                "lng": 77.9754
            },
            "B\u0101rnia": {
                "lat": 23.7311,
                "lng": 88.4329
            },
            "G\u0101livedu": {
                "lat": 14.0333,
                "lng": 78.5
            },
            "Kakching": {
                "lat": 24.6085,
                "lng": 94.0029
            },
            "Kargil": {
                "lat": 34.5594,
                "lng": 76.1256
            },
            "Vadugappatti": {
                "lat": 10.1036,
                "lng": 77.5731
            },
            "Khunti Dhanaili": {
                "lat": 25.9376,
                "lng": 87.379
            },
            "Noniy\u0101": {
                "lat": 26.6763,
                "lng": 84.6163
            },
            "Kodala": {
                "lat": 19.6243,
                "lng": 84.9408
            },
            "Arachchal\u016br": {
                "lat": 11.1627,
                "lng": 77.7007
            },
            "Sohtha": {
                "lat": 25.6449,
                "lng": 87.3068
            },
            "M\u0101lh\u012bpur": {
                "lat": 25.4069,
                "lng": 86.0234
            },
            "Barharwa Kal\u0101n": {
                "lat": 26.5434,
                "lng": 84.8286
            },
            "Birsinghpur": {
                "lat": 24.7981,
                "lng": 80.9739
            },
            "Omal\u016br": {
                "lat": 11.745,
                "lng": 78.047
            },
            "Madukk\u016br": {
                "lat": 10.48,
                "lng": 79.4
            },
            "Jagdispur": {
                "lat": 22.65,
                "lng": 88.29
            },
            "Jal\u0101lpur": {
                "lat": 26.0433,
                "lng": 84.5041
            },
            "Manamelkudi": {
                "lat": 10.0419,
                "lng": 79.23
            },
            "B\u0101lasamudram": {
                "lat": 10.419,
                "lng": 77.4992
            },
            "Cher\u0101n": {
                "lat": 25.361,
                "lng": 85.5421
            },
            "Mukht\u0101rpur Salkani": {
                "lat": 25.6643,
                "lng": 85.7784
            },
            "Tadikalap\u016bdi": {
                "lat": 16.5356,
                "lng": 81.1033
            },
            "Vitthal\u0101puram": {
                "lat": 8.6833,
                "lng": 77.8292
            },
            "Singhwara": {
                "lat": 26.1842,
                "lng": 85.7368
            },
            "Porum\u0101milla": {
                "lat": 15.0167,
                "lng": 78.9833
            },
            "Gokarna": {
                "lat": 24.0541,
                "lng": 88.1176
            },
            "Khutauna": {
                "lat": 26.4969,
                "lng": 86.3959
            },
            "Saraikela": {
                "lat": 22.6996,
                "lng": 85.9313
            },
            "Bandora": {
                "lat": 15.4082,
                "lng": 73.9813
            },
            "Kanhauli Manohar": {
                "lat": 25.9755,
                "lng": 84.6853
            },
            "Att\u016br": {
                "lat": 8.3224,
                "lng": 77.2558
            },
            "It\u0101hri": {
                "lat": 25.313,
                "lng": 86.5459
            },
            "Aruv\u0101palam": {
                "lat": 9.1833,
                "lng": 76.85
            },
            "Chot\u0101la": {
                "lat": 29.7808,
                "lng": 74.5226
            },
            "Ganeshpur": {
                "lat": 25.7678,
                "lng": 87.3587
            },
            "Baharu": {
                "lat": 22.204,
                "lng": 88.4283
            },
            "\u0100giripalle": {
                "lat": 16.6833,
                "lng": 80.65
            },
            "Kitt\u016br": {
                "lat": 15.6,
                "lng": 74.9
            },
            "Jaypul": {
                "lat": 22.7833,
                "lng": 88.5695
            },
            "Jakkamp\u0101laiyam": {
                "lat": 10.5264,
                "lng": 77.2884
            },
            "Chenn\u016br": {
                "lat": 14.1473,
                "lng": 79.8477
            },
            "Rustampur": {
                "lat": 25.57,
                "lng": 85.3061
            },
            "Math Lohiy\u0101r": {
                "lat": 26.6247,
                "lng": 84.7072
            },
            "K\u0101mayakkavundanpatti": {
                "lat": 9.7386,
                "lng": 77.32
            },
            "Sult\u0101n\u0101b\u0101d": {
                "lat": 18.5247,
                "lng": 79.2722
            },
            "Cherukunnu": {
                "lat": 12.0041,
                "lng": 75.3004
            },
            "Khusropur": {
                "lat": 25.4817,
                "lng": 85.3849
            },
            "Pilkha": {
                "lat": 23.1645,
                "lng": 83.1821
            },
            "Bakarpur Ogairah": {
                "lat": 25.3294,
                "lng": 87.5138
            },
            "Kajur": {
                "lat": 24.8768,
                "lng": 85.292
            },
            "S\u016brappalli": {
                "lat": 11.7187,
                "lng": 77.8778
            },
            "Birhana": {
                "lat": 25.4489,
                "lng": 85.7054
            },
            "Anantapalle": {
                "lat": 16.9767,
                "lng": 81.4442
            },
            "Aur\u0101hi": {
                "lat": 26.0355,
                "lng": 86.7467
            },
            "Ott\u016br": {
                "lat": 8.7167,
                "lng": 76.7667
            },
            "Fatipura": {
                "lat": 22.8383,
                "lng": 74.2313
            },
            "Ottakkadai": {
                "lat": 9.958,
                "lng": 78.1886
            },
            "Koll\u016bru": {
                "lat": 16.1847,
                "lng": 80.7961
            },
            "R\u0101mew\u0101di": {
                "lat": 16.0333,
                "lng": 74.35
            },
            "Kalanj\u016br": {
                "lat": 9.1167,
                "lng": 76.85
            },
            "Bhiloda": {
                "lat": 23.46,
                "lng": 73.15
            },
            "Makhu": {
                "lat": 31.1033,
                "lng": 74.9963
            },
            "Vontimitta": {
                "lat": 14.3833,
                "lng": 79.0333
            },
            "B\u0101dsh\u0101hpur": {
                "lat": 28.393,
                "lng": 77.048
            },
            "Ayyamp\u0101laiyam": {
                "lat": 10.2253,
                "lng": 77.7485
            },
            "Sivapuram": {
                "lat": 11.9075,
                "lng": 75.6048
            },
            "Mayil\u016br": {
                "lat": 9.942,
                "lng": 76.6395
            },
            "Penugonda": {
                "lat": 16.6547,
                "lng": 81.7445
            },
            "K\u0101yanna": {
                "lat": 11.4917,
                "lng": 75.8417
            },
            "R\u0101hon": {
                "lat": 31.0527,
                "lng": 76.1191
            },
            "Marah\u014dm": {
                "lat": 33.8303,
                "lng": 75.0832
            },
            "R\u0101ni Shakarpura": {
                "lat": 25.5534,
                "lng": 86.2992
            },
            "Sapatgr\u0101m": {
                "lat": 26.3373,
                "lng": 90.1236
            },
            "Utn\u016br": {
                "lat": 19.3667,
                "lng": 78.7667
            },
            "Kumaranall\u016br": {
                "lat": 9.6167,
                "lng": 76.5833
            },
            "Sughr\u0101in": {
                "lat": 25.746,
                "lng": 86.325
            },
            "As\u0101ra": {
                "lat": 29.2494,
                "lng": 77.2915
            },
            "Dandu Mail\u0101ram": {
                "lat": 17.2333,
                "lng": 78.7667
            },
            "W\u0101tr\u0101p": {
                "lat": 9.6353,
                "lng": 77.6389
            },
            "Neriyamangalam": {
                "lat": 10.05,
                "lng": 76.7833
            },
            "Nan\u0101ttup\u0101rai": {
                "lat": 9.379,
                "lng": 76.8779
            },
            "R\u0101iganj B\u0101z\u0101r": {
                "lat": 26.78,
                "lng": 82.143
            },
            "J\u0101walgeri": {
                "lat": 15.8656,
                "lng": 76.8156
            },
            "Kizh\u016br": {
                "lat": 11.9667,
                "lng": 75.6667
            },
            "Kudali": {
                "lat": 9.15,
                "lng": 76.85
            },
            "Chittayankottai": {
                "lat": 10.2686,
                "lng": 77.8327
            },
            "Neykk\u0101rappatti": {
                "lat": 11.6225,
                "lng": 78.1058
            },
            "Valavak\u0101ttum\u016bla": {
                "lat": 9.98,
                "lng": 76.52
            },
            "Mohanpur": {
                "lat": 25.5507,
                "lng": 85.3222
            },
            "Amarw\u0101ra": {
                "lat": 22.2978,
                "lng": 79.1694
            },
            "Bahut\u0101l": {
                "lat": 24.5714,
                "lng": 87.8971
            },
            "P\u0101tapatnam": {
                "lat": 18.75,
                "lng": 84.0833
            },
            "Tagazhi": {
                "lat": 9.3707,
                "lng": 76.4115
            },
            "Brahmadesam": {
                "lat": 11.5449,
                "lng": 77.5803
            },
            "Manatanna": {
                "lat": 11.9125,
                "lng": 75.7569
            },
            "Karambakkudi": {
                "lat": 10.4587,
                "lng": 79.141
            },
            "S\u0101yalkudi": {
                "lat": 9.1692,
                "lng": 78.447
            },
            "Suchindram": {
                "lat": 8.1544,
                "lng": 77.467
            },
            "Heggadadevankote": {
                "lat": 12.0881,
                "lng": 76.3296
            },
            "Vijayapuri North": {
                "lat": 16.6028,
                "lng": 79.3075
            },
            "Vellikulangara": {
                "lat": 10.3667,
                "lng": 76.4167
            },
            "Dighirp\u0101r": {
                "lat": 22.3034,
                "lng": 88.6678
            },
            "Kishund\u0101spur": {
                "lat": 25.3334,
                "lng": 87.3318
            },
            "Udayagiri": {
                "lat": 20.1242,
                "lng": 84.3687
            },
            "Nidiyanga": {
                "lat": 12.0889,
                "lng": 75.4806
            },
            "Valabh\u012bpur": {
                "lat": 21.8878,
                "lng": 71.8795
            },
            "Pawai": {
                "lat": 24.2664,
                "lng": 80.162
            },
            "Gandevi": {
                "lat": 20.82,
                "lng": 72.98
            },
            "Chhapra Bah\u0101s": {
                "lat": 26.7266,
                "lng": 84.7912
            },
            "Odaiyakulam": {
                "lat": 10.5679,
                "lng": 76.9105
            },
            "Dhusar Tik\u0101patti": {
                "lat": 25.5214,
                "lng": 87.2212
            },
            "Sr\u012bmushnam": {
                "lat": 11.4012,
                "lng": 79.4038
            },
            "Kodikkulam": {
                "lat": 9.9811,
                "lng": 78.2121
            },
            "Kadamakudi": {
                "lat": 10.0652,
                "lng": 76.2451
            },
            "Tanakpur": {
                "lat": 29.074,
                "lng": 80.109
            },
            "Cholai": {
                "lat": 12.04,
                "lng": 75.46
            },
            "P\u016bluvappatti": {
                "lat": 10.963,
                "lng": 76.8158
            },
            "Dh\u0101ni Sukhan": {
                "lat": 28.7932,
                "lng": 76.0502
            },
            "Belaur": {
                "lat": 25.4477,
                "lng": 84.6473
            },
            "Kunithala": {
                "lat": 11.9,
                "lng": 75.7833
            },
            "Dh\u016bmnagar": {
                "lat": 26.756,
                "lng": 84.5233
            },
            "Vardann\u0101pet": {
                "lat": 17.7735,
                "lng": 79.5731
            },
            "K\u0101ndla Port": {
                "lat": 23.03,
                "lng": 70.22
            },
            "Chethakal": {
                "lat": 9.4386,
                "lng": 76.8322
            },
            "Mahudha": {
                "lat": 22.82,
                "lng": 72.93
            },
            "Kannamp\u0101laiyam": {
                "lat": 10.9954,
                "lng": 77.099
            },
            "Mansingha": {
                "lat": 26.7807,
                "lng": 84.8367
            },
            "Akamb\u0101dam": {
                "lat": 11.1,
                "lng": 76.3167
            },
            "Kalan\u0101du": {
                "lat": 12.4667,
                "lng": 75.0
            },
            "R\u016bpb\u0101s": {
                "lat": 26.9833,
                "lng": 77.5833
            },
            "Kasimkota": {
                "lat": 17.6736,
                "lng": 82.9634
            },
            "Son\u0101pur": {
                "lat": 26.3716,
                "lng": 87.2162
            },
            "Trisshil\u0113ri": {
                "lat": 11.8333,
                "lng": 76.0333
            },
            "Puduppatti": {
                "lat": 9.6171,
                "lng": 77.6375
            },
            "D\u016bbacherla": {
                "lat": 16.9116,
                "lng": 81.3569
            },
            "Tar\u016br": {
                "lat": 10.6722,
                "lng": 76.4694
            },
            "Eravatt\u016br": {
                "lat": 11.5833,
                "lng": 75.7333
            },
            "Bharanik\u0101vu Tekku": {
                "lat": 9.1847,
                "lng": 76.5446
            },
            "S\u0101har": {
                "lat": 26.5408,
                "lng": 85.859
            },
            "Pochampalli": {
                "lat": 17.3473,
                "lng": 78.8195
            },
            "Beko": {
                "lat": 24.0415,
                "lng": 85.9536
            },
            "Turkaguda": {
                "lat": 17.153,
                "lng": 78.6195
            },
            "Damdama": {
                "lat": 24.4341,
                "lng": 87.7849
            },
            "Bhit Bhagw\u0101npur": {
                "lat": 26.116,
                "lng": 86.355
            },
            "K\u0101jha": {
                "lat": 25.7747,
                "lng": 87.3545
            },
            "Dhem\u0101ji": {
                "lat": 27.4833,
                "lng": 94.5833
            },
            "B\u0101lkonda": {
                "lat": 18.8667,
                "lng": 78.35
            },
            "R\u0101j\u0101pur": {
                "lat": 16.67,
                "lng": 73.52
            },
            "Puttalam": {
                "lat": 8.0981,
                "lng": 77.464
            },
            "Cheramkod": {
                "lat": 11.6,
                "lng": 76.3167
            },
            "Solapuram": {
                "lat": 9.3757,
                "lng": 77.5598
            },
            "Jur\u0101wanpur Kar\u0101ri": {
                "lat": 25.5273,
                "lng": 85.4182
            },
            "Dewangarh": {
                "lat": 24.8637,
                "lng": 85.8584
            },
            "Narendrapatnam": {
                "lat": 17.0964,
                "lng": 81.8812
            },
            "Kolakal\u016bru": {
                "lat": 16.3052,
                "lng": 80.6182
            },
            "Antarvedi": {
                "lat": 16.3333,
                "lng": 81.7333
            },
            "Chembagar\u0101manpud\u016br": {
                "lat": 8.2461,
                "lng": 77.4907
            },
            "Bilpura": {
                "lat": 23.2216,
                "lng": 79.9917
            },
            "Sundarpur": {
                "lat": 26.3037,
                "lng": 84.8581
            },
            "Bhagta": {
                "lat": 30.4882,
                "lng": 75.1013
            },
            "Bhagw\u0101ngola": {
                "lat": 24.3485,
                "lng": 88.3243
            },
            "Bayn\u0101la": {
                "lat": 22.4835,
                "lng": 88.4997
            },
            "Kadaiyam": {
                "lat": 8.832,
                "lng": 77.357
            },
            "Belo": {
                "lat": 25.8747,
                "lng": 86.8855
            },
            "Kompalle": {
                "lat": 17.4993,
                "lng": 78.4583
            },
            "Ernag\u016bdem": {
                "lat": 16.9989,
                "lng": 81.5061
            },
            "Vatl\u016bru": {
                "lat": 16.7009,
                "lng": 81.0525
            },
            "Edakkazhiy\u016br": {
                "lat": 10.6167,
                "lng": 75.9833
            },
            "Jasol": {
                "lat": 25.49,
                "lng": 72.13
            },
            "Chitt\u016br": {
                "lat": 11.647,
                "lng": 77.8239
            },
            "S\u0101ligr\u0101ma": {
                "lat": 12.5602,
                "lng": 76.2611
            },
            "Ponnamar\u0101vati": {
                "lat": 10.2803,
                "lng": 78.536
            },
            "Palh\u0101lan": {
                "lat": 34.1823,
                "lng": 74.5506
            },
            "Kukkund\u016br": {
                "lat": 13.2404,
                "lng": 74.9541
            },
            "S\u0101n\u0101tikri": {
                "lat": 22.023,
                "lng": 88.5033
            },
            "Kumaralingam": {
                "lat": 10.4894,
                "lng": 77.3499
            },
            "Palanisettipatti": {
                "lat": 9.9998,
                "lng": 77.4641
            },
            "Sir\u0101li": {
                "lat": 22.1519,
                "lng": 77.0617
            },
            "Meghauna": {
                "lat": 25.6904,
                "lng": 86.3809
            },
            "Pipra Lat\u012bf": {
                "lat": 25.3579,
                "lng": 86.7174
            },
            "Patil\u0101r": {
                "lat": 27.0361,
                "lng": 84.2106
            },
            "Kur\u0101war": {
                "lat": 23.5118,
                "lng": 77.0246
            },
            "Asth\u0101nw\u0101n": {
                "lat": 25.2215,
                "lng": 85.6262
            },
            "Vemulad\u012bvi": {
                "lat": 16.3408,
                "lng": 81.6958
            },
            "Maur\u0101nw\u0101n": {
                "lat": 26.43,
                "lng": 80.88
            },
            "Pichhor": {
                "lat": 25.9602,
                "lng": 78.3893
            },
            "Sarai Jatt\u0101n": {
                "lat": 31.1797,
                "lng": 75.6745
            },
            "Udala": {
                "lat": 21.5781,
                "lng": 86.5668
            },
            "Turh\u0101patti": {
                "lat": 26.8511,
                "lng": 84.4522
            },
            "Phulaut": {
                "lat": 25.5114,
                "lng": 86.9375
            },
            "Bilgi": {
                "lat": 16.3472,
                "lng": 75.6181
            },
            "Mitrapur": {
                "lat": 24.4371,
                "lng": 87.9666
            },
            "Chilamatt\u016bru": {
                "lat": 13.8394,
                "lng": 77.7039
            },
            "Kambad\u016bru": {
                "lat": 14.3575,
                "lng": 77.2186
            },
            "Arumb\u0101v\u016br": {
                "lat": 11.381,
                "lng": 78.7296
            },
            "Dil\u0101rpur": {
                "lat": 25.3968,
                "lng": 87.5969
            },
            "Nedumudi": {
                "lat": 9.4429,
                "lng": 76.404
            },
            "Odan\u0101vattam": {
                "lat": 8.9355,
                "lng": 76.7713
            },
            "Vipparla": {
                "lat": 16.3023,
                "lng": 79.9373
            },
            "Bugg\u0101nipalle": {
                "lat": 15.4741,
                "lng": 78.175
            },
            "Chokkan\u0101thapuram": {
                "lat": 9.9921,
                "lng": 77.3566
            },
            "Siladon": {
                "lat": 23.1527,
                "lng": 85.3657
            },
            "Sod\u0101g": {
                "lat": 23.1436,
                "lng": 85.3874
            },
            "L\u0101l Khatang\u0101": {
                "lat": 23.2627,
                "lng": 85.3376
            },
            "Khijri": {
                "lat": 22.8001,
                "lng": 84.9474
            },
            "Samsik\u0101puram": {
                "lat": 9.4156,
                "lng": 77.5917
            },
            "Raspur Patasia": {
                "lat": 25.544,
                "lng": 85.7247
            },
            "Gop\u0101lnagar": {
                "lat": 22.8289,
                "lng": 88.2139
            },
            "Kot Bh\u0101i": {
                "lat": 30.2678,
                "lng": 74.699
            },
            "Talain\u0101yar Agrah\u0101ram": {
                "lat": 10.5614,
                "lng": 79.7723
            },
            "Kumaramputt\u016br": {
                "lat": 10.9667,
                "lng": 76.4333
            },
            "Semra": {
                "lat": 26.6523,
                "lng": 85.0872
            },
            "Chh\u0101jli": {
                "lat": 30.0348,
                "lng": 75.828
            },
            "Isr\u0101in Kal\u0101n": {
                "lat": 25.9844,
                "lng": 87.0372
            },
            "Bas\u0101rh": {
                "lat": 25.9808,
                "lng": 85.1285
            },
            "Maddikera": {
                "lat": 15.25,
                "lng": 77.417
            },
            "Am\u0101nganj": {
                "lat": 24.4266,
                "lng": 80.0358
            },
            "Undi": {
                "lat": 16.6,
                "lng": 81.4667
            },
            "Pihra": {
                "lat": 24.6424,
                "lng": 85.8043
            },
            "Bankheri": {
                "lat": 22.7696,
                "lng": 78.5341
            },
            "Bh\u0101numukkala": {
                "lat": 15.3119,
                "lng": 78.2277
            },
            "Dudhgaon": {
                "lat": 18.2787,
                "lng": 76.0536
            },
            "Elandakuttai": {
                "lat": 11.3992,
                "lng": 77.7782
            },
            "Bel\u0101ri": {
                "lat": 25.9655,
                "lng": 86.9161
            },
            "T\u0113kkampatti": {
                "lat": 11.2559,
                "lng": 76.8872
            },
            "M\u0101tar": {
                "lat": 21.726,
                "lng": 73.0251
            },
            "Shedbal": {
                "lat": 16.6892,
                "lng": 74.7543
            },
            "K\u0101lik\u0101pur": {
                "lat": 26.4956,
                "lng": 86.089
            },
            "Peddaboddepalle": {
                "lat": 17.6606,
                "lng": 82.6296
            },
            "S\u0101hpur": {
                "lat": 25.7035,
                "lng": 85.1483
            },
            "Udayendram": {
                "lat": 12.6962,
                "lng": 78.6227
            },
            "Mangaw\u0101n": {
                "lat": 24.6675,
                "lng": 81.5464
            },
            "R\u0101tan": {
                "lat": 25.4349,
                "lng": 86.6432
            },
            "Marui": {
                "lat": 24.8639,
                "lng": 85.6338
            },
            "Pimpalgaon R\u0101j\u0101": {
                "lat": 20.7167,
                "lng": 76.4333
            },
            "Mulakaledu": {
                "lat": 14.3648,
                "lng": 76.9267
            },
            "Billap\u0101du": {
                "lat": 16.6364,
                "lng": 80.9661
            },
            "Mus\u0101pur": {
                "lat": 25.6821,
                "lng": 85.7346
            },
            "Aravakkurichchi": {
                "lat": 10.776,
                "lng": 77.9076
            },
            "Py\u0101pali": {
                "lat": 15.2669,
                "lng": 77.7611
            },
            "Jang\u012bd": {
                "lat": 18.4475,
                "lng": 79.8414
            },
            "Tondangi": {
                "lat": 17.25,
                "lng": 82.4667
            },
            "Hebli": {
                "lat": 15.4858,
                "lng": 75.1283
            },
            "Petl\u0101wad": {
                "lat": 23.0108,
                "lng": 74.7961
            },
            "Seven Pagodas": {
                "lat": 12.6197,
                "lng": 80.1944
            },
            "Salua": {
                "lat": 22.61,
                "lng": 88.27
            },
            "Cumbum": {
                "lat": 15.5667,
                "lng": 79.1167
            },
            "Puliy\u016br": {
                "lat": 10.9499,
                "lng": 78.1453
            },
            "Hath\u012baundha": {
                "lat": 25.7223,
                "lng": 86.9972
            },
            "Lakhaura": {
                "lat": 26.7522,
                "lng": 84.9647
            },
            "Gurramkonda": {
                "lat": 13.7833,
                "lng": 78.5833
            },
            "Mall\u0101puram": {
                "lat": 11.9823,
                "lng": 78.248
            },
            "Telua": {
                "lat": 26.6396,
                "lng": 84.5108
            },
            "Gang\u0101pur": {
                "lat": 25.8565,
                "lng": 86.9496
            },
            "Warni": {
                "lat": 18.5436,
                "lng": 77.9072
            },
            "Sr\u012bvardhan": {
                "lat": 18.0333,
                "lng": 73.0167
            },
            "Koduman": {
                "lat": 9.1917,
                "lng": 76.8
            },
            "Bhoj": {
                "lat": 16.5333,
                "lng": 74.45
            },
            "Itki Th\u0101kurgaon": {
                "lat": 23.3456,
                "lng": 85.1114
            },
            "Khaira": {
                "lat": 24.8727,
                "lng": 86.2089
            },
            "Chautham": {
                "lat": 25.5439,
                "lng": 86.6567
            },
            "Bilaua": {
                "lat": 26.0501,
                "lng": 78.2752
            },
            "Babhani Bholwa": {
                "lat": 26.0876,
                "lng": 86.7456
            },
            "Bhatranha": {
                "lat": 25.9846,
                "lng": 86.6348
            },
            "Changamkari": {
                "lat": 9.3667,
                "lng": 76.5333
            },
            "Putaparti": {
                "lat": 14.166,
                "lng": 77.811
            },
            "Tabhka Kh\u0101s": {
                "lat": 25.6518,
                "lng": 85.9426
            },
            "Jadia": {
                "lat": 26.0937,
                "lng": 86.9852
            },
            "Bina": {
                "lat": 26.0792,
                "lng": 86.6326
            },
            "Koh\u012br": {
                "lat": 17.6,
                "lng": 77.7167
            },
            "Hasanganj": {
                "lat": 26.78,
                "lng": 80.67
            },
            "Peddapalle": {
                "lat": 14.4046,
                "lng": 78.9456
            },
            "Devendranagar": {
                "lat": 24.6169,
                "lng": 80.3789
            },
            "Rajni": {
                "lat": 25.8132,
                "lng": 86.9772
            },
            "Ekma": {
                "lat": 26.0541,
                "lng": 86.6121
            },
            "Rev\u016br": {
                "lat": 16.8216,
                "lng": 79.9926
            },
            "Ochanthururtha": {
                "lat": 10.0,
                "lng": 76.2333
            },
            "Saty\u0101mangala": {
                "lat": 13.0193,
                "lng": 76.1243
            },
            "Udiy\u0101vara": {
                "lat": 13.3097,
                "lng": 74.7366
            },
            "Kutavett\u016br": {
                "lat": 8.9167,
                "lng": 76.75
            },
            "Aruvikkara": {
                "lat": 8.3331,
                "lng": 77.2788
            },
            "Kaintragarh": {
                "lat": 20.7211,
                "lng": 84.5351
            },
            "Kon\u0101rka": {
                "lat": 19.8878,
                "lng": 86.0948
            },
            "Shamsa": {
                "lat": 25.623,
                "lng": 85.931
            },
            "Barajor": {
                "lat": 24.7992,
                "lng": 86.3731
            },
            "Pavannur": {
                "lat": 11.95,
                "lng": 75.5
            },
            "Tillor Khurd": {
                "lat": 22.617,
                "lng": 75.948
            },
            "Chettikulam": {
                "lat": 8.0943,
                "lng": 77.5614
            },
            "Aivanallur": {
                "lat": 11.2161,
                "lng": 79.5392
            },
            "Shafinagar": {
                "lat": 28.4117,
                "lng": 78.1365
            },
            "Damalcheruvu": {
                "lat": 13.4833,
                "lng": 79.05
            },
            "Cortalim": {
                "lat": 15.3978,
                "lng": 73.9106
            },
            "Majhgaw\u0101n": {
                "lat": 23.4039,
                "lng": 80.2098
            },
            "Hombal": {
                "lat": 15.5164,
                "lng": 75.5556
            },
            "Bellatti": {
                "lat": 12.65,
                "lng": 78.0
            },
            "Singh\u0101nw\u0101la": {
                "lat": 30.7699,
                "lng": 75.1218
            },
            "Hullahalli": {
                "lat": 12.1,
                "lng": 76.55
            },
            "Muttamtura": {
                "lat": 8.13,
                "lng": 77.32
            },
            "Sathamba": {
                "lat": 23.1691,
                "lng": 73.3266
            },
            "Valatt\u016br": {
                "lat": 12.8899,
                "lng": 78.8394
            },
            "Nedumpura": {
                "lat": 10.3903,
                "lng": 76.1514
            },
            "Turori": {
                "lat": 17.8278,
                "lng": 76.6903
            },
            "Kh\u0101ndh\u0101r": {
                "lat": 26.0233,
                "lng": 76.6097
            },
            "Shirud": {
                "lat": 20.7167,
                "lng": 74.9
            },
            "Galatge": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Vasa": {
                "lat": 22.66,
                "lng": 72.75
            },
            "Kaguchi": {
                "lat": 11.4519,
                "lng": 76.8061
            },
            "Yelur": {
                "lat": 15.7817,
                "lng": 74.5178
            },
            "M\u0101ndleshwar": {
                "lat": 22.176,
                "lng": 75.6599
            },
            "\u0100ndip\u0101laiyam": {
                "lat": 11.092,
                "lng": 77.315
            },
            "Goluw\u0101li": {
                "lat": 29.63,
                "lng": 74.06
            },
            "\u0100dra": {
                "lat": 23.5,
                "lng": 86.67
            },
            "Vall\u016br": {
                "lat": 13.256,
                "lng": 80.2818
            },
            "Majholi": {
                "lat": 23.5011,
                "lng": 79.924
            },
            "Ganj\u0101m": {
                "lat": 19.387,
                "lng": 85.0508
            },
            "Ch\u0101ndi": {
                "lat": 25.7296,
                "lng": 87.5195
            },
            "Chemmanam": {
                "lat": 9.8072,
                "lng": 76.2774
            },
            "Yell\u0101reddi": {
                "lat": 18.3746,
                "lng": 78.6543
            },
            "L\u0101nj\u012b": {
                "lat": 21.5018,
                "lng": 80.5406
            },
            "Sembedu": {
                "lat": 13.1298,
                "lng": 79.5634
            },
            "Lakkundi": {
                "lat": 15.3897,
                "lng": 75.7183
            },
            "Pursa": {
                "lat": 26.3019,
                "lng": 86.4778
            },
            "Kunnum\u0113l": {
                "lat": 9.35,
                "lng": 76.4
            },
            "Badarpur": {
                "lat": 24.8685,
                "lng": 92.5961
            },
            "Kela Khera": {
                "lat": 29.09,
                "lng": 79.19
            },
            "Amm\u016br": {
                "lat": 12.975,
                "lng": 79.3628
            },
            "Sindhn\u016br": {
                "lat": 15.77,
                "lng": 76.7556
            },
            "Aiyampuzha": {
                "lat": 10.25,
                "lng": 76.4667
            },
            "Valangim\u0101n": {
                "lat": 10.8897,
                "lng": 79.394
            },
            "Jhand\u0101pur": {
                "lat": 25.3995,
                "lng": 86.9479
            },
            "Parapatti": {
                "lat": 12.1083,
                "lng": 78.0198
            },
            "Champ\u0101pur": {
                "lat": 26.8881,
                "lng": 84.834
            },
            "\u0100lampur": {
                "lat": 24.8721,
                "lng": 83.8829
            },
            "Hanams\u0101gar": {
                "lat": 15.8722,
                "lng": 76.0431
            },
            "Vell\u0101l\u0101puram": {
                "lat": 11.6525,
                "lng": 77.938
            },
            "Barharw\u0101": {
                "lat": 24.8571,
                "lng": 87.779
            },
            "Ch\u0101il\u0101ha": {
                "lat": 26.6738,
                "lng": 84.8801
            },
            "Nagar\u016br": {
                "lat": 8.7333,
                "lng": 76.85
            },
            "Ch\u012bpurupalle": {
                "lat": 18.3,
                "lng": 83.5667
            },
            "Madhuban": {
                "lat": 25.8838,
                "lng": 86.8176
            },
            "Avalp\u016bndurai": {
                "lat": 11.231,
                "lng": 77.7182
            },
            "Hariharp\u0101ra": {
                "lat": 24.0468,
                "lng": 88.4244
            },
            "Kum\u0101ramangalam": {
                "lat": 11.3676,
                "lng": 77.93
            },
            "Tend\u016bkheda": {
                "lat": 23.1708,
                "lng": 78.8724
            },
            "Cherrapunji": {
                "lat": 25.284,
                "lng": 91.721
            },
            "Kum\u0101r\u012bpur": {
                "lat": 25.4425,
                "lng": 87.6217
            },
            "Nenmini": {
                "lat": 11.0528,
                "lng": 76.2083
            },
            "Benipati": {
                "lat": 26.4442,
                "lng": 85.9083
            },
            "Devarapalle": {
                "lat": 17.03,
                "lng": 81.37
            },
            "P\u0101lod": {
                "lat": 8.7033,
                "lng": 77.0264
            },
            "Sanquelim": {
                "lat": 15.5027,
                "lng": 73.7669
            },
            "Kamr\u0101w\u0101n": {
                "lat": 25.6956,
                "lng": 85.838
            },
            "Curtorim": {
                "lat": 15.28,
                "lng": 74.03
            },
            "Hasanpur Juned": {
                "lat": 25.5915,
                "lng": 85.5238
            },
            "Cherupazhasshi": {
                "lat": 12.0916,
                "lng": 75.4414
            },
            "Perunkolatt\u016br": {
                "lat": 12.043,
                "lng": 78.9174
            },
            "Ijra": {
                "lat": 26.3119,
                "lng": 86.0037
            },
            "Garhpura": {
                "lat": 25.6638,
                "lng": 86.1818
            },
            "Vadd\u0101palli": {
                "lat": 18.5323,
                "lng": 77.8959
            },
            "And\u016brkonam": {
                "lat": 8.5969,
                "lng": 76.8664
            },
            "B\u0101ladharm\u0101ram": {
                "lat": 17.4104,
                "lng": 79.969
            },
            "S\u0101hit": {
                "lat": 25.5888,
                "lng": 85.7948
            },
            "Chh\u0101pia": {
                "lat": 26.0831,
                "lng": 84.8447
            },
            "Tirumalaiyamp\u0101laiyam": {
                "lat": 10.879,
                "lng": 76.9295
            },
            "K\u014dteshwar": {
                "lat": 13.607,
                "lng": 74.75
            },
            "Kod\u0101ngipatti": {
                "lat": 9.992,
                "lng": 77.4332
            },
            "Kurumbal\u016br": {
                "lat": 11.236,
                "lng": 78.8005
            },
            "Paramati": {
                "lat": 11.1544,
                "lng": 78.0213
            },
            "M\u0101nantheri": {
                "lat": 11.8428,
                "lng": 75.615
            },
            "Pudur": {
                "lat": 9.0001,
                "lng": 77.2076
            },
            "Kamalganj": {
                "lat": 27.27,
                "lng": 79.65
            },
            "Rasulpur Dhuria": {
                "lat": 25.5693,
                "lng": 87.0375
            },
            "Mulak\u0101d": {
                "lat": 9.8902,
                "lng": 76.6546
            },
            "Kaonke": {
                "lat": 30.7659,
                "lng": 75.4172
            },
            "Vubatalai": {
                "lat": 11.3597,
                "lng": 76.7649
            },
            "Tiruv\u0101saladi": {
                "lat": 11.4013,
                "lng": 79.7746
            },
            "Alang\u0101nall\u016br": {
                "lat": 10.047,
                "lng": 78.0903
            },
            "Kankip\u0101du": {
                "lat": 16.45,
                "lng": 80.7833
            },
            "Vemul\u016bru": {
                "lat": 16.9337,
                "lng": 81.8057
            },
            "Paliaturutu": {
                "lat": 10.2959,
                "lng": 76.1581
            },
            "Sinha": {
                "lat": 25.6902,
                "lng": 84.6291
            },
            "Holalagondi": {
                "lat": 15.4873,
                "lng": 77.0464
            },
            "S\u0101ty\u016bn": {
                "lat": 28.5667,
                "lng": 75.1167
            },
            "Kelamangalam": {
                "lat": 12.6031,
                "lng": 77.8519
            },
            "Dinm\u0101npur": {
                "lat": 25.9112,
                "lng": 85.9288
            },
            "Thillang\u00e9ri": {
                "lat": 11.95,
                "lng": 75.6167
            },
            "Bal\u012bgaon": {
                "lat": 25.4196,
                "lng": 84.4951
            },
            "Talya": {
                "lat": 14.042,
                "lng": 76.184
            },
            "K\u0101njikkovil": {
                "lat": 11.3689,
                "lng": 77.5962
            },
            "Jitw\u0101rpur Niz\u0101mat": {
                "lat": 25.8489,
                "lng": 85.8006
            },
            "Lakkampatti": {
                "lat": 11.4461,
                "lng": 77.4118
            },
            "Palayad": {
                "lat": 11.5847,
                "lng": 75.7528
            },
            "Akora": {
                "lat": 26.4721,
                "lng": 78.7989
            },
            "Paniem": {
                "lat": 15.5167,
                "lng": 78.35
            },
            "Kottacheruvu": {
                "lat": 14.1886,
                "lng": 77.7658
            },
            "Darpa": {
                "lat": 26.84,
                "lng": 84.9774
            },
            "Tirupp\u0101ch\u016br": {
                "lat": 13.1384,
                "lng": 79.8764
            },
            "Relangi": {
                "lat": 16.705,
                "lng": 81.647
            },
            "Bijaipur": {
                "lat": 26.0556,
                "lng": 77.3697
            },
            "Halw\u0101ra": {
                "lat": 30.7167,
                "lng": 75.65
            },
            "Gundlapelle": {
                "lat": 16.4102,
                "lng": 79.8813
            },
            "N\u0101gireddipalli": {
                "lat": 14.2701,
                "lng": 79.1013
            },
            "Kus\u0101ha": {
                "lat": 26.2077,
                "lng": 86.9587
            },
            "Kannaman\u0101yakkan\u016br": {
                "lat": 10.5527,
                "lng": 77.269
            },
            "Pandh\u0101na": {
                "lat": 21.6982,
                "lng": 76.2249
            },
            "Pakary\u0101 Harsidhi": {
                "lat": 26.6526,
                "lng": 84.7198
            },
            "Suw\u0101sra": {
                "lat": 24.0698,
                "lng": 75.6523
            },
            "Nadis\u0101l": {
                "lat": 13.1326,
                "lng": 74.7777
            },
            "Gaviniv\u0101rip\u0101lem": {
                "lat": 15.8378,
                "lng": 80.4315
            },
            "Surajpura": {
                "lat": 25.2576,
                "lng": 86.2283
            },
            "Sadhoa": {
                "lat": 25.4049,
                "lng": 87.198
            },
            "Ling\u0101l": {
                "lat": 16.2833,
                "lng": 78.5167
            },
            "Chinnav\u0101dampatti": {
                "lat": 11.0615,
                "lng": 76.9838
            },
            "L\u0101kho": {
                "lat": 25.4113,
                "lng": 86.1792
            },
            "Barhni": {
                "lat": 27.4972,
                "lng": 82.7861
            },
            "Harduli": {
                "lat": 22.9278,
                "lng": 79.903
            },
            "Babhang\u0101w\u0101n": {
                "lat": 25.3674,
                "lng": 86.9501
            },
            "Nawada": {
                "lat": 25.5431,
                "lng": 85.924
            },
            "P\u0101kala": {
                "lat": 15.2694,
                "lng": 80.072
            },
            "Markacho": {
                "lat": 24.3263,
                "lng": 85.8346
            },
            "Roh": {
                "lat": 24.8905,
                "lng": 85.675
            },
            "Khallikot": {
                "lat": 19.6091,
                "lng": 85.0861
            },
            "Tiruvalanjuli": {
                "lat": 10.9449,
                "lng": 79.3286
            },
            "Lagunia Surajkanth": {
                "lat": 25.8304,
                "lng": 85.7887
            },
            "Tarlapalli": {
                "lat": 18.8345,
                "lng": 79.4907
            },
            "Shatr\u0101na": {
                "lat": 29.9102,
                "lng": 76.1202
            },
            "Narahia": {
                "lat": 26.3681,
                "lng": 86.5434
            },
            "B\u0101ra": {
                "lat": 25.9286,
                "lng": 86.5237
            },
            "Sirmaur": {
                "lat": 24.8365,
                "lng": 81.3645
            },
            "Kolw\u0101ra": {
                "lat": 25.3538,
                "lng": 86.77
            },
            "Baqi\u0101b\u0101d": {
                "lat": 25.1,
                "lng": 82.9
            },
            "Satw\u0101s": {
                "lat": 22.5363,
                "lng": 76.6845
            },
            "Davorlim": {
                "lat": 15.2722,
                "lng": 73.9924
            },
            "Al\u016br": {
                "lat": 15.3942,
                "lng": 77.2264
            },
            "Dornakal": {
                "lat": 17.447,
                "lng": 80.151
            },
            "Tirkar\u016br": {
                "lat": 10.0667,
                "lng": 76.6167
            },
            "Kharki": {
                "lat": 23.9165,
                "lng": 85.8596
            },
            "Barhi": {
                "lat": 26.4629,
                "lng": 86.2771
            },
            "Vadugapatti": {
                "lat": 11.1518,
                "lng": 77.7375
            },
            "Chik B\u0101navar": {
                "lat": 13.0846,
                "lng": 77.5014
            },
            "Paidiipalli": {
                "lat": 18.0172,
                "lng": 79.6159
            },
            "Muty\u0101lapalle": {
                "lat": 16.4019,
                "lng": 81.5703
            },
            "Nagra": {
                "lat": 25.968,
                "lng": 83.873
            },
            "Hald\u012bb\u0101ri": {
                "lat": 26.33,
                "lng": 88.77
            },
            "T\u016bpr\u0101n": {
                "lat": 17.8452,
                "lng": 78.4787
            },
            "Harg\u0101w\u0101n": {
                "lat": 25.1428,
                "lng": 85.5652
            },
            "Mohanpur Gaugh\u0101ta": {
                "lat": 25.3345,
                "lng": 87.3404
            },
            "K\u0101remp\u016bdi": {
                "lat": 16.4333,
                "lng": 79.7167
            },
            "V\u0101sad": {
                "lat": 22.45,
                "lng": 73.0667
            },
            "Puraini": {
                "lat": 26.0607,
                "lng": 87.0331
            },
            "Kamatgi": {
                "lat": 16.119,
                "lng": 75.8482
            },
            "Tezu": {
                "lat": 27.92,
                "lng": 96.17
            },
            "Doria Son\u0101pur": {
                "lat": 26.183,
                "lng": 87.3873
            },
            "Penuganchiprolu": {
                "lat": 16.9033,
                "lng": 80.2356
            },
            "Sail\u0101na": {
                "lat": 23.4622,
                "lng": 74.9232
            },
            "Pati\u0101li": {
                "lat": 27.7167,
                "lng": 79.0167
            },
            "Barela": {
                "lat": 23.0968,
                "lng": 80.0508
            },
            "Thondiamannu": {
                "lat": 9.2639,
                "lng": 76.8833
            },
            "Baulia": {
                "lat": 25.399,
                "lng": 87.6002
            },
            "Bihpuri\u0101gaon": {
                "lat": 27.0172,
                "lng": 93.9167
            },
            "Narhan": {
                "lat": 25.6941,
                "lng": 86.0074
            },
            "Bh\u0101nukum\u0101ri": {
                "lat": 26.3395,
                "lng": 89.7697
            },
            "Hindalgi": {
                "lat": 16.1364,
                "lng": 74.6547
            },
            "Muttuk\u016bru": {
                "lat": 14.2667,
                "lng": 80.1
            },
            "Lakhsetipet": {
                "lat": 18.8873,
                "lng": 79.2069
            },
            "P\u0101lda": {
                "lat": 22.68,
                "lng": 75.8939
            },
            "W\u0101l\u0101j\u0101b\u0101d": {
                "lat": 12.7904,
                "lng": 79.8236
            },
            "Gua": {
                "lat": 22.2136,
                "lng": 85.3877
            },
            "Jori Kal\u0101n": {
                "lat": 24.2045,
                "lng": 84.877
            },
            "Pol\u0101ia Kal\u0101n": {
                "lat": 23.2119,
                "lng": 76.5529
            },
            "Chikhli Kal\u0101n": {
                "lat": 22.2152,
                "lng": 78.743
            },
            "Kottavalasa": {
                "lat": 17.9,
                "lng": 83.2
            },
            "Kizhariy\u016br": {
                "lat": 11.4833,
                "lng": 75.6833
            },
            "K\u0101rk\u016bdalpatti": {
                "lat": 11.5002,
                "lng": 78.3521
            },
            "V\u012braghattam": {
                "lat": 18.6833,
                "lng": 83.6
            },
            "M\u0101ngoli": {
                "lat": 16.65,
                "lng": 75.81
            },
            "Huliy\u0101r": {
                "lat": 13.5828,
                "lng": 76.5361
            },
            "Kambainell\u016br": {
                "lat": 12.2071,
                "lng": 78.3187
            },
            "Korangal": {
                "lat": 17.107,
                "lng": 77.627
            },
            "Anantar\u0101zupeta": {
                "lat": 14.0014,
                "lng": 79.3319
            },
            "Bariariy\u0101": {
                "lat": 26.5105,
                "lng": 84.7165
            },
            "Ungut\u016bru": {
                "lat": 16.823,
                "lng": 81.4238
            },
            "Bikkavolu": {
                "lat": 16.95,
                "lng": 82.05
            },
            "Ghaura": {
                "lat": 24.5059,
                "lng": 79.0781
            },
            "Vedasand\u016br": {
                "lat": 10.531,
                "lng": 77.9502
            },
            "Manoharpur": {
                "lat": 22.3746,
                "lng": 85.1923
            },
            "Chikitigarh": {
                "lat": 19.2023,
                "lng": 84.6145
            },
            "R\u0101je": {
                "lat": 26.2216,
                "lng": 86.1454
            },
            "Kukdeshwar": {
                "lat": 24.4828,
                "lng": 75.2675
            },
            "K\u014ddan\u0101d": {
                "lat": 10.18,
                "lng": 76.51
            },
            "Padav\u0113du": {
                "lat": 12.673,
                "lng": 79.1047
            },
            "Saren": {
                "lat": 25.1149,
                "lng": 84.9744
            },
            "K\u0101shti": {
                "lat": 18.5492,
                "lng": 74.5822
            },
            "Pannimadai": {
                "lat": 11.0823,
                "lng": 76.9164
            },
            "Nagardevla Budrukh": {
                "lat": 20.5667,
                "lng": 75.2167
            },
            "Terkuveng\u0101nall\u016br": {
                "lat": 9.4052,
                "lng": 77.5408
            },
            "Barkuhi": {
                "lat": 22.1901,
                "lng": 78.7047
            },
            "Chebrolu": {
                "lat": 16.1967,
                "lng": 80.525
            },
            "Sidd\u0101pur": {
                "lat": 14.347,
                "lng": 74.894
            },
            "\u0100langudi": {
                "lat": 10.3604,
                "lng": 78.9779
            },
            "P\u0101nr": {
                "lat": 25.6884,
                "lng": 85.798
            },
            "Odl\u0101b\u0101ri": {
                "lat": 26.8594,
                "lng": 88.6214
            },
            "Tamarakulam": {
                "lat": 8.1325,
                "lng": 77.4848
            },
            "P\u0101nsem\u0101l": {
                "lat": 21.6598,
                "lng": 74.6994
            },
            "Nallagunta": {
                "lat": 16.5763,
                "lng": 80.6854
            },
            "T\u0101lbahat": {
                "lat": 25.042,
                "lng": 78.433
            },
            "Pirangut": {
                "lat": 18.5115,
                "lng": 73.6801
            },
            "Bhulw\u0101l": {
                "lat": 32.8094,
                "lng": 74.7683
            },
            "Orumanay\u016br": {
                "lat": 10.5583,
                "lng": 76.0347
            },
            "Raghudebbati": {
                "lat": 22.53,
                "lng": 88.2
            },
            "K\u0101tr\u0101vulapalle": {
                "lat": 17.1277,
                "lng": 82.0831
            },
            "Antarvedip\u0101lem": {
                "lat": 16.3319,
                "lng": 81.732
            },
            "Jitw\u0101rpur Kumhra": {
                "lat": 25.7852,
                "lng": 85.7044
            },
            "Pul\u016br": {
                "lat": 18.1,
                "lng": 78.85
            },
            "Kutav\u016br": {
                "lat": 8.7833,
                "lng": 76.8167
            },
            "Rambha": {
                "lat": 19.4433,
                "lng": 84.9881
            },
            "Sur Singh": {
                "lat": 31.3992,
                "lng": 74.716
            },
            "K\u012blattingal": {
                "lat": 8.6891,
                "lng": 76.7877
            },
            "Ch\u0101pr\u0101": {
                "lat": 23.5391,
                "lng": 88.5467
            },
            "Siroda": {
                "lat": 15.3292,
                "lng": 74.0271
            },
            "Phulw\u0101r": {
                "lat": 26.8148,
                "lng": 84.843
            },
            "Gud\u016br": {
                "lat": 17.7956,
                "lng": 79.9747
            },
            "Eraur\u0101": {
                "lat": 24.613,
                "lng": 84.4555
            },
            "Doranahalli": {
                "lat": 16.7324,
                "lng": 76.9162
            },
            "By\u0101hatti": {
                "lat": 15.4468,
                "lng": 75.2054
            },
            "Bela": {
                "lat": 24.9689,
                "lng": 84.974
            },
            "R\u0101jim": {
                "lat": 20.965,
                "lng": 81.8817
            },
            "Tam\u0101r": {
                "lat": 23.0488,
                "lng": 85.649
            },
            "Ponnampatti": {
                "lat": 10.55,
                "lng": 78.3744
            },
            "Sumbal": {
                "lat": 34.2307,
                "lng": 74.6472
            },
            "Vammanal": {
                "lat": 12.0281,
                "lng": 75.7093
            },
            "S\u0101san": {
                "lat": 25.6883,
                "lng": 86.1907
            },
            "Karugam\u0101d": {
                "lat": 10.4667,
                "lng": 76.1
            },
            "Kottaiy\u016br": {
                "lat": 10.1096,
                "lng": 78.7956
            },
            "Am\u0101ri": {
                "lat": 25.7179,
                "lng": 86.0839
            },
            "Suknad\u0101nga": {
                "lat": 24.46,
                "lng": 88.05
            },
            "K\u0101rai": {
                "lat": 12.9377,
                "lng": 79.3163
            },
            "\u016attukkottai": {
                "lat": 13.3354,
                "lng": 79.898
            },
            "Bar\u0101ri": {
                "lat": 25.5068,
                "lng": 87.3761
            },
            "Barjora": {
                "lat": 23.4333,
                "lng": 87.2833
            },
            "Ilampillai": {
                "lat": 11.6066,
                "lng": 78.0068
            },
            "Ke\u0101l": {
                "lat": 25.1218,
                "lng": 84.6324
            },
            "Turuttiyad": {
                "lat": 11.5167,
                "lng": 75.65
            },
            "R\u0101n\u0101pur": {
                "lat": 22.647,
                "lng": 74.5212
            },
            "Alewah": {
                "lat": 29.4767,
                "lng": 76.4522
            },
            "R\u0101j\u0101s\u016br": {
                "lat": 17.86,
                "lng": 76.95
            },
            "Brahm\u0101nandapuram": {
                "lat": 16.9583,
                "lng": 82.0056
            },
            "Ann\u0101ram": {
                "lat": 16.784,
                "lng": 79.302
            },
            "Kongarapalli": {
                "lat": 10.0448,
                "lng": 76.3552
            },
            "Ganguv\u0101rpatti": {
                "lat": 10.1694,
                "lng": 77.6973
            },
            "Sutih\u0101r": {
                "lat": 25.8482,
                "lng": 84.9712
            },
            "Boha": {
                "lat": 29.8348,
                "lng": 75.5237
            },
            "Mankal": {
                "lat": 17.2014,
                "lng": 78.4678
            },
            "Chaug\u0101in": {
                "lat": 25.4801,
                "lng": 84.2328
            },
            "Belw\u0101ra": {
                "lat": 25.7443,
                "lng": 86.4755
            },
            "Am\u0101ha": {
                "lat": 26.1284,
                "lng": 86.7998
            },
            "Belsandi T\u0101ra": {
                "lat": 25.7722,
                "lng": 85.9608
            },
            "Perd\u016br": {
                "lat": 13.3844,
                "lng": 74.9029
            },
            "Attibele": {
                "lat": 12.7781,
                "lng": 77.7726
            },
            "Hunasagi": {
                "lat": 16.4575,
                "lng": 76.5239
            },
            "Sukma": {
                "lat": 18.4,
                "lng": 81.6667
            },
            "V\u012bragan\u016br": {
                "lat": 11.4761,
                "lng": 78.7355
            },
            "Muttun\u0101yakkanpatti": {
                "lat": 11.7125,
                "lng": 78.033
            },
            "Kherg\u0101m": {
                "lat": 20.63,
                "lng": 73.09
            },
            "Kelang\u0101h": {
                "lat": 28.87,
                "lng": 76.3285
            },
            "Kub\u0101dupuram": {
                "lat": 16.468,
                "lng": 79.9539
            },
            "Krishnar\u0101yapuram": {
                "lat": 10.9563,
                "lng": 78.2782
            },
            "Bhogpur": {
                "lat": 31.55,
                "lng": 75.63
            },
            "J\u012bran": {
                "lat": 24.3087,
                "lng": 74.8909
            },
            "Polukallu": {
                "lat": 15.8284,
                "lng": 77.7431
            },
            "Tumbipp\u0101di": {
                "lat": 11.808,
                "lng": 78.0783
            },
            "T\u0101ti": {
                "lat": 23.3772,
                "lng": 85.4379
            },
            "Linganaboyinacherla": {
                "lat": 16.3961,
                "lng": 81.6598
            },
            "Kishanpur Ratw\u0101ra": {
                "lat": 25.4822,
                "lng": 86.8511
            },
            "Polavaram": {
                "lat": 17.2479,
                "lng": 81.6432
            },
            "Tarauna": {
                "lat": 26.2371,
                "lng": 87.5123
            },
            "Iskapalli": {
                "lat": 14.7363,
                "lng": 80.0981
            },
            "Parih\u0101ra": {
                "lat": 25.5378,
                "lng": 86.2728
            },
            "Dog\u0101chi": {
                "lat": 24.6195,
                "lng": 87.9221
            },
            "M\u0101ndalgarh": {
                "lat": 25.2,
                "lng": 75.1
            },
            "K\u0101uriya": {
                "lat": 26.1517,
                "lng": 84.6948
            },
            "Gangaikond\u0101n": {
                "lat": 11.5389,
                "lng": 79.4765
            },
            "Valayam": {
                "lat": 11.7214,
                "lng": 75.6697
            },
            "Haidarnagar": {
                "lat": 24.4883,
                "lng": 83.9443
            },
            "Vellodu": {
                "lat": 10.3048,
                "lng": 77.9552
            },
            "Vemb\u0101rpatti": {
                "lat": 10.25,
                "lng": 78.1167
            },
            "Khajawa": {
                "lat": 24.9174,
                "lng": 79.9269
            },
            "Irikk\u016br": {
                "lat": 11.97,
                "lng": 75.57
            },
            "Colangute": {
                "lat": 15.5417,
                "lng": 73.7619
            },
            "Turuvan\u016br": {
                "lat": 14.22,
                "lng": 76.4
            },
            "Naw\u0101garh": {
                "lat": 31.1899,
                "lng": 77.6261
            },
            "Gangavalli": {
                "lat": 11.4983,
                "lng": 78.6461
            },
            "Bideipur": {
                "lat": 21.0147,
                "lng": 86.8195
            },
            "Tibri": {
                "lat": 31.9854,
                "lng": 75.4316
            },
            "Mad\u0101npur": {
                "lat": 25.867,
                "lng": 86.7875
            },
            "Arugollu": {
                "lat": 16.8253,
                "lng": 81.5775
            },
            "Kodigenahalli": {
                "lat": 13.7214,
                "lng": 77.3863
            },
            "Singal\u0101nd\u0101puram": {
                "lat": 11.4166,
                "lng": 78.2224
            },
            "Amr\u0101b\u0101d": {
                "lat": 16.3833,
                "lng": 78.8333
            },
            "Chity\u0101l": {
                "lat": 17.2333,
                "lng": 79.1333
            },
            "Kanniv\u0101di": {
                "lat": 10.3794,
                "lng": 77.8303
            },
            "Manav\u0101lakurichi": {
                "lat": 8.1478,
                "lng": 77.3055
            },
            "Bargh\u0101t": {
                "lat": 22.0306,
                "lng": 79.7328
            },
            "Badarw\u0101s": {
                "lat": 24.9752,
                "lng": 77.5649
            },
            "Gop\u0101lpur": {
                "lat": 19.2586,
                "lng": 84.9052
            },
            "Kudachi": {
                "lat": 16.48,
                "lng": 74.78
            },
            "Palk\u016br": {
                "lat": 15.4144,
                "lng": 78.2507
            },
            "Bodagudip\u0101du": {
                "lat": 14.7385,
                "lng": 79.8602
            },
            "Darihat": {
                "lat": 24.9702,
                "lng": 84.2319
            },
            "Malepur": {
                "lat": 24.974,
                "lng": 86.2555
            },
            "Sobr\u0101on": {
                "lat": 31.1833,
                "lng": 74.85
            },
            "T\u0101maraikkulam": {
                "lat": 10.1085,
                "lng": 77.5553
            },
            "Sher Chakla": {
                "lat": 25.3848,
                "lng": 86.6952
            },
            "Kovil\u016br": {
                "lat": 12.5537,
                "lng": 78.9168
            },
            "Kaley\u0101npur": {
                "lat": 26.4297,
                "lng": 84.9327
            },
            "Mohda": {
                "lat": 21.2751,
                "lng": 81.6676
            },
            "Tiruppanand\u0101l": {
                "lat": 11.0919,
                "lng": 79.4538
            },
            "Chak Husaini": {
                "lat": 25.5169,
                "lng": 86.5566
            },
            "Karma": {
                "lat": 23.684,
                "lng": 85.5706
            },
            "Kannadiparamba": {
                "lat": 11.935,
                "lng": 75.403
            },
            "Kal\u0101dgi": {
                "lat": 16.204,
                "lng": 75.5
            },
            "Bh\u012bmadolu": {
                "lat": 16.8144,
                "lng": 81.2617
            },
            "Is\u0101garh": {
                "lat": 24.8391,
                "lng": 77.8827
            },
            "Barahari": {
                "lat": 25.7652,
                "lng": 87.0937
            },
            "Ajjampur": {
                "lat": 13.7279,
                "lng": 76.0068
            },
            "Bilaspur": {
                "lat": 31.33,
                "lng": 76.75
            },
            "Aval\u0113palli": {
                "lat": 12.7714,
                "lng": 77.8594
            },
            "Domkonda": {
                "lat": 18.2561,
                "lng": 78.4377
            },
            "Kunjatturu": {
                "lat": 12.7431,
                "lng": 74.8861
            },
            "Dantew\u0101ra": {
                "lat": 18.9,
                "lng": 81.339
            },
            "Pitlam": {
                "lat": 18.2227,
                "lng": 77.8238
            },
            "Balh\u0101pur": {
                "lat": 25.3511,
                "lng": 86.2122
            },
            "Ottaikk\u0101lmantapam": {
                "lat": 10.8827,
                "lng": 76.9943
            },
            "Mahuwa Singhrai": {
                "lat": 25.8168,
                "lng": 85.3853
            },
            "Babhanganwa": {
                "lat": 26.1398,
                "lng": 86.8464
            },
            "Karpi": {
                "lat": 25.1612,
                "lng": 84.7299
            },
            "Chengara": {
                "lat": 10.129,
                "lng": 76.373
            },
            "Atner": {
                "lat": 21.6238,
                "lng": 77.9152
            },
            "Mohania": {
                "lat": 25.1692,
                "lng": 83.6192
            },
            "Nighoj": {
                "lat": 18.9597,
                "lng": 74.2769
            },
            "R\u0101yapalle": {
                "lat": 16.2828,
                "lng": 81.2504
            },
            "R\u0101zole": {
                "lat": 16.4743,
                "lng": 81.8402
            },
            "Sisai": {
                "lat": 23.1794,
                "lng": 84.7582
            },
            "Duvva": {
                "lat": 16.7792,
                "lng": 81.6242
            },
            "Am\u012bngarh": {
                "lat": 16.0572,
                "lng": 75.9489
            },
            "Gondalga": {
                "lat": 17.8053,
                "lng": 79.8944
            },
            "Pat\u0101lia": {
                "lat": 25.66,
                "lng": 86.0066
            },
            "Dattapulia": {
                "lat": 23.24,
                "lng": 88.7058
            },
            "Dh\u0101na": {
                "lat": 23.747,
                "lng": 78.8623
            },
            "N\u0101latv\u0101d": {
                "lat": 16.34,
                "lng": 76.14
            },
            "L\u0101la": {
                "lat": 24.5542,
                "lng": 92.6159
            },
            "Kurtkoti": {
                "lat": 15.3681,
                "lng": 75.5239
            },
            "Bishenpur": {
                "lat": 24.6282,
                "lng": 93.761
            },
            "Rahui": {
                "lat": 25.2728,
                "lng": 85.5555
            },
            "Karn\u0101wad": {
                "lat": 22.7361,
                "lng": 76.2439
            },
            "Rukh\u0101e": {
                "lat": 25.3269,
                "lng": 85.3725
            },
            "Kheri Naru": {
                "lat": 29.6479,
                "lng": 76.911
            },
            "Beur": {
                "lat": 25.569,
                "lng": 85.0944
            },
            "J\u0101nap\u0101du": {
                "lat": 16.4617,
                "lng": 79.8699
            },
            "Borgaon": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Kodriva": {
                "lat": 22.5342,
                "lng": 75.7725
            },
            "Avidha": {
                "lat": 21.6651,
                "lng": 73.0366
            },
            "Perungala": {
                "lat": 9.1764,
                "lng": 76.5278
            },
            "Siri\u0101ri": {
                "lat": 25.6733,
                "lng": 73.8084
            },
            "Karuvell\u016br": {
                "lat": 12.1733,
                "lng": 75.2097
            },
            "Sikat": {
                "lat": 25.5186,
                "lng": 87.4423
            },
            "Chandla": {
                "lat": 25.0715,
                "lng": 80.1929
            },
            "Marud\u016br": {
                "lat": 11.2346,
                "lng": 76.9064
            },
            "Gudikallu": {
                "lat": 15.7441,
                "lng": 77.476
            },
            "V\u0101ghodia": {
                "lat": 22.3,
                "lng": 73.38
            },
            "Afzala": {
                "lat": 25.9319,
                "lng": 86.2293
            },
            "Madakkathara": {
                "lat": 10.55,
                "lng": 76.2583
            },
            "Jamira": {
                "lat": 25.5535,
                "lng": 84.7063
            },
            "Elanad": {
                "lat": 10.6167,
                "lng": 76.3833
            },
            "Kesariy\u0101": {
                "lat": 26.3342,
                "lng": 84.8548
            },
            "Yedapalli": {
                "lat": 18.6789,
                "lng": 77.9505
            },
            "P\u0101lakkuzhi": {
                "lat": 9.8833,
                "lng": 76.6139
            },
            "Chembrassh\u0113ri": {
                "lat": 11.1243,
                "lng": 76.2582
            },
            "Dundigal": {
                "lat": 17.5781,
                "lng": 78.4288
            },
            "Panjgir\u0101in Kal\u0101n": {
                "lat": 30.6096,
                "lng": 74.9143
            },
            "Nagnur": {
                "lat": 16.14,
                "lng": 74.81
            },
            "Nallajerla": {
                "lat": 16.95,
                "lng": 81.4
            },
            "Gullapuram": {
                "lat": 10.0657,
                "lng": 77.6446
            },
            "Villanchirai": {
                "lat": 9.7198,
                "lng": 76.6517
            },
            "Bhakua": {
                "lat": 26.5167,
                "lng": 86.1974
            },
            "Kulharia": {
                "lat": 25.3382,
                "lng": 86.7612
            },
            "Kadatt\u016br": {
                "lat": 12.0861,
                "lng": 78.2926
            },
            "Chinna Mushidiv\u0101da": {
                "lat": 17.8057,
                "lng": 83.2033
            },
            "Dah\u0101ria": {
                "lat": 26.1807,
                "lng": 87.0043
            },
            "Ghordaur": {
                "lat": 25.7089,
                "lng": 86.612
            },
            "Kotg\u012br": {
                "lat": 18.5722,
                "lng": 77.8243
            },
            "Talegaon Dhamdhere": {
                "lat": 18.6678,
                "lng": 74.1536
            },
            "Bas\u0101on": {
                "lat": 26.2082,
                "lng": 84.6174
            },
            "Vellarivalli": {
                "lat": 11.6003,
                "lng": 77.7927
            },
            "Benaulim": {
                "lat": 15.27,
                "lng": 73.9346
            },
            "Parih\u0101rpur": {
                "lat": 26.4026,
                "lng": 86.1256
            },
            "Gaundr\u0101": {
                "lat": 26.3683,
                "lng": 85.0036
            },
            "Uppidamangalam": {
                "lat": 10.9034,
                "lng": 78.1575
            },
            "Panap\u0101kkam": {
                "lat": 12.921,
                "lng": 79.5663
            },
            "Vel\u0101nganni": {
                "lat": 10.6814,
                "lng": 79.8508
            },
            "Vikrav\u0101ndi": {
                "lat": 12.0369,
                "lng": 79.546
            },
            "Abhwar": {
                "lat": 26.174,
                "lng": 86.6324
            },
            "Telmar": {
                "lat": 25.4237,
                "lng": 85.4358
            },
            "Bes\u0101rh": {
                "lat": 25.9537,
                "lng": 87.0491
            },
            "Ch\u012bmalap\u0101du": {
                "lat": 16.8902,
                "lng": 80.6505
            },
            "Paruch\u016bru": {
                "lat": 15.967,
                "lng": 80.275
            },
            "B\u0101gli": {
                "lat": 22.6412,
                "lng": 76.3488
            },
            "Barka Parbatta": {
                "lat": 25.3396,
                "lng": 87.0576
            },
            "Mangalapur": {
                "lat": 13.6245,
                "lng": 79.5803
            },
            "Tiorp\u0101ra": {
                "lat": 22.238,
                "lng": 88.5614
            },
            "Bijai": {
                "lat": 25.4711,
                "lng": 87.0874
            },
            "Nattappettai": {
                "lat": 12.8187,
                "lng": 79.7493
            },
            "Sigli": {
                "lat": 15.0631,
                "lng": 75.4667
            },
            "Vemulap\u016bdi": {
                "lat": 17.6087,
                "lng": 82.6138
            },
            "Payyanadam": {
                "lat": 11.0108,
                "lng": 76.4457
            },
            "Pazhanji": {
                "lat": 10.6885,
                "lng": 76.0505
            },
            "Mangal\u0101puram": {
                "lat": 11.5667,
                "lng": 78.3788
            },
            "Marung\u016br": {
                "lat": 8.1721,
                "lng": 77.5037
            },
            "R\u0101jpur": {
                "lat": 25.0768,
                "lng": 84.2055
            },
            "Chandi": {
                "lat": 22.3503,
                "lng": 88.2828
            },
            "Amarpura": {
                "lat": 26.3667,
                "lng": 73.9833
            },
            "Dambal": {
                "lat": 15.296,
                "lng": 75.7737
            },
            "Tiruv\u0101rpu": {
                "lat": 9.5811,
                "lng": 76.4749
            },
            "Pataili": {
                "lat": 25.7872,
                "lng": 85.8316
            },
            "Dohta": {
                "lat": 26.1071,
                "lng": 87.9091
            },
            "Bachhauta": {
                "lat": 25.5242,
                "lng": 86.4606
            },
            "Dumari\u0101": {
                "lat": 26.7652,
                "lng": 84.3964
            },
            "N\u0101r\u0101yangarh": {
                "lat": 24.2708,
                "lng": 75.0501
            },
            "K\u0101gv\u0101d": {
                "lat": 16.48,
                "lng": 74.78
            },
            "Goh": {
                "lat": 24.9845,
                "lng": 84.6498
            },
            "Sirh\u0101li Kal\u0101n": {
                "lat": 31.2782,
                "lng": 74.9413
            },
            "Ratn\u0101puram": {
                "lat": 18.6283,
                "lng": 79.5517
            },
            "Undr\u0101javaram": {
                "lat": 16.7866,
                "lng": 81.6997
            },
            "Padaivedu": {
                "lat": 11.4401,
                "lng": 77.8089
            },
            "Aginiparru": {
                "lat": 16.6817,
                "lng": 80.7839
            },
            "Ann\u0101malainagar": {
                "lat": 11.4,
                "lng": 79.7333
            },
            "P\u0101nchgani": {
                "lat": 17.925,
                "lng": 73.8
            },
            "Ett\u0101pur": {
                "lat": 11.6625,
                "lng": 78.4764
            },
            "Tummanatti": {
                "lat": 11.4284,
                "lng": 76.7585
            },
            "Buttar": {
                "lat": 31.0038,
                "lng": 75.3973
            },
            "Modakkurichchi": {
                "lat": 11.2329,
                "lng": 77.7794
            },
            "Peringom": {
                "lat": 12.2236,
                "lng": 75.3139
            },
            "Karivalamvandanall\u016br": {
                "lat": 9.2715,
                "lng": 77.5416
            },
            "Doddipatla": {
                "lat": 16.5167,
                "lng": 81.8436
            },
            "Murag\u0101cha": {
                "lat": 22.696,
                "lng": 88.42
            },
            "Sumbha": {
                "lat": 25.5789,
                "lng": 86.3608
            },
            "Kuttyattur": {
                "lat": 11.9754,
                "lng": 75.4927
            },
            "Chhapra": {
                "lat": 26.4005,
                "lng": 85.3721
            },
            "Manivilund\u0101n": {
                "lat": 11.6004,
                "lng": 78.7169
            },
            "Dharmaram": {
                "lat": 18.3038,
                "lng": 79.4892
            },
            "Daulatnagar": {
                "lat": 25.3216,
                "lng": 87.8443
            },
            "Nanjai Kil\u0101b\u0101di": {
                "lat": 11.2658,
                "lng": 77.77
            },
            "Dharphari": {
                "lat": 26.1501,
                "lng": 84.9564
            },
            "Perladka": {
                "lat": 12.6426,
                "lng": 75.1049
            },
            "S\u0101bang": {
                "lat": 22.183,
                "lng": 87.599
            },
            "Mahalpur": {
                "lat": 31.3618,
                "lng": 76.0377
            },
            "Gop\u0101lasamudram": {
                "lat": 8.6747,
                "lng": 77.6379
            },
            "J\u016bla Buzarg": {
                "lat": 19.4639,
                "lng": 76.9861
            },
            "Arsali": {
                "lat": 24.3754,
                "lng": 83.5903
            },
            "Bichkunda": {
                "lat": 18.4,
                "lng": 77.7167
            },
            "Kathevaram": {
                "lat": 16.261,
                "lng": 80.636
            },
            "Khiria": {
                "lat": 25.6047,
                "lng": 87.4578
            },
            "Bond\u0101da": {
                "lat": 16.5295,
                "lng": 81.4549
            },
            "Tonakkal": {
                "lat": 8.6312,
                "lng": 76.9555
            },
            "Guttal": {
                "lat": 14.8333,
                "lng": 75.6333
            },
            "Bund\u0101la": {
                "lat": 31.1333,
                "lng": 75.65
            },
            "Hiss\u0101ramuruvani": {
                "lat": 15.771,
                "lng": 77.4194
            },
            "Jobat": {
                "lat": 22.416,
                "lng": 74.5683
            },
            "M\u0101rathalli": {
                "lat": 12.15,
                "lng": 77.1
            },
            "Auria": {
                "lat": 24.8563,
                "lng": 87.0112
            },
            "Penamal\u016bru": {
                "lat": 16.4681,
                "lng": 80.7194
            },
            "H\u0101jan": {
                "lat": 34.2989,
                "lng": 74.6168
            },
            "Sam\u0101lsar": {
                "lat": 30.6364,
                "lng": 74.9983
            },
            "Hulkoti": {
                "lat": 15.4333,
                "lng": 75.5333
            },
            "Bajala": {
                "lat": 12.8537,
                "lng": 74.8853
            },
            "Kaua Kol": {
                "lat": 24.8447,
                "lng": 85.8867
            },
            "Gothva": {
                "lat": 23.4781,
                "lng": 72.5624
            },
            "Tet\u0101ri": {
                "lat": 25.3757,
                "lng": 87.0691
            },
            "Saktipur": {
                "lat": 23.864,
                "lng": 88.1987
            },
            "Ayodhy\u0101pattanam": {
                "lat": 11.6755,
                "lng": 78.2409
            },
            "Koregaon": {
                "lat": 18.6456,
                "lng": 74.0592
            },
            "Maqs\u016bda": {
                "lat": 26.241,
                "lng": 86.0585
            },
            "Barh\u012b": {
                "lat": 24.3045,
                "lng": 85.4195
            },
            "Sarg\u016br": {
                "lat": 11.9997,
                "lng": 76.3961
            },
            "Shit\u0101b Di\u0101ra": {
                "lat": 25.7563,
                "lng": 84.6133
            },
            "Kanab\u016br": {
                "lat": 13.3523,
                "lng": 75.4517
            },
            "R\u0101jup\u0101lem": {
                "lat": 15.1385,
                "lng": 79.996
            },
            "Tirumak\u016bdal Narsipur": {
                "lat": 12.2121,
                "lng": 76.9018
            },
            "Kavit\u0101l": {
                "lat": 16.1082,
                "lng": 76.7962
            },
            "Medap\u0101du": {
                "lat": 17.0044,
                "lng": 82.1001
            },
            "Kott\u0101ram": {
                "lat": 8.1188,
                "lng": 77.5243
            },
            "Gurlapeta": {
                "lat": 18.1167,
                "lng": 83.4167
            },
            "Takkolam": {
                "lat": 13.0164,
                "lng": 79.7325
            },
            "Chandhaus": {
                "lat": 25.2977,
                "lng": 84.8595
            },
            "Udaipur Bithw\u0101r": {
                "lat": 26.2872,
                "lng": 86.1578
            },
            "Peddavadlap\u016bdi": {
                "lat": 16.4098,
                "lng": 80.6114
            },
            "Ekw\u0101ri": {
                "lat": 25.2975,
                "lng": 84.4994
            },
            "Nasiyan\u016br": {
                "lat": 11.3381,
                "lng": 77.6408
            },
            "Killai": {
                "lat": 11.4493,
                "lng": 79.7668
            },
            "Kalikiri": {
                "lat": 13.6333,
                "lng": 78.8
            },
            "Pallapp\u0101laiyam": {
                "lat": 11.3891,
                "lng": 77.6027
            },
            "J\u0101lhalli": {
                "lat": 13.0333,
                "lng": 77.55
            },
            "Paina": {
                "lat": 26.2513,
                "lng": 83.7812
            },
            "Balua": {
                "lat": 26.3272,
                "lng": 87.6231
            },
            "Mohgaon": {
                "lat": 21.6394,
                "lng": 78.7364
            },
            "Pandireddig\u016bdem": {
                "lat": 16.8,
                "lng": 81.3167
            },
            "Rasauli": {
                "lat": 26.127,
                "lng": 84.8378
            },
            "Char\u0101bidya": {
                "lat": 22.3303,
                "lng": 88.7702
            },
            "Turbih\u0101l": {
                "lat": 15.7614,
                "lng": 76.5964
            },
            "Ajas": {
                "lat": 34.3316,
                "lng": 74.6801
            },
            "D\u0101r\u0101suram": {
                "lat": 10.9515,
                "lng": 79.3562
            },
            "Day\u0101lpur S\u0101pha": {
                "lat": 25.7122,
                "lng": 85.3188
            },
            "Bar\u0101on": {
                "lat": 25.4551,
                "lng": 84.2661
            },
            "Neuri": {
                "lat": 26.0076,
                "lng": 86.1841
            },
            "B\u0101rah": {
                "lat": 25.3885,
                "lng": 85.4912
            },
            "Peddak\u016brap\u0101du": {
                "lat": 16.4833,
                "lng": 80.2667
            },
            "Gangoli": {
                "lat": 13.6538,
                "lng": 74.6664
            },
            "Kalaiyamputt\u016br": {
                "lat": 10.4542,
                "lng": 77.4831
            },
            "Belpukur": {
                "lat": 21.9851,
                "lng": 88.2125
            },
            "Thara": {
                "lat": 26.7027,
                "lng": 78.2788
            },
            "Chandauli": {
                "lat": 25.8972,
                "lng": 85.7161
            },
            "Sidhw\u0101n": {
                "lat": 31.2725,
                "lng": 75.3652
            },
            "Jalam": {
                "lat": 20.8167,
                "lng": 76.5833
            },
            "Ghor\u0101dongri": {
                "lat": 22.1228,
                "lng": 78.0025
            },
            "Moviya": {
                "lat": 21.9153,
                "lng": 70.8465
            },
            "Amauna": {
                "lat": 25.0531,
                "lng": 84.5169
            },
            "Dammennu": {
                "lat": 16.8255,
                "lng": 81.7079
            },
            "Anand\u0101puram": {
                "lat": 11.09,
                "lng": 76.9422
            },
            "Lohiy\u0101r Ujain": {
                "lat": 26.6284,
                "lng": 84.7452
            },
            "Baduri\u0101tola": {
                "lat": 24.0928,
                "lng": 84.0895
            },
            "Kottapadi": {
                "lat": 10.0667,
                "lng": 76.6
            },
            "Chh\u0101ra": {
                "lat": 28.6957,
                "lng": 76.7072
            },
            "Ch\u0101walh\u0101ti": {
                "lat": 26.4841,
                "lng": 88.5677
            },
            "Kollipara": {
                "lat": 16.2877,
                "lng": 80.7519
            },
            "Ch\u0101ng": {
                "lat": 28.8808,
                "lng": 76.2427
            },
            "Ji\u0101nganj": {
                "lat": 25.8661,
                "lng": 87.2612
            },
            "Sitalpur": {
                "lat": 26.405,
                "lng": 85.007
            },
            "Barwat Pasr\u0101in": {
                "lat": 26.7925,
                "lng": 84.5469
            },
            "Phulwaria": {
                "lat": 25.9395,
                "lng": 85.0035
            },
            "Bachr\u0101": {
                "lat": 23.6886,
                "lng": 85.0703
            },
            "Lingamparti": {
                "lat": 17.2827,
                "lng": 82.1231
            },
            "Upp\u0101da": {
                "lat": 17.0833,
                "lng": 82.3333
            },
            "Palangarai": {
                "lat": 11.1978,
                "lng": 77.3058
            },
            "K\u016bdangulam": {
                "lat": 8.1901,
                "lng": 77.7002
            },
            "Jhaua": {
                "lat": 25.625,
                "lng": 84.5161
            },
            "Mirdaul": {
                "lat": 26.2363,
                "lng": 87.0971
            },
            "Mulakal\u016bru": {
                "lat": 16.2776,
                "lng": 80.0672
            },
            "Jaitpur": {
                "lat": 25.9132,
                "lng": 84.573
            },
            "Lakkavaram": {
                "lat": 17.0628,
                "lng": 81.2919
            },
            "Cherutana Tekku": {
                "lat": 9.323,
                "lng": 76.4382
            },
            "S\u0101khmohan": {
                "lat": 25.6325,
                "lng": 85.9859
            },
            "R\u0101mpur Sh\u0101mchand": {
                "lat": 25.5664,
                "lng": 85.3289
            },
            "Kachavaram": {
                "lat": 16.5361,
                "lng": 79.7187
            },
            "\u0100tmak\u016bru": {
                "lat": 14.6452,
                "lng": 77.3554
            },
            "Amarw\u0101 Khurd": {
                "lat": 26.8333,
                "lng": 84.25
            },
            "Kalanaur": {
                "lat": 32.01,
                "lng": 75.09
            },
            "Kar\u0101hal": {
                "lat": 25.4909,
                "lng": 77.0543
            },
            "Kankandighi": {
                "lat": 21.9744,
                "lng": 88.4659
            },
            "Vellakkinar": {
                "lat": 11.0736,
                "lng": 76.9566
            },
            "Tirmaigiri": {
                "lat": 16.723,
                "lng": 79.3374
            },
            "Gurgunta": {
                "lat": 16.2558,
                "lng": 76.5628
            },
            "Sisauna": {
                "lat": 26.132,
                "lng": 87.6231
            },
            "Kalapp\u0101langulam": {
                "lat": 9.1889,
                "lng": 77.5604
            },
            "Saghar Sult\u0101npur": {
                "lat": 26.1583,
                "lng": 84.757
            },
            "B\u0101gew\u0101di": {
                "lat": 16.29,
                "lng": 74.6
            },
            "Elavanas\u016br": {
                "lat": 11.7154,
                "lng": 79.1796
            },
            "Pentap\u0101du Kasba": {
                "lat": 16.7804,
                "lng": 81.5254
            },
            "Kalavai": {
                "lat": 12.7691,
                "lng": 79.4196
            },
            "Ringnod": {
                "lat": 22.6113,
                "lng": 74.9338
            },
            "Edattirutti": {
                "lat": 10.3667,
                "lng": 76.1333
            },
            "Koturkolkara": {
                "lat": 9.5667,
                "lng": 76.8833
            },
            "Al\u0101yaman": {
                "lat": 8.8843,
                "lng": 76.9653
            },
            "Tanakkangulam": {
                "lat": 9.8877,
                "lng": 78.0397
            },
            "Mans\u0101pur": {
                "lat": 26.5262,
                "lng": 86.4101
            },
            "Rehti": {
                "lat": 22.7378,
                "lng": 77.434
            },
            "M\u016blaikkaraippatti": {
                "lat": 8.5454,
                "lng": 77.7679
            },
            "Dhutauli": {
                "lat": 25.5274,
                "lng": 86.625
            },
            "Chinnasekkadu": {
                "lat": 13.1609,
                "lng": 80.2573
            },
            "Daparkha": {
                "lat": 26.1293,
                "lng": 86.9004
            },
            "Saunshi": {
                "lat": 15.2167,
                "lng": 75.3
            },
            "Vedappatti": {
                "lat": 10.9988,
                "lng": 76.896
            },
            "Rahika": {
                "lat": 26.3803,
                "lng": 86.0204
            },
            "Bih\u0101r\u012bganj": {
                "lat": 25.7341,
                "lng": 86.9884
            },
            "Sugauna": {
                "lat": 26.4077,
                "lng": 86.2174
            },
            "Kall\u0101d": {
                "lat": 9.95,
                "lng": 76.6667
            },
            "Jan\u0101i": {
                "lat": 22.7157,
                "lng": 88.2426
            },
            "Meddappakkam": {
                "lat": 12.9166,
                "lng": 80.1939
            },
            "K\u0101dachchinall\u016br": {
                "lat": 11.3686,
                "lng": 77.8033
            },
            "Sattar": {
                "lat": 25.955,
                "lng": 86.5828
            },
            "Barhauna": {
                "lat": 25.304,
                "lng": 85.4216
            },
            "Kaul": {
                "lat": 29.8461,
                "lng": 76.6608
            },
            "Mullassh\u0113ri": {
                "lat": 10.5333,
                "lng": 76.0833
            },
            "Salempur": {
                "lat": 29.875,
                "lng": 77.858
            },
            "Arakere": {
                "lat": 12.4133,
                "lng": 76.8168
            },
            "Ganga": {
                "lat": 30.3229,
                "lng": 74.9234
            },
            "Ziro": {
                "lat": 27.5664,
                "lng": 93.8314
            },
            "Bela Simri": {
                "lat": 25.5295,
                "lng": 86.3079
            },
            "Bhagw\u0101npur Khurd": {
                "lat": 26.75,
                "lng": 83.74
            },
            "Venkatagirikota": {
                "lat": 13.0012,
                "lng": 78.4795
            },
            "B\u0101zidpur": {
                "lat": 26.1536,
                "lng": 86.1511
            },
            "Govindgarh": {
                "lat": 24.3785,
                "lng": 81.2964
            },
            "Yelmalla": {
                "lat": 18.8241,
                "lng": 78.1609
            },
            "Kachchippalli": {
                "lat": 11.595,
                "lng": 77.9188
            },
            "Chinnakk\u0101mp\u0101laiyam": {
                "lat": 10.7064,
                "lng": 77.4575
            },
            "Arohi": {
                "lat": 31.8419,
                "lng": 76.8645
            },
            "Kurwa Mathi\u0101": {
                "lat": 26.8572,
                "lng": 84.5058
            },
            "Lok\u0101pur": {
                "lat": 16.1656,
                "lng": 75.366
            },
            "R\u0101mpurw\u0101": {
                "lat": 26.7544,
                "lng": 84.7114
            },
            "Mall\u0101pur": {
                "lat": 16.14,
                "lng": 74.81
            },
            "Jasauli": {
                "lat": 26.4996,
                "lng": 84.8171
            },
            "Kannudaiy\u0101mpatti": {
                "lat": 10.6377,
                "lng": 78.4733
            },
            "Nangavalli": {
                "lat": 11.7619,
                "lng": 77.8909
            },
            "Harpur Bochaha": {
                "lat": 25.5919,
                "lng": 85.7517
            },
            "Nandy\u0101lampeta": {
                "lat": 14.7218,
                "lng": 78.8083
            },
            "Ch\u0101hatpur": {
                "lat": 26.2331,
                "lng": 87.5816
            },
            "Bariy\u0101rpur": {
                "lat": 26.6347,
                "lng": 84.9245
            },
            "Maheswa": {
                "lat": 25.9718,
                "lng": 86.855
            },
            "Anaikal": {
                "lat": 9.6003,
                "lng": 76.6846
            },
            "Vadasseri": {
                "lat": 8.1937,
                "lng": 77.4343
            },
            "Naw\u0101da Gobindganj": {
                "lat": 26.4868,
                "lng": 84.6561
            },
            "Barud": {
                "lat": 21.7497,
                "lng": 75.527
            },
            "Kolappal\u016br": {
                "lat": 11.51,
                "lng": 77.45
            },
            "K\u0101mrej": {
                "lat": 21.1364,
                "lng": 72.9572
            },
            "Maroli": {
                "lat": 21.024,
                "lng": 72.889
            },
            "Begampur": {
                "lat": 22.7368,
                "lng": 88.2518
            },
            "Dharampur B\u0101nde": {
                "lat": 25.6609,
                "lng": 85.5742
            },
            "Bah\u0101durpur": {
                "lat": 25.4522,
                "lng": 85.6303
            },
            "Maj\u0101rh\u0101t": {
                "lat": 25.9654,
                "lng": 86.8126
            },
            "Chinna Gollap\u0101lem": {
                "lat": 16.3701,
                "lng": 81.5322
            },
            "Mah\u0101baleshwar": {
                "lat": 17.925,
                "lng": 73.6575
            },
            "Bandlag\u016bda": {
                "lat": 17.3543,
                "lng": 78.3853
            },
            "J\u0101mi": {
                "lat": 18.05,
                "lng": 83.2667
            },
            "Duvv\u016bru": {
                "lat": 14.5507,
                "lng": 79.8331
            },
            "Obal\u0101puram": {
                "lat": 14.8,
                "lng": 78.783
            },
            "Emmigan\u016bru": {
                "lat": 15.15,
                "lng": 76.92
            },
            "Bakht\u0101warpur": {
                "lat": 28.821,
                "lng": 77.166
            },
            "Mahtha": {
                "lat": 26.5988,
                "lng": 86.3158
            },
            "P\u012brnagar": {
                "lat": 25.5982,
                "lng": 86.7178
            },
            "D\u0101marcherla": {
                "lat": 16.7269,
                "lng": 79.6369
            },
            "Sival\u0101rkulam": {
                "lat": 8.87,
                "lng": 77.5267
            },
            "M\u0101dugula": {
                "lat": 17.9167,
                "lng": 82.8
            },
            "Nirakpurp\u0101li": {
                "lat": 25.3244,
                "lng": 84.8114
            },
            "Sam\u0101i": {
                "lat": 24.9788,
                "lng": 85.5861
            },
            "Battulapalle": {
                "lat": 14.5167,
                "lng": 77.7833
            },
            "Sante Benn\u016br": {
                "lat": 14.1697,
                "lng": 76.0033
            },
            "Peraiy\u016br": {
                "lat": 9.7341,
                "lng": 77.79
            },
            "M\u0101n\u0101kond\u016br": {
                "lat": 18.3981,
                "lng": 79.225
            },
            "Bamhn\u012b": {
                "lat": 22.4822,
                "lng": 80.3716
            },
            "\u0100lampur Gonpura": {
                "lat": 25.5404,
                "lng": 85.0511
            },
            "Maniyur": {
                "lat": 11.95,
                "lng": 75.45
            },
            "Daharia": {
                "lat": 25.5307,
                "lng": 87.5947
            },
            "Kotturu": {
                "lat": 17.2958,
                "lng": 82.4752
            },
            "P\u016bd\u016bru": {
                "lat": 16.152,
                "lng": 77.8273
            },
            "Mugutkhan Hubli": {
                "lat": 15.79,
                "lng": 74.75
            },
            "Sheopuria": {
                "lat": 28.53,
                "lng": 79.77
            },
            "Til\u0101ri": {
                "lat": 25.004,
                "lng": 85.252
            },
            "Awantipur": {
                "lat": 33.9233,
                "lng": 75.0128
            },
            "Pond\u016bru": {
                "lat": 18.3508,
                "lng": 83.7567
            },
            "Putt\u0101nattam": {
                "lat": 10.467,
                "lng": 78.3687
            },
            "K\u0101kan": {
                "lat": 26.1509,
                "lng": 87.5674
            },
            "Tenmalai": {
                "lat": 9.3129,
                "lng": 77.4959
            },
            "Adukam": {
                "lat": 9.7954,
                "lng": 76.8456
            },
            "Kanniyambram": {
                "lat": 10.7333,
                "lng": 76.4
            },
            "Naduhatti": {
                "lat": 11.4043,
                "lng": 76.816
            },
            "K\u0101diy\u0101mpatti": {
                "lat": 11.872,
                "lng": 78.0961
            },
            "Jalpura": {
                "lat": 25.4862,
                "lng": 84.737
            },
            "Motk\u016br": {
                "lat": 17.4569,
                "lng": 79.2592
            },
            "Parigi": {
                "lat": 13.9069,
                "lng": 77.4572
            },
            "Chariyakulam": {
                "lat": 9.6527,
                "lng": 77.1226
            },
            "Kharak Kal\u0101n": {
                "lat": 28.807,
                "lng": 76.314
            },
            "Garh Sisai": {
                "lat": 25.6253,
                "lng": 85.7887
            },
            "Belagal": {
                "lat": 15.8183,
                "lng": 77.6702
            },
            "Muttanamp\u0101laiyam": {
                "lat": 11.0845,
                "lng": 77.3969
            },
            "Ang\u0101dikkal Tekkekara": {
                "lat": 9.2063,
                "lng": 76.7835
            },
            "Hosir": {
                "lat": 23.7735,
                "lng": 85.8132
            },
            "Khagra": {
                "lat": 25.3511,
                "lng": 87.0624
            },
            "M\u012bn\u0101kshipuram": {
                "lat": 9.9908,
                "lng": 77.3709
            },
            "Mahu\u0101kher\u0101ganj": {
                "lat": 29.13,
                "lng": 78.92
            },
            "Koppal": {
                "lat": 15.35,
                "lng": 76.15
            },
            "Chandw\u0101": {
                "lat": 23.6756,
                "lng": 84.7401
            },
            "Karedu": {
                "lat": 15.1833,
                "lng": 80.0667
            },
            "\u0100ppukkudal": {
                "lat": 11.4684,
                "lng": 77.5766
            },
            "Tirukk\u0101ttuppalli": {
                "lat": 10.51,
                "lng": 78.57
            },
            "Mandr\u0101mo": {
                "lat": 24.1822,
                "lng": 85.8734
            },
            "Murud": {
                "lat": 18.33,
                "lng": 72.96
            },
            "Nohsa": {
                "lat": 25.5653,
                "lng": 85.0558
            },
            "Parasur\u0101mp\u016br": {
                "lat": 14.2606,
                "lng": 76.8826
            },
            "Pilich": {
                "lat": 25.2379,
                "lng": 85.3483
            },
            "Marikal": {
                "lat": 16.602,
                "lng": 77.7294
            },
            "Dev\u0101pur": {
                "lat": 19.0443,
                "lng": 79.3499
            },
            "Osi\u0101n": {
                "lat": 26.7167,
                "lng": 72.9167
            },
            "Nakhtar\u0101na": {
                "lat": 23.35,
                "lng": 69.2633
            },
            "Turki": {
                "lat": 26.0381,
                "lng": 85.3577
            },
            "Betma": {
                "lat": 22.68,
                "lng": 75.62
            },
            "Mahrail": {
                "lat": 26.2952,
                "lng": 86.2763
            },
            "M\u0101yam\u0101nkurichchi": {
                "lat": 8.8855,
                "lng": 77.5041
            },
            "Hansot": {
                "lat": 21.5833,
                "lng": 72.8
            },
            "Tola Khadda": {
                "lat": 26.7396,
                "lng": 84.5014
            },
            "Champ\u0101d\u0101nga": {
                "lat": 22.83,
                "lng": 87.96
            },
            "Kewatg\u0101w\u0101n": {
                "lat": 25.8012,
                "lng": 86.3229
            },
            "S\u0101valgi": {
                "lat": 16.671,
                "lng": 75.3515
            },
            "Pannaikk\u0101du": {
                "lat": 10.2761,
                "lng": 77.6272
            },
            "Satt\u0113g\u0101lam": {
                "lat": 12.15,
                "lng": 77.1
            },
            "Dhanupra": {
                "lat": 25.6992,
                "lng": 86.4716
            },
            "Paith\u0101n Kawai": {
                "lat": 26.1755,
                "lng": 86.0685
            },
            "Coringa": {
                "lat": 16.8,
                "lng": 82.2333
            },
            "Zuvvaladinne": {
                "lat": 14.808,
                "lng": 80.0705
            },
            "Gosaingaon": {
                "lat": 25.3724,
                "lng": 87.1049
            },
            "Devsar": {
                "lat": 28.76,
                "lng": 76.087
            },
            "Vik\u0101snagar": {
                "lat": 30.468,
                "lng": 77.774
            },
            "\u0100lw\u0101rkurichchi": {
                "lat": 8.7838,
                "lng": 77.3952
            },
            "Kotw\u0101patti R\u0101mpur": {
                "lat": 25.7153,
                "lng": 84.8063
            },
            "Domahani": {
                "lat": 23.7569,
                "lng": 87.0256
            },
            "Thair": {
                "lat": 18.3213,
                "lng": 76.133
            },
            "Tirupp\u0101lai": {
                "lat": 9.9825,
                "lng": 78.143
            },
            "Chab\u0101l Kal\u0101n": {
                "lat": 31.4818,
                "lng": 74.7927
            },
            "Kath\u0101ni\u0101n": {
                "lat": 31.6373,
                "lng": 74.7572
            },
            "Kuttiy\u0113ri": {
                "lat": 12.0667,
                "lng": 75.35
            },
            "Bheja": {
                "lat": 26.1046,
                "lng": 86.395
            },
            "Zaladanki": {
                "lat": 14.8843,
                "lng": 79.9125
            },
            "R\u0101nranagudipeta": {
                "lat": 12.6618,
                "lng": 78.5483
            },
            "Lakshm\u012bn\u0101r\u0101yanapuram": {
                "lat": 11.7914,
                "lng": 79.5555
            },
            "P\u0101tiram": {
                "lat": 25.3167,
                "lng": 88.75
            },
            "Guruvar\u0101jukuppam": {
                "lat": 13.1125,
                "lng": 79.5472
            },
            "Ahirauliy\u0101": {
                "lat": 26.5091,
                "lng": 84.7999
            },
            "Harrai": {
                "lat": 22.6143,
                "lng": 79.2221
            },
            "Bhanas Hivre": {
                "lat": 19.4858,
                "lng": 74.9664
            },
            "Hanumantanpatti": {
                "lat": 9.7858,
                "lng": 77.317
            },
            "Nelmad\u016br": {
                "lat": 9.51,
                "lng": 78.3
            },
            "K\u0101nkon": {
                "lat": 15.02,
                "lng": 74.02
            },
            "Gohpur": {
                "lat": 26.8818,
                "lng": 93.6156
            },
            "Bandalli": {
                "lat": 12.164,
                "lng": 77.3504
            },
            "Kallupatti": {
                "lat": 9.7167,
                "lng": 77.8667
            },
            "Dhansura": {
                "lat": 23.3492,
                "lng": 73.2119
            },
            "Titara": {
                "lat": 26.2311,
                "lng": 84.2444
            },
            "Mallappulasseri": {
                "lat": 9.3167,
                "lng": 76.6833
            },
            "Hullatti": {
                "lat": 11.4771,
                "lng": 76.696
            },
            "Kolatt\u016br": {
                "lat": 13.3295,
                "lng": 79.4797
            },
            "Maraiy\u016br": {
                "lat": 10.2762,
                "lng": 77.1615
            },
            "Ponnai": {
                "lat": 13.1276,
                "lng": 79.2575
            },
            "Butt\u0101yag\u016bdem": {
                "lat": 17.2089,
                "lng": 81.3014
            },
            "Payyanpalli": {
                "lat": 11.8111,
                "lng": 76.0556
            },
            "Kalingiyam": {
                "lat": 11.4324,
                "lng": 77.4178
            },
            "Ammav\u0101rikuppam": {
                "lat": 13.1784,
                "lng": 79.4169
            },
            "Kamtaul": {
                "lat": 26.328,
                "lng": 85.8238
            },
            "Chennampatti": {
                "lat": 11.7011,
                "lng": 77.6785
            },
            "Kallamalai": {
                "lat": 11.0489,
                "lng": 76.5691
            },
            "Attan\u016br": {
                "lat": 11.5006,
                "lng": 78.1356
            },
            "Panj\u012bp\u0101ra": {
                "lat": 26.1369,
                "lng": 88.0132
            },
            "Sher": {
                "lat": 26.3422,
                "lng": 84.621
            },
            "Arma": {
                "lat": 25.1966,
                "lng": 86.2468
            },
            "Olagadam": {
                "lat": 11.5656,
                "lng": 77.6428
            },
            "Dharhara": {
                "lat": 25.2543,
                "lng": 86.403
            },
            "Moka": {
                "lat": 15.238,
                "lng": 77.0599
            },
            "Ichora": {
                "lat": 19.4333,
                "lng": 78.4667
            },
            "Nitte": {
                "lat": 13.1858,
                "lng": 74.9394
            },
            "Tenamb\u0101kkam": {
                "lat": 12.8102,
                "lng": 79.7341
            },
            "Dubak": {
                "lat": 18.1744,
                "lng": 78.6667
            },
            "Alampur": {
                "lat": 26.0251,
                "lng": 78.797
            },
            "Bandw\u0101r": {
                "lat": 25.5093,
                "lng": 86.1595
            },
            "Aphaur": {
                "lat": 25.8828,
                "lng": 84.7595
            },
            "Periyakoduveri": {
                "lat": 11.4811,
                "lng": 77.2989
            },
            "K\u0101ttukkottai": {
                "lat": 11.6026,
                "lng": 78.6698
            },
            "Desri": {
                "lat": 25.6691,
                "lng": 85.4082
            },
            "Navelim": {
                "lat": 15.2564,
                "lng": 73.9686
            },
            "J\u0101nkinagar": {
                "lat": 25.8955,
                "lng": 87.0668
            },
            "Dasraha Bhogr\u0101jpur": {
                "lat": 25.5691,
                "lng": 85.6441
            },
            "Kotia": {
                "lat": 26.275,
                "lng": 86.0532
            },
            "Chettipulam": {
                "lat": 10.4743,
                "lng": 79.7546
            },
            "Pandalkudi": {
                "lat": 9.3947,
                "lng": 78.1051
            },
            "Kakching Khunou": {
                "lat": 24.4047,
                "lng": 93.8998
            },
            "Paikpar": {
                "lat": 26.0966,
                "lng": 87.1349
            },
            "Atm\u0101k\u016br": {
                "lat": 18.0712,
                "lng": 79.736
            },
            "Kot Shamir": {
                "lat": 30.1191,
                "lng": 75.0058
            },
            "Melav\u0101yi": {
                "lat": 13.9397,
                "lng": 77.2256
            },
            "Pebberu": {
                "lat": 16.2167,
                "lng": 77.9833
            },
            "Lodh\u012bkheda": {
                "lat": 21.5824,
                "lng": 78.8591
            },
            "Eraniel": {
                "lat": 8.2059,
                "lng": 77.3173
            },
            "Hathw\u0101n": {
                "lat": 25.6672,
                "lng": 86.3999
            },
            "Yenkuv\u0101rig\u016bdem": {
                "lat": 17.2055,
                "lng": 81.1598
            },
            "Baikunthpur": {
                "lat": 24.7277,
                "lng": 81.4098
            },
            "Kulatt\u016br": {
                "lat": 10.7052,
                "lng": 78.5507
            },
            "Pol\u0101s": {
                "lat": 18.83,
                "lng": 78.97
            },
            "Sev\u016br": {
                "lat": 12.6865,
                "lng": 79.2687
            },
            "Dagm\u0101ra": {
                "lat": 26.3953,
                "lng": 86.7601
            },
            "Kamalnagar": {
                "lat": 18.231,
                "lng": 77.1791
            },
            "P\u0101iker": {
                "lat": 24.4388,
                "lng": 87.9112
            },
            "Mekra": {
                "lat": 25.4705,
                "lng": 85.8406
            },
            "Mahespur": {
                "lat": 24.6954,
                "lng": 87.9414
            },
            "Khujner": {
                "lat": 23.786,
                "lng": 76.6177
            },
            "Eral": {
                "lat": 8.6258,
                "lng": 78.0228
            },
            "Kallanai": {
                "lat": 10.0374,
                "lng": 78.0829
            },
            "Doddanahalli": {
                "lat": 12.3892,
                "lng": 76.9672
            },
            "Manamodu": {
                "lat": 11.1965,
                "lng": 79.5953
            },
            "Neralakaje": {
                "lat": 12.5884,
                "lng": 75.0348
            },
            "Narala": {
                "lat": 12.5884,
                "lng": 75.0348
            },
            "Ch\u0101marru": {
                "lat": 16.65,
                "lng": 80.1333
            },
            "Bhachhi Asli": {
                "lat": 26.0147,
                "lng": 86.0444
            },
            "Karr\u0101pur": {
                "lat": 23.9489,
                "lng": 78.8658
            },
            "Dharmastala": {
                "lat": 12.9479,
                "lng": 75.3807
            },
            "Pathra": {
                "lat": 24.8804,
                "lng": 87.0447
            },
            "Puduvayal": {
                "lat": 10.1034,
                "lng": 78.8462
            },
            "Han\u016br": {
                "lat": 12.0874,
                "lng": 77.3021
            },
            "Chichli": {
                "lat": 22.8336,
                "lng": 78.8261
            },
            "Kumirimora": {
                "lat": 22.6969,
                "lng": 88.2267
            },
            "Ghogha": {
                "lat": 21.68,
                "lng": 72.28
            },
            "Lauriy\u0101 Nandangarh": {
                "lat": 26.9985,
                "lng": 84.4085
            },
            "Kolanp\u0101k": {
                "lat": 17.6942,
                "lng": 79.0348
            },
            "Kottagudi Muttan\u0101d": {
                "lat": 10.0133,
                "lng": 77.0633
            },
            "Sabbavaram": {
                "lat": 17.79,
                "lng": 83.123
            },
            "Belwa": {
                "lat": 26.1056,
                "lng": 87.4916
            },
            "Loiyo": {
                "lat": 23.7928,
                "lng": 85.632
            },
            "Rudr\u016br": {
                "lat": 18.67,
                "lng": 77.9
            },
            "Bukkapatnam": {
                "lat": 14.2,
                "lng": 77.8
            },
            "Mariyammanahalli": {
                "lat": 15.16,
                "lng": 76.36
            },
            "Dorand\u0101": {
                "lat": 24.471,
                "lng": 85.9449
            },
            "Namchi": {
                "lat": 27.17,
                "lng": 88.35
            },
            "Karimkunnum": {
                "lat": 9.85,
                "lng": 76.6833
            },
            "Saraiya": {
                "lat": 24.8012,
                "lng": 84.0816
            },
            "Kutt\u016br": {
                "lat": 10.5631,
                "lng": 76.1951
            },
            "Bhais\u0101lotan": {
                "lat": 27.45,
                "lng": 83.9167
            },
            "Uppukkottai": {
                "lat": 9.9587,
                "lng": 77.4105
            },
            "Ghor\u0101dal": {
                "lat": 22.0519,
                "lng": 88.3594
            },
            "Nemili": {
                "lat": 12.9783,
                "lng": 79.6172
            },
            "Bhankarpur": {
                "lat": 30.65,
                "lng": 76.82
            },
            "Mat\u0101la": {
                "lat": 17.8244,
                "lng": 76.8591
            },
            "Kayatt\u0101r": {
                "lat": 8.9469,
                "lng": 77.7714
            },
            "K\u014dtt\u0101ya": {
                "lat": 10.7646,
                "lng": 76.5452
            },
            "Vannivedu": {
                "lat": 12.9173,
                "lng": 79.353
            },
            "Vandith\u0101valam": {
                "lat": 10.65,
                "lng": 76.7583
            },
            "Gajhara": {
                "lat": 26.5237,
                "lng": 86.3715
            },
            "Morab": {
                "lat": 15.5833,
                "lng": 75.1667
            },
            "Sam\u0101dh Bhai": {
                "lat": 30.5985,
                "lng": 75.1506
            },
            "Kesarimangalam": {
                "lat": 11.5423,
                "lng": 77.7247
            },
            "Panzg\u0101m": {
                "lat": 34.484,
                "lng": 74.0753
            },
            "Eklahra": {
                "lat": 22.2036,
                "lng": 78.6893
            },
            "Barsaun": {
                "lat": 25.6316,
                "lng": 84.4372
            },
            "Muthusw\u0101mipuram": {
                "lat": 9.39,
                "lng": 77.4887
            },
            "Mulug": {
                "lat": 18.1895,
                "lng": 79.9437
            },
            "Pachchaimalaiyankottai": {
                "lat": 10.2736,
                "lng": 77.8794
            },
            "Mangarw\u0101ra": {
                "lat": 26.0119,
                "lng": 87.0854
            },
            "M\u0101ndu": {
                "lat": 23.7946,
                "lng": 85.4687
            },
            "Antri": {
                "lat": 26.0581,
                "lng": 78.21
            },
            "Velair": {
                "lat": 18.0071,
                "lng": 79.3272
            },
            "Kuzhippilli": {
                "lat": 10.015,
                "lng": 76.22
            },
            "Ch\u0101mp\u0101h\u0101ti": {
                "lat": 22.4043,
                "lng": 88.4937
            },
            "Pulakurti": {
                "lat": 15.7502,
                "lng": 77.7091
            },
            "Sonupur": {
                "lat": 25.8,
                "lng": 86.0253
            },
            "Punnila": {
                "lat": 9.0842,
                "lng": 76.9175
            },
            "Yedtare": {
                "lat": 13.9246,
                "lng": 74.6171
            },
            "Bharwel\u012b": {
                "lat": 21.8373,
                "lng": 80.2271
            },
            "Datoda": {
                "lat": 22.5713,
                "lng": 75.8823
            },
            "M\u0101negaon": {
                "lat": 23.2062,
                "lng": 80.0123
            },
            "K\u012brippatti": {
                "lat": 11.5357,
                "lng": 78.4897
            },
            "Bhair\u0101pura": {
                "lat": 12.2119,
                "lng": 76.8953
            },
            "Anndevarapeta": {
                "lat": 17.0937,
                "lng": 81.5799
            },
            "Pill\u0101nall\u016br": {
                "lat": 11.4322,
                "lng": 78.1334
            },
            "Narhat": {
                "lat": 24.7774,
                "lng": 85.4237
            },
            "P\u0101pampeta": {
                "lat": 14.6855,
                "lng": 77.578
            },
            "Ganapatip\u0101laiyam": {
                "lat": 11.0309,
                "lng": 77.3367
            },
            "Mugalivakkam": {
                "lat": 13.0205,
                "lng": 80.1653
            },
            "Bird\u0101ban": {
                "lat": 26.4012,
                "lng": 84.9934
            },
            "Kodusseri": {
                "lat": 10.3431,
                "lng": 76.3931
            },
            "R\u0101mpur Kudarkatti": {
                "lat": 26.0672,
                "lng": 87.4795
            },
            "Nih\u0101l Singhw\u0101la": {
                "lat": 30.5919,
                "lng": 75.2804
            },
            "Viswan\u0101thaperi": {
                "lat": 9.3359,
                "lng": 77.45
            },
            "Bhagw\u0101npur Desua": {
                "lat": 25.8124,
                "lng": 85.8382
            },
            "Kesabpur": {
                "lat": 22.6196,
                "lng": 88.2075
            },
            "Dhaula": {
                "lat": 30.2856,
                "lng": 75.4602
            },
            "Bishn\u0101h": {
                "lat": 32.6106,
                "lng": 74.8556
            },
            "Bharokhara": {
                "lat": 25.8655,
                "lng": 85.6834
            },
            "Pharkia": {
                "lat": 25.9384,
                "lng": 87.4034
            },
            "N\u0101ttarampalli": {
                "lat": 12.592,
                "lng": 78.5116
            },
            "Chalgeri": {
                "lat": 14.5652,
                "lng": 75.7166
            },
            "Bang\u0101w\u0101n": {
                "lat": 26.0427,
                "lng": 87.4202
            },
            "Goner": {
                "lat": 26.7795,
                "lng": 75.9123
            },
            "Sarmastpur": {
                "lat": 25.949,
                "lng": 85.5751
            },
            "Vil\u0101ngurichchi": {
                "lat": 11.0709,
                "lng": 77.0178
            },
            "Manne Ekeli": {
                "lat": 17.72,
                "lng": 77.36
            },
            "B\u0101gra": {
                "lat": 25.2,
                "lng": 72.5833
            },
            "Pittalav\u0101nip\u0101lem": {
                "lat": 15.9686,
                "lng": 80.6054
            },
            "K\u0101rvetnagar": {
                "lat": 13.4167,
                "lng": 79.45
            },
            "Paramanandal": {
                "lat": 12.3585,
                "lng": 78.7664
            },
            "Sabaur": {
                "lat": 25.2428,
                "lng": 87.0446
            },
            "Edamon": {
                "lat": 9.0,
                "lng": 76.9667
            },
            "Gulni": {
                "lat": 24.9309,
                "lng": 85.803
            },
            "Chudamani": {
                "lat": 21.1379,
                "lng": 86.7794
            },
            "Utehia": {
                "lat": 26.4484,
                "lng": 81.3651
            },
            "Kumarkh\u0101li": {
                "lat": 22.3598,
                "lng": 88.799
            },
            "Velivennu": {
                "lat": 16.5034,
                "lng": 81.4116
            },
            "Balha": {
                "lat": 25.5468,
                "lng": 86.5683
            },
            "Saht\u0101h": {
                "lat": 25.8567,
                "lng": 85.2407
            },
            "Singhb\u0101ri": {
                "lat": 25.3761,
                "lng": 87.9485
            },
            "Viyapuram": {
                "lat": 9.3167,
                "lng": 76.4667
            },
            "Basantpur": {
                "lat": 26.1331,
                "lng": 87.4656
            },
            "Huvin Hippargi": {
                "lat": 16.59,
                "lng": 75.96
            },
            "Kh\u0101nda": {
                "lat": 28.9167,
                "lng": 76.8833
            },
            "Pinneli": {
                "lat": 16.5689,
                "lng": 79.8334
            },
            "Dahivel": {
                "lat": 21.0667,
                "lng": 74.1667
            },
            "Jai": {
                "lat": 28.9833,
                "lng": 77.9333
            },
            "Sadalgi": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Halgar": {
                "lat": 18.0958,
                "lng": 76.8881
            },
            "Arni ka Khera": {
                "lat": 24.9,
                "lng": 74.33
            },
            "Pramatam": {
                "lat": 9.2667,
                "lng": 76.7333
            },
            "\u0100rutla": {
                "lat": 17.1346,
                "lng": 78.7574
            },
            "Talen": {
                "lat": 23.5695,
                "lng": 76.7282
            },
            "Doran\u0101la": {
                "lat": 15.9,
                "lng": 79.1
            },
            "Mugdampalli": {
                "lat": 17.6167,
                "lng": 77.4974
            },
            "Husepur": {
                "lat": 26.4609,
                "lng": 84.1699
            },
            "Tundhul": {
                "lat": 23.3247,
                "lng": 85.2447
            },
            "Kaviti": {
                "lat": 19.0167,
                "lng": 84.6833
            },
            "P\u0101ta Putrela": {
                "lat": 17.0173,
                "lng": 80.7137
            },
            "Siruv\u0101chch\u016br": {
                "lat": 11.638,
                "lng": 78.7557
            },
            "Minn\u0101l": {
                "lat": 13.0744,
                "lng": 79.559
            },
            "R\u0101singapuram": {
                "lat": 9.9448,
                "lng": 77.3362
            },
            "Hathauri": {
                "lat": 25.9593,
                "lng": 85.9747
            },
            "Marauatpur": {
                "lat": 25.6165,
                "lng": 85.4685
            },
            "M\u0101lthone": {
                "lat": 24.3055,
                "lng": 78.5283
            },
            "Tetagunta": {
                "lat": 17.314,
                "lng": 82.443
            },
            "T\u0101ran": {
                "lat": 26.157,
                "lng": 87.543
            },
            "Saksoh\u0101ra": {
                "lat": 25.3635,
                "lng": 85.7013
            },
            "Vir\u0101lippatti": {
                "lat": 10.1081,
                "lng": 77.7257
            },
            "Aw\u0101nkh": {
                "lat": 32.1392,
                "lng": 75.4611
            },
            "Karaund": {
                "lat": 25.9741,
                "lng": 86.2343
            },
            "Adalaj": {
                "lat": 23.17,
                "lng": 72.58
            },
            "Pud\u016br": {
                "lat": 11.296,
                "lng": 77.7478
            },
            "Nor\u012ba": {
                "lat": 32.521,
                "lng": 74.7984
            },
            "Vijay\u0101pati": {
                "lat": 8.1913,
                "lng": 77.7476
            },
            "Jaday\u0101mp\u0101laiyam": {
                "lat": 11.293,
                "lng": 76.9495
            },
            "Sondho Dull\u0101h": {
                "lat": 25.9016,
                "lng": 85.3569
            },
            "Kon\u0101je": {
                "lat": 12.8162,
                "lng": 74.9021
            },
            "Amar\u0101puuram": {
                "lat": 14.1333,
                "lng": 76.9833
            },
            "Umri": {
                "lat": 26.5106,
                "lng": 78.9367
            },
            "Khem Karan": {
                "lat": 31.145,
                "lng": 74.545
            },
            "Parappukara": {
                "lat": 10.4064,
                "lng": 76.2657
            },
            "Dhanauri": {
                "lat": 29.7833,
                "lng": 76.1833
            },
            "Chaital": {
                "lat": 22.5106,
                "lng": 88.7996
            },
            "Pedda Adsarlapalli": {
                "lat": 16.7086,
                "lng": 79.0286
            },
            "Mullurkara": {
                "lat": 10.7,
                "lng": 76.2667
            },
            "Mandasa": {
                "lat": 18.8683,
                "lng": 84.463
            },
            "Bilauri": {
                "lat": 25.7703,
                "lng": 87.5163
            },
            "H\u0101tod": {
                "lat": 22.7938,
                "lng": 75.7393
            },
            "Paramankurichi": {
                "lat": 8.4785,
                "lng": 78.0465
            },
            "Edattala": {
                "lat": 9.8678,
                "lng": 76.4361
            },
            "Bairo": {
                "lat": 26.0144,
                "lng": 86.51
            },
            "Kotla": {
                "lat": 32.7522,
                "lng": 74.747
            },
            "Tanakallu": {
                "lat": 13.92,
                "lng": 78.18
            },
            "Kirikera": {
                "lat": 13.769,
                "lng": 77.4841
            },
            "Mamarappatti": {
                "lat": 11.4845,
                "lng": 78.0374
            },
            "Ch\u0101ndpura": {
                "lat": 25.4966,
                "lng": 86.2104
            },
            "Amb\u0101tturai": {
                "lat": 10.2603,
                "lng": 77.9303
            },
            "Ajjanahalli": {
                "lat": 12.0376,
                "lng": 77.8138
            },
            "Chinna Oramp\u0101du": {
                "lat": 14.0613,
                "lng": 79.2671
            },
            "Bhansia": {
                "lat": 26.0992,
                "lng": 87.5316
            },
            "Enamadala": {
                "lat": 16.2281,
                "lng": 80.3306
            },
            "Gundugolanu": {
                "lat": 16.7833,
                "lng": 81.2333
            },
            "Shamshernagar": {
                "lat": 25.0862,
                "lng": 84.4497
            },
            "Pakra": {
                "lat": 25.3711,
                "lng": 87.0807
            },
            "Mayil\u0101di": {
                "lat": 8.155,
                "lng": 77.5056
            },
            "Kaml\u0101pur": {
                "lat": 17.5786,
                "lng": 76.9887
            },
            "Puliyampatti": {
                "lat": 11.6653,
                "lng": 77.8041
            },
            "Mehs\u0101ri": {
                "lat": 25.7554,
                "lng": 85.8477
            },
            "Rudr\u0101ngi": {
                "lat": 18.6262,
                "lng": 78.7022
            },
            "Mer\u0101l": {
                "lat": 24.1876,
                "lng": 83.7076
            },
            "Malar": {
                "lat": 26.2237,
                "lng": 86.6909
            },
            "Khajuri": {
                "lat": 26.5216,
                "lng": 84.3391
            },
            "Chainpur": {
                "lat": 25.0345,
                "lng": 83.5066
            },
            "\u0100vadatt\u016br": {
                "lat": 11.7014,
                "lng": 77.8638
            },
            "Pedda Vegi": {
                "lat": 16.7708,
                "lng": 81.1039
            },
            "Dendul\u016bru": {
                "lat": 16.4527,
                "lng": 81.0951
            },
            "Vellall\u016br": {
                "lat": 8.7639,
                "lng": 76.8345
            },
            "B\u012bb\u012bpet": {
                "lat": 18.2101,
                "lng": 78.5221
            },
            "K\u0101lipatnam": {
                "lat": 16.3904,
                "lng": 81.5295
            },
            "Angalakurichchi": {
                "lat": 10.5282,
                "lng": 76.9962
            },
            "Chero": {
                "lat": 25.2463,
                "lng": 85.8169
            },
            "Lahra Muhabbat": {
                "lat": 30.2421,
                "lng": 75.1666
            },
            "Gu\u0101b\u0101ri": {
                "lat": 26.2262,
                "lng": 87.9181
            },
            "It\u0101m\u0101ti": {
                "lat": 20.1333,
                "lng": 85.15
            },
            "Sirsia Hanum\u0101nganj": {
                "lat": 26.1381,
                "lng": 87.1133
            },
            "Bataredh": {
                "lat": 26.425,
                "lng": 84.6061
            },
            "Premnagar": {
                "lat": 30.3331,
                "lng": 77.9608
            },
            "Korahia": {
                "lat": 26.5325,
                "lng": 86.1552
            },
            "Ras\u016blpur": {
                "lat": 25.9938,
                "lng": 85.8935
            },
            "Barokhar": {
                "lat": 24.9167,
                "lng": 81.9667
            },
            "Wargal": {
                "lat": 17.7751,
                "lng": 78.6181
            },
            "T\u012bkar": {
                "lat": 24.4194,
                "lng": 81.3776
            },
            "Holalu": {
                "lat": 14.8406,
                "lng": 75.7144
            },
            "K\u0101kal\u016br": {
                "lat": 13.1394,
                "lng": 79.9287
            },
            "Thogapalle": {
                "lat": 17.1279,
                "lng": 80.6355
            },
            "Beladi": {
                "lat": 13.1464,
                "lng": 74.9399
            },
            "Sr\u012br\u0101mpuram": {
                "lat": 10.4346,
                "lng": 77.8075
            },
            "Bikn\u016br": {
                "lat": 18.215,
                "lng": 78.4367
            },
            "Vegarai": {
                "lat": 11.0903,
                "lng": 77.9679
            },
            "Roda": {
                "lat": 30.682,
                "lng": 75.0195
            },
            "Sahasmal": {
                "lat": 26.2461,
                "lng": 87.4983
            },
            "Kh\u0101ns\u0101hibpuram": {
                "lat": 9.6304,
                "lng": 77.6028
            },
            "All\u016br": {
                "lat": 14.68,
                "lng": 80.06
            },
            "S\u012bp\u0101lakottai": {
                "lat": 9.8493,
                "lng": 77.4517
            },
            "Kotha Gur\u016b": {
                "lat": 30.4419,
                "lng": 75.0948
            },
            "Owk": {
                "lat": 15.2167,
                "lng": 78.1167
            },
            "Jaitw\u0101r": {
                "lat": 24.732,
                "lng": 80.8602
            },
            "Kornep\u0101du": {
                "lat": 16.2444,
                "lng": 80.3857
            },
            "Annak\u0101ttum\u016bla": {
                "lat": 9.8575,
                "lng": 76.4009
            },
            "Pastp\u0101r": {
                "lat": 25.8275,
                "lng": 86.8327
            },
            "Sher Muhammadpuram": {
                "lat": 18.2997,
                "lng": 83.8331
            },
            "Agadi": {
                "lat": 14.819,
                "lng": 75.469
            },
            "Tiruv\u0101d\u0101nai": {
                "lat": 9.7841,
                "lng": 78.9172
            },
            "R\u0101j\u0101ram": {
                "lat": 18.987,
                "lng": 79.0844
            },
            "Padinj\u0101remuri": {
                "lat": 10.2661,
                "lng": 76.1461
            },
            "Murukondap\u0101du": {
                "lat": 15.9174,
                "lng": 80.4131
            },
            "Abhia": {
                "lat": 25.3499,
                "lng": 87.1427
            },
            "Chin\u0101val": {
                "lat": 21.1964,
                "lng": 75.9261
            },
            "Konand": {
                "lat": 25.1729,
                "lng": 85.6842
            },
            "Ch\u0101lt\u0101b\u0101ria": {
                "lat": 22.2347,
                "lng": 88.5014
            },
            "Vengavasal": {
                "lat": 12.8991,
                "lng": 80.169
            },
            "Chintapalle": {
                "lat": 17.8667,
                "lng": 82.35
            },
            "Din\u0101ra": {
                "lat": 25.4613,
                "lng": 78.3205
            },
            "Hardiy\u0101": {
                "lat": 26.9443,
                "lng": 84.8542
            },
            "Cossimb\u0101z\u0101r": {
                "lat": 24.12,
                "lng": 88.28
            },
            "Baghauni": {
                "lat": 25.9338,
                "lng": 86.0594
            },
            "Nellipoyil": {
                "lat": 11.4403,
                "lng": 76.0406
            },
            "Ch\u016bndal": {
                "lat": 10.6175,
                "lng": 76.0947
            },
            "Auta": {
                "lat": 25.3882,
                "lng": 85.9558
            },
            "Nandimandalam": {
                "lat": 14.4052,
                "lng": 78.5246
            },
            "Sindalakkundu": {
                "lat": 10.3665,
                "lng": 77.9046
            },
            "Bith\u0101n": {
                "lat": 25.6954,
                "lng": 86.2789
            },
            "Semri": {
                "lat": 22.6833,
                "lng": 78.0833
            },
            "Rattihalli": {
                "lat": 14.4167,
                "lng": 75.5
            },
            "Chakai": {
                "lat": 26.0884,
                "lng": 87.6154
            },
            "Sengurichchi": {
                "lat": 10.3756,
                "lng": 78.1658
            },
            "\u016ajh\u0101na": {
                "lat": 29.7155,
                "lng": 76.1341
            },
            "Olhanpur": {
                "lat": 25.8855,
                "lng": 84.8186
            },
            "Tibbar": {
                "lat": 31.9697,
                "lng": 75.4159
            },
            "Vell\u0101r": {
                "lat": 11.8938,
                "lng": 77.9698
            },
            "Bark\u0101gaon": {
                "lat": 23.8651,
                "lng": 85.2167
            },
            "Mali\u0101l": {
                "lat": 18.7,
                "lng": 78.9667
            },
            "Mallikkundam": {
                "lat": 11.8715,
                "lng": 77.9227
            },
            "Venkidanga": {
                "lat": 10.5167,
                "lng": 76.0833
            },
            "Tirodi": {
                "lat": 21.6852,
                "lng": 79.7191
            },
            "Sothgaon": {
                "lat": 26.6046,
                "lng": 85.9473
            },
            "Vilandai": {
                "lat": 11.3267,
                "lng": 79.3856
            },
            "Bok\u0101kh\u0101t": {
                "lat": 26.6402,
                "lng": 93.6005
            },
            "Talak\u0101d": {
                "lat": 12.1887,
                "lng": 77.0289
            },
            "Kaut\u0101lam": {
                "lat": 15.7711,
                "lng": 77.1239
            },
            "Keol\u0101ri": {
                "lat": 22.3697,
                "lng": 79.906
            },
            "Nellip\u0101ka": {
                "lat": 17.7679,
                "lng": 80.8567
            },
            "K\u0101ranchedu": {
                "lat": 15.8823,
                "lng": 80.317
            },
            "Bayyanag\u016bdem": {
                "lat": 17.125,
                "lng": 81.3628
            },
            "Elavalli": {
                "lat": 10.5667,
                "lng": 76.0833
            },
            "Chitt\u0101ttukara": {
                "lat": 10.5667,
                "lng": 76.0833
            },
            "Naras\u0101puram": {
                "lat": 17.1016,
                "lng": 81.0765
            },
            "Bagaura": {
                "lat": 26.0388,
                "lng": 84.4624
            },
            "Bhainsahi": {
                "lat": 26.4404,
                "lng": 84.5443
            },
            "Nandavaram": {
                "lat": 16.017,
                "lng": 77.531
            },
            "Panamaram": {
                "lat": 11.739,
                "lng": 76.073
            },
            "Sukurhutu": {
                "lat": 23.4433,
                "lng": 85.2917
            },
            "Lakri": {
                "lat": 26.2323,
                "lng": 84.6933
            },
            "Samesi": {
                "lat": 26.6145,
                "lng": 81.1017
            },
            "K\u0101ttakamp\u0101la": {
                "lat": 10.6833,
                "lng": 76.0333
            },
            "Is\u0101pur": {
                "lat": 25.4122,
                "lng": 86.9893
            },
            "Kizhakkanela": {
                "lat": 8.652,
                "lng": 76.8701
            },
            "Dhanaura": {
                "lat": 25.1905,
                "lng": 87.2595
            },
            "Kunkalagunta": {
                "lat": 16.2969,
                "lng": 79.8866
            },
            "Son\u0101da": {
                "lat": 26.962,
                "lng": 88.2684
            },
            "Karmauli": {
                "lat": 26.4502,
                "lng": 86.1033
            },
            "Siur": {
                "lat": 24.8166,
                "lng": 85.6728
            },
            "Dammapeta": {
                "lat": 17.2667,
                "lng": 81.0167
            },
            "Pongode": {
                "lat": 16.9246,
                "lng": 79.7343
            },
            "Kallakkudi": {
                "lat": 10.9767,
                "lng": 78.9525
            },
            "Naw\u0101 Nagar Niz\u0101mat": {
                "lat": 26.2895,
                "lng": 84.9351
            },
            "M\u0101hta": {
                "lat": 31.6647,
                "lng": 75.2509
            },
            "Kotra": {
                "lat": 22.7062,
                "lng": 88.5411
            },
            "Baghambarpur": {
                "lat": 26.8075,
                "lng": 84.4059
            },
            "Betmangala": {
                "lat": 13.0085,
                "lng": 78.3282
            },
            "R\u0101yavaram": {
                "lat": 17.3608,
                "lng": 81.753
            },
            "Sew\u0101i": {
                "lat": 23.6175,
                "lng": 85.6783
            },
            "Koln\u0101d": {
                "lat": 12.9,
                "lng": 75.03
            },
            "Devipattinam": {
                "lat": 9.477,
                "lng": 78.8961
            },
            "\u0100d\u016bru": {
                "lat": 12.5583,
                "lng": 75.1357
            },
            "Ren\u0101pur": {
                "lat": 18.5167,
                "lng": 76.6
            },
            "Guraahai": {
                "lat": 33.6449,
                "lng": 74.2301
            },
            "Hals\u016br": {
                "lat": 18.0176,
                "lng": 77.0098
            },
            "Ankli": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Tummalacheruvu": {
                "lat": 17.7667,
                "lng": 80.8
            },
            "Khiml\u0101sa": {
                "lat": 24.2058,
                "lng": 78.3639
            },
            "Mor\u016br": {
                "lat": 11.4221,
                "lng": 77.8743
            },
            "Vadakkumb\u0101gam": {
                "lat": 10.1487,
                "lng": 76.4159
            },
            "Kurabalakota": {
                "lat": 13.65,
                "lng": 78.4833
            },
            "Yercaud": {
                "lat": 11.7794,
                "lng": 78.2034
            },
            "Gop\u0101lapuram": {
                "lat": 17.1007,
                "lng": 81.5406
            },
            "Na\u012bgarhi": {
                "lat": 24.7869,
                "lng": 81.7787
            },
            "Jethuli": {
                "lat": 25.5378,
                "lng": 85.2841
            },
            "Bah\u0101darpur": {
                "lat": 21.2922,
                "lng": 76.1806
            },
            "Pettaiv\u0101yttalai": {
                "lat": 10.9014,
                "lng": 78.494
            },
            "J\u012bdigunta": {
                "lat": 16.9098,
                "lng": 81.8553
            },
            "Ghal Kal\u0101n": {
                "lat": 30.8189,
                "lng": 75.1063
            },
            "Kushmanchi": {
                "lat": 17.2263,
                "lng": 79.9668
            },
            "Sathmalpur": {
                "lat": 25.8749,
                "lng": 85.8567
            },
            "Khandpara": {
                "lat": 20.2644,
                "lng": 85.1767
            },
            "Gulgam": {
                "lat": 34.55,
                "lng": 74.2173
            },
            "Le\u0101ma": {
                "lat": 26.214,
                "lng": 85.998
            },
            "Perumbakkam": {
                "lat": 13.0133,
                "lng": 80.143
            },
            "Tadapuramb\u0101kkam": {
                "lat": 13.3205,
                "lng": 80.1959
            },
            "Kros\u016bru": {
                "lat": 16.55,
                "lng": 80.1331
            },
            "Patera": {
                "lat": 23.996,
                "lng": 79.6837
            },
            "Ghambiraopet": {
                "lat": 18.3,
                "lng": 78.5833
            },
            "Chintalav\u0101di": {
                "lat": 10.9511,
                "lng": 78.3158
            },
            "Labbaikkudikk\u0101du": {
                "lat": 11.3922,
                "lng": 79.0218
            },
            "Dharm\u0101ram": {
                "lat": 18.736,
                "lng": 79.2194
            },
            "Mangalkot": {
                "lat": 23.5252,
                "lng": 87.9034
            },
            "N\u0101lw\u0101r": {
                "lat": 16.9436,
                "lng": 76.9919
            },
            "Kantilo": {
                "lat": 20.3615,
                "lng": 85.1921
            },
            "Doda": {
                "lat": 30.3844,
                "lng": 74.6394
            },
            "Sunkarevu": {
                "lat": 16.39,
                "lng": 81.85
            },
            "B\u0101h\u0101galpur": {
                "lat": 24.5934,
                "lng": 87.9536
            },
            "Ichh\u0101pur": {
                "lat": 21.1551,
                "lng": 76.1556
            },
            "Moparip\u0101laiyam": {
                "lat": 11.1332,
                "lng": 77.147
            },
            "Nandipeta": {
                "lat": 18.9622,
                "lng": 78.1772
            },
            "Budha Th\u0113h": {
                "lat": 31.5177,
                "lng": 75.3009
            },
            "Golet": {
                "lat": 19.239,
                "lng": 79.3891
            },
            "N\u012bd\u0101mangalam": {
                "lat": 10.772,
                "lng": 79.4167
            },
            "Sahri": {
                "lat": 25.4721,
                "lng": 85.7277
            },
            "Ingurti": {
                "lat": 17.6695,
                "lng": 79.8003
            },
            "Doddappan\u0101yakkan\u016br": {
                "lat": 9.981,
                "lng": 77.734
            },
            "Sh\u0101hpur Baghauni": {
                "lat": 25.8831,
                "lng": 85.6859
            },
            "Kundurpi": {
                "lat": 14.2833,
                "lng": 77.0333
            },
            "Raun": {
                "lat": 26.3431,
                "lng": 78.9443
            },
            "Desanagi": {
                "lat": 16.92,
                "lng": 76.24
            },
            "Mirik": {
                "lat": 26.887,
                "lng": 88.187
            },
            "Orchha": {
                "lat": 25.35,
                "lng": 78.64
            },
            "P\u0101kkam": {
                "lat": 13.1436,
                "lng": 80.028
            },
            "Pipr\u0101hi": {
                "lat": 26.5871,
                "lng": 86.3773
            },
            "Devik\u0101puram": {
                "lat": 12.4744,
                "lng": 79.3475
            },
            "Baru\u0101ri": {
                "lat": 26.0305,
                "lng": 86.5965
            },
            "Sathi\u0101la": {
                "lat": 31.555,
                "lng": 75.2656
            },
            "B\u0101nk": {
                "lat": 24.9539,
                "lng": 84.1928
            },
            "Pering\u014dttukurusshi": {
                "lat": 10.7536,
                "lng": 76.4883
            },
            "Gaurihar Kh\u0101liqnagar": {
                "lat": 25.9356,
                "lng": 85.4671
            },
            "Fatehpur Sh\u0101hb\u0101z": {
                "lat": 25.5698,
                "lng": 85.3437
            },
            "Jamsaut": {
                "lat": 25.61,
                "lng": 85.0148
            },
            "P\u0101n\u0101pur Langa": {
                "lat": 25.723,
                "lng": 85.2878
            },
            "Satyavedu": {
                "lat": 13.437,
                "lng": 79.956
            },
            "Kavaratti": {
                "lat": 10.5626,
                "lng": 72.6369
            },
            "Vengikkal": {
                "lat": 12.2642,
                "lng": 79.0675
            },
            "K\u0101ri": {
                "lat": 24.8368,
                "lng": 78.8665
            },
            "Aras\u016br": {
                "lat": 11.0866,
                "lng": 77.1146
            },
            "Rupenaguntla": {
                "lat": 16.3043,
                "lng": 79.9991
            },
            "Shanmukhasundarapuram": {
                "lat": 10.0065,
                "lng": 77.5982
            },
            "Bhab\u0101nipur": {
                "lat": 24.7153,
                "lng": 87.9227
            },
            "Loran": {
                "lat": 33.8346,
                "lng": 74.3312
            },
            "Ninga": {
                "lat": 25.4616,
                "lng": 86.0757
            },
            "Kalakada": {
                "lat": 13.8167,
                "lng": 78.8
            },
            "Reng\u0101li": {
                "lat": 21.646,
                "lng": 84.0531
            },
            "Kh\u0101ng\u0101on": {
                "lat": 25.5146,
                "lng": 84.7528
            },
            "Maruttuvakkudi": {
                "lat": 11.0153,
                "lng": 79.4819
            },
            "Bur\u0101m": {
                "lat": 25.9739,
                "lng": 86.3196
            },
            "Chakwai": {
                "lat": 25.0543,
                "lng": 85.662
            },
            "Chitauria": {
                "lat": 25.4869,
                "lng": 87.5511
            },
            "Odatturai": {
                "lat": 11.4577,
                "lng": 77.515
            },
            "Baroni Khurd": {
                "lat": 25.6852,
                "lng": 78.3746
            },
            "Barahkurwa": {
                "lat": 26.146,
                "lng": 86.9237
            },
            "Gandh\u0101ri": {
                "lat": 18.3932,
                "lng": 78.1174
            },
            "Kumari": {
                "lat": 25.6564,
                "lng": 87.7112
            },
            "Pallipattu": {
                "lat": 13.3361,
                "lng": 79.4413
            },
            "Kinattukkadavu": {
                "lat": 10.8225,
                "lng": 77.0196
            },
            "Kondh\u0101li": {
                "lat": 21.1383,
                "lng": 78.6408
            },
            "Naliya": {
                "lat": 23.2611,
                "lng": 68.8267
            },
            "Mah\u0101gama": {
                "lat": 25.0342,
                "lng": 87.3187
            },
            "Pothia": {
                "lat": 25.5413,
                "lng": 87.2637
            },
            "Senduri\u0101": {
                "lat": 26.7469,
                "lng": 84.6754
            },
            "R\u0101ib\u0101ri Mahuawa": {
                "lat": 27.098,
                "lng": 84.2963
            },
            "Lopon": {
                "lat": 30.6715,
                "lng": 75.3438
            },
            "Prat\u0101par\u0101mpuram": {
                "lat": 10.6741,
                "lng": 79.8512
            },
            "Gundr\u0101jukuppam": {
                "lat": 13.3406,
                "lng": 79.5687
            },
            "Y\u0101ll\u016bru": {
                "lat": 15.3063,
                "lng": 78.4559
            },
            "Simr\u0101hi": {
                "lat": 26.3135,
                "lng": 86.8454
            },
            "Falim\u0101ri": {
                "lat": 26.3856,
                "lng": 89.8233
            },
            "Anjuna": {
                "lat": 15.5833,
                "lng": 73.7333
            },
            "Konakondla": {
                "lat": 15.1053,
                "lng": 77.364
            },
            "Samayanall\u016br": {
                "lat": 9.9792,
                "lng": 78.0359
            },
            "Halav\u0101galu": {
                "lat": 14.7083,
                "lng": 75.7753
            },
            "Baghmaria": {
                "lat": 26.44,
                "lng": 86.43
            },
            "Harji": {
                "lat": 25.3,
                "lng": 72.85
            },
            "Bind": {
                "lat": 25.3035,
                "lng": 85.6869
            },
            "Sarj\u0101pur": {
                "lat": 12.86,
                "lng": 77.786
            },
            "S\u0101lamedu": {
                "lat": 11.9088,
                "lng": 79.4919
            },
            "P\u012br Maker": {
                "lat": 25.9622,
                "lng": 85.0257
            },
            "B\u0101nsb\u0101ri": {
                "lat": 26.1934,
                "lng": 87.4498
            },
            "Uchchangidurgam": {
                "lat": 14.5614,
                "lng": 76.0537
            },
            "Bhagwatpur": {
                "lat": 25.7484,
                "lng": 85.7016
            },
            "Biddupur": {
                "lat": 25.6464,
                "lng": 85.3203
            },
            "Parora": {
                "lat": 25.8022,
                "lng": 87.4032
            },
            "Baghra": {
                "lat": 25.5597,
                "lng": 85.5841
            },
            "\u0100tharga": {
                "lat": 16.9875,
                "lng": 75.8863
            },
            "Arumb\u0101kkam": {
                "lat": 13.5427,
                "lng": 80.069
            },
            "Meghraj": {
                "lat": 23.5,
                "lng": 73.5
            },
            "Jamhor": {
                "lat": 24.8486,
                "lng": 84.3258
            },
            "Pach\u0101hi": {
                "lat": 26.1932,
                "lng": 86.3597
            },
            "Manpaur": {
                "lat": 26.5081,
                "lng": 85.9478
            },
            "Phulpar\u0101s": {
                "lat": 26.3549,
                "lng": 86.4908
            },
            "Manappakkam": {
                "lat": 13.0108,
                "lng": 80.1686
            },
            "Kalinagar": {
                "lat": 22.4219,
                "lng": 88.8695
            },
            "Parvatgiri": {
                "lat": 17.7417,
                "lng": 79.7264
            },
            "Per\u0101mpuzha": {
                "lat": 12.1343,
                "lng": 75.3006
            },
            "Bargaon": {
                "lat": 23.1795,
                "lng": 84.6991
            },
            "Nalamb\u016br": {
                "lat": 13.0867,
                "lng": 80.1703
            },
            "Cherlagandlap\u0101lem": {
                "lat": 16.0069,
                "lng": 80.6117
            },
            "Kurgunta": {
                "lat": 17.2,
                "lng": 77.36
            },
            "Dharmaj": {
                "lat": 22.4167,
                "lng": 72.8
            },
            "Natshal": {
                "lat": 22.1957,
                "lng": 88.0272
            },
            "P\u0101pireddippatti": {
                "lat": 11.914,
                "lng": 78.3675
            },
            "Tiruvennanall\u016br": {
                "lat": 11.8589,
                "lng": 79.3664
            },
            "M\u0101shy\u0101l": {
                "lat": 17.3226,
                "lng": 76.2407
            },
            "B\u0101r\u012bgarh": {
                "lat": 25.2325,
                "lng": 80.0347
            },
            "Masinigudi": {
                "lat": 11.5683,
                "lng": 76.6409
            },
            "Varadar\u0101jampettai": {
                "lat": 11.3553,
                "lng": 79.4186
            },
            "T\u0101zhakudi": {
                "lat": 8.2348,
                "lng": 77.4533
            },
            "Edasseri": {
                "lat": 8.6654,
                "lng": 76.8459
            },
            "Ikhl\u0101spur": {
                "lat": 25.0589,
                "lng": 83.6083
            },
            "Periya Soragai": {
                "lat": 11.7394,
                "lng": 77.917
            },
            "Singura": {
                "lat": 24.7928,
                "lng": 92.951
            },
            "Panpuli": {
                "lat": 9.0214,
                "lng": 77.2502
            },
            "Tikota": {
                "lat": 16.76,
                "lng": 75.62
            },
            "Meru": {
                "lat": 24.0097,
                "lng": 85.4673
            },
            "Pipariya": {
                "lat": 25.2593,
                "lng": 86.1149
            },
            "Somav\u0101rappatti": {
                "lat": 10.6779,
                "lng": 77.2131
            },
            "Kanh\u0101ipur": {
                "lat": 25.4542,
                "lng": 85.8396
            },
            "Bhilavadi": {
                "lat": 16.8586,
                "lng": 74.3317
            },
            "Iskap\u0101lem": {
                "lat": 14.5416,
                "lng": 79.8925
            },
            "Sinor": {
                "lat": 22.0,
                "lng": 73.3
            },
            "Asthal Bohar": {
                "lat": 28.873,
                "lng": 76.642
            },
            "Samahuta": {
                "lat": 25.8542,
                "lng": 84.6614
            },
            "B\u0101n\u0101var": {
                "lat": 13.4167,
                "lng": 76.1667
            },
            "S\u012brpanandal": {
                "lat": 11.9741,
                "lng": 79.0428
            },
            "Bara Belun": {
                "lat": 23.4007,
                "lng": 87.9733
            },
            "T\u0101mba": {
                "lat": 17.0001,
                "lng": 75.9934
            },
            "Dati\u0101na": {
                "lat": 25.4834,
                "lng": 84.8628
            },
            "Kov\u016brupalli": {
                "lat": 14.7772,
                "lng": 79.9999
            },
            "J\u0101dopur Shukul": {
                "lat": 26.525,
                "lng": 84.4341
            },
            "N\u0101r\u0101yanavanam": {
                "lat": 13.42,
                "lng": 79.58
            },
            "Deodha": {
                "lat": 25.7815,
                "lng": 86.1346
            },
            "Malkan\u016br": {
                "lat": 18.0821,
                "lng": 79.3628
            },
            "Sahsaul": {
                "lat": 25.7024,
                "lng": 86.7758
            },
            "Makhm\u0101lpur": {
                "lat": 25.287,
                "lng": 87.5469
            },
            "Eran\u0101puram": {
                "lat": 11.5581,
                "lng": 77.9893
            },
            "Chintakunta": {
                "lat": 14.6476,
                "lng": 78.469
            },
            "Bramhabarada": {
                "lat": 20.6683,
                "lng": 86.0747
            },
            "Bard\u012bha Turki": {
                "lat": 25.8059,
                "lng": 85.5441
            },
            "Attippattu": {
                "lat": 13.2633,
                "lng": 80.2911
            },
            "Gethaura": {
                "lat": 25.6879,
                "lng": 87.7092
            },
            "Kishunpur": {
                "lat": 25.7947,
                "lng": 86.8237
            },
            "Bora": {
                "lat": 23.6585,
                "lng": 76.8112
            },
            "Rahi\u0101r Kunchi": {
                "lat": 25.8627,
                "lng": 86.0924
            },
            "Amarchinta": {
                "lat": 16.374,
                "lng": 77.7729
            },
            "Jagd\u012bshpur": {
                "lat": 26.1526,
                "lng": 86.1146
            },
            "Belauncha": {
                "lat": 26.2124,
                "lng": 86.3554
            },
            "Amala": {
                "lat": 9.859,
                "lng": 76.8112
            },
            "Mannarai": {
                "lat": 11.1172,
                "lng": 77.3726
            },
            "Dhakaich": {
                "lat": 25.5835,
                "lng": 84.1886
            },
            "Talupula": {
                "lat": 14.25,
                "lng": 78.2667
            },
            "R\u0101nti": {
                "lat": 26.3519,
                "lng": 86.0932
            },
            "Khargr\u0101m": {
                "lat": 24.0343,
                "lng": 87.9853
            },
            "Tittachcheri": {
                "lat": 10.8674,
                "lng": 79.7893
            },
            "Kummarapurugup\u0101lem": {
                "lat": 16.3653,
                "lng": 81.6192
            },
            "Lathasepura": {
                "lat": 25.8131,
                "lng": 85.718
            },
            "Laukaria": {
                "lat": 26.7184,
                "lng": 84.4242
            },
            "B\u016bdili": {
                "lat": 13.9353,
                "lng": 77.7355
            },
            "Namli": {
                "lat": 23.4612,
                "lng": 75.0604
            },
            "Kalasa": {
                "lat": 13.234,
                "lng": 75.3553
            },
            "Sirgora": {
                "lat": 22.2063,
                "lng": 78.8832
            },
            "Chikni": {
                "lat": 26.0664,
                "lng": 87.4406
            },
            "Guntupalle": {
                "lat": 16.568,
                "lng": 80.548
            },
            "Damonojodi": {
                "lat": 18.7632,
                "lng": 82.9194
            },
            "\u0100ramb\u0101kkam": {
                "lat": 13.5258,
                "lng": 80.071
            },
            "Bucheya": {
                "lat": 26.3421,
                "lng": 84.6642
            },
            "Barka Gaon": {
                "lat": 26.144,
                "lng": 84.6598
            },
            "Parbata": {
                "lat": 25.3164,
                "lng": 86.72
            },
            "Malkhaid": {
                "lat": 17.195,
                "lng": 77.1608
            },
            "P\u016bngulam": {
                "lat": 12.5727,
                "lng": 78.753
            },
            "Harsinghpur": {
                "lat": 26.0504,
                "lng": 86.218
            },
            "Kawadgaon": {
                "lat": 17.91,
                "lng": 77.53
            },
            "Chikk\u0101ramp\u0101laiyam": {
                "lat": 11.2416,
                "lng": 76.9854
            },
            "Potukonda": {
                "lat": 17.2708,
                "lng": 81.754
            },
            "N\u0101gal\u0101puram": {
                "lat": 13.3889,
                "lng": 79.7967
            },
            "Rasaunk": {
                "lat": 25.5537,
                "lng": 86.5117
            },
            "Palukudoddi": {
                "lat": 15.8504,
                "lng": 77.6753
            },
            "Kumbad\u0101je": {
                "lat": 12.5667,
                "lng": 75.1
            },
            "Bhand\u0101rso": {
                "lat": 26.178,
                "lng": 86.1415
            },
            "Somn\u0101ha": {
                "lat": 25.9518,
                "lng": 85.7042
            },
            "Sihaul": {
                "lat": 25.9405,
                "lng": 86.512
            },
            "Sonb\u0101ri": {
                "lat": 24.2286,
                "lng": 80.7902
            },
            "May\u016breswar": {
                "lat": 23.9908,
                "lng": 87.7647
            },
            "Okhargara": {
                "lat": 24.2141,
                "lng": 83.7856
            },
            "Atru": {
                "lat": 24.81,
                "lng": 76.63
            },
            "Olaippatti": {
                "lat": 11.7676,
                "lng": 77.9927
            },
            "Sa\u0101datpur Agu\u0101ni": {
                "lat": 25.283,
                "lng": 86.7412
            },
            "Banagi": {
                "lat": 13.61,
                "lng": 75.53
            },
            "B\u0101lehonn\u016br": {
                "lat": 13.35,
                "lng": 75.46
            },
            "Pongal\u016br": {
                "lat": 10.9654,
                "lng": 77.372
            },
            "K\u012blkottai": {
                "lat": 10.2861,
                "lng": 77.916
            },
            "Kanamadi": {
                "lat": 16.9291,
                "lng": 75.3807
            },
            "Udawantnagar": {
                "lat": 25.5054,
                "lng": 84.6216
            },
            "Murap\u0101ka": {
                "lat": 18.2282,
                "lng": 83.7926
            },
            "Achankovil": {
                "lat": 8.9667,
                "lng": 77.1431
            },
            "Sheron": {
                "lat": 30.1582,
                "lng": 75.7178
            },
            "Kowdalli": {
                "lat": 12.067,
                "lng": 77.4438
            },
            "Kumharsan": {
                "lat": 25.6421,
                "lng": 86.1971
            },
            "Panhar": {
                "lat": 25.0936,
                "lng": 85.2176
            },
            "Mort\u0101d": {
                "lat": 18.8167,
                "lng": 78.4833
            },
            "Kachn\u0101r": {
                "lat": 25.9699,
                "lng": 84.3589
            },
            "Nawalpur": {
                "lat": 26.9366,
                "lng": 84.2985
            },
            "K\u0101ttupputt\u016br": {
                "lat": 10.9833,
                "lng": 78.2333
            },
            "Phulg\u0101chhi": {
                "lat": 26.3653,
                "lng": 87.9399
            },
            "Ikk\u0101du": {
                "lat": 13.1724,
                "lng": 79.9358
            },
            "Hatw\u0101ns": {
                "lat": 22.7683,
                "lng": 78.3464
            },
            "N\u0101gojanahalli": {
                "lat": 12.357,
                "lng": 78.2886
            },
            "Duggir\u0101la": {
                "lat": 16.3271,
                "lng": 80.628
            },
            "Yargatti": {
                "lat": 15.9667,
                "lng": 75.0167
            },
            "Bhattiprolu": {
                "lat": 16.1026,
                "lng": 80.7807
            },
            "Terku Valliy\u016br": {
                "lat": 8.357,
                "lng": 77.6087
            },
            "R\u0101man\u0101yakkanp\u0101laiyam": {
                "lat": 11.6291,
                "lng": 78.5624
            },
            "P\u0101ta Ellamilli": {
                "lat": 16.8473,
                "lng": 81.4884
            },
            "Basaithi": {
                "lat": 26.0284,
                "lng": 87.3329
            },
            "L\u0101lsaraia": {
                "lat": 26.7388,
                "lng": 84.6607
            },
            "Day\u0101lpur": {
                "lat": 26.0511,
                "lng": 84.5968
            },
            "Karebilachi": {
                "lat": 14.1449,
                "lng": 75.9032
            },
            "Hebri": {
                "lat": 13.4667,
                "lng": 74.9833
            },
            "Elch\u016bru": {
                "lat": 16.0813,
                "lng": 79.9269
            },
            "Shamunpet": {
                "lat": 17.9472,
                "lng": 79.6014
            },
            "Kaniyamb\u0101di": {
                "lat": 12.8118,
                "lng": 79.134
            },
            "P\u0113nagam": {
                "lat": 10.4522,
                "lng": 76.0972
            },
            "Dalippur": {
                "lat": 25.4222,
                "lng": 84.3933
            },
            "H\u0101thid\u0101h Buzurg": {
                "lat": 25.3716,
                "lng": 85.9857
            },
            "Bangramanj\u0113shvara": {
                "lat": 12.6833,
                "lng": 75.0
            },
            "Devanakavundan\u016br": {
                "lat": 11.5048,
                "lng": 77.8383
            },
            "Shankarpalli": {
                "lat": 17.4523,
                "lng": 78.131
            },
            "Chhabila": {
                "lat": 26.2556,
                "lng": 85.9933
            },
            "Kad\u016br S\u0101hib": {
                "lat": 31.4239,
                "lng": 75.0977
            },
            "Phar\u0101ha": {
                "lat": 24.8522,
                "lng": 85.5471
            },
            "Kisara": {
                "lat": 17.5233,
                "lng": 78.6677
            },
            "M\u0101dhopur": {
                "lat": 26.7474,
                "lng": 84.7054
            },
            "Muk\u0101si Pid\u0101riy\u016br": {
                "lat": 11.2069,
                "lng": 77.5922
            },
            "Puliyara": {
                "lat": 9.0041,
                "lng": 77.1889
            },
            "Sankhavaram": {
                "lat": 15.0497,
                "lng": 78.9836
            },
            "Sakkamapatti": {
                "lat": 9.925,
                "lng": 77.6039
            },
            "Konen Agrah\u0101r": {
                "lat": 12.9582,
                "lng": 77.6619
            },
            "Koch": {
                "lat": 24.9259,
                "lng": 84.7724
            },
            "Perali": {
                "lat": 15.886,
                "lng": 80.547
            },
            "Kenduad\u012bh": {
                "lat": 23.7757,
                "lng": 86.3761
            },
            "Murgod": {
                "lat": 15.78,
                "lng": 75.12
            },
            "Tonse West": {
                "lat": 13.3968,
                "lng": 74.7069
            },
            "Delv\u0101da": {
                "lat": 20.7759,
                "lng": 71.0465
            },
            "Tekkumb\u0101gam": {
                "lat": 10.119,
                "lng": 76.418
            },
            "Amha": {
                "lat": 26.0518,
                "lng": 86.6906
            },
            "Kongupatti": {
                "lat": 11.8593,
                "lng": 78.0217
            },
            "Tirumayam": {
                "lat": 10.2449,
                "lng": 78.7464
            },
            "Rolla": {
                "lat": 13.8331,
                "lng": 77.1
            },
            "All\u0101hdurg": {
                "lat": 17.9667,
                "lng": 77.9167
            },
            "Sarauni Kal\u0101n": {
                "lat": 25.7579,
                "lng": 86.9392
            },
            "W\u0101l\u016br": {
                "lat": 19.4872,
                "lng": 76.5561
            },
            "Chinna Annal\u016bru": {
                "lat": 14.8913,
                "lng": 79.6761
            },
            "Chauki": {
                "lat": 25.4573,
                "lng": 86.4018
            },
            "Edlap\u0101du": {
                "lat": 16.1007,
                "lng": 80.1339
            },
            "B\u0101gn\u0101n": {
                "lat": 22.47,
                "lng": 87.97
            },
            "Jagann\u0101thpur": {
                "lat": 22.2211,
                "lng": 85.6392
            },
            "Sr\u012bpur": {
                "lat": 25.5861,
                "lng": 86.0803
            },
            "Patori": {
                "lat": 25.9665,
                "lng": 86.5471
            },
            "Vengapalli": {
                "lat": 11.6333,
                "lng": 76.0167
            },
            "Dhilw\u0101n Kal\u0101n": {
                "lat": 30.5718,
                "lng": 74.8655
            },
            "Bardipuram": {
                "lat": 18.6431,
                "lng": 78.1584
            },
            "M\u0101nsinghpur Bijrauli": {
                "lat": 25.7552,
                "lng": 85.5099
            },
            "Nagar Nahusa": {
                "lat": 25.3959,
                "lng": 85.3431
            },
            "Honw\u0101da": {
                "lat": 16.7333,
                "lng": 77.9333
            },
            "Paura Madan Singh": {
                "lat": 25.7917,
                "lng": 85.2333
            },
            "Singoli": {
                "lat": 24.9667,
                "lng": 75.3
            },
            "Yelpur": {
                "lat": 18.7651,
                "lng": 78.3953
            },
            "Punjai Turaiy\u0101mp\u0101laiyam": {
                "lat": 11.5142,
                "lng": 77.4234
            },
            "Mallagunta": {
                "lat": 12.6343,
                "lng": 78.5194
            },
            "B\u0101w\u0101li": {
                "lat": 22.4041,
                "lng": 88.1852
            },
            "Champua": {
                "lat": 22.0667,
                "lng": 85.6658
            },
            "Bhangha": {
                "lat": 25.578,
                "lng": 87.2782
            },
            "R\u0101mabhadrapuram": {
                "lat": 18.5,
                "lng": 83.2833
            },
            "Veng\u016br": {
                "lat": 10.1413,
                "lng": 76.5495
            },
            "Masakkavundanchettip\u0101laiyam": {
                "lat": 11.1473,
                "lng": 77.0756
            },
            "M\u0113munda": {
                "lat": 11.6006,
                "lng": 75.6339
            },
            "Tiruppor\u016br": {
                "lat": 12.7259,
                "lng": 80.1895
            },
            "Bishunpur Hak\u012bm\u0101b\u0101d": {
                "lat": 25.8453,
                "lng": 85.8432
            },
            "Palsud": {
                "lat": 21.8262,
                "lng": 74.9691
            },
            "Devgeri": {
                "lat": 14.8512,
                "lng": 75.3705
            },
            "Satai": {
                "lat": 24.722,
                "lng": 79.631
            },
            "\u016attukkuli": {
                "lat": 11.1689,
                "lng": 77.4543
            },
            "Mahm\u016bda": {
                "lat": 25.0531,
                "lng": 85.2662
            },
            "Nao Kothi": {
                "lat": 25.5196,
                "lng": 86.1954
            },
            "Vadamugam Vellodu": {
                "lat": 11.2366,
                "lng": 77.6617
            },
            "Pottireddippatti": {
                "lat": 11.1659,
                "lng": 78.2708
            },
            "Nallamada": {
                "lat": 14.2164,
                "lng": 77.9944
            },
            "Kalavap\u016bdi": {
                "lat": 16.4623,
                "lng": 81.3887
            },
            "Pedappai": {
                "lat": 12.8854,
                "lng": 80.022
            },
            "N\u0101dendla": {
                "lat": 16.175,
                "lng": 80.1861
            },
            "Kab\u012brpur": {
                "lat": 26.2661,
                "lng": 84.205
            },
            "Kaniy\u016br": {
                "lat": 11.093,
                "lng": 77.156
            },
            "Ch\u0101oke": {
                "lat": 30.1847,
                "lng": 75.3362
            },
            "Swarna": {
                "lat": 15.8542,
                "lng": 80.2858
            },
            "Karakthal": {
                "lat": 23.2001,
                "lng": 71.7271
            },
            "Sisia": {
                "lat": 25.4539,
                "lng": 87.4549
            },
            "Chinnatad\u0101gam": {
                "lat": 11.0816,
                "lng": 76.8674
            },
            "Bari\u0101rpur K\u0101ndh": {
                "lat": 25.9391,
                "lng": 85.4674
            },
            "Basbiti": {
                "lat": 26.0939,
                "lng": 86.5289
            },
            "Madna": {
                "lat": 26.3963,
                "lng": 86.3884
            },
            "Kuttappatti": {
                "lat": 11.7939,
                "lng": 77.9225
            },
            "Sontha": {
                "lat": 26.1861,
                "lng": 87.7766
            },
            "Arniya": {
                "lat": 25.715,
                "lng": 85.5448
            },
            "Yairipok": {
                "lat": 24.6779,
                "lng": 94.0477
            },
            "Koilakh": {
                "lat": 26.3358,
                "lng": 86.201
            },
            "Kh\u0101njah\u0101npur": {
                "lat": 25.6055,
                "lng": 86.0927
            },
            "Sangr\u0101m": {
                "lat": 26.3141,
                "lng": 86.3515
            },
            "Karik\u0101d": {
                "lat": 10.6917,
                "lng": 76.0722
            },
            "Tarw\u0101ra": {
                "lat": 26.2007,
                "lng": 84.4874
            },
            "Kodmi\u0101l": {
                "lat": 18.6333,
                "lng": 78.9
            },
            "Kiratpur R\u0101j\u0101r\u0101m": {
                "lat": 25.856,
                "lng": 85.285
            },
            "Hardia": {
                "lat": 25.8657,
                "lng": 86.2176
            },
            "Ghanpur": {
                "lat": 17.4989,
                "lng": 78.2225
            },
            "R\u0101mbilli": {
                "lat": 17.4644,
                "lng": 82.9314
            },
            "Chand\u016br": {
                "lat": 16.98,
                "lng": 79.06
            },
            "Harri": {
                "lat": 26.3238,
                "lng": 86.2975
            },
            "Garkha": {
                "lat": 25.8293,
                "lng": 84.8617
            },
            "Sahoria Subhai": {
                "lat": 25.9028,
                "lng": 87.0421
            },
            "Pallarimangalam": {
                "lat": 10.0617,
                "lng": 77.059
            },
            "Kothri Kal\u0101n": {
                "lat": 23.0722,
                "lng": 76.8296
            },
            "P\u0101netha": {
                "lat": 21.92,
                "lng": 73.08
            },
            "R\u0101mchandarpur": {
                "lat": 25.2365,
                "lng": 86.1459
            },
            "Bist\u0101n": {
                "lat": 21.6979,
                "lng": 75.6657
            },
            "Tanipp\u0101di": {
                "lat": 12.1078,
                "lng": 78.834
            },
            "Amjhera": {
                "lat": 22.5578,
                "lng": 75.1185
            },
            "Mataili Khemchand": {
                "lat": 25.5612,
                "lng": 87.1685
            },
            "Nirpur": {
                "lat": 25.7192,
                "lng": 87.1507
            },
            "Ukal": {
                "lat": 21.2167,
                "lng": 73.5833
            },
            "Chirak": {
                "lat": 30.7206,
                "lng": 75.1718
            },
            "Mudki": {
                "lat": 30.78,
                "lng": 74.8811
            },
            "Anuppampattu": {
                "lat": 13.3018,
                "lng": 80.2258
            },
            "Tirupp\u0101laikudi": {
                "lat": 9.5461,
                "lng": 78.9172
            },
            "Venmani Padinj\u0101ra": {
                "lat": 9.4735,
                "lng": 76.5487
            },
            "P\u0101lakollu": {
                "lat": 16.5333,
                "lng": 81.7333
            },
            "P\u0101tras\u0101er": {
                "lat": 23.1968,
                "lng": 87.5272
            },
            "Yellayap\u0101lem": {
                "lat": 14.5378,
                "lng": 79.9515
            },
            "Pattanam": {
                "lat": 10.9808,
                "lng": 77.0542
            },
            "Ghagga": {
                "lat": 30.0198,
                "lng": 76.1041
            },
            "Kulgo": {
                "lat": 24.0154,
                "lng": 85.9743
            },
            "Varga\u016br": {
                "lat": 11.1452,
                "lng": 78.3477
            },
            "Pottipuram": {
                "lat": 9.9401,
                "lng": 77.3213
            },
            "Mutl\u016bru": {
                "lat": 16.15,
                "lng": 80.4833
            },
            "Jitw\u0101rpur Chauth": {
                "lat": 25.8499,
                "lng": 85.8074
            },
            "Jauli": {
                "lat": 29.4273,
                "lng": 77.8595
            },
            "R\u0101jgh\u0101t Garail": {
                "lat": 25.7618,
                "lng": 87.2126
            },
            "Gamharia": {
                "lat": 26.0561,
                "lng": 86.7243
            },
            "Kurw\u0101r": {
                "lat": 26.3475,
                "lng": 81.9787
            },
            "R\u0101mpur Kal\u0101n": {
                "lat": 26.1649,
                "lng": 77.4691
            },
            "B\u0101nu Chhapra": {
                "lat": 26.8098,
                "lng": 84.5201
            },
            "Kurikuppi": {
                "lat": 15.07,
                "lng": 76.55
            },
            "Sohw\u0101l": {
                "lat": 25.9261,
                "lng": 83.3628
            },
            "Hejam\u0101di": {
                "lat": 13.1062,
                "lng": 74.7839
            },
            "Alipur": {
                "lat": 18.8621,
                "lng": 78.8754
            },
            "Kaurih\u0101r": {
                "lat": 26.965,
                "lng": 84.8462
            },
            "B\u0101lupur": {
                "lat": 25.2611,
                "lng": 87.8947
            },
            "Chh\u0101t\u0101pur": {
                "lat": 26.2197,
                "lng": 87.0048
            },
            "Andhana": {
                "lat": 25.2574,
                "lng": 85.4548
            },
            "Ty\u0101magondal": {
                "lat": 13.2137,
                "lng": 77.301
            },
            "Darmah\u0101": {
                "lat": 26.3663,
                "lng": 84.9312
            },
            "P\u0101lamedu": {
                "lat": 10.105,
                "lng": 78.1134
            },
            "M\u0101ngobandar": {
                "lat": 24.8162,
                "lng": 86.2709
            },
            "Papraur": {
                "lat": 25.4413,
                "lng": 86.0378
            },
            "Muzaffarnagar": {
                "lat": 29.482,
                "lng": 77.7
            },
            "Zafar\u0101b\u0101d": {
                "lat": 25.7,
                "lng": 82.7
            },
            "Seon\u0101r": {
                "lat": 25.4142,
                "lng": 85.8834
            },
            "Marreddipalli": {
                "lat": 17.7767,
                "lng": 78.6016
            },
            "Paduma": {
                "lat": 26.5766,
                "lng": 86.2394
            },
            "Telkap": {
                "lat": 24.7048,
                "lng": 84.0093
            },
            "Kuppachchip\u0101laiyam": {
                "lat": 11.0273,
                "lng": 78.1131
            },
            "Kondakomarla": {
                "lat": 14.0678,
                "lng": 77.9561
            },
            "Ravutulap\u016bdi": {
                "lat": 17.3833,
                "lng": 82.3833
            },
            "\u0100navatti": {
                "lat": 14.5645,
                "lng": 75.1523
            },
            "S\u012btalpur": {
                "lat": 25.7764,
                "lng": 85.0283
            },
            "Nekarikallu": {
                "lat": 16.371,
                "lng": 79.943
            },
            "Chiramanang\u0101d": {
                "lat": 10.6819,
                "lng": 76.1142
            },
            "Nilav\u0101rappatti": {
                "lat": 11.6081,
                "lng": 78.1499
            },
            "Bhanghi": {
                "lat": 26.3612,
                "lng": 87.1826
            },
            "Nuvem": {
                "lat": 15.3174,
                "lng": 73.9387
            },
            "N\u0101ran\u0101puram": {
                "lat": 11.0254,
                "lng": 77.29
            },
            "N\u012brkunnam": {
                "lat": 9.35,
                "lng": 76.5167
            },
            "Akhn\u016br": {
                "lat": 32.87,
                "lng": 74.73
            },
            "Pipalraw\u0101n": {
                "lat": 23.1625,
                "lng": 76.4704
            },
            "Bhaur\u0101dah": {
                "lat": 26.252,
                "lng": 87.86
            },
            "Errahalli": {
                "lat": 12.3911,
                "lng": 78.2232
            },
            "Punjai K\u0101l\u0101mangalam": {
                "lat": 11.2322,
                "lng": 77.8236
            },
            "Mutuk\u016bru": {
                "lat": 16.3829,
                "lng": 79.4664
            },
            "Sarsai N\u0101war": {
                "lat": 26.96,
                "lng": 79.23
            },
            "K\u0101jhi Hridenagar": {
                "lat": 25.932,
                "lng": 87.2179
            },
            "Jhundo": {
                "lat": 24.7756,
                "lng": 86.2473
            },
            "Kuruvambalam": {
                "lat": 10.9375,
                "lng": 76.1639
            },
            "Rudra Nagar": {
                "lat": 24.3841,
                "lng": 87.884
            },
            "Lulhaul": {
                "lat": 25.8787,
                "lng": 86.1386
            },
            "S\u0101mbre": {
                "lat": 15.88,
                "lng": 74.56
            },
            "Odugatt\u016br": {
                "lat": 12.7679,
                "lng": 78.883
            },
            "Pragadavaram": {
                "lat": 17.0167,
                "lng": 81.0167
            },
            "K\u012blr\u0101jakular\u0101man": {
                "lat": 9.3969,
                "lng": 77.6344
            },
            "Moda": {
                "lat": 13.8246,
                "lng": 77.4421
            },
            "Sunadkuppi": {
                "lat": 16.14,
                "lng": 74.81
            },
            "Suh\u0101gi": {
                "lat": 23.2206,
                "lng": 79.9569
            },
            "Katt\u0101ri": {
                "lat": 11.4593,
                "lng": 77.7263
            },
            "Kuttattuppatti": {
                "lat": 10.3759,
                "lng": 77.8972
            },
            "Jaladurgam": {
                "lat": 15.284,
                "lng": 77.9001
            },
            "Kingri": {
                "lat": 25.6326,
                "lng": 86.6911
            },
            "Babhnoul": {
                "lat": 25.3202,
                "lng": 84.2128
            },
            "Komarolu": {
                "lat": 15.2667,
                "lng": 79.0
            },
            "Goasi": {
                "lat": 25.7643,
                "lng": 87.4155
            },
            "Karuvelampatti": {
                "lat": 9.8448,
                "lng": 78.0451
            },
            "P\u0101diy\u016br": {
                "lat": 10.4234,
                "lng": 78.0254
            },
            "Kum\u0101rav\u0101di": {
                "lat": 10.5264,
                "lng": 78.3118
            },
            "Chandreru": {
                "lat": 17.098,
                "lng": 81.9959
            },
            "Bh\u0101lpatti": {
                "lat": 26.1853,
                "lng": 86.0509
            },
            "R\u0101yb\u0101g": {
                "lat": 16.48,
                "lng": 74.78
            },
            "Vellav\u0101danparappu": {
                "lat": 11.1854,
                "lng": 77.8425
            },
            "Ker\u0101i": {
                "lat": 25.751,
                "lng": 85.8586
            },
            "Panamarattuppatti": {
                "lat": 11.562,
                "lng": 78.1649
            },
            "Maniamkulam": {
                "lat": 9.641,
                "lng": 76.644
            },
            "B\u0101zid Chak Kasturi": {
                "lat": 25.6559,
                "lng": 85.4412
            },
            "R\u0101j\u0101nagaram": {
                "lat": 17.0833,
                "lng": 81.9
            },
            "Sapahi": {
                "lat": 26.6517,
                "lng": 84.8526
            },
            "Alagappapuram": {
                "lat": 8.1468,
                "lng": 77.5413
            },
            "\u012amani": {
                "lat": 16.3302,
                "lng": 80.658
            },
            "Parnera": {
                "lat": 20.55,
                "lng": 72.95
            },
            "Tigr\u0101na": {
                "lat": 28.862,
                "lng": 76.137
            },
            "Thev\u016br": {
                "lat": 11.524,
                "lng": 77.7539
            },
            "Enumulapalle": {
                "lat": 14.1446,
                "lng": 77.8166
            },
            "Rewtith": {
                "lat": 26.2853,
                "lng": 84.7155
            },
            "Chitt\u0101rkottal": {
                "lat": 9.4276,
                "lng": 78.9015
            },
            "Sidhap Kal\u0101n": {
                "lat": 26.5456,
                "lng": 86.2346
            },
            "Galsi": {
                "lat": 23.3372,
                "lng": 87.6925
            },
            "Sangam": {
                "lat": 14.5956,
                "lng": 79.7428
            },
            "Milatt\u016br": {
                "lat": 10.8576,
                "lng": 79.2394
            },
            "Kurdi": {
                "lat": 16.0511,
                "lng": 77.2114
            },
            "Sangalbah\u012bta": {
                "lat": 25.3295,
                "lng": 87.3018
            },
            "Lachhm\u012bpur": {
                "lat": 25.5248,
                "lng": 87.3667
            },
            "Sahuli": {
                "lat": 26.1118,
                "lng": 84.3485
            },
            "Gairtganj": {
                "lat": 23.4102,
                "lng": 78.2208
            },
            "Jhundpura": {
                "lat": 26.3473,
                "lng": 77.501
            },
            "T\u0101lsur": {
                "lat": 25.3667,
                "lng": 87.8512
            },
            "Kattip\u016bdi": {
                "lat": 17.25,
                "lng": 82.3333
            },
            "Mathigeri": {
                "lat": 12.15,
                "lng": 77.1
            },
            "Bhaur": {
                "lat": 26.2751,
                "lng": 86.1552
            },
            "Dakhr\u0101m": {
                "lat": 26.0542,
                "lng": 86.128
            },
            "Kongan\u0101puram": {
                "lat": 11.571,
                "lng": 77.9004
            },
            "Hem\u0101vati": {
                "lat": 14.0232,
                "lng": 76.995
            },
            "It\u0101rhi": {
                "lat": 25.4832,
                "lng": 84.0129
            },
            "Singapperum\u0101lkovil": {
                "lat": 12.7595,
                "lng": 80.0075
            },
            "Katra": {
                "lat": 32.9917,
                "lng": 74.9319
            },
            "Besagarahalli": {
                "lat": 12.6333,
                "lng": 77.0
            },
            "Hosahalli": {
                "lat": 14.648,
                "lng": 76.4672
            },
            "K\u0101nk\u014dl": {
                "lat": 12.1556,
                "lng": 75.2238
            },
            "S\u0101lotgi": {
                "lat": 17.17,
                "lng": 75.96
            },
            "Chettimangurichchi": {
                "lat": 11.6393,
                "lng": 77.8557
            },
            "Puran\u0101ttukara": {
                "lat": 10.5528,
                "lng": 76.1613
            },
            "K\u0101la Di\u0101ra": {
                "lat": 25.5092,
                "lng": 85.448
            },
            "Malapannanagudi": {
                "lat": 15.28,
                "lng": 76.37
            },
            "Bharra": {
                "lat": 25.4398,
                "lng": 86.1624
            },
            "Teus": {
                "lat": 25.2493,
                "lng": 85.7141
            },
            "R\u0101m\u0101reddi": {
                "lat": 18.411,
                "lng": 78.3674
            },
            "Kadwa": {
                "lat": 25.0316,
                "lng": 84.0924
            },
            "Gudibanda": {
                "lat": 13.67,
                "lng": 77.7
            },
            "Gobardhanpur Kan\u0101p": {
                "lat": 25.0682,
                "lng": 84.5241
            },
            "Karadichitt\u016br": {
                "lat": 11.8289,
                "lng": 78.8758
            },
            "Am\u0101yan": {
                "lat": 26.3205,
                "lng": 78.7581
            },
            "Kumbh\u0101ri": {
                "lat": 21.2088,
                "lng": 83.6467
            },
            "Sirugudi": {
                "lat": 10.2627,
                "lng": 78.3088
            },
            "Maghra": {
                "lat": 25.1903,
                "lng": 85.4921
            },
            "Jiwachhpur": {
                "lat": 26.312,
                "lng": 87.0405
            },
            "Chinnamandem": {
                "lat": 13.9419,
                "lng": 78.6814
            },
            "P\u0101ma": {
                "lat": 25.8122,
                "lng": 86.8474
            },
            "Usupp\u016br": {
                "lat": 11.3815,
                "lng": 79.697
            },
            "Part\u0101p T\u0101nr": {
                "lat": 25.8897,
                "lng": 85.2551
            },
            "Kanur": {
                "lat": 17.0318,
                "lng": 75.7022
            },
            "Mittahalli": {
                "lat": 12.421,
                "lng": 78.1991
            },
            "Antardipa": {
                "lat": 24.6442,
                "lng": 87.9213
            },
            "Ghattu": {
                "lat": 13.6602,
                "lng": 78.3331
            },
            "Sabnima": {
                "lat": 25.4583,
                "lng": 85.567
            },
            "Munn\u016bru": {
                "lat": 12.8283,
                "lng": 74.8803
            },
            "Barharia": {
                "lat": 26.3191,
                "lng": 84.4547
            },
            "Aranda": {
                "lat": 26.085,
                "lng": 84.3601
            },
            "Bajpe": {
                "lat": 12.9803,
                "lng": 74.8832
            },
            "Khokri Kal\u0101n": {
                "lat": 30.8456,
                "lng": 75.3307
            },
            "Sokhodewara": {
                "lat": 24.8358,
                "lng": 85.8725
            },
            "Ghosw\u0101ri": {
                "lat": 25.4687,
                "lng": 85.4964
            },
            "Dasai": {
                "lat": 22.72,
                "lng": 75.1321
            },
            "Kabira": {
                "lat": 25.6897,
                "lng": 86.4852
            },
            "Kurhani": {
                "lat": 25.9803,
                "lng": 85.3374
            },
            "R\u0101nko": {
                "lat": 25.5181,
                "lng": 86.4899
            },
            "Vellip\u0101laiyam": {
                "lat": 11.3314,
                "lng": 76.9813
            },
            "Maida Babhang\u0101w\u0101n": {
                "lat": 25.4793,
                "lng": 86.0669
            },
            "Sonakhal": {
                "lat": 22.2125,
                "lng": 88.7133
            },
            "Kart\u0101l": {
                "lat": 16.9859,
                "lng": 78.5014
            },
            "Sir\u016br T\u0101jband": {
                "lat": 18.7,
                "lng": 76.9333
            },
            "Bodippatti": {
                "lat": 10.5642,
                "lng": 77.2336
            },
            "Sh\u0101mpur": {
                "lat": 23.418,
                "lng": 77.0867
            },
            "Tekk\u0101tt\u016br": {
                "lat": 10.2997,
                "lng": 78.7884
            },
            "Gajiginh\u0101lu": {
                "lat": 15.6969,
                "lng": 76.6647
            },
            "Kasavanampatti": {
                "lat": 10.3692,
                "lng": 77.8699
            },
            "P\u016bligunt\u0101": {
                "lat": 12.4395,
                "lng": 78.3625
            },
            "Kopp\u0101ka": {
                "lat": 16.7494,
                "lng": 81.0311
            },
            "Pachauth": {
                "lat": 25.576,
                "lng": 86.8083
            },
            "Loh\u0101rda": {
                "lat": 22.5918,
                "lng": 76.5957
            },
            "Darauli": {
                "lat": 26.0781,
                "lng": 84.1314
            },
            "Sihma": {
                "lat": 25.6992,
                "lng": 86.2446
            },
            "Paddhari": {
                "lat": 22.43,
                "lng": 70.6
            },
            "Mathila": {
                "lat": 25.4715,
                "lng": 84.1438
            },
            "Tarabha": {
                "lat": 20.7325,
                "lng": 83.6744
            },
            "Phaphot": {
                "lat": 25.6817,
                "lng": 86.0069
            },
            "Kulri\u0101n": {
                "lat": 29.7994,
                "lng": 75.6827
            },
            "Sambalhera": {
                "lat": 29.325,
                "lng": 77.9194
            },
            "Torpa": {
                "lat": 22.9361,
                "lng": 85.0889
            },
            "Madathapatti": {
                "lat": 9.1321,
                "lng": 77.4245
            },
            "Ar\u0101velli": {
                "lat": 18.719,
                "lng": 79.0262
            },
            "Chota Mollakh\u0101li": {
                "lat": 22.2222,
                "lng": 88.8956
            },
            "Jhonkar": {
                "lat": 23.2361,
                "lng": 76.1814
            },
            "Pallatt\u016br": {
                "lat": 10.1461,
                "lng": 78.8031
            },
            "N\u0101gambhotlap\u0101lem": {
                "lat": 15.6804,
                "lng": 79.8151
            },
            "K\u0101khandiki": {
                "lat": 16.601,
                "lng": 75.6405
            },
            "T\u0101marankottai": {
                "lat": 10.43,
                "lng": 79.32
            },
            "Garag": {
                "lat": 15.575,
                "lng": 74.9297
            },
            "Dhilwan": {
                "lat": 31.5143,
                "lng": 75.3457
            },
            "Tulshia": {
                "lat": 26.3468,
                "lng": 87.8411
            },
            "Karukkalv\u0101di": {
                "lat": 11.6714,
                "lng": 78.0126
            },
            "Sahuria": {
                "lat": 25.8197,
                "lng": 86.6132
            },
            "N\u0101hargarh": {
                "lat": 24.1692,
                "lng": 75.2342
            },
            "Buttar Khurd": {
                "lat": 30.725,
                "lng": 75.275
            },
            "Paitt\u016br": {
                "lat": 11.5356,
                "lng": 78.5749
            },
            "Per\u016br": {
                "lat": 10.9752,
                "lng": 76.9129
            },
            "Bagr\u012bnagar": {
                "lat": 25.9,
                "lng": 73.8
            },
            "Nallipp\u0101laiyam": {
                "lat": 11.2388,
                "lng": 78.1495
            },
            "Morsand": {
                "lat": 25.9335,
                "lng": 85.698
            },
            "Vemb\u016br": {
                "lat": 10.5893,
                "lng": 78.0709
            },
            "Balwa": {
                "lat": 29.406,
                "lng": 77.308
            },
            "S\u0101m\u0101khi\u0101li": {
                "lat": 23.3333,
                "lng": 70.5833
            },
            "Yedappalli": {
                "lat": 11.3792,
                "lng": 76.8108
            },
            "Bhop\u0101lia": {
                "lat": 22.2791,
                "lng": 73.7104
            },
            "Naini": {
                "lat": 25.832,
                "lng": 84.7179
            },
            "Adalpur": {
                "lat": 26.191,
                "lng": 86.0239
            },
            "Devanakonda": {
                "lat": 15.5333,
                "lng": 77.55
            },
            "Krishnamsettipalle": {
                "lat": 15.3695,
                "lng": 78.884
            },
            "Gudlavalleru": {
                "lat": 16.35,
                "lng": 81.05
            },
            "Duraisw\u0101mipuram": {
                "lat": 9.0661,
                "lng": 77.4517
            },
            "R\u0101jpur Kal\u0101n": {
                "lat": 25.6792,
                "lng": 84.1259
            },
            "Ambhua": {
                "lat": 24.5568,
                "lng": 87.8651
            },
            "Tiruvalam": {
                "lat": 12.9825,
                "lng": 79.2661
            },
            "Balua R\u0101mpur": {
                "lat": 26.7777,
                "lng": 84.428
            },
            "Bel\u0101o": {
                "lat": 25.1476,
                "lng": 85.7377
            },
            "Digh\u0101w\u0101ni": {
                "lat": 22.2061,
                "lng": 78.8135
            },
            "Wepangandla": {
                "lat": 16.1157,
                "lng": 78.1301
            },
            "Vaddepalli": {
                "lat": 15.9359,
                "lng": 77.8417
            },
            "Dhanw\u0101r": {
                "lat": 24.4107,
                "lng": 85.9818
            },
            "Vikrutam\u0101la": {
                "lat": 13.6206,
                "lng": 79.5642
            },
            "Burhia Dhanghatta": {
                "lat": 25.9004,
                "lng": 87.2852
            },
            "Kopparam": {
                "lat": 16.0841,
                "lng": 79.9874
            },
            "Lakhn\u0101": {
                "lat": 26.65,
                "lng": 79.15
            },
            "Valpoy": {
                "lat": 15.5324,
                "lng": 74.1367
            },
            "Bakwa": {
                "lat": 26.0601,
                "lng": 86.4124
            },
            "Saidapet": {
                "lat": 13.1434,
                "lng": 80.1912
            },
            "Koth": {
                "lat": 22.636,
                "lng": 72.3
            },
            "K\u0101mthi": {
                "lat": 21.2161,
                "lng": 79.1973
            },
            "B\u0101lia": {
                "lat": 24.2433,
                "lng": 75.8061
            },
            "Veng\u0101nell\u016br": {
                "lat": 10.7,
                "lng": 76.3333
            },
            "Valatt": {
                "lat": 11.7833,
                "lng": 75.9
            },
            "Nagtala": {
                "lat": 22.4667,
                "lng": 88.3833
            },
            "Chandera": {
                "lat": 25.0829,
                "lng": 78.9784
            },
            "M\u0101chalpur": {
                "lat": 24.1277,
                "lng": 76.3167
            },
            "Telsang": {
                "lat": 16.72,
                "lng": 75.06
            },
            "Kotharpettai": {
                "lat": 12.678,
                "lng": 78.6243
            },
            "Kod\u016bru": {
                "lat": 13.8692,
                "lng": 77.7298
            },
            "Banta": {
                "lat": 24.2323,
                "lng": 87.8749
            },
            "S\u0101\u012bnkhera": {
                "lat": 22.9589,
                "lng": 78.5782
            },
            "Velapp\u0101di": {
                "lat": 12.6499,
                "lng": 79.3151
            },
            "Eriyodu": {
                "lat": 10.5172,
                "lng": 78.0627
            },
            "Narkatpalli": {
                "lat": 17.203,
                "lng": 79.195
            },
            "Jh\u0101ua": {
                "lat": 25.7487,
                "lng": 84.924
            },
            "Tripur\u0101ntakam": {
                "lat": 16.0007,
                "lng": 79.4563
            },
            "Jigani": {
                "lat": 12.7861,
                "lng": 77.6385
            },
            "M\u0101tsavaram": {
                "lat": 16.6,
                "lng": 79.7333
            },
            "Gokinepalle": {
                "lat": 16.9228,
                "lng": 79.7827
            },
            "Telkapalli": {
                "lat": 16.45,
                "lng": 78.4667
            },
            "Gay\u0101spur": {
                "lat": 25.9217,
                "lng": 84.4399
            },
            "J\u0101lihalli": {
                "lat": 16.365,
                "lng": 76.7811
            },
            "P\u0101raippatti": {
                "lat": 10.3144,
                "lng": 77.8601
            },
            "Desuri": {
                "lat": 25.2833,
                "lng": 73.5667
            },
            "Nidam\u0101n\u016bru": {
                "lat": 16.5066,
                "lng": 80.7222
            },
            "Nalgora": {
                "lat": 22.0346,
                "lng": 88.4743
            },
            "M\u0101dhopur Haz\u0101ri": {
                "lat": 26.2623,
                "lng": 84.8995
            },
            "Phek": {
                "lat": 25.571,
                "lng": 94.301
            },
            "M\u0101nkur": {
                "lat": 23.4353,
                "lng": 87.5527
            },
            "Ghary\u0101la": {
                "lat": 31.2294,
                "lng": 74.7352
            },
            "Kottapuram": {
                "lat": 10.2958,
                "lng": 76.2431
            },
            "Kalgi": {
                "lat": 17.35,
                "lng": 77.15
            },
            "Bomm\u0101rbettu": {
                "lat": 13.339,
                "lng": 74.8784
            },
            "Momanpet": {
                "lat": 17.5175,
                "lng": 77.8981
            },
            "Ambalan\u016br": {
                "lat": 16.59,
                "lng": 75.96
            },
            "Peravali": {
                "lat": 15.2861,
                "lng": 77.4997
            },
            "Fakirtaki": {
                "lat": 22.3815,
                "lng": 88.7901
            },
            "Bassian": {
                "lat": 30.6559,
                "lng": 75.5389
            },
            "Phulm\u0101lik": {
                "lat": 25.4132,
                "lng": 86.4255
            },
            "Khawaspur": {
                "lat": 26.2331,
                "lng": 84.6388
            },
            "Sikti\u0101hi": {
                "lat": 26.4812,
                "lng": 86.4023
            },
            "Hirehadagalli": {
                "lat": 14.9267,
                "lng": 75.8322
            },
            "Rounia": {
                "lat": 25.5179,
                "lng": 87.3999
            },
            "Mallan": {
                "lat": 30.4015,
                "lng": 74.7365
            },
            "Vellat\u016bru": {
                "lat": 16.1209,
                "lng": 79.6508
            },
            "M\u0101ruteru": {
                "lat": 16.6237,
                "lng": 81.7394
            },
            "Srikrishnapur": {
                "lat": 22.9717,
                "lng": 88.0351
            },
            "Mokarrampur": {
                "lat": 26.2176,
                "lng": 86.0433
            },
            "Konkav\u0101ripalle": {
                "lat": 13.7125,
                "lng": 78.4861
            },
            "Cheruvann\u016br": {
                "lat": 11.8284,
                "lng": 75.7295
            },
            "Ganga S\u0101gar": {
                "lat": 21.6528,
                "lng": 88.0753
            },
            "Sonw\u0101n": {
                "lat": 25.6258,
                "lng": 86.2161
            },
            "Tirkha": {
                "lat": 26.129,
                "lng": 81.0343
            },
            "Oppatav\u0101di": {
                "lat": 12.569,
                "lng": 78.3992
            },
            "Ratnagiri": {
                "lat": 13.8111,
                "lng": 77.1301
            },
            "Babhniy\u0101w\u0101n": {
                "lat": 25.4943,
                "lng": 84.4815
            },
            "Arimalam": {
                "lat": 10.255,
                "lng": 78.884
            },
            "Harsola": {
                "lat": 22.5694,
                "lng": 75.8165
            },
            "Domb\u0101chcheri": {
                "lat": 9.9604,
                "lng": 77.3909
            },
            "Hari\u0101na": {
                "lat": 31.6351,
                "lng": 75.8389
            },
            "K\u0101mepalle": {
                "lat": 16.5191,
                "lng": 79.835
            },
            "Y\u0101davolu": {
                "lat": 17.0619,
                "lng": 81.4729
            },
            "\u0100zamnagar": {
                "lat": 25.5456,
                "lng": 87.8294
            },
            "Solim": {
                "lat": 15.6145,
                "lng": 73.7704
            },
            "Auhar Sheikh": {
                "lat": 26.7216,
                "lng": 84.5993
            },
            "Motibennur": {
                "lat": 14.715,
                "lng": 75.48
            },
            "Khiram": {
                "lat": 33.732,
                "lng": 75.16
            },
            "Lakkireddipalle": {
                "lat": 14.1667,
                "lng": 78.7
            },
            "Kalam\u016bla": {
                "lat": 11.1417,
                "lng": 76.3417
            },
            "N\u0101na": {
                "lat": 24.916,
                "lng": 73.133
            },
            "Shahar Telpa": {
                "lat": 25.133,
                "lng": 84.6533
            },
            "Ratauli": {
                "lat": 26.1861,
                "lng": 86.7104
            },
            "Balaungi": {
                "lat": 30.7306,
                "lng": 76.6937
            },
            "Z\u0101wal": {
                "lat": 24.9833,
                "lng": 72.7333
            },
            "Sr\u012bsailain": {
                "lat": 16.0833,
                "lng": 78.8667
            },
            "Simarw\u0101ra Durg\u0101pur": {
                "lat": 25.852,
                "lng": 85.4944
            },
            "Jankampet": {
                "lat": 18.7067,
                "lng": 78.0233
            },
            "Kandr\u0101w\u0101n": {
                "lat": 25.8882,
                "lng": 81.2618
            },
            "Mallapuram": {
                "lat": 9.828,
                "lng": 77.673
            },
            "Dora": {
                "lat": 22.1858,
                "lng": 88.5142
            },
            "Mehdauli": {
                "lat": 25.6561,
                "lng": 86.0257
            },
            "Meghaul": {
                "lat": 25.6561,
                "lng": 86.0257
            },
            "Tekk\u0113kara": {
                "lat": 9.7,
                "lng": 76.7833
            },
            "K\u012blminnal": {
                "lat": 12.9447,
                "lng": 79.2514
            },
            "Gauniv\u0101ripalle": {
                "lat": 13.9756,
                "lng": 77.8768
            },
            "Ull\u016br": {
                "lat": 10.9706,
                "lng": 79.4063
            },
            "Bhikkiwind Utt\u0101r": {
                "lat": 31.3453,
                "lng": 74.6914
            },
            "Mel Seval": {
                "lat": 8.6722,
                "lng": 77.6147
            },
            "Somireddipalle": {
                "lat": 14.8365,
                "lng": 78.9062
            },
            "M\u0101li": {
                "lat": 25.6549,
                "lng": 86.7275
            },
            "Reddipalle": {
                "lat": 14.1993,
                "lng": 78.0102
            },
            "Indalvai": {
                "lat": 18.5403,
                "lng": 78.2248
            },
            "Tettuppatti": {
                "lat": 10.3979,
                "lng": 77.8225
            },
            "Gohuma Bairia": {
                "lat": 26.3938,
                "lng": 86.3976
            },
            "Shahm\u012brpet": {
                "lat": 17.5947,
                "lng": 78.5749
            },
            "T\u0101rar": {
                "lat": 25.1821,
                "lng": 87.1671
            },
            "Barai": {
                "lat": 26.3717,
                "lng": 86.0134
            },
            "Nay\u0101nagar": {
                "lat": 25.7465,
                "lng": 86.1027
            },
            "Badru Kh\u0101n": {
                "lat": 30.2523,
                "lng": 75.7917
            },
            "Vairampatti": {
                "lat": 10.5515,
                "lng": 78.3022
            },
            "Maheshr\u0101m": {
                "lat": 25.2874,
                "lng": 87.4124
            },
            "Khair Kh\u0101n": {
                "lat": 26.2727,
                "lng": 87.3037
            },
            "Vellur": {
                "lat": 12.1667,
                "lng": 75.35
            },
            "K\u012bramangalam": {
                "lat": 10.2859,
                "lng": 79.0996
            },
            "App\u0101r\u0101opeta": {
                "lat": 16.8987,
                "lng": 81.5684
            },
            "Borgamp\u0101d": {
                "lat": 17.65,
                "lng": 80.8667
            },
            "P\u016bval\u016br": {
                "lat": 10.9003,
                "lng": 78.8308
            },
            "P\u0101rtiban\u016br": {
                "lat": 9.5855,
                "lng": 78.4545
            },
            "Raibh\u012br": {
                "lat": 25.9978,
                "lng": 86.8781
            },
            "J\u012btpur": {
                "lat": 26.8149,
                "lng": 84.9578
            },
            "Muttam": {
                "lat": 9.8378,
                "lng": 76.7126
            },
            "Potun\u016bru": {
                "lat": 16.7442,
                "lng": 81.2206
            },
            "Luathaha": {
                "lat": 26.6316,
                "lng": 84.9079
            },
            "\u0100hiro": {
                "lat": 24.9192,
                "lng": 87.1054
            },
            "Koila Dewa": {
                "lat": 26.4079,
                "lng": 84.1962
            },
            "Vadakku Ariyan\u0101yakipuram": {
                "lat": 8.7208,
                "lng": 77.545
            },
            "Multi": {
                "lat": 22.2847,
                "lng": 88.4053
            },
            "N\u0101gasamudram": {
                "lat": 15.0556,
                "lng": 77.5004
            },
            "Kandul\u0101puram": {
                "lat": 15.5976,
                "lng": 79.1365
            },
            "Mi\u0101ni": {
                "lat": 31.7092,
                "lng": 75.5661
            },
            "R\u0101mannapeta": {
                "lat": 17.2833,
                "lng": 79.0859
            },
            "Kottap\u0101lem": {
                "lat": 17.436,
                "lng": 82.495
            },
            "Bar\u0101gaon": {
                "lat": 24.5682,
                "lng": 79.0185
            },
            "Narasingam": {
                "lat": 9.9658,
                "lng": 78.1833
            },
            "Bar\u0101hi": {
                "lat": 25.9725,
                "lng": 86.7452
            },
            "Venkat\u0101puram": {
                "lat": 18.7652,
                "lng": 78.3933
            },
            "Amgachia": {
                "lat": 22.4156,
                "lng": 88.3051
            },
            "Angw\u0101li": {
                "lat": 23.7317,
                "lng": 85.9858
            },
            "Aliganj": {
                "lat": 24.9365,
                "lng": 85.925
            },
            "Kothi": {
                "lat": 24.7526,
                "lng": 80.7775
            },
            "Darwa": {
                "lat": 25.669,
                "lng": 85.6461
            },
            "Shirbadgi": {
                "lat": 15.9418,
                "lng": 75.7768
            },
            "Halg\u016br": {
                "lat": 12.4249,
                "lng": 77.227
            },
            "Surla": {
                "lat": 15.5333,
                "lng": 73.8333
            },
            "Jhakhra": {
                "lat": 25.7528,
                "lng": 85.7474
            },
            "P\u0101nchi": {
                "lat": 25.1123,
                "lng": 85.6321
            },
            "Jandi\u0101la": {
                "lat": 31.2157,
                "lng": 75.6218
            },
            "Ajjipuram": {
                "lat": 12.0457,
                "lng": 77.3532
            },
            "Pipra Naurangi\u0101": {
                "lat": 26.8591,
                "lng": 84.4328
            },
            "Bellamkonda": {
                "lat": 16.4922,
                "lng": 80.0089
            },
            "L\u0101bhgaon": {
                "lat": 25.5062,
                "lng": 86.3899
            },
            "Kat\u0101lpur": {
                "lat": 26.2255,
                "lng": 84.7608
            },
            "Roddam": {
                "lat": 14.1,
                "lng": 77.43
            },
            "J\u0101dupatti": {
                "lat": 26.1186,
                "lng": 86.3114
            },
            "Togamalai": {
                "lat": 10.7251,
                "lng": 78.4108
            },
            "V\u016btuk\u016bru": {
                "lat": 14.5778,
                "lng": 80.1423
            },
            "Narot Mehra": {
                "lat": 32.2673,
                "lng": 75.5647
            },
            "Valaparla": {
                "lat": 15.9167,
                "lng": 80.05
            },
            "Kilkunda": {
                "lat": 11.2569,
                "lng": 76.6697
            },
            "Tiruchchuli": {
                "lat": 9.5348,
                "lng": 78.2008
            },
            "Kanteru": {
                "lat": 16.3906,
                "lng": 80.5425
            },
            "Teln\u0101l": {
                "lat": 23.01,
                "lng": 73.03
            },
            "K\u0101za": {
                "lat": 16.3887,
                "lng": 80.5412
            },
            "Mungod": {
                "lat": 17.0667,
                "lng": 79.0667
            },
            "Periyanegamam": {
                "lat": 10.7432,
                "lng": 77.103
            },
            "Villas\u0101gar": {
                "lat": 18.4736,
                "lng": 78.9645
            },
            "Elatt\u016br": {
                "lat": 11.3866,
                "lng": 77.308
            },
            "Dhanur Kaly\u0101nw\u0101di": {
                "lat": 17.77,
                "lng": 77.14
            },
            "Sisw\u0101r": {
                "lat": 26.4292,
                "lng": 86.4586
            },
            "Haldipur": {
                "lat": 14.3333,
                "lng": 74.4333
            },
            "Rompicherla": {
                "lat": 13.7228,
                "lng": 79.0542
            },
            "Solind\u0101b\u0101d": {
                "lat": 25.8463,
                "lng": 86.5907
            },
            "R\u0101parla": {
                "lat": 16.302,
                "lng": 80.9674
            },
            "Hire Megalageri": {
                "lat": 14.544,
                "lng": 75.9776
            },
            "Potavaram": {
                "lat": 17.0194,
                "lng": 81.4128
            },
            "De\u016blgaon M\u0101hi": {
                "lat": 20.0833,
                "lng": 76.1833
            },
            "Bilga": {
                "lat": 31.05,
                "lng": 75.65
            },
            "Kondalahalli": {
                "lat": 14.72,
                "lng": 76.76
            },
            "Bhagirathpur": {
                "lat": 24.0884,
                "lng": 88.4947
            },
            "Borim": {
                "lat": 15.3604,
                "lng": 74.0004
            },
            "Mawai": {
                "lat": 24.8043,
                "lng": 78.9388
            },
            "Mangl\u016br": {
                "lat": 15.5177,
                "lng": 76.1476
            },
            "Sirigeri": {
                "lat": 15.63,
                "lng": 76.9
            },
            "Belagola": {
                "lat": 13.3833,
                "lng": 75.5833
            },
            "S\u016blagiri": {
                "lat": 12.6645,
                "lng": 78.0134
            },
            "Rettanai": {
                "lat": 12.1953,
                "lng": 79.5526
            },
            "Hipparga": {
                "lat": 17.4568,
                "lng": 76.3739
            },
            "Begow\u0101l": {
                "lat": 31.6125,
                "lng": 75.5194
            },
            "N\u0101thpur": {
                "lat": 26.3261,
                "lng": 87.0916
            },
            "Aro\u0101li": {
                "lat": 24.043,
                "lng": 87.9443
            },
            "Vadd\u0101di": {
                "lat": 17.8474,
                "lng": 82.8729
            },
            "Kalakeri": {
                "lat": 16.6667,
                "lng": 76.3
            },
            "Anandnagar": {
                "lat": 27.1,
                "lng": 83.2833
            },
            "Horti": {
                "lat": 17.17,
                "lng": 75.96
            },
            "Masdi": {
                "lat": 25.2441,
                "lng": 86.7233
            },
            "Kondakindi Agrah\u0101ram": {
                "lat": 14.679,
                "lng": 77.7401
            },
            "Adigapp\u0101di": {
                "lat": 12.1459,
                "lng": 78.0946
            },
            "Perumuchchi": {
                "lat": 13.056,
                "lng": 79.6755
            },
            "Havan\u016br": {
                "lat": 14.8667,
                "lng": 75.7
            },
            "Chauki Hasan Chauki Makhdum": {
                "lat": 26.2333,
                "lng": 84.4964
            },
            "Machchand": {
                "lat": 26.3241,
                "lng": 79.0543
            },
            "Sh\u0101hpur Chaumukhi": {
                "lat": 25.7683,
                "lng": 86.9049
            },
            "Talugai": {
                "lat": 11.3752,
                "lng": 78.4822
            },
            "Korosav\u0101da": {
                "lat": 18.7256,
                "lng": 84.0573
            },
            "Sahidganj": {
                "lat": 25.6627,
                "lng": 87.0976
            },
            "Kadal\u0101di": {
                "lat": 12.404,
                "lng": 78.966
            },
            "Ekamba": {
                "lat": 25.9689,
                "lng": 87.5741
            },
            "Jangalapalle": {
                "lat": 14.8997,
                "lng": 79.8936
            },
            "Advi Devalpalli": {
                "lat": 16.6631,
                "lng": 79.5139
            },
            "Erutukada": {
                "lat": 8.2658,
                "lng": 77.1358
            },
            "Hard\u0101s Bigha": {
                "lat": 25.4994,
                "lng": 85.3551
            },
            "Karapa": {
                "lat": 16.9,
                "lng": 82.1667
            },
            "Padugaipattu": {
                "lat": 8.3642,
                "lng": 77.9815
            },
            "Thikriw\u0101la": {
                "lat": 30.4328,
                "lng": 75.5261
            },
            "M\u0101l\u012bnagar": {
                "lat": 25.9967,
                "lng": 85.6863
            },
            "Ghat\u0101w\u0101n": {
                "lat": 25.0968,
                "lng": 83.7266
            },
            "Mesr\u0101": {
                "lat": 23.4339,
                "lng": 85.4152
            },
            "B\u0101gal\u016br": {
                "lat": 12.8333,
                "lng": 77.8667
            },
            "Dudhpura": {
                "lat": 25.7964,
                "lng": 86.1189
            },
            "Sh\u0101hpur Undi": {
                "lat": 25.637,
                "lng": 85.6013
            },
            "B\u0101ra Khurd": {
                "lat": 25.2599,
                "lng": 85.4325
            },
            "Mocharim": {
                "lat": 24.6794,
                "lng": 84.9905
            },
            "Rup\u0101na": {
                "lat": 30.407,
                "lng": 74.5276
            },
            "Dh\u0101mua": {
                "lat": 22.2875,
                "lng": 88.3962
            },
            "Dal\u0101warpur": {
                "lat": 26.3466,
                "lng": 84.9581
            },
            "S\u0101ha": {
                "lat": 30.3,
                "lng": 76.9667
            },
            "Rangasamudram": {
                "lat": 13.714,
                "lng": 78.2645
            },
            "Taiy\u016br": {
                "lat": 12.7833,
                "lng": 80.1833
            },
            "Gauli Pal\u0101siya": {
                "lat": 22.5323,
                "lng": 75.7275
            },
            "Petmanhalli": {
                "lat": 17.7688,
                "lng": 77.5056
            },
            "Tinkoni": {
                "lat": 26.8613,
                "lng": 84.9647
            },
            "Lep\u0101kshi": {
                "lat": 13.81,
                "lng": 77.6
            },
            "Nallikod\u016br": {
                "lat": 17.5811,
                "lng": 79.75
            },
            "Vembadit\u0101lam": {
                "lat": 11.5,
                "lng": 78.1
            },
            "Mah\u0101r\u0101japuram": {
                "lat": 9.6588,
                "lng": 77.6631
            },
            "Baldeogarh": {
                "lat": 24.7562,
                "lng": 79.0672
            },
            "Suth\u0101lia": {
                "lat": 23.9955,
                "lng": 77.1395
            },
            "Puduparambubh\u0101gam": {
                "lat": 9.6,
                "lng": 76.74
            },
            "Sirugamani": {
                "lat": 10.8975,
                "lng": 78.5142
            },
            "Munagap\u0101ka": {
                "lat": 17.6333,
                "lng": 83.0
            },
            "P\u0101nrepatti": {
                "lat": 25.5537,
                "lng": 83.9775
            },
            "L\u0101lmunia Munh\u0101ra": {
                "lat": 26.5741,
                "lng": 86.4012
            },
            "K\u012bl Perambal\u016br": {
                "lat": 11.36,
                "lng": 79.098
            },
            "Belp\u0101ra": {
                "lat": 20.5889,
                "lng": 82.9729
            },
            "Dhangaraha": {
                "lat": 26.0204,
                "lng": 84.7023
            },
            "Pakka Kal\u0101n": {
                "lat": 30.0323,
                "lng": 74.8555
            },
            "S\u012blamalai": {
                "lat": 9.9621,
                "lng": 77.3371
            },
            "Dagi\u0101p\u0101ra": {
                "lat": 26.4568,
                "lng": 91.8294
            },
            "Vandamettu": {
                "lat": 9.7167,
                "lng": 77.15
            },
            "Tiri": {
                "lat": 25.8871,
                "lng": 86.6757
            },
            "Amwa Majh\u0101r": {
                "lat": 26.7362,
                "lng": 84.5818
            },
            "Kolagallu": {
                "lat": 15.15,
                "lng": 76.92
            },
            "Wagh\u0101i": {
                "lat": 20.7667,
                "lng": 73.4833
            },
            "Ghargaon": {
                "lat": 18.616,
                "lng": 74.698
            },
            "Deh": {
                "lat": 27.3,
                "lng": 73.9
            },
            "Medarametla": {
                "lat": 15.7167,
                "lng": 80.0167
            },
            "Hanzviur": {
                "lat": 34.141,
                "lng": 74.5829
            },
            "Amsin": {
                "lat": 26.5733,
                "lng": 82.4357
            },
            "Muttalakanpatti": {
                "lat": 10.8652,
                "lng": 78.6492
            },
            "Isnapuram": {
                "lat": 17.5443,
                "lng": 78.3486
            },
            "Karum\u016blaikkal": {
                "lat": 9.3167,
                "lng": 76.8
            },
            "Dhalai": {
                "lat": 24.5958,
                "lng": 92.8466
            },
            "Nidamal\u016bru": {
                "lat": 15.6547,
                "lng": 80.0678
            },
            "Budwan": {
                "lat": 25.7775,
                "lng": 81.1681
            },
            "Chhimluang": {
                "lat": 24.5157,
                "lng": 92.8222
            },
            "Dh\u012brw\u0101s": {
                "lat": 28.8443,
                "lng": 74.916
            },
            "Siddar\u0101mpuram": {
                "lat": 15.53,
                "lng": 76.636
            },
            "Reha Mota": {
                "lat": 23.1887,
                "lng": 69.9034
            },
            "Parav\u0101kkottai": {
                "lat": 10.6005,
                "lng": 79.4313
            },
            "Mev\u0101ni": {
                "lat": 11.5,
                "lng": 77.5333
            },
            "Thari\u0101l": {
                "lat": 32.3547,
                "lng": 75.6067
            },
            "Dewal Thal": {
                "lat": 29.7,
                "lng": 80.2167
            },
            "Lakshm\u012bpuram": {
                "lat": 10.0774,
                "lng": 77.5198
            },
            "G\u0101ndhali": {
                "lat": 21.104,
                "lng": 75.105
            },
            "Fatehgarh": {
                "lat": 20.3,
                "lng": 85.33
            },
            "Kambh\u0101ladinne": {
                "lat": 15.1274,
                "lng": 79.5413
            },
            "Kerw\u0101da": {
                "lat": 21.9,
                "lng": 72.85
            },
            "Ulipuram": {
                "lat": 11.4667,
                "lng": 78.4667
            },
            "Kochhor": {
                "lat": 27.6268,
                "lng": 75.1376
            },
            "Betnoti": {
                "lat": 21.7355,
                "lng": 86.8499
            },
            "B\u0101l\u0101g\u0101m": {
                "lat": 21.3694,
                "lng": 70.0981
            },
            "R\u0101mamangalam": {
                "lat": 9.9333,
                "lng": 76.5
            },
            "Bomareddipalli": {
                "lat": 18.7044,
                "lng": 79.1568
            },
            "Rakhw\u0101ri": {
                "lat": 26.3603,
                "lng": 86.2882
            },
            "Vilpatti": {
                "lat": 10.2672,
                "lng": 77.5014
            },
            "Erraballa": {
                "lat": 14.3971,
                "lng": 78.1744
            },
            "P\u0101ikp\u0101ra": {
                "lat": 24.3149,
                "lng": 87.8503
            },
            "Y\u0101dw\u0101d": {
                "lat": 16.24,
                "lng": 75.18
            },
            "Ketugr\u0101m": {
                "lat": 23.7017,
                "lng": 88.0439
            },
            "Rangam\u0101ti": {
                "lat": 26.8076,
                "lng": 89.4572
            },
            "Fateha": {
                "lat": 25.6073,
                "lng": 85.8669
            },
            "Kengarai": {
                "lat": 11.4144,
                "lng": 76.9277
            },
            "Pullamb\u0101di": {
                "lat": 10.9667,
                "lng": 78.9167
            },
            "Chada": {
                "lat": 17.4992,
                "lng": 79.0756
            },
            "Dilra": {
                "lat": 29.043,
                "lng": 78.75
            },
            "Inung\u016br": {
                "lat": 10.8507,
                "lng": 78.4889
            },
            "G\u016blyam": {
                "lat": 15.3598,
                "lng": 77.067
            },
            "Hosur": {
                "lat": 15.8201,
                "lng": 74.9301
            },
            "Itikalapalle": {
                "lat": 14.6013,
                "lng": 77.6582
            },
            "Pain\u0101l": {
                "lat": 25.59,
                "lng": 84.9304
            },
            "Kany\u0101na": {
                "lat": 12.9,
                "lng": 75.03
            },
            "Tansandra": {
                "lat": 13.0554,
                "lng": 77.6342
            },
            "M\u0101mobih\u0101t": {
                "lat": 26.1466,
                "lng": 86.1747
            },
            "Kottaipatti": {
                "lat": 10.1533,
                "lng": 77.7315
            },
            "Erraguntlakota": {
                "lat": 13.9622,
                "lng": 79.2768
            },
            "Tokkav\u0101di": {
                "lat": 11.3702,
                "lng": 77.8412
            },
            "Kambaliyampatti": {
                "lat": 10.3671,
                "lng": 78.1233
            },
            "Ch\u0101kicherla": {
                "lat": 15.1127,
                "lng": 80.0366
            },
            "S\u016bndekuppam": {
                "lat": 12.4567,
                "lng": 78.2125
            },
            "Kapasi\u0101w\u0101n": {
                "lat": 25.2783,
                "lng": 85.2869
            },
            "Padinj\u0101reb\u0101gam": {
                "lat": 10.3222,
                "lng": 76.1792
            },
            "Kh\u0101ndsa": {
                "lat": 28.422,
                "lng": 76.99
            },
            "Mahem": {
                "lat": 15.5796,
                "lng": 73.926
            },
            "Ettimadai": {
                "lat": 10.8911,
                "lng": 76.9087
            },
            "Khur\u0101n Milik": {
                "lat": 25.6,
                "lng": 86.8679
            },
            "Saharbani": {
                "lat": 25.7201,
                "lng": 86.41
            },
            "Ennamangalam": {
                "lat": 11.6449,
                "lng": 77.593
            },
            "Bhado Khara": {
                "lat": 24.9567,
                "lng": 85.513
            },
            "Nari\u0101r": {
                "lat": 25.8875,
                "lng": 86.5632
            },
            "Pachch\u0101mp\u0101laiyam": {
                "lat": 11.5723,
                "lng": 77.6096
            },
            "Ammanabrolu": {
                "lat": 15.5802,
                "lng": 80.1488
            },
            "Kamdoli": {
                "lat": 15.2048,
                "lng": 75.2549
            },
            "Akal\u0101pura": {
                "lat": 13.4852,
                "lng": 77.4621
            },
            "Telpur": {
                "lat": 27.0548,
                "lng": 84.3336
            },
            "Kin\u0101l\u016br": {
                "lat": 11.4667,
                "lng": 75.8333
            },
            "Kh\u0101pdeh": {
                "lat": 26.2706,
                "lng": 87.1993
            },
            "Kalimala": {
                "lat": 18.0738,
                "lng": 81.7495
            },
            "Kadr\u0101b\u0101d": {
                "lat": 25.5793,
                "lng": 85.9475
            },
            "Parw\u0101ha": {
                "lat": 26.2336,
                "lng": 87.2197
            },
            "Vedurup\u0101val\u016bru": {
                "lat": 16.5519,
                "lng": 80.7309
            },
            "Kargahi\u0101 Purab": {
                "lat": 26.7887,
                "lng": 84.5298
            },
            "Bajw\u0101ra": {
                "lat": 31.515,
                "lng": 75.9494
            },
            "Angalakud\u016bru Malepalle": {
                "lat": 16.2392,
                "lng": 80.6117
            },
            "Minn\u0101mpalli": {
                "lat": 11.6758,
                "lng": 78.2676
            },
            "Pavittiram": {
                "lat": 11.1406,
                "lng": 78.3631
            },
            "Barni": {
                "lat": 25.3484,
                "lng": 85.0594
            },
            "Pipraun": {
                "lat": 26.599,
                "lng": 85.9356
            },
            "P\u016bdimadaka": {
                "lat": 17.5,
                "lng": 83.0167
            },
            "Kunn\u016br": {
                "lat": 9.5882,
                "lng": 77.7006
            },
            "Karajgi": {
                "lat": 14.8641,
                "lng": 75.4535
            },
            "Kaunra": {
                "lat": 25.5231,
                "lng": 84.5575
            },
            "Bharhopur": {
                "lat": 25.9488,
                "lng": 84.5274
            },
            "Kaith": {
                "lat": 25.4776,
                "lng": 86.2001
            },
            "N\u0101vinipatti": {
                "lat": 10.0439,
                "lng": 78.3557
            },
            "Suhiya": {
                "lat": 25.6482,
                "lng": 84.3916
            },
            "Shankarampet": {
                "lat": 18.049,
                "lng": 77.9143
            },
            "Tonse East": {
                "lat": 13.3963,
                "lng": 74.7217
            },
            "W\u0101ngi": {
                "lat": 18.1833,
                "lng": 75.15
            },
            "Bilehra": {
                "lat": 23.6463,
                "lng": 78.7311
            },
            "Dera Baba Nanak": {
                "lat": 32.0321,
                "lng": 75.0292
            },
            "Ippag\u016bdem": {
                "lat": 17.7668,
                "lng": 79.3751
            },
            "Siruval\u016br": {
                "lat": 11.36,
                "lng": 77.4575
            },
            "Bagh\u0101nt": {
                "lat": 26.1748,
                "lng": 86.1705
            },
            "Jettihalli": {
                "lat": 12.08,
                "lng": 78.1193
            },
            "Gudgeri": {
                "lat": 15.1225,
                "lng": 75.3653
            },
            "Namakadu": {
                "lat": 14.006,
                "lng": 80.1194
            },
            "Muth\u0101bana": {
                "lat": 24.9346,
                "lng": 88.0425
            },
            "Bhadsara": {
                "lat": 25.3696,
                "lng": 84.8444
            },
            "Kaim\u0101ti": {
                "lat": 26.3186,
                "lng": 89.7843
            },
            "Kadanganeri": {
                "lat": 8.92,
                "lng": 77.55
            },
            "Gurwali\u0101 Bisw\u0101s": {
                "lat": 26.8372,
                "lng": 84.4687
            },
            "Barahra": {
                "lat": 26.2356,
                "lng": 86.5615
            },
            "Balt\u0101ra": {
                "lat": 25.5116,
                "lng": 86.7232
            },
            "Reddig\u016bdem": {
                "lat": 16.8939,
                "lng": 80.6917
            },
            "Q\u0101zigund": {
                "lat": 33.5921,
                "lng": 75.1654
            },
            "Cheg\u016br": {
                "lat": 17.1758,
                "lng": 78.2298
            },
            "Ommangi": {
                "lat": 17.2597,
                "lng": 82.2236
            },
            "P\u0101lepalli": {
                "lat": 12.5667,
                "lng": 78.3071
            },
            "Muttattuteruvu": {
                "lat": 9.4333,
                "lng": 76.5389
            },
            "Vettaikk\u0101raniruppu": {
                "lat": 10.5739,
                "lng": 79.8353
            },
            "Lingampet": {
                "lat": 18.2383,
                "lng": 78.1303
            },
            "Mahis\u0101nrh": {
                "lat": 25.7358,
                "lng": 85.5818
            },
            "Tanichchiyam": {
                "lat": 10.0412,
                "lng": 78.0127
            },
            "Paradar\u0101mi": {
                "lat": 13.0833,
                "lng": 78.9833
            },
            "B\u0113rikai": {
                "lat": 12.8056,
                "lng": 77.971
            },
            "Narasimhar\u0101japura": {
                "lat": 13.6108,
                "lng": 75.512
            },
            "Achchippatti": {
                "lat": 10.6989,
                "lng": 77.015
            },
            "Kadoli": {
                "lat": 15.88,
                "lng": 74.56
            },
            "T\u012bgaon": {
                "lat": 21.6456,
                "lng": 78.4576
            },
            "Sur\u012br": {
                "lat": 27.7718,
                "lng": 77.7208
            },
            "T\u016blin": {
                "lat": 23.3794,
                "lng": 85.8906
            },
            "Boriguma": {
                "lat": 19.0468,
                "lng": 82.5532
            },
            "Katarm\u0101la": {
                "lat": 25.5036,
                "lng": 86.2447
            },
            "H\u0101nsa": {
                "lat": 26.0911,
                "lng": 87.3089
            },
            "Khari\u0101l": {
                "lat": 30.1056,
                "lng": 75.8855
            },
            "Kokilad\u0101nga": {
                "lat": 26.3688,
                "lng": 89.8335
            },
            "Pedda Kotayalanka": {
                "lat": 16.1567,
                "lng": 80.8314
            },
            "Itamukkala": {
                "lat": 15.3731,
                "lng": 80.113
            },
            "Kaim\u016bh": {
                "lat": 33.7197,
                "lng": 75.0811
            },
            "Sh\u0101hz\u0101dpur": {
                "lat": 25.6541,
                "lng": 86.8709
            },
            "Manubolu": {
                "lat": 14.1833,
                "lng": 79.8833
            },
            "Patsanda": {
                "lat": 24.858,
                "lng": 86.3008
            },
            "Chandanki\u0101ri": {
                "lat": 23.5781,
                "lng": 86.3598
            },
            "Karghar": {
                "lat": 25.1267,
                "lng": 83.9274
            },
            "Iraiy\u016br": {
                "lat": 11.7834,
                "lng": 79.197
            },
            "Pushpatt\u016br": {
                "lat": 10.5438,
                "lng": 77.41
            },
            "Terku Narippaiy\u016br": {
                "lat": 9.1167,
                "lng": 78.4188
            },
            "B\u012brpur B\u0101r\u0101patti Pindraun": {
                "lat": 26.5475,
                "lng": 86.3898
            },
            "Kodivalasa": {
                "lat": 13.2547,
                "lng": 79.4249
            },
            "Gang\u0101pur Athar": {
                "lat": 25.8214,
                "lng": 85.7123
            },
            "P\u0101darti": {
                "lat": 15.469,
                "lng": 80.183
            },
            "Nisarpur": {
                "lat": 22.1088,
                "lng": 74.8078
            },
            "Kusugal": {
                "lat": 15.3667,
                "lng": 75.2167
            },
            "Pithaura": {
                "lat": 26.262,
                "lng": 87.0983
            },
            "Baragaon": {
                "lat": 25.1348,
                "lng": 85.432
            },
            "N\u0101gulap\u0101du": {
                "lat": 16.0634,
                "lng": 80.3395
            },
            "Amjh\u0101r": {
                "lat": 25.0641,
                "lng": 84.5591
            },
            "Ind\u016brti": {
                "lat": 17.0055,
                "lng": 78.9054
            },
            "Gorha": {
                "lat": 26.2267,
                "lng": 86.9349
            },
            "Chuhal": {
                "lat": 31.595,
                "lng": 75.9684
            },
            "Bas\u0101patna": {
                "lat": 15.4302,
                "lng": 76.4869
            },
            "Pasupatikovil": {
                "lat": 10.8893,
                "lng": 79.1795
            },
            "Kalas": {
                "lat": 15.0981,
                "lng": 75.4067
            },
            "P\u0101lkot": {
                "lat": 22.8748,
                "lng": 84.6433
            },
            "Gangaur": {
                "lat": 26.5666,
                "lng": 85.8821
            },
            "Sorab": {
                "lat": 14.3814,
                "lng": 75.0918
            },
            "Kinh\u0101lu": {
                "lat": 15.4431,
                "lng": 76.1397
            },
            "Kaly\u0101npur": {
                "lat": 26.4802,
                "lng": 84.1789
            },
            "Parav\u0101da": {
                "lat": 17.6283,
                "lng": 83.0793
            },
            "K\u012bl V\u0101l\u016br": {
                "lat": 10.7659,
                "lng": 79.7419
            },
            "Keshw\u0101ri": {
                "lat": 24.1878,
                "lng": 85.8411
            },
            "M\u0101nushmuria": {
                "lat": 22.2808,
                "lng": 86.7386
            },
            "Velp\u016bru": {
                "lat": 16.1496,
                "lng": 79.863
            },
            "Silv\u0101rpatti": {
                "lat": 10.118,
                "lng": 77.6241
            },
            "Khathjari": {
                "lat": 24.7046,
                "lng": 81.9891
            },
            "Veppatt\u016br": {
                "lat": 11.0154,
                "lng": 79.4344
            },
            "Chak Th\u0101t": {
                "lat": 25.7347,
                "lng": 86.034
            },
            "B\u0101g": {
                "lat": 22.359,
                "lng": 74.7905
            },
            "Bamora": {
                "lat": 24.0554,
                "lng": 78.0893
            },
            "Kotli Ablu": {
                "lat": 30.3504,
                "lng": 74.7868
            },
            "Jhitkahiy\u0101": {
                "lat": 26.786,
                "lng": 84.8858
            },
            "Alagarai": {
                "lat": 10.9826,
                "lng": 78.3891
            },
            "Koini": {
                "lat": 26.4226,
                "lng": 84.5147
            },
            "Charne": {
                "lat": 26.1185,
                "lng": 87.0754
            },
            "Gangania": {
                "lat": 25.2395,
                "lng": 86.6536
            },
            "Muddan\u016bru": {
                "lat": 14.6667,
                "lng": 78.4
            },
            "Nallamadu": {
                "lat": 16.8802,
                "lng": 81.3549
            },
            "R\u0101mnagar Bankat": {
                "lat": 26.7595,
                "lng": 84.6122
            },
            "Manal\u016brpettai": {
                "lat": 12.0079,
                "lng": 79.0918
            },
            "Pakri": {
                "lat": 25.5876,
                "lng": 85.158
            },
            "V\u0101l\u0101ntaravai": {
                "lat": 9.339,
                "lng": 78.8871
            },
            "Perket": {
                "lat": 18.7942,
                "lng": 78.3068
            },
            "Karankot": {
                "lat": 17.2866,
                "lng": 77.5644
            },
            "Mah\u012bn\u0101thpur": {
                "lat": 26.6554,
                "lng": 86.0674
            },
            "Kanchanpur": {
                "lat": 25.6636,
                "lng": 85.2703
            },
            "Jaisinghnagar": {
                "lat": 23.626,
                "lng": 78.5726
            },
            "K\u0101tt\u0101garam": {
                "lat": 12.411,
                "lng": 78.3153
            },
            "Gang\u016bru": {
                "lat": 16.475,
                "lng": 80.7411
            },
            "Salkhua": {
                "lat": 25.6677,
                "lng": 86.6002
            },
            "Erravaram": {
                "lat": 17.5458,
                "lng": 82.8433
            },
            "Aramangalam": {
                "lat": 11.5843,
                "lng": 76.0001
            },
            "Koln\u016br": {
                "lat": 18.5095,
                "lng": 79.426
            },
            "Dhams\u0101in": {
                "lat": 26.0938,
                "lng": 86.2541
            },
            "Chegurumomadi": {
                "lat": 18.2361,
                "lng": 79.1974
            },
            "Jagatpur": {
                "lat": 26.3738,
                "lng": 86.0509
            },
            "S\u0101tul\u016bru": {
                "lat": 16.2541,
                "lng": 80.1214
            },
            "Url\u0101ha": {
                "lat": 25.744,
                "lng": 87.0929
            },
            "Undavalli": {
                "lat": 16.4957,
                "lng": 80.58
            },
            "Dhanw\u0101da": {
                "lat": 16.65,
                "lng": 77.6667
            },
            "Sirdala": {
                "lat": 24.6559,
                "lng": 85.4092
            },
            "Hunasamaranhalli": {
                "lat": 13.1435,
                "lng": 77.62
            },
            "Murungattoluvu": {
                "lat": 11.1651,
                "lng": 77.6411
            },
            "Ghusiya": {
                "lat": 25.1832,
                "lng": 84.2486
            },
            "Mot\u012bpur": {
                "lat": 25.8159,
                "lng": 86.014
            },
            "Semmarikulan": {
                "lat": 8.4815,
                "lng": 77.9972
            },
            "Kaithinia": {
                "lat": 26.2317,
                "lng": 86.2817
            },
            "Kusumha": {
                "lat": 25.1859,
                "lng": 85.9234
            },
            "Hadi\u0101ya": {
                "lat": 30.3413,
                "lng": 75.5117
            },
            "Atlapadu": {
                "lat": 16.8877,
                "lng": 81.6772
            },
            "Karattupp\u0101laiyam": {
                "lat": 11.4402,
                "lng": 77.3575
            },
            "Elambal\u016br": {
                "lat": 11.2669,
                "lng": 78.8816
            },
            "Bareh": {
                "lat": 26.6786,
                "lng": 78.1887
            },
            "Kakkat": {
                "lat": 12.1998,
                "lng": 75.2024
            },
            "Tivim": {
                "lat": 15.6,
                "lng": 73.8
            },
            "Mahuver": {
                "lat": 21.0081,
                "lng": 72.8681
            },
            "Tell\u0101r": {
                "lat": 12.4011,
                "lng": 79.553
            },
            "Haraiy\u0101": {
                "lat": 27.09,
                "lng": 85.09
            },
            "Kottampatti": {
                "lat": 10.6705,
                "lng": 77.0194
            },
            "Sundarap\u0101ndiyam": {
                "lat": 9.6127,
                "lng": 77.6738
            },
            "L\u0101pang\u0101": {
                "lat": 23.6333,
                "lng": 85.377
            },
            "Tiruvengadam": {
                "lat": 9.2586,
                "lng": 77.6748
            },
            "Periyapuliy\u016br": {
                "lat": 11.4296,
                "lng": 77.6309
            },
            "Kann\u0101ndahalli": {
                "lat": 12.4164,
                "lng": 78.3803
            },
            "Vanipenta": {
                "lat": 14.7906,
                "lng": 78.7805
            },
            "Punnavalli": {
                "lat": 16.4062,
                "lng": 80.401
            },
            "Belsara": {
                "lat": 26.0152,
                "lng": 87.2023
            },
            "Bararam": {
                "lat": 26.1572,
                "lng": 84.3627
            },
            "Pipra Dew\u0101s": {
                "lat": 25.4636,
                "lng": 86.0236
            },
            "\u0100tt\u016brkuppam": {
                "lat": 12.6,
                "lng": 78.5423
            },
            "Wadgira": {
                "lat": 16.5858,
                "lng": 77.098
            },
            "Tung\u0101vi": {
                "lat": 10.6263,
                "lng": 77.3294
            },
            "Zafargarh": {
                "lat": 17.7686,
                "lng": 79.4859
            },
            "P\u0101ra": {
                "lat": 23.52,
                "lng": 86.52
            },
            "Pai Bigha": {
                "lat": 25.0511,
                "lng": 84.9226
            },
            "Latteri": {
                "lat": 12.9712,
                "lng": 79.0685
            },
            "Santam\u0101gul\u016bru": {
                "lat": 16.1303,
                "lng": 79.9486
            },
            "Mohdra": {
                "lat": 24.1849,
                "lng": 79.9577
            },
            "Malh\u0101rgarh": {
                "lat": 24.2829,
                "lng": 74.9902
            },
            "Velakkuttai": {
                "lat": 12.6519,
                "lng": 78.6937
            },
            "Aval\u016brpet": {
                "lat": 12.38,
                "lng": 79.3465
            },
            "Pull\u016bru": {
                "lat": 16.8301,
                "lng": 80.6226
            },
            "Pondal\u016bru": {
                "lat": 14.2535,
                "lng": 79.2408
            },
            "Makri": {
                "lat": 24.386,
                "lng": 83.5559
            },
            "Piprai": {
                "lat": 24.5097,
                "lng": 77.9642
            },
            "Pindra": {
                "lat": 24.9595,
                "lng": 80.7879
            },
            "Ottappid\u0101ram": {
                "lat": 8.9127,
                "lng": 78.0218
            },
            "Path\u0101ri": {
                "lat": 23.9333,
                "lng": 78.2167
            },
            "M\u0101khar": {
                "lat": 25.7693,
                "lng": 86.6025
            },
            "Parm\u0101npur": {
                "lat": 26.1457,
                "lng": 87.3122
            },
            "Laligam": {
                "lat": 12.05,
                "lng": 78.15
            },
            "Bithauli": {
                "lat": 26.0219,
                "lng": 86.0793
            },
            "M\u0101nrar": {
                "lat": 25.8895,
                "lng": 85.0643
            },
            "Thepaha R\u0101ja R\u0101m": {
                "lat": 26.2229,
                "lng": 84.2523
            },
            "Reddippatti": {
                "lat": 11.21,
                "lng": 78.2166
            },
            "Pareo": {
                "lat": 25.5582,
                "lng": 84.8017
            },
            "Kar\u012bmpur": {
                "lat": 23.97,
                "lng": 88.62
            },
            "Kishanp\u016bra Kal\u0101n": {
                "lat": 30.9337,
                "lng": 75.3499
            },
            "Dundankop": {
                "lat": 16.14,
                "lng": 74.81
            },
            "Dahua": {
                "lat": 24.7742,
                "lng": 87.0325
            },
            "Talapalli": {
                "lat": 18.1158,
                "lng": 78.57
            },
            "Barahbatta": {
                "lat": 25.7727,
                "lng": 85.7522
            },
            "Dandkhora": {
                "lat": 25.5729,
                "lng": 87.6472
            },
            "Tiruvambalapuram": {
                "lat": 8.2514,
                "lng": 77.7752
            },
            "Konidena": {
                "lat": 16.0156,
                "lng": 80.057
            },
            "Dhorgaon": {
                "lat": 25.9545,
                "lng": 86.8933
            },
            "Simrol": {
                "lat": 22.5387,
                "lng": 75.9111
            },
            "Ikkarai Boluv\u0101mpatti": {
                "lat": 10.9669,
                "lng": 76.7996
            },
            "Nanjund\u0101puram": {
                "lat": 11.0857,
                "lng": 76.8726
            },
            "Kataha": {
                "lat": 26.7159,
                "lng": 84.9424
            },
            "Beltangadi": {
                "lat": 12.9795,
                "lng": 75.2699
            },
            "Bendrahall\u012b": {
                "lat": 12.2642,
                "lng": 78.3223
            },
            "Piploda": {
                "lat": 23.6073,
                "lng": 74.942
            },
            "Kath\u016brah": {
                "lat": 29.086,
                "lng": 76.582
            },
            "Adamankottai": {
                "lat": 12.0742,
                "lng": 78.1237
            },
            "Bh\u0101gsar": {
                "lat": 30.4417,
                "lng": 74.4023
            },
            "M\u0101d\u0101ri H\u0101t": {
                "lat": 26.7,
                "lng": 89.2833
            },
            "Bochaha": {
                "lat": 25.5675,
                "lng": 85.7393
            },
            "Gundi": {
                "lat": 18.6237,
                "lng": 79.0923
            },
            "Nambutalai": {
                "lat": 9.7277,
                "lng": 79.0071
            },
            "Anantpur": {
                "lat": 16.889,
                "lng": 75.0146
            },
            "Tadhwa Nandpur": {
                "lat": 26.7556,
                "lng": 84.4326
            },
            "Babhantoli": {
                "lat": 26.252,
                "lng": 87.926
            },
            "Masar": {
                "lat": 25.5577,
                "lng": 84.5767
            },
            "G\u0101ndlapenta": {
                "lat": 14.05,
                "lng": 78.3089
            },
            "Thogad\u016bru": {
                "lat": 12.0957,
                "lng": 76.8078
            },
            "Kirlamp\u016bdi": {
                "lat": 17.1919,
                "lng": 82.1825
            },
            "Bhaw\u0101nandpur": {
                "lat": 25.54,
                "lng": 86.103
            },
            "Hindoli": {
                "lat": 25.4,
                "lng": 75.83
            },
            "Petua": {
                "lat": 22.4143,
                "lng": 88.4489
            },
            "Dobh\u0101w\u0101n": {
                "lat": 25.3987,
                "lng": 85.7856
            },
            "Gonikoppal": {
                "lat": 12.183,
                "lng": 75.9294
            },
            "Chavuttahalli": {
                "lat": 12.4202,
                "lng": 78.2352
            },
            "Chilpur": {
                "lat": 18.2388,
                "lng": 79.4529
            },
            "Kudayatt\u016br": {
                "lat": 9.8167,
                "lng": 76.7833
            },
            "Soanpeta": {
                "lat": 18.9586,
                "lng": 78.361
            },
            "Majali": {
                "lat": 14.9,
                "lng": 74.1
            },
            "S\u016blibele": {
                "lat": 13.1667,
                "lng": 77.8
            },
            "P\u0101chh\u0101pur": {
                "lat": 16.0955,
                "lng": 74.6875
            },
            "Ban\u0101so": {
                "lat": 23.9884,
                "lng": 85.7866
            },
            "Ratnahalli": {
                "lat": 12.3652,
                "lng": 76.502
            },
            "Puduppattanam": {
                "lat": 10.7626,
                "lng": 79.1616
            },
            "Rellivalasa": {
                "lat": 17.9108,
                "lng": 83.41
            },
            "T\u0101lav\u0101di": {
                "lat": 11.778,
                "lng": 77.0055
            },
            "Perumb\u0101lai": {
                "lat": 11.9635,
                "lng": 77.9375
            },
            "Pagidy\u0101la": {
                "lat": 15.9333,
                "lng": 78.3333
            },
            "Avan\u0101ship\u0101laiyam": {
                "lat": 10.9696,
                "lng": 77.4352
            },
            "N\u0101gath\u0101n": {
                "lat": 16.83,
                "lng": 75.73
            },
            "Par\u016br": {
                "lat": 11.5793,
                "lng": 79.255
            },
            "Sarmera": {
                "lat": 25.2564,
                "lng": 85.7988
            },
            "S\u0101namp\u016bdi": {
                "lat": 16.086,
                "lng": 79.835
            },
            "Gaw\u0101n": {
                "lat": 28.43,
                "lng": 78.35
            },
            "Ittikelakunta": {
                "lat": 16.6797,
                "lng": 81.7094
            },
            "Kochg\u0101w\u0101n": {
                "lat": 25.0431,
                "lng": 85.6279
            },
            "Nandam\u016bru": {
                "lat": 16.8458,
                "lng": 81.6466
            },
            "Daulatpur": {
                "lat": 25.3682,
                "lng": 87.8983
            },
            "Baretha": {
                "lat": 25.5258,
                "lng": 87.4485
            },
            "Adesar": {
                "lat": 23.5589,
                "lng": 70.9833
            },
            "Mi\u0101npur Dubauli": {
                "lat": 26.7381,
                "lng": 84.4661
            },
            "Koranampatti": {
                "lat": 11.6085,
                "lng": 77.9049
            },
            "Palankottai": {
                "lat": 9.1356,
                "lng": 77.6952
            },
            "Bisaria": {
                "lat": 25.9789,
                "lng": 87.1003
            },
            "Parauli": {
                "lat": 26.2227,
                "lng": 84.7042
            },
            "V\u012brap\u0101ndiyanpattanam": {
                "lat": 8.52,
                "lng": 78.1181
            },
            "Kamargani": {
                "lat": 22.5475,
                "lng": 88.6606
            },
            "Vemulanarva": {
                "lat": 16.992,
                "lng": 80.2824
            },
            "N\u0101zira": {
                "lat": 22.218,
                "lng": 88.2757
            },
            "Kondaparti": {
                "lat": 17.9219,
                "lng": 79.5373
            },
            "Ekalbehri": {
                "lat": 21.8933,
                "lng": 78.8794
            },
            "Vardham\u0101nkota": {
                "lat": 17.3775,
                "lng": 79.441
            },
            "Gobindpura": {
                "lat": 30.2805,
                "lng": 75.0187
            },
            "D\u0101kpatthar": {
                "lat": 30.4969,
                "lng": 77.7989
            },
            "Kann\u016blu": {
                "lat": 12.8685,
                "lng": 74.9025
            },
            "S\u0101ngi": {
                "lat": 26.3237,
                "lng": 86.4481
            },
            "S\u0101lehpur": {
                "lat": 25.6119,
                "lng": 87.2973
            },
            "Arrapalli": {
                "lat": 18.0718,
                "lng": 79.7386
            },
            "Th\u012bkri": {
                "lat": 22.0634,
                "lng": 75.4007
            },
            "Dabhaura": {
                "lat": 25.1162,
                "lng": 81.3044
            },
            "Naru\u0101r": {
                "lat": 26.2388,
                "lng": 86.2396
            },
            "Berchha": {
                "lat": 23.2823,
                "lng": 76.3199
            },
            "R\u0101masingavaram": {
                "lat": 16.8863,
                "lng": 81.1083
            },
            "Gang\u0101dhar": {
                "lat": 18.5883,
                "lng": 79.011
            },
            "Dodarasinakere": {
                "lat": 12.5083,
                "lng": 77.02
            },
            "Kanhai": {
                "lat": 25.9761,
                "lng": 86.2898
            },
            "Udburu": {
                "lat": 23.0333,
                "lng": 85.3667
            },
            "Khund\u0101wandpur": {
                "lat": 25.6689,
                "lng": 86.0363
            },
            "Navani": {
                "lat": 11.3709,
                "lng": 78.138
            },
            "Ekhari": {
                "lat": 26.5246,
                "lng": 86.3071
            },
            "Kh\u0101spur": {
                "lat": 25.6466,
                "lng": 84.9936
            },
            "Jalkaura": {
                "lat": 25.5034,
                "lng": 86.3671
            },
            "Kaikaram": {
                "lat": 16.812,
                "lng": 81.366
            },
            "Vatakemuri": {
                "lat": 10.43,
                "lng": 76.12
            },
            "R\u0101mpur Rajwa": {
                "lat": 25.7057,
                "lng": 86.1781
            },
            "Somv\u0101rpet": {
                "lat": 12.597,
                "lng": 75.8496
            },
            "Mallampalli": {
                "lat": 18.1105,
                "lng": 79.8494
            },
            "Barw\u0101dih": {
                "lat": 23.8478,
                "lng": 84.1105
            },
            "Madhopur": {
                "lat": 26.7369,
                "lng": 85.0625
            },
            "At\u0101ri": {
                "lat": 31.6008,
                "lng": 74.6056
            },
            "M\u0101nb\u0101z\u0101r": {
                "lat": 23.0615,
                "lng": 86.6642
            },
            "Valaiy\u0101mpattu": {
                "lat": 12.6927,
                "lng": 78.6347
            },
            "Madhura": {
                "lat": 25.5418,
                "lng": 87.5267
            },
            "Jan\u0101pul": {
                "lat": 22.8615,
                "lng": 88.6868
            },
            "\u0100div\u0101la": {
                "lat": 13.9131,
                "lng": 76.6619
            },
            "Kalladai": {
                "lat": 10.7272,
                "lng": 78.4634
            },
            "Manchenahalli": {
                "lat": 13.5007,
                "lng": 77.6035
            },
            "R\u0101mpur Parhat": {
                "lat": 25.5916,
                "lng": 87.1622
            },
            "Khagam": {
                "lat": 27.32,
                "lng": 95.9
            },
            "Trovagunta": {
                "lat": 15.5509,
                "lng": 80.0589
            },
            "Kujri": {
                "lat": 26.2392,
                "lng": 87.6366
            },
            "\u0100njukulippatti": {
                "lat": 10.2444,
                "lng": 78.0794
            },
            "Dh\u0101ntola": {
                "lat": 26.2016,
                "lng": 88.1095
            },
            "B\u0101gor": {
                "lat": 25.3575,
                "lng": 74.3731
            },
            "Nath\u0101na": {
                "lat": 30.3155,
                "lng": 75.0931
            },
            "R\u0101nigaon": {
                "lat": 27.1,
                "lng": 74.4167
            },
            "Mangr\u0101w\u0101n": {
                "lat": 25.0929,
                "lng": 84.3912
            },
            "Mudd\u0101da": {
                "lat": 18.2385,
                "lng": 83.8687
            },
            "Il\u0101mi": {
                "lat": 24.6776,
                "lng": 87.8804
            },
            "Devmaudaldal": {
                "lat": 24.6312,
                "lng": 81.1594
            },
            "B\u0101ghduma": {
                "lat": 24.8223,
                "lng": 87.0116
            },
            "Kaithw\u0101r": {
                "lat": 26.1678,
                "lng": 86.2575
            },
            "Valtoha": {
                "lat": 31.2074,
                "lng": 74.6423
            },
            "Madhuban Bediban": {
                "lat": 26.5062,
                "lng": 84.9951
            },
            "Karath": {
                "lat": 25.201,
                "lng": 84.3672
            },
            "Mehd\u012bpur": {
                "lat": 25.3902,
                "lng": 86.7324
            },
            "N\u0101r\u0101yanraopet": {
                "lat": 18.2114,
                "lng": 78.7794
            },
            "Hesarghatta": {
                "lat": 13.1391,
                "lng": 77.4783
            },
            "Malaudh": {
                "lat": 30.6333,
                "lng": 75.9356
            },
            "Katigang": {
                "lat": 25.4157,
                "lng": 87.8245
            },
            "Nadimp\u0101lem": {
                "lat": 16.2123,
                "lng": 80.3097
            },
            "Ekch\u0101ri": {
                "lat": 25.2093,
                "lng": 87.2159
            },
            "Vir\u0101limalai": {
                "lat": 10.6023,
                "lng": 78.5471
            },
            "Tepperum\u0101lnall\u016br": {
                "lat": 10.9694,
                "lng": 79.4254
            },
            "Durgi": {
                "lat": 16.4286,
                "lng": 79.5406
            },
            "Periya Pattanam": {
                "lat": 9.2726,
                "lng": 78.9023
            },
            "Bimun": {
                "lat": 34.0588,
                "lng": 74.7539
            },
            "Molagavalli": {
                "lat": 15.3567,
                "lng": 77.3301
            },
            "Malaim\u0101chchampatti": {
                "lat": 10.9058,
                "lng": 77.005
            },
            "Isr\u0101na": {
                "lat": 29.2739,
                "lng": 76.8461
            },
            "Sundarsi": {
                "lat": 23.2688,
                "lng": 76.4399
            },
            "El\u016brpatti": {
                "lat": 11.0234,
                "lng": 78.2844
            },
            "Asahni": {
                "lat": 26.0079,
                "lng": 84.4799
            },
            "Marne": {
                "lat": 13.3284,
                "lng": 75.0113
            },
            "Al\u0101walpur": {
                "lat": 31.4316,
                "lng": 75.6561
            },
            "Upp\u016br": {
                "lat": 13.3945,
                "lng": 74.7657
            },
            "Inole": {
                "lat": 17.8682,
                "lng": 79.5545
            },
            "Gold": {
                "lat": 33.5874,
                "lng": 74.1445
            },
            "Kerap": {
                "lat": 24.8332,
                "lng": 84.614
            },
            "Idumb\u0101vanam": {
                "lat": 10.4224,
                "lng": 79.6001
            },
            "Bijeraghogarh": {
                "lat": 23.9955,
                "lng": 80.6066
            },
            "N\u0101rsingi": {
                "lat": 18.0446,
                "lng": 78.4254
            },
            "Kh\u0101wad": {
                "lat": 23.5443,
                "lng": 72.4432
            },
            "Erikolam": {
                "lat": 12.1333,
                "lng": 75.2833
            },
            "Kadan\u0101du": {
                "lat": 11.4607,
                "lng": 76.7182
            },
            "Komm\u016bru": {
                "lat": 16.0667,
                "lng": 80.3667
            },
            "Gonghaur": {
                "lat": 26.4346,
                "lng": 86.216
            },
            "Raitar": {
                "lat": 25.0577,
                "lng": 85.5366
            },
            "Dh\u0101nga": {
                "lat": 26.463,
                "lng": 86.0452
            },
            "Kankan\u0101lapalle": {
                "lat": 15.9923,
                "lng": 79.4848
            },
            "Pipra": {
                "lat": 26.346,
                "lng": 87.2916
            },
            "Chewara": {
                "lat": 25.0756,
                "lng": 85.9277
            },
            "Harl\u0101khi": {
                "lat": 26.6353,
                "lng": 85.9842
            },
            "R\u0101sol": {
                "lat": 20.6298,
                "lng": 85.3126
            },
            "Ghosr\u0101w\u0101n": {
                "lat": 25.091,
                "lng": 85.578
            },
            "Saidoke": {
                "lat": 30.5267,
                "lng": 75.2867
            },
            "Medleri": {
                "lat": 14.6667,
                "lng": 75.7333
            },
            "Kh\u0101npur Khairanti": {
                "lat": 26.1456,
                "lng": 84.3334
            },
            "Bairiy\u0101": {
                "lat": 26.3392,
                "lng": 84.8236
            },
            "El\u016brup\u0101du": {
                "lat": 16.5167,
                "lng": 81.35
            },
            "Aurahi": {
                "lat": 25.8263,
                "lng": 87.1365
            },
            "Dibr\u0101ghani": {
                "lat": 25.8261,
                "lng": 87.0664
            },
            "Vadak\u0113thara": {
                "lat": 10.6583,
                "lng": 76.4667
            },
            "Ghan\u012bpur Bejha": {
                "lat": 25.9281,
                "lng": 85.5548
            },
            "Basni": {
                "lat": 25.4435,
                "lng": 82.8291
            },
            "B\u016bdal\u016br": {
                "lat": 10.7861,
                "lng": 78.9864
            },
            "Anj\u0113halli": {
                "lat": 12.1395,
                "lng": 77.9808
            },
            "Naurhiya": {
                "lat": 24.2069,
                "lng": 82.5655
            },
            "S\u0101diqpur Maraul": {
                "lat": 25.9966,
                "lng": 85.5777
            },
            "Bendarhalli": {
                "lat": 12.15,
                "lng": 77.1
            },
            "Simarbani": {
                "lat": 26.1953,
                "lng": 87.1769
            },
            "Sivamalai": {
                "lat": 11.0319,
                "lng": 77.5384
            },
            "Jaw\u0101sa": {
                "lat": 26.6259,
                "lng": 78.7418
            },
            "Bobil": {
                "lat": 25.6269,
                "lng": 86.8069
            },
            "Sarpam\u0101ri": {
                "lat": 26.3073,
                "lng": 89.8666
            },
            "Nirna": {
                "lat": 17.77,
                "lng": 77.14
            },
            "I\u0101war": {
                "lat": 23.0094,
                "lng": 76.5007
            },
            "Khar\u012b": {
                "lat": 33.389,
                "lng": 75.1514
            },
            "Muhammadganj": {
                "lat": 26.1506,
                "lng": 87.0792
            },
            "Dharir": {
                "lat": 22.3958,
                "lng": 88.8628
            },
            "Kallayi": {
                "lat": 12.0051,
                "lng": 75.583
            },
            "Danau K\u00e4ndimarg": {
                "lat": 33.5646,
                "lng": 74.9754
            },
            "Ramdeora": {
                "lat": 27.01,
                "lng": 71.92
            },
            "R\u0101mkali": {
                "lat": 22.4322,
                "lng": 88.3285
            },
            "Pett\u0101mp\u0101laiyam": {
                "lat": 11.3499,
                "lng": 77.5706
            },
            "Devar\u0101palle": {
                "lat": 17.9886,
                "lng": 82.9811
            },
            "Uppalaguptam": {
                "lat": 16.5667,
                "lng": 82.1
            },
            "Taisar": {
                "lat": 22.4867,
                "lng": 84.1804
            },
            "Hazrat Shiura": {
                "lat": 25.5976,
                "lng": 85.6119
            },
            "Jamun\u0101mukh": {
                "lat": 26.1015,
                "lng": 92.7426
            },
            "Vempatti": {
                "lat": 17.4488,
                "lng": 79.5992
            },
            "Ukw\u0101": {
                "lat": 21.971,
                "lng": 80.4662
            },
            "Gaurdah": {
                "lat": 25.7097,
                "lng": 86.557
            },
            "Kadimetla": {
                "lat": 15.7376,
                "lng": 77.5385
            },
            "Jhabrera": {
                "lat": 29.8091,
                "lng": 77.7734
            },
            "Sarakkayhalli": {
                "lat": 12.2191,
                "lng": 78.048
            },
            "Kharagbani": {
                "lat": 26.4852,
                "lng": 86.3073
            },
            "K\u0101kar\u0101ti": {
                "lat": 24.5639,
                "lng": 80.2659
            },
            "Rang\u0101puram": {
                "lat": 15.4092,
                "lng": 78.0746
            },
            "Tirubhuvane": {
                "lat": 11.9281,
                "lng": 79.6448
            },
            "P\u016bmalakkundu": {
                "lat": 9.8871,
                "lng": 77.4498
            },
            "Bakhari\u0101": {
                "lat": 26.7666,
                "lng": 84.6619
            },
            "Ukh\u0101i Purb\u0101ri Patti": {
                "lat": 26.2471,
                "lng": 84.4066
            },
            "Barjhar": {
                "lat": 22.6012,
                "lng": 74.2471
            },
            "Ham\u012bra": {
                "lat": 31.4589,
                "lng": 75.4304
            },
            "Hathaura": {
                "lat": 26.1744,
                "lng": 84.3294
            },
            "Chhapera": {
                "lat": 23.8948,
                "lng": 76.4538
            },
            "Tandar\u0101mpattu": {
                "lat": 12.1533,
                "lng": 78.9473
            },
            "Baisuhalli": {
                "lat": 12.2064,
                "lng": 78.1906
            },
            "Barahpur": {
                "lat": 25.4192,
                "lng": 85.8762
            },
            "L\u0101nghnaj": {
                "lat": 23.45,
                "lng": 72.4833
            },
            "Tiruv\u0101duturai": {
                "lat": 11.0379,
                "lng": 79.5215
            },
            "Mupp\u0101lla": {
                "lat": 16.3198,
                "lng": 80.0927
            },
            "Tadangam": {
                "lat": 12.1056,
                "lng": 78.1226
            },
            "B\u0101gch\u012bni": {
                "lat": 26.4817,
                "lng": 77.8185
            },
            "Mah\u0101lgaon": {
                "lat": 26.0466,
                "lng": 87.5774
            },
            "B\u0101yaram": {
                "lat": 18.0506,
                "lng": 80.7375
            },
            "Kirang\u016br": {
                "lat": 12.4316,
                "lng": 76.6993
            },
            "Chinn\u0101mp\u0101laiyam": {
                "lat": 10.6604,
                "lng": 77.0248
            },
            "Asudapuram": {
                "lat": 15.878,
                "lng": 78.132
            },
            "Chilmil": {
                "lat": 25.4563,
                "lng": 86.1364
            },
            "Chiman": {
                "lat": 30.12,
                "lng": 75.695
            },
            "Qutubpur": {
                "lat": 25.6279,
                "lng": 85.3684
            },
            "Barpath\u0101r": {
                "lat": 26.2871,
                "lng": 93.8884
            },
            "Kala\u012bkunda": {
                "lat": 22.3424,
                "lng": 87.2265
            },
            "L\u0101dhuka": {
                "lat": 30.5082,
                "lng": 74.0905
            },
            "Chandw\u0101r\u0101": {
                "lat": 24.393,
                "lng": 85.4798
            },
            "Bhog\u0101puram": {
                "lat": 18.0311,
                "lng": 83.4967
            },
            "Parol": {
                "lat": 32.346,
                "lng": 75.4344
            },
            "Nattakk\u0101daiy\u016br": {
                "lat": 11.0882,
                "lng": 77.6637
            },
            "K\u0101t\u016bria": {
                "lat": 24.7476,
                "lng": 86.7205
            },
            "Takkali": {
                "lat": 8.2461,
                "lng": 77.3186
            },
            "Mallapp\u0101di": {
                "lat": 12.5273,
                "lng": 78.3756
            },
            "Kesli": {
                "lat": 23.4183,
                "lng": 78.8062
            },
            "Manteswar": {
                "lat": 23.4225,
                "lng": 88.1075
            },
            "N\u016blivedu": {
                "lat": 14.1002,
                "lng": 78.5548
            },
            "Chinnakkavundan\u016br": {
                "lat": 11.4559,
                "lng": 77.8673
            },
            "J\u0101ffar Kh\u0101npet": {
                "lat": 18.6875,
                "lng": 79.5411
            },
            "Boddik\u016brap\u0101du": {
                "lat": 15.6591,
                "lng": 79.7509
            },
            "Taraw\u0101n": {
                "lat": 24.7295,
                "lng": 85.2529
            },
            "Pel\u0101gor": {
                "lat": 25.5992,
                "lng": 87.7214
            },
            "Suj\u0101pur": {
                "lat": 25.4815,
                "lng": 87.4435
            },
            "Sosale": {
                "lat": 12.233,
                "lng": 76.917
            },
            "Borna": {
                "lat": 25.4373,
                "lng": 86.6172
            },
            "Hasanpura": {
                "lat": 26.0751,
                "lng": 84.3618
            },
            "N\u0101g\u0101yalanka": {
                "lat": 15.95,
                "lng": 80.9167
            },
            "Un\u0101o": {
                "lat": 25.5784,
                "lng": 78.6006
            },
            "Telkathu": {
                "lat": 26.1124,
                "lng": 84.3733
            },
            "Kalv\u0101rpatti": {
                "lat": 10.625,
                "lng": 77.9375
            },
            "Maihma Sarja": {
                "lat": 30.311,
                "lng": 74.8427
            },
            "Jah\u0101ng\u012brpur S\u0101lkhani": {
                "lat": 25.8335,
                "lng": 85.4795
            },
            "Baisa": {
                "lat": 25.3552,
                "lng": 86.7246
            },
            "Timm\u0101puram": {
                "lat": 15.4887,
                "lng": 78.5744
            },
            "Rudrapray\u0101g": {
                "lat": 30.28,
                "lng": 78.98
            },
            "J\u0101npur": {
                "lat": 25.2958,
                "lng": 84.8789
            },
            "Chiank\u012b": {
                "lat": 24.0051,
                "lng": 84.1098
            },
            "Ralla": {
                "lat": 30.12,
                "lng": 75.432
            },
            "Pangunattam": {
                "lat": 12.1112,
                "lng": 78.0123
            },
            "Rupahi": {
                "lat": 26.411,
                "lng": 92.74
            },
            "Patch\u016br": {
                "lat": 12.5949,
                "lng": 78.4622
            },
            "Mariy\u0101dau": {
                "lat": 24.2762,
                "lng": 79.6559
            },
            "Satrav\u0101da": {
                "lat": 13.3201,
                "lng": 79.5488
            },
            "Pasr\u0101ha": {
                "lat": 25.3973,
                "lng": 86.7034
            },
            "Dr\u0101ksh\u0101r\u0101ma": {
                "lat": 16.7928,
                "lng": 82.0635
            },
            "Urang\u0101npatti": {
                "lat": 9.9984,
                "lng": 78.4297
            },
            "Mumaradikop": {
                "lat": 15.9,
                "lng": 75.5
            },
            "Honganur": {
                "lat": 12.6044,
                "lng": 77.2175
            },
            "Isua": {
                "lat": 25.233,
                "lng": 85.843
            },
            "Kanajanahalli": {
                "lat": 14.1092,
                "lng": 76.8381
            },
            "Khand\u0101ich": {
                "lat": 24.8511,
                "lng": 86.2395
            },
            "Modav\u0101ndisatyamangalam": {
                "lat": 11.2635,
                "lng": 77.7285
            },
            "Kharahara": {
                "lat": 24.9142,
                "lng": 87.0006
            },
            "Dahi": {
                "lat": 22.1122,
                "lng": 74.5879
            },
            "As\u0101rhi": {
                "lat": 25.2892,
                "lng": 85.2189
            },
            "Lagunia Raghukanth": {
                "lat": 25.8195,
                "lng": 85.7725
            },
            "Kollankulam": {
                "lat": 8.7964,
                "lng": 77.5681
            },
            "Kunnatt\u016br": {
                "lat": 11.2727,
                "lng": 77.4107
            },
            "Uppalap\u0101du": {
                "lat": 16.3862,
                "lng": 79.9983
            },
            "Chicholi": {
                "lat": 22.01,
                "lng": 77.66
            },
            "Majhariy\u0101": {
                "lat": 26.8696,
                "lng": 84.8771
            },
            "Bhachhi": {
                "lat": 26.3404,
                "lng": 86.0585
            },
            "Thakurainia": {
                "lat": 26.2098,
                "lng": 86.0191
            },
            "\u0100nandpur": {
                "lat": 25.6192,
                "lng": 84.8673
            },
            "Gainrha": {
                "lat": 25.9916,
                "lng": 87.4648
            },
            "Deokali": {
                "lat": 25.9068,
                "lng": 86.1111
            },
            "R\u0101jap\u016bdi": {
                "lat": 17.183,
                "lng": 82.05
            },
            "Punnaikk\u0101yal": {
                "lat": 8.6322,
                "lng": 78.1119
            },
            "Oppicherla": {
                "lat": 16.4444,
                "lng": 79.6967
            },
            "P\u0101ppampatti": {
                "lat": 10.4446,
                "lng": 77.4048
            },
            "Alukkuli": {
                "lat": 11.4458,
                "lng": 77.3574
            },
            "Chilakh\u0101na": {
                "lat": 26.299,
                "lng": 89.5892
            },
            "Piru": {
                "lat": 25.1009,
                "lng": 84.5583
            },
            "Itiki": {
                "lat": 14.951,
                "lng": 76.0944
            },
            "Barn\u0101on": {
                "lat": 25.4809,
                "lng": 84.525
            },
            "Pedda Tumbalam": {
                "lat": 15.6328,
                "lng": 77.1036
            },
            "Gan\u0101ram": {
                "lat": 18.5275,
                "lng": 78.245
            },
            "Alg\u016bn": {
                "lat": 31.2795,
                "lng": 74.6517
            },
            "Samalpur": {
                "lat": 25.1961,
                "lng": 88.0419
            },
            "Gandikunta": {
                "lat": 16.1693,
                "lng": 80.9443
            },
            "Bendap\u016bdi": {
                "lat": 17.2673,
                "lng": 82.3758
            },
            "Katteragandla": {
                "lat": 15.0091,
                "lng": 78.9584
            },
            "S\u012bt\u0101nagaram": {
                "lat": 16.2396,
                "lng": 80.5855
            },
            "Harbatpur": {
                "lat": 30.45,
                "lng": 77.73
            },
            "Chunakhali": {
                "lat": 22.301,
                "lng": 88.7951
            },
            "Iarpur": {
                "lat": 22.2998,
                "lng": 88.2994
            },
            "Raipur Buzurg": {
                "lat": 25.7118,
                "lng": 85.6956
            },
            "M\u0101midipalli": {
                "lat": 18.7772,
                "lng": 78.2962
            },
            "Aranya Kal\u0101n": {
                "lat": 23.2448,
                "lng": 76.7435
            },
            "N\u016btakki": {
                "lat": 16.4139,
                "lng": 80.6506
            },
            "Kahla": {
                "lat": 26.3747,
                "lng": 84.5433
            },
            "Sandalpur": {
                "lat": 25.5639,
                "lng": 87.5082
            },
            "Karsaut": {
                "lat": 26.1298,
                "lng": 84.4825
            },
            "Vallapuram": {
                "lat": 11.4,
                "lng": 78.88
            },
            "Kondrukota": {
                "lat": 17.3477,
                "lng": 81.6041
            },
            "Vaikuntam": {
                "lat": 11.5197,
                "lng": 77.9398
            },
            "Kaujalgi": {
                "lat": 16.14,
                "lng": 74.81
            },
            "Kondayamp\u0101laiyam": {
                "lat": 11.513,
                "lng": 77.4371
            },
            "J\u0101jireddig\u016bdem": {
                "lat": 17.3278,
                "lng": 79.5711
            },
            "Gudim\u016blakhandrika": {
                "lat": 16.382,
                "lng": 81.7335
            },
            "Gandhw\u0101ni": {
                "lat": 22.34,
                "lng": 75.0048
            },
            "Vallahbh\u0101puram": {
                "lat": 16.3528,
                "lng": 80.7175
            },
            "Badagabettu": {
                "lat": 13.3335,
                "lng": 74.7999
            },
            "Gangaura Behra": {
                "lat": 25.9541,
                "lng": 86.5482
            },
            "Badnor": {
                "lat": 25.8355,
                "lng": 74.2775
            },
            "Nonea": {
                "lat": 26.9746,
                "lng": 84.8768
            },
            "Saraunja": {
                "lat": 25.769,
                "lng": 86.5273
            },
            "Kandiyankovil": {
                "lat": 11.0147,
                "lng": 77.4695
            },
            "Ottapp\u0101rai": {
                "lat": 11.1936,
                "lng": 77.5964
            },
            "Sw\u0101mimalai": {
                "lat": 10.9575,
                "lng": 79.3293
            },
            "Ghattupal": {
                "lat": 17.0725,
                "lng": 78.9239
            },
            "Kharika": {
                "lat": 25.7173,
                "lng": 85.1256
            },
            "Kodakkal": {
                "lat": 13.0685,
                "lng": 79.4149
            },
            "Kannavam": {
                "lat": 11.8333,
                "lng": 75.6667
            },
            "Jambukuttaippatti": {
                "lat": 12.3246,
                "lng": 78.3443
            },
            "Chokkalingapuram": {
                "lat": 10.193,
                "lng": 78.3973
            },
            "B\u0101danahatti": {
                "lat": 15.3176,
                "lng": 76.8258
            },
            "Vadav\u0101lam": {
                "lat": 10.4286,
                "lng": 78.8913
            },
            "Irungal\u016br": {
                "lat": 10.9399,
                "lng": 78.7619
            },
            "Gothurutha": {
                "lat": 10.1862,
                "lng": 76.2013
            },
            "R\u0101m\u0101yipatti": {
                "lat": 11.472,
                "lng": 78.2109
            },
            "Laheji": {
                "lat": 26.0957,
                "lng": 84.4009
            },
            "D\u0101margidda": {
                "lat": 16.8189,
                "lng": 77.5031
            },
            "Jamhra": {
                "lat": 25.7777,
                "lng": 86.7899
            },
            "Sus\u0101ri": {
                "lat": 22.1797,
                "lng": 74.7577
            },
            "Valkurti": {
                "lat": 18.7171,
                "lng": 79.3823
            },
            "Muli": {
                "lat": 22.6388,
                "lng": 71.458
            },
            "Shirguppi": {
                "lat": 16.72,
                "lng": 75.06
            },
            "Chalk\u0101ri": {
                "lat": 23.758,
                "lng": 85.954
            },
            "Jogaili": {
                "lat": 25.9096,
                "lng": 87.4061
            },
            "Chikk\u0101la": {
                "lat": 16.9698,
                "lng": 81.5922
            },
            "Sewa": {
                "lat": 24.8753,
                "lng": 86.3257
            },
            "Jagann\u0101dapuram": {
                "lat": 13.2745,
                "lng": 80.1802
            },
            "Himmatpura": {
                "lat": 30.5271,
                "lng": 75.3627
            },
            "R\u0101jepur": {
                "lat": 25.5377,
                "lng": 85.6228
            },
            "Sukk\u0101mpatti": {
                "lat": 10.5267,
                "lng": 78.1728
            },
            "Hirehalli": {
                "lat": 14.5255,
                "lng": 76.6634
            },
            "Harpur Bhindi": {
                "lat": 25.7746,
                "lng": 85.6563
            },
            "M\u0101depalli": {
                "lat": 12.5491,
                "lng": 78.34
            },
            "Nelali": {
                "lat": 10.9282,
                "lng": 77.4832
            },
            "Budhma": {
                "lat": 25.6451,
                "lng": 86.8317
            },
            "Dommasandra": {
                "lat": 12.8781,
                "lng": 77.7543
            },
            "Op": {
                "lat": 25.2092,
                "lng": 85.3019
            },
            "Banbh\u0101g": {
                "lat": 25.7919,
                "lng": 87.414
            },
            "Bariariya Tola R\u0101jpur": {
                "lat": 26.4567,
                "lng": 84.7043
            },
            "Halgeri": {
                "lat": 14.5551,
                "lng": 75.6099
            },
            "Sungal": {
                "lat": 32.9392,
                "lng": 74.6935
            },
            "Pilik\u014ddu": {
                "lat": 12.1903,
                "lng": 75.1667
            },
            "Fateh Nangal": {
                "lat": 31.9453,
                "lng": 75.3191
            },
            "Morub\u0101galu": {
                "lat": 13.9702,
                "lng": 77.0411
            },
            "Anj\u016br": {
                "lat": 10.5992,
                "lng": 76.1553
            },
            "N\u0101r\u0101yanpur": {
                "lat": 17.8589,
                "lng": 76.9733
            },
            "Belakv\u0101di": {
                "lat": 12.255,
                "lng": 77.1225
            },
            "Chinn\u0101yag\u016bdem": {
                "lat": 17.047,
                "lng": 81.481
            },
            "Kond\u016bru": {
                "lat": 16.678,
                "lng": 80.573
            },
            "Sansa": {
                "lat": 25.0291,
                "lng": 84.4597
            },
            "Mangasamudram": {
                "lat": 13.2291,
                "lng": 79.0909
            },
            "Kaith\u0101hi": {
                "lat": 26.3488,
                "lng": 86.1521
            },
            "D\u0101mu": {
                "lat": 26.5461,
                "lng": 85.982
            },
            "Sibkund": {
                "lat": 25.3041,
                "lng": 86.399
            },
            "Nerinjippettai": {
                "lat": 11.6543,
                "lng": 77.7586
            },
            "Dihri": {
                "lat": 25.3796,
                "lng": 85.5376
            },
            "Bhargaon": {
                "lat": 26.075,
                "lng": 87.1483
            },
            "M\u0101lingaon": {
                "lat": 26.356,
                "lng": 87.9614
            },
            "Yenmangandla": {
                "lat": 16.8839,
                "lng": 78.0332
            },
            "Govindapalle": {
                "lat": 15.3525,
                "lng": 78.5224
            },
            "Mokri": {
                "lat": 25.0184,
                "lng": 83.5633
            },
            "Chitr\u0101da": {
                "lat": 17.0802,
                "lng": 82.2469
            },
            "Nandiy\u0101lam": {
                "lat": 12.9237,
                "lng": 79.2635
            },
            "Tettu": {
                "lat": 13.6275,
                "lng": 78.4322
            },
            "Bamaiya Harl\u0101l": {
                "lat": 25.6664,
                "lng": 85.8802
            },
            "Killimangalam": {
                "lat": 10.7167,
                "lng": 76.35
            },
            "Kongnolli": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Alawandi": {
                "lat": 15.2303,
                "lng": 75.9801
            },
            "Kaglipur": {
                "lat": 12.8006,
                "lng": 77.5082
            },
            "Koheda": {
                "lat": 18.1709,
                "lng": 79.0934
            },
            "Phagu": {
                "lat": 26.95,
                "lng": 88.7
            },
            "Sih\u0101li J\u0101g\u012br": {
                "lat": 28.7997,
                "lng": 78.2628
            },
            "Kud\u016bru": {
                "lat": 13.111,
                "lng": 77.1479
            },
            "Patt\u012bswaram": {
                "lat": 10.9253,
                "lng": 79.3424
            },
            "Miryal": {
                "lat": 17.5661,
                "lng": 78.8095
            },
            "Oday\u0101rpatti": {
                "lat": 11.6663,
                "lng": 78.2144
            },
            "Prabh\u0101t Pattan": {
                "lat": 21.6407,
                "lng": 78.2722
            },
            "Uchti": {
                "lat": 25.9197,
                "lng": 86.2544
            },
            "Satghara": {
                "lat": 26.3977,
                "lng": 86.1678
            },
            "Tekpanja": {
                "lat": 22.2086,
                "lng": 88.3122
            },
            "Govind\u0101puram": {
                "lat": 16.1548,
                "lng": 80.1027
            },
            "Kod": {
                "lat": 22.885,
                "lng": 75.1862
            },
            "Chaukhata": {
                "lat": 25.0247,
                "lng": 81.7375
            },
            "Datian": {
                "lat": 25.4379,
                "lng": 87.8378
            },
            "Gok": {
                "lat": 27.1065,
                "lng": 88.2459
            },
            "Ambara": {
                "lat": 22.1902,
                "lng": 78.6708
            },
            "Sog\u0101m": {
                "lat": 34.5014,
                "lng": 74.3823
            },
            "Ken\u0101r": {
                "lat": 25.2916,
                "lng": 85.7416
            },
            "D\u0101vul\u016bru": {
                "lat": 16.2625,
                "lng": 80.7436
            },
            "Koila Belw\u0101": {
                "lat": 26.3753,
                "lng": 84.9986
            },
            "Yeld\u016brti": {
                "lat": 17.9074,
                "lng": 78.3721
            },
            "Dasaut": {
                "lat": 25.8997,
                "lng": 86.0964
            },
            "Hokur Badasgom": {
                "lat": 33.6503,
                "lng": 75.2359
            },
            "Haripura": {
                "lat": 34.0601,
                "lng": 74.7125
            },
            "Bim\u0101w\u0101n": {
                "lat": 25.5111,
                "lng": 84.4946
            },
            "Olalap\u0101di": {
                "lat": 10.7979,
                "lng": 76.8923
            },
            "Mauji": {
                "lat": 25.6585,
                "lng": 86.2313
            },
            "Majhaulia": {
                "lat": 25.9635,
                "lng": 85.5738
            },
            "Periyamutt\u016br": {
                "lat": 12.463,
                "lng": 78.1969
            },
            "Hussepur": {
                "lat": 26.1801,
                "lng": 84.9408
            },
            "D\u0101ita": {
                "lat": 24.3497,
                "lng": 87.9342
            },
            "Velampatti": {
                "lat": 10.2311,
                "lng": 78.2294
            },
            "B\u0101geshwar": {
                "lat": 29.838,
                "lng": 79.771
            },
            "Andiyappan\u016br": {
                "lat": 12.5342,
                "lng": 78.7024
            },
            "Mirz\u0101nagar": {
                "lat": 25.7726,
                "lng": 85.3804
            },
            "Kanas\u0101napalle": {
                "lat": 16.5227,
                "lng": 80.7772
            },
            "Gurmia": {
                "lat": 25.819,
                "lng": 85.2295
            },
            "J\u0101munia": {
                "lat": 25.3676,
                "lng": 87.0477
            },
            "Suganw\u0101n": {
                "lat": 25.1176,
                "lng": 85.0022
            },
            "Pedda Mupp\u0101ram": {
                "lat": 17.4792,
                "lng": 79.6817
            },
            "Mar\u0101i Kal\u0101n": {
                "lat": 24.0211,
                "lng": 85.3506
            },
            "Hagaranahalli": {
                "lat": 12.3677,
                "lng": 76.4173
            },
            "Fatehpur B\u0101la": {
                "lat": 25.8338,
                "lng": 85.6928
            },
            "Virav\u0101da": {
                "lat": 17.1194,
                "lng": 82.211
            },
            "Gidha": {
                "lat": 26.0507,
                "lng": 86.8176
            },
            "Gh\u0101t Bor\u016bl": {
                "lat": 17.77,
                "lng": 77.14
            },
            "P\u016bttai": {
                "lat": 11.8957,
                "lng": 78.8874
            },
            "Venkat\u0101drip\u0101lem": {
                "lat": 16.0506,
                "lng": 79.217
            },
            "Sarauni": {
                "lat": 24.8613,
                "lng": 85.8712
            },
            "Uppugunduru": {
                "lat": 15.673,
                "lng": 80.167
            },
            "T\u0101la": {
                "lat": 24.3735,
                "lng": 81.1989
            },
            "Udarband": {
                "lat": 24.8698,
                "lng": 92.8694
            },
            "Ayanikk\u0101d": {
                "lat": 10.458,
                "lng": 76.126
            },
            "M\u0101noke": {
                "lat": 30.675,
                "lng": 75.456
            },
            "Mamn\u016br": {
                "lat": 17.9092,
                "lng": 79.5926
            },
            "Kh\u0101sbalanda": {
                "lat": 22.5881,
                "lng": 88.6887
            },
            "Janhap\u0101ra": {
                "lat": 21.339,
                "lng": 83.731
            },
            "Warkan": {
                "lat": 25.0331,
                "lng": 72.6862
            },
            "Dadrewa": {
                "lat": 28.6728,
                "lng": 75.2328
            },
            "Ch\u0101rakunda": {
                "lat": 16.6916,
                "lng": 78.7054
            },
            "T\u0101jpur": {
                "lat": 25.9022,
                "lng": 84.5055
            },
            "Gohi Bishunpur": {
                "lat": 25.9275,
                "lng": 85.8626
            },
            "Kurichedu": {
                "lat": 15.9,
                "lng": 79.5833
            },
            "Kann\u0101l": {
                "lat": 18.6938,
                "lng": 79.4288
            },
            "R\u0101mpur Khajuriy\u0101": {
                "lat": 26.3923,
                "lng": 84.7688
            },
            "S\u0101h\u0101pur": {
                "lat": 22.52,
                "lng": 88.17
            },
            "N\u0101dol": {
                "lat": 25.367,
                "lng": 73.45
            },
            "Kaniw\u0101ra": {
                "lat": 22.2145,
                "lng": 79.7366
            },
            "Moman Barodiya": {
                "lat": 23.6042,
                "lng": 76.3364
            },
            "B\u0101bai Kal\u0101n": {
                "lat": 22.8313,
                "lng": 78.6774
            },
            "Dhauni": {
                "lat": 24.9901,
                "lng": 87.0011
            },
            "Madhur\u0101pur": {
                "lat": 25.9456,
                "lng": 85.9759
            },
            "B\u0101sdeopur": {
                "lat": 25.3909,
                "lng": 86.6821
            },
            "Hatti Matt\u016br": {
                "lat": 14.9435,
                "lng": 75.4239
            },
            "H\u0101rua": {
                "lat": 24.5257,
                "lng": 87.9898
            },
            "Mor\u0101nha": {
                "lat": 27.1874,
                "lng": 94.9156
            },
            "Mishrikot": {
                "lat": 15.2465,
                "lng": 75.0567
            },
            "Kambaneri Pudukkudi": {
                "lat": 9.0685,
                "lng": 77.3795
            },
            "M\u0101dhavaram": {
                "lat": 16.89,
                "lng": 81.5937
            },
            "Jangalapalli": {
                "lat": 14.4818,
                "lng": 79.8906
            },
            "Urp\u0101ar": {
                "lat": 31.0537,
                "lng": 75.9652
            },
            "Lohara": {
                "lat": 29.1667,
                "lng": 77.2167
            },
            "Rohera": {
                "lat": 24.6102,
                "lng": 72.964
            },
            "Ugamedi": {
                "lat": 22.001,
                "lng": 71.6481
            },
            "Tikar": {
                "lat": 23.15,
                "lng": 71.1
            },
            "Pat\u016bt": {
                "lat": 25.4658,
                "lng": 84.814
            },
            "Hetanpur": {
                "lat": 25.5821,
                "lng": 85.5495
            },
            "Kalicherla": {
                "lat": 13.8833,
                "lng": 78.5333
            },
            "Dholb\u0101ja": {
                "lat": 26.2674,
                "lng": 87.2761
            },
            "Hireh\u0101luhosahalli": {
                "lat": 15.0105,
                "lng": 76.836
            },
            "R\u0101jod": {
                "lat": 22.9501,
                "lng": 75.064
            },
            "Panasap\u0101du": {
                "lat": 17.0185,
                "lng": 82.2349
            },
            "Settiv\u0101ripalle": {
                "lat": 14.7543,
                "lng": 78.7649
            },
            "Peddannav\u0101ripalle": {
                "lat": 14.2535,
                "lng": 78.3384
            },
            "Devan\u0101ngurichchi": {
                "lat": 11.3895,
                "lng": 77.8547
            },
            "K\u0101ndra": {
                "lat": 22.8517,
                "lng": 86.0519
            },
            "Gudl\u016bru": {
                "lat": 15.0667,
                "lng": 79.9
            },
            "Chundale": {
                "lat": 11.5739,
                "lng": 76.0578
            },
            "Kanchanpalli": {
                "lat": 17.7427,
                "lng": 79.3128
            },
            "Telaprolu": {
                "lat": 16.586,
                "lng": 80.89
            },
            "Seydunganall\u016br": {
                "lat": 8.6624,
                "lng": 77.8293
            },
            "Khiri\u0101w\u0101n": {
                "lat": 25.112,
                "lng": 84.4017
            },
            "Kattam\u016bru": {
                "lat": 17.08,
                "lng": 82.13
            },
            "Hos\u016bru": {
                "lat": 15.3797,
                "lng": 77.4598
            },
            "Sanganakallu": {
                "lat": 15.1847,
                "lng": 76.9703
            },
            "Pulim\u0101kkal": {
                "lat": 9.65,
                "lng": 76.7167
            },
            "Pedda Pendy\u0101la": {
                "lat": 17.923,
                "lng": 79.4085
            },
            "Ponn\u0101da": {
                "lat": 18.2536,
                "lng": 83.9012
            },
            "Bhirua": {
                "lat": 25.8509,
                "lng": 86.3319
            },
            "Barw\u0101n": {
                "lat": 23.9379,
                "lng": 87.935
            },
            "Yek\u0101mbarakuppam": {
                "lat": 13.3168,
                "lng": 79.5678
            },
            "Talevad": {
                "lat": 17.17,
                "lng": 75.96
            },
            "Mahamda": {
                "lat": 25.8108,
                "lng": 84.7874
            },
            "Pararia": {
                "lat": 26.34,
                "lng": 87.5896
            },
            "Lolokhur": {
                "lat": 26.4136,
                "lng": 87.4012
            },
            "Khars\u0101w\u0101n": {
                "lat": 22.7909,
                "lng": 85.831
            },
            "Mudgere": {
                "lat": 13.137,
                "lng": 75.606
            },
            "Tov\u0101la": {
                "lat": 8.2312,
                "lng": 77.506
            },
            "\u016anagatla": {
                "lat": 16.9611,
                "lng": 81.661
            },
            "Sarenja": {
                "lat": 25.4449,
                "lng": 83.8942
            },
            "G\u0101zulapalle": {
                "lat": 15.4036,
                "lng": 78.6176
            },
            "S\u0101nchi": {
                "lat": 23.4865,
                "lng": 77.7378
            },
            "Bayyavaram": {
                "lat": 17.6638,
                "lng": 82.942
            },
            "Kusmaul": {
                "lat": 26.1911,
                "lng": 87.1311
            },
            "Karadge": {
                "lat": 16.42,
                "lng": 74.58
            },
            "Phulhara": {
                "lat": 25.8937,
                "lng": 86.13
            },
            "Vanav\u0101si": {
                "lat": 11.7523,
                "lng": 77.8779
            },
            "Sambhu Chak": {
                "lat": 26.4081,
                "lng": 84.9227
            },
            "Baw\u0101na": {
                "lat": 28.762,
                "lng": 77.063
            },
            "B\u0101ba Bak\u0101la": {
                "lat": 31.555,
                "lng": 75.2656
            },
            "Bhelsi": {
                "lat": 24.7866,
                "lng": 79.1281
            },
            "Jasauli Patti": {
                "lat": 26.4912,
                "lng": 84.8818
            },
            "Dalav\u0101ypattanam": {
                "lat": 10.6747,
                "lng": 77.4845
            },
            "Chitvel": {
                "lat": 14.1667,
                "lng": 79.3333
            },
            "Munag\u0101la": {
                "lat": 17.05,
                "lng": 79.8333
            },
            "Corgao": {
                "lat": 15.7096,
                "lng": 73.7497
            },
            "Bikkatti": {
                "lat": 11.3741,
                "lng": 76.7513
            },
            "Esanai": {
                "lat": 11.3004,
                "lng": 78.8314
            },
            "M\u0101lior": {
                "lat": 25.39,
                "lng": 87.8473
            },
            "Kotabomm\u0101li": {
                "lat": 18.5333,
                "lng": 84.1667
            },
            "Yellanda": {
                "lat": 17.7929,
                "lng": 79.5912
            },
            "Borio": {
                "lat": 25.0344,
                "lng": 87.5925
            },
            "R\u0101mgarha": {
                "lat": 25.7983,
                "lng": 84.9592
            },
            "Belma": {
                "lat": 12.87,
                "lng": 74.84
            },
            "Sampgaon": {
                "lat": 15.79,
                "lng": 74.75
            },
            "Khagaur": {
                "lat": 25.1744,
                "lng": 86.1021
            },
            "Chinna Kalaiyamputt\u016br": {
                "lat": 10.4714,
                "lng": 77.4657
            },
            "Guttikonda": {
                "lat": 16.43,
                "lng": 79.834
            },
            "Kovvali": {
                "lat": 16.7333,
                "lng": 81.1667
            },
            "Balv\u0101di": {
                "lat": 21.4412,
                "lng": 75.223
            },
            "Cholavaram": {
                "lat": 13.2389,
                "lng": 80.1627
            },
            "R\u0101mapattanam": {
                "lat": 10.7106,
                "lng": 76.9183
            },
            "Mangalpur Gudaria": {
                "lat": 26.6513,
                "lng": 84.4832
            },
            "N\u0101nguneri": {
                "lat": 8.4906,
                "lng": 77.6588
            },
            "Khor\u0101g\u0101chhi": {
                "lat": 26.3344,
                "lng": 87.5411
            },
            "Barhagarh": {
                "lat": 19.645,
                "lng": 84.4079
            },
            "Penaballi": {
                "lat": 17.2103,
                "lng": 80.6978
            },
            "Kil\u0101nkundal": {
                "lat": 10.7543,
                "lng": 77.7269
            },
            "Periyap\u014ddu": {
                "lat": 10.6099,
                "lng": 76.8786
            },
            "Segarai": {
                "lat": 10.73,
                "lng": 79.5111
            },
            "Bas\u0101ha": {
                "lat": 26.1329,
                "lng": 86.748
            },
            "Channubanda": {
                "lat": 17.0331,
                "lng": 80.8056
            },
            "Ayas": {
                "lat": 24.2508,
                "lng": 87.7784
            },
            "Enkak\u0101d": {
                "lat": 10.65,
                "lng": 76.25
            },
            "Bhadw\u0101r": {
                "lat": 25.5576,
                "lng": 84.7559
            },
            "Lakhan\u0101puram": {
                "lat": 18.7504,
                "lng": 83.5534
            },
            "Potangal": {
                "lat": 18.5661,
                "lng": 77.7742
            },
            "Tullukuttin\u0101yakkan\u016br": {
                "lat": 9.8149,
                "lng": 77.6808
            },
            "Vadas\u012bkarambattu": {
                "lat": 11.6446,
                "lng": 79.6882
            },
            "Karor": {
                "lat": 25.6018,
                "lng": 86.093
            },
            "Punjai Lakk\u0101puram": {
                "lat": 11.305,
                "lng": 77.7554
            },
            "Kishtw\u0101r": {
                "lat": 33.3135,
                "lng": 75.7673
            },
            "Madd\u016br": {
                "lat": 13.2397,
                "lng": 79.5653
            },
            "W\u0101b\u0101gai": {
                "lat": 24.5309,
                "lng": 93.9376
            },
            "Kanhauli": {
                "lat": 26.4815,
                "lng": 86.1876
            },
            "All\u0101hpur": {
                "lat": 26.3434,
                "lng": 77.8195
            },
            "Melpanaikk\u0101du": {
                "lat": 10.2599,
                "lng": 79.1169
            },
            "Thar\u012bke": {
                "lat": 30.8758,
                "lng": 75.7756
            },
            "Rew\u0101hi": {
                "lat": 26.2698,
                "lng": 87.2124
            },
            "Palangav\u0101ngudi": {
                "lat": 10.7244,
                "lng": 78.8108
            },
            "Majhariy\u0101 Sheikh": {
                "lat": 26.7356,
                "lng": 84.642
            },
            "Polakala": {
                "lat": 13.3458,
                "lng": 78.8167
            },
            "Makaya": {
                "lat": 25.271,
                "lng": 87.8897
            },
            "Gurmaila": {
                "lat": 25.4829,
                "lng": 87.349
            },
            "Alwa Tirunagari": {
                "lat": 8.61,
                "lng": 77.94
            },
            "Khem Karan Saray": {
                "lat": 25.1233,
                "lng": 84.8117
            },
            "B\u0101lum\u0101th": {
                "lat": 23.8298,
                "lng": 84.7888
            },
            "N\u0101nan": {
                "lat": 25.0905,
                "lng": 85.5031
            },
            "Bhagatpur": {
                "lat": 25.4098,
                "lng": 86.3076
            },
            "Padakanti": {
                "lat": 18.6942,
                "lng": 78.2842
            },
            "Kharsod B": {
                "lat": 23.2225,
                "lng": 75.386
            },
            "Mosr\u0101h": {
                "lat": 18.6155,
                "lng": 77.9718
            },
            "Khandrauli": {
                "lat": 29.3888,
                "lng": 77.2793
            },
            "Bhatauli\u0101": {
                "lat": 26.4771,
                "lng": 84.7789
            },
            "\u0100gad\u0101llanka": {
                "lat": 16.7123,
                "lng": 81.2732
            },
            "Ghorbanki": {
                "lat": 26.5697,
                "lng": 86.0484
            },
            "Mall\u0101ram": {
                "lat": 18.754,
                "lng": 78.112
            },
            "Gangapatnam": {
                "lat": 14.5237,
                "lng": 80.1521
            },
            "D\u0101ra": {
                "lat": 26.1546,
                "lng": 86.4483
            },
            "Kamb\u016br": {
                "lat": 10.1599,
                "lng": 78.3264
            },
            "Golakpur": {
                "lat": 25.1777,
                "lng": 85.0728
            },
            "Chiranell\u016br": {
                "lat": 10.6344,
                "lng": 76.1378
            },
            "Ain\u0101pur": {
                "lat": 16.48,
                "lng": 74.78
            },
            "Katahra": {
                "lat": 26.2235,
                "lng": 87.0643
            },
            "Kanakpur": {
                "lat": 24.4976,
                "lng": 88.0361
            },
            "Mannamangalam": {
                "lat": 10.4944,
                "lng": 76.3361
            },
            "Muriy\u0101d": {
                "lat": 10.3667,
                "lng": 76.2667
            },
            "Pullalacheruvu": {
                "lat": 16.1589,
                "lng": 79.3986
            },
            "Sirikonda": {
                "lat": 17.1667,
                "lng": 79.7
            },
            "Jogi\u0101ra": {
                "lat": 26.4083,
                "lng": 85.75
            },
            "Gondauli": {
                "lat": 27.83,
                "lng": 77.88
            },
            "Vannikkonendal": {
                "lat": 8.9959,
                "lng": 77.6245
            },
            "Reni": {
                "lat": 27.161,
                "lng": 76.713
            },
            "Amtala": {
                "lat": 22.3669,
                "lng": 88.2777
            },
            "Sulahpet": {
                "lat": 17.4014,
                "lng": 77.3472
            },
            "Mandalav\u0101di": {
                "lat": 12.6073,
                "lng": 78.6055
            },
            "Sakaddi": {
                "lat": 25.5771,
                "lng": 84.7554
            },
            "Keora": {
                "lat": 25.4374,
                "lng": 85.0726
            },
            "Kadiyadda": {
                "lat": 16.8774,
                "lng": 81.5149
            },
            "Khetko": {
                "lat": 23.7554,
                "lng": 85.9122
            },
            "Shankarpur": {
                "lat": 26.1821,
                "lng": 87.1552
            },
            "Malang\u0101m": {
                "lat": 34.4383,
                "lng": 74.5575
            },
            "Jaimalpura": {
                "lat": 28.9619,
                "lng": 75.9569
            },
            "Karariy\u0101": {
                "lat": 26.5249,
                "lng": 84.791
            },
            "Tirumalaippatti": {
                "lat": 11.3353,
                "lng": 78.2194
            },
            "Sew\u0101ri": {
                "lat": 25.1,
                "lng": 73.283
            },
            "K\u0101lkuni": {
                "lat": 14.6,
                "lng": 74.8333
            },
            "Nimmekal": {
                "lat": 17.2372,
                "lng": 79.6633
            },
            "Nellutla": {
                "lat": 17.7034,
                "lng": 79.1956
            },
            "Pallippatti": {
                "lat": 11.8722,
                "lng": 77.897
            },
            "Madn\u016br": {
                "lat": 18.5,
                "lng": 77.6333
            },
            "Chak Habib": {
                "lat": 25.7238,
                "lng": 85.8789
            },
            "Vinjam": {
                "lat": 13.2544,
                "lng": 79.263
            },
            "Timmapuram": {
                "lat": 17.1036,
                "lng": 81.0781
            },
            "Madan\u0101ncheri": {
                "lat": 12.7034,
                "lng": 78.5991
            },
            "S\u0101nwas": {
                "lat": 25.1795,
                "lng": 85.7148
            },
            "Ablu": {
                "lat": 30.3391,
                "lng": 74.7879
            },
            "T\u0101mganj": {
                "lat": 26.2478,
                "lng": 87.1569
            },
            "Hams\u0101varam": {
                "lat": 17.2938,
                "lng": 82.4942
            },
            "Mah\u0101gaon": {
                "lat": 17.5211,
                "lng": 76.915
            },
            "Satw\u0101r": {
                "lat": 26.2163,
                "lng": 84.512
            },
            "R\u0101mgarh": {
                "lat": 25.2889,
                "lng": 83.6535
            },
            "Jonnagiri": {
                "lat": 15.2303,
                "lng": 77.5714
            },
            "Chakla Waini": {
                "lat": 25.9081,
                "lng": 85.6836
            },
            "Kodavatip\u016bdi": {
                "lat": 17.4887,
                "lng": 82.6046
            },
            "Valasa": {
                "lat": 14.1632,
                "lng": 76.9469
            },
            "B\u0101smanpur": {
                "lat": 26.6433,
                "lng": 84.9789
            },
            "Vadapalanji": {
                "lat": 9.9266,
                "lng": 78.0096
            },
            "Adigoppula": {
                "lat": 16.4402,
                "lng": 79.6235
            },
            "Rangv\u0101sa": {
                "lat": 22.6435,
                "lng": 75.7886
            },
            "M\u0101nsong": {
                "lat": 27.1628,
                "lng": 88.5661
            },
            "B\u0101run": {
                "lat": 24.8602,
                "lng": 84.2232
            },
            "Sorala": {
                "lat": 19.1297,
                "lng": 84.7506
            },
            "M\u0101lip\u0101kar": {
                "lat": 25.3621,
                "lng": 87.9144
            },
            "Iklod": {
                "lat": 26.0222,
                "lng": 77.3208
            },
            "Dhobipet": {
                "lat": 17.4716,
                "lng": 78.087
            },
            "Ranjal": {
                "lat": 18.7458,
                "lng": 77.9483
            },
            "Tillaivil\u0101gam": {
                "lat": 10.4127,
                "lng": 79.5547
            },
            "Penugolanu": {
                "lat": 16.9771,
                "lng": 80.4369
            },
            "Vanduv\u0101ncheri": {
                "lat": 10.4292,
                "lng": 79.6498
            },
            "Bommayap\u0101laiyam": {
                "lat": 11.9922,
                "lng": 79.8499
            },
            "Maisaram": {
                "lat": 17.1329,
                "lng": 78.4367
            },
            "Baikunthapur": {
                "lat": 22.9808,
                "lng": 88.2058
            },
            "Rautara": {
                "lat": 25.663,
                "lng": 87.5378
            },
            "Miy\u0101r": {
                "lat": 13.1945,
                "lng": 75.0484
            },
            "Varatanapalli": {
                "lat": 12.5828,
                "lng": 78.302
            },
            "Gholia Kal\u0101n": {
                "lat": 30.6726,
                "lng": 75.18
            },
            "Sow\u0101n": {
                "lat": 25.554,
                "lng": 84.2334
            },
            "Dhar\u0101wat": {
                "lat": 25.0592,
                "lng": 85.042
            },
            "Garsekurti": {
                "lat": 18.5083,
                "lng": 78.9774
            },
            "Bh\u0101saula D\u0101n\u0101pur": {
                "lat": 25.5509,
                "lng": 85.0308
            },
            "Kampel": {
                "lat": 22.6167,
                "lng": 76.05
            },
            "Bachchannapet": {
                "lat": 17.7883,
                "lng": 79.0349
            },
            "Yeland\u016br": {
                "lat": 12.07,
                "lng": 77.03
            },
            "K\u016bnimedu": {
                "lat": 12.0885,
                "lng": 79.894
            },
            "Bundehra": {
                "lat": 25.3776,
                "lng": 86.7376
            },
            "Adiyakkamangalam": {
                "lat": 10.7626,
                "lng": 79.6775
            },
            "Deodora": {
                "lat": 22.6149,
                "lng": 80.3616
            },
            "Somarasampettai": {
                "lat": 10.8125,
                "lng": 78.6342
            },
            "Kusumbe": {
                "lat": 20.9167,
                "lng": 74.65
            },
            "Paratdiha": {
                "lat": 24.1638,
                "lng": 86.307
            },
            "Muturkha": {
                "lat": 24.1044,
                "lng": 86.2855
            },
            "Kirr\u0101yach": {
                "lat": 26.6322,
                "lng": 78.3423
            },
            "Hirni": {
                "lat": 25.8323,
                "lng": 86.2806
            },
            "Chinna Mupparam": {
                "lat": 17.6319,
                "lng": 79.8411
            },
            "Sr\u012br\u0101mapuram": {
                "lat": 16.18,
                "lng": 80.29
            },
            "Hardiya": {
                "lat": 25.5301,
                "lng": 84.4961
            },
            "\u012adupugallu": {
                "lat": 16.4609,
                "lng": 80.7543
            },
            "R\u0101mpatti": {
                "lat": 26.0028,
                "lng": 86.8014
            },
            "Vidapanakallu": {
                "lat": 15.0667,
                "lng": 77.1833
            },
            "Abir\u0101mam": {
                "lat": 9.4423,
                "lng": 78.4399
            },
            "Z\u016blakallu": {
                "lat": 16.4442,
                "lng": 79.7874
            },
            "Pukkulam": {
                "lat": 9.277,
                "lng": 78.9938
            },
            "R\u0101ni S\u0101wargaon": {
                "lat": 18.9655,
                "lng": 76.7493
            },
            "Hiramandalam": {
                "lat": 18.6708,
                "lng": 83.9505
            },
            "D\u0101mal": {
                "lat": 12.886,
                "lng": 79.5922
            },
            "B\u016bdamangalam": {
                "lat": 12.3792,
                "lng": 79.2308
            },
            "Angichettipp\u0101laiyam": {
                "lat": 11.7742,
                "lng": 79.5255
            },
            "Pasivedalajimma": {
                "lat": 16.9952,
                "lng": 81.7
            },
            "Tilvalli": {
                "lat": 14.6268,
                "lng": 75.231
            },
            "S\u0101nrha": {
                "lat": 25.7979,
                "lng": 84.7504
            },
            "Jabera": {
                "lat": 23.5582,
                "lng": 79.678
            },
            "Ranod": {
                "lat": 25.0748,
                "lng": 77.8761
            },
            "Kanav\u0101ypud\u016br": {
                "lat": 11.9282,
                "lng": 78.1832
            },
            "Rahta": {
                "lat": 25.9213,
                "lng": 86.9734
            },
            "Teranikallu": {
                "lat": 15.6392,
                "lng": 77.5303
            },
            "Dhanauli": {
                "lat": 26.0026,
                "lng": 86.0251
            },
            "Pull\u0101nvidudi": {
                "lat": 10.359,
                "lng": 79.088
            },
            "Vadak\u0101du": {
                "lat": 10.3418,
                "lng": 79.0609
            },
            "Hakka": {
                "lat": 25.9163,
                "lng": 87.6474
            },
            "Pedda Nindrakolanu": {
                "lat": 16.7342,
                "lng": 81.3941
            },
            "Rudravaram": {
                "lat": 15.266,
                "lng": 78.628
            },
            "Sanj\u0101t": {
                "lat": 25.6046,
                "lng": 86.0199
            },
            "M\u0101laim\u0101rpuram": {
                "lat": 14.2096,
                "lng": 79.3072
            },
            "Mirchpur": {
                "lat": 29.316,
                "lng": 76.1755
            },
            "Bhui": {
                "lat": 25.0894,
                "lng": 85.3888
            },
            "Ekangar Sarai": {
                "lat": 25.2234,
                "lng": 85.2298
            },
            "Velakalnattam": {
                "lat": 12.5505,
                "lng": 78.4429
            },
            "Bharno": {
                "lat": 23.2204,
                "lng": 84.8891
            },
            "Gundum\u0101l": {
                "lat": 16.8939,
                "lng": 77.7112
            },
            "Karuveppampatti": {
                "lat": 11.3983,
                "lng": 77.8746
            },
            "S\u0101tg\u0101chia": {
                "lat": 23.2641,
                "lng": 88.16
            },
            "Jal\u0101lkhera": {
                "lat": 21.4392,
                "lng": 78.7719
            },
            "Majra": {
                "lat": 25.7131,
                "lng": 87.3637
            },
            "N\u0101gaiyampatti": {
                "lat": 11.4639,
                "lng": 78.4801
            },
            "Tsr\u0101r Shar\u012bf": {
                "lat": 33.8632,
                "lng": 74.7652
            },
            "Pendekallu": {
                "lat": 15.3753,
                "lng": 77.6242
            },
            "N\u0101rona": {
                "lat": 17.5156,
                "lng": 76.7182
            },
            "R\u0101gampet": {
                "lat": 18.6383,
                "lng": 79.1441
            },
            "Pillutla": {
                "lat": 16.539,
                "lng": 79.9044
            },
            "V\u012brapalle": {
                "lat": 14.1556,
                "lng": 78.8569
            },
            "Navappatti": {
                "lat": 11.7393,
                "lng": 77.7836
            },
            "Punnappatti": {
                "lat": 10.2243,
                "lng": 78.1982
            },
            "Ak\u0101lgarh": {
                "lat": 30.8019,
                "lng": 75.8582
            },
            "Angallu": {
                "lat": 13.6287,
                "lng": 78.4857
            },
            "Barbana": {
                "lat": 26.0719,
                "lng": 87.2539
            },
            "Bommagondanahalli": {
                "lat": 13.8984,
                "lng": 77.0733
            },
            "Az\u012bzpur Ch\u0101nde": {
                "lat": 25.7932,
                "lng": 85.5945
            },
            "Uchen": {
                "lat": 27.0931,
                "lng": 77.4097
            },
            "Sohta": {
                "lat": 26.2149,
                "lng": 87.0826
            },
            "Doiw\u0101la": {
                "lat": 30.176,
                "lng": 78.124
            },
            "Gang\u0101jalgh\u0101ti": {
                "lat": 23.42,
                "lng": 87.12
            },
            "Bh\u0101nuvalli": {
                "lat": 14.4333,
                "lng": 75.7667
            },
            "Janglot": {
                "lat": 32.4353,
                "lng": 75.5231
            },
            "Sini": {
                "lat": 22.7933,
                "lng": 85.9454
            },
            "Koppunur": {
                "lat": 16.4867,
                "lng": 79.3318
            },
            "Vadacheri": {
                "lat": 10.7324,
                "lng": 78.5261
            },
            "Raia": {
                "lat": 15.4969,
                "lng": 73.8089
            },
            "Pulivalam": {
                "lat": 10.7525,
                "lng": 79.6352
            },
            "Tonk Khurd": {
                "lat": 23.0983,
                "lng": 76.2155
            },
            "Karadip\u0101ra": {
                "lat": 10.55,
                "lng": 76.5833
            },
            "Pokhraira": {
                "lat": 26.0711,
                "lng": 85.2034
            },
            "P\u0101pp\u0101kudi": {
                "lat": 8.752,
                "lng": 77.5026
            },
            "N\u0101ttarasankottai": {
                "lat": 9.869,
                "lng": 78.5531
            },
            "Mirdoddi": {
                "lat": 18.0786,
                "lng": 78.677
            },
            "Dharhwa": {
                "lat": 26.9104,
                "lng": 84.2769
            },
            "Gadaul": {
                "lat": 26.1287,
                "lng": 86.2375
            },
            "Katr\u012bdih": {
                "lat": 25.0829,
                "lng": 85.6334
            },
            "Painkulam": {
                "lat": 10.7375,
                "lng": 76.3125
            },
            "Koshanam": {
                "lat": 11.3701,
                "lng": 77.3523
            },
            "Mur\u0101jpur": {
                "lat": 25.9506,
                "lng": 86.4713
            },
            "B\u0101galv\u0101d": {
                "lat": 16.0528,
                "lng": 76.8877
            },
            "Gobindpur": {
                "lat": 24.782,
                "lng": 85.6943
            },
            "Kambhamp\u0101du": {
                "lat": 16.9853,
                "lng": 80.6534
            },
            "Py\u0101lakurti": {
                "lat": 15.7286,
                "lng": 77.8168
            },
            "Kursaha": {
                "lat": 25.5588,
                "lng": 85.6875
            },
            "Suttamalli": {
                "lat": 8.6987,
                "lng": 77.6323
            },
            "Kott\u016br": {
                "lat": 9.9038,
                "lng": 77.4071
            },
            "Ch\u0101kand": {
                "lat": 24.8907,
                "lng": 84.9761
            },
            "Guntapalli": {
                "lat": 14.7385,
                "lng": 79.04
            },
            "Herseh Chh\u012bna": {
                "lat": 31.7453,
                "lng": 74.783
            },
            "Bhatkhori": {
                "lat": 25.9156,
                "lng": 86.9072
            },
            "Parasbani": {
                "lat": 25.8249,
                "lng": 87.2983
            },
            "Ami\u0101w\u0101r": {
                "lat": 25.0405,
                "lng": 84.3096
            },
            "Jujh\u0101rpur": {
                "lat": 25.7514,
                "lng": 87.9634
            },
            "Mustaf\u0101b\u0101d": {
                "lat": 18.2787,
                "lng": 78.7108
            },
            "Pathr\u0101ha": {
                "lat": 26.433,
                "lng": 87.0743
            },
            "Har\u012bke": {
                "lat": 31.1663,
                "lng": 74.9418
            },
            "Chintakommadinne": {
                "lat": 14.4267,
                "lng": 78.7618
            },
            "Nagdah": {
                "lat": 26.25,
                "lng": 86.0
            },
            "Tumberi": {
                "lat": 12.6986,
                "lng": 78.5856
            },
            "Dil\u0101warpur": {
                "lat": 19.0908,
                "lng": 78.2275
            },
            "Belhatti": {
                "lat": 15.0818,
                "lng": 75.6465
            },
            "Eduttav\u0101ynattam": {
                "lat": 11.8057,
                "lng": 78.827
            },
            "Bikrampur B\u0101nde": {
                "lat": 25.8415,
                "lng": 85.7424
            },
            "Chak Pah\u0101r": {
                "lat": 25.7986,
                "lng": 85.6218
            },
            "Onda": {
                "lat": 25.2452,
                "lng": 85.665
            },
            "Salar": {
                "lat": 33.8789,
                "lng": 75.2485
            },
            "Th\u0101th\u016bpur": {
                "lat": 25.9731,
                "lng": 86.0296
            },
            "Vanuk\u016bru": {
                "lat": 16.4406,
                "lng": 80.7379
            },
            "Kottadindulu": {
                "lat": 16.3518,
                "lng": 81.5915
            },
            "Bishamagiri": {
                "lat": 19.3849,
                "lng": 84.4715
            },
            "Dirusumarru": {
                "lat": 16.4722,
                "lng": 81.5295
            },
            "Nandn\u0101w\u0101n": {
                "lat": 25.0785,
                "lng": 86.096
            },
            "Sobh\u0101pur": {
                "lat": 22.7737,
                "lng": 78.2638
            },
            "K\u0101oni": {
                "lat": 30.4,
                "lng": 74.663
            },
            "Mahadipur": {
                "lat": 24.8566,
                "lng": 88.1248
            },
            "Bisaul": {
                "lat": 26.6151,
                "lng": 85.993
            },
            "Pinjranw\u0101n": {
                "lat": 25.1702,
                "lng": 84.8203
            },
            "Maina": {
                "lat": 23.1712,
                "lng": 76.6629
            },
            "Rangw\u0101sa": {
                "lat": 22.6419,
                "lng": 75.7893
            },
            "Pars\u0101d": {
                "lat": 26.1586,
                "lng": 86.3579
            },
            "Tokatippa": {
                "lat": 16.2836,
                "lng": 81.5024
            },
            "Ul\u0101tu": {
                "lat": 23.2766,
                "lng": 85.5012
            },
            "Gokhul\u0101pur": {
                "lat": 26.2479,
                "lng": 87.1137
            },
            "K\u012blakkurichchi": {
                "lat": 10.7645,
                "lng": 78.7406
            },
            "Kattirippulam": {
                "lat": 10.464,
                "lng": 79.7597
            },
            "R\u0101jhanpur": {
                "lat": 25.7885,
                "lng": 86.4647
            },
            "Midalam": {
                "lat": 8.206,
                "lng": 77.216
            },
            "Khandauli": {
                "lat": 27.3125,
                "lng": 78.0289
            },
            "Basr\u016br": {
                "lat": 13.6308,
                "lng": 74.7377
            },
            "Mannegudam": {
                "lat": 17.4601,
                "lng": 79.9177
            },
            "Chettiyapatti": {
                "lat": 10.424,
                "lng": 77.8228
            },
            "Segaon": {
                "lat": 21.8585,
                "lng": 75.3293
            },
            "T\u0101razu": {
                "lat": 34.2714,
                "lng": 74.5009
            },
            "Panjampatti": {
                "lat": 10.3178,
                "lng": 77.9263
            },
            "Hathi\u0101k\u0101n": {
                "lat": 25.606,
                "lng": 84.9997
            },
            "R\u0101iparthi": {
                "lat": 17.7042,
                "lng": 79.6081
            },
            "Hire Vadvatti": {
                "lat": 15.2235,
                "lng": 75.7831
            },
            "M\u0101gam": {
                "lat": 34.4595,
                "lng": 74.2306
            },
            "Said\u0101b\u0101d": {
                "lat": 25.5489,
                "lng": 85.3087
            },
            "Ch\u0101np": {
                "lat": 26.1951,
                "lng": 84.39
            },
            "M\u0101npur": {
                "lat": 22.4315,
                "lng": 75.6211
            },
            "Pirojgarh": {
                "lat": 26.2769,
                "lng": 86.4389
            },
            "Antargangi": {
                "lat": 17.17,
                "lng": 75.96
            },
            "Kharod": {
                "lat": 21.75,
                "lng": 82.57
            },
            "Teghra": {
                "lat": 26.4929,
                "lng": 86.368
            },
            "Achchampeta": {
                "lat": 17.0941,
                "lng": 82.3099
            },
            "Khaur": {
                "lat": 32.8229,
                "lng": 74.5112
            },
            "Pasaul": {
                "lat": 26.2647,
                "lng": 85.6756
            },
            "Jainagar": {
                "lat": 24.3756,
                "lng": 85.6443
            },
            "S\u012blaiyampatti": {
                "lat": 9.8732,
                "lng": 77.3927
            },
            "Kanav\u0101ypatti": {
                "lat": 10.1857,
                "lng": 77.7347
            },
            "Mudichchur": {
                "lat": 12.911,
                "lng": 80.0693
            },
            "Shyamnagar": {
                "lat": 22.97,
                "lng": 88.38
            },
            "Velim": {
                "lat": 15.1644,
                "lng": 73.9759
            },
            "H\u0101ta": {
                "lat": 25.046,
                "lng": 83.4632
            },
            "B\u0101lakrishnanpatti": {
                "lat": 11.2511,
                "lng": 78.4736
            },
            "Amingaon": {
                "lat": 26.23,
                "lng": 91.67
            },
            "Pariy\u0101ri": {
                "lat": 25.2128,
                "lng": 84.7829
            },
            "Koila": {
                "lat": 25.455,
                "lng": 86.771
            },
            "Kany\u0101kulam": {
                "lat": 8.2012,
                "lng": 77.4015
            },
            "\u0100mud\u0101lapalle": {
                "lat": 15.9301,
                "lng": 80.6899
            },
            "Piliv": {
                "lat": 17.6788,
                "lng": 74.9664
            },
            "Sr\u012brang\u0101pur": {
                "lat": 16.1917,
                "lng": 78.0507
            },
            "Anantas\u0101garam": {
                "lat": 14.5833,
                "lng": 79.4167
            },
            "Gund\u016br": {
                "lat": 10.7339,
                "lng": 78.7184
            },
            "Jagta": {
                "lat": 26.1321,
                "lng": 87.1911
            },
            "Bandam\u016brlanka": {
                "lat": 16.517,
                "lng": 81.988
            },
            "K\u0101liganj": {
                "lat": 23.7348,
                "lng": 88.2293
            },
            "Hosuru": {
                "lat": 13.7399,
                "lng": 77.4312
            },
            "Nunih\u0101t": {
                "lat": 24.4843,
                "lng": 87.1323
            },
            "Hale Dy\u0101mavvanahalli": {
                "lat": 14.2682,
                "lng": 76.4904
            },
            "V\u012brapperum\u0101nall\u016br": {
                "lat": 11.7763,
                "lng": 79.4466
            },
            "Nett\u0101dahalli": {
                "lat": 12.133,
                "lng": 78.0671
            },
            "R\u0101ni S\u0101gar": {
                "lat": 25.6079,
                "lng": 84.3626
            },
            "L\u0101lam": {
                "lat": 9.7167,
                "lng": 76.7
            },
            "Charu\u0101nw\u0101n": {
                "lat": 25.1348,
                "lng": 85.6812
            },
            "Shidd\u0101p\u016br": {
                "lat": 13.6903,
                "lng": 74.9149
            },
            "Babhangaon": {
                "lat": 25.7897,
                "lng": 86.9767
            },
            "Gamail": {
                "lat": 25.7062,
                "lng": 86.9914
            },
            "L\u0101ndupd\u012bh": {
                "lat": 23.1478,
                "lng": 85.7817
            },
            "Dharm\u0101jig\u016bdem": {
                "lat": 16.54,
                "lng": 81.0
            },
            "Navipet": {
                "lat": 18.8022,
                "lng": 77.9836
            },
            "Midut\u016bru": {
                "lat": 15.7667,
                "lng": 78.3
            },
            "Ch\u0101palamadugu": {
                "lat": 16.073,
                "lng": 79.3775
            },
            "D\u0101la": {
                "lat": 30.7773,
                "lng": 75.2494
            },
            "Raonta": {
                "lat": 30.5619,
                "lng": 75.19
            },
            "Harewa": {
                "lat": 25.6831,
                "lng": 86.5909
            },
            "Lohna": {
                "lat": 26.2314,
                "lng": 86.2095
            },
            "Kodaimangalam": {
                "lat": 10.4733,
                "lng": 77.507
            },
            "Yaragol": {
                "lat": 16.9047,
                "lng": 77.0661
            },
            "Modachch\u016br": {
                "lat": 11.4415,
                "lng": 77.4288
            },
            "Sakhua": {
                "lat": 26.1734,
                "lng": 86.7624
            },
            "Nes\u0101rg": {
                "lat": 15.9069,
                "lng": 74.774
            },
            "Vairichettip\u0101laiyam": {
                "lat": 11.2872,
                "lng": 78.4893
            },
            "N\u016blvi": {
                "lat": 15.2728,
                "lng": 75.1673
            },
            "Sukand": {
                "lat": 26.6444,
                "lng": 78.4752
            },
            "T\u0101din\u0101da": {
                "lat": 16.547,
                "lng": 81.3219
            },
            "P\u0101ta Upp\u0101l": {
                "lat": 18.1799,
                "lng": 79.4762
            },
            "Yamkanmardi": {
                "lat": 16.128,
                "lng": 74.527
            },
            "Reddiyapatti": {
                "lat": 10.1581,
                "lng": 78.1901
            },
            "Kolumalapalle": {
                "lat": 15.4774,
                "lng": 78.1293
            },
            "Tsallagundla": {
                "lat": 16.3522,
                "lng": 79.9619
            },
            "Puran Bigha": {
                "lat": 24.839,
                "lng": 84.854
            },
            "Kandan\u0101ti": {
                "lat": 15.6997,
                "lng": 77.4757
            },
            "Talwandi Chaudhri\u0101n": {
                "lat": 31.3,
                "lng": 75.1764
            },
            "N\u0101ngal Chaudhri": {
                "lat": 27.8923,
                "lng": 76.1122
            },
            "T\u0101lakulam": {
                "lat": 8.1824,
                "lng": 77.3161
            },
            "Guria": {
                "lat": 26.1633,
                "lng": 86.9645
            },
            "Karupp\u016br": {
                "lat": 10.4918,
                "lng": 78.3296
            },
            "Dattap\u0101ra": {
                "lat": 22.8491,
                "lng": 88.9023
            },
            "Mangala": {
                "lat": 11.9998,
                "lng": 76.9594
            },
            "Poninguinim": {
                "lat": 14.9679,
                "lng": 74.0865
            },
            "Keregodu": {
                "lat": 12.6333,
                "lng": 76.9167
            },
            "Kas\u0101p": {
                "lat": 25.4702,
                "lng": 84.6087
            },
            "Phopn\u0101r Kal\u0101n": {
                "lat": 21.2365,
                "lng": 76.3268
            },
            "Nijgaon Par\u0101npur": {
                "lat": 25.1559,
                "lng": 87.9732
            },
            "Naw\u0101nagar": {
                "lat": 25.3861,
                "lng": 84.2069
            },
            "Th\u0101ndew\u0101la": {
                "lat": 30.472,
                "lng": 74.5898
            },
            "Khutha Baijn\u0101th": {
                "lat": 26.0268,
                "lng": 87.117
            },
            "Kandan\u016br": {
                "lat": 10.1037,
                "lng": 78.8254
            },
            "Medikunda": {
                "lat": 15.9581,
                "lng": 77.6095
            },
            "Andanappettai": {
                "lat": 10.7498,
                "lng": 79.8226
            },
            "Perungulam": {
                "lat": 8.6413,
                "lng": 77.9985
            },
            "Dodvad": {
                "lat": 15.79,
                "lng": 74.75
            },
            "H\u0101th\u0101pur": {
                "lat": 26.5609,
                "lng": 86.0247
            },
            "S\u0101garpur": {
                "lat": 26.2306,
                "lng": 86.0732
            },
            "Darsur": {
                "lat": 25.9711,
                "lng": 85.9148
            },
            "Kuchai Kot": {
                "lat": 26.5559,
                "lng": 84.3292
            },
            "Mandalapalle": {
                "lat": 14.0209,
                "lng": 77.7511
            },
            "Pachrukhi": {
                "lat": 26.1593,
                "lng": 84.4087
            },
            "Repala": {
                "lat": 15.0,
                "lng": 79.7
            },
            "Pedda Penki": {
                "lat": 18.5853,
                "lng": 83.5069
            },
            "Nottampatti": {
                "lat": 9.9772,
                "lng": 77.7177
            },
            "N\u0101likkalpatti": {
                "lat": 11.6021,
                "lng": 78.134
            },
            "Chinaur": {
                "lat": 25.9467,
                "lng": 78.1024
            },
            "Munnelli": {
                "lat": 14.92,
                "lng": 78.9546
            },
            "Tar\u012bchar Kal\u0101n": {
                "lat": 25.4118,
                "lng": 78.89
            },
            "Jaitpura": {
                "lat": 26.9231,
                "lng": 75.808
            },
            "Navs\u0101ri": {
                "lat": 20.8504,
                "lng": 72.92
            },
            "Tonk": {
                "lat": 26.1505,
                "lng": 75.79
            }
        }
    },
    "CN": {
        "name": "China",
        "cities": {
            "Guangzhou": {
                "lat": 23.13,
                "lng": 113.26
            },
            "Shanghai": {
                "lat": 31.2286,
                "lng": 121.4747
            },
            "Beijing": {
                "lat": 39.9067,
                "lng": 116.3975
            },
            "Shenzhen": {
                "lat": 22.5415,
                "lng": 114.0596
            },
            "Chengdu": {
                "lat": 30.66,
                "lng": 104.0633
            },
            "Xi\u2019an": {
                "lat": 34.2611,
                "lng": 108.9422
            },
            "Chongqing": {
                "lat": 29.5637,
                "lng": 106.5504
            },
            "Baoding": {
                "lat": 38.874,
                "lng": 115.464
            },
            "Linyi": {
                "lat": 35.1038,
                "lng": 118.3564
            },
            "Dongguan": {
                "lat": 39.014,
                "lng": 111.0768
            },
            "Tianjin": {
                "lat": 39.1336,
                "lng": 117.2054
            },
            "Wuhan": {
                "lat": 30.5934,
                "lng": 114.3046
            },
            "Nanyang": {
                "lat": 32.9987,
                "lng": 112.5292
            },
            "Hangzhou": {
                "lat": 30.267,
                "lng": 120.153
            },
            "Foshan": {
                "lat": 23.0214,
                "lng": 113.1216
            },
            "Tongshan": {
                "lat": 34.204,
                "lng": 117.284
            },
            "Zhoukou": {
                "lat": 33.625,
                "lng": 114.6418
            },
            "Ganzhou": {
                "lat": 25.831,
                "lng": 114.933
            },
            "Heze": {
                "lat": 35.2343,
                "lng": 115.4796
            },
            "Quanzhou": {
                "lat": 24.8744,
                "lng": 118.6757
            },
            "Nanjing": {
                "lat": 32.0608,
                "lng": 118.7789
            },
            "Jining": {
                "lat": 41.03,
                "lng": 113.08
            },
            "Fuyang": {
                "lat": 30.0553,
                "lng": 119.95
            },
            "Shenyang": {
                "lat": 41.8025,
                "lng": 123.4281
            },
            "Shangqiu": {
                "lat": 34.415,
                "lng": 115.656
            },
            "Cangzhou": {
                "lat": 38.3047,
                "lng": 116.8387
            },
            "Xingtai": {
                "lat": 37.0717,
                "lng": 114.5048
            },
            "Zhumadian": {
                "lat": 33.014,
                "lng": 114.022
            },
            "Zhanjiang": {
                "lat": 21.2701,
                "lng": 110.3575
            },
            "Bijie": {
                "lat": 27.284,
                "lng": 105.292
            },
            "Yancheng": {
                "lat": 33.3482,
                "lng": 120.1626
            },
            "Hengyang": {
                "lat": 26.894,
                "lng": 112.572
            },
            "Zunyi": {
                "lat": 27.722,
                "lng": 107.031
            },
            "Shaoyang": {
                "lat": 32.912,
                "lng": 119.8526
            },
            "Shangrao": {
                "lat": 28.4551,
                "lng": 117.9431
            },
            "Xinyang": {
                "lat": 32.149,
                "lng": 114.091
            },
            "Maoming": {
                "lat": 21.6627,
                "lng": 110.9255
            },
            "Jieyang": {
                "lat": 23.551,
                "lng": 116.3727
            },
            "Liaocheng": {
                "lat": 36.4559,
                "lng": 115.9852
            },
            "Huanggang": {
                "lat": 30.4537,
                "lng": 114.8724
            },
            "Dalian": {
                "lat": 38.9,
                "lng": 121.6
            },
            "Qingdao": {
                "lat": 36.0669,
                "lng": 120.3827
            },
            "Yulin": {
                "lat": 22.654,
                "lng": 110.181
            },
            "Qujing": {
                "lat": 25.491,
                "lng": 103.796
            },
            "Nangandao": {
                "lat": 35.3036,
                "lng": 113.9268
            },
            "Pudong": {
                "lat": 31.2347,
                "lng": 121.5064
            },
            "Zhengzhou": {
                "lat": 34.764,
                "lng": 113.684
            },
            "Dezhou": {
                "lat": 37.436,
                "lng": 116.359
            },
            "Nanchong": {
                "lat": 30.8372,
                "lng": 106.1106
            },
            "Jinan": {
                "lat": 36.6702,
                "lng": 117.0207
            },
            "Tai\u2019an": {
                "lat": 36.202,
                "lng": 117.087
            },
            "Langfang": {
                "lat": 39.5383,
                "lng": 116.6835
            },
            "Dazhou": {
                "lat": 31.2093,
                "lng": 107.4678
            },
            "Suzhou": {
                "lat": 31.3,
                "lng": 120.6194
            },
            "Yongzhou": {
                "lat": 26.42,
                "lng": 111.613
            },
            "Changde": {
                "lat": 29.031,
                "lng": 111.699
            },
            "Xiangyang": {
                "lat": 34.2995,
                "lng": 108.4905
            },
            "Zhaotong": {
                "lat": 27.338,
                "lng": 103.717
            },
            "Zhangzhou": {
                "lat": 24.513,
                "lng": 117.647
            },
            "Yichun": {
                "lat": 47.728,
                "lng": 128.841
            },
            "Bozhou": {
                "lat": 33.8626,
                "lng": 115.7742
            },
            "Suqian": {
                "lat": 33.9331,
                "lng": 118.2831
            },
            "Ji\u2019an": {
                "lat": 27.0912,
                "lng": 114.9668
            },
            "Guilin": {
                "lat": 25.275,
                "lng": 110.296
            },
            "Pingdingshan": {
                "lat": 33.735,
                "lng": 113.2999
            },
            "Mianyang": {
                "lat": 31.468,
                "lng": 104.679
            },
            "Huanglongsi": {
                "lat": 34.795,
                "lng": 114.345
            },
            "Yuncheng": {
                "lat": 35.0267,
                "lng": 111.007
            },
            "Changsha": {
                "lat": 22.3773,
                "lng": 112.6982
            },
            "Weinan": {
                "lat": 34.5206,
                "lng": 109.471
            },
            "Chenzhou": {
                "lat": 25.77,
                "lng": 113.016
            },
            "Jiangmen": {
                "lat": 22.5789,
                "lng": 113.0815
            },
            "Jiujiang": {
                "lat": 29.661,
                "lng": 115.954
            },
            "Xinpu": {
                "lat": 34.5966,
                "lng": 119.2214
            },
            "Yibin": {
                "lat": 28.752,
                "lng": 104.643
            },
            "Huaihua": {
                "lat": 27.5698,
                "lng": 110.0016
            },
            "Yangzhou": {
                "lat": 32.3912,
                "lng": 119.4363
            },
            "Taizhou": {
                "lat": 28.6557,
                "lng": 121.4208
            },
            "Kunming": {
                "lat": 25.0464,
                "lng": 102.7094
            },
            "Yiyang": {
                "lat": 26.4221,
                "lng": 112.3999
            },
            "Changchun": {
                "lat": 43.897,
                "lng": 125.326
            },
            "Lu\u2019an": {
                "lat": 31.736,
                "lng": 116.52
            },
            "Jiangguanchi": {
                "lat": 34.0244,
                "lng": 113.8201
            },
            "Meizhou": {
                "lat": 24.289,
                "lng": 116.122
            },
            "\u00dcr\u00fcmqi": {
                "lat": 43.8225,
                "lng": 87.6125
            },
            "Guigang": {
                "lat": 23.112,
                "lng": 109.599
            },
            "Shantou": {
                "lat": 23.354,
                "lng": 116.682
            },
            "Xiaoganzhan": {
                "lat": 30.918,
                "lng": 113.957
            },
            "Luzhou": {
                "lat": 23.3686,
                "lng": 114.5194
            },
            "Hefei": {
                "lat": 31.8206,
                "lng": 117.2273
            },
            "Hengshui": {
                "lat": 37.739,
                "lng": 115.669
            },
            "Anqing": {
                "lat": 30.5318,
                "lng": 117.1153
            },
            "Liuzhou": {
                "lat": 24.3264,
                "lng": 109.4281
            },
            "Zhangjiakou": {
                "lat": 40.769,
                "lng": 114.886
            },
            "Zhaoqing": {
                "lat": 23.05,
                "lng": 112.4667
            },
            "Shijiazhuang": {
                "lat": 38.0425,
                "lng": 114.51
            },
            "Ningbo": {
                "lat": 29.8603,
                "lng": 121.6245
            },
            "Qiqihar": {
                "lat": 47.3549,
                "lng": 123.9182
            },
            "Fuzhou": {
                "lat": 26.0743,
                "lng": 119.2964
            },
            "Chifeng": {
                "lat": 42.257,
                "lng": 118.888
            },
            "Xiaoxita": {
                "lat": 30.692,
                "lng": 111.287
            },
            "Chuzhou": {
                "lat": 32.256,
                "lng": 118.333
            },
            "Linfen": {
                "lat": 36.088,
                "lng": 111.519
            },
            "Qingyuan": {
                "lat": 24.5004,
                "lng": 108.6667
            },
            "Xianyang": {
                "lat": 34.3299,
                "lng": 108.7088
            },
            "Loudi": {
                "lat": 27.6998,
                "lng": 111.9944
            },
            "Binzhou": {
                "lat": 37.383,
                "lng": 117.971
            },
            "Zhuzhou": {
                "lat": 27.829,
                "lng": 113.133
            },
            "Taiyuan": {
                "lat": 37.8704,
                "lng": 112.5497
            },
            "Nanning": {
                "lat": 22.8167,
                "lng": 108.3275
            },
            "Harbin": {
                "lat": 45.7576,
                "lng": 126.6409
            },
            "Suihua": {
                "lat": 46.654,
                "lng": 126.969
            },
            "Zaozhuang": {
                "lat": 34.8109,
                "lng": 117.3238
            },
            "Xiamen": {
                "lat": 24.4796,
                "lng": 118.0889
            },
            "Neijiang": {
                "lat": 29.5802,
                "lng": 105.058
            },
            "Baicheng": {
                "lat": 41.7957,
                "lng": 81.8715
            },
            "Wuhu": {
                "lat": 31.3526,
                "lng": 118.4331
            },
            "Yulinshi": {
                "lat": 38.2858,
                "lng": 109.7341
            },
            "Wenzhou": {
                "lat": 27.9938,
                "lng": 120.6993
            },
            "Changzhou": {
                "lat": 31.811,
                "lng": 119.974
            },
            "Puyang": {
                "lat": 35.7627,
                "lng": 115.0292
            },
            "Jiaozuo": {
                "lat": 35.2157,
                "lng": 113.2419
            },
            "Nanchang": {
                "lat": 28.683,
                "lng": 115.858
            },
            "Deyang": {
                "lat": 31.127,
                "lng": 104.398
            },
            "Hohhot": {
                "lat": 40.842,
                "lng": 111.749
            },
            "Hechi": {
                "lat": 24.693,
                "lng": 108.085
            },
            "Tangshan": {
                "lat": 39.6294,
                "lng": 118.1739
            },
            "Shiyan": {
                "lat": 23.1251,
                "lng": 113.8633
            },
            "Anshan": {
                "lat": 39.7144,
                "lng": 118.9964
            },
            "Baojishi": {
                "lat": 34.363,
                "lng": 107.238
            },
            "Qinzhou": {
                "lat": 21.981,
                "lng": 108.654
            },
            "Guiyang": {
                "lat": 26.647,
                "lng": 106.63
            },
            "Bengbu": {
                "lat": 32.917,
                "lng": 117.389
            },
            "Bazhou": {
                "lat": 39.1333,
                "lng": 116.3833
            },
            "Suining": {
                "lat": 30.533,
                "lng": 105.593
            },
            "Wuxi": {
                "lat": 26.58,
                "lng": 111.841
            },
            "Hanzhong": {
                "lat": 33.0664,
                "lng": 107.0232
            },
            "Putian": {
                "lat": 25.4526,
                "lng": 119.0078
            },
            "Zhenjiang": {
                "lat": 32.188,
                "lng": 119.424
            },
            "Guang\u2019an": {
                "lat": 30.4564,
                "lng": 106.6326
            },
            "Changzhi": {
                "lat": 36.195,
                "lng": 113.117
            },
            "Tongren": {
                "lat": 27.7316,
                "lng": 109.1895
            },
            "Leshan": {
                "lat": 29.552,
                "lng": 103.766
            },
            "Qinhuangdao": {
                "lat": 39.8882,
                "lng": 119.5202
            },
            "Xinzhou": {
                "lat": 38.416,
                "lng": 112.734
            },
            "Lanzhou": {
                "lat": 36.0606,
                "lng": 103.8268
            },
            "Wuzhou": {
                "lat": 23.4767,
                "lng": 111.279
            },
            "Huainan": {
                "lat": 32.6314,
                "lng": 117.0194
            },
            "Qincheng": {
                "lat": 34.5809,
                "lng": 105.7311
            },
            "Rizhao": {
                "lat": 35.417,
                "lng": 119.527
            },
            "Meishan": {
                "lat": 34.1736,
                "lng": 112.839
            },
            "Ningde": {
                "lat": 26.6662,
                "lng": 119.5477
            },
            "Zhongshan": {
                "lat": 34.943,
                "lng": 105.8771
            },
            "Weihai": {
                "lat": 37.5133,
                "lng": 122.1205
            },
            "Haikou": {
                "lat": 20.0186,
                "lng": 110.3488
            },
            "Tongliao": {
                "lat": 43.654,
                "lng": 122.243
            },
            "Chaoyang": {
                "lat": 41.571,
                "lng": 120.453
            },
            "Shaoguan": {
                "lat": 24.811,
                "lng": 113.597
            },
            "Heyuan": {
                "lat": 23.7443,
                "lng": 114.7002
            },
            "Daqing": {
                "lat": 46.589,
                "lng": 125.104
            },
            "Xiangtan": {
                "lat": 27.8313,
                "lng": 112.9454
            },
            "Longyan": {
                "lat": 25.076,
                "lng": 117.017
            },
            "Baotou": {
                "lat": 40.6213,
                "lng": 109.9532
            },
            "Handan": {
                "lat": 36.601,
                "lng": 114.487
            },
            "Jinzhou": {
                "lat": 41.129,
                "lng": 121.148
            },
            "Nanping": {
                "lat": 26.6418,
                "lng": 118.1774
            },
            "Shanwei": {
                "lat": 22.7872,
                "lng": 115.3756
            },
            "Chaozhou": {
                "lat": 23.658,
                "lng": 116.622
            },
            "Weifang": {
                "lat": 36.708,
                "lng": 119.162
            },
            "Huai\u2019an": {
                "lat": 33.551,
                "lng": 119.113
            },
            "Zibo": {
                "lat": 36.8138,
                "lng": 118.055
            },
            "Ankang": {
                "lat": 32.6854,
                "lng": 109.029
            },
            "Gulou": {
                "lat": 26.0865,
                "lng": 119.298
            },
            "Sanming": {
                "lat": 26.2634,
                "lng": 117.6389
            },
            "Yangjiang": {
                "lat": 21.857,
                "lng": 111.983
            },
            "Jiamusi": {
                "lat": 46.8,
                "lng": 130.319
            },
            "Luohe": {
                "lat": 33.583,
                "lng": 114.0109
            },
            "Dingxi": {
                "lat": 35.608,
                "lng": 104.592
            },
            "Shaoxing": {
                "lat": 30.0511,
                "lng": 120.5833
            },
            "Yantai": {
                "lat": 37.4646,
                "lng": 121.4478
            },
            "Huizhou": {
                "lat": 23.112,
                "lng": 114.416
            },
            "Lishui": {
                "lat": 28.468,
                "lng": 119.923
            },
            "Xuanzhou": {
                "lat": 30.939,
                "lng": 118.759
            },
            "Zigong": {
                "lat": 29.339,
                "lng": 104.779
            },
            "Guangyuan": {
                "lat": 32.436,
                "lng": 105.844
            },
            "Huangshi": {
                "lat": 30.2011,
                "lng": 115.039
            },
            "Xining": {
                "lat": 36.6224,
                "lng": 101.7804
            },
            "Zhuhai": {
                "lat": 22.2716,
                "lng": 113.5769
            },
            "Huludao": {
                "lat": 40.711,
                "lng": 120.837
            },
            "Baoshan": {
                "lat": 25.112,
                "lng": 99.161
            },
            "Lianshan": {
                "lat": 40.7523,
                "lng": 120.828
            },
            "Longba": {
                "lat": 33.3702,
                "lng": 104.9603
            },
            "Jilin": {
                "lat": 43.8519,
                "lng": 126.5481
            },
            "Tieling": {
                "lat": 42.2237,
                "lng": 123.726
            },
            "Yunfu": {
                "lat": 28.6331,
                "lng": 104.4181
            },
            "Luoyang": {
                "lat": 34.6197,
                "lng": 112.4539
            },
            "Anshun": {
                "lat": 26.2531,
                "lng": 105.9476
            },
            "Yingkou": {
                "lat": 40.625,
                "lng": 122.219
            },
            "Yanjiang": {
                "lat": 30.129,
                "lng": 104.627
            },
            "Mudanjiang": {
                "lat": 44.5514,
                "lng": 129.6329
            },
            "Yan\u2019an": {
                "lat": 36.65,
                "lng": 109.494
            },
            "Jincheng": {
                "lat": 39.5529,
                "lng": 113.1933
            },
            "Nantong": {
                "lat": 31.981,
                "lng": 120.894
            },
            "Lincang": {
                "lat": 23.884,
                "lng": 100.089
            },
            "Yuxi": {
                "lat": 24.347,
                "lng": 102.527
            },
            "Laibin": {
                "lat": 23.7501,
                "lng": 109.2212
            },
            "Shengli": {
                "lat": 37.9842,
                "lng": 106.1967
            },
            "Dandong": {
                "lat": 40.1167,
                "lng": 124.3833
            },
            "Qinbaling": {
                "lat": 35.7278,
                "lng": 107.64
            },
            "Gaoping": {
                "lat": 35.798,
                "lng": 112.924
            },
            "Ma\u2019anshan": {
                "lat": 31.669,
                "lng": 118.507
            },
            "Puning": {
                "lat": 23.298,
                "lng": 116.166
            },
            "Huaibei": {
                "lat": 33.956,
                "lng": 116.798
            },
            "Chongzuo": {
                "lat": 22.377,
                "lng": 107.365
            },
            "Yushan": {
                "lat": 31.322,
                "lng": 120.985
            },
            "Hezhou": {
                "lat": 24.4042,
                "lng": 111.5672
            },
            "Pingliang": {
                "lat": 35.5424,
                "lng": 106.6649
            },
            "Qingyang": {
                "lat": 36.1985,
                "lng": 113.4313
            },
            "Shangzhou": {
                "lat": 33.868,
                "lng": 109.9244
            },
            "Sanmenxia": {
                "lat": 34.7732,
                "lng": 111.2004
            },
            "Pingxiang": {
                "lat": 27.659,
                "lng": 113.887
            },
            "Yinchuan": {
                "lat": 38.485,
                "lng": 106.225
            },
            "Xiping": {
                "lat": 25.6005,
                "lng": 103.8166
            },
            "Jixi": {
                "lat": 45.295,
                "lng": 130.969
            },
            "Fushun": {
                "lat": 41.881,
                "lng": 123.957
            },
            "Beihai": {
                "lat": 21.481,
                "lng": 109.12
            },
            "Fuxin": {
                "lat": 42.022,
                "lng": 121.67
            },
            "Wuwei": {
                "lat": 37.929,
                "lng": 102.638
            },
            "Siping": {
                "lat": 43.1668,
                "lng": 124.3506
            },
            "Shuyangzha": {
                "lat": 34.1299,
                "lng": 118.7734
            },
            "Shuozhou": {
                "lat": 39.3317,
                "lng": 112.4329
            },
            "Sanzhou": {
                "lat": 30.82,
                "lng": 108.4
            },
            "Changshu": {
                "lat": 31.656,
                "lng": 120.753
            },
            "Heihe": {
                "lat": 50.2401,
                "lng": 127.521
            },
            "Ximeicun": {
                "lat": 24.9612,
                "lng": 118.3849
            },
            "Jingdezhen": {
                "lat": 29.2917,
                "lng": 117.1986
            },
            "Liaoyang": {
                "lat": 41.279,
                "lng": 123.176
            },
            "Chengtangcun": {
                "lat": 35.0833,
                "lng": 117.15
            },
            "Jiangyin": {
                "lat": 31.839,
                "lng": 120.295
            },
            "Hebi": {
                "lat": 35.748,
                "lng": 114.297
            },
            "Huzhou": {
                "lat": 30.8925,
                "lng": 120.0875
            },
            "Jiaxing": {
                "lat": 30.747,
                "lng": 120.756
            },
            "Zhangjiajie": {
                "lat": 29.117,
                "lng": 110.479
            },
            "Baiyin": {
                "lat": 36.5451,
                "lng": 104.1389
            },
            "Guiping": {
                "lat": 23.4,
                "lng": 110.0833
            },
            "Lianjiang": {
                "lat": 21.7333,
                "lng": 110.2833
            },
            "Jianguang": {
                "lat": 28.1958,
                "lng": 115.7833
            },
            "Yucheng": {
                "lat": 36.934,
                "lng": 116.638
            },
            "Xushan": {
                "lat": 30.1667,
                "lng": 121.2333
            },
            "Leizhou": {
                "lat": 20.9143,
                "lng": 110.0967
            },
            "Huazhou": {
                "lat": 21.664,
                "lng": 110.6396
            },
            "Jinhua": {
                "lat": 29.079,
                "lng": 119.647
            },
            "Pizhou": {
                "lat": 34.398,
                "lng": 117.89
            },
            "Yangshe": {
                "lat": 31.8767,
                "lng": 120.556
            },
            "Rui\u2019an": {
                "lat": 27.7833,
                "lng": 120.625
            },
            "Wenling": {
                "lat": 28.3667,
                "lng": 121.3667
            },
            "Gaozhou": {
                "lat": 21.9244,
                "lng": 110.8422
            },
            "Fuqing": {
                "lat": 25.7167,
                "lng": 119.3833
            },
            "Wuzhong": {
                "lat": 37.9978,
                "lng": 106.1986
            },
            "Pingdu": {
                "lat": 36.7769,
                "lng": 119.9884
            },
            "Yangquan": {
                "lat": 37.0749,
                "lng": 111.5541
            },
            "Yutan": {
                "lat": 28.278,
                "lng": 112.552
            },
            "Chizhou": {
                "lat": 30.6654,
                "lng": 117.4916
            },
            "Liangshi": {
                "lat": 27.26,
                "lng": 111.7442
            },
            "Huangshan": {
                "lat": 29.7149,
                "lng": 118.3376
            },
            "Benxi": {
                "lat": 41.4868,
                "lng": 123.685
            },
            "Xintai": {
                "lat": 35.909,
                "lng": 117.768
            },
            "Wusong": {
                "lat": 30.9333,
                "lng": 117.7667
            },
            "Yushu": {
                "lat": 44.826,
                "lng": 126.55
            },
            "Kuaidamao": {
                "lat": 41.7283,
                "lng": 125.9397
            },
            "Baishan": {
                "lat": 40.1656,
                "lng": 116.3144
            },
            "Haicheng": {
                "lat": 40.8824,
                "lng": 122.6852
            },
            "Yicheng": {
                "lat": 31.721,
                "lng": 112.257
            },
            "Rucheng": {
                "lat": 32.246,
                "lng": 120.591
            },
            "Huaiyin": {
                "lat": 33.5819,
                "lng": 119.028
            },
            "Dayan": {
                "lat": 26.8552,
                "lng": 100.2259
            },
            "Laiwu": {
                "lat": 36.1833,
                "lng": 117.6667
            },
            "Jingling": {
                "lat": 30.664,
                "lng": 113.167
            },
            "Yongcheng": {
                "lat": 33.9299,
                "lng": 116.45
            },
            "Yiwu": {
                "lat": 29.3069,
                "lng": 120.0753
            },
            "Beidao": {
                "lat": 34.602,
                "lng": 105.918
            },
            "Xiangshui": {
                "lat": 26.5964,
                "lng": 104.8314
            },
            "Dadukou": {
                "lat": 26.5824,
                "lng": 101.7184
            },
            "Lingcheng": {
                "lat": 22.7,
                "lng": 110.35
            },
            "Shuangyashan": {
                "lat": 46.6762,
                "lng": 131.1416
            },
            "Xinyu": {
                "lat": 27.8186,
                "lng": 114.9167
            },
            "Zhangye": {
                "lat": 38.9248,
                "lng": 100.4499
            },
            "Rongcheng": {
                "lat": 39.05,
                "lng": 115.8657
            },
            "Liaoyuan": {
                "lat": 42.888,
                "lng": 125.1447
            },
            "Guankou": {
                "lat": 30.988,
                "lng": 103.647
            },
            "Lianyuan": {
                "lat": 27.692,
                "lng": 111.664
            },
            "Kaiyuan": {
                "lat": 23.7111,
                "lng": 103.2461
            },
            "Zhuji": {
                "lat": 29.7167,
                "lng": 120.2333
            },
            "Yingtan": {
                "lat": 28.2721,
                "lng": 117.0395
            },
            "Leiyang": {
                "lat": 26.4223,
                "lng": 112.8598
            },
            "Guyuan": {
                "lat": 36.01,
                "lng": 106.257
            },
            "Xiantao": {
                "lat": 30.328,
                "lng": 113.443
            },
            "Yingchuan": {
                "lat": 34.1511,
                "lng": 113.4733
            },
            "Dongtai": {
                "lat": 32.795,
                "lng": 120.519
            },
            "Dingzhou": {
                "lat": 38.516,
                "lng": 114.99
            },
            "Xibeijie": {
                "lat": 39.7334,
                "lng": 98.4943
            },
            "Yuyao": {
                "lat": 30.05,
                "lng": 121.15
            },
            "Hanchuan": {
                "lat": 30.652,
                "lng": 113.8274
            },
            "Gongzhuling": {
                "lat": 43.5053,
                "lng": 124.8224
            },
            "Zhufeng": {
                "lat": 36.0,
                "lng": 119.4167
            },
            "Ezhou": {
                "lat": 30.3914,
                "lng": 114.8949
            },
            "Xiashi": {
                "lat": 30.5333,
                "lng": 120.6833
            },
            "Zhongwei": {
                "lat": 37.5002,
                "lng": 105.1968
            },
            "Mizhou": {
                "lat": 35.99,
                "lng": 119.3801
            },
            "Xishan": {
                "lat": 23.059,
                "lng": 115.5564
            },
            "Hegang": {
                "lat": 47.3501,
                "lng": 130.298
            },
            "Fangchenggang": {
                "lat": 21.6867,
                "lng": 108.3547
            },
            "Jiancheng": {
                "lat": 30.3931,
                "lng": 104.5465
            },
            "Shouguang": {
                "lat": 36.857,
                "lng": 118.791
            },
            "Sanya": {
                "lat": 18.2533,
                "lng": 109.5036
            },
            "Wutong": {
                "lat": 30.6333,
                "lng": 120.5333
            },
            "Linhai": {
                "lat": 28.85,
                "lng": 121.1167
            },
            "Wafangdian": {
                "lat": 39.6271,
                "lng": 121.9796
            },
            "Zhongxiang": {
                "lat": 31.169,
                "lng": 112.5853
            },
            "Xinyi": {
                "lat": 22.3549,
                "lng": 110.9468
            },
            "Zaoyang": {
                "lat": 32.129,
                "lng": 112.772
            },
            "Xingyi": {
                "lat": 25.092,
                "lng": 104.8955
            },
            "Shuizhai": {
                "lat": 33.4433,
                "lng": 114.8994
            },
            "Xingcheng": {
                "lat": 40.1399,
                "lng": 118.303
            },
            "Taixing": {
                "lat": 32.159,
                "lng": 120.029
            },
            "Xinhualu": {
                "lat": 34.3964,
                "lng": 113.7402
            },
            "Yingcheng": {
                "lat": 24.21,
                "lng": 113.401
            },
            "Luocheng": {
                "lat": 30.99,
                "lng": 104.25
            },
            "Huilong": {
                "lat": 31.87,
                "lng": 121.703
            },
            "Wuchuan": {
                "lat": 21.441,
                "lng": 110.779
            },
            "Nada": {
                "lat": 19.5209,
                "lng": 109.5808
            },
            "Taishan": {
                "lat": 22.2486,
                "lng": 112.785
            },
            "Anqiu": {
                "lat": 36.478,
                "lng": 119.219
            },
            "Feicheng": {
                "lat": 36.1861,
                "lng": 116.7719
            },
            "Taihe": {
                "lat": 30.8706,
                "lng": 105.3784
            },
            "Xin\u2019an": {
                "lat": 34.286,
                "lng": 118.355
            },
            "Taihecun": {
                "lat": 45.771,
                "lng": 131.003
            },
            "Kashgar": {
                "lat": 39.4681,
                "lng": 75.9938
            },
            "Qingzhou": {
                "lat": 36.6853,
                "lng": 118.4796
            },
            "Daye": {
                "lat": 30.0961,
                "lng": 114.9804
            },
            "Hengzhou": {
                "lat": 22.6799,
                "lng": 109.2614
            },
            "Zhuanghe": {
                "lat": 39.681,
                "lng": 122.967
            },
            "Quzhou": {
                "lat": 28.9702,
                "lng": 118.8593
            },
            "Jiaozhou": {
                "lat": 36.2647,
                "lng": 120.0334
            },
            "Wuchang": {
                "lat": 44.924,
                "lng": 127.158
            },
            "Yangchun": {
                "lat": 22.1704,
                "lng": 111.7916
            },
            "Dengtalu": {
                "lat": 36.1386,
                "lng": 114.1066
            },
            "Zhoushan": {
                "lat": 29.9856,
                "lng": 122.2074
            },
            "Hai\u2019an": {
                "lat": 32.535,
                "lng": 120.563
            },
            "Laiyang": {
                "lat": 36.9758,
                "lng": 120.7136
            },
            "Kaifeng Chengguanzhen": {
                "lat": 34.8519,
                "lng": 114.3481
            },
            "Gaomi": {
                "lat": 36.3833,
                "lng": 119.75
            },
            "Lhasa": {
                "lat": 29.6534,
                "lng": 91.1719
            },
            "Leping": {
                "lat": 37.613,
                "lng": 113.6995
            },
            "Shache": {
                "lat": 38.4261,
                "lng": 77.25
            },
            "Hailun": {
                "lat": 47.4667,
                "lng": 126.9667
            },
            "Macheng": {
                "lat": 31.173,
                "lng": 115.008
            },
            "Zijinglu": {
                "lat": 34.7667,
                "lng": 112.9667
            },
            "Yuci": {
                "lat": 37.6823,
                "lng": 112.7281
            },
            "Dehui": {
                "lat": 44.535,
                "lng": 125.703
            },
            "Tongchuan": {
                "lat": 34.897,
                "lng": 108.945
            },
            "Chengxiang": {
                "lat": 31.564,
                "lng": 121.174
            },
            "Rongjiawan": {
                "lat": 29.1409,
                "lng": 113.1087
            },
            "Weichanglu": {
                "lat": 37.1792,
                "lng": 119.9333
            },
            "Renqiu": {
                "lat": 38.686,
                "lng": 116.084
            },
            "Xindi": {
                "lat": 29.827,
                "lng": 113.476
            },
            "Wu\u2019an": {
                "lat": 36.697,
                "lng": 114.204
            },
            "Qingping": {
                "lat": 34.539,
                "lng": 113.391
            },
            "Gaoyou": {
                "lat": 32.7847,
                "lng": 119.4432
            },
            "Hejian": {
                "lat": 38.4466,
                "lng": 116.0995
            },
            "Puxi": {
                "lat": 35.2125,
                "lng": 114.735
            },
            "Danyang": {
                "lat": 32.0,
                "lng": 119.586
            },
            "Dongyang": {
                "lat": 29.2667,
                "lng": 120.2167
            },
            "Xigaz\u00ea": {
                "lat": 29.25,
                "lng": 88.8833
            },
            "Qamdo": {
                "lat": 31.143,
                "lng": 97.17
            },
            "Xiangxiang": {
                "lat": 27.7186,
                "lng": 112.5502
            },
            "Chaohucun": {
                "lat": 31.6244,
                "lng": 117.8902
            },
            "Zouping": {
                "lat": 36.863,
                "lng": 117.743
            },
            "Dali": {
                "lat": 34.7953,
                "lng": 109.9378
            },
            "Korla": {
                "lat": 41.7259,
                "lng": 86.1746
            },
            "Songzi": {
                "lat": 30.174,
                "lng": 111.757
            },
            "Laixi": {
                "lat": 36.8667,
                "lng": 120.5333
            },
            "Zhongba": {
                "lat": 31.771,
                "lng": 104.755
            },
            "Qingnian": {
                "lat": 36.8494,
                "lng": 115.7061
            },
            "Guangshui": {
                "lat": 31.617,
                "lng": 113.826
            },
            "Sizhan": {
                "lat": 38.9846,
                "lng": 106.3828
            },
            "Lichuan": {
                "lat": 30.291,
                "lng": 108.9364
            },
            "Licheng": {
                "lat": 24.5,
                "lng": 110.4
            },
            "Chengguan": {
                "lat": 35.4623,
                "lng": 113.8054
            },
            "Nehe": {
                "lat": 48.4833,
                "lng": 124.8833
            },
            "Zunhua": {
                "lat": 40.189,
                "lng": 117.966
            },
            "Wugang": {
                "lat": 26.7266,
                "lng": 110.6319
            },
            "Shuangqiao": {
                "lat": 35.067,
                "lng": 112.602
            },
            "Langzhong": {
                "lat": 31.558,
                "lng": 106.005
            },
            "Qian\u2019an": {
                "lat": 39.999,
                "lng": 118.701
            },
            "Cencheng": {
                "lat": 22.9167,
                "lng": 111.0
            },
            "Guli": {
                "lat": 28.9,
                "lng": 120.0333
            },
            "Anlu": {
                "lat": 31.2667,
                "lng": 113.6667
            },
            "Songyang": {
                "lat": 34.4553,
                "lng": 113.0253
            },
            "Shihezi": {
                "lat": 44.3054,
                "lng": 86.0804
            },
            "Yatou": {
                "lat": 37.1653,
                "lng": 122.4867
            },
            "Xichang": {
                "lat": 27.8944,
                "lng": 102.2644
            },
            "Dashiqiao": {
                "lat": 40.6167,
                "lng": 122.5167
            },
            "Qianxi": {
                "lat": 27.0087,
                "lng": 106.0323
            },
            "Shishi": {
                "lat": 24.7167,
                "lng": 118.7
            },
            "Miluo Chengguanzhen": {
                "lat": 28.761,
                "lng": 113.16
            },
            "Gaizhou": {
                "lat": 40.4,
                "lng": 122.35
            },
            "Leling": {
                "lat": 37.73,
                "lng": 117.232
            },
            "Jianshe": {
                "lat": 34.2137,
                "lng": 113.7629
            },
            "Jingcheng": {
                "lat": 32.009,
                "lng": 120.263
            },
            "Xinmin": {
                "lat": 25.4831,
                "lng": 104.8416
            },
            "Shanhu": {
                "lat": 29.6,
                "lng": 120.8167
            },
            "Zhongshu": {
                "lat": 27.7919,
                "lng": 106.4013
            },
            "Kumul": {
                "lat": 42.8193,
                "lng": 93.5151
            },
            "Pinghu": {
                "lat": 30.7005,
                "lng": 121.0104
            },
            "Qionghu": {
                "lat": 28.945,
                "lng": 112.598
            },
            "Zhaodong": {
                "lat": 46.051,
                "lng": 125.962
            },
            "Puyang Chengguanzhen": {
                "lat": 35.7004,
                "lng": 114.98
            },
            "Wenchang": {
                "lat": 31.054,
                "lng": 116.9507
            },
            "Shulan": {
                "lat": 44.4059,
                "lng": 126.9655
            },
            "Lingbao Chengguanzhen": {
                "lat": 34.5176,
                "lng": 110.8942
            },
            "Sanhe": {
                "lat": 36.5639,
                "lng": 105.6439
            },
            "Jieshou": {
                "lat": 33.257,
                "lng": 115.3746
            },
            "Suohe": {
                "lat": 34.7833,
                "lng": 113.35
            },
            "Guixi": {
                "lat": 28.2925,
                "lng": 117.2454
            },
            "Wuxue": {
                "lat": 29.844,
                "lng": 115.561
            },
            "Jinghong": {
                "lat": 22.009,
                "lng": 100.797
            },
            "Tengyue": {
                "lat": 25.0167,
                "lng": 98.4833
            },
            "Ruiming": {
                "lat": 25.886,
                "lng": 116.027
            },
            "Qufu": {
                "lat": 35.5819,
                "lng": 116.9862
            },
            "Xinshi": {
                "lat": 31.1333,
                "lng": 113.1167
            },
            "Jin\u2019e": {
                "lat": 29.3394,
                "lng": 105.2875
            },
            "Chuxiong": {
                "lat": 25.033,
                "lng": 101.5459
            },
            "Xinxing": {
                "lat": 47.1601,
                "lng": 123.8
            },
            "Kaihua": {
                "lat": 23.387,
                "lng": 104.233
            },
            "Meilan": {
                "lat": 19.999,
                "lng": 110.403
            },
            "Turpan": {
                "lat": 42.9512,
                "lng": 89.1895
            },
            "Meihekou": {
                "lat": 42.5407,
                "lng": 125.7107
            },
            "Jurong": {
                "lat": 31.946,
                "lng": 119.164
            },
            "Yuhuan": {
                "lat": 28.1277,
                "lng": 121.2288
            },
            "Xinji": {
                "lat": 38.4682,
                "lng": 116.8918
            },
            "Changping": {
                "lat": 40.2248,
                "lng": 116.1944
            },
            "Zhangshu": {
                "lat": 28.0546,
                "lng": 115.5462
            },
            "Zhuozhou": {
                "lat": 39.485,
                "lng": 115.974
            },
            "Tianchang": {
                "lat": 37.9986,
                "lng": 114.0183
            },
            "Sihui": {
                "lat": 23.3265,
                "lng": 112.7338
            },
            "Wenlan": {
                "lat": 23.3961,
                "lng": 103.3649
            },
            "Shangzhi": {
                "lat": 45.2116,
                "lng": 127.962
            },
            "Botou": {
                "lat": 38.0833,
                "lng": 116.5833
            },
            "Xiulin": {
                "lat": 29.7209,
                "lng": 112.4254
            },
            "Fu\u2019an": {
                "lat": 27.1,
                "lng": 119.6333
            },
            "Luofeng": {
                "lat": 37.359,
                "lng": 120.396
            },
            "Lingyuan": {
                "lat": 41.25,
                "lng": 119.4
            },
            "Shenzhou": {
                "lat": 38.002,
                "lng": 115.56
            },
            "Kuiju": {
                "lat": 36.8667,
                "lng": 119.4
            },
            "Zhenzhou": {
                "lat": 32.2723,
                "lng": 119.1848
            },
            "Wencheng": {
                "lat": 19.617,
                "lng": 110.7551
            },
            "Lanxi": {
                "lat": 46.2664,
                "lng": 126.276
            },
            "Dangyang": {
                "lat": 30.821,
                "lng": 111.789
            },
            "Luanzhou": {
                "lat": 39.741,
                "lng": 118.704
            },
            "Wuhai": {
                "lat": 39.655,
                "lng": 106.794
            },
            "Yanji": {
                "lat": 42.9,
                "lng": 129.5
            },
            "Huanghua": {
                "lat": 38.372,
                "lng": 117.33
            },
            "Wancheng": {
                "lat": 18.8,
                "lng": 110.4
            },
            "Fengcheng": {
                "lat": 37.4313,
                "lng": 112.027
            },
            "Ghulja": {
                "lat": 43.9,
                "lng": 81.35
            },
            "Fuding": {
                "lat": 27.2,
                "lng": 120.2
            },
            "Chang\u2019an": {
                "lat": 29.4768,
                "lng": 113.4504
            },
            "Aksu": {
                "lat": 41.185,
                "lng": 80.2904
            },
            "Mingguang": {
                "lat": 32.7833,
                "lng": 117.9667
            },
            "Gaobeidian": {
                "lat": 39.327,
                "lng": 115.874
            },
            "Ailan Mubage": {
                "lat": 43.908,
                "lng": 81.2777
            },
            "Anda": {
                "lat": 46.424,
                "lng": 125.352
            },
            "Longzhou": {
                "lat": 30.32,
                "lng": 112.23
            },
            "Heshan": {
                "lat": 23.8,
                "lng": 108.8833
            },
            "Jiaji": {
                "lat": 19.2431,
                "lng": 110.4642
            },
            "Beining": {
                "lat": 41.6,
                "lng": 121.8
            },
            "Encheng": {
                "lat": 22.1833,
                "lng": 112.3
            },
            "Nagqu": {
                "lat": 31.476,
                "lng": 92.051
            },
            "Meicheng": {
                "lat": 30.631,
                "lng": 116.581
            },
            "Nenjiang": {
                "lat": 49.11,
                "lng": 125.13
            },
            "Hongjiang": {
                "lat": 27.191,
                "lng": 109.819
            },
            "Beipiao": {
                "lat": 41.801,
                "lng": 120.771
            },
            "Dengtacun": {
                "lat": 41.4333,
                "lng": 123.3333
            },
            "Zhijiang": {
                "lat": 27.4367,
                "lng": 109.678
            },
            "Suoluntun": {
                "lat": 45.2279,
                "lng": 124.8432
            },
            "Chengjiao": {
                "lat": 35.3984,
                "lng": 114.0649
            },
            "Duyun": {
                "lat": 26.2594,
                "lng": 107.5186
            },
            "Yuanping": {
                "lat": 38.731,
                "lng": 112.711
            },
            "Yueqing": {
                "lat": 28.12,
                "lng": 120.96
            },
            "Karamay": {
                "lat": 45.5799,
                "lng": 84.8892
            },
            "Shahe": {
                "lat": 36.8549,
                "lng": 114.5033
            },
            "Dunhua": {
                "lat": 43.3667,
                "lng": 128.2333
            },
            "Lianran": {
                "lat": 24.919,
                "lng": 102.478
            },
            "Jiannan": {
                "lat": 31.3333,
                "lng": 104.2
            },
            "Nangong": {
                "lat": 37.359,
                "lng": 115.409
            },
            "Jiaojiangcun": {
                "lat": 28.6804,
                "lng": 121.45
            },
            "Laohekou": {
                "lat": 32.359,
                "lng": 111.684
            },
            "Beian": {
                "lat": 48.25,
                "lng": 126.5167
            },
            "Fujin": {
                "lat": 47.25,
                "lng": 132.0333
            },
            "Xiaoyi": {
                "lat": 36.0833,
                "lng": 111.5167
            },
            "Qingzhen": {
                "lat": 26.5704,
                "lng": 106.4687
            },
            "Jiangshan": {
                "lat": 28.7361,
                "lng": 118.625
            },
            "Longjiang": {
                "lat": 47.3404,
                "lng": 123.18
            },
            "Kuqa": {
                "lat": 41.7156,
                "lng": 82.9322
            },
            "Jian\u2019ou": {
                "lat": 27.0333,
                "lng": 118.3167
            },
            "Huadian": {
                "lat": 42.972,
                "lng": 126.746
            },
            "Minzhu": {
                "lat": 43.7238,
                "lng": 127.3448
            },
            "Tanbei": {
                "lat": 35.088,
                "lng": 112.951
            },
            "Pingquan": {
                "lat": 41.0042,
                "lng": 118.6848
            },
            "Baisha": {
                "lat": 29.4896,
                "lng": 119.337
            },
            "Yongji": {
                "lat": 34.867,
                "lng": 110.448
            },
            "Danjiangkou": {
                "lat": 32.5401,
                "lng": 111.5131
            },
            "Ning\u2019an": {
                "lat": 44.341,
                "lng": 129.483
            },
            "Jinchang": {
                "lat": 38.4858,
                "lng": 112.9643
            },
            "Guangming": {
                "lat": 45.3357,
                "lng": 122.7765
            },
            "Zhuangyuan": {
                "lat": 37.3056,
                "lng": 120.829
            },
            "Huili Chengguanzhen": {
                "lat": 26.6333,
                "lng": 102.25
            },
            "Xunyang": {
                "lat": 32.8341,
                "lng": 109.3653
            },
            "Yan\u2019an Beilu": {
                "lat": 44.0144,
                "lng": 87.2674
            },
            "Hailin": {
                "lat": 44.594,
                "lng": 129.38
            },
            "Mishan": {
                "lat": 45.5298,
                "lng": 131.8466
            },
            "Zhengjiatun": {
                "lat": 43.5183,
                "lng": 123.5027
            },
            "Lecheng": {
                "lat": 25.1333,
                "lng": 113.3333
            },
            "Xicheng": {
                "lat": 23.3667,
                "lng": 103.15
            },
            "Pencheng": {
                "lat": 29.676,
                "lng": 115.681
            },
            "Da\u2019an": {
                "lat": 45.5,
                "lng": 124.2833
            },
            "Xingren": {
                "lat": 25.435,
                "lng": 105.1862
            },
            "Fenyang": {
                "lat": 37.2616,
                "lng": 111.7699
            },
            "Fangting": {
                "lat": 31.127,
                "lng": 104.167
            },
            "Linghai": {
                "lat": 41.1667,
                "lng": 121.35
            },
            "Zhangjiakou Shi Xuanhua Qu": {
                "lat": 40.5944,
                "lng": 115.0243
            },
            "Wanyuan": {
                "lat": 31.9833,
                "lng": 107.9667
            },
            "Jiexiu": {
                "lat": 37.027,
                "lng": 111.917
            },
            "Jiangjiafan": {
                "lat": 31.0238,
                "lng": 111.9709
            },
            "Hejin": {
                "lat": 35.596,
                "lng": 110.712
            },
            "Zhugang": {
                "lat": 28.1358,
                "lng": 121.2264
            },
            "Hancheng": {
                "lat": 35.4667,
                "lng": 110.4333
            },
            "Tieli": {
                "lat": 46.95,
                "lng": 128.05
            },
            "Lianzhou": {
                "lat": 24.781,
                "lng": 112.377
            },
            "Yidu": {
                "lat": 30.378,
                "lng": 111.45
            },
            "Lingxi": {
                "lat": 27.5036,
                "lng": 120.3975
            },
            "Z\u00eatang": {
                "lat": 29.238,
                "lng": 91.771
            },
            "Helixi": {
                "lat": 30.6267,
                "lng": 118.9861
            },
            "Lengshuijiang": {
                "lat": 27.686,
                "lng": 111.436
            },
            "Panshi": {
                "lat": 42.95,
                "lng": 126.0667
            },
            "Huichang": {
                "lat": 34.9073,
                "lng": 112.7914
            },
            "Jinshan": {
                "lat": 25.1462,
                "lng": 102.0723
            },
            "Anguo": {
                "lat": 38.418,
                "lng": 115.327
            },
            "Nanqiao": {
                "lat": 30.9167,
                "lng": 121.4547
            },
            "Wudalianchi": {
                "lat": 48.75,
                "lng": 126.1667
            },
            "Shuanghe": {
                "lat": 33.032,
                "lng": 109.6099
            },
            "Yong\u2019an": {
                "lat": 31.0206,
                "lng": 109.4587
            },
            "Linxia Chengguanzhen": {
                "lat": 35.6047,
                "lng": 103.2422
            },
            "Hongzhai": {
                "lat": 34.9857,
                "lng": 104.5446
            },
            "Yakeshi": {
                "lat": 49.286,
                "lng": 120.712
            },
            "Yingmen": {
                "lat": 39.9487,
                "lng": 97.4395
            },
            "Tangdong": {
                "lat": 25.973,
                "lng": 113.231
            },
            "Yingzhong": {
                "lat": 32.237,
                "lng": 119.815
            },
            "Hangu": {
                "lat": 39.232,
                "lng": 117.777
            },
            "Bamiantong": {
                "lat": 44.9167,
                "lng": 130.5167
            },
            "Ulanhot": {
                "lat": 46.0722,
                "lng": 122.0933
            },
            "Yunzhong": {
                "lat": 39.8279,
                "lng": 113.1
            },
            "Binxian": {
                "lat": 35.0542,
                "lng": 108.0814
            },
            "Hotan": {
                "lat": 37.1167,
                "lng": 79.9167
            },
            "Zalantun": {
                "lat": 48.027,
                "lng": 122.708
            },
            "Xiaoli": {
                "lat": 22.6721,
                "lng": 113.2509
            },
            "Jiayuguan": {
                "lat": 39.7732,
                "lng": 98.2882
            },
            "Datang": {
                "lat": 26.3909,
                "lng": 108.0764
            },
            "Longquan": {
                "lat": 40.3703,
                "lng": 113.7483
            },
            "Yonghetun": {
                "lat": 39.9131,
                "lng": 116.6458
            },
            "Qianzhou": {
                "lat": 28.276,
                "lng": 109.712
            },
            "Yakou": {
                "lat": 33.295,
                "lng": 113.5269
            },
            "Dexing": {
                "lat": 28.9306,
                "lng": 117.5947
            },
            "Hulin": {
                "lat": 45.7667,
                "lng": 132.9333
            },
            "Hong\u2019an": {
                "lat": 47.21,
                "lng": 123.61
            },
            "Fuquan": {
                "lat": 26.6863,
                "lng": 107.5204
            },
            "Huozhou": {
                "lat": 36.569,
                "lng": 111.7551
            },
            "Tongchuanshi": {
                "lat": 35.08,
                "lng": 109.03
            },
            "Xingsha": {
                "lat": 28.2573,
                "lng": 113.1234
            },
            "Shaowu": {
                "lat": 27.3403,
                "lng": 117.4925
            },
            "Bijiao": {
                "lat": 22.9253,
                "lng": 113.2067
            },
            "Huayin": {
                "lat": 34.5833,
                "lng": 110.1
            },
            "Jinshi": {
                "lat": 29.5,
                "lng": 111.908
            },
            "Manzhouli": {
                "lat": 49.598,
                "lng": 117.379
            },
            "Beichengqu": {
                "lat": 40.437,
                "lng": 113.11
            },
            "Zhangping": {
                "lat": 37.6339,
                "lng": 112.8775
            },
            "Atushi": {
                "lat": 39.7162,
                "lng": 76.1684
            },
            "Diaobingshancun": {
                "lat": 42.4667,
                "lng": 123.5667
            },
            "Wuyishan": {
                "lat": 27.7564,
                "lng": 118.0353
            },
            "Shenmu": {
                "lat": 38.827,
                "lng": 110.499
            },
            "Chishui": {
                "lat": 28.5904,
                "lng": 105.6975
            },
            "Bole": {
                "lat": 44.8539,
                "lng": 82.0514
            },
            "Lin\u2019an": {
                "lat": 23.6279,
                "lng": 102.8014
            },
            "Dianbu": {
                "lat": 31.8883,
                "lng": 117.4608
            },
            "Hunchun": {
                "lat": 42.863,
                "lng": 130.366
            },
            "Nong\u2019an": {
                "lat": 44.4347,
                "lng": 125.175
            },
            "Ji\u2019an Shi": {
                "lat": 41.1253,
                "lng": 126.194
            },
            "Wayaobu": {
                "lat": 37.1427,
                "lng": 109.6752
            },
            "Gujiao": {
                "lat": 37.907,
                "lng": 112.176
            },
            "Dongxing": {
                "lat": 21.5478,
                "lng": 107.9717
            },
            "Golmud": {
                "lat": 36.4072,
                "lng": 94.9283
            },
            "Xigujing": {
                "lat": 31.1731,
                "lng": 121.2723
            },
            "Dongning": {
                "lat": 44.1167,
                "lng": 130.8167
            },
            "Taisheng": {
                "lat": 23.2939,
                "lng": 113.3531
            },
            "Jiutai": {
                "lat": 44.1447,
                "lng": 125.8443
            },
            "Puqi": {
                "lat": 29.7204,
                "lng": 113.88
            },
            "Luzhang": {
                "lat": 25.8226,
                "lng": 98.8577
            },
            "Huangyan": {
                "lat": 28.65,
                "lng": 121.25
            },
            "Helong": {
                "lat": 42.5436,
                "lng": 129.0028
            },
            "Dunhuang": {
                "lat": 40.1411,
                "lng": 94.6639
            },
            "Donghua": {
                "lat": 35.2167,
                "lng": 106.6
            },
            "Longjing": {
                "lat": 42.7667,
                "lng": 129.4333
            },
            "Tongjiang": {
                "lat": 47.65,
                "lng": 132.5
            },
            "Longjin": {
                "lat": 22.8853,
                "lng": 113.0758
            },
            "Kangqiao": {
                "lat": 31.35,
                "lng": 105.0667
            },
            "Linjiang": {
                "lat": 41.8167,
                "lng": 126.9167
            },
            "Fenglu": {
                "lat": 24.6506,
                "lng": 102.935
            },
            "Basuo": {
                "lat": 19.092,
                "lng": 108.671
            },
            "Tiantoujiao": {
                "lat": 23.0241,
                "lng": 114.1046
            },
            "Kuytun": {
                "lat": 44.4264,
                "lng": 84.9032
            },
            "Fukang": {
                "lat": 44.1646,
                "lng": 87.9536
            },
            "Simao": {
                "lat": 22.7807,
                "lng": 100.9782
            },
            "Zhanlicun": {
                "lat": 23.2881,
                "lng": 116.2594
            },
            "Jinggang": {
                "lat": 23.2722,
                "lng": 113.2183
            },
            "Tacheng": {
                "lat": 46.7517,
                "lng": 82.9869
            },
            "Liancheng": {
                "lat": 24.0515,
                "lng": 105.0573
            },
            "Zhengding": {
                "lat": 38.1464,
                "lng": 114.5708
            },
            "Aral": {
                "lat": 40.548,
                "lng": 81.281
            },
            "Xiaping": {
                "lat": 33.4031,
                "lng": 106.0581
            },
            "Nan Zhuang": {
                "lat": 22.9839,
                "lng": 113.0139
            },
            "Chaoshan": {
                "lat": 23.0768,
                "lng": 113.8696
            },
            "Danzao": {
                "lat": 23.0427,
                "lng": 112.9145
            },
            "Jinghai": {
                "lat": 38.9294,
                "lng": 116.9169
            },
            "Dahuaishu": {
                "lat": 36.2601,
                "lng": 111.6743
            },
            "Tafeng": {
                "lat": 25.3775,
                "lng": 112.2034
            },
            "Fengyicun": {
                "lat": 23.6636,
                "lng": 116.6028
            },
            "Xianshuigu": {
                "lat": 38.9786,
                "lng": 117.3886
            },
            "Dongguazhen": {
                "lat": 25.076,
                "lng": 101.509
            },
            "Dongsheng": {
                "lat": 36.9996,
                "lng": 105.0029
            },
            "Xinjing": {
                "lat": 31.2142,
                "lng": 121.3681
            },
            "Yima": {
                "lat": 34.7469,
                "lng": 111.875
            },
            "Xintang": {
                "lat": 22.7833,
                "lng": 113.15
            },
            "Zhaxi": {
                "lat": 27.844,
                "lng": 105.0451
            },
            "Zhaoxiang": {
                "lat": 31.149,
                "lng": 121.1962
            },
            "Jinjiang": {
                "lat": 19.7386,
                "lng": 110.0058
            },
            "Altay": {
                "lat": 47.8666,
                "lng": 88.1166
            },
            "Shizhaobi": {
                "lat": 23.921,
                "lng": 115.7774
            },
            "Xiangcheng": {
                "lat": 25.4783,
                "lng": 100.5551
            },
            "Shizuishan": {
                "lat": 39.2333,
                "lng": 106.769
            },
            "Mabacun": {
                "lat": 24.6807,
                "lng": 113.5936
            },
            "Yunxian Chengguanzhen": {
                "lat": 32.8082,
                "lng": 110.8136
            },
            "Zhangmu Touwei": {
                "lat": 22.9149,
                "lng": 114.0833
            },
            "Nanqiaotou": {
                "lat": 22.7176,
                "lng": 113.2918
            },
            "Shuangcheng": {
                "lat": 45.3503,
                "lng": 126.28
            },
            "Nantou": {
                "lat": 22.7217,
                "lng": 113.2926
            },
            "Baiyashi": {
                "lat": 26.4019,
                "lng": 111.2864
            },
            "Lucheng": {
                "lat": 29.9985,
                "lng": 101.9569
            },
            "Cili": {
                "lat": 29.4225,
                "lng": 111.1219
            },
            "Zhaozhou": {
                "lat": 37.7527,
                "lng": 114.7775
            },
            "Fuyuan": {
                "lat": 48.3647,
                "lng": 134.3079
            },
            "Jizhou": {
                "lat": 37.5455,
                "lng": 115.5663
            },
            "Fugu": {
                "lat": 39.0259,
                "lng": 111.0683
            },
            "Longtian": {
                "lat": 24.3512,
                "lng": 114.1293
            },
            "Gexianzhuang": {
                "lat": 37.0694,
                "lng": 115.6591
            },
            "Lianhe": {
                "lat": 47.1314,
                "lng": 129.2906
            },
            "Wuling": {
                "lat": 39.4421,
                "lng": 114.23
            },
            "Tumen": {
                "lat": 42.9667,
                "lng": 129.85
            },
            "Genhe": {
                "lat": 50.7833,
                "lng": 121.5167
            },
            "Jiangna": {
                "lat": 23.6128,
                "lng": 104.3433
            },
            "Shuixi": {
                "lat": 22.509,
                "lng": 113.3213
            },
            "Sanxi": {
                "lat": 22.5111,
                "lng": 113.3161
            },
            "Yongqing": {
                "lat": 34.7522,
                "lng": 106.1312
            },
            "Yangliuqing": {
                "lat": 39.13,
                "lng": 117.0142
            },
            "Xuqiaocun": {
                "lat": 30.4355,
                "lng": 120.3645
            },
            "Quyang": {
                "lat": 26.5755,
                "lng": 109.7008
            },
            "Yulu": {
                "lat": 23.5193,
                "lng": 116.4055
            },
            "Aihua": {
                "lat": 24.4514,
                "lng": 100.1273
            },
            "Qiaotou": {
                "lat": 36.935,
                "lng": 101.6736
            },
            "Shacheng": {
                "lat": 40.4039,
                "lng": 115.5189
            },
            "Hezuo": {
                "lat": 35.0002,
                "lng": 102.9103
            },
            "Kaiyun": {
                "lat": 27.2399,
                "lng": 112.8639
            },
            "Changbang": {
                "lat": 30.4555,
                "lng": 120.4433
            },
            "Taoyang": {
                "lat": 35.3754,
                "lng": 103.8612
            },
            "Tangjia": {
                "lat": 30.7833,
                "lng": 120.3
            },
            "Shihuajie": {
                "lat": 32.2742,
                "lng": 111.4717
            },
            "Xishancun": {
                "lat": 23.2589,
                "lng": 116.2388
            },
            "Nantang": {
                "lat": 22.4986,
                "lng": 113.531
            },
            "Yilong": {
                "lat": 23.7081,
                "lng": 102.496
            },
            "Mazhang": {
                "lat": 21.2757,
                "lng": 110.3221
            },
            "Chongshan": {
                "lat": 18.7751,
                "lng": 109.5169
            },
            "Houzhuang": {
                "lat": 35.639,
                "lng": 111.2324
            },
            "Mositai": {
                "lat": 45.5333,
                "lng": 119.6528
            },
            "Yishi": {
                "lat": 35.1379,
                "lng": 110.764
            },
            "Fugangcun": {
                "lat": 23.5873,
                "lng": 116.5957
            },
            "Hengnan": {
                "lat": 22.5348,
                "lng": 113.2491
            },
            "Jingping": {
                "lat": 39.5189,
                "lng": 112.2837
            },
            "Xinhua": {
                "lat": 37.8291,
                "lng": 102.5986
            },
            "Weifen": {
                "lat": 38.4633,
                "lng": 111.1203
            },
            "Banzhuangcun": {
                "lat": 34.8504,
                "lng": 118.851
            },
            "Mabai": {
                "lat": 23.0151,
                "lng": 104.396
            },
            "Xiongzhou": {
                "lat": 38.9786,
                "lng": 116.073
            },
            "Fengning": {
                "lat": 41.2013,
                "lng": 116.6433
            },
            "Menglang": {
                "lat": 22.5586,
                "lng": 99.9336
            },
            "Xiegang": {
                "lat": 22.9616,
                "lng": 114.1487
            },
            "Nanxicun": {
                "lat": 23.4975,
                "lng": 116.2436
            },
            "Fanyang": {
                "lat": 31.0847,
                "lng": 118.1942
            },
            "Wujiaqu": {
                "lat": 44.167,
                "lng": 87.543
            },
            "Doudian": {
                "lat": 39.6503,
                "lng": 116.0617
            },
            "Longkeng": {
                "lat": 24.0376,
                "lng": 112.0427
            },
            "Weiyuan": {
                "lat": 36.8413,
                "lng": 101.9557
            },
            "Boli": {
                "lat": 45.7564,
                "lng": 130.5759
            },
            "Jiantang": {
                "lat": 27.8333,
                "lng": 99.7
            },
            "Dongping": {
                "lat": 28.3817,
                "lng": 111.2194
            },
            "Meizichong": {
                "lat": 28.1419,
                "lng": 111.6444
            },
            "Bayan Hot": {
                "lat": 38.8556,
                "lng": 105.7001
            },
            "Pingyuanjie": {
                "lat": 23.7491,
                "lng": 103.7841
            },
            "Yishui": {
                "lat": 35.7904,
                "lng": 118.62
            },
            "Yi Xian": {
                "lat": 39.3444,
                "lng": 115.4954
            },
            "Lingtang": {
                "lat": 23.6032,
                "lng": 113.074
            },
            "Wangjia": {
                "lat": 30.6218,
                "lng": 120.7212
            },
            "Jinbi": {
                "lat": 25.7356,
                "lng": 101.3239
            },
            "Fenggang": {
                "lat": 23.6283,
                "lng": 116.5718
            },
            "Rongwo": {
                "lat": 35.5161,
                "lng": 102.0183
            },
            "Linquan": {
                "lat": 37.9513,
                "lng": 110.9877
            },
            "Xiancun": {
                "lat": 23.2374,
                "lng": 116.3152
            },
            "Mengdingjie": {
                "lat": 23.5568,
                "lng": 99.0817
            },
            "Julu": {
                "lat": 37.22,
                "lng": 115.0309
            },
            "Wangqing": {
                "lat": 43.3126,
                "lng": 129.7572
            },
            "Leshou": {
                "lat": 38.1902,
                "lng": 116.1205
            },
            "Qiantangcun": {
                "lat": 23.6742,
                "lng": 116.915
            },
            "Changting": {
                "lat": 25.867,
                "lng": 116.3167
            },
            "Shuangshuicun": {
                "lat": 22.4356,
                "lng": 112.9964
            },
            "Bama": {
                "lat": 24.1474,
                "lng": 107.2593
            },
            "Wulan": {
                "lat": 36.5585,
                "lng": 104.6765
            },
            "Heyunkeng": {
                "lat": 23.9293,
                "lng": 112.9185
            },
            "Tangxing": {
                "lat": 35.7261,
                "lng": 111.7108
            },
            "Taohuajiang": {
                "lat": 28.5239,
                "lng": 112.1342
            },
            "Tuncheng": {
                "lat": 19.3633,
                "lng": 110.0978
            },
            "Yaofeng": {
                "lat": 35.1395,
                "lng": 111.2174
            },
            "Dingcheng": {
                "lat": 19.6803,
                "lng": 110.3681
            },
            "Shaoshanzhan": {
                "lat": 27.915,
                "lng": 112.5267
            },
            "Longhua": {
                "lat": 41.3246,
                "lng": 117.7344
            },
            "Taishan Houcun": {
                "lat": 41.317,
                "lng": 117.7264
            },
            "Ducheng": {
                "lat": 23.2445,
                "lng": 111.5342
            },
            "Fusui": {
                "lat": 22.6325,
                "lng": 107.9047
            },
            "Jinhe": {
                "lat": 22.7686,
                "lng": 103.2272
            },
            "Korgas": {
                "lat": 44.2125,
                "lng": 80.4097
            },
            "Longchuan": {
                "lat": 25.1945,
                "lng": 101.2759
            },
            "Luancheng": {
                "lat": 37.9003,
                "lng": 114.6483
            },
            "Wutiancun": {
                "lat": 23.1852,
                "lng": 116.4757
            },
            "Mingxing": {
                "lat": 37.4264,
                "lng": 112.5442
            },
            "Nantingcun": {
                "lat": 20.804,
                "lng": 110.0826
            },
            "Wuyi": {
                "lat": 37.7965,
                "lng": 115.892
            },
            "Kongjiazhuangcun": {
                "lat": 40.7536,
                "lng": 114.7237
            },
            "Shuibian": {
                "lat": 24.1263,
                "lng": 112.7668
            },
            "Xiedian": {
                "lat": 35.419,
                "lng": 110.8281
            },
            "Hengkou": {
                "lat": 32.7378,
                "lng": 108.7695
            },
            "Gaoliying Ercun": {
                "lat": 40.1578,
                "lng": 116.4933
            },
            "Shiji": {
                "lat": 22.2198,
                "lng": 112.8531
            },
            "Wujindian": {
                "lat": 31.9839,
                "lng": 112.7703
            },
            "Yanggao": {
                "lat": 21.3298,
                "lng": 109.9177
            },
            "Koktokay": {
                "lat": 47.0004,
                "lng": 89.4666
            },
            "Dongchuan": {
                "lat": 25.5086,
                "lng": 101.2356
            },
            "Hengbei": {
                "lat": 23.8787,
                "lng": 115.7309
            },
            "Pingyi": {
                "lat": 35.5104,
                "lng": 117.62
            },
            "Huolu": {
                "lat": 38.0874,
                "lng": 114.3159
            },
            "Chengbin": {
                "lat": 19.9969,
                "lng": 110.3375
            },
            "Linkou": {
                "lat": 45.2819,
                "lng": 130.2519
            },
            "Zhongcheng": {
                "lat": 28.5973,
                "lng": 103.9773
            },
            "Mengla": {
                "lat": 21.4947,
                "lng": 101.5693
            },
            "E\u2019erguna": {
                "lat": 50.243,
                "lng": 120.181
            },
            "Daxincun": {
                "lat": 38.4428,
                "lng": 106.3111
            },
            "Funing": {
                "lat": 39.8879,
                "lng": 119.2314
            },
            "Fancheng": {
                "lat": 39.1891,
                "lng": 113.2729
            },
            "Tailai": {
                "lat": 46.3909,
                "lng": 123.4161
            },
            "Rulin": {
                "lat": 26.3585,
                "lng": 110.3117
            },
            "Kargilik": {
                "lat": 37.885,
                "lng": 77.4131
            },
            "Laiyuan": {
                "lat": 39.3515,
                "lng": 114.6853
            },
            "Xinglong": {
                "lat": 40.4146,
                "lng": 117.493
            },
            "Puqiancun": {
                "lat": 20.0289,
                "lng": 110.5769
            },
            "Panggezhuang": {
                "lat": 39.6336,
                "lng": 116.3178
            },
            "Dayong": {
                "lat": 22.4653,
                "lng": 113.3004
            },
            "Shancheng": {
                "lat": 34.7904,
                "lng": 116.08
            },
            "Xindian": {
                "lat": 25.3093,
                "lng": 101.5455
            },
            "Chaigoubu": {
                "lat": 40.6687,
                "lng": 114.4157
            },
            "Jingzhou": {
                "lat": 37.6911,
                "lng": 116.2672
            },
            "Zhuolu": {
                "lat": 40.3753,
                "lng": 115.215
            },
            "Longkoucun": {
                "lat": 23.5742,
                "lng": 116.7303
            },
            "Keshan": {
                "lat": 48.0263,
                "lng": 125.8659
            },
            "Bailongqiaocun": {
                "lat": 29.0767,
                "lng": 119.5517
            },
            "Wenping": {
                "lat": 27.193,
                "lng": 103.5461
            },
            "Nanfengcun": {
                "lat": 23.746,
                "lng": 111.7966
            },
            "Jinsha": {
                "lat": 23.5286,
                "lng": 116.615
            },
            "Yinying": {
                "lat": 37.941,
                "lng": 113.5602
            },
            "Baiquan": {
                "lat": 47.6018,
                "lng": 126.0819
            },
            "Guzhou": {
                "lat": 25.9452,
                "lng": 108.5238
            },
            "Pan\u2019an": {
                "lat": 34.7575,
                "lng": 105.1135
            },
            "Longtangwan": {
                "lat": 27.7772,
                "lng": 111.7514
            },
            "Zhanggu": {
                "lat": 30.9504,
                "lng": 101.9167
            },
            "Qingquan": {
                "lat": 38.7823,
                "lng": 101.0826
            },
            "Yueshanwan": {
                "lat": 27.8614,
                "lng": 112.2911
            },
            "Shangchuankou": {
                "lat": 36.3283,
                "lng": 102.8015
            },
            "Huishi": {
                "lat": 35.6918,
                "lng": 105.0531
            },
            "Yitiaoshan": {
                "lat": 37.1889,
                "lng": 104.0571
            },
            "Liuhe": {
                "lat": 42.2669,
                "lng": 125.7404
            },
            "Yunnanyi": {
                "lat": 25.3916,
                "lng": 100.6846
            },
            "Lindong": {
                "lat": 43.9681,
                "lng": 119.3756
            },
            "Yanghe": {
                "lat": 38.2727,
                "lng": 106.2496
            },
            "Chengjiao Chengguanzhen": {
                "lat": 34.4362,
                "lng": 104.0306
            },
            "Longshan": {
                "lat": 26.3347,
                "lng": 106.8929
            },
            "Shashijie": {
                "lat": 28.3458,
                "lng": 113.424
            },
            "Yuquan": {
                "lat": 40.4203,
                "lng": 114.0864
            },
            "Huajing": {
                "lat": 31.1194,
                "lng": 121.4519
            },
            "Wanggezhuang": {
                "lat": 39.5683,
                "lng": 116.5941
            },
            "Lushar": {
                "lat": 36.4971,
                "lng": 101.564
            },
            "Karakax": {
                "lat": 37.2714,
                "lng": 79.7267
            },
            "Guangping": {
                "lat": 36.4791,
                "lng": 114.941
            },
            "Huquan": {
                "lat": 39.7603,
                "lng": 114.2834
            },
            "Zanhuang": {
                "lat": 37.6656,
                "lng": 114.3861
            },
            "Qarqan": {
                "lat": 38.1338,
                "lng": 85.5333
            },
            "Xiantangcun": {
                "lat": 23.794,
                "lng": 114.7447
            },
            "Xieqiaocun": {
                "lat": 30.4973,
                "lng": 120.6106
            },
            "Xibang": {
                "lat": 30.9412,
                "lng": 120.8872
            },
            "Sangzishi": {
                "lat": 27.6675,
                "lng": 111.3706
            },
            "Lechang": {
                "lat": 35.6415,
                "lng": 111.4633
            },
            "Qinggang": {
                "lat": 46.69,
                "lng": 126.1
            },
            "Kangbao": {
                "lat": 41.8511,
                "lng": 114.6176
            },
            "Yangqingcun": {
                "lat": 21.3594,
                "lng": 110.1164
            },
            "Lingshou": {
                "lat": 38.3064,
                "lng": 114.3783
            },
            "Gusang": {
                "lat": 28.2864,
                "lng": 113.7424
            },
            "Wenxicun": {
                "lat": 28.1565,
                "lng": 120.3824
            },
            "Shangtangcun": {
                "lat": 21.5989,
                "lng": 111.5907
            },
            "Sihushan": {
                "lat": 28.9716,
                "lng": 112.6413
            },
            "Yongbei": {
                "lat": 26.6897,
                "lng": 100.7463
            },
            "Zengqiao": {
                "lat": 27.5153,
                "lng": 112.2336
            },
            "Xiluodu": {
                "lat": 28.236,
                "lng": 103.6301
            },
            "Meihuacun": {
                "lat": 37.8862,
                "lng": 114.8204
            },
            "Shihe": {
                "lat": 39.2708,
                "lng": 113.5478
            },
            "Yongyang": {
                "lat": 27.1017,
                "lng": 106.7332
            },
            "Yisuhe": {
                "lat": 27.7736,
                "lng": 112.9558
            },
            "Jinxing": {
                "lat": 37.9869,
                "lng": 106.2027
            },
            "Suifenhe": {
                "lat": 44.4124,
                "lng": 131.1518
            },
            "Dongcun": {
                "lat": 38.28,
                "lng": 111.6751
            },
            "Huajiang": {
                "lat": 25.7491,
                "lng": 105.6063
            },
            "Lianzhuangcun": {
                "lat": 37.1146,
                "lng": 115.7594
            },
            "Wangzhuang": {
                "lat": 39.8003,
                "lng": 116.15
            },
            "Zhoujiajing": {
                "lat": 31.1116,
                "lng": 121.0518
            },
            "Gannan": {
                "lat": 47.9117,
                "lng": 123.4978
            },
            "Xikeng": {
                "lat": 24.0505,
                "lng": 116.8538
            },
            "Wuyang": {
                "lat": 27.057,
                "lng": 108.3959
            },
            "Ma\u2019erkang": {
                "lat": 31.9057,
                "lng": 102.2065
            },
            "Tianningcun": {
                "lat": 30.8938,
                "lng": 120.8009
            },
            "Liannong": {
                "lat": 31.0107,
                "lng": 121.0393
            },
            "Liantangcun": {
                "lat": 22.9517,
                "lng": 112.4597
            },
            "Zengcun": {
                "lat": 38.2461,
                "lng": 114.7394
            },
            "Sucun": {
                "lat": 31.0554,
                "lng": 118.1057
            },
            "Nanjian": {
                "lat": 25.0531,
                "lng": 100.5231
            },
            "Suileng": {
                "lat": 47.246,
                "lng": 127.106
            },
            "Xiezhou": {
                "lat": 34.9124,
                "lng": 110.8517
            },
            "Gengzhuangqiaocun": {
                "lat": 37.4453,
                "lng": 114.9804
            },
            "Yanguancun": {
                "lat": 30.4541,
                "lng": 120.5545
            },
            "Huaniu": {
                "lat": 34.5658,
                "lng": 105.8215
            },
            "Qianwu": {
                "lat": 22.1635,
                "lng": 113.2217
            },
            "Tieshansi": {
                "lat": 30.2064,
                "lng": 114.9003
            },
            "Mali": {
                "lat": 23.1338,
                "lng": 104.708
            },
            "Ping\u2019an": {
                "lat": 36.502,
                "lng": 102.1082
            },
            "Huaixiangcun": {
                "lat": 22.4817,
                "lng": 111.0542
            },
            "Chini": {
                "lat": 23.3916,
                "lng": 113.0755
            },
            "Bianyang": {
                "lat": 25.6194,
                "lng": 106.535
            },
            "Longxing": {
                "lat": 35.6091,
                "lng": 111.2304
            },
            "Cuizhuangzi": {
                "lat": 38.2897,
                "lng": 116.5472
            },
            "Langtang": {
                "lat": 27.9636,
                "lng": 111.11
            },
            "Longmen": {
                "lat": 35.6119,
                "lng": 110.5746
            },
            "Changling": {
                "lat": 44.27,
                "lng": 123.99
            },
            "Nawucun": {
                "lat": 22.0039,
                "lng": 110.5874
            },
            "Siliancun": {
                "lat": 22.7155,
                "lng": 111.3163
            },
            "Nansan": {
                "lat": 23.7784,
                "lng": 98.8253
            },
            "Xinqing": {
                "lat": 48.2363,
                "lng": 129.5059
            },
            "Wangguanzhuang Sicun": {
                "lat": 37.0183,
                "lng": 115.5773
            },
            "Wuhuang": {
                "lat": 29.9649,
                "lng": 104.7883
            },
            "Songjiangcun": {
                "lat": 42.1756,
                "lng": 127.4842
            },
            "Chaiwu": {
                "lat": 39.6556,
                "lng": 116.6292
            },
            "Caojia": {
                "lat": 27.8197,
                "lng": 111.3364
            },
            "Shidong": {
                "lat": 23.6873,
                "lng": 111.9296
            },
            "Shidongcun": {
                "lat": 23.6193,
                "lng": 112.0701
            },
            "Jihong": {
                "lat": 32.6972,
                "lng": 111.7928
            },
            "Hongshandian": {
                "lat": 27.5736,
                "lng": 112.0158
            },
            "Yangmei": {
                "lat": 22.8728,
                "lng": 112.7802
            },
            "Qingan": {
                "lat": 46.8719,
                "lng": 127.5118
            },
            "Xincheng": {
                "lat": 36.0333,
                "lng": 113.4667
            },
            "Yaozhuangcun": {
                "lat": 30.9113,
                "lng": 120.9573
            },
            "Zhangjiazhuang": {
                "lat": 39.7804,
                "lng": 118.2004
            },
            "Zhangjiazhuangcun": {
                "lat": 38.1753,
                "lng": 114.7395
            },
            "Qabqa": {
                "lat": 36.2667,
                "lng": 100.6167
            },
            "Bamei": {
                "lat": 24.2634,
                "lng": 105.0809
            },
            "Lengquancun": {
                "lat": 39.9431,
                "lng": 116.0886
            },
            "Mengmeng": {
                "lat": 23.4718,
                "lng": 99.8336
            },
            "Wangjiazhai": {
                "lat": 26.6895,
                "lng": 104.8043
            },
            "Huazangsi": {
                "lat": 36.9836,
                "lng": 103.1265
            },
            "Shimencun": {
                "lat": 30.6265,
                "lng": 120.4417
            },
            "Huaiyang": {
                "lat": 37.7701,
                "lng": 114.5312
            },
            "Youfangcun": {
                "lat": 37.0042,
                "lng": 115.7755
            },
            "Qinhe": {
                "lat": 36.5014,
                "lng": 112.3247
            },
            "Gutao": {
                "lat": 37.2,
                "lng": 112.15
            },
            "Xiayang": {
                "lat": 26.4906,
                "lng": 113.7708
            },
            "Dongxishan": {
                "lat": 27.7056,
                "lng": 111.8561
            },
            "Xiazhai": {
                "lat": 27.6909,
                "lng": 107.1837
            },
            "Jieshangya": {
                "lat": 29.505,
                "lng": 110.7653
            },
            "Shankou": {
                "lat": 24.5032,
                "lng": 116.4046
            },
            "Tuodian": {
                "lat": 24.6907,
                "lng": 101.6382
            },
            "Fangcun": {
                "lat": 37.9799,
                "lng": 114.549
            },
            "Guozhen": {
                "lat": 34.3668,
                "lng": 107.198
            },
            "Dongzhang": {
                "lat": 37.935,
                "lng": 115.0839
            },
            "Liujiaxia": {
                "lat": 35.9423,
                "lng": 103.3146
            },
            "Jiashizhuangcun": {
                "lat": 37.8683,
                "lng": 114.9478
            },
            "Dongshan": {
                "lat": 22.0597,
                "lng": 112.8319
            },
            "Qulicun": {
                "lat": 22.565,
                "lng": 107.8022
            },
            "Liutuancun": {
                "lat": 36.945,
                "lng": 119.395
            },
            "Santangpai": {
                "lat": 27.4008,
                "lng": 111.9942
            },
            "Yongping": {
                "lat": 37.0103,
                "lng": 109.8243
            },
            "Yecun": {
                "lat": 33.7663,
                "lng": 110.1305
            },
            "Gaocheng": {
                "lat": 30.0167,
                "lng": 100.2667
            },
            "Liuhu": {
                "lat": 35.5449,
                "lng": 106.6801
            },
            "Zhongtanying": {
                "lat": 40.2125,
                "lng": 116.5844
            },
            "Dahutang": {
                "lat": 28.0694,
                "lng": 112.5442
            },
            "Liulin": {
                "lat": 34.5891,
                "lng": 103.5081
            },
            "Jitaicun": {
                "lat": 36.3533,
                "lng": 115.3048
            },
            "Nanzhuangzhen": {
                "lat": 23.721,
                "lng": 102.8231
            },
            "Yashan": {
                "lat": 22.4776,
                "lng": 112.7289
            },
            "Daxiang": {
                "lat": 22.3775,
                "lng": 112.8008
            },
            "Dongnanyanfa": {
                "lat": 39.63,
                "lng": 116.4083
            },
            "Wangtan": {
                "lat": 39.2847,
                "lng": 118.98
            },
            "Shimenzhai": {
                "lat": 40.0892,
                "lng": 119.6019
            },
            "Cuihua": {
                "lat": 27.7527,
                "lng": 103.8906
            },
            "Zhongzhai": {
                "lat": 25.7783,
                "lng": 107.8665
            },
            "Huilongcun": {
                "lat": 32.7028,
                "lng": 115.4742
            },
            "Hexiwu": {
                "lat": 39.6225,
                "lng": 116.9539
            },
            "Dongsu": {
                "lat": 38.0153,
                "lng": 115.1461
            },
            "Changchong": {
                "lat": 26.3404,
                "lng": 107.1866
            },
            "Shangpa": {
                "lat": 26.9052,
                "lng": 98.8679
            },
            "Tangdukou": {
                "lat": 26.9949,
                "lng": 111.2708
            },
            "Qianjiang Shequ": {
                "lat": 23.6157,
                "lng": 108.9603
            },
            "Caiguantun": {
                "lat": 26.3363,
                "lng": 105.9841
            },
            "Baiji": {
                "lat": 26.0231,
                "lng": 106.9267
            },
            "Bingmei": {
                "lat": 25.7408,
                "lng": 108.9023
            },
            "Hekou": {
                "lat": 22.5125,
                "lng": 103.9586
            },
            "Shangshan": {
                "lat": 23.4792,
                "lng": 115.6918
            },
            "Shazhou": {
                "lat": 40.1376,
                "lng": 94.6638
            },
            "Liuquancun": {
                "lat": 39.3658,
                "lng": 116.3138
            },
            "Yaopu": {
                "lat": 26.17,
                "lng": 105.8489
            },
            "Tangjiacun": {
                "lat": 20.8425,
                "lng": 109.8469
            },
            "Yaoquan": {
                "lat": 34.5851,
                "lng": 105.7262
            },
            "Houbu": {
                "lat": 36.4167,
                "lng": 112.9833
            },
            "Beigangwa": {
                "lat": 39.84,
                "lng": 116.2033
            },
            "Changtoushang": {
                "lat": 19.3441,
                "lng": 110.6096
            },
            "Daijiazhuang": {
                "lat": 38.1344,
                "lng": 114.3906
            },
            "Yuchengcun": {
                "lat": 23.5633,
                "lng": 116.2691
            },
            "Xinjun": {
                "lat": 28.7233,
                "lng": 120.0189
            },
            "Jinku": {
                "lat": 23.0372,
                "lng": 112.5151
            },
            "Zhexiang": {
                "lat": 24.2591,
                "lng": 98.2826
            },
            "Yutiancun": {
                "lat": 25.8747,
                "lng": 119.4578
            },
            "Xihuachi": {
                "lat": 35.8164,
                "lng": 108.0125
            },
            "Shanshan": {
                "lat": 27.8203,
                "lng": 112.0164
            },
            "Zhangliangcun": {
                "lat": 37.1256,
                "lng": 112.0664
            },
            "Khutubi": {
                "lat": 44.1874,
                "lng": 86.8946
            },
            "Nandongcun": {
                "lat": 38.1031,
                "lng": 114.7582
            },
            "Nandazhang": {
                "lat": 38.1024,
                "lng": 114.7658
            },
            "Zhangcun": {
                "lat": 38.1333,
                "lng": 114.9333
            },
            "Longtoushan Jiezi": {
                "lat": 27.1157,
                "lng": 103.3817
            },
            "Mengdong": {
                "lat": 23.1475,
                "lng": 99.2492
            },
            "Shuilou": {
                "lat": 22.336,
                "lng": 112.7929
            },
            "Loufan": {
                "lat": 38.0694,
                "lng": 111.7911
            },
            "Bairuo": {
                "lat": 28.4782,
                "lng": 112.8048
            },
            "Xidiancun": {
                "lat": 29.4787,
                "lng": 121.4368
            },
            "Shenjiatun": {
                "lat": 40.7695,
                "lng": 114.8549
            },
            "Machang": {
                "lat": 33.1912,
                "lng": 107.3732
            },
            "Liantang": {
                "lat": 22.9352,
                "lng": 111.7237
            },
            "Wangyuanqiao": {
                "lat": 38.3849,
                "lng": 106.2664
            },
            "Songhuajiangcun": {
                "lat": 44.7158,
                "lng": 125.9544
            },
            "Lingquan": {
                "lat": 36.9985,
                "lng": 110.8417
            },
            "Zhujiacun": {
                "lat": 26.3164,
                "lng": 104.3893
            },
            "Yangcunzai": {
                "lat": 23.4338,
                "lng": 114.4664
            },
            "Yinggen": {
                "lat": 19.0372,
                "lng": 109.8283
            },
            "Dacun": {
                "lat": 34.7201,
                "lng": 109.0549
            },
            "Litian Gezhuang": {
                "lat": 39.8151,
                "lng": 119.0219
            },
            "Tanxia": {
                "lat": 23.9475,
                "lng": 115.5361
            },
            "Dongyangshi": {
                "lat": 28.2133,
                "lng": 113.3997
            },
            "Shengaocun": {
                "lat": 32.5844,
                "lng": 120.1336
            },
            "Dongluocun": {
                "lat": 22.3994,
                "lng": 107.685
            },
            "Hongfengcun": {
                "lat": 21.9231,
                "lng": 111.9329
            },
            "Lijiaxiang": {
                "lat": 39.5556,
                "lng": 116.4292
            },
            "Yingzhou Linchang": {
                "lat": 18.4199,
                "lng": 109.8553
            },
            "Anyuan": {
                "lat": 34.8839,
                "lng": 105.2758
            },
            "Baichigan": {
                "lat": 39.5275,
                "lng": 115.8564
            },
            "Shanhe": {
                "lat": 35.495,
                "lng": 108.3519
            },
            "Yangtangxu": {
                "lat": 21.5375,
                "lng": 110.0044
            },
            "Yatangcun": {
                "lat": 21.6172,
                "lng": 110.0319
            },
            "Taicheng": {
                "lat": 38.7206,
                "lng": 113.2437
            },
            "Oroqen Zizhiqi": {
                "lat": 50.5667,
                "lng": 123.7167
            },
            "Longtang": {
                "lat": 26.1984,
                "lng": 107.7946
            },
            "Menghan": {
                "lat": 21.8526,
                "lng": 100.9265
            },
            "Alashankou": {
                "lat": 45.1733,
                "lng": 82.5546
            },
            "Lishaocun": {
                "lat": 22.7236,
                "lng": 111.4607
            },
            "Yian": {
                "lat": 47.8804,
                "lng": 125.3
            },
            "Zhongbai": {
                "lat": 26.7723,
                "lng": 107.8883
            },
            "Xisa": {
                "lat": 23.4401,
                "lng": 104.68
            },
            "Huanghuajie": {
                "lat": 27.9952,
                "lng": 103.5507
            },
            "Jiaozishan": {
                "lat": 26.3342,
                "lng": 105.9324
            },
            "Sanjiang": {
                "lat": 24.7265,
                "lng": 112.2884
            },
            "Tangpingcun": {
                "lat": 22.0292,
                "lng": 111.9346
            },
            "Huanren": {
                "lat": 41.2672,
                "lng": 125.3611
            },
            "Nongzhangjie": {
                "lat": 24.616,
                "lng": 97.8818
            },
            "Yangfang": {
                "lat": 40.5723,
                "lng": 115.0301
            },
            "Huangyoutang": {
                "lat": 23.6173,
                "lng": 115.4856
            },
            "Changchunpu": {
                "lat": 27.2388,
                "lng": 105.185
            },
            "Xiwanzi": {
                "lat": 40.9717,
                "lng": 115.2737
            },
            "Sungandiancun": {
                "lat": 36.2221,
                "lng": 115.3246
            },
            "Lanyi": {
                "lat": 38.7048,
                "lng": 111.5601
            },
            "Shijiazhuangnan": {
                "lat": 37.9383,
                "lng": 114.4453
            },
            "Qiaotouyi": {
                "lat": 28.4114,
                "lng": 112.927
            },
            "Xiaguanying": {
                "lat": 35.9427,
                "lng": 104.1717
            },
            "Bayan": {
                "lat": 36.1064,
                "lng": 102.2547
            },
            "Chuangjian": {
                "lat": 29.1558,
                "lng": 112.2456
            },
            "Siyang": {
                "lat": 27.2116,
                "lng": 108.7463
            },
            "Menglie": {
                "lat": 22.5833,
                "lng": 101.8661
            },
            "Zhongdong Shequ": {
                "lat": 22.8317,
                "lng": 107.8331
            },
            "Dengtangcun": {
                "lat": 23.6821,
                "lng": 116.5259
            },
            "Yamen": {
                "lat": 22.2999,
                "lng": 113.0585
            },
            "Sanchahe": {
                "lat": 36.378,
                "lng": 106.0869
            },
            "Yanqi": {
                "lat": 42.0586,
                "lng": 86.5683
            },
            "Baocheng": {
                "lat": 18.6431,
                "lng": 109.6994
            },
            "Fengguangcun": {
                "lat": 23.9062,
                "lng": 116.6984
            },
            "Dajiecun": {
                "lat": 36.2965,
                "lng": 115.2071
            },
            "Jelily\u00fczi": {
                "lat": 43.9749,
                "lng": 81.5328
            },
            "Wangsicun": {
                "lat": 37.9975,
                "lng": 116.9238
            },
            "Tunzi": {
                "lat": 35.5782,
                "lng": 107.3699
            },
            "Baishi Airikecun": {
                "lat": 40.8748,
                "lng": 80.3616
            },
            "Nanshuicun": {
                "lat": 22.0316,
                "lng": 113.2358
            },
            "Shaxi": {
                "lat": 22.3067,
                "lng": 113.1469
            },
            "Pingxiangcheng": {
                "lat": 36.9819,
                "lng": 114.9131
            },
            "Zuojiawu": {
                "lat": 39.9514,
                "lng": 118.1511
            },
            "Elixku": {
                "lat": 38.6803,
                "lng": 77.3106
            },
            "Wangtuan": {
                "lat": 36.8624,
                "lng": 105.9915
            },
            "Xinpo": {
                "lat": 19.7738,
                "lng": 110.3658
            },
            "Ulan Hua": {
                "lat": 41.53,
                "lng": 111.7
            },
            "Matan": {
                "lat": 30.5731,
                "lng": 113.3342
            },
            "Dabutou": {
                "lat": 36.0708,
                "lng": 112.8744
            },
            "Bulicun": {
                "lat": 24.3657,
                "lng": 116.2726
            },
            "Haomen": {
                "lat": 37.376,
                "lng": 101.6173
            },
            "Danihe": {
                "lat": 39.8489,
                "lng": 119.421
            },
            "Aketao": {
                "lat": 39.1349,
                "lng": 75.9337
            },
            "Yandian": {
                "lat": 31.3271,
                "lng": 113.58
            },
            "Beiya": {
                "lat": 36.464,
                "lng": 104.4513
            },
            "Yejituo": {
                "lat": 39.8634,
                "lng": 118.6645
            },
            "Pingshang": {
                "lat": 23.3974,
                "lng": 115.8842
            },
            "Yangshuwa": {
                "lat": 34.7647,
                "lng": 111.7603
            },
            "Daiyue": {
                "lat": 39.5284,
                "lng": 112.8056
            },
            "Taozhou": {
                "lat": 30.8998,
                "lng": 119.4097
            },
            "Zhentang": {
                "lat": 21.8662,
                "lng": 110.6996
            },
            "Shangzhuangcun": {
                "lat": 23.5226,
                "lng": 116.7134
            },
            "Baohezhuangcun": {
                "lat": 39.685,
                "lng": 116.2967
            },
            "Dama": {
                "lat": 30.5009,
                "lng": 120.3413
            },
            "Jieshang": {
                "lat": 26.2663,
                "lng": 104.6671
            },
            "Tilingzhai": {
                "lat": 40.2353,
                "lng": 118.5282
            },
            "Maojiatang": {
                "lat": 27.5692,
                "lng": 111.7647
            },
            "Cangxi": {
                "lat": 27.6481,
                "lng": 111.1458
            },
            "Shenjiabang": {
                "lat": 30.5783,
                "lng": 120.8193
            },
            "Shangcaiyuan": {
                "lat": 24.6817,
                "lng": 102.6918
            },
            "Zhujiezhen": {
                "lat": 23.7444,
                "lng": 104.9008
            },
            "Zhangziying": {
                "lat": 39.6842,
                "lng": 116.59
            },
            "Shangzhen": {
                "lat": 33.7116,
                "lng": 110.2575
            },
            "Yihezhuang": {
                "lat": 39.1373,
                "lng": 116.0744
            },
            "Zhangzhengqiao": {
                "lat": 38.4042,
                "lng": 106.3567
            },
            "Lixingcun": {
                "lat": 23.0852,
                "lng": 116.3666
            },
            "Pingtang": {
                "lat": 26.0677,
                "lng": 107.4035
            },
            "Zhegaozhen": {
                "lat": 31.7827,
                "lng": 117.7553
            },
            "Peyziwat": {
                "lat": 39.4905,
                "lng": 76.7389
            },
            "Guying": {
                "lat": 38.0887,
                "lng": 114.556
            },
            "Zhaitangcun": {
                "lat": 24.5133,
                "lng": 112.3451
            },
            "Chaodongcun": {
                "lat": 25.0183,
                "lng": 111.1683
            },
            "Xinzhancun": {
                "lat": 43.8667,
                "lng": 127.3333
            },
            "Tekes": {
                "lat": 43.2181,
                "lng": 81.8372
            },
            "Xiaotangzhuang": {
                "lat": 40.3997,
                "lng": 116.765
            },
            "Lianmuqin Kancun": {
                "lat": 42.8833,
                "lng": 89.9333
            },
            "Cishan": {
                "lat": 36.578,
                "lng": 114.102
            },
            "Jishi": {
                "lat": 35.8511,
                "lng": 102.4788
            },
            "Luxitun": {
                "lat": 22.2606,
                "lng": 107.6442
            },
            "Shalingzicun": {
                "lat": 40.6807,
                "lng": 114.9205
            },
            "Rencun": {
                "lat": 22.6585,
                "lng": 112.3674
            },
            "Shaliuhe": {
                "lat": 39.8728,
                "lng": 117.939
            },
            "Xiaodian": {
                "lat": 39.9435,
                "lng": 116.912
            },
            "Zhanjia": {
                "lat": 34.7564,
                "lng": 109.3846
            },
            "Baiyan": {
                "lat": 26.3584,
                "lng": 106.2347
            },
            "Yinchengpu": {
                "lat": 39.8189,
                "lng": 118.188
            },
            "Fucheng": {
                "lat": 35.3678,
                "lng": 103.7074
            },
            "Yankou": {
                "lat": 27.595,
                "lng": 105.4174
            },
            "Zaojiao": {
                "lat": 34.4727,
                "lng": 105.7062
            },
            "Xangda": {
                "lat": 32.2055,
                "lng": 96.4781
            },
            "Shilou": {
                "lat": 39.6519,
                "lng": 115.9747
            },
            "Youwangjie": {
                "lat": 24.8695,
                "lng": 99.1067
            },
            "Nilka": {
                "lat": 43.7826,
                "lng": 82.5089
            },
            "Donggou": {
                "lat": 19.6593,
                "lng": 110.846
            },
            "Zhaoyu": {
                "lat": 37.3512,
                "lng": 112.3193
            },
            "Jiming": {
                "lat": 40.1884,
                "lng": 118.1392
            },
            "Shenwan": {
                "lat": 22.2915,
                "lng": 113.3695
            },
            "Shentang": {
                "lat": 22.3024,
                "lng": 113.3638
            },
            "Wenxian Chengguanzhen": {
                "lat": 32.9421,
                "lng": 104.687
            },
            "Tanmen": {
                "lat": 19.2429,
                "lng": 110.612
            },
            "Duanshan": {
                "lat": 25.7943,
                "lng": 106.6983
            },
            "Beidaying": {
                "lat": 39.9686,
                "lng": 119.5515
            },
            "Yingyangcun": {
                "lat": 22.0974,
                "lng": 106.7567
            },
            "Huangxicun": {
                "lat": 24.4672,
                "lng": 115.7773
            },
            "Dashao": {
                "lat": 39.5517,
                "lng": 115.8206
            },
            "Wanghong Yidui": {
                "lat": 38.1993,
                "lng": 106.2284
            },
            "Andingcun": {
                "lat": 39.6172,
                "lng": 116.4944
            },
            "Taozhuangcun": {
                "lat": 30.9694,
                "lng": 120.8095
            },
            "Fengrenxu": {
                "lat": 24.1757,
                "lng": 115.3271
            },
            "Zhedao": {
                "lat": 24.8098,
                "lng": 98.2961
            },
            "Daheba": {
                "lat": 28.0258,
                "lng": 106.4133
            },
            "Wuyuan": {
                "lat": 41.0896,
                "lng": 108.2722
            },
            "Gongyefu": {
                "lat": 41.8378,
                "lng": 118.5011
            },
            "Liaojiayuan": {
                "lat": 29.3906,
                "lng": 109.9919
            },
            "Xiaba": {
                "lat": 27.8825,
                "lng": 108.1017
            },
            "Shibancun": {
                "lat": 22.1539,
                "lng": 110.7082
            },
            "Shetang": {
                "lat": 34.5568,
                "lng": 105.9722
            },
            "Hengshuicun": {
                "lat": 22.3674,
                "lng": 111.4105
            },
            "Yong\u2019ancun": {
                "lat": 23.1788,
                "lng": 112.6904
            },
            "Yingshouyingzi": {
                "lat": 40.5451,
                "lng": 117.656
            },
            "Toksun": {
                "lat": 42.7918,
                "lng": 88.6536
            },
            "Mangdongshan": {
                "lat": 24.1844,
                "lng": 98.3385
            },
            "Mashan": {
                "lat": 37.0017,
                "lng": 111.8933
            },
            "Nan\u2019ao": {
                "lat": 38.5162,
                "lng": 114.5648
            },
            "Dongyuya": {
                "lat": 29.5619,
                "lng": 111.035
            },
            "Duanzhuang": {
                "lat": 36.5796,
                "lng": 111.7577
            },
            "Yangshuling": {
                "lat": 40.9942,
                "lng": 118.8791
            },
            "Guotang": {
                "lat": 23.8384,
                "lng": 115.9201
            },
            "Benhao": {
                "lat": 18.6122,
                "lng": 109.9587
            },
            "Shuangtian": {
                "lat": 22.864,
                "lng": 114.5443
            },
            "Xiaobazi": {
                "lat": 27.333,
                "lng": 105.5099
            },
            "Zhongwangzhuang": {
                "lat": 38.0333,
                "lng": 115.2667
            },
            "Shiqiao": {
                "lat": 34.1418,
                "lng": 105.1214
            },
            "Matouying": {
                "lat": 39.2922,
                "lng": 118.8143
            },
            "Duzhuang": {
                "lat": 40.0121,
                "lng": 119.5262
            },
            "Pingdeng": {
                "lat": 26.0564,
                "lng": 109.9239
            },
            "Zhonghechang": {
                "lat": 27.8886,
                "lng": 107.2531
            },
            "Tanglou": {
                "lat": 22.2272,
                "lng": 110.863
            },
            "Tantoucun": {
                "lat": 22.1888,
                "lng": 110.871
            },
            "Chahe": {
                "lat": 27.1694,
                "lng": 105.3792
            },
            "Laoaoba": {
                "lat": 26.8377,
                "lng": 105.5292
            },
            "Shangluhu": {
                "lat": 23.2538,
                "lng": 115.5815
            },
            "Zhongtai": {
                "lat": 35.0675,
                "lng": 107.613
            },
            "Zhailuo": {
                "lat": 26.8794,
                "lng": 105.3081
            },
            "Baishaling": {
                "lat": 24.095,
                "lng": 113.7592
            },
            "Qianxucun": {
                "lat": 37.836,
                "lng": 116.6708
            },
            "Toli": {
                "lat": 45.9313,
                "lng": 83.6039
            },
            "Huanghuajing": {
                "lat": 24.1989,
                "lng": 112.9104
            },
            "Heshancun": {
                "lat": 30.6344,
                "lng": 120.3637
            },
            "Guma": {
                "lat": 37.6168,
                "lng": 78.2809
            },
            "Gaotan": {
                "lat": 32.3227,
                "lng": 108.3812
            },
            "Qujingpu": {
                "lat": 38.0814,
                "lng": 106.0489
            },
            "Qianshanhong Nongchang": {
                "lat": 29.1442,
                "lng": 112.4347
            },
            "Jiaoxiling": {
                "lat": 28.219,
                "lng": 113.4764
            },
            "Yangyuhe": {
                "lat": 33.8614,
                "lng": 109.874
            },
            "Uchturpan": {
                "lat": 41.2136,
                "lng": 79.2319
            },
            "Gabasumdo": {
                "lat": 35.2481,
                "lng": 100.5903
            },
            "Shuiding": {
                "lat": 44.05,
                "lng": 80.8667
            },
            "Niuchangqiao": {
                "lat": 26.6247,
                "lng": 106.0077
            },
            "Zongdi": {
                "lat": 25.5909,
                "lng": 106.3163
            },
            "Longtan": {
                "lat": 40.7861,
                "lng": 115.5786
            },
            "Shatiancun": {
                "lat": 21.9053,
                "lng": 110.7151
            },
            "Shilan": {
                "lat": 21.8724,
                "lng": 110.6604
            },
            "Laojiezi": {
                "lat": 26.86,
                "lng": 103.1306
            },
            "Narat": {
                "lat": 43.3198,
                "lng": 84.0147
            },
            "Wengtiancun": {
                "lat": 19.9327,
                "lng": 110.875
            },
            "Wangtang": {
                "lat": 19.9117,
                "lng": 110.8072
            },
            "Tongyangdao": {
                "lat": 41.7676,
                "lng": 109.9711
            },
            "Toqsu": {
                "lat": 41.5417,
                "lng": 82.604
            },
            "Kedu": {
                "lat": 25.7103,
                "lng": 106.846
            },
            "Sihecun": {
                "lat": 22.4729,
                "lng": 111.5734
            },
            "Bo\u2019ao": {
                "lat": 19.1606,
                "lng": 110.5808
            },
            "Haiyang": {
                "lat": 39.9533,
                "lng": 119.5431
            },
            "Lishanpu": {
                "lat": 27.6256,
                "lng": 112.4047
            },
            "Liugoucun": {
                "lat": 40.9455,
                "lng": 118.2708
            },
            "Zuitou": {
                "lat": 34.0622,
                "lng": 107.3128
            },
            "Xima": {
                "lat": 37.9764,
                "lng": 114.6258
            },
            "Shangxiao": {
                "lat": 35.4969,
                "lng": 107.4914
            },
            "Chuanliaocun": {
                "lat": 28.2611,
                "lng": 120.2106
            },
            "Qingshan": {
                "lat": 27.35,
                "lng": 105.02
            },
            "Xincun": {
                "lat": 27.6739,
                "lng": 103.8739
            },
            "Langdu": {
                "lat": 23.3129,
                "lng": 102.2283
            },
            "Jinju": {
                "lat": 22.7073,
                "lng": 111.8223
            },
            "Yuanyangzhen": {
                "lat": 34.7847,
                "lng": 104.7762
            },
            "Zhaicun": {
                "lat": 22.6174,
                "lng": 112.6275
            },
            "Ganjing": {
                "lat": 35.3338,
                "lng": 110.0955
            },
            "Liushuquan": {
                "lat": 39.352,
                "lng": 118.111
            },
            "Dongfeng": {
                "lat": 22.2479,
                "lng": 112.3794
            },
            "Tianyingcun": {
                "lat": 29.9167,
                "lng": 119.4167
            },
            "Dengjiazhuang": {
                "lat": 37.7051,
                "lng": 115.7883
            },
            "Chuimatan": {
                "lat": 35.7166,
                "lng": 102.8771
            },
            "Bangzha": {
                "lat": 24.8345,
                "lng": 104.6721
            },
            "Majiadiancun": {
                "lat": 39.9672,
                "lng": 123.8753
            },
            "Gaoya": {
                "lat": 36.4609,
                "lng": 104.9936
            },
            "Gonglang": {
                "lat": 24.8392,
                "lng": 100.3122
            },
            "Haoping": {
                "lat": 32.5992,
                "lng": 108.6148
            },
            "Yinajia": {
                "lat": 26.8239,
                "lng": 105.695
            },
            "Nanpingcun": {
                "lat": 39.753,
                "lng": 114.0923
            },
            "Zhutailing": {
                "lat": 27.4028,
                "lng": 113.0614
            },
            "Malanguan": {
                "lat": 40.1899,
                "lng": 117.7001
            },
            "Haiwei": {
                "lat": 19.4275,
                "lng": 108.8129
            },
            "Ciying": {
                "lat": 25.3595,
                "lng": 103.9143
            },
            "Zhongcun": {
                "lat": 35.3615,
                "lng": 107.9791
            },
            "Yanyan": {
                "lat": 24.4166,
                "lng": 116.3271
            },
            "Fengruncun": {
                "lat": 34.8537,
                "lng": 109.8283
            },
            "Wumayingcun": {
                "lat": 38.0094,
                "lng": 116.8032
            },
            "Yapqan": {
                "lat": 39.2029,
                "lng": 76.1749
            },
            "Lanling": {
                "lat": 23.0033,
                "lng": 114.5671
            },
            "Yuzhang": {
                "lat": 25.3561,
                "lng": 105.102
            },
            "Dalupo": {
                "lat": 19.3908,
                "lng": 110.4662
            },
            "Wujiaying": {
                "lat": 33.1871,
                "lng": 107.048
            },
            "Liangwu": {
                "lat": 23.6012,
                "lng": 111.8238
            },
            "Beifan": {
                "lat": 31.3881,
                "lng": 113.5269
            },
            "Zhenbeibu": {
                "lat": 38.6275,
                "lng": 106.0669
            },
            "Shitan": {
                "lat": 22.4546,
                "lng": 112.5832
            },
            "Donggangli": {
                "lat": 39.9733,
                "lng": 119.6406
            },
            "Dongxiaozhai": {
                "lat": 40.1149,
                "lng": 118.1375
            },
            "Hanerik": {
                "lat": 39.2627,
                "lng": 76.419
            },
            "Qianwangcun": {
                "lat": 29.2283,
                "lng": 121.3483
            },
            "Zhuchangba": {
                "lat": 26.6615,
                "lng": 106.5485
            },
            "Zhangshanying": {
                "lat": 40.4775,
                "lng": 115.8508
            },
            "Ganzhu": {
                "lat": 26.9483,
                "lng": 116.3714
            },
            "Qiaomaichuan": {
                "lat": 39.7866,
                "lng": 113.8239
            },
            "Liushui": {
                "lat": 32.5959,
                "lng": 108.7479
            },
            "Tangcun": {
                "lat": 37.4377,
                "lng": 115.8463
            },
            "Huilongping": {
                "lat": 28.1889,
                "lng": 106.2086
            },
            "Lihe": {
                "lat": 34.009,
                "lng": 105.3416
            },
            "Jurh": {
                "lat": 44.6961,
                "lng": 120.5123
            },
            "Gaojiayingcun": {
                "lat": 40.8814,
                "lng": 114.9662
            },
            "Hoh Ereg": {
                "lat": 41.0955,
                "lng": 111.4408
            },
            "Dasungezhuang": {
                "lat": 40.0839,
                "lng": 116.9219
            },
            "Xiaojiangcun": {
                "lat": 24.6168,
                "lng": 112.5945
            },
            "Suonan": {
                "lat": 35.6633,
                "lng": 103.39
            },
            "Bazhajiemicun": {
                "lat": 38.8967,
                "lng": 77.6529
            },
            "Xunjiansi": {
                "lat": 23.9625,
                "lng": 103.1836
            },
            "Neihuzhai": {
                "lat": 22.9748,
                "lng": 115.8345
            },
            "Mujiayu": {
                "lat": 40.4097,
                "lng": 116.9325
            },
            "Shiling": {
                "lat": 27.3576,
                "lng": 105.1535
            },
            "Xingang": {
                "lat": 28.9294,
                "lng": 112.316
            },
            "Gy\u00eagu": {
                "lat": 33.0166,
                "lng": 96.7333
            },
            "Zhaoling": {
                "lat": 27.5975,
                "lng": 113.1156
            },
            "Manlin": {
                "lat": 23.663,
                "lng": 101.8853
            },
            "Labuleng": {
                "lat": 35.1973,
                "lng": 102.5187
            },
            "Huaibaijie": {
                "lat": 35.7056,
                "lng": 109.5855
            },
            "Wulongpu": {
                "lat": 37.9356,
                "lng": 110.3566
            },
            "Xinsi": {
                "lat": 34.6503,
                "lng": 104.6302
            },
            "Yanshanbu": {
                "lat": 26.3326,
                "lng": 107.1071
            },
            "Shaomi": {
                "lat": 26.4727,
                "lng": 104.8126
            },
            "Beixingzhuang": {
                "lat": 35.7033,
                "lng": 111.1029
            },
            "Nanyangcun": {
                "lat": 36.0819,
                "lng": 114.3481
            },
            "Yuli": {
                "lat": 41.3351,
                "lng": 86.2614
            },
            "Maying": {
                "lat": 36.0448,
                "lng": 102.8343
            },
            "Zhengtun": {
                "lat": 25.1494,
                "lng": 105.0802
            },
            "Taishituncun": {
                "lat": 40.5364,
                "lng": 117.1219
            },
            "Gonghe": {
                "lat": 35.3308,
                "lng": 108.0885
            },
            "Daxin": {
                "lat": 26.621,
                "lng": 107.2398
            },
            "Zhucaoying": {
                "lat": 40.1759,
                "lng": 119.5909
            },
            "Chankou": {
                "lat": 35.7754,
                "lng": 104.5345
            },
            "Huangzhai": {
                "lat": 38.0605,
                "lng": 112.6701
            },
            "Jiangdi": {
                "lat": 27.0126,
                "lng": 103.6041
            },
            "Pingtan": {
                "lat": 23.2525,
                "lng": 111.4119
            },
            "Qapqal": {
                "lat": 43.834,
                "lng": 81.1581
            },
            "Caojiachuan": {
                "lat": 34.9016,
                "lng": 111.521
            },
            "Dongta": {
                "lat": 38.0824,
                "lng": 106.339
            },
            "Jizhuang": {
                "lat": 40.3614,
                "lng": 116.9858
            },
            "Banbishancun": {
                "lat": 40.37,
                "lng": 117.9317
            },
            "Aoshang": {
                "lat": 25.6842,
                "lng": 113.0322
            },
            "Tongzhou": {
                "lat": 25.7716,
                "lng": 106.973
            },
            "Lihuzhuang": {
                "lat": 39.652,
                "lng": 117.8649
            },
            "Yaojiazhuangcun": {
                "lat": 40.7158,
                "lng": 114.8733
            },
            "Yaojiafen": {
                "lat": 40.6669,
                "lng": 115.0586
            },
            "Xinzhai": {
                "lat": 26.6959,
                "lng": 106.9964
            },
            "Tong\u2019anyi": {
                "lat": 35.3041,
                "lng": 104.6802
            },
            "Bayi": {
                "lat": 29.6664,
                "lng": 94.3628
            },
            "Youganning": {
                "lat": 34.7356,
                "lng": 101.6114
            },
            "Yegainnyin": {
                "lat": 34.7356,
                "lng": 101.5978
            },
            "Jiangjiadong": {
                "lat": 24.4985,
                "lng": 112.8397
            },
            "Qia\u2019erbagecun": {
                "lat": 37.9724,
                "lng": 77.3399
            },
            "Hancha": {
                "lat": 37.8572,
                "lng": 109.4972
            },
            "Xinnongcun": {
                "lat": 42.2357,
                "lng": 122.9455
            },
            "Fengjia": {
                "lat": 27.7969,
                "lng": 110.8603
            },
            "Dabaozi": {
                "lat": 26.7006,
                "lng": 109.4634
            },
            "Agan": {
                "lat": 35.9176,
                "lng": 103.8501
            },
            "Baiheqiao": {
                "lat": 22.9764,
                "lng": 103.7982
            },
            "Hexiang": {
                "lat": 19.5286,
                "lng": 109.6353
            },
            "Hanmayingcun": {
                "lat": 41.2636,
                "lng": 117.8596
            },
            "Manbengtang": {
                "lat": 22.0896,
                "lng": 100.8931
            },
            "Qagan Us": {
                "lat": 36.3972,
                "lng": 98.0122
            },
            "Gandujie": {
                "lat": 35.8908,
                "lng": 102.3272
            },
            "Longbangcun": {
                "lat": 22.8833,
                "lng": 106.3333
            },
            "Yangping": {
                "lat": 27.7607,
                "lng": 100.6614
            },
            "Guding": {
                "lat": 26.4876,
                "lng": 107.446
            },
            "Diguapo": {
                "lat": 25.6988,
                "lng": 104.9614
            },
            "Jiangjiehe": {
                "lat": 27.2618,
                "lng": 107.3886
            },
            "Sanjianxiang": {
                "lat": 27.5425,
                "lng": 111.3475
            },
            "Yuancun": {
                "lat": 27.454,
                "lng": 106.6649
            },
            "Zhaodianzi": {
                "lat": 39.9373,
                "lng": 118.6401
            },
            "Xingangli": {
                "lat": 39.9101,
                "lng": 119.5468
            },
            "Mengdan": {
                "lat": 24.2752,
                "lng": 98.4672
            },
            "Hongshui": {
                "lat": 38.5077,
                "lng": 100.8814
            },
            "Guantiankan": {
                "lat": 28.2966,
                "lng": 106.6083
            },
            "Yuanhucun": {
                "lat": 44.1977,
                "lng": 86.8928
            },
            "Liuliang": {
                "lat": 35.2695,
                "lng": 105.986
            },
            "Doujing": {
                "lat": 26.4789,
                "lng": 105.1253
            },
            "Liuguang": {
                "lat": 26.997,
                "lng": 106.4376
            },
            "Hoxut": {
                "lat": 42.2552,
                "lng": 86.8607
            },
            "Hushihacun": {
                "lat": 40.8899,
                "lng": 116.987
            },
            "Xihuangcun": {
                "lat": 37.1411,
                "lng": 114.2293
            },
            "Heishanzuicun": {
                "lat": 41.0354,
                "lng": 116.9304
            },
            "Heishuikeng": {
                "lat": 37.7905,
                "lng": 110.5711
            },
            "Tangbian": {
                "lat": 25.6539,
                "lng": 106.7793
            },
            "Magitang": {
                "lat": 35.9506,
                "lng": 101.9686
            },
            "Zerong": {
                "lat": 24.9692,
                "lng": 104.9558
            },
            "Tazishan": {
                "lat": 41.0937,
                "lng": 119.0408
            },
            "Lejiangxiang": {
                "lat": 25.9161,
                "lng": 109.8719
            },
            "Caowotan": {
                "lat": 37.2739,
                "lng": 104.1046
            },
            "Qitai": {
                "lat": 41.5494,
                "lng": 113.5339
            },
            "Hala": {
                "lat": 26.8272,
                "lng": 103.9938
            },
            "Liuma": {
                "lat": 25.6682,
                "lng": 105.8732
            },
            "Mingjiujie": {
                "lat": 23.4509,
                "lng": 103.6482
            },
            "Shaogang": {
                "lat": 38.1583,
                "lng": 106.0661
            },
            "Guanzhai": {
                "lat": 26.2697,
                "lng": 105.3089
            },
            "Zhangshicun": {
                "lat": 24.5477,
                "lng": 113.524
            },
            "Longjia": {
                "lat": 36.0962,
                "lng": 113.419
            },
            "Sanpaicun": {
                "lat": 24.6642,
                "lng": 112.2935
            },
            "Xintian": {
                "lat": 23.1427,
                "lng": 103.5489
            },
            "Shaoyu": {
                "lat": 34.0629,
                "lng": 105.3672
            },
            "Shuangluan": {
                "lat": 40.9608,
                "lng": 117.7939
            },
            "Shaying": {
                "lat": 25.974,
                "lng": 105.4233
            },
            "Qishe": {
                "lat": 24.9232,
                "lng": 104.7218
            },
            "Gangwuzhen": {
                "lat": 25.9644,
                "lng": 105.3376
            },
            "Matigou": {
                "lat": 37.6043,
                "lng": 109.9024
            },
            "Yuanquan": {
                "lat": 40.5004,
                "lng": 95.8
            },
            "Gaoniang": {
                "lat": 26.8394,
                "lng": 109.177
            },
            "Mengjiacun": {
                "lat": 40.0186,
                "lng": 119.7878
            },
            "Erjie": {
                "lat": 24.6997,
                "lng": 102.4872
            },
            "Miaojiaping": {
                "lat": 37.5777,
                "lng": 110.0658
            },
            "Minquan": {
                "lat": 37.442,
                "lng": 103.3811
            },
            "Dingjiagouxiang": {
                "lat": 35.5307,
                "lng": 105.0207
            },
            "Zhongguyue": {
                "lat": 38.2833,
                "lng": 113.8667
            },
            "Chedaopo": {
                "lat": 36.4008,
                "lng": 106.7351
            },
            "Quanzhang": {
                "lat": 35.6613,
                "lng": 111.1049
            },
            "Shangjing": {
                "lat": 24.5601,
                "lng": 114.8943
            },
            "Shanglingcun": {
                "lat": 24.6076,
                "lng": 114.9939
            },
            "Duijiang": {
                "lat": 27.0782,
                "lng": 105.5256
            },
            "Zhoujia": {
                "lat": 35.2976,
                "lng": 108.0347
            },
            "Dijiasuoxiang": {
                "lat": 35.6883,
                "lng": 105.2586
            },
            "Bakeshiyingcun": {
                "lat": 40.7237,
                "lng": 117.193
            },
            "Xintangcun": {
                "lat": 23.9423,
                "lng": 116.7865
            },
            "Xinying": {
                "lat": 35.706,
                "lng": 104.1824
            },
            "Yanggezhuang": {
                "lat": 39.3831,
                "lng": 118.7119
            },
            "Liuchuan": {
                "lat": 26.6549,
                "lng": 108.5876
            },
            "Liuguoju": {
                "lat": 38.2571,
                "lng": 110.3597
            },
            "Long\u2019e": {
                "lat": 25.8061,
                "lng": 109.2134
            },
            "Dalain Hob": {
                "lat": 41.9645,
                "lng": 101.0667
            },
            "Dazhangzicun": {
                "lat": 40.6239,
                "lng": 118.1081
            },
            "Dazhangzi": {
                "lat": 40.7463,
                "lng": 118.1318
            },
            "Miqiao": {
                "lat": 35.4991,
                "lng": 108.2949
            },
            "Xixinzhuangzhen": {
                "lat": 37.0165,
                "lng": 111.4908
            },
            "Dongjiangshui": {
                "lat": 33.3787,
                "lng": 104.9516
            },
            "Fuying": {
                "lat": 41.0302,
                "lng": 117.7205
            },
            "Fuyingzicun": {
                "lat": 40.8754,
                "lng": 117.6978
            },
            "Zequ": {
                "lat": 35.0376,
                "lng": 101.4606
            },
            "Xiaolongtan": {
                "lat": 23.7836,
                "lng": 103.1563
            },
            "Xinyuan": {
                "lat": 37.2953,
                "lng": 99.0341
            },
            "Wentang": {
                "lat": 23.9918,
                "lng": 112.2868
            },
            "Liangyi": {
                "lat": 35.2698,
                "lng": 106.093
            },
            "Langrucun": {
                "lat": 36.9014,
                "lng": 79.6261
            },
            "Jinjicun": {
                "lat": 22.165,
                "lng": 112.4848
            },
            "Jinji": {
                "lat": 21.9898,
                "lng": 112.4549
            },
            "Lufeng": {
                "lat": 24.5738,
                "lng": 103.113
            },
            "Zhongzai": {
                "lat": 26.6877,
                "lng": 105.6548
            },
            "Taohongpozhen": {
                "lat": 36.9854,
                "lng": 111.4737
            },
            "Baipingshan": {
                "lat": 26.196,
                "lng": 106.55
            },
            "Changji": {
                "lat": 26.9471,
                "lng": 108.7524
            },
            "Bikou": {
                "lat": 32.7489,
                "lng": 105.2408
            },
            "Longchang": {
                "lat": 27.6627,
                "lng": 105.7881
            },
            "Yunshan": {
                "lat": 34.761,
                "lng": 105.7915
            },
            "Xiangjiaba": {
                "lat": 28.5627,
                "lng": 104.3461
            },
            "Chaona": {
                "lat": 35.1,
                "lng": 107.2167
            },
            "Chinggil": {
                "lat": 46.6673,
                "lng": 90.3786
            },
            "Dalachi": {
                "lat": 36.6392,
                "lng": 105.0156
            },
            "Yaojia": {
                "lat": 28.4547,
                "lng": 109.1831
            },
            "Longji": {
                "lat": 25.7317,
                "lng": 110.0631
            },
            "D\u00eangka": {
                "lat": 34.0587,
                "lng": 103.2074
            },
            "Dianga": {
                "lat": 34.0636,
                "lng": 103.2136
            },
            "Hongsi": {
                "lat": 35.5113,
                "lng": 105.5136
            },
            "Xiaqiaotou": {
                "lat": 27.2167,
                "lng": 100.15
            },
            "Xiaozhengzhuang": {
                "lat": 39.625,
                "lng": 117.9031
            },
            "Xixucun": {
                "lat": 36.694,
                "lng": 113.7967
            },
            "Hedongcun": {
                "lat": 37.7544,
                "lng": 102.7906
            },
            "Wenwu": {
                "lat": 24.7413,
                "lng": 116.1942
            },
            "Chenlu": {
                "lat": 35.0286,
                "lng": 109.1547
            },
            "Jiaojiazhuang": {
                "lat": 38.2636,
                "lng": 101.8328
            },
            "Mangqu": {
                "lat": 35.5833,
                "lng": 100.75
            },
            "Dalanping": {
                "lat": 28.0189,
                "lng": 110.0025
            },
            "Banqiao": {
                "lat": 35.8912,
                "lng": 107.9655
            },
            "Baiceng": {
                "lat": 25.3885,
                "lng": 105.7848
            },
            "Andicun": {
                "lat": 28.9761,
                "lng": 119.6403
            },
            "Dengshangcun": {
                "lat": 41.3442,
                "lng": 118.1676
            },
            "Shangtianba": {
                "lat": 28.039,
                "lng": 103.8584
            },
            "Humayingcun": {
                "lat": 41.1145,
                "lng": 116.8985
            },
            "Xiangyuncun": {
                "lat": 24.9641,
                "lng": 118.1668
            },
            "Majiagoucha": {
                "lat": 37.5033,
                "lng": 109.6303
            },
            "Xihuangni": {
                "lat": 38.3575,
                "lng": 113.8519
            },
            "Ut Bulag": {
                "lat": 44.9089,
                "lng": 82.2054
            },
            "Yinhua": {
                "lat": 33.453,
                "lng": 110.2601
            },
            "Huchuan": {
                "lat": 34.9249,
                "lng": 106.1409
            },
            "Beizhou": {
                "lat": 29.1636,
                "lng": 112.6811
            },
            "Quan\u2019ancun": {
                "lat": 25.1345,
                "lng": 114.2646
            },
            "Qiaoyang": {
                "lat": 35.0716,
                "lng": 104.1846
            },
            "Jinta": {
                "lat": 37.8573,
                "lng": 102.5775
            },
            "Weizhou": {
                "lat": 21.0214,
                "lng": 109.1214
            },
            "Shuichecun": {
                "lat": 24.09,
                "lng": 116.0174
            },
            "Shimubi": {
                "lat": 19.1645,
                "lng": 110.3043
            },
            "Huasuo": {
                "lat": 35.4043,
                "lng": 107.0869
            },
            "Jun Bel": {
                "lat": 39.2428,
                "lng": 106.1922
            },
            "Guanduqiao": {
                "lat": 29.4792,
                "lng": 111.2975
            },
            "Yanhewan": {
                "lat": 36.7522,
                "lng": 109.3678
            },
            "Wangjiaxian": {
                "lat": 36.5443,
                "lng": 104.1496
            },
            "Majia": {
                "lat": 35.4599,
                "lng": 103.2076
            },
            "Jiaoxiyakou": {
                "lat": 26.1274,
                "lng": 102.4502
            },
            "Yanshuiguan": {
                "lat": 36.8252,
                "lng": 110.2345
            },
            "Wulong": {
                "lat": 23.3202,
                "lng": 112.1901
            },
            "Sanjiang Nongchang": {
                "lat": 19.8808,
                "lng": 110.602
            },
            "Lakoucun": {
                "lat": 28.3412,
                "lng": 119.9523
            },
            "Taiyong": {
                "lat": 26.4726,
                "lng": 108.5105
            },
            "Chumpak": {
                "lat": 41.8585,
                "lng": 84.137
            },
            "Beiwusidui": {
                "lat": 40.0692,
                "lng": 116.8228
            },
            "Zhipingxiang": {
                "lat": 35.295,
                "lng": 105.6158
            },
            "Ping\u2019anbao": {
                "lat": 40.4901,
                "lng": 117.5973
            },
            "Huangyadong": {
                "lat": 36.8039,
                "lng": 113.4446
            },
            "Jinmingsi": {
                "lat": 38.0512,
                "lng": 110.2869
            },
            "Chaoyangdicun": {
                "lat": 42.0221,
                "lng": 118.2178
            },
            "Xireg": {
                "lat": 36.925,
                "lng": 98.478
            },
            "Mangha": {
                "lat": 23.4883,
                "lng": 98.9078
            },
            "Yongcong": {
                "lat": 26.0451,
                "lng": 109.1327
            },
            "Qingxicun": {
                "lat": 24.53,
                "lng": 116.5904
            },
            "Shangping": {
                "lat": 25.0897,
                "lng": 113.0395
            },
            "Ganxi": {
                "lat": 27.1274,
                "lng": 109.1755
            },
            "Sunjiayan": {
                "lat": 27.8776,
                "lng": 108.3073
            },
            "Kaiwen": {
                "lat": 27.1548,
                "lng": 99.8371
            },
            "Qigexingcun": {
                "lat": 42.02,
                "lng": 86.3031
            },
            "Bugugoucun": {
                "lat": 41.6904,
                "lng": 117.4483
            },
            "Liangwancun": {
                "lat": 28.5034,
                "lng": 104.2611
            },
            "Damaishan": {
                "lat": 24.5038,
                "lng": 112.2797
            },
            "Ceyu": {
                "lat": 37.7939,
                "lng": 114.0905
            },
            "Mahao": {
                "lat": 26.8675,
                "lng": 108.3267
            },
            "Huguan Nongchang": {
                "lat": 21.2015,
                "lng": 110.2459
            },
            "Kaji": {
                "lat": 26.0285,
                "lng": 102.7888
            },
            "Shanyincun": {
                "lat": 37.9151,
                "lng": 114.4126
            },
            "Pingtiancun": {
                "lat": 25.2225,
                "lng": 114.6252
            },
            "Shiwan": {
                "lat": 37.4786,
                "lng": 109.423
            },
            "Fengdeng": {
                "lat": 38.5514,
                "lng": 106.2464
            },
            "Tashi": {
                "lat": 34.4977,
                "lng": 106.5281
            },
            "Zhongling": {
                "lat": 28.9391,
                "lng": 107.7073
            },
            "Honggu": {
                "lat": 36.293,
                "lng": 102.9575
            },
            "Luchong": {
                "lat": 27.2832,
                "lng": 109.0346
            },
            "Qiaotouba": {
                "lat": 33.8116,
                "lng": 104.8493
            },
            "Dongyuancun": {
                "lat": 28.319,
                "lng": 120.2321
            },
            "Jiuduhe": {
                "lat": 40.3594,
                "lng": 116.4539
            },
            "Nanzhai": {
                "lat": 26.6299,
                "lng": 108.7645
            },
            "Reshuijie": {
                "lat": 24.458,
                "lng": 114.8282
            },
            "Xinyingheyan": {
                "lat": 35.3369,
                "lng": 103.2681
            },
            "Shichuanxiang": {
                "lat": 34.5866,
                "lng": 104.3211
            },
            "Huodoushancun": {
                "lat": 40.7797,
                "lng": 117.204
            },
            "Xinbocun": {
                "lat": 42.3037,
                "lng": 117.7259
            },
            "Xinchangcun": {
                "lat": 26.4249,
                "lng": 107.5323
            },
            "Maqu": {
                "lat": 35.9451,
                "lng": 106.9953
            },
            "Shuangxianxiang": {
                "lat": 35.33,
                "lng": 105.695
            },
            "Xiushuicun": {
                "lat": 25.1728,
                "lng": 113.0007
            },
            "Liuba": {
                "lat": 38.1634,
                "lng": 102.1493
            },
            "Lajia": {
                "lat": 34.6797,
                "lng": 100.6406
            },
            "Baxiangshan": {
                "lat": 23.7642,
                "lng": 115.9696
            },
            "Erbaocun": {
                "lat": 42.9581,
                "lng": 93.1153
            },
            "Jiajin": {
                "lat": 25.6743,
                "lng": 108.4228
            },
            "Aheqi": {
                "lat": 40.9365,
                "lng": 78.4023
            },
            "Huanian": {
                "lat": 24.0781,
                "lng": 102.201
            },
            "Baohe": {
                "lat": 33.2033,
                "lng": 106.9544
            },
            "Wanding": {
                "lat": 24.0833,
                "lng": 98.0667
            },
            "Yuxiaguan": {
                "lat": 36.0311,
                "lng": 113.558
            },
            "Mangpa": {
                "lat": 23.4923,
                "lng": 98.9118
            },
            "Muping": {
                "lat": 30.3692,
                "lng": 102.8139
            },
            "Bajiao": {
                "lat": 27.6573,
                "lng": 108.1937
            },
            "Diao\u2019ecun": {
                "lat": 40.7227,
                "lng": 115.8201
            },
            "Shuinancun": {
                "lat": 23.2995,
                "lng": 112.3753
            },
            "Xiaoba": {
                "lat": 26.7217,
                "lng": 106.9236
            },
            "Hejiaji": {
                "lat": 37.3539,
                "lng": 109.8377
            },
            "Lijiacha": {
                "lat": 37.2467,
                "lng": 109.4061
            },
            "Caotan": {
                "lat": 36.2501,
                "lng": 105.1742
            },
            "Sanjiaocheng": {
                "lat": 36.8908,
                "lng": 100.9997
            },
            "Bailin": {
                "lat": 33.485,
                "lng": 105.0034
            },
            "Gucheng": {
                "lat": 34.4545,
                "lng": 105.2064
            },
            "Wulingshancun": {
                "lat": 40.4755,
                "lng": 117.4888
            },
            "Wen\u2019anyi": {
                "lat": 36.8658,
                "lng": 110.0553
            },
            "Longtaixiang": {
                "lat": 34.5988,
                "lng": 104.8963
            },
            "Aba": {
                "lat": 32.9039,
                "lng": 101.6925
            },
            "Wangjiabian": {
                "lat": 38.2412,
                "lng": 110.2353
            },
            "Mawu": {
                "lat": 34.431,
                "lng": 104.9187
            },
            "Xinyaoshang": {
                "lat": 26.835,
                "lng": 106.8403
            },
            "Zhamog": {
                "lat": 29.8595,
                "lng": 95.7681
            },
            "Pingcha": {
                "lat": 26.349,
                "lng": 109.3791
            },
            "Ma\u2019ai": {
                "lat": 34.5937,
                "lng": 102.4814
            },
            "Pingtouchuanxiang": {
                "lat": 35.8763,
                "lng": 105.3241
            },
            "Xintianfeng": {
                "lat": 24.4575,
                "lng": 115.5205
            },
            "M\u00eadog": {
                "lat": 29.4833,
                "lng": 95.5
            },
            "Guantingzhan": {
                "lat": 40.2492,
                "lng": 115.578
            },
            "Sanjiangkou": {
                "lat": 24.7579,
                "lng": 104.5901
            },
            "Gaohucun": {
                "lat": 28.3367,
                "lng": 120.2178
            },
            "Xiaozui": {
                "lat": 35.6912,
                "lng": 108.0779
            },
            "Chuqung": {
                "lat": 33.3667,
                "lng": 97.1333
            },
            "Bangshang": {
                "lat": 32.2575,
                "lng": 108.1119
            },
            "Kuiyibagecun": {
                "lat": 38.1023,
                "lng": 77.1539
            },
            "Shenjiaba": {
                "lat": 32.9441,
                "lng": 108.6414
            },
            "Haikoucun": {
                "lat": 28.3237,
                "lng": 120.0853
            },
            "Qiushanxiang": {
                "lat": 34.3562,
                "lng": 104.8983
            },
            "Kangning": {
                "lat": 38.0176,
                "lng": 102.352
            },
            "Lishuping": {
                "lat": 33.1832,
                "lng": 104.8881
            },
            "Fenggeling": {
                "lat": 34.5312,
                "lng": 106.4437
            },
            "Tanggemu Nongchang": {
                "lat": 36.0781,
                "lng": 100.0911
            },
            "Biandanshan": {
                "lat": 26.0409,
                "lng": 105.6351
            },
            "Nanfang": {
                "lat": 23.3568,
                "lng": 115.5167
            },
            "Fanzhao": {
                "lat": 26.6615,
                "lng": 108.3834
            },
            "Zhengdong": {
                "lat": 22.4819,
                "lng": 101.5078
            },
            "Hongliuwan": {
                "lat": 39.6348,
                "lng": 94.3386
            },
            "Shengping": {
                "lat": 28.4865,
                "lng": 98.913
            },
            "Gar": {
                "lat": 32.1166,
                "lng": 80.0583
            },
            "Ziketan": {
                "lat": 35.5886,
                "lng": 99.9867
            },
            "Yueyaquan": {
                "lat": 40.1256,
                "lng": 94.6632
            },
            "Gaolingcun": {
                "lat": 40.6003,
                "lng": 117.1072
            },
            "Dujiashigou": {
                "lat": 37.7691,
                "lng": 110.0705
            },
            "Dongcha": {
                "lat": 34.38,
                "lng": 106.6623
            },
            "Ruoqiang": {
                "lat": 39.0181,
                "lng": 88.1681
            },
            "Jixian": {
                "lat": 35.7321,
                "lng": 107.9731
            },
            "Dangcheng": {
                "lat": 39.5161,
                "lng": 94.8728
            },
            "Xiba": {
                "lat": 40.1645,
                "lng": 98.7521
            },
            "Zengjiaba": {
                "lat": 32.1263,
                "lng": 109.4692
            },
            "Aweitancun": {
                "lat": 47.7251,
                "lng": 88.0499
            },
            "Shiyuan": {
                "lat": 35.7991,
                "lng": 102.8437
            },
            "Xiada": {
                "lat": 24.0391,
                "lng": 113.4543
            },
            "Pagq\u00ean": {
                "lat": 33.9739,
                "lng": 99.9083
            },
            "Jingjiazhuang": {
                "lat": 40.4703,
                "lng": 116.0869
            },
            "Dachengzicun": {
                "lat": 40.3919,
                "lng": 117.1028
            },
            "Benbutucun": {
                "lat": 42.0253,
                "lng": 86.7034
            },
            "Yezhi": {
                "lat": 27.7035,
                "lng": 99.0458
            },
            "Baluntaicun": {
                "lat": 42.7594,
                "lng": 86.3231
            },
            "Leichi": {
                "lat": 36.3351,
                "lng": 110.2612
            },
            "Xiangping": {
                "lat": 24.5892,
                "lng": 112.1237
            },
            "Dazhuangzi": {
                "lat": 40.2321,
                "lng": 99.0558
            },
            "Nanmucun": {
                "lat": 25.1619,
                "lng": 114.5559
            },
            "Dashouping": {
                "lat": 23.6425,
                "lng": 111.6978
            },
            "Daping": {
                "lat": 24.6501,
                "lng": 112.1693
            },
            "Nanzhangcheng": {
                "lat": 37.9108,
                "lng": 114.0749
            },
            "Gy\u00fcmai": {
                "lat": 33.756,
                "lng": 99.65
            },
            "Digar": {
                "lat": 42.685,
                "lng": 89.7711
            },
            "Bifeng": {
                "lat": 30.1067,
                "lng": 103.0133
            },
            "Hongtuliang": {
                "lat": 40.9986,
                "lng": 114.155
            },
            "Zhujiagua": {
                "lat": 38.2242,
                "lng": 110.4032
            },
            "Heyin": {
                "lat": 36.0451,
                "lng": 101.4242
            },
            "Gyangz\u00ea": {
                "lat": 28.9148,
                "lng": 89.6045
            },
            "Jinzhong": {
                "lat": 26.3504,
                "lng": 103.4167
            },
            "Fulin": {
                "lat": 29.3489,
                "lng": 102.6767
            },
            "Linxi": {
                "lat": 43.5171,
                "lng": 118.0333
            },
            "Panying": {
                "lat": 33.0023,
                "lng": 112.5281
            },
            "Chengde": {
                "lat": 40.9604,
                "lng": 117.93
            },
            "Huinan": {
                "lat": 42.6229,
                "lng": 126.2614
            }
        }
    },
    "PH": {
        "name": "Philippines",
        "cities": {
            "Manila": {
                "lat": 14.5958,
                "lng": 120.9772
            },
            "Quezon City": {
                "lat": 14.65,
                "lng": 121.0475
            },
            "Zamboanga City": {
                "lat": 6.9042,
                "lng": 122.0761
            },
            "Davao": {
                "lat": 7.07,
                "lng": 125.6
            },
            "Caloocan City": {
                "lat": 14.65,
                "lng": 120.97
            },
            "Canagatan": {
                "lat": 18.0,
                "lng": 121.8
            },
            "Cebu City": {
                "lat": 10.32,
                "lng": 123.75
            },
            "Antipolo": {
                "lat": 14.5842,
                "lng": 121.1763
            },
            "Taguig City": {
                "lat": 14.52,
                "lng": 121.05
            },
            "Pasig City": {
                "lat": 14.5605,
                "lng": 121.0765
            },
            "Cagayan de Oro": {
                "lat": 8.48,
                "lng": 124.65
            },
            "Valenzuela": {
                "lat": 14.7,
                "lng": 120.98
            },
            "Dasmari\u00f1as": {
                "lat": 14.33,
                "lng": 120.94
            },
            "General Santos": {
                "lat": 6.12,
                "lng": 125.17
            },
            "City of Para\u00f1aque": {
                "lat": 14.5008,
                "lng": 120.9915
            },
            "Bacoor": {
                "lat": 14.4624,
                "lng": 120.9645
            },
            "Tondo": {
                "lat": 14.617,
                "lng": 120.967
            },
            "San Jose del Monte": {
                "lat": 14.8139,
                "lng": 121.0453
            },
            "Makati City": {
                "lat": 14.5567,
                "lng": 121.0214
            },
            "Las Pi\u00f1as City": {
                "lat": 14.45,
                "lng": 120.98
            },
            "Bacolod": {
                "lat": 8.1892,
                "lng": 124.0238
            },
            "Iloilo": {
                "lat": 10.72,
                "lng": 122.57
            },
            "Muntinlupa City": {
                "lat": 14.38,
                "lng": 121.05
            },
            "City of Calamba": {
                "lat": 14.22,
                "lng": 121.17
            },
            "Lapu-Lapu City": {
                "lat": 10.3127,
                "lng": 123.9488
            },
            "Imus": {
                "lat": 14.4297,
                "lng": 120.9367
            },
            "Angeles City": {
                "lat": 15.1472,
                "lng": 120.5847
            },
            "Marikina City": {
                "lat": 14.65,
                "lng": 121.1
            },
            "General Trias": {
                "lat": 14.38,
                "lng": 120.88
            },
            "Rodriguez": {
                "lat": 14.72,
                "lng": 121.12
            },
            "Pasay City": {
                "lat": 14.55,
                "lng": 121.0
            },
            "Mandaluyong City": {
                "lat": 14.58,
                "lng": 121.03
            },
            "Santa Rosa": {
                "lat": 15.4239,
                "lng": 120.9389
            },
            "Bi\u00f1an": {
                "lat": 14.33,
                "lng": 121.08
            },
            "Sampaloc": {
                "lat": 14.1625,
                "lng": 121.6378
            },
            "Taytay": {
                "lat": 10.82,
                "lng": 119.52
            },
            "Tarlac City": {
                "lat": 15.4869,
                "lng": 120.59
            },
            "Malabon": {
                "lat": 14.66,
                "lng": 120.96
            },
            "Cainta": {
                "lat": 14.57,
                "lng": 121.12
            },
            "Lipa City": {
                "lat": 13.9411,
                "lng": 121.1622
            },
            "Butuan": {
                "lat": 8.948,
                "lng": 125.543
            },
            "Baguio City": {
                "lat": 16.4119,
                "lng": 120.5933
            },
            "Mandaue City": {
                "lat": 10.33,
                "lng": 123.93
            },
            "Iligan": {
                "lat": 8.23,
                "lng": 124.25
            },
            "Cabuyao": {
                "lat": 14.275,
                "lng": 121.125
            },
            "San Fernando": {
                "lat": 12.4858,
                "lng": 123.7628
            },
            "Batangas": {
                "lat": 13.83,
                "lng": 121.0
            },
            "Naga City": {
                "lat": 13.6244,
                "lng": 123.1864
            },
            "Cabanatuan City": {
                "lat": 15.4908,
                "lng": 120.9678
            },
            "San Pedro": {
                "lat": 14.3583,
                "lng": 121.0583
            },
            "Cotabato": {
                "lat": 7.22,
                "lng": 124.25
            },
            "Binangonan": {
                "lat": 14.4514,
                "lng": 121.1919
            },
            "Tanza": {
                "lat": 14.3944,
                "lng": 120.8531
            },
            "Puerto Princesa": {
                "lat": 9.75,
                "lng": 118.75
            },
            "Tagum": {
                "lat": 7.4478,
                "lng": 125.8078
            },
            "Silang": {
                "lat": 14.2306,
                "lng": 120.975
            },
            "Mabalacat": {
                "lat": 15.22,
                "lng": 120.58
            },
            "Santa Maria": {
                "lat": 17.47,
                "lng": 121.75
            },
            "San Pablo": {
                "lat": 17.4478,
                "lng": 121.795
            },
            "Lucena": {
                "lat": 10.88,
                "lng": 122.6
            },
            "San Mateo": {
                "lat": 16.88,
                "lng": 121.58
            },
            "Talisay": {
                "lat": 14.1356,
                "lng": 122.9244
            },
            "Malolos": {
                "lat": 14.8433,
                "lng": 120.8114
            },
            "Olongapo": {
                "lat": 14.83,
                "lng": 120.28
            },
            "Marilao": {
                "lat": 14.7581,
                "lng": 120.9481
            },
            "Tacloban": {
                "lat": 11.24,
                "lng": 125.0
            },
            "Navotas": {
                "lat": 14.6667,
                "lng": 120.9417
            },
            "Ormoc": {
                "lat": 11.0106,
                "lng": 124.6075
            },
            "Meycauayan": {
                "lat": 14.73,
                "lng": 120.95
            },
            "Santo Tomas": {
                "lat": 17.4,
                "lng": 121.77
            },
            "Valencia": {
                "lat": 9.6097,
                "lng": 124.208
            },
            "Trece Martires City": {
                "lat": 14.28,
                "lng": 120.87
            },
            "Pagadian": {
                "lat": 7.8272,
                "lng": 123.4364
            },
            "Legazpi City": {
                "lat": 13.13,
                "lng": 123.73
            },
            "Panabo": {
                "lat": 7.3,
                "lng": 125.68
            },
            "Toledo": {
                "lat": 10.38,
                "lng": 123.65
            },
            "Marawi City": {
                "lat": 8.0031,
                "lng": 124.285
            },
            "San Carlos City": {
                "lat": 15.9281,
                "lng": 120.3489
            },
            "Santa Ana": {
                "lat": 14.5277,
                "lng": 121.0766
            },
            "Kabankalan": {
                "lat": 9.98,
                "lng": 122.82
            },
            "Koronadal": {
                "lat": 6.2541,
                "lng": 124.9922
            },
            "Tanauan": {
                "lat": 11.12,
                "lng": 125.02
            },
            "Bago": {
                "lat": 10.5388,
                "lng": 122.8384
            },
            "Malaybalay": {
                "lat": 8.1564,
                "lng": 125.1333
            },
            "Digos": {
                "lat": 6.75,
                "lng": 125.35
            },
            "Calbayog City": {
                "lat": 12.07,
                "lng": 124.6
            },
            "Sorsogon": {
                "lat": 12.9742,
                "lng": 124.0058
            },
            "Roxas City": {
                "lat": 11.5894,
                "lng": 122.7511
            },
            "Dagupan City": {
                "lat": 16.043,
                "lng": 120.334
            },
            "Lubao": {
                "lat": 14.93,
                "lng": 120.6
            },
            "Mexico": {
                "lat": 15.07,
                "lng": 120.72
            },
            "Polomolok": {
                "lat": 6.22,
                "lng": 125.07
            },
            "General Mariano Alvarez": {
                "lat": 14.3,
                "lng": 121.0
            },
            "San Miguel": {
                "lat": 13.6411,
                "lng": 124.3008
            },
            "Surigao": {
                "lat": 9.7897,
                "lng": 125.4958
            },
            "Concepcion": {
                "lat": 8.4167,
                "lng": 123.6
            },
            "Baliuag": {
                "lat": 14.954,
                "lng": 120.901
            },
            "Tuguegarao": {
                "lat": 17.6133,
                "lng": 121.7303
            },
            "Sariaya": {
                "lat": 13.97,
                "lng": 121.53
            },
            "Naic": {
                "lat": 14.32,
                "lng": 120.77
            },
            "Kidapawan": {
                "lat": 7.0083,
                "lng": 125.0894
            },
            "Cadiz": {
                "lat": 10.95,
                "lng": 123.3
            },
            "Ilagan": {
                "lat": 17.1489,
                "lng": 121.8894
            },
            "Danao": {
                "lat": 10.02,
                "lng": 124.27
            },
            "Capas": {
                "lat": 15.3372,
                "lng": 120.59
            },
            "Pandi": {
                "lat": 14.87,
                "lng": 120.95
            },
            "San Jose": {
                "lat": 10.18,
                "lng": 125.5683
            },
            "Poblacion": {
                "lat": 10.1614,
                "lng": 125.1303
            },
            "Minglanilla": {
                "lat": 10.245,
                "lng": 123.7964
            },
            "Mariveles": {
                "lat": 14.43,
                "lng": 120.48
            },
            "Sagay": {
                "lat": 9.12,
                "lng": 124.72
            },
            "Santiago": {
                "lat": 17.2947,
                "lng": 120.4453
            },
            "Consolacion": {
                "lat": 10.4,
                "lng": 123.95
            },
            "Mati": {
                "lat": 6.9483,
                "lng": 126.2272
            },
            "Calapan": {
                "lat": 13.414,
                "lng": 121.18
            },
            "Arayat": {
                "lat": 15.1493,
                "lng": 120.7692
            },
            "Urdaneta": {
                "lat": 15.9761,
                "lng": 120.5711
            },
            "Calabayan": {
                "lat": 16.77,
                "lng": 121.78
            },
            "Malasiqui": {
                "lat": 15.92,
                "lng": 120.42
            },
            "Bocaue": {
                "lat": 14.8,
                "lng": 120.93
            },
            "Tabaco": {
                "lat": 13.35,
                "lng": 123.73
            },
            "Porac": {
                "lat": 15.0719,
                "lng": 120.5419
            },
            "Ozamiz City": {
                "lat": 8.15,
                "lng": 123.85
            },
            "Payatas": {
                "lat": 14.7099,
                "lng": 121.0997
            },
            "Tanay": {
                "lat": 14.4972,
                "lng": 121.2864
            },
            "Dipolog": {
                "lat": 8.5872,
                "lng": 123.3408
            },
            "Candelaria": {
                "lat": 15.63,
                "lng": 119.93
            },
            "La Trinidad": {
                "lat": 16.46,
                "lng": 120.59
            },
            "Jolo": {
                "lat": 6.0,
                "lng": 121.0
            },
            "Gingoog": {
                "lat": 8.82,
                "lng": 125.1
            },
            "Nasugbu": {
                "lat": 14.07,
                "lng": 120.63
            },
            "Carcar": {
                "lat": 10.12,
                "lng": 123.63
            },
            "Norzagaray": {
                "lat": 14.92,
                "lng": 121.05
            },
            "Floridablanca": {
                "lat": 14.974,
                "lng": 120.528
            },
            "Dumaguete City": {
                "lat": 9.3103,
                "lng": 123.3081
            },
            "Daraga": {
                "lat": 13.1619,
                "lng": 123.6939
            },
            "San Andres": {
                "lat": 13.3231,
                "lng": 122.6761
            },
            "Hagonoy": {
                "lat": 6.68,
                "lng": 125.3
            },
            "Naga": {
                "lat": 7.7978,
                "lng": 122.6944
            },
            "San Carlos": {
                "lat": 10.4929,
                "lng": 123.4095
            },
            "Talavera": {
                "lat": 15.5839,
                "lng": 120.9189
            },
            "Angono": {
                "lat": 14.5234,
                "lng": 121.1536
            },
            "Silay": {
                "lat": 10.8,
                "lng": 122.97
            },
            "City of Isabela": {
                "lat": 6.7,
                "lng": 121.97
            },
            "Bamban": {
                "lat": 15.2742,
                "lng": 120.5669
            },
            "Guagua": {
                "lat": 14.97,
                "lng": 120.63
            },
            "Rosario": {
                "lat": 12.52,
                "lng": 124.42
            },
            "Guimba": {
                "lat": 15.6606,
                "lng": 120.765
            },
            "Santa Cruz": {
                "lat": 14.1167,
                "lng": 121.2833
            },
            "San Juan": {
                "lat": 17.68,
                "lng": 120.73
            },
            "Magalang": {
                "lat": 15.22,
                "lng": 120.67
            },
            "Gapan": {
                "lat": 15.3122,
                "lng": 120.9489
            },
            "Bayawan": {
                "lat": 9.37,
                "lng": 122.8
            },
            "Tabuk": {
                "lat": 17.4084,
                "lng": 121.2785
            },
            "Candaba": {
                "lat": 15.0933,
                "lng": 120.8283
            },
            "Calumpit": {
                "lat": 14.92,
                "lng": 120.77
            },
            "Dinalupihan": {
                "lat": 14.88,
                "lng": 120.47
            },
            "Malita": {
                "lat": 6.4108,
                "lng": 125.6144
            },
            "Ligao": {
                "lat": 13.22,
                "lng": 123.52
            },
            "Midsayap": {
                "lat": 7.19,
                "lng": 124.53
            },
            "Apalit": {
                "lat": 14.9496,
                "lng": 120.7587
            },
            "Samal": {
                "lat": 14.7678,
                "lng": 120.5431
            },
            "Datu Odin Sinsuat": {
                "lat": 7.1889,
                "lng": 124.1839
            },
            "Himamaylan": {
                "lat": 10.1,
                "lng": 122.87
            },
            "Bongao": {
                "lat": 5.0292,
                "lng": 119.7731
            },
            "San Ildefonso": {
                "lat": 15.0789,
                "lng": 120.9419
            },
            "Los Ba\u00f1os": {
                "lat": 14.17,
                "lng": 121.22
            },
            "Iriga City": {
                "lat": 13.4231,
                "lng": 123.4122
            },
            "Plaridel": {
                "lat": 13.9511,
                "lng": 122.0203
            },
            "Guiguinto": {
                "lat": 14.83,
                "lng": 120.88
            },
            "Manolo Fortich": {
                "lat": 8.3675,
                "lng": 124.8656
            },
            "Mangaldan": {
                "lat": 16.07,
                "lng": 120.4039
            },
            "Libmanan": {
                "lat": 13.6964,
                "lng": 123.06
            },
            "Tayabas": {
                "lat": 14.02,
                "lng": 121.58
            },
            "Subic": {
                "lat": 14.8769,
                "lng": 120.2328
            },
            "Baybay": {
                "lat": 10.68,
                "lng": 124.8
            },
            "Daet": {
                "lat": 14.1142,
                "lng": 122.9564
            },
            "Laoag": {
                "lat": 18.1978,
                "lng": 120.5936
            },
            "Santamesa": {
                "lat": 14.6,
                "lng": 121.0167
            },
            "Quezon": {
                "lat": 14.05,
                "lng": 122.13
            },
            "Naujan": {
                "lat": 13.3233,
                "lng": 121.3028
            },
            "Glan": {
                "lat": 5.82,
                "lng": 125.2
            },
            "Bayugan": {
                "lat": 8.71,
                "lng": 125.75
            },
            "Tacurong": {
                "lat": 6.68,
                "lng": 124.67
            },
            "Labo": {
                "lat": 14.1561,
                "lng": 122.8294
            },
            "Pulilan": {
                "lat": 14.902,
                "lng": 120.849
            },
            "Cawayan": {
                "lat": 11.9303,
                "lng": 123.7689
            },
            "Maramag": {
                "lat": 7.7631,
                "lng": 125.005
            },
            "Lingayen": {
                "lat": 16.0167,
                "lng": 120.2333
            },
            "Kawit": {
                "lat": 14.43,
                "lng": 120.9
            },
            "Bagong Silangan": {
                "lat": 14.7094,
                "lng": 121.1
            },
            "Catbalogan": {
                "lat": 11.78,
                "lng": 124.88
            },
            "Tiaong": {
                "lat": 13.95,
                "lng": 121.32
            },
            "Carmona": {
                "lat": 14.32,
                "lng": 121.05
            },
            "Malu\u00f1gun": {
                "lat": 6.27,
                "lng": 125.28
            },
            "Bulan": {
                "lat": 12.6697,
                "lng": 123.8758
            },
            "Sultan Kudarat": {
                "lat": 7.23,
                "lng": 124.25
            },
            "Tagbilaran City": {
                "lat": 9.65,
                "lng": 123.85
            },
            "Masbate": {
                "lat": 12.27,
                "lng": 123.58
            },
            "Balanga": {
                "lat": 14.68,
                "lng": 120.53
            },
            "Sindangan": {
                "lat": 8.2386,
                "lng": 122.9986
            },
            "San Rafael": {
                "lat": 11.1728,
                "lng": 122.8267
            },
            "Paniqui": {
                "lat": 15.6681,
                "lng": 120.5789
            },
            "Parang": {
                "lat": 5.92,
                "lng": 120.92
            },
            "Guihul\u00f1gan": {
                "lat": 10.12,
                "lng": 123.27
            },
            "Cavite City": {
                "lat": 14.48,
                "lng": 120.9
            },
            "Calasiao": {
                "lat": 16.02,
                "lng": 120.37
            },
            "Lamitan": {
                "lat": 6.65,
                "lng": 122.13
            },
            "Talipao": {
                "lat": 5.976,
                "lng": 121.1087
            },
            "Putatan": {
                "lat": 14.3984,
                "lng": 121.0364
            },
            "Alaminos": {
                "lat": 14.0635,
                "lng": 121.2451
            },
            "Bislig": {
                "lat": 8.2133,
                "lng": 126.3161
            },
            "Malate": {
                "lat": 14.5642,
                "lng": 120.9913
            },
            "Pili": {
                "lat": 13.7177,
                "lng": 123.7448
            },
            "Oton": {
                "lat": 10.6931,
                "lng": 122.4736
            },
            "M\u2019lang": {
                "lat": 6.95,
                "lng": 124.88
            },
            "Catarman": {
                "lat": 9.13,
                "lng": 124.68
            },
            "Isulan": {
                "lat": 6.63,
                "lng": 124.6
            },
            "Escalante": {
                "lat": 10.83,
                "lng": 123.5
            },
            "Balayan": {
                "lat": 13.93,
                "lng": 120.73
            },
            "Balamban": {
                "lat": 10.47,
                "lng": 123.78
            },
            "Lopez": {
                "lat": 13.884,
                "lng": 122.2604
            },
            "Gerona": {
                "lat": 15.6069,
                "lng": 120.5986
            },
            "Monkayo": {
                "lat": 7.8239,
                "lng": 126.0536
            },
            "Daanbantayan": {
                "lat": 11.25,
                "lng": 124.0
            },
            "Lemery": {
                "lat": 11.23,
                "lng": 122.93
            },
            "Indanan": {
                "lat": 6.0,
                "lng": 120.97
            },
            "Palimbang": {
                "lat": 6.22,
                "lng": 124.2
            },
            "Sablayan": {
                "lat": 12.8428,
                "lng": 120.775
            },
            "Santa Barbara": {
                "lat": 10.8231,
                "lng": 122.5344
            },
            "Salaman": {
                "lat": 6.63,
                "lng": 124.07
            },
            "Bauan": {
                "lat": 13.7917,
                "lng": 121.0085
            },
            "Pantukan": {
                "lat": 7.1269,
                "lng": 125.8975
            },
            "Pongotan": {
                "lat": 7.1269,
                "lng": 125.8975
            },
            "Pinamalayan": {
                "lat": 13.0364,
                "lng": 121.4889
            },
            "Victorias": {
                "lat": 10.9,
                "lng": 123.08
            },
            "Compostela": {
                "lat": 7.67,
                "lng": 126.08
            },
            "Ipil": {
                "lat": 7.7822,
                "lng": 122.5867
            },
            "Surallah": {
                "lat": 6.37,
                "lng": 124.73
            },
            "Polangui": {
                "lat": 13.2922,
                "lng": 123.4856
            },
            "Banga": {
                "lat": 6.3,
                "lng": 124.78
            },
            "Kalibo": {
                "lat": 11.7072,
                "lng": 122.37
            },
            "Hinigaran": {
                "lat": 10.27,
                "lng": 122.85
            },
            "Calabanga": {
                "lat": 13.7089,
                "lng": 123.2161
            },
            "Passi": {
                "lat": 11.1,
                "lng": 122.63
            },
            "Murcia": {
                "lat": 10.6,
                "lng": 123.03
            },
            "Bogo": {
                "lat": 11.02,
                "lng": 124.0
            },
            "Solana": {
                "lat": 17.6522,
                "lng": 121.6914
            },
            "Echague": {
                "lat": 16.7056,
                "lng": 121.6761
            },
            "Aroroy": {
                "lat": 12.5125,
                "lng": 123.3989
            },
            "Prosperidad": {
                "lat": 8.61,
                "lng": 125.92
            },
            "Alabel": {
                "lat": 6.1023,
                "lng": 125.2868
            },
            "Makilala": {
                "lat": 6.97,
                "lng": 125.08
            },
            "Baggao": {
                "lat": 17.9347,
                "lng": 121.7733
            },
            "Calauan": {
                "lat": 14.15,
                "lng": 121.32
            },
            "Baras": {
                "lat": 13.67,
                "lng": 124.37
            },
            "Western Bicutan": {
                "lat": 14.5094,
                "lng": 121.0381
            },
            "Maasin": {
                "lat": 10.88,
                "lng": 122.43
            },
            "San Fabian": {
                "lat": 16.15,
                "lng": 120.45
            },
            "Calaca": {
                "lat": 13.93,
                "lng": 120.8131
            },
            "Camiling": {
                "lat": 15.6867,
                "lng": 120.4131
            },
            "Binmaley": {
                "lat": 16.0323,
                "lng": 120.269
            },
            "Nabua": {
                "lat": 13.4083,
                "lng": 123.375
            },
            "Bantayan": {
                "lat": 11.2,
                "lng": 123.73
            },
            "Cabiao": {
                "lat": 15.2522,
                "lng": 120.8575
            },
            "Guinobatan": {
                "lat": 13.18,
                "lng": 123.6
            },
            "Batarasa": {
                "lat": 8.67,
                "lng": 117.62
            },
            "Tagaytay": {
                "lat": 14.1,
                "lng": 120.93
            },
            "Dapitan": {
                "lat": 8.6549,
                "lng": 123.4243
            },
            "Pandacan": {
                "lat": 14.594,
                "lng": 121.007
            },
            "Nabunturan": {
                "lat": 7.6008,
                "lng": 125.9656
            },
            "Bais": {
                "lat": 9.5907,
                "lng": 123.1213
            },
            "Science City of Mu\u00f1oz": {
                "lat": 15.7153,
                "lng": 120.9039
            },
            "Bolinao": {
                "lat": 16.3881,
                "lng": 119.8947
            },
            "Maco": {
                "lat": 7.3619,
                "lng": 125.8553
            },
            "Siaton": {
                "lat": 9.07,
                "lng": 123.03
            },
            "San Antonio": {
                "lat": 12.414,
                "lng": 124.279
            },
            "Mabinay": {
                "lat": 9.73,
                "lng": 122.92
            },
            "Tanjay": {
                "lat": 9.52,
                "lng": 123.16
            },
            "Calatrava": {
                "lat": 12.6167,
                "lng": 122.0708
            },
            "Carmen": {
                "lat": 9.2289,
                "lng": 126.0167
            },
            "Ubay": {
                "lat": 10.056,
                "lng": 124.4729
            },
            "Banco Filipino International Village": {
                "lat": 14.4499,
                "lng": 120.9996
            },
            "Siasi": {
                "lat": 5.5462,
                "lng": 120.8145
            },
            "Matalam": {
                "lat": 7.08,
                "lng": 124.9
            },
            "Buhi": {
                "lat": 13.4347,
                "lng": 123.5167
            },
            "Lambunao": {
                "lat": 11.05,
                "lng": 122.48
            },
            "Bulacan": {
                "lat": 14.7928,
                "lng": 120.8789
            },
            "Kapalong": {
                "lat": 7.5854,
                "lng": 125.7052
            },
            "San Francisco": {
                "lat": 10.65,
                "lng": 124.35
            },
            "Malapatan": {
                "lat": 5.97,
                "lng": 125.28
            },
            "Barili": {
                "lat": 10.12,
                "lng": 123.53
            },
            "Cabadbaran": {
                "lat": 9.12,
                "lng": 125.53
            },
            "Tagoloan": {
                "lat": 8.1092,
                "lng": 124.4392
            },
            "Paco": {
                "lat": 14.583,
                "lng": 120.983
            },
            "Patikul": {
                "lat": 6.07,
                "lng": 121.1
            },
            "Robles": {
                "lat": 10.35,
                "lng": 123.07
            },
            "San Isidro": {
                "lat": 9.9369,
                "lng": 126.0886
            },
            "Mangatarem": {
                "lat": 15.7874,
                "lng": 120.2921
            },
            "Jaen": {
                "lat": 15.3392,
                "lng": 120.9069
            },
            "Pagbilao": {
                "lat": 13.972,
                "lng": 121.687
            },
            "Bauang": {
                "lat": 16.53,
                "lng": 120.33
            },
            "Pototan": {
                "lat": 10.95,
                "lng": 122.63
            },
            "Limay": {
                "lat": 14.5619,
                "lng": 120.5983
            },
            "Argao": {
                "lat": 9.88,
                "lng": 123.6
            },
            "Macabebe": {
                "lat": 14.9081,
                "lng": 120.7156
            },
            "Narra": {
                "lat": 9.28,
                "lng": 118.42
            },
            "Santa Catalina": {
                "lat": 17.5917,
                "lng": 120.3644
            },
            "Hermosa": {
                "lat": 14.83,
                "lng": 120.5
            },
            "Bustos": {
                "lat": 14.95,
                "lng": 120.92
            },
            "Kabacan": {
                "lat": 7.12,
                "lng": 124.82
            },
            "Umingan": {
                "lat": 15.9289,
                "lng": 120.8419
            },
            "Talakag": {
                "lat": 8.2319,
                "lng": 124.6025
            },
            "Balagtas": {
                "lat": 14.8145,
                "lng": 120.9085
            },
            "Bongabong": {
                "lat": 12.7469,
                "lng": 121.4881
            },
            "Virac": {
                "lat": 13.58,
                "lng": 124.23
            },
            "Palo": {
                "lat": 11.1583,
                "lng": 124.9917
            },
            "Infanta": {
                "lat": 15.8208,
                "lng": 119.9083
            },
            "Manaoag": {
                "lat": 16.0439,
                "lng": 120.4856
            },
            "Pilar": {
                "lat": 17.42,
                "lng": 120.6
            },
            "Pinamungahan": {
                "lat": 10.27,
                "lng": 123.58
            },
            "Libon": {
                "lat": 13.3,
                "lng": 123.4333
            },
            "Bugallon": {
                "lat": 15.92,
                "lng": 120.18
            },
            "Pozorrubio": {
                "lat": 16.12,
                "lng": 120.55
            },
            "Esperanza": {
                "lat": 11.7369,
                "lng": 124.0417
            },
            "Dalaguete": {
                "lat": 9.7612,
                "lng": 123.5349
            },
            "Balingasag": {
                "lat": 8.75,
                "lng": 124.78
            },
            "Brookes Point": {
                "lat": 8.78,
                "lng": 117.83
            },
            "Dumangas": {
                "lat": 10.83,
                "lng": 122.72
            },
            "Alicia": {
                "lat": 9.8957,
                "lng": 124.4415
            },
            "Tupi": {
                "lat": 6.33,
                "lng": 124.95
            },
            "Lala": {
                "lat": 7.97,
                "lng": 123.75
            },
            "Jose Abad Santos": {
                "lat": 5.92,
                "lng": 125.65
            },
            "Bula": {
                "lat": 13.4694,
                "lng": 123.2775
            },
            "Catanauan": {
                "lat": 13.5917,
                "lng": 122.325
            },
            "Carles": {
                "lat": 11.57,
                "lng": 123.13
            },
            "San Dionisio": {
                "lat": 11.2711,
                "lng": 123.0948
            },
            "Bacacay": {
                "lat": 13.2925,
                "lng": 123.7917
            },
            "Sipalay": {
                "lat": 9.75,
                "lng": 122.4
            },
            "Oroquieta": {
                "lat": 8.48,
                "lng": 123.8
            },
            "Camalig": {
                "lat": 13.13,
                "lng": 123.67
            },
            "Borongan": {
                "lat": 11.6094,
                "lng": 125.4361
            },
            "Gumaca": {
                "lat": 13.921,
                "lng": 122.1002
            },
            "Calauag": {
                "lat": 13.9575,
                "lng": 122.2875
            },
            "Pililla": {
                "lat": 14.48,
                "lng": 121.3
            },
            "Binalbagan": {
                "lat": 10.2,
                "lng": 122.87
            },
            "Goa": {
                "lat": 13.6983,
                "lng": 123.4889
            },
            "Talibon": {
                "lat": 10.12,
                "lng": 124.28
            },
            "Morong": {
                "lat": 14.68,
                "lng": 120.2683
            },
            "Mauban": {
                "lat": 14.1911,
                "lng": 121.7308
            },
            "Alabang": {
                "lat": 14.4184,
                "lng": 121.0385
            },
            "Tumauini": {
                "lat": 17.27,
                "lng": 121.8
            },
            "Pavia": {
                "lat": 10.775,
                "lng": 122.5417
            },
            "Aliaga": {
                "lat": 15.5036,
                "lng": 120.845
            },
            "Orani": {
                "lat": 14.8,
                "lng": 120.53
            },
            "Rizal": {
                "lat": 8.5272,
                "lng": 123.5517
            },
            "Tinambac": {
                "lat": 13.8183,
                "lng": 123.3244
            },
            "Roxas": {
                "lat": 12.58,
                "lng": 121.5
            },
            "Victoria": {
                "lat": 12.45,
                "lng": 124.32
            },
            "Don Carlos": {
                "lat": 7.6808,
                "lng": 124.9953
            },
            "San Pascual": {
                "lat": 13.1286,
                "lng": 122.9775
            },
            "La Paz": {
                "lat": 17.6739,
                "lng": 120.6847
            },
            "Bagumbayan": {
                "lat": 14.4744,
                "lng": 121.0569
            },
            "Buenavista": {
                "lat": 13.25,
                "lng": 121.95
            },
            "Aparri": {
                "lat": 18.3575,
                "lng": 121.6372
            },
            "Indang": {
                "lat": 14.2,
                "lng": 120.88
            },
            "Alamada": {
                "lat": 7.3868,
                "lng": 124.5534
            },
            "San Leonardo": {
                "lat": 15.3611,
                "lng": 120.9622
            },
            "Mamungan": {
                "lat": 8.12,
                "lng": 124.22
            },
            "Tangub": {
                "lat": 8.0667,
                "lng": 123.75
            },
            "Sipocot": {
                "lat": 13.7675,
                "lng": 122.9783
            },
            "Tuburan": {
                "lat": 6.6,
                "lng": 122.2
            },
            "Miagao": {
                "lat": 10.6442,
                "lng": 122.2352
            },
            "Cuyapo": {
                "lat": 15.7778,
                "lng": 120.6608
            },
            "Castillejos": {
                "lat": 14.93,
                "lng": 120.2
            },
            "Bayombong": {
                "lat": 16.48,
                "lng": 121.15
            },
            "Bay": {
                "lat": 14.18,
                "lng": 121.28
            },
            "Pikit": {
                "lat": 7.05,
                "lng": 124.67
            },
            "Lupon": {
                "lat": 6.8969,
                "lng": 126.0117
            },
            "Bongabon": {
                "lat": 15.6321,
                "lng": 121.1448
            },
            "Janiuay": {
                "lat": 10.95,
                "lng": 122.5
            },
            "Botolan": {
                "lat": 15.2896,
                "lng": 120.0245
            },
            "Rosales": {
                "lat": 15.8944,
                "lng": 120.6328
            },
            "La Carlota": {
                "lat": 10.42,
                "lng": 122.92
            },
            "Opol": {
                "lat": 8.52,
                "lng": 124.57
            },
            "Oas": {
                "lat": 13.2589,
                "lng": 123.4953
            },
            "Agoo": {
                "lat": 16.322,
                "lng": 120.3647
            },
            "Lantapan": {
                "lat": 8.0,
                "lng": 125.0233
            },
            "Coron": {
                "lat": 12.0,
                "lng": 120.2
            },
            "Kiamba": {
                "lat": 5.98,
                "lng": 124.62
            },
            "San Remigio": {
                "lat": 10.8331,
                "lng": 122.0875
            },
            "Lutayan": {
                "lat": 6.6,
                "lng": 124.85
            },
            "Angat": {
                "lat": 14.9281,
                "lng": 121.0293
            },
            "Solano": {
                "lat": 16.5183,
                "lng": 121.1811
            },
            "Tigbauan": {
                "lat": 10.6747,
                "lng": 122.3776
            },
            "Pateros": {
                "lat": 14.5448,
                "lng": 121.0671
            },
            "Villasis": {
                "lat": 15.9,
                "lng": 120.58
            },
            "Nagcarlan": {
                "lat": 14.1364,
                "lng": 121.4165
            },
            "Isabela": {
                "lat": 10.2,
                "lng": 122.98
            },
            "Hilongos": {
                "lat": 10.37,
                "lng": 124.75
            },
            "Malvar": {
                "lat": 14.0417,
                "lng": 121.1583
            },
            "Sibulan": {
                "lat": 9.35,
                "lng": 123.28
            },
            "Saravia": {
                "lat": 10.88,
                "lng": 122.97
            },
            "Atimonan": {
                "lat": 14.0036,
                "lng": 121.9199
            },
            "Teresa": {
                "lat": 14.5586,
                "lng": 121.2083
            },
            "Tuao": {
                "lat": 17.735,
                "lng": 121.4553
            },
            "Sibalom": {
                "lat": 10.7883,
                "lng": 122.0175
            },
            "Jose Pa\u00f1ganiban": {
                "lat": 14.2922,
                "lng": 122.6928
            },
            "Calinog": {
                "lat": 11.13,
                "lng": 122.5
            },
            "Moncada": {
                "lat": 15.7331,
                "lng": 120.5725
            },
            "Bansalan": {
                "lat": 6.78,
                "lng": 125.22
            },
            "Tandag": {
                "lat": 9.0789,
                "lng": 126.1986
            },
            "Kapatagan": {
                "lat": 7.9,
                "lng": 123.77
            },
            "Aurora": {
                "lat": 16.9918,
                "lng": 121.6357
            },
            "Cambanugoy": {
                "lat": 7.5386,
                "lng": 125.7508
            },
            "Itogon": {
                "lat": 16.37,
                "lng": 120.67
            },
            "Baao": {
                "lat": 13.4535,
                "lng": 123.3654
            },
            "Taal": {
                "lat": 13.88,
                "lng": 120.93
            },
            "Candon": {
                "lat": 17.1958,
                "lng": 120.4489
            },
            "Tunasan": {
                "lat": 14.3725,
                "lng": 121.0364
            },
            "Abuyog": {
                "lat": 10.7458,
                "lng": 125.0122
            },
            "Cabatuan": {
                "lat": 16.9589,
                "lng": 121.6692
            },
            "Santo Domingo": {
                "lat": 17.6414,
                "lng": 120.4108
            },
            "Tulunan": {
                "lat": 6.83,
                "lng": 124.88
            },
            "Jinoba-an": {
                "lat": 9.6018,
                "lng": 122.4668
            },
            "Orion": {
                "lat": 14.6206,
                "lng": 120.5817
            },
            "Castilla": {
                "lat": 12.9553,
                "lng": 123.8764
            },
            "Laoang": {
                "lat": 12.57,
                "lng": 125.02
            },
            "Tigaon": {
                "lat": 13.6319,
                "lng": 123.4975
            },
            "Matanao": {
                "lat": 6.75,
                "lng": 125.23
            },
            "Gubat": {
                "lat": 12.9189,
                "lng": 124.1231
            },
            "Paracale": {
                "lat": 14.2797,
                "lng": 122.7869
            },
            "San Mariano": {
                "lat": 16.98,
                "lng": 122.02
            },
            "Malay": {
                "lat": 11.8997,
                "lng": 121.9094
            },
            "Obando": {
                "lat": 14.7083,
                "lng": 120.9375
            },
            "Sigaboy": {
                "lat": 6.6536,
                "lng": 126.0717
            },
            "Ilog": {
                "lat": 10.03,
                "lng": 122.77
            },
            "Ragay": {
                "lat": 13.8217,
                "lng": 122.7897
            },
            "Kananya": {
                "lat": 11.1856,
                "lng": 124.5597
            },
            "Medellin": {
                "lat": 11.1286,
                "lng": 123.9622
            },
            "Maimbung": {
                "lat": 5.93,
                "lng": 121.03
            },
            "Alfonso": {
                "lat": 14.1379,
                "lng": 120.8552
            },
            "Irosin": {
                "lat": 12.705,
                "lng": 124.0319
            },
            "San Simon": {
                "lat": 14.998,
                "lng": 120.78
            },
            "Mansalay": {
                "lat": 12.5204,
                "lng": 121.4385
            },
            "Upi": {
                "lat": 7.0106,
                "lng": 124.1629
            },
            "Gattaran": {
                "lat": 18.0611,
                "lng": 121.6453
            },
            "Canlaon": {
                "lat": 10.38,
                "lng": 123.2
            },
            "El Salvador": {
                "lat": 8.57,
                "lng": 124.52
            },
            "Calatagan": {
                "lat": 13.8322,
                "lng": 120.6322
            },
            "Baganga": {
                "lat": 7.5752,
                "lng": 126.5585
            },
            "San Luis": {
                "lat": 15.72,
                "lng": 121.52
            },
            "Ibaan": {
                "lat": 13.8176,
                "lng": 121.133
            },
            "Palompon": {
                "lat": 11.05,
                "lng": 124.38
            },
            "Naval": {
                "lat": 11.58,
                "lng": 124.45
            },
            "Barotac Nuevo": {
                "lat": 10.9,
                "lng": 122.7
            },
            "Masantol": {
                "lat": 14.9,
                "lng": 120.72
            },
            "New Corella": {
                "lat": 7.5866,
                "lng": 125.8237
            },
            "Cogan": {
                "lat": 10.58,
                "lng": 123.97
            },
            "Dumanjog": {
                "lat": 10.05,
                "lng": 123.48
            },
            "Asingan": {
                "lat": 16.0023,
                "lng": 120.6695
            },
            "Milagros": {
                "lat": 12.2192,
                "lng": 123.5089
            },
            "Buluan": {
                "lat": 6.7154,
                "lng": 124.7854
            },
            "Boac": {
                "lat": 13.45,
                "lng": 121.83
            },
            "Cupang": {
                "lat": 14.4315,
                "lng": 121.0486
            },
            "Alangalang": {
                "lat": 11.2061,
                "lng": 124.8478
            },
            "Uson": {
                "lat": 12.2253,
                "lng": 123.7834
            },
            "Jasaan": {
                "lat": 8.65,
                "lng": 124.75
            },
            "Pinagkaisahan": {
                "lat": 14.5229,
                "lng": 121.0555
            },
            "Lagonoy": {
                "lat": 13.7353,
                "lng": 123.5208
            },
            "Basey": {
                "lat": 11.2817,
                "lng": 125.0683
            },
            "Pangantocan": {
                "lat": 7.8331,
                "lng": 124.8294
            },
            "Ramon": {
                "lat": 16.78,
                "lng": 121.53
            },
            "Tiwi": {
                "lat": 13.4585,
                "lng": 123.6805
            },
            "Binalonan": {
                "lat": 16.05,
                "lng": 120.6
            },
            "Sucat": {
                "lat": 14.46,
                "lng": 121.05
            },
            "Placer": {
                "lat": 9.657,
                "lng": 125.6016
            },
            "Lian": {
                "lat": 14.03,
                "lng": 120.65
            },
            "Libungan": {
                "lat": 7.25,
                "lng": 124.52
            },
            "Magsaysay": {
                "lat": 10.87,
                "lng": 121.05
            },
            "Diffun": {
                "lat": 16.5936,
                "lng": 121.5025
            },
            "Lower Bicutan": {
                "lat": 14.5033,
                "lng": 121.0642
            },
            "General Tinio": {
                "lat": 15.35,
                "lng": 121.05
            },
            "Bambang": {
                "lat": 14.5228,
                "lng": 121.0689
            },
            "Paombong": {
                "lat": 14.8311,
                "lng": 120.7892
            },
            "Iba": {
                "lat": 15.33,
                "lng": 119.98
            },
            "Mulanay": {
                "lat": 13.5222,
                "lng": 122.4042
            },
            "Urbiztondo": {
                "lat": 15.8227,
                "lng": 120.3295
            },
            "Batac": {
                "lat": 18.0554,
                "lng": 120.5649
            },
            "Tatalon": {
                "lat": 14.6242,
                "lng": 121.0153
            },
            "Asturias": {
                "lat": 10.5679,
                "lng": 123.7172
            },
            "Mercedes": {
                "lat": 14.1093,
                "lng": 123.0109
            },
            "Manapla": {
                "lat": 10.958,
                "lng": 123.123
            },
            "Carigara": {
                "lat": 11.3,
                "lng": 124.68
            },
            "Sara": {
                "lat": 11.25,
                "lng": 123.02
            },
            "Pila": {
                "lat": 14.23,
                "lng": 121.37
            },
            "Masinloc": {
                "lat": 15.53,
                "lng": 119.95
            },
            "Pontevedra": {
                "lat": 11.48,
                "lng": 122.83
            },
            "Trento": {
                "lat": 8.05,
                "lng": 126.06
            },
            "Tapas": {
                "lat": 11.2622,
                "lng": 122.5369
            },
            "San Manuel": {
                "lat": 15.7975,
                "lng": 120.6072
            },
            "Don Bosco": {
                "lat": 14.4817,
                "lng": 121.0258
            },
            "Tagkawayan": {
                "lat": 13.97,
                "lng": 122.53
            },
            "Titay": {
                "lat": 7.8703,
                "lng": 122.5594
            },
            "Minalabac": {
                "lat": 13.57,
                "lng": 123.1844
            },
            "Vigan": {
                "lat": 17.5747,
                "lng": 120.3869
            },
            "Cabagan": {
                "lat": 17.43,
                "lng": 121.77
            },
            "Impasugong": {
                "lat": 8.3028,
                "lng": 125.0014
            },
            "Magpet": {
                "lat": 7.12,
                "lng": 125.12
            },
            "Kitaotao": {
                "lat": 7.6406,
                "lng": 125.0086
            },
            "Ajuy": {
                "lat": 11.1725,
                "lng": 123.0196
            },
            "Pasacao": {
                "lat": 13.5103,
                "lng": 123.0419
            },
            "Sibonga": {
                "lat": 10.03,
                "lng": 123.57
            },
            "Guiuan": {
                "lat": 11.03,
                "lng": 125.73
            },
            "Estancia": {
                "lat": 11.45,
                "lng": 123.15
            },
            "Barobo": {
                "lat": 8.5292,
                "lng": 126.1217
            },
            "Molave": {
                "lat": 8.0933,
                "lng": 123.485
            },
            "Lucban": {
                "lat": 14.1133,
                "lng": 121.5569
            },
            "Zaragoza": {
                "lat": 15.4492,
                "lng": 120.7933
            },
            "Pigcawayan": {
                "lat": 7.28,
                "lng": 124.43
            },
            "Hamtic": {
                "lat": 10.7,
                "lng": 121.98
            },
            "San Joaquin": {
                "lat": 10.6,
                "lng": 122.08
            },
            "Bani": {
                "lat": 16.1869,
                "lng": 119.8592
            },
            "President Roxas": {
                "lat": 11.4297,
                "lng": 122.9247
            },
            "Burauen": {
                "lat": 10.9833,
                "lng": 124.9
            },
            "Dauis": {
                "lat": 9.625,
                "lng": 123.865
            },
            "Claveria": {
                "lat": 18.6061,
                "lng": 121.0822
            },
            "Ibajay": {
                "lat": 11.8211,
                "lng": 122.1617
            },
            "Sison": {
                "lat": 9.6592,
                "lng": 125.5272
            },
            "Naguilian": {
                "lat": 17.02,
                "lng": 121.85
            },
            "Bato": {
                "lat": 13.6,
                "lng": 124.3
            },
            "Leon": {
                "lat": 10.7808,
                "lng": 122.3894
            },
            "Padre Garcia": {
                "lat": 13.88,
                "lng": 121.22
            },
            "Caramoan": {
                "lat": 13.7707,
                "lng": 123.8631
            },
            "Santa Ignacia": {
                "lat": 15.62,
                "lng": 120.43
            },
            "Bantacan": {
                "lat": 7.5483,
                "lng": 126.1381
            },
            "Ocampo": {
                "lat": 13.5594,
                "lng": 123.3761
            },
            "San Narciso": {
                "lat": 15.02,
                "lng": 120.08
            },
            "Kalamansig": {
                "lat": 6.57,
                "lng": 124.05
            },
            "Mabini": {
                "lat": 16.0697,
                "lng": 119.94
            },
            "Cataingan": {
                "lat": 12.0028,
                "lng": 123.9944
            },
            "Gloria": {
                "lat": 12.9722,
                "lng": 121.4778
            },
            "El Nido": {
                "lat": 11.1956,
                "lng": 119.4075
            },
            "Bangued": {
                "lat": 17.6,
                "lng": 120.62
            },
            "Aringay": {
                "lat": 16.3982,
                "lng": 120.3555
            },
            "Wao": {
                "lat": 7.6404,
                "lng": 124.7257
            },
            "Amulung": {
                "lat": 17.8387,
                "lng": 121.7235
            },
            "Pe\u00f1ablanca": {
                "lat": 17.6258,
                "lng": 121.7853
            },
            "Donsol": {
                "lat": 12.9083,
                "lng": 123.5981
            },
            "Cardona": {
                "lat": 14.4861,
                "lng": 121.2289
            },
            "Tubod": {
                "lat": 9.5547,
                "lng": 125.5697
            },
            "Pananaw": {
                "lat": 5.98,
                "lng": 121.25
            },
            "Bignay": {
                "lat": 14.7456,
                "lng": 120.9961
            },
            "Dumarao": {
                "lat": 11.2631,
                "lng": 122.6878
            },
            "Noveleta": {
                "lat": 14.43,
                "lng": 120.88
            },
            "Kiblawan": {
                "lat": 6.62,
                "lng": 125.22
            },
            "Odiongan": {
                "lat": 12.4,
                "lng": 122.0
            },
            "Malabang": {
                "lat": 7.5903,
                "lng": 124.0703
            },
            "Pio Duran": {
                "lat": 13.03,
                "lng": 123.45
            },
            "Vinzons": {
                "lat": 14.1739,
                "lng": 122.9081
            },
            "Dulag": {
                "lat": 10.9525,
                "lng": 125.0317
            },
            "Libona": {
                "lat": 8.3333,
                "lng": 124.7433
            },
            "Alcala": {
                "lat": 17.9031,
                "lng": 121.6556
            },
            "Panay": {
                "lat": 11.5553,
                "lng": 122.7931
            },
            "Dumingag": {
                "lat": 8.155,
                "lng": 123.345
            },
            "Lal-lo": {
                "lat": 18.2,
                "lng": 121.6622
            },
            "Barotac Viejo": {
                "lat": 11.05,
                "lng": 122.85
            },
            "Inabanga": {
                "lat": 10.03,
                "lng": 124.07
            },
            "Siocon": {
                "lat": 7.7067,
                "lng": 122.1361
            },
            "Minalin": {
                "lat": 14.97,
                "lng": 120.68
            },
            "Tuba": {
                "lat": 16.3928,
                "lng": 120.5603
            },
            "Mahayag": {
                "lat": 8.1297,
                "lng": 123.4383
            },
            "Santa Rita": {
                "lat": 11.4522,
                "lng": 124.9408
            },
            "Bacolor": {
                "lat": 14.9984,
                "lng": 120.6526
            },
            "Balimbing": {
                "lat": 7.9,
                "lng": 123.85
            },
            "New Washington": {
                "lat": 11.6483,
                "lng": 122.4339
            },
            "Tibigan": {
                "lat": 9.95,
                "lng": 123.97
            },
            "Mamburao": {
                "lat": 13.2233,
                "lng": 120.596
            },
            "Sogod": {
                "lat": 10.75,
                "lng": 124.0
            },
            "Bunawan": {
                "lat": 8.18,
                "lng": 125.99
            },
            "Malinao": {
                "lat": 11.6431,
                "lng": 122.3072
            },
            "Sumisip": {
                "lat": 6.42,
                "lng": 121.98
            },
            "Albuera": {
                "lat": 10.9186,
                "lng": 124.6923
            },
            "Ayungon": {
                "lat": 9.8584,
                "lng": 123.1468
            },
            "Banisilan": {
                "lat": 7.5,
                "lng": 124.7
            },
            "Kabasalan": {
                "lat": 7.7968,
                "lng": 122.7627
            },
            "Isabel": {
                "lat": 10.93,
                "lng": 124.43
            },
            "Norala": {
                "lat": 6.55,
                "lng": 124.67
            },
            "Montevista": {
                "lat": 7.7053,
                "lng": 125.9903
            },
            "Tuy": {
                "lat": 14.02,
                "lng": 120.73
            },
            "Lower Tungawan": {
                "lat": 7.6047,
                "lng": 122.4228
            },
            "Cordon": {
                "lat": 16.67,
                "lng": 121.45
            },
            "Pagalungan": {
                "lat": 7.0592,
                "lng": 124.6987
            },
            "Narvacan": {
                "lat": 17.4192,
                "lng": 120.4756
            },
            "Dingle": {
                "lat": 11.05,
                "lng": 122.67
            },
            "Lupao": {
                "lat": 15.8794,
                "lng": 120.8994
            },
            "Lumba-a-Bayabao": {
                "lat": 7.88,
                "lng": 124.38
            },
            "Tantangan": {
                "lat": 6.62,
                "lng": 124.75
            },
            "Maluso": {
                "lat": 6.55,
                "lng": 121.88
            },
            "Jones": {
                "lat": 16.5583,
                "lng": 121.7
            },
            "Talagutong": {
                "lat": 6.27,
                "lng": 125.67
            },
            "Palayan City": {
                "lat": 15.5422,
                "lng": 121.0844
            },
            "Tayug": {
                "lat": 16.0267,
                "lng": 120.7478
            },
            "Basud": {
                "lat": 14.07,
                "lng": 122.97
            },
            "Aguilar": {
                "lat": 15.8899,
                "lng": 120.2379
            },
            "Angadanan": {
                "lat": 16.7571,
                "lng": 121.7479
            },
            "Maria Aurora": {
                "lat": 15.7967,
                "lng": 121.4737
            },
            "Buguias": {
                "lat": 16.8033,
                "lng": 120.8211
            },
            "Nasipit": {
                "lat": 8.99,
                "lng": 125.34
            },
            "Manjuyod": {
                "lat": 9.68,
                "lng": 123.15
            },
            "Katipunan": {
                "lat": 8.5134,
                "lng": 123.2847
            },
            "Dolores": {
                "lat": 17.65,
                "lng": 120.71
            },
            "Upper Bicutan": {
                "lat": 14.4873,
                "lng": 121.0503
            },
            "Culasi": {
                "lat": 11.4272,
                "lng": 122.056
            },
            "Banaybanay": {
                "lat": 6.9664,
                "lng": 126.0114
            },
            "Bacnotan": {
                "lat": 16.7197,
                "lng": 120.3481
            },
            "Bulalacao": {
                "lat": 12.325,
                "lng": 121.343
            },
            "San Jacinto": {
                "lat": 12.5683,
                "lng": 123.7336
            },
            "Pagsanjan": {
                "lat": 14.27,
                "lng": 121.45
            },
            "General Mamerto Natividad": {
                "lat": 15.603,
                "lng": 121.051
            },
            "Labangan": {
                "lat": 7.8667,
                "lng": 123.5167
            },
            "Loon": {
                "lat": 9.8,
                "lng": 123.8
            },
            "Cateel": {
                "lat": 7.79,
                "lng": 126.4531
            },
            "Maitum": {
                "lat": 6.03,
                "lng": 124.48
            },
            "Central Signal Village": {
                "lat": 14.5106,
                "lng": 121.0576
            },
            "Mandaon": {
                "lat": 12.2259,
                "lng": 123.2842
            },
            "Guinayangan": {
                "lat": 13.9,
                "lng": 122.45
            },
            "Labason": {
                "lat": 8.0647,
                "lng": 122.52
            },
            "Loreto": {
                "lat": 10.3586,
                "lng": 125.5816
            },
            "Hinatuan": {
                "lat": 8.3661,
                "lng": 126.3361
            },
            "Baler": {
                "lat": 15.7583,
                "lng": 121.5625
            },
            "Jaro": {
                "lat": 11.1894,
                "lng": 124.7853
            },
            "Badian": {
                "lat": 9.8694,
                "lng": 123.3959
            },
            "Kalilangan": {
                "lat": 7.7464,
                "lng": 124.7475
            },
            "Santa Elena": {
                "lat": 14.1797,
                "lng": 122.3919
            },
            "Roseller Lim": {
                "lat": 7.6583,
                "lng": 122.4639
            },
            "Guambog": {
                "lat": 7.3092,
                "lng": 125.8556
            },
            "Moises Padilla": {
                "lat": 10.27,
                "lng": 123.08
            },
            "Toboso": {
                "lat": 10.72,
                "lng": 123.52
            },
            "Laurel": {
                "lat": 14.05,
                "lng": 120.9
            },
            "South Upi": {
                "lat": 6.8548,
                "lng": 124.1443
            },
            "Abucay": {
                "lat": 14.7222,
                "lng": 120.5354
            },
            "Villaba": {
                "lat": 11.22,
                "lng": 124.4
            },
            "Nueva Valencia": {
                "lat": 10.5259,
                "lng": 122.5398
            },
            "Proper Bansud": {
                "lat": 12.8594,
                "lng": 121.4567
            },
            "Panitan": {
                "lat": 11.4639,
                "lng": 122.7694
            },
            "Balabac": {
                "lat": 7.98,
                "lng": 117.05
            },
            "Tucuran": {
                "lat": 7.8547,
                "lng": 123.5783
            },
            "Carranglan": {
                "lat": 15.9608,
                "lng": 121.0631
            },
            "Llanera": {
                "lat": 15.6625,
                "lng": 121.0197
            },
            "Polanco": {
                "lat": 8.5319,
                "lng": 123.3631
            },
            "President Quirino": {
                "lat": 6.7,
                "lng": 124.73
            },
            "Liloy": {
                "lat": 8.1228,
                "lng": 122.6736
            },
            "Aritao": {
                "lat": 16.2973,
                "lng": 121.0338
            },
            "Madridejos": {
                "lat": 11.27,
                "lng": 123.73
            },
            "Matnog": {
                "lat": 12.5856,
                "lng": 124.0856
            },
            "Puerto Galera": {
                "lat": 13.5,
                "lng": 120.9542
            },
            "Amadeo": {
                "lat": 14.1728,
                "lng": 120.9277
            },
            "Kibawe": {
                "lat": 7.5678,
                "lng": 124.9903
            },
            "Kasibu": {
                "lat": 16.3181,
                "lng": 121.2958
            },
            "Gonzaga": {
                "lat": 18.2614,
                "lng": 121.9969
            },
            "Daram": {
                "lat": 11.6341,
                "lng": 124.7947
            },
            "Socorro": {
                "lat": 9.6181,
                "lng": 125.9661
            },
            "Anda": {
                "lat": 9.744,
                "lng": 124.576
            },
            "Tagudin": {
                "lat": 16.9361,
                "lng": 120.4464
            },
            "Tabogon": {
                "lat": 10.93,
                "lng": 124.03
            },
            "Mondragon": {
                "lat": 12.52,
                "lng": 124.75
            },
            "Maayon": {
                "lat": 11.3903,
                "lng": 122.7828
            },
            "Lasam": {
                "lat": 18.07,
                "lng": 121.6
            },
            "Bacong": {
                "lat": 9.2464,
                "lng": 123.2948
            },
            "San Jose de Urquico": {
                "lat": 15.4781,
                "lng": 120.4639
            },
            "Mambajao": {
                "lat": 9.25,
                "lng": 124.72
            },
            "La Libertad": {
                "lat": 10.03,
                "lng": 123.22
            },
            "Tagbina": {
                "lat": 8.4578,
                "lng": 126.1578
            },
            "Tampakan": {
                "lat": 6.45,
                "lng": 124.93
            },
            "Marikina Heights": {
                "lat": 14.6534,
                "lng": 121.1187
            },
            "Maddela": {
                "lat": 16.3411,
                "lng": 121.6833
            },
            "Malilipot": {
                "lat": 13.32,
                "lng": 123.73
            },
            "Mobo": {
                "lat": 12.3372,
                "lng": 123.6581
            },
            "Vallehermoso": {
                "lat": 10.33,
                "lng": 123.32
            },
            "Lobo": {
                "lat": 13.65,
                "lng": 121.25
            },
            "Mambusao": {
                "lat": 11.43,
                "lng": 122.5953
            },
            "Maragondon": {
                "lat": 14.27,
                "lng": 120.73
            },
            "Nabas": {
                "lat": 11.8278,
                "lng": 122.0939
            },
            "Siay": {
                "lat": 7.7056,
                "lng": 122.8641
            },
            "Romblon": {
                "lat": 12.55,
                "lng": 122.28
            },
            "Villanueva": {
                "lat": 8.58,
                "lng": 124.78
            },
            "Leyte": {
                "lat": 11.37,
                "lng": 124.48
            },
            "Balaoan": {
                "lat": 16.82,
                "lng": 120.4
            },
            "Ba\u00f1ga": {
                "lat": 11.6389,
                "lng": 122.3331
            },
            "Payabon": {
                "lat": 9.77,
                "lng": 123.13
            },
            "Malalag": {
                "lat": 6.6,
                "lng": 125.4
            },
            "Balud": {
                "lat": 12.0369,
                "lng": 123.1935
            },
            "Taysan": {
                "lat": 13.78,
                "lng": 121.2
            },
            "Dingras": {
                "lat": 18.1036,
                "lng": 120.6975
            },
            "Valladolid": {
                "lat": 10.47,
                "lng": 122.83
            },
            "Panglao": {
                "lat": 9.579,
                "lng": 123.745
            },
            "Tago": {
                "lat": 9.0192,
                "lng": 126.2333
            },
            "Baco": {
                "lat": 13.3584,
                "lng": 121.0977
            },
            "Pamplona": {
                "lat": 18.4661,
                "lng": 121.3425
            },
            "Santo Ni\u00f1o": {
                "lat": 11.9263,
                "lng": 124.4492
            },
            "Palauig": {
                "lat": 15.4336,
                "lng": 119.9083
            },
            "San Nicolas": {
                "lat": 13.9283,
                "lng": 120.951
            },
            "Alimodian": {
                "lat": 10.8196,
                "lng": 122.4322
            },
            "Veruela": {
                "lat": 8.07,
                "lng": 125.96
            },
            "Caraga": {
                "lat": 7.3283,
                "lng": 126.5658
            },
            "Buldon": {
                "lat": 7.52,
                "lng": 124.37
            },
            "Talacogon": {
                "lat": 8.45,
                "lng": 125.79
            },
            "Manay": {
                "lat": 7.2108,
                "lng": 126.5328
            },
            "Jordan": {
                "lat": 10.6,
                "lng": 122.6
            },
            "Kalingalan Caluang": {
                "lat": 5.88,
                "lng": 121.27
            },
            "Lilio": {
                "lat": 14.13,
                "lng": 121.436
            },
            "Siniloan": {
                "lat": 14.42,
                "lng": 121.45
            },
            "Clarin": {
                "lat": 9.97,
                "lng": 124.02
            },
            "Damulog": {
                "lat": 7.4853,
                "lng": 124.9411
            },
            "Bayanan": {
                "lat": 14.4078,
                "lng": 121.05
            },
            "Agoncillo": {
                "lat": 13.9334,
                "lng": 120.9285
            },
            "Sual": {
                "lat": 16.0661,
                "lng": 120.0958
            },
            "Gabaldon": {
                "lat": 15.4506,
                "lng": 121.3378
            },
            "Caluya": {
                "lat": 11.932,
                "lng": 121.548
            },
            "Cabugao": {
                "lat": 17.7947,
                "lng": 120.4556
            },
            "Aborlan": {
                "lat": 9.4386,
                "lng": 118.5481
            },
            "Real": {
                "lat": 14.67,
                "lng": 121.6
            },
            "Jamindan": {
                "lat": 11.4094,
                "lng": 122.5103
            },
            "Margosatubig": {
                "lat": 7.5783,
                "lng": 123.1659
            },
            "Olutanga": {
                "lat": 7.3106,
                "lng": 122.8464
            },
            "Buug": {
                "lat": 7.7286,
                "lng": 123.0608
            },
            "Patnongon": {
                "lat": 10.9142,
                "lng": 121.9947
            },
            "Laur": {
                "lat": 15.5797,
                "lng": 121.1864
            },
            "Borbon": {
                "lat": 10.83,
                "lng": 124.0
            },
            "Tayasan": {
                "lat": 9.92,
                "lng": 123.15
            },
            "Mapandan": {
                "lat": 16.026,
                "lng": 120.454
            },
            "Bangar": {
                "lat": 16.9,
                "lng": 120.42
            },
            "Bagabag": {
                "lat": 16.6044,
                "lng": 121.2521
            },
            "Luuk": {
                "lat": 5.9676,
                "lng": 121.3133
            },
            "Marantao": {
                "lat": 7.95,
                "lng": 124.233
            },
            "Karuhatan": {
                "lat": 14.6883,
                "lng": 120.9761
            },
            "San Marcelino": {
                "lat": 14.9742,
                "lng": 120.1573
            },
            "Basista": {
                "lat": 15.8524,
                "lng": 120.3976
            },
            "Tambulig": {
                "lat": 8.07,
                "lng": 123.5372
            },
            "Oras": {
                "lat": 12.1414,
                "lng": 125.4392
            },
            "Magallanes": {
                "lat": 9.02,
                "lng": 125.52
            },
            "Mabuhay": {
                "lat": 7.4176,
                "lng": 122.837
            },
            "Sitangkai": {
                "lat": 4.6615,
                "lng": 119.3919
            },
            "Luna": {
                "lat": 16.97,
                "lng": 121.73
            },
            "Mankayan": {
                "lat": 16.8567,
                "lng": 120.7933
            },
            "Bantay": {
                "lat": 17.5839,
                "lng": 120.3908
            },
            "Imbatug": {
                "lat": 8.3128,
                "lng": 124.6873
            },
            "Languyan": {
                "lat": 5.2667,
                "lng": 120.0833
            },
            "Moalboal": {
                "lat": 9.95,
                "lng": 123.4
            },
            "Basilisa": {
                "lat": 10.0654,
                "lng": 125.5968
            },
            "San Enrique": {
                "lat": 10.42,
                "lng": 122.85
            },
            "Manucan": {
                "lat": 8.5161,
                "lng": 123.0917
            },
            "Sagnay": {
                "lat": 13.6039,
                "lng": 123.5233
            },
            "Enrile": {
                "lat": 17.5622,
                "lng": 121.7011
            },
            "Las Navas": {
                "lat": 12.34,
                "lng": 125.032
            },
            "Simbahan": {
                "lat": 6.3,
                "lng": 120.58
            },
            "Dinas": {
                "lat": 7.6161,
                "lng": 123.3378
            },
            "Cabanglasan": {
                "lat": 8.0772,
                "lng": 125.2992
            },
            "Siayan": {
                "lat": 8.2517,
                "lng": 123.1144
            },
            "Cuenca": {
                "lat": 13.92,
                "lng": 121.05
            },
            "Capalonga": {
                "lat": 14.3314,
                "lng": 122.4928
            },
            "Canaman": {
                "lat": 13.6481,
                "lng": 123.1711
            },
            "Gasan": {
                "lat": 13.32,
                "lng": 121.85
            },
            "Dagami": {
                "lat": 11.0611,
                "lng": 124.9031
            },
            "Rapu-Rapu": {
                "lat": 13.18,
                "lng": 124.13
            },
            "Barira": {
                "lat": 7.4706,
                "lng": 124.3561
            },
            "Gutalac": {
                "lat": 7.98,
                "lng": 122.4
            },
            "Sibuco": {
                "lat": 7.2928,
                "lng": 122.0669
            },
            "Matanog": {
                "lat": 7.47,
                "lng": 124.25
            },
            "Bel-Air": {
                "lat": 14.5639,
                "lng": 121.0263
            },
            "Pandan": {
                "lat": 14.05,
                "lng": 124.17
            },
            "Jagna": {
                "lat": 9.65,
                "lng": 124.37
            },
            "Numancia": {
                "lat": 11.7042,
                "lng": 122.3289
            },
            "Medina": {
                "lat": 8.92,
                "lng": 125.02
            },
            "Casiguran": {
                "lat": 16.28,
                "lng": 122.12
            },
            "Pambujan": {
                "lat": 12.57,
                "lng": 124.93
            },
            "Pola": {
                "lat": 13.1439,
                "lng": 121.44
            },
            "Bautista": {
                "lat": 15.8103,
                "lng": 120.4756
            },
            "Juban": {
                "lat": 12.8478,
                "lng": 123.9894
            },
            "Pansol": {
                "lat": 14.6514,
                "lng": 121.08
            },
            "Gandara": {
                "lat": 12.013,
                "lng": 124.8118
            },
            "Allacapan": {
                "lat": 18.227,
                "lng": 121.5556
            },
            "Abra de Ilog": {
                "lat": 13.4448,
                "lng": 120.726
            },
            "Sulop": {
                "lat": 6.5986,
                "lng": 125.3436
            },
            "Lingig": {
                "lat": 8.0381,
                "lng": 126.4127
            },
            "Trinidad": {
                "lat": 10.0795,
                "lng": 124.3432
            },
            "Balasan": {
                "lat": 11.4728,
                "lng": 123.0878
            },
            "Guimbal": {
                "lat": 10.67,
                "lng": 122.32
            },
            "Munai": {
                "lat": 7.9758,
                "lng": 124.0636
            },
            "Payao": {
                "lat": 7.5857,
                "lng": 122.8022
            },
            "Mendez-Nu\u00f1ez": {
                "lat": 14.1286,
                "lng": 120.9058
            },
            "Leganes": {
                "lat": 10.78,
                "lng": 122.58
            },
            "Bugasong": {
                "lat": 11.0447,
                "lng": 122.0647
            },
            "Due\u00f1as": {
                "lat": 11.07,
                "lng": 122.62
            },
            "Abulug": {
                "lat": 18.4441,
                "lng": 121.4576
            },
            "Bonifacio": {
                "lat": 8.0527,
                "lng": 123.6136
            },
            "Bayog": {
                "lat": 7.8474,
                "lng": 123.0423
            },
            "Mogpog": {
                "lat": 13.48,
                "lng": 121.87
            },
            "Barugo": {
                "lat": 11.32,
                "lng": 124.73
            },
            "Ballesteros": {
                "lat": 18.4108,
                "lng": 121.5169
            },
            "Santa Fe": {
                "lat": 12.15,
                "lng": 122.0
            },
            "Aloguinsan": {
                "lat": 10.2229,
                "lng": 123.5491
            },
            "Tandubas": {
                "lat": 5.134,
                "lng": 120.3461
            },
            "Pinukpuk": {
                "lat": 17.5731,
                "lng": 121.3653
            },
            "New Panamao": {
                "lat": 5.97,
                "lng": 121.2
            },
            "Simunul": {
                "lat": 4.898,
                "lng": 119.8213
            },
            "Sibutu": {
                "lat": 4.85,
                "lng": 119.47
            },
            "General Nakar": {
                "lat": 14.7631,
                "lng": 121.635
            },
            "Talayan": {
                "lat": 6.9844,
                "lng": 124.3564
            },
            "Laoac East": {
                "lat": 16.0333,
                "lng": 120.55
            },
            "Guindulman": {
                "lat": 9.762,
                "lng": 124.488
            },
            "Cantilan": {
                "lat": 9.3356,
                "lng": 125.9769
            },
            "Palapag": {
                "lat": 12.547,
                "lng": 125.116
            },
            "Jalajala": {
                "lat": 14.354,
                "lng": 121.324
            },
            "Shariff Aguak": {
                "lat": 6.8647,
                "lng": 124.4417
            },
            "San Quintin": {
                "lat": 15.9844,
                "lng": 120.815
            },
            "Milaor": {
                "lat": 13.5956,
                "lng": 123.1781
            },
            "Sibagat": {
                "lat": 8.82,
                "lng": 125.69
            },
            "Initao": {
                "lat": 8.5,
                "lng": 124.3167
            },
            "Lupi Viejo": {
                "lat": 13.7908,
                "lng": 122.9092
            },
            "Lianga": {
                "lat": 8.633,
                "lng": 126.0932
            },
            "Tabango": {
                "lat": 11.3067,
                "lng": 124.3714
            },
            "Baclaran": {
                "lat": 14.5319,
                "lng": 120.9942
            },
            "Dao": {
                "lat": 11.38,
                "lng": 122.63
            },
            "Tobias Fornier": {
                "lat": 10.5178,
                "lng": 121.945
            },
            "Catmon": {
                "lat": 10.67,
                "lng": 123.95
            },
            "Kadingilan": {
                "lat": 7.6003,
                "lng": 124.9097
            },
            "Capoocan": {
                "lat": 11.2944,
                "lng": 124.6419
            },
            "Midsalip": {
                "lat": 8.0328,
                "lng": 123.3147
            },
            "Datu Paglas": {
                "lat": 6.7669,
                "lng": 124.85
            },
            "Batobato": {
                "lat": 6.8361,
                "lng": 126.0889
            },
            "Masiu": {
                "lat": 7.82,
                "lng": 124.32
            },
            "Sapa Sapa": {
                "lat": 5.0899,
                "lng": 120.2729
            },
            "Cabarroguis": {
                "lat": 16.5103,
                "lng": 121.5222
            },
            "Columbio": {
                "lat": 6.7,
                "lng": 124.93
            },
            "San Vicente": {
                "lat": 14.1061,
                "lng": 122.8733
            },
            "Bacarra": {
                "lat": 18.2519,
                "lng": 120.6107
            },
            "Batan": {
                "lat": 11.5853,
                "lng": 122.4981
            },
            "Getafe": {
                "lat": 10.15,
                "lng": 124.15
            },
            "Banate": {
                "lat": 11.05,
                "lng": 122.78
            },
            "Vintar": {
                "lat": 18.2306,
                "lng": 120.6489
            },
            "Dupax Del Norte": {
                "lat": 16.3075,
                "lng": 121.1019
            },
            "Pandami": {
                "lat": 5.53,
                "lng": 120.75
            },
            "Dipaculao": {
                "lat": 15.98,
                "lng": 121.63
            },
            "Calape": {
                "lat": 9.88,
                "lng": 123.88
            },
            "Dumalinao": {
                "lat": 7.82,
                "lng": 123.37
            },
            "Mayantoc": {
                "lat": 15.6203,
                "lng": 120.3775
            },
            "Matalom": {
                "lat": 10.28,
                "lng": 124.8
            },
            "Diplahan": {
                "lat": 7.6911,
                "lng": 122.9853
            },
            "Balindong": {
                "lat": 7.92,
                "lng": 124.2
            },
            "Badoc": {
                "lat": 17.9267,
                "lng": 120.4754
            },
            "Lumbang": {
                "lat": 14.297,
                "lng": 121.459
            },
            "Pe\u00f1aranda": {
                "lat": 15.3531,
                "lng": 121.0017
            },
            "Jimalalud": {
                "lat": 9.9797,
                "lng": 123.1999
            },
            "Mallig": {
                "lat": 17.2086,
                "lng": 121.6106
            },
            "Igbaras": {
                "lat": 10.72,
                "lng": 122.27
            },
            "Catubig": {
                "lat": 12.4,
                "lng": 125.05
            },
            "Alubijid": {
                "lat": 8.5714,
                "lng": 124.4751
            },
            "Buguey": {
                "lat": 18.2882,
                "lng": 121.8331
            },
            "Salug": {
                "lat": 8.1075,
                "lng": 122.7575
            },
            "Caramoran": {
                "lat": 13.98,
                "lng": 124.13
            },
            "Tamparan": {
                "lat": 7.879,
                "lng": 124.333
            },
            "Malangas": {
                "lat": 7.6317,
                "lng": 123.0322
            },
            "Bauko": {
                "lat": 16.9917,
                "lng": 120.8644
            },
            "Sergio Osme\u00f1a Sr": {
                "lat": 8.3003,
                "lng": 123.5083
            },
            "Polillo": {
                "lat": 14.72,
                "lng": 121.95
            },
            "Pantabangan": {
                "lat": 15.8086,
                "lng": 121.1433
            },
            "Tubao": {
                "lat": 16.35,
                "lng": 120.42
            },
            "Sigma": {
                "lat": 11.4214,
                "lng": 122.6662
            },
            "Calubian": {
                "lat": 11.4467,
                "lng": 124.4278
            },
            "Merida": {
                "lat": 10.9098,
                "lng": 124.5376
            },
            "Bagac": {
                "lat": 14.5951,
                "lng": 120.3918
            },
            "Dasol": {
                "lat": 15.9896,
                "lng": 119.8805
            },
            "Dimataling": {
                "lat": 7.5297,
                "lng": 123.3661
            },
            "Magsingal": {
                "lat": 17.685,
                "lng": 120.4244
            },
            "Ivisan": {
                "lat": 11.5217,
                "lng": 122.6908
            },
            "Paracelis": {
                "lat": 17.1811,
                "lng": 121.4036
            },
            "Siraway": {
                "lat": 7.5853,
                "lng": 122.1406
            },
            "Gata": {
                "lat": 7.85,
                "lng": 124.35
            },
            "Tairan Camp": {
                "lat": 6.65,
                "lng": 121.87
            },
            "Ganassi": {
                "lat": 7.8269,
                "lng": 124.1034
            },
            "Aglipay": {
                "lat": 16.4889,
                "lng": 121.5874
            },
            "Balatan": {
                "lat": 13.32,
                "lng": 123.23
            },
            "Gamu": {
                "lat": 17.05,
                "lng": 121.83
            },
            "Mataas Na Kahoy": {
                "lat": 13.97,
                "lng": 121.08
            },
            "Anilao": {
                "lat": 10.9785,
                "lng": 122.7531
            },
            "Torrijos": {
                "lat": 13.32,
                "lng": 122.08
            },
            "Maguing": {
                "lat": 7.9,
                "lng": 124.4
            },
            "Pantao-Ragat": {
                "lat": 8.05,
                "lng": 124.15
            },
            "Las Nieves": {
                "lat": 8.74,
                "lng": 125.6
            },
            "Calintaan": {
                "lat": 12.5756,
                "lng": 120.9428
            },
            "Piagapo": {
                "lat": 8.0,
                "lng": 124.2
            },
            "Candijay": {
                "lat": 9.818,
                "lng": 124.496
            },
            "Marogong": {
                "lat": 7.67,
                "lng": 124.15
            },
            "Pulupandan": {
                "lat": 10.52,
                "lng": 122.8
            },
            "Dumalag": {
                "lat": 11.3039,
                "lng": 122.6214
            },
            "Balete": {
                "lat": 14.02,
                "lng": 121.1
            },
            "Iguig": {
                "lat": 17.7517,
                "lng": 121.7364
            },
            "Dauin": {
                "lat": 9.2,
                "lng": 123.27
            },
            "Balungao": {
                "lat": 15.9,
                "lng": 120.7
            },
            "Salay": {
                "lat": 8.87,
                "lng": 124.8
            },
            "Agno": {
                "lat": 16.1161,
                "lng": 119.8027
            },
            "Ragan Sur": {
                "lat": 17.32,
                "lng": 121.78
            },
            "San Roque": {
                "lat": 14.48,
                "lng": 120.9011
            },
            "Padada": {
                "lat": 6.63,
                "lng": 125.35
            },
            "Balabagan": {
                "lat": 7.53,
                "lng": 124.12
            },
            "Quiapo": {
                "lat": 14.6,
                "lng": 120.983
            },
            "Bontoc": {
                "lat": 17.09,
                "lng": 120.9772
            },
            "Benito Soliven": {
                "lat": 16.98,
                "lng": 121.95
            },
            "Makato": {
                "lat": 11.712,
                "lng": 122.2922
            },
            "Pasuquin": {
                "lat": 18.3342,
                "lng": 120.6183
            },
            "West Rembo": {
                "lat": 14.5667,
                "lng": 121.0667
            },
            "South Ubian": {
                "lat": 5.18,
                "lng": 120.48
            },
            "Zamboanguita": {
                "lat": 9.1,
                "lng": 123.199
            },
            "Sumilao": {
                "lat": 8.3281,
                "lng": 124.9783
            },
            "Kumalarang": {
                "lat": 7.7478,
                "lng": 123.1442
            },
            "Manticao": {
                "lat": 8.4042,
                "lng": 124.2867
            },
            "Garchitorena": {
                "lat": 13.88,
                "lng": 123.7
            },
            "Lavezares": {
                "lat": 12.53,
                "lng": 124.33
            },
            "Bagong Pag-Asa": {
                "lat": 14.6622,
                "lng": 121.0331
            },
            "Licab": {
                "lat": 15.54,
                "lng": 120.7619
            },
            "Oslob": {
                "lat": 9.55,
                "lng": 123.4
            },
            "Poona-Piagapo": {
                "lat": 8.08,
                "lng": 124.08
            },
            "Hinunangan": {
                "lat": 10.4,
                "lng": 125.2
            },
            "Sexmoan": {
                "lat": 14.936,
                "lng": 120.623
            },
            "Lugus": {
                "lat": 5.7,
                "lng": 120.82
            },
            "Dapa": {
                "lat": 9.7578,
                "lng": 126.0528
            },
            "Ampatuan": {
                "lat": 6.8348,
                "lng": 124.4581
            },
            "Siquijor": {
                "lat": 9.18,
                "lng": 123.58
            },
            "Jimenez": {
                "lat": 8.3333,
                "lng": 123.8333
            },
            "Tabuelan": {
                "lat": 10.85,
                "lng": 123.9
            },
            "Ponot": {
                "lat": 8.4428,
                "lng": 123.0219
            },
            "Baleno": {
                "lat": 12.4739,
                "lng": 123.4982
            },
            "Babatngon": {
                "lat": 11.4207,
                "lng": 124.8434
            },
            "Tibiao": {
                "lat": 11.2892,
                "lng": 122.0342
            },
            "Do\u00f1a Remedios Trinidad": {
                "lat": 15.0,
                "lng": 121.08
            },
            "Tudela": {
                "lat": 10.636,
                "lng": 124.47
            },
            "Basay": {
                "lat": 9.42,
                "lng": 122.63
            },
            "Saint Bernard": {
                "lat": 10.28,
                "lng": 125.13
            },
            "Datu Piang": {
                "lat": 7.0178,
                "lng": 124.4974
            },
            "Libacao": {
                "lat": 11.48,
                "lng": 122.3
            },
            "Kolambugan": {
                "lat": 8.1144,
                "lng": 123.8971
            },
            "Pinyahan": {
                "lat": 14.64,
                "lng": 121.0461
            },
            "Cabangan": {
                "lat": 15.13,
                "lng": 120.15
            },
            "Bayang": {
                "lat": 7.793,
                "lng": 124.192
            },
            "Mainit": {
                "lat": 9.5369,
                "lng": 125.5231
            },
            "Cuartero": {
                "lat": 11.3428,
                "lng": 122.6714
            },
            "Aloran": {
                "lat": 8.4146,
                "lng": 123.8228
            },
            "Reina Mercedes Viejo": {
                "lat": 16.9872,
                "lng": 121.8272
            },
            "Reina Mercedes": {
                "lat": 16.9872,
                "lng": 121.8272
            },
            "Majayjay": {
                "lat": 14.1463,
                "lng": 121.4729
            },
            "Caridad": {
                "lat": 14.4828,
                "lng": 120.8958
            },
            "Dingalan": {
                "lat": 15.38,
                "lng": 121.4
            },
            "Talusan": {
                "lat": 7.4263,
                "lng": 122.8084
            },
            "Mahaplag": {
                "lat": 10.6072,
                "lng": 124.9636
            },
            "Magdalena": {
                "lat": 14.2,
                "lng": 121.43
            },
            "Lapuyan": {
                "lat": 7.6325,
                "lng": 123.1914
            },
            "Leon Postigo": {
                "lat": 8.1514,
                "lng": 122.9244
            },
            "Conner": {
                "lat": 17.8086,
                "lng": 121.2889
            },
            "Pitogo": {
                "lat": 13.785,
                "lng": 122.0881
            },
            "Villareal": {
                "lat": 11.57,
                "lng": 124.93
            },
            "Palanas": {
                "lat": 12.1464,
                "lng": 123.9219
            },
            "Macalelon": {
                "lat": 13.75,
                "lng": 122.13
            },
            "Zarraga": {
                "lat": 10.82,
                "lng": 122.6
            },
            "Ramon Magsaysay": {
                "lat": 8.0053,
                "lng": 123.4869
            },
            "Taraka": {
                "lat": 7.8994,
                "lng": 124.3481
            },
            "Badiangan": {
                "lat": 10.986,
                "lng": 122.5369
            },
            "Tarragona": {
                "lat": 7.0491,
                "lng": 126.4471
            },
            "Tambo": {
                "lat": 14.5164,
                "lng": 120.9889
            },
            "Alitagtag": {
                "lat": 13.865,
                "lng": 121.0063
            },
            "Santa Margarita": {
                "lat": 12.0378,
                "lng": 124.6578
            },
            "Labrador": {
                "lat": 16.0339,
                "lng": 120.1392
            },
            "Magarao": {
                "lat": 13.6619,
                "lng": 123.1903
            },
            "Malitbog": {
                "lat": 8.5361,
                "lng": 124.8808
            },
            "Saguiaran": {
                "lat": 8.03,
                "lng": 124.27
            },
            "Sapian": {
                "lat": 11.4939,
                "lng": 122.6047
            },
            "Bien Unido": {
                "lat": 10.13,
                "lng": 124.38
            },
            "Bugho": {
                "lat": 10.8,
                "lng": 124.93
            },
            "Pantar": {
                "lat": 8.0667,
                "lng": 124.2667
            },
            "Macrohon": {
                "lat": 10.0797,
                "lng": 124.9431
            },
            "Lauaan": {
                "lat": 11.1429,
                "lng": 122.0417
            },
            "Plainview": {
                "lat": 14.5777,
                "lng": 121.0337
            },
            "Bubong": {
                "lat": 8.02,
                "lng": 124.48
            },
            "Madalum": {
                "lat": 7.853,
                "lng": 124.119
            },
            "Kayapa": {
                "lat": 16.3583,
                "lng": 120.8861
            },
            "Santa Josefa": {
                "lat": 7.98,
                "lng": 126.03
            },
            "Del Gallego": {
                "lat": 13.9233,
                "lng": 122.5961
            },
            "Lagindingan": {
                "lat": 8.58,
                "lng": 124.45
            },
            "Pandag": {
                "lat": 6.7411,
                "lng": 124.7827
            },
            "Lamut": {
                "lat": 16.6517,
                "lng": 121.2178
            },
            "Poro": {
                "lat": 10.629,
                "lng": 124.407
            },
            "Sanchez-Mira": {
                "lat": 18.5611,
                "lng": 121.2344
            },
            "Manito": {
                "lat": 13.1235,
                "lng": 123.8693
            },
            "Sierra Bullones": {
                "lat": 9.82,
                "lng": 124.28
            },
            "Dancagan": {
                "lat": 7.6119,
                "lng": 125.0028
            },
            "Burgos": {
                "lat": 16.525,
                "lng": 120.4583
            },
            "Sinait": {
                "lat": 17.8667,
                "lng": 120.4583
            },
            "Santa Lucia": {
                "lat": 17.1222,
                "lng": 120.4494
            },
            "Binidayan": {
                "lat": 7.8,
                "lng": 124.17
            },
            "Bobon": {
                "lat": 12.52,
                "lng": 124.57
            },
            "Tubay": {
                "lat": 9.16,
                "lng": 125.52
            },
            "Santo Cristo": {
                "lat": 14.6603,
                "lng": 121.03
            },
            "Pura": {
                "lat": 15.6248,
                "lng": 120.648
            },
            "Natividad": {
                "lat": 16.0422,
                "lng": 120.7953
            },
            "Talisayan": {
                "lat": 8.9917,
                "lng": 124.8833
            },
            "Tabina": {
                "lat": 7.4655,
                "lng": 123.4086
            },
            "Tarangnan": {
                "lat": 11.9,
                "lng": 124.75
            },
            "Altavas": {
                "lat": 11.5383,
                "lng": 122.4869
            },
            "Alegria": {
                "lat": 9.4667,
                "lng": 125.5767
            },
            "Busuanga": {
                "lat": 12.1335,
                "lng": 119.9363
            },
            "Tipo-Tipo": {
                "lat": 6.53,
                "lng": 122.17
            },
            "Ayuquitan": {
                "lat": 9.4644,
                "lng": 123.2237
            },
            "Lopez Jaena": {
                "lat": 8.55,
                "lng": 123.7667
            },
            "Unisan": {
                "lat": 13.8413,
                "lng": 121.9752
            },
            "Tumbao": {
                "lat": 7.12,
                "lng": 124.38
            },
            "Nagtipunan": {
                "lat": 16.22,
                "lng": 121.6
            },
            "Talugtug": {
                "lat": 15.7789,
                "lng": 120.8111
            },
            "Camalaniugan": {
                "lat": 18.2756,
                "lng": 121.6756
            },
            "Allen": {
                "lat": 12.5013,
                "lng": 124.282
            },
            "Sarrat": {
                "lat": 18.1578,
                "lng": 120.6447
            },
            "Monreal": {
                "lat": 12.644,
                "lng": 123.6648
            },
            "Pagudpud": {
                "lat": 18.5614,
                "lng": 120.7878
            },
            "Soccorro": {
                "lat": 14.6179,
                "lng": 121.0558
            },
            "Camaligan": {
                "lat": 13.6208,
                "lng": 123.1656
            },
            "San Felipe": {
                "lat": 15.0619,
                "lng": 120.07
            },
            "Pangil": {
                "lat": 14.4,
                "lng": 121.47
            },
            "Quirino": {
                "lat": 17.1383,
                "lng": 120.6764
            },
            "Paoay": {
                "lat": 18.0625,
                "lng": 120.5203
            },
            "Paete": {
                "lat": 14.37,
                "lng": 121.48
            },
            "Dimasalang": {
                "lat": 12.1933,
                "lng": 123.8583
            },
            "Jabonga": {
                "lat": 9.3431,
                "lng": 125.5156
            },
            "Solsona": {
                "lat": 18.0961,
                "lng": 120.7725
            },
            "Piat": {
                "lat": 17.7919,
                "lng": 121.4769
            },
            "General Luna": {
                "lat": 9.7836,
                "lng": 126.1567
            },
            "Tugaya": {
                "lat": 7.884,
                "lng": 124.172
            },
            "Kalayaan": {
                "lat": 14.328,
                "lng": 121.48
            },
            "Patian": {
                "lat": 5.8444,
                "lng": 121.1439
            },
            "Baroy": {
                "lat": 8.03,
                "lng": 123.78
            },
            "Tamilisan": {
                "lat": 7.9761,
                "lng": 122.6639
            },
            "Ternate": {
                "lat": 14.28,
                "lng": 120.72
            },
            "Burdeos": {
                "lat": 14.8436,
                "lng": 121.9697
            },
            "Carrascal": {
                "lat": 9.3683,
                "lng": 125.9494
            },
            "Garcia Hernandez": {
                "lat": 9.6144,
                "lng": 124.2946
            },
            "Ramain": {
                "lat": 7.97,
                "lng": 124.35
            },
            "Sagbayan": {
                "lat": 9.92,
                "lng": 124.1
            },
            "Kauswagan": {
                "lat": 8.1917,
                "lng": 124.0847
            },
            "Lagonglong": {
                "lat": 8.8,
                "lng": 124.78
            },
            "Caibiran": {
                "lat": 11.57,
                "lng": 124.58
            },
            "San Agustin": {
                "lat": 16.52,
                "lng": 121.75
            },
            "Mina": {
                "lat": 10.93,
                "lng": 122.58
            },
            "Cavinti": {
                "lat": 14.245,
                "lng": 121.507
            },
            "General Emilio Aguinaldo": {
                "lat": 14.18,
                "lng": 120.8
            },
            "Bulusan": {
                "lat": 12.7522,
                "lng": 124.1356
            },
            "Kalawit": {
                "lat": 7.9051,
                "lng": 122.5279
            },
            "Catigbian": {
                "lat": 9.83,
                "lng": 124.03
            },
            "Baliguian": {
                "lat": 7.8088,
                "lng": 122.1452
            },
            "Candoni": {
                "lat": 9.82,
                "lng": 122.6
            },
            "Limbuhan": {
                "lat": 11.8836,
                "lng": 124.0497
            },
            "Tangalan": {
                "lat": 11.7775,
                "lng": 122.2603
            },
            "Dumaran": {
                "lat": 10.53,
                "lng": 119.77
            },
            "Pakil": {
                "lat": 14.38,
                "lng": 121.48
            },
            "Cuyo": {
                "lat": 10.85,
                "lng": 121.02
            },
            "Padre Burgos": {
                "lat": 10.03,
                "lng": 125.02
            },
            "Maharlika Village": {
                "lat": 14.4989,
                "lng": 121.0533
            },
            "Gamay": {
                "lat": 12.38,
                "lng": 125.3
            },
            "Barbasa": {
                "lat": 11.1961,
                "lng": 122.0389
            },
            "Maigo": {
                "lat": 8.15,
                "lng": 123.97
            },
            "Calbiga": {
                "lat": 11.63,
                "lng": 125.02
            },
            "Tugatog": {
                "lat": 14.6629,
                "lng": 120.969
            },
            "Cajidiocan": {
                "lat": 12.37,
                "lng": 122.68
            },
            "Maibog": {
                "lat": 10.15,
                "lng": 125.0
            },
            "Calamba": {
                "lat": 8.5583,
                "lng": 123.6417
            },
            "Culion": {
                "lat": 11.8944,
                "lng": 120.0186
            },
            "Caba": {
                "lat": 16.4316,
                "lng": 120.3446
            },
            "Tubungan": {
                "lat": 10.78,
                "lng": 122.3
            },
            "Ramos": {
                "lat": 15.6667,
                "lng": 120.6417
            },
            "Viga": {
                "lat": 13.87,
                "lng": 124.3
            },
            "Oteiza": {
                "lat": 8.7437,
                "lng": 126.2214
            },
            "Lumbatan": {
                "lat": 7.785,
                "lng": 124.256
            },
            "Butig": {
                "lat": 7.7239,
                "lng": 124.3011
            },
            "Prieto Diaz": {
                "lat": 13.0408,
                "lng": 124.1931
            },
            "Parabcan": {
                "lat": 13.71,
                "lng": 123.745
            },
            "Sarangani": {
                "lat": 5.412,
                "lng": 125.422
            },
            "Lazi": {
                "lat": 9.128,
                "lng": 123.634
            },
            "Piddig": {
                "lat": 18.1647,
                "lng": 120.7172
            },
            "Baclayon": {
                "lat": 9.6227,
                "lng": 123.9135
            },
            "Naawan": {
                "lat": 8.4333,
                "lng": 124.3
            },
            "Ugong": {
                "lat": 14.5842,
                "lng": 121.0729
            },
            "Maribojoc": {
                "lat": 9.75,
                "lng": 123.85
            },
            "Batad": {
                "lat": 11.42,
                "lng": 123.12
            },
            "Salcedo": {
                "lat": 17.1517,
                "lng": 120.5361
            },
            "Madamba": {
                "lat": 7.88,
                "lng": 124.07
            },
            "Anini-y": {
                "lat": 10.4325,
                "lng": 121.9253
            },
            "Gigaquit": {
                "lat": 9.5947,
                "lng": 125.6975
            },
            "Looc": {
                "lat": 12.2605,
                "lng": 121.9926
            },
            "Cagwait": {
                "lat": 8.9181,
                "lng": 126.3017
            },
            "Guipos": {
                "lat": 7.735,
                "lng": 123.3231
            },
            "Can-Avid": {
                "lat": 12.0,
                "lng": 125.45
            },
            "Tigbao": {
                "lat": 7.8205,
                "lng": 123.2277
            },
            "MacArthur": {
                "lat": 10.8356,
                "lng": 124.9964
            },
            "Rancheria Payau": {
                "lat": 7.8509,
                "lng": 123.1542
            },
            "Santa Teresita": {
                "lat": 18.2519,
                "lng": 121.91
            },
            "Cabucgayan": {
                "lat": 11.4719,
                "lng": 124.575
            },
            "Llorente": {
                "lat": 11.4125,
                "lng": 125.5458
            },
            "Inopacan": {
                "lat": 10.5,
                "lng": 124.75
            },
            "Krus na Ligas": {
                "lat": 14.6442,
                "lng": 121.0636
            },
            "Kitcharao": {
                "lat": 9.46,
                "lng": 125.58
            },
            "Mabitac": {
                "lat": 14.43,
                "lng": 121.42
            },
            "Linamon": {
                "lat": 8.18,
                "lng": 124.17
            },
            "Dupax del Sur": {
                "lat": 16.2842,
                "lng": 121.0917
            },
            "Loyola Heights": {
                "lat": 14.6403,
                "lng": 121.0744
            },
            "Aguinaldo": {
                "lat": 16.9789,
                "lng": 121.3272
            },
            "Ronda": {
                "lat": 10.0003,
                "lng": 123.4095
            },
            "Barcelona": {
                "lat": 12.8694,
                "lng": 124.1419
            },
            "Punturin": {
                "lat": 14.7381,
                "lng": 120.9978
            },
            "San Guillermo": {
                "lat": 16.7244,
                "lng": 121.81
            },
            "Luisiana": {
                "lat": 14.185,
                "lng": 121.5109
            },
            "Hindang": {
                "lat": 10.4339,
                "lng": 124.7278
            },
            "Banting": {
                "lat": 5.7167,
                "lng": 120.9
            },
            "Tolosa": {
                "lat": 11.0633,
                "lng": 125.0353
            },
            "Banaue": {
                "lat": 16.9119,
                "lng": 121.0614
            },
            "Kapay": {
                "lat": 8.08,
                "lng": 124.4
            },
            "Lugait": {
                "lat": 8.3411,
                "lng": 124.2592
            },
            "Guadalupe Nuevo": {
                "lat": 14.5587,
                "lng": 121.0446
            },
            "Binondo": {
                "lat": 14.6,
                "lng": 120.967
            },
            "Sapang Dalaga": {
                "lat": 8.55,
                "lng": 123.57
            },
            "Kawayan": {
                "lat": 11.7,
                "lng": 124.37
            },
            "Samboan": {
                "lat": 9.5288,
                "lng": 123.3064
            },
            "San Martin De Porres": {
                "lat": 14.4817,
                "lng": 121.0425
            },
            "Pi\u00f1an": {
                "lat": 8.4822,
                "lng": 123.45
            },
            "Del Carmen": {
                "lat": 9.869,
                "lng": 125.97
            },
            "Villa Verde": {
                "lat": 16.6067,
                "lng": 121.1828
            },
            "Valderrama": {
                "lat": 11.0036,
                "lng": 122.1294
            },
            "Mulundo": {
                "lat": 7.93,
                "lng": 124.38
            },
            "Dagohoy": {
                "lat": 9.92,
                "lng": 124.28
            },
            "Malabuyoc": {
                "lat": 9.65,
                "lng": 123.33
            },
            "Sinacaban": {
                "lat": 8.2854,
                "lng": 123.8436
            },
            "Caoayan": {
                "lat": 17.5469,
                "lng": 120.3831
            },
            "Cervantes": {
                "lat": 16.9917,
                "lng": 120.7333
            },
            "Marihatag": {
                "lat": 8.8008,
                "lng": 126.2983
            },
            "Tublay": {
                "lat": 16.4764,
                "lng": 120.6333
            },
            "Siruma": {
                "lat": 14.0219,
                "lng": 123.2589
            },
            "Pastrana": {
                "lat": 11.13,
                "lng": 124.88
            },
            "Buruanga": {
                "lat": 11.8438,
                "lng": 121.8888
            },
            "Napindan": {
                "lat": 14.5398,
                "lng": 121.0966
            },
            "Tadian": {
                "lat": 16.9961,
                "lng": 120.8208
            },
            "Pugo": {
                "lat": 16.28,
                "lng": 120.48
            },
            "Espiritu": {
                "lat": 17.9806,
                "lng": 120.6544
            },
            "Kapangan": {
                "lat": 16.5764,
                "lng": 120.6053
            },
            "Balintawak": {
                "lat": 14.6506,
                "lng": 121.0014
            },
            "Cabusao": {
                "lat": 13.7275,
                "lng": 123.1142
            },
            "Diadi": {
                "lat": 16.66,
                "lng": 121.3686
            },
            "Atok": {
                "lat": 16.6272,
                "lng": 120.7672
            },
            "Tingloy": {
                "lat": 13.65,
                "lng": 120.87
            },
            "Jiabong": {
                "lat": 11.7625,
                "lng": 124.9519
            },
            "Ermita": {
                "lat": 14.583,
                "lng": 120.983
            },
            "Alcoy": {
                "lat": 9.7082,
                "lng": 123.506
            },
            "San Teodoro": {
                "lat": 13.4358,
                "lng": 121.0192
            },
            "Lumbayanague": {
                "lat": 7.78,
                "lng": 124.28
            },
            "Malanday": {
                "lat": 14.7194,
                "lng": 120.9547
            },
            "San Gabriel": {
                "lat": 16.67,
                "lng": 120.4
            },
            "Madalag": {
                "lat": 11.5269,
                "lng": 122.3064
            },
            "Lagawe": {
                "lat": 16.7975,
                "lng": 121.1228
            },
            "Duero": {
                "lat": 9.72,
                "lng": 124.4
            },
            "Malimono": {
                "lat": 9.6183,
                "lng": 125.4019
            },
            "Nunu\u00f1gan": {
                "lat": 7.82,
                "lng": 123.97
            },
            "Sebaste": {
                "lat": 11.5901,
                "lng": 122.0945
            },
            "Taft": {
                "lat": 11.9058,
                "lng": 125.4181
            },
            "Paglat": {
                "lat": 6.7811,
                "lng": 124.7849
            },
            "Balilihan": {
                "lat": 9.75,
                "lng": 123.97
            },
            "Paluan": {
                "lat": 13.42,
                "lng": 120.47
            },
            "Santander": {
                "lat": 9.45,
                "lng": 123.33
            },
            "Bilar": {
                "lat": 9.7,
                "lng": 124.1
            },
            "Tinoc": {
                "lat": 16.675,
                "lng": 120.9367
            },
            "Baliangao": {
                "lat": 8.67,
                "lng": 123.6
            },
            "Tinajeros": {
                "lat": 14.6733,
                "lng": 120.969
            },
            "Cagdianao": {
                "lat": 9.92,
                "lng": 125.67
            },
            "Cortes": {
                "lat": 9.2753,
                "lng": 126.1911
            },
            "Pinabacdao": {
                "lat": 11.62,
                "lng": 124.98
            },
            "Mayorga": {
                "lat": 10.9,
                "lng": 125.0
            },
            "Libjo": {
                "lat": 10.196,
                "lng": 125.5328
            },
            "Buadiposo-Buntong": {
                "lat": 7.97,
                "lng": 124.38
            },
            "Marcos": {
                "lat": 18.0444,
                "lng": 120.6786
            },
            "Bombon": {
                "lat": 13.6867,
                "lng": 123.1994
            },
            "Almeria": {
                "lat": 11.6206,
                "lng": 124.3794
            },
            "Bucay": {
                "lat": 17.54,
                "lng": 120.72
            },
            "Flora": {
                "lat": 18.2147,
                "lng": 121.4186
            },
            "Gitagum": {
                "lat": 8.5956,
                "lng": 124.4054
            },
            "Loay": {
                "lat": 9.6,
                "lng": 124.02
            },
            "Marabut": {
                "lat": 11.12,
                "lng": 125.22
            },
            "Jovellar": {
                "lat": 13.07,
                "lng": 123.6
            },
            "Kiangan": {
                "lat": 16.7775,
                "lng": 121.0878
            },
            "Palanan": {
                "lat": 17.0589,
                "lng": 122.43
            },
            "Biliran": {
                "lat": 11.58,
                "lng": 124.48
            },
            "Pinili": {
                "lat": 17.954,
                "lng": 120.527
            },
            "San Jorge": {
                "lat": 11.9833,
                "lng": 124.8167
            },
            "Boljoon": {
                "lat": 9.63,
                "lng": 123.43
            },
            "Matagob": {
                "lat": 11.1469,
                "lng": 124.4733
            },
            "Libertad": {
                "lat": 8.5583,
                "lng": 124.35
            },
            "Talitay": {
                "lat": 7.0353,
                "lng": 124.3758
            },
            "Sibutao": {
                "lat": 8.6131,
                "lng": 123.4797
            },
            "Lubang": {
                "lat": 13.8586,
                "lng": 120.1228
            },
            "Loboc": {
                "lat": 9.63,
                "lng": 124.03
            },
            "Calayan": {
                "lat": 19.2619,
                "lng": 121.4753
            },
            "Tagana-an": {
                "lat": 9.6964,
                "lng": 125.5825
            },
            "Sudipen": {
                "lat": 16.9,
                "lng": 120.47
            },
            "Alcantara": {
                "lat": 9.9715,
                "lng": 123.4047
            },
            "Saguday": {
                "lat": 16.5394,
                "lng": 121.5636
            },
            "Dilasag": {
                "lat": 16.4,
                "lng": 122.22
            },
            "Santa Magdalena": {
                "lat": 12.6489,
                "lng": 124.1083
            },
            "Kibungan": {
                "lat": 16.6939,
                "lng": 120.6539
            },
            "Tomas Oppus": {
                "lat": 10.25,
                "lng": 124.98
            },
            "Ginatilan": {
                "lat": 9.6,
                "lng": 123.35
            },
            "Tubaran": {
                "lat": 7.72,
                "lng": 124.17
            },
            "Famy": {
                "lat": 14.43,
                "lng": 121.45
            },
            "Madrid": {
                "lat": 9.2619,
                "lng": 125.9647
            },
            "Barangka": {
                "lat": 14.6297,
                "lng": 121.0803
            },
            "Panukulan": {
                "lat": 14.93,
                "lng": 121.82
            },
            "Arteche": {
                "lat": 12.2694,
                "lng": 125.3712
            },
            "Zumarraga": {
                "lat": 11.639,
                "lng": 124.841
            },
            "Kabugao": {
                "lat": 18.0239,
                "lng": 121.1833
            },
            "Bingawan": {
                "lat": 11.23,
                "lng": 122.57
            },
            "Tankal": {
                "lat": 8.0,
                "lng": 124.0
            },
            "Despujols": {
                "lat": 12.5183,
                "lng": 122.0108
            },
            "Alabat": {
                "lat": 14.1023,
                "lng": 122.0136
            },
            "Kabayan": {
                "lat": 16.6233,
                "lng": 120.8381
            },
            "Sulat": {
                "lat": 11.82,
                "lng": 125.45
            },
            "Leso": {
                "lat": 11.6697,
                "lng": 122.3269
            },
            "Mayoyao": {
                "lat": 16.9736,
                "lng": 121.2214
            },
            "Calumpang": {
                "lat": 14.6249,
                "lng": 121.0865
            },
            "Julita": {
                "lat": 10.9731,
                "lng": 124.9622
            },
            "Pudtol": {
                "lat": 18.2356,
                "lng": 121.3733
            },
            "Ambaguio": {
                "lat": 16.5316,
                "lng": 121.0282
            },
            "Magdiwang": {
                "lat": 12.48,
                "lng": 122.52
            },
            "San Policarpo": {
                "lat": 12.1791,
                "lng": 125.5072
            },
            "Anao-aon": {
                "lat": 9.7778,
                "lng": 125.4231
            },
            "Maydolong": {
                "lat": 11.5,
                "lng": 125.5
            },
            "Motiong": {
                "lat": 11.78,
                "lng": 125.0
            },
            "Project Six": {
                "lat": 14.6561,
                "lng": 121.0408
            },
            "Libagon": {
                "lat": 10.3,
                "lng": 125.05
            },
            "Silvino Lobos": {
                "lat": 12.3281,
                "lng": 124.8464
            },
            "Tatarikan": {
                "lat": 7.73,
                "lng": 124.12
            },
            "Patnanungan": {
                "lat": 14.78,
                "lng": 122.18
            },
            "Bangui": {
                "lat": 18.5378,
                "lng": 120.7653
            },
            "Santa": {
                "lat": 17.486,
                "lng": 120.4344
            },
            "Antequera": {
                "lat": 9.7812,
                "lng": 123.8975
            },
            "Calanogas": {
                "lat": 7.75,
                "lng": 124.1
            },
            "Lagangilang": {
                "lat": 17.61,
                "lng": 120.73
            },
            "Dimiao": {
                "lat": 9.62,
                "lng": 124.17
            },
            "Bacuag": {
                "lat": 9.6081,
                "lng": 125.6405
            },
            "San Tomas": {
                "lat": 15.8792,
                "lng": 120.5883
            },
            "Tayum": {
                "lat": 17.62,
                "lng": 120.66
            },
            "San Julian": {
                "lat": 11.7536,
                "lng": 125.4558
            },
            "Matungao": {
                "lat": 8.13,
                "lng": 124.17
            },
            "Lope de Vega": {
                "lat": 12.2983,
                "lng": 124.6238
            },
            "Mahinog": {
                "lat": 9.15,
                "lng": 124.78
            },
            "Boston": {
                "lat": 7.8697,
                "lng": 126.3761
            },
            "Batuan": {
                "lat": 9.8,
                "lng": 124.13
            },
            "Bakun": {
                "lat": 16.7925,
                "lng": 120.6614
            },
            "Pualas": {
                "lat": 7.82,
                "lng": 124.07
            },
            "Quinapundan": {
                "lat": 11.1578,
                "lng": 125.5208
            },
            "Nampicuan": {
                "lat": 15.7342,
                "lng": 120.6356
            },
            "Larena": {
                "lat": 9.249,
                "lng": 123.591
            },
            "Bokod": {
                "lat": 16.4914,
                "lng": 120.83
            },
            "Araceli": {
                "lat": 10.5529,
                "lng": 119.9904
            },
            "Bagulin": {
                "lat": 16.6079,
                "lng": 120.4378
            },
            "General MacArthur": {
                "lat": 11.2486,
                "lng": 125.54
            },
            "Maria": {
                "lat": 9.196,
                "lng": 123.655
            },
            "Balangiga": {
                "lat": 11.1092,
                "lng": 125.3867
            },
            "Balangkas": {
                "lat": 14.7381,
                "lng": 120.9978
            },
            "Mapanas": {
                "lat": 12.475,
                "lng": 125.254
            },
            "Santol": {
                "lat": 16.77,
                "lng": 120.45
            },
            "Belison": {
                "lat": 10.8381,
                "lng": 121.9606
            },
            "Kinogitan": {
                "lat": 8.9855,
                "lng": 124.7937
            },
            "Hinabangan": {
                "lat": 11.7,
                "lng": 125.07
            },
            "Lanuza": {
                "lat": 9.2322,
                "lng": 126.0592
            },
            "Dakit": {
                "lat": 10.06,
                "lng": 125.1606
            },
            "Buli": {
                "lat": 14.443,
                "lng": 121.051
            },
            "Santa Marcela": {
                "lat": 18.2872,
                "lng": 121.4375
            },
            "Lawang Bato": {
                "lat": 14.73,
                "lng": 120.9961
            },
            "San Clemente": {
                "lat": 15.7119,
                "lng": 120.3603
            },
            "Tinglayan": {
                "lat": 17.265,
                "lng": 121.1489
            },
            "Giporlos": {
                "lat": 11.1208,
                "lng": 125.4494
            },
            "Silago": {
                "lat": 10.5291,
                "lng": 125.1618
            },
            "Lawaan": {
                "lat": 11.1408,
                "lng": 125.3011
            },
            "Culaba": {
                "lat": 11.6578,
                "lng": 124.5425
            },
            "Balbalan": {
                "lat": 17.4436,
                "lng": 121.2008
            },
            "Agutaya": {
                "lat": 11.152,
                "lng": 120.9396
            },
            "Perez": {
                "lat": 14.18,
                "lng": 121.93
            },
            "Agdangan": {
                "lat": 13.8758,
                "lng": 121.9122
            },
            "Calanasan": {
                "lat": 18.255,
                "lng": 121.0436
            },
            "Pidigan": {
                "lat": 17.57,
                "lng": 120.59
            },
            "Hinundayan": {
                "lat": 10.35,
                "lng": 125.25
            },
            "Capul": {
                "lat": 12.423,
                "lng": 124.182
            },
            "Lila": {
                "lat": 9.6,
                "lng": 124.1
            },
            "Currimao": {
                "lat": 18.0203,
                "lng": 120.4864
            },
            "Anao": {
                "lat": 15.7304,
                "lng": 120.6264
            },
            "Josefina": {
                "lat": 8.2144,
                "lng": 123.5439
            },
            "Nueva Era": {
                "lat": 17.92,
                "lng": 120.67
            },
            "Mariana": {
                "lat": 14.6194,
                "lng": 121.0347
            },
            "Fort Bonifacio": {
                "lat": 14.5311,
                "lng": 121.0478
            },
            "Tabontabon": {
                "lat": 11.03,
                "lng": 124.97
            },
            "Lapinig": {
                "lat": 12.315,
                "lng": 125.302
            },
            "Agcogon": {
                "lat": 12.07,
                "lng": 121.93
            },
            "Mutia": {
                "lat": 8.4176,
                "lng": 123.4771
            },
            "Manabo": {
                "lat": 17.43,
                "lng": 120.7
            },
            "Sablan": {
                "lat": 16.4967,
                "lng": 120.4875
            },
            "Gainza": {
                "lat": 13.6167,
                "lng": 123.1414
            },
            "Sagada": {
                "lat": 17.0842,
                "lng": 120.9008
            },
            "Sevilla": {
                "lat": 9.7,
                "lng": 124.05
            },
            "Biri": {
                "lat": 12.67,
                "lng": 124.38
            },
            "Alburquerque": {
                "lat": 9.6104,
                "lng": 123.9549
            },
            "Bagamanoc": {
                "lat": 13.9408,
                "lng": 124.2888
            },
            "Balingoan": {
                "lat": 9.0,
                "lng": 124.85
            },
            "Panaon": {
                "lat": 8.3667,
                "lng": 123.8333
            },
            "Suyo": {
                "lat": 16.9839,
                "lng": 120.5139
            },
            "Tuktukan": {
                "lat": 14.528,
                "lng": 121.0718
            },
            "Dinagat": {
                "lat": 9.9561,
                "lng": 125.5933
            },
            "Pasil": {
                "lat": 17.3894,
                "lng": 121.1597
            },
            "Don Galo": {
                "lat": 14.5072,
                "lng": 120.9844
            },
            "San Ricardo": {
                "lat": 9.92,
                "lng": 125.28
            },
            "Natonin": {
                "lat": 17.1089,
                "lng": 121.2797
            },
            "Galimuyod": {
                "lat": 17.18,
                "lng": 120.47
            },
            "Pintuyan": {
                "lat": 9.95,
                "lng": 125.25
            },
            "Balangkayan": {
                "lat": 11.4728,
                "lng": 125.5108
            },
            "Corcuera": {
                "lat": 12.8,
                "lng": 122.05
            },
            "Hingyon": {
                "lat": 16.8522,
                "lng": 121.0989
            },
            "Sugbongkogon": {
                "lat": 8.95,
                "lng": 124.78
            },
            "Panganiban": {
                "lat": 13.9,
                "lng": 124.3
            },
            "Sabangan": {
                "lat": 17.0044,
                "lng": 120.9233
            },
            "Basco": {
                "lat": 20.45,
                "lng": 121.97
            },
            "Corella": {
                "lat": 9.68,
                "lng": 123.92
            },
            "Santa Monica": {
                "lat": 10.02,
                "lng": 126.038
            },
            "Lubuagan": {
                "lat": 17.35,
                "lng": 121.18
            },
            "Almagro": {
                "lat": 11.9108,
                "lng": 124.2852
            },
            "Bayabas": {
                "lat": 8.9678,
                "lng": 126.2817
            },
            "Hunduan": {
                "lat": 16.83,
                "lng": 121.0
            },
            "Tagapul-an": {
                "lat": 12.05,
                "lng": 124.15
            },
            "Tanudan": {
                "lat": 17.2814,
                "lng": 121.2303
            },
            "Gigmoto": {
                "lat": 13.78,
                "lng": 124.38
            },
            "San Sebastian": {
                "lat": 11.7,
                "lng": 125.02
            },
            "La Huerta": {
                "lat": 14.4972,
                "lng": 120.9953
            },
            "Alfonso Casta\u00f1eda": {
                "lat": 15.7933,
                "lng": 121.3025
            },
            "Hernani": {
                "lat": 11.3239,
                "lng": 125.6181
            },
            "Magugpo Poblacion": {
                "lat": 7.3821,
                "lng": 125.8017
            }
        }
    },
    "BR": {
        "name": "Brazil",
        "cities": {
            "S\u00e3o Paulo": {
                "lat": -23.55,
                "lng": -46.6333
            },
            "Rio de Janeiro": {
                "lat": -22.9111,
                "lng": -43.2056
            },
            "Belo Horizonte": {
                "lat": -19.9167,
                "lng": -43.9333
            },
            "Fortaleza": {
                "lat": -3.7275,
                "lng": -38.5275
            },
            "Bras\u00edlia": {
                "lat": -15.7939,
                "lng": -47.8828
            },
            "Salvador": {
                "lat": -12.9747,
                "lng": -38.4767
            },
            "Manaus": {
                "lat": -3.1189,
                "lng": -60.0217
            },
            "Santos": {
                "lat": -23.9369,
                "lng": -46.325
            },
            "Curitiba": {
                "lat": -25.4297,
                "lng": -49.2711
            },
            "Recife": {
                "lat": -8.05,
                "lng": -34.9
            },
            "Goi\u00e2nia": {
                "lat": -16.6667,
                "lng": -49.25
            },
            "Porto Alegre": {
                "lat": -30.0331,
                "lng": -51.23
            },
            "Guarulhos": {
                "lat": -23.4628,
                "lng": -46.5328
            },
            "Bel\u00e9m": {
                "lat": -6.7347,
                "lng": -35.5169
            },
            "Campinas": {
                "lat": -22.9058,
                "lng": -47.0608
            },
            "S\u00e3o Lu\u00eds": {
                "lat": -2.5283,
                "lng": -44.3044
            },
            "Natal": {
                "lat": -5.7833,
                "lng": -35.2
            },
            "Macei\u00f3": {
                "lat": -9.6658,
                "lng": -35.735
            },
            "Teresina": {
                "lat": -5.0949,
                "lng": -42.8042
            },
            "Campo Grande": {
                "lat": -20.4839,
                "lng": -54.615
            },
            "S\u00e3o Gon\u00e7alo": {
                "lat": -22.8269,
                "lng": -43.0539
            },
            "Igua\u00e7u": {
                "lat": -22.74,
                "lng": -43.47
            },
            "Jo\u00e3o Pessoa": {
                "lat": -7.12,
                "lng": -34.88
            },
            "S\u00e3o Bernardo do Campo": {
                "lat": -23.7,
                "lng": -46.55
            },
            "Santo Andr\u00e9": {
                "lat": -23.6572,
                "lng": -46.5333
            },
            "Osasco": {
                "lat": -23.5328,
                "lng": -46.7919
            },
            "Sorocaba": {
                "lat": -23.5017,
                "lng": -47.4581
            },
            "Uberl\u00e2ndia": {
                "lat": -18.9231,
                "lng": -48.2886
            },
            "Jaboat\u00e3o": {
                "lat": -8.1803,
                "lng": -35.0014
            },
            "Ribeir\u00e3o Pr\u00eato": {
                "lat": -21.1783,
                "lng": -47.8067
            },
            "S\u00e3o Jos\u00e9 dos Campos": {
                "lat": -23.1789,
                "lng": -45.8869
            },
            "Aracaju": {
                "lat": -10.9167,
                "lng": -37.05
            },
            "Cuiab\u00e1": {
                "lat": -15.5958,
                "lng": -56.0969
            },
            "Jaboat\u00e3o dos Guararapes": {
                "lat": -8.1122,
                "lng": -35.0156
            },
            "Contagem": {
                "lat": -19.9319,
                "lng": -44.0539
            },
            "Joinvile": {
                "lat": -26.3204,
                "lng": -48.8437
            },
            "Feira de Santana": {
                "lat": -12.25,
                "lng": -38.95
            },
            "Londrina": {
                "lat": -23.31,
                "lng": -51.1628
            },
            "Juiz de Fora": {
                "lat": -21.7619,
                "lng": -43.3494
            },
            "Florian\u00f3polis": {
                "lat": -27.6122,
                "lng": -48.4853
            },
            "Serra": {
                "lat": -20.1289,
                "lng": -40.3078
            },
            "Macap\u00e1": {
                "lat": 0.033,
                "lng": -51.05
            },
            "Campos": {
                "lat": -21.7539,
                "lng": -41.3239
            },
            "Niter\u00f3i": {
                "lat": -22.8833,
                "lng": -43.1036
            },
            "S\u00e3o Jos\u00e9 do Rio Pr\u00eato": {
                "lat": -20.8081,
                "lng": -49.3811
            },
            "Ananindeua": {
                "lat": -1.3658,
                "lng": -48.3719
            },
            "Vila Velha": {
                "lat": 3.2094,
                "lng": -51.2308
            },
            "Caxias do Sul": {
                "lat": -29.1667,
                "lng": -51.1833
            },
            "Porto Velho": {
                "lat": -8.7619,
                "lng": -63.9039
            },
            "Mogi das Cruzes": {
                "lat": -23.5228,
                "lng": -46.1878
            },
            "Piracicaba": {
                "lat": -22.7253,
                "lng": -47.6492
            },
            "Campina Grande": {
                "lat": -7.2306,
                "lng": -35.8811
            },
            "Mau\u00e1": {
                "lat": -23.6678,
                "lng": -46.4608
            },
            "Boa Vista": {
                "lat": 2.8194,
                "lng": -60.6714
            },
            "Betim": {
                "lat": -19.9678,
                "lng": -44.1978
            },
            "Maring\u00e1": {
                "lat": -23.4,
                "lng": -51.9167
            },
            "Jundia\u00ed": {
                "lat": -23.1858,
                "lng": -46.8839
            },
            "An\u00e1polis": {
                "lat": -16.3339,
                "lng": -48.9519
            },
            "Diadema": {
                "lat": -23.6861,
                "lng": -46.6106
            },
            "Carapicu\u00edba": {
                "lat": -23.5192,
                "lng": -46.8358
            },
            "Petrolina": {
                "lat": -9.3825,
                "lng": -40.5022
            },
            "Caruaru": {
                "lat": -8.2828,
                "lng": -35.9758
            },
            "Itaquaquecetuba": {
                "lat": -23.4864,
                "lng": -46.3486
            },
            "Bauru": {
                "lat": -22.315,
                "lng": -49.0608
            },
            "Rio Branco": {
                "lat": -9.9747,
                "lng": -67.81
            },
            "Blumenau": {
                "lat": -26.9333,
                "lng": -49.05
            },
            "Ponta Grossa": {
                "lat": -25.0994,
                "lng": -50.1583
            },
            "Caucaia": {
                "lat": -3.7167,
                "lng": -38.65
            },
            "Franca": {
                "lat": -20.5389,
                "lng": -47.4008
            },
            "Olinda": {
                "lat": -8.0,
                "lng": -34.8833
            },
            "Praia Grande": {
                "lat": -24.0061,
                "lng": -46.4028
            },
            "Cariacica": {
                "lat": -20.2639,
                "lng": -40.42
            },
            "Cascavel": {
                "lat": -4.1308,
                "lng": -38.2358
            },
            "Canoas": {
                "lat": -29.92,
                "lng": -51.18
            },
            "Paulista": {
                "lat": -6.5939,
                "lng": -37.6239
            },
            "Vit\u00f3ria da Conquista": {
                "lat": -14.8658,
                "lng": -40.8389
            },
            "Uberaba": {
                "lat": -19.7478,
                "lng": -47.9319
            },
            "Santar\u00e9m": {
                "lat": -2.43,
                "lng": -54.72
            },
            "S\u00e3o Vicente": {
                "lat": -23.9633,
                "lng": -46.3922
            },
            "Ribeir\u00e3o das Neves": {
                "lat": -19.7669,
                "lng": -44.0869
            },
            "S\u00e3o Jos\u00e9 dos Pinhais": {
                "lat": -25.535,
                "lng": -49.2058
            },
            "Pelotas": {
                "lat": -31.7719,
                "lng": -52.3425
            },
            "Vit\u00f3ria": {
                "lat": -20.2889,
                "lng": -40.3083
            },
            "Taubat\u00e9": {
                "lat": -23.025,
                "lng": -45.5556
            },
            "Barueri": {
                "lat": -23.5111,
                "lng": -46.8764
            },
            "Guaruj\u00e1": {
                "lat": -23.9936,
                "lng": -46.2564
            },
            "Suzano": {
                "lat": -23.5428,
                "lng": -46.3108
            },
            "Palmas": {
                "lat": -26.4842,
                "lng": -51.9906
            },
            "Cama\u00e7ari": {
                "lat": -12.6978,
                "lng": -38.3239
            },
            "V\u00e1rzea Grande": {
                "lat": -15.65,
                "lng": -56.1333
            },
            "Juazeiro do Norte": {
                "lat": -7.2,
                "lng": -39.3333
            },
            "Foz do Igua\u00e7u": {
                "lat": -25.54,
                "lng": -54.5875
            },
            "Petr\u00f3polis": {
                "lat": -22.505,
                "lng": -43.1789
            },
            "Crato": {
                "lat": -7.2339,
                "lng": -39.4089
            },
            "Cotia": {
                "lat": -23.6042,
                "lng": -46.9194
            },
            "Tabo\u00e3o da Serra": {
                "lat": -23.5328,
                "lng": -46.7994
            },
            "Imperatriz": {
                "lat": -5.5333,
                "lng": -47.4833
            },
            "Santa Maria": {
                "lat": -24.9389,
                "lng": -51.8628
            },
            "Marab\u00e1": {
                "lat": -5.35,
                "lng": -49.1167
            },
            "Sumar\u00e9": {
                "lat": -22.8219,
                "lng": -47.2669
            },
            "Gravata\u00ed": {
                "lat": -29.9333,
                "lng": -50.9833
            },
            "Mossor\u00f3": {
                "lat": -5.1878,
                "lng": -37.3439
            },
            "Itaja\u00ed": {
                "lat": -26.9,
                "lng": -48.6667
            },
            "Volta Redonda": {
                "lat": -22.5228,
                "lng": -44.1039
            },
            "Governador Valadares": {
                "lat": -18.85,
                "lng": -41.9333
            },
            "Indaiatuba": {
                "lat": -23.0903,
                "lng": -47.2181
            },
            "S\u00e3o Carlos": {
                "lat": -27.0775,
                "lng": -53.0039
            },
            "Parnamirim": {
                "lat": -8.0908,
                "lng": -39.5778
            },
            "S\u00e3o Jos\u00e9": {
                "lat": -27.6,
                "lng": -48.6167
            },
            "Limeira": {
                "lat": -22.565,
                "lng": -47.4019
            },
            "Maca\u00e9": {
                "lat": -22.3708,
                "lng": -41.7869
            },
            "Rondon\u00f3polis": {
                "lat": -16.4667,
                "lng": -54.6333
            },
            "S\u00e3o Jos\u00e9 de Ribamar": {
                "lat": -2.55,
                "lng": -44.05
            },
            "Dourados": {
                "lat": -22.2208,
                "lng": -54.8058
            },
            "Araraquara": {
                "lat": -21.7939,
                "lng": -48.1758
            },
            "Americana": {
                "lat": -22.7386,
                "lng": -47.3311
            },
            "Sete Lagoas": {
                "lat": -19.4658,
                "lng": -44.2469
            },
            "Mar\u00edlia": {
                "lat": -22.2139,
                "lng": -49.9458
            },
            "Jacare\u00ed": {
                "lat": -23.3053,
                "lng": -45.9658
            },
            "Juazeiro": {
                "lat": -9.4306,
                "lng": -40.5028
            },
            "Arapiraca": {
                "lat": -9.7519,
                "lng": -36.6608
            },
            "Maracana\u00fa": {
                "lat": -3.8667,
                "lng": -38.6333
            },
            "Colombo": {
                "lat": -25.2919,
                "lng": -49.2239
            },
            "Divin\u00f3polis": {
                "lat": -20.1389,
                "lng": -44.8839
            },
            "Mag\u00e9": {
                "lat": -22.6528,
                "lng": -43.0408
            },
            "Novo Hamburgo": {
                "lat": -29.6778,
                "lng": -51.1308
            },
            "Ipatinga": {
                "lat": -19.5,
                "lng": -42.5333
            },
            "Rio Verde": {
                "lat": -17.745,
                "lng": -50.9167
            },
            "Presidente Prudente": {
                "lat": -22.1256,
                "lng": -51.3889
            },
            "Itabora\u00ed": {
                "lat": -22.7439,
                "lng": -42.8589
            },
            "Viam\u00e3o": {
                "lat": -30.0808,
                "lng": -51.0228
            },
            "Chapec\u00f3": {
                "lat": -27.0958,
                "lng": -52.6178
            },
            "Itapevi": {
                "lat": -23.5489,
                "lng": -46.9342
            },
            "Cabo Frio": {
                "lat": -22.8789,
                "lng": -42.0189
            },
            "Santa Luzia": {
                "lat": -15.4289,
                "lng": -39.3339
            },
            "S\u00e3o Leopoldo": {
                "lat": -29.76,
                "lng": -51.1469
            },
            "Hortol\u00e2ndia": {
                "lat": -22.8583,
                "lng": -47.22
            },
            "Crici\u00fama": {
                "lat": -28.6775,
                "lng": -49.3697
            },
            "Sobral": {
                "lat": -3.6739,
                "lng": -40.2389
            },
            "Santo Agostinho": {
                "lat": -8.2897,
                "lng": -35.03
            },
            "Luzi\u00e2nia": {
                "lat": -16.2528,
                "lng": -47.95
            },
            "Passo Fundo": {
                "lat": -28.25,
                "lng": -52.4
            },
            "Lauro de Freitas": {
                "lat": -12.8939,
                "lng": -38.3269
            },
            "Rio Claro": {
                "lat": -22.7228,
                "lng": -44.1358
            },
            "Ara\u00e7atuba": {
                "lat": -21.2089,
                "lng": -50.4328
            },
            "Maric\u00e1": {
                "lat": -22.9189,
                "lng": -42.8189
            },
            "Sinop": {
                "lat": -11.8481,
                "lng": -55.6492
            },
            "Nossa Senhora do Socorro": {
                "lat": -10.855,
                "lng": -37.0592
            },
            "Castanhal": {
                "lat": -1.2969,
                "lng": -47.9219
            },
            "Rio Grande": {
                "lat": -32.035,
                "lng": -52.0986
            },
            "Nova Friburgo": {
                "lat": -22.2819,
                "lng": -42.5311
            },
            "Alvorada": {
                "lat": -12.48,
                "lng": -49.125
            },
            "Itabuna": {
                "lat": -14.7858,
                "lng": -39.28
            },
            "Cachoeiro de Itapemirim": {
                "lat": -20.8489,
                "lng": -41.1128
            },
            "Ferraz de Vasconcelos": {
                "lat": -23.5411,
                "lng": -46.3689
            },
            "Santa B\u00e1rbara d\u2019Oeste": {
                "lat": -22.7544,
                "lng": -47.4142
            },
            "Jaragu\u00e1 do Sul": {
                "lat": -26.4833,
                "lng": -49.0667
            },
            "Guarapuava": {
                "lat": -25.3833,
                "lng": -51.45
            },
            "Ilh\u00e9us": {
                "lat": -14.7889,
                "lng": -39.0489
            },
            "Timon": {
                "lat": -5.0939,
                "lng": -42.8369
            },
            "Aragua\u00edna": {
                "lat": -7.1908,
                "lng": -48.2069
            },
            "Ibirit\u00e9": {
                "lat": -20.0219,
                "lng": -44.0589
            },
            "Barra Mansa": {
                "lat": -22.5439,
                "lng": -44.1708
            },
            "Francisco Morato": {
                "lat": -23.2817,
                "lng": -46.7425
            },
            "P\u00f4rto Seguro": {
                "lat": -16.4333,
                "lng": -39.0833
            },
            "Angra dos Reis": {
                "lat": -23.0067,
                "lng": -44.3181
            },
            "Itapecerica da Serra": {
                "lat": -23.7172,
                "lng": -46.8494
            },
            "Itu": {
                "lat": -23.2642,
                "lng": -47.2992
            },
            "Linhares": {
                "lat": -19.3894,
                "lng": -40.0678
            },
            "S\u00e3o Caetano do Sul": {
                "lat": -23.7,
                "lng": -46.55
            },
            "Lajes": {
                "lat": -5.7,
                "lng": -36.245
            },
            "Teres\u00f3polis": {
                "lat": -22.4119,
                "lng": -42.9658
            },
            "Po\u00e7os de Caldas": {
                "lat": -21.7878,
                "lng": -46.5608
            },
            "Parna\u00edba": {
                "lat": -2.905,
                "lng": -41.7769
            },
            "Bragan\u00e7a Paulista": {
                "lat": -22.9531,
                "lng": -46.5422
            },
            "Pindamonhangaba": {
                "lat": -22.9239,
                "lng": -45.4617
            },
            "Barreiras": {
                "lat": -12.1483,
                "lng": -44.9925
            },
            "Patos de Minas": {
                "lat": -18.5817,
                "lng": -46.5147
            },
            "Jequi\u00e9": {
                "lat": -13.863,
                "lng": -40.0843
            },
            "Abaetetuba": {
                "lat": -1.7178,
                "lng": -48.8828
            },
            "Itapetininga": {
                "lat": -23.5917,
                "lng": -48.0531
            },
            "Caxias": {
                "lat": -4.8589,
                "lng": -43.3558
            },
            "Rio das Ostras": {
                "lat": -22.5269,
                "lng": -41.945
            },
            "Camarajibe": {
                "lat": -8.0219,
                "lng": -34.9808
            },
            "Santana de Parna\u00edba": {
                "lat": -23.4439,
                "lng": -46.9178
            },
            "Mogi Gua\u00e7u": {
                "lat": -22.3719,
                "lng": -46.9419
            },
            "Pouso Alegre": {
                "lat": -22.2281,
                "lng": -45.9336
            },
            "Alagoinhas": {
                "lat": -12.1358,
                "lng": -38.4189
            },
            "Arauc\u00e1ria": {
                "lat": -25.4167,
                "lng": -49.25
            },
            "Toledo": {
                "lat": -24.7139,
                "lng": -53.7428
            },
            "Santa Rita": {
                "lat": -7.1139,
                "lng": -34.9778
            },
            "Franco da Rocha": {
                "lat": -23.3286,
                "lng": -46.7244
            },
            "Pa\u00e7o do Lumiar": {
                "lat": -2.5319,
                "lng": -44.1078
            },
            "Teixeira de Freitas": {
                "lat": -17.5403,
                "lng": -39.7437
            },
            "Garanhuns": {
                "lat": -8.8903,
                "lng": -36.4928
            },
            "Trindade": {
                "lat": -7.7619,
                "lng": -40.2678
            },
            "Paranagu\u00e1": {
                "lat": -25.5208,
                "lng": -48.5094
            },
            "Botucatu": {
                "lat": -22.8858,
                "lng": -48.445
            },
            "Balne\u00e1rio de Cambori\u00fa": {
                "lat": -26.9953,
                "lng": -48.6356
            },
            "Brusque": {
                "lat": -27.0949,
                "lng": -48.9148
            },
            "Te\u00f3filo Otoni": {
                "lat": -17.8578,
                "lng": -41.505
            },
            "Palho\u00e7a": {
                "lat": -27.6444,
                "lng": -48.6678
            },
            "Atibaia": {
                "lat": -23.1172,
                "lng": -46.5506
            },
            "Varginha": {
                "lat": -21.5517,
                "lng": -45.43
            },
            "Campo Largo": {
                "lat": -25.4589,
                "lng": -49.5278
            },
            "Cachoeirinha": {
                "lat": -8.4858,
                "lng": -36.2328
            },
            "Caraguatatuba": {
                "lat": -23.62,
                "lng": -45.4128
            },
            "Salto": {
                "lat": -23.2008,
                "lng": -47.2869
            },
            "Camet\u00e1": {
                "lat": -2.2439,
                "lng": -49.4958
            },
            "Vit\u00f3ria de Santo Ant\u00e3o": {
                "lat": -8.1264,
                "lng": -35.3075
            },
            "Marituba": {
                "lat": -10.2833,
                "lng": -36.4
            },
            "Ja\u00fa": {
                "lat": -22.2958,
                "lng": -48.5578
            },
            "Santa Cruz do Sul": {
                "lat": -29.7178,
                "lng": -52.4258
            },
            "S\u00e3o Mateus": {
                "lat": -18.7158,
                "lng": -39.8589
            },
            "Cubat\u00e3o": {
                "lat": -23.8953,
                "lng": -46.4256
            },
            "Conselheiro Lafaiete": {
                "lat": -20.66,
                "lng": -43.7858
            },
            "Ji-Paran\u00e1": {
                "lat": -10.8853,
                "lng": -61.9517
            },
            "Itapipoca": {
                "lat": -3.4939,
                "lng": -39.5789
            },
            "Araras": {
                "lat": -22.3572,
                "lng": -47.3844
            },
            "Apucarana": {
                "lat": -23.5508,
                "lng": -51.4608
            },
            "Vespasiano": {
                "lat": -19.6919,
                "lng": -43.9228
            },
            "Araruama": {
                "lat": -22.8728,
                "lng": -42.3428
            },
            "Sabar\u00e1": {
                "lat": -19.8858,
                "lng": -43.8069
            },
            "Bragan\u00e7a": {
                "lat": -1.0628,
                "lng": -46.7728
            },
            "Votorantim": {
                "lat": -23.5469,
                "lng": -47.4378
            },
            "Sert\u00e3ozinho": {
                "lat": -21.1381,
                "lng": -47.9906
            },
            "Barcarena Nova": {
                "lat": -1.5058,
                "lng": -48.6258
            },
            "Jandira": {
                "lat": -23.5278,
                "lng": -46.9028
            },
            "Valinhos": {
                "lat": -22.9706,
                "lng": -46.9958
            },
            "Altamira": {
                "lat": -3.2028,
                "lng": -52.2058
            },
            "Barbacena": {
                "lat": -21.2167,
                "lng": -43.7667
            },
            "Resende": {
                "lat": -22.4689,
                "lng": -44.4469
            },
            "Guarapari": {
                "lat": -20.65,
                "lng": -40.5
            },
            "Itaituba": {
                "lat": -4.2758,
                "lng": -55.9839
            },
            "Tr\u00eas Lagoas": {
                "lat": -20.7511,
                "lng": -51.6783
            },
            "Bento Gon\u00e7alves": {
                "lat": -29.1708,
                "lng": -51.5189
            },
            "Itatiba": {
                "lat": -23.0058,
                "lng": -46.8389
            },
            "Barretos": {
                "lat": -20.5569,
                "lng": -48.5678
            },
            "Almirante Tamandar\u00e9": {
                "lat": -25.325,
                "lng": -49.31
            },
            "Arapongas": {
                "lat": -23.4189,
                "lng": -51.4239
            },
            "Guaratinguet\u00e1": {
                "lat": -22.8167,
                "lng": -45.2278
            },
            "Birigui": {
                "lat": -21.2886,
                "lng": -50.34
            },
            "Piraquara": {
                "lat": -25.4419,
                "lng": -49.0628
            },
            "Senador Canedo": {
                "lat": -16.7594,
                "lng": -49.0864
            },
            "Bag\u00e9": {
                "lat": -31.3308,
                "lng": -54.1069
            },
            "Araguari": {
                "lat": -18.6489,
                "lng": -48.1869
            },
            "Uruguaiana": {
                "lat": -29.755,
                "lng": -57.0878
            },
            "Umuarama": {
                "lat": -23.7658,
                "lng": -53.325
            },
            "Tatu\u00ed": {
                "lat": -23.3556,
                "lng": -47.8569
            },
            "S\u00e3o Gon\u00e7alo do Amarante": {
                "lat": -3.6058,
                "lng": -38.9689
            },
            "Catanduva": {
                "lat": -21.1378,
                "lng": -48.9728
            },
            "V\u00e1rzea Paulista": {
                "lat": -23.2114,
                "lng": -46.8283
            },
            "Formosa": {
                "lat": -15.5369,
                "lng": -47.3339
            },
            "Ribeir\u00e3o Pires": {
                "lat": -23.7153,
                "lng": -46.4194
            },
            "Igarassu": {
                "lat": -7.8342,
                "lng": -34.9064
            },
            "Sim\u00f5es Filho": {
                "lat": -12.7839,
                "lng": -38.4039
            },
            "Catal\u00e3o": {
                "lat": -18.17,
                "lng": -47.9419
            },
            "Cod\u00f3": {
                "lat": -4.455,
                "lng": -43.8858
            },
            "Po\u00e1": {
                "lat": -23.5286,
                "lng": -46.345
            },
            "Euri\u00e1polis": {
                "lat": -16.3778,
                "lng": -39.58
            },
            "Itabira": {
                "lat": -19.6189,
                "lng": -43.2269
            },
            "Paulo Afonso": {
                "lat": -9.4,
                "lng": -38.225
            },
            "Passos": {
                "lat": -20.7189,
                "lng": -46.61
            },
            "Colatina": {
                "lat": -19.5389,
                "lng": -40.6308
            },
            "Nova Lima": {
                "lat": -19.9858,
                "lng": -43.8469
            },
            "Arax\u00e1": {
                "lat": -19.5928,
                "lng": -46.9408
            },
            "S\u00e3o Louren\u00e7o da Mata": {
                "lat": -8.0019,
                "lng": -35.0178
            },
            "Ariquemes": {
                "lat": -9.9161,
                "lng": -63.0408
            },
            "Sorriso": {
                "lat": -12.545,
                "lng": -55.7108
            },
            "Tubar\u00e3o": {
                "lat": -28.4667,
                "lng": -49.0069
            },
            "Itumbiara": {
                "lat": -18.4167,
                "lng": -49.2167
            },
            "Balsas": {
                "lat": -7.5272,
                "lng": -46.0442
            },
            "Santana": {
                "lat": -12.9828,
                "lng": -44.0508
            },
            "Camb\u00e9": {
                "lat": -23.2758,
                "lng": -51.2778
            },
            "A\u00e7ailandia": {
                "lat": -4.9469,
                "lng": -47.505
            },
            "S\u00e3o Pedro da Aldeia": {
                "lat": -22.8389,
                "lng": -42.1028
            },
            "Jata\u00ed": {
                "lat": -17.8808,
                "lng": -51.8306
            },
            "Erechim": {
                "lat": -27.6339,
                "lng": -52.2739
            },
            "Nova Serrana": {
                "lat": -19.8667,
                "lng": -44.9833
            },
            "Japeri": {
                "lat": -22.6431,
                "lng": -43.6533
            },
            "Paragominas": {
                "lat": -2.9958,
                "lng": -47.3528
            },
            "Lagarto": {
                "lat": -10.9169,
                "lng": -37.65
            },
            "Maranguape": {
                "lat": -3.89,
                "lng": -38.6858
            },
            "Planaltina": {
                "lat": -15.4528,
                "lng": -47.6139
            },
            "Lavras": {
                "lat": -21.245,
                "lng": -45.0
            },
            "Coronel Fabriciano": {
                "lat": -19.5189,
                "lng": -42.6289
            },
            "Muria\u00e9": {
                "lat": -21.1306,
                "lng": -42.3664
            },
            "Ourinhos": {
                "lat": -22.9744,
                "lng": -49.8719
            },
            "Bacabal": {
                "lat": -4.225,
                "lng": -44.78
            },
            "Itacoatiara": {
                "lat": -3.1428,
                "lng": -58.4439
            },
            "Breves": {
                "lat": -1.6819,
                "lng": -50.48
            },
            "Ub\u00e1": {
                "lat": -21.12,
                "lng": -42.9428
            },
            "Patos": {
                "lat": -7.0244,
                "lng": -37.28
            },
            "Itanha\u00e9m": {
                "lat": -24.1806,
                "lng": -46.7828
            },
            "Aracruz": {
                "lat": -19.82,
                "lng": -40.2728
            },
            "Iguatu": {
                "lat": -6.3589,
                "lng": -39.2989
            },
            "Cambori\u00fa": {
                "lat": -27.025,
                "lng": -48.6539
            },
            "Santo Ant\u00f4nio de Jesus": {
                "lat": -12.9692,
                "lng": -39.2611
            },
            "Caieiras": {
                "lat": -23.3644,
                "lng": -46.7408
            },
            "Ituiutaba": {
                "lat": -18.9667,
                "lng": -49.4667
            },
            "Assis": {
                "lat": -22.6619,
                "lng": -50.4119
            },
            "Itaperuna": {
                "lat": -21.205,
                "lng": -41.8878
            },
            "Campo Mour\u00e3o": {
                "lat": -24.0458,
                "lng": -52.3828
            },
            "Caldas Novas": {
                "lat": -17.745,
                "lng": -48.625
            },
            "Manacapuru": {
                "lat": -3.2997,
                "lng": -60.6206
            },
            "Abreu e Lima": {
                "lat": -7.9117,
                "lng": -34.9028
            },
            "Santa Cruz do Capibaribe": {
                "lat": -7.9569,
                "lng": -36.205
            },
            "Leme": {
                "lat": -22.1861,
                "lng": -47.3906
            },
            "Valen\u00e7a": {
                "lat": -22.2458,
                "lng": -43.7
            },
            "Paul\u00ednia": {
                "lat": -22.7611,
                "lng": -47.1542
            },
            "Ita\u00fana": {
                "lat": -20.075,
                "lng": -44.5758
            },
            "Ipojuca": {
                "lat": -8.4,
                "lng": -35.0639
            },
            "Lajeado": {
                "lat": -29.4669,
                "lng": -51.9608
            },
            "Par\u00e1 de Minas": {
                "lat": -19.86,
                "lng": -44.6078
            },
            "Francisco Beltr\u00e3o": {
                "lat": -26.0833,
                "lng": -53.05
            },
            "Votuporanga": {
                "lat": -20.4172,
                "lng": -49.9728
            },
            "Parintins": {
                "lat": -2.6278,
                "lng": -56.7358
            },
            "Corumb\u00e1": {
                "lat": -19.0089,
                "lng": -57.6528
            },
            "Itabaiana": {
                "lat": -7.3289,
                "lng": -35.3328
            },
            "Vilhena": {
                "lat": -12.7406,
                "lng": -60.1458
            },
            "S\u00e3o Crist\u00f3v\u00e3o": {
                "lat": -11.015,
                "lng": -37.2058
            },
            "Paracatu": {
                "lat": -17.2217,
                "lng": -46.875
            },
            "Ponta Por\u00e3": {
                "lat": -22.5358,
                "lng": -55.7258
            },
            "Rio Largo": {
                "lat": -9.4778,
                "lng": -35.8528
            },
            "Mairipor\u00e3": {
                "lat": -23.3189,
                "lng": -46.5869
            },
            "Itajub\u00e1": {
                "lat": -22.4258,
                "lng": -45.4528
            },
            "Ubatuba": {
                "lat": -23.4394,
                "lng": -45.07
            },
            "Gua\u00edba": {
                "lat": -30.1183,
                "lng": -51.3214
            },
            "Barra do Pira\u00ed": {
                "lat": -22.47,
                "lng": -43.8258
            },
            "Mogi Mirim": {
                "lat": -22.4319,
                "lng": -46.9578
            },
            "S\u00e3o Jo\u00e3o da Boa Vista": {
                "lat": -21.9692,
                "lng": -46.7981
            },
            "Serra Talhada": {
                "lat": -7.9833,
                "lng": -38.3
            },
            "S\u00e3o Roque": {
                "lat": -23.5292,
                "lng": -47.1353
            },
            "Cruzeiro do Sul": {
                "lat": -29.5128,
                "lng": -51.985
            },
            "Pato Branco": {
                "lat": -26.2167,
                "lng": -52.6667
            },
            "Tucuru\u00ed": {
                "lat": -3.7678,
                "lng": -49.6728
            },
            "Avar\u00e9": {
                "lat": -23.0989,
                "lng": -48.9258
            },
            "Manhua\u00e7u": {
                "lat": -20.2581,
                "lng": -42.0336
            },
            "Ca\u00e7apava": {
                "lat": -23.1008,
                "lng": -45.7069
            },
            "S\u00e3o Jo\u00e3o del Rei": {
                "lat": -21.1358,
                "lng": -44.26
            },
            "Patroc\u00ednio": {
                "lat": -18.9333,
                "lng": -46.9833
            },
            "Itapeva": {
                "lat": -22.7678,
                "lng": -46.2208
            },
            "Saquarema": {
                "lat": -22.92,
                "lng": -42.51
            },
            "C\u00e1ceres": {
                "lat": -16.0711,
                "lng": -57.6789
            },
            "Barra do Corda": {
                "lat": -5.5,
                "lng": -45.25
            },
            "Guanambi": {
                "lat": -14.2228,
                "lng": -42.7808
            },
            "Caratinga": {
                "lat": -19.7833,
                "lng": -42.1333
            },
            "Lorena": {
                "lat": -22.7308,
                "lng": -45.1247
            },
            "Cacoal": {
                "lat": -11.4386,
                "lng": -61.4472
            },
            "Una\u00ed": {
                "lat": -16.3639,
                "lng": -46.9042
            },
            "Gravat\u00e1": {
                "lat": -8.1997,
                "lng": -35.5667
            },
            "Navegantes": {
                "lat": -26.8989,
                "lng": -48.6539
            },
            "Reden\u00e7\u00e3o": {
                "lat": -4.2258,
                "lng": -38.7308
            },
            "Gurupi": {
                "lat": -11.73,
                "lng": -49.0689
            },
            "Araripina": {
                "lat": -7.55,
                "lng": -40.5667
            },
            "Santa In\u00eas": {
                "lat": -13.2919,
                "lng": -39.8189
            },
            "Iju\u00ed": {
                "lat": -28.3878,
                "lng": -53.915
            },
            "Pinheiro": {
                "lat": -2.5208,
                "lng": -45.0828
            },
            "Santana do Livramento": {
                "lat": -30.8775,
                "lng": -55.5331
            },
            "Carpina": {
                "lat": -7.8508,
                "lng": -35.2547
            },
            "Quixad\u00e1": {
                "lat": -4.969,
                "lng": -39.013
            },
            "Aruj\u00e1": {
                "lat": -23.3967,
                "lng": -46.3211
            },
            "Lucas do Rio Verde": {
                "lat": -13.0719,
                "lng": -55.9192
            },
            "Mat\u00e3o": {
                "lat": -21.6028,
                "lng": -48.3658
            },
            "S\u00e3o Bento do Sul": {
                "lat": -26.25,
                "lng": -49.3833
            },
            "Serrinha": {
                "lat": -11.661,
                "lng": -39.007
            },
            "Picos": {
                "lat": -7.0769,
                "lng": -41.4669
            },
            "Moju": {
                "lat": -1.89,
                "lng": -48.7661
            },
            "Bayeux": {
                "lat": -7.1333,
                "lng": -34.9333
            },
            "Jacobina": {
                "lat": -11.1808,
                "lng": -40.5178
            },
            "Cruzeiro": {
                "lat": -22.5772,
                "lng": -44.9583
            },
            "Maca\u00edba": {
                "lat": -5.8578,
                "lng": -35.3539
            },
            "Quixeramobim": {
                "lat": -5.2,
                "lng": -39.2833
            },
            "Conc\u00f3rdia": {
                "lat": -27.2339,
                "lng": -52.0278
            },
            "Paranava\u00ed": {
                "lat": -23.0728,
                "lng": -52.465
            },
            "Tim\u00f3teo": {
                "lat": -19.5828,
                "lng": -42.6439
            },
            "S\u00e3o Sebasti\u00e3o": {
                "lat": -9.9339,
                "lng": -36.5539
            },
            "Chapadinha": {
                "lat": -3.7419,
                "lng": -43.36
            },
            "Pacatuba": {
                "lat": -10.4528,
                "lng": -36.6508
            },
            "Campo Limpo": {
                "lat": -23.2064,
                "lng": -46.7844
            },
            "Curvelo": {
                "lat": -18.7558,
                "lng": -44.4308
            },
            "Serop\u00e9dica": {
                "lat": -22.7439,
                "lng": -43.7078
            },
            "Aquiraz": {
                "lat": -3.9,
                "lng": -38.3831
            },
            "Cachoeira do Sul": {
                "lat": -30.0392,
                "lng": -52.8939
            },
            "Goiana": {
                "lat": -7.5608,
                "lng": -35.0028
            },
            "Cianorte": {
                "lat": -23.6167,
                "lng": -52.6167
            },
            "Belo Jardim": {
                "lat": -8.3358,
                "lng": -36.4239
            },
            "Viana": {
                "lat": -3.22,
                "lng": -45.0039
            },
            "Senhor do Bonfim": {
                "lat": -10.45,
                "lng": -40.1833
            },
            "Ca\u00e7ador": {
                "lat": -26.775,
                "lng": -51.015
            },
            "Cear\u00e1-Mirim": {
                "lat": -5.6333,
                "lng": -35.4333
            },
            "Alfenas": {
                "lat": -21.4333,
                "lng": -45.95
            },
            "Tr\u00eas Rios": {
                "lat": -22.1169,
                "lng": -43.2089
            },
            "Bebedouro": {
                "lat": -20.9494,
                "lng": -48.4792
            },
            "Santa Rosa": {
                "lat": -29.1361,
                "lng": -49.7
            },
            "Santo \u00c2ngelo": {
                "lat": -28.2833,
                "lng": -54.25
            },
            "Vinhedo": {
                "lat": -23.03,
                "lng": -46.975
            },
            "Tiangu\u00e1": {
                "lat": -3.7333,
                "lng": -40.9833
            },
            "Ibi\u00fana": {
                "lat": -23.6564,
                "lng": -47.2225
            },
            "Crate\u00fas": {
                "lat": -5.177,
                "lng": -40.668
            },
            "Esteio": {
                "lat": -29.8608,
                "lng": -51.1789
            },
            "Lins": {
                "lat": -21.6786,
                "lng": -49.7425
            },
            "Sapiranga": {
                "lat": -29.6333,
                "lng": -51.0
            },
            "Cataguases": {
                "lat": -21.4,
                "lng": -42.6833
            },
            "Tr\u00eas Cora\u00e7\u00f5es": {
                "lat": -21.6947,
                "lng": -45.2553
            },
            "Itapira": {
                "lat": -22.4361,
                "lng": -46.8217
            },
            "Lagoa Santa": {
                "lat": -19.6269,
                "lng": -43.89
            },
            "Aracati": {
                "lat": -4.5619,
                "lng": -37.77
            },
            "Tel\u00eamaco Borba": {
                "lat": -24.35,
                "lng": -50.6167
            },
            "Pira\u00e7ununga": {
                "lat": -21.9961,
                "lng": -47.4258
            },
            "Ouro Pr\u00eato": {
                "lat": -20.3853,
                "lng": -43.5036
            },
            "Canind\u00e9": {
                "lat": -4.3586,
                "lng": -39.314
            },
            "Tef\u00e9": {
                "lat": -3.3539,
                "lng": -64.7108
            },
            "Jo\u00e3o Monlevade": {
                "lat": -19.81,
                "lng": -43.1739
            },
            "Irec\u00ea": {
                "lat": -11.3039,
                "lng": -42.8558
            },
            "Pacajus": {
                "lat": -4.1667,
                "lng": -38.4667
            },
            "Santa Isabel do Par\u00e1": {
                "lat": -1.2667,
                "lng": -48.1833
            },
            "Russas": {
                "lat": -4.94,
                "lng": -37.9758
            },
            "Amparo": {
                "lat": -22.7031,
                "lng": -46.765
            },
            "Rio do Sul": {
                "lat": -27.2167,
                "lng": -49.65
            },
            "Gaspar": {
                "lat": -26.9333,
                "lng": -48.9667
            },
            "Esmeraldas": {
                "lat": -19.7628,
                "lng": -44.3139
            },
            "Alegrete": {
                "lat": -29.7838,
                "lng": -55.791
            },
            "Candeias": {
                "lat": -20.7669,
                "lng": -45.2758
            },
            "Santo Ant\u00f4nio do Descoberto": {
                "lat": -15.9436,
                "lng": -48.2594
            },
            "Casa Nova": {
                "lat": -9.1619,
                "lng": -40.9708
            },
            "Ararangu\u00e1": {
                "lat": -28.935,
                "lng": -49.4858
            },
            "Goianira": {
                "lat": -16.5067,
                "lng": -49.4239
            },
            "Jabuticabal": {
                "lat": -21.2547,
                "lng": -48.3222
            },
            "Castro": {
                "lat": -24.7908,
                "lng": -50.0119
            },
            "Cajamar": {
                "lat": -23.3561,
                "lng": -46.8769
            },
            "S\u00e3o Sebasti\u00e3o do Para\u00edso": {
                "lat": -20.9169,
                "lng": -46.9908
            },
            "Palmeira dos \u00cdndios": {
                "lat": -9.4069,
                "lng": -36.6278
            },
            "Indaial": {
                "lat": -26.8978,
                "lng": -49.2319
            },
            "Dias d\u2019\u00c1vila": {
                "lat": -12.6128,
                "lng": -38.2969
            },
            "Campo Formoso": {
                "lat": -10.5089,
                "lng": -40.3208
            },
            "Fernand\u00f3polis": {
                "lat": -20.2839,
                "lng": -50.2458
            },
            "Goian\u00e9sia": {
                "lat": -15.3239,
                "lng": -49.1189
            },
            "Coari": {
                "lat": -4.085,
                "lng": -63.1408
            },
            "Capanema": {
                "lat": -25.6719,
                "lng": -53.8089
            },
            "Brumado": {
                "lat": -14.2036,
                "lng": -41.6653
            },
            "Mineiros": {
                "lat": -17.5703,
                "lng": -52.5553
            },
            "Graja\u00fa": {
                "lat": -5.8189,
                "lng": -46.1389
            },
            "Farroupilha": {
                "lat": -29.2333,
                "lng": -51.35
            },
            "Bigua\u00e7u": {
                "lat": -27.5,
                "lng": -48.6667
            },
            "Alenquer": {
                "lat": -1.9448,
                "lng": -54.732
            },
            "Barra do Gar\u00e7as": {
                "lat": -15.89,
                "lng": -52.2567
            },
            "Peru\u00edbe": {
                "lat": -24.3231,
                "lng": -47.0022
            },
            "Mococa": {
                "lat": -21.4678,
                "lng": -47.0047
            },
            "Arcoverde": {
                "lat": -8.4189,
                "lng": -37.0539
            },
            "Ven\u00e2ncio Aires": {
                "lat": -29.6,
                "lng": -52.1833
            },
            "Oriximin\u00e1": {
                "lat": -1.7658,
                "lng": -55.8658
            },
            "Janu\u00e1ria": {
                "lat": -15.4878,
                "lng": -44.3619
            },
            "Concei\u00e7\u00e3o do Coit\u00e9": {
                "lat": -11.55,
                "lng": -39.2667
            },
            "Formiga": {
                "lat": -20.4615,
                "lng": -45.4323
            },
            "Pesqueira": {
                "lat": -8.3617,
                "lng": -36.6947
            },
            "Rol\u00e2ndia": {
                "lat": -23.3,
                "lng": -51.3667
            },
            "Itapema": {
                "lat": -27.09,
                "lng": -48.6108
            },
            "Tom\u00e9-A\u00e7u": {
                "lat": -2.4167,
                "lng": -48.15
            },
            "Sousa": {
                "lat": -6.7608,
                "lng": -38.2308
            },
            "Tabatinga": {
                "lat": -21.7167,
                "lng": -48.6875
            },
            "Embu-Gua\u00e7u": {
                "lat": -23.8319,
                "lng": -46.8117
            },
            "Cosm\u00f3polis": {
                "lat": -22.6458,
                "lng": -47.1961
            },
            "Jana\u00faba": {
                "lat": -15.8014,
                "lng": -43.2764
            },
            "Cabedelo": {
                "lat": -6.9808,
                "lng": -34.8339
            },
            "Len\u00e7\u00f3is Paulista": {
                "lat": -22.5986,
                "lng": -48.8003
            },
            "Itapetinga": {
                "lat": -15.2535,
                "lng": -40.2555
            },
            "Surubim": {
                "lat": -7.8333,
                "lng": -35.75
            },
            "Rio Negro": {
                "lat": -26.1,
                "lng": -49.79
            },
            "Barreirinhas": {
                "lat": -2.75,
                "lng": -42.8333
            },
            "Bom Jesus da Lapa": {
                "lat": -13.255,
                "lng": -43.4178
            },
            "Piripiri": {
                "lat": -4.2733,
                "lng": -41.7769
            },
            "S\u00e3o F\u00e9lix do Xingu": {
                "lat": -6.6333,
                "lng": -51.9833
            },
            "Ouricuri": {
                "lat": -7.8828,
                "lng": -40.0819
            },
            "Est\u00e2ncia": {
                "lat": -11.2678,
                "lng": -37.4378
            },
            "Itaberaba": {
                "lat": -12.5333,
                "lng": -40.3
            },
            "Igarap\u00e9-Miri": {
                "lat": -1.9833,
                "lng": -48.9667
            },
            "Acara\u00fa": {
                "lat": -2.8858,
                "lng": -40.1186
            },
            "Itamaraju": {
                "lat": -17.0667,
                "lng": -39.5333
            },
            "Porto Nacional": {
                "lat": -10.7078,
                "lng": -48.4169
            },
            "Bertioga": {
                "lat": -23.8547,
                "lng": -46.1389
            },
            "Vacaria": {
                "lat": -28.5122,
                "lng": -50.9339
            },
            "Tup\u00e3": {
                "lat": -21.9347,
                "lng": -50.5136
            },
            "Camocim": {
                "lat": -2.9019,
                "lng": -40.8408
            },
            "Penedo": {
                "lat": -10.29,
                "lng": -36.5858
            },
            "Cap\u00e3o da Canoa": {
                "lat": -29.7667,
                "lng": -50.0167
            },
            "Benevides": {
                "lat": -1.3667,
                "lng": -48.25
            },
            "Mirassol": {
                "lat": -20.8189,
                "lng": -49.5208
            },
            "Cajazeiras": {
                "lat": -6.89,
                "lng": -38.5619
            },
            "Batatais": {
                "lat": -20.8911,
                "lng": -47.585
            },
            "Campo Bom": {
                "lat": -29.6789,
                "lng": -51.0528
            },
            "Itupeva": {
                "lat": -23.1531,
                "lng": -47.0578
            },
            "Ic\u00f3": {
                "lat": -6.4008,
                "lng": -38.8619
            },
            "Portel": {
                "lat": -1.9358,
                "lng": -50.8208
            },
            "Car\u00e0zinho": {
                "lat": -28.2839,
                "lng": -52.7858
            },
            "Cristalina": {
                "lat": -16.7689,
                "lng": -47.6156
            },
            "Camaqu\u00e3": {
                "lat": -30.85,
                "lng": -51.8167
            },
            "Boituva": {
                "lat": -23.2833,
                "lng": -47.6722
            },
            "Nova Odessa": {
                "lat": -22.78,
                "lng": -47.2989
            },
            "Mongagu\u00e1": {
                "lat": -24.0875,
                "lng": -46.6289
            },
            "S\u00e3o Miguel dos Campos": {
                "lat": -9.7808,
                "lng": -36.0939
            },
            "Morada Nova": {
                "lat": -5.1069,
                "lng": -38.3728
            },
            "Santo Amaro": {
                "lat": -12.5469,
                "lng": -38.7119
            },
            "Bezerros": {
                "lat": -8.2353,
                "lng": -35.7528
            },
            "Pen\u00e1polis": {
                "lat": -21.4197,
                "lng": -50.0775
            },
            "Extremoz": {
                "lat": -5.7,
                "lng": -35.3
            },
            "Euclides da Cunha": {
                "lat": -10.5078,
                "lng": -39.0139
            },
            "Salgueiro": {
                "lat": -8.0742,
                "lng": -39.1192
            },
            "Barbalha": {
                "lat": -7.305,
                "lng": -39.3019
            },
            "Tau\u00e1": {
                "lat": -6.0028,
                "lng": -40.2928
            },
            "Mau\u00e9s": {
                "lat": -3.3836,
                "lng": -57.7186
            },
            "Caic\u00f3": {
                "lat": -6.4578,
                "lng": -37.0978
            },
            "Iranduba": {
                "lat": -3.285,
                "lng": -60.1858
            },
            "Ibitinga": {
                "lat": -21.7578,
                "lng": -48.8289
            },
            "Itapecuru Mirim": {
                "lat": -3.3928,
                "lng": -44.3589
            },
            "Cruz das Almas": {
                "lat": -12.67,
                "lng": -39.1019
            },
            "Floriano": {
                "lat": -6.7669,
                "lng": -43.0228
            },
            "S\u00e3o Borja": {
                "lat": -28.6602,
                "lng": -56.0038
            },
            "Monte Alegre": {
                "lat": -6.0678,
                "lng": -35.3319
            },
            "Registro": {
                "lat": -24.4875,
                "lng": -47.8436
            },
            "Escada": {
                "lat": -8.3592,
                "lng": -35.2236
            },
            "Andradina": {
                "lat": -20.8958,
                "lng": -51.3789
            },
            "Vi\u00e7osa do Cear\u00e1": {
                "lat": -3.5667,
                "lng": -41.0833
            },
            "Coroat\u00e1": {
                "lat": -4.13,
                "lng": -44.1239
            },
            "Limoeiro do Norte": {
                "lat": -5.1333,
                "lng": -38.0833
            },
            "Montenegro": {
                "lat": -29.6889,
                "lng": -51.4608
            },
            "Uni\u00e3o dos Palmares": {
                "lat": -9.1628,
                "lng": -36.0319
            },
            "Irati": {
                "lat": -25.4697,
                "lng": -50.6547
            },
            "Cruz Alta": {
                "lat": -28.6386,
                "lng": -53.6064
            },
            "Parob\u00e9": {
                "lat": -29.6289,
                "lng": -50.835
            },
            "Pedro Leopoldo": {
                "lat": -19.6178,
                "lng": -44.0428
            },
            "Jaguari\u00fana": {
                "lat": -22.68,
                "lng": -46.99
            },
            "Viseu": {
                "lat": -1.1969,
                "lng": -46.14
            },
            "Alta Floresta": {
                "lat": -9.8861,
                "lng": -56.0872
            },
            "Frutal": {
                "lat": -20.0167,
                "lng": -48.9333
            },
            "S\u00e3o Gabriel": {
                "lat": -11.2289,
                "lng": -41.9119
            },
            "Trairi": {
                "lat": -3.2778,
                "lng": -39.2689
            },
            "Santa Isabel": {
                "lat": -23.3158,
                "lng": -46.2208
            },
            "Taquara": {
                "lat": -29.6506,
                "lng": -50.7806
            },
            "Guarabira": {
                "lat": -6.855,
                "lng": -35.49
            },
            "Humait\u00e1": {
                "lat": -7.5061,
                "lng": -63.0208
            },
            "Ponte Nova": {
                "lat": -20.4158,
                "lng": -42.9089
            },
            "Acar\u00e1": {
                "lat": -1.9608,
                "lng": -48.1969
            },
            "Coruripe": {
                "lat": -10.1258,
                "lng": -36.1758
            },
            "I\u00e7ara": {
                "lat": -28.7133,
                "lng": -49.3
            },
            "Cachoeiras de Macacu": {
                "lat": -22.4628,
                "lng": -42.6528
            },
            "Ipir\u00e1": {
                "lat": -12.1583,
                "lng": -39.7389
            },
            "Pau d\u2019Alho": {
                "lat": -7.8969,
                "lng": -35.18
            },
            "Pirapora": {
                "lat": -17.3478,
                "lng": -44.9436
            },
            "Mafra": {
                "lat": -26.1167,
                "lng": -49.8
            },
            "Limoeiro": {
                "lat": -7.875,
                "lng": -35.45
            },
            "Porto Feliz": {
                "lat": -23.2147,
                "lng": -47.5239
            },
            "Rolim de Moura": {
                "lat": -11.7256,
                "lng": -61.7781
            },
            "Videira": {
                "lat": -27.0078,
                "lng": -51.1519
            },
            "Monte Mor": {
                "lat": -22.9467,
                "lng": -47.315
            },
            "Moreno": {
                "lat": -8.1186,
                "lng": -35.0922
            },
            "Tr\u00eas Pontas": {
                "lat": -21.3703,
                "lng": -45.5122
            },
            "Ibipor\u00e3": {
                "lat": -23.2833,
                "lng": -51.05
            },
            "Ol\u00edmpia": {
                "lat": -20.7372,
                "lng": -48.9147
            },
            "Uni\u00e3o da Vit\u00f3ria": {
                "lat": -26.2167,
                "lng": -51.0833
            },
            "Canoinhas": {
                "lat": -26.1769,
                "lng": -50.39
            },
            "Catu": {
                "lat": -12.3497,
                "lng": -38.3831
            },
            "Piedade": {
                "lat": -23.7119,
                "lng": -47.4278
            },
            "Palmares": {
                "lat": -8.6828,
                "lng": -35.5919
            },
            "Capit\u00e3o Po\u00e7o": {
                "lat": -1.75,
                "lng": -47.0833
            },
            "Medianeira": {
                "lat": -25.295,
                "lng": -54.0939
            },
            "Ribeira do Pombal": {
                "lat": -10.8333,
                "lng": -38.5333
            },
            "Manicor\u00e9": {
                "lat": -5.8089,
                "lng": -61.3
            },
            "S\u00e3o Francisco do Sul": {
                "lat": -26.2428,
                "lng": -48.6378
            },
            "Marechal C\u00e2ndido Rondon": {
                "lat": -24.5667,
                "lng": -54.0667
            },
            "Extrema": {
                "lat": -22.855,
                "lng": -46.3178
            },
            "Tut\u00f3ia": {
                "lat": -2.75,
                "lng": -42.2667
            },
            "Granja": {
                "lat": -3.12,
                "lng": -40.8258
            },
            "Itabirito": {
                "lat": -20.253,
                "lng": -43.801
            },
            "Inhumas": {
                "lat": -16.355,
                "lng": -49.4931
            },
            "A\u00e7u": {
                "lat": -5.5769,
                "lng": -36.9089
            },
            "Capivari": {
                "lat": -22.995,
                "lng": -47.5078
            },
            "Rondon do Par\u00e1": {
                "lat": -4.7758,
                "lng": -48.0669
            },
            "Beberibe": {
                "lat": -4.1833,
                "lng": -38.1333
            },
            "Itapag\u00e9": {
                "lat": -3.6833,
                "lng": -39.5833
            },
            "S\u00e3o Miguel do Guam\u00e1": {
                "lat": -1.6167,
                "lng": -47.45
            },
            "Xinguara": {
                "lat": -7.095,
                "lng": -49.9458
            },
            "Congonhas": {
                "lat": -20.5,
                "lng": -43.8578
            },
            "Timba\u00faba": {
                "lat": -7.5108,
                "lng": -35.3178
            },
            "S\u00e3o Francisco": {
                "lat": -5.1228,
                "lng": -47.3858
            },
            "Lagoa da Prata": {
                "lat": -20.0167,
                "lng": -45.5333
            },
            "Paracambi": {
                "lat": -22.6108,
                "lng": -43.7089
            },
            "P\u00f4rto Ferreira": {
                "lat": -21.8539,
                "lng": -47.4789
            },
            "Tramanda\u00ed": {
                "lat": -29.9667,
                "lng": -50.1333
            },
            "Imbituba": {
                "lat": -28.2333,
                "lng": -48.6667
            },
            "Marechal Deodoro": {
                "lat": -9.71,
                "lng": -35.895
            },
            "Para\u00edso do Tocantins": {
                "lat": -10.1833,
                "lng": -48.8
            },
            "Campo Belo": {
                "lat": -20.8969,
                "lng": -45.2769
            },
            "Santo Est\u00eav\u00e3o": {
                "lat": -12.43,
                "lng": -39.2508
            },
            "Delmiro Gouveia": {
                "lat": -9.3858,
                "lng": -37.9958
            },
            "Taquaritinga": {
                "lat": -21.4058,
                "lng": -48.505
            },
            "\u00d3bidos": {
                "lat": -1.9,
                "lng": -55.5167
            },
            "S\u00e3o Jos\u00e9 do Rio Pardo": {
                "lat": -21.5956,
                "lng": -46.8886
            },
            "Bu\u00edque": {
                "lat": -8.6167,
                "lng": -37.15
            },
            "Pontes e Lacerda": {
                "lat": -15.2258,
                "lng": -59.335
            },
            "Caetit\u00e9": {
                "lat": -14.0689,
                "lng": -42.475
            },
            "S\u00e3o Gabriel da Cachoeira": {
                "lat": -0.1192,
                "lng": -67.0828
            },
            "Guapimirim": {
                "lat": -22.5369,
                "lng": -42.8153
            },
            "Bai\u00e3o": {
                "lat": -2.7908,
                "lng": -49.6719
            },
            "Xanxer\u00ea": {
                "lat": -26.8769,
                "lng": -52.4039
            },
            "Tijucas": {
                "lat": -27.2414,
                "lng": -48.6336
            },
            "Morrinhos": {
                "lat": -3.2289,
                "lng": -40.125
            },
            "Sap\u00e9": {
                "lat": -7.095,
                "lng": -35.2328
            },
            "Leopoldina": {
                "lat": -21.5319,
                "lng": -42.6428
            },
            "Barra": {
                "lat": -11.0889,
                "lng": -43.1419
            },
            "Brejo Santo": {
                "lat": -7.4833,
                "lng": -39.0
            },
            "Guaxup\u00e9": {
                "lat": -21.305,
                "lng": -46.7128
            },
            "Tobias Barreto": {
                "lat": -11.1839,
                "lng": -37.9978
            },
            "Juruti": {
                "lat": -2.1522,
                "lng": -56.0922
            },
            "Pontal": {
                "lat": -21.0225,
                "lng": -48.0372
            },
            "Campos do Jord\u00e3o": {
                "lat": -22.7389,
                "lng": -45.5908
            },
            "Vigia": {
                "lat": -0.8583,
                "lng": -48.1417
            },
            "Quirin\u00f3polis": {
                "lat": -18.4481,
                "lng": -50.4464
            },
            "Est\u00e2ncia Velha": {
                "lat": -29.65,
                "lng": -51.1833
            },
            "Jaru": {
                "lat": -10.4389,
                "lng": -62.4664
            },
            "Navira\u00ed": {
                "lat": -23.065,
                "lng": -54.1908
            },
            "Cabre\u00fava": {
                "lat": -23.3075,
                "lng": -47.1331
            },
            "Boa Viagem": {
                "lat": -5.1278,
                "lng": -39.7319
            },
            "Vargem Grande Paulista": {
                "lat": -23.6028,
                "lng": -47.0258
            },
            "Artur Nogueira": {
                "lat": -22.5731,
                "lng": -47.1725
            },
            "Itarar\u00e9": {
                "lat": -24.1125,
                "lng": -49.3317
            },
            "Louveira": {
                "lat": -23.0864,
                "lng": -46.9506
            },
            "Cerquilho Velho": {
                "lat": -23.165,
                "lng": -47.7436
            },
            "Itupiranga": {
                "lat": -5.15,
                "lng": -49.3333
            },
            "Cangu\u00e7u": {
                "lat": -31.4,
                "lng": -52.6833
            },
            "Coelho Neto": {
                "lat": -4.2569,
                "lng": -43.0128
            },
            "Monte Alto": {
                "lat": -21.2611,
                "lng": -48.4964
            },
            "S\u00e3o Bento do Una": {
                "lat": -8.5228,
                "lng": -36.4439
            },
            "Prudent\u00f3polis": {
                "lat": -25.2128,
                "lng": -50.9778
            },
            "Santiago": {
                "lat": -29.1917,
                "lng": -54.8672
            },
            "Jales": {
                "lat": -20.2689,
                "lng": -50.5458
            },
            "Tucano": {
                "lat": -10.9628,
                "lng": -38.7869
            },
            "Brejo da Madre de Deus": {
                "lat": -8.15,
                "lng": -36.3667
            },
            "S\u00e3o Joaquim da Barra": {
                "lat": -20.5808,
                "lng": -47.855
            },
            "Pedreira": {
                "lat": -22.7419,
                "lng": -46.9014
            },
            "Araci": {
                "lat": -11.3328,
                "lng": -38.9669
            },
            "Bocai\u00fava": {
                "lat": -17.1078,
                "lng": -43.815
            },
            "Aquidauana": {
                "lat": -20.4708,
                "lng": -55.7869
            },
            "Barras": {
                "lat": -4.2469,
                "lng": -42.2958
            },
            "Monte Santo": {
                "lat": -10.4378,
                "lng": -39.3328
            },
            "Trememb\u00e9": {
                "lat": -22.9597,
                "lng": -45.5403
            },
            "Diamantina": {
                "lat": -18.2489,
                "lng": -43.6
            },
            "Monte Carmelo": {
                "lat": -18.7267,
                "lng": -47.4994
            },
            "S\u00e3o Benedito": {
                "lat": -4.0489,
                "lng": -40.865
            },
            "Altos": {
                "lat": -5.0389,
                "lng": -42.4608
            },
            "Os\u00f3rio": {
                "lat": -29.8942,
                "lng": -50.2652
            },
            "S\u00e3o Jos\u00e9 de Mipibu": {
                "lat": -6.0833,
                "lng": -35.25
            },
            "Sidrol\u00e2ndia": {
                "lat": -20.9319,
                "lng": -54.9608
            },
            "Cap\u00e3o Bonito": {
                "lat": -24.0058,
                "lng": -48.3494
            },
            "Dracena": {
                "lat": -21.4828,
                "lng": -51.5328
            },
            "Campo Maior": {
                "lat": -4.8333,
                "lng": -42.1736
            },
            "Po\u00e7\u00f5es": {
                "lat": -14.5167,
                "lng": -40.35
            },
            "Jo\u00e3o Pinheiro": {
                "lat": -17.7442,
                "lng": -46.1739
            },
            "Araioses": {
                "lat": -2.89,
                "lng": -41.9028
            },
            "Guaramirim": {
                "lat": -26.45,
                "lng": -49.0
            },
            "Santa Cruz do Rio Pardo": {
                "lat": -22.8989,
                "lng": -49.6328
            },
            "S\u00e3o Bento": {
                "lat": -6.4858,
                "lng": -37.4508
            },
            "Santana do Ipanema": {
                "lat": -9.3778,
                "lng": -37.245
            },
            "Seabra": {
                "lat": -12.4189,
                "lng": -41.77
            },
            "Uni\u00e3o": {
                "lat": -4.5858,
                "lng": -42.8639
            },
            "Casimiro de Abreu": {
                "lat": -22.4808,
                "lng": -42.2039
            },
            "Eus\u00e9bio": {
                "lat": -3.89,
                "lng": -38.4508
            },
            "Nova Ven\u00e9cia": {
                "lat": -18.7108,
                "lng": -40.4008
            },
            "Mairinque": {
                "lat": -23.5464,
                "lng": -47.1836
            },
            "Jaguaquara": {
                "lat": -13.5333,
                "lng": -39.9667
            },
            "Paragua\u00e7u Paulista": {
                "lat": -22.4197,
                "lng": -50.5797
            },
            "Campo Novo do Parecis": {
                "lat": -13.675,
                "lng": -57.8919
            },
            "Ju\u00edna": {
                "lat": -11.3778,
                "lng": -58.7408
            },
            "Igarap\u00e9": {
                "lat": -20.07,
                "lng": -44.3019
            },
            "Porangatu": {
                "lat": -13.4319,
                "lng": -49.1306
            },
            "Mauriti": {
                "lat": -7.3833,
                "lng": -38.7667
            },
            "Canela": {
                "lat": -29.3617,
                "lng": -50.8156
            },
            "L\u00e1brea": {
                "lat": -7.2589,
                "lng": -64.7978
            },
            "Barra Velha": {
                "lat": -26.6156,
                "lng": -48.6847
            },
            "Jaragu\u00e1": {
                "lat": -15.7397,
                "lng": -49.3347
            },
            "Corn\u00e9lio Proc\u00f3pio": {
                "lat": -23.1333,
                "lng": -50.65
            },
            "Araquari": {
                "lat": -26.3833,
                "lng": -48.7167
            },
            "Presidente Dutra": {
                "lat": -11.2958,
                "lng": -41.9869
            },
            "Marau": {
                "lat": -28.4489,
                "lng": -52.2
            },
            "Maracaju": {
                "lat": -21.6139,
                "lng": -55.1678
            },
            "Caet\u00e9": {
                "lat": -19.88,
                "lng": -43.67
            },
            "Lapa": {
                "lat": -25.77,
                "lng": -49.7158
            },
            "Timb\u00f3": {
                "lat": -26.8228,
                "lng": -49.2719
            },
            "Acopiara": {
                "lat": -6.1,
                "lng": -39.45
            },
            "Pederneiras": {
                "lat": -22.3517,
                "lng": -48.775
            },
            "Dois Vizinhos": {
                "lat": -26.0833,
                "lng": -53.05
            },
            "Santana do Para\u00edso": {
                "lat": -19.3639,
                "lng": -42.5689
            },
            "S\u00e3o Louren\u00e7o": {
                "lat": -22.1167,
                "lng": -45.05
            },
            "Xique-Xique": {
                "lat": -10.8303,
                "lng": -42.7308
            },
            "Itabera\u00ed": {
                "lat": -16.0083,
                "lng": -49.7961
            },
            "Concei\u00e7\u00e3o do Araguaia": {
                "lat": -7.5539,
                "lng": -49.7128
            },
            "Mamanguape": {
                "lat": -6.8375,
                "lng": -35.1267
            },
            "Augusto Correa": {
                "lat": -1.0272,
                "lng": -46.6446
            },
            "Gar\u00e7a": {
                "lat": -22.2153,
                "lng": -49.6511
            },
            "S\u00e3o Sebasti\u00e3o do Pass\u00e9": {
                "lat": -12.5128,
                "lng": -38.495
            },
            "Santo Ant\u00f4nio da Platina": {
                "lat": -23.2667,
                "lng": -50.1
            },
            "S\u00e3o Miguel d\u2019Oeste": {
                "lat": -26.7308,
                "lng": -53.5153
            },
            "Bom Conselho": {
                "lat": -9.17,
                "lng": -36.68
            },
            "Rio Grande da Serra": {
                "lat": -23.7439,
                "lng": -46.3978
            },
            "Livramento de Nossa Senhora": {
                "lat": -13.6428,
                "lng": -41.8408
            },
            "Salto de Pirapora": {
                "lat": -23.6489,
                "lng": -47.5733
            },
            "Uruar\u00e1": {
                "lat": -3.7178,
                "lng": -53.7369
            },
            "Presidente Epit\u00e1cio": {
                "lat": -21.7647,
                "lng": -52.1053
            },
            "Panambi": {
                "lat": -28.2928,
                "lng": -53.5019
            },
            "Tarauac\u00e1": {
                "lat": -8.1608,
                "lng": -70.7658
            },
            "Vargem Grande": {
                "lat": -3.5431,
                "lng": -43.9158
            },
            "Vargem Grande do Sul": {
                "lat": -21.8328,
                "lng": -46.8922
            },
            "Santo Ant\u00f4nio": {
                "lat": -6.311,
                "lng": -35.48
            },
            "Laguna": {
                "lat": -28.4825,
                "lng": -48.7808
            },
            "Barreiros": {
                "lat": -8.8178,
                "lng": -35.1858
            },
            "Itarema": {
                "lat": -2.9233,
                "lng": -39.9144
            },
            "Orl\u00e2ndia": {
                "lat": -20.7203,
                "lng": -47.8867
            },
            "Sim\u00e3o Dias": {
                "lat": -10.7378,
                "lng": -37.8108
            },
            "Vera Cruz": {
                "lat": -22.2197,
                "lng": -49.8194
            },
            "Mata de S\u00e3o Jo\u00e3o": {
                "lat": -12.5305,
                "lng": -38.2996
            },
            "Jos\u00e9 de Freitas": {
                "lat": -4.75,
                "lng": -42.5833
            },
            "Urua\u00e7u": {
                "lat": -14.5194,
                "lng": -49.145
            },
            "S\u00e3o Mateus do Sul": {
                "lat": -25.8667,
                "lng": -50.3833
            },
            "Amontada": {
                "lat": -3.3833,
                "lng": -39.8333
            },
            "Para\u00edba do Sul": {
                "lat": -22.1619,
                "lng": -43.2928
            },
            "Guaratuba": {
                "lat": -25.9,
                "lng": -48.5667
            },
            "Guaraciaba do Norte": {
                "lat": -4.1667,
                "lng": -40.7667
            },
            "S\u00e3o Louren\u00e7o do Sul": {
                "lat": -31.3653,
                "lng": -51.9783
            },
            "Maca\u00fabas": {
                "lat": -13.0189,
                "lng": -42.6989
            },
            "Marialva": {
                "lat": -23.4667,
                "lng": -51.7
            },
            "Jardin\u00f3polis": {
                "lat": -21.0178,
                "lng": -47.7639
            },
            "Pai\u00e7andu": {
                "lat": -23.4556,
                "lng": -52.0417
            },
            "T\u00f4rres": {
                "lat": -29.3267,
                "lng": -49.7675
            },
            "Salinas": {
                "lat": -16.1185,
                "lng": -42.174
            },
            "Santa Helena": {
                "lat": -24.86,
                "lng": -54.3328
            },
            "\u00c1guas Belas": {
                "lat": -9.1167,
                "lng": -37.1167
            },
            "Barra dos Coqueiros": {
                "lat": -10.9089,
                "lng": -37.0389
            },
            "Arcos": {
                "lat": -20.2914,
                "lng": -45.5397
            },
            "Sena Madureira": {
                "lat": -9.0658,
                "lng": -68.6569
            },
            "Santo Ant\u00f4nio de P\u00e1dua": {
                "lat": -21.5389,
                "lng": -42.18
            },
            "Currais Novos": {
                "lat": -6.25,
                "lng": -36.5167
            },
            "Mangaratiba": {
                "lat": -22.96,
                "lng": -44.0408
            },
            "Nossa Senhora da Gl\u00f3ria": {
                "lat": -10.2178,
                "lng": -37.42
            },
            "Toritama": {
                "lat": -7.9822,
                "lng": -36.0508
            },
            "Esperantina": {
                "lat": -3.9019,
                "lng": -42.2339
            },
            "Parana\u00edba": {
                "lat": -19.6769,
                "lng": -51.1908
            },
            "Salin\u00f3polis": {
                "lat": -0.6276,
                "lng": -47.3456
            },
            "Muan\u00e1": {
                "lat": -1.5278,
                "lng": -49.2169
            },
            "Promiss\u00e3o": {
                "lat": -21.5369,
                "lng": -49.8583
            },
            "Ipia\u00fa": {
                "lat": -14.1369,
                "lng": -39.7339
            },
            "Itabaianinha": {
                "lat": -11.2739,
                "lng": -37.79
            },
            "Nanuque": {
                "lat": -17.8433,
                "lng": -40.3517
            },
            "Barra de S\u00e3o Francisco": {
                "lat": -18.755,
                "lng": -40.8908
            },
            "Santa Rita do Sapuca\u00ed": {
                "lat": -22.2519,
                "lng": -45.7028
            },
            "P\u00f4rto de Moz": {
                "lat": -1.75,
                "lng": -52.2333
            },
            "Remanso": {
                "lat": -9.6219,
                "lng": -42.0808
            },
            "Santa Maria da Boa Vista": {
                "lat": -8.8089,
                "lng": -39.825
            },
            "Andradas": {
                "lat": -22.0678,
                "lng": -46.5689
            },
            "Guariba": {
                "lat": -21.36,
                "lng": -48.2283
            },
            "Almenara": {
                "lat": -16.1839,
                "lng": -40.6939
            },
            "Curu\u00e7\u00e1": {
                "lat": -0.7339,
                "lng": -47.855
            },
            "Colinas": {
                "lat": -6.0258,
                "lng": -44.2489
            },
            "Autazes": {
                "lat": -3.58,
                "lng": -59.1308
            },
            "Afogados da Ingazeira": {
                "lat": -7.7508,
                "lng": -37.6389
            },
            "Boa Esperan\u00e7a": {
                "lat": -18.54,
                "lng": -40.2958
            },
            "Pedra Branca": {
                "lat": -5.4539,
                "lng": -39.7169
            },
            "Santa Quit\u00e9ria": {
                "lat": -4.3364,
                "lng": -40.1536
            },
            "Gramado": {
                "lat": -29.4,
                "lng": -50.8667
            },
            "Socorro": {
                "lat": -22.5914,
                "lng": -46.5289
            },
            "Curitibanos": {
                "lat": -27.3,
                "lng": -50.575
            },
            "Arma\u00e7\u00e3o dos B\u00fazios": {
                "lat": -22.7469,
                "lng": -41.8819
            },
            "Pinhal": {
                "lat": -22.1908,
                "lng": -46.7408
            },
            "Capelinha": {
                "lat": -17.6908,
                "lng": -42.5158
            },
            "Tucum\u00e3": {
                "lat": -6.7519,
                "lng": -51.1539
            },
            "S\u00e3o Gon\u00e7alo dos Campos": {
                "lat": -12.4328,
                "lng": -38.9669
            },
            "Nova Vi\u00e7osa": {
                "lat": -17.8833,
                "lng": -39.3667
            },
            "It\u00e1polis": {
                "lat": -21.5958,
                "lng": -48.8128
            },
            "Presidente Venceslau": {
                "lat": -21.8761,
                "lng": -51.8439
            },
            "Guajar\u00e1-Mirim": {
                "lat": -10.7828,
                "lng": -65.3394
            },
            "Amamba\u00ed": {
                "lat": -23.1039,
                "lng": -55.2258
            },
            "Jacar\u00e8zinho": {
                "lat": -23.1608,
                "lng": -49.9689
            },
            "Gua\u00edra": {
                "lat": -24.08,
                "lng": -54.2558
            },
            "Oliveira": {
                "lat": -20.6958,
                "lng": -44.8269
            },
            "Matinhos": {
                "lat": -25.8167,
                "lng": -48.5333
            },
            "Rio Negrinho": {
                "lat": -26.25,
                "lng": -49.5167
            },
            "Visconde do Rio Branco": {
                "lat": -21.0103,
                "lng": -42.8406
            },
            "V\u00e1rzea Alegre": {
                "lat": -6.7889,
                "lng": -39.2958
            },
            "S\u00e3o Fid\u00e9lis": {
                "lat": -21.6458,
                "lng": -41.7469
            },
            "S\u00e3o Raimundo Nonato": {
                "lat": -9.015,
                "lng": -42.6989
            },
            "Brumadinho": {
                "lat": -20.1428,
                "lng": -44.2
            },
            "S\u00e3o Mateus do Maranh\u00e3o": {
                "lat": -4.0333,
                "lng": -44.4667
            },
            "Santa Helena de Goi\u00e1s": {
                "lat": -17.7214,
                "lng": -50.6036
            },
            "Campina Grande do Sul": {
                "lat": -25.3058,
                "lng": -49.055
            },
            "Penalva": {
                "lat": -3.3,
                "lng": -45.1667
            },
            "Ouro Branco": {
                "lat": -9.1667,
                "lng": -37.3567
            },
            "Paracuru": {
                "lat": -3.4,
                "lng": -39.0667
            },
            "Santa Maria da Vit\u00f3ria": {
                "lat": -13.4,
                "lng": -44.2
            },
            "Ros\u00e1rio": {
                "lat": -2.9339,
                "lng": -44.235
            },
            "Itaitinga": {
                "lat": -3.9667,
                "lng": -38.5167
            },
            "Novo Horizonte": {
                "lat": -21.3356,
                "lng": -49.2208
            },
            "Iturama": {
                "lat": -19.7297,
                "lng": -50.1961
            },
            "Pitangueiras": {
                "lat": -21.0094,
                "lng": -48.2217
            },
            "Am\u00e9rico Brasiliense": {
                "lat": -21.7361,
                "lng": -48.1114
            },
            "Oeiras": {
                "lat": -7.0167,
                "lng": -42.1333
            },
            "Matozinhos": {
                "lat": -19.5578,
                "lng": -44.0808
            },
            "Entre Rios": {
                "lat": -11.9419,
                "lng": -38.0839
            },
            "Mucuri": {
                "lat": -18.0858,
                "lng": -39.5508
            },
            "Pedro II": {
                "lat": -4.4167,
                "lng": -41.4667
            },
            "Mateus Leme": {
                "lat": -19.9858,
                "lng": -44.4278
            },
            "Santaluz": {
                "lat": -11.2558,
                "lng": -39.375
            },
            "Pentecoste": {
                "lat": -3.8,
                "lng": -39.2833
            },
            "Afu\u00e1": {
                "lat": -0.1569,
                "lng": -50.3869
            },
            "Momba\u00e7a": {
                "lat": -5.7428,
                "lng": -39.6278
            },
            "S\u00e3o Francisco do Conde": {
                "lat": -12.65,
                "lng": -38.6833
            },
            "Jacund\u00e1": {
                "lat": -4.4508,
                "lng": -49.1158
            },
            "Cust\u00f3dia": {
                "lat": -8.0875,
                "lng": -37.6431
            },
            "Massap\u00ea": {
                "lat": -3.5228,
                "lng": -40.3428
            },
            "Tiet\u00ea": {
                "lat": -23.1019,
                "lng": -47.7147
            },
            "Benjamin Constant": {
                "lat": -4.3828,
                "lng": -70.0308
            },
            "Bom Jardim": {
                "lat": -22.1519,
                "lng": -42.4189
            },
            "Jeremoabo": {
                "lat": -10.0669,
                "lng": -38.35
            },
            "Rio Brilhante": {
                "lat": -21.8019,
                "lng": -54.5458
            },
            "Sirinha\u00e9m": {
                "lat": -8.5947,
                "lng": -35.1139
            },
            "Rio Branco do Sul": {
                "lat": -25.1667,
                "lng": -49.3
            },
            "Paraty": {
                "lat": -23.2194,
                "lng": -44.7147
            },
            "Atalaia": {
                "lat": -9.5019,
                "lng": -36.0228
            },
            "Turia\u00e7u": {
                "lat": -1.6628,
                "lng": -45.3719
            },
            "Itaqui": {
                "lat": -29.1167,
                "lng": -56.55
            },
            "Bonito": {
                "lat": -1.3628,
                "lng": -47.3069
            },
            "Amargosa": {
                "lat": -13.03,
                "lng": -39.605
            },
            "Cansan\u00e7\u00e3o": {
                "lat": -10.6708,
                "lng": -39.4978
            },
            "Porteirinha": {
                "lat": -15.7486,
                "lng": -43.0236
            },
            "Agudos": {
                "lat": -22.4739,
                "lng": -48.9836
            },
            "Santa Cruz": {
                "lat": -6.2289,
                "lng": -36.0228
            },
            "S\u00e3o Manuel": {
                "lat": -22.7308,
                "lng": -48.5708
            },
            "Amarante do Maranh\u00e3o": {
                "lat": -5.6,
                "lng": -46.75
            },
            "Dom Pedrito": {
                "lat": -30.967,
                "lng": -54.667
            },
            "Campos Novos": {
                "lat": -27.4019,
                "lng": -51.225
            },
            "Miss\u00e3o Velha": {
                "lat": -7.25,
                "lng": -39.1428
            },
            "Ipueiras": {
                "lat": -4.5431,
                "lng": -40.7167
            },
            "Mandaguari": {
                "lat": -23.5478,
                "lng": -51.6708
            },
            "Jos\u00e9 Bonif\u00e1cio": {
                "lat": -21.0528,
                "lng": -49.6878
            },
            "Ros\u00e1rio do Sul": {
                "lat": -30.25,
                "lng": -54.9167
            },
            "Lajedo": {
                "lat": -8.6639,
                "lng": -36.32
            },
            "S\u00e3o Jo\u00e3o da Barra": {
                "lat": -21.64,
                "lng": -41.0508
            },
            "Barra do Cho\u00e7a": {
                "lat": -14.8808,
                "lng": -40.5789
            },
            "Barra Bonita": {
                "lat": -22.4947,
                "lng": -48.5581
            },
            "Tuntum": {
                "lat": -5.2333,
                "lng": -44.65
            },
            "Aparecida": {
                "lat": -22.8472,
                "lng": -45.23
            },
            "Girau do Ponciano": {
                "lat": -9.8839,
                "lng": -36.8289
            },
            "Apodi": {
                "lat": -5.6639,
                "lng": -37.7989
            },
            "Piu\u00ed": {
                "lat": -20.465,
                "lng": -45.9578
            },
            "Maragogipe": {
                "lat": -12.7778,
                "lng": -38.9189
            },
            "Igarap\u00e9-A\u00e7u": {
                "lat": -1.1269,
                "lng": -47.6178
            },
            "Alian\u00e7a": {
                "lat": -7.6028,
                "lng": -35.2308
            },
            "Ipor\u00e1": {
                "lat": -16.4411,
                "lng": -51.1194
            },
            "Goiatuba": {
                "lat": -18.0111,
                "lng": -49.3569
            },
            "Bariri": {
                "lat": -22.0744,
                "lng": -48.7403
            },
            "B\u00f4ca do Acre": {
                "lat": -8.7519,
                "lng": -67.3978
            },
            "Feij\u00f3": {
                "lat": -8.1639,
                "lng": -70.3539
            },
            "Pilar": {
                "lat": -9.5972,
                "lng": -35.9567
            },
            "Rio Real": {
                "lat": -11.4667,
                "lng": -37.9333
            },
            "Pil\u00e3o Arcado": {
                "lat": -10.0028,
                "lng": -42.5039
            },
            "Concei\u00e7\u00e3o de Jacu\u00edpe": {
                "lat": -12.3269,
                "lng": -38.765
            },
            "Barra do Bugres": {
                "lat": -15.0728,
                "lng": -57.1808
            },
            "Baturit\u00e9": {
                "lat": -4.3289,
                "lng": -38.885
            },
            "Bom Jesus do Itabapoana": {
                "lat": -21.1339,
                "lng": -41.68
            },
            "Jaguaria\u00edva": {
                "lat": -24.25,
                "lng": -49.7
            },
            "Adamantina": {
                "lat": -21.6847,
                "lng": -51.0733
            },
            "Confresa": {
                "lat": -10.6439,
                "lng": -51.5689
            },
            "Ouro Preto d\u2019Oeste": {
                "lat": -10.7481,
                "lng": -62.2158
            },
            "Charqueadas": {
                "lat": -29.9667,
                "lng": -51.6333
            },
            "Palotina": {
                "lat": -24.2833,
                "lng": -53.8397
            },
            "Prado": {
                "lat": -17.3408,
                "lng": -39.2208
            },
            "Pimenta Bueno": {
                "lat": -11.6725,
                "lng": -61.1936
            },
            "Padre Bernardo": {
                "lat": -15.1655,
                "lng": -48.2903
            },
            "Niquel\u00e2ndia": {
                "lat": -14.4675,
                "lng": -48.4589
            },
            "Tamb\u00e9": {
                "lat": -7.4478,
                "lng": -35.2439
            },
            "Ilhabela": {
                "lat": -23.815,
                "lng": -45.3706
            },
            "Posse": {
                "lat": -14.0886,
                "lng": -46.355
            },
            "Juara": {
                "lat": -11.255,
                "lng": -57.52
            },
            "Agua\u00ed": {
                "lat": -22.0603,
                "lng": -46.9736
            },
            "S\u00e3o Jos\u00e9 do Belmonte": {
                "lat": -7.8608,
                "lng": -38.76
            },
            "Santa F\u00e9 do Sul": {
                "lat": -20.2689,
                "lng": -50.5458
            },
            "S\u00e3o Lu\u00eds Gonzaga": {
                "lat": -28.4078,
                "lng": -54.9608
            },
            "Castelo": {
                "lat": -20.6039,
                "lng": -41.185
            },
            "Bagre": {
                "lat": -1.9,
                "lng": -50.1639
            },
            "Rio Pardo": {
                "lat": -29.9897,
                "lng": -52.3781
            },
            "Bodoc\u00f3": {
                "lat": -7.7778,
                "lng": -39.9408
            },
            "Bela Vista de Goi\u00e1s": {
                "lat": -16.9667,
                "lng": -48.95
            },
            "Itaporanga d\u2019Ajuda": {
                "lat": -10.9978,
                "lng": -37.3108
            },
            "S\u00e3o Joaquim de Bicas": {
                "lat": -20.0489,
                "lng": -44.2739
            },
            "Garibaldi": {
                "lat": -29.2889,
                "lng": -51.5642
            },
            "Ara\u00e7ua\u00ed": {
                "lat": -16.85,
                "lng": -42.07
            },
            "S\u00e3o Pedro": {
                "lat": -22.5486,
                "lng": -47.9139
            },
            "Almeirim": {
                "lat": -1.5228,
                "lng": -52.5819
            },
            "Nova Cruz": {
                "lat": -6.4778,
                "lng": -35.4339
            },
            "S\u00e3o Desid\u00e9rio": {
                "lat": -12.3667,
                "lng": -44.9667
            },
            "Pomerode": {
                "lat": -26.7408,
                "lng": -49.1769
            },
            "Colinas do Tocantins": {
                "lat": -8.0589,
                "lng": -48.475
            },
            "Cura\u00e7\u00e1": {
                "lat": -8.9919,
                "lng": -39.9078
            },
            "Brejo": {
                "lat": -3.6839,
                "lng": -42.75
            },
            "Cravinhos": {
                "lat": -21.3403,
                "lng": -47.7294
            },
            "Port\u00e3o": {
                "lat": -29.6928,
                "lng": -51.235
            },
            "S\u00e3o Domingos do Maranh\u00e3o": {
                "lat": -5.7,
                "lng": -44.3667
            },
            "Vassouras": {
                "lat": -22.4039,
                "lng": -43.6628
            },
            "Curralinho": {
                "lat": -1.8139,
                "lng": -49.795
            },
            "Iti\u00faba": {
                "lat": -10.6908,
                "lng": -39.8528
            },
            "Palmeira": {
                "lat": -25.4289,
                "lng": -50.0058
            },
            "S\u00e3o Lu\u00eds de Montes Belos": {
                "lat": -16.5222,
                "lng": -50.3789
            },
            "Oeiras do Par\u00e1": {
                "lat": -1.9667,
                "lng": -49.85
            },
            "Inhambupe": {
                "lat": -11.7833,
                "lng": -38.35
            },
            "Bra\u00e7o do Norte": {
                "lat": -28.275,
                "lng": -49.1658
            },
            "V\u00e1rzea da Palma": {
                "lat": -17.5978,
                "lng": -44.7308
            },
            "Jaguaribe": {
                "lat": -5.8908,
                "lng": -38.6219
            },
            "Penha": {
                "lat": -26.7689,
                "lng": -48.6458
            },
            "Morro do Chap\u00e9u": {
                "lat": -11.55,
                "lng": -41.157
            },
            "Pitanga": {
                "lat": -24.7569,
                "lng": -51.7608
            },
            "Ribeir\u00e3o": {
                "lat": -8.5139,
                "lng": -35.3778
            },
            "Fraiburgo": {
                "lat": -27.0258,
                "lng": -50.9208
            },
            "Coxim": {
                "lat": -18.5069,
                "lng": -54.76
            },
            "Po\u00e7o Redondo": {
                "lat": -9.8058,
                "lng": -37.685
            },
            "Monteiro": {
                "lat": -7.8889,
                "lng": -37.12
            },
            "Riach\u00e3o do Jacu\u00edpe": {
                "lat": -11.81,
                "lng": -39.3819
            },
            "Iper\u00f3": {
                "lat": -23.3503,
                "lng": -47.6886
            },
            "Estreito": {
                "lat": -6.5608,
                "lng": -47.4508
            },
            "Jo\u00e3o C\u00e2mara": {
                "lat": -5.5378,
                "lng": -35.82
            },
            "Capim Grosso": {
                "lat": -11.3808,
                "lng": -40.0128
            },
            "Eirunep\u00e9": {
                "lat": -6.66,
                "lng": -69.8739
            },
            "Palmeira das Miss\u00f5es": {
                "lat": -27.9167,
                "lng": -53.2833
            },
            "Borba": {
                "lat": -4.3878,
                "lng": -59.5939
            },
            "Taiobeiras": {
                "lat": -15.8072,
                "lng": -42.2228
            },
            "Touros": {
                "lat": -5.1989,
                "lng": -35.4608
            },
            "Osvaldo Cruz": {
                "lat": -21.7967,
                "lng": -50.8786
            },
            "P\u00f4rto Uni\u00e3o": {
                "lat": -26.2378,
                "lng": -51.0778
            },
            "S\u00e3o Paulo de Oliven\u00e7a": {
                "lat": -3.4594,
                "lng": -68.9333
            },
            "Itamarandiba": {
                "lat": -17.8569,
                "lng": -42.8589
            },
            "Biritiba-Mirim": {
                "lat": -23.5728,
                "lng": -46.0389
            },
            "Urbano Santos": {
                "lat": -3.2,
                "lng": -43.3833
            },
            "Sert\u00e2nia": {
                "lat": -8.0706,
                "lng": -37.2658
            },
            "Igrejinha": {
                "lat": -29.5739,
                "lng": -50.79
            },
            "Teut\u00f4nia": {
                "lat": -29.4333,
                "lng": -51.8
            },
            "Bela Cruz": {
                "lat": -3.0508,
                "lng": -40.1678
            },
            "Ubajara": {
                "lat": -3.85,
                "lng": -40.9333
            },
            "Ivaipor\u00e3": {
                "lat": -24.25,
                "lng": -51.75
            },
            "Jaguarari": {
                "lat": -10.2667,
                "lng": -40.2
            },
            "S\u00e3o Jo\u00e3o Batista": {
                "lat": -2.955,
                "lng": -44.8069
            },
            "Ita\u00edba": {
                "lat": -8.9478,
                "lng": -37.4228
            },
            "Frederico Westphalen": {
                "lat": -27.3667,
                "lng": -53.4
            },
            "Esplanada": {
                "lat": -11.7958,
                "lng": -37.945
            },
            "Ca\u00e7apava do Sul": {
                "lat": -30.5122,
                "lng": -53.4908
            },
            "Pombal": {
                "lat": -6.77,
                "lng": -37.8019
            },
            "Ouril\u00e2ndia do Norte": {
                "lat": -6.755,
                "lng": -51.0839
            },
            "Correntina": {
                "lat": -13.3333,
                "lng": -44.65
            },
            "Pires do Rio": {
                "lat": -17.3014,
                "lng": -48.28
            },
            "Guararapes": {
                "lat": -21.2608,
                "lng": -50.6428
            },
            "Cachoeira Paulista": {
                "lat": -22.665,
                "lng": -45.0094
            },
            "Guanh\u00e3es": {
                "lat": -18.7833,
                "lng": -42.95
            },
            "Laranjeiras do Sul": {
                "lat": -25.4167,
                "lng": -52.4167
            },
            "Paraipaba": {
                "lat": -3.4333,
                "lng": -39.1167
            },
            "Estr\u00eala": {
                "lat": -29.5019,
                "lng": -51.9658
            },
            "Gandu": {
                "lat": -13.7439,
                "lng": -39.4869
            },
            "Ibat\u00e9": {
                "lat": -21.955,
                "lng": -47.9969
            },
            "Mat\u00f5es": {
                "lat": -5.5189,
                "lng": -43.1989
            },
            "Maragogi": {
                "lat": -9.0122,
                "lng": -35.2225
            },
            "Catende": {
                "lat": -8.6669,
                "lng": -35.7169
            },
            "Miguel Alves": {
                "lat": -4.1667,
                "lng": -42.9
            },
            "Pojuca": {
                "lat": -12.4308,
                "lng": -38.3278
            },
            "Campo Alegre": {
                "lat": -26.1928,
                "lng": -49.2658
            },
            "Ouro Fino": {
                "lat": -22.2828,
                "lng": -46.3689
            },
            "S\u00e3o Miguel Arcanjo": {
                "lat": -23.8783,
                "lng": -47.9972
            },
            "Bras\u00edlia de Minas": {
                "lat": -16.2078,
                "lng": -44.4289
            },
            "N\u00edsia Floresta": {
                "lat": -6.0667,
                "lng": -35.1333
            },
            "Ner\u00f3polis": {
                "lat": -16.405,
                "lng": -49.2167
            },
            "Santo Ant\u00f4nio do Tau\u00e1": {
                "lat": -1.1833,
                "lng": -48.1333
            },
            "Araguatins": {
                "lat": -5.6508,
                "lng": -48.1239
            },
            "Xaxim": {
                "lat": -26.9617,
                "lng": -52.5347
            },
            "Palmeiras de Goi\u00e1s": {
                "lat": -16.7933,
                "lng": -49.8853
            },
            "Domingos Martins": {
                "lat": -20.3628,
                "lng": -40.6589
            },
            "Exu": {
                "lat": -7.5119,
                "lng": -39.7239
            },
            "S\u00e3o Gotardo": {
                "lat": -19.3108,
                "lng": -46.0489
            },
            "Gurup\u00e1": {
                "lat": -1.405,
                "lng": -51.64
            },
            "Descalvado": {
                "lat": -21.9039,
                "lng": -47.6028
            },
            "Jaguaruana": {
                "lat": -4.8333,
                "lng": -37.7833
            },
            "Itapicuru": {
                "lat": -11.3169,
                "lng": -38.2328
            },
            "Capela": {
                "lat": -9.4075,
                "lng": -36.0736
            },
            "Morro Agudo": {
                "lat": -20.7314,
                "lng": -48.0578
            },
            "Cururupu": {
                "lat": -1.8333,
                "lng": -44.8667
            },
            "Mandagua\u00e7u": {
                "lat": -23.3333,
                "lng": -52.0833
            },
            "Parambu": {
                "lat": -6.2167,
                "lng": -40.7167
            },
            "Forquilhinha": {
                "lat": -28.7642,
                "lng": -49.4722
            },
            "Pindar\u00e9-Mirim": {
                "lat": -3.6167,
                "lng": -45.35
            },
            "Col\u00edder": {
                "lat": -10.8128,
                "lng": -55.455
            },
            "C\u00e2ndido Mota": {
                "lat": -22.7464,
                "lng": -50.3869
            },
            "Rio das Pedras": {
                "lat": -22.8428,
                "lng": -47.6058
            },
            "Ara\u00e7oiaba da Serra": {
                "lat": -23.5053,
                "lng": -47.6142
            },
            "Bandeirantes": {
                "lat": -23.11,
                "lng": -50.3678
            },
            "Parnarama": {
                "lat": -5.6833,
                "lng": -43.1
            },
            "Carangola": {
                "lat": -20.7328,
                "lng": -42.0289
            },
            "Esperan\u00e7a": {
                "lat": -7.0228,
                "lng": -35.5975
            },
            "Barrinha": {
                "lat": -21.1946,
                "lng": -48.1644
            },
            "Pocon\u00e9": {
                "lat": -16.2667,
                "lng": -56.6333
            },
            "Itaperu\u00e7u": {
                "lat": -25.2,
                "lng": -49.3667
            },
            "Espinosa": {
                "lat": -14.9081,
                "lng": -42.8103
            },
            "Tangu\u00e1": {
                "lat": -22.73,
                "lng": -42.7139
            },
            "Barreirinha": {
                "lat": -2.7928,
                "lng": -57.07
            },
            "Pompeu": {
                "lat": -19.2233,
                "lng": -45.0033
            },
            "S\u00e3o Jos\u00e9 do Egito": {
                "lat": -7.4789,
                "lng": -37.2744
            },
            "Iguape": {
                "lat": -24.7081,
                "lng": -47.5553
            },
            "Itapemirim": {
                "lat": -21.0111,
                "lng": -40.8339
            },
            "Arraial do Cabo": {
                "lat": -22.9658,
                "lng": -42.0278
            },
            "Santa Vit\u00f3ria do Palmar": {
                "lat": -33.5189,
                "lng": -53.3681
            },
            "Irituia": {
                "lat": -1.7667,
                "lng": -47.4333
            },
            "Itatiaia": {
                "lat": -22.4961,
                "lng": -44.5633
            },
            "C\u00edcero Dantas": {
                "lat": -10.6,
                "lng": -38.3833
            },
            "Canavieiras": {
                "lat": -15.675,
                "lng": -38.9469
            },
            "Flores da Cunha": {
                "lat": -29.0306,
                "lng": -51.1833
            },
            "S\u00e3o Lu\u00eds do Quitunde": {
                "lat": -9.3178,
                "lng": -35.5608
            },
            "Careiro da V\u00e1rzea": {
                "lat": -3.2208,
                "lng": -59.8258
            },
            "Vit\u00f3ria do Mearim": {
                "lat": -3.4667,
                "lng": -44.8833
            },
            "Lavras da Mangabeira": {
                "lat": -6.75,
                "lng": -38.95
            },
            "Careiro": {
                "lat": -3.7678,
                "lng": -60.3689
            },
            "Bar\u00e3o de Cocais": {
                "lat": -19.9458,
                "lng": -43.4869
            },
            "Alegre": {
                "lat": -20.7639,
                "lng": -41.5328
            },
            "Al\u00e9m Para\u00edba": {
                "lat": -21.8878,
                "lng": -42.7039
            },
            "Juatuba": {
                "lat": -19.9519,
                "lng": -44.3428
            },
            "Riacho de Santana": {
                "lat": -13.6089,
                "lng": -42.9389
            },
            "Dois Irm\u00e3os": {
                "lat": -29.58,
                "lng": -51.085
            },
            "Nova Russas": {
                "lat": -4.7,
                "lng": -40.5667
            },
            "Campo Alegre de Lourdes": {
                "lat": -9.5158,
                "lng": -43.0108
            },
            "Presidente Figueiredo": {
                "lat": -2.0172,
                "lng": -60.025
            },
            "Catol\u00e9 do Rocha": {
                "lat": -6.35,
                "lng": -37.75
            },
            "Tabuleiro do Norte": {
                "lat": -5.25,
                "lng": -38.1167
            },
            "Nazar\u00e9 da Mata": {
                "lat": -7.7419,
                "lng": -35.2278
            },
            "Juquitiba": {
                "lat": -23.9319,
                "lng": -47.0686
            },
            "Lu\u00eds Correia": {
                "lat": -2.8789,
                "lng": -41.6669
            },
            "Santana do Acara\u00fa": {
                "lat": -3.45,
                "lng": -40.2
            },
            "Jarinu": {
                "lat": -23.1014,
                "lng": -46.7283
            },
            "Carap\u00f3": {
                "lat": -22.6339,
                "lng": -54.8219
            },
            "S\u00e3o Jos\u00e9 da Tapera": {
                "lat": -9.5578,
                "lng": -37.3808
            },
            "Capim": {
                "lat": -1.675,
                "lng": -47.775
            },
            "Pau dos Ferros": {
                "lat": -6.1108,
                "lng": -38.2089
            },
            "Santa B\u00e1rbara": {
                "lat": -19.95,
                "lng": -43.4
            },
            "Afonso Cl\u00e1udio": {
                "lat": -20.0739,
                "lng": -41.1239
            },
            "Camamu": {
                "lat": -13.95,
                "lng": -39.1167
            },
            "Pontal do Paran\u00e1": {
                "lat": -25.5833,
                "lng": -48.35
            },
            "Carlos Barbosa": {
                "lat": -29.3,
                "lng": -51.5
            },
            "Cabrob\u00f3": {
                "lat": -8.5142,
                "lng": -38.2767
            },
            "Cl\u00e1udio": {
                "lat": -20.4428,
                "lng": -44.7658
            },
            "Joa\u00e7aba": {
                "lat": -27.1728,
                "lng": -51.5008
            },
            "Floresta": {
                "lat": -23.5989,
                "lng": -52.0808
            },
            "Soledade": {
                "lat": -7.0569,
                "lng": -36.3628
            },
            "Sombrio": {
                "lat": -29.1039,
                "lng": -49.6289
            },
            "Anchieta": {
                "lat": -20.8056,
                "lng": -40.6444
            },
            "Garopaba": {
                "lat": -28.0233,
                "lng": -48.6222
            },
            "Imbituva": {
                "lat": -25.2,
                "lng": -50.5833
            },
            "Serra Negra": {
                "lat": -22.6119,
                "lng": -46.7008
            },
            "Pinh\u00e3o": {
                "lat": -25.6958,
                "lng": -51.66
            },
            "Campo Magro": {
                "lat": -25.3689,
                "lng": -49.4508
            },
            "Igarapava": {
                "lat": -20.0383,
                "lng": -47.7469
            },
            "Buriti": {
                "lat": -3.9419,
                "lng": -42.925
            },
            "Canguaretama": {
                "lat": -6.3833,
                "lng": -35.1333
            },
            "Piraju": {
                "lat": -23.1936,
                "lng": -49.3839
            },
            "Pedras de Fogo": {
                "lat": -7.3833,
                "lng": -35.1167
            },
            "Cruz": {
                "lat": -2.9178,
                "lng": -40.1719
            },
            "Pati do Alferes": {
                "lat": -22.4289,
                "lng": -43.4189
            },
            "Limoeiro do Ajuru": {
                "lat": -1.9167,
                "lng": -49.3833
            },
            "Cambu\u00ed": {
                "lat": -22.6119,
                "lng": -46.0578
            },
            "Arari": {
                "lat": -3.4539,
                "lng": -44.78
            },
            "Bujaru": {
                "lat": -1.5167,
                "lng": -48.0167
            },
            "Espig\u00e3o D\u2019Oeste": {
                "lat": -11.5247,
                "lng": -61.0128
            },
            "Prainha": {
                "lat": -1.8,
                "lng": -53.48
            },
            "Gl\u00f3ria do Goit\u00e1": {
                "lat": -8.0019,
                "lng": -35.2928
            },
            "Paratinga": {
                "lat": -12.6908,
                "lng": -43.1839
            },
            "Cachoeira": {
                "lat": -12.5844,
                "lng": -38.9558
            },
            "Santa Cruz Cabr\u00e1lia": {
                "lat": -16.2778,
                "lng": -39.025
            },
            "Irar\u00e1": {
                "lat": -12.0458,
                "lng": -38.7658
            },
            "S\u00e3o Miguel do Igua\u00e7u": {
                "lat": -25.35,
                "lng": -54.1667
            },
            "Baixo Guandu": {
                "lat": -19.5189,
                "lng": -41.0158
            },
            "Paripiranga": {
                "lat": -10.6833,
                "lng": -37.8667
            },
            "Mirand\u00f3polis": {
                "lat": -21.1336,
                "lng": -51.1017
            },
            "Carmo do Parana\u00edba": {
                "lat": -19.0008,
                "lng": -48.3158
            },
            "Ipubi": {
                "lat": -7.65,
                "lng": -40.1167
            },
            "Limoeiro de Anadia": {
                "lat": -9.7414,
                "lng": -36.5033
            },
            "Humberto de Campos": {
                "lat": -2.6,
                "lng": -43.4667
            },
            "Candel\u00e1ria": {
                "lat": -29.6689,
                "lng": -52.7889
            },
            "Tr\u00eas Marias": {
                "lat": -18.2,
                "lng": -45.2333
            },
            "Coromandel": {
                "lat": -18.4667,
                "lng": -47.2167
            },
            "Carinhanha": {
                "lat": -14.305,
                "lng": -43.765
            },
            "Santa Cruz das Palmeiras": {
                "lat": -21.8269,
                "lng": -47.2486
            },
            "Ibaiti": {
                "lat": -23.8333,
                "lng": -50.1667
            },
            "Caet\u00e9s": {
                "lat": -8.7728,
                "lng": -36.6228
            },
            "Bom Jesus": {
                "lat": -28.6678,
                "lng": -50.4169
            },
            "Carauari": {
                "lat": -4.8828,
                "lng": -66.8958
            },
            "Muritiba": {
                "lat": -12.6258,
                "lng": -38.99
            },
            "Tracuateua": {
                "lat": -1.0833,
                "lng": -46.9
            },
            "I\u00fana": {
                "lat": -20.3458,
                "lng": -41.5358
            },
            "Rancharia": {
                "lat": -22.2292,
                "lng": -50.8931
            },
            "Jaciara": {
                "lat": -15.9833,
                "lng": -54.95
            },
            "Cajati": {
                "lat": -24.7361,
                "lng": -48.1228
            },
            "Piracuruca": {
                "lat": -3.9333,
                "lng": -41.7
            },
            "Concei\u00e7\u00e3o da Barra": {
                "lat": -18.5928,
                "lng": -39.7319
            },
            "Goio-Er\u00ea": {
                "lat": -24.185,
                "lng": -53.0278
            },
            "Concei\u00e7\u00e3o das Alagoas": {
                "lat": -19.915,
                "lng": -48.3878
            },
            "Guararema": {
                "lat": -23.415,
                "lng": -46.035
            },
            "Prata": {
                "lat": -19.3069,
                "lng": -48.9239
            },
            "Passira": {
                "lat": -7.995,
                "lng": -35.5808
            },
            "Ruy Barbosa": {
                "lat": -12.2839,
                "lng": -40.4939
            },
            "Rio Pardo de Minas": {
                "lat": -15.6056,
                "lng": -42.5342
            },
            "Maravilha": {
                "lat": -9.2358,
                "lng": -37.35
            },
            "Cocal": {
                "lat": -3.4708,
                "lng": -41.555
            },
            "Santo Ant\u00f4nio do I\u00e7\u00e1": {
                "lat": -3.1019,
                "lng": -67.94
            },
            "Itabela": {
                "lat": -16.575,
                "lng": -39.5528
            },
            "Conchal": {
                "lat": -22.55,
                "lng": -47.2833
            },
            "Casa Branca": {
                "lat": -21.7739,
                "lng": -47.0858
            },
            "Anaj\u00e1s": {
                "lat": -0.9869,
                "lng": -49.94
            },
            "Iguaba Grande": {
                "lat": -22.8389,
                "lng": -42.2289
            },
            "Melga\u00e7o": {
                "lat": -1.8039,
                "lng": -50.7119
            },
            "Gua\u00e7u\u00ed": {
                "lat": -20.7758,
                "lng": -41.6789
            },
            "Traipu": {
                "lat": -9.9708,
                "lng": -37.0028
            },
            "Pirap\u00f2zinho": {
                "lat": -22.2753,
                "lng": -51.5
            },
            "Mon\u00e7\u00e3o": {
                "lat": -3.4919,
                "lng": -45.2508
            },
            "Itapissuma": {
                "lat": -7.7758,
                "lng": -34.8919
            },
            "Hidrol\u00e2ndia": {
                "lat": -4.4078,
                "lng": -40.4378
            },
            "Jo\u00e3o Alfredo": {
                "lat": -7.8558,
                "lng": -35.5878
            },
            "Itacar\u00e9": {
                "lat": -14.2781,
                "lng": -38.9961
            },
            "Porto Belo": {
                "lat": -27.15,
                "lng": -48.5333
            },
            "Tabira": {
                "lat": -7.5833,
                "lng": -37.55
            },
            "Aparecida do Taboado": {
                "lat": -20.0869,
                "lng": -51.0939
            },
            "Lagoa Vermelha": {
                "lat": -28.2089,
                "lng": -51.5258
            },
            "Quijingue": {
                "lat": -10.75,
                "lng": -39.2
            },
            "Marac\u00e1s": {
                "lat": -13.4333,
                "lng": -40.45
            },
            "Lagoa S\u00eaca": {
                "lat": -7.1708,
                "lng": -35.8539
            },
            "Pilar do Sul": {
                "lat": -23.8128,
                "lng": -47.7158
            },
            "Vila do Conde": {
                "lat": -7.26,
                "lng": -34.9078
            },
            "Pombos": {
                "lat": -8.1408,
                "lng": -35.3958
            },
            "Novo Oriente": {
                "lat": -5.5339,
                "lng": -40.7739
            },
            "Triunfo": {
                "lat": -7.8378,
                "lng": -38.1019
            },
            "Oiapoque": {
                "lat": 3.8428,
                "lng": -51.835
            },
            "Pira\u00ed": {
                "lat": -22.6289,
                "lng": -43.8978
            },
            "Mandirituba": {
                "lat": -25.7833,
                "lng": -49.3333
            },
            "Jardim": {
                "lat": -21.48,
                "lng": -56.1378
            },
            "Ibipetuba": {
                "lat": -11.0,
                "lng": -44.5333
            },
            "Ita\u00ed": {
                "lat": -23.4178,
                "lng": -49.0906
            },
            "Macau": {
                "lat": -5.115,
                "lng": -36.6339
            },
            "Santo Ant\u00f4nio do Monte": {
                "lat": -20.0869,
                "lng": -45.6272
            },
            "Porto Calvo": {
                "lat": -9.045,
                "lng": -35.3978
            },
            "Corrente": {
                "lat": -10.4428,
                "lng": -45.1619
            },
            "Santo Amaro da Imperatriz": {
                "lat": -27.6833,
                "lng": -48.7667
            },
            "Bady Bassitt": {
                "lat": -20.9181,
                "lng": -49.4453
            },
            "\u00c1lvares Machado": {
                "lat": -22.0744,
                "lng": -51.4733
            },
            "Mocajuba": {
                "lat": -2.5833,
                "lng": -49.5
            },
            "Pi\u00e7arras": {
                "lat": -26.75,
                "lng": -48.6667
            },
            "Mina\u00e7u": {
                "lat": -13.5328,
                "lng": -48.22
            },
            "Nova Olinda do Norte": {
                "lat": -3.8878,
                "lng": -59.0939
            },
            "Nazar\u00e9": {
                "lat": -13.035,
                "lng": -39.0139
            },
            "Alex\u00e2nia": {
                "lat": -16.0786,
                "lng": -48.5008
            },
            "Anajatuba": {
                "lat": -3.2639,
                "lng": -44.62
            },
            "Aripuan\u00e3": {
                "lat": -10.1767,
                "lng": -59.4439
            },
            "Novo Cruzeiro": {
                "lat": -17.4678,
                "lng": -41.875
            },
            "S\u00e3o Bernardo": {
                "lat": -3.3608,
                "lng": -42.4178
            },
            "Tupanatinga": {
                "lat": -8.75,
                "lng": -37.3333
            },
            "Bara\u00fana": {
                "lat": -6.6428,
                "lng": -36.2539
            },
            "Miracema": {
                "lat": -21.4119,
                "lng": -42.1969
            },
            "Canind\u00e9 de S\u00e3o Francisco": {
                "lat": -9.6419,
                "lng": -37.7878
            },
            "Mirassol d\u2019Oeste": {
                "lat": -15.675,
                "lng": -58.0958
            },
            "Sol\u00e2nea": {
                "lat": -6.75,
                "lng": -35.65
            },
            "Goianinha": {
                "lat": -6.2669,
                "lng": -35.21
            },
            "Piren\u00f3polis": {
                "lat": -15.8519,
                "lng": -48.9589
            },
            "Piracaia": {
                "lat": -23.0539,
                "lng": -46.3581
            },
            "Pitangui": {
                "lat": -19.6772,
                "lng": -44.89
            },
            "Ipu": {
                "lat": -4.3257,
                "lng": -40.7103
            },
            "Itamarac\u00e1": {
                "lat": -7.7472,
                "lng": -34.8278
            },
            "Sacramento": {
                "lat": -19.865,
                "lng": -47.44
            },
            "Propri\u00e1": {
                "lat": -10.2108,
                "lng": -36.84
            },
            "Jaguar\u00e3o": {
                "lat": -32.5658,
                "lng": -53.3758
            },
            "Ibimirim": {
                "lat": -8.5333,
                "lng": -37.7
            },
            "Nova Esperan\u00e7a": {
                "lat": -23.1839,
                "lng": -52.205
            },
            "Miguel Pereira": {
                "lat": -22.4539,
                "lng": -43.4689
            },
            "Porto da Folha": {
                "lat": -9.9169,
                "lng": -37.2778
            },
            "Mantena": {
                "lat": -18.7819,
                "lng": -40.98
            },
            "Ituporanga": {
                "lat": -27.4167,
                "lng": -49.6
            },
            "Dois C\u00f3rregos": {
                "lat": -22.3661,
                "lng": -48.3803
            },
            "Cap\u00e3o do Le\u00e3o": {
                "lat": -31.7628,
                "lng": -52.4839
            },
            "Timbiras": {
                "lat": -4.255,
                "lng": -43.9408
            },
            "Marapanim": {
                "lat": -0.7,
                "lng": -47.7
            },
            "\u00c1gua Preta": {
                "lat": -8.7069,
                "lng": -35.5308
            },
            "Cajuru": {
                "lat": -21.2753,
                "lng": -47.3042
            },
            "Vic\u00eancia": {
                "lat": -7.6667,
                "lng": -35.3333
            },
            "Ilha Solteira": {
                "lat": -20.4272,
                "lng": -51.3436
            },
            "El\u00f3i Mendes": {
                "lat": -21.6103,
                "lng": -45.5689
            },
            "Cariria\u00e7u": {
                "lat": -7.0333,
                "lng": -39.2833
            },
            "Ibotirama": {
                "lat": -12.185,
                "lng": -43.2208
            },
            "Batalha": {
                "lat": -9.6778,
                "lng": -37.1247
            },
            "Laranjal Paulista": {
                "lat": -23.0497,
                "lng": -47.8367
            },
            "Itapuranga": {
                "lat": -15.5619,
                "lng": -49.9489
            },
            "Campos Gerais": {
                "lat": -21.235,
                "lng": -45.7589
            },
            "Camanducaia": {
                "lat": -22.755,
                "lng": -46.145
            },
            "Alagoa Grande": {
                "lat": -7.0889,
                "lng": -35.635
            },
            "Barra da Estiva": {
                "lat": -13.6258,
                "lng": -41.3269
            },
            "Brasil\u00e9ia": {
                "lat": -11.0,
                "lng": -68.7333
            },
            "Bom Jesus dos Perd\u00f5es": {
                "lat": -23.135,
                "lng": -46.4653
            },
            "Queimadas": {
                "lat": -10.9778,
                "lng": -39.6239
            },
            "S\u00e3o Joaquim": {
                "lat": -28.2939,
                "lng": -49.9319
            },
            "Mimoso do Sul": {
                "lat": -21.0639,
                "lng": -41.3658
            },
            "Formosa do Rio Preto": {
                "lat": -11.0478,
                "lng": -45.1928
            },
            "Fonte Boa": {
                "lat": -2.5139,
                "lng": -66.0917
            },
            "Santa Isabel do Rio Negro": {
                "lat": -0.4139,
                "lng": -65.0189
            },
            "Canarana I": {
                "lat": -13.5544,
                "lng": -52.2722
            },
            "Maracan\u00e3": {
                "lat": -0.765,
                "lng": -47.45
            },
            "Marco": {
                "lat": -3.1239,
                "lng": -40.1469
            },
            "Arapoti": {
                "lat": -24.1667,
                "lng": -49.6667
            },
            "Lap\u00e3o": {
                "lat": -11.4,
                "lng": -41.8333
            },
            "Nova Prata": {
                "lat": -28.7833,
                "lng": -51.6
            },
            "Pereira Barreto": {
                "lat": -20.6383,
                "lng": -51.1092
            },
            "S\u00e3o Sebasti\u00e3o da Boa Vista": {
                "lat": -1.7178,
                "lng": -49.5408
            },
            "Igaci": {
                "lat": -9.5369,
                "lng": -36.6336
            },
            "Inaj\u00e1": {
                "lat": -8.9031,
                "lng": -37.8269
            },
            "S\u00e3o Jo\u00e3o Nepomuceno": {
                "lat": -21.5333,
                "lng": -43.0
            },
            "Jericoacoara": {
                "lat": -2.7936,
                "lng": -40.5081
            },
            "Aracoiaba": {
                "lat": -4.3708,
                "lng": -38.8139
            },
            "Ipameri": {
                "lat": -17.7247,
                "lng": -48.1597
            },
            "Miranda": {
                "lat": -20.2408,
                "lng": -56.3778
            },
            "Sobradinho": {
                "lat": -9.455,
                "lng": -40.8228
            },
            "Astorga": {
                "lat": -23.2328,
                "lng": -51.6658
            },
            "Tupaciguara": {
                "lat": -18.5928,
                "lng": -48.705
            },
            "S\u00e3o Jos\u00e9 do Norte": {
                "lat": -32.015,
                "lng": -52.0419
            },
            "Anag\u00e9": {
                "lat": -14.6,
                "lng": -41.1333
            },
            "Tr\u00eas Passos": {
                "lat": -27.4558,
                "lng": -53.9319
            },
            "Cra\u00edbas": {
                "lat": -9.6178,
                "lng": -36.7678
            },
            "Cora\u00e7\u00e3o de Jesus": {
                "lat": -16.685,
                "lng": -44.365
            },
            "Luzil\u00e2ndia": {
                "lat": -3.4578,
                "lng": -42.37
            },
            "Ibirapitanga": {
                "lat": -14.1625,
                "lng": -39.3725
            },
            "Tanabi": {
                "lat": -20.6258,
                "lng": -49.6489
            },
            "C\u00e2ndido Sales": {
                "lat": -15.505,
                "lng": -41.2389
            },
            "Apia\u00ed": {
                "lat": -24.5097,
                "lng": -48.8428
            },
            "Uru\u00e7u\u00ed": {
                "lat": -7.2333,
                "lng": -44.55
            },
            "Brod\u00f3squi": {
                "lat": -20.9908,
                "lng": -47.6589
            },
            "Taquari": {
                "lat": -29.8,
                "lng": -51.8597
            },
            "Piranhas": {
                "lat": -16.4164,
                "lng": -51.8158
            },
            "Juta\u00ed": {
                "lat": -2.7469,
                "lng": -66.7669
            },
            "Aimor\u00e9s": {
                "lat": -19.4958,
                "lng": -41.0639
            },
            "Campos Sales": {
                "lat": -7.0739,
                "lng": -40.3758
            },
            "Ibiapina": {
                "lat": -3.9228,
                "lng": -40.8889
            },
            "Bombinhas": {
                "lat": -27.1378,
                "lng": -48.5169
            },
            "S\u00e3o Jo\u00e3o dos Patos": {
                "lat": -6.5,
                "lng": -43.7
            },
            "Nepomuceno": {
                "lat": -21.2281,
                "lng": -45.2397
            },
            "Nossa Senhora das Dores": {
                "lat": -10.4919,
                "lng": -37.1928
            },
            "Ponta de Pedras": {
                "lat": -1.39,
                "lng": -48.8708
            },
            "Rio Preto da Eva": {
                "lat": -2.6989,
                "lng": -59.7
            },
            "Tr\u00eas de Maio": {
                "lat": -27.7728,
                "lng": -54.24
            },
            "Piracanjuba": {
                "lat": -17.1333,
                "lng": -49.0167
            },
            "Martin\u00f3polis": {
                "lat": -22.1461,
                "lng": -51.1709
            },
            "Santa Rita do Passa Quatro": {
                "lat": -21.7103,
                "lng": -47.4781
            },
            "Tamboril": {
                "lat": -4.8319,
                "lng": -40.3208
            },
            "Icatu": {
                "lat": -2.7758,
                "lng": -44.0658
            },
            "S\u00e3o Louren\u00e7o d\u2019Oeste": {
                "lat": -26.4,
                "lng": -52.7667
            },
            "Guara\u00ed": {
                "lat": -8.8167,
                "lng": -48.3833
            },
            "Taquaritinga do Norte": {
                "lat": -7.9,
                "lng": -36.05
            },
            "Junqueiro": {
                "lat": -9.925,
                "lng": -36.4758
            },
            "Brasil Novo": {
                "lat": -3.2619,
                "lng": -52.6678
            },
            "Castro Alves": {
                "lat": -12.7658,
                "lng": -39.4278
            },
            "Jo\u00e3o Lisboa": {
                "lat": -5.3667,
                "lng": -47.2833
            },
            "Jacar\u00e9": {
                "lat": -18.9058,
                "lng": -40.0758
            },
            "Uau\u00e1": {
                "lat": -9.8333,
                "lng": -39.4667
            },
            "Miguel Calmon": {
                "lat": -11.4289,
                "lng": -40.595
            },
            "Boquim": {
                "lat": -11.1469,
                "lng": -37.6208
            },
            "Santa Maria do Par\u00e1": {
                "lat": -1.35,
                "lng": -47.5667
            },
            "Ia\u00e7u": {
                "lat": -12.7669,
                "lng": -40.2119
            },
            "Condado": {
                "lat": -7.5858,
                "lng": -35.1058
            },
            "Rio Tinto": {
                "lat": -6.807,
                "lng": -35.0778
            },
            "Reserva": {
                "lat": -24.65,
                "lng": -50.8508
            },
            "Tapejara": {
                "lat": -23.7328,
                "lng": -52.8728
            },
            "Guabiruba": {
                "lat": -27.0858,
                "lng": -48.9811
            },
            "Alcoba\u00e7a": {
                "lat": -17.519,
                "lng": -39.196
            },
            "Cordeir\u00f3polis": {
                "lat": -22.4819,
                "lng": -47.4569
            },
            "Ocara": {
                "lat": -4.4833,
                "lng": -38.6167
            },
            "Igara\u00e7u do Tiet\u00ea": {
                "lat": -22.5092,
                "lng": -48.5578
            },
            "S\u00e3o Sebasti\u00e3o do Ca\u00ed": {
                "lat": -29.5869,
                "lng": -51.3758
            },
            "Tr\u00eas Coroas": {
                "lat": -29.5153,
                "lng": -50.7794
            },
            "Pedra Azul": {
                "lat": -15.9986,
                "lng": -41.2719
            },
            "Minas Novas": {
                "lat": -17.2189,
                "lng": -42.59
            },
            "Itamb\u00e9": {
                "lat": -15.245,
                "lng": -40.6239
            },
            "Valente": {
                "lat": -11.5667,
                "lng": -39.45
            },
            "Taquarituba": {
                "lat": -23.5328,
                "lng": -49.2439
            },
            "Nova Xavantina": {
                "lat": -14.6728,
                "lng": -52.3528
            },
            "Canhotinho": {
                "lat": -8.8819,
                "lng": -36.1908
            },
            "Ipixuna": {
                "lat": -7.0508,
                "lng": -71.695
            },
            "Cantanhede": {
                "lat": -3.65,
                "lng": -44.4
            },
            "Pinheiral": {
                "lat": -22.5128,
                "lng": -44.0008
            },
            "Satuba": {
                "lat": -9.5628,
                "lng": -35.8239
            },
            "Senador Pompeu": {
                "lat": -5.5833,
                "lng": -39.3667
            },
            "S\u00e3o Geraldo do Araguaia": {
                "lat": -6.4,
                "lng": -48.5333
            },
            "Valpara\u00edso": {
                "lat": -21.2278,
                "lng": -50.8683
            },
            "Carutapera": {
                "lat": -1.195,
                "lng": -46.02
            },
            "Nova Soure": {
                "lat": -11.2333,
                "lng": -38.4833
            },
            "Guatuba": {
                "lat": -4.0333,
                "lng": -38.6333
            },
            "Canarana": {
                "lat": -11.685,
                "lng": -41.7689
            },
            "Soure": {
                "lat": -0.7169,
                "lng": -48.5228
            },
            "Ortigueira": {
                "lat": -24.2,
                "lng": -50.9167
            },
            "Quatro Barras": {
                "lat": -25.3667,
                "lng": -49.0833
            },
            "Forquilha": {
                "lat": -3.7978,
                "lng": -40.2608
            },
            "Angatuba": {
                "lat": -23.4903,
                "lng": -48.4128
            },
            "Itapor\u00e3": {
                "lat": -22.0789,
                "lng": -54.7889
            },
            "Salvaterra": {
                "lat": -0.7528,
                "lng": -48.5169
            },
            "Paraopeba": {
                "lat": -19.2739,
                "lng": -44.4039
            },
            "Anast\u00e1cio": {
                "lat": -20.4839,
                "lng": -55.8069
            },
            "Espera Feliz": {
                "lat": -20.65,
                "lng": -41.9069
            },
            "Areia Branca": {
                "lat": -10.7578,
                "lng": -37.315
            },
            "Monte Si\u00e3o": {
                "lat": -22.4328,
                "lng": -46.5728
            },
            "Lagoa Grande": {
                "lat": -8.9969,
                "lng": -40.2719
            },
            "Goi\u00e1s": {
                "lat": -15.9339,
                "lng": -50.14
            },
            "Buritis": {
                "lat": -15.6178,
                "lng": -46.4228
            },
            "Independ\u00eancia": {
                "lat": -5.3958,
                "lng": -40.3089
            },
            "Veran\u00f3polis": {
                "lat": -28.95,
                "lng": -51.55
            },
            "Jequitinhonha": {
                "lat": -16.4333,
                "lng": -41.0
            },
            "Cachoeira do Arari": {
                "lat": -1.0108,
                "lng": -48.9628
            },
            "Laranjeiras": {
                "lat": -10.8058,
                "lng": -37.17
            },
            "S\u00e3o Gon\u00e7alo do Sapuca\u00ed": {
                "lat": -21.8919,
                "lng": -45.595
            },
            "Santa Quit\u00e9ria do Maranh\u00e3o": {
                "lat": -3.5167,
                "lng": -42.5333
            },
            "Urucurituba": {
                "lat": -3.1308,
                "lng": -58.155
            },
            "Itaporanga": {
                "lat": -7.3039,
                "lng": -38.15
            },
            "S\u00e3o Jo\u00e3o da Ponte": {
                "lat": -15.9303,
                "lng": -44.0061
            },
            "Belo Oriente": {
                "lat": -19.22,
                "lng": -42.4839
            },
            "Juc\u00e1s": {
                "lat": -6.525,
                "lng": -39.5278
            },
            "Umba\u00faba": {
                "lat": -11.3828,
                "lng": -37.6578
            },
            "Irau\u00e7uba": {
                "lat": -3.75,
                "lng": -39.7833
            },
            "S\u00e3o Jo\u00e3o do Para\u00edso": {
                "lat": -15.3133,
                "lng": -42.015
            },
            "Buritizeiro": {
                "lat": -17.3508,
                "lng": -44.9639
            },
            "Porto Franco": {
                "lat": -6.3378,
                "lng": -47.3989
            },
            "Tacaratu": {
                "lat": -9.1024,
                "lng": -38.15
            },
            "Brotas": {
                "lat": -22.2847,
                "lng": -48.1272
            },
            "Macaparana": {
                "lat": -7.555,
                "lng": -35.4528
            },
            "Iraquara": {
                "lat": -12.2489,
                "lng": -41.6189
            },
            "Matriz de Camarajibe": {
                "lat": -9.1519,
                "lng": -35.5328
            },
            "Cruzeiro do Oeste": {
                "lat": -23.7667,
                "lng": -53.0667
            },
            "P\u00e3o de A\u00e7\u00facar": {
                "lat": -9.7478,
                "lng": -37.4369
            },
            "Encruzilhada do Sul": {
                "lat": -30.5333,
                "lng": -52.5167
            },
            "Novo Aripuan\u00e3": {
                "lat": -5.1214,
                "lng": -60.3806
            },
            "Caranda\u00ed": {
                "lat": -20.9544,
                "lng": -43.7914
            },
            "Agrestina": {
                "lat": -8.45,
                "lng": -35.95
            },
            "Manari": {
                "lat": -8.9639,
                "lng": -37.6278
            },
            "Aurora": {
                "lat": -6.9428,
                "lng": -38.9678
            },
            "Santo Ant\u00f4nio do Sudoeste": {
                "lat": -26.0667,
                "lng": -53.7333
            },
            "Orle\u00e3es": {
                "lat": -28.35,
                "lng": -49.3
            },
            "Conde": {
                "lat": -11.8139,
                "lng": -37.6108
            },
            "Pira\u00ed do Sul": {
                "lat": -24.5347,
                "lng": -49.9389
            },
            "Santa Gertrudes": {
                "lat": -22.4567,
                "lng": -47.5303
            },
            "Tamandar\u00e9": {
                "lat": -8.76,
                "lng": -35.105
            },
            "Codaj\u00e1s": {
                "lat": -3.8369,
                "lng": -62.0569
            },
            "Bambu\u00ed": {
                "lat": -20.0058,
                "lng": -45.9769
            },
            "S\u00e3o Miguel": {
                "lat": -6.2119,
                "lng": -38.4969
            },
            "Corinto": {
                "lat": -18.3808,
                "lng": -44.4558
            },
            "Cupira": {
                "lat": -8.6169,
                "lng": -35.95
            },
            "Muju\u00ed dos Campos": {
                "lat": -2.6847,
                "lng": -54.6403
            },
            "Quara\u00ed": {
                "lat": -30.3875,
                "lng": -56.4514
            },
            "Matinha": {
                "lat": -3.1008,
                "lng": -45.0339
            },
            "Carmo do Cajuru": {
                "lat": -20.1839,
                "lng": -44.7708
            },
            "Francisco S\u00e1": {
                "lat": -16.4789,
                "lng": -43.4947
            },
            "Raul Soares": {
                "lat": -20.0833,
                "lng": -42.3667
            },
            "Santa Rosa de Viterbo": {
                "lat": -21.4728,
                "lng": -47.3631
            },
            "Pindoretama": {
                "lat": -4.0333,
                "lng": -38.3
            },
            "Coronel Vivida": {
                "lat": -25.9667,
                "lng": -52.5667
            },
            "Capinzal": {
                "lat": -27.3439,
                "lng": -51.6119
            },
            "Aldeias Altas": {
                "lat": -4.6278,
                "lng": -43.4708
            },
            "Poxor\u00e9o": {
                "lat": -15.8369,
                "lng": -54.3889
            },
            "Carambe\u00ed": {
                "lat": -24.9333,
                "lng": -50.0833
            },
            "Teodoro Sampaio": {
                "lat": -22.5325,
                "lng": -52.1675
            },
            "Abaet\u00e9": {
                "lat": -19.1583,
                "lng": -45.4522
            },
            "Santo Ant\u00f4nio de Posse": {
                "lat": -22.6061,
                "lng": -46.9194
            },
            "Loanda": {
                "lat": -22.9,
                "lng": -53.1667
            },
            "Cambar\u00e1": {
                "lat": -23.0458,
                "lng": -50.0739
            },
            "Nova Petr\u00f3polis": {
                "lat": -29.367,
                "lng": -51.1
            },
            "Concei\u00e7\u00e3o do Mato Dentro": {
                "lat": -19.0369,
                "lng": -43.425
            },
            "Ribas do Rio Pardo": {
                "lat": -20.4428,
                "lng": -53.7589
            },
            "Planalto": {
                "lat": -27.3289,
                "lng": -53.0589
            },
            "Bananeiras": {
                "lat": -6.75,
                "lng": -35.6328
            },
            "Dom Pedro": {
                "lat": -4.4833,
                "lng": -44.45
            },
            "Batagua\u00e7u": {
                "lat": -21.7139,
                "lng": -52.4219
            },
            "Panelas": {
                "lat": -8.6639,
                "lng": -36.0058
            },
            "Ivoti": {
                "lat": -29.6,
                "lng": -51.1667
            },
            "Encantado": {
                "lat": -29.2358,
                "lng": -51.87
            },
            "Itaocara": {
                "lat": -21.6789,
                "lng": -42.0819
            },
            "Colorado": {
                "lat": -22.8378,
                "lng": -51.9728
            },
            "Ara\u00e7ariguama": {
                "lat": -23.4386,
                "lng": -47.0614
            },
            "Sarandi": {
                "lat": -27.9439,
                "lng": -52.9228
            },
            "Ecoporanga": {
                "lat": -18.3728,
                "lng": -40.8308
            },
            "Siqueira Campos": {
                "lat": -23.6889,
                "lng": -49.8339
            },
            "Pedra": {
                "lat": -8.5006,
                "lng": -36.9456
            },
            "Feira Grande": {
                "lat": -9.9,
                "lng": -36.6778
            },
            "Inhapim": {
                "lat": -19.55,
                "lng": -42.1167
            },
            "Olindina": {
                "lat": -11.3667,
                "lng": -38.35
            },
            "Areia": {
                "lat": -6.9628,
                "lng": -35.6919
            },
            "Tocantin\u00f3polis": {
                "lat": -6.3167,
                "lng": -47.2833
            },
            "Camacan": {
                "lat": -15.4189,
                "lng": -39.4958
            },
            "Senador Jos\u00e9 Porf\u00edrio": {
                "lat": -2.5833,
                "lng": -51.9167
            },
            "Cora\u00e7\u00e3o de Maria": {
                "lat": -12.2328,
                "lng": -38.75
            },
            "Cacul\u00e9": {
                "lat": -14.5028,
                "lng": -42.2219
            },
            "Buriti Bravo": {
                "lat": -5.8333,
                "lng": -43.8333
            },
            "Piraju\u00ed": {
                "lat": -21.9989,
                "lng": -49.4569
            },
            "Quissam\u00e3": {
                "lat": -22.1069,
                "lng": -41.4719
            },
            "Barreira": {
                "lat": -4.2869,
                "lng": -38.6428
            },
            "Ibatiba": {
                "lat": -20.2364,
                "lng": -41.5092
            },
            "Cedro": {
                "lat": -7.7219,
                "lng": -39.2389
            },
            "Pi\u00fama": {
                "lat": -20.8392,
                "lng": -40.7267
            },
            "Valen\u00e7a do Piau\u00ed": {
                "lat": -6.4,
                "lng": -41.75
            },
            "Monte Apraz\u00edvel": {
                "lat": -20.7725,
                "lng": -49.7142
            },
            "Silv\u00e2nia": {
                "lat": -16.6678,
                "lng": -48.5869
            },
            "Ibi\u00e1": {
                "lat": -19.4778,
                "lng": -46.5389
            },
            "Medeiros Neto": {
                "lat": -17.3333,
                "lng": -40.2333
            },
            "Flores": {
                "lat": -7.8658,
                "lng": -37.975
            },
            "Cunha": {
                "lat": -23.0739,
                "lng": -44.96
            },
            "Palmital": {
                "lat": -24.8928,
                "lng": -52.2028
            },
            "S\u00e3o Jos\u00e9 do Rio Preto": {
                "lat": -22.1508,
                "lng": -42.9239
            },
            "Itai\u00f3polis": {
                "lat": -26.3358,
                "lng": -49.9058
            },
            "Ceres": {
                "lat": -15.3033,
                "lng": -49.6053
            },
            "Pinhalzinho": {
                "lat": -22.7789,
                "lng": -46.5908
            },
            "Iracem\u00e1polis": {
                "lat": -22.5806,
                "lng": -47.5186
            },
            "Arroio do Meio": {
                "lat": -29.4008,
                "lng": -51.945
            },
            "Vertentes": {
                "lat": -7.9028,
                "lng": -35.9878
            },
            "S\u00earro": {
                "lat": -18.605,
                "lng": -43.379
            },
            "Diamantino": {
                "lat": -14.4089,
                "lng": -56.4458
            },
            "Mazag\u00e3o": {
                "lat": -0.115,
                "lng": -51.2889
            },
            "Ituber\u00e1": {
                "lat": -13.7333,
                "lng": -39.15
            },
            "S\u00e3o Miguel do Araguaia": {
                "lat": -13.2889,
                "lng": -50.1542
            },
            "S\u00e3o Francisco de Paula": {
                "lat": -29.4444,
                "lng": -50.5806
            },
            "Muzambinho": {
                "lat": -21.3758,
                "lng": -46.5258
            },
            "Pio XII": {
                "lat": -3.8833,
                "lng": -45.2833
            },
            "Mata Grande": {
                "lat": -9.1178,
                "lng": -37.7339
            },
            "Santa Teresa": {
                "lat": -25.0519,
                "lng": -53.6328
            },
            "Orob\u00f3": {
                "lat": -7.745,
                "lng": -35.6019
            },
            "Palmas de Monte Alto": {
                "lat": -14.2667,
                "lng": -43.1667
            },
            "Po\u00e7o Verde": {
                "lat": -10.7106,
                "lng": -38.1828
            },
            "Miguel\u00f3polis": {
                "lat": -20.1794,
                "lng": -48.0319
            },
            "Erval d\u2019Oeste": {
                "lat": -27.1939,
                "lng": -51.495
            },
            "Alhandra": {
                "lat": -7.4333,
                "lng": -34.9
            },
            "Guapia\u00e7u": {
                "lat": -20.795,
                "lng": -49.2203
            },
            "Assar\u00e9": {
                "lat": -6.8667,
                "lng": -39.8667
            },
            "Paramirim": {
                "lat": -13.4428,
                "lng": -42.2389
            },
            "Ibicara\u00ed": {
                "lat": -14.85,
                "lng": -39.6
            },
            "Riach\u00e3o das Neves": {
                "lat": -11.7458,
                "lng": -44.91
            },
            "S\u00e3o Miguel do Guapor\u00e9": {
                "lat": -11.6936,
                "lng": -62.7114
            },
            "Bela Vista": {
                "lat": -22.1089,
                "lng": -56.5208
            },
            "Paragua\u00e7u": {
                "lat": -21.5333,
                "lng": -45.7667
            },
            "Ibirub\u00e1": {
                "lat": -28.6333,
                "lng": -53.1
            },
            "Pancas": {
                "lat": -19.225,
                "lng": -40.8508
            },
            "Lad\u00e1rio": {
                "lat": -19.005,
                "lng": -57.6019
            },
            "Bastos": {
                "lat": -21.9219,
                "lng": -50.7319
            },
            "Parelhas": {
                "lat": -6.6878,
                "lng": -36.6578
            },
            "Boquira": {
                "lat": -12.8167,
                "lng": -42.7667
            },
            "Alta Floresta D\u2019Oeste": {
                "lat": -11.9681,
                "lng": -61.9542
            },
            "Senador Guiomard": {
                "lat": -10.1508,
                "lng": -67.7358
            },
            "Nhamund\u00e1": {
                "lat": -2.1858,
                "lng": -56.7128
            },
            "Tamba\u00fa": {
                "lat": -21.705,
                "lng": -47.2744
            },
            "Icapu\u00ed": {
                "lat": -4.7128,
                "lng": -37.355
            },
            "S\u00e3o Jo\u00e3o do Piau\u00ed": {
                "lat": -8.35,
                "lng": -42.25
            },
            "Uru\u00e7uca": {
                "lat": -14.5833,
                "lng": -39.2667
            },
            "Jandaia do Sul": {
                "lat": -23.6,
                "lng": -51.65
            },
            "Perd\u00f5es": {
                "lat": -21.0908,
                "lng": -45.0908
            },
            "Igreja Nova": {
                "lat": -10.1253,
                "lng": -36.6619
            },
            "Igua\u00ed": {
                "lat": -14.75,
                "lng": -40.0667
            },
            "Silva Jardim": {
                "lat": -22.6508,
                "lng": -42.3919
            },
            "S\u00e3o Jo\u00e3o": {
                "lat": -25.8278,
                "lng": -52.725
            },
            "Rolante": {
                "lat": -29.6667,
                "lng": -50.5667
            },
            "S\u00e3o Sep\u00e9": {
                "lat": -30.1667,
                "lng": -53.5667
            },
            "Luc\u00e9lia": {
                "lat": -21.7203,
                "lng": -51.0189
            },
            "Caapor\u00e3": {
                "lat": -7.5167,
                "lng": -34.9
            },
            "Boca da Mata": {
                "lat": -9.6408,
                "lng": -36.22
            },
            "Maraca\u00e7um\u00e9": {
                "lat": -2.05,
                "lng": -45.9333
            },
            "Princesa Isabel": {
                "lat": -7.7369,
                "lng": -37.9928
            },
            "Concei\u00e7\u00e3o de Macabu": {
                "lat": -22.085,
                "lng": -41.8678
            },
            "Itapaci": {
                "lat": -14.9611,
                "lng": -49.5594
            },
            "S\u00e3o Marcos": {
                "lat": -28.9708,
                "lng": -51.0678
            },
            "Chopinzinho": {
                "lat": -25.85,
                "lng": -52.5
            },
            "Palmeiras": {
                "lat": -12.5289,
                "lng": -41.5589
            },
            "Caxambu": {
                "lat": -21.9833,
                "lng": -44.9167
            },
            "Paulistana": {
                "lat": -8.15,
                "lng": -41.15
            },
            "Laje": {
                "lat": -13.1819,
                "lng": -39.425
            },
            "Itambacuri": {
                "lat": -18.0167,
                "lng": -41.7
            },
            "Mirador": {
                "lat": -6.3708,
                "lng": -44.3628
            },
            "S\u00e3o Jer\u00f4nimo": {
                "lat": -29.9589,
                "lng": -51.7219
            },
            "Tanha\u00e7u": {
                "lat": -14.0167,
                "lng": -41.2833
            },
            "Cassil\u00e2ndia": {
                "lat": -19.1128,
                "lng": -51.7339
            },
            "Capela do Alto": {
                "lat": -23.4706,
                "lng": -47.7347
            },
            "Itapecerica": {
                "lat": -20.4728,
                "lng": -46.1258
            },
            "Santa Vit\u00f3ria": {
                "lat": -18.8333,
                "lng": -50.1167
            },
            "Caracara\u00ed": {
                "lat": 1.8119,
                "lng": -61.1384
            },
            "Carmo do Rio Claro": {
                "lat": -20.9731,
                "lng": -46.1189
            },
            "Pacatu": {
                "lat": -11.9578,
                "lng": -38.975
            },
            "Corea\u00fa": {
                "lat": -3.5328,
                "lng": -40.6569
            },
            "Alagoa Nova": {
                "lat": -7.0708,
                "lng": -35.7578
            },
            "Urussanga": {
                "lat": -28.5178,
                "lng": -49.3208
            },
            "Guar\u00e1": {
                "lat": -20.4283,
                "lng": -47.8242
            },
            "Monte Santo de Minas": {
                "lat": -21.2,
                "lng": -46.9833
            },
            "Quixer\u00e9": {
                "lat": -5.0833,
                "lng": -37.9833
            },
            "Lajinha": {
                "lat": -20.1508,
                "lng": -41.6228
            },
            "Junqueir\u00f3polis": {
                "lat": -21.5147,
                "lng": -51.4336
            },
            "Conselheiro Pena": {
                "lat": -19.1667,
                "lng": -41.4667
            },
            "S\u00e3o Jos\u00e9 da Laje": {
                "lat": -9.01,
                "lng": -36.0578
            },
            "Concei\u00e7\u00e3o da Feira": {
                "lat": -12.5058,
                "lng": -38.9989
            },
            "Ibicoara": {
                "lat": -13.4,
                "lng": -41.3
            },
            "Cordeiro": {
                "lat": -22.0289,
                "lng": -42.3608
            },
            "Chaves": {
                "lat": -0.16,
                "lng": -49.9878
            },
            "Beruri": {
                "lat": -3.8983,
                "lng": -61.3731
            },
            "Oliveira dos Brejinhos": {
                "lat": -12.3167,
                "lng": -42.9
            },
            "Guaratinga": {
                "lat": -16.5667,
                "lng": -39.5667
            },
            "Campestre": {
                "lat": -21.7108,
                "lng": -46.2458
            },
            "Monte Azul": {
                "lat": -15.1553,
                "lng": -42.8589
            },
            "Olho d\u2019\u00c1gua das Flores": {
                "lat": -9.5358,
                "lng": -37.2939
            },
            "S\u00e3o Jo\u00e3o de Pirabas": {
                "lat": -0.7667,
                "lng": -47.1667
            },
            "Lagoa do Itaenga": {
                "lat": -7.9167,
                "lng": -35.3333
            },
            "Riacho das Almas": {
                "lat": -8.1333,
                "lng": -35.8667
            },
            "Mara\u00fa": {
                "lat": -14.1028,
                "lng": -39.015
            },
            "Manhumirim": {
                "lat": -20.3578,
                "lng": -41.9578
            },
            "Caravelas": {
                "lat": -17.732,
                "lng": -39.266
            },
            "Ch\u00e3 Grande": {
                "lat": -8.2333,
                "lng": -35.45
            },
            "Peritor\u00f3": {
                "lat": -4.3828,
                "lng": -44.3328
            },
            "Parais\u00f3polis": {
                "lat": -22.5539,
                "lng": -45.78
            },
            "Itatira": {
                "lat": -4.5,
                "lng": -39.6167
            },
            "Lambari": {
                "lat": -21.9758,
                "lng": -45.35
            },
            "Jaboticatubas": {
                "lat": -19.5139,
                "lng": -43.745
            },
            "Jaguaruna": {
                "lat": -28.615,
                "lng": -49.0256
            },
            "Porto Real": {
                "lat": -22.42,
                "lng": -44.29
            },
            "S\u00e3o Felipe": {
                "lat": -12.8469,
                "lng": -39.0889
            },
            "Salgado": {
                "lat": -11.0319,
                "lng": -37.475
            },
            "C\u00e2ndido Mendes": {
                "lat": -1.4469,
                "lng": -45.7169
            },
            "Chorozinho": {
                "lat": -4.3,
                "lng": -38.4833
            },
            "Erv\u00e1lia": {
                "lat": -20.9067,
                "lng": -42.6569
            },
            "Pomp\u00e9ia": {
                "lat": -22.1086,
                "lng": -50.1717
            },
            "Uruburetama": {
                "lat": -3.625,
                "lng": -39.5078
            },
            "Monte Alegre de Minas": {
                "lat": -18.8708,
                "lng": -48.8808
            },
            "Medina": {
                "lat": -16.2286,
                "lng": -41.4783
            },
            "Regente Feij\u00f3": {
                "lat": -22.2214,
                "lng": -51.3028
            },
            "Aquidab\u00e3": {
                "lat": -10.2808,
                "lng": -37.0189
            },
            "Turmalina": {
                "lat": -17.2858,
                "lng": -42.73
            },
            "Belmonte": {
                "lat": -15.8631,
                "lng": -38.8828
            },
            "Piat\u00e3": {
                "lat": -13.1519,
                "lng": -41.7728
            },
            "Barroso": {
                "lat": -21.1869,
                "lng": -43.9758
            },
            "Porto Real do Col\u00e9gio": {
                "lat": -10.1858,
                "lng": -36.84
            },
            "Schroeder": {
                "lat": -26.4072,
                "lng": -49.0728
            },
            "Mutu\u00edpe": {
                "lat": -13.25,
                "lng": -39.5167
            },
            "S\u00e3o Joaquim do Monte": {
                "lat": -8.4167,
                "lng": -35.85
            },
            "Presidente Get\u00falio": {
                "lat": -27.05,
                "lng": -49.6167
            },
            "Rio Formoso": {
                "lat": -8.6667,
                "lng": -35.15
            },
            "Tupanciret\u00e3": {
                "lat": -29.0806,
                "lng": -53.8358
            },
            "Vazante": {
                "lat": -17.9892,
                "lng": -46.9019
            },
            "Tibagi": {
                "lat": -24.5,
                "lng": -50.4
            },
            "Alpin\u00f3polis": {
                "lat": -20.8639,
                "lng": -46.3878
            },
            "Carira": {
                "lat": -10.3608,
                "lng": -37.7008
            },
            "Vila Rica": {
                "lat": -10.0119,
                "lng": -51.1158
            },
            "Andir\u00e1": {
                "lat": -23.0508,
                "lng": -50.2289
            },
            "Castilho": {
                "lat": -20.8722,
                "lng": -51.4875
            },
            "Ibirama": {
                "lat": -27.0667,
                "lng": -49.5167
            },
            "Cantagalo": {
                "lat": -25.3739,
                "lng": -52.1258
            },
            "Rio Verde de Mato Grosso": {
                "lat": -18.9178,
                "lng": -54.8439
            },
            "Prad\u00f3polis": {
                "lat": -21.3594,
                "lng": -48.0656
            },
            "Rem\u00edgio": {
                "lat": -6.9028,
                "lng": -35.8339
            },
            "Itaparica": {
                "lat": -12.8878,
                "lng": -38.6789
            },
            "Rubiataba": {
                "lat": -15.1639,
                "lng": -49.7992
            },
            "Araripe": {
                "lat": -7.2128,
                "lng": -40.0458
            },
            "Simon\u00e9sia": {
                "lat": -20.1239,
                "lng": -42.0008
            },
            "Tr\u00eas Barras": {
                "lat": -26.1064,
                "lng": -50.3222
            },
            "Itatinga": {
                "lat": -23.1017,
                "lng": -48.6158
            },
            "Cris\u00f3polis": {
                "lat": -11.5167,
                "lng": -38.1667
            },
            "Cuit\u00e9": {
                "lat": -6.485,
                "lng": -36.1569
            },
            "Castelo do Piau\u00ed": {
                "lat": -5.3333,
                "lng": -41.55
            },
            "Or\u00f3s": {
                "lat": -6.2439,
                "lng": -38.9139
            },
            "Buriti dos Lopes": {
                "lat": -3.1667,
                "lng": -41.8667
            },
            "Jussara": {
                "lat": -11.0469,
                "lng": -41.9689
            },
            "Amp\u00e8re": {
                "lat": -25.915,
                "lng": -53.4728
            },
            "Tapau\u00e1": {
                "lat": -5.6278,
                "lng": -63.1828
            },
            "Xapuri": {
                "lat": -10.6519,
                "lng": -68.5039
            },
            "Buritirama": {
                "lat": -5.5928,
                "lng": -47.0178
            },
            "Bequim\u00e3o": {
                "lat": -2.4333,
                "lng": -44.7833
            },
            "Cara\u00fabas": {
                "lat": -5.7928,
                "lng": -37.5569
            },
            "Cara\u00ed": {
                "lat": -17.1889,
                "lng": -41.695
            },
            "Guap\u00f3": {
                "lat": -16.8297,
                "lng": -49.5353
            },
            "Ipanema": {
                "lat": -19.8008,
                "lng": -41.7128
            },
            "Pauini": {
                "lat": -7.7139,
                "lng": -66.9758
            },
            "S\u00e3o Vicente Ferrer": {
                "lat": -2.4089,
                "lng": -44.415
            },
            "Nova Granada": {
                "lat": -20.5339,
                "lng": -49.3142
            },
            "Pariquera-A\u00e7u": {
                "lat": -24.715,
                "lng": -47.8811
            },
            "Alto Araguaia": {
                "lat": -17.3167,
                "lng": -53.2
            },
            "Barro": {
                "lat": -7.1769,
                "lng": -38.7819
            },
            "Canto do Buriti": {
                "lat": -8.1167,
                "lng": -42.9667
            },
            "Juqui\u00e1": {
                "lat": -24.3208,
                "lng": -47.6347
            },
            "Paranapanema": {
                "lat": -23.3886,
                "lng": -48.7228
            },
            "Presidente M\u00e9dici": {
                "lat": -11.1758,
                "lng": -61.9008
            },
            "M\u00e2ncio Lima": {
                "lat": -7.6139,
                "lng": -72.8958
            },
            "Monte Azul Paulista": {
                "lat": -20.9072,
                "lng": -48.6414
            },
            "Marechal Taumaturgo": {
                "lat": -8.9472,
                "lng": -72.7903
            },
            "Pitimbu": {
                "lat": -7.4708,
                "lng": -34.8089
            },
            "Tonantins": {
                "lat": -2.8728,
                "lng": -67.8019
            },
            "Realeza": {
                "lat": -25.7669,
                "lng": -53.5269
            },
            "\u00c1guas Formosas": {
                "lat": -17.0819,
                "lng": -40.9358
            },
            "Ara\u00e7oiaba": {
                "lat": -7.7881,
                "lng": -35.0897
            },
            "Venceslau Br\u00e1s": {
                "lat": -23.8739,
                "lng": -49.8028
            },
            "Jaic\u00f3s": {
                "lat": -7.35,
                "lng": -41.1333
            },
            "Cocos": {
                "lat": -14.1839,
                "lng": -44.5339
            },
            "Papanduva": {
                "lat": -26.4167,
                "lng": -50.15
            },
            "Guaran\u00e9sia": {
                "lat": -21.2989,
                "lng": -46.8028
            },
            "Passagem Franca": {
                "lat": -6.1667,
                "lng": -43.7833
            },
            "Vargem Alta": {
                "lat": -20.6708,
                "lng": -41.0069
            },
            "Contenda": {
                "lat": -25.6758,
                "lng": -49.535
            },
            "Cerqueira C\u00e9sar": {
                "lat": -23.0356,
                "lng": -49.1661
            },
            "Buti\u00e1": {
                "lat": -30.12,
                "lng": -51.9619
            },
            "Pindoba\u00e7u": {
                "lat": -10.7333,
                "lng": -40.35
            },
            "S\u00e3o Jos\u00e9 de Piranhas": {
                "lat": -7.1167,
                "lng": -38.5
            },
            "Taquarana": {
                "lat": -9.645,
                "lng": -36.4969
            },
            "\u00c1gua Branca": {
                "lat": -7.5119,
                "lng": -37.6408
            },
            "Lagoa de Itaenga": {
                "lat": -7.9358,
                "lng": -35.29
            },
            "Cafel\u00e2ndia": {
                "lat": -21.8025,
                "lng": -49.61
            },
            "Chapada dos Guimar\u00e3es": {
                "lat": -15.4608,
                "lng": -55.75
            },
            "Bar\u00e3o do Graja\u00fa": {
                "lat": -6.75,
                "lng": -43.0167
            },
            "Caconde": {
                "lat": -21.5289,
                "lng": -46.6439
            },
            "Lagoa Formosa": {
                "lat": -18.7789,
                "lng": -46.4078
            },
            "Guare\u00ed": {
                "lat": -23.3728,
                "lng": -48.1842
            },
            "Formoso do Araguaia": {
                "lat": -11.8025,
                "lng": -49.5283
            },
            "Concei\u00e7\u00e3o": {
                "lat": -7.5619,
                "lng": -38.5089
            },
            "Horizontina": {
                "lat": -27.6258,
                "lng": -54.3078
            },
            "Varzel\u00e2ndia": {
                "lat": -15.7047,
                "lng": -44.0283
            },
            "Barcelos": {
                "lat": -0.975,
                "lng": -62.9239
            },
            "S\u00e3o Jos\u00e9 da Coroa Grande": {
                "lat": -8.9,
                "lng": -35.15
            },
            "Pastos Bons": {
                "lat": -6.6019,
                "lng": -44.0769
            },
            "Ibirataia": {
                "lat": -14.0667,
                "lng": -39.6333
            },
            "Terra Santa": {
                "lat": -2.1039,
                "lng": -56.4869
            },
            "Itaju\u00edpe": {
                "lat": -14.6833,
                "lng": -39.3667
            },
            "Presidente Oleg\u00e1rio": {
                "lat": -18.4178,
                "lng": -46.4178
            },
            "Alt\u00f4nia": {
                "lat": -23.9333,
                "lng": -53.9
            },
            "Aroeiras": {
                "lat": -7.545,
                "lng": -35.7078
            },
            "Afr\u00e2nio": {
                "lat": -8.515,
                "lng": -41.005
            },
            "S\u00e3o Raimundo das Mangabeiras": {
                "lat": -7.0167,
                "lng": -45.4833
            },
            "Carna\u00edba": {
                "lat": -7.805,
                "lng": -37.7939
            },
            "Ribeir\u00e3o Branco": {
                "lat": -24.2208,
                "lng": -48.7656
            },
            "Uba\u00edra": {
                "lat": -13.2667,
                "lng": -39.65
            },
            "Urucar\u00e1": {
                "lat": -2.5358,
                "lng": -57.76
            },
            "Sanhar\u00f3": {
                "lat": -8.3608,
                "lng": -36.5658
            },
            "Seara": {
                "lat": -27.1167,
                "lng": -52.2833
            },
            "Carlos Chagas": {
                "lat": -17.7056,
                "lng": -40.7631
            },
            "Engenheiro Coelho": {
                "lat": -22.4883,
                "lng": -47.215
            },
            "Reriutaba": {
                "lat": -4.1667,
                "lng": -40.5833
            },
            "Miracema do Tocantins": {
                "lat": -9.5669,
                "lng": -48.3919
            },
            "Garuva": {
                "lat": -26.0269,
                "lng": -48.855
            },
            "Morros": {
                "lat": -2.8639,
                "lng": -44.0389
            },
            "Miracatu": {
                "lat": -24.2808,
                "lng": -47.46
            },
            "Matip\u00f3": {
                "lat": -20.2839,
                "lng": -42.3408
            },
            "Morro da Fuma\u00e7a": {
                "lat": -28.65,
                "lng": -49.2
            },
            "Colina": {
                "lat": -20.7136,
                "lng": -48.5411
            },
            "Madre de Deus": {
                "lat": -12.7408,
                "lng": -38.6208
            },
            "Anicuns": {
                "lat": -16.4628,
                "lng": -49.9606
            },
            "Potirendaba": {
                "lat": -21.0483,
                "lng": -49.3772
            },
            "Alc\u00e2ntara": {
                "lat": -2.4089,
                "lng": -44.415
            },
            "Pio IX": {
                "lat": -6.8378,
                "lng": -40.5789
            },
            "Lagoa da Canoa": {
                "lat": -9.83,
                "lng": -36.7378
            },
            "Matel\u00e2ndia": {
                "lat": -25.25,
                "lng": -53.9833
            },
            "Belo Campo": {
                "lat": -15.0378,
                "lng": -41.26
            },
            "Nordestina": {
                "lat": -10.8333,
                "lng": -39.4333
            },
            "Quitandinha": {
                "lat": -25.8719,
                "lng": -49.4978
            },
            "Inhapi": {
                "lat": -9.2189,
                "lng": -37.7539
            },
            "Aragar\u00e7as": {
                "lat": -15.9025,
                "lng": -52.2419
            },
            "Rio Maria": {
                "lat": -7.3108,
                "lng": -50.0478
            },
            "Itapororoca": {
                "lat": -6.85,
                "lng": -35.25
            },
            "Ces\u00e1rio Lange": {
                "lat": -23.2267,
                "lng": -47.9531
            },
            "Baependi": {
                "lat": -21.95,
                "lng": -44.8833
            },
            "S\u00e3o Benedito do Rio Preto": {
                "lat": -3.3333,
                "lng": -43.5833
            },
            "Capoeiras": {
                "lat": -8.735,
                "lng": -36.6269
            },
            "Mirante do Paranapanema": {
                "lat": -22.2919,
                "lng": -51.9064
            },
            "Picu\u00ed": {
                "lat": -6.5167,
                "lng": -36.35
            },
            "Tai\u00f3": {
                "lat": -27.1,
                "lng": -49.9833
            },
            "Riach\u00e3o do Dantas": {
                "lat": -11.0689,
                "lng": -37.725
            },
            "Pontalina": {
                "lat": -17.52,
                "lng": -49.4392
            },
            "Morretes": {
                "lat": -25.4769,
                "lng": -48.8339
            },
            "Bel\u00e9m de S\u00e3o Francisco": {
                "lat": -8.7578,
                "lng": -38.9639
            },
            "Aveiro": {
                "lat": -3.6167,
                "lng": -55.3167
            },
            "Paraibano": {
                "lat": -6.5,
                "lng": -44.0167
            },
            "Paraibuna": {
                "lat": -23.3861,
                "lng": -45.6622
            },
            "Luz": {
                "lat": -19.8008,
                "lng": -45.6858
            },
            "Comodoro": {
                "lat": -13.65,
                "lng": -59.7833
            },
            "J\u00falio de Castilhos": {
                "lat": -29.2269,
                "lng": -53.6817
            },
            "Manga": {
                "lat": -14.7558,
                "lng": -43.9319
            },
            "Baixa Grande": {
                "lat": -11.96,
                "lng": -40.1678
            },
            "Nazar\u00e9 Paulista": {
                "lat": -23.1808,
                "lng": -46.395
            },
            "Farias Brito": {
                "lat": -6.9272,
                "lng": -39.5728
            },
            "Gameleira": {
                "lat": -8.5833,
                "lng": -35.3833
            },
            "Serrita": {
                "lat": -7.9333,
                "lng": -39.3167
            },
            "Amaraji": {
                "lat": -8.4,
                "lng": -35.45
            },
            "Solon\u00f3pole": {
                "lat": -5.7333,
                "lng": -39.0167
            },
            "Ing\u00e1": {
                "lat": -7.2678,
                "lng": -35.6128
            },
            "Una": {
                "lat": -15.2833,
                "lng": -39.0667
            },
            "Terra Roxa d\u2019Oeste": {
                "lat": -24.1569,
                "lng": -54.0969
            },
            "Campos Belos": {
                "lat": -13.0369,
                "lng": -46.7719
            },
            "Varjota": {
                "lat": -4.1939,
                "lng": -40.4769
            },
            "Belterra": {
                "lat": -2.6358,
                "lng": -54.9369
            },
            "Antonina": {
                "lat": -25.4289,
                "lng": -48.7119
            },
            "Madalena": {
                "lat": -4.8569,
                "lng": -39.5769
            },
            "\u00c1gua Azul do Norte": {
                "lat": -6.7908,
                "lng": -50.4669
            },
            "Pedra Preta": {
                "lat": -16.6228,
                "lng": -54.4739
            },
            "Tapero\u00e1": {
                "lat": -7.2078,
                "lng": -36.8261
            },
            "Cristin\u00e1polis": {
                "lat": -11.4758,
                "lng": -37.755
            },
            "Itaber\u00e1": {
                "lat": -23.8619,
                "lng": -49.1372
            },
            "Fund\u00e3o": {
                "lat": -19.9328,
                "lng": -40.4069
            },
            "Campina Verde": {
                "lat": -19.5333,
                "lng": -49.4833
            },
            "Serra Preta": {
                "lat": -12.16,
                "lng": -39.3319
            },
            "Lagoa do Carro": {
                "lat": -7.845,
                "lng": -35.32
            },
            "Antenor Navarro": {
                "lat": -6.7333,
                "lng": -38.45
            },
            "Sapea\u00e7u": {
                "lat": -12.7333,
                "lng": -39.2167
            },
            "Santo Anast\u00e1cio": {
                "lat": -21.8383,
                "lng": -51.6103
            },
            "Quipap\u00e1": {
                "lat": -8.8333,
                "lng": -36.0333
            },
            "Olho d\u2019\u00c1gua das Cunh\u00e3s": {
                "lat": -4.1389,
                "lng": -45.12
            },
            "Itua\u00e7u": {
                "lat": -13.8167,
                "lng": -41.3
            },
            "N\u00e3o-Me-Toque": {
                "lat": -28.4589,
                "lng": -52.8208
            },
            "Filad\u00e9lfia": {
                "lat": -10.7408,
                "lng": -40.1328
            },
            "F\u00e1tima": {
                "lat": -10.6,
                "lng": -38.2169
            },
            "Guapiara": {
                "lat": -24.185,
                "lng": -48.5328
            },
            "Carm\u00f3polis de Minas": {
                "lat": -20.55,
                "lng": -44.6333
            },
            "Our\u00e9m": {
                "lat": -1.55,
                "lng": -47.1
            },
            "Montanha": {
                "lat": -18.1269,
                "lng": -40.3628
            },
            "P\u00f4rto Grande": {
                "lat": 0.7128,
                "lng": -51.4128
            },
            "Itapi\u00fana": {
                "lat": -4.55,
                "lng": -38.95
            },
            "\u00c1guas de Lind\u00f3ia": {
                "lat": -22.4767,
                "lng": -46.6333
            },
            "S\u00e3o Lu\u00eds Gonzaga do Maranh\u00e3o": {
                "lat": -4.38,
                "lng": -44.67
            },
            "Itanh\u00e9m": {
                "lat": -17.15,
                "lng": -40.3333
            },
            "Jucurutu": {
                "lat": -6.0339,
                "lng": -37.02
            },
            "Cairu": {
                "lat": -13.4869,
                "lng": -39.0439
            },
            "Dian\u00f3polis": {
                "lat": -11.6258,
                "lng": -46.8203
            },
            "Sapucaia": {
                "lat": -21.995,
                "lng": -42.9139
            },
            "Pirapemas": {
                "lat": -3.7167,
                "lng": -44.2333
            },
            "Major Isidoro": {
                "lat": -9.5319,
                "lng": -36.985
            },
            "Mairi": {
                "lat": -11.7167,
                "lng": -40.1333
            },
            "Jaguaripe": {
                "lat": -13.1128,
                "lng": -38.8958
            },
            "Pirapora do Bom Jesus": {
                "lat": -23.3972,
                "lng": -47.0028
            },
            "S\u00e3o Miguel do Tapuio": {
                "lat": -5.5,
                "lng": -41.3333
            },
            "Abar\u00e9": {
                "lat": -8.7167,
                "lng": -39.1167
            },
            "Terenos": {
                "lat": -20.4419,
                "lng": -54.86
            },
            "Carir\u00e9": {
                "lat": -3.9508,
                "lng": -40.4728
            },
            "S\u00e3o Francisco de Assis": {
                "lat": -29.5503,
                "lng": -55.1311
            },
            "Ubaitaba": {
                "lat": -14.3,
                "lng": -39.3333
            },
            "Tijucas do Sul": {
                "lat": -25.9333,
                "lng": -49.1833
            },
            "Boqueir\u00e3o": {
                "lat": -7.4819,
                "lng": -36.135
            },
            "Piritiba": {
                "lat": -11.7333,
                "lng": -40.5667
            },
            "Arroio Grande": {
                "lat": -32.2378,
                "lng": -53.0869
            },
            "Rio Bananal": {
                "lat": -19.265,
                "lng": -40.3328
            },
            "Malacacheta": {
                "lat": -17.8419,
                "lng": -42.0769
            },
            "Piratini": {
                "lat": -31.4478,
                "lng": -53.1039
            },
            "Mendes": {
                "lat": -22.5269,
                "lng": -43.7328
            },
            "Augustin\u00f3polis": {
                "lat": -5.4658,
                "lng": -47.8878
            },
            "Croat\u00e1": {
                "lat": -4.4,
                "lng": -40.9108
            },
            "Urucuia": {
                "lat": -16.1308,
                "lng": -45.7425
            },
            "Umirim": {
                "lat": -3.6833,
                "lng": -39.3333
            },
            "Corb\u00e9lia": {
                "lat": -24.8,
                "lng": -53.3
            },
            "Pocinhos": {
                "lat": -7.0769,
                "lng": -36.0608
            },
            "Cafarnaum": {
                "lat": -11.7,
                "lng": -41.4667
            },
            "Cruz do Esp\u00edrito Santo": {
                "lat": -7.1333,
                "lng": -35.1
            },
            "Pe\u00e7anha": {
                "lat": -18.5489,
                "lng": -42.5569
            },
            "Rio Pomba": {
                "lat": -21.2667,
                "lng": -43.1667
            },
            "Nova Era": {
                "lat": -19.75,
                "lng": -43.0378
            },
            "Camocim de S\u00e3o F\u00e9lix": {
                "lat": -8.35,
                "lng": -35.7667
            },
            "Viradouro": {
                "lat": -20.8731,
                "lng": -48.2969
            },
            "Borda da Mata": {
                "lat": -22.2739,
                "lng": -46.165
            },
            "S\u00e3o Domingos do Prata": {
                "lat": -19.865,
                "lng": -42.9678
            },
            "Abelardo Luz": {
                "lat": -26.5647,
                "lng": -52.3283
            },
            "Padre Para\u00edso": {
                "lat": -17.0667,
                "lng": -41.4833
            },
            "Muniz Freire": {
                "lat": -20.4639,
                "lng": -41.4128
            },
            "Otac\u00edlio Costa": {
                "lat": -27.5667,
                "lng": -50.1167
            },
            "Mundo Novo": {
                "lat": -11.8589,
                "lng": -40.4719
            },
            "Fortim": {
                "lat": -4.45,
                "lng": -37.7833
            },
            "Porci\u00fancula": {
                "lat": -20.9628,
                "lng": -42.0408
            },
            "Santo Ant\u00f4nio do Amparo": {
                "lat": -20.9458,
                "lng": -44.9189
            },
            "Arinos": {
                "lat": -15.9119,
                "lng": -46.0964
            },
            "Seng\u00e9s": {
                "lat": -24.1,
                "lng": -49.4833
            },
            "Capistrano": {
                "lat": -4.4667,
                "lng": -38.9167
            },
            "Venturosa": {
                "lat": -8.5747,
                "lng": -36.8742
            },
            "Amarante": {
                "lat": -6.2408,
                "lng": -42.855
            },
            "Jaguaretama": {
                "lat": -5.6167,
                "lng": -38.7667
            },
            "Cip\u00f3": {
                "lat": -11.1,
                "lng": -38.5169
            },
            "Abadi\u00e2nia": {
                "lat": -16.1,
                "lng": -48.8
            },
            "Resplendor": {
                "lat": -19.3333,
                "lng": -41.25
            },
            "Carnaubal": {
                "lat": -4.1669,
                "lng": -40.9428
            },
            "Itacarambi": {
                "lat": -15.1017,
                "lng": -44.1067
            },
            "Carmo": {
                "lat": -21.9339,
                "lng": -42.6089
            },
            "Banabui\u00fa": {
                "lat": -5.2833,
                "lng": -38.8667
            },
            "Anori": {
                "lat": -3.7728,
                "lng": -61.6442
            },
            "Araruna": {
                "lat": -23.9319,
                "lng": -52.4958
            },
            "Dormentes": {
                "lat": -8.4469,
                "lng": -40.7708
            },
            "Cacimba de Dentro": {
                "lat": -6.6419,
                "lng": -35.79
            },
            "Sum\u00e9": {
                "lat": -7.65,
                "lng": -36.9167
            },
            "Concei\u00e7\u00e3o do Almeida": {
                "lat": -12.6178,
                "lng": -39.9558
            },
            "Iati": {
                "lat": -9.05,
                "lng": -36.85
            },
            "Massaranduba": {
                "lat": -7.2,
                "lng": -35.7889
            },
            "Po\u00e7\u00e3o de Pedras": {
                "lat": -4.75,
                "lng": -44.9333
            },
            "Itirapina": {
                "lat": -22.2528,
                "lng": -47.8228
            },
            "Tanque Novo": {
                "lat": -13.5458,
                "lng": -42.4908
            },
            "C\u00e1ssia": {
                "lat": -20.5828,
                "lng": -46.9219
            },
            "Teju\u00e7uoca": {
                "lat": -4.0167,
                "lng": -39.5167
            },
            "Joaquim Gomes": {
                "lat": -9.1328,
                "lng": -35.7478
            },
            "Bom Sucesso": {
                "lat": -21.0328,
                "lng": -44.7578
            },
            "Monsenhor Tabosa": {
                "lat": -4.7833,
                "lng": -40.0667
            },
            "Ipaba": {
                "lat": -19.3833,
                "lng": -42.4167
            },
            "Regenera\u00e7\u00e3o": {
                "lat": -6.2378,
                "lng": -42.6878
            },
            "Correntes": {
                "lat": -9.1289,
                "lng": -36.33
            },
            "Pouso Redondo": {
                "lat": -27.2578,
                "lng": -49.9339
            },
            "Manaquiri": {
                "lat": -3.4281,
                "lng": -60.4594
            },
            "Mata Roma": {
                "lat": -3.6167,
                "lng": -43.1167
            },
            "Elias Fausto": {
                "lat": -23.0428,
                "lng": -47.3739
            },
            "Serra Dourada": {
                "lat": -12.7608,
                "lng": -43.95
            },
            "Crix\u00e1s": {
                "lat": -14.5333,
                "lng": -49.9667
            },
            "Souto Soares": {
                "lat": -12.0889,
                "lng": -41.6378
            },
            "Paulino Neves": {
                "lat": -2.7167,
                "lng": -42.55
            },
            "Conde\u00faba": {
                "lat": -14.8833,
                "lng": -41.9833
            },
            "Itarantim": {
                "lat": -15.65,
                "lng": -40.05
            },
            "Porteiras": {
                "lat": -7.535,
                "lng": -39.1178
            },
            "Ilhota": {
                "lat": -26.9,
                "lng": -48.8667
            },
            "Ribeir\u00f3polis": {
                "lat": -10.5389,
                "lng": -37.4361
            },
            "S\u00e3o Sim\u00e3o": {
                "lat": -21.4789,
                "lng": -47.5508
            },
            "Piranga": {
                "lat": -20.6833,
                "lng": -43.3
            },
            "Cari\u00fas": {
                "lat": -6.5333,
                "lng": -39.5
            },
            "Macatuba": {
                "lat": -22.5022,
                "lng": -48.7114
            },
            "Juazeirinho": {
                "lat": -7.0678,
                "lng": -36.5778
            },
            "Brasnorte": {
                "lat": -12.155,
                "lng": -57.9789
            },
            "Santo Ant\u00f4nio do Leverger": {
                "lat": -15.8658,
                "lng": -56.0769
            },
            "Itajobi": {
                "lat": -21.3178,
                "lng": -49.0539
            },
            "Fortuna": {
                "lat": -5.7328,
                "lng": -44.1578
            },
            "Ibitit\u00e1": {
                "lat": -11.5667,
                "lng": -41.9667
            },
            "Serafina Cor\u00eaa": {
                "lat": -28.7119,
                "lng": -51.935
            },
            "Santana do Cariri": {
                "lat": -7.1833,
                "lng": -39.7333
            },
            "Tibau do Sul": {
                "lat": -6.1869,
                "lng": -35.0919
            },
            "Governador Celso Ramos": {
                "lat": -27.315,
                "lng": -48.5589
            },
            "Carl\u00f3polis": {
                "lat": -23.4167,
                "lng": -49.6833
            },
            "S\u00e3o Jo\u00e3o dos Poleiros": {
                "lat": -5.1139,
                "lng": -43.81
            },
            "Boa Vista do Tupim": {
                "lat": -12.66,
                "lng": -40.6089
            },
            "Canutama": {
                "lat": -6.5339,
                "lng": -64.3828
            },
            "Japaratuba": {
                "lat": -10.5928,
                "lng": -36.94
            },
            "S\u00e3o Paulo do Potengi": {
                "lat": -5.9167,
                "lng": -35.75
            },
            "Vila Bela da Sant\u00edssima Trindade": {
                "lat": -15.0078,
                "lng": -59.9508
            },
            "Campo do Brito": {
                "lat": -10.7328,
                "lng": -37.4928
            },
            "\u00c1gua Clara": {
                "lat": -20.4478,
                "lng": -52.8778
            },
            "P\u00f4rto Acre": {
                "lat": -9.5878,
                "lng": -67.5328
            },
            "S\u00e3o Vicente F\u00e9rrer": {
                "lat": -7.5908,
                "lng": -35.4908
            },
            "S\u00e3o Caetano de Odivelas": {
                "lat": -0.75,
                "lng": -48.0333
            },
            "Ara\u00e7agi": {
                "lat": -6.8528,
                "lng": -35.3808
            },
            "Itapiranga": {
                "lat": -2.7489,
                "lng": -58.0219
            },
            "Salitre": {
                "lat": -7.2839,
                "lng": -40.4606
            },
            "Itoror\u00f3": {
                "lat": -15.1169,
                "lng": -40.07
            },
            "Piquet Carneiro": {
                "lat": -5.8,
                "lng": -39.4167
            },
            "Ibipeba": {
                "lat": -11.6333,
                "lng": -42.0333
            },
            "Mangueirinha": {
                "lat": -25.95,
                "lng": -52.15
            },
            "Get\u00falio Vargas": {
                "lat": -27.89,
                "lng": -52.2278
            },
            "Buritama": {
                "lat": -21.0667,
                "lng": -50.1475
            },
            "Cidreira": {
                "lat": -30.1608,
                "lng": -50.2339
            },
            "Pl\u00e1cido de Castro": {
                "lat": -10.2758,
                "lng": -67.15
            },
            "Cabaceiras do Paragua\u00e7u": {
                "lat": -12.55,
                "lng": -39.1833
            },
            "Itaquitinga": {
                "lat": -7.6667,
                "lng": -35.1
            },
            "Indiaroba": {
                "lat": -11.5189,
                "lng": -37.5119
            },
            "Coaraci": {
                "lat": -14.6333,
                "lng": -39.5333
            },
            "Santa Maria das Barreiras": {
                "lat": -8.855,
                "lng": -49.7219
            },
            "Pedregulho": {
                "lat": -20.2569,
                "lng": -47.4767
            },
            "Caiap\u00f4nia": {
                "lat": -16.9519,
                "lng": -51.8061
            },
            "Encruzilhada": {
                "lat": -15.5308,
                "lng": -40.9089
            },
            "Pianc\u00f3": {
                "lat": -7.1978,
                "lng": -37.9289
            },
            "Charqueada": {
                "lat": -22.5097,
                "lng": -47.7781
            },
            "Ne\u00f3polis": {
                "lat": -10.32,
                "lng": -36.5789
            },
            "Cajari": {
                "lat": -3.3208,
                "lng": -45.0108
            },
            "Passa Quatro": {
                "lat": -22.39,
                "lng": -44.9669
            },
            "Faxinal": {
                "lat": -24.0008,
                "lng": -51.32
            },
            "Po\u00e7o Fundo": {
                "lat": -21.7808,
                "lng": -45.965
            },
            "Nova Resende": {
                "lat": -21.1258,
                "lng": -46.42
            },
            "Caridade": {
                "lat": -4.2319,
                "lng": -39.1928
            },
            "Barra de Santo Ant\u00f4nio": {
                "lat": -9.405,
                "lng": -35.5069
            },
            "Nova Ol\u00edmpia": {
                "lat": -14.7969,
                "lng": -57.2878
            },
            "Central": {
                "lat": -11.1358,
                "lng": -42.1128
            },
            "Sananduva": {
                "lat": -27.95,
                "lng": -51.8
            },
            "Tremedal": {
                "lat": -14.9758,
                "lng": -41.4108
            },
            "Bacuri": {
                "lat": -1.7028,
                "lng": -45.1339
            },
            "Acarape": {
                "lat": -4.2242,
                "lng": -38.7083
            },
            "S\u00e3o Francisco do Guapor\u00e9": {
                "lat": -12.0522,
                "lng": -63.5675
            },
            "Raposos": {
                "lat": -19.9669,
                "lng": -43.8039
            },
            "Utinga": {
                "lat": -12.0819,
                "lng": -41.0939
            },
            "Altin\u00f3polis": {
                "lat": -21.0231,
                "lng": -47.3728
            },
            "Tocantins": {
                "lat": -21.175,
                "lng": -43.0178
            },
            "C\u00earro Azul": {
                "lat": -24.8239,
                "lng": -49.2608
            },
            "Jacupiranga": {
                "lat": -24.6925,
                "lng": -48.0022
            },
            "Ubat\u00e3": {
                "lat": -14.2,
                "lng": -39.5167
            },
            "Canudos": {
                "lat": -9.964,
                "lng": -39.164
            },
            "Fartura": {
                "lat": -23.3883,
                "lng": -49.51
            },
            "Agudo": {
                "lat": -29.6453,
                "lng": -53.24
            },
            "Cajueiro": {
                "lat": -9.3967,
                "lng": -36.1536
            },
            "Giru\u00e1": {
                "lat": -28.0278,
                "lng": -54.35
            },
            "S\u00e1tiro Dias": {
                "lat": -11.6,
                "lng": -38.6
            },
            "S\u00e3o Louren\u00e7o da Serra": {
                "lat": -23.8528,
                "lng": -46.9428
            },
            "Balne\u00e1rio do Rinc\u00e3o": {
                "lat": -28.8344,
                "lng": -49.2361
            },
            "Cruz Machado": {
                "lat": -26.0167,
                "lng": -51.35
            },
            "Campanha": {
                "lat": -21.8389,
                "lng": -45.3914
            },
            "Apor\u00e1": {
                "lat": -11.66,
                "lng": -38.0808
            },
            "Sertan\u00f3polis": {
                "lat": -23.0667,
                "lng": -51.0333
            },
            "Cumaru": {
                "lat": -8.0058,
                "lng": -35.6969
            },
            "Quixel\u00f4": {
                "lat": -6.2539,
                "lng": -39.2019
            },
            "Marmeleiro": {
                "lat": -26.1489,
                "lng": -53.0258
            },
            "Pia\u00e7abu\u00e7u": {
                "lat": -10.4058,
                "lng": -36.4339
            },
            "Cerejeiras": {
                "lat": -13.1889,
                "lng": -60.8122
            },
            "Alvar\u00e3es": {
                "lat": -3.2208,
                "lng": -64.8042
            },
            "Mario Campos": {
                "lat": -20.0558,
                "lng": -44.1878
            },
            "Tupi Paulista": {
                "lat": -21.3811,
                "lng": -51.5706
            },
            "Demerval Lob\u00e3o": {
                "lat": -5.3667,
                "lng": -42.6833
            },
            "Jata\u00faba": {
                "lat": -7.9667,
                "lng": -36.4833
            },
            "Tapiramut\u00e1": {
                "lat": -11.85,
                "lng": -40.7833
            },
            "Col\u00f4nia Leopoldina": {
                "lat": -8.9089,
                "lng": -35.725
            },
            "Jo\u00e3o Neiva": {
                "lat": -19.7578,
                "lng": -40.3858
            },
            "Novo Air\u00e3o": {
                "lat": -2.6208,
                "lng": -60.9439
            },
            "Ipor\u00e3": {
                "lat": -24.0028,
                "lng": -53.7039
            },
            "Itatim": {
                "lat": -12.7119,
                "lng": -39.6978
            },
            "Santa Juliana": {
                "lat": -19.865,
                "lng": -47.44
            },
            "Mirangaba": {
                "lat": -10.95,
                "lng": -40.5833
            },
            "Correia Pinto": {
                "lat": -27.5833,
                "lng": -50.4
            },
            "Orizona": {
                "lat": -17.0339,
                "lng": -48.2978
            },
            "Campina da Lagoa": {
                "lat": -24.55,
                "lng": -52.7167
            },
            "Maruim": {
                "lat": -10.7378,
                "lng": -37.0819
            },
            "Nova Brasil\u00e2ndia d\u2019Oeste": {
                "lat": -11.7197,
                "lng": -62.3158
            },
            "Colorado do Oeste": {
                "lat": -13.1167,
                "lng": -60.5417
            },
            "Palmitos": {
                "lat": -27.0675,
                "lng": -53.1611
            },
            "Frecheirinha": {
                "lat": -3.7667,
                "lng": -40.8
            },
            "S\u00e3o Pedro do Sul": {
                "lat": -29.6167,
                "lng": -54.1667
            },
            "Ipu\u00e3": {
                "lat": -20.4381,
                "lng": -48.0122
            },
            "Canapi": {
                "lat": -9.1269,
                "lng": -37.605
            },
            "Frei Paulo": {
                "lat": -10.5489,
                "lng": -37.5339
            },
            "Santa Cec\u00edlia": {
                "lat": -26.9608,
                "lng": -50.4269
            },
            "Bituruna": {
                "lat": -26.1667,
                "lng": -51.5667
            },
            "Itariri": {
                "lat": -24.2889,
                "lng": -47.1739
            },
            "Igapor\u00e3": {
                "lat": -13.7667,
                "lng": -42.7167
            },
            "Gl\u00f3ria": {
                "lat": -9.3389,
                "lng": -38.2569
            },
            "Nobres": {
                "lat": -14.7333,
                "lng": -56.3333
            },
            "Panorama": {
                "lat": -21.3564,
                "lng": -51.86
            },
            "Ros\u00e1rio Oeste": {
                "lat": -14.8358,
                "lng": -56.4278
            },
            "Estrela de Alagoas": {
                "lat": -9.39,
                "lng": -36.76
            },
            "Tururu": {
                "lat": -3.5833,
                "lng": -39.45
            },
            "Messias": {
                "lat": -9.3828,
                "lng": -35.8419
            },
            "Nova Olinda": {
                "lat": -7.6319,
                "lng": -48.4228
            },
            "Malhada": {
                "lat": -14.3358,
                "lng": -43.7739
            },
            "Cruz\u00edlia": {
                "lat": -21.8333,
                "lng": -44.8
            },
            "Urandi": {
                "lat": -14.7708,
                "lng": -42.655
            },
            "Eldorado": {
                "lat": -23.7869,
                "lng": -54.2839
            },
            "Jupi": {
                "lat": -8.7167,
                "lng": -36.4167
            },
            "Inhuma": {
                "lat": -6.6678,
                "lng": -41.7078
            },
            "S\u00e3o Jo\u00e3o Evangelista": {
                "lat": -18.5478,
                "lng": -42.7628
            },
            "Atalaia do Norte": {
                "lat": -4.3719,
                "lng": -70.1919
            },
            "Itajib\u00e1": {
                "lat": -14.2833,
                "lng": -39.85
            },
            "Uira\u00fana": {
                "lat": -6.5178,
                "lng": -38.4119
            },
            "Guamar\u00e9": {
                "lat": -5.1,
                "lng": -36.3167
            },
            "Lagoa da Confus\u00e3o": {
                "lat": -10.7939,
                "lng": -49.6239
            },
            "Pereiro": {
                "lat": -6.045,
                "lng": -38.4608
            },
            "Itanhandu": {
                "lat": -22.3,
                "lng": -44.95
            },
            "Conchas": {
                "lat": -23.0153,
                "lng": -48.0106
            },
            "Sumidouro": {
                "lat": -22.05,
                "lng": -42.675
            },
            "Barrocas": {
                "lat": -11.5289,
                "lng": -39.0778
            },
            "Sales\u00f3polis": {
                "lat": -23.8461,
                "lng": -46.6297
            },
            "Espumoso": {
                "lat": -28.7333,
                "lng": -52.85
            },
            "Meruoca": {
                "lat": -3.5419,
                "lng": -40.455
            },
            "Biritinga": {
                "lat": -11.6228,
                "lng": -38.8075
            },
            "Am\u00e9rica Dourada": {
                "lat": -11.455,
                "lng": -41.4358
            },
            "Jaguapit\u00e3": {
                "lat": -23.1167,
                "lng": -51.55
            },
            "Mira\u00ed": {
                "lat": -21.2,
                "lng": -42.6167
            },
            "Piratininga": {
                "lat": -22.4128,
                "lng": -49.1347
            },
            "Natividade do Carangola": {
                "lat": -21.0419,
                "lng": -41.9728
            },
            "Clevel\u00e2ndia": {
                "lat": -26.3958,
                "lng": -52.4708
            },
            "Ipau\u00e7u": {
                "lat": -23.0569,
                "lng": -49.6267
            },
            "Alvin\u00f3polis": {
                "lat": -20.1069,
                "lng": -43.0489
            },
            "Corup\u00e1": {
                "lat": -26.4333,
                "lng": -49.2333
            },
            "Ferreiros": {
                "lat": -7.4478,
                "lng": -35.2439
            },
            "Brasil\u00e2ndia": {
                "lat": -17.0117,
                "lng": -46.0031
            },
            "Boa Esperan\u00e7a do Sul": {
                "lat": -21.9925,
                "lng": -48.3908
            },
            "Andorinha": {
                "lat": -10.345,
                "lng": -39.8328
            },
            "Dion\u00edsio Cerqueira": {
                "lat": -26.25,
                "lng": -53.6333
            },
            "Salinas da Margarida": {
                "lat": -12.8667,
                "lng": -38.7667
            },
            "Cand\u00f3i": {
                "lat": -25.7167,
                "lng": -52.1833
            },
            "Santa Br\u00edgida": {
                "lat": -9.7333,
                "lng": -38.1333
            },
            "Vian\u00f3polis": {
                "lat": -16.75,
                "lng": -48.5111
            },
            "Joa\u00edma": {
                "lat": -16.65,
                "lng": -41.0333
            },
            "Restinga S\u00eaca": {
                "lat": -29.8167,
                "lng": -53.3833
            },
            "Barra do Sul": {
                "lat": -26.4572,
                "lng": -48.6114
            },
            "Tarum\u00e3": {
                "lat": -22.7467,
                "lng": -50.5772
            },
            "Cujubim": {
                "lat": -9.3628,
                "lng": -62.5853
            },
            "Terra Rica": {
                "lat": -22.7167,
                "lng": -52.6333
            },
            "Bela Vista do Para\u00edso": {
                "lat": -23.0,
                "lng": -51.2
            },
            "Botelhos": {
                "lat": -21.6328,
                "lng": -46.395
            },
            "Ponto Novo": {
                "lat": -10.8619,
                "lng": -40.1339
            },
            "C\u00e2ndido de Abreu": {
                "lat": -24.5833,
                "lng": -51.3333
            },
            "Patroc\u00ednio Paulista": {
                "lat": -20.6394,
                "lng": -47.2817
            },
            "Buerarema": {
                "lat": -14.95,
                "lng": -39.3167
            },
            "Araputanga": {
                "lat": -15.4708,
                "lng": -58.3528
            },
            "Itamonte": {
                "lat": -22.2833,
                "lng": -44.8833
            },
            "S\u00edtio do Quinto": {
                "lat": -10.3667,
                "lng": -38.2167
            },
            "Tavares": {
                "lat": -7.6358,
                "lng": -37.8778
            },
            "Mozarl\u00e2ndia": {
                "lat": -14.7406,
                "lng": -50.5519
            },
            "Entre Rios de Minas": {
                "lat": -20.6708,
                "lng": -44.0658
            },
            "Pinda\u00ed": {
                "lat": -14.4833,
                "lng": -42.6833
            },
            "Alagoinha": {
                "lat": -8.4658,
                "lng": -36.7758
            },
            "Tarumirim": {
                "lat": -19.2808,
                "lng": -42.0069
            },
            "Coremas": {
                "lat": -7.0167,
                "lng": -37.9667
            },
            "Tapes": {
                "lat": -30.6728,
                "lng": -51.3958
            },
            "Capin\u00f3polis": {
                "lat": -18.6667,
                "lng": -49.5667
            },
            "Ibitiara": {
                "lat": -12.6519,
                "lng": -42.2178
            },
            "Rio Piracicaba": {
                "lat": -19.9289,
                "lng": -43.1739
            },
            "Teixeira": {
                "lat": -7.2228,
                "lng": -37.2539
            },
            "Cambuci": {
                "lat": -21.575,
                "lng": -41.9108
            },
            "Arroio dos Ratos": {
                "lat": -30.0769,
                "lng": -51.7289
            },
            "Nova Ponte": {
                "lat": -19.1983,
                "lng": -47.73
            },
            "Palmeirais": {
                "lat": -5.9667,
                "lng": -43.0667
            },
            "Sever\u00ednia": {
                "lat": -20.8089,
                "lng": -48.8028
            },
            "Bicas": {
                "lat": -21.725,
                "lng": -43.0589
            },
            "Pindorama": {
                "lat": -21.1858,
                "lng": -48.9069
            },
            "Bom Jesus do Galho": {
                "lat": -19.8289,
                "lng": -42.3158
            },
            "Rio Parana\u00edba": {
                "lat": -19.1939,
                "lng": -46.2469
            },
            "\u00c1gua Boa": {
                "lat": -17.9958,
                "lng": -42.3889
            },
            "Sonora": {
                "lat": -17.5769,
                "lng": -54.7578
            },
            "Rebou\u00e7as": {
                "lat": -25.6061,
                "lng": -50.6928
            },
            "Tenente Portela": {
                "lat": -27.3708,
                "lng": -53.7578
            },
            "\u00c1gua Fria": {
                "lat": -11.8669,
                "lng": -38.7669
            },
            "Po\u00e7o das Trincheiras": {
                "lat": -9.3128,
                "lng": -37.2858
            },
            "Barroquinha": {
                "lat": -3.0189,
                "lng": -41.1369
            },
            "Jacara\u00fa": {
                "lat": -6.6667,
                "lng": -35.3
            },
            "Jacaraci": {
                "lat": -14.85,
                "lng": -42.4333
            },
            "Ita\u00fa de Minas": {
                "lat": -20.7389,
                "lng": -46.7519
            },
            "Muqui": {
                "lat": -20.9519,
                "lng": -41.3458
            },
            "Jita\u00fana": {
                "lat": -14.0167,
                "lng": -39.95
            },
            "Sim\u00f5es": {
                "lat": -7.5989,
                "lng": -40.8178
            },
            "Alto Long\u00e1": {
                "lat": -5.25,
                "lng": -42.2
            },
            "Monte Alegre de Sergipe": {
                "lat": -10.0269,
                "lng": -37.5619
            },
            "Santo Ant\u00f4nio dos Lopes": {
                "lat": -4.8689,
                "lng": -44.36
            },
            "Puxinan\u00e3": {
                "lat": -7.1608,
                "lng": -35.9608
            },
            "Marechal Floriano": {
                "lat": -20.4128,
                "lng": -40.6828
            },
            "Imba\u00fa": {
                "lat": -24.445,
                "lng": -50.7608
            },
            "Maria da F\u00e9": {
                "lat": -22.3078,
                "lng": -45.375
            },
            "Braz\u00f3polis": {
                "lat": -22.4739,
                "lng": -45.6078
            },
            "Manuel Ribas": {
                "lat": -24.5158,
                "lng": -51.6678
            },
            "Sabin\u00f3polis": {
                "lat": -18.6667,
                "lng": -43.1
            },
            "Borborema": {
                "lat": -21.62,
                "lng": -49.0739
            },
            "Caldas": {
                "lat": -21.9239,
                "lng": -46.3858
            },
            "Campos Altos": {
                "lat": -19.6958,
                "lng": -46.1708
            },
            "Antas": {
                "lat": -10.4,
                "lng": -38.3328
            },
            "Adustina": {
                "lat": -10.5333,
                "lng": -38.1167
            },
            "Mira\u00edma": {
                "lat": -3.5833,
                "lng": -39.9833
            },
            "S\u00e3o Jos\u00e9 do Cedro": {
                "lat": -26.5,
                "lng": -53.5
            },
            "Mirandiba": {
                "lat": -8.1,
                "lng": -38.7333
            },
            "Alto Santo": {
                "lat": -5.5208,
                "lng": -38.2719
            },
            "Ipiranga": {
                "lat": -25.0239,
                "lng": -50.5839
            },
            "Astolfo Dutra": {
                "lat": -21.3167,
                "lng": -42.8667
            },
            "Ipangua\u00e7u": {
                "lat": -5.5,
                "lng": -36.85
            },
            "Gurinh\u00e9m": {
                "lat": -7.1333,
                "lng": -35.45
            },
            "Matias Barbosa": {
                "lat": -21.8667,
                "lng": -43.3167
            },
            "Curu\u00e1": {
                "lat": -1.8878,
                "lng": -55.1169
            },
            "Capit\u00e3o En\u00e9as": {
                "lat": -16.3178,
                "lng": -43.7092
            },
            "Lagoa Real": {
                "lat": -14.0333,
                "lng": -42.1333
            },
            "Valentim Gentil": {
                "lat": -20.4219,
                "lng": -50.0878
            },
            "Sider\u00f3polis": {
                "lat": -28.5833,
                "lng": -49.4333
            },
            "Aiuaba": {
                "lat": -6.6333,
                "lng": -40.1167
            },
            "Italva": {
                "lat": -21.4208,
                "lng": -41.6908
            },
            "Lagoa dos Gatos": {
                "lat": -8.6578,
                "lng": -35.9
            },
            "Nina Rodrigues": {
                "lat": -3.4658,
                "lng": -43.905
            },
            "Montalv\u00e2nia": {
                "lat": -14.4175,
                "lng": -44.3703
            },
            "Pedro Afonso": {
                "lat": -8.9678,
                "lng": -48.175
            },
            "\u00c1guas Vermelhas": {
                "lat": -15.75,
                "lng": -41.4667
            },
            "Rio Azul": {
                "lat": -25.7328,
                "lng": -50.7958
            },
            "Itagua\u00e7u": {
                "lat": -18.8,
                "lng": -40.85
            },
            "Petrol\u00e2ndia": {
                "lat": -9.1828,
                "lng": -38.2689
            },
            "Santa Ad\u00e9lia": {
                "lat": -21.2428,
                "lng": -48.8039
            },
            "Taguatinga": {
                "lat": -12.4061,
                "lng": -46.4339
            },
            "Martinho Campos": {
                "lat": -19.3319,
                "lng": -45.2369
            },
            "Milh\u00e3": {
                "lat": -5.675,
                "lng": -39.1939
            },
            "Crist\u00f3polis": {
                "lat": -12.2339,
                "lng": -44.4208
            },
            "Coribe": {
                "lat": -13.8333,
                "lng": -44.4667
            },
            "S\u00e3o Jo\u00e3o d\u2019Alian\u00e7a": {
                "lat": -14.7114,
                "lng": -47.5161
            },
            "Felixl\u00e2ndia": {
                "lat": -18.758,
                "lng": -44.899
            },
            "Mirinzal": {
                "lat": -2.0167,
                "lng": -44.7167
            },
            "Jardim de Piranhas": {
                "lat": -6.3667,
                "lng": -37.3333
            },
            "Santa Branca": {
                "lat": -23.3967,
                "lng": -45.8839
            },
            "Goian\u00e1polis": {
                "lat": -16.505,
                "lng": -49.0214
            },
            "Pacaembu": {
                "lat": -21.5622,
                "lng": -51.2606
            },
            "Nova Ipixuna": {
                "lat": -4.9208,
                "lng": -49.0769
            },
            "Alfredo Chaves": {
                "lat": -20.635,
                "lng": -40.75
            },
            "Aracatu": {
                "lat": -14.4167,
                "lng": -41.45
            },
            "Ibicu\u00ed": {
                "lat": -14.85,
                "lng": -39.9833
            },
            "Abre Campo": {
                "lat": -20.3008,
                "lng": -42.4778
            },
            "Papagaios": {
                "lat": -19.4489,
                "lng": -44.7478
            },
            "Mansid\u00e3o": {
                "lat": -10.7167,
                "lng": -44.0333
            },
            "Alterosa": {
                "lat": -21.25,
                "lng": -46.1333
            },
            "Maraca\u00ed": {
                "lat": -22.6106,
                "lng": -50.6672
            },
            "Alto Paran\u00e1": {
                "lat": -23.0833,
                "lng": -52.3667
            },
            "Santo Augusto": {
                "lat": -27.85,
                "lng": -53.7833
            },
            "Gr\u00e3o Mogol": {
                "lat": -16.5667,
                "lng": -42.9
            },
            "Mogeiro": {
                "lat": -7.3,
                "lng": -35.4667
            },
            "Joaquim Pires": {
                "lat": -3.5167,
                "lng": -42.2
            },
            "Areado": {
                "lat": -21.3589,
                "lng": -46.1458
            },
            "Simpl\u00edcio Mendes": {
                "lat": -7.85,
                "lng": -41.9
            },
            "Tracunha\u00e9m": {
                "lat": -7.805,
                "lng": -35.24
            },
            "Porto Alegre do Norte": {
                "lat": -10.8778,
                "lng": -51.6328
            },
            "Ibipitanga": {
                "lat": -12.9,
                "lng": -42.5
            },
            "Manoel Vitorino": {
                "lat": -14.145,
                "lng": -40.2428
            },
            "Avanhandava": {
                "lat": -21.4608,
                "lng": -49.9497
            },
            "Saboeiro": {
                "lat": -6.5419,
                "lng": -39.9069
            },
            "Carm\u00f3polis": {
                "lat": -10.6478,
                "lng": -36.9889
            },
            "Carapebus": {
                "lat": -22.1869,
                "lng": -41.6608
            },
            "Itaguara": {
                "lat": -20.3919,
                "lng": -44.4878
            },
            "Ribeiro do Amparo": {
                "lat": -11.0469,
                "lng": -38.4328
            },
            "Primavera": {
                "lat": -0.9428,
                "lng": -47.1158
            },
            "Salo\u00e1": {
                "lat": -8.9667,
                "lng": -36.65
            },
            "Barra do Mendes": {
                "lat": -11.81,
                "lng": -42.0589
            },
            "Paripueira": {
                "lat": -9.465,
                "lng": -35.5519
            },
            "Pedro Velho": {
                "lat": -6.4389,
                "lng": -35.2208
            },
            "Anadia": {
                "lat": -9.6844,
                "lng": -36.3042
            },
            "Magalh\u00e3es de Almeida": {
                "lat": -3.4,
                "lng": -42.2
            },
            "Passo de Camarajibe": {
                "lat": -9.2378,
                "lng": -35.4928
            },
            "Itagi": {
                "lat": -14.1628,
                "lng": -40.0058
            },
            "Gra\u00e7a": {
                "lat": -4.0458,
                "lng": -40.7528
            },
            "Assa\u00ed": {
                "lat": -23.3728,
                "lng": -50.8408
            },
            "Vargem da Ro\u00e7a": {
                "lat": -11.6069,
                "lng": -40.1369
            },
            "Carmo de Minas": {
                "lat": -22.1219,
                "lng": -45.1289
            },
            "Iguatemi": {
                "lat": -23.68,
                "lng": -54.5608
            },
            "Acajutiba": {
                "lat": -11.6667,
                "lng": -38.0167
            },
            "Anapurus": {
                "lat": -3.6719,
                "lng": -43.1158
            },
            "Itapetim": {
                "lat": -7.3778,
                "lng": -37.19
            },
            "Ca\u00e7u": {
                "lat": -18.5631,
                "lng": -51.1247
            },
            "Guap\u00e9": {
                "lat": -20.7833,
                "lng": -45.9167
            },
            "Feliz": {
                "lat": -29.4508,
                "lng": -51.3058
            },
            "S\u00e3o Pedro do Piau\u00ed": {
                "lat": -5.9333,
                "lng": -42.7167
            },
            "Uruoca": {
                "lat": -3.3139,
                "lng": -40.5569
            },
            "Itinga": {
                "lat": -16.6094,
                "lng": -41.7719
            },
            "Flores de Goi\u00e1s": {
                "lat": -14.4606,
                "lng": -47.0317
            },
            "Urup\u00eas": {
                "lat": -21.2019,
                "lng": -49.29
            },
            "Tacaimb\u00f3": {
                "lat": -8.3158,
                "lng": -36.2928
            },
            "Atal\u00e9ia": {
                "lat": -18.0439,
                "lng": -41.11
            },
            "Guarania\u00e7u": {
                "lat": -25.1,
                "lng": -52.8667
            },
            "Angical": {
                "lat": -12.0069,
                "lng": -44.6939
            },
            "Ibateguara": {
                "lat": -8.9728,
                "lng": -35.9389
            },
            "Uruana": {
                "lat": -15.4983,
                "lng": -49.6908
            },
            "Nova Trento": {
                "lat": -27.2867,
                "lng": -48.9303
            },
            "S\u00e3o Jo\u00e3o do Triunfo": {
                "lat": -25.6667,
                "lng": -50.3333
            },
            "Nonoai": {
                "lat": -27.0958,
                "lng": -52.6178
            },
            "Nova Cana\u00e3": {
                "lat": -14.7939,
                "lng": -40.1419
            },
            "Ipecaet\u00e1": {
                "lat": -12.3,
                "lng": -39.3078
            },
            "C\u00earro Largo": {
                "lat": -28.1489,
                "lng": -54.7378
            },
            "Quat\u00e1": {
                "lat": -22.2475,
                "lng": -50.6983
            },
            "Presidente Kennedy": {
                "lat": -21.0989,
                "lng": -41.0467
            },
            "Auriflama": {
                "lat": -20.6856,
                "lng": -50.5547
            },
            "Boa Nova": {
                "lat": -14.3628,
                "lng": -40.2078
            },
            "Itapagipe": {
                "lat": -19.9089,
                "lng": -49.3808
            },
            "Uarini": {
                "lat": -2.99,
                "lng": -65.0911
            },
            "Quatis": {
                "lat": -22.4069,
                "lng": -44.2578
            },
            "Maribondo": {
                "lat": -9.5769,
                "lng": -36.305
            },
            "Normandia": {
                "lat": 3.8808,
                "lng": -59.6228
            },
            "Mucambo": {
                "lat": -3.9089,
                "lng": -40.7469
            },
            "Pot\u00e9": {
                "lat": -17.8075,
                "lng": -41.7858
            },
            "Nova Veneza": {
                "lat": -16.2731,
                "lng": -49.3239
            },
            "Pi\u00ean": {
                "lat": -26.1,
                "lng": -49.4333
            },
            "Mirabela": {
                "lat": -16.2628,
                "lng": -44.1639
            },
            "Retirol\u00e2ndia": {
                "lat": -11.5667,
                "lng": -39.3167
            },
            "Jurema": {
                "lat": -8.7178,
                "lng": -36.1358
            },
            "Curi\u00fava": {
                "lat": -24.0333,
                "lng": -50.45
            },
            "Umburanas": {
                "lat": -10.7328,
                "lng": -41.3258
            },
            "Alexandria": {
                "lat": -6.4128,
                "lng": -38.0158
            },
            "Pedreiras": {
                "lat": -4.5696,
                "lng": -44.67
            },
            "Frei Miguelinho": {
                "lat": -7.95,
                "lng": -35.95
            },
            "Jiquiri\u00e7\u00e1": {
                "lat": -13.2569,
                "lng": -39.5719
            },
            "Boninal": {
                "lat": -12.7,
                "lng": -41.8333
            },
            "Engenheiro Caldas": {
                "lat": -19.2189,
                "lng": -42.0458
            },
            "Santa Luzia do Itanhy": {
                "lat": -11.3508,
                "lng": -37.4478
            },
            "Serra Branca": {
                "lat": -7.4828,
                "lng": -36.665
            },
            "Baian\u00f3polis": {
                "lat": -12.3058,
                "lng": -44.535
            },
            "Primeira Cruz": {
                "lat": -2.5,
                "lng": -43.4333
            },
            "Oroc\u00f3": {
                "lat": -8.62,
                "lng": -39.6019
            },
            "Brejo do Cruz": {
                "lat": -6.3489,
                "lng": -37.4978
            },
            "S\u00e3o F\u00e9lix do Araguaia": {
                "lat": -11.6169,
                "lng": -50.6689
            },
            "Manten\u00f3polis": {
                "lat": -18.8628,
                "lng": -41.1228
            },
            "Elesb\u00e3o Veloso": {
                "lat": -6.2167,
                "lng": -42.1333
            },
            "Camapu\u00e3": {
                "lat": -19.5308,
                "lng": -54.0439
            },
            "Piquete": {
                "lat": -22.6139,
                "lng": -45.1758
            },
            "Upanema": {
                "lat": -5.6419,
                "lng": -37.2578
            },
            "Presidente Bernardes": {
                "lat": -22.0061,
                "lng": -51.5531
            },
            "Mascote": {
                "lat": -15.55,
                "lng": -39.2833
            },
            "S\u00e3o Ludgero": {
                "lat": -28.3258,
                "lng": -49.1767
            },
            "Caapiranga": {
                "lat": -3.3275,
                "lng": -61.2089
            },
            "Itaet\u00e9": {
                "lat": -12.9833,
                "lng": -40.9667
            },
            "S\u00e3o Jo\u00e3o do Araguaia": {
                "lat": -5.3833,
                "lng": -48.7667
            },
            "Barro Alto": {
                "lat": -14.9683,
                "lng": -48.92
            },
            "Mambor\u00ea": {
                "lat": -24.3,
                "lng": -52.5333
            },
            "S\u00e3o Pedro da \u00c1gua Branca": {
                "lat": -5.085,
                "lng": -48.4289
            },
            "Uiba\u00ed": {
                "lat": -11.3369,
                "lng": -42.1328
            },
            "Mallet": {
                "lat": -25.8778,
                "lng": -50.8208
            },
            "S\u00edtio do Mato": {
                "lat": -13.085,
                "lng": -43.465
            },
            "Balsa Nova": {
                "lat": -25.6,
                "lng": -49.6167
            },
            "Lu\u00eds Ant\u00f4nio": {
                "lat": -21.555,
                "lng": -47.7044
            },
            "V\u00e1rzea Nova": {
                "lat": -11.2589,
                "lng": -40.9419
            },
            "Peabiru": {
                "lat": -23.9,
                "lng": -52.3333
            },
            "Serrol\u00e2ndia": {
                "lat": -11.4333,
                "lng": -40.3
            },
            "Itapu\u00ed": {
                "lat": -22.2333,
                "lng": -48.7192
            },
            "Bocai\u00fava do Sul": {
                "lat": -25.1833,
                "lng": -49.1333
            },
            "Joaquim Nabuco": {
                "lat": -8.6239,
                "lng": -35.5328
            },
            "Ar\u00eas": {
                "lat": -6.1833,
                "lng": -35.15
            },
            "Para\u00edso do Norte": {
                "lat": -23.2167,
                "lng": -52.6333
            },
            "P\u00e9 de Serra": {
                "lat": -11.8339,
                "lng": -39.6128
            },
            "Iva\u00ed": {
                "lat": -25.0108,
                "lng": -50.8589
            },
            "Nioaque": {
                "lat": -21.135,
                "lng": -55.83
            },
            "Rio de Contas": {
                "lat": -13.5789,
                "lng": -41.8108
            },
            "Mulungu do Morro": {
                "lat": -11.95,
                "lng": -41.6167
            },
            "Igrapi\u00fana": {
                "lat": -13.8167,
                "lng": -39.15
            },
            "Rio do Ant\u00f4nio": {
                "lat": -14.4,
                "lng": -42.0833
            },
            "Bom Princ\u00edpio": {
                "lat": -29.4833,
                "lng": -51.35
            },
            "Alvorada D\u2019Oeste": {
                "lat": -11.3414,
                "lng": -62.2864
            },
            "Iraci": {
                "lat": -8.8078,
                "lng": -35.9519
            },
            "S\u00e3o Domingos": {
                "lat": -26.5578,
                "lng": -52.5319
            },
            "Andara\u00ed": {
                "lat": -11.7125,
                "lng": -40.6258
            },
            "Caldeir\u00e3o Grande": {
                "lat": -11.02,
                "lng": -40.3028
            },
            "Cotegipe": {
                "lat": -12.0278,
                "lng": -44.2578
            },
            "Ribeir\u00e3o do Pinhal": {
                "lat": -23.4,
                "lng": -50.3
            },
            "Alto Gar\u00e7as": {
                "lat": -16.9333,
                "lng": -53.5333
            },
            "Dom Feliciano": {
                "lat": -30.7,
                "lng": -52.1167
            },
            "Monte Belo": {
                "lat": -21.3258,
                "lng": -46.3678
            },
            "Turvo": {
                "lat": -28.9258,
                "lng": -49.6789
            },
            "Miss\u00f5es": {
                "lat": -14.8819,
                "lng": -44.0819
            },
            "Ubaporanga": {
                "lat": -19.635,
                "lng": -42.1058
            },
            "Candiba": {
                "lat": -14.4,
                "lng": -42.8667
            },
            "Ch\u00e3 da Alegria": {
                "lat": -8.0008,
                "lng": -35.2133
            },
            "Ant\u00f4nio Prado": {
                "lat": -28.8578,
                "lng": -51.2828
            },
            "Wanderley": {
                "lat": -12.12,
                "lng": -43.8878
            },
            "Casinhas": {
                "lat": -7.7408,
                "lng": -35.7208
            },
            "Itatiaiu\u00e7u": {
                "lat": -20.2,
                "lng": -44.4167
            },
            "Cardoso Moreira": {
                "lat": -21.4878,
                "lng": -41.6158
            },
            "Tartarugalzinho": {
                "lat": 1.5058,
                "lng": -50.9119
            },
            "Brej\u00f5es": {
                "lat": -13.1039,
                "lng": -39.7958
            },
            "Nossa Senhora do Livramento": {
                "lat": -15.775,
                "lng": -56.3456
            },
            "Japoat\u00e3": {
                "lat": -10.3469,
                "lng": -36.8008
            },
            "Morungaba": {
                "lat": -22.88,
                "lng": -46.7917
            },
            "Nova Londrina": {
                "lat": -22.75,
                "lng": -53.0
            },
            "Paranhos": {
                "lat": -23.8928,
                "lng": -55.4308
            },
            "Bujari": {
                "lat": -9.8308,
                "lng": -67.9519
            },
            "Barra de Santa Rosa": {
                "lat": -6.7167,
                "lng": -36.0667
            },
            "Apuiar\u00e9s": {
                "lat": -3.9333,
                "lng": -39.4
            },
            "Crissiumal": {
                "lat": -27.5,
                "lng": -54.1167
            },
            "Sang\u00e3o": {
                "lat": -28.6378,
                "lng": -49.1292
            },
            "Cidel\u00e2ndia": {
                "lat": -5.1739,
                "lng": -47.7819
            },
            "Lontras": {
                "lat": -27.1658,
                "lng": -49.5419
            },
            "Colares": {
                "lat": -0.9369,
                "lng": -48.2819
            },
            "P\u00f4rto Murtinho": {
                "lat": -21.6989,
                "lng": -57.8828
            },
            "Palmares do Sul": {
                "lat": -30.2667,
                "lng": -50.5167
            },
            "Canelinha": {
                "lat": -27.2833,
                "lng": -48.7833
            },
            "Nova Crix\u00e1s": {
                "lat": -14.0981,
                "lng": -50.3239
            },
            "Buenos Aires": {
                "lat": -7.7258,
                "lng": -35.3269
            },
            "Nova Timboteua": {
                "lat": -1.2,
                "lng": -47.4
            },
            "Rio Casca": {
                "lat": -20.2258,
                "lng": -42.6508
            },
            "Santa Maria do Sua\u00e7u\u00ed": {
                "lat": -18.2,
                "lng": -42.4167
            },
            "Lagoa Dourada": {
                "lat": -20.9167,
                "lng": -44.0833
            },
            "Novo Lino": {
                "lat": -8.915,
                "lng": -35.6467
            },
            "Rodeio": {
                "lat": -26.9228,
                "lng": -49.3664
            },
            "Serra Azul": {
                "lat": -21.3108,
                "lng": -47.5656
            },
            "Ilic\u00ednia": {
                "lat": -20.9333,
                "lng": -45.8333
            },
            "Sete Barras": {
                "lat": -24.3878,
                "lng": -47.9256
            },
            "Joan\u00f3polis": {
                "lat": -22.9303,
                "lng": -46.2756
            },
            "Mar de Espanha": {
                "lat": -21.8667,
                "lng": -43.0167
            },
            "Miranorte": {
                "lat": -9.4833,
                "lng": -48.4833
            },
            "Tagua\u00ed": {
                "lat": -23.4519,
                "lng": -49.4089
            },
            "Malhador": {
                "lat": -10.6578,
                "lng": -37.305
            },
            "Bom Repouso": {
                "lat": -22.4667,
                "lng": -46.15
            },
            "Fortaleza dos Nogueiras": {
                "lat": -6.9,
                "lng": -46.15
            },
            "Rio Vermelho": {
                "lat": -18.2939,
                "lng": -43.0089
            },
            "Dores do Indai\u00e1": {
                "lat": -19.4628,
                "lng": -45.6019
            },
            "Costa Marques": {
                "lat": -12.445,
                "lng": -64.2272
            },
            "Presidente J\u00e2nio Quadros": {
                "lat": -14.6889,
                "lng": -41.6778
            },
            "Cedral": {
                "lat": -2.0,
                "lng": -44.5358
            },
            "Queluz": {
                "lat": -22.5372,
                "lng": -44.7742
            },
            "Ibira\u00e7u": {
                "lat": -19.8319,
                "lng": -40.37
            },
            "Duartina": {
                "lat": -22.4144,
                "lng": -49.4039
            },
            "Lucena": {
                "lat": -6.9,
                "lng": -34.8689
            },
            "Concei\u00e7\u00e3o do Rio Verde": {
                "lat": -21.8833,
                "lng": -45.0833
            },
            "Iconha": {
                "lat": -20.7928,
                "lng": -40.8108
            },
            "Montividiu": {
                "lat": -17.4381,
                "lng": -51.1275
            },
            "Campinorte": {
                "lat": -14.315,
                "lng": -49.15
            },
            "Xavantes": {
                "lat": -23.0389,
                "lng": -49.7094
            },
            "Alto do Rodrigues": {
                "lat": -5.2833,
                "lng": -36.7667
            },
            "Chaval": {
                "lat": -3.0333,
                "lng": -41.25
            },
            "Santana do Matos": {
                "lat": -5.95,
                "lng": -36.65
            },
            "Engenheiro Beltr\u00e3o": {
                "lat": -23.7972,
                "lng": -52.2692
            },
            "Gravatal": {
                "lat": -28.3333,
                "lng": -49.0333
            },
            "Goiatins": {
                "lat": -7.7,
                "lng": -47.1833
            },
            "Jequeri": {
                "lat": -20.45,
                "lng": -42.6667
            },
            "Po\u00e7o Branco": {
                "lat": -5.6228,
                "lng": -35.6628
            },
            "Ribeir\u00e3o Claro": {
                "lat": -23.1939,
                "lng": -49.7578
            },
            "Cambuquira": {
                "lat": -21.8519,
                "lng": -45.2958
            },
            "Heli\u00f3polis": {
                "lat": -10.6828,
                "lng": -38.2858
            },
            "Bom Retiro do Sul": {
                "lat": -29.6167,
                "lng": -51.9333
            },
            "Canan\u00e9ia": {
                "lat": -25.0147,
                "lng": -47.9267
            },
            "Lav\u00ednia": {
                "lat": -21.1683,
                "lng": -51.0397
            },
            "Pend\u00eancias": {
                "lat": -5.2667,
                "lng": -36.7167
            },
            "Jer\u00f4nimo Monteiro": {
                "lat": -20.7889,
                "lng": -41.395
            },
            "Teixeiras": {
                "lat": -20.65,
                "lng": -42.85
            },
            "Engenheiro Paulo de Frontin": {
                "lat": -22.55,
                "lng": -43.6778
            },
            "Porto Valter": {
                "lat": -8.2689,
                "lng": -72.7439
            },
            "Carnaubeira da Penha": {
                "lat": -8.3219,
                "lng": -38.7439
            },
            "Itiquira": {
                "lat": -17.2089,
                "lng": -54.15
            },
            "Barra do Ribeiro": {
                "lat": -30.3,
                "lng": -51.3
            },
            "Bom Lugar": {
                "lat": -4.22,
                "lng": -45.0389
            },
            "Arara": {
                "lat": -6.8278,
                "lng": -35.7578
            },
            "Brejinho": {
                "lat": -6.1908,
                "lng": -35.3569
            },
            "Paranatama": {
                "lat": -8.9197,
                "lng": -36.6569
            },
            "Cristais": {
                "lat": -20.8758,
                "lng": -45.5189
            },
            "Santa Leopoldina": {
                "lat": -20.1008,
                "lng": -40.53
            },
            "Mucug\u00ea": {
                "lat": -13.005,
                "lng": -41.3708
            },
            "Salgado de S\u00e3o F\u00e9lix": {
                "lat": -7.35,
                "lng": -35.4333
            },
            "Chor\u00f3": {
                "lat": -4.8433,
                "lng": -39.1411
            },
            "Santa Filomena": {
                "lat": -8.1628,
                "lng": -40.6158
            },
            "Mostardas": {
                "lat": -31.1,
                "lng": -50.95
            },
            "Ipaumirim": {
                "lat": -6.7833,
                "lng": -38.7167
            },
            "Nova Laranjeiras": {
                "lat": -25.3,
                "lng": -52.5333
            },
            "Poranga": {
                "lat": -4.745,
                "lng": -40.9258
            },
            "S\u00e3o Francisco do Maranh\u00e3o": {
                "lat": -6.25,
                "lng": -42.8667
            },
            "Arroio do Tigre": {
                "lat": -29.3328,
                "lng": -53.0928
            },
            "Nilo Pe\u00e7anha": {
                "lat": -13.5989,
                "lng": -39.1069
            },
            "Porto": {
                "lat": -3.8928,
                "lng": -42.71
            },
            "Mato Verde": {
                "lat": -15.3981,
                "lng": -42.8567
            },
            "Iapu": {
                "lat": -19.4333,
                "lng": -42.2167
            },
            "Tomar do Geru": {
                "lat": -11.3728,
                "lng": -37.8408
            },
            "Jardim Alegre": {
                "lat": -24.15,
                "lng": -51.7167
            },
            "Manuel Urbano": {
                "lat": -8.8389,
                "lng": -69.26
            },
            "Tabocas do Brejo Velho": {
                "lat": -12.7058,
                "lng": -44.0069
            },
            "Ibaretama": {
                "lat": -4.8667,
                "lng": -38.8833
            },
            "Seberi": {
                "lat": -27.4833,
                "lng": -53.4
            },
            "Joaquim T\u00e1vora": {
                "lat": -23.4989,
                "lng": -49.905
            },
            "Governador Dix-Sept Rosado": {
                "lat": -5.4667,
                "lng": -37.5167
            },
            "Lagoa do Ouro": {
                "lat": -9.1269,
                "lng": -36.4589
            },
            "Andrel\u00e2ndia": {
                "lat": -21.74,
                "lng": -44.3089
            },
            "Brejetuba": {
                "lat": -20.1458,
                "lng": -41.29
            },
            "Aragoi\u00e2nia": {
                "lat": -16.9142,
                "lng": -49.4525
            },
            "Cachoeira de Minas": {
                "lat": -22.355,
                "lng": -45.7789
            },
            "Dom Bas\u00edlio": {
                "lat": -13.7333,
                "lng": -41.7667
            },
            "Imaru\u00ed": {
                "lat": -28.35,
                "lng": -48.8167
            },
            "Iacanga": {
                "lat": -21.89,
                "lng": -49.0247
            },
            "S\u00e3o Gon\u00e7alo do Rio Abaixo": {
                "lat": -19.8258,
                "lng": -43.3619
            },
            "Imaculada": {
                "lat": -7.3667,
                "lng": -37.5
            },
            "Lic\u00ednio de Almeida": {
                "lat": -14.6833,
                "lng": -42.5
            },
            "Areal": {
                "lat": -22.2308,
                "lng": -43.1058
            },
            "Tapiratiba": {
                "lat": -21.4683,
                "lng": -46.7486
            },
            "Jata\u00eczinho": {
                "lat": -23.2539,
                "lng": -50.98
            },
            "Bocaina": {
                "lat": -22.1361,
                "lng": -48.5181
            },
            "Virgem da Lapa": {
                "lat": -16.8167,
                "lng": -42.35
            },
            "Serra do Salitre": {
                "lat": -19.1108,
                "lng": -46.69
            },
            "Axix\u00e1": {
                "lat": -2.8369,
                "lng": -44.0528
            },
            "S\u00e3o Gon\u00e7alo do Par\u00e1": {
                "lat": -19.9828,
                "lng": -44.8589
            },
            "Brotas de Maca\u00fabas": {
                "lat": -12.0,
                "lng": -42.6333
            },
            "Ed\u00e9ia": {
                "lat": -17.3375,
                "lng": -49.9286
            },
            "Irupi": {
                "lat": -20.345,
                "lng": -41.6408
            },
            "Uba\u00ed": {
                "lat": -16.2833,
                "lng": -44.7833
            },
            "Ibir\u00e1": {
                "lat": -21.0803,
                "lng": -49.2392
            },
            "S\u00e3o Bento do Sapuca\u00ed": {
                "lat": -22.6889,
                "lng": -45.7308
            },
            "Lu\u00eds Alves": {
                "lat": -26.7333,
                "lng": -48.95
            },
            "Concei\u00e7\u00e3o do Castelo": {
                "lat": -20.3678,
                "lng": -41.2439
            },
            "S\u00e3o Sebasti\u00e3o do Uatum\u00e3": {
                "lat": -2.5719,
                "lng": -57.8708
            },
            "Jardim do Serid\u00f3": {
                "lat": -6.5833,
                "lng": -36.7667
            },
            "Guai\u00e7ara": {
                "lat": -21.6219,
                "lng": -49.7986
            },
            "Angicos": {
                "lat": -5.6658,
                "lng": -36.6008
            },
            "Porecatu": {
                "lat": -22.7558,
                "lng": -51.3789
            },
            "Ielmo Marinho": {
                "lat": -5.8167,
                "lng": -35.55
            },
            "Ibicuitinga": {
                "lat": -4.9833,
                "lng": -38.65
            },
            "Xex\u00e9u": {
                "lat": -8.8019,
                "lng": -35.6269
            },
            "Pira\u00faba": {
                "lat": -21.2833,
                "lng": -43.0333
            },
            "Gararu": {
                "lat": -9.9678,
                "lng": -37.0828
            },
            "Loreto": {
                "lat": -7.0839,
                "lng": -45.1408
            },
            "Ipaporanga": {
                "lat": -4.9,
                "lng": -40.7667
            },
            "Santa Cruz da Baixa Verde": {
                "lat": -7.8167,
                "lng": -38.1667
            },
            "Silves": {
                "lat": -2.8389,
                "lng": -58.2092
            },
            "Ara\u00e7\u00e1s": {
                "lat": -12.22,
                "lng": -38.2028
            },
            "Itain\u00f3polis": {
                "lat": -7.4,
                "lng": -41.5167
            },
            "Carmo da Cachoeira": {
                "lat": -21.4608,
                "lng": -45.2239
            },
            "Quatipuru": {
                "lat": -0.8667,
                "lng": -46.9833
            },
            "Jucati": {
                "lat": -8.7,
                "lng": -36.45
            },
            "Cachoeira Alta": {
                "lat": -18.7558,
                "lng": -50.9322
            },
            "Estiva": {
                "lat": -22.4628,
                "lng": -46.0169
            },
            "Len\u00e7\u00f3is": {
                "lat": -12.5628,
                "lng": -41.39
            },
            "Nova Ubirat\u00e3": {
                "lat": -12.9908,
                "lng": -55.255
            },
            "Alto Alegre dos Parecis": {
                "lat": -12.1278,
                "lng": -61.8508
            },
            "Palestina": {
                "lat": -20.39,
                "lng": -49.4331
            },
            "Lebon R\u00e9gis": {
                "lat": -26.9333,
                "lng": -50.7
            },
            "Pirangi": {
                "lat": -21.0914,
                "lng": -48.6578
            },
            "Cidade Ga\u00facha": {
                "lat": -23.3667,
                "lng": -53.0167
            },
            "Prudente de Morais": {
                "lat": -19.4819,
                "lng": -44.155
            },
            "Florest\u00f3polis": {
                "lat": -22.8667,
                "lng": -51.4
            },
            "Montanhas": {
                "lat": -6.4833,
                "lng": -35.3
            },
            "Saubara": {
                "lat": -12.75,
                "lng": -38.75
            },
            "Caetan\u00f3polis": {
                "lat": -19.295,
                "lng": -44.4189
            },
            "Sales Oliveira": {
                "lat": -20.7719,
                "lng": -47.8381
            },
            "S\u00e0o Amaro das Brotas": {
                "lat": -10.7889,
                "lng": -37.0539
            },
            "Cabo Verde": {
                "lat": -21.4719,
                "lng": -46.3958
            },
            "Santa F\u00e9": {
                "lat": -23.0378,
                "lng": -51.805
            },
            "Campo do Meio": {
                "lat": -21.1069,
                "lng": -45.83
            },
            "Alc\u00e2ntaras": {
                "lat": -3.5889,
                "lng": -40.5458
            },
            "Bataipor\u00e3": {
                "lat": -22.295,
                "lng": -53.2708
            },
            "Cardoso": {
                "lat": -20.0819,
                "lng": -49.9139
            },
            "Pimenteiras": {
                "lat": -6.245,
                "lng": -41.4189
            },
            "Machados": {
                "lat": -7.6858,
                "lng": -35.515
            },
            "Gouv\u00eaa": {
                "lat": -18.4539,
                "lng": -43.7408
            },
            "Rio Novo do Sul": {
                "lat": -20.8628,
                "lng": -40.9358
            },
            "Tabapu\u00e3": {
                "lat": -20.9639,
                "lng": -49.0319
            },
            "Estiva Gerbi": {
                "lat": -22.2708,
                "lng": -46.945
            },
            "Getulina": {
                "lat": -21.7986,
                "lng": -49.9286
            },
            "Pedro de Toledo": {
                "lat": -24.2747,
                "lng": -47.2328
            },
            "Curimat\u00e1": {
                "lat": -10.0358,
                "lng": -44.3058
            },
            "Caetano": {
                "lat": -14.3378,
                "lng": -40.91
            },
            "Roncador": {
                "lat": -24.6028,
                "lng": -52.275
            },
            "Quebrangulo": {
                "lat": -9.319,
                "lng": -36.471
            },
            "S\u00e3o Jo\u00e3o do Manhua\u00e7u": {
                "lat": -20.3939,
                "lng": -42.1508
            },
            "Bet\u00e2nia": {
                "lat": -8.2758,
                "lng": -38.0339
            },
            "Mineiros do Tiet\u00ea": {
                "lat": -22.4094,
                "lng": -48.4506
            },
            "Resende Costa": {
                "lat": -20.9167,
                "lng": -44.2333
            },
            "Aratuba": {
                "lat": -4.4178,
                "lng": -39.045
            },
            "Ant\u00f4nio Carlos": {
                "lat": -21.3178,
                "lng": -43.7469
            },
            "Pinheiro Machado": {
                "lat": -31.5778,
                "lng": -53.3725
            },
            "Faxinal dos Guedes": {
                "lat": -26.8833,
                "lng": -52.2333
            },
            "Pacoti": {
                "lat": -4.225,
                "lng": -38.9228
            },
            "Aurelino Leal": {
                "lat": -14.3169,
                "lng": -39.3269
            },
            "Moreira Sales": {
                "lat": -24.0167,
                "lng": -53.05
            },
            "Seringueiras": {
                "lat": -11.7981,
                "lng": -63.0311
            },
            "Capela de Santana": {
                "lat": -29.7,
                "lng": -51.3333
            },
            "Arei\u00f3polis": {
                "lat": -22.6681,
                "lng": -48.665
            },
            "Cacequi": {
                "lat": -29.8833,
                "lng": -54.8167
            },
            "Bernardino de Campos": {
                "lat": -23.0131,
                "lng": -49.4742
            },
            "Ant\u00f4nio Cardoso": {
                "lat": -12.435,
                "lng": -39.12
            },
            "Planura": {
                "lat": -20.1472,
                "lng": -48.6978
            },
            "Mortugaba": {
                "lat": -14.9833,
                "lng": -42.3167
            },
            "Itanhomi": {
                "lat": -19.1667,
                "lng": -41.8667
            },
            "S\u00e3o Jos\u00e9 do Campestre": {
                "lat": -6.3158,
                "lng": -35.7139
            },
            "Iui\u00fa": {
                "lat": -14.4,
                "lng": -43.5833
            },
            "Alto Parna\u00edba": {
                "lat": -9.1108,
                "lng": -45.93
            },
            "Maril\u00e2ndia": {
                "lat": -19.4128,
                "lng": -40.5419
            },
            "Peri-Mirim": {
                "lat": -2.5778,
                "lng": -44.8539
            },
            "Capit\u00e3o de Campos": {
                "lat": -4.4569,
                "lng": -41.9439
            },
            "Parapu\u00e3": {
                "lat": -21.7681,
                "lng": -50.7717
            },
            "Ararend\u00e1": {
                "lat": -4.75,
                "lng": -40.8333
            },
            "Cal\u00e7ado": {
                "lat": -8.7419,
                "lng": -36.3339
            },
            "C\u00e9u Azul": {
                "lat": -25.1469,
                "lng": -53.8489
            },
            "Divinol\u00e2ndia": {
                "lat": -21.6614,
                "lng": -46.7392
            },
            "Juruaia": {
                "lat": -21.25,
                "lng": -46.5833
            },
            "Congonhal": {
                "lat": -22.1528,
                "lng": -46.0389
            },
            "Iguaraci": {
                "lat": -7.8333,
                "lng": -37.5333
            },
            "Pirapetinga": {
                "lat": -21.6558,
                "lng": -42.3458
            },
            "Nossa Senhora dos Milagres": {
                "lat": -12.87,
                "lng": -39.8589
            },
            "Dois Riachos": {
                "lat": -9.3928,
                "lng": -37.1008
            },
            "Santa Mariana": {
                "lat": -23.15,
                "lng": -50.55
            },
            "General Carneiro": {
                "lat": -26.4278,
                "lng": -51.3158
            },
            "Floresta Azul": {
                "lat": -14.85,
                "lng": -39.6833
            },
            "Moita Bonita": {
                "lat": -10.5778,
                "lng": -37.3428
            },
            "Fagundes": {
                "lat": -7.355,
                "lng": -35.775
            },
            "Jacinto": {
                "lat": -16.1439,
                "lng": -40.2928
            },
            "S\u00e3o Sebasti\u00e3o de Lagoa de Ro\u00e7a": {
                "lat": -7.0828,
                "lng": -35.835
            },
            "Aperib\u00e9": {
                "lat": -21.6208,
                "lng": -42.1028
            },
            "Anguera": {
                "lat": -12.1496,
                "lng": -39.2468
            },
            "S\u00e3o F\u00e9lix": {
                "lat": -12.6069,
                "lng": -38.9694
            },
            "Botupor\u00e3": {
                "lat": -13.3819,
                "lng": -42.5228
            },
            "Carmo da Mata": {
                "lat": -20.55,
                "lng": -44.8833
            },
            "Itatuba": {
                "lat": -7.375,
                "lng": -35.6278
            },
            "Patu": {
                "lat": -6.11,
                "lng": -37.6369
            },
            "Cerro Cor\u00e1": {
                "lat": -6.0458,
                "lng": -36.3458
            },
            "Itiru\u00e7u": {
                "lat": -13.5167,
                "lng": -40.15
            },
            "Ribeir\u00e3o Bonito": {
                "lat": -22.0667,
                "lng": -48.1761
            },
            "Para\u00fana": {
                "lat": -16.9511,
                "lng": -50.4103
            },
            "Duas Barras": {
                "lat": -22.0508,
                "lng": -42.5219
            },
            "Pira\u00ed do Norte": {
                "lat": -13.75,
                "lng": -39.3667
            },
            "Mana\u00edra": {
                "lat": -7.7,
                "lng": -38.1667
            },
            "Guiratinga": {
                "lat": -16.3489,
                "lng": -53.7578
            },
            "Tr\u00eas Cachoeiras": {
                "lat": -29.45,
                "lng": -49.9333
            },
            "Matias Ol\u00edmpio": {
                "lat": -3.7167,
                "lng": -42.55
            },
            "Cunha Por\u00e3": {
                "lat": -26.9,
                "lng": -53.15
            },
            "Ibiraci": {
                "lat": -20.4619,
                "lng": -47.1219
            },
            "Rialma": {
                "lat": -16.0083,
                "lng": -49.7961
            },
            "Quilombo": {
                "lat": -26.7258,
                "lng": -52.7208
            },
            "Itamarati": {
                "lat": -6.425,
                "lng": -68.2528
            },
            "Bueno Brand\u00e3o": {
                "lat": -22.45,
                "lng": -46.35
            },
            "Groa\u00edras": {
                "lat": -3.8833,
                "lng": -40.3833
            },
            "Passa e Fica": {
                "lat": -6.4358,
                "lng": -35.6428
            },
            "Gilbu\u00e9s": {
                "lat": -9.8333,
                "lng": -45.35
            },
            "Alto Rio Doce": {
                "lat": -21.0333,
                "lng": -43.4167
            },
            "Sair\u00e9": {
                "lat": -8.3333,
                "lng": -35.65
            },
            "Gentio do Ouro": {
                "lat": -11.4167,
                "lng": -42.5
            },
            "Coroaci": {
                "lat": -18.6219,
                "lng": -42.2858
            },
            "Itarana": {
                "lat": -19.8739,
                "lng": -40.875
            },
            "Concei\u00e7\u00e3o dos Ouros": {
                "lat": -22.4167,
                "lng": -45.7833
            },
            "Bofete": {
                "lat": -23.1022,
                "lng": -48.2578
            },
            "Governador Lindenberg": {
                "lat": -19.2519,
                "lng": -40.4608
            },
            "Avelino Lopes": {
                "lat": -10.1369,
                "lng": -43.9489
            },
            "Rio dos Cedros": {
                "lat": -26.7333,
                "lng": -49.2667
            },
            "Martin\u00f3pole": {
                "lat": -3.25,
                "lng": -40.6833
            },
            "Afonso Bezerra": {
                "lat": -5.5,
                "lng": -36.5167
            },
            "Urubici": {
                "lat": -28.015,
                "lng": -49.5919
            },
            "Roseira": {
                "lat": -22.8981,
                "lng": -45.3053
            },
            "Centen\u00e1rio do Sul": {
                "lat": -22.8,
                "lng": -51.6167
            },
            "S\u00edtio Novo de Goi\u00e1s": {
                "lat": -5.5167,
                "lng": -47.4667
            },
            "S\u00e3o Jer\u00f4nimo da Serra": {
                "lat": -23.7167,
                "lng": -50.7333
            },
            "Igaratinga": {
                "lat": -19.9333,
                "lng": -44.7
            },
            "Laranja da Terra": {
                "lat": -19.8989,
                "lng": -41.0569
            },
            "S\u00e3o Lu\u00eds do Curu": {
                "lat": -3.6667,
                "lng": -39.2333
            },
            "Amatur\u00e1": {
                "lat": -3.3639,
                "lng": -68.1978
            },
            "D\u00e1rio Meira": {
                "lat": -14.4358,
                "lng": -39.9078
            },
            "Oliven\u00e7a": {
                "lat": -9.5186,
                "lng": -37.1906
            },
            "Eugen\u00f3polis": {
                "lat": -21.0989,
                "lng": -42.1869
            },
            "Terra Boa": {
                "lat": -12.3919,
                "lng": -38.625
            },
            "Guaraciaba": {
                "lat": -26.5989,
                "lng": -53.5178
            },
            "Barbosa Ferraz": {
                "lat": -24.0667,
                "lng": -52.05
            },
            "Guaraci": {
                "lat": -20.4986,
                "lng": -48.9447
            },
            "Itamogi": {
                "lat": -21.0833,
                "lng": -47.05
            },
            "Iramaia": {
                "lat": -13.2858,
                "lng": -40.9508
            },
            "Capela do Alto Alegre": {
                "lat": -11.6667,
                "lng": -39.85
            },
            "Juru\u00e1": {
                "lat": -3.4808,
                "lng": -66.0689
            },
            "Urop\u00e1": {
                "lat": -11.1406,
                "lng": -62.3608
            },
            "Candiota": {
                "lat": -31.6,
                "lng": -53.7333
            },
            "Tamarana": {
                "lat": -23.7228,
                "lng": -51.0969
            },
            "Mara Rosa": {
                "lat": -14.0172,
                "lng": -49.1764
            },
            "Quer\u00eancia do Norte": {
                "lat": -23.0,
                "lng": -53.4667
            },
            "Iretama": {
                "lat": -24.2833,
                "lng": -52.0333
            },
            "Capim Branco": {
                "lat": -19.5489,
                "lng": -44.1169
            },
            "Monte Alegre do Piau\u00ed": {
                "lat": -9.7333,
                "lng": -45.2833
            },
            "Ponte Serrada": {
                "lat": -26.8667,
                "lng": -51.9667
            },
            "Santa Terezinha de Goi\u00e1s": {
                "lat": -14.4333,
                "lng": -49.6956
            },
            "Coit\u00e9 do N\u00f3ia": {
                "lat": -9.6319,
                "lng": -36.5789
            },
            "Jacinto Machado": {
                "lat": -29.0,
                "lng": -49.7667
            },
            "Cal\u00e7oene": {
                "lat": 2.4978,
                "lng": -50.9489
            },
            "Can\u00e1polis": {
                "lat": -13.07,
                "lng": -44.2019
            },
            "Pires Ferreira": {
                "lat": -4.25,
                "lng": -40.6333
            },
            "Uruc\u00e2nia": {
                "lat": -20.3667,
                "lng": -42.75
            },
            "Acari": {
                "lat": -6.46,
                "lng": -36.641
            },
            "Monte Dourado": {
                "lat": -0.8642,
                "lng": -52.5404
            },
            "Ninheira": {
                "lat": -15.3056,
                "lng": -41.7233
            },
            "Iaciara": {
                "lat": -14.1106,
                "lng": -46.6267
            },
            "Chorroch\u00f3": {
                "lat": -8.9833,
                "lng": -39.1
            },
            "Jaguari": {
                "lat": -29.4969,
                "lng": -54.69
            },
            "Paric\u00f4nia": {
                "lat": -9.2539,
                "lng": -38.0058
            },
            "Lagoa do Mato": {
                "lat": -6.0469,
                "lng": -43.5258
            },
            "Mulungu": {
                "lat": -7.0239,
                "lng": -35.4619
            },
            "P\u00f4rto Firme": {
                "lat": -20.6667,
                "lng": -43.1
            },
            "Catanduvas": {
                "lat": -25.2028,
                "lng": -53.1569
            },
            "Monsenhor Gil": {
                "lat": -5.5833,
                "lng": -42.6167
            },
            "Corumb\u00e1 de Goi\u00e1s": {
                "lat": -15.9242,
                "lng": -48.8086
            },
            "S\u00e3o Tiago": {
                "lat": -20.9128,
                "lng": -44.5089
            },
            "S\u00e3o Jos\u00e9 do Cal\u00e7ado": {
                "lat": -21.025,
                "lng": -41.6539
            },
            "Paran\u00e3": {
                "lat": -12.615,
                "lng": -47.8828
            },
            "Wanderl\u00e2ndia": {
                "lat": -6.8333,
                "lng": -47.8
            },
            "Benedito Novo": {
                "lat": -26.8,
                "lng": -49.4167
            },
            "Recreio": {
                "lat": -21.525,
                "lng": -42.4689
            },
            "Xambio\u00e1": {
                "lat": -6.4108,
                "lng": -48.5358
            },
            "Vargem": {
                "lat": -22.8889,
                "lng": -46.4136
            },
            "Cristino Castro": {
                "lat": -8.8167,
                "lng": -44.2167
            },
            "Po\u00e7\u00e3o": {
                "lat": -8.1858,
                "lng": -36.705
            },
            "Presidente Vargas": {
                "lat": -3.4069,
                "lng": -44.0239
            },
            "Rio do Pires": {
                "lat": -13.1167,
                "lng": -42.3
            },
            "Buriti Alegre": {
                "lat": -18.1381,
                "lng": -49.0417
            },
            "Delta": {
                "lat": -19.9769,
                "lng": -47.7708
            },
            "Santa Maria de Itabira": {
                "lat": -19.4489,
                "lng": -43.1128
            },
            "Alfredo Wagner": {
                "lat": -27.7,
                "lng": -49.3339
            },
            "Sa\u00fade": {
                "lat": -10.9408,
                "lng": -40.4178
            },
            "Cacimbinhas": {
                "lat": -9.4,
                "lng": -36.99
            },
            "Itaip\u00e9": {
                "lat": -17.4019,
                "lng": -41.6689
            },
            "Macajuba": {
                "lat": -12.1358,
                "lng": -40.36
            },
            "Pedras de Maria da Cruz": {
                "lat": -15.6017,
                "lng": -44.3894
            },
            "Fervedouro": {
                "lat": -20.7258,
                "lng": -42.2789
            },
            "Catunda": {
                "lat": -4.5333,
                "lng": -40.2
            },
            "Muqu\u00e9m de S\u00e3o Francisco": {
                "lat": -12.065,
                "lng": -43.5489
            },
            "Santa Teresinha": {
                "lat": -12.7719,
                "lng": -39.5228
            },
            "S\u00e3o Sebasti\u00e3o da Grama": {
                "lat": -21.7106,
                "lng": -46.8208
            },
            "Nova Europa": {
                "lat": -21.7783,
                "lng": -48.5608
            },
            "Ibiassuc\u00ea": {
                "lat": -14.2833,
                "lng": -42.2833
            },
            "Firmin\u00f3polis": {
                "lat": -16.5822,
                "lng": -50.3017
            },
            "Roca Sales": {
                "lat": -29.2833,
                "lng": -51.8667
            },
            "Dona In\u00eas": {
                "lat": -6.6167,
                "lng": -35.6333
            },
            "Ura\u00ed": {
                "lat": -23.2,
                "lng": -50.8167
            },
            "Terra Alta": {
                "lat": -1.0378,
                "lng": -47.9078
            },
            "Uchoa": {
                "lat": -20.9528,
                "lng": -49.1747
            },
            "Coronel Freitas": {
                "lat": -26.9333,
                "lng": -52.7833
            },
            "Constantina": {
                "lat": -27.7333,
                "lng": -52.9833
            },
            "Senhora dos Rem\u00e9dios": {
                "lat": -21.0278,
                "lng": -43.5828
            },
            "Ca\u00e9m": {
                "lat": -11.1,
                "lng": -40.4333
            },
            "Paramoti": {
                "lat": -4.1,
                "lng": -39.25
            },
            "Capit\u00f3lio": {
                "lat": -20.615,
                "lng": -46.05
            },
            "Bel\u00e9m de Maria": {
                "lat": -8.6167,
                "lng": -35.85
            },
            "Merc\u00eas": {
                "lat": -21.1939,
                "lng": -43.3408
            },
            "Concei\u00e7\u00e3o da Aparecida": {
                "lat": -21.1,
                "lng": -46.2
            },
            "Sarapu\u00ed": {
                "lat": -23.6406,
                "lng": -47.8247
            },
            "Itabirinha de Mantena": {
                "lat": -18.5658,
                "lng": -41.2328
            },
            "Jaguaribara": {
                "lat": -5.6667,
                "lng": -38.6167
            },
            "Rio do Fogo": {
                "lat": -5.2667,
                "lng": -35.3833
            },
            "Itap\u00e9": {
                "lat": -14.8978,
                "lng": -39.4208
            },
            "S\u00e3o Lu\u00eds do Paraitinga": {
                "lat": -23.2219,
                "lng": -45.31
            },
            "S\u00e3o Miguel das Matas": {
                "lat": -13.0167,
                "lng": -39.45
            },
            "Matina": {
                "lat": -13.9,
                "lng": -42.8489
            },
            "Santo Ant\u00f4nio do Jacinto": {
                "lat": -16.5339,
                "lng": -40.1758
            },
            "Alvorada do Sul": {
                "lat": -22.7833,
                "lng": -51.1833
            },
            "Anan\u00e1s": {
                "lat": -6.3658,
                "lng": -48.0728
            },
            "Pintadas": {
                "lat": -11.8128,
                "lng": -39.9089
            },
            "Inhangapi": {
                "lat": -1.4167,
                "lng": -47.8833
            },
            "Itaueira": {
                "lat": -7.6,
                "lng": -43.0333
            },
            "\u00c0rvorezinha": {
                "lat": -28.8833,
                "lng": -52.1667
            },
            "Caracol": {
                "lat": -9.2789,
                "lng": -43.33
            },
            "S\u00e3o Rom\u00e3o": {
                "lat": -16.3689,
                "lng": -45.0722
            },
            "Virgin\u00f3polis": {
                "lat": -18.75,
                "lng": -42.75
            },
            "General Salgado": {
                "lat": -20.6478,
                "lng": -50.3608
            },
            "Riol\u00e2ndia": {
                "lat": -19.99,
                "lng": -49.6808
            },
            "Rodelas": {
                "lat": -8.8333,
                "lng": -38.7667
            },
            "Buriti do Tocantins": {
                "lat": -5.3158,
                "lng": -48.2289
            },
            "Mauril\u00e2ndia": {
                "lat": -17.9714,
                "lng": -50.3328
            },
            "Jord\u00e2nia": {
                "lat": -15.9,
                "lng": -40.1833
            },
            "Trajano de Morais": {
                "lat": -22.0633,
                "lng": -42.0664
            },
            "Alto Para\u00edso de Goi\u00e1s": {
                "lat": -14.1333,
                "lng": -47.5167
            },
            "Rubim": {
                "lat": -16.375,
                "lng": -40.5378
            },
            "Bonfin\u00f3polis": {
                "lat": -16.6172,
                "lng": -48.96
            },
            "Ouroeste": {
                "lat": -20.0008,
                "lng": -50.3719
            },
            "Serid\u00f3": {
                "lat": -6.85,
                "lng": -36.4167
            },
            "Guimar\u00e3es": {
                "lat": -2.1328,
                "lng": -44.6008
            },
            "Arraias": {
                "lat": -12.9308,
                "lng": -46.9378
            },
            "Terra de Areia": {
                "lat": -29.5833,
                "lng": -50.0667
            },
            "Irine\u00f3polis": {
                "lat": -26.2386,
                "lng": -50.7997
            },
            "S\u00e3o Geraldo": {
                "lat": -20.9228,
                "lng": -42.8339
            },
            "Itapitanga": {
                "lat": -14.4228,
                "lng": -39.565
            },
            "Potiragu\u00e1": {
                "lat": -15.6,
                "lng": -39.8833
            },
            "Guadalupe": {
                "lat": -6.7869,
                "lng": -43.5689
            },
            "Saudade": {
                "lat": -26.9242,
                "lng": -53.0031
            },
            "Tenente Ananias Gomes": {
                "lat": -6.45,
                "lng": -38.1667
            },
            "Axix\u00e1 do Tocantins": {
                "lat": -5.6169,
                "lng": -47.7689
            },
            "Naz\u00e1ria": {
                "lat": -5.3608,
                "lng": -42.8083
            },
            "Rio Acima": {
                "lat": -20.0878,
                "lng": -43.7889
            },
            "Fronteiras": {
                "lat": -7.0833,
                "lng": -40.6167
            },
            "Maxaranguape": {
                "lat": -5.5158,
                "lng": -35.2619
            },
            "Bonito de Santa F\u00e9": {
                "lat": -7.3167,
                "lng": -38.5167
            },
            "Jaqueira": {
                "lat": -8.7269,
                "lng": -35.7928
            },
            "Santa Teresinha (2)": {
                "lat": -7.3778,
                "lng": -37.48
            },
            "Catarina": {
                "lat": -6.1308,
                "lng": -39.8778
            },
            "Palm\u00e1cia": {
                "lat": -4.1333,
                "lng": -38.8333
            },
            "Angelim": {
                "lat": -8.8833,
                "lng": -36.2833
            },
            "Vila Muriqui": {
                "lat": -22.9261,
                "lng": -43.9478
            },
            "Sucupira do Norte": {
                "lat": -6.45,
                "lng": -44.2
            },
            "Agudos do Sul": {
                "lat": -25.9833,
                "lng": -49.3333
            },
            "Santa Maria Madalena": {
                "lat": -21.955,
                "lng": -42.0078
            },
            "Igarap\u00e9 Grande": {
                "lat": -4.585,
                "lng": -44.8528
            },
            "Governador Archer": {
                "lat": -4.9333,
                "lng": -44.3833
            },
            "Duque Bacelar": {
                "lat": -4.15,
                "lng": -42.95
            },
            "S\u00e3o Miguel de Touros": {
                "lat": -5.1167,
                "lng": -35.6333
            },
            "Campo Redondo": {
                "lat": -6.2428,
                "lng": -36.1828
            },
            "Divis\u00f3polis": {
                "lat": -15.7333,
                "lng": -41.0
            },
            "P\u00e2ntano Grande": {
                "lat": -30.1833,
                "lng": -52.3667
            },
            "Varre-Sai": {
                "lat": -20.9308,
                "lng": -41.8689
            },
            "Centralina": {
                "lat": -18.5839,
                "lng": -49.1989
            },
            "P\u00f4rto Esperidi\u00e3o": {
                "lat": -15.85,
                "lng": -58.4667
            },
            "Cort\u00eas": {
                "lat": -8.4667,
                "lng": -35.55
            },
            "Flor\u00e2nia": {
                "lat": -6.1333,
                "lng": -36.8167
            },
            "Arataca": {
                "lat": -15.2628,
                "lng": -39.4139
            },
            "Pescaria Brava": {
                "lat": -28.3833,
                "lng": -48.8833
            },
            "S\u00e3o Jos\u00e9 do Jacu\u00edpe": {
                "lat": -11.5,
                "lng": -40.0333
            },
            "Mirante": {
                "lat": -14.2419,
                "lng": -40.7728
            },
            "Gudofredo Viana": {
                "lat": -1.4028,
                "lng": -45.78
            },
            "Carmo do Rio Verde": {
                "lat": -15.3569,
                "lng": -49.7058
            },
            "Bela Vista de Minas": {
                "lat": -19.83,
                "lng": -43.0908
            },
            "Serra Caiada": {
                "lat": -6.1058,
                "lng": -35.7128
            },
            "Cajapi\u00f3": {
                "lat": -2.9667,
                "lng": -44.8
            },
            "Parnagu\u00e1": {
                "lat": -10.2167,
                "lng": -44.6333
            },
            "Primeiro de Maio": {
                "lat": -22.8,
                "lng": -51.0167
            },
            "S\u00e3o Sebasti\u00e3o do Maranh\u00e3o": {
                "lat": -18.0839,
                "lng": -42.5708
            },
            "Umarizal": {
                "lat": -5.9833,
                "lng": -37.8167
            },
            "Porangaba": {
                "lat": -23.1758,
                "lng": -48.125
            },
            "Monda\u00ed": {
                "lat": -27.0833,
                "lng": -53.4167
            },
            "Abaiara": {
                "lat": -7.35,
                "lng": -39.05
            },
            "Dumont": {
                "lat": -21.2364,
                "lng": -47.9733
            },
            "Juripiranga": {
                "lat": -7.3667,
                "lng": -35.2333
            },
            "Dores de Campos": {
                "lat": -21.1,
                "lng": -44.0333
            },
            "S\u00e3o Tom\u00e9": {
                "lat": -5.9728,
                "lng": -36.075
            },
            "Bananal": {
                "lat": -22.6839,
                "lng": -44.3236
            },
            "Anam\u00e3": {
                "lat": -3.58,
                "lng": -61.4039
            },
            "Arau\u00e1": {
                "lat": -11.2619,
                "lng": -37.62
            },
            "Cara\u00edbas": {
                "lat": -14.6,
                "lng": -41.335
            },
            "P\u00f4rto Xavier": {
                "lat": -27.9,
                "lng": -55.1333
            },
            "Ipupiara": {
                "lat": -11.8167,
                "lng": -42.6167
            },
            "Grossos": {
                "lat": -4.98,
                "lng": -37.155
            },
            "Setubinha": {
                "lat": -17.6,
                "lng": -42.1589
            },
            "Varzedo": {
                "lat": -12.9833,
                "lng": -39.4
            },
            "Manduri": {
                "lat": -23.0033,
                "lng": -49.3219
            },
            "Beneditinos": {
                "lat": -5.45,
                "lng": -42.3667
            },
            "Nhandeara": {
                "lat": -20.6944,
                "lng": -50.0386
            },
            "Juru": {
                "lat": -7.5369,
                "lng": -37.8189
            },
            "Mariluz": {
                "lat": -24.0333,
                "lng": -53.2167
            },
            "Aramari": {
                "lat": -12.0667,
                "lng": -38.5
            },
            "Berilo": {
                "lat": -16.9519,
                "lng": -42.4658
            },
            "Guia Lopes da Laguna": {
                "lat": -21.4578,
                "lng": -56.1139
            },
            "Api\u00fana": {
                "lat": -27.0333,
                "lng": -49.4167
            },
            "Ronda Alta": {
                "lat": -27.7833,
                "lng": -52.7833
            },
            "Barrac\u00e3o": {
                "lat": -26.2539,
                "lng": -53.6328
            },
            "Ribeir\u00e3o do Largo": {
                "lat": -15.4589,
                "lng": -40.7389
            },
            "Redentora": {
                "lat": -27.6667,
                "lng": -53.65
            },
            "Bom Jesus da Serra": {
                "lat": -14.3833,
                "lng": -40.5167
            },
            "Alto Piquiri": {
                "lat": -24.0167,
                "lng": -53.4667
            },
            "Nova Floresta": {
                "lat": -6.455,
                "lng": -36.2028
            },
            "Carnaubais": {
                "lat": -5.35,
                "lng": -36.8333
            },
            "Vitorino": {
                "lat": -26.2769,
                "lng": -52.7839
            },
            "Neves Paulista": {
                "lat": -20.8458,
                "lng": -49.63
            },
            "Ventania": {
                "lat": -24.2458,
                "lng": -50.2428
            },
            "In\u00e1cio Martins": {
                "lat": -25.5708,
                "lng": -51.0789
            },
            "Jucuru\u00e7u": {
                "lat": -16.8667,
                "lng": -39.9167
            },
            "Palmares Paulista": {
                "lat": -21.0828,
                "lng": -48.8008
            },
            "Nova Bassano": {
                "lat": -28.7239,
                "lng": -51.705
            },
            "Campo Er\u00ea": {
                "lat": -26.3833,
                "lng": -53.05
            },
            "Flexeiras": {
                "lat": -9.2728,
                "lng": -35.715
            },
            "Branquinha": {
                "lat": -9.2458,
                "lng": -36.015
            },
            "B\u00e1lsamo": {
                "lat": -20.7339,
                "lng": -49.58
            },
            "Ferros": {
                "lat": -19.2319,
                "lng": -43.0228
            },
            "Cavalcante": {
                "lat": -13.7944,
                "lng": -47.4556
            },
            "Petrolina de Goi\u00e1s": {
                "lat": -16.0953,
                "lng": -49.3367
            },
            "Paranacity": {
                "lat": -22.9,
                "lng": -52.15
            },
            "Fontoura Xavier": {
                "lat": -28.9833,
                "lng": -52.35
            },
            "Teixeira Soares": {
                "lat": -25.3678,
                "lng": -50.4608
            },
            "\u00c1guia Branca": {
                "lat": -18.9828,
                "lng": -40.74
            },
            "Wagner": {
                "lat": -12.2869,
                "lng": -41.1678
            },
            "Aren\u00e1polis": {
                "lat": -14.4333,
                "lng": -56.8167
            },
            "Casca": {
                "lat": -28.5667,
                "lng": -51.9833
            },
            "Quixabeira": {
                "lat": -11.4119,
                "lng": -40.1278
            },
            "Cambira": {
                "lat": -23.6,
                "lng": -51.5667
            },
            "Carneirinho": {
                "lat": -19.6978,
                "lng": -50.6878
            },
            "Ipiranga do Piau\u00ed": {
                "lat": -6.8167,
                "lng": -41.75
            },
            "Estr\u00eala d\u2019Oeste": {
                "lat": -20.2878,
                "lng": -50.4008
            },
            "An\u00edsio de Abreu": {
                "lat": -9.1889,
                "lng": -43.0458
            },
            "S\u00e3o Jorge d\u2019Oeste": {
                "lat": -25.7058,
                "lng": -52.9178
            },
            "Pau Brasil": {
                "lat": -15.4639,
                "lng": -39.6508
            },
            "Sebasti\u00e3o Laranjeiras": {
                "lat": -14.5728,
                "lng": -42.94
            },
            "Maraial": {
                "lat": -8.8028,
                "lng": -35.8289
            },
            "Igarat\u00e1": {
                "lat": -23.2044,
                "lng": -46.1561
            },
            "Palhano": {
                "lat": -4.745,
                "lng": -37.9589
            },
            "Pirpirituba": {
                "lat": -6.7667,
                "lng": -35.5
            },
            "Torrinha": {
                "lat": -22.4261,
                "lng": -48.1692
            },
            "Chupinguaia": {
                "lat": -12.5522,
                "lng": -60.8997
            },
            "Peixe": {
                "lat": -12.025,
                "lng": -48.5389
            },
            "Barros Cassal": {
                "lat": -29.1,
                "lng": -52.5833
            },
            "Porto de Pedras": {
                "lat": -9.1578,
                "lng": -35.295
            },
            "Ros\u00e1rio do Catete": {
                "lat": -10.6958,
                "lng": -37.0308
            },
            "Janda\u00edra": {
                "lat": -11.5639,
                "lng": -37.7839
            },
            "Hercul\u00e2ndia": {
                "lat": -22.0036,
                "lng": -50.3853
            },
            "Rin\u00f3polis": {
                "lat": -21.7258,
                "lng": -50.7222
            },
            "Mirante da Serra": {
                "lat": -11.0297,
                "lng": -62.675
            },
            "Nossa Senhora Aparecida": {
                "lat": -10.4428,
                "lng": -37.4889
            },
            "Fazendinha": {
                "lat": -0.0441,
                "lng": -51.1171
            },
            "Ba\u00eda da Trai\u00e7\u00e3o": {
                "lat": -6.7,
                "lng": -34.9333
            },
            "Japaratinga": {
                "lat": -9.0878,
                "lng": -35.2578
            },
            "Ant\u00f4nio Dias": {
                "lat": -19.6528,
                "lng": -42.8719
            },
            "Ara\u00fajos": {
                "lat": -19.9478,
                "lng": -45.1658
            },
            "C\u00earro Grande": {
                "lat": -30.59,
                "lng": -51.7389
            },
            "Arceburgo": {
                "lat": -21.3639,
                "lng": -46.94
            },
            "Itapebi": {
                "lat": -15.9333,
                "lng": -39.5333
            },
            "Verdejante": {
                "lat": -7.9167,
                "lng": -38.9833
            },
            "Corumba\u00edba": {
                "lat": -18.1472,
                "lng": -48.5503
            },
            "Entre Iju\u00eds": {
                "lat": -28.36,
                "lng": -54.2678
            },
            "Pirip\u00e1": {
                "lat": -14.9333,
                "lng": -41.7167
            },
            "Cachoeira dos \u00cdndios": {
                "lat": -6.9269,
                "lng": -38.6739
            },
            "Buen\u00f3polis": {
                "lat": -17.8742,
                "lng": -44.1767
            },
            "Japur\u00e1": {
                "lat": -1.8261,
                "lng": -66.5989
            },
            "Ipui\u00fana": {
                "lat": -22.1,
                "lng": -46.1833
            },
            "Cajobi": {
                "lat": -20.88,
                "lng": -48.8089
            },
            "Umburetama": {
                "lat": -7.6958,
                "lng": -35.6597
            },
            "Piranguinho": {
                "lat": -22.4008,
                "lng": -45.5319
            },
            "Mata Verde": {
                "lat": -15.6858,
                "lng": -40.7408
            },
            "Montes Altos": {
                "lat": -5.8333,
                "lng": -47.0667
            },
            "Andr\u00e9 Fernandes": {
                "lat": -15.9664,
                "lng": -41.4978
            },
            "Brejol\u00e2ndia": {
                "lat": -12.4828,
                "lng": -43.9658
            },
            "Rinc\u00e3o": {
                "lat": -21.5869,
                "lng": -48.0708
            },
            "Rondon": {
                "lat": -23.4108,
                "lng": -52.7608
            },
            "Brej\u00e3o": {
                "lat": -9.03,
                "lng": -36.5689
            },
            "Lu\u00eds Gomes": {
                "lat": -6.4167,
                "lng": -38.3833
            },
            "Paulo Lopes": {
                "lat": -27.9619,
                "lng": -48.6839
            },
            "Morada Nova de Minas": {
                "lat": -18.6047,
                "lng": -45.3572
            },
            "Janu\u00e1rio Cicco": {
                "lat": -6.15,
                "lng": -35.5833
            },
            "Salto Grande": {
                "lat": -22.8928,
                "lng": -49.9856
            },
            "Coqueiral": {
                "lat": -21.1861,
                "lng": -45.4439
            },
            "Rafard": {
                "lat": -23.0117,
                "lng": -47.5269
            },
            "Carneiros": {
                "lat": -9.4828,
                "lng": -37.3769
            },
            "Marca\u00e7\u00e3o": {
                "lat": -6.77,
                "lng": -35.015
            },
            "Icara\u00edma": {
                "lat": -23.3833,
                "lng": -53.6833
            },
            "Santana do Manhua\u00e7u": {
                "lat": -20.1078,
                "lng": -41.925
            },
            "Pena Forte": {
                "lat": -7.8289,
                "lng": -39.0769
            },
            "Miradouro": {
                "lat": -20.8908,
                "lng": -42.3428
            },
            "Rio das Flores": {
                "lat": -22.1678,
                "lng": -43.5858
            },
            "Natuba": {
                "lat": -7.6408,
                "lng": -35.55
            },
            "A\u00e7ucena": {
                "lat": -19.0667,
                "lng": -42.5333
            },
            "Colm\u00e9ia": {
                "lat": -8.7289,
                "lng": -48.765
            },
            "Caputira": {
                "lat": -20.1667,
                "lng": -42.2667
            },
            "Terra Nova": {
                "lat": -8.23,
                "lng": -39.3758
            },
            "Santa Isabel do Iva\u00ed": {
                "lat": -22.9667,
                "lng": -53.2333
            },
            "Virg\u00ednia": {
                "lat": -22.3328,
                "lng": -45.0919
            },
            "Palmeira d\u2019Oeste": {
                "lat": -20.4158,
                "lng": -50.7619
            },
            "Ibirapu\u00e3": {
                "lat": -17.65,
                "lng": -40.1167
            },
            "Matias Cardoso": {
                "lat": -14.8203,
                "lng": -43.8772
            },
            "Tasso Fragoso": {
                "lat": -8.4667,
                "lng": -45.75
            },
            "Caturama": {
                "lat": -13.3167,
                "lng": -42.3
            },
            "Armaz\u00e9m": {
                "lat": -28.2619,
                "lng": -49.0178
            },
            "Potengi": {
                "lat": -7.0908,
                "lng": -40.0269
            },
            "Pedrinhas": {
                "lat": -11.1919,
                "lng": -37.6739
            },
            "Ur\u00e2nia": {
                "lat": -20.2458,
                "lng": -50.6428
            },
            "Ba\u00eda Formosa": {
                "lat": -6.3689,
                "lng": -35.0078
            },
            "Sab\u00e1udia": {
                "lat": -23.3,
                "lng": -51.55
            },
            "S\u00e3o Sebasti\u00e3o do Curral": {
                "lat": -20.2758,
                "lng": -45.005
            },
            "Lontra": {
                "lat": -15.9028,
                "lng": -44.305
            },
            "Treze T\u00edlias": {
                "lat": -27.0019,
                "lng": -51.4058
            },
            "Pereiras": {
                "lat": -23.0761,
                "lng": -47.9758
            },
            "Vicentin\u00f3polis": {
                "lat": -17.735,
                "lng": -49.8058
            },
            "Riacho dos Machados": {
                "lat": -16.0033,
                "lng": -43.0433
            },
            "Natividade": {
                "lat": -11.7097,
                "lng": -47.7233
            },
            "Lamar\u00e3o": {
                "lat": -11.7828,
                "lng": -38.9
            },
            "Leopoldo de Bulh\u00f5es": {
                "lat": -16.6247,
                "lng": -48.7439
            },
            "Comendador Levy Gasparian": {
                "lat": -22.0289,
                "lng": -43.205
            },
            "Queimada Nova": {
                "lat": -8.5789,
                "lng": -41.4189
            },
            "Maiquinique": {
                "lat": -15.6167,
                "lng": -40.25
            },
            "Faro": {
                "lat": -2.1708,
                "lng": -56.745
            },
            "Santan\u00f3polis": {
                "lat": -12.0169,
                "lng": -38.8669
            },
            "Calif\u00f3rnia": {
                "lat": -23.65,
                "lng": -51.355
            },
            "S\u00e3o Jos\u00e9 do Cerrito": {
                "lat": -27.6667,
                "lng": -50.5833
            },
            "S\u00e3o Pedro do Iva\u00ed": {
                "lat": -23.865,
                "lng": -51.8558
            },
            "Limeira d\u2019Oeste": {
                "lat": -19.5517,
                "lng": -50.5817
            },
            "Aratu\u00edpe": {
                "lat": -13.0833,
                "lng": -39.0
            },
            "Maril\u00e2ndia do Sul": {
                "lat": -23.75,
                "lng": -51.3167
            },
            "Catu\u00edpe": {
                "lat": -28.25,
                "lng": -54.0119
            },
            "Malhada de Pedras": {
                "lat": -14.3878,
                "lng": -41.8789
            },
            "Passo do Sert\u00e3o": {
                "lat": -29.2167,
                "lng": -49.8
            },
            "Rodeiro": {
                "lat": -21.2,
                "lng": -42.8667
            },
            "Paula C\u00e2ndido": {
                "lat": -20.8833,
                "lng": -42.9667
            },
            "Campos Lindos": {
                "lat": -7.9939,
                "lng": -46.8678
            },
            "Belo Vale": {
                "lat": -20.4078,
                "lng": -44.0239
            },
            "Monte Alegre do Sul": {
                "lat": -22.6819,
                "lng": -46.6808
            },
            "Ouro Verde": {
                "lat": -21.4894,
                "lng": -51.7003
            },
            "Arealva": {
                "lat": -22.0286,
                "lng": -48.9111
            },
            "Santa Cruz do Monte Castelo": {
                "lat": -22.95,
                "lng": -53.3167
            },
            "Tombos": {
                "lat": -21.1333,
                "lng": -42.3667
            },
            "Dobrada": {
                "lat": -21.5167,
                "lng": -48.3939
            },
            "Sinimbu": {
                "lat": -29.5389,
                "lng": -52.5219
            },
            "Marcol\u00e2ndia": {
                "lat": -7.4333,
                "lng": -40.65
            },
            "Ararica": {
                "lat": -29.616,
                "lng": -50.928
            },
            "Nossa Senhora dos Rem\u00e9dios": {
                "lat": -3.9833,
                "lng": -42.6667
            },
            "Carbonita": {
                "lat": -17.5269,
                "lng": -43.0158
            },
            "Amap\u00e1": {
                "lat": 2.0528,
                "lng": -50.7928
            },
            "Jacareacanga": {
                "lat": -6.2226,
                "lng": -57.7617
            },
            "Abun\u00e3": {
                "lat": -9.6954,
                "lng": -65.3597
            },
            "Pr\u00edncipe da Beira": {
                "lat": -12.4167,
                "lng": -64.4166
            },
            "Montes Claros": {
                "lat": -16.7306,
                "lng": -43.8639
            }
        }
    },
    "KR": {
        "name": "Korea, South",
        "cities": {
            "Seoul": {
                "lat": 37.56,
                "lng": 126.99
            },
            "Busan": {
                "lat": 35.18,
                "lng": 129.075
            },
            "Incheon": {
                "lat": 37.4833,
                "lng": 126.6333
            },
            "Daegu": {
                "lat": 35.8717,
                "lng": 128.6017
            },
            "Gwangju": {
                "lat": 37.3667,
                "lng": 127.2833
            },
            "Daejeon": {
                "lat": 36.35,
                "lng": 127.385
            },
            "Suwon": {
                "lat": 37.2667,
                "lng": 127.0167
            },
            "Ulsan": {
                "lat": 35.55,
                "lng": 129.3167
            },
            "Tongjin": {
                "lat": 37.2333,
                "lng": 127.2
            },
            "Goyang": {
                "lat": 37.65,
                "lng": 126.8
            },
            "Changwon": {
                "lat": 35.2708,
                "lng": 128.6631
            },
            "Hwasu-dong": {
                "lat": 37.1997,
                "lng": 126.8314
            },
            "S\u014fngnam": {
                "lat": 37.4333,
                "lng": 127.15
            },
            "Cheongju": {
                "lat": 36.6333,
                "lng": 127.4833
            },
            "Bucheon": {
                "lat": 37.5,
                "lng": 126.7833
            },
            "Yanggok": {
                "lat": 37.6333,
                "lng": 127.2167
            },
            "Ansan": {
                "lat": 37.3167,
                "lng": 126.8333
            },
            "Ch\u2019\u014fnan": {
                "lat": 36.81,
                "lng": 127.1475
            },
            "Kimhae": {
                "lat": 35.2342,
                "lng": 128.8811
            },
            "Anyang": {
                "lat": 37.3833,
                "lng": 126.9333
            },
            "Pohang": {
                "lat": 36.0322,
                "lng": 129.365
            },
            "Pyeongtaek": {
                "lat": 36.9922,
                "lng": 127.1128
            },
            "Jeju": {
                "lat": 33.513,
                "lng": 126.523
            },
            "Sih\u016dng": {
                "lat": 37.3799,
                "lng": 126.8029
            },
            "Uijeongbu": {
                "lat": 37.7486,
                "lng": 127.0389
            },
            "Paju": {
                "lat": 37.8667,
                "lng": 126.8
            },
            "Kumi": {
                "lat": 36.1195,
                "lng": 128.3444
            },
            "Gimpo": {
                "lat": 37.7,
                "lng": 126.6
            },
            "Jeonju": {
                "lat": 35.8167,
                "lng": 127.15
            },
            "Chinju": {
                "lat": 35.2,
                "lng": 128.0833
            },
            "Asan": {
                "lat": 36.35,
                "lng": 126.9167
            },
            "W\u014fnju": {
                "lat": 37.3417,
                "lng": 127.9208
            },
            "Gwangmyeongni": {
                "lat": 37.476,
                "lng": 126.866
            },
            "Yangsan": {
                "lat": 35.3333,
                "lng": 129.0333
            },
            "Chuncheon": {
                "lat": 37.8667,
                "lng": 127.7333
            },
            "Gyeongsan": {
                "lat": 35.8167,
                "lng": 128.7333
            },
            "Kunsan": {
                "lat": 35.9833,
                "lng": 126.7167
            },
            "Yeosu": {
                "lat": 34.7607,
                "lng": 127.6622
            },
            "Iksan": {
                "lat": 35.9439,
                "lng": 126.9544
            },
            "Kunp\u2019o": {
                "lat": 37.35,
                "lng": 126.95
            },
            "Suncheon": {
                "lat": 34.9506,
                "lng": 127.4875
            },
            "Ky\u014fngju": {
                "lat": 35.85,
                "lng": 129.2167
            },
            "Mokpo": {
                "lat": 34.7589,
                "lng": 126.38
            },
            "Osan": {
                "lat": 37.1498,
                "lng": 127.0771
            },
            "Gangneung": {
                "lat": 37.75,
                "lng": 128.9
            },
            "Ich\u2019\u014fn": {
                "lat": 37.2667,
                "lng": 127.4333
            },
            "Chungju": {
                "lat": 36.9667,
                "lng": 127.95
            },
            "Sejong": {
                "lat": 36.487,
                "lng": 127.2822
            },
            "Anseong": {
                "lat": 37.0078,
                "lng": 127.2797
            },
            "Guri": {
                "lat": 37.6,
                "lng": 127.15
            },
            "Masan": {
                "lat": 35.1833,
                "lng": 128.55
            },
            "S\u014fsan": {
                "lat": 36.7817,
                "lng": 126.4522
            },
            "Pocheon": {
                "lat": 37.8947,
                "lng": 127.2002
            },
            "\u016ciwang": {
                "lat": 37.3448,
                "lng": 126.9683
            },
            "Hanam": {
                "lat": 37.5167,
                "lng": 127.2
            },
            "Seogwipo": {
                "lat": 33.2497,
                "lng": 126.56
            },
            "Andong": {
                "lat": 36.5592,
                "lng": 128.7289
            },
            "Gwangyang": {
                "lat": 34.9333,
                "lng": 127.6833
            },
            "Jicheon": {
                "lat": 36.1333,
                "lng": 128.1
            },
            "Chech\u2019\u014fn": {
                "lat": 37.1333,
                "lng": 128.2167
            },
            "Noksan": {
                "lat": 36.2039,
                "lng": 127.0847
            },
            "Ch\u2019ungmu": {
                "lat": 34.8333,
                "lng": 128.4167
            },
            "Tangjin": {
                "lat": 36.8931,
                "lng": 126.6281
            },
            "Sach\u2019on": {
                "lat": 35.0,
                "lng": 128.15
            },
            "Y\u014fju": {
                "lat": 37.3,
                "lng": 127.6333
            },
            "Sa-ch\u2019on": {
                "lat": 35.0667,
                "lng": 128.0833
            },
            "Hosan": {
                "lat": 36.2,
                "lng": 127.0833
            },
            "Jeonghae": {
                "lat": 35.5667,
                "lng": 126.85
            },
            "Y\u014fngju": {
                "lat": 36.8058,
                "lng": 128.6239
            },
            "Miryang": {
                "lat": 35.5,
                "lng": 128.75
            },
            "Sangju": {
                "lat": 36.44,
                "lng": 128.17
            },
            "Boryeong": {
                "lat": 36.3333,
                "lng": 126.6167
            },
            "Dongducheon": {
                "lat": 37.9167,
                "lng": 127.0667
            },
            "Naju": {
                "lat": 35.0333,
                "lng": 126.7167
            },
            "Tonghae": {
                "lat": 37.5248,
                "lng": 129.1143
            },
            "Kimje": {
                "lat": 35.8017,
                "lng": 126.8889
            },
            "Sokcho": {
                "lat": 38.2069,
                "lng": 128.5919
            },
            "Mun\u2019gy\u014fng": {
                "lat": 36.5939,
                "lng": 128.2014
            },
            "Samch\u2019\u014fk": {
                "lat": 37.45,
                "lng": 129.165
            },
            "Pongnam": {
                "lat": 37.22,
                "lng": 126.9494
            },
            "Gwacheon": {
                "lat": 37.4333,
                "lng": 127.0
            },
            "Haeryong": {
                "lat": 34.9131,
                "lng": 127.5394
            },
            "Taebaek": {
                "lat": 37.1667,
                "lng": 128.9833
            },
            "Jeomchon": {
                "lat": 36.6028,
                "lng": 127.3042
            },
            "Yeonil": {
                "lat": 36.0,
                "lng": 129.35
            },
            "Heunghae": {
                "lat": 36.1167,
                "lng": 129.35
            },
            "An\u2019gang": {
                "lat": 35.99,
                "lng": 129.227
            },
            "Munsan": {
                "lat": 37.8529,
                "lng": 126.7848
            },
            "Eonyang": {
                "lat": 35.5667,
                "lng": 129.1167
            },
            "Hayang": {
                "lat": 35.9167,
                "lng": 128.8167
            },
            "Hallim": {
                "lat": 33.3729,
                "lng": 126.2827
            },
            "Kujwa": {
                "lat": 33.5025,
                "lng": 126.7944
            },
            "Ulchin": {
                "lat": 37.002,
                "lng": 129.4003
            },
            "Daean": {
                "lat": 35.4038,
                "lng": 129.2788
            },
            "Eumseong": {
                "lat": 36.9353,
                "lng": 127.6897
            },
            "Sapkyo": {
                "lat": 36.6864,
                "lng": 126.7367
            },
            "P\u2019y\u014fngch\u2019ang": {
                "lat": 37.3675,
                "lng": 128.3958
            },
            "Guryongpo": {
                "lat": 35.9833,
                "lng": 129.55
            },
            "Seosaeng": {
                "lat": 35.3536,
                "lng": 129.3303
            },
            "Muan": {
                "lat": 34.9897,
                "lng": 126.4714
            },
            "Hongseong": {
                "lat": 36.6009,
                "lng": 126.665
            }
        }
    },
    "MX": {
        "name": "Mexico",
        "cities": {
            "Mexico City": {
                "lat": 19.4333,
                "lng": -99.1333
            },
            "Guadalajara": {
                "lat": 20.6767,
                "lng": -103.3475
            },
            "Monterrey": {
                "lat": 25.6667,
                "lng": -100.3
            },
            "Tijuana": {
                "lat": 32.525,
                "lng": -117.0333
            },
            "Ecatepec": {
                "lat": 19.6097,
                "lng": -99.06
            },
            "Le\u00f3n de los Aldama": {
                "lat": 21.1167,
                "lng": -101.6833
            },
            "Puebla": {
                "lat": 19.0333,
                "lng": -98.1833
            },
            "Zapopan": {
                "lat": 20.7167,
                "lng": -103.4
            },
            "Ju\u00e1rez": {
                "lat": 31.745,
                "lng": -106.485
            },
            "Mexicali": {
                "lat": 32.6633,
                "lng": -115.4678
            },
            "Nezahualc\u00f3yotl": {
                "lat": 19.4081,
                "lng": -99.0186
            },
            "Ciudad Nezahualc\u00f3yotl": {
                "lat": 19.4006,
                "lng": -99.0148
            },
            "Morelia": {
                "lat": 19.7683,
                "lng": -101.1894
            },
            "Aguascalientes": {
                "lat": 21.876,
                "lng": -102.296
            },
            "Naucalpan de Ju\u00e1rez": {
                "lat": 19.4753,
                "lng": -99.2378
            },
            "M\u00e9rida": {
                "lat": 20.97,
                "lng": -89.62
            },
            "Canc\u00fan": {
                "lat": 21.1606,
                "lng": -86.8475
            },
            "Chihuahua": {
                "lat": 28.6369,
                "lng": -106.0769
            },
            "Saltillo": {
                "lat": 25.4231,
                "lng": -100.9919
            },
            "El Dorado": {
                "lat": 24.808,
                "lng": -107.397
            },
            "Hermosillo": {
                "lat": 29.0989,
                "lng": -110.9542
            },
            "Culiac\u00e1n": {
                "lat": 24.8069,
                "lng": -107.3939
            },
            "Quer\u00e9taro": {
                "lat": 20.5875,
                "lng": -100.3928
            },
            "San Luis Potos\u00ed": {
                "lat": 22.1511,
                "lng": -100.9761
            },
            "Guadalupe": {
                "lat": 22.7528,
                "lng": -102.5078
            },
            "Acapulco de Ju\u00e1rez": {
                "lat": 16.8636,
                "lng": -99.8825
            },
            "Tlaquepaque": {
                "lat": 20.6167,
                "lng": -103.3167
            },
            "Durango": {
                "lat": 24.025,
                "lng": -104.6675
            },
            "Tlalnepantla": {
                "lat": 19.5367,
                "lng": -99.1947
            },
            "Chimalhuac\u00e1n": {
                "lat": 19.4167,
                "lng": -98.9
            },
            "Irapuato": {
                "lat": 20.6667,
                "lng": -101.35
            },
            "Reynosa": {
                "lat": 26.0922,
                "lng": -98.2778
            },
            "Tuxtla": {
                "lat": 16.7528,
                "lng": -93.1167
            },
            "Tonal\u00e1": {
                "lat": 16.0914,
                "lng": -93.7518
            },
            "Cuautitl\u00e1n Izcalli": {
                "lat": 19.65,
                "lng": -99.25
            },
            "Ixtapaluca": {
                "lat": 19.3186,
                "lng": -98.8822
            },
            "Heroica Matamoros": {
                "lat": 25.8797,
                "lng": -97.5042
            },
            "Ciudad Apodaca": {
                "lat": 25.7817,
                "lng": -100.1886
            },
            "Tultitl\u00e1n de Mariano Escobedo": {
                "lat": 19.645,
                "lng": -99.1694
            },
            "Mazatl\u00e1n": {
                "lat": 23.2167,
                "lng": -106.4167
            },
            "Ciudad L\u00f3pez Mateos": {
                "lat": 19.55,
                "lng": -99.2833
            },
            "Ensenada": {
                "lat": 31.8578,
                "lng": -116.6058
            },
            "San Nicol\u00e1s de los Garza": {
                "lat": 25.75,
                "lng": -100.2833
            },
            "Veracruz": {
                "lat": 19.1903,
                "lng": -96.1533
            },
            "Xalapa": {
                "lat": 19.54,
                "lng": -96.9275
            },
            "Nuevo Laredo": {
                "lat": 27.4861,
                "lng": -99.5069
            },
            "Celaya": {
                "lat": 20.5222,
                "lng": -100.8122
            },
            "Nicol\u00e1s Romero": {
                "lat": 19.5833,
                "lng": -99.3667
            },
            "Tec\u00e1mac": {
                "lat": 19.7131,
                "lng": -98.9683
            },
            "Minatitl\u00e1n": {
                "lat": 19.3833,
                "lng": -104.05
            },
            "Villahermosa": {
                "lat": 17.9892,
                "lng": -92.9281
            },
            "G\u00f3mez Palacio": {
                "lat": 25.5611,
                "lng": -103.4983
            },
            "Cuernavaca": {
                "lat": 18.9186,
                "lng": -99.2342
            },
            "Uruapan": {
                "lat": 19.4208,
                "lng": -102.0628
            },
            "Tepic": {
                "lat": 21.5083,
                "lng": -104.8931
            },
            "Soledad de Graciano S\u00e1nchez": {
                "lat": 22.1833,
                "lng": -100.9333
            },
            "Ciudad Obreg\u00f3n": {
                "lat": 27.4939,
                "lng": -109.9389
            },
            "Salamanca": {
                "lat": 20.5703,
                "lng": -101.1972
            },
            "Guasavito": {
                "lat": 25.5744,
                "lng": -108.4706
            },
            "Tehuac\u00e1n": {
                "lat": 18.4617,
                "lng": -97.3928
            },
            "Coatzacoalcos": {
                "lat": 18.15,
                "lng": -94.4333
            },
            "Chalco": {
                "lat": 19.2647,
                "lng": -98.8975
            },
            "Ciudad Benito Ju\u00e1rez": {
                "lat": 25.65,
                "lng": -100.0833
            },
            "Ciudad Santa Catarina": {
                "lat": 25.6833,
                "lng": -100.45
            },
            "Ciudad Victoria": {
                "lat": 23.7389,
                "lng": -99.1431
            },
            "Playa del Carmen": {
                "lat": 20.6281,
                "lng": -87.0755
            },
            "San Juan del R\u00edo": {
                "lat": 20.3833,
                "lng": -99.9833
            },
            "Oaxaca": {
                "lat": 17.0606,
                "lng": -96.7253
            },
            "Ciudad General Escobedo": {
                "lat": 25.7933,
                "lng": -100.1583
            },
            "Los Mochis": {
                "lat": 25.7835,
                "lng": -108.9937
            },
            "Pachuca": {
                "lat": 20.1,
                "lng": -98.75
            },
            "Tampico": {
                "lat": 22.2553,
                "lng": -97.8686
            },
            "Coacalco": {
                "lat": 19.6333,
                "lng": -99.0931
            },
            "Huixquilucan": {
                "lat": 19.3611,
                "lng": -99.3508
            },
            "Nogales": {
                "lat": 18.8167,
                "lng": -97.1667
            },
            "Heroica Nogales": {
                "lat": 31.3186,
                "lng": -110.9458
            },
            "La Paz": {
                "lat": 24.1422,
                "lng": -110.3108
            },
            "Campeche": {
                "lat": 19.85,
                "lng": -90.5306
            },
            "Carmen": {
                "lat": 18.6431,
                "lng": -91.8306
            },
            "Monclova": {
                "lat": 26.9103,
                "lng": -101.4222
            },
            "Garc\u00eda": {
                "lat": 25.8167,
                "lng": -100.5833
            },
            "Fresnillo": {
                "lat": 23.175,
                "lng": -102.8675
            },
            "Puerto Vallarta": {
                "lat": 20.6458,
                "lng": -105.2222
            },
            "Toluca": {
                "lat": 19.2925,
                "lng": -99.6569
            },
            "Tapachula": {
                "lat": 14.9,
                "lng": -92.2667
            },
            "Jiutepec": {
                "lat": 18.8833,
                "lng": -99.1667
            },
            "Comalcalco": {
                "lat": 18.2801,
                "lng": -93.2018
            },
            "Zamora": {
                "lat": 19.9833,
                "lng": -102.2833
            },
            "C\u00f3rdoba": {
                "lat": 18.8942,
                "lng": -96.9347
            },
            "Ciudad Madero": {
                "lat": 22.25,
                "lng": -97.8333
            },
            "Cuautla": {
                "lat": 18.8167,
                "lng": -98.95
            },
            "Guanajuato": {
                "lat": 21.0178,
                "lng": -101.2567
            },
            "Ciudad del Carmen": {
                "lat": 18.6333,
                "lng": -91.8333
            },
            "Manzanillo": {
                "lat": 19.0522,
                "lng": -104.3158
            },
            "Poza Rica de Hidalgo": {
                "lat": 20.5333,
                "lng": -97.45
            },
            "Chilpancingo": {
                "lat": 17.55,
                "lng": -99.5
            },
            "San Crist\u00f3bal": {
                "lat": 16.7367,
                "lng": -92.6383
            },
            "Cuauht\u00e9moc": {
                "lat": 19.3281,
                "lng": -103.6028
            },
            "Cuautitl\u00e1n": {
                "lat": 19.4522,
                "lng": -104.3583
            },
            "Ciudad Valles": {
                "lat": 21.9833,
                "lng": -99.0167
            },
            "San Luis R\u00edo Colorado": {
                "lat": 32.4767,
                "lng": -114.7625
            },
            "Santa Mar\u00eda Texmelucan": {
                "lat": 19.2833,
                "lng": -98.4333
            },
            "Chicoloapan": {
                "lat": 19.4167,
                "lng": -98.9
            },
            "Piedras Negras": {
                "lat": 28.7,
                "lng": -100.5231
            },
            "Lagos de Moreno": {
                "lat": 21.35,
                "lng": -101.9167
            },
            "Chetumal": {
                "lat": 18.5036,
                "lng": -88.3053
            },
            "San Martin Texmelucan de Labastida": {
                "lat": 19.2833,
                "lng": -98.4333
            },
            "Tuxtepec": {
                "lat": 18.1,
                "lng": -96.1167
            },
            "San Andr\u00e9s Tuxtla": {
                "lat": 18.4483,
                "lng": -95.2122
            },
            "Papantla de Olarte": {
                "lat": 20.4478,
                "lng": -97.32
            },
            "Tulancingo": {
                "lat": 20.0833,
                "lng": -98.3667
            },
            "Ciudad Acu\u00f1a": {
                "lat": 29.3242,
                "lng": -100.9317
            },
            "Macuspana": {
                "lat": 17.7667,
                "lng": -92.6
            },
            "Guaymas": {
                "lat": 27.9183,
                "lng": -110.8933
            },
            "Ixtlahuaca": {
                "lat": 19.5689,
                "lng": -99.7669
            },
            "Delicias": {
                "lat": 28.1931,
                "lng": -105.4717
            },
            "Valle de Santiago": {
                "lat": 20.3928,
                "lng": -101.1914
            },
            "P\u00e9njamo": {
                "lat": 20.4311,
                "lng": -101.7222
            },
            "Villa de \u00c1lvarez": {
                "lat": 19.25,
                "lng": -103.7333
            },
            "Pesquer\u00eda": {
                "lat": 25.785,
                "lng": -100.0511
            },
            "Colima": {
                "lat": 19.2433,
                "lng": -103.7247
            },
            "Boca del Rio": {
                "lat": 19.1056,
                "lng": -96.1078
            },
            "Tepatitl\u00e1n de Morelos": {
                "lat": 20.8139,
                "lng": -102.7639
            },
            "Las Maravillas": {
                "lat": 16.3667,
                "lng": -91.7333
            },
            "Iguala de la Independencia": {
                "lat": 18.345,
                "lng": -99.5383
            },
            "Kanas\u00edn": {
                "lat": 20.9344,
                "lng": -89.5578
            },
            "Cunduac\u00e1n": {
                "lat": 18.0667,
                "lng": -93.1667
            },
            "San Jos\u00e9 del Cabo": {
                "lat": 23.0614,
                "lng": -109.7081
            },
            "Zinacantepec": {
                "lat": 19.2833,
                "lng": -99.7333
            },
            "Huejutla de Reyes": {
                "lat": 21.1333,
                "lng": -98.4167
            },
            "Cholula de Rivadabia": {
                "lat": 19.0633,
                "lng": -98.3064
            },
            "Apatzingan de la Constitucion": {
                "lat": 19.0886,
                "lng": -102.3508
            },
            "Orizaba": {
                "lat": 18.85,
                "lng": -97.1
            },
            "Zacatecas": {
                "lat": 22.7736,
                "lng": -102.5736
            },
            "San Pedro Garza Garc\u00eda": {
                "lat": 25.6667,
                "lng": -100.3
            },
            "Ciudad Hidalgo": {
                "lat": 14.6792,
                "lng": -92.1497
            },
            "Navojoa": {
                "lat": 27.0813,
                "lng": -109.4461
            },
            "Chilapa de \u00c1lvarez": {
                "lat": 17.5944,
                "lng": -99.1778
            },
            "Tizayuca": {
                "lat": 19.8333,
                "lng": -98.9833
            },
            "Miramar": {
                "lat": 22.3375,
                "lng": -97.8694
            },
            "Matamoros": {
                "lat": 25.533,
                "lng": -103.25
            },
            "Heroica Guaymas": {
                "lat": 27.9183,
                "lng": -110.8989
            },
            "Tecom\u00e1n": {
                "lat": 18.9089,
                "lng": -103.8747
            },
            "Temixco": {
                "lat": 18.85,
                "lng": -99.2333
            },
            "El Pueblito": {
                "lat": 20.5397,
                "lng": -100.4414
            },
            "Ciudad Guzm\u00e1n": {
                "lat": 19.7,
                "lng": -103.4667
            },
            "Tlapacoyan": {
                "lat": 19.9617,
                "lng": -97.2108
            },
            "Las Delicias": {
                "lat": 28.2,
                "lng": -105.5
            },
            "Comit\u00e1n": {
                "lat": 16.25,
                "lng": -92.1333
            },
            "Chil\u00f3n": {
                "lat": 17.1055,
                "lng": -92.2715
            },
            "San Antonio Enchisi": {
                "lat": 19.7072,
                "lng": -99.7867
            },
            "Mart\u00ednez de la Torre": {
                "lat": 20.0667,
                "lng": -97.05
            },
            "Temoaya": {
                "lat": 19.4686,
                "lng": -99.5933
            },
            "Lerma": {
                "lat": 19.8,
                "lng": -90.6
            },
            "Texcoco": {
                "lat": 19.52,
                "lng": -98.88
            },
            "Parral": {
                "lat": 26.9333,
                "lng": -105.6667
            },
            "Tenancingo": {
                "lat": 18.9608,
                "lng": -99.5906
            },
            "Tula de Allende": {
                "lat": 20.05,
                "lng": -99.35
            },
            "Matehuala": {
                "lat": 23.6528,
                "lng": -100.6444
            },
            "Ciudad R\u00edo Bravo": {
                "lat": 25.9861,
                "lng": -98.0889
            },
            "San Luis de la Paz": {
                "lat": 21.2986,
                "lng": -100.5164
            },
            "Playas de Rosarito": {
                "lat": 32.3422,
                "lng": -117.0561
            },
            "San Felipe del Progreso": {
                "lat": 19.7125,
                "lng": -99.9511
            },
            "Ixmiquilpan": {
                "lat": 20.4819,
                "lng": -99.2181
            },
            "Tamazunchale": {
                "lat": 21.2667,
                "lng": -98.7833
            },
            "Para\u00edso": {
                "lat": 18.3961,
                "lng": -93.2128
            },
            "Rinc\u00f3n de Romos": {
                "lat": 22.2333,
                "lng": -102.3167
            },
            "Ocotl\u00e1n": {
                "lat": 20.3467,
                "lng": -102.7744
            },
            "San Jos\u00e9 del Rinc\u00f3n Centro": {
                "lat": 19.6606,
                "lng": -100.1525
            },
            "Santa Cruz Xoxocotl\u00e1n": {
                "lat": 17.0264,
                "lng": -96.7333
            },
            "Teziutlan": {
                "lat": 19.8178,
                "lng": -97.3667
            },
            "Coatepec": {
                "lat": 19.4522,
                "lng": -96.9614
            },
            "Tultepec": {
                "lat": 19.685,
                "lng": -99.1281
            },
            "Agua Prieta": {
                "lat": 31.3258,
                "lng": -109.5489
            },
            "Ciudad de Atlixco": {
                "lat": 18.9,
                "lng": -98.45
            },
            "San Francisco": {
                "lat": 30.7841,
                "lng": -112.518
            },
            "Jilotepec": {
                "lat": 19.6097,
                "lng": -96.9511
            },
            "Salinas Victoria": {
                "lat": 25.9667,
                "lng": -100.3
            },
            "Santa Rosa Jauregui": {
                "lat": 20.7418,
                "lng": -100.4473
            },
            "Guasave": {
                "lat": 25.5655,
                "lng": -108.4718
            },
            "Apaseo el Grande": {
                "lat": 20.5531,
                "lng": -100.6347
            },
            "Ciudad Mante": {
                "lat": 22.7333,
                "lng": -98.95
            },
            "T\u00faxpam de Rodr\u00edguez Cano": {
                "lat": 20.95,
                "lng": -97.4
            },
            "Acayucan": {
                "lat": 17.9422,
                "lng": -94.9103
            },
            "La Piedad": {
                "lat": 20.3333,
                "lng": -102.0167
            },
            "La Trinidad": {
                "lat": 15.9833,
                "lng": -91.8667
            },
            "Apizaco": {
                "lat": 19.4167,
                "lng": -98.1333
            },
            "Xicotepec de Ju\u00e1rez": {
                "lat": 20.3,
                "lng": -97.9667
            },
            "Salina Cruz": {
                "lat": 16.1833,
                "lng": -95.2
            },
            "Tecate": {
                "lat": 32.5722,
                "lng": -116.6403
            },
            "Tala": {
                "lat": 20.6667,
                "lng": -103.7
            },
            "Maravat\u00edo de Ocampo": {
                "lat": 19.8976,
                "lng": -100.45
            },
            "San Andr\u00e9s Cholula": {
                "lat": 19.05,
                "lng": -98.3
            },
            "P\u00e1tzcuaro": {
                "lat": 19.5164,
                "lng": -101.6097
            },
            "Ciudad Lerdo": {
                "lat": 25.55,
                "lng": -103.5167
            },
            "Juventino Rosas": {
                "lat": 20.65,
                "lng": -101.0
            },
            "Ciudad L\u00e1zaro C\u00e1rdenas": {
                "lat": 17.9561,
                "lng": -102.1922
            },
            "Sahuayo de Morelos": {
                "lat": 20.05,
                "lng": -102.7333
            },
            "Villa Victoria": {
                "lat": 19.4333,
                "lng": -100.0
            },
            "Huebampo": {
                "lat": 26.7228,
                "lng": -109.4856
            },
            "Cozumel": {
                "lat": 20.5104,
                "lng": -86.9493
            },
            "Tecamachalco": {
                "lat": 18.8667,
                "lng": -97.7167
            },
            "Tac\u00e1mbaro de Codallos": {
                "lat": 19.2356,
                "lng": -101.4569
            },
            "Cadereyta Jim\u00e9nez": {
                "lat": 25.6,
                "lng": -100.0
            },
            "Frontera": {
                "lat": 18.5336,
                "lng": -92.6469
            },
            "Juchit\u00e1n de Zaragoza": {
                "lat": 16.4333,
                "lng": -95.0167
            },
            "Silao": {
                "lat": 20.9478,
                "lng": -101.4281
            },
            "San Francisco del Rinc\u00f3n": {
                "lat": 21.0228,
                "lng": -101.86
            },
            "Tejupilco": {
                "lat": 18.9058,
                "lng": -100.1528
            },
            "Linares": {
                "lat": 24.8597,
                "lng": -99.5647
            },
            "San Miguel de Allende": {
                "lat": 20.9142,
                "lng": -100.7436
            },
            "San Juan de los Lagos": {
                "lat": 21.2458,
                "lng": -102.3308
            },
            "Los Reyes de Salgado": {
                "lat": 19.5833,
                "lng": -102.4667
            },
            "Rioverde": {
                "lat": 21.93,
                "lng": -99.98
            },
            "Ixtaczoquitl\u00e1n": {
                "lat": 18.85,
                "lng": -97.0667
            },
            "Pur\u00edsima de Bustos": {
                "lat": 21.0333,
                "lng": -101.8667
            },
            "Jacona de Plancarte": {
                "lat": 19.9333,
                "lng": -102.3167
            },
            "Yuriria": {
                "lat": 20.2,
                "lng": -101.15
            },
            "Mineral de la Reforma": {
                "lat": 20.15,
                "lng": -98.4333
            },
            "Valle Hermoso": {
                "lat": 25.6736,
                "lng": -97.8144
            },
            "Zapotlanejo": {
                "lat": 20.6228,
                "lng": -103.0689
            },
            "Cabo San Lucas": {
                "lat": 22.8897,
                "lng": -109.9156
            },
            "Ixtlahuac\u00e1n de los Membrillos": {
                "lat": 20.35,
                "lng": -103.1833
            },
            "La Barca": {
                "lat": 20.2833,
                "lng": -102.5667
            },
            "Tepotzotl\u00e1n": {
                "lat": 19.7161,
                "lng": -99.2236
            },
            "Santa Catarina Otzolotepec": {
                "lat": 18.5667,
                "lng": -97.25
            },
            "Heroica Caborca": {
                "lat": 30.7167,
                "lng": -112.15
            },
            "Zihuatanejo": {
                "lat": 17.6444,
                "lng": -101.5511
            },
            "Dolores Hidalgo Cuna de la Independencia Nacional": {
                "lat": 21.1516,
                "lng": -100.9369
            },
            "San Mateo Atenco": {
                "lat": 19.2667,
                "lng": -99.5333
            },
            "Ramos Arizpe": {
                "lat": 25.55,
                "lng": -100.9667
            },
            "Ocoyoacac": {
                "lat": 19.2739,
                "lng": -99.4606
            },
            "Torre\u00f3n": {
                "lat": 25.5419,
                "lng": -103.4083
            },
            "Jocotitl\u00e1n": {
                "lat": 19.7072,
                "lng": -99.7867
            },
            "Jiqu\u00edlpan de Ju\u00e1rez": {
                "lat": 19.9942,
                "lng": -102.7172
            },
            "Autl\u00e1n de Navarro": {
                "lat": 19.7667,
                "lng": -104.3667
            },
            "Coyula": {
                "lat": 20.6586,
                "lng": -103.2264
            },
            "Sabinas": {
                "lat": 27.9167,
                "lng": -101.3
            },
            "Santo Domingo Tehuantepec": {
                "lat": 16.3244,
                "lng": -95.2389
            },
            "Apaseo el Alto": {
                "lat": 20.45,
                "lng": -100.6167
            },
            "Santiago Tianguistenco": {
                "lat": 19.1797,
                "lng": -99.4706
            },
            "Misantla": {
                "lat": 19.9333,
                "lng": -96.85
            },
            "Guam\u00fachil": {
                "lat": 25.4639,
                "lng": -108.0794
            },
            "Teoloyucan": {
                "lat": 19.7442,
                "lng": -99.1811
            },
            "Puerto Pe\u00f1asco": {
                "lat": 31.3167,
                "lng": -113.5369
            },
            "Cortazar": {
                "lat": 20.4828,
                "lng": -100.9611
            },
            "Valle de Bravo": {
                "lat": 19.1925,
                "lng": -100.1306
            },
            "Huatusco": {
                "lat": 19.1489,
                "lng": -96.9661
            },
            "Atotonilco el Alto": {
                "lat": 20.5333,
                "lng": -102.5
            },
            "Huehuetoca": {
                "lat": 19.8342,
                "lng": -99.2033
            },
            "Altamira": {
                "lat": 22.3375,
                "lng": -97.8694
            },
            "Huauchinango": {
                "lat": 20.1767,
                "lng": -98.0528
            },
            "Balanc\u00e1n": {
                "lat": 17.8,
                "lng": -91.5333
            },
            "Temascalcingo": {
                "lat": 19.9147,
                "lng": -100.0036
            },
            "Zempoala": {
                "lat": 19.9153,
                "lng": -98.6689
            },
            "Jerez de Garc\u00eda Salinas": {
                "lat": 22.6481,
                "lng": -102.9903
            },
            "Santiago Tuxtla": {
                "lat": 18.4653,
                "lng": -95.3025
            },
            "Cosamaloapan": {
                "lat": 18.3667,
                "lng": -95.8
            },
            "Alvarado": {
                "lat": 18.7811,
                "lng": -95.7572
            },
            "Tezonapa": {
                "lat": 18.6,
                "lng": -96.6833
            },
            "Acambay": {
                "lat": 19.9539,
                "lng": -99.8431
            },
            "Ac\u00e1mbaro": {
                "lat": 20.0361,
                "lng": -100.7314
            },
            "Uman": {
                "lat": 20.8833,
                "lng": -89.75
            },
            "Chiapa de Corzo": {
                "lat": 16.7069,
                "lng": -93.0139
            },
            "Nuevo Casas Grandes": {
                "lat": 30.4167,
                "lng": -107.9167
            },
            "Oxchuc": {
                "lat": 16.7833,
                "lng": -92.2833
            },
            "Tequisquiapan": {
                "lat": 20.5206,
                "lng": -99.8958
            },
            "Ixhuatl\u00e1n de Madero": {
                "lat": 20.6833,
                "lng": -98.0167
            },
            "Encarnaci\u00f3n de D\u00edaz": {
                "lat": 21.5167,
                "lng": -102.2333
            },
            "Pinotepa": {
                "lat": 16.3412,
                "lng": -98.0537
            },
            "Simojovel de Allende": {
                "lat": 17.15,
                "lng": -92.7167
            },
            "San Jos\u00e9 Villa de Allende": {
                "lat": 19.3747,
                "lng": -100.1475
            },
            "Tizim\u00edn": {
                "lat": 21.1425,
                "lng": -88.1647
            },
            "Tlacotepec": {
                "lat": 18.6882,
                "lng": -97.6489
            },
            "Taxco de Alarc\u00f3n": {
                "lat": 18.5564,
                "lng": -99.605
            },
            "Uriangato": {
                "lat": 20.1333,
                "lng": -101.1667
            },
            "Acatzingo": {
                "lat": 18.9817,
                "lng": -97.7822
            },
            "Huamantla": {
                "lat": 19.3133,
                "lng": -97.9228
            },
            "Palenque": {
                "lat": 17.4333,
                "lng": -91.8
            },
            "Um\u00e1n": {
                "lat": 20.8833,
                "lng": -89.75
            },
            "Zumpango": {
                "lat": 19.7969,
                "lng": -99.0992
            },
            "Francisco I. Madero": {
                "lat": 25.7753,
                "lng": -103.2731
            },
            "Tepeapulco": {
                "lat": 19.7856,
                "lng": -98.5517
            },
            "San Pedro": {
                "lat": 25.7578,
                "lng": -102.9831
            },
            "Santa Luc\u00eda del Camino": {
                "lat": 17.0667,
                "lng": -96.7
            },
            "Catemaco": {
                "lat": 18.4167,
                "lng": -95.1167
            },
            "Cintalapa de Figueroa": {
                "lat": 16.6978,
                "lng": -93.7203
            },
            "Valladolid": {
                "lat": 20.6894,
                "lng": -88.2017
            },
            "Jim\u00e9nez": {
                "lat": 27.13,
                "lng": -104.9067
            },
            "San Pedro Mixtepec": {
                "lat": 16.0,
                "lng": -97.1169
            },
            "Colonia del Sol": {
                "lat": 22.9125,
                "lng": -109.9208
            },
            "Cuetzalan": {
                "lat": 20.0333,
                "lng": -97.5167
            },
            "Ocosingo": {
                "lat": 16.9072,
                "lng": -92.0961
            },
            "Pabell\u00f3n de Arteaga": {
                "lat": 22.15,
                "lng": -102.2667
            },
            "Buena Vista Tomatl\u00e1n": {
                "lat": 19.2102,
                "lng": -102.5869
            },
            "Chenalh\u00f3": {
                "lat": 16.9333,
                "lng": -92.5667
            },
            "Calimaya": {
                "lat": 19.1608,
                "lng": -99.6172
            },
            "Santiago": {
                "lat": 25.4333,
                "lng": -100.1333
            },
            "Teotihuacan": {
                "lat": 19.6897,
                "lng": -98.8608
            },
            "Santa Ana Chiautempan": {
                "lat": 19.3167,
                "lng": -98.1833
            },
            "San Francisco de los Romo": {
                "lat": 22.0833,
                "lng": -102.2667
            },
            "La Independencia": {
                "lat": 16.2526,
                "lng": -92.0237
            },
            "Tlalmanalco": {
                "lat": 19.2044,
                "lng": -98.8025
            },
            "Acaxochitl\u00e1n": {
                "lat": 20.1667,
                "lng": -98.2
            },
            "Santa Mar\u00eda Huatulco": {
                "lat": 15.85,
                "lng": -96.3333
            },
            "Guacharachi": {
                "lat": 27.15,
                "lng": -107.3167
            },
            "Cuautepec de Hinojosa": {
                "lat": 20.15,
                "lng": -98.4333
            },
            "Ciudad de Huajuapam de Le\u00f3n": {
                "lat": 17.8,
                "lng": -97.7667
            },
            "Montemorelos": {
                "lat": 25.1872,
                "lng": -99.8267
            },
            "Tres Valles": {
                "lat": 18.1667,
                "lng": -96.0167
            },
            "Meoqui": {
                "lat": 28.2722,
                "lng": -105.4819
            },
            "Jalacingo": {
                "lat": 19.8,
                "lng": -97.3
            },
            "Petatl\u00e1n": {
                "lat": 17.5383,
                "lng": -101.2739
            },
            "Tlajomulco de Z\u00fa\u00f1iga": {
                "lat": 20.4736,
                "lng": -103.4431
            },
            "Zongolica": {
                "lat": 18.6667,
                "lng": -97.0
            },
            "Ciudad Constituci\u00f3n": {
                "lat": 25.0322,
                "lng": -111.6703
            },
            "Pedro Celestino Negrete": {
                "lat": 24.726,
                "lng": -102.984
            },
            "Loreto": {
                "lat": 26.0128,
                "lng": -111.3433
            },
            "Actopan": {
                "lat": 20.2681,
                "lng": -98.9442
            },
            "Isla": {
                "lat": 18.0333,
                "lng": -95.5333
            },
            "Hueyapan de Ocampo": {
                "lat": 18.15,
                "lng": -95.15
            },
            "Villa de Zaachila": {
                "lat": 16.9508,
                "lng": -96.7492
            },
            "Ocozocoautla de Espinosa": {
                "lat": 16.7625,
                "lng": -93.375
            },
            "Jes\u00fas Mar\u00eda": {
                "lat": 20.6078,
                "lng": -102.2242
            },
            "Tepalcatepec": {
                "lat": 19.1833,
                "lng": -102.85
            },
            "Atenco": {
                "lat": 19.5167,
                "lng": -98.9167
            },
            "Tecoanapa": {
                "lat": 16.8,
                "lng": -99.15
            },
            "Acatl\u00e1n de P\u00e9rez Figueroa": {
                "lat": 18.5333,
                "lng": -96.6
            },
            "Jocotepec": {
                "lat": 20.2863,
                "lng": -103.4304
            },
            "Coyuca de Catal\u00e1n": {
                "lat": 18.3256,
                "lng": -100.6992
            },
            "Santa Mar\u00eda Atzompa": {
                "lat": 17.1014,
                "lng": -96.7778
            },
            "Coyotepec": {
                "lat": 19.75,
                "lng": -99.1711
            },
            "J\u00e1ltipan de Morelos": {
                "lat": 17.9703,
                "lng": -94.7144
            },
            "Tezoyuca": {
                "lat": 19.5903,
                "lng": -98.9083
            },
            "Yajal\u00f3n": {
                "lat": 17.1833,
                "lng": -92.3167
            },
            "Camargo": {
                "lat": 27.667,
                "lng": -105.167
            },
            "Cihuatl\u00e1n": {
                "lat": 19.25,
                "lng": -104.5667
            },
            "Heroica Ciudad de Tlaxiaco": {
                "lat": 17.2077,
                "lng": -97.6798
            },
            "Zimapan": {
                "lat": 20.7333,
                "lng": -99.3833
            },
            "Yecapixtla": {
                "lat": 18.8833,
                "lng": -98.865
            },
            "Mat\u00edas Romero": {
                "lat": 16.8667,
                "lng": -95.0333
            },
            "Temascal": {
                "lat": 18.2394,
                "lng": -96.4031
            },
            "Villa del Carb\u00f3n": {
                "lat": 19.7272,
                "lng": -99.5417
            },
            "Apan": {
                "lat": 19.7,
                "lng": -98.4333
            },
            "Playa Vicente": {
                "lat": 17.8333,
                "lng": -95.8167
            },
            "Axochiapan": {
                "lat": 18.5,
                "lng": -98.75
            },
            "Nueva Rosita": {
                "lat": 27.9372,
                "lng": -101.2172
            },
            "Ciudad Melchor M\u00fazquiz": {
                "lat": 27.8667,
                "lng": -101.5
            },
            "Galeana": {
                "lat": 24.8333,
                "lng": -100.0667
            },
            "Juan Rodr\u00edguez Clara": {
                "lat": 18.0,
                "lng": -95.4
            },
            "Empalme": {
                "lat": 27.9617,
                "lng": -110.8125
            },
            "Zacatelco": {
                "lat": 19.2167,
                "lng": -98.2333
            },
            "Santa Clara del Cobre": {
                "lat": 19.405,
                "lng": -101.638
            },
            "San Felipe Orizatl\u00e1n": {
                "lat": 21.1719,
                "lng": -98.6064
            },
            "Xico": {
                "lat": 19.417,
                "lng": -97.0
            },
            "Zacualtip\u00e1n": {
                "lat": 20.65,
                "lng": -98.65
            },
            "Cananea": {
                "lat": 30.9828,
                "lng": -110.3017
            },
            "Tlapa de Comonfort": {
                "lat": 17.5461,
                "lng": -98.5764
            },
            "San Juan Cancuc": {
                "lat": 16.9333,
                "lng": -92.3833
            },
            "Ameca": {
                "lat": 20.5486,
                "lng": -104.0431
            },
            "Tlalixcoyan": {
                "lat": 18.8031,
                "lng": -96.0611
            },
            "Calpulalpan": {
                "lat": 19.5869,
                "lng": -98.5683
            },
            "Jalapa": {
                "lat": 17.7,
                "lng": -92.8
            },
            "Mixquiahuala de Juarez": {
                "lat": 20.2311,
                "lng": -99.2131
            },
            "Melchor Ocampo": {
                "lat": 19.7083,
                "lng": -99.1444
            },
            "Villaflores": {
                "lat": 16.2355,
                "lng": -93.2677
            },
            "Tuxpan": {
                "lat": 21.8667,
                "lng": -105.45
            },
            "Perote": {
                "lat": 19.5619,
                "lng": -97.2419
            },
            "Paracho de Verduzco": {
                "lat": 19.65,
                "lng": -102.0667
            },
            "Progreso": {
                "lat": 32.5842,
                "lng": -115.5842
            },
            "Mariano Escobedo": {
                "lat": 18.9167,
                "lng": -97.1333
            },
            "Salvatierra": {
                "lat": 20.2156,
                "lng": -100.8961
            },
            "Ciudad Manuel Doblado": {
                "lat": 20.7303,
                "lng": -101.9533
            },
            "Sayula": {
                "lat": 19.8667,
                "lng": -103.6167
            },
            "Jaral del Progreso": {
                "lat": 20.3667,
                "lng": -101.0667
            },
            "Tomatl\u00e1n": {
                "lat": 19.9369,
                "lng": -105.25
            },
            "Santiago Juxtlahuaca": {
                "lat": 17.3333,
                "lng": -98.0167
            },
            "Coatepec Harinas": {
                "lat": 18.9,
                "lng": -99.7167
            },
            "Zacatepec": {
                "lat": 18.6833,
                "lng": -99.1833
            },
            "Berrioz\u00e1bal": {
                "lat": 16.8003,
                "lng": -93.2733
            },
            "Miahuatl\u00e1n de Porfirio D\u00edaz": {
                "lat": 16.3167,
                "lng": -96.5833
            },
            "Tamu\u00edn": {
                "lat": 22.0,
                "lng": -98.7833
            },
            "Ciudad de Allende": {
                "lat": 25.2833,
                "lng": -100.0167
            },
            "Tanganc\u00edcuaro de Arista": {
                "lat": 19.887,
                "lng": -102.208
            },
            "Soteapan": {
                "lat": 18.2333,
                "lng": -94.8667
            },
            "R\u00edo Grande": {
                "lat": 23.8225,
                "lng": -103.0361
            },
            "Tenosique": {
                "lat": 17.4756,
                "lng": -91.4225
            },
            "Ocuilan de Arteaga": {
                "lat": 19.0,
                "lng": -99.4
            },
            "Santa Ana Nextlalpan": {
                "lat": 19.7167,
                "lng": -99.0667
            },
            "La Huacana": {
                "lat": 18.9625,
                "lng": -101.8069
            },
            "Tepeji del R\u00edo de Ocampo": {
                "lat": 19.9039,
                "lng": -99.3414
            },
            "Acatl\u00e1n de Osorio": {
                "lat": 18.2086,
                "lng": -98.0575
            },
            "San Juan Evangelista": {
                "lat": 17.8833,
                "lng": -95.1333
            },
            "Parras de la Fuente": {
                "lat": 25.4403,
                "lng": -102.1792
            },
            "Zacatl\u00e1n": {
                "lat": 19.9319,
                "lng": -97.96
            },
            "Tlanchinol": {
                "lat": 19.9892,
                "lng": -98.6786
            },
            "Ixtapan de la Sal": {
                "lat": 18.8333,
                "lng": -99.6833
            },
            "Paso de Ovejas": {
                "lat": 19.285,
                "lng": -96.44
            },
            "Tulum": {
                "lat": 20.2119,
                "lng": -87.4658
            },
            "P\u00e1nuco": {
                "lat": 22.05,
                "lng": -98.1833
            },
            "Temascalapa": {
                "lat": 19.8,
                "lng": -98.9
            },
            "Villanueva": {
                "lat": 22.3536,
                "lng": -102.8831
            },
            "San Felipe": {
                "lat": 31.0275,
                "lng": -114.8353
            },
            "Ticul": {
                "lat": 20.3953,
                "lng": -89.5339
            },
            "Sayula de Alem\u00e1n": {
                "lat": 17.8833,
                "lng": -94.95
            },
            "Putla Villa de Guerrero": {
                "lat": 17.0167,
                "lng": -97.9167
            },
            "Nueva Italia de Ruiz": {
                "lat": 19.0194,
                "lng": -102.1089
            },
            "Huixtla": {
                "lat": 15.1383,
                "lng": -92.4658
            },
            "Ciudad Sabinas Hidalgo": {
                "lat": 26.5,
                "lng": -100.1833
            },
            "Paso del Macho": {
                "lat": 18.9667,
                "lng": -96.7167
            },
            "Turicato": {
                "lat": 19.05,
                "lng": -101.4167
            },
            "El Oro de Hidalgo": {
                "lat": 19.8008,
                "lng": -100.1314
            },
            "Amatenango de la Frontera": {
                "lat": 15.5333,
                "lng": -92.0833
            },
            "Amecameca de Ju\u00e1rez": {
                "lat": 19.75,
                "lng": -99.1711
            },
            "Tecozautla": {
                "lat": 20.5333,
                "lng": -99.6333
            },
            "Tequila": {
                "lat": 20.8828,
                "lng": -103.8367
            },
            "Tanc\u00edtaro": {
                "lat": 19.3378,
                "lng": -102.3625
            },
            "Rodolfo S\u00e1nchez Taboada": {
                "lat": 31.7958,
                "lng": -116.5911
            },
            "Champot\u00f3n": {
                "lat": 19.35,
                "lng": -90.7167
            },
            "Juanacatl\u00e1n": {
                "lat": 20.5,
                "lng": -103.1667
            },
            "Capulhuac": {
                "lat": 19.2,
                "lng": -99.4667
            },
            "Jonuta": {
                "lat": 18.0833,
                "lng": -92.1333
            },
            "Escuinapa": {
                "lat": 22.835,
                "lng": -105.778
            },
            "Tepetlaoxtoc": {
                "lat": 19.5731,
                "lng": -98.8203
            },
            "Magdalena de Kino": {
                "lat": 30.6167,
                "lng": -111.05
            },
            "Temascaltepec de Gonz\u00e1lez": {
                "lat": 19.0433,
                "lng": -100.0414
            },
            "Huatabampo": {
                "lat": 27.05,
                "lng": -109.4167
            },
            "San Salvador El Seco": {
                "lat": 19.1333,
                "lng": -97.65
            },
            "Ometepec": {
                "lat": 16.6833,
                "lng": -98.4167
            },
            "Nanchital de L\u00e1zaro C\u00e1rdenas del R\u00edo": {
                "lat": 18.0667,
                "lng": -94.4167
            },
            "Villa Donato Guerra": {
                "lat": 19.3083,
                "lng": -100.1422
            },
            "Xalatlaco": {
                "lat": 19.1811,
                "lng": -99.4164
            },
            "Esc\u00e1rcega": {
                "lat": 18.6067,
                "lng": -90.7344
            },
            "Juchitepec": {
                "lat": 19.0997,
                "lng": -98.8792
            },
            "San Rafael": {
                "lat": 20.1889,
                "lng": -96.8658
            },
            "Zapotiltic": {
                "lat": 19.627,
                "lng": -103.417
            },
            "San Fernando": {
                "lat": 16.8717,
                "lng": -93.2063
            },
            "Ozumba": {
                "lat": 19.0392,
                "lng": -98.7936
            },
            "Teapa": {
                "lat": 17.5483,
                "lng": -92.953
            },
            "Reforma": {
                "lat": 17.8658,
                "lng": -93.1472
            },
            "\u00darsulo Galv\u00e1n": {
                "lat": 19.4,
                "lng": -96.3
            },
            "Abasolo": {
                "lat": 24.0559,
                "lng": -98.3733
            },
            "Las Rosas": {
                "lat": 16.3667,
                "lng": -92.35
            },
            "Navolato": {
                "lat": 24.7656,
                "lng": -107.7019
            },
            "San Salvador": {
                "lat": 20.2833,
                "lng": -99.0153
            },
            "Ciudad Sahagun": {
                "lat": 19.7714,
                "lng": -98.5803
            },
            "Cuitzeo del Porvenir": {
                "lat": 19.9686,
                "lng": -101.1394
            },
            "Zacoalco de Torres": {
                "lat": 20.2333,
                "lng": -103.5833
            },
            "Metepec": {
                "lat": 19.2511,
                "lng": -99.6047
            },
            "Tetela de Ocampo": {
                "lat": 19.8167,
                "lng": -97.8
            },
            "Xochistlahuaca": {
                "lat": 16.7914,
                "lng": -98.2419
            },
            "San Felipe Jalapa de D\u00edaz": {
                "lat": 18.0667,
                "lng": -96.5333
            },
            "Nochistl\u00e1n de Mej\u00eda": {
                "lat": 21.3642,
                "lng": -102.8464
            },
            "Quiroga": {
                "lat": 19.6667,
                "lng": -101.5333
            },
            "Villa Luvianos": {
                "lat": 18.92,
                "lng": -100.2983
            },
            "Motozintla": {
                "lat": 15.3632,
                "lng": -92.2477
            },
            "Tlalpujahua de Ray\u00f3n": {
                "lat": 19.805,
                "lng": -100.1744
            },
            "Soledad de Doblado": {
                "lat": 19.05,
                "lng": -96.4167
            },
            "Atlautla": {
                "lat": 19.0,
                "lng": -98.7167
            },
            "San Juan Guichicovi": {
                "lat": 16.9667,
                "lng": -95.0833
            },
            "Contla": {
                "lat": 19.3333,
                "lng": -98.1667
            },
            "Apaxco de Ocampo": {
                "lat": 19.98,
                "lng": -99.17
            },
            "Los Reyes de Ju\u00e1rez": {
                "lat": 18.9458,
                "lng": -97.8058
            },
            "Jalpan": {
                "lat": 21.2167,
                "lng": -99.4725
            },
            "Casta\u00f1os": {
                "lat": 26.7833,
                "lng": -101.4167
            },
            "Cuer\u00e1maro": {
                "lat": 20.6258,
                "lng": -101.6739
            },
            "Tepehuac\u00e1n de Guerrero": {
                "lat": 21.0131,
                "lng": -98.8442
            },
            "San Agust\u00edn Tlaxiaca": {
                "lat": 20.1144,
                "lng": -98.8867
            },
            "Villagr\u00e1n": {
                "lat": 20.517,
                "lng": -100.983
            },
            "Amatepec": {
                "lat": 18.65,
                "lng": -100.15
            },
            "Nava": {
                "lat": 28.4214,
                "lng": -100.7675
            },
            "Atitalaquia": {
                "lat": 20.0583,
                "lng": -99.2208
            },
            "Acapetahua": {
                "lat": 15.2333,
                "lng": -92.7667
            },
            "Paracuaro": {
                "lat": 19.1467,
                "lng": -102.2194
            },
            "Tlaxcoapan": {
                "lat": 20.0953,
                "lng": -99.22
            },
            "Miacatl\u00e1n": {
                "lat": 18.7722,
                "lng": -99.3547
            },
            "Cuajinicuilapa": {
                "lat": 16.4717,
                "lng": -98.4153
            },
            "Tecolutla": {
                "lat": 20.4797,
                "lng": -97.01
            },
            "Jaltenco": {
                "lat": 19.7511,
                "lng": -99.0931
            },
            "Tehuipango": {
                "lat": 18.5167,
                "lng": -97.05
            },
            "Santiago Papasquiaro": {
                "lat": 25.0439,
                "lng": -105.4192
            },
            "Huaquechula": {
                "lat": 18.7667,
                "lng": -98.55
            },
            "Puerto Escondido": {
                "lat": 15.8619,
                "lng": -97.0672
            },
            "Ciudad Altamirano": {
                "lat": 18.3472,
                "lng": -100.6531
            },
            "Tecax": {
                "lat": 20.2019,
                "lng": -89.2881
            },
            "Felipe Carrillo Puerto": {
                "lat": 19.5786,
                "lng": -88.0453
            },
            "Huejotzingo": {
                "lat": 19.1619,
                "lng": -98.4064
            },
            "Arriaga": {
                "lat": 16.2361,
                "lng": -93.8995
            },
            "Pinal de Amoles": {
                "lat": 21.1342,
                "lng": -99.4586
            },
            "Acatl\u00e1n": {
                "lat": 20.1444,
                "lng": -98.4383
            },
            "Atlatlahucan": {
                "lat": 18.935,
                "lng": -98.9
            },
            "Miahuatl\u00e1n": {
                "lat": 18.2833,
                "lng": -97.2833
            },
            "\u00c1lamo": {
                "lat": 20.9167,
                "lng": -97.675
            },
            "Santa Mar\u00eda Tonameca": {
                "lat": 15.7458,
                "lng": -96.5472
            },
            "Sultepec": {
                "lat": 18.8667,
                "lng": -99.95
            },
            "Hunucm\u00e1": {
                "lat": 21.0153,
                "lng": -89.8744
            },
            "Ixhuatlancillo": {
                "lat": 18.9,
                "lng": -97.15
            },
            "Jamay": {
                "lat": 20.29,
                "lng": -102.71
            },
            "Zumpango del R\u00edo": {
                "lat": 17.65,
                "lng": -99.5
            },
            "Carlos A. Carrillo": {
                "lat": 18.3667,
                "lng": -95.75
            },
            "Amat\u00e1n": {
                "lat": 17.3667,
                "lng": -92.8167
            },
            "San Agust\u00edn Loxicha": {
                "lat": 16.0167,
                "lng": -96.6167
            },
            "Las Margaritas": {
                "lat": 16.3159,
                "lng": -91.9816
            },
            "El Salto": {
                "lat": 23.7823,
                "lng": -105.3585
            },
            "Santa Mar\u00eda Colotepec": {
                "lat": 15.8833,
                "lng": -96.9167
            },
            "Manlio Fabio Altamirano": {
                "lat": 19.1003,
                "lng": -96.3411
            },
            "Tantoyuca": {
                "lat": 21.35,
                "lng": -98.2333
            },
            "San Miguel Ocotenco": {
                "lat": 19.0666,
                "lng": -97.4525
            },
            "Ciudad Serd\u00e1n": {
                "lat": 18.9833,
                "lng": -97.45
            },
            "Atotonilco el Grande": {
                "lat": 20.6667,
                "lng": -98.6667
            },
            "San Juan Cotzoc\u00f3n": {
                "lat": 17.1667,
                "lng": -95.7833
            },
            "Cazones de Herrera": {
                "lat": 20.7044,
                "lng": -97.3106
            },
            "Ahualulco de Mercado": {
                "lat": 20.6167,
                "lng": -103.8667
            },
            "Tecali": {
                "lat": 18.9,
                "lng": -97.9667
            },
            "San Mart\u00edn Totol\u00e1n": {
                "lat": 20.0833,
                "lng": -102.65
            },
            "Cerro Azul": {
                "lat": 21.192,
                "lng": -97.7409
            },
            "General P\u00e1nfilo Natera": {
                "lat": 22.665,
                "lng": -102.1097
            },
            "San Buenaventura": {
                "lat": 27.0625,
                "lng": -101.5467
            },
            "Doctor Mora": {
                "lat": 21.1425,
                "lng": -100.3192
            },
            "La Higuerita": {
                "lat": 19.5,
                "lng": -104.8833
            },
            "La Huerta": {
                "lat": 19.4833,
                "lng": -104.65
            },
            "Ozuluama de Mascare\u00f1as": {
                "lat": 21.6667,
                "lng": -97.85
            },
            "Motul": {
                "lat": 21.1667,
                "lng": -89.4667
            },
            "Tototl\u00e1n": {
                "lat": 20.5333,
                "lng": -102.7833
            },
            "Soledad Atzompa": {
                "lat": 18.75,
                "lng": -97.15
            },
            "Tamiahua": {
                "lat": 21.2788,
                "lng": -97.4462
            },
            "Rafael Delgado": {
                "lat": 18.8167,
                "lng": -97.0667
            },
            "Oxkutzkab": {
                "lat": 20.3056,
                "lng": -89.4183
            },
            "Malinalco": {
                "lat": 18.9483,
                "lng": -99.4947
            },
            "Naolinco de Victoria": {
                "lat": 19.6367,
                "lng": -96.8525
            },
            "San Miguel de Papasquiaro": {
                "lat": 24.8304,
                "lng": -105.34
            },
            "Ojinaga": {
                "lat": 29.5644,
                "lng": -104.4164
            },
            "Tequixquiac": {
                "lat": 19.9097,
                "lng": -99.1417
            },
            "Yahualica de Gonz\u00e1lez Gallo": {
                "lat": 21.1781,
                "lng": -102.886
            },
            "Tlacolula de Matamoros": {
                "lat": 16.9542,
                "lng": -96.4792
            },
            "Huautla": {
                "lat": 21.0308,
                "lng": -98.285
            },
            "Emiliano Zapata": {
                "lat": 16.5392,
                "lng": -92.8967
            },
            "Puente Nacional": {
                "lat": 19.3333,
                "lng": -96.4833
            },
            "Yahualica": {
                "lat": 20.9531,
                "lng": -98.38
            },
            "Huej\u00facar": {
                "lat": 22.3591,
                "lng": -103.2108
            },
            "Ocatl\u00e1n": {
                "lat": 19.3167,
                "lng": -98.2283
            },
            "Chalchihuit\u00e1n": {
                "lat": 17.0333,
                "lng": -92.6167
            },
            "Axapusco": {
                "lat": 19.7194,
                "lng": -98.7972
            },
            "Pueblo Ju\u00e1rez": {
                "lat": 17.7,
                "lng": -93.2
            },
            "Charo": {
                "lat": 19.75,
                "lng": -101.05
            },
            "Magdalena": {
                "lat": 20.9167,
                "lng": -103.95
            },
            "Chapa de Mota": {
                "lat": 19.8144,
                "lng": -99.5269
            },
            "Comalapa": {
                "lat": 15.65,
                "lng": -92.15
            },
            "Tixtla de Guerrero": {
                "lat": 17.5667,
                "lng": -99.4
            },
            "Sombrerete": {
                "lat": 23.6333,
                "lng": -103.6397
            },
            "Ocotl\u00e1n de Morelos": {
                "lat": 16.7914,
                "lng": -96.675
            },
            "Tlaltenango de S\u00e1nchez Rom\u00e1n": {
                "lat": 21.7815,
                "lng": -103.3059
            },
            "Cuilapan de Guerrero": {
                "lat": 16.9972,
                "lng": -96.7817
            },
            "Chapala": {
                "lat": 20.2961,
                "lng": -103.1911
            },
            "Acatic": {
                "lat": 20.7803,
                "lng": -102.91
            },
            "San Mart\u00edn de las Pir\u00e1mides": {
                "lat": 19.7333,
                "lng": -98.8167
            },
            "Allende": {
                "lat": 28.3333,
                "lng": -100.8333
            },
            "Fort\u00edn de las Flores": {
                "lat": 18.9,
                "lng": -97.0
            },
            "Tapalpa": {
                "lat": 19.9445,
                "lng": -103.7585
            },
            "Degollado": {
                "lat": 20.4667,
                "lng": -102.15
            },
            "Valle Nacional": {
                "lat": 17.7667,
                "lng": -96.3
            },
            "Chocam\u00e1n": {
                "lat": 19.0167,
                "lng": -97.0333
            },
            "Altepexi": {
                "lat": 18.35,
                "lng": -97.3167
            },
            "Xoxocotla": {
                "lat": 18.685,
                "lng": -99.2439
            },
            "Texistepec": {
                "lat": 17.9,
                "lng": -94.8167
            },
            "Chachahuantla": {
                "lat": 20.2756,
                "lng": -98.1503
            },
            "Pahuatl\u00e1n de Valle": {
                "lat": 20.2756,
                "lng": -98.1503
            },
            "Tepexi de Rodr\u00edguez": {
                "lat": 18.5833,
                "lng": -97.9333
            },
            "Chapulhuac\u00e1n": {
                "lat": 21.1547,
                "lng": -98.9039
            },
            "La Resolana": {
                "lat": 19.3425,
                "lng": -104.2878
            },
            "Atoyac de \u00c1lvarez": {
                "lat": 17.2,
                "lng": -100.4333
            },
            "Colonia General Felipe \u00c1ngeles": {
                "lat": 23.9167,
                "lng": -104.6
            },
            "Santa Mar\u00eda Chilchotla": {
                "lat": 18.2333,
                "lng": -96.8167
            },
            "Las Vigas de Ram\u00edrez": {
                "lat": 19.6369,
                "lng": -97.1
            },
            "Cruz Grande": {
                "lat": 16.7333,
                "lng": -99.1333
            },
            "Huan\u00edmaro": {
                "lat": 20.3675,
                "lng": -101.4969
            },
            "Villa Hidalgo": {
                "lat": 21.6667,
                "lng": -102.6
            },
            "Naranjos": {
                "lat": 21.3509,
                "lng": -97.6866
            },
            "Teopisca": {
                "lat": 16.55,
                "lng": -92.5
            },
            "Castillo de Teayo": {
                "lat": 20.75,
                "lng": -97.6333
            },
            "Etzatl\u00e1n": {
                "lat": 20.7667,
                "lng": -104.1333
            },
            "C\u00e1rdenas": {
                "lat": 22.01,
                "lng": -99.652
            },
            "Ciudad Miguel Alem\u00e1n": {
                "lat": 26.4003,
                "lng": -99.0253
            },
            "El Arenal": {
                "lat": 20.2167,
                "lng": -98.9167
            },
            "Comapa": {
                "lat": 19.165,
                "lng": -96.8856
            },
            "Zimatl\u00e1n de \u00c1lvarez": {
                "lat": 16.8667,
                "lng": -96.7833
            },
            "Tepetlixpa": {
                "lat": 19.0006,
                "lng": -98.8178
            },
            "Peto": {
                "lat": 20.1256,
                "lng": -88.9214
            },
            "Atlapexco": {
                "lat": 21.0221,
                "lng": -98.3543
            },
            "Calvillo": {
                "lat": 21.85,
                "lng": -102.7167
            },
            "Colotl\u00e1n": {
                "lat": 22.2,
                "lng": -103.3
            },
            "Chiconcuac": {
                "lat": 19.55,
                "lng": -98.9
            },
            "Lerdo de Tejada": {
                "lat": 18.638,
                "lng": -95.5085
            },
            "San Juan Zitlaltepec": {
                "lat": 19.7167,
                "lng": -99.05
            },
            "Hidalgotitl\u00e1n": {
                "lat": 17.7833,
                "lng": -94.6333
            },
            "Suchiapa": {
                "lat": 16.6181,
                "lng": -93.0942
            },
            "Cardonal": {
                "lat": 20.6167,
                "lng": -99.1167
            },
            "Jos\u00e9 Cardel": {
                "lat": 19.3667,
                "lng": -96.3667
            },
            "Mapastepec": {
                "lat": 15.4411,
                "lng": -92.8916
            },
            "Texcaltitl\u00e1n": {
                "lat": 18.9297,
                "lng": -99.9372
            },
            "Puerto Morelos": {
                "lat": 20.8536,
                "lng": -86.8753
            },
            "Jamiltepec": {
                "lat": 16.2833,
                "lng": -97.8167
            },
            "Senguio": {
                "lat": 19.7328,
                "lng": -100.3522
            },
            "Cacahoat\u00e1n": {
                "lat": 14.9898,
                "lng": -92.1681
            },
            "Cher\u00e1n": {
                "lat": 19.6833,
                "lng": -101.95
            },
            "Ixtlahuac\u00e1n del R\u00edo": {
                "lat": 20.8667,
                "lng": -103.25
            },
            "Cotija de la Paz": {
                "lat": 19.8167,
                "lng": -102.6833
            },
            "Santa Ana Jilotzingo": {
                "lat": 19.4,
                "lng": -99.2333
            },
            "Vista Hermosa de Negrete": {
                "lat": 20.2717,
                "lng": -102.4749
            },
            "San Ignacio Cerro Gordo": {
                "lat": 20.7333,
                "lng": -102.517
            },
            "Ajacuba": {
                "lat": 20.0833,
                "lng": -99.1167
            },
            "Huehuetla": {
                "lat": 20.1075,
                "lng": -97.6233
            },
            "Tlalnelhuayocan": {
                "lat": 19.5667,
                "lng": -96.9667
            },
            "Santa Catarina Juquila": {
                "lat": 16.2379,
                "lng": -97.2914
            },
            "Aldama": {
                "lat": 22.9194,
                "lng": -98.0736
            },
            "Asunci\u00f3n Nochixtl\u00e1n": {
                "lat": 17.45,
                "lng": -97.2167
            },
            "Santa Cruz Zenzontepec": {
                "lat": 16.5333,
                "lng": -97.5
            },
            "Coalcom\u00e1n de V\u00e1zquez Pallares": {
                "lat": 18.7833,
                "lng": -103.1667
            },
            "San Blas Atempa": {
                "lat": 16.3167,
                "lng": -95.2167
            },
            "Filomeno Mata": {
                "lat": 20.2,
                "lng": -97.7
            },
            "Santiago Ixcuintla": {
                "lat": 21.811,
                "lng": -105.2079
            },
            "San Jos\u00e9 Tenango": {
                "lat": 18.15,
                "lng": -96.7333
            },
            "Pijijiapan": {
                "lat": 15.6833,
                "lng": -93.2167
            },
            "San Sebasti\u00e1n Tutla": {
                "lat": 17.0667,
                "lng": -96.6667
            },
            "Xochiatipan de Castillo": {
                "lat": 20.8333,
                "lng": -98.285
            },
            "Tlacoachistlahuaca": {
                "lat": 16.7833,
                "lng": -98.2
            },
            "Cuqu\u00edo": {
                "lat": 20.929,
                "lng": -103.0229
            },
            "Uni\u00f3n de San Antonio": {
                "lat": 21.128,
                "lng": -102.006
            },
            "Zapotl\u00e1n del Rey": {
                "lat": 20.4674,
                "lng": -102.9248
            },
            "San Bartolo Tutotepec": {
                "lat": 20.4,
                "lng": -98.2
            },
            "Villa Corona": {
                "lat": 20.3989,
                "lng": -103.6889
            },
            "Mazapil": {
                "lat": 24.6383,
                "lng": -101.5553
            },
            "Benito Ju\u00e1rez": {
                "lat": 20.8833,
                "lng": -98.2
            },
            "Amacuzac": {
                "lat": 18.6,
                "lng": -99.37
            },
            "Palau": {
                "lat": 27.9167,
                "lng": -101.4167
            },
            "Santos Reyes Nopala": {
                "lat": 16.1,
                "lng": -97.15
            },
            "Compostela": {
                "lat": 21.2333,
                "lng": -104.9
            },
            "Moloac\u00e1n": {
                "lat": 17.9833,
                "lng": -94.35
            },
            "Tenango de Doria": {
                "lat": 20.3356,
                "lng": -98.2267
            },
            "Pajapan": {
                "lat": 18.2667,
                "lng": -94.6833
            },
            "Tepatlaxco": {
                "lat": 19.0667,
                "lng": -97.9667
            },
            "Tasquillo": {
                "lat": 20.6167,
                "lng": -99.25
            },
            "Totutla": {
                "lat": 19.2167,
                "lng": -96.9667
            },
            "Ziracuaretiro": {
                "lat": 19.4333,
                "lng": -101.9167
            },
            "Mecayapan": {
                "lat": 18.2167,
                "lng": -94.8333
            },
            "Tecolotl\u00e1n": {
                "lat": 20.1,
                "lng": -103.8333
            },
            "Ejutla de Crespo": {
                "lat": 16.5672,
                "lng": -96.7308
            },
            "Pisaflores": {
                "lat": 21.1933,
                "lng": -99.005
            },
            "Calnali": {
                "lat": 20.9,
                "lng": -98.5833
            },
            "Tlahuelilpan": {
                "lat": 20.1297,
                "lng": -99.2286
            },
            "Ignacio de la Llave": {
                "lat": 18.6618,
                "lng": -95.9721
            },
            "Pe\u00f1amiller": {
                "lat": 21.0519,
                "lng": -99.815
            },
            "Dzitbalch\u00e9": {
                "lat": 20.3167,
                "lng": -90.05
            },
            "San Juan Lalana": {
                "lat": 17.4667,
                "lng": -95.8833
            },
            "Guadalupe Victoria": {
                "lat": 19.2833,
                "lng": -97.3333
            },
            "Zinap\u00e9cuaro": {
                "lat": 19.8603,
                "lng": -100.8275
            },
            "Alfajayucan": {
                "lat": 20.4,
                "lng": -99.35
            },
            "Salinas de Hidalgo": {
                "lat": 22.628,
                "lng": -101.7156
            },
            "San Juli\u00e1n": {
                "lat": 21.0167,
                "lng": -102.1667
            },
            "Nuevo San Juan Parangaricutiro": {
                "lat": 19.4,
                "lng": -102.1333
            },
            "Chinampa de Gorostiza": {
                "lat": 21.3667,
                "lng": -97.7333
            },
            "Jalpa de M\u00e9ndez": {
                "lat": 18.1764,
                "lng": -93.0631
            },
            "Epitacio Huerta": {
                "lat": 20.1348,
                "lng": -100.2934
            },
            "Santa Mar\u00eda Petapa": {
                "lat": 16.8167,
                "lng": -95.1167
            },
            "Ciudad Guadalupe Victoria": {
                "lat": 24.4497,
                "lng": -104.1225
            },
            "Zapotl\u00e1n de Ju\u00e1rez": {
                "lat": 19.9667,
                "lng": -98.85
            },
            "Juchique de Ferrer": {
                "lat": 19.8333,
                "lng": -96.7
            },
            "Cosautl\u00e1n": {
                "lat": 19.3333,
                "lng": -96.9833
            },
            "Oteapan": {
                "lat": 18.0,
                "lng": -94.6667
            },
            "Santa Anita": {
                "lat": 30.5118,
                "lng": -111.1234
            },
            "Izamal": {
                "lat": 20.9314,
                "lng": -89.0178
            },
            "Venustiano Carranza": {
                "lat": 16.3294,
                "lng": -92.5625
            },
            "Santiago Tulantepec": {
                "lat": 20.0397,
                "lng": -98.3575
            },
            "Ciudad de Huitzuco": {
                "lat": 18.3,
                "lng": -99.35
            },
            "El Rosario": {
                "lat": 22.9922,
                "lng": -105.8572
            },
            "Vicente Guerrero": {
                "lat": 30.7264,
                "lng": -115.9903
            },
            "Tlaltetela": {
                "lat": 19.3167,
                "lng": -96.9
            },
            "Ixhuatl\u00e1n del Sureste": {
                "lat": 18.017,
                "lng": -94.38
            },
            "San Jacinto Amilpas": {
                "lat": 17.1,
                "lng": -96.7667
            },
            "La Cruz": {
                "lat": 23.9214,
                "lng": -106.8919
            },
            "Capilla de Guadalupe": {
                "lat": 20.65,
                "lng": -102.8
            },
            "Tatahuicapan": {
                "lat": 18.25,
                "lng": -94.7667
            },
            "Cos\u00edo": {
                "lat": 22.3667,
                "lng": -102.3
            },
            "Cochoapa el Grande": {
                "lat": 17.2,
                "lng": -98.45
            },
            "Madera": {
                "lat": 29.19,
                "lng": -108.1414
            },
            "Ayutla de los Libres": {
                "lat": 16.9,
                "lng": -99.2167
            },
            "Tanhuato de Guerrero": {
                "lat": 20.2819,
                "lng": -102.3319
            },
            "Amatit\u00e1n": {
                "lat": 20.835,
                "lng": -103.731
            },
            "Erongar\u00edcuaro": {
                "lat": 19.5833,
                "lng": -101.7167
            },
            "Chilcuautla": {
                "lat": 20.3333,
                "lng": -99.2333
            },
            "Pur\u00e9paro de Echa\u00edz": {
                "lat": 19.9,
                "lng": -102.0167
            },
            "Pichucalco": {
                "lat": 17.5086,
                "lng": -93.1178
            },
            "Huasca de Ocampo": {
                "lat": 20.2028,
                "lng": -98.5758
            },
            "San Pedro Tapanatepec": {
                "lat": 16.3667,
                "lng": -94.2
            },
            "Zacualpan": {
                "lat": 18.7197,
                "lng": -99.78
            },
            "Nopala de Villagran": {
                "lat": 20.2528,
                "lng": -99.6433
            },
            "Huitzilan": {
                "lat": 19.9667,
                "lng": -97.6833
            },
            "La Magdalena Tlaltelulco": {
                "lat": 19.2667,
                "lng": -98.2
            },
            "Zozocolco de Hidalgo": {
                "lat": 20.1333,
                "lng": -97.5833
            },
            "Acala": {
                "lat": 16.5533,
                "lng": -92.8069
            },
            "Talpa de Allende": {
                "lat": 20.3806,
                "lng": -104.8222
            },
            "Calkin\u00ed": {
                "lat": 20.3667,
                "lng": -90.05
            },
            "Tecuala": {
                "lat": 22.4004,
                "lng": -105.46
            },
            "Coquimatl\u00e1n": {
                "lat": 19.2038,
                "lng": -103.8086
            },
            "Chemax": {
                "lat": 20.655,
                "lng": -87.9372
            },
            "San Mateo del Mar": {
                "lat": 16.2,
                "lng": -94.9833
            },
            "Huixcolotla": {
                "lat": 18.9147,
                "lng": -97.7736
            },
            "Cerritos": {
                "lat": 22.4275,
                "lng": -100.2783
            },
            "Tepetzintla": {
                "lat": 19.9667,
                "lng": -97.8333
            },
            "Tlaxcala": {
                "lat": 19.3125,
                "lng": -98.24
            },
            "Mineral del Monte": {
                "lat": 20.1333,
                "lng": -98.6667
            },
            "T\u00e9cpan de Galeana": {
                "lat": 17.25,
                "lng": -100.6833
            },
            "Zontecomatl\u00e1n de L\u00f3pez y Fuentes": {
                "lat": 20.7667,
                "lng": -98.3333
            },
            "Guachochi": {
                "lat": 26.8194,
                "lng": -107.07
            },
            "Tlayacapan": {
                "lat": 18.9556,
                "lng": -98.9811
            },
            "Chiconquiaco": {
                "lat": 19.75,
                "lng": -96.8167
            },
            "Nacozari Viejo": {
                "lat": 30.3833,
                "lng": -109.6833
            },
            "Churumuco de Morelos": {
                "lat": 18.6167,
                "lng": -101.6333
            },
            "Santa Rosal\u00eda": {
                "lat": 27.3389,
                "lng": -112.2669
            },
            "Tianguistengo": {
                "lat": 20.7278,
                "lng": -98.6289
            },
            "San Andr\u00e9s Timilpan": {
                "lat": 19.8667,
                "lng": -99.7333
            },
            "El Barrio de la Soledad": {
                "lat": 16.8,
                "lng": -95.1167
            },
            "Ci\u00e9nega de Flores": {
                "lat": 25.95,
                "lng": -100.1833
            },
            "Bochil": {
                "lat": 16.9833,
                "lng": -92.9167
            },
            "Almoloya de Alquisiras": {
                "lat": 18.85,
                "lng": -99.85
            },
            "Juan Aldama": {
                "lat": 24.291,
                "lng": -103.394
            },
            "Grajales": {
                "lat": 19.25,
                "lng": -97.7833
            },
            "Santiago Jocotepec": {
                "lat": 17.5833,
                "lng": -95.8833
            },
            "Tepoztl\u00e1n": {
                "lat": 18.9853,
                "lng": -99.0997
            },
            "Charcas": {
                "lat": 23.1261,
                "lng": -101.1128
            },
            "Santiago de Anaya": {
                "lat": 20.3844,
                "lng": -98.9647
            },
            "Ezequiel Montes": {
                "lat": 20.6667,
                "lng": -99.8833
            },
            "Mazamitla": {
                "lat": 19.9153,
                "lng": -103.0208
            },
            "Polotitl\u00e1n de la Ilustraci\u00f3n": {
                "lat": 20.2253,
                "lng": -99.8154
            },
            "Uni\u00f3n de Tula": {
                "lat": 19.957,
                "lng": -104.268
            },
            "Tampamol\u00f3n Corona": {
                "lat": 21.5667,
                "lng": -98.8167
            },
            "San Pedro Pochutla": {
                "lat": 15.7476,
                "lng": -96.4666
            },
            "Guerrero Negro": {
                "lat": 27.9589,
                "lng": -114.0561
            },
            "Tapilula": {
                "lat": 17.25,
                "lng": -93.0167
            },
            "Ixcatepec": {
                "lat": 21.2333,
                "lng": -98.0
            },
            "Chalma": {
                "lat": 21.2167,
                "lng": -98.4
            },
            "Ilamatl\u00e1n": {
                "lat": 20.7833,
                "lng": -98.45
            },
            "Ascensi\u00f3n": {
                "lat": 31.0928,
                "lng": -107.9964
            },
            "Villa Uni\u00f3n": {
                "lat": 23.9667,
                "lng": -104.0333
            },
            "Cadereyta": {
                "lat": 20.7,
                "lng": -99.8167
            },
            "Soyaniquilpan": {
                "lat": 19.9892,
                "lng": -99.4361
            },
            "Villa Ju\u00e1rez": {
                "lat": 27.1278,
                "lng": -109.8426
            },
            "Santa Mar\u00eda Jalapa del Marqu\u00e9s": {
                "lat": 16.4401,
                "lng": -95.4434
            },
            "Singuilucan": {
                "lat": 19.9675,
                "lng": -98.5178
            },
            "Mazatl\u00e1n Villa de Flores": {
                "lat": 18.0167,
                "lng": -96.9167
            },
            "La Crucecita": {
                "lat": 15.7753,
                "lng": -96.1425
            },
            "Tezontepec": {
                "lat": 19.8833,
                "lng": -98.8167
            },
            "Nacozari de Garc\u00eda": {
                "lat": 30.3736,
                "lng": -109.6864
            },
            "Chapantongo": {
                "lat": 20.2833,
                "lng": -99.4
            },
            "Valpara\u00edso": {
                "lat": 22.7667,
                "lng": -103.5667
            },
            "Ayutla": {
                "lat": 20.0333,
                "lng": -104.4333
            },
            "Nuevo Ideal": {
                "lat": 24.8875,
                "lng": -105.0728
            },
            "Santa Cruz Amilpas": {
                "lat": 17.0667,
                "lng": -96.6833
            },
            "Pueblo Nuevo": {
                "lat": 17.15,
                "lng": -92.9
            },
            "Huazalingo": {
                "lat": 21.8156,
                "lng": -98.5078
            },
            "Santiago Amoltepec": {
                "lat": 16.6167,
                "lng": -97.5
            },
            "Isla Mujeres": {
                "lat": 21.2333,
                "lng": -86.7333
            },
            "Maxcan\u00fa": {
                "lat": 20.5833,
                "lng": -89.9833
            },
            "Xalpatlahuac": {
                "lat": 17.3333,
                "lng": -98.65
            },
            "El Fuerte": {
                "lat": 26.4214,
                "lng": -108.62
            },
            "Bacalar": {
                "lat": 18.6769,
                "lng": -88.3953
            },
            "Villa Sola de Vega": {
                "lat": 16.5125,
                "lng": -96.9806
            },
            "Eloxochitl\u00e1n": {
                "lat": 18.5088,
                "lng": -96.9227
            },
            "Santa Ana Maya": {
                "lat": 20.0,
                "lng": -101.0167
            },
            "San Pedro Jicay\u00e1n": {
                "lat": 16.4167,
                "lng": -97.9833
            },
            "Santa Ana": {
                "lat": 30.5406,
                "lng": -111.1205
            },
            "San Miguel Xoxtla": {
                "lat": 19.1833,
                "lng": -98.3
            },
            "Mecatl\u00e1n": {
                "lat": 20.2135,
                "lng": -97.6574
            },
            "Pantepec": {
                "lat": 17.1833,
                "lng": -93.05
            },
            "Tzintzuntz\u00e1n": {
                "lat": 19.6283,
                "lng": -101.5789
            },
            "Juchipila": {
                "lat": 21.4103,
                "lng": -103.1175
            },
            "Cuichapa": {
                "lat": 18.7667,
                "lng": -96.8667
            },
            "Tempoal de S\u00e1nchez": {
                "lat": 21.5167,
                "lng": -98.3833
            },
            "La Magdalena Chichicaspa": {
                "lat": 19.4181,
                "lng": -99.3228
            },
            "Cocotitl\u00e1n": {
                "lat": 19.2167,
                "lng": -98.85
            },
            "Citlalt\u00e9pec": {
                "lat": 21.3366,
                "lng": -97.8784
            },
            "Yecuatla": {
                "lat": 19.8667,
                "lng": -96.7833
            },
            "San Carlos Yautepec": {
                "lat": 16.5,
                "lng": -96.1
            },
            "Jacala": {
                "lat": 21.0053,
                "lng": -99.1719
            },
            "El Parral": {
                "lat": 16.3662,
                "lng": -93.0067
            },
            "P\u00e1nuco de Coronado": {
                "lat": 24.5392,
                "lng": -104.3253
            },
            "Ojuelos de Jalisco": {
                "lat": 21.8667,
                "lng": -101.5833
            },
            "Jaltenango": {
                "lat": 15.8725,
                "lng": -92.725
            },
            "San Juan del Rio del Centauro del Norte": {
                "lat": 24.7667,
                "lng": -104.4667
            },
            "Chiautla de Tapia": {
                "lat": 18.3,
                "lng": -98.6039
            },
            "Jos\u00e9 Mar\u00eda Morelos": {
                "lat": 19.75,
                "lng": -88.7
            },
            "Tolcayuca": {
                "lat": 19.95,
                "lng": -98.9167
            },
            "San Nicol\u00e1s de los Ranchos": {
                "lat": 19.0667,
                "lng": -98.4833
            },
            "\u00c1ngel R. Cabada": {
                "lat": 18.5969,
                "lng": -95.4453
            },
            "San Pedro Ixcatl\u00e1n": {
                "lat": 18.15,
                "lng": -96.5
            },
            "Mixtla de Altamirano": {
                "lat": 18.6,
                "lng": -97.0
            },
            "Tlachichilco": {
                "lat": 20.6217,
                "lng": -98.1994
            },
            "Santa Cruz Itundujia": {
                "lat": 16.8667,
                "lng": -97.65
            },
            "Jiquipilas": {
                "lat": 16.6677,
                "lng": -93.6505
            },
            "Huandacareo": {
                "lat": 19.9906,
                "lng": -101.275
            },
            "Espita": {
                "lat": 21.0128,
                "lng": -88.3047
            },
            "Epazoyucan": {
                "lat": 20.0177,
                "lng": -98.6361
            },
            "Olintla": {
                "lat": 20.1,
                "lng": -97.6833
            },
            "Muna": {
                "lat": 20.48,
                "lng": -89.72
            },
            "Sonoita": {
                "lat": 31.8614,
                "lng": -112.8544
            },
            "Ajijic": {
                "lat": 20.3,
                "lng": -103.2833
            },
            "Tlacolulan": {
                "lat": 19.6667,
                "lng": -97.0
            },
            "Zaragoza": {
                "lat": 28.4869,
                "lng": -100.9175
            },
            "Villa Purificaci\u00f3n": {
                "lat": 19.8167,
                "lng": -104.7333
            },
            "Amatenango del Valle": {
                "lat": 16.5167,
                "lng": -92.4
            },
            "San Pablo Atlazalpan": {
                "lat": 19.2172,
                "lng": -98.909
            },
            "Anenecuilco": {
                "lat": 18.7781,
                "lng": -98.985
            },
            "Gonz\u00e1lez": {
                "lat": 22.8281,
                "lng": -98.4306
            },
            "Temamatla": {
                "lat": 19.2028,
                "lng": -98.87
            },
            "Pihuamo": {
                "lat": 18.95,
                "lng": -103.167
            },
            "Jamapa": {
                "lat": 19.0411,
                "lng": -96.2411
            },
            "Candelaria": {
                "lat": 18.1835,
                "lng": -91.0463
            },
            "Mineral de Angangueo": {
                "lat": 19.6167,
                "lng": -100.2833
            },
            "San Luis de La Loma": {
                "lat": 17.2714,
                "lng": -100.8911
            },
            "La Trinitaria": {
                "lat": 16.1203,
                "lng": -92.0408
            },
            "Joquicingo": {
                "lat": 19.0556,
                "lng": -99.5467
            },
            "Tezoatl\u00e1n de Segura y Luna": {
                "lat": 17.65,
                "lng": -97.8167
            },
            "Saucillo": {
                "lat": 28.0333,
                "lng": -105.2833
            },
            "Tixkokob": {
                "lat": 21.0022,
                "lng": -89.3936
            },
            "Acanceh": {
                "lat": 20.8133,
                "lng": -89.4524
            },
            "\u00c1lamos": {
                "lat": 27.0275,
                "lng": -108.94
            },
            "Nacajuca": {
                "lat": 18.1692,
                "lng": -93.0197
            },
            "Texcatepec": {
                "lat": 20.5833,
                "lng": -98.3667
            },
            "Soyal\u00f3": {
                "lat": 16.9333,
                "lng": -92.9833
            },
            "Villa Corzo": {
                "lat": 16.1848,
                "lng": -93.2677
            },
            "Totolapan": {
                "lat": 18.9869,
                "lng": -98.9197
            },
            "Santa B\u00e1rbara": {
                "lat": 26.8133,
                "lng": -105.8203
            },
            "Santa Catarina Ayotzingo": {
                "lat": 19.2647,
                "lng": -98.8975
            },
            "Almoloya": {
                "lat": 19.7,
                "lng": -98.4
            },
            "Escuintla": {
                "lat": 15.3333,
                "lng": -92.6333
            },
            "Altamirano": {
                "lat": 16.7361,
                "lng": -92.0389
            },
            "San Ignacio Cohuirimpo": {
                "lat": 27.05,
                "lng": -109.4167
            },
            "Tenango del Aire": {
                "lat": 19.1575,
                "lng": -98.8581
            },
            "San Juanito": {
                "lat": 27.97,
                "lng": -107.6003
            },
            "Tierra Colorada": {
                "lat": 17.1656,
                "lng": -99.5264
            },
            "Candelaria Loxicha": {
                "lat": 15.9167,
                "lng": -96.4833
            },
            "Coacoatzintla": {
                "lat": 19.65,
                "lng": -96.9333
            },
            "Las Veredas": {
                "lat": 23.1503,
                "lng": -109.7061
            },
            "Santiago Tangamandapio": {
                "lat": 19.95,
                "lng": -102.4333
            },
            "Teotlaltzingo": {
                "lat": 19.2333,
                "lng": -98.4833
            },
            "Santiago Suchilquitongo": {
                "lat": 17.25,
                "lng": -96.8833
            },
            "Cuatro Ci\u00e9negas de Carranza": {
                "lat": 26.9861,
                "lng": -102.0664
            },
            "Molango": {
                "lat": 20.7844,
                "lng": -98.7175
            },
            "Coroneo": {
                "lat": 20.1333,
                "lng": -100.3333
            },
            "Canatl\u00e1n": {
                "lat": 24.52,
                "lng": -104.78
            },
            "Teocuitatl\u00e1n de Corona": {
                "lat": 20.0167,
                "lng": -103.1833
            },
            "Agua Blanca Iturbide": {
                "lat": 20.35,
                "lng": -98.35
            },
            "Hecelchak\u00e1n": {
                "lat": 20.1667,
                "lng": -90.1333
            },
            "Jaltocan": {
                "lat": 21.1333,
                "lng": -98.5383
            },
            "Chavinda": {
                "lat": 20.0167,
                "lng": -102.45
            },
            "Tlalixtac de Cabrera": {
                "lat": 17.0667,
                "lng": -96.65
            },
            "Tetela del Volc\u00e1n": {
                "lat": 18.8931,
                "lng": -98.7297
            },
            "San Blas": {
                "lat": 21.5397,
                "lng": -105.2856
            },
            "Akil": {
                "lat": 20.2656,
                "lng": -89.3478
            },
            "Mexicaltzingo": {
                "lat": 19.2092,
                "lng": -99.5858
            },
            "Cuapiaxtla de Madero": {
                "lat": 18.9167,
                "lng": -97.8167
            },
            "La Misi\u00f3n": {
                "lat": 21.1,
                "lng": -99.1333
            },
            "Chichihualco": {
                "lat": 17.655,
                "lng": -99.674
            },
            "Ciudad Tula": {
                "lat": 23.0,
                "lng": -99.72
            },
            "Parilla": {
                "lat": 17.9119,
                "lng": -92.9175
            },
            "Benem\u00e9rito": {
                "lat": 16.5158,
                "lng": -90.6533
            },
            "Huejuquilla el Alto": {
                "lat": 22.6256,
                "lng": -103.8964
            },
            "Pajacuar\u00e1n": {
                "lat": 20.1178,
                "lng": -102.5667
            },
            "San Pedro Huamelula": {
                "lat": 16.0167,
                "lng": -95.6667
            },
            "Plat\u00f3n S\u00e1nchez": {
                "lat": 21.2833,
                "lng": -98.3667
            },
            "China": {
                "lat": 25.7033,
                "lng": -99.2365
            },
            "San Nicolas Buenos Aires": {
                "lat": 19.1667,
                "lng": -97.55
            },
            "Tzucacab": {
                "lat": 20.0708,
                "lng": -89.0506
            },
            "An\u00e1huac": {
                "lat": 28.48,
                "lng": -106.7442
            },
            "Cuicatlan": {
                "lat": 17.8,
                "lng": -96.95
            },
            "Coycoyan de las Flores": {
                "lat": 17.2667,
                "lng": -98.2667
            },
            "Jilotl\u00e1n de los Dolores": {
                "lat": 19.3719,
                "lng": -103.0193
            },
            "Huichapan": {
                "lat": 20.3733,
                "lng": -99.6489
            },
            "Cuencam\u00e9 de Ceniceros": {
                "lat": 24.8667,
                "lng": -103.7
            },
            "Tekit": {
                "lat": 20.5322,
                "lng": -89.3314
            },
            "Etchojoa": {
                "lat": 26.8667,
                "lng": -109.65
            },
            "Ciudad Cuauht\u00e9moc": {
                "lat": 21.2833,
                "lng": -98.3667
            },
            "Santo Tom\u00e1s de los Pl\u00e1tanos": {
                "lat": 19.1817,
                "lng": -100.2589
            },
            "Pueblo Viejo": {
                "lat": 19.179,
                "lng": -100.2984
            },
            "San Gregorio Atzompa": {
                "lat": 19.0224,
                "lng": -98.3445
            },
            "Tepetl\u00e1n": {
                "lat": 19.6667,
                "lng": -96.8
            },
            "Comala": {
                "lat": 19.3208,
                "lng": -103.7603
            },
            "Tila": {
                "lat": 17.3006,
                "lng": -92.4259
            },
            "Xicot\u00e9ncatl": {
                "lat": 22.9958,
                "lng": -98.9447
            },
            "Villa Aldama": {
                "lat": 19.65,
                "lng": -97.2333
            },
            "Cuervos": {
                "lat": 32.6308,
                "lng": -114.8569
            },
            "Lototla": {
                "lat": 20.8392,
                "lng": -98.7178
            },
            "Chanal": {
                "lat": 16.6601,
                "lng": -92.2599
            },
            "Tlahualilo de Zaragoza": {
                "lat": 26.1083,
                "lng": -103.4431
            },
            "Seybaplaya": {
                "lat": 19.6394,
                "lng": -90.6883
            },
            "Car\u00e1cuaro": {
                "lat": 19.0167,
                "lng": -101.1261
            },
            "Santa Mar\u00eda Jacatepec": {
                "lat": 17.85,
                "lng": -96.2
            },
            "San Juanito de Escobedo": {
                "lat": 20.8,
                "lng": -104.0
            },
            "Halach\u00f3": {
                "lat": 20.4764,
                "lng": -90.0819
            },
            "Zacualpan de Amilpas": {
                "lat": 18.7836,
                "lng": -98.7594
            },
            "Ecatzingo": {
                "lat": 18.95,
                "lng": -98.75
            },
            "San Agust\u00edn de las Juntas": {
                "lat": 17.0,
                "lng": -96.7167
            },
            "Doctor Arroyo": {
                "lat": 23.6667,
                "lng": -100.1667
            },
            "San Pablo Huixtepec": {
                "lat": 16.8167,
                "lng": -96.7833
            },
            "Choix": {
                "lat": 26.7086,
                "lng": -108.3253
            },
            "Mascota": {
                "lat": 20.5235,
                "lng": -104.7886
            },
            "Tlahuiltepa": {
                "lat": 20.9233,
                "lng": -98.9497
            },
            "Otumba": {
                "lat": 19.6989,
                "lng": -98.7572
            },
            "Santa Isabel Cholula": {
                "lat": 19.0,
                "lng": -98.3667
            },
            "Santa Mar\u00eda Ajoloapan": {
                "lat": 19.9692,
                "lng": -99.0353
            },
            "Santo Domingo Petapa": {
                "lat": 16.8167,
                "lng": -95.1333
            },
            "Tecoh": {
                "lat": 20.7419,
                "lng": -89.4744
            },
            "Ciudad Insurgentes": {
                "lat": 25.2617,
                "lng": -111.7744
            },
            "Amatl\u00e1n de los Reyes": {
                "lat": 18.8457,
                "lng": -96.9149
            },
            "Coatetelco": {
                "lat": 18.7294,
                "lng": -99.3256
            },
            "San Pedro Atocpan": {
                "lat": 19.2006,
                "lng": -99.0492
            },
            "Ixtapa Zihuatanejo": {
                "lat": 17.6367,
                "lng": -101.5514
            },
            "San Francisco Ixhuatan": {
                "lat": 16.35,
                "lng": -94.4833
            },
            "Higuera de Zaragoza": {
                "lat": 25.95,
                "lng": -109.2833
            },
            "Tepechitl\u00e1n": {
                "lat": 21.6667,
                "lng": -103.3333
            },
            "Tzitzio": {
                "lat": 19.5856,
                "lng": -100.9231
            },
            "San Francisco Chimalpa": {
                "lat": 19.4419,
                "lng": -99.3419
            },
            "Almoloya del R\u00edo": {
                "lat": 19.1586,
                "lng": -99.4886
            },
            "La Junta": {
                "lat": 28.4778,
                "lng": -107.3317
            },
            "Santa Mar\u00eda del Tule": {
                "lat": 17.0465,
                "lng": -96.6363
            },
            "Santa Cruz Atizap\u00e1n": {
                "lat": 19.1756,
                "lng": -99.4886
            },
            "San Jos\u00e9 de Gracia": {
                "lat": 22.15,
                "lng": -102.4167
            },
            "Tepetitlan": {
                "lat": 20.1842,
                "lng": -99.3808
            },
            "Tataltepec de Vald\u00e9s": {
                "lat": 16.3064,
                "lng": -97.5461
            },
            "Ayapango": {
                "lat": 19.1264,
                "lng": -98.8028
            },
            "Santa Mar\u00eda Xadani": {
                "lat": 16.3667,
                "lng": -95.0167
            },
            "San Juan Ixcaquixtla": {
                "lat": 18.45,
                "lng": -97.8167
            },
            "Hostotipaquillo": {
                "lat": 21.2167,
                "lng": -103.85
            },
            "L\u00e1zaro C\u00e1rdenas": {
                "lat": 28.3897,
                "lng": -105.6236
            },
            "Monte Escobedo": {
                "lat": 22.3,
                "lng": -103.5667
            },
            "Teotitl\u00e1n": {
                "lat": 18.1333,
                "lng": -97.0833
            },
            "Pantelh\u00f3": {
                "lat": 17.0,
                "lng": -92.4833
            },
            "Ray\u00f3n": {
                "lat": 19.1481,
                "lng": -99.58
            },
            "V\u00edcam Pueblo": {
                "lat": 27.6422,
                "lng": -110.2897
            },
            "El Espinal": {
                "lat": 16.4906,
                "lng": -95.0444
            },
            "Ahumada": {
                "lat": 30.6186,
                "lng": -106.5122
            },
            "Coahuitl\u00e1n": {
                "lat": 20.2667,
                "lng": -97.7167
            },
            "San Pedro Ixtlahuaca": {
                "lat": 17.05,
                "lng": -96.8167
            },
            "Ayotoxco de Guerrero": {
                "lat": 20.1,
                "lng": -97.4
            },
            "Bacobampo": {
                "lat": 26.9889,
                "lng": -109.65
            },
            "Hueyotlipan": {
                "lat": 18.9,
                "lng": -97.85
            },
            "San Pablo Villa de Mitla": {
                "lat": 16.917,
                "lng": -96.4
            },
            "San Quint\u00edn": {
                "lat": 30.4837,
                "lng": -115.95
            },
            "Esperanza": {
                "lat": 27.58,
                "lng": -109.9298
            },
            "Nautla": {
                "lat": 20.2167,
                "lng": -96.7833
            },
            "Altata": {
                "lat": 24.6333,
                "lng": -107.9167
            },
            "Mazat\u00e1n": {
                "lat": 29.0167,
                "lng": -110.1333
            },
            "Punta Prieta": {
                "lat": 28.9289,
                "lng": -114.1556
            }
        }
    },
    "EG": {
        "name": "Egypt",
        "cities": {
            "Cairo": {
                "lat": 30.0444,
                "lng": 31.2358
            },
            "Giza": {
                "lat": 29.987,
                "lng": 31.2118
            },
            "Alexandria": {
                "lat": 31.1975,
                "lng": 29.8925
            },
            "Shubr\u0101 al Khaymah": {
                "lat": 30.1286,
                "lng": 31.2422
            },
            "Ashm\u016bn": {
                "lat": 30.2941,
                "lng": 31.0342
            },
            "Sinn\u016bris": {
                "lat": 29.4072,
                "lng": 30.8667
            },
            "Dayr\u016b\u0163": {
                "lat": 27.5667,
                "lng": 30.8167
            },
            "\u0162\u016bkh": {
                "lat": 30.3539,
                "lng": 31.2007
            },
            "Al Man\u015f\u016brah": {
                "lat": 31.05,
                "lng": 31.3833
            },
            "\u1e28alw\u0101n": {
                "lat": 29.8453,
                "lng": 31.3333
            },
            "Al Ma\u1e29allah al Kubr\u00e1": {
                "lat": 30.9686,
                "lng": 31.1636
            },
            "Shib\u012bn al Qan\u0101\u0163ir": {
                "lat": 30.3133,
                "lng": 31.3214
            },
            "Ab\u016b \u1e28ummu\u015f": {
                "lat": 31.1006,
                "lng": 30.3128
            },
            "Port Said": {
                "lat": 31.2625,
                "lng": 32.3061
            },
            "Al Fayy\u016bm": {
                "lat": 29.3084,
                "lng": 30.8441
            },
            "Suez": {
                "lat": 29.9667,
                "lng": 32.55
            },
            "Ab\u016b \u0162isht": {
                "lat": 26.1183,
                "lng": 32.0953
            },
            "Al Fashn": {
                "lat": 28.8227,
                "lng": 30.8992
            },
            "Bib\u0101": {
                "lat": 28.9218,
                "lng": 30.9844
            },
            "Akhm\u012bm": {
                "lat": 26.5667,
                "lng": 31.75
            },
            "Al Mar\u0101ghah": {
                "lat": 26.7,
                "lng": 31.6
            },
            "\u0162an\u0163\u0101": {
                "lat": 30.7833,
                "lng": 31.0
            },
            "Ismailia": {
                "lat": 30.5833,
                "lng": 32.2667
            },
            "Al B\u0101j\u016br": {
                "lat": 30.4333,
                "lng": 31.0569
            },
            "Aws\u012bm": {
                "lat": 30.1167,
                "lng": 31.1333
            },
            "Tal\u0101": {
                "lat": 30.6801,
                "lng": 30.9438
            },
            "\u0162alkh\u0101": {
                "lat": 31.0547,
                "lng": 31.3756
            },
            "Asy\u016b\u0163": {
                "lat": 27.1833,
                "lng": 31.1667
            },
            "Dikirnis": {
                "lat": 31.0883,
                "lng": 31.5969
            },
            "Ash Shuhad\u0101\u2019": {
                "lat": 30.6039,
                "lng": 30.9021
            },
            "Birkat as Sab\u2018": {
                "lat": 30.6338,
                "lng": 31.0848
            },
            "Az Zaq\u0101z\u012bq": {
                "lat": 30.5667,
                "lng": 31.5
            },
            "Al Waqf": {
                "lat": 26.0808,
                "lng": 32.4245
            },
            "Al \u2018Ajam\u012b": {
                "lat": 31.0959,
                "lng": 29.7604
            },
            "Al Ma\u0163ar\u012byah": {
                "lat": 31.1833,
                "lng": 32.0333
            },
            "Shubr\u0101kh\u012bt": {
                "lat": 31.0275,
                "lng": 30.7128
            },
            "Kafr \u015eaqr": {
                "lat": 30.7964,
                "lng": 31.6261
            },
            "Qill\u012bn": {
                "lat": 31.0464,
                "lng": 30.8546
            },
            "Shib\u012bn al Kawm": {
                "lat": 30.5586,
                "lng": 31.01
            },
            "Asw\u0101n": {
                "lat": 24.0889,
                "lng": 32.8997
            },
            "Kafr ad Daww\u0101r": {
                "lat": 31.1311,
                "lng": 30.13
            },
            "R\u00e2s el-Barr": {
                "lat": 31.5125,
                "lng": 31.8256
            },
            "Al \u2018\u0100shir min Rama\u1e11\u0101n": {
                "lat": 30.3065,
                "lng": 31.7415
            },
            "Damanh\u016br": {
                "lat": 31.0361,
                "lng": 30.4694
            },
            "Al Miny\u0101": {
                "lat": 28.1194,
                "lng": 30.7444
            },
            "Biyal\u0101": {
                "lat": 31.1747,
                "lng": 31.2204
            },
            "S\u0101qultah": {
                "lat": 26.65,
                "lng": 31.6667
            },
            "Mallaw\u012b": {
                "lat": 27.7333,
                "lng": 30.8333
            },
            "Farsh\u016b\u0163": {
                "lat": 26.055,
                "lng": 32.1639
            },
            "Damietta": {
                "lat": 31.4167,
                "lng": 31.8214
            },
            "Luxor": {
                "lat": 25.6967,
                "lng": 32.6444
            },
            "Timayy al Imd\u012bd": {
                "lat": 30.9438,
                "lng": 31.5293
            },
            "Qin\u0101": {
                "lat": 26.1667,
                "lng": 32.7167
            },
            "\u015eidf\u0101": {
                "lat": 26.9653,
                "lng": 31.3808
            },
            "S\u016bh\u0101j": {
                "lat": 26.55,
                "lng": 31.7
            },
            "Ban\u012b Suwayf": {
                "lat": 29.0667,
                "lng": 31.0833
            },
            "Al \u2018Ar\u012bsh": {
                "lat": 31.1249,
                "lng": 33.8006
            },
            "Ar Ra\u1e29m\u0101n\u012byah": {
                "lat": 31.1062,
                "lng": 30.6337
            },
            "Al Ghardaqah": {
                "lat": 27.2578,
                "lng": 33.8117
            },
            "Banh\u0101": {
                "lat": 30.4667,
                "lng": 31.1833
            },
            "Qaly\u016bb": {
                "lat": 30.1997,
                "lng": 31.2053
            },
            "Mad\u012bnat as S\u0101dis min Ukt\u016bbar": {
                "lat": 29.9833,
                "lng": 30.9667
            },
            "Kafr ash Shaykh": {
                "lat": 31.1117,
                "lng": 30.9458
            },
            "Dis\u016bq": {
                "lat": 31.1422,
                "lng": 30.645
            },
            "Bilbays": {
                "lat": 30.4167,
                "lng": 31.5667
            },
            "Idf\u016b": {
                "lat": 24.9778,
                "lng": 32.8733
            },
            "Rosetta": {
                "lat": 31.4044,
                "lng": 30.4164
            },
            "M\u012bt Ghamr": {
                "lat": 30.7167,
                "lng": 31.25
            },
            "Mun\u016bf": {
                "lat": 30.4658,
                "lng": 30.9308
            },
            "F\u0101q\u016bs": {
                "lat": 30.7282,
                "lng": 31.797
            },
            "Ma\u0163r\u016b\u1e29": {
                "lat": 31.3333,
                "lng": 27.2167
            },
            "Idk\u016b": {
                "lat": 31.3,
                "lng": 30.3
            },
            "Jirj\u0101": {
                "lat": 26.3333,
                "lng": 31.9
            },
            "Ra\u2019s Gh\u0101rib": {
                "lat": 28.3597,
                "lng": 33.0775
            },
            "Al Jamm\u0101l\u012byah": {
                "lat": 31.1806,
                "lng": 31.8653
            },
            "Zift\u00e1": {
                "lat": 30.7142,
                "lng": 31.2443
            },
            "Sam\u0101l\u016b\u0163": {
                "lat": 28.3097,
                "lng": 30.7108
            },
            "Al Badrashayn": {
                "lat": 29.852,
                "lng": 31.276
            },
            "Bi\u2019r al \u2018Abd": {
                "lat": 31.0181,
                "lng": 33.0111
            },
            "Ibshaw\u0101y": {
                "lat": 29.4447,
                "lng": 30.7107
            },
            "Manfal\u016b\u0163": {
                "lat": 27.3167,
                "lng": 30.9667
            },
            "Rafa\u1e29": {
                "lat": 31.2808,
                "lng": 34.2414
            },
            "M\u012bt Sals\u012bl": {
                "lat": 31.1667,
                "lng": 31.8
            },
            "Ban\u012b Maz\u0101r": {
                "lat": 28.5,
                "lng": 30.8
            },
            "Mad\u012bnat as S\u0101d\u0101t": {
                "lat": 30.3811,
                "lng": 30.5266
            },
            "Al Kh\u0101nkah": {
                "lat": 30.1601,
                "lng": 31.3133
            },
            "Armant": {
                "lat": 25.6167,
                "lng": 32.5333
            },
            "Kafr az Zayy\u0101t": {
                "lat": 30.8247,
                "lng": 30.8153
            },
            "Magh\u0101ghah": {
                "lat": 28.65,
                "lng": 30.85
            },
            "Kawm Umb\u016b": {
                "lat": 24.4667,
                "lng": 32.95
            },
            "B\u016br Fu\u2019\u0101d": {
                "lat": 31.25,
                "lng": 32.3167
            },
            "\u2018Izbat al Burj": {
                "lat": 31.5031,
                "lng": 31.8411
            },
            "Al Q\u016b\u015f\u012byah": {
                "lat": 27.4143,
                "lng": 30.8216
            },
            "Kird\u0101sah": {
                "lat": 30.032,
                "lng": 31.1104
            },
            "Isn\u0101": {
                "lat": 25.3,
                "lng": 32.55
            },
            "Abn\u016bb": {
                "lat": 27.2667,
                "lng": 31.15
            },
            "Hihy\u0101": {
                "lat": 30.6687,
                "lng": 31.5904
            },
            "Al Minsh\u0101h": {
                "lat": 26.4833,
                "lng": 31.8
            },
            "Al Qurayn": {
                "lat": 30.6161,
                "lng": 31.7353
            },
            "Ash Shaykh Zuwayd": {
                "lat": 31.2119,
                "lng": 34.1106
            },
            "Dalj\u0101": {
                "lat": 27.65,
                "lng": 30.7
            },
            "Ab\u016b Qurq\u0101\u015f": {
                "lat": 27.9333,
                "lng": 30.8333
            },
            "S\u012bd\u012b Bar\u0101n\u012b": {
                "lat": 31.6108,
                "lng": 25.9256
            },
            "Naj\u2018 \u1e28amm\u0101d\u012b": {
                "lat": 26.05,
                "lng": 32.25
            },
            "A\u015f \u015eaff": {
                "lat": 29.5772,
                "lng": 31.2906
            },
            "Samann\u016bd": {
                "lat": 30.9667,
                "lng": 31.25
            },
            "Ab\u016b Za\u2018bal": {
                "lat": 30.2415,
                "lng": 31.411
            },
            "Dandarah": {
                "lat": 26.1681,
                "lng": 32.6561
            },
            "Al Kh\u0101rjah": {
                "lat": 25.44,
                "lng": 30.55
            },
            "Al Balyan\u0101": {
                "lat": 26.2333,
                "lng": 32.0
            },
            "Ma\u0163\u0101y": {
                "lat": 28.4167,
                "lng": 30.75
            },
            "Ban\u012b \u2018Ubayd": {
                "lat": 31.0234,
                "lng": 31.6479
            },
            "Kafr al Ba\u0163\u0163\u012bkh": {
                "lat": 31.3234,
                "lng": 31.7612
            },
            "Ab\u016b al Ma\u0163\u0101m\u012br": {
                "lat": 30.9084,
                "lng": 30.1485
            },
            "Al \u2018Ayy\u0101\u0163": {
                "lat": 29.6167,
                "lng": 31.25
            },
            "\u015e\u0101n al \u1e28ajar al Qibl\u012byah": {
                "lat": 30.9769,
                "lng": 31.88
            },
            "Al Bad\u0101r\u012b": {
                "lat": 26.9925,
                "lng": 31.4156
            },
            "Kafr al Kurd\u012b": {
                "lat": 31.1429,
                "lng": 31.7826
            },
            "Dayr Maw\u0101s": {
                "lat": 27.6333,
                "lng": 30.85
            },
            "Ihn\u0101sy\u0101 al Mad\u012bnah": {
                "lat": 29.0833,
                "lng": 30.9333
            },
            "Nabar\u016bh": {
                "lat": 31.0942,
                "lng": 31.3
            },
            "Dar\u0101w": {
                "lat": 24.4,
                "lng": 32.9167
            },
            "Ab\u016b Q\u012br": {
                "lat": 31.3167,
                "lng": 30.0667
            },
            "F\u0101rask\u016br": {
                "lat": 31.3297,
                "lng": 31.7147
            },
            "Badr": {
                "lat": 30.136,
                "lng": 31.715
            },
            "Shanaw\u0101n": {
                "lat": 30.5031,
                "lng": 31.0058
            },
            "Qu\u0163\u016br": {
                "lat": 30.9735,
                "lng": 30.9563
            },
            "Ma\u1e29allat Damanah": {
                "lat": 31.0754,
                "lng": 31.4959
            },
            "Al \u1e28usayn\u012byah": {
                "lat": 30.8617,
                "lng": 31.9181
            },
            "Ab\u016b \u015euwayr": {
                "lat": 30.5633,
                "lng": 32.1155
            },
            "Saf\u0101j\u0101": {
                "lat": 26.7333,
                "lng": 33.9333
            },
            "Qiman al \u2018Ar\u016bs": {
                "lat": 29.3005,
                "lng": 31.1683
            },
            "As Sarw": {
                "lat": 31.2387,
                "lng": 31.6538
            },
            "Qah\u0101": {
                "lat": 30.2833,
                "lng": 31.2
            },
            "Al Karnak": {
                "lat": 25.7186,
                "lng": 32.6586
            },
            "Hirr\u012byat Raznah": {
                "lat": 30.6028,
                "lng": 31.5372
            },
            "Kafr Shukr": {
                "lat": 30.547,
                "lng": 31.2673
            },
            "S\u012bwah": {
                "lat": 29.2,
                "lng": 25.5167
            },
            "Kafr Sa\u2018d": {
                "lat": 31.3594,
                "lng": 31.6865
            },
            "Sh\u0101r\u016bnah": {
                "lat": 28.5667,
                "lng": 30.85
            },
            "Ab\u016b \u015e\u012br Ban\u0101": {
                "lat": 30.9,
                "lng": 31.2347
            },
            "Qif\u0163": {
                "lat": 25.9956,
                "lng": 32.8158
            },
            "Naq\u0101dah": {
                "lat": 25.9,
                "lng": 32.7167
            },
            "M\u012bt Nam\u0101": {
                "lat": 30.1453,
                "lng": 31.2343
            },
            "Al Qays": {
                "lat": 28.4833,
                "lng": 30.7833
            },
            "Sunb\u0101\u0163": {
                "lat": 30.8057,
                "lng": 31.207
            },
            "\u015e\u0101 al \u1e28ajar": {
                "lat": 30.9647,
                "lng": 30.7683
            },
            "Jar\u0101j\u016bs": {
                "lat": 25.8681,
                "lng": 32.7566
            },
            "Ban\u012b Murr": {
                "lat": 27.2272,
                "lng": 31.1944
            },
            "Z\u0101wiyat Raz\u012bn": {
                "lat": 30.4122,
                "lng": 30.8536
            },
            "Dayr Ab\u016b \u1e28innis": {
                "lat": 27.7864,
                "lng": 30.905
            },
            "Ban\u012b \u1e28asan ash Shur\u016bq": {
                "lat": 27.9333,
                "lng": 30.8833
            },
            "Aj\u0101": {
                "lat": 30.9414,
                "lng": 31.2942
            },
            "Biyahm\u016b": {
                "lat": 29.3675,
                "lng": 30.8528
            },
            "Al \u1e28\u012bbah": {
                "lat": 28.7736,
                "lng": 30.9228
            },
            "Al Mad\u0101m\u016bd": {
                "lat": 25.7167,
                "lng": 32.65
            },
            "Sakh\u0101": {
                "lat": 31.089,
                "lng": 30.951
            },
            "Dayr al Barsh\u0101": {
                "lat": 27.75,
                "lng": 30.9
            },
            "A\u1e11 \u1e10ab\u2018ah": {
                "lat": 31.0338,
                "lng": 28.4333
            },
            "Birq\u0101sh": {
                "lat": 30.1692,
                "lng": 31.0417
            },
            "Qant\u012br": {
                "lat": 30.8,
                "lng": 31.8333
            },
            "Sharm ash Shaykh": {
                "lat": 27.915,
                "lng": 34.3275
            },
            "Sidmant al Jabal": {
                "lat": 29.1431,
                "lng": 30.9
            },
            "Dinshaw\u0101y": {
                "lat": 30.6,
                "lng": 30.8167
            },
            "A\u0163 \u0162\u016br": {
                "lat": 28.2417,
                "lng": 33.6222
            },
            "Burj al \u2018Arab": {
                "lat": 30.8489,
                "lng": 29.6117
            },
            "Dish\u0101shah": {
                "lat": 28.9831,
                "lng": 30.8492
            },
            "M\u012bt Dams\u012bs": {
                "lat": 30.8267,
                "lng": 31.2226
            },
            "Qal\u2018at an Nakhl": {
                "lat": 29.9,
                "lng": 33.75
            },
            "Saw\u0101dah": {
                "lat": 28.0775,
                "lng": 30.7953
            },
            "Abj\u012bj": {
                "lat": 29.2861,
                "lng": 30.8152
            },
            "Quf\u0101dah": {
                "lat": 28.5812,
                "lng": 30.7554
            },
            "Shanh\u016br": {
                "lat": 25.8604,
                "lng": 32.7779
            },
            "Mand\u012bshah": {
                "lat": 28.3515,
                "lng": 28.9317
            },
            "Dayr as Sanq\u016br\u012byah": {
                "lat": 28.4868,
                "lng": 30.6648
            },
            "Najr\u012bj": {
                "lat": 30.9667,
                "lng": 30.8667
            },
            "As Sall\u016bm": {
                "lat": 31.55,
                "lng": 25.16
            },
            "Qa\u015fr al Far\u0101firah": {
                "lat": 27.0583,
                "lng": 27.97
            },
            "Al Qa\u015fr": {
                "lat": 25.6959,
                "lng": 28.8837
            },
            "Al \u2018Alamayn": {
                "lat": 30.8333,
                "lng": 28.95
            },
            "Barn\u012bs": {
                "lat": 23.946,
                "lng": 35.4842
            }
        }
    },
    "US": {
        "name": "United States",
        "cities": {
            "New York": {
                "lat": 40.6943,
                "lng": -73.9249
            },
            "Los Angeles": {
                "lat": 34.1141,
                "lng": -118.4068
            },
            "Chicago": {
                "lat": 41.8375,
                "lng": -87.6866
            },
            "Miami": {
                "lat": 36.8878,
                "lng": -94.8711
            },
            "Houston": {
                "lat": 29.786,
                "lng": -95.3885
            },
            "Dallas": {
                "lat": 41.3608,
                "lng": -75.9656
            },
            "Philadelphia": {
                "lat": 40.0077,
                "lng": -75.1339
            },
            "Atlanta": {
                "lat": 33.7628,
                "lng": -84.422
            },
            "Washington": {
                "lat": 40.9884,
                "lng": -74.0636
            },
            "Boston": {
                "lat": 42.3188,
                "lng": -71.0852
            },
            "Phoenix": {
                "lat": 33.5722,
                "lng": -112.0892
            },
            "Detroit": {
                "lat": 42.3834,
                "lng": -83.1024
            },
            "Seattle": {
                "lat": 47.6211,
                "lng": -122.3244
            },
            "San Francisco": {
                "lat": 37.7558,
                "lng": -122.4449
            },
            "San Diego": {
                "lat": 32.8313,
                "lng": -117.1222
            },
            "Minneapolis": {
                "lat": 44.9635,
                "lng": -93.2678
            },
            "Tampa": {
                "lat": 27.9945,
                "lng": -82.4447
            },
            "Brooklyn": {
                "lat": 41.4349,
                "lng": -81.7497
            },
            "Denver": {
                "lat": 39.762,
                "lng": -104.8758
            },
            "Queens": {
                "lat": 40.7498,
                "lng": -73.7976
            },
            "Riverside": {
                "lat": 41.0318,
                "lng": -73.5827
            },
            "Las Vegas": {
                "lat": 35.6011,
                "lng": -105.2206
            },
            "Baltimore": {
                "lat": 39.3051,
                "lng": -76.6144
            },
            "St. Louis": {
                "lat": 38.6359,
                "lng": -90.2451
            },
            "Portland": {
                "lat": 36.5921,
                "lng": -86.5239
            },
            "San Antonio": {
                "lat": 29.4632,
                "lng": -98.5238
            },
            "Sacramento": {
                "lat": 38.5677,
                "lng": -121.4685
            },
            "Austin": {
                "lat": 43.6721,
                "lng": -92.9784
            },
            "Orlando": {
                "lat": 28.4773,
                "lng": -81.337
            },
            "San Jose": {
                "lat": 37.3012,
                "lng": -121.848
            },
            "Pittsburgh": {
                "lat": 40.4397,
                "lng": -79.9763
            },
            "Indianapolis": {
                "lat": 39.7771,
                "lng": -86.1458
            },
            "Manhattan": {
                "lat": 41.4274,
                "lng": -87.9805
            },
            "Cincinnati": {
                "lat": 39.1413,
                "lng": -84.506
            },
            "Kansas City": {
                "lat": 39.1235,
                "lng": -94.7443
            },
            "Cleveland": {
                "lat": 33.744,
                "lng": -90.7285
            },
            "Columbus": {
                "lat": 33.5088,
                "lng": -88.4096
            },
            "Bronx": {
                "lat": 40.8501,
                "lng": -73.8662
            },
            "Virginia Beach": {
                "lat": 36.7335,
                "lng": -76.0435
            },
            "Charlotte": {
                "lat": 42.5662,
                "lng": -84.8304
            },
            "Milwaukee": {
                "lat": 43.0642,
                "lng": -87.9675
            },
            "Providence": {
                "lat": 41.823,
                "lng": -71.4187
            },
            "Jacksonville": {
                "lat": 31.9642,
                "lng": -95.2617
            },
            "Nashville": {
                "lat": 36.1715,
                "lng": -86.7842
            },
            "Salt Lake City": {
                "lat": 40.7776,
                "lng": -111.9311
            },
            "Raleigh": {
                "lat": 35.8324,
                "lng": -78.6429
            },
            "Richmond": {
                "lat": 29.5824,
                "lng": -95.7563
            },
            "Memphis": {
                "lat": 27.5435,
                "lng": -82.5608
            },
            "Oklahoma City": {
                "lat": 35.4676,
                "lng": -97.5136
            },
            "Hartford": {
                "lat": 43.6644,
                "lng": -72.3865
            },
            "Louisville": {
                "lat": 40.837,
                "lng": -81.2643
            },
            "Buffalo": {
                "lat": 45.1794,
                "lng": -93.8644
            },
            "New Orleans": {
                "lat": 30.0687,
                "lng": -89.9288
            },
            "Fort Worth": {
                "lat": 32.7817,
                "lng": -97.3474
            },
            "Bridgeport": {
                "lat": 39.3036,
                "lng": -80.2478
            },
            "Tucson": {
                "lat": 32.1541,
                "lng": -110.8787
            },
            "El Paso": {
                "lat": 31.8476,
                "lng": -106.43
            },
            "Omaha": {
                "lat": 41.2627,
                "lng": -96.0529
            },
            "McAllen": {
                "lat": 26.2252,
                "lng": -98.2467
            },
            "Hempstead": {
                "lat": 40.6629,
                "lng": -73.6089
            },
            "Birmingham": {
                "lat": 42.5446,
                "lng": -83.2166
            },
            "Albuquerque": {
                "lat": 35.1054,
                "lng": -106.6465
            },
            "Tulsa": {
                "lat": 36.1283,
                "lng": -95.9042
            },
            "Fresno": {
                "lat": 29.5357,
                "lng": -95.4696
            },
            "Charleston": {
                "lat": 39.4842,
                "lng": -88.1781
            },
            "Rochester": {
                "lat": 42.6866,
                "lng": -83.1198
            },
            "Dayton": {
                "lat": 30.0315,
                "lng": -94.9158
            },
            "Cape Coral": {
                "lat": 26.6443,
                "lng": -81.9957
            },
            "Mission Viejo": {
                "lat": 33.6096,
                "lng": -117.6551
            },
            "Colorado Springs": {
                "lat": 38.8674,
                "lng": -104.7605
            },
            "Baton Rouge": {
                "lat": 30.442,
                "lng": -91.1311
            },
            "Allentown": {
                "lat": 40.5961,
                "lng": -75.4756
            },
            "Ogden": {
                "lat": 34.2656,
                "lng": -77.7966
            },
            "Provo": {
                "lat": 40.2457,
                "lng": -111.6457
            },
            "Knoxville": {
                "lat": 35.9692,
                "lng": -83.9496
            },
            "Grand Rapids": {
                "lat": 47.238,
                "lng": -93.5327
            },
            "Columbia": {
                "lat": 40.0347,
                "lng": -76.4944
            },
            "Albany": {
                "lat": 37.8897,
                "lng": -122.3018
            },
            "Bakersfield": {
                "lat": 35.3529,
                "lng": -119.0359
            },
            "New Haven": {
                "lat": 41.0676,
                "lng": -85.0174
            },
            "Des Moines": {
                "lat": 47.3914,
                "lng": -122.3156
            },
            "Akron": {
                "lat": 41.0798,
                "lng": -81.5219
            },
            "Palm Bay": {
                "lat": 27.9631,
                "lng": -80.6593
            },
            "Concord": {
                "lat": 39.8741,
                "lng": -75.5135
            },
            "Wichita": {
                "lat": 37.6895,
                "lng": -97.3443
            },
            "Mesa": {
                "lat": 33.4015,
                "lng": -111.7178
            },
            "Harrisburg": {
                "lat": 35.3125,
                "lng": -80.6485
            },
            "Staten Island": {
                "lat": 40.5834,
                "lng": -74.1496
            },
            "Toledo": {
                "lat": 41.6638,
                "lng": -83.5827
            },
            "Brookhaven": {
                "lat": 31.5803,
                "lng": -90.4432
            },
            "Worcester": {
                "lat": 40.1899,
                "lng": -75.3522
            },
            "Long Beach": {
                "lat": 30.3608,
                "lng": -89.1651
            },
            "Port St. Lucie": {
                "lat": 27.2796,
                "lng": -80.3884
            },
            "Little Rock": {
                "lat": 34.7256,
                "lng": -92.3577
            },
            "Reno": {
                "lat": 39.5497,
                "lng": -119.8483
            },
            "Spokane": {
                "lat": 47.6671,
                "lng": -117.433
            },
            "Madison": {
                "lat": 38.7581,
                "lng": -85.3973
            },
            "Boise": {
                "lat": 43.6005,
                "lng": -116.2308
            },
            "Bonita Springs": {
                "lat": 26.3558,
                "lng": -81.7859
            },
            "Denton": {
                "lat": 33.2175,
                "lng": -97.1418
            },
            "Oakland": {
                "lat": 35.2256,
                "lng": -89.5372
            },
            "Springfield": {
                "lat": 43.2907,
                "lng": -72.4809
            },
            "Augusta": {
                "lat": 37.6955,
                "lng": -96.9919
            },
            "Kissimmee": {
                "lat": 28.3041,
                "lng": -81.4169
            },
            "Winston-Salem": {
                "lat": 36.1029,
                "lng": -80.261
            },
            "Stockton": {
                "lat": 37.9765,
                "lng": -121.3109
            },
            "Syracuse": {
                "lat": 41.0859,
                "lng": -112.0698
            },
            "Chattanooga": {
                "lat": 35.066,
                "lng": -85.2481
            },
            "Lancaster": {
                "lat": 34.7248,
                "lng": -80.7804
            },
            "Greenville": {
                "lat": 41.882,
                "lng": -71.5549
            },
            "Durham": {
                "lat": 43.1174,
                "lng": -70.9194
            },
            "Pensacola": {
                "lat": 30.4413,
                "lng": -87.1911
            },
            "Fayetteville": {
                "lat": 33.4501,
                "lng": -84.471
            },
            "Arlington": {
                "lat": 35.2594,
                "lng": -89.668
            },
            "Aurora": {
                "lat": 42.7382,
                "lng": -78.6373
            },
            "Oxnard": {
                "lat": 34.1964,
                "lng": -119.1815
            },
            "Indio": {
                "lat": 33.7346,
                "lng": -116.2346
            },
            "Scranton": {
                "lat": 41.4044,
                "lng": -75.6649
            },
            "Victorville": {
                "lat": 34.5277,
                "lng": -117.3536
            },
            "Trenton": {
                "lat": 39.4792,
                "lng": -84.462
            },
            "Modesto": {
                "lat": 37.6375,
                "lng": -121.0028
            },
            "Huntsville": {
                "lat": 30.7009,
                "lng": -95.5567
            },
            "Honolulu": {
                "lat": 21.3294,
                "lng": -157.846
            },
            "Greensboro": {
                "lat": 36.0956,
                "lng": -79.8271
            },
            "Anaheim": {
                "lat": 33.839,
                "lng": -117.8574
            },
            "Islip": {
                "lat": 40.7385,
                "lng": -73.1888
            },
            "Corpus Christi": {
                "lat": 27.7254,
                "lng": -97.3767
            },
            "Fort Wayne": {
                "lat": 41.0888,
                "lng": -85.1436
            },
            "Fort Collins": {
                "lat": 40.5477,
                "lng": -105.0656
            },
            "Jackson": {
                "lat": 39.9057,
                "lng": -76.8796
            },
            "Myrtle Beach": {
                "lat": 33.7094,
                "lng": -78.8844
            },
            "Antioch": {
                "lat": 42.4742,
                "lng": -88.0721
            },
            "Lansing": {
                "lat": 39.2428,
                "lng": -94.8972
            },
            "Lexington": {
                "lat": 40.7779,
                "lng": -99.7461
            },
            "Mobile": {
                "lat": 30.6782,
                "lng": -88.1162
            },
            "Youngstown": {
                "lat": 41.0993,
                "lng": -80.6463
            },
            "Henderson": {
                "lat": 32.1576,
                "lng": -94.796
            },
            "Savannah": {
                "lat": 32.0286,
                "lng": -81.1821
            },
            "Poughkeepsie": {
                "lat": 41.695,
                "lng": -73.9211
            },
            "Santa Ana": {
                "lat": 33.7367,
                "lng": -117.8819
            },
            "Ann Arbor": {
                "lat": 42.2759,
                "lng": -83.731
            },
            "St. Paul": {
                "lat": 44.9478,
                "lng": -93.1039
            },
            "Newark": {
                "lat": 43.0418,
                "lng": -77.093
            },
            "Irvine": {
                "lat": 33.6772,
                "lng": -117.7738
            },
            "Oyster Bay": {
                "lat": 40.7846,
                "lng": -73.514
            },
            "Santa Rosa": {
                "lat": 38.4458,
                "lng": -122.7067
            },
            "Canton": {
                "lat": 32.5975,
                "lng": -90.0317
            },
            "Asheville": {
                "lat": 35.5707,
                "lng": -82.5537
            },
            "Flint": {
                "lat": 43.0236,
                "lng": -83.6921
            },
            "Winter Haven": {
                "lat": 28.0118,
                "lng": -81.7014
            },
            "Anchorage": {
                "lat": 61.1508,
                "lng": -149.1091
            },
            "Lincoln": {
                "lat": 40.1508,
                "lng": -89.372
            },
            "Jersey City": {
                "lat": 40.7184,
                "lng": -74.0686
            },
            "Plano": {
                "lat": 41.6757,
                "lng": -88.5293
            },
            "Shreveport": {
                "lat": 32.4653,
                "lng": -93.7955
            },
            "Davenport": {
                "lat": 28.1587,
                "lng": -81.6083
            },
            "Lubbock": {
                "lat": 33.5657,
                "lng": -101.8879
            },
            "Lakeland": {
                "lat": 35.2585,
                "lng": -89.7308
            },
            "South Bend": {
                "lat": 41.6767,
                "lng": -86.2696
            },
            "Chula Vista": {
                "lat": 32.6281,
                "lng": -117.0144
            },
            "Chandler": {
                "lat": 33.2825,
                "lng": -111.8514
            },
            "Rockford": {
                "lat": 42.2596,
                "lng": -89.064
            },
            "Reading": {
                "lat": 39.2242,
                "lng": -84.4333
            },
            "Eugene": {
                "lat": 44.0564,
                "lng": -123.1174
            },
            "Wilmington": {
                "lat": 39.4362,
                "lng": -83.8141
            },
            "Santa Clarita": {
                "lat": 34.4175,
                "lng": -118.4964
            },
            "Gilbert": {
                "lat": 33.31,
                "lng": -111.7463
            },
            "Salem": {
                "lat": 40.0539,
                "lng": -111.6718
            },
            "Killeen": {
                "lat": 31.0753,
                "lng": -97.7297
            },
            "Round Lake Beach": {
                "lat": 42.3791,
                "lng": -88.0811
            },
            "North Las Vegas": {
                "lat": 36.2883,
                "lng": -115.0888
            },
            "Kennewick": {
                "lat": 46.1978,
                "lng": -119.1732
            },
            "St. Petersburg": {
                "lat": 27.7931,
                "lng": -82.6652
            },
            "Tallahassee": {
                "lat": 30.4551,
                "lng": -84.2527
            },
            "Laredo": {
                "lat": 27.5625,
                "lng": -99.4874
            },
            "Irving": {
                "lat": 32.8583,
                "lng": -96.9702
            },
            "Peoria": {
                "lat": 33.7843,
                "lng": -112.2989
            },
            "Montgomery": {
                "lat": 39.2496,
                "lng": -84.3458
            },
            "Chesapeake": {
                "lat": 36.6778,
                "lng": -76.3023
            },
            "Glendale": {
                "lat": 43.1287,
                "lng": -87.9277
            },
            "York": {
                "lat": 34.9967,
                "lng": -81.2341
            },
            "Nashua": {
                "lat": 42.7491,
                "lng": -71.491
            },
            "Garland": {
                "lat": 32.91,
                "lng": -96.6304
            },
            "Scottsdale": {
                "lat": 33.6872,
                "lng": -111.8651
            },
            "Lafayette": {
                "lat": 37.8919,
                "lng": -122.1189
            },
            "Norfolk": {
                "lat": 42.1163,
                "lng": -71.3295
            },
            "North Hempstead": {
                "lat": 40.7912,
                "lng": -73.6688
            },
            "Appleton": {
                "lat": 44.278,
                "lng": -88.3892
            },
            "Rock Hill": {
                "lat": 34.9415,
                "lng": -81.0241
            },
            "Fremont": {
                "lat": 41.3535,
                "lng": -83.1147
            },
            "Fargo": {
                "lat": 46.8651,
                "lng": -96.8292
            },
            "Gulfport": {
                "lat": 27.7463,
                "lng": -82.7099
            },
            "Bremerton": {
                "lat": 47.5436,
                "lng": -122.7121
            },
            "Green Bay": {
                "lat": 44.5148,
                "lng": -87.9895
            },
            "Enterprise": {
                "lat": 31.3275,
                "lng": -85.8463
            },
            "Hialeah": {
                "lat": 25.8696,
                "lng": -80.3045
            },
            "Deltona": {
                "lat": 28.905,
                "lng": -81.2137
            },
            "San Bernardino": {
                "lat": 34.1416,
                "lng": -117.2943
            },
            "Gainesville": {
                "lat": 38.7931,
                "lng": -77.6347
            },
            "Spring Valley": {
                "lat": 32.7317,
                "lng": -116.9766
            },
            "Tacoma": {
                "lat": 47.2431,
                "lng": -122.4531
            },
            "Roanoke": {
                "lat": 33.0144,
                "lng": -97.2276
            },
            "Babylon": {
                "lat": 40.6925,
                "lng": -73.3586
            },
            "Brownsville": {
                "lat": 35.589,
                "lng": -89.2578
            },
            "College Station": {
                "lat": 30.5852,
                "lng": -96.2959
            },
            "Olympia": {
                "lat": 47.0417,
                "lng": -122.8959
            },
            "Clarksville": {
                "lat": 35.457,
                "lng": -93.4803
            },
            "Yonkers": {
                "lat": 40.9466,
                "lng": -73.8673
            },
            "Moreno Valley": {
                "lat": 33.9244,
                "lng": -117.2045
            },
            "Thousand Oaks": {
                "lat": 34.1914,
                "lng": -118.8756
            },
            "Fontana": {
                "lat": 34.0968,
                "lng": -117.4599
            },
            "Hickory": {
                "lat": 35.741,
                "lng": -81.3223
            },
            "Amarillo": {
                "lat": 35.1984,
                "lng": -101.8316
            },
            "Sioux Falls": {
                "lat": 43.5396,
                "lng": -96.7311
            },
            "Huntington": {
                "lat": 38.7916,
                "lng": -77.074
            },
            "Evansville": {
                "lat": 37.9881,
                "lng": -87.5341
            },
            "Waterbury": {
                "lat": 41.5582,
                "lng": -73.0361
            },
            "Frisco": {
                "lat": 33.156,
                "lng": -96.8216
            },
            "Lorain": {
                "lat": 41.4409,
                "lng": -82.184
            },
            "Kalamazoo": {
                "lat": 42.2749,
                "lng": -85.5882
            },
            "Galveston": {
                "lat": 29.2484,
                "lng": -94.8913
            },
            "Spartanburg": {
                "lat": 34.9442,
                "lng": -81.9251
            },
            "Santa Barbara": {
                "lat": 34.4285,
                "lng": -119.7202
            },
            "Sunrise Manor": {
                "lat": 36.1783,
                "lng": -115.0487
            },
            "Huntington Beach": {
                "lat": 33.696,
                "lng": -118.0018
            },
            "Grand Prairie": {
                "lat": 32.6871,
                "lng": -97.0208
            },
            "Overland Park": {
                "lat": 38.887,
                "lng": -94.687
            },
            "McKinney": {
                "lat": 33.2016,
                "lng": -96.667
            },
            "Waco": {
                "lat": 31.5599,
                "lng": -97.1882
            },
            "Hagerstown": {
                "lat": 39.6401,
                "lng": -77.7217
            },
            "Nampa": {
                "lat": 43.5844,
                "lng": -116.5638
            },
            "Cedar Rapids": {
                "lat": 41.9662,
                "lng": -91.6783
            },
            "Vancouver": {
                "lat": 45.6366,
                "lng": -122.5967
            },
            "Paradise": {
                "lat": 36.0872,
                "lng": -115.1355
            },
            "Ocala": {
                "lat": 29.178,
                "lng": -82.1511
            },
            "Erie": {
                "lat": 40.0404,
                "lng": -105.0399
            },
            "Newport News": {
                "lat": 37.1051,
                "lng": -76.5185
            },
            "Frederick": {
                "lat": 40.1088,
                "lng": -104.9701
            },
            "Murfreesboro": {
                "lat": 35.849,
                "lng": -86.4121
            },
            "Fort Lauderdale": {
                "lat": 26.1412,
                "lng": -80.1464
            },
            "Tempe": {
                "lat": 33.3881,
                "lng": -111.9319
            },
            "Danbury": {
                "lat": 41.4015,
                "lng": -73.4709
            },
            "Spring Hill": {
                "lat": 35.7437,
                "lng": -86.9116
            },
            "Gastonia": {
                "lat": 35.2494,
                "lng": -81.1854
            },
            "Salinas": {
                "lat": 36.6883,
                "lng": -121.6317
            },
            "Fredericksburg": {
                "lat": 30.266,
                "lng": -98.8751
            },
            "Ontario": {
                "lat": 43.2408,
                "lng": -77.314
            },
            "Elk Grove": {
                "lat": 38.4161,
                "lng": -121.3842
            },
            "Cary": {
                "lat": 42.2129,
                "lng": -88.2494
            },
            "Rancho Cucamonga": {
                "lat": 34.1247,
                "lng": -117.5667
            },
            "Oceanside": {
                "lat": 40.6328,
                "lng": -73.6364
            },
            "Garden Grove": {
                "lat": 33.7787,
                "lng": -117.9601
            },
            "Hemet": {
                "lat": 33.7341,
                "lng": -116.9969
            },
            "Pembroke Pines": {
                "lat": 26.0128,
                "lng": -80.3382
            },
            "Panama City": {
                "lat": 30.1995,
                "lng": -85.6003
            },
            "Vallejo": {
                "lat": 38.1125,
                "lng": -122.2342
            },
            "Manchester": {
                "lat": 42.9921,
                "lng": -77.1897
            },
            "Medford": {
                "lat": 39.8639,
                "lng": -74.8231
            },
            "Mauldin": {
                "lat": 34.7821,
                "lng": -82.3032
            },
            "Santa Cruz": {
                "lat": 36.9789,
                "lng": -122.0346
            },
            "Palmdale": {
                "lat": 34.5944,
                "lng": -118.1057
            },
            "Norwich": {
                "lat": 41.5495,
                "lng": -72.0882
            },
            "Muskegon": {
                "lat": 43.2281,
                "lng": -86.2562
            },
            "Leesburg": {
                "lat": 39.1057,
                "lng": -77.5544
            },
            "High Point": {
                "lat": 35.991,
                "lng": -79.9937
            },
            "Tuscaloosa": {
                "lat": 33.2348,
                "lng": -87.5268
            },
            "Visalia": {
                "lat": 36.3276,
                "lng": -119.3269
            },
            "Marysville": {
                "lat": 42.9084,
                "lng": -82.4806
            },
            "Merced": {
                "lat": 37.3057,
                "lng": -120.4779
            },
            "Hayward": {
                "lat": 37.6328,
                "lng": -122.0766
            },
            "Corona": {
                "lat": 33.8616,
                "lng": -117.5649
            },
            "Paterson": {
                "lat": 40.9147,
                "lng": -74.1624
            },
            "Alexandria": {
                "lat": 38.9621,
                "lng": -84.3859
            },
            "Macon": {
                "lat": 32.8065,
                "lng": -83.6974
            },
            "Binghamton": {
                "lat": 42.1014,
                "lng": -75.9093
            },
            "Lakewood": {
                "lat": 41.4822,
                "lng": -81.8008
            },
            "Odessa": {
                "lat": 31.8801,
                "lng": -102.3448
            },
            "Sunnyvale": {
                "lat": 37.3836,
                "lng": -122.0255
            },
            "New Bedford": {
                "lat": 41.6697,
                "lng": -70.9428
            },
            "Hollywood": {
                "lat": 26.0293,
                "lng": -80.1679
            },
            "South Lyon": {
                "lat": 42.4614,
                "lng": -83.6526
            },
            "Escondido": {
                "lat": 33.1348,
                "lng": -117.0723
            },
            "Lake Charles": {
                "lat": 30.201,
                "lng": -93.2111
            },
            "Pasadena": {
                "lat": 39.1552,
                "lng": -76.5537
            },
            "Bellevue": {
                "lat": 44.4592,
                "lng": -87.955
            },
            "Joliet": {
                "lat": 41.5188,
                "lng": -88.1499
            },
            "Champaign": {
                "lat": 40.1142,
                "lng": -88.2737
            },
            "Pomona": {
                "lat": 39.4688,
                "lng": -74.5502
            },
            "Fairfield": {
                "lat": 41.0064,
                "lng": -91.9667
            },
            "Mesquite": {
                "lat": 36.8032,
                "lng": -114.133
            },
            "Elkhart": {
                "lat": 41.6916,
                "lng": -85.9627
            },
            "Naperville": {
                "lat": 41.748,
                "lng": -88.1659
            },
            "St. George": {
                "lat": 37.0758,
                "lng": -113.5752
            },
            "Roseville": {
                "lat": 45.0155,
                "lng": -93.1545
            },
            "Ramapo": {
                "lat": 41.1404,
                "lng": -74.1072
            },
            "Topeka": {
                "lat": 39.0346,
                "lng": -95.6955
            },
            "Burlington": {
                "lat": 48.4676,
                "lng": -122.3298
            },
            "Warner Robins": {
                "lat": 32.5961,
                "lng": -83.6527
            },
            "Surprise": {
                "lat": 33.6815,
                "lng": -112.4515
            },
            "Torrance": {
                "lat": 33.8346,
                "lng": -118.3416
            },
            "Santa Maria": {
                "lat": 34.9333,
                "lng": -120.4431
            },
            "Athens": {
                "lat": 32.2041,
                "lng": -95.8321
            },
            "Houma": {
                "lat": 29.58,
                "lng": -90.7059
            },
            "Fullerton": {
                "lat": 40.6309,
                "lng": -75.4834
            },
            "Olathe": {
                "lat": 38.8833,
                "lng": -94.82
            },
            "Thornton": {
                "lat": 39.9197,
                "lng": -104.9438
            },
            "Greeley": {
                "lat": 40.4152,
                "lng": -104.7706
            },
            "Beaumont": {
                "lat": 33.9076,
                "lng": -116.9766
            },
            "Las Cruces": {
                "lat": 32.3265,
                "lng": -106.7893
            },
            "Midland": {
                "lat": 47.1734,
                "lng": -122.412
            },
            "Metairie": {
                "lat": 29.9977,
                "lng": -90.178
            },
            "West Valley City": {
                "lat": 40.6886,
                "lng": -112.0123
            },
            "Orange": {
                "lat": 30.1226,
                "lng": -93.7607
            },
            "Warren": {
                "lat": 41.8434,
                "lng": -79.1444
            },
            "Grand Junction": {
                "lat": 39.0877,
                "lng": -108.5673
            },
            "Tyler": {
                "lat": 32.3184,
                "lng": -95.3062
            },
            "Hampton": {
                "lat": 42.9391,
                "lng": -70.837
            },
            "Bloomington": {
                "lat": 34.0601,
                "lng": -117.4013
            },
            "Elizabeth": {
                "lat": 40.6658,
                "lng": -74.1913
            },
            "Stamford": {
                "lat": 41.1039,
                "lng": -73.5585
            },
            "Yuma": {
                "lat": 32.5995,
                "lng": -114.5491
            },
            "Kent": {
                "lat": 41.4735,
                "lng": -73.7319
            },
            "Miramar": {
                "lat": 25.9773,
                "lng": -80.3351
            },
            "Coeur d'Alene": {
                "lat": 47.704,
                "lng": -116.7936
            },
            "Coral Springs": {
                "lat": 26.2702,
                "lng": -80.2591
            },
            "Sterling Heights": {
                "lat": 42.5809,
                "lng": -83.0305
            },
            "Yuba City": {
                "lat": 39.1357,
                "lng": -121.6383
            },
            "Yakima": {
                "lat": 46.5923,
                "lng": -120.5496
            },
            "Carrollton": {
                "lat": 33.5818,
                "lng": -85.0838
            },
            "Racine": {
                "lat": 42.7274,
                "lng": -87.8135
            },
            "Johnson City": {
                "lat": 42.123,
                "lng": -75.9624
            },
            "Billings": {
                "lat": 45.7891,
                "lng": -108.5526
            },
            "Iowa City": {
                "lat": 41.6559,
                "lng": -91.5303
            },
            "Dover": {
                "lat": 40.5304,
                "lng": -81.4806
            },
            "Bellingham": {
                "lat": 42.0777,
                "lng": -71.4741
            },
            "Amherst": {
                "lat": 42.8706,
                "lng": -71.6068
            },
            "Lynchburg": {
                "lat": 37.4003,
                "lng": -79.1909
            },
            "Santa Clara": {
                "lat": 44.1154,
                "lng": -123.1344
            },
            "Norman": {
                "lat": 35.2335,
                "lng": -97.3471
            },
            "Simi Valley": {
                "lat": 34.2663,
                "lng": -118.749
            },
            "Fort Smith": {
                "lat": 35.3495,
                "lng": -94.3695
            },
            "Abilene": {
                "lat": 32.4543,
                "lng": -99.7384
            },
            "Lewisville": {
                "lat": 36.103,
                "lng": -80.4166
            },
            "Pearland": {
                "lat": 29.5581,
                "lng": -95.3218
            },
            "Lehigh Acres": {
                "lat": 26.612,
                "lng": -81.6388
            },
            "Kenosha": {
                "lat": 42.5865,
                "lng": -87.8773
            },
            "Seaside": {
                "lat": 36.6224,
                "lng": -121.8199
            },
            "Arvada": {
                "lat": 39.832,
                "lng": -105.151
            },
            "Waldorf": {
                "lat": 38.6085,
                "lng": -76.9194
            },
            "Independence": {
                "lat": 37.2119,
                "lng": -95.7327
            },
            "Logan": {
                "lat": 40.5263,
                "lng": -78.4234
            },
            "Harlingen": {
                "lat": 26.1916,
                "lng": -97.6977
            },
            "Berkeley": {
                "lat": 39.9156,
                "lng": -74.1923
            },
            "Clovis": {
                "lat": 34.4376,
                "lng": -103.1923
            },
            "Round Rock": {
                "lat": 30.527,
                "lng": -97.6642
            },
            "Pueblo": {
                "lat": 38.2701,
                "lng": -104.6114
            },
            "Temple": {
                "lat": 31.1068,
                "lng": -97.3891
            },
            "Meridian": {
                "lat": 32.3846,
                "lng": -88.6896
            },
            "Duluth": {
                "lat": 34.0053,
                "lng": -84.1492
            },
            "The Woodlands": {
                "lat": 30.1738,
                "lng": -95.5134
            },
            "Boulder": {
                "lat": 40.0248,
                "lng": -105.2524
            },
            "Richardson": {
                "lat": 32.9716,
                "lng": -96.7093
            },
            "Cambridge": {
                "lat": 45.5612,
                "lng": -93.2283
            },
            "West Palm Beach": {
                "lat": 26.7469,
                "lng": -80.132
            },
            "Port Arthur": {
                "lat": 29.8554,
                "lng": -93.9264
            },
            "East Los Angeles": {
                "lat": 34.0326,
                "lng": -118.1691
            },
            "Redding": {
                "lat": 40.5698,
                "lng": -122.365
            },
            "Clearwater": {
                "lat": 27.979,
                "lng": -82.7623
            },
            "Monroe": {
                "lat": 42.603,
                "lng": -89.6381
            },
            "Utica": {
                "lat": 43.0962,
                "lng": -75.2261
            },
            "St. Cloud": {
                "lat": 28.2363,
                "lng": -81.2779
            },
            "Mandeville": {
                "lat": 30.3751,
                "lng": -90.0904
            },
            "West Jordan": {
                "lat": 40.6024,
                "lng": -112.0008
            },
            "Smithtown": {
                "lat": 40.8663,
                "lng": -73.2164
            },
            "North Charleston": {
                "lat": 32.9067,
                "lng": -80.0722
            },
            "Westminster": {
                "lat": 39.5796,
                "lng": -77.0067
            },
            "Saginaw": {
                "lat": 32.8657,
                "lng": -97.3654
            },
            "Kailua": {
                "lat": 21.392,
                "lng": -157.7396
            },
            "Brandon": {
                "lat": 43.5928,
                "lng": -96.5799
            },
            "Carlsbad": {
                "lat": 32.401,
                "lng": -104.2388
            },
            "Lowell": {
                "lat": 36.2561,
                "lng": -94.1532
            },
            "Broken Arrow": {
                "lat": 36.038,
                "lng": -95.7806
            },
            "Elgin": {
                "lat": 30.3526,
                "lng": -97.3883
            },
            "Gresham": {
                "lat": 45.5021,
                "lng": -122.4413
            },
            "League City": {
                "lat": 29.4874,
                "lng": -95.1087
            },
            "Downey": {
                "lat": 33.9379,
                "lng": -118.1311
            },
            "Waterloo": {
                "lat": 38.3403,
                "lng": -90.1538
            },
            "Murrieta": {
                "lat": 33.5719,
                "lng": -117.1909
            },
            "Leominster": {
                "lat": 42.5209,
                "lng": -71.7717
            },
            "Longview": {
                "lat": 46.1461,
                "lng": -122.963
            },
            "Pompano Beach": {
                "lat": 26.2428,
                "lng": -80.1312
            },
            "Bend": {
                "lat": 44.0563,
                "lng": -121.3095
            },
            "Miami Gardens": {
                "lat": 25.9433,
                "lng": -80.2426
            },
            "Costa Mesa": {
                "lat": 33.6667,
                "lng": -117.9135
            },
            "Sioux City": {
                "lat": 42.4959,
                "lng": -96.3901
            },
            "Everett": {
                "lat": 42.4064,
                "lng": -71.0545
            },
            "Gilroy": {
                "lat": 37.0046,
                "lng": -121.5854
            },
            "San Buenaventura": {
                "lat": 34.2741,
                "lng": -119.2314
            },
            "Temecula": {
                "lat": 33.4928,
                "lng": -117.1315
            },
            "Sugar Land": {
                "lat": 29.5935,
                "lng": -95.6358
            },
            "Chico": {
                "lat": 39.7578,
                "lng": -121.8161
            },
            "Eau Claire": {
                "lat": 44.8197,
                "lng": -91.4948
            },
            "El Monte": {
                "lat": 34.0739,
                "lng": -118.0291
            },
            "Idaho Falls": {
                "lat": 43.4871,
                "lng": -112.0362
            },
            "Dearborn": {
                "lat": 42.3127,
                "lng": -83.213
            },
            "West Covina": {
                "lat": 34.0555,
                "lng": -117.9113
            },
            "Sparks": {
                "lat": 39.5736,
                "lng": -119.7161
            },
            "South Fulton": {
                "lat": 33.6273,
                "lng": -84.58
            },
            "Centennial": {
                "lat": 39.5926,
                "lng": -104.8673
            },
            "Sandy Springs": {
                "lat": 33.9366,
                "lng": -84.3703
            },
            "Edison": {
                "lat": 40.536,
                "lng": -74.3697
            },
            "Inglewood": {
                "lat": 33.9566,
                "lng": -118.3444
            },
            "Hillsboro": {
                "lat": 45.5273,
                "lng": -122.936
            },
            "Burbank": {
                "lat": 41.7444,
                "lng": -87.7686
            },
            "Davie": {
                "lat": 26.0789,
                "lng": -80.287
            },
            "El Cajon": {
                "lat": 32.8017,
                "lng": -116.9604
            },
            "Jurupa Valley": {
                "lat": 34.001,
                "lng": -117.4706
            },
            "Allen": {
                "lat": 33.1088,
                "lng": -96.6735
            },
            "Auburn": {
                "lat": 41.3666,
                "lng": -85.0559
            },
            "Renton": {
                "lat": 47.4784,
                "lng": -122.1918
            },
            "Holland": {
                "lat": 42.7677,
                "lng": -86.0984
            },
            "Brockton": {
                "lat": 42.0821,
                "lng": -71.0242
            },
            "Rio Rancho": {
                "lat": 35.2873,
                "lng": -106.6982
            },
            "San Mateo": {
                "lat": 37.5522,
                "lng": -122.3121
            },
            "Rialto": {
                "lat": 34.1175,
                "lng": -117.3892
            },
            "Spokane Valley": {
                "lat": 47.6626,
                "lng": -117.2346
            },
            "Charlottesville": {
                "lat": 38.0375,
                "lng": -78.4855
            },
            "Menifee": {
                "lat": 33.6909,
                "lng": -117.1849
            },
            "Daly City": {
                "lat": 37.6862,
                "lng": -122.4685
            },
            "Woodbridge": {
                "lat": 38.6409,
                "lng": -77.258
            },
            "Wichita Falls": {
                "lat": 33.9072,
                "lng": -98.5291
            },
            "Riverview": {
                "lat": 42.1723,
                "lng": -83.1946
            },
            "Norwalk": {
                "lat": 41.4895,
                "lng": -93.6913
            },
            "Lee's Summit": {
                "lat": 38.9171,
                "lng": -94.3816
            },
            "Longmont": {
                "lat": 40.1686,
                "lng": -105.1005
            },
            "Vacaville": {
                "lat": 38.3587,
                "lng": -121.9673
            },
            "Highlands Ranch": {
                "lat": 39.5419,
                "lng": -104.9708
            },
            "Kingsport": {
                "lat": 36.5224,
                "lng": -82.5454
            },
            "San Tan Valley": {
                "lat": 33.1786,
                "lng": -111.5629
            },
            "Quincy": {
                "lat": 39.9336,
                "lng": -91.3799
            },
            "Edinburg": {
                "lat": 26.3196,
                "lng": -98.1597
            },
            "Lynn": {
                "lat": 42.4781,
                "lng": -70.9664
            },
            "San Angelo": {
                "lat": 31.4424,
                "lng": -100.4504
            },
            "Hesperia": {
                "lat": 34.3975,
                "lng": -117.3147
            },
            "Bowling Green": {
                "lat": 41.3776,
                "lng": -83.6495
            },
            "Federal Way": {
                "lat": 47.3091,
                "lng": -122.3358
            },
            "Carmel": {
                "lat": 41.3899,
                "lng": -73.7239
            },
            "Bismarck": {
                "lat": 46.8143,
                "lng": -100.7694
            },
            "Fishers": {
                "lat": 39.9588,
                "lng": -85.9659
            },
            "Vista": {
                "lat": 33.1896,
                "lng": -117.2386
            },
            "Boca Raton": {
                "lat": 26.3752,
                "lng": -80.108
            },
            "St. Augustine": {
                "lat": 29.8976,
                "lng": -81.31
            },
            "Beaverton": {
                "lat": 45.4779,
                "lng": -122.8168
            },
            "Goodyear": {
                "lat": 33.2614,
                "lng": -112.3622
            },
            "Portsmouth": {
                "lat": 41.5922,
                "lng": -71.2745
            },
            "Orem": {
                "lat": 40.2981,
                "lng": -111.6994
            },
            "Sunrise": {
                "lat": 26.1547,
                "lng": -80.2997
            },
            "Greece": {
                "lat": 43.246,
                "lng": -77.6988
            },
            "Arden-Arcade": {
                "lat": 38.6017,
                "lng": -121.3845
            },
            "Lawrence": {
                "lat": 40.2954,
                "lng": -74.7205
            },
            "Toms River": {
                "lat": 39.9895,
                "lng": -74.1654
            },
            "Sandy": {
                "lat": 41.1447,
                "lng": -78.7295
            },
            "Slidell": {
                "lat": 30.2887,
                "lng": -89.7828
            },
            "Buckeye": {
                "lat": 33.4314,
                "lng": -112.6429
            },
            "Livonia": {
                "lat": 42.3972,
                "lng": -83.3733
            },
            "Williamsburg": {
                "lat": 37.2693,
                "lng": -76.7076
            },
            "Suffolk": {
                "lat": 36.6953,
                "lng": -76.6398
            },
            "Compton": {
                "lat": 33.893,
                "lng": -118.2274
            },
            "La Crosse": {
                "lat": 43.8241,
                "lng": -91.2268
            },
            "Edmond": {
                "lat": 35.6689,
                "lng": -97.416
            },
            "Carson": {
                "lat": 33.8374,
                "lng": -118.2559
            },
            "San Marcos": {
                "lat": 29.8734,
                "lng": -97.9356
            },
            "Greenburgh": {
                "lat": 41.033,
                "lng": -73.8412
            },
            "Tracy": {
                "lat": 37.7269,
                "lng": -121.4523
            },
            "Prescott Valley": {
                "lat": 34.598,
                "lng": -112.3185
            },
            "Middletown": {
                "lat": 40.201,
                "lng": -76.7289
            },
            "Fall River": {
                "lat": 41.7136,
                "lng": -71.1015
            },
            "Santa Fe": {
                "lat": 29.3889,
                "lng": -95.1003
            },
            "Plantation": {
                "lat": 26.1259,
                "lng": -80.2617
            },
            "New Braunfels": {
                "lat": 29.6994,
                "lng": -98.1148
            },
            "Roswell": {
                "lat": 33.373,
                "lng": -104.5294
            },
            "Conroe": {
                "lat": 30.3238,
                "lng": -95.4824
            },
            "South Gate": {
                "lat": 33.9447,
                "lng": -118.1926
            },
            "Santa Monica": {
                "lat": 34.0235,
                "lng": -118.481
            },
            "Kirkland": {
                "lat": 43.0368,
                "lng": -75.3865
            },
            "Hoover": {
                "lat": 33.3763,
                "lng": -86.8068
            },
            "O'Fallon": {
                "lat": 38.5974,
                "lng": -89.9148
            },
            "Hamilton": {
                "lat": 40.9334,
                "lng": -75.2844
            },
            "Alafaya": {
                "lat": 28.528,
                "lng": -81.1868
            },
            "Palm Coast": {
                "lat": 29.5389,
                "lng": -81.2457
            },
            "Lawton": {
                "lat": 34.6175,
                "lng": -98.4202
            },
            "Chino": {
                "lat": 33.9836,
                "lng": -117.6654
            },
            "Mount Pleasant": {
                "lat": 40.9625,
                "lng": -91.5452
            },
            "Manteca": {
                "lat": 37.7927,
                "lng": -121.2264
            },
            "Florence": {
                "lat": 43.9916,
                "lng": -124.1063
            },
            "Joplin": {
                "lat": 37.0757,
                "lng": -94.5018
            },
            "Germantown": {
                "lat": 43.2343,
                "lng": -88.1217
            },
            "San Leandro": {
                "lat": 37.7074,
                "lng": -122.1599
            },
            "Cheektowaga": {
                "lat": 42.9082,
                "lng": -78.7466
            },
            "Town 'n' Country": {
                "lat": 28.0106,
                "lng": -82.5763
            },
            "Clifton": {
                "lat": 39.0764,
                "lng": -108.4605
            },
            "Waukegan": {
                "lat": 42.3698,
                "lng": -87.8716
            },
            "Avondale": {
                "lat": 33.3873,
                "lng": -112.3235
            },
            "Atascocita": {
                "lat": 29.9777,
                "lng": -95.1953
            },
            "Missoula": {
                "lat": 46.8751,
                "lng": -114.0214
            },
            "Fort Myers": {
                "lat": 26.6194,
                "lng": -81.8302
            },
            "Newton": {
                "lat": 35.663,
                "lng": -81.2335
            },
            "Muncie": {
                "lat": 40.1989,
                "lng": -85.395
            },
            "Rapid City": {
                "lat": 44.0716,
                "lng": -103.2204
            },
            "Troy": {
                "lat": 38.7268,
                "lng": -89.8977
            },
            "Livermore": {
                "lat": 37.6868,
                "lng": -121.7607
            },
            "Citrus Heights": {
                "lat": 38.6948,
                "lng": -121.288
            },
            "Hawthorne": {
                "lat": 40.9579,
                "lng": -74.1582
            },
            "Winchester": {
                "lat": 35.1898,
                "lng": -86.1075
            },
            "Springdale": {
                "lat": 39.2909,
                "lng": -84.476
            },
            "Clarkstown": {
                "lat": 41.1319,
                "lng": -73.966
            },
            "Whittier": {
                "lat": 33.9678,
                "lng": -118.0188
            },
            "Deerfield Beach": {
                "lat": 26.305,
                "lng": -80.1277
            },
            "Decatur": {
                "lat": 40.8286,
                "lng": -84.9277
            },
            "San Ramon": {
                "lat": 37.7624,
                "lng": -121.9365
            },
            "Vineland": {
                "lat": 39.4653,
                "lng": -74.9981
            },
            "Mission": {
                "lat": 39.027,
                "lng": -94.6568
            },
            "Lake Forest": {
                "lat": 42.238,
                "lng": -87.8596
            },
            "Colonie": {
                "lat": 42.7396,
                "lng": -73.7874
            },
            "Upper Darby": {
                "lat": 39.949,
                "lng": -75.2891
            },
            "Newport Beach": {
                "lat": 33.6151,
                "lng": -117.8668
            },
            "Melbourne": {
                "lat": 28.1086,
                "lng": -80.6627
            },
            "Brooklyn Park": {
                "lat": 39.217,
                "lng": -76.6174
            },
            "Bryan": {
                "lat": 41.4706,
                "lng": -84.5483
            },
            "Westland": {
                "lat": 42.3192,
                "lng": -83.3805
            },
            "Napa": {
                "lat": 38.2975,
                "lng": -122.301
            },
            "Baytown": {
                "lat": 29.7587,
                "lng": -94.9671
            },
            "Cicero": {
                "lat": 43.1662,
                "lng": -76.0662
            },
            "Anderson": {
                "lat": 40.4497,
                "lng": -122.295
            },
            "Franklin": {
                "lat": 43.4499,
                "lng": -71.6691
            },
            "Farmington Hills": {
                "lat": 42.486,
                "lng": -83.3771
            },
            "Buena Park": {
                "lat": 33.8572,
                "lng": -118.0046
            },
            "Pine Hills": {
                "lat": 28.5818,
                "lng": -81.4692
            },
            "State College": {
                "lat": 40.7909,
                "lng": -77.8567
            },
            "Redwood City": {
                "lat": 37.5025,
                "lng": -122.2253
            },
            "Warwick": {
                "lat": 40.2503,
                "lng": -75.0818
            },
            "Cranston": {
                "lat": 41.7658,
                "lng": -71.4858
            },
            "Largo": {
                "lat": 38.88,
                "lng": -76.8289
            },
            "Miami Beach": {
                "lat": 25.8171,
                "lng": -80.1396
            },
            "Alhambra": {
                "lat": 34.084,
                "lng": -118.1355
            },
            "Johns Creek": {
                "lat": 34.0333,
                "lng": -84.2027
            },
            "Mountain View": {
                "lat": 37.4001,
                "lng": -122.0796
            },
            "Salisbury": {
                "lat": 42.8465,
                "lng": -70.8616
            },
            "Morgantown": {
                "lat": 39.6383,
                "lng": -79.9464
            },
            "Silver Spring": {
                "lat": 40.2503,
                "lng": -77.0567
            },
            "Layton": {
                "lat": 41.077,
                "lng": -111.962
            },
            "Folsom": {
                "lat": 39.8924,
                "lng": -75.3287
            },
            "Madera": {
                "lat": 36.963,
                "lng": -120.0778
            },
            "New Rochelle": {
                "lat": 40.9304,
                "lng": -73.7836
            },
            "Parma": {
                "lat": 43.2651,
                "lng": -77.7968
            },
            "Terre Haute": {
                "lat": 39.466,
                "lng": -87.3751
            },
            "Somerville": {
                "lat": 40.5696,
                "lng": -74.6092
            },
            "Flagstaff": {
                "lat": 35.1872,
                "lng": -111.6194
            },
            "Boynton Beach": {
                "lat": 26.5281,
                "lng": -80.0811
            },
            "Homestead": {
                "lat": 25.4665,
                "lng": -80.4472
            },
            "Plymouth": {
                "lat": 43.7447,
                "lng": -87.966
            },
            "Anniston": {
                "lat": 33.6712,
                "lng": -85.8135
            },
            "Texarkana": {
                "lat": 33.436,
                "lng": -93.9959
            },
            "Tustin": {
                "lat": 33.7311,
                "lng": -117.8107
            },
            "Pharr": {
                "lat": 26.1685,
                "lng": -98.1904
            },
            "Port Huron": {
                "lat": 42.9821,
                "lng": -82.4388
            },
            "Turlock": {
                "lat": 37.5053,
                "lng": -120.8588
            },
            "Rancho Cordova": {
                "lat": 38.5737,
                "lng": -121.2527
            },
            "The Villages": {
                "lat": 28.9034,
                "lng": -81.9887
            },
            "Milpitas": {
                "lat": 37.4336,
                "lng": -121.8921
            },
            "Perris": {
                "lat": 33.7898,
                "lng": -117.2233
            },
            "Upland": {
                "lat": 34.1178,
                "lng": -117.6604
            },
            "Alton": {
                "lat": 26.2884,
                "lng": -98.3098
            },
            "Pleasanton": {
                "lat": 28.9642,
                "lng": -98.4957
            },
            "Kendall": {
                "lat": 25.6697,
                "lng": -80.3556
            },
            "Jonesboro": {
                "lat": 35.8212,
                "lng": -90.6791
            },
            "Bellflower": {
                "lat": 33.888,
                "lng": -118.1271
            },
            "Battle Creek": {
                "lat": 42.2985,
                "lng": -85.2296
            },
            "Chino Hills": {
                "lat": 33.9508,
                "lng": -117.7254
            },
            "Cheyenne": {
                "lat": 41.135,
                "lng": -104.7902
            },
            "Lebanon": {
                "lat": 43.6353,
                "lng": -72.2531
            },
            "Carmichael": {
                "lat": 38.6318,
                "lng": -121.3263
            },
            "South Jordan": {
                "lat": 40.557,
                "lng": -111.9784
            },
            "Davis": {
                "lat": 38.5553,
                "lng": -121.737
            },
            "Elizabethtown": {
                "lat": 40.1533,
                "lng": -76.599
            },
            "Schaumburg": {
                "lat": 42.0308,
                "lng": -88.0838
            },
            "Alameda": {
                "lat": 37.7668,
                "lng": -122.267
            },
            "Hammond": {
                "lat": 30.5061,
                "lng": -90.4563
            },
            "Pasco": {
                "lat": 46.2506,
                "lng": -119.1305
            },
            "Evanston": {
                "lat": 41.2602,
                "lng": -110.9646
            },
            "Lehi": {
                "lat": 40.4136,
                "lng": -111.8725
            },
            "North Port": {
                "lat": 27.0576,
                "lng": -82.1977
            },
            "Arlington Heights": {
                "lat": 42.0955,
                "lng": -87.9826
            },
            "Camarillo": {
                "lat": 34.223,
                "lng": -119.0321
            },
            "Wyoming": {
                "lat": 39.2297,
                "lng": -84.4816
            },
            "Flower Mound": {
                "lat": 33.0343,
                "lng": -97.1146
            },
            "Bethlehem": {
                "lat": 42.5856,
                "lng": -73.8219
            },
            "Hattiesburg": {
                "lat": 31.3074,
                "lng": -89.317
            },
            "Loveland": {
                "lat": 39.2677,
                "lng": -84.2733
            },
            "Pittsburg": {
                "lat": 37.4129,
                "lng": -94.6985
            },
            "Cedar Park": {
                "lat": 30.5105,
                "lng": -97.8197
            },
            "Wenatchee": {
                "lat": 47.436,
                "lng": -120.3295
            },
            "Southfield": {
                "lat": 42.4765,
                "lng": -83.2605
            },
            "Rochester Hills": {
                "lat": 42.6645,
                "lng": -83.1563
            },
            "Valdosta": {
                "lat": 30.8502,
                "lng": -83.2788
            },
            "Owensboro": {
                "lat": 37.7575,
                "lng": -87.1172
            },
            "Apple Valley": {
                "lat": 44.7457,
                "lng": -93.2004
            },
            "Woodbury": {
                "lat": 40.8176,
                "lng": -73.4703
            },
            "Pawtucket": {
                "lat": 41.8744,
                "lng": -71.3743
            },
            "St. Joseph": {
                "lat": 39.7598,
                "lng": -94.821
            },
            "Cherry Hill": {
                "lat": 38.5696,
                "lng": -77.2895
            },
            "Doral": {
                "lat": 25.8152,
                "lng": -80.3565
            },
            "Missouri City": {
                "lat": 29.563,
                "lng": -95.5365
            },
            "Saratoga Springs": {
                "lat": 40.345,
                "lng": -111.9154
            },
            "Pocatello": {
                "lat": 42.8724,
                "lng": -112.4647
            },
            "Oshkosh": {
                "lat": 44.0227,
                "lng": -88.5619
            },
            "Brick": {
                "lat": 40.06,
                "lng": -74.1099
            },
            "New Britain": {
                "lat": 40.3084,
                "lng": -75.207
            },
            "Castle Rock": {
                "lat": 39.3763,
                "lng": -104.8535
            },
            "Lauderhill": {
                "lat": 26.1605,
                "lng": -80.2241
            },
            "Broomfield": {
                "lat": 39.9542,
                "lng": -105.0526
            },
            "Dale City": {
                "lat": 38.6473,
                "lng": -77.3459
            },
            "Bolingbrook": {
                "lat": 41.6901,
                "lng": -88.1021
            },
            "Redmond": {
                "lat": 44.2612,
                "lng": -121.181
            },
            "Mansfield": {
                "lat": 40.0853,
                "lng": -74.7149
            },
            "Ellicott City": {
                "lat": 39.2774,
                "lng": -76.8345
            },
            "Sheboygan": {
                "lat": 43.7403,
                "lng": -87.7316
            },
            "Glens Falls": {
                "lat": 43.3109,
                "lng": -73.6458
            },
            "Daytona Beach": {
                "lat": 29.1995,
                "lng": -81.0982
            },
            "Lodi": {
                "lat": 40.8784,
                "lng": -74.0814
            },
            "Redlands": {
                "lat": 39.0886,
                "lng": -108.6582
            },
            "Harrisonburg": {
                "lat": 38.4362,
                "lng": -78.8735
            },
            "Dothan": {
                "lat": 31.2336,
                "lng": -85.407
            },
            "Mount Vernon": {
                "lat": 38.714,
                "lng": -77.1043
            },
            "Centreville": {
                "lat": 38.839,
                "lng": -77.4389
            },
            "Altoona": {
                "lat": 44.8029,
                "lng": -91.4385
            },
            "Framingham": {
                "lat": 42.3085,
                "lng": -71.4368
            },
            "Camden": {
                "lat": 33.5672,
                "lng": -92.8467
            },
            "Georgetown": {
                "lat": 31.9849,
                "lng": -81.226
            },
            "Baldwin Park": {
                "lat": 34.0829,
                "lng": -117.9722
            },
            "Rocklin": {
                "lat": 38.8075,
                "lng": -121.2489
            },
            "Porterville": {
                "lat": 36.0643,
                "lng": -119.0338
            },
            "Tamarac": {
                "lat": 26.2056,
                "lng": -80.2539
            },
            "Glen Burnie": {
                "lat": 39.156,
                "lng": -76.6071
            },
            "Blacksburg": {
                "lat": 37.23,
                "lng": -80.4279
            },
            "Wausau": {
                "lat": 44.962,
                "lng": -89.6459
            },
            "Sumter": {
                "lat": 33.9392,
                "lng": -80.393
            },
            "Janesville": {
                "lat": 42.6854,
                "lng": -89.0135
            },
            "Brunswick": {
                "lat": 42.7558,
                "lng": -73.5903
            },
            "Dublin": {
                "lat": 32.536,
                "lng": -82.928
            },
            "Waukesha": {
                "lat": 43.0087,
                "lng": -88.2464
            },
            "Fairbanks": {
                "lat": 64.8353,
                "lng": -147.6533
            },
            "Lakeville": {
                "lat": 41.831,
                "lng": -70.9594
            },
            "St. Charles": {
                "lat": 41.9193,
                "lng": -88.3109
            },
            "Redondo Beach": {
                "lat": 33.8577,
                "lng": -118.3765
            },
            "Bayonne": {
                "lat": 40.6668,
                "lng": -74.1158
            },
            "Grand Forks": {
                "lat": 47.9214,
                "lng": -97.0884
            },
            "Noblesville": {
                "lat": 40.0355,
                "lng": -86.0042
            },
            "El Paso de Robles": {
                "lat": 35.6394,
                "lng": -120.656
            },
            "North Richland Hills": {
                "lat": 32.8604,
                "lng": -97.218
            },
            "Maple Grove": {
                "lat": 45.1089,
                "lng": -93.4626
            },
            "Passaic": {
                "lat": 40.8574,
                "lng": -74.1282
            },
            "Blaine": {
                "lat": 45.1696,
                "lng": -93.2077
            },
            "Lake Elsinore": {
                "lat": 33.6847,
                "lng": -117.3344
            },
            "Rogers": {
                "lat": 45.1865,
                "lng": -93.5783
            },
            "Casas Adobes": {
                "lat": 32.3423,
                "lng": -111.0113
            },
            "Sherman": {
                "lat": 33.6273,
                "lng": -96.6221
            },
            "Walnut Creek": {
                "lat": 37.9024,
                "lng": -122.0399
            },
            "Conway": {
                "lat": 44.0085,
                "lng": -71.0719
            },
            "Eastvale": {
                "lat": 33.9617,
                "lng": -117.5803
            },
            "Union City": {
                "lat": 36.4267,
                "lng": -89.0474
            },
            "Michigan City": {
                "lat": 41.7092,
                "lng": -86.869
            },
            "Poinciana": {
                "lat": 28.1217,
                "lng": -81.4821
            },
            "Tulare": {
                "lat": 36.1995,
                "lng": -119.34
            },
            "Gary": {
                "lat": 41.5905,
                "lng": -87.3473
            },
            "Gaithersburg": {
                "lat": 39.1346,
                "lng": -77.2131
            },
            "East Orange": {
                "lat": 40.7651,
                "lng": -74.2117
            },
            "Wesley Chapel": {
                "lat": 34.9985,
                "lng": -80.6903
            },
            "West Des Moines": {
                "lat": 41.5521,
                "lng": -93.7806
            },
            "Dalton": {
                "lat": 34.769,
                "lng": -84.972
            },
            "Dubuque": {
                "lat": 42.5002,
                "lng": -90.707
            },
            "Schenectady": {
                "lat": 42.8025,
                "lng": -73.9275
            },
            "Southampton": {
                "lat": 40.0249,
                "lng": -77.5459
            },
            "Ankeny": {
                "lat": 41.7288,
                "lng": -93.6031
            },
            "Maricopa": {
                "lat": 33.0408,
                "lng": -112.0011
            },
            "Eagan": {
                "lat": 44.817,
                "lng": -93.1638
            },
            "Hanford": {
                "lat": 36.3274,
                "lng": -119.6549
            },
            "Bristol": {
                "lat": 36.6181,
                "lng": -82.1604
            },
            "Yorba Linda": {
                "lat": 33.889,
                "lng": -117.7714
            },
            "Weston": {
                "lat": 42.3589,
                "lng": -71.3001
            },
            "Watsonville": {
                "lat": 36.9206,
                "lng": -121.7708
            },
            "Palo Alto": {
                "lat": 37.3905,
                "lng": -122.1468
            },
            "South Hill": {
                "lat": 47.1198,
                "lng": -122.2853
            },
            "Shawnee": {
                "lat": 35.3531,
                "lng": -96.9647
            },
            "Great Falls": {
                "lat": 39.011,
                "lng": -77.3013
            },
            "Haverhill": {
                "lat": 42.7838,
                "lng": -71.0871
            },
            "Palatine": {
                "lat": 42.118,
                "lng": -88.043
            },
            "Corvallis": {
                "lat": 44.5698,
                "lng": -123.2779
            },
            "Rockville": {
                "lat": 39.0834,
                "lng": -77.1553
            },
            "Old Bridge": {
                "lat": 40.4004,
                "lng": -74.3126
            },
            "Skokie": {
                "lat": 42.036,
                "lng": -87.74
            },
            "Casper": {
                "lat": 42.842,
                "lng": -106.3208
            },
            "Ames": {
                "lat": 42.0256,
                "lng": -93.6217
            },
            "Delray Beach": {
                "lat": 26.455,
                "lng": -80.0905
            },
            "Sammamish": {
                "lat": 47.6017,
                "lng": -122.0416
            },
            "Lynwood": {
                "lat": 41.5235,
                "lng": -87.551
            },
            "Dundalk": {
                "lat": 39.2704,
                "lng": -76.4942
            },
            "Bethesda": {
                "lat": 38.9866,
                "lng": -77.1188
            },
            "Morristown": {
                "lat": 40.7967,
                "lng": -74.4772
            },
            "Kankakee": {
                "lat": 41.102,
                "lng": -87.8643
            },
            "Alpharetta": {
                "lat": 34.0704,
                "lng": -84.2738
            },
            "Novi": {
                "lat": 42.4786,
                "lng": -83.4893
            },
            "Gloucester": {
                "lat": 42.626,
                "lng": -70.6897
            },
            "Kenner": {
                "lat": 30.0109,
                "lng": -90.2549
            },
            "Bay City": {
                "lat": 28.9838,
                "lng": -95.9601
            },
            "Victoria": {
                "lat": 44.8634,
                "lng": -93.6585
            },
            "South San Francisco": {
                "lat": 37.6538,
                "lng": -122.4196
            },
            "Apex": {
                "lat": 35.7237,
                "lng": -78.8743
            },
            "Parkersburg": {
                "lat": 39.2623,
                "lng": -81.542
            },
            "Malden": {
                "lat": 42.4305,
                "lng": -71.0576
            },
            "Castro Valley": {
                "lat": 37.7088,
                "lng": -122.0626
            },
            "Bozeman": {
                "lat": 45.6833,
                "lng": -111.0558
            },
            "Brentwood": {
                "lat": 40.3734,
                "lng": -79.9757
            },
            "Waltham": {
                "lat": 42.3889,
                "lng": -71.2423
            },
            "Pflugerville": {
                "lat": 30.4515,
                "lng": -97.6018
            },
            "North Little Rock": {
                "lat": 34.7807,
                "lng": -92.2371
            },
            "Laguna Niguel": {
                "lat": 33.5275,
                "lng": -117.705
            },
            "San Clemente": {
                "lat": 33.4499,
                "lng": -117.6102
            },
            "Burnsville": {
                "lat": 44.7648,
                "lng": -93.2793
            },
            "Spring": {
                "lat": 40.3038,
                "lng": -76.0262
            },
            "Port Charlotte": {
                "lat": 26.9918,
                "lng": -82.114
            },
            "Eden Prairie": {
                "lat": 44.8488,
                "lng": -93.4595
            },
            "Greenwood": {
                "lat": 35.2134,
                "lng": -94.2408
            },
            "Lower Merion": {
                "lat": 40.0282,
                "lng": -75.2807
            },
            "Millcreek": {
                "lat": 42.0859,
                "lng": -80.1194
            },
            "Coon Rapids": {
                "lat": 45.1755,
                "lng": -93.3094
            },
            "Rowlett": {
                "lat": 32.9155,
                "lng": -96.5489
            },
            "Florence-Graham": {
                "lat": 33.9682,
                "lng": -118.2447
            },
            "Commerce City": {
                "lat": 39.8641,
                "lng": -104.843
            },
            "Bossier City": {
                "lat": 32.5224,
                "lng": -93.6668
            },
            "Taylor": {
                "lat": 30.5729,
                "lng": -97.4268
            },
            "La Habra": {
                "lat": 33.9282,
                "lng": -117.9515
            },
            "Port Orange": {
                "lat": 29.1085,
                "lng": -81.0137
            },
            "Brookline": {
                "lat": 42.3243,
                "lng": -71.1408
            },
            "Moore": {
                "lat": 40.7798,
                "lng": -75.422
            },
            "Council Bluffs": {
                "lat": 41.2369,
                "lng": -95.8516
            },
            "Bensalem": {
                "lat": 40.1086,
                "lng": -74.9431
            },
            "Leander": {
                "lat": 30.5728,
                "lng": -97.8618
            },
            "Dearborn Heights": {
                "lat": 42.3164,
                "lng": -83.2769
            },
            "Reston": {
                "lat": 38.9497,
                "lng": -77.3461
            },
            "North Bergen": {
                "lat": 40.7938,
                "lng": -74.0242
            },
            "Montebello": {
                "lat": 34.0155,
                "lng": -118.1108
            },
            "Pontiac": {
                "lat": 40.8894,
                "lng": -88.6409
            },
            "Encinitas": {
                "lat": 33.0492,
                "lng": -117.261
            },
            "Queen Creek": {
                "lat": 33.2454,
                "lng": -111.6173
            },
            "Hendersonville": {
                "lat": 35.3247,
                "lng": -82.4575
            },
            "Palm Harbor": {
                "lat": 28.0847,
                "lng": -82.7481
            },
            "Pico Rivera": {
                "lat": 33.9901,
                "lng": -118.0888
            },
            "Marietta": {
                "lat": 39.4241,
                "lng": -81.4465
            },
            "Wellington": {
                "lat": 40.7,
                "lng": -105.0054
            },
            "Sanford": {
                "lat": 43.4244,
                "lng": -70.7573
            },
            "Woodland": {
                "lat": 38.6712,
                "lng": -121.75
            },
            "Caldwell": {
                "lat": 40.8389,
                "lng": -74.2776
            },
            "Huntersville": {
                "lat": 35.406,
                "lng": -80.8716
            },
            "Jupiter": {
                "lat": 26.92,
                "lng": -80.1128
            },
            "San Rafael": {
                "lat": 37.9905,
                "lng": -122.5221
            },
            "La Mesa": {
                "lat": 32.7703,
                "lng": -117.0204
            },
            "Richland": {
                "lat": 40.644,
                "lng": -79.9579
            },
            "Revere": {
                "lat": 42.4189,
                "lng": -71.004
            },
            "Meriden": {
                "lat": 41.5369,
                "lng": -72.7946
            },
            "Piscataway": {
                "lat": 40.5467,
                "lng": -74.4636
            },
            "Monterey Park": {
                "lat": 34.0497,
                "lng": -118.1326
            },
            "Gardena": {
                "lat": 33.8943,
                "lng": -118.3072
            },
            "Euless": {
                "lat": 32.8508,
                "lng": -97.08
            },
            "Irvington": {
                "lat": 40.7243,
                "lng": -74.2317
            },
            "Clay": {
                "lat": 33.6951,
                "lng": -86.609
            },
            "Des Plaines": {
                "lat": 42.0345,
                "lng": -87.9008
            },
            "Hamburg": {
                "lat": 42.7394,
                "lng": -78.8581
            },
            "Union": {
                "lat": 38.4399,
                "lng": -90.9927
            },
            "West Allis": {
                "lat": 43.0068,
                "lng": -88.0296
            },
            "North Miami": {
                "lat": 25.9008,
                "lng": -80.1686
            },
            "The Hammocks": {
                "lat": 25.67,
                "lng": -80.4482
            },
            "Cupertino": {
                "lat": 37.3168,
                "lng": -122.0466
            },
            "Taylorsville": {
                "lat": 40.6569,
                "lng": -111.9493
            },
            "Petaluma": {
                "lat": 38.2423,
                "lng": -122.6266
            },
            "Kokomo": {
                "lat": 40.464,
                "lng": -86.1277
            },
            "Santee": {
                "lat": 32.8554,
                "lng": -116.9851
            },
            "Stonecrest": {
                "lat": 33.6842,
                "lng": -84.1372
            },
            "Taunton": {
                "lat": 41.9036,
                "lng": -71.0943
            },
            "White Plains": {
                "lat": 41.022,
                "lng": -73.7547
            },
            "Palm Beach Gardens": {
                "lat": 26.8466,
                "lng": -80.1679
            },
            "Chapel Hill": {
                "lat": 35.9271,
                "lng": -79.0391
            },
            "Hoboken": {
                "lat": 40.7452,
                "lng": -74.0281
            },
            "Parker": {
                "lat": 34.8513,
                "lng": -82.4512
            },
            "Blue Springs": {
                "lat": 39.0124,
                "lng": -94.2721
            },
            "Shoreline": {
                "lat": 47.7564,
                "lng": -122.3426
            },
            "St. Clair Shores": {
                "lat": 42.4925,
                "lng": -82.8961
            },
            "Horizon West": {
                "lat": 28.4417,
                "lng": -81.6146
            },
            "Margate": {
                "lat": 26.2466,
                "lng": -80.2119
            },
            "Orland Park": {
                "lat": 41.6075,
                "lng": -87.8619
            },
            "Abington": {
                "lat": 42.118,
                "lng": -70.959
            },
            "Carson City": {
                "lat": 39.1511,
                "lng": -119.7476
            },
            "Midwest City": {
                "lat": 35.463,
                "lng": -97.371
            },
            "Royal Oak": {
                "lat": 42.5084,
                "lng": -83.1539
            },
            "Bowie": {
                "lat": 38.9549,
                "lng": -76.7406
            },
            "Kettering": {
                "lat": 38.8888,
                "lng": -76.7889
            },
            "St. Peters": {
                "lat": 38.7825,
                "lng": -90.6061
            },
            "Oak Lawn": {
                "lat": 41.7139,
                "lng": -87.7528
            },
            "Towson": {
                "lat": 39.3944,
                "lng": -76.619
            },
            "Coconut Creek": {
                "lat": 26.2803,
                "lng": -80.1842
            },
            "Lenexa": {
                "lat": 38.9609,
                "lng": -94.8018
            },
            "Bartlett": {
                "lat": 41.9804,
                "lng": -88.2071
            },
            "South Whittier": {
                "lat": 33.9336,
                "lng": -118.0312
            },
            "Weymouth": {
                "lat": 42.1981,
                "lng": -70.9466
            },
            "Lake Havasu City": {
                "lat": 34.5006,
                "lng": -114.3113
            },
            "Bel Air South": {
                "lat": 39.5022,
                "lng": -76.3113
            },
            "Fountainebleau": {
                "lat": 25.7723,
                "lng": -80.346
            },
            "Wylie": {
                "lat": 33.0362,
                "lng": -96.5161
            },
            "Highland": {
                "lat": 38.7602,
                "lng": -89.6812
            },
            "Fountain Valley": {
                "lat": 33.7105,
                "lng": -117.9514
            },
            "Berwyn": {
                "lat": 41.8433,
                "lng": -87.7909
            },
            "Severn": {
                "lat": 39.1355,
                "lng": -76.6956
            },
            "National City": {
                "lat": 32.6654,
                "lng": -117.0983
            },
            "Lacey": {
                "lat": 39.8564,
                "lng": -74.2624
            },
            "Mount Prospect": {
                "lat": 42.0641,
                "lng": -87.9375
            },
            "Arcadia": {
                "lat": 43.087,
                "lng": -77.0858
            },
            "Parsippany-Troy Hills": {
                "lat": 40.8601,
                "lng": -74.4237
            },
            "DeSoto": {
                "lat": 32.5992,
                "lng": -96.8633
            },
            "Smyrna": {
                "lat": 39.2935,
                "lng": -75.6083
            },
            "Bradenton": {
                "lat": 27.4901,
                "lng": -82.5757
            },
            "New Brunswick": {
                "lat": 40.487,
                "lng": -74.445
            },
            "Tinley Park": {
                "lat": 41.567,
                "lng": -87.8049
            },
            "Sarasota": {
                "lat": 27.3387,
                "lng": -82.5432
            },
            "Hacienda Heights": {
                "lat": 33.997,
                "lng": -117.9727
            },
            "Chicopee": {
                "lat": 42.1764,
                "lng": -72.5719
            },
            "West Haven": {
                "lat": 41.2083,
                "lng": -112.0541
            },
            "Herriman": {
                "lat": 40.4899,
                "lng": -112.017
            },
            "Perth Amboy": {
                "lat": 40.5202,
                "lng": -74.2724
            },
            "Casa Grande": {
                "lat": 32.9069,
                "lng": -111.7624
            },
            "Tigard": {
                "lat": 45.4237,
                "lng": -122.7845
            },
            "Apopka": {
                "lat": 28.7015,
                "lng": -81.5316
            },
            "Southaven": {
                "lat": 34.9514,
                "lng": -89.9786
            },
            "Huntington Park": {
                "lat": 33.98,
                "lng": -118.2167
            },
            "Diamond Bar": {
                "lat": 33.9992,
                "lng": -117.8161
            },
            "Bentonville": {
                "lat": 36.3547,
                "lng": -94.2305
            },
            "Yucaipa": {
                "lat": 34.0336,
                "lng": -117.0429
            },
            "Plainfield": {
                "lat": 39.6954,
                "lng": -86.3716
            },
            "Aspen Hill": {
                "lat": 39.0927,
                "lng": -77.0826
            },
            "Rocky Mount": {
                "lat": 35.9685,
                "lng": -77.804
            },
            "Peabody": {
                "lat": 42.5335,
                "lng": -70.9725
            },
            "West Sacramento": {
                "lat": 38.5557,
                "lng": -121.5505
            },
            "Wayne": {
                "lat": 42.2774,
                "lng": -83.3877
            },
            "San Jacinto": {
                "lat": 33.797,
                "lng": -116.9916
            },
            "Kentwood": {
                "lat": 42.8852,
                "lng": -85.5926
            },
            "Colton": {
                "lat": 34.0538,
                "lng": -117.3255
            },
            "Oak Park": {
                "lat": 34.185,
                "lng": -118.7669
            },
            "Westchester": {
                "lat": 41.8492,
                "lng": -87.8906
            },
            "Wheaton": {
                "lat": 39.0492,
                "lng": -77.0573
            },
            "Lower Paxton": {
                "lat": 40.3183,
                "lng": -76.7983
            },
            "Aloha": {
                "lat": 45.492,
                "lng": -122.8725
            },
            "Minnetonka": {
                "lat": 44.9322,
                "lng": -93.4598
            },
            "Howell": {
                "lat": 42.6078,
                "lng": -83.9339
            },
            "Pinellas Park": {
                "lat": 27.8589,
                "lng": -82.7078
            },
            "Kannapolis": {
                "lat": 35.4764,
                "lng": -80.6408
            },
            "Paramount": {
                "lat": 33.8977,
                "lng": -118.1651
            },
            "Texas City": {
                "lat": 29.4154,
                "lng": -94.9682
            },
            "Novato": {
                "lat": 38.092,
                "lng": -122.5575
            },
            "Edina": {
                "lat": 44.8914,
                "lng": -93.3602
            },
            "Normal": {
                "lat": 40.5217,
                "lng": -88.9884
            },
            "Tamiami": {
                "lat": 25.7556,
                "lng": -80.4016
            },
            "Grand Island": {
                "lat": 43.0198,
                "lng": -78.9619
            },
            "Methuen Town": {
                "lat": 42.734,
                "lng": -71.1889
            },
            "Elyria": {
                "lat": 41.376,
                "lng": -82.106
            },
            "Kendale Lakes": {
                "lat": 25.7081,
                "lng": -80.4078
            },
            "Bloomfield": {
                "lat": 40.8098,
                "lng": -74.1869
            },
            "Marana": {
                "lat": 32.4355,
                "lng": -111.1558
            },
            "West New York": {
                "lat": 40.7857,
                "lng": -74.0094
            },
            "Twin Falls": {
                "lat": 42.5645,
                "lng": -114.4613
            },
            "Florissant": {
                "lat": 38.7996,
                "lng": -90.3269
            },
            "Glendora": {
                "lat": 34.1449,
                "lng": -117.8467
            },
            "Cathedral City": {
                "lat": 33.8362,
                "lng": -116.4642
            },
            "Aliso Viejo": {
                "lat": 33.5792,
                "lng": -117.7289
            },
            "Placentia": {
                "lat": 33.8807,
                "lng": -117.8553
            },
            "Hoffman Estates": {
                "lat": 42.064,
                "lng": -88.1468
            },
            "Burien": {
                "lat": 47.4762,
                "lng": -122.3394
            },
            "Dunwoody": {
                "lat": 33.9418,
                "lng": -84.3122
            },
            "Palm Desert": {
                "lat": 33.7378,
                "lng": -116.3695
            },
            "Collierville": {
                "lat": 35.047,
                "lng": -89.6987
            },
            "Levittown": {
                "lat": 40.7241,
                "lng": -73.5125
            },
            "Rosemead": {
                "lat": 34.0689,
                "lng": -118.0823
            },
            "Country Club": {
                "lat": 37.9687,
                "lng": -121.3408
            },
            "Enid": {
                "lat": 36.4063,
                "lng": -97.87
            },
            "Cuyahoga Falls": {
                "lat": 41.1641,
                "lng": -81.5207
            },
            "Mishawaka": {
                "lat": 41.6742,
                "lng": -86.1666
            },
            "Summerville": {
                "lat": 33.0016,
                "lng": -80.1799
            },
            "Irondequoit": {
                "lat": 43.2096,
                "lng": -77.5705
            },
            "Grapevine": {
                "lat": 32.9343,
                "lng": -97.0742
            },
            "Covina": {
                "lat": 34.0903,
                "lng": -117.8818
            },
            "Milford city": {
                "lat": 41.2255,
                "lng": -73.0625
            },
            "Draper": {
                "lat": 40.4957,
                "lng": -111.8605
            },
            "Catalina Foothills": {
                "lat": 32.3046,
                "lng": -110.8838
            },
            "Delano": {
                "lat": 35.7662,
                "lng": -119.2635
            },
            "Haverford": {
                "lat": 39.9868,
                "lng": -75.3164
            },
            "Murray": {
                "lat": 36.6146,
                "lng": -88.3207
            },
            "Mooresville": {
                "lat": 39.6022,
                "lng": -86.3681
            },
            "Downers Grove": {
                "lat": 41.7949,
                "lng": -88.0172
            },
            "Florin": {
                "lat": 38.4832,
                "lng": -121.4043
            },
            "Cypress": {
                "lat": 33.8171,
                "lng": -118.0386
            },
            "Jeffersonville": {
                "lat": 38.3376,
                "lng": -85.7026
            },
            "North Bethesda": {
                "lat": 39.0393,
                "lng": -77.1191
            },
            "Azusa": {
                "lat": 34.1386,
                "lng": -117.9124
            },
            "Coral Gables": {
                "lat": 25.7037,
                "lng": -80.2715
            },
            "Chesterfield": {
                "lat": 40.1166,
                "lng": -74.6459
            },
            "McLean": {
                "lat": 38.9436,
                "lng": -77.1943
            },
            "St. Louis Park": {
                "lat": 44.9488,
                "lng": -93.3649
            },
            "East Honolulu": {
                "lat": 21.2975,
                "lng": -157.7211
            },
            "East Brunswick": {
                "lat": 40.4281,
                "lng": -74.4179
            },
            "Bedford": {
                "lat": 41.3919,
                "lng": -81.536
            },
            "Euclid": {
                "lat": 41.5903,
                "lng": -81.5188
            },
            "Ceres": {
                "lat": 37.5952,
                "lng": -120.962
            },
            "University": {
                "lat": 28.0771,
                "lng": -82.4335
            },
            "Biloxi": {
                "lat": 30.4426,
                "lng": -88.9514
            },
            "El Dorado Hills": {
                "lat": 38.675,
                "lng": -121.049
            },
            "Cerritos": {
                "lat": 33.8678,
                "lng": -118.0686
            },
            "Rye": {
                "lat": 41.0075,
                "lng": -73.6872
            },
            "Burleson": {
                "lat": 32.517,
                "lng": -97.3344
            },
            "Portage": {
                "lat": 43.5489,
                "lng": -89.4658
            },
            "Barnstable": {
                "lat": 41.6655,
                "lng": -70.3639
            },
            "Poway": {
                "lat": 32.9871,
                "lng": -117.0201
            },
            "Cedar Hill": {
                "lat": 32.581,
                "lng": -96.9591
            },
            "Stillwater": {
                "lat": 42.9701,
                "lng": -73.6885
            },
            "Titusville": {
                "lat": 28.5727,
                "lng": -80.8193
            },
            "Orangetown": {
                "lat": 41.0527,
                "lng": -73.9475
            },
            "Niagara Falls": {
                "lat": 43.0921,
                "lng": -79.0147
            },
            "Kyle": {
                "lat": 29.9932,
                "lng": -97.8852
            },
            "West Orange": {
                "lat": 40.7893,
                "lng": -74.2628
            },
            "Westfield": {
                "lat": 40.6516,
                "lng": -74.3432
            },
            "Little Elm": {
                "lat": 33.1858,
                "lng": -96.9295
            },
            "North Highlands": {
                "lat": 38.6713,
                "lng": -121.3721
            },
            "Tuckahoe": {
                "lat": 37.5878,
                "lng": -77.5858
            },
            "Wake Forest": {
                "lat": 35.9632,
                "lng": -78.514
            },
            "Minot": {
                "lat": 48.2375,
                "lng": -101.278
            },
            "Wauwatosa": {
                "lat": 43.0616,
                "lng": -88.0347
            },
            "Bothell": {
                "lat": 47.7735,
                "lng": -122.2044
            },
            "Glenview": {
                "lat": 42.0825,
                "lng": -87.8214
            },
            "Rockwall": {
                "lat": 32.9169,
                "lng": -96.4374
            },
            "Wilson": {
                "lat": 35.7311,
                "lng": -77.9284
            },
            "Rancho Santa Margarita": {
                "lat": 33.6318,
                "lng": -117.5988
            },
            "La Mirada": {
                "lat": 33.9025,
                "lng": -118.0093
            },
            "Antelope": {
                "lat": 38.7153,
                "lng": -121.361
            },
            "Hilo": {
                "lat": 19.6883,
                "lng": -155.0863
            },
            "San Luis Obispo": {
                "lat": 35.2669,
                "lng": -120.669
            },
            "Egg Harbor": {
                "lat": 39.3787,
                "lng": -74.6102
            },
            "Mentor": {
                "lat": 41.6893,
                "lng": -81.3362
            },
            "Ocoee": {
                "lat": 28.5796,
                "lng": -81.5332
            },
            "Perinton": {
                "lat": 43.0781,
                "lng": -77.4283
            },
            "Rowland Heights": {
                "lat": 33.9716,
                "lng": -117.8912
            },
            "Evesham": {
                "lat": 39.8605,
                "lng": -74.8947
            },
            "Fort Pierce": {
                "lat": 27.4255,
                "lng": -80.3431
            },
            "Brea": {
                "lat": 33.9254,
                "lng": -117.8655
            },
            "Oro Valley": {
                "lat": 32.4208,
                "lng": -110.9767
            },
            "East Providence": {
                "lat": 41.8065,
                "lng": -71.3565
            },
            "Pearl City": {
                "lat": 21.4031,
                "lng": -157.9566
            },
            "Salina": {
                "lat": 43.1023,
                "lng": -76.1768
            },
            "South Brunswick": {
                "lat": 40.384,
                "lng": -74.5256
            },
            "Beavercreek": {
                "lat": 39.731,
                "lng": -84.0624
            },
            "Winter Garden": {
                "lat": 28.5421,
                "lng": -81.5967
            },
            "Potomac": {
                "lat": 39.0141,
                "lng": -77.1943
            },
            "Farmington": {
                "lat": 42.4614,
                "lng": -83.3784
            },
            "Henrietta": {
                "lat": 43.0555,
                "lng": -77.6413
            },
            "Attleboro": {
                "lat": 41.9311,
                "lng": -71.295
            },
            "Strongsville": {
                "lat": 41.3128,
                "lng": -81.8313
            },
            "Eagle Mountain": {
                "lat": 40.3137,
                "lng": -112.0114
            },
            "Bridgewater": {
                "lat": 41.9728,
                "lng": -70.9749
            },
            "Prescott": {
                "lat": 34.5849,
                "lng": -112.4473
            },
            "Olive Branch": {
                "lat": 34.961,
                "lng": -89.8469
            },
            "Goose Creek": {
                "lat": 32.9927,
                "lng": -80.0055
            },
            "Sicklerville": {
                "lat": 39.7452,
                "lng": -74.9934
            },
            "Altamonte Springs": {
                "lat": 28.6615,
                "lng": -81.3953
            },
            "Haltom City": {
                "lat": 32.8176,
                "lng": -97.2707
            },
            "Hackensack": {
                "lat": 40.8891,
                "lng": -74.0462
            },
            "Elmhurst": {
                "lat": 41.8973,
                "lng": -87.9432
            },
            "Urbandale": {
                "lat": 41.639,
                "lng": -93.7812
            },
            "Los Banos": {
                "lat": 37.063,
                "lng": -120.8406
            },
            "Littleton": {
                "lat": 42.535,
                "lng": -71.4891
            },
            "Ashburn": {
                "lat": 39.0277,
                "lng": -77.4714
            },
            "East Lansing": {
                "lat": 42.748,
                "lng": -84.4836
            },
            "West Seneca": {
                "lat": 42.8374,
                "lng": -78.7509
            },
            "Bountiful": {
                "lat": 40.8721,
                "lng": -111.8647
            },
            "Keller": {
                "lat": 32.9335,
                "lng": -97.2256
            },
            "Morgan Hill": {
                "lat": 37.1325,
                "lng": -121.6419
            },
            "Webster": {
                "lat": 29.5317,
                "lng": -95.1188
            },
            "Sierra Vista": {
                "lat": 31.563,
                "lng": -110.3153
            },
            "Sayreville": {
                "lat": 40.4656,
                "lng": -74.3237
            },
            "Odenton": {
                "lat": 39.0661,
                "lng": -76.6938
            },
            "Cleveland Heights": {
                "lat": 41.5113,
                "lng": -81.5636
            },
            "Gallatin": {
                "lat": 36.3782,
                "lng": -86.4698
            },
            "Palm Springs": {
                "lat": 26.6348,
                "lng": -80.0969
            },
            "Mount Laurel": {
                "lat": 39.9483,
                "lng": -74.9047
            },
            "Riverton": {
                "lat": 43.0421,
                "lng": -108.4144
            },
            "West Lafayette": {
                "lat": 40.4432,
                "lng": -86.9238
            },
            "Cutler Bay": {
                "lat": 25.5765,
                "lng": -80.3356
            },
            "Lake Ridge": {
                "lat": 38.6847,
                "lng": -77.3066
            },
            "Whitney": {
                "lat": 36.1008,
                "lng": -115.0379
            },
            "Pahrump": {
                "lat": 36.2235,
                "lng": -115.9974
            },
            "North Lauderdale": {
                "lat": 26.2113,
                "lng": -80.2209
            },
            "Wentzville": {
                "lat": 38.8153,
                "lng": -90.8669
            },
            "Fond du Lac": {
                "lat": 43.7718,
                "lng": -88.4397
            },
            "Rohnert Park": {
                "lat": 38.3479,
                "lng": -122.6965
            },
            "Mankato": {
                "lat": 44.1715,
                "lng": -93.9772
            },
            "Moorhead": {
                "lat": 46.8673,
                "lng": -96.7461
            },
            "The Colony": {
                "lat": 33.0925,
                "lng": -96.8976
            },
            "Burke": {
                "lat": 38.7773,
                "lng": -77.2633
            },
            "Freeport": {
                "lat": 43.8556,
                "lng": -70.1009
            },
            "El Centro": {
                "lat": 32.7865,
                "lng": -115.5595
            },
            "Shakopee": {
                "lat": 44.7745,
                "lng": -93.4772
            },
            "Wilkes-Barre": {
                "lat": 41.2469,
                "lng": -75.876
            },
            "Lompoc": {
                "lat": 34.6618,
                "lng": -120.4714
            },
            "Hicksville": {
                "lat": 40.7637,
                "lng": -73.5245
            },
            "Oakland Park": {
                "lat": 26.178,
                "lng": -80.1528
            },
            "Lombard": {
                "lat": 41.8741,
                "lng": -88.0157
            },
            "Pittsfield": {
                "lat": 42.4517,
                "lng": -73.2605
            },
            "North Brunswick": {
                "lat": 40.4505,
                "lng": -74.4798
            },
            "Greenacres": {
                "lat": 26.627,
                "lng": -80.1371
            },
            "Oakley": {
                "lat": 37.9929,
                "lng": -121.6952
            },
            "Linden": {
                "lat": 40.6251,
                "lng": -74.2383
            },
            "Campbell": {
                "lat": 37.2802,
                "lng": -121.9539
            },
            "Danville": {
                "lat": 39.7584,
                "lng": -86.5019
            },
            "North Miami Beach": {
                "lat": 25.9302,
                "lng": -80.1659
            },
            "Clermont": {
                "lat": 28.5325,
                "lng": -81.7208
            },
            "San Bruno": {
                "lat": 37.6256,
                "lng": -122.4313
            },
            "Channelview": {
                "lat": 29.7914,
                "lng": -95.1144
            },
            "Ormond Beach": {
                "lat": 29.2985,
                "lng": -81.1014
            },
            "Huber Heights": {
                "lat": 39.8595,
                "lng": -84.113
            },
            "Woonsocket": {
                "lat": 42.001,
                "lng": -71.4993
            },
            "Hillsborough": {
                "lat": 36.0679,
                "lng": -79.0991
            },
            "Buffalo Grove": {
                "lat": 42.1675,
                "lng": -87.9615
            },
            "West Babylon": {
                "lat": 40.7129,
                "lng": -73.3585
            },
            "Catonsville": {
                "lat": 39.2646,
                "lng": -76.7424
            },
            "Altadena": {
                "lat": 34.1927,
                "lng": -118.1346
            },
            "Edmonds": {
                "lat": 47.8115,
                "lng": -122.3533
            },
            "Linton Hall": {
                "lat": 38.7551,
                "lng": -77.575
            },
            "Spanish Fork": {
                "lat": 40.1101,
                "lng": -111.6405
            },
            "Newnan": {
                "lat": 33.3767,
                "lng": -84.7761
            },
            "Vineyard": {
                "lat": 40.3059,
                "lng": -111.7545
            },
            "Jefferson City": {
                "lat": 38.5676,
                "lng": -92.1759
            },
            "Manassas": {
                "lat": 38.7479,
                "lng": -77.4838
            },
            "Puyallup": {
                "lat": 47.1793,
                "lng": -122.2902
            },
            "Schertz": {
                "lat": 29.565,
                "lng": -98.2535
            },
            "Coppell": {
                "lat": 32.9639,
                "lng": -96.9903
            },
            "North Fort Myers": {
                "lat": 26.7244,
                "lng": -81.8485
            },
            "Moline": {
                "lat": 41.4821,
                "lng": -90.492
            },
            "Beverly": {
                "lat": 42.5681,
                "lng": -70.8627
            },
            "Annandale": {
                "lat": 38.8324,
                "lng": -77.196
            },
            "Coachella": {
                "lat": 33.6905,
                "lng": -116.143
            },
            "Woodlawn": {
                "lat": 38.7332,
                "lng": -77.1149
            },
            "Meadow Woods": {
                "lat": 28.3698,
                "lng": -81.3467
            },
            "Coram": {
                "lat": 40.8812,
                "lng": -73.0059
            },
            "Belleville": {
                "lat": 40.795,
                "lng": -74.1617
            },
            "Peachtree Corners": {
                "lat": 33.967,
                "lng": -84.232
            },
            "Cortlandt": {
                "lat": 41.2552,
                "lng": -73.9018
            },
            "Hollister": {
                "lat": 36.8555,
                "lng": -121.3986
            },
            "Holly Springs": {
                "lat": 34.1681,
                "lng": -84.4847
            },
            "Delaware": {
                "lat": 40.2851,
                "lng": -83.0741
            },
            "Rancho Palos Verdes": {
                "lat": 33.7554,
                "lng": -118.3634
            },
            "Waxahachie": {
                "lat": 32.4035,
                "lng": -96.8446
            },
            "Billerica": {
                "lat": 42.5587,
                "lng": -71.2673
            },
            "Hempfield": {
                "lat": 40.2847,
                "lng": -79.5841
            },
            "Mableton": {
                "lat": 33.8133,
                "lng": -84.5655
            },
            "Fitchburg": {
                "lat": 42.9859,
                "lng": -89.4255
            },
            "Bullhead City": {
                "lat": 35.1205,
                "lng": -114.5461
            },
            "Marlboro": {
                "lat": 40.3427,
                "lng": -74.2567
            },
            "Teaneck": {
                "lat": 40.89,
                "lng": -74.0107
            },
            "Grove City": {
                "lat": 39.8654,
                "lng": -83.069
            },
            "Maplewood": {
                "lat": 40.733,
                "lng": -74.2712
            },
            "Marion": {
                "lat": 35.2035,
                "lng": -90.2059
            },
            "Marlborough": {
                "lat": 41.6337,
                "lng": -73.9904
            },
            "Brookfield": {
                "lat": 41.8245,
                "lng": -87.847
            },
            "French Valley": {
                "lat": 33.5999,
                "lng": -117.1069
            },
            "Shelton": {
                "lat": 47.2186,
                "lng": -123.1121
            },
            "Pine Bluff": {
                "lat": 34.2116,
                "lng": -92.0173
            },
            "Kearny": {
                "lat": 40.7528,
                "lng": -74.1202
            },
            "Hallandale Beach": {
                "lat": 25.9854,
                "lng": -80.1423
            },
            "Milton": {
                "lat": 47.2524,
                "lng": -122.3153
            },
            "Woburn": {
                "lat": 42.4869,
                "lng": -71.1543
            },
            "Reynoldsburg": {
                "lat": 39.9588,
                "lng": -82.7943
            },
            "Covington": {
                "lat": 35.566,
                "lng": -89.6482
            },
            "Friendswood": {
                "lat": 29.511,
                "lng": -95.1981
            },
            "Penn Hills": {
                "lat": 40.4762,
                "lng": -79.8254
            },
            "Weslaco": {
                "lat": 26.1599,
                "lng": -97.9877
            },
            "Land O' Lakes": {
                "lat": 28.2075,
                "lng": -82.4476
            },
            "Essex": {
                "lat": 44.5196,
                "lng": -73.0656
            },
            "Annapolis": {
                "lat": 38.9706,
                "lng": -76.5047
            },
            "DeKalb": {
                "lat": 41.9314,
                "lng": -88.7481
            },
            "Cedar Falls": {
                "lat": 42.5195,
                "lng": -92.4536
            },
            "Manalapan": {
                "lat": 40.28,
                "lng": -74.3436
            },
            "Brighton": {
                "lat": 40.7023,
                "lng": -80.3677
            },
            "Crystal Lake": {
                "lat": 42.2333,
                "lng": -88.3351
            },
            "Lake Oswego": {
                "lat": 45.4129,
                "lng": -122.7004
            },
            "Severna Park": {
                "lat": 39.087,
                "lng": -76.5687
            },
            "Findlay": {
                "lat": 41.0469,
                "lng": -83.6379
            },
            "New Berlin": {
                "lat": 42.9726,
                "lng": -88.1291
            },
            "Culver City": {
                "lat": 34.0058,
                "lng": -118.3968
            },
            "Indian Trail": {
                "lat": 35.0699,
                "lng": -80.6452
            },
            "Duncanville": {
                "lat": 32.646,
                "lng": -96.9127
            },
            "Valley Stream": {
                "lat": 40.6647,
                "lng": -73.7043
            },
            "Clinton": {
                "lat": 38.3716,
                "lng": -93.7679
            },
            "The Acreage": {
                "lat": 26.774,
                "lng": -80.2779
            },
            "Romeoville": {
                "lat": 41.6318,
                "lng": -88.0997
            },
            "Hurst": {
                "lat": 32.8353,
                "lng": -97.1809
            },
            "Post Falls": {
                "lat": 47.7213,
                "lng": -116.9384
            },
            "Hutchinson": {
                "lat": 44.8855,
                "lng": -94.3768
            },
            "Chelsea": {
                "lat": 33.3262,
                "lng": -86.63
            },
            "Waipahu": {
                "lat": 21.3859,
                "lng": -158.0101
            },
            "Lynnwood": {
                "lat": 47.8284,
                "lng": -122.3033
            },
            "Winslow": {
                "lat": 35.0243,
                "lng": -110.7095
            },
            "Northampton": {
                "lat": 40.6866,
                "lng": -75.4904
            },
            "Lincoln Park": {
                "lat": 40.9239,
                "lng": -74.3035
            },
            "Fort Lee": {
                "lat": 40.8509,
                "lng": -73.9713
            },
            "Cape Girardeau": {
                "lat": 37.3109,
                "lng": -89.5597
            },
            "Montclair": {
                "lat": 38.6111,
                "lng": -77.34
            },
            "Hobbs": {
                "lat": 32.7281,
                "lng": -103.16
            },
            "Carol Stream": {
                "lat": 41.9181,
                "lng": -88.1307
            },
            "Plant City": {
                "lat": 28.014,
                "lng": -82.1201
            },
            "Aventura": {
                "lat": 25.9566,
                "lng": -80.1371
            },
            "Streamwood": {
                "lat": 42.0209,
                "lng": -88.1779
            },
            "Oviedo": {
                "lat": 28.658,
                "lng": -81.1872
            },
            "Mount Juliet": {
                "lat": 36.199,
                "lng": -86.5115
            },
            "Ossining": {
                "lat": 41.1559,
                "lng": -73.8564
            },
            "Issaquah": {
                "lat": 47.5439,
                "lng": -122.0471
            },
            "Parkland": {
                "lat": 26.3219,
                "lng": -80.2533
            },
            "Park Ridge": {
                "lat": 41.0352,
                "lng": -74.0423
            },
            "Cottage Grove": {
                "lat": 43.796,
                "lng": -123.0573
            },
            "Bell Gardens": {
                "lat": 33.9663,
                "lng": -118.155
            },
            "San Gabriel": {
                "lat": 34.0949,
                "lng": -118.099
            },
            "Security-Widefield": {
                "lat": 38.7489,
                "lng": -104.7142
            },
            "Grants Pass": {
                "lat": 42.4333,
                "lng": -123.3317
            },
            "Keizer": {
                "lat": 45.0028,
                "lng": -123.0243
            },
            "Penfield": {
                "lat": 43.1602,
                "lng": -77.4483
            },
            "Roy": {
                "lat": 41.1714,
                "lng": -112.0485
            },
            "Bettendorf": {
                "lat": 41.5657,
                "lng": -90.4765
            },
            "Westerville": {
                "lat": 40.1241,
                "lng": -82.921
            },
            "Royal Palm Beach": {
                "lat": 26.7038,
                "lng": -80.2241
            },
            "Haverstraw": {
                "lat": 41.2055,
                "lng": -74.0384
            },
            "Apache Junction": {
                "lat": 33.3985,
                "lng": -111.535
            },
            "Navarre": {
                "lat": 30.4174,
                "lng": -86.8907
            },
            "Wheeling": {
                "lat": 40.0752,
                "lng": -80.6951
            },
            "Lake Stevens": {
                "lat": 48.0024,
                "lng": -122.0953
            },
            "Rexburg": {
                "lat": 43.8226,
                "lng": -111.7919
            },
            "Braintree": {
                "lat": 42.2039,
                "lng": -71.0022
            },
            "Urbana": {
                "lat": 40.1085,
                "lng": -83.7541
            },
            "Shrewsbury": {
                "lat": 42.2842,
                "lng": -71.7154
            },
            "Rosenberg": {
                "lat": 29.5456,
                "lng": -95.8223
            },
            "West Fargo": {
                "lat": 46.8573,
                "lng": -96.9057
            },
            "La Presa": {
                "lat": 32.711,
                "lng": -117.0027
            },
            "Menomonee Falls": {
                "lat": 43.1487,
                "lng": -88.1227
            },
            "Vestavia Hills": {
                "lat": 33.4518,
                "lng": -86.7437
            },
            "Calexico": {
                "lat": 32.6849,
                "lng": -115.4944
            },
            "Valrico": {
                "lat": 27.9193,
                "lng": -82.2293
            },
            "La Vergne": {
                "lat": 36.02,
                "lng": -86.5586
            },
            "Atlantic City": {
                "lat": 39.3797,
                "lng": -74.4527
            },
            "Peachtree City": {
                "lat": 33.3942,
                "lng": -84.571
            },
            "Phenix City": {
                "lat": 32.4585,
                "lng": -85.0253
            },
            "DeLand": {
                "lat": 29.0225,
                "lng": -81.2875
            },
            "Mechanicsville": {
                "lat": 37.6263,
                "lng": -77.3561
            },
            "Stanton": {
                "lat": 33.8003,
                "lng": -117.9935
            },
            "Holyoke": {
                "lat": 42.2125,
                "lng": -72.6411
            },
            "Winter Springs": {
                "lat": 28.6889,
                "lng": -81.2703
            },
            "Owasso": {
                "lat": 36.2878,
                "lng": -95.8311
            },
            "Prattville": {
                "lat": 32.4597,
                "lng": -86.4573
            },
            "East Point": {
                "lat": 33.6696,
                "lng": -84.4701
            },
            "Clifton Park": {
                "lat": 42.8587,
                "lng": -73.8242
            },
            "Pacifica": {
                "lat": 37.6113,
                "lng": -122.4782
            },
            "Hot Springs": {
                "lat": 34.4892,
                "lng": -93.0501
            },
            "Adelanto": {
                "lat": 34.5814,
                "lng": -117.4397
            },
            "Princeton": {
                "lat": 33.1778,
                "lng": -96.5044
            },
            "Northglenn": {
                "lat": 39.9108,
                "lng": -104.9783
            },
            "Tupelo": {
                "lat": 34.2692,
                "lng": -88.7318
            },
            "La Quinta": {
                "lat": 33.6536,
                "lng": -116.2785
            },
            "Elmont": {
                "lat": 40.7033,
                "lng": -73.7078
            },
            "Mission Bend": {
                "lat": 29.6948,
                "lng": -95.6658
            },
            "La Puente": {
                "lat": 34.0323,
                "lng": -117.9533
            },
            "Carpentersville": {
                "lat": 42.1227,
                "lng": -88.2896
            },
            "Cheltenham": {
                "lat": 40.0781,
                "lng": -75.1382
            },
            "Sun City": {
                "lat": 33.6165,
                "lng": -112.2819
            },
            "Hilton Head Island": {
                "lat": 32.1896,
                "lng": -80.7499
            },
            "Galloway": {
                "lat": 39.4914,
                "lng": -74.4803
            },
            "Riviera Beach": {
                "lat": 39.1623,
                "lng": -76.5257
            },
            "Monrovia": {
                "lat": 34.165,
                "lng": -117.9921
            },
            "Foothill Farms": {
                "lat": 38.6867,
                "lng": -121.3475
            },
            "Rome": {
                "lat": 43.226,
                "lng": -75.4909
            },
            "South Valley": {
                "lat": 35.0093,
                "lng": -106.6819
            },
            "New Albany": {
                "lat": 40.0809,
                "lng": -82.7848
            },
            "Lewiston": {
                "lat": 43.1793,
                "lng": -78.971
            },
            "Greenfield": {
                "lat": 36.3242,
                "lng": -121.2428
            },
            "Evans": {
                "lat": 42.6528,
                "lng": -79.0063
            },
            "Pleasant Grove": {
                "lat": 33.494,
                "lng": -86.9782
            },
            "Oregon City": {
                "lat": 45.3418,
                "lng": -122.5924
            },
            "Bartlesville": {
                "lat": 36.7365,
                "lng": -95.9456
            },
            "Rock Island": {
                "lat": 41.4699,
                "lng": -90.5827
            },
            "Hanover Park": {
                "lat": 41.9818,
                "lng": -88.1446
            },
            "Leavenworth": {
                "lat": 39.3239,
                "lng": -94.924
            },
            "Martinez": {
                "lat": 33.5209,
                "lng": -82.0985
            },
            "Greer": {
                "lat": 34.933,
                "lng": -82.2313
            },
            "Tucker": {
                "lat": 33.8436,
                "lng": -84.2024
            },
            "Pennsauken": {
                "lat": 39.9649,
                "lng": -75.0563
            },
            "Richmond West": {
                "lat": 25.6105,
                "lng": -80.4297
            },
            "Muskogee": {
                "lat": 35.7432,
                "lng": -95.3567
            },
            "Guilderland": {
                "lat": 42.708,
                "lng": -73.9631
            },
            "Claremont": {
                "lat": 43.379,
                "lng": -72.3368
            },
            "Kearns": {
                "lat": 40.6519,
                "lng": -112.0095
            },
            "East Meadow": {
                "lat": 40.7197,
                "lng": -73.5604
            },
            "Wildomar": {
                "lat": 33.6173,
                "lng": -117.2582
            },
            "Richfield": {
                "lat": 43.2372,
                "lng": -88.2413
            },
            "Estero": {
                "lat": 26.4276,
                "lng": -81.7951
            },
            "Beloit": {
                "lat": 42.523,
                "lng": -89.0184
            },
            "Natick": {
                "lat": 42.2847,
                "lng": -71.3497
            },
            "Oakton": {
                "lat": 38.8887,
                "lng": -77.3016
            },
            "Central Islip": {
                "lat": 40.7836,
                "lng": -73.1945
            },
            "Upper Arlington": {
                "lat": 40.0272,
                "lng": -83.0704
            },
            "Copperas Cove": {
                "lat": 31.1192,
                "lng": -97.914
            },
            "Andover": {
                "lat": 37.6873,
                "lng": -97.1352
            },
            "Tooele": {
                "lat": 40.5393,
                "lng": -112.3082
            },
            "Oak Creek": {
                "lat": 42.8803,
                "lng": -87.9009
            },
            "Cumberland": {
                "lat": 39.6515,
                "lng": -78.7584
            },
            "Yorktown": {
                "lat": 40.183,
                "lng": -85.5123
            },
            "Randallstown": {
                "lat": 39.3723,
                "lng": -76.8024
            },
            "Merrillville": {
                "lat": 41.4728,
                "lng": -87.3196
            },
            "Chelmsford": {
                "lat": 42.6,
                "lng": -71.3631
            },
            "Temple City": {
                "lat": 34.1022,
                "lng": -118.0579
            },
            "Carrollwood": {
                "lat": 28.0577,
                "lng": -82.5148
            },
            "Ewing": {
                "lat": 40.265,
                "lng": -74.8006
            },
            "Hilliard": {
                "lat": 40.0353,
                "lng": -83.1578
            },
            "Dunedin": {
                "lat": 28.0329,
                "lng": -82.7862
            },
            "Moorpark": {
                "lat": 34.2861,
                "lng": -118.8768
            },
            "Egypt Lake-Leto": {
                "lat": 28.0177,
                "lng": -82.5062
            },
            "Farmers Branch": {
                "lat": 32.9272,
                "lng": -96.8804
            },
            "Lauderdale Lakes": {
                "lat": 26.1682,
                "lng": -80.2017
            },
            "Chillum": {
                "lat": 38.9666,
                "lng": -76.9791
            },
            "Orangevale": {
                "lat": 38.6881,
                "lng": -121.2209
            },
            "Sun Prairie": {
                "lat": 43.1825,
                "lng": -89.2362
            },
            "Cedar City": {
                "lat": 37.6834,
                "lng": -113.0956
            },
            "La Porte": {
                "lat": 41.6069,
                "lng": -86.7142
            },
            "Commack": {
                "lat": 40.8435,
                "lng": -73.2833
            },
            "Riverhead": {
                "lat": 40.9408,
                "lng": -72.7095
            },
            "Norristown": {
                "lat": 40.1225,
                "lng": -75.3398
            },
            "Calumet City": {
                "lat": 41.6134,
                "lng": -87.5505
            },
            "South Miami Heights": {
                "lat": 25.5886,
                "lng": -80.3862
            },
            "Addison": {
                "lat": 32.959,
                "lng": -96.8355
            },
            "Inver Grove Heights": {
                "lat": 44.8247,
                "lng": -93.0596
            },
            "Coventry": {
                "lat": 41.6934,
                "lng": -71.6611
            },
            "El Mirage": {
                "lat": 33.5905,
                "lng": -112.3271
            },
            "Midvale": {
                "lat": 40.6148,
                "lng": -111.8927
            },
            "Kendall West": {
                "lat": 25.7065,
                "lng": -80.4388
            },
            "Lima": {
                "lat": 40.741,
                "lng": -84.112
            },
            "Freehold": {
                "lat": 40.2233,
                "lng": -74.2986
            },
            "Gahanna": {
                "lat": 40.0251,
                "lng": -82.8637
            },
            "Olney": {
                "lat": 38.7285,
                "lng": -88.0839
            },
            "Torrington": {
                "lat": 41.8349,
                "lng": -73.1282
            },
            "Kaneohe": {
                "lat": 21.4062,
                "lng": -157.7904
            },
            "North Ridgeville": {
                "lat": 41.3852,
                "lng": -82.0195
            },
            "San Juan": {
                "lat": 26.1903,
                "lng": -98.152
            },
            "Midlothian": {
                "lat": 41.6254,
                "lng": -87.7243
            },
            "Socorro": {
                "lat": 34.0543,
                "lng": -106.9065
            },
            "Fuquay-Varina": {
                "lat": 35.5953,
                "lng": -78.7778
            },
            "Oakville": {
                "lat": 41.5893,
                "lng": -73.0905
            },
            "West Hollywood": {
                "lat": 34.0883,
                "lng": -118.3719
            },
            "Westmont": {
                "lat": 39.9082,
                "lng": -75.0551
            },
            "Fruit Cove": {
                "lat": 30.0972,
                "lng": -81.6175
            },
            "Benton": {
                "lat": 34.5776,
                "lng": -92.5713
            },
            "Wildwood": {
                "lat": 28.7752,
                "lng": -82.0075
            },
            "Douglasville": {
                "lat": 33.7384,
                "lng": -84.7054
            },
            "Springville": {
                "lat": 40.1638,
                "lng": -111.6205
            },
            "San Luis": {
                "lat": 32.4911,
                "lng": -114.7089
            },
            "Watertown Town": {
                "lat": 42.37,
                "lng": -71.1774
            },
            "Woodstock": {
                "lat": 42.3096,
                "lng": -88.4353
            },
            "Fair Oaks": {
                "lat": 33.9193,
                "lng": -84.5445
            },
            "Manhattan Beach": {
                "lat": 33.8894,
                "lng": -118.3967
            },
            "San Juan Capistrano": {
                "lat": 33.5008,
                "lng": -117.6544
            },
            "Owings Mills": {
                "lat": 39.4115,
                "lng": -76.7913
            },
            "Cookeville": {
                "lat": 36.1482,
                "lng": -85.5109
            },
            "Northbrook": {
                "lat": 39.2467,
                "lng": -84.5796
            },
            "Fair Lawn": {
                "lat": 40.9359,
                "lng": -74.1177
            },
            "University City": {
                "lat": 38.6657,
                "lng": -90.3315
            },
            "Oswego": {
                "lat": 43.4516,
                "lng": -76.5005
            },
            "Oildale": {
                "lat": 35.4293,
                "lng": -119.0306
            },
            "Graham": {
                "lat": 33.1017,
                "lng": -98.5779
            },
            "Mason": {
                "lat": 39.3571,
                "lng": -84.3023
            },
            "Montgomery Village": {
                "lat": 39.1788,
                "lng": -77.1954
            },
            "Hinesville": {
                "lat": 31.8248,
                "lng": -81.6137
            },
            "Golden Glades": {
                "lat": 25.9129,
                "lng": -80.2013
            },
            "Del Rio": {
                "lat": 29.3708,
                "lng": -100.8801
            },
            "Goshen": {
                "lat": 41.3817,
                "lng": -74.3498
            },
            "University Place": {
                "lat": 47.2147,
                "lng": -122.5461
            },
            "Randolph": {
                "lat": 40.8434,
                "lng": -74.5819
            },
            "Butte": {
                "lat": 45.902,
                "lng": -112.6571
            },
            "Falls": {
                "lat": 40.1686,
                "lng": -74.7915
            },
            "Huntington Station": {
                "lat": 40.8446,
                "lng": -73.405
            },
            "Pleasant Hill": {
                "lat": 38.8059,
                "lng": -94.2652
            },
            "Manitowoc": {
                "lat": 44.0991,
                "lng": -87.6812
            },
            "Fairborn": {
                "lat": 39.801,
                "lng": -84.0094
            },
            "San Dimas": {
                "lat": 34.1082,
                "lng": -117.809
            },
            "Stow": {
                "lat": 41.1765,
                "lng": -81.4344
            },
            "McMinnville": {
                "lat": 35.6864,
                "lng": -85.7812
            },
            "College Park": {
                "lat": 33.6363,
                "lng": -84.464
            },
            "Kiryas Joel": {
                "lat": 41.3411,
                "lng": -74.1667
            },
            "Lakewood Ranch": {
                "lat": 27.4185,
                "lng": -82.3948
            },
            "Pueblo West": {
                "lat": 38.3465,
                "lng": -104.7246
            },
            "Merritt Island": {
                "lat": 28.3139,
                "lng": -80.6708
            },
            "Derry": {
                "lat": 40.2709,
                "lng": -76.6561
            },
            "Timberwood Park": {
                "lat": 29.6995,
                "lng": -98.4838
            },
            "Sahuarita": {
                "lat": 31.9323,
                "lng": -110.9654
            },
            "Dakota Ridge": {
                "lat": 39.6192,
                "lng": -105.1344
            },
            "Prairieville": {
                "lat": 30.3151,
                "lng": -90.9571
            },
            "Eastchester": {
                "lat": 40.9536,
                "lng": -73.8133
            },
            "Lufkin": {
                "lat": 31.3217,
                "lng": -94.7277
            },
            "Pikesville": {
                "lat": 39.3893,
                "lng": -76.702
            },
            "Eastpointe": {
                "lat": 42.4657,
                "lng": -82.9461
            },
            "Deer Park": {
                "lat": 40.7623,
                "lng": -73.3218
            },
            "Cooper City": {
                "lat": 26.0463,
                "lng": -80.2862
            },
            "Westlake": {
                "lat": 41.4524,
                "lng": -81.9294
            },
            "Woodridge": {
                "lat": 41.737,
                "lng": -88.0409
            },
            "Spanaway": {
                "lat": 47.0979,
                "lng": -122.4233
            },
            "Valparaiso": {
                "lat": 41.4783,
                "lng": -87.0506
            },
            "American Fork": {
                "lat": 40.3783,
                "lng": -111.7953
            },
            "North Providence": {
                "lat": 41.8616,
                "lng": -71.4575
            },
            "City of Orange": {
                "lat": 40.7681,
                "lng": -74.2348
            },
            "Windsor": {
                "lat": 43.2405,
                "lng": -89.2948
            },
            "Gadsden": {
                "lat": 34.0086,
                "lng": -86.0157
            },
            "Walla Walla": {
                "lat": 46.0671,
                "lng": -118.3368
            },
            "New City": {
                "lat": 41.1542,
                "lng": -73.9912
            },
            "Kearney": {
                "lat": 39.355,
                "lng": -94.3599
            },
            "Leawood": {
                "lat": 38.9075,
                "lng": -94.6258
            },
            "Baldwin": {
                "lat": 40.369,
                "lng": -79.9669
            },
            "Mount Lebanon": {
                "lat": 40.3752,
                "lng": -80.0494
            },
            "Crown Point": {
                "lat": 41.4143,
                "lng": -87.3457
            },
            "Fallbrook": {
                "lat": 33.3693,
                "lng": -117.2259
            },
            "Upper Merion": {
                "lat": 40.0902,
                "lng": -75.3791
            },
            "Englewood": {
                "lat": 39.8643,
                "lng": -84.307
            },
            "East Lake": {
                "lat": 28.1206,
                "lng": -82.6868
            },
            "Goldsboro": {
                "lat": 35.3778,
                "lng": -77.9717
            },
            "Ken Caryl": {
                "lat": 39.577,
                "lng": -105.1144
            },
            "Warminster": {
                "lat": 40.2043,
                "lng": -75.0915
            },
            "Manlius": {
                "lat": 43.049,
                "lng": -75.9792
            },
            "West Little River": {
                "lat": 25.8571,
                "lng": -80.2367
            },
            "Alabaster": {
                "lat": 33.2198,
                "lng": -86.8225
            },
            "Bell": {
                "lat": 33.9801,
                "lng": -118.1798
            },
            "Kennesaw": {
                "lat": 34.026,
                "lng": -84.6177
            },
            "South Riding": {
                "lat": 38.912,
                "lng": -77.5132
            },
            "Menlo Park": {
                "lat": 37.4685,
                "lng": -122.1673
            },
            "Buenaventura Lakes": {
                "lat": 28.3349,
                "lng": -81.3539
            },
            "Bethel Park": {
                "lat": 40.3238,
                "lng": -80.0364
            },
            "Petersburg": {
                "lat": 37.2043,
                "lng": -77.3913
            },
            "Radnor": {
                "lat": 40.0287,
                "lng": -75.3675
            },
            "Cottonwood Heights": {
                "lat": 40.6137,
                "lng": -111.8144
            },
            "Foster City": {
                "lat": 37.5553,
                "lng": -122.2659
            },
            "Ross": {
                "lat": 40.5256,
                "lng": -80.0243
            },
            "Uniondale": {
                "lat": 40.7176,
                "lng": -73.5947
            },
            "Lower Makefield": {
                "lat": 40.2309,
                "lng": -74.855
            },
            "Statesboro": {
                "lat": 32.4375,
                "lng": -81.7751
            },
            "Cranberry": {
                "lat": 40.7104,
                "lng": -80.1059
            },
            "Gillette": {
                "lat": 44.2752,
                "lng": -105.4982
            },
            "Brooklyn Center": {
                "lat": 45.0681,
                "lng": -93.3162
            },
            "Los Gatos": {
                "lat": 37.2304,
                "lng": -121.9562
            },
            "Glendale Heights": {
                "lat": 41.9196,
                "lng": -88.0785
            },
            "Kingman": {
                "lat": 35.217,
                "lng": -114.0105
            },
            "Chester": {
                "lat": 37.3531,
                "lng": -77.4342
            },
            "Harker Heights": {
                "lat": 31.0572,
                "lng": -97.6446
            },
            "Dana Point": {
                "lat": 33.4733,
                "lng": -117.6967
            },
            "Hampden": {
                "lat": 40.2602,
                "lng": -76.9809
            },
            "Clarence": {
                "lat": 43.0196,
                "lng": -78.6375
            },
            "Sherwood": {
                "lat": 45.3594,
                "lng": -122.8427
            },
            "Kaysville": {
                "lat": 41.029,
                "lng": -111.9456
            },
            "Falmouth": {
                "lat": 43.7476,
                "lng": -70.2827
            },
            "Goleta": {
                "lat": 34.4361,
                "lng": -119.8594
            },
            "Cibolo": {
                "lat": 29.5634,
                "lng": -98.2115
            },
            "Helena": {
                "lat": 33.2837,
                "lng": -86.8791
            },
            "Elk Grove Village": {
                "lat": 42.0064,
                "lng": -87.9921
            },
            "Garfield": {
                "lat": 40.8791,
                "lng": -74.1085
            },
            "Ferry Pass": {
                "lat": 30.5205,
                "lng": -87.1899
            },
            "Savage": {
                "lat": 44.7545,
                "lng": -93.3632
            },
            "Lower Macungie": {
                "lat": 40.5303,
                "lng": -75.57
            },
            "Beverly Hills": {
                "lat": 28.9176,
                "lng": -82.4542
            },
            "Desert Hot Springs": {
                "lat": 33.955,
                "lng": -116.5429
            },
            "Clearfield": {
                "lat": 41.103,
                "lng": -112.0238
            },
            "Dartmouth": {
                "lat": 41.6138,
                "lng": -70.9973
            },
            "Dracut": {
                "lat": 42.6832,
                "lng": -71.301
            },
            "Wheat Ridge": {
                "lat": 39.7728,
                "lng": -105.1028
            },
            "North Olmsted": {
                "lat": 41.4149,
                "lng": -81.919
            },
            "Massillon": {
                "lat": 40.7838,
                "lng": -81.5254
            },
            "Weatherford": {
                "lat": 35.538,
                "lng": -98.6853
            },
            "Nacogdoches": {
                "lat": 31.6134,
                "lng": -94.6528
            },
            "Juneau": {
                "lat": 58.4546,
                "lng": -134.1739
            },
            "Pekin": {
                "lat": 40.5678,
                "lng": -89.6251
            },
            "Canyon Lake": {
                "lat": 33.6885,
                "lng": -117.2621
            },
            "Aiken": {
                "lat": 33.5303,
                "lng": -81.7271
            },
            "Marrero": {
                "lat": 29.8871,
                "lng": -90.1126
            },
            "Pullman": {
                "lat": 46.7336,
                "lng": -117.168
            },
            "Franklin Square": {
                "lat": 40.7002,
                "lng": -73.6775
            },
            "Needham": {
                "lat": 42.2814,
                "lng": -71.2411
            },
            "Deptford": {
                "lat": 39.8157,
                "lng": -75.1181
            },
            "Long Branch": {
                "lat": 40.2965,
                "lng": -73.9915
            },
            "South Kingstown": {
                "lat": 41.4458,
                "lng": -71.544
            },
            "San Pablo": {
                "lat": 37.9629,
                "lng": -122.3426
            },
            "Centereach": {
                "lat": 40.8681,
                "lng": -73.0821
            },
            "Maryville": {
                "lat": 40.3428,
                "lng": -94.8702
            },
            "Cleburne": {
                "lat": 32.3568,
                "lng": -97.4151
            },
            "Willingboro": {
                "lat": 40.028,
                "lng": -74.8882
            },
            "Atwater": {
                "lat": 37.3529,
                "lng": -120.5959
            },
            "Newburgh": {
                "lat": 41.5531,
                "lng": -74.0599
            },
            "North Huntingdon": {
                "lat": 40.3294,
                "lng": -79.7336
            },
            "Tredyffrin": {
                "lat": 40.0663,
                "lng": -75.454
            },
            "Laramie": {
                "lat": 41.3099,
                "lng": -105.6085
            },
            "Bangor": {
                "lat": 44.8323,
                "lng": -68.7906
            },
            "Dania Beach": {
                "lat": 26.0593,
                "lng": -80.1637
            },
            "Garner": {
                "lat": 35.6936,
                "lng": -78.6181
            },
            "West Bend": {
                "lat": 43.4173,
                "lng": -88.1818
            },
            "Naugatuck": {
                "lat": 41.489,
                "lng": -73.0518
            },
            "Mundelein": {
                "lat": 42.2693,
                "lng": -88.0102
            },
            "Holladay": {
                "lat": 40.66,
                "lng": -111.8226
            },
            "Lawndale": {
                "lat": 33.8884,
                "lng": -118.3531
            },
            "Lake Magdalene": {
                "lat": 28.0875,
                "lng": -82.4791
            },
            "Oak Ridge": {
                "lat": 41.0323,
                "lng": -74.4971
            },
            "Milford Mill": {
                "lat": 39.3444,
                "lng": -76.7668
            },
            "Prosper": {
                "lat": 33.2394,
                "lng": -96.8087
            },
            "Cornelius": {
                "lat": 45.5188,
                "lng": -123.051
            },
            "New Bern": {
                "lat": 35.0955,
                "lng": -77.0723
            },
            "Opelika": {
                "lat": 32.6612,
                "lng": -85.3769
            },
            "Ithaca": {
                "lat": 42.4442,
                "lng": -76.5032
            },
            "Nicholasville": {
                "lat": 37.8906,
                "lng": -84.5668
            },
            "Norwood": {
                "lat": 39.1605,
                "lng": -84.4535
            },
            "Mamaroneck": {
                "lat": 40.9443,
                "lng": -73.7488
            },
            "Redan": {
                "lat": 33.7394,
                "lng": -84.1644
            },
            "Bel Air North": {
                "lat": 39.5543,
                "lng": -76.3732
            },
            "Port Chester": {
                "lat": 41.0051,
                "lng": -73.668
            },
            "La Verne": {
                "lat": 34.1207,
                "lng": -117.7703
            },
            "LaGrange": {
                "lat": 33.0274,
                "lng": -85.0384
            },
            "Laguna Hills": {
                "lat": 33.5918,
                "lng": -117.6991
            },
            "Middle River": {
                "lat": 39.3436,
                "lng": -76.4253
            },
            "Orcutt": {
                "lat": 34.8691,
                "lng": -120.4222
            },
            "Los Altos": {
                "lat": 37.3684,
                "lng": -122.0965
            },
            "West Falls Church": {
                "lat": 38.8648,
                "lng": -77.1878
            },
            "North Royalton": {
                "lat": 41.3138,
                "lng": -81.745
            },
            "Livingston": {
                "lat": 37.3875,
                "lng": -120.7248
            },
            "Tewksbury": {
                "lat": 42.612,
                "lng": -71.2278
            },
            "Sterling": {
                "lat": 40.6205,
                "lng": -103.1925
            },
            "Hopkinsville": {
                "lat": 36.8381,
                "lng": -87.4775
            },
            "Alamogordo": {
                "lat": 32.8837,
                "lng": -105.9625
            },
            "Burlingame": {
                "lat": 37.5859,
                "lng": -122.3667
            },
            "Bethany": {
                "lat": 35.5071,
                "lng": -97.6418
            },
            "Lakeside": {
                "lat": 37.6132,
                "lng": -77.4768
            },
            "Ballwin": {
                "lat": 38.595,
                "lng": -90.55
            },
            "Ridley": {
                "lat": 39.8854,
                "lng": -75.328
            },
            "SeaTac": {
                "lat": 47.4444,
                "lng": -122.2986
            },
            "West Warwick": {
                "lat": 41.6986,
                "lng": -71.5157
            },
            "West Odessa": {
                "lat": 31.8389,
                "lng": -102.5003
            },
            "North Chicago": {
                "lat": 42.3172,
                "lng": -87.8595
            },
            "Voorhees": {
                "lat": 39.845,
                "lng": -74.9549
            },
            "North Andover": {
                "lat": 42.6713,
                "lng": -71.0865
            },
            "Zionsville": {
                "lat": 39.9897,
                "lng": -86.3182
            },
            "Santa Paula": {
                "lat": 34.3545,
                "lng": -119.0656
            },
            "Southlake": {
                "lat": 32.9545,
                "lng": -97.1503
            },
            "Saratoga": {
                "lat": 37.2684,
                "lng": -122.0263
            },
            "Northport": {
                "lat": 33.2586,
                "lng": -87.5993
            },
            "Niles": {
                "lat": 41.8346,
                "lng": -86.2473
            },
            "Eagle": {
                "lat": 43.7223,
                "lng": -116.3866
            },
            "Gurnee": {
                "lat": 42.3708,
                "lng": -87.9392
            },
            "Miami Lakes": {
                "lat": 25.9125,
                "lng": -80.3214
            },
            "Bay Shore": {
                "lat": 40.7288,
                "lng": -73.2495
            },
            "Wallkill": {
                "lat": 41.4854,
                "lng": -74.3939
            },
            "Parkville": {
                "lat": 39.3832,
                "lng": -76.5519
            },
            "San Carlos": {
                "lat": 37.4982,
                "lng": -122.2677
            },
            "Pittsford": {
                "lat": 43.0733,
                "lng": -77.5268
            },
            "North Tonawanda": {
                "lat": 43.0457,
                "lng": -78.8659
            },
            "Lawrenceville": {
                "lat": 33.9523,
                "lng": -83.9932
            },
            "New Smyrna Beach": {
                "lat": 29.0249,
                "lng": -80.9652
            },
            "Rotterdam": {
                "lat": 42.8133,
                "lng": -74.0129
            },
            "Austintown": {
                "lat": 41.0932,
                "lng": -80.7405
            },
            "Eldersburg": {
                "lat": 39.4041,
                "lng": -76.9528
            },
            "Seguin": {
                "lat": 29.5891,
                "lng": -97.9676
            },
            "Liberty": {
                "lat": 41.8132,
                "lng": -74.7775
            },
            "Bella Vista": {
                "lat": 36.4667,
                "lng": -94.2707
            },
            "Drexel Heights": {
                "lat": 32.1453,
                "lng": -111.048
            },
            "Highland Park": {
                "lat": 32.8311,
                "lng": -96.8012
            },
            "Milford": {
                "lat": 40.4291,
                "lng": -75.4153
            },
            "Granger": {
                "lat": 41.7374,
                "lng": -86.1348
            },
            "Monterey": {
                "lat": 36.5919,
                "lng": -121.8808
            },
            "Winter Park": {
                "lat": 28.5989,
                "lng": -81.3442
            },
            "East Niles": {
                "lat": 35.3683,
                "lng": -118.9225
            },
            "Chamblee": {
                "lat": 33.8842,
                "lng": -84.3007
            },
            "Vero Beach South": {
                "lat": 27.6132,
                "lng": -80.4163
            },
            "Southgate": {
                "lat": 42.2047,
                "lng": -83.2057
            },
            "Banning": {
                "lat": 33.946,
                "lng": -116.8992
            },
            "Galesburg": {
                "lat": 40.9506,
                "lng": -90.3763
            },
            "Vestal": {
                "lat": 42.0492,
                "lng": -76.026
            },
            "Algonquin": {
                "lat": 42.1629,
                "lng": -88.3159
            },
            "Wellesley": {
                "lat": 42.3043,
                "lng": -71.2855
            },
            "Nutley": {
                "lat": 40.8192,
                "lng": -74.1571
            },
            "Fleming Island": {
                "lat": 30.0988,
                "lng": -81.7124
            },
            "East Windsor": {
                "lat": 40.2606,
                "lng": -74.5295
            },
            "Raytown": {
                "lat": 38.9944,
                "lng": -94.4641
            },
            "San Lorenzo": {
                "lat": 37.6733,
                "lng": -122.1332
            },
            "Atascadero": {
                "lat": 35.4827,
                "lng": -120.6858
            },
            "Morrisville": {
                "lat": 40.2074,
                "lng": -74.78
            },
            "Fridley": {
                "lat": 45.0841,
                "lng": -93.2595
            },
            "East Fishkill": {
                "lat": 41.5567,
                "lng": -73.7824
            },
            "Summerlin South": {
                "lat": 36.1242,
                "lng": -115.3324
            },
            "Orchard Park": {
                "lat": 42.7516,
                "lng": -78.7455
            },
            "Paragould": {
                "lat": 36.0555,
                "lng": -90.5149
            },
            "Hazleton": {
                "lat": 40.9505,
                "lng": -75.9725
            },
            "Rahway": {
                "lat": 40.6077,
                "lng": -74.2807
            },
            "Lathrop": {
                "lat": 37.809,
                "lng": -121.3131
            },
            "Matthews": {
                "lat": 35.1195,
                "lng": -80.7101
            },
            "Laurel": {
                "lat": 27.1507,
                "lng": -82.4534
            },
            "Schererville": {
                "lat": 41.4861,
                "lng": -87.4442
            },
            "Hobart": {
                "lat": 44.4967,
                "lng": -88.1602
            },
            "Burton": {
                "lat": 42.9974,
                "lng": -83.6175
            },
            "Eastern Goleta Valley": {
                "lat": 34.4448,
                "lng": -119.7879
            },
            "Johnston": {
                "lat": 41.691,
                "lng": -93.7234
            },
            "East Palo Alto": {
                "lat": 37.4671,
                "lng": -122.1352
            },
            "Central": {
                "lat": 30.5593,
                "lng": -91.0369
            },
            "Hutto": {
                "lat": 30.5396,
                "lng": -97.544
            },
            "Fountain": {
                "lat": 38.6886,
                "lng": -104.6829
            },
            "Garfield Heights": {
                "lat": 41.4199,
                "lng": -81.6038
            },
            "Melrose": {
                "lat": 42.4556,
                "lng": -71.059
            },
            "West Windsor": {
                "lat": 40.2897,
                "lng": -74.6266
            },
            "McCandless": {
                "lat": 40.5836,
                "lng": -80.0283
            },
            "Golden Gate": {
                "lat": 26.1844,
                "lng": -81.7031
            },
            "Brownsburg": {
                "lat": 39.8337,
                "lng": -86.383
            },
            "Bluffton": {
                "lat": 40.7424,
                "lng": -85.173
            },
            "Crofton": {
                "lat": 39.0144,
                "lng": -76.68
            },
            "Carney": {
                "lat": 39.405,
                "lng": -76.5235
            },
            "Suisun City": {
                "lat": 38.2473,
                "lng": -122.0091
            },
            "Glenville": {
                "lat": 42.8869,
                "lng": -73.9925
            },
            "Kirkwood": {
                "lat": 38.5788,
                "lng": -90.4203
            },
            "Sun City Center": {
                "lat": 27.715,
                "lng": -82.3569
            },
            "Magna": {
                "lat": 40.7634,
                "lng": -112.1599
            },
            "Oakleaf Plantation": {
                "lat": 30.1689,
                "lng": -81.8337
            },
            "Gates": {
                "lat": 43.1514,
                "lng": -77.713
            },
            "Perry Hall": {
                "lat": 39.4068,
                "lng": -76.478
            },
            "East Lake-Orient Park": {
                "lat": 27.997,
                "lng": -82.3653
            },
            "Drexel Hill": {
                "lat": 39.9495,
                "lng": -75.3038
            },
            "Orchards": {
                "lat": 45.689,
                "lng": -122.5306
            },
            "Shaker Heights": {
                "lat": 41.4744,
                "lng": -81.5496
            },
            "Queensbury": {
                "lat": 43.3568,
                "lng": -73.6765
            },
            "McDonough": {
                "lat": 33.4397,
                "lng": -84.1505
            },
            "Chili": {
                "lat": 43.0845,
                "lng": -77.7541
            },
            "Casselberry": {
                "lat": 28.6624,
                "lng": -81.3217
            },
            "Mehlville": {
                "lat": 38.5018,
                "lng": -90.3149
            },
            "Whitehall": {
                "lat": 39.9682,
                "lng": -82.8833
            },
            "Stoughton": {
                "lat": 42.9237,
                "lng": -89.2225
            },
            "Lake in the Hills": {
                "lat": 42.1913,
                "lng": -88.3477
            },
            "Laplace": {
                "lat": 30.0731,
                "lng": -90.4758
            },
            "Russellville": {
                "lat": 34.5055,
                "lng": -87.7283
            },
            "Haines City": {
                "lat": 28.1102,
                "lng": -81.6157
            },
            "Stafford": {
                "lat": 29.6271,
                "lng": -95.5654
            },
            "Easton": {
                "lat": 38.776,
                "lng": -76.0701
            },
            "Ruskin": {
                "lat": 27.7065,
                "lng": -82.4209
            },
            "Mount Olive": {
                "lat": 40.8662,
                "lng": -74.7426
            },
            "Stockbridge": {
                "lat": 33.5254,
                "lng": -84.2295
            },
            "Jeffersontown": {
                "lat": 38.2049,
                "lng": -85.5701
            },
            "West Springfield": {
                "lat": 38.7771,
                "lng": -77.2268
            },
            "Bixby": {
                "lat": 35.9454,
                "lng": -95.8776
            },
            "Harrison": {
                "lat": 40.6374,
                "lng": -79.7173
            },
            "Jamestown": {
                "lat": 46.9063,
                "lng": -98.6937
            },
            "Agawam": {
                "lat": 42.0657,
                "lng": -72.6526
            },
            "Statesville": {
                "lat": 35.7842,
                "lng": -80.8713
            },
            "Saugus": {
                "lat": 42.4681,
                "lng": -71.0145
            },
            "Madison Heights": {
                "lat": 37.4487,
                "lng": -79.1057
            },
            "Monroeville": {
                "lat": 40.4262,
                "lng": -79.7605
            },
            "Frankfort": {
                "lat": 40.281,
                "lng": -86.5212
            },
            "South Laurel": {
                "lat": 39.0603,
                "lng": -76.8456
            },
            "Forest Hills": {
                "lat": 42.9577,
                "lng": -85.4895
            },
            "Short Pump": {
                "lat": 37.6549,
                "lng": -77.6201
            },
            "Allen Park": {
                "lat": 42.2595,
                "lng": -83.2107
            },
            "Aberdeen": {
                "lat": 35.135,
                "lng": -79.4326
            },
            "New Iberia": {
                "lat": 30.0049,
                "lng": -91.8202
            },
            "Clarksburg": {
                "lat": 39.2862,
                "lng": -80.3232
            },
            "Glen Ellyn": {
                "lat": 41.8666,
                "lng": -88.0629
            },
            "Monsey": {
                "lat": 41.1181,
                "lng": -74.0682
            },
            "Temescal Valley": {
                "lat": 33.7581,
                "lng": -117.4677
            },
            "Bergenfield": {
                "lat": 40.9236,
                "lng": -73.9982
            },
            "Walnut": {
                "lat": 34.0334,
                "lng": -117.8593
            },
            "Wappinger": {
                "lat": 41.5899,
                "lng": -73.8918
            },
            "Eagle Pass": {
                "lat": 28.7125,
                "lng": -100.484
            },
            "Glen Cove": {
                "lat": 40.8709,
                "lng": -73.6287
            },
            "Ridgecrest": {
                "lat": 35.6308,
                "lng": -117.6622
            },
            "Neptune": {
                "lat": 40.2105,
                "lng": -74.0539
            },
            "Maryland Heights": {
                "lat": 38.7189,
                "lng": -90.4749
            },
            "East Hampton": {
                "lat": 41.0117,
                "lng": -72.1277
            },
            "Converse": {
                "lat": 29.5091,
                "lng": -98.3084
            },
            "Oakdale": {
                "lat": 37.7618,
                "lng": -120.8468
            },
            "Twentynine Palms": {
                "lat": 34.1478,
                "lng": -116.0659
            },
            "Shaler": {
                "lat": 40.5229,
                "lng": -79.9632
            },
            "Lake Jackson": {
                "lat": 29.0516,
                "lng": -95.4521
            },
            "Garden City": {
                "lat": 32.0867,
                "lng": -81.1773
            },
            "Danvers": {
                "lat": 42.574,
                "lng": -70.9494
            },
            "Maple Valley": {
                "lat": 47.3659,
                "lng": -122.0368
            },
            "Daphne": {
                "lat": 30.6263,
                "lng": -87.8816
            },
            "Hamtramck": {
                "lat": 42.3954,
                "lng": -83.0559
            },
            "Sachse": {
                "lat": 32.9726,
                "lng": -96.5793
            },
            "Belmont": {
                "lat": 39.0622,
                "lng": -77.4985
            },
            "Rockledge": {
                "lat": 28.3203,
                "lng": -80.736
            },
            "Tualatin": {
                "lat": 45.3772,
                "lng": -122.7748
            },
            "Wilmette": {
                "lat": 42.0771,
                "lng": -87.7282
            },
            "Williston": {
                "lat": 44.4345,
                "lng": -73.0888
            },
            "Ramsey": {
                "lat": 41.0595,
                "lng": -74.1454
            },
            "Bernards": {
                "lat": 40.6761,
                "lng": -74.5677
            },
            "Chaska": {
                "lat": 44.8164,
                "lng": -93.6091
            },
            "Williamsport": {
                "lat": 41.2399,
                "lng": -77.037
            },
            "Immokalee": {
                "lat": 26.4253,
                "lng": -81.4251
            },
            "Ocean": {
                "lat": 40.252,
                "lng": -74.0392
            },
            "Dodge City": {
                "lat": 37.7611,
                "lng": -100.0182
            },
            "North Kingstown": {
                "lat": 41.5687,
                "lng": -71.4629
            },
            "New Windsor": {
                "lat": 41.4742,
                "lng": -74.1089
            },
            "Huntley": {
                "lat": 42.1599,
                "lng": -88.433
            },
            "Tysons": {
                "lat": 38.9215,
                "lng": -77.2273
            },
            "Reisterstown": {
                "lat": 39.455,
                "lng": -76.814
            },
            "Prior Lake": {
                "lat": 44.7246,
                "lng": -93.4419
            },
            "New London": {
                "lat": 41.3502,
                "lng": -72.1022
            },
            "Swatara": {
                "lat": 40.2463,
                "lng": -76.8031
            },
            "Homewood": {
                "lat": 41.5591,
                "lng": -87.661
            },
            "Fortuna Foothills": {
                "lat": 32.6616,
                "lng": -114.3974
            },
            "Millville": {
                "lat": 39.3903,
                "lng": -75.0561
            },
            "Marshalltown": {
                "lat": 42.0343,
                "lng": -92.9068
            },
            "McHenry": {
                "lat": 42.3387,
                "lng": -88.2932
            },
            "Lemon Grove": {
                "lat": 32.7331,
                "lng": -117.0344
            },
            "Crestview": {
                "lat": 30.7477,
                "lng": -86.5785
            },
            "Mililani Town": {
                "lat": 21.4465,
                "lng": -158.0147
            },
            "Chicago Heights": {
                "lat": 41.51,
                "lng": -87.6345
            },
            "Green": {
                "lat": 40.9483,
                "lng": -81.4757
            },
            "Alvin": {
                "lat": 29.3872,
                "lng": -95.2938
            },
            "Balch Springs": {
                "lat": 32.7194,
                "lng": -96.6151
            },
            "Neenah": {
                "lat": 44.1669,
                "lng": -88.4767
            },
            "Ilchester": {
                "lat": 39.2187,
                "lng": -76.7683
            },
            "Mason City": {
                "lat": 43.1487,
                "lng": -93.1997
            },
            "Citrus Park": {
                "lat": 28.073,
                "lng": -82.5628
            },
            "Pearl": {
                "lat": 32.273,
                "lng": -90.0918
            },
            "New Lenox": {
                "lat": 41.5095,
                "lng": -87.9703
            },
            "Kahului": {
                "lat": 20.8715,
                "lng": -156.4603
            },
            "Holbrook": {
                "lat": 42.1471,
                "lng": -71.0057
            },
            "Oak Forest": {
                "lat": 41.6054,
                "lng": -87.7527
            },
            "Ewa Gentry": {
                "lat": 21.3344,
                "lng": -158.0262
            },
            "West Islip": {
                "lat": 40.7041,
                "lng": -73.2954
            },
            "Merrimack": {
                "lat": 42.8547,
                "lng": -71.5188
            },
            "Kernersville": {
                "lat": 36.1065,
                "lng": -80.0842
            },
            "West Linn": {
                "lat": 45.367,
                "lng": -122.6399
            },
            "Lindenhurst": {
                "lat": 42.4175,
                "lng": -88.0257
            },
            "Thomasville": {
                "lat": 30.8394,
                "lng": -83.9782
            },
            "Asheboro": {
                "lat": 35.7158,
                "lng": -79.8127
            },
            "Lochearn": {
                "lat": 39.3461,
                "lng": -76.7307
            },
            "Wakefield": {
                "lat": 38.823,
                "lng": -77.2406
            },
            "Benicia": {
                "lat": 38.0725,
                "lng": -122.1526
            },
            "Springettsbury": {
                "lat": 39.9907,
                "lng": -76.6736
            },
            "Leisure City": {
                "lat": 25.4935,
                "lng": -80.4363
            },
            "Plum": {
                "lat": 40.5024,
                "lng": -79.7495
            },
            "Granite City": {
                "lat": 38.7296,
                "lng": -90.1268
            },
            "Wooster": {
                "lat": 40.8172,
                "lng": -81.9336
            },
            "Gladstone": {
                "lat": 45.3864,
                "lng": -122.5934
            },
            "Lemoore": {
                "lat": 36.2949,
                "lng": -119.7983
            },
            "Moon": {
                "lat": 40.5081,
                "lng": -80.2074
            },
            "Pemberton": {
                "lat": 39.9562,
                "lng": -74.6
            },
            "Bridgeton": {
                "lat": 38.7673,
                "lng": -90.4275
            },
            "Paducah": {
                "lat": 37.0711,
                "lng": -88.644
            },
            "Shoreview": {
                "lat": 45.0842,
                "lng": -93.1358
            },
            "Clayton": {
                "lat": 39.6627,
                "lng": -75.0782
            },
            "Temple Terrace": {
                "lat": 28.0436,
                "lng": -82.3773
            },
            "Ives Estates": {
                "lat": 25.9632,
                "lng": -80.183
            },
            "West Melbourne": {
                "lat": 28.0694,
                "lng": -80.6736
            },
            "Vernon Hills": {
                "lat": 42.234,
                "lng": -87.9608
            },
            "Upper Dublin": {
                "lat": 40.1502,
                "lng": -75.1813
            },
            "South Portland": {
                "lat": 43.631,
                "lng": -70.2895
            },
            "Horn Lake": {
                "lat": 34.9512,
                "lng": -90.0501
            },
            "Sun City West": {
                "lat": 33.6695,
                "lng": -112.3573
            },
            "Edwardsville": {
                "lat": 38.7922,
                "lng": -89.9874
            },
            "Plainview": {
                "lat": 34.1911,
                "lng": -101.7235
            },
            "South Pasadena": {
                "lat": 34.1103,
                "lng": -118.1573
            },
            "Paramus": {
                "lat": 40.9455,
                "lng": -74.0712
            },
            "Susquehanna": {
                "lat": 40.3111,
                "lng": -76.8699
            },
            "Superior": {
                "lat": 39.934,
                "lng": -105.1588
            },
            "Sanger": {
                "lat": 33.3715,
                "lng": -97.1678
            },
            "Horsham": {
                "lat": 40.1993,
                "lng": -75.1665
            },
            "Cabot": {
                "lat": 34.9766,
                "lng": -92.0274
            },
            "Mint Hill": {
                "lat": 35.1783,
                "lng": -80.6528
            },
            "Eureka": {
                "lat": 38.5004,
                "lng": -90.6491
            },
            "Brawley": {
                "lat": 32.9783,
                "lng": -115.5287
            },
            "Shirley": {
                "lat": 40.7936,
                "lng": -72.8748
            },
            "Elmira": {
                "lat": 42.0938,
                "lng": -76.8097
            },
            "Upper Macungie": {
                "lat": 40.5694,
                "lng": -75.6244
            },
            "Wall": {
                "lat": 40.1674,
                "lng": -74.0974
            },
            "Apollo Beach": {
                "lat": 27.7618,
                "lng": -82.4003
            },
            "Owatonna": {
                "lat": 44.0912,
                "lng": -93.2312
            },
            "Camas": {
                "lat": 45.6005,
                "lng": -122.4304
            },
            "East Chicago": {
                "lat": 41.6469,
                "lng": -87.4545
            },
            "Batavia": {
                "lat": 42.9987,
                "lng": -78.1802
            },
            "Wasco": {
                "lat": 35.5938,
                "lng": -119.3671
            },
            "Walpole": {
                "lat": 42.1464,
                "lng": -71.2555
            },
            "South Salt Lake": {
                "lat": 40.7057,
                "lng": -111.8986
            },
            "East Hempfield": {
                "lat": 40.0825,
                "lng": -76.3831
            },
            "Wright": {
                "lat": 30.4445,
                "lng": -86.6419
            },
            "Pooler": {
                "lat": 32.1043,
                "lng": -81.2569
            },
            "Woodburn": {
                "lat": 38.8503,
                "lng": -77.2322
            },
            "Forest Grove": {
                "lat": 45.5243,
                "lng": -123.1097
            },
            "Imperial Beach": {
                "lat": 32.5693,
                "lng": -117.118
            },
            "Lockport": {
                "lat": 43.1698,
                "lng": -78.6956
            },
            "Terrytown": {
                "lat": 29.9014,
                "lng": -90.0279
            },
            "Medina": {
                "lat": 41.1358,
                "lng": -81.8695
            },
            "Winona": {
                "lat": 44.0505,
                "lng": -91.6684
            },
            "Northdale": {
                "lat": 28.1058,
                "lng": -82.5263
            },
            "Key West": {
                "lat": 24.5642,
                "lng": -81.7775
            },
            "Keystone": {
                "lat": 28.1312,
                "lng": -82.5999
            },
            "Ridgewood": {
                "lat": 40.9821,
                "lng": -74.1127
            },
            "Hercules": {
                "lat": 38.0064,
                "lng": -122.2564
            },
            "Fort Mill": {
                "lat": 35.0061,
                "lng": -80.9389
            },
            "Cave Spring": {
                "lat": 37.2254,
                "lng": -80.0072
            },
            "De Witt": {
                "lat": 43.03,
                "lng": -76.0819
            },
            "Rosemount": {
                "lat": 44.7466,
                "lng": -93.0661
            },
            "Wilsonville": {
                "lat": 45.3109,
                "lng": -122.7702
            },
            "Upper Moreland": {
                "lat": 40.1572,
                "lng": -75.1021
            },
            "Fairland": {
                "lat": 39.0803,
                "lng": -76.9527
            },
            "Londonderry": {
                "lat": 42.8796,
                "lng": -71.3873
            },
            "Palm City": {
                "lat": 27.1735,
                "lng": -80.2862
            },
            "Big Spring": {
                "lat": 32.2389,
                "lng": -101.4799
            },
            "Columbine": {
                "lat": 39.5879,
                "lng": -105.0694
            },
            "Elk River": {
                "lat": 45.3314,
                "lng": -93.567
            },
            "Grandview": {
                "lat": 46.2443,
                "lng": -119.9092
            },
            "Bessemer": {
                "lat": 33.3712,
                "lng": -86.9728
            },
            "Colleyville": {
                "lat": 32.8913,
                "lng": -97.1486
            },
            "Jenks": {
                "lat": 35.9981,
                "lng": -95.9736
            },
            "El Cerrito": {
                "lat": 37.9196,
                "lng": -122.3025
            },
            "Chanhassen": {
                "lat": 44.8546,
                "lng": -93.5621
            },
            "Suitland": {
                "lat": 38.8492,
                "lng": -76.9225
            },
            "Inkster": {
                "lat": 42.2935,
                "lng": -83.3148
            },
            "Fort Washington": {
                "lat": 38.7339,
                "lng": -77.0069
            },
            "Le Ray": {
                "lat": 44.0771,
                "lng": -75.7975
            },
            "Rockville Centre": {
                "lat": 40.6643,
                "lng": -73.6383
            },
            "Marshfield": {
                "lat": 44.6627,
                "lng": -90.1728
            },
            "Halfmoon": {
                "lat": 42.864,
                "lng": -73.7275
            },
            "Palm River-Clair Mel": {
                "lat": 27.9239,
                "lng": -82.3791
            },
            "Oxford": {
                "lat": 36.3155,
                "lng": -78.5848
            },
            "Lower Providence": {
                "lat": 40.1485,
                "lng": -75.4266
            },
            "Venice": {
                "lat": 27.1184,
                "lng": -82.4137
            },
            "Pace": {
                "lat": 30.6188,
                "lng": -87.1667
            },
            "West Chicago": {
                "lat": 41.896,
                "lng": -88.2253
            },
            "Trussville": {
                "lat": 33.6405,
                "lng": -86.5815
            },
            "Tumwater": {
                "lat": 46.9891,
                "lng": -122.9175
            },
            "Moscow": {
                "lat": 46.7308,
                "lng": -116.9986
            },
            "Warrington": {
                "lat": 30.3835,
                "lng": -87.2946
            },
            "Staunton": {
                "lat": 38.1593,
                "lng": -79.0611
            },
            "Derby": {
                "lat": 39.84,
                "lng": -104.9172
            },
            "Stevens Point": {
                "lat": 44.5241,
                "lng": -89.5507
            },
            "Okemos": {
                "lat": 42.7057,
                "lng": -84.4135
            },
            "Cliffside Park": {
                "lat": 40.8222,
                "lng": -73.9879
            },
            "Rockaway": {
                "lat": 40.9602,
                "lng": -74.4988
            },
            "Xenia": {
                "lat": 39.6829,
                "lng": -83.9413
            },
            "Fort Hood": {
                "lat": 31.1357,
                "lng": -97.7834
            },
            "Kalispell": {
                "lat": 48.2153,
                "lng": -114.3274
            },
            "South Bradenton": {
                "lat": 27.4612,
                "lng": -82.5822
            },
            "Mahwah": {
                "lat": 41.0816,
                "lng": -74.1856
            },
            "Mercer Island": {
                "lat": 47.5661,
                "lng": -122.232
            },
            "Exeter": {
                "lat": 36.2941,
                "lng": -119.1459
            },
            "Silver Springs Shores": {
                "lat": 29.1126,
                "lng": -82.0149
            },
            "West Whittier-Los Nietos": {
                "lat": 33.9759,
                "lng": -118.0689
            },
            "Peekskill": {
                "lat": 41.2884,
                "lng": -73.9227
            },
            "Belvidere": {
                "lat": 42.2543,
                "lng": -88.8649
            },
            "Hudson": {
                "lat": 28.3595,
                "lng": -82.6894
            },
            "Newberg": {
                "lat": 45.3075,
                "lng": -122.9601
            },
            "Holt": {
                "lat": 42.6416,
                "lng": -84.5308
            },
            "De Pere": {
                "lat": 44.4308,
                "lng": -88.0785
            },
            "Ottumwa": {
                "lat": 41.0195,
                "lng": -92.4186
            },
            "Tarpon Springs": {
                "lat": 28.1493,
                "lng": -82.7623
            },
            "Galt": {
                "lat": 38.2698,
                "lng": -121.3004
            },
            "Hazelwood": {
                "lat": 38.7931,
                "lng": -90.3899
            },
            "Norco": {
                "lat": 33.9252,
                "lng": -117.5498
            },
            "Mill Creek East": {
                "lat": 47.8361,
                "lng": -122.1877
            },
            "Caledonia": {
                "lat": 42.7986,
                "lng": -87.8762
            },
            "Forney": {
                "lat": 32.744,
                "lng": -96.4529
            },
            "Camillus": {
                "lat": 43.0539,
                "lng": -76.3056
            },
            "Sebastian": {
                "lat": 27.7822,
                "lng": -80.4819
            },
            "Kingsville": {
                "lat": 27.5094,
                "lng": -97.861
            },
            "Elkridge": {
                "lat": 39.1941,
                "lng": -76.7428
            },
            "Reedley": {
                "lat": 36.5988,
                "lng": -119.4471
            },
            "Barstow": {
                "lat": 34.8661,
                "lng": -117.0471
            },
            "Avon Lake": {
                "lat": 41.4944,
                "lng": -82.0159
            },
            "Allendale": {
                "lat": 42.9845,
                "lng": -85.9499
            },
            "University Park": {
                "lat": 32.8506,
                "lng": -96.7937
            },
            "Fish Hawk": {
                "lat": 27.8511,
                "lng": -82.2164
            },
            "Melrose Park": {
                "lat": 41.903,
                "lng": -87.8636
            },
            "Walker": {
                "lat": 42.9853,
                "lng": -85.7446
            },
            "Barberton": {
                "lat": 41.0095,
                "lng": -81.6037
            },
            "Carteret": {
                "lat": 40.5849,
                "lng": -74.2284
            },
            "Moses Lake": {
                "lat": 47.1279,
                "lng": -119.2759
            },
            "Dedham": {
                "lat": 42.2467,
                "lng": -71.1777
            },
            "North Tustin": {
                "lat": 33.7636,
                "lng": -117.7947
            },
            "Edgewood": {
                "lat": 47.2309,
                "lng": -122.2832
            },
            "Dickinson": {
                "lat": 29.4548,
                "lng": -95.0589
            },
            "Corsicana": {
                "lat": 32.0824,
                "lng": -96.4665
            },
            "Mequon": {
                "lat": 43.2352,
                "lng": -87.9839
            },
            "Hastings": {
                "lat": 43.3215,
                "lng": -76.1582
            },
            "Newport": {
                "lat": 44.6242,
                "lng": -124.0513
            },
            "Muskego": {
                "lat": 42.886,
                "lng": -88.1291
            },
            "Romulus": {
                "lat": 42.2237,
                "lng": -83.366
            },
            "Seal Beach": {
                "lat": 33.7542,
                "lng": -118.0714
            },
            "Waukee": {
                "lat": 41.5985,
                "lng": -93.8869
            },
            "Yarmouth": {
                "lat": 43.7978,
                "lng": -70.1719
            },
            "Maywood": {
                "lat": 40.9025,
                "lng": -74.0634
            },
            "Norton Shores": {
                "lat": 43.1621,
                "lng": -86.2519
            },
            "Sandusky": {
                "lat": 41.4468,
                "lng": -82.7024
            },
            "Sugar Hill": {
                "lat": 34.108,
                "lng": -84.0558
            },
            "Morton Grove": {
                "lat": 42.0423,
                "lng": -87.789
            },
            "Westchase": {
                "lat": 28.0597,
                "lng": -82.611
            },
            "Loma Linda": {
                "lat": 34.045,
                "lng": -117.2498
            },
            "Denison": {
                "lat": 33.7672,
                "lng": -96.5808
            },
            "Watertown": {
                "lat": 44.9094,
                "lng": -97.1532
            },
            "Wyandotte": {
                "lat": 42.2113,
                "lng": -83.1558
            },
            "Perrysburg": {
                "lat": 41.5377,
                "lng": -83.6413
            },
            "Fort Dodge": {
                "lat": 42.5098,
                "lng": -94.1751
            },
            "Kuna": {
                "lat": 43.488,
                "lng": -116.3933
            },
            "Arnold": {
                "lat": 38.4297,
                "lng": -90.3733
            },
            "Carolina Forest": {
                "lat": 33.7651,
                "lng": -78.913
            },
            "Avon": {
                "lat": 39.7601,
                "lng": -86.3916
            },
            "Riverbank": {
                "lat": 37.726,
                "lng": -120.9433
            },
            "West Milford": {
                "lat": 41.106,
                "lng": -74.3914
            },
            "Bailey's Crossroads": {
                "lat": 38.8477,
                "lng": -77.1305
            },
            "Ardmore": {
                "lat": 40.0033,
                "lng": -75.2947
            },
            "Soledad": {
                "lat": 36.4432,
                "lng": -121.3426
            },
            "Zanesville": {
                "lat": 39.9565,
                "lng": -82.0132
            },
            "San Benito": {
                "lat": 26.1298,
                "lng": -97.644
            },
            "Frederickson": {
                "lat": 47.0916,
                "lng": -122.3603
            },
            "Scotch Plains": {
                "lat": 40.6332,
                "lng": -74.3739
            },
            "Cloverleaf": {
                "lat": 29.7882,
                "lng": -95.1724
            },
            "Lutz": {
                "lat": 28.1396,
                "lng": -82.4467
            },
            "Dinuba": {
                "lat": 36.5453,
                "lng": -119.3987
            },
            "North Potomac": {
                "lat": 39.0955,
                "lng": -77.2373
            },
            "Bainbridge Island": {
                "lat": 47.6439,
                "lng": -122.5434
            },
            "Dix Hills": {
                "lat": 40.8033,
                "lng": -73.3375
            },
            "Greenbelt": {
                "lat": 38.9953,
                "lng": -76.8885
            },
            "Bayonet Point": {
                "lat": 28.3254,
                "lng": -82.6834
            },
            "Ridgeland": {
                "lat": 32.4236,
                "lng": -90.1481
            },
            "Coral Terrace": {
                "lat": 25.7464,
                "lng": -80.3049
            },
            "Auburn Hills": {
                "lat": 42.6735,
                "lng": -83.2448
            },
            "Selma": {
                "lat": 29.5866,
                "lng": -98.3132
            },
            "Paris": {
                "lat": 38.2016,
                "lng": -84.2718
            },
            "Zion": {
                "lat": 42.4603,
                "lng": -87.8511
            },
            "Westford": {
                "lat": 42.5864,
                "lng": -71.4401
            },
            "Centerville": {
                "lat": 40.9284,
                "lng": -111.8848
            },
            "Homer Glen": {
                "lat": 41.6043,
                "lng": -87.9497
            },
            "Leland": {
                "lat": 34.2042,
                "lng": -78.0279
            },
            "Socastee": {
                "lat": 33.6871,
                "lng": -79.0086
            },
            "Oak Harbor": {
                "lat": 48.2964,
                "lng": -122.6333
            },
            "Herndon": {
                "lat": 38.9699,
                "lng": -77.3867
            },
            "Ponca City": {
                "lat": 36.7235,
                "lng": -97.0677
            },
            "Landover": {
                "lat": 38.9241,
                "lng": -76.8875
            },
            "North Augusta": {
                "lat": 33.5214,
                "lng": -81.9547
            },
            "King of Prussia": {
                "lat": 40.0963,
                "lng": -75.3821
            },
            "Faribault": {
                "lat": 44.2996,
                "lng": -93.2789
            },
            "North Laurel": {
                "lat": 39.1285,
                "lng": -76.8476
            },
            "Bay Point": {
                "lat": 38.0329,
                "lng": -121.9615
            },
            "Happy Valley": {
                "lat": 45.4358,
                "lng": -122.5081
            },
            "Port St. John": {
                "lat": 28.4757,
                "lng": -80.8104
            },
            "Benbrook": {
                "lat": 32.6788,
                "lng": -97.4637
            },
            "West Memphis": {
                "lat": 35.153,
                "lng": -90.1996
            },
            "Kerrville": {
                "lat": 30.0398,
                "lng": -99.132
            },
            "Ballenger Creek": {
                "lat": 39.3807,
                "lng": -77.4206
            },
            "Ladera Ranch": {
                "lat": 33.5492,
                "lng": -117.6417
            },
            "White Bear Lake": {
                "lat": 45.0656,
                "lng": -93.015
            },
            "Barnegat": {
                "lat": 39.7668,
                "lng": -74.2776
            },
            "Collinsville": {
                "lat": 38.677,
                "lng": -90.0063
            },
            "Elmwood Park": {
                "lat": 40.9049,
                "lng": -74.1201
            },
            "Starkville": {
                "lat": 33.4608,
                "lng": -88.8297
            },
            "South Plainfield": {
                "lat": 40.5748,
                "lng": -74.4152
            },
            "Wekiwa Springs": {
                "lat": 28.6984,
                "lng": -81.4251
            },
            "Middleborough": {
                "lat": 41.8803,
                "lng": -70.8745
            },
            "Palmetto Bay": {
                "lat": 25.6219,
                "lng": -80.3221
            },
            "Somerset": {
                "lat": 37.0834,
                "lng": -84.6109
            },
            "Fairfax": {
                "lat": 38.8531,
                "lng": -77.2997
            },
            "Belton": {
                "lat": 31.0525,
                "lng": -97.479
            },
            "North Lynnwood": {
                "lat": 47.8533,
                "lng": -122.2762
            },
            "Pelham": {
                "lat": 40.9,
                "lng": -73.8063
            },
            "Yukon": {
                "lat": 35.5201,
                "lng": -97.7639
            },
            "Hingham": {
                "lat": 42.2176,
                "lng": -70.8854
            },
            "Simpsonville": {
                "lat": 34.7287,
                "lng": -82.2569
            },
            "Emporia": {
                "lat": 38.4028,
                "lng": -96.1932
            },
            "Marple": {
                "lat": 39.9654,
                "lng": -75.3657
            },
            "Fishkill": {
                "lat": 41.5129,
                "lng": -73.9271
            },
            "Upper Providence": {
                "lat": 40.1654,
                "lng": -75.4888
            },
            "Bloomingdale": {
                "lat": 36.5793,
                "lng": -82.5096
            },
            "Wadsworth": {
                "lat": 41.0279,
                "lng": -81.7323
            },
            "Rolling Meadows": {
                "lat": 42.0747,
                "lng": -88.0252
            },
            "Solon": {
                "lat": 41.3865,
                "lng": -81.44
            },
            "Mandan": {
                "lat": 46.829,
                "lng": -100.887
            },
            "Bellview": {
                "lat": 30.462,
                "lng": -87.312
            },
            "Jasmine Estates": {
                "lat": 28.293,
                "lng": -82.6907
            },
            "Kingston": {
                "lat": 41.2652,
                "lng": -75.8876
            },
            "Papillion": {
                "lat": 41.1511,
                "lng": -96.0665
            },
            "Plainsboro": {
                "lat": 40.3377,
                "lng": -74.5879
            },
            "San Fernando": {
                "lat": 34.2886,
                "lng": -118.4362
            },
            "Acton": {
                "lat": 42.4843,
                "lng": -71.4378
            },
            "Rosemont": {
                "lat": 38.5478,
                "lng": -121.3553
            },
            "South Elgin": {
                "lat": 41.9906,
                "lng": -88.3135
            },
            "Webster Groves": {
                "lat": 38.5866,
                "lng": -90.3544
            },
            "Cranford": {
                "lat": 40.6564,
                "lng": -74.3036
            },
            "Willoughby": {
                "lat": 41.6459,
                "lng": -81.4084
            },
            "Farragut": {
                "lat": 35.8731,
                "lng": -84.1821
            },
            "Fountain Hills": {
                "lat": 33.6073,
                "lng": -111.7398
            },
            "Munster": {
                "lat": 41.5468,
                "lng": -87.504
            },
            "Waverly": {
                "lat": 42.725,
                "lng": -92.4708
            },
            "Southold": {
                "lat": 41.0432,
                "lng": -72.4185
            },
            "Patterson": {
                "lat": 41.4849,
                "lng": -73.5921
            },
            "Shelbyville": {
                "lat": 38.2067,
                "lng": -85.2292
            },
            "Muscatine": {
                "lat": 41.4195,
                "lng": -91.068
            },
            "Champlin": {
                "lat": 45.1702,
                "lng": -93.3903
            },
            "Roseburg": {
                "lat": 43.2231,
                "lng": -123.352
            },
            "Raritan": {
                "lat": 40.507,
                "lng": -74.8662
            },
            "Kenmore": {
                "lat": 42.9646,
                "lng": -78.8713
            },
            "Mountain House": {
                "lat": 37.7673,
                "lng": -121.5449
            },
            "Jacksonville Beach": {
                "lat": 30.2782,
                "lng": -81.4045
            },
            "Brookings": {
                "lat": 44.3022,
                "lng": -96.7859
            },
            "Nixa": {
                "lat": 37.0453,
                "lng": -93.2959
            },
            "Watauga": {
                "lat": 32.8719,
                "lng": -97.2515
            },
            "Marshall": {
                "lat": 40.6453,
                "lng": -80.11
            },
            "Lisle": {
                "lat": 41.7918,
                "lng": -88.0888
            },
            "Gardner": {
                "lat": 42.5845,
                "lng": -71.9868
            },
            "Griffin": {
                "lat": 33.2418,
                "lng": -84.2748
            },
            "Maple Heights": {
                "lat": 41.4094,
                "lng": -81.5625
            },
            "Copiague": {
                "lat": 40.6707,
                "lng": -73.3922
            },
            "Van Buren": {
                "lat": 43.1211,
                "lng": -76.3591
            },
            "Rock Springs": {
                "lat": 41.5947,
                "lng": -109.2209
            },
            "Pottstown": {
                "lat": 40.2508,
                "lng": -75.6445
            },
            "Westerly": {
                "lat": 41.3635,
                "lng": -71.7899
            },
            "North Platte": {
                "lat": 41.1263,
                "lng": -100.7641
            },
            "Niskayuna": {
                "lat": 42.803,
                "lng": -73.8729
            },
            "Camp Springs": {
                "lat": 38.8052,
                "lng": -76.9199
            },
            "Linda": {
                "lat": 39.1241,
                "lng": -121.5421
            },
            "Martha Lake": {
                "lat": 47.8479,
                "lng": -122.2327
            },
            "Cockeysville": {
                "lat": 39.4804,
                "lng": -76.6294
            },
            "Cottage Lake": {
                "lat": 47.7466,
                "lng": -122.0755
            },
            "Raymore": {
                "lat": 38.8029,
                "lng": -94.4583
            },
            "Pickerington": {
                "lat": 39.889,
                "lng": -82.7678
            },
            "Wareham": {
                "lat": 41.7662,
                "lng": -70.6999
            },
            "Union Hill-Novelty Hill": {
                "lat": 47.6788,
                "lng": -122.0284
            },
            "Eastmont": {
                "lat": 47.8968,
                "lng": -122.1818
            },
            "Ashland": {
                "lat": 39.8782,
                "lng": -75.0085
            },
            "New Brighton": {
                "lat": 45.0658,
                "lng": -93.206
            },
            "Edgewater": {
                "lat": 38.9373,
                "lng": -76.5572
            },
            "Easley": {
                "lat": 34.8188,
                "lng": -82.5827
            },
            "Trotwood": {
                "lat": 39.7926,
                "lng": -84.3165
            },
            "West Goshen": {
                "lat": 39.9756,
                "lng": -75.5927
            },
            "Liberty Triangle": {
                "lat": 29.076,
                "lng": -82.2191
            },
            "Chantilly": {
                "lat": 38.8868,
                "lng": -77.4453
            },
            "Calabasas": {
                "lat": 34.1375,
                "lng": -118.6689
            },
            "Cartersville": {
                "lat": 34.1644,
                "lng": -84.8009
            },
            "Eustis": {
                "lat": 28.8563,
                "lng": -81.6771
            },
            "Roxbury": {
                "lat": 40.8822,
                "lng": -74.6523
            },
            "Morris": {
                "lat": 41.3749,
                "lng": -88.4304
            },
            "Bear": {
                "lat": 39.6189,
                "lng": -75.6808
            },
            "Hopewell": {
                "lat": 40.5906,
                "lng": -80.2731
            },
            "Upper Allen": {
                "lat": 40.18,
                "lng": -76.9808
            },
            "Fernley": {
                "lat": 39.5627,
                "lng": -119.1906
            },
            "Lysander": {
                "lat": 43.1799,
                "lng": -76.3745
            },
            "Glassboro": {
                "lat": 39.7014,
                "lng": -75.1113
            },
            "Taylors": {
                "lat": 34.9157,
                "lng": -82.3124
            },
            "Stoneham": {
                "lat": 42.4741,
                "lng": -71.0972
            },
            "Radcliff": {
                "lat": 37.8204,
                "lng": -85.9365
            },
            "Brent": {
                "lat": 30.4727,
                "lng": -87.2495
            },
            "Searcy": {
                "lat": 35.2418,
                "lng": -91.7351
            },
            "Crystal": {
                "lat": 45.0377,
                "lng": -93.3599
            },
            "Nocatee": {
                "lat": 30.0927,
                "lng": -81.4095
            },
            "Christiansburg": {
                "lat": 37.1406,
                "lng": -80.4036
            },
            "Laguna Beach": {
                "lat": 33.5455,
                "lng": -117.7613
            },
            "Loves Park": {
                "lat": 42.3364,
                "lng": -88.9975
            },
            "Blue Island": {
                "lat": 41.6578,
                "lng": -87.6811
            },
            "Peters": {
                "lat": 40.2739,
                "lng": -80.0803
            },
            "Prairie Village": {
                "lat": 38.9874,
                "lng": -94.6362
            },
            "Keene": {
                "lat": 42.9494,
                "lng": -72.2998
            },
            "Roselle": {
                "lat": 40.6527,
                "lng": -74.2599
            },
            "Machesney Park": {
                "lat": 42.3666,
                "lng": -89.0266
            },
            "Katy": {
                "lat": 29.7905,
                "lng": -95.8353
            },
            "Millbrae": {
                "lat": 37.5994,
                "lng": -122.4023
            },
            "Hialeah Gardens": {
                "lat": 25.8878,
                "lng": -80.3569
            },
            "Corcoran": {
                "lat": 36.0841,
                "lng": -119.5613
            },
            "Brambleton": {
                "lat": 38.9803,
                "lng": -77.5323
            },
            "Pecan Grove": {
                "lat": 29.6235,
                "lng": -95.733
            },
            "Sun Valley": {
                "lat": 39.6104,
                "lng": -119.777
            },
            "Onondaga": {
                "lat": 42.9686,
                "lng": -76.2169
            },
            "Duncan": {
                "lat": 34.5408,
                "lng": -97.9215
            },
            "Villa Park": {
                "lat": 41.8865,
                "lng": -87.9779
            },
            "Junction City": {
                "lat": 39.0277,
                "lng": -96.8508
            },
            "Hazel Dell": {
                "lat": 45.6797,
                "lng": -122.6553
            },
            "Anthem": {
                "lat": 33.856,
                "lng": -112.1168
            },
            "The Crossings": {
                "lat": 25.6708,
                "lng": -80.4018
            },
            "Candler-McAfee": {
                "lat": 33.7267,
                "lng": -84.2723
            },
            "Kapolei": {
                "lat": 21.3399,
                "lng": -158.0677
            },
            "Allison Park": {
                "lat": 40.573,
                "lng": -79.9603
            },
            "North Plainfield": {
                "lat": 40.6209,
                "lng": -74.4386
            },
            "Cudahy": {
                "lat": 42.9467,
                "lng": -87.8641
            },
            "East San Gabriel": {
                "lat": 34.1157,
                "lng": -118.0791
            },
            "Fairhope": {
                "lat": 30.5209,
                "lng": -87.8813
            },
            "Bonney Lake": {
                "lat": 47.1791,
                "lng": -122.17
            },
            "Summit": {
                "lat": 47.1694,
                "lng": -122.3628
            },
            "East Peoria": {
                "lat": 40.6736,
                "lng": -89.5419
            },
            "Florida Ridge": {
                "lat": 27.5805,
                "lng": -80.3848
            },
            "Horizon City": {
                "lat": 31.6799,
                "lng": -106.1903
            },
            "Corinth": {
                "lat": 34.9474,
                "lng": -88.5143
            },
            "Coralville": {
                "lat": 41.699,
                "lng": -91.5967
            },
            "Willowbrook": {
                "lat": 41.7641,
                "lng": -87.9454
            },
            "Vernon": {
                "lat": 34.1479,
                "lng": -99.3
            },
            "Biddeford": {
                "lat": 43.4673,
                "lng": -70.4511
            },
            "Albertville": {
                "lat": 34.2633,
                "lng": -86.2108
            },
            "Rancho San Diego": {
                "lat": 32.7624,
                "lng": -116.9197
            },
            "Montville": {
                "lat": 40.9135,
                "lng": -74.3594
            },
            "Central Falls": {
                "lat": 41.8901,
                "lng": -71.3934
            },
            "Waynesboro": {
                "lat": 39.7524,
                "lng": -77.5822
            },
            "Lyndhurst": {
                "lat": 41.5172,
                "lng": -81.4922
            },
            "Acworth": {
                "lat": 34.0566,
                "lng": -84.6716
            },
            "Scarborough": {
                "lat": 43.5911,
                "lng": -70.3682
            },
            "DeBary": {
                "lat": 28.8815,
                "lng": -81.324
            },
            "Ruston": {
                "lat": 32.5328,
                "lng": -92.6363
            },
            "Brushy Creek": {
                "lat": 30.5128,
                "lng": -97.7386
            },
            "Palmer": {
                "lat": 42.1888,
                "lng": -72.3112
            },
            "Hillside": {
                "lat": 40.6961,
                "lng": -74.2286
            },
            "Mountain Brook": {
                "lat": 33.4871,
                "lng": -86.74
            },
            "Marina": {
                "lat": 36.681,
                "lng": -121.7892
            },
            "Kihei": {
                "lat": 20.7653,
                "lng": -156.4454
            },
            "West Carson": {
                "lat": 33.8229,
                "lng": -118.2931
            },
            "Silver Firs": {
                "lat": 47.8635,
                "lng": -122.1497
            },
            "Sunny Isles Beach": {
                "lat": 25.9385,
                "lng": -80.1246
            },
            "West Deptford": {
                "lat": 39.8431,
                "lng": -75.1852
            },
            "Chalmette": {
                "lat": 29.9438,
                "lng": -89.966
            },
            "McNair": {
                "lat": 38.9513,
                "lng": -77.4115
            },
            "Granite Bay": {
                "lat": 38.7601,
                "lng": -121.1714
            },
            "Ensley": {
                "lat": 30.5259,
                "lng": -87.2733
            },
            "Golden Valley": {
                "lat": 44.9901,
                "lng": -93.3591
            },
            "West Rancho Dominguez": {
                "lat": 33.9057,
                "lng": -118.2683
            },
            "World Golf Village": {
                "lat": 29.9654,
                "lng": -81.4896
            },
            "Lower": {
                "lat": 38.9819,
                "lng": -74.9087
            },
            "Ramona": {
                "lat": 33.0474,
                "lng": -116.8766
            },
            "Smithfield": {
                "lat": 36.9755,
                "lng": -76.6152
            },
            "West Puente Valley": {
                "lat": 34.0513,
                "lng": -117.9681
            },
            "Lorton": {
                "lat": 38.6983,
                "lng": -77.2164
            },
            "East Ridge": {
                "lat": 34.9973,
                "lng": -85.2285
            },
            "Carbondale": {
                "lat": 41.5714,
                "lng": -75.5048
            },
            "Sapulpa": {
                "lat": 36.0091,
                "lng": -96.1003
            },
            "Chillicothe": {
                "lat": 39.7953,
                "lng": -93.5499
            },
            "Darien": {
                "lat": 41.7448,
                "lng": -87.9822
            },
            "Valinda": {
                "lat": 34.04,
                "lng": -117.93
            },
            "New Hartford": {
                "lat": 43.0586,
                "lng": -75.2821
            },
            "Chambersburg": {
                "lat": 39.9315,
                "lng": -77.6556
            },
            "St. Andrews": {
                "lat": 34.051,
                "lng": -81.1057
            },
            "Pascagoula": {
                "lat": 30.3666,
                "lng": -88.5506
            },
            "North Salt Lake": {
                "lat": 40.8439,
                "lng": -111.9187
            },
            "Middleton": {
                "lat": 43.7113,
                "lng": -116.6157
            },
            "New Castle": {
                "lat": 39.9191,
                "lng": -85.3697
            },
            "Port Hueneme": {
                "lat": 34.1618,
                "lng": -119.2036
            },
            "Massapequa": {
                "lat": 40.6676,
                "lng": -73.4706
            },
            "Muhlenberg": {
                "lat": 40.3955,
                "lng": -75.925
            },
            "Columbia Heights": {
                "lat": 45.0484,
                "lng": -93.2472
            },
            "Hayesville": {
                "lat": 44.9793,
                "lng": -122.9738
            },
            "Parsippany": {
                "lat": 40.8645,
                "lng": -74.4135
            },
            "Klamath Falls": {
                "lat": 42.2195,
                "lng": -121.7754
            },
            "Manor": {
                "lat": 30.3562,
                "lng": -97.5227
            },
            "Langley Park": {
                "lat": 38.9897,
                "lng": -76.9808
            },
            "Silverdale": {
                "lat": 47.6663,
                "lng": -122.6828
            },
            "Sedalia": {
                "lat": 38.7059,
                "lng": -93.234
            },
            "Selden": {
                "lat": 40.8714,
                "lng": -73.0466
            },
            "South Euclid": {
                "lat": 41.524,
                "lng": -81.5245
            },
            "Shelby": {
                "lat": 35.2904,
                "lng": -81.5451
            },
            "Yucca Valley": {
                "lat": 34.1234,
                "lng": -116.4216
            },
            "Millburn": {
                "lat": 40.7394,
                "lng": -74.324
            },
            "Duarte": {
                "lat": 34.161,
                "lng": -117.9504
            },
            "Del City": {
                "lat": 35.4483,
                "lng": -97.4408
            },
            "Gallup": {
                "lat": 35.5182,
                "lng": -108.7431
            },
            "American Canyon": {
                "lat": 38.1796,
                "lng": -122.2583
            },
            "New Hope": {
                "lat": 45.0375,
                "lng": -93.3869
            },
            "Alliance": {
                "lat": 40.9107,
                "lng": -81.1189
            },
            "Unity": {
                "lat": 40.2811,
                "lng": -79.4236
            },
            "Tukwila": {
                "lat": 47.475,
                "lng": -122.2728
            },
            "Fulshear": {
                "lat": 29.693,
                "lng": -95.8804
            },
            "Rocky River": {
                "lat": 41.4702,
                "lng": -81.8525
            },
            "Mililani Mauka": {
                "lat": 21.4756,
                "lng": -157.9948
            },
            "Payson": {
                "lat": 34.2433,
                "lng": -111.3195
            },
            "Lino Lakes": {
                "lat": 45.1679,
                "lng": -93.083
            },
            "Celina": {
                "lat": 40.555,
                "lng": -84.5626
            },
            "Holiday": {
                "lat": 28.1864,
                "lng": -82.7429
            },
            "Harvey": {
                "lat": 41.6076,
                "lng": -87.652
            },
            "Secaucus": {
                "lat": 40.781,
                "lng": -74.0659
            },
            "Ozark": {
                "lat": 31.4508,
                "lng": -85.6473
            },
            "East Patchogue": {
                "lat": 40.7704,
                "lng": -72.9817
            },
            "Rio Rico": {
                "lat": 31.4957,
                "lng": -110.9885
            },
            "Four Square Mile": {
                "lat": 39.6808,
                "lng": -104.888
            },
            "Geneva": {
                "lat": 42.8645,
                "lng": -76.9826
            },
            "Pleasant Prairie": {
                "lat": 42.5266,
                "lng": -87.8895
            },
            "Seymour": {
                "lat": 35.8783,
                "lng": -83.7669
            },
            "Mukilteo": {
                "lat": 47.9096,
                "lng": -122.3035
            },
            "Westborough": {
                "lat": 42.2681,
                "lng": -71.614
            },
            "Mountlake Terrace": {
                "lat": 47.7921,
                "lng": -122.3077
            },
            "South Lake Tahoe": {
                "lat": 38.9393,
                "lng": -119.9828
            },
            "Lindenwold": {
                "lat": 39.8173,
                "lng": -74.9898
            },
            "Vicksburg": {
                "lat": 32.3173,
                "lng": -90.8868
            },
            "Moorestown": {
                "lat": 39.9784,
                "lng": -74.9413
            },
            "Somers": {
                "lat": 41.3056,
                "lng": -73.7252
            },
            "Winter Gardens": {
                "lat": 32.8376,
                "lng": -116.9268
            },
            "South Holland": {
                "lat": 41.5977,
                "lng": -87.6022
            },
            "Sulphur": {
                "lat": 30.2286,
                "lng": -93.3566
            },
            "Dolton": {
                "lat": 41.6284,
                "lng": -87.5979
            },
            "Clemmons": {
                "lat": 36.0319,
                "lng": -80.3861
            },
            "Perry": {
                "lat": 32.472,
                "lng": -83.7283
            },
            "Yorkville": {
                "lat": 41.6563,
                "lng": -88.4507
            },
            "West Pensacola": {
                "lat": 30.4263,
                "lng": -87.2679
            },
            "Carrboro": {
                "lat": 35.9259,
                "lng": -79.0878
            },
            "Suwanee": {
                "lat": 34.0508,
                "lng": -84.0686
            },
            "Lealman": {
                "lat": 27.8197,
                "lng": -82.6847
            },
            "Rose Hill": {
                "lat": 38.7872,
                "lng": -77.1085
            },
            "Milwaukie": {
                "lat": 45.4445,
                "lng": -122.6219
            },
            "Hyde Park": {
                "lat": 41.8011,
                "lng": -73.906
            },
            "Bayou Cane": {
                "lat": 29.6243,
                "lng": -90.751
            },
            "Foley": {
                "lat": 30.3983,
                "lng": -87.665
            },
            "Hays": {
                "lat": 38.8821,
                "lng": -99.3221
            },
            "Tullahoma": {
                "lat": 35.3721,
                "lng": -86.2172
            },
            "North Bellmore": {
                "lat": 40.6904,
                "lng": -73.539
            },
            "North Ogden": {
                "lat": 41.3123,
                "lng": -111.9584
            },
            "Makakilo": {
                "lat": 21.3591,
                "lng": -158.0813
            },
            "South Whitehall": {
                "lat": 40.6154,
                "lng": -75.5503
            },
            "Park Forest": {
                "lat": 41.4817,
                "lng": -87.6867
            },
            "Wade Hampton": {
                "lat": 34.8821,
                "lng": -82.3336
            },
            "Stephenville": {
                "lat": 32.2147,
                "lng": -98.2205
            },
            "Willmar": {
                "lat": 45.1216,
                "lng": -95.0569
            },
            "Merrick": {
                "lat": 40.6515,
                "lng": -73.5535
            },
            "Upper St. Clair": {
                "lat": 40.3336,
                "lng": -80.0842
            },
            "Mineola": {
                "lat": 40.747,
                "lng": -73.6394
            },
            "East Moline": {
                "lat": 41.5199,
                "lng": -90.3879
            },
            "East Pennsboro": {
                "lat": 40.2886,
                "lng": -76.9394
            },
            "Green Valley": {
                "lat": 39.3414,
                "lng": -77.24
            },
            "Fort Walton Beach": {
                "lat": 30.4255,
                "lng": -86.6222
            },
            "Five Corners": {
                "lat": 45.6883,
                "lng": -122.5738
            },
            "Little Egg Harbor": {
                "lat": 39.5969,
                "lng": -74.3454
            },
            "Snellville": {
                "lat": 33.8562,
                "lng": -84.0038
            },
            "Battle Ground": {
                "lat": 45.7766,
                "lng": -122.5413
            },
            "Ludlow": {
                "lat": 42.1921,
                "lng": -72.458
            },
            "Murphy": {
                "lat": 33.0186,
                "lng": -96.6105
            },
            "Hyattsville": {
                "lat": 38.9613,
                "lng": -76.9548
            },
            "Carlisle": {
                "lat": 40.2,
                "lng": -77.2034
            },
            "West St. Paul": {
                "lat": 44.9018,
                "lng": -93.0858
            },
            "Murrysville": {
                "lat": 40.4456,
                "lng": -79.6554
            },
            "Buckingham": {
                "lat": 40.3188,
                "lng": -75.058
            },
            "Grayslake": {
                "lat": 42.3405,
                "lng": -88.0338
            },
            "Mill Creek": {
                "lat": 47.8631,
                "lng": -122.2037
            },
            "Coolbaugh": {
                "lat": 41.1837,
                "lng": -75.4386
            },
            "Bryant": {
                "lat": 34.6152,
                "lng": -92.4914
            },
            "Hanahan": {
                "lat": 32.9302,
                "lng": -80.0027
            },
            "St. John": {
                "lat": 41.4429,
                "lng": -87.4697
            },
            "Ennis": {
                "lat": 32.3254,
                "lng": -96.6347
            },
            "Parma Heights": {
                "lat": 41.3865,
                "lng": -81.7637
            },
            "Lennox": {
                "lat": 33.938,
                "lng": -118.3586
            },
            "South Milwaukee": {
                "lat": 42.912,
                "lng": -87.8627
            },
            "Marquette": {
                "lat": 46.544,
                "lng": -87.4082
            },
            "Waxhaw": {
                "lat": 34.9363,
                "lng": -80.7439
            },
            "Lomita": {
                "lat": 33.7933,
                "lng": -118.3175
            },
            "Rosamond": {
                "lat": 34.8658,
                "lng": -118.2155
            },
            "Hurricane": {
                "lat": 37.1487,
                "lng": -113.3517
            },
            "Adrian": {
                "lat": 41.8994,
                "lng": -84.0447
            },
            "South St. Paul": {
                "lat": 44.8877,
                "lng": -93.0411
            },
            "Nanuet": {
                "lat": 41.0957,
                "lng": -74.0155
            },
            "Mead Valley": {
                "lat": 33.8333,
                "lng": -117.2852
            },
            "Pleasantville": {
                "lat": 39.39,
                "lng": -74.5169
            },
            "Libertyville": {
                "lat": 42.287,
                "lng": -87.967
            },
            "Jefferson": {
                "lat": 29.9609,
                "lng": -90.1554
            },
            "Forest Lake": {
                "lat": 45.2536,
                "lng": -92.9582
            },
            "Bothell West": {
                "lat": 47.8056,
                "lng": -122.2401
            },
            "Mustang": {
                "lat": 35.3917,
                "lng": -97.7246
            },
            "Northfield": {
                "lat": 44.455,
                "lng": -93.1698
            },
            "Elko": {
                "lat": 40.8381,
                "lng": -115.7678
            },
            "Limerick": {
                "lat": 40.2323,
                "lng": -75.5344
            },
            "Stevenson Ranch": {
                "lat": 34.3894,
                "lng": -118.5883
            },
            "Lower Southampton": {
                "lat": 40.1541,
                "lng": -74.9903
            },
            "Golden": {
                "lat": 39.7406,
                "lng": -105.2118
            },
            "Otsego": {
                "lat": 45.266,
                "lng": -93.6201
            },
            "Bourne": {
                "lat": 41.7233,
                "lng": -70.5816
            },
            "Saco": {
                "lat": 43.539,
                "lng": -70.4624
            },
            "Piqua": {
                "lat": 40.1506,
                "lng": -84.2441
            },
            "Imperial": {
                "lat": 32.839,
                "lng": -115.572
            },
            "Sandwich": {
                "lat": 41.7137,
                "lng": -70.4851
            },
            "Hauppauge": {
                "lat": 40.8211,
                "lng": -73.2109
            },
            "Montrose": {
                "lat": 38.4689,
                "lng": -107.859
            },
            "West Hempstead": {
                "lat": 40.6959,
                "lng": -73.6507
            },
            "North Liberty": {
                "lat": 41.7438,
                "lng": -91.6112
            },
            "Old Jamestown": {
                "lat": 38.8394,
                "lng": -90.2847
            },
            "Painesville": {
                "lat": 41.724,
                "lng": -81.2536
            },
            "La Ca\u00f1ada Flintridge": {
                "lat": 34.2097,
                "lng": -118.2002
            },
            "Marblehead": {
                "lat": 42.4992,
                "lng": -70.8639
            },
            "Sidney": {
                "lat": 40.2891,
                "lng": -84.1667
            },
            "Altamont": {
                "lat": 42.198,
                "lng": -121.7248
            },
            "Middle": {
                "lat": 39.0852,
                "lng": -74.8337
            },
            "Westbrook": {
                "lat": 43.6954,
                "lng": -70.3539
            },
            "North Amityville": {
                "lat": 40.7005,
                "lng": -73.4119
            },
            "Salmon Creek": {
                "lat": 45.7099,
                "lng": -122.6632
            },
            "Lynbrook": {
                "lat": 40.6579,
                "lng": -73.6742
            },
            "East Northport": {
                "lat": 40.8791,
                "lng": -73.3233
            },
            "Marion Oaks": {
                "lat": 29.0011,
                "lng": -82.1953
            },
            "Oroville": {
                "lat": 39.4999,
                "lng": -121.5634
            },
            "Whitpain": {
                "lat": 40.1578,
                "lng": -75.2769
            },
            "East Hemet": {
                "lat": 33.7301,
                "lng": -116.941
            },
            "Hazlet": {
                "lat": 40.4265,
                "lng": -74.1712
            },
            "South Burlington": {
                "lat": 44.4622,
                "lng": -73.2202
            },
            "Palisades Park": {
                "lat": 40.8472,
                "lng": -73.9966
            },
            "Lower Allen": {
                "lat": 40.2082,
                "lng": -76.9287
            },
            "Ypsilanti": {
                "lat": 42.244,
                "lng": -83.6208
            },
            "Shafter": {
                "lat": 35.4794,
                "lng": -119.2013
            },
            "Midway": {
                "lat": 30.4169,
                "lng": -87.0229
            },
            "Arbutus": {
                "lat": 39.2428,
                "lng": -76.6922
            },
            "Mayfield Heights": {
                "lat": 41.5174,
                "lng": -81.4534
            },
            "Culpeper": {
                "lat": 38.4704,
                "lng": -78.0001
            },
            "Buckhall": {
                "lat": 38.7239,
                "lng": -77.4476
            },
            "La Crescenta-Montrose": {
                "lat": 34.2322,
                "lng": -118.2353
            },
            "Agoura Hills": {
                "lat": 34.151,
                "lng": -118.7609
            },
            "Rosedale": {
                "lat": 35.3886,
                "lng": -119.2058
            },
            "Casa de Oro-Mount Helix": {
                "lat": 32.764,
                "lng": -116.9688
            },
            "Meadowbrook": {
                "lat": 33.3935,
                "lng": -86.7041
            },
            "Rolla": {
                "lat": 37.9459,
                "lng": -91.7607
            },
            "Howard": {
                "lat": 44.5703,
                "lng": -88.0928
            },
            "Port Angeles": {
                "lat": 48.1141,
                "lng": -123.4565
            },
            "Forest Park": {
                "lat": 41.8683,
                "lng": -87.8157
            },
            "Sand Springs": {
                "lat": 36.1353,
                "lng": -96.1283
            },
            "Merrifield": {
                "lat": 38.8731,
                "lng": -77.2426
            },
            "Affton": {
                "lat": 38.5499,
                "lng": -90.3264
            },
            "Plattsburgh": {
                "lat": 44.6951,
                "lng": -73.4563
            },
            "Oregon": {
                "lat": 42.9253,
                "lng": -89.3892
            },
            "Hunters Creek": {
                "lat": 28.361,
                "lng": -81.4358
            },
            "Mokena": {
                "lat": 41.5327,
                "lng": -87.8781
            },
            "Crest Hill": {
                "lat": 41.5723,
                "lng": -88.1124
            },
            "Maple Shade": {
                "lat": 39.952,
                "lng": -74.995
            },
            "Miamisburg": {
                "lat": 39.6322,
                "lng": -84.2724
            },
            "Stroud": {
                "lat": 41.0001,
                "lng": -75.2173
            },
            "Broadview Heights": {
                "lat": 41.3196,
                "lng": -81.6779
            },
            "Lincolnia": {
                "lat": 38.8158,
                "lng": -77.1543
            },
            "Alamo": {
                "lat": 37.8548,
                "lng": -122.0136
            },
            "Universal City": {
                "lat": 29.5521,
                "lng": -98.3074
            },
            "Holden": {
                "lat": 42.3561,
                "lng": -71.8608
            },
            "Sparta": {
                "lat": 43.9377,
                "lng": -90.8131
            },
            "Palm Valley": {
                "lat": 30.2011,
                "lng": -81.3948
            },
            "Lackawanna": {
                "lat": 42.8182,
                "lng": -78.8325
            },
            "Nogales": {
                "lat": 31.3624,
                "lng": -110.9336
            },
            "Lake Zurich": {
                "lat": 42.1956,
                "lng": -88.087
            },
            "Evergreen Park": {
                "lat": 41.7213,
                "lng": -87.7013
            },
            "Claremore": {
                "lat": 36.3146,
                "lng": -95.6095
            },
            "West Whiteland": {
                "lat": 40.0227,
                "lng": -75.6239
            },
            "Beltsville": {
                "lat": 39.0394,
                "lng": -76.9211
            },
            "Kinston": {
                "lat": 35.2747,
                "lng": -77.5939
            },
            "South El Monte": {
                "lat": 34.0493,
                "lng": -118.0484
            },
            "Whitemarsh": {
                "lat": 40.104,
                "lng": -75.2483
            },
            "Grafton": {
                "lat": 43.3204,
                "lng": -87.948
            },
            "Arvin": {
                "lat": 35.1944,
                "lng": -118.8306
            },
            "Punta Gorda": {
                "lat": 26.8941,
                "lng": -82.0513
            },
            "Brigham City": {
                "lat": 41.5035,
                "lng": -112.0453
            },
            "Holtsville": {
                "lat": 40.8124,
                "lng": -73.0447
            },
            "Erlanger": {
                "lat": 39.0109,
                "lng": -84.5864
            },
            "Hermosa Beach": {
                "lat": 33.8653,
                "lng": -118.3962
            },
            "East Massapequa": {
                "lat": 40.6742,
                "lng": -73.4359
            },
            "West Mifflin": {
                "lat": 40.3581,
                "lng": -79.9073
            },
            "Orinda": {
                "lat": 37.8808,
                "lng": -122.179
            },
            "Angleton": {
                "lat": 29.1721,
                "lng": -95.4293
            },
            "Madisonville": {
                "lat": 37.3409,
                "lng": -87.5034
            },
            "Bartow": {
                "lat": 27.8868,
                "lng": -81.8214
            },
            "Lynn Haven": {
                "lat": 30.2337,
                "lng": -85.637
            },
            "Zachary": {
                "lat": 30.6643,
                "lng": -91.1634
            },
            "Sweetwater": {
                "lat": 32.4692,
                "lng": -100.4092
            },
            "Sartell": {
                "lat": 45.6188,
                "lng": -94.2206
            },
            "Prunedale": {
                "lat": 36.8064,
                "lng": -121.6555
            },
            "Seabrook": {
                "lat": 29.5751,
                "lng": -95.0235
            },
            "Boone": {
                "lat": 42.0531,
                "lng": -93.877
            },
            "Prichard": {
                "lat": 30.7735,
                "lng": -88.1301
            },
            "Warrensburg": {
                "lat": 38.7627,
                "lng": -93.726
            },
            "Naples": {
                "lat": 26.1504,
                "lng": -81.7936
            },
            "Twinsburg": {
                "lat": 41.322,
                "lng": -81.4451
            },
            "Sudley": {
                "lat": 38.7878,
                "lng": -77.4961
            },
            "Seminole": {
                "lat": 27.8435,
                "lng": -82.784
            },
            "Cutlerville": {
                "lat": 42.8403,
                "lng": -85.6734
            },
            "Hermiston": {
                "lat": 45.8326,
                "lng": -119.2854
            },
            "Maitland": {
                "lat": 28.6295,
                "lng": -81.3718
            },
            "Ferndale": {
                "lat": 48.8526,
                "lng": -122.5894
            },
            "Sherrelwood": {
                "lat": 39.839,
                "lng": -105.0014
            },
            "Valle Vista": {
                "lat": 33.7436,
                "lng": -116.8871
            },
            "Groveland": {
                "lat": 28.6098,
                "lng": -81.8246
            },
            "Roosevelt": {
                "lat": 40.6797,
                "lng": -73.5837
            },
            "Springboro": {
                "lat": 39.5615,
                "lng": -84.2348
            },
            "Maumelle": {
                "lat": 34.8522,
                "lng": -92.4
            },
            "West Manchester": {
                "lat": 39.9456,
                "lng": -76.7952
            },
            "Badger": {
                "lat": 64.8006,
                "lng": -147.3877
            },
            "Tinton Falls": {
                "lat": 40.2708,
                "lng": -74.0949
            },
            "Norton": {
                "lat": 41.0294,
                "lng": -81.6461
            },
            "Deerfield": {
                "lat": 42.1654,
                "lng": -87.8516
            },
            "Horseheads": {
                "lat": 42.1625,
                "lng": -76.7941
            },
            "Cedar Mill": {
                "lat": 45.5355,
                "lng": -122.8006
            },
            "Durango": {
                "lat": 37.2659,
                "lng": -107.8781
            },
            "Knightdale": {
                "lat": 35.7911,
                "lng": -78.4966
            },
            "Central Point": {
                "lat": 42.3764,
                "lng": -122.9111
            },
            "Uwchlan": {
                "lat": 40.0522,
                "lng": -75.6679
            },
            "White Oak": {
                "lat": 39.0451,
                "lng": -76.9885
            },
            "Lumberton": {
                "lat": 39.9569,
                "lng": -74.8036
            },
            "Liberal": {
                "lat": 37.0466,
                "lng": -100.9295
            },
            "Haslett": {
                "lat": 42.7525,
                "lng": -84.402
            },
            "Tavares": {
                "lat": 28.792,
                "lng": -81.7352
            },
            "Saugerties": {
                "lat": 42.0891,
                "lng": -73.9969
            },
            "Cocoa": {
                "lat": 28.382,
                "lng": -80.7675
            },
            "Dixon": {
                "lat": 41.8439,
                "lng": -89.4794
            },
            "Scituate": {
                "lat": 41.7926,
                "lng": -71.6203
            },
            "Sylvania": {
                "lat": 41.71,
                "lng": -83.7092
            },
            "Cimarron Hills": {
                "lat": 38.8597,
                "lng": -104.6995
            },
            "Winthrop": {
                "lat": 42.3761,
                "lng": -70.9846
            },
            "Point Pleasant": {
                "lat": 40.0772,
                "lng": -74.0702
            },
            "North Valley Stream": {
                "lat": 40.684,
                "lng": -73.7077
            },
            "West Chester": {
                "lat": 39.9601,
                "lng": -75.6058
            },
            "Ferguson": {
                "lat": 38.749,
                "lng": -90.295
            },
            "Fate": {
                "lat": 32.943,
                "lng": -96.3858
            },
            "Gautier": {
                "lat": 30.4106,
                "lng": -88.6568
            },
            "Ellensburg": {
                "lat": 46.9999,
                "lng": -120.5475
            },
            "Weirton": {
                "lat": 40.406,
                "lng": -80.5671
            },
            "Ansonia": {
                "lat": 41.3443,
                "lng": -73.0689
            },
            "Fox Crossing": {
                "lat": 44.2228,
                "lng": -88.4763
            },
            "Pinole": {
                "lat": 37.9931,
                "lng": -122.2833
            },
            "Sudbury": {
                "lat": 42.3847,
                "lng": -71.4234
            },
            "Franklin Farm": {
                "lat": 38.9133,
                "lng": -77.3969
            },
            "Matteson": {
                "lat": 41.5095,
                "lng": -87.7468
            },
            "San Carlos Park": {
                "lat": 26.4765,
                "lng": -81.8193
            },
            "Lake Shore": {
                "lat": 39.103,
                "lng": -76.4876
            },
            "Coronado": {
                "lat": 32.6649,
                "lng": -117.1604
            },
            "Alsip": {
                "lat": 41.6701,
                "lng": -87.7368
            },
            "Cinco Ranch": {
                "lat": 29.7395,
                "lng": -95.7607
            },
            "North Myrtle Beach": {
                "lat": 33.8232,
                "lng": -78.7082
            },
            "Santa Fe Springs": {
                "lat": 33.9329,
                "lng": -118.0625
            },
            "Ronkonkoma": {
                "lat": 40.8037,
                "lng": -73.1246
            },
            "Onalaska": {
                "lat": 43.8883,
                "lng": -91.2075
            },
            "Lakeway": {
                "lat": 30.3544,
                "lng": -97.9864
            },
            "Bradfordville": {
                "lat": 30.5735,
                "lng": -84.2055
            },
            "Republic": {
                "lat": 37.1452,
                "lng": -93.4446
            },
            "Brownwood": {
                "lat": 31.7127,
                "lng": -98.9767
            },
            "Sheridan": {
                "lat": 44.7961,
                "lng": -106.9642
            },
            "Syosset": {
                "lat": 40.8157,
                "lng": -73.502
            },
            "East St. Louis": {
                "lat": 38.6155,
                "lng": -90.1304
            },
            "Owego": {
                "lat": 42.0881,
                "lng": -76.1905
            },
            "Wisconsin Rapids": {
                "lat": 44.3927,
                "lng": -89.8265
            },
            "Chowchilla": {
                "lat": 37.1095,
                "lng": -120.2349
            },
            "Durant": {
                "lat": 33.9949,
                "lng": -96.3923
            },
            "Rutherford": {
                "lat": 40.8203,
                "lng": -74.1057
            },
            "Hopkins": {
                "lat": 44.9259,
                "lng": -93.4056
            },
            "Hopkinton": {
                "lat": 42.2255,
                "lng": -71.5378
            },
            "Bayshore Gardens": {
                "lat": 27.4345,
                "lng": -82.5793
            },
            "Palestine": {
                "lat": 31.7544,
                "lng": -95.6471
            },
            "Wallingford Center": {
                "lat": 41.4499,
                "lng": -72.8189
            },
            "Lansdale": {
                "lat": 40.2417,
                "lng": -75.2812
            },
            "Phoenixville": {
                "lat": 40.1359,
                "lng": -75.5201
            },
            "Country Walk": {
                "lat": 25.633,
                "lng": -80.4353
            },
            "South San Jose Hills": {
                "lat": 34.0123,
                "lng": -117.9043
            },
            "Altus": {
                "lat": 34.6565,
                "lng": -99.3061
            },
            "Ottawa": {
                "lat": 38.5996,
                "lng": -95.2636
            },
            "Creve Coeur": {
                "lat": 38.662,
                "lng": -90.443
            },
            "Martinsburg": {
                "lat": 39.4582,
                "lng": -77.9776
            },
            "Round Lake": {
                "lat": 42.3435,
                "lng": -88.1059
            },
            "Blooming Grove": {
                "lat": 41.3948,
                "lng": -74.184
            },
            "Bensenville": {
                "lat": 41.9579,
                "lng": -87.9442
            },
            "Trinity": {
                "lat": 28.1809,
                "lng": -82.6582
            },
            "Seagoville": {
                "lat": 32.653,
                "lng": -96.5456
            },
            "Centerton": {
                "lat": 36.3566,
                "lng": -94.2971
            },
            "St. Michael": {
                "lat": 45.2014,
                "lng": -93.692
            },
            "Castaic": {
                "lat": 34.4818,
                "lng": -118.6316
            },
            "Knik-Fairview": {
                "lat": 61.5082,
                "lng": -149.6252
            },
            "Fairwood": {
                "lat": 47.4467,
                "lng": -122.143
            },
            "Bellwood": {
                "lat": 41.8829,
                "lng": -87.8762
            },
            "Five Forks": {
                "lat": 34.8069,
                "lng": -82.2271
            },
            "Preakness": {
                "lat": 40.9382,
                "lng": -74.2242
            },
            "Anna": {
                "lat": 33.3472,
                "lng": -96.5508
            },
            "Hatfield": {
                "lat": 40.2758,
                "lng": -75.2895
            },
            "Colonia": {
                "lat": 40.5926,
                "lng": -74.3148
            },
            "Windham": {
                "lat": 42.8076,
                "lng": -71.2995
            },
            "Clive": {
                "lat": 41.6147,
                "lng": -93.798
            },
            "Elizabeth City": {
                "lat": 36.2942,
                "lng": -76.236
            },
            "Nederland": {
                "lat": 29.9707,
                "lng": -94.0015
            },
            "Kingsland": {
                "lat": 30.8194,
                "lng": -81.7216
            },
            "Shenandoah": {
                "lat": 30.4019,
                "lng": -91.002
            },
            "Wheatfield": {
                "lat": 43.0975,
                "lng": -78.8831
            },
            "Berea": {
                "lat": 37.5904,
                "lng": -84.2898
            },
            "Arcata": {
                "lat": 40.8615,
                "lng": -124.0758
            },
            "Parole": {
                "lat": 38.9863,
                "lng": -76.5518
            },
            "Melville": {
                "lat": 40.7824,
                "lng": -73.4088
            },
            "Oxon Hill": {
                "lat": 38.7884,
                "lng": -76.9727
            },
            "Iselin": {
                "lat": 40.5697,
                "lng": -74.3172
            },
            "Brook Park": {
                "lat": 41.4036,
                "lng": -81.8219
            },
            "St. Marys": {
                "lat": 41.4574,
                "lng": -78.5342
            },
            "Goffstown": {
                "lat": 43.019,
                "lng": -71.568
            },
            "Foxborough": {
                "lat": 42.0627,
                "lng": -71.2461
            },
            "Boerne": {
                "lat": 29.7847,
                "lng": -98.7292
            },
            "Sharon": {
                "lat": 41.234,
                "lng": -80.4998
            },
            "La Marque": {
                "lat": 29.3683,
                "lng": -94.9941
            },
            "Arroyo Grande": {
                "lat": 35.1241,
                "lng": -120.5845
            },
            "Albert Lea": {
                "lat": 43.6548,
                "lng": -93.3643
            },
            "Cullman": {
                "lat": 34.1775,
                "lng": -86.8407
            },
            "Johnstown": {
                "lat": 40.3499,
                "lng": -104.9482
            },
            "New River": {
                "lat": 33.8835,
                "lng": -112.0858
            },
            "Ocean Springs": {
                "lat": 30.4082,
                "lng": -88.7861
            },
            "Winder": {
                "lat": 33.9917,
                "lng": -83.7218
            },
            "Tallmadge": {
                "lat": 41.1023,
                "lng": -81.4216
            },
            "Fairmont": {
                "lat": 43.6441,
                "lng": -94.4621
            },
            "North Babylon": {
                "lat": 40.7314,
                "lng": -73.3248
            },
            "Crowley": {
                "lat": 30.2175,
                "lng": -92.3752
            },
            "North Bay Shore": {
                "lat": 40.7602,
                "lng": -73.2617
            },
            "East Goshen": {
                "lat": 39.9934,
                "lng": -75.5478
            },
            "Greene": {
                "lat": 39.9543,
                "lng": -77.5668
            },
            "Franklin Park": {
                "lat": 40.4439,
                "lng": -74.5431
            },
            "Newburyport": {
                "lat": 42.8124,
                "lng": -70.8878
            },
            "Redland": {
                "lat": 39.1339,
                "lng": -77.1464
            },
            "Maryland City": {
                "lat": 39.1016,
                "lng": -76.8051
            },
            "Lake Ronkonkoma": {
                "lat": 40.8297,
                "lng": -73.1128
            },
            "Pembroke": {
                "lat": 42.0655,
                "lng": -70.8014
            },
            "Calverton": {
                "lat": 39.0578,
                "lng": -76.9488
            },
            "Lenoir": {
                "lat": 35.9094,
                "lng": -81.5249
            },
            "Panama City Beach": {
                "lat": 30.237,
                "lng": -85.8776
            },
            "Pinecrest": {
                "lat": 25.665,
                "lng": -80.3042
            },
            "Palos Hills": {
                "lat": 41.6986,
                "lng": -87.8266
            },
            "Phelan": {
                "lat": 34.4398,
                "lng": -117.5248
            },
            "South Orange Village": {
                "lat": 40.7491,
                "lng": -74.2602
            },
            "Amsterdam": {
                "lat": 42.942,
                "lng": -74.1906
            },
            "Centralia": {
                "lat": 38.5224,
                "lng": -89.1232
            },
            "Ocean Acres": {
                "lat": 39.743,
                "lng": -74.2805
            },
            "Kingstowne": {
                "lat": 38.7625,
                "lng": -77.1444
            },
            "North Massapequa": {
                "lat": 40.7031,
                "lng": -73.4678
            },
            "Oconomowoc": {
                "lat": 43.0996,
                "lng": -88.4948
            },
            "Steubenville": {
                "lat": 40.3653,
                "lng": -80.6519
            },
            "South Fayette": {
                "lat": 40.3556,
                "lng": -80.1617
            },
            "Sycamore": {
                "lat": 41.9957,
                "lng": -88.6821
            },
            "Logansport": {
                "lat": 40.7472,
                "lng": -86.352
            },
            "Colonial Heights": {
                "lat": 37.265,
                "lng": -77.3969
            },
            "Schofield Barracks": {
                "lat": 21.4936,
                "lng": -158.0617
            },
            "Canby": {
                "lat": 45.2652,
                "lng": -122.6867
            },
            "Bourbonnais": {
                "lat": 41.183,
                "lng": -87.8784
            },
            "Menasha": {
                "lat": 44.2125,
                "lng": -88.4271
            },
            "McAlester": {
                "lat": 34.9257,
                "lng": -95.7734
            },
            "Shorewood": {
                "lat": 43.0913,
                "lng": -87.8864
            },
            "White Settlement": {
                "lat": 32.7554,
                "lng": -97.4605
            },
            "Fort Carson": {
                "lat": 38.7095,
                "lng": -104.772
            },
            "Hybla Valley": {
                "lat": 38.7484,
                "lng": -77.0821
            },
            "Southeast": {
                "lat": 41.4032,
                "lng": -73.5985
            },
            "East Riverdale": {
                "lat": 38.96,
                "lng": -76.9109
            },
            "Scarsdale": {
                "lat": 40.9902,
                "lng": -73.7773
            },
            "Spanish Lake": {
                "lat": 38.7884,
                "lng": -90.2077
            },
            "Whitestown": {
                "lat": 39.9706,
                "lng": -86.3612
            },
            "Bryn Mawr-Skyway": {
                "lat": 47.4949,
                "lng": -122.241
            },
            "Tillmans Corner": {
                "lat": 30.5819,
                "lng": -88.2128
            },
            "Dumont": {
                "lat": 40.9452,
                "lng": -73.9923
            },
            "Nipomo": {
                "lat": 35.0319,
                "lng": -120.4985
            },
            "Ashtabula": {
                "lat": 41.8805,
                "lng": -80.7984
            },
            "Terrell": {
                "lat": 32.734,
                "lng": -96.2931
            },
            "Gorham": {
                "lat": 43.7034,
                "lng": -70.4581
            },
            "Cameron Park": {
                "lat": 38.6738,
                "lng": -120.9872
            },
            "Wantagh": {
                "lat": 40.6686,
                "lng": -73.5104
            },
            "Mount Washington": {
                "lat": 38.043,
                "lng": -85.5551
            },
            "Sevierville": {
                "lat": 35.8872,
                "lng": -83.5678
            },
            "Cohoes": {
                "lat": 42.7732,
                "lng": -73.7077
            },
            "Towamencin": {
                "lat": 40.2417,
                "lng": -75.3387
            },
            "Blythe": {
                "lat": 33.6219,
                "lng": -114.6195
            },
            "Doylestown": {
                "lat": 40.2962,
                "lng": -75.1393
            },
            "Burke Centre": {
                "lat": 38.7903,
                "lng": -77.2999
            },
            "Ammon": {
                "lat": 43.4745,
                "lng": -111.9568
            },
            "Alice": {
                "lat": 27.7556,
                "lng": -98.0653
            },
            "Natchitoches": {
                "lat": 31.7315,
                "lng": -93.0975
            },
            "Franconia": {
                "lat": 40.3055,
                "lng": -75.359
            },
            "Glenvar Heights": {
                "lat": 25.709,
                "lng": -80.3155
            },
            "Mebane": {
                "lat": 36.0852,
                "lng": -79.2736
            },
            "Tiffin": {
                "lat": 41.1165,
                "lng": -83.1805
            },
            "Anoka": {
                "lat": 45.2099,
                "lng": -93.3893
            },
            "Steiner Ranch": {
                "lat": 30.3654,
                "lng": -97.896
            },
            "Gibsonton": {
                "lat": 27.826,
                "lng": -82.3761
            },
            "Pataskala": {
                "lat": 40.011,
                "lng": -82.7155
            },
            "Delran": {
                "lat": 40.017,
                "lng": -74.9495
            },
            "Godfrey": {
                "lat": 38.9577,
                "lng": -90.2156
            },
            "Idylwood": {
                "lat": 38.8896,
                "lng": -77.2056
            },
            "North Canton": {
                "lat": 40.8742,
                "lng": -81.3971
            },
            "Lake Butler": {
                "lat": 28.4862,
                "lng": -81.546
            },
            "Willimantic": {
                "lat": 41.7171,
                "lng": -72.2114
            },
            "Cahokia Heights": {
                "lat": 38.571,
                "lng": -90.1525
            },
            "North Aurora": {
                "lat": 41.8086,
                "lng": -88.3414
            },
            "McKeesport": {
                "lat": 40.3418,
                "lng": -79.844
            },
            "Rockland": {
                "lat": 42.1295,
                "lng": -70.91
            },
            "Live Oak": {
                "lat": 29.5545,
                "lng": -98.3404
            },
            "East Lampeter": {
                "lat": 40.0375,
                "lng": -76.2162
            },
            "Hope Mills": {
                "lat": 34.971,
                "lng": -78.9597
            },
            "Southbridge": {
                "lat": 42.0604,
                "lng": -72.0338
            },
            "Anacortes": {
                "lat": 48.4878,
                "lng": -122.6292
            },
            "Brenham": {
                "lat": 30.1584,
                "lng": -96.3966
            },
            "Pinehurst": {
                "lat": 35.1922,
                "lng": -79.4684
            },
            "Mount Holly": {
                "lat": 39.995,
                "lng": -74.7863
            },
            "Camano": {
                "lat": 48.1865,
                "lng": -122.4708
            },
            "Gretna": {
                "lat": 41.1314,
                "lng": -96.245
            },
            "Stuart": {
                "lat": 27.1959,
                "lng": -80.2444
            },
            "Siloam Springs": {
                "lat": 36.1844,
                "lng": -94.5315
            },
            "Woodmere": {
                "lat": 29.8493,
                "lng": -90.0751
            },
            "Eastlake": {
                "lat": 41.6581,
                "lng": -81.4322
            },
            "El Dorado": {
                "lat": 37.821,
                "lng": -96.8613
            },
            "Storrs": {
                "lat": 41.8083,
                "lng": -72.2514
            },
            "New Philadelphia": {
                "lat": 40.486,
                "lng": -81.4401
            },
            "Zephyrhills": {
                "lat": 28.2409,
                "lng": -82.1797
            },
            "Cortland": {
                "lat": 42.6004,
                "lng": -76.1784
            },
            "Lemont": {
                "lat": 41.6695,
                "lng": -87.9838
            },
            "Clemson": {
                "lat": 34.6837,
                "lng": -82.8124
            },
            "Penn": {
                "lat": 40.1864,
                "lng": -76.3726
            },
            "Colchester": {
                "lat": 44.5545,
                "lng": -73.2168
            },
            "Takoma Park": {
                "lat": 38.981,
                "lng": -77.0028
            },
            "Oak Grove": {
                "lat": 45.3409,
                "lng": -93.3264
            },
            "Kirksville": {
                "lat": 40.1986,
                "lng": -92.5752
            },
            "Norcross": {
                "lat": 33.9379,
                "lng": -84.2065
            },
            "St. Matthews": {
                "lat": 38.2497,
                "lng": -85.6384
            },
            "Goodlettsville": {
                "lat": 36.333,
                "lng": -86.7026
            },
            "Scott": {
                "lat": 40.3875,
                "lng": -80.0791
            },
            "Coalinga": {
                "lat": 36.142,
                "lng": -120.3242
            },
            "South Ogden": {
                "lat": 41.1722,
                "lng": -111.9576
            },
            "El Reno": {
                "lat": 35.5429,
                "lng": -97.966
            },
            "Fairview": {
                "lat": 35.9815,
                "lng": -87.1291
            },
            "South Venice": {
                "lat": 27.0444,
                "lng": -82.4155
            },
            "Bluffdale": {
                "lat": 40.4744,
                "lng": -111.9381
            },
            "West Columbia": {
                "lat": 33.9932,
                "lng": -81.0936
            },
            "Morganton": {
                "lat": 35.7408,
                "lng": -81.7002
            },
            "Los Lunas": {
                "lat": 34.8115,
                "lng": -106.7803
            },
            "Laguna Woods": {
                "lat": 33.6099,
                "lng": -117.7299
            },
            "Bon Air": {
                "lat": 37.5187,
                "lng": -77.5713
            },
            "Olivehurst": {
                "lat": 39.0795,
                "lng": -121.5566
            },
            "Conyers": {
                "lat": 33.6646,
                "lng": -83.9966
            },
            "Wahiawa": {
                "lat": 21.5012,
                "lng": -158.0178
            },
            "Wilton": {
                "lat": 43.1502,
                "lng": -73.7276
            },
            "Streetsboro": {
                "lat": 41.2396,
                "lng": -81.3456
            },
            "Woodcrest": {
                "lat": 33.8789,
                "lng": -117.3687
            },
            "Holmdel": {
                "lat": 40.3768,
                "lng": -74.1725
            },
            "Villa Rica": {
                "lat": 33.7294,
                "lng": -84.9177
            },
            "Glassmanor": {
                "lat": 38.8181,
                "lng": -76.9833
            },
            "Jenison": {
                "lat": 42.9063,
                "lng": -85.8269
            },
            "Fort Thomas": {
                "lat": 39.0801,
                "lng": -84.4518
            },
            "West Lampeter": {
                "lat": 39.9947,
                "lng": -76.256
            },
            "Bethpage": {
                "lat": 40.7495,
                "lng": -73.4856
            },
            "Amesbury": {
                "lat": 42.853,
                "lng": -70.9446
            },
            "Hernando": {
                "lat": 28.9451,
                "lng": -82.3781
            },
            "Rancho Mirage": {
                "lat": 33.7635,
                "lng": -116.4271
            },
            "Stanford": {
                "lat": 37.4252,
                "lng": -122.1674
            },
            "North Druid Hills": {
                "lat": 33.8185,
                "lng": -84.3254
            },
            "Buford": {
                "lat": 34.1192,
                "lng": -83.9903
            },
            "Avenel": {
                "lat": 40.5842,
                "lng": -74.2716
            },
            "Flowing Wells": {
                "lat": 32.2937,
                "lng": -111.011
            },
            "Calhoun": {
                "lat": 34.491,
                "lng": -84.9386
            },
            "North Decatur": {
                "lat": 33.8074,
                "lng": -84.2889
            },
            "Hinsdale": {
                "lat": 41.8007,
                "lng": -87.9273
            },
            "Bellaire": {
                "lat": 29.704,
                "lng": -95.4621
            },
            "Pinewood": {
                "lat": 25.8697,
                "lng": -80.2174
            },
            "Malta": {
                "lat": 42.9853,
                "lng": -73.7879
            },
            "Swansea": {
                "lat": 38.5507,
                "lng": -89.9858
            },
            "Fairview Park": {
                "lat": 41.4419,
                "lng": -81.853
            },
            "Beckley": {
                "lat": 37.7877,
                "lng": -81.1841
            },
            "Manassas Park": {
                "lat": 38.7709,
                "lng": -77.4429
            },
            "Barrington": {
                "lat": 43.2139,
                "lng": -71.0424
            },
            "South Hadley": {
                "lat": 42.2567,
                "lng": -72.5793
            },
            "Kaukauna": {
                "lat": 44.2773,
                "lng": -88.2641
            },
            "Groves": {
                "lat": 29.9457,
                "lng": -93.9164
            },
            "Estelle": {
                "lat": 29.8447,
                "lng": -90.1021
            },
            "Lake St. Louis": {
                "lat": 38.7846,
                "lng": -90.7886
            },
            "Safety Harbor": {
                "lat": 28.008,
                "lng": -82.6964
            },
            "Denville": {
                "lat": 40.889,
                "lng": -74.4893
            },
            "El Segundo": {
                "lat": 33.917,
                "lng": -118.4018
            },
            "Cinnaminson": {
                "lat": 40.0008,
                "lng": -74.993
            },
            "Upper Grand Lagoon": {
                "lat": 30.169,
                "lng": -85.7407
            },
            "Ca\u00f1on City": {
                "lat": 38.443,
                "lng": -105.2203
            },
            "Tifton": {
                "lat": 31.4624,
                "lng": -83.5203
            },
            "West Hempfield": {
                "lat": 40.0564,
                "lng": -76.4632
            },
            "Massapequa Park": {
                "lat": 40.6817,
                "lng": -73.4496
            },
            "Milledgeville": {
                "lat": 33.0874,
                "lng": -83.2414
            },
            "Port Washington": {
                "lat": 43.3846,
                "lng": -87.8855
            },
            "Wixom": {
                "lat": 42.5243,
                "lng": -83.5346
            },
            "Upper Gwynedd": {
                "lat": 40.2144,
                "lng": -75.2891
            },
            "Pendleton": {
                "lat": 45.6757,
                "lng": -118.8201
            },
            "Hannibal": {
                "lat": 39.7098,
                "lng": -91.3939
            },
            "Geddes": {
                "lat": 43.0762,
                "lng": -76.2252
            },
            "Myrtle Grove": {
                "lat": 34.123,
                "lng": -77.8834
            },
            "Upper Saucon": {
                "lat": 40.5364,
                "lng": -75.4084
            },
            "Adelphi": {
                "lat": 39.0017,
                "lng": -76.9649
            },
            "Heber": {
                "lat": 40.507,
                "lng": -111.3986
            },
            "Hampton Bays": {
                "lat": 40.8695,
                "lng": -72.5225
            },
            "Bridgeview": {
                "lat": 41.7403,
                "lng": -87.8067
            },
            "Ashwaubenon": {
                "lat": 44.4796,
                "lng": -88.0889
            },
            "Powder Springs": {
                "lat": 33.8659,
                "lng": -84.684
            },
            "McKinleyville": {
                "lat": 40.9488,
                "lng": -124.0857
            },
            "Laconia": {
                "lat": 43.5725,
                "lng": -71.4775
            },
            "Belle Glade": {
                "lat": 26.6916,
                "lng": -80.6656
            },
            "Colonial Park": {
                "lat": 40.2987,
                "lng": -76.8069
            },
            "Farmingville": {
                "lat": 40.839,
                "lng": -73.0404
            },
            "New Milford": {
                "lat": 40.9337,
                "lng": -74.0196
            },
            "Pampa": {
                "lat": 35.5479,
                "lng": -100.9651
            },
            "Moraga": {
                "lat": 37.8439,
                "lng": -122.1225
            },
            "Defiance": {
                "lat": 41.2813,
                "lng": -84.3657
            },
            "Bostonia": {
                "lat": 32.8189,
                "lng": -116.948
            },
            "Damascus": {
                "lat": 45.4233,
                "lng": -122.4436
            },
            "Hot Springs Village": {
                "lat": 34.6566,
                "lng": -92.9643
            },
            "Mattoon": {
                "lat": 39.4774,
                "lng": -88.3623
            },
            "Upper Chichester": {
                "lat": 39.8414,
                "lng": -75.4421
            },
            "Washougal": {
                "lat": 45.5825,
                "lng": -122.3451
            },
            "Wyckoff": {
                "lat": 40.9989,
                "lng": -74.1676
            },
            "Weehawken": {
                "lat": 40.7677,
                "lng": -74.0168
            },
            "Truckee": {
                "lat": 39.3455,
                "lng": -120.1848
            },
            "Palmetto Estates": {
                "lat": 25.6211,
                "lng": -80.3616
            },
            "Sunland Park": {
                "lat": 31.8201,
                "lng": -106.5958
            },
            "Havelock": {
                "lat": 34.9078,
                "lng": -76.8987
            },
            "Chestnuthill": {
                "lat": 40.9568,
                "lng": -75.4183
            },
            "Donna": {
                "lat": 26.1468,
                "lng": -98.0559
            },
            "New Port Richey": {
                "lat": 28.2468,
                "lng": -82.717
            },
            "Kings Park": {
                "lat": 40.8881,
                "lng": -73.2475
            },
            "Vincennes": {
                "lat": 38.6759,
                "lng": -87.5102
            },
            "Aston": {
                "lat": 39.8719,
                "lng": -75.4349
            },
            "La Vista": {
                "lat": 41.1816,
                "lng": -96.0664
            },
            "Firestone": {
                "lat": 40.1565,
                "lng": -104.9494
            },
            "Richmond Hill": {
                "lat": 31.9012,
                "lng": -81.3125
            },
            "East Greenbush": {
                "lat": 42.6122,
                "lng": -73.6969
            },
            "Harborcreek": {
                "lat": 42.1498,
                "lng": -79.9501
            },
            "Murraysville": {
                "lat": 34.2919,
                "lng": -77.8429
            },
            "Hueytown": {
                "lat": 33.4239,
                "lng": -87.022
            },
            "Beaver Dam": {
                "lat": 43.4688,
                "lng": -88.8309
            },
            "Calera": {
                "lat": 33.1254,
                "lng": -86.7449
            },
            "Lake Mary": {
                "lat": 28.7592,
                "lng": -81.336
            },
            "Glenmont": {
                "lat": 39.0698,
                "lng": -77.0467
            },
            "Millbrook": {
                "lat": 32.5027,
                "lng": -86.3737
            },
            "Red Wing": {
                "lat": 44.5817,
                "lng": -92.6036
            },
            "Bull Run": {
                "lat": 38.7802,
                "lng": -77.5204
            },
            "Clearlake": {
                "lat": 38.9589,
                "lng": -122.633
            },
            "Morton": {
                "lat": 40.6135,
                "lng": -89.4669
            },
            "Three Lakes": {
                "lat": 25.6415,
                "lng": -80.4
            },
            "Rio Linda": {
                "lat": 38.6875,
                "lng": -121.4417
            },
            "Wailuku": {
                "lat": 20.8834,
                "lng": -156.5059
            },
            "Country Club Hills": {
                "lat": 41.5636,
                "lng": -87.7251
            },
            "Humble": {
                "lat": 29.9921,
                "lng": -95.2655
            },
            "Menomonie": {
                "lat": 44.8893,
                "lng": -91.9084
            },
            "West Richland": {
                "lat": 46.3115,
                "lng": -119.3999
            },
            "Ada": {
                "lat": 34.7662,
                "lng": -96.6681
            },
            "Vero Beach": {
                "lat": 27.6463,
                "lng": -80.393
            },
            "Thompson": {
                "lat": 41.6474,
                "lng": -74.6745
            },
            "East Highland Park": {
                "lat": 37.577,
                "lng": -77.3865
            },
            "Ukiah": {
                "lat": 39.1464,
                "lng": -123.2105
            },
            "Ham Lake": {
                "lat": 45.2545,
                "lng": -93.2039
            },
            "Jasper": {
                "lat": 33.8508,
                "lng": -87.271
            },
            "Hillcrest Heights": {
                "lat": 38.8373,
                "lng": -76.9641
            },
            "Warsaw": {
                "lat": 41.2448,
                "lng": -85.8465
            },
            "Fairburn": {
                "lat": 33.5496,
                "lng": -84.5914
            },
            "Lake Wales": {
                "lat": 27.9195,
                "lng": -81.5961
            },
            "Fillmore": {
                "lat": 34.3989,
                "lng": -118.9174
            },
            "Ojus": {
                "lat": 25.9563,
                "lng": -80.1606
            },
            "Glenn Heights": {
                "lat": 32.5506,
                "lng": -96.8548
            },
            "Albemarle": {
                "lat": 35.3594,
                "lng": -80.1915
            },
            "Cloverly": {
                "lat": 39.1064,
                "lng": -76.9995
            },
            "Auburndale": {
                "lat": 28.0963,
                "lng": -81.8012
            },
            "Pearl River": {
                "lat": 41.0615,
                "lng": -74.0047
            },
            "Spanish Springs": {
                "lat": 39.6568,
                "lng": -119.6694
            },
            "Hilltown": {
                "lat": 40.3415,
                "lng": -75.2534
            },
            "Hanover": {
                "lat": 43.7156,
                "lng": -72.191
            },
            "Dyer": {
                "lat": 41.4977,
                "lng": -87.509
            },
            "Vienna": {
                "lat": 39.324,
                "lng": -81.5383
            },
            "Dentsville": {
                "lat": 34.0754,
                "lng": -80.9547
            },
            "Tahlequah": {
                "lat": 35.9112,
                "lng": -94.977
            },
            "Hollins": {
                "lat": 37.3434,
                "lng": -79.9534
            },
            "Radford": {
                "lat": 37.1229,
                "lng": -80.5587
            },
            "Moreau": {
                "lat": 43.2469,
                "lng": -73.6659
            },
            "East Longmeadow": {
                "lat": 42.0597,
                "lng": -72.499
            },
            "Mercedes": {
                "lat": 26.1533,
                "lng": -97.9128
            },
            "Fairview Heights": {
                "lat": 38.5974,
                "lng": -90.0053
            },
            "North Arlington": {
                "lat": 40.7874,
                "lng": -74.1273
            },
            "Grosse Pointe Woods": {
                "lat": 42.4366,
                "lng": -82.8987
            },
            "Crawfordsville": {
                "lat": 40.0428,
                "lng": -86.8976
            },
            "Muscle Shoals": {
                "lat": 34.7432,
                "lng": -87.6343
            },
            "Mount Dora": {
                "lat": 28.8142,
                "lng": -81.6344
            },
            "Westport": {
                "lat": 41.5886,
                "lng": -71.0837
            },
            "Sunnyside": {
                "lat": 46.3157,
                "lng": -120.0058
            },
            "Tiverton": {
                "lat": 41.609,
                "lng": -71.1741
            },
            "Poplar Bluff": {
                "lat": 36.7632,
                "lng": -90.4135
            },
            "Northbridge": {
                "lat": 42.13,
                "lng": -71.6547
            },
            "Douglas": {
                "lat": 42.0524,
                "lng": -71.7515
            },
            "Fort Drum": {
                "lat": 44.045,
                "lng": -75.7845
            },
            "Welby": {
                "lat": 39.8403,
                "lng": -104.9655
            },
            "Center Point": {
                "lat": 33.6447,
                "lng": -86.6852
            },
            "Glen Allen": {
                "lat": 37.666,
                "lng": -77.4838
            },
            "Parkway": {
                "lat": 38.4993,
                "lng": -121.452
            },
            "Artesia": {
                "lat": 32.8497,
                "lng": -104.4268
            },
            "Chickasha": {
                "lat": 35.0409,
                "lng": -97.9474
            },
            "Opa-locka": {
                "lat": 25.8997,
                "lng": -80.2551
            },
            "South Middleton": {
                "lat": 40.1324,
                "lng": -77.1642
            },
            "Hermitage": {
                "lat": 41.2305,
                "lng": -80.4413
            },
            "Lemay": {
                "lat": 38.5325,
                "lng": -90.2845
            },
            "Walnut Park": {
                "lat": 33.9682,
                "lng": -118.222
            },
            "Burrillville": {
                "lat": 41.9706,
                "lng": -71.6984
            },
            "Stallings": {
                "lat": 35.1088,
                "lng": -80.6598
            },
            "Mastic Beach": {
                "lat": 40.7664,
                "lng": -72.8369
            },
            "Griffith": {
                "lat": 41.5277,
                "lng": -87.4239
            },
            "West Norriton": {
                "lat": 40.1308,
                "lng": -75.3794
            },
            "Readington": {
                "lat": 40.5822,
                "lng": -74.7796
            },
            "Ocean Pointe": {
                "lat": 21.3145,
                "lng": -158.0289
            },
            "Dyersburg": {
                "lat": 36.0465,
                "lng": -89.3777
            },
            "South Houston": {
                "lat": 29.6611,
                "lng": -95.2285
            },
            "Hibbing": {
                "lat": 47.398,
                "lng": -92.9486
            },
            "River Falls": {
                "lat": 44.8609,
                "lng": -92.6247
            },
            "Four Corners": {
                "lat": 29.6705,
                "lng": -95.6596
            },
            "Troutdale": {
                "lat": 45.5372,
                "lng": -122.3955
            },
            "Westwood": {
                "lat": 42.3031,
                "lng": -85.6286
            },
            "Sikeston": {
                "lat": 36.8854,
                "lng": -89.5877
            },
            "Sayville": {
                "lat": 40.7478,
                "lng": -73.084
            },
            "Easthampton": {
                "lat": 42.2651,
                "lng": -72.672
            },
            "Forks": {
                "lat": 40.7358,
                "lng": -75.2211
            },
            "La Grange": {
                "lat": 38.3987,
                "lng": -85.375
            },
            "Saraland": {
                "lat": 30.8479,
                "lng": -88.1004
            },
            "Ripon": {
                "lat": 37.7417,
                "lng": -121.131
            },
            "South River": {
                "lat": 40.4455,
                "lng": -74.3783
            },
            "Eloy": {
                "lat": 32.747,
                "lng": -111.5992
            },
            "Middle Smithfield": {
                "lat": 41.0918,
                "lng": -75.1031
            },
            "North Fayette": {
                "lat": 40.4204,
                "lng": -80.2245
            },
            "Dickson": {
                "lat": 36.0637,
                "lng": -87.3665
            },
            "Gardendale": {
                "lat": 33.6677,
                "lng": -86.8069
            },
            "Grovetown": {
                "lat": 33.4503,
                "lng": -82.2073
            },
            "Stone Ridge": {
                "lat": 38.9294,
                "lng": -77.5557
            },
            "Lady Lake": {
                "lat": 28.9241,
                "lng": -81.9299
            },
            "Bay Village": {
                "lat": 41.4851,
                "lng": -81.9317
            },
            "Duxbury": {
                "lat": 42.0465,
                "lng": -70.7139
            },
            "Fort Mohave": {
                "lat": 35.0004,
                "lng": -114.5748
            },
            "Hewitt": {
                "lat": 31.452,
                "lng": -97.196
            },
            "Sulphur Springs": {
                "lat": 33.1421,
                "lng": -95.6122
            },
            "Mountain Home": {
                "lat": 36.3351,
                "lng": -92.384
            },
            "Azalea Park": {
                "lat": 28.5473,
                "lng": -81.2956
            },
            "Americus": {
                "lat": 32.0736,
                "lng": -84.2248
            },
            "Lithia Springs": {
                "lat": 33.7811,
                "lng": -84.6487
            },
            "Grandville": {
                "lat": 42.9004,
                "lng": -85.7564
            },
            "Fort Hunt": {
                "lat": 38.7361,
                "lng": -77.0589
            },
            "Youngsville": {
                "lat": 30.0963,
                "lng": -91.9968
            },
            "The Dalles": {
                "lat": 45.6053,
                "lng": -121.1818
            },
            "St. Simons": {
                "lat": 31.1775,
                "lng": -81.3857
            },
            "Kerman": {
                "lat": 36.7248,
                "lng": -120.0625
            },
            "Viera West": {
                "lat": 28.2467,
                "lng": -80.7387
            },
            "Coos Bay": {
                "lat": 43.3789,
                "lng": -124.233
            },
            "Marco Island": {
                "lat": 25.933,
                "lng": -81.6993
            },
            "Summerfield": {
                "lat": 36.1973,
                "lng": -79.8997
            },
            "Bellmore": {
                "lat": 40.6569,
                "lng": -73.5285
            },
            "Middleburg Heights": {
                "lat": 41.3696,
                "lng": -81.815
            },
            "Alpine": {
                "lat": 40.4629,
                "lng": -111.7724
            },
            "Lower Salford": {
                "lat": 40.2639,
                "lng": -75.3929
            },
            "Fairhaven": {
                "lat": 41.6394,
                "lng": -70.8732
            },
            "Floral Park": {
                "lat": 40.7227,
                "lng": -73.7029
            },
            "Highland Village": {
                "lat": 33.0897,
                "lng": -97.0615
            },
            "Prospect Heights": {
                "lat": 42.1039,
                "lng": -87.9267
            },
            "Seaford": {
                "lat": 40.6678,
                "lng": -73.4922
            },
            "Gatesville": {
                "lat": 31.4445,
                "lng": -97.7317
            },
            "Indianola": {
                "lat": 33.4492,
                "lng": -90.6447
            },
            "Overland": {
                "lat": 38.6966,
                "lng": -90.3689
            },
            "Hugo": {
                "lat": 45.1671,
                "lng": -92.9588
            },
            "Elkton": {
                "lat": 39.6066,
                "lng": -75.8209
            },
            "Seven Oaks": {
                "lat": 34.0475,
                "lng": -81.1435
            },
            "Port Orchard": {
                "lat": 47.5163,
                "lng": -122.661
            },
            "Rutland": {
                "lat": 42.3848,
                "lng": -71.9673
            },
            "Pewaukee": {
                "lat": 43.0701,
                "lng": -88.2412
            },
            "Opelousas": {
                "lat": 30.5252,
                "lng": -92.0816
            },
            "Niceville": {
                "lat": 30.529,
                "lng": -86.4748
            },
            "Victor": {
                "lat": 42.9894,
                "lng": -77.4277
            },
            "Antrim": {
                "lat": 39.7862,
                "lng": -77.7221
            },
            "Longmeadow": {
                "lat": 42.0475,
                "lng": -72.5718
            },
            "Westbury": {
                "lat": 40.7599,
                "lng": -73.5891
            },
            "Waterville": {
                "lat": 44.5441,
                "lng": -69.6624
            },
            "Patton": {
                "lat": 40.8258,
                "lng": -77.9237
            },
            "Thibodaux": {
                "lat": 29.7949,
                "lng": -90.8146
            },
            "Susanville": {
                "lat": 40.4206,
                "lng": -120.6132
            },
            "Whitewater": {
                "lat": 42.8372,
                "lng": -88.7341
            },
            "Lynden": {
                "lat": 48.9502,
                "lng": -122.4545
            },
            "North Strabane": {
                "lat": 40.2279,
                "lng": -80.1488
            },
            "Newberry": {
                "lat": 34.2813,
                "lng": -81.601
            },
            "Wolf Trap": {
                "lat": 38.9395,
                "lng": -77.2842
            },
            "Hayden": {
                "lat": 47.768,
                "lng": -116.804
            },
            "North Whitehall": {
                "lat": 40.6797,
                "lng": -75.5788
            },
            "Southern Pines": {
                "lat": 35.1927,
                "lng": -79.4039
            },
            "Grain Valley": {
                "lat": 39.0171,
                "lng": -94.2084
            },
            "Mount Clemens": {
                "lat": 42.5977,
                "lng": -82.8821
            },
            "Mitchell": {
                "lat": 43.7294,
                "lng": -98.0337
            },
            "Northborough": {
                "lat": 42.3231,
                "lng": -71.6462
            },
            "Chubbuck": {
                "lat": 42.9263,
                "lng": -112.4623
            },
            "Coto de Caza": {
                "lat": 33.5959,
                "lng": -117.586
            },
            "Longwood": {
                "lat": 28.7014,
                "lng": -81.3487
            },
            "Fort Leonard Wood": {
                "lat": 37.7562,
                "lng": -92.1274
            },
            "Shively": {
                "lat": 38.197,
                "lng": -85.8136
            },
            "Cumru": {
                "lat": 40.2811,
                "lng": -75.9544
            },
            "Princeton Meadows": {
                "lat": 40.3347,
                "lng": -74.5651
            },
            "Red Hill": {
                "lat": 33.7777,
                "lng": -79.0111
            },
            "Southchase": {
                "lat": 28.3793,
                "lng": -81.3903
            },
            "Pequannock": {
                "lat": 40.9627,
                "lng": -74.3041
            },
            "Scottsboro": {
                "lat": 34.6438,
                "lng": -86.0491
            },
            "Greenwood Village": {
                "lat": 39.6153,
                "lng": -104.9131
            },
            "North Reading": {
                "lat": 42.5816,
                "lng": -71.0876
            },
            "Los Osos": {
                "lat": 35.3065,
                "lng": -120.8242
            },
            "Rendon": {
                "lat": 32.5789,
                "lng": -97.2349
            },
            "La Palma": {
                "lat": 33.8504,
                "lng": -118.0407
            },
            "Aldine": {
                "lat": 29.9123,
                "lng": -95.3784
            },
            "Rossville": {
                "lat": 39.3572,
                "lng": -76.4767
            },
            "Cherryland": {
                "lat": 37.6792,
                "lng": -122.1038
            },
            "Carthage": {
                "lat": 37.1503,
                "lng": -94.3225
            },
            "White Center": {
                "lat": 47.5086,
                "lng": -122.3479
            },
            "Seekonk": {
                "lat": 41.8379,
                "lng": -71.3174
            },
            "Traverse City": {
                "lat": 44.7546,
                "lng": -85.6038
            },
            "Greeneville": {
                "lat": 36.168,
                "lng": -82.8197
            },
            "Bemidji": {
                "lat": 47.4828,
                "lng": -94.8797
            },
            "Northview": {
                "lat": 43.0427,
                "lng": -85.6016
            },
            "Yankton": {
                "lat": 42.8901,
                "lng": -97.3927
            },
            "Clark": {
                "lat": 40.6203,
                "lng": -74.3134
            },
            "Hartselle": {
                "lat": 34.4391,
                "lng": -86.9396
            },
            "Robbinsville": {
                "lat": 40.222,
                "lng": -74.591
            },
            "Glasgow": {
                "lat": 37.0048,
                "lng": -85.9263
            },
            "Grimes": {
                "lat": 41.6779,
                "lng": -93.7947
            },
            "Bradley": {
                "lat": 41.1641,
                "lng": -87.8452
            },
            "Robinson": {
                "lat": 31.4501,
                "lng": -97.1201
            },
            "Homosassa Springs": {
                "lat": 28.8119,
                "lng": -82.5392
            },
            "Discovery Bay": {
                "lat": 37.9063,
                "lng": -121.599
            },
            "Eden": {
                "lat": 36.5027,
                "lng": -79.7412
            },
            "Uvalde": {
                "lat": 29.2152,
                "lng": -99.7782
            },
            "Bennington": {
                "lat": 42.8854,
                "lng": -73.2133
            },
            "Fruitville": {
                "lat": 27.3328,
                "lng": -82.4616
            },
            "Haddon": {
                "lat": 39.9063,
                "lng": -75.0625
            },
            "White": {
                "lat": 40.621,
                "lng": -79.1513
            },
            "Belchertown": {
                "lat": 42.2788,
                "lng": -72.4004
            },
            "Tenafly": {
                "lat": 40.9176,
                "lng": -73.9532
            },
            "Ladson": {
                "lat": 33.0093,
                "lng": -80.1078
            },
            "Harper Woods": {
                "lat": 42.439,
                "lng": -82.9293
            },
            "Norridge": {
                "lat": 41.9637,
                "lng": -87.8231
            },
            "Chaparral": {
                "lat": 32.0442,
                "lng": -106.406
            },
            "Mantua": {
                "lat": 39.7618,
                "lng": -75.1686
            },
            "Palmer Ranch": {
                "lat": 27.2286,
                "lng": -82.4672
            },
            "Vincent": {
                "lat": 34.0983,
                "lng": -117.9238
            },
            "Rio Grande City": {
                "lat": 26.3808,
                "lng": -98.8215
            },
            "Vail": {
                "lat": 32.0217,
                "lng": -110.6937
            },
            "Palmview": {
                "lat": 26.2318,
                "lng": -98.3823
            },
            "Asbury Park": {
                "lat": 40.2226,
                "lng": -74.0119
            },
            "Melissa": {
                "lat": 33.2891,
                "lng": -96.5573
            },
            "Glenn Dale": {
                "lat": 38.9833,
                "lng": -76.804
            },
            "Wilmington Island": {
                "lat": 32.0033,
                "lng": -80.9752
            },
            "Upper Southampton": {
                "lat": 40.1723,
                "lng": -75.0363
            },
            "Macomb": {
                "lat": 40.4709,
                "lng": -90.6807
            },
            "Phillipsburg": {
                "lat": 40.6894,
                "lng": -75.1821
            },
            "Davidson": {
                "lat": 35.484,
                "lng": -80.8247
            },
            "Beech Grove": {
                "lat": 39.7157,
                "lng": -86.0871
            },
            "Gulf Shores": {
                "lat": 30.2764,
                "lng": -87.7017
            },
            "Greenlawn": {
                "lat": 40.8632,
                "lng": -73.3646
            },
            "Ewa Beach": {
                "lat": 21.3181,
                "lng": -158.0073
            },
            "Berkley": {
                "lat": 39.8045,
                "lng": -105.0281
            },
            "Whitman": {
                "lat": 42.08,
                "lng": -70.9399
            },
            "Mashpee": {
                "lat": 41.6178,
                "lng": -70.4908
            },
            "Roanoke Rapids": {
                "lat": 36.4452,
                "lng": -77.649
            },
            "Depew": {
                "lat": 42.9118,
                "lng": -78.7043
            },
            "Vandalia": {
                "lat": 39.8791,
                "lng": -84.193
            },
            "New Cassel": {
                "lat": 40.7602,
                "lng": -73.5649
            },
            "Swampscott": {
                "lat": 42.4757,
                "lng": -70.9068
            },
            "Raynham": {
                "lat": 41.9312,
                "lng": -71.0437
            },
            "Gloversville": {
                "lat": 43.0491,
                "lng": -74.3465
            },
            "Pacific Grove": {
                "lat": 36.6188,
                "lng": -121.9259
            },
            "Tonawanda": {
                "lat": 43.0105,
                "lng": -78.8805
            },
            "Canyon": {
                "lat": 34.9911,
                "lng": -101.919
            },
            "Eggertsville": {
                "lat": 42.9665,
                "lng": -78.8065
            },
            "West Park": {
                "lat": 25.984,
                "lng": -80.1923
            },
            "Talladega": {
                "lat": 33.4333,
                "lng": -86.0986
            },
            "Hazel Park": {
                "lat": 42.4619,
                "lng": -83.0977
            },
            "Front Royal": {
                "lat": 38.926,
                "lng": -78.1838
            },
            "Spring Creek": {
                "lat": 40.745,
                "lng": -115.5983
            },
            "Royse City": {
                "lat": 32.9762,
                "lng": -96.3175
            },
            "Mineral Wells": {
                "lat": 32.8169,
                "lng": -98.0776
            },
            "South Farmingdale": {
                "lat": 40.7175,
                "lng": -73.4473
            },
            "Lockhart": {
                "lat": 29.8785,
                "lng": -97.6831
            },
            "Potsdam": {
                "lat": 44.6774,
                "lng": -75.0396
            },
            "California City": {
                "lat": 35.1578,
                "lng": -117.8721
            },
            "Worthington": {
                "lat": 43.6281,
                "lng": -95.599
            },
            "Buda": {
                "lat": 30.0758,
                "lng": -97.8487
            },
            "Laurinburg": {
                "lat": 34.7602,
                "lng": -79.4773
            },
            "Mastic": {
                "lat": 40.8096,
                "lng": -72.8479
            },
            "West University Place": {
                "lat": 29.7157,
                "lng": -95.4321
            },
            "Lakeland North": {
                "lat": 47.3374,
                "lng": -122.2812
            },
            "Weigelstown": {
                "lat": 39.9852,
                "lng": -76.8306
            },
            "Metuchen": {
                "lat": 40.5424,
                "lng": -74.3628
            },
            "Hereford": {
                "lat": 34.8225,
                "lng": -102.4001
            },
            "Newtown": {
                "lat": 39.992,
                "lng": -75.4106
            },
            "Riverdale": {
                "lat": 41.1732,
                "lng": -112.0023
            },
            "East Rancho Dominguez": {
                "lat": 33.8949,
                "lng": -118.1956
            },
            "Hickory Hills": {
                "lat": 41.7248,
                "lng": -87.828
            },
            "Holliston": {
                "lat": 42.1977,
                "lng": -71.445
            },
            "Lake Arbor": {
                "lat": 38.9105,
                "lng": -76.8304
            },
            "Branchburg": {
                "lat": 40.5629,
                "lng": -74.714
            },
            "Highland Springs": {
                "lat": 37.5516,
                "lng": -77.3285
            },
            "Oldsmar": {
                "lat": 28.0506,
                "lng": -82.6698
            },
            "Greensburg": {
                "lat": 39.3518,
                "lng": -85.5028
            },
            "Boulder City": {
                "lat": 35.8407,
                "lng": -114.9257
            },
            "Red Oak": {
                "lat": 32.5212,
                "lng": -96.7866
            },
            "Forest City": {
                "lat": 28.6619,
                "lng": -81.4444
            },
            "Salida": {
                "lat": 37.7083,
                "lng": -121.0842
            },
            "Lemon Hill": {
                "lat": 38.5172,
                "lng": -121.4573
            },
            "Fort Payne": {
                "lat": 34.4559,
                "lng": -85.6965
            },
            "Tanque Verde": {
                "lat": 32.2687,
                "lng": -110.7437
            },
            "Whitefish Bay": {
                "lat": 43.1131,
                "lng": -87.9003
            },
            "Sullivan": {
                "lat": 43.0923,
                "lng": -75.8794
            },
            "Waunakee": {
                "lat": 43.1829,
                "lng": -89.4447
            },
            "Stony Point": {
                "lat": 41.2593,
                "lng": -74.0112
            },
            "Alexander City": {
                "lat": 32.9229,
                "lng": -85.9358
            },
            "New Paltz": {
                "lat": 41.7577,
                "lng": -74.0883
            },
            "Williamstown": {
                "lat": 39.6874,
                "lng": -74.9786
            },
            "Clarksdale": {
                "lat": 34.1933,
                "lng": -90.5693
            },
            "Piney Green": {
                "lat": 34.7498,
                "lng": -77.3208
            },
            "Adams": {
                "lat": 40.7092,
                "lng": -80.0118
            },
            "Deming": {
                "lat": 32.2631,
                "lng": -107.7525
            },
            "Havre de Grace": {
                "lat": 39.548,
                "lng": -76.1146
            },
            "Dennis": {
                "lat": 41.7064,
                "lng": -70.1644
            },
            "Lilburn": {
                "lat": 33.8897,
                "lng": -84.1364
            },
            "Hammonton": {
                "lat": 39.6572,
                "lng": -74.7678
            },
            "Pine": {
                "lat": 40.6437,
                "lng": -80.0315
            },
            "Great Bend": {
                "lat": 38.3593,
                "lng": -98.8015
            },
            "Greendale": {
                "lat": 42.9371,
                "lng": -88.0018
            },
            "Isla Vista": {
                "lat": 34.4128,
                "lng": -119.8614
            },
            "Hooksett": {
                "lat": 43.0709,
                "lng": -71.4365
            },
            "Gladeview": {
                "lat": 25.8395,
                "lng": -80.2368
            },
            "Guilford": {
                "lat": 39.8811,
                "lng": -77.6012
            },
            "Hershey": {
                "lat": 40.2806,
                "lng": -76.6458
            },
            "Owosso": {
                "lat": 42.9955,
                "lng": -84.176
            },
            "Fraser": {
                "lat": 42.5388,
                "lng": -82.9496
            },
            "Lake Stickney": {
                "lat": 47.8733,
                "lng": -122.2582
            },
            "North New Hyde Park": {
                "lat": 40.746,
                "lng": -73.6876
            },
            "Flower Hill": {
                "lat": 39.1676,
                "lng": -77.1824
            },
            "Parlier": {
                "lat": 36.6087,
                "lng": -119.5434
            },
            "Chippewa Falls": {
                "lat": 44.9358,
                "lng": -91.3902
            },
            "Narragansett": {
                "lat": 41.4291,
                "lng": -71.4668
            },
            "Groveton": {
                "lat": 38.7605,
                "lng": -77.098
            },
            "Wilbraham": {
                "lat": 42.127,
                "lng": -72.4308
            },
            "Broadlands": {
                "lat": 39.0168,
                "lng": -77.5167
            },
            "Red Bluff": {
                "lat": 40.1735,
                "lng": -122.2413
            },
            "Falls Church": {
                "lat": 38.8847,
                "lng": -77.1751
            },
            "Port Royal": {
                "lat": 32.3557,
                "lng": -80.7029
            },
            "Key Biscayne": {
                "lat": 25.6908,
                "lng": -80.1653
            },
            "Salem Lakes": {
                "lat": 42.5366,
                "lng": -88.1306
            },
            "Belvedere Park": {
                "lat": 33.7489,
                "lng": -84.2599
            },
            "Clarkston": {
                "lat": 33.8117,
                "lng": -84.2403
            },
            "Cecil": {
                "lat": 40.3147,
                "lng": -80.1942
            },
            "Moultrie": {
                "lat": 31.1591,
                "lng": -83.7708
            },
            "Reidsville": {
                "lat": 36.3376,
                "lng": -79.6725
            },
            "Middlesex": {
                "lat": 40.5744,
                "lng": -74.5011
            },
            "McCalla": {
                "lat": 33.3023,
                "lng": -87.0302
            },
            "Santaquin": {
                "lat": 39.9708,
                "lng": -111.7941
            },
            "Scottsbluff": {
                "lat": 41.8684,
                "lng": -103.6616
            },
            "Robbinsdale": {
                "lat": 45.0261,
                "lng": -93.3332
            },
            "Short Hills": {
                "lat": 40.7389,
                "lng": -74.3278
            },
            "West Freehold": {
                "lat": 40.2324,
                "lng": -74.2943
            },
            "Monticello": {
                "lat": 45.2991,
                "lng": -93.7996
            },
            "Anderson Creek": {
                "lat": 35.2657,
                "lng": -78.958
            },
            "Nolensville": {
                "lat": 35.9572,
                "lng": -86.6719
            },
            "Verona": {
                "lat": 42.9892,
                "lng": -89.5383
            },
            "Chatham": {
                "lat": 40.7274,
                "lng": -74.4289
            },
            "Black Forest": {
                "lat": 39.0608,
                "lng": -104.6752
            },
            "Nether Providence": {
                "lat": 39.8971,
                "lng": -75.3697
            },
            "Caln": {
                "lat": 40.0014,
                "lng": -75.7619
            },
            "Brainerd": {
                "lat": 46.3553,
                "lng": -94.1983
            },
            "Hopatcong": {
                "lat": 40.9541,
                "lng": -74.6593
            },
            "Halawa": {
                "lat": 21.3753,
                "lng": -157.9185
            },
            "Skippack": {
                "lat": 40.2165,
                "lng": -75.419
            },
            "Minneola": {
                "lat": 28.6067,
                "lng": -81.7322
            },
            "Dumas": {
                "lat": 35.8613,
                "lng": -101.9642
            },
            "North Auburn": {
                "lat": 38.9306,
                "lng": -121.082
            },
            "Sierra Vista Southeast": {
                "lat": 31.4525,
                "lng": -110.216
            },
            "West Bradford": {
                "lat": 39.9633,
                "lng": -75.716
            },
            "Washington Court House": {
                "lat": 39.5381,
                "lng": -83.4281
            },
            "Lake Wylie": {
                "lat": 35.0997,
                "lng": -81.0678
            },
            "East Bridgewater": {
                "lat": 42.0352,
                "lng": -70.9424
            },
            "Natchez": {
                "lat": 31.5437,
                "lng": -91.3867
            },
            "Moorestown-Lenola": {
                "lat": 39.9659,
                "lng": -74.9643
            },
            "Elizabethton": {
                "lat": 36.3367,
                "lng": -82.237
            },
            "Mercerville": {
                "lat": 40.236,
                "lng": -74.6917
            },
            "Pineville": {
                "lat": 35.0864,
                "lng": -80.8915
            },
            "Bradley Gardens": {
                "lat": 40.5711,
                "lng": -74.6678
            },
            "Wilkinsburg": {
                "lat": 40.4442,
                "lng": -79.8733
            },
            "Brookside": {
                "lat": 39.6665,
                "lng": -75.7152
            },
            "Saddle Brook": {
                "lat": 40.9033,
                "lng": -74.0955
            },
            "Iona": {
                "lat": 26.516,
                "lng": -81.9601
            },
            "Loganville": {
                "lat": 33.8353,
                "lng": -83.8958
            },
            "Brandermill": {
                "lat": 37.434,
                "lng": -77.6522
            },
            "East Greenwich": {
                "lat": 39.7903,
                "lng": -75.2396
            },
            "Chicago Ridge": {
                "lat": 41.7034,
                "lng": -87.7795
            },
            "Jericho": {
                "lat": 40.7875,
                "lng": -73.5416
            },
            "Somerton": {
                "lat": 32.6007,
                "lng": -114.6994
            },
            "West Lealman": {
                "lat": 27.8192,
                "lng": -82.7384
            },
            "Gantt": {
                "lat": 34.7837,
                "lng": -82.4027
            },
            "North Mankato": {
                "lat": 44.181,
                "lng": -94.0391
            },
            "Bainbridge": {
                "lat": 41.3855,
                "lng": -81.3478
            },
            "Little Falls": {
                "lat": 45.9862,
                "lng": -94.3586
            },
            "Uxbridge": {
                "lat": 42.0593,
                "lng": -71.638
            },
            "Elk Plain": {
                "lat": 47.0425,
                "lng": -122.3663
            },
            "Cedar Lake": {
                "lat": 41.3696,
                "lng": -87.4389
            },
            "Willowick": {
                "lat": 41.6342,
                "lng": -81.4678
            },
            "Luling": {
                "lat": 29.9008,
                "lng": -90.3523
            },
            "Fallsburg": {
                "lat": 41.7391,
                "lng": -74.6038
            },
            "Bensville": {
                "lat": 38.6176,
                "lng": -77.0077
            },
            "Mill Valley": {
                "lat": 37.9086,
                "lng": -122.5421
            },
            "Chesterton": {
                "lat": 41.5997,
                "lng": -87.055
            },
            "Hobe Sound": {
                "lat": 27.0729,
                "lng": -80.1425
            },
            "Huron": {
                "lat": 44.3623,
                "lng": -98.2094
            },
            "Coolidge": {
                "lat": 32.9363,
                "lng": -111.5254
            },
            "Federal Heights": {
                "lat": 39.8651,
                "lng": -105.0154
            },
            "Sun Lakes": {
                "lat": 33.2172,
                "lng": -111.8695
            },
            "East Whiteland": {
                "lat": 40.0474,
                "lng": -75.5547
            },
            "Beekman": {
                "lat": 41.6042,
                "lng": -73.6944
            },
            "Shepherdsville": {
                "lat": 37.9813,
                "lng": -85.7007
            },
            "Collingswood": {
                "lat": 39.916,
                "lng": -75.0758
            },
            "Lakewood Park": {
                "lat": 27.539,
                "lng": -80.3865
            },
            "Powell": {
                "lat": 36.0358,
                "lng": -84.0296
            },
            "Fergus Falls": {
                "lat": 46.2854,
                "lng": -96.0758
            },
            "Pierre": {
                "lat": 44.3748,
                "lng": -100.3205
            },
            "Kemp Mill": {
                "lat": 39.0412,
                "lng": -77.0215
            },
            "Oneonta": {
                "lat": 42.4551,
                "lng": -75.0666
            },
            "Allouez": {
                "lat": 44.4721,
                "lng": -88.0259
            },
            "Indiana": {
                "lat": 40.622,
                "lng": -79.1552
            },
            "Dunmore": {
                "lat": 41.4152,
                "lng": -75.6072
            },
            "Hidalgo": {
                "lat": 26.109,
                "lng": -98.2464
            },
            "Jefferson Valley-Yorktown": {
                "lat": 41.318,
                "lng": -73.8008
            },
            "East Wenatchee": {
                "lat": 47.4174,
                "lng": -120.2822
            },
            "Nantucket": {
                "lat": 41.2831,
                "lng": -70.0692
            },
            "New Ulm": {
                "lat": 44.312,
                "lng": -94.4685
            },
            "Thonotosassa": {
                "lat": 28.0464,
                "lng": -82.291
            },
            "Wauconda": {
                "lat": 42.2749,
                "lng": -88.1359
            },
            "Sharonville": {
                "lat": 39.2825,
                "lng": -84.4071
            },
            "Circleville": {
                "lat": 39.6063,
                "lng": -82.9334
            },
            "St. Helens": {
                "lat": 45.8572,
                "lng": -122.8164
            },
            "Hawaiian Gardens": {
                "lat": 33.8304,
                "lng": -118.0728
            },
            "Beach Park": {
                "lat": 42.426,
                "lng": -87.8583
            },
            "Lone Tree": {
                "lat": 39.5309,
                "lng": -104.8709
            },
            "East Norriton": {
                "lat": 40.1506,
                "lng": -75.3364
            },
            "East Milton": {
                "lat": 30.6175,
                "lng": -86.9636
            },
            "McFarland": {
                "lat": 43.0203,
                "lng": -89.2828
            },
            "Grass Valley": {
                "lat": 39.2238,
                "lng": -121.0522
            },
            "Roselle Park": {
                "lat": 40.6653,
                "lng": -74.2666
            },
            "McPherson": {
                "lat": 38.3714,
                "lng": -97.6605
            },
            "Bothell East": {
                "lat": 47.8064,
                "lng": -122.1844
            },
            "Warrenville": {
                "lat": 41.8209,
                "lng": -88.1857
            },
            "Wells Branch": {
                "lat": 30.4433,
                "lng": -97.679
            },
            "Camp Pendleton South": {
                "lat": 33.2329,
                "lng": -117.393
            },
            "Plumstead": {
                "lat": 40.3878,
                "lng": -75.1164
            },
            "Lower Moreland": {
                "lat": 40.1346,
                "lng": -75.0542
            },
            "Waimalu": {
                "lat": 21.3913,
                "lng": -157.9345
            },
            "East Cleveland": {
                "lat": 41.5317,
                "lng": -81.5794
            },
            "Destin": {
                "lat": 30.395,
                "lng": -86.4701
            },
            "Royal Kunia": {
                "lat": 21.4053,
                "lng": -158.0318
            },
            "Wood Dale": {
                "lat": 41.9668,
                "lng": -87.9808
            },
            "Shiloh": {
                "lat": 39.8159,
                "lng": -84.2317
            },
            "Olean": {
                "lat": 42.0819,
                "lng": -78.4321
            },
            "Hockessin": {
                "lat": 39.7837,
                "lng": -75.6815
            },
            "Sunset": {
                "lat": 25.706,
                "lng": -80.353
            },
            "Beacon": {
                "lat": 41.5036,
                "lng": -73.9655
            },
            "New Franklin": {
                "lat": 40.9525,
                "lng": -81.5838
            },
            "Artondale": {
                "lat": 47.3021,
                "lng": -122.6406
            },
            "Waycross": {
                "lat": 31.2108,
                "lng": -82.3579
            },
            "Millbury": {
                "lat": 42.1925,
                "lng": -71.7741
            },
            "Bellair-Meadowbrook Terrace": {
                "lat": 30.1796,
                "lng": -81.7375
            },
            "Dryden": {
                "lat": 42.4786,
                "lng": -76.3564
            },
            "Beachwood": {
                "lat": 39.9286,
                "lng": -74.2022
            },
            "South Sioux City": {
                "lat": 42.4627,
                "lng": -96.4125
            },
            "Sauk Rapids": {
                "lat": 45.5981,
                "lng": -94.1538
            },
            "Olympia Heights": {
                "lat": 25.724,
                "lng": -80.3388
            },
            "Maumee": {
                "lat": 41.5696,
                "lng": -83.6636
            },
            "Wayland": {
                "lat": 42.3586,
                "lng": -71.3594
            },
            "Teays Valley": {
                "lat": 38.4482,
                "lng": -81.9246
            },
            "Forest Hill": {
                "lat": 32.6619,
                "lng": -97.2662
            },
            "Channahon": {
                "lat": 41.421,
                "lng": -88.2599
            },
            "Braselton": {
                "lat": 34.1087,
                "lng": -83.8127
            },
            "Villas": {
                "lat": 39.0157,
                "lng": -74.935
            },
            "Ipswich": {
                "lat": 42.6857,
                "lng": -70.8399
            },
            "Bellefontaine": {
                "lat": 40.3627,
                "lng": -83.763
            },
            "Yulee": {
                "lat": 30.635,
                "lng": -81.5678
            },
            "Snoqualmie": {
                "lat": 47.5293,
                "lng": -121.8412
            },
            "Willow Grove": {
                "lat": 40.1469,
                "lng": -75.1174
            },
            "Cayce": {
                "lat": 33.9459,
                "lng": -81.0429
            },
            "Waianae": {
                "lat": 21.4568,
                "lng": -158.1758
            },
            "Ephrata": {
                "lat": 40.1811,
                "lng": -76.1812
            },
            "Hazel Crest": {
                "lat": 41.5732,
                "lng": -87.6899
            },
            "St. James": {
                "lat": 40.8761,
                "lng": -73.1521
            },
            "Miami Springs": {
                "lat": 25.8195,
                "lng": -80.2894
            },
            "North Fair Oaks": {
                "lat": 37.4754,
                "lng": -122.2034
            },
            "Rocky Point": {
                "lat": 40.9357,
                "lng": -72.9364
            },
            "Lamont": {
                "lat": 35.2659,
                "lng": -118.9159
            },
            "Moberly": {
                "lat": 39.4179,
                "lng": -92.4364
            },
            "Coldwater": {
                "lat": 41.9465,
                "lng": -84.9989
            },
            "Colesville": {
                "lat": 39.073,
                "lng": -77.001
            },
            "South Park": {
                "lat": 40.2988,
                "lng": -79.9944
            },
            "Valencia West": {
                "lat": 32.1355,
                "lng": -111.1123
            },
            "Glenpool": {
                "lat": 35.9488,
                "lng": -96.0052
            },
            "Latham": {
                "lat": 42.7427,
                "lng": -73.7497
            },
            "Warrensville Heights": {
                "lat": 41.4363,
                "lng": -81.5222
            },
            "Glen Carbon": {
                "lat": 38.758,
                "lng": -89.9822
            },
            "University Heights": {
                "lat": 41.4948,
                "lng": -81.535
            },
            "Speedway": {
                "lat": 39.7937,
                "lng": -86.2481
            },
            "Beeville": {
                "lat": 28.4053,
                "lng": -97.749
            },
            "Port Neches": {
                "lat": 29.9765,
                "lng": -93.9459
            },
            "California": {
                "lat": 38.2969,
                "lng": -76.4949
            },
            "Spring Garden": {
                "lat": 39.9454,
                "lng": -76.7212
            },
            "Brecksville": {
                "lat": 41.3079,
                "lng": -81.6192
            },
            "Wyandanch": {
                "lat": 40.7496,
                "lng": -73.3633
            },
            "New Providence": {
                "lat": 40.6996,
                "lng": -74.4035
            },
            "Beaufort": {
                "lat": 32.4597,
                "lng": -80.7235
            },
            "New Carrollton": {
                "lat": 38.9656,
                "lng": -76.8775
            },
            "Gonzalez": {
                "lat": 30.5822,
                "lng": -87.2906
            },
            "East Islip": {
                "lat": 40.7257,
                "lng": -73.1869
            },
            "Bardstown": {
                "lat": 37.8175,
                "lng": -85.455
            },
            "Newington": {
                "lat": 38.7358,
                "lng": -77.1993
            },
            "Western Springs": {
                "lat": 41.8023,
                "lng": -87.9006
            },
            "Echelon": {
                "lat": 39.8482,
                "lng": -74.9957
            },
            "Nesconset": {
                "lat": 40.8467,
                "lng": -73.1522
            },
            "Plover": {
                "lat": 44.4615,
                "lng": -89.5382
            },
            "Endicott": {
                "lat": 42.098,
                "lng": -76.0639
            },
            "Martinsville": {
                "lat": 39.4149,
                "lng": -86.4316
            },
            "Lexington Park": {
                "lat": 38.2528,
                "lng": -76.4424
            },
            "Bexley": {
                "lat": 39.965,
                "lng": -82.9343
            },
            "South Charleston": {
                "lat": 38.3426,
                "lng": -81.7155
            },
            "Bridgetown": {
                "lat": 39.1552,
                "lng": -84.6359
            },
            "Eatontown": {
                "lat": 40.2913,
                "lng": -74.0558
            },
            "Shawangunk": {
                "lat": 41.6335,
                "lng": -74.2654
            },
            "Bonita": {
                "lat": 32.6651,
                "lng": -117.0296
            },
            "King City": {
                "lat": 36.2164,
                "lng": -121.133
            },
            "Avocado Heights": {
                "lat": 34.0391,
                "lng": -117.997
            },
            "Lake Forest Park": {
                "lat": 47.7574,
                "lng": -122.2864
            },
            "Fruita": {
                "lat": 39.1548,
                "lng": -108.7305
            },
            "Los Alamos": {
                "lat": 35.8927,
                "lng": -106.2862
            },
            "Moncks Corner": {
                "lat": 33.173,
                "lng": -80.0107
            },
            "Irondale": {
                "lat": 33.5439,
                "lng": -86.6606
            },
            "Callaway": {
                "lat": 30.1349,
                "lng": -85.5568
            },
            "Fort Lewis": {
                "lat": 47.0955,
                "lng": -122.5674
            },
            "Harwich": {
                "lat": 41.6957,
                "lng": -70.0684
            },
            "Avenal": {
                "lat": 36.0311,
                "lng": -120.1162
            },
            "Sault Ste. Marie": {
                "lat": 46.4817,
                "lng": -84.3723
            },
            "Azle": {
                "lat": 32.8955,
                "lng": -97.5379
            },
            "Kilgore": {
                "lat": 32.398,
                "lng": -94.8602
            },
            "Connersville": {
                "lat": 39.6582,
                "lng": -85.141
            },
            "Joppatowne": {
                "lat": 39.4181,
                "lng": -76.3516
            },
            "Butler": {
                "lat": 41.0358,
                "lng": -75.9798
            },
            "Pottsville": {
                "lat": 40.6798,
                "lng": -76.2091
            },
            "Palmetto": {
                "lat": 27.5251,
                "lng": -82.5749
            },
            "Atlantic Beach": {
                "lat": 30.3375,
                "lng": -81.4127
            },
            "River Ridge": {
                "lat": 29.9593,
                "lng": -90.2202
            },
            "Jupiter Farms": {
                "lat": 26.9222,
                "lng": -80.2189
            },
            "Amity": {
                "lat": 40.2905,
                "lng": -75.7477
            },
            "Lake Station": {
                "lat": 41.5729,
                "lng": -87.2599
            },
            "Carnot-Moon": {
                "lat": 40.5187,
                "lng": -80.2178
            },
            "Andrews": {
                "lat": 32.3207,
                "lng": -102.552
            },
            "Charlton": {
                "lat": 42.1351,
                "lng": -71.968
            },
            "Mountain Park": {
                "lat": 33.8458,
                "lng": -84.1314
            },
            "La Grange Park": {
                "lat": 41.8308,
                "lng": -87.8723
            },
            "Sweden": {
                "lat": 43.1791,
                "lng": -77.9406
            },
            "Blue Ash": {
                "lat": 39.248,
                "lng": -84.3827
            },
            "Broussard": {
                "lat": 30.1396,
                "lng": -91.954
            },
            "Coatesville": {
                "lat": 39.9849,
                "lng": -75.82
            },
            "White House": {
                "lat": 36.4648,
                "lng": -86.6666
            },
            "Woodinville": {
                "lat": 47.757,
                "lng": -122.1477
            },
            "Elfers": {
                "lat": 28.214,
                "lng": -82.723
            },
            "Woodland Park": {
                "lat": 40.8904,
                "lng": -74.1946
            },
            "Blytheville": {
                "lat": 35.9321,
                "lng": -89.9051
            },
            "Lincolnwood": {
                "lat": 42.0054,
                "lng": -87.733
            },
            "North Greenbush": {
                "lat": 42.6706,
                "lng": -73.6633
            },
            "Naranja": {
                "lat": 25.5164,
                "lng": -80.4222
            },
            "Orange City": {
                "lat": 28.9348,
                "lng": -81.2881
            },
            "Kings Park West": {
                "lat": 38.8151,
                "lng": -77.296
            },
            "Steamboat Springs": {
                "lat": 40.4777,
                "lng": -106.8243
            },
            "Trophy Club": {
                "lat": 33.004,
                "lng": -97.1899
            },
            "New Kingman-Butler": {
                "lat": 35.2645,
                "lng": -114.0091
            },
            "Moody": {
                "lat": 33.5986,
                "lng": -86.4963
            },
            "Orangeburg": {
                "lat": 33.4928,
                "lng": -80.8671
            },
            "Palos Verdes Estates": {
                "lat": 33.7872,
                "lng": -118.3973
            },
            "Cheval": {
                "lat": 28.1459,
                "lng": -82.5184
            },
            "Carpinteria": {
                "lat": 34.3962,
                "lng": -119.5118
            },
            "Cedar Grove": {
                "lat": 40.8565,
                "lng": -74.2288
            },
            "Weddington": {
                "lat": 35.0228,
                "lng": -80.7383
            },
            "Medway": {
                "lat": 42.1535,
                "lng": -71.4291
            },
            "Ridgefield Park": {
                "lat": 40.8543,
                "lng": -74.0201
            },
            "Pell City": {
                "lat": 33.561,
                "lng": -86.2669
            },
            "South Daytona": {
                "lat": 29.1657,
                "lng": -81.0055
            },
            "Chino Valley": {
                "lat": 34.7594,
                "lng": -112.412
            },
            "Berkeley Heights": {
                "lat": 40.6764,
                "lng": -74.4244
            },
            "Grand Terrace": {
                "lat": 34.0312,
                "lng": -117.3132
            },
            "Mounds View": {
                "lat": 45.1071,
                "lng": -93.2076
            },
            "Lake Los Angeles": {
                "lat": 34.6097,
                "lng": -117.8339
            },
            "La Grande": {
                "lat": 45.3242,
                "lng": -118.0865
            },
            "North Palm Beach": {
                "lat": 26.8217,
                "lng": -80.0576
            },
            "Soddy-Daisy": {
                "lat": 35.2571,
                "lng": -85.1739
            },
            "Pomfret": {
                "lat": 42.4029,
                "lng": -79.3534
            },
            "New Hanover": {
                "lat": 40.3145,
                "lng": -75.5566
            },
            "Ionia": {
                "lat": 42.9773,
                "lng": -85.0727
            },
            "Oatfield": {
                "lat": 45.4125,
                "lng": -122.594
            },
            "Cherry Hill Mall": {
                "lat": 39.9384,
                "lng": -75.0117
            },
            "West Carrollton": {
                "lat": 39.6701,
                "lng": -84.2542
            },
            "On Top of the World Designated Place": {
                "lat": 29.1058,
                "lng": -82.2866
            },
            "Hawaiian Paradise Park": {
                "lat": 19.5828,
                "lng": -154.9695
            },
            "Fernandina Beach": {
                "lat": 30.6571,
                "lng": -81.4511
            },
            "Forrest City": {
                "lat": 35.0135,
                "lng": -90.7931
            },
            "Montgomeryville": {
                "lat": 40.2502,
                "lng": -75.2405
            },
            "Webb City": {
                "lat": 37.1412,
                "lng": -94.4676
            },
            "Timberlake": {
                "lat": 37.3167,
                "lng": -79.2481
            },
            "Jennings": {
                "lat": 30.2233,
                "lng": -92.6582
            },
            "Waconia": {
                "lat": 44.8422,
                "lng": -93.7896
            },
            "Ridge": {
                "lat": 40.9068,
                "lng": -72.8816
            },
            "West Monroe": {
                "lat": 32.512,
                "lng": -92.1513
            },
            "Larkspur": {
                "lat": 37.9393,
                "lng": -122.5312
            },
            "Kingsbury": {
                "lat": 43.344,
                "lng": -73.5396
            },
            "Schodack": {
                "lat": 42.5297,
                "lng": -73.6858
            },
            "Vinings": {
                "lat": 33.8608,
                "lng": -84.4686
            },
            "Solana Beach": {
                "lat": 32.9943,
                "lng": -117.2574
            },
            "Grantsville": {
                "lat": 40.6148,
                "lng": -112.4777
            },
            "North Adams": {
                "lat": 42.6844,
                "lng": -73.1166
            },
            "Newcastle": {
                "lat": 35.2401,
                "lng": -97.5995
            },
            "Meadville": {
                "lat": 41.6476,
                "lng": -80.1468
            },
            "Lynnfield": {
                "lat": 42.5356,
                "lng": -71.0382
            },
            "Byram": {
                "lat": 32.189,
                "lng": -90.2861
            },
            "D'Iberville": {
                "lat": 30.4709,
                "lng": -88.9011
            },
            "Holly Hill": {
                "lat": 29.2442,
                "lng": -81.0465
            },
            "St. Ann": {
                "lat": 38.7266,
                "lng": -90.3872
            },
            "Lahaina": {
                "lat": 20.8848,
                "lng": -156.6618
            },
            "Fostoria": {
                "lat": 41.16,
                "lng": -83.4121
            },
            "Dardenne Prairie": {
                "lat": 38.7565,
                "lng": -90.7319
            },
            "Highlands": {
                "lat": 41.3601,
                "lng": -74.0084
            },
            "Suamico": {
                "lat": 44.6354,
                "lng": -88.0683
            },
            "Finneytown": {
                "lat": 39.2159,
                "lng": -84.5144
            },
            "Red Bank": {
                "lat": 33.9309,
                "lng": -81.2322
            },
            "Woodhaven": {
                "lat": 42.132,
                "lng": -83.2374
            },
            "Vadnais Heights": {
                "lat": 45.057,
                "lng": -93.0748
            },
            "Tehachapi": {
                "lat": 35.1276,
                "lng": -118.4744
            },
            "Cheney": {
                "lat": 47.4901,
                "lng": -117.579
            },
            "Medfield": {
                "lat": 42.1848,
                "lng": -71.305
            },
            "Florida City": {
                "lat": 25.4418,
                "lng": -80.4685
            },
            "Emeryville": {
                "lat": 37.8382,
                "lng": -122.2932
            },
            "Lansdowne": {
                "lat": 39.2365,
                "lng": -76.6659
            },
            "Bloomsburg": {
                "lat": 41.0027,
                "lng": -76.4561
            },
            "Cornwall": {
                "lat": 41.4195,
                "lng": -74.054
            },
            "Sugarland Run": {
                "lat": 39.0309,
                "lng": -77.3762
            },
            "Tega Cay": {
                "lat": 35.039,
                "lng": -81.0111
            },
            "Tucson Estates": {
                "lat": 32.1792,
                "lng": -111.1266
            },
            "Overlea": {
                "lat": 39.3642,
                "lng": -76.5175
            },
            "Florham Park": {
                "lat": 40.7773,
                "lng": -74.3953
            },
            "Festus": {
                "lat": 38.2194,
                "lng": -90.4097
            },
            "San Anselmo": {
                "lat": 37.9821,
                "lng": -122.5699
            },
            "Grover Beach": {
                "lat": 35.1204,
                "lng": -120.6197
            },
            "Big Bear City": {
                "lat": 34.2536,
                "lng": -116.7903
            },
            "Eufaula": {
                "lat": 31.9102,
                "lng": -85.1505
            },
            "Accokeek": {
                "lat": 38.6745,
                "lng": -77.0023
            },
            "Guymon": {
                "lat": 36.6903,
                "lng": -101.4774
            },
            "Richton Park": {
                "lat": 41.4816,
                "lng": -87.7387
            },
            "Annapolis Neck": {
                "lat": 38.9409,
                "lng": -76.4997
            },
            "Pennsville": {
                "lat": 39.6266,
                "lng": -75.5089
            },
            "Woolwich": {
                "lat": 39.74,
                "lng": -75.317
            },
            "Northlake": {
                "lat": 41.9142,
                "lng": -87.9054
            },
            "Wawarsing": {
                "lat": 41.7526,
                "lng": -74.4172
            },
            "Manorville": {
                "lat": 40.8574,
                "lng": -72.7915
            },
            "Paradise Valley": {
                "lat": 33.5434,
                "lng": -111.9595
            },
            "Neosho": {
                "lat": 36.8437,
                "lng": -94.3758
            },
            "Dunkirk": {
                "lat": 42.4801,
                "lng": -79.3324
            },
            "Fords": {
                "lat": 40.5359,
                "lng": -74.3126
            },
            "Wickliffe": {
                "lat": 41.6072,
                "lng": -81.469
            },
            "Kelso": {
                "lat": 46.1236,
                "lng": -122.8909
            },
            "Pasadena Hills": {
                "lat": 28.2881,
                "lng": -82.238
            },
            "Gaffney": {
                "lat": 35.0743,
                "lng": -81.6552
            },
            "Branson": {
                "lat": 36.6509,
                "lng": -93.2636
            },
            "Fulton": {
                "lat": 43.3171,
                "lng": -76.4167
            },
            "Newington Forest": {
                "lat": 38.737,
                "lng": -77.2339
            },
            "Robertsville": {
                "lat": 40.3395,
                "lng": -74.2939
            },
            "Holiday City-Berkeley": {
                "lat": 39.9639,
                "lng": -74.2787
            },
            "Melvindale": {
                "lat": 42.2802,
                "lng": -83.1782
            },
            "Tomball": {
                "lat": 30.0951,
                "lng": -95.6194
            },
            "Enumclaw": {
                "lat": 47.2018,
                "lng": -121.9897
            },
            "Ulster": {
                "lat": 41.9699,
                "lng": -74.0041
            },
            "Rehoboth": {
                "lat": 41.8439,
                "lng": -71.2456
            },
            "Mamakating": {
                "lat": 41.586,
                "lng": -74.4951
            },
            "Levelland": {
                "lat": 33.5806,
                "lng": -102.3635
            },
            "Winnetka": {
                "lat": 42.1064,
                "lng": -87.7421
            },
            "Minooka": {
                "lat": 41.4507,
                "lng": -88.2791
            },
            "Lakeland South": {
                "lat": 47.2786,
                "lng": -122.2826
            },
            "Mendota": {
                "lat": 36.7555,
                "lng": -120.3776
            },
            "Grenada": {
                "lat": 33.7816,
                "lng": -89.813
            },
            "Brown Deer": {
                "lat": 43.1743,
                "lng": -87.975
            },
            "Lindsay": {
                "lat": 36.2082,
                "lng": -119.0897
            },
            "Jensen Beach": {
                "lat": 27.2437,
                "lng": -80.2423
            },
            "Dingman": {
                "lat": 41.3226,
                "lng": -74.9264
            },
            "Upper": {
                "lat": 39.2563,
                "lng": -74.727
            },
            "Cloquet": {
                "lat": 46.7221,
                "lng": -92.4923
            },
            "Viera East": {
                "lat": 28.2613,
                "lng": -80.7149
            },
            "Rantoul": {
                "lat": 40.3031,
                "lng": -88.1549
            },
            "Cascades": {
                "lat": 39.0464,
                "lng": -77.3874
            },
            "Baraboo": {
                "lat": 43.4695,
                "lng": -89.7376
            },
            "North Smithfield": {
                "lat": 41.9727,
                "lng": -71.5514
            },
            "Brock Hall": {
                "lat": 38.8604,
                "lng": -76.7459
            },
            "Fortuna": {
                "lat": 40.5862,
                "lng": -124.1419
            },
            "Lake Norman of Catawba": {
                "lat": 35.5995,
                "lng": -80.984
            },
            "Justice": {
                "lat": 41.7495,
                "lng": -87.8345
            },
            "Fort Atkinson": {
                "lat": 42.9253,
                "lng": -88.8442
            },
            "Asbury Lake": {
                "lat": 30.0472,
                "lng": -81.7853
            },
            "Fort Bliss": {
                "lat": 31.8396,
                "lng": -106.3747
            },
            "Borger": {
                "lat": 35.6598,
                "lng": -101.4012
            },
            "Stony Brook": {
                "lat": 40.9061,
                "lng": -73.1278
            },
            "Sarasota Springs": {
                "lat": 27.3087,
                "lng": -82.4746
            },
            "Goldenrod": {
                "lat": 28.6114,
                "lng": -81.2916
            },
            "Kingsburg": {
                "lat": 36.5244,
                "lng": -119.5602
            },
            "Jerome": {
                "lat": 42.7179,
                "lng": -114.5159
            },
            "Poquoson": {
                "lat": 37.1318,
                "lng": -76.3568
            },
            "Upper Uwchlan": {
                "lat": 40.0817,
                "lng": -75.707
            },
            "Sedro-Woolley": {
                "lat": 48.5112,
                "lng": -122.2321
            },
            "Sylacauga": {
                "lat": 33.178,
                "lng": -86.2605
            },
            "North St. Paul": {
                "lat": 45.0137,
                "lng": -92.9995
            },
            "Baker": {
                "lat": 30.5832,
                "lng": -91.1582
            },
            "Haddonfield": {
                "lat": 39.8955,
                "lng": -75.0346
            },
            "Highland City": {
                "lat": 27.9633,
                "lng": -81.8781
            },
            "Prairie Ridge": {
                "lat": 47.1438,
                "lng": -122.1408
            },
            "San Marino": {
                "lat": 34.1224,
                "lng": -118.1132
            },
            "Upper Montclair": {
                "lat": 40.8433,
                "lng": -74.2006
            },
            "Wimauma": {
                "lat": 27.6964,
                "lng": -82.3034
            },
            "Massena": {
                "lat": 44.9609,
                "lng": -74.8339
            },
            "Escanaba": {
                "lat": 45.7477,
                "lng": -87.09
            },
            "Mango": {
                "lat": 27.9914,
                "lng": -82.307
            },
            "Amelia": {
                "lat": 39.0269,
                "lng": -84.2218
            },
            "College": {
                "lat": 40.8144,
                "lng": -77.8172
            },
            "Lewisburg": {
                "lat": 35.451,
                "lng": -86.7901
            },
            "Conneaut": {
                "lat": 41.9275,
                "lng": -80.5685
            },
            "Tyngsborough": {
                "lat": 42.6662,
                "lng": -71.429
            },
            "Nanakuli": {
                "lat": 21.3892,
                "lng": -158.1445
            },
            "Gonzales": {
                "lat": 36.5055,
                "lng": -121.4427
            },
            "Blackfoot": {
                "lat": 43.194,
                "lng": -112.3454
            },
            "Herrin": {
                "lat": 37.7983,
                "lng": -89.0305
            },
            "Patchogue": {
                "lat": 40.7621,
                "lng": -73.0185
            },
            "Crestwood": {
                "lat": 41.6454,
                "lng": -87.7397
            },
            "Cottonwood": {
                "lat": 34.7195,
                "lng": -112.0016
            },
            "North Castle": {
                "lat": 41.1331,
                "lng": -73.6949
            },
            "Cypress Lake": {
                "lat": 26.5392,
                "lng": -81.8999
            },
            "Ocean Pines": {
                "lat": 38.3851,
                "lng": -75.1487
            },
            "Lake City": {
                "lat": 30.1901,
                "lng": -82.6471
            },
            "Jefferson Hills": {
                "lat": 40.2926,
                "lng": -79.9329
            },
            "McComb": {
                "lat": 31.2442,
                "lng": -90.4716
            },
            "Leeds": {
                "lat": 33.5436,
                "lng": -86.5639
            },
            "German Flatts": {
                "lat": 42.9868,
                "lng": -74.9804
            },
            "Streator": {
                "lat": 41.1245,
                "lng": -88.8297
            },
            "Home Gardens": {
                "lat": 33.8783,
                "lng": -117.5116
            },
            "Doctor Phillips": {
                "lat": 28.4474,
                "lng": -81.4914
            },
            "Lower Pottsgrove": {
                "lat": 40.2537,
                "lng": -75.5975
            },
            "Fairfax Station": {
                "lat": 38.7942,
                "lng": -77.3358
            },
            "Newman": {
                "lat": 37.3156,
                "lng": -121.0212
            },
            "Spearfish": {
                "lat": 44.4909,
                "lng": -103.8155
            },
            "Commerce": {
                "lat": 33.2421,
                "lng": -95.8992
            },
            "West Deer": {
                "lat": 40.6351,
                "lng": -79.8693
            },
            "West Plains": {
                "lat": 36.7377,
                "lng": -91.868
            },
            "Effingham": {
                "lat": 39.1205,
                "lng": -88.5509
            },
            "El Campo": {
                "lat": 29.2,
                "lng": -96.2723
            },
            "Scotts Valley": {
                "lat": 37.0555,
                "lng": -122.0118
            },
            "Beatrice": {
                "lat": 40.2736,
                "lng": -96.7455
            },
            "Malone": {
                "lat": 44.7956,
                "lng": -74.2859
            },
            "Cedarburg": {
                "lat": 43.299,
                "lng": -87.9887
            },
            "Diamond Springs": {
                "lat": 38.692,
                "lng": -120.8391
            },
            "Tamalpais-Homestead Valley": {
                "lat": 37.8793,
                "lng": -122.5382
            },
            "Lake Tapps": {
                "lat": 47.2307,
                "lng": -122.1695
            },
            "Lewisboro": {
                "lat": 41.2697,
                "lng": -73.5828
            },
            "North Merrick": {
                "lat": 40.6871,
                "lng": -73.5615
            },
            "Kewanee": {
                "lat": 41.2399,
                "lng": -89.9264
            },
            "Wrentham": {
                "lat": 42.0513,
                "lng": -71.3552
            },
            "Moss Point": {
                "lat": 30.4241,
                "lng": -88.5289
            },
            "New Kensington": {
                "lat": 40.5712,
                "lng": -79.7523
            },
            "Brattleboro": {
                "lat": 42.8619,
                "lng": -72.6145
            },
            "Claiborne": {
                "lat": 32.5379,
                "lng": -92.1981
            },
            "Choctaw": {
                "lat": 35.4802,
                "lng": -97.2666
            },
            "Dranesville": {
                "lat": 38.9955,
                "lng": -77.3693
            },
            "Camp Verde": {
                "lat": 34.569,
                "lng": -111.856
            },
            "El Sobrante": {
                "lat": 33.8724,
                "lng": -117.4624
            },
            "Macedonia": {
                "lat": 41.3147,
                "lng": -81.4989
            },
            "North Middleton": {
                "lat": 40.2462,
                "lng": -77.2168
            },
            "Maili": {
                "lat": 21.4133,
                "lng": -158.1701
            },
            "Wyndham": {
                "lat": 37.6924,
                "lng": -77.6123
            },
            "New Baltimore": {
                "lat": 38.7495,
                "lng": -77.7151
            },
            "Mitchellville": {
                "lat": 38.9358,
                "lng": -76.8146
            },
            "Crossville": {
                "lat": 35.9526,
                "lng": -85.0295
            },
            "Glen Rock": {
                "lat": 40.9601,
                "lng": -74.125
            },
            "Portales": {
                "lat": 34.1754,
                "lng": -103.3565
            },
            "Lower Gwynedd": {
                "lat": 40.188,
                "lng": -75.2373
            },
            "Hasbrouck Heights": {
                "lat": 40.8618,
                "lng": -74.0741
            },
            "East Renton Highlands": {
                "lat": 47.4718,
                "lng": -122.0854
            },
            "Valley Falls": {
                "lat": 41.9233,
                "lng": -71.3924
            },
            "Woodward": {
                "lat": 36.4247,
                "lng": -99.4058
            },
            "Mays Chapel": {
                "lat": 39.4343,
                "lng": -76.6516
            },
            "Destrehan": {
                "lat": 29.9626,
                "lng": -90.3676
            },
            "Gardnerville Ranchos": {
                "lat": 38.8957,
                "lng": -119.7492
            },
            "Honeygo": {
                "lat": 39.4055,
                "lng": -76.4282
            },
            "North Lebanon": {
                "lat": 40.3668,
                "lng": -76.4215
            },
            "St. Peter": {
                "lat": 44.3295,
                "lng": -93.9658
            },
            "Celebration": {
                "lat": 28.3102,
                "lng": -81.551
            },
            "Liberty Lake": {
                "lat": 47.6686,
                "lng": -117.1036
            },
            "Rosaryville": {
                "lat": 38.7672,
                "lng": -76.8266
            },
            "River Edge": {
                "lat": 40.9269,
                "lng": -74.0387
            },
            "Key Largo": {
                "lat": 25.1224,
                "lng": -80.412
            },
            "Yeadon": {
                "lat": 39.9325,
                "lng": -75.2527
            },
            "Fort Campbell North": {
                "lat": 36.6631,
                "lng": -87.4764
            },
            "Saddlebrooke": {
                "lat": 32.5576,
                "lng": -110.874
            },
            "Monfort Heights": {
                "lat": 39.1823,
                "lng": -84.6075
            },
            "Verde Village": {
                "lat": 34.7119,
                "lng": -111.9941
            },
            "View Park-Windsor Hills": {
                "lat": 33.9955,
                "lng": -118.3483
            },
            "Arkansas City": {
                "lat": 37.0726,
                "lng": -97.0385
            },
            "Somersworth": {
                "lat": 43.2534,
                "lng": -70.8856
            },
            "Rapho": {
                "lat": 40.1576,
                "lng": -76.458
            },
            "Winton": {
                "lat": 37.3854,
                "lng": -120.6173
            },
            "Star": {
                "lat": 43.7026,
                "lng": -116.4914
            },
            "Fenton": {
                "lat": 42.7994,
                "lng": -83.7144
            },
            "Palos Heights": {
                "lat": 41.6637,
                "lng": -87.7958
            },
            "Ballston": {
                "lat": 42.9542,
                "lng": -73.8794
            },
            "Spencer": {
                "lat": 43.1468,
                "lng": -95.1534
            },
            "South Miami": {
                "lat": 25.7079,
                "lng": -80.2952
            },
            "Goulds": {
                "lat": 25.5614,
                "lng": -80.388
            },
            "Athol": {
                "lat": 42.5841,
                "lng": -72.2177
            },
            "Gig Harbor": {
                "lat": 47.3352,
                "lng": -122.5964
            },
            "Bound Brook": {
                "lat": 40.5676,
                "lng": -74.5383
            },
            "Box Elder": {
                "lat": 44.112,
                "lng": -103.0818
            },
            "East Bethel": {
                "lat": 45.3557,
                "lng": -93.2038
            },
            "Hamilton Square": {
                "lat": 40.2248,
                "lng": -74.6526
            },
            "Dudley": {
                "lat": 42.055,
                "lng": -71.9352
            },
            "Archdale": {
                "lat": 35.9032,
                "lng": -79.9591
            },
            "Linganore": {
                "lat": 39.4127,
                "lng": -77.3014
            },
            "Lake Elmo": {
                "lat": 44.9944,
                "lng": -92.9031
            },
            "Big Lake": {
                "lat": 45.3417,
                "lng": -93.7434
            },
            "Miller Place": {
                "lat": 40.9374,
                "lng": -72.9864
            },
            "Wallington": {
                "lat": 40.8536,
                "lng": -74.1069
            },
            "Middleburg": {
                "lat": 30.0502,
                "lng": -81.9011
            },
            "Bordentown": {
                "lat": 40.142,
                "lng": -74.7098
            },
            "Alum Rock": {
                "lat": 37.3694,
                "lng": -121.8238
            },
            "Endwell": {
                "lat": 42.1184,
                "lng": -76.0219
            },
            "Poulsbo": {
                "lat": 47.7417,
                "lng": -122.6407
            },
            "North Union": {
                "lat": 39.9101,
                "lng": -79.6733
            },
            "Castle Pines": {
                "lat": 39.4625,
                "lng": -104.8706
            },
            "South Yarmouth": {
                "lat": 41.6692,
                "lng": -70.2005
            },
            "Winfield": {
                "lat": 41.8787,
                "lng": -88.1507
            },
            "Picayune": {
                "lat": 30.5322,
                "lng": -89.6724
            },
            "Muscoy": {
                "lat": 34.1552,
                "lng": -117.3477
            },
            "Little Chute": {
                "lat": 44.2906,
                "lng": -88.3208
            },
            "Mountain Top": {
                "lat": 41.1353,
                "lng": -75.9045
            },
            "Minden": {
                "lat": 32.6187,
                "lng": -93.2762
            },
            "Oak Hills": {
                "lat": 45.5405,
                "lng": -122.8413
            },
            "Tarrytown": {
                "lat": 41.0647,
                "lng": -73.8672
            },
            "Putnam Valley": {
                "lat": 41.398,
                "lng": -73.8368
            },
            "Green River": {
                "lat": 41.5127,
                "lng": -109.471
            },
            "Show Low": {
                "lat": 34.2671,
                "lng": -110.0384
            },
            "Guttenberg": {
                "lat": 40.7928,
                "lng": -74.0049
            },
            "Mack": {
                "lat": 39.1492,
                "lng": -84.6794
            },
            "Mapleton": {
                "lat": 40.1188,
                "lng": -111.5742
            },
            "Lawrenceburg": {
                "lat": 35.2497,
                "lng": -87.3325
            },
            "Rossmoor": {
                "lat": 33.7887,
                "lng": -118.0803
            },
            "Lunenburg": {
                "lat": 42.5897,
                "lng": -71.7199
            },
            "Los Alamitos": {
                "lat": 33.7971,
                "lng": -118.0592
            },
            "Shady Hills": {
                "lat": 28.4042,
                "lng": -82.5468
            },
            "Spring Lake": {
                "lat": 35.1843,
                "lng": -78.9962
            },
            "Lantana": {
                "lat": 26.5834,
                "lng": -80.0564
            },
            "Lower Burrell": {
                "lat": 40.5818,
                "lng": -79.7141
            },
            "Elma": {
                "lat": 42.8231,
                "lng": -78.6371
            },
            "Ringwood": {
                "lat": 41.1065,
                "lng": -74.2749
            },
            "James Island": {
                "lat": 32.7353,
                "lng": -79.9394
            },
            "Irmo": {
                "lat": 34.1018,
                "lng": -81.1956
            },
            "Seven Hills": {
                "lat": 41.3803,
                "lng": -81.6736
            },
            "Signal Hill": {
                "lat": 33.803,
                "lng": -118.1681
            },
            "Center": {
                "lat": 40.6483,
                "lng": -80.2977
            },
            "Bellmawr": {
                "lat": 39.8666,
                "lng": -75.0941
            },
            "Vermillion": {
                "lat": 42.7811,
                "lng": -96.9256
            },
            "Dent": {
                "lat": 39.1915,
                "lng": -84.6601
            },
            "Lanham": {
                "lat": 38.962,
                "lng": -76.8421
            },
            "La Riviera": {
                "lat": 38.5683,
                "lng": -121.3544
            },
            "Newport East": {
                "lat": 41.5159,
                "lng": -71.2878
            },
            "Emmaus": {
                "lat": 40.5352,
                "lng": -75.4978
            },
            "Bucyrus": {
                "lat": 40.8054,
                "lng": -82.9719
            },
            "Mendota Heights": {
                "lat": 44.8815,
                "lng": -93.14
            },
            "Mount Sinai": {
                "lat": 40.9372,
                "lng": -73.0179
            },
            "Carver": {
                "lat": 41.8739,
                "lng": -70.7563
            },
            "Miami Shores": {
                "lat": 25.867,
                "lng": -80.1779
            },
            "Half Moon Bay": {
                "lat": 37.4685,
                "lng": -122.4382
            },
            "Pepperell": {
                "lat": 42.6713,
                "lng": -71.6043
            },
            "Bayou Blue": {
                "lat": 29.6341,
                "lng": -90.6733
            },
            "Middle Valley": {
                "lat": 35.1877,
                "lng": -85.1959
            },
            "Schiller Park": {
                "lat": 41.9586,
                "lng": -87.8693
            },
            "Hartsville": {
                "lat": 36.3921,
                "lng": -86.1568
            },
            "Markham": {
                "lat": 41.6,
                "lng": -87.6904
            },
            "Roessleville": {
                "lat": 42.6969,
                "lng": -73.7964
            },
            "Minnehaha": {
                "lat": 45.6577,
                "lng": -122.6204
            },
            "Moss Bluff": {
                "lat": 30.3039,
                "lng": -93.2051
            },
            "Town and Country": {
                "lat": 38.6317,
                "lng": -90.479
            },
            "River Forest": {
                "lat": 41.895,
                "lng": -87.8194
            },
            "Kennebunk": {
                "lat": 43.3972,
                "lng": -70.5707
            },
            "Gold Canyon": {
                "lat": 33.3639,
                "lng": -111.423
            },
            "Gardere": {
                "lat": 30.3582,
                "lng": -91.1346
            },
            "Forest": {
                "lat": 37.3728,
                "lng": -79.2831
            },
            "Stuarts Draft": {
                "lat": 38.0188,
                "lng": -79.0354
            },
            "Sussex": {
                "lat": 43.1346,
                "lng": -88.2226
            },
            "Port Lavaca": {
                "lat": 28.6181,
                "lng": -96.6278
            },
            "Platteville": {
                "lat": 42.728,
                "lng": -90.4681
            },
            "Roma": {
                "lat": 26.4166,
                "lng": -99.0061
            },
            "Loyalsock": {
                "lat": 41.2743,
                "lng": -76.9839
            },
            "Grosse Pointe Park": {
                "lat": 42.3794,
                "lng": -82.9287
            },
            "Burley": {
                "lat": 42.5379,
                "lng": -113.7926
            },
            "Mexico": {
                "lat": 39.1625,
                "lng": -91.8712
            },
            "Fort Morgan": {
                "lat": 40.2537,
                "lng": -103.7903
            },
            "Lovington": {
                "lat": 32.9128,
                "lng": -103.3276
            },
            "Elk City": {
                "lat": 35.3862,
                "lng": -99.4301
            },
            "Oskaloosa": {
                "lat": 41.2922,
                "lng": -92.6404
            },
            "Ridgefield": {
                "lat": 40.8313,
                "lng": -74.0147
            },
            "Leon Valley": {
                "lat": 29.4954,
                "lng": -98.6143
            },
            "Healdsburg": {
                "lat": 38.6224,
                "lng": -122.8651
            },
            "Lindon": {
                "lat": 40.3414,
                "lng": -111.7187
            },
            "East Glenville": {
                "lat": 42.8648,
                "lng": -73.9256
            },
            "Bath": {
                "lat": 43.9346,
                "lng": -69.8346
            },
            "Sun Village": {
                "lat": 34.5596,
                "lng": -117.9558
            },
            "Fuller Heights": {
                "lat": 27.9227,
                "lng": -81.9978
            },
            "Pike Creek Valley": {
                "lat": 39.7294,
                "lng": -75.6989
            },
            "Wilton Manors": {
                "lat": 26.1593,
                "lng": -80.1395
            },
            "North Wantagh": {
                "lat": 40.6983,
                "lng": -73.5086
            },
            "New Garden": {
                "lat": 39.8119,
                "lng": -75.7517
            },
            "University of California-Santa Barbara": {
                "lat": 34.4151,
                "lng": -119.8568
            },
            "Port Salerno": {
                "lat": 27.1461,
                "lng": -80.1894
            },
            "Gloucester City": {
                "lat": 39.8924,
                "lng": -75.1172
            },
            "Campbellsville": {
                "lat": 37.3445,
                "lng": -85.3511
            },
            "Woods Cross": {
                "lat": 40.8731,
                "lng": -111.917
            },
            "Dobbs Ferry": {
                "lat": 41.0127,
                "lng": -73.8697
            },
            "Rostraver": {
                "lat": 40.169,
                "lng": -79.8087
            },
            "Clawson": {
                "lat": 42.5367,
                "lng": -83.1504
            },
            "East Grand Rapids": {
                "lat": 42.9464,
                "lng": -85.6088
            },
            "Snyder": {
                "lat": 32.7133,
                "lng": -100.9116
            },
            "Suffern": {
                "lat": 41.1138,
                "lng": -74.1422
            },
            "Morgan City": {
                "lat": 29.7041,
                "lng": -91.192
            },
            "Okmulgee": {
                "lat": 35.6134,
                "lng": -96.0068
            },
            "Wells": {
                "lat": 43.3267,
                "lng": -70.6336
            },
            "Progress": {
                "lat": 40.2901,
                "lng": -76.8394
            },
            "Medulla": {
                "lat": 27.957,
                "lng": -81.9866
            },
            "Catskill": {
                "lat": 42.2063,
                "lng": -73.9435
            },
            "Maltby": {
                "lat": 47.8027,
                "lng": -122.1044
            },
            "Cocoa Beach": {
                "lat": 28.3327,
                "lng": -80.6274
            },
            "Ravenna": {
                "lat": 41.1612,
                "lng": -81.2422
            },
            "Taylorville": {
                "lat": 39.5328,
                "lng": -89.2804
            },
            "Norwell": {
                "lat": 42.1608,
                "lng": -70.8177
            },
            "Lakeland Highlands": {
                "lat": 27.9572,
                "lng": -81.9496
            },
            "Lowes Island": {
                "lat": 39.0471,
                "lng": -77.3524
            },
            "Manvel": {
                "lat": 29.4798,
                "lng": -95.3635
            },
            "Westphalia": {
                "lat": 38.8356,
                "lng": -76.8298
            },
            "Ocean City": {
                "lat": 39.2681,
                "lng": -74.602
            },
            "North Valley": {
                "lat": 35.1736,
                "lng": -106.6231
            },
            "Groton": {
                "lat": 42.6137,
                "lng": -71.5614
            },
            "Two Rivers": {
                "lat": 44.1565,
                "lng": -87.5824
            },
            "Kings Mountain": {
                "lat": 35.2349,
                "lng": -81.3501
            },
            "Willistown": {
                "lat": 40.001,
                "lng": -75.4915
            },
            "West Point": {
                "lat": 33.6064,
                "lng": -88.6572
            },
            "Lincolnton": {
                "lat": 35.4748,
                "lng": -81.2386
            },
            "Granbury": {
                "lat": 32.4475,
                "lng": -97.7702
            },
            "Wanaque": {
                "lat": 41.044,
                "lng": -74.29
            },
            "Abbeville": {
                "lat": 29.9751,
                "lng": -92.1265
            },
            "Storm Lake": {
                "lat": 42.6431,
                "lng": -95.196
            },
            "Boiling Springs": {
                "lat": 35.045,
                "lng": -81.9779
            },
            "Satellite Beach": {
                "lat": 28.1782,
                "lng": -80.6019
            },
            "Westtown": {
                "lat": 39.9417,
                "lng": -75.5565
            },
            "Tucson Mountains": {
                "lat": 32.2822,
                "lng": -111.0773
            },
            "New Port Richey East": {
                "lat": 28.2605,
                "lng": -82.6931
            },
            "Batesville": {
                "lat": 35.7687,
                "lng": -91.6227
            },
            "Piedmont": {
                "lat": 37.8225,
                "lng": -122.2301
            },
            "Elon": {
                "lat": 36.1016,
                "lng": -79.5086
            },
            "Cherry Creek": {
                "lat": 39.6094,
                "lng": -104.8645
            },
            "Inwood": {
                "lat": 40.6219,
                "lng": -73.7506
            },
            "North Lindenhurst": {
                "lat": 40.7072,
                "lng": -73.3859
            },
            "Sierra Madre": {
                "lat": 34.1687,
                "lng": -118.0504
            },
            "Birch Bay": {
                "lat": 48.9243,
                "lng": -122.7519
            },
            "Lloyd": {
                "lat": 41.7286,
                "lng": -73.9961
            },
            "Waipio": {
                "lat": 21.4143,
                "lng": -157.9966
            },
            "Roscoe": {
                "lat": 42.4256,
                "lng": -89.0084
            },
            "Burr Ridge": {
                "lat": 41.7485,
                "lng": -87.92
            },
            "Orono": {
                "lat": 44.8867,
                "lng": -68.7166
            },
            "Elwood": {
                "lat": 40.8462,
                "lng": -73.3389
            },
            "White City": {
                "lat": 42.4316,
                "lng": -122.8322
            },
            "Waupun": {
                "lat": 43.6314,
                "lng": -88.7381
            },
            "Port Wentworth": {
                "lat": 32.1951,
                "lng": -81.1991
            },
            "East Hanover": {
                "lat": 40.8192,
                "lng": -74.3638
            },
            "Alcoa": {
                "lat": 35.8076,
                "lng": -83.9753
            },
            "Raceland": {
                "lat": 29.7282,
                "lng": -90.6362
            },
            "Canandaigua": {
                "lat": 42.8608,
                "lng": -77.3182
            },
            "Citrus Springs": {
                "lat": 28.9931,
                "lng": -82.4594
            },
            "Merriam": {
                "lat": 39.0186,
                "lng": -94.6933
            },
            "Wyomissing": {
                "lat": 40.3317,
                "lng": -75.9703
            },
            "Lake Grove": {
                "lat": 40.8586,
                "lng": -73.1168
            },
            "Leicester": {
                "lat": 42.24,
                "lng": -71.912
            },
            "Marinette": {
                "lat": 45.0871,
                "lng": -87.6336
            },
            "Great Neck": {
                "lat": 40.8029,
                "lng": -73.7333
            },
            "Lower Saucon": {
                "lat": 40.5881,
                "lng": -75.3188
            },
            "Coshocton": {
                "lat": 40.2618,
                "lng": -81.848
            },
            "Monmouth": {
                "lat": 40.914,
                "lng": -90.6425
            },
            "Pompton Lakes": {
                "lat": 41.0024,
                "lng": -74.2859
            },
            "Sturgis": {
                "lat": 41.7991,
                "lng": -85.4184
            },
            "Petal": {
                "lat": 31.3477,
                "lng": -89.2359
            },
            "Magnolia": {
                "lat": 33.2775,
                "lng": -93.2261
            },
            "Bithlo": {
                "lat": 28.5644,
                "lng": -81.1073
            },
            "Hornsby Bend": {
                "lat": 30.2388,
                "lng": -97.5899
            },
            "Waihee-Waiehu": {
                "lat": 20.9188,
                "lng": -156.5063
            },
            "Cold Springs": {
                "lat": 39.6927,
                "lng": -119.9775
            },
            "Greentree": {
                "lat": 39.8989,
                "lng": -74.9613
            },
            "Gloucester Point": {
                "lat": 37.2767,
                "lng": -76.5043
            },
            "Fairview Shores": {
                "lat": 28.6021,
                "lng": -81.3958
            },
            "Franklin Lakes": {
                "lat": 41.0086,
                "lng": -74.2083
            },
            "Sebring": {
                "lat": 27.477,
                "lng": -81.453
            },
            "Collegedale": {
                "lat": 35.0526,
                "lng": -85.0488
            },
            "Lyndon": {
                "lat": 38.2645,
                "lng": -85.5891
            },
            "Westview": {
                "lat": 25.8825,
                "lng": -80.2415
            },
            "North Logan": {
                "lat": 41.7759,
                "lng": -111.8066
            },
            "Burkburnett": {
                "lat": 34.0746,
                "lng": -98.5672
            },
            "Citrus": {
                "lat": 34.116,
                "lng": -117.8891
            },
            "Pebble Creek": {
                "lat": 28.1583,
                "lng": -82.3411
            },
            "Totowa": {
                "lat": 40.9039,
                "lng": -74.2214
            },
            "Easttown": {
                "lat": 40.0281,
                "lng": -75.4403
            },
            "North Branch": {
                "lat": 45.5137,
                "lng": -92.9601
            },
            "Grand Haven": {
                "lat": 43.0553,
                "lng": -86.2201
            },
            "Little Ferry": {
                "lat": 40.8464,
                "lng": -74.0388
            },
            "Pleasant View": {
                "lat": 41.3249,
                "lng": -112.0011
            },
            "Pompton Plains": {
                "lat": 40.9679,
                "lng": -74.3075
            },
            "Belgrade": {
                "lat": 45.7789,
                "lng": -111.1736
            },
            "Vashon": {
                "lat": 47.4122,
                "lng": -122.4726
            },
            "The Hills": {
                "lat": 40.6561,
                "lng": -74.6215
            },
            "Bedford Heights": {
                "lat": 41.4041,
                "lng": -81.5053
            },
            "Valley Center": {
                "lat": 33.233,
                "lng": -117.0158
            },
            "West Caldwell": {
                "lat": 40.8488,
                "lng": -74.2971
            },
            "Prineville": {
                "lat": 44.2985,
                "lng": -120.8607
            },
            "Timberlane": {
                "lat": 29.8781,
                "lng": -90.0303
            },
            "Rincon": {
                "lat": 32.2947,
                "lng": -81.2353
            },
            "Lehman": {
                "lat": 41.1518,
                "lng": -74.9924
            },
            "Chenango": {
                "lat": 42.1954,
                "lng": -75.8989
            },
            "Pocono": {
                "lat": 41.0612,
                "lng": -75.3102
            },
            "Gages Lake": {
                "lat": 42.3519,
                "lng": -87.9828
            },
            "Delhi": {
                "lat": 37.4306,
                "lng": -120.7759
            },
            "Manville": {
                "lat": 40.542,
                "lng": -74.5892
            },
            "Berthoud": {
                "lat": 40.3071,
                "lng": -105.0426
            },
            "Haysville": {
                "lat": 37.5648,
                "lng": -97.3527
            },
            "Terryville": {
                "lat": 40.9093,
                "lng": -73.0486
            },
            "Fife": {
                "lat": 47.2329,
                "lng": -122.3518
            },
            "Triangle": {
                "lat": 38.5483,
                "lng": -77.3195
            },
            "Guthrie": {
                "lat": 35.8424,
                "lng": -97.4366
            },
            "Peru": {
                "lat": 41.3482,
                "lng": -89.1371
            },
            "Malvern": {
                "lat": 34.3734,
                "lng": -92.8205
            },
            "Worth": {
                "lat": 41.6877,
                "lng": -87.7916
            },
            "Wantage": {
                "lat": 41.2431,
                "lng": -74.6258
            },
            "Kapaa": {
                "lat": 22.091,
                "lng": -159.3521
            },
            "Travilah": {
                "lat": 39.057,
                "lng": -77.247
            },
            "Mount Kisco": {
                "lat": 41.2016,
                "lng": -73.7281
            },
            "Lake Monticello": {
                "lat": 37.921,
                "lng": -78.3295
            },
            "Atchison": {
                "lat": 39.5625,
                "lng": -95.1367
            },
            "Van Wert": {
                "lat": 40.8651,
                "lng": -84.5879
            },
            "Martin": {
                "lat": 36.3385,
                "lng": -88.8513
            },
            "Sugarmill Woods": {
                "lat": 28.7299,
                "lng": -82.501
            },
            "Union Park": {
                "lat": 28.5657,
                "lng": -81.2355
            },
            "East Cocalico": {
                "lat": 40.2242,
                "lng": -76.1057
            },
            "Holmen": {
                "lat": 43.9706,
                "lng": -91.2654
            },
            "Fox Lake": {
                "lat": 42.4239,
                "lng": -88.1844
            },
            "South Union": {
                "lat": 39.8705,
                "lng": -79.7222
            },
            "Scaggsville": {
                "lat": 39.1416,
                "lng": -76.8843
            },
            "Timonium": {
                "lat": 39.4459,
                "lng": -76.6032
            },
            "Belle Chasse": {
                "lat": 29.8558,
                "lng": -90.0045
            },
            "Bolivar": {
                "lat": 37.6059,
                "lng": -93.4175
            },
            "Raymond": {
                "lat": 43.0322,
                "lng": -71.1994
            },
            "DeForest": {
                "lat": 43.2301,
                "lng": -89.3431
            },
            "Morro Bay": {
                "lat": 35.3682,
                "lng": -120.8482
            },
            "Del Aire": {
                "lat": 33.9167,
                "lng": -118.3693
            },
            "East Bakersfield": {
                "lat": 35.3832,
                "lng": -118.9743
            },
            "St. Albans": {
                "lat": 38.3769,
                "lng": -81.8198
            },
            "Lehigh": {
                "lat": 40.7678,
                "lng": -75.5394
            },
            "Placerville": {
                "lat": 38.7308,
                "lng": -120.7979
            },
            "Munhall": {
                "lat": 40.3935,
                "lng": -79.9005
            },
            "West Hanover": {
                "lat": 40.3635,
                "lng": -76.7468
            },
            "Vidalia": {
                "lat": 32.2125,
                "lng": -82.4019
            },
            "Middle Island": {
                "lat": 40.8857,
                "lng": -72.9454
            },
            "Linthicum": {
                "lat": 39.2088,
                "lng": -76.6626
            },
            "Westwood Lakes": {
                "lat": 25.7237,
                "lng": -80.3717
            },
            "Forestville": {
                "lat": 38.8518,
                "lng": -76.8708
            },
            "Edwards": {
                "lat": 39.6215,
                "lng": -106.6184
            },
            "Richmond Heights": {
                "lat": 38.6309,
                "lng": -90.3332
            },
            "Waggaman": {
                "lat": 29.9373,
                "lng": -90.2354
            },
            "Sonoma": {
                "lat": 38.2902,
                "lng": -122.4598
            },
            "Malibu": {
                "lat": 34.037,
                "lng": -118.7839
            },
            "Pine Hill": {
                "lat": 39.7879,
                "lng": -74.9857
            },
            "Potomac Park": {
                "lat": 35.3636,
                "lng": -118.965
            },
            "Rancho Mission Viejo": {
                "lat": 33.514,
                "lng": -117.5618
            },
            "Tarboro": {
                "lat": 35.9046,
                "lng": -77.5563
            },
            "Doraville": {
                "lat": 33.9073,
                "lng": -84.2711
            },
            "Bogalusa": {
                "lat": 30.7812,
                "lng": -89.8633
            },
            "Lyons": {
                "lat": 41.8119,
                "lng": -87.8191
            },
            "Corning": {
                "lat": 42.147,
                "lng": -77.0561
            },
            "Darby": {
                "lat": 39.921,
                "lng": -75.261
            },
            "Powdersville": {
                "lat": 34.7826,
                "lng": -82.4959
            },
            "Fountain Inn": {
                "lat": 34.6989,
                "lng": -82.2005
            },
            "Maynard": {
                "lat": 42.4264,
                "lng": -71.4561
            },
            "Clute": {
                "lat": 29.0256,
                "lng": -95.3975
            },
            "Broomall": {
                "lat": 39.9688,
                "lng": -75.354
            },
            "La Homa": {
                "lat": 26.2796,
                "lng": -98.3575
            },
            "Myers Corner": {
                "lat": 41.5864,
                "lng": -73.8793
            },
            "West Haverstraw": {
                "lat": 41.2063,
                "lng": -73.9884
            },
            "Essex Junction": {
                "lat": 44.4902,
                "lng": -73.1141
            },
            "Galena Park": {
                "lat": 29.7452,
                "lng": -95.2333
            },
            "Friendly": {
                "lat": 38.7601,
                "lng": -76.9642
            },
            "Bellefontaine Neighbors": {
                "lat": 38.7529,
                "lng": -90.228
            },
            "Bel Air": {
                "lat": 39.5348,
                "lng": -76.346
            },
            "Kendall Park": {
                "lat": 40.4135,
                "lng": -74.5631
            },
            "Little Canada": {
                "lat": 45.0244,
                "lng": -93.0863
            },
            "Blackhawk": {
                "lat": 37.8159,
                "lng": -121.9071
            },
            "Mount Airy": {
                "lat": 39.3742,
                "lng": -77.1534
            },
            "Hanson": {
                "lat": 42.0558,
                "lng": -70.8723
            },
            "Nanticoke": {
                "lat": 41.2005,
                "lng": -76.0003
            },
            "Douglass": {
                "lat": 40.3438,
                "lng": -75.5909
            },
            "West Perrine": {
                "lat": 25.6061,
                "lng": -80.3639
            },
            "Ironton": {
                "lat": 38.5323,
                "lng": -82.6779
            },
            "Lake Barcroft": {
                "lat": 38.8514,
                "lng": -77.1579
            },
            "Sumner": {
                "lat": 47.2189,
                "lng": -122.2338
            },
            "Wendell": {
                "lat": 35.7819,
                "lng": -78.3952
            },
            "Yazoo City": {
                "lat": 32.8619,
                "lng": -90.4075
            },
            "Alachua": {
                "lat": 29.7779,
                "lng": -82.4832
            },
            "Airway Heights": {
                "lat": 47.6459,
                "lng": -117.5792
            },
            "Scottdale": {
                "lat": 33.795,
                "lng": -84.2634
            },
            "Lakeland Village": {
                "lat": 33.648,
                "lng": -117.3706
            },
            "Yorkshire": {
                "lat": 38.7882,
                "lng": -77.4496
            },
            "Acushnet": {
                "lat": 41.7138,
                "lng": -70.9012
            },
            "Millington": {
                "lat": 35.335,
                "lng": -89.8991
            },
            "Pella": {
                "lat": 41.4052,
                "lng": -92.9189
            },
            "Campton Hills": {
                "lat": 41.9498,
                "lng": -88.4166
            },
            "Le Mars": {
                "lat": 42.781,
                "lng": -96.1733
            },
            "Monument": {
                "lat": 39.0736,
                "lng": -104.8467
            },
            "Excelsior Springs": {
                "lat": 39.339,
                "lng": -94.24
            },
            "The Pinery": {
                "lat": 39.4462,
                "lng": -104.7591
            },
            "River Grove": {
                "lat": 41.9243,
                "lng": -87.8379
            },
            "North Bellport": {
                "lat": 40.7868,
                "lng": -72.9457
            },
            "Alderwood Manor": {
                "lat": 47.8146,
                "lng": -122.2672
            },
            "Saks": {
                "lat": 33.7118,
                "lng": -85.8536
            },
            "Felida": {
                "lat": 45.7138,
                "lng": -122.7103
            },
            "Loudoun Valley Estates": {
                "lat": 38.977,
                "lng": -77.5053
            },
            "Forest Acres": {
                "lat": 34.0323,
                "lng": -80.9716
            },
            "Stansbury Park": {
                "lat": 40.6356,
                "lng": -112.3054
            },
            "Winterville": {
                "lat": 35.5291,
                "lng": -77.4
            },
            "Soquel": {
                "lat": 36.9978,
                "lng": -121.9483
            },
            "Lighthouse Point": {
                "lat": 26.2785,
                "lng": -80.0891
            },
            "Hillcrest": {
                "lat": 35.379,
                "lng": -118.9578
            },
            "Heath": {
                "lat": 32.8439,
                "lng": -96.4674
            },
            "Bonham": {
                "lat": 33.588,
                "lng": -96.1901
            },
            "Somers Point": {
                "lat": 39.3167,
                "lng": -74.6066
            },
            "Lake Hopatcong": {
                "lat": 40.9599,
                "lng": -74.6094
            },
            "Bellmead": {
                "lat": 31.6026,
                "lng": -97.0897
            },
            "Espa\u00f1ola": {
                "lat": 36.0044,
                "lng": -106.0686
            },
            "Palatka": {
                "lat": 29.6493,
                "lng": -81.6705
            },
            "Vermilion": {
                "lat": 41.4103,
                "lng": -82.3214
            },
            "Waimea": {
                "lat": 20.0124,
                "lng": -155.6381
            },
            "East Bradford": {
                "lat": 39.959,
                "lng": -75.6469
            },
            "Lake Morton-Berrydale": {
                "lat": 47.3325,
                "lng": -122.1032
            },
            "Chalco": {
                "lat": 41.1817,
                "lng": -96.1353
            },
            "Standish": {
                "lat": 43.7811,
                "lng": -70.5684
            },
            "Flat Rock": {
                "lat": 42.0991,
                "lng": -83.2716
            },
            "Valley": {
                "lat": 32.8088,
                "lng": -85.181
            },
            "Silverton": {
                "lat": 45.003,
                "lng": -122.7808
            },
            "Yelm": {
                "lat": 46.9398,
                "lng": -122.6262
            },
            "Difficult Run": {
                "lat": 38.9016,
                "lng": -77.3471
            },
            "Plattekill": {
                "lat": 41.6478,
                "lng": -74.0718
            },
            "Lovejoy": {
                "lat": 33.4426,
                "lng": -84.3177
            },
            "Warr Acres": {
                "lat": 35.5285,
                "lng": -97.6182
            },
            "Southborough": {
                "lat": 42.3012,
                "lng": -71.5297
            },
            "South Lebanon": {
                "lat": 40.3058,
                "lng": -76.3708
            },
            "Rifle": {
                "lat": 39.5362,
                "lng": -107.7729
            },
            "Wabash": {
                "lat": 40.8034,
                "lng": -85.8301
            },
            "Smithville": {
                "lat": 39.4929,
                "lng": -74.4801
            },
            "Waterford": {
                "lat": 37.6429,
                "lng": -120.7553
            },
            "Marlton": {
                "lat": 38.762,
                "lng": -76.7857
            },
            "Cadillac": {
                "lat": 44.2493,
                "lng": -85.4164
            },
            "Mount Vista": {
                "lat": 45.7373,
                "lng": -122.6316
            },
            "Fairmount": {
                "lat": 43.0414,
                "lng": -76.2485
            },
            "Sleepy Hollow": {
                "lat": 41.0936,
                "lng": -73.8722
            },
            "Gateway": {
                "lat": 26.5793,
                "lng": -81.7446
            },
            "Farmersville": {
                "lat": 36.305,
                "lng": -119.2083
            },
            "Spout Springs": {
                "lat": 35.2724,
                "lng": -79.0331
            },
            "Kennett": {
                "lat": 39.8374,
                "lng": -75.6808
            },
            "Comstock Park": {
                "lat": 43.0438,
                "lng": -85.6778
            },
            "Galion": {
                "lat": 40.7385,
                "lng": -82.7792
            },
            "Winchendon": {
                "lat": 42.6667,
                "lng": -72.0487
            },
            "Millstone": {
                "lat": 40.2123,
                "lng": -74.4302
            },
            "Calimesa": {
                "lat": 33.9874,
                "lng": -117.0542
            },
            "Shasta Lake": {
                "lat": 40.679,
                "lng": -122.3775
            },
            "Arkadelphia": {
                "lat": 34.1253,
                "lng": -93.0729
            },
            "Scotchtown": {
                "lat": 41.4765,
                "lng": -74.3668
            },
            "Woodlyn": {
                "lat": 39.8774,
                "lng": -75.3445
            },
            "Brewster": {
                "lat": 41.7463,
                "lng": -70.0676
            },
            "Chestnut Ridge": {
                "lat": 41.0829,
                "lng": -74.0551
            },
            "White Marsh": {
                "lat": 39.3819,
                "lng": -76.4574
            },
            "Trumbull Center": {
                "lat": 41.2415,
                "lng": -73.1836
            },
            "Oneida": {
                "lat": 43.0769,
                "lng": -75.6622
            },
            "Watervliet": {
                "lat": 42.7243,
                "lng": -73.7068
            },
            "Berwick": {
                "lat": 41.0555,
                "lng": -76.2492
            },
            "Geneseo": {
                "lat": 42.8038,
                "lng": -77.7783
            },
            "London": {
                "lat": 39.8935,
                "lng": -83.4375
            },
            "Burtonsville": {
                "lat": 39.1166,
                "lng": -76.9356
            },
            "West Vero Corridor": {
                "lat": 27.6363,
                "lng": -80.4844
            },
            "Bacliff": {
                "lat": 29.5085,
                "lng": -94.9888
            },
            "Wood River": {
                "lat": 38.8631,
                "lng": -90.0774
            },
            "Brookdale": {
                "lat": 40.8348,
                "lng": -74.1798
            },
            "South Huntington": {
                "lat": 40.8225,
                "lng": -73.3921
            },
            "Waynesville": {
                "lat": 35.4854,
                "lng": -82.9996
            },
            "Raymondville": {
                "lat": 26.4759,
                "lng": -97.7769
            },
            "Versailles": {
                "lat": 38.0486,
                "lng": -84.7258
            },
            "Murrells Inlet": {
                "lat": 33.556,
                "lng": -79.0594
            },
            "Fort Madison": {
                "lat": 40.6207,
                "lng": -91.3509
            },
            "Lenoir City": {
                "lat": 35.8111,
                "lng": -84.2818
            },
            "La Plata": {
                "lat": 38.5352,
                "lng": -76.97
            },
            "Marina del Rey": {
                "lat": 33.9765,
                "lng": -118.4486
            },
            "Tremonton": {
                "lat": 41.7187,
                "lng": -112.189
            },
            "Carroll": {
                "lat": 42.0699,
                "lng": -94.8646
            },
            "Pine Ridge": {
                "lat": 28.933,
                "lng": -82.4761
            },
            "Tipp City": {
                "lat": 39.9644,
                "lng": -84.1866
            },
            "Rockport": {
                "lat": 28.029,
                "lng": -97.0722
            },
            "North Lakes": {
                "lat": 61.6191,
                "lng": -149.311
            },
            "Elkhorn": {
                "lat": 42.6713,
                "lng": -88.5377
            },
            "North Bend": {
                "lat": 43.4075,
                "lng": -124.2364
            },
            "Boaz": {
                "lat": 34.1985,
                "lng": -86.1529
            },
            "East Whittier": {
                "lat": 33.9244,
                "lng": -117.9887
            },
            "Robstown": {
                "lat": 27.794,
                "lng": -97.6692
            },
            "San Diego Country Estates": {
                "lat": 33.0094,
                "lng": -116.7874
            },
            "New Hyde Park": {
                "lat": 40.7323,
                "lng": -73.6858
            },
            "East Setauket": {
                "lat": 40.921,
                "lng": -73.0942
            },
            "Rotonda": {
                "lat": 26.8844,
                "lng": -82.2791
            },
            "Halfway": {
                "lat": 39.6162,
                "lng": -77.7703
            },
            "Pingree Grove": {
                "lat": 42.0855,
                "lng": -88.4358
            },
            "Cordele": {
                "lat": 31.9563,
                "lng": -83.7694
            },
            "Flowood": {
                "lat": 32.3359,
                "lng": -90.0802
            },
            "Fort Oglethorpe": {
                "lat": 34.9318,
                "lng": -85.246
            },
            "Alpena": {
                "lat": 45.074,
                "lng": -83.4402
            },
            "Carencro": {
                "lat": 30.3126,
                "lng": -92.0388
            },
            "Claymont": {
                "lat": 39.8032,
                "lng": -75.4607
            },
            "Lake Arrowhead": {
                "lat": 34.2531,
                "lng": -117.1944
            },
            "Rainbow City": {
                "lat": 33.9336,
                "lng": -86.0922
            },
            "Astoria": {
                "lat": 46.1856,
                "lng": -123.8053
            },
            "Kendallville": {
                "lat": 41.4441,
                "lng": -85.2578
            },
            "Cedartown": {
                "lat": 34.0223,
                "lng": -85.2479
            },
            "Ware": {
                "lat": 42.2806,
                "lng": -72.2843
            },
            "Port Townsend": {
                "lat": 48.122,
                "lng": -122.7877
            },
            "New Richmond": {
                "lat": 45.125,
                "lng": -92.5377
            },
            "Corte Madera": {
                "lat": 37.9238,
                "lng": -122.5128
            },
            "Hazle": {
                "lat": 40.9558,
                "lng": -75.9998
            },
            "Molalla": {
                "lat": 45.1502,
                "lng": -122.5845
            },
            "Vernal": {
                "lat": 40.4517,
                "lng": -109.5378
            },
            "North Gates": {
                "lat": 43.1718,
                "lng": -77.7064
            },
            "Hermantown": {
                "lat": 46.8057,
                "lng": -92.2407
            },
            "Snohomish": {
                "lat": 47.9276,
                "lng": -122.0969
            },
            "Hillsdale": {
                "lat": 41.0074,
                "lng": -74.044
            },
            "Garden Acres": {
                "lat": 37.9637,
                "lng": -121.2296
            },
            "Hull": {
                "lat": 42.2861,
                "lng": -70.8835
            },
            "Safford": {
                "lat": 32.8335,
                "lng": -109.6964
            },
            "Hackettstown": {
                "lat": 40.854,
                "lng": -74.8257
            },
            "Warrenton": {
                "lat": 38.8187,
                "lng": -91.1385
            },
            "Baker City": {
                "lat": 44.7749,
                "lng": -117.832
            },
            "Gunbarrel": {
                "lat": 40.0632,
                "lng": -105.1713
            },
            "Kittery": {
                "lat": 43.0998,
                "lng": -70.7126
            },
            "Glenwood Springs": {
                "lat": 39.5455,
                "lng": -107.3347
            },
            "Progress Village": {
                "lat": 27.8831,
                "lng": -82.3593
            },
            "Cairo": {
                "lat": 30.879,
                "lng": -84.205
            },
            "Quartz Hill": {
                "lat": 34.6527,
                "lng": -118.2163
            },
            "Brandywine": {
                "lat": 38.6963,
                "lng": -76.8846
            },
            "Spanish Fort": {
                "lat": 30.7254,
                "lng": -87.8597
            },
            "Cody": {
                "lat": 44.5213,
                "lng": -109.0548
            },
            "Rio Vista": {
                "lat": 38.1765,
                "lng": -121.7025
            },
            "San Elizario": {
                "lat": 31.5793,
                "lng": -106.2632
            },
            "Atoka": {
                "lat": 35.4239,
                "lng": -89.7861
            },
            "East Rockaway": {
                "lat": 40.6432,
                "lng": -73.6672
            },
            "Bee Ridge": {
                "lat": 27.2855,
                "lng": -82.4731
            },
            "Waldwick": {
                "lat": 41.0133,
                "lng": -74.1259
            },
            "Lake Hiawatha": {
                "lat": 40.8816,
                "lng": -74.3826
            },
            "Struthers": {
                "lat": 41.051,
                "lng": -80.592
            },
            "Browns Mills": {
                "lat": 39.9737,
                "lng": -74.569
            },
            "Orange Lake": {
                "lat": 41.5369,
                "lng": -74.1005
            },
            "Scenic Oaks": {
                "lat": 29.7038,
                "lng": -98.6712
            },
            "Blackwells Mills": {
                "lat": 40.4773,
                "lng": -74.5976
            },
            "Oakbrook": {
                "lat": 38.9996,
                "lng": -84.6797
            },
            "Francisville": {
                "lat": 39.1068,
                "lng": -84.7277
            },
            "Makaha": {
                "lat": 21.4739,
                "lng": -158.2102
            },
            "Glocester": {
                "lat": 41.8934,
                "lng": -71.6889
            },
            "Cross Lanes": {
                "lat": 38.4351,
                "lng": -81.7707
            },
            "Cheat Lake": {
                "lat": 39.667,
                "lng": -79.8564
            },
            "Red Hook": {
                "lat": 42.0188,
                "lng": -73.8788
            },
            "Harrisonville": {
                "lat": 38.653,
                "lng": -94.3467
            },
            "Airmont": {
                "lat": 41.0992,
                "lng": -74.0989
            },
            "East Rutherford": {
                "lat": 40.8179,
                "lng": -74.0854
            },
            "Reedsburg": {
                "lat": 43.5348,
                "lng": -89.9965
            },
            "Grosse Pointe Farms": {
                "lat": 42.4068,
                "lng": -82.8993
            },
            "George Mason": {
                "lat": 38.8356,
                "lng": -77.3186
            },
            "Chesapeake Ranch Estates": {
                "lat": 38.3574,
                "lng": -76.4147
            },
            "Mayfield": {
                "lat": 36.7371,
                "lng": -88.6447
            },
            "Roslyn": {
                "lat": 40.1311,
                "lng": -75.1374
            },
            "Fort Meade": {
                "lat": 39.1061,
                "lng": -76.7437
            },
            "North Versailles": {
                "lat": 40.3785,
                "lng": -79.8084
            },
            "Kinnelon": {
                "lat": 40.9847,
                "lng": -74.3862
            },
            "Budd Lake": {
                "lat": 40.8733,
                "lng": -74.7375
            },
            "Cape Canaveral": {
                "lat": 28.3933,
                "lng": -80.6049
            },
            "Penn Forest": {
                "lat": 40.9571,
                "lng": -75.6313
            },
            "Wood-Ridge": {
                "lat": 40.8508,
                "lng": -74.0878
            },
            "Cedar Hills": {
                "lat": 45.5047,
                "lng": -122.8053
            },
            "Walker Mill": {
                "lat": 38.8758,
                "lng": -76.8854
            },
            "Willoughby Hills": {
                "lat": 41.5873,
                "lng": -81.4333
            },
            "Colts Neck": {
                "lat": 40.2928,
                "lng": -74.168
            },
            "East Liverpool": {
                "lat": 40.6333,
                "lng": -80.5677
            },
            "Detroit Lakes": {
                "lat": 46.806,
                "lng": -95.8449
            },
            "Bastrop": {
                "lat": 32.7748,
                "lng": -91.9078
            },
            "DuPont": {
                "lat": 47.1079,
                "lng": -122.6495
            },
            "Halls": {
                "lat": 36.0817,
                "lng": -83.9344
            },
            "Muskegon Heights": {
                "lat": 43.2023,
                "lng": -86.2421
            },
            "Fallston": {
                "lat": 39.5332,
                "lng": -76.4452
            },
            "Picnic Point": {
                "lat": 47.8744,
                "lng": -122.3077
            },
            "Coal": {
                "lat": 40.7873,
                "lng": -76.5493
            },
            "Sellersburg": {
                "lat": 38.4028,
                "lng": -85.7706
            },
            "Rockcreek": {
                "lat": 45.5526,
                "lng": -122.876
            },
            "Capitola": {
                "lat": 36.9773,
                "lng": -121.9537
            },
            "Rye Brook": {
                "lat": 41.0302,
                "lng": -73.6864
            },
            "Fair Oaks Ranch": {
                "lat": 29.7467,
                "lng": -98.6376
            },
            "Ellisville": {
                "lat": 38.5897,
                "lng": -90.5884
            },
            "Uniontown": {
                "lat": 39.8993,
                "lng": -79.7246
            },
            "Coweta": {
                "lat": 35.9683,
                "lng": -95.6545
            },
            "Ogdensburg": {
                "lat": 44.7088,
                "lng": -75.4717
            },
            "Golden Hills": {
                "lat": 35.1409,
                "lng": -118.4968
            },
            "River Vale": {
                "lat": 41.0138,
                "lng": -74.008
            },
            "St. Augustine Shores": {
                "lat": 29.8039,
                "lng": -81.3086
            },
            "Beckett Ridge": {
                "lat": 39.3448,
                "lng": -84.438
            },
            "Wapakoneta": {
                "lat": 40.5664,
                "lng": -84.1916
            },
            "Keokuk": {
                "lat": 40.4095,
                "lng": -91.4031
            },
            "Greencastle": {
                "lat": 39.6432,
                "lng": -86.8418
            },
            "Sauk Village": {
                "lat": 41.4906,
                "lng": -87.5706
            },
            "Independent Hill": {
                "lat": 38.6404,
                "lng": -77.4089
            },
            "Sweet Home": {
                "lat": 44.4023,
                "lng": -122.7028
            },
            "DeRidder": {
                "lat": 30.8468,
                "lng": -93.2931
            },
            "Plains": {
                "lat": 41.2657,
                "lng": -75.8131
            },
            "Lambertville": {
                "lat": 41.7484,
                "lng": -83.6244
            },
            "Sturbridge": {
                "lat": 42.1076,
                "lng": -72.0904
            },
            "Philipstown": {
                "lat": 41.4189,
                "lng": -73.9152
            },
            "Fort Salonga": {
                "lat": 40.9068,
                "lng": -73.2996
            },
            "Succasunna": {
                "lat": 40.853,
                "lng": -74.6568
            },
            "Bardmoor": {
                "lat": 27.8574,
                "lng": -82.7534
            },
            "Loudonville": {
                "lat": 42.7068,
                "lng": -73.7665
            },
            "Jesup": {
                "lat": 31.5992,
                "lng": -81.8895
            },
            "Little River": {
                "lat": 33.8786,
                "lng": -78.6393
            },
            "Plainville": {
                "lat": 42.0141,
                "lng": -71.3364
            },
            "Vidor": {
                "lat": 30.1291,
                "lng": -93.9967
            },
            "Arden Hills": {
                "lat": 45.0721,
                "lng": -93.167
            },
            "Alden": {
                "lat": 42.9114,
                "lng": -78.5211
            },
            "College Place": {
                "lat": 46.0419,
                "lng": -118.3878
            },
            "Pleasant Valley": {
                "lat": 41.7697,
                "lng": -73.8049
            },
            "Madera Acres": {
                "lat": 37.0123,
                "lng": -120.0799
            },
            "Alamosa": {
                "lat": 37.4752,
                "lng": -105.877
            },
            "Lincoln City": {
                "lat": 44.9751,
                "lng": -124.0072
            },
            "Avon Park": {
                "lat": 27.5898,
                "lng": -81.5068
            },
            "Chevy Chase": {
                "lat": 38.9944,
                "lng": -77.0738
            },
            "Marathon": {
                "lat": 24.7263,
                "lng": -81.0374
            },
            "Bay St. Louis": {
                "lat": 30.3281,
                "lng": -89.3774
            },
            "Terrace Heights": {
                "lat": 46.6045,
                "lng": -120.4393
            },
            "Neshannock": {
                "lat": 41.0509,
                "lng": -80.352
            },
            "Stonegate": {
                "lat": 39.5357,
                "lng": -104.8032
            },
            "Garrison": {
                "lat": 39.4023,
                "lng": -76.7514
            },
            "Keansburg": {
                "lat": 40.4469,
                "lng": -74.1315
            },
            "Green Cove Springs": {
                "lat": 29.9904,
                "lng": -81.6807
            },
            "Fultondale": {
                "lat": 33.6174,
                "lng": -86.8014
            },
            "Croydon": {
                "lat": 40.0911,
                "lng": -74.8975
            },
            "Monmouth Junction": {
                "lat": 40.3754,
                "lng": -74.5582
            },
            "Sedona": {
                "lat": 34.8581,
                "lng": -111.7941
            },
            "Eagle Point": {
                "lat": 42.4677,
                "lng": -122.8016
            },
            "East Brandywine": {
                "lat": 40.0364,
                "lng": -75.7505
            },
            "Thomaston": {
                "lat": 32.8907,
                "lng": -84.3272
            },
            "Ingleside": {
                "lat": 27.8703,
                "lng": -97.2075
            },
            "Pine Lake Park": {
                "lat": 40.0017,
                "lng": -74.2595
            },
            "Fredonia": {
                "lat": 42.4407,
                "lng": -79.3319
            },
            "Mentone": {
                "lat": 34.0609,
                "lng": -117.1108
            },
            "Harvard": {
                "lat": 42.4296,
                "lng": -88.6211
            },
            "Lake Mohawk": {
                "lat": 41.0149,
                "lng": -74.664
            },
            "Flowery Branch": {
                "lat": 34.1712,
                "lng": -83.9142
            },
            "Rolesville": {
                "lat": 35.9223,
                "lng": -78.4656
            },
            "Cypress Gardens": {
                "lat": 28.0036,
                "lng": -81.6857
            },
            "White Horse": {
                "lat": 40.1919,
                "lng": -74.7023
            },
            "Eidson Road": {
                "lat": 28.6677,
                "lng": -100.4788
            },
            "Tanaina": {
                "lat": 61.6576,
                "lng": -149.4263
            },
            "Sunbury": {
                "lat": 40.8616,
                "lng": -76.7871
            },
            "Hyrum": {
                "lat": 41.6325,
                "lng": -111.8445
            },
            "Canonsburg": {
                "lat": 40.2643,
                "lng": -80.1867
            },
            "Lisbon": {
                "lat": 44.0265,
                "lng": -70.09
            },
            "Pike Road": {
                "lat": 32.2939,
                "lng": -86.09
            },
            "Silver City": {
                "lat": 32.7783,
                "lng": -108.2699
            },
            "University of Virginia": {
                "lat": 38.0405,
                "lng": -78.5164
            },
            "Countryside": {
                "lat": 39.0518,
                "lng": -77.4124
            },
            "Willow Street": {
                "lat": 39.981,
                "lng": -76.2706
            },
            "Orange Cove": {
                "lat": 36.6211,
                "lng": -119.3188
            },
            "Brewer": {
                "lat": 44.7835,
                "lng": -68.7352
            },
            "Monett": {
                "lat": 36.9218,
                "lng": -93.9278
            },
            "Morehead City": {
                "lat": 34.7308,
                "lng": -76.7388
            },
            "Itasca": {
                "lat": 41.9772,
                "lng": -88.0183
            },
            "St. Stephens": {
                "lat": 35.7642,
                "lng": -81.2746
            },
            "Sturgeon Bay": {
                "lat": 44.8228,
                "lng": -87.366
            },
            "Bluefield": {
                "lat": 37.2608,
                "lng": -81.2143
            },
            "South Strabane": {
                "lat": 40.1756,
                "lng": -80.191
            },
            "Herkimer": {
                "lat": 43.061,
                "lng": -74.9894
            },
            "Sandy Hook": {
                "lat": 41.4128,
                "lng": -73.244
            },
            "North College Hill": {
                "lat": 39.2175,
                "lng": -84.552
            },
            "Jessup": {
                "lat": 39.1488,
                "lng": -76.7772
            },
            "Oil City": {
                "lat": 41.4281,
                "lng": -79.7036
            },
            "Hartland": {
                "lat": 43.1029,
                "lng": -88.3396
            },
            "Jacinto City": {
                "lat": 29.7663,
                "lng": -95.241
            },
            "Southport": {
                "lat": 42.04,
                "lng": -76.8775
            },
            "Kensington": {
                "lat": 41.6284,
                "lng": -72.7686
            },
            "New Square": {
                "lat": 41.141,
                "lng": -74.0294
            },
            "Bladensburg": {
                "lat": 38.9424,
                "lng": -76.9264
            },
            "Aiea": {
                "lat": 21.3865,
                "lng": -157.9232
            },
            "Topsham": {
                "lat": 43.9614,
                "lng": -69.9587
            },
            "McKee City": {
                "lat": 39.4465,
                "lng": -74.6445
            },
            "Girard": {
                "lat": 41.1665,
                "lng": -80.6963
            },
            "West Glens Falls": {
                "lat": 43.3057,
                "lng": -73.6964
            },
            "Parsons": {
                "lat": 37.3405,
                "lng": -95.2958
            },
            "LaSalle": {
                "lat": 41.3589,
                "lng": -89.0737
            },
            "Lititz": {
                "lat": 40.154,
                "lng": -76.3044
            },
            "Rathdrum": {
                "lat": 47.7948,
                "lng": -116.8944
            },
            "Pine Castle": {
                "lat": 28.4651,
                "lng": -81.3741
            },
            "Lower Swatara": {
                "lat": 40.2188,
                "lng": -76.7602
            },
            "Lincoln Village": {
                "lat": 39.9532,
                "lng": -83.1314
            },
            "Boulder Hill": {
                "lat": 41.7113,
                "lng": -88.3353
            },
            "Matawan": {
                "lat": 40.4127,
                "lng": -74.2365
            },
            "Mahomet": {
                "lat": 40.1888,
                "lng": -88.3901
            },
            "Bethel": {
                "lat": 39.8458,
                "lng": -75.4891
            },
            "Bohemia": {
                "lat": 40.7717,
                "lng": -73.1271
            },
            "Meridianville": {
                "lat": 34.8729,
                "lng": -86.5722
            },
            "Tomah": {
                "lat": 43.9879,
                "lng": -90.4999
            },
            "Esopus": {
                "lat": 41.8425,
                "lng": -73.9936
            },
            "Bargersville": {
                "lat": 39.5412,
                "lng": -86.2004
            },
            "Cape Elizabeth": {
                "lat": 43.5891,
                "lng": -70.238
            },
            "Steger": {
                "lat": 41.4723,
                "lng": -87.6176
            },
            "Grinnell": {
                "lat": 41.7359,
                "lng": -92.7244
            },
            "Amityville": {
                "lat": 40.6696,
                "lng": -73.4156
            },
            "Hickam Housing": {
                "lat": 21.3311,
                "lng": -157.9474
            },
            "Iowa Colony": {
                "lat": 29.4407,
                "lng": -95.4206
            },
            "South Abington": {
                "lat": 41.49,
                "lng": -75.6891
            },
            "Oakwood": {
                "lat": 39.7202,
                "lng": -84.1734
            },
            "Berlin": {
                "lat": 44.4869,
                "lng": -71.2599
            },
            "Pryor Creek": {
                "lat": 36.2996,
                "lng": -95.3107
            },
            "The Village": {
                "lat": 35.5706,
                "lng": -97.5567
            },
            "Columbia City": {
                "lat": 41.1612,
                "lng": -85.4855
            },
            "Helena-West Helena": {
                "lat": 34.5313,
                "lng": -90.6201
            },
            "Woodfield": {
                "lat": 34.0587,
                "lng": -80.9309
            },
            "Bridge City": {
                "lat": 30.0298,
                "lng": -93.8406
            },
            "Eunice": {
                "lat": 30.4904,
                "lng": -92.4191
            },
            "Alma": {
                "lat": 43.3799,
                "lng": -84.6556
            },
            "Anaconda": {
                "lat": 46.0608,
                "lng": -113.0678
            },
            "Madeira": {
                "lat": 39.1856,
                "lng": -84.3734
            },
            "Lugoff": {
                "lat": 34.2113,
                "lng": -80.6973
            },
            "Fishersville": {
                "lat": 38.105,
                "lng": -78.9826
            },
            "Glenshaw": {
                "lat": 40.5391,
                "lng": -79.9735
            },
            "Rochelle": {
                "lat": 41.9197,
                "lng": -89.0629
            },
            "Coral Hills": {
                "lat": 38.8706,
                "lng": -76.9215
            },
            "Flossmoor": {
                "lat": 41.5391,
                "lng": -87.6858
            },
            "Mechanicsburg": {
                "lat": 40.2115,
                "lng": -77.006
            },
            "Cusseta": {
                "lat": 32.347,
                "lng": -84.787
            },
            "Newmarket": {
                "lat": 43.0691,
                "lng": -70.9516
            },
            "Woodway": {
                "lat": 31.4988,
                "lng": -97.2314
            },
            "Denham Springs": {
                "lat": 30.4743,
                "lng": -90.9593
            },
            "Middlesborough": {
                "lat": 36.6127,
                "lng": -83.7227
            },
            "Helena Valley Southeast": {
                "lat": 46.6219,
                "lng": -111.8973
            },
            "Locust Grove": {
                "lat": 33.3446,
                "lng": -84.1071
            },
            "Frankfort Square": {
                "lat": 41.5219,
                "lng": -87.8031
            },
            "Diamondhead": {
                "lat": 30.3791,
                "lng": -89.3707
            },
            "Southside": {
                "lat": 33.9007,
                "lng": -86.0238
            },
            "Canal Winchester": {
                "lat": 39.8437,
                "lng": -82.8126
            },
            "Sutton": {
                "lat": 42.1337,
                "lng": -71.7503
            },
            "East Stroudsburg": {
                "lat": 41.0023,
                "lng": -75.1779
            },
            "South Amboy": {
                "lat": 40.4852,
                "lng": -74.2831
            },
            "Crestline": {
                "lat": 34.2486,
                "lng": -117.2887
            },
            "Charter Oak": {
                "lat": 34.1025,
                "lng": -117.8564
            },
            "Rodeo": {
                "lat": 38.0367,
                "lng": -122.2526
            },
            "St. Anthony": {
                "lat": 45.0278,
                "lng": -93.2174
            },
            "Havre": {
                "lat": 48.5427,
                "lng": -109.6804
            },
            "Sheffield": {
                "lat": 34.757,
                "lng": -87.6977
            },
            "Fort Stewart": {
                "lat": 31.8811,
                "lng": -81.6131
            },
            "Bull Mountain": {
                "lat": 45.4125,
                "lng": -122.832
            },
            "Angola": {
                "lat": 41.6433,
                "lng": -85.005
            },
            "Quakertown": {
                "lat": 40.4398,
                "lng": -75.3455
            },
            "East Franklin": {
                "lat": 40.4933,
                "lng": -74.471
            },
            "Ecorse": {
                "lat": 42.2489,
                "lng": -83.1399
            },
            "Skidaway Island": {
                "lat": 31.9372,
                "lng": -81.0449
            },
            "Newfane": {
                "lat": 43.2818,
                "lng": -78.6932
            },
            "St. Francis": {
                "lat": 42.9716,
                "lng": -87.873
            },
            "Bernalillo": {
                "lat": 35.3127,
                "lng": -106.5537
            },
            "Ventnor City": {
                "lat": 39.3457,
                "lng": -74.486
            },
            "Bethalto": {
                "lat": 38.9015,
                "lng": -90.0466
            },
            "Laurens": {
                "lat": 34.5022,
                "lng": -82.0207
            },
            "Leonia": {
                "lat": 40.8638,
                "lng": -73.99
            },
            "Mound": {
                "lat": 44.9328,
                "lng": -93.6591
            },
            "Wasilla": {
                "lat": 61.577,
                "lng": -149.466
            },
            "Merrill": {
                "lat": 45.182,
                "lng": -89.6994
            },
            "Macedon": {
                "lat": 43.0792,
                "lng": -77.3095
            },
            "Walworth": {
                "lat": 43.1633,
                "lng": -77.3131
            },
            "Picture Rocks": {
                "lat": 32.3274,
                "lng": -111.2558
            },
            "Fairfield Glade": {
                "lat": 36.0028,
                "lng": -84.8711
            },
            "Rensselaer": {
                "lat": 42.6465,
                "lng": -73.7328
            },
            "Sugar Grove": {
                "lat": 41.7759,
                "lng": -88.4481
            },
            "Magalia": {
                "lat": 39.8228,
                "lng": -121.6078
            },
            "Haiku-Pauwela": {
                "lat": 20.9156,
                "lng": -156.3022
            },
            "Southwick": {
                "lat": 42.0544,
                "lng": -72.7785
            },
            "Fallon": {
                "lat": 39.4737,
                "lng": -118.7779
            },
            "Hailey": {
                "lat": 43.5141,
                "lng": -114.3001
            },
            "White Meadow Lake": {
                "lat": 40.9241,
                "lng": -74.5107
            },
            "Washington Terrace": {
                "lat": 41.1683,
                "lng": -111.9783
            },
            "Shawano": {
                "lat": 44.7748,
                "lng": -88.5843
            },
            "Cape St. Claire": {
                "lat": 39.0433,
                "lng": -76.4471
            },
            "Waseca": {
                "lat": 44.0827,
                "lng": -93.5029
            },
            "O'Hara": {
                "lat": 40.5092,
                "lng": -79.8949
            },
            "Palm Beach": {
                "lat": 26.6945,
                "lng": -80.0408
            },
            "Freetown": {
                "lat": 41.7714,
                "lng": -71.0157
            },
            "Aliquippa": {
                "lat": 40.6155,
                "lng": -80.2547
            },
            "Panthersville": {
                "lat": 33.7059,
                "lng": -84.2763
            },
            "Blackstone": {
                "lat": 42.0399,
                "lng": -71.5313
            },
            "Conshohocken": {
                "lat": 40.0772,
                "lng": -75.3034
            },
            "Maidencreek": {
                "lat": 40.4618,
                "lng": -75.8927
            },
            "Grants": {
                "lat": 35.1538,
                "lng": -107.8335
            },
            "Ahuimanu": {
                "lat": 21.4379,
                "lng": -157.8404
            },
            "Ivins": {
                "lat": 37.1742,
                "lng": -113.6809
            },
            "Rockingham": {
                "lat": 34.9386,
                "lng": -79.7608
            },
            "Tuskegee": {
                "lat": 32.4409,
                "lng": -85.7131
            },
            "West Manheim": {
                "lat": 39.7458,
                "lng": -76.9431
            },
            "Micco": {
                "lat": 27.8683,
                "lng": -80.51
            },
            "Flanders": {
                "lat": 40.8412,
                "lng": -74.7102
            },
            "Incline Village": {
                "lat": 39.2639,
                "lng": -119.9455
            },
            "Springs": {
                "lat": 41.0212,
                "lng": -72.1584
            },
            "Seven Corners": {
                "lat": 38.8658,
                "lng": -77.1445
            },
            "Audubon": {
                "lat": 39.8906,
                "lng": -75.0722
            },
            "Helotes": {
                "lat": 29.5687,
                "lng": -98.696
            },
            "Des Peres": {
                "lat": 38.5973,
                "lng": -90.448
            },
            "Forestdale": {
                "lat": 33.5737,
                "lng": -86.9
            },
            "Delta": {
                "lat": 38.756,
                "lng": -108.0772
            },
            "North Codorus": {
                "lat": 39.8646,
                "lng": -76.8189
            },
            "West Athens": {
                "lat": 33.9235,
                "lng": -118.3033
            },
            "Perkasie": {
                "lat": 40.372,
                "lng": -75.292
            },
            "Elsmere": {
                "lat": 38.9948,
                "lng": -84.6017
            },
            "Crawford": {
                "lat": 41.5685,
                "lng": -74.3169
            },
            "Marlboro Village": {
                "lat": 38.8307,
                "lng": -76.7699
            },
            "Middlebury": {
                "lat": 44.0043,
                "lng": -73.1218
            },
            "Temperance": {
                "lat": 41.7653,
                "lng": -83.5755
            },
            "Sunset Hills": {
                "lat": 38.531,
                "lng": -90.4087
            },
            "East Grand Forks": {
                "lat": 47.9286,
                "lng": -97.0138
            },
            "Miramar Beach": {
                "lat": 30.3854,
                "lng": -86.3442
            },
            "Tiburon": {
                "lat": 37.8854,
                "lng": -122.4637
            },
            "Hawthorn Woods": {
                "lat": 42.2313,
                "lng": -88.0624
            },
            "Fairless Hills": {
                "lat": 40.1783,
                "lng": -74.8524
            },
            "Weare": {
                "lat": 43.0813,
                "lng": -71.7219
            },
            "Cresskill": {
                "lat": 40.9405,
                "lng": -73.9596
            },
            "Westampton": {
                "lat": 40.0168,
                "lng": -74.8213
            },
            "Cameron": {
                "lat": 39.7444,
                "lng": -94.2329
            },
            "Benton Harbor": {
                "lat": 42.1159,
                "lng": -86.4488
            },
            "Orosi": {
                "lat": 36.5433,
                "lng": -119.2914
            },
            "McCordsville": {
                "lat": 39.8966,
                "lng": -85.9208
            },
            "Huntertown": {
                "lat": 41.2185,
                "lng": -85.1705
            },
            "August": {
                "lat": 37.9797,
                "lng": -121.2625
            },
            "Mendon": {
                "lat": 42.9859,
                "lng": -77.5479
            },
            "New Scotland": {
                "lat": 42.6047,
                "lng": -73.9413
            },
            "Absecon": {
                "lat": 39.4229,
                "lng": -74.4944
            },
            "Beecher": {
                "lat": 43.0903,
                "lng": -83.7039
            },
            "Hooper": {
                "lat": 41.1599,
                "lng": -112.2871
            },
            "Townsend": {
                "lat": 42.6671,
                "lng": -71.7115
            },
            "Valley Cottage": {
                "lat": 41.1162,
                "lng": -73.9433
            },
            "Economy": {
                "lat": 40.6411,
                "lng": -80.1841
            },
            "Harrodsburg": {
                "lat": 37.7654,
                "lng": -84.8474
            },
            "Country Club Estates": {
                "lat": 31.2113,
                "lng": -81.4622
            },
            "Roxborough Park": {
                "lat": 39.4492,
                "lng": -105.0746
            },
            "Toccoa": {
                "lat": 34.581,
                "lng": -83.3263
            },
            "Northwest Harborcreek": {
                "lat": 42.1494,
                "lng": -79.9945
            },
            "Bemiss": {
                "lat": 30.9318,
                "lng": -83.2386
            },
            "Orange Park": {
                "lat": 30.1706,
                "lng": -81.7041
            },
            "Harahan": {
                "lat": 29.9374,
                "lng": -90.2031
            },
            "Old Orchard Beach": {
                "lat": 43.5239,
                "lng": -70.3904
            },
            "Travis Ranch": {
                "lat": 32.8015,
                "lng": -96.4775
            },
            "Breinigsville": {
                "lat": 40.5394,
                "lng": -75.6347
            },
            "Lapeer": {
                "lat": 43.0447,
                "lng": -83.3253
            },
            "North Merritt Island": {
                "lat": 28.4585,
                "lng": -80.7162
            },
            "Tuscumbia": {
                "lat": 34.7204,
                "lng": -87.7035
            },
            "Booneville": {
                "lat": 34.6643,
                "lng": -88.5684
            },
            "Bala Cynwyd": {
                "lat": 40.0116,
                "lng": -75.2283
            },
            "Hampstead": {
                "lat": 42.8821,
                "lng": -71.171
            },
            "Craig": {
                "lat": 40.5171,
                "lng": -107.5554
            },
            "Blacklick Estates": {
                "lat": 39.9049,
                "lng": -82.8655
            },
            "Rio del Mar": {
                "lat": 36.9607,
                "lng": -121.8807
            },
            "Seneca Falls": {
                "lat": 42.9136,
                "lng": -76.7904
            },
            "Lago Vista": {
                "lat": 30.4519,
                "lng": -97.9908
            },
            "Lake Park": {
                "lat": 26.7998,
                "lng": -80.0681
            },
            "Glens Falls North": {
                "lat": 43.335,
                "lng": -73.6832
            },
            "Harleysville": {
                "lat": 40.2792,
                "lng": -75.3873
            },
            "Rice Lake": {
                "lat": 45.4864,
                "lng": -91.7447
            },
            "East Nottingham": {
                "lat": 39.7621,
                "lng": -75.9705
            },
            "Pulaski": {
                "lat": 37.0528,
                "lng": -80.7624
            },
            "Brooksville": {
                "lat": 28.5404,
                "lng": -82.3903
            },
            "Lopatcong": {
                "lat": 40.7091,
                "lng": -75.1551
            },
            "Leisure World": {
                "lat": 39.1023,
                "lng": -77.0689
            },
            "Saline": {
                "lat": 42.174,
                "lng": -83.778
            },
            "Fitzgerald": {
                "lat": 31.7134,
                "lng": -83.2514
            },
            "Evergreen": {
                "lat": 48.2308,
                "lng": -114.27
            },
            "Perkiomen": {
                "lat": 40.2316,
                "lng": -75.4644
            },
            "Fort Irwin": {
                "lat": 35.2477,
                "lng": -116.6834
            },
            "Cloverdale": {
                "lat": 38.7962,
                "lng": -123.0152
            },
            "Plainedge": {
                "lat": 40.7241,
                "lng": -73.477
            },
            "Park Forest Village": {
                "lat": 40.7996,
                "lng": -77.9084
            },
            "Sheffield Lake": {
                "lat": 41.4883,
                "lng": -82.0979
            },
            "West Donegal": {
                "lat": 40.1297,
                "lng": -76.6226
            },
            "Haledon": {
                "lat": 40.9363,
                "lng": -74.1887
            },
            "Indian Harbour Beach": {
                "lat": 28.1529,
                "lng": -80.5976
            },
            "Harwood Heights": {
                "lat": 41.9663,
                "lng": -87.8057
            },
            "Bogota": {
                "lat": 40.8751,
                "lng": -74.0293
            },
            "Ladue": {
                "lat": 38.6377,
                "lng": -90.3815
            },
            "Beaver Falls": {
                "lat": 40.7619,
                "lng": -80.3226
            },
            "West Caln": {
                "lat": 40.0237,
                "lng": -75.8866
            },
            "Purcellville": {
                "lat": 39.1378,
                "lng": -77.711
            },
            "Edgewater Park": {
                "lat": 40.054,
                "lng": -74.9117
            },
            "Hope": {
                "lat": 33.6682,
                "lng": -93.5895
            },
            "Upper Leacock": {
                "lat": 40.0801,
                "lng": -76.1854
            },
            "Village St. George": {
                "lat": 30.3598,
                "lng": -91.0672
            },
            "Bayport": {
                "lat": 40.7461,
                "lng": -73.0546
            },
            "Fair Lakes": {
                "lat": 38.853,
                "lng": -77.3885
            },
            "Brownfield": {
                "lat": 33.1757,
                "lng": -102.273
            },
            "Litchfield Beach": {
                "lat": 33.4773,
                "lng": -79.1181
            },
            "St. Pete Beach": {
                "lat": 27.7235,
                "lng": -82.7387
            },
            "Rock Falls": {
                "lat": 41.7724,
                "lng": -89.6928
            },
            "Sandpoint": {
                "lat": 48.2822,
                "lng": -116.5614
            },
            "Orting": {
                "lat": 47.0967,
                "lng": -122.2112
            },
            "Crozet": {
                "lat": 38.0645,
                "lng": -78.6961
            },
            "Whippany": {
                "lat": 40.8233,
                "lng": -74.4184
            },
            "Napoleon": {
                "lat": 41.3977,
                "lng": -84.1244
            },
            "North Londonderry": {
                "lat": 40.3227,
                "lng": -76.5867
            },
            "Edgemere": {
                "lat": 39.2273,
                "lng": -76.459
            },
            "Kulpsville": {
                "lat": 40.244,
                "lng": -75.3407
            },
            "Thief River Falls": {
                "lat": 48.1108,
                "lng": -96.1778
            },
            "Pearsall": {
                "lat": 28.8915,
                "lng": -99.0975
            },
            "Cumberland Hill": {
                "lat": 41.9736,
                "lng": -71.4605
            },
            "Collingdale": {
                "lat": 39.9151,
                "lng": -75.2776
            },
            "Woodmoor": {
                "lat": 39.1063,
                "lng": -104.8456
            },
            "Collier": {
                "lat": 40.3991,
                "lng": -80.1322
            },
            "East York": {
                "lat": 39.9687,
                "lng": -76.6755
            },
            "Poteau": {
                "lat": 35.0282,
                "lng": -94.6346
            },
            "Coffeyville": {
                "lat": 37.0519,
                "lng": -95.618
            },
            "Andalusia": {
                "lat": 31.3102,
                "lng": -86.4781
            },
            "Seneca": {
                "lat": 34.6818,
                "lng": -82.96
            },
            "Signal Mountain": {
                "lat": 35.1448,
                "lng": -85.3457
            },
            "Cortez": {
                "lat": 37.3503,
                "lng": -108.5768
            },
            "Maysville": {
                "lat": 38.6455,
                "lng": -83.7911
            },
            "Sanatoga": {
                "lat": 40.2497,
                "lng": -75.5887
            },
            "Gibsonville": {
                "lat": 36.0993,
                "lng": -79.5415
            },
            "Fort Valley": {
                "lat": 32.552,
                "lng": -83.8817
            },
            "Toppenish": {
                "lat": 46.3806,
                "lng": -120.3122
            },
            "Upper Makefield": {
                "lat": 40.2941,
                "lng": -74.925
            },
            "Oak Hills Place": {
                "lat": 30.369,
                "lng": -91.0887
            },
            "Pitman": {
                "lat": 39.7335,
                "lng": -75.1306
            },
            "North Haledon": {
                "lat": 40.9628,
                "lng": -74.1844
            },
            "Pittsgrove": {
                "lat": 39.5404,
                "lng": -75.1291
            },
            "London Grove": {
                "lat": 39.8327,
                "lng": -75.8155
            },
            "Jeannette": {
                "lat": 40.3277,
                "lng": -79.6139
            },
            "Glencoe": {
                "lat": 42.1347,
                "lng": -87.7641
            },
            "Schuylkill": {
                "lat": 40.1086,
                "lng": -75.4982
            },
            "Boonton": {
                "lat": 40.9047,
                "lng": -74.4048
            },
            "Clanton": {
                "lat": 32.8444,
                "lng": -86.6233
            },
            "Mahopac": {
                "lat": 41.3688,
                "lng": -73.7414
            },
            "Meadow Lakes": {
                "lat": 61.638,
                "lng": -149.608
            },
            "Presque Isle": {
                "lat": 46.6868,
                "lng": -67.9874
            },
            "Bee Cave": {
                "lat": 30.3084,
                "lng": -97.9629
            },
            "Chartiers": {
                "lat": 40.2505,
                "lng": -80.2495
            },
            "Spring Valley Lake": {
                "lat": 34.4987,
                "lng": -117.2683
            },
            "Perry Heights": {
                "lat": 40.7977,
                "lng": -81.468
            },
            "Mascoutah": {
                "lat": 38.5192,
                "lng": -89.8045
            },
            "Stony Brook University": {
                "lat": 40.9098,
                "lng": -73.1217
            },
            "Lenox": {
                "lat": 43.1113,
                "lng": -75.7582
            },
            "Summit View": {
                "lat": 47.1343,
                "lng": -122.3467
            },
            "Murillo": {
                "lat": 26.2642,
                "lng": -98.1233
            },
            "Hoquiam": {
                "lat": 46.9863,
                "lng": -123.9022
            },
            "Corona de Tucson": {
                "lat": 31.9503,
                "lng": -110.7835
            },
            "Hollymead": {
                "lat": 38.1266,
                "lng": -78.4386
            },
            "Ellicott": {
                "lat": 42.133,
                "lng": -79.236
            },
            "Port Jervis": {
                "lat": 41.3783,
                "lng": -74.691
            },
            "Aransas Pass": {
                "lat": 27.8876,
                "lng": -97.1136
            },
            "South Londonderry": {
                "lat": 40.2424,
                "lng": -76.5432
            },
            "Chanute": {
                "lat": 37.6695,
                "lng": -95.4621
            },
            "Hamlin": {
                "lat": 43.3213,
                "lng": -77.9135
            },
            "Blanchard": {
                "lat": 35.1524,
                "lng": -97.6602
            },
            "Anthony": {
                "lat": 32.0131,
                "lng": -106.5984
            },
            "Summit Park": {
                "lat": 40.7423,
                "lng": -111.5872
            },
            "Dunn Loring": {
                "lat": 38.8945,
                "lng": -77.2315
            },
            "Lake Villa": {
                "lat": 42.4184,
                "lng": -88.0836
            },
            "Innsbrook": {
                "lat": 37.6552,
                "lng": -77.5775
            },
            "Marcy": {
                "lat": 43.1731,
                "lng": -75.2662
            },
            "Solebury": {
                "lat": 40.3676,
                "lng": -75.0032
            },
            "Newstead": {
                "lat": 43.0196,
                "lng": -78.5223
            },
            "Savoy": {
                "lat": 40.06,
                "lng": -88.2552
            },
            "Cheviot": {
                "lat": 39.1577,
                "lng": -84.6139
            },
            "Hillview": {
                "lat": 38.0562,
                "lng": -85.6847
            },
            "Sangaree": {
                "lat": 33.0327,
                "lng": -80.1253
            },
            "Tecumseh": {
                "lat": 42.0066,
                "lng": -83.945
            },
            "River Road": {
                "lat": 44.0833,
                "lng": -123.132
            },
            "Olmsted Falls": {
                "lat": 41.3657,
                "lng": -81.9038
            },
            "Wharton": {
                "lat": 29.3177,
                "lng": -96.1022
            },
            "Heartland": {
                "lat": 32.6748,
                "lng": -96.4515
            },
            "North Cornwall": {
                "lat": 40.3134,
                "lng": -76.4515
            },
            "Baxter": {
                "lat": 46.3426,
                "lng": -94.2793
            },
            "Fort Mitchell": {
                "lat": 39.046,
                "lng": -84.5562
            },
            "Perryton": {
                "lat": 36.3928,
                "lng": -100.7976
            },
            "Benner": {
                "lat": 40.8698,
                "lng": -77.8153
            },
            "Clarendon Hills": {
                "lat": 41.7981,
                "lng": -87.9568
            },
            "Fort Knox": {
                "lat": 37.8915,
                "lng": -85.9636
            },
            "Park Hills": {
                "lat": 37.8211,
                "lng": -90.505
            },
            "Village Green-Green Ridge": {
                "lat": 39.8639,
                "lng": -75.4257
            },
            "Highland Heights": {
                "lat": 41.5518,
                "lng": -81.4691
            },
            "Lihue": {
                "lat": 21.9721,
                "lng": -159.3564
            },
            "Gulf Hills": {
                "lat": 30.4367,
                "lng": -88.815
            },
            "Guntersville": {
                "lat": 34.3671,
                "lng": -86.2637
            },
            "Manteno": {
                "lat": 41.247,
                "lng": -87.8457
            },
            "Long Hill": {
                "lat": 40.6838,
                "lng": -74.4878
            },
            "East Donegal": {
                "lat": 40.0823,
                "lng": -76.5631
            },
            "Fort Riley": {
                "lat": 39.1064,
                "lng": -96.8117
            },
            "Mechanicstown": {
                "lat": 41.4472,
                "lng": -74.3914
            },
            "Templeton": {
                "lat": 35.556,
                "lng": -120.7181
            },
            "Corbin": {
                "lat": 36.9323,
                "lng": -84.1006
            },
            "Carneys Point": {
                "lat": 39.6967,
                "lng": -75.4475
            },
            "Skowhegan": {
                "lat": 44.7554,
                "lng": -69.6657
            },
            "Kennedy": {
                "lat": 40.4768,
                "lng": -80.1028
            },
            "Citrus Hills": {
                "lat": 28.887,
                "lng": -82.4312
            },
            "Seffner": {
                "lat": 27.9981,
                "lng": -82.2735
            },
            "Bryans Road": {
                "lat": 38.6145,
                "lng": -77.0851
            },
            "Monona": {
                "lat": 43.054,
                "lng": -89.3334
            },
            "Kennedale": {
                "lat": 32.6434,
                "lng": -97.2172
            },
            "Swissvale": {
                "lat": 40.4206,
                "lng": -79.8859
            },
            "Eden Isle": {
                "lat": 30.2268,
                "lng": -89.8043
            },
            "Othello": {
                "lat": 46.8221,
                "lng": -119.1653
            },
            "Gering": {
                "lat": 41.8275,
                "lng": -103.6622
            },
            "Lamesa": {
                "lat": 32.7333,
                "lng": -101.9541
            },
            "Millis": {
                "lat": 42.1693,
                "lng": -71.3626
            },
            "Oak Island": {
                "lat": 33.9434,
                "lng": -78.1366
            },
            "Kings Grant": {
                "lat": 34.2664,
                "lng": -77.8659
            },
            "Paloma Creek South": {
                "lat": 33.21,
                "lng": -96.9325
            },
            "Merrydale": {
                "lat": 30.4998,
                "lng": -91.1081
            },
            "Closter": {
                "lat": 40.9733,
                "lng": -73.9604
            },
            "Arab": {
                "lat": 34.3309,
                "lng": -86.4991
            },
            "Conewago": {
                "lat": 40.0658,
                "lng": -76.7932
            },
            "Dunn": {
                "lat": 35.3114,
                "lng": -78.6129
            },
            "West Long Branch": {
                "lat": 40.2883,
                "lng": -74.0185
            },
            "Barton": {
                "lat": 42.0812,
                "lng": -76.4982
            },
            "Glenwood": {
                "lat": 41.541,
                "lng": -87.6118
            },
            "Arizona City": {
                "lat": 32.7506,
                "lng": -111.6707
            },
            "Perryville": {
                "lat": 37.7263,
                "lng": -89.8759
            },
            "Bushkill": {
                "lat": 40.7976,
                "lng": -75.3281
            },
            "Montecito": {
                "lat": 34.4384,
                "lng": -119.6309
            },
            "Montague": {
                "lat": 42.5549,
                "lng": -72.5177
            },
            "Severance": {
                "lat": 40.5265,
                "lng": -104.865
            },
            "Malverne": {
                "lat": 40.6746,
                "lng": -73.6721
            },
            "Sallisaw": {
                "lat": 35.4606,
                "lng": -94.8072
            },
            "Lewistown": {
                "lat": 40.5964,
                "lng": -77.573
            },
            "Delavan": {
                "lat": 42.6282,
                "lng": -88.6323
            },
            "Sutherlin": {
                "lat": 43.3882,
                "lng": -123.3242
            },
            "Richland Hills": {
                "lat": 32.8095,
                "lng": -97.2273
            },
            "Whitehouse": {
                "lat": 32.2222,
                "lng": -95.221
            },
            "Old Forge": {
                "lat": 41.3704,
                "lng": -75.7409
            },
            "West Earl": {
                "lat": 40.126,
                "lng": -76.1774
            },
            "Montpelier": {
                "lat": 44.2659,
                "lng": -72.5717
            }
        }
    },
    "BD": {
        "name": "Bangladesh",
        "cities": {
            "Dhaka": {
                "lat": 23.7639,
                "lng": 90.3889
            },
            "Chattogram": {
                "lat": 22.335,
                "lng": 91.8325
            },
            "G\u0101zipura": {
                "lat": 23.9889,
                "lng": 90.375
            },
            "R\u0101jsh\u0101hi": {
                "lat": 24.3667,
                "lng": 88.6
            },
            "Khulna": {
                "lat": 22.8167,
                "lng": 89.55
            },
            "Rangapukur": {
                "lat": 25.56,
                "lng": 89.25
            },
            "N\u0101r\u0101yanganj": {
                "lat": 23.62,
                "lng": 90.5
            },
            "Mymensingh": {
                "lat": 24.7539,
                "lng": 90.4031
            },
            "Sylhet": {
                "lat": 24.9,
                "lng": 91.8667
            },
            "Sir\u0101jganj": {
                "lat": 24.45,
                "lng": 89.75
            },
            "Chauddagram": {
                "lat": 23.2283,
                "lng": 91.3133
            },
            "Kushtia": {
                "lat": 23.9101,
                "lng": 89.1231
            },
            "Barura": {
                "lat": 23.375,
                "lng": 91.0583
            },
            "Bogra": {
                "lat": 24.85,
                "lng": 89.3667
            },
            "T\u0101ng\u0101il": {
                "lat": 24.2644,
                "lng": 89.9181
            },
            "Savar": {
                "lat": 23.85,
                "lng": 90.26
            },
            "Tungi": {
                "lat": 23.9,
                "lng": 90.41
            },
            "Comilla": {
                "lat": 23.45,
                "lng": 91.2
            },
            "Rangpur": {
                "lat": 25.75,
                "lng": 89.2444
            },
            "Br\u0101hmanb\u0101ria": {
                "lat": 23.9656,
                "lng": 91.108
            },
            "Feni": {
                "lat": 23.0183,
                "lng": 91.41
            },
            "Siddhirganj": {
                "lat": 23.6833,
                "lng": 90.5167
            },
            "Jhenida": {
                "lat": 23.5417,
                "lng": 89.1833
            },
            "Companiganj": {
                "lat": 22.875,
                "lng": 91.2833
            },
            "Jessore": {
                "lat": 23.1704,
                "lng": 89.2
            },
            "B\u0101nchp\u0101r": {
                "lat": 23.2,
                "lng": 90.8
            },
            "Saidpur": {
                "lat": 25.7781,
                "lng": 88.8975
            },
            "Nazipur": {
                "lat": 25.0415,
                "lng": 88.7605
            },
            "Barishal": {
                "lat": 22.7,
                "lng": 90.3667
            },
            "Chu\u0101d\u0101nga": {
                "lat": 23.644,
                "lng": 88.8556
            },
            "Cox\u2019s B\u0101z\u0101r": {
                "lat": 21.4272,
                "lng": 92.005
            },
            "Din\u0101jpur": {
                "lat": 25.6167,
                "lng": 88.65
            },
            "Brahmanpara": {
                "lat": 23.6167,
                "lng": 91.1083
            },
            "P\u0101bna": {
                "lat": 24.0167,
                "lng": 89.2167
            },
            "Narsingdi": {
                "lat": 23.9231,
                "lng": 90.7122
            },
            "Jam\u0101lpur": {
                "lat": 24.9004,
                "lng": 89.95
            },
            "Naogaon": {
                "lat": 24.8,
                "lng": 88.9333
            },
            "Bh\u0101nd\u0101ria": {
                "lat": 22.4883,
                "lng": 90.0733
            },
            "Naw\u0101bganj": {
                "lat": 24.6,
                "lng": 88.2667
            },
            "Sr\u012bpur": {
                "lat": 24.2011,
                "lng": 90.4843
            },
            "Maijdi": {
                "lat": 22.8333,
                "lng": 91.1
            },
            "Far\u012bdpur": {
                "lat": 23.602,
                "lng": 89.833
            },
            "Fulg\u0101zi": {
                "lat": 23.1333,
                "lng": 91.4333
            },
            "S\u0101tkhira": {
                "lat": 22.7188,
                "lng": 89.0713
            },
            "Netrakona": {
                "lat": 24.8819,
                "lng": 90.7275
            },
            "Goalundo Gh\u0101t": {
                "lat": 23.7333,
                "lng": 89.7617
            },
            "Bhola": {
                "lat": 22.6833,
                "lng": 90.65
            },
            "Habiganj": {
                "lat": 24.3808,
                "lng": 91.4144
            },
            "Lakshm\u012bpur": {
                "lat": 22.94,
                "lng": 90.833
            },
            "M\u0101nikganj": {
                "lat": 23.85,
                "lng": 90.0167
            },
            "Kaukh\u0101li": {
                "lat": 22.6333,
                "lng": 90.0667
            },
            "Patuakh\u0101li": {
                "lat": 22.3542,
                "lng": 90.3181
            },
            "Panchari Bazar": {
                "lat": 23.29,
                "lng": 91.9
            },
            "Nakhyaungchar\u012bp\u0101ra": {
                "lat": 21.4167,
                "lng": 92.1833
            },
            "Lalmanirhat": {
                "lat": 25.9167,
                "lng": 89.45
            },
            "Pirojpur": {
                "lat": 22.578,
                "lng": 89.968
            },
            "Arankhola": {
                "lat": 24.661,
                "lng": 90.0884
            },
            "Gaibandha": {
                "lat": 25.329,
                "lng": 89.5415
            },
            "Raharpur": {
                "lat": 24.8194,
                "lng": 88.325
            },
            "Elenga": {
                "lat": 24.3386,
                "lng": 89.9219
            },
            "J\u0101l\u0101k\u0101ti": {
                "lat": 22.6386,
                "lng": 90.1993
            },
            "Sherpur": {
                "lat": 24.665,
                "lng": 89.419
            },
            "Gop\u0101lganj": {
                "lat": 23.01,
                "lng": 89.82
            },
            "Sun\u0101mganj": {
                "lat": 25.071,
                "lng": 91.4035
            },
            "Gop\u0101lpur": {
                "lat": 24.5583,
                "lng": 89.9167
            },
            "Mukt\u0101g\u0101cha": {
                "lat": 24.7662,
                "lng": 90.2562
            },
            "Chh\u0101galn\u0101iya": {
                "lat": 23.0395,
                "lng": 91.5146
            },
            "Bh\u0101rella": {
                "lat": 23.5506,
                "lng": 91.0566
            },
            "Char Fasson": {
                "lat": 22.1861,
                "lng": 90.7597
            },
            "Maulavi B\u0101z\u0101r": {
                "lat": 24.4808,
                "lng": 91.7644
            },
            "Dohaz\u0101ri": {
                "lat": 22.1628,
                "lng": 92.0728
            },
            "K\u0101lih\u0101ti": {
                "lat": 24.3833,
                "lng": 89.9833
            },
            "S\u012bt\u0101kund": {
                "lat": 22.62,
                "lng": 91.66
            },
            "Ben\u0101pol": {
                "lat": 23.0419,
                "lng": 88.8956
            },
            "Kishmat Dhanb\u0101ri": {
                "lat": 24.6167,
                "lng": 90.025
            },
            "Chandanais": {
                "lat": 22.2,
                "lng": 92.0083
            },
            "Hatibanda": {
                "lat": 24.2105,
                "lng": 90.1492
            },
            "D\u0101ganbhuiya": {
                "lat": 22.9127,
                "lng": 91.3181
            },
            "Barguna": {
                "lat": 22.15,
                "lng": 90.1167
            },
            "Nagdha Simla": {
                "lat": 24.6143,
                "lng": 89.9166
            },
            "Loh\u0101g\u0101ra": {
                "lat": 22.0181,
                "lng": 92.0974
            },
            "N\u0101lchiti": {
                "lat": 22.635,
                "lng": 90.27
            },
            "Majidpur": {
                "lat": 23.5796,
                "lng": 90.7689
            },
            "Sakhipur": {
                "lat": 24.3167,
                "lng": 90.175
            },
            "Parsuram": {
                "lat": 23.2135,
                "lng": 91.4447
            },
            "Mathba": {
                "lat": 22.3,
                "lng": 89.95
            },
            "Mirz\u0101pur": {
                "lat": 24.1083,
                "lng": 90.0917
            },
            "Daulatkh\u0101n": {
                "lat": 22.6,
                "lng": 90.7472
            },
            "Duptiair": {
                "lat": 23.991,
                "lng": 89.7982
            },
            "Lakshm\u012bchar\u012bp\u0101ra": {
                "lat": 22.79,
                "lng": 91.9
            },
            "Phulpur": {
                "lat": 24.955,
                "lng": 90.3573
            },
            "Mundam\u0101la": {
                "lat": 24.6081,
                "lng": 88.4728
            },
            "Amtali": {
                "lat": 22.137,
                "lng": 90.228
            },
            "Sh\u0101hb\u0101zpur": {
                "lat": 24.0531,
                "lng": 91.1719
            },
            "Son\u0101g\u0101zi": {
                "lat": 22.8492,
                "lng": 91.3928
            },
            "Jagatpur": {
                "lat": 23.6048,
                "lng": 90.7628
            },
            "Harin\u0101kunda": {
                "lat": 23.65,
                "lng": 89.0333
            },
            "Latifpur": {
                "lat": 22.3777,
                "lng": 91.7639
            },
            "B\u0101is\u0101ri": {
                "lat": 22.79,
                "lng": 90.1313
            },
            "Dhubaria": {
                "lat": 24.0044,
                "lng": 89.8484
            },
            "Farad\u0101b\u0101d": {
                "lat": 23.7445,
                "lng": 90.8772
            },
            "Fatikchari": {
                "lat": 22.6878,
                "lng": 91.7811
            }
        }
    },
    "TH": {
        "name": "Thailand",
        "cities": {
            "Bangkok": {
                "lat": 13.7525,
                "lng": 100.4942
            },
            "Chiang Mai": {
                "lat": 18.7953,
                "lng": 98.9986
            },
            "Nakhon Ratchasima": {
                "lat": 14.975,
                "lng": 102.1
            },
            "Khon Kaen": {
                "lat": 16.4333,
                "lng": 102.8333
            },
            "Hat Yai": {
                "lat": 7.0167,
                "lng": 100.4667
            },
            "Chon Buri": {
                "lat": 13.3611,
                "lng": 100.985
            },
            "Phatthaya": {
                "lat": 12.9357,
                "lng": 100.889
            },
            "Si Racha": {
                "lat": 13.174,
                "lng": 100.93
            },
            "Nonthaburi": {
                "lat": 13.8667,
                "lng": 100.5167
            },
            "Pak Kret": {
                "lat": 13.9125,
                "lng": 100.4978
            },
            "Surat Thani": {
                "lat": 9.1397,
                "lng": 99.3306
            },
            "Udon Thani": {
                "lat": 17.4167,
                "lng": 102.75
            },
            "Ban Bang Pu Mai": {
                "lat": 13.5441,
                "lng": 100.6175
            },
            "Nakhon Si Thammarat": {
                "lat": 8.4364,
                "lng": 99.9631
            },
            "Ban Mangkon": {
                "lat": 13.6138,
                "lng": 100.6104
            },
            "Ban Laem Chabang": {
                "lat": 13.0833,
                "lng": 100.8833
            },
            "Nakhon Sawan": {
                "lat": 15.7133,
                "lng": 100.1353
            },
            "Ban Talat Rangsit": {
                "lat": 13.9833,
                "lng": 100.6167
            },
            "Ban Nong Prue": {
                "lat": 12.9228,
                "lng": 100.9375
            },
            "Ubon Ratchathani": {
                "lat": 15.2281,
                "lng": 104.8594
            },
            "Nakhon Pathom": {
                "lat": 13.8206,
                "lng": 100.0625
            },
            "Phuket": {
                "lat": 7.8881,
                "lng": 98.3975
            },
            "Ban Tha Khlong": {
                "lat": 14.0894,
                "lng": 100.6236
            },
            "Phitsanulok": {
                "lat": 16.8158,
                "lng": 100.2636
            },
            "Chiang Rai": {
                "lat": 19.9094,
                "lng": 99.8275
            },
            "Ko Samui": {
                "lat": 9.5,
                "lng": 100.0
            },
            "Ban Suan": {
                "lat": 13.3616,
                "lng": 100.9795
            },
            "Songkhla": {
                "lat": 7.2061,
                "lng": 100.5967
            },
            "Ban Lam Sam Kaeo": {
                "lat": 13.9728,
                "lng": 100.6511
            },
            "Rayong": {
                "lat": 12.6742,
                "lng": 101.2789
            },
            "Saraburi": {
                "lat": 14.5286,
                "lng": 100.9114
            },
            "Yala": {
                "lat": 6.5425,
                "lng": 101.2831
            },
            "Trang": {
                "lat": 7.5575,
                "lng": 99.6103
            },
            "Samut Sakhon": {
                "lat": 13.5486,
                "lng": 100.2775
            },
            "Hua Hin": {
                "lat": 12.5686,
                "lng": 99.9578
            },
            "Ban Rangsit": {
                "lat": 14.0167,
                "lng": 100.75
            },
            "Ban Bang Kaeo": {
                "lat": 13.6371,
                "lng": 100.6636
            },
            "Khlong Luang": {
                "lat": 14.0647,
                "lng": 100.6458
            },
            "Lampang": {
                "lat": 18.3,
                "lng": 99.5
            },
            "Prachuap Khiri Khan": {
                "lat": 11.8167,
                "lng": 99.8
            },
            "Sakon Nakhon": {
                "lat": 17.1564,
                "lng": 104.1456
            },
            "Phra Nakhon Si Ayutthaya": {
                "lat": 14.3478,
                "lng": 100.5606
            },
            "Maha Sarakham": {
                "lat": 16.1772,
                "lng": 103.3008
            },
            "Ban Om Noi": {
                "lat": 13.7001,
                "lng": 100.3241
            },
            "Muban Saeng Bua Thong": {
                "lat": 13.9424,
                "lng": 100.3913
            },
            "Samut Prakan": {
                "lat": 13.5897,
                "lng": 100.6386
            },
            "Bang Bua Thong": {
                "lat": 13.9099,
                "lng": 100.4263
            },
            "Nong Khai": {
                "lat": 17.8681,
                "lng": 102.7444
            },
            "Chong Nonsi": {
                "lat": 13.6965,
                "lng": 100.5427
            },
            "Ban Plai Bua Phatthana": {
                "lat": 13.9032,
                "lng": 100.3989
            },
            "Ban Bang Mae Nang": {
                "lat": 13.8815,
                "lng": 100.3759
            },
            "Pattani": {
                "lat": 6.8664,
                "lng": 101.2508
            },
            "Ban Pet": {
                "lat": 16.4379,
                "lng": 102.7645
            },
            "Singhanakhon": {
                "lat": 7.2389,
                "lng": 100.5506
            },
            "Si Sa Ket": {
                "lat": 15.1069,
                "lng": 104.3294
            },
            "Bang Kruai": {
                "lat": 13.8042,
                "lng": 100.4755
            },
            "Ban Wat Lak Hok": {
                "lat": 13.5633,
                "lng": 100.0564
            },
            "Su-ngai Kolok": {
                "lat": 6.0333,
                "lng": 101.9667
            },
            "Narathiwat": {
                "lat": 6.4167,
                "lng": 101.8167
            },
            "Surin": {
                "lat": 14.8864,
                "lng": 103.4932
            },
            "Chachoengsao": {
                "lat": 13.6903,
                "lng": 101.0703
            },
            "Ban Bang Khu Lat": {
                "lat": 13.9134,
                "lng": 100.369
            },
            "Ban Na Pa": {
                "lat": 13.3956,
                "lng": 101.0232
            },
            "Ban Doi Suthep": {
                "lat": 18.7944,
                "lng": 98.9142
            },
            "Chaiyaphum": {
                "lat": 15.8056,
                "lng": 102.0311
            },
            "Ratchaburi": {
                "lat": 13.5356,
                "lng": 99.8133
            },
            "Ban Mueang Na Tai": {
                "lat": 19.5932,
                "lng": 98.9618
            },
            "Pak Chong": {
                "lat": 14.6796,
                "lng": 101.3976
            },
            "Cha-am": {
                "lat": 12.7992,
                "lng": 99.9683
            },
            "Roi Et": {
                "lat": 16.0531,
                "lng": 103.6511
            },
            "Phatthalung": {
                "lat": 7.5,
                "lng": 100.0
            },
            "Kalasin": {
                "lat": 16.4342,
                "lng": 103.5092
            },
            "Phra Phutthabat": {
                "lat": 14.7212,
                "lng": 100.8047
            },
            "Uttaradit": {
                "lat": 17.6231,
                "lng": 100.0958
            },
            "Chumphon": {
                "lat": 10.4939,
                "lng": 99.18
            },
            "Ban Pak Phun": {
                "lat": 8.5391,
                "lng": 99.9702
            },
            "Mukdahan": {
                "lat": 16.5431,
                "lng": 104.7228
            },
            "Ban Ang Sila": {
                "lat": 13.3364,
                "lng": 100.9278
            },
            "Chum Phae": {
                "lat": 16.5431,
                "lng": 102.1104
            },
            "Mae Sot": {
                "lat": 16.7131,
                "lng": 98.5747
            },
            "Ban Sai Ma Tai": {
                "lat": 13.8444,
                "lng": 100.4829
            },
            "Thung Song": {
                "lat": 8.1669,
                "lng": 99.6745
            },
            "Krabi": {
                "lat": 8.0592,
                "lng": 98.9189
            },
            "Samrong": {
                "lat": 13.6421,
                "lng": 100.6039
            },
            "Kamphaeng Phet": {
                "lat": 16.4811,
                "lng": 99.5222
            },
            "Ban Phai": {
                "lat": 16.073,
                "lng": 102.7362
            },
            "Bang Phongphang": {
                "lat": 13.6791,
                "lng": 100.5291
            },
            "Samut Songkhram": {
                "lat": 13.4097,
                "lng": 100.0017
            },
            "Tha Yang": {
                "lat": 12.9658,
                "lng": 99.8924
            },
            "Ban Bang Khu Wat": {
                "lat": 13.9576,
                "lng": 100.4903
            },
            "Warin Chamrap": {
                "lat": 15.2008,
                "lng": 104.8612
            },
            "Ban Chang": {
                "lat": 12.7208,
                "lng": 101.0669
            },
            "Buri Ram": {
                "lat": 14.9942,
                "lng": 103.1022
            },
            "Ban Phonla Krang": {
                "lat": 14.9192,
                "lng": 102.1095
            },
            "Betong": {
                "lat": 5.7731,
                "lng": 101.0725
            },
            "Nakhon Phanom": {
                "lat": 17.4069,
                "lng": 104.7808
            },
            "Suphan Buri": {
                "lat": 14.4675,
                "lng": 100.1169
            },
            "Kathu": {
                "lat": 7.9112,
                "lng": 98.3475
            },
            "Ban Pa Sak": {
                "lat": 16.8477,
                "lng": 100.2563
            },
            "Amnat Charoen": {
                "lat": 15.85,
                "lng": 104.6333
            },
            "Ban Piang Luang": {
                "lat": 19.6493,
                "lng": 98.6352
            },
            "Kanchanaburi": {
                "lat": 14.0194,
                "lng": 99.5311
            },
            "Ban Khamen": {
                "lat": 13.8823,
                "lng": 100.2879
            },
            "Ban Bang Phun": {
                "lat": 13.9968,
                "lng": 100.5891
            },
            "Takhli": {
                "lat": 15.2667,
                "lng": 100.35
            },
            "Lop Buri": {
                "lat": 14.8,
                "lng": 100.6269
            },
            "Chanthaburi": {
                "lat": 12.6086,
                "lng": 102.1039
            },
            "Bang Sao Thong": {
                "lat": 13.5812,
                "lng": 100.7957
            },
            "Satun": {
                "lat": 6.6147,
                "lng": 100.0681
            },
            "Ban Phru": {
                "lat": 6.948,
                "lng": 100.4794
            },
            "Sattahip": {
                "lat": 12.6636,
                "lng": 100.9036
            },
            "Ban Thoet Thai": {
                "lat": 20.3084,
                "lng": 99.6445
            },
            "Sai Mai": {
                "lat": 13.8882,
                "lng": 100.462
            },
            "Phetchaburi": {
                "lat": 13.1119,
                "lng": 99.9458
            },
            "Ban Bang Rin": {
                "lat": 9.9531,
                "lng": 98.6342
            },
            "Ban Bang Krang": {
                "lat": 13.8422,
                "lng": 100.4539
            },
            "Wichian Buri": {
                "lat": 15.6565,
                "lng": 101.1072
            },
            "Loei": {
                "lat": 17.4853,
                "lng": 101.7303
            },
            "Phichit": {
                "lat": 16.4431,
                "lng": 100.3467
            },
            "Ban Pa Tueng": {
                "lat": 20.1339,
                "lng": 99.8325
            },
            "Phetchabun": {
                "lat": 16.4169,
                "lng": 101.1533
            },
            "Krathum Baen": {
                "lat": 13.6631,
                "lng": 100.2572
            },
            "Nong Bua Lamphu": {
                "lat": 17.2042,
                "lng": 102.4444
            },
            "Nang Rong": {
                "lat": 14.6283,
                "lng": 102.7933
            },
            "Wang Nam Yen": {
                "lat": 13.5004,
                "lng": 102.1806
            },
            "Sadao": {
                "lat": 6.6386,
                "lng": 100.424
            },
            "Mae Sai": {
                "lat": 20.4333,
                "lng": 99.8833
            },
            "Na Klang": {
                "lat": 17.3043,
                "lng": 102.1964
            },
            "Nan": {
                "lat": 18.7833,
                "lng": 100.7833
            },
            "Pak Phanang": {
                "lat": 8.3538,
                "lng": 100.2023
            },
            "Yasothon": {
                "lat": 15.7972,
                "lng": 104.1431
            },
            "Ban Tha Pha": {
                "lat": 13.8437,
                "lng": 99.8681
            },
            "Ban Klang": {
                "lat": 18.5791,
                "lng": 99.0687
            },
            "Ban Wat Sala Daeng": {
                "lat": 13.8097,
                "lng": 100.3589
            },
            "Ban Tha Kham": {
                "lat": 9.1058,
                "lng": 99.2325
            },
            "Ban Tha Ton": {
                "lat": 20.0608,
                "lng": 99.3622
            },
            "Ban Na San": {
                "lat": 8.7997,
                "lng": 99.3635
            },
            "Ban Thap Kwang": {
                "lat": 14.6103,
                "lng": 101.0753
            },
            "Kantharalak": {
                "lat": 14.6536,
                "lng": 104.6278
            },
            "Ban Bueng": {
                "lat": 13.3142,
                "lng": 101.1114
            },
            "Ban Patong": {
                "lat": 7.8931,
                "lng": 98.2983
            },
            "Ban Fang Tuen": {
                "lat": 19.5497,
                "lng": 99.7566
            },
            "Ban Mon Pin": {
                "lat": 19.9168,
                "lng": 99.1583
            },
            "Ban Bueng Phra": {
                "lat": 16.746,
                "lng": 100.275
            },
            "Ban Na Yang": {
                "lat": 12.8339,
                "lng": 99.9346
            },
            "Tak Bai": {
                "lat": 6.2592,
                "lng": 102.0531
            },
            "Ban Bo Haeo": {
                "lat": 18.3048,
                "lng": 99.4657
            },
            "Ban Mae Hia Nai": {
                "lat": 18.7433,
                "lng": 98.964
            },
            "Ban Mae Kha Tai": {
                "lat": 18.7352,
                "lng": 98.9678
            },
            "Tha Bo": {
                "lat": 17.8494,
                "lng": 102.5858
            },
            "Ban Mai": {
                "lat": 14.9629,
                "lng": 102.0314
            },
            "Sikhio": {
                "lat": 14.9,
                "lng": 101.7167
            },
            "Sa Kaeo": {
                "lat": 13.8206,
                "lng": 102.0589
            },
            "San Kamphaeng": {
                "lat": 18.75,
                "lng": 99.1167
            },
            "Sing Buri": {
                "lat": 14.8911,
                "lng": 100.4031
            },
            "Prachin Buri": {
                "lat": 14.0567,
                "lng": 101.3739
            },
            "Klaeng": {
                "lat": 12.7778,
                "lng": 101.6483
            },
            "Ban Mae Ngon Khilek": {
                "lat": 19.8021,
                "lng": 99.1614
            },
            "Ban Mae Ka Hua Thung": {
                "lat": 19.0942,
                "lng": 99.9136
            },
            "Sam Phran": {
                "lat": 13.727,
                "lng": 100.2153
            },
            "Tak": {
                "lat": 16.8711,
                "lng": 99.125
            },
            "Ban Thum": {
                "lat": 16.4523,
                "lng": 102.7225
            },
            "Phayao": {
                "lat": 19.1653,
                "lng": 99.9036
            },
            "Ban Song": {
                "lat": 8.6603,
                "lng": 99.3768
            },
            "Ban Pong": {
                "lat": 13.8174,
                "lng": 99.883
            },
            "Rattaphum": {
                "lat": 7.1412,
                "lng": 100.2905
            },
            "Sawankhalok": {
                "lat": 17.3099,
                "lng": 99.8263
            },
            "Ban Phe": {
                "lat": 12.6287,
                "lng": 101.4399
            },
            "Ban Rawai": {
                "lat": 7.7707,
                "lng": 98.3185
            },
            "Ban Tha Mai I": {
                "lat": 18.7461,
                "lng": 98.9819
            },
            "Pran Buri": {
                "lat": 12.3939,
                "lng": 99.9159
            },
            "Ban Wang Nok Aen": {
                "lat": 16.8333,
                "lng": 100.4833
            },
            "Phrae": {
                "lat": 18.1453,
                "lng": 100.1419
            },
            "Ban Na Sai": {
                "lat": 17.7334,
                "lng": 98.9513
            },
            "Ban Cho Ho": {
                "lat": 15.0311,
                "lng": 102.1381
            },
            "Ban Lam Narai": {
                "lat": 15.2,
                "lng": 101.1333
            },
            "Ban Nong Han": {
                "lat": 18.9,
                "lng": 99.0
            },
            "Ban Thung Tam Sao": {
                "lat": 6.9581,
                "lng": 100.3207
            },
            "Aranyaprathet": {
                "lat": 13.6928,
                "lng": 102.5017
            },
            "Nakhon Nayok": {
                "lat": 14.2069,
                "lng": 101.2142
            },
            "Ban Kao": {
                "lat": 13.8667,
                "lng": 99.15
            },
            "Bang Phae": {
                "lat": 13.6983,
                "lng": 99.9068
            },
            "Ranong": {
                "lat": 9.9619,
                "lng": 98.6389
            },
            "Chai Prakan": {
                "lat": 19.7322,
                "lng": 99.1403
            },
            "Ban Dung": {
                "lat": 17.6986,
                "lng": 103.2611
            },
            "Lam Luk Ka": {
                "lat": 13.9297,
                "lng": 100.7375
            },
            "Ban Samo Khae": {
                "lat": 16.8408,
                "lng": 100.3415
            },
            "Si Satchanalai": {
                "lat": 17.4155,
                "lng": 99.8152
            },
            "Ban Tha Pho": {
                "lat": 16.8582,
                "lng": 100.2291
            },
            "Sukhothai": {
                "lat": 17.0,
                "lng": 99.8167
            },
            "Ban Pang Mu": {
                "lat": 19.3336,
                "lng": 97.9656
            },
            "Ban Nong Kathao": {
                "lat": 16.9833,
                "lng": 100.7833
            },
            "Ban Duea": {
                "lat": 16.1253,
                "lng": 101.8967
            },
            "Uthai Thani": {
                "lat": 15.38,
                "lng": 100.025
            },
            "Khao Yoi": {
                "lat": 13.2403,
                "lng": 99.8254
            },
            "Ban Khek Noi": {
                "lat": 16.8118,
                "lng": 100.9671
            },
            "Thoen": {
                "lat": 17.61,
                "lng": 99.2234
            },
            "Ban Na Kham": {
                "lat": 14.0681,
                "lng": 101.8125
            },
            "Ban Ton Thong Chai": {
                "lat": 18.3375,
                "lng": 99.5334
            },
            "Ban Ho Mae Salong": {
                "lat": 20.1631,
                "lng": 99.6225
            },
            "Ban Mae Sun Luang": {
                "lat": 19.8305,
                "lng": 99.1706
            },
            "Ban San Phak Wan Luang": {
                "lat": 18.7049,
                "lng": 98.9661
            },
            "Ban Du": {
                "lat": 19.9691,
                "lng": 99.8589
            },
            "Ban Kaeng": {
                "lat": 16.4119,
                "lng": 102.0402
            },
            "Ban Ao Nang": {
                "lat": 8.0314,
                "lng": 98.8225
            },
            "Ban Don Kaeo": {
                "lat": 18.8567,
                "lng": 98.9689
            },
            "Ban Nong Kula": {
                "lat": 16.65,
                "lng": 99.95
            },
            "Pak Thong Chai": {
                "lat": 14.7167,
                "lng": 102.0167
            },
            "Ban Tha Thong": {
                "lat": 16.8582,
                "lng": 100.2291
            },
            "Det Udom": {
                "lat": 14.906,
                "lng": 105.0784
            },
            "Bua Yai": {
                "lat": 15.5858,
                "lng": 102.4337
            },
            "Thong Pha Phum": {
                "lat": 14.7382,
                "lng": 98.632
            },
            "Sai Buri": {
                "lat": 6.7012,
                "lng": 101.6181
            },
            "Ban Mueang Nga": {
                "lat": 18.5997,
                "lng": 99.0181
            },
            "Pathum Thani": {
                "lat": 14.05,
                "lng": 100.4833
            },
            "Bang Racham": {
                "lat": 14.9,
                "lng": 100.3167
            },
            "Taphan Hin": {
                "lat": 16.2108,
                "lng": 100.4188
            },
            "Ban Bang Muang": {
                "lat": 13.8273,
                "lng": 100.3859
            },
            "Ban Tha Luang Lang": {
                "lat": 12.6376,
                "lng": 102.0884
            },
            "Ban Bung Kha": {
                "lat": 16.1675,
                "lng": 104.6408
            },
            "Ban Mae Kaluang": {
                "lat": 19.0778,
                "lng": 99.927
            },
            "Dok Kham Tai": {
                "lat": 19.162,
                "lng": 99.9926
            },
            "Ban Don Thong": {
                "lat": 16.904,
                "lng": 100.3563
            },
            "Ban Na Chom Thian": {
                "lat": 12.8356,
                "lng": 100.9264
            },
            "Ang Thong": {
                "lat": 14.5925,
                "lng": 100.4572
            },
            "Ban Chomphu Nuea": {
                "lat": 16.6833,
                "lng": 100.6667
            },
            "Ban Khlong": {
                "lat": 16.8353,
                "lng": 100.232
            },
            "Ban Chorakhe Samphan": {
                "lat": 14.3258,
                "lng": 99.8623
            },
            "Ban Nong Hoi": {
                "lat": 18.75,
                "lng": 99.0167
            },
            "Phra Samut Chedi": {
                "lat": 13.5976,
                "lng": 100.5848
            },
            "Song Phi Nong": {
                "lat": 14.2306,
                "lng": 100.0389
            },
            "Ban Krang": {
                "lat": 16.746,
                "lng": 100.275
            },
            "Ban Tat": {
                "lat": 17.2791,
                "lng": 102.8022
            },
            "Kaeng Khoi": {
                "lat": 14.5864,
                "lng": 100.9967
            },
            "Ban Sop Tia": {
                "lat": 18.3895,
                "lng": 98.678
            },
            "Chaiyo": {
                "lat": 14.6666,
                "lng": 100.4702
            },
            "Lamphun": {
                "lat": 18.5864,
                "lng": 99.0119
            },
            "Ban Tom Klang": {
                "lat": 19.1961,
                "lng": 99.8378
            },
            "Chok Chai": {
                "lat": 14.7333,
                "lng": 102.1667
            },
            "Rong Kwang": {
                "lat": 18.3392,
                "lng": 100.3172
            },
            "Ban Laem": {
                "lat": 13.2168,
                "lng": 99.9767
            },
            "Chai Nat": {
                "lat": 15.1872,
                "lng": 100.1283
            },
            "Kantang": {
                "lat": 7.4067,
                "lng": 99.515
            },
            "Bang Phlat": {
                "lat": 13.8247,
                "lng": 100.4908
            },
            "Ban Bang Non": {
                "lat": 9.9923,
                "lng": 98.6507
            },
            "Huai Yot": {
                "lat": 7.7894,
                "lng": 99.6347
            },
            "Nong Khae": {
                "lat": 14.3352,
                "lng": 100.8727
            },
            "Doi Lo": {
                "lat": 18.4667,
                "lng": 98.7833
            },
            "Ban Tap Tao": {
                "lat": 19.728,
                "lng": 100.2939
            },
            "Ban Huai So Nuea": {
                "lat": 20.0536,
                "lng": 100.2828
            },
            "Na Wa": {
                "lat": 17.4692,
                "lng": 104.102
            },
            "Ban Bo Luang": {
                "lat": 18.1476,
                "lng": 98.3489
            },
            "Ban Bang Sai": {
                "lat": 13.3847,
                "lng": 100.9856
            },
            "Ban Noen Phoem": {
                "lat": 17.1167,
                "lng": 100.8667
            },
            "Ban Muang Ngam": {
                "lat": 7.3536,
                "lng": 100.4898
            },
            "Ban Pae": {
                "lat": 18.2108,
                "lng": 98.6164
            },
            "Wang Saphung": {
                "lat": 17.2995,
                "lng": 101.7624
            },
            "Nong Bua": {
                "lat": 15.8647,
                "lng": 100.5858
            },
            "Don Sak": {
                "lat": 9.3169,
                "lng": 99.6944
            },
            "Kuchinarai": {
                "lat": 16.5318,
                "lng": 104.044
            },
            "Ban Phan Don": {
                "lat": 17.129,
                "lng": 102.9618
            },
            "Lom Sak": {
                "lat": 16.7775,
                "lng": 101.2468
            },
            "Ban Mae Tuen": {
                "lat": 18.01,
                "lng": 98.8809
            },
            "Phon": {
                "lat": 15.8084,
                "lng": 102.6018
            },
            "Ban Mae Kham Lang Wat": {
                "lat": 20.2225,
                "lng": 99.8561
            },
            "Ban Wiang Phan": {
                "lat": 20.4128,
                "lng": 99.8853
            },
            "Den Chai": {
                "lat": 17.9835,
                "lng": 100.0519
            },
            "Ban Saeo": {
                "lat": 20.2158,
                "lng": 100.225
            },
            "Satuek": {
                "lat": 15.3008,
                "lng": 103.3013
            },
            "Ban Bang Lamung": {
                "lat": 13.047,
                "lng": 100.9289
            },
            "Ban Kat": {
                "lat": 18.1764,
                "lng": 97.9339
            },
            "Lang Suan": {
                "lat": 9.95,
                "lng": 99.0667
            },
            "Kaset Wisai": {
                "lat": 15.6556,
                "lng": 103.5836
            },
            "Salaya": {
                "lat": 13.8023,
                "lng": 100.3208
            },
            "Tha Chang": {
                "lat": 9.2674,
                "lng": 99.1922
            },
            "Bang Ban": {
                "lat": 14.4247,
                "lng": 100.4758
            },
            "Ban Wang Daeng": {
                "lat": 17.479,
                "lng": 100.1247
            },
            "Si Wilai": {
                "lat": 18.1865,
                "lng": 103.7408
            },
            "Phanat Nikhom": {
                "lat": 13.4458,
                "lng": 101.1844
            },
            "Fao Rai": {
                "lat": 18.0175,
                "lng": 103.3057
            },
            "Ban Yang Hom": {
                "lat": 19.9222,
                "lng": 100.3056
            },
            "Mae Wang": {
                "lat": 18.6567,
                "lng": 98.6816
            },
            "Phrai Bueng": {
                "lat": 14.749,
                "lng": 104.3574
            },
            "Phibun Mangsahan": {
                "lat": 15.2482,
                "lng": 105.2296
            },
            "Khlung": {
                "lat": 12.4525,
                "lng": 102.2267
            },
            "Pua": {
                "lat": 19.1799,
                "lng": 100.9089
            },
            "Ban Bang Yai": {
                "lat": 13.8369,
                "lng": 100.3591
            },
            "Doi Saket": {
                "lat": 18.8667,
                "lng": 99.1333
            },
            "Wang Sombun": {
                "lat": 13.3515,
                "lng": 102.1833
            },
            "Sam Ko": {
                "lat": 14.6013,
                "lng": 100.2602
            },
            "Ban Ko": {
                "lat": 13.6486,
                "lng": 100.0063
            },
            "Ban Thung Khao Phuang": {
                "lat": 19.5342,
                "lng": 98.9621
            },
            "Ban Lueak": {
                "lat": 13.7065,
                "lng": 99.8902
            },
            "Phai Sali": {
                "lat": 15.6,
                "lng": 100.6494
            },
            "Trat": {
                "lat": 12.2417,
                "lng": 102.5125
            },
            "Phangnga": {
                "lat": 8.4644,
                "lng": 98.5317
            },
            "Prakhon Chai": {
                "lat": 14.6092,
                "lng": 103.0818
            },
            "Ban Ueam": {
                "lat": 18.4246,
                "lng": 99.4434
            },
            "Ban Non Sombun": {
                "lat": 18.2983,
                "lng": 103.6528
            },
            "Phon Charoen": {
                "lat": 18.0258,
                "lng": 103.7094
            },
            "Ban Dan Na Kham": {
                "lat": 17.7167,
                "lng": 100.1333
            },
            "Ban Wisit": {
                "lat": 18.348,
                "lng": 103.6308
            },
            "Kut Chap": {
                "lat": 17.4262,
                "lng": 102.5646
            },
            "Tha Muang": {
                "lat": 13.9611,
                "lng": 99.6411
            },
            "Phra Pradaeng": {
                "lat": 13.6592,
                "lng": 100.5331
            },
            "Kranuan": {
                "lat": 16.7081,
                "lng": 103.0811
            },
            "Ban Bong Tai": {
                "lat": 17.4057,
                "lng": 103.2992
            },
            "Ban Bang Toei": {
                "lat": 14.0656,
                "lng": 100.5226
            },
            "Ban Pa Hung": {
                "lat": 19.5672,
                "lng": 99.7013
            },
            "Nong Ki": {
                "lat": 14.6867,
                "lng": 102.5325
            },
            "Ban Bu Sung": {
                "lat": 14.9602,
                "lng": 104.1657
            },
            "Sung Noen": {
                "lat": 14.8965,
                "lng": 101.8167
            },
            "Ban Ngao": {
                "lat": 19.6736,
                "lng": 100.2583
            },
            "Kamalasai": {
                "lat": 16.3383,
                "lng": 103.5756
            },
            "Ban Pong Yaeng Nai": {
                "lat": 18.8833,
                "lng": 98.8167
            },
            "Phak Hai": {
                "lat": 14.4626,
                "lng": 100.3667
            },
            "Ban Pha Bong": {
                "lat": 19.2266,
                "lng": 97.9707
            },
            "Ban Phan Chali": {
                "lat": 16.6333,
                "lng": 100.4833
            },
            "Tha Mai": {
                "lat": 12.6196,
                "lng": 102.0112
            },
            "Nong Wua So": {
                "lat": 17.2702,
                "lng": 102.5985
            },
            "Ban Mae Sam Laep": {
                "lat": 17.975,
                "lng": 97.7392
            },
            "Mae O": {
                "lat": 19.65,
                "lng": 99.8333
            },
            "Chom Thong": {
                "lat": 18.4901,
                "lng": 98.6747
            },
            "Bueng Khong Long": {
                "lat": 17.9667,
                "lng": 104.045
            },
            "Ban Mae Chedi": {
                "lat": 19.1833,
                "lng": 99.5167
            },
            "Non Sung": {
                "lat": 15.1788,
                "lng": 102.2514
            },
            "Chiang Klang": {
                "lat": 19.293,
                "lng": 100.8739
            },
            "Mae Ai": {
                "lat": 20.0314,
                "lng": 99.2842
            },
            "Ban Bueng Kok": {
                "lat": 16.6833,
                "lng": 100.0333
            },
            "Thap Khlo": {
                "lat": 16.16,
                "lng": 100.5967
            },
            "Ban Kang": {
                "lat": 18.5444,
                "lng": 98.8889
            },
            "Ban Dong Mada": {
                "lat": 19.7237,
                "lng": 99.7153
            },
            "Ban Lao Yao": {
                "lat": 18.3667,
                "lng": 98.7833
            },
            "Ban Nam Dip Luang": {
                "lat": 18.45,
                "lng": 98.8333
            },
            "Wiang Sa": {
                "lat": 8.6364,
                "lng": 99.3683
            },
            "Photharam": {
                "lat": 13.6918,
                "lng": 99.8531
            },
            "Ban Wiang Ka Long": {
                "lat": 19.2332,
                "lng": 99.5165
            },
            "Mae Rim": {
                "lat": 18.9163,
                "lng": 98.9605
            },
            "Ban Nong Tong": {
                "lat": 18.6115,
                "lng": 98.9466
            },
            "Kanchanadit": {
                "lat": 9.1653,
                "lng": 99.4706
            },
            "Ban Krot": {
                "lat": 14.3121,
                "lng": 100.6005
            },
            "Ban Sathan": {
                "lat": 18.2667,
                "lng": 100.7167
            },
            "Ban Bang Phlap": {
                "lat": 13.9241,
                "lng": 100.4684
            },
            "Ban San Pong": {
                "lat": 18.9424,
                "lng": 98.9439
            },
            "Ban Khi Lek": {
                "lat": 19.0625,
                "lng": 98.9396
            },
            "Bang Khla": {
                "lat": 13.7268,
                "lng": 101.2105
            },
            "Ban Bo Phlap": {
                "lat": 13.8439,
                "lng": 100.0686
            },
            "Nakhon Thai": {
                "lat": 17.1011,
                "lng": 100.8296
            },
            "Ban Son Loi": {
                "lat": 13.9122,
                "lng": 100.42
            },
            "Nong Kung Si": {
                "lat": 16.65,
                "lng": 103.3
            },
            "Pong Nam Ron": {
                "lat": 12.9057,
                "lng": 102.2663
            },
            "Ban Kham Pom": {
                "lat": 15.9653,
                "lng": 105.2112
            },
            "Ban Chang Phuak": {
                "lat": 18.8032,
                "lng": 98.9811
            },
            "Suwannaphum": {
                "lat": 15.6078,
                "lng": 103.8
            },
            "Ban Wat Chan": {
                "lat": 16.8033,
                "lng": 100.2351
            },
            "Ban Yaeng": {
                "lat": 16.8833,
                "lng": 100.8
            },
            "Ban Wang Krachae": {
                "lat": 14.2333,
                "lng": 98.8667
            },
            "Ban Tha Phra": {
                "lat": 16.3298,
                "lng": 102.7998
            },
            "Ban Ngio Ngam": {
                "lat": 17.6671,
                "lng": 100.1754
            },
            "Ban Wang Pradu": {
                "lat": 16.76,
                "lng": 100.35
            },
            "Akat Amnuai": {
                "lat": 17.5898,
                "lng": 103.9859
            },
            "Kaeng Khro": {
                "lat": 16.1086,
                "lng": 102.2581
            },
            "Tha Luang": {
                "lat": 15.0697,
                "lng": 101.1182
            },
            "Kosum Phisai": {
                "lat": 16.243,
                "lng": 103.0627
            },
            "Thung Sai": {
                "lat": 16.2955,
                "lng": 99.8089
            },
            "Ban Nikhom Phatthana": {
                "lat": 16.7242,
                "lng": 99.9697
            },
            "Thap Than": {
                "lat": 15.457,
                "lng": 99.8959
            },
            "Sai Ngam": {
                "lat": 16.4669,
                "lng": 99.8902
            },
            "Kui Buri": {
                "lat": 12.0702,
                "lng": 99.8667
            },
            "Ban Pong Tao": {
                "lat": 18.8333,
                "lng": 99.95
            },
            "Ban Noen Kum Nueng": {
                "lat": 16.55,
                "lng": 100.4167
            },
            "Ban Muang Kham": {
                "lat": 19.5008,
                "lng": 99.7466
            },
            "Takua Pa": {
                "lat": 8.8658,
                "lng": 98.3413
            },
            "Ban Si Don Chai": {
                "lat": 20.13,
                "lng": 100.3614
            },
            "Ban Sai Yoi": {
                "lat": 16.4167,
                "lng": 100.6
            },
            "Pa Mok": {
                "lat": 14.4921,
                "lng": 100.4448
            },
            "Nam Som": {
                "lat": 17.7694,
                "lng": 102.1435
            },
            "Phimai": {
                "lat": 15.2229,
                "lng": 102.494
            },
            "Yang Talat": {
                "lat": 16.3997,
                "lng": 103.3678
            },
            "Pa Sang": {
                "lat": 18.5261,
                "lng": 98.9394
            },
            "Wiang Haeng": {
                "lat": 19.55,
                "lng": 98.65
            },
            "Ban Ratchakrut": {
                "lat": 9.7571,
                "lng": 98.5915
            },
            "Na Yung": {
                "lat": 17.9142,
                "lng": 102.2403
            },
            "Ban Charoen Mueang": {
                "lat": 19.6075,
                "lng": 99.7547
            },
            "Ban Wat Phrik": {
                "lat": 16.746,
                "lng": 100.275
            },
            "Mae Hong Son": {
                "lat": 19.3011,
                "lng": 97.97
            },
            "Bueng Kan": {
                "lat": 18.3254,
                "lng": 103.6704
            },
            "Ban Huai Hin": {
                "lat": 12.5697,
                "lng": 99.9443
            }
        }
    },
    "RU": {
        "name": "Russia",
        "cities": {
            "Moscow": {
                "lat": 55.7558,
                "lng": 37.6172
            },
            "Saint Petersburg": {
                "lat": 59.9375,
                "lng": 30.3086
            },
            "Novosibirsk": {
                "lat": 55.05,
                "lng": 82.95
            },
            "Yekaterinburg": {
                "lat": 56.8356,
                "lng": 60.6128
            },
            "Kazan": {
                "lat": 55.7964,
                "lng": 49.1089
            },
            "Nizhniy Novgorod": {
                "lat": 56.3269,
                "lng": 44.0075
            },
            "Chelyabinsk": {
                "lat": 55.1547,
                "lng": 61.3758
            },
            "Omsk": {
                "lat": 54.9833,
                "lng": 73.3667
            },
            "Samara": {
                "lat": 53.2028,
                "lng": 50.1408
            },
            "Rostov": {
                "lat": 57.1833,
                "lng": 39.4167
            },
            "Ufa": {
                "lat": 54.7261,
                "lng": 55.9475
            },
            "Krasnoyarsk": {
                "lat": 56.0089,
                "lng": 92.8719
            },
            "Voronezh": {
                "lat": 51.6717,
                "lng": 39.2106
            },
            "Perm": {
                "lat": 58.0,
                "lng": 56.3167
            },
            "Volgograd": {
                "lat": 48.7086,
                "lng": 44.5147
            },
            "Krasnodar": {
                "lat": 45.0333,
                "lng": 38.9667
            },
            "Tyumen": {
                "lat": 57.15,
                "lng": 65.5333
            },
            "Saratov": {
                "lat": 51.5333,
                "lng": 46.0167
            },
            "Tolyatti": {
                "lat": 53.5089,
                "lng": 49.4222
            },
            "Makhachkala": {
                "lat": 42.9667,
                "lng": 47.4833
            },
            "Izhevsk": {
                "lat": 56.8333,
                "lng": 53.1833
            },
            "Barnaul": {
                "lat": 53.3486,
                "lng": 83.7764
            },
            "Ulyanovsk": {
                "lat": 54.3167,
                "lng": 48.3667
            },
            "Irkutsk": {
                "lat": 52.2833,
                "lng": 104.2833
            },
            "Khabarovsk": {
                "lat": 48.4833,
                "lng": 135.0833
            },
            "Yaroslavl": {
                "lat": 57.6167,
                "lng": 39.85
            },
            "Vladivostok": {
                "lat": 43.1333,
                "lng": 131.9
            },
            "Tomsk": {
                "lat": 56.5,
                "lng": 84.9667
            },
            "Orenburg": {
                "lat": 51.7833,
                "lng": 55.1
            },
            "Kemerovo": {
                "lat": 55.3667,
                "lng": 86.0667
            },
            "Novokuznetsk": {
                "lat": 53.7667,
                "lng": 87.1333
            },
            "Ryazan": {
                "lat": 54.63,
                "lng": 39.7425
            },
            "Naberezhnyye Chelny": {
                "lat": 55.6833,
                "lng": 52.3167
            },
            "Astrakhan": {
                "lat": 46.35,
                "lng": 48.035
            },
            "Penza": {
                "lat": 53.2,
                "lng": 45.0
            },
            "Lipetsk": {
                "lat": 52.6167,
                "lng": 39.6
            },
            "Kirov": {
                "lat": 54.0833,
                "lng": 34.3167
            },
            "Kaliningrad": {
                "lat": 54.7003,
                "lng": 20.4531
            },
            "Cheboksary": {
                "lat": 56.15,
                "lng": 47.2333
            },
            "Balashikha": {
                "lat": 55.8167,
                "lng": 37.9667
            },
            "Tula": {
                "lat": 54.2,
                "lng": 37.6167
            },
            "Kursk": {
                "lat": 51.7167,
                "lng": 36.1833
            },
            "Stavropol": {
                "lat": 45.05,
                "lng": 41.9833
            },
            "Ulan-Ude": {
                "lat": 51.8333,
                "lng": 107.6
            },
            "Tver": {
                "lat": 56.8625,
                "lng": 35.9242
            },
            "Magnitogorsk": {
                "lat": 53.3833,
                "lng": 59.0333
            },
            "Sochi": {
                "lat": 43.5853,
                "lng": 39.7203
            },
            "Ivanovo": {
                "lat": 56.9967,
                "lng": 40.9819
            },
            "Bryansk": {
                "lat": 53.2333,
                "lng": 34.3667
            },
            "Surgut": {
                "lat": 61.25,
                "lng": 73.4333
            },
            "Nizhniy Tagil": {
                "lat": 57.9167,
                "lng": 59.9667
            },
            "Vladimir": {
                "lat": 56.1286,
                "lng": 40.4058
            },
            "Arkhangelsk": {
                "lat": 64.5333,
                "lng": 40.5333
            },
            "Chita": {
                "lat": 52.05,
                "lng": 113.4667
            },
            "Belgorod": {
                "lat": 50.6,
                "lng": 36.6
            },
            "Kaluga": {
                "lat": 54.55,
                "lng": 36.2833
            },
            "Volzhskiy": {
                "lat": 48.8056,
                "lng": 44.7417
            },
            "Smolensk": {
                "lat": 54.7828,
                "lng": 32.0453
            },
            "Saransk": {
                "lat": 54.1833,
                "lng": 45.1833
            },
            "Cherepovets": {
                "lat": 59.1167,
                "lng": 37.9
            },
            "Vologda": {
                "lat": 59.2167,
                "lng": 39.9
            },
            "Yakutsk": {
                "lat": 62.03,
                "lng": 129.73
            },
            "Kurgan": {
                "lat": 55.4667,
                "lng": 65.35
            },
            "Vladikavkaz": {
                "lat": 43.04,
                "lng": 44.6775
            },
            "Or\u00ebl": {
                "lat": 52.9686,
                "lng": 36.0694
            },
            "Podolsk": {
                "lat": 55.4311,
                "lng": 37.5456
            },
            "Groznyy": {
                "lat": 43.3125,
                "lng": 45.6986
            },
            "Tambov": {
                "lat": 52.7231,
                "lng": 41.4539
            },
            "Sterlitamak": {
                "lat": 53.6333,
                "lng": 55.95
            },
            "Nizhnevartovsk": {
                "lat": 60.95,
                "lng": 76.6
            },
            "Petrozavodsk": {
                "lat": 61.7833,
                "lng": 34.3333
            },
            "Kostroma": {
                "lat": 57.7681,
                "lng": 40.9269
            },
            "Novorossiysk": {
                "lat": 44.7167,
                "lng": 37.7833
            },
            "Murmansk": {
                "lat": 68.9706,
                "lng": 33.075
            },
            "Yoshkar-Ola": {
                "lat": 56.65,
                "lng": 47.8833
            },
            "Nalchik": {
                "lat": 43.4833,
                "lng": 43.6167
            },
            "Engels": {
                "lat": 51.5017,
                "lng": 46.1222
            },
            "Khimki": {
                "lat": 55.8892,
                "lng": 37.445
            },
            "Taganrog": {
                "lat": 47.2167,
                "lng": 38.9167
            },
            "Komsomol\u2019sk-na-Amure": {
                "lat": 50.5667,
                "lng": 137.0
            },
            "Syktyvkar": {
                "lat": 61.6667,
                "lng": 50.8167
            },
            "Nizhnekamsk": {
                "lat": 55.6333,
                "lng": 51.8167
            },
            "Shakhty": {
                "lat": 47.7,
                "lng": 40.2333
            },
            "Bratsk": {
                "lat": 56.1167,
                "lng": 101.6
            },
            "Dzerzhinsk": {
                "lat": 56.2333,
                "lng": 43.45
            },
            "Noginsk": {
                "lat": 55.85,
                "lng": 38.4333
            },
            "Orsk": {
                "lat": 51.2,
                "lng": 58.5667
            },
            "Kolpino": {
                "lat": 59.7333,
                "lng": 30.6
            },
            "Blagoveshchensk": {
                "lat": 55.035,
                "lng": 55.9781
            },
            "Staryy Oskol": {
                "lat": 51.3,
                "lng": 37.8333
            },
            "Angarsk": {
                "lat": 52.55,
                "lng": 103.9
            },
            "Velikiy Novgorod": {
                "lat": 58.55,
                "lng": 31.2667
            },
            "Korol\u00ebv": {
                "lat": 55.9167,
                "lng": 37.8167
            },
            "Mytishchi": {
                "lat": 55.9167,
                "lng": 37.7667
            },
            "Pskov": {
                "lat": 57.8167,
                "lng": 28.3333
            },
            "Lyubertsy": {
                "lat": 55.6783,
                "lng": 37.8944
            },
            "Biysk": {
                "lat": 52.5333,
                "lng": 85.2167
            },
            "Yuzhno-Sakhalinsk": {
                "lat": 46.9667,
                "lng": 142.7333
            },
            "Armavir": {
                "lat": 45.0,
                "lng": 41.1167
            },
            "Rybinsk": {
                "lat": 58.05,
                "lng": 38.8333
            },
            "Prokopyevsk": {
                "lat": 53.8833,
                "lng": 86.7167
            },
            "Balakovo": {
                "lat": 52.0333,
                "lng": 47.7833
            },
            "Abakan": {
                "lat": 53.7167,
                "lng": 91.4667
            },
            "Norilsk": {
                "lat": 69.3333,
                "lng": 88.2167
            },
            "Krasnogorsk": {
                "lat": 48.4172,
                "lng": 142.0869
            },
            "Syzran": {
                "lat": 53.1667,
                "lng": 48.4667
            },
            "Volgodonsk": {
                "lat": 47.5167,
                "lng": 42.15
            },
            "Ussuriysk": {
                "lat": 43.8,
                "lng": 131.9667
            },
            "Kamensk-Ural\u2019skiy": {
                "lat": 56.4,
                "lng": 61.9333
            },
            "Novocherkassk": {
                "lat": 47.4222,
                "lng": 40.0939
            },
            "Zlatoust": {
                "lat": 55.1667,
                "lng": 59.6667
            },
            "Petropavlovsk-Kamchatskiy": {
                "lat": 53.0167,
                "lng": 158.65
            },
            "Elektrostal": {
                "lat": 55.7833,
                "lng": 38.4667
            },
            "Severodvinsk": {
                "lat": 64.5667,
                "lng": 39.8667
            },
            "Almetyevsk": {
                "lat": 54.9,
                "lng": 52.3
            },
            "Salavat": {
                "lat": 53.3667,
                "lng": 55.9333
            },
            "Miass": {
                "lat": 55.0,
                "lng": 60.1
            },
            "Orekhovo-Borisovo Yuzhnoye": {
                "lat": 55.6,
                "lng": 37.7333
            },
            "Kopeysk": {
                "lat": 55.1,
                "lng": 61.6167
            },
            "Odintsovo": {
                "lat": 55.6667,
                "lng": 37.2667
            },
            "Pyatigorsk": {
                "lat": 44.05,
                "lng": 43.0667
            },
            "Kolomna": {
                "lat": 55.0833,
                "lng": 38.7833
            },
            "Nakhodka": {
                "lat": 67.7206,
                "lng": 77.6617
            },
            "Berezniki": {
                "lat": 59.4167,
                "lng": 56.7833
            },
            "Khasavyurt": {
                "lat": 43.25,
                "lng": 46.5833
            },
            "Rubtsovsk": {
                "lat": 51.5167,
                "lng": 81.2
            },
            "Maykop": {
                "lat": 44.6,
                "lng": 40.0833
            },
            "Kovrov": {
                "lat": 56.3683,
                "lng": 41.3108
            },
            "Kislovodsk": {
                "lat": 43.9167,
                "lng": 42.7167
            },
            "Nefteyugansk": {
                "lat": 61.0833,
                "lng": 72.7
            },
            "Domodedovo": {
                "lat": 55.44,
                "lng": 37.7619
            },
            "Neftekamsk": {
                "lat": 56.1417,
                "lng": 54.4667
            },
            "Bataysk": {
                "lat": 47.1667,
                "lng": 39.7333
            },
            "Novocheboksarsk": {
                "lat": 56.1333,
                "lng": 47.5
            },
            "Serpukhov": {
                "lat": 54.9167,
                "lng": 37.4
            },
            "Shchelkovo": {
                "lat": 55.9167,
                "lng": 38.0
            },
            "Novomoskovsk": {
                "lat": 54.0833,
                "lng": 38.2167
            },
            "Kaspiysk": {
                "lat": 42.8803,
                "lng": 47.6383
            },
            "Derbent": {
                "lat": 42.05,
                "lng": 48.3
            },
            "Pervouralsk": {
                "lat": 56.9167,
                "lng": 59.9333
            },
            "Cherkessk": {
                "lat": 44.2167,
                "lng": 42.05
            },
            "Orekhovo-Zuyevo": {
                "lat": 55.8,
                "lng": 38.9667
            },
            "Nazran": {
                "lat": 43.2167,
                "lng": 44.7667
            },
            "Nevinnomyssk": {
                "lat": 44.6333,
                "lng": 41.9333
            },
            "Ramenskoye": {
                "lat": 55.5667,
                "lng": 38.2167
            },
            "Dimitrovgrad": {
                "lat": 54.1833,
                "lng": 49.5833
            },
            "Kyzyl": {
                "lat": 51.7167,
                "lng": 94.45
            },
            "Obninsk": {
                "lat": 55.0931,
                "lng": 36.6106
            },
            "Oktyabr\u2019skiy": {
                "lat": 52.6636,
                "lng": 156.2387
            },
            "Novyy Urengoy": {
                "lat": 66.0833,
                "lng": 76.6833
            },
            "Yessentuki": {
                "lat": 44.0333,
                "lng": 42.85
            },
            "Kamyshin": {
                "lat": 50.0833,
                "lng": 45.4
            },
            "Murom": {
                "lat": 55.5667,
                "lng": 42.0333
            },
            "Dolgoprudnyy": {
                "lat": 55.9333,
                "lng": 37.5
            },
            "Novoshakhtinsk": {
                "lat": 47.7667,
                "lng": 39.9167
            },
            "Zhukovskiy": {
                "lat": 55.5972,
                "lng": 38.1203
            },
            "Seversk": {
                "lat": 56.6,
                "lng": 84.85
            },
            "Reutov": {
                "lat": 55.7622,
                "lng": 37.8567
            },
            "Noyabrsk": {
                "lat": 63.2,
                "lng": 75.45
            },
            "Art\u00ebm": {
                "lat": 43.3667,
                "lng": 132.2
            },
            "Khanty-Mansiysk": {
                "lat": 61.0,
                "lng": 69.0
            },
            "Pushkino": {
                "lat": 56.0167,
                "lng": 37.8333
            },
            "Achinsk": {
                "lat": 56.2817,
                "lng": 90.5039
            },
            "Sergiyev Posad": {
                "lat": 56.315,
                "lng": 38.1367
            },
            "Yelets": {
                "lat": 52.6167,
                "lng": 38.4667
            },
            "Arzamas": {
                "lat": 55.4,
                "lng": 43.8167
            },
            "Berdsk": {
                "lat": 54.75,
                "lng": 83.1
            },
            "Elista": {
                "lat": 46.3167,
                "lng": 44.2667
            },
            "Novokuybyshevsk": {
                "lat": 53.1,
                "lng": 49.9333
            },
            "Zheleznogorsk": {
                "lat": 56.25,
                "lng": 93.5333
            },
            "Zel\u00ebnodol\u2019sk": {
                "lat": 55.85,
                "lng": 48.5167
            },
            "Tobolsk": {
                "lat": 58.2,
                "lng": 68.2667
            },
            "Sarapul": {
                "lat": 56.4667,
                "lng": 53.8
            },
            "Serov": {
                "lat": 59.6,
                "lng": 60.5667
            },
            "Votkinsk": {
                "lat": 57.05,
                "lng": 54.0
            },
            "Ukhta": {
                "lat": 63.5667,
                "lng": 53.7
            },
            "Leninsk-Kuznetskiy": {
                "lat": 54.6575,
                "lng": 86.1617
            },
            "Mezhdurechensk": {
                "lat": 53.6864,
                "lng": 88.0703
            },
            "Gatchina": {
                "lat": 59.5833,
                "lng": 30.1333
            },
            "Mikhaylovsk": {
                "lat": 56.45,
                "lng": 59.1333
            },
            "Sarov": {
                "lat": 54.9333,
                "lng": 43.3167
            },
            "Michurinsk": {
                "lat": 52.8833,
                "lng": 40.4833
            },
            "Glazov": {
                "lat": 58.1333,
                "lng": 52.6667
            },
            "Magadan": {
                "lat": 59.5667,
                "lng": 150.8
            },
            "Solikamsk": {
                "lat": 59.6433,
                "lng": 56.75
            },
            "Voskresensk": {
                "lat": 55.3167,
                "lng": 38.7
            },
            "Velikiye Luki": {
                "lat": 56.3333,
                "lng": 30.5333
            },
            "Kisel\u00ebvsk": {
                "lat": 54.0,
                "lng": 86.65
            },
            "Kamensk-Shakhtinskiy": {
                "lat": 48.3206,
                "lng": 40.2611
            },
            "Kansk": {
                "lat": 56.2,
                "lng": 95.7167
            },
            "Lobnya": {
                "lat": 56.0167,
                "lng": 37.4833
            },
            "Gubkin": {
                "lat": 51.2833,
                "lng": 37.5667
            },
            "Novotroitsk": {
                "lat": 51.2039,
                "lng": 58.3114
            },
            "Buzuluk": {
                "lat": 52.7833,
                "lng": 52.25
            },
            "Shch\u00ebkino": {
                "lat": 54.0,
                "lng": 37.5167
            },
            "Bugulma": {
                "lat": 54.5364,
                "lng": 52.7975
            },
            "Dmitrov": {
                "lat": 56.35,
                "lng": 37.5333
            },
            "Kineshma": {
                "lat": 57.4333,
                "lng": 42.1333
            },
            "Yeysk": {
                "lat": 46.7111,
                "lng": 38.2733
            },
            "Chaykovskiy": {
                "lat": 56.7667,
                "lng": 54.15
            },
            "Kuznetsk": {
                "lat": 53.1167,
                "lng": 46.6
            },
            "Ust\u2019-Ilimsk": {
                "lat": 58.0,
                "lng": 102.6667
            },
            "Yurga": {
                "lat": 55.7231,
                "lng": 84.8861
            },
            "Anapa": {
                "lat": 44.8944,
                "lng": 37.3167
            },
            "Novouralsk": {
                "lat": 57.25,
                "lng": 60.0833
            },
            "Azov": {
                "lat": 47.1083,
                "lng": 39.4181
            },
            "Klin": {
                "lat": 56.3333,
                "lng": 36.7333
            },
            "Oz\u00ebrsk": {
                "lat": 55.75,
                "lng": 60.7167
            },
            "Kropotkin": {
                "lat": 45.4333,
                "lng": 40.5667
            },
            "Usol\u2019ye-Sibirskoye": {
                "lat": 52.75,
                "lng": 103.6333
            },
            "Vorkuta": {
                "lat": 67.5,
                "lng": 64.0333
            },
            "Gelendzhik": {
                "lat": 44.575,
                "lng": 38.0725
            },
            "Balashov": {
                "lat": 51.5469,
                "lng": 43.1733
            },
            "Ivanteyevka": {
                "lat": 55.9833,
                "lng": 37.9333
            },
            "Anzhero-Sudzhensk": {
                "lat": 56.0833,
                "lng": 86.0333
            },
            "Shadrinsk": {
                "lat": 56.1333,
                "lng": 63.65
            },
            "Chernogorsk": {
                "lat": 53.8167,
                "lng": 91.3
            },
            "Dubna": {
                "lat": 56.7333,
                "lng": 37.1667
            },
            "Yelabuga": {
                "lat": 55.7667,
                "lng": 52.0833
            },
            "Mineral\u2019nyye Vody": {
                "lat": 44.2167,
                "lng": 43.1333
            },
            "Yegoryevsk": {
                "lat": 55.3833,
                "lng": 39.05
            },
            "Birobidzhan": {
                "lat": 48.8,
                "lng": 132.9333
            },
            "Novoaltaysk": {
                "lat": 53.3833,
                "lng": 83.9333
            },
            "Troitsk": {
                "lat": 54.0833,
                "lng": 61.5667
            },
            "Vsevolozhsk": {
                "lat": 60.0333,
                "lng": 30.6667
            },
            "Chapayevsk": {
                "lat": 52.9833,
                "lng": 49.7167
            },
            "Belovo": {
                "lat": 54.4167,
                "lng": 86.3
            },
            "Kirovo-Chepetsk": {
                "lat": 58.55,
                "lng": 50.0
            },
            "Vyborg": {
                "lat": 60.7167,
                "lng": 28.7667
            },
            "Verkhnyaya Pyshma": {
                "lat": 56.9761,
                "lng": 60.5944
            },
            "Chekhov": {
                "lat": 55.15,
                "lng": 37.45
            },
            "Tuymazy": {
                "lat": 54.6,
                "lng": 53.7
            },
            "Minusinsk": {
                "lat": 53.7,
                "lng": 91.6833
            },
            "Kstovo": {
                "lat": 56.1667,
                "lng": 44.1667
            },
            "Georgiyevsk": {
                "lat": 44.15,
                "lng": 43.4667
            },
            "Slavyansk-na-Kubani": {
                "lat": 45.25,
                "lng": 38.1167
            },
            "Belogorsk": {
                "lat": 50.9167,
                "lng": 128.4667
            },
            "Vidnoye": {
                "lat": 55.55,
                "lng": 37.7167
            },
            "Stupino": {
                "lat": 54.8833,
                "lng": 38.1
            },
            "Beloretsk": {
                "lat": 53.9667,
                "lng": 58.4
            },
            "Ishimbay": {
                "lat": 53.45,
                "lng": 56.0333
            },
            "Kungur": {
                "lat": 57.4333,
                "lng": 56.9333
            },
            "Zarechnyy": {
                "lat": 56.8167,
                "lng": 61.3333
            },
            "Ishim": {
                "lat": 56.1167,
                "lng": 69.5
            },
            "Buynaksk": {
                "lat": 42.8167,
                "lng": 47.1167
            },
            "Gukovo": {
                "lat": 48.05,
                "lng": 39.9167
            },
            "Asbest": {
                "lat": 57.0,
                "lng": 61.4667
            },
            "Donskoy": {
                "lat": 53.9658,
                "lng": 38.3247
            },
            "Gorno-Altaysk": {
                "lat": 51.95,
                "lng": 85.9667
            },
            "Volsk": {
                "lat": 52.05,
                "lng": 47.3833
            },
            "Novaya Balakhna": {
                "lat": 56.4899,
                "lng": 43.6011
            },
            "Klintsy": {
                "lat": 52.75,
                "lng": 32.2333
            },
            "Rossosh": {
                "lat": 50.2,
                "lng": 39.55
            },
            "Pavlovskiy Posad": {
                "lat": 55.7667,
                "lng": 38.6667
            },
            "Revda": {
                "lat": 56.8053,
                "lng": 59.9283
            },
            "Leninogorsk": {
                "lat": 54.6,
                "lng": 52.5
            },
            "Tuapse": {
                "lat": 44.1044,
                "lng": 39.0772
            },
            "Zelenogorsk": {
                "lat": 56.1,
                "lng": 94.5833
            },
            "Naro-Fominsk": {
                "lat": 55.3833,
                "lng": 36.7667
            },
            "Polevskoy": {
                "lat": 56.45,
                "lng": 60.1833
            },
            "Kotlas": {
                "lat": 61.25,
                "lng": 46.6333
            },
            "Borisoglebsk": {
                "lat": 51.3667,
                "lng": 42.0833
            },
            "Lysva": {
                "lat": 58.1003,
                "lng": 57.8042
            },
            "Bud\u00ebnnovsk": {
                "lat": 44.7833,
                "lng": 44.1667
            },
            "Sibay": {
                "lat": 52.7,
                "lng": 58.65
            },
            "Fryazino": {
                "lat": 55.95,
                "lng": 38.05
            },
            "Belebey": {
                "lat": 54.1,
                "lng": 54.1333
            },
            "Chistopol": {
                "lat": 55.3647,
                "lng": 50.6261
            },
            "Kumertau": {
                "lat": 52.7667,
                "lng": 55.7833
            },
            "Labinsk": {
                "lat": 44.6333,
                "lng": 40.7333
            },
            "Urus-Martan": {
                "lat": 43.1333,
                "lng": 45.55
            },
            "Rzhev": {
                "lat": 56.25,
                "lng": 34.3167
            },
            "Aleksandrov": {
                "lat": 56.4,
                "lng": 38.7333
            },
            "Izberbash": {
                "lat": 42.5633,
                "lng": 47.8636
            },
            "Lytkarino": {
                "lat": 55.5833,
                "lng": 37.9
            },
            "Nyagan": {
                "lat": 62.1333,
                "lng": 65.3833
            },
            "Tikhvin": {
                "lat": 59.65,
                "lng": 33.5333
            },
            "Meleuz": {
                "lat": 52.95,
                "lng": 55.9333
            },
            "Aleksin": {
                "lat": 54.5167,
                "lng": 37.1
            },
            "Ber\u00ebzovskiy": {
                "lat": 55.6167,
                "lng": 86.25
            },
            "Prokhladnyy": {
                "lat": 43.75,
                "lng": 44.0333
            },
            "Mikhaylovka": {
                "lat": 43.9283,
                "lng": 132.0061
            },
            "Tikhoretsk": {
                "lat": 45.8667,
                "lng": 40.1333
            },
            "Pavlovo": {
                "lat": 55.9653,
                "lng": 43.0711
            },
            "Salsk": {
                "lat": 46.4833,
                "lng": 41.5333
            },
            "Krasnotur\u2019insk": {
                "lat": 59.7733,
                "lng": 60.1853
            },
            "Krymsk": {
                "lat": 44.9233,
                "lng": 37.9806
            },
            "Shuya": {
                "lat": 56.85,
                "lng": 41.3667
            },
            "Neryungri": {
                "lat": 56.6833,
                "lng": 124.6667
            },
            "Iskitim": {
                "lat": 54.64,
                "lng": 83.3061
            },
            "Klimovsk": {
                "lat": 55.3667,
                "lng": 37.5333
            },
            "Shali": {
                "lat": 43.15,
                "lng": 45.9
            },
            "Lesosibirsk": {
                "lat": 58.2358,
                "lng": 92.4828
            },
            "Apatity": {
                "lat": 67.5667,
                "lng": 33.4
            },
            "Dzerzhinskiy": {
                "lat": 55.6333,
                "lng": 37.85
            },
            "Gus\u2019-Khrustal\u2019nyy": {
                "lat": 55.6167,
                "lng": 40.6833
            },
            "Volzhsk": {
                "lat": 55.8703,
                "lng": 48.3561
            },
            "Svobodnyy": {
                "lat": 51.4,
                "lng": 128.1
            },
            "Krasnokamsk": {
                "lat": 58.0833,
                "lng": 55.6833
            },
            "Liski": {
                "lat": 50.9667,
                "lng": 39.5167
            },
            "Zhigulevsk": {
                "lat": 53.3997,
                "lng": 49.4953
            },
            "Vyksa": {
                "lat": 55.3167,
                "lng": 42.1667
            },
            "Gudermes": {
                "lat": 43.35,
                "lng": 46.1
            },
            "Sertolovo": {
                "lat": 60.15,
                "lng": 30.2167
            },
            "Vyazma": {
                "lat": 55.2107,
                "lng": 34.2912
            },
            "Krasnokamensk": {
                "lat": 50.1,
                "lng": 118.0333
            },
            "Belorechensk": {
                "lat": 44.7686,
                "lng": 39.8733
            },
            "Timashevsk": {
                "lat": 45.6167,
                "lng": 38.9333
            },
            "Solnechnogorsk": {
                "lat": 56.1833,
                "lng": 36.9833
            },
            "Uzlovaya": {
                "lat": 53.9833,
                "lng": 38.1667
            },
            "Severomorsk": {
                "lat": 69.0667,
                "lng": 33.4167
            },
            "Salekhard": {
                "lat": 66.5333,
                "lng": 66.6
            },
            "Snezhinsk": {
                "lat": 56.0833,
                "lng": 60.7333
            },
            "Kirishi": {
                "lat": 59.45,
                "lng": 32.0167
            },
            "Cheremkhovo": {
                "lat": 53.15,
                "lng": 103.0833
            },
            "Moskovskiy": {
                "lat": 55.6,
                "lng": 37.35
            },
            "Borovichi": {
                "lat": 58.4,
                "lng": 33.9167
            },
            "Roslavl": {
                "lat": 53.95,
                "lng": 32.8833
            },
            "Novovyatsk": {
                "lat": 58.5083,
                "lng": 49.6994
            },
            "Mozhga": {
                "lat": 56.45,
                "lng": 52.2167
            },
            "Kizlyar": {
                "lat": 43.85,
                "lng": 46.7167
            },
            "Buguruslan": {
                "lat": 53.6583,
                "lng": 52.4358
            },
            "Lesnoy": {
                "lat": 58.6333,
                "lng": 59.7833
            },
            "Kurganinsk": {
                "lat": 44.8667,
                "lng": 40.6167
            },
            "Shelekhov": {
                "lat": 52.2167,
                "lng": 104.1167
            },
            "Kashira": {
                "lat": 54.8333,
                "lng": 38.1667
            },
            "Donetsk": {
                "lat": 48.3333,
                "lng": 39.9333
            },
            "Sayanogorsk": {
                "lat": 53.05,
                "lng": 91.45
            },
            "Kingisepp": {
                "lat": 59.3667,
                "lng": 28.6167
            },
            "Arsenyev": {
                "lat": 44.1667,
                "lng": 133.25
            },
            "Livny": {
                "lat": 52.4239,
                "lng": 37.5997
            },
            "Otradnyy": {
                "lat": 53.3667,
                "lng": 51.35
            },
            "Vyshniy Voloch\u00ebk": {
                "lat": 57.5833,
                "lng": 34.5667
            },
            "Kotelniki": {
                "lat": 55.6617,
                "lng": 37.8672
            },
            "Zarinsk": {
                "lat": 53.7089,
                "lng": 84.9672
            },
            "Gryazi": {
                "lat": 52.5,
                "lng": 39.9667
            },
            "Megion": {
                "lat": 61.05,
                "lng": 76.1
            },
            "Torzhok": {
                "lat": 57.0333,
                "lng": 34.9667
            },
            "Kanash": {
                "lat": 55.5069,
                "lng": 47.4914
            },
            "Nazarovo": {
                "lat": 56.0,
                "lng": 90.3833
            },
            "Ruzayevka": {
                "lat": 54.0667,
                "lng": 44.9333
            },
            "Aksay": {
                "lat": 43.3667,
                "lng": 46.4333
            },
            "Parma": {
                "lat": 65.923,
                "lng": 57.403
            },
            "Nadym": {
                "lat": 65.5333,
                "lng": 72.5167
            },
            "Volkhov": {
                "lat": 59.9167,
                "lng": 32.3
            },
            "Kimry": {
                "lat": 56.8667,
                "lng": 37.35
            },
            "Kuybyshev": {
                "lat": 55.45,
                "lng": 78.3
            },
            "Yartsevo": {
                "lat": 55.0667,
                "lng": 32.7
            },
            "Rasskazovo": {
                "lat": 52.6667,
                "lng": 41.8833
            },
            "Chusovoy": {
                "lat": 58.3167,
                "lng": 57.8167
            },
            "Raduzhnyy": {
                "lat": 62.1333,
                "lng": 77.4667
            },
            "Birsk": {
                "lat": 55.4167,
                "lng": 55.5333
            },
            "Shebekino": {
                "lat": 50.4167,
                "lng": 36.9
            },
            "Osinniki": {
                "lat": 53.6167,
                "lng": 87.3333
            },
            "Mozdok": {
                "lat": 43.7333,
                "lng": 44.7
            },
            "Safonovo": {
                "lat": 55.1167,
                "lng": 33.25
            },
            "Monchegorsk": {
                "lat": 67.9333,
                "lng": 32.9167
            },
            "Korenovsk": {
                "lat": 45.4686,
                "lng": 39.4519
            },
            "Kolchugino": {
                "lat": 56.3167,
                "lng": 39.3667
            },
            "Satka": {
                "lat": 55.05,
                "lng": 59.0333
            },
            "Krasnoznamensk": {
                "lat": 55.6008,
                "lng": 37.0358
            },
            "Ust\u2019-Kut": {
                "lat": 56.7833,
                "lng": 105.6333
            },
            "Gay": {
                "lat": 51.4678,
                "lng": 58.4431
            },
            "Strezhevoy": {
                "lat": 60.7333,
                "lng": 77.5833
            },
            "Verkhnyaya Salda": {
                "lat": 58.0456,
                "lng": 60.5533
            },
            "Karabulak": {
                "lat": 43.3167,
                "lng": 44.9167
            },
            "Myski": {
                "lat": 53.7,
                "lng": 87.8167
            },
            "Temryuk": {
                "lat": 45.2667,
                "lng": 37.3667
            },
            "Spassk-Dal\u2019niy": {
                "lat": 44.6,
                "lng": 132.8167
            },
            "Pyt\u2019-Yakh": {
                "lat": 60.75,
                "lng": 72.7833
            },
            "Pugachev": {
                "lat": 52.0167,
                "lng": 48.8
            },
            "Pechora": {
                "lat": 65.1667,
                "lng": 57.25
            },
            "Uray": {
                "lat": 60.1333,
                "lng": 64.8
            },
            "Sovetsk": {
                "lat": 57.5833,
                "lng": 48.9833
            },
            "Sosnovoborsk": {
                "lat": 56.1333,
                "lng": 93.3667
            },
            "Chebarkul": {
                "lat": 54.9833,
                "lng": 60.3667
            },
            "Zainsk": {
                "lat": 55.3,
                "lng": 52.0167
            },
            "Kamen\u2019-na-Obi": {
                "lat": 53.7833,
                "lng": 81.3333
            },
            "Belaya Kalitva": {
                "lat": 48.1833,
                "lng": 40.7667
            },
            "Tutayev": {
                "lat": 57.8833,
                "lng": 39.55
            },
            "Amursk": {
                "lat": 50.2167,
                "lng": 136.9
            },
            "Yalutorovsk": {
                "lat": 56.65,
                "lng": 66.2833
            },
            "Apsheronsk": {
                "lat": 44.4667,
                "lng": 39.7333
            },
            "Novozybkov": {
                "lat": 52.5333,
                "lng": 31.9333
            },
            "Tulun": {
                "lat": 54.5667,
                "lng": 100.5667
            },
            "Abinsk": {
                "lat": 44.8667,
                "lng": 38.15
            },
            "Morshansk": {
                "lat": 53.45,
                "lng": 41.8167
            },
            "Yelizovo": {
                "lat": 53.1833,
                "lng": 158.3833
            },
            "Rtishchevo": {
                "lat": 52.2667,
                "lng": 43.7667
            },
            "Kachkanar": {
                "lat": 58.7,
                "lng": 59.4833
            },
            "Sayansk": {
                "lat": 54.1167,
                "lng": 102.1667
            },
            "Pereslavl\u2019-Zalesskiy": {
                "lat": 56.7333,
                "lng": 38.85
            },
            "Mariinsk": {
                "lat": 56.2,
                "lng": 87.7833
            },
            "Konakovo": {
                "lat": 56.7,
                "lng": 36.75
            },
            "Novodvinsk": {
                "lat": 64.4167,
                "lng": 40.8333
            },
            "Krasnoufimsk": {
                "lat": 56.6167,
                "lng": 57.7722
            },
            "Usinsk": {
                "lat": 66.0,
                "lng": 57.5333
            },
            "Mtsensk": {
                "lat": 53.2833,
                "lng": 36.5667
            },
            "Kurchatov": {
                "lat": 51.6667,
                "lng": 35.65
            },
            "Alekseyevka": {
                "lat": 50.6333,
                "lng": 38.7
            },
            "Krasnyy Sulin": {
                "lat": 47.8833,
                "lng": 40.1
            },
            "Zavolzhye": {
                "lat": 56.65,
                "lng": 43.4
            },
            "Baksan": {
                "lat": 43.6825,
                "lng": 43.5339
            },
            "Bol\u2019shoy Kamen\u2019": {
                "lat": 43.1167,
                "lng": 132.35
            },
            "Izobil\u2019nyy": {
                "lat": 45.3667,
                "lng": 41.7167
            },
            "Yuzhnouralsk": {
                "lat": 54.45,
                "lng": 61.25
            },
            "Uchaly": {
                "lat": 54.3167,
                "lng": 59.3833
            },
            "Uryupinsk": {
                "lat": 50.8,
                "lng": 42.0167
            },
            "Akhtubinsk": {
                "lat": 48.2833,
                "lng": 46.1667
            },
            "Dalnegorsk": {
                "lat": 44.55,
                "lng": 135.5833
            },
            "Tosno": {
                "lat": 59.55,
                "lng": 30.9
            },
            "Goryachiy Klyuch": {
                "lat": 44.6333,
                "lng": 39.1333
            },
            "Malgobek": {
                "lat": 43.5,
                "lng": 44.75
            },
            "Yugorsk": {
                "lat": 61.3167,
                "lng": 63.3333
            },
            "Argun": {
                "lat": 43.2944,
                "lng": 45.8839
            },
            "Alapayevsk": {
                "lat": 57.85,
                "lng": 61.7
            },
            "Kizilyurt": {
                "lat": 43.2167,
                "lng": 46.8667
            },
            "Rezh": {
                "lat": 57.3667,
                "lng": 61.3667
            },
            "Lesozavodsk": {
                "lat": 45.4667,
                "lng": 133.4
            },
            "Beslan": {
                "lat": 43.1833,
                "lng": 44.55
            },
            "Kyshtym": {
                "lat": 55.7,
                "lng": 60.55
            },
            "Irbit": {
                "lat": 57.6667,
                "lng": 63.0667
            },
            "Sokol": {
                "lat": 59.4667,
                "lng": 40.1167
            },
            "Boyarka": {
                "lat": 70.767,
                "lng": 97.5
            },
            "Kamenka": {
                "lat": 53.1833,
                "lng": 44.05
            },
            "Chernyakhovsk": {
                "lat": 54.6347,
                "lng": 21.8119
            },
            "Lyudinovo": {
                "lat": 53.8667,
                "lng": 34.4333
            },
            "Vyazniki": {
                "lat": 56.25,
                "lng": 42.1667
            },
            "Protvino": {
                "lat": 54.8833,
                "lng": 37.2167
            },
            "Svetlograd": {
                "lat": 45.35,
                "lng": 42.85
            },
            "Kinel": {
                "lat": 53.2222,
                "lng": 50.6333
            },
            "Frolovo": {
                "lat": 49.7667,
                "lng": 43.6667
            },
            "Millerovo": {
                "lat": 48.9167,
                "lng": 40.3833
            },
            "Mirnyy": {
                "lat": 62.7667,
                "lng": 40.3333
            },
            "Yefremov": {
                "lat": 53.15,
                "lng": 38.1167
            },
            "Luga": {
                "lat": 58.7333,
                "lng": 29.8167
            },
            "Aznakayevo": {
                "lat": 54.85,
                "lng": 53.1
            },
            "Alatyr": {
                "lat": 54.85,
                "lng": 46.5833
            },
            "Zelenokumsk": {
                "lat": 44.4167,
                "lng": 43.9167
            },
            "Korkino": {
                "lat": 54.9,
                "lng": 61.4167
            },
            "Koryazhma": {
                "lat": 61.3167,
                "lng": 47.1333
            },
            "Vichuga": {
                "lat": 57.2,
                "lng": 41.9167
            },
            "Gulkevichi": {
                "lat": 45.3594,
                "lng": 40.7072
            },
            "Valuyki": {
                "lat": 50.2167,
                "lng": 38.1
            },
            "Sharypovo": {
                "lat": 55.5333,
                "lng": 89.2
            },
            "Korsakov": {
                "lat": 46.6333,
                "lng": 142.7667
            },
            "Nizhneudinsk": {
                "lat": 54.9333,
                "lng": 99.0
            },
            "Bogorodsk": {
                "lat": 56.1167,
                "lng": 43.5
            },
            "Sukhoy Log": {
                "lat": 56.9167,
                "lng": 62.05
            },
            "Tayshet": {
                "lat": 55.95,
                "lng": 98.0167
            },
            "Tavda": {
                "lat": 58.05,
                "lng": 65.2667
            },
            "Baltiysk": {
                "lat": 54.65,
                "lng": 19.9167
            },
            "Shatura": {
                "lat": 55.5667,
                "lng": 39.55
            },
            "Furmanov": {
                "lat": 57.25,
                "lng": 41.1333
            },
            "Partizansk": {
                "lat": 43.1167,
                "lng": 133.1167
            },
            "Tynda": {
                "lat": 55.1667,
                "lng": 124.7167
            },
            "Chernushka": {
                "lat": 56.5167,
                "lng": 56.1
            },
            "Slobodskoy": {
                "lat": 58.7208,
                "lng": 50.185
            },
            "Tr\u00ebkhgornyy": {
                "lat": 54.8,
                "lng": 58.45
            },
            "Nurlat": {
                "lat": 54.4333,
                "lng": 50.8
            },
            "Dobryanka": {
                "lat": 58.4667,
                "lng": 56.4167
            },
            "Istra": {
                "lat": 55.9167,
                "lng": 36.8667
            },
            "Ostrogozhsk": {
                "lat": 50.8667,
                "lng": 39.0667
            },
            "Vyatskiye Polyany": {
                "lat": 56.2239,
                "lng": 51.0633
            },
            "Kulebaki": {
                "lat": 55.4167,
                "lng": 42.5333
            },
            "Slantsy": {
                "lat": 59.1167,
                "lng": 28.0667
            },
            "Primorsko-Akhtarsk": {
                "lat": 46.05,
                "lng": 38.1667
            },
            "Serdobsk": {
                "lat": 52.4667,
                "lng": 44.2
            },
            "Uglich": {
                "lat": 57.5333,
                "lng": 38.3333
            },
            "Velikiy Ustyug": {
                "lat": 60.7667,
                "lng": 46.3
            },
            "Teykovo": {
                "lat": 56.8547,
                "lng": 40.5411
            },
            "Sosnogorsk": {
                "lat": 63.6,
                "lng": 53.9
            },
            "Novovoronezh": {
                "lat": 51.3167,
                "lng": 39.2167
            },
            "Marks": {
                "lat": 51.7167,
                "lng": 46.75
            },
            "Kudymkar": {
                "lat": 59.0167,
                "lng": 54.6667
            },
            "Kandalaksha": {
                "lat": 67.1569,
                "lng": 32.4117
            },
            "Bogoroditsk": {
                "lat": 53.7667,
                "lng": 38.1333
            },
            "Sovetskiy": {
                "lat": 61.3614,
                "lng": 63.5842
            },
            "Zima": {
                "lat": 53.9167,
                "lng": 102.05
            },
            "Dyurtyuli": {
                "lat": 55.4833,
                "lng": 54.8667
            },
            "Nartkala": {
                "lat": 43.55,
                "lng": 43.85
            },
            "Blagodarnyy": {
                "lat": 45.1,
                "lng": 43.45
            },
            "Ust\u2019-Dzheguta": {
                "lat": 44.0872,
                "lng": 41.9733
            },
            "Kondopoga": {
                "lat": 62.2,
                "lng": 34.2833
            },
            "Kasimov": {
                "lat": 54.9333,
                "lng": 41.4
            },
            "Mozhaysk": {
                "lat": 55.5167,
                "lng": 36.0333
            },
            "Gorodets": {
                "lat": 56.65,
                "lng": 43.4833
            },
            "Kokhma": {
                "lat": 56.9311,
                "lng": 41.0889
            },
            "Kotovsk": {
                "lat": 52.5833,
                "lng": 41.5167
            },
            "Slavgorod": {
                "lat": 52.9833,
                "lng": 78.6667
            },
            "Art\u00ebmovskiy": {
                "lat": 57.3564,
                "lng": 61.8711
            },
            "Dedovsk": {
                "lat": 55.85,
                "lng": 37.1167
            },
            "Dagestanskiye Ogni": {
                "lat": 42.1167,
                "lng": 48.1833
            },
            "Asha": {
                "lat": 55.0,
                "lng": 57.2667
            },
            "Kostomuksha": {
                "lat": 64.5833,
                "lng": 30.6
            },
            "Likino-Dulevo": {
                "lat": 55.7167,
                "lng": 38.95
            },
            "Novaya Usman\u2019": {
                "lat": 51.645,
                "lng": 39.4142
            },
            "Divnogorsk": {
                "lat": 55.9594,
                "lng": 92.3619
            },
            "Ob": {
                "lat": 54.9917,
                "lng": 82.7125
            },
            "Bogdanovich": {
                "lat": 56.7803,
                "lng": 62.0494
            },
            "Shumerlya": {
                "lat": 55.5333,
                "lng": 46.3833
            },
            "Gagarin": {
                "lat": 55.55,
                "lng": 35.0167
            },
            "Barabinsk": {
                "lat": 55.35,
                "lng": 78.35
            },
            "Petrovsk": {
                "lat": 52.3167,
                "lng": 45.3833
            },
            "Borzya": {
                "lat": 50.3833,
                "lng": 116.5167
            },
            "Kartaly": {
                "lat": 53.05,
                "lng": 60.65
            },
            "Aleysk": {
                "lat": 52.5,
                "lng": 82.7833
            },
            "Gusev": {
                "lat": 54.5833,
                "lng": 22.2
            },
            "Pokhvistnevo": {
                "lat": 53.65,
                "lng": 52.1333
            },
            "Udomlya": {
                "lat": 57.8833,
                "lng": 35.0
            },
            "Tara": {
                "lat": 56.8833,
                "lng": 74.3667
            },
            "Topki": {
                "lat": 55.3333,
                "lng": 85.75
            },
            "Desnogorsk": {
                "lat": 54.15,
                "lng": 33.2833
            },
            "Yemanzhelinsk": {
                "lat": 54.7667,
                "lng": 61.3333
            },
            "Sorochinsk": {
                "lat": 52.4167,
                "lng": 53.1
            },
            "Verkhniy Ufaley": {
                "lat": 56.0667,
                "lng": 60.2333
            },
            "Kholmsk": {
                "lat": 47.05,
                "lng": 142.05
            },
            "Aleksandrovskoye": {
                "lat": 44.7167,
                "lng": 43.0
            },
            "Maloyaroslavets": {
                "lat": 55.0167,
                "lng": 36.4667
            },
            "Staraya Russa": {
                "lat": 58.0,
                "lng": 31.3333
            },
            "Kushva": {
                "lat": 58.2833,
                "lng": 59.7333
            },
            "Gubkinskiy": {
                "lat": 66.4333,
                "lng": 76.5
            },
            "Sol\u2019-Iletsk": {
                "lat": 51.1667,
                "lng": 55.0
            },
            "Znamensk": {
                "lat": 48.5833,
                "lng": 45.75
            },
            "Karasuk": {
                "lat": 53.7167,
                "lng": 78.05
            },
            "Mayskiy": {
                "lat": 43.65,
                "lng": 44.0667
            },
            "Semiluki": {
                "lat": 51.6833,
                "lng": 39.0333
            },
            "Segezha": {
                "lat": 63.7333,
                "lng": 34.3167
            },
            "Severouralsk": {
                "lat": 60.15,
                "lng": 59.9667
            },
            "Krasnoarmeysk": {
                "lat": 51.0231,
                "lng": 45.7031
            },
            "Novoaleksandrovsk": {
                "lat": 45.5,
                "lng": 41.2333
            },
            "Kamyshlov": {
                "lat": 56.8333,
                "lng": 62.7167
            },
            "Oktyabrsk": {
                "lat": 53.1667,
                "lng": 48.6667
            },
            "Kirovsk": {
                "lat": 67.6167,
                "lng": 33.65
            },
            "Dalnerechensk": {
                "lat": 45.9333,
                "lng": 133.7333
            },
            "Kirzhach": {
                "lat": 56.15,
                "lng": 38.8667
            },
            "Karpinsk": {
                "lat": 59.7667,
                "lng": 59.9833
            },
            "Polysayevo": {
                "lat": 54.6014,
                "lng": 86.2486
            },
            "Labytnangi": {
                "lat": 66.6572,
                "lng": 66.4183
            },
            "Skopin": {
                "lat": 53.8167,
                "lng": 39.55
            },
            "Novopavlovsk": {
                "lat": 43.95,
                "lng": 43.6333
            },
            "Zavodoukovsk": {
                "lat": 56.4833,
                "lng": 66.5333
            },
            "Kimovsk": {
                "lat": 53.9667,
                "lng": 38.5333
            },
            "Kireyevsk": {
                "lat": 53.9333,
                "lng": 37.9333
            },
            "Kurchaloy": {
                "lat": 43.2044,
                "lng": 46.0911
            },
            "Otradnoye": {
                "lat": 59.7833,
                "lng": 30.8167
            },
            "Balabanovo": {
                "lat": 55.1833,
                "lng": 36.6667
            },
            "Inta": {
                "lat": 66.0833,
                "lng": 60.1333
            },
            "Losino-Petrovskiy": {
                "lat": 55.8744,
                "lng": 38.2017
            },
            "Vnukovo": {
                "lat": 55.5997,
                "lng": 37.28
            },
            "Yanaul": {
                "lat": 56.2833,
                "lng": 54.9333
            },
            "Oz\u00ebry": {
                "lat": 54.85,
                "lng": 38.5667
            },
            "Mednogorsk": {
                "lat": 51.4222,
                "lng": 57.5953
            },
            "Dyatkovo": {
                "lat": 53.6,
                "lng": 34.3333
            },
            "Morozovsk": {
                "lat": 48.3667,
                "lng": 41.8333
            },
            "Sovetskaya Gavan\u2019": {
                "lat": 48.9667,
                "lng": 140.2833
            },
            "Atkarsk": {
                "lat": 51.8667,
                "lng": 45.0
            },
            "Kochubeyevskoye": {
                "lat": 44.7,
                "lng": 41.8333
            },
            "Zheleznovodsk": {
                "lat": 44.1394,
                "lng": 43.0169
            },
            "Pavlovsk": {
                "lat": 50.4578,
                "lng": 40.1081
            },
            "Nar\u2019yan-Mar": {
                "lat": 67.6333,
                "lng": 53.05
            },
            "Zernograd": {
                "lat": 46.85,
                "lng": 40.3167
            },
            "Neftekumsk": {
                "lat": 44.7833,
                "lng": 44.7667
            },
            "Stroitel": {
                "lat": 50.7833,
                "lng": 36.4833
            },
            "Asino": {
                "lat": 57.0,
                "lng": 86.1333
            },
            "Buturlinovka": {
                "lat": 50.8333,
                "lng": 40.5833
            },
            "Sem\u00ebnov": {
                "lat": 56.8,
                "lng": 44.5
            },
            "Uvarovo": {
                "lat": 51.9833,
                "lng": 42.2667
            },
            "Sharya": {
                "lat": 58.3667,
                "lng": 45.5
            },
            "Rodniki": {
                "lat": 57.1167,
                "lng": 41.7333
            },
            "Kraskovo": {
                "lat": 55.6586,
                "lng": 37.9869
            },
            "Buy": {
                "lat": 58.4833,
                "lng": 41.5333
            },
            "Kuvandyk": {
                "lat": 51.4667,
                "lng": 57.3667
            },
            "Tatarsk": {
                "lat": 55.25,
                "lng": 75.9833
            },
            "Bogotol": {
                "lat": 56.2,
                "lng": 89.5167
            },
            "Kalach-na-Donu": {
                "lat": 48.7,
                "lng": 43.5167
            },
            "Ipatovo": {
                "lat": 45.7167,
                "lng": 42.0
            },
            "Tayga": {
                "lat": 56.0667,
                "lng": 85.6167
            },
            "Unecha": {
                "lat": 52.8461,
                "lng": 32.6767
            },
            "Davlekanovo": {
                "lat": 54.2167,
                "lng": 55.0333
            },
            "Lensk": {
                "lat": 60.7333,
                "lng": 114.9167
            },
            "Zheleznogorsk-Ilimskiy": {
                "lat": 56.5833,
                "lng": 104.1333
            },
            "Severobaykalsk": {
                "lat": 55.65,
                "lng": 109.3167
            },
            "Sredneuralsk": {
                "lat": 56.9833,
                "lng": 60.4667
            },
            "Achkhoy-Martan": {
                "lat": 43.1892,
                "lng": 45.2847
            },
            "Sasovo": {
                "lat": 54.35,
                "lng": 41.9167
            },
            "Kolpashevo": {
                "lat": 58.3,
                "lng": 82.9
            },
            "Gusinooz\u00ebrsk": {
                "lat": 51.2833,
                "lng": 106.5167
            },
            "Fokino": {
                "lat": 53.2128,
                "lng": 34.4186
            },
            "Krasnouralsk": {
                "lat": 58.35,
                "lng": 60.05
            },
            "Elektrogorsk": {
                "lat": 55.8833,
                "lng": 38.8
            },
            "Isilkul": {
                "lat": 54.95,
                "lng": 71.25
            },
            "Lermontov": {
                "lat": 44.1167,
                "lng": 42.9833
            },
            "Velsk": {
                "lat": 61.0667,
                "lng": 42.1167
            },
            "Kalachinsk": {
                "lat": 55.05,
                "lng": 74.5833
            },
            "Zeya": {
                "lat": 53.7333,
                "lng": 127.25
            },
            "Tashtagol": {
                "lat": 52.7667,
                "lng": 87.8667
            },
            "Bronnitsy": {
                "lat": 55.4261,
                "lng": 38.265
            },
            "Kotelnich": {
                "lat": 58.3078,
                "lng": 48.3181
            },
            "Staraya Kupavna": {
                "lat": 55.8,
                "lng": 38.1667
            },
            "Khadyzhensk": {
                "lat": 44.4256,
                "lng": 39.5319
            },
            "Mendeleyevsk": {
                "lat": 55.9,
                "lng": 52.3333
            },
            "Zvenigorod": {
                "lat": 55.7333,
                "lng": 36.85
            },
            "Nikolskoye": {
                "lat": 59.7,
                "lng": 30.7833
            },
            "Bavly": {
                "lat": 54.3833,
                "lng": 53.2833
            },
            "Guryevsk": {
                "lat": 54.7833,
                "lng": 20.6167
            },
            "Nevyansk": {
                "lat": 57.4833,
                "lng": 60.2
            },
            "Kommunar": {
                "lat": 59.6333,
                "lng": 30.4
            },
            "Semikarakorsk": {
                "lat": 47.5167,
                "lng": 40.8
            },
            "Kotovo": {
                "lat": 50.3,
                "lng": 44.8167
            },
            "Vilyuchinsk": {
                "lat": 52.9306,
                "lng": 158.4028
            },
            "Vereshchagino": {
                "lat": 58.1,
                "lng": 54.6667
            },
            "Svetlyy": {
                "lat": 54.6667,
                "lng": 20.1333
            },
            "Ryazhsk": {
                "lat": 53.7,
                "lng": 40.05
            },
            "Palatka": {
                "lat": 60.1,
                "lng": 150.9
            },
            "Tarko-Sale": {
                "lat": 64.9167,
                "lng": 77.8
            },
            "Ust\u2019-Katav": {
                "lat": 54.9333,
                "lng": 58.1833
            },
            "Omutninsk": {
                "lat": 58.6667,
                "lng": 52.1833
            },
            "Khotkovo": {
                "lat": 56.25,
                "lng": 38.0
            },
            "Chernogolovka": {
                "lat": 56.0,
                "lng": 38.3667
            },
            "Lyskovo": {
                "lat": 56.0289,
                "lng": 45.0361
            },
            "Bologoye": {
                "lat": 57.8833,
                "lng": 34.05
            },
            "Vikhorevka": {
                "lat": 56.1167,
                "lng": 101.1667
            },
            "Karachayevsk": {
                "lat": 43.7731,
                "lng": 41.9169
            },
            "Toguchin": {
                "lat": 55.2333,
                "lng": 84.4167
            },
            "Sysert": {
                "lat": 56.5,
                "lng": 60.8167
            },
            "Pushchino": {
                "lat": 54.8333,
                "lng": 37.6167
            },
            "Nizhniy Lomov": {
                "lat": 53.5167,
                "lng": 43.6667
            },
            "Osa": {
                "lat": 57.3,
                "lng": 55.4667
            },
            "Buinsk": {
                "lat": 54.9667,
                "lng": 48.2833
            },
            "Olenegorsk": {
                "lat": 68.15,
                "lng": 33.2833
            },
            "Kurovskoye": {
                "lat": 55.5833,
                "lng": 38.9167
            },
            "Elektrougli": {
                "lat": 55.7167,
                "lng": 38.2
            },
            "Dugulubgey": {
                "lat": 43.65,
                "lng": 43.55
            },
            "Nerekhta": {
                "lat": 57.45,
                "lng": 40.5833
            },
            "Bobrov": {
                "lat": 51.1,
                "lng": 40.0333
            },
            "Aldan": {
                "lat": 58.6167,
                "lng": 125.4167
            },
            "Nikolsk": {
                "lat": 53.7167,
                "lng": 46.0667
            },
            "Arsk": {
                "lat": 56.1,
                "lng": 49.8833
            },
            "Tyrnyauz": {
                "lat": 43.4,
                "lng": 42.9167
            },
            "Kozmodemyansk": {
                "lat": 56.3367,
                "lng": 46.5711
            },
            "Kaltan": {
                "lat": 53.5167,
                "lng": 87.2833
            },
            "Ostrov": {
                "lat": 57.35,
                "lng": 28.35
            },
            "Roshal": {
                "lat": 55.6667,
                "lng": 39.8833
            },
            "Bezhetsk": {
                "lat": 57.7667,
                "lng": 36.7
            },
            "Okha": {
                "lat": 53.5889,
                "lng": 142.9492
            },
            "Kyakhta": {
                "lat": 50.35,
                "lng": 106.45
            },
            "Sergach": {
                "lat": 55.5333,
                "lng": 45.4667
            },
            "Pikal\u00ebvo": {
                "lat": 59.5333,
                "lng": 34.1333
            },
            "Kotelnikovo": {
                "lat": 47.6333,
                "lng": 43.15
            },
            "Kubinka": {
                "lat": 55.5667,
                "lng": 36.7
            },
            "Shakhunya": {
                "lat": 57.6667,
                "lng": 46.6167
            },
            "Alagir": {
                "lat": 43.0333,
                "lng": 44.2333
            },
            "Tsotsin-Yurt": {
                "lat": 43.238,
                "lng": 46.0
            },
            "Usman": {
                "lat": 52.05,
                "lng": 39.7333
            },
            "Nizhnyaya Tura": {
                "lat": 58.6208,
                "lng": 59.8478
            },
            "Gubakha": {
                "lat": 58.8706,
                "lng": 57.5933
            },
            "Volokolamsk": {
                "lat": 56.0333,
                "lng": 35.95
            },
            "Agryz": {
                "lat": 56.5219,
                "lng": 52.9975
            },
            "Nyandoma": {
                "lat": 61.6667,
                "lng": 40.2167
            },
            "Bachi-Yurt": {
                "lat": 43.2164,
                "lng": 46.1939
            },
            "Rybnoye": {
                "lat": 54.7333,
                "lng": 39.5167
            },
            "Cherepanovo": {
                "lat": 54.2333,
                "lng": 83.3667
            },
            "Yershov": {
                "lat": 51.35,
                "lng": 48.2667
            },
            "Dudinka": {
                "lat": 69.4064,
                "lng": 86.1764
            },
            "Beloyarskiy": {
                "lat": 63.7167,
                "lng": 66.6667
            },
            "Terek": {
                "lat": 43.4833,
                "lng": 44.1333
            },
            "Lodeynoye Pole": {
                "lat": 60.7167,
                "lng": 33.55
            },
            "Ardon": {
                "lat": 43.1833,
                "lng": 44.3167
            },
            "Rayevskiy": {
                "lat": 54.0664,
                "lng": 54.9358
            },
            "Kirovgrad": {
                "lat": 57.435,
                "lng": 60.0561
            },
            "Bakal": {
                "lat": 54.9333,
                "lng": 58.8167
            },
            "Lebedyan": {
                "lat": 53.0167,
                "lng": 39.15
            },
            "Nikolayevsk-na-Amure": {
                "lat": 53.1333,
                "lng": 140.7333
            },
            "Zverevo": {
                "lat": 47.0206,
                "lng": 40.1225
            },
            "Proletarsk": {
                "lat": 46.7,
                "lng": 41.7333
            },
            "Onega": {
                "lat": 63.9167,
                "lng": 38.0833
            },
            "Dankov": {
                "lat": 53.25,
                "lng": 39.1333
            },
            "Kovylkino": {
                "lat": 54.0403,
                "lng": 43.9214
            },
            "Kalach": {
                "lat": 50.4333,
                "lng": 41.0
            },
            "Sortavala": {
                "lat": 61.7056,
                "lng": 30.6958
            },
            "Lgov": {
                "lat": 51.6667,
                "lng": 35.2667
            },
            "Novyy Oskol": {
                "lat": 50.7583,
                "lng": 37.8736
            },
            "Surovikino": {
                "lat": 48.6,
                "lng": 42.85
            },
            "Kavalerovo": {
                "lat": 44.2702,
                "lng": 135.0498
            },
            "Shimanovsk": {
                "lat": 52.0,
                "lng": 127.7
            },
            "Vanino": {
                "lat": 49.0873,
                "lng": 140.2425
            },
            "Prioz\u00ebrsk": {
                "lat": 61.05,
                "lng": 30.1333
            },
            "Goyty": {
                "lat": 43.1642,
                "lng": 45.6222
            },
            "Yur\u2019yev-Pol\u2019skiy": {
                "lat": 56.5,
                "lng": 39.6833
            },
            "Abdulino": {
                "lat": 53.6667,
                "lng": 53.6333
            },
            "Nytva": {
                "lat": 57.95,
                "lng": 55.3333
            },
            "Belooz\u00ebrskiy": {
                "lat": 55.4589,
                "lng": 38.4411
            },
            "Slyudyanka": {
                "lat": 51.6333,
                "lng": 103.75
            },
            "Starodub": {
                "lat": 52.5833,
                "lng": 32.7667
            },
            "Neftegorsk": {
                "lat": 52.8,
                "lng": 51.1667
            },
            "Avtury": {
                "lat": 43.1667,
                "lng": 46.0
            },
            "Kharabali": {
                "lat": 47.405,
                "lng": 47.2556
            },
            "Kukmor": {
                "lat": 56.1833,
                "lng": 50.8833
            },
            "Sobinka": {
                "lat": 56.0,
                "lng": 40.0333
            },
            "Ekazhevo": {
                "lat": 43.2081,
                "lng": 44.8186
            },
            "Golitsyno": {
                "lat": 55.6147,
                "lng": 36.9872
            },
            "Nelidovo": {
                "lat": 56.2167,
                "lng": 32.8
            },
            "Inza": {
                "lat": 53.85,
                "lng": 46.35
            },
            "Polyarnyy": {
                "lat": 69.2,
                "lng": 33.4667
            },
            "Yeniseysk": {
                "lat": 58.4667,
                "lng": 92.1333
            },
            "Plast": {
                "lat": 54.3833,
                "lng": 60.8167
            },
            "Krasnokumskoye": {
                "lat": 44.1778,
                "lng": 43.5
            },
            "Suvorov": {
                "lat": 54.15,
                "lng": 36.55
            },
            "Karachev": {
                "lat": 53.1167,
                "lng": 34.9667
            },
            "Nizhnyaya Salda": {
                "lat": 58.0667,
                "lng": 60.7167
            },
            "Raychikhinsk": {
                "lat": 49.7833,
                "lng": 129.4167
            },
            "Shumikha": {
                "lat": 55.2333,
                "lng": 63.3
            },
            "Krasnoslobodsk": {
                "lat": 54.4333,
                "lng": 43.8
            },
            "Baymak": {
                "lat": 52.5833,
                "lng": 58.3167
            },
            "Kinel\u2019-Cherkassy": {
                "lat": 53.4683,
                "lng": 51.4775
            },
            "Konstantinovsk": {
                "lat": 47.5667,
                "lng": 41.0833
            },
            "Kusa": {
                "lat": 55.35,
                "lng": 59.45
            },
            "Turinsk": {
                "lat": 58.0333,
                "lng": 63.7
            },
            "Gavrilov-Yam": {
                "lat": 57.3167,
                "lng": 39.85
            },
            "Menzelinsk": {
                "lat": 55.7333,
                "lng": 53.1167
            },
            "Pokrov": {
                "lat": 55.9117,
                "lng": 39.1847
            },
            "Podporozhye": {
                "lat": 60.9,
                "lng": 34.2
            },
            "Galich": {
                "lat": 58.3833,
                "lng": 42.35
            },
            "Zhukovka": {
                "lat": 53.5333,
                "lng": 33.7333
            },
            "Belaya Glina": {
                "lat": 46.0833,
                "lng": 40.8583
            },
            "Iglino": {
                "lat": 54.8383,
                "lng": 56.4231
            },
            "Novomichurinsk": {
                "lat": 54.05,
                "lng": 39.7167
            },
            "Povorino": {
                "lat": 51.2,
                "lng": 42.25
            },
            "Poch\u00ebp": {
                "lat": 52.9333,
                "lng": 33.45
            },
            "Seltso": {
                "lat": 53.3678,
                "lng": 34.0978
            },
            "Uchkeken": {
                "lat": 43.9333,
                "lng": 42.5167
            },
            "Kozelsk": {
                "lat": 54.0353,
                "lng": 35.7767
            },
            "Kirsanov": {
                "lat": 52.65,
                "lng": 42.7333
            },
            "Kurtamysh": {
                "lat": 54.9167,
                "lng": 64.4333
            },
            "Novoanninskiy": {
                "lat": 50.5333,
                "lng": 42.6833
            },
            "Petrovsk-Zabaykal\u2019skiy": {
                "lat": 51.2667,
                "lng": 108.8333
            },
            "Babayurt": {
                "lat": 43.5997,
                "lng": 46.7736
            },
            "Kodinsk": {
                "lat": 58.6833,
                "lng": 99.1833
            },
            "Kasli": {
                "lat": 55.9,
                "lng": 60.7667
            },
            "Rylsk": {
                "lat": 51.5667,
                "lng": 34.6667
            },
            "Degtyarsk": {
                "lat": 56.7,
                "lng": 60.1167
            },
            "Kamyzyak": {
                "lat": 46.1167,
                "lng": 48.0833
            },
            "Barysh": {
                "lat": 53.65,
                "lng": 47.1
            },
            "Talitsa": {
                "lat": 57.0167,
                "lng": 63.7167
            },
            "Bikin": {
                "lat": 46.8167,
                "lng": 134.2667
            },
            "Plavsk": {
                "lat": 53.7167,
                "lng": 37.2833
            },
            "Ivdel": {
                "lat": 60.6833,
                "lng": 60.4167
            },
            "Katav-Ivanovsk": {
                "lat": 54.75,
                "lng": 58.2167
            },
            "Borodino": {
                "lat": 55.9056,
                "lng": 94.8997
            },
            "Kalininsk": {
                "lat": 51.5,
                "lng": 44.4667
            },
            "Mamadysh": {
                "lat": 55.7131,
                "lng": 51.4106
            },
            "Kovdor": {
                "lat": 67.5594,
                "lng": 30.4667
            },
            "Zhirnovsk": {
                "lat": 50.9833,
                "lng": 44.7833
            },
            "Novouzensk": {
                "lat": 50.4667,
                "lng": 48.1333
            },
            "Bolotnoye": {
                "lat": 55.6833,
                "lng": 84.4167
            },
            "Anadyr": {
                "lat": 64.7333,
                "lng": 177.5167
            },
            "Svetogorsk": {
                "lat": 61.1167,
                "lng": 28.85
            },
            "Zelenogradsk": {
                "lat": 54.9667,
                "lng": 20.4833
            },
            "Ostashkov": {
                "lat": 57.15,
                "lng": 33.1
            },
            "Krasnovishersk": {
                "lat": 60.4167,
                "lng": 57.1
            },
            "Abaza": {
                "lat": 52.6481,
                "lng": 90.0739
            },
            "Chaltyr": {
                "lat": 47.2848,
                "lng": 39.4823
            },
            "Poronaysk": {
                "lat": 49.2167,
                "lng": 143.1167
            },
            "Shchigry": {
                "lat": 51.8667,
                "lng": 36.9333
            },
            "Yaransk": {
                "lat": 57.3167,
                "lng": 47.9
            },
            "Yasnogorsk": {
                "lat": 54.5,
                "lng": 37.6667
            },
            "Privolzhsk": {
                "lat": 57.3825,
                "lng": 41.2861
            },
            "Yasnyy": {
                "lat": 51.05,
                "lng": 59.9667
            },
            "Boksitogorsk": {
                "lat": 59.4833,
                "lng": 33.8333
            },
            "Aramil": {
                "lat": 56.7,
                "lng": 60.8333
            },
            "Kulunda": {
                "lat": 52.5661,
                "lng": 78.9369
            },
            "Leninsk": {
                "lat": 48.7,
                "lng": 45.2167
            },
            "Pestovo": {
                "lat": 58.6,
                "lng": 35.8167
            },
            "Nerchinsk": {
                "lat": 51.9944,
                "lng": 116.5556
            },
            "Kizel": {
                "lat": 59.05,
                "lng": 57.6667
            },
            "Udelnaya": {
                "lat": 55.6355,
                "lng": 38.0479
            },
            "Kondrovo": {
                "lat": 54.8,
                "lng": 35.9333
            },
            "Chegdomyn": {
                "lat": 51.1178,
                "lng": 133.0241
            },
            "Navashino": {
                "lat": 55.55,
                "lng": 42.2
            },
            "Nevel": {
                "lat": 56.0333,
                "lng": 29.9167
            },
            "Gryazovets": {
                "lat": 58.8833,
                "lng": 40.25
            },
            "Shlisselburg": {
                "lat": 59.9536,
                "lng": 31.0383
            },
            "Krasnogvardeyskoye": {
                "lat": 45.1428,
                "lng": 39.5919
            },
            "Danilov": {
                "lat": 58.1833,
                "lng": 40.1667
            },
            "Sukhinichi": {
                "lat": 54.1,
                "lng": 35.35
            },
            "Belokurikha": {
                "lat": 51.9833,
                "lng": 84.9833
            },
            "Pallasovka": {
                "lat": 50.05,
                "lng": 46.8833
            },
            "Arzgir": {
                "lat": 45.3694,
                "lng": 44.2281
            },
            "Katyr-Yurt": {
                "lat": 43.1686,
                "lng": 45.3681
            },
            "Tsivilsk": {
                "lat": 55.8667,
                "lng": 47.4833
            },
            "Agidel": {
                "lat": 55.9,
                "lng": 53.9333
            },
            "Ust\u2019-Ordynskiy": {
                "lat": 52.8056,
                "lng": 104.7622
            },
            "Tsimlyansk": {
                "lat": 47.6478,
                "lng": 42.0961
            },
            "Krasnyy Kut": {
                "lat": 50.95,
                "lng": 46.9667
            },
            "Polyarnyye Zori": {
                "lat": 67.3667,
                "lng": 32.5
            },
            "Valday": {
                "lat": 57.9667,
                "lng": 33.25
            },
            "Volgorechensk": {
                "lat": 57.4439,
                "lng": 41.1592
            },
            "Medvezhyegorsk": {
                "lat": 62.9,
                "lng": 34.4667
            },
            "Zapolyarnyy": {
                "lat": 69.4167,
                "lng": 30.8
            },
            "Lakinsk": {
                "lat": 56.0333,
                "lng": 39.9667
            },
            "Yakhroma": {
                "lat": 56.2833,
                "lng": 37.4667
            },
            "Och\u00ebr": {
                "lat": 57.8833,
                "lng": 54.7333
            },
            "Ven\u00ebv": {
                "lat": 54.35,
                "lng": 38.2667
            },
            "Uzhur": {
                "lat": 55.3175,
                "lng": 89.8225
            },
            "Geldagana": {
                "lat": 43.2153,
                "lng": 46.0403
            },
            "Novoulyanovsk": {
                "lat": 54.1667,
                "lng": 48.3833
            },
            "Dubovka": {
                "lat": 49.05,
                "lng": 44.8333
            },
            "Chudovo": {
                "lat": 59.1167,
                "lng": 31.6667
            },
            "Gekhi": {
                "lat": 43.1636,
                "lng": 45.4725
            },
            "Mayrtup": {
                "lat": 43.2,
                "lng": 46.1333
            },
            "Kupino": {
                "lat": 54.3667,
                "lng": 77.3
            },
            "Kashin": {
                "lat": 57.35,
                "lng": 37.6167
            },
            "Kuleshovka": {
                "lat": 47.0833,
                "lng": 39.5667
            },
            "Svetlogorsk": {
                "lat": 54.95,
                "lng": 20.15
            },
            "Nikolayevsk": {
                "lat": 50.0333,
                "lng": 45.45
            },
            "Trubchevsk": {
                "lat": 52.5667,
                "lng": 33.7667
            },
            "Ak-Dovurak": {
                "lat": 51.1833,
                "lng": 90.6
            },
            "Zhukovo": {
                "lat": 55.0333,
                "lng": 36.75
            },
            "Kudrovo": {
                "lat": 59.9,
                "lng": 30.5167
            },
            "Golyshmanovo": {
                "lat": 56.3819,
                "lng": 68.3715
            },
            "Melenki": {
                "lat": 55.3333,
                "lng": 41.65
            },
            "Alleroy": {
                "lat": 43.087,
                "lng": 46.302
            },
            "Akhty": {
                "lat": 41.4647,
                "lng": 47.74
            },
            "Ruza": {
                "lat": 55.7,
                "lng": 36.2
            },
            "Oboyan": {
                "lat": 51.2,
                "lng": 36.2833
            },
            "Kasumkent": {
                "lat": 41.6667,
                "lng": 48.1333
            },
            "Gvardeysk": {
                "lat": 54.6667,
                "lng": 21.0833
            },
            "Pervomaysk": {
                "lat": 54.8667,
                "lng": 43.8
            },
            "Bel\u00ebv": {
                "lat": 53.8125,
                "lng": 36.1333
            },
            "Uyar": {
                "lat": 55.8267,
                "lng": 94.3153
            },
            "Zabaykalsk": {
                "lat": 49.6514,
                "lng": 117.3269
            },
            "Karabanovo": {
                "lat": 56.3167,
                "lng": 38.7
            },
            "Kaspiyskiy": {
                "lat": 45.3908,
                "lng": 47.3658
            },
            "Krasnozavodsk": {
                "lat": 56.4333,
                "lng": 38.2333
            },
            "Chernigovka": {
                "lat": 44.3406,
                "lng": 132.5689
            },
            "Manturovo": {
                "lat": 58.3333,
                "lng": 44.7667
            },
            "Divnoye": {
                "lat": 45.9072,
                "lng": 43.3558
            },
            "Mogocha": {
                "lat": 53.7333,
                "lng": 119.7667
            },
            "Svirsk": {
                "lat": 53.0833,
                "lng": 103.3333
            },
            "Sim": {
                "lat": 54.9833,
                "lng": 57.7
            },
            "Vyazemskiy": {
                "lat": 47.525,
                "lng": 134.7572
            },
            "Syasstroy": {
                "lat": 60.15,
                "lng": 32.5833
            },
            "Nartan": {
                "lat": 43.5167,
                "lng": 43.7
            },
            "Gorokhovets": {
                "lat": 56.2,
                "lng": 42.7
            },
            "Taldom": {
                "lat": 56.7333,
                "lng": 37.5333
            },
            "Adygeysk": {
                "lat": 44.8832,
                "lng": 39.1902
            },
            "Samashki": {
                "lat": 43.2906,
                "lng": 45.3014
            },
            "Yemva": {
                "lat": 62.6,
                "lng": 50.8667
            },
            "Surkhakhi": {
                "lat": 43.1878,
                "lng": 44.9036
            },
            "Staryye Atagi": {
                "lat": 43.1167,
                "lng": 45.7333
            },
            "Shilka": {
                "lat": 51.85,
                "lng": 116.0333
            },
            "Lukoyanov": {
                "lat": 55.0333,
                "lng": 44.4833
            },
            "Baykalsk": {
                "lat": 51.5167,
                "lng": 104.1333
            },
            "Sagopshi": {
                "lat": 43.4847,
                "lng": 44.5881
            },
            "Yuzha": {
                "lat": 56.5833,
                "lng": 42.0333
            },
            "Petrov Val": {
                "lat": 50.15,
                "lng": 45.2167
            },
            "Petushki": {
                "lat": 55.9333,
                "lng": 39.4667
            },
            "Elkhotovo": {
                "lat": 43.3458,
                "lng": 44.2028
            },
            "Kameshkovo": {
                "lat": 56.35,
                "lng": 41.0
            },
            "Uren": {
                "lat": 57.45,
                "lng": 45.7833
            },
            "Khvalynsk": {
                "lat": 52.4833,
                "lng": 48.1
            },
            "Kataysk": {
                "lat": 56.3,
                "lng": 62.6
            },
            "Alkhan-Kala": {
                "lat": 43.2586,
                "lng": 45.5392
            },
            "Bodaybo": {
                "lat": 57.8667,
                "lng": 114.2
            },
            "Mesker-Yurt": {
                "lat": 43.2514,
                "lng": 45.9072
            },
            "Sernovodsk": {
                "lat": 43.3175,
                "lng": 45.1411
            },
            "Krasnyy Yar": {
                "lat": 46.5344,
                "lng": 48.3431
            },
            "Vylgort": {
                "lat": 61.6275,
                "lng": 50.7672
            },
            "Botlikh": {
                "lat": 42.6672,
                "lng": 46.215
            },
            "Cherlak": {
                "lat": 54.1605,
                "lng": 74.82
            },
            "Gornyak": {
                "lat": 50.9833,
                "lng": 81.4667
            },
            "Toropets": {
                "lat": 56.5,
                "lng": 31.65
            },
            "Islamey": {
                "lat": 43.6756,
                "lng": 43.455
            },
            "Krasnousol\u2019skiy": {
                "lat": 53.8947,
                "lng": 56.4686
            },
            "Volosovo": {
                "lat": 59.4333,
                "lng": 29.4833
            },
            "Dalmatovo": {
                "lat": 56.2667,
                "lng": 62.9333
            },
            "Poselikha": {
                "lat": 51.988,
                "lng": 81.827
            },
            "Arkadak": {
                "lat": 51.9333,
                "lng": 43.5
            },
            "Novaya Lyalya": {
                "lat": 59.0667,
                "lng": 60.6
            },
            "Levashi": {
                "lat": 42.4314,
                "lng": 47.3236
            },
            "Pokrovskoye": {
                "lat": 47.4164,
                "lng": 38.8989
            },
            "Chaplygin": {
                "lat": 53.2333,
                "lng": 39.9667
            },
            "Strunino": {
                "lat": 56.3667,
                "lng": 38.5833
            },
            "Magdagachi": {
                "lat": 53.45,
                "lng": 125.8
            },
            "Zayukovo": {
                "lat": 43.6119,
                "lng": 43.3269
            },
            "Dolinsk": {
                "lat": 47.3167,
                "lng": 142.8
            },
            "Udachnyy": {
                "lat": 66.4,
                "lng": 112.3167
            },
            "Kem": {
                "lat": 64.95,
                "lng": 34.5833
            },
            "Likhoslavl": {
                "lat": 57.1333,
                "lng": 35.4833
            },
            "Chegem Vtoroy": {
                "lat": 43.5667,
                "lng": 43.5833
            },
            "Severnyy": {
                "lat": 67.6083,
                "lng": 64.1233
            },
            "Nyazepetrovsk": {
                "lat": 56.05,
                "lng": 59.6
            },
            "Cherdakly": {
                "lat": 54.3591,
                "lng": 48.8487
            },
            "Aginskoye": {
                "lat": 51.1031,
                "lng": 114.5228
            },
            "Nikel": {
                "lat": 69.4139,
                "lng": 30.2319
            },
            "Sorsk": {
                "lat": 54.0333,
                "lng": 90.25
            },
            "Yuryuzan": {
                "lat": 54.8667,
                "lng": 58.4333
            },
            "Babayevo": {
                "lat": 59.3833,
                "lng": 35.9167
            },
            "Chekmagush": {
                "lat": 55.1333,
                "lng": 54.6667
            },
            "Gornozavodsk": {
                "lat": 58.3667,
                "lng": 58.3333
            },
            "Chkalovsk": {
                "lat": 56.7667,
                "lng": 43.2333
            },
            "Drezna": {
                "lat": 55.7453,
                "lng": 38.8486
            },
            "Pionerskiy": {
                "lat": 54.9517,
                "lng": 20.2333
            },
            "Miasskoye": {
                "lat": 55.2825,
                "lng": 61.8939
            },
            "Sosenskiy": {
                "lat": 54.05,
                "lng": 35.9667
            },
            "Tetyushi": {
                "lat": 54.9333,
                "lng": 48.8333
            },
            "Boguchany": {
                "lat": 58.3667,
                "lng": 97.45
            },
            "Korablino": {
                "lat": 53.9167,
                "lng": 40.0167
            },
            "Chulym": {
                "lat": 55.1167,
                "lng": 80.9667
            },
            "Kursavka": {
                "lat": 44.45,
                "lng": 42.5
            },
            "Zvenigovo": {
                "lat": 55.9833,
                "lng": 48.0167
            },
            "Zakamensk": {
                "lat": 50.3833,
                "lng": 103.3
            },
            "Baley": {
                "lat": 51.6,
                "lng": 116.6333
            },
            "Kirensk": {
                "lat": 57.7833,
                "lng": 108.0833
            },
            "Chuguyevka": {
                "lat": 44.1667,
                "lng": 133.8583
            },
            "Nazyvayevsk": {
                "lat": 55.5667,
                "lng": 71.2667
            },
            "Vilyuysk": {
                "lat": 63.75,
                "lng": 121.6333
            },
            "Narimanov": {
                "lat": 46.6833,
                "lng": 47.85
            },
            "Boguchar": {
                "lat": 49.95,
                "lng": 40.55
            },
            "Sosnovka": {
                "lat": 56.25,
                "lng": 51.3
            },
            "Kalyazin": {
                "lat": 57.2333,
                "lng": 37.8833
            },
            "Shalushka": {
                "lat": 43.5261,
                "lng": 43.5594
            },
            "Karabash": {
                "lat": 55.4833,
                "lng": 60.2167
            },
            "Shagonar": {
                "lat": 51.55,
                "lng": 92.7667
            },
            "Belousovo": {
                "lat": 55.0833,
                "lng": 36.6667
            },
            "Kamen\u2019-Rybolov": {
                "lat": 44.7667,
                "lng": 132.0167
            },
            "Zasechnoye": {
                "lat": 53.1142,
                "lng": 45.0601
            },
            "Kandry": {
                "lat": 54.55,
                "lng": 54.1
            },
            "Mesyagutovo": {
                "lat": 55.5167,
                "lng": 58.2667
            },
            "Neman": {
                "lat": 55.0333,
                "lng": 22.0333
            },
            "Khorol": {
                "lat": 44.4289,
                "lng": 132.0742
            },
            "Khasanya": {
                "lat": 43.4333,
                "lng": 43.575
            },
            "Aleksandrovsk": {
                "lat": 59.1667,
                "lng": 57.6
            },
            "Borovsk": {
                "lat": 55.2,
                "lng": 36.5
            },
            "Surazh": {
                "lat": 53.0167,
                "lng": 32.3833
            },
            "Praskoveya": {
                "lat": 44.7444,
                "lng": 44.2031
            },
            "Zavitinsk": {
                "lat": 50.1281,
                "lng": 129.4433
            },
            "Nadezhda": {
                "lat": 45.0448,
                "lng": 42.1104
            },
            "Yuzhno-Sukhokumsk": {
                "lat": 44.6667,
                "lng": 45.65
            },
            "Khilok": {
                "lat": 51.35,
                "lng": 110.45
            },
            "Malaya Vishera": {
                "lat": 58.85,
                "lng": 32.2333
            },
            "Peschanokopskoye": {
                "lat": 46.1958,
                "lng": 41.0778
            },
            "Kremenki": {
                "lat": 54.8833,
                "lng": 37.1167
            },
            "Vysokovsk": {
                "lat": 56.3167,
                "lng": 36.55
            },
            "Zmeinogorsk": {
                "lat": 51.1667,
                "lng": 82.2
            },
            "Leninaul": {
                "lat": 43.0833,
                "lng": 46.5667
            },
            "Ol\u00ebkminsk": {
                "lat": 60.3667,
                "lng": 120.4167
            },
            "Pitkyaranta": {
                "lat": 61.575,
                "lng": 31.4778
            },
            "Sylva": {
                "lat": 58.0333,
                "lng": 56.7681
            },
            "Zuyevka": {
                "lat": 58.4,
                "lng": 51.15
            },
            "Sudogda": {
                "lat": 55.95,
                "lng": 40.8667
            },
            "Nevelsk": {
                "lat": 46.6833,
                "lng": 141.8667
            },
            "Belaya Kholunitsa": {
                "lat": 58.85,
                "lng": 50.8667
            },
            "Taksimo": {
                "lat": 56.3315,
                "lng": 114.89
            },
            "Magas": {
                "lat": 43.1667,
                "lng": 44.8
            },
            "Vytegra": {
                "lat": 61.0,
                "lng": 36.45
            },
            "Buzdyak": {
                "lat": 54.5667,
                "lng": 54.5308
            },
            "Tyukalinsk": {
                "lat": 55.8667,
                "lng": 72.2
            },
            "Argayash": {
                "lat": 55.4889,
                "lng": 60.8758
            },
            "Zaoz\u00ebrnyy": {
                "lat": 55.9667,
                "lng": 94.7
            },
            "Kambarka": {
                "lat": 56.2667,
                "lng": 54.2167
            },
            "Ertil": {
                "lat": 51.85,
                "lng": 40.8
            },
            "Mikhaylov": {
                "lat": 54.2333,
                "lng": 39.0333
            },
            "Vorsma": {
                "lat": 55.9833,
                "lng": 43.25
            },
            "Opochka": {
                "lat": 56.7,
                "lng": 28.6667
            },
            "Zavolzhsk": {
                "lat": 57.4667,
                "lng": 42.15
            },
            "Yermolino": {
                "lat": 55.2,
                "lng": 36.6
            },
            "Tolbazy": {
                "lat": 54.0139,
                "lng": 55.8922
            },
            "Verkhniy Tagil": {
                "lat": 57.3833,
                "lng": 59.95
            },
            "Nogliki": {
                "lat": 51.8333,
                "lng": 143.1667
            },
            "Zhatay": {
                "lat": 62.15,
                "lng": 129.8167
            },
            "Kosh-Agach": {
                "lat": 49.995,
                "lng": 88.6672
            },
            "Krivodanovka": {
                "lat": 55.0881,
                "lng": 82.6551
            },
            "Ikryanoye": {
                "lat": 46.1,
                "lng": 47.7333
            },
            "Vuktyl": {
                "lat": 63.7,
                "lng": 57.3167
            },
            "Dylym": {
                "lat": 43.0694,
                "lng": 46.6367
            },
            "Digora": {
                "lat": 43.1581,
                "lng": 44.1569
            },
            "Kargopol": {
                "lat": 61.5,
                "lng": 38.9333
            },
            "Snezhnogorsk": {
                "lat": 69.2,
                "lng": 33.2333
            },
            "Urzhum": {
                "lat": 57.1167,
                "lng": 50.0
            },
            "Kushnar\u00ebnkovo": {
                "lat": 55.105,
                "lng": 55.3478
            },
            "Varna": {
                "lat": 53.3806,
                "lng": 60.9803
            },
            "Belomorsk": {
                "lat": 64.5253,
                "lng": 34.7658
            },
            "Suntar": {
                "lat": 62.1667,
                "lng": 117.6333
            },
            "Serafimovskiy": {
                "lat": 54.4167,
                "lng": 53.7833
            },
            "Totma": {
                "lat": 59.9667,
                "lng": 42.75
            },
            "Mikun": {
                "lat": 62.3667,
                "lng": 50.0833
            },
            "Dorogobuzh": {
                "lat": 54.9167,
                "lng": 33.3
            },
            "Nyurba": {
                "lat": 63.2833,
                "lng": 118.3333
            },
            "Alkhan-Yurt": {
                "lat": 43.2317,
                "lng": 45.5722
            },
            "Suzdal": {
                "lat": 56.4167,
                "lng": 40.45
            },
            "Lakha N\u00ebvre": {
                "lat": 43.6228,
                "lng": 45.3397
            },
            "Novyye Atagi": {
                "lat": 43.1333,
                "lng": 45.7667
            },
            "Aleksandrov Gay": {
                "lat": 50.1333,
                "lng": 48.55
            },
            "Nolinsk": {
                "lat": 57.5667,
                "lng": 49.9333
            },
            "Shchuchye": {
                "lat": 55.2167,
                "lng": 62.75
            },
            "Volodarsk": {
                "lat": 56.2167,
                "lng": 43.1833
            },
            "Kola": {
                "lat": 68.8833,
                "lng": 33.0833
            },
            "Pechory": {
                "lat": 57.8167,
                "lng": 27.6167
            },
            "Kenzhe": {
                "lat": 43.4911,
                "lng": 43.5528
            },
            "Luza": {
                "lat": 60.65,
                "lng": 47.2833
            },
            "Zadonsk": {
                "lat": 52.4,
                "lng": 38.9333
            },
            "Levokumskoye": {
                "lat": 44.8228,
                "lng": 44.6592
            },
            "Valerik": {
                "lat": 43.1764,
                "lng": 45.4042
            },
            "Bakaly": {
                "lat": 55.1778,
                "lng": 53.8003
            },
            "Dombarovskiy": {
                "lat": 50.755,
                "lng": 59.5331
            },
            "Igrim": {
                "lat": 63.1933,
                "lng": 64.4194
            },
            "Ashukino": {
                "lat": 56.1631,
                "lng": 37.9439
            },
            "Burayevo": {
                "lat": 55.8397,
                "lng": 55.4044
            },
            "Soldato-Aleksandrovskoye": {
                "lat": 44.2659,
                "lng": 43.7562
            },
            "Aleksandrovsk-Sakhalinskiy": {
                "lat": 50.9,
                "lng": 142.15
            },
            "Ivangorod": {
                "lat": 59.3667,
                "lng": 28.2167
            },
            "Rudnya": {
                "lat": 54.95,
                "lng": 31.1
            },
            "Sultan-Yangiyurt": {
                "lat": 43.2167,
                "lng": 46.8667
            },
            "Shira": {
                "lat": 54.4939,
                "lng": 89.9628
            },
            "Yutsa": {
                "lat": 43.9625,
                "lng": 42.9875
            },
            "Aniva": {
                "lat": 46.7167,
                "lng": 142.5167
            },
            "Verkhneyarkeyevo": {
                "lat": 55.4458,
                "lng": 54.3167
            },
            "Kargat": {
                "lat": 55.1956,
                "lng": 80.2811
            },
            "Verkhneuralsk": {
                "lat": 53.8833,
                "lng": 59.25
            },
            "Chechen-Aul": {
                "lat": 43.2,
                "lng": 45.7889
            },
            "Gadzhiyevo": {
                "lat": 69.25,
                "lng": 33.4167
            },
            "Pokrovsk": {
                "lat": 61.4833,
                "lng": 129.15
            },
            "Zavyalovo": {
                "lat": 56.7922,
                "lng": 53.3756
            },
            "Nizhniye Sergi": {
                "lat": 56.6667,
                "lng": 59.3167
            },
            "Lesnoy Gorodok": {
                "lat": 55.6405,
                "lng": 37.2075
            },
            "Mikhaylovskoye": {
                "lat": 43.0997,
                "lng": 44.6317
            },
            "Aniskino": {
                "lat": 55.9417,
                "lng": 38.15
            },
            "Podstepki": {
                "lat": 53.5151,
                "lng": 49.1294
            },
            "Ust\u2019-Nera": {
                "lat": 64.5666,
                "lng": 143.2
            },
            "Bichura": {
                "lat": 50.5897,
                "lng": 107.5961
            },
            "Chadan": {
                "lat": 51.2833,
                "lng": 91.5833
            },
            "Nizhniy Odes": {
                "lat": 63.65,
                "lng": 54.85
            },
            "Tarusa": {
                "lat": 54.7333,
                "lng": 37.1833
            },
            "Kharovsk": {
                "lat": 59.95,
                "lng": 40.2
            },
            "Navoloki": {
                "lat": 57.4667,
                "lng": 41.9667
            },
            "Vinsady": {
                "lat": 44.0817,
                "lng": 42.9606
            },
            "Sergiyevsk": {
                "lat": 53.945,
                "lng": 51.1711
            },
            "Bolokhovo": {
                "lat": 54.0833,
                "lng": 37.8333
            },
            "Verkhnyaya Tura": {
                "lat": 58.3594,
                "lng": 59.8175
            },
            "Argudan": {
                "lat": 43.42,
                "lng": 43.92
            },
            "Yelnya": {
                "lat": 54.5833,
                "lng": 33.1833
            },
            "Kirs": {
                "lat": 59.35,
                "lng": 52.25
            },
            "Dzhalka": {
                "lat": 43.3189,
                "lng": 45.9936
            },
            "Borskoye": {
                "lat": 53.0261,
                "lng": 51.6983
            },
            "Suoyarvi": {
                "lat": 62.0833,
                "lng": 32.35
            },
            "Serafimovich": {
                "lat": 49.5833,
                "lng": 42.7333
            },
            "Topchikha": {
                "lat": 52.8167,
                "lng": 83.1167
            },
            "Kratovo": {
                "lat": 55.6,
                "lng": 38.15
            },
            "Pudozh": {
                "lat": 61.8,
                "lng": 36.5167
            },
            "Neya": {
                "lat": 58.3,
                "lng": 43.8667
            },
            "Kuvshinovo": {
                "lat": 57.0333,
                "lng": 34.1833
            },
            "Psyzh": {
                "lat": 44.2333,
                "lng": 42.0167
            },
            "Povarovo": {
                "lat": 56.072,
                "lng": 37.0706
            },
            "Soltsy": {
                "lat": 58.1333,
                "lng": 30.3167
            },
            "Beloz\u00ebrsk": {
                "lat": 60.0333,
                "lng": 37.7667
            },
            "Gorodoviki": {
                "lat": 46.1353,
                "lng": 41.9656
            },
            "Minyar": {
                "lat": 55.0667,
                "lng": 57.55
            },
            "Mariinskiy Posad": {
                "lat": 56.1,
                "lng": 47.7167
            },
            "Porkhov": {
                "lat": 57.7833,
                "lng": 29.5667
            },
            "Gremyachinsk": {
                "lat": 58.5667,
                "lng": 57.85
            },
            "Volchansk": {
                "lat": 59.9333,
                "lng": 60.0833
            },
            "Perevoz": {
                "lat": 55.6,
                "lng": 44.5333
            },
            "Mrakovo": {
                "lat": 52.7139,
                "lng": 56.6269
            },
            "Uglegorsk": {
                "lat": 49.0667,
                "lng": 142.0333
            },
            "Gubden": {
                "lat": 42.55,
                "lng": 47.55
            },
            "Ustyuzhna": {
                "lat": 58.8333,
                "lng": 36.4333
            },
            "Verkhoturye": {
                "lat": 58.8667,
                "lng": 60.8
            },
            "Laishevo": {
                "lat": 55.4,
                "lng": 49.5667
            },
            "Kozlovka": {
                "lat": 55.85,
                "lng": 48.25
            },
            "Verkhniye Achaluki": {
                "lat": 43.3469,
                "lng": 44.6975
            },
            "Ardatov": {
                "lat": 54.85,
                "lng": 46.2333
            },
            "Verkhniy Mamon": {
                "lat": 50.1644,
                "lng": 40.3856
            },
            "Pochinok": {
                "lat": 54.4,
                "lng": 32.45
            },
            "Novoselitskoye": {
                "lat": 44.7494,
                "lng": 43.4386
            },
            "Dubovskoye": {
                "lat": 47.4092,
                "lng": 42.7575
            },
            "Lipki": {
                "lat": 53.9333,
                "lng": 37.7
            },
            "Karmaskaly": {
                "lat": 54.3694,
                "lng": 56.1778
            },
            "Chermen": {
                "lat": 43.1486,
                "lng": 44.7069
            },
            "Biryusinsk": {
                "lat": 55.9667,
                "lng": 97.8333
            },
            "Olovyannaya": {
                "lat": 50.95,
                "lng": 115.5666
            },
            "Obluchye": {
                "lat": 49.0167,
                "lng": 131.05
            },
            "Skovorodino": {
                "lat": 53.9833,
                "lng": 123.9333
            },
            "Khandyga": {
                "lat": 62.666,
                "lng": 135.6
            },
            "Makarov": {
                "lat": 48.6333,
                "lng": 142.8
            },
            "Verkhnevilyuysk": {
                "lat": 63.45,
                "lng": 120.3
            },
            "Umba": {
                "lat": 66.6814,
                "lng": 34.3455
            },
            "Tazovskiy": {
                "lat": 67.4833,
                "lng": 78.7167
            },
            "Mundybash": {
                "lat": 53.2333,
                "lng": 87.3167
            },
            "Tiksi": {
                "lat": 71.6269,
                "lng": 128.835
            },
            "Okhotsk": {
                "lat": 59.383,
                "lng": 143.217
            },
            "Tura": {
                "lat": 64.2833,
                "lng": 100.2167
            },
            "Bilibino": {
                "lat": 68.05,
                "lng": 166.45
            },
            "Chernyshevskiy": {
                "lat": 63.0128,
                "lng": 112.4714
            },
            "Igarka": {
                "lat": 67.4667,
                "lng": 86.5833
            },
            "Ust\u2019-Kamchatsk": {
                "lat": 56.2167,
                "lng": 162.55
            },
            "Turukhansk": {
                "lat": 65.797,
                "lng": 87.968
            },
            "Bagdarin": {
                "lat": 54.4444,
                "lng": 113.5861
            },
            "Sangar": {
                "lat": 63.9241,
                "lng": 127.4739
            },
            "Khatanga": {
                "lat": 71.9797,
                "lng": 102.4728
            },
            "Susuman": {
                "lat": 62.7833,
                "lng": 148.1667
            },
            "Batagay": {
                "lat": 67.656,
                "lng": 134.635
            },
            "Omsukchan": {
                "lat": 62.5333,
                "lng": 155.8
            },
            "Novyy Uoyan": {
                "lat": 56.135,
                "lng": 111.7339
            },
            "Pevek": {
                "lat": 69.7,
                "lng": 170.2833
            },
            "Vitim": {
                "lat": 59.4515,
                "lng": 112.5578
            },
            "Cherskiy": {
                "lat": 68.7501,
                "lng": 161.33
            },
            "Palana": {
                "lat": 59.084,
                "lng": 159.95
            },
            "Zyryanka": {
                "lat": 65.736,
                "lng": 150.89
            },
            "De-Kastri": {
                "lat": 51.4666,
                "lng": 140.7833
            },
            "Srednekolymsk": {
                "lat": 67.4667,
                "lng": 153.7167
            },
            "Zhigansk": {
                "lat": 66.7667,
                "lng": 123.3833
            },
            "Bukachacha": {
                "lat": 52.9833,
                "lng": 116.9166
            },
            "Ugol\u2019nyye Kopi": {
                "lat": 64.7333,
                "lng": 177.7
            },
            "Mezen": {
                "lat": 65.8333,
                "lng": 44.2667
            },
            "Teeli": {
                "lat": 51.0086,
                "lng": 90.2092
            },
            "Ust\u2019-Maya": {
                "lat": 60.4566,
                "lng": 134.5433
            },
            "Saranpaul": {
                "lat": 64.26,
                "lng": 60.9083
            },
            "Provideniya": {
                "lat": 64.4235,
                "lng": -173.2258
            },
            "Chokurdakh": {
                "lat": 70.6183,
                "lng": 147.8946
            },
            "Severo-Kuril\u2019sk": {
                "lat": 50.6667,
                "lng": 156.1167
            },
            "Saskylakh": {
                "lat": 71.9653,
                "lng": 114.0922
            },
            "Egvekinot": {
                "lat": 66.3221,
                "lng": -179.1837
            },
            "Evensk": {
                "lat": 61.95,
                "lng": 159.2333
            },
            "Beringovskiy": {
                "lat": 63.0655,
                "lng": 179.3067
            },
            "Novyy Port": {
                "lat": 67.6919,
                "lng": 72.8964
            },
            "Ust\u2019-Kuyga": {
                "lat": 70.0171,
                "lng": 135.6
            },
            "Eldikan": {
                "lat": 60.8,
                "lng": 135.1833
            },
            "Art\u00ebmovsk": {
                "lat": 54.3483,
                "lng": 93.4356
            },
            "Kazachye": {
                "lat": 70.7522,
                "lng": 136.2342
            },
            "Lavrentiya": {
                "lat": 65.5842,
                "lng": -170.9889
            },
            "Verkhoyansk": {
                "lat": 67.55,
                "lng": 133.3833
            },
            "Dikson": {
                "lat": 73.507,
                "lng": 80.5451
            },
            "Chumikan": {
                "lat": 54.7,
                "lng": 135.2833
            },
            "Klyuchi": {
                "lat": 56.3167,
                "lng": 160.8333
            },
            "Ayan": {
                "lat": 56.4583,
                "lng": 138.1681
            },
            "Omolon": {
                "lat": 65.2667,
                "lng": 160.4667
            },
            "Yer\u00ebma": {
                "lat": 60.3808,
                "lng": 107.7794
            },
            "Zhilinda": {
                "lat": 70.1333,
                "lng": 113.9833
            },
            "Manily": {
                "lat": 62.4908,
                "lng": 165.3298
            },
            "Uelen": {
                "lat": 66.1594,
                "lng": -169.8092
            },
            "Mikhalkino": {
                "lat": 69.4353,
                "lng": 161.5508
            },
            "Oymyakon": {
                "lat": 63.4608,
                "lng": 142.7858
            },
            "Sherlovaya Gora": {
                "lat": 50.5306,
                "lng": 116.3006
            },
            "Nizhneyansk": {
                "lat": 71.4333,
                "lng": 136.0666
            },
            "Bugrino": {
                "lat": 68.7831,
                "lng": 49.3036
            },
            "Shoyna": {
                "lat": 67.8778,
                "lng": 44.1547
            },
            "Put\u2019 Lenina": {
                "lat": 68.5166,
                "lng": 107.8
            },
            "Enurmino": {
                "lat": 66.95,
                "lng": -171.8167
            },
            "Amderma": {
                "lat": 69.7631,
                "lng": 61.6678
            },
            "Korf": {
                "lat": 60.3667,
                "lng": 166.0167
            },
            "Progress": {
                "lat": 49.7504,
                "lng": 129.6167
            },
            "Il\u2019pyrskiy": {
                "lat": 59.96,
                "lng": 164.2
            },
            "Mukhomornoye": {
                "lat": 66.4171,
                "lng": 173.3333
            },
            "Vorontsovo": {
                "lat": 71.6983,
                "lng": 83.5642
            },
            "Kovda": {
                "lat": 66.6919,
                "lng": 32.8708
            },
            "Matochkin Shar": {
                "lat": 73.27,
                "lng": 56.4497
            },
            "Siglan": {
                "lat": 59.0337,
                "lng": 152.4166
            },
            "Omchak": {
                "lat": 61.6333,
                "lng": 147.9167
            },
            "Shalaurova": {
                "lat": 73.2204,
                "lng": 143.1833
            },
            "Khorgo": {
                "lat": 73.4833,
                "lng": 113.63
            },
            "Tiyerbes": {
                "lat": 64.3728,
                "lng": 120.5494
            },
            "Peregrebnoye": {
                "lat": 62.967,
                "lng": 65.0859
            },
            "Komsa": {
                "lat": 61.868,
                "lng": 89.2577
            },
            "Gyda": {
                "lat": 70.8814,
                "lng": 78.4661
            },
            "Khakhar": {
                "lat": 57.6666,
                "lng": 135.43
            },
            "Menkerya": {
                "lat": 67.9886,
                "lng": 123.3505
            },
            "Ust\u2019-Nyukzha": {
                "lat": 56.55,
                "lng": 121.6
            },
            "Zv\u00ebzdnyy": {
                "lat": 70.9333,
                "lng": -179.6
            },
            "Pakhachi": {
                "lat": 60.5816,
                "lng": 169.05
            },
            "Indiga": {
                "lat": 67.6898,
                "lng": 49.0166
            },
            "Starorybnoye": {
                "lat": 72.7666,
                "lng": 104.8
            },
            "Laryak": {
                "lat": 61.1012,
                "lng": 80.2514
            },
            "Ulkan": {
                "lat": 55.9004,
                "lng": 107.7833
            },
            "Strelka": {
                "lat": 61.867,
                "lng": 152.2502
            },
            "Chagda": {
                "lat": 60.1,
                "lng": 133.9
            },
            "Sagastyr": {
                "lat": 73.3779,
                "lng": 126.5924
            },
            "Zemlya Bunge": {
                "lat": 74.8983,
                "lng": 142.105
            },
            "Trofimovsk": {
                "lat": 72.5997,
                "lng": 127.0337
            },
            "Tunguskhaya": {
                "lat": 64.9004,
                "lng": 125.25
            },
            "Agapa": {
                "lat": 71.4504,
                "lng": 89.25
            },
            "Podkamennaya Tunguska": {
                "lat": 61.5995,
                "lng": 90.1236
            },
            "Tukchi": {
                "lat": 57.367,
                "lng": 139.5
            },
            "Varnek": {
                "lat": 69.7153,
                "lng": 60.06
            },
            "Numto": {
                "lat": 63.6667,
                "lng": 71.3333
            },
            "Ust\u2019-Olen\u00ebk": {
                "lat": 72.9833,
                "lng": 119.8167
            },
            "Bol\u2019sheretsk": {
                "lat": 52.439,
                "lng": 156.3594
            },
            "Olen\u00ebk": {
                "lat": 68.5,
                "lng": 112.4667
            },
            "Utkholok": {
                "lat": 57.5504,
                "lng": 157.2333
            },
            "Yessey": {
                "lat": 68.4652,
                "lng": 102.1887
            },
            "Karamken": {
                "lat": 60.2004,
                "lng": 151.1666
            },
            "Ambarchik": {
                "lat": 69.6261,
                "lng": 162.3039
            },
            "Nordvik": {
                "lat": 74.0165,
                "lng": 111.51
            },
            "Logashkino": {
                "lat": 70.85,
                "lng": 153.9167
            }
        }
    },
    "AR": {
        "name": "Argentina",
        "cities": {
            "Buenos Aires": {
                "lat": -34.6033,
                "lng": -58.3817
            },
            "C\u00f3rdoba": {
                "lat": -31.4167,
                "lng": -64.1833
            },
            "Rosario": {
                "lat": -32.9575,
                "lng": -60.6394
            },
            "Santiago del Estero": {
                "lat": -27.7833,
                "lng": -64.2667
            },
            "Comodoro Rivadavia": {
                "lat": -45.8647,
                "lng": -67.4808
            },
            "Mar del Plata": {
                "lat": -38.0,
                "lng": -57.55
            },
            "San Miguel de Tucum\u00e1n": {
                "lat": -26.8167,
                "lng": -65.2167
            },
            "Salta": {
                "lat": -24.7833,
                "lng": -65.4167
            },
            "San Juan": {
                "lat": -31.5342,
                "lng": -68.5261
            },
            "Lan\u00fas": {
                "lat": -34.7,
                "lng": -58.4
            },
            "Santa Fe": {
                "lat": -31.6333,
                "lng": -60.7
            },
            "Corrientes": {
                "lat": -27.4833,
                "lng": -58.8167
            },
            "San Salvador de Jujuy": {
                "lat": -24.1833,
                "lng": -65.3
            },
            "Bah\u00eda Blanca": {
                "lat": -38.7167,
                "lng": -62.2667
            },
            "Pilar": {
                "lat": -31.6833,
                "lng": -63.9
            },
            "Resistencia": {
                "lat": -27.4514,
                "lng": -58.9867
            },
            "Posadas": {
                "lat": -27.3667,
                "lng": -55.9
            },
            "Quilmes": {
                "lat": -34.7167,
                "lng": -58.2667
            },
            "Paran\u00e1": {
                "lat": -31.7331,
                "lng": -60.5297
            },
            "Merlo": {
                "lat": -34.6653,
                "lng": -58.7275
            },
            "Gonz\u00e1lez Cat\u00e1n": {
                "lat": -34.7667,
                "lng": -58.6167
            },
            "Formosa": {
                "lat": -26.1833,
                "lng": -58.1833
            },
            "Neuqu\u00e9n": {
                "lat": -38.9525,
                "lng": -68.0642
            },
            "Banfield": {
                "lat": -34.75,
                "lng": -58.3833
            },
            "Jos\u00e9 C. Paz": {
                "lat": -34.5167,
                "lng": -58.7667
            },
            "San Rafael": {
                "lat": -34.6,
                "lng": -68.3333
            },
            "R\u00edo Cuarto": {
                "lat": -33.1333,
                "lng": -64.35
            },
            "La Plata": {
                "lat": -34.9211,
                "lng": -57.9544
            },
            "Godoy Cruz": {
                "lat": -32.9167,
                "lng": -68.8333
            },
            "Isidro Casanova": {
                "lat": -34.7,
                "lng": -58.5833
            },
            "Las Heras": {
                "lat": -46.55,
                "lng": -68.95
            },
            "La Rioja": {
                "lat": -29.4125,
                "lng": -66.8542
            },
            "Berazategui": {
                "lat": -34.7167,
                "lng": -58.25
            },
            "San Luis": {
                "lat": -33.3,
                "lng": -66.3333
            },
            "San Fernando": {
                "lat": -34.45,
                "lng": -58.5667
            },
            "General Roca": {
                "lat": -39.0333,
                "lng": -67.5833
            },
            "Jos\u00e9 Mar\u00eda Ezeiza": {
                "lat": -34.8333,
                "lng": -58.5167
            },
            "San Nicol\u00e1s de los Arroyos": {
                "lat": -33.3333,
                "lng": -60.2167
            },
            "Catamarca": {
                "lat": -28.4667,
                "lng": -65.7833
            },
            "San Miguel": {
                "lat": -34.5333,
                "lng": -58.7167
            },
            "Concordia": {
                "lat": -31.4,
                "lng": -58.0333
            },
            "Rafael Castillo": {
                "lat": -34.7167,
                "lng": -58.6167
            },
            "Florencio Varela": {
                "lat": -34.8167,
                "lng": -58.2833
            },
            "San Justo": {
                "lat": -30.7833,
                "lng": -60.5833
            },
            "Ituzaing\u00f3": {
                "lat": -27.6,
                "lng": -56.6667
            },
            "Temperley": {
                "lat": -34.7667,
                "lng": -58.3833
            },
            "Ramos Mej\u00eda": {
                "lat": -34.65,
                "lng": -58.5667
            },
            "Puerto Madryn": {
                "lat": -42.7667,
                "lng": -65.05
            },
            "Mendoza": {
                "lat": -32.8833,
                "lng": -68.8167
            },
            "San Carlos de Bariloche": {
                "lat": -41.15,
                "lng": -71.3
            },
            "Lomas de Zamora": {
                "lat": -34.7667,
                "lng": -58.4
            },
            "Villa Mercedes": {
                "lat": -33.6667,
                "lng": -65.4667
            },
            "Bernal": {
                "lat": -34.7,
                "lng": -58.2833
            },
            "Esteban Echeverr\u00eda": {
                "lat": -34.8167,
                "lng": -58.4667
            },
            "Gualeguaych\u00fa": {
                "lat": -33.0167,
                "lng": -58.5167
            },
            "Castelar": {
                "lat": -34.6667,
                "lng": -58.6667
            },
            "Villa Krause": {
                "lat": -31.5833,
                "lng": -68.5333
            },
            "Luj\u00e1n": {
                "lat": -34.5667,
                "lng": -59.1
            },
            "Maip\u00fa": {
                "lat": -36.8667,
                "lng": -57.8667
            },
            "La Banda": {
                "lat": -27.7333,
                "lng": -64.25
            },
            "Pergamino": {
                "lat": -33.8833,
                "lng": -60.5667
            },
            "Santa Rosa": {
                "lat": -33.25,
                "lng": -68.15
            },
            "Concepci\u00f3n del Uruguay": {
                "lat": -32.4833,
                "lng": -58.2333
            },
            "Libertad": {
                "lat": -34.6833,
                "lng": -58.6833
            },
            "Mor\u00f3n": {
                "lat": -34.65,
                "lng": -58.6167
            },
            "Burzaco": {
                "lat": -34.8167,
                "lng": -58.3667
            },
            "Z\u00e1rate": {
                "lat": -34.0833,
                "lng": -59.0333
            },
            "R\u00edo Grande": {
                "lat": -53.7833,
                "lng": -67.7
            },
            "Trelew": {
                "lat": -43.25,
                "lng": -65.3
            },
            "R\u00edo Gallegos": {
                "lat": -51.6233,
                "lng": -69.2161
            },
            "Caseros": {
                "lat": -34.6106,
                "lng": -58.5625
            },
            "Villa Luzuriaga": {
                "lat": -34.6595,
                "lng": -58.5833
            },
            "Ciudad General Belgrano": {
                "lat": -34.7167,
                "lng": -58.5333
            },
            "Rafaela": {
                "lat": -31.2667,
                "lng": -61.4833
            },
            "San Francisco Solano": {
                "lat": -34.7667,
                "lng": -58.3167
            },
            "Necochea": {
                "lat": -38.55,
                "lng": -58.7333
            },
            "Presidencia Roque S\u00e1enz Pe\u00f1a": {
                "lat": -26.7833,
                "lng": -60.45
            },
            "Olavarr\u00eda": {
                "lat": -36.9,
                "lng": -60.3333
            },
            "Villa Mar\u00eda": {
                "lat": -32.4103,
                "lng": -63.2314
            },
            "Berisso": {
                "lat": -34.8728,
                "lng": -57.8858
            },
            "Cipolletti": {
                "lat": -38.9333,
                "lng": -67.9833
            },
            "Chimbas": {
                "lat": -31.5,
                "lng": -68.5319
            },
            "Campana": {
                "lat": -34.1667,
                "lng": -58.9167
            },
            "Jun\u00edn": {
                "lat": -34.5833,
                "lng": -60.95
            },
            "General Rodr\u00edguez": {
                "lat": -34.6167,
                "lng": -58.95
            },
            "Grand Bourg": {
                "lat": -34.4833,
                "lng": -58.7167
            },
            "Monte Chingolo": {
                "lat": -34.7333,
                "lng": -58.35
            },
            "Rivadavia": {
                "lat": -33.1833,
                "lng": -68.4667
            },
            "Puerto Iguaz\u00fa": {
                "lat": -25.6,
                "lng": -54.5667
            },
            "Remedios de Escalada": {
                "lat": -34.7167,
                "lng": -58.4
            },
            "Gobernador G\u00e1lvez": {
                "lat": -33.0256,
                "lng": -60.6336
            },
            "Ezpeleta": {
                "lat": -34.7517,
                "lng": -58.2344
            },
            "San Mart\u00edn": {
                "lat": -31.4397,
                "lng": -68.5206
            },
            "Villa Celina": {
                "lat": -34.7167,
                "lng": -58.4833
            },
            "San Ram\u00f3n de la Nueva Or\u00e1n": {
                "lat": -23.1333,
                "lng": -64.3333
            },
            "San Pedro": {
                "lat": -24.2196,
                "lng": -64.87
            },
            "Villa Carlos Paz": {
                "lat": -31.4,
                "lng": -64.5167
            },
            "Reconquista": {
                "lat": -29.2333,
                "lng": -59.9333
            },
            "Ciudadela": {
                "lat": -34.6333,
                "lng": -58.5333
            },
            "Luj\u00e1n de Cuyo": {
                "lat": -32.998,
                "lng": -68.873
            },
            "Goya": {
                "lat": -29.1333,
                "lng": -59.25
            },
            "Bella Vista": {
                "lat": -27.0333,
                "lng": -65.3
            },
            "Lomas del Mirador": {
                "lat": -34.6667,
                "lng": -58.5297
            },
            "Ober\u00e1": {
                "lat": -27.4833,
                "lng": -55.1333
            },
            "Wilde": {
                "lat": -34.7,
                "lng": -58.3167
            },
            "Mart\u00ednez": {
                "lat": -34.4833,
                "lng": -58.5
            },
            "Santo Tom\u00e9": {
                "lat": -28.55,
                "lng": -56.05
            },
            "Don Torcuato": {
                "lat": -34.5,
                "lng": -58.6167
            },
            "Gerli": {
                "lat": -34.6833,
                "lng": -58.3667
            },
            "Piran\u00e9": {
                "lat": -25.7328,
                "lng": -59.11
            },
            "Mercedes": {
                "lat": -29.2,
                "lng": -58.0833
            },
            "Banda del R\u00edo Sal\u00ed": {
                "lat": -26.85,
                "lng": -65.1667
            },
            "Tartagal": {
                "lat": -22.5,
                "lng": -63.8333
            },
            "Villa Dom\u00ednico": {
                "lat": -34.6917,
                "lng": -58.3333
            },
            "San Francisco": {
                "lat": -31.4356,
                "lng": -62.0714
            },
            "Viedma": {
                "lat": -40.8,
                "lng": -63.0
            },
            "Los Polvorines": {
                "lat": -34.5,
                "lng": -58.7
            },
            "Sarand\u00ed": {
                "lat": -34.6833,
                "lng": -58.3333
            },
            "Hurlingham": {
                "lat": -34.6,
                "lng": -58.6333
            },
            "Gar\u00edn": {
                "lat": -34.4167,
                "lng": -58.75
            },
            "El Palomar": {
                "lat": -34.6167,
                "lng": -58.5833
            },
            "Beccar": {
                "lat": -34.4667,
                "lng": -58.5167
            },
            "Punta Alta": {
                "lat": -38.88,
                "lng": -62.075
            },
            "Chivilcoy": {
                "lat": -34.9,
                "lng": -60.0167
            },
            "Glew": {
                "lat": -34.8833,
                "lng": -58.3833
            },
            "General Pico": {
                "lat": -35.6667,
                "lng": -63.7333
            },
            "Cosqu\u00edn": {
                "lat": -31.2436,
                "lng": -64.4653
            },
            "Eldorado": {
                "lat": -26.4,
                "lng": -54.6333
            },
            "Tres Arroyos": {
                "lat": -38.3667,
                "lng": -60.2667
            },
            "Ushuaia": {
                "lat": -54.8019,
                "lng": -68.3031
            },
            "Rafael Calzada": {
                "lat": -34.7833,
                "lng": -58.3667
            },
            "Azul": {
                "lat": -36.7833,
                "lng": -59.85
            },
            "Barranqueras": {
                "lat": -27.4833,
                "lng": -58.9333
            },
            "Bel\u00e9n de Escobar": {
                "lat": -34.3333,
                "lng": -58.8167
            },
            "Ensenada": {
                "lat": -34.8644,
                "lng": -57.9103
            },
            "Mariano Acosta": {
                "lat": -34.7167,
                "lng": -58.7833
            },
            "Clorinda": {
                "lat": -25.2833,
                "lng": -57.7167
            },
            "Guernica": {
                "lat": -34.9167,
                "lng": -58.3833
            },
            "Caleta Olivia": {
                "lat": -46.4333,
                "lng": -67.5333
            },
            "Ensenada Berisso": {
                "lat": -34.8667,
                "lng": -57.9
            },
            "El Talar de Pacheco": {
                "lat": -34.4719,
                "lng": -58.655
            },
            "Palpal\u00e1": {
                "lat": -24.25,
                "lng": -65.1167
            },
            "Yerba Buena": {
                "lat": -26.8167,
                "lng": -65.3167
            },
            "Concepci\u00f3n": {
                "lat": -27.3333,
                "lng": -65.5833
            },
            "Villaguay": {
                "lat": -31.85,
                "lng": -59.0167
            },
            "Tunuy\u00e1n": {
                "lat": -33.5667,
                "lng": -69.0167
            },
            "Florida": {
                "lat": -34.5167,
                "lng": -58.5
            },
            "Alta Gracia": {
                "lat": -31.6667,
                "lng": -64.4333
            },
            "La Reja": {
                "lat": -34.6394,
                "lng": -58.8283
            },
            "San Lorenzo": {
                "lat": -32.75,
                "lng": -60.7333
            },
            "Longchamps": {
                "lat": -34.85,
                "lng": -58.3833
            },
            "Catriel": {
                "lat": -37.8667,
                "lng": -67.8333
            },
            "Villa Constituci\u00f3n": {
                "lat": -33.2333,
                "lng": -60.3333
            },
            "General Mart\u00edn Miguel de G\u00fcemes": {
                "lat": -24.6667,
                "lng": -65.05
            },
            "Libertador General San Mart\u00edn": {
                "lat": -23.8,
                "lng": -64.7833
            },
            "R\u00edo Tercero": {
                "lat": -32.1833,
                "lng": -64.1
            },
            "Trenque Lauquen": {
                "lat": -35.9667,
                "lng": -62.7
            },
            "Chajar\u00ed": {
                "lat": -30.7667,
                "lng": -57.9833
            },
            "Balcarce": {
                "lat": -37.8456,
                "lng": -58.2578
            },
            "San Vicente": {
                "lat": -35.0167,
                "lng": -58.4167
            },
            "San Isidro": {
                "lat": -34.4667,
                "lng": -58.5167
            },
            "General Pacheco": {
                "lat": -34.45,
                "lng": -58.65
            },
            "Paso de los Libres": {
                "lat": -29.7167,
                "lng": -57.0833
            },
            "Dock Sur": {
                "lat": -34.6417,
                "lng": -58.3478
            },
            "Gualeguay": {
                "lat": -33.15,
                "lng": -59.3333
            },
            "Coronel Su\u00e1rez": {
                "lat": -37.4667,
                "lng": -61.9333
            },
            "Esperanza": {
                "lat": -31.4167,
                "lng": -60.9333
            },
            "Tolosa": {
                "lat": -34.8942,
                "lng": -57.9647
            },
            "Llavallol": {
                "lat": -34.7667,
                "lng": -58.3833
            },
            "Villa \u00c1ngela": {
                "lat": -27.5833,
                "lng": -60.7167
            },
            "Tortuguitas": {
                "lat": -34.475,
                "lng": -58.7556
            },
            "Claypole": {
                "lat": -34.8,
                "lng": -58.3333
            },
            "Villa Alsina": {
                "lat": -34.6667,
                "lng": -58.4167
            },
            "Jos\u00e9 M\u00e1rmol": {
                "lat": -34.7833,
                "lng": -58.3667
            },
            "Ap\u00f3stoles": {
                "lat": -27.9167,
                "lng": -55.7667
            },
            "Pinamar": {
                "lat": -37.1,
                "lng": -56.85
            },
            "Taf\u00ed Viejo": {
                "lat": -26.7333,
                "lng": -65.2667
            },
            "Virreyes": {
                "lat": -34.45,
                "lng": -58.5333
            },
            "Marcos Paz": {
                "lat": -34.7667,
                "lng": -58.8333
            },
            "Ingeniero Pablo Nogu\u00e9s": {
                "lat": -34.4667,
                "lng": -58.7667
            },
            "Chacabuco": {
                "lat": -34.6333,
                "lng": -60.4667
            },
            "Arist\u00f3bulo del Valle": {
                "lat": -27.1167,
                "lng": -54.9167
            },
            "San Antonio de Padua": {
                "lat": -34.6667,
                "lng": -58.7
            },
            "Haedo": {
                "lat": -34.65,
                "lng": -58.6
            },
            "Granadero Baigorria": {
                "lat": -32.85,
                "lng": -60.7
            },
            "Esquel": {
                "lat": -42.9,
                "lng": -71.3167
            },
            "Nueve de Julio": {
                "lat": -35.45,
                "lng": -60.8833
            },
            "Munro": {
                "lat": -34.5333,
                "lng": -58.5167
            },
            "Cutral-C\u00f3": {
                "lat": -38.9333,
                "lng": -69.2333
            },
            "Villa Adelina": {
                "lat": -34.5167,
                "lng": -58.5333
            },
            "Villa Ballester": {
                "lat": -34.5167,
                "lng": -58.5333
            },
            "Casilda": {
                "lat": -33.05,
                "lng": -61.1667
            },
            "Curuz\u00fa Cuati\u00e1": {
                "lat": -29.7833,
                "lng": -58.0833
            },
            "Centenario": {
                "lat": -38.8,
                "lng": -68.1333
            },
            "Bell Ville": {
                "lat": -32.6333,
                "lng": -62.6833
            },
            "Chilecito": {
                "lat": -29.1667,
                "lng": -67.5
            },
            "Chascom\u00fas": {
                "lat": -35.575,
                "lng": -58.0089
            },
            "Bragado": {
                "lat": -35.1167,
                "lng": -60.5
            },
            "City Bell": {
                "lat": -34.85,
                "lng": -58.05
            },
            "Plottier": {
                "lat": -38.95,
                "lng": -68.2333
            },
            "La Calera": {
                "lat": -31.3439,
                "lng": -64.3353
            },
            "Termas de R\u00edo Hondo": {
                "lat": -27.4833,
                "lng": -64.8667
            },
            "Zapala": {
                "lat": -38.9,
                "lng": -70.0667
            },
            "Aguilares": {
                "lat": -27.4333,
                "lng": -65.6167
            },
            "Quitilipi": {
                "lat": -26.8667,
                "lng": -60.2167
            },
            "Fontana": {
                "lat": -27.4167,
                "lng": -59.0333
            },
            "Ca\u00f1uelas": {
                "lat": -35.0333,
                "lng": -58.7333
            },
            "Avellaneda": {
                "lat": -29.1175,
                "lng": -59.6583
            },
            "General Jos\u00e9 de San Mart\u00edn": {
                "lat": -26.5375,
                "lng": -59.3417
            },
            "Jes\u00fas Mar\u00eda": {
                "lat": -30.9833,
                "lng": -64.1
            },
            "Pehuaj\u00f3": {
                "lat": -35.8,
                "lng": -61.9
            },
            "Lobos": {
                "lat": -35.1833,
                "lng": -59.0833
            },
            "Pichanal": {
                "lat": -23.3167,
                "lng": -64.2167
            },
            "Cruz del Eje": {
                "lat": -30.7333,
                "lng": -64.8
            },
            "Gobernador Virasora": {
                "lat": -28.05,
                "lng": -56.0333
            },
            "Victoria": {
                "lat": -32.6167,
                "lng": -60.1667
            },
            "Miramar": {
                "lat": -38.2667,
                "lng": -57.8333
            },
            "Villa Regina": {
                "lat": -39.1,
                "lng": -67.0667
            },
            "General Alvear": {
                "lat": -36.0333,
                "lng": -60.0167
            },
            "Villa Dolores": {
                "lat": -31.9333,
                "lng": -65.2
            },
            "Villa Gesell": {
                "lat": -37.25,
                "lng": -56.9833
            },
            "Capit\u00e1n Berm\u00fadez": {
                "lat": -32.8167,
                "lng": -60.7167
            },
            "Ca\u00f1ada de G\u00f3mez": {
                "lat": -32.8167,
                "lng": -61.4
            },
            "Garup\u00e1": {
                "lat": -27.4833,
                "lng": -55.8333
            },
            "Baradero": {
                "lat": -33.8,
                "lng": -59.5167
            },
            "General San Mart\u00edn": {
                "lat": -34.5667,
                "lng": -58.5333
            },
            "Adrogue": {
                "lat": -34.8,
                "lng": -58.3833
            },
            "Caucete": {
                "lat": -31.65,
                "lng": -68.2833
            },
            "Lincoln": {
                "lat": -34.85,
                "lng": -61.5167
            },
            "San Mart\u00edn de los Andes": {
                "lat": -40.1667,
                "lng": -71.35
            },
            "Malarg\u00fce": {
                "lat": -35.475,
                "lng": -69.5833
            },
            "Castelli": {
                "lat": -25.95,
                "lng": -60.6167
            },
            "Marcos Ju\u00e1rez": {
                "lat": -32.7,
                "lng": -62.1
            },
            "Arrecifes": {
                "lat": -34.0667,
                "lng": -60.1167
            },
            "Las Flores": {
                "lat": -36.0139,
                "lng": -59.1
            },
            "Fr\u00edas": {
                "lat": -28.6333,
                "lng": -65.1333
            },
            "Charata": {
                "lat": -27.2167,
                "lng": -61.2
            },
            "P\u00e9rez": {
                "lat": -33.0,
                "lng": -60.7667
            },
            "Mu\u00f1iz": {
                "lat": -34.5333,
                "lng": -58.7
            },
            "Rosario de la Frontera": {
                "lat": -25.8,
                "lng": -64.9667
            },
            "Dolores": {
                "lat": -36.3167,
                "lng": -57.6667
            },
            "Firmat": {
                "lat": -33.45,
                "lng": -61.4833
            },
            "Jard\u00edn Am\u00e9rica": {
                "lat": -27.05,
                "lng": -55.2333
            },
            "Mar de Aj\u00f3": {
                "lat": -36.7203,
                "lng": -56.6878
            },
            "Leandro N. Alem": {
                "lat": -27.6,
                "lng": -55.3333
            },
            "El Calafate": {
                "lat": -50.3378,
                "lng": -72.26
            },
            "Candelaria": {
                "lat": -27.4594,
                "lng": -55.7453
            },
            "Tres Isletas": {
                "lat": -26.35,
                "lng": -60.4167
            },
            "Rawson": {
                "lat": -43.3,
                "lng": -65.1
            },
            "La Paz": {
                "lat": -33.4667,
                "lng": -67.55
            },
            "Vicente L\u00f3pez": {
                "lat": -34.5333,
                "lng": -58.475
            },
            "Embarcaci\u00f3n": {
                "lat": -23.2167,
                "lng": -64.1
            },
            "Tapiales": {
                "lat": -34.7058,
                "lng": -58.5047
            },
            "Funes": {
                "lat": -32.9167,
                "lng": -60.8167
            },
            "Monte Caseros": {
                "lat": -30.25,
                "lng": -57.65
            },
            "Veinticinco de Mayo": {
                "lat": -37.7712,
                "lng": -67.7174
            },
            "A\u00f1atuya": {
                "lat": -28.4667,
                "lng": -62.8333
            },
            "Monteros": {
                "lat": -27.1667,
                "lng": -65.5
            },
            "Col\u00f3n": {
                "lat": -32.2167,
                "lng": -58.1333
            },
            "San Andr\u00e9s de Giles": {
                "lat": -34.4333,
                "lng": -59.4333
            },
            "Manuel B. Gonnet": {
                "lat": -34.85,
                "lng": -58.0167
            },
            "Las Bre\u00f1as": {
                "lat": -27.0667,
                "lng": -61.0667
            },
            "Famaill\u00e1": {
                "lat": -27.05,
                "lng": -65.4
            },
            "Allen": {
                "lat": -38.9667,
                "lng": -67.8333
            },
            "Nogoy\u00e1": {
                "lat": -32.4,
                "lng": -59.8
            },
            "Cinco Saltos": {
                "lat": -38.8167,
                "lng": -68.0667
            },
            "Arroyito": {
                "lat": -31.4167,
                "lng": -63.05
            },
            "Melchor Romero": {
                "lat": -34.9492,
                "lng": -58.0472
            },
            "Machagai": {
                "lat": -26.9167,
                "lng": -60.0333
            },
            "Rosario de Lerma": {
                "lat": -24.9833,
                "lng": -65.5833
            },
            "De\u00e1n Funes": {
                "lat": -30.4333,
                "lng": -64.35
            },
            "Alejandro Korn": {
                "lat": -34.9667,
                "lng": -58.3667
            },
            "San Antonio de Areco": {
                "lat": -34.25,
                "lng": -59.4667
            },
            "Carlos Casares": {
                "lat": -35.6167,
                "lng": -61.3667
            },
            "San Isidro de Lules": {
                "lat": -26.9333,
                "lng": -65.35
            },
            "Joaqu\u00edn V. Gonz\u00e1lez": {
                "lat": -25.0833,
                "lng": -64.1833
            },
            "San Jos\u00e9 de J\u00e1chal": {
                "lat": -30.2333,
                "lng": -68.75
            },
            "Pico Truncado": {
                "lat": -46.795,
                "lng": -67.955
            },
            "Don Bosco": {
                "lat": -34.7,
                "lng": -58.2833
            },
            "Profesor Salvador Mazza": {
                "lat": -22.05,
                "lng": -63.7
            },
            "Arroyo Seco": {
                "lat": -33.1667,
                "lng": -60.5
            },
            "Sunchales": {
                "lat": -30.9333,
                "lng": -61.5667
            },
            "Laboulaye": {
                "lat": -34.1167,
                "lng": -63.4
            },
            "Carmen de Patagones": {
                "lat": -40.7833,
                "lng": -62.9667
            },
            "G\u00e1lvez": {
                "lat": -32.0333,
                "lng": -61.2167
            },
            "Matheu": {
                "lat": -34.3667,
                "lng": -58.8333
            },
            "Brandsen": {
                "lat": -35.1667,
                "lng": -58.2167
            },
            "Saavedra": {
                "lat": -37.7833,
                "lng": -62.35
            },
            "R\u00edo Segundo": {
                "lat": -31.6501,
                "lng": -63.9116
            },
            "Villa Elisa": {
                "lat": -32.1667,
                "lng": -58.4
            },
            "Crespo": {
                "lat": -32.0333,
                "lng": -60.3167
            },
            "Vera": {
                "lat": -29.4667,
                "lng": -60.2167
            },
            "Diamante": {
                "lat": -32.0667,
                "lng": -60.65
            },
            "Billinghurst": {
                "lat": -34.5667,
                "lng": -58.5333
            },
            "Mart\u00edn Coronado": {
                "lat": -34.6,
                "lng": -58.55
            },
            "El Bols\u00f3n": {
                "lat": -41.9667,
                "lng": -71.5333
            },
            "Carlos Spegazzini": {
                "lat": -34.8833,
                "lng": -58.5667
            },
            "Villa Nueva": {
                "lat": -32.4331,
                "lng": -63.2475
            },
            "Rufino": {
                "lat": -34.2667,
                "lng": -62.7
            },
            "Unquillo": {
                "lat": -31.2333,
                "lng": -64.3167
            },
            "Saladas": {
                "lat": -28.25,
                "lng": -58.6167
            },
            "Monte Cristo": {
                "lat": -31.3431,
                "lng": -63.9444
            },
            "Aldo Bonzi": {
                "lat": -34.7083,
                "lng": -58.5181
            },
            "Coronda": {
                "lat": -31.9667,
                "lng": -60.9167
            },
            "General Juan Madariaga": {
                "lat": -37.0167,
                "lng": -57.1333
            },
            "General Deheza": {
                "lat": -32.7564,
                "lng": -63.7889
            },
            "Santa Elena": {
                "lat": -30.95,
                "lng": -59.8
            },
            "Cerrillos": {
                "lat": -24.9,
                "lng": -65.4833
            },
            "San Jorge": {
                "lat": -31.8961,
                "lng": -61.8597
            },
            "Puerto Rico": {
                "lat": -26.8,
                "lng": -55.0167
            },
            "Villa Sarmiento": {
                "lat": -34.6333,
                "lng": -58.5667
            },
            "Ayacucho": {
                "lat": -37.1333,
                "lng": -58.4667
            },
            "Santa Mar\u00eda": {
                "lat": -31.2611,
                "lng": -64.4639
            },
            "Morteros": {
                "lat": -30.7,
                "lng": -62.0
            },
            "La Quiaca": {
                "lat": -22.1042,
                "lng": -65.5972
            },
            "Daireaux": {
                "lat": -36.6,
                "lng": -61.75
            },
            "Federaci\u00f3n": {
                "lat": -30.9833,
                "lng": -57.9167
            },
            "Puan": {
                "lat": -37.55,
                "lng": -62.7667
            },
            "Villa San Jos\u00e9": {
                "lat": -32.2,
                "lng": -58.2167
            },
            "La Falda": {
                "lat": -31.0833,
                "lng": -64.5
            },
            "Carcara\u00f1\u00e1": {
                "lat": -32.85,
                "lng": -61.15
            },
            "General Villegas": {
                "lat": -35.0333,
                "lng": -63.0167
            },
            "San Antonio Oeste": {
                "lat": -40.7333,
                "lng": -64.95
            },
            "Carhu\u00e9": {
                "lat": -37.1796,
                "lng": -62.7601
            },
            "Federal": {
                "lat": -30.95,
                "lng": -58.8
            },
            "La Lucila": {
                "lat": -34.4833,
                "lng": -58.4833
            },
            "General Pinedo": {
                "lat": -27.3167,
                "lng": -61.2833
            },
            "Wanda": {
                "lat": -25.9667,
                "lng": -54.5833
            },
            "Villa del Rosario": {
                "lat": -31.5833,
                "lng": -63.5333
            },
            "San Luis del Palmar": {
                "lat": -27.5167,
                "lng": -58.5667
            },
            "Villa Paranacito": {
                "lat": -33.7,
                "lng": -58.6833
            },
            "Fray Luis A. Beltr\u00e1n": {
                "lat": -32.7833,
                "lng": -60.7333
            },
            "Villa Ocampo": {
                "lat": -28.4833,
                "lng": -59.35
            },
            "Villa Ojo de Agua": {
                "lat": -29.5167,
                "lng": -63.7
            },
            "Carmen de Areco": {
                "lat": -34.3858,
                "lng": -59.8294
            },
            "Tostado": {
                "lat": -29.2333,
                "lng": -61.7667
            },
            "Ceres": {
                "lat": -29.8667,
                "lng": -61.95
            },
            "Rauch": {
                "lat": -36.7667,
                "lng": -59.1
            },
            "San Crist\u00f3bal": {
                "lat": -30.3167,
                "lng": -61.2333
            },
            "Rold\u00e1n": {
                "lat": -32.9011,
                "lng": -60.9072
            },
            "Benito Ju\u00e1rez": {
                "lat": -37.6667,
                "lng": -59.7833
            },
            "Puerto Deseado": {
                "lat": -47.75,
                "lng": -65.9167
            },
            "Andalgal\u00e1": {
                "lat": -27.6,
                "lng": -66.3167
            },
            "Pig\u00fc\u00e9": {
                "lat": -37.6167,
                "lng": -62.4167
            },
            "Las Rosas": {
                "lat": -32.4833,
                "lng": -61.5833
            },
            "San Javier": {
                "lat": -30.5833,
                "lng": -59.95
            },
            "General Belgrano": {
                "lat": -35.7667,
                "lng": -58.5
            },
            "General Viamonte": {
                "lat": -35.0,
                "lng": -61.0333
            },
            "Empedrado": {
                "lat": -27.9333,
                "lng": -58.7833
            },
            "Puerto San Mart\u00edn": {
                "lat": -32.7167,
                "lng": -60.7333
            },
            "Oncativo": {
                "lat": -31.9167,
                "lng": -63.6667
            },
            "Plaza Huincul": {
                "lat": -38.9338,
                "lng": -69.1987
            },
            "General Enrique Mosconi": {
                "lat": -22.6,
                "lng": -63.8167
            },
            "Chos Malal": {
                "lat": -37.3833,
                "lng": -70.2667
            },
            "Chamical": {
                "lat": -30.3667,
                "lng": -66.3167
            },
            "Acasusso": {
                "lat": -34.4667,
                "lng": -58.5167
            },
            "Santa Rosa de Calamuchita": {
                "lat": -32.0667,
                "lng": -64.5333
            },
            "Rosario del Tala": {
                "lat": -32.3,
                "lng": -59.1333
            },
            "General Las Heras": {
                "lat": -34.9333,
                "lng": -58.95
            },
            "San Salvador": {
                "lat": -31.6167,
                "lng": -58.5
            },
            "Jun\u00edn de los Andes": {
                "lat": -39.9167,
                "lng": -71.0667
            },
            "General Acha": {
                "lat": -37.3833,
                "lng": -64.6
            },
            "Monte Quemado": {
                "lat": -25.8036,
                "lng": -62.8303
            },
            "La Carlota": {
                "lat": -33.4333,
                "lng": -63.3
            },
            "Ingeniero Maschwitz": {
                "lat": -34.3667,
                "lng": -58.75
            },
            "Las Lomitas": {
                "lat": -24.7072,
                "lng": -60.5944
            },
            "Las Parejas": {
                "lat": -32.6833,
                "lng": -61.5333
            },
            "Laguna Paiva": {
                "lat": -31.3039,
                "lng": -60.6589
            },
            "Villa Uni\u00f3n": {
                "lat": -29.3,
                "lng": -68.2
            },
            "Bel\u00e9n": {
                "lat": -27.65,
                "lng": -67.0333
            },
            "Presidencia de la Plaza": {
                "lat": -26.9833,
                "lng": -59.8333
            },
            "Lober\u00eda": {
                "lat": -38.1333,
                "lng": -58.7667
            },
            "Malvinas Argentinas": {
                "lat": -31.3697,
                "lng": -64.0531
            },
            "Justo Daract": {
                "lat": -33.8667,
                "lng": -65.1667
            },
            "Villa Berthet": {
                "lat": -27.2667,
                "lng": -60.4167
            },
            "Recreo": {
                "lat": -29.2667,
                "lng": -65.0667
            },
            "Cafayate": {
                "lat": -26.0833,
                "lng": -65.9667
            },
            "Tornquist": {
                "lat": -38.1,
                "lng": -62.2167
            },
            "General Cabrera": {
                "lat": -32.7994,
                "lng": -63.8683
            },
            "R\u00edo Colorado": {
                "lat": -38.9908,
                "lng": -64.0958
            },
            "Oliva": {
                "lat": -32.0333,
                "lng": -63.5667
            },
            "Santa Luc\u00eda": {
                "lat": -28.9833,
                "lng": -59.1
            },
            "Navarro": {
                "lat": -35.0167,
                "lng": -59.2667
            },
            "Coronel Dorrego": {
                "lat": -38.7,
                "lng": -61.2667
            },
            "Tinogasta": {
                "lat": -28.0667,
                "lng": -67.5667
            },
            "Ramallo": {
                "lat": -33.3167,
                "lng": -60.2
            },
            "Humahuaca": {
                "lat": -23.2,
                "lng": -65.35
            },
            "Capit\u00e1n Sarmiento": {
                "lat": -34.1667,
                "lng": -59.7833
            },
            "Hernando": {
                "lat": -32.4167,
                "lng": -63.7333
            },
            "Capilla del Monte": {
                "lat": -30.85,
                "lng": -64.5167
            },
            "Armstrong": {
                "lat": -32.7833,
                "lng": -61.6
            },
            "San Bernardo": {
                "lat": -27.2667,
                "lng": -60.7
            },
            "El Tr\u00e9bol": {
                "lat": -32.1833,
                "lng": -61.7167
            },
            "Villa La Angostura": {
                "lat": -40.7625,
                "lng": -71.6463
            },
            "San Carlos Centro": {
                "lat": -31.7333,
                "lng": -61.1
            },
            "Quimil\u00ed": {
                "lat": -27.6333,
                "lng": -62.4167
            },
            "Roque P\u00e9rez": {
                "lat": -35.4167,
                "lng": -59.3167
            },
            "Bernardo de Irigoyen": {
                "lat": -26.25,
                "lng": -53.9833
            },
            "San Roque": {
                "lat": -28.5667,
                "lng": -58.7167
            },
            "Santa Rosa de R\u00edo Primero": {
                "lat": -31.15,
                "lng": -63.3833
            },
            "Sarmiento": {
                "lat": -45.6,
                "lng": -69.0833
            },
            "Villa Nougues": {
                "lat": -26.8578,
                "lng": -65.3756
            },
            "Campo Largo": {
                "lat": -26.8,
                "lng": -60.8333
            },
            "Frontera": {
                "lat": -31.4278,
                "lng": -62.0619
            },
            "Ingeniero White": {
                "lat": -38.7667,
                "lng": -62.2667
            },
            "Corzuela": {
                "lat": -26.9333,
                "lng": -60.9667
            },
            "Corral de Bustos": {
                "lat": -33.2833,
                "lng": -62.2
            },
            "Leones": {
                "lat": -32.6617,
                "lng": -62.2967
            },
            "Ranchos": {
                "lat": -35.5333,
                "lng": -58.2833
            },
            "San Jos\u00e9 de Feliciano": {
                "lat": -30.3833,
                "lng": -58.75
            },
            "Bat\u00e1n": {
                "lat": -38.0078,
                "lng": -57.7097
            },
            "Choele Choel": {
                "lat": -39.2667,
                "lng": -65.6833
            },
            "La Leonesa": {
                "lat": -27.05,
                "lng": -58.7167
            },
            "Brinkmann": {
                "lat": -30.8669,
                "lng": -62.0336
            },
            "Kaiken": {
                "lat": -54.5062,
                "lng": -67.1905
            },
            "Alcorta": {
                "lat": -33.5333,
                "lng": -61.1167
            },
            "Ciudad de Loreto": {
                "lat": -28.3,
                "lng": -64.2
            },
            "Puerto Tirol": {
                "lat": -27.3667,
                "lng": -59.0667
            },
            "Basavilbaso": {
                "lat": -32.3667,
                "lng": -58.8833
            },
            "Henderson": {
                "lat": -36.2833,
                "lng": -61.7167
            },
            "Pedro Luro": {
                "lat": -39.5,
                "lng": -62.6833
            },
            "Adolfo Gonz\u00e1les Chaves": {
                "lat": -38.0333,
                "lng": -60.0833
            },
            "Villa Ca\u00f1\u00e1s": {
                "lat": -34.0,
                "lng": -61.6
            },
            "Huinca Renanc\u00f3": {
                "lat": -34.8333,
                "lng": -64.3833
            },
            "Abra Pampa": {
                "lat": -22.7167,
                "lng": -65.7
            },
            "Punta Indio": {
                "lat": -35.2667,
                "lng": -57.2167
            },
            "Magdalena": {
                "lat": -35.0833,
                "lng": -57.5167
            },
            "Capilla del Se\u00f1or": {
                "lat": -34.2833,
                "lng": -59.1
            },
            "Romang": {
                "lat": -29.5,
                "lng": -59.7667
            },
            "Monte Rico": {
                "lat": -24.4514,
                "lng": -65.1667
            },
            "Sauce": {
                "lat": -30.0667,
                "lng": -58.7667
            },
            "Embalse": {
                "lat": -32.1833,
                "lng": -64.4167
            },
            "Rada Tilly": {
                "lat": -45.9333,
                "lng": -67.5333
            },
            "Santa Sylvina": {
                "lat": -27.7833,
                "lng": -61.15
            },
            "Coronel Du Graty": {
                "lat": -27.6667,
                "lng": -60.9333
            },
            "Mburucuy\u00e1": {
                "lat": -28.05,
                "lng": -58.2333
            },
            "Urdinarrain": {
                "lat": -32.6856,
                "lng": -58.8867
            },
            "Villa Aberastain": {
                "lat": -31.65,
                "lng": -68.5833
            },
            "Viale": {
                "lat": -31.8667,
                "lng": -60.0167
            },
            "Yacimiento R\u00edo Turbio": {
                "lat": -51.5333,
                "lng": -72.3
            },
            "La Cruz": {
                "lat": -28.1833,
                "lng": -56.65
            },
            "Pampa del Infierno": {
                "lat": -26.5167,
                "lng": -61.1667
            },
            "San Juli\u00e1n": {
                "lat": -49.3,
                "lng": -67.7167
            },
            "Ingeniero Guillermo N. Ju\u00e1rez": {
                "lat": -23.9,
                "lng": -61.85
            },
            "Comandante Luis Piedra Buena": {
                "lat": -49.983,
                "lng": -68.91
            },
            "Ingeniero Jacobacci": {
                "lat": -41.3,
                "lng": -69.5833
            },
            "Veintiocho de Noviembre": {
                "lat": -51.65,
                "lng": -72.3
            },
            "Chepes": {
                "lat": -31.35,
                "lng": -66.6
            },
            "General Conesa": {
                "lat": -40.1,
                "lng": -64.4167
            },
            "San Antonio de los Cobres": {
                "lat": -24.2178,
                "lng": -66.32
            },
            "Perito Moreno": {
                "lat": -46.5886,
                "lng": -70.9242
            },
            "Gobernador Gregores": {
                "lat": -48.7667,
                "lng": -70.25
            },
            "Victorica": {
                "lat": -36.2167,
                "lng": -65.45
            },
            "Comandante Fontana": {
                "lat": -25.3333,
                "lng": -59.6833
            },
            "Las Lajas": {
                "lat": -38.6,
                "lng": -70.3
            },
            "El Mait\u00e9n": {
                "lat": -42.05,
                "lng": -71.1667
            },
            "Uspallata": {
                "lat": -32.5667,
                "lng": -69.3167
            },
            "Darregueira": {
                "lat": -37.6996,
                "lng": -63.1666
            },
            "Trancas": {
                "lat": -26.2172,
                "lng": -65.2831
            },
            "Villalonga": {
                "lat": -39.8829,
                "lng": -62.5833
            },
            "R\u00edo Mayo": {
                "lat": -45.6869,
                "lng": -70.26
            },
            "Rodeo": {
                "lat": -30.2164,
                "lng": -69.1394
            },
            "Chumbicha": {
                "lat": -28.8667,
                "lng": -66.2333
            },
            "Alto R\u00edo Senguer": {
                "lat": -45.0167,
                "lng": -70.8167
            },
            "Sierra Colorada": {
                "lat": -40.5833,
                "lng": -67.8
            },
            "Villa Rumipal": {
                "lat": -32.1879,
                "lng": -64.4803
            },
            "Los Blancos": {
                "lat": -23.6,
                "lng": -62.6
            },
            "Susques": {
                "lat": -23.4167,
                "lng": -66.4833
            },
            "Comallo": {
                "lat": -41.0333,
                "lng": -70.2667
            },
            "Gastre": {
                "lat": -42.2667,
                "lng": -69.2167
            },
            "Telsen": {
                "lat": -42.3833,
                "lng": -66.95
            },
            "Tandil": {
                "lat": -37.3167,
                "lng": -59.1333
            },
            "Rinconada": {
                "lat": -22.4333,
                "lng": -66.1667
            },
            "Venado Tuerto": {
                "lat": -33.75,
                "lng": -61.9667
            }
        }
    },
    "NG": {
        "name": "Nigeria",
        "cities": {
            "Lagos": {
                "lat": 6.455,
                "lng": 3.3841
            },
            "Abuja": {
                "lat": 9.0667,
                "lng": 7.4833
            },
            "Ibadan": {
                "lat": 7.3964,
                "lng": 3.9167
            },
            "Awka": {
                "lat": 6.2069,
                "lng": 7.0678
            },
            "Kano": {
                "lat": 12.0,
                "lng": 8.5167
            },
            "Port Harcourt": {
                "lat": 4.8242,
                "lng": 7.0336
            },
            "Nneyi-Umuleri": {
                "lat": 6.3333,
                "lng": 6.8333
            },
            "Onitsha": {
                "lat": 6.1667,
                "lng": 6.7833
            },
            "Maiduguri": {
                "lat": 11.8333,
                "lng": 13.15
            },
            "Aba": {
                "lat": 5.1167,
                "lng": 7.3667
            },
            "Benin City": {
                "lat": 6.3333,
                "lng": 5.6222
            },
            "Shagamu": {
                "lat": 6.8333,
                "lng": 3.65
            },
            "Ikare": {
                "lat": 7.5167,
                "lng": 5.75
            },
            "Ogbomoso": {
                "lat": 8.1333,
                "lng": 4.25
            },
            "Mushin": {
                "lat": 6.5333,
                "lng": 3.35
            },
            "Owerri": {
                "lat": 5.485,
                "lng": 7.035
            },
            "Abeokuta": {
                "lat": 7.1608,
                "lng": 3.3483
            },
            "Ikeja": {
                "lat": 6.6,
                "lng": 3.35
            },
            "Akure": {
                "lat": 7.25,
                "lng": 5.195
            },
            "Ilorin": {
                "lat": 8.5,
                "lng": 4.55
            },
            "Kaduna": {
                "lat": 10.5167,
                "lng": 7.4333
            },
            "Osogbo": {
                "lat": 7.7667,
                "lng": 4.5667
            },
            "Enugu": {
                "lat": 6.4528,
                "lng": 7.5111
            },
            "Bauchi": {
                "lat": 10.3158,
                "lng": 9.8442
            },
            "Lokoja": {
                "lat": 7.8019,
                "lng": 6.7442
            },
            "Agege": {
                "lat": 6.6219,
                "lng": 3.3258
            },
            "Jalingo": {
                "lat": 8.9,
                "lng": 11.3667
            },
            "Jos": {
                "lat": 9.9167,
                "lng": 8.8903
            },
            "Somolu": {
                "lat": 6.5408,
                "lng": 3.3872
            },
            "Uyo": {
                "lat": 5.0333,
                "lng": 7.9275
            },
            "Ile-Ife": {
                "lat": 7.4667,
                "lng": 4.5667
            },
            "Ado-Ekiti": {
                "lat": 7.6211,
                "lng": 5.2214
            },
            "Sokoto": {
                "lat": 13.0622,
                "lng": 5.2339
            },
            "Ugep": {
                "lat": 5.8,
                "lng": 8.0833
            },
            "Zaria": {
                "lat": 11.0667,
                "lng": 7.7
            },
            "Yola": {
                "lat": 9.23,
                "lng": 12.46
            },
            "Nnewi": {
                "lat": 6.0167,
                "lng": 6.9167
            },
            "Gwoza": {
                "lat": 11.0861,
                "lng": 13.6914
            },
            "Saki": {
                "lat": 8.6667,
                "lng": 3.4
            },
            "Oyo": {
                "lat": 7.85,
                "lng": 3.933
            },
            "Ilesa": {
                "lat": 7.6167,
                "lng": 4.7167
            },
            "Gusau": {
                "lat": 12.15,
                "lng": 6.6667
            },
            "Warri": {
                "lat": 5.5167,
                "lng": 5.75
            },
            "Umuahia": {
                "lat": 5.5333,
                "lng": 7.4833
            },
            "Gwagwalada": {
                "lat": 8.9392,
                "lng": 7.0758
            },
            "Lafia": {
                "lat": 8.4917,
                "lng": 8.5167
            },
            "Okene": {
                "lat": 7.55,
                "lng": 6.2333
            },
            "Katsina": {
                "lat": 12.9889,
                "lng": 7.6008
            },
            "Minna": {
                "lat": 9.6139,
                "lng": 6.5569
            },
            "Kaura Namoda": {
                "lat": 12.6,
                "lng": 6.5897
            },
            "Nguru": {
                "lat": 12.8792,
                "lng": 10.4525
            },
            "Gombe": {
                "lat": 10.2904,
                "lng": 11.17
            },
            "Hong": {
                "lat": 10.2317,
                "lng": 12.9303
            },
            "Ondo": {
                "lat": 7.0833,
                "lng": 4.8333
            },
            "Sapele": {
                "lat": 5.9,
                "lng": 5.6667
            },
            "Iseyin": {
                "lat": 7.9667,
                "lng": 3.6
            },
            "Damboa": {
                "lat": 11.15,
                "lng": 12.75
            },
            "Ijebu-Ode": {
                "lat": 6.8208,
                "lng": 3.9208
            },
            "Owo": {
                "lat": 7.1961,
                "lng": 5.5864
            },
            "Osisioma": {
                "lat": 5.1497,
                "lng": 7.3303
            },
            "Apapa": {
                "lat": 6.45,
                "lng": 3.3667
            },
            "Kuje": {
                "lat": 8.8822,
                "lng": 7.2203
            },
            "Burutu": {
                "lat": 5.35,
                "lng": 5.5167
            },
            "Kukawa": {
                "lat": 12.9167,
                "lng": 13.5667
            },
            "Mai\u2019Adua": {
                "lat": 13.1906,
                "lng": 8.2117
            },
            "Isanlu": {
                "lat": 8.1667,
                "lng": 5.8
            },
            "Baure": {
                "lat": 12.7833,
                "lng": 8.7667
            },
            "Iwo": {
                "lat": 7.6333,
                "lng": 4.1833
            },
            "Malumfashi": {
                "lat": 11.8,
                "lng": 7.6167
            },
            "Epe": {
                "lat": 6.5833,
                "lng": 3.9833
            },
            "Inisa": {
                "lat": 7.9833,
                "lng": 4.65
            },
            "Ikot Ekpene": {
                "lat": 5.1833,
                "lng": 7.7167
            },
            "Funtua": {
                "lat": 11.5204,
                "lng": 7.32
            },
            "Calabar": {
                "lat": 4.9767,
                "lng": 8.3383
            },
            "Biu": {
                "lat": 10.6111,
                "lng": 12.195
            },
            "Ada": {
                "lat": 7.8956,
                "lng": 4.7094
            },
            "Bida": {
                "lat": 9.0804,
                "lng": 6.01
            },
            "Makurdi": {
                "lat": 7.7333,
                "lng": 8.5
            },
            "Offa": {
                "lat": 8.147,
                "lng": 4.72
            },
            "Ede": {
                "lat": 7.7389,
                "lng": 4.4361
            },
            "Kisi": {
                "lat": 9.0833,
                "lng": 3.85
            },
            "Zango": {
                "lat": 12.9333,
                "lng": 8.5333
            },
            "Dutse": {
                "lat": 11.7011,
                "lng": 9.3419
            },
            "Ipokia": {
                "lat": 6.5333,
                "lng": 2.85
            },
            "Auchi": {
                "lat": 7.0667,
                "lng": 6.2667
            },
            "Asaba": {
                "lat": 6.1833,
                "lng": 6.7333
            },
            "Numan": {
                "lat": 9.4669,
                "lng": 12.0328
            },
            "Igboho": {
                "lat": 8.8333,
                "lng": 3.75
            },
            "Ipetumodu": {
                "lat": 7.507,
                "lng": 4.4447
            },
            "Idanre": {
                "lat": 7.0922,
                "lng": 5.1322
            },
            "Mubi": {
                "lat": 10.2667,
                "lng": 13.2667
            },
            "Abaji": {
                "lat": 8.4756,
                "lng": 6.9433
            },
            "Gashua": {
                "lat": 12.8681,
                "lng": 11.0464
            },
            "Unwana": {
                "lat": 5.8625,
                "lng": 7.9486
            },
            "Bama": {
                "lat": 11.5189,
                "lng": 13.6842
            },
            "Ilobu": {
                "lat": 7.84,
                "lng": 4.486
            },
            "Nsukka": {
                "lat": 6.8567,
                "lng": 7.3958
            },
            "Itele": {
                "lat": 6.7667,
                "lng": 4.0625
            },
            "Nkpor": {
                "lat": 6.15,
                "lng": 6.8333
            },
            "Birnin Kebbi": {
                "lat": 12.4504,
                "lng": 4.1999
            },
            "Azare": {
                "lat": 11.6742,
                "lng": 10.1911
            },
            "Kontagora": {
                "lat": 10.403,
                "lng": 5.473
            },
            "Wukari": {
                "lat": 7.8704,
                "lng": 9.78
            },
            "Damaturu": {
                "lat": 11.7444,
                "lng": 11.9611
            },
            "Potiskum": {
                "lat": 11.7104,
                "lng": 11.08
            },
            "Keffi": {
                "lat": 8.8464,
                "lng": 7.8733
            },
            "Idah": {
                "lat": 7.0978,
                "lng": 6.7433
            },
            "Ikom": {
                "lat": 6.0833,
                "lng": 8.6167
            },
            "Iperu": {
                "lat": 6.9143,
                "lng": 3.6632
            },
            "Iju": {
                "lat": 6.6107,
                "lng": 3.0857
            },
            "Igbanke": {
                "lat": 6.3869,
                "lng": 6.1647
            },
            "Otukpo": {
                "lat": 7.1904,
                "lng": 8.13
            },
            "Chibok": {
                "lat": 10.8697,
                "lng": 12.8467
            },
            "Ipele": {
                "lat": 7.1333,
                "lng": 5.6667
            },
            "Oke-Mesi": {
                "lat": 7.8167,
                "lng": 4.9167
            },
            "Ikot Okoro": {
                "lat": 4.9,
                "lng": 7.7167
            },
            "Itapa-Ekiti": {
                "lat": 7.8131,
                "lng": 5.3844
            },
            "Kumo": {
                "lat": 10.0431,
                "lng": 11.2183
            },
            "Ikot Abasi": {
                "lat": 4.5704,
                "lng": 7.56
            },
            "Ire": {
                "lat": 7.9361,
                "lng": 4.725
            },
            "Choba": {
                "lat": 4.8906,
                "lng": 6.9033
            },
            "Okuta": {
                "lat": 9.2199,
                "lng": 3.2005
            },
            "Koko": {
                "lat": 11.4232,
                "lng": 4.517
            },
            "Zungeru": {
                "lat": 9.8128,
                "lng": 6.1558
            },
            "Tegina": {
                "lat": 10.0706,
                "lng": 6.1906
            },
            "Jebba": {
                "lat": 9.1539,
                "lng": 4.8119
            },
            "Aiyetoro Gbede": {
                "lat": 7.9833,
                "lng": 6.0
            },
            "Amawom": {
                "lat": 5.4667,
                "lng": 7.5667
            },
            "Orlu": {
                "lat": 5.7837,
                "lng": 7.0333
            },
            "Abakaliki": {
                "lat": 6.3249,
                "lng": 8.1137
            },
            "Yenagoa": {
                "lat": 4.9267,
                "lng": 6.2676
            },
            "Isemi-Ile": {
                "lat": 7.97,
                "lng": 3.59
            }
        }
    },
    "TR": {
        "name": "Turkey",
        "cities": {
            "Istanbul": {
                "lat": 41.0136,
                "lng": 28.955
            },
            "Ankara": {
                "lat": 39.93,
                "lng": 32.85
            },
            "\u0130zmir": {
                "lat": 38.42,
                "lng": 27.14
            },
            "Bursa": {
                "lat": 40.1833,
                "lng": 29.05
            },
            "Konya": {
                "lat": 37.8667,
                "lng": 32.4833
            },
            "Mersin": {
                "lat": 36.8,
                "lng": 34.6333
            },
            "Adana": {
                "lat": 37.0,
                "lng": 35.3213
            },
            "Diyarbak\u0131r": {
                "lat": 37.91,
                "lng": 40.24
            },
            "Kayseri": {
                "lat": 38.7225,
                "lng": 35.4875
            },
            "Samsun": {
                "lat": 41.2903,
                "lng": 36.3336
            },
            "Bal\u0131kesir": {
                "lat": 39.6333,
                "lng": 27.8833
            },
            "Denizli": {
                "lat": 37.7833,
                "lng": 29.0964
            },
            "\u00c7ankaya": {
                "lat": 39.9244,
                "lng": 32.8856
            },
            "Ke\u00e7i\u00f6ren": {
                "lat": 40.0,
                "lng": 32.8667
            },
            "Osmangazi": {
                "lat": 40.1983,
                "lng": 29.06
            },
            "Esenyurt": {
                "lat": 41.0342,
                "lng": 28.68
            },
            "Eski\u015fehir": {
                "lat": 39.7767,
                "lng": 30.5206
            },
            "Seyhan": {
                "lat": 36.9831,
                "lng": 35.3328
            },
            "Erzurum": {
                "lat": 39.9086,
                "lng": 41.2769
            },
            "Trabzon": {
                "lat": 41.005,
                "lng": 39.7225
            },
            "Ba\u011fc\u0131lar": {
                "lat": 41.0344,
                "lng": 28.8564
            },
            "Umraniye": {
                "lat": 41.0311,
                "lng": 29.1081
            },
            "Yenimahalle": {
                "lat": 39.9719,
                "lng": 32.8119
            },
            "Pendik": {
                "lat": 40.8747,
                "lng": 29.235
            },
            "Mamak": {
                "lat": 39.9422,
                "lng": 32.9231
            },
            "Sel\u00e7uklu": {
                "lat": 37.8814,
                "lng": 32.4922
            },
            "Y\u0131ld\u0131r\u0131m": {
                "lat": 40.11,
                "lng": 29.0821
            },
            "Etimesgut": {
                "lat": 39.9458,
                "lng": 32.6694
            },
            "Yukar\u0131karaman": {
                "lat": 36.9167,
                "lng": 30.6833
            },
            "Melikgazi": {
                "lat": 38.7167,
                "lng": 35.5
            },
            "Sincan": {
                "lat": 39.9594,
                "lng": 32.5767
            },
            "Nil\u00fcfer": {
                "lat": 40.2833,
                "lng": 28.95
            },
            "Buca": {
                "lat": 38.35,
                "lng": 27.1667
            },
            "\u015eanl\u0131urfa": {
                "lat": 37.1583,
                "lng": 38.7917
            },
            "K\u00fc\u00e7\u00fck\u00e7ekmece": {
                "lat": 41.0,
                "lng": 28.8
            },
            "Kartal": {
                "lat": 40.8872,
                "lng": 29.19
            },
            "Bornova": {
                "lat": 38.4697,
                "lng": 27.2211
            },
            "Demiryol": {
                "lat": 37.887,
                "lng": 41.132
            },
            "Erk\u00f6kl\u00fc": {
                "lat": 37.887,
                "lng": 41.132
            },
            "Batman": {
                "lat": 37.887,
                "lng": 41.132
            },
            "Esenler": {
                "lat": 41.0339,
                "lng": 28.8903
            },
            "Kahramanmara\u015f": {
                "lat": 37.5833,
                "lng": 36.9333
            },
            "K\u00e2\u011f\u0131thane": {
                "lat": 41.0719,
                "lng": 28.9664
            },
            "Avc\u0131lar": {
                "lat": 40.9792,
                "lng": 28.7214
            },
            "Malatya": {
                "lat": 38.3486,
                "lng": 38.3194
            },
            "Elaz\u0131\u011f": {
                "lat": 38.6744,
                "lng": 39.2228
            },
            "Alt\u0131nda\u011f": {
                "lat": 39.9636,
                "lng": 32.9033
            },
            "Kocasinan": {
                "lat": 38.7333,
                "lng": 35.4833
            },
            "Ba\u011flar": {
                "lat": 37.9167,
                "lng": 40.1833
            },
            "Y\u00fcre\u011fir": {
                "lat": 36.9981,
                "lng": 35.3439
            },
            "Manisa": {
                "lat": 38.6144,
                "lng": 27.4292
            },
            "Hatay": {
                "lat": 36.2025,
                "lng": 36.1606
            },
            "Gaziantep": {
                "lat": 37.0628,
                "lng": 37.3792
            },
            "Gebze": {
                "lat": 40.8,
                "lng": 29.4333
            },
            "Sivas": {
                "lat": 39.75,
                "lng": 37.0167
            },
            "Alanya": {
                "lat": 36.55,
                "lng": 32.0
            },
            "Kocaeli": {
                "lat": 40.7625,
                "lng": 29.9175
            },
            "Kayap\u0131nar": {
                "lat": 37.95,
                "lng": 40.1667
            },
            "Konak": {
                "lat": 38.4189,
                "lng": 27.1285
            },
            "Van": {
                "lat": 38.4942,
                "lng": 43.38
            },
            "Pamukkale": {
                "lat": 37.9167,
                "lng": 29.1167
            },
            "Kar\u015f\u0131yaka": {
                "lat": 38.4594,
                "lng": 27.1153
            },
            "Sar\u0131yer": {
                "lat": 41.1669,
                "lng": 29.0572
            },
            "Meram": {
                "lat": 37.8364,
                "lng": 32.4383
            },
            "Beylikd\u00fcz\u00fc": {
                "lat": 41.0011,
                "lng": 28.6419
            },
            "Sultanbeyli": {
                "lat": 40.9683,
                "lng": 29.2617
            },
            "Ye\u015filyurt": {
                "lat": 40.0,
                "lng": 36.22
            },
            "Afyonkarahisar": {
                "lat": 38.7578,
                "lng": 30.5386
            },
            "Battalgazi": {
                "lat": 38.4228,
                "lng": 38.3656
            },
            "G\u00fcng\u00f6ren": {
                "lat": 41.0225,
                "lng": 28.8717
            },
            "Sakarya": {
                "lat": 40.7833,
                "lng": 30.4
            },
            "\u0130neg\u00f6l": {
                "lat": 40.0806,
                "lng": 29.5097
            },
            "\u00c7orlu": {
                "lat": 41.15,
                "lng": 27.8
            },
            "Arnavutk\u00f6y": {
                "lat": 41.1856,
                "lng": 28.7406
            },
            "Bayat": {
                "lat": 40.646,
                "lng": 34.261
            },
            "Ad\u0131yaman": {
                "lat": 37.7639,
                "lng": 38.2778
            },
            "K\u00fctahya": {
                "lat": 39.4167,
                "lng": 29.9833
            },
            "Osmaniye": {
                "lat": 37.075,
                "lng": 36.25
            },
            "Ayd\u0131n": {
                "lat": 37.8481,
                "lng": 27.8453
            },
            "Isparta": {
                "lat": 37.7647,
                "lng": 30.5567
            },
            "Siverek": {
                "lat": 37.75,
                "lng": 39.3167
            },
            "Antalya": {
                "lat": 36.8874,
                "lng": 30.7075
            },
            "Tuzla": {
                "lat": 40.8161,
                "lng": 29.3006
            },
            "Aksaray": {
                "lat": 38.3742,
                "lng": 34.0289
            },
            "K\u0131z\u0131ltepe": {
                "lat": 37.1939,
                "lng": 40.5861
            },
            "\u00c7ekme": {
                "lat": 41.0369,
                "lng": 29.1786
            },
            "\u0130skenderun": {
                "lat": 36.5817,
                "lng": 36.165
            },
            "B\u00fcy\u00fck\u00e7ekmece": {
                "lat": 41.02,
                "lng": 28.5775
            },
            "Beykoz": {
                "lat": 41.1342,
                "lng": 29.0922
            },
            "D\u00fczce": {
                "lat": 40.8417,
                "lng": 31.1583
            },
            "Tarsus": {
                "lat": 36.9165,
                "lng": 34.8951
            },
            "Ordu": {
                "lat": 40.9833,
                "lng": 37.8833
            },
            "U\u015fak": {
                "lat": 38.6778,
                "lng": 29.4042
            },
            "Mezitli": {
                "lat": 36.75,
                "lng": 34.5333
            },
            "Sar\u0131\u00e7am": {
                "lat": 37.0333,
                "lng": 35.4167
            },
            "Yeni\u015fehir": {
                "lat": 40.2644,
                "lng": 29.6531
            },
            "\u00c7i\u011fli": {
                "lat": 38.4833,
                "lng": 27.05
            },
            "Tekirda\u011f": {
                "lat": 40.9778,
                "lng": 27.5153
            },
            "Menemen": {
                "lat": 38.6,
                "lng": 27.0667
            },
            "Viran\u015fehir": {
                "lat": 37.2306,
                "lng": 39.7653
            },
            "Karak\u00f6pr\u00fc": {
                "lat": 37.1847,
                "lng": 38.7908
            },
            "Mu\u015f": {
                "lat": 38.7333,
                "lng": 41.4911
            },
            "K\u0131r\u0131kkale": {
                "lat": 39.8417,
                "lng": 33.5139
            },
            "Bolu": {
                "lat": 40.7347,
                "lng": 31.6075
            },
            "Edirne": {
                "lat": 41.6769,
                "lng": 26.5556
            },
            "Torbal\u0131": {
                "lat": 38.1619,
                "lng": 27.3583
            },
            "Bodrum": {
                "lat": 37.0378,
                "lng": 27.4242
            },
            "Akhisar": {
                "lat": 38.9239,
                "lng": 27.84
            },
            "Erci\u015f": {
                "lat": 39.0311,
                "lng": 43.3597
            },
            "Talas": {
                "lat": 38.6833,
                "lng": 35.5667
            },
            "Karaman": {
                "lat": 37.1819,
                "lng": 33.2181
            },
            "Fethiye": {
                "lat": 36.6514,
                "lng": 29.1231
            },
            "\u00c7erkezk\u00f6y": {
                "lat": 41.2889,
                "lng": 28.0042
            },
            "Siirt": {
                "lat": 37.925,
                "lng": 41.9458
            },
            "K\u00f6rfez": {
                "lat": 40.7833,
                "lng": 29.7333
            },
            "Manavgat": {
                "lat": 36.7833,
                "lng": 31.4333
            },
            "Tokat": {
                "lat": 40.3139,
                "lng": 36.5542
            },
            "Turgutlu": {
                "lat": 38.5,
                "lng": 27.7
            },
            "Nazilli": {
                "lat": 37.9125,
                "lng": 28.3206
            },
            "G\u00f6lc\u00fck": {
                "lat": 40.7172,
                "lng": 29.8228
            },
            "Salihli": {
                "lat": 38.4811,
                "lng": 28.1392
            },
            "Bing\u00f6l": {
                "lat": 38.8861,
                "lng": 40.5017
            },
            "Ceyhan": {
                "lat": 37.0289,
                "lng": 35.8125
            },
            "Erzincan": {
                "lat": 39.7464,
                "lng": 39.4914
            },
            "Edremit": {
                "lat": 38.4242,
                "lng": 43.2561
            },
            "Band\u0131rma": {
                "lat": 40.35,
                "lng": 27.9667
            },
            "K\u0131r\u015fehir": {
                "lat": 39.1456,
                "lng": 34.1608
            },
            "Nev\u015fehir": {
                "lat": 38.6264,
                "lng": 34.7139
            },
            "Erdemli": {
                "lat": 36.6056,
                "lng": 34.3111
            },
            "Mahmutlu": {
                "lat": 39.7464,
                "lng": 39.4914
            },
            "B\u00fcy\u00fck \u00c7ak\u0131rman": {
                "lat": 39.7464,
                "lng": 39.4914
            },
            "Bu\u011fdayl\u0131": {
                "lat": 39.7464,
                "lng": 39.4914
            },
            "G\u00f6lba\u015f\u0131": {
                "lat": 37.7839,
                "lng": 37.6444
            },
            "A\u011fr\u0131": {
                "lat": 39.7186,
                "lng": 43.0508
            },
            "Kastamonu": {
                "lat": 41.3764,
                "lng": 33.7764
            },
            "L\u00fcleburgaz": {
                "lat": 41.4056,
                "lng": 27.3569
            },
            "Milas": {
                "lat": 37.3167,
                "lng": 27.7833
            },
            "Derince": {
                "lat": 40.7539,
                "lng": 29.8231
            },
            "Ere\u011fli": {
                "lat": 41.2792,
                "lng": 31.4208
            },
            "Cizre": {
                "lat": 37.332,
                "lng": 42.187
            },
            "Nizip": {
                "lat": 37.01,
                "lng": 37.7972
            },
            "Bafra": {
                "lat": 41.5722,
                "lng": 35.9147
            },
            "Elbistan": {
                "lat": 38.2014,
                "lng": 37.1883
            },
            "Rize": {
                "lat": 41.0247,
                "lng": 40.5222
            },
            "\u00c7ar\u015famba": {
                "lat": 41.1992,
                "lng": 36.7275
            },
            "Silivri": {
                "lat": 41.0736,
                "lng": 28.2478
            },
            "Gaziemir": {
                "lat": 38.3214,
                "lng": 27.1275
            },
            "I\u011fd\u0131r": {
                "lat": 39.9208,
                "lng": 44.0444
            },
            "Yalova": {
                "lat": 40.6556,
                "lng": 29.275
            },
            "Silopi": {
                "lat": 37.2486,
                "lng": 42.4703
            },
            "\u00d6demi\u015f": {
                "lat": 38.2311,
                "lng": 27.9719
            },
            "Karab\u00fck": {
                "lat": 41.1986,
                "lng": 32.6264
            },
            "Ku\u015fadas\u0131": {
                "lat": 37.8597,
                "lng": 27.2597
            },
            "Kozan": {
                "lat": 37.45,
                "lng": 35.8
            },
            "Ergani": {
                "lat": 38.2692,
                "lng": 39.7617
            },
            "\u00c7ay\u0131rova": {
                "lat": 40.8,
                "lng": 29.4167
            },
            "D\u00f6rtyol": {
                "lat": 36.8428,
                "lng": 36.2244
            },
            "\u00dcnye": {
                "lat": 41.1272,
                "lng": 37.2881
            },
            "Karatepe": {
                "lat": 40.7333,
                "lng": 29.9667
            },
            "Giresun": {
                "lat": 40.9153,
                "lng": 38.3894
            },
            "Polatl\u0131": {
                "lat": 39.5842,
                "lng": 32.1472
            },
            "Serik": {
                "lat": 36.9167,
                "lng": 31.1
            },
            "Kadirli": {
                "lat": 37.3697,
                "lng": 36.1
            },
            "Ak\u00e7aabat": {
                "lat": 41.0167,
                "lng": 39.55
            },
            "Patnos": {
                "lat": 39.2358,
                "lng": 42.8686
            },
            "K\u00e2hta": {
                "lat": 37.7803,
                "lng": 38.6217
            },
            "Do\u011fubayaz\u0131t": {
                "lat": 39.5472,
                "lng": 44.0833
            },
            "Samanda\u011f": {
                "lat": 36.085,
                "lng": 35.9806
            },
            "K\u0131r\u0131khan": {
                "lat": 36.4975,
                "lng": 36.3608
            },
            "Gemlik": {
                "lat": 40.4317,
                "lng": 29.1561
            },
            "S\u00f6ke": {
                "lat": 37.7508,
                "lng": 27.4103
            },
            "Y\u00fcksekova": {
                "lat": 37.569,
                "lng": 44.283
            },
            "Silifke": {
                "lat": 36.3761,
                "lng": 33.9322
            },
            "Bismil": {
                "lat": 37.8467,
                "lng": 40.6661
            },
            "Ni\u011fde": {
                "lat": 37.9667,
                "lng": 34.6792
            },
            "Kapakl\u0131": {
                "lat": 41.3333,
                "lng": 27.9667
            },
            "Fatsa": {
                "lat": 41.0306,
                "lng": 37.5
            },
            "Nusaybin": {
                "lat": 37.075,
                "lng": 41.2153
            },
            "Amasya": {
                "lat": 40.65,
                "lng": 35.8331
            },
            "Midyat": {
                "lat": 37.4167,
                "lng": 41.3697
            },
            "Ak\u00e7akale": {
                "lat": 36.7108,
                "lng": 38.9478
            },
            "Soma": {
                "lat": 39.1883,
                "lng": 27.6089
            },
            "Mudanya": {
                "lat": 40.3764,
                "lng": 28.8833
            },
            "Kemalpa\u015fa": {
                "lat": 38.4278,
                "lng": 27.4161
            },
            "Yozgat": {
                "lat": 39.8208,
                "lng": 34.8083
            },
            "Ala\u015fehir": {
                "lat": 38.35,
                "lng": 28.5167
            },
            "Suru\u00e7": {
                "lat": 36.9764,
                "lng": 38.4269
            },
            "Bergama": {
                "lat": 39.1167,
                "lng": 27.1833
            },
            "G\u00fcrsu": {
                "lat": 40.2144,
                "lng": 29.1931
            },
            "Mustafakemalpa\u015fa": {
                "lat": 40.0353,
                "lng": 28.4117
            },
            "Tav\u015fanl\u0131": {
                "lat": 39.5333,
                "lng": 29.4833
            },
            "Zonguldak": {
                "lat": 41.4564,
                "lng": 31.7986
            },
            "Sur": {
                "lat": 37.9106,
                "lng": 40.2358
            },
            "Reyhanl\u0131": {
                "lat": 36.2681,
                "lng": 36.5675
            },
            "Marmaris": {
                "lat": 36.85,
                "lng": 28.2667
            },
            "A\u015fa\u011f\u0131\u00e7inik": {
                "lat": 41.2719,
                "lng": 36.3508
            },
            "Didim": {
                "lat": 37.3756,
                "lng": 27.2678
            },
            "Harran": {
                "lat": 36.8708,
                "lng": 39.025
            },
            "\u00c7ubuk": {
                "lat": 40.2386,
                "lng": 33.0331
            },
            "Burdur": {
                "lat": 37.7194,
                "lng": 30.2833
            },
            "Alia\u011fa": {
                "lat": 38.8008,
                "lng": 26.9728
            },
            "Erbaa": {
                "lat": 40.6744,
                "lng": 36.5681
            },
            "Birecik": {
                "lat": 37.025,
                "lng": 37.9769
            },
            "Menderes": {
                "lat": 38.254,
                "lng": 27.134
            },
            "Ak\u015fehir": {
                "lat": 38.3575,
                "lng": 31.4164
            },
            "Tatvan": {
                "lat": 38.5022,
                "lng": 42.2814
            },
            "\u00c7aycuma": {
                "lat": 41.4267,
                "lng": 32.0758
            },
            "\u00c7anakkale": {
                "lat": 40.1519,
                "lng": 26.4056
            },
            "Biga": {
                "lat": 40.2281,
                "lng": 27.2422
            },
            "Arsuz": {
                "lat": 36.4128,
                "lng": 35.8867
            },
            "Vezirk\u00f6pr\u00fc": {
                "lat": 41.1431,
                "lng": 35.46
            },
            "Hendek": {
                "lat": 40.805,
                "lng": 30.7492
            },
            "Akyaz\u0131": {
                "lat": 40.6833,
                "lng": 30.6253
            },
            "Ceylanp\u0131nar": {
                "lat": 36.8461,
                "lng": 40.0489
            },
            "Mardin": {
                "lat": 37.3131,
                "lng": 40.735
            },
            "Silvan": {
                "lat": 38.1422,
                "lng": 41.0014
            },
            "Karacabey": {
                "lat": 40.2167,
                "lng": 28.35
            },
            "Tire": {
                "lat": 38.0833,
                "lng": 27.7333
            },
            "Ke\u015fan": {
                "lat": 40.85,
                "lng": 26.6333
            },
            "Bilecik": {
                "lat": 40.1431,
                "lng": 29.9792
            },
            "Y\u0131ld\u0131z": {
                "lat": 41.6344,
                "lng": 32.3375
            },
            "G\u00fcrgenp\u0131nar\u0131": {
                "lat": 41.6344,
                "lng": 32.3375
            },
            "Uzung\u00f6z": {
                "lat": 41.6344,
                "lng": 32.3375
            },
            "Af\u015fin": {
                "lat": 38.2464,
                "lng": 36.9186
            },
            "Orhangazi": {
                "lat": 40.4889,
                "lng": 29.3083
            },
            "Bal\u00e7ova": {
                "lat": 38.3856,
                "lng": 27.0581
            },
            "Bulan\u0131k": {
                "lat": 39.095,
                "lng": 42.2667
            },
            "Turhal": {
                "lat": 40.39,
                "lng": 36.09
            },
            "K\u0131rklareli": {
                "lat": 41.7347,
                "lng": 27.2253
            },
            "D\u00f6\u015femealt\u0131": {
                "lat": 37.0236,
                "lng": 30.6011
            },
            "Sorgun": {
                "lat": 39.8144,
                "lng": 35.1903
            },
            "Aksu": {
                "lat": 36.95,
                "lng": 30.85
            },
            "\u0130dil": {
                "lat": 37.341,
                "lng": 41.894
            },
            "G\u00f6nen": {
                "lat": 40.1014,
                "lng": 27.6514
            },
            "Boz\u00fcy\u00fck": {
                "lat": 39.9078,
                "lng": 30.0367
            },
            "\u00c7\u0131nar": {
                "lat": 37.7242,
                "lng": 40.415
            },
            "T\u00fcrko\u011flu": {
                "lat": 37.3914,
                "lng": 36.8522
            },
            "Bey\u015fehir": {
                "lat": 37.6764,
                "lng": 31.7261
            },
            "Kumluca": {
                "lat": 36.3667,
                "lng": 30.2833
            },
            "\u00c7atalca": {
                "lat": 41.1417,
                "lng": 28.4631
            },
            "Merzifon": {
                "lat": 40.875,
                "lng": 35.4633
            },
            "Bart\u0131n": {
                "lat": 41.6344,
                "lng": 32.3375
            },
            "Terme": {
                "lat": 41.2092,
                "lng": 36.9739
            },
            "Ayval\u0131k": {
                "lat": 39.3167,
                "lng": 26.6944
            },
            "Karasu": {
                "lat": 41.1036,
                "lng": 30.6936
            },
            "Pazarc\u0131k": {
                "lat": 37.4894,
                "lng": 37.2967
            },
            "Kars": {
                "lat": 40.6078,
                "lng": 43.0958
            },
            "\u0130slahiye": {
                "lat": 37.025,
                "lng": 36.6345
            },
            "\u00c7umra": {
                "lat": 37.575,
                "lng": 32.7747
            },
            "Bulancak": {
                "lat": 40.9333,
                "lng": 38.2333
            },
            "Urla": {
                "lat": 38.3222,
                "lng": 26.7647
            },
            "Anamur": {
                "lat": 36.0243,
                "lng": 32.8026
            },
            "Develi": {
                "lat": 38.3886,
                "lng": 35.4925
            },
            "Bucak": {
                "lat": 37.4592,
                "lng": 30.5953
            },
            "Seydi\u015fehir": {
                "lat": 37.4183,
                "lng": 31.8506
            },
            "Niksar": {
                "lat": 40.5917,
                "lng": 36.9517
            },
            "Simav": {
                "lat": 39.0833,
                "lng": 28.9833
            },
            "\u00c7ald\u0131ran": {
                "lat": 39.1419,
                "lng": 43.9139
            },
            "Narl\u0131dere": {
                "lat": 38.3911,
                "lng": 27.0028
            },
            "Mut": {
                "lat": 36.6458,
                "lng": 33.4375
            },
            "Kilis": {
                "lat": 36.7167,
                "lng": 37.1167
            },
            "Derik": {
                "lat": 37.3658,
                "lng": 40.2697
            },
            "Uzunk\u00f6pr\u00fc": {
                "lat": 41.2667,
                "lng": 26.6833
            },
            "Kozluk": {
                "lat": 38.1944,
                "lng": 41.4911
            },
            "Burhaniye": {
                "lat": 39.5,
                "lng": 26.95
            },
            "Alt\u0131n\u00f6z\u00fc": {
                "lat": 36.1,
                "lng": 36.23
            },
            "Bor": {
                "lat": 37.8964,
                "lng": 34.5628
            },
            "\u00c7ivril": {
                "lat": 38.3014,
                "lng": 29.7386
            },
            "\u00d6zalp": {
                "lat": 38.6583,
                "lng": 43.9894
            },
            "Karam\u00fcrsel": {
                "lat": 40.6892,
                "lng": 29.615
            },
            "Hakkari": {
                "lat": 37.577,
                "lng": 43.739
            },
            "Ka\u015f": {
                "lat": 36.202,
                "lng": 29.638
            },
            "Sinop": {
                "lat": 42.0267,
                "lng": 35.1511
            },
            "G\u00fcm\u00fc\u015fhane": {
                "lat": 40.4597,
                "lng": 39.4778
            },
            "Devrek": {
                "lat": 41.2333,
                "lng": 31.9667
            },
            "D\u00fczi\u00e7i": {
                "lat": 37.2431,
                "lng": 36.4567
            },
            "Mu\u011fla": {
                "lat": 37.2167,
                "lng": 28.3667
            },
            "Hassa": {
                "lat": 36.7994,
                "lng": 36.5178
            },
            "Korkuteli": {
                "lat": 37.0667,
                "lng": 30.2
            },
            "Zile": {
                "lat": 40.3,
                "lng": 35.8833
            },
            "\u015e\u0131rnak": {
                "lat": 37.52,
                "lng": 42.459
            },
            "Ac\u0131payam": {
                "lat": 37.425,
                "lng": 29.3539
            },
            "Saruhanl\u0131": {
                "lat": 38.7342,
                "lng": 27.5658
            },
            "Ortaca": {
                "lat": 36.8389,
                "lng": 28.7644
            },
            "K\u0131z\u0131lp\u0131nar": {
                "lat": 41.2667,
                "lng": 27.9667
            },
            "Gazipa\u015fa": {
                "lat": 36.2728,
                "lng": 32.3131
            },
            "Ilg\u0131n": {
                "lat": 38.2792,
                "lng": 31.9139
            },
            "Dilovas\u0131": {
                "lat": 40.7797,
                "lng": 29.535
            },
            "Mahmutlar": {
                "lat": 36.4928,
                "lng": 32.0992
            },
            "Safranbolu": {
                "lat": 41.2494,
                "lng": 32.6833
            },
            "Kand\u0131ra": {
                "lat": 41.0722,
                "lng": 30.1611
            },
            "Malkara": {
                "lat": 40.8933,
                "lng": 26.9042
            },
            "Bozova": {
                "lat": 37.3628,
                "lng": 38.5256
            },
            "Ba\u015fkale": {
                "lat": 38.0475,
                "lng": 44.015
            },
            "Cihanbeyli": {
                "lat": 38.6581,
                "lng": 32.9281
            },
            "Tekkek\u00f6y": {
                "lat": 41.2125,
                "lng": 36.4569
            },
            "Bitlis": {
                "lat": 38.4,
                "lng": 42.1083
            },
            "\u0130ncirliova": {
                "lat": 37.8547,
                "lng": 27.7236
            },
            "\u00c7ermik": {
                "lat": 38.1372,
                "lng": 39.4511
            },
            "Geyve": {
                "lat": 40.5089,
                "lng": 30.29
            },
            "G\u00f6ksun": {
                "lat": 38.0211,
                "lng": 36.4917
            },
            "Bigadi\u00e7": {
                "lat": 39.3925,
                "lng": 28.1311
            },
            "Gediz": {
                "lat": 38.9939,
                "lng": 29.3914
            },
            "Karap\u0131nar": {
                "lat": 37.7147,
                "lng": 33.5508
            },
            "\u00c7ine": {
                "lat": 37.6117,
                "lng": 28.0614
            },
            "Arakl\u0131": {
                "lat": 40.9333,
                "lng": 40.05
            },
            "Arifiye": {
                "lat": 40.7167,
                "lng": 30.3667
            },
            "Saray": {
                "lat": 38.6506,
                "lng": 44.17
            },
            "Sungurlu": {
                "lat": 40.161,
                "lng": 34.377
            },
            "Babaeski": {
                "lat": 41.4325,
                "lng": 27.0931
            },
            "Kozlu": {
                "lat": 41.4333,
                "lng": 31.75
            },
            "Beypazar\u0131": {
                "lat": 40.1703,
                "lng": 31.9211
            },
            "\u00c7an": {
                "lat": 40.0275,
                "lng": 27.0475
            },
            "Finike": {
                "lat": 36.3,
                "lng": 30.15
            },
            "G\u00fcroymak": {
                "lat": 38.5769,
                "lng": 42.0211
            },
            "Dalaman": {
                "lat": 36.7667,
                "lng": 28.8
            },
            "Yalva\u00e7": {
                "lat": 38.2956,
                "lng": 31.18
            },
            "Suluova": {
                "lat": 40.8333,
                "lng": 35.65
            },
            "Haymana": {
                "lat": 39.4311,
                "lng": 32.4956
            },
            "Muradiye": {
                "lat": 38.9947,
                "lng": 43.7681
            },
            "Yata\u011fan": {
                "lat": 37.3425,
                "lng": 28.1394
            },
            "Sapanca": {
                "lat": 40.6906,
                "lng": 30.2703
            },
            "Seferhisar": {
                "lat": 38.2,
                "lng": 26.8333
            },
            "Elmada\u011f": {
                "lat": 39.9208,
                "lng": 33.2308
            },
            "\u00c7ayeli": {
                "lat": 40.75,
                "lng": 41.0667
            },
            "Alapl\u0131": {
                "lat": 41.1806,
                "lng": 31.3861
            },
            "\u0130znik": {
                "lat": 40.4292,
                "lng": 29.7211
            },
            "Dikili": {
                "lat": 39.0667,
                "lng": 26.8833
            },
            "Germencik": {
                "lat": 37.8697,
                "lng": 27.6058
            },
            "Boyabat": {
                "lat": 41.4653,
                "lng": 34.7708
            },
            "Kiraz": {
                "lat": 38.2306,
                "lng": 28.2044
            },
            "Of": {
                "lat": 40.945,
                "lng": 40.2644
            },
            "\u00c7e\u015fme": {
                "lat": 38.3236,
                "lng": 26.3028
            },
            "Bolvadin": {
                "lat": 38.7167,
                "lng": 31.05
            },
            "Kula": {
                "lat": 38.5458,
                "lng": 28.6494
            },
            "Akda\u011fmadeni": {
                "lat": 39.6658,
                "lng": 35.8836
            },
            "Sar\u0131kam\u0131\u015f": {
                "lat": 40.3381,
                "lng": 42.5731
            },
            "Hilvan": {
                "lat": 37.5886,
                "lng": 38.9556
            },
            "Diyadin": {
                "lat": 39.54,
                "lng": 43.6772
            },
            "Tavas": {
                "lat": 37.5728,
                "lng": 29.0714
            },
            "Sinanpa\u015fa": {
                "lat": 38.75,
                "lng": 30.25
            },
            "Yakac\u0131k": {
                "lat": 40.9164,
                "lng": 29.2222
            },
            "Erzin": {
                "lat": 36.9533,
                "lng": 36.2033
            },
            "Nurda\u011f\u0131": {
                "lat": 37.1789,
                "lng": 36.7397
            },
            "Halfeti": {
                "lat": 37.2289,
                "lng": 37.9457
            },
            "Arde\u015fen": {
                "lat": 41.19,
                "lng": 40.9831
            },
            "Ahlat": {
                "lat": 38.7528,
                "lng": 42.4944
            },
            "Elmal\u0131": {
                "lat": 36.7333,
                "lng": 29.9
            },
            "Akyurt": {
                "lat": 40.1333,
                "lng": 33.0833
            },
            "Evren": {
                "lat": 39.0167,
                "lng": 33.8
            },
            "Bay\u0131nd\u0131r": {
                "lat": 38.2192,
                "lng": 27.6481
            },
            "Bayburt": {
                "lat": 40.2597,
                "lng": 40.2278
            },
            "Tosya": {
                "lat": 41.0172,
                "lng": 34.0383
            },
            "H\u0131d\u0131rbey": {
                "lat": 36.203,
                "lng": 36.129
            },
            "Havza": {
                "lat": 40.9667,
                "lng": 35.6667
            },
            "K\u0131rka\u011fa\u00e7": {
                "lat": 39.1056,
                "lng": 27.6733
            },
            "Yomra": {
                "lat": 40.9589,
                "lng": 39.8469
            },
            "Do\u011fan\u015fehir": {
                "lat": 38.0958,
                "lng": 37.8792
            },
            "K\u00f6yce\u011fiz": {
                "lat": 36.9714,
                "lng": 28.6889
            },
            "\u00c7iftlikk\u00f6y": {
                "lat": 40.65,
                "lng": 29.3333
            },
            "Susurluk": {
                "lat": 39.9139,
                "lng": 28.1603
            },
            "Ta\u015fk\u00f6pr\u00fc": {
                "lat": 41.5097,
                "lng": 34.2142
            },
            "Ak\u00e7akoca": {
                "lat": 41.0878,
                "lng": 31.1244
            },
            "Gen\u00e7": {
                "lat": 38.75,
                "lng": 40.556
            },
            "\u015eark\u0131\u015fla": {
                "lat": 39.35,
                "lng": 36.4
            },
            "Dicle": {
                "lat": 38.375,
                "lng": 40.0733
            },
            "Emirda\u011f": {
                "lat": 39.0167,
                "lng": 31.15
            },
            "G\u00fczelbah\u00e7e": {
                "lat": 38.3356,
                "lng": 26.8911
            },
            "Sel\u00e7uk": {
                "lat": 37.95,
                "lng": 27.3667
            },
            "Besni": {
                "lat": 37.6942,
                "lng": 37.8614
            },
            "Kaman": {
                "lat": 39.3575,
                "lng": 33.7239
            },
            "\u015euhut": {
                "lat": 38.5333,
                "lng": 30.55
            },
            "Kulp": {
                "lat": 38.6642,
                "lng": 41.0067
            },
            "Demirci": {
                "lat": 39.045,
                "lng": 28.6581
            },
            "Horasan": {
                "lat": 40.0397,
                "lng": 42.1711
            },
            "\u015eile": {
                "lat": 41.1764,
                "lng": 29.6128
            },
            "Maz\u0131da\u011f\u0131": {
                "lat": 37.4792,
                "lng": 40.4864
            },
            "Banaz": {
                "lat": 38.7333,
                "lng": 29.75
            },
            "Yahyal\u0131": {
                "lat": 38.1,
                "lng": 35.3608
            },
            "Kurtalan": {
                "lat": 37.9261,
                "lng": 41.7003
            },
            "Sar\u0131g\u00f6l": {
                "lat": 38.2381,
                "lng": 28.6961
            },
            "G\u00f6r\u00fckle": {
                "lat": 40.2333,
                "lng": 28.8333
            },
            "G\u00fcrp\u0131nar": {
                "lat": 38.3269,
                "lng": 43.4133
            },
            "Yerk\u00f6y": {
                "lat": 39.6381,
                "lng": 34.4672
            },
            "Yaylada\u011f\u0131": {
                "lat": 35.9025,
                "lng": 36.0606
            },
            "Hamidiye": {
                "lat": 40.0997,
                "lng": 26.3967
            },
            "Ak\u00e7ada\u011f": {
                "lat": 38.345,
                "lng": 37.9686
            },
            "\u00dcrg\u00fcp": {
                "lat": 38.6314,
                "lng": 34.9131
            },
            "Kilimli": {
                "lat": 41.4833,
                "lng": 31.8333
            },
            "Aktuluk": {
                "lat": 39.1064,
                "lng": 39.5472
            },
            "Fo\u00e7a": {
                "lat": 38.6667,
                "lng": 26.7667
            },
            "E\u015fme": {
                "lat": 38.4,
                "lng": 28.9667
            },
            "Arsin": {
                "lat": 40.95,
                "lng": 39.9333
            },
            "\u00c7orum": {
                "lat": 40.5455,
                "lng": 34.957
            },
            "Espiye": {
                "lat": 40.95,
                "lng": 38.7333
            },
            "\u015eerefliko\u00e7hisar": {
                "lat": 38.9444,
                "lng": 33.5419
            },
            "Bo\u011fazl\u0131yan": {
                "lat": 39.1942,
                "lng": 35.2472
            },
            "\u00c7\u0131narc\u0131k": {
                "lat": 40.6333,
                "lng": 29.1167
            },
            "Honaz": {
                "lat": 37.7578,
                "lng": 29.2642
            },
            "S\u0131nd\u0131rg\u0131": {
                "lat": 39.24,
                "lng": 28.175
            },
            "Bozdo\u011fan": {
                "lat": 37.6728,
                "lng": 28.3103
            },
            "Karaa\u011fa\u00e7": {
                "lat": 36.5667,
                "lng": 36.1333
            },
            "Belen": {
                "lat": 36.4889,
                "lng": 36.1944
            },
            "\u00c7ukur\u00e7ay\u0131r": {
                "lat": 40.9867,
                "lng": 39.7297
            },
            "Dursunbey": {
                "lat": 39.5878,
                "lng": 28.6275
            },
            "Per\u015fembe": {
                "lat": 41.0656,
                "lng": 37.7714
            },
            "And\u0131r\u0131n": {
                "lat": 37.5764,
                "lng": 36.3506
            },
            "Kad\u0131nhan\u0131": {
                "lat": 38.2397,
                "lng": 32.2114
            },
            "Sar\u0131kaya": {
                "lat": 39.4936,
                "lng": 35.3769
            },
            "Araban": {
                "lat": 37.4247,
                "lng": 37.6928
            },
            "Tunceli": {
                "lat": 39.1064,
                "lng": 39.5472
            },
            "O\u011fuzeli": {
                "lat": 36.965,
                "lng": 37.5092
            },
            "\u015eark\u00f6y": {
                "lat": 40.6039,
                "lng": 27.1064
            },
            "Hani": {
                "lat": 38.4136,
                "lng": 40.3925
            },
            "Pervari": {
                "lat": 37.9331,
                "lng": 42.5469
            },
            "Mutki": {
                "lat": 38.4092,
                "lng": 41.9219
            },
            "Tirebolu": {
                "lat": 41.0056,
                "lng": 38.8167
            },
            "Yenice": {
                "lat": 40.0883,
                "lng": 29.4211
            },
            "Erdek": {
                "lat": 40.3986,
                "lng": 27.7931
            },
            "Ortak\u00f6y": {
                "lat": 40.283,
                "lng": 35.267
            },
            "Gallipoli": {
                "lat": 40.4139,
                "lng": 26.6703
            },
            "Y\u0131ld\u0131zeli": {
                "lat": 39.8642,
                "lng": 36.5983
            },
            "Bostani\u00e7i": {
                "lat": 38.5072,
                "lng": 43.4356
            },
            "\u0130vrindi": {
                "lat": 39.5833,
                "lng": 27.4833
            },
            "B\u00fcnyan": {
                "lat": 38.8486,
                "lng": 35.8592
            },
            "\u00c7ay": {
                "lat": 38.5833,
                "lng": 31.0333
            },
            "Yah\u015fihan": {
                "lat": 39.8503,
                "lng": 33.4536
            },
            "Varto": {
                "lat": 39.1731,
                "lng": 41.4558
            },
            "Sarayk\u00f6y": {
                "lat": 37.9264,
                "lng": 28.9267
            },
            "Ferizli": {
                "lat": 40.9333,
                "lng": 30.4833
            },
            "Osmanc\u0131k": {
                "lat": 40.9667,
                "lng": 34.8
            },
            "Hayrabolu": {
                "lat": 41.2131,
                "lng": 27.1
            },
            "Pamukova": {
                "lat": 40.5167,
                "lng": 30.1667
            },
            "Be\u015firi": {
                "lat": 37.921,
                "lng": 41.292
            },
            "Ta\u015fova": {
                "lat": 40.75,
                "lng": 36.3167
            },
            "Oltu": {
                "lat": 40.5456,
                "lng": 41.9958
            },
            "Muratl\u0131": {
                "lat": 41.1742,
                "lng": 27.5086
            },
            "K\u0131n\u0131k": {
                "lat": 39.0939,
                "lng": 27.3767
            },
            "Bayrami\u00e7": {
                "lat": 39.8128,
                "lng": 26.61
            },
            "Karako\u00e7an": {
                "lat": 38.95,
                "lng": 40.0333
            },
            "Geva\u015f": {
                "lat": 38.2978,
                "lng": 43.1056
            },
            "\u0130hsaniye": {
                "lat": 39.0333,
                "lng": 30.4167
            },
            "Kumru": {
                "lat": 40.8744,
                "lng": 37.2639
            },
            "Almus": {
                "lat": 40.3748,
                "lng": 36.9031
            },
            "K\u00f6\u015fk": {
                "lat": 37.8533,
                "lng": 28.0517
            },
            "Alt\u0131nova": {
                "lat": 40.6975,
                "lng": 29.5114
            },
            "Vakf\u0131kebir": {
                "lat": 41.0475,
                "lng": 39.2797
            },
            "\u00c7iftlik": {
                "lat": 38.1667,
                "lng": 34.4833
            },
            "Nall\u0131han": {
                "lat": 40.1836,
                "lng": 31.3506
            },
            "G\u00fclnar": {
                "lat": 36.3389,
                "lng": 33.3986
            },
            "Havran": {
                "lat": 39.5583,
                "lng": 27.0983
            },
            "\u0130psala": {
                "lat": 40.9167,
                "lng": 26.3833
            },
            "S\u00fcrmene": {
                "lat": 40.9142,
                "lng": 40.1125
            },
            "Korgan": {
                "lat": 40.8247,
                "lng": 37.3467
            },
            "Savur": {
                "lat": 37.5375,
                "lng": 40.8892
            },
            "Darge\u00e7it": {
                "lat": 37.544,
                "lng": 41.72
            },
            "Harbiye": {
                "lat": 36.145,
                "lng": 36.1411
            },
            "Pasinler": {
                "lat": 39.9797,
                "lng": 41.6756
            },
            "\u0130mamo\u011flu": {
                "lat": 37.2589,
                "lng": 35.6728
            },
            "P\u0131narba\u015f\u0131": {
                "lat": 38.722,
                "lng": 36.391
            },
            "Saray\u00f6n\u00fc": {
                "lat": 38.2661,
                "lng": 32.4064
            },
            "K\u0131z\u0131lcahamam": {
                "lat": 40.4697,
                "lng": 32.6506
            },
            "Bozyaz\u0131": {
                "lat": 36.1,
                "lng": 32.9667
            },
            "Buldan": {
                "lat": 38.045,
                "lng": 28.8306
            },
            "Ma\u00e7ka": {
                "lat": 40.8119,
                "lng": 39.6131
            },
            "G\u00f6rdes": {
                "lat": 38.9328,
                "lng": 28.2903
            },
            "\u0130ncesu": {
                "lat": 38.6222,
                "lng": 35.1847
            },
            "Ondokuzmay\u0131s": {
                "lat": 41.4944,
                "lng": 36.0789
            },
            "Bozk\u0131r": {
                "lat": 37.1886,
                "lng": 32.2456
            },
            "Lice": {
                "lat": 38.45,
                "lng": 40.65
            },
            "Baykan": {
                "lat": 38.1639,
                "lng": 41.7847
            },
            "\u00c7ank\u0131r\u0131": {
                "lat": 40.5986,
                "lng": 33.6192
            },
            "Kuyucak": {
                "lat": 37.9153,
                "lng": 28.4592
            },
            "Tekman": {
                "lat": 39.645,
                "lng": 41.5083
            },
            "G\u00f6lk\u00f6y": {
                "lat": 40.6875,
                "lng": 37.6178
            },
            "Demre": {
                "lat": 36.2472,
                "lng": 29.9828
            },
            "Marmara Ere\u011flisi": {
                "lat": 40.9697,
                "lng": 27.9553
            },
            "Ala\u00e7am": {
                "lat": 41.61,
                "lng": 35.595
            },
            "Artvin": {
                "lat": 41.1833,
                "lng": 41.8181
            },
            "Korkut": {
                "lat": 38.7386,
                "lng": 41.7828
            },
            "Hask\u00f6y": {
                "lat": 38.6822,
                "lng": 41.6894
            },
            "\u015eark\u00eekaraa\u011fa\u00e7": {
                "lat": 38.0803,
                "lng": 31.3658
            },
            "Karayaz\u0131": {
                "lat": 39.7039,
                "lng": 42.1486
            },
            "Bor\u00e7ka": {
                "lat": 41.3636,
                "lng": 41.6792
            },
            "Kocaali": {
                "lat": 41.0547,
                "lng": 30.8503
            },
            "Kovanc\u0131lar": {
                "lat": 38.7217,
                "lng": 39.8672
            },
            "Su\u015fehri": {
                "lat": 40.1658,
                "lng": 38.0942
            },
            "Ula": {
                "lat": 37.1036,
                "lng": 28.4147
            },
            "Denizciler": {
                "lat": 36.65,
                "lng": 36.2167
            },
            "G\u00f6le": {
                "lat": 40.7928,
                "lng": 42.6086
            },
            "Dat\u00e7a": {
                "lat": 36.7283,
                "lng": 27.6869
            },
            "Gemerek": {
                "lat": 39.1819,
                "lng": 36.0681
            },
            "H\u0131n\u0131s": {
                "lat": 39.3586,
                "lng": 41.7003
            },
            "Darende": {
                "lat": 38.5472,
                "lng": 37.5111
            },
            "Tuzluca": {
                "lat": 40.0494,
                "lng": 43.6608
            },
            "Karata\u015f": {
                "lat": 36.5625,
                "lng": 35.3803
            },
            "Kaynarca": {
                "lat": 41.0308,
                "lng": 30.3075
            },
            "Hopa": {
                "lat": 41.3903,
                "lng": 41.4194
            },
            "Palu": {
                "lat": 38.7039,
                "lng": 39.9511
            },
            "Ayanc\u0131k": {
                "lat": 41.95,
                "lng": 34.5833
            },
            "A\u015fkale": {
                "lat": 39.9211,
                "lng": 40.6947
            },
            "Gerede": {
                "lat": 40.8006,
                "lng": 32.1986
            },
            "Akku\u015f": {
                "lat": 40.7931,
                "lng": 37.0164
            },
            "\u00c7a\u011flayancerit": {
                "lat": 37.7506,
                "lng": 37.2931
            },
            "Cide": {
                "lat": 41.8922,
                "lng": 33.0044
            },
            "Pelitli": {
                "lat": 40.9833,
                "lng": 39.8
            },
            "Ardahan": {
                "lat": 41.1111,
                "lng": 42.7022
            },
            "Uluk\u0131\u015fla": {
                "lat": 37.5467,
                "lng": 34.4844
            },
            "Selim": {
                "lat": 40.4633,
                "lng": 42.7858
            },
            "Yunak": {
                "lat": 38.8172,
                "lng": 31.7356
            },
            "Yak\u0131nca": {
                "lat": 38.3,
                "lng": 38.25
            },
            "Tomarza": {
                "lat": 38.4472,
                "lng": 35.7992
            },
            "Aybast\u0131": {
                "lat": 40.6867,
                "lng": 37.3992
            },
            "Be\u015fikd\u00fcz\u00fc": {
                "lat": 41.0494,
                "lng": 39.2353
            },
            "\u015eirvan": {
                "lat": 38.0633,
                "lng": 42.0275
            },
            "G\u00f6lhisar": {
                "lat": 37.1408,
                "lng": 29.5075
            },
            "Digor": {
                "lat": 40.3756,
                "lng": 43.4125
            },
            "Kara\u00e7oban": {
                "lat": 39.3508,
                "lng": 42.1119
            },
            "Bah\u00e7e": {
                "lat": 37.2,
                "lng": 36.5833
            },
            "Yavuzeli": {
                "lat": 37.3192,
                "lng": 37.5686
            },
            "G\u00fcm\u00fc\u015fhac\u0131k\u00f6y": {
                "lat": 40.8667,
                "lng": 35.2167
            },
            "Karaisal\u0131": {
                "lat": 37.2611,
                "lng": 35.06
            },
            "Ko\u00e7arl\u0131": {
                "lat": 37.7608,
                "lng": 27.7061
            },
            "Kepsut": {
                "lat": 39.6889,
                "lng": 28.1522
            },
            "G\u00fcl\u015fehir": {
                "lat": 38.7464,
                "lng": 34.6206
            },
            "Zara": {
                "lat": 39.895,
                "lng": 37.7531
            },
            "Kavak": {
                "lat": 41.0736,
                "lng": 36.0403
            },
            "Kangal": {
                "lat": 39.2367,
                "lng": 37.3886
            },
            "G\u00f6k\u00e7ebey": {
                "lat": 41.3081,
                "lng": 32.1417
            },
            "E\u011fil": {
                "lat": 38.2572,
                "lng": 40.0847
            },
            "Ulus": {
                "lat": 41.5861,
                "lng": 32.6406
            },
            "Kelkit": {
                "lat": 40.1286,
                "lng": 39.4383
            },
            "Aral\u0131k": {
                "lat": 39.8728,
                "lng": 44.5192
            },
            "Ka\u011f\u0131zman": {
                "lat": 40.1583,
                "lng": 43.1342
            },
            "Osmaneli": {
                "lat": 40.35,
                "lng": 30.0167
            },
            "Derinkuyu": {
                "lat": 38.3736,
                "lng": 34.7347
            },
            "Sivasl\u0131": {
                "lat": 38.4986,
                "lng": 29.6814
            },
            "Sivrihisar": {
                "lat": 39.45,
                "lng": 31.5378
            },
            "Pozant\u0131": {
                "lat": 37.4278,
                "lng": 34.8711
            },
            "Arhavi": {
                "lat": 41.3333,
                "lng": 41.3
            },
            "Sultanhisar": {
                "lat": 37.8897,
                "lng": 28.1575
            },
            "Bala": {
                "lat": 39.5539,
                "lng": 33.1211
            },
            "Eruh": {
                "lat": 37.7497,
                "lng": 42.1797
            },
            "Baskil": {
                "lat": 38.5697,
                "lng": 38.8231
            },
            "Ta\u015fl\u0131\u00e7ay": {
                "lat": 39.6333,
                "lng": 43.3778
            },
            "Dereli": {
                "lat": 40.7389,
                "lng": 38.4486
            },
            "Selendi": {
                "lat": 38.7444,
                "lng": 28.8678
            },
            "\u00c7ilimli": {
                "lat": 40.9,
                "lng": 31.05
            },
            "Toprakkale": {
                "lat": 37.0669,
                "lng": 36.1464
            },
            "\u015eiran": {
                "lat": 40.1894,
                "lng": 39.1267
            },
            "Emet": {
                "lat": 39.3333,
                "lng": 29.2667
            },
            "\u00c7ekerek": {
                "lat": 40.0731,
                "lng": 35.4947
            },
            "Dura\u011fan": {
                "lat": 41.4167,
                "lng": 35.05
            },
            "Ac\u0131g\u00f6l": {
                "lat": 38.55,
                "lng": 34.5167
            },
            "Yusufeli": {
                "lat": 40.8106,
                "lng": 41.5269
            },
            "Alaca": {
                "lat": 40.1683,
                "lng": 34.8425
            },
            "S\u00f6\u011f\u00fct": {
                "lat": 40.0186,
                "lng": 30.1814
            },
            "Kale": {
                "lat": 37.4392,
                "lng": 28.8453
            },
            "G\u00fcla\u011fa\u00e7": {
                "lat": 38.4,
                "lng": 34.35
            },
            "Gerze": {
                "lat": 41.8056,
                "lng": 35.1981
            },
            "Ayvac\u0131k": {
                "lat": 39.6011,
                "lng": 26.4047
            },
            "Malazgirt": {
                "lat": 39.1478,
                "lng": 42.5442
            },
            "Ara\u00e7": {
                "lat": 41.2422,
                "lng": 33.3283
            },
            "G\u00f6rele": {
                "lat": 41.0333,
                "lng": 39.0
            },
            "\u00c7erke\u015f": {
                "lat": 40.8139,
                "lng": 32.8908
            },
            "Orhaneli": {
                "lat": 39.9028,
                "lng": 28.9875
            },
            "\u00c7atak": {
                "lat": 38.0086,
                "lng": 43.0597
            },
            "Narl\u0131ca": {
                "lat": 36.2333,
                "lng": 36.2167
            },
            "\u015eenkaya": {
                "lat": 40.5619,
                "lng": 42.3464
            },
            "Sava\u015ftepe": {
                "lat": 39.3839,
                "lng": 27.6547
            },
            "Keskin": {
                "lat": 39.6731,
                "lng": 33.6136
            },
            "Manyas": {
                "lat": 40.0464,
                "lng": 27.97
            },
            "Konakl\u0131": {
                "lat": 36.5833,
                "lng": 31.8833
            },
            "\u00c7al": {
                "lat": 38.0836,
                "lng": 29.3989
            },
            "Tufanbeyli": {
                "lat": 38.2647,
                "lng": 36.2225
            },
            "Yumurtal\u0131k": {
                "lat": 36.7678,
                "lng": 35.7922
            },
            "Asarc\u0131k": {
                "lat": 41.0314,
                "lng": 36.2683
            },
            "Karacasu": {
                "lat": 37.7308,
                "lng": 28.6061
            },
            "\u0130skilip": {
                "lat": 40.7361,
                "lng": 34.4739
            },
            "\u015eav\u015fat": {
                "lat": 41.2433,
                "lng": 42.3639
            },
            "G\u00f6me\u00e7": {
                "lat": 39.3911,
                "lng": 26.84
            },
            "\u00c7ameli": {
                "lat": 37.0708,
                "lng": 29.3453
            },
            "Ye\u015filhisar": {
                "lat": 38.35,
                "lng": 35.0867
            },
            "\u00c7at": {
                "lat": 39.6111,
                "lng": 40.9789
            },
            "Arpa\u00e7ay": {
                "lat": 40.8483,
                "lng": 43.3317
            },
            "\u00c7ukurca": {
                "lat": 37.2469,
                "lng": 43.6117
            },
            "Tortum": {
                "lat": 40.2981,
                "lng": 41.6325
            },
            "Ar\u0131cak": {
                "lat": 38.5644,
                "lng": 40.1339
            },
            "Ulubey": {
                "lat": 40.8761,
                "lng": 37.7406
            },
            "Sar\u0131o\u011flan": {
                "lat": 39.0769,
                "lng": 35.9667
            },
            "F\u0131nd\u0131kl\u0131": {
                "lat": 41.1333,
                "lng": 41.0167
            },
            "Alt\u0131nta\u015f": {
                "lat": 39.0615,
                "lng": 30.1099
            },
            "E\u011firdir": {
                "lat": 37.875,
                "lng": 30.8506
            },
            "Ya\u011fl\u0131dere": {
                "lat": 40.8572,
                "lng": 38.6325
            },
            "Ahmetli": {
                "lat": 38.5183,
                "lng": 27.9386
            },
            "Divri\u011fi": {
                "lat": 39.3667,
                "lng": 38.1167
            },
            "\u00c7ar\u015f\u0131ba\u015f\u0131": {
                "lat": 41.0833,
                "lng": 39.3833
            },
            "Turgutalp": {
                "lat": 39.1833,
                "lng": 27.5833
            },
            "\u00c7aykara": {
                "lat": 40.7475,
                "lng": 40.2419
            },
            "K\u00f6pr\u00fck\u00f6y": {
                "lat": 39.9756,
                "lng": 41.8711
            },
            "Hazro": {
                "lat": 38.2561,
                "lng": 40.7831
            },
            "\u015eemdinli": {
                "lat": 37.308,
                "lng": 44.571
            },
            "H\u00fcy\u00fck": {
                "lat": 37.9519,
                "lng": 31.5958
            },
            "Do\u011fanhisar": {
                "lat": 38.1447,
                "lng": 31.6781
            },
            "T\u00fcrkeli": {
                "lat": 41.9486,
                "lng": 34.3367
            },
            "Alada\u011f": {
                "lat": 37.5464,
                "lng": 35.3986
            },
            "Davutlar": {
                "lat": 37.7333,
                "lng": 27.3
            },
            "Feke": {
                "lat": 37.815,
                "lng": 35.9125
            },
            "Serinyol": {
                "lat": 36.3667,
                "lng": 36.2
            },
            "Mecit\u00f6z\u00fc": {
                "lat": 40.52,
                "lng": 35.2953
            },
            "Y\u0131\u011f\u0131lca": {
                "lat": 40.9667,
                "lng": 31.45
            },
            "Aya\u015f": {
                "lat": 40.0167,
                "lng": 32.3333
            },
            "Altunhisar": {
                "lat": 37.9981,
                "lng": 34.3711
            },
            "Ye\u015filova": {
                "lat": 37.5069,
                "lng": 29.7533
            },
            "Kestel": {
                "lat": 36.5161,
                "lng": 32.0743
            },
            "Ezine": {
                "lat": 39.79,
                "lng": 26.3325
            },
            "Kocak\u00f6y": {
                "lat": 38.2897,
                "lng": 40.5014
            },
            "Avsallar": {
                "lat": 36.5833,
                "lng": 31.8833
            },
            "Adilcevaz": {
                "lat": 38.8058,
                "lng": 42.7469
            },
            "G\u00f6lmarmara": {
                "lat": 38.7167,
                "lng": 27.9167
            },
            "Ta\u015fucu": {
                "lat": 36.3333,
                "lng": 33.8833
            },
            "Domani\u00e7": {
                "lat": 39.8033,
                "lng": 29.6178
            },
            "\u015eefaatl\u0131": {
                "lat": 39.5017,
                "lng": 34.7522
            },
            "Vize": {
                "lat": 41.5736,
                "lng": 27.7653
            },
            "\u00c7ifteler": {
                "lat": 39.3831,
                "lng": 31.0392
            },
            "Sultanda\u011f\u0131": {
                "lat": 38.5333,
                "lng": 31.2333
            },
            "\u00d6zdere": {
                "lat": 38.0175,
                "lng": 27.1283
            },
            "Lapseki": {
                "lat": 40.3439,
                "lng": 26.6836
            },
            "Avanos": {
                "lat": 38.715,
                "lng": 34.8467
            },
            "\u00c7atalp\u0131nar": {
                "lat": 40.8789,
                "lng": 37.4531
            },
            "Cumayeri": {
                "lat": 40.8736,
                "lng": 30.9508
            },
            "Amasra": {
                "lat": 41.7494,
                "lng": 32.3864
            },
            "\u00c7i\u00e7ekda\u011f\u0131": {
                "lat": 39.6069,
                "lng": 34.4086
            },
            "Piraziz": {
                "lat": 40.95,
                "lng": 38.125
            },
            "Bah\u00e7esaray": {
                "lat": 38.1286,
                "lng": 42.8075
            },
            "\u0130spir": {
                "lat": 40.4836,
                "lng": 40.9953
            },
            "Tonya": {
                "lat": 40.8856,
                "lng": 39.2922
            },
            "Alt\u0131nekin": {
                "lat": 38.3078,
                "lng": 32.8686
            },
            "\u00dcz\u00fcml\u00fc": {
                "lat": 39.71,
                "lng": 39.7017
            },
            "Serinhisar": {
                "lat": 37.5806,
                "lng": 29.2697
            },
            "Pazar": {
                "lat": 40.262,
                "lng": 36.313
            },
            "Sumbas": {
                "lat": 37.4431,
                "lng": 36.0322
            },
            "\u00d6merli": {
                "lat": 37.4025,
                "lng": 40.9561
            },
            "Saraykent": {
                "lat": 39.6936,
                "lng": 35.5111
            },
            "Solhan": {
                "lat": 38.9681,
                "lng": 41.0536
            },
            "S\u00f6\u011f\u00fctl\u00fc": {
                "lat": 40.9,
                "lng": 30.4833
            },
            "Karg\u0131p\u0131nar": {
                "lat": 36.6667,
                "lng": 34.4167
            },
            "Eynesil": {
                "lat": 41.05,
                "lng": 39.1333
            },
            "\u00c7ayba\u015f\u0131": {
                "lat": 41.0175,
                "lng": 37.0983
            },
            "Torul": {
                "lat": 40.5572,
                "lng": 39.2919
            },
            "Saimbeyli": {
                "lat": 37.9833,
                "lng": 36.0833
            },
            "Ye\u015filli": {
                "lat": 37.3406,
                "lng": 40.8258
            },
            "\u00c7ay\u0131ralan": {
                "lat": 39.305,
                "lng": 35.6444
            },
            "Seyitgazi": {
                "lat": 39.4456,
                "lng": 30.6939
            },
            "Mucur": {
                "lat": 39.0636,
                "lng": 34.3783
            },
            "Karap\u00fcr\u00e7ek": {
                "lat": 40.6425,
                "lng": 30.5386
            },
            "Kumlu": {
                "lat": 36.3728,
                "lng": 36.4528
            },
            "Han\u0131m\u00e7iftli\u011fi": {
                "lat": 38.3833,
                "lng": 38.3333
            },
            "D\u00fczk\u00f6y": {
                "lat": 40.8742,
                "lng": 39.4256
            },
            "\u0130kizce": {
                "lat": 41.0531,
                "lng": 37.0769
            },
            "Ortaklar": {
                "lat": 37.8833,
                "lng": 27.5
            },
            "Mesudiye": {
                "lat": 40.4625,
                "lng": 37.7725
            },
            "\u00c7amard\u0131": {
                "lat": 37.8364,
                "lng": 34.9928
            },
            "Balya": {
                "lat": 39.75,
                "lng": 27.5833
            },
            "G\u00fc\u00e7l\u00fckonak": {
                "lat": 37.4711,
                "lng": 41.9139
            },
            "Akseki": {
                "lat": 37.0486,
                "lng": 31.79
            },
            "\u00c7avd\u0131r": {
                "lat": 37.155,
                "lng": 29.6939
            },
            "Meri\u00e7": {
                "lat": 41.1833,
                "lng": 26.4167
            },
            "Kalecik": {
                "lat": 40.0972,
                "lng": 33.4083
            },
            "K\u00f6pr\u00fcba\u015f\u0131": {
                "lat": 38.7497,
                "lng": 28.4047
            },
            "Sason": {
                "lat": 38.3803,
                "lng": 41.3953
            },
            "Buharkent": {
                "lat": 37.9617,
                "lng": 28.7422
            },
            "Koyulhisar": {
                "lat": 40.3053,
                "lng": 37.8306
            },
            "G\u00fcrgentepe": {
                "lat": 40.7883,
                "lng": 37.6017
            },
            "Yenipazar": {
                "lat": 37.8269,
                "lng": 28.1975
            },
            "Sar\u0131veliler": {
                "lat": 36.6964,
                "lng": 32.6203
            },
            "Hisarc\u0131k": {
                "lat": 39.2497,
                "lng": 29.2314
            },
            "Beyda\u011f": {
                "lat": 38.0833,
                "lng": 28.2333
            },
            "P\u00fct\u00fcrge": {
                "lat": 38.1967,
                "lng": 38.8697
            },
            "Uludere": {
                "lat": 37.446,
                "lng": 42.852
            },
            "Hac\u0131lar": {
                "lat": 38.6497,
                "lng": 35.4503
            },
            "Refahiye": {
                "lat": 39.9011,
                "lng": 38.7686
            },
            "Hizan": {
                "lat": 38.2256,
                "lng": 42.4269
            },
            "Bozkurt": {
                "lat": 37.8167,
                "lng": 29.6167
            },
            "Acarlar": {
                "lat": 37.8244,
                "lng": 27.7467
            },
            "Narman": {
                "lat": 40.3472,
                "lng": 41.8683
            },
            "Nurhak": {
                "lat": 37.9658,
                "lng": 37.4272
            },
            "Alucra": {
                "lat": 40.3167,
                "lng": 38.7667
            },
            "Karaburun": {
                "lat": 38.6333,
                "lng": 26.5167
            },
            "T\u00f6m\u00fck": {
                "lat": 36.6667,
                "lng": 34.3833
            },
            "Erfelek": {
                "lat": 41.8833,
                "lng": 34.9167
            },
            "Yaz\u0131han": {
                "lat": 38.5969,
                "lng": 38.1878
            },
            "Hadim": {
                "lat": 36.9883,
                "lng": 32.4569
            },
            "Selimpa\u015fa": {
                "lat": 41.054,
                "lng": 28.367
            },
            "Umurlu": {
                "lat": 37.85,
                "lng": 27.9667
            },
            "\u015ealpazar\u0131": {
                "lat": 40.9422,
                "lng": 39.1942
            },
            "\u00c7\u00fcng\u00fc\u015f": {
                "lat": 38.2128,
                "lng": 39.2869
            },
            "Sultanhan\u0131": {
                "lat": 38.25,
                "lng": 33.55
            },
            "\u00c7ama\u015f": {
                "lat": 40.9025,
                "lng": 37.5281
            },
            "Senirkent": {
                "lat": 38.1081,
                "lng": 30.55
            },
            "Yeni\u00e7iftlik": {
                "lat": 41.0167,
                "lng": 27.85
            },
            "Ermenek": {
                "lat": 36.6389,
                "lng": 32.8925
            },
            "Velime\u015fe": {
                "lat": 41.25,
                "lng": 27.8833
            },
            "Beldibi": {
                "lat": 36.8667,
                "lng": 28.2667
            },
            "Derecik": {
                "lat": 37.083,
                "lng": 44.311
            },
            "Alpu": {
                "lat": 39.7667,
                "lng": 30.95
            },
            "Ortakent": {
                "lat": 37.1035,
                "lng": 27.2926
            },
            "Kuzuculu": {
                "lat": 36.8833,
                "lng": 36.2333
            },
            "Ayd\u0131nc\u0131k": {
                "lat": 40.1167,
                "lng": 35.2667
            },
            "Side": {
                "lat": 36.7667,
                "lng": 31.3889
            },
            "Akyaka": {
                "lat": 40.7444,
                "lng": 43.625
            },
            "Keles": {
                "lat": 39.9136,
                "lng": 29.2294
            },
            "Kavakl\u0131dere": {
                "lat": 37.4469,
                "lng": 28.3633
            },
            "Arapgir": {
                "lat": 39.0333,
                "lng": 38.4833
            },
            "Il\u0131cak\u00f6y": {
                "lat": 36.8219,
                "lng": 31.3672
            },
            "Sivrice": {
                "lat": 38.4467,
                "lng": 39.3117
            },
            "S\u00fct\u00e7\u00fcler": {
                "lat": 37.4944,
                "lng": 30.9817
            },
            "G\u00f6yn\u00fccek": {
                "lat": 40.3833,
                "lng": 35.5167
            },
            "\u015eebin Karahisar": {
                "lat": 40.2886,
                "lng": 38.4233
            },
            "G\u00fcr\u00fcn": {
                "lat": 38.7225,
                "lng": 37.2775
            },
            "K\u00fc\u00e7\u00fckkuyu": {
                "lat": 39.55,
                "lng": 26.6167
            },
            "K\u00fc\u00e7\u00fck Dalyan": {
                "lat": 36.2167,
                "lng": 36.1667
            },
            "\u0130nebolu": {
                "lat": 41.9747,
                "lng": 33.7608
            },
            "G\u00f6lpazar\u0131": {
                "lat": 40.2847,
                "lng": 30.3172
            },
            "Karpuzlu": {
                "lat": 37.5592,
                "lng": 27.8344
            },
            "Pazaryeri": {
                "lat": 40.0,
                "lng": 29.9
            },
            "G\u00f6lyaka": {
                "lat": 40.7769,
                "lng": 30.9958
            },
            "Susuz": {
                "lat": 40.78,
                "lng": 43.1353
            },
            "Karahall\u0131": {
                "lat": 38.3167,
                "lng": 29.5167
            },
            "G\u00f6k\u00e7e": {
                "lat": 37.207,
                "lng": 40.697
            },
            "Ye\u015filk\u00f6y": {
                "lat": 36.8667,
                "lng": 36.2
            },
            "Karkam\u0131\u015f": {
                "lat": 36.8339,
                "lng": 37.9969
            },
            "Ala\u00e7at\u0131": {
                "lat": 38.2814,
                "lng": 26.3742
            },
            "Kur\u015funlu": {
                "lat": 40.85,
                "lng": 33.25
            },
            "Ekin\u00f6z\u00fc": {
                "lat": 38.0617,
                "lng": 37.1928
            },
            "Akbez": {
                "lat": 36.85,
                "lng": 36.5167
            },
            "\u00c7anta": {
                "lat": 41.0806,
                "lng": 28.0819
            },
            "Tefenni": {
                "lat": 37.3111,
                "lng": 29.7744
            },
            "Ele\u015fkirt": {
                "lat": 39.7981,
                "lng": 42.6744
            },
            "Kayna\u015fl\u0131": {
                "lat": 40.7667,
                "lng": 31.3167
            },
            "Tut": {
                "lat": 37.7967,
                "lng": 37.915
            },
            "Kabata\u015f": {
                "lat": 40.75,
                "lng": 37.45
            },
            "Sar\u0131z": {
                "lat": 38.4792,
                "lng": 36.5006
            },
            "Kum\u00e7at\u0131": {
                "lat": 37.471,
                "lng": 42.317
            },
            "\u00c7eltik": {
                "lat": 39.0244,
                "lng": 31.7906
            },
            "\u0130li\u00e7": {
                "lat": 39.4536,
                "lng": 38.5633
            },
            "B\u00fcy\u00fckorhan": {
                "lat": 39.75,
                "lng": 28.9167
            },
            "Ba\u015fmak\u00e7\u0131": {
                "lat": 37.8833,
                "lng": 30.0
            },
            "Erenler": {
                "lat": 38.8197,
                "lng": 30.5406
            },
            "\u00c7\u0131ld\u0131r": {
                "lat": 41.1289,
                "lng": 43.1328
            },
            "Re\u015fadiye": {
                "lat": 40.3914,
                "lng": 37.3383
            },
            "G\u00f6k\u00e7eada": {
                "lat": 40.1608,
                "lng": 25.8444
            },
            "\u00c7amoluk": {
                "lat": 40.1333,
                "lng": 38.7333
            },
            "Kadriye": {
                "lat": 36.85,
                "lng": 31.05
            },
            "Armutlu": {
                "lat": 40.5167,
                "lng": 28.8333
            },
            "Yaz\u0131konak": {
                "lat": 38.6167,
                "lng": 39.3
            },
            "Kad\u0131k\u00f6y": {
                "lat": 40.6167,
                "lng": 29.2333
            },
            "G\u00fcney": {
                "lat": 38.1544,
                "lng": 29.0678
            },
            "G\u00fcm\u00fc\u015fova": {
                "lat": 40.85,
                "lng": 30.9333
            },
            "K\u00f6se": {
                "lat": 40.21,
                "lng": 39.6511
            },
            "Adakl\u0131": {
                "lat": 39.2308,
                "lng": 40.4781
            },
            "Alt\u0131nyayla": {
                "lat": 39.2725,
                "lng": 36.7511
            },
            "G\u00fcneys\u0131n\u0131r": {
                "lat": 37.2981,
                "lng": 32.7211
            },
            "Ke\u015fap": {
                "lat": 40.9167,
                "lng": 38.5167
            },
            "Aslanapa": {
                "lat": 39.2167,
                "lng": 29.8667
            },
            "\u00c7obanlar": {
                "lat": 38.7,
                "lng": 30.7833
            },
            "Belek": {
                "lat": 36.85,
                "lng": 31.05
            },
            "Karay\u0131lan": {
                "lat": 36.7167,
                "lng": 36.2333
            },
            "\u00c7ay\u0131rl\u0131": {
                "lat": 39.8056,
                "lng": 40.0364
            },
            "Karl\u0131ova": {
                "lat": 39.2992,
                "lng": 41.0142
            },
            "Yaprakl\u0131": {
                "lat": 40.7572,
                "lng": 33.7789
            },
            "Emirgazi": {
                "lat": 37.9022,
                "lng": 33.8372
            },
            "Eceabat": {
                "lat": 40.1839,
                "lng": 26.3564
            },
            "Kulu": {
                "lat": 39.0892,
                "lng": 33.0806
            },
            "Yakakent": {
                "lat": 41.6222,
                "lng": 35.5314
            },
            "\u015eaban\u00f6z\u00fc": {
                "lat": 40.4825,
                "lng": 33.2836
            },
            "Ta\u015fkent": {
                "lat": 36.9233,
                "lng": 32.4889
            },
            "Uzundere": {
                "lat": 40.5364,
                "lng": 41.5483
            },
            "Aktepe": {
                "lat": 36.7,
                "lng": 36.4833
            },
            "G\u00fcneysu": {
                "lat": 40.9781,
                "lng": 40.6136
            },
            "Havsa": {
                "lat": 41.5492,
                "lng": 26.8217
            },
            "\u00c7aml\u0131yayla": {
                "lat": 37.1703,
                "lng": 34.6083
            },
            "Demir\u00f6z\u00fc": {
                "lat": 40.1639,
                "lng": 39.8925
            },
            "Adalar": {
                "lat": 40.8578,
                "lng": 29.12
            },
            "K\u0131z\u0131lca\u015far": {
                "lat": 39.8167,
                "lng": 32.7333
            },
            "A\u011flasun": {
                "lat": 37.6494,
                "lng": 30.5339
            },
            "Sulakyurt": {
                "lat": 40.1575,
                "lng": 33.7175
            }
        }
    },
    "PK": {
        "name": "Pakistan",
        "cities": {
            "Karachi": {
                "lat": 24.86,
                "lng": 67.01
            },
            "Lahore": {
                "lat": 31.5497,
                "lng": 74.3436
            },
            "Kotla Qasim Khan": {
                "lat": 32.5833,
                "lng": 73.75
            },
            "Faisalabad": {
                "lat": 31.4167,
                "lng": 73.0911
            },
            "Rawalpindi": {
                "lat": 33.6,
                "lng": 73.0333
            },
            "Gujranwala": {
                "lat": 32.1567,
                "lng": 74.19
            },
            "Peshawar": {
                "lat": 34.0144,
                "lng": 71.5675
            },
            "Multan": {
                "lat": 30.1978,
                "lng": 71.4697
            },
            "Hyderabad City": {
                "lat": 25.3792,
                "lng": 68.3683
            },
            "Islamabad": {
                "lat": 33.6931,
                "lng": 73.0639
            },
            "Quetta": {
                "lat": 30.1833,
                "lng": 67.0
            },
            "Bahawalpur": {
                "lat": 29.3956,
                "lng": 71.6836
            },
            "Sargodha": {
                "lat": 32.0836,
                "lng": 72.6711
            },
            "Chak Forty-one": {
                "lat": 31.0167,
                "lng": 73.85
            },
            "Sialkot City": {
                "lat": 32.4925,
                "lng": 74.5311
            },
            "Sukkur": {
                "lat": 27.7061,
                "lng": 68.8483
            },
            "Pindi Bhattian": {
                "lat": 31.8958,
                "lng": 73.2761
            },
            "Larkana": {
                "lat": 27.5583,
                "lng": 68.2111
            },
            "Chiniot": {
                "lat": 31.7194,
                "lng": 72.9842
            },
            "Shekhupura": {
                "lat": 31.7111,
                "lng": 73.9878
            },
            "Surab": {
                "lat": 28.4925,
                "lng": 66.2597
            },
            "Sanghar": {
                "lat": 26.0442,
                "lng": 68.9536
            },
            "Rahimyar Khan": {
                "lat": 28.42,
                "lng": 70.3
            },
            "Jhang City": {
                "lat": 31.2694,
                "lng": 72.3161
            },
            "Mardan": {
                "lat": 34.2012,
                "lng": 72.0258
            },
            "Dera Ghazi Khan": {
                "lat": 30.0331,
                "lng": 70.64
            },
            "Gujrat": {
                "lat": 32.5739,
                "lng": 74.0789
            },
            "Cantonment": {
                "lat": 31.5167,
                "lng": 74.3833
            },
            "Bhawana": {
                "lat": 31.5661,
                "lng": 72.6461
            },
            "Malakwal": {
                "lat": 32.5531,
                "lng": 73.2067
            },
            "Thari Mir Wah": {
                "lat": 27.0683,
                "lng": 68.6023
            },
            "Sarai Alamgir": {
                "lat": 32.9,
                "lng": 73.75
            },
            "Shah Latif Town": {
                "lat": 24.8806,
                "lng": 67.1625
            },
            "Khipro": {
                "lat": 25.823,
                "lng": 69.377
            },
            "Chak Jhumra": {
                "lat": 31.5667,
                "lng": 73.1833
            },
            "Mirpur Mathelo": {
                "lat": 28.0167,
                "lng": 69.5333
            },
            "Kot Radha Kishan": {
                "lat": 31.1725,
                "lng": 74.0997
            },
            "Kasur": {
                "lat": 31.1167,
                "lng": 74.45
            },
            "Khairpur Tamewah": {
                "lat": 29.58,
                "lng": 72.2328
            },
            "Chakwal": {
                "lat": 32.9303,
                "lng": 72.8556
            },
            "Mingaora": {
                "lat": 34.7717,
                "lng": 72.36
            },
            "Khwazakhela": {
                "lat": 34.9333,
                "lng": 72.4667
            },
            "Nawabshah": {
                "lat": 26.2442,
                "lng": 68.41
            },
            "Kotri": {
                "lat": 25.374,
                "lng": 68.3013
            },
            "Sahiwal": {
                "lat": 30.6611,
                "lng": 73.1083
            },
            "Mehrabpur": {
                "lat": 27.0994,
                "lng": 68.4208
            },
            "Hafizabad": {
                "lat": 32.0714,
                "lng": 73.6878
            },
            "Mirpur Khas": {
                "lat": 25.525,
                "lng": 69.0158
            },
            "Okara": {
                "lat": 30.8092,
                "lng": 73.4536
            },
            "Khanewal": {
                "lat": 30.3,
                "lng": 71.9333
            },
            "Ratodero": {
                "lat": 27.8,
                "lng": 68.2833
            },
            "Thatta": {
                "lat": 24.7461,
                "lng": 67.9244
            },
            "Chilas": {
                "lat": 35.4194,
                "lng": 74.0944
            },
            "Turbat": {
                "lat": 26.0042,
                "lng": 63.0606
            },
            "Mirpur Bhtoro": {
                "lat": 24.73,
                "lng": 68.25
            },
            "Mandi Burewala": {
                "lat": 30.1592,
                "lng": 72.6817
            },
            "Jacobabad": {
                "lat": 28.2769,
                "lng": 68.4514
            },
            "Parachinar": {
                "lat": 33.9,
                "lng": 70.1
            },
            "Jhelum": {
                "lat": 32.9425,
                "lng": 73.7256
            },
            "Saddiqabad": {
                "lat": 28.3006,
                "lng": 70.1302
            },
            "Kuchlagh": {
                "lat": 31.0833,
                "lng": 67.1056
            },
            "Khapalu": {
                "lat": 35.1667,
                "lng": 76.3333
            },
            "Talhar": {
                "lat": 24.8833,
                "lng": 68.8167
            },
            "Kohat": {
                "lat": 33.5833,
                "lng": 71.4333
            },
            "Muridke": {
                "lat": 31.802,
                "lng": 74.255
            },
            "Muzaffargarh": {
                "lat": 30.0694,
                "lng": 71.1942
            },
            "Hala": {
                "lat": 25.81,
                "lng": 68.43
            },
            "Ziarat": {
                "lat": 30.381,
                "lng": 67.727
            },
            "Khanpur": {
                "lat": 28.65,
                "lng": 70.68
            },
            "Gojra": {
                "lat": 31.15,
                "lng": 72.6833
            },
            "Mandi Bahauddin": {
                "lat": 32.5797,
                "lng": 73.4814
            },
            "Eminabad": {
                "lat": 32.0414,
                "lng": 74.26
            },
            "Jaranwala": {
                "lat": 31.3333,
                "lng": 73.4167
            },
            "Chauk Azam": {
                "lat": 30.9648,
                "lng": 71.217
            },
            "Abbottabad": {
                "lat": 34.1558,
                "lng": 73.2194
            },
            "Dadu": {
                "lat": 26.7325,
                "lng": 67.7792
            },
            "Khairpur Mir\u2019s": {
                "lat": 27.5333,
                "lng": 68.7667
            },
            "Bahawalnagar": {
                "lat": 29.9928,
                "lng": 73.2536
            },
            "Khuzdar": {
                "lat": 27.8,
                "lng": 66.6167
            },
            "Pakpattan": {
                "lat": 30.3442,
                "lng": 73.3839
            },
            "Zafarwal": {
                "lat": 32.35,
                "lng": 74.9
            },
            "Tando Allahyar": {
                "lat": 25.4617,
                "lng": 68.7167
            },
            "Sarhari": {
                "lat": 26.1,
                "lng": 68.4833
            },
            "Ahmadpur East": {
                "lat": 29.1439,
                "lng": 71.2592
            },
            "Wazirabad": {
                "lat": 32.4353,
                "lng": 74.1142
            },
            "Vihari": {
                "lat": 30.0419,
                "lng": 72.3528
            },
            "New Mirpur": {
                "lat": 33.15,
                "lng": 73.7333
            },
            "Kamalia": {
                "lat": 30.7333,
                "lng": 72.65
            },
            "Renala Khurd": {
                "lat": 30.8833,
                "lng": 73.6
            },
            "Kot Addu": {
                "lat": 30.4664,
                "lng": 70.9656
            },
            "Nowshera": {
                "lat": 34.0153,
                "lng": 71.9747
            },
            "Lodhran": {
                "lat": 29.5333,
                "lng": 71.6333
            },
            "Swabi": {
                "lat": 34.1202,
                "lng": 72.4702
            },
            "Badin": {
                "lat": 24.6572,
                "lng": 68.8406
            },
            "Goth Tando Sumro": {
                "lat": 25.45,
                "lng": 68.7167
            },
            "Ghotki": {
                "lat": 28.1,
                "lng": 69.19
            },
            "Khushab": {
                "lat": 32.2986,
                "lng": 72.3508
            },
            "Dera Ismail Khan": {
                "lat": 31.8314,
                "lng": 70.9019
            },
            "Bagu Na Mohra": {
                "lat": 33.22,
                "lng": 73.21
            },
            "Chaman": {
                "lat": 30.9222,
                "lng": 66.4447
            },
            "Charsadda": {
                "lat": 34.15,
                "lng": 71.7333
            },
            "Kandhkot": {
                "lat": 28.244,
                "lng": 69.181
            },
            "Bahrain": {
                "lat": 35.2075,
                "lng": 72.5456
            },
            "Tando Muhammad Khan": {
                "lat": 25.1239,
                "lng": 68.5389
            },
            "Chishtian": {
                "lat": 29.8,
                "lng": 72.8333
            },
            "Shahdadpur": {
                "lat": 25.9228,
                "lng": 68.6206
            },
            "Hasilpur": {
                "lat": 29.6917,
                "lng": 72.5453
            },
            "Kambar": {
                "lat": 27.5868,
                "lng": 68.001
            },
            "Attock Khurd": {
                "lat": 33.7667,
                "lng": 72.3667
            },
            "Harnai": {
                "lat": 30.1,
                "lng": 67.9378
            },
            "Muzaffarabad": {
                "lat": 34.3583,
                "lng": 73.4722
            },
            "Arifwala": {
                "lat": 30.2981,
                "lng": 73.0561
            },
            "Mianwali": {
                "lat": 32.5853,
                "lng": 71.5436
            },
            "Shabqadar": {
                "lat": 34.2054,
                "lng": 71.5833
            },
            "Jauharabad": {
                "lat": 32.2919,
                "lng": 72.2736
            },
            "Gwadar": {
                "lat": 25.1264,
                "lng": 62.3225
            },
            "Nasatta": {
                "lat": 34.1022,
                "lng": 71.7964
            },
            "Gujar Khan": {
                "lat": 33.253,
                "lng": 73.304
            },
            "Jalalpur Jattan": {
                "lat": 32.6419,
                "lng": 74.2033
            },
            "Bhakkar": {
                "lat": 31.6278,
                "lng": 71.0625
            },
            "Dipalpur": {
                "lat": 30.6708,
                "lng": 73.6533
            },
            "Kharian": {
                "lat": 32.8108,
                "lng": 73.8647
            },
            "Taxila": {
                "lat": 33.7458,
                "lng": 72.7875
            },
            "Wadala Sandhuan": {
                "lat": 32.1833,
                "lng": 74.4
            },
            "Mian Channun": {
                "lat": 30.45,
                "lng": 72.3667
            },
            "Bhalwal": {
                "lat": 32.2656,
                "lng": 72.8994
            },
            "Dera Allahyar": {
                "lat": 28.3769,
                "lng": 68.3506
            },
            "Panjgur": {
                "lat": 26.9683,
                "lng": 64.1014
            },
            "Kathri": {
                "lat": 26.4583,
                "lng": 68.3192
            },
            "Jamshoro": {
                "lat": 25.4244,
                "lng": 68.2811
            },
            "Khewra": {
                "lat": 32.65,
                "lng": 73.0167
            },
            "Dinga": {
                "lat": 32.641,
                "lng": 73.7243
            },
            "Pattoki": {
                "lat": 31.0167,
                "lng": 73.85
            },
            "Harunabad": {
                "lat": 29.6097,
                "lng": 73.1378
            },
            "Usta Muhammad": {
                "lat": 28.1783,
                "lng": 68.0431
            },
            "Kahror Pakka": {
                "lat": 29.6236,
                "lng": 71.9167
            },
            "Toba Tek Singh": {
                "lat": 30.9711,
                "lng": 72.4825
            },
            "Kahan": {
                "lat": 29.2982,
                "lng": 68.9023
            },
            "Samundri": {
                "lat": 31.0625,
                "lng": 72.9542
            },
            "Shakargarh": {
                "lat": 32.2628,
                "lng": 75.1583
            },
            "Sambrial": {
                "lat": 32.16,
                "lng": 74.4
            },
            "Shujaabad": {
                "lat": 29.8803,
                "lng": 71.295
            },
            "Hujra Shah Muqim": {
                "lat": 30.7333,
                "lng": 73.8167
            },
            "Kabirwala": {
                "lat": 30.4053,
                "lng": 71.8681
            },
            "Talamba": {
                "lat": 30.5255,
                "lng": 72.2398
            },
            "Rohri": {
                "lat": 27.6831,
                "lng": 68.9
            },
            "Mansehra": {
                "lat": 34.3339,
                "lng": 73.2014
            },
            "Lala Musa": {
                "lat": 32.7003,
                "lng": 73.9578
            },
            "Sakrand": {
                "lat": 26.1381,
                "lng": 68.2731
            },
            "Saidu Sharif": {
                "lat": 34.75,
                "lng": 72.3572
            },
            "Chunian": {
                "lat": 30.9639,
                "lng": 73.9803
            },
            "Nankana Sahib": {
                "lat": 31.45,
                "lng": 73.7067
            },
            "Bannu": {
                "lat": 32.9864,
                "lng": 70.6044
            },
            "Faruka": {
                "lat": 31.8853,
                "lng": 72.4153
            },
            "Pasrur": {
                "lat": 32.16,
                "lng": 74.4
            },
            "Timargara": {
                "lat": 34.8278,
                "lng": 71.8417
            },
            "Dina": {
                "lat": 33.0283,
                "lng": 73.6011
            },
            "Rangewala": {
                "lat": 30.8222,
                "lng": 74.2611
            },
            "Chenab Nagar": {
                "lat": 31.7528,
                "lng": 72.9222
            },
            "Hadali": {
                "lat": 32.2922,
                "lng": 72.1922
            },
            "Liaquatpur": {
                "lat": 28.9353,
                "lng": 70.9508
            },
            "Abdul Hakim": {
                "lat": 30.55,
                "lng": 72.1328
            },
            "Kot Mumin": {
                "lat": 32.1883,
                "lng": 73.0286
            },
            "Hassan Abdal": {
                "lat": 33.8195,
                "lng": 72.689
            },
            "Chak Thirty-six North Branch": {
                "lat": 32.1261,
                "lng": 72.7297
            },
            "Rukan": {
                "lat": 32.4236,
                "lng": 73.2722
            },
            "Buni": {
                "lat": 36.2728,
                "lng": 72.2597
            },
            "Chak Sixty-one Gugera Branch": {
                "lat": 31.5875,
                "lng": 73.6897
            },
            "Haripur": {
                "lat": 33.9942,
                "lng": 72.9333
            },
            "Zhob": {
                "lat": 31.3417,
                "lng": 69.4486
            },
            "Badrashni": {
                "lat": 33.9886,
                "lng": 72.025
            },
            "Lalian": {
                "lat": 31.8253,
                "lng": 72.8027
            },
            "Tank": {
                "lat": 32.13,
                "lng": 70.23
            },
            "Kharan": {
                "lat": 28.5833,
                "lng": 65.4167
            },
            "Sukheke Mandi": {
                "lat": 31.8833,
                "lng": 73.4667
            },
            "Jalalpur Bhattian": {
                "lat": 32.0644,
                "lng": 73.377
            },
            "Bandhi": {
                "lat": 26.5833,
                "lng": 68.3
            },
            "Safdarabad": {
                "lat": 31.7167,
                "lng": 73.5667
            },
            "Jalalabad": {
                "lat": 35.88,
                "lng": 74.493
            },
            "Choa Saidan Shah": {
                "lat": 32.7167,
                "lng": 72.9833
            },
            "Ranipur": {
                "lat": 27.2889,
                "lng": 68.5044
            },
            "Hangu": {
                "lat": 33.5333,
                "lng": 71.0667
            },
            "Charbagh": {
                "lat": 34.8333,
                "lng": 72.4417
            },
            "Kalu Khan": {
                "lat": 34.2167,
                "lng": 72.3
            },
            "Bat Khela": {
                "lat": 34.62,
                "lng": 71.97
            },
            "Naushahro Firoz": {
                "lat": 26.85,
                "lng": 68.1333
            },
            "Sillanwali": {
                "lat": 31.825,
                "lng": 72.5389
            },
            "Allahabad": {
                "lat": 28.9333,
                "lng": 70.9667
            },
            "Risalpur Cantonment": {
                "lat": 34.0811,
                "lng": 71.9725
            },
            "Karak": {
                "lat": 33.1167,
                "lng": 71.1
            },
            "Nawan Shahr": {
                "lat": 34.1642,
                "lng": 73.2639
            },
            "Pishin": {
                "lat": 30.5848,
                "lng": 66.9948
            },
            "Kundian": {
                "lat": 32.4522,
                "lng": 71.4718
            },
            "Umarkot": {
                "lat": 25.3631,
                "lng": 69.7425
            },
            "Chakdarra": {
                "lat": 34.65,
                "lng": 72.0333
            },
            "Pasni": {
                "lat": 25.2652,
                "lng": 63.4698
            },
            "Sadda": {
                "lat": 33.7056,
                "lng": 70.3292
            },
            "Landi Kotal": {
                "lat": 34.1053,
                "lng": 71.1553
            },
            "Bagh": {
                "lat": 33.9735,
                "lng": 73.7918
            },
            "Ghuenke": {
                "lat": 32.4244,
                "lng": 74.4603
            },
            "Chitral": {
                "lat": 35.8461,
                "lng": 71.7858
            },
            "Chak Five Hundred Seventy-five": {
                "lat": 31.55,
                "lng": 73.8333
            },
            "Masho Khel": {
                "lat": 33.9103,
                "lng": 71.5025
            },
            "Dulmial": {
                "lat": 32.7333,
                "lng": 72.9167
            },
            "Malak Abad": {
                "lat": 34.8,
                "lng": 71.8
            },
            "Pind Dadan Khan": {
                "lat": 32.5883,
                "lng": 73.0447
            },
            "Uthal": {
                "lat": 25.8,
                "lng": 66.6167
            },
            "Johi": {
                "lat": 26.6921,
                "lng": 67.6133
            },
            "Hajira": {
                "lat": 33.7717,
                "lng": 73.8961
            },
            "Ayun": {
                "lat": 35.7225,
                "lng": 71.7669
            },
            "Bhimbar": {
                "lat": 32.9747,
                "lng": 74.0731
            },
            "Atharan Hazari": {
                "lat": 31.1671,
                "lng": 72.0898
            },
            "Batgram": {
                "lat": 34.6833,
                "lng": 73.0167
            },
            "Arandu": {
                "lat": 35.3103,
                "lng": 71.5486
            },
            "Jaglot": {
                "lat": 35.685,
                "lng": 74.6239
            },
            "Wahga": {
                "lat": 31.6047,
                "lng": 74.5731
            },
            "Skardu": {
                "lat": 35.2903,
                "lng": 75.6444
            },
            "Thal": {
                "lat": 33.3644,
                "lng": 70.5478
            },
            "Dainyor": {
                "lat": 35.9194,
                "lng": 74.3889
            },
            "Kulachi": {
                "lat": 31.9286,
                "lng": 70.4592
            },
            "Qazi Ahmad": {
                "lat": 26.4083,
                "lng": 68.1564
            },
            "Kalat": {
                "lat": 29.03,
                "lng": 66.589
            },
            "Idak": {
                "lat": 32.9741,
                "lng": 70.1988
            },
            "Phularwan": {
                "lat": 32.1883,
                "lng": 73.0286
            },
            "Uch Sharif": {
                "lat": 29.2333,
                "lng": 71.0667
            },
            "Kotli": {
                "lat": 33.5056,
                "lng": 73.8992
            },
            "Doaba": {
                "lat": 33.5333,
                "lng": 70.7333
            },
            "Phalia": {
                "lat": 32.4328,
                "lng": 73.5778
            },
            "Murree": {
                "lat": 33.9042,
                "lng": 73.3903
            },
            "Akora": {
                "lat": 34.0006,
                "lng": 72.1217
            },
            "Bela": {
                "lat": 26.2267,
                "lng": 66.3113
            },
            "Mithi": {
                "lat": 24.74,
                "lng": 69.8
            },
            "Gahi Mammar": {
                "lat": 29.7375,
                "lng": 71.9575
            },
            "Roulia": {
                "lat": 32.7972,
                "lng": 74.0639
            },
            "Haji Shah": {
                "lat": 33.75,
                "lng": 72.4
            },
            "Baltit": {
                "lat": 36.333,
                "lng": 74.666
            },
            "Dhanot": {
                "lat": 29.5333,
                "lng": 71.6333
            },
            "Mian Sahib": {
                "lat": 28.1559,
                "lng": 68.6397
            },
            "Awan Patti": {
                "lat": 34.25,
                "lng": 73.66
            },
            "Sangota": {
                "lat": 34.7833,
                "lng": 72.4167
            },
            "Ghora Gali": {
                "lat": 33.8833,
                "lng": 73.3833
            },
            "Mankera": {
                "lat": 31.3833,
                "lng": 71.4333
            },
            "Shergarh": {
                "lat": 30.8281,
                "lng": 73.7383
            },
            "Sultanpur Mor": {
                "lat": 32.1883,
                "lng": 73.0286
            },
            "Nurkot": {
                "lat": 32.2017,
                "lng": 75.1186
            },
            "Saidpur": {
                "lat": 33.7421,
                "lng": 73.0677
            },
            "Hattian Bala": {
                "lat": 34.1691,
                "lng": 73.7432
            },
            "Firoza": {
                "lat": 28.75,
                "lng": 70.8167
            },
            "Miro Khan": {
                "lat": 27.7597,
                "lng": 68.0917
            },
            "Gakuch": {
                "lat": 36.1736,
                "lng": 73.7667
            },
            "Haider Khel": {
                "lat": 32.9449,
                "lng": 70.296
            },
            "Surmon Chogga Grong": {
                "lat": 35.1518,
                "lng": 76.4454
            },
            "Basla": {
                "lat": 33.3833,
                "lng": 73.3167
            },
            "Gadani": {
                "lat": 25.1194,
                "lng": 66.7319
            },
            "Bunji": {
                "lat": 35.6422,
                "lng": 74.6336
            },
            "Ghota Fatehgarh": {
                "lat": 32.09,
                "lng": 74.78
            },
            "Jandola": {
                "lat": 32.3317,
                "lng": 70.1228
            },
            "Gilgit": {
                "lat": 35.9208,
                "lng": 74.3083
            },
            "Drazinda": {
                "lat": 31.7069,
                "lng": 70.1352
            },
            "Chak Thathi": {
                "lat": 30.3701,
                "lng": 73.2215
            }
        }
    },
    "VN": {
        "name": "Vietnam",
        "cities": {
            "Ho Chi Minh City": {
                "lat": 10.7756,
                "lng": 106.7019
            },
            "Hanoi": {
                "lat": 21.0,
                "lng": 105.85
            },
            "Haiphong": {
                "lat": 20.8651,
                "lng": 106.6838
            },
            "C\u1ea7n Th\u01a1": {
                "lat": 10.0333,
                "lng": 105.7833
            },
            "Bi\u00ean H\u00f2a": {
                "lat": 10.95,
                "lng": 106.8167
            },
            "Th\u1ee7 \u0110\u1ee9c": {
                "lat": 10.8266,
                "lng": 106.7609
            },
            "Qu\u1ea3ng H\u00e0": {
                "lat": 15.9333,
                "lng": 108.2667
            },
            "Hu\u1ebf": {
                "lat": 16.4667,
                "lng": 107.5792
            },
            "T\u00e2n An": {
                "lat": 10.5333,
                "lng": 106.4167
            },
            "B\u1eafc Ninh": {
                "lat": 21.1833,
                "lng": 106.05
            },
            "H\u1ea3i D\u01b0\u01a1ng": {
                "lat": 20.9397,
                "lng": 106.3306
            },
            "Vinh": {
                "lat": 18.6667,
                "lng": 105.6667
            },
            "T\u00e2n Uy\u00ean": {
                "lat": 11.0508,
                "lng": 106.7636
            },
            "\u0110\u00e0 L\u1ea1t": {
                "lat": 11.9417,
                "lng": 108.4383
            },
            "Thanh H\u00f3a": {
                "lat": 19.8075,
                "lng": 105.7764
            },
            "Nha Trang": {
                "lat": 12.245,
                "lng": 109.1917
            },
            "Nam \u0110\u1ecbnh": {
                "lat": 20.42,
                "lng": 106.1683
            },
            "V\u0169ng T\u00e0u": {
                "lat": 10.3833,
                "lng": 107.1167
            },
            "Bu\u00f4n Ma Thu\u1ed9t": {
                "lat": 12.6667,
                "lng": 108.05
            },
            "Th\u00e1i Nguy\u00ean": {
                "lat": 21.6,
                "lng": 105.85
            },
            "Th\u1ee7 D\u1ea7u M\u1ed9t": {
                "lat": 10.9667,
                "lng": 106.65
            },
            "An Nh\u01a1n": {
                "lat": 13.917,
                "lng": 109.083
            },
            "C\u00e0 Mau": {
                "lat": 9.1833,
                "lng": 105.15
            },
            "Quy Nh\u01a1n": {
                "lat": 13.7667,
                "lng": 109.2333
            },
            "Nghi S\u01a1n": {
                "lat": 19.417,
                "lng": 105.75
            },
            "Long Xuy\u00ean": {
                "lat": 10.3736,
                "lng": 105.4458
            },
            "Vi\u1ec7t Tr\u00ec": {
                "lat": 21.3,
                "lng": 105.4333
            },
            "M\u1ef9 Tho": {
                "lat": 10.35,
                "lng": 106.35
            },
            "Th\u00e1i B\u00ecnh": {
                "lat": 20.4461,
                "lng": 106.3422
            },
            "Qu\u1ea3ng Ng\u00e3i": {
                "lat": 15.1167,
                "lng": 108.8
            },
            "\u1ea4p \u0110a L\u1ee3i": {
                "lat": 11.9333,
                "lng": 108.4667
            },
            "R\u1ea1ch Gi\u00e1": {
                "lat": 10.0167,
                "lng": 105.0833
            },
            "Tuy H\u00f2a": {
                "lat": 13.0819,
                "lng": 109.295
            },
            "Ph\u00fa Y\u00ean": {
                "lat": 21.4156,
                "lng": 105.8739
            },
            "S\u01a1n T\u00e2y": {
                "lat": 21.1333,
                "lng": 105.5
            },
            "S\u00f3c Tr\u0103ng": {
                "lat": 9.6028,
                "lng": 105.9736
            },
            "Ch\u00ed Linh": {
                "lat": 21.133,
                "lng": 106.383
            },
            "Sa \u0110\u00e9c": {
                "lat": 10.3,
                "lng": 105.7667
            },
            "Cao L\u00e3nh": {
                "lat": 10.4672,
                "lng": 105.6303
            },
            "Phan Thi\u1ebft": {
                "lat": 10.9333,
                "lng": 108.1
            },
            "Kinh M\u00f4n": {
                "lat": 21.033,
                "lng": 106.5
            },
            "Ph\u1ee7 T\u1eeb S\u01a1n": {
                "lat": 21.1189,
                "lng": 105.9611
            },
            "H\u00e0 T\u0129nh": {
                "lat": 18.3333,
                "lng": 105.9
            },
            "V\u0129nh Long": {
                "lat": 10.25,
                "lng": 105.9667
            },
            "L\u1ea1ng S\u01a1n": {
                "lat": 21.8478,
                "lng": 106.7578
            },
            "V\u0129nh Ch\u00e2u": {
                "lat": 9.333,
                "lng": 106.0
            },
            "Tr\u1ea3ng B\u00e0ng": {
                "lat": 11.033,
                "lng": 106.367
            },
            "Qu\u1ea3ng Y\u00ean": {
                "lat": 20.917,
                "lng": 106.833
            },
            "B\u1eafc Giang": {
                "lat": 21.2667,
                "lng": 106.2
            },
            "H\u1ea1 Long": {
                "lat": 20.95,
                "lng": 107.0667
            },
            "Mang La": {
                "lat": 14.3617,
                "lng": 108.0083
            },
            "B\u1ea3o L\u1ed9c": {
                "lat": 11.5481,
                "lng": 107.8075
            },
            "Phan Rang-Th\u00e1p Ch\u00e0m": {
                "lat": 11.5667,
                "lng": 108.9833
            },
            "T\u00e2n Ph\u00fa": {
                "lat": 11.272,
                "lng": 107.4367
            },
            "\u0110\u1ed3ng H\u1edbi": {
                "lat": 17.4831,
                "lng": 106.5997
            },
            "Tr\u00e0 Vinh": {
                "lat": 9.9333,
                "lng": 106.35
            },
            "Ninh B\u00ecnh": {
                "lat": 20.2539,
                "lng": 105.975
            },
            "Ch\u00e2u \u0110\u1ed1c": {
                "lat": 10.7,
                "lng": 105.1167
            },
            "B\u1ea1c Li\u00eau": {
                "lat": 9.2833,
                "lng": 105.7167
            },
            "C\u1ea9m Ph\u1ea3": {
                "lat": 21.0167,
                "lng": 107.3167
            },
            "V\u0129nh Y\u00ean": {
                "lat": 21.31,
                "lng": 105.5967
            },
            "H\u00f2a Th\u00e0nh": {
                "lat": 11.2831,
                "lng": 106.1297
            },
            "G\u00f2 C\u00f4ng": {
                "lat": 10.3667,
                "lng": 106.6667
            },
            "Long Kh\u00e1nh": {
                "lat": 10.917,
                "lng": 107.167
            },
            "\u0110\u1ee9c Ph\u1ed5": {
                "lat": 14.81,
                "lng": 108.96
            },
            "\u0110\u1ed3ng Xo\u00e0i": {
                "lat": 11.5169,
                "lng": 106.8392
            },
            "H\u01b0ng Y\u00ean": {
                "lat": 20.65,
                "lng": 106.0667
            },
            "Cam Ranh": {
                "lat": 11.9136,
                "lng": 109.1369
            },
            "Pleiku": {
                "lat": 13.9833,
                "lng": 108.0
            },
            "T\u00e2n Ch\u00e2u": {
                "lat": 10.7739,
                "lng": 105.2369
            },
            "Gi\u00e1 Rai": {
                "lat": 9.25,
                "lng": 105.417
            },
            "Ph\u1ee7 L\u00fd": {
                "lat": 20.5411,
                "lng": 105.9139
            },
            "T\u00e2y Ninh": {
                "lat": 11.3678,
                "lng": 106.1189
            },
            "Ba \u0110\u1ed3n": {
                "lat": 17.7547,
                "lng": 106.4231
            },
            "Long B\u00ecnh": {
                "lat": 10.9458,
                "lng": 106.8775
            },
            "L\u00e0o Cai": {
                "lat": 22.4806,
                "lng": 103.975
            },
            "Bu\u00f4n H\u1ed3": {
                "lat": 12.8544,
                "lng": 108.2703
            },
            "B\u1ebfn Tre": {
                "lat": 10.2333,
                "lng": 106.3833
            },
            "Cai L\u1eady": {
                "lat": 10.417,
                "lng": 106.083
            },
            "Tam K\u1ef3": {
                "lat": 15.5667,
                "lng": 108.4833
            },
            "H\u1ed9i An": {
                "lat": 15.8833,
                "lng": 108.3333
            },
            "H\u00f2a B\u00ecnh": {
                "lat": 20.8133,
                "lng": 105.3383
            },
            "Ch\u01a1n Th\u00e0nh": {
                "lat": 11.4292,
                "lng": 106.6572
            },
            "Th\u00e0nh Ph\u1ed1 U\u00f4ng B\u00ed": {
                "lat": 21.0356,
                "lng": 106.7644
            },
            "K\u1ef3 Anh": {
                "lat": 18.0678,
                "lng": 106.2967
            },
            "\u0110\u00f4ng H\u00f2a": {
                "lat": 12.9931,
                "lng": 109.3314
            },
            "S\u1ea7m S\u01a1n": {
                "lat": 19.7333,
                "lng": 105.9
            },
            "B\u00e0 R\u1ecba": {
                "lat": 10.4992,
                "lng": 107.1675
            },
            "M\u00f3ng C\u00e1i": {
                "lat": 21.5333,
                "lng": 107.9667
            },
            "La Gi": {
                "lat": 10.66,
                "lng": 107.7719
            },
            "S\u01a1n La": {
                "lat": 21.327,
                "lng": 103.9141
            },
            "B\u00ecnh H\u00f2a": {
                "lat": 10.9353,
                "lng": 106.8614
            },
            "S\u00f4ng C\u1ea7u": {
                "lat": 13.55,
                "lng": 109.2
            },
            "B\u00ecnh Long": {
                "lat": 11.6527,
                "lng": 106.6093
            },
            "Tuy\u00ean Quang": {
                "lat": 21.8167,
                "lng": 105.2167
            },
            "Tam \u0110i\u1ec7p": {
                "lat": 20.1556,
                "lng": 105.9181
            },
            "Ng\u00e3 B\u1ea3y": {
                "lat": 9.8164,
                "lng": 105.8197
            },
            "H\u1ed3ng Ng\u1ef1": {
                "lat": 10.833,
                "lng": 105.283
            },
            "B\u1ec9m S\u01a1n": {
                "lat": 20.0781,
                "lng": 105.8603
            },
            "Y\u00ean B\u00e1i": {
                "lat": 21.7,
                "lng": 104.8667
            },
            "V\u1ecb Thanh": {
                "lat": 9.7833,
                "lng": 105.4708
            },
            "H\u01b0\u01a1ng Th\u1ee7y": {
                "lat": 16.4,
                "lng": 107.6889
            },
            "\u0110\u00f4ng H\u00e0": {
                "lat": 16.8303,
                "lng": 107.0972
            },
            "Ph\u00fa Th\u1ecd": {
                "lat": 21.4003,
                "lng": 105.2225
            },
            "Ph\u00fac Y\u00ean": {
                "lat": 21.2333,
                "lng": 105.7
            },
            "An Kh\u00ea": {
                "lat": 13.9531,
                "lng": 108.6569
            },
            "Ninh H\u00f2a": {
                "lat": 12.4917,
                "lng": 109.1258
            },
            "C\u1eeda L\u00f4": {
                "lat": 18.8167,
                "lng": 105.7167
            },
            "D\u0129 An": {
                "lat": 10.9039,
                "lng": 106.7678
            },
            "Cao B\u1eb1ng": {
                "lat": 22.6667,
                "lng": 106.2583
            },
            "H\u01b0\u01a1ng Tr\u00e0": {
                "lat": 16.4675,
                "lng": 107.5181
            },
            "Long M\u1ef9": {
                "lat": 9.6814,
                "lng": 105.5708
            },
            "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7": {
                "lat": 21.3833,
                "lng": 103.0167
            },
            "Ph\u00fa Qu\u1ed1c": {
                "lat": 10.2289,
                "lng": 103.9669
            },
            "Ngh\u0129a L\u1ed9": {
                "lat": 21.5758,
                "lng": 104.5192
            },
            "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh": {
                "lat": 11.0,
                "lng": 106.7167
            },
            "Gia Ngh\u0129a": {
                "lat": 11.9833,
                "lng": 107.7
            },
            "H\u00e0 Giang": {
                "lat": 22.8333,
                "lng": 104.9833
            },
            "A Yun Pa": {
                "lat": 13.3939,
                "lng": 108.4408
            },
            "S\u00f4ng \u0110\u1ed1c": {
                "lat": 9.0333,
                "lng": 104.8167
            },
            "Duy\u00ean H\u1ea3i": {
                "lat": 9.6331,
                "lng": 106.4975
            },
            "H\u00e0 Ti\u00ean": {
                "lat": 10.3833,
                "lng": 104.4833
            },
            "Phan R\u00ed C\u1eeda": {
                "lat": 11.1739,
                "lng": 108.5661
            },
            "B\u1eafc K\u1ea1n": {
                "lat": 22.1333,
                "lng": 105.8333
            },
            "Lai Ch\u00e2u": {
                "lat": 22.3992,
                "lng": 103.4392
            },
            "H\u1ed1 Nai": {
                "lat": 10.9725,
                "lng": 106.8789
            },
            "Tam Hi\u1ec7p": {
                "lat": 10.9497,
                "lng": 106.8575
            },
            "M\u1ef9 H\u00f2a": {
                "lat": 10.3655,
                "lng": 105.4011
            },
            "B\u1eedu Long": {
                "lat": 10.96,
                "lng": 106.7967
            },
            "Bai Chay": {
                "lat": 20.9658,
                "lng": 107.0347
            },
            "Thu\u1eadn Ti\u1ebfn": {
                "lat": 10.0894,
                "lng": 105.8281
            },
            "Ph\u00fa M\u1ef9": {
                "lat": 10.5906,
                "lng": 107.0481
            },
            "Di Linh": {
                "lat": 11.5778,
                "lng": 108.0751
            },
            "Long Th\u00e0nh": {
                "lat": 10.8667,
                "lng": 106.9167
            },
            "\u1ea4p Kh\u00e1nh H\u00f2a": {
                "lat": 10.6333,
                "lng": 105.2167
            },
            "Ki\u00ean L\u01b0\u01a1ng": {
                "lat": 10.2856,
                "lng": 104.6444
            },
            "Bu\u00f4n Tr\u1ea5p": {
                "lat": 12.4833,
                "lng": 108.0167
            },
            "Qu\u1ea3ng Tr\u1ecb": {
                "lat": 16.7469,
                "lng": 107.194
            },
            "\u1ea4p Ph\u00fa M\u1ef9": {
                "lat": 9.75,
                "lng": 106.0
            },
            "Thu\u00e2n An": {
                "lat": 16.5489,
                "lng": 107.6436
            },
            "Ch\u1ee3 Ph\u01b0\u1edbc H\u1ea3i": {
                "lat": 10.4283,
                "lng": 107.2953
            },
            "T\u00e2n Phong": {
                "lat": 19.7322,
                "lng": 105.7817
            },
            "M\u01b0\u1eddng Lay": {
                "lat": 22.0678,
                "lng": 103.1506
            },
            "\u1ea4p Kh\u00e1nh H\u01b0ng": {
                "lat": 10.2,
                "lng": 105.85
            },
            "B\u00ecnh Minh": {
                "lat": 10.0961,
                "lng": 105.7894
            },
            "Th\u1ecb Tr\u1ea5n Ng\u1ea3i Giao": {
                "lat": 10.6406,
                "lng": 107.2478
            },
            "H\u01b0\u01a1ng Canh": {
                "lat": 21.2833,
                "lng": 105.65
            },
            "M\u00f4ng D\u01b0\u01a1ng": {
                "lat": 21.0553,
                "lng": 107.3431
            },
            "M\u1ef9 L\u01b0\u01a1ng": {
                "lat": 20.8667,
                "lng": 105.6667
            },
            "Hi\u1ec7p H\u00f2a": {
                "lat": 10.9289,
                "lng": 106.8378
            },
            "Bo": {
                "lat": 20.6736,
                "lng": 105.5311
            },
            "T\u00e2n V\u1ea1n": {
                "lat": 10.9119,
                "lng": 106.8261
            },
            "Khe Sanh": {
                "lat": 16.6193,
                "lng": 106.7305
            },
            "H\u00f2a Th\u01b0\u1ee3ng": {
                "lat": 21.6472,
                "lng": 105.8278
            },
            "Ba Ch\u00fac": {
                "lat": 10.5,
                "lng": 104.9
            },
            "An Th\u00e0nh B": {
                "lat": 10.1958,
                "lng": 106.3314
            },
            "Ch\u1ee3 M\u1edbi": {
                "lat": 10.55,
                "lng": 105.4
            },
            "\u1ea4p B\u00ecnh Th\u00e0nh": {
                "lat": 10.1833,
                "lng": 105.2
            },
            "\u1ea4p Ph\u00fa H\u1ea3i": {
                "lat": 11.1667,
                "lng": 108.5667
            },
            "Ph\u01b0\u1edbc Long": {
                "lat": 9.4194,
                "lng": 105.3842
            },
            "Ch\u1ee3 L\u00e1ch": {
                "lat": 10.2647,
                "lng": 106.13
            },
            "M\u01b0\u01a1ng Theng": {
                "lat": 21.3869,
                "lng": 103.0156
            },
            "Xu\u00e2n Tr\u00f9ng": {
                "lat": 21.05,
                "lng": 105.2833
            },
            "H\u00e0ng Tr\u1ea1m": {
                "lat": 20.3944,
                "lng": 105.6222
            },
            "An Ph\u00fa": {
                "lat": 10.85,
                "lng": 105.0833
            },
            "Thanh Xu\u00e2n": {
                "lat": 10.2308,
                "lng": 106.3253
            },
            "Ki\u1ebfn Giang": {
                "lat": 17.225,
                "lng": 106.7917
            },
            "Th\u1ea1nh Ph\u00fa": {
                "lat": 9.9539,
                "lng": 106.5069
            },
            "T\u00e2n S\u01a1n": {
                "lat": 21.26,
                "lng": 106.2681
            },
            "Th\u1ecb Tr\u1ea5n M\u1eadu A": {
                "lat": 21.8781,
                "lng": 104.6956
            },
            "An Ch\u00e2u": {
                "lat": 21.3333,
                "lng": 106.85
            },
            "Ng\u1ecdc S\u01a1n": {
                "lat": 21.35,
                "lng": 106.0
            },
            "Sa Pa": {
                "lat": 22.3356,
                "lng": 103.8419
            },
            "Nh\u00e2n Tr\u1ea1ch": {
                "lat": 19.05,
                "lng": 105.55
            },
            "Th\u1ecb Tr\u1ea5n \u0110\u1ed3ng \u0110\u0103ng": {
                "lat": 21.9458,
                "lng": 106.6967
            },
            "\u1ea4p T\u00e2n Ng\u00e3i": {
                "lat": 10.2369,
                "lng": 106.2878
            },
            "C\u00f4n \u0110\u1ea3o": {
                "lat": 8.6931,
                "lng": 106.5767
            },
            "Lu\u00e2n Ch\u00e2u": {
                "lat": 21.74,
                "lng": 103.343
            },
            "Kon Tum": {
                "lat": 14.3544,
                "lng": 108.0075
            },
            "\u0110\u00e0 N\u1eb5ng": {
                "lat": 16.0748,
                "lng": 108.224
            }
        }
    },
    "IR": {
        "name": "Iran",
        "cities": {
            "Tehran": {
                "lat": 35.6892,
                "lng": 51.3889
            },
            "Mashhad": {
                "lat": 36.3264,
                "lng": 59.5433
            },
            "Khowrhesht": {
                "lat": 36.205,
                "lng": 49.6872
            },
            "E\u015ffah\u0101n": {
                "lat": 32.6447,
                "lng": 51.6675
            },
            "Karaj": {
                "lat": 35.8272,
                "lng": 50.9489
            },
            "Sh\u012br\u0101z": {
                "lat": 29.61,
                "lng": 52.5425
            },
            "Tabr\u012bz": {
                "lat": 38.0814,
                "lng": 46.3006
            },
            "Qom": {
                "lat": 34.64,
                "lng": 50.8764
            },
            "Ahv\u0101z": {
                "lat": 31.3047,
                "lng": 48.6783
            },
            "Kerm\u0101nsh\u0101h": {
                "lat": 34.3325,
                "lng": 47.0933
            },
            "Kerm\u0101n": {
                "lat": 30.2911,
                "lng": 57.0678
            },
            "Or\u016bm\u012byeh": {
                "lat": 37.5439,
                "lng": 45.0647
            },
            "P\u012bshbar": {
                "lat": 33.3886,
                "lng": 59.9789
            },
            "Borv\u0101yeh-ye Al B\u016b \u2018Az\u012bz": {
                "lat": 31.6283,
                "lng": 48.6147
            },
            "Rasht": {
                "lat": 37.2744,
                "lng": 49.5889
            },
            "Bah\u0101r": {
                "lat": 34.9028,
                "lng": 48.4425
            },
            "Z\u0101hed\u0101n": {
                "lat": 29.5025,
                "lng": 60.8558
            },
            "Hamad\u0101n": {
                "lat": 34.8064,
                "lng": 48.5161
            },
            "Yazd": {
                "lat": 31.8822,
                "lng": 54.3397
            },
            "Ardab\u012bl": {
                "lat": 38.2425,
                "lng": 48.29
            },
            "Bandar \u2018Abb\u0101s": {
                "lat": 27.1961,
                "lng": 56.2878
            },
            "Ar\u0101k": {
                "lat": 34.0914,
                "lng": 49.6933
            },
            "Esl\u0101mshahr": {
                "lat": 35.5317,
                "lng": 51.2247
            },
            "Sanandaj": {
                "lat": 35.3114,
                "lng": 46.9961
            },
            "Qazv\u012bn": {
                "lat": 36.2894,
                "lng": 50.0086
            },
            "Zanj\u0101n": {
                "lat": 36.6789,
                "lng": 48.5056
            },
            "Khorram\u0101b\u0101d": {
                "lat": 36.7822,
                "lng": 50.8714
            },
            "Gorg\u0101n": {
                "lat": 36.8378,
                "lng": 54.4414
            },
            "Shahr\u012b\u0101r": {
                "lat": 35.6722,
                "lng": 51.0236
            },
            "Shahr-e Qods": {
                "lat": 35.7097,
                "lng": 51.1119
            },
            "K\u0101sh\u0101n": {
                "lat": 33.9825,
                "lng": 51.4325
            },
            "Mal\u0101rd": {
                "lat": 35.6806,
                "lng": 50.9783
            },
            "Sart\u0101": {
                "lat": 36.4491,
                "lng": 53.3234
            },
            "Dezf\u016bl": {
                "lat": 32.3786,
                "lng": 48.4144
            },
            "B\u0101bol": {
                "lat": 36.5475,
                "lng": 52.6783
            },
            "Bojn\u016brd": {
                "lat": 37.4722,
                "lng": 57.3289
            },
            "Q\u0101\u2019em Shahr": {
                "lat": 36.4636,
                "lng": 52.8581
            },
            "Khomeyn\u012b Shahr": {
                "lat": 32.6803,
                "lng": 51.5361
            },
            "Sabzev\u0101r": {
                "lat": 36.2125,
                "lng": 57.6764
            },
            "\u0100mol": {
                "lat": 36.4703,
                "lng": 52.3467
            },
            "P\u0101kdasht": {
                "lat": 35.485,
                "lng": 51.6794
            },
            "Najaf\u0101b\u0101d": {
                "lat": 32.6447,
                "lng": 51.3603
            },
            "Bor\u016bjerd": {
                "lat": 33.9111,
                "lng": 48.7522
            },
            "Madan": {
                "lat": 30.3469,
                "lng": 48.2825
            },
            "Qarchak": {
                "lat": 35.4267,
                "lng": 51.5842
            },
            "Var\u0101m\u012bn": {
                "lat": 35.3508,
                "lng": 51.6358
            },
            "Bandar-e B\u016bshehr": {
                "lat": 28.9264,
                "lng": 50.8514
            },
            "Neysh\u0101b\u016br": {
                "lat": 36.22,
                "lng": 58.82
            },
            "S\u0101veh": {
                "lat": 35.0278,
                "lng": 50.3611
            },
            "B\u012brjand": {
                "lat": 32.8744,
                "lng": 59.2161
            },
            "Nas\u012bm Shahr": {
                "lat": 35.5617,
                "lng": 51.1617
            },
            "Khowy": {
                "lat": 38.5464,
                "lng": 44.9542
            },
            "B\u016bk\u0101n": {
                "lat": 36.515,
                "lng": 46.2075
            },
            "Shahr-e Kord": {
                "lat": 32.3311,
                "lng": 50.8594
            },
            "Semn\u0101n": {
                "lat": 35.5811,
                "lng": 53.3833
            },
            "Fard\u012bs": {
                "lat": 35.73,
                "lng": 50.9822
            },
            "Mar\u0101gheh": {
                "lat": 37.3894,
                "lng": 46.2378
            },
            "S\u012brj\u0101n": {
                "lat": 29.437,
                "lng": 55.6802
            },
            "Sh\u0101h\u012bn Shahr": {
                "lat": 32.8606,
                "lng": 51.5531
            },
            "Mal\u0101yer": {
                "lat": 34.3,
                "lng": 48.8178
            },
            "Mah\u0101b\u0101d": {
                "lat": 36.7678,
                "lng": 45.7339
            },
            "Saqqez": {
                "lat": 36.2411,
                "lng": 46.2706
            },
            "Bandar-e M\u0101hshahr": {
                "lat": 30.5567,
                "lng": 49.1897
            },
            "Rafsanj\u0101n": {
                "lat": 30.4111,
                "lng": 55.9911
            },
            "Bor\u0101zj\u0101n": {
                "lat": 29.2694,
                "lng": 51.22
            },
            "Gonbad-e K\u0101v\u016bs": {
                "lat": 37.2433,
                "lng": 55.1692
            },
            "Sh\u0101hr\u016bd": {
                "lat": 36.4133,
                "lng": 54.9781
            },
            "Marvdasht": {
                "lat": 29.8786,
                "lng": 52.8067
            },
            "Q\u016bch\u0101n": {
                "lat": 37.106,
                "lng": 58.5095
            },
            "Jahrom": {
                "lat": 28.5031,
                "lng": 53.5658
            },
            "Kam\u0101lshahr": {
                "lat": 35.8658,
                "lng": 50.8717
            },
            "Torbat-e \u1e28eydar\u012byeh": {
                "lat": 35.29,
                "lng": 59.2169
            },
            "Mar\u012bv\u0101n": {
                "lat": 35.5217,
                "lng": 46.1806
            },
            "And\u012bmeshk": {
                "lat": 32.4714,
                "lng": 48.3489
            },
            "Shahre\u1e95\u0101": {
                "lat": 32.0122,
                "lng": 51.8594
            },
            "Z\u0101bol": {
                "lat": 31.0339,
                "lng": 61.4878
            },
            "Khorramshahr": {
                "lat": 30.4394,
                "lng": 48.1817
            },
            "Marand": {
                "lat": 38.4331,
                "lng": 45.7686
            },
            "J\u012broft": {
                "lat": 28.6797,
                "lng": 57.7447
            },
            "Salm\u0101s": {
                "lat": 38.2028,
                "lng": 44.7669
            },
            "Bam": {
                "lat": 29.0994,
                "lng": 58.3436
            },
            "Behbah\u0101n": {
                "lat": 30.5942,
                "lng": 50.2433
            },
            "Dor\u016bd": {
                "lat": 33.4994,
                "lng": 49.0531
            },
            "Naz\u0327ar\u0101b\u0101d": {
                "lat": 35.9583,
                "lng": 50.605
            },
            "Mo\u1e29ammad Shahr": {
                "lat": 35.7483,
                "lng": 50.9025
            },
            "Bandar-e Anzal\u012b": {
                "lat": 37.4708,
                "lng": 49.47
            },
            "\u012ar\u0101nshahr": {
                "lat": 27.1978,
                "lng": 60.6836
            },
            "Fas\u0101": {
                "lat": 28.9483,
                "lng": 53.6336
            },
            "B\u0101neh": {
                "lat": 35.9969,
                "lng": 45.8853
            },
            "Y\u0101s\u016bj": {
                "lat": 30.6672,
                "lng": 51.5797
            },
            "Ch\u0101bah\u0101r": {
                "lat": 25.2928,
                "lng": 60.6497
            },
            "Rob\u0101\u0163 Kar\u012bm": {
                "lat": 35.4825,
                "lng": 51.0786
            },
            "K\u0101shmar": {
                "lat": 35.2431,
                "lng": 58.4594
            },
            "Sh\u016bshtar": {
                "lat": 32.045,
                "lng": 48.8594
            },
            "Ahar": {
                "lat": 38.4797,
                "lng": 47.0669
            },
            "Masjed Soleym\u0101n": {
                "lat": 31.96,
                "lng": 49.2881
            },
            "Torbat-e J\u0101m": {
                "lat": 35.2431,
                "lng": 60.625
            },
            "Abhar": {
                "lat": 36.1511,
                "lng": 49.2244
            },
            "M\u012b\u0101neh": {
                "lat": 37.4239,
                "lng": 47.7197
            },
            "Do Gonbad\u0101n": {
                "lat": 30.36,
                "lng": 50.7839
            },
            "K\u0101zer\u016bn": {
                "lat": 29.6156,
                "lng": 51.6567
            },
            "Behshahr": {
                "lat": 36.6911,
                "lng": 53.5539
            },
            "Alvand": {
                "lat": 36.1867,
                "lng": 50.0639
            },
            "P\u012br\u0101nshahr": {
                "lat": 36.6956,
                "lng": 45.1456
            },
            "Esl\u0101m\u0101b\u0101d-e Gharb": {
                "lat": 34.1103,
                "lng": 46.5275
            },
            "K\u016bhdasht": {
                "lat": 33.5294,
                "lng": 47.6092
            },
            "Khowr\u0101sg\u0101n": {
                "lat": 32.6539,
                "lng": 51.7553
            },
            "Sh\u012brv\u0101n": {
                "lat": 37.4006,
                "lng": 57.9278
            },
            "Meybod": {
                "lat": 32.2444,
                "lng": 54.0186
            },
            "T\u0101kest\u0101n": {
                "lat": 36.0672,
                "lng": 49.6958
            },
            "Langar\u016bd": {
                "lat": 37.1956,
                "lng": 50.1517
            },
            "B\u016bmahen": {
                "lat": 35.7314,
                "lng": 51.8675
            },
            "Bah\u0101rest\u0101n": {
                "lat": 32.4867,
                "lng": 51.7808
            },
            "Al\u012bg\u016bdarz": {
                "lat": 33.4025,
                "lng": 49.6903
            },
            "Bandar Em\u0101m": {
                "lat": 30.5058,
                "lng": 49.0742
            },
            "Qorveh": {
                "lat": 35.1678,
                "lng": 47.8008
            },
            "Sh\u016bsh": {
                "lat": 32.1947,
                "lng": 48.2475
            },
            "Nah\u0101vand": {
                "lat": 34.1928,
                "lng": 48.3731
            },
            "Bon\u0101b": {
                "lat": 37.3428,
                "lng": 46.0617
            },
            "Ardak\u0101n": {
                "lat": 32.3061,
                "lng": 54.0081
            },
            "R\u0101mhormoz": {
                "lat": 31.2753,
                "lng": 49.6044
            },
            "Meshg\u012bn Shahr": {
                "lat": 38.3972,
                "lng": 47.6739
            },
            "Bandar-e Gen\u0101veh": {
                "lat": 29.5839,
                "lng": 50.5189
            },
            "Naqadeh": {
                "lat": 36.9547,
                "lng": 45.3881
            },
            "Khomeyn": {
                "lat": 33.6422,
                "lng": 50.0789
            },
            "Om\u012bd\u012byeh": {
                "lat": 30.7583,
                "lng": 49.705
            },
            "\u0100r\u0101n B\u012bdgol": {
                "lat": 34.0589,
                "lng": 51.4817
            },
            "B\u0101qershahr": {
                "lat": 35.5322,
                "lng": 51.4036
            },
            "Zarand": {
                "lat": 30.8108,
                "lng": 56.5658
            },
            "M\u0101hd\u0101sht": {
                "lat": 35.7194,
                "lng": 50.7978
            },
            "Mob\u0101rakeh": {
                "lat": 32.3494,
                "lng": 51.5
            },
            "Meshk\u012bn Dasht": {
                "lat": 35.7469,
                "lng": 50.9361
            },
            "Nek\u0101": {
                "lat": 36.65,
                "lng": 53.2972
            },
            "Kang\u0101n": {
                "lat": 27.8389,
                "lng": 52.0619
            },
            "\u0100byek": {
                "lat": 36.0411,
                "lng": 50.5306
            },
            "Sar\u0101v\u0101n": {
                "lat": 27.3708,
                "lng": 62.3325
            },
            "B\u0101bolsar": {
                "lat": 36.7006,
                "lng": 52.65
            },
            "Esfar\u0101yen": {
                "lat": 37.0703,
                "lng": 57.5089
            },
            "D\u0101mgh\u0101n": {
                "lat": 36.1656,
                "lng": 54.3419
            },
            "Bor\u016bjen": {
                "lat": 31.9678,
                "lng": 51.2894
            },
            "Dehdasht": {
                "lat": 30.7953,
                "lng": 50.5653
            },
            "Kh\u0101sh": {
                "lat": 28.22,
                "lng": 61.2114
            },
            "T\u0101yb\u0101d": {
                "lat": 34.7408,
                "lng": 60.7792
            },
            "K\u016bt-e \u2018Abdoll\u0101h": {
                "lat": 31.2447,
                "lng": 48.6594
            },
            "Zarr\u012bn Shahr": {
                "lat": 32.3889,
                "lng": 51.3733
            },
            "Asad\u0101b\u0101d": {
                "lat": 34.7825,
                "lng": 48.1219
            },
            "Hashtgerd": {
                "lat": 35.9619,
                "lng": 50.6889
            },
            "Tonek\u0101bon": {
                "lat": 36.8153,
                "lng": 50.8711
            },
            "Khorramdarreh": {
                "lat": 36.2072,
                "lng": 49.1992
            },
            "Eqb\u0101l\u012byeh": {
                "lat": 36.2306,
                "lng": 49.9225
            },
            "Jav\u0101nr\u016bd": {
                "lat": 34.8078,
                "lng": 46.4939
            },
            "Hashtpar": {
                "lat": 37.7933,
                "lng": 48.9044
            },
            "\u015eabb\u0101shahr": {
                "lat": 35.5817,
                "lng": 51.1119
            },
            "Bandar-e Torkaman": {
                "lat": 36.9014,
                "lng": 54.0736
            },
            "B\u012bj\u0101r": {
                "lat": 35.8729,
                "lng": 47.5993
            },
            "\u2018Al\u012b\u0101b\u0101d-e Kat\u016bl": {
                "lat": 36.9075,
                "lng": 54.8667
            },
            "Kahn\u016bj": {
                "lat": 27.9406,
                "lng": 57.6992
            },
            "Shahr-e B\u0101bak": {
                "lat": 30.1233,
                "lng": 55.1172
            },
            "S\u016bsangerd": {
                "lat": 31.5569,
                "lng": 48.1894
            },
            "T\u016byserk\u0101n": {
                "lat": 34.5494,
                "lng": 48.4456
            },
            "Chah\u0101r Dangeh": {
                "lat": 35.5958,
                "lng": 51.3083
            },
            "Neyr\u012bz": {
                "lat": 29.1928,
                "lng": 54.3197
            },
            "Nowshahr": {
                "lat": 36.6475,
                "lng": 51.4931
            },
            "Garms\u0101r": {
                "lat": 35.2206,
                "lng": 52.3389
            },
            "Dam\u0101vand": {
                "lat": 35.6928,
                "lng": 52.0467
            },
            "Golp\u0101yeg\u0101n": {
                "lat": 33.4508,
                "lng": 50.2817
            },
            "Azn\u0101": {
                "lat": 33.4597,
                "lng": 49.4547
            },
            "\u015eowme\u2018eh Sar\u0101": {
                "lat": 37.3,
                "lng": 49.3142
            },
            "Sardasht": {
                "lat": 36.1553,
                "lng": 45.48
            },
            "B\u0101fq": {
                "lat": 31.6033,
                "lng": 55.4003
            },
            "\u0100st\u0101neh-ye Ashraf\u012byeh": {
                "lat": 37.2658,
                "lng": 49.9431
            },
            "\u0100z\u0304arshahr": {
                "lat": 37.7628,
                "lng": 45.9758
            },
            "Eql\u012bd": {
                "lat": 30.8928,
                "lng": 52.6889
            },
            "Sonqor": {
                "lat": 34.7828,
                "lng": 47.5983
            },
            "Hars\u012bn": {
                "lat": 34.2728,
                "lng": 47.5875
            },
            "\u0100z\u0101dshahr": {
                "lat": 37.0869,
                "lng": 55.1742
            },
            "Tak\u0101b": {
                "lat": 36.4008,
                "lng": 47.1108
            },
            "Kon\u0101rak": {
                "lat": 25.3589,
                "lng": 60.3992
            },
            "Ma\u1e29all\u0101t": {
                "lat": 33.9056,
                "lng": 50.4572
            },
            "Darcheh": {
                "lat": 32.6131,
                "lng": 51.5531
            },
            "Q\u0101\u2019en": {
                "lat": 33.7333,
                "lng": 59.1811
            },
            "Sarakhs": {
                "lat": 36.5425,
                "lng": 61.1589
            },
            "Sar\u0101b": {
                "lat": 37.9414,
                "lng": 47.5367
            },
            "Sh\u0101deg\u0101n": {
                "lat": 30.6492,
                "lng": 48.6653
            },
            "Dowlat\u0101b\u0101d": {
                "lat": 35.2842,
                "lng": 59.5225
            },
            "Del\u012bj\u0101n": {
                "lat": 33.9903,
                "lng": 50.6836
            },
            "Gon\u0101b\u0101d": {
                "lat": 34.3575,
                "lng": 58.6783
            },
            "Khowrm\u016bj": {
                "lat": 28.6567,
                "lng": 51.3808
            },
            "Qeshm": {
                "lat": 26.9494,
                "lng": 56.2681
            },
            "Lordeg\u0101n": {
                "lat": 31.5081,
                "lng": 50.8319
            },
            "K\u012bsh": {
                "lat": 26.5553,
                "lng": 53.985
            },
            "Oshnav\u012byeh": {
                "lat": 37.0375,
                "lng": 45.0981
            },
            "\u0162abas": {
                "lat": 33.5961,
                "lng": 56.9281
            },
            "Far\u012bm\u0101n": {
                "lat": 35.7044,
                "lng": 59.8486
            },
            "Khalkh\u0101l": {
                "lat": 37.6272,
                "lng": 48.53
            },
            "Fereyd\u016bn Ken\u0101r": {
                "lat": 36.685,
                "lng": 52.5208
            },
            "R\u016bdsar": {
                "lat": 37.1406,
                "lng": 50.2942
            },
            "Fal\u0101varj\u0101n": {
                "lat": 32.5558,
                "lng": 51.5106
            },
            "Kahr\u012bzak": {
                "lat": 35.5169,
                "lng": 51.3603
            },
            "Dargaz": {
                "lat": 37.4456,
                "lng": 59.1078
            },
            "Estahb\u0101n": {
                "lat": 29.1269,
                "lng": 54.0394
            },
            "Kal\u0101leh": {
                "lat": 37.3789,
                "lng": 55.4889
            },
            "R\u0101msar": {
                "lat": 36.9225,
                "lng": 50.6431
            },
            "\u015ea\u1e29neh": {
                "lat": 34.4811,
                "lng": 47.68
            },
            "Khod\u0101bandeh": {
                "lat": 36.1194,
                "lng": 48.5917
            },
            "Sarpol-e Z\u0304ah\u0101b": {
                "lat": 34.4589,
                "lng": 45.8614
            },
            "B\u0101ft": {
                "lat": 29.235,
                "lng": 56.5989
            },
            "Ger\u0101sh": {
                "lat": 27.6667,
                "lng": 54.1411
            },
            "N\u0101z\u0327er\u0101b\u0101d": {
                "lat": 31.5775,
                "lng": 54.4369
            },
            "Sh\u0101h\u012bn Dezh": {
                "lat": 36.6806,
                "lng": 46.5681
            },
            "D\u012bv\u0101ndarreh": {
                "lat": 35.9158,
                "lng": 47.0228
            },
            "\u2018Ajab Sh\u012br": {
                "lat": 37.4833,
                "lng": 45.8911
            },
            "Aleshtar": {
                "lat": 33.8653,
                "lng": 48.2622
            },
            "Khv\u0101f": {
                "lat": 34.5711,
                "lng": 60.1439
            },
            "Dehlor\u0101n": {
                "lat": 32.6892,
                "lng": 47.2689
            },
            "Zarq\u0101n": {
                "lat": 29.7683,
                "lng": 52.7186
            },
            "Farrokh Shahr": {
                "lat": 32.2714,
                "lng": 50.9775
            },
            "Kav\u0101r": {
                "lat": 29.2017,
                "lng": 52.6917
            },
            "Jam": {
                "lat": 27.8236,
                "lng": 52.3303
            },
            "Eyv\u0101n": {
                "lat": 33.8278,
                "lng": 46.3061
            },
            "F\u0101rs\u0101n": {
                "lat": 32.2553,
                "lng": 50.5711
            },
            "Bandar-e Lengeh": {
                "lat": 26.5594,
                "lng": 54.885
            },
            "M\u012bn\u016bdasht": {
                "lat": 37.2306,
                "lng": 55.3736
            },
            "Qahder\u012bj\u0101n": {
                "lat": 32.5772,
                "lng": 51.4539
            },
            "Eshteh\u0101rd": {
                "lat": 35.7247,
                "lng": 50.3717
            },
            "B\u0101zeh Kal\u0101gh": {
                "lat": 36.5325,
                "lng": 59.3636
            },
            "Chahchaheh": {
                "lat": 36.5325,
                "lng": 59.3636
            },
            "Bozmarg\u012b": {
                "lat": 36.5325,
                "lng": 59.3636
            },
            "Kal\u0101teh-ye M\u012br \u0100b": {
                "lat": 36.5325,
                "lng": 59.3636
            },
            "Sardr\u016bd": {
                "lat": 38.0286,
                "lng": 46.1475
            },
            "Karahr\u016bd": {
                "lat": 34.0628,
                "lng": 49.6461
            },
            "L\u0101merd": {
                "lat": 27.3336,
                "lng": 53.1886
            },
            "Hend\u012bj\u0101n": {
                "lat": 30.2383,
                "lng": 49.7075
            },
            "Germ\u012b": {
                "lat": 39.0297,
                "lng": 48.0803
            },
            "Ferdows": {
                "lat": 34.0192,
                "lng": 58.1731
            },
            "R\u016bdehen": {
                "lat": 35.7333,
                "lng": 51.9192
            },
            "Bardaskan": {
                "lat": 35.2625,
                "lng": 57.9703
            },
            "F\u016bman": {
                "lat": 37.2242,
                "lng": 49.3125
            },
            "Abark\u016bh": {
                "lat": 31.1267,
                "lng": 53.2592
            },
            "Malek\u0101n": {
                "lat": 37.15,
                "lng": 46.1042
            },
            "\u015eaf\u0101shahr": {
                "lat": 30.6133,
                "lng": 53.1928
            },
            "Q\u0101\u2019em\u012byeh": {
                "lat": 29.8525,
                "lng": 51.5842
            },
            "Qarah \u1e94\u012b\u0101\u2019 od D\u012bn": {
                "lat": 38.89,
                "lng": 45.0283
            },
            "Poldokhtar": {
                "lat": 33.1489,
                "lng": 47.7164
            },
            "B\u0101gh-e Malek": {
                "lat": 31.5247,
                "lng": 49.8869
            },
            "Sem\u012brom": {
                "lat": 31.4094,
                "lng": 51.5736
            },
            "Dehgol\u0101n": {
                "lat": 35.2778,
                "lng": 47.4175
            },
            "P\u0101veh": {
                "lat": 35.0433,
                "lng": 46.355
            },
            "Bandar-e Deylam": {
                "lat": 30.0542,
                "lng": 50.1639
            },
            "Sh\u0101hedshahr": {
                "lat": 35.5731,
                "lng": 51.0869
            },
            "Show\u0163": {
                "lat": 39.2197,
                "lng": 44.7722
            },
            "Bards\u012br": {
                "lat": 29.9275,
                "lng": 56.5764
            },
            "\u0100shkh\u0101neh": {
                "lat": 37.5578,
                "lng": 56.9258
            },
            "R\u0101msh\u012br": {
                "lat": 30.8936,
                "lng": 49.4086
            },
            "Rav\u0101nsar": {
                "lat": 34.7125,
                "lng": 46.6511
            },
            "Mahd\u012bshahr": {
                "lat": 35.7144,
                "lng": 53.3569
            },
            "Gaz": {
                "lat": 32.8061,
                "lng": 51.6206
            },
            "Gotvand": {
                "lat": 32.2433,
                "lng": 48.8136
            },
            "Deyr": {
                "lat": 27.8417,
                "lng": 51.9394
            },
            "G\u0101l\u012bkesh": {
                "lat": 37.2728,
                "lng": 55.4328
            },
            "Sheyb\u0101n": {
                "lat": 31.4061,
                "lng": 48.7944
            },
            "Kel\u012bsh\u0101d va S\u016bdarj\u0101n": {
                "lat": 32.5489,
                "lng": 51.5286
            },
            "\u2018\u0100l\u012b Shahr": {
                "lat": 28.9306,
                "lng": 51.0689
            },
            "Barav\u0101t": {
                "lat": 29.0656,
                "lng": 58.4039
            },
            "R\u0101var": {
                "lat": 31.2633,
                "lng": 56.8072
            },
            "Garmdarreh": {
                "lat": 35.7478,
                "lng": 51.0678
            },
            "Goldasht": {
                "lat": 32.6269,
                "lng": 51.4386
            },
            "G\u012bl\u0101n-e Gharb": {
                "lat": 34.1406,
                "lng": 45.9183
            },
            "Shabestar": {
                "lat": 38.18,
                "lng": 45.7039
            },
            "\u1e28am\u012bd\u012byeh": {
                "lat": 31.4811,
                "lng": 48.435
            },
            "Khosrowshahr": {
                "lat": 37.9497,
                "lng": 46.0508
            },
            "Darreh Shahr": {
                "lat": 33.1431,
                "lng": 47.3794
            },
            "Ma\u2019m\u016bn\u012byeh": {
                "lat": 35.305,
                "lng": 50.4975
            },
            "Hafshej\u0101n": {
                "lat": 32.2269,
                "lng": 50.7931
            },
            "Gal\u016bg\u0101h": {
                "lat": 36.7269,
                "lng": 53.8089
            },
            "Sh\u0101zand": {
                "lat": 33.9292,
                "lng": 49.4103
            },
            "\u0162orqabeh": {
                "lat": 36.3142,
                "lng": 59.3725
            },
            "Bandar-e Gaz": {
                "lat": 36.7739,
                "lng": 53.9481
            },
            "Mo\u1e29ammad\u0101b\u0101d": {
                "lat": 28.6517,
                "lng": 59.0158
            },
            "Hashtr\u016bd": {
                "lat": 37.4714,
                "lng": 47.0583
            },
            "Khv\u0101ns\u0101r": {
                "lat": 33.2208,
                "lng": 50.3153
            },
            "Far\u0101shband": {
                "lat": 28.8564,
                "lng": 52.0964
            },
            "Khowrz\u016bq": {
                "lat": 32.7783,
                "lng": 51.6478
            },
            "Evaz": {
                "lat": 27.76,
                "lng": 54.0067
            },
            "S\u012b\u0101hkal": {
                "lat": 37.1506,
                "lng": 49.8714
            },
            "L\u012bkak": {
                "lat": 30.8928,
                "lng": 50.0928
            },
            "Ma\u1e29m\u016bd\u0101b\u0101d Nem\u016bneh": {
                "lat": 36.2903,
                "lng": 49.8994
            },
            "J\u0101jarm": {
                "lat": 36.9519,
                "lng": 56.3758
            },
            "Hoveyzeh": {
                "lat": 31.4606,
                "lng": 48.0781
            },
            "Abr\u012bsham": {
                "lat": 32.5675,
                "lng": 51.5733
            },
            "Mah\u0101jer\u0101n-e Kamar": {
                "lat": 34.0486,
                "lng": 49.4311
            },
            "Khonj": {
                "lat": 27.8897,
                "lng": 53.4308
            },
            "Kab\u016bdar\u0101hang": {
                "lat": 35.2094,
                "lng": 48.7236
            },
            "Bandar-e Kong": {
                "lat": 26.5947,
                "lng": 54.9381
            },
            "Gom\u012bsh\u0101n": {
                "lat": 37.07,
                "lng": 54.0769
            },
            "Ashkezar": {
                "lat": 32.0006,
                "lng": 54.2075
            },
            "\u0100b Pakhsh": {
                "lat": 29.3606,
                "lng": 51.0683
            },
            "Nakhl-e Taq\u012b": {
                "lat": 27.5014,
                "lng": 52.5858
            },
            "Chogh\u0101dak": {
                "lat": 28.9864,
                "lng": 51.0375
            },
            "Kher\u0101meh": {
                "lat": 29.4992,
                "lng": 53.3131
            },
            "Qa\u015fr-e Sh\u012br\u012bn": {
                "lat": 34.5158,
                "lng": 45.5772
            },
            "Taft": {
                "lat": 31.7492,
                "lng": 54.2103
            },
            "Osk\u016b": {
                "lat": 37.9147,
                "lng": 46.1217
            },
            "N\u012b\u0101r": {
                "lat": 38.2369,
                "lng": 48.3272
            },
            "Gerd Far\u0101marz Sh\u0101hed\u012byeh": {
                "lat": 31.9417,
                "lng": 54.2677
            },
            "Abrand\u0101b\u0101d-e Sh\u0101hed\u012byeh": {
                "lat": 31.9408,
                "lng": 54.2736
            },
            "Nehband\u0101n": {
                "lat": 31.5436,
                "lng": 60.0372
            },
            "Sarvest\u0101n": {
                "lat": 29.2739,
                "lng": 53.2203
            },
            "\u2018Anbar\u0101b\u0101d": {
                "lat": 28.4794,
                "lng": 57.8414
            },
            "Lav\u0101s\u0101n": {
                "lat": 35.8183,
                "lng": 51.6383
            },
            "Fey\u1e95\u0101b\u0101d": {
                "lat": 35.0183,
                "lng": 58.7839
            },
            "B\u012bdest\u0101n": {
                "lat": 36.2308,
                "lng": 50.1236
            },
            "D\u012bz\u012bcheh": {
                "lat": 32.3869,
                "lng": 51.5122
            },
            "Seyah Cheshmeh": {
                "lat": 39.0631,
                "lng": 44.3836
            },
            "N\u012bkshahr": {
                "lat": 26.2253,
                "lng": 60.2167
            },
            "Arsanj\u0101n": {
                "lat": 29.9142,
                "lng": 53.3058
            },
            "Mehr\u0101n": {
                "lat": 33.1208,
                "lng": 46.1642
            },
            "Sedeh Lanj\u0101n": {
                "lat": 32.3786,
                "lng": 51.3178
            },
            "Sa\u2018\u0101dat Shahr": {
                "lat": 30.0772,
                "lng": 53.1328
            },
            "Deh\u0101q\u0101n": {
                "lat": 31.9386,
                "lng": 51.6475
            },
            "J\u0101sk": {
                "lat": 25.6531,
                "lng": 57.7817
            },
            "\u012alkhch\u012b": {
                "lat": 37.9373,
                "lng": 45.979
            },
            "\u012alkhech\u012b": {
                "lat": 37.9378,
                "lng": 45.9803
            },
            "Tafresh": {
                "lat": 34.6939,
                "lng": 50.0156
            },
            "Boshr\u016byeh": {
                "lat": 33.8681,
                "lng": 57.4286
            },
            "B\u012bleh Sav\u0101r": {
                "lat": 39.3778,
                "lng": 48.3497
            },
            "Haftkel": {
                "lat": 31.4444,
                "lng": 49.5314
            },
            "Ardest\u0101n": {
                "lat": 33.3731,
                "lng": 52.3689
            },
            "T\u012br\u0101n": {
                "lat": 32.7033,
                "lng": 51.1558
            },
            "Man\u016bj\u0101n": {
                "lat": 27.4031,
                "lng": 57.4917
            },
            "Manj\u012bl": {
                "lat": 36.7447,
                "lng": 49.4014
            },
            "Chamgard\u0101n": {
                "lat": 32.3931,
                "lng": 51.3392
            },
            "An\u0101r": {
                "lat": 30.8694,
                "lng": 55.2678
            },
            "Dastgerd": {
                "lat": 32.8008,
                "lng": 51.6647
            },
            "Morte\u1e95\u00e1 Gerd": {
                "lat": 35.6019,
                "lng": 51.3486
            },
            "Bandar-e Kham\u012br": {
                "lat": 26.9511,
                "lng": 55.5867
            },
            "Veys": {
                "lat": 31.4861,
                "lng": 48.8756
            },
            "Sh\u0101l": {
                "lat": 35.8975,
                "lng": 49.7694
            },
            "Char\u0101m": {
                "lat": 30.7461,
                "lng": 50.7461
            },
            "Ahram": {
                "lat": 28.8833,
                "lng": 51.275
            },
            "Amlash": {
                "lat": 37.0944,
                "lng": 50.1928
            },
            "L\u0101lej\u012bn": {
                "lat": 34.9742,
                "lng": 48.4756
            },
            "Neq\u0101b": {
                "lat": 36.7072,
                "lng": 57.4194
            },
            "Sep\u012bd\u0101n": {
                "lat": 30.2594,
                "lng": 51.9853
            },
            "Dargah\u0101n": {
                "lat": 26.9667,
                "lng": 56.0708
            },
            "Razan": {
                "lat": 35.3886,
                "lng": 49.0331
            },
            "Na\u0163anz": {
                "lat": 33.5072,
                "lng": 51.9136
            },
            "K\u012b\u0101shahr": {
                "lat": 37.4214,
                "lng": 49.9408
            },
            "F\u0101men\u012bn": {
                "lat": 35.1139,
                "lng": 48.9725
            },
            "Sh\u0101nd\u012bz": {
                "lat": 36.3961,
                "lng": 59.3092
            },
            "H\u012bdaj": {
                "lat": 36.2547,
                "lng": 49.1311
            },
            "Dowbar\u0101n": {
                "lat": 28.405,
                "lng": 54.1878
            },
            "Sar\u0101y\u0101n": {
                "lat": 33.8614,
                "lng": 58.5217
            },
            "Rostam\u0101b\u0101d": {
                "lat": 36.8994,
                "lng": 49.4931
            },
            "Nam\u012bn": {
                "lat": 38.4256,
                "lng": 48.4839
            },
            "Jannat Shahr": {
                "lat": 28.6569,
                "lng": 54.6861
            },
            "S\u016br\u0101n": {
                "lat": 27.2856,
                "lng": 61.9964
            },
            "Bandar-e \u2018Asal\u016byeh": {
                "lat": 27.4744,
                "lng": 52.6114
            },
            "Kharv-e Sofl\u00e1": {
                "lat": 36.1403,
                "lng": 59.0075
            },
            "Fereyd\u016bnshahr": {
                "lat": 32.9419,
                "lng": 50.1203
            },
            "Kal\u0101rdasht": {
                "lat": 36.5056,
                "lng": 51.1589
            },
            "Zehak": {
                "lat": 30.8933,
                "lng": 61.6789
            },
            "Far\u0101donbeh": {
                "lat": 32.0078,
                "lng": 51.2156
            },
            "R\u0101bor": {
                "lat": 29.2903,
                "lng": 56.9133
            },
            "Qal\u2018eh Ganj": {
                "lat": 27.525,
                "lng": 57.88
            },
            "Fann\u016bj": {
                "lat": 26.5753,
                "lng": 59.6403
            },
            "Lowsh\u0101n": {
                "lat": 36.6278,
                "lng": 49.5114
            },
            "Shahr-e Her\u0101t": {
                "lat": 30.0547,
                "lng": 54.3717
            },
            "Ben": {
                "lat": 32.5444,
                "lng": 50.7461
            },
            "Khom\u0101m": {
                "lat": 37.3911,
                "lng": 49.6597
            },
            "Landeh": {
                "lat": 30.9808,
                "lng": 50.4214
            },
            "Varzaneh": {
                "lat": 32.4197,
                "lng": 52.6478
            },
            "B\u0101smenj": {
                "lat": 37.9964,
                "lng": 46.4717
            },
            "Sangar": {
                "lat": 37.1797,
                "lng": 49.6942
            },
            "N\u0101ysar": {
                "lat": 35.3267,
                "lng": 47.0686
            },
            "Eyv\u0101nekey": {
                "lat": 35.3444,
                "lng": 52.0714
            },
            "Sang\u0101n": {
                "lat": 34.3994,
                "lng": 60.2558
            },
            "Sar\u0101bleh": {
                "lat": 33.7683,
                "lng": 46.5619
            },
            "Asfarvar\u012bn": {
                "lat": 35.9358,
                "lng": 49.7469
            },
            "Re\u1e95v\u0101nshahr": {
                "lat": 37.5481,
                "lng": 49.1356
            },
            "S\u016breshj\u0101n": {
                "lat": 32.3156,
                "lng": 50.6783
            },
            "Charmah\u012bn": {
                "lat": 32.3372,
                "lng": 51.1933
            },
            "Senj\u0101n": {
                "lat": 34.0506,
                "lng": 49.6214
            },
            "Z\u0101bol\u012b": {
                "lat": 27.1283,
                "lng": 61.6722
            },
            "Bamp\u016br": {
                "lat": 27.195,
                "lng": 60.4547
            },
            "Bold\u0101j\u012b": {
                "lat": 31.9383,
                "lng": 51.0533
            },
            "Arakv\u0101z-e Maleksh\u0101h\u012b": {
                "lat": 33.3847,
                "lng": 46.5978
            },
            "Mamq\u0101n": {
                "lat": 37.8458,
                "lng": 45.9719
            },
            "Bajest\u0101n": {
                "lat": 34.5161,
                "lng": 58.1842
            },
            "Z\u0101rach": {
                "lat": 31.9911,
                "lng": 54.2322
            },
            "Rostam Kol\u0101": {
                "lat": 36.6789,
                "lng": 53.4297
            },
            "Qa\u015fr-e Qand": {
                "lat": 26.2356,
                "lng": 60.7408
            },
            "Poldasht": {
                "lat": 39.3447,
                "lng": 45.0681
            },
            "Kashaf": {
                "lat": 36.3678,
                "lng": 59.5767
            },
            "K\u016bshk": {
                "lat": 32.6419,
                "lng": 51.4997
            },
            "Lavandev\u012bl": {
                "lat": 38.3089,
                "lng": 48.8703
            },
            "Va\u1e29datt\u012byeh": {
                "lat": 29.4842,
                "lng": 51.2369
            },
            "Hamad\u0101nak": {
                "lat": 35.5422,
                "lng": 51.1383
            },
            "Sar\u0101b-e T\u0101veh-ye \u2018Oly\u0101": {
                "lat": 30.5964,
                "lng": 51.5725
            },
            "Chamest\u0101n": {
                "lat": 36.4792,
                "lng": 52.1194
            },
            "Shahm\u012brz\u0101d": {
                "lat": 35.7692,
                "lng": 53.3361
            },
            "Arvand Ken\u0101r": {
                "lat": 29.9786,
                "lng": 48.5189
            },
            "K\u0101r\u012bz": {
                "lat": 34.8117,
                "lng": 60.8239
            },
            "\u015e\u0101\u2019\u012bn Qal\u2018eh": {
                "lat": 36.3058,
                "lng": 49.0739
            },
            "\u1e28a\u015f\u015feh": {
                "lat": 32.7067,
                "lng": 51.7556
            },
            "Garmeh": {
                "lat": 36.9864,
                "lng": 56.2881
            },
            "Qa\u015fr-e Qomsheh": {
                "lat": 29.7756,
                "lng": 52.4417
            },
            "Kh\u0101n Beb\u012bn": {
                "lat": 37.0097,
                "lng": 54.9858
            },
            "Shams\u0101b\u0101d": {
                "lat": 32.2978,
                "lng": 48.4283
            },
            "P\u012br Bakr\u0101n": {
                "lat": 32.4678,
                "lng": 51.5556
            },
            "Gol Tappeh": {
                "lat": 35.4872,
                "lng": 51.565
            },
            "Mary\u0101naj": {
                "lat": 34.8297,
                "lng": 48.4578
            },
            "Qal\u2018eh Chan\u2018\u0101n": {
                "lat": 31.2403,
                "lng": 48.7011
            },
            "B\u0101sht": {
                "lat": 30.3611,
                "lng": 51.1558
            },
            "K\u016bhban\u0101n": {
                "lat": 31.41,
                "lng": 56.2825
            },
            "Deh Bakr\u012b": {
                "lat": 29.0508,
                "lng": 57.9167
            },
            "As\u0101lem": {
                "lat": 37.7211,
                "lng": 48.9583
            },
            "Qal\u2018eh Tall": {
                "lat": 31.6331,
                "lng": 49.8897
            },
            "Darb-e Behesht": {
                "lat": 29.2331,
                "lng": 57.3397
            },
            "Dakh\u0101n": {
                "lat": 35.125,
                "lng": 49.5175
            },
            "Lasht-e Nesh\u0101": {
                "lat": 37.3608,
                "lng": 49.8611
            },
            "Her\u012bs": {
                "lat": 38.2475,
                "lng": 47.1164
            },
            "B\u0101gh\u012bn": {
                "lat": 30.1839,
                "lng": 56.8075
            },
            "Hach\u012br\u016bd": {
                "lat": 36.6856,
                "lng": 51.345
            },
            "N\u016bk\u0101n": {
                "lat": 34.3608,
                "lng": 47.1725
            },
            "Shahrak-e Enqel\u0101b": {
                "lat": 35.5156,
                "lng": 51.6867
            },
            "Q\u0101r\u012b Kol\u0101-ye Ara\u0163eh": {
                "lat": 36.4917,
                "lng": 52.9531
            },
            "Dalg\u0101n": {
                "lat": 27.4825,
                "lng": 59.4464
            },
            "R\u0101yen": {
                "lat": 29.5981,
                "lng": 57.4383
            },
            "B\u0101gh-e Bah\u0101dor\u0101n": {
                "lat": 32.3756,
                "lng": 51.1881
            },
            "Meymand": {
                "lat": 28.8683,
                "lng": 52.7525
            },
            "R\u0101sak": {
                "lat": 26.2356,
                "lng": 61.4014
            },
            "Ardal": {
                "lat": 31.9989,
                "lng": 50.6617
            },
            "Mashhad R\u012bzeh": {
                "lat": 34.7947,
                "lng": 60.5144
            },
            "K\u016bche\u015ffah\u0101n": {
                "lat": 37.2783,
                "lng": 49.7728
            },
            "Galleh D\u0101r": {
                "lat": 27.6578,
                "lng": 52.6594
            },
            "Ma\u1e29alleh-ye Sh\u012br\u012bn\u016b": {
                "lat": 27.6258,
                "lng": 52.4761
            },
            "\u015e\u016bf\u012b\u0101n": {
                "lat": 38.2722,
                "lng": 45.9789
            },
            "Bastak": {
                "lat": 27.1953,
                "lng": 54.3678
            },
            "Sorkheh": {
                "lat": 35.4644,
                "lng": 53.2086
            },
            "M\u012b\u0101ndasht": {
                "lat": 33.0758,
                "lng": 50.1631
            },
            "\u0162\u0101lkhvoncheh": {
                "lat": 32.2614,
                "lng": 51.5619
            },
            "Bav\u0101n\u0101t": {
                "lat": 30.4717,
                "lng": 53.6261
            },
            "Z\u0101hed Shahr": {
                "lat": 28.7461,
                "lng": 53.8039
            },
            "Qahj\u0101varest\u0101n": {
                "lat": 32.7017,
                "lng": 51.8358
            },
            "Gorg\u0101b": {
                "lat": 32.8661,
                "lng": 51.5972
            },
            "Salm\u0101nshahr": {
                "lat": 36.7039,
                "lng": 51.1936
            },
            "Khesht": {
                "lat": 29.5633,
                "lng": 51.335
            },
            "Shahrak-e Ja\u2018far\u012byeh": {
                "lat": 35.6617,
                "lng": 50.9947
            },
            "D\u0101nesfah\u0101n": {
                "lat": 35.8117,
                "lng": 49.7431
            },
            "Chah\u0101r Borj-e Qad\u012bm": {
                "lat": 37.1231,
                "lng": 45.9761
            },
            "Ja\u2018far\u012byeh": {
                "lat": 34.7739,
                "lng": 50.5164
            },
            "Marvast": {
                "lat": 30.4775,
                "lng": 54.2128
            },
            "Shahr-e Majles\u012b": {
                "lat": 32.1956,
                "lng": 51.5017
            },
            "M\u012brj\u0101veh": {
                "lat": 29.0147,
                "lng": 61.4503
            },
            "Kaleybar": {
                "lat": 38.8664,
                "lng": 47.0406
            },
            "Mo\u1e29ammad Y\u0101r": {
                "lat": 36.9819,
                "lng": 45.5222
            },
            "M\u012bl\u0101jerd": {
                "lat": 34.6214,
                "lng": 49.2081
            },
            "Ab\u016bz\u0304ar-e Ghaff\u0101r\u012b": {
                "lat": 32.1806,
                "lng": 48.2778
            },
            "Joght\u0101y": {
                "lat": 36.6383,
                "lng": 57.0717
            },
            "J\u016braq\u0101n": {
                "lat": 34.885,
                "lng": 48.5531
            },
            "Bah\u0101b\u0101d": {
                "lat": 31.8719,
                "lng": 56.0239
            },
            "S\u016brak": {
                "lat": 36.5939,
                "lng": 53.2086
            },
            "Golb\u0101f": {
                "lat": 29.8828,
                "lng": 57.7322
            },
            "\u1e28orr-e R\u012b\u0101\u1e29\u012b": {
                "lat": 32.1444,
                "lng": 48.3906
            },
            "Nasht\u012bf\u0101n": {
                "lat": 34.435,
                "lng": 60.1811
            },
            "Eshkan\u0101n": {
                "lat": 27.2278,
                "lng": 53.6072
            },
            "Zeyd\u0101b\u0101d": {
                "lat": 29.6008,
                "lng": 55.5367
            },
            "Ban\u0101r\u016byeh": {
                "lat": 28.0864,
                "lng": 54.0481
            },
            "Shahr-e P\u012br": {
                "lat": 28.3122,
                "lng": 54.3328
            },
            "B\u012bdkh\u016bn": {
                "lat": 27.4792,
                "lng": 52.6681
            },
            "P\u0101\u2019\u012bn Ch\u0101f": {
                "lat": 37.2294,
                "lng": 50.2544
            },
            "Moslem Ebn-e \u2018Aq\u012bl": {
                "lat": 32.0561,
                "lng": 48.3056
            },
            "Jolf\u0101": {
                "lat": 38.9386,
                "lng": 45.6253
            },
            "Kom\u012bj\u0101n": {
                "lat": 34.7181,
                "lng": 49.325
            },
            "\u0100sht\u012b\u0101n": {
                "lat": 34.5222,
                "lng": 50.0069
            },
            "Banak": {
                "lat": 27.8722,
                "lng": 52.0289
            },
            "Sarb\u012bsheh": {
                "lat": 32.5775,
                "lng": 59.7961
            },
            "Pardanj\u0101n": {
                "lat": 32.2539,
                "lng": 50.5975
            },
            "Azandar\u012b\u0101n": {
                "lat": 34.5044,
                "lng": 48.6919
            },
            "Mar\u0101veh Tappeh": {
                "lat": 37.9031,
                "lng": 55.9567
            },
            "Sa\u2018\u012bd\u012b": {
                "lat": 30.3364,
                "lng": 57.0994
            },
            "Shahrak-e P\u0101rs": {
                "lat": 35.6081,
                "lng": 51.3808
            },
            "Seyyed\u0101n": {
                "lat": 30.0019,
                "lng": 53.0033
            },
            "Kond R\u016bd": {
                "lat": 38.0094,
                "lng": 46.4794
            },
            "Nal\u0101s": {
                "lat": 36.2631,
                "lng": 45.4933
            },
            "S\u0101r\u012b": {
                "lat": 36.5633,
                "lng": 53.0601
            },
            "\u012al\u0101m": {
                "lat": 33.6374,
                "lng": 46.4227
            }
        }
    },
    "CD": {
        "name": "Congo (Kinshasa)",
        "cities": {
            "Kinshasa": {
                "lat": -4.3219,
                "lng": 15.3119
            },
            "Mbuji-Mayi": {
                "lat": -6.15,
                "lng": 23.6
            },
            "Kananga": {
                "lat": -5.897,
                "lng": 22.4488
            },
            "Lubumbashi": {
                "lat": -11.6647,
                "lng": 27.4794
            },
            "Katako-Kombe": {
                "lat": -3.4,
                "lng": 24.42
            },
            "Mbandaka": {
                "lat": 0.0478,
                "lng": 18.2558
            },
            "Bukavu": {
                "lat": -2.5,
                "lng": 28.8667
            },
            "Kisangani": {
                "lat": 0.5167,
                "lng": 25.2
            },
            "Kibanseke Premi\u00e8re": {
                "lat": -4.4419,
                "lng": 15.395
            },
            "Bunia": {
                "lat": 1.5667,
                "lng": 30.25
            },
            "Tshikapa": {
                "lat": -6.4167,
                "lng": 20.8
            },
            "Uvira": {
                "lat": -3.4,
                "lng": 29.15
            },
            "Likasi": {
                "lat": -10.9833,
                "lng": 26.7333
            },
            "Kolwezi": {
                "lat": -10.7167,
                "lng": 25.4667
            },
            "Kikwit": {
                "lat": -5.0386,
                "lng": 18.8181
            },
            "Ndjili": {
                "lat": -4.4089,
                "lng": 15.3775
            },
            "Kisenzi": {
                "lat": -4.4094,
                "lng": 15.3425
            },
            "Matadi": {
                "lat": -5.8167,
                "lng": 13.4833
            },
            "Goma": {
                "lat": -1.6794,
                "lng": 29.2336
            },
            "Kabinda": {
                "lat": -6.13,
                "lng": 24.48
            },
            "Beni": {
                "lat": 0.5,
                "lng": 29.4667
            },
            "Butembo": {
                "lat": 0.15,
                "lng": 29.2833
            },
            "Gbadolite": {
                "lat": 4.2833,
                "lng": 21.0167
            },
            "Mwene-Ditu": {
                "lat": -7.0,
                "lng": 23.45
            },
            "Isiro": {
                "lat": 2.7833,
                "lng": 27.6167
            },
            "Kindu": {
                "lat": -2.95,
                "lng": 25.95
            },
            "Boma": {
                "lat": -5.85,
                "lng": 13.05
            },
            "Kamina": {
                "lat": -8.7386,
                "lng": 24.9906
            },
            "Moanda": {
                "lat": -5.9342,
                "lng": 12.3494
            },
            "Kalemie": {
                "lat": -5.9128,
                "lng": 29.1906
            },
            "Wamba": {
                "lat": 2.1442,
                "lng": 27.9929
            },
            "Gandajika": {
                "lat": -6.75,
                "lng": 23.9667
            },
            "Bandundu": {
                "lat": -3.3167,
                "lng": 17.3667
            },
            "Nsele": {
                "lat": -4.3744,
                "lng": 15.4947
            },
            "Gemena": {
                "lat": 3.25,
                "lng": 19.7667
            },
            "Kipushi": {
                "lat": -11.7625,
                "lng": 27.25
            },
            "Baraka": {
                "lat": -4.1041,
                "lng": 29.094
            },
            "Ilebo": {
                "lat": -4.3167,
                "lng": 20.6
            },
            "Kongolo": {
                "lat": -5.4,
                "lng": 27.0
            },
            "Bumba": {
                "lat": 2.1844,
                "lng": 22.4703
            },
            "Mbanza-Ngungu": {
                "lat": -5.25,
                "lng": 14.8667
            },
            "Lingwala": {
                "lat": -4.3203,
                "lng": 15.2983
            },
            "Luvungi": {
                "lat": -2.86,
                "lng": 29.03
            },
            "Tshilenge": {
                "lat": -6.25,
                "lng": 23.7667
            },
            "Lisala": {
                "lat": 2.1486,
                "lng": 21.5136
            },
            "Lodja": {
                "lat": -3.5242,
                "lng": 23.5964
            },
            "Binga": {
                "lat": 2.4,
                "lng": 20.42
            },
            "Kasongo": {
                "lat": -4.45,
                "lng": 26.65
            },
            "Idiofa": {
                "lat": -4.9668,
                "lng": 19.591
            },
            "Bakwa-Kalonji": {
                "lat": -4.35,
                "lng": 20.72
            },
            "Bulungu": {
                "lat": -4.55,
                "lng": 18.6
            },
            "Buta": {
                "lat": 2.8,
                "lng": 24.7333
            },
            "Mbulungu": {
                "lat": -6.0667,
                "lng": 21.9
            },
            "Zongo": {
                "lat": 4.3433,
                "lng": 18.5928
            },
            "Hyosha": {
                "lat": 0.6975,
                "lng": 29.5197
            },
            "Dungu": {
                "lat": 3.6167,
                "lng": 28.5667
            },
            "Mulongo": {
                "lat": -7.8333,
                "lng": 27.0
            },
            "Mweka": {
                "lat": -4.85,
                "lng": 21.5667
            },
            "Kahemba": {
                "lat": -7.2829,
                "lng": 19.0
            },
            "Kalima": {
                "lat": -2.5667,
                "lng": 26.6167
            },
            "Basoko": {
                "lat": 1.2333,
                "lng": 23.6
            },
            "Kasumbalesa": {
                "lat": -12.2564,
                "lng": 27.8028
            },
            "Manono": {
                "lat": -7.2947,
                "lng": 27.4545
            },
            "Inongo": {
                "lat": -1.95,
                "lng": 18.2667
            },
            "Lubao": {
                "lat": -5.385,
                "lng": 25.745
            },
            "Bukama": {
                "lat": -9.2,
                "lng": 25.8333
            },
            "Nyunzu": {
                "lat": -5.95,
                "lng": 28.0333
            },
            "Kenge": {
                "lat": -4.8056,
                "lng": 17.0417
            },
            "Lusambo": {
                "lat": -4.9729,
                "lng": 23.4368
            },
            "Luebo": {
                "lat": -5.35,
                "lng": 21.4167
            },
            "Dibaya-Lubwe": {
                "lat": -4.15,
                "lng": 19.8667
            },
            "Tshela": {
                "lat": -4.9667,
                "lng": 12.9333
            },
            "Aketi": {
                "lat": 2.7333,
                "lng": 23.75
            },
            "Mangai": {
                "lat": -4.0499,
                "lng": 19.5333
            },
            "Kampene": {
                "lat": -3.5968,
                "lng": 26.6658
            },
            "Kambove": {
                "lat": -10.8764,
                "lng": 26.5969
            },
            "Kaniama": {
                "lat": -7.5696,
                "lng": 24.17
            },
            "Boende": {
                "lat": -0.281,
                "lng": 20.876
            },
            "Yangambi": {
                "lat": 0.7675,
                "lng": 24.4414
            },
            "Fungurume": {
                "lat": -10.6167,
                "lng": 26.3
            },
            "Mushie": {
                "lat": -3.0167,
                "lng": 16.9167
            },
            "Businga": {
                "lat": 3.3397,
                "lng": 20.87
            },
            "Masi-Manimba": {
                "lat": -4.779,
                "lng": 17.9077
            },
            "Bolobo": {
                "lat": -2.1667,
                "lng": 16.2333
            },
            "Kanyobagonga": {
                "lat": -0.702,
                "lng": 29.1726
            },
            "Kabalo": {
                "lat": -6.05,
                "lng": 26.9167
            },
            "Djugu": {
                "lat": 1.9184,
                "lng": 30.5019
            },
            "Kasangulu": {
                "lat": -4.5911,
                "lng": 15.1703
            },
            "Libenge": {
                "lat": 3.6508,
                "lng": 18.6375
            },
            "Lukula": {
                "lat": -5.4,
                "lng": 12.9333
            },
            "Aru": {
                "lat": 2.8617,
                "lng": 30.8333
            },
            "Demba": {
                "lat": -5.5,
                "lng": 22.2667
            },
            "Drodro": {
                "lat": 1.7667,
                "lng": 30.5333
            },
            "Pepa": {
                "lat": -7.7106,
                "lng": 29.776
            },
            "Pweto": {
                "lat": -8.4667,
                "lng": 28.9
            },
            "Watsa": {
                "lat": 3.0372,
                "lng": 29.5355
            },
            "Gungu": {
                "lat": -5.7333,
                "lng": 19.3167
            },
            "Kasongo-Lunda": {
                "lat": -6.4783,
                "lng": 16.8166
            },
            "Basankusu": {
                "lat": 1.2222,
                "lng": 19.8028
            },
            "Oshwe": {
                "lat": -3.4188,
                "lng": 19.4334
            },
            "Bondo": {
                "lat": 3.8167,
                "lng": 23.6833
            },
            "Kinzau-Vuete": {
                "lat": -5.4967,
                "lng": 13.2893
            },
            "Doruma": {
                "lat": 4.7333,
                "lng": 27.7
            },
            "Sake": {
                "lat": -1.5741,
                "lng": 29.0413
            },
            "Bosobolo": {
                "lat": 4.1833,
                "lng": 19.9
            },
            "Kiri": {
                "lat": -1.4955,
                "lng": 18.9277
            },
            "Kamituga": {
                "lat": -3.06,
                "lng": 28.18
            },
            "Luozi": {
                "lat": -4.948,
                "lng": 14.133
            },
            "Yakoma": {
                "lat": 4.0982,
                "lng": 22.4432
            },
            "Kasaji": {
                "lat": -10.3662,
                "lng": 23.45
            },
            "Poko": {
                "lat": 3.15,
                "lng": 26.8833
            },
            "Kimpese": {
                "lat": -5.5631,
                "lng": 14.4458
            },
            "Moba": {
                "lat": -7.0398,
                "lng": 29.7661
            },
            "Dilolo": {
                "lat": -10.4667,
                "lng": 22.4667
            },
            "Bongandanga": {
                "lat": 1.51,
                "lng": 21.05
            },
            "Mangbwalu": {
                "lat": 1.9352,
                "lng": 30.0462
            },
            "Mwenga": {
                "lat": -3.0382,
                "lng": 28.4325
            },
            "Lubutu": {
                "lat": -0.7431,
                "lng": 26.5833
            },
            "Buluko": {
                "lat": -0.757,
                "lng": 28.528
            },
            "Luanza": {
                "lat": -8.6996,
                "lng": 28.7
            },
            "Dibaya": {
                "lat": -6.5095,
                "lng": 22.87
            },
            "Ikela": {
                "lat": -1.1833,
                "lng": 23.2667
            },
            "Lusanga": {
                "lat": -5.5808,
                "lng": 16.5143
            },
            "Bafwasende": {
                "lat": 1.0103,
                "lng": 27.1617
            }
        }
    },
    "GB": {
        "name": "United Kingdom",
        "cities": {
            "London": {
                "lat": 51.5072,
                "lng": -0.1275
            },
            "Birmingham": {
                "lat": 52.48,
                "lng": -1.9025
            },
            "Portsmouth": {
                "lat": 50.8058,
                "lng": -1.0872
            },
            "Southampton": {
                "lat": 50.9025,
                "lng": -1.4042
            },
            "Leeds": {
                "lat": 53.7975,
                "lng": -1.5436
            },
            "Nottingham": {
                "lat": 52.9561,
                "lng": -1.1512
            },
            "Bristol": {
                "lat": 51.4536,
                "lng": -2.5975
            },
            "Glasgow": {
                "lat": 55.8611,
                "lng": -4.25
            },
            "Manchester": {
                "lat": 53.479,
                "lng": -2.2452
            },
            "Sheffield": {
                "lat": 53.3808,
                "lng": -1.4703
            },
            "Liverpool": {
                "lat": 53.4094,
                "lng": -2.9785
            },
            "Leicester": {
                "lat": 52.6344,
                "lng": -1.1319
            },
            "Caerdydd": {
                "lat": 51.4833,
                "lng": -3.1833
            },
            "Edinburgh": {
                "lat": 55.9533,
                "lng": -3.1892
            },
            "Worthing": {
                "lat": 50.8147,
                "lng": -0.3714
            },
            "Stoke-on-Trent": {
                "lat": 53.0,
                "lng": -2.1833
            },
            "Coventry": {
                "lat": 52.4081,
                "lng": -1.5106
            },
            "Belfast": {
                "lat": 54.5964,
                "lng": -5.93
            },
            "Reading": {
                "lat": 51.4542,
                "lng": -0.9731
            },
            "Kingston upon Hull": {
                "lat": 53.7444,
                "lng": -0.3325
            },
            "Newcastle": {
                "lat": 54.978,
                "lng": -1.6102
            },
            "Bradford": {
                "lat": 53.8,
                "lng": -1.75
            },
            "Bolton": {
                "lat": 53.578,
                "lng": -2.429
            },
            "Sunderland": {
                "lat": 54.906,
                "lng": -1.381
            },
            "Derby": {
                "lat": 52.9247,
                "lng": -1.478
            },
            "Plymouth": {
                "lat": 50.3714,
                "lng": -4.1422
            },
            "Westminster": {
                "lat": 51.4947,
                "lng": -0.1353
            },
            "Wolverhampton": {
                "lat": 52.5833,
                "lng": -2.1333
            },
            "Northampton": {
                "lat": 52.2304,
                "lng": -0.8938
            },
            "Abertawe": {
                "lat": 51.6167,
                "lng": -3.95
            },
            "Barnsley": {
                "lat": 53.5547,
                "lng": -1.4791
            },
            "Swindon": {
                "lat": 51.56,
                "lng": -1.78
            },
            "Norwich": {
                "lat": 52.6286,
                "lng": 1.2928
            },
            "Luton": {
                "lat": 51.8783,
                "lng": -0.4147
            },
            "Milton Keynes": {
                "lat": 52.04,
                "lng": -0.76
            },
            "Solihull": {
                "lat": 52.413,
                "lng": -1.778
            },
            "Islington": {
                "lat": 51.544,
                "lng": -0.1027
            },
            "Aberdeen": {
                "lat": 57.15,
                "lng": -2.11
            },
            "Peterborough": {
                "lat": 52.5661,
                "lng": -0.2364
            },
            "Croydon": {
                "lat": 51.3727,
                "lng": -0.1099
            },
            "Bournemouth": {
                "lat": 50.72,
                "lng": -1.88
            },
            "Basildon": {
                "lat": 51.58,
                "lng": 0.49
            },
            "Maidstone": {
                "lat": 51.272,
                "lng": 0.529
            },
            "Ilford": {
                "lat": 51.5575,
                "lng": 0.0858
            },
            "Warrington": {
                "lat": 53.39,
                "lng": -2.59
            },
            "Huddersfield": {
                "lat": 53.645,
                "lng": -1.7798
            },
            "Southend": {
                "lat": 51.55,
                "lng": 0.71
            },
            "Newport": {
                "lat": 52.7691,
                "lng": -2.3787
            },
            "Enfield": {
                "lat": 51.6522,
                "lng": -0.0808
            },
            "Oxford": {
                "lat": 51.75,
                "lng": -1.25
            },
            "Ipswich": {
                "lat": 52.0594,
                "lng": 1.1556
            },
            "Harrow": {
                "lat": 51.5836,
                "lng": -0.3464
            },
            "Dundee": {
                "lat": 56.462,
                "lng": -2.9707
            },
            "West Bromwich": {
                "lat": 52.519,
                "lng": -1.995
            },
            "Gloucester": {
                "lat": 51.8667,
                "lng": -2.25
            },
            "Poole": {
                "lat": 50.7167,
                "lng": -1.9833
            },
            "Birkenhead": {
                "lat": 53.393,
                "lng": -3.014
            },
            "Telford": {
                "lat": 52.6766,
                "lng": -2.4469
            },
            "Preston": {
                "lat": 53.83,
                "lng": -2.735
            },
            "York": {
                "lat": 53.96,
                "lng": -1.08
            },
            "Middlesbrough": {
                "lat": 54.5767,
                "lng": -1.2355
            },
            "Blackpool": {
                "lat": 53.8142,
                "lng": -3.0503
            },
            "Stockport": {
                "lat": 53.4083,
                "lng": -2.1494
            },
            "Brighton": {
                "lat": 50.8208,
                "lng": -0.1375
            },
            "Sale": {
                "lat": 53.424,
                "lng": -2.322
            },
            "Lincoln": {
                "lat": 53.2283,
                "lng": -0.5389
            },
            "Tottenham": {
                "lat": 51.5975,
                "lng": -0.0681
            },
            "Exeter": {
                "lat": 50.7256,
                "lng": -3.5269
            },
            "Cambridge": {
                "lat": 52.2053,
                "lng": 0.1192
            },
            "Romford": {
                "lat": 51.5768,
                "lng": 0.1801
            },
            "Colchester": {
                "lat": 51.8917,
                "lng": 0.903
            },
            "High Wycombe": {
                "lat": 51.6287,
                "lng": -0.7482
            },
            "Gateshead": {
                "lat": 54.9556,
                "lng": -1.6
            },
            "Slough": {
                "lat": 51.5084,
                "lng": -0.5881
            },
            "Blackburn": {
                "lat": 53.748,
                "lng": -2.482
            },
            "Cheltenham": {
                "lat": 51.9,
                "lng": -2.0667
            },
            "Chelmsford": {
                "lat": 51.73,
                "lng": 0.48
            },
            "Rochdale": {
                "lat": 53.61,
                "lng": -2.16
            },
            "Wythenshawe": {
                "lat": 53.392,
                "lng": -2.264
            },
            "Sutton Coldfield": {
                "lat": 52.563,
                "lng": -1.822
            },
            "Doncaster": {
                "lat": 53.5231,
                "lng": -1.1339
            },
            "Rotherham": {
                "lat": 53.43,
                "lng": -1.357
            },
            "Walthamstow": {
                "lat": 51.584,
                "lng": -0.021
            },
            "Basingstoke": {
                "lat": 51.2667,
                "lng": -1.0876
            },
            "Crawley": {
                "lat": 51.1092,
                "lng": -0.1872
            },
            "Dagenham": {
                "lat": 51.5397,
                "lng": 0.1422
            },
            "Gillingham": {
                "lat": 51.0375,
                "lng": -2.2748
            },
            "Halifax": {
                "lat": 53.725,
                "lng": -1.863
            },
            "Salford": {
                "lat": 53.483,
                "lng": -2.2931
            },
            "Wigan": {
                "lat": 53.5448,
                "lng": -2.6318
            },
            "Hounslow": {
                "lat": 51.4668,
                "lng": -0.375
            },
            "Wembley": {
                "lat": 51.5528,
                "lng": -0.2979
            },
            "Saint Helens": {
                "lat": 53.4542,
                "lng": -2.7361
            },
            "Worcester": {
                "lat": 52.1911,
                "lng": -2.2206
            },
            "Eastbourne": {
                "lat": 50.77,
                "lng": 0.28
            },
            "Wakefield": {
                "lat": 53.6825,
                "lng": -1.4975
            },
            "Oldham": {
                "lat": 53.5444,
                "lng": -2.1169
            },
            "Hammersmith": {
                "lat": 51.4928,
                "lng": -0.2229
            },
            "Rayleigh": {
                "lat": 51.5864,
                "lng": 0.6049
            },
            "Hemel Hempstead": {
                "lat": 51.7526,
                "lng": -0.4692
            },
            "Bath": {
                "lat": 51.38,
                "lng": -2.36
            },
            "Hayes": {
                "lat": 51.378,
                "lng": 0.0192
            },
            "Darlington": {
                "lat": 54.527,
                "lng": -1.5526
            },
            "Wimbledon": {
                "lat": 51.422,
                "lng": -0.208
            },
            "Bedford": {
                "lat": 52.135,
                "lng": -0.47
            },
            "Hove": {
                "lat": 50.8352,
                "lng": -0.1758
            },
            "Southport": {
                "lat": 53.6475,
                "lng": -3.0053
            },
            "Hastings": {
                "lat": 50.85,
                "lng": 0.57
            },
            "Orpington": {
                "lat": 51.3741,
                "lng": 0.0986
            },
            "Watford": {
                "lat": 51.655,
                "lng": -0.3957
            },
            "Stevenage": {
                "lat": 51.9017,
                "lng": -0.2019
            },
            "Grimsby": {
                "lat": 53.5675,
                "lng": -0.08
            },
            "Hartlepool": {
                "lat": 54.69,
                "lng": -1.21
            },
            "Bromley": {
                "lat": 51.407,
                "lng": 0.021
            },
            "Chester": {
                "lat": 53.19,
                "lng": -2.89
            },
            "Fulham": {
                "lat": 51.4828,
                "lng": -0.195
            },
            "Nuneaton": {
                "lat": 52.523,
                "lng": -1.468
            },
            "Derry": {
                "lat": 54.9975,
                "lng": -7.32
            },
            "Ealing": {
                "lat": 51.5175,
                "lng": -0.2988
            },
            "Woolwich": {
                "lat": 51.488,
                "lng": 0.063
            },
            "Aylesbury": {
                "lat": 51.8168,
                "lng": -0.8124
            },
            "Ashford": {
                "lat": 51.434,
                "lng": -0.464
            },
            "Stockton-on-Tees": {
                "lat": 54.57,
                "lng": -1.32
            },
            "Edmonton": {
                "lat": 51.6154,
                "lng": -0.0708
            },
            "Saint Albans": {
                "lat": 51.755,
                "lng": -0.336
            },
            "Harlow": {
                "lat": 51.779,
                "lng": 0.128
            },
            "Burnley": {
                "lat": 53.789,
                "lng": -2.248
            },
            "Redditch": {
                "lat": 52.3,
                "lng": -1.9333
            },
            "Batley": {
                "lat": 53.7167,
                "lng": -1.6356
            },
            "Scunthorpe": {
                "lat": 53.5809,
                "lng": -0.6502
            },
            "Dudley": {
                "lat": 52.508,
                "lng": -2.089
            },
            "Bury": {
                "lat": 53.593,
                "lng": -2.298
            },
            "Eastleigh": {
                "lat": 50.9667,
                "lng": -1.35
            },
            "Brixton": {
                "lat": 51.4575,
                "lng": -0.1175
            },
            "Southall": {
                "lat": 51.5111,
                "lng": -0.3756
            },
            "Bracknell": {
                "lat": 51.416,
                "lng": -0.749
            },
            "Paisley": {
                "lat": 55.8456,
                "lng": -4.4239
            },
            "Guildford": {
                "lat": 51.2365,
                "lng": -0.5703
            },
            "Chatham": {
                "lat": 51.37,
                "lng": 0.52
            },
            "East Ham": {
                "lat": 51.5323,
                "lng": 0.0554
            },
            "Weston-super-Mare": {
                "lat": 51.346,
                "lng": -2.977
            },
            "Carlisle": {
                "lat": 54.8947,
                "lng": -2.9364
            },
            "South Shields": {
                "lat": 54.995,
                "lng": -1.43
            },
            "East Kilbride": {
                "lat": 55.7644,
                "lng": -4.1769
            },
            "Newcastle under Lyme": {
                "lat": 53.0109,
                "lng": -2.2278
            },
            "Burton upon Trent": {
                "lat": 52.8019,
                "lng": -1.6367
            },
            "Gravesend": {
                "lat": 51.4415,
                "lng": 0.3685
            },
            "Tamworth": {
                "lat": 52.633,
                "lng": -1.695
            },
            "Harrogate": {
                "lat": 53.9919,
                "lng": -1.5378
            },
            "Crewe": {
                "lat": 53.099,
                "lng": -2.44
            },
            "Shrewsbury": {
                "lat": 52.708,
                "lng": -2.754
            },
            "Gosport": {
                "lat": 50.7948,
                "lng": -1.1243
            },
            "Lisburn": {
                "lat": 54.512,
                "lng": -6.031
            },
            "Lowestoft": {
                "lat": 52.48,
                "lng": 1.75
            },
            "Rugby": {
                "lat": 52.37,
                "lng": -1.26
            },
            "Stafford": {
                "lat": 52.807,
                "lng": -2.117
            },
            "Chingford": {
                "lat": 51.623,
                "lng": 0.009
            },
            "Uxbridge": {
                "lat": 51.5404,
                "lng": -0.4778
            },
            "Mansfield": {
                "lat": 53.1444,
                "lng": -1.1964
            },
            "Rhondda": {
                "lat": 51.6159,
                "lng": -3.4175
            },
            "Tynemouth": {
                "lat": 55.017,
                "lng": -1.423
            },
            "Cannock": {
                "lat": 52.691,
                "lng": -2.027
            },
            "Walsall": {
                "lat": 52.58,
                "lng": -1.98
            },
            "Washington": {
                "lat": 54.9,
                "lng": -1.52
            },
            "Grays": {
                "lat": 51.475,
                "lng": 0.33
            },
            "Godalming": {
                "lat": 51.1855,
                "lng": -0.6155
            },
            "Walton upon Thames": {
                "lat": 51.3868,
                "lng": -0.4133
            },
            "Finchley": {
                "lat": 51.599,
                "lng": -0.187
            },
            "Thornton Heath": {
                "lat": 51.4002,
                "lng": -0.1086
            },
            "Torquay": {
                "lat": 50.47,
                "lng": -3.53
            },
            "Farnborough": {
                "lat": 51.3591,
                "lng": 0.0741
            },
            "Kensington": {
                "lat": 51.5,
                "lng": -0.19
            },
            "Boston": {
                "lat": 52.974,
                "lng": -0.0214
            },
            "Paignton": {
                "lat": 50.4353,
                "lng": -3.5625
            },
            "Waterlooville": {
                "lat": 50.88,
                "lng": -1.03
            },
            "Guiseley": {
                "lat": 53.875,
                "lng": -1.706
            },
            "Bognor Regis": {
                "lat": 50.7824,
                "lng": -0.6764
            },
            "Hornchurch": {
                "lat": 51.5565,
                "lng": 0.2128
            },
            "Maidenhead": {
                "lat": 51.5217,
                "lng": -0.7177
            },
            "Mitcham": {
                "lat": 51.4009,
                "lng": -0.1517
            },
            "Feltham": {
                "lat": 51.4496,
                "lng": -0.4089
            },
            "Stourbridge": {
                "lat": 52.4575,
                "lng": -2.1479
            },
            "Rochester": {
                "lat": 51.375,
                "lng": 0.5
            },
            "Dewsbury": {
                "lat": 53.691,
                "lng": -1.633
            },
            "Woking": {
                "lat": 51.305,
                "lng": -0.539
            },
            "Sittingbourne": {
                "lat": 51.34,
                "lng": 0.74
            },
            "Acton": {
                "lat": 51.5135,
                "lng": -0.2707
            },
            "Twickenham": {
                "lat": 51.449,
                "lng": -0.337
            },
            "Runcorn": {
                "lat": 53.3417,
                "lng": -2.7313
            },
            "Wrecsam": {
                "lat": 53.046,
                "lng": -2.993
            },
            "Widnes": {
                "lat": 53.362,
                "lng": -2.7341
            },
            "Margate": {
                "lat": 51.385,
                "lng": 1.3838
            },
            "Ellesmere Port": {
                "lat": 53.279,
                "lng": -2.897
            },
            "Bangor": {
                "lat": 53.228,
                "lng": -4.128
            },
            "Taunton": {
                "lat": 51.019,
                "lng": -3.1
            },
            "Wallasey": {
                "lat": 53.4158,
                "lng": -3.0233
            },
            "Loughborough": {
                "lat": 52.7725,
                "lng": -1.2078
            },
            "Barking": {
                "lat": 51.54,
                "lng": 0.08
            },
            "Edgware": {
                "lat": 51.6185,
                "lng": -0.2729
            },
            "Littlehampton": {
                "lat": 50.8094,
                "lng": -0.5409
            },
            "Ruislip": {
                "lat": 51.576,
                "lng": -0.433
            },
            "Halesowen": {
                "lat": 52.4502,
                "lng": -2.0509
            },
            "Streatham": {
                "lat": 51.4279,
                "lng": -0.1235
            },
            "Royal Tunbridge Wells": {
                "lat": 51.132,
                "lng": 0.263
            },
            "Bebington": {
                "lat": 53.35,
                "lng": -3.003
            },
            "Aldershot": {
                "lat": 51.2483,
                "lng": -0.7614
            },
            "Macclesfield": {
                "lat": 53.25,
                "lng": -2.13
            },
            "Wellingborough": {
                "lat": 52.3028,
                "lng": -0.6944
            },
            "Kettering": {
                "lat": 52.3931,
                "lng": -0.7229
            },
            "Braintree": {
                "lat": 51.878,
                "lng": 0.55
            },
            "Royal Leamington Spa": {
                "lat": 52.2919,
                "lng": -1.5358
            },
            "Kidderminster": {
                "lat": 52.3885,
                "lng": -2.249
            },
            "Barrow in Furness": {
                "lat": 54.1108,
                "lng": -3.2261
            },
            "Corby": {
                "lat": 52.4877,
                "lng": -0.7013
            },
            "Canterbury": {
                "lat": 51.28,
                "lng": 1.08
            },
            "Christchurch": {
                "lat": 50.73,
                "lng": -1.78
            },
            "Keighley": {
                "lat": 53.867,
                "lng": -1.911
            },
            "Hamilton": {
                "lat": 55.777,
                "lng": -4.039
            },
            "Hereford": {
                "lat": 52.056,
                "lng": -2.716
            },
            "Dunfermline": {
                "lat": 56.0719,
                "lng": -3.4393
            },
            "Brentwood": {
                "lat": 51.62,
                "lng": 0.305
            },
            "Altrincham": {
                "lat": 53.3838,
                "lng": -2.3547
            },
            "Lancaster": {
                "lat": 54.0489,
                "lng": -2.8014
            },
            "Crosby": {
                "lat": 53.4872,
                "lng": -3.0343
            },
            "Barri": {
                "lat": 51.405,
                "lng": -3.27
            },
            "Willenhall": {
                "lat": 52.5798,
                "lng": -2.0605
            },
            "Bootle": {
                "lat": 53.4457,
                "lng": -2.9891
            },
            "Stratford": {
                "lat": 51.5423,
                "lng": -0.0026
            },
            "Folkestone": {
                "lat": 51.0792,
                "lng": 1.1794
            },
            "Dartford": {
                "lat": 51.4444,
                "lng": 0.2172
            },
            "Weymouth": {
                "lat": 50.6097,
                "lng": -2.4547
            },
            "Horsham": {
                "lat": 51.062,
                "lng": -0.325
            },
            "Cumbernauld": {
                "lat": 55.945,
                "lng": -3.994
            },
            "Esher": {
                "lat": 51.3691,
                "lng": -0.365
            },
            "Andover": {
                "lat": 51.208,
                "lng": -1.48
            },
            "Livingston": {
                "lat": 55.8834,
                "lng": -3.5157
            },
            "Neath": {
                "lat": 51.66,
                "lng": -3.81
            },
            "Clacton-on-Sea": {
                "lat": 51.7918,
                "lng": 1.1457
            },
            "Northwich": {
                "lat": 53.259,
                "lng": -2.518
            },
            "Rowley Regis": {
                "lat": 52.488,
                "lng": -2.05
            },
            "Scarborough": {
                "lat": 54.2825,
                "lng": -0.4
            },
            "Leith": {
                "lat": 55.98,
                "lng": -3.17
            },
            "Yeovil": {
                "lat": 50.9452,
                "lng": -2.637
            },
            "Pen-y-Bont ar Ogwr": {
                "lat": 51.507,
                "lng": -3.578
            },
            "Eltham": {
                "lat": 51.451,
                "lng": 0.052
            },
            "Hampstead": {
                "lat": 51.5541,
                "lng": -0.1744
            },
            "Cwmbran": {
                "lat": 51.653,
                "lng": -3.021
            },
            "Sutton in Ashfield": {
                "lat": 53.125,
                "lng": -1.261
            },
            "Welwyn Garden City": {
                "lat": 51.8062,
                "lng": -0.1932
            },
            "Morden": {
                "lat": 51.4015,
                "lng": -0.1949
            },
            "Smethwick": {
                "lat": 52.4931,
                "lng": -1.9686
            },
            "Kirkcaldy": {
                "lat": 56.1107,
                "lng": -3.1674
            },
            "Durham": {
                "lat": 54.7761,
                "lng": -1.5733
            },
            "Perth": {
                "lat": 56.3958,
                "lng": -3.4333
            },
            "Barnet": {
                "lat": 51.6444,
                "lng": -0.1997
            },
            "Stretford": {
                "lat": 53.4466,
                "lng": -2.3086
            },
            "Banbury": {
                "lat": 52.061,
                "lng": -1.336
            },
            "Beckenham": {
                "lat": 51.408,
                "lng": -0.022
            },
            "Greenford": {
                "lat": 51.5299,
                "lng": -0.3488
            },
            "Ayr": {
                "lat": 55.458,
                "lng": -4.629
            },
            "Wokingham": {
                "lat": 51.41,
                "lng": -0.84
            },
            "Kilmarnock": {
                "lat": 55.6111,
                "lng": -4.4957
            },
            "Cheshunt": {
                "lat": 51.702,
                "lng": -0.035
            },
            "Havant": {
                "lat": 50.8517,
                "lng": -0.9842
            },
            "Kirkby": {
                "lat": 53.48,
                "lng": -2.89
            },
            "Salisbury": {
                "lat": 51.07,
                "lng": -1.79
            },
            "Erith": {
                "lat": 51.48,
                "lng": 0.1778
            },
            "Hinckley": {
                "lat": 52.5413,
                "lng": -1.3725
            },
            "Ashton": {
                "lat": 53.4897,
                "lng": -2.0952
            },
            "Surbiton": {
                "lat": 51.394,
                "lng": -0.307
            },
            "Castleford": {
                "lat": 53.716,
                "lng": -1.356
            },
            "Catford": {
                "lat": 51.4452,
                "lng": -0.0207
            },
            "Worksop": {
                "lat": 53.3042,
                "lng": -1.1244
            },
            "Morley": {
                "lat": 53.7492,
                "lng": -1.6023
            },
            "Coatbridge": {
                "lat": 55.8625,
                "lng": -4.0266
            },
            "Wallsend": {
                "lat": 54.991,
                "lng": -1.534
            },
            "Merthyr Tudful": {
                "lat": 51.743,
                "lng": -3.378
            },
            "Sidcup": {
                "lat": 51.4263,
                "lng": 0.1024
            },
            "Kingston upon Thames": {
                "lat": 51.4103,
                "lng": -0.2995
            },
            "Middleton": {
                "lat": 53.555,
                "lng": -2.187
            },
            "Fleet": {
                "lat": 51.2834,
                "lng": -0.8456
            },
            "King\u2019s Lynn": {
                "lat": 52.754,
                "lng": 0.398
            },
            "Hoddesdon": {
                "lat": 51.759,
                "lng": -0.015
            },
            "Fareham": {
                "lat": 50.85,
                "lng": -1.18
            },
            "Greenock": {
                "lat": 55.95,
                "lng": -4.765
            },
            "Urmston": {
                "lat": 53.4487,
                "lng": -2.3747
            },
            "Sutton": {
                "lat": 51.3656,
                "lng": -0.1963
            },
            "Caerphilly": {
                "lat": 51.578,
                "lng": -3.218
            },
            "Bridgwater": {
                "lat": 51.128,
                "lng": -2.993
            },
            "Leigh": {
                "lat": 53.4975,
                "lng": -2.515
            },
            "Hatfield": {
                "lat": 53.58,
                "lng": -1.0
            },
            "Newbury": {
                "lat": 51.401,
                "lng": -1.323
            },
            "Welling": {
                "lat": 51.4594,
                "lng": 0.1097
            },
            "Kingswood": {
                "lat": 51.46,
                "lng": -2.505
            },
            "Dunstable": {
                "lat": 51.886,
                "lng": -0.521
            },
            "Bury Saint Edmunds": {
                "lat": 52.2474,
                "lng": 0.7183
            },
            "Ramsgate": {
                "lat": 51.336,
                "lng": 1.416
            },
            "Inverness": {
                "lat": 57.4778,
                "lng": -4.2247
            },
            "Strood": {
                "lat": 51.393,
                "lng": 0.478
            },
            "Cleethorpes": {
                "lat": 53.5533,
                "lng": -0.0215
            },
            "Skelmersdale": {
                "lat": 53.55,
                "lng": -2.776
            },
            "Tipton": {
                "lat": 52.5259,
                "lng": -2.0751
            },
            "Eccles": {
                "lat": 53.4824,
                "lng": -2.339
            },
            "Pinner": {
                "lat": 51.5932,
                "lng": -0.3894
            },
            "Great Yarmouth": {
                "lat": 52.606,
                "lng": 1.729
            },
            "Tonbridge": {
                "lat": 51.1987,
                "lng": 0.2764
            },
            "Ilkeston": {
                "lat": 52.9711,
                "lng": -1.3092
            },
            "Leyland": {
                "lat": 53.6903,
                "lng": -2.6992
            },
            "Glenrothes": {
                "lat": 56.198,
                "lng": -3.178
            },
            "Chorley": {
                "lat": 53.653,
                "lng": -2.632
            },
            "Herne Bay": {
                "lat": 51.37,
                "lng": 1.13
            },
            "Bishops Stortford": {
                "lat": 51.872,
                "lng": 0.1725
            },
            "Arnold": {
                "lat": 53.005,
                "lng": -1.127
            },
            "Long Eaton": {
                "lat": 52.898,
                "lng": -1.271
            },
            "Coalville": {
                "lat": 52.724,
                "lng": -1.369
            },
            "Stirling": {
                "lat": 56.1166,
                "lng": -3.9369
            },
            "Bletchley": {
                "lat": 51.994,
                "lng": -0.732
            },
            "Leighton Buzzard": {
                "lat": 51.9165,
                "lng": -0.6617
            },
            "Airdrie": {
                "lat": 55.86,
                "lng": -3.98
            },
            "Blyth": {
                "lat": 55.126,
                "lng": -1.514
            },
            "Port Talbot": {
                "lat": 51.5906,
                "lng": -3.7986
            },
            "Laindon": {
                "lat": 51.574,
                "lng": 0.4181
            },
            "Redcar": {
                "lat": 54.618,
                "lng": -1.069
            },
            "Llanelli": {
                "lat": 51.684,
                "lng": -4.163
            },
            "Beeston": {
                "lat": 52.927,
                "lng": -1.215
            },
            "Small Heath": {
                "lat": 52.4629,
                "lng": -1.8542
            },
            "Camberley": {
                "lat": 51.335,
                "lng": -0.742
            },
            "Houghton le Spring": {
                "lat": 54.841,
                "lng": -1.468
            },
            "Whitley Bay": {
                "lat": 55.0456,
                "lng": -1.4443
            },
            "Denton": {
                "lat": 53.4554,
                "lng": -2.1122
            },
            "Chippenham": {
                "lat": 51.459,
                "lng": -2.116
            },
            "West Bridgford": {
                "lat": 52.932,
                "lng": -1.127
            },
            "Borehamwood": {
                "lat": 51.6578,
                "lng": -0.2722
            },
            "Hyde": {
                "lat": 53.4474,
                "lng": -2.082
            },
            "Hendon": {
                "lat": 51.5837,
                "lng": -0.2252
            },
            "Falkirk": {
                "lat": 56.0011,
                "lng": -3.7835
            },
            "Walkden": {
                "lat": 53.5239,
                "lng": -2.3991
            },
            "Kenton": {
                "lat": 51.5878,
                "lng": -0.3086
            },
            "Woodley": {
                "lat": 51.453,
                "lng": -0.902
            },
            "Accrington": {
                "lat": 53.7534,
                "lng": -2.3638
            },
            "Bridlington": {
                "lat": 54.0819,
                "lng": -0.1923
            },
            "Winchester": {
                "lat": 51.0632,
                "lng": -1.308
            },
            "Billingham": {
                "lat": 54.61,
                "lng": -1.27
            },
            "Winsford": {
                "lat": 53.194,
                "lng": -2.52
            },
            "Ewell": {
                "lat": 51.35,
                "lng": -0.249
            },
            "Morecambe": {
                "lat": 54.073,
                "lng": -2.87
            },
            "Grantham": {
                "lat": 52.918,
                "lng": -0.638
            },
            "Swadlincote": {
                "lat": 52.774,
                "lng": -1.557
            },
            "Exmouth": {
                "lat": 50.62,
                "lng": -3.413
            },
            "North Shields": {
                "lat": 55.0097,
                "lng": -1.4448
            },
            "Hitchin": {
                "lat": 51.947,
                "lng": -0.283
            },
            "Spalding": {
                "lat": 52.7858,
                "lng": -0.1529
            },
            "Rainham": {
                "lat": 51.36,
                "lng": 0.61
            },
            "Letchworth": {
                "lat": 51.978,
                "lng": -0.23
            },
            "Lichfield": {
                "lat": 52.682,
                "lng": -1.829
            },
            "Irvine": {
                "lat": 55.6201,
                "lng": -4.6614
            },
            "Wickford": {
                "lat": 51.6114,
                "lng": 0.5207
            },
            "Loughton": {
                "lat": 51.6494,
                "lng": 0.0735
            },
            "Glossop": {
                "lat": 53.443,
                "lng": -1.949
            },
            "Huyton": {
                "lat": 53.4111,
                "lng": -2.8403
            },
            "Abingdon": {
                "lat": 51.6717,
                "lng": -1.2783
            },
            "Trowbridge": {
                "lat": 51.32,
                "lng": -2.208
            },
            "Warwick": {
                "lat": 52.28,
                "lng": -1.59
            },
            "Motherwell": {
                "lat": 55.7839,
                "lng": -3.9852
            },
            "Dumfries": {
                "lat": 55.07,
                "lng": -3.603
            },
            "Brighouse": {
                "lat": 53.707,
                "lng": -1.794
            },
            "Wigston Magna": {
                "lat": 52.5812,
                "lng": -1.093
            },
            "Windsor": {
                "lat": 51.4791,
                "lng": -0.6095
            },
            "Didcot": {
                "lat": 51.606,
                "lng": -1.241
            },
            "Earley": {
                "lat": 51.433,
                "lng": -0.933
            },
            "Bexleyheath": {
                "lat": 51.459,
                "lng": 0.138
            },
            "Rushden": {
                "lat": 52.288,
                "lng": -0.601
            },
            "Ecclesfield": {
                "lat": 53.4429,
                "lng": -1.4698
            },
            "Wisbech": {
                "lat": 52.664,
                "lng": 0.16
            },
            "Darwen": {
                "lat": 53.698,
                "lng": -2.461
            },
            "Prestwich": {
                "lat": 53.5333,
                "lng": -2.2833
            },
            "Epsom": {
                "lat": 51.3331,
                "lng": -0.2686
            },
            "Pontypridd": {
                "lat": 51.602,
                "lng": -3.342
            },
            "Rutherglen": {
                "lat": 55.828,
                "lng": -4.214
            },
            "Saint Neots": {
                "lat": 52.228,
                "lng": -0.27
            },
            "Bedworth": {
                "lat": 52.475,
                "lng": -1.477
            },
            "Dover": {
                "lat": 51.1295,
                "lng": 1.3089
            },
            "Chichester": {
                "lat": 50.8365,
                "lng": -0.7792
            },
            "Deal": {
                "lat": 51.2226,
                "lng": 1.4006
            },
            "Pontefract": {
                "lat": 53.691,
                "lng": -1.312
            },
            "Bicester": {
                "lat": 51.9,
                "lng": -1.15
            },
            "Beverley": {
                "lat": 53.845,
                "lng": -0.427
            },
            "Burgess Hill": {
                "lat": 50.9535,
                "lng": -0.1262
            },
            "Stratford-upon-Avon": {
                "lat": 52.1928,
                "lng": -1.7064
            },
            "Newark upon Trent": {
                "lat": 53.0772,
                "lng": -0.8089
            },
            "Northolt": {
                "lat": 51.5467,
                "lng": -0.37
            },
            "Wishaw": {
                "lat": 55.7742,
                "lng": -3.9183
            },
            "Harpenden": {
                "lat": 51.8175,
                "lng": -0.3524
            },
            "Whitstable": {
                "lat": 51.361,
                "lng": 1.026
            },
            "Congleton": {
                "lat": 53.162,
                "lng": -2.217
            },
            "Radcliffe": {
                "lat": 53.5615,
                "lng": -2.3268
            },
            "Carshalton": {
                "lat": 51.3652,
                "lng": -0.1676
            },
            "Plympton": {
                "lat": 50.386,
                "lng": -4.051
            },
            "Bulwell": {
                "lat": 53.001,
                "lng": -1.197
            },
            "Newtownards": {
                "lat": 54.591,
                "lng": -5.68
            },
            "Kendal": {
                "lat": 54.326,
                "lng": -2.745
            },
            "Sevenoaks": {
                "lat": 51.2781,
                "lng": 0.1874
            },
            "Northfleet": {
                "lat": 51.44,
                "lng": 0.34
            },
            "Cramlington": {
                "lat": 55.082,
                "lng": -1.585
            },
            "Bromsgrove": {
                "lat": 52.3353,
                "lng": -2.0579
            },
            "Nelson": {
                "lat": 53.8346,
                "lng": -2.218
            },
            "Cambuslang": {
                "lat": 55.819,
                "lng": -4.1671
            },
            "Pont-y-p\u0175l": {
                "lat": 51.703,
                "lng": -3.041
            },
            "Hanwell": {
                "lat": 51.509,
                "lng": -0.338
            },
            "Stanford le Hope": {
                "lat": 51.514,
                "lng": 0.4244
            },
            "Ballymena": {
                "lat": 54.86,
                "lng": -6.28
            },
            "Frome": {
                "lat": 51.2279,
                "lng": -2.3215
            },
            "Wood Green": {
                "lat": 51.5981,
                "lng": -0.1149
            },
            "Bushey": {
                "lat": 51.6429,
                "lng": -0.3604
            },
            "Heywood": {
                "lat": 53.59,
                "lng": -2.219
            },
            "Carrickfergus": {
                "lat": 54.7136,
                "lng": -5.8075
            },
            "Bearsden": {
                "lat": 55.9195,
                "lng": -4.3337
            },
            "Reddish": {
                "lat": 53.4383,
                "lng": -2.1613
            },
            "Billericay": {
                "lat": 51.628,
                "lng": 0.4184
            },
            "Newry": {
                "lat": 54.176,
                "lng": -6.349
            },
            "Darlaston": {
                "lat": 52.5708,
                "lng": -2.0457
            },
            "Ashington": {
                "lat": 55.181,
                "lng": -1.568
            },
            "Jarrow": {
                "lat": 54.9814,
                "lng": -1.49
            },
            "Witney": {
                "lat": 51.78,
                "lng": -1.49
            },
            "Shotley Bridge": {
                "lat": 54.87,
                "lng": -1.86
            },
            "Longton": {
                "lat": 52.9877,
                "lng": -2.1327
            },
            "Melton Mowbray": {
                "lat": 52.7661,
                "lng": -0.886
            },
            "Haverhill": {
                "lat": 52.08,
                "lng": 0.44
            },
            "Thetford": {
                "lat": 52.41,
                "lng": 0.74
            },
            "Aldridge": {
                "lat": 52.606,
                "lng": -1.9179
            },
            "Seaford": {
                "lat": 50.77,
                "lng": 0.1
            },
            "Farnworth": {
                "lat": 53.5452,
                "lng": -2.3999
            },
            "Highbury": {
                "lat": 51.552,
                "lng": -0.097
            },
            "Newton Mearns": {
                "lat": 55.7716,
                "lng": -4.3347
            },
            "Cheadle Hulme": {
                "lat": 53.3761,
                "lng": -2.1897
            },
            "Newton Aycliffe": {
                "lat": 54.62,
                "lng": -1.58
            },
            "East Grinstead": {
                "lat": 51.1286,
                "lng": -0.0144
            },
            "Ashton in Makerfield": {
                "lat": 53.487,
                "lng": -2.641
            },
            "Clydebank": {
                "lat": 55.8997,
                "lng": -4.4006
            },
            "Wilmslow": {
                "lat": 53.325,
                "lng": -2.239
            },
            "Burntwood": {
                "lat": 52.6831,
                "lng": -1.92
            },
            "Fleetwood": {
                "lat": 53.922,
                "lng": -3.012
            },
            "Bournville": {
                "lat": 52.4299,
                "lng": -1.9355
            },
            "Shenley Brook End": {
                "lat": 52.009,
                "lng": -0.789
            },
            "Consett": {
                "lat": 54.85,
                "lng": -1.83
            },
            "New Milton": {
                "lat": 50.76,
                "lng": -1.65
            },
            "Coulsdon": {
                "lat": 51.3211,
                "lng": -0.1386
            },
            "Farnham": {
                "lat": 51.215,
                "lng": -0.799
            },
            "Bilston": {
                "lat": 52.566,
                "lng": -2.0728
            },
            "Wellington": {
                "lat": 50.9755,
                "lng": -3.2243
            },
            "Witham": {
                "lat": 51.7978,
                "lng": 0.6373
            },
            "Oldbury": {
                "lat": 52.505,
                "lng": -2.0159
            },
            "Thatcham": {
                "lat": 51.405,
                "lng": -1.264
            },
            "Bishop Auckland": {
                "lat": 54.663,
                "lng": -1.676
            },
            "Longbridge": {
                "lat": 52.395,
                "lng": -1.979
            },
            "Bloxwich": {
                "lat": 52.614,
                "lng": -2.004
            },
            "Upminster": {
                "lat": 51.5557,
                "lng": 0.2512
            },
            "Kirkby in Ashfield": {
                "lat": 53.099,
                "lng": -1.245
            },
            "Workington": {
                "lat": 54.6365,
                "lng": -3.5549
            },
            "Kingswinford": {
                "lat": 52.4981,
                "lng": -2.1657
            },
            "Rhyl": {
                "lat": 53.321,
                "lng": -3.48
            },
            "Whitehaven": {
                "lat": 54.548,
                "lng": -3.5855
            },
            "Droitwich": {
                "lat": 52.267,
                "lng": -2.153
            },
            "Daventry": {
                "lat": 52.2578,
                "lng": -1.1628
            },
            "Hindley": {
                "lat": 53.5355,
                "lng": -2.5658
            },
            "Portishead": {
                "lat": 51.484,
                "lng": -2.7626
            },
            "Pitsea": {
                "lat": 51.569,
                "lng": 0.504
            },
            "Westhoughton": {
                "lat": 53.549,
                "lng": -2.529
            },
            "Broadstairs": {
                "lat": 51.3589,
                "lng": 1.4394
            },
            "Thundersley": {
                "lat": 51.57,
                "lng": 0.59
            },
            "Gorleston-on-Sea": {
                "lat": 52.5757,
                "lng": 1.7235
            },
            "Penistone": {
                "lat": 53.525,
                "lng": -1.629
            },
            "Thornaby on Tees": {
                "lat": 54.5585,
                "lng": -1.3025
            },
            "Hertford": {
                "lat": 51.7966,
                "lng": -0.0774
            },
            "Newton in Makerfield": {
                "lat": 53.45,
                "lng": -2.633
            },
            "Felixstowe": {
                "lat": 51.9639,
                "lng": 1.3515
            },
            "Rugeley": {
                "lat": 52.7599,
                "lng": -1.9388
            },
            "Kings Norton": {
                "lat": 52.4072,
                "lng": -1.9272
            },
            "Alfreton": {
                "lat": 53.097,
                "lng": -1.38
            },
            "Chester-le-Street": {
                "lat": 54.8594,
                "lng": -1.5699
            },
            "Farnley": {
                "lat": 53.7876,
                "lng": -1.6159
            },
            "Great Sankey": {
                "lat": 53.3918,
                "lng": -2.6383
            },
            "Golborne": {
                "lat": 53.4758,
                "lng": -2.5943
            },
            "Plymstock": {
                "lat": 50.3569,
                "lng": -4.09
            },
            "Ryde": {
                "lat": 50.7271,
                "lng": -1.1618
            },
            "Ormskirk": {
                "lat": 53.5665,
                "lng": -2.8869
            },
            "Barnstaple": {
                "lat": 51.08,
                "lng": -4.06
            },
            "Rickmansworth": {
                "lat": 51.6383,
                "lng": -0.4659
            },
            "Littleover": {
                "lat": 52.906,
                "lng": -1.505
            },
            "Arbroath": {
                "lat": 56.561,
                "lng": -2.586
            },
            "Caversham": {
                "lat": 51.467,
                "lng": -0.973
            },
            "Oadby": {
                "lat": 52.5987,
                "lng": -1.0763
            },
            "Kidsgrove": {
                "lat": 53.0874,
                "lng": -2.2478
            },
            "East Retford": {
                "lat": 53.3228,
                "lng": -0.9431
            },
            "Huntingdon": {
                "lat": 52.3364,
                "lng": -0.1717
            },
            "Stalybridge": {
                "lat": 53.4834,
                "lng": -2.04
            },
            "Stanmore": {
                "lat": 51.618,
                "lng": -0.314
            },
            "Droylsden": {
                "lat": 53.4828,
                "lng": -2.1582
            },
            "Marple": {
                "lat": 53.397,
                "lng": -2.061
            },
            "Perry Barr": {
                "lat": 52.5249,
                "lng": -1.9042
            },
            "Gosforth": {
                "lat": 55.007,
                "lng": -1.623
            },
            "Newton Abbot": {
                "lat": 50.529,
                "lng": -3.61
            },
            "Bishopbriggs": {
                "lat": 55.9046,
                "lng": -4.225
            },
            "Whitefield": {
                "lat": 53.5521,
                "lng": -2.2992
            },
            "Elgin": {
                "lat": 57.6486,
                "lng": -3.3153
            },
            "Penwortham": {
                "lat": 53.74,
                "lng": -2.72
            },
            "Harborne": {
                "lat": 52.46,
                "lng": -1.95
            },
            "Rawtenstall": {
                "lat": 53.699,
                "lng": -2.291
            },
            "Clifton": {
                "lat": 53.9721,
                "lng": -1.0979
            },
            "Swinton": {
                "lat": 53.4877,
                "lng": -1.3149
            },
            "Bathgate": {
                "lat": 55.9024,
                "lng": -3.6431
            },
            "Market Harborough": {
                "lat": 52.4775,
                "lng": -0.9206
            },
            "Waltham Abbey": {
                "lat": 51.6846,
                "lng": 0.0004
            },
            "Gainsborough": {
                "lat": 53.4016,
                "lng": -0.7732
            },
            "Erdington": {
                "lat": 52.5236,
                "lng": -1.8378
            },
            "Haywards Heath": {
                "lat": 51.0048,
                "lng": -0.0979
            },
            "Wednesfield": {
                "lat": 52.5998,
                "lng": -2.0827
            },
            "Farsley": {
                "lat": 53.8116,
                "lng": -1.6718
            },
            "Kenilworth": {
                "lat": 52.341,
                "lng": -1.566
            },
            "Potters Bar": {
                "lat": 51.698,
                "lng": -0.183
            },
            "Foleshill": {
                "lat": 52.4255,
                "lng": -1.502
            },
            "Formby": {
                "lat": 53.5586,
                "lng": -3.0666
            },
            "Pudsey": {
                "lat": 53.797,
                "lng": -1.663
            },
            "Evesham": {
                "lat": 52.092,
                "lng": -1.947
            },
            "March": {
                "lat": 52.551,
                "lng": 0.088
            },
            "Tiverton": {
                "lat": 50.903,
                "lng": -3.488
            },
            "Shoeburyness": {
                "lat": 51.5316,
                "lng": 0.7978
            },
            "Litherland": {
                "lat": 53.4727,
                "lng": -2.999
            },
            "Yeadon": {
                "lat": 53.869,
                "lng": -1.688
            },
            "Acomb": {
                "lat": 53.955,
                "lng": -1.126
            },
            "Reigate": {
                "lat": 51.237,
                "lng": -0.206
            },
            "Penarth": {
                "lat": 51.43,
                "lng": -3.17
            },
            "Horley": {
                "lat": 51.174,
                "lng": -0.172
            },
            "Newquay": {
                "lat": 50.412,
                "lng": -5.0757
            },
            "Camborne": {
                "lat": 50.213,
                "lng": -5.3
            },
            "Northwood": {
                "lat": 51.601,
                "lng": -0.4176
            },
            "Atherton": {
                "lat": 53.523,
                "lng": -2.495
            },
            "Clevedon": {
                "lat": 51.438,
                "lng": -2.854
            },
            "Sandbach": {
                "lat": 53.146,
                "lng": -2.367
            },
            "Renfrew": {
                "lat": 55.878,
                "lng": -4.389
            },
            "Belper": {
                "lat": 53.029,
                "lng": -1.475
            },
            "Sheldon": {
                "lat": 52.45,
                "lng": -1.7666
            },
            "Falmouth": {
                "lat": 50.15,
                "lng": -5.07
            },
            "Yate": {
                "lat": 51.5402,
                "lng": -2.411
            },
            "Biggleswade": {
                "lat": 52.0855,
                "lng": -0.2557
            },
            "Bailleston": {
                "lat": 55.8474,
                "lng": -4.1147
            },
            "Truro": {
                "lat": 50.26,
                "lng": -5.051
            },
            "Stowmarket": {
                "lat": 52.19,
                "lng": 1.0
            },
            "Chesham": {
                "lat": 51.712,
                "lng": -0.612
            },
            "Richmond": {
                "lat": 51.456,
                "lng": -0.301
            },
            "Chandlers Ford": {
                "lat": 50.984,
                "lng": -1.3792
            },
            "Kirkintilloch": {
                "lat": 55.938,
                "lng": -4.155
            },
            "Darton": {
                "lat": 53.585,
                "lng": -1.5325
            },
            "Royton": {
                "lat": 53.566,
                "lng": -2.121
            },
            "Dronfield": {
                "lat": 53.3024,
                "lng": -1.4664
            },
            "Ossett": {
                "lat": 53.68,
                "lng": -1.58
            },
            "Penzance": {
                "lat": 50.119,
                "lng": -5.537
            },
            "Skegness": {
                "lat": 53.1436,
                "lng": 0.3428
            },
            "Saint Austell": {
                "lat": 50.34,
                "lng": -4.79
            },
            "Caterham": {
                "lat": 51.2803,
                "lng": -0.0816
            },
            "Woodlesford": {
                "lat": 53.7567,
                "lng": -1.453
            },
            "Stratton Saint Margaret": {
                "lat": 51.586,
                "lng": -1.762
            },
            "Normanton": {
                "lat": 53.697,
                "lng": -1.416
            },
            "Wallington": {
                "lat": 51.3647,
                "lng": -0.1403
            },
            "Musselburgh": {
                "lat": 55.942,
                "lng": -3.054
            },
            "Norton": {
                "lat": 54.589,
                "lng": -1.3157
            },
            "Leek": {
                "lat": 53.108,
                "lng": -2.0234
            },
            "Leigh-on-Sea": {
                "lat": 51.5425,
                "lng": 0.6535
            },
            "Boscombe": {
                "lat": 50.725,
                "lng": -1.84
            },
            "Llandudno": {
                "lat": 53.3225,
                "lng": -3.825
            },
            "Failsworth": {
                "lat": 53.5102,
                "lng": -2.1575
            },
            "Kirkstall": {
                "lat": 53.816,
                "lng": -1.602
            },
            "Crowborough": {
                "lat": 51.06,
                "lng": 0.16
            },
            "Dumbarton": {
                "lat": 55.95,
                "lng": -4.5667
            },
            "Shoreham-by-Sea": {
                "lat": 50.834,
                "lng": -0.273
            },
            "Hythe": {
                "lat": 51.0716,
                "lng": 1.084
            },
            "Hailsham": {
                "lat": 50.8647,
                "lng": 0.2577
            },
            "Omagh": {
                "lat": 54.598,
                "lng": -7.309
            },
            "Maghull": {
                "lat": 53.5174,
                "lng": -2.9449
            },
            "Newmarket": {
                "lat": 52.2459,
                "lng": 0.4105
            },
            "Sandhurst": {
                "lat": 51.349,
                "lng": -0.8
            },
            "Tewkesbury": {
                "lat": 51.99,
                "lng": -2.16
            },
            "Rothwell": {
                "lat": 53.7485,
                "lng": -1.478
            },
            "Bellshill": {
                "lat": 55.816,
                "lng": -4.026
            },
            "Ely": {
                "lat": 52.3981,
                "lng": 0.2622
            },
            "Ripley": {
                "lat": 53.05,
                "lng": -1.407
            },
            "Seaham": {
                "lat": 54.84,
                "lng": -1.34
            },
            "Peterlee": {
                "lat": 54.76,
                "lng": -1.33
            },
            "Dorchester": {
                "lat": 50.7154,
                "lng": -2.4367
            },
            "Halewood": {
                "lat": 53.3599,
                "lng": -2.84
            },
            "Killingworth": {
                "lat": 55.0318,
                "lng": -1.5557
            },
            "Horwich": {
                "lat": 53.592,
                "lng": -2.54
            },
            "Buxton": {
                "lat": 53.259,
                "lng": -1.911
            },
            "Bispham": {
                "lat": 53.852,
                "lng": -3.041
            },
            "Hampton": {
                "lat": 51.422,
                "lng": -0.367
            },
            "Minster": {
                "lat": 51.421,
                "lng": 0.809
            },
            "Antrim": {
                "lat": 54.7173,
                "lng": -6.2055
            },
            "Irlam": {
                "lat": 53.445,
                "lng": -2.422
            },
            "Biddulph": {
                "lat": 53.12,
                "lng": -2.17
            },
            "Spennymoor": {
                "lat": 54.7,
                "lng": -1.59
            },
            "Garforth": {
                "lat": 53.792,
                "lng": -1.388
            },
            "Sleaford": {
                "lat": 52.996,
                "lng": -0.413
            },
            "Stoke Gifford": {
                "lat": 51.517,
                "lng": -2.548
            },
            "Selby": {
                "lat": 53.7836,
                "lng": -1.0678
            },
            "Stamford": {
                "lat": 52.656,
                "lng": -0.484
            },
            "Davyhulme": {
                "lat": 53.4559,
                "lng": -2.3683
            },
            "Burnham-on-Sea": {
                "lat": 51.2376,
                "lng": -2.9935
            },
            "Abbots Langley": {
                "lat": 51.701,
                "lng": -0.416
            },
            "Mirfield": {
                "lat": 53.6807,
                "lng": -1.6888
            },
            "Goole": {
                "lat": 53.6992,
                "lng": -0.8692
            },
            "Kempston": {
                "lat": 52.114,
                "lng": -0.497
            },
            "Adel": {
                "lat": 53.8489,
                "lng": -1.5849
            },
            "Liversedge": {
                "lat": 53.7067,
                "lng": -1.69
            },
            "Great Linford": {
                "lat": 52.068,
                "lng": -0.7637
            },
            "Faversham": {
                "lat": 51.3177,
                "lng": 0.8928
            },
            "Dukinfield": {
                "lat": 53.4739,
                "lng": -2.0828
            },
            "Peterhead": {
                "lat": 57.5091,
                "lng": -1.7832
            },
            "Ecclesall": {
                "lat": 53.362,
                "lng": -1.498
            },
            "Ystrad Mynach": {
                "lat": 51.6419,
                "lng": -3.2405
            },
            "Frimley": {
                "lat": 51.3143,
                "lng": -0.7387
            },
            "Molesey": {
                "lat": 51.401,
                "lng": -0.363
            },
            "Prestatyn": {
                "lat": 53.331,
                "lng": -3.405
            },
            "Cirencester": {
                "lat": 51.719,
                "lng": -1.968
            },
            "Calne": {
                "lat": 51.438,
                "lng": -2.005
            },
            "Monkseaton": {
                "lat": 55.043,
                "lng": -1.459
            },
            "Southbourne": {
                "lat": 50.722,
                "lng": -1.798
            },
            "Horsforth": {
                "lat": 53.837,
                "lng": -1.643
            },
            "Larne": {
                "lat": 54.8517,
                "lng": -5.8133
            },
            "Lancing": {
                "lat": 50.832,
                "lng": -0.319
            },
            "Ware": {
                "lat": 51.8108,
                "lng": -0.0314
            },
            "Broughty Ferry": {
                "lat": 56.4672,
                "lng": -2.8699
            },
            "Eastwood": {
                "lat": 53.018,
                "lng": -1.304
            },
            "East Dereham": {
                "lat": 52.681,
                "lng": 0.94
            },
            "Stannington": {
                "lat": 53.396,
                "lng": -1.536
            },
            "Ebbw Vale": {
                "lat": 51.78,
                "lng": -3.21
            },
            "Appley Bridge": {
                "lat": 53.579,
                "lng": -2.721
            },
            "Rawmarsh": {
                "lat": 53.4636,
                "lng": -1.3439
            },
            "Staines-upon-Thames": {
                "lat": 51.434,
                "lng": -0.511
            },
            "Bedlington": {
                "lat": 55.133,
                "lng": -1.583
            },
            "Mill Hill": {
                "lat": 51.62,
                "lng": -0.23
            },
            "Almondbury": {
                "lat": 53.6344,
                "lng": -1.7489
            },
            "Hadleigh": {
                "lat": 51.5535,
                "lng": 0.6095
            },
            "Bingley": {
                "lat": 53.846,
                "lng": -1.836
            },
            "Bideford": {
                "lat": 51.016,
                "lng": -4.209
            },
            "Staveley": {
                "lat": 53.2694,
                "lng": -1.3484
            },
            "Shaw": {
                "lat": 53.5783,
                "lng": -2.0951
            },
            "Fulwood": {
                "lat": 53.365,
                "lng": -1.544
            },
            "Warminster": {
                "lat": 51.205,
                "lng": -2.181
            },
            "Redhill": {
                "lat": 51.2393,
                "lng": -0.1726
            },
            "Heckmondwike": {
                "lat": 53.708,
                "lng": -1.67
            },
            "Poulton le Fylde": {
                "lat": 53.847,
                "lng": -2.995
            },
            "Berkhampstead": {
                "lat": 51.76,
                "lng": -0.56
            },
            "Wandsworth": {
                "lat": 51.455,
                "lng": -0.192
            },
            "Sunbury": {
                "lat": 51.423,
                "lng": -0.424
            },
            "Mickleover": {
                "lat": 52.901,
                "lng": -1.552
            },
            "Bourne": {
                "lat": 52.7684,
                "lng": -0.3775
            },
            "Madeley": {
                "lat": 52.637,
                "lng": -2.448
            },
            "Ramsbottom": {
                "lat": 53.6483,
                "lng": -2.3167
            },
            "Colne": {
                "lat": 53.8554,
                "lng": -2.1756
            },
            "Alton": {
                "lat": 51.1498,
                "lng": -0.9769
            },
            "Portchester": {
                "lat": 50.842,
                "lng": -1.12
            },
            "Harwich": {
                "lat": 51.934,
                "lng": 1.266
            },
            "South Ockendon": {
                "lat": 51.5207,
                "lng": 0.2956
            },
            "Moreton": {
                "lat": 53.401,
                "lng": -3.111
            },
            "Whittlesey": {
                "lat": 52.558,
                "lng": -0.13
            },
            "Fern Down": {
                "lat": 50.81,
                "lng": -1.9
            },
            "Barrhead": {
                "lat": 55.801,
                "lng": -4.389
            },
            "Maesteg": {
                "lat": 51.61,
                "lng": -3.65
            },
            "Saint Andrews": {
                "lat": 56.3404,
                "lng": -2.7955
            },
            "Bonnyrigg": {
                "lat": 55.8747,
                "lng": -3.1031
            },
            "Quedgeley": {
                "lat": 51.825,
                "lng": -2.28
            },
            "Woodhouse": {
                "lat": 53.358,
                "lng": -1.373
            },
            "Royston": {
                "lat": 53.61,
                "lng": -1.45
            },
            "Bramhall": {
                "lat": 53.357,
                "lng": -2.164
            },
            "Louth": {
                "lat": 53.3669,
                "lng": -0.0061
            },
            "Hednesford": {
                "lat": 52.7115,
                "lng": -2.0006
            },
            "Thorne": {
                "lat": 53.6083,
                "lng": -0.9583
            },
            "Houghton Regis": {
                "lat": 51.9039,
                "lng": -0.5247
            },
            "Thornton": {
                "lat": 53.7898,
                "lng": -1.8504
            },
            "Heanor": {
                "lat": 53.014,
                "lng": -1.354
            },
            "Friern Barnet": {
                "lat": 51.6126,
                "lng": -0.1584
            },
            "Milton": {
                "lat": 53.05,
                "lng": -2.142
            },
            "Glen Parva": {
                "lat": 52.5867,
                "lng": -1.1617
            },
            "Cottingham": {
                "lat": 53.7822,
                "lng": -0.4136
            },
            "Clitheroe": {
                "lat": 53.8711,
                "lng": -2.3916
            },
            "Mosbrough": {
                "lat": 53.325,
                "lng": -1.362
            },
            "Heysham": {
                "lat": 54.046,
                "lng": -2.894
            },
            "Clayton": {
                "lat": 53.782,
                "lng": -1.8135
            },
            "Guisborough": {
                "lat": 54.535,
                "lng": -1.0563
            },
            "Morriston": {
                "lat": 51.6647,
                "lng": -3.9389
            },
            "High Blantyre": {
                "lat": 55.7936,
                "lng": -4.0919
            },
            "Maltby": {
                "lat": 53.426,
                "lng": -1.21
            },
            "Braunstone": {
                "lat": 52.616,
                "lng": -1.175
            },
            "Haslingden": {
                "lat": 53.705,
                "lng": -2.328
            },
            "North Hykeham": {
                "lat": 53.1833,
                "lng": -0.5875
            },
            "Devizes": {
                "lat": 51.3528,
                "lng": -1.9958
            },
            "Saltash": {
                "lat": 50.408,
                "lng": -4.212
            },
            "Haslemere": {
                "lat": 51.09,
                "lng": -0.712
            },
            "Brixham": {
                "lat": 50.394,
                "lng": -3.516
            },
            "Saint Ives": {
                "lat": 50.211,
                "lng": -5.48
            },
            "Fazakerley": {
                "lat": 53.4676,
                "lng": -2.9408
            },
            "Windlesham": {
                "lat": 51.36,
                "lng": -0.66
            },
            "Connahs Quay": {
                "lat": 53.218,
                "lng": -3.057
            },
            "Plumstead": {
                "lat": 51.49,
                "lng": 0.09
            },
            "Bredbury": {
                "lat": 53.42,
                "lng": -2.113
            },
            "Banstead": {
                "lat": 51.322,
                "lng": -0.204
            },
            "Whickham": {
                "lat": 54.946,
                "lng": -1.6761
            },
            "Grangemouth": {
                "lat": 56.012,
                "lng": -3.717
            },
            "Keynsham": {
                "lat": 51.4135,
                "lng": -2.4968
            },
            "Cleckheaton": {
                "lat": 53.725,
                "lng": -1.719
            },
            "The Mumbles": {
                "lat": 51.573,
                "lng": -3.9992
            },
            "Cowley": {
                "lat": 51.528,
                "lng": -0.481
            },
            "Hebburn": {
                "lat": 54.972,
                "lng": -1.513
            },
            "Ashby de la Zouch": {
                "lat": 52.746,
                "lng": -1.476
            },
            "Enfield Lock": {
                "lat": 51.6686,
                "lng": -0.026
            },
            "Kilwinning": {
                "lat": 55.655,
                "lng": -4.703
            },
            "Westbury": {
                "lat": 51.26,
                "lng": -2.191
            },
            "Stone": {
                "lat": 52.9,
                "lng": -2.15
            },
            "Denby Dale": {
                "lat": 53.572,
                "lng": -1.655
            },
            "Birstall": {
                "lat": 52.6736,
                "lng": -1.12
            },
            "Dungannon": {
                "lat": 54.5,
                "lng": -6.77
            },
            "Ripon": {
                "lat": 54.138,
                "lng": -1.524
            },
            "Queensbury": {
                "lat": 53.7683,
                "lng": -1.8453
            },
            "Stepney": {
                "lat": 51.5152,
                "lng": -0.0462
            },
            "Basford": {
                "lat": 52.978,
                "lng": -1.169
            },
            "Tyldesley": {
                "lat": 53.5166,
                "lng": -2.4667
            },
            "East Barnet": {
                "lat": 51.643,
                "lng": -0.163
            },
            "Upton": {
                "lat": 53.385,
                "lng": -3.099
            },
            "Buckley": {
                "lat": 53.172,
                "lng": -3.086
            },
            "Johnstone": {
                "lat": 55.8346,
                "lng": -4.5027
            },
            "Worcester Park": {
                "lat": 51.3752,
                "lng": -0.239
            },
            "East Finchley": {
                "lat": 51.5902,
                "lng": -0.1753
            },
            "Lewes": {
                "lat": 50.8747,
                "lng": 0.0117
            },
            "Chertsey": {
                "lat": 51.3902,
                "lng": -0.5074
            },
            "South Hayling": {
                "lat": 50.78,
                "lng": -0.97
            },
            "Penicuik": {
                "lat": 55.826,
                "lng": -3.22
            },
            "Adwick le Street": {
                "lat": 53.5677,
                "lng": -1.1931
            },
            "Addlestone": {
                "lat": 51.3695,
                "lng": -0.4901
            },
            "Northallerton": {
                "lat": 54.3378,
                "lng": -1.4285
            },
            "Lymington": {
                "lat": 50.75,
                "lng": -1.55
            },
            "Carterton": {
                "lat": 51.76,
                "lng": -1.59
            },
            "Ponders End": {
                "lat": 51.646,
                "lng": -0.046
            },
            "Ince-in-Makerfield": {
                "lat": 53.5402,
                "lng": -2.599
            },
            "Porthcawl": {
                "lat": 51.48,
                "lng": -3.69
            },
            "Nailsea": {
                "lat": 51.43,
                "lng": -2.76
            },
            "Oswestry": {
                "lat": 52.8598,
                "lng": -3.0538
            },
            "West Ham": {
                "lat": 51.534,
                "lng": 0.008
            },
            "Matlock": {
                "lat": 53.14,
                "lng": -1.55
            },
            "Saffron Walden": {
                "lat": 52.022,
                "lng": 0.243
            },
            "Shipley": {
                "lat": 53.833,
                "lng": -1.777
            },
            "Todmorden": {
                "lat": 53.713,
                "lng": -2.096
            },
            "Stapleford": {
                "lat": 52.929,
                "lng": -1.274
            },
            "Weybridge": {
                "lat": 51.362,
                "lng": -0.453
            },
            "Knaresborough": {
                "lat": 54.0084,
                "lng": -1.467
            },
            "Broxburn": {
                "lat": 55.934,
                "lng": -3.471
            },
            "Seacombe": {
                "lat": 53.409,
                "lng": -3.029
            },
            "Baildon": {
                "lat": 53.851,
                "lng": -1.763
            },
            "Perivale": {
                "lat": 51.5383,
                "lng": -0.3192
            },
            "Wombwell": {
                "lat": 53.516,
                "lng": -1.4
            },
            "Stanley": {
                "lat": 53.7145,
                "lng": -1.476
            },
            "Llantrisant": {
                "lat": 51.542,
                "lng": -3.375
            },
            "Broxbourne": {
                "lat": 51.7495,
                "lng": -0.0216
            },
            "Dawlish": {
                "lat": 50.581,
                "lng": -3.466
            },
            "Featherstone": {
                "lat": 53.7,
                "lng": -1.37
            },
            "Mexborough": {
                "lat": 53.4992,
                "lng": -1.283
            },
            "Neston": {
                "lat": 53.289,
                "lng": -3.074
            },
            "Bidston": {
                "lat": 53.402,
                "lng": -3.078
            },
            "Northenden": {
                "lat": 53.4075,
                "lng": -2.2583
            },
            "Chellaston": {
                "lat": 52.8671,
                "lng": -1.4384
            },
            "Verwood": {
                "lat": 50.8815,
                "lng": -1.8785
            },
            "Redruth": {
                "lat": 50.2328,
                "lng": -5.2264
            },
            "Palmers Green": {
                "lat": 51.6178,
                "lng": -0.1092
            },
            "Totteridge": {
                "lat": 51.6354,
                "lng": -0.2
            },
            "Blaydon": {
                "lat": 54.963,
                "lng": -1.719
            },
            "New Brighton": {
                "lat": 53.432,
                "lng": -3.049
            },
            "Newport Pagnell": {
                "lat": 52.087,
                "lng": -0.722
            },
            "Tredegar": {
                "lat": 51.7776,
                "lng": -3.2407
            },
            "Swallownest": {
                "lat": 53.3623,
                "lng": -1.3251
            },
            "Netherton": {
                "lat": 52.4908,
                "lng": -2.0835
            },
            "Hessle": {
                "lat": 53.7239,
                "lng": -0.4319
            },
            "Winkfield": {
                "lat": 51.4318,
                "lng": -0.7096
            },
            "Petersfield": {
                "lat": 51.0038,
                "lng": -0.9374
            },
            "Kenley": {
                "lat": 51.3242,
                "lng": -0.0969
            },
            "Sudbury": {
                "lat": 52.0417,
                "lng": 0.7282
            },
            "Wyke": {
                "lat": 53.7333,
                "lng": -1.7667
            },
            "Kew Green": {
                "lat": 51.5308,
                "lng": -0.2248
            },
            "West Wickham": {
                "lat": 51.3765,
                "lng": -0.0193
            },
            "Shepshed": {
                "lat": 52.7711,
                "lng": -1.2951
            },
            "Bromborough": {
                "lat": 53.336,
                "lng": -2.978
            },
            "Chislehurst": {
                "lat": 51.412,
                "lng": 0.075
            },
            "Ilkley": {
                "lat": 53.925,
                "lng": -1.822
            },
            "Baguley": {
                "lat": 53.399,
                "lng": -2.276
            },
            "Armagh": {
                "lat": 54.3499,
                "lng": -6.6546
            },
            "Romsey": {
                "lat": 50.989,
                "lng": -1.4966
            },
            "Bo\u2019ness": {
                "lat": 56.0168,
                "lng": -3.6089
            },
            "Penrith": {
                "lat": 54.6648,
                "lng": -2.7548
            },
            "Balham": {
                "lat": 51.4434,
                "lng": -0.1525
            },
            "Teignmouth": {
                "lat": 50.5515,
                "lng": -3.4886
            },
            "Banbridge": {
                "lat": 54.349,
                "lng": -6.27
            },
            "Larkhall": {
                "lat": 55.737,
                "lng": -3.972
            },
            "Bodmin": {
                "lat": 50.466,
                "lng": -4.718
            },
            "Conwy": {
                "lat": 53.28,
                "lng": -3.83
            },
            "Prestwick": {
                "lat": 55.4956,
                "lng": -4.6142
            },
            "Troon": {
                "lat": 55.54,
                "lng": -4.66
            },
            "Melksham": {
                "lat": 51.371,
                "lng": -2.138
            },
            "Wibsey": {
                "lat": 53.7672,
                "lng": -1.7728
            },
            "Great Baddow": {
                "lat": 51.719,
                "lng": 0.507
            },
            "Skipton": {
                "lat": 53.9625,
                "lng": -2.0163
            },
            "Port Glasgow": {
                "lat": 55.934,
                "lng": -4.6906
            },
            "Lowton": {
                "lat": 53.471,
                "lng": -2.569
            },
            "Burbage": {
                "lat": 52.5277,
                "lng": -1.3483
            },
            "Thorpe Saint Andrew": {
                "lat": 52.6354,
                "lng": 1.3431
            },
            "Moston": {
                "lat": 53.5156,
                "lng": -2.1848
            },
            "Chelsfield": {
                "lat": 51.3582,
                "lng": 0.1278
            },
            "Aberdare": {
                "lat": 51.713,
                "lng": -3.445
            },
            "Thornbury": {
                "lat": 51.6094,
                "lng": -2.5249
            },
            "Morton": {
                "lat": 51.6094,
                "lng": -2.5249
            },
            "Uckfield": {
                "lat": 50.97,
                "lng": 0.1
            },
            "Deysbrook": {
                "lat": 53.429,
                "lng": -2.934
            },
            "Armthorpe": {
                "lat": 53.5352,
                "lng": -1.0522
            },
            "Southgate": {
                "lat": 51.6316,
                "lng": -0.1265
            },
            "Alloa": {
                "lat": 56.116,
                "lng": -3.793
            },
            "Dollis Hill": {
                "lat": 51.5641,
                "lng": -0.2341
            },
            "Wymondham": {
                "lat": 52.57,
                "lng": 1.116
            },
            "Hetton le Hole": {
                "lat": 54.821,
                "lng": -1.449
            },
            "Amersham": {
                "lat": 51.677,
                "lng": -0.603
            },
            "West Derby": {
                "lat": 53.4338,
                "lng": -2.907
            },
            "Cowes": {
                "lat": 50.7595,
                "lng": -1.3002
            },
            "West Drayton": {
                "lat": 51.5043,
                "lng": -0.4646
            },
            "Conisbrough": {
                "lat": 53.479,
                "lng": -1.227
            },
            "Rottingdean": {
                "lat": 50.815,
                "lng": -0.065
            },
            "Kidbrooke": {
                "lat": 51.4621,
                "lng": 0.0273
            },
            "Rock Ferry": {
                "lat": 53.373,
                "lng": -3.008
            },
            "Shirley": {
                "lat": 51.3813,
                "lng": -0.0543
            },
            "Pollokshaws": {
                "lat": 55.8252,
                "lng": -4.2947
            },
            "Chard": {
                "lat": 50.8728,
                "lng": -2.9587
            },
            "Patcham": {
                "lat": 50.864,
                "lng": -0.15
            },
            "Weston": {
                "lat": 53.413,
                "lng": -2.798
            },
            "Poynton": {
                "lat": 53.35,
                "lng": -2.1167
            },
            "Seacroft": {
                "lat": 53.8222,
                "lng": -1.4599
            },
            "Forfar": {
                "lat": 56.6442,
                "lng": -2.8884
            },
            "Maldon": {
                "lat": 51.7318,
                "lng": 0.6758
            },
            "Kearsley": {
                "lat": 53.53,
                "lng": -2.37
            },
            "Leyton": {
                "lat": 51.57,
                "lng": -0.015
            },
            "Mackworth": {
                "lat": 52.9277,
                "lng": -1.5373
            },
            "Ashtead": {
                "lat": 51.31,
                "lng": -0.299
            },
            "Wombourn": {
                "lat": 52.5302,
                "lng": -2.1857
            },
            "Bishops Cleeve": {
                "lat": 51.947,
                "lng": -2.061
            },
            "Nantwich": {
                "lat": 53.067,
                "lng": -2.522
            },
            "Hazel Grove": {
                "lat": 53.375,
                "lng": -2.111
            },
            "Morpeth": {
                "lat": 55.1675,
                "lng": -1.6908
            },
            "Uttoxeter": {
                "lat": 52.898,
                "lng": -1.86
            },
            "Marlow": {
                "lat": 51.57,
                "lng": -0.78
            },
            "Pemberton": {
                "lat": 53.536,
                "lng": -2.6738
            },
            "Brierley Hill": {
                "lat": 52.4795,
                "lng": -2.1245
            },
            "Streetly": {
                "lat": 52.577,
                "lng": -1.884
            },
            "Bickley": {
                "lat": 51.4003,
                "lng": 0.0466
            },
            "Rustington": {
                "lat": 50.8102,
                "lng": -0.5052
            },
            "Eastham": {
                "lat": 53.313,
                "lng": -2.962
            },
            "Cosham": {
                "lat": 50.8424,
                "lng": -1.066
            },
            "Enniskillen": {
                "lat": 54.3447,
                "lng": -7.6389
            },
            "Littleborough": {
                "lat": 53.644,
                "lng": -2.098
            },
            "Rosyth": {
                "lat": 56.0339,
                "lng": -3.4323
            },
            "Hawick": {
                "lat": 55.422,
                "lng": -2.787
            },
            "Sidmouth": {
                "lat": 50.68,
                "lng": -3.239
            },
            "Milford Haven": {
                "lat": 51.7142,
                "lng": -5.0427
            },
            "Kidlington": {
                "lat": 51.8231,
                "lng": -1.2903
            },
            "Knottingley": {
                "lat": 53.705,
                "lng": -1.249
            },
            "Otley": {
                "lat": 53.905,
                "lng": -1.687
            },
            "Helensburgh": {
                "lat": 56.0166,
                "lng": -4.7333
            },
            "Childwall": {
                "lat": 53.395,
                "lng": -2.881
            },
            "Allestree": {
                "lat": 52.9519,
                "lng": -1.4856
            },
            "Middlewich": {
                "lat": 53.192,
                "lng": -2.443
            },
            "Whitby": {
                "lat": 54.4858,
                "lng": -0.6206
            },
            "Royal Wootton Bassett": {
                "lat": 51.533,
                "lng": -1.9
            },
            "Bridport": {
                "lat": 50.7336,
                "lng": -2.7584
            },
            "Rossington": {
                "lat": 53.4759,
                "lng": -1.0613
            },
            "Hilsea": {
                "lat": 50.83,
                "lng": -1.07
            },
            "Hemsworth": {
                "lat": 53.61,
                "lng": -1.35
            },
            "Strabane": {
                "lat": 54.83,
                "lng": -7.47
            },
            "Little Hulton": {
                "lat": 53.53,
                "lng": -2.418
            },
            "Stroud": {
                "lat": 51.744,
                "lng": -2.215
            },
            "Chaddesden": {
                "lat": 52.9301,
                "lng": -1.4383
            },
            "Heswall": {
                "lat": 53.328,
                "lng": -3.099
            },
            "Dalkeith": {
                "lat": 55.8958,
                "lng": -3.0583
            },
            "Amblecote": {
                "lat": 52.46,
                "lng": -2.16
            },
            "Alsager": {
                "lat": 53.096,
                "lng": -2.305
            },
            "Saint Budeaux": {
                "lat": 50.4033,
                "lng": -4.1856
            },
            "Corsham": {
                "lat": 51.434,
                "lng": -2.185
            },
            "Egg Buckland": {
                "lat": 50.4006,
                "lng": -4.1136
            },
            "Bacup": {
                "lat": 53.704,
                "lng": -2.199
            },
            "Carluke": {
                "lat": 55.734,
                "lng": -3.834
            },
            "Ampthill": {
                "lat": 52.0263,
                "lng": -0.4906
            },
            "Shirebrook": {
                "lat": 53.2048,
                "lng": -1.2197
            },
            "Shiremoor": {
                "lat": 55.0366,
                "lng": -1.5048
            },
            "Standish": {
                "lat": 53.586,
                "lng": -2.664
            },
            "Thame": {
                "lat": 51.75,
                "lng": -0.97
            },
            "West Boldon": {
                "lat": 54.945,
                "lng": -1.441
            },
            "Linlithgow": {
                "lat": 55.9791,
                "lng": -3.6105
            },
            "Knutsford": {
                "lat": 53.3025,
                "lng": -2.3708
            },
            "Dingle": {
                "lat": 53.3774,
                "lng": -2.9613
            },
            "Walsall Wood": {
                "lat": 52.6277,
                "lng": -1.9301
            },
            "Southwick": {
                "lat": 54.9193,
                "lng": -1.4062
            },
            "Fraserburgh": {
                "lat": 57.693,
                "lng": -2.005
            },
            "Caerfyrddin": {
                "lat": 51.856,
                "lng": -4.316
            },
            "Hamworthy": {
                "lat": 50.7207,
                "lng": -2.0109
            },
            "Wantage": {
                "lat": 51.589,
                "lng": -1.427
            },
            "Wollaston": {
                "lat": 52.4619,
                "lng": -2.1663
            },
            "Great Driffield": {
                "lat": 54.005,
                "lng": -0.438
            },
            "Pendlebury": {
                "lat": 53.5075,
                "lng": -2.3154
            },
            "Goodmayes": {
                "lat": 51.5631,
                "lng": 0.1133
            },
            "Milnrow": {
                "lat": 53.6101,
                "lng": -2.1111
            },
            "Aberystwyth": {
                "lat": 52.414,
                "lng": -4.081
            },
            "Holborn": {
                "lat": 51.5204,
                "lng": -0.1136
            },
            "Brackley": {
                "lat": 52.032,
                "lng": -1.147
            },
            "Midsomer Norton": {
                "lat": 51.2842,
                "lng": -2.4817
            },
            "Galashiels": {
                "lat": 55.6194,
                "lng": -2.8033
            },
            "Gornalwood": {
                "lat": 52.523,
                "lng": -2.124
            },
            "Yiewsley": {
                "lat": 51.513,
                "lng": -0.471
            },
            "Shenley Church End": {
                "lat": 52.022,
                "lng": -0.788
            },
            "Flint": {
                "lat": 53.2482,
                "lng": -3.1358
            },
            "Woolton": {
                "lat": 53.374,
                "lng": -2.865
            },
            "Leagrave": {
                "lat": 51.903,
                "lng": -0.466
            },
            "Cliftonville": {
                "lat": 51.3881,
                "lng": 1.4046
            },
            "Buckingham": {
                "lat": 51.995,
                "lng": -0.986
            },
            "Chilwell": {
                "lat": 52.916,
                "lng": -1.235
            },
            "Syston": {
                "lat": 52.7,
                "lng": -1.08
            },
            "Little Lever": {
                "lat": 53.563,
                "lng": -2.369
            },
            "Milngavie": {
                "lat": 55.9421,
                "lng": -4.3137
            },
            "Sanderstead": {
                "lat": 51.3358,
                "lng": -0.0818
            },
            "Chalfont Saint Peter": {
                "lat": 51.607,
                "lng": -0.556
            },
            "Ainsdale": {
                "lat": 53.6021,
                "lng": -3.0405
            },
            "Lymm": {
                "lat": 53.3834,
                "lng": -2.475
            },
            "Flitwick": {
                "lat": 52.0038,
                "lng": -0.4966
            },
            "Brownhills": {
                "lat": 52.647,
                "lng": -1.933
            },
            "Hornsey": {
                "lat": 51.587,
                "lng": -0.118
            },
            "Sutton on Hull": {
                "lat": 53.7806,
                "lng": -0.3047
            },
            "Market Warsop": {
                "lat": 53.2,
                "lng": -1.15
            },
            "Warsop": {
                "lat": 53.2,
                "lng": -1.15
            },
            "Saltcoats": {
                "lat": 55.6352,
                "lng": -4.7896
            },
            "Horndean": {
                "lat": 50.9136,
                "lng": -0.9961
            },
            "North Walsham": {
                "lat": 52.8214,
                "lng": 1.3861
            },
            "Wordsley": {
                "lat": 52.483,
                "lng": -2.15
            },
            "Croxley Green": {
                "lat": 51.647,
                "lng": -0.445
            },
            "Armadale": {
                "lat": 55.8978,
                "lng": -3.7047
            },
            "Mablethorpe": {
                "lat": 53.3409,
                "lng": 0.261
            },
            "Wolverton": {
                "lat": 52.0626,
                "lng": -0.8102
            },
            "Costessey": {
                "lat": 52.6602,
                "lng": 1.2161
            },
            "Barton upon Irwell": {
                "lat": 53.476,
                "lng": -2.36
            },
            "Cromer": {
                "lat": 52.931,
                "lng": 1.302
            },
            "Bickenhill": {
                "lat": 52.439,
                "lng": -1.725
            },
            "Tilbury": {
                "lat": 51.4606,
                "lng": 0.3582
            },
            "Chigwell": {
                "lat": 51.6225,
                "lng": 0.0723
            },
            "Hersham": {
                "lat": 51.3681,
                "lng": -0.4008
            },
            "Spondon": {
                "lat": 52.92,
                "lng": -1.401
            },
            "Coseley": {
                "lat": 52.55,
                "lng": -2.083
            },
            "Ovenden": {
                "lat": 53.7432,
                "lng": -1.8779
            },
            "Chepstow": {
                "lat": 51.642,
                "lng": -2.675
            },
            "Lye": {
                "lat": 52.459,
                "lng": -2.116
            },
            "Tonyrefail": {
                "lat": 51.584,
                "lng": -3.4306
            },
            "Giffnock": {
                "lat": 55.8051,
                "lng": -4.2946
            },
            "New Mills": {
                "lat": 53.367,
                "lng": -2.007
            },
            "Thatto Heath": {
                "lat": 53.4352,
                "lng": -2.7481
            },
            "Harrow on the Hill": {
                "lat": 51.5655,
                "lng": -0.3327
            },
            "Selston": {
                "lat": 53.07,
                "lng": -1.3
            },
            "Newhaven": {
                "lat": 50.8,
                "lng": 0.06
            },
            "Olton": {
                "lat": 52.4377,
                "lng": -1.8058
            },
            "Cheadle": {
                "lat": 52.9849,
                "lng": -1.9865
            },
            "Tranent": {
                "lat": 55.945,
                "lng": -2.954
            },
            "Bamber Bridge": {
                "lat": 53.7281,
                "lng": -2.6606
            },
            "Huntington": {
                "lat": 53.9926,
                "lng": -1.043
            },
            "Montrose": {
                "lat": 56.708,
                "lng": -2.467
            },
            "Tring": {
                "lat": 51.7962,
                "lng": -0.6592
            },
            "North Tidworth": {
                "lat": 51.237,
                "lng": -1.664
            },
            "Sedgley": {
                "lat": 52.54,
                "lng": -2.123
            },
            "Beaconsfield": {
                "lat": 51.6009,
                "lng": -0.6347
            },
            "Bridgnorth": {
                "lat": 52.535,
                "lng": -2.4195
            },
            "Fenton": {
                "lat": 52.9977,
                "lng": -2.1578
            },
            "Walton-on-the-Naze": {
                "lat": 51.848,
                "lng": 1.268
            },
            "Bentley": {
                "lat": 53.551,
                "lng": -1.144
            },
            "Berwick-Upon-Tweed": {
                "lat": 55.7692,
                "lng": -2.0025
            },
            "Hwlffordd": {
                "lat": 51.8,
                "lng": -4.97
            },
            "Wells": {
                "lat": 51.2094,
                "lng": -2.645
            },
            "Minehead": {
                "lat": 51.2038,
                "lng": -3.4738
            },
            "Leominster": {
                "lat": 52.2282,
                "lng": -2.7385
            },
            "Sheerness": {
                "lat": 51.441,
                "lng": 0.76
            },
            "Sandridge": {
                "lat": 51.7808,
                "lng": -0.3038
            },
            "Halstead": {
                "lat": 51.9451,
                "lng": 0.6411
            },
            "Bargoed": {
                "lat": 51.69,
                "lng": -3.24
            },
            "Rushall": {
                "lat": 52.6089,
                "lng": -1.9592
            },
            "Snaresbrook": {
                "lat": 51.587,
                "lng": 0.0146
            },
            "Eckington": {
                "lat": 53.308,
                "lng": -1.3643
            },
            "Hoyland Nether": {
                "lat": 53.4985,
                "lng": -1.4406
            },
            "Ivybridge": {
                "lat": 50.389,
                "lng": -3.921
            },
            "Torton": {
                "lat": 52.4522,
                "lng": -2.1606
            },
            "Trentham": {
                "lat": 52.9663,
                "lng": -2.1899
            },
            "Wath upon Dearne": {
                "lat": 53.5022,
                "lng": -1.3402
            },
            "Street": {
                "lat": 51.127,
                "lng": -2.74
            },
            "Oswaldtwistle": {
                "lat": 53.743,
                "lng": -2.393
            },
            "Market Drayton": {
                "lat": 52.9044,
                "lng": -2.4848
            },
            "Hackney": {
                "lat": 51.541,
                "lng": -0.027
            },
            "Wetherby": {
                "lat": 53.9276,
                "lng": -1.3839
            },
            "Sowerby Bridge": {
                "lat": 53.71,
                "lng": -1.91
            },
            "Risca": {
                "lat": 51.608,
                "lng": -3.091
            },
            "Ulverston": {
                "lat": 54.193,
                "lng": -3.09
            },
            "Elland": {
                "lat": 53.683,
                "lng": -1.84
            },
            "Prudhoe": {
                "lat": 54.961,
                "lng": -1.849
            },
            "Bolsover": {
                "lat": 53.2304,
                "lng": -1.2875
            },
            "Bryn": {
                "lat": 53.499,
                "lng": -2.657
            },
            "Sandy": {
                "lat": 52.131,
                "lng": -0.297
            },
            "Cowdenbeath": {
                "lat": 56.11,
                "lng": -3.35
            },
            "Burnham": {
                "lat": 51.54,
                "lng": -0.66
            },
            "Henley on Thames": {
                "lat": 51.5357,
                "lng": -0.903
            },
            "Ascot": {
                "lat": 51.4084,
                "lng": -0.6707
            },
            "Sunninghill": {
                "lat": 51.4025,
                "lng": -0.655
            },
            "Abertillery": {
                "lat": 51.73,
                "lng": -3.13
            },
            "Wallingford": {
                "lat": 51.599,
                "lng": -1.125
            },
            "Belgrave": {
                "lat": 52.6566,
                "lng": -1.1262
            },
            "Downpatrick": {
                "lat": 54.322,
                "lng": -5.703
            },
            "Wanstead": {
                "lat": 51.5778,
                "lng": 0.0286
            },
            "Blundellsands": {
                "lat": 53.48,
                "lng": -3.05
            },
            "Brislington": {
                "lat": 51.4316,
                "lng": -2.5439
            },
            "Tadley": {
                "lat": 51.3506,
                "lng": -1.1376
            },
            "Culcheth": {
                "lat": 53.4517,
                "lng": -2.5218
            },
            "Holyhead": {
                "lat": 53.309,
                "lng": -4.633
            },
            "Haydock": {
                "lat": 53.4678,
                "lng": -2.6609
            },
            "Dawley": {
                "lat": 52.663,
                "lng": -2.467
            },
            "Wealdstone": {
                "lat": 51.5957,
                "lng": -0.3352
            },
            "Greetland": {
                "lat": 53.6869,
                "lng": -1.8714
            },
            "Buckhurst Hill": {
                "lat": 51.632,
                "lng": 0.036
            },
            "Harrow Weald": {
                "lat": 51.604,
                "lng": -0.339
            },
            "Great Bookham": {
                "lat": 51.278,
                "lng": -0.373
            },
            "Little Bookham": {
                "lat": 51.2804,
                "lng": -0.3897
            },
            "Pelsall": {
                "lat": 52.631,
                "lng": -1.972
            },
            "Carnoustie": {
                "lat": 56.501,
                "lng": -2.71
            },
            "Newtown": {
                "lat": 52.5132,
                "lng": -3.3141
            },
            "Knowsley": {
                "lat": 53.4498,
                "lng": -2.8501
            },
            "Leatherhead": {
                "lat": 51.295,
                "lng": -0.329
            },
            "Oxted": {
                "lat": 51.257,
                "lng": -0.006
            },
            "Burslem": {
                "lat": 53.0426,
                "lng": -2.1879
            },
            "Hipperholme": {
                "lat": 53.7258,
                "lng": -1.812
            },
            "Chorleywood": {
                "lat": 51.65,
                "lng": -0.51
            },
            "Limavady": {
                "lat": 55.053,
                "lng": -6.946
            },
            "Stanwell": {
                "lat": 51.457,
                "lng": -0.4715
            },
            "Astley": {
                "lat": 53.5008,
                "lng": -2.4454
            },
            "Burtonwood": {
                "lat": 53.4302,
                "lng": -2.6614
            },
            "Maryport": {
                "lat": 54.7128,
                "lng": -3.4926
            },
            "Largs": {
                "lat": 55.795,
                "lng": -4.87
            },
            "Boultham": {
                "lat": 53.214,
                "lng": -0.5561
            },
            "Kingsnorth": {
                "lat": 51.1178,
                "lng": 0.8615
            },
            "Greasley": {
                "lat": 53.02,
                "lng": -1.27
            },
            "Cranleigh": {
                "lat": 51.1363,
                "lng": -0.4784
            },
            "Mountain Ash": {
                "lat": 51.6814,
                "lng": -3.3792
            },
            "Crayford": {
                "lat": 51.4491,
                "lng": 0.1812
            },
            "Castle Bromwich": {
                "lat": 52.505,
                "lng": -1.7856
            },
            "Linslade": {
                "lat": 51.9243,
                "lng": -0.6774
            },
            "Ilfracombe": {
                "lat": 51.208,
                "lng": -4.12
            },
            "Prescot": {
                "lat": 53.4286,
                "lng": -2.8064
            },
            "Wootton": {
                "lat": 52.2007,
                "lng": -0.8857
            },
            "Stonehaven": {
                "lat": 56.964,
                "lng": -2.211
            },
            "Dorking": {
                "lat": 51.2325,
                "lng": -0.3306
            },
            "Honiton": {
                "lat": 50.8,
                "lng": -3.19
            },
            "Aylestone": {
                "lat": 52.604,
                "lng": -1.154
            },
            "Dorridge": {
                "lat": 52.372,
                "lng": -1.7554
            },
            "Hexham": {
                "lat": 54.971,
                "lng": -2.101
            },
            "Evington": {
                "lat": 52.628,
                "lng": -1.074
            },
            "Southborough": {
                "lat": 51.1598,
                "lng": 0.2652
            },
            "Iver": {
                "lat": 51.521,
                "lng": -0.507
            },
            "Ollerton": {
                "lat": 53.2,
                "lng": -1.02
            },
            "Barton upon Humber": {
                "lat": 53.6833,
                "lng": -0.45
            },
            "Timperley": {
                "lat": 53.387,
                "lng": -2.328
            },
            "Whitchurch": {
                "lat": 52.969,
                "lng": -2.682
            },
            "Great Wyrley": {
                "lat": 52.6593,
                "lng": -2.0102
            },
            "Larbert": {
                "lat": 56.0229,
                "lng": -3.826
            },
            "Ballymoney": {
                "lat": 55.071,
                "lng": -6.508
            },
            "Epping": {
                "lat": 51.7004,
                "lng": 0.1087
            },
            "Bottesford": {
                "lat": 53.5521,
                "lng": -0.6714
            },
            "Tavistock": {
                "lat": 50.545,
                "lng": -4.15
            },
            "Chatteris": {
                "lat": 52.456,
                "lng": 0.055
            },
            "Llandybie": {
                "lat": 51.82,
                "lng": -4.001
            },
            "Churchdown": {
                "lat": 51.88,
                "lng": -2.17
            },
            "Colwyn Bay": {
                "lat": 53.29,
                "lng": -3.7
            },
            "Cudworth": {
                "lat": 53.5784,
                "lng": -1.4149
            },
            "Oakham": {
                "lat": 52.6705,
                "lng": -0.7333
            },
            "Hellesdon": {
                "lat": 52.6485,
                "lng": 1.2509
            },
            "Highgate": {
                "lat": 51.5716,
                "lng": -0.1448
            },
            "Ponteland": {
                "lat": 55.048,
                "lng": -1.747
            },
            "Mossley": {
                "lat": 53.5147,
                "lng": -2.0387
            },
            "Hollinwood": {
                "lat": 53.5183,
                "lng": -2.144
            },
            "Great Harwood": {
                "lat": 53.786,
                "lng": -2.408
            },
            "Inverurie": {
                "lat": 57.28,
                "lng": -2.38
            },
            "Soham": {
                "lat": 52.3338,
                "lng": 0.3361
            },
            "Whitburn": {
                "lat": 55.8621,
                "lng": -3.6872
            },
            "Rainhill": {
                "lat": 53.4157,
                "lng": -2.7625
            },
            "Earlestown": {
                "lat": 53.45,
                "lng": -2.65
            },
            "Biggin Hill": {
                "lat": 51.3127,
                "lng": 0.0336
            },
            "Shepton Mallet": {
                "lat": 51.193,
                "lng": -2.546
            },
            "Methil": {
                "lat": 56.1844,
                "lng": -3.0223
            },
            "Wooburn": {
                "lat": 51.581,
                "lng": -0.691
            },
            "Flixton": {
                "lat": 53.447,
                "lng": -2.391
            },
            "Parkstone": {
                "lat": 50.71,
                "lng": -1.95
            },
            "Barnoldswick": {
                "lat": 53.9147,
                "lng": -2.1895
            },
            "Selsey": {
                "lat": 50.735,
                "lng": -0.7898
            },
            "Amesbury": {
                "lat": 51.173,
                "lng": -1.78
            },
            "Ormesby": {
                "lat": 54.5492,
                "lng": -1.1817
            },
            "Diss": {
                "lat": 52.3812,
                "lng": 1.1079
            },
            "Ross on Wye": {
                "lat": 51.914,
                "lng": -2.587
            },
            "Desborough": {
                "lat": 52.4398,
                "lng": -0.8172
            },
            "Hatch End": {
                "lat": 51.601,
                "lng": -0.3743
            },
            "Chesterfield": {
                "lat": 53.2363,
                "lng": -1.4292
            },
            "Darfield": {
                "lat": 53.538,
                "lng": -1.3812
            },
            "Knowle": {
                "lat": 52.3881,
                "lng": -1.7318
            },
            "Ardrossan": {
                "lat": 55.6432,
                "lng": -4.8097
            },
            "Aylesford": {
                "lat": 51.3033,
                "lng": 0.4796
            },
            "Cookstown": {
                "lat": 54.647,
                "lng": -6.745
            },
            "Longbenton": {
                "lat": 55.0,
                "lng": -1.57
            },
            "Felton": {
                "lat": 51.51,
                "lng": -2.574
            },
            "Englefield Green": {
                "lat": 51.4301,
                "lng": -0.5699
            },
            "Kingsteignton": {
                "lat": 50.5458,
                "lng": -3.5962
            },
            "Abergele": {
                "lat": 53.28,
                "lng": -3.58
            },
            "Monmouth": {
                "lat": 51.81,
                "lng": -2.72
            },
            "Whitnash": {
                "lat": 52.268,
                "lng": -1.524
            },
            "Ryhope": {
                "lat": 54.8679,
                "lng": -1.3698
            },
            "Attleborough": {
                "lat": 52.5183,
                "lng": 1.0192
            },
            "Holbeach": {
                "lat": 52.8037,
                "lng": 0.0154
            },
            "Launceston": {
                "lat": 50.637,
                "lng": -4.36
            },
            "Burton Latimer": {
                "lat": 52.365,
                "lng": -0.678
            },
            "Eccleston": {
                "lat": 53.4539,
                "lng": -2.7748
            },
            "Prestonpans": {
                "lat": 55.9597,
                "lng": -2.961
            },
            "Scartho": {
                "lat": 53.5399,
                "lng": -0.0926
            },
            "Bradford-on-Avon": {
                "lat": 51.347,
                "lng": -2.251
            },
            "Northfield": {
                "lat": 52.408,
                "lng": -1.963
            },
            "Great Dunmow": {
                "lat": 51.871,
                "lng": 0.364
            },
            "Charlton Kings": {
                "lat": 51.8877,
                "lng": -2.0413
            },
            "Ickenham": {
                "lat": 51.558,
                "lng": -0.4484
            },
            "Blandford Forum": {
                "lat": 50.856,
                "lng": -2.165
            },
            "Gourock": {
                "lat": 55.9538,
                "lng": -4.8173
            },
            "Teddington": {
                "lat": 51.424,
                "lng": -0.332
            },
            "Stranraer": {
                "lat": 54.902,
                "lng": -5.027
            },
            "Mildenhall": {
                "lat": 52.3446,
                "lng": 0.5089
            },
            "Broadstone": {
                "lat": 50.7605,
                "lng": -1.995
            },
            "Spitalfields": {
                "lat": 51.5166,
                "lng": -0.075
            },
            "Cheam": {
                "lat": 51.36,
                "lng": -0.21
            },
            "Ludlow": {
                "lat": 52.368,
                "lng": -2.718
            },
            "Appleton": {
                "lat": 53.3508,
                "lng": -2.5441
            },
            "Lemington": {
                "lat": 54.972,
                "lng": -1.723
            },
            "Snodland": {
                "lat": 51.328,
                "lng": 0.4467
            },
            "Ellon": {
                "lat": 57.366,
                "lng": -2.086
            },
            "New Silksworth": {
                "lat": 54.871,
                "lng": -1.3957
            },
            "Fort William": {
                "lat": 56.8198,
                "lng": -5.1052
            },
            "Castleton": {
                "lat": 53.5907,
                "lng": -2.1737
            },
            "Rogerstone": {
                "lat": 51.5906,
                "lng": -3.0537
            },
            "Great Missenden": {
                "lat": 51.7042,
                "lng": -0.7078
            },
            "Corfe Mullen": {
                "lat": 50.7701,
                "lng": -2.0175
            },
            "Pocklington": {
                "lat": 53.9309,
                "lng": -0.7781
            },
            "Beccles": {
                "lat": 52.458,
                "lng": 1.563
            },
            "Forres": {
                "lat": 57.608,
                "lng": -3.62
            },
            "Padiham": {
                "lat": 53.797,
                "lng": -2.311
            },
            "Worsley": {
                "lat": 53.5093,
                "lng": -2.3845
            },
            "Warfield": {
                "lat": 51.442,
                "lng": -0.737
            },
            "Bingham": {
                "lat": 52.952,
                "lng": -0.953
            },
            "Kilsyth": {
                "lat": 55.98,
                "lng": -4.06
            },
            "Abergavenny": {
                "lat": 51.824,
                "lng": -3.0167
            },
            "Radlett": {
                "lat": 51.685,
                "lng": -0.318
            },
            "Mold": {
                "lat": 53.166,
                "lng": -3.133
            },
            "Vange": {
                "lat": 51.55,
                "lng": 0.4667
            },
            "Chapeltown": {
                "lat": 53.462,
                "lng": -1.466
            },
            "Lydney": {
                "lat": 51.7286,
                "lng": -2.5285
            },
            "Southwater": {
                "lat": 51.0238,
                "lng": -0.3526
            },
            "Horbury": {
                "lat": 53.6595,
                "lng": -1.5324
            },
            "Downham Market": {
                "lat": 52.6,
                "lng": 0.39
            },
            "Euxton": {
                "lat": 53.662,
                "lng": -2.674
            },
            "Marton": {
                "lat": 54.5372,
                "lng": -1.2047
            },
            "Shildon": {
                "lat": 54.63,
                "lng": -1.65
            },
            "Bishopstoke": {
                "lat": 50.9679,
                "lng": -1.3278
            },
            "Aberaman": {
                "lat": 51.7,
                "lng": -3.4333
            },
            "Birchington": {
                "lat": 51.377,
                "lng": 1.305
            },
            "Nairn": {
                "lat": 57.586,
                "lng": -3.869
            },
            "Handsworth": {
                "lat": 53.37,
                "lng": -1.4
            },
            "Aldenham": {
                "lat": 51.6723,
                "lng": -0.3546
            },
            "Burscough": {
                "lat": 53.596,
                "lng": -2.843
            },
            "Balderton": {
                "lat": 53.0549,
                "lng": -0.7727
            },
            "Liskeard": {
                "lat": 50.4536,
                "lng": -4.4651
            },
            "Baldock": {
                "lat": 51.99,
                "lng": -0.19
            },
            "Worth": {
                "lat": 51.113,
                "lng": -0.147
            },
            "Stocksbridge": {
                "lat": 53.478,
                "lng": -1.588
            },
            "Abram": {
                "lat": 53.508,
                "lng": -2.588
            },
            "Caldicot": {
                "lat": 51.591,
                "lng": -2.7492
            },
            "Choppington": {
                "lat": 55.145,
                "lng": -1.601
            },
            "Kippax": {
                "lat": 53.7669,
                "lng": -1.3705
            },
            "Tottington": {
                "lat": 53.613,
                "lng": -2.339
            },
            "Helston": {
                "lat": 50.1,
                "lng": -5.27
            },
            "Dodworth": {
                "lat": 53.5417,
                "lng": -1.5214
            },
            "Shepperton": {
                "lat": 51.39,
                "lng": -0.46
            },
            "Pembroke Dock": {
                "lat": 51.693,
                "lng": -4.946
            },
            "Felpham": {
                "lat": 50.7905,
                "lng": -0.6539
            },
            "Cobham": {
                "lat": 51.329,
                "lng": -0.409
            },
            "Raynes Park": {
                "lat": 51.4033,
                "lng": -0.2321
            },
            "Rhosllanerchrugog": {
                "lat": 53.011,
                "lng": -3.052
            },
            "Lambeth": {
                "lat": 51.49,
                "lng": -0.119
            },
            "Bradwell": {
                "lat": 52.05,
                "lng": -0.787
            },
            "Glenfield": {
                "lat": 52.6491,
                "lng": -1.2062
            },
            "Immingham": {
                "lat": 53.6139,
                "lng": -0.2183
            },
            "Hockley": {
                "lat": 51.6014,
                "lng": 0.6363
            },
            "Caernarfon": {
                "lat": 53.14,
                "lng": -4.27
            },
            "Swanage": {
                "lat": 50.608,
                "lng": -1.96
            },
            "Yarm": {
                "lat": 54.5,
                "lng": -1.35
            },
            "Boxley": {
                "lat": 51.3024,
                "lng": 0.5429
            },
            "Newburn": {
                "lat": 54.983,
                "lng": -1.743
            },
            "Tadworth": {
                "lat": 51.294,
                "lng": -0.242
            },
            "Shevington": {
                "lat": 53.572,
                "lng": -2.69
            },
            "Worsborough": {
                "lat": 53.52,
                "lng": -1.47
            },
            "London Colney": {
                "lat": 51.726,
                "lng": -0.3
            },
            "Emsworth": {
                "lat": 50.849,
                "lng": -0.938
            },
            "Llantwit Major": {
                "lat": 51.4062,
                "lng": -3.475
            },
            "Bewdley": {
                "lat": 52.3758,
                "lng": -2.315
            },
            "South Normanton": {
                "lat": 53.107,
                "lng": -1.343
            },
            "Killamarsh": {
                "lat": 53.3205,
                "lng": -1.3116
            },
            "Brockworth": {
                "lat": 51.85,
                "lng": -2.15
            },
            "Radstock": {
                "lat": 51.293,
                "lng": -2.448
            },
            "Dunblane": {
                "lat": 56.1838,
                "lng": -3.9674
            },
            "Thames Ditton": {
                "lat": 51.389,
                "lng": -0.331
            },
            "Hayle": {
                "lat": 50.186,
                "lng": -5.419
            },
            "Horsell": {
                "lat": 51.3286,
                "lng": -0.5617
            },
            "Raunds": {
                "lat": 52.345,
                "lng": -0.537
            },
            "Broadwater": {
                "lat": 50.8282,
                "lng": -0.3742
            },
            "Wilnecote": {
                "lat": 52.6081,
                "lng": -1.6677
            },
            "Lutterworth": {
                "lat": 52.456,
                "lng": -1.2
            },
            "Queensferry": {
                "lat": 55.99,
                "lng": -3.398
            },
            "Sherborne": {
                "lat": 50.9469,
                "lng": -2.5171
            },
            "Irthlingborough": {
                "lat": 52.324,
                "lng": -0.614
            },
            "Frodsham": {
                "lat": 53.2947,
                "lng": -2.7289
            },
            "Polegate": {
                "lat": 50.8216,
                "lng": 0.2442
            },
            "Ledbury": {
                "lat": 52.0339,
                "lng": -2.4235
            },
            "Coleford": {
                "lat": 51.791,
                "lng": -2.6162
            },
            "Crigglestone": {
                "lat": 53.644,
                "lng": -1.5225
            },
            "Towcester": {
                "lat": 52.13,
                "lng": -0.99
            },
            "Stevenston": {
                "lat": 55.645,
                "lng": -4.758
            },
            "Bowdon": {
                "lat": 53.376,
                "lng": -2.366
            },
            "Clay Cross": {
                "lat": 53.1637,
                "lng": -1.4128
            },
            "Bude": {
                "lat": 50.824,
                "lng": -4.542
            },
            "Totnes": {
                "lat": 50.432,
                "lng": -3.684
            },
            "Cullercoats": {
                "lat": 55.033,
                "lng": -1.433
            },
            "Tilehurst": {
                "lat": 51.4579,
                "lng": -1.0406
            },
            "Aberbargoed": {
                "lat": 51.6968,
                "lng": -3.224
            },
            "Tiptree": {
                "lat": 51.81,
                "lng": 0.75
            },
            "Yatton": {
                "lat": 51.3855,
                "lng": -2.8256
            },
            "Yaxley": {
                "lat": 52.52,
                "lng": -0.26
            },
            "Littleport": {
                "lat": 52.4568,
                "lng": 0.3046
            },
            "Pencoed": {
                "lat": 51.5228,
                "lng": -3.5047
            },
            "Brandon": {
                "lat": 52.4474,
                "lng": 0.6242
            },
            "Haddington": {
                "lat": 55.956,
                "lng": -2.781
            },
            "Shanklin": {
                "lat": 50.6333,
                "lng": -1.175
            },
            "Usworth": {
                "lat": 54.94,
                "lng": -1.55
            },
            "Portlethen": {
                "lat": 57.061,
                "lng": -2.13
            },
            "Dinnington": {
                "lat": 53.3667,
                "lng": -1.2
            },
            "Pontardulais": {
                "lat": 51.71,
                "lng": -4.04
            },
            "Bonhill": {
                "lat": 55.983,
                "lng": -4.567
            },
            "Lanark": {
                "lat": 55.6749,
                "lng": -3.777
            },
            "Rye": {
                "lat": 50.95,
                "lng": 0.73
            },
            "Leven": {
                "lat": 56.195,
                "lng": -2.9942
            },
            "Dunbar": {
                "lat": 56.0027,
                "lng": -2.5169
            },
            "Barwell": {
                "lat": 52.5682,
                "lng": -1.3462
            },
            "Blairgowrie": {
                "lat": 56.5916,
                "lng": -3.3405
            },
            "Cupar": {
                "lat": 56.32,
                "lng": -3.01
            },
            "Bovingdon": {
                "lat": 51.7231,
                "lng": -0.5367
            },
            "Denbigh": {
                "lat": 53.1854,
                "lng": -3.4178
            },
            "Sileby": {
                "lat": 52.731,
                "lng": -1.106
            },
            "Ferryhill": {
                "lat": 54.69,
                "lng": -1.55
            },
            "Peebles": {
                "lat": 55.6519,
                "lng": -3.1889
            },
            "Broughton Astley": {
                "lat": 52.5278,
                "lng": -1.2275
            },
            "Glastonbury": {
                "lat": 51.1485,
                "lng": -2.714
            },
            "Felling": {
                "lat": 54.95,
                "lng": -1.564
            },
            "Great Cornard": {
                "lat": 52.0245,
                "lng": 0.7497
            },
            "Caister-on-Sea": {
                "lat": 52.651,
                "lng": 1.733
            },
            "Sandiacre": {
                "lat": 52.923,
                "lng": -1.289
            },
            "Holywell": {
                "lat": 53.274,
                "lng": -3.223
            },
            "Partick": {
                "lat": 55.8699,
                "lng": -4.3125
            },
            "Ifield": {
                "lat": 51.1234,
                "lng": -0.2073
            },
            "Rhymney": {
                "lat": 51.759,
                "lng": -3.283
            },
            "Cumnock": {
                "lat": 55.4529,
                "lng": -4.2638
            },
            "Hucclecote": {
                "lat": 51.85,
                "lng": -2.18
            },
            "Brimington": {
                "lat": 53.258,
                "lng": -1.3905
            },
            "Cherry Hinton": {
                "lat": 52.1849,
                "lng": 0.176
            },
            "Annan": {
                "lat": 54.983,
                "lng": -3.266
            },
            "Cinderford": {
                "lat": 51.8225,
                "lng": -2.4989
            },
            "Cockermouth": {
                "lat": 54.6613,
                "lng": -3.362
            },
            "Binfield": {
                "lat": 51.432,
                "lng": -0.792
            },
            "Atherstone": {
                "lat": 52.5787,
                "lng": -1.5462
            },
            "Buckie": {
                "lat": 57.6764,
                "lng": -2.965
            },
            "Chapel en le Frith": {
                "lat": 53.322,
                "lng": -1.917
            },
            "Stansted Mountfitchet": {
                "lat": 51.898,
                "lng": 0.198
            },
            "Canford Cliffs": {
                "lat": 50.7,
                "lng": -1.93
            },
            "Whitwick": {
                "lat": 52.7403,
                "lng": -1.3556
            },
            "Ammanford": {
                "lat": 51.8,
                "lng": -3.993
            },
            "Waltham Cross": {
                "lat": 51.686,
                "lng": -0.0331
            },
            "Bucksburn": {
                "lat": 57.177,
                "lng": -2.175
            },
            "Kirk of Shotts": {
                "lat": 55.823,
                "lng": -3.804
            },
            "Sompting": {
                "lat": 50.8303,
                "lng": -0.3395
            },
            "East Leake": {
                "lat": 52.832,
                "lng": -1.177
            },
            "Meltham": {
                "lat": 53.592,
                "lng": -1.85
            },
            "Worplesdon": {
                "lat": 51.272,
                "lng": -0.612
            },
            "Penkridge": {
                "lat": 52.7252,
                "lng": -2.1164
            },
            "Clayton le Moors": {
                "lat": 53.775,
                "lng": -2.384
            },
            "Cam": {
                "lat": 51.7011,
                "lng": -2.3642
            },
            "Oakengates": {
                "lat": 52.695,
                "lng": -2.451
            },
            "Bedwas": {
                "lat": 51.5926,
                "lng": -3.2061
            },
            "West Auckland": {
                "lat": 54.6318,
                "lng": -1.721
            },
            "Carlton Colville": {
                "lat": 52.454,
                "lng": 1.691
            },
            "Kirkwall": {
                "lat": 58.981,
                "lng": -2.96
            },
            "Wick": {
                "lat": 58.454,
                "lng": -3.089
            },
            "Lerwick": {
                "lat": 60.155,
                "lng": -1.145
            }
        }
    },
    "FR": {
        "name": "France",
        "cities": {
            "Paris": {
                "lat": 48.8567,
                "lng": 2.3522
            },
            "Bordeaux": {
                "lat": 44.84,
                "lng": -0.58
            },
            "Marseille": {
                "lat": 43.2964,
                "lng": 5.37
            },
            "Lyon": {
                "lat": 45.76,
                "lng": 4.84
            },
            "Toulouse": {
                "lat": 43.6045,
                "lng": 1.444
            },
            "Nice": {
                "lat": 43.7034,
                "lng": 7.2663
            },
            "Nantes": {
                "lat": 47.2181,
                "lng": -1.5528
            },
            "Montpellier": {
                "lat": 43.6119,
                "lng": 3.8772
            },
            "Strasbourg": {
                "lat": 48.5833,
                "lng": 7.7458
            },
            "Lille": {
                "lat": 50.6278,
                "lng": 3.0583
            },
            "Rennes": {
                "lat": 48.1147,
                "lng": -1.6794
            },
            "Toulon": {
                "lat": 43.1258,
                "lng": 5.9306
            },
            "Reims": {
                "lat": 49.2628,
                "lng": 4.0347
            },
            "Saint-\u00c9tienne": {
                "lat": 45.4347,
                "lng": 4.3903
            },
            "Le Havre": {
                "lat": 49.49,
                "lng": 0.1
            },
            "Dijon": {
                "lat": 47.3167,
                "lng": 5.0167
            },
            "Grenoble": {
                "lat": 45.1715,
                "lng": 5.7224
            },
            "Angers": {
                "lat": 47.4736,
                "lng": -0.5542
            },
            "Villeurbanne": {
                "lat": 45.7667,
                "lng": 4.8803
            },
            "N\u00eemes": {
                "lat": 43.8383,
                "lng": 4.3597
            },
            "Aix-en-Provence": {
                "lat": 43.5263,
                "lng": 5.4454
            },
            "Clermont-Ferrand": {
                "lat": 45.7831,
                "lng": 3.0824
            },
            "Le Mans": {
                "lat": 48.0077,
                "lng": 0.1984
            },
            "Brest": {
                "lat": 48.39,
                "lng": -4.49
            },
            "Tours": {
                "lat": 47.3936,
                "lng": 0.6892
            },
            "Amiens": {
                "lat": 49.892,
                "lng": 2.299
            },
            "Annecy": {
                "lat": 45.916,
                "lng": 6.133
            },
            "Limoges": {
                "lat": 45.8353,
                "lng": 1.2625
            },
            "Metz": {
                "lat": 49.1203,
                "lng": 6.1778
            },
            "Boulogne-Billancourt": {
                "lat": 48.8352,
                "lng": 2.2409
            },
            "Perpignan": {
                "lat": 42.6986,
                "lng": 2.8956
            },
            "Besan\u00e7on": {
                "lat": 47.24,
                "lng": 6.02
            },
            "Orl\u00e9ans": {
                "lat": 47.9025,
                "lng": 1.909
            },
            "Rouen": {
                "lat": 49.4428,
                "lng": 1.0886
            },
            "Saint-Denis": {
                "lat": 48.9356,
                "lng": 2.3539
            },
            "Montreuil": {
                "lat": 48.8611,
                "lng": 2.4436
            },
            "Caen": {
                "lat": 49.1814,
                "lng": -0.3636
            },
            "Argenteuil": {
                "lat": 48.95,
                "lng": 2.25
            },
            "Mulhouse": {
                "lat": 47.75,
                "lng": 7.34
            },
            "Nancy": {
                "lat": 48.6936,
                "lng": 6.1846
            },
            "Tourcoing": {
                "lat": 50.7239,
                "lng": 3.1612
            },
            "Roubaix": {
                "lat": 50.6901,
                "lng": 3.1817
            },
            "Nanterre": {
                "lat": 48.8988,
                "lng": 2.1969
            },
            "Vitry-sur-Seine": {
                "lat": 48.7875,
                "lng": 2.3928
            },
            "Cr\u00e9teil": {
                "lat": 48.7911,
                "lng": 2.4628
            },
            "Avignon": {
                "lat": 43.95,
                "lng": 4.8075
            },
            "Poitiers": {
                "lat": 46.58,
                "lng": 0.34
            },
            "Aubervilliers": {
                "lat": 48.9131,
                "lng": 2.3831
            },
            "Colombes": {
                "lat": 48.9236,
                "lng": 2.2522
            },
            "Dunkerque": {
                "lat": 51.0383,
                "lng": 2.3775
            },
            "Aulnay-sous-Bois": {
                "lat": 48.9386,
                "lng": 2.4906
            },
            "Versailles": {
                "lat": 48.8053,
                "lng": 2.135
            },
            "Courbevoic": {
                "lat": 48.8978,
                "lng": 2.2531
            },
            "B\u00e9ziers": {
                "lat": 43.3476,
                "lng": 3.219
            },
            "La Rochelle": {
                "lat": 46.16,
                "lng": -1.15
            },
            "Rueil-Malmaison": {
                "lat": 48.876,
                "lng": 2.181
            },
            "Champigny-sur-Marne": {
                "lat": 48.8172,
                "lng": 2.5156
            },
            "Pau": {
                "lat": 43.3,
                "lng": -0.37
            },
            "M\u00e9rignac": {
                "lat": 44.8386,
                "lng": -0.6436
            },
            "Saint-Maur-des-Foss\u00e9s": {
                "lat": 48.7994,
                "lng": 2.4997
            },
            "Antibes": {
                "lat": 43.5808,
                "lng": 7.1239
            },
            "Ajaccio": {
                "lat": 41.9267,
                "lng": 8.7369
            },
            "Cannes": {
                "lat": 43.5513,
                "lng": 7.0128
            },
            "Saint-Nazaire": {
                "lat": 47.2736,
                "lng": -2.2139
            },
            "Drancy": {
                "lat": 48.93,
                "lng": 2.45
            },
            "Noisy-le-Grand": {
                "lat": 48.8478,
                "lng": 2.5528
            },
            "Issy-les-Moulineaux": {
                "lat": 48.8239,
                "lng": 2.27
            },
            "Cergy": {
                "lat": 49.0361,
                "lng": 2.0631
            },
            "Levallois-Perret": {
                "lat": 48.895,
                "lng": 2.2872
            },
            "Colmar": {
                "lat": 48.0817,
                "lng": 7.3556
            },
            "Calais": {
                "lat": 50.9481,
                "lng": 1.8564
            },
            "Pessac": {
                "lat": 44.8067,
                "lng": -0.6311
            },
            "V\u00e9nissieux": {
                "lat": 45.6969,
                "lng": 4.8858
            },
            "Clichy": {
                "lat": 48.9044,
                "lng": 2.3064
            },
            "Valence": {
                "lat": 44.9333,
                "lng": 4.8917
            },
            "Ivry-sur-Seine": {
                "lat": 48.8078,
                "lng": 2.3747
            },
            "Bourges": {
                "lat": 47.0844,
                "lng": 2.3964
            },
            "Quimper": {
                "lat": 47.9967,
                "lng": -4.0964
            },
            "Antony": {
                "lat": 48.7539,
                "lng": 2.2975
            },
            "Troyes": {
                "lat": 48.2997,
                "lng": 4.0792
            },
            "La Seyne-sur-Mer": {
                "lat": 43.1,
                "lng": 5.883
            },
            "Montauban": {
                "lat": 44.0181,
                "lng": 1.3558
            },
            "Pantin": {
                "lat": 48.8966,
                "lng": 2.4017
            },
            "Chamb\u00e9ry": {
                "lat": 45.57,
                "lng": 5.9118
            },
            "Niort": {
                "lat": 46.3258,
                "lng": -0.4606
            },
            "Neuilly-sur-Seine": {
                "lat": 48.8881,
                "lng": 2.2686
            },
            "Sarcelles": {
                "lat": 48.9956,
                "lng": 2.3808
            },
            "Le Blanc-Mesnil": {
                "lat": 48.9387,
                "lng": 2.4614
            },
            "Maisons-Alfort": {
                "lat": 48.8058,
                "lng": 2.4378
            },
            "Lorient": {
                "lat": 47.75,
                "lng": -3.36
            },
            "Villejuif": {
                "lat": 48.7919,
                "lng": 2.3636
            },
            "Bellevue": {
                "lat": 48.871,
                "lng": 2.385
            },
            "Fr\u00e9jus": {
                "lat": 43.433,
                "lng": 6.737
            },
            "Beauvais": {
                "lat": 49.4303,
                "lng": 2.0952
            },
            "Narbonne": {
                "lat": 43.1836,
                "lng": 3.0042
            },
            "Meaux": {
                "lat": 48.9603,
                "lng": 2.8883
            },
            "Hy\u00e8res": {
                "lat": 43.1199,
                "lng": 6.1316
            },
            "Bobigny": {
                "lat": 48.9106,
                "lng": 2.4397
            },
            "La Roche-sur-Yon": {
                "lat": 46.6705,
                "lng": -1.426
            },
            "Clamart": {
                "lat": 48.8014,
                "lng": 2.2628
            },
            "Vannes": {
                "lat": 47.6559,
                "lng": -2.7603
            },
            "Chelles": {
                "lat": 48.8833,
                "lng": 2.6
            },
            "Cholet": {
                "lat": 47.06,
                "lng": -0.8783
            },
            "\u00c9vry": {
                "lat": 48.6238,
                "lng": 2.4296
            },
            "\u00c9pinay-sur-Seine": {
                "lat": 48.9553,
                "lng": 2.3092
            },
            "Saint-Ouen": {
                "lat": 48.9123,
                "lng": 2.3342
            },
            "Saint-Quentin": {
                "lat": 49.8486,
                "lng": 3.2864
            },
            "Bondy": {
                "lat": 48.9022,
                "lng": 2.4828
            },
            "Bayonne": {
                "lat": 43.49,
                "lng": -1.48
            },
            "Corbeil-Essonnes": {
                "lat": 48.6139,
                "lng": 2.482
            },
            "Cagnes-sur-Mer": {
                "lat": 43.6644,
                "lng": 7.1489
            },
            "Vaulx-en-Velin": {
                "lat": 45.7768,
                "lng": 4.9186
            },
            "Sevran": {
                "lat": 48.9333,
                "lng": 2.5333
            },
            "Fontenay-sous-Bois": {
                "lat": 48.8517,
                "lng": 2.4772
            },
            "Sartrouville": {
                "lat": 48.9372,
                "lng": 2.1644
            },
            "Massy": {
                "lat": 48.7309,
                "lng": 2.2713
            },
            "Arles": {
                "lat": 43.6767,
                "lng": 4.6278
            },
            "Albi": {
                "lat": 43.9289,
                "lng": 2.1464
            },
            "Laval": {
                "lat": 48.0733,
                "lng": -0.7689
            },
            "Saint-Herblain": {
                "lat": 47.2122,
                "lng": -1.6497
            },
            "Gennevilliers": {
                "lat": 48.9333,
                "lng": 2.3
            },
            "Suresnes": {
                "lat": 48.87,
                "lng": 2.22
            },
            "Saint-Priest": {
                "lat": 45.6964,
                "lng": 4.9439
            },
            "Vincennes": {
                "lat": 48.8478,
                "lng": 2.4392
            },
            "Bastia": {
                "lat": 42.7008,
                "lng": 9.4503
            },
            "Martigues": {
                "lat": 43.4053,
                "lng": 5.0475
            },
            "Grasse": {
                "lat": 43.6667,
                "lng": 6.9167
            },
            "Montrouge": {
                "lat": 48.8172,
                "lng": 2.3219
            },
            "Aubagne": {
                "lat": 43.2908,
                "lng": 5.5708
            },
            "Saint-Malo": {
                "lat": 48.65,
                "lng": -2.01
            },
            "\u00c9vreux": {
                "lat": 49.02,
                "lng": 1.15
            },
            "La Courneuve": {
                "lat": 48.9322,
                "lng": 2.3967
            },
            "Blois": {
                "lat": 47.5939,
                "lng": 1.3281
            },
            "Brive-la-Gaillarde": {
                "lat": 45.1583,
                "lng": 1.5321
            },
            "Charleville-M\u00e9zi\u00e8res": {
                "lat": 49.7719,
                "lng": 4.7161
            },
            "Meudon": {
                "lat": 48.8123,
                "lng": 2.2382
            },
            "Carcassonne": {
                "lat": 43.21,
                "lng": 2.35
            },
            "Choisy-le-Roi": {
                "lat": 48.763,
                "lng": 2.409
            },
            "Noisy-le-Sec": {
                "lat": 48.8894,
                "lng": 2.4503
            },
            "Livry-Gargan": {
                "lat": 48.9192,
                "lng": 2.5361
            },
            "Rosny-sous-Bois": {
                "lat": 48.8667,
                "lng": 2.4833
            },
            "Talence": {
                "lat": 44.8,
                "lng": -0.584
            },
            "Belfort": {
                "lat": 47.64,
                "lng": 6.85
            },
            "Alfortville": {
                "lat": 48.805,
                "lng": 2.4239
            },
            "Chalon-sur-Sa\u00f4ne": {
                "lat": 46.7806,
                "lng": 4.8528
            },
            "Salon-de-Provence": {
                "lat": 43.6406,
                "lng": 5.0972
            },
            "S\u00e8te": {
                "lat": 43.4053,
                "lng": 3.6975
            },
            "Istres": {
                "lat": 43.5151,
                "lng": 4.9895
            },
            "Mantes-la-Jolie": {
                "lat": 48.9908,
                "lng": 1.7172
            },
            "Saint-Brieuc": {
                "lat": 48.5136,
                "lng": -2.7653
            },
            "Tarbes": {
                "lat": 43.23,
                "lng": 0.07
            },
            "Al\u00e8s": {
                "lat": 44.1281,
                "lng": 4.0817
            },
            "Ch\u00e2lons-en-Champagne": {
                "lat": 48.9575,
                "lng": 4.365
            },
            "Bagneux": {
                "lat": 48.7983,
                "lng": 2.3137
            },
            "Puteaux": {
                "lat": 48.885,
                "lng": 2.2389
            },
            "Caluire-et-Cuire": {
                "lat": 45.7953,
                "lng": 4.8472
            },
            "Bron": {
                "lat": 45.7386,
                "lng": 4.9131
            },
            "Rez\u00e9": {
                "lat": 47.1917,
                "lng": -1.5694
            },
            "Valenciennes": {
                "lat": 50.3581,
                "lng": 3.5233
            },
            "Ch\u00e2teauroux": {
                "lat": 46.8103,
                "lng": 1.6911
            },
            "Garges-l\u00e8s-Gonesse": {
                "lat": 48.9728,
                "lng": 2.4008
            },
            "Castres": {
                "lat": 43.6,
                "lng": 2.25
            },
            "Arras": {
                "lat": 50.292,
                "lng": 2.78
            },
            "Melun": {
                "lat": 48.5406,
                "lng": 2.66
            },
            "Thionville": {
                "lat": 49.3589,
                "lng": 6.1692
            },
            "Le Cannet": {
                "lat": 43.5769,
                "lng": 7.0194
            },
            "Bourg-en-Bresse": {
                "lat": 46.2056,
                "lng": 5.2289
            },
            "Anglet": {
                "lat": 43.485,
                "lng": -1.5183
            },
            "Angoul\u00eame": {
                "lat": 45.65,
                "lng": 0.16
            },
            "Boulogne-sur-Mer": {
                "lat": 50.7264,
                "lng": 1.6147
            },
            "Wattrelos": {
                "lat": 50.7,
                "lng": 3.217
            },
            "Saint-Germain-en-Laye": {
                "lat": 48.8989,
                "lng": 2.0938
            },
            "Villenave-d\u2019Ornon": {
                "lat": 44.7806,
                "lng": -0.5658
            },
            "Gap": {
                "lat": 44.5594,
                "lng": 6.0786
            },
            "Mont\u00e9limar": {
                "lat": 44.5581,
                "lng": 4.7508
            },
            "Compi\u00e8gne": {
                "lat": 49.4149,
                "lng": 2.8231
            },
            "Stains": {
                "lat": 48.95,
                "lng": 2.3833
            },
            "Gagny": {
                "lat": 48.8833,
                "lng": 2.5333
            },
            "Colomiers": {
                "lat": 43.6139,
                "lng": 1.3367
            },
            "Poissy": {
                "lat": 48.9294,
                "lng": 2.0456
            },
            "Draguignan": {
                "lat": 43.5403,
                "lng": 6.4667
            },
            "Douai": {
                "lat": 50.3714,
                "lng": 3.08
            },
            "Bagnolet": {
                "lat": 48.8692,
                "lng": 2.4181
            },
            "Marcq-en-Baroeul": {
                "lat": 50.6711,
                "lng": 3.0972
            },
            "Villepinte": {
                "lat": 48.955,
                "lng": 2.541
            },
            "Saint-Martin-d\u2019H\u00e8res": {
                "lat": 45.1672,
                "lng": 5.7653
            },
            "Chartres": {
                "lat": 48.456,
                "lng": 1.484
            },
            "Jou\u00e9-l\u00e9s-Tours": {
                "lat": 47.3514,
                "lng": 0.6625
            },
            "Annemasse": {
                "lat": 46.1958,
                "lng": 6.2364
            },
            "Neuilly-sur-Marne": {
                "lat": 48.8537,
                "lng": 2.549
            },
            "Franconville": {
                "lat": 48.9889,
                "lng": 2.2314
            },
            "Savigny-sur-Orge": {
                "lat": 48.6797,
                "lng": 2.3457
            },
            "Thonon-les-Bains": {
                "lat": 46.3627,
                "lng": 6.475
            },
            "La Ciotat": {
                "lat": 43.1769,
                "lng": 5.6086
            },
            "\u00c9chirolles": {
                "lat": 45.1436,
                "lng": 5.7183
            },
            "Ch\u00e2tillon": {
                "lat": 48.8,
                "lng": 2.29
            },
            "Athis-Mons": {
                "lat": 48.7074,
                "lng": 2.3889
            },
            "Six-Fours-les-Plages": {
                "lat": 43.1009,
                "lng": 5.82
            },
            "Creil": {
                "lat": 49.2583,
                "lng": 2.4833
            },
            "Saint-Rapha\u00ebl": {
                "lat": 43.4252,
                "lng": 6.7684
            },
            "Conflans-Sainte-Honorine": {
                "lat": 48.9992,
                "lng": 2.0983
            },
            "Villefranche-sur-Sa\u00f4ne": {
                "lat": 45.9833,
                "lng": 4.7167
            },
            "Meyzieu": {
                "lat": 45.7667,
                "lng": 5.0036
            },
            "Sainte-Genevi\u00e8ve-des-Bois": {
                "lat": 48.6369,
                "lng": 2.3403
            },
            "Haguenau": {
                "lat": 48.82,
                "lng": 7.79
            },
            "Vitrolles": {
                "lat": 43.46,
                "lng": 5.2486
            },
            "Villeneuve-Saint-Georges": {
                "lat": 48.7325,
                "lng": 2.4497
            },
            "Saint-Chamond": {
                "lat": 45.4775,
                "lng": 4.5153
            },
            "Ch\u00e2tenay-Malabry": {
                "lat": 48.7653,
                "lng": 2.2781
            },
            "Palaiseau": {
                "lat": 48.7145,
                "lng": 2.2457
            },
            "Auxerre": {
                "lat": 47.7986,
                "lng": 3.5672
            },
            "Roanne": {
                "lat": 46.0367,
                "lng": 4.0689
            },
            "M\u00e2con": {
                "lat": 46.3063,
                "lng": 4.8313
            },
            "Le Perreux-Sur-Marne": {
                "lat": 48.8422,
                "lng": 2.5036
            },
            "Schiltigheim": {
                "lat": 48.6078,
                "lng": 7.75
            },
            "Les Mureaux": {
                "lat": 48.9875,
                "lng": 1.9172
            },
            "Trappes": {
                "lat": 48.7775,
                "lng": 2.0025
            },
            "Nogent-sur-Marne": {
                "lat": 48.8375,
                "lng": 2.4833
            },
            "Houilles": {
                "lat": 48.9261,
                "lng": 2.1892
            },
            "Montlu\u00e7on": {
                "lat": 46.3408,
                "lng": 2.6033
            },
            "Romainville": {
                "lat": 48.884,
                "lng": 2.435
            },
            "Marignane": {
                "lat": 43.416,
                "lng": 5.2145
            },
            "Romans-sur-Is\u00e8re": {
                "lat": 45.0464,
                "lng": 5.0517
            },
            "Nevers": {
                "lat": 46.9933,
                "lng": 3.1572
            },
            "Lens": {
                "lat": 50.4322,
                "lng": 2.8333
            },
            "Saint-M\u00e9dard-en-Jalles": {
                "lat": 44.8964,
                "lng": -0.7164
            },
            "Agen": {
                "lat": 44.2049,
                "lng": 0.6212
            },
            "Pierrefitte-sur-Seine": {
                "lat": 48.9656,
                "lng": 2.3614
            },
            "\u00c9pinal": {
                "lat": 48.1744,
                "lng": 6.4512
            },
            "Bezons": {
                "lat": 48.9261,
                "lng": 2.2178
            },
            "Aix-les-Bains": {
                "lat": 45.6886,
                "lng": 5.915
            },
            "Montigny-le-Bretonneux": {
                "lat": 48.7711,
                "lng": 2.0333
            },
            "Cambrai": {
                "lat": 50.1767,
                "lng": 3.2356
            },
            "L\u2019Ha\u00ff-les-Roses": {
                "lat": 48.78,
                "lng": 2.3374
            },
            "Plaisir": {
                "lat": 48.8183,
                "lng": 1.9472
            },
            "Pontoise": {
                "lat": 49.0516,
                "lng": 2.1017
            },
            "Ch\u00e2tellerault": {
                "lat": 46.8178,
                "lng": 0.5461
            },
            "Rillieux-la-Pape": {
                "lat": 45.8214,
                "lng": 4.8983
            },
            "Thiais": {
                "lat": 48.765,
                "lng": 2.3923
            },
            "Vienne": {
                "lat": 45.5242,
                "lng": 4.8781
            },
            "Vigneux-sur-Seine": {
                "lat": 48.7001,
                "lng": 2.417
            },
            "Viry-Ch\u00e2tillon": {
                "lat": 48.6713,
                "lng": 2.375
            },
            "Saint-Laurent-du-Var": {
                "lat": 43.668,
                "lng": 7.188
            },
            "Dreux": {
                "lat": 48.7372,
                "lng": 1.3664
            },
            "B\u00e8gles": {
                "lat": 44.8086,
                "lng": -0.5478
            },
            "Carpentras": {
                "lat": 44.0558,
                "lng": 5.0489
            },
            "Goussainville": {
                "lat": 49.0325,
                "lng": 2.4747
            },
            "Mont-de-Marsan": {
                "lat": 43.89,
                "lng": -0.5
            },
            "Villiers-sur-Marne": {
                "lat": 48.8275,
                "lng": 2.5447
            },
            "Cachan": {
                "lat": 48.7919,
                "lng": 2.3319
            },
            "Savigny-le-Temple": {
                "lat": 48.5841,
                "lng": 2.5832
            },
            "Menton": {
                "lat": 43.775,
                "lng": 7.5
            },
            "Villemomble": {
                "lat": 48.8833,
                "lng": 2.5
            },
            "Malakoff": {
                "lat": 48.8172,
                "lng": 2.2992
            },
            "Li\u00e9vin": {
                "lat": 50.4228,
                "lng": 2.7786
            },
            "La Garenne-Colombes": {
                "lat": 48.9056,
                "lng": 2.2445
            },
            "Ris-Orangis": {
                "lat": 48.6537,
                "lng": 2.4161
            },
            "Bois-Colombes": {
                "lat": 48.9175,
                "lng": 2.2683
            },
            "Clichy-sous-Bois": {
                "lat": 48.9102,
                "lng": 2.5532
            },
            "D\u00e9cines-Charpieu": {
                "lat": 45.7694,
                "lng": 4.9594
            },
            "Saint-Cloud": {
                "lat": 48.84,
                "lng": 2.22
            },
            "Chatou": {
                "lat": 48.8897,
                "lng": 2.1573
            },
            "Bourgoin-Jallieu": {
                "lat": 45.5861,
                "lng": 5.2736
            },
            "Vand\u0153uvre-l\u00e8s-Nancy": {
                "lat": 48.6567,
                "lng": 6.1683
            },
            "P\u00e9rigueux": {
                "lat": 45.1929,
                "lng": 0.7217
            },
            "Charenton-le-Pont": {
                "lat": 48.8265,
                "lng": 2.405
            },
            "Tournefeuille": {
                "lat": 43.5853,
                "lng": 1.3442
            },
            "Guyancourt": {
                "lat": 48.7714,
                "lng": 2.0739
            },
            "Le Plessis-Robinson": {
                "lat": 48.7811,
                "lng": 2.2633
            },
            "Draveil": {
                "lat": 48.6852,
                "lng": 2.408
            },
            "Agde": {
                "lat": 43.3108,
                "lng": 3.4758
            },
            "Maubeuge": {
                "lat": 50.2775,
                "lng": 3.9734
            },
            "Ermont": {
                "lat": 48.9922,
                "lng": 2.2603
            },
            "Sotteville-l\u00e8s-Rouen": {
                "lat": 49.4092,
                "lng": 1.09
            },
            "Orange": {
                "lat": 44.1383,
                "lng": 4.8097
            },
            "Villiers-le-Bel": {
                "lat": 49.0094,
                "lng": 2.3911
            },
            "Fresnes": {
                "lat": 48.755,
                "lng": 2.3221
            },
            "Soissons": {
                "lat": 49.3817,
                "lng": 3.3236
            },
            "Yerres": {
                "lat": 48.7171,
                "lng": 2.4881
            },
            "Saint-\u00c9tienne-du-Rouvray": {
                "lat": 49.3786,
                "lng": 1.105
            },
            "Dieppe": {
                "lat": 49.925,
                "lng": 1.075
            },
            "Saint-S\u00e9bastien-sur-Loire": {
                "lat": 47.2081,
                "lng": -1.5014
            },
            "Vallauris": {
                "lat": 43.5805,
                "lng": 7.0538
            },
            "Vanves": {
                "lat": 48.8208,
                "lng": 2.2897
            },
            "Lomme": {
                "lat": 50.6358,
                "lng": 3.0142
            },
            "Limeil-Br\u00e9vannes": {
                "lat": 48.7464,
                "lng": 2.4883
            },
            "Montfermeil": {
                "lat": 48.9,
                "lng": 2.5667
            },
            "Orvault": {
                "lat": 47.2717,
                "lng": -1.6225
            },
            "Le Chesnay": {
                "lat": 48.8211,
                "lng": 2.1311
            },
            "Sucy-en-Brie": {
                "lat": 48.7697,
                "lng": 2.5228
            },
            "Grigny": {
                "lat": 45.6092,
                "lng": 4.7906
            },
            "Lambersart": {
                "lat": 50.65,
                "lng": 3.025
            },
            "Illkirch-Graffenstaden": {
                "lat": 48.53,
                "lng": 7.72
            },
            "Br\u00e9tigny-sur-Orge": {
                "lat": 48.6114,
                "lng": 2.3059
            },
            "Sens": {
                "lat": 48.1972,
                "lng": 3.2833
            },
            "Taverny": {
                "lat": 49.0264,
                "lng": 2.2275
            },
            "Oullins": {
                "lat": 45.7142,
                "lng": 4.8075
            },
            "Villeparisis": {
                "lat": 48.9503,
                "lng": 2.6025
            },
            "Rambouillet": {
                "lat": 48.6444,
                "lng": 1.8308
            },
            "Cenon": {
                "lat": 44.8578,
                "lng": -0.5317
            },
            "Sannois": {
                "lat": 48.9722,
                "lng": 2.2578
            },
            "Cormeilles-en-Parisis": {
                "lat": 48.9739,
                "lng": 2.2014
            },
            "Bussy-Saint-Georges": {
                "lat": 48.8422,
                "lng": 2.6983
            },
            "La Teste-de-Buch": {
                "lat": 44.62,
                "lng": -1.1457
            },
            "\u00c9tampes": {
                "lat": 48.4343,
                "lng": 2.1615
            },
            "Blagnac": {
                "lat": 43.6364,
                "lng": 1.3906
            },
            "Miramas": {
                "lat": 43.5822,
                "lng": 5.0019
            },
            "Bergerac": {
                "lat": 44.85,
                "lng": 0.48
            },
            "Saumur": {
                "lat": 47.26,
                "lng": -0.0769
            },
            "Lunel": {
                "lat": 43.6778,
                "lng": 4.1361
            },
            "\u00c9lancourt": {
                "lat": 48.7847,
                "lng": 1.9589
            },
            "H\u00e9nin-Beaumont": {
                "lat": 50.4217,
                "lng": 2.9508
            },
            "Vertou": {
                "lat": 47.1689,
                "lng": -1.4697
            },
            "Le Grand-Quevilly": {
                "lat": 49.4072,
                "lng": 1.0531
            },
            "Gonesse": {
                "lat": 48.9875,
                "lng": 2.4494
            },
            "Cavaillon": {
                "lat": 43.8375,
                "lng": 5.0381
            },
            "La Garde": {
                "lat": 43.1256,
                "lng": 6.0108
            },
            "Gradignan": {
                "lat": 44.7725,
                "lng": -0.6156
            },
            "Aurillac": {
                "lat": 44.9261,
                "lng": 2.4406
            },
            "Vichy": {
                "lat": 46.1278,
                "lng": 3.4267
            },
            "Biarritz": {
                "lat": 43.48,
                "lng": -1.56
            },
            "Champs-Sur-Marne": {
                "lat": 48.8529,
                "lng": 2.6027
            },
            "Armenti\u00e8res": {
                "lat": 50.6881,
                "lng": 2.8811
            },
            "Montb\u00e9liard": {
                "lat": 47.51,
                "lng": 6.8
            },
            "Alen\u00e7on": {
                "lat": 48.4306,
                "lng": 0.0931
            },
            "Saintes": {
                "lat": 45.7464,
                "lng": -0.6333
            },
            "Brunoy": {
                "lat": 48.6979,
                "lng": 2.5044
            },
            "Eaubonne": {
                "lat": 48.9922,
                "lng": 2.2806
            },
            "Villeneuve-la-Garenne": {
                "lat": 48.9372,
                "lng": 2.3278
            },
            "Cherbourg": {
                "lat": 49.63,
                "lng": -1.62
            },
            "Vierzon": {
                "lat": 47.2225,
                "lng": 2.0694
            },
            "Muret": {
                "lat": 43.4611,
                "lng": 1.3267
            },
            "Saint-Ouen-l\u2019Aum\u00f4ne": {
                "lat": 49.0447,
                "lng": 2.1111
            },
            "B\u00e9thune": {
                "lat": 50.5303,
                "lng": 2.6408
            },
            "Castelnau-le-Lez": {
                "lat": 43.6369,
                "lng": 3.9019
            },
            "Fontenay-aux-Roses": {
                "lat": 48.7893,
                "lng": 2.2888
            },
            "Libourne": {
                "lat": 44.92,
                "lng": -0.24
            },
            "Vernon": {
                "lat": 49.09,
                "lng": 1.49
            },
            "Orly": {
                "lat": 48.7439,
                "lng": 2.3928
            },
            "Le Kremlin-Bic\u00eatre": {
                "lat": 48.81,
                "lng": 2.3581
            },
            "Eysines": {
                "lat": 44.8853,
                "lng": -0.65
            },
            "Le Bouscat": {
                "lat": 44.8651,
                "lng": -0.5996
            },
            "Rodez": {
                "lat": 44.3506,
                "lng": 2.575
            },
            "Les Pavillons-sous-Bois": {
                "lat": 48.9,
                "lng": 2.5
            },
            "Laon": {
                "lat": 49.5639,
                "lng": 3.6244
            },
            "La Valette-du-Var": {
                "lat": 43.1383,
                "lng": 5.9831
            },
            "Frontignan": {
                "lat": 43.4483,
                "lng": 3.7561
            },
            "Montgeron": {
                "lat": 48.7039,
                "lng": 2.4605
            },
            "Dole": {
                "lat": 47.0931,
                "lng": 5.4906
            },
            "Les Lilas": {
                "lat": 48.88,
                "lng": 2.42
            },
            "Lormont": {
                "lat": 44.8792,
                "lng": -0.5217
            },
            "Rochefort": {
                "lat": 45.9421,
                "lng": -0.9588
            },
            "Maisons-Laffitte": {
                "lat": 48.9469,
                "lng": 2.1456
            },
            "Saint-Dizier": {
                "lat": 48.6383,
                "lng": 4.9497
            },
            "Roissy-en-Brie": {
                "lat": 48.7906,
                "lng": 2.6519
            },
            "Cou\u00ebron": {
                "lat": 47.2156,
                "lng": -1.7228
            },
            "Auch": {
                "lat": 43.6465,
                "lng": 0.5855
            },
            "Lanester": {
                "lat": 47.7647,
                "lng": -3.3422
            },
            "Loos": {
                "lat": 50.6128,
                "lng": 3.0144
            },
            "Manosque": {
                "lat": 43.8342,
                "lng": 5.7839
            },
            "Fontaine": {
                "lat": 45.1939,
                "lng": 5.6856
            },
            "Olivet": {
                "lat": 47.8639,
                "lng": 1.9006
            },
            "Dammarie-l\u00e8-Lys": {
                "lat": 48.5177,
                "lng": 2.6402
            },
            "V\u00e9lizy-Villacoublay": {
                "lat": 48.7834,
                "lng": 2.1834
            },
            "Saint-Louis": {
                "lat": 47.59,
                "lng": 7.57
            },
            "Tassin-la-Demi-Lune": {
                "lat": 45.764,
                "lng": 4.78
            },
            "S\u00e8vres": {
                "lat": 48.8239,
                "lng": 2.2117
            },
            "Montigny-l\u00e8s-Cormeilles": {
                "lat": 48.9944,
                "lng": 2.1958
            },
            "Abbeville": {
                "lat": 50.1058,
                "lng": 1.8358
            },
            "Deuil-la-Barre": {
                "lat": 48.9767,
                "lng": 2.3272
            },
            "Challans": {
                "lat": 46.8467,
                "lng": -1.8781
            },
            "La Madeleine": {
                "lat": 50.6558,
                "lng": 3.071
            },
            "Torcy": {
                "lat": 48.8502,
                "lng": 2.6508
            },
            "Gujan-Mestras": {
                "lat": 44.6364,
                "lng": -1.0667
            },
            "Gif-sur-Yvette": {
                "lat": 48.7018,
                "lng": 2.1339
            },
            "Oyonnax": {
                "lat": 46.2561,
                "lng": 5.6556
            },
            "Montereau-faut-Yonne": {
                "lat": 48.3853,
                "lng": 2.9508
            },
            "Combs-la-Ville": {
                "lat": 48.67,
                "lng": 2.56
            },
            "H\u00e9rouville-Saint-Clair": {
                "lat": 49.2044,
                "lng": -0.3253
            },
            "Les Pennes-Mirabeau": {
                "lat": 43.4106,
                "lng": 5.3103
            },
            "\u00c9pernay": {
                "lat": 49.0403,
                "lng": 3.96
            },
            "Montmorency": {
                "lat": 48.9906,
                "lng": 2.3228
            },
            "Sainte-Foy-l\u00e8s-Lyon": {
                "lat": 45.7336,
                "lng": 4.8025
            },
            "Montigny-l\u00e8s-Metz": {
                "lat": 49.1006,
                "lng": 6.1539
            },
            "Le Petit-Quevilly": {
                "lat": 49.4311,
                "lng": 1.0539
            },
            "Millau": {
                "lat": 44.0986,
                "lng": 3.0783
            },
            "Saint-Jean-de-Braye": {
                "lat": 47.9128,
                "lng": 1.9719
            },
            "Chaumont": {
                "lat": 48.1117,
                "lng": 5.1389
            },
            "Villeneuve-sur-Lot": {
                "lat": 44.4081,
                "lng": 0.705
            },
            "Seynod": {
                "lat": 45.8897,
                "lng": 6.0967
            },
            "Mandelieu-la-Napoule": {
                "lat": 43.5464,
                "lng": 6.9381
            },
            "Arcueil": {
                "lat": 48.8075,
                "lng": 2.3361
            },
            "Hazebrouck": {
                "lat": 50.725,
                "lng": 2.5392
            },
            "Allauch": {
                "lat": 43.3369,
                "lng": 5.4828
            },
            "Mons-en-Baroeul": {
                "lat": 50.6369,
                "lng": 3.1103
            },
            "Fleury-les-Aubrais": {
                "lat": 47.9312,
                "lng": 1.921
            },
            "Saint-Michel-sur-Orge": {
                "lat": 48.6303,
                "lng": 2.3025
            },
            "Neuilly-Plaisance": {
                "lat": 48.8619,
                "lng": 2.5064
            },
            "Annecy-le-Vieux": {
                "lat": 45.92,
                "lng": 6.1428
            },
            "Nogent-sur-Oise": {
                "lat": 49.2756,
                "lng": 2.4683
            },
            "Mantes-la-Ville": {
                "lat": 48.975,
                "lng": 1.7117
            },
            "Ach\u00e8res": {
                "lat": 48.9602,
                "lng": 2.0684
            },
            "Dax": {
                "lat": 43.71,
                "lng": -1.05
            },
            "Saint-Mand\u00e9": {
                "lat": 48.8422,
                "lng": 2.4186
            },
            "Gardanne": {
                "lat": 43.4553,
                "lng": 5.476
            },
            "Forbach": {
                "lat": 49.19,
                "lng": 6.9
            },
            "Villeneuve-le-Roi": {
                "lat": 48.7333,
                "lng": 2.4167
            },
            "Saint-Cyr-l\u2019\u00c9cole": {
                "lat": 48.8008,
                "lng": 2.0633
            },
            "Saint-Genis-Laval": {
                "lat": 45.6953,
                "lng": 4.7931
            },
            "Voiron": {
                "lat": 45.3642,
                "lng": 5.5906
            },
            "Saint-Gratien": {
                "lat": 48.9719,
                "lng": 2.2828
            },
            "Wasquehal": {
                "lat": 50.6694,
                "lng": 3.1308
            },
            "Halluin": {
                "lat": 50.7836,
                "lng": 3.1256
            },
            "Bourg-la-Reine": {
                "lat": 48.7796,
                "lng": 2.3151
            },
            "Morsang-sur-Orge": {
                "lat": 48.6618,
                "lng": 2.3461
            },
            "Croix": {
                "lat": 50.6781,
                "lng": 3.1508
            },
            "Coudekerque-Branche": {
                "lat": 51.0253,
                "lng": 2.3917
            },
            "Le Creusot": {
                "lat": 46.8014,
                "lng": 4.4411
            },
            "Ozoir-la-Ferri\u00e8re": {
                "lat": 48.778,
                "lng": 2.68
            },
            "Givors": {
                "lat": 45.5914,
                "lng": 4.7694
            },
            "Foug\u00e8res": {
                "lat": 48.3525,
                "lng": -1.1986
            },
            "Denain": {
                "lat": 50.3294,
                "lng": 3.3958
            },
            "Mitry-Mory": {
                "lat": 48.9833,
                "lng": 2.6167
            },
            "Sarreguemines": {
                "lat": 49.11,
                "lng": 7.07
            },
            "Longjumeau": {
                "lat": 48.6943,
                "lng": 2.2958
            },
            "Concarneau": {
                "lat": 47.8761,
                "lng": -3.9178
            },
            "Carquefou": {
                "lat": 47.2975,
                "lng": -1.4914
            },
            "Sceaux": {
                "lat": 48.7786,
                "lng": 2.2906
            },
            "Saint-Pol-sur-Mer": {
                "lat": 51.0314,
                "lng": 2.3439
            },
            "La Celle-Saint-Cloud": {
                "lat": 48.8411,
                "lng": 2.1344
            },
            "Joinville-le-Pont": {
                "lat": 48.8214,
                "lng": 2.4728
            },
            "Bouguenais": {
                "lat": 47.18,
                "lng": -1.6236
            },
            "Lannion": {
                "lat": 48.7333,
                "lng": -3.4542
            },
            "Cugnaux": {
                "lat": 43.5386,
                "lng": 1.3444
            },
            "Grande-Synthe": {
                "lat": 51.0139,
                "lng": 2.3028
            },
            "La Chapelle-sur-Erdre": {
                "lat": 47.2997,
                "lng": -1.5517
            },
            "Chevilly-Larue": {
                "lat": 48.7663,
                "lng": 2.3533
            },
            "Lingolsheim": {
                "lat": 48.56,
                "lng": 7.68
            },
            "Le Plessis-Tr\u00e9vise": {
                "lat": 48.8111,
                "lng": 2.5717
            },
            "Bruges": {
                "lat": 44.8836,
                "lng": -0.6114
            },
            "Le M\u00e9e-sur-Seine": {
                "lat": 48.5333,
                "lng": 2.6289
            },
            "Cournon-d\u2019Auvergne": {
                "lat": 45.7411,
                "lng": 3.1964
            },
            "Cahors": {
                "lat": 44.4483,
                "lng": 1.4414
            },
            "Mont-Saint-Aignan": {
                "lat": 49.463,
                "lng": 1.0883
            },
            "Beaune": {
                "lat": 47.025,
                "lng": 4.8397
            },
            "Pertuis": {
                "lat": 43.695,
                "lng": 5.5036
            },
            "Chaville": {
                "lat": 48.8086,
                "lng": 2.1886
            },
            "L\u2019Isle-sur-la-Sorgue": {
                "lat": 43.9199,
                "lng": 5.0549
            },
            "Plaisance-du-Touch": {
                "lat": 43.5667,
                "lng": 1.2972
            },
            "Chilly-Mazarin": {
                "lat": 48.7025,
                "lng": 2.3125
            },
            "Bressuire": {
                "lat": 46.8408,
                "lng": -0.4872
            },
            "Albertville": {
                "lat": 45.6767,
                "lng": 6.3933
            },
            "Lisieux": {
                "lat": 49.15,
                "lng": 0.23
            },
            "Mougins": {
                "lat": 43.6008,
                "lng": 6.9956
            },
            "Bruz": {
                "lat": 48.0256,
                "lng": -1.7447
            },
            "Bourg-l\u00e8s-Valence": {
                "lat": 44.9486,
                "lng": 4.8961
            },
            "Ronchin": {
                "lat": 50.6053,
                "lng": 3.0886
            },
            "Vence": {
                "lat": 43.7236,
                "lng": 7.1142
            },
            "Saint-L\u00f4": {
                "lat": 49.12,
                "lng": -1.09
            },
            "Saint-Fons": {
                "lat": 45.7086,
                "lng": 4.8533
            },
            "Moulins": {
                "lat": 46.5653,
                "lng": 3.3333
            },
            "Saint-Di\u00e9-des-Vosges": {
                "lat": 48.28,
                "lng": 6.95
            },
            "S\u00e9lestat": {
                "lat": 48.2594,
                "lng": 7.4542
            },
            "Brie-Comte-Robert": {
                "lat": 48.6917,
                "lng": 2.6083
            },
            "La Crau": {
                "lat": 43.1506,
                "lng": 6.0747
            },
            "Sorgues": {
                "lat": 44.0092,
                "lng": 4.873
            },
            "Villefontaine": {
                "lat": 45.6142,
                "lng": 5.1494
            },
            "Gentilly": {
                "lat": 48.8133,
                "lng": 2.3444
            },
            "Royan": {
                "lat": 45.62,
                "lng": -1.03
            },
            "Vitr\u00e9": {
                "lat": 48.1242,
                "lng": -1.2081
            },
            "Bonneuil-sur-Marne": {
                "lat": 48.7742,
                "lng": 2.4875
            },
            "Riom": {
                "lat": 45.8944,
                "lng": 3.1133
            },
            "Hem": {
                "lat": 50.6558,
                "lng": 3.1886
            },
            "Le Puy-en-Velay": {
                "lat": 45.0444,
                "lng": 3.8847
            },
            "Maurepas": {
                "lat": 48.76,
                "lng": 1.95
            },
            "Ploemeur": {
                "lat": 47.7372,
                "lng": -3.4261
            },
            "Meylan": {
                "lat": 45.2092,
                "lng": 5.78
            },
            "\u00c9ragny": {
                "lat": 49.0181,
                "lng": 2.0922
            },
            "Cognac": {
                "lat": 45.6958,
                "lng": -0.3292
            },
            "Hellemmes-Lille": {
                "lat": 50.6167,
                "lng": 3.1167
            },
            "Juvisy-sur-Orge": {
                "lat": 48.6889,
                "lng": 2.3783
            },
            "Chennevi\u00e8res-sur-Marne": {
                "lat": 48.7983,
                "lng": 2.5339
            },
            "\u00c9cully": {
                "lat": 45.7753,
                "lng": 4.7783
            },
            "Louviers": {
                "lat": 49.22,
                "lng": 1.17
            },
            "Cran-G\u00e9vrier": {
                "lat": 45.9097,
                "lng": 6.1107
            },
            "Carri\u00e8res-sous-Poissy": {
                "lat": 48.9486,
                "lng": 2.0394
            },
            "Rosenda\u00ebl": {
                "lat": 51.0413,
                "lng": 2.4037
            },
            "Bagnols-sur-C\u00e8ze": {
                "lat": 44.1623,
                "lng": 4.6193
            },
            "Moissy-Cramayel": {
                "lat": 48.6269,
                "lng": 2.5931
            },
            "F\u00e2ches-Thumesnil": {
                "lat": 50.5994,
                "lng": 3.0744
            },
            "F\u00e9camp": {
                "lat": 49.76,
                "lng": 0.38
            },
            "Soisy-sous-Montmorency": {
                "lat": 48.9886,
                "lng": 2.3006
            },
            "Bischheim": {
                "lat": 48.6144,
                "lng": 7.7528
            },
            "Pornic": {
                "lat": 47.1167,
                "lng": -2.1
            },
            "Ch\u00e2teauneuf-les-Martigues": {
                "lat": 43.3838,
                "lng": 5.165
            },
            "Garches": {
                "lat": 48.8461,
                "lng": 2.1889
            },
            "Carvin": {
                "lat": 50.4939,
                "lng": 2.9589
            },
            "Pontarlier": {
                "lat": 46.9067,
                "lng": 6.3556
            },
            "Hendaye": {
                "lat": 43.36,
                "lng": -1.77
            },
            "Lun\u00e9ville": {
                "lat": 48.5894,
                "lng": 6.5017
            },
            "Floirac": {
                "lat": 44.8375,
                "lng": -0.5247
            },
            "Cesson-S\u00e9vign\u00e9": {
                "lat": 48.1217,
                "lng": -1.6025
            },
            "Avion": {
                "lat": 50.41,
                "lng": 2.8336
            },
            "Brignoles": {
                "lat": 43.4058,
                "lng": 6.0615
            },
            "Saint-Maximin-la-Sainte-Baume": {
                "lat": 43.4532,
                "lng": 5.8614
            },
            "Limay": {
                "lat": 48.9942,
                "lng": 1.7367
            },
            "Boissy-Saint-L\u00e9ger": {
                "lat": 48.7511,
                "lng": 2.5106
            },
            "Le Pontet": {
                "lat": 43.965,
                "lng": 4.8606
            },
            "Lattes": {
                "lat": 43.57,
                "lng": 3.9094
            },
            "Chamali\u00e8res": {
                "lat": 45.7744,
                "lng": 3.0678
            },
            "Yutz": {
                "lat": 49.3594,
                "lng": 6.1892
            },
            "Bry-sur-Marne": {
                "lat": 48.8411,
                "lng": 2.5222
            },
            "Balma": {
                "lat": 43.6111,
                "lng": 1.4994
            },
            "Osny": {
                "lat": 49.06,
                "lng": 2.0633
            },
            "Sanary-sur-Mer": {
                "lat": 43.118,
                "lng": 5.8014
            },
            "Marmande": {
                "lat": 44.5,
                "lng": 0.17
            },
            "Les Clayes-sous-Bois": {
                "lat": 48.8167,
                "lng": 1.9833
            },
            "L\u2019Isle-d\u2019Abeau": {
                "lat": 45.6233,
                "lng": 5.2305
            },
            "Digne-les-Bains": {
                "lat": 44.0933,
                "lng": 6.2364
            },
            "Jouy-le-Moutier": {
                "lat": 49.0117,
                "lng": 2.0397
            },
            "Cluses": {
                "lat": 46.0611,
                "lng": 6.5794
            },
            "Firminy": {
                "lat": 45.3889,
                "lng": 4.2878
            },
            "Sallanches": {
                "lat": 45.9372,
                "lng": 6.6328
            },
            "Lons-le-Saunier": {
                "lat": 46.6753,
                "lng": 5.5544
            },
            "Saint-Cyr-sur-Loire": {
                "lat": 47.399,
                "lng": 0.6673
            },
            "Saint-\u00c9gr\u00e8ve": {
                "lat": 45.2325,
                "lng": 5.6836
            },
            "Viroflay": {
                "lat": 48.8,
                "lng": 2.167
            },
            "Octeville": {
                "lat": 49.6269,
                "lng": -1.6431
            },
            "Annonay": {
                "lat": 45.2408,
                "lng": 4.6714
            },
            "Le Pr\u00e9-Saint-Gervais": {
                "lat": 48.8833,
                "lng": 2.4
            },
            "Montceau-les-Mines": {
                "lat": 46.6678,
                "lng": 4.3697
            },
            "Ambar\u00e8s-et-Lagrave": {
                "lat": 44.9258,
                "lng": -0.4853
            },
            "Cestas": {
                "lat": 44.7453,
                "lng": -0.6811
            },
            "Villeneuve-Loubet": {
                "lat": 43.6589,
                "lng": 7.1222
            },
            "Verdun": {
                "lat": 49.162,
                "lng": 5.3876
            },
            "Saran": {
                "lat": 47.9514,
                "lng": 1.8747
            },
            "Ch\u00e2teaurenard": {
                "lat": 43.8833,
                "lng": 4.8553
            },
            "Sedan": {
                "lat": 49.7034,
                "lng": 4.9388
            },
            "Mauguio": {
                "lat": 43.6169,
                "lng": 4.0083
            },
            "Saint-Jean-de-la-Ruelle": {
                "lat": 47.9139,
                "lng": 1.8745
            },
            "Marly-le-Roi": {
                "lat": 48.8675,
                "lng": 2.0947
            },
            "Escoublac": {
                "lat": 47.2867,
                "lng": -2.3908
            },
            "Les Herbiers": {
                "lat": 46.87,
                "lng": -1.01
            },
            "Pamiers": {
                "lat": 43.1175,
                "lng": 1.6117
            },
            "Landerneau": {
                "lat": 48.4517,
                "lng": -4.2481
            },
            "Gu\u00e9rande": {
                "lat": 47.32,
                "lng": -2.42
            },
            "Port-de-Bouc": {
                "lat": 43.4058,
                "lng": 4.9892
            },
            "Domont": {
                "lat": 49.0283,
                "lng": 2.3275
            },
            "Montbrison": {
                "lat": 45.6083,
                "lng": 4.0658
            },
            "Tourlaville": {
                "lat": 49.6417,
                "lng": -1.5775
            },
            "Vaur\u00e9al": {
                "lat": 49.0353,
                "lng": 2.0328
            },
            "Orsay": {
                "lat": 48.6993,
                "lng": 2.1875
            },
            "Blanquefort": {
                "lat": 44.9114,
                "lng": -0.6364
            },
            "Rumilly": {
                "lat": 45.8758,
                "lng": 5.9447
            },
            "Saint-Amand-les-Eaux": {
                "lat": 50.4486,
                "lng": 3.4281
            },
            "Saint-Leu-la-For\u00eat": {
                "lat": 49.0175,
                "lng": 2.2472
            },
            "Hayange": {
                "lat": 49.33,
                "lng": 6.0628
            },
            "Elbeuf": {
                "lat": 49.29,
                "lng": 1.01
            },
            "Fontainebleau": {
                "lat": 48.4097,
                "lng": 2.7025
            },
            "Verneuil-sur-Seine": {
                "lat": 48.9806,
                "lng": 1.9747
            },
            "Petite-Synthe": {
                "lat": 51.0194,
                "lng": 2.3467
            },
            "Saint-Pierre-des-Corps": {
                "lat": 47.3914,
                "lng": 0.7289
            },
            "Toul": {
                "lat": 48.675,
                "lng": 5.8917
            },
            "Saint-Julien-en-Genevois": {
                "lat": 46.1436,
                "lng": 6.0811
            },
            "Le Pecq": {
                "lat": 48.8969,
                "lng": 2.1069
            },
            "Vend\u00f4me": {
                "lat": 47.7936,
                "lng": 1.0664
            },
            "Hennebont": {
                "lat": 47.805,
                "lng": -3.2775
            },
            "Mennecy": {
                "lat": 48.5661,
                "lng": 2.4369
            },
            "Beaucaire": {
                "lat": 43.8081,
                "lng": 4.6442
            },
            "Gaillac": {
                "lat": 43.9014,
                "lng": 1.8992
            },
            "Le V\u00e9sinet": {
                "lat": 48.8923,
                "lng": 2.1331
            },
            "Sin-le-Noble": {
                "lat": 50.363,
                "lng": 3.113
            },
            "Luc\u00e9": {
                "lat": 48.4389,
                "lng": 1.4658
            },
            "Wattignies": {
                "lat": 50.5856,
                "lng": 3.0439
            },
            "Sainte-Luce-sur-Loire": {
                "lat": 47.2503,
                "lng": -1.4856
            },
            "Fos-sur-Mer": {
                "lat": 43.4403,
                "lng": 4.9486
            },
            "Noisiel": {
                "lat": 48.8547,
                "lng": 2.6289
            },
            "Guipavas": {
                "lat": 48.4342,
                "lng": -4.3994
            },
            "Montivilliers": {
                "lat": 49.5469,
                "lng": 0.1889
            },
            "Bois-d\u2019Arcy": {
                "lat": 48.8,
                "lng": 2.03
            },
            "Tarascon": {
                "lat": 43.8058,
                "lng": 4.6603
            },
            "Saint-Rambert": {
                "lat": 45.5003,
                "lng": 4.2406
            },
            "Tr\u00e9laz\u00e9": {
                "lat": 47.446,
                "lng": -0.465
            },
            "Wittenheim": {
                "lat": 47.8081,
                "lng": 7.3375
            },
            "Senlis": {
                "lat": 49.2081,
                "lng": 2.5875
            },
            "Coulommiers": {
                "lat": 48.8247,
                "lng": 3.1068
            },
            "Bouc-Bel-Air": {
                "lat": 43.4553,
                "lng": 5.415
            },
            "Rive-de-Gier": {
                "lat": 45.5303,
                "lng": 4.6178
            },
            "Saint-Brice-sous-For\u00eat": {
                "lat": 48.9992,
                "lng": 2.3578
            },
            "Francheville": {
                "lat": 45.7364,
                "lng": 4.7636
            },
            "Ch\u00e2teau-Thierry": {
                "lat": 49.04,
                "lng": 3.4
            },
            "Longwy": {
                "lat": 49.52,
                "lng": 5.7606
            },
            "Vesoul": {
                "lat": 47.6231,
                "lng": 6.1558
            },
            "Olonne-sur-Mer": {
                "lat": 46.5369,
                "lng": -1.7717
            },
            "Montargis": {
                "lat": 47.9978,
                "lng": 2.7333
            },
            "Saint-Avold": {
                "lat": 49.1044,
                "lng": 6.7067
            },
            "Carri\u00e8res-sur-Seine": {
                "lat": 48.9108,
                "lng": 2.2889
            },
            "Issoire": {
                "lat": 45.545,
                "lng": 3.2497
            },
            "Saint-Avertin": {
                "lat": 47.3675,
                "lng": 0.7278
            },
            "Saint-Servan-sur-Mer": {
                "lat": 48.6361,
                "lng": -2.0147
            },
            "Ramonville-Saint-Agne": {
                "lat": 43.5434,
                "lng": 1.4787
            },
            "Avrill\u00e9": {
                "lat": 47.5078,
                "lng": -0.5878
            },
            "La Fl\u00e8che": {
                "lat": 47.6959,
                "lng": -0.0747
            },
            "Castanet-Tolosan": {
                "lat": 43.5164,
                "lng": 1.4989
            },
            "Morlaix": {
                "lat": 48.5783,
                "lng": -3.8267
            },
            "Le Raincy": {
                "lat": 48.9,
                "lng": 2.5167
            },
            "Bailleul": {
                "lat": 50.7392,
                "lng": 2.7333
            },
            "Amb\u00e9rieu-en-Bugey": {
                "lat": 45.9631,
                "lng": 5.3541
            },
            "Le Bourget": {
                "lat": 48.9353,
                "lng": 2.4256
            },
            "Illzach": {
                "lat": 47.7831,
                "lng": 7.3486
            },
            "Fameck": {
                "lat": 49.3,
                "lng": 6.11
            },
            "Montmagny": {
                "lat": 48.9744,
                "lng": 2.3467
            },
            "Pontivy": {
                "lat": 48.0692,
                "lng": -2.9614
            },
            "Romilly-sur-Seine": {
                "lat": 48.5167,
                "lng": 3.7275
            },
            "Villers-l\u00e8s-Nancy": {
                "lat": 48.6731,
                "lng": 6.1547
            },
            "Haubourdin": {
                "lat": 50.6097,
                "lng": 2.9878
            },
            "Pl\u00e9rin": {
                "lat": 48.5353,
                "lng": -2.7694
            },
            "Dinan": {
                "lat": 48.4564,
                "lng": -2.0489
            },
            "Laxou": {
                "lat": 48.6864,
                "lng": 6.1528
            },
            "Bar-le-Duc": {
                "lat": 48.7719,
                "lng": 5.1603
            },
            "Saint-Omer": {
                "lat": 50.7461,
                "lng": 2.2617
            },
            "Lognes": {
                "lat": 48.836,
                "lng": 2.6278
            },
            "Saint-Maurice": {
                "lat": 48.8156,
                "lng": 2.4592
            },
            "Saint-Jean-de-Luz": {
                "lat": 43.39,
                "lng": -1.66
            },
            "Verri\u00e8res-le-Buisson": {
                "lat": 48.7464,
                "lng": 2.2674
            },
            "Arnouville-l\u00e8s-Gonesse": {
                "lat": 48.9878,
                "lng": 2.4175
            },
            "Saint-Genis-Pouilly": {
                "lat": 46.2437,
                "lng": 6.025
            },
            "Biscarrosse": {
                "lat": 44.3942,
                "lng": -1.1628
            },
            "Les Sables-d\u2019Olonne": {
                "lat": 46.4972,
                "lng": -1.7833
            },
            "Saint-Fargeau": {
                "lat": 48.5356,
                "lng": 2.5241
            },
            "Montesson": {
                "lat": 48.9085,
                "lng": 2.1496
            },
            "Flers": {
                "lat": 48.7518,
                "lng": -0.5605
            },
            "Valenton": {
                "lat": 48.745,
                "lng": 2.4672
            },
            "Roquebrune-sur-Argens": {
                "lat": 43.43,
                "lng": 6.63
            },
            "Chen\u00f4ve": {
                "lat": 47.2917,
                "lng": 5.0081
            },
            "Sainte-Maxime": {
                "lat": 43.309,
                "lng": 6.635
            },
            "Saint-Brevin-les-Pins": {
                "lat": 47.2486,
                "lng": -2.1656
            },
            "Ch\u00e2teau-d\u2019Olonne": {
                "lat": 46.505,
                "lng": -1.7361
            },
            "Pont-\u00e0-Mousson": {
                "lat": 48.9053,
                "lng": 6.0547
            },
            "M\u00e9ru": {
                "lat": 49.2356,
                "lng": 2.1339
            },
            "Saint-Paul-l\u00e8s-Dax": {
                "lat": 43.7264,
                "lng": -1.0517
            },
            "Woippy": {
                "lat": 49.1519,
                "lng": 6.1522
            },
            "Saint-Gilles": {
                "lat": 43.6786,
                "lng": 4.4317
            },
            "Bois-Guillaume": {
                "lat": 49.4614,
                "lng": 1.1092
            },
            "Auray": {
                "lat": 47.6686,
                "lng": -2.9814
            },
            "Cr\u00e9py-en-Valois": {
                "lat": 49.2358,
                "lng": 2.89
            },
            "Hautmont": {
                "lat": 50.2486,
                "lng": 3.9253
            },
            "Castelsarrasin": {
                "lat": 44.0408,
                "lng": 1.1078
            },
            "Douarnenez": {
                "lat": 48.0928,
                "lng": -4.3292
            },
            "Caudry": {
                "lat": 50.1258,
                "lng": 3.4125
            },
            "Ollioules": {
                "lat": 43.133,
                "lng": 5.85
            },
            "Thouars": {
                "lat": 46.9758,
                "lng": -0.2142
            },
            "Persan": {
                "lat": 49.1542,
                "lng": 2.2719
            },
            "Tulle": {
                "lat": 45.2673,
                "lng": 1.7655
            },
            "Fleury-M\u00e9rogis": {
                "lat": 48.63,
                "lng": 2.362
            },
            "Canteleu": {
                "lat": 49.4411,
                "lng": 1.0261
            },
            "Audincourt": {
                "lat": 47.4836,
                "lng": 6.8403
            },
            "Mont\u00e9vrain": {
                "lat": 48.875,
                "lng": 2.7456
            },
            "Lons": {
                "lat": 43.3158,
                "lng": -0.4094
            },
            "Berre-l\u2019\u00c9tang": {
                "lat": 43.476,
                "lng": 5.1686
            },
            "Bill\u00e8re": {
                "lat": 43.3031,
                "lng": -0.3906
            },
            "Lieusaint": {
                "lat": 48.6333,
                "lng": 2.55
            },
            "Pierrelatte": {
                "lat": 44.3783,
                "lng": 4.6964
            },
            "Rixheim": {
                "lat": 47.7494,
                "lng": 7.4053
            },
            "Lys-l\u00e8s-Lannoy": {
                "lat": 50.6722,
                "lng": 3.2153
            },
            "Roncq": {
                "lat": 50.7544,
                "lng": 3.1211
            },
            "Saint-Orens-de-Gameville": {
                "lat": 43.5518,
                "lng": 1.5338
            },
            "Moissac": {
                "lat": 44.1056,
                "lng": 1.0861
            },
            "Saint-Martin-de-Crau": {
                "lat": 43.6408,
                "lng": 4.8133
            },
            "Mions": {
                "lat": 45.6629,
                "lng": 4.956
            },
            "Montataire": {
                "lat": 49.2561,
                "lng": 2.4392
            },
            "Avon": {
                "lat": 48.4097,
                "lng": 2.7161
            },
            "Saint-Jacques-de-la-Lande": {
                "lat": 48.0656,
                "lng": -1.7186
            },
            "Boll\u00e8ne": {
                "lat": 44.2811,
                "lng": 4.7494
            },
            "Vaires-sur-Marne": {
                "lat": 48.8667,
                "lng": 2.65
            },
            "Lourdes": {
                "lat": 43.0942,
                "lng": -0.0469
            },
            "Courcouronnes": {
                "lat": 48.6239,
                "lng": 2.4294
            },
            "Genas": {
                "lat": 45.7314,
                "lng": 5.0022
            },
            "Plouzan\u00e9": {
                "lat": 48.3831,
                "lng": -4.6189
            },
            "Fontenay-le-Comte": {
                "lat": 46.466,
                "lng": -0.8061
            },
            "Fontenay-le-Fleury": {
                "lat": 48.8144,
                "lng": 2.0494
            },
            "Plougastel-Daoulas": {
                "lat": 48.3733,
                "lng": -4.3694
            },
            "Tergnier": {
                "lat": 49.6564,
                "lng": 3.2883
            },
            "Anzin": {
                "lat": 50.3722,
                "lng": 3.505
            },
            "Gien": {
                "lat": 47.6897,
                "lng": 2.63
            },
            "Ostwald": {
                "lat": 48.5425,
                "lng": 7.7103
            },
            "Berck-sur-Mer": {
                "lat": 50.4089,
                "lng": 1.5933
            },
            "Argentan": {
                "lat": 48.75,
                "lng": -0.02
            },
            "Carros": {
                "lat": 43.7933,
                "lng": 7.1883
            },
            "Outreau": {
                "lat": 50.7044,
                "lng": 1.5947
            },
            "Amilly": {
                "lat": 47.9742,
                "lng": 2.7708
            },
            "Andr\u00e9sy": {
                "lat": 48.9817,
                "lng": 2.0592
            },
            "Morangis": {
                "lat": 48.7055,
                "lng": 2.3326
            },
            "Nemours": {
                "lat": 48.2674,
                "lng": 2.697
            },
            "Kingersheim": {
                "lat": 47.7922,
                "lng": 7.3386
            },
            "Mouvaux": {
                "lat": 50.7039,
                "lng": 3.1414
            },
            "Graulhet": {
                "lat": 43.7617,
                "lng": 1.9892
            },
            "Autun": {
                "lat": 46.9517,
                "lng": 4.2994
            },
            "Monteux": {
                "lat": 44.0361,
                "lng": 4.997
            },
            "Gex": {
                "lat": 46.3333,
                "lng": 6.0578
            },
            "Noyon": {
                "lat": 49.5817,
                "lng": 2.9997
            },
            "Saint-Andr\u00e9": {
                "lat": 50.6608,
                "lng": 3.045
            },
            "Amboise": {
                "lat": 47.4042,
                "lng": 0.98
            },
            "Tarnos": {
                "lat": 43.5411,
                "lng": -1.46
            },
            "Ch\u00e2teaudun": {
                "lat": 48.0717,
                "lng": 1.3387
            },
            "Cusset": {
                "lat": 46.1353,
                "lng": 3.4572
            },
            "Mayenne": {
                "lat": 48.312,
                "lng": -0.6183
            },
            "Bonneville": {
                "lat": 46.0797,
                "lng": 6.4014
            },
            "Saint-Andr\u00e9-de-Cubzac": {
                "lat": 44.9956,
                "lng": -0.4447
            },
            "Gu\u00e9ret": {
                "lat": 46.1714,
                "lng": 1.8692
            },
            "Auriol": {
                "lat": 43.3681,
                "lng": 5.6361
            },
            "Seclin": {
                "lat": 50.5489,
                "lng": 3.0303
            },
            "Juvignac": {
                "lat": 43.6139,
                "lng": 3.8106
            },
            "Saint-Andr\u00e9-les-Vergers": {
                "lat": 48.2806,
                "lng": 4.0547
            },
            "Bayeux": {
                "lat": 49.2794,
                "lng": -0.7028
            },
            "Valbonne": {
                "lat": 43.6424,
                "lng": 7.0095
            },
            "Ch\u00e2lette-sur-Loing": {
                "lat": 48.0125,
                "lng": 2.7364
            },
            "Les Ponts-de-C\u00e9": {
                "lat": 47.4253,
                "lng": -0.5242
            },
            "Betton": {
                "lat": 48.1833,
                "lng": -1.6428
            },
            "Saint-Hilaire-de-Riez": {
                "lat": 46.7219,
                "lng": -1.9442
            },
            "Beausoleil": {
                "lat": 43.7425,
                "lng": 7.4244
            },
            "Comines": {
                "lat": 50.7616,
                "lng": 3.0086
            },
            "M\u00e8ze": {
                "lat": 43.4278,
                "lng": 3.6058
            },
            "Villeneuve-l\u00e8s-Avignon": {
                "lat": 43.9672,
                "lng": 4.7967
            },
            "Yzeure": {
                "lat": 46.5669,
                "lng": 3.3553
            },
            "Canet-en-Roussillon": {
                "lat": 42.7067,
                "lng": 3.0078
            },
            "Granville": {
                "lat": 48.8374,
                "lng": -1.5939
            },
            "Lamballe": {
                "lat": 48.4694,
                "lng": -2.5167
            },
            "Vidauban": {
                "lat": 43.4262,
                "lng": 6.4325
            },
            "Saint-Jean-de-V\u00e9das": {
                "lat": 43.5772,
                "lng": 3.8247
            },
            "Cuers": {
                "lat": 43.2383,
                "lng": 6.0717
            },
            "Fonsorbes": {
                "lat": 43.5369,
                "lng": 1.2319
            },
            "Creutzwald": {
                "lat": 49.2,
                "lng": 6.6833
            },
            "Andernos-les-Bains": {
                "lat": 44.7433,
                "lng": -1.0892
            },
            "Pont-du-Ch\u00e2teau": {
                "lat": 45.7992,
                "lng": 3.2492
            },
            "Vernouillet": {
                "lat": 48.9728,
                "lng": 1.9842
            },
            "Castelnaudary": {
                "lat": 43.3192,
                "lng": 1.9544
            },
            "Bischwiller": {
                "lat": 48.7667,
                "lng": 7.8575
            },
            "Brignais": {
                "lat": 45.6739,
                "lng": 4.7542
            },
            "Triel-sur-Seine": {
                "lat": 48.9817,
                "lng": 2.0069
            },
            "Oissel": {
                "lat": 49.3425,
                "lng": 1.0922
            },
            "Aubenas": {
                "lat": 44.6206,
                "lng": 4.3911
            },
            "Sarrebourg": {
                "lat": 48.74,
                "lng": 7.05
            },
            "L\u2019Union": {
                "lat": 43.6575,
                "lng": 1.4853
            },
            "Harnes": {
                "lat": 50.4458,
                "lng": 2.9067
            },
            "Aubergenville": {
                "lat": 48.959,
                "lng": 1.855
            },
            "Mende": {
                "lat": 44.5194,
                "lng": 3.5014
            },
            "Quimperl\u00e9": {
                "lat": 47.8736,
                "lng": -3.5486
            },
            "La Motte-Servolex": {
                "lat": 45.5975,
                "lng": 5.8783
            },
            "La Chapelle-Saint-Luc": {
                "lat": 48.3125,
                "lng": 4.0453
            },
            "Pont-Sainte-Maxence": {
                "lat": 49.3019,
                "lng": 2.6044
            },
            "Claye-Souilly": {
                "lat": 48.95,
                "lng": 2.7
            },
            "Bully-les-Mines": {
                "lat": 50.4425,
                "lng": 2.7253
            },
            "Obernai": {
                "lat": 48.46,
                "lng": 7.48
            },
            "Ch\u00e2teaubriant": {
                "lat": 47.7178,
                "lng": -1.375
            },
            "Rognac": {
                "lat": 43.4886,
                "lng": 5.2331
            },
            "Riedisheim": {
                "lat": 47.7489,
                "lng": 7.3675
            },
            "Barentin": {
                "lat": 49.55,
                "lng": 0.95
            },
            "Pornichet": {
                "lat": 47.2775,
                "lng": -2.3389
            },
            "Saint-Cyr-sur-Mer": {
                "lat": 43.1789,
                "lng": 5.7086
            },
            "Sabl\u00e9-sur-Sarthe": {
                "lat": 47.8505,
                "lng": -0.3292
            },
            "Louvres": {
                "lat": 49.0447,
                "lng": 2.5056
            },
            "La Queue-en-Brie": {
                "lat": 48.79,
                "lng": 2.5775
            },
            "Solli\u00e8s-Pont": {
                "lat": 43.1911,
                "lng": 6.0417
            },
            "Le Chambon-Feugerolles": {
                "lat": 45.3969,
                "lng": 4.3258
            },
            "Raismes": {
                "lat": 50.3897,
                "lng": 3.4867
            },
            "Marly": {
                "lat": 49.0599,
                "lng": 6.154
            },
            "Ifs": {
                "lat": 49.1389,
                "lng": -0.3519
            },
            "Provins": {
                "lat": 48.5604,
                "lng": 3.299
            },
            "Guidel": {
                "lat": 47.7914,
                "lng": -3.4872
            },
            "Saint-Av\u00e9": {
                "lat": 47.6872,
                "lng": -2.7333
            },
            "Gisors": {
                "lat": 49.2811,
                "lng": 1.7772
            },
            "Pac\u00e9": {
                "lat": 48.1477,
                "lng": -1.7739
            },
            "Craponne": {
                "lat": 45.7453,
                "lng": 4.7233
            },
            "Talant": {
                "lat": 47.3372,
                "lng": 5.0064
            },
            "Onet Village": {
                "lat": 44.3664,
                "lng": 2.5942
            },
            "\u00c9pinay-sous-S\u00e9nart": {
                "lat": 48.6948,
                "lng": 2.5147
            },
            "Florange": {
                "lat": 49.3222,
                "lng": 6.1192
            },
            "L\u2019Isle-Adam": {
                "lat": 49.1119,
                "lng": 2.2236
            },
            "Ch\u00e2teau-Gontier": {
                "lat": 47.8286,
                "lng": -0.7028
            },
            "Maizi\u00e8res-l\u00e8s-Metz": {
                "lat": 49.2125,
                "lng": 6.1617
            },
            "Le Relecq-Kerhuon": {
                "lat": 48.4092,
                "lng": -4.3956
            },
            "Somain": {
                "lat": 50.3583,
                "lng": 3.2811
            },
            "Villecresnes": {
                "lat": 48.7214,
                "lng": 2.5342
            },
            "Vauvert": {
                "lat": 43.6942,
                "lng": 4.2769
            },
            "Cogolin": {
                "lat": 43.2526,
                "lng": 6.53
            },
            "Cernay": {
                "lat": 47.81,
                "lng": 7.18
            },
            "Sept\u00e8mes-les-Vallons": {
                "lat": 43.3992,
                "lng": 5.3667
            },
            "Villefranche-de-Rouergue": {
                "lat": 44.3533,
                "lng": 2.035
            },
            "Saint-Pierre-du-Perray": {
                "lat": 48.6123,
                "lng": 2.4952
            },
            "Sassenage": {
                "lat": 45.2058,
                "lng": 5.6656
            },
            "Bolbec": {
                "lat": 49.57,
                "lng": 0.47
            },
            "Thiers": {
                "lat": 45.8572,
                "lng": 3.5483
            },
            "Annappes": {
                "lat": 50.6264,
                "lng": 3.1506
            },
            "Saint-Gaudens": {
                "lat": 43.1089,
                "lng": 0.7242
            },
            "Chambray-l\u00e8s-Tours": {
                "lat": 47.3383,
                "lng": 0.7144
            },
            "Le Haillan": {
                "lat": 44.8725,
                "lng": -0.6758
            },
            "Saint-Est\u00e8ve": {
                "lat": 42.7142,
                "lng": 2.8428
            },
            "Noeux-les-Mines": {
                "lat": 50.4803,
                "lng": 2.6656
            },
            "Chauny": {
                "lat": 49.6164,
                "lng": 3.22
            },
            "Mios": {
                "lat": 44.6058,
                "lng": -0.9356
            },
            "H\u0153nheim": {
                "lat": 48.625,
                "lng": 7.7553
            },
            "Ved\u00e8ne": {
                "lat": 43.9783,
                "lng": 4.9039
            },
            "Fourmies": {
                "lat": 50.0181,
                "lng": 4.0539
            },
            "Vitry-le-Fran\u00e7ois": {
                "lat": 48.7256,
                "lng": 4.5853
            },
            "Bellegarde-sur-Valserine": {
                "lat": 46.1083,
                "lng": 5.8267
            },
            "La Londe-les-Maures": {
                "lat": 43.1381,
                "lng": 6.2344
            },
            "Enghien-les-Bains": {
                "lat": 48.9697,
                "lng": 2.3081
            },
            "M\u00e9ricourt": {
                "lat": 50.4031,
                "lng": 2.8667
            },
            "Saint-Cyprien": {
                "lat": 42.6189,
                "lng": 3.0069
            },
            "Plan-de-Cuques": {
                "lat": 43.3478,
                "lng": 5.4639
            },
            "Saverne": {
                "lat": 48.74,
                "lng": 7.36
            },
            "Saint-Junien": {
                "lat": 45.8881,
                "lng": 0.9019
            },
            "Yvetot": {
                "lat": 49.62,
                "lng": 0.75
            },
            "Ploufragan": {
                "lat": 48.49,
                "lng": -2.794
            },
            "Dugny": {
                "lat": 48.95,
                "lng": 2.4167
            },
            "Bruay-sur-l\u2019Escaut": {
                "lat": 50.3989,
                "lng": 3.5403
            },
            "Saint-Germain-l\u00e8s-Arpajon": {
                "lat": 48.5946,
                "lng": 2.2558
            },
            "Arpajon": {
                "lat": 48.5901,
                "lng": 2.2476
            },
            "Dourdan": {
                "lat": 48.5289,
                "lng": 2.0156
            },
            "Dammartin-en-Go\u00eble": {
                "lat": 49.0538,
                "lng": 2.6817
            },
            "La Roche-sur-Foron": {
                "lat": 46.0673,
                "lng": 6.3113
            },
            "Arcachon": {
                "lat": 44.65,
                "lng": -1.17
            },
            "Saint-Jean": {
                "lat": 43.6664,
                "lng": 1.5058
            },
            "Porto-Vecchio": {
                "lat": 41.5917,
                "lng": 9.2803
            },
            "Gravelines": {
                "lat": 50.9858,
                "lng": 2.1283
            },
            "Marquette-l\u00e8s-Lille": {
                "lat": 50.6764,
                "lng": 3.0667
            },
            "Guilherand": {
                "lat": 44.9353,
                "lng": 4.8756
            },
            "Tournon-sur-Rh\u00f4ne": {
                "lat": 45.0672,
                "lng": 4.8328
            },
            "Vaux-le-P\u00e9nil": {
                "lat": 48.5257,
                "lng": 2.6824
            },
            "Villepreux": {
                "lat": 48.8308,
                "lng": 2.0031
            },
            "Cesson": {
                "lat": 48.5633,
                "lng": 2.6053
            },
            "Guebwiller": {
                "lat": 47.9083,
                "lng": 7.2108
            },
            "Saint-Saulve": {
                "lat": 50.37,
                "lng": 3.555
            },
            "Biganos": {
                "lat": 44.645,
                "lng": -0.9772
            },
            "Chassieu": {
                "lat": 45.745,
                "lng": 4.966
            },
            "Pontch\u00e2teau": {
                "lat": 47.43,
                "lng": -2.083
            },
            "Passy": {
                "lat": 45.9244,
                "lng": 6.6872
            },
            "Stiring-Wendel": {
                "lat": 49.2,
                "lng": 6.93
            },
            "Panazol": {
                "lat": 45.8397,
                "lng": 1.3108
            },
            "Mainvilliers": {
                "lat": 48.4539,
                "lng": 1.4628
            },
            "Le Luc": {
                "lat": 43.3956,
                "lng": 6.3133
            },
            "Montlouis-sur-Loire": {
                "lat": 47.3892,
                "lng": 0.8281
            },
            "Riorges": {
                "lat": 46.0436,
                "lng": 4.0414
            },
            "Maromme": {
                "lat": 49.4825,
                "lng": 1.0428
            },
            "Allonnes": {
                "lat": 47.9686,
                "lng": 0.1606
            },
            "Saint-Martin-Boulogne": {
                "lat": 50.7267,
                "lng": 1.6328
            },
            "Issoudun": {
                "lat": 46.9608,
                "lng": 1.9944
            },
            "L\u00e9zignan-Corbi\u00e8res": {
                "lat": 43.2014,
                "lng": 2.7584
            },
            "Chevigny-Saint-Sauveur": {
                "lat": 47.3025,
                "lng": 5.1364
            },
            "Corbas": {
                "lat": 45.66,
                "lng": 4.9
            },
            "\u00c9taples": {
                "lat": 50.5186,
                "lng": 1.6414
            },
            "Ferney-Voltaire": {
                "lat": 46.2558,
                "lng": 6.1081
            },
            "Erstein": {
                "lat": 48.42,
                "lng": 7.66
            },
            "Castelginest": {
                "lat": 43.6947,
                "lng": 1.4336
            },
            "Bondoufle": {
                "lat": 48.6145,
                "lng": 2.3807
            },
            "Lavaur": {
                "lat": 43.6997,
                "lng": 1.8197
            },
            "Amn\u00e9ville": {
                "lat": 49.2614,
                "lng": 6.1425
            },
            "Le Pont-de-Claix": {
                "lat": 45.1289,
                "lng": 5.6989
            },
            "Wambrechies": {
                "lat": 50.6861,
                "lng": 3.0494
            },
            "Argel\u00e8s-sur-Mer": {
                "lat": 42.5449,
                "lng": 3.0235
            },
            "Thouar\u00e9-sur-Loire": {
                "lat": 47.2683,
                "lng": -1.4394
            },
            "Annoeullin": {
                "lat": 50.53,
                "lng": 2.9336
            },
            "Valentigney": {
                "lat": 47.4633,
                "lng": 6.8331
            },
            "Vire": {
                "lat": 48.84,
                "lng": -0.89
            },
            "\u00c9pinay-sur-Orge": {
                "lat": 48.6726,
                "lng": 2.3239
            },
            "D\u00e9ville-l\u00e8s-Rouen": {
                "lat": 49.47,
                "lng": 1.05
            },
            "Fondettes": {
                "lat": 47.405,
                "lng": 0.5997
            },
            "Chanteloup-les-Vignes": {
                "lat": 48.9792,
                "lng": 2.0319
            },
            "Pia": {
                "lat": 42.7458,
                "lng": 2.9217
            },
            "Le Pradet": {
                "lat": 43.1064,
                "lng": 6.0239
            },
            "Le Taillan-M\u00e9doc": {
                "lat": 44.9053,
                "lng": -0.6686
            },
            "Voisins-le-Bretonneux": {
                "lat": 48.7592,
                "lng": 2.0517
            },
            "L\u00e9ognan": {
                "lat": 44.7294,
                "lng": -0.5997
            },
            "Ville-d\u2019Avray": {
                "lat": 48.8261,
                "lng": 2.1933
            },
            "Orthez": {
                "lat": 43.49,
                "lng": -0.77
            },
            "Trets": {
                "lat": 43.4475,
                "lng": 5.6836
            },
            "Chantilly": {
                "lat": 49.2,
                "lng": 2.4667
            },
            "Beaumont": {
                "lat": 45.7525,
                "lng": 3.0836
            },
            "Pernes-les-Fontaines": {
                "lat": 43.9986,
                "lng": 5.0597
            },
            "Oloron-Sainte-Marie": {
                "lat": 43.1947,
                "lng": -0.6056
            },
            "Pont-Saint-Esprit": {
                "lat": 44.2575,
                "lng": 4.6492
            },
            "Longuenesse": {
                "lat": 50.7364,
                "lng": 2.2383
            },
            "Brian\u00e7on": {
                "lat": 44.8964,
                "lng": 6.6356
            },
            "Flers-lez-Lille": {
                "lat": 50.6333,
                "lng": 3.1333
            },
            "P\u00e9lissanne": {
                "lat": 43.6322,
                "lng": 5.1511
            },
            "Tinqueux": {
                "lat": 49.2509,
                "lng": 3.9918
            },
            "Urrugne": {
                "lat": 43.3631,
                "lng": -1.6989
            },
            "Ormesson-sur-Marne": {
                "lat": 48.7858,
                "lng": 2.5383
            },
            "Saint-G\u00e9ly-du-Fesc": {
                "lat": 43.6931,
                "lng": 3.8067
            },
            "Apt": {
                "lat": 43.8769,
                "lng": 5.3969
            },
            "Mouans-Sartoux": {
                "lat": 43.6211,
                "lng": 6.9725
            },
            "Sainte-Savine": {
                "lat": 48.2956,
                "lng": 4.0497
            },
            "Pierre-B\u00e9nite": {
                "lat": 45.7036,
                "lng": 4.8242
            },
            "Marck": {
                "lat": 50.9486,
                "lng": 1.9508
            },
            "H\u00e9ricourt": {
                "lat": 47.5783,
                "lng": 6.7622
            },
            "Clermont": {
                "lat": 49.3797,
                "lng": 2.4133
            },
            "Villers-Cotter\u00eats": {
                "lat": 49.26,
                "lng": 3.0906
            },
            "Vieux-Cond\u00e9": {
                "lat": 50.4603,
                "lng": 3.5692
            },
            "Tarare": {
                "lat": 45.8969,
                "lng": 4.4339
            },
            "Portes-l\u00e8s-Valence": {
                "lat": 44.8742,
                "lng": 4.8769
            },
            "Villeneuve-l\u00e8s-Maguelone": {
                "lat": 43.5331,
                "lng": 3.8617
            },
            "Thorigny-sur-Marne": {
                "lat": 48.8788,
                "lng": 2.7075
            },
            "Croissy-sur-Seine": {
                "lat": 48.8783,
                "lng": 2.1433
            },
            "Villeneuve-Tolosane": {
                "lat": 43.5247,
                "lng": 1.3425
            },
            "Jeumont": {
                "lat": 50.2953,
                "lng": 4.1019
            },
            "Cabestany": {
                "lat": 42.6811,
                "lng": 2.9433
            },
            "Wittelsheim": {
                "lat": 47.8058,
                "lng": 7.2383
            },
            "La Riche": {
                "lat": 47.39,
                "lng": 0.6614
            },
            "Villebon-sur-Yvette": {
                "lat": 48.7002,
                "lng": 2.2277
            },
            "Limoux": {
                "lat": 43.0569,
                "lng": 2.2186
            },
            "La Trinit\u00e9": {
                "lat": 43.7417,
                "lng": 7.3147
            },
            "Gerzat": {
                "lat": 45.8267,
                "lng": 3.1456
            },
            "Avranches": {
                "lat": 48.685,
                "lng": -1.3556
            },
            "Brumath": {
                "lat": 48.7328,
                "lng": 7.7092
            },
            "Pfastatt": {
                "lat": 47.7697,
                "lng": 7.3025
            },
            "Chantepie": {
                "lat": 48.0892,
                "lng": -1.6153
            },
            "Treilli\u00e8res": {
                "lat": 47.3319,
                "lng": -1.6256
            },
            "Miribel": {
                "lat": 45.8253,
                "lng": 4.9536
            },
            "Dinard": {
                "lat": 48.63,
                "lng": -2.06
            },
            "La Chapelle-Saint-Mesmin": {
                "lat": 47.8906,
                "lng": 1.8408
            },
            "Igny": {
                "lat": 48.7421,
                "lng": 2.2261
            },
            "Aubi\u00e8re": {
                "lat": 45.7517,
                "lng": 3.1117
            },
            "Oignies": {
                "lat": 50.47,
                "lng": 2.9944
            },
            "Douchy-les-Mines": {
                "lat": 50.3022,
                "lng": 3.3939
            },
            "Courri\u00e8res": {
                "lat": 50.4586,
                "lng": 2.9481
            },
            "Blain": {
                "lat": 47.4769,
                "lng": -1.7622
            },
            "Gaillard": {
                "lat": 46.1858,
                "lng": 6.2083
            },
            "Neuville-en-Ferrain": {
                "lat": 50.7472,
                "lng": 3.1589
            },
            "Aizenay": {
                "lat": 46.74,
                "lng": -1.6083
            },
            "Parempuyre": {
                "lat": 44.95,
                "lng": -0.6039
            },
            "Divonne-les-Bains": {
                "lat": 46.3567,
                "lng": 6.1428
            },
            "Caudebec-l\u00e8s-Elbeuf": {
                "lat": 49.2817,
                "lng": 1.0222
            },
            "Auterive": {
                "lat": 43.3514,
                "lng": 1.4753
            },
            "Fuveau": {
                "lat": 43.4528,
                "lng": 5.5619
            },
            "Buxerolles": {
                "lat": 46.5983,
                "lng": 0.35
            },
            "Fouesnant": {
                "lat": 47.8942,
                "lng": -4.0108
            },
            "Gignac-la-Nerthe": {
                "lat": 43.3932,
                "lng": 5.2356
            },
            "Mondeville": {
                "lat": 49.1747,
                "lng": -0.3197
            },
            "Saint-Max": {
                "lat": 48.7017,
                "lng": 6.2072
            },
            "Cabri\u00e8s": {
                "lat": 43.4419,
                "lng": 5.3803
            },
            "Villerupt": {
                "lat": 49.4697,
                "lng": 5.9286
            },
            "Mazamet": {
                "lat": 43.4928,
                "lng": 2.3742
            },
            "Auchel": {
                "lat": 50.5086,
                "lng": 2.4744
            },
            "Parthenay": {
                "lat": 46.6462,
                "lng": -0.2478
            },
            "M\u00e9ry-sur-Oise": {
                "lat": 49.0644,
                "lng": 2.1872
            },
            "Saint-Laurent-de-la-Salanque": {
                "lat": 42.7744,
                "lng": 2.9914
            },
            "Le Beausset": {
                "lat": 43.1989,
                "lng": 5.8033
            },
            "Saint-Loub\u00e8s": {
                "lat": 44.9181,
                "lng": -0.4269
            },
            "Pierrelaye": {
                "lat": 49.0233,
                "lng": 2.1508
            },
            "Aniche": {
                "lat": 50.3306,
                "lng": 3.2519
            },
            "Soyaux": {
                "lat": 45.6414,
                "lng": 0.1986
            },
            "Lambesc": {
                "lat": 43.6547,
                "lng": 5.2625
            },
            "Lillers": {
                "lat": 50.5644,
                "lng": 2.4831
            },
            "Chambly": {
                "lat": 49.1672,
                "lng": 2.2489
            },
            "Eybens": {
                "lat": 45.1494,
                "lng": 5.7508
            },
            "Fosses": {
                "lat": 49.0989,
                "lng": 2.5075
            },
            "Max\u00e9ville": {
                "lat": 48.7122,
                "lng": 6.1639
            },
            "Carmaux": {
                "lat": 44.05,
                "lng": 2.1589
            },
            "Biot": {
                "lat": 43.6294,
                "lng": 7.0961
            },
            "Plo\u00ebrmel": {
                "lat": 47.9325,
                "lng": -2.3961
            },
            "V\u00e9traz-Monthoux": {
                "lat": 46.1742,
                "lng": 6.2581
            },
            "Saint-Pierre-du-Mont": {
                "lat": 43.8833,
                "lng": -0.5183
            },
            "Montigny-en-Gohelle": {
                "lat": 50.4286,
                "lng": 2.9308
            },
            "Roche-la-Moli\u00e8re": {
                "lat": 45.4347,
                "lng": 4.3244
            },
            "Feyzin": {
                "lat": 45.6728,
                "lng": 4.8589
            },
            "Saint-Gr\u00e9goire": {
                "lat": 48.1517,
                "lng": -1.685
            },
            "Couzeix": {
                "lat": 45.8769,
                "lng": 1.2389
            },
            "Portet-sur-Garonne": {
                "lat": 43.5231,
                "lng": 1.4067
            },
            "Seysses": {
                "lat": 43.4989,
                "lng": 1.3133
            },
            "Albert": {
                "lat": 50.0028,
                "lng": 2.6528
            },
            "Figeac": {
                "lat": 44.6086,
                "lng": 2.0317
            },
            "Loud\u00e9ac": {
                "lat": 48.1778,
                "lng": -2.7539
            },
            "Voreppe": {
                "lat": 45.2986,
                "lng": 5.6375
            },
            "Ingr\u00e9": {
                "lat": 47.9211,
                "lng": 1.825
            },
            "Darn\u00e9tal": {
                "lat": 49.4453,
                "lng": 1.1519
            },
            "Grand-Couronne": {
                "lat": 49.3583,
                "lng": 1.0081
            },
            "La Fert\u00e9-sous-Jouarre": {
                "lat": 48.9497,
                "lng": 3.1303
            },
            "Bondues": {
                "lat": 50.7022,
                "lng": 3.0939
            },
            "Serris": {
                "lat": 48.8456,
                "lng": 2.7878
            },
            "Rombas": {
                "lat": 49.2503,
                "lng": 6.095
            },
            "Saint-Lys": {
                "lat": 43.515,
                "lng": 1.1783
            },
            "Borgo": {
                "lat": 42.5547,
                "lng": 9.4281
            },
            "Revel": {
                "lat": 43.4594,
                "lng": 2.0047
            },
            "La Farl\u00e8de": {
                "lat": 43.1686,
                "lng": 6.0436
            },
            "Arques": {
                "lat": 50.7361,
                "lng": 2.3033
            },
            "Merville": {
                "lat": 50.6444,
                "lng": 2.6411
            },
            "Sallaumines": {
                "lat": 50.4203,
                "lng": 2.8631
            },
            "Le Muy": {
                "lat": 43.4736,
                "lng": 6.5667
            },
            "Saint-Doulchard": {
                "lat": 47.1056,
                "lng": 2.3575
            },
            "Dombasle-sur-Meurthe": {
                "lat": 48.6256,
                "lng": 6.3506
            },
            "Bernay": {
                "lat": 49.09,
                "lng": 0.6
            },
            "Saint-R\u00e9my-de-Provence": {
                "lat": 43.79,
                "lng": 4.8325
            },
            "Neuville-l\u00e8s-Dieppe": {
                "lat": 49.9267,
                "lng": 1.1014
            },
            "Beaumont-sur-Oise": {
                "lat": 49.1431,
                "lng": 2.2872
            },
            "Leers": {
                "lat": 50.6825,
                "lng": 3.2447
            },
            "Aire-sur-la-Lys": {
                "lat": 50.64,
                "lng": 2.4
            },
            "Bourg-de-P\u00e9age": {
                "lat": 45.0386,
                "lng": 5.0508
            },
            "Aytr\u00e9": {
                "lat": 46.1347,
                "lng": -1.1133
            },
            "Saint-Sulpice-la-Pointe": {
                "lat": 43.775,
                "lng": 1.6872
            },
            "\u00c9zanville": {
                "lat": 49.0286,
                "lng": 2.3617
            },
            "P\u00e9rols": {
                "lat": 43.5658,
                "lng": 3.9511
            },
            "Basse-Goulaine": {
                "lat": 47.2161,
                "lng": -1.4642
            },
            "Lorgues": {
                "lat": 43.4949,
                "lng": 6.3612
            },
            "Le Rheu": {
                "lat": 48.1028,
                "lng": -1.7942
            },
            "Crosne": {
                "lat": 48.7164,
                "lng": 2.458
            },
            "Lescar": {
                "lat": 43.3336,
                "lng": -0.4347
            },
            "Lu\u00e7on": {
                "lat": 46.4556,
                "lng": -1.1647
            },
            "Quincy-sous-S\u00e9nart": {
                "lat": 48.6714,
                "lng": 2.5407
            },
            "L\u00e9guevin": {
                "lat": 43.5997,
                "lng": 1.2339
            },
            "Foix": {
                "lat": 42.9664,
                "lng": 1.6078
            },
            "Vallet": {
                "lat": 47.1622,
                "lng": -1.2656
            },
            "Saint-Barth\u00e9lemy-d\u2019Anjou": {
                "lat": 47.468,
                "lng": -0.493
            },
            "Saint-Amand-Montrond": {
                "lat": 46.7,
                "lng": 2.51
            },
            "Frouzins": {
                "lat": 43.5172,
                "lng": 1.3256
            },
            "Cosne sur Loire": {
                "lat": 47.4122,
                "lng": 2.9275
            },
            "Elne": {
                "lat": 42.5997,
                "lng": 2.971
            },
            "Carqueiranne": {
                "lat": 43.0958,
                "lng": 6.0742
            },
            "Cond\u00e9-sur-l\u2019Escaut": {
                "lat": 50.45,
                "lng": 3.5914
            },
            "Saint-Philbert-de-Grand-Lieu": {
                "lat": 47.0358,
                "lng": -1.6386
            },
            "Magny-les-Hameaux": {
                "lat": 48.7363,
                "lng": 2.0877
            },
            "Saint-Jean-le-Blanc": {
                "lat": 47.8928,
                "lng": 1.9183
            },
            "Jarville-la-Malgrange": {
                "lat": 48.67,
                "lng": 6.2067
            },
            "Le Passage": {
                "lat": 44.2025,
                "lng": 0.6042
            },
            "Audenge": {
                "lat": 44.6847,
                "lng": -1.0119
            },
            "Molsheim": {
                "lat": 48.54,
                "lng": 7.49
            },
            "Savenay": {
                "lat": 47.3619,
                "lng": -1.9406
            },
            "Aucamville": {
                "lat": 43.6697,
                "lng": 1.4311
            },
            "Valr\u00e9as": {
                "lat": 44.385,
                "lng": 4.9911
            },
            "Nogent-le-Rotrou": {
                "lat": 48.3225,
                "lng": 0.8225
            },
            "Lan\u00e7on-Provence": {
                "lat": 43.593,
                "lng": 5.1286
            },
            "Tonneins": {
                "lat": 44.3906,
                "lng": 0.3092
            },
            "Ouistreham": {
                "lat": 49.28,
                "lng": -0.26
            },
            "Nort-sur-Erdre": {
                "lat": 47.4403,
                "lng": -1.4972
            },
            "L\u2019Isle-Jourdain": {
                "lat": 43.6144,
                "lng": 1.0817
            },
            "Hagondange": {
                "lat": 49.255,
                "lng": 6.1686
            },
            "Redon": {
                "lat": 47.6522,
                "lng": -2.0836
            },
            "Saint-Jean-d\u2019Illac": {
                "lat": 44.8106,
                "lng": -0.7825
            },
            "Livron-sur-Dr\u00f4me": {
                "lat": 44.7736,
                "lng": 4.8439
            },
            "Le Cr\u00e8s": {
                "lat": 43.6481,
                "lng": 3.9397
            },
            "Clermont-l\u2019H\u00e9rault": {
                "lat": 43.6281,
                "lng": 3.4331
            },
            "Launaguet": {
                "lat": 43.675,
                "lng": 1.4578
            },
            "Bures-sur-Yvette": {
                "lat": 48.6969,
                "lng": 2.1639
            },
            "Escaudain": {
                "lat": 50.335,
                "lng": 3.343
            },
            "Lesquin": {
                "lat": 50.5906,
                "lng": 3.1117
            },
            "Paray-le-Monial": {
                "lat": 46.4519,
                "lng": 4.1203
            },
            "Belley": {
                "lat": 45.7667,
                "lng": 5.6833
            },
            "Joigny": {
                "lat": 47.9831,
                "lng": 3.3978
            },
            "\u00c9vian-les-Bains": {
                "lat": 46.3833,
                "lng": 6.5833
            },
            "Mont-Saint-Martin": {
                "lat": 49.5411,
                "lng": 5.78
            },
            "Landivisiau": {
                "lat": 48.51,
                "lng": -4.0669
            },
            "Capbreton": {
                "lat": 43.6431,
                "lng": -1.4311
            },
            "Ville-la-Grand": {
                "lat": 46.2031,
                "lng": 6.2475
            },
            "Ussel": {
                "lat": 45.5489,
                "lng": 2.31
            },
            "Beauchamp": {
                "lat": 49.0144,
                "lng": 2.1906
            },
            "Beuvry": {
                "lat": 50.5206,
                "lng": 2.6803
            },
            "Rivesaltes": {
                "lat": 42.7697,
                "lng": 2.8747
            },
            "Pithiviers": {
                "lat": 48.1725,
                "lng": 2.2525
            },
            "Magny-le-Hongre": {
                "lat": 48.8631,
                "lng": 2.8133
            },
            "Scionzier": {
                "lat": 46.0581,
                "lng": 6.5503
            },
            "Chaponost": {
                "lat": 45.7103,
                "lng": 4.7422
            },
            "Mohon": {
                "lat": 49.752,
                "lng": 4.7315
            },
            "La Ravoire": {
                "lat": 45.56,
                "lng": 5.96
            },
            "Bougival": {
                "lat": 48.8667,
                "lng": 2.1333
            },
            "Les Sorini\u00e8res": {
                "lat": 47.1472,
                "lng": -1.5286
            },
            "Tomblaine": {
                "lat": 48.6833,
                "lng": 6.2167
            },
            "Le Teich": {
                "lat": 44.6347,
                "lng": -1.0225
            },
            "Guichen": {
                "lat": 47.9681,
                "lng": -1.7939
            },
            "Lamorlaye": {
                "lat": 49.1556,
                "lng": 2.4417
            },
            "Veauche": {
                "lat": 45.5619,
                "lng": 4.2781
            },
            "Sarzeau": {
                "lat": 47.5281,
                "lng": -2.7686
            },
            "Grabels": {
                "lat": 43.6486,
                "lng": 3.8025
            },
            "Gonfreville-l\u2019Orcher": {
                "lat": 49.5058,
                "lng": 0.2342
            },
            "Grenade": {
                "lat": 43.7722,
                "lng": 1.2936
            },
            "C\u00e9bazat": {
                "lat": 45.8322,
                "lng": 3.1006
            },
            "S\u00e9n\u00e9": {
                "lat": 47.6206,
                "lng": -2.7358
            },
            "Monistrol-sur-Loire": {
                "lat": 45.2933,
                "lng": 4.1728
            },
            "Gouvieux": {
                "lat": 49.1883,
                "lng": 2.4169
            },
            "Mori\u00e8res-l\u00e8s-Avignon": {
                "lat": 43.9425,
                "lng": 4.9053
            },
            "Le Teil": {
                "lat": 44.5458,
                "lng": 4.6831
            },
            "Nangis": {
                "lat": 48.5556,
                "lng": 3.0153
            },
            "Le Portel": {
                "lat": 50.7075,
                "lng": 1.5744
            },
            "Breuillet": {
                "lat": 48.5658,
                "lng": 2.1706
            },
            "Qu\u00e9tigny": {
                "lat": 47.3153,
                "lng": 5.1069
            },
            "La Fare-les-Oliviers": {
                "lat": 43.5525,
                "lng": 5.195
            },
            "Irigny": {
                "lat": 45.6731,
                "lng": 4.8225
            },
            "Le Thor": {
                "lat": 43.93,
                "lng": 4.995
            },
            "Bellerive-sur-Allier": {
                "lat": 46.1175,
                "lng": 3.4044
            },
            "Golbey": {
                "lat": 48.1967,
                "lng": 6.4381
            },
            "Fontaine-l\u00e8s-Dijon": {
                "lat": 47.3433,
                "lng": 5.0206
            },
            "Dardilly": {
                "lat": 45.8064,
                "lng": 4.7536
            },
            "Vert-Saint-Denis": {
                "lat": 48.565,
                "lng": 2.6147
            },
            "Sarlat-la-Can\u00e9da": {
                "lat": 44.8928,
                "lng": 1.2153
            },
            "Param\u00e9": {
                "lat": 48.6603,
                "lng": -1.9792
            },
            "Boucau": {
                "lat": 43.5244,
                "lng": -1.4853
            },
            "Ch\u00e9cy": {
                "lat": 47.8942,
                "lng": 2.0275
            },
            "La Fert\u00e9-Bernard": {
                "lat": 48.1875,
                "lng": 0.6553
            },
            "Rouvroy": {
                "lat": 50.3942,
                "lng": 2.9044
            },
            "Entraigues-sur-la-Sorgue": {
                "lat": 44.0036,
                "lng": 4.9272
            },
            "Qu\u00e9ven": {
                "lat": 47.7894,
                "lng": -3.4139
            },
            "Essey-l\u00e8s-Nancy": {
                "lat": 48.7067,
                "lng": 6.2231
            },
            "Poisy": {
                "lat": 45.9216,
                "lng": 6.0622
            },
            "Saint-Paul-Trois-Ch\u00e2teaux": {
                "lat": 44.3497,
                "lng": 4.769
            },
            "Aulnoye-Aymeries": {
                "lat": 50.205,
                "lng": 3.8364
            },
            "Crest": {
                "lat": 44.7292,
                "lng": 5.0228
            },
            "Notre-Dame-de-Gravenchon": {
                "lat": 49.49,
                "lng": 0.5719
            },
            "Wingles": {
                "lat": 50.4947,
                "lng": 2.8561
            },
            "P\u00e9rigny": {
                "lat": 46.1533,
                "lng": -1.0953
            },
            "Saint-Jean-de-Monts": {
                "lat": 46.7936,
                "lng": -2.0587
            },
            "Lillebonne": {
                "lat": 49.5189,
                "lng": 0.5343
            },
            "Saint-Claude": {
                "lat": 46.3878,
                "lng": 5.8639
            },
            "Onnaing": {
                "lat": 50.3883,
                "lng": 3.5989
            },
            "La Chapelle d\u2019Armenti\u00e8res": {
                "lat": 50.6736,
                "lng": 2.8961
            },
            "Margny-l\u00e8s-Compi\u00e8gne": {
                "lat": 49.4269,
                "lng": 2.8217
            },
            "Velaux": {
                "lat": 43.5286,
                "lng": 5.2564
            },
            "Le Petit-Couronne": {
                "lat": 49.3864,
                "lng": 1.0283
            },
            "Aigues-Mortes": {
                "lat": 43.5675,
                "lng": 4.1933
            },
            "Pibrac": {
                "lat": 43.6175,
                "lng": 1.2856
            },
            "Longvic": {
                "lat": 47.2886,
                "lng": 5.0644
            },
            "Roquevaire": {
                "lat": 43.35,
                "lng": 5.6053
            },
            "Montlh\u00e9ry": {
                "lat": 48.6392,
                "lng": 2.2722
            },
            "Coutras": {
                "lat": 45.0417,
                "lng": -0.1272
            },
            "Dom\u00e9rat": {
                "lat": 46.3611,
                "lng": 2.5353
            },
            "L\u2019\u00cele-Saint-Denis": {
                "lat": 48.9333,
                "lng": 2.3333
            },
            "Lempdes": {
                "lat": 45.7719,
                "lng": 3.1944
            },
            "Hirson": {
                "lat": 49.9217,
                "lng": 4.0839
            },
            "Le Loroux-Bottereau": {
                "lat": 47.2389,
                "lng": -1.3478
            },
            "Isbergues": {
                "lat": 50.6242,
                "lng": 2.4575
            },
            "Artigues-pr\u00e8s-Bordeaux": {
                "lat": 44.8628,
                "lng": -0.485
            },
            "Saint-Chamas": {
                "lat": 43.5506,
                "lng": 5.0347
            },
            "Chamonix-Mont-Blanc": {
                "lat": 45.9231,
                "lng": 6.8697
            },
            "Le Poir\u00e9-sur-Vie": {
                "lat": 46.7682,
                "lng": -1.5098
            },
            "Maul\u00e9on": {
                "lat": 46.9236,
                "lng": -0.7483
            },
            "Plabennec": {
                "lat": 48.5028,
                "lng": -4.4247
            },
            "Roussillon": {
                "lat": 45.3719,
                "lng": 4.8272
            },
            "Vif": {
                "lat": 45.0561,
                "lng": 5.6708
            },
            "Thorign\u00e9-Fouillard": {
                "lat": 48.1603,
                "lng": -1.5786
            },
            "La Grande-Motte": {
                "lat": 43.5614,
                "lng": 4.0858
            },
            "Janz\u00e9": {
                "lat": 47.9614,
                "lng": -1.4978
            },
            "Vaucresson": {
                "lat": 48.8392,
                "lng": 2.1578
            },
            "Gretz-Armainvilliers": {
                "lat": 48.7417,
                "lng": 2.735
            },
            "Ergu\u00e9-Gab\u00e9ric": {
                "lat": 47.9969,
                "lng": -4.0208
            },
            "Privas": {
                "lat": 44.7358,
                "lng": 4.597
            },
            "La Salvetat-Saint-Gilles": {
                "lat": 43.5769,
                "lng": 1.2722
            },
            "P\u00e9renchies": {
                "lat": 50.6686,
                "lng": 2.9731
            },
            "Thaon-les-Vosges": {
                "lat": 48.251,
                "lng": 6.42
            },
            "Sautron": {
                "lat": 47.2644,
                "lng": -1.6667
            },
            "Liffr\u00e9": {
                "lat": 48.2142,
                "lng": -1.5067
            },
            "Saint-Vallier": {
                "lat": 46.6428,
                "lng": 4.3719
            }
        }
    },
    "PE": {
        "name": "Peru",
        "cities": {
            "Lima": {
                "lat": -12.06,
                "lng": -77.0375
            },
            "Arequipa": {
                "lat": -16.4,
                "lng": -71.5333
            },
            "Trujillo": {
                "lat": -8.112,
                "lng": -79.0288
            },
            "Chiclayo": {
                "lat": -6.763,
                "lng": -79.8366
            },
            "Piura": {
                "lat": -5.2,
                "lng": -80.6333
            },
            "Cusco": {
                "lat": -13.525,
                "lng": -71.9722
            },
            "Callao": {
                "lat": -12.0522,
                "lng": -77.1392
            },
            "Huancayo": {
                "lat": -12.0667,
                "lng": -75.2167
            },
            "Iquitos": {
                "lat": -3.75,
                "lng": -73.25
            },
            "Pucallpa": {
                "lat": -8.3833,
                "lng": -74.55
            },
            "Chimbote": {
                "lat": -9.0745,
                "lng": -78.5936
            },
            "Tacna": {
                "lat": -18.0147,
                "lng": -70.2489
            },
            "Ica": {
                "lat": -14.0667,
                "lng": -75.7333
            },
            "Juliaca": {
                "lat": -15.4833,
                "lng": -70.1333
            },
            "Tarapoto": {
                "lat": -6.4833,
                "lng": -76.3667
            },
            "Ayacucho": {
                "lat": -13.1631,
                "lng": -74.2244
            },
            "Cajamarca": {
                "lat": -7.1667,
                "lng": -78.5167
            },
            "Huacho": {
                "lat": -11.1067,
                "lng": -77.605
            },
            "Hu\u00e1nuco": {
                "lat": -9.9295,
                "lng": -76.2397
            },
            "Sullana": {
                "lat": -4.9,
                "lng": -80.6833
            },
            "Huaycan": {
                "lat": -12.0181,
                "lng": -76.8139
            },
            "Barranca": {
                "lat": -10.7541,
                "lng": -77.761
            },
            "Puno": {
                "lat": -15.8433,
                "lng": -70.0236
            },
            "San Ignacio": {
                "lat": -5.1456,
                "lng": -79.0017
            },
            "Huaraz": {
                "lat": -9.5333,
                "lng": -77.5333
            },
            "Pisco": {
                "lat": -13.7167,
                "lng": -76.2
            },
            "Talara": {
                "lat": -4.5799,
                "lng": -81.2719
            },
            "Tumbes": {
                "lat": -3.5708,
                "lng": -80.4597
            },
            "Ja\u00e9n": {
                "lat": -5.7083,
                "lng": -78.8083
            },
            "Chosica": {
                "lat": -11.9361,
                "lng": -76.6972
            },
            "Puerto Maldonado": {
                "lat": -12.6,
                "lng": -69.1833
            },
            "Chulucanas": {
                "lat": -5.0961,
                "lng": -80.1647
            },
            "Andahuaylas": {
                "lat": -13.6575,
                "lng": -73.3833
            },
            "Huaral": {
                "lat": -11.5,
                "lng": -77.2
            },
            "Catacaos": {
                "lat": -5.2653,
                "lng": -80.675
            },
            "Abancay": {
                "lat": -13.6333,
                "lng": -72.8833
            },
            "Huanchaco": {
                "lat": -7.7,
                "lng": -79.4333
            },
            "Moquegua": {
                "lat": -17.2,
                "lng": -70.9333
            },
            "V\u00edctor Larco Herrera": {
                "lat": -8.1333,
                "lng": -79.05
            },
            "Ilo": {
                "lat": -17.6459,
                "lng": -71.3453
            },
            "Chincha Alta": {
                "lat": -13.45,
                "lng": -76.1333
            },
            "Yurimaguas": {
                "lat": -5.9,
                "lng": -76.0833
            },
            "Cerro de Pasco": {
                "lat": -10.6864,
                "lng": -76.2625
            },
            "Lambayeque": {
                "lat": -6.7,
                "lng": -79.9
            },
            "Moyobamba": {
                "lat": -6.0333,
                "lng": -76.9667
            },
            "Chota": {
                "lat": -6.5614,
                "lng": -78.6486
            },
            "Tarma": {
                "lat": -11.4186,
                "lng": -75.6908
            },
            "Juanju\u00ed": {
                "lat": -7.1802,
                "lng": -76.7265
            },
            "Cutervo": {
                "lat": -6.3778,
                "lng": -78.8167
            },
            "Sechura": {
                "lat": -5.5576,
                "lng": -80.8223
            },
            "Huancavelica": {
                "lat": -12.7864,
                "lng": -74.9756
            },
            "Pativilca": {
                "lat": -10.6958,
                "lng": -77.7742
            },
            "Tingo Mar\u00eda": {
                "lat": -9.2953,
                "lng": -75.9975
            },
            "Chep\u00e9n": {
                "lat": -7.2271,
                "lng": -79.4298
            },
            "Bagua Grande": {
                "lat": -5.7572,
                "lng": -78.4453
            },
            "Huaura": {
                "lat": -11.1,
                "lng": -77.6
            },
            "Huamachuco": {
                "lat": -7.812,
                "lng": -78.0487
            },
            "Caman\u00e1": {
                "lat": -16.6167,
                "lng": -72.7167
            },
            "Vir\u00fa": {
                "lat": -8.4143,
                "lng": -78.7524
            },
            "Sicuani": {
                "lat": -14.272,
                "lng": -71.229
            },
            "Forbe Oroya": {
                "lat": -11.522,
                "lng": -75.91
            },
            "Chancay": {
                "lat": -11.5653,
                "lng": -77.2714
            },
            "Chachapoyas": {
                "lat": -6.2167,
                "lng": -77.85
            },
            "Ferre\u00f1afe": {
                "lat": -6.7183,
                "lng": -79.7703
            },
            "Moche": {
                "lat": -8.1706,
                "lng": -79.0094
            },
            "Quillabamba": {
                "lat": -12.8681,
                "lng": -72.6931
            },
            "Huanta": {
                "lat": -12.9397,
                "lng": -74.2475
            },
            "La Rinconada": {
                "lat": -14.6325,
                "lng": -69.4458
            },
            "Casma": {
                "lat": -9.4742,
                "lng": -78.3106
            },
            "Tocache Nuevo": {
                "lat": -8.1889,
                "lng": -76.5139
            },
            "Az\u00e1ngaro": {
                "lat": -14.9099,
                "lng": -70.1974
            },
            "Ilave": {
                "lat": -16.0836,
                "lng": -69.6383
            },
            "Mollendo": {
                "lat": -17.0231,
                "lng": -72.0147
            },
            "Supe": {
                "lat": -10.8017,
                "lng": -77.7436
            },
            "Chalhuanca": {
                "lat": -14.295,
                "lng": -73.2444
            },
            "Jauja": {
                "lat": -11.775,
                "lng": -75.5
            },
            "Celend\u00edn": {
                "lat": -6.8667,
                "lng": -78.15
            },
            "Pacasmayo": {
                "lat": -7.4003,
                "lng": -79.57
            },
            "San Vicente de Ca\u00f1ete": {
                "lat": -13.0833,
                "lng": -76.4
            },
            "Requena": {
                "lat": -5.0569,
                "lng": -73.8515
            },
            "Otuzco": {
                "lat": -7.9,
                "lng": -78.5833
            },
            "Santo Tom\u00e1s": {
                "lat": -14.451,
                "lng": -72.082
            },
            "Santiago de Chuco": {
                "lat": -8.1502,
                "lng": -78.1783
            },
            "Caballococha": {
                "lat": -3.9058,
                "lng": -70.5164
            },
            "El Milagro": {
                "lat": -8.0284,
                "lng": -79.0672
            },
            "Car\u00e1s": {
                "lat": -9.0472,
                "lng": -77.7433
            },
            "Monsef\u00fa": {
                "lat": -6.8778,
                "lng": -79.8719
            },
            "Nazca": {
                "lat": -14.8289,
                "lng": -74.9436
            },
            "Rioja": {
                "lat": -6.05,
                "lng": -77.1417
            },
            "Bellavista": {
                "lat": -7.0668,
                "lng": -76.5847
            },
            "Chilca": {
                "lat": -12.5208,
                "lng": -76.7369
            },
            "Juli": {
                "lat": -16.2125,
                "lng": -69.4603
            },
            "Paramonga": {
                "lat": -10.6744,
                "lng": -77.8186
            },
            "Ayaviri": {
                "lat": -14.8818,
                "lng": -70.5901
            },
            "Cartavio": {
                "lat": -7.8833,
                "lng": -79.2167
            },
            "Puquio": {
                "lat": -14.6939,
                "lng": -74.1241
            },
            "Zarumilla": {
                "lat": -3.5014,
                "lng": -80.2722
            },
            "Lamas": {
                "lat": -6.4167,
                "lng": -76.5333
            },
            "San Pedro de Lloc": {
                "lat": -7.4167,
                "lng": -79.5
            },
            "Huarmey": {
                "lat": -10.0686,
                "lng": -78.1603
            },
            "Puerto Pimentel": {
                "lat": -6.8367,
                "lng": -79.9342
            },
            "Concepci\u00f3n": {
                "lat": -11.9185,
                "lng": -75.3129
            },
            "Contamana": {
                "lat": -7.3333,
                "lng": -75.0167
            },
            "Lampa": {
                "lat": -15.3636,
                "lng": -70.3656
            },
            "San Ram\u00f3n": {
                "lat": -11.1247,
                "lng": -75.3569
            },
            "Sisa": {
                "lat": -6.6142,
                "lng": -76.6936
            },
            "Salaverry": {
                "lat": -8.2214,
                "lng": -78.9764
            },
            "Motupe": {
                "lat": -6.1519,
                "lng": -79.7142
            },
            "Cajabamba": {
                "lat": -7.6237,
                "lng": -78.046
            },
            "Saposoa": {
                "lat": -6.9364,
                "lng": -76.7718
            },
            "Oxapampa": {
                "lat": -10.574,
                "lng": -75.405
            },
            "Oy\u00f3n": {
                "lat": -10.6692,
                "lng": -76.7728
            },
            "Macusani": {
                "lat": -14.0692,
                "lng": -70.4311
            },
            "Pampas": {
                "lat": -12.3989,
                "lng": -74.8667
            },
            "Yanahuanca": {
                "lat": -10.4914,
                "lng": -76.5136
            },
            "Jun\u00edn": {
                "lat": -11.15,
                "lng": -75.9833
            },
            "Urcos": {
                "lat": -13.6861,
                "lng": -71.6228
            },
            "Ascope": {
                "lat": -7.7138,
                "lng": -79.1077
            },
            "M\u00e1ncora": {
                "lat": -4.1056,
                "lng": -81.0472
            },
            "Santiago": {
                "lat": -14.1892,
                "lng": -75.7126
            },
            "Satipo": {
                "lat": -11.2542,
                "lng": -74.6367
            },
            "Chupaca": {
                "lat": -12.062,
                "lng": -75.2872
            },
            "Olmos": {
                "lat": -5.9855,
                "lng": -79.7569
            },
            "Pisac": {
                "lat": -13.4242,
                "lng": -71.8578
            },
            "Calca": {
                "lat": -13.323,
                "lng": -71.956
            },
            "Huari": {
                "lat": -9.3689,
                "lng": -77.2369
            },
            "Chala": {
                "lat": -15.8519,
                "lng": -74.25
            },
            "Aplao": {
                "lat": -16.0713,
                "lng": -72.4882
            },
            "Acobamba": {
                "lat": -12.8431,
                "lng": -74.5694
            },
            "Putina": {
                "lat": -15.47,
                "lng": -69.43
            },
            "Coracora": {
                "lat": -15.017,
                "lng": -73.7804
            },
            "Paita": {
                "lat": -5.0911,
                "lng": -81.1064
            },
            "Urubamba": {
                "lat": -13.3042,
                "lng": -72.1167
            },
            "Nauta": {
                "lat": -4.5083,
                "lng": -73.5833
            },
            "Desaguadero": {
                "lat": -16.5667,
                "lng": -69.0333
            },
            "Tournavista": {
                "lat": -8.9322,
                "lng": -74.7052
            },
            "Rocafuerte": {
                "lat": -0.9329,
                "lng": -75.4
            },
            "Andoas": {
                "lat": -2.9042,
                "lng": -76.4028
            },
            "Puca Urco": {
                "lat": -2.3328,
                "lng": -71.9167
            },
            "Soldado Bartra": {
                "lat": -2.5161,
                "lng": -75.7666
            },
            "G\u00fcepp\u00ed": {
                "lat": -0.1166,
                "lng": -75.23
            }
        }
    },
    "AO": {
        "name": "Angola",
        "cities": {
            "Luanda": {
                "lat": -8.8383,
                "lng": 13.2344
            },
            "Cacuaco": {
                "lat": -8.8053,
                "lng": 13.2444
            },
            "Cazenga": {
                "lat": -8.8214,
                "lng": 13.2911
            },
            "Lubango": {
                "lat": -14.9167,
                "lng": 13.5
            },
            "Cabinda": {
                "lat": -5.56,
                "lng": 12.19
            },
            "Huambo": {
                "lat": -12.7767,
                "lng": 15.7347
            },
            "Malanje": {
                "lat": -9.5333,
                "lng": 16.35
            },
            "Benguela": {
                "lat": -12.55,
                "lng": 13.4167
            },
            "Belas": {
                "lat": -8.9983,
                "lng": 13.265
            },
            "Talatona": {
                "lat": -8.9167,
                "lng": 13.1833
            },
            "Lobito": {
                "lat": -12.3667,
                "lng": 13.5333
            },
            "Saurimo": {
                "lat": -9.65,
                "lng": 20.4
            },
            "Cubal": {
                "lat": -13.0333,
                "lng": 14.25
            },
            "Luena": {
                "lat": -11.7918,
                "lng": 19.9062
            },
            "Cuito": {
                "lat": -12.3833,
                "lng": 16.9333
            },
            "U\u00edge": {
                "lat": -7.6167,
                "lng": 15.05
            },
            "Menongue": {
                "lat": -14.6556,
                "lng": 17.6842
            },
            "Vila Teixeira da Silva": {
                "lat": -12.1958,
                "lng": 15.8556
            },
            "Sumbe": {
                "lat": -11.2053,
                "lng": 13.8417
            },
            "Gabela": {
                "lat": -10.85,
                "lng": 14.3667
            },
            "Ndulo": {
                "lat": -11.4833,
                "lng": 15.8333
            },
            "Mo\u00e7\u00e2medes": {
                "lat": -15.1953,
                "lng": 12.1508
            },
            "Ganda": {
                "lat": -13.0167,
                "lng": 14.6333
            },
            "Uacu Cungo": {
                "lat": -11.3583,
                "lng": 15.1194
            },
            "Barra do Dande": {
                "lat": -8.4728,
                "lng": 13.3731
            },
            "Soio": {
                "lat": -6.1333,
                "lng": 12.3667
            },
            "Tchitato": {
                "lat": -7.3667,
                "lng": 20.8333
            },
            "Chibia": {
                "lat": -15.1833,
                "lng": 13.7
            },
            "Ucu Seles": {
                "lat": -11.4047,
                "lng": 14.3006
            },
            "Cuango": {
                "lat": -9.1444,
                "lng": 18.0464
            },
            "Mbanza Kongo": {
                "lat": -6.2678,
                "lng": 14.2481
            },
            "Caluquembe": {
                "lat": -13.9208,
                "lng": 14.5347
            },
            "Dundo": {
                "lat": -7.3801,
                "lng": 20.8351
            },
            "Catumbela": {
                "lat": -12.4339,
                "lng": 13.5433
            },
            "Cazanga": {
                "lat": -9.3,
                "lng": 14.95
            },
            "Catape": {
                "lat": -13.7667,
                "lng": 15.0833
            },
            "Ebo": {
                "lat": -11.0,
                "lng": 14.6667
            },
            "Bocoio": {
                "lat": -12.45,
                "lng": 14.15
            },
            "Quibala": {
                "lat": -10.7333,
                "lng": 14.9833
            },
            "Ndalatando": {
                "lat": -9.3,
                "lng": 14.9167
            },
            "Quipungo": {
                "lat": -14.8167,
                "lng": 14.55
            },
            "Camacupa": {
                "lat": -12.0256,
                "lng": 17.4686
            },
            "Lucapa": {
                "lat": -8.4228,
                "lng": 20.7392
            },
            "Maquela do Zombo": {
                "lat": -6.05,
                "lng": 15.1167
            },
            "Namacunde": {
                "lat": -17.3,
                "lng": 15.85
            },
            "Negage": {
                "lat": -7.7667,
                "lng": 15.2667
            },
            "Porto Amboim": {
                "lat": -10.7183,
                "lng": 13.75
            },
            "Quimbele": {
                "lat": -6.5167,
                "lng": 16.2167
            },
            "Londuimbali": {
                "lat": -12.2419,
                "lng": 15.3133
            },
            "Chicomba": {
                "lat": -14.1333,
                "lng": 14.9167
            },
            "Chinguar": {
                "lat": -12.55,
                "lng": 16.3333
            },
            "Catabola": {
                "lat": -12.1167,
                "lng": 17.3
            },
            "Ondjiva": {
                "lat": -17.0667,
                "lng": 15.7333
            },
            "Catchiungo": {
                "lat": -12.5667,
                "lng": 16.2333
            },
            "Cassongue": {
                "lat": -11.8333,
                "lng": 15.0
            },
            "Quilengues": {
                "lat": -14.0814,
                "lng": 14.0931
            },
            "Mungo": {
                "lat": -11.6667,
                "lng": 16.1667
            },
            "Balombo": {
                "lat": -12.35,
                "lng": 14.7667
            },
            "Ba\u00eda Farta": {
                "lat": -12.6072,
                "lng": 13.1944
            },
            "Ndjamba": {
                "lat": -14.7,
                "lng": 16.0667
            },
            "Quilenda": {
                "lat": -10.6333,
                "lng": 14.3333
            },
            "Caimbambo": {
                "lat": -12.9,
                "lng": 14.0833
            },
            "Cambambe": {
                "lat": -9.7586,
                "lng": 14.5122
            },
            "Luau": {
                "lat": -10.7044,
                "lng": 22.2283
            },
            "Conda": {
                "lat": -11.1667,
                "lng": 14.5
            },
            "Humpata": {
                "lat": -15.0,
                "lng": 13.3333
            },
            "Chongoroi": {
                "lat": -13.5667,
                "lng": 13.95
            },
            "Longonjo": {
                "lat": -12.9067,
                "lng": 15.1864
            },
            "Calandala": {
                "lat": -9.0667,
                "lng": 17.9
            },
            "Kuvango": {
                "lat": -14.4667,
                "lng": 16.3
            },
            "Chitembo": {
                "lat": -13.5167,
                "lng": 16.7667
            },
            "Chitemo": {
                "lat": -13.4872,
                "lng": 17.1919
            },
            "Caxito": {
                "lat": -8.58,
                "lng": 13.6642
            },
            "Cunhinga": {
                "lat": -12.2333,
                "lng": 16.7833
            },
            "Dondo": {
                "lat": -9.6942,
                "lng": 14.4228
            },
            "Xangongo": {
                "lat": -16.7467,
                "lng": 14.9747
            },
            "Cahama": {
                "lat": -16.2833,
                "lng": 14.3167
            },
            "Cu\u00edmba": {
                "lat": -6.1178,
                "lng": 14.6172
            },
            "Sanza Pombo": {
                "lat": -7.3333,
                "lng": 16.0
            },
            "X\u00e1 Muteba": {
                "lat": -9.5167,
                "lng": 17.8167
            },
            "Damba": {
                "lat": -6.8833,
                "lng": 15.0167
            },
            "Chipindo": {
                "lat": -13.8244,
                "lng": 15.8
            },
            "Camabatela": {
                "lat": -8.1833,
                "lng": 15.3667
            },
            "Sungo": {
                "lat": -11.2333,
                "lng": 14.3167
            },
            "Nambuangongo": {
                "lat": -8.0167,
                "lng": 14.2
            },
            "Calulo": {
                "lat": -10.0007,
                "lng": 14.901
            },
            "Capenda Camulemba": {
                "lat": -9.4233,
                "lng": 18.4328
            },
            "Cuvelai": {
                "lat": -15.6667,
                "lng": 15.8
            },
            "Nova Esperan\u00e7a": {
                "lat": -6.7333,
                "lng": 15.9
            },
            "Cuemba": {
                "lat": -12.15,
                "lng": 18.0833
            },
            "Porto Alexandre": {
                "lat": -15.8,
                "lng": 11.85
            },
            "Ucuma": {
                "lat": -12.85,
                "lng": 15.0667
            },
            "Luquembo": {
                "lat": -10.7333,
                "lng": 17.8833
            },
            "Cangola": {
                "lat": -7.9667,
                "lng": 15.8667
            },
            "Cambundi Catembo": {
                "lat": -10.0756,
                "lng": 17.5569
            },
            "Cangandala": {
                "lat": -9.7833,
                "lng": 16.4333
            },
            "Cacongo": {
                "lat": -5.2333,
                "lng": 12.1333
            },
            "Tomb\u00f4co": {
                "lat": -6.8,
                "lng": 13.3
            },
            "Mucaba": {
                "lat": -7.1667,
                "lng": 14.9333
            },
            "Cuchi": {
                "lat": -14.65,
                "lng": 16.9
            },
            "Cuito Cuanavale": {
                "lat": -15.1667,
                "lng": 19.1667
            },
            "Cacuso": {
                "lat": -8.2333,
                "lng": 15.35
            },
            "Puri": {
                "lat": -7.6833,
                "lng": 15.6
            },
            "Quilevo": {
                "lat": -7.6167,
                "lng": 15.05
            },
            "Golungo Alto": {
                "lat": -9.1333,
                "lng": 14.7667
            },
            "Muconda": {
                "lat": -10.6,
                "lng": 21.3167
            },
            "Bembe": {
                "lat": -7.1,
                "lng": 14.45
            },
            "Massango": {
                "lat": -11.3167,
                "lng": 14.9333
            },
            "Buco Zau": {
                "lat": -4.95,
                "lng": 12.6167
            },
            "Virei": {
                "lat": -15.7167,
                "lng": 12.95
            },
            "Cacolo": {
                "lat": -10.1333,
                "lng": 19.2667
            },
            "Tchindjendje": {
                "lat": -12.8167,
                "lng": 14.9333
            },
            "Mucari": {
                "lat": -9.4667,
                "lng": 16.9167
            },
            "Dala": {
                "lat": -11.0342,
                "lng": 20.2036
            },
            "Marimba": {
                "lat": -8.3667,
                "lng": 17.0167
            },
            "N\u00f3qui": {
                "lat": -5.8681,
                "lng": 13.4325
            },
            "Ambriz": {
                "lat": -7.85,
                "lng": 13.1167
            },
            "Lucala": {
                "lat": -9.6333,
                "lng": 14.0667
            },
            "Samba Cango": {
                "lat": -9.1,
                "lng": 15.55
            },
            "Caombo": {
                "lat": -8.7,
                "lng": 16.5167
            },
            "Quirima": {
                "lat": -10.9,
                "lng": 18.0833
            },
            "Alto-Cuilo": {
                "lat": -10.05,
                "lng": 19.5167
            },
            "Cuilo": {
                "lat": -8.2371,
                "lng": 19.5238
            },
            "Quela": {
                "lat": -9.3833,
                "lng": 17.05
            },
            "Luacano": {
                "lat": -11.2167,
                "lng": 21.65
            },
            "Lubalo": {
                "lat": -9.15,
                "lng": 19.2833
            },
            "Quibaxi": {
                "lat": -8.5,
                "lng": 14.5833
            },
            "Ambiula": {
                "lat": -7.4333,
                "lng": 14.65
            },
            "Quimavango": {
                "lat": -7.62,
                "lng": 13.03
            },
            "Bolongongo": {
                "lat": -8.4667,
                "lng": 15.25
            },
            "Cunda di\u00e1 Baze": {
                "lat": -8.9167,
                "lng": 17.0667
            },
            "Nzeto": {
                "lat": -7.2333,
                "lng": 12.8667
            },
            "Songo": {
                "lat": -7.3496,
                "lng": 14.85
            },
            "Bibala": {
                "lat": -14.7667,
                "lng": 13.35
            },
            "Banga": {
                "lat": -8.7333,
                "lng": 15.1667
            },
            "Quiculungo": {
                "lat": -8.5167,
                "lng": 15.3167
            },
            "Chibemba": {
                "lat": -15.7355,
                "lng": 13.8905
            },
            "Bala Cangamba": {
                "lat": -13.6833,
                "lng": 19.8667
            },
            "Calenga": {
                "lat": -11.3196,
                "lng": 16.2
            },
            "Tchitado": {
                "lat": -17.3167,
                "lng": 13.9167
            },
            "Cazombo": {
                "lat": -11.9058,
                "lng": 22.9217
            }
        }
    },
    "MY": {
        "name": "Malaysia",
        "cities": {
            "Kuala Lumpur": {
                "lat": 3.1478,
                "lng": 101.6953
            },
            "Seberang Jaya": {
                "lat": 5.4083,
                "lng": 100.3695
            },
            "Klang": {
                "lat": 3.0333,
                "lng": 101.45
            },
            "Ipoh": {
                "lat": 4.5972,
                "lng": 101.075
            },
            "George Town": {
                "lat": 5.4144,
                "lng": 100.3292
            },
            "Petaling Jaya": {
                "lat": 3.0972,
                "lng": 101.6444
            },
            "Kuantan": {
                "lat": 3.8167,
                "lng": 103.3333
            },
            "Shah Alam": {
                "lat": 3.0722,
                "lng": 101.5167
            },
            "Sungai Petani": {
                "lat": 5.65,
                "lng": 100.5
            },
            "Johor Bahru": {
                "lat": 1.4556,
                "lng": 103.7611
            },
            "Kota Bharu": {
                "lat": 6.1333,
                "lng": 102.25
            },
            "Melaka": {
                "lat": 2.1944,
                "lng": 102.2486
            },
            "Kota Kinabalu": {
                "lat": 5.975,
                "lng": 116.0725
            },
            "Seremban": {
                "lat": 2.7222,
                "lng": 101.9417
            },
            "Sandakan": {
                "lat": 5.8333,
                "lng": 118.1167
            },
            "Kuching": {
                "lat": 1.5575,
                "lng": 110.3439
            },
            "Kluang": {
                "lat": 2.0336,
                "lng": 103.3194
            },
            "Muar": {
                "lat": 2.05,
                "lng": 102.5667
            },
            "Pasir Gudang": {
                "lat": 1.5028,
                "lng": 103.9356
            },
            "Kuala Terengganu": {
                "lat": 5.3292,
                "lng": 103.1361
            },
            "Sibu": {
                "lat": 2.2878,
                "lng": 111.8308
            },
            "Taiping": {
                "lat": 4.85,
                "lng": 100.7333
            },
            "Kajang": {
                "lat": 2.9931,
                "lng": 101.7889
            },
            "Miri": {
                "lat": 4.3925,
                "lng": 113.9861
            },
            "Teluk Intan": {
                "lat": 4.0333,
                "lng": 101.0167
            },
            "Kulai": {
                "lat": 1.6667,
                "lng": 103.6
            },
            "Alor Setar": {
                "lat": 6.1183,
                "lng": 100.3694
            },
            "Bukit Mertajam": {
                "lat": 5.3655,
                "lng": 100.4607
            },
            "Lahad Datu": {
                "lat": 5.03,
                "lng": 118.34
            },
            "Segamat": {
                "lat": 2.5111,
                "lng": 102.8139
            },
            "Tumpat": {
                "lat": 6.1667,
                "lng": 102.1667
            },
            "Keningau": {
                "lat": 5.3333,
                "lng": 116.1667
            },
            "Batu Pahat": {
                "lat": 1.8522,
                "lng": 102.9372
            },
            "Batu Gajah": {
                "lat": 4.4667,
                "lng": 101.05
            },
            "Tuaran": {
                "lat": 6.1833,
                "lng": 116.2333
            },
            "Bayan Lepas": {
                "lat": 5.2833,
                "lng": 100.2667
            },
            "Port Dickson": {
                "lat": 2.5167,
                "lng": 101.8
            },
            "Bintulu": {
                "lat": 3.1733,
                "lng": 113.0433
            },
            "Tawau": {
                "lat": 4.2583,
                "lng": 117.8944
            },
            "Simanggang": {
                "lat": 1.2353,
                "lng": 111.4697
            },
            "Labuan": {
                "lat": 5.3,
                "lng": 115.22
            },
            "Raub": {
                "lat": 3.7952,
                "lng": 101.856
            },
            "Marudi": {
                "lat": 4.1833,
                "lng": 114.3167
            },
            "Cukai": {
                "lat": 4.2332,
                "lng": 103.4479
            },
            "Butterworth": {
                "lat": 5.3942,
                "lng": 100.3664
            },
            "Putrajaya": {
                "lat": 2.93,
                "lng": 101.69
            },
            "Taman Senai": {
                "lat": 1.5833,
                "lng": 103.6333
            },
            "Taman Johor Jaya": {
                "lat": 1.5333,
                "lng": 103.8167
            },
            "Machang": {
                "lat": 5.7608,
                "lng": 102.2158
            },
            "Limbang": {
                "lat": 4.7592,
                "lng": 115.0067
            },
            "Sarikei": {
                "lat": 2.1256,
                "lng": 111.5219
            },
            "Tangkak": {
                "lat": 2.2667,
                "lng": 102.55
            },
            "Kangar": {
                "lat": 6.4333,
                "lng": 100.2
            },
            "Rengam": {
                "lat": 1.8833,
                "lng": 103.4
            },
            "Lawas": {
                "lat": 4.8333,
                "lng": 115.4
            },
            "Cameron Highlands": {
                "lat": 4.5,
                "lng": 101.5
            },
            "Bukit Gambir": {
                "lat": 2.2167,
                "lng": 102.6667
            },
            "Kampung Baharu Nilai": {
                "lat": 2.8167,
                "lng": 101.8
            },
            "Long Lama": {
                "lat": 3.7644,
                "lng": 114.4039
            },
            "Simpang Renggam": {
                "lat": 1.8333,
                "lng": 103.3167
            },
            "Kuah": {
                "lat": 6.3167,
                "lng": 99.85
            },
            "Kudat": {
                "lat": 6.8833,
                "lng": 116.8333
            },
            "Lubok Antu": {
                "lat": 1.05,
                "lng": 111.8333
            },
            "Parit Buntar": {
                "lat": 5.1167,
                "lng": 100.5
            },
            "Bidur": {
                "lat": 4.1167,
                "lng": 101.2833
            },
            "Membakut": {
                "lat": 5.4667,
                "lng": 115.7833
            },
            "Kuala Lipis": {
                "lat": 4.184,
                "lng": 102.042
            },
            "Perai": {
                "lat": 5.3779,
                "lng": 100.3992
            },
            "Beaufort": {
                "lat": 5.3458,
                "lng": 115.7446
            },
            "Padang Besar": {
                "lat": 6.6626,
                "lng": 100.3217
            }
        }
    },
    "ZA": {
        "name": "South Africa",
        "cities": {
            "Johannesburg": {
                "lat": -26.2044,
                "lng": 28.0456
            },
            "Cape Town": {
                "lat": -33.9253,
                "lng": 18.4239
            },
            "Soweto": {
                "lat": -26.2678,
                "lng": 27.8585
            },
            "Gqeberha": {
                "lat": -33.9581,
                "lng": 25.6
            },
            "Pietermaritzburg": {
                "lat": -29.6167,
                "lng": 30.3833
            },
            "Durban": {
                "lat": -29.8833,
                "lng": 31.05
            },
            "Pretoria": {
                "lat": -25.7461,
                "lng": 28.1881
            },
            "Rustenburg": {
                "lat": -25.6667,
                "lng": 27.2428
            },
            "Newcastle": {
                "lat": -27.7464,
                "lng": 29.9328
            },
            "East London": {
                "lat": -33.0175,
                "lng": 27.9047
            },
            "Katlehong": {
                "lat": -26.3333,
                "lng": 28.15
            },
            "Khayelitsha": {
                "lat": -34.0403,
                "lng": 18.6778
            },
            "Randburg": {
                "lat": -26.0936,
                "lng": 28.0064
            },
            "Roodepoort": {
                "lat": -26.1625,
                "lng": 27.8725
            },
            "Mitchells Plain": {
                "lat": -34.0506,
                "lng": 18.6181
            },
            "Boksburg": {
                "lat": -26.2125,
                "lng": 28.2625
            },
            "Bloemfontein": {
                "lat": -29.1167,
                "lng": 26.2167
            },
            "Germiston": {
                "lat": -26.2178,
                "lng": 28.1672
            },
            "Centurion": {
                "lat": -25.8603,
                "lng": 28.1894
            },
            "Kimberley": {
                "lat": -28.7383,
                "lng": 24.7639
            },
            "Sandton": {
                "lat": -26.107,
                "lng": 28.0517
            },
            "Klerksdorp": {
                "lat": -26.8667,
                "lng": 26.6667
            },
            "Bethelsdorp": {
                "lat": -33.8833,
                "lng": 25.5
            },
            "Kempton Park": {
                "lat": -26.1,
                "lng": 28.2333
            },
            "Nqutu": {
                "lat": -28.232,
                "lng": 30.566
            },
            "Kroonstad": {
                "lat": -27.65,
                "lng": 27.2333
            },
            "Benoni": {
                "lat": -26.1883,
                "lng": 28.3206
            },
            "George": {
                "lat": -33.9667,
                "lng": 22.45
            },
            "Potchefstroom": {
                "lat": -26.715,
                "lng": 27.1033
            },
            "Pinetown": {
                "lat": -29.8167,
                "lng": 30.85
            },
            "Krugersdorp": {
                "lat": -26.1,
                "lng": 27.7667
            },
            "Mthatha": {
                "lat": -31.58,
                "lng": 28.79
            },
            "Polokwane": {
                "lat": -23.9,
                "lng": 29.45
            },
            "Springs": {
                "lat": -26.2547,
                "lng": 28.4428
            },
            "Alberton": {
                "lat": -26.2672,
                "lng": 28.1219
            },
            "Upington": {
                "lat": -28.45,
                "lng": 21.25
            },
            "Winterveld": {
                "lat": -25.42,
                "lng": 27.949
            },
            "Parow": {
                "lat": -33.9,
                "lng": 18.6
            },
            "Paarl": {
                "lat": -33.7242,
                "lng": 18.9558
            },
            "Empangeni": {
                "lat": -28.75,
                "lng": 31.9
            },
            "Witbank": {
                "lat": -25.877,
                "lng": 29.201
            },
            "Uitenhage": {
                "lat": -33.7667,
                "lng": 25.4
            },
            "KwaDukuza": {
                "lat": -29.3333,
                "lng": 31.2917
            },
            "Vereeniging": {
                "lat": -26.6736,
                "lng": 27.9319
            },
            "Worcester": {
                "lat": -33.645,
                "lng": 19.4436
            },
            "Vanderbijlpark": {
                "lat": -26.6992,
                "lng": 27.8356
            },
            "Grahamstown": {
                "lat": -33.2996,
                "lng": 26.52
            },
            "Oudtshoorn": {
                "lat": -33.5833,
                "lng": 22.2
            },
            "Midrand": {
                "lat": -25.9992,
                "lng": 28.1264
            },
            "Middelburg": {
                "lat": -31.4939,
                "lng": 25.0172
            },
            "Ermelo": {
                "lat": -26.5333,
                "lng": 29.9833
            },
            "Randfontein": {
                "lat": -26.1797,
                "lng": 27.7042
            },
            "Stellenbosch": {
                "lat": -33.9367,
                "lng": 18.8614
            },
            "Sasolburg": {
                "lat": -26.8142,
                "lng": 27.8286
            },
            "Brakpan": {
                "lat": -26.2353,
                "lng": 28.37
            },
            "Thaba Nchu": {
                "lat": -29.2,
                "lng": 26.8333
            },
            "Kwamhlanga": {
                "lat": -25.432,
                "lng": 28.708
            },
            "Thohoyandou": {
                "lat": -22.95,
                "lng": 30.4833
            },
            "Queenstown": {
                "lat": -31.9,
                "lng": 26.8833
            },
            "Virginia": {
                "lat": -28.1064,
                "lng": 26.8622
            },
            "Ladysmith": {
                "lat": -28.5597,
                "lng": 29.7806
            },
            "Welkom": {
                "lat": -27.9831,
                "lng": 26.7208
            },
            "Odendaalsrus": {
                "lat": -27.8667,
                "lng": 26.6833
            },
            "Bethal": {
                "lat": -26.45,
                "lng": 29.45
            },
            "Mossel Bay": {
                "lat": -34.1833,
                "lng": 22.1333
            },
            "Richards Bay": {
                "lat": -28.7694,
                "lng": 32.0594
            },
            "Wellington": {
                "lat": -33.6333,
                "lng": 18.9833
            },
            "Meyerton": {
                "lat": -26.5583,
                "lng": 28.0197
            },
            "Queensburgh": {
                "lat": -29.8667,
                "lng": 30.9333
            },
            "Phuthaditjhaba": {
                "lat": -28.5333,
                "lng": 28.8167
            },
            "Brits": {
                "lat": -25.6344,
                "lng": 27.7811
            },
            "Harrismith": {
                "lat": -28.2833,
                "lng": 29.1333
            },
            "Kokstad": {
                "lat": -30.5539,
                "lng": 29.4269
            },
            "Knysna": {
                "lat": -34.0356,
                "lng": 23.0489
            },
            "Edenvale": {
                "lat": -26.1411,
                "lng": 28.1528
            },
            "Vryheid": {
                "lat": -27.7669,
                "lng": 30.8
            },
            "Kuilsrivier": {
                "lat": -33.9414,
                "lng": 18.7066
            },
            "Bothaville": {
                "lat": -27.3833,
                "lng": 26.6167
            },
            "Parys": {
                "lat": -26.9,
                "lng": 27.45
            },
            "Grabouw": {
                "lat": -34.15,
                "lng": 19.0167
            },
            "Musina": {
                "lat": -22.3381,
                "lng": 30.0417
            },
            "Schweizer-Reineke": {
                "lat": -27.1833,
                "lng": 25.3333
            },
            "Secunda": {
                "lat": -26.5161,
                "lng": 29.2028
            },
            "Despatch": {
                "lat": -33.8015,
                "lng": 25.4768
            },
            "Vredenburg": {
                "lat": -32.9064,
                "lng": 17.9958
            },
            "Nigel": {
                "lat": -26.4203,
                "lng": 28.4683
            },
            "Mmabatho": {
                "lat": -25.85,
                "lng": 25.6333
            },
            "Lydenburg": {
                "lat": -25.096,
                "lng": 30.446
            },
            "Siyabuswa": {
                "lat": -25.1167,
                "lng": 29.05
            },
            "Makan": {
                "lat": -25.605,
                "lng": 27.91
            },
            "Namakgale": {
                "lat": -23.938,
                "lng": 31.028
            },
            "Malmesbury": {
                "lat": -33.45,
                "lng": 18.7333
            },
            "Graaff-Reinet": {
                "lat": -32.2522,
                "lng": 24.5406
            },
            "Port Shepstone": {
                "lat": -30.75,
                "lng": 30.45
            },
            "Lebowakgomo": {
                "lat": -24.305,
                "lng": 29.565
            },
            "Dundee": {
                "lat": -28.1725,
                "lng": 30.2314
            },
            "Phokeng": {
                "lat": -25.5833,
                "lng": 27.1333
            },
            "Beaufort West": {
                "lat": -32.35,
                "lng": 22.5833
            },
            "Mankoeng": {
                "lat": -23.886,
                "lng": 29.718
            },
            "Acornhoek": {
                "lat": -24.593,
                "lng": 31.097
            },
            "Ceres": {
                "lat": -33.3667,
                "lng": 19.3167
            },
            "Plettenberg Bay": {
                "lat": -34.05,
                "lng": 23.3667
            },
            "Westville": {
                "lat": -29.831,
                "lng": 30.925
            },
            "Postmasburg": {
                "lat": -28.3279,
                "lng": 23.0706
            },
            "Kloof": {
                "lat": -29.7833,
                "lng": 30.8333
            },
            "Ulundi": {
                "lat": -28.3167,
                "lng": 31.4167
            },
            "Humansdorp": {
                "lat": -34.0333,
                "lng": 24.7667
            },
            "Mondlo": {
                "lat": -27.967,
                "lng": 30.722
            },
            "Thabazimbi": {
                "lat": -24.6,
                "lng": 27.4
            },
            "Saldanha": {
                "lat": -32.9978,
                "lng": 17.9456
            },
            "Heilbron": {
                "lat": -27.2836,
                "lng": 27.9708
            },
            "Jeffrey\u2019s Bay": {
                "lat": -34.0333,
                "lng": 24.9167
            },
            "Wesselsbron": {
                "lat": -27.85,
                "lng": 26.3667
            },
            "Margate": {
                "lat": -30.85,
                "lng": 30.3667
            },
            "Dinokana": {
                "lat": -25.447,
                "lng": 25.863
            },
            "Lichtenburg": {
                "lat": -26.15,
                "lng": 26.1667
            },
            "Frankfort": {
                "lat": -27.2833,
                "lng": 28.5167
            },
            "Giyani": {
                "lat": -23.31,
                "lng": 30.7064
            },
            "Mathibestad": {
                "lat": -25.276,
                "lng": 28.178
            },
            "Port Alfred": {
                "lat": -33.5917,
                "lng": 26.8875
            },
            "Mpophomeni": {
                "lat": -29.567,
                "lng": 30.182
            },
            "Fort Beaufort": {
                "lat": -32.7789,
                "lng": 26.6353
            },
            "Louis Trichardt": {
                "lat": -23.05,
                "lng": 29.9
            },
            "Stutterheim": {
                "lat": -32.5667,
                "lng": 27.4167
            },
            "Hennenman": {
                "lat": -27.9667,
                "lng": 27.0333
            },
            "Volksrust": {
                "lat": -27.3667,
                "lng": 29.8833
            },
            "uMhlanga Rocks": {
                "lat": -29.7333,
                "lng": 31.0708
            },
            "Jan Kempdorp": {
                "lat": -27.9194,
                "lng": 24.8361
            },
            "De Aar": {
                "lat": -30.65,
                "lng": 24.0167
            },
            "Bultfontein": {
                "lat": -28.2869,
                "lng": 26.1506
            },
            "Ka-Bungeni": {
                "lat": -23.206,
                "lng": 30.224
            },
            "Carletonville": {
                "lat": -26.3581,
                "lng": 27.3981
            },
            "Warrenton": {
                "lat": -28.1114,
                "lng": 24.8497
            },
            "Nkowakowa": {
                "lat": -23.886,
                "lng": 30.293
            },
            "Hartbeespoort": {
                "lat": -25.7442,
                "lng": 27.8994
            },
            "Estcourt": {
                "lat": -29.0,
                "lng": 29.8833
            },
            "Robertson": {
                "lat": -33.8,
                "lng": 19.8833
            },
            "Pampierstad": {
                "lat": -27.776,
                "lng": 24.69
            },
            "Howick": {
                "lat": -29.4667,
                "lng": 30.2333
            },
            "Hammanskraal": {
                "lat": -25.4,
                "lng": 28.2833
            },
            "Vryburg": {
                "lat": -26.9586,
                "lng": 24.7322
            },
            "Bronkhorstspruit": {
                "lat": -25.805,
                "lng": 28.7464
            },
            "Reitz": {
                "lat": -27.8,
                "lng": 28.4333
            },
            "Barkly West": {
                "lat": -28.5381,
                "lng": 24.5186
            },
            "Douglas": {
                "lat": -29.05,
                "lng": 23.7667
            },
            "Indwe": {
                "lat": -31.466,
                "lng": 27.333
            },
            "Itsoseng": {
                "lat": -26.083,
                "lng": 25.882
            },
            "Garsfontein": {
                "lat": -25.7913,
                "lng": 28.2935
            },
            "Deneysville": {
                "lat": -26.895,
                "lng": 28.0983
            },
            "Setlagode": {
                "lat": -26.287,
                "lng": 25.117
            },
            "Allanridge": {
                "lat": -27.7544,
                "lng": 26.6367
            },
            "Ganyesa": {
                "lat": -26.5908,
                "lng": 24.1594
            },
            "Ballitoville": {
                "lat": -29.5333,
                "lng": 31.2167
            },
            "Somerset East": {
                "lat": -32.7167,
                "lng": 25.5833
            },
            "Taung": {
                "lat": -27.5625,
                "lng": 24.7481
            },
            "Vredendal": {
                "lat": -31.6644,
                "lng": 18.5061
            },
            "Stilfontein": {
                "lat": -26.8428,
                "lng": 26.7744
            },
            "Mooirivier": {
                "lat": -29.2,
                "lng": 29.9833
            },
            "Vrede": {
                "lat": -27.43,
                "lng": 29.16
            },
            "Glencoe": {
                "lat": -28.1833,
                "lng": 30.15
            },
            "Swellendam": {
                "lat": -34.0231,
                "lng": 20.44
            },
            "Villiers": {
                "lat": -27.0333,
                "lng": 28.6
            },
            "Colesberg": {
                "lat": -30.7167,
                "lng": 25.1
            },
            "Kingsborough": {
                "lat": -30.0833,
                "lng": 30.8667
            },
            "Bethlehem": {
                "lat": -28.224,
                "lng": 28.311
            },
            "eMuziwezinto": {
                "lat": -30.3167,
                "lng": 30.6667
            },
            "Riversdale": {
                "lat": -34.0969,
                "lng": 21.2622
            },
            "Bethulie": {
                "lat": -30.4667,
                "lng": 25.9667
            },
            "Turffontein": {
                "lat": -26.2446,
                "lng": 28.0397
            },
            "Bredasdorp": {
                "lat": -34.5333,
                "lng": 20.0417
            },
            "Kinross": {
                "lat": -26.4167,
                "lng": 29.0833
            },
            "Kriel": {
                "lat": -26.255,
                "lng": 29.263
            },
            "Montagu": {
                "lat": -33.7833,
                "lng": 20.1167
            },
            "Alice": {
                "lat": -32.7892,
                "lng": 26.835
            },
            "Mahikeng": {
                "lat": -25.8656,
                "lng": 25.6436
            },
            "Makapanstad": {
                "lat": -25.239,
                "lng": 28.116
            },
            "Eshowe": {
                "lat": -28.8883,
                "lng": 31.4483
            },
            "Vredefort": {
                "lat": -27.0167,
                "lng": 27.3833
            },
            "Tzaneen": {
                "lat": -23.8333,
                "lng": 30.1667
            },
            "Edenburg": {
                "lat": -29.7347,
                "lng": 25.9369
            },
            "Breyten": {
                "lat": -26.3,
                "lng": 29.9833
            },
            "Morokweng": {
                "lat": -26.127,
                "lng": 23.775
            },
            "Prieska": {
                "lat": -29.6683,
                "lng": 22.7439
            },
            "Primrose": {
                "lat": -26.1833,
                "lng": 28.1667
            },
            "Bedfordview": {
                "lat": -26.1794,
                "lng": 28.1361
            },
            "eManzimtoti": {
                "lat": -30.05,
                "lng": 30.8833
            },
            "Koppies": {
                "lat": -27.2333,
                "lng": 27.5833
            },
            "Kirkwood": {
                "lat": -33.4003,
                "lng": 25.4425
            },
            "Dani\u00eblskuil": {
                "lat": -28.2,
                "lng": 23.5833
            },
            "Orkney": {
                "lat": -26.9789,
                "lng": 26.6692
            },
            "Makwassie": {
                "lat": -27.3167,
                "lng": 25.9833
            },
            "Hillcrest": {
                "lat": -29.78,
                "lng": 30.7628
            },
            "Ashton": {
                "lat": -33.8347,
                "lng": 20.0547
            },
            "Okkerneutboom": {
                "lat": -24.595,
                "lng": 31.139
            },
            "Phalaborwa": {
                "lat": -23.9333,
                "lng": 31.1167
            },
            "Kuruman": {
                "lat": -27.45,
                "lng": 23.4333
            },
            "Caledon": {
                "lat": -34.23,
                "lng": 19.4283
            },
            "Fouriesburg": {
                "lat": -28.6227,
                "lng": 28.2109
            },
            "Pretoria-Noord": {
                "lat": -25.6731,
                "lng": 28.1733
            },
            "Elandsdoorn": {
                "lat": -25.288,
                "lng": 29.196
            },
            "Petrus Steyn": {
                "lat": -27.65,
                "lng": 28.1333
            },
            "Moorreesburg": {
                "lat": -33.15,
                "lng": 18.6667
            },
            "Springbok": {
                "lat": -29.6667,
                "lng": 17.8833
            },
            "Casteel": {
                "lat": -24.7247,
                "lng": 31.0201
            },
            "Buffelshoek": {
                "lat": -24.6308,
                "lng": 31.1383
            },
            "Groutville": {
                "lat": -29.388,
                "lng": 31.245
            },
            "Summerstrand": {
                "lat": -33.9914,
                "lng": 25.6569
            },
            "New Germany": {
                "lat": -29.8,
                "lng": 30.8833
            },
            "Jeppe\u2019s Reef": {
                "lat": -25.72,
                "lng": 31.477
            },
            "Dysselsdorp": {
                "lat": -33.5667,
                "lng": 22.4333
            },
            "Matatiele": {
                "lat": -30.3422,
                "lng": 28.8061
            },
            "eXobho": {
                "lat": -30.1572,
                "lng": 30.0647
            },
            "Amersfoort": {
                "lat": -27.0078,
                "lng": 29.8711
            },
            "Adelaide": {
                "lat": -32.7078,
                "lng": 26.2956
            },
            "Lenyenye": {
                "lat": -23.972,
                "lng": 30.269
            },
            "Piketberg": {
                "lat": -32.9,
                "lng": 18.7667
            },
            "Keimoes": {
                "lat": -28.7,
                "lng": 20.9667
            },
            "Barberton": {
                "lat": -25.7861,
                "lng": 31.0531
            },
            "Hankey": {
                "lat": -33.8314,
                "lng": 24.8808
            },
            "Gansbaai": {
                "lat": -34.5828,
                "lng": 19.3522
            },
            "Molteno": {
                "lat": -31.3961,
                "lng": 26.3631
            },
            "Saint Helena Bay": {
                "lat": -32.7583,
                "lng": 18.0278
            },
            "Kathu": {
                "lat": -27.7,
                "lng": 23.05
            },
            "Mount Fletcher": {
                "lat": -30.692,
                "lng": 28.503
            },
            "Gonubie": {
                "lat": -32.943,
                "lng": 28.008
            },
            "Mogogelo": {
                "lat": -25.354,
                "lng": 28.138
            },
            "Scottburgh": {
                "lat": -30.2833,
                "lng": 30.75
            },
            "Vaal Reefs": {
                "lat": -26.929,
                "lng": 26.736
            },
            "De Doorns": {
                "lat": -33.4833,
                "lng": 19.6833
            },
            "Riebeeckstad": {
                "lat": -27.9188,
                "lng": 26.8188
            },
            "Ventersburg": {
                "lat": -28.0833,
                "lng": 27.1333
            },
            "Bhisho": {
                "lat": -32.8494,
                "lng": 27.4381
            },
            "Langeloop": {
                "lat": -25.684,
                "lng": 31.635
            },
            "Ekuvukeni": {
                "lat": -28.466,
                "lng": 30.157
            },
            "Velddrif": {
                "lat": -32.7833,
                "lng": 18.1667
            },
            "Sannieshof": {
                "lat": -26.5333,
                "lng": 25.8
            },
            "Warden": {
                "lat": -27.8539,
                "lng": 28.9667
            },
            "Mogwase": {
                "lat": -25.276,
                "lng": 27.216
            },
            "Delareyville": {
                "lat": -26.6833,
                "lng": 25.4667
            },
            "Groot-Brakrivier": {
                "lat": -34.0425,
                "lng": 22.2314
            },
            "Standerton": {
                "lat": -26.95,
                "lng": 29.25
            },
            "Hartswater": {
                "lat": -27.7667,
                "lng": 24.8167
            },
            "Darling": {
                "lat": -33.3833,
                "lng": 18.3833
            },
            "Koffiefontein": {
                "lat": -29.4081,
                "lng": 25.0022
            },
            "Delportshoop": {
                "lat": -28.4167,
                "lng": 24.3
            },
            "Hopetown": {
                "lat": -29.6258,
                "lng": 24.0856
            },
            "Westonaria": {
                "lat": -26.3178,
                "lng": 27.6506
            },
            "Olifantshoek": {
                "lat": -27.9333,
                "lng": 22.7333
            },
            "Evander": {
                "lat": -26.4719,
                "lng": 29.11
            },
            "Alexandria": {
                "lat": -33.6533,
                "lng": 26.4083
            },
            "Villiersdorp": {
                "lat": -33.9908,
                "lng": 19.2894
            },
            "Barkly East": {
                "lat": -30.9681,
                "lng": 27.5933
            },
            "Koloti": {
                "lat": -23.746,
                "lng": 29.307
            },
            "Kraaipan": {
                "lat": -26.294,
                "lng": 25.297
            },
            "Ga-Kgapane": {
                "lat": -23.649,
                "lng": 30.226
            },
            "Ncora": {
                "lat": -31.7938,
                "lng": 27.7579
            },
            "Sitebe": {
                "lat": -29.089,
                "lng": 31.432
            },
            "Wepener": {
                "lat": -29.7333,
                "lng": 27.0333
            },
            "Harding": {
                "lat": -30.5833,
                "lng": 29.8833
            },
            "Kakamas": {
                "lat": -28.8,
                "lng": 20.65
            },
            "Fochville": {
                "lat": -26.4833,
                "lng": 27.4833
            },
            "Hertzogville": {
                "lat": -28.1333,
                "lng": 25.5
            },
            "Mabeskraal": {
                "lat": -25.196,
                "lng": 26.803
            },
            "Boekenhouthoek": {
                "lat": -25.303,
                "lng": 29.015
            },
            "Sabie": {
                "lat": -25.0978,
                "lng": 30.7792
            },
            "Steynsrus": {
                "lat": -27.95,
                "lng": 27.5667
            },
            "Zeerust": {
                "lat": -25.5333,
                "lng": 26.0833
            },
            "Bonnievale": {
                "lat": -33.9275,
                "lng": 20.1006
            },
            "Greytown": {
                "lat": -29.0667,
                "lng": 30.5833
            },
            "Mbuzini": {
                "lat": -25.9333,
                "lng": 31.9333
            },
            "Tulbagh": {
                "lat": -33.285,
                "lng": 19.1378
            },
            "Machadodorp": {
                "lat": -25.6667,
                "lng": 30.25
            },
            "Cofimvaba": {
                "lat": -32.0025,
                "lng": 27.5806
            },
            "Doornkop": {
                "lat": -26.2328,
                "lng": 27.7833
            },
            "Cullinan": {
                "lat": -25.6728,
                "lng": 28.5208
            },
            "Gillitts": {
                "lat": -29.7972,
                "lng": 30.7944
            },
            "Cradock": {
                "lat": -32.165,
                "lng": 25.6175
            },
            "Boshof": {
                "lat": -28.55,
                "lng": 25.2333
            },
            "Carnarvon": {
                "lat": -30.9667,
                "lng": 22.1333
            },
            "Port Saint John\u2019s": {
                "lat": -31.6288,
                "lng": 29.5369
            },
            "Vanrhynsdorp": {
                "lat": -31.6167,
                "lng": 18.7333
            },
            "Hermanus": {
                "lat": -34.4167,
                "lng": 19.25
            },
            "Komatipoort": {
                "lat": -25.4333,
                "lng": 31.95
            },
            "Aliwal North": {
                "lat": -30.7,
                "lng": 26.7
            },
            "Pofadder": {
                "lat": -29.1286,
                "lng": 19.3947
            },
            "Brandfort": {
                "lat": -28.7014,
                "lng": 26.4589
            },
            "Bloemhof": {
                "lat": -27.65,
                "lng": 25.59
            },
            "Alexander Bay": {
                "lat": -28.6083,
                "lng": 16.5033
            },
            "Ubombo": {
                "lat": -27.5667,
                "lng": 32.0833
            },
            "Nelspruit": {
                "lat": -25.4745,
                "lng": 30.9703
            }
        }
    },
    "CO": {
        "name": "Colombia",
        "cities": {
            "Bogot\u00e1": {
                "lat": 4.7111,
                "lng": -74.0722
            },
            "Medell\u00edn": {
                "lat": 6.2308,
                "lng": -75.5906
            },
            "Cali": {
                "lat": 3.4206,
                "lng": -76.5222
            },
            "Barranquilla": {
                "lat": 10.9833,
                "lng": -74.8019
            },
            "Cartagena": {
                "lat": 10.4,
                "lng": -75.5
            },
            "Bucaramanga": {
                "lat": 7.1333,
                "lng": -73.0
            },
            "C\u00facuta": {
                "lat": 7.8942,
                "lng": -72.5039
            },
            "Palermo": {
                "lat": 2.8917,
                "lng": -75.4375
            },
            "Soledad": {
                "lat": 10.9167,
                "lng": -74.75
            },
            "Villavicencio": {
                "lat": 4.15,
                "lng": -73.6333
            },
            "Pereira": {
                "lat": 4.8143,
                "lng": -75.6946
            },
            "Bello": {
                "lat": 6.3333,
                "lng": -75.5667
            },
            "Valledupar": {
                "lat": 10.4833,
                "lng": -73.25
            },
            "Ibagu\u00e9": {
                "lat": 4.4333,
                "lng": -75.2333
            },
            "Monter\u00eda": {
                "lat": 8.75,
                "lng": -75.8833
            },
            "Soacha": {
                "lat": 4.5872,
                "lng": -74.2214
            },
            "Santa Marta": {
                "lat": 11.2419,
                "lng": -74.2053
            },
            "Manizales": {
                "lat": 5.1,
                "lng": -75.55
            },
            "Buenaventura": {
                "lat": 3.8772,
                "lng": -77.0267
            },
            "Pasto": {
                "lat": 1.2078,
                "lng": -77.2772
            },
            "Neiva": {
                "lat": 2.9345,
                "lng": -75.2809
            },
            "Palmira": {
                "lat": 3.5833,
                "lng": -76.25
            },
            "Fontib\u00f3n": {
                "lat": 4.6786,
                "lng": -74.1411
            },
            "Usme": {
                "lat": 4.4464,
                "lng": -74.1522
            },
            "Armenia": {
                "lat": 4.53,
                "lng": -75.68
            },
            "Popay\u00e1n": {
                "lat": 2.4542,
                "lng": -76.6092
            },
            "Floridablanca": {
                "lat": 7.2167,
                "lng": -73.0667
            },
            "Sincelejo": {
                "lat": 9.295,
                "lng": -75.3961
            },
            "Itag\u00fc\u00ed": {
                "lat": 6.1667,
                "lng": -75.6167
            },
            "Envigado": {
                "lat": 6.1667,
                "lng": -75.5667
            },
            "Tulu\u00e1": {
                "lat": 4.0833,
                "lng": -76.2
            },
            "Tumaco": {
                "lat": 1.8067,
                "lng": -78.7647
            },
            "Barrancabermeja": {
                "lat": 7.0667,
                "lng": -73.85
            },
            "Uripa": {
                "lat": 11.9167,
                "lng": -72.0
            },
            "Zipaquir\u00e1": {
                "lat": 5.0333,
                "lng": -74.0
            },
            "Florencia": {
                "lat": 1.6139,
                "lng": -75.6128
            },
            "Turbo": {
                "lat": 8.1,
                "lng": -76.7333
            },
            "Santander de Quilichao": {
                "lat": 3.0167,
                "lng": -76.4833
            },
            "Dosquebradas": {
                "lat": 4.8333,
                "lng": -75.6833
            },
            "Fusagasug\u00e1": {
                "lat": 4.3452,
                "lng": -74.3618
            },
            "R\u00edohacha": {
                "lat": 11.5442,
                "lng": -72.9069
            },
            "Ipiales": {
                "lat": 0.8303,
                "lng": -77.6444
            },
            "Tunja": {
                "lat": 5.5333,
                "lng": -73.3667
            },
            "Gir\u00f3n": {
                "lat": 7.0708,
                "lng": -73.1731
            },
            "Piedecuesta": {
                "lat": 7.0833,
                "lng": -73.0
            },
            "Facatativ\u00e1": {
                "lat": 4.8167,
                "lng": -74.3667
            },
            "Cartago": {
                "lat": 4.7,
                "lng": -75.9167
            },
            "Malambo": {
                "lat": 10.85,
                "lng": -74.75
            },
            "Rionegro": {
                "lat": 6.1535,
                "lng": -75.3743
            },
            "Pitalito": {
                "lat": 1.8989,
                "lng": -76.0419
            },
            "Quibd\u00f3": {
                "lat": 5.6922,
                "lng": -76.6581
            },
            "Jamund\u00ed": {
                "lat": 3.2667,
                "lng": -76.55
            },
            "Girardot": {
                "lat": 4.305,
                "lng": -74.8017
            },
            "Ci\u00e9naga": {
                "lat": 11.0069,
                "lng": -74.25
            },
            "Guadalajara de Buga": {
                "lat": 3.9,
                "lng": -76.3019
            },
            "Mosquera": {
                "lat": 2.4903,
                "lng": -78.4953
            },
            "Duitama": {
                "lat": 5.8333,
                "lng": -73.0167
            },
            "Ch\u00eda": {
                "lat": 4.85,
                "lng": -74.05
            },
            "Magangu\u00e9": {
                "lat": 9.25,
                "lng": -74.7667
            },
            "Maicao": {
                "lat": 11.3778,
                "lng": -72.2389
            },
            "Apartad\u00f3": {
                "lat": 7.8833,
                "lng": -76.6333
            },
            "Uribia": {
                "lat": 11.9167,
                "lng": -72.0
            },
            "Oca\u00f1a": {
                "lat": 8.2333,
                "lng": -73.35
            },
            "Sogamoso": {
                "lat": 5.7167,
                "lng": -72.9208
            },
            "Turbaco": {
                "lat": 10.35,
                "lng": -75.3333
            },
            "Madrid": {
                "lat": 4.7344,
                "lng": -74.2683
            },
            "Aguachica": {
                "lat": 8.3167,
                "lng": -73.6333
            },
            "Yopal": {
                "lat": 5.35,
                "lng": -72.41
            },
            "Sabanalarga": {
                "lat": 10.63,
                "lng": -74.9236
            },
            "Arauca": {
                "lat": 7.0903,
                "lng": -70.7617
            },
            "Candelaria": {
                "lat": 10.4592,
                "lng": -74.8806
            },
            "Yumbo": {
                "lat": 3.585,
                "lng": -76.4958
            },
            "Ceret\u00e9": {
                "lat": 8.8833,
                "lng": -75.8
            },
            "Los Patios": {
                "lat": 7.8333,
                "lng": -72.5167
            },
            "Funza": {
                "lat": 4.7167,
                "lng": -74.2167
            },
            "Fundaci\u00f3n": {
                "lat": 10.5214,
                "lng": -74.1867
            },
            "Sahag\u00fan": {
                "lat": 8.95,
                "lng": -75.45
            },
            "Caucasia": {
                "lat": 7.9833,
                "lng": -75.2
            },
            "Montel\u00edbano": {
                "lat": 7.975,
                "lng": -75.4208
            },
            "Caldas": {
                "lat": 6.09,
                "lng": -75.638
            },
            "Chiquinquir\u00e1": {
                "lat": 5.6333,
                "lng": -73.75
            },
            "Chigorod\u00f3": {
                "lat": 7.6675,
                "lng": -76.6808
            },
            "Sabaneta": {
                "lat": 6.15,
                "lng": -75.6
            },
            "Tierralta": {
                "lat": 7.9167,
                "lng": -76.1667
            },
            "Espinal": {
                "lat": 4.2,
                "lng": -74.8333
            },
            "Arjona": {
                "lat": 10.2586,
                "lng": -75.3461
            },
            "Acac\u00edas": {
                "lat": 3.9878,
                "lng": -73.7597
            },
            "Copacabana": {
                "lat": 6.3333,
                "lng": -75.5
            },
            "Santa Rosa de Cabal": {
                "lat": 4.8667,
                "lng": -75.6167
            },
            "San Vicente del Cagu\u00e1n": {
                "lat": 2.1167,
                "lng": -74.7667
            },
            "La Dorada": {
                "lat": 0.3436,
                "lng": -76.9108
            },
            "Turban\u00e1": {
                "lat": 10.2833,
                "lng": -75.45
            },
            "El Banco": {
                "lat": 8.9983,
                "lng": -73.9747
            },
            "San Andr\u00e9s": {
                "lat": 12.5847,
                "lng": -81.7006
            },
            "Calarc\u00e1": {
                "lat": 4.5333,
                "lng": -75.65
            },
            "La Estrella": {
                "lat": 6.1667,
                "lng": -75.6667
            },
            "Corozal": {
                "lat": 9.3333,
                "lng": -75.25
            },
            "Villa del Rosario": {
                "lat": 7.8353,
                "lng": -72.4736
            },
            "Garz\u00f3n": {
                "lat": 2.1819,
                "lng": -75.6578
            },
            "Planeta Rica": {
                "lat": 8.4089,
                "lng": -75.5819
            },
            "Necocl\u00ed": {
                "lat": 8.4167,
                "lng": -76.7833
            },
            "San Jos\u00e9 del Guaviare": {
                "lat": 2.5667,
                "lng": -72.6333
            },
            "Manaure": {
                "lat": 11.7792,
                "lng": -72.4494
            },
            "El Carmen de Bol\u00edvar": {
                "lat": 9.7167,
                "lng": -75.1333
            },
            "La Ceja": {
                "lat": 6.0306,
                "lng": -75.4317
            },
            "Marinilla": {
                "lat": 6.1738,
                "lng": -75.3347
            },
            "Ci\u00e9naga de Oro": {
                "lat": 8.8833,
                "lng": -75.6167
            },
            "Puerto As\u00eds": {
                "lat": 0.5167,
                "lng": -76.5
            },
            "Carmen de Viboral": {
                "lat": 6.0833,
                "lng": -75.3333
            },
            "Riosucio": {
                "lat": 7.4406,
                "lng": -77.1189
            },
            "Plato": {
                "lat": 9.7919,
                "lng": -74.7872
            },
            "La Plata": {
                "lat": 2.39,
                "lng": -75.9075
            },
            "Villamar\u00eda": {
                "lat": 5.0,
                "lng": -75.5
            },
            "Galapa": {
                "lat": 10.9167,
                "lng": -74.8333
            },
            "San Marcos": {
                "lat": 8.6611,
                "lng": -75.1347
            },
            "Florida": {
                "lat": 3.3275,
                "lng": -76.2386
            },
            "Pradera": {
                "lat": 3.4167,
                "lng": -76.1667
            },
            "Orito": {
                "lat": 0.6661,
                "lng": -76.8708
            },
            "Puerto Boyac\u00e1": {
                "lat": 6.0,
                "lng": -74.4167
            },
            "El Cerrito": {
                "lat": 3.6667,
                "lng": -76.1667
            },
            "Mocoa": {
                "lat": 1.15,
                "lng": -76.6475
            },
            "Saravena": {
                "lat": 6.9556,
                "lng": -71.8722
            },
            "Valle del Guamuez": {
                "lat": 0.4253,
                "lng": -76.9053
            },
            "Cajic\u00e1": {
                "lat": 4.9167,
                "lng": -74.0333
            },
            "Puerto Libertador": {
                "lat": 7.9067,
                "lng": -75.6733
            },
            "Tame": {
                "lat": 6.4667,
                "lng": -71.7333
            },
            "Barbosa": {
                "lat": 5.933,
                "lng": -73.621
            },
            "Lorica": {
                "lat": 9.2419,
                "lng": -75.816
            },
            "San Onofre": {
                "lat": 9.7333,
                "lng": -75.5333
            },
            "San Gil": {
                "lat": 6.5592,
                "lng": -73.135
            },
            "El Bagre": {
                "lat": 7.5942,
                "lng": -74.8119
            },
            "Baranoa": {
                "lat": 10.8,
                "lng": -74.9167
            },
            "Girardota": {
                "lat": 6.3764,
                "lng": -75.4473
            },
            "Guarne": {
                "lat": 6.28,
                "lng": -75.4427
            },
            "Chinchin\u00e1": {
                "lat": 4.9825,
                "lng": -75.6036
            },
            "Chin\u00fa": {
                "lat": 9.0833,
                "lng": -75.3333
            },
            "Pamplona": {
                "lat": 7.3781,
                "lng": -72.6525
            },
            "Cimitarra": {
                "lat": 6.3167,
                "lng": -73.95
            },
            "San Juan del Cesar": {
                "lat": 10.7667,
                "lng": -73.0
            },
            "Mar\u00eda la Baja": {
                "lat": 10.0,
                "lng": -75.3333
            },
            "Agust\u00edn Codazzi": {
                "lat": 10.0367,
                "lng": -73.2369
            },
            "Samaniego": {
                "lat": 1.35,
                "lng": -77.6
            },
            "Carepa": {
                "lat": 7.7578,
                "lng": -76.6553
            },
            "La Vega": {
                "lat": 4.9992,
                "lng": -74.3411
            },
            "Chaparral": {
                "lat": 3.7236,
                "lng": -75.4847
            },
            "San Andr\u00e9s de Sotavento": {
                "lat": 9.15,
                "lng": -75.5
            },
            "Ayapel": {
                "lat": 8.3167,
                "lng": -75.15
            },
            "Urrao": {
                "lat": 6.3156,
                "lng": -76.1342
            },
            "Zarzal": {
                "lat": 4.3983,
                "lng": -76.0772
            },
            "Valencia": {
                "lat": 8.2592,
                "lng": -76.1469
            },
            "La Jagua de Ibirico": {
                "lat": 9.5667,
                "lng": -73.3333
            },
            "Puerto Tejada": {
                "lat": 3.25,
                "lng": -76.4167
            },
            "Ortega": {
                "lat": 3.9167,
                "lng": -75.25
            },
            "San Pelayo": {
                "lat": 8.9667,
                "lng": -75.85
            },
            "Bol\u00edvar": {
                "lat": 5.9911,
                "lng": -73.7739
            },
            "Arboletes": {
                "lat": 8.8517,
                "lng": -76.4283
            },
            "Piendam\u00f3": {
                "lat": 2.6408,
                "lng": -76.5284
            },
            "Villeta": {
                "lat": 5.0083,
                "lng": -74.4738
            },
            "Andes": {
                "lat": 5.5833,
                "lng": -75.9167
            },
            "Aguazul": {
                "lat": 5.1731,
                "lng": -72.5547
            },
            "Cumaribo": {
                "lat": 4.4333,
                "lng": -69.8
            },
            "Puerto Colombia": {
                "lat": 11.0167,
                "lng": -74.8833
            },
            "Miranda": {
                "lat": 3.25,
                "lng": -76.25
            },
            "El Charco": {
                "lat": 2.4775,
                "lng": -78.1111
            },
            "C\u00e1ceres": {
                "lat": 7.6667,
                "lng": -75.3333
            },
            "Santa Rosa del Sur": {
                "lat": 7.9667,
                "lng": -74.05
            },
            "Montenegro": {
                "lat": 4.5671,
                "lng": -75.7504
            },
            "Pueblo Nuevo": {
                "lat": 8.5008,
                "lng": -75.5072
            },
            "Yarumal": {
                "lat": 7.0306,
                "lng": -75.5905
            },
            "Puerto Gait\u00e1n": {
                "lat": 4.3142,
                "lng": -72.0825
            },
            "Tuch\u00edn": {
                "lat": 9.1833,
                "lng": -75.55
            },
            "Arauquita": {
                "lat": 7.0303,
                "lng": -71.4356
            },
            "T\u00faquerres": {
                "lat": 1.0833,
                "lng": -77.6167
            },
            "Sevilla": {
                "lat": 4.2689,
                "lng": -75.9361
            },
            "Guaduas": {
                "lat": 5.0694,
                "lng": -74.5981
            },
            "Segovia": {
                "lat": 7.0781,
                "lng": -74.7017
            },
            "Pie de Pat\u00f3": {
                "lat": 5.5333,
                "lng": -77.0
            },
            "Bosconia": {
                "lat": 9.9761,
                "lng": -73.8903
            },
            "Cumbal": {
                "lat": 0.9078,
                "lng": -77.7908
            },
            "Aracataca": {
                "lat": 10.5937,
                "lng": -74.1928
            },
            "La Uni\u00f3n": {
                "lat": 8.8606,
                "lng": -75.2806
            },
            "Acevedo": {
                "lat": 1.8192,
                "lng": -75.8964
            },
            "L\u00edbano": {
                "lat": 4.9167,
                "lng": -75.1667
            },
            "Fonseca": {
                "lat": 10.8333,
                "lng": -72.8333
            },
            "Villa de San Diego de Ubat\u00e9": {
                "lat": 5.3072,
                "lng": -73.8144
            },
            "La Tebaida": {
                "lat": 4.45,
                "lng": -75.8
            },
            "Puerto Berr\u00edo": {
                "lat": 6.49,
                "lng": -74.4025
            },
            "Taraz\u00e1": {
                "lat": 7.5881,
                "lng": -75.4028
            },
            "Barbacoas": {
                "lat": 1.6717,
                "lng": -78.1397
            },
            "Cajib\u00edo": {
                "lat": 2.6333,
                "lng": -76.6333
            },
            "\u00c1brego": {
                "lat": 8.0,
                "lng": -73.2
            },
            "Sibat\u00e9": {
                "lat": 4.4908,
                "lng": -74.2594
            },
            "Barrancas": {
                "lat": 11.0,
                "lng": -72.75
            },
            "Sampu\u00e9s": {
                "lat": 9.1833,
                "lng": -75.3833
            },
            "Dibulla": {
                "lat": 11.2667,
                "lng": -73.3
            },
            "Pat\u00eda": {
                "lat": 2.1667,
                "lng": -77.0833
            },
            "San Sebasti\u00e1n de Mariquita": {
                "lat": 5.25,
                "lng": -74.9167
            },
            "El Bordo": {
                "lat": 2.1667,
                "lng": -77.0833
            },
            "Santa Rosa de Osos": {
                "lat": 6.65,
                "lng": -75.4667
            },
            "Tib\u00fa": {
                "lat": 8.65,
                "lng": -72.7333
            },
            "Melgar": {
                "lat": 4.2039,
                "lng": -74.6428
            },
            "Dagua": {
                "lat": 3.6667,
                "lng": -76.7
            },
            "La Macarena": {
                "lat": 2.1828,
                "lng": -73.7847
            },
            "Anserma": {
                "lat": 5.2381,
                "lng": -75.784
            },
            "Belalc\u00e1zar": {
                "lat": 5.0,
                "lng": -75.8167
            },
            "Sons\u00f3n": {
                "lat": 5.7097,
                "lng": -75.3108
            },
            "Tocancip\u00e1": {
                "lat": 4.9657,
                "lng": -73.9136
            },
            "San Pablo": {
                "lat": 1.6725,
                "lng": -77.0139
            },
            "San Bernardo del Viento": {
                "lat": 9.35,
                "lng": -75.95
            },
            "Santiago de Tol\u00fa": {
                "lat": 9.5333,
                "lng": -75.5833
            },
            "Guacar\u00ed": {
                "lat": 3.7667,
                "lng": -76.3333
            },
            "Gigante": {
                "lat": 2.3867,
                "lng": -75.5461
            },
            "Cartagena del Chair\u00e1": {
                "lat": 1.35,
                "lng": -74.8333
            },
            "Santuario": {
                "lat": 5.0725,
                "lng": -75.9623
            },
            "Quimbaya": {
                "lat": 4.6333,
                "lng": -75.75
            },
            "Buenos Aires": {
                "lat": 2.9167,
                "lng": -76.6667
            },
            "Puerto L\u00f3pez": {
                "lat": 4.0872,
                "lng": -72.9606
            },
            "San Luis de Sinc\u00e9": {
                "lat": 9.25,
                "lng": -75.15
            },
            "Campoalegre": {
                "lat": 2.6867,
                "lng": -75.3256
            },
            "Caldono": {
                "lat": 2.8,
                "lng": -76.5333
            },
            "San Agust\u00edn": {
                "lat": 1.9,
                "lng": -76.2833
            },
            "San Juan Nepomuceno": {
                "lat": 9.95,
                "lng": -75.0833
            },
            "Majagual": {
                "lat": 8.5,
                "lng": -74.6667
            },
            "Quinch\u00eda": {
                "lat": 5.3379,
                "lng": -75.7296
            },
            "Timb\u00edo": {
                "lat": 2.3445,
                "lng": -76.6839
            },
            "Puerto Rico": {
                "lat": 2.9383,
                "lng": -73.2083
            },
            "Roldanillo": {
                "lat": 4.4167,
                "lng": -76.15
            },
            "San Antero": {
                "lat": 9.3833,
                "lng": -75.75
            },
            "Leticia": {
                "lat": -4.2167,
                "lng": -69.9333
            },
            "Corinto": {
                "lat": 3.1739,
                "lng": -76.2594
            },
            "Sabanagrande": {
                "lat": 10.8,
                "lng": -74.75
            },
            "Pivijay": {
                "lat": 10.4667,
                "lng": -74.6167
            },
            "Puebloviejo": {
                "lat": 10.9972,
                "lng": -74.2875
            },
            "Bocas de Satinga": {
                "lat": 2.3469,
                "lng": -78.3256
            },
            "Puerto Escondido": {
                "lat": 8.95,
                "lng": -76.25
            },
            "La Mesa": {
                "lat": 4.6303,
                "lng": -74.4625
            },
            "Silvia": {
                "lat": 2.6167,
                "lng": -76.3833
            },
            "Cota": {
                "lat": 4.8167,
                "lng": -74.1
            },
            "El Dif\u00edcil": {
                "lat": 9.85,
                "lng": -74.2386
            },
            "Inz\u00e1": {
                "lat": 2.5503,
                "lng": -76.0636
            },
            "La Virginia": {
                "lat": 4.9167,
                "lng": -75.8333
            },
            "San Pedro de Urab\u00e1": {
                "lat": 8.2833,
                "lng": -76.3833
            },
            "Remedios": {
                "lat": 7.0275,
                "lng": -74.6939
            },
            "Santo Tom\u00e1s": {
                "lat": 10.7667,
                "lng": -74.9167
            },
            "Paipa": {
                "lat": 5.8333,
                "lng": -73.15
            },
            "Guachav\u00e9s": {
                "lat": 1.2219,
                "lng": -77.6772
            },
            "Sitionuevo": {
                "lat": 10.7833,
                "lng": -74.7167
            },
            "Puerto Wilches": {
                "lat": 7.35,
                "lng": -73.9
            },
            "Neira": {
                "lat": 5.1664,
                "lng": -75.5189
            },
            "Guamo": {
                "lat": 4.0833,
                "lng": -74.9167
            },
            "Socorro": {
                "lat": 6.5333,
                "lng": -73.2
            },
            "Torib\u00edo": {
                "lat": 2.9581,
                "lng": -76.2714
            },
            "Amag\u00e1": {
                "lat": 6.05,
                "lng": -75.7
            },
            "Chimichagua": {
                "lat": 9.25,
                "lng": -73.8167
            },
            "Planadas": {
                "lat": 3.1964,
                "lng": -75.6444
            },
            "Fresno": {
                "lat": 5.1556,
                "lng": -75.0403
            },
            "Tabio": {
                "lat": 4.9158,
                "lng": -74.0983
            },
            "Purificaci\u00f3n": {
                "lat": 3.8667,
                "lng": -74.9333
            },
            "Flandes": {
                "lat": 4.2833,
                "lng": -74.8
            },
            "Aipe": {
                "lat": 3.2167,
                "lng": -75.2333
            },
            "Sop\u00f3": {
                "lat": 4.9167,
                "lng": -73.9333
            },
            "Mo\u00f1itos": {
                "lat": 9.2503,
                "lng": -76.1325
            },
            "La Calera": {
                "lat": 4.7531,
                "lng": -73.92
            },
            "Caicedonia": {
                "lat": 4.3333,
                "lng": -75.8333
            },
            "San Carlos": {
                "lat": 6.1897,
                "lng": -74.9969
            },
            "El Copey": {
                "lat": 10.15,
                "lng": -73.9667
            },
            "Isnos": {
                "lat": 1.9333,
                "lng": -76.2333
            },
            "Puerto Legu\u00edzamo": {
                "lat": -0.1939,
                "lng": -74.7806
            },
            "Coyaima": {
                "lat": 3.8333,
                "lng": -75.0833
            },
            "Mit\u00fa": {
                "lat": 1.1983,
                "lng": -70.1733
            },
            "Villanueva": {
                "lat": 10.4456,
                "lng": -75.2761
            },
            "Guamal": {
                "lat": 3.88,
                "lng": -73.7656
            },
            "San Vicente de Chucur\u00ed": {
                "lat": 6.8833,
                "lng": -73.4167
            },
            "Pacho": {
                "lat": 5.1306,
                "lng": -74.1583
            },
            "Palmar de Varela": {
                "lat": 10.75,
                "lng": -74.75
            },
            "Argelia": {
                "lat": 5.7425,
                "lng": -75.1464
            },
            "Sup\u00eda": {
                "lat": 5.4667,
                "lng": -75.65
            },
            "Ciudad Bol\u00edvar": {
                "lat": 5.85,
                "lng": -76.0333
            },
            "Tauramena": {
                "lat": 5.0167,
                "lng": -72.75
            },
            "San Juan de Urab\u00e1": {
                "lat": 8.7667,
                "lng": -76.5333
            },
            "Pueblo Bello": {
                "lat": 10.4167,
                "lng": -73.5833
            },
            "Chiriguan\u00e1": {
                "lat": 9.3667,
                "lng": -73.6
            },
            "Albania": {
                "lat": 11.1597,
                "lng": -72.5856
            },
            "Fortul": {
                "lat": 6.7922,
                "lng": -71.7756
            },
            "Morales": {
                "lat": 8.3133,
                "lng": -73.8719
            },
            "Los C\u00f3rdobas": {
                "lat": 8.9,
                "lng": -76.3667
            },
            "Circasia": {
                "lat": 4.6167,
                "lng": -75.6333
            },
            "San Benito Abad": {
                "lat": 8.9333,
                "lng": -75.0333
            },
            "San Alberto": {
                "lat": 7.7525,
                "lng": -73.3892
            },
            "Balboa": {
                "lat": 2.0436,
                "lng": -77.2197
            },
            "Pensilvania": {
                "lat": 5.5,
                "lng": -75.0833
            },
            "Paz de Ariporo": {
                "lat": 5.8833,
                "lng": -71.9
            },
            "Buesaco": {
                "lat": 1.3833,
                "lng": -77.1667
            },
            "Mahates": {
                "lat": 10.2333,
                "lng": -75.1833
            },
            "Istmina": {
                "lat": 5.1667,
                "lng": -76.6833
            },
            "Santa Ana": {
                "lat": 9.319,
                "lng": -74.57
            },
            "San Mart\u00edn": {
                "lat": 8.0472,
                "lng": -73.5153
            },
            "Nech\u00ed": {
                "lat": 8.1,
                "lng": -74.7833
            },
            "Yolomb\u00f3": {
                "lat": 6.5978,
                "lng": -75.0122
            },
            "Solano": {
                "lat": 0.6983,
                "lng": -75.2539
            },
            "Vistahermosa": {
                "lat": 3.1239,
                "lng": -73.7514
            },
            "San Jos\u00e9": {
                "lat": 1.4744,
                "lng": -77.0836
            },
            "Caloto": {
                "lat": 3.0333,
                "lng": -76.4167
            },
            "Bel\u00e9n de Umbr\u00eda": {
                "lat": 5.2,
                "lng": -75.8667
            },
            "Chocont\u00e1": {
                "lat": 5.1467,
                "lng": -73.6825
            },
            "Sandon\u00e1": {
                "lat": 1.2833,
                "lng": -77.4667
            },
            "Pinillos": {
                "lat": 8.9167,
                "lng": -74.4667
            },
            "Algeciras": {
                "lat": 2.5333,
                "lng": -75.3167
            },
            "Santa Rosa": {
                "lat": 10.4456,
                "lng": -75.3686
            },
            "Calamar": {
                "lat": 1.9206,
                "lng": -72.5542
            },
            "El Retorno": {
                "lat": 2.3306,
                "lng": -72.6278
            },
            "Valdivia": {
                "lat": 7.2938,
                "lng": -75.3919
            },
            "Puerto Guzm\u00e1n": {
                "lat": 0.9703,
                "lng": -76.5858
            },
            "Donmat\u00edas": {
                "lat": 6.4833,
                "lng": -75.4333
            },
            "Rioblanco": {
                "lat": 3.5,
                "lng": -75.8333
            },
            "Marsella": {
                "lat": 4.9167,
                "lng": -75.75
            },
            "Cogua": {
                "lat": 5.0667,
                "lng": -73.9833
            },
            "Zaragoza": {
                "lat": 7.494,
                "lng": -74.871
            },
            "Guap\u00ed": {
                "lat": 2.5703,
                "lng": -77.8856
            },
            "El Zulia": {
                "lat": 7.9333,
                "lng": -72.6
            },
            "Hatonuevo": {
                "lat": 11.0694,
                "lng": -72.7669
            },
            "Honda": {
                "lat": 5.2042,
                "lng": -74.7417
            },
            "Ituango": {
                "lat": 7.1667,
                "lng": -75.75
            },
            "El Paso": {
                "lat": 9.6622,
                "lng": -73.7519
            },
            "Canalete": {
                "lat": 8.79,
                "lng": -76.2411
            },
            "Teorama": {
                "lat": 8.4353,
                "lng": -73.2864
            },
            "Puerto Concordia": {
                "lat": 2.6833,
                "lng": -72.7667
            },
            "Ach\u00ed": {
                "lat": 8.5667,
                "lng": -74.55
            },
            "Curuman\u00ed": {
                "lat": 9.2,
                "lng": -73.55
            },
            "Dabeiba": {
                "lat": 7.0,
                "lng": -76.25
            },
            "Luruaco": {
                "lat": 10.6083,
                "lng": -75.1417
            },
            "Rivera": {
                "lat": 2.7853,
                "lng": -75.2589
            },
            "Guadalupe": {
                "lat": 2.025,
                "lng": -75.7572
            },
            "Ataco": {
                "lat": 3.6,
                "lng": -75.3833
            },
            "Amalfi": {
                "lat": 6.9167,
                "lng": -75.0833
            },
            "Buenavista": {
                "lat": 9.3333,
                "lng": -74.3
            },
            "Manzanares": {
                "lat": 5.2519,
                "lng": -75.1569
            },
            "Santa Fe de Antioquia": {
                "lat": 6.55,
                "lng": -75.8167
            },
            "Sardinata": {
                "lat": 8.0833,
                "lng": -72.8
            },
            "Sabana de Torres": {
                "lat": 7.4,
                "lng": -73.5
            },
            "Mutat\u00e1": {
                "lat": 7.2442,
                "lng": -76.4358
            },
            "Sucre": {
                "lat": 2.0383,
                "lng": -76.9253
            },
            "Natagaima": {
                "lat": 3.5833,
                "lng": -75.0833
            },
            "El Doncello": {
                "lat": 1.6833,
                "lng": -75.2833
            },
            "El Colegio": {
                "lat": 4.5808,
                "lng": -74.4425
            },
            "Repel\u00f3n": {
                "lat": 10.55,
                "lng": -75.1333
            },
            "Pailitas": {
                "lat": 8.9667,
                "lng": -73.6333
            },
            "Timbiqu\u00ed": {
                "lat": 2.7667,
                "lng": -77.6333
            },
            "Tiquisio": {
                "lat": 8.5667,
                "lng": -74.2667
            },
            "Silvania": {
                "lat": 4.4033,
                "lng": -74.3881
            },
            "Taminango": {
                "lat": 1.5667,
                "lng": -77.2833
            },
            "Villa de Leyva": {
                "lat": 5.6333,
                "lng": -73.5333
            },
            "Los Palmitos": {
                "lat": 9.3811,
                "lng": -75.2714
            },
            "Ginebra": {
                "lat": 3.7244,
                "lng": -76.2672
            },
            "Puerto Triunfo": {
                "lat": 5.8667,
                "lng": -74.65
            },
            "San Jacinto": {
                "lat": 9.8311,
                "lng": -75.1219
            },
            "Nueva Granada": {
                "lat": 9.8031,
                "lng": -74.3903
            },
            "Galeras": {
                "lat": 9.1667,
                "lng": -75.05
            },
            "Villagarz\u00f3n": {
                "lat": 1.0294,
                "lng": -76.6164
            },
            "San Bernardo": {
                "lat": 1.5108,
                "lng": -77.0467
            },
            "El Pauj\u00edl": {
                "lat": 1.5644,
                "lng": -75.3319
            },
            "Santa B\u00e1rbara": {
                "lat": 5.8747,
                "lng": -75.5661
            },
            "Cumaral": {
                "lat": 4.2694,
                "lng": -73.4864
            },
            "Almaguer": {
                "lat": 1.9167,
                "lng": -76.8667
            },
            "Aguadas": {
                "lat": 5.6167,
                "lng": -75.4667
            },
            "Montecristo": {
                "lat": 8.2994,
                "lng": -74.4756
            },
            "Suaza": {
                "lat": 1.9833,
                "lng": -75.8167
            },
            "Villapinz\u00f3n": {
                "lat": 5.2167,
                "lng": -73.6
            },
            "Fredonia": {
                "lat": 5.9167,
                "lng": -75.6667
            },
            "El Ret\u00e9n": {
                "lat": 10.6167,
                "lng": -74.2667
            },
            "Bugalagrande": {
                "lat": 4.2167,
                "lng": -76.1667
            },
            "Sotomayor": {
                "lat": 1.4933,
                "lng": -77.5214
            },
            "Moniquir\u00e1": {
                "lat": 5.9167,
                "lng": -73.5
            },
            "Ovejas": {
                "lat": 9.5,
                "lng": -75.1667
            },
            "Samac\u00e1": {
                "lat": 5.5,
                "lng": -73.4833
            },
            "Becerril": {
                "lat": 9.7,
                "lng": -73.2833
            },
            "Timan\u00e1": {
                "lat": 1.9833,
                "lng": -75.95
            },
            "San Lorenzo": {
                "lat": 1.5039,
                "lng": -77.2178
            },
            "In\u00edrida": {
                "lat": 3.8653,
                "lng": -67.9239
            },
            "Simit\u00ed": {
                "lat": 7.9667,
                "lng": -73.95
            },
            "Concordia": {
                "lat": 10.2667,
                "lng": -74.8333
            },
            "Tenjo": {
                "lat": 4.9167,
                "lng": -74.1667
            },
            "Rovira": {
                "lat": 4.25,
                "lng": -75.3333
            },
            "Frontino": {
                "lat": 6.7833,
                "lng": -76.1333
            },
            "Tol\u00fa Viejo": {
                "lat": 9.45,
                "lng": -75.45
            },
            "M\u00e1laga": {
                "lat": 6.7833,
                "lng": -72.6667
            },
            "Su\u00e1rez": {
                "lat": 2.9589,
                "lng": -76.6953
            },
            "Urumita": {
                "lat": 10.5667,
                "lng": -73.0167
            },
            "Venadillo": {
                "lat": 4.75,
                "lng": -74.9167
            },
            "Ansermanuevo": {
                "lat": 4.8,
                "lng": -76.0
            },
            "Polonuevo": {
                "lat": 10.7833,
                "lng": -74.8667
            },
            "El Rosal": {
                "lat": 4.8519,
                "lng": -74.2628
            },
            "Tad\u00f3": {
                "lat": 5.2667,
                "lng": -76.5667
            },
            "San Sebasti\u00e1n de Buenavista": {
                "lat": 9.2403,
                "lng": -74.3556
            },
            "Anzo\u00e1tegui": {
                "lat": 4.6339,
                "lng": -75.0972
            },
            "Guachucal": {
                "lat": 0.9667,
                "lng": -77.7333
            },
            "V\u00e9lez": {
                "lat": 6.0103,
                "lng": -73.6763
            },
            "Tocaima": {
                "lat": 4.5,
                "lng": -74.6667
            },
            "Villa Rica": {
                "lat": 3.1736,
                "lng": -76.4631
            },
            "Salamina": {
                "lat": 5.4083,
                "lng": -75.49
            },
            "Pizarro": {
                "lat": 4.95,
                "lng": -77.3667
            },
            "San Diego": {
                "lat": 10.3375,
                "lng": -73.1825
            },
            "Astrea": {
                "lat": 9.5,
                "lng": -73.9833
            },
            "Ponedera": {
                "lat": 10.65,
                "lng": -74.75
            },
            "Campo de la Cruz": {
                "lat": 10.3778,
                "lng": -74.8814
            },
            "Trujillo": {
                "lat": 4.25,
                "lng": -76.3333
            },
            "Andaluc\u00eda": {
                "lat": 4.1667,
                "lng": -76.1667
            },
            "San Pedro": {
                "lat": 8.7333,
                "lng": -74.7167
            },
            "Convenci\u00f3n": {
                "lat": 8.8333,
                "lng": -73.2
            },
            "Ricaurte": {
                "lat": 4.2792,
                "lng": -74.7728
            },
            "San Vicente": {
                "lat": 6.2819,
                "lng": -75.3322
            },
            "Pelaya": {
                "lat": 8.6833,
                "lng": -73.6667
            },
            "Salgar": {
                "lat": 5.9617,
                "lng": -75.9753
            },
            "El Carmen de Chucur\u00ed": {
                "lat": 6.6981,
                "lng": -73.5111
            },
            "La Primavera": {
                "lat": 5.4906,
                "lng": -70.4092
            },
            "Totor\u00f3": {
                "lat": 2.5117,
                "lng": -76.4003
            },
            "Restrepo": {
                "lat": 3.825,
                "lng": -76.525
            },
            "Abejorral": {
                "lat": 5.7894,
                "lng": -75.4281
            },
            "Yond\u00f3": {
                "lat": 7.0,
                "lng": -73.9167
            },
            "Mistrat\u00f3": {
                "lat": 5.3,
                "lng": -75.8833
            },
            "Saman\u00e1": {
                "lat": 5.5833,
                "lng": -74.9167
            },
            "Guaranda": {
                "lat": 8.4672,
                "lng": -74.5367
            },
            "Suesca": {
                "lat": 5.1,
                "lng": -73.8
            },
            "Betulia": {
                "lat": 6.1122,
                "lng": -75.9839
            },
            "Cajamarca": {
                "lat": 4.4167,
                "lng": -75.5
            },
            "T\u00edogollo": {
                "lat": 10.3408,
                "lng": -74.7333
            },
            "Toledo": {
                "lat": 7.3131,
                "lng": -72.4875
            },
            "L\u00e9rida": {
                "lat": 4.9,
                "lng": -74.9167
            },
            "C\u00e1queza": {
                "lat": 4.4053,
                "lng": -73.9478
            },
            "Gachancip\u00e1": {
                "lat": 4.9908,
                "lng": -73.8731
            },
            "Retiro": {
                "lat": 6.0572,
                "lng": -75.5025
            },
            "Garagoa": {
                "lat": 5.0825,
                "lng": -73.3642
            },
            "San Roque": {
                "lat": 6.4853,
                "lng": -75.0197
            },
            "Puerto Carre\u00f1o": {
                "lat": 6.1903,
                "lng": -67.4836
            },
            "El Pi\u00f1\u00f3n": {
                "lat": 10.3333,
                "lng": -74.6667
            },
            "La Cruz": {
                "lat": 1.6047,
                "lng": -76.9742
            },
            "San Estanislao": {
                "lat": 10.4,
                "lng": -75.15
            },
            "Anor\u00ed": {
                "lat": 7.0736,
                "lng": -75.1458
            },
            "Pupiales": {
                "lat": 0.8667,
                "lng": -77.65
            },
            "Toro": {
                "lat": 4.6117,
                "lng": -76.0814
            },
            "Jambal\u00f3": {
                "lat": 2.85,
                "lng": -76.3167
            },
            "Chin\u00e1cota": {
                "lat": 7.6167,
                "lng": -72.6
            },
            "Nobsa": {
                "lat": 5.7667,
                "lng": -72.95
            },
            "Momil": {
                "lat": 9.2333,
                "lng": -75.6833
            },
            "Yotoco": {
                "lat": 3.8667,
                "lng": -76.3833
            },
            "El Pe\u00f1ol": {
                "lat": 6.2186,
                "lng": -75.2433
            },
            "Cotorra": {
                "lat": 9.05,
                "lng": -75.8
            },
            "Subachoque": {
                "lat": 4.9281,
                "lng": -74.1736
            },
            "Tarqui": {
                "lat": 2.1106,
                "lng": -75.8231
            },
            "Aquitania": {
                "lat": 5.5833,
                "lng": -72.8333
            },
            "Dari\u00e9n": {
                "lat": 3.9167,
                "lng": -76.6667
            },
            "Chivolo": {
                "lat": 10.0261,
                "lng": -74.6211
            },
            "Piji\u00f1o del Carmen": {
                "lat": 9.3333,
                "lng": -74.45
            },
            "Guasca": {
                "lat": 4.8658,
                "lng": -73.8772
            },
            "La Monta\u00f1ita": {
                "lat": 1.5833,
                "lng": -75.25
            },
            "Ventaquemada": {
                "lat": 5.4167,
                "lng": -73.5
            },
            "Land\u00e1zuri": {
                "lat": 6.2181,
                "lng": -73.8114
            },
            "T\u00e1mesis": {
                "lat": 5.6667,
                "lng": -75.7167
            },
            "La Apartada": {
                "lat": 8.1006,
                "lng": -75.3825
            },
            "Puerto Salgar": {
                "lat": 5.5,
                "lng": -74.5833
            },
            "La Gloria": {
                "lat": 8.5833,
                "lng": -73.5833
            },
            "Obando": {
                "lat": 4.5833,
                "lng": -75.9167
            },
            "Coconuco": {
                "lat": 2.25,
                "lng": -76.4167
            },
            "Monterrey": {
                "lat": 4.8783,
                "lng": -72.8972
            },
            "Mercaderes": {
                "lat": 1.8,
                "lng": -77.1667
            },
            "Cocorn\u00e1": {
                "lat": 6.0569,
                "lng": -75.1853
            },
            "Riofr\u00edo": {
                "lat": 4.1561,
                "lng": -76.2878
            },
            "Pur\u00edsima de la Concepci\u00f3n": {
                "lat": 9.2333,
                "lng": -75.7333
            },
            "Condoto": {
                "lat": 5.1,
                "lng": -76.65
            },
            "C\u00f3mbita": {
                "lat": 5.75,
                "lng": -73.25
            },
            "Anapoima": {
                "lat": 4.5503,
                "lng": -74.5361
            },
            "San Mart\u00edn de Loba": {
                "lat": 8.8333,
                "lng": -73.9167
            },
            "Sopetr\u00e1n": {
                "lat": 6.5017,
                "lng": -75.7433
            },
            "Barranco de Loba": {
                "lat": 8.95,
                "lng": -74.1167
            },
            "Morroa": {
                "lat": 9.3333,
                "lng": -75.3167
            },
            "San Antonio": {
                "lat": 10.0,
                "lng": -74.6667
            },
            "Salda\u00f1a": {
                "lat": 3.9347,
                "lng": -75.0203
            },
            "Tenerife": {
                "lat": 9.8989,
                "lng": -74.8589
            },
            "Algarrobo": {
                "lat": 10.1,
                "lng": -74.2667
            },
            "Juan de Acosta": {
                "lat": 10.8333,
                "lng": -75.0333
            },
            "Sibundoy": {
                "lat": 1.2033,
                "lng": -76.9192
            },
            "Titirib\u00ed": {
                "lat": 6.0667,
                "lng": -75.8
            },
            "Tibasosa": {
                "lat": 5.8333,
                "lng": -72.9667
            },
            "El Carmen de Atrato": {
                "lat": 5.8983,
                "lng": -76.1431
            },
            "R\u00edo de Oro": {
                "lat": 8.0,
                "lng": -73.5
            },
            "Santa Catalina": {
                "lat": 10.6039,
                "lng": -75.2878
            },
            "C\u00f3rdoba": {
                "lat": 9.5867,
                "lng": -74.8272
            },
            "Sesquil\u00e9": {
                "lat": 5.0453,
                "lng": -73.7972
            },
            "Tamalameque": {
                "lat": 8.8333,
                "lng": -73.5833
            },
            "Clemencia": {
                "lat": 10.5833,
                "lng": -75.3333
            },
            "Jard\u00edn": {
                "lat": 5.5986,
                "lng": -75.8194
            },
            "Palmito": {
                "lat": 9.3333,
                "lng": -75.55
            },
            "Altos del Rosario": {
                "lat": 8.8,
                "lng": -74.1667
            },
            "Castilla La Nueva": {
                "lat": 3.8333,
                "lng": -73.6833
            },
            "Ca\u00f1asgordas": {
                "lat": 6.7497,
                "lng": -76.0258
            },
            "R\u00e1quira": {
                "lat": 5.5333,
                "lng": -73.6333
            },
            "Palestina": {
                "lat": 1.75,
                "lng": -76.0667
            },
            "Chim\u00e1": {
                "lat": 9.15,
                "lng": -75.6333
            },
            "Nemoc\u00f3n": {
                "lat": 5.05,
                "lng": -73.8833
            },
            "Manat\u00ed": {
                "lat": 10.445,
                "lng": -74.9597
            },
            "San Juan de Betulia": {
                "lat": 9.2756,
                "lng": -75.2456
            },
            "San Jacinto del Cauca": {
                "lat": 8.25,
                "lng": -74.7167
            },
            "Rosas": {
                "lat": 2.2667,
                "lng": -76.75
            },
            "Man\u00ed": {
                "lat": 4.8167,
                "lng": -72.2833
            },
            "El Tabl\u00f3n": {
                "lat": 1.4269,
                "lng": -77.0969
            },
            "La Victoria": {
                "lat": 4.5214,
                "lng": -76.0364
            },
            "P\u00e1cora": {
                "lat": 5.5258,
                "lng": -75.4589
            },
            "Bagad\u00f3": {
                "lat": 5.4167,
                "lng": -76.4167
            },
            "San Jer\u00f3nimo": {
                "lat": 6.4417,
                "lng": -75.7278
            },
            "Cicuco": {
                "lat": 9.2667,
                "lng": -74.65
            },
            "San Antonio del Tequendama": {
                "lat": 4.6328,
                "lng": -74.3522
            },
            "Simijaca": {
                "lat": 5.5019,
                "lng": -73.8519
            },
            "Anolaima": {
                "lat": 4.7617,
                "lng": -74.464
            },
            "Alcal\u00e1": {
                "lat": 4.6667,
                "lng": -75.75
            },
            "Ap\u00eda": {
                "lat": 5.1,
                "lng": -75.95
            },
            "Santa B\u00e1rbara de Pinto": {
                "lat": 9.4333,
                "lng": -74.7
            },
            "Viot\u00e1": {
                "lat": 4.4381,
                "lng": -74.5239
            },
            "San Rafael": {
                "lat": 6.2975,
                "lng": -75.0314
            },
            "La Argentina": {
                "lat": 2.1961,
                "lng": -75.98
            },
            "El Tambo": {
                "lat": 1.4131,
                "lng": -77.3981
            },
            "Gamarra": {
                "lat": 8.3333,
                "lng": -73.6667
            },
            "Los Santos": {
                "lat": 6.9167,
                "lng": -73.0833
            },
            "Viterbo": {
                "lat": 5.0667,
                "lng": -75.8833
            },
            "Gu\u00e1tica": {
                "lat": 5.3167,
                "lng": -75.8
            },
            "Pueblo Rico": {
                "lat": 5.25,
                "lng": -76.1667
            },
            "Chachag\u00fc\u00ed": {
                "lat": 1.3605,
                "lng": -77.2835
            },
            "Saboy\u00e1": {
                "lat": 5.7,
                "lng": -73.7667
            },
            "Susa": {
                "lat": 5.4528,
                "lng": -73.814
            },
            "Arbel\u00e1ez": {
                "lat": 4.2725,
                "lng": -74.4151
            },
            "Hatillo de Loba": {
                "lat": 8.9586,
                "lng": -74.0808
            },
            "Pital": {
                "lat": 2.987,
                "lng": -75.826
            },
            "F\u00f3meque": {
                "lat": 4.4847,
                "lng": -73.8936
            },
            "Ungu\u00eda": {
                "lat": 8.05,
                "lng": -77.1
            },
            "Pasca": {
                "lat": 4.3075,
                "lng": -74.3008
            },
            "Puerto Nare": {
                "lat": 6.1917,
                "lng": -74.5867
            },
            "Eb\u00e9jico": {
                "lat": 6.3333,
                "lng": -75.7667
            },
            "Marquetalia": {
                "lat": 5.3333,
                "lng": -75.0
            },
            "Jeric\u00f3": {
                "lat": 5.7833,
                "lng": -75.7833
            },
            "Acand\u00ed": {
                "lat": 8.5333,
                "lng": -77.2333
            },
            "Caimito": {
                "lat": 8.8333,
                "lng": -75.1667
            },
            "Filandia": {
                "lat": 4.6667,
                "lng": -75.6333
            },
            "Curit\u00ed": {
                "lat": 6.6667,
                "lng": -73.0
            },
            "Oiba": {
                "lat": 6.2667,
                "lng": -73.3
            },
            "El Carmen": {
                "lat": 8.5128,
                "lng": -73.4508
            },
            "Paispamba": {
                "lat": 2.25,
                "lng": -76.5833
            },
            "Distracci\u00f3n": {
                "lat": 10.9,
                "lng": -72.8833
            },
            "Trinidad": {
                "lat": 5.4089,
                "lng": -71.6622
            },
            "Puente Nacional": {
                "lat": 5.8833,
                "lng": -73.6833
            },
            "Fuente de Oro": {
                "lat": 3.4667,
                "lng": -73.6167
            },
            "Santa Luc\u00eda": {
                "lat": 10.3167,
                "lng": -74.95
            },
            "Santa Genoveva de Docord\u00f3": {
                "lat": 4.2586,
                "lng": -77.3675
            },
            "Cisneros": {
                "lat": 6.5383,
                "lng": -75.0886
            },
            "San Carlos de Guaroa": {
                "lat": 3.7111,
                "lng": -73.2425
            },
            "Guayabal": {
                "lat": 4.9633,
                "lng": -74.9056
            },
            "Hato Corozal": {
                "lat": 3.1833,
                "lng": -73.75
            },
            "Guachet\u00e1": {
                "lat": 5.3856,
                "lng": -73.6856
            },
            "Zambrano": {
                "lat": 9.75,
                "lng": -74.8333
            },
            "San Jos\u00e9 del Fragua": {
                "lat": 1.3286,
                "lng": -75.9728
            },
            "Talaigua Nuevo": {
                "lat": 9.3069,
                "lng": -74.5686
            },
            "Santa Rosa de Viterbo": {
                "lat": 5.8833,
                "lng": -72.9833
            },
            "Cove\u00f1as": {
                "lat": 9.4167,
                "lng": -75.7
            },
            "El Play\u00f3n": {
                "lat": 7.4767,
                "lng": -73.2081
            },
            "Llor\u00f3": {
                "lat": 5.5,
                "lng": -76.5333
            },
            "Puerto Caicedo": {
                "lat": 0.685,
                "lng": -76.6044
            },
            "Oporapa": {
                "lat": 2.05,
                "lng": -75.9667
            },
            "Gachet\u00e1": {
                "lat": 4.8176,
                "lng": -73.636
            },
            "Bel\u00e9n de los Andaqu\u00edes": {
                "lat": 1.4161,
                "lng": -75.8725
            },
            "El \u00c1guila": {
                "lat": 4.9167,
                "lng": -76.0833
            },
            "La Esperanza": {
                "lat": 7.6392,
                "lng": -73.3358
            },
            "La Palma": {
                "lat": 5.3606,
                "lng": -74.3897
            },
            "Vijes": {
                "lat": 3.7,
                "lng": -76.45
            },
            "Agua de Dios": {
                "lat": 4.3781,
                "lng": -74.6739
            },
            "C\u00e1chira": {
                "lat": 7.75,
                "lng": -73.1667
            },
            "Tarr\u00e1": {
                "lat": 8.0475,
                "lng": -73.0836
            },
            "El Tarra": {
                "lat": 8.5756,
                "lng": -73.0944
            },
            "Yacop\u00ed": {
                "lat": 5.4667,
                "lng": -74.3333
            },
            "Mogotes": {
                "lat": 6.4833,
                "lng": -72.9667
            },
            "Icononzo": {
                "lat": 4.1833,
                "lng": -74.5333
            },
            "Guaitarilla": {
                "lat": 1.1333,
                "lng": -77.5564
            },
            "Santo Domingo": {
                "lat": 6.4708,
                "lng": -75.1658
            },
            "Choach\u00ed": {
                "lat": 4.5297,
                "lng": -73.9236
            },
            "Ubal\u00e1": {
                "lat": 4.7439,
                "lng": -73.5347
            },
            "Villahermosa": {
                "lat": 5.0,
                "lng": -75.1667
            },
            "Tubar\u00e1": {
                "lat": 10.8667,
                "lng": -74.9833
            },
            "Yacuanquer": {
                "lat": 1.1167,
                "lng": -77.4
            },
            "Lejan\u00edas": {
                "lat": 3.5268,
                "lng": -74.0232
            },
            "Chitag\u00e1": {
                "lat": 7.1333,
                "lng": -72.6667
            },
            "Angostura": {
                "lat": 6.8667,
                "lng": -75.3333
            },
            "Regidor": {
                "lat": 8.6667,
                "lng": -73.8333
            },
            "\u00dambita": {
                "lat": 5.2167,
                "lng": -73.4667
            },
            "Caparrap\u00ed": {
                "lat": 5.3442,
                "lng": -74.4917
            },
            "Venecia": {
                "lat": 5.9167,
                "lng": -75.75
            },
            "Tello": {
                "lat": 3.0667,
                "lng": -75.1333
            },
            "Lenguazaque": {
                "lat": 5.3069,
                "lng": -73.7061
            },
            "Puerto Santander": {
                "lat": 8.3636,
                "lng": -72.4075
            },
            "Santa Mar\u00eda": {
                "lat": 2.95,
                "lng": -75.65
            },
            "Charal\u00e1": {
                "lat": 6.25,
                "lng": -73.0833
            },
            "Medina": {
                "lat": 4.5092,
                "lng": -73.3494
            },
            "Potos\u00ed": {
                "lat": 0.8081,
                "lng": -77.5728
            },
            "Toca": {
                "lat": 5.5667,
                "lng": -73.2
            },
            "G\u00e9nova": {
                "lat": 1.6442,
                "lng": -77.0194
            },
            "Granada": {
                "lat": 4.5186,
                "lng": -74.3514
            },
            "Saladoblanco": {
                "lat": 2.0167,
                "lng": -76.05
            },
            "Ramiriqu\u00ed": {
                "lat": 5.4,
                "lng": -73.3333
            },
            "Suaita": {
                "lat": 6.1019,
                "lng": -73.4406
            },
            "El Cairo": {
                "lat": 4.75,
                "lng": -76.25
            },
            "Vegach\u00ed": {
                "lat": 6.7731,
                "lng": -74.7994
            },
            "San Calixto": {
                "lat": 8.4,
                "lng": -73.2167
            },
            "Bellavista": {
                "lat": 6.5236,
                "lng": -76.9744
            },
            "Padilla": {
                "lat": 3.2333,
                "lng": -76.3167
            },
            "La Sierra": {
                "lat": 2.25,
                "lng": -76.8333
            },
            "Arroyohondo": {
                "lat": 10.1333,
                "lng": -75.5667
            },
            "Coello": {
                "lat": 4.3333,
                "lng": -74.9167
            },
            "Margarita": {
                "lat": 9.0833,
                "lng": -74.2
            },
            "Aranzazu": {
                "lat": 5.3,
                "lng": -75.45
            },
            "Noros\u00ed": {
                "lat": 8.5261,
                "lng": -74.0378
            },
            "Cachipay": {
                "lat": 4.7308,
                "lng": -74.4375
            },
            "Socot\u00e1": {
                "lat": 6.05,
                "lng": -72.6333
            },
            "Sasaima": {
                "lat": 4.965,
                "lng": -74.4347
            },
            "San Sebasti\u00e1n": {
                "lat": 1.8439,
                "lng": -76.7717
            },
            "El Roble": {
                "lat": 9.1,
                "lng": -75.2
            },
            "San Fernando": {
                "lat": 9.2683,
                "lng": -74.5302
            },
            "Tesalia": {
                "lat": 2.4833,
                "lng": -75.7333
            },
            "Mesetas": {
                "lat": 3.3781,
                "lng": -74.0447
            },
            "Hacar\u00ed": {
                "lat": 8.3167,
                "lng": -73.15
            },
            "Puerto Nari\u00f1o": {
                "lat": -3.7733,
                "lng": -70.3819
            },
            "Tuta": {
                "lat": 5.7,
                "lng": -73.2333
            },
            "Filadelfia": {
                "lat": 5.3,
                "lng": -75.6
            },
            "Guateque": {
                "lat": 5.0056,
                "lng": -73.4722
            },
            "Cunday": {
                "lat": 4.0833,
                "lng": -74.6667
            },
            "Chita": {
                "lat": 6.1667,
                "lng": -72.4167
            },
            "Mutis": {
                "lat": 6.2167,
                "lng": -77.4
            },
            "Suan": {
                "lat": 10.3333,
                "lng": -74.8833
            },
            "Pesca": {
                "lat": 5.5833,
                "lng": -73.05
            },
            "Betania": {
                "lat": 5.75,
                "lng": -75.9667
            },
            "Piamonte": {
                "lat": 1.1194,
                "lng": -76.3269
            },
            "Bojac\u00e1": {
                "lat": 4.7336,
                "lng": -74.3422
            },
            "Risaralda": {
                "lat": 5.1667,
                "lng": -75.75
            },
            "Falan": {
                "lat": 5.1333,
                "lng": -74.95
            },
            "Une": {
                "lat": 4.4019,
                "lng": -74.025
            },
            "Tiban\u00e1": {
                "lat": 5.3167,
                "lng": -73.4
            },
            "Solita": {
                "lat": 0.8667,
                "lng": -75.65
            },
            "Guatap\u00e9": {
                "lat": 6.2333,
                "lng": -75.1667
            },
            "Palocabildo": {
                "lat": 5.1333,
                "lng": -75.0333
            },
            "Carmen de Carupa": {
                "lat": 5.3503,
                "lng": -73.9053
            },
            "San Zen\u00f3n": {
                "lat": 9.245,
                "lng": -74.4992
            },
            "Consac\u00e1": {
                "lat": 1.2,
                "lng": -77.4667
            },
            "Campamento": {
                "lat": 6.9789,
                "lng": -75.2964
            },
            "\u00cdquira": {
                "lat": 2.65,
                "lng": -75.65
            },
            "La Florida": {
                "lat": 1.3017,
                "lng": -77.4106
            },
            "Iscuand\u00e9": {
                "lat": 2.4444,
                "lng": -77.9717
            },
            "Arboledas": {
                "lat": 7.6667,
                "lng": -72.75
            },
            "Puerto Lleras": {
                "lat": 3.2694,
                "lng": -73.375
            },
            "Linares": {
                "lat": 1.35,
                "lng": -77.5333
            },
            "Siachoque": {
                "lat": 5.5,
                "lng": -73.2333
            },
            "Nuqu\u00ed": {
                "lat": 5.7167,
                "lng": -77.2667
            },
            "Yaguar\u00e1": {
                "lat": 2.6661,
                "lng": -75.5186
            },
            "Angel\u00f3polis": {
                "lat": 6.1333,
                "lng": -75.7
            },
            "Tena": {
                "lat": 4.6547,
                "lng": -74.39
            },
            "Nunch\u00eda": {
                "lat": 5.6333,
                "lng": -72.2
            },
            "Carmen de Apical\u00e1": {
                "lat": 4.15,
                "lng": -74.7333
            },
            "Teruel": {
                "lat": 2.75,
                "lng": -75.5667
            },
            "Alvarado": {
                "lat": 4.5667,
                "lng": -74.95
            },
            "Gonz\u00e1lez": {
                "lat": 8.4,
                "lng": -73.3333
            },
            "Ospina": {
                "lat": 1.0581,
                "lng": -77.5664
            },
            "Entrerr\u00edos": {
                "lat": 6.5667,
                "lng": -75.5167
            },
            "Tausa": {
                "lat": 5.1964,
                "lng": -73.8875
            },
            "Zapatoca": {
                "lat": 6.8167,
                "lng": -73.2667
            },
            "San Juan de Arama": {
                "lat": 3.3736,
                "lng": -73.8767
            },
            "Carlosama": {
                "lat": 0.8658,
                "lng": -77.7294
            },
            "Jun\u00edn": {
                "lat": 4.7903,
                "lng": -73.8136
            },
            "Iles": {
                "lat": 0.9667,
                "lng": -77.5167
            },
            "Brice\u00f1o": {
                "lat": 7.1111,
                "lng": -75.55
            },
            "Nari\u00f1o": {
                "lat": 5.6092,
                "lng": -75.1764
            },
            "La Belleza": {
                "lat": 5.8614,
                "lng": -73.9683
            },
            "Yal\u00ed": {
                "lat": 6.6767,
                "lng": -74.8411
            },
            "Usiacur\u00ed": {
                "lat": 10.75,
                "lng": -74.9833
            },
            "Muzo": {
                "lat": 5.5313,
                "lng": -74.1073
            },
            "El Dovio": {
                "lat": 4.5167,
                "lng": -76.2333
            },
            "Orocu\u00e9": {
                "lat": 4.7942,
                "lng": -71.34
            },
            "Jurad\u00f3": {
                "lat": 7.1114,
                "lng": -77.7714
            }
        }
    },
    "TZ": {
        "name": "Tanzania",
        "cities": {
            "Dar es Salaam": {
                "lat": -6.8161,
                "lng": 39.2803
            },
            "Mwanza": {
                "lat": -2.5167,
                "lng": 32.9
            },
            "Ubungo": {
                "lat": -6.7889,
                "lng": 39.2056
            },
            "Mbeya": {
                "lat": -8.9,
                "lng": 33.45
            },
            "Arusha": {
                "lat": -3.3667,
                "lng": 36.6833
            },
            "Uvinza": {
                "lat": -5.1069,
                "lng": 30.3839
            },
            "Geita": {
                "lat": -2.8714,
                "lng": 32.2294
            },
            "Sumbawanga": {
                "lat": -7.9667,
                "lng": 31.6167
            },
            "Kibaha": {
                "lat": -6.7667,
                "lng": 38.9167
            },
            "Bariadi": {
                "lat": -2.7919,
                "lng": 33.9894
            },
            "Kahama": {
                "lat": -3.8375,
                "lng": 32.6
            },
            "Kasulu": {
                "lat": -4.58,
                "lng": 30.1
            },
            "Tabora": {
                "lat": -5.0167,
                "lng": 32.8
            },
            "Zanzibar": {
                "lat": -6.165,
                "lng": 39.199
            },
            "Morogoro": {
                "lat": -6.8242,
                "lng": 37.6633
            },
            "Ifakara": {
                "lat": -8.1,
                "lng": 36.6833
            },
            "Mpanda": {
                "lat": -6.35,
                "lng": 31.0667
            },
            "Iringa": {
                "lat": -7.77,
                "lng": 35.69
            },
            "Singida": {
                "lat": -4.8167,
                "lng": 34.75
            },
            "Bukoba": {
                "lat": -1.3333,
                "lng": 31.8167
            },
            "Moshi": {
                "lat": -3.3349,
                "lng": 37.3404
            },
            "Kigoma": {
                "lat": -4.8833,
                "lng": 29.6333
            },
            "Tarime": {
                "lat": -1.35,
                "lng": 34.3833
            },
            "Nzega": {
                "lat": -4.2169,
                "lng": 33.1864
            },
            "Handeni": {
                "lat": -5.4242,
                "lng": 38.0194
            },
            "Shinyanga": {
                "lat": -3.6619,
                "lng": 33.4231
            },
            "Musoma": {
                "lat": -1.5,
                "lng": 33.8
            },
            "Tanga": {
                "lat": -5.0742,
                "lng": 39.0992
            },
            "Songea": {
                "lat": -10.6833,
                "lng": 35.65
            },
            "Mtwara": {
                "lat": -10.2736,
                "lng": 40.1828
            },
            "Tukuyu": {
                "lat": -9.25,
                "lng": 33.65
            },
            "Chake Chake": {
                "lat": -5.2395,
                "lng": 39.77
            },
            "Tunduru": {
                "lat": -11.0667,
                "lng": 37.35
            },
            "Dodoma": {
                "lat": -6.1731,
                "lng": 35.7419
            },
            "Mbulu": {
                "lat": -3.85,
                "lng": 35.55
            },
            "Korogwe": {
                "lat": -5.1558,
                "lng": 38.4503
            },
            "Kilosa": {
                "lat": -6.83,
                "lng": 36.9875
            },
            "Lindi": {
                "lat": -9.9969,
                "lng": 39.7144
            },
            "Njombe": {
                "lat": -9.3333,
                "lng": 34.7667
            },
            "Tunduma": {
                "lat": -9.3,
                "lng": 32.7667
            },
            "Masasi": {
                "lat": -10.7296,
                "lng": 38.7999
            },
            "Mugumu": {
                "lat": -1.8333,
                "lng": 34.6667
            },
            "Bagamoyo": {
                "lat": -6.4444,
                "lng": 38.9028
            },
            "Magu": {
                "lat": -2.5833,
                "lng": 33.4333
            },
            "Mlimba": {
                "lat": -8.7786,
                "lng": 35.8111
            },
            "Misungwi": {
                "lat": -2.85,
                "lng": 33.0833
            },
            "Same": {
                "lat": -4.0667,
                "lng": 37.7833
            },
            "Lushoto": {
                "lat": -4.7928,
                "lng": 38.2911
            },
            "Geiro": {
                "lat": -6.1428,
                "lng": 36.8694
            },
            "Ngudu": {
                "lat": -2.9667,
                "lng": 33.3333
            },
            "Babati": {
                "lat": -4.2167,
                "lng": 35.75
            },
            "Kibiti": {
                "lat": -7.7296,
                "lng": 38.95
            },
            "Mpwapwa": {
                "lat": -6.35,
                "lng": 36.4833
            },
            "Masanwa": {
                "lat": -3.1833,
                "lng": 33.7833
            },
            "Chato": {
                "lat": -2.6378,
                "lng": 31.7669
            },
            "Sikonge": {
                "lat": -5.6333,
                "lng": 32.7667
            },
            "Wete": {
                "lat": -5.0567,
                "lng": 39.7281
            },
            "Manyoni": {
                "lat": -5.75,
                "lng": 34.8333
            },
            "Kabanga": {
                "lat": -2.9022,
                "lng": 30.4986
            },
            "Kakonko": {
                "lat": -3.2796,
                "lng": 30.96
            },
            "Biharamulo": {
                "lat": -2.6333,
                "lng": 31.3167
            },
            "Itigi": {
                "lat": -5.7,
                "lng": 34.4833
            },
            "Kilindoni": {
                "lat": -7.9167,
                "lng": 39.65
            },
            "Mazinde": {
                "lat": -4.8073,
                "lng": 38.2118
            },
            "Mikumi": {
                "lat": -7.4072,
                "lng": 36.9722
            },
            "Muheza": {
                "lat": -5.1739,
                "lng": 38.7875
            },
            "Sirari": {
                "lat": -1.2244,
                "lng": 34.4383
            },
            "Rubeho": {
                "lat": -6.2578,
                "lng": 36.8653
            },
            "Ngara": {
                "lat": -2.5122,
                "lng": 30.6558
            },
            "Karema": {
                "lat": -6.8205,
                "lng": 30.4389
            },
            "Nyahanga": {
                "lat": -2.3829,
                "lng": 33.55
            },
            "Bububu": {
                "lat": -6.1,
                "lng": 39.2167
            },
            "Butiama": {
                "lat": -1.7667,
                "lng": 33.9667
            },
            "Msata": {
                "lat": -6.3362,
                "lng": 38.385
            },
            "Kashasha": {
                "lat": -1.7578,
                "lng": 31.6158
            },
            "Ngerengere": {
                "lat": -6.75,
                "lng": 38.1167
            },
            "Ihumwa": {
                "lat": -6.1667,
                "lng": 35.8833
            },
            "Ngorongoro": {
                "lat": -3.2496,
                "lng": 35.52
            },
            "Keza": {
                "lat": -2.754,
                "lng": 30.682
            },
            "Mwaya": {
                "lat": -9.4917,
                "lng": 34.0333
            },
            "Msoga": {
                "lat": -6.5667,
                "lng": 38.3
            },
            "Mbamba Bay": {
                "lat": -11.2833,
                "lng": 34.7717
            },
            "Kibaya": {
                "lat": -5.3014,
                "lng": 36.5825
            },
            "Rudewa": {
                "lat": -10.1008,
                "lng": 34.6836
            },
            "Nansio": {
                "lat": -2.1078,
                "lng": 33.0864
            },
            "Oldeani": {
                "lat": -3.35,
                "lng": 35.55
            },
            "Mkokotoni": {
                "lat": -5.88,
                "lng": 39.2731
            },
            "Kipili": {
                "lat": -7.4329,
                "lng": 30.6
            },
            "Kanyato": {
                "lat": -4.4565,
                "lng": 30.2614
            },
            "Mahonda": {
                "lat": -5.9897,
                "lng": 39.2519
            },
            "Vwawa": {
                "lat": -9.1081,
                "lng": 32.9347
            },
            "Koani": {
                "lat": -6.1333,
                "lng": 39.2833
            }
        }
    },
    "SD": {
        "name": "Sudan",
        "cities": {
            "Khartoum": {
                "lat": 15.6,
                "lng": 32.5
            },
            "Omdurman": {
                "lat": 15.65,
                "lng": 32.4833
            },
            "Al Mijlad": {
                "lat": 11.0339,
                "lng": 27.74
            },
            "Khartoum North": {
                "lat": 15.6333,
                "lng": 32.6333
            },
            "Port Sudan": {
                "lat": 19.6167,
                "lng": 37.2167
            },
            "El Geneina": {
                "lat": 13.45,
                "lng": 22.45
            },
            "Kassala": {
                "lat": 15.45,
                "lng": 36.4
            },
            "El Fasher": {
                "lat": 13.6306,
                "lng": 25.35
            },
            "Nyala": {
                "lat": 12.05,
                "lng": 24.8833
            },
            "El Obeid": {
                "lat": 13.1833,
                "lng": 30.2167
            },
            "Gedaref": {
                "lat": 14.0333,
                "lng": 35.3833
            },
            "Wad Medani": {
                "lat": 14.4,
                "lng": 33.5167
            },
            "K\u016bst\u012b": {
                "lat": 13.1667,
                "lng": 32.6667
            },
            "Ed Daein": {
                "lat": 11.4608,
                "lng": 26.1283
            },
            "Singa": {
                "lat": 13.15,
                "lng": 33.9333
            },
            "Kurmuk": {
                "lat": 10.55,
                "lng": 34.2833
            },
            "Ar Ruseris": {
                "lat": 11.85,
                "lng": 34.3833
            },
            "Ed Damazin": {
                "lat": 11.7667,
                "lng": 34.35
            },
            "Al Man\u0101qil": {
                "lat": 14.2467,
                "lng": 32.9861
            },
            "Sann\u0101r": {
                "lat": 13.55,
                "lng": 33.5833
            },
            "Atbara": {
                "lat": 17.6972,
                "lng": 33.9783
            },
            "Ed Damer": {
                "lat": 17.59,
                "lng": 33.96
            },
            "An Nuh\u016bd": {
                "lat": 12.6925,
                "lng": 28.4233
            },
            "Kadugli": {
                "lat": 11.0167,
                "lng": 29.7167
            },
            "Ad Diwem": {
                "lat": 14.0,
                "lng": 32.3167
            },
            "Dongola": {
                "lat": 19.1698,
                "lng": 30.4749
            },
            "Umm Ruwaba": {
                "lat": 12.9046,
                "lng": 31.2079
            },
            "Shendi": {
                "lat": 16.6833,
                "lng": 33.4333
            },
            "Ad Dabbah": {
                "lat": 18.0502,
                "lng": 30.9495
            },
            "Ab\u016b \u1e28amad": {
                "lat": 19.5433,
                "lng": 33.3378
            },
            "Kutum": {
                "lat": 14.2056,
                "lng": 24.65
            },
            "Saw\u0101kin": {
                "lat": 19.1025,
                "lng": 37.33
            },
            "Tok\u0101r": {
                "lat": 18.4253,
                "lng": 37.7292
            },
            "Zalingei": {
                "lat": 12.9,
                "lng": 23.4833
            },
            "Al Qi\u0163ena": {
                "lat": 14.8612,
                "lng": 32.3692
            },
            "Baban\u016bsah": {
                "lat": 11.3333,
                "lng": 27.8
            },
            "W\u0101d\u012b \u1e28alf\u0101\u2019": {
                "lat": 21.8,
                "lng": 31.35
            },
            "Berber": {
                "lat": 18.0306,
                "lng": 33.9933
            },
            "Kuraymah": {
                "lat": 18.55,
                "lng": 31.85
            },
            "Umm Badr": {
                "lat": 14.2167,
                "lng": 27.9333
            },
            "Maraw\u012b": {
                "lat": 18.4833,
                "lng": 31.8167
            },
            "Karmah an Nuzul": {
                "lat": 19.6008,
                "lng": 30.4097
            },
            "Haya": {
                "lat": 18.3461,
                "lng": 36.3969
            },
            "Rabak": {
                "lat": 13.185,
                "lng": 32.744
            },
            "El Fula": {
                "lat": 11.7175,
                "lng": 28.34
            }
        }
    },
    "HK": {
        "name": "Hong Kong",
        "cities": {
            "Hong Kong": {
                "lat": 22.3,
                "lng": 114.2
            },
            "Kowloon": {
                "lat": 22.3167,
                "lng": 114.1833
            },
            "Sha Tin": {
                "lat": 22.3802,
                "lng": 114.1898
            },
            "Kowloon City": {
                "lat": 22.3282,
                "lng": 114.1916
            },
            "Sham Shui Po": {
                "lat": 22.3307,
                "lng": 114.1622
            },
            "Tin Shui Wai": {
                "lat": 22.46,
                "lng": 114.002
            },
            "Central District": {
                "lat": 22.2867,
                "lng": 114.155
            },
            "Tsing Yi Town": {
                "lat": 22.3456,
                "lng": 114.1
            },
            "Lam Tin": {
                "lat": 22.3094,
                "lng": 114.2361
            },
            "San Tung Chung Hang": {
                "lat": 22.2869,
                "lng": 113.9436
            },
            "Kennedy Town": {
                "lat": 22.28,
                "lng": 114.125
            },
            "Kwai Chung": {
                "lat": 22.3674,
                "lng": 114.1273
            },
            "Pak Tin Pa": {
                "lat": 22.3364,
                "lng": 114.1667
            },
            "Cheung Chau": {
                "lat": 22.2106,
                "lng": 114.0289
            },
            "Choi Hung": {
                "lat": 22.335,
                "lng": 114.2067
            },
            "Shek Tong Tsui": {
                "lat": 22.2871,
                "lng": 114.1357
            },
            "Wang Tau Hom": {
                "lat": 22.3408,
                "lng": 114.1871
            },
            "Shek Wai Kok": {
                "lat": 22.3753,
                "lng": 114.1237
            },
            "Tung Tau Tsuen": {
                "lat": 22.3334,
                "lng": 114.1924
            },
            "Sha Kok Mei": {
                "lat": 22.3784,
                "lng": 114.1941
            },
            "Sai Wan Ho": {
                "lat": 22.2877,
                "lng": 114.1365
            },
            "Sai Kung Tuk": {
                "lat": 22.3814,
                "lng": 114.2733
            },
            "Wan Tau Tong": {
                "lat": 22.4423,
                "lng": 114.167
            }
        }
    },
    "SA": {
        "name": "Saudi Arabia",
        "cities": {
            "Riyadh": {
                "lat": 24.6333,
                "lng": 46.7167
            },
            "Jeddah": {
                "lat": 21.5433,
                "lng": 39.1728
            },
            "Mecca": {
                "lat": 21.4225,
                "lng": 39.8233
            },
            "Medina": {
                "lat": 24.47,
                "lng": 39.61
            },
            "Ad Damm\u0101m": {
                "lat": 26.4333,
                "lng": 50.1
            },
            "Tab\u016bk": {
                "lat": 28.3972,
                "lng": 36.5789
            },
            "Al Huf\u016bf": {
                "lat": 25.3833,
                "lng": 49.5833
            },
            "Al Qa\u0163\u012bf": {
                "lat": 26.556,
                "lng": 49.996
            },
            "Al \u1e28illah": {
                "lat": 23.4895,
                "lng": 46.7564
            },
            "A\u0163 \u0162\u0101\u2019if": {
                "lat": 21.2751,
                "lng": 40.4062
            },
            "Al Jubayl": {
                "lat": 27.0,
                "lng": 49.6544
            },
            "Buraydah": {
                "lat": 26.3333,
                "lng": 43.9667
            },
            "\u1e28afr al B\u0101\u0163in": {
                "lat": 28.4342,
                "lng": 45.9636
            },
            "Yanbu": {
                "lat": 24.0883,
                "lng": 38.0582
            },
            "\u1e28\u0101\u2019il": {
                "lat": 27.5167,
                "lng": 41.6833
            },
            "Al Qunfudhah": {
                "lat": 19.1264,
                "lng": 41.0789
            },
            "Al Mubarraz": {
                "lat": 25.3833,
                "lng": 49.6
            },
            "Abh\u0101": {
                "lat": 18.2169,
                "lng": 42.5053
            },
            "\u015eaby\u0101": {
                "lat": 17.1489,
                "lng": 42.6258
            },
            "Al Khubar": {
                "lat": 26.2833,
                "lng": 50.2
            },
            "Sak\u0101k\u0101": {
                "lat": 29.9697,
                "lng": 40.2
            },
            "Qal\u2018at B\u012bshah": {
                "lat": 20.0,
                "lng": 42.6
            },
            "\u2018Unayzah": {
                "lat": 26.0906,
                "lng": 43.9875
            },
            "Al Qurayy\u0101t": {
                "lat": 31.3167,
                "lng": 37.3667
            },
            "Al \u1e28aw\u012byah": {
                "lat": 21.4411,
                "lng": 40.4975
            },
            "J\u0101z\u0101n": {
                "lat": 16.8892,
                "lng": 42.5611
            },
            "Az Zulf\u012b": {
                "lat": 26.2833,
                "lng": 44.8
            },
            "Ar Rass": {
                "lat": 25.8667,
                "lng": 43.5
            },
            "\u1e28ara\u1e11": {
                "lat": 24.1456,
                "lng": 49.0653
            },
            "Al A\u1e29ad al Mas\u0101ri\u1e29ah": {
                "lat": 16.7097,
                "lng": 42.955
            },
            "Kham\u012bs Mushay\u0163": {
                "lat": 18.3,
                "lng": 42.7333
            },
            "\u0162urayf": {
                "lat": 31.6775,
                "lng": 38.6531
            },
            "Shar\u016brah": {
                "lat": 17.4833,
                "lng": 47.1167
            },
            "Sayh\u0101t": {
                "lat": 26.475,
                "lng": 50.0417
            },
            "Raf\u1e29\u0101": {
                "lat": 29.6386,
                "lng": 43.5014
            },
            "Ra\u2019s al Khafj\u012b": {
                "lat": 28.4211,
                "lng": 48.4956
            },
            "Najr\u0101n": {
                "lat": 17.4917,
                "lng": 44.1322
            },
            "Al L\u012bth": {
                "lat": 20.15,
                "lng": 40.2667
            },
            "Ad Darb": {
                "lat": 17.7167,
                "lng": 42.25
            },
            "Badr \u1e28unayn": {
                "lat": 23.78,
                "lng": 38.7906
            },
            "Khulay\u015f": {
                "lat": 22.1539,
                "lng": 39.3183
            },
            "Ras Tanura": {
                "lat": 26.65,
                "lng": 50.1667
            },
            "An Nim\u0101\u015f": {
                "lat": 19.12,
                "lng": 42.13
            },
            "Al Maj\u0101ridah": {
                "lat": 19.1167,
                "lng": 41.9167
            },
            "Al Kharj": {
                "lat": 24.1483,
                "lng": 47.305
            },
            "Al Wajh": {
                "lat": 26.2833,
                "lng": 36.4167
            },
            "Abqaiq": {
                "lat": 25.935,
                "lng": 49.6661
            },
            "Al Midhnab": {
                "lat": 25.8601,
                "lng": 44.2223
            },
            "\u1e10ub\u0101": {
                "lat": 27.3493,
                "lng": 35.6962
            },
            "Al \u2018Aq\u012bq": {
                "lat": 20.2685,
                "lng": 41.6493
            },
            "\u1e10ulay\u2018 Rash\u012bd": {
                "lat": 25.5067,
                "lng": 42.8614
            },
            "Shaqr\u0101\u2019": {
                "lat": 25.2402,
                "lng": 45.251
            },
            "Al Mindak": {
                "lat": 20.1833,
                "lng": 41.2333
            },
            "Dhahran": {
                "lat": 26.2667,
                "lng": 50.15
            },
            "Al \u2018Ayd\u0101b\u012b": {
                "lat": 17.237,
                "lng": 42.939
            },
            "Q\u0101r\u0101": {
                "lat": 29.8833,
                "lng": 40.2167
            },
            "Ash Shin\u0101n": {
                "lat": 27.1782,
                "lng": 42.443
            },
            "\u1e28aw\u0163at Sudayr": {
                "lat": 25.5951,
                "lng": 45.6338
            },
            "Arar": {
                "lat": 30.9753,
                "lng": 41.0231
            },
            "\u1e28aql": {
                "lat": 29.2833,
                "lng": 34.9333
            },
            "Al \u2018Aww\u0101m\u012byah": {
                "lat": 26.5833,
                "lng": 49.9833
            },
            "As Sulayyil": {
                "lat": 20.4597,
                "lng": 45.5744
            },
            "Dawmat al Jandal": {
                "lat": 29.8153,
                "lng": 39.8664
            },
            "Al B\u0101\u1e29ah": {
                "lat": 20.0125,
                "lng": 41.4653
            },
            "Ad Dir\u2018\u012byah": {
                "lat": 24.7333,
                "lng": 46.5756
            },
            "Al Gh\u0101\u0163": {
                "lat": 26.0267,
                "lng": 44.9608
            },
            "Al Awj\u0101m": {
                "lat": 26.5583,
                "lng": 49.9503
            },
            "Al \u1e28\u0101\u2019ir": {
                "lat": 25.79,
                "lng": 45.38
            },
            "\u1e28armah": {
                "lat": 25.9261,
                "lng": 45.3336
            },
            "Al Majma\u2018ah": {
                "lat": 25.9039,
                "lng": 45.3456
            },
            "\u1e10urum\u0101": {
                "lat": 24.6,
                "lng": 46.1333
            },
            "Al Quway\u2018\u012byah": {
                "lat": 24.0464,
                "lng": 45.2656
            }
        }
    },
    "CL": {
        "name": "Chile",
        "cities": {
            "Santiago": {
                "lat": -33.4372,
                "lng": -70.6506
            },
            "Concepci\u00f3n": {
                "lat": -36.8282,
                "lng": -73.0514
            },
            "Puente Alto": {
                "lat": -33.6167,
                "lng": -70.5667
            },
            "Maip\u00fa": {
                "lat": -33.5167,
                "lng": -70.7667
            },
            "La Florida": {
                "lat": -33.5333,
                "lng": -70.5833
            },
            "Antofagasta": {
                "lat": -23.65,
                "lng": -70.4
            },
            "Vi\u00f1a del Mar": {
                "lat": -33.0244,
                "lng": -71.5517
            },
            "San Bernardo": {
                "lat": -33.5833,
                "lng": -70.7
            },
            "Valpara\u00edso": {
                "lat": -33.0461,
                "lng": -71.6197
            },
            "Temuco": {
                "lat": -38.7333,
                "lng": -72.6667
            },
            "Las Condes": {
                "lat": -33.4117,
                "lng": -70.55
            },
            "Rancagua": {
                "lat": -34.1667,
                "lng": -70.75
            },
            "Pe\u00f1alol\u00e9n": {
                "lat": -33.4833,
                "lng": -70.55
            },
            "Quilicura": {
                "lat": -33.3667,
                "lng": -70.7333
            },
            "Talca": {
                "lat": -35.4269,
                "lng": -71.6656
            },
            "Coquimbo": {
                "lat": -29.9531,
                "lng": -71.3433
            },
            "Arica": {
                "lat": -18.4778,
                "lng": -70.3181
            },
            "La Serena": {
                "lat": -29.9,
                "lng": -71.25
            },
            "Iquique": {
                "lat": -20.2167,
                "lng": -70.15
            },
            "La Pintana": {
                "lat": -33.5833,
                "lng": -70.6333
            },
            "El Bosque": {
                "lat": -33.5667,
                "lng": -70.675
            },
            "Puerto Montt": {
                "lat": -41.4667,
                "lng": -72.9333
            },
            "Chill\u00e1n": {
                "lat": -36.6,
                "lng": -72.1167
            },
            "Osorno": {
                "lat": -40.5725,
                "lng": -73.1353
            },
            "Calama": {
                "lat": -22.4667,
                "lng": -68.9333
            },
            "Copiap\u00f3": {
                "lat": -27.3664,
                "lng": -70.3331
            },
            "Talcahuano": {
                "lat": -36.7167,
                "lng": -73.1219
            },
            "Valdivia": {
                "lat": -39.8139,
                "lng": -73.2458
            },
            "Quilpu\u00e9": {
                "lat": -33.05,
                "lng": -71.45
            },
            "Colina": {
                "lat": -33.2017,
                "lng": -70.6703
            },
            "Los \u00c1ngeles": {
                "lat": -37.4667,
                "lng": -72.35
            },
            "Curic\u00f3": {
                "lat": -34.9833,
                "lng": -71.2333
            },
            "Renca": {
                "lat": -33.4,
                "lng": -70.7167
            },
            "Conchal\u00ed": {
                "lat": -33.3833,
                "lng": -70.675
            },
            "Villa Alemana": {
                "lat": -33.0422,
                "lng": -71.3733
            },
            "Punta Arenas": {
                "lat": -53.1667,
                "lng": -70.9333
            },
            "La Granja": {
                "lat": -33.5333,
                "lng": -70.625
            },
            "Coronel": {
                "lat": -37.0167,
                "lng": -73.2167
            },
            "Macul": {
                "lat": -32.6583,
                "lng": -70.6
            },
            "Melipilla": {
                "lat": -33.6253,
                "lng": -71.2711
            },
            "Alto Hospicio": {
                "lat": -20.25,
                "lng": -70.1167
            },
            "Lo Barnechea": {
                "lat": -33.35,
                "lng": -70.5167
            },
            "Espejo": {
                "lat": -33.5167,
                "lng": -70.6917
            },
            "Buin": {
                "lat": -33.7333,
                "lng": -70.7333
            },
            "San Ram\u00f3n": {
                "lat": -33.5333,
                "lng": -70.6417
            },
            "San Joaqu\u00edn": {
                "lat": -33.4792,
                "lng": -70.6413
            },
            "La Reina": {
                "lat": -33.45,
                "lng": -70.55
            },
            "Pe\u00f1aflor": {
                "lat": -33.6167,
                "lng": -70.9167
            },
            "Los Cerrillos": {
                "lat": -33.5,
                "lng": -70.7167
            },
            "San Antonio": {
                "lat": -33.5933,
                "lng": -71.6217
            },
            "Chiguayante": {
                "lat": -36.9167,
                "lng": -73.0167
            },
            "Padre Hurtado": {
                "lat": -33.5667,
                "lng": -70.8333
            },
            "Ovalle": {
                "lat": -30.6,
                "lng": -71.2
            },
            "Linares": {
                "lat": -35.85,
                "lng": -71.6
            },
            "Quillota": {
                "lat": -32.8667,
                "lng": -71.25
            },
            "Padre Las Casas": {
                "lat": -38.7667,
                "lng": -72.6
            },
            "Paine": {
                "lat": -33.8167,
                "lng": -70.75
            },
            "San Felipe": {
                "lat": -32.75,
                "lng": -70.7239
            },
            "Coyhaique": {
                "lat": -45.5667,
                "lng": -72.0667
            },
            "Los Andes": {
                "lat": -32.8333,
                "lng": -70.6167
            },
            "San Fernando": {
                "lat": -34.5833,
                "lng": -70.9833
            },
            "Talagante": {
                "lat": -33.6667,
                "lng": -70.8667
            },
            "Rengo": {
                "lat": -34.4167,
                "lng": -70.8667
            },
            "Villarrica": {
                "lat": -39.2667,
                "lng": -72.2167
            },
            "La Calera": {
                "lat": -32.7867,
                "lng": -71.1972
            },
            "Angol": {
                "lat": -37.8,
                "lng": -72.7167
            },
            "Penco": {
                "lat": -36.7333,
                "lng": -72.9833
            },
            "Vallenar": {
                "lat": -28.5708,
                "lng": -70.7581
            },
            "San Vicente de Tagua Tagua": {
                "lat": -34.4394,
                "lng": -71.0761
            },
            "Limache": {
                "lat": -33.0167,
                "lng": -71.2667
            },
            "Lota": {
                "lat": -37.0833,
                "lng": -73.1667
            },
            "San Clemente": {
                "lat": -35.55,
                "lng": -71.4833
            },
            "Machal\u00ed": {
                "lat": -34.1825,
                "lng": -70.6514
            },
            "Tom\u00e9": {
                "lat": -36.6167,
                "lng": -72.95
            },
            "Alerce": {
                "lat": -41.4,
                "lng": -72.9333
            },
            "Constituci\u00f3n": {
                "lat": -35.3333,
                "lng": -72.4167
            },
            "Molina": {
                "lat": -35.1144,
                "lng": -71.2825
            },
            "Parral": {
                "lat": -36.15,
                "lng": -71.8333
            },
            "Placilla de Pe\u00f1uelas": {
                "lat": -33.1156,
                "lng": -71.5678
            },
            "Ancud": {
                "lat": -41.8667,
                "lng": -73.8333
            },
            "Maule": {
                "lat": -35.5333,
                "lng": -71.7
            },
            "Lampa": {
                "lat": -33.2833,
                "lng": -70.9
            },
            "Isla de Maipo": {
                "lat": -33.75,
                "lng": -70.9
            },
            "Santa Cruz": {
                "lat": -34.6372,
                "lng": -71.3631
            },
            "Victoria": {
                "lat": -38.2333,
                "lng": -72.3333
            },
            "Ca\u00f1ete": {
                "lat": -37.7994,
                "lng": -73.3953
            },
            "Lautaro": {
                "lat": -38.5167,
                "lng": -72.45
            },
            "Chimbarongo": {
                "lat": -34.7089,
                "lng": -71.0431
            },
            "Castro": {
                "lat": -42.4667,
                "lng": -73.8
            },
            "Curanilahue": {
                "lat": -37.4764,
                "lng": -73.3444
            },
            "Conc\u00f3n": {
                "lat": -32.9167,
                "lng": -71.5167
            },
            "Quintero": {
                "lat": -32.7833,
                "lng": -71.5333
            },
            "Nueva Imperial": {
                "lat": -38.7433,
                "lng": -72.95
            },
            "Graneros": {
                "lat": -34.0647,
                "lng": -70.7264
            },
            "Monte Patria": {
                "lat": -30.8333,
                "lng": -70.7
            },
            "San Javier": {
                "lat": -36.6,
                "lng": -71.75
            },
            "Puc\u00f3n": {
                "lat": -39.2767,
                "lng": -71.9744
            },
            "Longav\u00ed": {
                "lat": -35.9667,
                "lng": -71.6833
            },
            "Cabrero": {
                "lat": -37.0333,
                "lng": -72.4
            },
            "Mulch\u00e9n": {
                "lat": -37.7167,
                "lng": -72.2333
            },
            "Teno": {
                "lat": -34.8667,
                "lng": -71.1833
            },
            "Chill\u00e1n Viejo": {
                "lat": -36.6239,
                "lng": -72.1358
            },
            "Nacimiento": {
                "lat": -37.5,
                "lng": -72.6667
            },
            "La Uni\u00f3n": {
                "lat": -40.2833,
                "lng": -73.0833
            },
            "El Monte": {
                "lat": -33.6833,
                "lng": -71.0167
            },
            "Puerto Varas": {
                "lat": -41.3167,
                "lng": -72.9833
            },
            "Requ\u00ednoa": {
                "lat": -34.2786,
                "lng": -70.8117
            },
            "Carahue": {
                "lat": -38.7089,
                "lng": -73.1647
            },
            "Salamanca": {
                "lat": -31.7667,
                "lng": -70.9667
            },
            "Vicu\u00f1a": {
                "lat": -30.0333,
                "lng": -70.7167
            },
            "Las Cabras": {
                "lat": -34.2917,
                "lng": -71.3097
            },
            "Curacav\u00ed": {
                "lat": -33.4,
                "lng": -71.15
            },
            "Tocopilla": {
                "lat": -22.0964,
                "lng": -70.2
            },
            "Quell\u00f3n": {
                "lat": -43.0992,
                "lng": -73.5961
            },
            "Lebu": {
                "lat": -37.6,
                "lng": -73.6667
            },
            "Collipulli": {
                "lat": -37.95,
                "lng": -72.4333
            },
            "Cartagena": {
                "lat": -33.5425,
                "lng": -71.5958
            },
            "Llaillay": {
                "lat": -32.8403,
                "lng": -70.9483
            },
            "Vilc\u00fan": {
                "lat": -38.65,
                "lng": -72.2333
            },
            "Freire": {
                "lat": -38.9558,
                "lng": -72.6256
            },
            "Laja": {
                "lat": -37.2667,
                "lng": -72.7
            },
            "La Cruz": {
                "lat": -32.8281,
                "lng": -71.1761
            },
            "Pitrufqu\u00e9n": {
                "lat": -38.9833,
                "lng": -72.65
            },
            "Casablanca": {
                "lat": -33.3167,
                "lng": -71.4167
            },
            "Nogales": {
                "lat": -32.735,
                "lng": -71.2025
            },
            "Puerto Natales": {
                "lat": -51.7333,
                "lng": -72.5167
            },
            "Loncoche": {
                "lat": -39.3667,
                "lng": -72.6333
            },
            "Los Vilos": {
                "lat": -31.9167,
                "lng": -71.5167
            },
            "Hualqui": {
                "lat": -36.96,
                "lng": -72.93
            },
            "Purranque": {
                "lat": -40.9167,
                "lng": -73.1667
            },
            "Bulnes": {
                "lat": -36.7425,
                "lng": -72.2983
            },
            "Illapel": {
                "lat": -31.6333,
                "lng": -71.1667
            },
            "Yumbel": {
                "lat": -37.1333,
                "lng": -72.5333
            },
            "Pichidegua": {
                "lat": -34.3586,
                "lng": -71.2831
            },
            "Los \u00c1lamos": {
                "lat": -37.6272,
                "lng": -73.4619
            },
            "Los Bajos": {
                "lat": -41.1228,
                "lng": -73.0581
            },
            "Frutillar Alto": {
                "lat": -41.0872,
                "lng": -73.0956
            },
            "Cabildo": {
                "lat": -32.4275,
                "lng": -71.0664
            },
            "La Ligua": {
                "lat": -32.45,
                "lng": -71.2167
            },
            "Paillaco": {
                "lat": -40.0667,
                "lng": -72.8833
            },
            "Cunco": {
                "lat": -38.9167,
                "lng": -72.0333
            },
            "Do\u00f1ihue": {
                "lat": -34.2261,
                "lng": -70.965
            },
            "Retiro": {
                "lat": -36.0333,
                "lng": -71.7667
            },
            "Los Lagos": {
                "lat": -39.85,
                "lng": -72.8333
            },
            "Calera de Tango": {
                "lat": -33.6302,
                "lng": -70.7616
            },
            "Hijuelas": {
                "lat": -32.7986,
                "lng": -71.1439
            },
            "Coltauco": {
                "lat": -34.3,
                "lng": -71.1
            },
            "Colb\u00fan": {
                "lat": -35.7,
                "lng": -71.4167
            },
            "Arauco": {
                "lat": -37.25,
                "lng": -73.3167
            },
            "Sagrada Familia": {
                "lat": -35.0,
                "lng": -71.3833
            },
            "Puerto Ays\u00e9n": {
                "lat": -45.4,
                "lng": -72.6833
            },
            "Traigu\u00e9n": {
                "lat": -38.25,
                "lng": -72.6833
            },
            "San Esteban": {
                "lat": -32.7992,
                "lng": -70.5803
            },
            "R\u00edo Bueno": {
                "lat": -40.3167,
                "lng": -72.9667
            },
            "Batuco": {
                "lat": -33.2308,
                "lng": -70.8089
            },
            "Yerbas Buenas": {
                "lat": -35.75,
                "lng": -71.5667
            },
            "Pirque": {
                "lat": -33.6333,
                "lng": -70.55
            },
            "Curacaut\u00edn": {
                "lat": -38.4333,
                "lng": -71.8833
            },
            "Llanquihue": {
                "lat": -41.2581,
                "lng": -73.0086
            },
            "Caldera": {
                "lat": -27.0667,
                "lng": -70.8333
            },
            "Los Muermos": {
                "lat": -41.4,
                "lng": -73.4833
            },
            "Calbuco": {
                "lat": -41.7667,
                "lng": -73.1333
            },
            "Coelemu": {
                "lat": -36.4878,
                "lng": -72.7028
            },
            "Olmu\u00e9": {
                "lat": -32.9953,
                "lng": -71.1856
            },
            "Maull\u00edn": {
                "lat": -41.6167,
                "lng": -73.6
            },
            "Teodoro Schmidt": {
                "lat": -38.9949,
                "lng": -73.0894
            },
            "Nancagua": {
                "lat": -34.6667,
                "lng": -71.2167
            },
            "Putaendo": {
                "lat": -32.6278,
                "lng": -70.7167
            },
            "Bellavista": {
                "lat": -34.9333,
                "lng": -71.3167
            },
            "Ch\u00e9pica": {
                "lat": -34.7333,
                "lng": -71.2833
            },
            "Tiltil": {
                "lat": -33.0817,
                "lng": -70.9278
            },
            "R\u00edo Negro": {
                "lat": -40.7833,
                "lng": -73.2
            },
            "Villa Alegre": {
                "lat": -35.6667,
                "lng": -71.75
            },
            "Santa Mar\u00eda": {
                "lat": -32.7469,
                "lng": -70.6597
            },
            "Gorbea": {
                "lat": -39.1,
                "lng": -72.6833
            },
            "Peumo": {
                "lat": -34.3961,
                "lng": -71.1694
            },
            "Tierra Amarilla": {
                "lat": -27.4822,
                "lng": -70.2658
            },
            "Diego de Almagro": {
                "lat": -26.3911,
                "lng": -70.0458
            },
            "Futrono": {
                "lat": -40.1333,
                "lng": -72.4
            },
            "Combarbal\u00e1": {
                "lat": -31.1833,
                "lng": -71.0
            },
            "Tucapel": {
                "lat": -37.2833,
                "lng": -71.95
            },
            "Santa B\u00e1rbara": {
                "lat": -37.6706,
                "lng": -72.0214
            },
            "San Jos\u00e9 de Maipo": {
                "lat": -33.64,
                "lng": -70.3531
            },
            "Calle Larga": {
                "lat": -32.8831,
                "lng": -70.6486
            },
            "El Tabo": {
                "lat": -33.4586,
                "lng": -71.6619
            },
            "Catemu": {
                "lat": -32.8828,
                "lng": -70.6483
            },
            "Santa Juana": {
                "lat": -37.166,
                "lng": -72.933
            },
            "Cha\u00f1aral": {
                "lat": -26.3444,
                "lng": -70.6219
            },
            "Olivar Bajo": {
                "lat": -34.2289,
                "lng": -70.8799
            },
            "Olivar": {
                "lat": -34.233,
                "lng": -70.883
            },
            "Frutillar": {
                "lat": -41.1228,
                "lng": -73.0581
            },
            "Pur\u00e9n": {
                "lat": -38.0319,
                "lng": -73.0728
            },
            "Pichilemu": {
                "lat": -34.3919,
                "lng": -72.0139
            },
            "San Francisco de Mostazal": {
                "lat": -33.9799,
                "lng": -70.7122
            },
            "Quirihue": {
                "lat": -36.2833,
                "lng": -72.5333
            },
            "Codegua": {
                "lat": -34.0347,
                "lng": -70.6686
            },
            "Quinta de Tilcoco": {
                "lat": -34.3547,
                "lng": -70.9636
            },
            "Rinconada de Malloa": {
                "lat": -34.4464,
                "lng": -70.9458
            },
            "Taltal": {
                "lat": -25.4333,
                "lng": -70.4667
            },
            "Galvarino": {
                "lat": -38.4,
                "lng": -72.7833
            },
            "Palmilla": {
                "lat": -34.6042,
                "lng": -71.3583
            },
            "Lumaco": {
                "lat": -38.15,
                "lng": -72.9167
            },
            "Puyehue": {
                "lat": -40.6833,
                "lng": -72.6
            },
            "El Quisco": {
                "lat": -33.3913,
                "lng": -71.6929
            },
            "Panguipulli": {
                "lat": -39.6444,
                "lng": -72.3306
            },
            "Andacollo": {
                "lat": -30.2303,
                "lng": -71.0858
            },
            "Chicureo Abajo": {
                "lat": -33.2833,
                "lng": -70.6333
            },
            "Punitaqui": {
                "lat": -30.9,
                "lng": -71.2667
            },
            "Yungay": {
                "lat": -37.1194,
                "lng": -72.0189
            },
            "\u00d1iqu\u00e9n": {
                "lat": -36.3,
                "lng": -71.9
            },
            "Peralillo": {
                "lat": -34.4875,
                "lng": -71.4917
            },
            "San Nicol\u00e1s": {
                "lat": -36.5033,
                "lng": -72.2122
            },
            "Pinto": {
                "lat": -36.7036,
                "lng": -71.8922
            },
            "Cholchol": {
                "lat": -38.6,
                "lng": -72.8333
            },
            "Mar\u00eda Pinto": {
                "lat": -33.5167,
                "lng": -71.1167
            },
            "Lonquimay": {
                "lat": -38.4333,
                "lng": -71.2333
            },
            "Algarrobo": {
                "lat": -33.3911,
                "lng": -71.6928
            },
            "San Pablo": {
                "lat": -40.4,
                "lng": -73.0167
            },
            "Petorca": {
                "lat": -32.2514,
                "lng": -70.9314
            },
            "Renaico": {
                "lat": -37.6667,
                "lng": -72.5833
            },
            "Nueva Tolt\u00e9n": {
                "lat": -39.1786,
                "lng": -73.1656
            },
            "San Pedro": {
                "lat": -33.8944,
                "lng": -71.4558
            },
            "Huachipato": {
                "lat": -36.7481,
                "lng": -73.0986
            },
            "Tir\u00faa": {
                "lat": -38.3414,
                "lng": -73.4911
            },
            "Lago Ranco": {
                "lat": -40.3167,
                "lng": -72.5
            },
            "Rauco": {
                "lat": -34.9167,
                "lng": -71.3167
            },
            "Negrete": {
                "lat": -37.5833,
                "lng": -72.5333
            },
            "Curepto": {
                "lat": -35.0833,
                "lng": -72.0167
            },
            "Huala\u00f1e": {
                "lat": -34.9765,
                "lng": -71.8048
            },
            "Pozo Almonte": {
                "lat": -20.2667,
                "lng": -69.8
            },
            "Mejillones": {
                "lat": -23.1,
                "lng": -70.45
            },
            "Canela Baja": {
                "lat": -31.3989,
                "lng": -71.4561
            },
            "Chanco": {
                "lat": -36.2667,
                "lng": -72.7167
            },
            "Lo Miranda": {
                "lat": -34.19,
                "lng": -70.89
            },
            "Puerto Octay": {
                "lat": -40.9667,
                "lng": -72.9
            },
            "Huasco": {
                "lat": -28.4664,
                "lng": -71.2192
            },
            "Florida": {
                "lat": -36.8167,
                "lng": -72.6667
            },
            "Rocas de Santo Domingo": {
                "lat": -33.6358,
                "lng": -71.6281
            },
            "San Juan de la Costa": {
                "lat": -40.5167,
                "lng": -73.4
            },
            "Hualaihu\u00e9": {
                "lat": -42.1,
                "lng": -72.4167
            },
            "Lanco": {
                "lat": -39.45,
                "lng": -72.7833
            },
            "Oficina Mar\u00eda Elena": {
                "lat": -22.3451,
                "lng": -69.6615
            },
            "Chonchi": {
                "lat": -42.6219,
                "lng": -73.7742
            },
            "Cochrane": {
                "lat": -47.2547,
                "lng": -72.575
            },
            "Tolt\u00e9n": {
                "lat": -39.2166,
                "lng": -73.2123
            },
            "Puerto Williams": {
                "lat": -54.9333,
                "lng": -67.6167
            },
            "Villa O\u2019Higgins": {
                "lat": -48.4683,
                "lng": -72.56
            },
            "Toconao": {
                "lat": -23.1903,
                "lng": -68.005
            },
            "Cauquenes": {
                "lat": -35.9667,
                "lng": -72.35
            },
            "Cuya": {
                "lat": -19.1597,
                "lng": -70.1794
            },
            "Lagunas": {
                "lat": -20.9829,
                "lng": -69.6833
            },
            "Chuquicamata": {
                "lat": -22.3169,
                "lng": -68.9301
            }
        }
    },
    "ES": {
        "name": "Spain",
        "cities": {
            "Madrid": {
                "lat": 40.4169,
                "lng": -3.7033
            },
            "Barcelona": {
                "lat": 41.3828,
                "lng": 2.1769
            },
            "Valencia": {
                "lat": 39.47,
                "lng": -0.3764
            },
            "Sevilla": {
                "lat": 37.39,
                "lng": -5.99
            },
            "M\u00e1laga": {
                "lat": 36.7194,
                "lng": -4.42
            },
            "Bilbao": {
                "lat": 43.2569,
                "lng": -2.9236
            },
            "Zaragoza": {
                "lat": 41.65,
                "lng": -0.8833
            },
            "Murcia": {
                "lat": 37.9861,
                "lng": -1.1303
            },
            "Las Palmas": {
                "lat": 28.1258,
                "lng": -15.4353
            },
            "Palma": {
                "lat": 39.5667,
                "lng": 2.65
            },
            "Santa Cruz": {
                "lat": 28.4667,
                "lng": -16.25
            },
            "Alicante": {
                "lat": 38.3453,
                "lng": -0.4831
            },
            "C\u00f3rdoba": {
                "lat": 37.8845,
                "lng": -4.7796
            },
            "Valladolid": {
                "lat": 41.6528,
                "lng": -4.7236
            },
            "Vigo": {
                "lat": 42.2314,
                "lng": -8.7124
            },
            "Gij\u00f3n": {
                "lat": 43.5333,
                "lng": -5.7
            },
            "Vitoria-Gasteiz": {
                "lat": 42.85,
                "lng": -2.6833
            },
            "A Coru\u00f1a": {
                "lat": 43.365,
                "lng": -8.41
            },
            "Elche": {
                "lat": 38.2669,
                "lng": -0.6983
            },
            "Granada": {
                "lat": 37.1781,
                "lng": -3.6008
            },
            "Badalona": {
                "lat": 41.4489,
                "lng": 2.2461
            },
            "Tarrasa": {
                "lat": 41.57,
                "lng": 2.013
            },
            "Petrel": {
                "lat": 38.4789,
                "lng": -0.7967
            },
            "Sabadell": {
                "lat": 41.5486,
                "lng": 2.1075
            },
            "Cartagena": {
                "lat": 37.6,
                "lng": -0.9833
            },
            "Oviedo": {
                "lat": 43.36,
                "lng": -5.845
            },
            "Jerez de la Frontera": {
                "lat": 36.6817,
                "lng": -6.1378
            },
            "M\u00f3stoles": {
                "lat": 40.3333,
                "lng": -3.8667
            },
            "Pamplona": {
                "lat": 42.8167,
                "lng": -1.65
            },
            "Almer\u00eda": {
                "lat": 36.8403,
                "lng": -2.4681
            },
            "Alcal\u00e1 de Henares": {
                "lat": 40.4667,
                "lng": -3.3667
            },
            "Legan\u00e9s": {
                "lat": 40.3281,
                "lng": -3.7644
            },
            "Donostia": {
                "lat": 43.3214,
                "lng": -1.9856
            },
            "Fuenlabrada": {
                "lat": 40.2833,
                "lng": -3.8
            },
            "Getafe": {
                "lat": 40.3047,
                "lng": -3.7311
            },
            "Castell\u00f3n de la Plana": {
                "lat": 39.9831,
                "lng": -0.0331
            },
            "Burgos": {
                "lat": 42.35,
                "lng": -3.7067
            },
            "Albacete": {
                "lat": 38.9956,
                "lng": -1.8558
            },
            "Santander": {
                "lat": 43.4628,
                "lng": -3.805
            },
            "Alcorc\u00f3n": {
                "lat": 40.35,
                "lng": -3.8333
            },
            "Guadalajara": {
                "lat": 40.6337,
                "lng": -3.1674
            },
            "La Laguna": {
                "lat": 28.4853,
                "lng": -16.3167
            },
            "Marbella": {
                "lat": 36.5167,
                "lng": -4.8833
            },
            "Logro\u00f1o": {
                "lat": 42.465,
                "lng": -2.4456
            },
            "Badajoz": {
                "lat": 38.8803,
                "lng": -6.9753
            },
            "Salamanca": {
                "lat": 40.965,
                "lng": -5.6642
            },
            "Lleida": {
                "lat": 41.6167,
                "lng": 0.6333
            },
            "Huelva": {
                "lat": 37.25,
                "lng": -6.95
            },
            "Dos Hermanas": {
                "lat": 37.2836,
                "lng": -5.9222
            },
            "Tarragona": {
                "lat": 41.1175,
                "lng": 1.2528
            },
            "Parla": {
                "lat": 40.2372,
                "lng": -3.7742
            },
            "Matar\u00f3": {
                "lat": 41.5333,
                "lng": 2.45
            },
            "Torrej\u00f3n de Ardoz": {
                "lat": 40.4614,
                "lng": -3.4978
            },
            "Algeciras": {
                "lat": 36.1275,
                "lng": -5.4539
            },
            "Le\u00f3n": {
                "lat": 42.6056,
                "lng": -5.57
            },
            "Alcobendas": {
                "lat": 40.5333,
                "lng": -3.6333
            },
            "Ja\u00e9n": {
                "lat": 37.7667,
                "lng": -3.7711
            },
            "C\u00e1diz": {
                "lat": 36.535,
                "lng": -6.2975
            },
            "Reus": {
                "lat": 41.156,
                "lng": 1.108
            },
            "Roquetas de Mar": {
                "lat": 36.7642,
                "lng": -2.6147
            },
            "Girona": {
                "lat": 41.9844,
                "lng": 2.8211
            },
            "Ourense": {
                "lat": 42.3364,
                "lng": -7.8633
            },
            "Telde": {
                "lat": 27.9833,
                "lng": -15.4167
            },
            "San Vicente de Baracaldo": {
                "lat": 43.2972,
                "lng": -2.9917
            },
            "Vaciamadrid": {
                "lat": 40.3394,
                "lng": -3.5181
            },
            "Santiago de Compostela": {
                "lat": 42.8778,
                "lng": -8.5444
            },
            "Lorca": {
                "lat": 37.6798,
                "lng": -1.6944
            },
            "Lugo": {
                "lat": 43.0167,
                "lng": -7.55
            },
            "Las Rozas de Madrid": {
                "lat": 40.4917,
                "lng": -3.8733
            },
            "San Cugat del Vall\u00e9s": {
                "lat": 41.4667,
                "lng": 2.0833
            },
            "C\u00e1ceres": {
                "lat": 39.4833,
                "lng": -6.3667
            },
            "San Fernando": {
                "lat": 36.4667,
                "lng": -6.2
            },
            "San Sebasti\u00e1n de los Reyes": {
                "lat": 40.5469,
                "lng": -3.6258
            },
            "Mijas": {
                "lat": 36.6,
                "lng": -4.6333
            },
            "El Ejido": {
                "lat": 36.7831,
                "lng": -2.8167
            },
            "El Puerto de Santa Mar\u00eda": {
                "lat": 36.6015,
                "lng": -6.2381
            },
            "Torrevieja": {
                "lat": 37.9778,
                "lng": -0.6833
            },
            "Chiclana de la Frontera": {
                "lat": 36.4167,
                "lng": -6.15
            },
            "Torrente": {
                "lat": 39.4389,
                "lng": -0.4656
            },
            "Ciudad de Melilla": {
                "lat": 35.3,
                "lng": -2.95
            },
            "Toledo": {
                "lat": 39.8567,
                "lng": -4.0244
            },
            "Fuengirola": {
                "lat": 36.5417,
                "lng": -4.625
            },
            "V\u00e9lez-M\u00e1laga": {
                "lat": 36.7833,
                "lng": -4.1
            },
            "Arona": {
                "lat": 28.1,
                "lng": -16.6667
            },
            "San Baudilio de Llobregat": {
                "lat": 41.336,
                "lng": 2.043
            },
            "Talavera de la Reina": {
                "lat": 39.9583,
                "lng": -4.8328
            },
            "Ciudad de Ceuta": {
                "lat": 35.8883,
                "lng": -5.3156
            },
            "Pontevedra": {
                "lat": 42.4333,
                "lng": -8.6333
            },
            "Valdemoro": {
                "lat": 40.1908,
                "lng": -3.6742
            },
            "Orihuela": {
                "lat": 38.0856,
                "lng": -0.9469
            },
            "Rub\u00ed": {
                "lat": 41.4933,
                "lng": 2.0325
            },
            "Manresa": {
                "lat": 41.7294,
                "lng": 1.8272
            },
            "Gand\u00eda": {
                "lat": 38.9667,
                "lng": -0.1833
            },
            "Estepona": {
                "lat": 36.4264,
                "lng": -5.1472
            },
            "Alcal\u00e1 de Guadaira": {
                "lat": 37.3333,
                "lng": -5.85
            },
            "Palencia": {
                "lat": 42.0167,
                "lng": -4.5333
            },
            "Molina de Segura": {
                "lat": 38.0548,
                "lng": -1.2131
            },
            "Benalm\u00e1dena": {
                "lat": 36.6,
                "lng": -4.5167
            },
            "Avil\u00e9s": {
                "lat": 43.5561,
                "lng": -5.9083
            },
            "Ciudad Real": {
                "lat": 38.9833,
                "lng": -3.9167
            },
            "Paterna": {
                "lat": 39.5028,
                "lng": -0.4406
            },
            "Majadahonda": {
                "lat": 40.4728,
                "lng": -3.8722
            },
            "Benidorm": {
                "lat": 38.5342,
                "lng": -0.1314
            },
            "Torremolinos": {
                "lat": 36.6218,
                "lng": -4.5003
            },
            "Sagunto": {
                "lat": 39.6764,
                "lng": -0.2733
            },
            "Sanl\u00facar de Barrameda": {
                "lat": 36.7667,
                "lng": -6.35
            },
            "Castelldefels": {
                "lat": 41.286,
                "lng": 1.982
            },
            "Villanueva y Geltr\u00fa": {
                "lat": 41.2242,
                "lng": 1.7256
            },
            "Viladec\u00e1ns": {
                "lat": 41.3158,
                "lng": 2.0197
            },
            "Collado-Villalba": {
                "lat": 40.6333,
                "lng": -4.0083
            },
            "El Prat de Llobregat": {
                "lat": 41.3333,
                "lng": 2.1
            },
            "Arrecife": {
                "lat": 28.9625,
                "lng": -13.5506
            },
            "Ferrol": {
                "lat": 43.4844,
                "lng": -8.2328
            },
            "La L\u00ednea de la Concepci\u00f3n": {
                "lat": 36.1681,
                "lng": -5.3486
            },
            "Ponferrada": {
                "lat": 42.55,
                "lng": -6.5833
            },
            "Granollers": {
                "lat": 41.608,
                "lng": 2.288
            },
            "Ir\u00fan": {
                "lat": 43.3378,
                "lng": -1.7888
            },
            "Boadilla del Monte": {
                "lat": 40.4069,
                "lng": -3.875
            },
            "San Vicente del Raspeig": {
                "lat": 38.3964,
                "lng": -0.5253
            },
            "Aranjuez": {
                "lat": 40.0333,
                "lng": -3.6028
            },
            "Alcoy": {
                "lat": 38.6983,
                "lng": -0.4736
            },
            "M\u00e9rida": {
                "lat": 38.9,
                "lng": -6.3333
            },
            "Zamora": {
                "lat": 41.4989,
                "lng": -5.7556
            },
            "Motril": {
                "lat": 36.75,
                "lng": -3.5167
            },
            "\u00c1vila": {
                "lat": 40.65,
                "lng": -4.6833
            },
            "Pinto": {
                "lat": 40.25,
                "lng": -3.7
            },
            "Colmenar Viejo": {
                "lat": 40.6589,
                "lng": -3.7658
            },
            "Linares": {
                "lat": 38.0833,
                "lng": -3.6333
            },
            "Granadilla de Abona": {
                "lat": 28.1167,
                "lng": -16.5667
            },
            "Huesca": {
                "lat": 42.1333,
                "lng": -0.4167
            },
            "San Bartolom\u00e9": {
                "lat": 27.9256,
                "lng": -15.5725
            },
            "Cuenca": {
                "lat": 40.0667,
                "lng": -2.15
            },
            "Elda": {
                "lat": 38.4789,
                "lng": -0.7967
            },
            "Pola de Siero": {
                "lat": 43.3833,
                "lng": -5.65
            },
            "Ibiza": {
                "lat": 38.9089,
                "lng": 1.4328
            },
            "Villareal": {
                "lat": 39.9378,
                "lng": -0.1014
            },
            "Mollet": {
                "lat": 41.539,
                "lng": 2.213
            },
            "Utrera": {
                "lat": 37.183,
                "lng": -5.767
            },
            "Torrelavega": {
                "lat": 43.3531,
                "lng": -4.0458
            },
            "Rinc\u00f3n de la Victoria": {
                "lat": 36.7167,
                "lng": -4.2833
            },
            "Segovia": {
                "lat": 40.9481,
                "lng": -4.1183
            },
            "Adeje": {
                "lat": 28.1167,
                "lng": -16.7167
            },
            "Figueras": {
                "lat": 42.2667,
                "lng": 2.965
            },
            "Gav\u00e1": {
                "lat": 41.3072,
                "lng": 2.0039
            },
            "Vich": {
                "lat": 41.9303,
                "lng": 2.2547
            },
            "Mairena del Aljarafe": {
                "lat": 37.3333,
                "lng": -6.0667
            },
            "Esplugas de Llobregat": {
                "lat": 41.3767,
                "lng": 2.0858
            },
            "Alcira": {
                "lat": 39.15,
                "lng": -0.435
            },
            "Manacor": {
                "lat": 39.5667,
                "lng": 3.2
            },
            "Santurce-Antiguo": {
                "lat": 43.3303,
                "lng": -3.0314
            },
            "San Fel\u00edu de Llobregat": {
                "lat": 41.3833,
                "lng": 2.0439
            },
            "Mislata": {
                "lat": 39.475,
                "lng": -0.4156
            },
            "Denia": {
                "lat": 38.8444,
                "lng": 0.1111
            },
            "San Antonio Abad": {
                "lat": 37.6261,
                "lng": -0.9896
            },
            "Puertollano": {
                "lat": 38.6833,
                "lng": -4.1167
            },
            "Portugalete": {
                "lat": 43.3194,
                "lng": -3.0194
            },
            "Alhaur\u00edn de la Torre": {
                "lat": 36.6667,
                "lng": -4.55
            },
            "Puerto del Rosario": {
                "lat": 28.5,
                "lng": -13.8667
            },
            "Alcantarilla": {
                "lat": 37.9722,
                "lng": -1.2094
            },
            "Lucena": {
                "lat": 37.4089,
                "lng": -4.4853
            },
            "La Orotava": {
                "lat": 28.3906,
                "lng": -16.5247
            },
            "Puerto Real": {
                "lat": 36.5292,
                "lng": -6.1919
            },
            "Blanes": {
                "lat": 41.676,
                "lng": 2.793
            },
            "Lloret de Mar": {
                "lat": 41.7,
                "lng": 2.8333
            },
            "Santa Eulalia del R\u00edo": {
                "lat": 38.9847,
                "lng": 1.5336
            },
            "Antequera": {
                "lat": 37.0183,
                "lng": -4.5597
            },
            "Igualada": {
                "lat": 41.5833,
                "lng": 1.6167
            },
            "Villafranca del Panad\u00e9s": {
                "lat": 41.3447,
                "lng": 1.6994
            },
            "La Rinconada": {
                "lat": 37.4833,
                "lng": -5.9667
            },
            "Soria": {
                "lat": 41.7667,
                "lng": -2.4667
            },
            "Burjasot": {
                "lat": 39.5064,
                "lng": -0.4067
            },
            "Vendrell": {
                "lat": 41.2203,
                "lng": 1.5347
            },
            "\u00c9cija": {
                "lat": 37.5333,
                "lng": -5.0833
            },
            "Marratxi": {
                "lat": 39.6422,
                "lng": 2.7528
            },
            "Plasencia": {
                "lat": 40.0333,
                "lng": -6.1
            },
            "Nar\u00f3n": {
                "lat": 43.55,
                "lng": -8.15
            },
            "Lluchmayor": {
                "lat": 39.4833,
                "lng": 2.8833
            },
            "Torre-Pacheco": {
                "lat": 37.7333,
                "lng": -0.95
            },
            "San Fernando de Henares": {
                "lat": 40.4256,
                "lng": -3.5353
            },
            "Los Palacios y Villafranca": {
                "lat": 37.1625,
                "lng": -5.9242
            },
            "Arucas": {
                "lat": 28.1188,
                "lng": -15.5231
            },
            "Ripollet": {
                "lat": 41.4969,
                "lng": 2.1574
            },
            "Sama": {
                "lat": 43.3,
                "lng": -5.6833
            },
            "Olot": {
                "lat": 42.1833,
                "lng": 2.4833
            },
            "San Adri\u00e1n de Bes\u00f3s": {
                "lat": 41.4285,
                "lng": 2.223
            },
            "Santa Pola": {
                "lat": 38.1897,
                "lng": -0.5556
            },
            "Oleiros": {
                "lat": 43.3333,
                "lng": -8.3166
            },
            "Tudela": {
                "lat": 42.0653,
                "lng": -1.6067
            },
            "Villagarc\u00eda de Arosa": {
                "lat": 42.5977,
                "lng": -8.7632
            },
            "Don Benito": {
                "lat": 38.9545,
                "lng": -5.8617
            },
            "Moncada": {
                "lat": 39.5431,
                "lng": -0.3928
            },
            "\u00c1guilas": {
                "lat": 37.4042,
                "lng": -1.5819
            },
            "Cambrils": {
                "lat": 41.0667,
                "lng": 1.0583
            },
            "Tomelloso": {
                "lat": 39.1578,
                "lng": -3.0208
            },
            "Teruel": {
                "lat": 40.3436,
                "lng": -1.1072
            },
            "Mieres": {
                "lat": 43.2508,
                "lng": -5.7767
            },
            "Onteniente": {
                "lat": 38.8222,
                "lng": -0.6072
            },
            "El Plan": {
                "lat": 37.6405,
                "lng": -1.0085
            },
            "Maspalomas": {
                "lat": 27.7667,
                "lng": -15.5833
            },
            "Arroyomolinos": {
                "lat": 40.2667,
                "lng": -3.9
            },
            "And\u00fajar": {
                "lat": 38.0392,
                "lng": -4.0506
            },
            "Burriana": {
                "lat": 39.8894,
                "lng": -0.0925
            },
            "Miranda de Ebro": {
                "lat": 42.6833,
                "lng": -2.9333
            },
            "Cieza": {
                "lat": 38.2392,
                "lng": -1.4189
            },
            "San Javier": {
                "lat": 37.8037,
                "lng": -0.8343
            },
            "Azuqueca de Henares": {
                "lat": 40.5647,
                "lng": -3.2681
            },
            "Yecla": {
                "lat": 38.6136,
                "lng": -1.1147
            },
            "Galapagar": {
                "lat": 40.5764,
                "lng": -4.0019
            },
            "Villajoyosa": {
                "lat": 38.5053,
                "lng": -0.2328
            },
            "Tortosa": {
                "lat": 40.8128,
                "lng": 0.5233
            },
            "Inca": {
                "lat": 39.7167,
                "lng": 2.9167
            },
            "San Juan Desp\u00ed": {
                "lat": 41.3681,
                "lng": 2.0581
            },
            "Mazarr\u00f3n": {
                "lat": 37.5983,
                "lng": -1.3139
            },
            "\u00dabeda": {
                "lat": 38.0133,
                "lng": -3.3705
            },
            "Villena": {
                "lat": 38.635,
                "lng": -0.8658
            },
            "Almendralejo": {
                "lat": 38.6833,
                "lng": -6.4167
            },
            "San Roque": {
                "lat": 36.2097,
                "lng": -5.3844
            },
            "Aranda de Duero": {
                "lat": 41.6833,
                "lng": -3.6833
            },
            "Arteijo": {
                "lat": 43.3044,
                "lng": -8.5114
            },
            "Aldaya": {
                "lat": 39.4639,
                "lng": -0.4628
            },
            "Ronda": {
                "lat": 36.7372,
                "lng": -5.1647
            },
            "Castro-Urdiales": {
                "lat": 43.3844,
                "lng": -3.215
            },
            "Totana": {
                "lat": 37.7711,
                "lng": -1.5003
            },
            "N\u00edjar": {
                "lat": 36.9667,
                "lng": -2.2
            },
            "Salt": {
                "lat": 41.9761,
                "lng": 2.7881
            },
            "Am\u00e9s": {
                "lat": 42.9,
                "lng": -8.6333
            },
            "Ingenio": {
                "lat": 27.9214,
                "lng": -15.4325
            },
            "Sitges": {
                "lat": 41.237,
                "lng": 1.823
            },
            "San Pedro de Ribas": {
                "lat": 41.2597,
                "lng": 1.7736
            },
            "Manises": {
                "lat": 39.4833,
                "lng": -0.45
            },
            "Carballo": {
                "lat": 43.2167,
                "lng": -8.6833
            },
            "Navalcarnero": {
                "lat": 40.2847,
                "lng": -4.0136
            },
            "Arcos de la Frontera": {
                "lat": 36.75,
                "lng": -5.8
            },
            "Alc\u00e1zar de San Juan": {
                "lat": 39.4056,
                "lng": -3.2056
            },
            "Coria del R\u00edo": {
                "lat": 37.2833,
                "lng": -6.05
            },
            "Culleredo": {
                "lat": 43.2883,
                "lng": -8.3894
            },
            "Puerto de la Cruz": {
                "lat": 28.4167,
                "lng": -16.5333
            },
            "Calafell": {
                "lat": 41.202,
                "lng": 1.57
            },
            "Illescas": {
                "lat": 40.1167,
                "lng": -3.8333
            },
            "Hell\u00edn": {
                "lat": 38.5167,
                "lng": -1.6833
            },
            "Valdepe\u00f1as": {
                "lat": 38.7617,
                "lng": -3.385
            },
            "Salou": {
                "lat": 41.077,
                "lng": 1.144
            },
            "Crevillente": {
                "lat": 38.2486,
                "lng": -0.8089
            },
            "San Andr\u00e9s del Rabanedo": {
                "lat": 42.6167,
                "lng": -5.6
            },
            "Mah\u00f3n": {
                "lat": 39.8875,
                "lng": 4.2611
            },
            "Alacu\u00e1s": {
                "lat": 39.4583,
                "lng": -0.4628
            },
            "Puente-Genil": {
                "lat": 37.3833,
                "lng": -4.7667
            },
            "Durango": {
                "lat": 43.1689,
                "lng": -2.63
            },
            "Vinaroz": {
                "lat": 40.4686,
                "lng": 0.4736
            },
            "Rota": {
                "lat": 36.6167,
                "lng": -6.35
            },
            "Catarroja": {
                "lat": 39.4028,
                "lng": -0.4044
            },
            "Carmona": {
                "lat": 37.4667,
                "lng": -5.6333
            },
            "Redondela": {
                "lat": 42.2833,
                "lng": -8.6167
            },
            "La Oliva": {
                "lat": 28.6111,
                "lng": -13.9278
            },
            "Lepe": {
                "lat": 37.2542,
                "lng": -7.2033
            },
            "Premi\u00e1 de Mar": {
                "lat": 41.4919,
                "lng": 2.3619
            },
            "Martorell": {
                "lat": 41.477,
                "lng": 1.928
            },
            "Candelaria": {
                "lat": 28.3547,
                "lng": -16.3711
            },
            "Benicarl\u00f3": {
                "lat": 40.4167,
                "lng": 0.4167
            },
            "C\u00e1rtama": {
                "lat": 36.7114,
                "lng": -4.6306
            },
            "V\u00edcar": {
                "lat": 36.8317,
                "lng": -2.6431
            },
            "Camas": {
                "lat": 37.4,
                "lng": -6.0333
            },
            "Villaviciosa de Od\u00f3n": {
                "lat": 40.3583,
                "lng": -3.9033
            },
            "San Vicente dels Horts": {
                "lat": 41.3953,
                "lng": 2.0108
            },
            "Sese\u00f1a": {
                "lat": 40.1036,
                "lng": -3.6981
            },
            "Sueca": {
                "lat": 39.2,
                "lng": -0.3114
            },
            "Almazora": {
                "lat": 39.9403,
                "lng": -0.0625
            },
            "Sestao": {
                "lat": 43.3108,
                "lng": -3.0056
            },
            "Lebrija": {
                "lat": 36.9194,
                "lng": -6.0781
            },
            "San Pedro del Pinatar": {
                "lat": 37.8167,
                "lng": -0.75
            },
            "Algemes\u00ed": {
                "lat": 39.1897,
                "lng": -0.4378
            },
            "Almu\u00f1\u00e9car": {
                "lat": 36.7333,
                "lng": -3.6833
            },
            "Mor\u00f3n de la Frontera": {
                "lat": 37.1222,
                "lng": -5.4517
            },
            "Paiporta": {
                "lat": 39.4278,
                "lng": -0.4183
            },
            "Muchamiel": {
                "lat": 38.4136,
                "lng": -0.4456
            },
            "Santa Eugenia": {
                "lat": 42.5667,
                "lng": -8.9833
            },
            "Paracuellos de Jarama": {
                "lat": 40.55,
                "lng": -3.5167
            },
            "San Andr\u00e9s de la Barca": {
                "lat": 41.4478,
                "lng": 1.9769
            },
            "Alhaur\u00edn el Grande": {
                "lat": 36.6331,
                "lng": -4.6831
            },
            "Cangas": {
                "lat": 42.2642,
                "lng": -8.7819
            },
            "Jumilla": {
                "lat": 38.4792,
                "lng": -1.325
            },
            "B\u00e9tera": {
                "lat": 39.5933,
                "lng": -0.4628
            },
            "Mol\u00edns de Rey": {
                "lat": 41.4139,
                "lng": 2.0158
            },
            "Santa Perpetua de Moguda": {
                "lat": 41.5375,
                "lng": 2.1819
            },
            "Ciempozuelos": {
                "lat": 40.1592,
                "lng": -3.6183
            },
            "Calpe": {
                "lat": 38.645,
                "lng": 0.0442
            },
            "Alboraya": {
                "lat": 39.5,
                "lng": -0.3522
            },
            "Novelda": {
                "lat": 38.385,
                "lng": -0.768
            },
            "Villanueva de la Serena": {
                "lat": 38.9667,
                "lng": -5.8
            },
            "Oliva": {
                "lat": 38.9194,
                "lng": -0.1211
            },
            "Onda": {
                "lat": 39.9625,
                "lng": -0.2639
            },
            "Tomares": {
                "lat": 37.3764,
                "lng": -6.0458
            },
            "San Juan de Alicante": {
                "lat": 38.4014,
                "lng": -0.4367
            },
            "Almonte": {
                "lat": 37.2667,
                "lng": -6.5167
            },
            "Adra": {
                "lat": 36.7478,
                "lng": -3.0161
            },
            "Villarrobledo": {
                "lat": 39.2667,
                "lng": -2.6
            },
            "Torrelodones": {
                "lat": 40.5756,
                "lng": -3.9292
            },
            "Armilla": {
                "lat": 37.15,
                "lng": -3.6167
            },
            "Co\u00edn": {
                "lat": 36.6667,
                "lng": -4.75
            },
            "Valls": {
                "lat": 41.2883,
                "lng": 1.2519
            },
            "Calahorra": {
                "lat": 42.3,
                "lng": -1.9667
            },
            "Gald\u00e1cano": {
                "lat": 43.2306,
                "lng": -2.8458
            },
            "Cambre": {
                "lat": 43.283,
                "lng": -8.333
            },
            "Liria": {
                "lat": 39.6258,
                "lng": -0.5942
            },
            "Olesa de Montserrat": {
                "lat": 41.545,
                "lng": 1.8944
            },
            "Erandio": {
                "lat": 43.3047,
                "lng": -2.9731
            },
            "Martos": {
                "lat": 37.7167,
                "lng": -3.9667
            },
            "Almansa": {
                "lat": 38.8682,
                "lng": -1.0979
            },
            "Los Barrios": {
                "lat": 36.1833,
                "lng": -5.4833
            },
            "Mar\u00edn": {
                "lat": 42.3933,
                "lng": -8.7
            },
            "Mairena del Alcor": {
                "lat": 37.3667,
                "lng": -5.7333
            },
            "Icod de los Vinos": {
                "lat": 28.35,
                "lng": -16.7
            },
            "Ibi": {
                "lat": 38.6272,
                "lng": -0.5753
            },
            "Mejorada del Campo": {
                "lat": 40.3967,
                "lng": -3.3858
            },
            "Altea": {
                "lat": 38.5986,
                "lng": -0.0519
            },
            "Cullera": {
                "lat": 39.1639,
                "lng": -0.2517
            },
            "Conil de la Frontera": {
                "lat": 36.2667,
                "lng": -6.0833
            },
            "Pilar de la Horadada": {
                "lat": 37.8667,
                "lng": -0.7833
            },
            "Teguise": {
                "lat": 29.05,
                "lng": -13.5667
            },
            "Alhama de Murcia": {
                "lat": 37.8514,
                "lng": -1.4264
            },
            "Vilaseca de Solcina": {
                "lat": 41.1108,
                "lng": 1.145
            },
            "Puenteareas": {
                "lat": 42.1667,
                "lng": -8.5
            },
            "Zarautz": {
                "lat": 43.2833,
                "lng": -2.1667
            },
            "Moguer": {
                "lat": 37.2747,
                "lng": -6.8386
            },
            "El Palmar": {
                "lat": 37.9422,
                "lng": -1.1614
            },
            "Villanueva de la Ca\u00f1ada": {
                "lat": 40.45,
                "lng": -3.9833
            },
            "Torre del Mar": {
                "lat": 36.75,
                "lng": -4.0833
            },
            "Barbate de Franco": {
                "lat": 36.1833,
                "lng": -5.9167
            },
            "Bormujos": {
                "lat": 37.3667,
                "lng": -6.0667
            },
            "San Fel\u00edu de Guixols": {
                "lat": 41.7833,
                "lng": 3.0333
            },
            "Laguna de Duero": {
                "lat": 41.5833,
                "lng": -4.7167
            },
            "San Juan de Aznalfarache": {
                "lat": 37.3667,
                "lng": -6.0167
            },
            "Esparraguera": {
                "lat": 41.5381,
                "lng": 1.8692
            },
            "Gu\u00eda de Isora": {
                "lat": 28.211,
                "lng": -16.7784
            },
            "Montilla": {
                "lat": 37.5833,
                "lng": -4.6333
            },
            "Maracena": {
                "lat": 37.2,
                "lng": -3.6333
            },
            "Aljaraque": {
                "lat": 37.2667,
                "lng": -7.0167
            },
            "Picasent": {
                "lat": 39.3611,
                "lng": -0.46
            },
            "Las Torres de Cotillas": {
                "lat": 38.0264,
                "lng": -1.2436
            },
            "Priego de C\u00f3rdoba": {
                "lat": 37.4333,
                "lng": -4.1833
            },
            "Nerja": {
                "lat": 36.7469,
                "lng": -3.8789
            },
            "Alfafar": {
                "lat": 39.4222,
                "lng": -0.3906
            },
            "Almorad\u00ed": {
                "lat": 38.1097,
                "lng": -0.7894
            },
            "Amposta": {
                "lat": 40.7106,
                "lng": 0.5808
            },
            "Ayamonte": {
                "lat": 37.2,
                "lng": -7.4
            },
            "Isla-Cristina": {
                "lat": 37.1992,
                "lng": -7.3214
            },
            "Alcal\u00e1 la Real": {
                "lat": 37.45,
                "lng": -3.9167
            },
            "G\u00fcimar": {
                "lat": 28.315,
                "lng": -16.41
            },
            "Aspe": {
                "lat": 38.3456,
                "lng": -0.7689
            },
            "Alcudia": {
                "lat": 39.8525,
                "lng": 3.1192
            },
            "P\u00e1jara": {
                "lat": 28.3511,
                "lng": -14.1075
            },
            "Manlleu": {
                "lat": 42.0,
                "lng": 2.2836
            },
            "Cartaya": {
                "lat": 37.2833,
                "lng": -7.15
            },
            "San Juan de Vilasar": {
                "lat": 41.506,
                "lng": 2.391
            },
            "Palafrugell": {
                "lat": 41.9174,
                "lng": 3.1631
            },
            "Torrox": {
                "lat": 36.75,
                "lng": -3.95
            },
            "La Uni\u00f3n": {
                "lat": 37.6192,
                "lng": -0.8756
            },
            "Carcagente": {
                "lat": 39.1222,
                "lng": -0.4489
            },
            "Algete": {
                "lat": 40.5978,
                "lng": -3.5003
            },
            "Puzol": {
                "lat": 39.6167,
                "lng": -0.3011
            },
            "Palma del R\u00edo": {
                "lat": 37.7,
                "lng": -5.2833
            },
            "Los Llanos de Aridane": {
                "lat": 28.65,
                "lng": -17.9
            },
            "Loja": {
                "lat": 37.1667,
                "lng": -4.15
            },
            "Baza": {
                "lat": 37.4833,
                "lng": -2.7667
            },
            "Hernani": {
                "lat": 43.2667,
                "lng": -1.9667
            },
            "Ba\u00f1olas": {
                "lat": 42.1194,
                "lng": 2.7664
            },
            "Sant Just Desvern": {
                "lat": 41.383,
                "lng": 2.075
            },
            "Hu\u00e9rcal-Overa": {
                "lat": 37.3833,
                "lng": -1.9333
            },
            "Requena": {
                "lat": 39.4861,
                "lng": -1.1017
            },
            "Lal\u00edn": {
                "lat": 42.65,
                "lng": -8.1167
            },
            "Rosas": {
                "lat": 42.2631,
                "lng": 3.1831
            },
            "Archena": {
                "lat": 38.115,
                "lng": -1.2992
            },
            "Medina del Campo": {
                "lat": 41.3,
                "lng": -4.9167
            },
            "Cabra": {
                "lat": 37.4718,
                "lng": -4.4336
            },
            "Atarfe": {
                "lat": 37.2229,
                "lng": -3.6899
            },
            "Benicasim": {
                "lat": 40.0553,
                "lng": 0.0642
            },
            "San Mart\u00edn de la Vega": {
                "lat": 40.2094,
                "lng": -3.5722
            },
            "Tolosa": {
                "lat": 43.1333,
                "lng": -2.0833
            },
            "Calella": {
                "lat": 41.6128,
                "lng": 2.6575
            },
            "Humanes de Madrid": {
                "lat": 40.2539,
                "lng": -3.8278
            },
            "Silla": {
                "lat": 39.3611,
                "lng": -0.4114
            },
            "Calatayud": {
                "lat": 41.35,
                "lng": -1.6333
            },
            "Chipiona": {
                "lat": 36.7333,
                "lng": -6.4333
            },
            "Bara\u00f1\u00e1in": {
                "lat": 42.8,
                "lng": -1.6667
            },
            "Amorebieta": {
                "lat": 43.2192,
                "lng": -2.7342
            },
            "El Arahal": {
                "lat": 37.2667,
                "lng": -5.55
            },
            "Callosa de Segura": {
                "lat": 38.1225,
                "lng": -0.8797
            },
            "Albolote": {
                "lat": 37.2306,
                "lng": -3.6569
            },
            "Moa\u00f1a": {
                "lat": 42.2833,
                "lng": -8.75
            },
            "Marchena": {
                "lat": 37.3333,
                "lng": -5.4167
            },
            "El Viso del Alcor": {
                "lat": 37.3833,
                "lng": -5.7167
            },
            "Malgrat de Mar": {
                "lat": 41.65,
                "lng": 2.75
            },
            "La Corredor\u00eda": {
                "lat": 43.3853,
                "lng": -5.8208
            },
            "La Eliana": {
                "lat": 39.5661,
                "lng": -0.5281
            },
            "Cardedeu": {
                "lat": 41.6406,
                "lng": 2.3594
            },
            "Boiro": {
                "lat": 42.65,
                "lng": -8.9
            },
            "La Nuc\u00eda": {
                "lat": 38.6172,
                "lng": -0.1231
            },
            "Utebo": {
                "lat": 41.7167,
                "lng": -1.0
            },
            "Vera": {
                "lat": 37.25,
                "lng": -1.8667
            },
            "Villaquilambre": {
                "lat": 42.6167,
                "lng": -5.6
            },
            "Tarifa": {
                "lat": 36.014,
                "lng": -5.606
            },
            "Los Alc\u00e1zares": {
                "lat": 37.7436,
                "lng": -0.8497
            },
            "Felanitx": {
                "lat": 39.4669,
                "lng": 3.1483
            },
            "Baena": {
                "lat": 37.6167,
                "lng": -4.3167
            },
            "Guadix": {
                "lat": 37.3,
                "lng": -3.1333
            },
            "Hu\u00e9rcal de Almer\u00eda": {
                "lat": 36.8833,
                "lng": -2.4333
            },
            "San Lorenzo de El Escorial": {
                "lat": 40.5936,
                "lng": -4.1428
            },
            "San Celoni": {
                "lat": 41.69,
                "lng": 2.49
            },
            "Lora del R\u00edo": {
                "lat": 37.65,
                "lng": -5.5167
            },
            "T\u00e1rrega": {
                "lat": 41.6469,
                "lng": 1.1392
            },
            "Monforte de Lemos": {
                "lat": 42.5164,
                "lng": -7.5161
            },
            "El Astillero": {
                "lat": 43.4017,
                "lng": -3.8194
            },
            "Palam\u00f3s": {
                "lat": 41.85,
                "lng": 3.1292
            },
            "Caldas de Montbuy": {
                "lat": 41.6292,
                "lng": 2.1694
            },
            "Fuente-\u00c1lamo de Murcia": {
                "lat": 37.7394,
                "lng": -1.1881
            },
            "Nigr\u00e1n": {
                "lat": 42.1419,
                "lng": -8.8056
            },
            "Tordera": {
                "lat": 41.7008,
                "lng": 2.72
            },
            "Mungu\u00eda": {
                "lat": 43.3547,
                "lng": -2.8472
            },
            "Manilva": {
                "lat": 36.3833,
                "lng": -5.25
            },
            "Sangenjo": {
                "lat": 42.4017,
                "lng": -8.8067
            },
            "Manzanares": {
                "lat": 38.9964,
                "lng": -3.3731
            },
            "Torredembarra": {
                "lat": 41.15,
                "lng": 1.4
            },
            "Monz\u00f3n": {
                "lat": 41.91,
                "lng": 0.19
            },
            "Balaguer": {
                "lat": 41.79,
                "lng": 0.805
            },
            "Daimiel": {
                "lat": 39.0833,
                "lng": -3.6167
            },
            "Rojales": {
                "lat": 38.0833,
                "lng": -0.7167
            },
            "Tabernes de Valldigna": {
                "lat": 39.0722,
                "lng": -0.2658
            },
            "Osuna": {
                "lat": 37.2333,
                "lng": -5.1
            },
            "Villanueva del Pardillo": {
                "lat": 40.4833,
                "lng": -3.9667
            },
            "Mula": {
                "lat": 38.0419,
                "lng": -1.4906
            },
            "Lumbreras": {
                "lat": 37.5633,
                "lng": -1.8072
            },
            "Guardamar del Segura": {
                "lat": 38.0897,
                "lng": -0.655
            },
            "Poyo": {
                "lat": 42.4333,
                "lng": -8.6667
            },
            "Barbastro": {
                "lat": 42.0361,
                "lng": 0.1264
            },
            "Pollensa": {
                "lat": 39.8772,
                "lng": 3.0164
            },
            "Benavente": {
                "lat": 42.0031,
                "lng": -5.6742
            },
            "Bail\u00e9n": {
                "lat": 38.0833,
                "lng": -3.7667
            },
            "Piera": {
                "lat": 41.5222,
                "lng": 1.7494
            },
            "Castilleja de la Cuesta": {
                "lat": 37.3833,
                "lng": -6.05
            },
            "Ejea de los Caballeros": {
                "lat": 42.1292,
                "lng": -1.1372
            },
            "Guadarrama": {
                "lat": 40.6728,
                "lng": -4.0889
            },
            "La Garriga": {
                "lat": 41.6833,
                "lng": 2.2833
            },
            "Canovellas": {
                "lat": 41.6203,
                "lng": 2.2847
            },
            "Guernica y Luno": {
                "lat": 43.3167,
                "lng": -2.6667
            },
            "Albal": {
                "lat": 39.3972,
                "lng": -0.4156
            },
            "Berga": {
                "lat": 42.1011,
                "lng": 1.8439
            },
            "Pozoblanco": {
                "lat": 38.3833,
                "lng": -4.85
            },
            "Cubellas": {
                "lat": 41.21,
                "lng": 1.6736
            },
            "Navalmoral de la Mata": {
                "lat": 39.8983,
                "lng": -5.5403
            },
            "Bermeo": {
                "lat": 43.42,
                "lng": -2.7264
            },
            "Sada": {
                "lat": 43.35,
                "lng": -8.25
            },
            "Montorn\u00e9s del Vall\u00e9s": {
                "lat": 41.5333,
                "lng": 2.2667
            },
            "Masamagrell": {
                "lat": 39.5703,
                "lng": -0.33
            },
            "Chiva": {
                "lat": 39.4714,
                "lng": -0.7197
            },
            "Zafra": {
                "lat": 38.4167,
                "lng": -6.4167
            },
            "El Escorial": {
                "lat": 40.5817,
                "lng": -4.1258
            },
            "La Algaba": {
                "lat": 37.45,
                "lng": -6.0
            },
            "Espartinas": {
                "lat": 37.3833,
                "lng": -6.1167
            },
            "Churriana de la Vega": {
                "lat": 37.15,
                "lng": -3.65
            },
            "Las Cabezas de San Juan": {
                "lat": 36.9817,
                "lng": -5.9406
            },
            "Ubrique": {
                "lat": 36.6833,
                "lng": -5.45
            },
            "Arenys de Mar": {
                "lat": 41.5819,
                "lng": 2.5503
            },
            "Santomera": {
                "lat": 38.0617,
                "lng": -1.0492
            },
            "Aguadulce": {
                "lat": 36.7642,
                "lng": -2.6147
            },
            "Alca\u00f1iz": {
                "lat": 41.0511,
                "lng": -0.1336
            },
            "Carlet": {
                "lat": 39.2264,
                "lng": -0.5211
            },
            "Punta Umbr\u00eda": {
                "lat": 37.1667,
                "lng": -6.95
            },
            "Tuineje": {
                "lat": 28.325,
                "lng": -14.0486
            },
            "Benet\u00faser": {
                "lat": 39.425,
                "lng": -0.3961
            },
            "Villalbilla": {
                "lat": 40.4339,
                "lng": -3.2989
            },
            "Vallirana": {
                "lat": 41.3878,
                "lng": 1.9322
            },
            "Taranc\u00f3n": {
                "lat": 40.0167,
                "lng": -3.0
            },
            "Miguelturra": {
                "lat": 38.9667,
                "lng": -3.95
            },
            "Baeza": {
                "lat": 37.9833,
                "lng": -3.4667
            },
            "Erm\u00faa": {
                "lat": 43.1875,
                "lng": -2.5008
            },
            "La Roda": {
                "lat": 39.207,
                "lng": -2.1604
            },
            "Montijo": {
                "lat": 38.91,
                "lng": -6.6175
            },
            "Corbera de Llobregat": {
                "lat": 41.4169,
                "lng": 1.9314
            },
            "Santa Cruz de la Palma": {
                "lat": 28.6825,
                "lng": -17.765
            },
            "Meco": {
                "lat": 40.5539,
                "lng": -3.3261
            },
            "Fraga": {
                "lat": 41.52,
                "lng": 0.35
            },
            "Santa \u00darsula": {
                "lat": 28.425,
                "lng": -16.4917
            },
            "Cunit": {
                "lat": 41.1983,
                "lng": 1.6406
            },
            "La Solana": {
                "lat": 38.65,
                "lng": -3.0667
            },
            "Vieiro": {
                "lat": 43.6481,
                "lng": -7.59
            },
            "Arnedo": {
                "lat": 42.2167,
                "lng": -2.1
            },
            "Azpeitia": {
                "lat": 43.1819,
                "lng": -2.2653
            },
            "Gondomar": {
                "lat": 42.1111,
                "lng": -8.7611
            },
            "Mollerusa": {
                "lat": 41.6319,
                "lng": 0.8961
            },
            "Cuevas del Almanzora": {
                "lat": 37.3,
                "lng": -1.8667
            },
            "Canet de Mar": {
                "lat": 41.5833,
                "lng": 2.5833
            },
            "Alpedrete": {
                "lat": 40.6583,
                "lng": -4.0322
            },
            "Villaviciosa": {
                "lat": 43.4833,
                "lng": -5.4333
            },
            "Torell\u00f3": {
                "lat": 42.0495,
                "lng": 2.265
            },
            "Sopelana": {
                "lat": 43.3814,
                "lng": -2.9792
            },
            "Og\u00edjares": {
                "lat": 37.1167,
                "lng": -3.6
            },
            "La Carolina": {
                "lat": 38.2667,
                "lng": -3.6167
            },
            "Cuarte de Huerva": {
                "lat": 41.5833,
                "lng": -0.9167
            },
            "Santa Marta de Tormes": {
                "lat": 40.9494,
                "lng": -5.6325
            },
            "Andoain": {
                "lat": 43.2167,
                "lng": -2.0167
            },
            "Ceheg\u00edn": {
                "lat": 38.0925,
                "lng": -1.7989
            },
            "Alginet": {
                "lat": 39.2625,
                "lng": -0.4683
            },
            "Bollullos par del Condado": {
                "lat": 37.3358,
                "lng": -6.5364
            },
            "La Carlota": {
                "lat": 37.6667,
                "lng": -4.9333
            },
            "Sanl\u00facar la Mayor": {
                "lat": 37.3831,
                "lng": -6.2
            },
            "Noya": {
                "lat": 42.7833,
                "lng": -8.8833
            },
            "Pilas": {
                "lat": 37.3017,
                "lng": -6.2986
            },
            "Beasain": {
                "lat": 43.0458,
                "lng": -2.1894
            },
            "Llanera": {
                "lat": 43.4667,
                "lng": -5.9333
            },
            "Moralzarzal": {
                "lat": 40.675,
                "lng": -3.9694
            },
            "Torrijos": {
                "lat": 39.9833,
                "lng": -4.2814
            },
            "Torre del Campo": {
                "lat": 37.7667,
                "lng": -3.8833
            },
            "Villalba": {
                "lat": 43.3,
                "lng": -7.6833
            },
            "Oca\u00f1a": {
                "lat": 39.9569,
                "lng": -3.4967
            },
            "Tomi\u00f1o": {
                "lat": 41.9833,
                "lng": -8.7167
            },
            "Nules": {
                "lat": 39.8525,
                "lng": -0.1506
            },
            "Santa Cruz de Bezana": {
                "lat": 43.4442,
                "lng": -3.9031
            },
            "Cambados": {
                "lat": 42.5,
                "lng": -8.8
            },
            "San Agust\u00edn de Guadalix": {
                "lat": 40.6781,
                "lng": -3.615
            },
            "Santa Coloma de Farn\u00e9s": {
                "lat": 41.8647,
                "lng": 2.6642
            },
            "Jaca": {
                "lat": 42.55,
                "lng": -0.55
            },
            "Ver\u00edn": {
                "lat": 41.9408,
                "lng": -7.4358
            },
            "S\u00f3ller": {
                "lat": 39.7675,
                "lng": 2.714
            },
            "Llanes": {
                "lat": 43.4214,
                "lng": -4.7564
            },
            "Antigua": {
                "lat": 28.4186,
                "lng": -14.0111
            },
            "Gines": {
                "lat": 37.3875,
                "lng": -6.0781
            },
            "Valdemorillo": {
                "lat": 40.5017,
                "lng": -4.0667
            },
            "Torredonjimeno": {
                "lat": 37.7667,
                "lng": -3.95
            },
            "Aguilar": {
                "lat": 37.5167,
                "lng": -4.65
            },
            "Guillena": {
                "lat": 37.5333,
                "lng": -6.05
            },
            "Sarria": {
                "lat": 42.7833,
                "lng": -7.4167
            },
            "Muriedas": {
                "lat": 43.4303,
                "lng": -3.8603
            },
            "La Llagosta": {
                "lat": 41.5156,
                "lng": 2.1928
            },
            "Canals": {
                "lat": 38.9611,
                "lng": -0.585
            },
            "Santa Margarita": {
                "lat": 39.7033,
                "lng": 3.1036
            },
            "Alovera": {
                "lat": 40.5967,
                "lng": -3.2481
            },
            "Albatera": {
                "lat": 38.1786,
                "lng": -0.8681
            },
            "Betanzos": {
                "lat": 43.2792,
                "lng": -8.2106
            },
            "Godella": {
                "lat": 39.52,
                "lng": -0.4114
            },
            "Abar\u00e1n": {
                "lat": 38.2031,
                "lng": -1.4003
            },
            "Campo de Criptana": {
                "lat": 39.4,
                "lng": -3.1167
            },
            "Sant Sadurn\u00ed d\u2019Anoia": {
                "lat": 41.4261,
                "lng": 1.785
            },
            "Gibrale\u00f3n": {
                "lat": 37.3753,
                "lng": -6.9694
            },
            "Berja": {
                "lat": 36.8453,
                "lng": -2.9469
            },
            "Vejer de la Frontera": {
                "lat": 36.25,
                "lng": -5.9667
            },
            "Abrera": {
                "lat": 41.5167,
                "lng": 1.9
            },
            "Argentona": {
                "lat": 41.55,
                "lng": 2.4
            },
            "Castellbisbal": {
                "lat": 41.476,
                "lng": 1.983
            },
            "Brenes": {
                "lat": 37.55,
                "lng": -5.8667
            },
            "Teror": {
                "lat": 28.0589,
                "lng": -15.5475
            },
            "Velilla de San Antonio": {
                "lat": 40.3667,
                "lng": -3.4833
            },
            "Vilanova del Cam\u00ed": {
                "lat": 41.5667,
                "lng": 1.6333
            },
            "Ceuti": {
                "lat": 38.0789,
                "lng": -1.2722
            },
            "Valverde del Camino": {
                "lat": 37.5667,
                "lng": -6.75
            },
            "Salobre\u00f1a": {
                "lat": 36.7467,
                "lng": -3.5869
            },
            "Fene": {
                "lat": 43.4667,
                "lng": -8.1667
            },
            "Mon\u00f3var": {
                "lat": 38.4369,
                "lng": -0.8381
            },
            "Palos de la Frontera": {
                "lat": 37.2278,
                "lng": -6.8932
            },
            "Teulada": {
                "lat": 38.7292,
                "lng": 0.1019
            },
            "Santa\u00f1y": {
                "lat": 39.3542,
                "lng": 3.1283
            },
            "Mongat": {
                "lat": 41.469,
                "lng": 2.2805
            },
            "Bayona": {
                "lat": 42.1178,
                "lng": -8.8506
            },
            "Estepa": {
                "lat": 37.2917,
                "lng": -4.8792
            },
            "Villafranca de los Barros": {
                "lat": 38.5667,
                "lng": -6.3333
            },
            "Torroella de Montgr\u00ed": {
                "lat": 42.0439,
                "lng": 3.1286
            },
            "Pola de Laviana": {
                "lat": 43.2358,
                "lng": -5.5563
            },
            "Capdepera": {
                "lat": 39.7,
                "lng": 3.4167
            },
            "Acal\u00e1 del R\u00edo": {
                "lat": 37.5167,
                "lng": -5.9667
            },
            "Benisa": {
                "lat": 38.7145,
                "lng": 0.0527
            },
            "B\u00e9jar": {
                "lat": 40.3833,
                "lng": -5.7667
            },
            "Villamart\u00edn": {
                "lat": 36.8667,
                "lng": -5.65
            },
            "Hu\u00e9tor Vega": {
                "lat": 37.15,
                "lng": -3.5667
            },
            "Socu\u00e9llamos": {
                "lat": 39.2933,
                "lng": -2.7942
            },
            "Albox": {
                "lat": 37.3833,
                "lng": -2.1333
            },
            "Ciudad-Rodrigo": {
                "lat": 40.5969,
                "lng": -6.5392
            },
            "Fuensalida": {
                "lat": 40.05,
                "lng": -4.2
            },
            "Bola\u00f1os de Calatrava": {
                "lat": 38.8831,
                "lng": -3.7167
            },
            "Benifay\u00f3": {
                "lat": 39.2847,
                "lng": -0.4281
            },
            "Santiago del Teide": {
                "lat": 28.2972,
                "lng": -16.8139
            },
            "Arrigorriaga": {
                "lat": 43.2078,
                "lng": -2.8861
            },
            "Bueu": {
                "lat": 42.3167,
                "lng": -8.7833
            },
            "Benaguacil": {
                "lat": 39.5933,
                "lng": -0.5864
            },
            "La Puebla del R\u00edo": {
                "lat": 37.2667,
                "lng": -6.05
            },
            "Son Servera": {
                "lat": 39.6208,
                "lng": 3.36
            },
            "Andraitx": {
                "lat": 39.5833,
                "lng": 2.4167
            },
            "San Andr\u00e9s de Llevaneras": {
                "lat": 41.5733,
                "lng": 2.4828
            },
            "Olivenza": {
                "lat": 38.6858,
                "lng": -7.1008
            },
            "Campos del Puerto": {
                "lat": 39.4306,
                "lng": 3.0194
            },
            "Pica\u00f1a": {
                "lat": 39.4361,
                "lng": -0.435
            },
            "Medina Sidonia": {
                "lat": 36.4667,
                "lng": -5.9167
            },
            "Pallej\u00e1": {
                "lat": 41.4242,
                "lng": 1.9978
            },
            "Peligros": {
                "lat": 37.2333,
                "lng": -3.6333
            },
            "Bullas": {
                "lat": 38.0497,
                "lng": -1.6706
            },
            "Azcoitia": {
                "lat": 43.1792,
                "lng": -2.3106
            },
            "Utiel": {
                "lat": 39.5672,
                "lng": -1.2067
            },
            "Castell\u00f3 de Ampurias": {
                "lat": 42.2583,
                "lng": 3.075
            },
            "Cangas de Narcea": {
                "lat": 43.1714,
                "lng": -6.5389
            },
            "O\u00f1ate": {
                "lat": 43.0333,
                "lng": -2.4167
            },
            "Beniel": {
                "lat": 38.0464,
                "lng": -1.0014
            },
            "Elg\u00f3ibar": {
                "lat": 43.2142,
                "lng": -2.4169
            },
            "Cocentaina": {
                "lat": 38.745,
                "lng": -0.4406
            },
            "J\u00f3dar": {
                "lat": 37.8333,
                "lng": -3.35
            },
            "Mancha Real": {
                "lat": 37.7864,
                "lng": -3.6125
            },
            "Castalla": {
                "lat": 38.5967,
                "lng": -0.6708
            },
            "Tegueste": {
                "lat": 28.5167,
                "lng": -16.3333
            },
            "Lardero": {
                "lat": 42.4333,
                "lng": -2.4667
            },
            "Pulp\u00ed": {
                "lat": 37.4019,
                "lng": -1.7508
            },
            "Beniaj\u00e1n": {
                "lat": 37.9833,
                "lng": -1.0667
            },
            "Sonseca": {
                "lat": 39.7,
                "lng": -3.95
            },
            "Bollullos de la Mitaci\u00f3n": {
                "lat": 37.3333,
                "lng": -6.1333
            },
            "Barbadanes": {
                "lat": 42.3003,
                "lng": -7.9064
            },
            "Quintanar de la Orden": {
                "lat": 39.5906,
                "lng": -3.0428
            },
            "Fortuna": {
                "lat": 38.1789,
                "lng": -1.1233
            },
            "Bargas": {
                "lat": 39.94,
                "lng": -4.0194
            },
            "Cabanillas del Campo": {
                "lat": 40.6383,
                "lng": -3.2353
            },
            "Berriozar": {
                "lat": 42.8361,
                "lng": -1.6714
            },
            "Meliana": {
                "lat": 39.5272,
                "lng": -0.3492
            },
            "Santo\u00f1a": {
                "lat": 43.4414,
                "lng": -3.4575
            },
            "La Puebla de Cazalla": {
                "lat": 37.2222,
                "lng": -5.3125
            },
            "Sant Joan de Vilatorrada": {
                "lat": 41.75,
                "lng": 1.8
            },
            "Rianxo": {
                "lat": 42.65,
                "lng": -8.8167
            },
            "Laredo": {
                "lat": 43.4144,
                "lng": -3.41
            },
            "Brunete": {
                "lat": 40.4,
                "lng": -3.9936
            },
            "Cass\u00e1 de la Selva": {
                "lat": 41.8892,
                "lng": 2.8772
            },
            "Alberique": {
                "lat": 39.1167,
                "lng": -0.5211
            },
            "Yuncos": {
                "lat": 40.0833,
                "lng": -3.8667
            },
            "Los Corrales de Buelna": {
                "lat": 43.2617,
                "lng": -4.0653
            },
            "Cantillana": {
                "lat": 37.6,
                "lng": -5.8167
            },
            "Villamarchante": {
                "lat": 39.5689,
                "lng": -0.6206
            },
            "La Palma del Condado": {
                "lat": 37.3842,
                "lng": -6.5517
            },
            "Ripoll": {
                "lat": 42.2006,
                "lng": 2.1928
            },
            "Tafalla": {
                "lat": 42.5277,
                "lng": -1.6745
            },
            "Hu\u00e9tor-T\u00e1jar": {
                "lat": 37.1947,
                "lng": -4.0464
            },
            "Daganzo de Arriba": {
                "lat": 40.5433,
                "lng": -3.4572
            },
            "Sedav\u00ed": {
                "lat": 39.425,
                "lng": -0.385
            },
            "Tarazona de Arag\u00f3n": {
                "lat": 41.9044,
                "lng": -1.7225
            },
            "Alcora": {
                "lat": 40.0667,
                "lng": -0.2
            },
            "Gri\u00f1\u00f3n": {
                "lat": 40.2167,
                "lng": -3.85
            },
            "La Escala": {
                "lat": 42.114,
                "lng": 3.139
            },
            "Luanco": {
                "lat": 43.61,
                "lng": -5.79
            },
            "Santa Margarita de Momb\u00fay": {
                "lat": 41.5756,
                "lng": 1.6092
            },
            "Gelves": {
                "lat": 37.3333,
                "lng": -6.0167
            },
            "Villacarrillo": {
                "lat": 38.1,
                "lng": -3.0833
            },
            "Garrucha": {
                "lat": 37.1842,
                "lng": -1.8214
            },
            "Caspe": {
                "lat": 41.2333,
                "lng": -0.0333
            },
            "El \u00c1lamo": {
                "lat": 40.2306,
                "lng": -3.9944
            },
            "Astorga": {
                "lat": 42.4589,
                "lng": -6.0633
            },
            "Pe\u00f1arroya-Pueblonuevo": {
                "lat": 38.3,
                "lng": -5.2667
            },
            "Amurrio": {
                "lat": 43.0528,
                "lng": -3.0014
            },
            "Pola de Lena": {
                "lat": 43.1583,
                "lng": -5.8292
            },
            "Salvatierra de Mi\u00f1o": {
                "lat": 42.0833,
                "lng": -8.5
            },
            "Alcaudete": {
                "lat": 37.5833,
                "lng": -4.1
            },
            "Villanueva de Arosa": {
                "lat": 42.5628,
                "lng": -8.8278
            },
            "Alguazas": {
                "lat": 38.0514,
                "lng": -1.2414
            },
            "Alhend\u00edn": {
                "lat": 37.1167,
                "lng": -3.65
            },
            "Zumaia": {
                "lat": 43.2972,
                "lng": -2.2569
            },
            "Madridejos": {
                "lat": 39.4667,
                "lng": -3.5333
            },
            "Canteras": {
                "lat": 37.6122,
                "lng": -1.0438
            },
            "Foz": {
                "lat": 43.5694,
                "lng": -7.2583
            },
            "Calasparra": {
                "lat": 38.2306,
                "lng": -1.7
            },
            "Masanasa": {
                "lat": 39.4083,
                "lng": -0.3989
            },
            "Caudete": {
                "lat": 38.7044,
                "lng": -0.9881
            },
            "Alella": {
                "lat": 41.495,
                "lng": 2.296
            },
            "Caba\u00f1aquinta": {
                "lat": 43.1,
                "lng": -5.5833
            },
            "Sax": {
                "lat": 38.5394,
                "lng": -0.8161
            },
            "Bin\u00e9far": {
                "lat": 41.85,
                "lng": 0.3
            },
            "San Vicente de Castellet": {
                "lat": 41.6678,
                "lng": 1.8633
            },
            "La Ba\u00f1eza": {
                "lat": 42.2975,
                "lng": -5.9017
            },
            "Mengibar": {
                "lat": 37.9683,
                "lng": -3.8089
            },
            "Morayra": {
                "lat": 38.688,
                "lng": 0.1345
            },
            "Torrej\u00f3n de la Calzada": {
                "lat": 40.2,
                "lng": -3.8
            },
            "Consuegra": {
                "lat": 39.4619,
                "lng": -3.6064
            },
            "Matadepera": {
                "lat": 41.6036,
                "lng": 2.0244
            },
            "Pizarra": {
                "lat": 36.7667,
                "lng": -4.7
            },
            "Santa Mar\u00eda de Palautordera": {
                "lat": 41.6953,
                "lng": 2.4458
            },
            "Fuente Palmera": {
                "lat": 37.7,
                "lng": -5.1
            },
            "Masquefa": {
                "lat": 41.5036,
                "lng": 1.8136
            },
            "Ribadeo": {
                "lat": 43.5336,
                "lng": -7.0403
            },
            "Alfaro": {
                "lat": 42.1783,
                "lng": -1.7492
            },
            "El Espinar": {
                "lat": 40.7186,
                "lng": -4.2478
            },
            "Alcarraz": {
                "lat": 41.5661,
                "lng": 0.5261
            },
            "Mora": {
                "lat": 39.684,
                "lng": -3.777
            },
            "Rute": {
                "lat": 37.3167,
                "lng": -4.3667
            },
            "El Molar": {
                "lat": 40.7336,
                "lng": -3.5814
            },
            "Alayor": {
                "lat": 39.9339,
                "lng": 4.1403
            },
            "Zum\u00e1rraga": {
                "lat": 43.0831,
                "lng": -2.3167
            },
            "Valsequillo de Gran Canaria": {
                "lat": 28.0,
                "lng": -15.4667
            },
            "Villarrubia de los Ojos": {
                "lat": 39.2167,
                "lng": -3.6
            },
            "Pinos Puente": {
                "lat": 37.25,
                "lng": -3.75
            },
            "Palafolls": {
                "lat": 41.6692,
                "lng": 2.7506
            },
            "San Juan del Puerto": {
                "lat": 37.3167,
                "lng": -6.8333
            },
            "Sangonera la Verde": {
                "lat": 37.9333,
                "lng": -1.2
            },
            "Caldas de Reyes": {
                "lat": 42.6028,
                "lng": -8.6383
            },
            "Grado": {
                "lat": 43.3881,
                "lng": -6.0736
            },
            "Alcanar": {
                "lat": 40.544,
                "lng": 0.482
            },
            "Fern\u00e1n-N\u00fa\u00f1ez": {
                "lat": 37.6667,
                "lng": -4.7167
            },
            "Cu\u00e9llar": {
                "lat": 41.4009,
                "lng": -4.3136
            },
            "Bu\u00f1ol": {
                "lat": 39.4194,
                "lng": -0.7906
            },
            "Malia\u00f1o": {
                "lat": 43.4186,
                "lng": -3.835
            },
            "Sevilla La Nueva": {
                "lat": 40.3475,
                "lng": -4.0286
            },
            "Colmenarejo": {
                "lat": 40.5608,
                "lng": -4.0169
            },
            "Piedras Blancas": {
                "lat": 43.56,
                "lng": -5.976
            },
            "Puigcerd\u00e1": {
                "lat": 42.4317,
                "lng": 1.9283
            },
            "San Sebasti\u00e1n de la Gomera": {
                "lat": 28.0922,
                "lng": -17.11
            },
            "Senmanat": {
                "lat": 41.6092,
                "lng": 2.1369
            },
            "Cervell\u00f3": {
                "lat": 41.3978,
                "lng": 1.9578
            },
            "Rafelbu\u00f1ol": {
                "lat": 39.5922,
                "lng": -0.3342
            },
            "Tabernes Blanques": {
                "lat": 39.5083,
                "lng": -0.3656
            },
            "Burela de Cabo": {
                "lat": 43.65,
                "lng": -7.4
            },
            "Cervera": {
                "lat": 41.6656,
                "lng": 1.2714
            },
            "Villaca\u00f1as": {
                "lat": 39.6333,
                "lng": -3.3333
            },
            "Olivares": {
                "lat": 37.4167,
                "lng": -6.15
            },
            "Segorbe": {
                "lat": 39.85,
                "lng": -0.4833
            },
            "Sabi\u00f1\u00e1nigo": {
                "lat": 42.5144,
                "lng": -0.3744
            },
            "Tocina": {
                "lat": 37.6,
                "lng": -5.7333
            },
            "Arenys de Munt": {
                "lat": 41.61,
                "lng": 2.54
            },
            "Umbrete": {
                "lat": 37.3667,
                "lng": -6.15
            },
            "Miajadas": {
                "lat": 39.15,
                "lng": -6.0667
            },
            "Santa Comba": {
                "lat": 43.0383,
                "lng": -8.8142
            },
            "Santa Mar\u00eda de Cay\u00f3n": {
                "lat": 43.3114,
                "lng": -3.8525
            },
            "Monserrat": {
                "lat": 39.3575,
                "lng": -0.6031
            },
            "Catral": {
                "lat": 38.1594,
                "lng": -0.805
            },
            "Muro del Alcoy": {
                "lat": 38.7797,
                "lng": -0.4361
            },
            "Manzanares el Real": {
                "lat": 40.7272,
                "lng": -3.8611
            },
            "Benahav\u00eds": {
                "lat": 36.519,
                "lng": -5.0454
            },
            "La Victoria de Acentejo": {
                "lat": 28.4333,
                "lng": -16.4667
            },
            "San Lorenzo de Descardazar": {
                "lat": 39.6097,
                "lng": 3.2839
            },
            "Llagostera": {
                "lat": 41.8292,
                "lng": 2.8933
            },
            "Tiana": {
                "lat": 41.4831,
                "lng": 2.2697
            },
            "San Fausto de Campcentellas": {
                "lat": 41.5061,
                "lng": 2.24
            },
            "Cist\u00e9rniga": {
                "lat": 41.6167,
                "lng": -4.6833
            },
            "Binisalem": {
                "lat": 39.6886,
                "lng": 2.8422
            },
            "Jerez de los Caballeros": {
                "lat": 38.3203,
                "lng": -6.7714
            },
            "Solsona": {
                "lat": 41.9978,
                "lng": 1.5208
            },
            "Palomares del R\u00edo": {
                "lat": 37.3167,
                "lng": -6.05
            },
            "San Fructuoso de Bag\u00e9s": {
                "lat": 41.752,
                "lng": 1.8745
            },
            "Sauzal": {
                "lat": 28.4778,
                "lng": -16.4361
            },
            "Montoro": {
                "lat": 38.0167,
                "lng": -4.3833
            },
            "Loeches": {
                "lat": 40.3833,
                "lng": -3.4
            },
            "Tineo": {
                "lat": 43.3333,
                "lng": -6.4167
            },
            "La Matanza de Acentejo": {
                "lat": 28.4528,
                "lng": -16.4556
            },
            "Suances": {
                "lat": 43.4333,
                "lng": -4.05
            },
            "Arico el Nuevo": {
                "lat": 28.1667,
                "lng": -16.4667
            },
            "Almusafes": {
                "lat": 39.2903,
                "lng": -0.4147
            },
            "Cheste": {
                "lat": 39.4797,
                "lng": -0.6844
            },
            "Villamediana de Iregua": {
                "lat": 42.4333,
                "lng": -2.4167
            },
            "Almagro": {
                "lat": 38.8878,
                "lng": -3.7122
            },
            "Silleda": {
                "lat": 42.7,
                "lng": -8.2333
            },
            "Finestrat": {
                "lat": 38.5669,
                "lng": -0.2125
            },
            "Montmel\u00f3": {
                "lat": 41.5547,
                "lng": 2.25
            },
            "Trujillo": {
                "lat": 39.4653,
                "lng": -5.8789
            },
            "San Mart\u00edn de Valdeiglesias": {
                "lat": 40.364,
                "lng": -4.401
            },
            "Hoyo de Manzanares": {
                "lat": 40.6333,
                "lng": -3.8833
            },
            "La Mujer": {
                "lat": 36.7522,
                "lng": -2.6839
            },
            "Monforte del Cid": {
                "lat": 38.3833,
                "lng": -0.7167
            },
            "Tordesillas": {
                "lat": 41.5,
                "lng": -5.0
            },
            "Tudela de Duero": {
                "lat": 41.5842,
                "lng": -4.58
            },
            "Zuera": {
                "lat": 41.8667,
                "lng": -0.7833
            },
            "Corella": {
                "lat": 42.1147,
                "lng": -1.7867
            },
            "Reinosa": {
                "lat": 43.0019,
                "lng": -4.1378
            },
            "Pedreguer": {
                "lat": 38.7933,
                "lng": 0.0342
            },
            "Colindres": {
                "lat": 43.3967,
                "lng": -3.4483
            },
            "Santiponce": {
                "lat": 37.4353,
                "lng": -6.0433
            },
            "Ortuella": {
                "lat": 43.3103,
                "lng": -3.0569
            }
        }
    },
    "IQ": {
        "name": "Iraq",
        "cities": {
            "Baghdad": {
                "lat": 33.3153,
                "lng": 44.3661
            },
            "Mosul": {
                "lat": 36.34,
                "lng": 43.13
            },
            "Al Ba\u015frah": {
                "lat": 30.515,
                "lng": 47.81
            },
            "Kirkuk": {
                "lat": 35.4667,
                "lng": 44.3167
            },
            "As Sulaym\u0101n\u012byah": {
                "lat": 35.5572,
                "lng": 45.4356
            },
            "Ar Ram\u0101d\u012b": {
                "lat": 33.4258,
                "lng": 43.2992
            },
            "Erbil": {
                "lat": 36.1912,
                "lng": 44.0092
            },
            "An Najaf": {
                "lat": 32.0,
                "lng": 44.33
            },
            "Karbal\u0101\u2019": {
                "lat": 32.6167,
                "lng": 44.0333
            },
            "An N\u0101\u015fir\u012byah": {
                "lat": 31.0439,
                "lng": 46.2575
            },
            "Al \u1e28illah": {
                "lat": 32.4775,
                "lng": 44.4314
            },
            "Al \u2018Am\u0101rah": {
                "lat": 31.8333,
                "lng": 47.15
            },
            "Ba\u2018q\u016bbah": {
                "lat": 33.75,
                "lng": 44.6333
            },
            "Ad D\u012bw\u0101n\u012byah": {
                "lat": 31.9892,
                "lng": 44.9247
            },
            "Az Zubayr": {
                "lat": 30.3833,
                "lng": 47.7
            },
            "Sawr\u0101n": {
                "lat": 36.6606,
                "lng": 44.5508
            },
            "Dah\u016bk": {
                "lat": 36.8667,
                "lng": 43.0
            },
            "Al K\u016bt": {
                "lat": 32.4907,
                "lng": 45.8304
            },
            "Ash Sh\u0101m\u012byah": {
                "lat": 31.9636,
                "lng": 44.5989
            },
            "Al Qurnah": {
                "lat": 31.0158,
                "lng": 47.4306
            },
            "Al \u1e28amzah": {
                "lat": 31.7339,
                "lng": 45.0086
            },
            "R\u0101niyah": {
                "lat": 36.255,
                "lng": 44.8836
            },
            "Ash Sha\u0163rah": {
                "lat": 31.4097,
                "lng": 46.1717
            },
            "Sab\u2018 al B\u016br": {
                "lat": 33.4644,
                "lng": 44.1536
            },
            "Al Fall\u016bjah": {
                "lat": 33.35,
                "lng": 43.7833
            },
            "Kal\u0101r": {
                "lat": 34.6292,
                "lng": 45.3222
            },
            "Ab\u012b al Kha\u015f\u012bb": {
                "lat": 30.4411,
                "lng": 47.9725
            },
            "As Sam\u0101wah": {
                "lat": 31.3167,
                "lng": 45.2833
            },
            "\u2018Aqrah": {
                "lat": 36.7414,
                "lng": 43.8933
            },
            "At T\u0101j\u012b": {
                "lat": 33.5092,
                "lng": 44.2342
            },
            "Z\u0101kh\u016b": {
                "lat": 37.15,
                "lng": 42.6833
            },
            "Ab\u016b Ghurayb": {
                "lat": 33.2919,
                "lng": 44.0656
            },
            "Al K\u016bfah": {
                "lat": 32.03,
                "lng": 44.4
            },
            "Al Miqd\u0101d\u012byah": {
                "lat": 33.9786,
                "lng": 44.9369
            },
            "S\u0101marr\u0101\u2019": {
                "lat": 34.1983,
                "lng": 43.8742
            },
            "N\u0101\u1e29iyat Ghamm\u0101s": {
                "lat": 31.7431,
                "lng": 44.6194
            },
            "N\u0101\u1e29iyat Kh\u0101n Ban\u012b Sa\u2018d": {
                "lat": 33.57,
                "lng": 44.5411
            },
            "\u0162\u016bz Kh\u016brm\u0101t\u016b": {
                "lat": 34.8772,
                "lng": 44.6383
            },
            "Salm\u0101n B\u0101k": {
                "lat": 33.1,
                "lng": 44.5833
            },
            "Ar Rumaythah": {
                "lat": 31.5209,
                "lng": 45.2017
            },
            "Qal\u2018at Sukkar": {
                "lat": 31.8589,
                "lng": 46.0772
            },
            "Umm Qa\u015fr": {
                "lat": 30.0342,
                "lng": 47.9294
            },
            "Tikr\u012bt": {
                "lat": 34.61,
                "lng": 43.68
            },
            "Al F\u0101w": {
                "lat": 29.9758,
                "lng": 48.4722
            },
            "N\u0101\u1e29iyat al Iskandar\u012byah": {
                "lat": 32.9,
                "lng": 44.35
            },
            "Ad Dujayl": {
                "lat": 33.8389,
                "lng": 44.2481
            },
            "Raw\u0101nduz": {
                "lat": 36.6119,
                "lng": 44.5247
            },
            "N\u0101\u1e29iyat al Karmah": {
                "lat": 33.4042,
                "lng": 43.9133
            },
            "Al Hind\u012byah": {
                "lat": 32.5442,
                "lng": 44.2197
            },
            "Al \u1e28ayy": {
                "lat": 32.1667,
                "lng": 46.05
            },
            "Imam Qasim": {
                "lat": 32.3014,
                "lng": 44.6892
            },
            "Tall \u2018Afar": {
                "lat": 36.3742,
                "lng": 42.4433
            },
            "A\u015f \u015euwayrah": {
                "lat": 32.9403,
                "lng": 44.6344
            },
            "Al Musayyib": {
                "lat": 32.7786,
                "lng": 44.29
            },
            "Al Q\u0101\u2019im": {
                "lat": 34.3688,
                "lng": 41.0945
            },
            "Saymayl": {
                "lat": 36.8583,
                "lng": 42.8501
            },
            "An Nu\u2018m\u0101n\u012byah": {
                "lat": 32.5,
                "lng": 45.3333
            },
            "Az Zubayd\u012byah": {
                "lat": 32.7588,
                "lng": 45.1773
            },
            "H\u012bt": {
                "lat": 33.645,
                "lng": 42.8264
            },
            "Jamjam\u0101l": {
                "lat": 35.5333,
                "lng": 44.8333
            },
            "\u1e28alabjah": {
                "lat": 35.1833,
                "lng": 45.9833
            },
            "Balad": {
                "lat": 34.0164,
                "lng": 44.1453
            },
            "Sayyid \u015e\u0101diq": {
                "lat": 35.3536,
                "lng": 45.8667
            },
            "Bayj\u012b": {
                "lat": 34.9292,
                "lng": 43.4931
            },
            "As Sa\u2018d\u012byah": {
                "lat": 34.1906,
                "lng": 45.1208
            },
            "\u1e28ad\u012bthah": {
                "lat": 34.1397,
                "lng": 42.3781
            },
            "Al \u2018Az\u012bz\u012byah": {
                "lat": 32.9083,
                "lng": 45.0667
            },
            "T\u0101zah Kh\u016brm\u0101t\u016b": {
                "lat": 35.3028,
                "lng": 44.3286
            },
            "Al H\u0101shim\u012byah": {
                "lat": 32.3664,
                "lng": 44.6581
            },
            "Qal\u0101dizay": {
                "lat": 36.1833,
                "lng": 45.1278
            },
            "Al Jab\u0101yish": {
                "lat": 30.9549,
                "lng": 46.9751
            },
            "Buhriz": {
                "lat": 33.7,
                "lng": 44.6667
            },
            "Al \u1e28amd\u0101n\u012byah": {
                "lat": 36.2697,
                "lng": 43.3775
            },
            "Kifr\u012b": {
                "lat": 34.6833,
                "lng": 44.9667
            },
            "Mad\u012bnat al \u1e28abb\u0101n\u012byah": {
                "lat": 33.3667,
                "lng": 43.5667
            },
            "Kh\u0101nah S\u016br": {
                "lat": 36.4731,
                "lng": 41.6161
            },
            "\u2018Aynk\u0101wah": {
                "lat": 36.2292,
                "lng": 43.9936
            },
            "Jalawl\u0101\u2019": {
                "lat": 34.2719,
                "lng": 45.1681
            },
            "Mandal\u012b": {
                "lat": 33.7333,
                "lng": 45.55
            },
            "\u1e28ar\u012br": {
                "lat": 36.5517,
                "lng": 44.3516
            },
            "Ar Ru\u0163bah": {
                "lat": 33.0381,
                "lng": 40.2844
            },
            "A\u015f \u015eaql\u0101w\u012byah": {
                "lat": 33.3964,
                "lng": 43.6833
            },
            "Shaql\u0101wah": {
                "lat": 36.4056,
                "lng": 44.3208
            },
            "Makhm\u016br": {
                "lat": 35.7756,
                "lng": 43.5794
            },
            "N\u0101\u1e29iyat al Kifl": {
                "lat": 32.2242,
                "lng": 44.3767
            },
            "Sinj\u0101r": {
                "lat": 36.3225,
                "lng": 41.8642
            },
            "Zurb\u0101\u0163\u012byah": {
                "lat": 33.15,
                "lng": 46.05
            },
            "Anah": {
                "lat": 34.3722,
                "lng": 41.9875
            },
            "K\u016bysinjaq": {
                "lat": 36.0833,
                "lng": 44.6333
            },
            "R\u0101wah": {
                "lat": 34.4686,
                "lng": 41.9167
            },
            "Tall Qa\u015fab": {
                "lat": 36.2583,
                "lng": 41.9431
            },
            "Ash Shaykh\u0101n": {
                "lat": 36.6917,
                "lng": 43.35
            },
            "B\u0101\u0163\u016bfah": {
                "lat": 37.1744,
                "lng": 43.0132
            },
            "A\u1e11 \u1e10ul\u016b\u2018\u012byah": {
                "lat": 34.05,
                "lng": 44.2
            },
            "Sin\u016bn\u012b": {
                "lat": 36.4575,
                "lng": 41.7061
            },
            "Ma\u015f\u012bf Sarsink": {
                "lat": 37.0333,
                "lng": 43.3333
            },
            "\u1e28amm\u0101m al \u2018Al\u012bl": {
                "lat": 36.1581,
                "lng": 43.2594
            },
            "Bar\u0163alah": {
                "lat": 36.3522,
                "lng": 43.3797
            },
            "Amirl\u012b": {
                "lat": 34.725,
                "lng": 44.5875
            },
            "Tall Ban\u0101t": {
                "lat": 36.255,
                "lng": 42.0164
            },
            "K\u016bhbil": {
                "lat": 36.5133,
                "lng": 41.9542
            },
            "Kh\u0101naq\u012bn": {
                "lat": 34.3333,
                "lng": 45.3833
            },
            "Mah\u0101d": {
                "lat": 36.6472,
                "lng": 43.4117
            },
            "Al \u2018Am\u0101d\u012byah": {
                "lat": 37.0925,
                "lng": 43.4872
            },
            "\u0100lt\u016bn Kawbr\u012b": {
                "lat": 35.7533,
                "lng": 44.1436
            }
        }
    },
    "SG": {
        "name": "Singapore",
        "cities": {
            "Singapore": {
                "lat": 1.3,
                "lng": 103.8
            }
        }
    },
    "CM": {
        "name": "Cameroon",
        "cities": {
            "Douala": {
                "lat": 4.05,
                "lng": 9.7
            },
            "Yaound\u00e9": {
                "lat": 3.8667,
                "lng": 11.5167
            },
            "Bamenda": {
                "lat": 5.9614,
                "lng": 10.1517
            },
            "Garoua": {
                "lat": 9.3,
                "lng": 13.4
            },
            "Bafoussam": {
                "lat": 5.4667,
                "lng": 10.4167
            },
            "Maroua": {
                "lat": 10.5971,
                "lng": 14.3157
            },
            "Ngaound\u00e9r\u00e9": {
                "lat": 7.3167,
                "lng": 13.5833
            },
            "Nkongsamba": {
                "lat": 4.95,
                "lng": 9.9333
            },
            "Bib\u00e9mi": {
                "lat": 9.3167,
                "lng": 13.8833
            },
            "Buea": {
                "lat": 4.1667,
                "lng": 9.2333
            },
            "Kouss\u00e9ri": {
                "lat": 12.0833,
                "lng": 15.0333
            },
            "Bogo": {
                "lat": 10.734,
                "lng": 14.608
            },
            "Bertoua": {
                "lat": 4.5833,
                "lng": 13.6833
            },
            "Widekum": {
                "lat": 5.8717,
                "lng": 9.7706
            },
            "Maga": {
                "lat": 10.85,
                "lng": 14.9333
            },
            "Foumban": {
                "lat": 5.7167,
                "lng": 10.9167
            },
            "Bafut": {
                "lat": 6.0833,
                "lng": 10.1
            },
            "Kumbo": {
                "lat": 6.205,
                "lng": 10.685
            },
            "Es\u00e9ka": {
                "lat": 3.65,
                "lng": 10.7667
            },
            "Tiko": {
                "lat": 4.075,
                "lng": 9.36
            },
            "\u00c9bolowa": {
                "lat": 2.9167,
                "lng": 11.15
            },
            "Dschang": {
                "lat": 5.45,
                "lng": 10.0667
            },
            "Ed\u00e9a": {
                "lat": 3.8,
                "lng": 10.1333
            },
            "Tibati": {
                "lat": 6.4667,
                "lng": 12.6333
            },
            "Melong": {
                "lat": 5.1211,
                "lng": 9.9528
            },
            "Bafia": {
                "lat": 4.75,
                "lng": 11.2167
            },
            "Guider": {
                "lat": 9.9342,
                "lng": 13.9486
            },
            "Sangm\u00e9lima": {
                "lat": 2.9333,
                "lng": 11.9833
            },
            "Mbalmayo": {
                "lat": 3.5167,
                "lng": 11.5
            },
            "Kribi": {
                "lat": 2.935,
                "lng": 9.91
            },
            "Foumbot": {
                "lat": 5.5,
                "lng": 10.6333
            },
            "Mora": {
                "lat": 11.05,
                "lng": 14.15
            },
            "Baham": {
                "lat": 5.3333,
                "lng": 10.4
            },
            "Mbouda": {
                "lat": 5.6333,
                "lng": 10.25
            },
            "Loum": {
                "lat": 4.718,
                "lng": 9.73
            },
            "Baba I": {
                "lat": 6.0622,
                "lng": 10.4903
            },
            "Yagoua": {
                "lat": 10.3428,
                "lng": 15.2406
            },
            "Me\u00efganga": {
                "lat": 6.53,
                "lng": 14.37
            },
            "Mokolo": {
                "lat": 10.74,
                "lng": 13.8
            },
            "Bamessing": {
                "lat": 5.9847,
                "lng": 10.3603
            },
            "Obala": {
                "lat": 4.1667,
                "lng": 11.5333
            },
            "Bamendjou": {
                "lat": 5.4,
                "lng": 10.3167
            },
            "Monat\u00e9l\u00e9": {
                "lat": 4.2667,
                "lng": 11.2
            },
            "Mamfe": {
                "lat": 5.7667,
                "lng": 9.2833
            },
            "Ngaoundal": {
                "lat": 6.5,
                "lng": 13.2667
            },
            "Biwong": {
                "lat": 3.1333,
                "lng": 11.15
            },
            "Batouri": {
                "lat": 4.4333,
                "lng": 14.3667
            },
            "Bafang": {
                "lat": 5.15,
                "lng": 10.1833
            },
            "Batcha": {
                "lat": 5.15,
                "lng": 10.1833
            },
            "Messam\u00e9na": {
                "lat": 3.7333,
                "lng": 12.8333
            },
            "Kumba": {
                "lat": 4.6333,
                "lng": 9.45
            },
            "Kael\u00e9": {
                "lat": 10.1,
                "lng": 14.45
            },
            "Nanga Eboko": {
                "lat": 4.6708,
                "lng": 12.3731
            },
            "Mbanga": {
                "lat": 4.5092,
                "lng": 9.5681
            },
            "Manjo": {
                "lat": 4.85,
                "lng": 9.8167
            },
            "Figuil": {
                "lat": 9.7667,
                "lng": 13.9667
            },
            "Pitoa": {
                "lat": 9.3833,
                "lng": 13.5333
            },
            "Lagdo": {
                "lat": 9.05,
                "lng": 13.7333
            },
            "Nguti": {
                "lat": 5.3167,
                "lng": 9.4167
            },
            "Limbe": {
                "lat": 4.0167,
                "lng": 9.2167
            },
            "Bamessi": {
                "lat": 6.0333,
                "lng": 10.5792
            },
            "Kalfou": {
                "lat": 10.2833,
                "lng": 14.9333
            },
            "Wum": {
                "lat": 6.3833,
                "lng": 10.0667
            },
            "Yokadouma": {
                "lat": 3.5167,
                "lng": 15.05
            },
            "Touboro": {
                "lat": 7.7708,
                "lng": 15.3567
            },
            "Ayos": {
                "lat": 3.9069,
                "lng": 12.5272
            },
            "Garoua Boula\u00ef": {
                "lat": 5.8833,
                "lng": 14.55
            },
            "B\u00e9labo": {
                "lat": 4.9333,
                "lng": 13.3
            },
            "Mbandjok": {
                "lat": 4.45,
                "lng": 11.9
            },
            "Akonolinga": {
                "lat": 3.7667,
                "lng": 12.25
            },
            "Bambalang": {
                "lat": 5.8867,
                "lng": 10.5331
            },
            "Kekem": {
                "lat": 5.1667,
                "lng": 10.0333
            },
            "Nkoteng": {
                "lat": 4.5167,
                "lng": 12.0333
            },
            "Bamukumbit": {
                "lat": 5.8167,
                "lng": 10.4167
            },
            "Bamumkumbit": {
                "lat": 5.8167,
                "lng": 10.4167
            },
            "Abong Mbang": {
                "lat": 3.9833,
                "lng": 13.1667
            },
            "Bafanji": {
                "lat": 5.8361,
                "lng": 10.4
            },
            "Diang": {
                "lat": 4.5833,
                "lng": 13.35
            },
            "Ngoulemakong": {
                "lat": 3.0833,
                "lng": 11.4333
            },
            "Yoko": {
                "lat": 5.5924,
                "lng": 12.3158
            },
            "Ngou": {
                "lat": 5.2,
                "lng": 10.3833
            },
            "Tefam": {
                "lat": 5.2667,
                "lng": 10.4667
            },
            "Mfou": {
                "lat": 3.96,
                "lng": 11.93
            },
            "Balikumbat": {
                "lat": 5.8928,
                "lng": 10.3597
            },
            "Olamz\u00e9": {
                "lat": 2.2167,
                "lng": 11.0833
            },
            "Kontcha": {
                "lat": 7.9667,
                "lng": 12.2333
            },
            "Aiyomojok": {
                "lat": 5.7504,
                "lng": 8.9833
            }
        }
    },
    "CA": {
        "name": "Canada",
        "cities": {
            "Toronto": {
                "lat": 43.7417,
                "lng": -79.3733
            },
            "Montr\u00e9al": {
                "lat": 45.5089,
                "lng": -73.5617
            },
            "Vancouver": {
                "lat": 49.25,
                "lng": -123.1
            },
            "Calgary": {
                "lat": 51.05,
                "lng": -114.0667
            },
            "Edmonton": {
                "lat": 53.5344,
                "lng": -113.4903
            },
            "Ottawa": {
                "lat": 45.4247,
                "lng": -75.695
            },
            "Winnipeg": {
                "lat": 49.8844,
                "lng": -97.1464
            },
            "Quebec City": {
                "lat": 46.8139,
                "lng": -71.2081
            },
            "Hamilton": {
                "lat": 43.2567,
                "lng": -79.8692
            },
            "Mississauga": {
                "lat": 43.6,
                "lng": -79.65
            },
            "Brampton": {
                "lat": 43.6833,
                "lng": -79.7667
            },
            "Surrey": {
                "lat": 49.19,
                "lng": -122.8489
            },
            "Kitchener": {
                "lat": 43.4186,
                "lng": -80.4728
            },
            "Halifax": {
                "lat": 44.6475,
                "lng": -63.5906
            },
            "Laval": {
                "lat": 45.5833,
                "lng": -73.75
            },
            "London": {
                "lat": 42.9836,
                "lng": -81.2497
            },
            "Victoria": {
                "lat": 48.4283,
                "lng": -123.3647
            },
            "Markham": {
                "lat": 43.8767,
                "lng": -79.2633
            },
            "Oshawa": {
                "lat": 43.9,
                "lng": -78.85
            },
            "Vaughan": {
                "lat": 43.8333,
                "lng": -79.5
            },
            "Windsor": {
                "lat": 42.2833,
                "lng": -83.0
            },
            "Gatineau": {
                "lat": 45.4833,
                "lng": -75.65
            },
            "Saskatoon": {
                "lat": 52.1333,
                "lng": -106.6833
            },
            "Longueuil": {
                "lat": 45.5333,
                "lng": -73.5167
            },
            "Burnaby": {
                "lat": 49.2667,
                "lng": -122.9667
            },
            "St. Catharines": {
                "lat": 43.1833,
                "lng": -79.2333
            },
            "Regina": {
                "lat": 50.4547,
                "lng": -104.6067
            },
            "Oakville": {
                "lat": 43.45,
                "lng": -79.6833
            },
            "Richmond": {
                "lat": 49.1667,
                "lng": -123.1333
            },
            "Richmond Hill": {
                "lat": 43.8667,
                "lng": -79.4333
            },
            "Burlington": {
                "lat": 43.3167,
                "lng": -79.8
            },
            "St. John's": {
                "lat": 47.4817,
                "lng": -52.7971
            },
            "Kelowna": {
                "lat": 49.8881,
                "lng": -119.4956
            },
            "Sherbrooke": {
                "lat": 45.4,
                "lng": -71.9
            },
            "Sudbury": {
                "lat": 46.49,
                "lng": -81.01
            },
            "Barrie": {
                "lat": 44.3711,
                "lng": -79.6769
            },
            "Abbotsford": {
                "lat": 49.05,
                "lng": -122.3167
            },
            "L\u00e9vis": {
                "lat": 46.8,
                "lng": -71.1833
            },
            "Coquitlam": {
                "lat": 49.2839,
                "lng": -122.7919
            },
            "Saguenay": {
                "lat": 48.4167,
                "lng": -71.0667
            },
            "Guelph": {
                "lat": 43.55,
                "lng": -80.25
            },
            "Trois-Rivi\u00e8res": {
                "lat": 46.35,
                "lng": -72.55
            },
            "Whitby": {
                "lat": 43.8833,
                "lng": -78.9417
            },
            "Cambridge": {
                "lat": 43.3972,
                "lng": -80.3114
            },
            "Kanata": {
                "lat": 45.3333,
                "lng": -75.9
            },
            "Milton": {
                "lat": 43.5083,
                "lng": -79.8833
            },
            "Kingston": {
                "lat": 44.3128,
                "lng": -76.4553
            },
            "Ajax": {
                "lat": 43.8583,
                "lng": -79.0364
            },
            "Waterloo": {
                "lat": 43.4667,
                "lng": -80.5167
            },
            "Terrebonne": {
                "lat": 45.7,
                "lng": -73.6333
            },
            "Moncton": {
                "lat": 46.1328,
                "lng": -64.7714
            },
            "Saanich": {
                "lat": 48.484,
                "lng": -123.381
            },
            "White Rock": {
                "lat": 49.025,
                "lng": -122.8028
            },
            "Thunder Bay": {
                "lat": 48.3822,
                "lng": -89.2461
            },
            "Delta": {
                "lat": 49.0847,
                "lng": -123.0586
            },
            "Nanaimo": {
                "lat": 49.1642,
                "lng": -123.9364
            },
            "Brantford": {
                "lat": 43.1667,
                "lng": -80.25
            },
            "Chatham": {
                "lat": 47.0443,
                "lng": -65.3781
            },
            "Chicoutimi": {
                "lat": 48.4333,
                "lng": -71.0667
            },
            "Clarington": {
                "lat": 43.935,
                "lng": -78.6083
            },
            "Saint-J\u00e9r\u00f4me": {
                "lat": 45.7833,
                "lng": -74.0
            },
            "Red Deer": {
                "lat": 52.2681,
                "lng": -113.8111
            },
            "Pickering": {
                "lat": 43.8354,
                "lng": -79.089
            },
            "Lethbridge": {
                "lat": 49.6942,
                "lng": -112.8328
            },
            "Kamloops": {
                "lat": 50.6761,
                "lng": -120.3408
            },
            "Saint-Jean-sur-Richelieu": {
                "lat": 45.3167,
                "lng": -73.2667
            },
            "Niagara Falls": {
                "lat": 43.06,
                "lng": -79.1067
            },
            "Cape Breton": {
                "lat": 46.1389,
                "lng": -60.1931
            },
            "Chilliwack": {
                "lat": 49.1577,
                "lng": -121.9509
            },
            "Brossard": {
                "lat": 45.4667,
                "lng": -73.45
            },
            "Maple Ridge": {
                "lat": 49.2167,
                "lng": -122.6
            },
            "Newmarket": {
                "lat": 44.05,
                "lng": -79.4667
            },
            "Repentigny": {
                "lat": 45.7333,
                "lng": -73.4667
            },
            "Peterborough": {
                "lat": 44.3,
                "lng": -78.3167
            },
            "Drummondville": {
                "lat": 45.8833,
                "lng": -72.4833
            },
            "Kawartha Lakes": {
                "lat": 44.35,
                "lng": -78.75
            },
            "New Westminster": {
                "lat": 49.2069,
                "lng": -122.9111
            },
            "Prince George": {
                "lat": 53.9169,
                "lng": -122.7494
            },
            "Caledon": {
                "lat": 43.8667,
                "lng": -79.8667
            },
            "Ch\u00e2teauguay": {
                "lat": 45.38,
                "lng": -73.75
            },
            "Belleville": {
                "lat": 44.1667,
                "lng": -77.3833
            },
            "Airdrie": {
                "lat": 51.2917,
                "lng": -114.0144
            },
            "Sarnia": {
                "lat": 42.9994,
                "lng": -82.3089
            },
            "Wood Buffalo": {
                "lat": 57.6042,
                "lng": -111.3284
            },
            "Sault Ste. Marie": {
                "lat": 46.5333,
                "lng": -84.35
            },
            "Saint John": {
                "lat": 45.2806,
                "lng": -66.0761
            },
            "Welland": {
                "lat": 42.9833,
                "lng": -79.2333
            },
            "Granby": {
                "lat": 45.4,
                "lng": -72.7333
            },
            "St. Albert": {
                "lat": 53.6303,
                "lng": -113.6258
            },
            "Fort McMurray": {
                "lat": 56.7333,
                "lng": -111.3833
            },
            "Fredericton": {
                "lat": 45.9636,
                "lng": -66.6431
            },
            "Grande Prairie": {
                "lat": 55.1708,
                "lng": -118.7947
            },
            "Medicine Hat": {
                "lat": 50.0417,
                "lng": -110.6775
            },
            "Halton Hills": {
                "lat": 43.63,
                "lng": -79.95
            },
            "Aurora": {
                "lat": 44.0,
                "lng": -79.4667
            },
            "Port Coquitlam": {
                "lat": 49.2625,
                "lng": -122.7811
            },
            "Mirabel": {
                "lat": 45.65,
                "lng": -74.0833
            },
            "Blainville": {
                "lat": 45.67,
                "lng": -73.88
            },
            "Lac-Brome": {
                "lat": 45.2167,
                "lng": -72.5167
            },
            "North Vancouver": {
                "lat": 49.3202,
                "lng": -123.0699
            },
            "Saint-Hyacinthe": {
                "lat": 45.6167,
                "lng": -72.95
            },
            "Bowmanville": {
                "lat": 43.9125,
                "lng": -78.6871
            },
            "Beloeil": {
                "lat": 45.5667,
                "lng": -73.2
            },
            "North Bay": {
                "lat": 46.3,
                "lng": -79.45
            },
            "Charlottetown": {
                "lat": 46.2403,
                "lng": -63.1347
            },
            "Vernon": {
                "lat": 50.267,
                "lng": -119.272
            },
            "Brandon": {
                "lat": 49.8483,
                "lng": -99.95
            },
            "Mascouche": {
                "lat": 45.75,
                "lng": -73.6
            },
            "Stouffville": {
                "lat": 43.9667,
                "lng": -79.25
            },
            "Shawinigan": {
                "lat": 46.5667,
                "lng": -72.75
            },
            "Joliette": {
                "lat": 46.0167,
                "lng": -73.45
            },
            "Rimouski": {
                "lat": 48.45,
                "lng": -68.53
            },
            "Courtenay": {
                "lat": 49.6878,
                "lng": -124.9944
            },
            "Dollard-des-Ormeaux": {
                "lat": 45.4833,
                "lng": -73.8167
            },
            "Cornwall": {
                "lat": 45.0275,
                "lng": -74.74
            },
            "Victoriaville": {
                "lat": 46.05,
                "lng": -71.9667
            },
            "Georgina": {
                "lat": 44.3,
                "lng": -79.4333
            },
            "Woodstock": {
                "lat": 43.1306,
                "lng": -80.7467
            },
            "Langford Station": {
                "lat": 48.4506,
                "lng": -123.5058
            },
            "Quinte West": {
                "lat": 44.1833,
                "lng": -77.5667
            },
            "St. Thomas": {
                "lat": 42.775,
                "lng": -81.1833
            },
            "Saint-Eustache": {
                "lat": 45.57,
                "lng": -73.9
            },
            "West Vancouver": {
                "lat": 49.3667,
                "lng": -123.1667
            },
            "Georgetown": {
                "lat": 43.6526,
                "lng": -79.9281
            },
            "New Tecumseth": {
                "lat": 44.0833,
                "lng": -79.75
            },
            "Innisfil": {
                "lat": 44.3,
                "lng": -79.5833
            },
            "Vaudreuil-Dorion": {
                "lat": 45.4,
                "lng": -74.0333
            },
            "Bradford West Gwillimbury": {
                "lat": 44.1333,
                "lng": -79.6333
            },
            "Salaberry-de-Valleyfield": {
                "lat": 45.25,
                "lng": -74.13
            },
            "Rouyn-Noranda": {
                "lat": 48.2333,
                "lng": -79.0167
            },
            "Boucherville": {
                "lat": 45.6,
                "lng": -73.45
            },
            "Mission": {
                "lat": 49.1337,
                "lng": -122.3112
            },
            "Timmins": {
                "lat": 48.4667,
                "lng": -81.3333
            },
            "Lakeshore": {
                "lat": 42.2399,
                "lng": -82.6511
            },
            "Brant": {
                "lat": 43.1167,
                "lng": -80.3667
            },
            "Spruce Grove": {
                "lat": 53.545,
                "lng": -113.9008
            },
            "Bradford": {
                "lat": 44.1167,
                "lng": -79.5667
            },
            "Campbell River": {
                "lat": 50.0244,
                "lng": -125.2475
            },
            "Prince Albert": {
                "lat": 53.2,
                "lng": -105.75
            },
            "Penticton": {
                "lat": 49.4911,
                "lng": -119.5886
            },
            "Sorel-Tracy": {
                "lat": 46.0333,
                "lng": -73.1167
            },
            "East Kelowna": {
                "lat": 49.8625,
                "lng": -119.5833
            },
            "Leamington": {
                "lat": 42.0667,
                "lng": -82.5833
            },
            "East Gwillimbury": {
                "lat": 44.1333,
                "lng": -79.4167
            },
            "C\u00f4te-Saint-Luc": {
                "lat": 45.4687,
                "lng": -73.6673
            },
            "Orangeville": {
                "lat": 43.9167,
                "lng": -80.1167
            },
            "Leduc": {
                "lat": 53.2594,
                "lng": -113.5492
            },
            "Moose Jaw": {
                "lat": 50.3933,
                "lng": -105.5519
            },
            "Port Moody": {
                "lat": 49.2831,
                "lng": -122.8317
            },
            "Pointe-Claire": {
                "lat": 45.45,
                "lng": -73.8167
            },
            "Orillia": {
                "lat": 44.6,
                "lng": -79.4167
            },
            "Stratford": {
                "lat": 46.2167,
                "lng": -63.0893
            },
            "Saint-Georges": {
                "lat": 46.0966,
                "lng": -70.6768
            },
            "Fort Erie": {
                "lat": 42.9167,
                "lng": -79.0167
            },
            "Val-d\u2019Or": {
                "lat": 48.1,
                "lng": -77.7833
            },
            "LaSalle": {
                "lat": 42.2167,
                "lng": -83.0667
            },
            "Cochrane": {
                "lat": 51.189,
                "lng": -114.467
            },
            "North Cowichan": {
                "lat": 48.8236,
                "lng": -123.7192
            },
            "Lloydminster": {
                "lat": 53.2807,
                "lng": -110.035
            },
            "Chambly": {
                "lat": 45.4311,
                "lng": -73.2873
            },
            "Centre Wellington": {
                "lat": 43.7,
                "lng": -80.3667
            },
            "Okotoks": {
                "lat": 50.725,
                "lng": -113.975
            },
            "Alma": {
                "lat": 48.55,
                "lng": -71.65
            },
            "Sainte-Julie": {
                "lat": 45.5833,
                "lng": -73.3333
            },
            "Saint-Constant": {
                "lat": 45.37,
                "lng": -73.57
            },
            "Langley": {
                "lat": 49.0987,
                "lng": -122.6597
            },
            "Grimsby": {
                "lat": 43.2,
                "lng": -79.55
            },
            "Magog": {
                "lat": 45.2667,
                "lng": -72.15
            },
            "Boisbriand": {
                "lat": 45.62,
                "lng": -73.83
            },
            "Whitehorse": {
                "lat": 60.7029,
                "lng": -135.0691
            },
            "Dieppe": {
                "lat": 46.0989,
                "lng": -64.7242
            },
            "King": {
                "lat": 44.0463,
                "lng": -79.6044
            },
            "Parksville": {
                "lat": 49.315,
                "lng": -124.312
            },
            "Conception Bay South": {
                "lat": 47.5167,
                "lng": -52.9833
            },
            "Keswick": {
                "lat": 44.25,
                "lng": -79.4667
            },
            "Fort Saskatchewan": {
                "lat": 53.7128,
                "lng": -113.2133
            },
            "Woolwich": {
                "lat": 43.5667,
                "lng": -80.4833
            },
            "Bolton": {
                "lat": 43.8833,
                "lng": -79.7333
            },
            "Sainte-Th\u00e9r\u00e8se": {
                "lat": 45.6333,
                "lng": -73.85
            },
            "Clarence-Rockland": {
                "lat": 45.4833,
                "lng": -75.2
            },
            "La Prairie": {
                "lat": 45.42,
                "lng": -73.5
            },
            "Saint-Bruno-de-Montarville": {
                "lat": 45.5333,
                "lng": -73.35
            },
            "Midland": {
                "lat": 44.75,
                "lng": -79.8833
            },
            "Thetford Mines": {
                "lat": 46.1,
                "lng": -71.3
            },
            "Lincoln": {
                "lat": 43.13,
                "lng": -79.43
            },
            "Quispamsis": {
                "lat": 45.4322,
                "lng": -65.9462
            },
            "Wasaga Beach": {
                "lat": 44.5206,
                "lng": -80.0167
            },
            "Collingwood": {
                "lat": 44.5,
                "lng": -80.2167
            },
            "Sept-\u00celes": {
                "lat": 50.2167,
                "lng": -66.3833
            },
            "Duncan": {
                "lat": 48.7787,
                "lng": -123.7079
            },
            "Hudson": {
                "lat": 45.45,
                "lng": -74.15
            },
            "Saint-Lin--Laurentides": {
                "lat": 45.85,
                "lng": -73.7667
            },
            "Tsawwassen": {
                "lat": 49.0436,
                "lng": -123.0985
            },
            "Strathroy-Caradoc": {
                "lat": 42.9575,
                "lng": -81.6167
            },
            "Squamish": {
                "lat": 49.7017,
                "lng": -123.1589
            },
            "Thorold": {
                "lat": 43.1167,
                "lng": -79.2
            },
            "Truro": {
                "lat": 45.3647,
                "lng": -63.28
            },
            "Amherstburg": {
                "lat": 42.1,
                "lng": -83.0833
            },
            "L\u2019Assomption": {
                "lat": 45.8333,
                "lng": -73.4167
            },
            "Tecumseh": {
                "lat": 42.2431,
                "lng": -82.9256
            },
            "Alliston": {
                "lat": 44.15,
                "lng": -79.8667
            },
            "Fergus": {
                "lat": 43.707,
                "lng": -80.3777
            },
            "Ladner": {
                "lat": 49.0833,
                "lng": -123.0833
            },
            "Candiac": {
                "lat": 45.38,
                "lng": -73.52
            },
            "Essa": {
                "lat": 44.25,
                "lng": -79.7833
            },
            "Paradise": {
                "lat": 47.5333,
                "lng": -52.8667
            },
            "Saint-Lambert": {
                "lat": 45.5,
                "lng": -73.5167
            },
            "Mount Pearl Park": {
                "lat": 47.5189,
                "lng": -52.8058
            },
            "Lindsay": {
                "lat": 44.35,
                "lng": -78.7333
            },
            "Saint-Lazare": {
                "lat": 45.4,
                "lng": -74.1333
            },
            "Owen Sound": {
                "lat": 44.5667,
                "lng": -80.9333
            },
            "Brockville": {
                "lat": 44.5833,
                "lng": -75.6833
            },
            "Chestermere": {
                "lat": 51.05,
                "lng": -113.8225
            },
            "Kingsville": {
                "lat": 42.1,
                "lng": -82.7167
            },
            "Port Alberni": {
                "lat": 49.2339,
                "lng": -124.805
            },
            "Springwater": {
                "lat": 44.4333,
                "lng": -79.7333
            },
            "Scugog": {
                "lat": 44.09,
                "lng": -78.936
            },
            "Uxbridge": {
                "lat": 44.1167,
                "lng": -79.1333
            },
            "Fort St. John": {
                "lat": 56.2465,
                "lng": -120.8476
            },
            "Wilmot": {
                "lat": 43.4,
                "lng": -80.65
            },
            "Essex": {
                "lat": 42.0833,
                "lng": -82.9
            },
            "Varennes": {
                "lat": 45.6833,
                "lng": -73.4333
            },
            "Oro-Medonte": {
                "lat": 44.5667,
                "lng": -79.5833
            },
            "Mont-Royal": {
                "lat": 45.5161,
                "lng": -73.6431
            },
            "Beaumont": {
                "lat": 53.3572,
                "lng": -113.4147
            },
            "Baie-Comeau": {
                "lat": 49.2167,
                "lng": -68.15
            },
            "Riverview": {
                "lat": 46.0613,
                "lng": -64.8052
            },
            "Cobourg": {
                "lat": 43.9667,
                "lng": -78.1667
            },
            "Cranbrook": {
                "lat": 49.5097,
                "lng": -115.7667
            },
            "Yellowknife": {
                "lat": 62.4709,
                "lng": -114.4053
            },
            "South Frontenac": {
                "lat": 44.5081,
                "lng": -76.4939
            },
            "Rivi\u00e8re-du-Loup": {
                "lat": 47.8333,
                "lng": -69.5333
            },
            "Port Colborne": {
                "lat": 42.8833,
                "lng": -79.25
            },
            "Saint-Augustin-de-Desmaures": {
                "lat": 46.7333,
                "lng": -71.4667
            },
            "Huntsville": {
                "lat": 45.3333,
                "lng": -79.2167
            },
            "Sainte-Marthe-sur-le-Lac": {
                "lat": 45.53,
                "lng": -73.93
            },
            "Westmount": {
                "lat": 45.4833,
                "lng": -73.6
            },
            "Russell": {
                "lat": 45.2569,
                "lng": -75.3583
            },
            "Les Coteaux": {
                "lat": 45.28,
                "lng": -74.23
            },
            "Salmon Arm": {
                "lat": 50.7022,
                "lng": -119.2722
            },
            "Kirkland": {
                "lat": 45.45,
                "lng": -73.8667
            },
            "Corner Brook": {
                "lat": 48.9287,
                "lng": -57.926
            },
            "New Glasgow": {
                "lat": 45.5926,
                "lng": -62.6455
            },
            "Dorval": {
                "lat": 45.45,
                "lng": -73.75
            },
            "Beaconsfield": {
                "lat": 45.4333,
                "lng": -73.8667
            },
            "Pitt Meadows": {
                "lat": 49.2333,
                "lng": -122.6833
            },
            "Niagara-on-the-Lake": {
                "lat": 43.2553,
                "lng": -79.0717
            },
            "Colwood": {
                "lat": 48.4236,
                "lng": -123.4958
            },
            "Middlesex Centre": {
                "lat": 43.05,
                "lng": -81.45
            },
            "Mont-Saint-Hilaire": {
                "lat": 45.5622,
                "lng": -73.1917
            },
            "Camrose": {
                "lat": 53.0167,
                "lng": -112.8333
            },
            "Selwyn": {
                "lat": 44.4167,
                "lng": -78.3333
            },
            "Tillsonburg": {
                "lat": 42.8667,
                "lng": -80.7333
            },
            "Pelham": {
                "lat": 43.0333,
                "lng": -79.3333
            },
            "Petawawa": {
                "lat": 45.9,
                "lng": -77.2833
            },
            "Stony Plain": {
                "lat": 53.5264,
                "lng": -114.0069
            },
            "Oak Bay": {
                "lat": 48.4264,
                "lng": -123.3228
            },
            "North Grenville": {
                "lat": 44.9667,
                "lng": -75.65
            },
            "Loyalist": {
                "lat": 44.25,
                "lng": -76.75
            },
            "Deux-Montagnes": {
                "lat": 45.5333,
                "lng": -73.8833
            },
            "Steinbach": {
                "lat": 49.5258,
                "lng": -96.6839
            },
            "Saint-Colomban": {
                "lat": 45.73,
                "lng": -74.13
            },
            "Miramichi": {
                "lat": 47.0196,
                "lng": -65.5072
            },
            "Esquimalt": {
                "lat": 48.4306,
                "lng": -123.4147
            },
            "Buckingham": {
                "lat": 45.5889,
                "lng": -75.4164
            },
            "Central Saanich": {
                "lat": 48.5142,
                "lng": -123.3839
            },
            "Sainte-Catherine": {
                "lat": 45.4,
                "lng": -73.58
            },
            "Port Hope": {
                "lat": 43.95,
                "lng": -78.3
            },
            "Inverness": {
                "lat": 46.2,
                "lng": -61.1
            },
            "Saint-Basile-le-Grand": {
                "lat": 45.5333,
                "lng": -73.2833
            },
            "L\u2019Ancienne-Lorette": {
                "lat": 46.8,
                "lng": -71.35
            },
            "Glace Bay": {
                "lat": 46.2,
                "lng": -59.9667
            },
            "Swift Current": {
                "lat": 50.2881,
                "lng": -107.7939
            },
            "Pembroke": {
                "lat": 45.8167,
                "lng": -77.1
            },
            "Edmundston": {
                "lat": 47.3765,
                "lng": -68.3253
            },
            "Yorkton": {
                "lat": 51.2139,
                "lng": -102.4628
            },
            "Springfield": {
                "lat": 49.9292,
                "lng": -96.6939
            },
            "Sylvan Lake": {
                "lat": 52.3083,
                "lng": -114.0964
            },
            "Simcoe": {
                "lat": 42.8376,
                "lng": -80.3073
            },
            "Strathroy": {
                "lat": 42.9558,
                "lng": -81.6226
            },
            "Bracebridge": {
                "lat": 45.0333,
                "lng": -79.3
            },
            "Summerside": {
                "lat": 46.4,
                "lng": -63.7833
            },
            "Canmore": {
                "lat": 51.089,
                "lng": -115.359
            },
            "Bathurst": {
                "lat": 47.62,
                "lng": -65.65
            },
            "Greater Napanee": {
                "lat": 44.25,
                "lng": -76.95
            },
            "Lake Country": {
                "lat": 50.0833,
                "lng": -119.4142
            },
            "Laurentides": {
                "lat": 45.85,
                "lng": -73.7667
            },
            "Hanover": {
                "lat": 49.4433,
                "lng": -96.8492
            },
            "Winkler": {
                "lat": 49.1817,
                "lng": -97.9397
            },
            "Saint-Charles-Borrom\u00e9e": {
                "lat": 46.05,
                "lng": -73.4667
            },
            "Cowansville": {
                "lat": 45.2,
                "lng": -72.75
            },
            "Sainte-Anne-des-Plaines": {
                "lat": 45.7617,
                "lng": -73.8204
            },
            "Gasp\u00e9": {
                "lat": 48.8333,
                "lng": -64.4833
            },
            "Sooke": {
                "lat": 48.3761,
                "lng": -123.7378
            },
            "Kenora": {
                "lat": 49.7667,
                "lng": -94.4833
            },
            "Cold Lake": {
                "lat": 54.4642,
                "lng": -110.1825
            },
            "Paris": {
                "lat": 43.2,
                "lng": -80.3833
            },
            "Brooks": {
                "lat": 50.5642,
                "lng": -111.8989
            },
            "Kentville": {
                "lat": 45.0775,
                "lng": -64.4958
            },
            "Comox": {
                "lat": 49.6733,
                "lng": -124.9022
            },
            "Pincourt": {
                "lat": 45.3833,
                "lng": -73.9833
            },
            "Mississippi Mills": {
                "lat": 45.2167,
                "lng": -76.2
            },
            "St. Clair": {
                "lat": 42.7833,
                "lng": -82.35
            },
            "Terrace": {
                "lat": 54.5164,
                "lng": -128.5997
            },
            "Mercier": {
                "lat": 45.32,
                "lng": -73.75
            },
            "Angus": {
                "lat": 44.3167,
                "lng": -79.8833
            },
            "West Lincoln": {
                "lat": 43.0667,
                "lng": -79.5667
            },
            "Lavaltrie": {
                "lat": 45.8833,
                "lng": -73.2833
            },
            "New Hamburg": {
                "lat": 43.3808,
                "lng": -80.7062
            },
            "West Nipissing / Nipissing Ouest": {
                "lat": 46.3667,
                "lng": -79.9167
            },
            "Strathmore": {
                "lat": 51.0378,
                "lng": -113.4003
            },
            "High River": {
                "lat": 50.5808,
                "lng": -113.8744
            },
            "Clearview": {
                "lat": 44.3981,
                "lng": -80.0742
            },
            "Lachute": {
                "lat": 45.65,
                "lng": -74.3333
            },
            "Rosem\u00e8re": {
                "lat": 45.6369,
                "lng": -73.8
            },
            "Matane": {
                "lat": 48.85,
                "lng": -67.5333
            },
            "Thames Centre": {
                "lat": 43.03,
                "lng": -81.08
            },
            "Powell River": {
                "lat": 49.8353,
                "lng": -124.5247
            },
            "Carleton Place": {
                "lat": 45.1333,
                "lng": -76.1333
            },
            "Guelph/Eramosa": {
                "lat": 43.63,
                "lng": -80.22
            },
            "Grand Falls": {
                "lat": 48.9578,
                "lng": -55.6633
            },
            "North Battleford": {
                "lat": 52.7575,
                "lng": -108.2861
            },
            "Mont-Laurier": {
                "lat": 46.55,
                "lng": -75.5
            },
            "Central Elgin": {
                "lat": 42.7667,
                "lng": -81.1
            },
            "Mistassini": {
                "lat": 48.8229,
                "lng": -72.2154
            },
            "Saugeen Shores": {
                "lat": 44.4333,
                "lng": -81.3667
            },
            "Ingersoll": {
                "lat": 43.0392,
                "lng": -80.8836
            },
            "Beauharnois": {
                "lat": 45.32,
                "lng": -73.87
            },
            "Rockland": {
                "lat": 45.5459,
                "lng": -75.2836
            },
            "South Stormont": {
                "lat": 45.0833,
                "lng": -74.9667
            },
            "B\u00e9cancour": {
                "lat": 46.3333,
                "lng": -72.4333
            },
            "Severn": {
                "lat": 44.75,
                "lng": -79.5167
            },
            "Lacombe": {
                "lat": 52.4683,
                "lng": -113.7369
            },
            "Sainte-Sophie": {
                "lat": 45.82,
                "lng": -73.9
            },
            "Port-Alfred": {
                "lat": 48.3333,
                "lng": -70.8833
            },
            "Val-des-Monts": {
                "lat": 45.65,
                "lng": -75.6667
            },
            "Beamsville": {
                "lat": 43.171,
                "lng": -79.4767
            },
            "Saint-Amable": {
                "lat": 45.65,
                "lng": -73.3
            },
            "Portage La Prairie": {
                "lat": 49.9728,
                "lng": -98.2919
            },
            "South Glengarry": {
                "lat": 45.2,
                "lng": -74.5833
            },
            "Sainte-Marie": {
                "lat": 46.45,
                "lng": -71.0333
            },
            "North Perth": {
                "lat": 43.73,
                "lng": -80.95
            },
            "Thompson": {
                "lat": 55.7433,
                "lng": -97.8553
            },
            "Trent Hills": {
                "lat": 44.3142,
                "lng": -77.8514
            },
            "Trail": {
                "lat": 49.095,
                "lng": -117.71
            },
            "The Nation / La Nation": {
                "lat": 45.35,
                "lng": -75.0333
            },
            "Amos": {
                "lat": 48.5667,
                "lng": -78.1167
            },
            "Wetaskiwin": {
                "lat": 52.9694,
                "lng": -113.3769
            },
            "West Grey": {
                "lat": 44.1833,
                "lng": -80.8167
            },
            "Warman": {
                "lat": 52.3219,
                "lng": -106.5842
            },
            "Sydney Mines": {
                "lat": 46.2333,
                "lng": -60.2167
            },
            "Dawson Creek": {
                "lat": 55.7606,
                "lng": -120.2356
            },
            "Sidney": {
                "lat": 48.6506,
                "lng": -123.3986
            },
            "Gravenhurst": {
                "lat": 44.9167,
                "lng": -79.3667
            },
            "Prince Rupert": {
                "lat": 54.3122,
                "lng": -130.3271
            },
            "Perth East": {
                "lat": 43.47,
                "lng": -80.95
            },
            "North Saanich": {
                "lat": 48.6142,
                "lng": -123.42
            },
            "Caledonia": {
                "lat": 43.0773,
                "lng": -79.9514
            },
            "Pr\u00e9vost": {
                "lat": 45.87,
                "lng": -74.08
            },
            "Sainte-Ad\u00e8le": {
                "lat": 45.95,
                "lng": -74.13
            },
            "Sainte-Agathe-des-Monts": {
                "lat": 46.05,
                "lng": -74.28
            },
            "Quesnel": {
                "lat": 52.9784,
                "lng": -122.4927
            },
            "Les \u00celes-de-la-Madeleine": {
                "lat": 47.3833,
                "lng": -61.8667
            },
            "Tach\u00e9": {
                "lat": 49.7081,
                "lng": -96.6736
            },
            "Wellington North": {
                "lat": 43.9,
                "lng": -80.57
            },
            "St. Andrews": {
                "lat": 50.27,
                "lng": -96.9747
            },
            "Williams Lake": {
                "lat": 52.1294,
                "lng": -122.1383
            },
            "Gander": {
                "lat": 48.9569,
                "lng": -54.6089
            },
            "Whistler": {
                "lat": 50.1208,
                "lng": -122.9544
            },
            "Brighton": {
                "lat": 44.1222,
                "lng": -77.7642
            },
            "Tiny": {
                "lat": 44.6833,
                "lng": -79.95
            },
            "Hawkesbury": {
                "lat": 45.6,
                "lng": -74.6
            },
            "Carignan": {
                "lat": 45.45,
                "lng": -73.3
            },
            "Brock": {
                "lat": 44.3167,
                "lng": -79.0833
            },
            "L\u2019\u00cele-Perrot": {
                "lat": 45.3833,
                "lng": -73.95
            },
            "Summerland": {
                "lat": 49.6006,
                "lng": -119.6778
            },
            "St. Clements": {
                "lat": 50.2689,
                "lng": -96.6742
            },
            "View Royal": {
                "lat": 48.4517,
                "lng": -123.4339
            },
            "Montmagny": {
                "lat": 46.9833,
                "lng": -70.55
            },
            "Cantley": {
                "lat": 45.5667,
                "lng": -75.7833
            },
            "Erin": {
                "lat": 43.7667,
                "lng": -80.0667
            },
            "Notre-Dame-de-l'\u00cele-Perrot": {
                "lat": 45.3667,
                "lng": -73.9333
            },
            "Kincardine": {
                "lat": 44.1667,
                "lng": -81.6333
            },
            "Elliot Lake": {
                "lat": 46.3833,
                "lng": -82.65
            },
            "Bromont": {
                "lat": 45.3167,
                "lng": -72.65
            },
            "Arnprior": {
                "lat": 45.4333,
                "lng": -76.35
            },
            "North Dundas": {
                "lat": 45.0833,
                "lng": -75.35
            },
            "Wellesley": {
                "lat": 43.55,
                "lng": -80.7167
            },
            "Nelson": {
                "lat": 49.5,
                "lng": -117.2833
            },
            "Ladysmith": {
                "lat": 48.9975,
                "lng": -123.8203
            },
            "Coldstream": {
                "lat": 50.22,
                "lng": -119.2481
            },
            "Hauterive": {
                "lat": 49.1833,
                "lng": -68.2667
            },
            "Georgian Bluffs": {
                "lat": 44.65,
                "lng": -81.0333
            },
            "Weyburn": {
                "lat": 49.6611,
                "lng": -103.8525
            },
            "La Tuque": {
                "lat": 48.0652,
                "lng": -74.0528
            },
            "Norwich": {
                "lat": 42.9833,
                "lng": -80.6
            },
            "Meaford": {
                "lat": 44.58,
                "lng": -80.73
            },
            "Adjala-Tosorontio": {
                "lat": 44.1333,
                "lng": -79.9333
            },
            "Hamilton Township": {
                "lat": 44.054,
                "lng": -78.2164
            },
            "Estevan": {
                "lat": 49.1392,
                "lng": -102.9861
            },
            "Dolbeau": {
                "lat": 48.8666,
                "lng": -72.2333
            },
            "South Dundas": {
                "lat": 44.9167,
                "lng": -75.2667
            },
            "Binbrook": {
                "lat": 43.122,
                "lng": -79.805
            },
            "Elmira": {
                "lat": 43.5996,
                "lng": -80.5604
            },
            "Lambton Shores": {
                "lat": 43.1833,
                "lng": -81.9
            },
            "North Dumfries": {
                "lat": 43.32,
                "lng": -80.38
            },
            "Martensville": {
                "lat": 52.2897,
                "lng": -106.6667
            },
            "Mapleton": {
                "lat": 43.7358,
                "lng": -80.6681
            },
            "Rawdon": {
                "lat": 46.05,
                "lng": -73.7167
            },
            "Morinville": {
                "lat": 53.8022,
                "lng": -113.6497
            },
            "Wallaceburg": {
                "lat": 42.5984,
                "lng": -82.3916
            },
            "Blackfalds": {
                "lat": 52.3833,
                "lng": -113.8
            },
            "Chester": {
                "lat": 44.65,
                "lng": -64.3
            },
            "Queens": {
                "lat": 44.0333,
                "lng": -64.7167
            },
            "Selkirk": {
                "lat": 50.1436,
                "lng": -96.8839
            },
            "Saint-F\u00e9licien": {
                "lat": 48.65,
                "lng": -72.45
            },
            "Roberval": {
                "lat": 48.52,
                "lng": -72.23
            },
            "Rideau Lakes": {
                "lat": 44.6667,
                "lng": -76.2167
            },
            "Sechelt": {
                "lat": 49.4742,
                "lng": -123.7542
            },
            "Bois-des-Filion": {
                "lat": 45.6667,
                "lng": -73.75
            },
            "North Glengarry": {
                "lat": 45.3333,
                "lng": -74.7333
            },
            "South Huron": {
                "lat": 43.32,
                "lng": -81.5
            },
            "Marieville": {
                "lat": 45.4333,
                "lng": -73.1667
            },
            "Penetanguishene": {
                "lat": 44.7667,
                "lng": -79.9333
            },
            "Tay": {
                "lat": 44.7167,
                "lng": -79.7667
            },
            "Castlegar": {
                "lat": 49.3256,
                "lng": -117.6661
            },
            "Cavan Monaghan": {
                "lat": 44.2,
                "lng": -78.4667
            },
            "Morden": {
                "lat": 49.1919,
                "lng": -98.1006
            },
            "Temiskaming Shores": {
                "lat": 47.5167,
                "lng": -79.6833
            },
            "Hinton": {
                "lat": 53.4114,
                "lng": -117.5639
            },
            "Saint-Sauveur": {
                "lat": 45.9,
                "lng": -74.17
            },
            "Grey Highlands": {
                "lat": 44.3333,
                "lng": -80.5
            },
            "East St. Paul": {
                "lat": 49.9772,
                "lng": -97.0103
            },
            "Stoneham-et-Tewkesbury": {
                "lat": 47.1667,
                "lng": -71.4333
            },
            "Alfred and Plantagenet": {
                "lat": 45.5667,
                "lng": -74.9167
            },
            "Mont-Tremblant": {
                "lat": 46.1167,
                "lng": -74.6
            },
            "Port Elgin": {
                "lat": 44.4333,
                "lng": -81.3833
            },
            "Saint-Zotique": {
                "lat": 45.25,
                "lng": -74.25
            },
            "Saint-Raymond": {
                "lat": 46.9,
                "lng": -71.8333
            },
            "Tottenham": {
                "lat": 44.0238,
                "lng": -79.8045
            },
            "Gibsons": {
                "lat": 49.4028,
                "lng": -123.5036
            },
            "Port Perry": {
                "lat": 44.1,
                "lng": -78.9333
            },
            "Amherst": {
                "lat": 45.8167,
                "lng": -64.2167
            },
            "Elizabethtown-Kitley": {
                "lat": 44.7,
                "lng": -75.8833
            },
            "Listowel": {
                "lat": 43.7351,
                "lng": -80.9533
            },
            "Crystal Beach": {
                "lat": 42.8661,
                "lng": -79.0624
            },
            "Smiths Falls": {
                "lat": 44.9,
                "lng": -76.0167
            },
            "Lorraine": {
                "lat": 45.6833,
                "lng": -73.7833
            },
            "Ramara": {
                "lat": 44.6333,
                "lng": -79.2167
            },
            "Notre-Dame-des-Prairies": {
                "lat": 46.05,
                "lng": -73.4333
            },
            "Leeds and the Thousand Islands": {
                "lat": 44.45,
                "lng": -76.08
            },
            "Brockton": {
                "lat": 44.1667,
                "lng": -81.2167
            },
            "Laurentian Valley": {
                "lat": 45.7681,
                "lng": -77.2239
            },
            "Mono": {
                "lat": 44.0167,
                "lng": -80.0667
            },
            "Acton": {
                "lat": 43.6333,
                "lng": -80.0333
            },
            "Sainte-Julienne": {
                "lat": 45.97,
                "lng": -73.72
            },
            "Qualicum Beach": {
                "lat": 49.35,
                "lng": -124.4333
            },
            "Malahide": {
                "lat": 42.7928,
                "lng": -80.9361
            },
            "Oromocto": {
                "lat": 45.8488,
                "lng": -66.4788
            },
            "Whitecourt": {
                "lat": 54.1417,
                "lng": -115.6833
            },
            "Olds": {
                "lat": 51.7928,
                "lng": -114.1067
            },
            "Huron East": {
                "lat": 43.63,
                "lng": -81.28
            },
            "Beckwith": {
                "lat": 45.0833,
                "lng": -76.0667
            },
            "Labrador City": {
                "lat": 52.95,
                "lng": -66.9167
            },
            "Shelburne": {
                "lat": 44.0833,
                "lng": -80.2
            },
            "Stanley": {
                "lat": 49.1331,
                "lng": -98.0656
            },
            "Taber": {
                "lat": 49.7847,
                "lng": -112.1508
            },
            "Donnacona": {
                "lat": 46.6747,
                "lng": -71.7294
            },
            "Corman Park No. 344": {
                "lat": 52.2291,
                "lng": -106.8002
            },
            "L\u2019Epiphanie": {
                "lat": 45.8483,
                "lng": -73.5061
            },
            "West Perth": {
                "lat": 43.47,
                "lng": -81.2
            },
            "Campbellton": {
                "lat": 48.005,
                "lng": -66.6731
            },
            "Bridgewater": {
                "lat": 44.37,
                "lng": -64.52
            },
            "Coaldale": {
                "lat": 49.7333,
                "lng": -112.6167
            },
            "Pont-Rouge": {
                "lat": 46.75,
                "lng": -71.7
            },
            "Champlain": {
                "lat": 45.5333,
                "lng": -74.65
            },
            "Coaticook": {
                "lat": 45.1333,
                "lng": -71.8
            },
            "Embrun": {
                "lat": 45.2735,
                "lng": -75.292
            },
            "Minto": {
                "lat": 43.9167,
                "lng": -80.8667
            },
            "La P\u00eache": {
                "lat": 45.6833,
                "lng": -75.9833
            },
            "Shediac": {
                "lat": 46.2167,
                "lng": -64.5333
            },
            "Iqaluit": {
                "lat": 63.7598,
                "lng": -68.5107
            }
        }
    },
    "KE": {
        "name": "Kenya",
        "cities": {
            "Nairobi": {
                "lat": -1.2864,
                "lng": 36.8172
            },
            "Mombasa": {
                "lat": -4.05,
                "lng": 39.6667
            },
            "Nakuru": {
                "lat": -0.3,
                "lng": 36.0667
            },
            "Eldoret": {
                "lat": 0.5167,
                "lng": 35.2833
            },
            "Kisumu": {
                "lat": -0.0833,
                "lng": 34.7667
            },
            "Kikuyu": {
                "lat": -1.25,
                "lng": 36.6667
            },
            "Lunga-Lunga": {
                "lat": -4.555,
                "lng": 39.1231
            },
            "Habaswein": {
                "lat": 1.01,
                "lng": 39.49
            },
            "Ongata Rongai": {
                "lat": -1.4,
                "lng": 36.77
            },
            "Garissa": {
                "lat": -0.4569,
                "lng": 39.6583
            },
            "Molo": {
                "lat": -0.25,
                "lng": 35.7333
            },
            "Kitenkela": {
                "lat": -1.5167,
                "lng": 36.85
            },
            "Kiambu": {
                "lat": -1.1667,
                "lng": 36.8167
            },
            "Ramu": {
                "lat": 3.9375,
                "lng": 41.2203
            },
            "Kilifi": {
                "lat": -3.6333,
                "lng": 39.85
            },
            "Malindi": {
                "lat": -3.2236,
                "lng": 40.13
            },
            "Vihiga": {
                "lat": 0.05,
                "lng": 34.725
            },
            "Machakos": {
                "lat": -1.5167,
                "lng": 37.2667
            },
            "Kisii": {
                "lat": -0.6833,
                "lng": 34.7667
            },
            "Ngong": {
                "lat": -1.3667,
                "lng": 36.6333
            },
            "Mumias": {
                "lat": 0.3333,
                "lng": 34.4833
            },
            "Thika": {
                "lat": -1.0396,
                "lng": 37.09
            },
            "Nyeri": {
                "lat": -0.4167,
                "lng": 36.95
            },
            "Kakamega": {
                "lat": 0.2822,
                "lng": 34.754
            },
            "Kendu Bay": {
                "lat": -0.3596,
                "lng": 34.64
            },
            "Wajir": {
                "lat": 1.75,
                "lng": 40.05
            },
            "Lafey": {
                "lat": 3.1508,
                "lng": 41.1864
            },
            "Athi River": {
                "lat": -1.45,
                "lng": 36.9833
            },
            "Ukunda": {
                "lat": -4.2875,
                "lng": 39.5661
            },
            "Nandi Hills": {
                "lat": 0.1003,
                "lng": 35.1764
            },
            "Narok": {
                "lat": -1.0833,
                "lng": 35.8667
            },
            "Embu": {
                "lat": -0.5389,
                "lng": 37.4583
            },
            "Kitale": {
                "lat": 1.0167,
                "lng": 35.0
            },
            "Wundanyi": {
                "lat": -3.3983,
                "lng": 38.3603
            },
            "El Wak": {
                "lat": 2.8028,
                "lng": 40.9275
            },
            "Banane": {
                "lat": 0.5,
                "lng": 38.67
            },
            "Kakuma": {
                "lat": 3.7167,
                "lng": 34.8667
            },
            "Wote": {
                "lat": -1.7833,
                "lng": 37.6333
            },
            "Kimilili": {
                "lat": 0.7833,
                "lng": 34.7167
            },
            "Bungoma": {
                "lat": 0.5667,
                "lng": 34.5667
            },
            "Isiolo": {
                "lat": 0.35,
                "lng": 37.5833
            },
            "Voi": {
                "lat": -3.3906,
                "lng": 38.5769
            },
            "Meru": {
                "lat": 0.05,
                "lng": 37.65
            },
            "Webuye": {
                "lat": 0.6167,
                "lng": 34.7667
            },
            "Iten": {
                "lat": 0.6731,
                "lng": 35.5083
            },
            "Homa Bay": {
                "lat": -0.5167,
                "lng": 34.45
            },
            "Rumuruti": {
                "lat": 0.26,
                "lng": 36.5363
            },
            "Nanyuki": {
                "lat": 0.0167,
                "lng": 37.0667
            },
            "Maralal": {
                "lat": 1.1,
                "lng": 36.7
            },
            "Busia": {
                "lat": 0.4633,
                "lng": 34.1053
            },
            "Mandera": {
                "lat": 3.9167,
                "lng": 41.8333
            },
            "Kericho": {
                "lat": -0.3692,
                "lng": 35.2839
            },
            "Kitui": {
                "lat": -1.3667,
                "lng": 38.0167
            },
            "Lamu": {
                "lat": -2.2694,
                "lng": 40.9022
            },
            "Moyale": {
                "lat": 3.527,
                "lng": 39.056
            },
            "Kajiado": {
                "lat": -1.85,
                "lng": 36.7833
            },
            "Taveta": {
                "lat": -3.3956,
                "lng": 37.6761
            },
            "Takaba": {
                "lat": 3.3963,
                "lng": 40.2251
            },
            "Baragoi": {
                "lat": 1.7833,
                "lng": 36.7833
            },
            "Kapsabet": {
                "lat": 0.3333,
                "lng": 35.1667
            },
            "Eldama Ravine": {
                "lat": 0.05,
                "lng": 35.7167
            },
            "Marsabit": {
                "lat": 2.3333,
                "lng": 37.9833
            },
            "Lodwar": {
                "lat": 3.1167,
                "lng": 35.6
            },
            "Mwingi": {
                "lat": -0.9333,
                "lng": 38.05
            },
            "Bartabwa": {
                "lat": 0.83,
                "lng": 35.8
            },
            "Naivasha": {
                "lat": -0.7202,
                "lng": 36.4285
            },
            "Kerugoya": {
                "lat": -0.5,
                "lng": 37.2833
            },
            "Makuyu": {
                "lat": -0.9,
                "lng": 37.1833
            },
            "Port Victoria": {
                "lat": 0.1,
                "lng": 33.9833
            },
            "Msambweni": {
                "lat": -4.47,
                "lng": 39.48
            },
            "Kwale": {
                "lat": -4.1744,
                "lng": 39.4603
            },
            "Sotik": {
                "lat": -0.68,
                "lng": 35.12
            },
            "Kargi": {
                "lat": 2.5036,
                "lng": 37.5725
            },
            "Hola": {
                "lat": -1.5,
                "lng": 40.0333
            },
            "Mwatate": {
                "lat": -3.5047,
                "lng": 38.3778
            },
            "Namanga": {
                "lat": -2.55,
                "lng": 36.7833
            },
            "Witu": {
                "lat": -2.3889,
                "lng": 40.4378
            },
            "Karungu": {
                "lat": -0.8496,
                "lng": 34.15
            },
            "Konza": {
                "lat": -1.7496,
                "lng": 37.12
            },
            "Tsavo": {
                "lat": -2.9828,
                "lng": 38.4666
            },
            "Nyamira": {
                "lat": -0.521,
                "lng": 34.914
            },
            "Siaya": {
                "lat": 0.0667,
                "lng": 34.2833
            },
            "Murang\u2019a": {
                "lat": -0.7167,
                "lng": 37.15
            },
            "Ol Kalou": {
                "lat": -0.273,
                "lng": 36.378
            },
            "Sotik Post": {
                "lat": -0.7813,
                "lng": 35.3416
            },
            "Kapenguria": {
                "lat": 1.2333,
                "lng": 35.1167
            },
            "Kabarnet": {
                "lat": 0.494,
                "lng": 35.744
            },
            "Migori": {
                "lat": -1.0634,
                "lng": 34.4731
            }
        }
    },
    "MM": {
        "name": "Burma",
        "cities": {
            "Rangoon": {
                "lat": 16.795,
                "lng": 96.16
            },
            "Mandalay": {
                "lat": 21.9831,
                "lng": 96.0844
            },
            "Nay Pyi Taw": {
                "lat": 19.7475,
                "lng": 96.115
            },
            "Kyaukse": {
                "lat": 21.6131,
                "lng": 96.1303
            },
            "Hpa-An": {
                "lat": 16.8906,
                "lng": 97.6333
            },
            "Maungdaw": {
                "lat": 20.8167,
                "lng": 92.3667
            },
            "Magway": {
                "lat": 20.15,
                "lng": 94.95
            },
            "Pathein": {
                "lat": 16.7842,
                "lng": 94.7333
            },
            "Bago": {
                "lat": 17.3333,
                "lng": 96.4833
            },
            "Taunggyi": {
                "lat": 20.7836,
                "lng": 97.0354
            },
            "Mawlamyine": {
                "lat": 16.4847,
                "lng": 97.6258
            },
            "Natogyi": {
                "lat": 21.4167,
                "lng": 95.65
            },
            "Myitkyina": {
                "lat": 25.3833,
                "lng": 97.4
            },
            "Myeik": {
                "lat": 12.4333,
                "lng": 98.6
            },
            "Monywa": {
                "lat": 22.1083,
                "lng": 95.1417
            },
            "Myawadi": {
                "lat": 16.6878,
                "lng": 98.5083
            },
            "Thanhlyin": {
                "lat": 16.7333,
                "lng": 96.25
            },
            "Meiktila": {
                "lat": 20.8833,
                "lng": 95.8833
            },
            "Letpandan": {
                "lat": 17.7866,
                "lng": 95.7507
            },
            "Hinthada": {
                "lat": 17.6461,
                "lng": 95.4603
            },
            "Thingangyun": {
                "lat": 16.8281,
                "lng": 96.1842
            },
            "Minbya": {
                "lat": 20.3622,
                "lng": 93.2733
            },
            "Katha": {
                "lat": 24.1822,
                "lng": 96.3306
            },
            "Mogok": {
                "lat": 22.9167,
                "lng": 96.5
            },
            "Kyaunggon": {
                "lat": 17.1,
                "lng": 95.1833
            },
            "Mahlaing": {
                "lat": 21.09,
                "lng": 95.64
            },
            "Mudon": {
                "lat": 16.2578,
                "lng": 97.7164
            },
            "Lashio": {
                "lat": 22.9333,
                "lng": 97.75
            },
            "Dawei": {
                "lat": 14.0833,
                "lng": 98.2
            },
            "Taungdwingyi": {
                "lat": 20.0017,
                "lng": 95.5461
            },
            "Thanatpin": {
                "lat": 17.2942,
                "lng": 96.58
            },
            "Myebon": {
                "lat": 20.05,
                "lng": 93.3667
            },
            "Pyay": {
                "lat": 18.82,
                "lng": 95.2156
            },
            "Thandwe": {
                "lat": 18.4667,
                "lng": 94.3667
            },
            "Mogaung": {
                "lat": 25.3014,
                "lng": 96.9417
            },
            "Thaton": {
                "lat": 16.9333,
                "lng": 97.3667
            },
            "Ponnagyun": {
                "lat": 20.3342,
                "lng": 93.0039
            },
            "Pakokku": {
                "lat": 21.332,
                "lng": 95.0866
            },
            "An": {
                "lat": 19.7833,
                "lng": 94.0333
            },
            "Taungoo": {
                "lat": 18.9333,
                "lng": 96.4333
            },
            "Htison": {
                "lat": 15.9,
                "lng": 98.1833
            },
            "Sittwe": {
                "lat": 20.167,
                "lng": 92.785
            },
            "Pyinmana": {
                "lat": 19.75,
                "lng": 96.2
            },
            "Paletwa": {
                "lat": 21.3,
                "lng": 92.85
            },
            "Tiddim": {
                "lat": 23.3758,
                "lng": 93.6539
            },
            "Myingyan": {
                "lat": 21.46,
                "lng": 95.3883
            },
            "M\u00f6ng Tun": {
                "lat": 20.3,
                "lng": 98.9333
            },
            "Shwebo": {
                "lat": 22.5667,
                "lng": 95.7
            },
            "Paingkyon": {
                "lat": 17.0242,
                "lng": 97.9844
            },
            "Pyapon": {
                "lat": 16.286,
                "lng": 95.6658
            },
            "Kengtung": {
                "lat": 21.2917,
                "lng": 99.6083
            },
            "Myaydo": {
                "lat": 19.3667,
                "lng": 95.2167
            },
            "Nansang": {
                "lat": 20.8889,
                "lng": 97.7361
            },
            "Sagaing": {
                "lat": 21.8822,
                "lng": 95.9786
            },
            "Zigon": {
                "lat": 18.3385,
                "lng": 95.623
            },
            "Kawthoung": {
                "lat": 9.9833,
                "lng": 98.55
            },
            "Allanmyo": {
                "lat": 19.3783,
                "lng": 95.2279
            },
            "Myaungmya": {
                "lat": 16.6036,
                "lng": 94.9289
            },
            "Buthidaung": {
                "lat": 20.8667,
                "lng": 92.5333
            },
            "Thongwa": {
                "lat": 16.7547,
                "lng": 96.5193
            },
            "Tachilek": {
                "lat": 20.45,
                "lng": 99.8833
            },
            "Maubin": {
                "lat": 16.73,
                "lng": 95.65
            },
            "Leiktho": {
                "lat": 19.2222,
                "lng": 96.5811
            },
            "Namtu": {
                "lat": 23.0925,
                "lng": 97.4011
            },
            "Yenangyaung": {
                "lat": 20.4597,
                "lng": 94.8742
            },
            "Nyaungu": {
                "lat": 21.2,
                "lng": 94.9167
            },
            "Kawkareik": {
                "lat": 16.5556,
                "lng": 98.24
            },
            "Wakema": {
                "lat": 16.6,
                "lng": 95.1833
            },
            "Bhamo": {
                "lat": 24.2667,
                "lng": 97.2333
            },
            "Labutta": {
                "lat": 16.1467,
                "lng": 94.7611
            },
            "Mindat": {
                "lat": 21.3667,
                "lng": 93.9833
            },
            "Pyu": {
                "lat": 18.4819,
                "lng": 96.4369
            },
            "Mawlaik": {
                "lat": 23.2833,
                "lng": 94.0833
            },
            "Chauk": {
                "lat": 20.8833,
                "lng": 94.8167
            },
            "Tamu": {
                "lat": 24.2167,
                "lng": 94.3097
            },
            "Myanaung": {
                "lat": 18.2833,
                "lng": 95.3167
            },
            "Myedu": {
                "lat": 23.1833,
                "lng": 95.4
            },
            "Kyaukme": {
                "lat": 22.5392,
                "lng": 97.0283
            },
            "Nyaungdon": {
                "lat": 17.0461,
                "lng": 95.6374
            },
            "Ye": {
                "lat": 15.2467,
                "lng": 97.8556
            },
            "Namhkam": {
                "lat": 23.8333,
                "lng": 97.6833
            },
            "Mohnyin": {
                "lat": 24.7833,
                "lng": 96.3667
            },
            "Tawsalun": {
                "lat": 21.406,
                "lng": 94.136
            },
            "Sami": {
                "lat": 21.2933,
                "lng": 93.0965
            },
            "Aungban": {
                "lat": 20.6667,
                "lng": 96.6333
            },
            "Lewe": {
                "lat": 19.6333,
                "lng": 96.1167
            },
            "Du Yar": {
                "lat": 15.2617,
                "lng": 97.7819
            },
            "Hakha": {
                "lat": 22.6455,
                "lng": 93.605
            },
            "Taungup": {
                "lat": 18.85,
                "lng": 94.2333
            },
            "Kawlin": {
                "lat": 23.7903,
                "lng": 95.6806
            },
            "Panglong": {
                "lat": 24.1003,
                "lng": 98.3139
            },
            "Kyonpyaw": {
                "lat": 17.3,
                "lng": 95.2
            },
            "Yesagyo": {
                "lat": 21.6333,
                "lng": 95.2414
            },
            "Kyeintali": {
                "lat": 18.0061,
                "lng": 94.49
            },
            "Amarapura": {
                "lat": 21.9,
                "lng": 96.05
            },
            "Minbu": {
                "lat": 20.18,
                "lng": 94.87
            },
            "Khaw Zar Chaung Wa": {
                "lat": 15.0375,
                "lng": 97.8293
            },
            "Palaw": {
                "lat": 12.9597,
                "lng": 98.6545
            },
            "Ingabu": {
                "lat": 17.8167,
                "lng": 95.2667
            },
            "Shanywathit": {
                "lat": 17.4244,
                "lng": 97.8878
            },
            "Kamamaung": {
                "lat": 17.3469,
                "lng": 97.6528
            },
            "Kyaukpyu": {
                "lat": 19.4264,
                "lng": 93.5458
            },
            "Pantanaw": {
                "lat": 16.985,
                "lng": 95.4656
            },
            "Haka": {
                "lat": 22.9833,
                "lng": 94.0167
            },
            "Pauktaw": {
                "lat": 20.2128,
                "lng": 93.1308
            },
            "Ngathainggyaung": {
                "lat": 17.4,
                "lng": 95.0833
            },
            "Mawkanin": {
                "lat": 15.5106,
                "lng": 97.8533
            },
            "Kawa": {
                "lat": 17.0897,
                "lng": 96.4663
            },
            "Bawgalegyi": {
                "lat": 18.9144,
                "lng": 96.7856
            },
            "Nawnghkio": {
                "lat": 22.3167,
                "lng": 96.8
            },
            "Khampat": {
                "lat": 23.7823,
                "lng": 94.1442
            },
            "W\u0101n Long": {
                "lat": 22.1667,
                "lng": 99.1833
            },
            "Kyaukmyaung": {
                "lat": 22.5833,
                "lng": 95.95
            },
            "Razua": {
                "lat": 22.0529,
                "lng": 93.4086
            },
            "Okpo": {
                "lat": 18.1167,
                "lng": 95.6667
            },
            "Hlaingbwe": {
                "lat": 17.1333,
                "lng": 97.8333
            },
            "Shingbwiyang": {
                "lat": 26.6667,
                "lng": 96.2167
            },
            "Chikha": {
                "lat": 23.8898,
                "lng": 93.5335
            },
            "Loikaw": {
                "lat": 19.6742,
                "lng": 97.2092
            },
            "Ngaputaw": {
                "lat": 16.5378,
                "lng": 94.6936
            },
            "Sadon": {
                "lat": 25.3927,
                "lng": 97.8996
            },
            "Chaungtha": {
                "lat": 16.9667,
                "lng": 94.45
            },
            "Putao": {
                "lat": 27.3258,
                "lng": 97.4225
            },
            "Ramree": {
                "lat": 19.0833,
                "lng": 93.8667
            },
            "Falam": {
                "lat": 22.9136,
                "lng": 93.6778
            },
            "Hsenwi": {
                "lat": 23.3,
                "lng": 97.9667
            }
        }
    },
    "AU": {
        "name": "Australia",
        "cities": {
            "Melbourne": {
                "lat": -37.8142,
                "lng": 144.9631
            },
            "Sydney": {
                "lat": -33.8678,
                "lng": 151.21
            },
            "Brisbane": {
                "lat": -27.4678,
                "lng": 153.0281
            },
            "Perth": {
                "lat": -31.9559,
                "lng": 115.8606
            },
            "Adelaide": {
                "lat": -34.9275,
                "lng": 138.6
            },
            "Gold Coast": {
                "lat": -28.0167,
                "lng": 153.4
            },
            "Cranbourne": {
                "lat": -38.0996,
                "lng": 145.2834
            },
            "Canberra": {
                "lat": -35.2931,
                "lng": 149.1269
            },
            "Central Coast": {
                "lat": -33.3,
                "lng": 151.2
            },
            "Wollongong": {
                "lat": -34.4331,
                "lng": 150.8831
            },
            "Ipswich": {
                "lat": -27.6144,
                "lng": 152.7608
            },
            "Hobart": {
                "lat": -42.8806,
                "lng": 147.325
            },
            "Geelong": {
                "lat": -38.15,
                "lng": 144.35
            },
            "Townsville": {
                "lat": -19.25,
                "lng": 146.8167
            },
            "Newcastle": {
                "lat": -32.9167,
                "lng": 151.75
            },
            "Cairns": {
                "lat": -16.92,
                "lng": 145.78
            },
            "Darwin": {
                "lat": -12.4381,
                "lng": 130.8411
            },
            "Ballarat": {
                "lat": -37.5608,
                "lng": 143.8475
            },
            "Toowoomba": {
                "lat": -27.5667,
                "lng": 151.95
            },
            "Bendigo": {
                "lat": -36.75,
                "lng": 144.2667
            },
            "Mandurah": {
                "lat": -32.5289,
                "lng": 115.7231
            },
            "Launceston": {
                "lat": -41.4419,
                "lng": 147.145
            },
            "Melton": {
                "lat": -37.6833,
                "lng": 144.5833
            },
            "Mackay": {
                "lat": -21.1411,
                "lng": 149.1858
            },
            "Bunbury": {
                "lat": -33.3272,
                "lng": 115.6369
            },
            "Bundaberg": {
                "lat": -24.8661,
                "lng": 152.3494
            },
            "Rockhampton": {
                "lat": -23.3781,
                "lng": 150.5136
            },
            "Hervey Bay": {
                "lat": -25.29,
                "lng": 152.84
            },
            "Buderim": {
                "lat": -26.6847,
                "lng": 153.0532
            },
            "Wagga Wagga": {
                "lat": -35.1189,
                "lng": 147.3689
            },
            "Pakenham": {
                "lat": -38.0712,
                "lng": 145.4878
            },
            "Port Macquarie": {
                "lat": -31.4333,
                "lng": 152.9
            },
            "Caloundra": {
                "lat": -26.8047,
                "lng": 153.1338
            },
            "Dubbo": {
                "lat": -32.2569,
                "lng": 148.6011
            },
            "Orange": {
                "lat": -33.2833,
                "lng": 149.1
            },
            "Frankston": {
                "lat": -38.1333,
                "lng": 145.1167
            },
            "Sunbury": {
                "lat": -37.5811,
                "lng": 144.7139
            },
            "Gladstone": {
                "lat": -23.8427,
                "lng": 151.2555
            },
            "Mildura": {
                "lat": -34.1889,
                "lng": 142.1583
            },
            "Bathurst": {
                "lat": -33.42,
                "lng": 149.5778
            },
            "Palmerston": {
                "lat": -12.4806,
                "lng": 130.9831
            },
            "Geraldton": {
                "lat": -28.7744,
                "lng": 114.6089
            },
            "Southport": {
                "lat": -27.9678,
                "lng": 153.4136
            },
            "Shepparton": {
                "lat": -36.3833,
                "lng": 145.4
            },
            "Kalgoorlie": {
                "lat": -30.7489,
                "lng": 121.4658
            },
            "Dandenong": {
                "lat": -37.981,
                "lng": 145.215
            },
            "Warrnambool": {
                "lat": -38.3833,
                "lng": 142.4833
            },
            "Quakers Hill": {
                "lat": -33.7361,
                "lng": 150.8778
            },
            "Caboolture": {
                "lat": -27.085,
                "lng": 152.9513
            },
            "Coffs Harbour": {
                "lat": -30.3022,
                "lng": 153.1189
            },
            "Mount Gambier": {
                "lat": -37.8294,
                "lng": 140.7828
            },
            "Busselton": {
                "lat": -33.6478,
                "lng": 115.3458
            },
            "Albany": {
                "lat": -35.0228,
                "lng": 117.8814
            },
            "Traralgon": {
                "lat": -38.1958,
                "lng": 146.5403
            },
            "Alice Springs": {
                "lat": -23.7,
                "lng": 133.87
            },
            "Warragul": {
                "lat": -38.15,
                "lng": 145.9333
            },
            "Devonport": {
                "lat": -41.18,
                "lng": 146.3503
            },
            "Goulburn": {
                "lat": -34.7547,
                "lng": 149.6186
            },
            "Whyalla": {
                "lat": -33.0333,
                "lng": 137.5667
            },
            "Armidale": {
                "lat": -30.5,
                "lng": 151.65
            },
            "Wodonga": {
                "lat": -36.1214,
                "lng": 146.8881
            },
            "Yaraka": {
                "lat": -24.8819,
                "lng": 144.078
            },
            "Kwinana": {
                "lat": -32.2394,
                "lng": 115.7702
            },
            "Burnie": {
                "lat": -41.0636,
                "lng": 145.8753
            },
            "Narangba": {
                "lat": -27.2022,
                "lng": 152.9591
            },
            "Griffith": {
                "lat": -34.29,
                "lng": 146.04
            },
            "Mount Eliza": {
                "lat": -38.189,
                "lng": 145.092
            },
            "Broken Hill": {
                "lat": -31.95,
                "lng": 141.4667
            },
            "Wangaratta": {
                "lat": -36.3583,
                "lng": 146.3125
            },
            "Karratha": {
                "lat": -20.7364,
                "lng": 116.8464
            },
            "Nerang": {
                "lat": -27.9902,
                "lng": 153.3358
            },
            "Maroochydore": {
                "lat": -26.6553,
                "lng": 153.0932
            },
            "Taree": {
                "lat": -31.9,
                "lng": 152.45
            },
            "Banora Point": {
                "lat": -28.2167,
                "lng": 153.5333
            },
            "Lara": {
                "lat": -38.0167,
                "lng": 144.4167
            },
            "Maryborough": {
                "lat": -37.05,
                "lng": 143.735
            },
            "Ulladulla": {
                "lat": -35.3486,
                "lng": 150.4678
            },
            "Rochedale": {
                "lat": -27.5991,
                "lng": 153.1297
            },
            "Cessnock": {
                "lat": -32.8342,
                "lng": 151.3555
            },
            "Horsham": {
                "lat": -36.7167,
                "lng": 142.2
            },
            "Echuca": {
                "lat": -36.1333,
                "lng": 144.75
            },
            "Murray Bridge": {
                "lat": -35.117,
                "lng": 139.267
            },
            "Wallan": {
                "lat": -37.4167,
                "lng": 144.9833
            },
            "Australind": {
                "lat": -33.28,
                "lng": 115.726
            },
            "Ormeau": {
                "lat": -27.7966,
                "lng": 153.26
            },
            "Barwon Heads": {
                "lat": -38.25,
                "lng": 144.5167
            },
            "Port Lincoln": {
                "lat": -34.7322,
                "lng": 135.8586
            },
            "Emerald": {
                "lat": -23.5208,
                "lng": 148.1619
            },
            "Mount Barker": {
                "lat": -34.63,
                "lng": 117.6669
            },
            "Morwell": {
                "lat": -38.2333,
                "lng": 146.4
            },
            "Forster": {
                "lat": -32.1806,
                "lng": 152.5117
            },
            "Sale": {
                "lat": -38.1,
                "lng": 147.0667
            },
            "Coomera": {
                "lat": -27.8722,
                "lng": 153.3147
            },
            "Penrith": {
                "lat": -33.7511,
                "lng": 150.6942
            },
            "Torquay": {
                "lat": -38.3333,
                "lng": 144.3167
            },
            "Goonellabah": {
                "lat": -28.8167,
                "lng": 153.3167
            },
            "Raymond Terrace": {
                "lat": -32.7615,
                "lng": 151.7441
            },
            "Leopold": {
                "lat": -38.1892,
                "lng": 144.4644
            },
            "Campbelltown": {
                "lat": -34.0667,
                "lng": 150.8167
            },
            "Drouin": {
                "lat": -38.1333,
                "lng": 145.85
            },
            "Warwick": {
                "lat": -28.2152,
                "lng": 152.0352
            },
            "Dalby": {
                "lat": -27.1813,
                "lng": 151.2661
            },
            "Rutherford": {
                "lat": -32.7153,
                "lng": 151.525
            },
            "Broome": {
                "lat": -17.9619,
                "lng": 122.2361
            },
            "Somerville": {
                "lat": -38.226,
                "lng": 145.177
            },
            "Gracemere": {
                "lat": -23.4391,
                "lng": 150.4558
            },
            "Batemans Bay": {
                "lat": -35.7081,
                "lng": 150.1744
            },
            "Nambour": {
                "lat": -26.6269,
                "lng": 152.9591
            },
            "Corinda": {
                "lat": -27.4858,
                "lng": 153.1011
            },
            "Muswellbrook": {
                "lat": -32.2656,
                "lng": 150.8886
            },
            "Gympie": {
                "lat": -26.19,
                "lng": 152.6655
            },
            "Kingston": {
                "lat": -42.9769,
                "lng": 147.3083
            },
            "Grafton": {
                "lat": -29.6833,
                "lng": 152.9333
            },
            "Kangaroo Flat": {
                "lat": -36.7833,
                "lng": 144.2333
            },
            "Bowral": {
                "lat": -34.4792,
                "lng": 150.4181
            },
            "Benalla": {
                "lat": -36.5519,
                "lng": 145.9817
            },
            "Kingaroy": {
                "lat": -26.541,
                "lng": 151.84
            },
            "Hamilton": {
                "lat": -37.7333,
                "lng": 142.0167
            },
            "Casino": {
                "lat": -28.8667,
                "lng": 153.05
            },
            "Swan Hill": {
                "lat": -35.3333,
                "lng": 143.55
            },
            "Parkes": {
                "lat": -33.1333,
                "lng": 148.1667
            },
            "Mudgee": {
                "lat": -32.6125,
                "lng": 149.5872
            },
            "Portland": {
                "lat": -38.3333,
                "lng": 141.6
            },
            "Mount Evelyn": {
                "lat": -37.783,
                "lng": 145.385
            },
            "Inverell": {
                "lat": -29.7667,
                "lng": 151.1167
            },
            "Bowen": {
                "lat": -20.0102,
                "lng": 148.2416
            },
            "Highfields": {
                "lat": -27.4633,
                "lng": 151.9458
            },
            "Andergrove": {
                "lat": -21.093,
                "lng": 149.1855
            },
            "Nowra": {
                "lat": -34.8808,
                "lng": 150.6075
            },
            "Currumbin": {
                "lat": -28.1575,
                "lng": 153.4686
            },
            "Flemington": {
                "lat": -33.868,
                "lng": 151.0709
            },
            "Wollert": {
                "lat": -37.597,
                "lng": 145.033
            },
            "Colac": {
                "lat": -38.3403,
                "lng": 143.5847
            },
            "Gisborne": {
                "lat": -37.49,
                "lng": 144.5889
            },
            "Kelso": {
                "lat": -33.4189,
                "lng": 149.6058
            },
            "Bargara": {
                "lat": -24.8205,
                "lng": 152.4625
            },
            "Yanchep": {
                "lat": -31.55,
                "lng": 115.634
            },
            "Moe": {
                "lat": -38.1722,
                "lng": 146.2678
            },
            "Moranbah": {
                "lat": -22.0016,
                "lng": 148.0533
            },
            "Ballina": {
                "lat": -28.8636,
                "lng": 153.5328
            },
            "Redlynch": {
                "lat": -16.8894,
                "lng": 145.6977
            },
            "Mareeba": {
                "lat": -16.9969,
                "lng": 145.423
            },
            "Moss Vale": {
                "lat": -34.55,
                "lng": 150.3833
            },
            "Gunnedah": {
                "lat": -30.9667,
                "lng": 150.25
            },
            "Cowra": {
                "lat": -33.8339,
                "lng": 148.6861
            },
            "Ayr": {
                "lat": -19.5744,
                "lng": 147.4066
            },
            "Tweed Heads": {
                "lat": -28.1833,
                "lng": 153.55
            },
            "Charters Towers": {
                "lat": -20.0765,
                "lng": 146.2614
            },
            "Katoomba": {
                "lat": -33.71,
                "lng": 150.31
            },
            "Young": {
                "lat": -34.3,
                "lng": 148.3
            },
            "Bairnsdale": {
                "lat": -37.8333,
                "lng": 147.6167
            },
            "Leeton": {
                "lat": -34.5667,
                "lng": 146.4
            },
            "Atherton": {
                "lat": -17.2658,
                "lng": 145.478
            },
            "Moree": {
                "lat": -29.4658,
                "lng": 149.8339
            },
            "Ararat": {
                "lat": -37.2833,
                "lng": 142.9167
            },
            "Bongaree": {
                "lat": -27.0813,
                "lng": 153.1636
            },
            "Kiama": {
                "lat": -34.6708,
                "lng": 150.8542
            },
            "Forbes": {
                "lat": -33.3833,
                "lng": 148.0167
            },
            "Seymour": {
                "lat": -37.03,
                "lng": 145.13
            },
            "Port Augusta": {
                "lat": -32.4925,
                "lng": 137.7658
            },
            "Northam": {
                "lat": -31.6531,
                "lng": 116.6661
            },
            "Roma": {
                "lat": -26.5733,
                "lng": 148.7869
            },
            "Newman": {
                "lat": -23.3539,
                "lng": 119.7319
            },
            "Cooma": {
                "lat": -36.235,
                "lng": 149.1258
            },
            "Deniliquin": {
                "lat": -35.5333,
                "lng": 144.9667
            },
            "Yeppoon": {
                "lat": -23.1288,
                "lng": 150.7444
            },
            "Queanbeyan": {
                "lat": -35.3533,
                "lng": 149.2342
            },
            "Tumut": {
                "lat": -35.3047,
                "lng": 148.2228
            },
            "Yamba": {
                "lat": -29.4333,
                "lng": 153.35
            },
            "Biloela": {
                "lat": -24.4002,
                "lng": 150.5133
            },
            "Stawell": {
                "lat": -37.05,
                "lng": 142.7667
            },
            "Byron Bay": {
                "lat": -28.6431,
                "lng": 153.615
            },
            "Narrabri": {
                "lat": -30.3167,
                "lng": 149.7667
            },
            "Goondiwindi": {
                "lat": -28.5461,
                "lng": 150.3097
            },
            "Richmond": {
                "lat": -20.7305,
                "lng": 143.1425
            },
            "Cobram": {
                "lat": -35.9667,
                "lng": 145.65
            },
            "McMinns Lagoon": {
                "lat": -12.5329,
                "lng": 131.05
            },
            "Scone": {
                "lat": -32.0833,
                "lng": 150.85
            },
            "Singleton": {
                "lat": -32.5667,
                "lng": 151.1697
            },
            "Wonthaggi": {
                "lat": -38.6056,
                "lng": 145.5917
            },
            "Lithgow": {
                "lat": -33.4833,
                "lng": 150.15
            },
            "Albury": {
                "lat": -36.0806,
                "lng": 146.9158
            },
            "Kununurra": {
                "lat": -15.7736,
                "lng": 128.7386
            },
            "Ingham": {
                "lat": -18.6508,
                "lng": 146.1572
            },
            "Narrogin": {
                "lat": -32.936,
                "lng": 117.178
            },
            "Victor Harbor": {
                "lat": -35.55,
                "lng": 138.6167
            },
            "Manjimup": {
                "lat": -34.2411,
                "lng": 116.1464
            },
            "Berri": {
                "lat": -34.2833,
                "lng": 140.6
            },
            "Port Hedland": {
                "lat": -20.31,
                "lng": 118.6011
            },
            "Katanning": {
                "lat": -33.6908,
                "lng": 117.5553
            },
            "Lismore": {
                "lat": -28.8167,
                "lng": 153.2833
            },
            "Merimbula": {
                "lat": -36.8981,
                "lng": 149.9011
            },
            "Ceduna": {
                "lat": -32.1167,
                "lng": 133.6667
            },
            "Port Douglas": {
                "lat": -16.4834,
                "lng": 145.4652
            },
            "Wallaroo": {
                "lat": -33.9167,
                "lng": 137.6167
            },
            "Proserpine": {
                "lat": -20.4016,
                "lng": 148.5808
            },
            "Clare": {
                "lat": -33.8333,
                "lng": 138.6
            },
            "Weipa": {
                "lat": -12.63,
                "lng": 141.8786
            },
            "Smithton": {
                "lat": -40.8417,
                "lng": 145.125
            },
            "Tom Price": {
                "lat": -22.6939,
                "lng": 117.795
            },
            "Bordertown": {
                "lat": -36.3118,
                "lng": 140.7702
            },
            "Longreach": {
                "lat": -23.4422,
                "lng": 144.2491
            },
            "Charleville": {
                "lat": -26.4016,
                "lng": 146.2383
            },
            "Cloncurry": {
                "lat": -20.7047,
                "lng": 140.5052
            },
            "Exmouth": {
                "lat": -21.9331,
                "lng": 114.1281
            },
            "Merredin": {
                "lat": -31.482,
                "lng": 118.279
            },
            "Esperance": {
                "lat": -33.8611,
                "lng": 121.8919
            },
            "Scottsdale": {
                "lat": -41.1667,
                "lng": 147.5167
            },
            "Maitland": {
                "lat": -32.7167,
                "lng": 151.55
            },
            "Queenstown": {
                "lat": -42.0806,
                "lng": 145.5556
            },
            "Bourke": {
                "lat": -30.1,
                "lng": 145.9333
            },
            "Tumby Bay": {
                "lat": -34.3667,
                "lng": 136.1
            },
            "Halls Creek": {
                "lat": -18.23,
                "lng": 127.67
            },
            "Peterborough": {
                "lat": -32.9667,
                "lng": 138.8333
            },
            "Port Denison": {
                "lat": -29.275,
                "lng": 114.9194
            },
            "Penola": {
                "lat": -37.3786,
                "lng": 140.8362
            },
            "Kingston South East": {
                "lat": -36.8167,
                "lng": 139.85
            },
            "Wagin": {
                "lat": -33.3167,
                "lng": 117.35
            },
            "Kalbarri": {
                "lat": -27.71,
                "lng": 114.16
            },
            "Katherine": {
                "lat": -14.4667,
                "lng": 132.2667
            },
            "Barcaldine": {
                "lat": -23.5569,
                "lng": 145.2883
            },
            "Innisfail": {
                "lat": -17.5238,
                "lng": 146.0311
            },
            "Ouyen": {
                "lat": -35.0667,
                "lng": 142.3167
            },
            "Hughenden": {
                "lat": -20.8438,
                "lng": 144.1986
            },
            "Cowell": {
                "lat": -33.6833,
                "lng": 136.9167
            },
            "Streaky Bay": {
                "lat": -32.799,
                "lng": 134.2048
            },
            "Laverton": {
                "lat": -28.6278,
                "lng": 122.4031
            },
            "Meningie": {
                "lat": -35.6883,
                "lng": 139.3378
            },
            "Winton": {
                "lat": -22.3913,
                "lng": 143.0381
            },
            "Yulara": {
                "lat": -25.2069,
                "lng": 130.971
            },
            "Gingin": {
                "lat": -31.34,
                "lng": 115.91
            },
            "Onslow": {
                "lat": -21.64,
                "lng": 115.11
            },
            "Bicheno": {
                "lat": -41.8667,
                "lng": 148.2833
            },
            "Wyndham": {
                "lat": -15.4825,
                "lng": 128.1228
            },
            "Roebourne": {
                "lat": -20.7667,
                "lng": 117.15
            },
            "Pannawonica": {
                "lat": -21.6333,
                "lng": 116.3333
            },
            "Meekatharra": {
                "lat": -26.5936,
                "lng": 118.4953
            },
            "Leonora": {
                "lat": -28.8845,
                "lng": 121.3308
            },
            "Gawler": {
                "lat": -34.5981,
                "lng": 138.745
            },
            "Kimba": {
                "lat": -33.1396,
                "lng": 136.4191
            },
            "Quilpie": {
                "lat": -26.6161,
                "lng": 144.2675
            },
            "Oatlands": {
                "lat": -42.3,
                "lng": 147.3706
            },
            "Norseman": {
                "lat": -32.1961,
                "lng": 121.7781
            },
            "Wilcannia": {
                "lat": -31.5569,
                "lng": 143.3792
            },
            "Southern Cross": {
                "lat": -31.2306,
                "lng": 119.3278
            },
            "Karumba": {
                "lat": -17.4838,
                "lng": 140.8397
            },
            "Kempsey": {
                "lat": -31.0833,
                "lng": 152.8333
            },
            "Mount Magnet": {
                "lat": -28.06,
                "lng": 117.8461
            },
            "Woomera": {
                "lat": -31.1496,
                "lng": 136.8
            },
            "Morawa": {
                "lat": -29.2111,
                "lng": 116.0089
            },
            "Theodore": {
                "lat": -24.9472,
                "lng": 150.0755
            },
            "Eidsvold": {
                "lat": -25.3719,
                "lng": 151.123
            },
            "Three Springs": {
                "lat": -29.5333,
                "lng": 115.7617
            },
            "Ravensthorpe": {
                "lat": -33.5817,
                "lng": 120.0469
            },
            "Pine Creek": {
                "lat": -13.8235,
                "lng": 131.8262
            },
            "Andamooka": {
                "lat": -30.4485,
                "lng": 137.1651
            },
            "Adelaide River": {
                "lat": -13.2378,
                "lng": 131.1047
            },
            "Burketown": {
                "lat": -17.7408,
                "lng": 139.5477
            },
            "Georgetown": {
                "lat": -18.2922,
                "lng": 143.548
            },
            "Boulia": {
                "lat": -22.9097,
                "lng": 139.9066
            },
            "Carnarvon": {
                "lat": -24.8672,
                "lng": 113.6611
            },
            "Thargomindah": {
                "lat": -27.9922,
                "lng": 143.8197
            },
            "Tamworth": {
                "lat": -31.0833,
                "lng": 150.9167
            },
            "Port Pirie": {
                "lat": -33.1858,
                "lng": 138.0169
            },
            "Ivanhoe": {
                "lat": -32.9164,
                "lng": 144.2994
            },
            "Camooweal": {
                "lat": -19.9211,
                "lng": 138.1194
            },
            "Bedourie": {
                "lat": -24.3594,
                "lng": 139.4691
            },
            "Mount Isa": {
                "lat": -20.7261,
                "lng": 139.4938
            },
            "Birdsville": {
                "lat": -25.8986,
                "lng": 139.3513
            },
            "Windorah": {
                "lat": -25.4205,
                "lng": 142.6544
            },
            "Kingoonya": {
                "lat": -30.917,
                "lng": 135.3147
            }
        }
    },
    "CI": {
        "name": "C\u00f4te d\u2019Ivoire",
        "cities": {
            "Abidjan": {
                "lat": 5.3167,
                "lng": -4.0333
            },
            "Yopougon": {
                "lat": 5.3167,
                "lng": -4.0667
            },
            "Cocody": {
                "lat": 5.35,
                "lng": -3.9667
            },
            "Bouak\u00e9": {
                "lat": 7.6833,
                "lng": -5.0167
            },
            "Port-Bou\u00ebt": {
                "lat": 5.2667,
                "lng": -3.9
            },
            "Daloa": {
                "lat": 6.8833,
                "lng": -6.45
            },
            "Koumassi": {
                "lat": 5.3,
                "lng": -3.95
            },
            "San-P\u00e9dro": {
                "lat": 4.75,
                "lng": -6.6333
            },
            "Attiecoub\u00e9": {
                "lat": 5.3333,
                "lng": -4.0333
            },
            "Korhogo": {
                "lat": 9.4167,
                "lng": -5.6167
            },
            "Gagnoa": {
                "lat": 6.1333,
                "lng": -5.9333
            },
            "Soubr\u00e9": {
                "lat": 5.7833,
                "lng": -6.6
            },
            "Oum\u00e9": {
                "lat": 6.3833,
                "lng": -5.4167
            },
            "Man": {
                "lat": 7.4,
                "lng": -7.55
            },
            "Duekou\u00e9": {
                "lat": 6.7333,
                "lng": -7.35
            },
            "Marcory": {
                "lat": 5.3,
                "lng": -3.9833
            },
            "Bingerville": {
                "lat": 5.35,
                "lng": -3.9
            },
            "Divo": {
                "lat": 5.8333,
                "lng": -5.3667
            },
            "Abengourou": {
                "lat": 6.7333,
                "lng": -3.4833
            },
            "Ferkess\u00e9dougou": {
                "lat": 9.5833,
                "lng": -5.2
            },
            "Adzop\u00e9": {
                "lat": 6.1667,
                "lng": -3.9833
            },
            "Bondoukou": {
                "lat": 8.0333,
                "lng": -2.8
            },
            "Dabou": {
                "lat": 5.3167,
                "lng": -4.3833
            },
            "Sinfra": {
                "lat": 6.85,
                "lng": -5.9167
            },
            "Agboville": {
                "lat": 5.9333,
                "lng": -4.2167
            },
            "Grand-Bassam": {
                "lat": 5.2,
                "lng": -3.7333
            },
            "Daoukro": {
                "lat": 7.05,
                "lng": -3.9667
            },
            "Aboisso": {
                "lat": 5.4667,
                "lng": -3.2
            },
            "Bouna": {
                "lat": 9.2667,
                "lng": -3.0
            },
            "Boundiali": {
                "lat": 9.5167,
                "lng": -6.4833
            },
            "Katiola": {
                "lat": 8.1333,
                "lng": -5.1
            },
            "Sassandra": {
                "lat": 4.95,
                "lng": -6.0833
            },
            "Odienn\u00e9": {
                "lat": 9.5,
                "lng": -7.5667
            },
            "Dabakala": {
                "lat": 8.3667,
                "lng": -4.4333
            },
            "Grand-Lahou": {
                "lat": 5.1333,
                "lng": -5.0167
            },
            "Bongouanou": {
                "lat": 6.65,
                "lng": -4.2
            },
            "Mankono": {
                "lat": 8.05,
                "lng": -6.1833
            },
            "Bouafl\u00e9": {
                "lat": 6.9833,
                "lng": -5.75
            },
            "Biankouma": {
                "lat": 7.7333,
                "lng": -7.6167
            },
            "Dimbokro": {
                "lat": 6.65,
                "lng": -4.7
            },
            "S\u00e9gu\u00e9la": {
                "lat": 7.9667,
                "lng": -6.6667
            },
            "Guiglo": {
                "lat": 6.55,
                "lng": -7.4833
            },
            "Touba": {
                "lat": 8.2833,
                "lng": -7.6833
            },
            "Toumodi": {
                "lat": 6.552,
                "lng": -5.019
            },
            "Yamoussoukro": {
                "lat": 6.8161,
                "lng": -5.2742
            },
            "Toumoukro": {
                "lat": 10.3833,
                "lng": -5.75
            },
            "Kong": {
                "lat": 9.15,
                "lng": -4.6167
            },
            "Rubino": {
                "lat": 6.0667,
                "lng": -4.3167
            },
            "Jacqueville": {
                "lat": 5.2,
                "lng": -4.4167
            },
            "Bin-Houy\u00e9": {
                "lat": 6.7833,
                "lng": -8.3167
            },
            "No\u00e9": {
                "lat": 5.2833,
                "lng": -2.8
            },
            "Bonoufla": {
                "lat": 7.1333,
                "lng": -6.4833
            },
            "Sipilou": {
                "lat": 7.8667,
                "lng": -8.1
            },
            "Zaliohouan": {
                "lat": 6.7833,
                "lng": -6.2333
            },
            "Yabayo": {
                "lat": 5.95,
                "lng": -6.6
            },
            "Lu\u00e9noufla": {
                "lat": 7.0667,
                "lng": -6.25
            },
            "Mab\u00e9hiri": {
                "lat": 5.6833,
                "lng": -6.4167
            },
            "Para": {
                "lat": 5.5167,
                "lng": -7.3333
            },
            "B\u00e9digoazon": {
                "lat": 6.5667,
                "lng": -7.7833
            },
            "Brofodoum\u00e9": {
                "lat": 5.5167,
                "lng": -3.9333
            },
            "Ahigb\u00e9 Koffikro": {
                "lat": 5.4,
                "lng": -3.3833
            },
            "Doukouya": {
                "lat": 6.4333,
                "lng": -5.55
            },
            "Minignan": {
                "lat": 10.0,
                "lng": -7.8333
            },
            "P\u00e9l\u00e9zi": {
                "lat": 7.2833,
                "lng": -6.8333
            },
            "Mignour\u00e9": {
                "lat": 7.4833,
                "lng": -6.7833
            },
            "Papara": {
                "lat": 10.6167,
                "lng": -6.25
            },
            "Bangolo": {
                "lat": 7.0123,
                "lng": -7.4864
            }
        }
    },
    "DE": {
        "name": "Germany",
        "cities": {
            "Berlin": {
                "lat": 52.52,
                "lng": 13.405
            },
            "Stuttgart": {
                "lat": 48.7775,
                "lng": 9.18
            },
            "Munich": {
                "lat": 48.1375,
                "lng": 11.575
            },
            "Hamburg": {
                "lat": 53.55,
                "lng": 10.0
            },
            "Cologne": {
                "lat": 50.9364,
                "lng": 6.9528
            },
            "Frankfurt": {
                "lat": 50.1106,
                "lng": 8.6822
            },
            "D\u00fcsseldorf": {
                "lat": 51.2333,
                "lng": 6.7833
            },
            "Leipzig": {
                "lat": 51.34,
                "lng": 12.375
            },
            "Dortmund": {
                "lat": 51.5139,
                "lng": 7.4653
            },
            "Essen": {
                "lat": 51.4508,
                "lng": 7.0131
            },
            "Bremen": {
                "lat": 53.0758,
                "lng": 8.8072
            },
            "Dresden": {
                "lat": 51.05,
                "lng": 13.74
            },
            "Hannover": {
                "lat": 52.3667,
                "lng": 9.7167
            },
            "Nuremberg": {
                "lat": 49.4539,
                "lng": 11.0775
            },
            "Duisburg": {
                "lat": 51.4347,
                "lng": 6.7625
            },
            "Bochum": {
                "lat": 51.4819,
                "lng": 7.2158
            },
            "Wuppertal": {
                "lat": 51.2667,
                "lng": 7.1833
            },
            "Bielefeld": {
                "lat": 52.0211,
                "lng": 8.5347
            },
            "Bonn": {
                "lat": 50.7333,
                "lng": 7.1
            },
            "M\u00fcnster": {
                "lat": 49.9167,
                "lng": 8.8667
            },
            "Mannheim": {
                "lat": 49.4878,
                "lng": 8.4661
            },
            "Karlsruhe": {
                "lat": 49.0092,
                "lng": 8.404
            },
            "Augsburg": {
                "lat": 48.3689,
                "lng": 10.8978
            },
            "Wiesbaden": {
                "lat": 50.0825,
                "lng": 8.24
            },
            "M\u00f6nchengladbach": {
                "lat": 51.2,
                "lng": 6.4333
            },
            "Gelsenkirchen": {
                "lat": 51.5167,
                "lng": 7.1
            },
            "Aachen": {
                "lat": 50.7756,
                "lng": 6.0836
            },
            "Braunschweig": {
                "lat": 52.2667,
                "lng": 10.5167
            },
            "Chemnitz": {
                "lat": 50.8333,
                "lng": 12.9167
            },
            "Kiel": {
                "lat": 54.3233,
                "lng": 10.1394
            },
            "Halle": {
                "lat": 51.4828,
                "lng": 11.9697
            },
            "Magdeburg": {
                "lat": 52.1317,
                "lng": 11.6392
            },
            "Freiburg im Breisgau": {
                "lat": 47.995,
                "lng": 7.85
            },
            "Krefeld": {
                "lat": 51.3333,
                "lng": 6.5667
            },
            "Mainz": {
                "lat": 49.9994,
                "lng": 8.2736
            },
            "L\u00fcbeck": {
                "lat": 53.8697,
                "lng": 10.6864
            },
            "Oberhausen": {
                "lat": 51.4967,
                "lng": 6.8706
            },
            "Rostock": {
                "lat": 54.0833,
                "lng": 12.1333
            },
            "Kassel": {
                "lat": 51.3158,
                "lng": 9.4979
            },
            "Hagen": {
                "lat": 51.3667,
                "lng": 7.4833
            },
            "Potsdam": {
                "lat": 52.4,
                "lng": 13.0667
            },
            "Saarbr\u00fccken": {
                "lat": 49.2333,
                "lng": 7.0
            },
            "Hamm": {
                "lat": 51.6667,
                "lng": 7.8167
            },
            "Ludwigshafen": {
                "lat": 49.4811,
                "lng": 8.4353
            },
            "Oldenburg": {
                "lat": 53.1439,
                "lng": 8.2139
            },
            "M\u00fclheim": {
                "lat": 51.4275,
                "lng": 6.8825
            },
            "Osnabr\u00fcck": {
                "lat": 52.2833,
                "lng": 8.05
            },
            "Leverkusen": {
                "lat": 51.0333,
                "lng": 6.9833
            },
            "Heidelberg": {
                "lat": 49.4167,
                "lng": 8.7167
            },
            "Darmstadt": {
                "lat": 49.8722,
                "lng": 8.6528
            },
            "Solingen": {
                "lat": 51.1667,
                "lng": 7.0833
            },
            "Regensburg": {
                "lat": 49.0167,
                "lng": 12.0833
            },
            "Herne": {
                "lat": 51.55,
                "lng": 7.2167
            },
            "Paderborn": {
                "lat": 51.7181,
                "lng": 8.7542
            },
            "Neuss": {
                "lat": 51.2,
                "lng": 6.7
            },
            "Ingolstadt": {
                "lat": 48.7631,
                "lng": 11.425
            },
            "F\u00fcrth": {
                "lat": 49.65,
                "lng": 8.7833
            },
            "Ulm": {
                "lat": 48.4,
                "lng": 9.9833
            },
            "Heilbronn": {
                "lat": 49.15,
                "lng": 9.2167
            },
            "Pforzheim": {
                "lat": 48.895,
                "lng": 8.705
            },
            "W\u00fcrzburg": {
                "lat": 49.7833,
                "lng": 9.9333
            },
            "Wolfsburg": {
                "lat": 52.4231,
                "lng": 10.7872
            },
            "G\u00f6ttingen": {
                "lat": 51.5339,
                "lng": 9.9356
            },
            "Bottrop": {
                "lat": 51.5247,
                "lng": 6.9228
            },
            "Reutlingen": {
                "lat": 48.4833,
                "lng": 9.2167
            },
            "Erlangen": {
                "lat": 49.5833,
                "lng": 11.0167
            },
            "Bremerhaven": {
                "lat": 53.55,
                "lng": 8.5833
            },
            "Koblenz": {
                "lat": 50.3597,
                "lng": 7.5978
            },
            "Bergisch Gladbach": {
                "lat": 50.9918,
                "lng": 7.1303
            },
            "Remscheid": {
                "lat": 51.1833,
                "lng": 7.2
            },
            "Trier": {
                "lat": 49.7567,
                "lng": 6.6414
            },
            "Recklinghausen": {
                "lat": 51.585,
                "lng": 7.1619
            },
            "Jena": {
                "lat": 50.9272,
                "lng": 11.5861
            },
            "Moers": {
                "lat": 51.4592,
                "lng": 6.6197
            },
            "Salzgitter": {
                "lat": 52.15,
                "lng": 10.3333
            },
            "Siegen": {
                "lat": 50.8833,
                "lng": 8.0167
            },
            "G\u00fctersloh": {
                "lat": 51.9,
                "lng": 8.3833
            },
            "Hildesheim": {
                "lat": 52.15,
                "lng": 9.95
            },
            "Hanau": {
                "lat": 50.1328,
                "lng": 8.9169
            },
            "Kaiserslautern": {
                "lat": 49.4447,
                "lng": 7.7689
            },
            "Cottbus": {
                "lat": 51.7606,
                "lng": 14.3342
            },
            "Schwerin": {
                "lat": 53.6333,
                "lng": 11.4167
            },
            "Witten": {
                "lat": 51.4333,
                "lng": 7.3333
            },
            "Esslingen": {
                "lat": 48.7333,
                "lng": 9.3167
            },
            "Ludwigsburg": {
                "lat": 48.8975,
                "lng": 9.1922
            },
            "Gie\u00dfen": {
                "lat": 50.5833,
                "lng": 8.6667
            },
            "Gera": {
                "lat": 50.8806,
                "lng": 12.0833
            },
            "D\u00fcren": {
                "lat": 50.8,
                "lng": 6.4833
            },
            "T\u00fcbingen": {
                "lat": 48.52,
                "lng": 9.0556
            },
            "Flensburg": {
                "lat": 54.7819,
                "lng": 9.4367
            },
            "Iserlohn": {
                "lat": 51.3833,
                "lng": 7.6667
            },
            "Villingen-Schwenningen": {
                "lat": 48.0603,
                "lng": 8.4586
            },
            "Ratingen": {
                "lat": 51.3,
                "lng": 6.85
            },
            "Zwickau": {
                "lat": 50.7167,
                "lng": 12.5
            },
            "L\u00fcnen": {
                "lat": 51.6167,
                "lng": 7.5167
            },
            "Konstanz": {
                "lat": 47.6667,
                "lng": 9.1833
            },
            "Worms": {
                "lat": 49.6319,
                "lng": 8.3653
            },
            "Marl": {
                "lat": 51.6667,
                "lng": 7.1167
            },
            "Minden": {
                "lat": 52.2883,
                "lng": 8.9167
            },
            "Velbert": {
                "lat": 51.3333,
                "lng": 7.05
            },
            "Norderstedt": {
                "lat": 53.7064,
                "lng": 10.0103
            },
            "Bamberg": {
                "lat": 49.8914,
                "lng": 10.8869
            },
            "Dessau-Rosslau": {
                "lat": 51.8333,
                "lng": 12.2333
            },
            "Neum\u00fcnster": {
                "lat": 54.0714,
                "lng": 9.99
            },
            "Delmenhorst": {
                "lat": 53.0506,
                "lng": 8.6317
            },
            "Viersen": {
                "lat": 51.2561,
                "lng": 6.3972
            },
            "Rheine": {
                "lat": 52.2833,
                "lng": 7.4333
            },
            "Marburg": {
                "lat": 50.81,
                "lng": 8.7708
            },
            "L\u00fcneburg": {
                "lat": 53.2525,
                "lng": 10.4144
            },
            "Dorsten": {
                "lat": 51.66,
                "lng": 6.9642
            },
            "Troisdorf": {
                "lat": 50.8161,
                "lng": 7.1556
            },
            "Wilhelmshaven": {
                "lat": 53.5286,
                "lng": 8.1056
            },
            "Gladbeck": {
                "lat": 51.5667,
                "lng": 6.9667
            },
            "Landshut": {
                "lat": 48.5397,
                "lng": 12.1508
            },
            "Detmold": {
                "lat": 51.9378,
                "lng": 8.8833
            },
            "Bayreuth": {
                "lat": 49.9481,
                "lng": 11.5783
            },
            "Arnsberg": {
                "lat": 51.3833,
                "lng": 8.0833
            },
            "Castrop-Rauxel": {
                "lat": 51.55,
                "lng": 7.3167
            },
            "Brandenburg": {
                "lat": 52.4167,
                "lng": 12.5333
            },
            "Aschaffenburg": {
                "lat": 49.9667,
                "lng": 9.15
            },
            "Bocholt": {
                "lat": 51.8333,
                "lng": 6.6167
            },
            "L\u00fcdenscheid": {
                "lat": 51.2167,
                "lng": 7.6333
            },
            "Celle": {
                "lat": 52.6256,
                "lng": 10.0825
            },
            "Kempten": {
                "lat": 47.7333,
                "lng": 10.3167
            },
            "Fulda": {
                "lat": 50.5508,
                "lng": 9.6753
            },
            "Lippstadt": {
                "lat": 51.6667,
                "lng": 8.35
            },
            "Aalen": {
                "lat": 48.8333,
                "lng": 10.1
            },
            "Dinslaken": {
                "lat": 51.5667,
                "lng": 6.7333
            },
            "Herford": {
                "lat": 52.1333,
                "lng": 8.6833
            },
            "R\u00fcsselsheim": {
                "lat": 50.0,
                "lng": 8.4333
            },
            "Kerpen": {
                "lat": 50.8719,
                "lng": 6.6961
            },
            "Neuwied": {
                "lat": 50.4286,
                "lng": 7.4614
            },
            "Weimar": {
                "lat": 50.9811,
                "lng": 11.3294
            },
            "Dormagen": {
                "lat": 51.1,
                "lng": 6.8167
            },
            "Sindelfingen": {
                "lat": 48.7133,
                "lng": 9.0028
            },
            "Plauen": {
                "lat": 50.4833,
                "lng": 12.1167
            },
            "Grevenbroich": {
                "lat": 51.0883,
                "lng": 6.5875
            },
            "Rosenheim": {
                "lat": 47.85,
                "lng": 12.1333
            },
            "Neubrandenburg": {
                "lat": 53.5569,
                "lng": 13.2611
            },
            "Friedrichshafen": {
                "lat": 47.65,
                "lng": 9.4833
            },
            "Herten": {
                "lat": 51.6,
                "lng": 7.1333
            },
            "Bergheim": {
                "lat": 50.9667,
                "lng": 6.65
            },
            "Schw\u00e4bisch Gm\u00fcnd": {
                "lat": 48.8,
                "lng": 9.8
            },
            "Offenburg": {
                "lat": 48.4667,
                "lng": 7.9333
            },
            "Garbsen": {
                "lat": 52.4183,
                "lng": 9.5981
            },
            "Wesel": {
                "lat": 51.6586,
                "lng": 6.6178
            },
            "Neu-Ulm": {
                "lat": 48.3833,
                "lng": 10.0
            },
            "H\u00fcrth": {
                "lat": 50.8775,
                "lng": 6.8761
            },
            "Unna": {
                "lat": 51.5347,
                "lng": 7.6889
            },
            "Langenfeld": {
                "lat": 51.1167,
                "lng": 6.95
            },
            "Euskirchen": {
                "lat": 50.6597,
                "lng": 6.7917
            },
            "Greifswald": {
                "lat": 54.0833,
                "lng": 13.3833
            },
            "Stralsund": {
                "lat": 54.3092,
                "lng": 13.0819
            },
            "G\u00f6ppingen": {
                "lat": 48.7025,
                "lng": 9.6528
            },
            "Frankfurt (Oder)": {
                "lat": 52.3419,
                "lng": 14.5517
            },
            "Hameln": {
                "lat": 52.1,
                "lng": 9.3667
            },
            "Meerbusch": {
                "lat": 51.2667,
                "lng": 6.6667
            },
            "Baden-Baden": {
                "lat": 48.7628,
                "lng": 8.2408
            },
            "G\u00f6rlitz": {
                "lat": 51.1528,
                "lng": 14.9872
            },
            "Lingen": {
                "lat": 52.5231,
                "lng": 7.3231
            },
            "Stolberg": {
                "lat": 50.7667,
                "lng": 6.2333
            },
            "Sankt Augustin": {
                "lat": 50.77,
                "lng": 7.1867
            },
            "Waiblingen": {
                "lat": 48.8303,
                "lng": 9.3169
            },
            "Eschweiler": {
                "lat": 50.8167,
                "lng": 6.2833
            },
            "Hilden": {
                "lat": 51.1714,
                "lng": 6.9394
            },
            "Pulheim": {
                "lat": 51.0,
                "lng": 6.8
            },
            "Langenhagen": {
                "lat": 52.4394,
                "lng": 9.74
            },
            "Nordhorn": {
                "lat": 52.4319,
                "lng": 7.0678
            },
            "Bad Homburg": {
                "lat": 50.2167,
                "lng": 8.6
            },
            "Bad Salzuflen": {
                "lat": 52.0833,
                "lng": 8.7467
            },
            "Schweinfurt": {
                "lat": 50.05,
                "lng": 10.2333
            },
            "Hattingen": {
                "lat": 51.3992,
                "lng": 7.1858
            },
            "Wetzlar": {
                "lat": 50.5667,
                "lng": 8.5
            },
            "Neustadt": {
                "lat": 50.7333,
                "lng": 11.75
            },
            "Passau": {
                "lat": 48.5744,
                "lng": 13.4647
            },
            "Kleve": {
                "lat": 51.79,
                "lng": 6.14
            },
            "Ahlen": {
                "lat": 51.7633,
                "lng": 7.8911
            },
            "Frechen": {
                "lat": 50.9167,
                "lng": 6.8167
            },
            "Wolfenb\u00fcttel": {
                "lat": 52.1622,
                "lng": 10.5369
            },
            "Bad Kreuznach": {
                "lat": 49.85,
                "lng": 7.8667
            },
            "Ibbenb\u00fcren": {
                "lat": 52.2778,
                "lng": 7.7167
            },
            "Gummersbach": {
                "lat": 51.0333,
                "lng": 7.5667
            },
            "Ravensburg": {
                "lat": 47.7831,
                "lng": 9.6114
            },
            "B\u00f6blingen": {
                "lat": 48.6856,
                "lng": 9.0153
            },
            "Peine": {
                "lat": 52.3203,
                "lng": 10.2336
            },
            "Speyer": {
                "lat": 49.3194,
                "lng": 8.4311
            },
            "Rastatt": {
                "lat": 48.85,
                "lng": 8.2
            },
            "Elmshorn": {
                "lat": 53.7519,
                "lng": 9.6511
            },
            "Emden": {
                "lat": 53.3669,
                "lng": 7.2061
            },
            "Goslar": {
                "lat": 51.9072,
                "lng": 10.43
            },
            "Willich": {
                "lat": 51.2631,
                "lng": 6.5492
            },
            "Heidenheim": {
                "lat": 48.6761,
                "lng": 10.1544
            },
            "Erftstadt": {
                "lat": 50.8167,
                "lng": 6.7667
            },
            "L\u00f6rrach": {
                "lat": 47.6167,
                "lng": 7.6667
            },
            "Cuxhaven": {
                "lat": 53.8611,
                "lng": 8.6944
            },
            "Gronau": {
                "lat": 52.2125,
                "lng": 7.0417
            },
            "Leonberg": {
                "lat": 48.8014,
                "lng": 9.0131
            },
            "Rheda-Wiedenbr\u00fcck": {
                "lat": 51.8417,
                "lng": 8.3
            },
            "Bad Oeynhausen": {
                "lat": 52.2,
                "lng": 8.8
            },
            "Singen": {
                "lat": 47.7628,
                "lng": 8.84
            },
            "Freising": {
                "lat": 48.4028,
                "lng": 11.7489
            },
            "Bergkamen": {
                "lat": 51.6167,
                "lng": 7.6333
            },
            "Straubing": {
                "lat": 48.8833,
                "lng": 12.5667
            },
            "Frankenthal": {
                "lat": 49.5333,
                "lng": 8.35
            },
            "Bornheim": {
                "lat": 50.7592,
                "lng": 7.005
            },
            "Soest": {
                "lat": 51.5711,
                "lng": 8.1092
            },
            "Stade": {
                "lat": 53.6008,
                "lng": 9.4764
            },
            "Alsdorf": {
                "lat": 50.8833,
                "lng": 6.1667
            },
            "Dachau": {
                "lat": 48.2603,
                "lng": 11.4342
            },
            "Hennef": {
                "lat": 50.7833,
                "lng": 7.2833
            },
            "Oranienburg": {
                "lat": 52.7544,
                "lng": 13.2369
            },
            "Landau": {
                "lat": 49.2,
                "lng": 8.1167
            },
            "D\u00fclmen": {
                "lat": 51.8308,
                "lng": 7.2783
            },
            "Melle": {
                "lat": 52.2044,
                "lng": 8.3389
            },
            "Herzogenrath": {
                "lat": 50.8667,
                "lng": 6.1
            },
            "Neunkirchen": {
                "lat": 50.7997,
                "lng": 8.0
            },
            "Schwerte": {
                "lat": 51.4458,
                "lng": 7.5653
            },
            "Hof": {
                "lat": 50.3167,
                "lng": 11.9167
            },
            "Bruchsal": {
                "lat": 49.1333,
                "lng": 8.6
            },
            "Rodgau": {
                "lat": 50.0167,
                "lng": 8.8833
            },
            "Albstadt": {
                "lat": 48.2119,
                "lng": 9.0239
            },
            "Halle-Neustadt": {
                "lat": 51.4789,
                "lng": 11.9214
            },
            "Filderstadt": {
                "lat": 48.6803,
                "lng": 9.2183
            },
            "B\u00fcnde": {
                "lat": 52.2,
                "lng": 8.6
            },
            "Gotha": {
                "lat": 50.9492,
                "lng": 10.705
            },
            "Fellbach": {
                "lat": 48.8086,
                "lng": 9.2758
            },
            "Memmingen": {
                "lat": 47.9878,
                "lng": 10.1811
            },
            "Kaufbeuren": {
                "lat": 47.88,
                "lng": 10.6225
            },
            "Weinheim": {
                "lat": 49.55,
                "lng": 8.6667
            },
            "Neustadt am R\u00fcbenberge": {
                "lat": 52.5,
                "lng": 9.4667
            },
            "Lehrte": {
                "lat": 52.3667,
                "lng": 9.9667
            },
            "Falkensee": {
                "lat": 52.5583,
                "lng": 13.0917
            },
            "Br\u00fchl": {
                "lat": 49.4,
                "lng": 8.5333
            },
            "Pinneberg": {
                "lat": 53.6333,
                "lng": 9.8
            },
            "Kaarst": {
                "lat": 51.2167,
                "lng": 6.6167
            },
            "Erkelenz": {
                "lat": 51.0833,
                "lng": 6.3167
            },
            "Wismar": {
                "lat": 53.9,
                "lng": 11.4667
            },
            "Erkrath": {
                "lat": 51.2239,
                "lng": 6.9147
            },
            "Bietigheim-Bissingen": {
                "lat": 48.9667,
                "lng": 9.1333
            },
            "Gifhorn": {
                "lat": 52.4886,
                "lng": 10.5464
            },
            "Borken": {
                "lat": 51.8333,
                "lng": 6.8667
            },
            "Heinsberg": {
                "lat": 51.0631,
                "lng": 6.0964
            },
            "Nettetal": {
                "lat": 51.3167,
                "lng": 6.2833
            },
            "Kamen": {
                "lat": 51.5917,
                "lng": 7.6653
            },
            "Aurich": {
                "lat": 53.4714,
                "lng": 7.4836
            },
            "Laatzen": {
                "lat": 52.3167,
                "lng": 9.8
            },
            "Amberg": {
                "lat": 49.4444,
                "lng": 11.8483
            },
            "Seevetal": {
                "lat": 53.3833,
                "lng": 9.9667
            },
            "Eisenach": {
                "lat": 50.9761,
                "lng": 10.3206
            },
            "Homburg": {
                "lat": 49.3167,
                "lng": 7.3333
            },
            "Dreieich": {
                "lat": 50.0,
                "lng": 8.7
            },
            "Ansbach": {
                "lat": 49.3,
                "lng": 10.5833
            },
            "Bensheim": {
                "lat": 49.6667,
                "lng": 8.6167
            },
            "Siegburg": {
                "lat": 50.8014,
                "lng": 7.2044
            },
            "Kirchheim unter Teck": {
                "lat": 48.6483,
                "lng": 9.4511
            },
            "Schw\u00e4bisch Hall": {
                "lat": 49.1122,
                "lng": 9.7375
            },
            "Coburg": {
                "lat": 50.2667,
                "lng": 10.9667
            },
            "Wunstorf": {
                "lat": 52.4275,
                "lng": 9.4294
            },
            "K\u00f6nigswinter": {
                "lat": 50.6736,
                "lng": 7.1947
            },
            "Eberswalde": {
                "lat": 52.8333,
                "lng": 13.8333
            },
            "N\u00fcrtingen": {
                "lat": 48.6333,
                "lng": 9.3333
            },
            "Germering": {
                "lat": 48.1333,
                "lng": 11.3667
            },
            "Nordhausen": {
                "lat": 51.505,
                "lng": 10.7911
            },
            "H\u00fcckelhoven": {
                "lat": 51.0608,
                "lng": 6.2197
            },
            "Schwabach": {
                "lat": 49.3292,
                "lng": 11.0208
            },
            "Buxtehude": {
                "lat": 53.4769,
                "lng": 9.7011
            },
            "Buchholz in der Nordheide": {
                "lat": 53.3167,
                "lng": 9.8667
            },
            "Neumarkt": {
                "lat": 49.2833,
                "lng": 11.4667
            },
            "Pirmasens": {
                "lat": 49.2,
                "lng": 7.6
            },
            "Lemgo": {
                "lat": 52.0272,
                "lng": 8.9117
            },
            "Freiberg": {
                "lat": 50.9119,
                "lng": 13.3428
            },
            "Halberstadt": {
                "lat": 51.8958,
                "lng": 11.0467
            },
            "Leinfelden-Echterdingen": {
                "lat": 48.6928,
                "lng": 9.1428
            },
            "Hofheim": {
                "lat": 50.0833,
                "lng": 8.45
            },
            "L\u00f6hne": {
                "lat": 52.2,
                "lng": 8.7
            },
            "Ahaus": {
                "lat": 52.0667,
                "lng": 7.0
            },
            "Schorndorf": {
                "lat": 48.8,
                "lng": 9.5333
            },
            "V\u00f6lklingen": {
                "lat": 49.25,
                "lng": 6.8333
            },
            "Maintal": {
                "lat": 50.15,
                "lng": 8.8333
            },
            "Ostfildern": {
                "lat": 48.7333,
                "lng": 9.25
            },
            "Ettlingen": {
                "lat": 48.9333,
                "lng": 8.4
            },
            "Freital": {
                "lat": 51.0167,
                "lng": 13.65
            },
            "Neu Isenburg": {
                "lat": 50.05,
                "lng": 8.7
            },
            "Niederkassel": {
                "lat": 50.8167,
                "lng": 7.0333
            },
            "Langen": {
                "lat": 53.6167,
                "lng": 8.5833
            },
            "Mettmann": {
                "lat": 51.25,
                "lng": 6.9667
            },
            "Ilmenau": {
                "lat": 50.6839,
                "lng": 10.9194
            },
            "Stendal": {
                "lat": 52.6,
                "lng": 11.85
            },
            "Pirna": {
                "lat": 50.9622,
                "lng": 13.9403
            },
            "Wei\u00dfenfels": {
                "lat": 51.2,
                "lng": 11.9667
            },
            "K\u00f6nigs Wusterhausen": {
                "lat": 52.2917,
                "lng": 13.625
            },
            "Kamp-Lintfort": {
                "lat": 51.5,
                "lng": 6.5333
            },
            "W\u00fcrselen": {
                "lat": 50.8167,
                "lng": 6.1333
            },
            "Papenburg": {
                "lat": 53.0667,
                "lng": 7.4
            },
            "Greven": {
                "lat": 52.0917,
                "lng": 7.6083
            },
            "Wesseling": {
                "lat": 50.8167,
                "lng": 6.9667
            },
            "Kehl": {
                "lat": 48.5667,
                "lng": 7.8167
            },
            "Bautzen": {
                "lat": 51.1814,
                "lng": 14.4242
            },
            "Backnang": {
                "lat": 48.9464,
                "lng": 9.4306
            },
            "Bitterfeld": {
                "lat": 51.6167,
                "lng": 12.3167
            },
            "F\u00fcrstenfeldbruck": {
                "lat": 48.1778,
                "lng": 11.2556
            },
            "Warendorf": {
                "lat": 51.9539,
                "lng": 7.9933
            },
            "Tuttlingen": {
                "lat": 47.985,
                "lng": 8.8233
            },
            "Beckum": {
                "lat": 51.755,
                "lng": 8.0403
            },
            "Cloppenburg": {
                "lat": 52.85,
                "lng": 8.05
            },
            "Coesfeld": {
                "lat": 51.95,
                "lng": 7.1667
            },
            "Suhl": {
                "lat": 50.6106,
                "lng": 10.6931
            },
            "Erding": {
                "lat": 48.2833,
                "lng": 11.9
            },
            "Sinsheim": {
                "lat": 49.25,
                "lng": 8.8833
            },
            "Porta Westfalica": {
                "lat": 52.2167,
                "lng": 8.9333
            },
            "Emsdetten": {
                "lat": 52.1728,
                "lng": 7.5344
            },
            "Winsen": {
                "lat": 53.3667,
                "lng": 10.2167
            },
            "M\u00fchlhausen": {
                "lat": 49.2475,
                "lng": 8.7239
            },
            "Voerde": {
                "lat": 51.6,
                "lng": 6.6833
            },
            "Meppen": {
                "lat": 52.6936,
                "lng": 7.2928
            },
            "Limburg": {
                "lat": 50.3833,
                "lng": 8.0667
            },
            "Ingelheim": {
                "lat": 49.9747,
                "lng": 8.0564
            },
            "Crailsheim": {
                "lat": 49.1347,
                "lng": 10.0706
            },
            "Bad Vilbel": {
                "lat": 50.1781,
                "lng": 8.7361
            },
            "Leer": {
                "lat": 53.2308,
                "lng": 7.4528
            },
            "Lage": {
                "lat": 51.9889,
                "lng": 8.7944
            },
            "M\u00f6rfelden-Walldorf": {
                "lat": 50.0,
                "lng": 8.5833
            },
            "Goch": {
                "lat": 51.6839,
                "lng": 6.1619
            },
            "Sankt Ingbert": {
                "lat": 49.3,
                "lng": 7.1167
            },
            "Datteln": {
                "lat": 51.6539,
                "lng": 7.3417
            },
            "Deggendorf": {
                "lat": 48.8333,
                "lng": 12.9667
            },
            "Barsinghausen": {
                "lat": 52.3,
                "lng": 9.4811
            },
            "Steinfurt": {
                "lat": 52.1475,
                "lng": 7.3442
            },
            "Balingen": {
                "lat": 48.2731,
                "lng": 8.8506
            },
            "Dietzenbach": {
                "lat": 50.0167,
                "lng": 8.7833
            },
            "Kempen": {
                "lat": 51.3658,
                "lng": 6.4194
            },
            "Seelze": {
                "lat": 52.3961,
                "lng": 9.5981
            },
            "Wermelskirchen": {
                "lat": 51.15,
                "lng": 7.2167
            },
            "Saarlouis": {
                "lat": 49.3167,
                "lng": 6.75
            },
            "Wedel": {
                "lat": 53.5833,
                "lng": 9.7
            },
            "Zweibr\u00fccken": {
                "lat": 49.25,
                "lng": 7.3667
            },
            "Viernheim": {
                "lat": 49.5417,
                "lng": 8.5786
            },
            "Ahrensburg": {
                "lat": 53.6747,
                "lng": 10.2411
            },
            "Merseburg": {
                "lat": 51.3544,
                "lng": 11.9928
            },
            "Geldern": {
                "lat": 51.5197,
                "lng": 6.3325
            },
            "Korschenbroich": {
                "lat": 51.1833,
                "lng": 6.5167
            },
            "Kornwestheim": {
                "lat": 48.8598,
                "lng": 9.1852
            },
            "Radebeul": {
                "lat": 51.1,
                "lng": 13.65
            },
            "Hemer": {
                "lat": 51.3833,
                "lng": 7.7667
            },
            "Biberach": {
                "lat": 48.1,
                "lng": 9.7833
            },
            "Stuhr": {
                "lat": 53.0167,
                "lng": 8.75
            },
            "Uelzen": {
                "lat": 52.9647,
                "lng": 10.5658
            },
            "Vechta": {
                "lat": 52.7306,
                "lng": 8.2886
            },
            "Rheinfelden (Baden)": {
                "lat": 47.5611,
                "lng": 7.7917
            },
            "Schwedt (Oder)": {
                "lat": 53.05,
                "lng": 14.2667
            },
            "Bad Nauheim": {
                "lat": 50.3667,
                "lng": 8.75
            },
            "J\u00fclich": {
                "lat": 50.9222,
                "lng": 6.3583
            },
            "Forchheim": {
                "lat": 49.7197,
                "lng": 11.0581
            },
            "Lampertheim": {
                "lat": 49.6,
                "lng": 8.4667
            },
            "Delbr\u00fcck": {
                "lat": 51.7667,
                "lng": 8.5667
            },
            "Achim": {
                "lat": 53.0653,
                "lng": 9.0342
            },
            "Herrenberg": {
                "lat": 48.5967,
                "lng": 8.8708
            },
            "F\u00fcrstenwalde": {
                "lat": 52.3667,
                "lng": 14.0667
            },
            "Geesthacht": {
                "lat": 53.4375,
                "lng": 10.3675
            },
            "Naumburg": {
                "lat": 51.15,
                "lng": 11.8167
            },
            "Bernburg": {
                "lat": 51.8,
                "lng": 11.7333
            },
            "Itzehoe": {
                "lat": 53.925,
                "lng": 9.5164
            },
            "Georgsmarienh\u00fctte": {
                "lat": 52.2,
                "lng": 8.0667
            },
            "Bramsche": {
                "lat": 52.4,
                "lng": 7.9833
            },
            "Radolfzell am Bodensee": {
                "lat": 47.7333,
                "lng": 8.9667
            },
            "Wernigerode": {
                "lat": 51.835,
                "lng": 10.7853
            },
            "Ganderkesee": {
                "lat": 53.0358,
                "lng": 8.5483
            },
            "Oer-Erkenschwick": {
                "lat": 51.6422,
                "lng": 7.2508
            },
            "Neuruppin": {
                "lat": 52.9331,
                "lng": 12.8
            },
            "Hoyerswerda": {
                "lat": 51.4333,
                "lng": 14.25
            },
            "Altenburg": {
                "lat": 50.985,
                "lng": 12.4333
            },
            "Burgdorf": {
                "lat": 52.45,
                "lng": 10.0083
            },
            "Kreuztal": {
                "lat": 50.9667,
                "lng": 7.9667
            },
            "Rheinberg": {
                "lat": 51.5467,
                "lng": 6.6006
            },
            "Gevelsberg": {
                "lat": 51.3167,
                "lng": 7.3333
            },
            "Werl": {
                "lat": 51.55,
                "lng": 7.92
            },
            "Lohmar": {
                "lat": 50.8167,
                "lng": 7.2167
            },
            "Walsrode": {
                "lat": 52.8667,
                "lng": 9.5833
            },
            "Friedberg": {
                "lat": 48.35,
                "lng": 10.9833
            },
            "Taunusstein": {
                "lat": 50.1333,
                "lng": 8.15
            },
            "Weil am Rhein": {
                "lat": 47.5947,
                "lng": 7.6108
            },
            "Einbeck": {
                "lat": 51.8167,
                "lng": 9.8667
            },
            "Osterholz-Scharmbeck": {
                "lat": 53.2167,
                "lng": 8.8
            },
            "Bad Hersfeld": {
                "lat": 50.8683,
                "lng": 9.7075
            },
            "Ennepetal": {
                "lat": 51.2833,
                "lng": 7.3333
            },
            "Haan": {
                "lat": 51.1667,
                "lng": 7.0
            },
            "Neuburg": {
                "lat": 48.7333,
                "lng": 11.1833
            },
            "Sch\u00f6nebeck": {
                "lat": 52.0167,
                "lng": 11.75
            },
            "Andernach": {
                "lat": 50.4397,
                "lng": 7.4017
            },
            "Gaggenau": {
                "lat": 48.8039,
                "lng": 8.3194
            },
            "Bretten": {
                "lat": 49.0364,
                "lng": 8.7061
            },
            "Merzig": {
                "lat": 49.45,
                "lng": 6.6167
            },
            "Meschede": {
                "lat": 51.35,
                "lng": 8.2833
            },
            "Schwandorf": {
                "lat": 49.3283,
                "lng": 12.11
            },
            "Rietberg": {
                "lat": 51.8,
                "lng": 8.4333
            },
            "Bad Zwischenahn": {
                "lat": 53.1836,
                "lng": 8.0097
            },
            "Rendsburg": {
                "lat": 54.3,
                "lng": 9.6667
            },
            "Werve": {
                "lat": 51.6667,
                "lng": 7.6167
            },
            "Waltrop": {
                "lat": 51.6167,
                "lng": 7.3833
            },
            "Oelde": {
                "lat": 51.8333,
                "lng": 8.15
            },
            "G\u00fcstrow": {
                "lat": 53.7939,
                "lng": 12.1764
            },
            "Landsberg": {
                "lat": 51.5333,
                "lng": 12.1667
            },
            "Unterschlei\u00dfheim": {
                "lat": 48.2833,
                "lng": 11.5667
            },
            "Northeim": {
                "lat": 51.7067,
                "lng": 10.0011
            },
            "T\u00f6nisvorst": {
                "lat": 51.3208,
                "lng": 6.4931
            },
            "Vaihingen an der Enz": {
                "lat": 48.9328,
                "lng": 8.9564
            },
            "Winnenden": {
                "lat": 48.8764,
                "lng": 9.3978
            },
            "Saalfeld": {
                "lat": 50.65,
                "lng": 11.3667
            },
            "Blankenfelde": {
                "lat": 52.35,
                "lng": 13.4
            },
            "B\u00fchl": {
                "lat": 48.6953,
                "lng": 8.135
            },
            "Springe": {
                "lat": 52.2167,
                "lng": 9.55
            },
            "Kelkheim (Taunus)": {
                "lat": 50.1378,
                "lng": 8.4497
            },
            "Riesa": {
                "lat": 51.3081,
                "lng": 13.2939
            },
            "Mei\u00dfen": {
                "lat": 51.1667,
                "lng": 13.4833
            },
            "R\u00f6srath": {
                "lat": 50.9,
                "lng": 7.1833
            },
            "Emmendingen": {
                "lat": 48.1214,
                "lng": 7.8492
            },
            "Schwelm": {
                "lat": 51.2667,
                "lng": 7.2667
            },
            "H\u00f6xter": {
                "lat": 51.7667,
                "lng": 9.3667
            },
            "Geislingen an der Steige": {
                "lat": 48.6244,
                "lng": 9.8306
            },
            "Mechernich": {
                "lat": 50.6,
                "lng": 6.65
            },
            "Reinbek": {
                "lat": 53.5089,
                "lng": 10.2483
            },
            "Hattersheim": {
                "lat": 50.0722,
                "lng": 8.4864
            },
            "Verden": {
                "lat": 52.9211,
                "lng": 9.2306
            },
            "Ludwigsfelde": {
                "lat": 52.2997,
                "lng": 13.2667
            },
            "Baunatal": {
                "lat": 51.2589,
                "lng": 9.4183
            },
            "Henstedt-Ulzburg": {
                "lat": 53.7833,
                "lng": 10.0
            },
            "Geilenkirchen": {
                "lat": 50.9653,
                "lng": 6.1194
            },
            "Kevelaer": {
                "lat": 51.5833,
                "lng": 6.25
            },
            "K\u00f6nigsbrunn": {
                "lat": 48.2689,
                "lng": 10.8908
            },
            "Grimma": {
                "lat": 51.2386,
                "lng": 12.7253
            },
            "Arnstadt": {
                "lat": 50.8342,
                "lng": 10.9464
            },
            "Wegberg": {
                "lat": 51.1417,
                "lng": 6.2792
            },
            "Leichlingen": {
                "lat": 51.1167,
                "lng": 7.0167
            },
            "Zeitz": {
                "lat": 51.0478,
                "lng": 12.1383
            },
            "Griesheim": {
                "lat": 49.8639,
                "lng": 8.5639
            },
            "Lohne": {
                "lat": 52.6667,
                "lng": 8.2386
            },
            "Sundern": {
                "lat": 51.3167,
                "lng": 8.0
            },
            "Teltow": {
                "lat": 52.4022,
                "lng": 13.2706
            },
            "Baesweiler": {
                "lat": 50.9,
                "lng": 6.1833
            },
            "Wetter (Ruhr)": {
                "lat": 51.3881,
                "lng": 7.395
            },
            "Schlo\u00df Holte-Stukenbrock": {
                "lat": 51.8833,
                "lng": 8.6167
            },
            "Wangen im Allg\u00e4u": {
                "lat": 47.6858,
                "lng": 9.8342
            },
            "Overath": {
                "lat": 50.95,
                "lng": 7.3
            },
            "Butzbach": {
                "lat": 50.4367,
                "lng": 8.6622
            },
            "Strausberg": {
                "lat": 52.5833,
                "lng": 13.8833
            },
            "Ehingen an der Donau": {
                "lat": 48.2833,
                "lng": 9.7236
            },
            "Hamminkeln": {
                "lat": 51.7319,
                "lng": 6.5908
            },
            "Leimen": {
                "lat": 49.3481,
                "lng": 8.6911
            },
            "Hohen Neuendorf": {
                "lat": 52.6667,
                "lng": 13.2833
            },
            "Rheinbach": {
                "lat": 50.6333,
                "lng": 6.95
            },
            "Wiesloch": {
                "lat": 49.2942,
                "lng": 8.6983
            },
            "Heppenheim": {
                "lat": 49.6431,
                "lng": 8.6389
            },
            "Pfaffenhofen": {
                "lat": 48.5333,
                "lng": 11.5167
            },
            "Hennigsdorf": {
                "lat": 52.6378,
                "lng": 13.2036
            },
            "Heiligenhaus": {
                "lat": 51.3167,
                "lng": 6.9667
            },
            "Bad Neuenahr-Ahrweiler": {
                "lat": 50.5447,
                "lng": 7.1133
            },
            "Aschersleben": {
                "lat": 51.75,
                "lng": 11.4667
            },
            "Neckarsulm": {
                "lat": 49.1917,
                "lng": 9.2246
            },
            "Achern": {
                "lat": 48.6333,
                "lng": 8.0667
            },
            "Lauf": {
                "lat": 49.5103,
                "lng": 11.2772
            },
            "Gro\u00df-Gerau": {
                "lat": 49.9192,
                "lng": 8.485
            },
            "M\u00fchlacker": {
                "lat": 48.95,
                "lng": 8.8394
            },
            "Weiterstadt": {
                "lat": 49.9,
                "lng": 8.6
            },
            "Bingen am Rhein": {
                "lat": 49.9667,
                "lng": 7.9
            },
            "Nordenham": {
                "lat": 53.5,
                "lng": 8.4667
            },
            "Zirndorf": {
                "lat": 49.45,
                "lng": 10.95
            },
            "Selm": {
                "lat": 51.6833,
                "lng": 7.4833
            },
            "Bad Honnef am Rhein": {
                "lat": 50.645,
                "lng": 7.2269
            },
            "L\u00fcbbecke": {
                "lat": 52.3081,
                "lng": 8.6231
            },
            "Harsewinkel": {
                "lat": 51.9667,
                "lng": 8.2331
            },
            "Unterhaching": {
                "lat": 48.0658,
                "lng": 11.61
            },
            "Lindau": {
                "lat": 47.5458,
                "lng": 9.6833
            },
            "Schleswig": {
                "lat": 54.5186,
                "lng": 9.5653
            },
            "Kulmbach": {
                "lat": 50.1,
                "lng": 11.4333
            },
            "Helmstedt": {
                "lat": 52.2281,
                "lng": 11.0106
            },
            "Horb am Neckar": {
                "lat": 48.4453,
                "lng": 8.6911
            },
            "Rinteln": {
                "lat": 52.1906,
                "lng": 9.0814
            },
            "Ellwangen": {
                "lat": 48.9611,
                "lng": 10.1306
            },
            "Friedrichsdorf": {
                "lat": 50.2556,
                "lng": 8.6397
            },
            "Geretsried": {
                "lat": 47.8667,
                "lng": 11.4667
            },
            "Sankt Wendel": {
                "lat": 49.4667,
                "lng": 7.1667
            },
            "Idstein": {
                "lat": 50.2206,
                "lng": 8.2742
            },
            "Vaterstetten": {
                "lat": 48.105,
                "lng": 11.7706
            },
            "Verl": {
                "lat": 51.8831,
                "lng": 8.5167
            },
            "Rottweil": {
                "lat": 48.1681,
                "lng": 8.6247
            },
            "Brilon": {
                "lat": 51.3956,
                "lng": 8.5678
            },
            "Sangerhausen": {
                "lat": 51.4667,
                "lng": 11.3
            },
            "\u00d6hringen": {
                "lat": 49.2,
                "lng": 9.5
            },
            "Roth": {
                "lat": 49.2461,
                "lng": 11.0911
            },
            "Lennestadt": {
                "lat": 51.1236,
                "lng": 8.0681
            },
            "Wiehl": {
                "lat": 50.95,
                "lng": 7.5333
            },
            "Salzkotten": {
                "lat": 51.6708,
                "lng": 8.6047
            },
            "Obertshausen": {
                "lat": 50.0667,
                "lng": 8.8333
            },
            "L\u00fcdinghausen": {
                "lat": 51.7667,
                "lng": 7.4333
            },
            "Weingarten": {
                "lat": 49.0514,
                "lng": 8.5306
            },
            "Delitzsch": {
                "lat": 51.5264,
                "lng": 12.3425
            },
            "Pfungstadt": {
                "lat": 49.8056,
                "lng": 8.6044
            },
            "Petershagen": {
                "lat": 52.3833,
                "lng": 8.9667
            },
            "Norden": {
                "lat": 53.5967,
                "lng": 7.2056
            },
            "Espelkamp": {
                "lat": 52.3772,
                "lng": 8.6328
            },
            "Ditzingen": {
                "lat": 48.8264,
                "lng": 9.0667
            },
            "Olpe": {
                "lat": 51.0167,
                "lng": 7.8333
            },
            "Plettenberg": {
                "lat": 51.2167,
                "lng": 7.8833
            },
            "Bad Oldesloe": {
                "lat": 53.8117,
                "lng": 10.3742
            },
            "Schmallenberg": {
                "lat": 51.149,
                "lng": 8.2845
            },
            "Meckenheim": {
                "lat": 50.6333,
                "lng": 7.0167
            },
            "Sprockh\u00f6vel": {
                "lat": 51.3667,
                "lng": 7.25
            },
            "Syke": {
                "lat": 52.9131,
                "lng": 8.8269
            },
            "Zittau": {
                "lat": 50.8961,
                "lng": 14.8072
            },
            "Rudolstadt": {
                "lat": 50.7169,
                "lng": 11.3275
            },
            "Meiningen": {
                "lat": 50.55,
                "lng": 10.4167
            },
            "Warstein": {
                "lat": 51.45,
                "lng": 8.35
            },
            "Waldshut-Tiengen": {
                "lat": 47.6231,
                "lng": 8.2144
            },
            "Rathenow": {
                "lat": 52.6,
                "lng": 12.3333
            },
            "Ronnenberg": {
                "lat": 52.3194,
                "lng": 9.6556
            },
            "Bad Mergentheim": {
                "lat": 49.5,
                "lng": 9.7667
            },
            "Isernhagen-S\u00fcd": {
                "lat": 52.4342,
                "lng": 9.8572
            },
            "Markkleeberg": {
                "lat": 51.2778,
                "lng": 12.3833
            },
            "Haren": {
                "lat": 52.7667,
                "lng": 7.2167
            },
            "Waldkraiburg": {
                "lat": 48.2167,
                "lng": 12.4
            },
            "Attendorn": {
                "lat": 51.1167,
                "lng": 7.9
            },
            "Herzogenaurach": {
                "lat": 49.57,
                "lng": 10.8819
            },
            "Varel": {
                "lat": 53.3969,
                "lng": 8.1361
            },
            "Bedburg": {
                "lat": 51.7667,
                "lng": 6.1833
            },
            "Freudenstadt": {
                "lat": 48.4633,
                "lng": 8.4111
            },
            "Sta\u00dffurt": {
                "lat": 51.8667,
                "lng": 11.5667
            },
            "Calw": {
                "lat": 48.7167,
                "lng": 8.7333
            },
            "\u00dcbach-Palenberg": {
                "lat": 50.9197,
                "lng": 6.1194
            },
            "Sehnde": {
                "lat": 52.3161,
                "lng": 9.9642
            },
            "Eisenh\u00fcttenstadt": {
                "lat": 52.145,
                "lng": 14.6728
            },
            "Wandlitz": {
                "lat": 52.75,
                "lng": 13.4497
            },
            "J\u00fcchen": {
                "lat": 51.1011,
                "lng": 6.5017
            },
            "Alfter": {
                "lat": 50.7356,
                "lng": 7.0092
            },
            "Westerstede": {
                "lat": 53.25,
                "lng": 7.9167
            },
            "Limbach-Oberfrohna": {
                "lat": 50.8667,
                "lng": 12.75
            },
            "D\u00f6beln": {
                "lat": 51.1194,
                "lng": 13.1128
            },
            "Starnberg": {
                "lat": 47.9972,
                "lng": 11.3406
            },
            "Husum": {
                "lat": 54.4667,
                "lng": 9.05
            },
            "Korbach": {
                "lat": 51.2833,
                "lng": 8.8667
            },
            "Gelnhausen": {
                "lat": 50.2,
                "lng": 9.1667
            },
            "Salzwedel": {
                "lat": 52.85,
                "lng": 11.15
            },
            "Kaltenkirchen": {
                "lat": 53.8397,
                "lng": 9.9603
            },
            "Hannoversch M\u00fcnden": {
                "lat": 51.4167,
                "lng": 9.65
            },
            "Sonneberg": {
                "lat": 50.35,
                "lng": 11.1667
            },
            "Mosbach": {
                "lat": 49.3522,
                "lng": 9.1467
            },
            "Dillenburg": {
                "lat": 50.7333,
                "lng": 8.2833
            },
            "Senftenberg": {
                "lat": 51.5167,
                "lng": 14.0167
            },
            "Netphen": {
                "lat": 50.9147,
                "lng": 8.1
            },
            "Leutkirch im Allg\u00e4u": {
                "lat": 47.8256,
                "lng": 10.0222
            },
            "Warburg": {
                "lat": 51.5,
                "lng": 9.1697
            },
            "Quedlinburg": {
                "lat": 51.7917,
                "lng": 11.1472
            },
            "Gersthofen": {
                "lat": 48.4167,
                "lng": 10.8667
            },
            "Wertheim": {
                "lat": 49.7589,
                "lng": 9.5175
            },
            "Rastede": {
                "lat": 53.25,
                "lng": 8.2
            },
            "Karben": {
                "lat": 50.2322,
                "lng": 8.7681
            },
            "Bad Soden am Taunus": {
                "lat": 50.1333,
                "lng": 8.5
            },
            "Vreden": {
                "lat": 52.0333,
                "lng": 6.8333
            },
            "\u00dcberlingen": {
                "lat": 47.7667,
                "lng": 9.165
            },
            "Apolda": {
                "lat": 51.0167,
                "lng": 11.5167
            },
            "Weilheim": {
                "lat": 47.8333,
                "lng": 11.15
            },
            "Bad Kissingen": {
                "lat": 50.2,
                "lng": 10.0667
            },
            "Bad Salzungen": {
                "lat": 50.8117,
                "lng": 10.2333
            },
            "Wallenhorst": {
                "lat": 52.35,
                "lng": 8.0167
            },
            "Nagold": {
                "lat": 48.5519,
                "lng": 8.7256
            },
            "Lengerich": {
                "lat": 52.175,
                "lng": 7.8667
            },
            "Friesoythe": {
                "lat": 53.0206,
                "lng": 7.8586
            },
            "Kitzingen": {
                "lat": 49.7333,
                "lng": 10.1667
            },
            "Edewecht": {
                "lat": 53.1258,
                "lng": 7.9825
            },
            "Senden": {
                "lat": 51.8572,
                "lng": 7.4828
            },
            "Neus\u00e4\u00df": {
                "lat": 48.4,
                "lng": 10.8333
            },
            "Laupheim": {
                "lat": 48.2289,
                "lng": 9.8797
            },
            "Herdecke": {
                "lat": 51.4,
                "lng": 7.4333
            },
            "Burg": {
                "lat": 52.2725,
                "lng": 11.855
            },
            "Stadthagen": {
                "lat": 52.3247,
                "lng": 9.2069
            },
            "B\u00fcdingen": {
                "lat": 50.2908,
                "lng": 9.1125
            },
            "Haar": {
                "lat": 48.1,
                "lng": 11.7333
            },
            "Metzingen": {
                "lat": 48.5367,
                "lng": 9.2858
            },
            "Donaueschingen": {
                "lat": 47.9531,
                "lng": 8.5033
            },
            "Ottobrunn": {
                "lat": 48.0667,
                "lng": 11.6667
            },
            "Bad Rappenau": {
                "lat": 49.2333,
                "lng": 9.1
            },
            "Versmold": {
                "lat": 52.0436,
                "lng": 8.15
            },
            "Eppingen": {
                "lat": 49.1333,
                "lng": 8.9167
            },
            "Radevormwald": {
                "lat": 51.2,
                "lng": 7.35
            },
            "Ilsede": {
                "lat": 52.2667,
                "lng": 10.1833
            },
            "Waldkirch": {
                "lat": 48.1,
                "lng": 7.9667
            },
            "Heide": {
                "lat": 54.1961,
                "lng": 9.0933
            },
            "Eschborn": {
                "lat": 50.1436,
                "lng": 8.57
            },
            "Karlsfeld": {
                "lat": 48.2167,
                "lng": 11.4667
            },
            "Schwetzingen": {
                "lat": 49.3833,
                "lng": 8.5667
            },
            "Gardelegen": {
                "lat": 52.5264,
                "lng": 11.3925
            },
            "Elsdorf": {
                "lat": 50.9333,
                "lng": 6.5667
            },
            "Glauchau": {
                "lat": 50.8233,
                "lng": 12.5444
            },
            "Aichach": {
                "lat": 48.45,
                "lng": 11.1333
            },
            "Sonthofen": {
                "lat": 47.5158,
                "lng": 10.2811
            },
            "Fl\u00f6rsheim": {
                "lat": 50.0167,
                "lng": 8.4333
            },
            "Soltau": {
                "lat": 52.9833,
                "lng": 9.8333
            },
            "Eislingen": {
                "lat": 48.6933,
                "lng": 9.7067
            },
            "Hockenheim": {
                "lat": 49.3181,
                "lng": 8.5472
            },
            "Bad Harzburg": {
                "lat": 51.8811,
                "lng": 10.5622
            },
            "M\u00fchldorf": {
                "lat": 48.2456,
                "lng": 12.5228
            },
            "Geseke": {
                "lat": 51.65,
                "lng": 8.5167
            },
            "Bad Krozingen": {
                "lat": 47.9167,
                "lng": 7.7
            },
            "Lindlar": {
                "lat": 51.0167,
                "lng": 7.3833
            },
            "Spremberg": {
                "lat": 51.5717,
                "lng": 14.3794
            },
            "Xanten": {
                "lat": 51.6622,
                "lng": 6.4539
            },
            "Seligenstadt": {
                "lat": 50.0333,
                "lng": 8.9667
            },
            "Stadtallendorf": {
                "lat": 50.8333,
                "lng": 9.0167
            },
            "Zerbst": {
                "lat": 51.9681,
                "lng": 12.0844
            },
            "Eckernf\u00f6rde": {
                "lat": 54.4742,
                "lng": 9.8378
            },
            "G\u00fcnzburg": {
                "lat": 48.4527,
                "lng": 10.2713
            },
            "Rees": {
                "lat": 51.7667,
                "lng": 6.4
            },
            "Osterode": {
                "lat": 51.7286,
                "lng": 10.2522
            },
            "Wagh\u00e4usel": {
                "lat": 49.25,
                "lng": 8.5167
            },
            "Zossen": {
                "lat": 52.2167,
                "lng": 13.4497
            },
            "Puchheim": {
                "lat": 48.15,
                "lng": 11.35
            },
            "Sondershausen": {
                "lat": 51.3667,
                "lng": 10.8667
            },
            "Gauting": {
                "lat": 48.0678,
                "lng": 11.3739
            },
            "Traunstein": {
                "lat": 47.8667,
                "lng": 12.6333
            },
            "Wildeshausen": {
                "lat": 52.9,
                "lng": 8.4333
            },
            "Schramberg": {
                "lat": 48.2269,
                "lng": 8.3842
            },
            "Wipperf\u00fcrth": {
                "lat": 51.1167,
                "lng": 7.4
            },
            "Traunreut": {
                "lat": 47.9667,
                "lng": 12.5833
            },
            "W\u00fclfrath": {
                "lat": 51.2833,
                "lng": 7.0333
            },
            "Germersheim": {
                "lat": 49.2167,
                "lng": 8.3667
            },
            "Gro\u00df-Umstadt": {
                "lat": 49.8667,
                "lng": 8.9333
            },
            "Z\u00fclpich": {
                "lat": 50.7,
                "lng": 6.65
            },
            "N\u00f6rdlingen": {
                "lat": 48.8511,
                "lng": 10.4883
            },
            "M\u00f6ssingen": {
                "lat": 48.4064,
                "lng": 9.0575
            },
            "Herborn": {
                "lat": 50.6825,
                "lng": 8.3061
            },
            "Luckenwalde": {
                "lat": 52.0833,
                "lng": 13.1667
            },
            "Bruchk\u00f6bel": {
                "lat": 50.1833,
                "lng": 8.9167
            },
            "Meinerzhagen": {
                "lat": 51.1167,
                "lng": 7.6333
            },
            "Stadtlohn": {
                "lat": 51.9925,
                "lng": 6.915
            },
            "Schortens": {
                "lat": 53.5333,
                "lng": 7.95
            },
            "Wittmund": {
                "lat": 53.575,
                "lng": 7.7808
            },
            "H\u00f6rstel": {
                "lat": 52.2972,
                "lng": 7.5861
            },
            "Enger": {
                "lat": 52.1333,
                "lng": 8.5667
            },
            "Werdau": {
                "lat": 50.7333,
                "lng": 12.3833
            },
            "Nidderau": {
                "lat": 50.25,
                "lng": 8.9
            },
            "Steinhagen": {
                "lat": 52.005,
                "lng": 8.4147
            },
            "Schwanewede": {
                "lat": 53.2333,
                "lng": 8.6
            },
            "Bad Waldsee": {
                "lat": 47.9211,
                "lng": 9.7519
            },
            "Bockum": {
                "lat": 51.3333,
                "lng": 6.5667
            },
            "Schifferstadt": {
                "lat": 49.3833,
                "lng": 8.3667
            },
            "Dingolfing": {
                "lat": 48.6333,
                "lng": 12.5
            },
            "Neufahrn bei Freising": {
                "lat": 48.3167,
                "lng": 11.6667
            },
            "Wachtberg": {
                "lat": 50.6167,
                "lng": 7.1333
            },
            "Fr\u00f6ndenberg": {
                "lat": 51.4719,
                "lng": 7.7658
            },
            "Coswig": {
                "lat": 51.8833,
                "lng": 12.4333
            },
            "Uetze": {
                "lat": 52.4661,
                "lng": 10.2039
            },
            "D\u00fclken": {
                "lat": 51.2522,
                "lng": 6.3353
            },
            "Greiz": {
                "lat": 50.6547,
                "lng": 12.1997
            },
            "Ha\u00dfloch": {
                "lat": 49.3631,
                "lng": 8.2558
            },
            "Reichenbach/Vogtland": {
                "lat": 50.6167,
                "lng": 12.3
            },
            "Neustrelitz": {
                "lat": 53.3647,
                "lng": 13.0636
            },
            "Duderstadt": {
                "lat": 51.5125,
                "lng": 10.2597
            },
            "Bad Schwartau": {
                "lat": 53.9194,
                "lng": 10.6975
            },
            "Blieskastel": {
                "lat": 49.2333,
                "lng": 7.25
            },
            "Ochtrup": {
                "lat": 52.2056,
                "lng": 7.1903
            },
            "Telgte": {
                "lat": 51.9819,
                "lng": 7.7856
            },
            "Lichtenfels": {
                "lat": 50.1333,
                "lng": 11.0333
            },
            "Schopfheim": {
                "lat": 47.65,
                "lng": 7.8167
            },
            "Giengen an der Brenz": {
                "lat": 48.6217,
                "lng": 10.245
            },
            "K\u00fcrten": {
                "lat": 51.0497,
                "lng": 7.2667
            },
            "Holzminden": {
                "lat": 51.8297,
                "lng": 9.4483
            },
            "Leinefelde": {
                "lat": 51.3833,
                "lng": 10.3333
            },
            "Oberkirch": {
                "lat": 48.5333,
                "lng": 8.0833
            },
            "Schmalkalden": {
                "lat": 50.7167,
                "lng": 10.45
            },
            "Tettnang": {
                "lat": 47.6708,
                "lng": 9.5875
            },
            "Donauw\u00f6rth": {
                "lat": 48.7,
                "lng": 10.8
            },
            "Dillingen": {
                "lat": 48.5667,
                "lng": 10.4667
            },
            "Korntal-M\u00fcnchingen": {
                "lat": 48.8306,
                "lng": 9.1214
            },
            "Waldbr\u00f6l": {
                "lat": 50.8789,
                "lng": 7.615
            },
            "Hiddenhausen": {
                "lat": 52.1667,
                "lng": 8.6167
            },
            "Nottuln": {
                "lat": 51.9167,
                "lng": 7.2833
            },
            "Gerlingen": {
                "lat": 48.8,
                "lng": 9.0653
            },
            "Moosburg": {
                "lat": 48.4667,
                "lng": 11.9333
            },
            "Torgau": {
                "lat": 51.5603,
                "lng": 13.0056
            },
            "Wilnsdorf": {
                "lat": 50.8167,
                "lng": 8.1
            },
            "Ennigerloh": {
                "lat": 51.8367,
                "lng": 8.0256
            },
            "Wittlich": {
                "lat": 49.9869,
                "lng": 6.8897
            },
            "Marsberg": {
                "lat": 51.45,
                "lng": 8.8333
            },
            "Sarstedt": {
                "lat": 52.2394,
                "lng": 9.8606
            },
            "Gr\u00f6benzell": {
                "lat": 48.2,
                "lng": 11.3667
            },
            "Mayen": {
                "lat": 50.3333,
                "lng": 7.2167
            },
            "Schenefeld": {
                "lat": 53.6028,
                "lng": 9.8233
            },
            "Oschersleben": {
                "lat": 52.0167,
                "lng": 11.25
            },
            "B\u00fcckeburg": {
                "lat": 52.2608,
                "lng": 9.0492
            },
            "Haiger": {
                "lat": 50.7422,
                "lng": 8.2039
            },
            "Bad Aibling": {
                "lat": 47.8639,
                "lng": 12.01
            },
            "Bad Pyrmont": {
                "lat": 51.9867,
                "lng": 9.2636
            },
            "Rhede": {
                "lat": 51.8333,
                "lng": 6.7006
            },
            "Engelskirchen": {
                "lat": 50.9833,
                "lng": 7.4167
            },
            "Wenden": {
                "lat": 50.9667,
                "lng": 7.8667
            },
            "Eschwege": {
                "lat": 51.1881,
                "lng": 10.0528
            },
            "Borna": {
                "lat": 51.1167,
                "lng": 12.5
            },
            "M\u00f6lln": {
                "lat": 53.6269,
                "lng": 10.6847
            },
            "M\u00fcllheim": {
                "lat": 47.8,
                "lng": 7.6333
            },
            "Hechingen": {
                "lat": 48.3517,
                "lng": 8.9633
            },
            "Weil der Stadt": {
                "lat": 48.7508,
                "lng": 8.8706
            },
            "Sulzbach-Rosenberg": {
                "lat": 49.5,
                "lng": 11.75
            },
            "Bad Driburg": {
                "lat": 51.7333,
                "lng": 9.0167
            },
            "Annaberg-Buchholz": {
                "lat": 50.58,
                "lng": 13.0022
            },
            "Burghausen": {
                "lat": 48.1667,
                "lng": 12.8333
            },
            "Nauen": {
                "lat": 52.6,
                "lng": 12.8831
            },
            "Wassenberg": {
                "lat": 51.1,
                "lng": 6.15
            },
            "Wolfratshausen": {
                "lat": 47.9133,
                "lng": 11.4278
            },
            "Heusenstamm": {
                "lat": 50.05,
                "lng": 8.8
            },
            "Haldensleben": {
                "lat": 52.2833,
                "lng": 11.4167
            },
            "Alzey": {
                "lat": 49.7458,
                "lng": 8.1153
            },
            "Gilching": {
                "lat": 48.1167,
                "lng": 11.3
            },
            "Schkeuditz": {
                "lat": 51.4,
                "lng": 12.2167
            },
            "Bad T\u00f6lz": {
                "lat": 47.7603,
                "lng": 11.5567
            },
            "Sch\u00f6nefeld": {
                "lat": 52.3883,
                "lng": 13.5047
            },
            "Blankenburg": {
                "lat": 51.7953,
                "lng": 10.9622
            },
            "Seesen": {
                "lat": 51.8931,
                "lng": 10.1783
            },
            "S\u00f6mmerda": {
                "lat": 51.1617,
                "lng": 11.1169
            },
            "Schwalmtal": {
                "lat": 51.2225,
                "lng": 6.2625
            },
            "Schneverdingen": {
                "lat": 53.1167,
                "lng": 9.8
            },
            "Eitorf": {
                "lat": 50.7697,
                "lng": 7.4517
            },
            "Lebach": {
                "lat": 49.41,
                "lng": 6.91
            },
            "Pfullingen": {
                "lat": 48.4656,
                "lng": 9.2261
            },
            "Radeberg": {
                "lat": 51.1167,
                "lng": 13.9167
            },
            "Burscheid": {
                "lat": 51.1,
                "lng": 7.1167
            },
            "Kolbermoor": {
                "lat": 47.85,
                "lng": 12.0667
            },
            "Neviges": {
                "lat": 51.3128,
                "lng": 7.0869
            },
            "Bad Berleburg": {
                "lat": 51.0497,
                "lng": 8.4
            },
            "Prenzlau": {
                "lat": 53.3167,
                "lng": 13.8667
            },
            "Bad Reichenhall": {
                "lat": 47.7247,
                "lng": 12.8769
            },
            "Hemmingen": {
                "lat": 52.3236,
                "lng": 9.7256
            },
            "Wei\u00dfenburg": {
                "lat": 49.0306,
                "lng": 10.9719
            },
            "Alzenau in Unterfranken": {
                "lat": 50.0667,
                "lng": 9.0667
            },
            "Bad D\u00fcrkheim": {
                "lat": 49.4594,
                "lng": 8.1681
            },
            "Marktoberdorf": {
                "lat": 47.7667,
                "lng": 10.6167
            },
            "Bremerv\u00f6rde": {
                "lat": 53.4833,
                "lng": 9.1333
            },
            "Alfeld": {
                "lat": 51.9886,
                "lng": 9.8269
            },
            "Bergneustadt": {
                "lat": 51.0333,
                "lng": 7.65
            },
            "Uetersen": {
                "lat": 53.6872,
                "lng": 9.6692
            },
            "Renningen": {
                "lat": 48.7661,
                "lng": 8.9347
            },
            "Rhynern": {
                "lat": 51.6299,
                "lng": 7.8581
            },
            "Crimmitschau": {
                "lat": 50.8181,
                "lng": 12.3875
            },
            "Glinde": {
                "lat": 53.5406,
                "lng": 10.2111
            },
            "Hochheim am Main": {
                "lat": 50.0167,
                "lng": 8.35
            },
            "Rhaude": {
                "lat": 53.1667,
                "lng": 7.55
            },
            "Vlotho": {
                "lat": 52.1667,
                "lng": 8.8497
            },
            "Schwalmstadt": {
                "lat": 50.9333,
                "lng": 9.2167
            },
            "Vellmar": {
                "lat": 51.35,
                "lng": 9.4667
            },
            "Hoppegarten": {
                "lat": 52.5167,
                "lng": 13.6667
            },
            "Hessisch Oldendorf": {
                "lat": 52.1667,
                "lng": 9.25
            },
            "B\u00f6nen": {
                "lat": 51.5986,
                "lng": 7.7592
            },
            "Lahnstein": {
                "lat": 50.3011,
                "lng": 7.6056
            },
            "Vechelde": {
                "lat": 52.2608,
                "lng": 10.372
            },
            "Kronberg": {
                "lat": 50.1833,
                "lng": 8.5
            },
            "Konz": {
                "lat": 49.7,
                "lng": 6.5833
            },
            "Sigmaringen": {
                "lat": 48.0867,
                "lng": 9.2164
            },
            "Illertissen": {
                "lat": 48.2167,
                "lng": 10.0833
            },
            "W\u00f6rth am Rhein": {
                "lat": 49.0517,
                "lng": 8.2603
            },
            "P\u00fcttlingen": {
                "lat": 49.2833,
                "lng": 6.8833
            },
            "Parchim": {
                "lat": 53.4167,
                "lng": 11.8333
            },
            "Bad Segeberg": {
                "lat": 53.9167,
                "lng": 10.3167
            },
            "Taufkirchen": {
                "lat": 48.3439,
                "lng": 12.1303
            },
            "Heusweiler": {
                "lat": 49.35,
                "lng": 6.9167
            },
            "Gro\u00dfenhain": {
                "lat": 51.2833,
                "lng": 13.55
            },
            "Bobingen": {
                "lat": 48.2667,
                "lng": 10.8167
            },
            "Ismaning": {
                "lat": 48.2264,
                "lng": 11.6725
            },
            "Frankenberg": {
                "lat": 50.9108,
                "lng": 13.0378
            },
            "Buchen in Odenwald": {
                "lat": 49.5217,
                "lng": 9.3233
            },
            "Halstenbek": {
                "lat": 53.6333,
                "lng": 9.8667
            },
            "Forst (Lausitz)": {
                "lat": 51.7333,
                "lng": 14.6333
            },
            "Werdohl": {
                "lat": 51.2667,
                "lng": 7.7667
            },
            "Freilassing": {
                "lat": 47.8333,
                "lng": 12.9667
            },
            "Oberasbach": {
                "lat": 49.4219,
                "lng": 10.9583
            },
            "Freudenberg": {
                "lat": 50.8997,
                "lng": 7.8667
            },
            "Weilerswist": {
                "lat": 50.7667,
                "lng": 6.8331
            },
            "Auerbach": {
                "lat": 49.6833,
                "lng": 11.6167
            },
            "Schrobenhausen": {
                "lat": 48.5333,
                "lng": 11.2667
            },
            "Bad M\u00fcnder am Deister": {
                "lat": 52.1992,
                "lng": 9.4653
            },
            "Kreuzau": {
                "lat": 50.75,
                "lng": 6.4831
            },
            "Diepholz": {
                "lat": 52.6072,
                "lng": 8.3711
            },
            "Bad S\u00e4ckingen": {
                "lat": 47.55,
                "lng": 7.95
            },
            "Nidda": {
                "lat": 50.4128,
                "lng": 9.0092
            },
            "Bexbach": {
                "lat": 49.3494,
                "lng": 7.2594
            },
            "Damme": {
                "lat": 52.5208,
                "lng": 8.1986
            },
            "Bad Wildungen": {
                "lat": 51.1167,
                "lng": 9.1167
            },
            "Trossingen": {
                "lat": 48.0756,
                "lng": 8.6361
            },
            "Garching bei M\u00fcnchen": {
                "lat": 48.25,
                "lng": 11.65
            },
            "Stockach": {
                "lat": 47.8514,
                "lng": 9.0114
            },
            "N\u00fcmbrecht": {
                "lat": 50.9053,
                "lng": 7.5422
            },
            "Sinzig": {
                "lat": 50.5453,
                "lng": 7.2519
            },
            "Remagen": {
                "lat": 50.5786,
                "lng": 7.2306
            },
            "Cham": {
                "lat": 49.2167,
                "lng": 12.65
            },
            "Babenhausen": {
                "lat": 49.9667,
                "lng": 8.95
            },
            "Kelsterbach": {
                "lat": 50.0617,
                "lng": 8.5311
            },
            "Gescher": {
                "lat": 51.9569,
                "lng": 7.0056
            },
            "Bad Langensalza": {
                "lat": 51.1081,
                "lng": 10.6467
            },
            "Schwarzenbek": {
                "lat": 53.5042,
                "lng": 10.4792
            },
            "Schwalbach": {
                "lat": 49.2833,
                "lng": 6.8167
            },
            "Holzwickede": {
                "lat": 51.5,
                "lng": 7.6167
            },
            "Horn-Bad Meinberg": {
                "lat": 51.8833,
                "lng": 8.9667
            },
            "Brand": {
                "lat": 50.7489,
                "lng": 6.165
            },
            "Bad M\u00fcnstereifel": {
                "lat": 50.5531,
                "lng": 6.7661
            },
            "Gunzenhausen": {
                "lat": 49.1147,
                "lng": 10.7542
            },
            "Oerlinghausen": {
                "lat": 51.9667,
                "lng": 8.6667
            },
            "Heilbad Heiligenstadt": {
                "lat": 51.3789,
                "lng": 10.1386
            },
            "Bendorf": {
                "lat": 50.4297,
                "lng": 7.5703
            },
            "Marktredwitz": {
                "lat": 50.0,
                "lng": 12.0667
            },
            "Bad W\u00f6rishofen": {
                "lat": 48.0058,
                "lng": 10.5969
            },
            "Stockelsdorf": {
                "lat": 53.8833,
                "lng": 10.65
            },
            "Eutin": {
                "lat": 54.1378,
                "lng": 10.6181
            },
            "K\u00fcnzell": {
                "lat": 50.55,
                "lng": 9.7167
            },
            "Kelheim": {
                "lat": 48.9167,
                "lng": 11.8667
            },
            "Wadgassen": {
                "lat": 49.2667,
                "lng": 6.7667
            },
            "Kamenz": {
                "lat": 51.2667,
                "lng": 14.1
            },
            "Ginsheim-Gustavsburg": {
                "lat": 49.9833,
                "lng": 8.3333
            },
            "H\u00fcnfeld": {
                "lat": 50.6667,
                "lng": 9.7667
            },
            "Eggenstein-Leopoldshafen": {
                "lat": 49.0778,
                "lng": 8.3925
            },
            "Kronach": {
                "lat": 50.2411,
                "lng": 11.3281
            },
            "Bruckm\u00fchl": {
                "lat": 47.8833,
                "lng": 11.9167
            },
            "Heidenau": {
                "lat": 50.9833,
                "lng": 13.8667
            },
            "Thale": {
                "lat": 51.75,
                "lng": 11.05
            },
            "Bitburg": {
                "lat": 49.9667,
                "lng": 6.5333
            },
            "Finnentrop": {
                "lat": 51.1667,
                "lng": 7.9667
            },
            "Wittenberge": {
                "lat": 53.0,
                "lng": 11.75
            },
            "Bad Lippspringe": {
                "lat": 51.7833,
                "lng": 8.8167
            },
            "K\u00f6nigstein im Taunus": {
                "lat": 50.1833,
                "lng": 8.4667
            },
            "B\u00fcrstadt": {
                "lat": 49.6333,
                "lng": 8.45
            },
            "Penzberg": {
                "lat": 47.75,
                "lng": 11.3833
            },
            "Holzkirchen": {
                "lat": 47.8833,
                "lng": 11.7
            },
            "Bassum": {
                "lat": 52.8494,
                "lng": 8.7267
            },
            "Loxstedt": {
                "lat": 53.4667,
                "lng": 8.65
            },
            "Seeheim-Jugenheim": {
                "lat": 49.75,
                "lng": 8.65
            },
            "Leopoldsh\u00f6he": {
                "lat": 52.0167,
                "lng": 8.6914
            },
            "Wurzen": {
                "lat": 51.3667,
                "lng": 12.7167
            },
            "Marienberg": {
                "lat": 50.6333,
                "lng": 13.15
            },
            "Reinheim": {
                "lat": 49.8269,
                "lng": 8.8308
            },
            "Eppelborn": {
                "lat": 49.3833,
                "lng": 6.9667
            },
            "Regenstauf": {
                "lat": 49.1236,
                "lng": 12.1283
            },
            "Raunheim": {
                "lat": 50.0167,
                "lng": 8.45
            },
            "H\u00f6velhof": {
                "lat": 51.8167,
                "lng": 8.65
            },
            "Brackenheim": {
                "lat": 49.0833,
                "lng": 9.0667
            },
            "Kirchhain": {
                "lat": 50.8167,
                "lng": 8.9167
            },
            "Wardenburg": {
                "lat": 53.0617,
                "lng": 8.1967
            },
            "Altena": {
                "lat": 51.3,
                "lng": 7.6667
            },
            "Kierspe": {
                "lat": 51.1333,
                "lng": 7.5667
            },
            "Petersberg": {
                "lat": 51.6,
                "lng": 11.9667
            },
            "Poing": {
                "lat": 48.1667,
                "lng": 11.8167
            },
            "Pocking": {
                "lat": 48.4,
                "lng": 13.3167
            },
            "Brakel": {
                "lat": 51.7167,
                "lng": 9.1833
            },
            "Hude": {
                "lat": 53.1111,
                "lng": 8.4625
            },
            "Kirchlengern": {
                "lat": 52.2,
                "lng": 8.6331
            },
            "Straelen": {
                "lat": 51.45,
                "lng": 6.2667
            },
            "Guben": {
                "lat": 51.9533,
                "lng": 14.7167
            },
            "Sulzbach": {
                "lat": 49.2833,
                "lng": 7.0667
            },
            "Halver": {
                "lat": 51.1833,
                "lng": 7.4667
            },
            "Gro\u00dfostheim": {
                "lat": 49.9167,
                "lng": 9.0833
            },
            "Bad Essen": {
                "lat": 52.3214,
                "lng": 8.34
            },
            "Erwitte": {
                "lat": 51.6167,
                "lng": 8.35
            },
            "Elsen": {
                "lat": 51.7333,
                "lng": 8.6833
            },
            "Bargteheide": {
                "lat": 53.7167,
                "lng": 10.2667
            },
            "Schmelz": {
                "lat": 49.4167,
                "lng": 6.8333
            },
            "Wiefelstede": {
                "lat": 53.2581,
                "lng": 8.1172
            },
            "Oyten": {
                "lat": 53.0611,
                "lng": 9.0178
            },
            "Wendlingen am Neckar": {
                "lat": 48.6747,
                "lng": 9.3817
            },
            "Bad Bentheim": {
                "lat": 52.3031,
                "lng": 7.1597
            },
            "K\u00fcnzelsau": {
                "lat": 49.2833,
                "lng": 9.6833
            },
            "Freiberg am Neckar": {
                "lat": 48.9333,
                "lng": 9.2
            },
            "Stahnsdorf": {
                "lat": 52.3922,
                "lng": 13.2167
            },
            "Alsfeld": {
                "lat": 50.7511,
                "lng": 9.2711
            },
            "Schl\u00fcchtern": {
                "lat": 50.35,
                "lng": 9.5167
            },
            "Markranst\u00e4dt": {
                "lat": 51.3017,
                "lng": 12.2211
            },
            "Michelstadt": {
                "lat": 49.6786,
                "lng": 9.0039
            },
            "Wendelstein": {
                "lat": 49.3536,
                "lng": 11.1483
            },
            "Br\u00fcggen": {
                "lat": 51.2417,
                "lng": 6.1822
            },
            "Gro\u00dfenkneten": {
                "lat": 52.95,
                "lng": 8.2667
            },
            "Marbach am Neckar": {
                "lat": 48.9333,
                "lng": 9.25
            },
            "Eilenburg": {
                "lat": 51.4608,
                "lng": 12.6358
            },
            "Preetz": {
                "lat": 54.2367,
                "lng": 10.2822
            },
            "Zeulenroda": {
                "lat": 50.6486,
                "lng": 11.9806
            },
            "Aue": {
                "lat": 50.5881,
                "lng": 12.7006
            },
            "Illingen": {
                "lat": 49.3667,
                "lng": 7.0333
            },
            "Weener": {
                "lat": 53.1692,
                "lng": 7.3564
            },
            "F\u00fcssen": {
                "lat": 47.5667,
                "lng": 10.7
            },
            "Erlensee": {
                "lat": 50.1333,
                "lng": 8.9333
            },
            "Finsterwalde": {
                "lat": 51.6282,
                "lng": 13.7102
            },
            "S\u00fcchteln": {
                "lat": 51.2833,
                "lng": 6.3833
            },
            "Schwarzenberg": {
                "lat": 50.5453,
                "lng": 12.7792
            },
            "K\u00f6nigslutter am Elm": {
                "lat": 52.25,
                "lng": 10.8167
            },
            "Eilendorf": {
                "lat": 50.7794,
                "lng": 6.1625
            },
            "Walldorf": {
                "lat": 49.3,
                "lng": 8.65
            },
            "Wadern": {
                "lat": 49.5167,
                "lng": 6.8667
            },
            "Drensteinfurt": {
                "lat": 51.7944,
                "lng": 7.7392
            },
            "Simmerath": {
                "lat": 50.6069,
                "lng": 6.3
            },
            "Ascheberg": {
                "lat": 51.7889,
                "lng": 7.62
            },
            "Taucha": {
                "lat": 51.38,
                "lng": 12.4936
            },
            "Breisach am Rhein": {
                "lat": 48.0333,
                "lng": 7.5833
            },
            "Rahden": {
                "lat": 52.4167,
                "lng": 8.6167
            },
            "Garrel": {
                "lat": 52.9581,
                "lng": 8.0253
            },
            "Hille": {
                "lat": 52.3331,
                "lng": 8.75
            },
            "L\u00fcbbenau/Spreewald": {
                "lat": 51.8667,
                "lng": 13.9667
            },
            "Ribnitz-Damgarten": {
                "lat": 54.25,
                "lng": 12.4667
            },
            "Langenberg": {
                "lat": 51.3519,
                "lng": 7.1217
            },
            "Dieburg": {
                "lat": 49.9,
                "lng": 8.85
            },
            "Ebersbach an der Fils": {
                "lat": 48.7147,
                "lng": 9.5236
            },
            "Schiffweiler": {
                "lat": 49.3667,
                "lng": 7.1167
            },
            "Mindelheim": {
                "lat": 48.0333,
                "lng": 10.4667
            },
            "Langenau": {
                "lat": 48.4967,
                "lng": 10.12
            },
            "Boppard": {
                "lat": 50.2314,
                "lng": 7.5908
            },
            "Templin": {
                "lat": 53.1167,
                "lng": 13.5
            },
            "Epe": {
                "lat": 52.1833,
                "lng": 7.0333
            },
            "Bad Neustadt": {
                "lat": 50.3219,
                "lng": 10.2161
            },
            "Eppelheim": {
                "lat": 49.4,
                "lng": 8.6333
            },
            "Hofgeismar": {
                "lat": 51.4833,
                "lng": 9.4
            },
            "Mainburg": {
                "lat": 48.65,
                "lng": 11.7833
            },
            "Neustadt in Holstein": {
                "lat": 54.1072,
                "lng": 10.8158
            },
            "Sandhausen": {
                "lat": 49.3439,
                "lng": 8.6581
            },
            "Gehrden": {
                "lat": 52.3117,
                "lng": 9.6003
            },
            "Blomberg": {
                "lat": 51.9333,
                "lng": 9.0833
            },
            "Bad Bramstedt": {
                "lat": 53.9186,
                "lng": 9.8844
            },
            "Ratekau": {
                "lat": 53.95,
                "lng": 10.7333
            },
            "Odenthal": {
                "lat": 51.0333,
                "lng": 7.1167
            },
            "Burbach": {
                "lat": 50.7444,
                "lng": 8.0861
            },
            "Clausthal-Zellerfeld": {
                "lat": 51.805,
                "lng": 10.3356
            },
            "Ober-Ramstadt": {
                "lat": 49.8333,
                "lng": 8.75
            },
            "Mering": {
                "lat": 48.2625,
                "lng": 10.9844
            },
            "Dorfen": {
                "lat": 48.2667,
                "lng": 12.15
            },
            "Niederkr\u00fcchten": {
                "lat": 51.1989,
                "lng": 6.2194
            },
            "Wei\u00dfwasser/Oberlausitz": {
                "lat": 51.5,
                "lng": 14.6333
            },
            "Brake": {
                "lat": 53.3333,
                "lng": 8.4833
            },
            "Schriesheim": {
                "lat": 49.4736,
                "lng": 8.6592
            },
            "Neustadt bei Coburg": {
                "lat": 50.3289,
                "lng": 11.1211
            },
            "Bad Wurzach": {
                "lat": 47.9094,
                "lng": 9.8994
            },
            "Markgr\u00f6ningen": {
                "lat": 48.9047,
                "lng": 9.0808
            },
            "Langelsheim": {
                "lat": 51.9381,
                "lng": 10.335
            },
            "Grenzach-Wyhlen": {
                "lat": 47.545,
                "lng": 7.6758
            },
            "Ritterhude": {
                "lat": 53.1831,
                "lng": 8.7561
            },
            "Fritzlar": {
                "lat": 51.1333,
                "lng": 9.2833
            },
            "Witzenhausen": {
                "lat": 51.3422,
                "lng": 9.8578
            },
            "B\u00fcttelborn": {
                "lat": 49.9,
                "lng": 8.5167
            },
            "Bissendorf": {
                "lat": 52.2333,
                "lng": 8.1667
            },
            "Baiersbronn": {
                "lat": 48.5058,
                "lng": 8.3711
            },
            "Schiffdorf": {
                "lat": 53.5358,
                "lng": 8.6589
            },
            "Malsch": {
                "lat": 48.8808,
                "lng": 8.3342
            },
            "Beckingen": {
                "lat": 49.3928,
                "lng": 6.7008
            },
            "Usingen": {
                "lat": 50.3344,
                "lng": 8.5375
            },
            "Grefrath": {
                "lat": 51.3333,
                "lng": 6.3333
            },
            "Jever": {
                "lat": 53.5744,
                "lng": 7.9008
            },
            "Niedernhausen": {
                "lat": 50.1667,
                "lng": 8.3167
            },
            "Isny im Allg\u00e4u": {
                "lat": 47.6919,
                "lng": 10.0394
            },
            "H\u00fcckeswagen": {
                "lat": 51.145,
                "lng": 7.3417
            },
            "Schwabm\u00fcnchen": {
                "lat": 48.1789,
                "lng": 10.755
            },
            "Pattensen": {
                "lat": 52.2667,
                "lng": 9.7667
            },
            "Hilchenbach": {
                "lat": 50.9983,
                "lng": 8.1094
            },
            "Selb": {
                "lat": 50.1667,
                "lng": 12.1333
            },
            "Much": {
                "lat": 50.9167,
                "lng": 7.4
            },
            "Friedland": {
                "lat": 51.4217,
                "lng": 9.9144
            },
            "Rellingen": {
                "lat": 53.65,
                "lng": 9.8167
            },
            "Neustadt an der Donau": {
                "lat": 48.8,
                "lng": 11.7667
            },
            "Harsefeld": {
                "lat": 53.45,
                "lng": 9.5
            },
            "Gro\u00df-Zimmern": {
                "lat": 49.8833,
                "lng": 8.8333
            },
            "Langwedel": {
                "lat": 52.9667,
                "lng": 9.2167
            },
            "Plochingen": {
                "lat": 48.7117,
                "lng": 9.4164
            },
            "Langenselbold": {
                "lat": 50.1833,
                "lng": 9.0333
            },
            "Hatten": {
                "lat": 53.0083,
                "lng": 8.3167
            },
            "Montabaur": {
                "lat": 50.4375,
                "lng": 7.8258
            },
            "Lauda-K\u00f6nigshofen": {
                "lat": 49.5686,
                "lng": 9.7039
            },
            "Tostedt": {
                "lat": 53.2833,
                "lng": 9.7167
            },
            "Stein bei N\u00fcrnberg": {
                "lat": 49.4167,
                "lng": 11.0167
            },
            "Ratzeburg": {
                "lat": 53.7,
                "lng": 10.75
            },
            "Wilsdruff": {
                "lat": 51.0522,
                "lng": 13.5383
            },
            "Ottweiler": {
                "lat": 49.3667,
                "lng": 7.1667
            },
            "Abensberg": {
                "lat": 48.8,
                "lng": 11.85
            },
            "Neu-Anspach": {
                "lat": 50.2931,
                "lng": 8.5089
            },
            "Immenstadt im Allg\u00e4u": {
                "lat": 47.5667,
                "lng": 10.2167
            },
            "Olsberg": {
                "lat": 51.35,
                "lng": 8.4833
            },
            "Eberbach": {
                "lat": 49.4667,
                "lng": 8.9833
            },
            "Uhingen": {
                "lat": 48.7058,
                "lng": 9.5919
            },
            "Burglengenfeld": {
                "lat": 49.2,
                "lng": 12.0333
            },
            "Sassenberg": {
                "lat": 51.9897,
                "lng": 8.0408
            },
            "Neubiberg": {
                "lat": 48.0833,
                "lng": 11.6833
            },
            "Eggenfelden": {
                "lat": 48.4039,
                "lng": 12.7642
            },
            "Eching": {
                "lat": 48.3,
                "lng": 11.6167
            },
            "Spenge": {
                "lat": 52.1333,
                "lng": 8.4833
            },
            "Gernsbach": {
                "lat": 48.7633,
                "lng": 8.3342
            },
            "Lohfelden": {
                "lat": 51.2667,
                "lng": 9.5333
            },
            "Rheydt": {
                "lat": 51.1667,
                "lng": 6.45
            },
            "Riegelsberg": {
                "lat": 49.2833,
                "lng": 6.9167
            },
            "Markdorf": {
                "lat": 47.7208,
                "lng": 9.3917
            },
            "L\u00f6bau": {
                "lat": 51.0944,
                "lng": 14.6667
            },
            "Tornesch": {
                "lat": 53.7,
                "lng": 9.7167
            },
            "Rehlingen-Siersburg": {
                "lat": 49.3686,
                "lng": 6.6786
            },
            "Niederzier": {
                "lat": 50.8831,
                "lng": 6.4667
            },
            "Mittweida": {
                "lat": 50.9856,
                "lng": 12.9811
            },
            "Lotte": {
                "lat": 52.2764,
                "lng": 7.9167
            },
            "Lengede": {
                "lat": 52.2,
                "lng": 10.3
            },
            "Neutraubling": {
                "lat": 48.9936,
                "lng": 12.1953
            },
            "Bad Camberg": {
                "lat": 50.3,
                "lng": 8.2667
            },
            "Grafing bei M\u00fcnchen": {
                "lat": 48.05,
                "lng": 11.9667
            },
            "Langerwehe": {
                "lat": 50.8167,
                "lng": 6.3497
            },
            "Lich": {
                "lat": 50.5217,
                "lng": 8.8208
            },
            "Wennigsen": {
                "lat": 52.2742,
                "lng": 9.5708
            },
            "Zeven": {
                "lat": 53.3,
                "lng": 9.2833
            },
            "Mittegro\u00dfefehn": {
                "lat": 53.3833,
                "lng": 7.5833
            },
            "Maisach": {
                "lat": 48.2167,
                "lng": 11.2667
            },
            "Oschatz": {
                "lat": 51.3003,
                "lng": 13.1072
            },
            "Kalkar": {
                "lat": 51.7389,
                "lng": 6.2925
            },
            "Murrhardt": {
                "lat": 48.98,
                "lng": 9.5814
            },
            "Uslar": {
                "lat": 51.6597,
                "lng": 9.6358
            },
            "Edingen-Neckarhausen": {
                "lat": 49.2833,
                "lng": 8.6167
            },
            "Feucht": {
                "lat": 49.3757,
                "lng": 11.213
            },
            "Dippoldiswalde": {
                "lat": 50.8933,
                "lng": 13.6667
            },
            "Lauchhammer": {
                "lat": 51.5,
                "lng": 13.8
            },
            "Bovenden": {
                "lat": 51.5897,
                "lng": 9.9222
            },
            "Landau an der Isar": {
                "lat": 48.6667,
                "lng": 12.6667
            },
            "Wittstock": {
                "lat": 53.1636,
                "lng": 12.4856
            },
            "Belm": {
                "lat": 52.3,
                "lng": 8.1333
            },
            "Hohenstein-Ernstthal": {
                "lat": 50.8,
                "lng": 12.7167
            },
            "Aldenhoven": {
                "lat": 50.8958,
                "lng": 6.2831
            },
            "Neuenkirchen": {
                "lat": 52.5167,
                "lng": 8.0667
            },
            "Rotenburg an der Fulda": {
                "lat": 50.9961,
                "lng": 9.7278
            },
            "Gr\u00fcnstadt": {
                "lat": 49.5692,
                "lng": 8.1681
            },
            "Ahrensfelde": {
                "lat": 52.5758,
                "lng": 13.5764
            },
            "Leuna": {
                "lat": 51.3167,
                "lng": 12.0167
            },
            "Schneeberg": {
                "lat": 50.5942,
                "lng": 12.6456
            },
            "Lorsch": {
                "lat": 49.6539,
                "lng": 8.5675
            },
            "Bebra": {
                "lat": 50.9711,
                "lng": 9.7903
            },
            "Erbach": {
                "lat": 48.3281,
                "lng": 9.8878
            },
            "L\u00fcbben (Spreewald)": {
                "lat": 51.95,
                "lng": 13.9
            },
            "V\u00f6hringen": {
                "lat": 48.2833,
                "lng": 10.0833
            },
            "H\u00f6chstadt an der Aisch": {
                "lat": 49.7,
                "lng": 10.8
            },
            "Gr\u00fcnberg": {
                "lat": 50.6,
                "lng": 8.95
            },
            "A\u00dflar": {
                "lat": 50.5833,
                "lng": 8.4667
            },
            "Wei\u00dfenhorn": {
                "lat": 48.3,
                "lng": 10.1667
            },
            "Denzlingen": {
                "lat": 48.0683,
                "lng": 7.8825
            },
            "Oberhaching": {
                "lat": 48.0167,
                "lng": 11.5833
            },
            "Sankt Leon-Rot": {
                "lat": 49.2653,
                "lng": 8.5986
            },
            "Melsungen": {
                "lat": 51.1333,
                "lng": 9.55
            },
            "Buchloe": {
                "lat": 48.0375,
                "lng": 10.725
            },
            "Meerane": {
                "lat": 50.8519,
                "lng": 12.4636
            },
            "Quakenbr\u00fcck": {
                "lat": 52.6772,
                "lng": 7.9575
            },
            "Philippsburg": {
                "lat": 49.2333,
                "lng": 8.45
            },
            "Lauterbach": {
                "lat": 50.6378,
                "lng": 9.3944
            },
            "Friesenheim": {
                "lat": 48.3731,
                "lng": 7.8833
            },
            "Ettenheim": {
                "lat": 48.2556,
                "lng": 7.8119
            },
            "Hilpoltstein": {
                "lat": 49.1833,
                "lng": 11.1833
            },
            "Markt Schwaben": {
                "lat": 48.1911,
                "lng": 11.8681
            },
            "Michendorf": {
                "lat": 52.3,
                "lng": 13.0167
            },
            "Bad Soden-Salm\u00fcnster": {
                "lat": 50.2667,
                "lng": 9.3667
            },
            "Ha\u00dffurt": {
                "lat": 50.0167,
                "lng": 10.5
            },
            "Wanzleben": {
                "lat": 52.0667,
                "lng": 11.4333
            },
            "Meckenbeuren": {
                "lat": 47.7,
                "lng": 9.5625
            },
            "Krumbach": {
                "lat": 48.25,
                "lng": 10.3667
            },
            "L\u00f6ningen": {
                "lat": 52.7167,
                "lng": 7.7667
            },
            "H\u00fcnxe": {
                "lat": 51.6417,
                "lng": 6.7672
            },
            "Gr\u00e4felfing": {
                "lat": 48.1189,
                "lng": 11.4289
            },
            "Eppstein": {
                "lat": 50.1333,
                "lng": 8.4
            },
            "Holzgerlingen": {
                "lat": 48.6392,
                "lng": 9.0108
            },
            "Beverstedt": {
                "lat": 53.4333,
                "lng": 8.8167
            },
            "Eichst\u00e4tt": {
                "lat": 48.8919,
                "lng": 11.1839
            },
            "Marienheide": {
                "lat": 51.0833,
                "lng": 7.5333
            },
            "Biedenkopf": {
                "lat": 50.9128,
                "lng": 8.5322
            },
            "Angerm\u00fcnde": {
                "lat": 53.0333,
                "lng": 14.0
            },
            "Bergen": {
                "lat": 54.4167,
                "lng": 13.4333
            },
            "Genthin": {
                "lat": 52.4,
                "lng": 12.1667
            },
            "Schm\u00f6lln": {
                "lat": 50.895,
                "lng": 12.3564
            },
            "Hettstedt": {
                "lat": 51.6333,
                "lng": 11.5
            },
            "Sendenhorst": {
                "lat": 51.8439,
                "lng": 7.8278
            },
            "Bad D\u00fcrrheim": {
                "lat": 48.0167,
                "lng": 8.5333
            },
            "Pfullendorf": {
                "lat": 47.9242,
                "lng": 9.2567
            },
            "Bar\u00dfel": {
                "lat": 53.1703,
                "lng": 7.7467
            },
            "Rommerskirchen": {
                "lat": 51.0347,
                "lng": 6.6914
            },
            "Neckargem\u00fcnd": {
                "lat": 49.3939,
                "lng": 8.7975
            },
            "Lappersdorf": {
                "lat": 49.0525,
                "lng": 12.0903
            },
            "Pegnitz": {
                "lat": 49.7564,
                "lng": 11.545
            },
            "Asperg": {
                "lat": 48.9,
                "lng": 9.1333
            },
            "Hasel\u00fcnne": {
                "lat": 52.6667,
                "lng": 7.4667
            },
            "Wentorf bei Hamburg": {
                "lat": 53.4931,
                "lng": 10.2533
            },
            "Spaichingen": {
                "lat": 48.0758,
                "lng": 8.7378
            },
            "Pfarrkirchen": {
                "lat": 48.4167,
                "lng": 12.9167
            },
            "Bad Salzdetfurth": {
                "lat": 52.0653,
                "lng": 10.0092
            },
            "Tauberbischofsheim": {
                "lat": 49.6225,
                "lng": 9.6628
            },
            "Wiesmoor": {
                "lat": 53.4,
                "lng": 7.7333
            },
            "Wolfhagen": {
                "lat": 51.3167,
                "lng": 9.1667
            },
            "Schermbeck": {
                "lat": 51.695,
                "lng": 6.8756
            },
            "Bad Laasphe": {
                "lat": 50.9303,
                "lng": 8.4167
            },
            "Dinklage": {
                "lat": 52.6667,
                "lng": 8.1333
            },
            "H\u00f6sbach": {
                "lat": 50.0,
                "lng": 9.2
            },
            "Weilburg": {
                "lat": 50.4833,
                "lng": 8.25
            },
            "Ubstadt-Weiher": {
                "lat": 49.1656,
                "lng": 8.625
            },
            "Velen": {
                "lat": 51.8939,
                "lng": 6.9897
            },
            "Manching": {
                "lat": 48.7186,
                "lng": 11.4972
            },
            "Saarwellingen": {
                "lat": 49.35,
                "lng": 6.8333
            },
            "Weinsberg": {
                "lat": 49.1518,
                "lng": 9.2857
            },
            "Cremlingen": {
                "lat": 52.2489,
                "lng": 10.6542
            },
            "Zehdenick": {
                "lat": 52.9833,
                "lng": 13.3333
            },
            "H\u00fcllhorst": {
                "lat": 52.2833,
                "lng": 8.6667
            },
            "Sch\u00fcttorf": {
                "lat": 52.3167,
                "lng": 7.2167
            },
            "Ergolding": {
                "lat": 48.5833,
                "lng": 12.1667
            },
            "Sulingen": {
                "lat": 52.6667,
                "lng": 8.8
            },
            "Olfen": {
                "lat": 51.7167,
                "lng": 7.3833
            },
            "Herbrechtingen": {
                "lat": 48.6253,
                "lng": 10.1739
            },
            "Fehmarnsund": {
                "lat": 54.4454,
                "lng": 11.1702
            },
            "\u00d6stringen": {
                "lat": 49.2194,
                "lng": 8.7108
            },
            "Gangelt": {
                "lat": 50.9831,
                "lng": 6.0
            },
            "Beverungen": {
                "lat": 51.6628,
                "lng": 9.3725
            },
            "Beelitz": {
                "lat": 52.2333,
                "lng": 12.9667
            },
            "Trebur": {
                "lat": 49.925,
                "lng": 8.4056
            },
            "Bischofsheim": {
                "lat": 49.9833,
                "lng": 8.35
            },
            "Mutterstadt": {
                "lat": 49.4333,
                "lng": 8.35
            },
            "Ketsch": {
                "lat": 49.3658,
                "lng": 8.5336
            },
            "Sankt Georgen im Schwarzwald": {
                "lat": 48.1247,
                "lng": 8.3308
            },
            "Plattling": {
                "lat": 48.7667,
                "lng": 12.8667
            },
            "Brieselang": {
                "lat": 52.5833,
                "lng": 13.0
            },
            "Wehr": {
                "lat": 47.6297,
                "lng": 7.9044
            },
            "Treuchtlingen": {
                "lat": 48.9553,
                "lng": 10.9094
            },
            "Rosbach vor der H\u00f6he": {
                "lat": 50.2986,
                "lng": 8.7006
            },
            "Barsb\u00fcttel": {
                "lat": 53.5689,
                "lng": 10.1711
            },
            "Hungen": {
                "lat": 50.4667,
                "lng": 8.9
            },
            "Schee\u00dfel": {
                "lat": 53.1706,
                "lng": 9.4831
            },
            "Hadamar": {
                "lat": 50.45,
                "lng": 8.05
            },
            "R\u00f6dental": {
                "lat": 50.2833,
                "lng": 11.0667
            },
            "Linnich": {
                "lat": 50.9789,
                "lng": 6.2678
            },
            "Bohmte": {
                "lat": 52.3667,
                "lng": 8.3167
            },
            "Wasserburg am Inn": {
                "lat": 48.0617,
                "lng": 12.2333
            },
            "Schleiden": {
                "lat": 50.5331,
                "lng": 6.4667
            },
            "Roding": {
                "lat": 49.2,
                "lng": 12.5167
            },
            "Wickede": {
                "lat": 51.4964,
                "lng": 7.8658
            },
            "W\u00e4chtersbach": {
                "lat": 50.2547,
                "lng": 9.2919
            },
            "M\u00f6ckern": {
                "lat": 52.1406,
                "lng": 11.9525
            },
            "Besigheim": {
                "lat": 48.9983,
                "lng": 9.1417
            },
            "Quierschied": {
                "lat": 49.3167,
                "lng": 7.0333
            },
            "Ro\u00dfdorf": {
                "lat": 49.85,
                "lng": 8.75
            },
            "Wadersloh": {
                "lat": 51.7386,
                "lng": 8.2514
            },
            "Fuldatal": {
                "lat": 51.3833,
                "lng": 9.55
            },
            "Herzberg am Harz": {
                "lat": 51.6575,
                "lng": 10.3411
            },
            "Bad Urach": {
                "lat": 48.4931,
                "lng": 9.3986
            },
            "Spiesen-Elversberg": {
                "lat": 49.3167,
                "lng": 7.1333
            },
            "Pei\u00dfenberg": {
                "lat": 47.795,
                "lng": 11.0603
            },
            "Twistringen": {
                "lat": 52.8,
                "lng": 8.65
            },
            "Kaufungen": {
                "lat": 51.2811,
                "lng": 9.6186
            },
            "Wietmarschen": {
                "lat": 52.5333,
                "lng": 7.1333
            },
            "G\u00e4rtringen": {
                "lat": 48.6408,
                "lng": 8.9006
            },
            "Sulz am Neckar": {
                "lat": 48.3628,
                "lng": 8.6317
            },
            "Kirchheim bei M\u00fcnchen": {
                "lat": 48.1833,
                "lng": 11.75
            },
            "Altenstadt": {
                "lat": 50.2856,
                "lng": 8.945
            },
            "Feuchtwangen": {
                "lat": 49.1667,
                "lng": 10.3167
            },
            "Hersbruck": {
                "lat": 49.5081,
                "lng": 11.4328
            },
            "Winterberg": {
                "lat": 51.2,
                "lng": 8.5167
            },
            "Bad Abbach": {
                "lat": 48.9333,
                "lng": 12.05
            },
            "Blaubeuren": {
                "lat": 48.4119,
                "lng": 9.785
            },
            "Tamm": {
                "lat": 48.9167,
                "lng": 9.1167
            },
            "Waltershausen": {
                "lat": 50.8975,
                "lng": 10.5558
            },
            "Alpen": {
                "lat": 51.575,
                "lng": 6.5125
            },
            "Schongau": {
                "lat": 47.8167,
                "lng": 10.9
            },
            "Bad Windsheim": {
                "lat": 49.5,
                "lng": 10.4167
            },
            "Steinheim": {
                "lat": 51.8658,
                "lng": 9.0944
            },
            "J\u00fcterbog": {
                "lat": 51.9933,
                "lng": 13.0728
            },
            "Bretzfeld": {
                "lat": 49.1833,
                "lng": 9.4333
            },
            "Brunsb\u00fcttel": {
                "lat": 53.8964,
                "lng": 9.1386
            },
            "Hessisch Lichtenau": {
                "lat": 51.2,
                "lng": 9.7167
            },
            "Dossenheim": {
                "lat": 49.45,
                "lng": 8.6667
            },
            "Vilsbiburg": {
                "lat": 48.45,
                "lng": 12.35
            },
            "Niefern-\u00d6schelbronn": {
                "lat": 48.9164,
                "lng": 8.7842
            },
            "Hirschaid": {
                "lat": 49.8167,
                "lng": 10.9833
            },
            "Emstek": {
                "lat": 52.8167,
                "lng": 8.15
            },
            "R\u00f6thenbach an der Pegnitz": {
                "lat": 49.4847,
                "lng": 11.2475
            },
            "Preu\u00dfisch Oldendorf": {
                "lat": 52.2833,
                "lng": 8.5
            },
            "Ladenburg": {
                "lat": 49.4667,
                "lng": 8.6167
            },
            "Zella-Mehlis": {
                "lat": 50.6597,
                "lng": 10.6669
            },
            "Gladenbach": {
                "lat": 50.7681,
                "lng": 8.5828
            },
            "Edemissen": {
                "lat": 52.3833,
                "lng": 10.2667
            },
            "Neuenburg am Rhein": {
                "lat": 47.8147,
                "lng": 7.5619
            },
            "Bad Sassendorf": {
                "lat": 51.5831,
                "lng": 8.1667
            },
            "Graben-Neudorf": {
                "lat": 49.1592,
                "lng": 8.4894
            },
            "Velten": {
                "lat": 52.6833,
                "lng": 13.1833
            },
            "Gaildorf": {
                "lat": 49.0,
                "lng": 9.7667
            },
            "Hagenow": {
                "lat": 53.4167,
                "lng": 11.1833
            },
            "Titisee-Neustadt": {
                "lat": 47.9122,
                "lng": 8.2147
            },
            "Ebersberg": {
                "lat": 48.0833,
                "lng": 11.9667
            },
            "Issum": {
                "lat": 51.5389,
                "lng": 6.4236
            },
            "Bad Freienwalde": {
                "lat": 52.7856,
                "lng": 14.0325
            },
            "Essenbach": {
                "lat": 48.6167,
                "lng": 12.2167
            },
            "Gaimersheim": {
                "lat": 48.8167,
                "lng": 11.3667
            },
            "Jork": {
                "lat": 53.5344,
                "lng": 9.6817
            },
            "Bad Fallingbostel": {
                "lat": 52.8675,
                "lng": 9.6967
            },
            "Enkesen": {
                "lat": 51.4667,
                "lng": 7.9667
            },
            "Anklam": {
                "lat": 53.85,
                "lng": 13.6833
            },
            "Wernau": {
                "lat": 48.6886,
                "lng": 9.4222
            },
            "Frohburg": {
                "lat": 51.0561,
                "lng": 12.555
            },
            "Teningen": {
                "lat": 48.1269,
                "lng": 7.8103
            },
            "Burladingen": {
                "lat": 48.2903,
                "lng": 9.1094
            },
            "Ludwigslust": {
                "lat": 53.3244,
                "lng": 11.4972
            },
            "Dinkelsb\u00fchl": {
                "lat": 49.0708,
                "lng": 10.3194
            },
            "Neuhausen auf den Fildern": {
                "lat": 48.6844,
                "lng": 9.2744
            },
            "Laichingen": {
                "lat": 48.4897,
                "lng": 9.6861
            },
            "Nordstemmen": {
                "lat": 52.1667,
                "lng": 9.7667
            },
            "Linkenheim-Hochstetten": {
                "lat": 49.1261,
                "lng": 8.41
            },
            "Zetel": {
                "lat": 53.4197,
                "lng": 7.9742
            },
            "Steinheim am der Murr": {
                "lat": 48.9667,
                "lng": 9.2833
            },
            "Apen": {
                "lat": 53.2214,
                "lng": 7.8097
            },
            "Maxh\u00fctte-Haidhof": {
                "lat": 49.2,
                "lng": 12.1
            },
            "Haaren": {
                "lat": 50.7956,
                "lng": 6.1269
            },
            "Oftersheim": {
                "lat": 49.3706,
                "lng": 8.5847
            },
            "Osterhofen": {
                "lat": 48.7019,
                "lng": 13.02
            },
            "Tholey": {
                "lat": 49.4667,
                "lng": 7.0333
            },
            "Rielasingen-Worblingen": {
                "lat": 47.7314,
                "lng": 8.8386
            },
            "Havixbeck": {
                "lat": 51.8111,
                "lng": 7.4167
            },
            "Meitingen": {
                "lat": 48.5333,
                "lng": 10.8333
            },
            "Perleberg": {
                "lat": 53.0667,
                "lng": 11.8667
            },
            "Lehre": {
                "lat": 52.3167,
                "lng": 10.6667
            },
            "Sassenburg": {
                "lat": 52.5167,
                "lng": 10.6333
            },
            "Durmersheim": {
                "lat": 48.9383,
                "lng": 8.2769
            },
            "Miesbach": {
                "lat": 47.7833,
                "lng": 11.8333
            },
            "Mettlach": {
                "lat": 49.4942,
                "lng": 6.6052
            },
            "Wolgast": {
                "lat": 54.05,
                "lng": 13.7667
            },
            "Rosdorf": {
                "lat": 51.5,
                "lng": 9.9
            },
            "Mettingen": {
                "lat": 52.3167,
                "lng": 7.7806
            },
            "Oberschlei\u00dfheim": {
                "lat": 48.25,
                "lng": 11.5667
            },
            "Gundelfingen": {
                "lat": 48.05,
                "lng": 7.8667
            },
            "Erkner": {
                "lat": 52.4167,
                "lng": 13.75
            },
            "Murnau am Staffelsee": {
                "lat": 47.6833,
                "lng": 11.2
            },
            "Welver": {
                "lat": 51.6167,
                "lng": 7.9583
            },
            "Heddesheim": {
                "lat": 49.5053,
                "lng": 8.6033
            },
            "Harrislee": {
                "lat": 54.7972,
                "lng": 9.3764
            },
            "Kronshagen": {
                "lat": 54.3333,
                "lng": 10.0833
            },
            "Gerstetten": {
                "lat": 48.6225,
                "lng": 10.0206
            },
            "M\u00f6hnesee": {
                "lat": 51.4958,
                "lng": 8.1306
            },
            "Eichenau": {
                "lat": 48.1667,
                "lng": 11.3167
            },
            "Monschau": {
                "lat": 50.56,
                "lng": 6.2564
            },
            "Lauenburg": {
                "lat": 53.3833,
                "lng": 10.5667
            },
            "Wolnzach": {
                "lat": 48.6,
                "lng": 11.6167
            },
            "Raesfeld": {
                "lat": 51.7667,
                "lng": 6.8333
            },
            "Hemsbach": {
                "lat": 49.5903,
                "lng": 8.6564
            },
            "Oestrich-Winkel": {
                "lat": 50.0025,
                "lng": 8.0175
            },
            "Leingarten": {
                "lat": 49.15,
                "lng": 9.1167
            },
            "Lauffen am Neckar": {
                "lat": 49.0833,
                "lng": 9.15
            },
            "Langg\u00f6ns": {
                "lat": 50.5,
                "lng": 8.6667
            },
            "Walld\u00fcrn": {
                "lat": 49.5831,
                "lng": 9.3681
            },
            "Oberderdingen": {
                "lat": 49.0625,
                "lng": 8.8019
            },
            "Neuenrade": {
                "lat": 51.2839,
                "lng": 7.78
            },
            "Zw\u00f6nitz": {
                "lat": 50.6167,
                "lng": 12.8
            },
            "Hauzenberg": {
                "lat": 48.65,
                "lng": 13.6333
            },
            "Pritzwalk": {
                "lat": 53.15,
                "lng": 12.1833
            },
            "Leutenbach": {
                "lat": 48.8883,
                "lng": 9.3914
            },
            "Burgthann": {
                "lat": 49.35,
                "lng": 11.3
            },
            "Drolshagen": {
                "lat": 51.0333,
                "lng": 7.7667
            },
            "Bopfingen": {
                "lat": 48.8569,
                "lng": 10.3522
            },
            "P\u00f6\u00dfneck": {
                "lat": 50.7,
                "lng": 11.6
            },
            "Bad D\u00fcrrenberg": {
                "lat": 51.2833,
                "lng": 12.0667
            },
            "Waldniel": {
                "lat": 51.2131,
                "lng": 6.2731
            },
            "Scharbeutz": {
                "lat": 54.0214,
                "lng": 10.7456
            },
            "Lindenberg im Allg\u00e4u": {
                "lat": 47.6,
                "lng": 9.9
            },
            "Geisenheim": {
                "lat": 49.9844,
                "lng": 7.9672
            },
            "Rangsdorf": {
                "lat": 52.2833,
                "lng": 13.4333
            },
            "Kissing": {
                "lat": 48.3,
                "lng": 10.9833
            },
            "Hildburghausen": {
                "lat": 50.4167,
                "lng": 10.75
            },
            "Schwaigern": {
                "lat": 49.1333,
                "lng": 9.05
            },
            "Peiting": {
                "lat": 47.8,
                "lng": 10.9333
            },
            "Billerbeck": {
                "lat": 51.9792,
                "lng": 7.295
            },
            "Limburgerhof": {
                "lat": 49.4167,
                "lng": 8.4
            },
            "Hallbergmoos": {
                "lat": 48.3333,
                "lng": 11.75
            },
            "Raubling": {
                "lat": 47.7881,
                "lng": 12.1047
            },
            "Ostrhauderfehn": {
                "lat": 53.1167,
                "lng": 7.6167
            },
            "Geisenfeld": {
                "lat": 48.6667,
                "lng": 11.6
            },
            "Wolmirstedt": {
                "lat": 52.2519,
                "lng": 11.6297
            },
            "M\u00f6mbris": {
                "lat": 50.0667,
                "lng": 9.1667
            },
            "Weeze": {
                "lat": 51.6267,
                "lng": 6.1967
            },
            "Schwieberdingen": {
                "lat": 48.8778,
                "lng": 9.075
            },
            "\u00dcberherrn": {
                "lat": 49.25,
                "lng": 6.7
            },
            "Me\u00dfstetten": {
                "lat": 48.1806,
                "lng": 8.9625
            },
            "Sinzheim": {
                "lat": 48.7619,
                "lng": 8.1669
            },
            "Stelle": {
                "lat": 53.3667,
                "lng": 10.1167
            },
            "Unterf\u00f6hring": {
                "lat": 48.2,
                "lng": 11.65
            },
            "Ostbevern": {
                "lat": 52.0389,
                "lng": 7.8458
            },
            "Zeuthen": {
                "lat": 52.3667,
                "lng": 13.6167
            },
            "Wittingen": {
                "lat": 52.7167,
                "lng": 10.7333
            },
            "Westerkappeln": {
                "lat": 52.3806,
                "lng": 7.6
            },
            "Eningen unter Achalm": {
                "lat": 48.4831,
                "lng": 9.2522
            },
            "Egelsbach": {
                "lat": 49.9667,
                "lng": 8.6667
            },
            "Gr\u00e4fenhainichen": {
                "lat": 51.7167,
                "lng": 12.4333
            },
            "Kirchhundem": {
                "lat": 51.0667,
                "lng": 8.0833
            },
            "Trostberg an der Alz": {
                "lat": 48.0167,
                "lng": 12.55
            },
            "Harsum": {
                "lat": 52.2,
                "lng": 9.95
            },
            "Bad Schwalbach": {
                "lat": 50.1333,
                "lng": 8.0667
            },
            "H\u00f6henkirchen-Siegertsbrunn": {
                "lat": 48.0167,
                "lng": 11.7333
            },
            "Dautphe": {
                "lat": 50.8333,
                "lng": 8.55
            },
            "Cadolzburg": {
                "lat": 49.45,
                "lng": 10.8667
            },
            "Marktheidenfeld": {
                "lat": 49.85,
                "lng": 9.6
            },
            "Gr\u00fcnwald": {
                "lat": 48.0333,
                "lng": 11.5167
            },
            "Recke": {
                "lat": 52.37,
                "lng": 7.7189
            },
            "Altdorf": {
                "lat": 48.5667,
                "lng": 12.1167
            },
            "Rudersberg": {
                "lat": 48.8856,
                "lng": 9.5281
            },
            "Engen": {
                "lat": 47.8528,
                "lng": 8.7714
            },
            "Rodenbach": {
                "lat": 50.1389,
                "lng": 9.0417
            },
            "Rothenburg ob der Tauber": {
                "lat": 49.3833,
                "lng": 10.1833
            },
            "Ochsenfurt": {
                "lat": 49.65,
                "lng": 10.0667
            },
            "Handewitt": {
                "lat": 54.7667,
                "lng": 9.3167
            },
            "Rheinau": {
                "lat": 48.6678,
                "lng": 7.9347
            },
            "Welzheim": {
                "lat": 48.8747,
                "lng": 9.6344
            },
            "Nu\u00dfloch": {
                "lat": 49.3236,
                "lng": 8.6939
            },
            "M\u00fclheim-K\u00e4rlich": {
                "lat": 50.3869,
                "lng": 7.4953
            },
            "Denkendorf": {
                "lat": 48.6958,
                "lng": 9.3175
            },
            "Drochtersen": {
                "lat": 53.7,
                "lng": 9.3833
            },
            "Bad Nenndorf": {
                "lat": 52.3369,
                "lng": 9.3786
            },
            "Eichenzell": {
                "lat": 50.4833,
                "lng": 9.7
            },
            "Fallersleben": {
                "lat": 52.4189,
                "lng": 10.7169
            },
            "M\u00f6glingen": {
                "lat": 48.8883,
                "lng": 9.1292
            },
            "Prien am Chiemsee": {
                "lat": 47.8667,
                "lng": 12.3333
            },
            "Hagen im Bremischen": {
                "lat": 53.3667,
                "lng": 8.65
            },
            "Werther": {
                "lat": 52.075,
                "lng": 8.4125
            },
            "Lauingen": {
                "lat": 48.5667,
                "lng": 10.4333
            },
            "Herbolzheim": {
                "lat": 48.2219,
                "lng": 7.7775
            },
            "Diez": {
                "lat": 50.3708,
                "lng": 8.0158
            },
            "Sch\u00f6ningen": {
                "lat": 52.1333,
                "lng": 10.95
            },
            "Isselburg": {
                "lat": 51.8331,
                "lng": 6.4667
            },
            "Feldkirchen-Westerham": {
                "lat": 47.9,
                "lng": 11.85
            },
            "Kranenburg": {
                "lat": 51.7897,
                "lng": 6.0072
            },
            "Belzig": {
                "lat": 52.1422,
                "lng": 12.5956
            },
            "Kriftel": {
                "lat": 50.0833,
                "lng": 8.45
            },
            "Waldkirchen": {
                "lat": 48.7305,
                "lng": 13.6011
            },
            "Balve": {
                "lat": 51.3333,
                "lng": 7.8667
            },
            "Bannewitz": {
                "lat": 50.9931,
                "lng": 13.7167
            },
            "Stollberg": {
                "lat": 50.7083,
                "lng": 12.7783
            },
            "Braunfels": {
                "lat": 50.5167,
                "lng": 8.3833
            },
            "Hasbergen": {
                "lat": 52.2167,
                "lng": 7.9167
            },
            "Gengenbach": {
                "lat": 48.4,
                "lng": 8.0167
            },
            "Kall": {
                "lat": 50.55,
                "lng": 6.55
            },
            "Herrsching am Ammersee": {
                "lat": 48.0,
                "lng": 11.1833
            },
            "N\u00f6rvenich": {
                "lat": 50.8,
                "lng": 6.65
            },
            "Planegg": {
                "lat": 48.1047,
                "lng": 11.4306
            },
            "Korb": {
                "lat": 48.8417,
                "lng": 9.3611
            },
            "Rutesheim": {
                "lat": 48.8097,
                "lng": 8.945
            },
            "Riedlingen": {
                "lat": 48.1553,
                "lng": 9.4728
            },
            "Gernsheim": {
                "lat": 49.75,
                "lng": 8.4833
            },
            "Regen": {
                "lat": 48.9667,
                "lng": 13.1333
            },
            "Lorch": {
                "lat": 48.7983,
                "lng": 9.6883
            },
            "Schkopau": {
                "lat": 51.3833,
                "lng": 11.9667
            },
            "Wildau": {
                "lat": 52.3167,
                "lng": 13.6333
            },
            "Haigerloch": {
                "lat": 48.3647,
                "lng": 8.805
            },
            "Eisenberg": {
                "lat": 49.5614,
                "lng": 8.0725
            },
            "R\u00fcthen": {
                "lat": 51.4933,
                "lng": 8.4833
            },
            "Adendorf": {
                "lat": 53.2833,
                "lng": 10.45
            },
            "Neuhof": {
                "lat": 50.4333,
                "lng": 9.6167
            },
            "Burgkirchen an der Alz": {
                "lat": 48.1667,
                "lng": 12.7167
            },
            "Lichtenstein": {
                "lat": 50.7564,
                "lng": 12.6317
            },
            "Malente": {
                "lat": 54.1667,
                "lng": 10.55
            },
            "Donzdorf": {
                "lat": 48.6833,
                "lng": 9.8167
            },
            "Wustermark": {
                "lat": 52.55,
                "lng": 12.95
            },
            "Altensteig": {
                "lat": 48.5864,
                "lng": 8.6047
            },
            "Karlsdorf-Neuthard": {
                "lat": 49.1364,
                "lng": 8.5439
            },
            "Kenzingen": {
                "lat": 48.1917,
                "lng": 7.7683
            },
            "Osterwieck": {
                "lat": 51.9667,
                "lng": 10.7167
            },
            "Langenzenn": {
                "lat": 49.4944,
                "lng": 10.7947
            },
            "Gottmadingen": {
                "lat": 47.7356,
                "lng": 8.7767
            },
            "Gl\u00fcckstadt": {
                "lat": 53.7917,
                "lng": 9.4219
            },
            "Hammelburg": {
                "lat": 50.1167,
                "lng": 9.9
            },
            "Sch\u00f6naich": {
                "lat": 48.6569,
                "lng": 9.0628
            },
            "Herxheim": {
                "lat": 49.1469,
                "lng": 8.22
            },
            "Lichtenau": {
                "lat": 51.6,
                "lng": 8.8833
            },
            "Hamb\u00fchren": {
                "lat": 52.6333,
                "lng": 9.9833
            },
            "Steinbach am Taunus": {
                "lat": 50.1667,
                "lng": 8.5667
            },
            "Nauheim": {
                "lat": 49.9506,
                "lng": 8.4631
            },
            "Boizenburg": {
                "lat": 53.3667,
                "lng": 10.7167
            },
            "Stephanskirchen": {
                "lat": 47.85,
                "lng": 12.1833
            },
            "M\u00fclsen": {
                "lat": 50.7447,
                "lng": 12.5747
            },
            "Wald-Michelbach": {
                "lat": 49.5667,
                "lng": 8.8333
            },
            "Kirchseeon": {
                "lat": 48.0731,
                "lng": 11.8861
            },
            "Kleinblittersdorf": {
                "lat": 49.1583,
                "lng": 7.0361
            },
            "Bad Orb": {
                "lat": 50.2167,
                "lng": 9.35
            },
            "Diedorf": {
                "lat": 48.35,
                "lng": 10.7667
            },
            "Morbach": {
                "lat": 49.8167,
                "lng": 7.1167
            },
            "Bestwig": {
                "lat": 51.3667,
                "lng": 8.4
            },
            "Dallgow-D\u00f6beritz": {
                "lat": 52.5331,
                "lng": 13.05
            },
            "Bischofswerda": {
                "lat": 51.1275,
                "lng": 14.1797
            },
            "Ruppichteroth": {
                "lat": 50.8439,
                "lng": 7.4836
            },
            "Felsberg": {
                "lat": 51.1333,
                "lng": 9.4167
            },
            "Barmstedt": {
                "lat": 53.7833,
                "lng": 9.7667
            },
            "Emsb\u00fcren": {
                "lat": 52.3925,
                "lng": 7.2914
            },
            "Schleusingen": {
                "lat": 50.5167,
                "lng": 10.75
            },
            "Die\u00dfen am Ammersee": {
                "lat": 47.95,
                "lng": 11.1
            },
            "Tangerh\u00fctte": {
                "lat": 52.4333,
                "lng": 11.8
            },
            "B\u00f6hl-Iggelheim": {
                "lat": 49.3833,
                "lng": 8.3
            },
            "Sch\u00f6nwalde-Siedlung": {
                "lat": 52.65,
                "lng": 12.9833
            },
            "Aerzen": {
                "lat": 52.0494,
                "lng": 9.2653
            },
            "Neuenhaus": {
                "lat": 52.5,
                "lng": 6.9667
            },
            "Bad Iburg": {
                "lat": 52.1592,
                "lng": 8.0472
            },
            "Weinb\u00f6hla": {
                "lat": 51.1667,
                "lng": 13.5667
            },
            "B\u00fcdelsdorf": {
                "lat": 54.3167,
                "lng": 9.6833
            },
            "Wendeburg": {
                "lat": 52.3167,
                "lng": 10.4
            },
            "Werlte": {
                "lat": 52.85,
                "lng": 7.6833
            },
            "Grevesm\u00fchlen": {
                "lat": 53.8667,
                "lng": 11.1667
            },
            "Reiskirchen": {
                "lat": 50.6,
                "lng": 8.8333
            },
            "Fl\u00f6ha": {
                "lat": 50.8558,
                "lng": 13.0714
            },
            "Plankstadt": {
                "lat": 49.3933,
                "lng": 8.5942
            },
            "Hilter": {
                "lat": 52.15,
                "lng": 8.15
            },
            "Burgau": {
                "lat": 48.4322,
                "lng": 10.4069
            },
            "Markt Indersdorf": {
                "lat": 48.3667,
                "lng": 11.3667
            },
            "Bad Wildbad": {
                "lat": 48.7503,
                "lng": 8.5506
            },
            "Gommern": {
                "lat": 52.0667,
                "lng": 11.8333
            },
            "Burgst\u00e4dt": {
                "lat": 50.9167,
                "lng": 12.8167
            },
            "Olbernhau": {
                "lat": 50.6667,
                "lng": 13.3333
            },
            "Friedeburg": {
                "lat": 53.45,
                "lng": 7.8333
            },
            "Lollar": {
                "lat": 50.6497,
                "lng": 8.7044
            },
            "Wildberg": {
                "lat": 48.6239,
                "lng": 8.7472
            },
            "Nideggen": {
                "lat": 50.7,
                "lng": 6.4833
            },
            "Simbach am Inn": {
                "lat": 48.2667,
                "lng": 13.0167
            },
            "Altenberge": {
                "lat": 52.0458,
                "lng": 7.4653
            },
            "Nordkirchen": {
                "lat": 51.7381,
                "lng": 7.5256
            },
            "Braunsbedra": {
                "lat": 51.2833,
                "lng": 11.9
            },
            "Demmin": {
                "lat": 53.905,
                "lng": 13.0439
            },
            "Nossen": {
                "lat": 51.05,
                "lng": 13.3
            },
            "Kaufering": {
                "lat": 48.0833,
                "lng": 10.8833
            },
            "Altusried": {
                "lat": 47.8,
                "lng": 10.2167
            },
            "Steinfeld": {
                "lat": 52.6,
                "lng": 8.2167
            },
            "Neuenstadt am Kocher": {
                "lat": 49.2333,
                "lng": 9.3333
            },
            "Steinau an der Stra\u00dfe": {
                "lat": 50.3167,
                "lng": 9.4667
            },
            "Klipphausen": {
                "lat": 51.0833,
                "lng": 13.5333
            },
            "Augustdorf": {
                "lat": 51.9,
                "lng": 8.7333
            },
            "Nieder-Olm": {
                "lat": 49.9083,
                "lng": 8.2028
            },
            "Anr\u00f6chte": {
                "lat": 51.5667,
                "lng": 8.3333
            },
            "Steinen": {
                "lat": 47.6453,
                "lng": 7.7403
            },
            "Aulendorf": {
                "lat": 47.9542,
                "lng": 9.6389
            },
            "Erlenbach am Main": {
                "lat": 49.8039,
                "lng": 9.1639
            },
            "Weilheim an der Teck": {
                "lat": 48.615,
                "lng": 9.5386
            },
            "Appenweier": {
                "lat": 48.5397,
                "lng": 7.98
            },
            "H\u00f6chst im Odenwald": {
                "lat": 49.8,
                "lng": 9.0
            },
            "Gro\u00dfburgwedel": {
                "lat": 52.4933,
                "lng": 9.8586
            },
            "Merzenich": {
                "lat": 50.8333,
                "lng": 6.5333
            },
            "Bad Lauterberg": {
                "lat": 51.6317,
                "lng": 10.4706
            },
            "Kirchlinteln": {
                "lat": 52.9428,
                "lng": 9.3183
            },
            "Morsbach": {
                "lat": 50.8667,
                "lng": 7.7167
            },
            "Schalksm\u00fchle": {
                "lat": 51.2167,
                "lng": 7.5167
            },
            "Tangerm\u00fcnde": {
                "lat": 52.5408,
                "lng": 11.9689
            },
            "Bogen": {
                "lat": 48.9167,
                "lng": 12.6833
            },
            "Goldenstedt": {
                "lat": 52.7833,
                "lng": 8.4167
            },
            "Lahstedt": {
                "lat": 52.25,
                "lng": 10.2167
            },
            "Querfurt": {
                "lat": 51.3833,
                "lng": 11.6
            },
            "Blumberg": {
                "lat": 47.8392,
                "lng": 8.5342
            },
            "Kirchzarten": {
                "lat": 47.965,
                "lng": 7.9556
            },
            "Ostercappeln": {
                "lat": 52.35,
                "lng": 8.2333
            },
            "Goldbach": {
                "lat": 49.9889,
                "lng": 9.1864
            },
            "S\u00fc\u00dfen": {
                "lat": 48.6797,
                "lng": 9.7575
            },
            "Spelle": {
                "lat": 52.3667,
                "lng": 7.4667
            },
            "K\u00f6nigsbach-Stein": {
                "lat": 48.9664,
                "lng": 8.6089
            },
            "Betzdorf": {
                "lat": 50.7856,
                "lng": 7.8728
            },
            "Rehburg-Loccum": {
                "lat": 52.4508,
                "lng": 9.2078
            },
            "Beilngries": {
                "lat": 49.0333,
                "lng": 11.4667
            },
            "Nieb\u00fcll": {
                "lat": 54.7881,
                "lng": 8.8296
            },
            "M\u00fcnchberg": {
                "lat": 50.2,
                "lng": 11.7667
            },
            "Birkenfeld": {
                "lat": 48.8697,
                "lng": 8.6361
            },
            "M\u00f6rlenbach": {
                "lat": 49.5992,
                "lng": 8.7347
            },
            "Bleicherode": {
                "lat": 51.4167,
                "lng": 10.5667
            },
            "Werneck": {
                "lat": 49.9833,
                "lng": 10.1
            },
            "Lenggries": {
                "lat": 47.6803,
                "lng": 11.5739
            },
            "Visbek": {
                "lat": 52.8366,
                "lng": 8.3098
            },
            "Kirkel": {
                "lat": 49.2833,
                "lng": 7.2333
            },
            "Alt\u00f6tting": {
                "lat": 48.2267,
                "lng": 12.6783
            },
            "Meuselwitz": {
                "lat": 51.05,
                "lng": 12.3
            },
            "Tutzing": {
                "lat": 47.9089,
                "lng": 11.2814
            },
            "Ro\u00dftal": {
                "lat": 49.4,
                "lng": 10.8833
            },
            "Weil im Sch\u00f6nbuch": {
                "lat": 48.6214,
                "lng": 9.0611
            },
            "Bieber": {
                "lat": 50.6,
                "lng": 8.5833
            },
            "Willst\u00e4tt": {
                "lat": 48.5417,
                "lng": 7.8964
            },
            "Heubach": {
                "lat": 48.7881,
                "lng": 9.9333
            },
            "Bobenheim-Roxheim": {
                "lat": 49.5833,
                "lng": 8.35
            },
            "R\u00fcdesheim am Rhein": {
                "lat": 49.9833,
                "lng": 7.9306
            },
            "Schotten": {
                "lat": 50.5,
                "lng": 9.1167
            },
            "Kaldenkirchen": {
                "lat": 51.3167,
                "lng": 6.2833
            },
            "Trebbin": {
                "lat": 52.2167,
                "lng": 13.2
            },
            "Wahlstedt": {
                "lat": 53.95,
                "lng": 10.2167
            },
            "Bad Frankenhausen": {
                "lat": 51.3558,
                "lng": 11.1011
            },
            "Mengen": {
                "lat": 48.0497,
                "lng": 9.33
            },
            "Gudensberg": {
                "lat": 51.1833,
                "lng": 9.3667
            },
            "Dettingen an der Erms": {
                "lat": 48.53,
                "lng": 9.3472
            },
            "Ainring": {
                "lat": 47.8136,
                "lng": 12.9428
            },
            "Ottendorf-Okrilla": {
                "lat": 51.1792,
                "lng": 13.8261
            },
            "Altenholz": {
                "lat": 54.4,
                "lng": 10.1333
            },
            "Friedrichsthal": {
                "lat": 49.3256,
                "lng": 7.0961
            },
            "Aich": {
                "lat": 48.6228,
                "lng": 9.2372
            },
            "Bad K\u00f6nig": {
                "lat": 49.75,
                "lng": 9.0167
            },
            "Oelsnitz": {
                "lat": 50.4167,
                "lng": 12.1667
            },
            "Bad Bevensen": {
                "lat": 53.0792,
                "lng": 10.5833
            },
            "Bad Ems": {
                "lat": 50.3381,
                "lng": 7.7106
            },
            "Marpingen": {
                "lat": 49.45,
                "lng": 7.05
            },
            "Nohfelden": {
                "lat": 49.5667,
                "lng": 7.15
            },
            "Waltenhofen": {
                "lat": 47.6667,
                "lng": 10.3
            },
            "Birkenau": {
                "lat": 49.5625,
                "lng": 8.7069
            },
            "Oldenburg in Holstein": {
                "lat": 54.3,
                "lng": 10.8833
            },
            "Maisenhausen": {
                "lat": 50.0167,
                "lng": 9.0
            },
            "K\u00fcmmersbruck": {
                "lat": 49.4167,
                "lng": 11.8833
            },
            "Pliezhausen": {
                "lat": 48.5586,
                "lng": 9.2058
            },
            "Schlitz": {
                "lat": 50.6667,
                "lng": 9.5667
            },
            "R\u00f6dinghausen": {
                "lat": 52.25,
                "lng": 8.4667
            },
            "K\u00f6sching": {
                "lat": 48.8167,
                "lng": 11.5
            },
            "Heilsbronn": {
                "lat": 49.3167,
                "lng": 10.8
            },
            "Mittenwalde": {
                "lat": 52.2667,
                "lng": 13.5333
            },
            "Bisingen": {
                "lat": 48.3119,
                "lng": 8.9178
            },
            "Merchweiler": {
                "lat": 49.35,
                "lng": 7.0333
            },
            "Bockenem": {
                "lat": 52.0117,
                "lng": 10.1319
            },
            "Ilsfeld": {
                "lat": 49.05,
                "lng": 9.25
            },
            "Pasewalk": {
                "lat": 53.5,
                "lng": 14.0
            },
            "Vettwei\u00df": {
                "lat": 50.7389,
                "lng": 6.5972
            },
            "Altlandsberg": {
                "lat": 52.5667,
                "lng": 13.7331
            },
            "K\u00f6ngen": {
                "lat": 48.6819,
                "lng": 9.3667
            },
            "Nordwalde": {
                "lat": 52.0833,
                "lng": 7.4833
            },
            "Laubach": {
                "lat": 50.5333,
                "lng": 8.99
            },
            "H\u00f6now": {
                "lat": 52.5483,
                "lng": 13.6378
            },
            "Bad Liebenzell": {
                "lat": 48.7742,
                "lng": 8.7314
            },
            "Visselh\u00f6vede": {
                "lat": 52.9667,
                "lng": 9.5833
            },
            "Magstadt": {
                "lat": 48.7422,
                "lng": 8.965
            },
            "W\u00f6lfersheim": {
                "lat": 50.3975,
                "lng": 8.8114
            },
            "Pl\u00fcderhausen": {
                "lat": 48.795,
                "lng": 9.6011
            },
            "Oberstdorf": {
                "lat": 47.4167,
                "lng": 10.2833
            },
            "Bad Breisig": {
                "lat": 50.5092,
                "lng": 7.2964
            },
            "Twist": {
                "lat": 52.6167,
                "lng": 7.0333
            },
            "F\u00fcrstenau": {
                "lat": 52.5167,
                "lng": 7.6667
            },
            "Osthofen": {
                "lat": 49.7078,
                "lng": 8.3289
            },
            "Everswinkel": {
                "lat": 51.925,
                "lng": 7.8478
            },
            "Giesen": {
                "lat": 52.2,
                "lng": 9.8833
            },
            "Oberhausen-Rheinhausen": {
                "lat": 49.2606,
                "lng": 8.485
            },
            "Worpswede": {
                "lat": 53.2222,
                "lng": 8.9278
            },
            "Schwaikheim": {
                "lat": 48.8714,
                "lng": 9.3531
            },
            "Dassel": {
                "lat": 51.8033,
                "lng": 9.6903
            },
            "Neu Bleckede": {
                "lat": 53.3,
                "lng": 10.7333
            },
            "Kl\u00f6tze": {
                "lat": 52.6263,
                "lng": 11.1616
            },
            "Gro\u00dfr\u00f6hrsdorf": {
                "lat": 51.1444,
                "lng": 14.0167
            },
            "Brandis": {
                "lat": 51.3347,
                "lng": 12.6089
            },
            "Veitsh\u00f6chheim": {
                "lat": 49.8328,
                "lng": 9.8817
            },
            "S\u00fcdlohn": {
                "lat": 51.9436,
                "lng": 6.8664
            },
            "Ohrdruf": {
                "lat": 50.8281,
                "lng": 10.7328
            },
            "Nersingen": {
                "lat": 48.4289,
                "lng": 10.1219
            },
            "Hemau": {
                "lat": 49.0519,
                "lng": 11.7828
            },
            "H\u00f6chberg": {
                "lat": 49.7831,
                "lng": 9.8817
            },
            "Grimmen": {
                "lat": 54.11,
                "lng": 13.0414
            },
            "L\u00fcchow": {
                "lat": 52.9667,
                "lng": 11.15
            },
            "Osterburg": {
                "lat": 52.7833,
                "lng": 11.7667
            },
            "Schulzendorf": {
                "lat": 52.3667,
                "lng": 13.5831
            },
            "Miltenberg": {
                "lat": 49.7039,
                "lng": 9.2644
            },
            "Bad Gandersheim": {
                "lat": 51.8719,
                "lng": 10.0253
            },
            "Ehringshausen": {
                "lat": 50.6,
                "lng": 8.3833
            },
            "Hohenhameln": {
                "lat": 52.26,
                "lng": 10.0664
            },
            "Wilkau-Ha\u00dflau": {
                "lat": 50.6667,
                "lng": 12.5167
            },
            "Sebnitz": {
                "lat": 50.9667,
                "lng": 14.2833
            },
            "H\u00f6hr-Grenzhausen": {
                "lat": 50.435,
                "lng": 7.6711
            },
            "Luckau": {
                "lat": 51.85,
                "lng": 13.7167
            },
            "Gro\u00dfhansdorf": {
                "lat": 53.6667,
                "lng": 10.2667
            },
            "Ilsenburg": {
                "lat": 51.8667,
                "lng": 10.6833
            },
            "Wertingen": {
                "lat": 48.5333,
                "lng": 10.6667
            },
            "Molbergen": {
                "lat": 52.8667,
                "lng": 7.9333
            },
            "Kemberg": {
                "lat": 51.7833,
                "lng": 12.6333
            },
            "T\u00f6ging am Inn": {
                "lat": 48.25,
                "lng": 12.5667
            },
            "Runkel": {
                "lat": 50.4053,
                "lng": 8.155
            },
            "Hohenm\u00f6lsen": {
                "lat": 51.1564,
                "lng": 12.0981
            },
            "Nittenau": {
                "lat": 49.2,
                "lng": 12.2667
            },
            "Teisendorf": {
                "lat": 47.85,
                "lng": 12.8167
            },
            "Aschheim": {
                "lat": 48.1733,
                "lng": 11.7178
            },
            "Kurort Steinbach-Hallenberg": {
                "lat": 50.7006,
                "lng": 10.5667
            },
            "Salzhemmendorf": {
                "lat": 52.0667,
                "lng": 9.5833
            },
            "Ichenhausen": {
                "lat": 48.3667,
                "lng": 10.3167
            },
            "Zorneding": {
                "lat": 48.0833,
                "lng": 11.8333
            },
            "Rehau": {
                "lat": 50.25,
                "lng": 12.0167
            },
            "Werneuchen": {
                "lat": 52.6333,
                "lng": 13.7333
            },
            "Obernkirchen": {
                "lat": 52.2664,
                "lng": 9.1178
            },
            "Wallerfangen": {
                "lat": 49.3128,
                "lng": 6.735
            },
            "Schlangen": {
                "lat": 51.8167,
                "lng": 8.8331
            },
            "Adelsdorf": {
                "lat": 49.7167,
                "lng": 10.9
            },
            "Aidlingen": {
                "lat": 48.6792,
                "lng": 8.8969
            },
            "Kolkwitz": {
                "lat": 51.75,
                "lng": 14.25
            },
            "Schaafheim": {
                "lat": 49.9167,
                "lng": 9.0167
            },
            "Wehrheim": {
                "lat": 50.3,
                "lng": 8.5667
            },
            "Zwenkau": {
                "lat": 51.2175,
                "lng": 12.3242
            },
            "Heiligenhafen": {
                "lat": 54.3739,
                "lng": 10.9797
            },
            "Bruchhausen-Vilsen": {
                "lat": 52.8333,
                "lng": 9.0
            },
            "Ehningen": {
                "lat": 48.6589,
                "lng": 8.9403
            },
            "Dornstadt": {
                "lat": 48.4692,
                "lng": 9.9417
            },
            "Torgelow": {
                "lat": 53.6167,
                "lng": 14.0
            },
            "Pfedelbach": {
                "lat": 49.1833,
                "lng": 9.5
            },
            "Ilvesheim": {
                "lat": 49.4725,
                "lng": 8.5675
            },
            "Ki\u00dflegg": {
                "lat": 47.79,
                "lng": 9.8842
            },
            "Tecklenburg": {
                "lat": 52.2194,
                "lng": 7.8125
            },
            "Bad Liebenwerda": {
                "lat": 51.5167,
                "lng": 13.4
            },
            "Frickenhausen": {
                "lat": 48.5928,
                "lng": 9.3611
            },
            "Elsenfeld": {
                "lat": 49.85,
                "lng": 9.1667
            },
            "Freystadt": {
                "lat": 49.2,
                "lng": 11.3167
            },
            "Ochsenhausen": {
                "lat": 48.0722,
                "lng": 9.9481
            },
            "Wei\u00dfenthurm": {
                "lat": 50.4144,
                "lng": 7.4606
            },
            "Borgholzhausen": {
                "lat": 52.1,
                "lng": 8.3
            },
            "Gomaringen": {
                "lat": 48.452,
                "lng": 9.1
            },
            "Niesky": {
                "lat": 51.3,
                "lng": 14.8167
            },
            "Wunsiedel": {
                "lat": 50.0167,
                "lng": 12.0167
            },
            "Barleben": {
                "lat": 52.2,
                "lng": 11.6333
            },
            "Rain": {
                "lat": 48.6833,
                "lng": 10.9167
            },
            "Dielheim": {
                "lat": 49.2825,
                "lng": 8.7347
            },
            "Rethen": {
                "lat": 52.2861,
                "lng": 9.825
            },
            "Bad Laer": {
                "lat": 52.1031,
                "lng": 8.0892
            },
            "Altenbeken": {
                "lat": 51.7667,
                "lng": 8.9333
            },
            "Kandel": {
                "lat": 49.0833,
                "lng": 8.2
            },
            "Gnarrenburg": {
                "lat": 53.3864,
                "lng": 9.005
            },
            "Elsfleth": {
                "lat": 53.2333,
                "lng": 8.4667
            },
            "Sa\u00dfnitz": {
                "lat": 54.5164,
                "lng": 13.6411
            },
            "Alsbach-H\u00e4hnlein": {
                "lat": 49.7333,
                "lng": 8.6
            },
            "Elze": {
                "lat": 52.1167,
                "lng": 9.7333
            },
            "Trittau": {
                "lat": 53.6167,
                "lng": 10.4
            },
            "Biblis": {
                "lat": 49.7,
                "lng": 8.4667
            },
            "D\u00f6rverden": {
                "lat": 52.85,
                "lng": 9.2333
            },
            "Waldfeucht": {
                "lat": 51.0714,
                "lng": 5.9831
            },
            "Z\u00f6rbig": {
                "lat": 51.6167,
                "lng": 12.1167
            },
            "Gr\u00fcnheide": {
                "lat": 52.4167,
                "lng": 13.8167
            },
            "Kyritz": {
                "lat": 52.95,
                "lng": 12.4
            },
            "Vallendar": {
                "lat": 50.3971,
                "lng": 7.622
            },
            "Laufenburg (Baden)": {
                "lat": 47.5656,
                "lng": 8.0647
            },
            "Mendig": {
                "lat": 50.3744,
                "lng": 7.2808
            },
            "Bad Schussenried": {
                "lat": 48.0067,
                "lng": 9.6586
            },
            "Reinfeld": {
                "lat": 53.8333,
                "lng": 10.4833
            },
            "Nalbach": {
                "lat": 49.3667,
                "lng": 6.7667
            },
            "Urbach": {
                "lat": 48.8133,
                "lng": 9.5789
            },
            "Fehrbellin": {
                "lat": 52.7997,
                "lng": 12.7667
            },
            "Mainaschaff": {
                "lat": 49.9833,
                "lng": 9.0833
            },
            "Gro\u00dfbeeren": {
                "lat": 52.3544,
                "lng": 13.3061
            },
            "Zwiesel": {
                "lat": 49.0167,
                "lng": 13.2333
            },
            "Berching": {
                "lat": 49.1,
                "lng": 11.4333
            },
            "Breckerfeld": {
                "lat": 51.2667,
                "lng": 7.4667
            },
            "Bockhorn": {
                "lat": 53.4,
                "lng": 8.0167
            },
            "Waldheim": {
                "lat": 51.0667,
                "lng": 13.0167
            },
            "Westerland": {
                "lat": 54.91,
                "lng": 8.3075
            },
            "Doberlug-Kirchhain": {
                "lat": 51.6167,
                "lng": 13.5667
            },
            "Furth im Wald": {
                "lat": 49.3097,
                "lng": 12.84
            },
            "Pullach im Isartal": {
                "lat": 48.05,
                "lng": 11.5167
            },
            "Hemmoor": {
                "lat": 53.7025,
                "lng": 9.1394
            },
            "Gerstungen": {
                "lat": 50.9625,
                "lng": 10.0597
            },
            "Brand-Erbisdorf": {
                "lat": 50.8689,
                "lng": 13.3219
            },
            "Furtwangen im Schwarzwald": {
                "lat": 48.0503,
                "lng": 8.2092
            },
            "Neu\u00f6tting": {
                "lat": 48.2167,
                "lng": 12.6833
            },
            "Zschopau": {
                "lat": 50.75,
                "lng": 13.0667
            },
            "Perl": {
                "lat": 49.4667,
                "lng": 6.3667
            },
            "Wenzenbach": {
                "lat": 49.0747,
                "lng": 12.1944
            },
            "Hilzingen": {
                "lat": 47.7653,
                "lng": 8.7844
            },
            "Neuried": {
                "lat": 48.0933,
                "lng": 11.4658
            },
            "Ortenberg": {
                "lat": 50.3558,
                "lng": 9.0553
            },
            "Fuldabr\u00fcck": {
                "lat": 51.4,
                "lng": 9.4667
            },
            "Gro\u00df Kreutz": {
                "lat": 52.4,
                "lng": 12.7833
            },
            "Pl\u00f6n": {
                "lat": 54.1622,
                "lng": 10.4214
            },
            "Bad Lauchst\u00e4dt": {
                "lat": 51.3667,
                "lng": 11.8333
            },
            "Bestensee": {
                "lat": 52.25,
                "lng": 13.65
            },
            "H\u00fcrtgenwald": {
                "lat": 50.71,
                "lng": 6.3744
            },
            "Neuhaus am Rennweg": {
                "lat": 50.5167,
                "lng": 11.15
            },
            "Eslohe": {
                "lat": 51.25,
                "lng": 8.1667
            },
            "Weilm\u00fcnster": {
                "lat": 50.4333,
                "lng": 8.3667
            },
            "Florstadt": {
                "lat": 50.3158,
                "lng": 8.8631
            },
            "B\u00f6sel": {
                "lat": 53.0058,
                "lng": 7.9542
            },
            "Oststeinbek": {
                "lat": 53.5442,
                "lng": 10.1664
            },
            "Hallstadt": {
                "lat": 49.9333,
                "lng": 10.8833
            },
            "Hohenbrunn": {
                "lat": 48.05,
                "lng": 11.7
            },
            "Schleiz": {
                "lat": 50.5833,
                "lng": 11.8167
            },
            "Kusterdingen": {
                "lat": 48.5312,
                "lng": 9.1484
            },
            "Bellheim": {
                "lat": 49.1981,
                "lng": 8.2792
            },
            "Bersenbr\u00fcck": {
                "lat": 52.5333,
                "lng": 7.9167
            },
            "Volkach": {
                "lat": 49.8667,
                "lng": 10.2167
            },
            "Naunhof": {
                "lat": 51.2778,
                "lng": 12.5883
            },
            "Steinheim am Albuch": {
                "lat": 48.6922,
                "lng": 10.0642
            },
            "Heek": {
                "lat": 52.1167,
                "lng": 7.0997
            },
            "Kressbronn am Bodensee": {
                "lat": 47.5958,
                "lng": 9.6
            },
            "Memmelsdorf": {
                "lat": 49.9328,
                "lng": 10.9533
            },
            "Obernburg am Main": {
                "lat": 49.84,
                "lng": 9.1414
            },
            "Ballenstedt": {
                "lat": 51.72,
                "lng": 11.2375
            },
            "Lienen": {
                "lat": 52.1461,
                "lng": 7.9739
            },
            "Waldenbuch": {
                "lat": 48.6372,
                "lng": 9.1317
            },
            "Garching an der Alz": {
                "lat": 48.1167,
                "lng": 12.5833
            },
            "Barth": {
                "lat": 54.3667,
                "lng": 12.7167
            },
            "Efringen-Kirchen": {
                "lat": 47.6556,
                "lng": 7.5658
            },
            "Gro\u00dfenl\u00fcder": {
                "lat": 50.5833,
                "lng": 9.5333
            },
            "Borgentreich": {
                "lat": 51.5667,
                "lng": 9.25
            },
            "Ottobeuren": {
                "lat": 47.9414,
                "lng": 10.2994
            },
            "Vohburg an der Donau": {
                "lat": 48.7667,
                "lng": 11.6167
            },
            "Sonsbeck": {
                "lat": 51.6089,
                "lng": 6.3769
            },
            "Biederitz": {
                "lat": 52.1608,
                "lng": 11.7178
            },
            "Me\u00dfkirch": {
                "lat": 47.9928,
                "lng": 9.1125
            },
            "Rauenberg": {
                "lat": 49.2678,
                "lng": 8.7036
            },
            "Bad Bergzabern": {
                "lat": 49.1028,
                "lng": 7.9991
            },
            "L\u00f6wenberg": {
                "lat": 52.8833,
                "lng": 13.15
            },
            "Herzberg": {
                "lat": 51.6833,
                "lng": 13.2333
            },
            "Obertraubling": {
                "lat": 48.9658,
                "lng": 12.1669
            },
            "Roetgen": {
                "lat": 50.65,
                "lng": 6.2
            },
            "Richterich": {
                "lat": 50.8086,
                "lng": 6.0625
            },
            "Wietze": {
                "lat": 52.65,
                "lng": 9.8333
            },
            "Ergoldsbach": {
                "lat": 48.6833,
                "lng": 12.2
            },
            "Heikendorf": {
                "lat": 54.3722,
                "lng": 10.2081
            },
            "Titz": {
                "lat": 51.0061,
                "lng": 6.4242
            },
            "Flieden": {
                "lat": 50.4167,
                "lng": 9.5667
            },
            "N\u00f6rten-Hardenberg": {
                "lat": 51.6167,
                "lng": 9.9333
            },
            "Meine": {
                "lat": 52.3833,
                "lng": 10.5333
            },
            "Tirschenreuth": {
                "lat": 49.8833,
                "lng": 12.3333
            },
            "Untergruppenbach": {
                "lat": 49.0833,
                "lng": 9.2667
            },
            "Timmendorfer Strand": {
                "lat": 53.9944,
                "lng": 10.7825
            },
            "Budenheim": {
                "lat": 50.0167,
                "lng": 8.1667
            },
            "Niederwerrn": {
                "lat": 50.0633,
                "lng": 10.1728
            },
            "Beutelsbach": {
                "lat": 48.8,
                "lng": 9.3833
            },
            "Sande": {
                "lat": 53.4833,
                "lng": 8.0
            },
            "Nierstein": {
                "lat": 49.8694,
                "lng": 8.3375
            },
            "Barntrup": {
                "lat": 51.9831,
                "lng": 9.1167
            },
            "Kappeln": {
                "lat": 54.6614,
                "lng": 9.9311
            },
            "Schwarzenbruck": {
                "lat": 49.35,
                "lng": 11.2333
            },
            "Ueckerm\u00fcnde": {
                "lat": 53.7389,
                "lng": 14.0444
            },
            "Bad Rothenfelde": {
                "lat": 52.1117,
                "lng": 8.1606
            },
            "Haibach": {
                "lat": 49.9675,
                "lng": 9.1969
            },
            "Viechtach": {
                "lat": 49.0792,
                "lng": 12.8847
            },
            "Heroldsberg": {
                "lat": 49.5333,
                "lng": 11.15
            },
            "Staufenberg": {
                "lat": 50.6667,
                "lng": 8.7167
            },
            "Wissen": {
                "lat": 50.7825,
                "lng": 7.735
            },
            "Siegsdorf": {
                "lat": 47.8167,
                "lng": 12.65
            },
            "Bad Endorf": {
                "lat": 47.9,
                "lng": 12.3
            },
            "M\u00fccheln": {
                "lat": 51.3,
                "lng": 11.8
            },
            "Kandern": {
                "lat": 47.7167,
                "lng": 7.6667
            },
            "Rottenburg an der Laaber": {
                "lat": 48.7019,
                "lng": 12.0272
            },
            "Nonnweiler": {
                "lat": 49.6167,
                "lng": 6.95
            },
            "M\u00f6ckm\u00fchl": {
                "lat": 49.3167,
                "lng": 9.35
            },
            "Woltersdorf": {
                "lat": 52.4478,
                "lng": 13.7572
            },
            "Neuenb\u00fcrg": {
                "lat": 48.8461,
                "lng": 8.5889
            },
            "Reichelsheim": {
                "lat": 49.7167,
                "lng": 8.85
            },
            "M\u00f6ser": {
                "lat": 52.2167,
                "lng": 11.8
            },
            "Schnaittach": {
                "lat": 49.5592,
                "lng": 11.3431
            },
            "Uedem": {
                "lat": 51.6675,
                "lng": 6.275
            },
            "Staufen im Breisgau": {
                "lat": 47.8814,
                "lng": 7.7314
            },
            "Erfurt": {
                "lat": 50.9781,
                "lng": 11.0289
            }
        }
    },
    "AF": {
        "name": "Afghanistan",
        "cities": {
            "Kabul": {
                "lat": 34.5253,
                "lng": 69.1783
            },
            "Kandah\u0101r": {
                "lat": 31.62,
                "lng": 65.7158
            },
            "Her\u0101t": {
                "lat": 34.3419,
                "lng": 62.2031
            },
            "Far\u0101h": {
                "lat": 32.3436,
                "lng": 62.1194
            },
            "Maz\u0101r-e Shar\u012bf": {
                "lat": 36.7,
                "lng": 67.1167
            },
            "Lashkar G\u0101h": {
                "lat": 31.5831,
                "lng": 64.3692
            },
            "Kunduz": {
                "lat": 36.7286,
                "lng": 68.8681
            },
            "T\u0101luq\u0101n": {
                "lat": 36.7167,
                "lng": 69.5167
            },
            "Jal\u0101l\u0101b\u0101d": {
                "lat": 34.4342,
                "lng": 70.4478
            },
            "Shibirgh\u0101n": {
                "lat": 36.665,
                "lng": 65.752
            },
            "Zaranj": {
                "lat": 30.96,
                "lng": 61.86
            },
            "Kh\u014dst": {
                "lat": 33.3331,
                "lng": 69.9169
            },
            "Ma\u012bmanah": {
                "lat": 35.9333,
                "lng": 64.75
            },
            "Mehtar L\u0101m": {
                "lat": 34.6683,
                "lng": 70.2089
            },
            "Ghazn\u012b": {
                "lat": 33.5492,
                "lng": 68.4233
            },
            "Paghm\u0101n": {
                "lat": 34.5833,
                "lng": 68.95
            },
            "Pul-e Khumr\u012b": {
                "lat": 35.95,
                "lng": 68.7
            },
            "B\u0101my\u0101n": {
                "lat": 34.825,
                "lng": 67.8333
            },
            "Ch\u0101r\u012bk\u0101r": {
                "lat": 35.0131,
                "lng": 69.1689
            },
            "Balkh": {
                "lat": 36.7581,
                "lng": 66.8981
            },
            "Baghl\u0101n": {
                "lat": 36.1328,
                "lng": 68.7
            },
            "Tar\u012bn K\u014d\u1e6f": {
                "lat": 32.6267,
                "lng": 65.8733
            },
            "Gard\u0113z": {
                "lat": 33.6,
                "lng": 69.2169
            },
            "B\u0101z\u0101r-e Yak\u0101wlang": {
                "lat": 34.7333,
                "lng": 66.9667
            },
            "Fa\u012b\u1e95\u0101b\u0101d": {
                "lat": 37.1166,
                "lng": 70.58
            },
            "Qal\u2018ah-ye Now": {
                "lat": 34.9867,
                "lng": 63.1292
            },
            "Gh\u014driy\u0101n": {
                "lat": 34.34,
                "lng": 61.47
            },
            "Sar-e Pul": {
                "lat": 36.2214,
                "lng": 65.9278
            },
            "Haskah M\u0113nah": {
                "lat": 34.1,
                "lng": 70.4667
            },
            "Ma\u1e29m\u016bd-e R\u0101q\u012b": {
                "lat": 35.0206,
                "lng": 69.3306
            },
            "\u0100qchah": {
                "lat": 36.9114,
                "lng": 66.1858
            },
            "Qal\u0101t": {
                "lat": 32.1061,
                "lng": 66.9069
            },
            "Asad\u0101b\u0101d": {
                "lat": 34.8742,
                "lng": 71.1528
            },
            "G\u016bd\u0101rah": {
                "lat": 34.8169,
                "lng": 69.0878
            },
            "Andkh\u014dy": {
                "lat": 36.95,
                "lng": 65.1167
            },
            "K\u014d\u1e6fah-ye \u2018As\u0332h\u0332r\u014d": {
                "lat": 34.4492,
                "lng": 68.7939
            },
            "Ma\u012bd\u0101n Shahr": {
                "lat": 34.3972,
                "lng": 68.8697
            },
            "Bagr\u0101m\u012b": {
                "lat": 34.4911,
                "lng": 69.2756
            },
            "B\u0101l\u0101 K\u014dh": {
                "lat": 36.5753,
                "lng": 66.8203
            },
            "Khulm": {
                "lat": 36.6833,
                "lng": 67.6833
            },
            "Kh\u0101n\u0101b\u0101d": {
                "lat": 36.6831,
                "lng": 69.1636
            },
            "\u1e28uk\u016bmat\u012b Baghr\u0101n": {
                "lat": 33.0669,
                "lng": 65.0917
            },
            "B\u0101z\u0101rak": {
                "lat": 35.3128,
                "lng": 69.5158
            },
            "Sheghn\u0101n": {
                "lat": 37.6167,
                "lng": 71.45
            },
            "Pul-e \u2018Alam": {
                "lat": 33.9808,
                "lng": 69.035
            },
            "Barak\u012b Barak": {
                "lat": 33.9692,
                "lng": 68.9447
            },
            "Sang\u012bn": {
                "lat": 32.0733,
                "lng": 64.8339
            },
            "Qal\u2018ah-ye Z\u0101l": {
                "lat": 37.015,
                "lng": 68.4672
            },
            "Karukh": {
                "lat": 34.4922,
                "lng": 62.5922
            },
            "Kushk": {
                "lat": 33.2956,
                "lng": 61.9522
            },
            "Qarq\u012bn": {
                "lat": 37.4128,
                "lng": 66.0472
            },
            "Isl\u0101m Qal\u2018ah": {
                "lat": 34.6667,
                "lng": 61.0667
            },
            "Za\u1e5fah Sharan": {
                "lat": 33.13,
                "lng": 68.78
            },
            "Fayr\u014dz K\u014dh": {
                "lat": 34.5225,
                "lng": 65.2517
            },
            "Taywarah": {
                "lat": 33.51,
                "lng": 64.42
            },
            "Barak\u012b": {
                "lat": 33.9333,
                "lng": 68.9167
            },
            "Zargh\u016bn Shahr": {
                "lat": 32.85,
                "lng": 68.4167
            },
            "Kuhs\u0101n": {
                "lat": 34.65,
                "lng": 61.1667
            },
            "Ishk\u0101shim": {
                "lat": 36.7092,
                "lng": 71.5739
            },
            "\u1e28uk\u016bmat\u012b G\u012bz\u0101b": {
                "lat": 33.3813,
                "lng": 66.2655
            },
            "Tujg": {
                "lat": 32.0686,
                "lng": 61.8058
            },
            "A\u012bbak": {
                "lat": 36.2653,
                "lng": 68.0167
            },
            "Panj\u0101b": {
                "lat": 34.3833,
                "lng": 67.0167
            },
            "Im\u0101m \u015e\u0101\u1e29ib": {
                "lat": 37.1844,
                "lng": 68.9144
            },
            "M\u0101m\u0101 Kh\u0113l": {
                "lat": 34.25,
                "lng": 70.01
            },
            "Deh-e Sh\u016b": {
                "lat": 30.4344,
                "lng": 63.3183
            },
            "Ch\u012bchkah": {
                "lat": 37.0706,
                "lng": 69.2983
            },
            "P\u0101r\u016bn": {
                "lat": 35.4167,
                "lng": 70.9169
            },
            "Sharan": {
                "lat": 33.1757,
                "lng": 68.7304
            },
            "N\u012bl\u012b": {
                "lat": 33.7218,
                "lng": 66.1302
            }
        }
    },
    "ML": {
        "name": "Mali",
        "cities": {
            "Bamako": {
                "lat": 12.6392,
                "lng": -8.0028
            },
            "Balandougou": {
                "lat": 13.3558,
                "lng": -9.5517
            },
            "Z\u00e9goua": {
                "lat": 10.4875,
                "lng": -5.6536
            },
            "Sikasso": {
                "lat": 11.3167,
                "lng": -5.6667
            },
            "Koutiala": {
                "lat": 12.3833,
                "lng": -5.4667
            },
            "S\u00e9gou": {
                "lat": 13.45,
                "lng": -6.2667
            },
            "Kayes": {
                "lat": 14.45,
                "lng": -11.4333
            },
            "Mopti": {
                "lat": 14.49,
                "lng": -4.18
            },
            "Niono": {
                "lat": 14.25,
                "lng": -5.9861
            },
            "Somasso": {
                "lat": 12.8667,
                "lng": -5.6
            },
            "Kati": {
                "lat": 12.7504,
                "lng": -8.08
            },
            "Koro": {
                "lat": 14.0683,
                "lng": -3.0814
            },
            "Nioro": {
                "lat": 15.1833,
                "lng": -9.55
            },
            "Bougouni": {
                "lat": 11.4167,
                "lng": -7.4833
            },
            "Baguin\u00e9da": {
                "lat": 12.6153,
                "lng": -7.7767
            },
            "Kolondi\u00e9ba": {
                "lat": 11.0833,
                "lng": -6.9
            },
            "P\u00e8l\u00e8ngana": {
                "lat": 13.4278,
                "lng": -6.2153
            },
            "Kouri": {
                "lat": 12.1833,
                "lng": -4.8056
            },
            "Kadiolo": {
                "lat": 10.5556,
                "lng": -5.7597
            },
            "Koumantou": {
                "lat": 11.4167,
                "lng": -6.8333
            },
            "Woloss\u00e9bougou": {
                "lat": 12.0,
                "lng": -7.9111
            },
            "Gao": {
                "lat": 16.2667,
                "lng": -0.05
            },
            "Dyero": {
                "lat": 13.6858,
                "lng": -5.8342
            },
            "Kita": {
                "lat": 13.0504,
                "lng": -9.4833
            },
            "Markala": {
                "lat": 13.6739,
                "lng": -6.075
            },
            "Dialakorodji": {
                "lat": 12.7083,
                "lng": -7.9689
            },
            "Sangar\u00e9bougou": {
                "lat": 12.6842,
                "lng": -7.9278
            },
            "Miss\u00e9ni": {
                "lat": 10.3167,
                "lng": -6.0769
            },
            "Massigui": {
                "lat": 11.8667,
                "lng": -6.7667
            },
            "Mondoro": {
                "lat": 14.6761,
                "lng": -1.9511
            },
            "Sokoura": {
                "lat": 13.5,
                "lng": -3.7667
            },
            "Dand\u00e9resso": {
                "lat": 11.4867,
                "lng": -5.4819
            },
            "San": {
                "lat": 13.3004,
                "lng": -4.9
            },
            "Fourou": {
                "lat": 10.7472,
                "lng": -6.1403
            },
            "Djidian K\u00e9ni\u00e9ba": {
                "lat": 13.1,
                "lng": -11.4
            },
            "Sadiola": {
                "lat": 13.8889,
                "lng": -11.7028
            },
            "S\u00e9b\u00e9koro": {
                "lat": 13.0,
                "lng": -8.0833
            },
            "Garalo": {
                "lat": 10.99,
                "lng": -7.437
            },
            "Kolongo-Bozo": {
                "lat": 13.8806,
                "lng": -5.6861
            },
            "Sanankoroba": {
                "lat": 12.3986,
                "lng": -7.9378
            },
            "Siribala": {
                "lat": 14.0419,
                "lng": -6.0617
            },
            "Kona": {
                "lat": 14.9467,
                "lng": -3.8933
            },
            "Zinzana": {
                "lat": 13.2528,
                "lng": -5.9653
            },
            "Mpessoba": {
                "lat": 12.6639,
                "lng": -5.7167
            },
            "Dinangorou": {
                "lat": 14.4519,
                "lng": -2.2419
            },
            "Niamina": {
                "lat": 13.3167,
                "lng": -6.9819
            },
            "Timbuktu": {
                "lat": 16.7758,
                "lng": -3.0094
            },
            "Diabali": {
                "lat": 14.6833,
                "lng": -6.0167
            },
            "Diakon": {
                "lat": 14.6264,
                "lng": -10.7319
            },
            "Kignan": {
                "lat": 11.8511,
                "lng": -6.0153
            },
            "Kati\u00e9na": {
                "lat": 13.3217,
                "lng": -5.6414
            },
            "Djenn\u00e9": {
                "lat": 13.9056,
                "lng": -4.555
            },
            "Moninnp\u00e9bougou": {
                "lat": 14.1475,
                "lng": -5.5217
            },
            "Barou\u00e9li": {
                "lat": 13.0667,
                "lng": -6.8333
            },
            "Ansongo": {
                "lat": 15.6594,
                "lng": 0.5017
            },
            "Lobougoula": {
                "lat": 11.0431,
                "lng": -5.9819
            },
            "Dioungani": {
                "lat": 14.3131,
                "lng": -2.7394
            },
            "Massantola": {
                "lat": 13.4833,
                "lng": -7.8
            },
            "Ni\u00e9na": {
                "lat": 11.4258,
                "lng": -6.35
            },
            "Boro": {
                "lat": 14.0075,
                "lng": -7.5072
            },
            "Dialoub\u00e9": {
                "lat": 15.0172,
                "lng": -4.2325
            },
            "Kl\u00e9la": {
                "lat": 11.6875,
                "lng": -5.6639
            },
            "Kons\u00e9gu\u00e9la": {
                "lat": 12.4033,
                "lng": -5.8847
            },
            "Di\u00e9ma": {
                "lat": 14.539,
                "lng": -9.19
            },
            "Banamba": {
                "lat": 13.55,
                "lng": -7.45
            },
            "Ouatagouna": {
                "lat": 15.1842,
                "lng": 0.7217
            },
            "S\u00e9gala Mba": {
                "lat": 14.0625,
                "lng": -10.9722
            },
            "Falou": {
                "lat": 14.5978,
                "lng": -7.9319
            },
            "Bankass": {
                "lat": 14.0667,
                "lng": -3.5167
            },
            "Bour\u00e8m Guindou": {
                "lat": 16.9004,
                "lng": -0.35
            },
            "Didi\u00e9ni": {
                "lat": 13.8833,
                "lng": -8.1
            },
            "Dougabougou": {
                "lat": 13.8186,
                "lng": -6.1189
            },
            "Moribabougou": {
                "lat": 12.6917,
                "lng": -7.8644
            },
            "Bamba": {
                "lat": 17.0333,
                "lng": -1.4
            },
            "Diangout\u00e9 Kamara": {
                "lat": 14.545,
                "lng": -9.513
            },
            "Douentza": {
                "lat": 15.0,
                "lng": -2.95
            },
            "Sirakorola": {
                "lat": 13.2833,
                "lng": -7.5667
            },
            "Sitakili": {
                "lat": 13.1075,
                "lng": -11.2286
            },
            "Bourem": {
                "lat": 16.9533,
                "lng": -0.3539
            },
            "Togu\u00e9r\u00e9-Koumb\u00e9": {
                "lat": 14.9181,
                "lng": -4.5933
            },
            "Mahina": {
                "lat": 13.7583,
                "lng": -10.8486
            },
            "Sibi": {
                "lat": 12.3786,
                "lng": -8.3333
            },
            "Kabo\u00efla": {
                "lat": 11.2136,
                "lng": -5.6347
            },
            "Gourma Rharous": {
                "lat": 16.88,
                "lng": -1.9238
            },
            "Konobougou": {
                "lat": 12.9167,
                "lng": -6.7667
            },
            "B\u00e9ma": {
                "lat": 15.044,
                "lng": -9.354
            },
            "Bandiagara": {
                "lat": 14.35,
                "lng": -3.6111
            },
            "Fatim\u00e9": {
                "lat": 13.5761,
                "lng": -5.3833
            },
            "Koumia": {
                "lat": 12.5958,
                "lng": -4.6903
            },
            "Gouna": {
                "lat": 10.9125,
                "lng": -8.0
            },
            "Madougou": {
                "lat": 14.3992,
                "lng": -3.0789
            },
            "Fatoma": {
                "lat": 14.6122,
                "lng": -4.0614
            },
            "Ngorkou": {
                "lat": 15.6592,
                "lng": -3.7156
            },
            "Kenndi\u00e9": {
                "lat": 14.675,
                "lng": -3.5111
            },
            "Sanando": {
                "lat": 12.9667,
                "lng": -6.4
            },
            "Koulikoro": {
                "lat": 12.8833,
                "lng": -7.55
            },
            "Ourikela": {
                "lat": 12.1106,
                "lng": -5.0625
            },
            "K\u00e9bila": {
                "lat": 11.2833,
                "lng": -7.0333
            },
            "Sokolo": {
                "lat": 14.7389,
                "lng": -6.1211
            },
            "Kouoro": {
                "lat": 11.9681,
                "lng": -5.6886
            },
            "Sansanding": {
                "lat": 13.7267,
                "lng": -6.0058
            },
            "Hombori": {
                "lat": 15.28,
                "lng": -1.7
            },
            "Koussan\u00e9": {
                "lat": 14.8847,
                "lng": -11.2333
            },
            "Youwarou": {
                "lat": 15.375,
                "lng": -4.2625
            },
            "Kamabougou": {
                "lat": 12.4833,
                "lng": -6.8
            },
            "Dialakoroba": {
                "lat": 12.2628,
                "lng": -7.9269
            },
            "Boura": {
                "lat": 12.4139,
                "lng": -4.5442
            },
            "Finnkolo": {
                "lat": 11.2681,
                "lng": -5.5111
            },
            "Sanso": {
                "lat": 11.7167,
                "lng": -6.85
            },
            "Yorosso": {
                "lat": 12.3556,
                "lng": -4.7778
            },
            "Dioumanzana": {
                "lat": 12.5972,
                "lng": -7.3061
            },
            "Gaviman\u00e9": {
                "lat": 15.1167,
                "lng": -9.9
            },
            "Bankoumana": {
                "lat": 12.2047,
                "lng": -8.2667
            },
            "Zanti\u00e9bougou": {
                "lat": 11.4,
                "lng": -7.25
            },
            "Kont\u00e9la": {
                "lat": 14.0394,
                "lng": -10.3667
            },
            "Kokofata": {
                "lat": 12.8825,
                "lng": -9.9458
            },
            "Walia": {
                "lat": 13.6,
                "lng": -10.3833
            },
            "M\u00e9naka": {
                "lat": 15.9167,
                "lng": 2.4
            },
            "Soye": {
                "lat": 14.2306,
                "lng": -4.2792
            },
            "Fangasso": {
                "lat": 13.5333,
                "lng": -4.45
            },
            "Touna": {
                "lat": 13.1167,
                "lng": -5.8333
            },
            "Diondiori": {
                "lat": 14.6119,
                "lng": -4.7742
            },
            "Bafoulab\u00e9": {
                "lat": 13.8083,
                "lng": -10.8306
            },
            "Guir\u00e9": {
                "lat": 14.6417,
                "lng": -6.6917
            },
            "Sangasso": {
                "lat": 12.1569,
                "lng": -5.6194
            },
            "Dorou": {
                "lat": 14.3,
                "lng": -3.4333
            },
            "Sar\u00e9-Yamou": {
                "lat": 16.0897,
                "lng": -3.2353
            },
            "Kassama": {
                "lat": 13.0564,
                "lng": -11.1167
            },
            "Sibila": {
                "lat": 13.7778,
                "lng": -5.8778
            },
            "Kouloum": {
                "lat": 14.45,
                "lng": -11.3708
            },
            "Diallassagou": {
                "lat": 13.7333,
                "lng": -3.6333
            },
            "Koporo-K\u00e9ni\u00e9-na": {
                "lat": 14.1308,
                "lng": -3.3539
            },
            "Djidian": {
                "lat": 13.2017,
                "lng": -9.4553
            },
            "Ty\u00e8l\u00e8": {
                "lat": 12.3028,
                "lng": -7.5681
            },
            "And\u00e9ranboukan": {
                "lat": 15.4283,
                "lng": 3.0183
            },
            "Nara": {
                "lat": 15.18,
                "lng": -7.28
            },
            "Ngolonianasso": {
                "lat": 12.4222,
                "lng": -5.6833
            },
            "Kassaro": {
                "lat": 12.9544,
                "lng": -8.8811
            },
            "Sandar\u00e9": {
                "lat": 14.7,
                "lng": -10.3
            },
            "Nossombougou": {
                "lat": 13.1,
                "lng": -7.9333
            },
            "Sakou\u00e9ba": {
                "lat": 13.3119,
                "lng": -6.28
            },
            "Blindio": {
                "lat": 11.6133,
                "lng": -6.3444
            },
            "Tominian": {
                "lat": 13.2833,
                "lng": -4.5833
            },
            "Ouankoro": {
                "lat": 13.3833,
                "lng": -3.8333
            },
            "Souba": {
                "lat": 13.4139,
                "lng": -6.5972
            },
            "Koula": {
                "lat": 13.1167,
                "lng": -7.65
            },
            "Segu\u00e9": {
                "lat": 13.8333,
                "lng": -3.75
            },
            "Dialafara": {
                "lat": 13.4606,
                "lng": -11.3786
            },
            "Fal\u00e9a": {
                "lat": 12.2647,
                "lng": -11.275
            },
            "Dou\u00e9tir\u00e9": {
                "lat": 16.5736,
                "lng": -3.3719
            },
            "Kanrangana": {
                "lat": 12.2208,
                "lng": -5.0333
            },
            "Z\u00e9bala": {
                "lat": 12.31,
                "lng": -5.1569
            },
            "Kangaba": {
                "lat": 11.9333,
                "lng": -8.4167
            },
            "Sinsina": {
                "lat": 12.3517,
                "lng": -5.4417
            },
            "Boki-W\u00e9r\u00e9": {
                "lat": 14.0106,
                "lng": -5.6033
            },
            "Bambara-Maound\u00e9": {
                "lat": 15.8519,
                "lng": -2.7861
            },
            "Konodimini": {
                "lat": 13.3239,
                "lng": -6.4228
            },
            "Soumpi": {
                "lat": 15.8458,
                "lng": -4.3217
            },
            "Kassorola": {
                "lat": 12.7556,
                "lng": -4.9742
            },
            "Goundam": {
                "lat": 16.4167,
                "lng": -3.6667
            },
            "S\u00e9goubougou": {
                "lat": 13.4181,
                "lng": -6.3069
            },
            "Mafun\u00e9": {
                "lat": 12.7833,
                "lng": -4.3667
            },
            "Diabigu\u00e9": {
                "lat": 15.15,
                "lng": -9.3167
            },
            "L\u00e9r\u00e9": {
                "lat": 15.7153,
                "lng": -4.9042
            },
            "Safo": {
                "lat": 12.7686,
                "lng": -7.9286
            },
            "Guioyo": {
                "lat": 13.5,
                "lng": -8.25
            },
            "Mandiakui": {
                "lat": 13.0167,
                "lng": -4.4667
            },
            "Lakaman\u00e9": {
                "lat": 14.506,
                "lng": -9.907
            },
            "Diafarab\u00e9": {
                "lat": 14.1389,
                "lng": -5.0189
            },
            "Namala Guimbala": {
                "lat": 13.3642,
                "lng": -9.3864
            },
            "Kifosso": {
                "lat": 12.5042,
                "lng": -4.9028
            },
            "Nkouraba": {
                "lat": 12.425,
                "lng": -7.4903
            },
            "Sagala": {
                "lat": 14.1103,
                "lng": -7.8881
            },
            "Nonkon": {
                "lat": 13.2167,
                "lng": -7.85
            },
            "Bangassi-Nangou": {
                "lat": 14.4933,
                "lng": -11.5067
            },
            "Dougouni": {
                "lat": 12.1436,
                "lng": -6.0103
            },
            "Doumanaba": {
                "lat": 11.6692,
                "lng": -5.9306
            },
            "Kidal": {
                "lat": 18.4389,
                "lng": 1.4083
            },
            "Lambidou": {
                "lat": 14.3136,
                "lng": -9.5515
            },
            "Ngolobougou": {
                "lat": 12.1733,
                "lng": -6.7089
            },
            "Tamani": {
                "lat": 13.1167,
                "lng": -7.8333
            },
            "Kouma\u00efra": {
                "lat": 15.7864,
                "lng": -3.8642
            },
            "Timissa": {
                "lat": 13.8167,
                "lng": -4.2
            },
            "Konina": {
                "lat": 12.4792,
                "lng": -6.1778
            },
            "Diankabou": {
                "lat": 14.5833,
                "lng": -3.0833
            },
            "Youdiou": {
                "lat": 14.2833,
                "lng": -3.1667
            },
            "Pinia": {
                "lat": 14.26,
                "lng": -3.9511
            },
            "Di\u00e9ramana": {
                "lat": 12.85,
                "lng": -5.4
            },
            "Yangasso": {
                "lat": 13.0667,
                "lng": -5.3167
            },
            "Y\u00e9r\u00e9r\u00e9": {
                "lat": 15.259,
                "lng": -9.427
            },
            "Miena": {
                "lat": 12.6306,
                "lng": -5.9972
            },
            "Fakola": {
                "lat": 10.5444,
                "lng": -6.9147
            },
            "A\u00efbongo": {
                "lat": 16.2231,
                "lng": -3.245
            },
            "Toubakoro": {
                "lat": 13.8833,
                "lng": -7.1833
            },
            "Koundian": {
                "lat": 13.1597,
                "lng": -10.6806
            },
            "Sibirila": {
                "lat": 11.4272,
                "lng": -7.7667
            },
            "Kambila": {
                "lat": 12.7967,
                "lng": -8.1033
            },
            "Sido": {
                "lat": 11.6653,
                "lng": -7.5997
            },
            "Dia\u00ef Koura": {
                "lat": 15.18,
                "lng": -9.984
            },
            "Bamaf\u00e9l\u00e9": {
                "lat": 13.1,
                "lng": -10.4
            },
            "Koubel-Koundia": {
                "lat": 14.9861,
                "lng": -3.0339
            },
            "Kokri": {
                "lat": 13.9606,
                "lng": -5.5153
            },
            "Sanankoro Djitoumou": {
                "lat": 12.055,
                "lng": -7.7061
            },
            "Dinb\u00e9la": {
                "lat": 11.7278,
                "lng": -6.3611
            },
            "Kani-Bonzon": {
                "lat": 14.1403,
                "lng": -3.6183
            },
            "Danga": {
                "lat": 16.465,
                "lng": -3.1364
            },
            "Niandjila": {
                "lat": 11.7975,
                "lng": -6.5447
            },
            "Sam\u00e9": {
                "lat": 14.4833,
                "lng": -11.5667
            },
            "Toukoroba": {
                "lat": 13.6083,
                "lng": -7.0389
            },
            "Dianguird\u00e9": {
                "lat": 14.4886,
                "lng": -9.0161
            },
            "Diank\u00e9": {
                "lat": 15.7333,
                "lng": -4.65
            },
            "Nangola": {
                "lat": 12.6667,
                "lng": -6.6
            },
            "Di\u00e9li": {
                "lat": 13.0667,
                "lng": -5.1167
            },
            "Diamou": {
                "lat": 12.1469,
                "lng": -7.2544
            },
            "Kobiri": {
                "lat": 13.1183,
                "lng": -9.8997
            },
            "Gogui": {
                "lat": 15.6833,
                "lng": -9.3333
            },
            "Madiama": {
                "lat": 13.8,
                "lng": -4.4
            },
            "Kadiana": {
                "lat": 10.75,
                "lng": -6.5
            },
            "Bouss\u00e9": {
                "lat": 13.5069,
                "lng": -5.9361
            },
            "Bourem Inali": {
                "lat": 16.6883,
                "lng": -2.8528
            },
            "Soubala": {
                "lat": 13.8633,
                "lng": -3.5019
            },
            "Tioribougou": {
                "lat": 13.3833,
                "lng": -7.9833
            },
            "Dir\u00e9": {
                "lat": 16.2561,
                "lng": -3.41
            },
            "Niagadina": {
                "lat": 12.1333,
                "lng": -8.2319
            },
            "Mountougoula": {
                "lat": 12.5297,
                "lng": -7.8222
            },
            "Barapir\u00e9": {
                "lat": 14.3053,
                "lng": -3.0517
            },
            "Alahina": {
                "lat": 15.2275,
                "lng": -8.7403
            },
            "Lessogou": {
                "lat": 13.8167,
                "lng": -3.6
            },
            "Doukombo": {
                "lat": 14.355,
                "lng": -3.6561
            },
            "Sinkolo": {
                "lat": 12.0875,
                "lng": -5.55
            },
            "Tourougoumb\u00e9": {
                "lat": 15.266,
                "lng": -9.229
            },
            "Pemmp\u00e9r\u00e9na": {
                "lat": 11.4306,
                "lng": -5.7542
            },
            "T\u00e9nenkou": {
                "lat": 14.4556,
                "lng": -4.9181
            },
            "Dimmbal": {
                "lat": 14.0167,
                "lng": -3.6167
            },
            "Niasso": {
                "lat": 12.6333,
                "lng": -4.5333
            },
            "Kourouma": {
                "lat": 11.8806,
                "lng": -5.8375
            },
            "Kassa": {
                "lat": 14.7247,
                "lng": -3.0664
            },
            "Sanzana": {
                "lat": 11.7486,
                "lng": -5.95
            },
            "Tambaga": {
                "lat": 12.9689,
                "lng": -9.8728
            },
            "Sorontona": {
                "lat": 13.0667,
                "lng": -4.9
            },
            "Nanpala": {
                "lat": 15.2792,
                "lng": -5.5528
            },
            "Dabiya": {
                "lat": 12.6714,
                "lng": -11.1375
            },
            "Matomou": {
                "lat": 13.85,
                "lng": -4.9667
            },
            "Bougoula": {
                "lat": 12.3081,
                "lng": -7.7922
            },
            "Gori": {
                "lat": 15.1089,
                "lng": -10.7581
            },
            "Bendougouba": {
                "lat": 13.0569,
                "lng": -9.3956
            },
            "Ouolodo": {
                "lat": 13.2,
                "lng": -7.9333
            },
            "Folomana": {
                "lat": 13.6028,
                "lng": -5.1542
            },
            "Kr\u00e8miss": {
                "lat": 15.3506,
                "lng": -10.3458
            },
            "Minanba": {
                "lat": 12.3956,
                "lng": -4.6733
            },
            "Da": {
                "lat": 13.16,
                "lng": -4.9111
            },
            "Sirakoro": {
                "lat": 12.6833,
                "lng": -9.2292
            },
            "Bougaribaya": {
                "lat": 12.8167,
                "lng": -9.8436
            },
            "Gu\u00e9moukouraba": {
                "lat": 14.2639,
                "lng": -9.3208
            },
            "Zani\u00e9na": {
                "lat": 11.2708,
                "lng": -6.4236
            },
            "Toukoto": {
                "lat": 13.45,
                "lng": -9.8792
            },
            "Mora": {
                "lat": 14.3278,
                "lng": -4.6
            },
            "Soule\u00ef": {
                "lat": 13.8778,
                "lng": -5.2425
            },
            "Ntorosso": {
                "lat": 12.3167,
                "lng": -4.95
            },
            "Dandoli": {
                "lat": 14.3981,
                "lng": -3.545
            },
            "Dindanko": {
                "lat": 14.1233,
                "lng": -9.5031
            },
            "Samabouro": {
                "lat": 13.0167,
                "lng": -6.3833
            },
            "Kiban": {
                "lat": 13.5833,
                "lng": -7.35
            },
            "Farako": {
                "lat": 11.3583,
                "lng": -6.5797
            },
            "Wakoro": {
                "lat": 12.5994,
                "lng": -6.69
            },
            "D\u00e9ny\u00e9koro": {
                "lat": 12.35,
                "lng": -6.6667
            },
            "Banikane": {
                "lat": 16.9494,
                "lng": -1.7481
            },
            "Diouna": {
                "lat": 13.4325,
                "lng": -5.8194
            },
            "Fatao": {
                "lat": 14.326,
                "lng": -9.532
            },
            "Wa": {
                "lat": 13.7,
                "lng": -4.4
            },
            "Banir\u00e9 Kor\u00e9": {
                "lat": 15.2072,
                "lng": -9.1517
            },
            "M\u00e9ridiala": {
                "lat": 12.0917,
                "lng": -7.2592
            },
            "Togou": {
                "lat": 13.5917,
                "lng": -5.9986
            },
            "Ka\u00ef": {
                "lat": 10.9694,
                "lng": -5.6944
            },
            "Baramandougou": {
                "lat": 13.6,
                "lng": -4.6
            },
            "Ntossoni": {
                "lat": 12.5311,
                "lng": -5.7714
            },
            "Gendou": {
                "lat": 12.8292,
                "lng": -6.4964
            },
            "In\u00e9kar": {
                "lat": 15.9492,
                "lng": 3.1592
            },
            "Moribila": {
                "lat": 12.6667,
                "lng": -5.05
            },
            "Tougouni": {
                "lat": 13.2167,
                "lng": -7.2
            },
            "Dougouf\u00e9": {
                "lat": 13.315,
                "lng": -6.6311
            },
            "Dala": {
                "lat": 15.1069,
                "lng": -2.64
            },
            "Aguelhok": {
                "lat": 19.465,
                "lng": 0.856
            },
            "Tessalit": {
                "lat": 20.2011,
                "lng": 1.0125
            },
            "Taoudenni": {
                "lat": 22.6667,
                "lng": -3.9833
            },
            "Y\u00e9liman\u00e9": {
                "lat": 15.118,
                "lng": -10.572
            },
            "Satadougou": {
                "lat": 12.617,
                "lng": -11.4066
            },
            "Araouane": {
                "lat": 18.905,
                "lng": -3.5283
            }
        }
    },
    "JO": {
        "name": "Jordan",
        "cities": {
            "Amman": {
                "lat": 31.9497,
                "lng": 35.9328
            },
            "Al Mafraq": {
                "lat": 32.3399,
                "lng": 36.2052
            },
            "Irbid": {
                "lat": 32.55,
                "lng": 35.85
            },
            "Az Zarq\u0101\u2019": {
                "lat": 32.0833,
                "lng": 36.1
            },
            "Ar Ru\u015fayfah": {
                "lat": 32.0178,
                "lng": 36.0464
            },
            "Al Juwayyidah": {
                "lat": 31.9167,
                "lng": 35.95
            },
            "Sa\u1e29\u0101b": {
                "lat": 31.8667,
                "lng": 36.0
            },
            "Ar Ramth\u0101": {
                "lat": 32.5589,
                "lng": 36.0147
            },
            "\u2018Ajl\u016bn": {
                "lat": 32.3325,
                "lng": 35.7517
            },
            "Al \u2018Aqabah": {
                "lat": 29.5319,
                "lng": 35.0056
            },
            "Al J\u012bzah": {
                "lat": 31.6833,
                "lng": 35.95
            },
            "As Sal\u0163": {
                "lat": 32.0333,
                "lng": 35.7333
            },
            "M\u0101dab\u0101": {
                "lat": 31.7167,
                "lng": 35.8
            },
            "Jarash": {
                "lat": 32.2806,
                "lng": 35.8972
            },
            "Ma\u2018\u0101n": {
                "lat": 30.1962,
                "lng": 35.7341
            },
            "Al \u1e28i\u015fn": {
                "lat": 32.4583,
                "lng": 35.8583
            },
            "A\u0163 \u0162urrah": {
                "lat": 32.6368,
                "lng": 35.99
            },
            "Dayr Ab\u016b Sa\u2018\u012bd": {
                "lat": 32.5025,
                "lng": 35.6922
            },
            "Ash Shajarah": {
                "lat": 32.6417,
                "lng": 35.9417
            },
            "Mu\u2019tah": {
                "lat": 31.1,
                "lng": 35.7
            },
            "Ash Sh\u016bnah ash Sham\u0101l\u012byah": {
                "lat": 32.61,
                "lng": 35.6081
            },
            "A\u0163 \u0162af\u012blah": {
                "lat": 30.84,
                "lng": 35.6
            },
            "Al Fu\u1e29ay\u015f": {
                "lat": 32.0167,
                "lng": 35.7667
            },
            "Al Karak": {
                "lat": 31.1833,
                "lng": 35.7
            },
            "\u015eamm\u0101": {
                "lat": 32.57,
                "lng": 35.69
            },
            "M\u0101\u1e29i\u015f": {
                "lat": 31.9833,
                "lng": 35.7667
            },
            "Al Maz\u0101r ash Sham\u0101l\u012b": {
                "lat": 32.4725,
                "lng": 35.7928
            },
            "Muthallath al Azraq": {
                "lat": 31.8342,
                "lng": 36.815
            },
            "Kafr \u2018Aw\u0101n": {
                "lat": 32.4167,
                "lng": 35.6833
            },
            "Malk\u0101": {
                "lat": 32.6653,
                "lng": 35.7333
            },
            "S\u0101kib": {
                "lat": 32.2854,
                "lng": 35.8113
            },
            "Bu\u015fayr\u0101": {
                "lat": 30.7389,
                "lng": 35.6072
            },
            "\u1e28al\u0101wah": {
                "lat": 32.3828,
                "lng": 35.6619
            }
        }
    },
    "MA": {
        "name": "Morocco",
        "cities": {
            "Casablanca": {
                "lat": 33.5333,
                "lng": -7.5833
            },
            "F\u00e8s": {
                "lat": 34.0433,
                "lng": -5.0033
            },
            "Marrakech": {
                "lat": 31.63,
                "lng": -8.0089
            },
            "Tangier": {
                "lat": 35.7767,
                "lng": -5.8039
            },
            "Sale": {
                "lat": 34.0333,
                "lng": -6.8
            },
            "Rabat": {
                "lat": 34.0209,
                "lng": -6.8416
            },
            "Mekn\u00e8s": {
                "lat": 33.895,
                "lng": -5.5547
            },
            "Oujda-Angad": {
                "lat": 34.6867,
                "lng": -1.9114
            },
            "Kenitra": {
                "lat": 34.25,
                "lng": -6.5833
            },
            "Agadir": {
                "lat": 30.4333,
                "lng": -9.6
            },
            "T\u00e9touan": {
                "lat": 35.5667,
                "lng": -5.3667
            },
            "Taourirt": {
                "lat": 34.4169,
                "lng": -2.885
            },
            "Temara": {
                "lat": 33.9267,
                "lng": -6.9122
            },
            "Safi": {
                "lat": 32.2833,
                "lng": -9.2333
            },
            "Kh\u00e9nifra": {
                "lat": 32.9394,
                "lng": -5.6675
            },
            "El Jadid": {
                "lat": 33.2333,
                "lng": -8.5
            },
            "La\u00e2youne": {
                "lat": 27.1536,
                "lng": -13.2033
            },
            "Mohammedia": {
                "lat": 33.6833,
                "lng": -7.3833
            },
            "Kouribga": {
                "lat": 32.8833,
                "lng": -6.9167
            },
            "B\u00e9ni Mellal": {
                "lat": 32.3394,
                "lng": -6.3608
            },
            "Ait Melloul": {
                "lat": 30.3342,
                "lng": -9.4972
            },
            "Nador": {
                "lat": 35.1667,
                "lng": -2.9333
            },
            "Taza": {
                "lat": 35.0639,
                "lng": -5.2025
            },
            "Settat": {
                "lat": 33.0,
                "lng": -7.6167
            },
            "Barrechid": {
                "lat": 33.2667,
                "lng": -7.5833
            },
            "Al Khmissat": {
                "lat": 33.8167,
                "lng": -6.0667
            },
            "Inezgane": {
                "lat": 30.3658,
                "lng": -9.5381
            },
            "Ksar El Kebir": {
                "lat": 35.009,
                "lng": -5.9
            },
            "My Drarga": {
                "lat": 30.38,
                "lng": -9.48
            },
            "Larache": {
                "lat": 35.1833,
                "lng": -6.15
            },
            "Guelmim": {
                "lat": 28.9833,
                "lng": -10.0667
            },
            "Berkane": {
                "lat": 34.9167,
                "lng": -2.3167
            },
            "Ad Dakhla": {
                "lat": 23.7081,
                "lng": -15.9456
            },
            "Bouskoura": {
                "lat": 33.4489,
                "lng": -7.6486
            },
            "Al Fqih Ben \u00c7alah": {
                "lat": 32.5,
                "lng": -6.5333
            },
            "Oued Zem": {
                "lat": 32.8667,
                "lng": -6.5667
            },
            "Sidi Slimane": {
                "lat": 34.26,
                "lng": -5.92
            },
            "Errachidia": {
                "lat": 31.9319,
                "lng": -4.4244
            },
            "Guercif": {
                "lat": 34.2333,
                "lng": -3.3667
            },
            "Oulad Te\u00efma": {
                "lat": 30.4,
                "lng": -9.2167
            },
            "Ben Guerir": {
                "lat": 32.23,
                "lng": -7.95
            },
            "Wislane": {
                "lat": 30.225,
                "lng": -8.3801
            },
            "Tiflet": {
                "lat": 33.8931,
                "lng": -6.3069
            },
            "Lqoliaa": {
                "lat": 30.2908,
                "lng": -9.4661
            },
            "Taroudannt": {
                "lat": 30.471,
                "lng": -8.8806
            },
            "Sefrou": {
                "lat": 33.83,
                "lng": -4.83
            },
            "Essaouira": {
                "lat": 31.5131,
                "lng": -9.7697
            },
            "Fnidq": {
                "lat": 35.85,
                "lng": -5.35
            },
            "Ait Ali": {
                "lat": 30.1765,
                "lng": -9.4886
            },
            "Sidi Qacem": {
                "lat": 34.2167,
                "lng": -5.7
            },
            "Tiznit": {
                "lat": 29.7167,
                "lng": -9.7167
            },
            "Moulay Abdallah": {
                "lat": 33.1978,
                "lng": -8.5883
            },
            "Tan-Tan": {
                "lat": 28.4333,
                "lng": -11.1
            },
            "Warzat": {
                "lat": 30.9167,
                "lng": -6.9167
            },
            "Youssoufia": {
                "lat": 32.25,
                "lng": -8.5333
            },
            "Sa\u2019ada": {
                "lat": 31.621,
                "lng": -8.1303
            },
            "Martil": {
                "lat": 35.6167,
                "lng": -5.2667
            },
            "A\u00efn Harrouda": {
                "lat": 33.6372,
                "lng": -7.4483
            },
            "Souq Sebt Oulad Nemma": {
                "lat": 32.2948,
                "lng": -6.7007
            },
            "Skhirate": {
                "lat": 33.85,
                "lng": -7.03
            },
            "Ouezzane": {
                "lat": 34.8,
                "lng": -5.5833
            },
            "Sidi Yahya Zaer": {
                "lat": 33.7105,
                "lng": -6.8831
            },
            "Benslimane": {
                "lat": 33.6167,
                "lng": -7.1167
            },
            "Semara": {
                "lat": 26.7394,
                "lng": -11.6703
            },
            "Al Hoce\u00efma": {
                "lat": 35.2472,
                "lng": -3.9322
            },
            "Beni Enzar": {
                "lat": 35.2667,
                "lng": -2.9333
            },
            "M\u2019diq": {
                "lat": 35.6858,
                "lng": -5.3253
            },
            "Sidi Bennour": {
                "lat": 32.65,
                "lng": -8.4333
            },
            "Midalt": {
                "lat": 32.68,
                "lng": -4.73
            },
            "Douar Ain Chkef": {
                "lat": 33.9619,
                "lng": -5.0813
            },
            "Azrou": {
                "lat": 33.4417,
                "lng": -5.2247
            },
            "El Kelaa des Srarhna": {
                "lat": 32.0481,
                "lng": -7.4083
            },
            "Ain El Aouda": {
                "lat": 33.8111,
                "lng": -6.7922
            },
            "Beni Yakhlef": {
                "lat": 33.6555,
                "lng": -7.3221
            },
            "Ad Darwa": {
                "lat": 33.4167,
                "lng": -7.5333
            },
            "Al Aaroui": {
                "lat": 35.0029,
                "lng": -3.0311
            },
            "Qasbat Tadla": {
                "lat": 32.6,
                "lng": -6.2667
            },
            "Boujad": {
                "lat": 32.7667,
                "lng": -6.4
            },
            "Tinajdad": {
                "lat": 31.5,
                "lng": -5.0167
            },
            "Jerada": {
                "lat": 34.3117,
                "lng": -2.1636
            },
            "Douar Bni Malek": {
                "lat": 34.78,
                "lng": -5.6425
            },
            "Chefchaouene": {
                "lat": 35.1714,
                "lng": -5.2697
            },
            "Mrirt": {
                "lat": 33.1667,
                "lng": -5.5667
            },
            "Cabo Bojador": {
                "lat": 26.1333,
                "lng": -14.5
            },
            "Sidi Mohamed Lahmar": {
                "lat": 34.7167,
                "lng": -6.2667
            },
            "Tineghir": {
                "lat": 31.5147,
                "lng": -5.5328
            },
            "El A\u00efoun": {
                "lat": 34.5852,
                "lng": -2.5056
            },
            "Azemmour": {
                "lat": 33.2878,
                "lng": -8.3422
            },
            "Temsia": {
                "lat": 30.36,
                "lng": -9.414
            },
            "Zoumi": {
                "lat": 34.8,
                "lng": -5.35
            },
            "Douar Laouamra": {
                "lat": 31.8,
                "lng": -8.7167
            },
            "Zagora": {
                "lat": 30.3306,
                "lng": -5.8381
            },
            "Ait Ourir": {
                "lat": 31.5644,
                "lng": -7.6628
            },
            "Sidi Bibi": {
                "lat": 30.2333,
                "lng": -9.5333
            },
            "Aziylal": {
                "lat": 31.9669,
                "lng": -6.5694
            },
            "Sidi Yahia El Gharb": {
                "lat": 34.3058,
                "lng": -6.3058
            },
            "Biougra": {
                "lat": 30.2144,
                "lng": -9.3708
            },
            "Taounate": {
                "lat": 34.5358,
                "lng": -4.64
            },
            "Bouznika": {
                "lat": 33.7897,
                "lng": -7.1575
            },
            "Aourir": {
                "lat": 30.4833,
                "lng": -9.6333
            },
            "Za\u00efo": {
                "lat": 34.9333,
                "lng": -2.7333
            },
            "Aguelmous": {
                "lat": 33.15,
                "lng": -5.8333
            },
            "El Hajeb": {
                "lat": 33.6928,
                "lng": -5.3711
            },
            "Dabouziya": {
                "lat": 33.3064,
                "lng": -8.3548
            },
            "Mnasra": {
                "lat": 34.7667,
                "lng": -5.5167
            },
            "Zeghanghane": {
                "lat": 35.15,
                "lng": -3.0
            },
            "Imzouren": {
                "lat": 35.15,
                "lng": -3.85
            },
            "Oulad Zemam": {
                "lat": 32.35,
                "lng": -6.6333
            },
            "Ben Ahmed": {
                "lat": 33.0655,
                "lng": -7.2464
            },
            "Tit Mellil": {
                "lat": 33.5533,
                "lng": -7.4822
            },
            "Arbaoua": {
                "lat": 34.9,
                "lng": -5.9167
            },
            "Douar Oulad Hssine": {
                "lat": 33.068,
                "lng": -8.5107
            },
            "Bahharet Oulad Ayyad": {
                "lat": 34.7702,
                "lng": -6.3047
            },
            "Mechraa Bel Ksiri": {
                "lat": 34.56,
                "lng": -5.95
            },
            "Mograne": {
                "lat": 34.4167,
                "lng": -6.4333
            },
            "Dar Ould Zidouh": {
                "lat": 32.3167,
                "lng": -6.9
            },
            "Asilah": {
                "lat": 35.4667,
                "lng": -6.0333
            },
            "Al \u2019Attawia": {
                "lat": 31.8347,
                "lng": -7.3125
            },
            "Demnat": {
                "lat": 31.7311,
                "lng": -7.0361
            },
            "Lalla Mimouna": {
                "lat": 34.85,
                "lng": -6.0669
            },
            "Fritissa": {
                "lat": 33.6167,
                "lng": -3.55
            },
            "Arfoud": {
                "lat": 31.4361,
                "lng": -4.2328
            },
            "Tameslouht": {
                "lat": 31.5,
                "lng": -8.1
            },
            "Bou Arfa": {
                "lat": 32.5309,
                "lng": -1.965
            },
            "Sidi Smai\u2019il": {
                "lat": 32.8167,
                "lng": -8.5
            },
            "Souk et Tnine Jorf el Mellah": {
                "lat": 34.4833,
                "lng": -5.5169
            },
            "Mehdya": {
                "lat": 34.2597,
                "lng": -6.65
            },
            "Oulad Hammou": {
                "lat": 33.2499,
                "lng": -8.3346
            },
            "Douar Oulad Aj-jabri": {
                "lat": 32.2567,
                "lng": -6.7839
            },
            "A\u00efn Taoujdat": {
                "lat": 33.9333,
                "lng": -5.2167
            },
            "Dar Bel Hamri": {
                "lat": 34.1889,
                "lng": -5.9697
            },
            "Chichaoua": {
                "lat": 31.5333,
                "lng": -8.7667
            },
            "Tahla": {
                "lat": 34.05,
                "lng": -4.42
            },
            "Bellaa": {
                "lat": 30.0314,
                "lng": -9.5542
            },
            "Oulad Ya\u00efch": {
                "lat": 32.4167,
                "lng": -6.3333
            },
            "Ksebia": {
                "lat": 34.2933,
                "lng": -6.1594
            },
            "Ourika Wawrmas": {
                "lat": 30.7167,
                "lng": -6.5333
            },
            "Tamorot": {
                "lat": 34.9333,
                "lng": -4.7833
            },
            "Moulay Bousselham": {
                "lat": 34.8786,
                "lng": -6.2933
            },
            "Iheddadene": {
                "lat": 35.15,
                "lng": -2.9667
            },
            "Sabaa Aiyoun": {
                "lat": 33.9,
                "lng": -5.3667
            },
            "Bourdoud": {
                "lat": 34.5922,
                "lng": -4.5492
            },
            "A\u00eft Faska": {
                "lat": 31.5058,
                "lng": -7.7161
            },
            "Rich": {
                "lat": 32.2583,
                "lng": -4.5056
            },
            "Boure\u00eft": {
                "lat": 34.9833,
                "lng": -4.9167
            },
            "Lamzoudia": {
                "lat": 31.5833,
                "lng": -8.4833
            },
            "Oulad Said": {
                "lat": 32.632,
                "lng": -8.8456
            },
            "Missour": {
                "lat": 33.05,
                "lng": -3.9833
            },
            "Ain Aicha": {
                "lat": 34.4833,
                "lng": -4.7
            },
            "Zawyat ech Che\u00efkh": {
                "lat": 32.6414,
                "lng": -5.9206
            },
            "Bouknadel": {
                "lat": 34.1333,
                "lng": -6.7333
            },
            "El Ghiate": {
                "lat": 32.0331,
                "lng": -9.1625
            },
            "Safsaf": {
                "lat": 34.5581,
                "lng": -6.0078
            },
            "Ouaoula": {
                "lat": 31.8667,
                "lng": -6.75
            },
            "Douar Olad. Salem": {
                "lat": 32.8739,
                "lng": -8.8588
            },
            "Oulad Tayeb": {
                "lat": 33.9598,
                "lng": -4.9954
            },
            "Ain Dfali": {
                "lat": 34.599,
                "lng": -5.5488
            },
            "Echemmaia Est": {
                "lat": 32.0786,
                "lng": -8.6325
            },
            "Oulad Barhil": {
                "lat": 30.6408,
                "lng": -8.475
            },
            "Douar \u2019Ayn Dfali": {
                "lat": 33.95,
                "lng": -4.45
            },
            "Bir Jdid": {
                "lat": 33.3737,
                "lng": -8.0002
            },
            "Setti Fatma": {
                "lat": 31.2256,
                "lng": -7.6758
            },
            "Skoura": {
                "lat": 31.0606,
                "lng": -6.5556
            },
            "Douar Ouled Ayad": {
                "lat": 32.2,
                "lng": -6.7975
            },
            "Zawyat an Nwa\u00e7er": {
                "lat": 33.3611,
                "lng": -7.6114
            },
            "Khenichet-sur Ouerrha": {
                "lat": 34.4333,
                "lng": -5.6667
            },
            "Ayt Mohamed": {
                "lat": 32.5667,
                "lng": -6.9833
            },
            "Gueznaia": {
                "lat": 35.7066,
                "lng": -5.9088
            },
            "Oulad Hassoune": {
                "lat": 31.6503,
                "lng": -7.8361
            },
            "Bni Frassen": {
                "lat": 34.3833,
                "lng": -4.3833
            },
            "Douar Imoukkane": {
                "lat": 35.0298,
                "lng": -4.8125
            },
            "Tnine Lgharbia": {
                "lat": 32.5719,
                "lng": -8.8568
            },
            "Tighedouine": {
                "lat": 31.4236,
                "lng": -7.5208
            },
            "Alnif": {
                "lat": 31.1167,
                "lng": -5.1667
            },
            "Sidi Lmokhtar": {
                "lat": 31.57,
                "lng": -9.01
            },
            "Souk Tlet El Gharb": {
                "lat": 34.6211,
                "lng": -6.1336
            },
            "Arbi\u2019a Tighadwiyn": {
                "lat": 31.4279,
                "lng": -7.5253
            },
            "Had Oulad Issa": {
                "lat": 32.9519,
                "lng": -8.6982
            },
            "Tidili Masfiywat": {
                "lat": 31.4667,
                "lng": -7.6167
            },
            "Sidi Ifni": {
                "lat": 29.3833,
                "lng": -10.1667
            },
            "Tamgrout": {
                "lat": 30.2667,
                "lng": -5.6667
            },
            "Selouane": {
                "lat": 35.0667,
                "lng": -2.9333
            },
            "Amizmiz": {
                "lat": 31.2167,
                "lng": -8.25
            },
            "Afourar": {
                "lat": 32.2167,
                "lng": -6.5
            },
            "Asni": {
                "lat": 31.25,
                "lng": -7.9833
            },
            "Tabount": {
                "lat": 30.88,
                "lng": -6.91
            },
            "Imi n\u2019Oulaoun": {
                "lat": 31.4224,
                "lng": -6.5641
            },
            "Saka": {
                "lat": 34.6167,
                "lng": -3.4167
            },
            "Bni Rzine": {
                "lat": 35.0108,
                "lng": -4.7262
            },
            "El Gara": {
                "lat": 33.2819,
                "lng": -7.2319
            },
            "Sidi Abdelkader": {
                "lat": 33.0494,
                "lng": -8.6886
            },
            "Imi-n-Tanout": {
                "lat": 31.16,
                "lng": -8.85
            },
            "Sidi Chiker": {
                "lat": 31.7453,
                "lng": -8.7069
            },
            "Sidi Rahal": {
                "lat": 33.7897,
                "lng": -7.1575
            },
            "Station des Essais M.V.A.": {
                "lat": 34.9352,
                "lng": -2.4306
            },
            "Re\u00e7ani": {
                "lat": 31.2859,
                "lng": -4.2751
            },
            "A\u00efn Cheggag": {
                "lat": 33.8833,
                "lng": -5.0333
            },
            "Sidi Jaber": {
                "lat": 32.3833,
                "lng": -6.4167
            },
            "Jdour": {
                "lat": 32.1269,
                "lng": -8.7633
            },
            "Douar Lamrabih": {
                "lat": 34.8167,
                "lng": -5.8167
            },
            "El Ksiba": {
                "lat": 32.5681,
                "lng": -6.0308
            },
            "Sidi Taibi": {
                "lat": 34.1917,
                "lng": -6.6845
            },
            "\u2019Ali Ben Sliman": {
                "lat": 31.91,
                "lng": -7.21
            },
            "Tarhzirt": {
                "lat": 32.4333,
                "lng": -6.1833
            },
            "A\u00eft Tamlil": {
                "lat": 31.48,
                "lng": -6.94
            },
            "Had Zraqtane": {
                "lat": 31.45,
                "lng": -7.4
            },
            "El Mansouria": {
                "lat": 33.75,
                "lng": -7.3
            },
            "Oulad Embarek": {
                "lat": 32.2833,
                "lng": -6.4667
            },
            "Oulad Fraj": {
                "lat": 32.9667,
                "lng": -8.2333
            },
            "Jnane Bouih": {
                "lat": 32.0308,
                "lng": -8.7894
            },
            "Ahfir": {
                "lat": 34.9514,
                "lng": -2.1025
            },
            "Ait Bousarane": {
                "lat": 31.7318,
                "lng": -7.0876
            },
            "Ait Bouziyane": {
                "lat": 33.8167,
                "lng": -5.2749
            },
            "Oulad Salmane": {
                "lat": 34.3356,
                "lng": -6.4604
            },
            "Douar Ait Sidi Daoud": {
                "lat": 31.6347,
                "lng": -7.6444
            },
            "Al Brouj": {
                "lat": 32.505,
                "lng": -7.1927
            },
            "Sidi Redouane": {
                "lat": 34.6833,
                "lng": -5.45
            },
            "Zaou\u00efa A\u00eft Ishak": {
                "lat": 32.76,
                "lng": -5.7233
            },
            "Imouzzer Kandar": {
                "lat": 33.73,
                "lng": -5.01
            },
            "Douar Toulal": {
                "lat": 33.8951,
                "lng": -5.6021
            },
            "Ait Ben Daoudi": {
                "lat": 31.6345,
                "lng": -7.644
            },
            "Beni Zouli": {
                "lat": 30.4839,
                "lng": -5.8619
            },
            "Oulmes": {
                "lat": 33.445,
                "lng": -6.0011
            },
            "Karia Ba Mohamed": {
                "lat": 34.3667,
                "lng": -5.2
            },
            "Tata": {
                "lat": 29.7428,
                "lng": -7.9725
            },
            "Jbabra": {
                "lat": 34.4314,
                "lng": -4.9642
            },
            "Tamri": {
                "lat": 30.695,
                "lng": -9.825
            },
            "Sebt Gzoula": {
                "lat": 32.1167,
                "lng": -9.0833
            },
            "Chouafa": {
                "lat": 34.9468,
                "lng": -6.1667
            },
            "Foum el Anser": {
                "lat": 32.3718,
                "lng": -6.2613
            },
            "Assa": {
                "lat": 28.6086,
                "lng": -9.4269
            },
            "Lamrasla": {
                "lat": 32.0247,
                "lng": -8.9153
            },
            "A\u00eft Bouchta": {
                "lat": 35.1056,
                "lng": -3.8503
            },
            "Bni Bouayach": {
                "lat": 35.0986,
                "lng": -3.8397
            },
            "Ikniwn": {
                "lat": 31.1736,
                "lng": -5.6733
            },
            "Bouarouss": {
                "lat": 34.3667,
                "lng": -4.8167
            },
            "Ghouazi": {
                "lat": 34.4667,
                "lng": -5.3
            },
            "Sidi Allal Tazi": {
                "lat": 34.5197,
                "lng": -6.3236
            },
            "Douar Oulad Mbarek": {
                "lat": 34.2833,
                "lng": -4.3167
            },
            "Laatatra": {
                "lat": 32.6315,
                "lng": -8.4148
            },
            "A\u00eft Majdane": {
                "lat": 31.8514,
                "lng": -6.9658
            },
            "Sahel": {
                "lat": 34.9667,
                "lng": -4.55
            },
            "Dar Chaifat": {
                "lat": 32.55,
                "lng": -7.5
            },
            "Awlouz": {
                "lat": 30.7,
                "lng": -8.15
            },
            "Milla\u2019ab": {
                "lat": 31.4737,
                "lng": -4.7424
            },
            "Ketama": {
                "lat": 34.9158,
                "lng": -4.5686
            },
            "Galaz": {
                "lat": 34.55,
                "lng": -4.8
            },
            "Chouafaa": {
                "lat": 34.7667,
                "lng": -6.05
            },
            "Tagounite": {
                "lat": 29.9585,
                "lng": -5.9151
            },
            "Sidi Yakoub": {
                "lat": 31.6667,
                "lng": -7.0667
            },
            "Knemis Dades": {
                "lat": 31.309,
                "lng": -6.028
            },
            "Oulad Amrane": {
                "lat": 32.2833,
                "lng": -9.2333
            },
            "Qal\u2019at Mgouna": {
                "lat": 31.2414,
                "lng": -6.1283
            },
            "Douar Lamjaara": {
                "lat": 34.6147,
                "lng": -5.2887
            },
            "Ratba": {
                "lat": 34.7833,
                "lng": -4.9333
            },
            "Sidi Lamine": {
                "lat": 32.9,
                "lng": -6.05
            },
            "Ouaouzgane": {
                "lat": 35.0167,
                "lng": -4.5167
            },
            "Ribat Al Khayr": {
                "lat": 33.82,
                "lng": -4.41
            },
            "Agourai": {
                "lat": 33.6333,
                "lng": -5.5833
            },
            "Sidi Ettiji": {
                "lat": 32.1717,
                "lng": -8.8686
            },
            "Douar Tabouda": {
                "lat": 34.7167,
                "lng": -5.1333
            },
            "Zirara": {
                "lat": 32.35,
                "lng": -8.5333
            },
            "Mensora": {
                "lat": 34.8356,
                "lng": -4.9655
            },
            "Tamallalt": {
                "lat": 31.8289,
                "lng": -7.5261
            },
            "Goulmima": {
                "lat": 31.6944,
                "lng": -4.9592
            },
            "Outat Oulad Al Haj": {
                "lat": 33.3333,
                "lng": -3.6833
            },
            "Sebt A\u00eft Saghiouchen": {
                "lat": 34.0122,
                "lng": -4.5536
            },
            "\u2019Ayn Bni Mathar": {
                "lat": 34.0889,
                "lng": -2.0247
            },
            "Timezgana": {
                "lat": 34.5833,
                "lng": -4.7333
            },
            "Talsint": {
                "lat": 32.5398,
                "lng": -3.4408
            },
            "Al Qbab": {
                "lat": 32.7333,
                "lng": -5.5167
            },
            "Bni Tajjit": {
                "lat": 32.2833,
                "lng": -3.4833
            },
            "Zegzel": {
                "lat": 34.8407,
                "lng": -2.3543
            },
            "Douar Azla": {
                "lat": 35.5564,
                "lng": -5.2453
            },
            "Masmouda": {
                "lat": 34.7862,
                "lng": -5.7078
            },
            "Ait Yaazem": {
                "lat": 33.7333,
                "lng": -5.5833
            },
            "Bouchabel": {
                "lat": 34.3833,
                "lng": -5.0167
            },
            "Bni Quolla": {
                "lat": 34.738,
                "lng": -5.5441
            },
            "Bou Djeniba": {
                "lat": 32.9,
                "lng": -6.7833
            },
            "Khat Azakane": {
                "lat": 32.2226,
                "lng": -9.1343
            },
            "Sidi Azzouz": {
                "lat": 34.5411,
                "lng": -5.7516
            },
            "Zemrane": {
                "lat": 31.67,
                "lng": -7.44
            },
            "Caidat Sidi Boubker El Haj": {
                "lat": 34.9148,
                "lng": -6.0735
            },
            "L\u2019Oulja": {
                "lat": 34.2894,
                "lng": -4.9481
            },
            "Ras el Oued": {
                "lat": 34.15,
                "lng": -4.0
            },
            "Haddada": {
                "lat": 34.2236,
                "lng": -6.5111
            },
            "Sidi Allal el Bahraoui": {
                "lat": 33.983,
                "lng": -6.417
            },
            "Tamazouzt": {
                "lat": 31.3833,
                "lng": -8.3833
            },
            "Ras El Oued": {
                "lat": 34.339,
                "lng": -4.5558
            },
            "A\u00efn Mediouna": {
                "lat": 34.5,
                "lng": -4.55
            },
            "Sebt Bni Smith": {
                "lat": 35.1373,
                "lng": -4.7107
            },
            "Skhour Rehamna": {
                "lat": 32.4833,
                "lng": -7.9167
            },
            "Al Hammam": {
                "lat": 33.1868,
                "lng": -5.6225
            },
            "Ezzhiliga": {
                "lat": 33.3,
                "lng": -6.5333
            },
            "Anazzou": {
                "lat": 30.6333,
                "lng": -8.05
            },
            "Tendrara": {
                "lat": 33.05,
                "lng": -2.0
            },
            "A\u00e7-\u00e7ahrij": {
                "lat": 31.8,
                "lng": -7.14
            },
            "Moul El Bergui": {
                "lat": 32.5113,
                "lng": -8.9849
            },
            "A\u00efn Jemaa": {
                "lat": 34.0333,
                "lng": -5.8
            },
            "Tazert": {
                "lat": 31.6597,
                "lng": -7.4092
            },
            "Souk Et-Tleta des Oulad Hamdane": {
                "lat": 33.1047,
                "lng": -8.2495
            },
            "Tirhassaline": {
                "lat": 32.7833,
                "lng": -5.65
            },
            "Douar El Arbaa Bou Quorra": {
                "lat": 34.749,
                "lng": -5.1982
            },
            "Oulad Bou Rahmoun": {
                "lat": 32.2954,
                "lng": -6.6668
            },
            "Mellila": {
                "lat": 33.3833,
                "lng": -7.1333
            },
            "Sabbah": {
                "lat": 33.8036,
                "lng": -7.0372
            },
            "Midar": {
                "lat": 34.95,
                "lng": -3.5333
            },
            "Esbiaat": {
                "lat": 32.2044,
                "lng": -8.5608
            },
            "Douar Souk L\u2018qolla": {
                "lat": 35.0718,
                "lng": -5.5702
            },
            "Tabant": {
                "lat": 31.6581,
                "lng": -6.42
            },
            "\u2019Tlat Bni Oukil": {
                "lat": 32.577,
                "lng": -6.84
            },
            "Sidi Zouine": {
                "lat": 31.6706,
                "lng": -8.3508
            },
            "Oued Jdida": {
                "lat": 33.9333,
                "lng": -5.3667
            },
            "Gourrama": {
                "lat": 32.3333,
                "lng": -4.0833
            },
            "Aghbal": {
                "lat": 34.9394,
                "lng": -2.1272
            },
            "Nzalat Laadam": {
                "lat": 32.1,
                "lng": -7.95
            },
            "Ighrem n\u2019Ougdal": {
                "lat": 31.2333,
                "lng": -7.4333
            },
            "Bni Darkoul": {
                "lat": 35.0563,
                "lng": -5.0688
            },
            "Driouch": {
                "lat": 34.9833,
                "lng": -3.3833
            },
            "Douar Oulad Driss": {
                "lat": 31.9039,
                "lng": -8.3024
            },
            "Oulad Driss": {
                "lat": 31.9996,
                "lng": -8.2276
            },
            "Mediouna": {
                "lat": 33.45,
                "lng": -7.51
            },
            "Ifrane": {
                "lat": 33.5333,
                "lng": -5.1167
            },
            "Wawizaght": {
                "lat": 32.1586,
                "lng": -6.355
            },
            "Ayt \u2019Attou ou L\u2019Arbi": {
                "lat": 31.5456,
                "lng": -8.2967
            },
            "Madagh": {
                "lat": 35.0133,
                "lng": -2.3397
            },
            "Boula\u2019wane": {
                "lat": 32.8607,
                "lng": -8.053
            },
            "Tagalft": {
                "lat": 32.2389,
                "lng": -6.1238
            },
            "Ourtzagh": {
                "lat": 34.55,
                "lng": -4.9333
            },
            "Zaouiat Moulay Bouchta El Khammar": {
                "lat": 34.4833,
                "lng": -5.1167
            },
            "Ihaddadene": {
                "lat": 31.2,
                "lng": -9.65
            },
            "Ait Yazza": {
                "lat": 30.5063,
                "lng": -8.7931
            },
            "Ben Taieb": {
                "lat": 35.0837,
                "lng": -3.4696
            },
            "Tafrant": {
                "lat": 34.625,
                "lng": -5.1242
            },
            "Bou Izakarn": {
                "lat": 29.1667,
                "lng": -9.7333
            },
            "Douar Hammadi": {
                "lat": 31.609,
                "lng": -8.4604
            },
            "Bezou": {
                "lat": 32.0833,
                "lng": -7.05
            },
            "Talwat": {
                "lat": 31.2883,
                "lng": -7.2372
            },
            "Sidi Abdelkarim": {
                "lat": 33.1869,
                "lng": -7.2333
            },
            "Temsamane": {
                "lat": 35.1167,
                "lng": -3.6333
            },
            "Oulad Chikh": {
                "lat": 32.8544,
                "lng": -7.5386
            },
            "Toulal": {
                "lat": 32.3036,
                "lng": -3.9861
            },
            "Khmis Sidi al \u2019Aydi": {
                "lat": 33.1228,
                "lng": -7.6219
            },
            "Arbaa Laaounate": {
                "lat": 32.7446,
                "lng": -8.1942
            },
            "Douar Lehgagcha": {
                "lat": 32.55,
                "lng": -8.7167
            },
            "Mqam at Tolba": {
                "lat": 34.0173,
                "lng": -6.25
            },
            "Targuist": {
                "lat": 34.95,
                "lng": -4.3
            },
            "Kissane Ltouqi": {
                "lat": 34.6,
                "lng": -5.0833
            },
            "Tounfit": {
                "lat": 32.4667,
                "lng": -5.2333
            },
            "Reggada": {
                "lat": 29.5205,
                "lng": -9.6664
            },
            "Sebt Labrikiyne": {
                "lat": 32.2944,
                "lng": -8.0722
            },
            "El Kansera": {
                "lat": 34.0419,
                "lng": -5.9272
            },
            "Douar Sidi Laaroussi": {
                "lat": 31.845,
                "lng": -9.1586
            },
            "Zayda": {
                "lat": 32.8167,
                "lng": -4.95
            },
            "Asjen": {
                "lat": 34.85,
                "lng": -5.6167
            },
            "Bhalil": {
                "lat": 33.85,
                "lng": -4.8667
            },
            "Ksar Sghir": {
                "lat": 35.8419,
                "lng": -5.5586
            },
            "Sebt Bni Garfett": {
                "lat": 35.25,
                "lng": -5.8333
            },
            "Bou Fekrane": {
                "lat": 33.7667,
                "lng": -5.4833
            },
            "Bni Khloug": {
                "lat": 32.65,
                "lng": -7.3833
            },
            "Douar Oulad Youssef": {
                "lat": 32.4807,
                "lng": -6.3769
            },
            "Kasba Tanora": {
                "lat": 32.4751,
                "lng": -6.1498
            },
            "Aghbala": {
                "lat": 32.4833,
                "lng": -5.6333
            },
            "Tizgane": {
                "lat": 35.4136,
                "lng": -5.0696
            },
            "Zag": {
                "lat": 28.0167,
                "lng": -9.3333
            },
            "Oulad Rahmoun": {
                "lat": 32.3278,
                "lng": -6.5006
            },
            "Laamarna": {
                "lat": 31.8944,
                "lng": -8.9842
            },
            "Bou Adel": {
                "lat": 34.5428,
                "lng": -4.5075
            },
            "A\u00eft Youssef Ou Ali": {
                "lat": 31.9833,
                "lng": -5.7833
            },
            "Ahermoumou": {
                "lat": 33.8184,
                "lng": -4.4106
            },
            "El Menzel": {
                "lat": 33.8389,
                "lng": -4.5458
            },
            "Souakene": {
                "lat": 35.1167,
                "lng": -5.95
            },
            "Teroual": {
                "lat": 34.6667,
                "lng": -5.2667
            },
            "Tamsaout": {
                "lat": 29.5333,
                "lng": -8.85
            },
            "Oulad Ouchchih": {
                "lat": 35.0939,
                "lng": -5.9454
            },
            "Ounagha": {
                "lat": 31.5333,
                "lng": -9.55
            },
            "Boumia": {
                "lat": 32.7228,
                "lng": -5.1053
            },
            "Oulad Fares": {
                "lat": 35.5167,
                "lng": -5.9333
            },
            "Oulad A\u00efssa": {
                "lat": 30.558,
                "lng": -8.614
            },
            "Jaidte Lbatma": {
                "lat": 31.6806,
                "lng": -7.7281
            },
            "Boumalne": {
                "lat": 31.4236,
                "lng": -6.0722
            },
            "Nkheila": {
                "lat": 32.9239,
                "lng": -7.0692
            },
            "Aj Jourf": {
                "lat": 31.4903,
                "lng": -4.4014
            },
            "Ar Rommani": {
                "lat": 33.5333,
                "lng": -6.6167
            },
            "Ain Kansara": {
                "lat": 34.15,
                "lng": -4.8314
            },
            "Guisser": {
                "lat": 32.7667,
                "lng": -7.5
            },
            "Gharbia": {
                "lat": 35.5153,
                "lng": -5.9306
            },
            "Oulad Hamdane": {
                "lat": 32.3333,
                "lng": -6.3667
            },
            "Malloussa": {
                "lat": 35.7333,
                "lng": -5.6333
            },
            "Tahannawt": {
                "lat": 31.3514,
                "lng": -7.9508
            },
            "Mayate": {
                "lat": 32.2667,
                "lng": -7.5167
            },
            "Steha": {
                "lat": 35.3646,
                "lng": -4.9306
            },
            "Al Fayd": {
                "lat": 30.6167,
                "lng": -8.2167
            },
            "Oulad Daoud": {
                "lat": 34.4058,
                "lng": -4.6939
            },
            "Ain Beida": {
                "lat": 31.585,
                "lng": -8.608
            },
            "Souq Jamaa Fdalate": {
                "lat": 33.5911,
                "lng": -7.2792
            },
            "Sidi Yahia": {
                "lat": 30.4969,
                "lng": -8.8211
            },
            "A\u00eft I\u2019yach": {
                "lat": 32.6667,
                "lng": -4.9556
            },
            "Matmata": {
                "lat": 34.1,
                "lng": -4.55
            },
            "Tizi Nisly": {
                "lat": 32.4667,
                "lng": -5.7667
            },
            "Zemamra": {
                "lat": 32.6215,
                "lng": -8.7023
            },
            "Oulad Amrane el Mekki": {
                "lat": 35.2167,
                "lng": -5.9667
            },
            "El Arba Des Bir Lenni": {
                "lat": 34.3272,
                "lng": -4.2039
            },
            "Tiddas": {
                "lat": 33.5665,
                "lng": -6.2659
            },
            "Toualet": {
                "lat": 32.7333,
                "lng": -7.1833
            },
            "Sidi Amer El Hadi": {
                "lat": 34.7992,
                "lng": -5.8394
            },
            "Tifni": {
                "lat": 31.6281,
                "lng": -6.9444
            },
            "Tamezmout": {
                "lat": 30.8075,
                "lng": -6.1142
            },
            "Moulay Driss Zerhoun": {
                "lat": 34.0542,
                "lng": -5.5272
            },
            "Oulad Friha": {
                "lat": 32.6108,
                "lng": -7.625
            },
            "Derdara": {
                "lat": 35.1103,
                "lng": -5.2872
            },
            "Sidi Kasem": {
                "lat": 35.5339,
                "lng": -5.2183
            },
            "Ta\u00efneste": {
                "lat": 34.5573,
                "lng": -4.136
            },
            "Al M\u2019aziz": {
                "lat": 33.6667,
                "lng": -6.3167
            },
            "Dzouz": {
                "lat": 31.89,
                "lng": -7.32
            },
            "Bouabout": {
                "lat": 31.2667,
                "lng": -9.1833
            },
            "Tawnza": {
                "lat": 32.0944,
                "lng": -6.6025
            },
            "Douar Trougout": {
                "lat": 35.18,
                "lng": -3.77
            },
            "Ahlaf": {
                "lat": 33.2833,
                "lng": -7.2
            },
            "Sidi Moussa Ben Ali": {
                "lat": 33.5594,
                "lng": -7.3817
            },
            "El Khemis des Beni Chegdal": {
                "lat": 32.4441,
                "lng": -6.9551
            },
            "Amdel": {
                "lat": 31.5617,
                "lng": -8.8944
            },
            "Ain Legdah": {
                "lat": 34.1667,
                "lng": -4.5
            },
            "Laqraqra": {
                "lat": 32.4333,
                "lng": -7.4333
            },
            "Douar Sgarta": {
                "lat": 32.1667,
                "lng": -7.6333
            },
            "Lahfayr": {
                "lat": 30.57,
                "lng": -8.4878
            },
            "Boudenib": {
                "lat": 31.9497,
                "lng": -3.6078
            },
            "Tilmi": {
                "lat": 31.8189,
                "lng": -5.7718
            },
            "Douar Oulad Naoual": {
                "lat": 34.4936,
                "lng": -5.7108
            },
            "Lamsabih": {
                "lat": 32.2933,
                "lng": -8.6889
            },
            "Ouaklim Oukider": {
                "lat": 31.45,
                "lng": -5.55
            },
            "Jem\u2019at Oulad \u2019Abbou": {
                "lat": 33.1156,
                "lng": -7.9333
            },
            "Lafrayta": {
                "lat": 31.9167,
                "lng": -7.25
            },
            "Commune Sidi Youssef Ben Ahmed": {
                "lat": 33.7861,
                "lng": -4.8152
            },
            "El Ghourdane": {
                "lat": 32.3205,
                "lng": -8.7637
            },
            "Jamaat Shaim": {
                "lat": 32.3486,
                "lng": -8.8431
            },
            "Touama": {
                "lat": 31.5339,
                "lng": -7.4872
            },
            "Sidi Abdellah Ben Taazizt": {
                "lat": 34.0019,
                "lng": -5.3695
            },
            "Iygli": {
                "lat": 29.5001,
                "lng": -9.0501
            },
            "Tissa": {
                "lat": 35.0228,
                "lng": -4.1408
            },
            "Douar Jwalla": {
                "lat": 31.89,
                "lng": -7.44
            },
            "Boujediane": {
                "lat": 35.1114,
                "lng": -5.7823
            },
            "Douar Ain Maatouf": {
                "lat": 34.4352,
                "lng": -4.5566
            },
            "Zaouiet Says": {
                "lat": 32.7931,
                "lng": -8.6506
            },
            "Iazizatene": {
                "lat": 35.2544,
                "lng": -3.115
            },
            "Kariat Ben Aouda": {
                "lat": 34.7667,
                "lng": -5.95
            },
            "Farkh\u00e2na": {
                "lat": 35.2833,
                "lng": -3.0167
            },
            "Takad Sahel": {
                "lat": 30.25,
                "lng": -9.55
            },
            "Timahdit": {
                "lat": 33.2369,
                "lng": -5.06
            },
            "Bni Drar": {
                "lat": 34.8578,
                "lng": -1.9928
            },
            "Figuig": {
                "lat": 32.1167,
                "lng": -1.2269
            },
            "Imi Mokorn": {
                "lat": 30.1675,
                "lng": -9.2322
            },
            "Douar Bouchfaa": {
                "lat": 34.1014,
                "lng": -4.2939
            },
            "Tanant": {
                "lat": 31.8667,
                "lng": -6.95
            },
            "Arbaa Sahel": {
                "lat": 29.5993,
                "lng": -9.877
            },
            "Taouloukoult": {
                "lat": 31.2167,
                "lng": -9.0833
            },
            "Agdz": {
                "lat": 30.6978,
                "lng": -6.4497
            },
            "Sebt Ait Ikkou": {
                "lat": 33.6686,
                "lng": -6.1372
            },
            "Lamhadi": {
                "lat": 32.3,
                "lng": -9.0167
            },
            "Hetane": {
                "lat": 32.8403,
                "lng": -6.8025
            },
            "Itzer": {
                "lat": 32.8833,
                "lng": -5.05
            },
            "Toundout": {
                "lat": 31.2667,
                "lng": -6.5833
            },
            "Abadou": {
                "lat": 31.5797,
                "lng": -7.3122
            },
            "A\u00efn Zora": {
                "lat": 34.66,
                "lng": -3.53
            },
            "A\u00eft Hani": {
                "lat": 31.7786,
                "lng": -5.4555
            },
            "Tamanar": {
                "lat": 31.0,
                "lng": -9.6667
            },
            "Had Laaounate": {
                "lat": 32.6128,
                "lng": -8.2256
            },
            "Tourza": {
                "lat": 29.4778,
                "lng": -10.0158
            },
            "Amtar": {
                "lat": 35.2385,
                "lng": -4.7943
            },
            "Mokrisset": {
                "lat": 34.91,
                "lng": -5.3536
            },
            "Tilougguit": {
                "lat": 32.0333,
                "lng": -6.2
            },
            "Sidi Bousber": {
                "lat": 34.5667,
                "lng": -5.3667
            },
            "Souq Sebt Says": {
                "lat": 32.7773,
                "lng": -8.6433
            },
            "Sidi Abdallah": {
                "lat": 32.5783,
                "lng": -7.8108
            },
            "Sidi Boushab": {
                "lat": 30.074,
                "lng": -9.279
            },
            "Zawyat Ahan\u00e7al": {
                "lat": 31.8325,
                "lng": -6.1056
            },
            "Awfouss": {
                "lat": 31.6833,
                "lng": -4.1667
            },
            "Oulad Slim": {
                "lat": 32.7775,
                "lng": -7.7725
            },
            "Oued Amlil": {
                "lat": 34.2,
                "lng": -4.2833
            },
            "Brikcha": {
                "lat": 34.9667,
                "lng": -5.5833
            },
            "Douar Echbanat": {
                "lat": 34.214,
                "lng": -5.3508
            },
            "Tnine Sidi Lyamani": {
                "lat": 35.37,
                "lng": -5.97
            },
            "Beni Oulid": {
                "lat": 34.5897,
                "lng": -4.4514
            },
            "Aghbalou n\u2019Kerdous": {
                "lat": 31.6767,
                "lng": -5.2976
            },
            "Bounaamane": {
                "lat": 29.5283,
                "lng": -9.8044
            },
            "Jaqma": {
                "lat": 33.292,
                "lng": -7.44
            },
            "Dar Si Aissa": {
                "lat": 32.4199,
                "lng": -8.908
            },
            "Sgamna": {
                "lat": 32.7342,
                "lng": -7.2025
            },
            "Ksar Lmajaz": {
                "lat": 35.8428,
                "lng": -5.5586
            },
            "Douar Mzoura": {
                "lat": 34.3167,
                "lng": -5.9333
            },
            "Jemaat Oulad Mhamed": {
                "lat": 33.0938,
                "lng": -7.0519
            },
            "Msila": {
                "lat": 35.2,
                "lng": -5.5667
            },
            "A\u00efn Zohra": {
                "lat": 34.1,
                "lng": -6.35
            },
            "Za\u2019roura": {
                "lat": 35.2167,
                "lng": -5.6667
            },
            "La\u2019tamna": {
                "lat": 31.91,
                "lng": -7.34
            },
            "Jafra": {
                "lat": 31.5145,
                "lng": -8.7555
            },
            "Taghbalt": {
                "lat": 30.62,
                "lng": -5.35
            },
            "Bou\u2019nane": {
                "lat": 32.0275,
                "lng": -3.04
            },
            "Zaggota": {
                "lat": 34.1667,
                "lng": -5.5333
            },
            "Tarhjicht": {
                "lat": 29.0564,
                "lng": -9.4281
            },
            "Kenafif": {
                "lat": 30.4167,
                "lng": -9.0333
            },
            "Dar El Kebdani": {
                "lat": 35.1203,
                "lng": -3.3322
            },
            "Sidi Rahhal": {
                "lat": 31.6667,
                "lng": -7.4833
            },
            "Kourimat": {
                "lat": 31.45,
                "lng": -9.3167
            },
            "Ech Cha\u00efbat": {
                "lat": 31.6,
                "lng": -7.85
            },
            "Foum Jam\u2019a": {
                "lat": 31.96,
                "lng": -6.98
            },
            "Douar Snada": {
                "lat": 35.0667,
                "lng": -4.2167
            },
            "Boudinar": {
                "lat": 35.15,
                "lng": -3.6333
            },
            "Outa Bouabane": {
                "lat": 34.2606,
                "lng": -4.4139
            },
            "Oulad \u2019Azzouz": {
                "lat": 32.7693,
                "lng": -6.7543
            },
            "Imlil": {
                "lat": 31.7567,
                "lng": -7.0106
            },
            "Tiztoutine": {
                "lat": 34.9833,
                "lng": -3.15
            },
            "Tadla": {
                "lat": 32.4409,
                "lng": -8.3708
            },
            "Ain Karma": {
                "lat": 34.0071,
                "lng": -5.6182
            },
            "Aghbalou Aqourar": {
                "lat": 33.9341,
                "lng": -4.7381
            },
            "Douar Lehouifrat": {
                "lat": 32.28,
                "lng": -8.31
            },
            "Aklim": {
                "lat": 34.9167,
                "lng": -2.4333
            },
            "Sidi Ahmed El Khadir": {
                "lat": 32.5167,
                "lng": -7.3833
            },
            "\u2019A\u00efn Leuh": {
                "lat": 33.2833,
                "lng": -5.3833
            },
            "Oued Laou": {
                "lat": 35.45,
                "lng": -5.0833
            },
            "Bni Boufrah": {
                "lat": 35.1,
                "lng": -4.3167
            },
            "Oulad Imloul": {
                "lat": 32.0333,
                "lng": -7.7167
            },
            "Bou Zemou": {
                "lat": 32.1114,
                "lng": -5.5197
            },
            "Oulad Ayyad": {
                "lat": 32.3333,
                "lng": -6.3833
            },
            "Tatoufet": {
                "lat": 35.0339,
                "lng": -5.7706
            },
            "Zawyat Sidi Ben Hamdoun": {
                "lat": 33.045,
                "lng": -7.929
            },
            "Bni Gmil": {
                "lat": 35.0833,
                "lng": -4.3833
            },
            "Douar Messassa": {
                "lat": 34.2803,
                "lng": -4.5336
            },
            "Gaada": {
                "lat": 35.1594,
                "lng": -2.9892
            },
            "Berkine": {
                "lat": 33.7665,
                "lng": -3.8438
            },
            "El Amim": {
                "lat": 32.2067,
                "lng": -7.2629
            },
            "Tissaf": {
                "lat": 33.4,
                "lng": -3.5833
            },
            "Tissint": {
                "lat": 29.9006,
                "lng": -7.3203
            },
            "Sidi El Hattab": {
                "lat": 32.2667,
                "lng": -7.2833
            },
            "Gammasa": {
                "lat": 31.4182,
                "lng": -8.3784
            },
            "Mh\u00e2j\u00e2r": {
                "lat": 35.12,
                "lng": -3.49
            },
            "Douar Oulad Bouziane": {
                "lat": 34.2325,
                "lng": -5.0444
            },
            "Ameskroud": {
                "lat": 30.5308,
                "lng": -9.3283
            },
            "Laanoussar": {
                "lat": 33.6833,
                "lng": -4.8167
            },
            "Laghzawna": {
                "lat": 33.189,
                "lng": -7.684
            },
            "Douar Oulad Sidi Moussa": {
                "lat": 32.2726,
                "lng": -7.1987
            },
            "Sidi Lahsene": {
                "lat": 34.0999,
                "lng": -2.6219
            },
            "Douar Ezzerarda": {
                "lat": 34.7667,
                "lng": -5.8333
            },
            "Oulad Cherif": {
                "lat": 31.7667,
                "lng": -7.7833
            },
            "Tanakoub": {
                "lat": 35.1091,
                "lng": -5.4577
            },
            "Sidi Bou Othmane": {
                "lat": 31.9033,
                "lng": -7.9422
            },
            "Douar El Mellaliyine": {
                "lat": 35.6264,
                "lng": -5.3414
            },
            "Bir Tam Tam": {
                "lat": 33.9833,
                "lng": -4.6333
            },
            "Tafersit": {
                "lat": 35.0167,
                "lng": -3.5333
            },
            "Mwaline al Oued": {
                "lat": 33.4467,
                "lng": -7.3283
            },
            "Chtiba": {
                "lat": 32.2,
                "lng": -7.3
            },
            "Iguidiy": {
                "lat": 30.7467,
                "lng": -7.9267
            },
            "Sidi Ouassay": {
                "lat": 30.05,
                "lng": -9.6833
            },
            "Gtarna": {
                "lat": 32.9724,
                "lng": -7.9645
            },
            "A\u00efn el Mediour": {
                "lat": 30.4,
                "lng": -8.8833
            },
            "Had Dra": {
                "lat": 31.5833,
                "lng": -9.5333
            },
            "Foum Zguid": {
                "lat": 30.0833,
                "lng": -6.8667
            },
            "Bni Sidel": {
                "lat": 35.1392,
                "lng": -3.1281
            },
            "Zawyat Sidi al Mekki": {
                "lat": 33.212,
                "lng": -7.717
            },
            "Douar Ait Taleb": {
                "lat": 32.3839,
                "lng": -8.1856
            },
            "Imilchil": {
                "lat": 32.155,
                "lng": -5.6347
            },
            "Iskourane": {
                "lat": 30.8433,
                "lng": -9.8186
            },
            "Msemrir": {
                "lat": 31.7028,
                "lng": -5.8125
            },
            "Ait Ikkou": {
                "lat": 33.669,
                "lng": -6.1376
            },
            "A\u00eft Ouaoumana": {
                "lat": 32.7128,
                "lng": -5.8042
            },
            "Agadir Melloul": {
                "lat": 30.2167,
                "lng": -7.8
            },
            "Amarzgane": {
                "lat": 31.05,
                "lng": -7.2167
            },
            "Hassi Berkane": {
                "lat": 34.8333,
                "lng": -2.8667
            },
            "Saidia": {
                "lat": 35.085,
                "lng": -2.2392
            },
            "Tiqqi": {
                "lat": 29.8667,
                "lng": -9.0667
            },
            "Bouhlou": {
                "lat": 34.1333,
                "lng": -4.4
            },
            "Iaboutene": {
                "lat": 35.067,
                "lng": -3.967
            },
            "Tleta Taghramt": {
                "lat": 35.7877,
                "lng": -5.4677
            },
            "Sidi el Mokhfi": {
                "lat": 34.6039,
                "lng": -4.7889
            },
            "Sidi Dahbi": {
                "lat": 33.05,
                "lng": -7.1833
            },
            "Zawiat Moulay Brahim": {
                "lat": 31.2858,
                "lng": -7.9656
            },
            "Oulad Khallouf": {
                "lat": 34.71,
                "lng": -2.58
            },
            "Lakhzazra": {
                "lat": 33.0333,
                "lng": -7.05
            },
            "Lambarkiyine": {
                "lat": 33.2,
                "lng": -7.5
            },
            "Bir Anzarane": {
                "lat": 23.8918,
                "lng": -14.5364
            },
            "Tifariti": {
                "lat": 26.158,
                "lng": -10.567
            },
            "Uad Damran": {
                "lat": 27.4181,
                "lng": -12.9842
            },
            "Lemsid": {
                "lat": 26.5482,
                "lng": -13.8482
            }
        }
    },
    "GH": {
        "name": "Ghana",
        "cities": {
            "Kumasi": {
                "lat": 6.7,
                "lng": -1.625
            },
            "Boankra": {
                "lat": 6.6944,
                "lng": -1.4028
            },
            "Accra": {
                "lat": 5.55,
                "lng": -0.2
            },
            "Tamale": {
                "lat": 9.4075,
                "lng": -0.8533
            },
            "Domaa-Ahenkro": {
                "lat": 7.2833,
                "lng": -2.8833
            },
            "Sekondi": {
                "lat": 4.9433,
                "lng": -1.704
            },
            "Sunyani": {
                "lat": 7.3333,
                "lng": -2.3333
            },
            "Ashaiman": {
                "lat": 5.7,
                "lng": -0.0333
            },
            "Obuase": {
                "lat": 6.2,
                "lng": -1.6833
            },
            "Cape Coast": {
                "lat": 5.1,
                "lng": -1.25
            },
            "Tema": {
                "lat": 5.6667,
                "lng": -0.0167
            },
            "Medina Estates": {
                "lat": 5.6833,
                "lng": -0.1667
            },
            "Koforidua": {
                "lat": 6.0833,
                "lng": -0.25
            },
            "Suhum": {
                "lat": 6.0333,
                "lng": -0.45
            },
            "Kintampo": {
                "lat": 8.0522,
                "lng": -1.7347
            },
            "Wa": {
                "lat": 10.0667,
                "lng": -2.5
            },
            "Atebubu": {
                "lat": 7.75,
                "lng": -0.9833
            },
            "Techiman": {
                "lat": 7.5772,
                "lng": -1.9292
            },
            "Dzolokpuita": {
                "lat": 6.7862,
                "lng": 0.4437
            },
            "Ho": {
                "lat": 6.6119,
                "lng": 0.4703
            },
            "Ahenkro": {
                "lat": 7.1164,
                "lng": 0.3242
            },
            "Dome": {
                "lat": 5.655,
                "lng": -0.235
            },
            "Gbawe": {
                "lat": 5.5767,
                "lng": -0.3108
            },
            "Konongo": {
                "lat": 6.6167,
                "lng": -1.2167
            },
            "Ejura": {
                "lat": 7.3833,
                "lng": -1.3667
            },
            "Bawku": {
                "lat": 11.05,
                "lng": -0.2333
            },
            "Swedru": {
                "lat": 5.5306,
                "lng": -0.7028
            },
            "Bolgatanga": {
                "lat": 10.7833,
                "lng": -0.85
            },
            "Aflao": {
                "lat": 6.1468,
                "lng": 1.1799
            },
            "Berekum": {
                "lat": 7.45,
                "lng": -2.5833
            },
            "Nkawkaw": {
                "lat": 6.55,
                "lng": -0.7667
            },
            "Tafo": {
                "lat": 6.7358,
                "lng": -1.6081
            },
            "Oda": {
                "lat": 5.9236,
                "lng": -0.9883
            },
            "Hohoe": {
                "lat": 7.149,
                "lng": 0.4746
            },
            "Winneba": {
                "lat": 5.35,
                "lng": -0.625
            },
            "Yendi": {
                "lat": 9.4324,
                "lng": -0.0043
            },
            "Bogoso": {
                "lat": 5.5686,
                "lng": -2.0058
            },
            "Begoro": {
                "lat": 6.3833,
                "lng": -0.3833
            },
            "Nsawam": {
                "lat": 5.8,
                "lng": -0.35
            },
            "Mampong": {
                "lat": 7.0667,
                "lng": -1.4
            },
            "Asamankese": {
                "lat": 5.8667,
                "lng": -0.6667
            },
            "Savelugu": {
                "lat": 9.6167,
                "lng": -0.8333
            },
            "Agogo": {
                "lat": 6.8,
                "lng": -1.0833
            },
            "Anloga": {
                "lat": 5.7919,
                "lng": 0.9003
            },
            "Prestea": {
                "lat": 5.4333,
                "lng": -2.15
            },
            "Effia-Kuma": {
                "lat": 4.9239,
                "lng": -1.7622
            },
            "Tarkwa": {
                "lat": 5.3,
                "lng": -1.9833
            },
            "Gyapekurom": {
                "lat": 7.5833,
                "lng": -2.7833
            },
            "Elmina": {
                "lat": 5.0833,
                "lng": -1.35
            },
            "Kpandu": {
                "lat": 7.0,
                "lng": 0.3
            },
            "Axim": {
                "lat": 4.8667,
                "lng": -2.2333
            },
            "Duayaw-Nkwanta": {
                "lat": 7.1667,
                "lng": -2.1
            },
            "Navrongo": {
                "lat": 10.8847,
                "lng": -1.0903
            },
            "Apam": {
                "lat": 5.2789,
                "lng": -0.7397
            },
            "Salaga": {
                "lat": 8.55,
                "lng": -0.5167
            },
            "Saltpond": {
                "lat": 5.2,
                "lng": -1.0667
            },
            "Akwatia": {
                "lat": 6.05,
                "lng": -0.8
            },
            "Shama": {
                "lat": 5.0167,
                "lng": -1.6333
            },
            "Nyakrom": {
                "lat": 5.6167,
                "lng": -0.7833
            },
            "Keta": {
                "lat": 5.9167,
                "lng": 0.9833
            },
            "Seva": {
                "lat": 5.9845,
                "lng": 0.9414
            },
            "Somanya": {
                "lat": 6.1039,
                "lng": -0.015
            },
            "Peduasi": {
                "lat": 5.85,
                "lng": -0.1833
            },
            "Aburi": {
                "lat": 5.85,
                "lng": -0.1833
            },
            "Mumford": {
                "lat": 5.2625,
                "lng": -0.7578
            },
            "Bechem": {
                "lat": 7.0833,
                "lng": -2.0167
            },
            "Kade": {
                "lat": 6.0833,
                "lng": -0.8333
            },
            "Odumase": {
                "lat": 7.3667,
                "lng": -2.3167
            },
            "Nalerigu": {
                "lat": 10.5333,
                "lng": -0.3667
            },
            "Anomabu": {
                "lat": 5.1667,
                "lng": -1.1167
            },
            "Akropong": {
                "lat": 5.9742,
                "lng": -0.0881
            },
            "Diari": {
                "lat": 9.8452,
                "lng": -0.8655
            },
            "Gumani": {
                "lat": 9.45,
                "lng": -0.7667
            },
            "Kete Krachi": {
                "lat": 7.8,
                "lng": -0.0167
            },
            "Kibi": {
                "lat": 6.1667,
                "lng": -0.55
            },
            "Kpandae": {
                "lat": 8.47,
                "lng": -0.02
            },
            "Mpraeso": {
                "lat": 6.58,
                "lng": -0.7297
            },
            "Sawla": {
                "lat": 9.2833,
                "lng": -2.4167
            },
            "Akim Swedru": {
                "lat": 5.894,
                "lng": -1.0127
            },
            "Karaga": {
                "lat": 9.925,
                "lng": -0.4297
            },
            "Aboso": {
                "lat": 5.3633,
                "lng": -1.9478
            },
            "Chamba": {
                "lat": 8.7,
                "lng": -0.1333
            },
            "Damongo": {
                "lat": 9.0833,
                "lng": -1.8167
            },
            "Goaso": {
                "lat": 6.8,
                "lng": -2.5167
            },
            "Dambai": {
                "lat": 8.0689,
                "lng": 0.1792
            },
            "Sefwi Wiawso": {
                "lat": 6.2158,
                "lng": -2.485
            }
        }
    },
    "DZ": {
        "name": "Algeria",
        "cities": {
            "Algiers": {
                "lat": 36.7539,
                "lng": 3.0589
            },
            "Constantine": {
                "lat": 36.35,
                "lng": 6.6
            },
            "Batna": {
                "lat": 35.55,
                "lng": 6.1667
            },
            "S\u00e9tif": {
                "lat": 36.19,
                "lng": 5.41
            },
            "Djelfa": {
                "lat": 34.6667,
                "lng": 3.25
            },
            "Annaba": {
                "lat": 36.9,
                "lng": 7.7667
            },
            "Sidi A\u00efssa": {
                "lat": 36.5,
                "lng": 4.2978
            },
            "Sidi Bel Abb\u00e8s": {
                "lat": 35.1939,
                "lng": -0.6414
            },
            "Biskra": {
                "lat": 34.85,
                "lng": 5.7333
            },
            "T\u00e9bessa": {
                "lat": 35.4,
                "lng": 8.1167
            },
            "Ouargla": {
                "lat": 31.95,
                "lng": 5.3167
            },
            "El Khroub": {
                "lat": 36.2667,
                "lng": 6.7
            },
            "Tiaret": {
                "lat": 35.3667,
                "lng": 1.3167
            },
            "Beja\u00efa": {
                "lat": 36.7511,
                "lng": 5.0642
            },
            "Tlemcen": {
                "lat": 34.8828,
                "lng": -1.3167
            },
            "Bir el Djir": {
                "lat": 35.7367,
                "lng": -0.5542
            },
            "Bordj Bou Arreridj": {
                "lat": 36.0667,
                "lng": 4.7667
            },
            "B\u00e9char": {
                "lat": 31.6167,
                "lng": -2.2167
            },
            "Blida": {
                "lat": 36.4686,
                "lng": 2.8319
            },
            "Skikda": {
                "lat": 36.8667,
                "lng": 6.9
            },
            "Souk Ahras": {
                "lat": 36.2864,
                "lng": 7.9511
            },
            "Chlef": {
                "lat": 36.1647,
                "lng": 1.3317
            },
            "El Eulma": {
                "lat": 36.15,
                "lng": 5.6833
            },
            "Bordj el Kiffan": {
                "lat": 36.7375,
                "lng": 3.1931
            },
            "Mostaganem": {
                "lat": 35.9333,
                "lng": 0.0833
            },
            "Touggourt": {
                "lat": 33.1,
                "lng": 6.0667
            },
            "M\u00e9d\u00e9a": {
                "lat": 36.2675,
                "lng": 2.75
            },
            "Tizi Ouzou": {
                "lat": 36.7167,
                "lng": 4.05
            },
            "El Oued": {
                "lat": 33.3611,
                "lng": 6.8606
            },
            "Laghouat": {
                "lat": 33.8028,
                "lng": 2.875
            },
            "M\u2019Sila": {
                "lat": 35.7019,
                "lng": 4.5472
            },
            "Jijel": {
                "lat": 36.8167,
                "lng": 5.75
            },
            "Relizane": {
                "lat": 35.7333,
                "lng": 0.55
            },
            "Sa\u00efda": {
                "lat": 34.8333,
                "lng": 0.15
            },
            "Baraki": {
                "lat": 36.6667,
                "lng": 3.1
            },
            "Guelma": {
                "lat": 36.45,
                "lng": 7.4333
            },
            "Gharda\u00efa": {
                "lat": 32.4833,
                "lng": 3.6667
            },
            "A\u00efn Be\u00efda": {
                "lat": 35.7833,
                "lng": 7.3833
            },
            "Maghnia": {
                "lat": 34.8617,
                "lng": -1.7306
            },
            "Bou Saada": {
                "lat": 36.7667,
                "lng": 4.5833
            },
            "Bou Sa\u00e2da": {
                "lat": 35.2083,
                "lng": 4.1739
            },
            "Mascara": {
                "lat": 35.4,
                "lng": 0.1333
            },
            "Khenchela": {
                "lat": 35.4167,
                "lng": 7.1333
            },
            "Barika": {
                "lat": 35.3972,
                "lng": 5.3658
            },
            "Messaad": {
                "lat": 34.1667,
                "lng": 3.5
            },
            "Aflou": {
                "lat": 34.1089,
                "lng": 2.1019
            },
            "A\u00efn Oussera": {
                "lat": 35.4489,
                "lng": 2.9044
            },
            "Oran": {
                "lat": 35.6969,
                "lng": -0.6331
            },
            "Es Senia": {
                "lat": 35.65,
                "lng": -0.6333
            },
            "Bab Ezzouar": {
                "lat": 36.7217,
                "lng": 3.1853
            },
            "Tamanrasset": {
                "lat": 22.7889,
                "lng": 5.5256
            },
            "Arzew": {
                "lat": 35.85,
                "lng": -0.3167
            },
            "A\u00efn M\u2019Lila": {
                "lat": 36.0361,
                "lng": 6.5708
            },
            "Hassi Bahbah": {
                "lat": 35.0667,
                "lng": 3.0333
            },
            "El Bayadh": {
                "lat": 33.6803,
                "lng": 1.0203
            },
            "Mohammadia": {
                "lat": 35.5833,
                "lng": 0.0667
            },
            "Khemis Miliana": {
                "lat": 36.2667,
                "lng": 2.2167
            },
            "El Milia": {
                "lat": 36.75,
                "lng": 6.2667
            },
            "Hamma Bouziane": {
                "lat": 36.5333,
                "lng": 6.7167
            },
            "Chelghoum el A\u00efd": {
                "lat": 36.1667,
                "lng": 6.1667
            },
            "Dar el Be\u00efda": {
                "lat": 36.7142,
                "lng": 3.2125
            },
            "Oum el Bouaghi": {
                "lat": 35.8775,
                "lng": 7.1136
            },
            "Sougueur": {
                "lat": 35.1833,
                "lng": 1.5
            },
            "Taher": {
                "lat": 36.7719,
                "lng": 5.8983
            },
            "Birkhadem": {
                "lat": 36.7167,
                "lng": 3.05
            },
            "Bir el Ater": {
                "lat": 34.7497,
                "lng": 8.0578
            },
            "Khemis el Khechna": {
                "lat": 36.65,
                "lng": 3.3306
            },
            "Merouana": {
                "lat": 35.6333,
                "lng": 5.9167
            },
            "A\u00efn Temouchent": {
                "lat": 35.3,
                "lng": -1.1333
            },
            "Cheria": {
                "lat": 35.2667,
                "lng": 7.75
            },
            "Tissemsilt": {
                "lat": 35.6078,
                "lng": 1.8111
            },
            "A\u00efn Oulmene": {
                "lat": 35.9167,
                "lng": 5.3
            },
            "Sig": {
                "lat": 35.5278,
                "lng": -0.1861
            },
            "Mila": {
                "lat": 36.4503,
                "lng": 6.2644
            },
            "Bouira": {
                "lat": 36.38,
                "lng": 3.9014
            },
            "Adrar": {
                "lat": 27.8667,
                "lng": -0.2833
            },
            "Ksar el Boukhari": {
                "lat": 35.8856,
                "lng": 2.7508
            },
            "Regha\u00efa": {
                "lat": 36.7631,
                "lng": 3.3426
            },
            "A\u00efn Defla": {
                "lat": 36.2652,
                "lng": 1.9703
            },
            "Hadjout": {
                "lat": 36.5167,
                "lng": 2.4167
            },
            "Mecheria": {
                "lat": 33.55,
                "lng": -0.2833
            },
            "Bordj Mena\u00efel": {
                "lat": 36.7417,
                "lng": 3.7231
            },
            "Oued Rhiou": {
                "lat": 35.9667,
                "lng": 0.9167
            },
            "Assi Bou Nif": {
                "lat": 35.7,
                "lng": -0.5
            },
            "Ouled Djellal": {
                "lat": 34.4167,
                "lng": 5.0667
            },
            "Tighenif": {
                "lat": 35.4167,
                "lng": 0.3333
            },
            "Rouiba": {
                "lat": 36.7333,
                "lng": 3.2833
            },
            "Kolea": {
                "lat": 36.6406,
                "lng": 2.765
            },
            "Berrouaghia": {
                "lat": 36.1333,
                "lng": 2.9167
            },
            "Baba Hassen": {
                "lat": 36.7,
                "lng": 2.9667
            },
            "Meskiana": {
                "lat": 35.6333,
                "lng": 7.6667
            },
            "A\u00efn Touta": {
                "lat": 35.3833,
                "lng": 5.9
            },
            "Lakhdaria": {
                "lat": 36.5622,
                "lng": 3.5944
            },
            "Sidi ech Chahmi": {
                "lat": 35.6667,
                "lng": -0.5167
            },
            "Rouissat": {
                "lat": 31.9167,
                "lng": 5.35
            },
            "El Attaf": {
                "lat": 36.2167,
                "lng": 1.6667
            },
            "Boufarik": {
                "lat": 36.5706,
                "lng": 2.9108
            },
            "Azzaba": {
                "lat": 36.7333,
                "lng": 7.1
            },
            "Boudouaou": {
                "lat": 36.73,
                "lng": 3.41
            },
            "Oulad Ya\u00efch": {
                "lat": 36.5039,
                "lng": 2.8619
            },
            "Morsott": {
                "lat": 35.6667,
                "lng": 8.0167
            },
            "Frenda": {
                "lat": 35.0667,
                "lng": 1.05
            },
            "Ksar Chellala": {
                "lat": 35.2167,
                "lng": 2.3167
            },
            "Ouenza": {
                "lat": 35.95,
                "lng": 8.1333
            },
            "A\u00efn Sefra": {
                "lat": 32.75,
                "lng": -0.5833
            },
            "Akbou": {
                "lat": 36.4667,
                "lng": 4.5333
            },
            "Zeralda": {
                "lat": 36.7167,
                "lng": 2.85
            },
            "R\u00e2s el Oued": {
                "lat": 35.9497,
                "lng": 5.0358
            },
            "Boukadir": {
                "lat": 36.0667,
                "lng": 1.1333
            },
            "\u2019A\u00efn Merane": {
                "lat": 36.1628,
                "lng": 0.9706
            },
            "Tamalous": {
                "lat": 36.8361,
                "lng": 6.6417
            },
            "Djamaa": {
                "lat": 33.5333,
                "lng": 6.0
            },
            "Sour el Ghozlane": {
                "lat": 36.1472,
                "lng": 3.6906
            },
            "Saoula": {
                "lat": 36.7,
                "lng": 3.0167
            },
            "\u2019A\u00efn el Turk": {
                "lat": 35.7408,
                "lng": -0.7492
            },
            "El Megha\u00efer": {
                "lat": 33.9506,
                "lng": 5.9242
            },
            "Fouka": {
                "lat": 36.6667,
                "lng": 2.75
            },
            "Telerghma": {
                "lat": 36.1167,
                "lng": 6.3667
            },
            "A\u00efn Fakroun": {
                "lat": 35.9667,
                "lng": 6.8667
            },
            "\u2019A\u00efn Azel": {
                "lat": 35.8433,
                "lng": 5.5219
            },
            "Chetouane": {
                "lat": 34.9203,
                "lng": -1.2897
            },
            "Oued Sly": {
                "lat": 36.1,
                "lng": 1.2
            },
            "Douera": {
                "lat": 36.6667,
                "lng": 2.95
            },
            "Ouled Moussa": {
                "lat": 36.6831,
                "lng": 3.3681
            },
            "Tindouf": {
                "lat": 27.6753,
                "lng": -8.1286
            },
            "Hassi Messaoud": {
                "lat": 31.7,
                "lng": 6.0667
            },
            "Miliana": {
                "lat": 36.3,
                "lng": 2.2333
            },
            "Beni Saf": {
                "lat": 35.3,
                "lng": -1.3833
            },
            "\u2019A\u00efn Arnat": {
                "lat": 36.1833,
                "lng": 5.3167
            },
            "El Affroun": {
                "lat": 36.4667,
                "lng": 2.6333
            },
            "Tadjenanet": {
                "lat": 36.1167,
                "lng": 5.9833
            },
            "Oued Fodda": {
                "lat": 36.1833,
                "lng": 1.5333
            },
            "Boumerdes": {
                "lat": 36.7603,
                "lng": 3.4724
            },
            "Meftah": {
                "lat": 36.6167,
                "lng": 3.2333
            },
            "Sidi Moussa": {
                "lat": 36.6056,
                "lng": 3.0886
            },
            "Metlili Chaamba": {
                "lat": 32.2728,
                "lng": 3.6275
            },
            "Reguiba": {
                "lat": 33.5667,
                "lng": 6.7167
            },
            "El Golea": {
                "lat": 30.5833,
                "lng": 2.8833
            },
            "Sebdou": {
                "lat": 34.6333,
                "lng": -1.3333
            },
            "\u2019A\u00efn el Melh": {
                "lat": 34.8483,
                "lng": 4.1611
            },
            "Magra": {
                "lat": 35.6,
                "lng": 5.0667
            },
            "Guemar": {
                "lat": 33.5,
                "lng": 6.8333
            },
            "Sidi Khaled": {
                "lat": 34.3833,
                "lng": 4.9833
            },
            "Staoueli": {
                "lat": 36.75,
                "lng": 2.8833
            },
            "Draa el Mizan": {
                "lat": 36.5333,
                "lng": 3.8333
            },
            "A\u00efn Tedeles": {
                "lat": 35.9947,
                "lng": 0.2956
            },
            "Em\u00eer Abdelkader": {
                "lat": 36.75,
                "lng": 5.8333
            },
            "Bougara": {
                "lat": 36.5333,
                "lng": 3.0833
            },
            "Souma": {
                "lat": 36.5183,
                "lng": 2.9053
            },
            "Ouled Beni Messous": {
                "lat": 36.7833,
                "lng": 2.9833
            },
            "Oued Athmenia": {
                "lat": 36.2497,
                "lng": 6.2861
            },
            "Collo": {
                "lat": 37.0056,
                "lng": 6.5603
            },
            "T\u00e9n\u00e8s": {
                "lat": 36.5122,
                "lng": 1.3044
            },
            "Bou Noura": {
                "lat": 32.4825,
                "lng": 3.7036
            },
            "Hammam Bou Hadjar": {
                "lat": 35.3792,
                "lng": -0.9706
            },
            "Kherrata": {
                "lat": 36.5,
                "lng": 5.2833
            },
            "Tebesbest": {
                "lat": 33.1167,
                "lng": 6.0833
            },
            "Ouled Fares": {
                "lat": 36.2667,
                "lng": 1.1833
            },
            "Hammam Dalaa": {
                "lat": 35.9281,
                "lng": 4.3744
            },
            "Azazga": {
                "lat": 36.7453,
                "lng": 4.3711
            },
            "Sidi Lakhdar": {
                "lat": 36.2653,
                "lng": 2.1617
            },
            "A\u00efn Taya": {
                "lat": 36.7917,
                "lng": 3.2889
            },
            "Cherchell": {
                "lat": 36.6075,
                "lng": 2.19
            },
            "Takhemaret": {
                "lat": 35.1072,
                "lng": 0.6833
            },
            "Oued el Alleug": {
                "lat": 36.55,
                "lng": 2.7833
            },
            "Selmane": {
                "lat": 35.6833,
                "lng": 4.7833
            },
            "Beni Tamou": {
                "lat": 36.5333,
                "lng": 2.8167
            },
            "Beni Slimane": {
                "lat": 36.227,
                "lng": 3.306
            },
            "Ghazaouet": {
                "lat": 35.1,
                "lng": -1.85
            },
            "Sidi Okba": {
                "lat": 34.75,
                "lng": 5.9
            },
            "Didouche Mourad": {
                "lat": 36.45,
                "lng": 6.6333
            },
            "El Arrouch": {
                "lat": 36.6531,
                "lng": 6.8364
            },
            "Timimoun": {
                "lat": 29.2628,
                "lng": -0.2389
            },
            "\u2018A\u00efn el Hadjel": {
                "lat": 35.6739,
                "lng": 3.8817
            },
            "Dellys": {
                "lat": 36.9133,
                "lng": 3.9141
            },
            "Oued Zenati": {
                "lat": 36.3167,
                "lng": 7.1667
            },
            "A\u00efn el Bya": {
                "lat": 35.8167,
                "lng": -0.2833
            },
            "Arris": {
                "lat": 35.25,
                "lng": 6.35
            },
            "A\u00efn Bessem": {
                "lat": 36.2967,
                "lng": 3.67
            },
            "I-n-Salah": {
                "lat": 27.195,
                "lng": 2.4833
            },
            "Nedroma": {
                "lat": 35.0,
                "lng": -1.7333
            },
            "El Abiodh Sidi Cheikh": {
                "lat": 32.8986,
                "lng": 0.5444
            },
            "Bougaa": {
                "lat": 36.3333,
                "lng": 5.0833
            },
            "Sidi Ali": {
                "lat": 36.1047,
                "lng": 0.4233
            },
            "Remchi": {
                "lat": 35.0667,
                "lng": -1.4333
            },
            "Hassi Khelifa": {
                "lat": 33.5622,
                "lng": 6.9903
            },
            "\u2019A\u00efn Abid": {
                "lat": 36.2325,
                "lng": 6.944
            },
            "Draa Ben Khedda": {
                "lat": 36.7333,
                "lng": 3.9667
            },
            "Boghni": {
                "lat": 36.55,
                "lng": 3.95
            },
            "Birtouta": {
                "lat": 36.65,
                "lng": 3.0
            },
            "A\u00efn Kercha": {
                "lat": 35.9167,
                "lng": 6.7
            },
            "Chabet el Ameur": {
                "lat": 36.6333,
                "lng": 3.7
            },
            "Berriane": {
                "lat": 32.8333,
                "lng": 3.7667
            },
            "Djendel": {
                "lat": 36.2186,
                "lng": 2.4089
            },
            "Zemoura": {
                "lat": 35.7167,
                "lng": 0.75
            },
            "El Idrissia": {
                "lat": 34.45,
                "lng": 2.5333
            },
            "El Kseur": {
                "lat": 36.6844,
                "lng": 4.8522
            },
            "N\u2019Gaous": {
                "lat": 35.555,
                "lng": 5.6106
            },
            "Tizi Gheniff": {
                "lat": 36.5833,
                "lng": 3.7667
            },
            "L\u2019Arbaa Na\u00eft Irathen": {
                "lat": 36.6367,
                "lng": 4.2067
            },
            "Sendjas": {
                "lat": 36.0667,
                "lng": 1.4
            },
            "Ouled Haddaj": {
                "lat": 36.72,
                "lng": 3.35
            },
            "Ahmer el \u2019A\u00efn": {
                "lat": 36.4781,
                "lng": 2.5639
            },
            "Timizart": {
                "lat": 36.8,
                "lng": 4.2667
            },
            "Ammi Moussa": {
                "lat": 35.8667,
                "lng": 1.1167
            },
            "Tazmalt": {
                "lat": 36.3844,
                "lng": 4.3992
            },
            "Zighout Youcef": {
                "lat": 36.5333,
                "lng": 6.7167
            },
            "Sabra": {
                "lat": 34.8333,
                "lng": -1.5333
            },
            "Mouza\u00efa": {
                "lat": 36.4667,
                "lng": 2.6833
            },
            "Ouled Ben Abd el Kader": {
                "lat": 36.0258,
                "lng": 1.2764
            },
            "Tablat": {
                "lat": 36.4167,
                "lng": 3.3167
            },
            "Babar": {
                "lat": 35.1692,
                "lng": 7.1013
            },
            "Isser": {
                "lat": 36.716,
                "lng": 3.666
            },
            "Sfizef": {
                "lat": 35.2333,
                "lng": -0.25
            },
            "Bordj el Bahri": {
                "lat": 36.8167,
                "lng": 3.2333
            },
            "Theniet el Had": {
                "lat": 35.8708,
                "lng": 2.0289
            },
            "Ouled Fayet": {
                "lat": 36.7333,
                "lng": 2.95
            },
            "Taougrite": {
                "lat": 36.25,
                "lng": 0.9167
            },
            "Ouled Slama Tahta": {
                "lat": 36.5483,
                "lng": 3.11
            },
            "Tazoult-Lambese": {
                "lat": 35.49,
                "lng": 6.26
            },
            "El Abadia": {
                "lat": 36.2667,
                "lng": 1.6833
            },
            "Barbacha": {
                "lat": 36.5667,
                "lng": 4.9667
            },
            "Bouguirat": {
                "lat": 35.7508,
                "lng": 0.2556
            },
            "Feidh el Botma": {
                "lat": 34.5278,
                "lng": 3.7819
            },
            "Birine": {
                "lat": 35.6333,
                "lng": 3.2167
            },
            "Dar Chioukh": {
                "lat": 34.9,
                "lng": 3.4833
            },
            "Sidi Akkacha": {
                "lat": 36.4667,
                "lng": 1.3
            },
            "Zeboudja": {
                "lat": 36.35,
                "lng": 1.4333
            },
            "Hennaya": {
                "lat": 34.95,
                "lng": -1.3667
            },
            "Zemmouri": {
                "lat": 36.7833,
                "lng": 3.6
            },
            "Ouled Mimoun": {
                "lat": 34.9,
                "lng": -1.0333
            },
            "Kaous": {
                "lat": 36.7702,
                "lng": 5.8136
            },
            "Ouled Rahmoun": {
                "lat": 36.1803,
                "lng": 6.705
            },
            "Misserghin": {
                "lat": 35.6167,
                "lng": -0.7333
            },
            "Chellalat el Adhaouara": {
                "lat": 35.9403,
                "lng": 3.4139
            },
            "El Hadjar": {
                "lat": 36.8,
                "lng": 7.7333
            },
            "Foughala": {
                "lat": 34.7167,
                "lng": 5.3167
            },
            "El Tarf": {
                "lat": 36.767,
                "lng": 8.317
            },
            "Menaceur": {
                "lat": 36.492,
                "lng": 2.2208
            },
            "Rouached": {
                "lat": 36.4583,
                "lng": 6.0397
            },
            "Tipasa": {
                "lat": 36.5919,
                "lng": 2.4494
            },
            "Chekfa": {
                "lat": 36.7667,
                "lng": 5.9667
            },
            "Debila": {
                "lat": 33.5064,
                "lng": 6.9381
            },
            "El Karimia": {
                "lat": 36.1167,
                "lng": 1.55
            },
            "T\u00e9lagh": {
                "lat": 34.7833,
                "lng": -0.5667
            },
            "A\u00efn Kechera": {
                "lat": 36.7483,
                "lng": 6.4325
            },
            "\u2019A\u00efn Boucif": {
                "lat": 35.8912,
                "lng": 3.1585
            },
            "Tadjmout": {
                "lat": 33.8667,
                "lng": 2.5167
            },
            "Mekla": {
                "lat": 36.6833,
                "lng": 4.2667
            },
            "Freha": {
                "lat": 36.75,
                "lng": 4.3167
            },
            "Douar Bou Tlelis": {
                "lat": 35.573,
                "lng": -0.9
            },
            "Ksar el Hirane": {
                "lat": 33.7833,
                "lng": 3.15
            },
            "Beni Amrane": {
                "lat": 36.6667,
                "lng": 3.5833
            },
            "Berhoum": {
                "lat": 35.65,
                "lng": 5.0333
            },
            "Khelil": {
                "lat": 36.1756,
                "lng": 5.0292
            },
            "Oued el Djemaa": {
                "lat": 35.7972,
                "lng": 0.6814
            },
            "Beni Rached": {
                "lat": 36.2806,
                "lng": 1.5161
            },
            "Makouda": {
                "lat": 36.7833,
                "lng": 4.0667
            },
            "Bordj Ghdir": {
                "lat": 35.9,
                "lng": 4.89
            },
            "Tadma\u00eft": {
                "lat": 36.75,
                "lng": 3.9
            },
            "Amoucha": {
                "lat": 36.3833,
                "lng": 5.4167
            },
            "Bouzeghaia": {
                "lat": 36.3333,
                "lng": 1.25
            },
            "El Amria": {
                "lat": 35.5333,
                "lng": -1.0167
            },
            "Naciria": {
                "lat": 36.75,
                "lng": 3.8333
            },
            "Mechta Ouled Oulha": {
                "lat": 35.6706,
                "lng": 4.8778
            },
            "Kadiria": {
                "lat": 36.5333,
                "lng": 3.6833
            },
            "Had Sahary": {
                "lat": 35.3517,
                "lng": 3.3608
            },
            "Souahlia": {
                "lat": 35.0333,
                "lng": -1.8833
            },
            "Khe\u00efredine": {
                "lat": 35.9806,
                "lng": 0.1681
            },
            "Ghriss": {
                "lat": 35.25,
                "lng": 0.1667
            },
            "Bourkika": {
                "lat": 36.5,
                "lng": 2.4833
            },
            "Djinet": {
                "lat": 36.8833,
                "lng": 3.7167
            },
            "Robbah": {
                "lat": 33.2811,
                "lng": 6.9108
            },
            "Sayada": {
                "lat": 35.95,
                "lng": 0.1333
            },
            "Hassi Maameche": {
                "lat": 35.8611,
                "lng": 0.725
            },
            "Sidi Amrane": {
                "lat": 33.5,
                "lng": 6.0167
            },
            "Sirat": {
                "lat": 35.78,
                "lng": 0.1919
            },
            "M\u2019Chedallah": {
                "lat": 36.3667,
                "lng": 4.2667
            },
            "Beni Douala": {
                "lat": 36.6167,
                "lng": 4.0833
            },
            "Chebli": {
                "lat": 36.5778,
                "lng": 3.0083
            },
            "Beni Mered": {
                "lat": 36.5233,
                "lng": 2.8617
            },
            "Thenia": {
                "lat": 36.7278,
                "lng": 3.5539
            },
            "Lioua": {
                "lat": 34.6375,
                "lng": 5.3958
            },
            "Oum Drou": {
                "lat": 36.2,
                "lng": 1.3833
            },
            "Mansourah": {
                "lat": 36.0819,
                "lng": 4.46
            },
            "Oued el Aneb": {
                "lat": 36.8833,
                "lng": 7.4833
            },
            "Tizi-n-Bechar": {
                "lat": 36.4311,
                "lng": 5.36
            },
            "Sidi Lahssen": {
                "lat": 35.1633,
                "lng": -0.6958
            },
            "Bordj Bounaama": {
                "lat": 35.85,
                "lng": 1.6167
            },
            "Beni Haoua": {
                "lat": 36.5333,
                "lng": 1.5667
            },
            "El Hammadia": {
                "lat": 35.9833,
                "lng": 4.75
            },
            "Sour": {
                "lat": 36.0006,
                "lng": 0.3414
            },
            "Aomar": {
                "lat": 36.5,
                "lng": 3.7667
            },
            "\u2019A\u00efn el Bell": {
                "lat": 34.35,
                "lng": 3.2167
            },
            "Reggane": {
                "lat": 26.7203,
                "lng": 0.1728
            },
            "\u2019A\u00efn el Hammam": {
                "lat": 36.5714,
                "lng": 4.3097
            },
            "Tala Yfassene": {
                "lat": 36.4583,
                "lng": 5.0889
            },
            "Ta\u00efbet": {
                "lat": 33.0833,
                "lng": 6.3833
            },
            "Abou el Hassan": {
                "lat": 36.4167,
                "lng": 1.2
            },
            "El Ancer": {
                "lat": 36.8,
                "lng": 6.1667
            },
            "Temacine": {
                "lat": 33.0219,
                "lng": 6.0228
            },
            "Mesra": {
                "lat": 35.8333,
                "lng": 0.1667
            },
            "Sidi Merouane": {
                "lat": 36.5333,
                "lng": 1.3333
            },
            "\u2019A\u00efn Mabed": {
                "lat": 34.8,
                "lng": 3.1333
            },
            "Merad": {
                "lat": 36.4667,
                "lng": 2.4333
            },
            "Recha\u00efga": {
                "lat": 35.4081,
                "lng": 1.9733
            },
            "Bir Kasdali": {
                "lat": 36.15,
                "lng": 5.0333
            },
            "Arbaoun": {
                "lat": 36.4667,
                "lng": 5.65
            },
            "Charef": {
                "lat": 34.6167,
                "lng": 2.8
            },
            "Arbatache": {
                "lat": 36.6361,
                "lng": 3.3708
            },
            "Oued el Abtal": {
                "lat": 35.45,
                "lng": 0.6833
            },
            "Tirmitine": {
                "lat": 36.6618,
                "lng": 3.9848
            },
            "El Malah": {
                "lat": 35.3883,
                "lng": -1.0944
            },
            "Zahana": {
                "lat": 35.5167,
                "lng": -0.4167
            },
            "Ben \u2019Akno\u00fbn": {
                "lat": 36.75,
                "lng": 3.0
            },
            "Medjedel": {
                "lat": 35.15,
                "lng": 3.6833
            },
            "Timoktene": {
                "lat": 27.0217,
                "lng": 1.015
            },
            "Bougtob": {
                "lat": 34.0425,
                "lng": 0.0892
            },
            "Bettioua": {
                "lat": 35.8,
                "lng": -0.2667
            },
            "El Achir": {
                "lat": 36.0667,
                "lng": 4.6333
            },
            "Maoussa": {
                "lat": 35.3833,
                "lng": 0.25
            },
            "Ben Daoud": {
                "lat": 35.7192,
                "lng": 0.5203
            },
            "El Bordj": {
                "lat": 35.5158,
                "lng": 0.3017
            },
            "Sidi Kada": {
                "lat": 35.3333,
                "lng": 0.35
            },
            "Herenfa": {
                "lat": 36.2453,
                "lng": 1.0458
            },
            "Beni Fouda": {
                "lat": 36.2861,
                "lng": 5.6072
            },
            "El Omaria": {
                "lat": 36.2667,
                "lng": 3.0333
            },
            "Fornaka": {
                "lat": 35.7517,
                "lng": -0.0161
            },
            "Sigus": {
                "lat": 36.1167,
                "lng": 6.7833
            },
            "Oued el Khe\u00efr": {
                "lat": 35.9503,
                "lng": 0.3808
            },
            "A\u00efn Zaou\u00efa": {
                "lat": 36.55,
                "lng": 3.8944
            },
            "El Kouif": {
                "lat": 35.5,
                "lng": 8.3167
            },
            "Illizi": {
                "lat": 26.505,
                "lng": 8.4822
            },
            "Souaflia": {
                "lat": 35.85,
                "lng": 0.3333
            },
            "Tizi Rached": {
                "lat": 36.6667,
                "lng": 4.1833
            },
            "Damous": {
                "lat": 36.55,
                "lng": 1.7
            },
            "Bou Hanifia el Hamamat": {
                "lat": 35.3167,
                "lng": -0.05
            },
            "Mers el Kebir": {
                "lat": 35.7286,
                "lng": -0.7069
            },
            "Sidi Daoud": {
                "lat": 36.85,
                "lng": 3.85
            },
            "Ighram": {
                "lat": 36.4667,
                "lng": 4.5167
            },
            "Azeffoun": {
                "lat": 36.9,
                "lng": 4.4167
            },
            "A\u00efn Feka": {
                "lat": 35.4333,
                "lng": 3.5833
            },
            "\u2019A\u00efn Abessa": {
                "lat": 36.3,
                "lng": 5.295
            },
            "Djouab": {
                "lat": 36.1333,
                "lng": 3.4333
            },
            "N\u2019Goussa": {
                "lat": 32.1408,
                "lng": 5.3083
            },
            "Tichi": {
                "lat": 36.6667,
                "lng": 5.1667
            },
            "Bordj Mokhtar": {
                "lat": 21.3289,
                "lng": 0.9542
            },
            "Bou Hadjar": {
                "lat": 36.5031,
                "lng": 8.1053
            },
            "Ouled Chebel": {
                "lat": 36.6,
                "lng": 3.0
            },
            "Berriche": {
                "lat": 35.9167,
                "lng": 7.3833
            },
            "Naama": {
                "lat": 33.2622,
                "lng": -0.3144
            },
            "Chorfa": {
                "lat": 36.3617,
                "lng": 4.3308
            },
            "\u2019A\u00efn Babouche": {
                "lat": 35.9411,
                "lng": 7.1856
            },
            "Medjana": {
                "lat": 36.1333,
                "lng": 4.6667
            },
            "El Ach": {
                "lat": 35.95,
                "lng": 4.6833
            },
            "Mecheraa Asfa": {
                "lat": 35.3839,
                "lng": 1.0533
            },
            "Ouamri": {
                "lat": 36.2333,
                "lng": 2.5667
            },
            "Baghlia": {
                "lat": 36.8167,
                "lng": 3.85
            },
            "Hammam M\u2019Ba\u00efls": {
                "lat": 36.3258,
                "lng": 7.6436
            },
            "Hadjadj": {
                "lat": 36.1,
                "lng": 0.3333
            },
            "Ouadhia": {
                "lat": 36.55,
                "lng": 4.0833
            },
            "\u2019A\u00efn el Arbaa": {
                "lat": 35.4075,
                "lng": -0.8817
            },
            "Boudjima": {
                "lat": 36.8,
                "lng": 4.15
            },
            "Mouiat Ouennsa": {
                "lat": 33.1969,
                "lng": 6.7053
            },
            "Souagui": {
                "lat": 36.1167,
                "lng": 3.2333
            },
            "Ebn Ziad": {
                "lat": 36.3833,
                "lng": 6.4667
            },
            "Ain el Hadid": {
                "lat": 35.0581,
                "lng": 0.8833
            },
            "Tizi-n-Tleta": {
                "lat": 36.5457,
                "lng": 4.0571
            },
            "Guerouma": {
                "lat": 36.4833,
                "lng": 3.4333
            },
            "Medrissa": {
                "lat": 34.9,
                "lng": 1.2333
            },
            "Sidi Ghiles": {
                "lat": 36.5833,
                "lng": 2.1167
            },
            "Chemini": {
                "lat": 36.6,
                "lng": 4.6167
            },
            "Boumahra Ahmed": {
                "lat": 36.45,
                "lng": 7.5167
            },
            "Es Sebt": {
                "lat": 36.6624,
                "lng": 7.0774
            },
            "Melouza": {
                "lat": 35.9808,
                "lng": 4.1861
            },
            "Mazagran": {
                "lat": 35.9019,
                "lng": 0.7269
            },
            "Belkheir": {
                "lat": 36.4667,
                "lng": 7.4833
            },
            "Bouchegouf": {
                "lat": 36.4667,
                "lng": 7.4833
            },
            "Texenna": {
                "lat": 36.65,
                "lng": 5.7833
            },
            "El Hadjira": {
                "lat": 32.6167,
                "lng": 5.5167
            },
            "Tsabit": {
                "lat": 28.3503,
                "lng": -0.2208
            },
            "Harchoune": {
                "lat": 36.1139,
                "lng": 1.5056
            },
            "Oulad Dahmane": {
                "lat": 36.3667,
                "lng": 3.4667
            },
            "El Ateuf": {
                "lat": 32.4775,
                "lng": 3.7478
            },
            "Labiod Medjadja": {
                "lat": 36.25,
                "lng": 1.4
            },
            "Chaabat el Leham": {
                "lat": 35.3361,
                "lng": -1.1011
            },
            "Bellaa": {
                "lat": 36.2,
                "lng": 5.85
            },
            "Djanet": {
                "lat": 24.555,
                "lng": 9.4853
            },
            "Djebahia": {
                "lat": 36.4764,
                "lng": 3.7578
            },
            "Benfreha": {
                "lat": 35.6933,
                "lng": -0.4192
            },
            "Adekar Kebouche": {
                "lat": 36.6833,
                "lng": 4.6667
            },
            "Blidet Amor": {
                "lat": 32.9514,
                "lng": 5.9806
            },
            "A\u00efn Nouissy": {
                "lat": 35.8,
                "lng": 0.05
            },
            "Ouled Sidi Brahim": {
                "lat": 36.3667,
                "lng": 3.7167
            },
            "Ahmed Rachedi": {
                "lat": 36.4,
                "lng": 6.1167
            },
            "A\u00eft Ya\u00efch": {
                "lat": 36.5811,
                "lng": 4.3303
            },
            "Iflissen": {
                "lat": 36.8636,
                "lng": 4.2203
            },
            "Mnagueur": {
                "lat": 33.1264,
                "lng": 6.3519
            },
            "Mers el Hadjad": {
                "lat": 35.7833,
                "lng": -0.1667
            },
            "Bougzoul": {
                "lat": 35.6997,
                "lng": 2.8478
            },
            "Sidi Ben Adda": {
                "lat": 35.3,
                "lng": -1.1833
            },
            "Moudjbara": {
                "lat": 34.5037,
                "lng": 3.4704
            },
            "Khadra": {
                "lat": 36.25,
                "lng": 0.5667
            },
            "Selmana": {
                "lat": 34.1833,
                "lng": 3.6
            },
            "Takerbouzt": {
                "lat": 36.418,
                "lng": 4.3428
            },
            "Tarhzout": {
                "lat": 33.4833,
                "lng": 6.75
            },
            "Oued Taria": {
                "lat": 35.1167,
                "lng": 0.0833
            },
            "Tidjelabine": {
                "lat": 36.7333,
                "lng": 3.5
            },
            "Megarine": {
                "lat": 33.1944,
                "lng": 6.0944
            },
            "Settara": {
                "lat": 36.7167,
                "lng": 6.3333
            },
            "El Kerma": {
                "lat": 35.6167,
                "lng": -0.5667
            },
            "Abadla": {
                "lat": 31.0167,
                "lng": -2.7333
            },
            "Menaa": {
                "lat": 35.1667,
                "lng": 6.0
            },
            "Kenadsa": {
                "lat": 31.5333,
                "lng": -2.4167
            },
            "El Hachimia": {
                "lat": 36.2333,
                "lng": 3.8333
            },
            "Bou Sfer": {
                "lat": 35.7108,
                "lng": -0.8106
            },
            "Chahbounia": {
                "lat": 35.55,
                "lng": 2.6
            },
            "Bouzina": {
                "lat": 35.2833,
                "lng": 6.1167
            },
            "Sidi Abd el Moumene": {
                "lat": 35.6528,
                "lng": 0.0139
            },
            "Bouskene": {
                "lat": 36.1833,
                "lng": 3.2333
            },
            "Oued Tl\u00e9lat": {
                "lat": 35.55,
                "lng": -0.45
            },
            "A\u00efn Youcef": {
                "lat": 35.05,
                "lng": -1.3667
            },
            "Afir": {
                "lat": 36.7676,
                "lng": 3.7029
            },
            "Benairia": {
                "lat": 36.35,
                "lng": 1.3833
            },
            "A\u00efn Lechiakh": {
                "lat": 36.1569,
                "lng": 2.4042
            },
            "\u2019A\u00efn Mouilah": {
                "lat": 34.75,
                "lng": 3.4833
            },
            "Sali": {
                "lat": 25.9606,
                "lng": -0.0275
            },
            "Bensekrane": {
                "lat": 35.0667,
                "lng": -1.2167
            },
            "\u2019A\u00efn Tolba": {
                "lat": 35.2483,
                "lng": -1.2489
            },
            "Debbache el Hadj Douadi": {
                "lat": 36.275,
                "lng": 6.1764
            },
            "Mechtras": {
                "lat": 36.55,
                "lng": 4.0
            },
            "Nakhla": {
                "lat": 33.2833,
                "lng": 6.95
            },
            "Ouzera": {
                "lat": 36.25,
                "lng": 2.85
            },
            "Ziama Mansouria": {
                "lat": 36.6667,
                "lng": 5.4833
            },
            "Gueltat Sidi Saad": {
                "lat": 34.3,
                "lng": 1.95
            },
            "El Adjiba": {
                "lat": 36.3333,
                "lng": 4.15
            },
            "\u2019A\u00efn el Assel": {
                "lat": 36.7864,
                "lng": 8.3825
            },
            "Arhribs": {
                "lat": 36.8022,
                "lng": 4.3228
            },
            "Iferhounene": {
                "lat": 36.5167,
                "lng": 4.3833
            },
            "El Aouana": {
                "lat": 36.7667,
                "lng": 5.6
            },
            "Sidi Makhlouf": {
                "lat": 34.1333,
                "lng": 3.0167
            },
            "Sidi Aoun": {
                "lat": 33.5422,
                "lng": 6.905
            },
            "Hassi Fedoul": {
                "lat": 35.4333,
                "lng": 2.2167
            },
            "Yakouren": {
                "lat": 36.7333,
                "lng": 4.45
            },
            "\u2019A\u00efn Kerma": {
                "lat": 36.5939,
                "lng": 8.2011
            },
            "Hassi el Ghella": {
                "lat": 35.45,
                "lng": -1.05
            },
            "El Marsa": {
                "lat": 36.8111,
                "lng": 3.2556
            },
            "Si Mustapha": {
                "lat": 36.7247,
                "lng": 3.6153
            },
            "Froha": {
                "lat": 35.3,
                "lng": 0.1333
            },
            "Stidia": {
                "lat": 35.8333,
                "lng": -0.3
            },
            "Tigzirt": {
                "lat": 36.9,
                "lng": 4.1167
            },
            "Oued Cheham": {
                "lat": 36.3833,
                "lng": 7.7667
            },
            "Fenoughil": {
                "lat": 27.6028,
                "lng": -0.3056
            },
            "Oued Seguin": {
                "lat": 36.171,
                "lng": 6.42
            },
            "Aougrout": {
                "lat": 28.7,
                "lng": 0.3167
            },
            "Sidi Ladjel": {
                "lat": 35.4333,
                "lng": 2.5
            },
            "Bechloul": {
                "lat": 36.3167,
                "lng": 4.0667
            },
            "Tafaraoui": {
                "lat": 35.4833,
                "lng": -0.5167
            },
            "\u2019A\u00efn Roua": {
                "lat": 36.3344,
                "lng": 5.1806
            },
            "El An\u00e7or": {
                "lat": 35.6833,
                "lng": -0.8667
            },
            "Dhalaa": {
                "lat": 35.4611,
                "lng": 7.5472
            },
            "Sidi Baizid": {
                "lat": 35.75,
                "lng": 2.3833
            },
            "Charouine": {
                "lat": 29.0186,
                "lng": -0.2578
            },
            "Teniet el Abed": {
                "lat": 35.2469,
                "lng": 6.1906
            },
            "Djemmorah": {
                "lat": 35.0667,
                "lng": 5.8333
            },
            "Beni Ounif": {
                "lat": 32.05,
                "lng": -1.25
            },
            "Galbois": {
                "lat": 36.2,
                "lng": 4.49
            },
            "\u2019A\u00efn Taghrout": {
                "lat": 36.1289,
                "lng": 5.0767
            },
            "Ksar Sbahi": {
                "lat": 36.0833,
                "lng": 7.25
            },
            "El Hamel": {
                "lat": 35.1333,
                "lng": 4.0833
            },
            "Bir Ghbalou": {
                "lat": 36.2642,
                "lng": 3.5836
            },
            "Tizi": {
                "lat": 35.3167,
                "lng": 0.0667
            },
            "Oued Sebbah": {
                "lat": 35.3725,
                "lng": -0.8114
            },
            "Beni Abb\u00e8s": {
                "lat": 30.08,
                "lng": -2.1
            },
            "Bouchagroun": {
                "lat": 34.7217,
                "lng": 5.4656
            },
            "Bou Khadra": {
                "lat": 35.7444,
                "lng": 8.0325
            },
            "Sidi Namane": {
                "lat": 36.7581,
                "lng": 3.9839
            },
            "Asfour": {
                "lat": 36.6667,
                "lng": 7.9833
            },
            "Bouhmama": {
                "lat": 35.2833,
                "lng": 6.7333
            },
            "\u2019A\u00efn Fekan": {
                "lat": 35.225,
                "lng": -0.0017
            },
            "Bir Ben Laabed": {
                "lat": 36.2175,
                "lng": 3.4267
            },
            "Rabta": {
                "lat": 35.9167,
                "lng": 4.75
            },
            "Boudouaou el Bahri": {
                "lat": 36.7733,
                "lng": 3.3872
            },
            "Bordj Okhriss": {
                "lat": 36.0833,
                "lng": 3.9667
            },
            "Sidi Brahim": {
                "lat": 35.2606,
                "lng": -0.5675
            },
            "Ben Nasseur": {
                "lat": 33.1108,
                "lng": 6.4411
            },
            "Bel Imour": {
                "lat": 35.9806,
                "lng": 4.8797
            },
            "Bordj Zemoura": {
                "lat": 36.2667,
                "lng": 4.85
            },
            "\u2019A\u00efn Tellout": {
                "lat": 34.9167,
                "lng": -0.9667
            },
            "Sidi Embarek": {
                "lat": 36.1039,
                "lng": 4.9119
            },
            "Jean-Mermoz": {
                "lat": 35.5606,
                "lng": -0.0844
            },
            "Benyahia Abderrahmane": {
                "lat": 36.2333,
                "lng": 6.0167
            },
            "Zelfana": {
                "lat": 32.3969,
                "lng": 4.2261
            },
            "Sidi Abdelaziz": {
                "lat": 36.85,
                "lng": 6.05
            },
            "Ouled Rabah": {
                "lat": 36.2833,
                "lng": 3.1333
            },
            "Oggaz": {
                "lat": 35.5667,
                "lng": -0.2667
            },
            "M\u2019Chouneche": {
                "lat": 34.95,
                "lng": 6.0
            },
            "Z\u00e9rizer": {
                "lat": 36.7272,
                "lng": 7.8947
            },
            "Kouinine": {
                "lat": 33.4,
                "lng": 6.8333
            },
            "\u2019A\u00efn Naga": {
                "lat": 34.6883,
                "lng": 6.0886
            },
            "Ben N\u2019Choud": {
                "lat": 36.8622,
                "lng": 3.8806
            },
            "Souama": {
                "lat": 36.6417,
                "lng": 4.3417
            },
            "Tamzoura": {
                "lat": 35.4167,
                "lng": -0.65
            },
            "Bekkaria": {
                "lat": 35.3722,
                "lng": 8.2422
            },
            "Boufatis": {
                "lat": 35.6833,
                "lng": -0.4167
            },
            "Ouled Brahim": {
                "lat": 36.2444,
                "lng": 2.9336
            },
            "Toudja": {
                "lat": 36.75,
                "lng": 4.9
            },
            "Bou Nouh": {
                "lat": 36.5,
                "lng": 3.9333
            },
            "\u2019A\u00efn Kihal": {
                "lat": 35.2044,
                "lng": -1.1961
            },
            "Tixter": {
                "lat": 36.05,
                "lng": 5.0833
            },
            "Tamentit": {
                "lat": 27.7672,
                "lng": -0.2678
            },
            "Negrine": {
                "lat": 34.4833,
                "lng": 7.5167
            },
            "Tarmount": {
                "lat": 35.8167,
                "lng": 4.2833
            },
            "Assi-Ben Okba": {
                "lat": 35.7294,
                "lng": -0.4658
            },
            "Tassera": {
                "lat": 36.0369,
                "lng": 5.0022
            },
            "Lac des Oiseaux": {
                "lat": 36.7833,
                "lng": 8.1167
            },
            "Oued Essalem": {
                "lat": 35.5833,
                "lng": 0.9167
            },
            "Ouled Rached": {
                "lat": 36.2119,
                "lng": 4.1106
            },
            "A\u00efn el Hadjar": {
                "lat": 36.3333,
                "lng": 3.8
            },
            "Zoubiria": {
                "lat": 36.0667,
                "lng": 2.9
            },
            "I-n-Amenas": {
                "lat": 28.0408,
                "lng": 9.5431
            },
            "Tenedla": {
                "lat": 33.6753,
                "lng": 6.0339
            },
            "Hacine": {
                "lat": 35.4569,
                "lng": -0.0025
            },
            "Abalessa": {
                "lat": 22.89,
                "lng": 4.8472
            },
            "Ifigha": {
                "lat": 36.6667,
                "lng": 4.4167
            },
            "Nechmeya": {
                "lat": 36.6114,
                "lng": 7.5133
            },
            "Ksar Belezma": {
                "lat": 35.6756,
                "lng": 5.9033
            },
            "Ouled Rahou": {
                "lat": 36.0069,
                "lng": 0.5919
            },
            "Ben Chicao": {
                "lat": 36.1997,
                "lng": 2.8486
            },
            "Seddouk Oufella": {
                "lat": 36.6061,
                "lng": 4.6389
            },
            "Bouati Mahmoud": {
                "lat": 36.5833,
                "lng": 7.3333
            },
            "El Outaya": {
                "lat": 35.0333,
                "lng": 5.6
            },
            "Chahana": {
                "lat": 36.6794,
                "lng": 5.9572
            },
            "Boukhralfa": {
                "lat": 36.6144,
                "lng": 5.0872
            },
            "Lichana": {
                "lat": 34.7247,
                "lng": 5.4325
            },
            "Chetma": {
                "lat": 34.85,
                "lng": 5.8167
            },
            "Bouaiche": {
                "lat": 35.5528,
                "lng": 2.3586
            },
            "Amalou": {
                "lat": 36.4778,
                "lng": 4.6333
            },
            "El Ghomri": {
                "lat": 35.6833,
                "lng": 0.2
            },
            "Tamza": {
                "lat": 35.3167,
                "lng": 6.8333
            },
            "Ouled Abbes": {
                "lat": 36.2167,
                "lng": 1.4833
            },
            "Tifra": {
                "lat": 36.6667,
                "lng": 4.7
            },
            "In Guezzam": {
                "lat": 19.5686,
                "lng": 5.7722
            },
            "I-n-Amguel": {
                "lat": 23.6936,
                "lng": 5.1647
            }
        }
    },
    "AE": {
        "name": "United Arab Emirates",
        "cities": {
            "Dubai": {
                "lat": 25.2631,
                "lng": 55.2972
            },
            "Abu Dhabi": {
                "lat": 24.4667,
                "lng": 54.3667
            },
            "Sharjah": {
                "lat": 25.3575,
                "lng": 55.3908
            },
            "Al \u2018Ayn": {
                "lat": 24.2075,
                "lng": 55.7447
            },
            "\u2018Ajm\u0101n": {
                "lat": 25.4136,
                "lng": 55.4456
            },
            "Ra\u2019s al Khaymah": {
                "lat": 25.7667,
                "lng": 55.95
            },
            "Al Fujayrah": {
                "lat": 25.1222,
                "lng": 56.3344
            },
            "Umm al Qaywayn": {
                "lat": 25.5533,
                "lng": 55.5475
            },
            "Kalb\u0101": {
                "lat": 25.0742,
                "lng": 56.3553
            },
            "Khawr Fakk\u0101n": {
                "lat": 25.3333,
                "lng": 56.35
            },
            "Mad\u012bnat Z\u0101yid": {
                "lat": 23.6522,
                "lng": 53.6536
            },
            "Al Jaz\u012brah al \u1e28amr\u0101\u2019": {
                "lat": 25.7089,
                "lng": 55.7972
            }
        }
    },
    "BO": {
        "name": "Bolivia",
        "cities": {
            "Santa Cruz de la Sierra": {
                "lat": -17.7892,
                "lng": -63.1975
            },
            "La Paz": {
                "lat": -16.4958,
                "lng": -68.1333
            },
            "Santa Cruz": {
                "lat": -17.8,
                "lng": -63.1833
            },
            "El Alto": {
                "lat": -16.5047,
                "lng": -68.1633
            },
            "Cochabamba": {
                "lat": -17.3883,
                "lng": -66.1597
            },
            "Oruro": {
                "lat": -17.9833,
                "lng": -67.15
            },
            "Sucre": {
                "lat": -19.0475,
                "lng": -65.26
            },
            "Tarija": {
                "lat": -21.5333,
                "lng": -64.7333
            },
            "Potos\u00ed": {
                "lat": -19.5892,
                "lng": -65.7533
            },
            "Warnes": {
                "lat": -17.5167,
                "lng": -63.1667
            },
            "Sacaba": {
                "lat": -17.4042,
                "lng": -66.0408
            },
            "Montero": {
                "lat": -17.3422,
                "lng": -63.2558
            },
            "Quillacollo": {
                "lat": -17.4,
                "lng": -66.2833
            },
            "Trinidad": {
                "lat": -14.8292,
                "lng": -64.9014
            },
            "Riberalta": {
                "lat": -11.0128,
                "lng": -66.0572
            },
            "Yacuiba": {
                "lat": -22.0153,
                "lng": -63.6772
            },
            "Villa Tunari": {
                "lat": -16.9747,
                "lng": -65.4203
            },
            "Colcapirhua": {
                "lat": -17.4167,
                "lng": -66.25
            },
            "Puerto Villarroel": {
                "lat": -16.8667,
                "lng": -64.7831
            },
            "Cobija": {
                "lat": -11.0333,
                "lng": -68.7333
            },
            "Sipe Sipe": {
                "lat": -17.45,
                "lng": -66.3833
            },
            "Villamontes": {
                "lat": -21.2608,
                "lng": -63.4761
            },
            "Guayaramer\u00edn": {
                "lat": -10.8,
                "lng": -65.3833
            },
            "Camiri": {
                "lat": -20.0386,
                "lng": -63.5183
            },
            "Tiquipaya": {
                "lat": -17.3333,
                "lng": -66.2167
            },
            "Viacha": {
                "lat": -16.6533,
                "lng": -68.3017
            },
            "Bermejo": {
                "lat": -22.7322,
                "lng": -64.3425
            },
            "Villaz\u00f3n": {
                "lat": -22.0911,
                "lng": -65.5961
            },
            "Uyuni": {
                "lat": -20.4628,
                "lng": -66.8239
            },
            "Mizque": {
                "lat": -17.9333,
                "lng": -65.3167
            },
            "Cotoca": {
                "lat": -17.7539,
                "lng": -62.9969
            },
            "Yapacani": {
                "lat": -17.4028,
                "lng": -63.885
            },
            "San Borja": {
                "lat": -14.8583,
                "lng": -66.7475
            },
            "Independencia": {
                "lat": -17.0839,
                "lng": -66.8181
            },
            "San Ignacio de Velasco": {
                "lat": -16.3667,
                "lng": -60.95
            },
            "Tupiza": {
                "lat": -21.4375,
                "lng": -65.7158
            },
            "Patacamaya": {
                "lat": -17.2333,
                "lng": -67.9167
            },
            "Caranavi": {
                "lat": -15.8333,
                "lng": -67.5667
            },
            "Chimor\u00e9": {
                "lat": -16.9833,
                "lng": -65.1333
            },
            "San Juli\u00e1n": {
                "lat": -16.9064,
                "lng": -62.6169
            },
            "Huanuni": {
                "lat": -18.29,
                "lng": -66.8383
            },
            "Llallagua": {
                "lat": -18.4167,
                "lng": -66.5833
            },
            "Capinota": {
                "lat": -17.715,
                "lng": -66.2636
            },
            "Rurrenabaque": {
                "lat": -14.4422,
                "lng": -67.5283
            },
            "Ascenci\u00f3n de Guarayos": {
                "lat": -15.8922,
                "lng": -63.1881
            },
            "Achocalla": {
                "lat": -16.5833,
                "lng": -68.1667
            },
            "Mineros": {
                "lat": -17.1178,
                "lng": -63.2331
            },
            "San Jos\u00e9 de Chiquitos": {
                "lat": -17.85,
                "lng": -60.75
            },
            "Vallegrande": {
                "lat": -18.4833,
                "lng": -64.1
            },
            "Mapiri": {
                "lat": -15.3097,
                "lng": -68.2161
            },
            "Portachuelo": {
                "lat": -17.3572,
                "lng": -63.3906
            },
            "Comarapa": {
                "lat": -17.9158,
                "lng": -64.53
            },
            "Punata": {
                "lat": -17.55,
                "lng": -65.8333
            },
            "Villa Yapacan\u00ed": {
                "lat": -17.4028,
                "lng": -63.885
            },
            "Ascensi\u00f3n": {
                "lat": -15.6996,
                "lng": -63.08
            },
            "Vinto": {
                "lat": -17.3833,
                "lng": -66.3
            },
            "Chulumani": {
                "lat": -16.6833,
                "lng": -67.8667
            },
            "Santa Ana de Yacuma": {
                "lat": -13.7444,
                "lng": -65.4269
            },
            "Challapata": {
                "lat": -18.9,
                "lng": -66.7667
            },
            "Okinawa N\u00famero Uno": {
                "lat": -17.2189,
                "lng": -62.8953
            },
            "Puerto Su\u00e1rez": {
                "lat": -18.9667,
                "lng": -57.7981
            },
            "Corocoro": {
                "lat": -17.1667,
                "lng": -68.45
            },
            "Torotoro": {
                "lat": -18.1342,
                "lng": -65.7633
            },
            "Puerto Quijarro": {
                "lat": -17.7796,
                "lng": -57.77
            },
            "Robor\u00e9": {
                "lat": -18.3333,
                "lng": -59.75
            },
            "San Ignacio de Moxo": {
                "lat": -14.9961,
                "lng": -65.64
            },
            "Puerto America": {
                "lat": -11.55,
                "lng": -68.0667
            },
            "Pail\u00f3n": {
                "lat": -17.6594,
                "lng": -62.7197
            },
            "Achacachi": {
                "lat": -16.0444,
                "lng": -68.685
            },
            "Reyes": {
                "lat": -14.2958,
                "lng": -67.3353
            },
            "Aiquile": {
                "lat": -18.1667,
                "lng": -65.1667
            },
            "Charagua": {
                "lat": -19.7906,
                "lng": -63.1978
            },
            "Cliza": {
                "lat": -17.6,
                "lng": -65.9333
            },
            "San Carlos": {
                "lat": -17.4044,
                "lng": -63.7325
            },
            "San Javier": {
                "lat": -16.2748,
                "lng": -62.5072
            },
            "Uncia": {
                "lat": -18.4681,
                "lng": -66.5647
            },
            "San Ram\u00f3n": {
                "lat": -13.2672,
                "lng": -64.6172
            },
            "San Mat\u00edas": {
                "lat": -16.3611,
                "lng": -58.4053
            },
            "Betanzos": {
                "lat": -19.5533,
                "lng": -65.4536
            },
            "Camargo": {
                "lat": -20.6403,
                "lng": -65.2103
            },
            "Samaipata": {
                "lat": -18.1794,
                "lng": -63.8756
            },
            "Magdalena": {
                "lat": -13.2606,
                "lng": -64.0528
            },
            "Sicasica": {
                "lat": -17.3333,
                "lng": -67.7333
            },
            "Padilla": {
                "lat": -19.3,
                "lng": -64.3
            },
            "San Lorenzo": {
                "lat": -21.4167,
                "lng": -64.7494
            },
            "Entre R\u00edos": {
                "lat": -21.5264,
                "lng": -64.1733
            },
            "Tarabuco": {
                "lat": -19.1667,
                "lng": -64.9167
            },
            "Quime": {
                "lat": -16.9817,
                "lng": -67.2167
            },
            "Coroico": {
                "lat": -16.1833,
                "lng": -67.7333
            },
            "Sorata": {
                "lat": -15.7733,
                "lng": -68.6481
            },
            "Baures": {
                "lat": -13.6556,
                "lng": -63.6958
            },
            "Apolo": {
                "lat": -14.72,
                "lng": -68.5039
            },
            "Cuevo": {
                "lat": -20.45,
                "lng": -63.5167
            },
            "Villa Mart\u00edn Colchak": {
                "lat": -20.7406,
                "lng": -67.6606
            },
            "Puerto Acosta": {
                "lat": -15.5333,
                "lng": -69.25
            },
            "Chara\u00f1a": {
                "lat": -17.5919,
                "lng": -69.4456
            },
            "Sabaya": {
                "lat": -19.0147,
                "lng": -68.3708
            },
            "Llica": {
                "lat": -19.85,
                "lng": -68.25
            },
            "Piso Firme": {
                "lat": -13.683,
                "lng": -61.8666
            },
            "Puerto Heath": {
                "lat": -12.5022,
                "lng": -68.65
            },
            "San Rafael": {
                "lat": -16.7795,
                "lng": -60.6799
            }
        }
    },
    "GR": {
        "name": "Greece",
        "cities": {
            "Athens": {
                "lat": 37.9842,
                "lng": 23.7281
            },
            "Thessalon\u00edki": {
                "lat": 40.6403,
                "lng": 22.9347
            },
            "Piraeus": {
                "lat": 37.943,
                "lng": 23.6469
            },
            "Ir\u00e1kleio": {
                "lat": 38.05,
                "lng": 23.7667
            },
            "P\u00e1tra": {
                "lat": 38.25,
                "lng": 21.7333
            },
            "L\u00e1risa": {
                "lat": 39.6417,
                "lng": 22.4167
            },
            "Perist\u00e9ri": {
                "lat": 38.0167,
                "lng": 23.6833
            },
            "Acharn\u00e9s": {
                "lat": 38.0833,
                "lng": 23.7333
            },
            "Kallith\u00e9a": {
                "lat": 37.95,
                "lng": 23.7
            },
            "Kalamari\u00e1": {
                "lat": 40.5833,
                "lng": 22.95
            },
            "Glyf\u00e1da": {
                "lat": 37.8667,
                "lng": 23.75
            },
            "N\u00edkaia": {
                "lat": 37.9667,
                "lng": 23.6333
            },
            "V\u00f3los": {
                "lat": 39.3667,
                "lng": 22.9333
            },
            "\u00cdlion": {
                "lat": 38.0333,
                "lng": 23.7
            },
            "\u00c9vosmos": {
                "lat": 40.6689,
                "lng": 22.9083
            },
            "Ilio\u00fapoli": {
                "lat": 37.9333,
                "lng": 23.75
            },
            "Chal\u00e1ndri": {
                "lat": 38.0167,
                "lng": 23.8
            },
            "Kerats\u00edni": {
                "lat": 37.9667,
                "lng": 23.6167
            },
            "N\u00e9a Sm\u00fdrni": {
                "lat": 37.95,
                "lng": 23.7167
            },
            "Maro\u00fasi": {
                "lat": 38.05,
                "lng": 23.8
            },
            "\u00c1gios Dim\u00edtrios": {
                "lat": 37.9333,
                "lng": 23.7333
            },
            "Zogr\u00e1fos": {
                "lat": 37.9783,
                "lng": 23.7717
            },
            "Aig\u00e1leo": {
                "lat": 37.992,
                "lng": 23.678
            },
            "N\u00e9a Ion\u00eda": {
                "lat": 38.0333,
                "lng": 23.75
            },
            "Kav\u00e1la": {
                "lat": 40.9333,
                "lng": 24.4
            },
            "Io\u00e1nnina": {
                "lat": 39.6636,
                "lng": 20.8522
            },
            "Palai\u00f3 F\u00e1liro": {
                "lat": 37.9333,
                "lng": 23.7
            },
            "Korydall\u00f3s": {
                "lat": 37.9833,
                "lng": 23.65
            },
            "Ag\u00eda Paraskev\u00ed": {
                "lat": 38.0117,
                "lng": 23.82
            },
            "Tr\u00edkala": {
                "lat": 39.55,
                "lng": 21.7667
            },
            "V\u00fdronas": {
                "lat": 37.9617,
                "lng": 23.7533
            },
            "Alexandro\u00fapoli": {
                "lat": 40.85,
                "lng": 25.8667
            },
            "Gal\u00e1tsi": {
                "lat": 38.0167,
                "lng": 23.75
            },
            "S\u00e9rres": {
                "lat": 41.0833,
                "lng": 23.55
            },
            "Kater\u00edni": {
                "lat": 40.2667,
                "lng": 22.5
            },
            "Chalk\u00edda": {
                "lat": 38.4625,
                "lng": 23.595
            },
            "Petro\u00fapoli": {
                "lat": 38.0333,
                "lng": 23.6833
            },
            "Kalam\u00e1ta": {
                "lat": 37.0378,
                "lng": 22.1111
            },
            "X\u00e1nthi": {
                "lat": 41.1333,
                "lng": 24.8833
            },
            "R\u00f3dos": {
                "lat": 36.4412,
                "lng": 28.2225
            },
            "Th\u00e9rmi": {
                "lat": 40.55,
                "lng": 23.0167
            },
            "Pall\u00edni": {
                "lat": 38.0,
                "lng": 23.8833
            },
            "Komotin\u00ed": {
                "lat": 41.1219,
                "lng": 25.4042
            },
            "Chani\u00e1": {
                "lat": 35.5167,
                "lng": 24.0167
            },
            "Agr\u00ednio": {
                "lat": 38.6167,
                "lng": 21.4
            },
            "Lam\u00eda": {
                "lat": 38.9,
                "lng": 22.4333
            },
            "Kifisi\u00e1": {
                "lat": 38.0833,
                "lng": 23.8167
            },
            "Cha\u00efd\u00e1ri": {
                "lat": 38.0167,
                "lng": 23.65
            },
            "Stavro\u00fapoli": {
                "lat": 40.6667,
                "lng": 22.9333
            },
            "Dr\u00e1ma": {
                "lat": 41.15,
                "lng": 24.1467
            },
            "Koz\u00e1ni": {
                "lat": 40.3,
                "lng": 21.7833
            },
            "V\u00e9roia": {
                "lat": 40.5167,
                "lng": 22.2
            },
            "Verg\u00edna": {
                "lat": 40.4865,
                "lng": 22.3173
            },
            "\u00c1limos": {
                "lat": 37.9167,
                "lng": 23.7167
            },
            "Z\u00e1kynthos": {
                "lat": 37.8,
                "lng": 20.75
            },
            "Kard\u00edtsa": {
                "lat": 39.3667,
                "lng": 21.9167
            },
            "Orai\u00f3kastro": {
                "lat": 40.7333,
                "lng": 22.9167
            },
            "Lagkad\u00e1s": {
                "lat": 40.75,
                "lng": 23.0667
            },
            "Pyla\u00eda": {
                "lat": 40.6,
                "lng": 22.9833
            },
            "Ampel\u00f3kipoi": {
                "lat": 40.65,
                "lng": 22.9167
            },
            "Syki\u00e9s": {
                "lat": 40.65,
                "lng": 22.95
            },
            "\u00c1gioi An\u00e1rgyroi": {
                "lat": 38.0267,
                "lng": 23.7183
            },
            "Argyro\u00fapoli": {
                "lat": 37.9,
                "lng": 23.75
            },
            "\u00c1no Li\u00f3sia": {
                "lat": 38.0833,
                "lng": 23.7
            },
            "R\u00e9thymno": {
                "lat": 35.3689,
                "lng": 24.4739
            },
            "Vril\u00edssia": {
                "lat": 38.0333,
                "lng": 23.8333
            },
            "Ptolema\u1e2fda": {
                "lat": 40.5167,
                "lng": 21.6833
            },
            "Salam\u00edna": {
                "lat": 37.9667,
                "lng": 23.5
            },
            "Cholarg\u00f3s": {
                "lat": 38.0,
                "lng": 23.8
            },
            "K\u00f3rinthos": {
                "lat": 37.9386,
                "lng": 22.9272
            },
            "Giannits\u00e1": {
                "lat": 40.7833,
                "lng": 22.4
            },
            "Tr\u00edpoli": {
                "lat": 37.5167,
                "lng": 22.3833
            },
            "Aspr\u00f3pyrgos": {
                "lat": 38.0667,
                "lng": 23.5833
            },
            "G\u00e9rakas": {
                "lat": 38.0333,
                "lng": 23.85
            },
            "Metam\u00f3rfosi": {
                "lat": 38.05,
                "lng": 23.75
            },
            "M\u00e9gara": {
                "lat": 37.9964,
                "lng": 23.3444
            },
            "Amali\u00e1da": {
                "lat": 37.8,
                "lng": 21.35
            },
            "Vo\u00fala": {
                "lat": 37.85,
                "lng": 23.75
            },
            "Kamater\u00f3": {
                "lat": 38.0597,
                "lng": 23.712
            },
            "Mytil\u00edni": {
                "lat": 39.1,
                "lng": 26.55
            },
            "\u00c1gios Nik\u00f3laos": {
                "lat": 35.1833,
                "lng": 25.7167
            },
            "Ch\u00edos": {
                "lat": 38.3725,
                "lng": 26.1375
            },
            "Paian\u00eda": {
                "lat": 37.95,
                "lng": 23.85
            },
            "Ag\u00eda Varv\u00e1ra": {
                "lat": 38.0,
                "lng": 23.65
            },
            "Kaisarian\u00ed": {
                "lat": 37.9683,
                "lng": 23.7583
            },
            "Greven\u00e1": {
                "lat": 40.085,
                "lng": 21.4275
            },
            "N\u00e9a Filad\u00e9lfeia": {
                "lat": 38.0367,
                "lng": 23.7367
            },
            "Mosch\u00e1to": {
                "lat": 37.95,
                "lng": 23.6667
            },
            "P\u00e9rama": {
                "lat": 37.9667,
                "lng": 23.5667
            },
            "Elefs\u00edna": {
                "lat": 38.0333,
                "lng": 23.5333
            },
            "K\u00e9rkyra": {
                "lat": 39.6239,
                "lng": 19.9214
            },
            "P\u00fdrgos": {
                "lat": 37.6667,
                "lng": 21.4333
            },
            "Kilk\u00eds": {
                "lat": 40.9833,
                "lng": 22.8667
            },
            "D\u00e1fni": {
                "lat": 37.95,
                "lng": 23.7333
            },
            "Mel\u00edssia": {
                "lat": 38.05,
                "lng": 23.8333
            },
            "Lefk\u00e1da": {
                "lat": 38.7167,
                "lng": 20.65
            },
            "Livadei\u00e1": {
                "lat": 38.4333,
                "lng": 22.8667
            },
            "\u00c1rgos": {
                "lat": 37.6167,
                "lng": 22.7167
            },
            "Pol\u00fdgyros": {
                "lat": 40.3667,
                "lng": 23.4333
            },
            "Kal\u00fdves Polyg\u00fdrou": {
                "lat": 40.2833,
                "lng": 23.4
            },
            "\u00c1rta": {
                "lat": 39.165,
                "lng": 20.9875
            },
            "Thebes": {
                "lat": 38.3208,
                "lng": 23.3178
            },
            "\u00c1no S\u00fdros": {
                "lat": 37.4383,
                "lng": 24.9122
            },
            "Art\u00e9mida": {
                "lat": 37.9667,
                "lng": 24.0
            },
            "P\u00e9fki": {
                "lat": 38.0667,
                "lng": 23.8
            },
            "Ir\u00e1kleia": {
                "lat": 41.1833,
                "lng": 23.2833
            },
            "A\u00edgio": {
                "lat": 38.2506,
                "lng": 22.0811
            },
            "Kos": {
                "lat": 36.85,
                "lng": 27.2333
            },
            "G\u00e1zi": {
                "lat": 35.325,
                "lng": 25.0667
            },
            "Korop\u00ed": {
                "lat": 37.9,
                "lng": 23.8733
            },
            "Pr\u00e9veza": {
                "lat": 38.9575,
                "lng": 20.7517
            },
            "N\u00e1ousa": {
                "lat": 40.6333,
                "lng": 22.0667
            },
            "Oresti\u00e1da": {
                "lat": 41.5,
                "lng": 26.5333
            },
            "Sk\u00fddra": {
                "lat": 40.7667,
                "lng": 22.15
            },
            "Chorti\u00e1tis": {
                "lat": 40.6333,
                "lng": 23.0167
            },
            "\u00c9dessa": {
                "lat": 40.8,
                "lng": 22.05
            },
            "Fl\u00f3rina": {
                "lat": 40.7833,
                "lng": 21.4
            },
            "Pan\u00f3rama": {
                "lat": 40.5833,
                "lng": 23.0333
            },
            "N\u00e9a Erythra\u00eda": {
                "lat": 38.0833,
                "lng": 23.8167
            },
            "Ellinik\u00f3": {
                "lat": 37.8667,
                "lng": 23.7333
            },
            "Amfiloch\u00eda": {
                "lat": 38.85,
                "lng": 21.1667
            },
            "Pera\u00eda": {
                "lat": 40.5,
                "lng": 22.925
            },
            "N\u00e9a M\u00e1kri": {
                "lat": 38.0833,
                "lng": 23.9833
            },
            "Kastori\u00e1": {
                "lat": 40.5167,
                "lng": 21.2667
            },
            "Sp\u00e1rti": {
                "lat": 37.0739,
                "lng": 22.4294
            },
            "\u00c1gios Io\u00e1nnis R\u00e9ntis": {
                "lat": 37.9667,
                "lng": 23.6667
            },
            "Alex\u00e1ndreia": {
                "lat": 40.6333,
                "lng": 22.45
            },
            "V\u00e1ri": {
                "lat": 37.8333,
                "lng": 23.8
            },
            "Efkarp\u00eda": {
                "lat": 40.6833,
                "lng": 22.95
            },
            "T\u00e1vros": {
                "lat": 37.9667,
                "lng": 23.695
            },
            "Chryso\u00fapoli": {
                "lat": 40.9833,
                "lng": 24.7
            },
            "Z\u00edtsa": {
                "lat": 39.75,
                "lng": 20.65
            },
            "N\u00e9a Alikarnass\u00f3s": {
                "lat": 35.3167,
                "lng": 25.1833
            },
            "Kal\u00fdvia Thoriko\u00fa": {
                "lat": 37.8333,
                "lng": 23.9167
            },
            "N\u00e1fplio": {
                "lat": 37.5658,
                "lng": 22.8
            },
            "Drapets\u00f3na": {
                "lat": 37.9467,
                "lng": 23.625
            },
            "Ermo\u00fapoli": {
                "lat": 37.4333,
                "lng": 24.9167
            },
            "Pap\u00e1gos": {
                "lat": 37.99,
                "lng": 23.7933
            },
            "P\u00e9fka": {
                "lat": 40.65,
                "lng": 22.9833
            },
            "N\u00e1fpaktos": {
                "lat": 38.3939,
                "lng": 21.8306
            },
            "Xyl\u00f3kastro": {
                "lat": 38.0667,
                "lng": 22.6333
            },
            "M\u00e1ndra": {
                "lat": 38.0667,
                "lng": 23.5
            },
            "Mesol\u00f3ngi": {
                "lat": 38.3692,
                "lng": 21.4278
            },
            "Gi\u00e1nnouli": {
                "lat": 39.6667,
                "lng": 22.4
            },
            "Ier\u00e1petra": {
                "lat": 35.0117,
                "lng": 25.7414
            },
            "K\u00e1lymnos": {
                "lat": 36.99,
                "lng": 26.985
            },
            "Raf\u00edna": {
                "lat": 38.0167,
                "lng": 24.0
            },
            "Loutr\u00e1ki": {
                "lat": 37.975,
                "lng": 22.9767
            },
            "Anatol\u00ed": {
                "lat": 39.6333,
                "lng": 20.8667
            },
            "Ialys\u00f3s": {
                "lat": 36.4167,
                "lng": 28.15
            },
            "T\u00fdrnavos": {
                "lat": 39.7333,
                "lng": 22.2833
            },
            "Glyk\u00e1 Ner\u00e1": {
                "lat": 37.9917,
                "lng": 23.8483
            },
            "Argost\u00f3li": {
                "lat": 38.1733,
                "lng": 20.4908
            },
            "Vocha\u00efk\u00f3": {
                "lat": 37.9333,
                "lng": 22.8
            },
            "Ymitt\u00f3s": {
                "lat": 37.95,
                "lng": 23.7333
            },
            "Skillo\u00fanta": {
                "lat": 37.6061,
                "lng": 21.687
            },
            "Arkaloch\u00f3ri": {
                "lat": 35.1441,
                "lng": 25.2606
            },
            "N\u00e9o Karlov\u00e1si": {
                "lat": 37.7833,
                "lng": 26.7
            },
            "N\u00e9o Psychik\u00f3": {
                "lat": 38.0,
                "lng": 23.7833
            },
            "F\u00edlippoi": {
                "lat": 41.0167,
                "lng": 24.2833
            },
            "Tymp\u00e1ki": {
                "lat": 35.0667,
                "lng": 24.7667
            },
            "Epanom\u00ed": {
                "lat": 40.4333,
                "lng": 22.9333
            },
            "Zef\u00fdri": {
                "lat": 38.0667,
                "lng": 23.7167
            },
            "Diavat\u00e1": {
                "lat": 40.6883,
                "lng": 22.8583
            },
            "Mess\u00edni": {
                "lat": 37.05,
                "lng": 22.0
            },
            "Ki\u00e1to": {
                "lat": 38.0117,
                "lng": 22.7467
            },
            "Lyk\u00f3vrysi": {
                "lat": 38.0667,
                "lng": 23.7833
            },
            "L\u00e1gos": {
                "lat": 41.0667,
                "lng": 24.9833
            },
            "Dod\u00f3ni": {
                "lat": 39.55,
                "lng": 20.7667
            },
            "P\u00f3rto R\u00e1fti": {
                "lat": 37.8869,
                "lng": 24.0042
            },
            "Vasilik\u00e1": {
                "lat": 40.48,
                "lng": 23.1367
            },
            "Psychik\u00f3": {
                "lat": 38.0167,
                "lng": 23.7667
            },
            "N\u00e9a Art\u00e1ki": {
                "lat": 38.5167,
                "lng": 23.6333
            },
            "Kouf\u00e1lia": {
                "lat": 40.7783,
                "lng": 22.5767
            },
            "Kran\u00eddi": {
                "lat": 37.38,
                "lng": 23.159
            },
            "Site\u00eda": {
                "lat": 35.2,
                "lng": 26.1
            },
            "N\u00e9a Moudani\u00e1": {
                "lat": 40.2333,
                "lng": 23.2833
            },
            "F\u00e1rsala": {
                "lat": 39.3,
                "lng": 22.3833
            },
            "S\u00edndos": {
                "lat": 40.6667,
                "lng": 22.8
            },
            "Didym\u00f3teicho": {
                "lat": 41.35,
                "lng": 26.5
            },
            "Sp\u00e1ta": {
                "lat": 37.9667,
                "lng": 23.9167
            },
            "Chal\u00e1stra": {
                "lat": 40.6333,
                "lng": 22.7333
            },
            "Zach\u00e1ro": {
                "lat": 37.4833,
                "lng": 21.65
            },
            "\u00c1rgos Orestik\u00f3": {
                "lat": 40.45,
                "lng": 21.25
            },
            "N\u00e9a Michani\u00f3na": {
                "lat": 40.4644,
                "lng": 22.8606
            },
            "Al\u00edartos": {
                "lat": 38.3717,
                "lng": 23.105
            },
            "Karpen\u00edsi": {
                "lat": 38.9167,
                "lng": 21.7833
            },
            "Kallon\u00ed": {
                "lat": 39.2333,
                "lng": 26.25
            }
        }
    },
    "ET": {
        "name": "Ethiopia",
        "cities": {
            "Addis Ababa": {
                "lat": 9.03,
                "lng": 38.74
            },
            "\u0112rer S\u0101t\u0101": {
                "lat": 9.5667,
                "lng": 41.3833
            },
            "Shashemen\u0113": {
                "lat": 7.2,
                "lng": 38.6
            },
            "K\u2019ebr\u012b Dehar": {
                "lat": 6.7333,
                "lng": 44.2667
            },
            "Nazr\u0113t": {
                "lat": 8.5414,
                "lng": 39.2689
            },
            "Mekele": {
                "lat": 13.4969,
                "lng": 39.4769
            },
            "God\u0113": {
                "lat": 5.9527,
                "lng": 43.5516
            },
            "\u0100wasa": {
                "lat": 7.05,
                "lng": 38.4667
            },
            "Dire Dawa": {
                "lat": 9.6,
                "lng": 41.8667
            },
            "Bahir Dar": {
                "lat": 11.6,
                "lng": 37.3833
            },
            "Sodo": {
                "lat": 6.855,
                "lng": 37.7808
            },
            "\u0100rba Minch\u2019": {
                "lat": 6.0333,
                "lng": 37.55
            },
            "Des\u0113": {
                "lat": 11.1333,
                "lng": 39.6333
            },
            "Hosa\u2019ina": {
                "lat": 7.55,
                "lng": 37.85
            },
            "K\u2019ebr\u012b Beyah": {
                "lat": 9.0833,
                "lng": 43.0833
            },
            "Jijiga": {
                "lat": 9.35,
                "lng": 42.8
            },
            "D\u012bla": {
                "lat": 6.4083,
                "lng": 38.3083
            },
            "Nek\u2019emt\u0113": {
                "lat": 9.0833,
                "lng": 36.55
            },
            "Debre Birhan": {
                "lat": 9.6833,
                "lng": 39.5333
            },
            "Debre Mark\u2019os": {
                "lat": 10.3333,
                "lng": 37.7167
            },
            "Ferfer": {
                "lat": 5.0833,
                "lng": 45.0833
            },
            "\u0100war\u0113": {
                "lat": 8.2667,
                "lng": 44.15
            },
            "Harar": {
                "lat": 9.3111,
                "lng": 42.1278
            },
            "Kombolcha": {
                "lat": 11.0867,
                "lng": 39.7367
            },
            "J\u012bma": {
                "lat": 7.6667,
                "lng": 36.8333
            },
            "Debre Tabor": {
                "lat": 11.85,
                "lng": 38.0167
            },
            "Harshin": {
                "lat": 9.2167,
                "lng": 43.5878
            },
            "\u0100d\u012bgrat": {
                "lat": 14.2667,
                "lng": 39.45
            },
            "Bab\u012bl\u0113": {
                "lat": 9.2167,
                "lng": 42.3333
            },
            "Debre Zeyit": {
                "lat": 8.75,
                "lng": 38.9833
            },
            "Weldiya": {
                "lat": 11.8306,
                "lng": 39.6
            },
            "Gamb\u0113la": {
                "lat": 8.25,
                "lng": 34.5833
            },
            "Shir\u0113": {
                "lat": 14.1,
                "lng": 38.2833
            },
            "M\u012bzan Tefer\u012b": {
                "lat": 7.0,
                "lng": 35.5833
            },
            "Denan": {
                "lat": 6.5,
                "lng": 43.5
            },
            "Yab\u0113lo": {
                "lat": 4.8833,
                "lng": 38.0833
            },
            "\u0100dwa": {
                "lat": 14.1667,
                "lng": 38.9
            },
            "Warder": {
                "lat": 6.9667,
                "lng": 45.35
            },
            "Gonder": {
                "lat": 12.6075,
                "lng": 37.4592
            },
            "\u0100d\u012bgala": {
                "lat": 10.4236,
                "lng": 42.2333
            },
            "Togoch\u2019al\u0113": {
                "lat": 9.6014,
                "lng": 43.3361
            },
            "Bod\u012bt\u012b": {
                "lat": 6.8667,
                "lng": 37.8667
            },
            "\u0100sela": {
                "lat": 7.95,
                "lng": 39.1167
            },
            "\u0100ksum": {
                "lat": 14.1208,
                "lng": 38.7278
            },
            "Mota": {
                "lat": 11.0833,
                "lng": 37.8667
            },
            "Bonga": {
                "lat": 7.2667,
                "lng": 36.2333
            },
            "Finote Selam": {
                "lat": 10.7,
                "lng": 37.2667
            },
            "Dangila": {
                "lat": 11.2667,
                "lng": 36.8333
            },
            "Metema": {
                "lat": 12.9667,
                "lng": 36.2
            },
            "Semera": {
                "lat": 11.7922,
                "lng": 41.0086
            },
            "Mojo": {
                "lat": 8.65,
                "lng": 39.0833
            },
            "Sebeta": {
                "lat": 8.9111,
                "lng": 38.6214
            },
            "Goba": {
                "lat": 7.0,
                "lng": 39.9833
            },
            "H\u0101gere Hiywet": {
                "lat": 8.9833,
                "lng": 37.85
            },
            "Rob\u0113": {
                "lat": 7.1167,
                "lng": 40.0
            },
            "Yirga \u2018Alem": {
                "lat": 6.75,
                "lng": 38.4167
            },
            "Mersa": {
                "lat": 11.6667,
                "lng": 39.6583
            },
            "Giyon": {
                "lat": 8.5333,
                "lng": 37.9667
            },
            "Mek\u2019\u012b": {
                "lat": 8.15,
                "lng": 38.8167
            },
            "Bed\u0113sa": {
                "lat": 8.9,
                "lng": 40.7833
            },
            "\u0100b\u012by \u0100d\u012b": {
                "lat": 13.6231,
                "lng": 39.0017
            },
            "\u0100zezo": {
                "lat": 12.5586,
                "lng": 37.4308
            },
            "Butaj\u012bra": {
                "lat": 8.1208,
                "lng": 38.3792
            },
            "\u0100lamat\u2019\u0101": {
                "lat": 12.4167,
                "lng": 39.55
            },
            "\u0100reka": {
                "lat": 7.071,
                "lng": 37.7076
            },
            "G\u012bmb\u012b": {
                "lat": 9.1667,
                "lng": 35.8333
            },
            "Dolo Odo": {
                "lat": 4.1667,
                "lng": 42.0667
            },
            "Chagne": {
                "lat": 10.95,
                "lng": 36.5
            },
            "Jinka": {
                "lat": 5.7833,
                "lng": 36.5667
            },
            "Wik\u2019ro": {
                "lat": 13.7833,
                "lng": 39.6
            },
            "Degeh Bur": {
                "lat": 8.2167,
                "lng": 43.5667
            },
            "Demb\u012b Dolo": {
                "lat": 8.5353,
                "lng": 34.8003
            },
            "Welk\u2019\u012bt\u2019\u0113": {
                "lat": 8.2833,
                "lng": 37.7833
            },
            "Metu": {
                "lat": 8.3,
                "lng": 35.5833
            },
            "Shak\u012bso": {
                "lat": 5.75,
                "lng": 38.9167
            },
            "Fich\u0113": {
                "lat": 9.8,
                "lng": 38.7333
            },
            "K\u2019ol\u012bto": {
                "lat": 7.3122,
                "lng": 38.0892
            },
            "Werota": {
                "lat": 11.9167,
                "lng": 37.7
            },
            "Genet": {
                "lat": 9.05,
                "lng": 38.5
            },
            "\u0100garo": {
                "lat": 7.8558,
                "lng": 36.5858
            },
            "Bur\u0113": {
                "lat": 10.7,
                "lng": 37.0667
            },
            "Moyal\u0113": {
                "lat": 3.527,
                "lng": 39.056
            },
            "Kobo": {
                "lat": 12.15,
                "lng": 39.6333
            },
            "\u0100d\u012bs Zemen": {
                "lat": 12.1167,
                "lng": 37.7833
            },
            "Dodola": {
                "lat": 6.9833,
                "lng": 39.1833
            },
            "Gelemso": {
                "lat": 8.8167,
                "lng": 40.5167
            },
            "Maychew": {
                "lat": 12.7833,
                "lng": 39.5333
            },
            "Asaita": {
                "lat": 11.5667,
                "lng": 41.4333
            },
            "Sek\u2019ot\u2019a": {
                "lat": 12.6253,
                "lng": 39.035
            },
            "Himora": {
                "lat": 14.2861,
                "lng": 36.6097
            },
            "M\u012b\u2019\u0113so": {
                "lat": 9.2333,
                "lng": 40.75
            },
            "Metah\u0101ra": {
                "lat": 8.9,
                "lng": 39.9167
            },
            "\u0100d\u0113t": {
                "lat": 11.2667,
                "lng": 37.4833
            },
            "Injibara": {
                "lat": 10.95,
                "lng": 36.9333
            },
            "Welench\u2019\u012bt\u012b": {
                "lat": 8.6667,
                "lng": 39.4333
            },
            "Debark\u2019": {
                "lat": 13.1333,
                "lng": 37.9
            },
            "Bat\u012b": {
                "lat": 11.1833,
                "lng": 40.0167
            },
            "\u0100sosa": {
                "lat": 10.0667,
                "lng": 34.5167
            },
            "Bichena": {
                "lat": 10.45,
                "lng": 38.2
            },
            "Nejo": {
                "lat": 9.5,
                "lng": 35.5
            },
            "Gebre Guracha": {
                "lat": 9.8,
                "lng": 38.4
            },
            "Aysha": {
                "lat": 10.75,
                "lng": 42.5667
            },
            "Bedel\u0113": {
                "lat": 8.45,
                "lng": 36.35
            },
            "\u0100sasa": {
                "lat": 7.1,
                "lng": 39.1944
            },
            "Kemis\u0113": {
                "lat": 10.7167,
                "lng": 39.8667
            },
            "\u0100bomsa": {
                "lat": 8.5833,
                "lng": 39.85
            },
            "Nefas Mewch\u2019a": {
                "lat": 11.7333,
                "lng": 38.4667
            },
            "Lal\u012bbela": {
                "lat": 12.0317,
                "lng": 39.0411
            },
            "G\u012bn\u012br": {
                "lat": 7.1333,
                "lng": 40.7
            },
            "Cheraro": {
                "lat": 14.3958,
                "lng": 37.7731
            },
            "Huruta": {
                "lat": 8.15,
                "lng": 39.35
            },
            "Korem": {
                "lat": 12.5,
                "lng": 39.5167
            },
            "D\u0113ra": {
                "lat": 8.3333,
                "lng": 39.3167
            },
            "Bek\u2019oj\u012b": {
                "lat": 7.5833,
                "lng": 39.3333
            },
            "H\u012brna": {
                "lat": 9.2167,
                "lng": 41.1
            },
            "Dejen": {
                "lat": 10.1667,
                "lng": 38.1333
            },
            "Funyan B\u012bra": {
                "lat": 9.35,
                "lng": 42.4
            },
            "Dembecha": {
                "lat": 10.55,
                "lng": 37.4833
            },
            "Shambu": {
                "lat": 9.5667,
                "lng": 37.1
            },
            "\u0100fdem": {
                "lat": 9.4667,
                "lng": 41.0
            },
            "Kelafo": {
                "lat": 5.5889,
                "lng": 44.2056
            },
            "Gesuba": {
                "lat": 6.7242,
                "lng": 37.5567
            },
            "Debre Werk\u2019": {
                "lat": 10.6667,
                "lng": 38.1667
            },
            "\u2018Alem T\u2019\u0113na": {
                "lat": 8.3,
                "lng": 38.95
            },
            "\u0100d\u012bs \u2018Alem": {
                "lat": 9.0333,
                "lng": 38.4
            },
            "Sh\u012bn\u012bl\u0113": {
                "lat": 9.6667,
                "lng": 41.8333
            },
            "Kofel\u0113": {
                "lat": 7.0,
                "lng": 38.75
            },
            "Dabat": {
                "lat": 12.9842,
                "lng": 37.765
            },
            "Kw\u012bh\u0101": {
                "lat": 13.4769,
                "lng": 39.545
            },
            "Sendafa": {
                "lat": 9.15,
                "lng": 39.0333
            },
            "Dolo Bay": {
                "lat": 4.1833,
                "lng": 42.0833
            },
            "Neg\u0113l\u0113": {
                "lat": 5.3167,
                "lng": 39.5833
            },
            "F\u012bnch\u2019a\u2019\u0101": {
                "lat": 9.9,
                "lng": 37.45
            },
            "\u0100wash": {
                "lat": 8.9944,
                "lng": 40.1667
            },
            "Chencha": {
                "lat": 6.25,
                "lng": 37.5667
            },
            "Kele": {
                "lat": 6.0833,
                "lng": 38.0333
            },
            "Were \u012alu": {
                "lat": 10.6,
                "lng": 39.4333
            },
            "Sagur\u0113": {
                "lat": 7.75,
                "lng": 39.15
            },
            "M\u0113ga": {
                "lat": 4.0167,
                "lng": 38.25
            },
            "Gor\u0113": {
                "lat": 8.149,
                "lng": 35.537
            },
            "Shend\u0113": {
                "lat": 10.6333,
                "lng": 36.9333
            },
            "B\u012bk\u0113": {
                "lat": 9.5297,
                "lng": 41.1969
            },
            "Intich\u2019o": {
                "lat": 14.2667,
                "lng": 39.15
            },
            "\u0100dam\u012b Tulu": {
                "lat": 7.8667,
                "lng": 38.7
            }
        }
    },
    "TW": {
        "name": "Taiwan",
        "cities": {
            "Taichung": {
                "lat": 24.1439,
                "lng": 120.6794
            },
            "Kaohsiung": {
                "lat": 22.615,
                "lng": 120.2975
            },
            "Taipei": {
                "lat": 25.0375,
                "lng": 121.5625
            },
            "Tainan": {
                "lat": 22.9833,
                "lng": 120.1833
            },
            "Zhongli": {
                "lat": 24.965,
                "lng": 121.2168
            },
            "Hsinchu": {
                "lat": 24.8167,
                "lng": 120.9833
            },
            "Taoyuan District": {
                "lat": 24.9913,
                "lng": 121.3143
            },
            "Keelung": {
                "lat": 25.1333,
                "lng": 121.7333
            },
            "Chiayi": {
                "lat": 23.48,
                "lng": 120.4497
            },
            "Changhua": {
                "lat": 24.0667,
                "lng": 120.5333
            },
            "Zhubei": {
                "lat": 24.8333,
                "lng": 121.0119
            },
            "Pingzhen": {
                "lat": 24.9439,
                "lng": 121.2161
            },
            "Pingtung": {
                "lat": 22.6761,
                "lng": 120.4942
            },
            "Yangmei": {
                "lat": 24.9167,
                "lng": 121.15
            },
            "Bade": {
                "lat": 24.9575,
                "lng": 121.2989
            },
            "Yilan": {
                "lat": 24.75,
                "lng": 121.75
            },
            "Yuanlin": {
                "lat": 23.9611,
                "lng": 120.5736
            },
            "Taitung": {
                "lat": 22.7583,
                "lng": 121.1444
            },
            "Douliu": {
                "lat": 23.7075,
                "lng": 120.5439
            },
            "Toufen": {
                "lat": 24.6832,
                "lng": 120.9072
            },
            "Nantou": {
                "lat": 23.9167,
                "lng": 120.6833
            },
            "Hualien": {
                "lat": 23.9722,
                "lng": 121.6064
            },
            "Caotun": {
                "lat": 23.983,
                "lng": 120.7326
            },
            "Zhudong": {
                "lat": 24.7366,
                "lng": 121.0916
            },
            "Miaoli": {
                "lat": 24.57,
                "lng": 120.82
            },
            "Zhunan": {
                "lat": 24.6833,
                "lng": 120.8833
            },
            "Hemei": {
                "lat": 24.1167,
                "lng": 120.5
            },
            "Lugang": {
                "lat": 24.05,
                "lng": 120.4333
            },
            "Ji\u2019an": {
                "lat": 23.95,
                "lng": 121.5722
            },
            "Hukou": {
                "lat": 24.9,
                "lng": 121.05
            },
            "Puli": {
                "lat": 23.9667,
                "lng": 120.9667
            },
            "Minxiong": {
                "lat": 23.5504,
                "lng": 120.4457
            },
            "Huwei": {
                "lat": 23.72,
                "lng": 120.4354
            },
            "Luodong": {
                "lat": 24.6767,
                "lng": 121.7669
            },
            "Magong": {
                "lat": 23.5667,
                "lng": 119.5833
            },
            "Xinfeng": {
                "lat": 24.9167,
                "lng": 121.0
            },
            "Zhushan": {
                "lat": 23.6889,
                "lng": 120.7289
            },
            "Xihu": {
                "lat": 23.9594,
                "lng": 120.48
            },
            "Chaozhou": {
                "lat": 22.55,
                "lng": 120.5333
            },
            "Dongshan": {
                "lat": 24.6319,
                "lng": 121.7539
            },
            "Neibu": {
                "lat": 22.65,
                "lng": 120.5875
            },
            "Wandan": {
                "lat": 22.5897,
                "lng": 120.4851
            },
            "Mailiao": {
                "lat": 23.75,
                "lng": 120.25
            },
            "Erlin": {
                "lat": 23.9229,
                "lng": 120.4091
            },
            "Shuishang": {
                "lat": 23.4319,
                "lng": 120.4147
            },
            "Donggang": {
                "lat": 22.4667,
                "lng": 120.4544
            },
            "Fuxing": {
                "lat": 24.0341,
                "lng": 120.4235
            },
            "Xiluo": {
                "lat": 23.7827,
                "lng": 120.4551
            },
            "Huatan": {
                "lat": 24.0316,
                "lng": 120.5579
            },
            "Yuanli": {
                "lat": 24.4168,
                "lng": 120.683
            },
            "Dounan": {
                "lat": 23.6833,
                "lng": 120.4833
            },
            "Zhongbu": {
                "lat": 23.4082,
                "lng": 120.5343
            },
            "Jincheng": {
                "lat": 24.4167,
                "lng": 118.3167
            },
            "Shetou": {
                "lat": 23.9076,
                "lng": 120.5992
            },
            "Pozi": {
                "lat": 23.4611,
                "lng": 120.242
            },
            "Beigang": {
                "lat": 23.5667,
                "lng": 120.3
            },
            "Wujie": {
                "lat": 24.7,
                "lng": 121.7833
            },
            "Dacun": {
                "lat": 23.9911,
                "lng": 120.5394
            },
            "Tianzhong": {
                "lat": 23.8595,
                "lng": 120.5878
            },
            "Taibao": {
                "lat": 23.45,
                "lng": 120.3333
            },
            "Xiushui": {
                "lat": 24.0356,
                "lng": 120.5037
            },
            "Shengang": {
                "lat": 24.1648,
                "lng": 120.4886
            },
            "Su\u2019ao": {
                "lat": 24.5961,
                "lng": 121.8511
            },
            "Mingjian": {
                "lat": 23.851,
                "lng": 120.6775
            },
            "Yongjing": {
                "lat": 23.9223,
                "lng": 120.5356
            },
            "Jiaoxi": {
                "lat": 24.8167,
                "lng": 121.7667
            },
            "Houlong": {
                "lat": 24.6167,
                "lng": 120.7833
            },
            "Zhuqi": {
                "lat": 23.5065,
                "lng": 120.6021
            },
            "Buxin": {
                "lat": 23.9523,
                "lng": 120.5349
            },
            "Beidou": {
                "lat": 23.8747,
                "lng": 120.5248
            },
            "Xinbu": {
                "lat": 24.8478,
                "lng": 121.0956
            },
            "Xinyuan": {
                "lat": 22.5149,
                "lng": 120.447
            },
            "Waiyuanshan": {
                "lat": 24.7443,
                "lng": 121.6684
            },
            "Tongxiao": {
                "lat": 24.4833,
                "lng": 120.6833
            },
            "Fangyuan": {
                "lat": 23.925,
                "lng": 120.3158
            },
            "Buyan": {
                "lat": 23.9961,
                "lng": 120.4618
            },
            "Gongguan": {
                "lat": 24.5053,
                "lng": 120.8447
            },
            "Xingang": {
                "lat": 23.56,
                "lng": 120.35
            },
            "Gukeng": {
                "lat": 23.65,
                "lng": 120.5667
            },
            "Dalin": {
                "lat": 23.5989,
                "lng": 120.47
            },
            "Hengchun": {
                "lat": 22.0,
                "lng": 120.75
            },
            "Pitou": {
                "lat": 23.8775,
                "lng": 120.4706
            },
            "Changzhi": {
                "lat": 22.6833,
                "lng": 120.5333
            },
            "Xizhou": {
                "lat": 24.1156,
                "lng": 120.535
            },
            "Toucheng": {
                "lat": 24.85,
                "lng": 121.8167
            },
            "Tuku": {
                "lat": 23.6911,
                "lng": 120.362
            },
            "Citong": {
                "lat": 23.7729,
                "lng": 120.5277
            },
            "Guanxi": {
                "lat": 24.8,
                "lng": 121.1833
            },
            "Tianwei": {
                "lat": 23.9023,
                "lng": 120.5214
            },
            "Yuanchang": {
                "lat": 23.642,
                "lng": 120.3229
            },
            "Ligang": {
                "lat": 22.8011,
                "lng": 120.507
            },
            "Kouhu": {
                "lat": 23.5763,
                "lng": 120.1725
            },
            "Budai": {
                "lat": 23.36,
                "lng": 120.17
            },
            "Erlun": {
                "lat": 23.791,
                "lng": 120.3954
            },
            "Zhuangwei": {
                "lat": 24.7702,
                "lng": 121.8023
            },
            "Yanbu": {
                "lat": 22.7544,
                "lng": 120.5724
            },
            "Dongshi": {
                "lat": 23.4748,
                "lng": 120.1769
            },
            "Shuilin": {
                "lat": 23.5662,
                "lng": 120.234
            },
            "Lunbei": {
                "lat": 23.7789,
                "lng": 120.3331
            },
            "Gaoshu": {
                "lat": 22.8202,
                "lng": 120.5979
            },
            "Fangliao": {
                "lat": 22.3656,
                "lng": 120.5936
            },
            "Fenyuan": {
                "lat": 24.0154,
                "lng": 120.6274
            },
            "Yuli": {
                "lat": 23.3875,
                "lng": 121.3764
            },
            "Taixi": {
                "lat": 23.7,
                "lng": 120.2
            },
            "Jiuru": {
                "lat": 22.7333,
                "lng": 120.4833
            },
            "Sihu": {
                "lat": 23.6411,
                "lng": 120.201
            },
            "Sanxing": {
                "lat": 24.6739,
                "lng": 121.6631
            },
            "Xincheng": {
                "lat": 24.0514,
                "lng": 121.6083
            },
            "Xionglin": {
                "lat": 24.7742,
                "lng": 121.0964
            },
            "Wanluan": {
                "lat": 22.5727,
                "lng": 120.5678
            },
            "Dapi": {
                "lat": 23.6494,
                "lng": 120.4254
            },
            "Meishan": {
                "lat": 23.5607,
                "lng": 120.6296
            },
            "Jiadong": {
                "lat": 22.4305,
                "lng": 120.5471
            },
            "Guoxing": {
                "lat": 24.055,
                "lng": 120.87
            },
            "Shoufeng": {
                "lat": 23.8667,
                "lng": 121.5167
            },
            "Yizhu": {
                "lat": 23.3565,
                "lng": 120.2235
            },
            "Xiulin": {
                "lat": 24.2167,
                "lng": 121.5333
            },
            "Linnei": {
                "lat": 23.7591,
                "lng": 120.6184
            },
            "Beinan": {
                "lat": 22.7833,
                "lng": 121.1167
            },
            "Tongluo": {
                "lat": 24.4833,
                "lng": 120.7833
            },
            "Linbian": {
                "lat": 22.4333,
                "lng": 120.5167
            },
            "Lugu": {
                "lat": 23.75,
                "lng": 120.733
            },
            "Xianxi": {
                "lat": 24.1334,
                "lng": 120.4589
            },
            "Shuili": {
                "lat": 23.7989,
                "lng": 120.8553
            },
            "Zhutian": {
                "lat": 22.589,
                "lng": 120.5303
            },
            "Renai": {
                "lat": 24.0462,
                "lng": 121.1304
            },
            "Zhuolan": {
                "lat": 24.3222,
                "lng": 120.8447
            },
            "Dacheng": {
                "lat": 23.8483,
                "lng": 120.3089
            },
            "Kanding": {
                "lat": 22.5194,
                "lng": 120.5032
            },
            "Sanyi": {
                "lat": 24.4167,
                "lng": 120.7667
            },
            "Yuchi": {
                "lat": 23.9,
                "lng": 120.9333
            },
            "Lucao": {
                "lat": 23.4133,
                "lng": 120.3092
            },
            "Baoshan": {
                "lat": 24.7428,
                "lng": 120.9991
            },
            "Zhutang": {
                "lat": 23.8528,
                "lng": 120.4114
            },
            "Ershui": {
                "lat": 23.8167,
                "lng": 120.6167
            },
            "Zhongliao": {
                "lat": 23.9039,
                "lng": 120.7819
            },
            "Xikou": {
                "lat": 23.5947,
                "lng": 120.3948
            },
            "Dongshicuo": {
                "lat": 23.7021,
                "lng": 120.2556
            },
            "Chenggong": {
                "lat": 23.1167,
                "lng": 121.35
            },
            "Dahu": {
                "lat": 24.3978,
                "lng": 120.8486
            },
            "Hengshan": {
                "lat": 24.71,
                "lng": 121.1369
            },
            "Baozhong": {
                "lat": 23.6956,
                "lng": 120.3117
            },
            "Kuangfu": {
                "lat": 23.6351,
                "lng": 121.4228
            },
            "Zaoqiao": {
                "lat": 24.65,
                "lng": 120.8667
            },
            "Fanlu": {
                "lat": 23.4494,
                "lng": 120.6033
            },
            "Ruisui": {
                "lat": 23.4333,
                "lng": 121.5
            },
            "Taimali": {
                "lat": 22.6167,
                "lng": 121.0
            },
            "Linluo": {
                "lat": 22.6506,
                "lng": 120.5271
            },
            "Fenglin": {
                "lat": 23.75,
                "lng": 121.4333
            },
            "Jiji": {
                "lat": 23.83,
                "lng": 120.783
            },
            "Nanzhou": {
                "lat": 22.4789,
                "lng": 120.5156
            },
            "Touwu": {
                "lat": 24.5833,
                "lng": 120.85
            },
            "Fuli": {
                "lat": 23.1333,
                "lng": 121.2833
            },
            "Jinshui": {
                "lat": 24.5761,
                "lng": 121.3081
            },
            "Jianshi": {
                "lat": 24.7053,
                "lng": 121.2014
            },
            "Xinpi": {
                "lat": 22.488,
                "lng": 120.5814
            },
            "Nanzhuang": {
                "lat": 24.5699,
                "lng": 121.0157
            },
            "Beibu": {
                "lat": 24.6639,
                "lng": 121.0681
            },
            "Nangan": {
                "lat": 26.15,
                "lng": 119.9333
            },
            "Banqiao": {
                "lat": 25.0143,
                "lng": 121.4672
            }
        }
    },
    "GT": {
        "name": "Guatemala",
        "cities": {
            "Guatemala City": {
                "lat": 14.6133,
                "lng": -90.5353
            },
            "Mixco": {
                "lat": 14.6331,
                "lng": -90.6064
            },
            "Villa Nueva": {
                "lat": 14.5269,
                "lng": -90.5875
            },
            "San Juan Sacatep\u00e9quez": {
                "lat": 14.7189,
                "lng": -90.6442
            },
            "San Pedro Carch\u00e1": {
                "lat": 15.4667,
                "lng": -90.3
            },
            "Cob\u00e1n": {
                "lat": 15.4833,
                "lng": -90.3667
            },
            "Quetzaltenango": {
                "lat": 14.8333,
                "lng": -91.5167
            },
            "Jalapa": {
                "lat": 14.6333,
                "lng": -89.9833
            },
            "Escuintla": {
                "lat": 14.2978,
                "lng": -90.7869
            },
            "Villa Canales": {
                "lat": 14.4833,
                "lng": -90.5333
            },
            "Jutiapa": {
                "lat": 14.2828,
                "lng": -89.8925
            },
            "Amatitl\u00e1n": {
                "lat": 14.4833,
                "lng": -90.6167
            },
            "Chichicastenango": {
                "lat": 14.9333,
                "lng": -91.1167
            },
            "Momostenango": {
                "lat": 15.0444,
                "lng": -91.4083
            },
            "Huehuetenango": {
                "lat": 15.3147,
                "lng": -91.4761
            },
            "San Luis": {
                "lat": 16.2,
                "lng": -89.44
            },
            "Petapa": {
                "lat": 14.5,
                "lng": -90.5667
            },
            "Santa Luc\u00eda Cotzumalguapa": {
                "lat": 14.35,
                "lng": -91.0167
            },
            "Chinautla": {
                "lat": 14.7,
                "lng": -90.5
            },
            "Coatepeque": {
                "lat": 14.7,
                "lng": -91.8667
            },
            "Totonicap\u00e1n": {
                "lat": 14.9108,
                "lng": -91.3606
            },
            "Chimaltenango": {
                "lat": 14.6622,
                "lng": -90.8208
            },
            "Tecp\u00e1n Guatemala": {
                "lat": 14.7667,
                "lng": -91.0
            },
            "Pochuta": {
                "lat": 14.545,
                "lng": -91.0889
            },
            "Chiquimula": {
                "lat": 14.7833,
                "lng": -89.5333
            },
            "Puerto Barrios": {
                "lat": 15.7133,
                "lng": -88.5899
            },
            "Retalhuleu": {
                "lat": 14.5333,
                "lng": -91.6833
            },
            "Malacat\u00e1n": {
                "lat": 14.9,
                "lng": -92.0497
            },
            "Joyabaj": {
                "lat": 14.995,
                "lng": -90.8075
            },
            "Morales": {
                "lat": 15.4725,
                "lng": -88.8414
            },
            "Solol\u00e1": {
                "lat": 14.7667,
                "lng": -91.1833
            },
            "Sayaxch\u00e9": {
                "lat": 16.5167,
                "lng": -90.1833
            },
            "Santa Cruz del Quich\u00e9": {
                "lat": 15.03,
                "lng": -91.15
            },
            "Senah\u00fa": {
                "lat": 15.4164,
                "lng": -89.8203
            },
            "San Mart\u00edn Jilotepeque": {
                "lat": 14.7833,
                "lng": -90.8
            },
            "Chiantla": {
                "lat": 15.35,
                "lng": -91.45
            },
            "San Jos\u00e9 Pinula": {
                "lat": 14.55,
                "lng": -90.4167
            },
            "Santa Catarina Pinula": {
                "lat": 14.5667,
                "lng": -90.5
            },
            "Nahual\u00e1": {
                "lat": 14.85,
                "lng": -91.3167
            },
            "San Pedro Sacatep\u00e9quez": {
                "lat": 14.6833,
                "lng": -90.6333
            },
            "Concepci\u00f3n Tutuapa": {
                "lat": 15.2833,
                "lng": -91.7833
            },
            "Mazatenango": {
                "lat": 14.5333,
                "lng": -91.5
            },
            "El Estor": {
                "lat": 15.5333,
                "lng": -89.35
            },
            "Nueva Concepci\u00f3n": {
                "lat": 14.1997,
                "lng": -91.2997
            },
            "Tacan\u00e1": {
                "lat": 15.2414,
                "lng": -92.0683
            },
            "Panzos": {
                "lat": 15.4,
                "lng": -89.6667
            },
            "San Crist\u00f3bal Verapaz": {
                "lat": 15.365,
                "lng": -90.4792
            },
            "Comitancillo": {
                "lat": 15.0833,
                "lng": -91.7167
            },
            "L\u00edvingston": {
                "lat": 15.83,
                "lng": -88.75
            },
            "San Pedro Pinula": {
                "lat": 14.6667,
                "lng": -89.85
            },
            "Fray Bartolom\u00e9 de Las Casas": {
                "lat": 15.8456,
                "lng": -89.8658
            },
            "Jocot\u00e1n": {
                "lat": 14.8167,
                "lng": -89.3833
            },
            "Palencia": {
                "lat": 14.6667,
                "lng": -90.3667
            },
            "Uspant\u00e1n": {
                "lat": 15.3458,
                "lng": -90.8694
            },
            "Pal\u00edn": {
                "lat": 14.4039,
                "lng": -90.6986
            },
            "Patz\u00fan": {
                "lat": 14.6833,
                "lng": -91.0167
            },
            "Salam\u00e1": {
                "lat": 15.1,
                "lng": -90.2667
            },
            "Santa Mar\u00eda La Pila": {
                "lat": 15.6056,
                "lng": -89.8125
            },
            "Popt\u00fan": {
                "lat": 16.322,
                "lng": -89.422
            },
            "Chisec": {
                "lat": 15.8125,
                "lng": -90.3217
            },
            "Puerto San Jos\u00e9": {
                "lat": 13.9333,
                "lng": -90.8167
            },
            "San Pedro Ayampuc": {
                "lat": 14.7833,
                "lng": -90.45
            },
            "Chicacao": {
                "lat": 14.5333,
                "lng": -91.3167
            },
            "Cuilco": {
                "lat": 15.4,
                "lng": -91.9667
            },
            "Zacapa": {
                "lat": 14.9667,
                "lng": -89.5333
            },
            "San Francisco El Alto": {
                "lat": 14.95,
                "lng": -91.45
            },
            "San Antonio Suchitep\u00e9quez": {
                "lat": 14.5333,
                "lng": -91.4167
            },
            "Los Amates": {
                "lat": 15.2667,
                "lng": -89.1
            },
            "Purulh\u00e1": {
                "lat": 15.2667,
                "lng": -90.2
            },
            "Chiquimulilla": {
                "lat": 14.0833,
                "lng": -90.3833
            },
            "Soloma": {
                "lat": 15.7167,
                "lng": -91.45
            },
            "Tiquisate": {
                "lat": 14.2833,
                "lng": -91.3667
            },
            "Fraijanes": {
                "lat": 14.4667,
                "lng": -90.4333
            },
            "Tajumulco": {
                "lat": 15.0833,
                "lng": -91.8833
            },
            "Camot\u00e1n": {
                "lat": 14.8167,
                "lng": -89.3817
            },
            "San Juan Chamelco": {
                "lat": 15.4257,
                "lng": -90.3263
            },
            "Ostuncalco": {
                "lat": 14.8667,
                "lng": -91.6167
            },
            "La Democracia": {
                "lat": 14.2308,
                "lng": -90.9472
            },
            "Santa Catarina Ixtahuac\u00e1n": {
                "lat": 14.8,
                "lng": -91.3667
            },
            "Barberena": {
                "lat": 14.3167,
                "lng": -90.3667
            },
            "Cubulco": {
                "lat": 15.1083,
                "lng": -90.6306
            },
            "San Mateo Ixtat\u00e1n": {
                "lat": 15.8333,
                "lng": -91.4833
            },
            "Aguacat\u00e1n": {
                "lat": 15.35,
                "lng": -91.3167
            },
            "Antigua Guatemala": {
                "lat": 14.5667,
                "lng": -90.7333
            },
            "Santa Mar\u00eda Chiquimula": {
                "lat": 15.0292,
                "lng": -91.3294
            },
            "Ixtahuac\u00e1n": {
                "lat": 15.4167,
                "lng": -91.7667
            },
            "Colomba": {
                "lat": 14.7167,
                "lng": -91.7333
            },
            "Esquipulas": {
                "lat": 14.5667,
                "lng": -89.35
            },
            "San Andr\u00e9s Villa Seca": {
                "lat": 14.5667,
                "lng": -91.5833
            },
            "San Agust\u00edn Acasaguastl\u00e1n": {
                "lat": 14.95,
                "lng": -89.9667
            },
            "Sacapulas": {
                "lat": 15.2892,
                "lng": -91.0892
            },
            "La Gomera": {
                "lat": 14.0833,
                "lng": -91.05
            },
            "San Miguel Ixtahuac\u00e1n": {
                "lat": 15.25,
                "lng": -91.75
            },
            "San Pablo": {
                "lat": 14.9333,
                "lng": -92.0
            },
            "El Tumbador": {
                "lat": 14.8667,
                "lng": -91.9333
            },
            "Chajul": {
                "lat": 15.4872,
                "lng": -91.0347
            },
            "Santa Eulalia": {
                "lat": 15.7333,
                "lng": -91.4586
            },
            "Nent\u00f3n": {
                "lat": 15.8,
                "lng": -91.75
            },
            "Tucur\u00fa": {
                "lat": 15.3,
                "lng": -90.0667
            },
            "Gual\u00e1n": {
                "lat": 15.1333,
                "lng": -89.3667
            },
            "San Marcos": {
                "lat": 14.9653,
                "lng": -91.7958
            },
            "La Libertad": {
                "lat": 16.7804,
                "lng": -90.12
            },
            "Santo Domingo Suchitep\u00e9quez": {
                "lat": 14.4667,
                "lng": -91.4833
            },
            "Masagua": {
                "lat": 14.2,
                "lng": -90.85
            },
            "Asunci\u00f3n Mita": {
                "lat": 14.3333,
                "lng": -89.7167
            },
            "Mataquescuintla": {
                "lat": 14.5336,
                "lng": -90.1839
            },
            "Cun\u00e9n": {
                "lat": 15.3333,
                "lng": -91.0333
            },
            "Cantel": {
                "lat": 14.8112,
                "lng": -91.4555
            },
            "Cuilapa": {
                "lat": 14.2833,
                "lng": -90.3
            },
            "Sumpango": {
                "lat": 14.6478,
                "lng": -90.7347
            },
            "Rabinal": {
                "lat": 15.068,
                "lng": -90.489
            },
            "Santiago Atitl\u00e1n": {
                "lat": 14.6333,
                "lng": -91.2333
            },
            "Colotenango": {
                "lat": 15.4167,
                "lng": -91.7167
            },
            "Santa B\u00e1rbara": {
                "lat": 14.4333,
                "lng": -91.2333
            },
            "Flores": {
                "lat": 16.9297,
                "lng": -89.8917
            },
            "Jacaltenango": {
                "lat": 15.6667,
                "lng": -91.7333
            },
            "El Asintal": {
                "lat": 14.6,
                "lng": -91.7333
            },
            "San Sebasti\u00e1n Huehuetenango": {
                "lat": 15.3833,
                "lng": -91.5833
            },
            "Chicam\u00e1n": {
                "lat": 15.3483,
                "lng": -90.7994
            },
            "Yepocapa": {
                "lat": 14.5,
                "lng": -90.95
            },
            "San Crist\u00f3bal Totonicap\u00e1n": {
                "lat": 14.9167,
                "lng": -91.4333
            },
            "Sanarate": {
                "lat": 14.795,
                "lng": -90.1922
            },
            "Nuevo San Carlos": {
                "lat": 14.6,
                "lng": -91.7
            },
            "Tactic": {
                "lat": 15.3167,
                "lng": -90.3511
            },
            "San Pedro Necta": {
                "lat": 15.5,
                "lng": -91.7667
            },
            "Patulul": {
                "lat": 14.4167,
                "lng": -91.1667
            },
            "Ciudad Vieja": {
                "lat": 14.5233,
                "lng": -90.7667
            },
            "Patzic\u00eda": {
                "lat": 14.6317,
                "lng": -90.9269
            },
            "Raxruh\u00e1": {
                "lat": 15.8666,
                "lng": -90.0424
            },
            "Zacualpa": {
                "lat": 15.0272,
                "lng": -90.8778
            },
            "Todos Santos Cuchumat\u00e1n": {
                "lat": 15.5167,
                "lng": -91.6167
            },
            "Olintepeque": {
                "lat": 14.8833,
                "lng": -91.5167
            },
            "Nueva Santa Rosa": {
                "lat": 14.3811,
                "lng": -90.2761
            },
            "San Raimundo": {
                "lat": 14.7647,
                "lng": -90.5956
            },
            "Champerico": {
                "lat": 14.293,
                "lng": -91.914
            },
            "La Uni\u00f3n": {
                "lat": 14.9667,
                "lng": -89.2833
            },
            "San P\u00e9dro Jocopilas": {
                "lat": 15.0953,
                "lng": -91.1508
            },
            "Comapa": {
                "lat": 14.1167,
                "lng": -89.9167
            },
            "Santiago Sacatep\u00e9quez": {
                "lat": 14.6531,
                "lng": -90.6525
            },
            "San Miguel Chicaj": {
                "lat": 15.1,
                "lng": -90.4
            },
            "Taxisco": {
                "lat": 14.0667,
                "lng": -90.4667
            },
            "San Juan Cotzal": {
                "lat": 15.4353,
                "lng": -91.0356
            },
            "San Miguel Acat\u00e1n": {
                "lat": 15.7,
                "lng": -91.6167
            },
            "Cuyotenango": {
                "lat": 14.5333,
                "lng": -91.5667
            },
            "San Carlos Sija": {
                "lat": 14.9833,
                "lng": -91.55
            },
            "Santa Cruz Verapaz": {
                "lat": 15.3667,
                "lng": -90.4333
            },
            "San Andr\u00e9s Itzapa": {
                "lat": 14.6167,
                "lng": -90.85
            },
            "La Blanca": {
                "lat": 14.5792,
                "lng": -92.1414
            },
            "San Jos\u00e9 Poaquil": {
                "lat": 14.8167,
                "lng": -90.9
            },
            "San Mart\u00edn Sacatep\u00e9quez": {
                "lat": 14.8246,
                "lng": -91.6425
            },
            "Chich\u00e9": {
                "lat": 15.0106,
                "lng": -91.065
            },
            "San Carlos Alzatate": {
                "lat": 14.5,
                "lng": -90.0667
            },
            "San Lucas Tolim\u00e1n": {
                "lat": 14.6333,
                "lng": -91.1333
            },
            "Santa Catarina Mita": {
                "lat": 14.45,
                "lng": -89.75
            },
            "El Palmar": {
                "lat": 14.65,
                "lng": -91.5833
            },
            "Jalpatagua": {
                "lat": 14.1333,
                "lng": -90.0167
            },
            "Jerez": {
                "lat": 14.1,
                "lng": -89.75
            },
            "Monjas": {
                "lat": 14.5,
                "lng": -89.8667
            },
            "R\u00edo Bravo": {
                "lat": 14.4011,
                "lng": -91.3195
            },
            "San Juan Ixcoy": {
                "lat": 15.6,
                "lng": -91.45
            },
            "San Antonio Ilotenango": {
                "lat": 15.0544,
                "lng": -91.2294
            },
            "San Sebasti\u00e1n": {
                "lat": 14.5667,
                "lng": -91.65
            },
            "Ciudad Melchor de Mencos": {
                "lat": 17.0667,
                "lng": -89.15
            },
            "Olopa": {
                "lat": 14.6833,
                "lng": -89.35
            },
            "Dolores": {
                "lat": 16.5142,
                "lng": -89.4158
            },
            "Quezaltepeque": {
                "lat": 14.6333,
                "lng": -89.45
            },
            "Conguaco": {
                "lat": 14.05,
                "lng": -90.0333
            },
            "San Andr\u00e9s Xecul": {
                "lat": 14.9,
                "lng": -91.4833
            },
            "Zaragoza": {
                "lat": 14.65,
                "lng": -90.8833
            },
            "San Agust\u00edn Chahal": {
                "lat": 15.75,
                "lng": -89.5667
            },
            "Pueblo Nuevo Vi\u00f1as": {
                "lat": 14.2333,
                "lng": -90.4667
            },
            "San Lucas Sacatep\u00e9quez": {
                "lat": 14.6167,
                "lng": -90.65
            },
            "Acatenango": {
                "lat": 14.5544,
                "lng": -90.9439
            },
            "Alotenango": {
                "lat": 14.4878,
                "lng": -90.8058
            },
            "Cabric\u00e1n": {
                "lat": 15.0747,
                "lng": -91.6486
            },
            "San Andr\u00e9s Sajcabaj\u00e1": {
                "lat": 15.1756,
                "lng": -90.9417
            },
            "Guastatoya": {
                "lat": 14.85,
                "lng": -90.0667
            },
            "Ciudad Tec\u00fan Um\u00e1n": {
                "lat": 14.6667,
                "lng": -92.15
            },
            "Sipacapa": {
                "lat": 15.2122,
                "lng": -91.6347
            },
            "San Luis Jilotepeque": {
                "lat": 14.65,
                "lng": -89.7333
            },
            "Santa Mar\u00eda de Jes\u00fas": {
                "lat": 14.4933,
                "lng": -90.7094
            },
            "Santa Mar\u00eda Ixhuat\u00e1n": {
                "lat": 14.1833,
                "lng": -90.2667
            },
            "Samayac": {
                "lat": 14.5833,
                "lng": -91.4667
            },
            "San Felipe": {
                "lat": 14.6206,
                "lng": -91.5961
            },
            "Lanqu\u00edn": {
                "lat": 15.5667,
                "lng": -89.9667
            },
            "Siquinal\u00e1": {
                "lat": 14.3,
                "lng": -90.9667
            },
            "La M\u00e1quina": {
                "lat": 14.3,
                "lng": -91.5667
            },
            "Casillas": {
                "lat": 14.4167,
                "lng": -90.25
            },
            "San Juan Atit\u00e1n": {
                "lat": 15.4333,
                "lng": -91.6333
            },
            "San Sebasti\u00e1n Coat\u00e1n": {
                "lat": 15.7333,
                "lng": -91.5667
            },
            "El Quetzal": {
                "lat": 14.7667,
                "lng": -91.8167
            },
            "El Progreso": {
                "lat": 14.35,
                "lng": -89.85
            },
            "Santa Lucia La Reforma": {
                "lat": 15.1333,
                "lng": -91.2333
            },
            "La Esperanza": {
                "lat": 14.8667,
                "lng": -91.5667
            },
            "Ixchigu\u00e1n": {
                "lat": 15.1675,
                "lng": -91.9319
            },
            "Flores Costa Cuca": {
                "lat": 14.65,
                "lng": -91.8167
            },
            "San Francisco Zapotitl\u00e1n": {
                "lat": 14.5833,
                "lng": -91.5167
            },
            "Tamah\u00fa": {
                "lat": 15.3333,
                "lng": -90.1667
            },
            "Santa Ana": {
                "lat": 16.8069,
                "lng": -89.8272
            },
            "Quesada": {
                "lat": 14.2667,
                "lng": -90.0333
            },
            "El Tejar": {
                "lat": 14.65,
                "lng": -90.8
            },
            "Santa Apolonia": {
                "lat": 14.7833,
                "lng": -90.9667
            },
            "Jocotenango": {
                "lat": 14.5819,
                "lng": -90.7436
            },
            "Pajapita": {
                "lat": 14.7167,
                "lng": -92.0333
            },
            "Malacatancito": {
                "lat": 15.2167,
                "lng": -91.5167
            },
            "San Jos\u00e9 Ojetenam": {
                "lat": 15.2167,
                "lng": -91.9667
            },
            "San Antonio La Paz": {
                "lat": 14.75,
                "lng": -90.2833
            },
            "San Pablo Jocopilas": {
                "lat": 14.5833,
                "lng": -91.45
            },
            "Guazacap\u00e1n": {
                "lat": 14.0667,
                "lng": -90.4167
            },
            "Santa Luc\u00eda Utatl\u00e1n": {
                "lat": 14.7667,
                "lng": -91.2667
            },
            "Salcaj\u00e1": {
                "lat": 14.8833,
                "lng": -91.45
            },
            "Santa Rosa de Lima": {
                "lat": 14.3833,
                "lng": -90.3
            },
            "Concepci\u00f3n Huista": {
                "lat": 15.6167,
                "lng": -91.6833
            },
            "San Antonio Sacatep\u00e9quez": {
                "lat": 14.9667,
                "lng": -91.7333
            },
            "Concepci\u00f3n Chiquirichapa": {
                "lat": 14.85,
                "lng": -91.6167
            },
            "Palestina de los Altos": {
                "lat": 14.9333,
                "lng": -91.7
            },
            "Parramos": {
                "lat": 14.6078,
                "lng": -90.8031
            },
            "Iztapa": {
                "lat": 13.9331,
                "lng": -90.7075
            },
            "Teculut\u00e1n": {
                "lat": 14.9831,
                "lng": -89.5667
            },
            "Atescatempa": {
                "lat": 14.175,
                "lng": -89.7417
            },
            "Pastores": {
                "lat": 14.6,
                "lng": -90.75
            },
            "Cajol\u00e1": {
                "lat": 14.9167,
                "lng": -91.6167
            },
            "San Antonio Huista": {
                "lat": 15.65,
                "lng": -91.7667
            },
            "San Juan Ermita": {
                "lat": 14.7667,
                "lng": -89.4333
            },
            "San Rafael La Independencia": {
                "lat": 15.7167,
                "lng": -91.5333
            },
            "San Vicente Pacaya": {
                "lat": 14.4161,
                "lng": -90.6392
            },
            "San Rafael Pie de la Cuesta": {
                "lat": 14.9333,
                "lng": -91.9167
            },
            "Yupiltepeque": {
                "lat": 14.2,
                "lng": -89.7833
            },
            "Sipacate": {
                "lat": 13.9333,
                "lng": -91.15
            },
            "San Crist\u00f3bal Cucho": {
                "lat": 14.9,
                "lng": -91.7833
            },
            "Santa Cruz Naranjo": {
                "lat": 14.3833,
                "lng": -90.3667
            },
            "Agua Blanca": {
                "lat": 14.4833,
                "lng": -89.6494
            },
            "Guanagazapa": {
                "lat": 14.2333,
                "lng": -90.65
            },
            "Santa Luc\u00eda Milpas Altas": {
                "lat": 14.5833,
                "lng": -90.6833
            },
            "Almolonga": {
                "lat": 14.8122,
                "lng": -91.4944
            },
            "San Bernardino": {
                "lat": 14.5333,
                "lng": -91.45
            },
            "Sibinal": {
                "lat": 15.1342,
                "lng": -92.05
            },
            "Chuarrancho": {
                "lat": 14.8181,
                "lng": -90.5153
            },
            "San Francisco": {
                "lat": 16.7833,
                "lng": -89.9361
            },
            "Santa Cruz Mulu\u00e1": {
                "lat": 14.5833,
                "lng": -91.6167
            },
            "Panajachel": {
                "lat": 14.7406,
                "lng": -91.1594
            },
            "Zunil": {
                "lat": 14.7833,
                "lng": -91.4833
            },
            "San Bartolom\u00e9 Jocotenango": {
                "lat": 15.1928,
                "lng": -91.0775
            },
            "San Antonio Palop\u00f3": {
                "lat": 14.7,
                "lng": -91.1167
            },
            "San Miguel Due\u00f1as": {
                "lat": 14.5167,
                "lng": -90.8
            },
            "El Chal": {
                "lat": 16.64,
                "lng": -89.65
            },
            "Huit\u00e1n": {
                "lat": 15.1,
                "lng": -91.6167
            },
            "Caba\u00f1as": {
                "lat": 14.9333,
                "lng": -89.8
            },
            "Santo Domingo Xenacoj": {
                "lat": 14.6822,
                "lng": -90.7067
            },
            "El Rodeo": {
                "lat": 14.3906,
                "lng": -90.8314
            },
            "Esquipulas Palo Gordo": {
                "lat": 14.9333,
                "lng": -91.8167
            },
            "San Jos\u00e9 Acatempa": {
                "lat": 14.2667,
                "lng": -90.1333
            },
            "San Rafael Las Flores": {
                "lat": 14.4814,
                "lng": -90.1733
            },
            "San Rafael Petzal": {
                "lat": 15.4167,
                "lng": -91.6667
            },
            "Sansare": {
                "lat": 14.75,
                "lng": -90.1167
            },
            "Chinique": {
                "lat": 15.0411,
                "lng": -91.0269
            },
            "San Jorge": {
                "lat": 14.9333,
                "lng": -89.6
            },
            "San Juan La Laguna": {
                "lat": 14.7,
                "lng": -91.2833
            },
            "San Jacinto": {
                "lat": 14.6667,
                "lng": -89.5
            },
            "Usumatl\u00e1n": {
                "lat": 14.9489,
                "lng": -89.7767
            },
            "San Mart\u00edn Zapotitl\u00e1n": {
                "lat": 14.6,
                "lng": -91.6
            },
            "San Juan Tecuaco": {
                "lat": 14.0836,
                "lng": -90.2664
            },
            "Santo Tom\u00e1s La Uni\u00f3n": {
                "lat": 14.6333,
                "lng": -91.3833
            },
            "Oc\u00f3s": {
                "lat": 14.5094,
                "lng": -92.1933
            },
            "Magdalena Milpas Altas": {
                "lat": 14.5453,
                "lng": -90.6753
            },
            "Canill\u00e1": {
                "lat": 15.1667,
                "lng": -90.85
            },
            "San Antonio Aguas Calientes": {
                "lat": 14.5333,
                "lng": -90.7667
            },
            "Playa Grande": {
                "lat": 15.9833,
                "lng": -90.7667
            },
            "Moraz\u00e1n": {
                "lat": 14.9328,
                "lng": -90.1431
            },
            "Concepci\u00f3n Las Minas": {
                "lat": 14.5167,
                "lng": -89.45
            },
            "San Miguel Pan\u00e1n": {
                "lat": 14.5333,
                "lng": -91.3667
            },
            "San Bartolo": {
                "lat": 15.0844,
                "lng": -91.4558
            },
            "Huit\u00e9": {
                "lat": 14.9333,
                "lng": -89.7167
            },
            "San Jos\u00e9 El \u00cddolo": {
                "lat": 14.45,
                "lng": -91.4167
            },
            "Tectit\u00e1n": {
                "lat": 15.3333,
                "lng": -92.0333
            },
            "San Pedro La Laguna": {
                "lat": 14.694,
                "lng": -91.272
            },
            "Moyuta": {
                "lat": 14.0333,
                "lng": -90.0833
            },
            "Pasaco": {
                "lat": 13.9833,
                "lng": -90.2
            },
            "Santa Cruz Balany\u00e1": {
                "lat": 14.6833,
                "lng": -90.9167
            },
            "Estanzuela": {
                "lat": 15.0,
                "lng": -89.5667
            },
            "Santa Ana Huista": {
                "lat": 15.6833,
                "lng": -91.8167
            },
            "San Gaspar Ixchil": {
                "lat": 15.3833,
                "lng": -91.7167
            },
            "Zapotitl\u00e1n": {
                "lat": 14.1333,
                "lng": -89.8333
            },
            "Santiago Chimaltenango": {
                "lat": 15.4814,
                "lng": -91.7261
            },
            "El Chol": {
                "lat": 14.9667,
                "lng": -90.4833
            },
            "Santa Clara La Laguna": {
                "lat": 14.7167,
                "lng": -91.3
            },
            "Pachalum": {
                "lat": 14.9269,
                "lng": -90.665
            },
            "San Miguel Sig\u00fcil\u00e1": {
                "lat": 14.9,
                "lng": -91.6167
            },
            "San Francisco la Uni\u00f3n": {
                "lat": 14.9167,
                "lng": -91.5333
            },
            "San Juan Bautista": {
                "lat": 14.4167,
                "lng": -91.1833
            },
            "San Jos\u00e9 La Arada": {
                "lat": 14.7167,
                "lng": -89.5833
            },
            "San Manuel Chaparr\u00f3n": {
                "lat": 14.5167,
                "lng": -89.7667
            },
            "Sibilia": {
                "lat": 15.0,
                "lng": -91.6167
            },
            "Zunilito": {
                "lat": 14.6167,
                "lng": -91.5167
            },
            "San Bartolom\u00e9 Milpas Altas": {
                "lat": 14.6072,
                "lng": -90.6781
            }
        }
    },
    "KW": {
        "name": "Kuwait",
        "cities": {
            "Kuwait City": {
                "lat": 29.3697,
                "lng": 47.9783
            },
            "Al Jahr\u0101\u2019": {
                "lat": 29.35,
                "lng": 47.6833
            },
            "Ab\u016b \u1e28ulayfah": {
                "lat": 29.1322,
                "lng": 48.1261
            },
            "Al A\u1e29mad\u012b": {
                "lat": 29.0769,
                "lng": 48.0838
            },
            "Ar Riqqah": {
                "lat": 29.1489,
                "lng": 48.1057
            }
        }
    },
    "HU": {
        "name": "Hungary",
        "cities": {
            "Budapest": {
                "lat": 47.4925,
                "lng": 19.0514
            },
            "Debrecen": {
                "lat": 47.53,
                "lng": 21.6392
            },
            "Sz\u00e9kesfeh\u00e9rv\u00e1r": {
                "lat": 47.1956,
                "lng": 18.4089
            },
            "P\u00e9cs": {
                "lat": 46.0711,
                "lng": 18.2331
            },
            "Gy\u0151r": {
                "lat": 47.6842,
                "lng": 17.6344
            },
            "Szeged": {
                "lat": 46.255,
                "lng": 20.145
            },
            "Ny\u00edregyh\u00e1za": {
                "lat": 47.9531,
                "lng": 21.7271
            },
            "Kecskem\u00e9t": {
                "lat": 46.9061,
                "lng": 19.6897
            },
            "Szombathely": {
                "lat": 47.2351,
                "lng": 16.6219
            },
            "Miskolc": {
                "lat": 48.0833,
                "lng": 20.6667
            },
            "Veszpr\u00e9m": {
                "lat": 47.093,
                "lng": 17.9138
            },
            "Kaposv\u00e1r": {
                "lat": 46.3638,
                "lng": 17.7823
            },
            "G\u00f6d\u00f6ll\u0151": {
                "lat": 47.6,
                "lng": 19.3667
            },
            "Sopron": {
                "lat": 47.6849,
                "lng": 16.5831
            },
            "\u00c9rd": {
                "lat": 47.3784,
                "lng": 18.922
            },
            "Szolnok": {
                "lat": 47.1747,
                "lng": 20.1965
            },
            "Tatab\u00e1nya": {
                "lat": 47.5862,
                "lng": 18.3949
            },
            "Zalaegerszeg": {
                "lat": 46.8392,
                "lng": 16.8511
            },
            "B\u00e9k\u00e9scsaba": {
                "lat": 46.679,
                "lng": 21.091
            },
            "Eger": {
                "lat": 47.899,
                "lng": 20.3747
            },
            "Nagykanizsa": {
                "lat": 46.455,
                "lng": 16.9925
            },
            "Dunakeszi": {
                "lat": 47.6303,
                "lng": 19.1412
            },
            "H\u00f3dmez\u0151v\u00e1s\u00e1rhely": {
                "lat": 46.4304,
                "lng": 20.3188
            },
            "Duna\u00fajv\u00e1ros": {
                "lat": 46.9806,
                "lng": 18.9127
            },
            "Szigetszentmikl\u00f3s": {
                "lat": 47.3456,
                "lng": 19.0483
            },
            "Cegl\u00e9d": {
                "lat": 47.1743,
                "lng": 19.802
            },
            "Mosonmagyar\u00f3v\u00e1r": {
                "lat": 47.8737,
                "lng": 17.2687
            },
            "V\u00e1c": {
                "lat": 47.7752,
                "lng": 19.131
            },
            "Baja": {
                "lat": 46.1833,
                "lng": 18.9536
            },
            "Salg\u00f3tarj\u00e1n": {
                "lat": 48.104,
                "lng": 19.8075
            },
            "\u00d3zd": {
                "lat": 48.2167,
                "lng": 20.3
            },
            "Szeksz\u00e1rd": {
                "lat": 46.356,
                "lng": 18.7038
            },
            "Hajd\u00fab\u00f6sz\u00f6rm\u00e9ny": {
                "lat": 47.6667,
                "lng": 21.5167
            },
            "Buda\u00f6rs": {
                "lat": 47.4607,
                "lng": 18.958
            },
            "Kiskunf\u00e9legyh\u00e1za": {
                "lat": 46.7052,
                "lng": 19.85
            },
            "P\u00e1pa": {
                "lat": 47.3237,
                "lng": 17.468
            },
            "Esztergom": {
                "lat": 47.7856,
                "lng": 18.7403
            },
            "Szentendre": {
                "lat": 47.7044,
                "lng": 19.0686
            },
            "Gyula": {
                "lat": 46.65,
                "lng": 21.2833
            },
            "Gy\u00f6ngy\u00f6s": {
                "lat": 47.7833,
                "lng": 19.9333
            },
            "Ajka": {
                "lat": 47.1006,
                "lng": 17.5522
            },
            "Kiskunhalas": {
                "lat": 46.4319,
                "lng": 19.4883
            },
            "J\u00e1szber\u00e9ny": {
                "lat": 47.5,
                "lng": 19.9167
            },
            "Orosh\u00e1za": {
                "lat": 46.5667,
                "lng": 20.6667
            },
            "Szentes": {
                "lat": 46.651,
                "lng": 20.258
            },
            "Gy\u00e1l": {
                "lat": 47.3861,
                "lng": 19.2192
            },
            "Si\u00f3fok": {
                "lat": 46.9239,
                "lng": 18.0901
            },
            "Kazincbarcika": {
                "lat": 48.2531,
                "lng": 20.6456
            },
            "Tata": {
                "lat": 47.6526,
                "lng": 18.3238
            },
            "Dunaharaszti": {
                "lat": 47.3539,
                "lng": 19.0948
            },
            "Hajd\u00faszoboszl\u00f3": {
                "lat": 47.4333,
                "lng": 21.3833
            },
            "Nagyk\u0151r\u00f6s": {
                "lat": 47.0332,
                "lng": 19.784
            },
            "G\u00f6d": {
                "lat": 47.6906,
                "lng": 19.1344
            },
            "Mak\u00f3": {
                "lat": 46.217,
                "lng": 20.483
            },
            "Koml\u00f3": {
                "lat": 46.1912,
                "lng": 18.2613
            },
            "Vecs\u00e9s": {
                "lat": 47.4057,
                "lng": 19.2648
            },
            "F\u00f3t": {
                "lat": 47.6181,
                "lng": 19.1903
            },
            "Veresegyh\u00e1z": {
                "lat": 47.6505,
                "lng": 19.283
            },
            "Hatvan": {
                "lat": 47.6681,
                "lng": 19.6697
            },
            "Gy\u00f6mr\u0151": {
                "lat": 47.425,
                "lng": 19.3977
            },
            "Kom\u00e1rom": {
                "lat": 47.7403,
                "lng": 18.1244
            },
            "V\u00e1rpalota": {
                "lat": 47.1989,
                "lng": 18.1376
            },
            "T\u00f6r\u00f6kszentmikl\u00f3s": {
                "lat": 47.1833,
                "lng": 20.4167
            },
            "Monor": {
                "lat": 47.3475,
                "lng": 19.4489
            },
            "Karcag": {
                "lat": 47.3111,
                "lng": 20.9161
            },
            "Pom\u00e1z": {
                "lat": 47.6431,
                "lng": 19.0196
            },
            "Szigethalom": {
                "lat": 47.3154,
                "lng": 19.0078
            },
            "Oroszl\u00e1ny": {
                "lat": 47.4833,
                "lng": 18.3167
            },
            "Keszthely": {
                "lat": 46.7696,
                "lng": 17.2481
            },
            "Sz\u00e1zhalombatta": {
                "lat": 47.3004,
                "lng": 18.9136
            },
            "Paks": {
                "lat": 46.622,
                "lng": 18.8559
            },
            "B\u00e9k\u00e9s": {
                "lat": 46.7667,
                "lng": 21.1333
            },
            "Dabas": {
                "lat": 47.189,
                "lng": 19.3126
            },
            "P\u00e9cel": {
                "lat": 47.4893,
                "lng": 19.3354
            },
            "Moh\u00e1cs": {
                "lat": 45.9959,
                "lng": 18.6798
            },
            "Domb\u00f3v\u00e1r": {
                "lat": 46.382,
                "lng": 18.1422
            },
            "Balmaz\u00fajv\u00e1ros": {
                "lat": 47.6167,
                "lng": 21.35
            },
            "Hajd\u00fan\u00e1n\u00e1s": {
                "lat": 47.85,
                "lng": 21.4333
            },
            "Mez\u0151t\u00far": {
                "lat": 47.0,
                "lng": 20.6333
            },
            "Mez\u0151k\u00f6vesd": {
                "lat": 47.8167,
                "lng": 20.5833
            },
            "Tisza\u00fajv\u00e1ros": {
                "lat": 47.9333,
                "lng": 21.0833
            },
            "Budakeszi": {
                "lat": 47.5123,
                "lng": 18.9281
            },
            "Csongr\u00e1d": {
                "lat": 46.7113,
                "lng": 20.1403
            },
            "M\u00e1t\u00e9szalka": {
                "lat": 47.95,
                "lng": 22.3167
            },
            "Biatorb\u00e1gy": {
                "lat": 47.4712,
                "lng": 18.8252
            },
            "S\u00e1rv\u00e1r": {
                "lat": 47.25,
                "lng": 16.9333
            },
            "Kisv\u00e1rda": {
                "lat": 48.2167,
                "lng": 22.0833
            },
            "T\u00f6r\u00f6kb\u00e1lint": {
                "lat": 47.4367,
                "lng": 18.9122
            },
            "Abony": {
                "lat": 47.1894,
                "lng": 20.0097
            },
            "Kalocsa": {
                "lat": 46.5335,
                "lng": 18.9858
            },
            "Pilisv\u00f6r\u00f6sv\u00e1r": {
                "lat": 47.6211,
                "lng": 18.9108
            },
            "Szarvas": {
                "lat": 46.8667,
                "lng": 20.55
            },
            "Tapolca": {
                "lat": 46.8828,
                "lng": 17.4081
            },
            "Beretty\u00f3\u00fajfalu": {
                "lat": 47.2167,
                "lng": 21.55
            },
            "Balassagyarmat": {
                "lat": 48.0786,
                "lng": 19.2942
            },
            "Hajd\u00fas\u00e1mson": {
                "lat": 47.6,
                "lng": 21.7667
            },
            "Kerepestarcsa": {
                "lat": 47.5478,
                "lng": 19.2634
            },
            "M\u00f3r": {
                "lat": 47.3717,
                "lng": 18.2086
            },
            "Albertirsa": {
                "lat": 47.24,
                "lng": 19.6067
            },
            "G\u00e1rdony": {
                "lat": 47.1973,
                "lng": 18.6091
            },
            "Kisk\u0151r\u00f6s": {
                "lat": 46.6204,
                "lng": 19.2838
            },
            "P\u00fcsp\u00f6klad\u00e1ny": {
                "lat": 47.3167,
                "lng": 21.1167
            },
            "S\u00e1toralja\u00fajhely": {
                "lat": 48.4,
                "lng": 21.6667
            },
            "Hajd\u00fahadh\u00e1z": {
                "lat": 47.6833,
                "lng": 21.6667
            },
            "Balatonf\u00fcred": {
                "lat": 46.95,
                "lng": 17.8833
            },
            "\u00dcll\u0151": {
                "lat": 47.3854,
                "lng": 19.3439
            },
            "Magl\u00f3d": {
                "lat": 47.4439,
                "lng": 19.3526
            },
            "Tiszavasv\u00e1ri": {
                "lat": 47.9667,
                "lng": 21.35
            },
            "\u00dajfeh\u00e9rt\u00f3": {
                "lat": 47.7989,
                "lng": 21.6833
            },
            "Bonyh\u00e1d": {
                "lat": 46.3006,
                "lng": 18.5309
            },
            "Gyomaendr\u0151d": {
                "lat": 46.9361,
                "lng": 20.8236
            },
            "Budakal\u00e1sz": {
                "lat": 47.6215,
                "lng": 19.046
            },
            "Nagyk\u00e1ta": {
                "lat": 47.412,
                "lng": 19.7396
            },
            "Hal\u00e1sztelek": {
                "lat": 47.3608,
                "lng": 18.9878
            },
            "Pilis": {
                "lat": 47.2844,
                "lng": 19.5435
            },
            "K\u0151szeg": {
                "lat": 47.3819,
                "lng": 16.5522
            },
            "Kiskunmajsa": {
                "lat": 46.4923,
                "lng": 19.7368
            },
            "Tiszak\u00e9cske": {
                "lat": 46.9312,
                "lng": 20.0951
            },
            "Lajosmizse": {
                "lat": 47.0264,
                "lng": 19.5578
            },
            "Isaszeg": {
                "lat": 47.5333,
                "lng": 19.4
            },
            "Di\u00f3sd": {
                "lat": 47.4042,
                "lng": 18.9458
            },
            "S\u00e1rbog\u00e1rd": {
                "lat": 46.8878,
                "lng": 18.6193
            },
            "Dorog": {
                "lat": 47.7194,
                "lng": 18.7292
            },
            "Ny\u00edrb\u00e1tor": {
                "lat": 47.8333,
                "lng": 22.1333
            },
            "T\u00f6k\u00f6l": {
                "lat": 47.3203,
                "lng": 18.9671
            },
            "B\u00e1tonyterenye": {
                "lat": 47.9892,
                "lng": 19.8286
            },
            "Saj\u00f3szentp\u00e9ter": {
                "lat": 48.2167,
                "lng": 20.7167
            },
            "Bicske": {
                "lat": 47.4907,
                "lng": 18.6363
            },
            "R\u00e1ckeve": {
                "lat": 47.1608,
                "lng": 18.9456
            },
            "Solym\u00e1r": {
                "lat": 47.591,
                "lng": 18.929
            },
            "Tiszaf\u00fcred": {
                "lat": 47.6167,
                "lng": 20.7667
            },
            "S\u00e1rospatak": {
                "lat": 48.319,
                "lng": 21.5664
            },
            "Marcali": {
                "lat": 46.5858,
                "lng": 17.4128
            },
            "Tolna": {
                "lat": 46.4236,
                "lng": 18.7903
            },
            "T\u00e1rnok": {
                "lat": 47.3597,
                "lng": 18.8586
            },
            "K\u00f6rmend": {
                "lat": 47.011,
                "lng": 16.6058
            },
            "Cs\u00f6m\u00f6r": {
                "lat": 47.55,
                "lng": 19.2333
            },
            "Kis\u00fajsz\u00e1ll\u00e1s": {
                "lat": 47.2169,
                "lng": 20.7669
            },
            "Tiszaf\u00f6ldv\u00e1r": {
                "lat": 46.9739,
                "lng": 20.2539
            },
            "Kapuv\u00e1r": {
                "lat": 47.6,
                "lng": 17.0333
            },
            "Balatonalm\u00e1di": {
                "lat": 47.0333,
                "lng": 18.0167
            },
            "\u00d3csa": {
                "lat": 47.2934,
                "lng": 19.226
            },
            "Heves": {
                "lat": 47.6,
                "lng": 20.2833
            },
            "Celld\u00f6m\u00f6lk": {
                "lat": 47.2557,
                "lng": 17.1491
            },
            "Barcs": {
                "lat": 45.9601,
                "lng": 17.4601
            },
            "Csorna": {
                "lat": 47.6167,
                "lng": 17.25
            },
            "Szigetv\u00e1r": {
                "lat": 46.0475,
                "lng": 17.7994
            },
            "Erd\u0151kertes": {
                "lat": 47.6667,
                "lng": 19.3167
            },
            "Mez\u0151ber\u00e9ny": {
                "lat": 46.8167,
                "lng": 21.0167
            },
            "Nagyat\u00e1d": {
                "lat": 46.2227,
                "lng": 17.3643
            },
            "Edel\u00e9ny": {
                "lat": 48.2967,
                "lng": 20.7442
            },
            "Sarkad": {
                "lat": 46.75,
                "lng": 21.3833
            },
            "Nagyk\u00e1ll\u00f3": {
                "lat": 47.8833,
                "lng": 21.85
            },
            "Kiskunlach\u00e1za": {
                "lat": 47.2,
                "lng": 19.0167
            },
            "Dunavars\u00e1ny": {
                "lat": 47.2781,
                "lng": 19.0672
            },
            "Nagykov\u00e1csi": {
                "lat": 47.58,
                "lng": 18.88
            },
            "Sikl\u00f3s": {
                "lat": 45.8521,
                "lng": 18.2988
            },
            "Piliscsaba": {
                "lat": 47.6167,
                "lng": 18.8333
            },
            "P\u00e1szt\u00f3": {
                "lat": 47.9194,
                "lng": 19.6978
            }
        }
    },
    "YE": {
        "name": "Yemen",
        "cities": {
            "Sanaa": {
                "lat": 15.3483,
                "lng": 44.2064
            },
            "Ta\u2018izz": {
                "lat": 13.5789,
                "lng": 44.0219
            },
            "Al \u1e28udaydah": {
                "lat": 14.8022,
                "lng": 42.9511
            },
            "Aden": {
                "lat": 12.8,
                "lng": 45.0333
            },
            "Ibb": {
                "lat": 13.9759,
                "lng": 44.1709
            },
            "Dham\u0101r": {
                "lat": 14.55,
                "lng": 44.4017
            },
            "Al Mukall\u0101": {
                "lat": 14.5333,
                "lng": 49.1333
            },
            "Al Man\u015f\u016brah": {
                "lat": 12.8531,
                "lng": 44.9722
            },
            "Tar\u012bm": {
                "lat": 16.05,
                "lng": 49.0
            },
            "Ash Shaykh \u2018Uthm\u0101n": {
                "lat": 12.8864,
                "lng": 45.0156
            },
            "Zinjib\u0101r": {
                "lat": 13.1283,
                "lng": 45.3803
            },
            "\u015ea\u2018dah": {
                "lat": 16.94,
                "lng": 43.7592
            },
            "Say\u2019\u016bn": {
                "lat": 15.943,
                "lng": 48.7873
            },
            "Ash Shi\u1e29r": {
                "lat": 14.7608,
                "lng": 49.6069
            },
            "At Taw\u0101h\u012b": {
                "lat": 12.7833,
                "lng": 44.9833
            },
            "Zab\u012bd": {
                "lat": 14.2,
                "lng": 43.3167
            },
            "Al Ma\u2018all\u0101\u2019": {
                "lat": 12.7897,
                "lng": 45.0064
            },
            "\u1e28ajjah": {
                "lat": 15.695,
                "lng": 43.5975
            },
            "B\u0101jil": {
                "lat": 15.0583,
                "lng": 43.285
            },
            "Rad\u0101\u2018": {
                "lat": 14.4151,
                "lng": 44.8371
            },
            "Bayt al Faq\u012bh": {
                "lat": 14.5161,
                "lng": 43.3244
            },
            "Al Q\u0101\u2018idah": {
                "lat": 13.7569,
                "lng": 44.1392
            },
            "Al Bay\u1e11\u0101\u2019": {
                "lat": 13.979,
                "lng": 45.574
            },
            "Ataq": {
                "lat": 14.5364,
                "lng": 46.8331
            },
            "Yar\u012bm": {
                "lat": 14.2972,
                "lng": 44.3803
            },
            "La\u1e29ij": {
                "lat": 13.05,
                "lng": 44.8833
            },
            "Al Madad": {
                "lat": 13.7167,
                "lng": 44.7333
            },
            "Ja\u2018\u0101r": {
                "lat": 13.2231,
                "lng": 45.3056
            },
            "Ma\u2018bar": {
                "lat": 14.794,
                "lng": 44.2935
            },
            "A\u1e11 \u1e10abyah": {
                "lat": 13.6967,
                "lng": 44.7308
            },
            "A\u1e11 \u1e10\u0101li\u2018": {
                "lat": 13.6957,
                "lng": 44.7314
            },
            "Marib": {
                "lat": 15.4606,
                "lng": 45.3261
            },
            "Ad D\u012bs": {
                "lat": 14.8833,
                "lng": 49.8667
            },
            "Az Zayd\u012byah": {
                "lat": 15.3292,
                "lng": 43.0094
            },
            "Al \u1e28azm": {
                "lat": 16.1642,
                "lng": 44.7767
            },
            "Damm\u0101j": {
                "lat": 16.8939,
                "lng": 43.8022
            },
            "Jiblah": {
                "lat": 13.9167,
                "lng": 44.15
            },
            "Khamir": {
                "lat": 15.9889,
                "lng": 43.9589
            },
            "Mocha": {
                "lat": 13.3203,
                "lng": 43.25
            },
            "Raydah": {
                "lat": 15.8233,
                "lng": 44.0386
            },
            "Shib\u0101m": {
                "lat": 15.9269,
                "lng": 48.6267
            },
            "Al Ghayz\u0327ah": {
                "lat": 16.2106,
                "lng": 52.1711
            },
            "Al Ma\u1e29w\u012bt": {
                "lat": 15.4694,
                "lng": 43.5453
            },
            "\u1e28ad\u012bb\u016b": {
                "lat": 12.65,
                "lng": 54.0167
            },
            "\u2018Amr\u0101n": {
                "lat": 15.6594,
                "lng": 43.9439
            },
            "Al Jab\u012bn": {
                "lat": 14.704,
                "lng": 43.599
            }
        }
    },
    "UZ": {
        "name": "Uzbekistan",
        "cities": {
            "Tashkent": {
                "lat": 41.3111,
                "lng": 69.2797
            },
            "Namangan": {
                "lat": 41.0011,
                "lng": 71.6683
            },
            "Andijon": {
                "lat": 40.6444,
                "lng": 72.3639
            },
            "Samarkand": {
                "lat": 39.6547,
                "lng": 66.9758
            },
            "Nukus": {
                "lat": 42.4667,
                "lng": 59.6
            },
            "Farg\u2018ona": {
                "lat": 40.3864,
                "lng": 71.7864
            },
            "Bukhara": {
                "lat": 39.7667,
                "lng": 64.4231
            },
            "Qarshi": {
                "lat": 38.8667,
                "lng": 65.8
            },
            "Qo\u2018qon": {
                "lat": 40.5286,
                "lng": 70.9425
            },
            "Marg\u2018ilon": {
                "lat": 40.4711,
                "lng": 71.7247
            },
            "Jizzax": {
                "lat": 40.1158,
                "lng": 67.8422
            },
            "Chirchiq": {
                "lat": 41.4667,
                "lng": 69.5833
            },
            "Urganch": {
                "lat": 41.55,
                "lng": 60.6333
            },
            "Olmaliq": {
                "lat": 40.85,
                "lng": 69.6
            },
            "Termiz": {
                "lat": 37.2167,
                "lng": 67.2833
            },
            "Navoiy": {
                "lat": 40.0844,
                "lng": 65.3792
            },
            "Angren": {
                "lat": 41.0167,
                "lng": 70.1436
            },
            "Kogon Shahri": {
                "lat": 39.7275,
                "lng": 64.5547
            },
            "Xiva": {
                "lat": 41.3814,
                "lng": 60.3611
            },
            "Shahrisabz": {
                "lat": 39.05,
                "lng": 66.8333
            },
            "Chust": {
                "lat": 40.9978,
                "lng": 71.2403
            },
            "Urgut Shahri": {
                "lat": 39.419,
                "lng": 67.2612
            },
            "Quvasoy": {
                "lat": 40.3,
                "lng": 71.9667
            },
            "Kattagan": {
                "lat": 40.2,
                "lng": 64.9167
            },
            "Bekobod": {
                "lat": 40.2167,
                "lng": 69.2167
            },
            "Q\u016dnghirot": {
                "lat": 43.0758,
                "lng": 58.9067
            },
            "Denov": {
                "lat": 38.2667,
                "lng": 67.9
            },
            "Shahrixon": {
                "lat": 40.7167,
                "lng": 72.05
            },
            "Asaka": {
                "lat": 40.6333,
                "lng": 72.2333
            },
            "Yangiy\u016dl": {
                "lat": 41.1125,
                "lng": 69.0467
            },
            "Parkent": {
                "lat": 41.2944,
                "lng": 69.6764
            },
            "Xo\u2018jayli Shahri": {
                "lat": 42.4,
                "lng": 59.45
            },
            "Kattaqo\u2019rg\u2019on Shahri": {
                "lat": 39.8989,
                "lng": 66.2561
            },
            "Guliston": {
                "lat": 40.4833,
                "lng": 68.7833
            },
            "Zarafshon Shahri": {
                "lat": 41.6081,
                "lng": 64.2264
            },
            "T\u016drtk\u016dl": {
                "lat": 41.55,
                "lng": 61.0
            },
            "Beruniy": {
                "lat": 41.6833,
                "lng": 60.75
            },
            "Quva": {
                "lat": 40.5247,
                "lng": 72.07
            },
            "Xonobod": {
                "lat": 40.8,
                "lng": 73.0
            },
            "Oqtosh Shahri": {
                "lat": 39.9269,
                "lng": 65.9256
            },
            "Kitob": {
                "lat": 39.1336,
                "lng": 66.8819
            },
            "Uchq\u016drghon Shahri": {
                "lat": 41.1214,
                "lng": 72.0936
            },
            "Xonqa": {
                "lat": 41.4742,
                "lng": 60.7822
            },
            "Ohangaron": {
                "lat": 40.9061,
                "lng": 69.6444
            },
            "Qo\u2018ng\u2018irot Shahri": {
                "lat": 43.0497,
                "lng": 58.8542
            },
            "Chimboy Shahri": {
                "lat": 42.9295,
                "lng": 59.782
            },
            "Dashtobod": {
                "lat": 40.1269,
                "lng": 68.4944
            },
            "Chortoq": {
                "lat": 41.0689,
                "lng": 71.8153
            },
            "Piskent": {
                "lat": 40.8992,
                "lng": 69.3425
            },
            "Paxtaobod": {
                "lat": 40.3453,
                "lng": 68.1925
            },
            "Payshamba Shahri": {
                "lat": 40.0078,
                "lng": 66.2372
            },
            "Buloqboshi": {
                "lat": 40.6222,
                "lng": 72.5028
            },
            "Kosonsoy": {
                "lat": 41.25,
                "lng": 71.55
            },
            "Qo\u2018rg\u2018ontepa": {
                "lat": 40.7336,
                "lng": 72.7583
            },
            "Keles": {
                "lat": 41.4033,
                "lng": 69.2067
            },
            "G\u2019ijduvon Shahri": {
                "lat": 40.1,
                "lng": 64.6667
            },
            "To\u2018raqo\u2018rg\u2018on": {
                "lat": 41.0,
                "lng": 71.5158
            },
            "Pop": {
                "lat": 40.8736,
                "lng": 71.1089
            },
            "Uychi": {
                "lat": 41.0294,
                "lng": 71.8483
            },
            "Bulung\u2019ur Shahri": {
                "lat": 39.76,
                "lng": 67.27
            },
            "Sherobod": {
                "lat": 37.6667,
                "lng": 67.0
            },
            "Yangiyer": {
                "lat": 40.2667,
                "lng": 68.8167
            },
            "Oltinko\u2018l": {
                "lat": 43.0758,
                "lng": 58.9067
            },
            "Bo\u2018ka": {
                "lat": 40.8136,
                "lng": 69.2019
            },
            "Qibray": {
                "lat": 41.3897,
                "lng": 69.465
            },
            "Gurlan": {
                "lat": 41.85,
                "lng": 60.4
            },
            "Xovos": {
                "lat": 40.2197,
                "lng": 68.84
            },
            "Zomin Shaharchasi": {
                "lat": 39.9631,
                "lng": 68.3939
            },
            "Haqqulobod": {
                "lat": 40.9061,
                "lng": 72.1194
            },
            "Iskandar": {
                "lat": 41.5581,
                "lng": 69.7047
            },
            "Dardoq": {
                "lat": 40.8156,
                "lng": 72.8311
            },
            "Poytug\u2018": {
                "lat": 40.9,
                "lng": 72.25
            },
            "Oltiariq": {
                "lat": 40.3917,
                "lng": 71.4767
            },
            "Salor": {
                "lat": 41.3833,
                "lng": 69.3583
            },
            "Baliqchi": {
                "lat": 40.9,
                "lng": 71.8528
            },
            "Sho\u2018rchi": {
                "lat": 38.0111,
                "lng": 67.7875
            },
            "G\u2019allaorol Shahri": {
                "lat": 40.0214,
                "lng": 67.5975
            },
            "Uchquduq Shahri": {
                "lat": 42.1567,
                "lng": 63.5556
            },
            "Chinobod": {
                "lat": 40.8767,
                "lng": 71.9725
            },
            "Jalolquduq": {
                "lat": 40.7194,
                "lng": 72.6428
            },
            "Paxtakor Shahri": {
                "lat": 40.3153,
                "lng": 67.9544
            },
            "Rishton": {
                "lat": 40.3567,
                "lng": 71.2847
            },
            "Chinoz": {
                "lat": 40.9375,
                "lng": 68.7578
            },
            "Yangi Marg\u2018ilon": {
                "lat": 40.4311,
                "lng": 71.7183
            },
            "Manghit": {
                "lat": 42.1167,
                "lng": 60.0667
            },
            "Beshariq": {
                "lat": 40.4358,
                "lng": 70.6103
            },
            "Jarqo\u2018rg\u2018on": {
                "lat": 37.5083,
                "lng": 67.4167
            },
            "Qorako\u2018l Shahri": {
                "lat": 39.5028,
                "lng": 63.8556
            },
            "G\u2018azalkent": {
                "lat": 41.5625,
                "lng": 69.775
            },
            "Nurota": {
                "lat": 40.565,
                "lng": 65.685
            },
            "Karmana": {
                "lat": 40.1422,
                "lng": 65.3625
            },
            "Chelak": {
                "lat": 39.9203,
                "lng": 66.8611
            },
            "Yakkabog\u2018": {
                "lat": 38.98,
                "lng": 66.68
            },
            "Xo\u2018jaobod": {
                "lat": 40.6653,
                "lng": 72.5667
            },
            "Sultonobod": {
                "lat": 40.7644,
                "lng": 72.9764
            },
            "Qorasuv": {
                "lat": 40.7222,
                "lng": 72.8872
            },
            "Krasnogorskiy": {
                "lat": 41.1542,
                "lng": 69.6697
            },
            "Shovot": {
                "lat": 41.65,
                "lng": 60.3
            },
            "Beshkent Shahri": {
                "lat": 38.8167,
                "lng": 65.65
            },
            "Shirin": {
                "lat": 40.2269,
                "lng": 69.1344
            },
            "Hazorasp": {
                "lat": 41.3167,
                "lng": 61.0667
            },
            "Qo\u2019shko\u2019pir": {
                "lat": 41.5333,
                "lng": 60.35
            },
            "Toshloq": {
                "lat": 40.4808,
                "lng": 71.7608
            },
            "Do\u2019stlik Shahri": {
                "lat": 40.5247,
                "lng": 68.0358
            },
            "Baxt": {
                "lat": 40.7139,
                "lng": 68.6933
            },
            "Jomboy Shahri": {
                "lat": 39.6989,
                "lng": 67.0933
            },
            "G\u2018uzor": {
                "lat": 38.6208,
                "lng": 66.2481
            },
            "Taxtako\u2018pir": {
                "lat": 43.0225,
                "lng": 60.2886
            },
            "Yangi Mirishkor": {
                "lat": 38.8514,
                "lng": 65.2778
            },
            "Druzhba": {
                "lat": 41.2222,
                "lng": 61.3067
            },
            "Boysun": {
                "lat": 38.2,
                "lng": 67.2
            },
            "Burbaliq": {
                "lat": 40.3247,
                "lng": 71.3692
            },
            "Do\u2018stobod": {
                "lat": 40.8564,
                "lng": 68.9281
            },
            "Vodil": {
                "lat": 40.1789,
                "lng": 71.7297
            },
            "Sardoba": {
                "lat": 40.5422,
                "lng": 68.4125
            },
            "Ishtixon Shahri": {
                "lat": 39.9664,
                "lng": 66.4861
            },
            "Yaypan": {
                "lat": 40.3758,
                "lng": 70.8156
            },
            "Juma Shahri": {
                "lat": 39.7161,
                "lng": 66.6642
            },
            "Gagarin Shahri": {
                "lat": 40.6619,
                "lng": 68.1722
            },
            "Kimyogarlar": {
                "lat": 39.6672,
                "lng": 66.8461
            },
            "Qumqo\u2018rg\u2018on": {
                "lat": 37.8278,
                "lng": 67.5972
            },
            "Uzun": {
                "lat": 38.3667,
                "lng": 68.0056
            },
            "Romitan Shahri": {
                "lat": 39.9333,
                "lng": 64.3833
            },
            "Sariosiyo": {
                "lat": 38.4133,
                "lng": 67.9572
            },
            "O\u2018nhayot": {
                "lat": 41.0103,
                "lng": 71.7614
            },
            "Shofirkon Shahri": {
                "lat": 40.1167,
                "lng": 64.5
            },
            "Boyovut": {
                "lat": 40.2822,
                "lng": 69.0183
            },
            "Toyloq Qishlog\u2019i": {
                "lat": 39.6014,
                "lng": 67.0908
            },
            "Sariq": {
                "lat": 37.6722,
                "lng": 67.2375
            },
            "Marhamat": {
                "lat": 40.5,
                "lng": 72.3333
            },
            "M\u016dynoq": {
                "lat": 43.7667,
                "lng": 59.0333
            },
            "Nishon Tumani": {
                "lat": 38.6558,
                "lng": 65.6994
            },
            "Charxin": {
                "lat": 39.6967,
                "lng": 66.7686
            },
            "\u016crtaowul": {
                "lat": 41.1908,
                "lng": 69.1494
            },
            "Chiroqchi": {
                "lat": 39.0336,
                "lng": 66.5739
            },
            "Bag\u2018dod": {
                "lat": 40.4606,
                "lng": 71.2214
            },
            "Yozyovon": {
                "lat": 40.6619,
                "lng": 71.7431
            },
            "Loyish Shaharchasi": {
                "lat": 39.8839,
                "lng": 66.7481
            },
            "Galaosiyo Shahri": {
                "lat": 39.85,
                "lng": 64.45
            },
            "Eski Arab": {
                "lat": 40.3686,
                "lng": 71.4231
            },
            "Kegeyli Shahar": {
                "lat": 42.7767,
                "lng": 59.6078
            },
            "Vobkent Shahri": {
                "lat": 40.0333,
                "lng": 64.5167
            },
            "Payariq Shahri": {
                "lat": 39.9892,
                "lng": 66.8458
            },
            "Qorao\u2018zak": {
                "lat": 43.0275,
                "lng": 60.0125
            },
            "G\u2019oliblar Qishlog\u2019i": {
                "lat": 40.4953,
                "lng": 67.8778
            },
            "Kuyganyor": {
                "lat": 40.8611,
                "lng": 72.3111
            },
            "Yangiariq": {
                "lat": 41.3628,
                "lng": 60.6075
            },
            "Gulbahor": {
                "lat": 41.0747,
                "lng": 69.0275
            },
            "Oqqo\u2018rg\u2018on": {
                "lat": 40.8764,
                "lng": 69.0453
            },
            "Qorashina": {
                "lat": 38.3394,
                "lng": 66.5619
            },
            "Ziyodin Shaharchasi": {
                "lat": 40.0342,
                "lng": 65.6547
            },
            "Yangiqo\u2018rg\u2018on": {
                "lat": 41.1872,
                "lng": 71.7333
            },
            "Dang\u2018ara": {
                "lat": 40.5831,
                "lng": 70.91
            },
            "Yangirabot": {
                "lat": 40.0253,
                "lng": 65.9608
            },
            "Eski Yakkabog\u2018": {
                "lat": 38.9314,
                "lng": 66.8389
            },
            "Tallimarjon Shahri": {
                "lat": 38.2967,
                "lng": 65.5533
            },
            "Sharg\u2018un": {
                "lat": 38.46,
                "lng": 67.9669
            },
            "Bog\u2019ot": {
                "lat": 41.35,
                "lng": 60.8167
            },
            "Tinchlik": {
                "lat": 40.4264,
                "lng": 71.4956
            },
            "Shumanay": {
                "lat": 42.6333,
                "lng": 58.9167
            },
            "Usmat Shaharchasi": {
                "lat": 39.7386,
                "lng": 67.6433
            },
            "Dehqonobod": {
                "lat": 40.5314,
                "lng": 69.0342
            },
            "Nuriston": {
                "lat": 38.4892,
                "lng": 65.6464
            },
            "Qanliko\u2018l": {
                "lat": 42.8333,
                "lng": 59.0
            },
            "Uchtepa Qishlog\u2019i": {
                "lat": 40.205,
                "lng": 67.9011
            },
            "Bandixon": {
                "lat": 37.8614,
                "lng": 67.3861
            },
            "Jomasho\u2018y": {
                "lat": 40.8633,
                "lng": 71.4597
            },
            "Shohimardon": {
                "lat": 39.9833,
                "lng": 71.8
            },
            "Xishrov": {
                "lat": 39.6383,
                "lng": 66.8897
            },
            "Oyim": {
                "lat": 40.8242,
                "lng": 72.7414
            },
            "Qiziltepa": {
                "lat": 40.0361,
                "lng": 64.8503
            },
            "Ingichka": {
                "lat": 39.7389,
                "lng": 65.9922
            },
            "Nurobod Shahri": {
                "lat": 39.6086,
                "lng": 66.2867
            },
            "Bo\u2018z": {
                "lat": 40.6833,
                "lng": 71.9167
            },
            "Xalqobod": {
                "lat": 37.4597,
                "lng": 66.9222
            },
            "Qorovulbozor": {
                "lat": 39.5,
                "lng": 64.8
            },
            "Zarbdor Shaharchasi": {
                "lat": 40.0747,
                "lng": 68.1683
            },
            "Dahbed": {
                "lat": 39.7636,
                "lng": 66.9131
            },
            "Qahramon": {
                "lat": 40.305,
                "lng": 68.6017
            },
            "Yangiobod": {
                "lat": 41.1192,
                "lng": 70.0942
            },
            "Angor": {
                "lat": 37.4639,
                "lng": 67.15
            },
            "G\u2018ozg\u2018on": {
                "lat": 40.5944,
                "lng": 65.4964
            },
            "Jondor Shaharchasi": {
                "lat": 39.7333,
                "lng": 64.1833
            },
            "Qorovul": {
                "lat": 41.5569,
                "lng": 60.5914
            }
        }
    },
    "UA": {
        "name": "Ukraine",
        "cities": {
            "Kyiv": {
                "lat": 50.45,
                "lng": 30.5233
            },
            "Kharkiv": {
                "lat": 49.9925,
                "lng": 36.2311
            },
            "Odesa": {
                "lat": 46.4775,
                "lng": 30.7326
            },
            "Dnipro": {
                "lat": 48.4675,
                "lng": 35.04
            },
            "Donetsk": {
                "lat": 48.0028,
                "lng": 37.8053
            },
            "Lviv": {
                "lat": 49.8425,
                "lng": 24.0322
            },
            "Zaporizhzhia": {
                "lat": 47.85,
                "lng": 35.1175
            },
            "Bazal\u2019tove": {
                "lat": 50.6765,
                "lng": 26.16
            },
            "Kryvyi Rih": {
                "lat": 47.91,
                "lng": 33.39
            },
            "Sevastopol": {
                "lat": 44.605,
                "lng": 33.5225
            },
            "Mykolaiv": {
                "lat": 49.5247,
                "lng": 23.9789
            },
            "Mariupol": {
                "lat": 47.0958,
                "lng": 37.5494
            },
            "Luhansk": {
                "lat": 48.5678,
                "lng": 39.3031
            },
            "Vinnytsia": {
                "lat": 49.2333,
                "lng": 28.4833
            },
            "Makiivka": {
                "lat": 48.0556,
                "lng": 37.9611
            },
            "Simferopol": {
                "lat": 44.9519,
                "lng": 34.1022
            },
            "Chernihiv": {
                "lat": 51.4939,
                "lng": 31.2947
            },
            "Poltava": {
                "lat": 49.5894,
                "lng": 34.5514
            },
            "Kherson": {
                "lat": 46.6425,
                "lng": 32.625
            },
            "Khmelnytskyi": {
                "lat": 49.4167,
                "lng": 27.0
            },
            "Cherkasy": {
                "lat": 49.4444,
                "lng": 32.0597
            },
            "Sumy": {
                "lat": 50.9119,
                "lng": 34.8028
            },
            "Chernivtsi": {
                "lat": 48.3,
                "lng": 25.9333
            },
            "Zhytomyr": {
                "lat": 50.25,
                "lng": 28.6667
            },
            "Horlivka": {
                "lat": 48.3,
                "lng": 38.05
            },
            "Rivne": {
                "lat": 50.6192,
                "lng": 26.2519
            },
            "Ivano-Frankivsk": {
                "lat": 48.9228,
                "lng": 24.7106
            },
            "Kropyvnytskyi": {
                "lat": 48.5,
                "lng": 32.2667
            },
            "Kamianske": {
                "lat": 48.5167,
                "lng": 34.6167
            },
            "Ternopil": {
                "lat": 49.5667,
                "lng": 25.6
            },
            "Kremenchuk": {
                "lat": 49.0631,
                "lng": 33.4039
            },
            "Lutsk": {
                "lat": 50.75,
                "lng": 25.3358
            },
            "Bila Tserkva": {
                "lat": 49.7989,
                "lng": 30.1153
            },
            "Sievierodonetsk": {
                "lat": 48.9481,
                "lng": 38.4933
            },
            "Kerch": {
                "lat": 45.3619,
                "lng": 36.4711
            },
            "Melitopol": {
                "lat": 46.8489,
                "lng": 35.3675
            },
            "Kramatorsk": {
                "lat": 48.7392,
                "lng": 37.5839
            },
            "Khrustalnyi": {
                "lat": 48.1214,
                "lng": 38.9453
            },
            "Uzhhorod": {
                "lat": 48.6239,
                "lng": 22.295
            },
            "Sloviansk": {
                "lat": 48.8533,
                "lng": 37.625
            },
            "Brovary": {
                "lat": 50.5111,
                "lng": 30.79
            },
            "Yevpatoriia": {
                "lat": 45.1939,
                "lng": 33.3681
            },
            "Berdiansk": {
                "lat": 46.7556,
                "lng": 36.7889
            },
            "Alchevsk": {
                "lat": 48.4778,
                "lng": 38.7978
            },
            "Nikopol": {
                "lat": 47.5667,
                "lng": 34.4
            },
            "Pavlohrad": {
                "lat": 48.5167,
                "lng": 35.8667
            },
            "Kamianets-Podilskyi": {
                "lat": 48.6833,
                "lng": 26.5833
            },
            "Lysychansk": {
                "lat": 48.9167,
                "lng": 38.4
            },
            "Dovzhansk": {
                "lat": 48.0778,
                "lng": 39.6472
            },
            "Mukacheve": {
                "lat": 48.45,
                "lng": 22.75
            },
            "Konotop": {
                "lat": 51.2167,
                "lng": 33.2
            },
            "Oleksandriia": {
                "lat": 48.6667,
                "lng": 33.1167
            },
            "Uman": {
                "lat": 48.75,
                "lng": 30.2167
            },
            "Krasnyi Luch": {
                "lat": 48.1333,
                "lng": 38.9333
            },
            "Yenakiieve": {
                "lat": 48.2167,
                "lng": 38.2
            },
            "Yalta": {
                "lat": 44.4994,
                "lng": 34.17
            },
            "Shostka": {
                "lat": 51.8667,
                "lng": 33.4833
            },
            "Stakhanov": {
                "lat": 48.5681,
                "lng": 38.6586
            },
            "Drohobych": {
                "lat": 49.35,
                "lng": 23.5
            },
            "Berdychiv": {
                "lat": 49.9,
                "lng": 28.5667
            },
            "Kostiantynivka": {
                "lat": 48.5333,
                "lng": 37.7167
            },
            "Bakhmut": {
                "lat": 48.5947,
                "lng": 38.0008
            },
            "Izmail": {
                "lat": 45.3517,
                "lng": 28.8364
            },
            "Novomoskovsk": {
                "lat": 48.6333,
                "lng": 35.2167
            },
            "Fedosiia": {
                "lat": 45.0342,
                "lng": 35.3792
            },
            "Kovel": {
                "lat": 51.2167,
                "lng": 24.7167
            },
            "Nizhyn": {
                "lat": 51.0381,
                "lng": 31.8861
            },
            "Smila": {
                "lat": 49.2117,
                "lng": 31.8731
            },
            "Chervonohrad": {
                "lat": 50.3867,
                "lng": 24.2289
            },
            "Pokrovsk": {
                "lat": 48.2828,
                "lng": 37.1828
            },
            "Pervomaisk": {
                "lat": 48.6297,
                "lng": 38.5619
            },
            "Boryspil": {
                "lat": 50.35,
                "lng": 30.95
            },
            "Korosten": {
                "lat": 50.95,
                "lng": 28.6333
            },
            "Kolomyia": {
                "lat": 48.5306,
                "lng": 25.0403
            },
            "Rubizhne": {
                "lat": 49.01,
                "lng": 38.3714
            },
            "Khartsyzk": {
                "lat": 48.0428,
                "lng": 38.1425
            },
            "Druzhkivka": {
                "lat": 48.6214,
                "lng": 37.5278
            },
            "Chornomorsk": {
                "lat": 46.3017,
                "lng": 30.6569
            },
            "Bilhorod-Dnistrovskyi": {
                "lat": 46.1833,
                "lng": 30.35
            },
            "Irpin": {
                "lat": 50.5167,
                "lng": 30.25
            },
            "Chystiakove": {
                "lat": 48.0219,
                "lng": 38.6264
            },
            "Stryi": {
                "lat": 49.25,
                "lng": 23.85
            },
            "Novohrad-Volynskyi": {
                "lat": 50.5833,
                "lng": 27.6333
            },
            "Lozova": {
                "lat": 48.8833,
                "lng": 36.3833
            },
            "Pryluky": {
                "lat": 50.5892,
                "lng": 32.3856
            },
            "Antratsyt": {
                "lat": 48.1192,
                "lng": 39.09
            },
            "Enerhodar": {
                "lat": 47.4989,
                "lng": 34.6558
            },
            "Horishni Plavni": {
                "lat": 49.0167,
                "lng": 33.6667
            },
            "Myrnohrad": {
                "lat": 48.2911,
                "lng": 37.2681
            },
            "Novovolynsk": {
                "lat": 50.7333,
                "lng": 24.1667
            },
            "Shakhtarsk": {
                "lat": 48.0469,
                "lng": 38.4686
            },
            "Okhtyrka": {
                "lat": 50.303,
                "lng": 34.892
            },
            "Snizhne": {
                "lat": 48.0283,
                "lng": 38.7656
            },
            "Rovenky": {
                "lat": 48.0711,
                "lng": 39.3428
            },
            "Fastiv": {
                "lat": 50.0833,
                "lng": 29.9167
            },
            "Lubny": {
                "lat": 50.0167,
                "lng": 33.0
            },
            "Svitlovodsk": {
                "lat": 49.0503,
                "lng": 33.2419
            },
            "Nova Kakhovka": {
                "lat": 46.755,
                "lng": 33.375
            },
            "Brianka": {
                "lat": 48.5133,
                "lng": 38.6431
            },
            "Marhanets": {
                "lat": 47.6447,
                "lng": 34.6042
            },
            "Krasnodon": {
                "lat": 48.3,
                "lng": 39.7333
            },
            "Zhovti Vody": {
                "lat": 48.35,
                "lng": 33.5
            },
            "Varash": {
                "lat": 51.3444,
                "lng": 25.8508
            },
            "Romny": {
                "lat": 50.75,
                "lng": 33.4667
            },
            "Myrhorod": {
                "lat": 49.9667,
                "lng": 33.6
            },
            "Podilsk": {
                "lat": 47.7419,
                "lng": 29.535
            },
            "Vyshneve": {
                "lat": 50.3869,
                "lng": 30.3581
            },
            "Yuzhnoukrainsk": {
                "lat": 47.8217,
                "lng": 31.175
            },
            "Dzhankoi": {
                "lat": 45.7086,
                "lng": 34.3933
            },
            "Vasylkiv": {
                "lat": 50.1783,
                "lng": 30.3158
            },
            "Dubno": {
                "lat": 50.3931,
                "lng": 25.735
            },
            "Pokrov": {
                "lat": 47.6533,
                "lng": 34.0842
            },
            "Boryslav": {
                "lat": 49.2892,
                "lng": 23.4189
            },
            "Bucha": {
                "lat": 50.5486,
                "lng": 30.2208
            },
            "Netishyn": {
                "lat": 50.35,
                "lng": 26.6333
            },
            "Holubivske": {
                "lat": 48.6333,
                "lng": 38.65
            },
            "Kakhovka": {
                "lat": 46.7978,
                "lng": 33.475
            },
            "Boyarka": {
                "lat": 50.3292,
                "lng": 30.2886
            },
            "Yasynuvata": {
                "lat": 48.1278,
                "lng": 37.8625
            },
            "Voznesensk": {
                "lat": 47.5667,
                "lng": 31.3
            },
            "Slavuta": {
                "lat": 50.3028,
                "lng": 26.8683
            },
            "Sambir": {
                "lat": 49.5167,
                "lng": 23.2028
            },
            "Toretsk": {
                "lat": 48.3917,
                "lng": 37.8733
            },
            "Starokostiantyniv": {
                "lat": 49.7556,
                "lng": 27.2208
            },
            "Zhmerynka": {
                "lat": 49.05,
                "lng": 28.1
            },
            "Hlukhiv": {
                "lat": 51.6747,
                "lng": 33.9133
            },
            "Obukhiv": {
                "lat": 50.13,
                "lng": 30.6567
            },
            "Chuhuiv": {
                "lat": 49.8356,
                "lng": 36.6864
            },
            "Yuzhne": {
                "lat": 46.63,
                "lng": 31.1
            },
            "Kostopil": {
                "lat": 50.8833,
                "lng": 26.45
            },
            "Novoyavorovskoye": {
                "lat": 49.9311,
                "lng": 23.5731
            },
            "Mohyliv-Podilskyi": {
                "lat": 48.45,
                "lng": 27.7833
            },
            "Vyshhorod": {
                "lat": 50.5833,
                "lng": 30.5
            },
            "Alushta": {
                "lat": 44.6672,
                "lng": 34.3978
            },
            "Synelnykove": {
                "lat": 48.3178,
                "lng": 35.5119
            },
            "Tokmak": {
                "lat": 47.2556,
                "lng": 35.7056
            },
            "Chortkiv": {
                "lat": 49.0075,
                "lng": 25.7906
            },
            "Pervomaiskyi": {
                "lat": 49.3869,
                "lng": 36.2142
            },
            "Balakliia": {
                "lat": 49.4658,
                "lng": 36.8678
            },
            "Novyi Rozdil": {
                "lat": 49.4697,
                "lng": 24.1367
            },
            "Sarny": {
                "lat": 51.3372,
                "lng": 26.6058
            },
            "Truskavets": {
                "lat": 49.2806,
                "lng": 23.505
            },
            "Dobropillia": {
                "lat": 48.4689,
                "lng": 37.0828
            },
            "Khrestivka": {
                "lat": 48.1464,
                "lng": 38.3606
            },
            "Khust": {
                "lat": 48.1814,
                "lng": 23.2978
            },
            "Zolotonosha": {
                "lat": 49.6667,
                "lng": 32.0333
            },
            "Kupiansk": {
                "lat": 49.7167,
                "lng": 37.5833
            },
            "Pershotravensk": {
                "lat": 48.3475,
                "lng": 36.4017
            },
            "Pereyaslav-Khmel\u2019nyts\u2019kyy": {
                "lat": 50.065,
                "lng": 31.445
            },
            "Ternivka": {
                "lat": 48.5167,
                "lng": 36.0
            },
            "Khmilnyk": {
                "lat": 49.5569,
                "lng": 27.9572
            },
            "Bakhchysarai": {
                "lat": 44.7528,
                "lng": 33.8608
            },
            "Kirovsk": {
                "lat": 48.6375,
                "lng": 38.6428
            },
            "Sofiyivs\u2019ka Borshchahivka": {
                "lat": 50.4114,
                "lng": 30.3692
            },
            "Perevalsk": {
                "lat": 48.4333,
                "lng": 38.8167
            },
            "Haisyn": {
                "lat": 48.8103,
                "lng": 29.3842
            },
            "Yany Kapu": {
                "lat": 45.9675,
                "lng": 33.8003
            },
            "Vynohradiv": {
                "lat": 48.1497,
                "lng": 23.025
            },
            "Malyn": {
                "lat": 50.7689,
                "lng": 29.27
            },
            "Slavutych": {
                "lat": 51.5206,
                "lng": 30.7569
            },
            "Oleshky": {
                "lat": 46.6167,
                "lng": 32.7167
            },
            "Zdolbuniv": {
                "lat": 50.5094,
                "lng": 26.2597
            },
            "Armiansk": {
                "lat": 46.1074,
                "lng": 33.6919
            },
            "Saky": {
                "lat": 45.1336,
                "lng": 33.5772
            },
            "Lebedyn": {
                "lat": 50.5831,
                "lng": 34.4822
            },
            "Debaltseve": {
                "lat": 48.3333,
                "lng": 38.4
            },
            "Korostyshiv": {
                "lat": 50.3167,
                "lng": 29.0667
            },
            "Zolochiv": {
                "lat": 49.8075,
                "lng": 24.9031
            },
            "Selydove": {
                "lat": 48.15,
                "lng": 37.3039
            },
            "Kaniv": {
                "lat": 49.75,
                "lng": 31.4667
            },
            "Brody": {
                "lat": 50.0831,
                "lng": 25.1477
            },
            "Pesochin": {
                "lat": 49.9594,
                "lng": 36.1108
            },
            "Berehove": {
                "lat": 48.2056,
                "lng": 22.6472
            },
            "Molodohvardiisk": {
                "lat": 48.3444,
                "lng": 39.6583
            },
            "Znamianka": {
                "lat": 48.7136,
                "lng": 32.6733
            },
            "Dokuchaievsk": {
                "lat": 47.7519,
                "lng": 37.6783
            },
            "Hadyach": {
                "lat": 50.3667,
                "lng": 34.0
            },
            "Nadvirna": {
                "lat": 48.6333,
                "lng": 24.5833
            },
            "Ladyzhyn": {
                "lat": 48.6667,
                "lng": 29.25
            },
            "Lyman": {
                "lat": 48.9853,
                "lng": 37.8111
            },
            "Koziatyn": {
                "lat": 49.7167,
                "lng": 28.8333
            },
            "Krolevets": {
                "lat": 51.55,
                "lng": 33.3833
            },
            "Vilnohirsk": {
                "lat": 48.4781,
                "lng": 34.0281
            },
            "Volnovakha": {
                "lat": 47.6022,
                "lng": 37.4919
            },
            "Merefa": {
                "lat": 49.8167,
                "lng": 36.05
            },
            "Bilohorodka": {
                "lat": 50.3942,
                "lng": 30.2314
            },
            "Sukhodilsk": {
                "lat": 48.35,
                "lng": 39.7167
            },
            "Sokal": {
                "lat": 50.4833,
                "lng": 24.2833
            },
            "Liubotyn": {
                "lat": 49.9483,
                "lng": 35.9294
            },
            "Dolyna": {
                "lat": 48.9706,
                "lng": 24.0111
            },
            "Kremenets": {
                "lat": 50.1081,
                "lng": 25.7275
            },
            "Popasna": {
                "lat": 48.6333,
                "lng": 38.38
            },
            "Stebnyk": {
                "lat": 49.3,
                "lng": 23.5667
            },
            "Polonne": {
                "lat": 50.12,
                "lng": 27.5078
            },
            "Krasnohrad": {
                "lat": 49.3667,
                "lng": 35.45
            },
            "Trostyanets\u2019": {
                "lat": 50.4667,
                "lng": 34.95
            },
            "Yahotyn": {
                "lat": 50.2569,
                "lng": 31.7817
            },
            "Okhmalynka": {
                "lat": 47.5333,
                "lng": 29.3
            },
            "Balta": {
                "lat": 47.94,
                "lng": 29.6219
            },
            "Dolynska": {
                "lat": 48.1111,
                "lng": 32.7647
            },
            "Henichesk": {
                "lat": 46.1667,
                "lng": 34.8
            },
            "Pidhorodne": {
                "lat": 48.575,
                "lng": 35.1022
            },
            "Starobilsk": {
                "lat": 49.2775,
                "lng": 38.9242
            },
            "Kiliia": {
                "lat": 45.45,
                "lng": 29.2667
            },
            "Kivsharivka": {
                "lat": 49.6289,
                "lng": 37.6831
            },
            "Kalynivka": {
                "lat": 49.4472,
                "lng": 28.5231
            },
            "Bakhmach": {
                "lat": 51.1831,
                "lng": 32.8297
            },
            "Kreminna": {
                "lat": 49.05,
                "lng": 38.2167
            },
            "Svatove": {
                "lat": 49.415,
                "lng": 38.155
            },
            "Amvrosiivka": {
                "lat": 47.7833,
                "lng": 38.4833
            },
            "Korsun-Shevchenkivskyi": {
                "lat": 49.4833,
                "lng": 31.2833
            },
            "Krasyliv": {
                "lat": 49.6519,
                "lng": 26.9706
            },
            "Volochysk": {
                "lat": 49.5333,
                "lng": 26.1667
            },
            "Kurakhove": {
                "lat": 47.9833,
                "lng": 37.2667
            },
            "Piatykhatky": {
                "lat": 48.4136,
                "lng": 33.7028
            },
            "Polohy": {
                "lat": 47.4833,
                "lng": 36.25
            },
            "Zuhres": {
                "lat": 48.0167,
                "lng": 38.2667
            },
            "Pelahiivka": {
                "lat": 48.0989,
                "lng": 38.6128
            },
            "Dniprorudne": {
                "lat": 47.3863,
                "lng": 34.997
            },
            "Reni": {
                "lat": 45.4575,
                "lng": 28.2711
            },
            "Rozdilna": {
                "lat": 46.8486,
                "lng": 30.0792
            },
            "Derhachi": {
                "lat": 50.1114,
                "lng": 36.1219
            },
            "Bolhrad": {
                "lat": 45.6672,
                "lng": 28.6128
            },
            "Skadovsk": {
                "lat": 46.1167,
                "lng": 32.9167
            },
            "Berezhany": {
                "lat": 49.4458,
                "lng": 24.9361
            },
            "Zvenyhorodka": {
                "lat": 49.0697,
                "lng": 30.9678
            },
            "Svalyava": {
                "lat": 48.5472,
                "lng": 22.9861
            },
            "Iziaslav": {
                "lat": 50.1167,
                "lng": 26.8
            },
            "Vatutine": {
                "lat": 49.0119,
                "lng": 31.0714
            },
            "Sudak": {
                "lat": 44.8514,
                "lng": 34.9725
            },
            "Shpola": {
                "lat": 48.9997,
                "lng": 31.3922
            },
            "Horodok": {
                "lat": 49.7833,
                "lng": 23.6444
            },
            "Berezan": {
                "lat": 50.3197,
                "lng": 31.47
            },
            "Bilohirsk": {
                "lat": 45.0544,
                "lng": 34.6022
            },
            "Novoukrainka": {
                "lat": 48.3231,
                "lng": 31.5242
            },
            "Yuvileine": {
                "lat": 48.5531,
                "lng": 39.1742
            },
            "Hostomel": {
                "lat": 50.5692,
                "lng": 30.2653
            },
            "Ukrainka": {
                "lat": 50.1531,
                "lng": 30.7435
            },
            "Chornobaivka": {
                "lat": 46.7006,
                "lng": 32.5478
            },
            "Dunaivtsi": {
                "lat": 48.8894,
                "lng": 26.8569
            },
            "Skvyra": {
                "lat": 49.7167,
                "lng": 29.6667
            },
            "Taromske": {
                "lat": 48.4617,
                "lng": 34.7828
            },
            "Vynnyky": {
                "lat": 49.8156,
                "lng": 24.1297
            },
            "Ostroh": {
                "lat": 50.3333,
                "lng": 26.5167
            },
            "Rakhiv": {
                "lat": 48.05,
                "lng": 24.2
            },
            "Verkhnodniprovsk": {
                "lat": 48.6561,
                "lng": 34.3283
            },
            "Ilovaisk": {
                "lat": 47.926,
                "lng": 38.194
            },
            "Bar": {
                "lat": 49.075,
                "lng": 27.6761
            },
            "Ovruch": {
                "lat": 51.3244,
                "lng": 28.8081
            },
            "Chervonopartyzansk": {
                "lat": 48.0833,
                "lng": 39.7833
            },
            "Putyvl": {
                "lat": 51.3167,
                "lng": 33.8667
            },
            "Burshtyn": {
                "lat": 49.2583,
                "lng": 24.6278
            },
            "Novyi Buh": {
                "lat": 47.6833,
                "lng": 32.5
            },
            "Pyryatyn": {
                "lat": 50.25,
                "lng": 32.5333
            },
            "Krasnohorivka": {
                "lat": 48.0067,
                "lng": 37.5128
            },
            "Bohodukhiv": {
                "lat": 50.1617,
                "lng": 35.5256
            },
            "Vuhledar": {
                "lat": 47.7792,
                "lng": 37.2483
            },
            "Ochakiv": {
                "lat": 46.6186,
                "lng": 31.5392
            },
            "Bilozerske": {
                "lat": 48.8667,
                "lng": 37.1333
            },
            "Stanytsia Luhanska": {
                "lat": 48.6706,
                "lng": 39.4722
            },
            "Tulchyn": {
                "lat": 48.6744,
                "lng": 28.8497
            },
            "Zolote": {
                "lat": 48.6928,
                "lng": 38.5194
            },
            "Artsyz": {
                "lat": 45.9944,
                "lng": 29.4322
            },
            "Vil\u2019nyans\u2019k": {
                "lat": 47.9419,
                "lng": 35.4281
            },
            "Sadovoye": {
                "lat": 46.05,
                "lng": 30.3667
            },
            "Novohrodivka": {
                "lat": 48.2,
                "lng": 37.3394
            },
            "Mykolaivka": {
                "lat": 48.8521,
                "lng": 37.774
            },
            "Storozhynets": {
                "lat": 48.1619,
                "lng": 25.72
            },
            "Karlivka": {
                "lat": 49.45,
                "lng": 35.1333
            },
            "Haivoron": {
                "lat": 48.3333,
                "lng": 29.8667
            },
            "Nyzhnia Krynka": {
                "lat": 48.1144,
                "lng": 38.1606
            },
            "Orikhiv": {
                "lat": 47.5667,
                "lng": 35.7833
            },
            "Zhashkiv": {
                "lat": 49.2358,
                "lng": 30.1111
            },
            "Zhovkva": {
                "lat": 50.055,
                "lng": 23.9767
            },
            "Horodyshche": {
                "lat": 49.2925,
                "lng": 31.4581
            },
            "Kivertsi": {
                "lat": 50.8331,
                "lng": 25.4614
            },
            "Radomyshl": {
                "lat": 50.4947,
                "lng": 29.2333
            },
            "Slobozhanske": {
                "lat": 48.5336,
                "lng": 35.0781
            },
            "Bunhe": {
                "lat": 48.2206,
                "lng": 38.2739
            },
            "Solonytsivka": {
                "lat": 49.9869,
                "lng": 36.0619
            },
            "Talne": {
                "lat": 48.8886,
                "lng": 30.7042
            },
            "Hola Prystan": {
                "lat": 46.5167,
                "lng": 32.5167
            },
            "Kaharlyk": {
                "lat": 49.8522,
                "lng": 30.8092
            },
            "Zbarazh": {
                "lat": 49.6667,
                "lng": 25.7778
            },
            "Nosivka": {
                "lat": 50.93,
                "lng": 31.5806
            },
            "Berezne": {
                "lat": 50.9967,
                "lng": 26.7394
            },
            "Terebovlya": {
                "lat": 49.3,
                "lng": 25.6903
            },
            "Borodyanka": {
                "lat": 50.6439,
                "lng": 29.9339
            },
            "Apostolove": {
                "lat": 47.6594,
                "lng": 33.7167
            },
            "Velykodolynske": {
                "lat": 46.3447,
                "lng": 30.5783
            },
            "Tetiiv": {
                "lat": 49.3708,
                "lng": 29.69
            },
            "Kalush": {
                "lat": 49.0442,
                "lng": 24.3597
            },
            "Antonivka": {
                "lat": 46.6808,
                "lng": 32.7358
            },
            "Hulyaypole": {
                "lat": 47.65,
                "lng": 36.2667
            },
            "Yavoriv": {
                "lat": 49.9469,
                "lng": 23.3931
            },
            "Perehinske": {
                "lat": 48.81,
                "lng": 24.19
            },
            "Khorol": {
                "lat": 49.7667,
                "lng": 33.2667
            },
            "Hvardiiske": {
                "lat": 45.1,
                "lng": 34.0
            },
            "Zelenodolsk": {
                "lat": 47.5658,
                "lng": 33.6464
            },
            "Novhorod-Siverskyi": {
                "lat": 51.9972,
                "lng": 33.2667
            },
            "Petrovske": {
                "lat": 48.2833,
                "lng": 38.8833
            },
            "Shchastia": {
                "lat": 48.7333,
                "lng": 39.2333
            },
            "Vasylivka": {
                "lat": 47.4431,
                "lng": 35.2819
            },
            "Prymorskyi": {
                "lat": 45.1167,
                "lng": 35.4806
            },
            "Buchach": {
                "lat": 49.0833,
                "lng": 25.4
            },
            "Rozhyshche": {
                "lat": 50.9131,
                "lng": 25.27
            },
            "Kamin-Kashyrskyi": {
                "lat": 51.62,
                "lng": 24.9653
            },
            "Biliaivka": {
                "lat": 46.4828,
                "lng": 30.2014
            },
            "Chasiv Yar": {
                "lat": 48.5884,
                "lng": 37.8359
            },
            "Bershad": {
                "lat": 48.3728,
                "lng": 29.5325
            },
            "Koriukivka": {
                "lat": 51.7833,
                "lng": 32.25
            },
            "Hnivan": {
                "lat": 49.0911,
                "lng": 28.3486
            },
            "Bashtanka": {
                "lat": 47.4056,
                "lng": 32.4375
            },
            "Kamianka-Dniprovska": {
                "lat": 47.4628,
                "lng": 34.4061
            },
            "Uzyn": {
                "lat": 49.8242,
                "lng": 30.4425
            },
            "Snihurivka": {
                "lat": 47.0736,
                "lng": 32.8
            },
            "Bilovodsk": {
                "lat": 49.2,
                "lng": 39.5756
            },
            "Mykhailivka": {
                "lat": 47.2694,
                "lng": 35.2222
            },
            "Zhdanivka": {
                "lat": 48.1375,
                "lng": 38.2608
            },
            "Horodnia": {
                "lat": 51.8931,
                "lng": 31.5947
            },
            "Myrne": {
                "lat": 47.4322,
                "lng": 37.7297
            },
            "Ukrainsk": {
                "lat": 48.1,
                "lng": 37.3833
            },
            "Baranivka": {
                "lat": 50.3,
                "lng": 27.6667
            },
            "Bilopillya": {
                "lat": 51.1458,
                "lng": 34.3117
            },
            "Kamianka": {
                "lat": 49.0333,
                "lng": 32.1
            },
            "Ovidiopol": {
                "lat": 46.2447,
                "lng": 30.4447
            },
            "Vasylkivka": {
                "lat": 48.2083,
                "lng": 36.0294
            },
            "Mala Vyska": {
                "lat": 48.65,
                "lng": 31.6333
            },
            "Kalmiuske": {
                "lat": 47.6667,
                "lng": 38.0667
            },
            "Nova Odesa": {
                "lat": 47.3097,
                "lng": 31.7792
            },
            "Liubashivka": {
                "lat": 47.8358,
                "lng": 30.2642
            },
            "Lokhvytsya": {
                "lat": 50.3598,
                "lng": 33.2723
            },
            "Yakymivka": {
                "lat": 46.7,
                "lng": 35.1667
            },
            "Myronivka": {
                "lat": 49.65,
                "lng": 30.9833
            },
            "Vakhrusheve": {
                "lat": 48.1667,
                "lng": 38.8333
            },
            "Nemyriv": {
                "lat": 48.9667,
                "lng": 28.85
            },
            "Hirnyk": {
                "lat": 48.05,
                "lng": 37.3667
            },
            "Zimna Voda": {
                "lat": 49.8222,
                "lng": 23.9028
            },
            "Svitlodarsk": {
                "lat": 48.4333,
                "lng": 38.2167
            },
            "Baryshivka": {
                "lat": 50.3539,
                "lng": 31.3167
            },
            "Prymorsk": {
                "lat": 46.7333,
                "lng": 36.35
            },
            "Kurman": {
                "lat": 45.4947,
                "lng": 34.2947
            },
            "Illintsi": {
                "lat": 49.1056,
                "lng": 29.2089
            },
            "Novoazovsk": {
                "lat": 47.1117,
                "lng": 38.0825
            },
            "Chornomorske": {
                "lat": 45.5019,
                "lng": 32.7025
            },
            "Zhydachiv": {
                "lat": 49.385,
                "lng": 24.1444
            },
            "Rokytne": {
                "lat": 49.6667,
                "lng": 30.4833
            },
            "Mena": {
                "lat": 51.5167,
                "lng": 32.2167
            },
            "Bobrynets": {
                "lat": 48.0578,
                "lng": 32.1581
            },
            "Bobrovytsia": {
                "lat": 50.745,
                "lng": 31.3869
            },
            "Nova Vodolaha": {
                "lat": 49.7192,
                "lng": 35.8761
            },
            "Siversk": {
                "lat": 48.8631,
                "lng": 38.0972
            },
            "Tatarbunary": {
                "lat": 45.8403,
                "lng": 29.6125
            },
            "Snovsk": {
                "lat": 51.8203,
                "lng": 31.9508
            },
            "Manevychi": {
                "lat": 51.2914,
                "lng": 25.5331
            },
            "Borshchiv": {
                "lat": 48.8028,
                "lng": 26.0364
            },
            "Khrystynivka": {
                "lat": 48.8,
                "lng": 29.9667
            },
            "Novomyrhorod": {
                "lat": 48.7833,
                "lng": 31.65
            },
            "Ichnia": {
                "lat": 50.85,
                "lng": 32.4
            },
            "Yampil": {
                "lat": 48.24,
                "lng": 28.2783
            },
            "Novotroitske": {
                "lat": 46.35,
                "lng": 34.3333
            },
            "Hrebinka": {
                "lat": 50.1167,
                "lng": 32.4167
            },
            "Lymanka": {
                "lat": 46.3883,
                "lng": 30.6808
            },
            "Soledar": {
                "lat": 48.6953,
                "lng": 38.0675
            },
            "Bolekhiv": {
                "lat": 49.0669,
                "lng": 23.8514
            },
            "Novodnistrovsk": {
                "lat": 48.5778,
                "lng": 27.4414
            },
            "Ivankiv": {
                "lat": 50.9328,
                "lng": 29.9047
            },
            "Kamianka-Buzka": {
                "lat": 50.1,
                "lng": 24.35
            },
            "Tavriisk": {
                "lat": 46.75,
                "lng": 33.4167
            },
            "Krasnoilsk": {
                "lat": 48.0147,
                "lng": 25.5872
            },
            "Sartana": {
                "lat": 47.1667,
                "lng": 37.6833
            },
            "Letychiv": {
                "lat": 49.3833,
                "lng": 27.6167
            },
            "Haspra": {
                "lat": 44.4361,
                "lng": 34.1106
            },
            "Lyuboml\u2019": {
                "lat": 51.2236,
                "lng": 24.0328
            },
            "Borzna": {
                "lat": 51.2531,
                "lng": 32.4292
            },
            "Shyroke": {
                "lat": 47.6844,
                "lng": 33.2728
            },
            "Inkerman": {
                "lat": 44.6,
                "lng": 33.6
            },
            "Tarashcha": {
                "lat": 49.55,
                "lng": 30.5
            },
            "Radyvyliv": {
                "lat": 50.1286,
                "lng": 25.2644
            },
            "Korolevo": {
                "lat": 48.15,
                "lng": 23.1333
            },
            "Teplodar": {
                "lat": 46.5036,
                "lng": 30.3244
            },
            "Vysokyi": {
                "lat": 49.8875,
                "lng": 36.1211
            },
            "Olevsk": {
                "lat": 51.2167,
                "lng": 27.65
            },
            "Novooleksiivka": {
                "lat": 46.2261,
                "lng": 34.6417
            },
            "Ratne": {
                "lat": 51.65,
                "lng": 24.5333
            },
            "Verkhivtseve": {
                "lat": 48.4853,
                "lng": 34.2539
            },
            "Sniatyn": {
                "lat": 48.45,
                "lng": 25.5667
            },
            "Dubove": {
                "lat": 48.1861,
                "lng": 23.8839
            },
            "Novgorodskoye": {
                "lat": 48.3319,
                "lng": 37.8364
            },
            "Pokrovske": {
                "lat": 47.9825,
                "lng": 36.2339
            },
            "Zinkiv": {
                "lat": 50.2103,
                "lng": 34.3581
            },
            "Rodynske": {
                "lat": 48.3531,
                "lng": 37.21
            },
            "Berezivka": {
                "lat": 47.2039,
                "lng": 30.9128
            },
            "Kobeliaky": {
                "lat": 49.1333,
                "lng": 34.2
            },
            "Makariv": {
                "lat": 50.4597,
                "lng": 29.8147
            },
            "Sieverne": {
                "lat": 48.0858,
                "lng": 38.6942
            },
            "Derazhnia": {
                "lat": 49.2667,
                "lng": 27.4333
            },
            "Dubliany": {
                "lat": 49.8961,
                "lng": 24.0925
            },
            "Radekhiv": {
                "lat": 50.2828,
                "lng": 24.6375
            },
            "Vesele": {
                "lat": 47.0158,
                "lng": 34.9122
            },
            "Pereshchepyne": {
                "lat": 49.0167,
                "lng": 35.35
            },
            "Izium": {
                "lat": 49.2128,
                "lng": 37.2569
            },
            "Sofiivka": {
                "lat": 48.2603,
                "lng": 38.1594
            },
            "Mizhhiria": {
                "lat": 48.5286,
                "lng": 23.5019
            },
            "Bilozerka": {
                "lat": 46.6278,
                "lng": 32.4431
            },
            "Zymohiria": {
                "lat": 48.5819,
                "lng": 38.9319
            },
            "Velyka Dymerka": {
                "lat": 50.5928,
                "lng": 30.9103
            },
            "Chaplynka": {
                "lat": 46.3644,
                "lng": 33.5331
            },
            "Cherniakhiv": {
                "lat": 50.455,
                "lng": 28.6653
            },
            "Syurte": {
                "lat": 48.5033,
                "lng": 22.2319
            },
            "Nyzhnohirskyi": {
                "lat": 45.4436,
                "lng": 34.7406
            },
            "Bezliudivka": {
                "lat": 49.8692,
                "lng": 36.2697
            },
            "Dubrovytsya": {
                "lat": 51.5667,
                "lng": 26.5667
            },
            "Pokotylivka": {
                "lat": 49.9114,
                "lng": 36.1786
            },
            "Novopskov": {
                "lat": 49.5383,
                "lng": 39.1019
            },
            "Dymka": {
                "lat": 48.0972,
                "lng": 25.9753
            },
            "Pustomyty": {
                "lat": 49.7139,
                "lng": 23.9108
            },
            "Tysmenytsia": {
                "lat": 48.9008,
                "lng": 24.8492
            },
            "Teplohirsk": {
                "lat": 48.5967,
                "lng": 38.5828
            },
            "Khotyn": {
                "lat": 48.5069,
                "lng": 26.4903
            },
            "Velykyi Bychkiv": {
                "lat": 47.9667,
                "lng": 24.015
            },
            "Smoline": {
                "lat": 48.6017,
                "lng": 31.2947
            },
            "Reshetylivka": {
                "lat": 49.5667,
                "lng": 34.0667
            },
            "Hlyboka": {
                "lat": 48.0833,
                "lng": 25.9333
            },
            "Kalanchak": {
                "lat": 46.2522,
                "lng": 33.2906
            },
            "Pohrebyshche": {
                "lat": 49.4833,
                "lng": 29.2667
            },
            "Obukhivka": {
                "lat": 48.5433,
                "lng": 34.8589
            },
            "Irshava": {
                "lat": 48.3,
                "lng": 23.05
            },
            "Stryzhavka": {
                "lat": 49.3103,
                "lng": 28.4808
            },
            "Bilenke": {
                "lat": 48.7664,
                "lng": 37.6314
            },
            "Hurzuf": {
                "lat": 44.5528,
                "lng": 34.2875
            },
            "Horodenka": {
                "lat": 48.6675,
                "lng": 25.5003
            },
            "Hirske": {
                "lat": 48.7519,
                "lng": 38.495
            },
            "Marinka": {
                "lat": 47.9419,
                "lng": 37.5036
            },
            "Velyki Luchky": {
                "lat": 48.4167,
                "lng": 22.5667
            },
            "Pivnichne": {
                "lat": 48.3886,
                "lng": 37.9067
            },
            "Zalishchyky": {
                "lat": 48.65,
                "lng": 25.7333
            },
            "Novyi Svit": {
                "lat": 47.8167,
                "lng": 38.0333
            },
            "Pomichna": {
                "lat": 48.25,
                "lng": 31.4167
            },
            "Kozova": {
                "lat": 49.4319,
                "lng": 25.1594
            },
            "Uspenka": {
                "lat": 48.3939,
                "lng": 39.1536
            },
            "Hlobyne": {
                "lat": 49.3903,
                "lng": 33.2503
            },
            "Yasenivskyi": {
                "lat": 48.1606,
                "lng": 39.2175
            },
            "Monastyryshche": {
                "lat": 48.99,
                "lng": 29.8011
            },
            "Horokhiv": {
                "lat": 50.4994,
                "lng": 24.765
            },
            "Tiachiv": {
                "lat": 48.0114,
                "lng": 23.5722
            },
            "Tlumach": {
                "lat": 48.8667,
                "lng": 25.0
            },
            "Ilarionove": {
                "lat": 48.4092,
                "lng": 35.2753
            },
            "Hlevakha": {
                "lat": 50.2622,
                "lng": 30.2894
            },
            "Kryzhopil": {
                "lat": 48.3842,
                "lng": 28.8625
            },
            "Volodymyrets": {
                "lat": 51.4208,
                "lng": 26.145
            },
            "Barvynkove": {
                "lat": 48.9,
                "lng": 37.0167
            },
            "Chyhyryn": {
                "lat": 49.0772,
                "lng": 32.6492
            },
            "Sokyriany": {
                "lat": 48.45,
                "lng": 27.3667
            },
            "Solotvyno": {
                "lat": 47.9556,
                "lng": 23.8711
            },
            "Chop": {
                "lat": 48.4333,
                "lng": 22.2
            },
            "Rava-Rus\u2019ka": {
                "lat": 50.225,
                "lng": 23.6242
            },
            "Yasinia": {
                "lat": 48.2728,
                "lng": 24.3747
            },
            "Liubymivka": {
                "lat": 48.0397,
                "lng": 39.44
            },
            "Masandra": {
                "lat": 44.5167,
                "lng": 34.1833
            },
            "Bushtyno": {
                "lat": 48.0536,
                "lng": 23.4767
            },
            "Nerubaiske": {
                "lat": 46.5467,
                "lng": 30.6306
            },
            "Dachne": {
                "lat": 46.5775,
                "lng": 30.5489
            },
            "Kosiv": {
                "lat": 48.315,
                "lng": 25.0953
            },
            "Biloziria": {
                "lat": 49.3053,
                "lng": 31.8922
            }
        }
    },
    "KP": {
        "name": "Korea, North",
        "cities": {
            "Pyongyang": {
                "lat": 39.0167,
                "lng": 125.7475
            },
            "Hamh\u016dng": {
                "lat": 39.9167,
                "lng": 127.5333
            },
            "Nampo": {
                "lat": 38.7333,
                "lng": 125.4
            },
            "Ch\u2019\u014fngjin": {
                "lat": 41.7833,
                "lng": 129.7667
            },
            "Sunch\u2019\u014fn": {
                "lat": 39.4167,
                "lng": 125.9333
            },
            "W\u014fnsan": {
                "lat": 39.1475,
                "lng": 127.4461
            },
            "Sin\u016diju": {
                "lat": 40.1,
                "lng": 124.4
            },
            "Tanch\u2019\u014fn": {
                "lat": 40.458,
                "lng": 128.911
            },
            "Kaech\u2019\u014fn": {
                "lat": 39.6986,
                "lng": 125.9061
            },
            "Sariw\u014fn": {
                "lat": 38.5064,
                "lng": 125.7597
            },
            "Kanggye": {
                "lat": 40.9667,
                "lng": 126.6
            },
            "Haeju": {
                "lat": 38.0333,
                "lng": 125.7167
            },
            "Anju": {
                "lat": 39.62,
                "lng": 125.66
            },
            "P\u2019y\u014fngs\u014fng-si": {
                "lat": 39.2605,
                "lng": 125.8618
            },
            "P\u2019y\u014fng-dong": {
                "lat": 39.1919,
                "lng": 125.8178
            },
            "Rajin": {
                "lat": 42.3444,
                "lng": 130.3844
            },
            "Kus\u014fng": {
                "lat": 39.9667,
                "lng": 125.1667
            },
            "Hyesan": {
                "lat": 41.4,
                "lng": 128.1833
            },
            "Kaes\u014fng": {
                "lat": 37.9667,
                "lng": 126.55
            },
            "Ch\u014fngju": {
                "lat": 39.65,
                "lng": 125.3333
            },
            "Chaed\u014fk": {
                "lat": 40.7988,
                "lng": 129.3602
            },
            "H\u016dich\u2019\u014fn": {
                "lat": 40.1707,
                "lng": 126.2761
            },
            "Hoery\u014fng": {
                "lat": 42.4333,
                "lng": 129.75
            },
            "Sinpo": {
                "lat": 40.0347,
                "lng": 128.1856
            },
            "Songnim": {
                "lat": 38.7542,
                "lng": 125.645
            },
            "Munch\u2019\u014fn": {
                "lat": 39.259,
                "lng": 127.356
            },
            "Manpo": {
                "lat": 41.157,
                "lng": 126.29
            },
            "Namsan": {
                "lat": 42.2275,
                "lng": 129.2541
            },
            "Ongjang": {
                "lat": 37.9371,
                "lng": 125.3571
            },
            "\u014ej\u014fk-tong": {
                "lat": 40.2167,
                "lng": 124.5333
            },
            "Changy\u014fn": {
                "lat": 38.2517,
                "lng": 125.1021
            },
            "Sejiy\u014fn": {
                "lat": 41.8,
                "lng": 128.3167
            },
            "Ch\u2019osan-\u016dp": {
                "lat": 40.8255,
                "lng": 125.8008
            },
            "P\u2019ungsan": {
                "lat": 40.8175,
                "lng": 128.1553
            },
            "Taedong": {
                "lat": 40.6171,
                "lng": 125.4501
            },
            "Sariw\u014fn-si": {
                "lat": 38.5242,
                "lng": 125.7547
            },
            "Munha-dong": {
                "lat": 39.3813,
                "lng": 127.2517
            },
            "Sil-li": {
                "lat": 39.488,
                "lng": 125.464
            }
        }
    },
    "IT": {
        "name": "Italy",
        "cities": {
            "Rome": {
                "lat": 41.8933,
                "lng": 12.4828
            },
            "Milan": {
                "lat": 45.4669,
                "lng": 9.19
            },
            "Naples": {
                "lat": 40.8333,
                "lng": 14.25
            },
            "Turin": {
                "lat": 45.0792,
                "lng": 7.6761
            },
            "Palermo": {
                "lat": 38.1111,
                "lng": 13.3517
            },
            "Genoa": {
                "lat": 44.4111,
                "lng": 8.9328
            },
            "Bologna": {
                "lat": 44.4939,
                "lng": 11.3428
            },
            "Florence": {
                "lat": 43.7714,
                "lng": 11.2542
            },
            "Bari": {
                "lat": 41.1253,
                "lng": 16.8667
            },
            "Catania": {
                "lat": 37.5,
                "lng": 15.0903
            },
            "Verona": {
                "lat": 45.4386,
                "lng": 10.9928
            },
            "Venice": {
                "lat": 45.4375,
                "lng": 12.3358
            },
            "Messina": {
                "lat": 38.1936,
                "lng": 15.5542
            },
            "Padova": {
                "lat": 45.4167,
                "lng": 11.8667
            },
            "Cosenza": {
                "lat": 39.3,
                "lng": 16.25
            },
            "Trieste": {
                "lat": 45.6503,
                "lng": 13.7703
            },
            "Parma": {
                "lat": 44.8015,
                "lng": 10.328
            },
            "Brescia": {
                "lat": 45.5417,
                "lng": 10.2167
            },
            "Prato": {
                "lat": 43.88,
                "lng": 11.0983
            },
            "Taranto": {
                "lat": 40.4711,
                "lng": 17.2431
            },
            "Modena": {
                "lat": 44.6469,
                "lng": 10.9256
            },
            "Reggio di Calabria": {
                "lat": 38.1114,
                "lng": 15.6619
            },
            "Reggio Emilia": {
                "lat": 44.7,
                "lng": 10.6333
            },
            "Perugia": {
                "lat": 43.1122,
                "lng": 12.3889
            },
            "Ravenna": {
                "lat": 44.4161,
                "lng": 12.2017
            },
            "Livorno": {
                "lat": 43.5519,
                "lng": 10.3083
            },
            "Rimini": {
                "lat": 44.0594,
                "lng": 12.5683
            },
            "Cagliari": {
                "lat": 39.2278,
                "lng": 9.1111
            },
            "Foggia": {
                "lat": 41.4642,
                "lng": 15.5461
            },
            "Ferrara": {
                "lat": 44.8333,
                "lng": 11.6167
            },
            "Latina": {
                "lat": 41.4672,
                "lng": 12.9036
            },
            "Salerno": {
                "lat": 40.6806,
                "lng": 14.7594
            },
            "Giugliano in Campania": {
                "lat": 40.9333,
                "lng": 14.2
            },
            "Monza": {
                "lat": 45.5836,
                "lng": 9.2736
            },
            "Sassari": {
                "lat": 40.725,
                "lng": 8.5586
            },
            "Bergamo": {
                "lat": 45.695,
                "lng": 9.67
            },
            "Pescara": {
                "lat": 42.4639,
                "lng": 14.2142
            },
            "Trento": {
                "lat": 46.0667,
                "lng": 11.1167
            },
            "Forl\u00ec": {
                "lat": 44.2333,
                "lng": 12.05
            },
            "Siracusa": {
                "lat": 37.0692,
                "lng": 15.2875
            },
            "Vicenza": {
                "lat": 45.55,
                "lng": 11.55
            },
            "Terni": {
                "lat": 42.5619,
                "lng": 12.6414
            },
            "Bolzano": {
                "lat": 46.5,
                "lng": 11.35
            },
            "Roncaglia": {
                "lat": 45.0478,
                "lng": 9.7006
            },
            "Piacenza": {
                "lat": 45.0478,
                "lng": 9.7006
            },
            "Novara": {
                "lat": 45.45,
                "lng": 8.62
            },
            "Ancona": {
                "lat": 43.6169,
                "lng": 13.5167
            },
            "Udine": {
                "lat": 46.0667,
                "lng": 13.2333
            },
            "Andria": {
                "lat": 41.2167,
                "lng": 16.3
            },
            "Arezzo": {
                "lat": 43.4633,
                "lng": 11.8783
            },
            "Cesena": {
                "lat": 44.1389,
                "lng": 12.2444
            },
            "Pesaro": {
                "lat": 43.9167,
                "lng": 12.9
            },
            "Lecce": {
                "lat": 40.35,
                "lng": 18.1667
            },
            "Barletta": {
                "lat": 41.3167,
                "lng": 16.2833
            },
            "La Spezia": {
                "lat": 44.1,
                "lng": 9.8167
            },
            "Alessandria": {
                "lat": 44.9167,
                "lng": 8.6167
            },
            "Mestre": {
                "lat": 45.4906,
                "lng": 12.2381
            },
            "Pistoia": {
                "lat": 43.9333,
                "lng": 10.9167
            },
            "Lucca": {
                "lat": 43.8417,
                "lng": 10.5028
            },
            "Pisa": {
                "lat": 43.7167,
                "lng": 10.4
            },
            "Catanzaro": {
                "lat": 38.9,
                "lng": 16.6
            },
            "Treviso": {
                "lat": 45.6667,
                "lng": 12.25
            },
            "Como": {
                "lat": 45.8167,
                "lng": 9.0833
            },
            "Busto Arsizio": {
                "lat": 45.6119,
                "lng": 8.85
            },
            "Brindisi": {
                "lat": 40.6333,
                "lng": 17.9333
            },
            "Fiumicino": {
                "lat": 41.7667,
                "lng": 12.2333
            },
            "Grosseto": {
                "lat": 42.7667,
                "lng": 11.1
            },
            "Torre del Greco": {
                "lat": 40.7833,
                "lng": 14.3667
            },
            "Marsala": {
                "lat": 37.7981,
                "lng": 12.4342
            },
            "Sesto San Giovanni": {
                "lat": 45.5333,
                "lng": 9.2333
            },
            "Varese": {
                "lat": 45.8167,
                "lng": 8.8333
            },
            "Pozzuoli": {
                "lat": 40.8444,
                "lng": 14.0933
            },
            "Cinisello Balsamo": {
                "lat": 45.55,
                "lng": 9.2167
            },
            "Aprilia": {
                "lat": 41.5833,
                "lng": 12.65
            },
            "Casoria": {
                "lat": 40.9,
                "lng": 14.3
            },
            "Asti": {
                "lat": 44.9,
                "lng": 8.2069
            },
            "Ragusa": {
                "lat": 36.9333,
                "lng": 14.75
            },
            "Caserta": {
                "lat": 41.0667,
                "lng": 14.3333
            },
            "Carpi": {
                "lat": 44.7833,
                "lng": 10.8833
            },
            "Gela": {
                "lat": 37.0667,
                "lng": 14.25
            },
            "Cremona": {
                "lat": 45.1333,
                "lng": 10.0247
            },
            "Pavia": {
                "lat": 45.185,
                "lng": 9.1542
            },
            "Altamura": {
                "lat": 40.8167,
                "lng": 16.55
            },
            "L\u2019Aquila": {
                "lat": 42.35,
                "lng": 13.4
            },
            "Imola": {
                "lat": 44.3531,
                "lng": 11.7147
            },
            "Quartu Sant\u2019Elena": {
                "lat": 39.2333,
                "lng": 9.1833
            },
            "Sant\u2019Eufemia Lamezia": {
                "lat": 38.9667,
                "lng": 16.3
            },
            "Acilia": {
                "lat": 41.7833,
                "lng": 12.3667
            },
            "Calimera": {
                "lat": 40.25,
                "lng": 18.2833
            },
            "Massa": {
                "lat": 44.0333,
                "lng": 10.1333
            },
            "Viterbo": {
                "lat": 42.4167,
                "lng": 12.1
            },
            "Potenza": {
                "lat": 40.6333,
                "lng": 15.8
            },
            "Pomezia": {
                "lat": 41.6833,
                "lng": 12.5
            },
            "Vittoria": {
                "lat": 36.95,
                "lng": 14.5333
            },
            "Marina di Carrara": {
                "lat": 44.0792,
                "lng": 10.1
            },
            "Castellammare di Stabia": {
                "lat": 40.6947,
                "lng": 14.4803
            },
            "Vigevano": {
                "lat": 45.3167,
                "lng": 8.8667
            },
            "Afragola": {
                "lat": 40.9167,
                "lng": 14.3167
            },
            "Olbia": {
                "lat": 40.9167,
                "lng": 9.5
            },
            "Viareggio": {
                "lat": 43.8667,
                "lng": 10.2333
            },
            "Legnano": {
                "lat": 45.5781,
                "lng": 8.9183
            },
            "Carrara": {
                "lat": 44.0792,
                "lng": 10.1
            },
            "Fano": {
                "lat": 43.8333,
                "lng": 13.0167
            },
            "Matera": {
                "lat": 40.6667,
                "lng": 16.6
            },
            "Anzio": {
                "lat": 41.4472,
                "lng": 12.6283
            },
            "Faenza": {
                "lat": 44.2856,
                "lng": 11.8833
            },
            "Caltanissetta": {
                "lat": 37.4903,
                "lng": 14.0625
            },
            "Crotone": {
                "lat": 39.0833,
                "lng": 17.1167
            },
            "Benevento": {
                "lat": 41.1333,
                "lng": 14.7833
            },
            "Acerra": {
                "lat": 40.95,
                "lng": 14.3667
            },
            "Savona": {
                "lat": 44.3081,
                "lng": 8.4811
            },
            "Marano di Napoli": {
                "lat": 40.9,
                "lng": 14.1833
            },
            "Molfetta": {
                "lat": 41.2,
                "lng": 16.6
            },
            "Cerignola": {
                "lat": 41.2667,
                "lng": 15.9
            },
            "Moncalieri": {
                "lat": 45.0,
                "lng": 7.6833
            },
            "Cuneo": {
                "lat": 44.3894,
                "lng": 7.5478
            },
            "Trapani": {
                "lat": 38.0175,
                "lng": 12.515
            },
            "Agrigento": {
                "lat": 37.3167,
                "lng": 13.5833
            },
            "Foligno": {
                "lat": 42.95,
                "lng": 12.7
            },
            "Trani": {
                "lat": 41.2667,
                "lng": 16.4167
            },
            "Tivoli": {
                "lat": 41.9597,
                "lng": 12.7981
            },
            "Manfredonia": {
                "lat": 41.6333,
                "lng": 15.9167
            },
            "Bisceglie": {
                "lat": 41.2431,
                "lng": 16.5053
            },
            "Modica": {
                "lat": 36.85,
                "lng": 14.7667
            },
            "Montesilvano": {
                "lat": 42.5142,
                "lng": 14.1494
            },
            "Bitonto": {
                "lat": 41.1167,
                "lng": 16.6833
            },
            "Bagheria": {
                "lat": 38.0803,
                "lng": 13.5086
            },
            "Siena": {
                "lat": 43.3186,
                "lng": 11.3306
            },
            "Gallarate": {
                "lat": 45.6667,
                "lng": 8.8
            },
            "San Remo": {
                "lat": 43.8175,
                "lng": 7.775
            },
            "Velletri": {
                "lat": 41.6867,
                "lng": 12.7775
            },
            "Avellino": {
                "lat": 40.9167,
                "lng": 14.7889
            },
            "Portici": {
                "lat": 40.8197,
                "lng": 14.3411
            },
            "Pordenone": {
                "lat": 45.95,
                "lng": 12.65
            },
            "Civitavecchia": {
                "lat": 42.1,
                "lng": 11.8
            },
            "Teramo": {
                "lat": 42.6591,
                "lng": 13.7022
            },
            "Cava de\u2019 Tirreni": {
                "lat": 40.7,
                "lng": 14.7
            },
            "Acireale": {
                "lat": 37.6167,
                "lng": 15.1667
            },
            "Rho": {
                "lat": 45.5311,
                "lng": 9.0406
            },
            "Ercolano": {
                "lat": 40.8,
                "lng": 14.35
            },
            "Mazara del Vallo": {
                "lat": 37.65,
                "lng": 12.5833
            },
            "Rovigo": {
                "lat": 45.0703,
                "lng": 11.7906
            },
            "Aversa": {
                "lat": 40.9667,
                "lng": 14.2
            },
            "Battipaglia": {
                "lat": 40.6167,
                "lng": 14.9833
            },
            "Scandicci": {
                "lat": 43.7544,
                "lng": 11.1894
            },
            "San Severo": {
                "lat": 41.6833,
                "lng": 15.3833
            },
            "Ardea": {
                "lat": 41.6167,
                "lng": 12.55
            },
            "Misterbianco": {
                "lat": 37.5167,
                "lng": 15.0
            },
            "Empoli": {
                "lat": 43.7167,
                "lng": 10.95
            },
            "Sesto Fiorentino": {
                "lat": 43.8319,
                "lng": 11.1994
            },
            "Mantova": {
                "lat": 45.1564,
                "lng": 10.7917
            },
            "Chieti": {
                "lat": 42.35,
                "lng": 14.1667
            },
            "Collegno": {
                "lat": 45.0833,
                "lng": 7.5833
            },
            "Scafati": {
                "lat": 40.7536,
                "lng": 14.5253
            },
            "Nettuno": {
                "lat": 41.4575,
                "lng": 12.6611
            },
            "Monopoli": {
                "lat": 40.95,
                "lng": 17.3
            },
            "Chioggia": {
                "lat": 45.2196,
                "lng": 12.2789
            },
            "Campi Bisenzio": {
                "lat": 43.8167,
                "lng": 11.1333
            },
            "Rivoli": {
                "lat": 45.0667,
                "lng": 7.5167
            },
            "Paderno Dugnano": {
                "lat": 45.5667,
                "lng": 9.1667
            },
            "Campobasso": {
                "lat": 41.5667,
                "lng": 14.6667
            },
            "Corato": {
                "lat": 41.15,
                "lng": 16.4
            },
            "Casalnuovo di Napoli": {
                "lat": 40.9167,
                "lng": 14.35
            },
            "San Benedetto del Tronto": {
                "lat": 42.95,
                "lng": 13.8833
            },
            "Martina Franca": {
                "lat": 40.6333,
                "lng": 17.0333
            },
            "Lecco": {
                "lat": 45.85,
                "lng": 9.4
            },
            "Cologno Monzese": {
                "lat": 45.5286,
                "lng": 9.2783
            },
            "Lissone": {
                "lat": 45.6167,
                "lng": 9.25
            },
            "Marino": {
                "lat": 41.7667,
                "lng": 12.6667
            },
            "Capannori": {
                "lat": 43.85,
                "lng": 10.5667
            },
            "Nichelino": {
                "lat": 45.0,
                "lng": 7.65
            },
            "Settimo Torinese": {
                "lat": 45.1333,
                "lng": 7.7667
            },
            "Ascoli Piceno": {
                "lat": 42.85,
                "lng": 13.5833
            },
            "Rieti": {
                "lat": 42.4,
                "lng": 12.8667
            },
            "Vercelli": {
                "lat": 45.3256,
                "lng": 8.4231
            },
            "Paterno": {
                "lat": 37.5667,
                "lng": 14.9
            },
            "Seregno": {
                "lat": 45.65,
                "lng": 9.2
            },
            "Cascina": {
                "lat": 43.6833,
                "lng": 10.55
            },
            "Terracina": {
                "lat": 41.2833,
                "lng": 13.25
            },
            "Lodi": {
                "lat": 45.3167,
                "lng": 9.5
            },
            "Alcamo": {
                "lat": 37.9778,
                "lng": 12.9639
            },
            "Nocera Inferiore": {
                "lat": 40.75,
                "lng": 14.6333
            },
            "Senigallia": {
                "lat": 43.7167,
                "lng": 13.2167
            },
            "Frosinone": {
                "lat": 41.6333,
                "lng": 13.35
            },
            "Gravina in Puglia": {
                "lat": 40.8167,
                "lng": 16.4167
            },
            "Biella": {
                "lat": 45.5667,
                "lng": 8.0667
            },
            "San Giorgio a Cremano": {
                "lat": 40.8333,
                "lng": 14.3333
            },
            "Bassano del Grappa": {
                "lat": 45.7667,
                "lng": 11.7342
            },
            "Alghero": {
                "lat": 40.56,
                "lng": 8.315
            },
            "Imperia": {
                "lat": 43.8833,
                "lng": 8.0333
            },
            "Civitanova Marche": {
                "lat": 43.3,
                "lng": 13.7333
            },
            "San Don\u00e0 di Piave": {
                "lat": 45.6333,
                "lng": 12.5667
            },
            "Desio": {
                "lat": 45.6167,
                "lng": 9.2167
            },
            "Rozzano": {
                "lat": 45.3833,
                "lng": 9.15
            },
            "Merano": {
                "lat": 46.6667,
                "lng": 11.1667
            },
            "Monterotondo": {
                "lat": 42.05,
                "lng": 12.6167
            },
            "Sassuolo": {
                "lat": 44.55,
                "lng": 10.7833
            },
            "Vasto": {
                "lat": 42.1167,
                "lng": 14.7069
            },
            "Avezzano": {
                "lat": 42.0411,
                "lng": 13.4397
            },
            "Ladispoli": {
                "lat": 41.95,
                "lng": 12.0833
            },
            "Macerata": {
                "lat": 43.3003,
                "lng": 13.4533
            },
            "Corigliano Calabro": {
                "lat": 39.6,
                "lng": 16.5167
            },
            "Torre Annunziata": {
                "lat": 40.75,
                "lng": 14.45
            },
            "Nicastro": {
                "lat": 38.9833,
                "lng": 16.3167
            },
            "Barcellona-Pozzo di Gotto": {
                "lat": 38.15,
                "lng": 15.2167
            },
            "Rovereto": {
                "lat": 45.8833,
                "lng": 11.05
            },
            "Carini": {
                "lat": 38.1333,
                "lng": 13.1833
            },
            "Albano Laziale": {
                "lat": 41.7333,
                "lng": 12.6667
            },
            "Cant\u00f9": {
                "lat": 45.7333,
                "lng": 9.1333
            },
            "Pomigliano d\u2019Arco": {
                "lat": 40.9167,
                "lng": 14.4
            },
            "Fondi": {
                "lat": 41.35,
                "lng": 13.4167
            },
            "San Giuliano Milanese": {
                "lat": 45.4,
                "lng": 9.2833
            },
            "Cesano Maderno": {
                "lat": 45.6281,
                "lng": 9.1461
            },
            "Iesi": {
                "lat": 43.5236,
                "lng": 13.2392
            },
            "Sciacca": {
                "lat": 37.5092,
                "lng": 13.0889
            },
            "Fasano": {
                "lat": 40.8333,
                "lng": 17.3667
            },
            "Monreale": {
                "lat": 38.0817,
                "lng": 13.2889
            },
            "Voghera": {
                "lat": 44.9925,
                "lng": 9.0092
            },
            "Ciampino": {
                "lat": 41.8,
                "lng": 12.6
            },
            "Schio": {
                "lat": 45.7111,
                "lng": 11.3556
            },
            "Saronno": {
                "lat": 45.6333,
                "lng": 9.05
            },
            "Marcianise": {
                "lat": 41.0333,
                "lng": 14.3
            },
            "Azzano": {
                "lat": 42.7565,
                "lng": 12.6855
            },
            "Citt\u00e0 di Castello": {
                "lat": 43.4608,
                "lng": 12.2439
            },
            "Cerveteri": {
                "lat": 42.0075,
                "lng": 12.105
            },
            "Mira": {
                "lat": 45.4333,
                "lng": 12.1333
            },
            "Eboli": {
                "lat": 40.6169,
                "lng": 15.0564
            },
            "Formia": {
                "lat": 41.2667,
                "lng": 13.6167
            },
            "Segrate": {
                "lat": 45.4833,
                "lng": 9.3
            },
            "Grugliasco": {
                "lat": 45.0667,
                "lng": 7.5833
            },
            "Maddaloni": {
                "lat": 41.0333,
                "lng": 14.3833
            },
            "Rossano": {
                "lat": 39.5667,
                "lng": 16.6333
            },
            "Rende": {
                "lat": 39.3333,
                "lng": 16.1833
            },
            "Spoleto": {
                "lat": 42.7565,
                "lng": 12.6855
            },
            "Melito di Napoli": {
                "lat": 40.9167,
                "lng": 14.2333
            },
            "Modugno": {
                "lat": 41.0833,
                "lng": 16.7833
            },
            "Bollate": {
                "lat": 45.55,
                "lng": 9.1167
            },
            "Cisterna di Latina": {
                "lat": 41.5908,
                "lng": 12.8283
            },
            "Pioltello": {
                "lat": 45.5,
                "lng": 9.3333
            },
            "Chieri": {
                "lat": 45.0125,
                "lng": 7.825
            },
            "Caivano": {
                "lat": 40.95,
                "lng": 14.3
            },
            "Fermo": {
                "lat": 43.1608,
                "lng": 13.7158
            },
            "Caltagirone": {
                "lat": 37.2375,
                "lng": 14.5125
            },
            "Belluno": {
                "lat": 46.1403,
                "lng": 12.2167
            },
            "Pinerolo": {
                "lat": 44.8833,
                "lng": 7.3333
            },
            "Casalecchio di Reno": {
                "lat": 44.4833,
                "lng": 11.2833
            },
            "Cento": {
                "lat": 44.7333,
                "lng": 11.2833
            },
            "Cassino": {
                "lat": 41.5,
                "lng": 13.8333
            },
            "Brugherio": {
                "lat": 45.55,
                "lng": 9.3
            },
            "Francavilla Fontana": {
                "lat": 40.5333,
                "lng": 17.5833
            },
            "Cernusco sul Naviglio": {
                "lat": 45.5167,
                "lng": 9.3333
            },
            "Limbiate": {
                "lat": 45.6,
                "lng": 9.1333
            },
            "Osimo": {
                "lat": 43.4833,
                "lng": 13.4833
            },
            "Augusta": {
                "lat": 37.25,
                "lng": 15.2167
            },
            "Mugnano di Napoli": {
                "lat": 40.9167,
                "lng": 14.2
            },
            "Nola": {
                "lat": 40.9261,
                "lng": 14.5275
            },
            "Formigine": {
                "lat": 44.6072,
                "lng": 10.9333
            },
            "Canicatt\u00ec": {
                "lat": 37.3667,
                "lng": 13.85
            },
            "Riccione Marina": {
                "lat": 44.0,
                "lng": 12.65
            },
            "Corsico": {
                "lat": 45.4333,
                "lng": 9.1167
            },
            "Conegliano": {
                "lat": 45.8868,
                "lng": 12.2974
            },
            "Licata": {
                "lat": 37.1083,
                "lng": 13.9469
            },
            "Pagani": {
                "lat": 40.7417,
                "lng": 14.6167
            },
            "Angri": {
                "lat": 40.7333,
                "lng": 14.5667
            },
            "Lanciano": {
                "lat": 42.2333,
                "lng": 14.3833
            },
            "Adrano": {
                "lat": 37.6667,
                "lng": 14.8333
            },
            "Nuoro": {
                "lat": 40.3167,
                "lng": 9.3333
            },
            "Crema": {
                "lat": 45.3667,
                "lng": 9.6833
            },
            "Somma Vesuviana": {
                "lat": 40.8725,
                "lng": 14.4369
            },
            "Gorizia": {
                "lat": 45.9333,
                "lng": 13.6167
            },
            "Aosta": {
                "lat": 45.7333,
                "lng": 7.3167
            },
            "Castelfranco Emilia": {
                "lat": 44.5967,
                "lng": 11.0528
            },
            "Villafranca di Verona": {
                "lat": 45.35,
                "lng": 10.85
            },
            "Castelfranco Veneto": {
                "lat": 45.6667,
                "lng": 11.9333
            },
            "San Lazzaro di Savena": {
                "lat": 44.4708,
                "lng": 11.4083
            },
            "Abbiategrasso": {
                "lat": 45.4,
                "lng": 8.9167
            },
            "Lugo": {
                "lat": 44.4167,
                "lng": 11.9167
            },
            "Sant\u2019Antimo": {
                "lat": 40.95,
                "lng": 14.2333
            },
            "Venaria Reale": {
                "lat": 45.1167,
                "lng": 7.6333
            },
            "Termoli": {
                "lat": 42.0,
                "lng": 14.9833
            },
            "Casale Monferrato": {
                "lat": 45.1333,
                "lng": 8.45
            },
            "Piombino": {
                "lat": 42.9167,
                "lng": 10.5333
            },
            "San Donato Milanese": {
                "lat": 45.4167,
                "lng": 9.2667
            },
            "Arzano": {
                "lat": 40.9167,
                "lng": 14.2667
            },
            "Mascalucia": {
                "lat": 37.5667,
                "lng": 15.05
            },
            "Massafra": {
                "lat": 40.5833,
                "lng": 17.1167
            },
            "Santa Maria Capua Vetere": {
                "lat": 41.0833,
                "lng": 14.25
            },
            "Camaiore": {
                "lat": 43.9333,
                "lng": 10.3
            },
            "Favara": {
                "lat": 37.3186,
                "lng": 13.6631
            },
            "Villaricca": {
                "lat": 40.9167,
                "lng": 14.2
            },
            "Alba": {
                "lat": 44.7,
                "lng": 8.0333
            },
            "Montebelluna": {
                "lat": 45.7753,
                "lng": 12.0389
            },
            "Vibo Valentia": {
                "lat": 38.675,
                "lng": 16.0972
            },
            "Lucera": {
                "lat": 41.5,
                "lng": 15.3333
            },
            "Nard\u00f2": {
                "lat": 40.1797,
                "lng": 18.0333
            },
            "Treviglio": {
                "lat": 45.5167,
                "lng": 9.6
            },
            "Partinico": {
                "lat": 38.045,
                "lng": 13.1208
            },
            "San Giuliano Terme": {
                "lat": 43.7611,
                "lng": 10.4403
            },
            "Grottaglie": {
                "lat": 40.5333,
                "lng": 17.4333
            },
            "Sarno": {
                "lat": 40.8167,
                "lng": 14.6167
            },
            "Gubbio": {
                "lat": 43.35,
                "lng": 12.5667
            },
            "Avola": {
                "lat": 36.9167,
                "lng": 15.1333
            },
            "Oristano": {
                "lat": 39.9,
                "lng": 8.5833
            },
            "Ostuni": {
                "lat": 40.7333,
                "lng": 17.5833
            },
            "San Giuseppe Vesuviano": {
                "lat": 40.8333,
                "lng": 14.5
            },
            "Rosignano Marittimo": {
                "lat": 43.4,
                "lng": 10.4667
            },
            "Milazzo": {
                "lat": 38.2208,
                "lng": 15.2414
            },
            "Comiso": {
                "lat": 36.95,
                "lng": 14.6
            },
            "Verbania": {
                "lat": 45.9333,
                "lng": 8.5333
            },
            "Manduria": {
                "lat": 40.4,
                "lng": 17.6333
            },
            "Monfalcone": {
                "lat": 45.8,
                "lng": 13.5333
            },
            "Bra": {
                "lat": 44.7,
                "lng": 7.85
            },
            "Pontedera": {
                "lat": 43.6625,
                "lng": 10.6328
            },
            "Castelvetrano": {
                "lat": 37.6833,
                "lng": 12.7931
            },
            "Desenzano del Garda": {
                "lat": 45.4667,
                "lng": 10.5333
            },
            "Rapallo": {
                "lat": 44.35,
                "lng": 9.2333
            },
            "Marigliano": {
                "lat": 40.9333,
                "lng": 14.45
            },
            "Pirri": {
                "lat": 39.2278,
                "lng": 9.1111
            },
            "Cervia": {
                "lat": 44.2586,
                "lng": 12.3558
            },
            "Fabriano": {
                "lat": 43.3333,
                "lng": 12.9167
            },
            "Misilmeri": {
                "lat": 38.0347,
                "lng": 13.4514
            },
            "Mondragone": {
                "lat": 41.1,
                "lng": 13.8833
            },
            "Frattamaggiore": {
                "lat": 40.9333,
                "lng": 14.2833
            },
            "Selargius": {
                "lat": 39.25,
                "lng": 9.1667
            },
            "Castel Volturno": {
                "lat": 41.05,
                "lng": 13.9167
            },
            "Poggibonsi": {
                "lat": 43.4667,
                "lng": 11.15
            },
            "Carmagnola": {
                "lat": 44.85,
                "lng": 7.7167
            },
            "Belpasso": {
                "lat": 37.5833,
                "lng": 14.9833
            },
            "Gragnano": {
                "lat": 40.6833,
                "lng": 14.5167
            },
            "Canosa di Puglia": {
                "lat": 41.2167,
                "lng": 16.0667
            },
            "Cecina": {
                "lat": 43.3167,
                "lng": 10.5167
            },
            "Aci Catena": {
                "lat": 37.6,
                "lng": 15.1333
            },
            "Parabiago": {
                "lat": 45.55,
                "lng": 8.95
            },
            "Mogliano Veneto": {
                "lat": 45.5786,
                "lng": 12.2364
            },
            "San Giovanni in Persiceto": {
                "lat": 44.6333,
                "lng": 11.1833
            },
            "San Miniato": {
                "lat": 43.6833,
                "lng": 10.85
            },
            "Spinea": {
                "lat": 45.5,
                "lng": 12.15
            },
            "Alatri": {
                "lat": 41.725,
                "lng": 13.3417
            },
            "Assisi": {
                "lat": 43.0758,
                "lng": 12.6175
            },
            "Chiavari": {
                "lat": 44.3167,
                "lng": 9.3333
            },
            "Novi Ligure": {
                "lat": 44.7617,
                "lng": 8.7906
            },
            "Mirano": {
                "lat": 45.5,
                "lng": 12.1
            },
            "Vittorio Veneto": {
                "lat": 45.9833,
                "lng": 12.3
            },
            "Fidenza": {
                "lat": 44.8667,
                "lng": 10.0667
            },
            "Albignasego": {
                "lat": 45.35,
                "lng": 11.8667
            },
            "Scicli": {
                "lat": 36.7881,
                "lng": 14.6978
            },
            "Garbagnate Milanese": {
                "lat": 45.5833,
                "lng": 9.0667
            },
            "Quarrata": {
                "lat": 43.8475,
                "lng": 10.9833
            },
            "Gioia del Colle": {
                "lat": 40.8,
                "lng": 16.9333
            },
            "Iesolo": {
                "lat": 45.5339,
                "lng": 12.6408
            },
            "Giarre": {
                "lat": 37.7333,
                "lng": 15.1833
            },
            "Lido di Iesolo": {
                "lat": 45.5339,
                "lng": 12.6408
            },
            "Tortona": {
                "lat": 44.8942,
                "lng": 8.8656
            },
            "Carbonia": {
                "lat": 39.1672,
                "lng": 8.5222
            },
            "San Giovanni Rotondo": {
                "lat": 41.7,
                "lng": 15.7333
            },
            "Lainate": {
                "lat": 45.5667,
                "lng": 9.0333
            },
            "Chivasso": {
                "lat": 45.1833,
                "lng": 7.8833
            },
            "Sant\u2019Anastasia": {
                "lat": 40.8667,
                "lng": 14.4
            },
            "Mesagne": {
                "lat": 40.5667,
                "lng": 17.8
            },
            "Montichiari": {
                "lat": 45.4167,
                "lng": 10.4
            },
            "Terlizzi": {
                "lat": 41.1333,
                "lng": 16.55
            },
            "Erice": {
                "lat": 38.0369,
                "lng": 12.5864
            },
            "Bresso": {
                "lat": 45.5333,
                "lng": 9.1833
            },
            "Giussano": {
                "lat": 45.7,
                "lng": 9.2167
            },
            "Boscoreale": {
                "lat": 40.7667,
                "lng": 14.4833
            },
            "Putignano": {
                "lat": 40.85,
                "lng": 17.1167
            },
            "Cesenatico": {
                "lat": 44.2,
                "lng": 12.3944
            },
            "Noicattaro": {
                "lat": 41.0333,
                "lng": 16.9833
            },
            "Galatina": {
                "lat": 40.1667,
                "lng": 18.1667
            },
            "Vimercate": {
                "lat": 45.6167,
                "lng": 9.3667
            },
            "Triggiano": {
                "lat": 41.0667,
                "lng": 16.9167
            },
            "Ass\u00e8mini": {
                "lat": 39.2833,
                "lng": 9.0
            },
            "Vignola": {
                "lat": 44.4667,
                "lng": 11.0
            },
            "Conversano": {
                "lat": 40.9667,
                "lng": 17.1167
            },
            "Santeramo in Colle": {
                "lat": 40.8,
                "lng": 16.7667
            },
            "Scandiano": {
                "lat": 44.5972,
                "lng": 10.6917
            },
            "Francavilla al Mare": {
                "lat": 42.4167,
                "lng": 14.3
            },
            "Valdagno": {
                "lat": 45.65,
                "lng": 11.3
            },
            "Porto Sant\u2019Elpidio": {
                "lat": 43.2667,
                "lng": 13.75
            },
            "Falconara Marittima": {
                "lat": 43.6333,
                "lng": 13.4
            },
            "Enna": {
                "lat": 37.5633,
                "lng": 14.2761
            },
            "Roseto degli Abruzzi": {
                "lat": 42.6833,
                "lng": 14.0167
            },
            "Legnago": {
                "lat": 45.1944,
                "lng": 11.3125
            },
            "San Giovanni Lupatoto": {
                "lat": 45.3833,
                "lng": 11.0333
            },
            "Arzignano": {
                "lat": 45.5167,
                "lng": 11.3333
            },
            "Seriate": {
                "lat": 45.6833,
                "lng": 9.7167
            },
            "Mariano Comense": {
                "lat": 45.7,
                "lng": 9.1833
            },
            "Bacoli": {
                "lat": 40.8,
                "lng": 14.0833
            },
            "Bagno a Ripoli": {
                "lat": 43.75,
                "lng": 11.3167
            },
            "Iglesias": {
                "lat": 39.3167,
                "lng": 8.5333
            },
            "Correggio": {
                "lat": 44.7703,
                "lng": 10.7822
            },
            "Niscemi": {
                "lat": 37.15,
                "lng": 14.3833
            },
            "Termini Imerese": {
                "lat": 37.9872,
                "lng": 13.6961
            },
            "Sora": {
                "lat": 41.7167,
                "lng": 13.6167
            },
            "Qualiano": {
                "lat": 40.9167,
                "lng": 14.15
            },
            "Mola di Bari": {
                "lat": 41.0667,
                "lng": 17.0833
            },
            "Ruvo di Puglia": {
                "lat": 41.1167,
                "lng": 16.4833
            },
            "Portogruaro": {
                "lat": 45.7833,
                "lng": 12.8333
            },
            "Noto": {
                "lat": 36.8833,
                "lng": 15.0833
            },
            "Fossano": {
                "lat": 44.55,
                "lng": 7.7333
            },
            "Magenta": {
                "lat": 45.4667,
                "lng": 8.8833
            },
            "Mirandola": {
                "lat": 44.8867,
                "lng": 11.0667
            },
            "Montevarchi": {
                "lat": 43.5286,
                "lng": 11.57
            },
            "Pompei": {
                "lat": 40.7492,
                "lng": 14.5006
            },
            "Seveso": {
                "lat": 45.6333,
                "lng": 9.1333
            },
            "Thiene": {
                "lat": 45.7083,
                "lng": 11.4778
            },
            "Nocera Superiore": {
                "lat": 40.7333,
                "lng": 14.6667
            },
            "San Giovanni la Punta": {
                "lat": 37.5833,
                "lng": 15.1
            },
            "Sezze": {
                "lat": 41.5,
                "lng": 13.0667
            },
            "Castiglione delle Stiviere": {
                "lat": 45.3917,
                "lng": 10.4889
            },
            "Muggi\u00f2": {
                "lat": 45.6,
                "lng": 9.2333
            },
            "Meda": {
                "lat": 45.6667,
                "lng": 9.1667
            },
            "Albenga": {
                "lat": 44.05,
                "lng": 8.2167
            },
            "Giulianova": {
                "lat": 42.75,
                "lng": 13.95
            },
            "Montecchio Maggiore": {
                "lat": 45.5,
                "lng": 11.4167
            },
            "Dalmine": {
                "lat": 45.65,
                "lng": 9.6
            },
            "Capoterra": {
                "lat": 39.1744,
                "lng": 8.9711
            },
            "Ottaviano": {
                "lat": 40.85,
                "lng": 14.4833
            },
            "Vigonza": {
                "lat": 45.45,
                "lng": 11.9833
            },
            "Orbassano": {
                "lat": 45.0,
                "lng": 7.5333
            },
            "Copertino": {
                "lat": 40.2667,
                "lng": 18.05
            },
            "Nova Milanese": {
                "lat": 45.5833,
                "lng": 9.2
            },
            "Biancavilla": {
                "lat": 37.65,
                "lng": 14.8667
            },
            "Pietrasanta": {
                "lat": 43.9667,
                "lng": 10.2333
            },
            "Genzano di Roma": {
                "lat": 41.7,
                "lng": 12.6833
            },
            "Ventimiglia": {
                "lat": 43.7903,
                "lng": 7.6083
            },
            "Selvazzano Dentro": {
                "lat": 45.3833,
                "lng": 11.7833
            },
            "Bressanone": {
                "lat": 46.7167,
                "lng": 11.65
            },
            "Frascati": {
                "lat": 41.8167,
                "lng": 12.6833
            },
            "Fucecchio": {
                "lat": 43.7333,
                "lng": 10.8
            },
            "Mentana": {
                "lat": 42.0167,
                "lng": 12.65
            },
            "Ivrea": {
                "lat": 45.4667,
                "lng": 7.8833
            },
            "Ceccano": {
                "lat": 41.5667,
                "lng": 13.3333
            },
            "Poggiomarino": {
                "lat": 40.8,
                "lng": 14.55
            },
            "Ortona": {
                "lat": 42.35,
                "lng": 14.4
            },
            "Sulmona": {
                "lat": 42.0333,
                "lng": 13.9333
            },
            "Sant\u2019Arcangelo di Romagna": {
                "lat": 44.0667,
                "lng": 12.45
            },
            "Palestrina": {
                "lat": 41.8333,
                "lng": 12.9
            },
            "Mondov\u00ec": {
                "lat": 44.3889,
                "lng": 7.8181
            },
            "Comacchio": {
                "lat": 44.7,
                "lng": 12.1833
            },
            "Paese": {
                "lat": 45.6667,
                "lng": 12.15
            },
            "Ginosa": {
                "lat": 40.5,
                "lng": 16.75
            },
            "Massarosa": {
                "lat": 43.8667,
                "lng": 10.3333
            },
            "Pachino": {
                "lat": 36.7167,
                "lng": 15.1
            },
            "Sarzana": {
                "lat": 44.1167,
                "lng": 9.9667
            },
            "Lentini": {
                "lat": 37.2833,
                "lng": 15.0
            },
            "Mercato San Severino": {
                "lat": 40.7833,
                "lng": 14.75
            },
            "Savigliano": {
                "lat": 44.65,
                "lng": 7.6333
            },
            "Pergine Valsugana": {
                "lat": 46.0667,
                "lng": 11.2333
            },
            "Colle di Val d\u2019Elsa": {
                "lat": 43.4,
                "lng": 11.1333
            },
            "Corciano": {
                "lat": 43.1333,
                "lng": 12.2833
            },
            "Cardito": {
                "lat": 40.95,
                "lng": 14.3
            },
            "Trezzano sul Naviglio": {
                "lat": 45.4333,
                "lng": 9.0667
            },
            "San Bonifacio": {
                "lat": 45.4,
                "lng": 11.2833
            },
            "Palma di Montechiaro": {
                "lat": 37.2,
                "lng": 13.7667
            },
            "Senago": {
                "lat": 45.5833,
                "lng": 9.1333
            },
            "Casal di Principe": {
                "lat": 41.0167,
                "lng": 14.1333
            },
            "Agropoli": {
                "lat": 40.35,
                "lng": 14.9833
            },
            "Borgomanero": {
                "lat": 45.7,
                "lng": 8.4667
            },
            "Floridia": {
                "lat": 37.0833,
                "lng": 15.15
            },
            "Cortona": {
                "lat": 43.2756,
                "lng": 11.9881
            },
            "Martellago": {
                "lat": 45.55,
                "lng": 12.1667
            },
            "Gorgonzola": {
                "lat": 45.5333,
                "lng": 9.4
            },
            "Sondrio": {
                "lat": 46.1667,
                "lng": 9.8667
            },
            "Porto Torres": {
                "lat": 40.8333,
                "lng": 8.4
            },
            "Ariano Irpino": {
                "lat": 41.1528,
                "lng": 15.0889
            },
            "Suzzara": {
                "lat": 45.0,
                "lng": 10.75
            },
            "San Cataldo": {
                "lat": 37.4833,
                "lng": 13.9833
            },
            "Argenta": {
                "lat": 44.6153,
                "lng": 11.8333
            },
            "Castrovillari": {
                "lat": 39.8167,
                "lng": 16.2
            },
            "Sestu": {
                "lat": 39.3,
                "lng": 9.1
            },
            "Castel San Pietro Terme": {
                "lat": 44.4,
                "lng": 11.5833
            },
            "Anagni": {
                "lat": 41.7422,
                "lng": 13.1583
            },
            "Monsummano": {
                "lat": 43.8667,
                "lng": 10.8167
            },
            "Montecatini Terme": {
                "lat": 43.8828,
                "lng": 10.7711
            },
            "Bussolengo": {
                "lat": 45.4667,
                "lng": 10.85
            },
            "Recanati": {
                "lat": 43.4036,
                "lng": 13.5497
            },
            "Cornaredo": {
                "lat": 45.5,
                "lng": 9.0333
            },
            "Piazza Armerina": {
                "lat": 37.3833,
                "lng": 14.3667
            },
            "Isernia": {
                "lat": 41.6028,
                "lng": 14.2397
            },
            "Rosolini": {
                "lat": 36.8167,
                "lng": 14.95
            },
            "Palo del Colle": {
                "lat": 41.05,
                "lng": 16.7
            },
            "Romano di Lombardia": {
                "lat": 45.5167,
                "lng": 9.75
            },
            "Colleferro": {
                "lat": 41.7333,
                "lng": 13.0167
            },
            "Trecate": {
                "lat": 45.4333,
                "lng": 8.7333
            },
            "Grottaferrata": {
                "lat": 41.7883,
                "lng": 12.6717
            },
            "Follonica": {
                "lat": 42.9189,
                "lng": 10.7614
            },
            "Feltre": {
                "lat": 46.0186,
                "lng": 11.91
            },
            "Cormano": {
                "lat": 45.55,
                "lng": 9.1667
            },
            "Trentola": {
                "lat": 40.9833,
                "lng": 14.1833
            },
            "Minturno": {
                "lat": 41.2667,
                "lng": 13.75
            },
            "Vico Equense": {
                "lat": 40.6667,
                "lng": 14.4333
            },
            "Abano Terme": {
                "lat": 45.3603,
                "lng": 11.79
            },
            "Sessa Aurunca": {
                "lat": 41.2333,
                "lng": 13.9333
            },
            "Rivalta di Torino": {
                "lat": 45.0333,
                "lng": 7.5167
            },
            "Pontassieve": {
                "lat": 43.775,
                "lng": 11.4375
            },
            "Pallazzolo sull\u2019Oglio": {
                "lat": 45.6,
                "lng": 9.8833
            },
            "Montalto Uffugo": {
                "lat": 39.4,
                "lng": 16.15
            },
            "Ferentino": {
                "lat": 41.6833,
                "lng": 13.25
            },
            "Cittadella": {
                "lat": 45.65,
                "lng": 11.7833
            },
            "Oderzo": {
                "lat": 45.78,
                "lng": 12.4881
            },
            "Piove di Sacco": {
                "lat": 45.3,
                "lng": 12.0333
            },
            "Salsomaggiore Terme": {
                "lat": 44.8167,
                "lng": 9.9833
            },
            "Acquaviva delle Fonti": {
                "lat": 40.9,
                "lng": 16.85
            },
            "Novate Milanese": {
                "lat": 45.5333,
                "lng": 9.1333
            },
            "Sacile": {
                "lat": 45.9667,
                "lng": 12.5
            },
            "Viadana": {
                "lat": 44.9167,
                "lng": 10.5167
            },
            "San Salvo": {
                "lat": 42.05,
                "lng": 14.7167
            },
            "Villabate": {
                "lat": 38.0833,
                "lng": 13.45
            },
            "Veroli": {
                "lat": 41.6833,
                "lng": 13.4167
            },
            "Ischia": {
                "lat": 40.7333,
                "lng": 13.95
            },
            "Lastra a Signa": {
                "lat": 43.7667,
                "lng": 11.1
            },
            "Gallipoli": {
                "lat": 40.0556,
                "lng": 17.9917
            },
            "Arese": {
                "lat": 45.55,
                "lng": 9.0667
            },
            "Castellana Grotte": {
                "lat": 40.8833,
                "lng": 17.1667
            },
            "Orvieto": {
                "lat": 42.7183,
                "lng": 12.1103
            },
            "Sabaudia": {
                "lat": 41.3,
                "lng": 13.0167
            },
            "Gaeta": {
                "lat": 41.2167,
                "lng": 13.5667
            },
            "Casarano": {
                "lat": 40.0167,
                "lng": 18.1667
            },
            "Giovinazzo": {
                "lat": 41.1833,
                "lng": 16.6667
            },
            "Casamassima": {
                "lat": 40.95,
                "lng": 16.9167
            },
            "Rovato": {
                "lat": 45.5667,
                "lng": 9.9833
            },
            "Cassano d\u2019Adda": {
                "lat": 45.5333,
                "lng": 9.5167
            },
            "Pescia": {
                "lat": 43.9017,
                "lng": 10.6898
            },
            "Vigna di Valle": {
                "lat": 42.1,
                "lng": 12.1833
            },
            "Zola Predosa": {
                "lat": 44.4883,
                "lng": 11.2181
            },
            "Gioia Tauro": {
                "lat": 38.4333,
                "lng": 15.9
            },
            "Chiari": {
                "lat": 45.5364,
                "lng": 9.9292
            },
            "Montemurlo": {
                "lat": 43.9333,
                "lng": 11.0333
            },
            "Anguillara Sabazia": {
                "lat": 42.0833,
                "lng": 12.2667
            },
            "Sant\u2019Antonio Abate": {
                "lat": 40.7333,
                "lng": 14.55
            },
            "Spoltore": {
                "lat": 42.45,
                "lng": 14.1333
            },
            "Scorz\u00e8": {
                "lat": 45.5719,
                "lng": 12.1089
            },
            "Acqui Terme": {
                "lat": 44.6833,
                "lng": 8.4667
            },
            "Casalgrande": {
                "lat": 44.5833,
                "lng": 10.7333
            },
            "Monserrato": {
                "lat": 39.25,
                "lng": 9.15
            },
            "Pozzallo": {
                "lat": 36.7333,
                "lng": 14.85
            },
            "Signa": {
                "lat": 43.7833,
                "lng": 11.1
            },
            "Acri": {
                "lat": 39.5,
                "lng": 16.3833
            },
            "Tradate": {
                "lat": 45.7,
                "lng": 8.9167
            },
            "Cusano Milanino": {
                "lat": 45.55,
                "lng": 9.1833
            },
            "Ceglie Messapico": {
                "lat": 40.65,
                "lng": 17.5
            },
            "Adria": {
                "lat": 45.05,
                "lng": 12.05
            },
            "San Mauro Torinese": {
                "lat": 45.1,
                "lng": 7.7667
            },
            "Ghedi": {
                "lat": 45.4,
                "lng": 10.2833
            },
            "Zagarolo": {
                "lat": 41.8333,
                "lng": 12.8333
            },
            "Castel Maggiore": {
                "lat": 44.5667,
                "lng": 11.3667
            },
            "Castelfidardo": {
                "lat": 43.4631,
                "lng": 13.55
            },
            "Santa Marinella": {
                "lat": 42.0333,
                "lng": 11.85
            },
            "Bracciano": {
                "lat": 42.1,
                "lng": 12.1833
            },
            "Noci": {
                "lat": 40.8,
                "lng": 17.1333
            },
            "Melzo": {
                "lat": 45.5,
                "lng": 9.4167
            },
            "Rutigliano": {
                "lat": 40.9333,
                "lng": 16.9
            },
            "Budrio": {
                "lat": 44.55,
                "lng": 11.5333
            },
            "Bronte": {
                "lat": 37.7833,
                "lng": 14.8333
            },
            "Laives": {
                "lat": 46.4333,
                "lng": 11.3333
            },
            "Corbetta": {
                "lat": 45.4667,
                "lng": 8.9167
            },
            "Ciri\u00e8": {
                "lat": 45.2333,
                "lng": 7.6
            },
            "Pavullo nel Frignano": {
                "lat": 44.3333,
                "lng": 10.8333
            },
            "San Vito dei Normanni": {
                "lat": 40.6581,
                "lng": 17.7078
            },
            "Borgo San Lorenzo": {
                "lat": 43.95,
                "lng": 11.3833
            },
            "Calenzano": {
                "lat": 43.8667,
                "lng": 11.1667
            },
            "Valenza": {
                "lat": 45.0167,
                "lng": 8.65
            },
            "Piossasco": {
                "lat": 44.9833,
                "lng": 7.4667
            },
            "Marsciano": {
                "lat": 42.9167,
                "lng": 12.3333
            },
            "Aci Sant\u2019Antonio": {
                "lat": 37.6,
                "lng": 15.1167
            },
            "Ariccia": {
                "lat": 41.72,
                "lng": 12.6711
            },
            "Melegnano": {
                "lat": 45.3667,
                "lng": 9.3167
            },
            "Agliana": {
                "lat": 43.9,
                "lng": 11.0
            },
            "Palmi": {
                "lat": 38.3667,
                "lng": 15.85
            },
            "Narni": {
                "lat": 42.5167,
                "lng": 12.5167
            },
            "Tolentino": {
                "lat": 43.2167,
                "lng": 13.3833
            },
            "Aci Castello": {
                "lat": 37.5556,
                "lng": 15.1458
            },
            "Savignano sul Rubicone": {
                "lat": 44.1,
                "lng": 12.4
            },
            "Ribera": {
                "lat": 37.4994,
                "lng": 13.265
            },
            "Policoro": {
                "lat": 40.2,
                "lng": 16.6667
            },
            "Arcore": {
                "lat": 45.6333,
                "lng": 9.3167
            },
            "Carate Brianza": {
                "lat": 45.7667,
                "lng": 9.1833
            },
            "Cordenons": {
                "lat": 45.9833,
                "lng": 12.7
            },
            "Somma Lombardo": {
                "lat": 45.6833,
                "lng": 8.7
            },
            "Arco": {
                "lat": 45.9167,
                "lng": 10.8833
            },
            "Domodossola": {
                "lat": 46.1167,
                "lng": 8.2833
            },
            "Pianoro": {
                "lat": 44.3875,
                "lng": 11.3444
            },
            "Capua": {
                "lat": 41.1056,
                "lng": 14.2139
            },
            "Siderno Marina": {
                "lat": 38.2667,
                "lng": 16.3
            },
            "Marcon": {
                "lat": 45.5667,
                "lng": 12.3
            },
            "Sona": {
                "lat": 45.4333,
                "lng": 10.8333
            },
            "Polignano a Mare": {
                "lat": 41.0,
                "lng": 17.2167
            },
            "Villorba": {
                "lat": 45.7333,
                "lng": 12.2333
            },
            "Rocca di Papa": {
                "lat": 41.7611,
                "lng": 12.7092
            },
            "Saluzzo": {
                "lat": 44.65,
                "lng": 7.4833
            },
            "Albino": {
                "lat": 45.7606,
                "lng": 9.7969
            },
            "Forio": {
                "lat": 40.7333,
                "lng": 13.85
            },
            "Pescantina": {
                "lat": 45.4833,
                "lng": 10.8667
            },
            "Isola Capo Rizzuto": {
                "lat": 38.9589,
                "lng": 17.0956
            },
            "Beinasco": {
                "lat": 45.0167,
                "lng": 7.5833
            },
            "Santa Maria di Sala": {
                "lat": 45.5,
                "lng": 12.0333
            },
            "Sestri Levante": {
                "lat": 44.2667,
                "lng": 9.4
            },
            "Valenzano": {
                "lat": 41.05,
                "lng": 16.8833
            },
            "Riva del Garda": {
                "lat": 45.8833,
                "lng": 10.85
            },
            "Maranello": {
                "lat": 44.5333,
                "lng": 10.8667
            },
            "Monselice": {
                "lat": 45.2333,
                "lng": 11.75
            },
            "Castelfiorentino": {
                "lat": 43.6,
                "lng": 10.9667
            },
            "Bareggio": {
                "lat": 45.4667,
                "lng": 8.9833
            },
            "Terzigno": {
                "lat": 40.8,
                "lng": 14.5
            },
            "S\u00ecnnai": {
                "lat": 39.3,
                "lng": 9.2
            },
            "Grumo Nevano": {
                "lat": 40.9333,
                "lng": 14.2667
            },
            "Tricase": {
                "lat": 39.9333,
                "lng": 18.3667
            },
            "Figline Valdarno": {
                "lat": 43.6167,
                "lng": 11.4667
            },
            "Melfi": {
                "lat": 41.0,
                "lng": 15.65
            },
            "Carlentini": {
                "lat": 37.2833,
                "lng": 15.0167
            },
            "Brunico": {
                "lat": 46.8,
                "lng": 11.9333
            },
            "Cercola": {
                "lat": 40.8667,
                "lng": 14.35
            },
            "Carovigno": {
                "lat": 40.7,
                "lng": 17.6667
            },
            "Nerviano": {
                "lat": 45.55,
                "lng": 8.9833
            },
            "Rubano": {
                "lat": 45.4333,
                "lng": 11.7833
            },
            "Fiorano Modenese": {
                "lat": 44.5333,
                "lng": 10.8167
            },
            "Baronissi": {
                "lat": 40.7462,
                "lng": 14.7705
            },
            "Cerea": {
                "lat": 45.2,
                "lng": 11.2167
            },
            "Orta Nova": {
                "lat": 41.3308,
                "lng": 15.7114
            },
            "Bovisio Masciago": {
                "lat": 45.6167,
                "lng": 9.15
            },
            "Vimodrone": {
                "lat": 45.5,
                "lng": 9.2667
            },
            "Preganziol": {
                "lat": 45.6,
                "lng": 12.2333
            },
            "Pisticci": {
                "lat": 40.3833,
                "lng": 16.55
            },
            "Campagna": {
                "lat": 40.6667,
                "lng": 15.1
            },
            "Medicina": {
                "lat": 44.4833,
                "lng": 11.6333
            },
            "San Giovanni Valdarno": {
                "lat": 43.5644,
                "lng": 11.5328
            },
            "Cattolica": {
                "lat": 43.9667,
                "lng": 12.7333
            },
            "Gussago": {
                "lat": 45.6,
                "lng": 10.15
            },
            "Torre Maggiore": {
                "lat": 41.6833,
                "lng": 15.2833
            },
            "Cassano al Ionio": {
                "lat": 39.7833,
                "lng": 16.3167
            },
            "Sant\u2019Elpidio a Mare": {
                "lat": 43.2333,
                "lng": 13.6833
            },
            "Alpignano": {
                "lat": 45.1,
                "lng": 7.5167
            },
            "Adelfia": {
                "lat": 41.0,
                "lng": 16.8667
            },
            "Reggello": {
                "lat": 43.6833,
                "lng": 11.5333
            },
            "San Casciano in Val di Pesa": {
                "lat": 43.65,
                "lng": 11.1833
            },
            "Vedelago": {
                "lat": 45.6833,
                "lng": 12.0167
            },
            "Malnate": {
                "lat": 45.8,
                "lng": 8.8833
            },
            "Erba": {
                "lat": 45.8167,
                "lng": 9.2167
            },
            "Bagnacavallo": {
                "lat": 44.4167,
                "lng": 11.9833
            },
            "Lein\u00ec": {
                "lat": 45.1833,
                "lng": 7.7167
            },
            "Umbertide": {
                "lat": 43.3,
                "lng": 12.3333
            },
            "Colle Salvetti": {
                "lat": 43.6,
                "lng": 10.4833
            },
            "Martinsicuro": {
                "lat": 42.8833,
                "lng": 13.9167
            },
            "Ispica": {
                "lat": 36.7833,
                "lng": 14.9
            },
            "Fiano Romano": {
                "lat": 42.1667,
                "lng": 12.6
            },
            "San Martino Buon Albergo": {
                "lat": 45.4167,
                "lng": 11.1
            },
            "Giaveno": {
                "lat": 45.0333,
                "lng": 7.35
            },
            "Palma Campania": {
                "lat": 40.8667,
                "lng": 14.55
            },
            "Castellaneta": {
                "lat": 40.6333,
                "lng": 16.9333
            },
            "Castenaso": {
                "lat": 44.5097,
                "lng": 11.4706
            },
            "Caravaggio": {
                "lat": 45.5,
                "lng": 9.65
            },
            "Noale": {
                "lat": 45.55,
                "lng": 12.0667
            },
            "Nonantola": {
                "lat": 44.6781,
                "lng": 11.0422
            },
            "Bovolone": {
                "lat": 45.25,
                "lng": 11.1333
            },
            "Scordia": {
                "lat": 37.3,
                "lng": 14.85
            },
            "Samarate": {
                "lat": 45.6167,
                "lng": 8.7833
            },
            "San Cesareo": {
                "lat": 41.8167,
                "lng": 12.8
            },
            "Este": {
                "lat": 45.2333,
                "lng": 11.6667
            },
            "Valeggio sul Mincio": {
                "lat": 45.35,
                "lng": 10.7333
            },
            "Tarquinia": {
                "lat": 42.2492,
                "lng": 11.7561
            },
            "San Giovanni in Fiore": {
                "lat": 39.2667,
                "lng": 16.7
            },
            "Concorezzo": {
                "lat": 45.6,
                "lng": 9.3333
            },
            "Brusciano": {
                "lat": 40.9167,
                "lng": 14.4167
            },
            "Codroipo": {
                "lat": 45.9625,
                "lng": 12.9792
            },
            "Lonigo": {
                "lat": 45.3833,
                "lng": 11.3833
            },
            "Grottammare": {
                "lat": 42.9897,
                "lng": 13.8658
            },
            "Saviano": {
                "lat": 40.9167,
                "lng": 14.5167
            },
            "Cadoneghe": {
                "lat": 45.45,
                "lng": 11.9333
            },
            "Lentate sul Seveso": {
                "lat": 45.6833,
                "lng": 9.1167
            },
            "Palagiano": {
                "lat": 40.5833,
                "lng": 17.05
            },
            "Palagonia": {
                "lat": 37.3333,
                "lng": 14.75
            },
            "Altopascio": {
                "lat": 43.8167,
                "lng": 10.6833
            },
            "Carugate": {
                "lat": 45.55,
                "lng": 9.3333
            },
            "Todi": {
                "lat": 42.7789,
                "lng": 12.4142
            },
            "Azzano Decimo": {
                "lat": 45.8833,
                "lng": 12.7167
            },
            "Copparo": {
                "lat": 44.9,
                "lng": 11.8333
            },
            "Valmontone": {
                "lat": 41.7833,
                "lng": 12.9167
            },
            "Molinella": {
                "lat": 44.6167,
                "lng": 11.6667
            },
            "Porto San Giorgio": {
                "lat": 43.1833,
                "lng": 13.8
            },
            "Porto Empedocle": {
                "lat": 37.3,
                "lng": 13.5333
            },
            "Ponsacco": {
                "lat": 43.6167,
                "lng": 10.6333
            },
            "Crusinallo": {
                "lat": 45.8775,
                "lng": 8.4089
            },
            "Galliate": {
                "lat": 45.4833,
                "lng": 8.7
            },
            "Zevio": {
                "lat": 45.3667,
                "lng": 11.1333
            },
            "Agrate Brianza": {
                "lat": 45.5833,
                "lng": 9.35
            },
            "Codogno": {
                "lat": 45.16,
                "lng": 9.705
            },
            "Certaldo": {
                "lat": 43.5478,
                "lng": 11.0411
            },
            "Casalpusterlengo": {
                "lat": 45.1833,
                "lng": 9.65
            },
            "Pianezza": {
                "lat": 45.1,
                "lng": 7.55
            },
            "Soliera": {
                "lat": 44.7333,
                "lng": 10.9333
            },
            "Sorrento": {
                "lat": 40.6261,
                "lng": 14.3761
            },
            "Potenza Picena": {
                "lat": 43.3667,
                "lng": 13.6167
            },
            "Besana in Brianza": {
                "lat": 45.7,
                "lng": 9.2833
            },
            "Silvi Paese": {
                "lat": 42.55,
                "lng": 14.1167
            },
            "Mortara": {
                "lat": 45.25,
                "lng": 8.75
            },
            "Frattaminore": {
                "lat": 40.95,
                "lng": 14.2667
            },
            "Sava": {
                "lat": 40.4,
                "lng": 17.5667
            },
            "Mottola": {
                "lat": 40.6333,
                "lng": 17.0333
            },
            "Civita Castellana": {
                "lat": 42.2833,
                "lng": 12.4
            },
            "Volpiano": {
                "lat": 45.2,
                "lng": 7.7833
            },
            "Castellarano": {
                "lat": 44.5667,
                "lng": 10.7833
            },
            "Capurso": {
                "lat": 41.05,
                "lng": 16.9167
            },
            "San Vito al Tagliamento": {
                "lat": 45.9,
                "lng": 12.8667
            },
            "Castiglione del Lago": {
                "lat": 43.1386,
                "lng": 12.0478
            },
            "Sansepolcro": {
                "lat": 43.5708,
                "lng": 12.1403
            },
            "Galatone": {
                "lat": 40.15,
                "lng": 18.0667
            },
            "Campodarsego": {
                "lat": 45.5,
                "lng": 11.9167
            },
            "Pedara": {
                "lat": 37.6167,
                "lng": 15.0667
            },
            "Pontinia": {
                "lat": 41.4,
                "lng": 13.05
            },
            "Cassano delle Murge": {
                "lat": 40.8833,
                "lng": 16.7667
            },
            "Sant\u2019Arpino": {
                "lat": 40.9575,
                "lng": 14.2492
            },
            "Casalmaggiore": {
                "lat": 44.9833,
                "lng": 10.4167
            },
            "Porcia": {
                "lat": 45.9667,
                "lng": 12.6167
            },
            "Castelnuovo Rangone": {
                "lat": 44.55,
                "lng": 10.9333
            },
            "Dolo": {
                "lat": 45.4269,
                "lng": 12.0753
            },
            "Finale Emilia": {
                "lat": 44.8333,
                "lng": 11.2833
            },
            "Cerro Maggiore": {
                "lat": 45.6,
                "lng": 8.95
            },
            "Fiorenzuola d\u2019Arda": {
                "lat": 44.9333,
                "lng": 9.9
            },
            "Taurianova": {
                "lat": 38.35,
                "lng": 16.0167
            },
            "Merate": {
                "lat": 45.7,
                "lng": 9.4333
            },
            "Citt\u00e0 Sant\u2019Angelo": {
                "lat": 42.5167,
                "lng": 14.05
            },
            "Laterza": {
                "lat": 40.6333,
                "lng": 16.8
            },
            "Sasso Marconi": {
                "lat": 44.4,
                "lng": 11.25
            },
            "Paola": {
                "lat": 39.3667,
                "lng": 16.0333
            },
            "Santa Croce sull\u2019 Arno": {
                "lat": 43.7167,
                "lng": 10.7833
            },
            "Surbo": {
                "lat": 40.4,
                "lng": 18.1333
            },
            "Ospitaletto": {
                "lat": 45.55,
                "lng": 10.0833
            },
            "Collecchio": {
                "lat": 44.75,
                "lng": 10.2167
            },
            "Rubiera": {
                "lat": 44.65,
                "lng": 10.7833
            },
            "Corridonia": {
                "lat": 43.25,
                "lng": 13.5167
            },
            "Malo": {
                "lat": 45.6667,
                "lng": 11.4167
            },
            "Ros\u00e0": {
                "lat": 45.7167,
                "lng": 11.7667
            },
            "Magione": {
                "lat": 43.15,
                "lng": 12.2
            },
            "Roncade": {
                "lat": 45.6333,
                "lng": 12.3667
            },
            "Castellammare del Golfo": {
                "lat": 38.0264,
                "lng": 12.8806
            },
            "Carmignano": {
                "lat": 43.8167,
                "lng": 11.0167
            },
            "Guastalla": {
                "lat": 44.9167,
                "lng": 10.6667
            },
            "Pineto": {
                "lat": 42.6167,
                "lng": 14.0667
            },
            "Oria": {
                "lat": 40.5,
                "lng": 17.6333
            },
            "Sommacampagna": {
                "lat": 45.4052,
                "lng": 10.846
            },
            "Tavagnacco": {
                "lat": 46.1333,
                "lng": 13.2167
            },
            "Vinci": {
                "lat": 43.7833,
                "lng": 10.9167
            },
            "Sorso": {
                "lat": 40.8,
                "lng": 8.5667
            },
            "Impruneta": {
                "lat": 43.6833,
                "lng": 11.25
            },
            "Rosarno": {
                "lat": 38.5,
                "lng": 15.9833
            },
            "Leno": {
                "lat": 45.3667,
                "lng": 10.2167
            },
            "Mascali": {
                "lat": 37.75,
                "lng": 15.2
            },
            "Omegna": {
                "lat": 45.8775,
                "lng": 8.4089
            },
            "Orbetello": {
                "lat": 42.4428,
                "lng": 11.2247
            },
            "Chiaravalle": {
                "lat": 43.6,
                "lng": 13.3333
            },
            "Gualdo Tadino": {
                "lat": 43.2333,
                "lng": 12.7833
            },
            "Santa Maria a Vico": {
                "lat": 41.0333,
                "lng": 14.4833
            },
            "Mondolfo": {
                "lat": 43.75,
                "lng": 13.1
            },
            "Opera": {
                "lat": 45.3833,
                "lng": 9.2167
            },
            "San Giorgio Ionico": {
                "lat": 40.45,
                "lng": 17.3667
            },
            "Oleggio": {
                "lat": 45.6,
                "lng": 8.6333
            },
            "Rescaldina": {
                "lat": 45.6167,
                "lng": 8.95
            },
            "Montelupo Fiorentino": {
                "lat": 43.7333,
                "lng": 11.0167
            },
            "Massalubrense": {
                "lat": 40.6111,
                "lng": 14.3447
            },
            "Villasanta": {
                "lat": 45.6,
                "lng": 9.3
            },
            "Luino": {
                "lat": 46.0,
                "lng": 8.75
            },
            "Trepuzzi": {
                "lat": 40.4,
                "lng": 18.0667
            },
            "Castel San Giovanni": {
                "lat": 45.05,
                "lng": 9.4333
            },
            "Fisciano": {
                "lat": 40.7667,
                "lng": 14.8
            },
            "Cir\u00f2 Marina": {
                "lat": 39.3694,
                "lng": 17.1278
            },
            "Riposto": {
                "lat": 37.7333,
                "lng": 15.2
            },
            "Busto Garolfo": {
                "lat": 45.5478,
                "lng": 8.8867
            },
            "Cassina de\u2019 Pecchi": {
                "lat": 45.5167,
                "lng": 9.3667
            },
            "Misano Adriatico": {
                "lat": 43.9789,
                "lng": 12.695
            },
            "Marostica": {
                "lat": 45.75,
                "lng": 11.65
            },
            "Castellanza": {
                "lat": 45.6167,
                "lng": 8.9
            },
            "Locorotondo": {
                "lat": 40.7558,
                "lng": 17.3264
            },
            "Cossato": {
                "lat": 45.5667,
                "lng": 8.1667
            },
            "Cefal\u00f9": {
                "lat": 38.0333,
                "lng": 14.0167
            },
            "Caselle Torinese": {
                "lat": 45.1833,
                "lng": 7.65
            },
            "Solaro": {
                "lat": 45.6,
                "lng": 9.0833
            },
            "Amantea": {
                "lat": 39.1333,
                "lng": 16.0667
            },
            "Trinitapoli": {
                "lat": 41.35,
                "lng": 16.1
            },
            "Fara in Sabina": {
                "lat": 42.2167,
                "lng": 12.7333
            },
            "Strada": {
                "lat": 43.5833,
                "lng": 11.3167
            },
            "Sannicandro Garganico": {
                "lat": 41.8333,
                "lng": 15.5667
            },
            "Varedo": {
                "lat": 45.6,
                "lng": 9.1667
            },
            "Leverano": {
                "lat": 40.2833,
                "lng": 18.0833
            },
            "Taggia": {
                "lat": 43.8667,
                "lng": 7.85
            },
            "Travagliato": {
                "lat": 45.5167,
                "lng": 10.0833
            },
            "Bondeno": {
                "lat": 44.8833,
                "lng": 11.4167
            },
            "Priverno": {
                "lat": 41.4667,
                "lng": 13.1833
            },
            "Urbino": {
                "lat": 43.7167,
                "lng": 12.6333
            },
            "Taglio": {
                "lat": 45.0167,
                "lng": 12.2167
            },
            "Crevalcore": {
                "lat": 44.7167,
                "lng": 11.15
            },
            "Arona": {
                "lat": 45.75,
                "lng": 8.55
            },
            "Castel San Giorgio": {
                "lat": 40.7833,
                "lng": 14.7
            },
            "San Ferdinando di Puglia": {
                "lat": 41.3,
                "lng": 16.0667
            },
            "Fiesole": {
                "lat": 43.8072,
                "lng": 11.2919
            },
            "Maglie": {
                "lat": 40.1167,
                "lng": 18.1333
            },
            "Artena": {
                "lat": 41.7333,
                "lng": 12.9167
            },
            "Formello": {
                "lat": 42.0833,
                "lng": 12.4
            },
            "Dueville": {
                "lat": 45.6333,
                "lng": 11.55
            },
            "Cervignano del Friuli": {
                "lat": 45.8231,
                "lng": 13.335
            },
            "Latiano": {
                "lat": 40.5333,
                "lng": 17.7167
            },
            "Castelfranco di Sotto": {
                "lat": 43.7,
                "lng": 10.75
            },
            "Calolziocorte": {
                "lat": 45.8,
                "lng": 9.4333
            },
            "Calderara di Reno": {
                "lat": 44.5667,
                "lng": 11.2667
            },
            "Stezzano": {
                "lat": 45.6333,
                "lng": 9.65
            },
            "Squinzano": {
                "lat": 40.4333,
                "lng": 18.05
            },
            "Monteroni di Lecce": {
                "lat": 40.3333,
                "lng": 18.1
            },
            "Veglie": {
                "lat": 40.3333,
                "lng": 17.9667
            },
            "Vieste": {
                "lat": 41.8833,
                "lng": 16.1667
            },
            "Arzachena": {
                "lat": 41.0833,
                "lng": 9.3833
            },
            "Rezzato": {
                "lat": 45.5333,
                "lng": 10.2333
            },
            "Alzano Lombardo": {
                "lat": 45.7317,
                "lng": 9.7286
            },
            "Sant\u2019Angelo Lodigiano": {
                "lat": 45.2333,
                "lng": 9.4
            },
            "Santa Maria a Monte": {
                "lat": 43.7,
                "lng": 10.6833
            },
            "Manerbio": {
                "lat": 45.3667,
                "lng": 10.1333
            },
            "Ponte San Giovanni": {
                "lat": 43.0897,
                "lng": 12.4456
            },
            "Vetralla": {
                "lat": 42.3106,
                "lng": 12.0792
            },
            "Montepulciano": {
                "lat": 43.1,
                "lng": 11.7833
            },
            "Montespertoli": {
                "lat": 43.65,
                "lng": 11.0833
            },
            "Casandrino": {
                "lat": 40.9333,
                "lng": 14.25
            },
            "Casatenovo": {
                "lat": 45.6972,
                "lng": 9.3122
            },
            "Lariano": {
                "lat": 41.7333,
                "lng": 12.8333
            },
            "San Martino di Lupari": {
                "lat": 45.65,
                "lng": 11.85
            },
            "Novellara": {
                "lat": 44.85,
                "lng": 10.7333
            },
            "Noceto": {
                "lat": 44.8167,
                "lng": 10.1833
            },
            "Tempio Pausania": {
                "lat": 40.9,
                "lng": 9.1
            },
            "Villacidro": {
                "lat": 39.45,
                "lng": 8.7333
            },
            "Castelnuovo di Verona": {
                "lat": 45.4333,
                "lng": 10.7667
            },
            "Melilli": {
                "lat": 37.1833,
                "lng": 15.1167
            },
            "Casale sul Sile": {
                "lat": 45.6,
                "lng": 12.3333
            },
            "Vigodarzere": {
                "lat": 45.45,
                "lng": 11.8833
            },
            "Crispiano": {
                "lat": 40.6,
                "lng": 17.2333
            },
            "Latisana": {
                "lat": 45.7833,
                "lng": 13.1167
            },
            "Sarezzo": {
                "lat": 45.65,
                "lng": 10.2
            },
            "Quattro Castella": {
                "lat": 44.6333,
                "lng": 10.4667
            },
            "Pavona": {
                "lat": 41.7167,
                "lng": 12.6167
            },
            "Forlimpopoli": {
                "lat": 44.1833,
                "lng": 12.1333
            },
            "Capo d\u2019Orlando": {
                "lat": 38.15,
                "lng": 14.7333
            },
            "San Pietro Vernotico": {
                "lat": 40.4833,
                "lng": 18.05
            },
            "Lavello": {
                "lat": 41.05,
                "lng": 15.8
            },
            "Ficarazzi": {
                "lat": 38.0833,
                "lng": 13.4667
            },
            "Turi": {
                "lat": 40.9167,
                "lng": 17.0167
            },
            "Montesarchio": {
                "lat": 41.0611,
                "lng": 14.6333
            },
            "Montefiascone": {
                "lat": 42.5403,
                "lng": 12.0369
            },
            "Carpenedolo": {
                "lat": 45.3667,
                "lng": 10.4333
            },
            "Camponogara": {
                "lat": 45.3833,
                "lng": 12.0667
            },
            "Trebaseleghe": {
                "lat": 45.5833,
                "lng": 12.05
            },
            "Tezze sul Brenta": {
                "lat": 45.6833,
                "lng": 11.7
            },
            "Castiglion Fiorentino": {
                "lat": 43.3439,
                "lng": 11.9189
            },
            "Statte": {
                "lat": 40.5667,
                "lng": 17.2167
            },
            "Loreto": {
                "lat": 43.4389,
                "lng": 13.6086
            },
            "Monteprandone": {
                "lat": 42.9167,
                "lng": 13.8333
            },
            "Palombara Sabina": {
                "lat": 42.0667,
                "lng": 12.7667
            },
            "M\u00faggia": {
                "lat": 45.6,
                "lng": 13.7667
            },
            "Calcinato": {
                "lat": 45.45,
                "lng": 10.4167
            },
            "Lanuvio": {
                "lat": 41.6833,
                "lng": 12.7
            },
            "Cavallino": {
                "lat": 40.3167,
                "lng": 18.2
            },
            "San Pietro in Casale": {
                "lat": 44.7,
                "lng": 11.4
            },
            "Terrasini Favarotta": {
                "lat": 38.15,
                "lng": 13.0833
            },
            "Quartucciu": {
                "lat": 39.2528,
                "lng": 9.1778
            },
            "Spilamberto": {
                "lat": 44.5333,
                "lng": 11.0167
            },
            "Fontanafredda": {
                "lat": 45.9667,
                "lng": 12.5667
            },
            "Salzano": {
                "lat": 45.5333,
                "lng": 12.1167
            },
            "Sedriano": {
                "lat": 45.4833,
                "lng": 8.9667
            },
            "Granarolo del l\u2019Emilia": {
                "lat": 44.55,
                "lng": 11.45
            },
            "Villa San Giovanni": {
                "lat": 38.2167,
                "lng": 15.6333
            },
            "Cavarzere": {
                "lat": 45.137,
                "lng": 12.0817
            },
            "Calcinaia": {
                "lat": 43.6833,
                "lng": 10.6167
            },
            "Patti": {
                "lat": 38.1389,
                "lng": 14.9653
            },
            "Gricignano d\u2019Aversa": {
                "lat": 41.0,
                "lng": 14.1833
            },
            "Nicosia": {
                "lat": 37.75,
                "lng": 14.4
            },
            "Olgiate Olona": {
                "lat": 45.6333,
                "lng": 8.8833
            },
            "Cairo Montenotte": {
                "lat": 44.4,
                "lng": 8.2667
            },
            "Santo Stino di Livenza": {
                "lat": 45.7333,
                "lng": 12.6833
            },
            "San Biagio di Callalta": {
                "lat": 45.6833,
                "lng": 12.3833
            },
            "San Marco in Lamis": {
                "lat": 41.7167,
                "lng": 15.6333
            },
            "Castel Goffredo": {
                "lat": 45.2981,
                "lng": 10.475
            },
            "Apricena": {
                "lat": 41.7667,
                "lng": 15.4333
            },
            "Lipari": {
                "lat": 38.4667,
                "lng": 14.95
            },
            "Varazze": {
                "lat": 44.3667,
                "lng": 8.5833
            },
            "Chiampo": {
                "lat": 45.55,
                "lng": 11.2833
            },
            "Montegranaro": {
                "lat": 43.2333,
                "lng": 13.6333
            },
            "Borgo San Dalmazzo": {
                "lat": 44.3333,
                "lng": 7.4833
            },
            "Rionero in Vulture": {
                "lat": 40.9167,
                "lng": 15.6667
            },
            "Canegrate": {
                "lat": 45.5667,
                "lng": 8.9333
            },
            "Lana": {
                "lat": 46.6167,
                "lng": 11.15
            },
            "Fagnano Olona": {
                "lat": 45.6667,
                "lng": 8.8667
            },
            "Bagnolo Mella": {
                "lat": 45.4333,
                "lng": 10.1833
            },
            "Villa Literno": {
                "lat": 41.0167,
                "lng": 14.0667
            },
            "Campiglia Marittima": {
                "lat": 43.0667,
                "lng": 10.6167
            },
            "Grammichele": {
                "lat": 37.2147,
                "lng": 14.6364
            },
            "Calvizzano": {
                "lat": 40.9,
                "lng": 14.1833
            },
            "Orzinuovi": {
                "lat": 45.4033,
                "lng": 9.925
            },
            "Anzola dell\u2019Emilia": {
                "lat": 44.5472,
                "lng": 11.1956
            },
            "Avigliana": {
                "lat": 45.0833,
                "lng": 7.4
            },
            "Seravezza": {
                "lat": 44.0,
                "lng": 10.2333
            },
            "Porto Recanati": {
                "lat": 43.4322,
                "lng": 13.6647
            },
            "Piano di Sorrento": {
                "lat": 40.6333,
                "lng": 14.4167
            },
            "Arluno": {
                "lat": 45.5,
                "lng": 8.9333
            },
            "Pontecorvo": {
                "lat": 41.45,
                "lng": 13.6667
            },
            "Leonforte": {
                "lat": 37.6417,
                "lng": 14.3958
            },
            "Biassono": {
                "lat": 45.6333,
                "lng": 9.2667
            },
            "Lavagna": {
                "lat": 44.3167,
                "lng": 9.3333
            },
            "Rivarolo Canavese": {
                "lat": 45.3333,
                "lng": 7.7167
            },
            "Cicciano": {
                "lat": 40.9667,
                "lng": 14.5333
            },
            "Gavardo": {
                "lat": 45.5875,
                "lng": 10.4389
            },
            "Spresiano": {
                "lat": 45.7833,
                "lng": 12.25
            },
            "Sala Consilina": {
                "lat": 40.4,
                "lng": 15.6
            },
            "Morbegno": {
                "lat": 46.1333,
                "lng": 9.5667
            },
            "Rottofreno": {
                "lat": 45.05,
                "lng": 9.55
            },
            "Montecorvino Rovella": {
                "lat": 40.6959,
                "lng": 14.9754
            },
            "Russi": {
                "lat": 44.3833,
                "lng": 12.0333
            },
            "San Mauro Pascoli": {
                "lat": 44.1,
                "lng": 12.4167
            },
            "Pianiga": {
                "lat": 45.45,
                "lng": 12.0167
            },
            "Grumo Appula": {
                "lat": 41.0167,
                "lng": 16.7
            },
            "San Prisco": {
                "lat": 41.0833,
                "lng": 14.2833
            },
            "Motta Sant\u2019Anastasia": {
                "lat": 37.5,
                "lng": 14.9667
            },
            "Borgosesia": {
                "lat": 45.7167,
                "lng": 8.2667
            },
            "Ugento": {
                "lat": 39.9272,
                "lng": 18.1614
            },
            "Sinalunga": {
                "lat": 43.2167,
                "lng": 11.7333
            },
            "Solofra": {
                "lat": 40.8333,
                "lng": 14.85
            },
            "Rocca Priora": {
                "lat": 41.7833,
                "lng": 12.7667
            },
            "Occhiobello": {
                "lat": 44.9167,
                "lng": 11.5833
            },
            "Monte San Giovanni Campano": {
                "lat": 41.6333,
                "lng": 13.5167
            },
            "Raffadali": {
                "lat": 37.4047,
                "lng": 13.5339
            },
            "San Gennaro Vesuviano": {
                "lat": 40.8667,
                "lng": 14.5333
            },
            "Cecchina": {
                "lat": 41.7667,
                "lng": 12.6167
            },
            "Eraclea": {
                "lat": 45.5833,
                "lng": 12.6833
            },
            "Bernalda": {
                "lat": 40.4167,
                "lng": 16.6833
            },
            "Terranuova Bracciolini": {
                "lat": 43.55,
                "lng": 11.5833
            },
            "Trezzo sull\u2019Adda": {
                "lat": 45.6,
                "lng": 9.5167
            },
            "Cinisi": {
                "lat": 38.1667,
                "lng": 13.1
            },
            "Lauria Inferiore": {
                "lat": 40.05,
                "lng": 15.8333
            },
            "San Severino Marche": {
                "lat": 43.2333,
                "lng": 13.1833
            },
            "Altavilla Vicentina": {
                "lat": 45.5167,
                "lng": 11.45
            },
            "Sant\u2019Agata di Militello": {
                "lat": 38.0667,
                "lng": 14.6333
            },
            "Olgiate Comasco": {
                "lat": 45.7833,
                "lng": 8.9667
            },
            "Monte Compatri": {
                "lat": 41.8081,
                "lng": 12.7372
            },
            "Sant\u2019Ambrogio di Valpolicella": {
                "lat": 45.5167,
                "lng": 10.8333
            },
            "Monte di Procida": {
                "lat": 40.8,
                "lng": 14.05
            },
            "Lizzanello": {
                "lat": 40.3051,
                "lng": 18.2213
            },
            "Locri": {
                "lat": 38.216,
                "lng": 16.2288
            },
            "Spilimbergo": {
                "lat": 46.1281,
                "lng": 12.885
            },
            "Bibbiena": {
                "lat": 43.7,
                "lng": 11.8167
            },
            "Vecchiano": {
                "lat": 43.7833,
                "lng": 10.3833
            },
            "Baranzate": {
                "lat": 45.5167,
                "lng": 9.1
            },
            "Camposampiero": {
                "lat": 45.5667,
                "lng": 11.9333
            },
            "Menfi": {
                "lat": 37.6,
                "lng": 12.9667
            },
            "Barrafranca": {
                "lat": 37.3667,
                "lng": 14.2
            },
            "Portoferraio": {
                "lat": 42.8167,
                "lng": 10.3167
            },
            "Susegana": {
                "lat": 45.85,
                "lng": 12.25
            },
            "Tortoreto": {
                "lat": 42.8,
                "lng": 13.9167
            },
            "Ronchi dei Legionari": {
                "lat": 45.8333,
                "lng": 13.5
            },
            "Portomaggiore": {
                "lat": 44.7,
                "lng": 11.8
            },
            "Fiume Veneto": {
                "lat": 45.9333,
                "lng": 12.7333
            },
            "Borgaro Torinese": {
                "lat": 45.15,
                "lng": 7.65
            },
            "Bitetto": {
                "lat": 41.0333,
                "lng": 16.75
            },
            "Francofonte": {
                "lat": 37.2333,
                "lng": 14.8833
            },
            "Serravalle Pistoiese": {
                "lat": 43.9,
                "lng": 10.8333
            },
            "Mestrino": {
                "lat": 45.45,
                "lng": 11.7667
            },
            "Carmiano": {
                "lat": 40.3458,
                "lng": 18.0458
            },
            "Torri di Quartesolo": {
                "lat": 45.5167,
                "lng": 11.6167
            },
            "Cornedo Vicentino": {
                "lat": 45.6167,
                "lng": 11.35
            },
            "Ponte San Pietro": {
                "lat": 45.7,
                "lng": 9.5833
            },
            "Marotta": {
                "lat": 43.7697,
                "lng": 13.1369
            },
            "Zero Branco": {
                "lat": 45.6,
                "lng": 12.1667
            },
            "Casteldaccia": {
                "lat": 38.0542,
                "lng": 13.5306
            },
            "Castenedolo": {
                "lat": 45.5333,
                "lng": 10.2333
            },
            "Isola della Scala": {
                "lat": 45.2667,
                "lng": 11.1333
            },
            "Amelia": {
                "lat": 42.55,
                "lng": 12.4167
            },
            "Pieve di Soligo": {
                "lat": 45.8833,
                "lng": 12.1667
            },
            "Maniago": {
                "lat": 46.1667,
                "lng": 12.7167
            },
            "Giffoni Valle Piana": {
                "lat": 40.7167,
                "lng": 14.9333
            },
            "Taviano": {
                "lat": 39.9833,
                "lng": 18.0833
            },
            "Mercogliano": {
                "lat": 40.9258,
                "lng": 14.7428
            },
            "San Gregorio di Catania": {
                "lat": 37.5667,
                "lng": 15.1167
            },
            "Alfonsine": {
                "lat": 44.5,
                "lng": 12.05
            },
            "Lendinara": {
                "lat": 45.085,
                "lng": 11.6006
            },
            "Stradella": {
                "lat": 45.0833,
                "lng": 9.3
            },
            "Casi\u00e8r": {
                "lat": 45.65,
                "lng": 12.3
            },
            "San Michele al Tagliamento": {
                "lat": 45.7636,
                "lng": 12.9953
            },
            "Montegrotto Terme": {
                "lat": 45.3333,
                "lng": 11.7833
            },
            "Campobello di Mazara": {
                "lat": 37.6333,
                "lng": 12.75
            },
            "Musile di Piave": {
                "lat": 45.6167,
                "lng": 12.5667
            },
            "Lonate Pozzolo": {
                "lat": 45.6,
                "lng": 8.75
            },
            "Gardone Val Trompia": {
                "lat": 45.6833,
                "lng": 10.1833
            },
            "Monte Sant\u2019Angelo": {
                "lat": 41.7,
                "lng": 15.9667
            },
            "Taurisano": {
                "lat": 39.95,
                "lng": 18.1667
            },
            "Capaci": {
                "lat": 38.1667,
                "lng": 13.2333
            },
            "Bitritto": {
                "lat": 41.05,
                "lng": 16.8333
            },
            "Caldogno": {
                "lat": 45.6,
                "lng": 11.4944
            },
            "Teano": {
                "lat": 41.25,
                "lng": 14.0667
            },
            "Trecastagni": {
                "lat": 37.6167,
                "lng": 15.0833
            },
            "Sant\u2019Ilario d\u2019Enza": {
                "lat": 44.7667,
                "lng": 10.45
            },
            "Priolo Gargallo": {
                "lat": 37.1667,
                "lng": 15.1833
            },
            "Scalea": {
                "lat": 39.8147,
                "lng": 15.7906
            },
            "Inzago": {
                "lat": 45.5333,
                "lng": 9.4833
            },
            "Monteforte Irpino": {
                "lat": 40.8928,
                "lng": 14.7194
            },
            "Montechiarugolo": {
                "lat": 44.7,
                "lng": 10.4167
            },
            "Nembro": {
                "lat": 45.75,
                "lng": 9.7667
            },
            "Carbonera": {
                "lat": 45.6833,
                "lng": 12.2833
            },
            "Siniscola": {
                "lat": 40.5833,
                "lng": 9.7
            },
            "Montopoli in Val d\u2019Arno": {
                "lat": 43.6667,
                "lng": 10.75
            },
            "Penne": {
                "lat": 42.45,
                "lng": 13.9167
            },
            "Margherita di Savoia": {
                "lat": 41.3667,
                "lng": 16.15
            },
            "Creazzo": {
                "lat": 45.5333,
                "lng": 11.4833
            },
            "Finale Ligure": {
                "lat": 44.1714,
                "lng": 8.3444
            },
            "Pulsano": {
                "lat": 40.3833,
                "lng": 17.35
            },
            "Cerese": {
                "lat": 45.05,
                "lng": 10.75
            },
            "Cisternino": {
                "lat": 40.7333,
                "lng": 17.4333
            },
            "Valmadrera": {
                "lat": 45.85,
                "lng": 9.3667
            },
            "Camisano Vicentino": {
                "lat": 45.5167,
                "lng": 11.7167
            },
            "Caorle": {
                "lat": 45.6,
                "lng": 12.8833
            },
            "Castelvetro di Modena": {
                "lat": 44.5,
                "lng": 10.95
            },
            "Paullo": {
                "lat": 45.4167,
                "lng": 9.4
            },
            "Piazzola sul Brenta": {
                "lat": 45.5333,
                "lng": 11.7667
            },
            "Arenzano": {
                "lat": 44.4042,
                "lng": 8.6806
            },
            "Codigoro": {
                "lat": 44.8333,
                "lng": 12.1167
            },
            "Cologno al Serio": {
                "lat": 45.5833,
                "lng": 9.7
            },
            "San Giustino": {
                "lat": 43.55,
                "lng": 12.1833
            },
            "Bertinoro": {
                "lat": 44.15,
                "lng": 12.1333
            },
            "Santa Flavia": {
                "lat": 38.0833,
                "lng": 13.5333
            },
            "Matino": {
                "lat": 40.0333,
                "lng": 18.1333
            },
            "Guspini": {
                "lat": 39.5333,
                "lng": 8.6333
            },
            "Santa Croce Camerina": {
                "lat": 36.8333,
                "lng": 14.5167
            },
            "Sesto Calende": {
                "lat": 45.7333,
                "lng": 8.6333
            },
            "Campagnano di Roma": {
                "lat": 42.1333,
                "lng": 12.3833
            },
            "Peschiera del Garda": {
                "lat": 45.4333,
                "lng": 10.6833
            },
            "Tortol\u00ec": {
                "lat": 39.9333,
                "lng": 9.65
            },
            "Cepagatti": {
                "lat": 42.3667,
                "lng": 14.0667
            },
            "Mazzarino": {
                "lat": 37.3,
                "lng": 14.2
            },
            "Pellezzano": {
                "lat": 40.7333,
                "lng": 14.7667
            },
            "Barberino di Mugello": {
                "lat": 44.0014,
                "lng": 11.2392
            },
            "Venosa": {
                "lat": 40.9618,
                "lng": 15.8148
            },
            "Castel Mella": {
                "lat": 45.5,
                "lng": 10.15
            },
            "Belmonte Mezzagno": {
                "lat": 38.05,
                "lng": 13.3833
            },
            "Venafro": {
                "lat": 41.4844,
                "lng": 14.0458
            },
            "Cividale del Friuli": {
                "lat": 46.1,
                "lng": 13.4333
            },
            "San Valentino Torio": {
                "lat": 40.7833,
                "lng": 14.6
            },
            "Castano Primo": {
                "lat": 45.55,
                "lng": 8.7667
            },
            "Capena": {
                "lat": 42.1403,
                "lng": 12.5403
            },
            "Cornate d\u2019Adda": {
                "lat": 45.65,
                "lng": 9.4667
            },
            "Paceco": {
                "lat": 37.9833,
                "lng": 12.55
            },
            "Ovada": {
                "lat": 44.6361,
                "lng": 8.6417
            },
            "Cave": {
                "lat": 41.8167,
                "lng": 12.9333
            },
            "Langhirano": {
                "lat": 44.6167,
                "lng": 10.2667
            },
            "Racale": {
                "lat": 39.9667,
                "lng": 18.1
            },
            "Isola del Liri": {
                "lat": 41.6794,
                "lng": 13.5728
            },
            "Martinengo": {
                "lat": 45.5667,
                "lng": 9.7667
            },
            "Motta di Livenza": {
                "lat": 45.7797,
                "lng": 12.6086
            },
            "Monte San Pietro": {
                "lat": 44.4667,
                "lng": 11.2
            },
            "Grezzana": {
                "lat": 45.5167,
                "lng": 11.0167
            },
            "Campolongo Maggiore": {
                "lat": 45.3333,
                "lng": 12.05
            },
            "Botticino Sera": {
                "lat": 45.5333,
                "lng": 10.2333
            },
            "Loano": {
                "lat": 44.1167,
                "lng": 8.25
            },
            "San Felice sul Panaro": {
                "lat": 44.8333,
                "lng": 11.1333
            },
            "Trevignano": {
                "lat": 45.7333,
                "lng": 12.0667
            },
            "Medesano": {
                "lat": 44.75,
                "lng": 10.1333
            },
            "Gambettola": {
                "lat": 44.1167,
                "lng": 12.3333
            },
            "Cerreto Guidi": {
                "lat": 43.7667,
                "lng": 10.8833
            },
            "Cazzago San Martino": {
                "lat": 45.5817,
                "lng": 10.0258
            },
            "Cameri": {
                "lat": 45.5,
                "lng": 8.65
            },
            "Aulla": {
                "lat": 44.2167,
                "lng": 9.9667
            },
            "Sant\u2019Ant\u00ecoco": {
                "lat": 39.035,
                "lng": 8.4125
            },
            "Avigliano": {
                "lat": 40.7314,
                "lng": 15.7166
            },
            "Cadelbosco di Sopra": {
                "lat": 44.7667,
                "lng": 10.6
            },
            "Massa Lombarda": {
                "lat": 44.45,
                "lng": 11.8167
            },
            "La Maddalena": {
                "lat": 41.2167,
                "lng": 9.4
            },
            "Trofarello": {
                "lat": 44.9833,
                "lng": 7.7333
            },
            "Acate": {
                "lat": 37.0339,
                "lng": 14.4942
            },
            "Trabia": {
                "lat": 38.0,
                "lng": 13.65
            },
            "Nave": {
                "lat": 45.585,
                "lng": 10.2833
            },
            "Usmate Velate": {
                "lat": 45.65,
                "lng": 9.35
            },
            "Montale": {
                "lat": 43.9333,
                "lng": 11.0167
            },
            "Riesi": {
                "lat": 37.2833,
                "lng": 14.0833
            },
            "Taormina": {
                "lat": 37.8522,
                "lng": 15.2919
            },
            "Gemona del Friuli": {
                "lat": 46.2833,
                "lng": 13.1333
            },
            "Melito di Porto Salvo": {
                "lat": 37.9167,
                "lng": 15.7833
            },
            "Coriano": {
                "lat": 43.9667,
                "lng": 12.6
            },
            "Sal\u00f2": {
                "lat": 45.6083,
                "lng": 10.5167
            },
            "Cislago": {
                "lat": 45.65,
                "lng": 8.9667
            },
            "Montecchio Emilia": {
                "lat": 44.6833,
                "lng": 10.4333
            },
            "Atripalda": {
                "lat": 40.9167,
                "lng": 14.8256
            },
            "Cori": {
                "lat": 41.6445,
                "lng": 12.9127
            },
            "Corleone": {
                "lat": 37.8167,
                "lng": 13.3
            },
            "Itri": {
                "lat": 41.2833,
                "lng": 13.5333
            },
            "Bonate di Sopra": {
                "lat": 45.6833,
                "lng": 9.5667
            },
            "Ravanusa": {
                "lat": 37.2667,
                "lng": 13.9667
            },
            "Silea": {
                "lat": 45.6533,
                "lng": 12.2958
            },
            "Atessa": {
                "lat": 42.0667,
                "lng": 14.45
            },
            "Riano": {
                "lat": 42.0911,
                "lng": 12.5228
            },
            "Locate di Triulzi": {
                "lat": 45.35,
                "lng": 9.2167
            },
            "Castelli Calepio": {
                "lat": 45.6333,
                "lng": 9.9
            },
            "Castelnovo ne\u2019 Monti": {
                "lat": 44.4333,
                "lng": 10.4
            },
            "Sant\u2019Agata de\u2019 Goti": {
                "lat": 41.0833,
                "lng": 14.5
            },
            "Oppeano": {
                "lat": 45.3,
                "lng": 11.1833
            },
            "San Maurizio Canavese": {
                "lat": 45.2167,
                "lng": 7.6333
            },
            "Alberobello": {
                "lat": 40.7833,
                "lng": 17.2333
            },
            "Induno Olona": {
                "lat": 45.8522,
                "lng": 8.8386
            },
            "San Marzano sul Sarno": {
                "lat": 40.7697,
                "lng": 14.5947
            },
            "Vigasio": {
                "lat": 45.3167,
                "lng": 10.9333
            },
            "Concordia Sagittaria": {
                "lat": 45.7667,
                "lng": 12.85
            },
            "Isola Vicentina": {
                "lat": 45.6333,
                "lng": 11.45
            },
            "Ramacca": {
                "lat": 37.3833,
                "lng": 14.7
            },
            "Celano": {
                "lat": 42.0864,
                "lng": 13.5575
            },
            "Randazzo": {
                "lat": 37.8833,
                "lng": 14.95
            },
            "Poirino": {
                "lat": 44.9167,
                "lng": 7.85
            },
            "Bomporto": {
                "lat": 44.7333,
                "lng": 11.0333
            },
            "Bordighera": {
                "lat": 43.7833,
                "lng": 7.6667
            },
            "Arcola": {
                "lat": 44.1167,
                "lng": 9.9
            },
            "Busca": {
                "lat": 44.5167,
                "lng": 7.4667
            },
            "Bibbiano": {
                "lat": 44.6667,
                "lng": 10.4667
            },
            "San Felice Circeo": {
                "lat": 41.2833,
                "lng": 13.1333
            },
            "Rignano Flaminio": {
                "lat": 42.2,
                "lng": 12.4833
            },
            "Sedico": {
                "lat": 46.1167,
                "lng": 12.1
            },
            "Torre Santa Susanna": {
                "lat": 40.4667,
                "lng": 17.7333
            },
            "Mori": {
                "lat": 45.85,
                "lng": 10.9833
            },
            "Nizza Monferrato": {
                "lat": 44.7667,
                "lng": 8.35
            },
            "Procida": {
                "lat": 40.7583,
                "lng": 14.0167
            },
            "Fiuggi": {
                "lat": 41.8,
                "lng": 13.2167
            },
            "Novi di Modena": {
                "lat": 44.8833,
                "lng": 10.9
            },
            "Badia Polesine": {
                "lat": 45.1,
                "lng": 11.5
            },
            "Alassio": {
                "lat": 44.0,
                "lng": 8.1667
            },
            "Macerata Campania": {
                "lat": 41.0667,
                "lng": 14.2667
            },
            "Verucchio": {
                "lat": 43.9833,
                "lng": 12.4333
            },
            "Sermoneta": {
                "lat": 41.55,
                "lng": 12.9833
            },
            "Volpago del Montello": {
                "lat": 45.7833,
                "lng": 12.1167
            },
            "Goito": {
                "lat": 45.25,
                "lng": 10.6667
            },
            "Canelli": {
                "lat": 44.7225,
                "lng": 8.2936
            },
            "Montignoso": {
                "lat": 44.0167,
                "lng": 10.1667
            },
            "Capodrise": {
                "lat": 41.05,
                "lng": 14.3
            },
            "Atri": {
                "lat": 42.5833,
                "lng": 13.9833
            },
            "Melendugno": {
                "lat": 40.2667,
                "lng": 18.3333
            },
            "Monteriggioni": {
                "lat": 43.367,
                "lng": 11.218
            },
            "Salemi": {
                "lat": 37.8167,
                "lng": 12.8
            },
            "Valdobbiadene": {
                "lat": 45.9,
                "lng": 11.9167
            },
            "Quinto di Treviso": {
                "lat": 45.65,
                "lng": 12.1667
            },
            "Barano d\u2019Ischia": {
                "lat": 40.7167,
                "lng": 13.9167
            },
            "Conselve": {
                "lat": 45.2333,
                "lng": 11.8667
            },
            "Fino Mornasco": {
                "lat": 45.75,
                "lng": 9.0333
            },
            "Bucine": {
                "lat": 43.4833,
                "lng": 11.6167
            },
            "Mussomeli": {
                "lat": 37.5794,
                "lng": 13.7525
            },
            "Mandello del Lario": {
                "lat": 45.9167,
                "lng": 9.3167
            },
            "Polistena": {
                "lat": 38.4,
                "lng": 16.0667
            },
            "Vaiano": {
                "lat": 43.9667,
                "lng": 11.1167
            },
            "Lomazzo": {
                "lat": 45.7,
                "lng": 9.0333
            },
            "Asola": {
                "lat": 45.2167,
                "lng": 10.4167
            },
            "Urgnano": {
                "lat": 45.6,
                "lng": 9.6833
            },
            "Campi Salentina": {
                "lat": 40.4,
                "lng": 18.0167
            },
            "Meldola": {
                "lat": 44.1333,
                "lng": 12.05
            },
            "Poggio a Caiano": {
                "lat": 43.8167,
                "lng": 11.0667
            },
            "Sovicille": {
                "lat": 43.2833,
                "lng": 11.2333
            },
            "Santo Stefano di Magra": {
                "lat": 44.1667,
                "lng": 9.9167
            },
            "Altofonte": {
                "lat": 38.05,
                "lng": 13.3
            },
            "Cavriago": {
                "lat": 44.7,
                "lng": 10.5333
            },
            "Partanna": {
                "lat": 37.7167,
                "lng": 12.8833
            },
            "Ozieri": {
                "lat": 40.5833,
                "lng": 9.0
            },
            "Vigonovo": {
                "lat": 45.3833,
                "lng": 12.05
            },
            "Tolmezzo": {
                "lat": 46.4,
                "lng": 13.0167
            },
            "Castelletto sopra Ticino": {
                "lat": 45.7167,
                "lng": 8.6333
            },
            "Morrovalle": {
                "lat": 43.3167,
                "lng": 13.5833
            },
            "San Vendemiano": {
                "lat": 45.8914,
                "lng": 12.3389
            },
            "Boscotrecase": {
                "lat": 40.7833,
                "lng": 14.4667
            },
            "Trescore Balneario": {
                "lat": 45.7,
                "lng": 9.85
            },
            "Bagnolo in Piano": {
                "lat": 44.7667,
                "lng": 10.6833
            },
            "Cittanova": {
                "lat": 38.35,
                "lng": 16.0833
            },
            "Montemarciano": {
                "lat": 43.6333,
                "lng": 13.3167
            },
            "Sant\u2019Egidio alla Vibrata": {
                "lat": 42.8333,
                "lng": 13.7167
            },
            "Gambol\u00f2": {
                "lat": 45.25,
                "lng": 8.8667
            },
            "San Giorgio del Sannio": {
                "lat": 41.0674,
                "lng": 14.8552
            },
            "Terralba": {
                "lat": 39.7167,
                "lng": 8.6333
            },
            "Poggio Renatico": {
                "lat": 44.7667,
                "lng": 11.5
            },
            "Crosia": {
                "lat": 39.5667,
                "lng": 16.7667
            },
            "Lurate Caccivio": {
                "lat": 45.7667,
                "lng": 9.0
            },
            "Castellamonte": {
                "lat": 45.3667,
                "lng": 7.7167
            },
            "San Lorenzo della Costa": {
                "lat": 44.3333,
                "lng": 9.2167
            },
            "Broni": {
                "lat": 45.0667,
                "lng": 9.2667
            },
            "Argelato": {
                "lat": 44.5758,
                "lng": 11.3486
            },
            "Racconigi": {
                "lat": 44.7667,
                "lng": 7.6833
            },
            "Boves": {
                "lat": 44.3333,
                "lng": 7.55
            },
            "Sannicandro di Bari": {
                "lat": 41.0,
                "lng": 16.8
            },
            "Casaluce": {
                "lat": 41.0,
                "lng": 14.2
            },
            "Cingoli": {
                "lat": 43.3667,
                "lng": 13.2167
            },
            "Lizzano": {
                "lat": 40.3919,
                "lng": 17.4483
            },
            "Cesa": {
                "lat": 40.9667,
                "lng": 14.2333
            },
            "Savignano sul Panaro": {
                "lat": 44.4833,
                "lng": 11.0333
            },
            "Sorbolo": {
                "lat": 44.85,
                "lng": 10.45
            },
            "Traversetolo": {
                "lat": 44.6333,
                "lng": 10.3833
            },
            "Conselice": {
                "lat": 44.3333,
                "lng": 11.7
            },
            "Volterra": {
                "lat": 43.4,
                "lng": 10.8667
            },
            "Castel Bolognese": {
                "lat": 44.3167,
                "lng": 11.8
            },
            "Cuorgn\u00e8": {
                "lat": 45.3833,
                "lng": 7.65
            },
            "Castelfranco di Sopra": {
                "lat": 43.6236,
                "lng": 11.5583
            },
            "Vaprio d\u2019Adda": {
                "lat": 45.5833,
                "lng": 9.5333
            },
            "Garlasco": {
                "lat": 45.2,
                "lng": 8.9167
            },
            "Bisignano": {
                "lat": 39.5167,
                "lng": 16.2833
            },
            "Cherasco": {
                "lat": 44.65,
                "lng": 7.8667
            },
            "Legnaro": {
                "lat": 45.35,
                "lng": 11.9667
            },
            "Mareno di Piave": {
                "lat": 45.85,
                "lng": 12.35
            },
            "Dolianova": {
                "lat": 39.3833,
                "lng": 9.1833
            },
            "Deruta": {
                "lat": 42.9833,
                "lng": 12.4167
            },
            "Lerici": {
                "lat": 44.0764,
                "lng": 9.9111
            },
            "Barga": {
                "lat": 44.075,
                "lng": 10.4817
            },
            "Cavriglia": {
                "lat": 43.5167,
                "lng": 11.4833
            },
            "San Giovanni in Marignano": {
                "lat": 43.9333,
                "lng": 12.7167
            },
            "Siano": {
                "lat": 40.8025,
                "lng": 14.6944
            },
            "Bellinzago Novarese": {
                "lat": 45.5833,
                "lng": 8.6333
            },
            "Elmas": {
                "lat": 39.2667,
                "lng": 9.05
            },
            "Recco": {
                "lat": 44.3667,
                "lng": 9.15
            },
            "Piombino Dese": {
                "lat": 45.6,
                "lng": 11.9333
            },
            "Resana": {
                "lat": 45.6333,
                "lng": 11.95
            },
            "Magnago": {
                "lat": 45.5833,
                "lng": 8.8
            },
            "Cetraro": {
                "lat": 39.5,
                "lng": 15.95
            },
            "Capriolo": {
                "lat": 45.6389,
                "lng": 9.9339
            },
            "Cutro": {
                "lat": 39.0328,
                "lng": 16.9825
            },
            "Nepi": {
                "lat": 42.2417,
                "lng": 12.3444
            },
            "Cermenate": {
                "lat": 45.7,
                "lng": 9.0833
            },
            "San Giorgio di Piano": {
                "lat": 44.65,
                "lng": 11.3833
            },
            "Ruffano": {
                "lat": 39.9831,
                "lng": 18.2489
            },
            "Santa Teresa di Riva": {
                "lat": 37.9333,
                "lng": 15.35
            },
            "Macomer": {
                "lat": 40.2667,
                "lng": 8.7833
            },
            "Vanzago": {
                "lat": 45.5333,
                "lng": 9.0
            },
            "Gatteo": {
                "lat": 44.1,
                "lng": 12.3833
            },
            "Zafferana Etnea": {
                "lat": 37.6833,
                "lng": 15.1
            },
            "Gassino Torinese": {
                "lat": 45.1333,
                "lng": 7.8167
            },
            "Marano Vicentino": {
                "lat": 45.7,
                "lng": 11.4333
            },
            "San Pancrazio Salentino": {
                "lat": 40.4167,
                "lng": 17.8333
            },
            "Bagnara Calabra": {
                "lat": 38.2833,
                "lng": 15.8167
            },
            "Gaggiano": {
                "lat": 45.4,
                "lng": 9.0333
            },
            "Pasian di Prato": {
                "lat": 46.05,
                "lng": 13.2
            },
            "Vittuone": {
                "lat": 45.4833,
                "lng": 8.95
            },
            "Loria": {
                "lat": 45.7333,
                "lng": 11.8667
            },
            "Montescaglioso": {
                "lat": 40.55,
                "lng": 16.6667
            },
            "Giardini": {
                "lat": 37.8333,
                "lng": 15.2833
            },
            "Brugnera": {
                "lat": 45.9,
                "lng": 12.5333
            },
            "Castelleone": {
                "lat": 45.3,
                "lng": 9.7667
            },
            "Inverigo": {
                "lat": 45.7333,
                "lng": 9.2167
            },
            "Castrolibero": {
                "lat": 39.3167,
                "lng": 16.2
            },
            "Vignate": {
                "lat": 45.5,
                "lng": 9.3667
            },
            "Matelica": {
                "lat": 43.2566,
                "lng": 13.0096
            },
            "Reggiolo": {
                "lat": 44.9167,
                "lng": 10.8167
            },
            "Felino": {
                "lat": 44.6936,
                "lng": 10.242
            },
            "Lavis": {
                "lat": 46.1333,
                "lng": 11.1167
            },
            "Maserada sul Piave": {
                "lat": 45.75,
                "lng": 12.3167
            },
            "Santa Lucia di Piave": {
                "lat": 45.85,
                "lng": 12.2833
            },
            "Gavirate": {
                "lat": 45.85,
                "lng": 8.7167
            },
            "Malalbergo": {
                "lat": 44.7167,
                "lng": 11.5333
            },
            "Maser\u00e0 di Padova": {
                "lat": 45.3167,
                "lng": 11.8667
            },
            "Istrana": {
                "lat": 45.6833,
                "lng": 12.1
            },
            "Carignano": {
                "lat": 44.9,
                "lng": 7.6833
            },
            "Mendicino": {
                "lat": 39.2628,
                "lng": 16.1945
            },
            "Fiumefreddo di Sicilia": {
                "lat": 37.8,
                "lng": 15.2
            },
            "Mosciano Sant\u2019Angelo": {
                "lat": 42.75,
                "lng": 13.8833
            },
            "Orte": {
                "lat": 42.4603,
                "lng": 12.3864
            },
            "Oggiono": {
                "lat": 45.7833,
                "lng": 9.35
            },
            "Campobello di Licata": {
                "lat": 37.25,
                "lng": 13.9167
            },
            "Lacchiarella": {
                "lat": 45.3167,
                "lng": 9.1333
            },
            "Borgosatollo": {
                "lat": 45.4761,
                "lng": 10.24
            },
            "Fossombrone": {
                "lat": 43.7,
                "lng": 12.8167
            },
            "Podenzano": {
                "lat": 44.95,
                "lng": 9.6833
            },
            "Monteroni d\u2019Arbia": {
                "lat": 43.2333,
                "lng": 11.4167
            },
            "Villa Castelli": {
                "lat": 40.5833,
                "lng": 17.4833
            },
            "Porto Tolle": {
                "lat": 44.95,
                "lng": 12.3167
            },
            "Monticello Conte Otto": {
                "lat": 45.6,
                "lng": 11.5833
            },
            "Foiano della Chiana": {
                "lat": 43.25,
                "lng": 11.8167
            },
            "Treia": {
                "lat": 43.3111,
                "lng": 13.3125
            },
            "Venturina": {
                "lat": 43.0278,
                "lng": 10.6033
            },
            "Druento": {
                "lat": 45.1333,
                "lng": 7.5833
            },
            "Iseo": {
                "lat": 45.65,
                "lng": 10.05
            },
            "Borgoricco": {
                "lat": 45.5167,
                "lng": 11.9667
            },
            "Segni": {
                "lat": 41.6833,
                "lng": 13.0167
            },
            "Noventa Vicentina": {
                "lat": 45.2833,
                "lng": 11.5333
            },
            "Aviano": {
                "lat": 46.0667,
                "lng": 12.5833
            },
            "Colorno": {
                "lat": 44.9333,
                "lng": 10.3833
            },
            "Montagnana": {
                "lat": 45.2333,
                "lng": 11.4611
            },
            "Monteforte d\u2019Alpone": {
                "lat": 45.4167,
                "lng": 11.2833
            },
            "Castelnuovo Berardenga": {
                "lat": 43.3474,
                "lng": 11.5044
            },
            "Filottrano": {
                "lat": 43.4333,
                "lng": 13.35
            },
            "Asolo": {
                "lat": 45.8,
                "lng": 11.9167
            },
            "Belvedere Marittimo": {
                "lat": 39.6167,
                "lng": 15.8667
            },
            "San Marzano di San Giuseppe": {
                "lat": 40.45,
                "lng": 17.5
            },
            "Tarcento": {
                "lat": 46.2167,
                "lng": 13.2167
            },
            "Minerbio": {
                "lat": 44.6167,
                "lng": 11.4833
            },
            "Teolo": {
                "lat": 45.35,
                "lng": 11.6667
            },
            "Sorisole": {
                "lat": 45.7333,
                "lng": 9.6667
            },
            "Viagrande": {
                "lat": 37.6167,
                "lng": 15.1
            },
            "Striano": {
                "lat": 40.8167,
                "lng": 14.5667
            },
            "Pandino": {
                "lat": 45.4,
                "lng": 9.55
            },
            "Aradeo": {
                "lat": 40.1333,
                "lng": 18.1333
            },
            "Casorate Primo": {
                "lat": 45.3167,
                "lng": 9.0167
            },
            "Tirano": {
                "lat": 46.2164,
                "lng": 10.1689
            },
            "Missaglia": {
                "lat": 45.7,
                "lng": 9.3333
            },
            "Albinea": {
                "lat": 44.6167,
                "lng": 10.6
            },
            "Soresina": {
                "lat": 45.3,
                "lng": 9.85
            },
            "Porto Santo Stefano": {
                "lat": 42.4353,
                "lng": 11.1175
            },
            "Ala": {
                "lat": 45.7583,
                "lng": 11.0056
            },
            "Uta": {
                "lat": 39.2883,
                "lng": 8.9576
            },
            "Porcari": {
                "lat": 43.8333,
                "lng": 10.6167
            },
            "La Loggia": {
                "lat": 44.9667,
                "lng": 7.6667
            },
            "Costa Volpino": {
                "lat": 45.8306,
                "lng": 10.0992
            },
            "Gessate": {
                "lat": 45.55,
                "lng": 9.4333
            },
            "Luzzi": {
                "lat": 39.45,
                "lng": 16.2833
            },
            "Aragona": {
                "lat": 37.4019,
                "lng": 13.6183
            },
            "Pizzo": {
                "lat": 38.7333,
                "lng": 16.1667
            },
            "Borgo a Buggiano": {
                "lat": 43.8833,
                "lng": 10.7333
            },
            "Sant\u2019Angelo in Lizzola": {
                "lat": 43.8333,
                "lng": 12.8
            },
            "Mozzate": {
                "lat": 45.6833,
                "lng": 8.95
            },
            "Coccaglio": {
                "lat": 45.5667,
                "lng": 9.9833
            },
            "Castagneto Carducci": {
                "lat": 43.1667,
                "lng": 10.6
            },
            "Civitella in Val di Chiana": {
                "lat": 43.4167,
                "lng": 11.7167
            },
            "Roccastrada": {
                "lat": 43.0,
                "lng": 11.1667
            },
            "Parabita": {
                "lat": 40.05,
                "lng": 18.1333
            },
            "Folignano": {
                "lat": 42.8167,
                "lng": 13.6333
            },
            "C\u00e0bras": {
                "lat": 39.9333,
                "lng": 8.5333
            },
            "Montalto di Castro": {
                "lat": 42.35,
                "lng": 11.6
            },
            "Cutrofiano": {
                "lat": 40.1167,
                "lng": 18.2
            },
            "Ponte Buggianese": {
                "lat": 43.85,
                "lng": 10.75
            },
            "Besozzo": {
                "lat": 45.85,
                "lng": 8.6667
            },
            "Roccapiemonte": {
                "lat": 40.75,
                "lng": 14.6833
            },
            "Flero": {
                "lat": 45.4667,
                "lng": 10.1833
            },
            "Caprino Veronese": {
                "lat": 45.6,
                "lng": 10.8
            },
            "Gonzaga": {
                "lat": 44.95,
                "lng": 10.8167
            },
            "Roverbella": {
                "lat": 45.2667,
                "lng": 10.7667
            },
            "Torre Boldone": {
                "lat": 45.7167,
                "lng": 9.7
            },
            "Lequile": {
                "lat": 40.3,
                "lng": 18.1333
            },
            "Zanica": {
                "lat": 45.6333,
                "lng": 9.6833
            },
            "Brandizzo": {
                "lat": 45.1833,
                "lng": 7.8333
            },
            "Castellabate": {
                "lat": 40.2789,
                "lng": 14.9528
            },
            "Triuggio": {
                "lat": 45.6667,
                "lng": 9.2667
            },
            "Altavilla Milicia": {
                "lat": 38.05,
                "lng": 13.55
            },
            "San Sebastiano al Vesuvio": {
                "lat": 40.8333,
                "lng": 14.3667
            },
            "Serramazzoni": {
                "lat": 44.4167,
                "lng": 10.8
            },
            "Bovalino Marina": {
                "lat": 38.15,
                "lng": 16.1667
            },
            "Vergiate": {
                "lat": 45.7167,
                "lng": 8.7
            },
            "Sant\u2019Agnello": {
                "lat": 40.6333,
                "lng": 14.4
            },
            "Martano": {
                "lat": 40.2,
                "lng": 18.3
            },
            "Trissino": {
                "lat": 45.5667,
                "lng": 11.3667
            },
            "Soverato Marina": {
                "lat": 38.6833,
                "lng": 16.55
            },
            "Petilia Policastro": {
                "lat": 39.1139,
                "lng": 16.7875
            },
            "Aurisina": {
                "lat": 45.7333,
                "lng": 13.75
            },
            "Bientina": {
                "lat": 43.7167,
                "lng": 10.6167
            },
            "Serramanna": {
                "lat": 39.4333,
                "lng": 8.9167
            },
            "Monte Porzio Catone": {
                "lat": 41.8167,
                "lng": 12.7167
            },
            "Trebisacce": {
                "lat": 39.8667,
                "lng": 16.5333
            },
            "Castelnuovo di Porto": {
                "lat": 42.1333,
                "lng": 12.5
            },
            "Castel Gandolfo": {
                "lat": 41.7469,
                "lng": 12.6519
            },
            "Vicopisano": {
                "lat": 43.6833,
                "lng": 10.5833
            },
            "Brembate": {
                "lat": 45.6,
                "lng": 9.55
            },
            "Troina": {
                "lat": 37.7833,
                "lng": 14.6
            },
            "Monte San Savino": {
                "lat": 43.3333,
                "lng": 11.7333
            },
            "Zogno": {
                "lat": 45.8,
                "lng": 9.6667
            },
            "Clusone": {
                "lat": 45.8833,
                "lng": 9.95
            },
            "Santa Margherita Ligure": {
                "lat": 44.3333,
                "lng": 9.2167
            },
            "Campogalliano": {
                "lat": 44.6833,
                "lng": 10.85
            },
            "Rignano sull\u2019Arno": {
                "lat": 43.7237,
                "lng": 11.4507
            }
        }
    },
    "EC": {
        "name": "Ecuador",
        "cities": {
            "Guayaquil": {
                "lat": -2.19,
                "lng": -79.8875
            },
            "Quito": {
                "lat": -0.22,
                "lng": -78.5125
            },
            "Cuenca": {
                "lat": -2.8974,
                "lng": -79.0045
            },
            "Santo Domingo de los Colorados": {
                "lat": -0.2542,
                "lng": -79.1719
            },
            "Eloy Alfaro": {
                "lat": -2.1733,
                "lng": -79.8311
            },
            "Machala": {
                "lat": -3.2667,
                "lng": -79.9667
            },
            "Manta": {
                "lat": -0.95,
                "lng": -80.7162
            },
            "Portoviejo": {
                "lat": -1.0561,
                "lng": -80.4553
            },
            "Loja": {
                "lat": -3.9833,
                "lng": -79.2
            },
            "Quevedo": {
                "lat": -1.0333,
                "lng": -79.45
            },
            "Ambato": {
                "lat": -1.2417,
                "lng": -78.6197
            },
            "Riobamba": {
                "lat": -1.6731,
                "lng": -78.6483
            },
            "Daule": {
                "lat": -1.8667,
                "lng": -79.9833
            },
            "Milagro": {
                "lat": -2.1347,
                "lng": -79.5872
            },
            "Ibarra": {
                "lat": 0.3627,
                "lng": -78.1307
            },
            "Esmeraldas": {
                "lat": 0.9667,
                "lng": -79.6528
            },
            "La Libertad": {
                "lat": -2.2333,
                "lng": -80.9
            },
            "Babahoyo": {
                "lat": -1.8167,
                "lng": -79.5167
            },
            "Sangolqu\u00ed": {
                "lat": -0.3344,
                "lng": -78.4475
            },
            "Latacunga": {
                "lat": -0.9319,
                "lng": -78.6161
            },
            "Samborond\u00f3n": {
                "lat": -2.1833,
                "lng": -79.8833
            },
            "Montecristi": {
                "lat": -1.05,
                "lng": -80.6667
            },
            "Pasaje": {
                "lat": -3.3269,
                "lng": -79.8049
            },
            "Santa Rosa": {
                "lat": -3.4522,
                "lng": -79.9617
            },
            "Tulc\u00e1n": {
                "lat": 0.8117,
                "lng": -77.7186
            },
            "Huaquillas": {
                "lat": -3.4814,
                "lng": -80.2432
            },
            "Nueva Loja": {
                "lat": 0.0847,
                "lng": -76.8828
            },
            "Chone": {
                "lat": -0.6833,
                "lng": -80.1
            },
            "Santa Elena": {
                "lat": -2.2267,
                "lng": -80.8583
            },
            "Puerto Francisco de Orellana": {
                "lat": -0.4625,
                "lng": -76.9842
            },
            "Playas": {
                "lat": -2.63,
                "lng": -80.39
            },
            "San Jacinto de Buena Fe": {
                "lat": -0.8986,
                "lng": -79.4889
            },
            "Jipijapa": {
                "lat": -1.3486,
                "lng": -80.5786
            },
            "Cayambe": {
                "lat": 0.0439,
                "lng": -78.1561
            },
            "Velasco Ibarra": {
                "lat": -1.0439,
                "lng": -79.6383
            },
            "Otavalo": {
                "lat": 0.2303,
                "lng": -78.2625
            },
            "Ventanas": {
                "lat": -1.45,
                "lng": -79.47
            },
            "La Troncal": {
                "lat": -2.4,
                "lng": -79.33
            },
            "Naranjal": {
                "lat": -2.6736,
                "lng": -79.6183
            },
            "Azogues": {
                "lat": -2.7333,
                "lng": -78.8333
            },
            "La Concordia": {
                "lat": -0.0067,
                "lng": -79.3958
            },
            "Salinas": {
                "lat": -2.2167,
                "lng": -80.9501
            },
            "Vinces": {
                "lat": -1.55,
                "lng": -79.7333
            },
            "Naranjito": {
                "lat": -2.1667,
                "lng": -79.4653
            },
            "Puyo": {
                "lat": -1.4861,
                "lng": -78.0028
            },
            "Balzar": {
                "lat": -1.36,
                "lng": -79.9
            },
            "La Man\u00e1": {
                "lat": -0.93,
                "lng": -79.22
            },
            "Rosa Zarate": {
                "lat": 0.33,
                "lng": -79.48
            },
            "Guaranda": {
                "lat": -1.6,
                "lng": -79.0
            },
            "Tena": {
                "lat": -0.989,
                "lng": -77.8159
            },
            "San Lorenzo de Esmeraldas": {
                "lat": 1.2864,
                "lng": -78.8353
            },
            "Jaramij\u00f3": {
                "lat": -0.9487,
                "lng": -80.6363
            },
            "Pedernales": {
                "lat": 0.07,
                "lng": -80.055
            },
            "Catamayo": {
                "lat": -3.9833,
                "lng": -79.35
            },
            "Atuntaqui": {
                "lat": 0.3317,
                "lng": -78.2189
            },
            "Pedro Carbo": {
                "lat": -1.8179,
                "lng": -80.233
            },
            "Machachi": {
                "lat": -0.51,
                "lng": -78.57
            },
            "Valencia": {
                "lat": -0.9525,
                "lng": -79.3531
            },
            "Yaguachi Nuevo": {
                "lat": -2.12,
                "lng": -79.69
            },
            "Camilo Ponce Enr\u00edquez": {
                "lat": -3.05,
                "lng": -79.7333
            },
            "Macas": {
                "lat": -2.3667,
                "lng": -78.1333
            },
            "Bah\u00eda de Car\u00e1quez": {
                "lat": -0.5995,
                "lng": -80.4245
            },
            "Guabo": {
                "lat": -3.2388,
                "lng": -79.8292
            },
            "Puerto Quito": {
                "lat": 0.1272,
                "lng": -79.2531
            },
            "Calceta": {
                "lat": -0.84,
                "lng": -80.17
            },
            "Atacames": {
                "lat": 0.8667,
                "lng": -79.8333
            },
            "Valle": {
                "lat": -2.95,
                "lng": -78.9833
            },
            "Pi\u00f1as": {
                "lat": -3.6806,
                "lng": -79.6806
            },
            "Zamora": {
                "lat": -4.0692,
                "lng": -78.9567
            },
            "San Antonio de Ibarra": {
                "lat": 0.3627,
                "lng": -78.1307
            },
            "San Miguel de Salcedo": {
                "lat": -1.05,
                "lng": -78.5833
            },
            "Lomas de Sargentillo": {
                "lat": -1.8833,
                "lng": -80.0833
            },
            "Montalvo": {
                "lat": -1.79,
                "lng": -79.2875
            },
            "Pujil\u00ed": {
                "lat": -0.95,
                "lng": -78.69
            },
            "San Gabriel": {
                "lat": 0.5983,
                "lng": -77.8353
            },
            "Ba\u00f1os": {
                "lat": -2.9,
                "lng": -79.0667
            },
            "Ricaurte": {
                "lat": -2.8667,
                "lng": -78.9333
            },
            "Yantzaza": {
                "lat": -3.85,
                "lng": -78.75
            },
            "Cariamanga": {
                "lat": -4.32,
                "lng": -79.56
            },
            "Bal\u00e1o": {
                "lat": -2.91,
                "lng": -79.81
            },
            "Puerto Ayora": {
                "lat": -0.75,
                "lng": -90.3167
            },
            "Sinincay": {
                "lat": -2.8333,
                "lng": -79.0
            },
            "Puerto L\u00f3pez": {
                "lat": -1.5589,
                "lng": -80.8106
            },
            "Macar\u00e1": {
                "lat": -4.3833,
                "lng": -79.95
            },
            "Zumbagua": {
                "lat": -0.9558,
                "lng": -78.9017
            },
            "Cumand\u00e1": {
                "lat": -2.2058,
                "lng": -79.1339
            },
            "Pelileo": {
                "lat": -1.3306,
                "lng": -78.5428
            },
            "Tosagua": {
                "lat": -0.78,
                "lng": -80.26
            },
            "Santa Cruz": {
                "lat": -0.5333,
                "lng": -90.35
            },
            "Suc\u00faa": {
                "lat": -2.46,
                "lng": -78.17
            },
            "Rocafuerte": {
                "lat": -0.9236,
                "lng": -80.4495
            },
            "Cotacachi": {
                "lat": 0.3,
                "lng": -78.2667
            },
            "Palestina": {
                "lat": -1.6263,
                "lng": -79.977
            },
            "Piedrahita": {
                "lat": -1.9167,
                "lng": -80.0115
            },
            "Zaruma": {
                "lat": -3.6833,
                "lng": -79.6
            },
            "Saquisil\u00ed": {
                "lat": -0.83,
                "lng": -78.67
            },
            "San Vicente": {
                "lat": -1.9,
                "lng": -79.2333
            },
            "P\u00edllaro": {
                "lat": -1.1667,
                "lng": -78.5333
            },
            "Gualaquiza": {
                "lat": -3.38,
                "lng": -78.58
            },
            "Guano": {
                "lat": -1.5833,
                "lng": -78.6333
            },
            "San Miguel": {
                "lat": -1.7,
                "lng": -79.0333
            },
            "Sim\u00f3n Bol\u00edvar": {
                "lat": -2.0025,
                "lng": -79.4856
            },
            "Puerto Baquerizo Moreno": {
                "lat": -0.9025,
                "lng": -89.6092
            },
            "Alaus\u00ed": {
                "lat": -2.19,
                "lng": -78.85
            },
            "Muisne": {
                "lat": 0.6108,
                "lng": -80.0186
            },
            "Valdez": {
                "lat": 1.25,
                "lng": -79.0
            },
            "Puerto Villamil": {
                "lat": -0.9568,
                "lng": -90.9672
            },
            "Yaupi": {
                "lat": -2.8379,
                "lng": -77.9357
            }
        }
    },
    "SO": {
        "name": "Somalia",
        "cities": {
            "Mogadishu": {
                "lat": 2.0392,
                "lng": 45.3419
            },
            "Kismaayo": {
                "lat": -0.3581,
                "lng": 42.5453
            },
            "Hargeysa": {
                "lat": 9.5631,
                "lng": 44.0675
            },
            "Baidoa": {
                "lat": 3.1167,
                "lng": 43.65
            },
            "Baardheere": {
                "lat": 2.3333,
                "lng": 42.2833
            },
            "Gaalkacyo": {
                "lat": 6.7697,
                "lng": 47.4308
            },
            "Marka": {
                "lat": 1.7133,
                "lng": 44.7656
            },
            "Boosaaso": {
                "lat": 11.2886,
                "lng": 49.1819
            },
            "Cadaado": {
                "lat": 6.1403,
                "lng": 46.6256
            },
            "Garoowe": {
                "lat": 8.4,
                "lng": 48.4667
            },
            "Berbera": {
                "lat": 10.4356,
                "lng": 45.0164
            },
            "Burco": {
                "lat": 9.5279,
                "lng": 45.5345
            },
            "Ceel Baraf": {
                "lat": 3.2073,
                "lng": 45.7587
            },
            "Qardho": {
                "lat": 9.5,
                "lng": 49.0833
            },
            "Buurhakaba": {
                "lat": 2.7833,
                "lng": 44.0833
            },
            "Luuq": {
                "lat": 3.6981,
                "lng": 42.5972
            },
            "Jamaame": {
                "lat": 0.0667,
                "lng": 42.75
            },
            "Jilib": {
                "lat": 0.4942,
                "lng": 42.7744
            },
            "Aw Dheegle": {
                "lat": 1.9667,
                "lng": 44.8333
            },
            "Bu\u2019aale": {
                "lat": 1.0833,
                "lng": 42.5833
            },
            "Jawhar": {
                "lat": 2.7833,
                "lng": 45.5
            },
            "Afmadow": {
                "lat": 0.5156,
                "lng": 42.0733
            },
            "Barwaaqo": {
                "lat": 3.4833,
                "lng": 42.1833
            },
            "Caracase": {
                "lat": 3.7533,
                "lng": 42.5442
            },
            "Xuddur": {
                "lat": 4.12,
                "lng": 43.8878
            },
            "Ufeyn": {
                "lat": 10.65,
                "lng": 49.75
            },
            "Afgooye": {
                "lat": 2.1413,
                "lng": 45.1169
            },
            "Wisil": {
                "lat": 5.4333,
                "lng": 48.1167
            },
            "Badhan": {
                "lat": 10.7139,
                "lng": 48.3347
            },
            "Xarardheere": {
                "lat": 4.6544,
                "lng": 47.8575
            },
            "Beledweyne": {
                "lat": 4.7358,
                "lng": 45.2036
            },
            "Qoryooley": {
                "lat": 1.7833,
                "lng": 44.5333
            },
            "Qoorlugud": {
                "lat": 8.55,
                "lng": 46.2333
            },
            "Buqda Caqable": {
                "lat": 4.06,
                "lng": 45.2575
            },
            "Oodweyne": {
                "lat": 9.4,
                "lng": 45.0667
            },
            "Bura": {
                "lat": 3.1722,
                "lng": 42.1411
            },
            "Bilis Qooqaani": {
                "lat": 0.2822,
                "lng": 41.7144
            },
            "Bacaadweyn": {
                "lat": 7.1917,
                "lng": 47.5258
            },
            "Godinlabe": {
                "lat": 5.8794,
                "lng": 46.6308
            },
            "Ceel Dheere": {
                "lat": 5.3683,
                "lng": 46.1911
            },
            "Busaar": {
                "lat": 2.6722,
                "lng": 41.3225
            },
            "Garbahaarrey": {
                "lat": 3.35,
                "lng": 42.2667
            },
            "Baraawe": {
                "lat": 1.1133,
                "lng": 44.0303
            },
            "Uar Esgudud": {
                "lat": 1.2667,
                "lng": 41.3833
            },
            "Wanlaweyn": {
                "lat": 2.6167,
                "lng": 44.9
            },
            "Baxdo": {
                "lat": 5.7889,
                "lng": 47.2267
            },
            "Eyl": {
                "lat": 7.9667,
                "lng": 49.85
            },
            "Dan Gorayo": {
                "lat": 8.7278,
                "lng": 49.3417
            },
            "Diinsoor": {
                "lat": 2.4106,
                "lng": 42.9767
            },
            "Qandala": {
                "lat": 11.4711,
                "lng": 49.8739
            },
            "Saylac": {
                "lat": 11.3539,
                "lng": 43.4731
            },
            "Bandar Murcaayo": {
                "lat": 11.6833,
                "lng": 50.45
            },
            "Yufle": {
                "lat": 10.3758,
                "lng": 47.1964
            },
            "Bandarbeyla": {
                "lat": 9.4833,
                "lng": 50.8167
            },
            "Xaafuun": {
                "lat": 10.4167,
                "lng": 51.2667
            },
            "Buqkoosaar": {
                "lat": 4.5108,
                "lng": 44.8164
            },
            "Jalalaqsi": {
                "lat": 3.4,
                "lng": 45.5833
            },
            "Hobyo": {
                "lat": 5.3514,
                "lng": 48.5256
            },
            "Cadale": {
                "lat": 2.75,
                "lng": 46.3167
            },
            "Buuhoodle": {
                "lat": 8.2311,
                "lng": 46.3267
            },
            "Buraan": {
                "lat": 10.2186,
                "lng": 48.7686
            },
            "Kiridh": {
                "lat": 8.9803,
                "lng": 46.1292
            },
            "Gadoon": {
                "lat": 5.6897,
                "lng": 46.6847
            },
            "Widhwidh": {
                "lat": 8.4544,
                "lng": 46.7064
            },
            "Ceelbuur": {
                "lat": 4.685,
                "lng": 46.6175
            },
            "Mereeg": {
                "lat": 3.7667,
                "lng": 47.3
            },
            "Caluula": {
                "lat": 11.9667,
                "lng": 50.75
            },
            "Dhuusamarreeb": {
                "lat": 5.535,
                "lng": 46.3861
            },
            "Buur Gaabo": {
                "lat": -1.2175,
                "lng": 41.8378
            },
            "Hurdiyo": {
                "lat": 10.5667,
                "lng": 51.1333
            },
            "Ceerigaabo": {
                "lat": 10.6162,
                "lng": 47.3679
            },
            "Laascaanood": {
                "lat": 8.4774,
                "lng": 47.3597
            },
            "Boorama": {
                "lat": 9.9361,
                "lng": 43.1828
            }
        }
    },
    "SY": {
        "name": "Syria",
        "cities": {
            "Damascus": {
                "lat": 33.5131,
                "lng": 36.2919
            },
            "Aleppo": {
                "lat": 36.2,
                "lng": 37.16
            },
            "Homs": {
                "lat": 34.7308,
                "lng": 36.7094
            },
            "Latakia": {
                "lat": 35.5167,
                "lng": 35.7833
            },
            "\u1e28am\u0101h": {
                "lat": 35.1333,
                "lng": 36.75
            },
            "Ar Raqqah": {
                "lat": 35.95,
                "lng": 39.01
            },
            "Dayr az Zawr": {
                "lat": 35.3333,
                "lng": 40.15
            },
            "Al \u1e28asakah": {
                "lat": 36.5117,
                "lng": 40.7422
            },
            "Al Q\u0101mishl\u012b": {
                "lat": 37.05,
                "lng": 41.22
            },
            "Idlib": {
                "lat": 35.9333,
                "lng": 36.6333
            },
            "Dar\u2018\u0101": {
                "lat": 32.6189,
                "lng": 36.1021
            },
            "Qabr as Sitt": {
                "lat": 33.4472,
                "lng": 36.3361
            },
            "D\u016bm\u0101": {
                "lat": 33.5711,
                "lng": 36.4011
            },
            "Jaram\u0101n\u0101": {
                "lat": 33.4833,
                "lng": 36.35
            },
            "As Salam\u012byah": {
                "lat": 35.0118,
                "lng": 37.0525
            },
            "As Saf\u012brah": {
                "lat": 36.0778,
                "lng": 37.3733
            },
            "Al \u1e28ajar al Aswad": {
                "lat": 33.464,
                "lng": 36.3044
            },
            "Ma\u2018arrat an Nu\u2018m\u0101n": {
                "lat": 35.6386,
                "lng": 36.6717
            },
            "\u0162ar\u0163\u016bs": {
                "lat": 34.8833,
                "lng": 35.8833
            },
            "D\u0101rayy\u0101": {
                "lat": 33.45,
                "lng": 36.25
            },
            "Ath Thawrah": {
                "lat": 35.8367,
                "lng": 38.5481
            },
            "Manbij": {
                "lat": 36.5275,
                "lng": 37.9553
            },
            "Jablah": {
                "lat": 35.35,
                "lng": 35.9167
            },
            "Al Qurayy\u0101": {
                "lat": 35.0025,
                "lng": 40.5117
            },
            "As Suwayd\u0101\u2019": {
                "lat": 32.7125,
                "lng": 36.5667
            },
            "Naw\u00e1": {
                "lat": 32.8889,
                "lng": 36.0431
            },
            "At Tall": {
                "lat": 33.6,
                "lng": 36.3
            },
            "Ar Rastan": {
                "lat": 34.9167,
                "lng": 36.7333
            },
            "Kh\u0101n Shaykh\u016bn": {
                "lat": 35.4389,
                "lng": 36.6511
            },
            "An Nabk": {
                "lat": 34.0167,
                "lng": 36.7167
            },
            "\u0100lb\u016b Kam\u0101l": {
                "lat": 34.4536,
                "lng": 40.9367
            },
            "Tadmur": {
                "lat": 34.56,
                "lng": 38.2672
            },
            "Bab\u012bl\u0101": {
                "lat": 33.4728,
                "lng": 36.3344
            },
            "Al May\u0101d\u012bn": {
                "lat": 35.0183,
                "lng": 40.4533
            },
            "Ash Shaykh Badr": {
                "lat": 34.9833,
                "lng": 36.0833
            },
            "\u2018\u0100m\u016bd\u0101": {
                "lat": 37.1042,
                "lng": 40.93
            },
            "Yabr\u016bd": {
                "lat": 33.9667,
                "lng": 36.6667
            },
            "Al Qu\u015fayr": {
                "lat": 34.5119,
                "lng": 36.5764
            },
            "\u1e28arast\u0101": {
                "lat": 33.5667,
                "lng": 36.3667
            },
            "Tallb\u012bsah": {
                "lat": 34.8333,
                "lng": 36.7333
            },
            "\u2018Ayn al \u2018Arab": {
                "lat": 36.891,
                "lng": 38.3536
            },
            "Zamalk\u0101": {
                "lat": 33.5253,
                "lng": 36.3528
            },
            "Jisr ash Shugh\u016br": {
                "lat": 35.8126,
                "lng": 36.3176
            },
            "Al Kiswah": {
                "lat": 33.35,
                "lng": 36.2333
            },
            "B\u0101niy\u0101s": {
                "lat": 35.1822,
                "lng": 35.9403
            },
            "T\u0101dif": {
                "lat": 36.3481,
                "lng": 37.5308
            },
            "Jayr\u016bd": {
                "lat": 33.8067,
                "lng": 36.7403
            },
            "Sar\u0101qib": {
                "lat": 35.8636,
                "lng": 36.8006
            },
            "Al M\u0101lik\u012byah": {
                "lat": 37.1667,
                "lng": 42.1333
            },
            "Al Ghizl\u0101n\u012byah": {
                "lat": 33.3986,
                "lng": 36.4531
            },
            "Haj\u012bn": {
                "lat": 34.6894,
                "lng": 40.8308
            },
            "Al Qaryatayn": {
                "lat": 34.2283,
                "lng": 37.2406
            },
            "Ma\u2018arratmi\u015fr\u012bn": {
                "lat": 36.0,
                "lng": 36.6667
            },
            "Ma\u015fy\u0101f": {
                "lat": 35.0653,
                "lng": 36.3422
            },
            "\u2018Afr\u012bn": {
                "lat": 36.5083,
                "lng": 36.8692
            },
            "Tallkalakh": {
                "lat": 34.6683,
                "lng": 36.2597
            },
            "Dayr \u1e28\u0101fir": {
                "lat": 36.1569,
                "lng": 37.7078
            },
            "\u015ealkhad": {
                "lat": 32.4917,
                "lng": 36.7111
            },
            "Tall Sal\u1e29ab": {
                "lat": 35.2609,
                "lng": 36.3822
            },
            "Qadsayy\u0101": {
                "lat": 33.5333,
                "lng": 36.2167
            },
            "\u0162afas": {
                "lat": 32.7356,
                "lng": 36.0669
            },
            "\u015e\u0101f\u012bt\u0101": {
                "lat": 34.8167,
                "lng": 36.1167
            },
            "J\u0101sim": {
                "lat": 32.9667,
                "lng": 36.0667
            },
            "I\u2018z\u0101z": {
                "lat": 36.5866,
                "lng": 37.0463
            },
            "Inkhil": {
                "lat": 33.0,
                "lng": 36.1167
            },
            "Ar Ru\u1e29aybah": {
                "lat": 33.7436,
                "lng": 36.7012
            },
            "Kafr Nubl": {
                "lat": 35.6139,
                "lng": 36.5611
            },
            "Ra\u2019s al \u2018Ayn": {
                "lat": 36.8503,
                "lng": 40.0706
            },
            "\u015e\u016br\u0101n": {
                "lat": 35.2897,
                "lng": 36.7433
            },
            "Yald\u0101": {
                "lat": 33.4628,
                "lng": 36.3217
            },
            "Tall Rif\u2018at": {
                "lat": 36.4733,
                "lng": 37.0972
            },
            "Al Qu\u0163ayfah": {
                "lat": 33.7389,
                "lng": 36.6
            },
            "Az Zabad\u0101n\u012b": {
                "lat": 33.725,
                "lng": 36.0972
            },
            "A\u015f \u015eanamayn": {
                "lat": 33.0711,
                "lng": 36.1842
            },
            "Siqb\u0101": {
                "lat": 33.5197,
                "lng": 36.3844
            },
            "\u015eaydn\u0101y\u0101": {
                "lat": 33.6967,
                "lng": 36.3739
            },
            "Tall Shih\u0101b": {
                "lat": 32.6886,
                "lng": 35.9889
            },
            "\u0162ayyibat al Im\u0101m": {
                "lat": 35.2661,
                "lng": 36.7114
            },
            "Subaykh\u0101n": {
                "lat": 34.8464,
                "lng": 40.5939
            },
            "Salq\u012bn": {
                "lat": 36.1333,
                "lng": 36.45
            },
            "Qa\u0163an\u0101": {
                "lat": 33.4333,
                "lng": 36.0833
            },
            "Kafr Ba\u0163n\u0101": {
                "lat": 33.5,
                "lng": 36.3667
            },
            "\u1e28\u0101rim": {
                "lat": 36.2,
                "lng": 36.5167
            },
            "Binnish": {
                "lat": 35.95,
                "lng": 36.7
            },
            "Nubl": {
                "lat": 36.3756,
                "lng": 36.9942
            },
            "\u2018Adr\u0101": {
                "lat": 33.6,
                "lng": 36.515
            },
            "Kafr L\u0101h\u0101": {
                "lat": 34.8831,
                "lng": 36.5
            },
            "Bu\u015fr\u00e1 ash Sh\u0101m": {
                "lat": 32.5167,
                "lng": 36.4833
            },
            "Izra": {
                "lat": 32.8454,
                "lng": 36.2499
            },
            "Z\u0101k\u012byah": {
                "lat": 33.3344,
                "lng": 36.1567
            },
            "Tall \u2018Aran": {
                "lat": 36.1231,
                "lng": 37.3369
            },
            "Mu\u1e29radah": {
                "lat": 35.25,
                "lng": 36.5833
            },
            "Al \u2018Ash\u0101rah": {
                "lat": 34.9203,
                "lng": 40.5594
            },
            "Man\u012bn": {
                "lat": 33.6422,
                "lng": 36.2978
            },
            "As Suqaylib\u012byah": {
                "lat": 35.3697,
                "lng": 36.38
            },
            "Al \u1e28\u0101rah": {
                "lat": 33.05,
                "lng": 36.0
            },
            "Kafr Zayt\u0101": {
                "lat": 35.3736,
                "lng": 36.6017
            },
            "M\u0101ri\u2018": {
                "lat": 36.4828,
                "lng": 37.1964
            },
            "Shahb\u0101": {
                "lat": 32.8542,
                "lng": 36.6292
            },
            "Al La\u0163\u0101minah": {
                "lat": 35.3208,
                "lng": 36.6225
            },
            "Khirbat Ghaz\u0101lah": {
                "lat": 32.7333,
                "lng": 36.2
            },
            "As Sukhnah": {
                "lat": 34.8868,
                "lng": 38.8721
            },
            "Tas\u012bl": {
                "lat": 32.8353,
                "lng": 35.9714
            },
            "Ash Shadd\u0101dah": {
                "lat": 36.0561,
                "lng": 40.7303
            },
            "Al Mu\u2018abbadah": {
                "lat": 37.0164,
                "lng": 41.9544
            },
            "Bayt Sa\u1e29am": {
                "lat": 33.4733,
                "lng": 36.3372
            },
            "Maskanah": {
                "lat": 35.9632,
                "lng": 38.0356
            },
            "Kuljibr\u012bn": {
                "lat": 36.5361,
                "lng": 37.1175
            },
            "Sarmad\u0101": {
                "lat": 36.1833,
                "lng": 36.7167
            },
            "Tall Abya\u1e11": {
                "lat": 36.6975,
                "lng": 38.9567
            },
            "Sarm\u012bn": {
                "lat": 35.9033,
                "lng": 36.7258
            },
            "M\u016brak": {
                "lat": 35.3753,
                "lng": 36.6872
            },
            "Al Mu\u2018a\u1e11\u1e11am\u012byah": {
                "lat": 33.7421,
                "lng": 36.6435
            },
            "\u015ea\u1e29n\u0101y\u0101": {
                "lat": 33.4242,
                "lng": 36.2244
            },
            "Kan\u0101kir": {
                "lat": 33.2672,
                "lng": 36.0969
            },
            "Jindayris": {
                "lat": 36.3947,
                "lng": 36.6889
            },
            "D\u0101rat \u2018Izzah": {
                "lat": 36.2828,
                "lng": 36.8519
            },
            "Kafr Nab\u016bdah": {
                "lat": 35.4317,
                "lng": 36.4928
            },
            "Bu\u015fr\u00e1 al \u1e28ar\u012br": {
                "lat": 32.8425,
                "lng": 36.34
            },
            "Ad Durayk\u012bsh": {
                "lat": 34.8969,
                "lng": 36.1346
            },
            "Sh\u012bn": {
                "lat": 34.7833,
                "lng": 36.4333
            },
            "Qal\u2018at al Ma\u1e11\u012bq": {
                "lat": 35.41,
                "lng": 36.39
            },
            "Al Muzayr\u012bb": {
                "lat": 32.7109,
                "lng": 36.0266
            },
            "Q\u0101rah": {
                "lat": 34.1542,
                "lng": 36.7442
            },
            "Mi\u2018rabah": {
                "lat": 32.5458,
                "lng": 36.4283
            },
            "\u1e28awsh al Ba\u1e29dal\u012byah": {
                "lat": 33.4292,
                "lng": 36.3611
            },
            "Kafr R\u016bm\u0101": {
                "lat": 35.6342,
                "lng": 36.6322
            },
            "Kafr Buhum": {
                "lat": 35.0611,
                "lng": 36.6972
            },
            "Tall Dhahab": {
                "lat": 34.8581,
                "lng": 36.4517
            },
            "\u2018Anad\u0101n": {
                "lat": 36.2936,
                "lng": 37.0444
            },
            "Ghab\u0101ghib": {
                "lat": 33.1839,
                "lng": 36.2264
            },
            "Jar\u0101bulus": {
                "lat": 36.8175,
                "lng": 38.0111
            },
            "\u015eayd\u0101": {
                "lat": 32.6283,
                "lng": 36.2261
            },
            "Mah\u012bn": {
                "lat": 34.2425,
                "lng": 37.0589
            },
            "Dayr \u2018A\u0163\u012byah": {
                "lat": 34.0833,
                "lng": 36.7667
            },
            "A\u015f \u015eabb\u016brah": {
                "lat": 33.5151,
                "lng": 36.1259
            },
            "Kha\u0163\u0163\u0101b": {
                "lat": 35.1975,
                "lng": 36.6672
            },
            "Jarjan\u0101z": {
                "lat": 35.6175,
                "lng": 36.7772
            },
            "B\u0101gh\u016bz Fawq\u0101n\u012b": {
                "lat": 34.4586,
                "lng": 40.9506
            },
            "Al At\u0101rib": {
                "lat": 36.1389,
                "lng": 36.83
            },
            "\u2018Utaybah": {
                "lat": 33.4861,
                "lng": 36.6011
            },
            "Al Karak": {
                "lat": 32.6872,
                "lng": 36.3508
            },
            "Al Musayfirah": {
                "lat": 32.6322,
                "lng": 36.3386
            },
            "Turm\u0101n\u012bn": {
                "lat": 36.2333,
                "lng": 36.8167
            },
            "B\u0101rah": {
                "lat": 35.683,
                "lng": 36.533
            },
            "Arman\u0101z": {
                "lat": 36.0833,
                "lng": 36.5
            },
            "Ma\u2018rab\u0101": {
                "lat": 33.5775,
                "lng": 36.2969
            },
            "Mi\u1e29qan": {
                "lat": 34.9744,
                "lng": 40.4856
            },
            "Kafr Takh\u0101r\u012bm": {
                "lat": 36.1164,
                "lng": 36.5147
            },
            "Al H\u0101mah": {
                "lat": 33.5581,
                "lng": 36.2222
            },
            "An Nayrab": {
                "lat": 36.1756,
                "lng": 37.2278
            },
            "Ma\u1e29ajjah": {
                "lat": 32.9558,
                "lng": 36.2214
            },
            "Gh\u0101riyah al Gharb\u012byah": {
                "lat": 32.6828,
                "lng": 36.2233
            },
            "Ghabrah": {
                "lat": 34.5378,
                "lng": 40.9125
            },
            "\u1e28\u0101s": {
                "lat": 35.6169,
                "lng": 36.5953
            },
            "M\u016b\u1e29 \u1e28asan": {
                "lat": 35.2394,
                "lng": 40.3244
            },
            "Jisrayn": {
                "lat": 33.5064,
                "lng": 36.3858
            },
            "Ma\u1e11\u0101y\u0101": {
                "lat": 33.6878,
                "lng": 36.1008
            },
            "Ab\u016b Khashab": {
                "lat": 35.9358,
                "lng": 40.0367
            },
            "Dh\u012bb\u0101n": {
                "lat": 35.0025,
                "lng": 40.5117
            },
            "Al Y\u0101d\u016bdah": {
                "lat": 32.6653,
                "lng": 36.0611
            },
            "Kafr Sajnah": {
                "lat": 35.5261,
                "lng": 36.5886
            },
            "Al Buway\u1e11ah": {
                "lat": 33.4264,
                "lng": 36.3208
            },
            "\u1e28\u012bsh": {
                "lat": 35.5464,
                "lng": 36.6431
            },
            "Al Qard\u0101\u1e29ah": {
                "lat": 35.4572,
                "lng": 36.0597
            },
            "Ab\u016b \u1e28ard\u016bb": {
                "lat": 34.845,
                "lng": 40.6431
            },
            "Ad Darb\u0101s\u012byah": {
                "lat": 37.0728,
                "lng": 40.6519
            },
            "Taftan\u0101z": {
                "lat": 35.9969,
                "lng": 36.7867
            },
            "Ra\u2019s al Ma\u2018arrah": {
                "lat": 34.0008,
                "lng": 36.5628
            },
            "Al Qunay\u0163irah": {
                "lat": 33.1256,
                "lng": 35.8239
            }
        }
    },
    "ZM": {
        "name": "Zambia",
        "cities": {
            "Lusaka": {
                "lat": -15.4167,
                "lng": 28.2833
            },
            "Ndola": {
                "lat": -12.9689,
                "lng": 28.6325
            },
            "Kitwe": {
                "lat": -12.8167,
                "lng": 28.2
            },
            "Kafue": {
                "lat": -15.7667,
                "lng": 28.1833
            },
            "Mazabuka": {
                "lat": -15.8467,
                "lng": 27.7475
            },
            "Mongu": {
                "lat": -15.2775,
                "lng": 23.1319
            },
            "Kasama": {
                "lat": -10.2117,
                "lng": 31.1783
            },
            "Kashikishi": {
                "lat": -9.3,
                "lng": 28.7167
            },
            "Nchelenge": {
                "lat": -9.3533,
                "lng": 28.7403
            },
            "Chingola": {
                "lat": -12.5333,
                "lng": 27.85
            },
            "Mansa": {
                "lat": -11.2,
                "lng": 28.8833
            },
            "Mufulira": {
                "lat": -12.5356,
                "lng": 28.2419
            },
            "Bwana Mkubwa": {
                "lat": -12.9833,
                "lng": 28.7
            },
            "Luanshya": {
                "lat": -13.1333,
                "lng": 28.4
            },
            "Kabwe": {
                "lat": -14.4333,
                "lng": 28.45
            },
            "Kasempa": {
                "lat": -13.455,
                "lng": 25.835
            },
            "Mporokoso": {
                "lat": -9.3833,
                "lng": 30.1
            },
            "Chipata": {
                "lat": -13.6453,
                "lng": 32.6464
            },
            "Solwezi": {
                "lat": -12.1433,
                "lng": 26.3858
            },
            "Chililabombwe": {
                "lat": -12.3667,
                "lng": 27.8278
            },
            "Chirundu": {
                "lat": -16.05,
                "lng": 28.8333
            },
            "Choma": {
                "lat": -16.7711,
                "lng": 26.9922
            },
            "Livingstone": {
                "lat": -17.85,
                "lng": 25.8667
            },
            "Mpika": {
                "lat": -11.83,
                "lng": 31.46
            },
            "Kawambwa": {
                "lat": -9.7914,
                "lng": 29.0789
            },
            "Mbala": {
                "lat": -8.8333,
                "lng": 31.3833
            },
            "Sesheke": {
                "lat": -17.4667,
                "lng": 24.3
            },
            "Mumbwa": {
                "lat": -14.9853,
                "lng": 27.0619
            },
            "Mwinilunga": {
                "lat": -11.7172,
                "lng": 24.4289
            },
            "Chinsali": {
                "lat": -10.5522,
                "lng": 32.0692
            },
            "Kapiri Mposhi": {
                "lat": -13.9772,
                "lng": 28.6847
            },
            "Kaoma": {
                "lat": -14.8,
                "lng": 24.8
            },
            "Chifubu": {
                "lat": -12.9333,
                "lng": 28.6167
            },
            "Chilonga": {
                "lat": -12.0244,
                "lng": 31.3364
            },
            "Lundazi": {
                "lat": -12.3,
                "lng": 33.1833
            },
            "Nakonde": {
                "lat": -9.3272,
                "lng": 32.7583
            },
            "Senanga": {
                "lat": -16.1167,
                "lng": 23.2667
            },
            "Kalabo": {
                "lat": -14.9911,
                "lng": 22.6789
            },
            "Zambezi": {
                "lat": -13.55,
                "lng": 23.1167
            },
            "Lukulu": {
                "lat": -14.4083,
                "lng": 23.2667
            },
            "Nyimba": {
                "lat": -14.5495,
                "lng": 30.81
            }
        }
    },
    "BF": {
        "name": "Burkina Faso",
        "cities": {
            "Ouagadougou": {
                "lat": 12.3686,
                "lng": -1.5275
            },
            "Bobo-Dioulasso": {
                "lat": 11.1833,
                "lng": -4.2833
            },
            "Koudougou": {
                "lat": 12.25,
                "lng": -2.3667
            },
            "Ouahigouya": {
                "lat": 13.5833,
                "lng": -2.4167
            },
            "Kaya": {
                "lat": 13.0833,
                "lng": -1.0833
            },
            "Banfora": {
                "lat": 10.6308,
                "lng": -4.7589
            },
            "Pouytenga": {
                "lat": 12.25,
                "lng": -0.4333
            },
            "Hound\u00e9": {
                "lat": 11.5,
                "lng": -3.5167
            },
            "Fada Ngourma": {
                "lat": 12.05,
                "lng": 0.3667
            },
            "D\u00e9dougou": {
                "lat": 12.4667,
                "lng": -3.4667
            },
            "Djibo": {
                "lat": 14.1011,
                "lng": -1.6306
            },
            "Tenkodogo": {
                "lat": 11.7833,
                "lng": -0.3667
            },
            "Kongoussi": {
                "lat": 13.3333,
                "lng": -1.5333
            },
            "Titao": {
                "lat": 13.25,
                "lng": -2.05
            },
            "Dori": {
                "lat": 14.03,
                "lng": -0.03
            },
            "Koup\u00e9la": {
                "lat": 12.1794,
                "lng": -0.3517
            },
            "Aribinda": {
                "lat": 14.2292,
                "lng": -0.8633
            },
            "Gaoua": {
                "lat": 10.3167,
                "lng": -3.1667
            },
            "Garango": {
                "lat": 11.8,
                "lng": -0.55
            },
            "Tanghin-Dassouri": {
                "lat": 12.2667,
                "lng": -1.7167
            },
            "Gourcy": {
                "lat": 13.2167,
                "lng": -2.35
            },
            "Yako": {
                "lat": 12.9667,
                "lng": -2.2667
            },
            "Bind\u00e9": {
                "lat": 11.75,
                "lng": -1.1
            },
            "Zorgo": {
                "lat": 12.25,
                "lng": -0.6167
            },
            "R\u00e9o": {
                "lat": 12.3167,
                "lng": -2.4667
            },
            "L\u00e9o": {
                "lat": 11.1,
                "lng": -2.1
            },
            "Niangoloko": {
                "lat": 10.2833,
                "lng": -4.9167
            },
            "Orodara": {
                "lat": 10.98,
                "lng": -4.91
            },
            "Ziniar\u00e9": {
                "lat": 12.5833,
                "lng": -1.3
            },
            "Nouna": {
                "lat": 12.7333,
                "lng": -3.8667
            },
            "Kindi": {
                "lat": 12.4333,
                "lng": -2.0167
            },
            "Bittou": {
                "lat": 11.2575,
                "lng": -0.3039
            },
            "Kombissiri": {
                "lat": 12.0667,
                "lng": -1.3333
            },
            "Manga": {
                "lat": 11.6667,
                "lng": -1.0667
            },
            "P\u00f4": {
                "lat": 11.1667,
                "lng": -1.15
            },
            "Bourzanga": {
                "lat": 13.6781,
                "lng": -1.5461
            },
            "Tougan": {
                "lat": 13.0667,
                "lng": -3.0667
            },
            "Sapouy": {
                "lat": 11.5544,
                "lng": -1.7736
            },
            "Dano": {
                "lat": 11.1436,
                "lng": -3.0628
            },
            "Mogt\u00e9do": {
                "lat": 12.2833,
                "lng": -0.8333
            },
            "Di\u00e9bougou": {
                "lat": 10.9667,
                "lng": -3.25
            },
            "Bouss\u00e9": {
                "lat": 12.6667,
                "lng": -1.8975
            },
            "Salanso": {
                "lat": 12.1833,
                "lng": -4.0833
            },
            "Boulsa": {
                "lat": 12.6667,
                "lng": -0.5833
            },
            "Pissila": {
                "lat": 13.1667,
                "lng": -0.8167
            },
            "B\u00e9gu\u00e9do": {
                "lat": 11.7833,
                "lng": -0.7333
            },
            "Zabr\u00e9": {
                "lat": 11.1833,
                "lng": -0.6333
            },
            "Bogand\u00e9": {
                "lat": 12.9667,
                "lng": -0.15
            },
            "Sapon\u00e9": {
                "lat": 12.0528,
                "lng": -1.6036
            },
            "Vili": {
                "lat": 12.2833,
                "lng": -2.2167
            },
            "Fara": {
                "lat": 11.5333,
                "lng": -2.7667
            },
            "Boromo": {
                "lat": 11.75,
                "lng": -2.9333
            },
            "Korsimoro": {
                "lat": 12.825,
                "lng": -1.0672
            },
            "Bati\u00e9": {
                "lat": 9.8833,
                "lng": -2.9167
            },
            "Kouka": {
                "lat": 11.9,
                "lng": -4.3333
            },
            "Toma": {
                "lat": 12.7667,
                "lng": -2.8833
            },
            "Diapaga": {
                "lat": 12.0667,
                "lng": 1.7833
            },
            "Gomboussougou": {
                "lat": 11.4333,
                "lng": -0.7667
            },
            "Gay\u00e9ri": {
                "lat": 12.65,
                "lng": 0.5
            },
            "P\u00e2": {
                "lat": 11.55,
                "lng": -3.26
            },
            "Mani": {
                "lat": 13.26,
                "lng": -0.2094
            },
            "Sabou": {
                "lat": 12.0667,
                "lng": -2.2333
            },
            "Pama": {
                "lat": 11.25,
                "lng": 0.7
            },
            "Niaogho": {
                "lat": 11.7667,
                "lng": -0.7667
            },
            "Ouargaye": {
                "lat": 11.5,
                "lng": 0.05
            },
            "Imassogo": {
                "lat": 12.4393,
                "lng": -2.3331
            },
            "B\u00e9na": {
                "lat": 12.0804,
                "lng": -4.189
            },
            "Sid\u00e9radougou": {
                "lat": 10.6876,
                "lng": -4.2627
            },
            "Sebba": {
                "lat": 13.4333,
                "lng": 0.5333
            },
            "Nandiala": {
                "lat": 12.3333,
                "lng": -2.1667
            },
            "Gorom-Gorom": {
                "lat": 14.45,
                "lng": -0.2333
            },
            "Kokologo": {
                "lat": 12.1833,
                "lng": -1.8833
            },
            "Soubakani\u00e9dougou": {
                "lat": 10.4824,
                "lng": -5.0098
            },
            "Ouar\u00e9gou": {
                "lat": 11.8,
                "lng": -0.7
            },
            "B\u00e9r\u00e9gadougou": {
                "lat": 10.7667,
                "lng": -4.75
            },
            "Tiou": {
                "lat": 11.95,
                "lng": -2.2
            }
        }
    },
    "LB": {
        "name": "Lebanon",
        "cities": {
            "Beirut": {
                "lat": 33.8983,
                "lng": 35.5057
            },
            "Barr Eli\u00e2s": {
                "lat": 33.775,
                "lng": 35.9
            },
            "Tripoli": {
                "lat": 34.4367,
                "lng": 35.8344
            },
            "Sidon": {
                "lat": 33.5606,
                "lng": 35.3758
            },
            "El Mini\u00e9": {
                "lat": 34.447,
                "lng": 35.8178
            },
            "Borj Hammoud": {
                "lat": 33.8936,
                "lng": 35.5403
            },
            "Aaley": {
                "lat": 33.8,
                "lng": 35.6
            },
            "Jo\u00fcni\u00e9": {
                "lat": 33.9697,
                "lng": 35.6156
            },
            "Zahl\u00e9": {
                "lat": 33.8333,
                "lng": 35.9167
            },
            "Baabda": {
                "lat": 33.8333,
                "lng": 35.5333
            },
            "Baalbek": {
                "lat": 34.0063,
                "lng": 36.2073
            },
            "Nabat\u00eey\u00e9": {
                "lat": 33.3833,
                "lng": 35.45
            },
            "Borj el Qobl\u00e9": {
                "lat": 33.2631,
                "lng": 35.2389
            },
            "Tyre": {
                "lat": 33.2708,
                "lng": 35.1961
            },
            "Qabb Eli\u00e2s": {
                "lat": 33.7986,
                "lng": 35.825
            },
            "El Gh\u00e2z\u00eey\u00e9": {
                "lat": 33.5186,
                "lng": 35.3661
            },
            "Aars\u00e2l": {
                "lat": 34.1794,
                "lng": 36.4208
            },
            "Batro\u00fbn": {
                "lat": 34.25,
                "lng": 35.65
            },
            "Jba\u00efl": {
                "lat": 34.1236,
                "lng": 35.6519
            },
            "Chmist\u00e2r": {
                "lat": 33.9667,
                "lng": 36.0167
            },
            "El Fanar": {
                "lat": 33.8667,
                "lng": 35.5667
            },
            "Bent Jba\u00efl": {
                "lat": 33.1208,
                "lng": 35.4336
            },
            "Zghart\u0101": {
                "lat": 34.4,
                "lng": 35.9
            },
            "Qornet Chahou\u00e2ne": {
                "lat": 33.92,
                "lng": 35.67
            },
            "Ehden": {
                "lat": 34.2919,
                "lng": 35.9546
            },
            "Bcharr\u00e9": {
                "lat": 34.2511,
                "lng": 36.0111
            },
            "Majdel Aanjar": {
                "lat": 33.7075,
                "lng": 35.9039
            },
            "El Hermel": {
                "lat": 34.3914,
                "lng": 36.3958
            },
            "Kfar Kidd\u00e9": {
                "lat": 34.2028,
                "lng": 35.6544
            },
            "El B\u00e2zo\u00fbr\u00eey\u00e9": {
                "lat": 33.2539,
                "lng": 35.2717
            },
            "Dba\u00efy\u00e9": {
                "lat": 33.9353,
                "lng": 35.5897
            },
            "Baaql\u00eene": {
                "lat": 33.6797,
                "lng": 35.5583
            },
            "R\u00e2s Baalbek": {
                "lat": 34.2597,
                "lng": 36.4236
            },
            "Joubb Jann\u00eene": {
                "lat": 33.6333,
                "lng": 35.7833
            },
            "Kahhal\u00e9": {
                "lat": 33.8219,
                "lng": 35.5875
            },
            "Barja": {
                "lat": 33.6497,
                "lng": 35.4433
            },
            "Hasro\u00fbn": {
                "lat": 34.2419,
                "lng": 35.9794
            },
            "El Q\u00e2a": {
                "lat": 34.3436,
                "lng": 36.4756
            },
            "Chaqra": {
                "lat": 33.1917,
                "lng": 35.4628
            },
            "Bteghr\u00eene": {
                "lat": 33.93,
                "lng": 35.745
            },
            "Qoubaiyat": {
                "lat": 34.5683,
                "lng": 36.2764
            },
            "Sarafand": {
                "lat": 33.4517,
                "lng": 35.2908
            },
            "Sr\u00eefa": {
                "lat": 33.2814,
                "lng": 35.3964
            },
            "Charto\u00fbn": {
                "lat": 33.7711,
                "lng": 35.6858
            },
            "Q\u00e2na": {
                "lat": 33.2092,
                "lng": 35.2992
            },
            "Amio\u00fbn": {
                "lat": 34.3,
                "lng": 35.8
            },
            "R\u00e2s el Metn": {
                "lat": 33.85,
                "lng": 35.6667
            },
            "Ed D\u00e2mo\u00fbr": {
                "lat": 33.7333,
                "lng": 35.45
            },
            "Kfar Aab\u00eeda": {
                "lat": 34.2264,
                "lng": 35.66
            },
            "Halba": {
                "lat": 34.5506,
                "lng": 36.0781
            }
        }
    },
    "RO": {
        "name": "Romania",
        "cities": {
            "Bucharest": {
                "lat": 44.4325,
                "lng": 26.1039
            },
            "Cluj-Napoca": {
                "lat": 46.7667,
                "lng": 23.5833
            },
            "Ia\u015fi": {
                "lat": 47.1622,
                "lng": 27.5889
            },
            "Constan\u0163a": {
                "lat": 44.1667,
                "lng": 28.6333
            },
            "Timi\u015foara": {
                "lat": 45.7597,
                "lng": 21.23
            },
            "Bra\u015fov": {
                "lat": 45.6667,
                "lng": 25.6167
            },
            "Craiova": {
                "lat": 44.3333,
                "lng": 23.8167
            },
            "Gala\u0163i": {
                "lat": 45.4233,
                "lng": 28.0425
            },
            "Oradea": {
                "lat": 47.0722,
                "lng": 21.9211
            },
            "Ploie\u015fti": {
                "lat": 44.9411,
                "lng": 26.0225
            },
            "Br\u0103ila": {
                "lat": 45.2692,
                "lng": 27.9575
            },
            "Arad": {
                "lat": 46.175,
                "lng": 21.3125
            },
            "Pite\u015fti": {
                "lat": 44.8606,
                "lng": 24.8678
            },
            "Bac\u0103u": {
                "lat": 46.5833,
                "lng": 26.9167
            },
            "Sibiu": {
                "lat": 45.7928,
                "lng": 24.1519
            },
            "T\u00e2rgu-Mure\u015f": {
                "lat": 46.5456,
                "lng": 24.5625
            },
            "Baia Mare": {
                "lat": 47.6567,
                "lng": 23.5719
            },
            "Buz\u0103u": {
                "lat": 45.1531,
                "lng": 26.8208
            },
            "R\u00e2mnicu V\u00e2lcea": {
                "lat": 45.1047,
                "lng": 24.3756
            },
            "Satu Mare": {
                "lat": 47.79,
                "lng": 22.89
            },
            "Boto\u015fani": {
                "lat": 47.7486,
                "lng": 26.6694
            },
            "Suceava": {
                "lat": 47.6514,
                "lng": 26.2556
            },
            "Re\u015fi\u0163a": {
                "lat": 45.3008,
                "lng": 21.8892
            },
            "Drobeta-Turnu Severin": {
                "lat": 44.6333,
                "lng": 22.65
            },
            "Piatra Neam\u0163": {
                "lat": 46.9275,
                "lng": 26.3708
            },
            "Bistri\u0163a": {
                "lat": 47.1333,
                "lng": 24.5
            },
            "T\u00e2rgu Jiu": {
                "lat": 45.0342,
                "lng": 23.2747
            },
            "T\u00e2rgovi\u015fte": {
                "lat": 44.9244,
                "lng": 25.4572
            },
            "Foc\u015fani": {
                "lat": 45.7,
                "lng": 27.1797
            },
            "Tulcea": {
                "lat": 45.19,
                "lng": 28.8
            },
            "Alba Iulia": {
                "lat": 46.0669,
                "lng": 23.57
            },
            "Slatina": {
                "lat": 44.4297,
                "lng": 24.3642
            },
            "Vaslui": {
                "lat": 46.6383,
                "lng": 27.7292
            },
            "C\u0103l\u0103ra\u015fi": {
                "lat": 44.2,
                "lng": 27.3333
            },
            "Giurgiu": {
                "lat": 43.9008,
                "lng": 25.9739
            },
            "Pope\u015fti-Leordeni": {
                "lat": 44.38,
                "lng": 26.17
            },
            "Deva": {
                "lat": 45.8781,
                "lng": 22.9144
            },
            "B\u00e2rlad": {
                "lat": 46.2167,
                "lng": 27.6667
            },
            "Zal\u0103u": {
                "lat": 47.1911,
                "lng": 23.0572
            },
            "Hunedoara": {
                "lat": 45.7697,
                "lng": 22.9203
            },
            "Flore\u015fti": {
                "lat": 46.7475,
                "lng": 23.4908
            },
            "Sf\u00e2ntu-Gheorghe": {
                "lat": 45.8636,
                "lng": 25.7875
            },
            "Roman": {
                "lat": 46.93,
                "lng": 26.93
            },
            "Voluntari": {
                "lat": 44.4925,
                "lng": 26.1914
            },
            "Turda": {
                "lat": 46.5667,
                "lng": 23.7833
            },
            "Miercurea-Ciuc": {
                "lat": 46.3594,
                "lng": 25.8017
            },
            "Slobozia": {
                "lat": 44.5639,
                "lng": 27.3661
            },
            "Alexandria": {
                "lat": 43.9686,
                "lng": 25.3333
            },
            "Bragadiru": {
                "lat": 44.3708,
                "lng": 25.975
            },
            "Media": {
                "lat": 46.15,
                "lng": 24.35
            },
            "Lugoj": {
                "lat": 45.7056,
                "lng": 21.9258
            },
            "Medgidia": {
                "lat": 44.2503,
                "lng": 28.2614
            },
            "N\u0103vodari": {
                "lat": 44.3211,
                "lng": 28.6133
            },
            "One\u015fti": {
                "lat": 46.2586,
                "lng": 26.7692
            },
            "Pantelimon": {
                "lat": 44.45,
                "lng": 26.2
            },
            "Tecuci": {
                "lat": 45.8467,
                "lng": 27.4278
            },
            "Sighetu Marma\u0163iei": {
                "lat": 47.9286,
                "lng": 23.8925
            },
            "Mangalia": {
                "lat": 43.8172,
                "lng": 28.5828
            },
            "Dej": {
                "lat": 47.0872,
                "lng": 23.8053
            },
            "Odorheiu Secuiesc": {
                "lat": 46.3139,
                "lng": 25.3017
            },
            "Petro\u015fani": {
                "lat": 45.4122,
                "lng": 23.3733
            },
            "S\u0103cele": {
                "lat": 45.62,
                "lng": 25.7097
            },
            "Pa\u015fcani": {
                "lat": 47.2494,
                "lng": 26.7272
            },
            "R\u00e2mnicu S\u0103rat": {
                "lat": 45.38,
                "lng": 27.06
            },
            "Reghin": {
                "lat": 46.7758,
                "lng": 24.7083
            },
            "Mioveni": {
                "lat": 44.9569,
                "lng": 24.9406
            },
            "C\u00e2mpina": {
                "lat": 45.13,
                "lng": 25.74
            },
            "Bor\u015fa": {
                "lat": 47.6553,
                "lng": 24.6631
            },
            "C\u00e2mpulung": {
                "lat": 45.2678,
                "lng": 25.0464
            },
            "Fete\u015fti": {
                "lat": 44.415,
                "lng": 27.8236
            },
            "Caracal": {
                "lat": 44.1125,
                "lng": 24.3472
            },
            "Sebe\u015f": {
                "lat": 45.96,
                "lng": 23.57
            },
            "F\u0103g\u0103ra\u015f": {
                "lat": 45.8447,
                "lng": 24.9742
            },
            "Curtea de Arge\u015f": {
                "lat": 45.1392,
                "lng": 24.6792
            },
            "Hu\u015fi": {
                "lat": 46.6742,
                "lng": 28.0597
            },
            "R\u0103d\u0103u\u0163i": {
                "lat": 47.8425,
                "lng": 25.9192
            },
            "Sighi\u015foara": {
                "lat": 46.2169,
                "lng": 24.7911
            },
            "F\u0103lticeni": {
                "lat": 47.4597,
                "lng": 26.3
            },
            "Dorohoi": {
                "lat": 47.9597,
                "lng": 26.3997
            },
            "Olteni\u0163a": {
                "lat": 44.0867,
                "lng": 26.6367
            },
            "Ro\u015fiori de Vede": {
                "lat": 44.1114,
                "lng": 24.9942
            },
            "Cisn\u0103die": {
                "lat": 45.7128,
                "lng": 24.1508
            },
            "Otopeni": {
                "lat": 44.55,
                "lng": 26.07
            },
            "Caransebe\u015f": {
                "lat": 45.4214,
                "lng": 22.2219
            },
            "Z\u0103rne\u015fti": {
                "lat": 45.5667,
                "lng": 25.3333
            },
            "Aiud": {
                "lat": 46.3122,
                "lng": 23.7292
            },
            "T\u00e2rn\u0103veni": {
                "lat": 46.3297,
                "lng": 24.27
            },
            "C\u00e2mpia Turzii": {
                "lat": 46.5486,
                "lng": 23.88
            },
            "Buftea": {
                "lat": 44.57,
                "lng": 25.95
            },
            "Codlea": {
                "lat": 45.6969,
                "lng": 25.4439
            },
            "Dumbr\u0103vi\u0163a": {
                "lat": 45.8,
                "lng": 21.25
            },
            "Com\u0103ne\u015fti": {
                "lat": 46.4297,
                "lng": 26.45
            },
            "Gherla": {
                "lat": 47.02,
                "lng": 23.9
            },
            "Vulcan": {
                "lat": 45.3811,
                "lng": 23.2914
            },
            "Moine\u015fti": {
                "lat": 46.4333,
                "lng": 26.4833
            },
            "Petrila": {
                "lat": 45.45,
                "lng": 23.42
            },
            "Turnu M\u0103gurele": {
                "lat": 43.7517,
                "lng": 24.8708
            },
            "Cugir": {
                "lat": 45.8436,
                "lng": 23.3636
            },
            "Dudu": {
                "lat": 44.4563,
                "lng": 25.9906
            },
            "Carei": {
                "lat": 47.6839,
                "lng": 22.4669
            },
            "Lupeni": {
                "lat": 45.3603,
                "lng": 23.2383
            },
            "T\u00e2rgu Neam\u0163": {
                "lat": 47.2025,
                "lng": 26.3586
            },
            "Blaj": {
                "lat": 46.1753,
                "lng": 23.9144
            },
            "Or\u0103\u015ftie": {
                "lat": 45.85,
                "lng": 23.2
            },
            "B\u0103icoi": {
                "lat": 45.0453,
                "lng": 25.8658
            },
            "Valu lui Traian": {
                "lat": 44.165,
                "lng": 28.455
            },
            "T\u00e2rgu Secuiesc": {
                "lat": 45.9969,
                "lng": 26.1406
            },
            "Ro\u015fu": {
                "lat": 44.4509,
                "lng": 26.0083
            },
            "Bal\u015f": {
                "lat": 44.35,
                "lng": 24.0994
            },
            "Motru": {
                "lat": 44.8036,
                "lng": 22.9708
            },
            "B\u0103ile\u015fti": {
                "lat": 44.0308,
                "lng": 23.3525
            },
            "R\u00e2\u015fnov": {
                "lat": 45.5933,
                "lng": 25.4603
            },
            "Gheorgheni": {
                "lat": 46.72,
                "lng": 25.59
            },
            "Salonta": {
                "lat": 46.8,
                "lng": 21.65
            },
            "C\u00e2mpulung Moldovenesc": {
                "lat": 47.5308,
                "lng": 25.5514
            },
            "Dr\u0103g\u0103\u015fani": {
                "lat": 44.6611,
                "lng": 24.2606
            },
            "Moreni": {
                "lat": 44.9803,
                "lng": 25.6444
            },
            "Vi\u015feu de Sus": {
                "lat": 47.7092,
                "lng": 24.4239
            },
            "Adjud": {
                "lat": 46.1,
                "lng": 27.1797
            },
            "Vicovu de Sus": {
                "lat": 47.9258,
                "lng": 25.68
            },
            "Cernavod\u0103": {
                "lat": 44.3381,
                "lng": 28.0336
            },
            "Filia\u015fi": {
                "lat": 44.4,
                "lng": 23.52
            },
            "Breaza": {
                "lat": 45.1872,
                "lng": 25.6622
            },
            "Chitila": {
                "lat": 44.5083,
                "lng": 25.9819
            },
            "Ludu\u015f": {
                "lat": 46.4778,
                "lng": 24.0961
            },
            "Cump\u0103na": {
                "lat": 44.1128,
                "lng": 28.5558
            },
            "Negre\u015fti-Oa\u015f": {
                "lat": 47.8694,
                "lng": 23.4242
            },
            "Valea Lupului": {
                "lat": 47.1833,
                "lng": 27.5
            },
            "M\u0103gurele": {
                "lat": 44.3494,
                "lng": 26.0297
            },
            "Baia-Sprie": {
                "lat": 47.6592,
                "lng": 23.7042
            },
            "Buhu\u015fi": {
                "lat": 46.715,
                "lng": 26.7042
            },
            "Ovidiu": {
                "lat": 44.27,
                "lng": 28.56
            },
            "Calafat": {
                "lat": 43.9858,
                "lng": 22.9575
            },
            "Berceni": {
                "lat": 44.3167,
                "lng": 26.1833
            },
            "Marghita": {
                "lat": 47.35,
                "lng": 22.3333
            },
            "Corabia": {
                "lat": 43.7736,
                "lng": 24.5033
            },
            "Urziceni": {
                "lat": 44.7181,
                "lng": 26.6453
            },
            "Gura Humorului": {
                "lat": 47.5539,
                "lng": 25.8892
            },
            "D\u0103rm\u0103ne\u015fti": {
                "lat": 46.37,
                "lng": 26.4797
            },
            "Mizil": {
                "lat": 45.0,
                "lng": 26.4406
            },
            "Pucioasa": {
                "lat": 45.0742,
                "lng": 25.4342
            },
            "Boc\u015fa": {
                "lat": 45.3747,
                "lng": 21.7106
            },
            "Bolintin Vale": {
                "lat": 44.4472,
                "lng": 25.7572
            },
            "Brad": {
                "lat": 46.1294,
                "lng": 22.79
            },
            "Topli\u0163a": {
                "lat": 46.9236,
                "lng": 25.3458
            },
            "Zimnicea": {
                "lat": 43.6522,
                "lng": 25.3681
            },
            "G\u0103e\u015fti": {
                "lat": 44.7194,
                "lng": 25.3197
            },
            "Vatra Dornei": {
                "lat": 47.3461,
                "lng": 25.3594
            },
            "Avrig": {
                "lat": 45.7081,
                "lng": 24.3747
            },
            "Ocna Mure\u015f": {
                "lat": 46.39,
                "lng": 23.86
            },
            "S\u00e2ntana": {
                "lat": 46.35,
                "lng": 21.5
            },
            "V\u0103lenii de Munte": {
                "lat": 45.1856,
                "lng": 26.0397
            },
            "Pecica": {
                "lat": 46.17,
                "lng": 21.07
            },
            "Darabani": {
                "lat": 48.1864,
                "lng": 26.5892
            },
            "S\u00e2npetru": {
                "lat": 45.7167,
                "lng": 25.6333
            },
            "Matca": {
                "lat": 45.85,
                "lng": 27.5333
            },
            "Valea Ad\u00e2nc\u0103": {
                "lat": 47.1256,
                "lng": 27.5565
            },
            "Domne\u015fti": {
                "lat": 44.4,
                "lng": 25.9167
            },
            "M\u0103r\u0103\u015fe\u015fti": {
                "lat": 45.88,
                "lng": 27.23
            },
            "Simeria": {
                "lat": 45.85,
                "lng": 23.01
            },
            "Beclean": {
                "lat": 47.1797,
                "lng": 24.1797
            },
            "Marginea": {
                "lat": 47.8167,
                "lng": 25.8333
            },
            "T\u00e2rgu L\u0103pu\u015f": {
                "lat": 47.4525,
                "lng": 23.8631
            },
            "Comarnic": {
                "lat": 45.2511,
                "lng": 25.6353
            },
            "Dolhasca": {
                "lat": 47.4303,
                "lng": 26.6094
            },
            "S\u00e2ngeorz-B\u0103i": {
                "lat": 47.37,
                "lng": 24.68
            },
            "Scornice\u015fti": {
                "lat": 44.57,
                "lng": 24.55
            },
            "S\u0103cueni": {
                "lat": 47.3525,
                "lng": 22.0914
            },
            "S\u00e2nnicolau Mare": {
                "lat": 46.0636,
                "lng": 20.6125
            },
            "Jilava": {
                "lat": 44.3328,
                "lng": 26.0781
            },
            "Lunca Cet\u0103\u0163uii": {
                "lat": 47.0931,
                "lng": 27.5662
            },
            "Fl\u0103m\u00e2nzi": {
                "lat": 47.55,
                "lng": 26.8667
            },
            "T\u00e2rgu Ocna": {
                "lat": 46.28,
                "lng": 26.62
            },
            "H\u00e2rl\u0103u": {
                "lat": 47.4278,
                "lng": 26.9114
            },
            "D\u0103buleni": {
                "lat": 43.8011,
                "lng": 24.0919
            },
            "Bolde\u015fti-Sc\u0103eni": {
                "lat": 45.03,
                "lng": 26.03
            },
            "Rovinari": {
                "lat": 44.9125,
                "lng": 23.1622
            },
            "N\u0103s\u0103ud": {
                "lat": 47.2833,
                "lng": 24.4067
            },
            "Pechea": {
                "lat": 45.6333,
                "lng": 27.8
            },
            "Jimbolia": {
                "lat": 45.7931,
                "lng": 20.7375
            },
            "Urla\u0163i": {
                "lat": 44.9911,
                "lng": 26.2306
            },
            "Videle": {
                "lat": 44.2833,
                "lng": 25.5333
            },
            "C\u0103lan": {
                "lat": 45.7319,
                "lng": 23.0247
            },
            "Lipova": {
                "lat": 46.0917,
                "lng": 21.6917
            },
            "Fundeni": {
                "lat": 44.4595,
                "lng": 26.1668
            },
            "Mogo\u015foaia": {
                "lat": 44.5333,
                "lng": 26.0
            },
            "Beiu\u015f": {
                "lat": 46.65,
                "lng": 22.35
            },
            "Dr\u0103g\u0103ne\u015fti-Olt": {
                "lat": 44.1697,
                "lng": 24.53
            },
            "Sovata": {
                "lat": 46.5961,
                "lng": 25.0744
            },
            "Jibou": {
                "lat": 47.2667,
                "lng": 23.25
            },
            "Ale\u015fd": {
                "lat": 47.0572,
                "lng": 22.3969
            },
            "T\u00e2rgu Frumos": {
                "lat": 47.2097,
                "lng": 27.0131
            },
            "Salcea": {
                "lat": 47.65,
                "lng": 26.37
            },
            "S\u0103b\u0103oani": {
                "lat": 47.0167,
                "lng": 26.85
            },
            "Nehoiu": {
                "lat": 45.3531,
                "lng": 26.305
            },
            "Coste\u015fti": {
                "lat": 44.6697,
                "lng": 24.88
            },
            "Odobe\u015fti": {
                "lat": 45.7667,
                "lng": 27.0667
            },
            "Topoloveni": {
                "lat": 44.8069,
                "lng": 25.0839
            },
            "Oravi\u0163a": {
                "lat": 45.0403,
                "lng": 21.6853
            },
            "Moisei": {
                "lat": 47.6561,
                "lng": 24.5381
            },
            "Titu": {
                "lat": 44.6622,
                "lng": 25.5736
            },
            "Poienile de sub Munte": {
                "lat": 47.8167,
                "lng": 24.4333
            },
            "Moldova Nou\u0103": {
                "lat": 44.7178,
                "lng": 21.6639
            },
            "Babadag": {
                "lat": 44.8981,
                "lng": 28.7419
            },
            "Covasna": {
                "lat": 45.8492,
                "lng": 26.1853
            },
            "Murfatlar": {
                "lat": 44.1736,
                "lng": 28.4083
            },
            "S\u00e2ngeorgiu de Mure\u015f": {
                "lat": 46.55,
                "lng": 24.4667
            },
            "Cajvana": {
                "lat": 47.7044,
                "lng": 25.9694
            },
            "Tunari": {
                "lat": 44.55,
                "lng": 26.1333
            },
            "Sinaia": {
                "lat": 45.35,
                "lng": 25.5514
            },
            "Strehaia": {
                "lat": 44.6222,
                "lng": 23.1972
            },
            "Poiana Mare": {
                "lat": 43.9333,
                "lng": 23.0833
            },
            "Podu Iloaiei": {
                "lat": 47.2167,
                "lng": 27.2667
            },
            "Apahida": {
                "lat": 46.8078,
                "lng": 23.74
            },
            "Valea lui Mihai": {
                "lat": 47.52,
                "lng": 22.13
            },
            "Ianca": {
                "lat": 45.135,
                "lng": 27.4747
            },
            "Liteni": {
                "lat": 47.52,
                "lng": 26.5319
            },
            "Baciu": {
                "lat": 46.7928,
                "lng": 23.525
            },
            "Ineu": {
                "lat": 46.4258,
                "lng": 21.8369
            },
            "Cristuru Secuiesc": {
                "lat": 46.2917,
                "lng": 25.0353
            },
            "Ha\u0163eg": {
                "lat": 45.6075,
                "lng": 22.95
            },
            "Afuma\u0163i": {
                "lat": 44.525,
                "lng": 26.2522
            },
            "H\u00e2r\u015fova": {
                "lat": 44.6833,
                "lng": 27.9519
            },
            "Tome\u015fti": {
                "lat": 47.1333,
                "lng": 27.7
            },
            "Or\u015fova": {
                "lat": 44.7253,
                "lng": 22.3961
            },
            "Lie\u015fti": {
                "lat": 45.6193,
                "lng": 27.5416
            }
        }
    },
    "LK": {
        "name": "Sri Lanka",
        "cities": {
            "Colombo": {
                "lat": 6.9344,
                "lng": 79.8428
            },
            "Mount Lavinia": {
                "lat": 6.8731,
                "lng": 79.8758
            },
            "Kesbewa": {
                "lat": 6.7953,
                "lng": 79.9386
            },
            "Maharagama": {
                "lat": 6.8494,
                "lng": 79.9236
            },
            "Moratuwa": {
                "lat": 6.7991,
                "lng": 79.8767
            },
            "Ratnapura": {
                "lat": 6.6806,
                "lng": 80.4022
            },
            "Negombo": {
                "lat": 7.2111,
                "lng": 79.8386
            },
            "Kandy": {
                "lat": 7.2964,
                "lng": 80.635
            },
            "Sri Jayewardenepura Kotte": {
                "lat": 6.9108,
                "lng": 79.8878
            },
            "Mawanella": {
                "lat": 7.2534,
                "lng": 80.4466
            },
            "Kotmale": {
                "lat": 7.0167,
                "lng": 80.5833
            },
            "Kalmunai": {
                "lat": 7.4167,
                "lng": 81.8167
            },
            "Kilinochchi": {
                "lat": 9.4004,
                "lng": 80.3999
            },
            "Hikkaduwa": {
                "lat": 6.1472,
                "lng": 80.1103
            },
            "Trincomalee": {
                "lat": 8.5667,
                "lng": 81.2333
            },
            "Galle": {
                "lat": 6.0328,
                "lng": 80.2156
            },
            "Jaffna": {
                "lat": 9.6647,
                "lng": 80.0167
            },
            "Kalpitiya": {
                "lat": 8.1667,
                "lng": 79.7167
            },
            "Batticaloa": {
                "lat": 7.7167,
                "lng": 81.7
            },
            "Athurugiriya": {
                "lat": 6.8922,
                "lng": 79.9428
            },
            "Tissamaharama": {
                "lat": 6.2833,
                "lng": 81.2833
            },
            "Mawatagama": {
                "lat": 7.4245,
                "lng": 80.4399
            },
            "Weligama": {
                "lat": 5.9739,
                "lng": 80.4294
            },
            "Tangalla": {
                "lat": 6.0167,
                "lng": 80.7833
            },
            "Dambulla": {
                "lat": 7.8578,
                "lng": 80.6525
            },
            "Matara": {
                "lat": 5.95,
                "lng": 80.5333
            },
            "Kolonnawa": {
                "lat": 6.9283,
                "lng": 79.895
            },
            "Akurana": {
                "lat": 7.365,
                "lng": 80.6172
            },
            "Galgamuwa": {
                "lat": 8.0,
                "lng": 80.2831
            },
            "Anuradhapura": {
                "lat": 8.335,
                "lng": 80.4108
            },
            "Gampaha": {
                "lat": 7.0917,
                "lng": 79.9997
            },
            "Narammala": {
                "lat": 7.4344,
                "lng": 80.2214
            },
            "Dikwella South": {
                "lat": 5.9667,
                "lng": 80.6833
            },
            "Kalawana": {
                "lat": 6.5315,
                "lng": 80.3967
            },
            "Nikaweratiya": {
                "lat": 7.7475,
                "lng": 80.1156
            },
            "Puttalam": {
                "lat": 8.033,
                "lng": 79.826
            },
            "Bakamune": {
                "lat": 7.7831,
                "lng": 80.8183
            },
            "Badulla": {
                "lat": 6.9847,
                "lng": 81.0564
            },
            "Sevanagala": {
                "lat": 6.3867,
                "lng": 80.9092
            },
            "Matale": {
                "lat": 7.4686,
                "lng": 80.6228
            },
            "Vavuniya": {
                "lat": 8.75,
                "lng": 80.4833
            },
            "Gampola": {
                "lat": 7.1647,
                "lng": 80.5767
            },
            "Mullaittivu": {
                "lat": 9.2833,
                "lng": 80.8
            },
            "Kalutara": {
                "lat": 6.5869,
                "lng": 79.9603
            },
            "Bentota": {
                "lat": 6.42,
                "lng": 80.0
            },
            "Mannar": {
                "lat": 8.9667,
                "lng": 79.8833
            },
            "Bandarawela": {
                "lat": 6.8333,
                "lng": 80.9833
            },
            "Point Pedro": {
                "lat": 9.8167,
                "lng": 80.2333
            },
            "Pothuhera": {
                "lat": 7.4199,
                "lng": 80.3274
            },
            "Kurunegala": {
                "lat": 7.4833,
                "lng": 80.3667
            },
            "Mabole": {
                "lat": 7.0,
                "lng": 79.9
            },
            "Hakmana": {
                "lat": 6.0796,
                "lng": 80.6577
            },
            "Nuwara Eliya": {
                "lat": 6.9667,
                "lng": 80.7667
            },
            "Galhinna": {
                "lat": 7.4161,
                "lng": 80.5688
            },
            "Kegalle": {
                "lat": 7.2531,
                "lng": 80.3453
            },
            "Hatton": {
                "lat": 6.8897,
                "lng": 80.5981
            },
            "Gandara West": {
                "lat": 5.95,
                "lng": 80.55
            },
            "Hambantota": {
                "lat": 6.1244,
                "lng": 81.1225
            },
            "Abasingammedda": {
                "lat": 7.3167,
                "lng": 80.6667
            },
            "Monaragala": {
                "lat": 6.8726,
                "lng": 81.3373
            }
        }
    },
    "AZ": {
        "name": "Azerbaijan",
        "cities": {
            "Baku": {
                "lat": 40.3953,
                "lng": 49.8822
            },
            "Sumqay\u0131t": {
                "lat": 40.5897,
                "lng": 49.6686
            },
            "G\u0259nc\u0259": {
                "lat": 40.6828,
                "lng": 46.3606
            },
            "A\u011fcab\u0259di": {
                "lat": 40.0528,
                "lng": 47.4614
            },
            "Ming\u0259\u00e7evir": {
                "lat": 40.77,
                "lng": 47.0489
            },
            "X\u0131rdalan": {
                "lat": 40.4486,
                "lng": 49.7564
            },
            "Nax\u00e7\u0131van": {
                "lat": 39.2161,
                "lng": 45.4106
            },
            "\u015eatrovka": {
                "lat": 39.9319,
                "lng": 48.9203
            },
            "Qara\u00e7uxur": {
                "lat": 40.3967,
                "lng": 49.9736
            },
            "H\u00f6vsan": {
                "lat": 40.3744,
                "lng": 50.0853
            },
            "Bak\u0131xanov": {
                "lat": 40.4217,
                "lng": 49.9644
            },
            "\u015eirvan": {
                "lat": 39.9323,
                "lng": 48.9203
            },
            "\u015e\u0259ki": {
                "lat": 41.2,
                "lng": 47.1667
            },
            "Yevlax": {
                "lat": 40.6172,
                "lng": 47.15
            },
            "M.\u018f. R\u0259sulzad\u0259": {
                "lat": 40.4344,
                "lng": 49.8336
            },
            "L\u0259nk\u0259ran": {
                "lat": 38.7536,
                "lng": 48.8511
            },
            "Xank\u0259ndi": {
                "lat": 39.8153,
                "lng": 46.7519
            },
            "Bil\u0259c\u0259ri": {
                "lat": 40.4314,
                "lng": 49.8081
            },
            "Ma\u015fta\u011fa": {
                "lat": 40.5328,
                "lng": 50.0039
            },
            "\u015e\u0259mkir": {
                "lat": 40.8297,
                "lng": 46.0189
            },
            "Xa\u00e7maz": {
                "lat": 41.4708,
                "lng": 48.8097
            },
            "\u015eamax\u0131": {
                "lat": 40.6303,
                "lng": 48.6414
            },
            "B\u0259rd\u0259": {
                "lat": 40.3744,
                "lng": 47.1267
            },
            "Quba": {
                "lat": 41.3597,
                "lng": 48.5125
            },
            "G\u00f6yg\u00f6l": {
                "lat": 40.5869,
                "lng": 46.3158
            },
            "Bin\u0259q\u0259di": {
                "lat": 40.4661,
                "lng": 49.8278
            },
            "G\u00f6y\u00e7ay": {
                "lat": 40.6531,
                "lng": 47.7406
            },
            "L\u00f6kbatan": {
                "lat": 40.3272,
                "lng": 49.73
            },
            "Qazax": {
                "lat": 41.0933,
                "lng": 45.3661
            },
            "Buzovna": {
                "lat": 40.5161,
                "lng": 50.1164
            },
            "\u0130mi\u015fli": {
                "lat": 39.8697,
                "lng": 48.06
            },
            "Zaqatala": {
                "lat": 41.6336,
                "lng": 46.6433
            },
            "Sabirabad": {
                "lat": 40.0128,
                "lng": 48.4789
            },
            "Salyan": {
                "lat": 39.595,
                "lng": 48.9792
            },
            "A\u011fda\u015f": {
                "lat": 40.65,
                "lng": 47.4761
            },
            "\u018fmircan": {
                "lat": 40.4264,
                "lng": 49.9836
            },
            "Hac\u0131qabul": {
                "lat": 40.0433,
                "lng": 48.9356
            },
            "Bin\u0259": {
                "lat": 40.4539,
                "lng": 50.0764
            },
            "C\u0259lilabad": {
                "lat": 39.2089,
                "lng": 48.4972
            },
            "Ke\u015fl\u0259": {
                "lat": 40.3978,
                "lng": 49.8986
            },
            "M\u0259rd\u0259kan": {
                "lat": 40.4922,
                "lng": 50.1389
            },
            "Zabrat": {
                "lat": 40.4872,
                "lng": 49.9525
            },
            "Sabun\u00e7u": {
                "lat": 40.4425,
                "lng": 49.9481
            },
            "Sahil": {
                "lat": 40.22,
                "lng": 49.5714
            },
            "Hac\u0131 Zeynalabdin": {
                "lat": 40.6233,
                "lng": 49.5586
            },
            "\u0130smay\u0131ll\u0131": {
                "lat": 40.79,
                "lng": 48.1519
            },
            "A\u011fsu": {
                "lat": 40.5692,
                "lng": 48.4008
            },
            "A\u011fstafa": {
                "lat": 41.1189,
                "lng": 45.4539
            },
            "Bil\u0259suvar": {
                "lat": 39.4583,
                "lng": 48.545
            },
            "Siy\u0259z\u0259n": {
                "lat": 41.0761,
                "lng": 49.1139
            },
            "Saatl\u0131": {
                "lat": 39.9311,
                "lng": 48.3697
            },
            "B\u00fclb\u00fcl\u0259": {
                "lat": 40.4342,
                "lng": 49.9758
            },
            "Hindarx": {
                "lat": 40.07,
                "lng": 47.2047
            },
            "Astara": {
                "lat": 38.4561,
                "lng": 48.8786
            },
            "Qusar": {
                "lat": 41.4264,
                "lng": 48.4356
            },
            "Yeni Suraxan\u0131": {
                "lat": 40.4311,
                "lng": 50.0361
            },
            "Qazyan": {
                "lat": 40.4425,
                "lng": 47.6767
            },
            "\u018frkivan": {
                "lat": 39.0183,
                "lng": 48.65
            },
            "K\u00fcrd\u0259mir": {
                "lat": 40.3383,
                "lng": 48.1608
            },
            "\u015e\u00fcv\u0259lan": {
                "lat": 40.4889,
                "lng": 50.2069
            },
            "Masall\u0131": {
                "lat": 39.0342,
                "lng": 48.6656
            },
            "Qobustan": {
                "lat": 40.5367,
                "lng": 48.9328
            },
            "Ucar": {
                "lat": 40.5183,
                "lng": 47.6542
            },
            "Neft\u00e7ala": {
                "lat": 39.3586,
                "lng": 49.2469
            },
            "Nehr\u0259m": {
                "lat": 39.1122,
                "lng": 45.4611
            },
            "Pirallah\u0131": {
                "lat": 40.4708,
                "lng": 50.3217
            },
            "Tovuz": {
                "lat": 40.9922,
                "lng": 45.6289
            },
            "Ba\u015f G\u00f6yn\u00fck": {
                "lat": 41.3228,
                "lng": 47.1133
            },
            "Quzanl\u0131": {
                "lat": 40.16,
                "lng": 47.1722
            },
            "Corat": {
                "lat": 40.5725,
                "lng": 49.7064
            },
            "Qazmalar": {
                "lat": 40.9814,
                "lng": 47.8458
            },
            "\u018fl\u0259t": {
                "lat": 39.9411,
                "lng": 49.4061
            },
            "T\u0259rt\u0259r": {
                "lat": 40.345,
                "lng": 46.9289
            },
            "Culfa": {
                "lat": 38.9558,
                "lng": 45.6308
            },
            "Beyl\u0259qan": {
                "lat": 39.7756,
                "lng": 47.6186
            },
            "Badamdar": {
                "lat": 40.3394,
                "lng": 49.8039
            },
            "Balaxan\u0131": {
                "lat": 40.4617,
                "lng": 49.92
            },
            "Liman": {
                "lat": 38.8733,
                "lng": 48.8139
            },
            "Qax": {
                "lat": 41.4225,
                "lng": 46.9242
            },
            "Zir\u0259": {
                "lat": 40.3636,
                "lng": 50.2903
            },
            "\u018fhm\u0259dli": {
                "lat": 40.3781,
                "lng": 49.9619
            },
            "Xudat": {
                "lat": 41.6281,
                "lng": 48.6828
            },
            "\u018fliabad": {
                "lat": 39.2267,
                "lng": 45.3983
            },
            "Saray": {
                "lat": 40.5322,
                "lng": 49.7164
            },
            "Da\u015fk\u0259s\u0259n": {
                "lat": 40.5244,
                "lng": 46.1069
            },
            "Ordubad": {
                "lat": 38.9081,
                "lng": 46.0278
            },
            "Samux": {
                "lat": 40.77,
                "lng": 46.4111
            },
            "Qovlar": {
                "lat": 40.9419,
                "lng": 45.7358
            },
            "T\u00fcrkan": {
                "lat": 40.3639,
                "lng": 50.215
            },
            "Naftalan": {
                "lat": 40.5067,
                "lng": 46.825
            },
            "G\u00f6yt\u0259p\u0259": {
                "lat": 39.1167,
                "lng": 48.5953
            },
            "Bolad\u0131": {
                "lat": 38.8892,
                "lng": 48.7656
            },
            "A\u015fa\u011f\u0131 Ay\u0131bl\u0131": {
                "lat": 40.9347,
                "lng": 45.8261
            },
            "V\u0259ndam": {
                "lat": 40.9447,
                "lng": 47.9411
            },
            "S\u0259d\u0259r\u0259k": {
                "lat": 39.7175,
                "lng": 44.8764
            },
            "G\u0259d\u0259b\u0259y": {
                "lat": 40.5656,
                "lng": 45.8161
            },
            "Q\u0131z\u0131lhac\u0131l\u0131": {
                "lat": 40.5808,
                "lng": 46.8503
            },
            "A\u015fa\u011f\u0131 Qu\u015f\u00e7u": {
                "lat": 40.9533,
                "lng": 45.6792
            },
            "Z\u0259rdab": {
                "lat": 40.2183,
                "lng": 47.7083
            },
            "Yard\u0131ml\u0131": {
                "lat": 38.9206,
                "lng": 48.2372
            },
            "Goranboy": {
                "lat": 40.6103,
                "lng": 46.7897
            },
            "Lerik": {
                "lat": 38.7753,
                "lng": 48.4153
            },
            "O\u011fuz": {
                "lat": 41.0708,
                "lng": 47.4583
            },
            "C\u0259bray\u0131l": {
                "lat": 39.4,
                "lng": 47.0261
            },
            "Xocav\u0259nd": {
                "lat": 39.7953,
                "lng": 47.1131
            },
            "Bab\u0259k": {
                "lat": 39.1519,
                "lng": 45.4417
            },
            "\u015eu\u015fa": {
                "lat": 39.7583,
                "lng": 46.7483
            },
            "Q\u0131vraq": {
                "lat": 39.3997,
                "lng": 45.1131
            },
            "A\u011fdam": {
                "lat": 40.9053,
                "lng": 45.5564
            },
            "\u015eahbuz": {
                "lat": 39.4072,
                "lng": 45.5739
            },
            "La\u00e7\u0131n": {
                "lat": 39.6408,
                "lng": 46.5469
            },
            "Heyd\u0259rabad": {
                "lat": 39.7203,
                "lng": 44.8531
            },
            "Balak\u0259n": {
                "lat": 41.7258,
                "lng": 46.4083
            },
            "X\u0131z\u0131": {
                "lat": 40.9078,
                "lng": 49.0733
            },
            "Xocal\u0131": {
                "lat": 39.9111,
                "lng": 46.7892
            },
            "Qubadl\u0131": {
                "lat": 39.3439,
                "lng": 46.5797
            },
            "K\u0259lb\u0259c\u0259r": {
                "lat": 40.1067,
                "lng": 46.0383
            },
            "F\u00fczuli": {
                "lat": 39.6003,
                "lng": 47.1431
            },
            "D\u0259v\u0259\u00e7i": {
                "lat": 41.2012,
                "lng": 48.9871
            },
            "\u015e\u0259rur": {
                "lat": 39.5544,
                "lng": 44.9826
            },
            "Q\u0259b\u0259l\u0259": {
                "lat": 40.9825,
                "lng": 47.8491
            }
        }
    },
    "MG": {
        "name": "Madagascar",
        "cities": {
            "Antananarivo": {
                "lat": -18.91,
                "lng": 47.525
            },
            "Androtsy": {
                "lat": -24.1,
                "lng": 46.3
            },
            "Betsiboka": {
                "lat": -16.95,
                "lng": 46.83
            },
            "Antsirabe": {
                "lat": -19.8667,
                "lng": 47.0333
            },
            "Mahajanga": {
                "lat": -15.7167,
                "lng": 46.3167
            },
            "Toamasina": {
                "lat": -18.155,
                "lng": 49.41
            },
            "Fianarantsoa": {
                "lat": -21.4536,
                "lng": 47.0858
            },
            "Toliara": {
                "lat": -23.35,
                "lng": 43.6667
            },
            "Antsiranana": {
                "lat": -12.3,
                "lng": 49.2833
            },
            "Ambovombe": {
                "lat": -25.1764,
                "lng": 46.0833
            },
            "Ambohimangakely": {
                "lat": -18.9167,
                "lng": 47.6
            },
            "Sambava": {
                "lat": -14.2667,
                "lng": 50.1667
            },
            "Antehiroka": {
                "lat": -18.85,
                "lng": 47.4833
            },
            "Belo Tsiribihina": {
                "lat": -19.7,
                "lng": 44.55
            },
            "Imerintsiatosika": {
                "lat": -18.9833,
                "lng": 47.3167
            },
            "Antalaha": {
                "lat": -14.8833,
                "lng": 50.25
            },
            "Ankazoabokely": {
                "lat": -21.5036,
                "lng": 45.2094
            },
            "T\u00f4lan\u0308aro": {
                "lat": -25.0325,
                "lng": 46.9833
            },
            "Madirovalo": {
                "lat": -16.4333,
                "lng": 46.5333
            },
            "Ambilobe": {
                "lat": -13.2,
                "lng": 49.05
            },
            "Analavory": {
                "lat": -18.9667,
                "lng": 46.7167
            },
            "Ambanja": {
                "lat": -13.6786,
                "lng": 48.4522
            },
            "Andoharanofotsy": {
                "lat": -18.975,
                "lng": 47.5333
            },
            "Antsalova": {
                "lat": -18.6667,
                "lng": 44.6167
            },
            "Ambahikily": {
                "lat": -21.6,
                "lng": 43.6667
            },
            "Antsinanantsena": {
                "lat": -18.8333,
                "lng": 47.55
            },
            "Moramanga": {
                "lat": -18.9469,
                "lng": 48.2303
            },
            "Antanifotsy": {
                "lat": -16.8667,
                "lng": 49.6333
            },
            "Ankadinondry-Sakay": {
                "lat": -19.0,
                "lng": 46.4639
            },
            "Morondava": {
                "lat": -20.2847,
                "lng": 44.3175
            },
            "Ampanihy": {
                "lat": -24.6833,
                "lng": 44.75
            },
            "Ambohibary": {
                "lat": -18.9,
                "lng": 48.2333
            },
            "Tanjombato": {
                "lat": -18.95,
                "lng": 47.5167
            },
            "Ampitatafika": {
                "lat": -18.9333,
                "lng": 47.475
            },
            "Ejeda": {
                "lat": -24.35,
                "lng": 44.5167
            },
            "Ambatondrazaka": {
                "lat": -17.8256,
                "lng": 48.4289
            },
            "Saranambana": {
                "lat": -17.25,
                "lng": 49.1833
            },
            "Faratsiho": {
                "lat": -19.4,
                "lng": 46.95
            },
            "Amboasary": {
                "lat": -25.05,
                "lng": 46.3833
            },
            "Tsiroanomandidy": {
                "lat": -18.7698,
                "lng": 46.05
            },
            "Manakara": {
                "lat": -22.15,
                "lng": 48.0
            },
            "Talatamaty": {
                "lat": -18.8333,
                "lng": 47.45
            },
            "Maroantsetra": {
                "lat": -15.4333,
                "lng": 49.75
            },
            "Morarano Chrome": {
                "lat": -17.75,
                "lng": 48.1667
            },
            "Fenoarivo Atsinanana": {
                "lat": -17.3814,
                "lng": 49.4092
            },
            "Ambohitrimanjaka": {
                "lat": -18.8667,
                "lng": 47.4333
            },
            "Ambositra": {
                "lat": -20.5167,
                "lng": 47.25
            },
            "Soavinandriana": {
                "lat": -19.1667,
                "lng": 46.7333
            },
            "Matsoandakana": {
                "lat": -14.9667,
                "lng": 49.25
            },
            "Benenitra": {
                "lat": -23.4522,
                "lng": 45.0781
            },
            "Ihosy": {
                "lat": -22.4036,
                "lng": 46.1258
            },
            "Marovoay": {
                "lat": -16.1111,
                "lng": 46.6439
            },
            "Androka": {
                "lat": -25.0219,
                "lng": 44.0749
            },
            "Ambatomainty": {
                "lat": -20.9,
                "lng": 46.45
            },
            "Vangaindrano": {
                "lat": -23.35,
                "lng": 47.6
            },
            "Antsohihy": {
                "lat": -14.8762,
                "lng": 47.9834
            },
            "Mahanoro": {
                "lat": -19.9,
                "lng": 48.8
            },
            "Ambatofinandrahana": {
                "lat": -20.55,
                "lng": 46.8
            },
            "Andilamena": {
                "lat": -17.0167,
                "lng": 48.5833
            },
            "Mandoto": {
                "lat": -19.5667,
                "lng": 46.2833
            },
            "Manazary": {
                "lat": -19.05,
                "lng": 46.8667
            },
            "Anosiala": {
                "lat": -18.7833,
                "lng": 47.4167
            },
            "Ikongo": {
                "lat": -21.8833,
                "lng": 47.4333
            },
            "Vohipeno": {
                "lat": -22.35,
                "lng": 47.8333
            },
            "Ampasina-Maningory": {
                "lat": -17.2167,
                "lng": 49.4167
            },
            "Vavatenina": {
                "lat": -17.4667,
                "lng": 49.2
            },
            "Mahabo": {
                "lat": -24.2833,
                "lng": 45.2153
            },
            "Mahasolo": {
                "lat": -19.1167,
                "lng": 46.3667
            },
            "Mananara Avaratra": {
                "lat": -16.1667,
                "lng": 49.7667
            },
            "Ikalamavony": {
                "lat": -21.15,
                "lng": 46.5833
            },
            "Firavahana": {
                "lat": -18.6333,
                "lng": 46.8667
            },
            "Fotadrevo": {
                "lat": -24.05,
                "lng": 45.0167
            },
            "Farafangana": {
                "lat": -22.8167,
                "lng": 47.8167
            },
            "Andapa": {
                "lat": -14.65,
                "lng": 49.65
            },
            "Betafo": {
                "lat": -19.84,
                "lng": 46.855
            },
            "Ankazomiriotra": {
                "lat": -19.65,
                "lng": 46.5
            },
            "Maromandia": {
                "lat": -14.2167,
                "lng": 48.0833
            },
            "Mahasoabe": {
                "lat": -21.5833,
                "lng": 47.2167
            },
            "Amparafaravola": {
                "lat": -17.5833,
                "lng": 48.2167
            },
            "Didy": {
                "lat": -18.1167,
                "lng": 48.5333
            },
            "Ambodimanga II": {
                "lat": -17.2667,
                "lng": 49.1667
            },
            "Vohitromby": {
                "lat": -23.3,
                "lng": 47.5333
            },
            "Kiranomena": {
                "lat": -18.2833,
                "lng": 46.05
            },
            "Antsohimbondrona": {
                "lat": -13.0833,
                "lng": 48.85
            },
            "Andriba": {
                "lat": -17.5833,
                "lng": 46.9333
            },
            "Bekoratsaka": {
                "lat": -16.1,
                "lng": 47.5
            },
            "Vohilava": {
                "lat": -21.7667,
                "lng": 47.9167
            },
            "Itampolo": {
                "lat": -24.6833,
                "lng": 43.95
            },
            "Ambano": {
                "lat": -19.8,
                "lng": 47.0333
            },
            "Sakaraha": {
                "lat": -22.9167,
                "lng": 44.5497
            },
            "Ambatomiady": {
                "lat": -19.6833,
                "lng": 47.4167
            },
            "Manaratsandry": {
                "lat": -16.1833,
                "lng": 46.5
            },
            "Vohitrandriana": {
                "lat": -20.75,
                "lng": 48.2667
            },
            "Andranovory": {
                "lat": -23.1333,
                "lng": 44.1333
            },
            "Andranomanelatra": {
                "lat": -19.7833,
                "lng": 47.1
            },
            "Tsitondroina": {
                "lat": -21.3,
                "lng": 45.9833
            },
            "Ambinanitelo": {
                "lat": -15.35,
                "lng": 49.5833
            },
            "Ambalavao": {
                "lat": -19.1,
                "lng": 47.5333
            },
            "Antsampandrano": {
                "lat": -19.5333,
                "lng": 47.5833
            },
            "Mananasy-Tsitakondaza": {
                "lat": -19.15,
                "lng": 46.65
            },
            "Antsahalava": {
                "lat": -19.5833,
                "lng": 47.4667
            },
            "Fandriana": {
                "lat": -20.2333,
                "lng": 47.3833
            },
            "Andoharanomaitso": {
                "lat": -21.4667,
                "lng": 46.95
            },
            "Fenoarivobe": {
                "lat": -18.4497,
                "lng": 46.5617
            },
            "Mampikony": {
                "lat": -16.0917,
                "lng": 47.6472
            },
            "Ambatolampy": {
                "lat": -18.9,
                "lng": 46.1167
            },
            "Arivonimamo": {
                "lat": -19.0333,
                "lng": 47.1667
            },
            "Tsinjoarivo": {
                "lat": -18.9333,
                "lng": 46.3167
            },
            "Sahavato": {
                "lat": -20.6,
                "lng": 48.3333
            },
            "Imito": {
                "lat": -20.4167,
                "lng": 47.3167
            },
            "Sandrandahy": {
                "lat": -20.35,
                "lng": 47.3
            },
            "Ambohijanahary": {
                "lat": -17.4,
                "lng": 48.3667
            },
            "Ambohitompoina": {
                "lat": -19.75,
                "lng": 47.5667
            },
            "Ampasimanolotra": {
                "lat": -18.8194,
                "lng": 49.0736
            },
            "Ambodibonara": {
                "lat": -20.3388,
                "lng": 48.5177
            },
            "Marolambo": {
                "lat": -20.05,
                "lng": 48.1167
            },
            "Behara": {
                "lat": -24.95,
                "lng": 46.3833
            },
            "Ambohijanaka": {
                "lat": -18.9833,
                "lng": 47.55
            },
            "Vohipaho": {
                "lat": -23.55,
                "lng": 47.5
            },
            "Tsaravary": {
                "lat": -21.25,
                "lng": 48.3
            },
            "Miandrarivo": {
                "lat": -18.9167,
                "lng": 45.9333
            },
            "Mandabe": {
                "lat": -21.05,
                "lng": 44.9333
            },
            "Andranomavo": {
                "lat": -16.5667,
                "lng": 45.5833
            },
            "Antsiatsiaka": {
                "lat": -17.0,
                "lng": 49.2
            },
            "Alakamisy Itenina": {
                "lat": -21.6333,
                "lng": 47.15
            },
            "Ambinanisakana": {
                "lat": -16.95,
                "lng": 49.5833
            },
            "Soanierana Ivongo": {
                "lat": -16.9167,
                "lng": 49.5833
            },
            "Ambodiangezoka": {
                "lat": -14.6,
                "lng": 49.5
            },
            "Beramanja": {
                "lat": -13.3333,
                "lng": 48.8667
            },
            "Anosipatrana": {
                "lat": -18.9333,
                "lng": 47.5
            },
            "Mahitsy": {
                "lat": -18.75,
                "lng": 47.35
            },
            "Ambatomborona": {
                "lat": -19.4,
                "lng": 47.1167
            },
            "Malaimbandy": {
                "lat": -20.3333,
                "lng": 45.6
            },
            "Mahambo": {
                "lat": -17.4833,
                "lng": 49.4667
            },
            "Ambatofotsy": {
                "lat": -21.75,
                "lng": 47.4833
            },
            "Tsarazaza": {
                "lat": -20.1333,
                "lng": 47.2333
            },
            "Maevatanana": {
                "lat": -16.95,
                "lng": 46.8333
            },
            "Andasibe": {
                "lat": -18.9333,
                "lng": 48.4167
            },
            "Maintirano": {
                "lat": -18.0667,
                "lng": 44.0167
            },
            "Fenoarivo": {
                "lat": -20.8667,
                "lng": 46.8833
            },
            "Mananjary": {
                "lat": -21.2311,
                "lng": 48.3419
            },
            "Ampahana": {
                "lat": -14.75,
                "lng": 50.2167
            },
            "Fidirana": {
                "lat": -19.5,
                "lng": 46.5667
            },
            "Mahatalaky": {
                "lat": -24.7833,
                "lng": 47.0833
            },
            "Ankazomborona": {
                "lat": -16.1167,
                "lng": 46.75
            },
            "Antsirabe Avaratra": {
                "lat": -13.9667,
                "lng": 49.9667
            },
            "Tsaratanana": {
                "lat": -15.65,
                "lng": 47.85
            },
            "Sahasoa": {
                "lat": -21.9833,
                "lng": 45.95
            },
            "Anivorano Avaratra": {
                "lat": -12.7333,
                "lng": 49.2333
            },
            "Ambohimasina": {
                "lat": -19.65,
                "lng": 46.7333
            },
            "Mahaiza": {
                "lat": -19.9,
                "lng": 46.8167
            },
            "Ambalamanasy II": {
                "lat": -14.5167,
                "lng": 49.5833
            },
            "Anjozorobe": {
                "lat": -18.4033,
                "lng": 47.85
            },
            "Soamanandrariny": {
                "lat": -19.65,
                "lng": 47.2833
            },
            "Ambatotsipihina": {
                "lat": -19.6333,
                "lng": 47.75
            },
            "Ambohimandroso": {
                "lat": -21.8833,
                "lng": 46.95
            },
            "Lopary": {
                "lat": -23.1833,
                "lng": 47.6667
            },
            "Antonibe": {
                "lat": -15.1167,
                "lng": 47.4
            },
            "Antanimieva": {
                "lat": -22.2333,
                "lng": 43.7667
            },
            "Miarinarivo": {
                "lat": -22.0833,
                "lng": 47.05
            },
            "Tsiatosika": {
                "lat": -21.2,
                "lng": 48.2333
            },
            "Ambohimahamasina": {
                "lat": -21.9333,
                "lng": 47.1833
            },
            "Miantso": {
                "lat": -18.7167,
                "lng": 47.15
            },
            "Antanimasaka": {
                "lat": -16.2333,
                "lng": 46.5167
            },
            "Ambohimiadana": {
                "lat": -19.2333,
                "lng": 47.7833
            },
            "Mangidy": {
                "lat": -21.25,
                "lng": 46.8
            },
            "Ambohitrarivo": {
                "lat": -17.4667,
                "lng": 48.35
            },
            "Mahela": {
                "lat": -19.4833,
                "lng": 48.4833
            },
            "Farahalana": {
                "lat": -14.4333,
                "lng": 50.1667
            },
            "Analamisampy": {
                "lat": -22.4667,
                "lng": 43.65
            },
            "Ambohitoaka": {
                "lat": -16.0167,
                "lng": 47.65
            },
            "Miantsoarivo": {
                "lat": -19.2,
                "lng": 47.4
            },
            "Ankaramy": {
                "lat": -13.9833,
                "lng": 48.2
            },
            "Marovato": {
                "lat": -25.5333,
                "lng": 45.2667
            },
            "Ankilizato": {
                "lat": -20.4,
                "lng": 45.05
            },
            "Andalatanosy": {
                "lat": -24.6667,
                "lng": 45.6
            },
            "Ivato": {
                "lat": -20.6167,
                "lng": 47.2
            },
            "Andoany": {
                "lat": -13.4,
                "lng": 48.2666
            },
            "Morombe": {
                "lat": -21.75,
                "lng": 43.3667
            },
            "Boriziny": {
                "lat": -15.567,
                "lng": 47.621
            },
            "Betsizaraina": {
                "lat": -19.95,
                "lng": 48.75
            },
            "Manakambahiny": {
                "lat": -17.8,
                "lng": 48.3333
            },
            "Ambodimotso Atsimo": {
                "lat": -15.3833,
                "lng": 48.6
            },
            "Ampasimanjeva": {
                "lat": -21.7333,
                "lng": 48.0333
            },
            "Iarintsena": {
                "lat": -21.8167,
                "lng": 46.8667
            },
            "Andranovorivato": {
                "lat": -21.6333,
                "lng": 46.9833
            },
            "Bemanonga": {
                "lat": -20.2833,
                "lng": 44.35
            },
            "Vohimasina": {
                "lat": -21.7333,
                "lng": 48.1333
            },
            "Andilanatoby": {
                "lat": -17.9333,
                "lng": 48.2333
            },
            "Alakamisy-Ambohimaha": {
                "lat": -21.3167,
                "lng": 47.2167
            },
            "Ambalaroka": {
                "lat": -22.1333,
                "lng": 47.7333
            },
            "Jafaro": {
                "lat": -24.9167,
                "lng": 45.5167
            },
            "Ankiabe-Salohy": {
                "lat": -15.6,
                "lng": 48.7167
            },
            "Antsakabary": {
                "lat": -15.05,
                "lng": 48.9333
            },
            "Ampanotokana": {
                "lat": -18.7167,
                "lng": 47.3
            },
            "Ambatomanoina": {
                "lat": -18.3167,
                "lng": 47.6167
            },
            "Lakato": {
                "lat": -19.1833,
                "lng": 48.4333
            },
            "Ambohitromby": {
                "lat": -18.4333,
                "lng": 47.15
            },
            "Ambinanindrano": {
                "lat": -20.65,
                "lng": 47.8
            },
            "Fierenana": {
                "lat": -18.4833,
                "lng": 48.4
            },
            "Morafeno": {
                "lat": -14.4,
                "lng": 50.0333
            },
            "Sitampiky": {
                "lat": -16.6667,
                "lng": 46.1
            },
            "Ambalavato": {
                "lat": -22.9667,
                "lng": 47.4667
            },
            "Tongobory": {
                "lat": -23.5167,
                "lng": 44.3333
            },
            "Tsarasaotra": {
                "lat": -20.4333,
                "lng": 47.2333
            },
            "Ambohipandrano": {
                "lat": -19.15,
                "lng": 47.3667
            },
            "Andolofotsy": {
                "lat": -18.75,
                "lng": 46.6333
            },
            "Soanindrariny": {
                "lat": -19.9,
                "lng": 47.2333
            },
            "Ankililoaka": {
                "lat": -22.7667,
                "lng": 43.6167
            },
            "Tsiamalao": {
                "lat": -15.05,
                "lng": 48.45
            },
            "Fiadanana": {
                "lat": -20.3333,
                "lng": 47.4333
            },
            "Antanambao": {
                "lat": -15.1667,
                "lng": 50.3667
            },
            "Sahamadio": {
                "lat": -20.3,
                "lng": 47.4
            },
            "Miorimivalana": {
                "lat": -17.2167,
                "lng": 49.2
            },
            "Ambohimanambola": {
                "lat": -18.95,
                "lng": 47.6
            },
            "Ampasimatera": {
                "lat": -15.9333,
                "lng": 47.75
            },
            "Karianga": {
                "lat": -22.4167,
                "lng": 47.3667
            },
            "Matanga": {
                "lat": -23.5167,
                "lng": 47.55
            },
            "Ankaraobato": {
                "lat": -18.9,
                "lng": 47.4667
            },
            "Ambohimanga": {
                "lat": -18.7667,
                "lng": 47.5667
            },
            "Vinany": {
                "lat": -19.6167,
                "lng": 46.4833
            },
            "Ambondro": {
                "lat": -25.25,
                "lng": 45.8333
            },
            "Miandrivazo": {
                "lat": -19.5239,
                "lng": 45.4573
            },
            "Beforona": {
                "lat": -18.9667,
                "lng": 48.5833
            },
            "Alatsinainy-Bakaro": {
                "lat": -19.3167,
                "lng": 47.6833
            },
            "Mahaditra": {
                "lat": -21.75,
                "lng": 47.1
            },
            "Ampasimena": {
                "lat": -24.3667,
                "lng": 47.1667
            },
            "Mandrosohasina": {
                "lat": -19.5833,
                "lng": 47.1667
            },
            "Ambalakirajy": {
                "lat": -15.8333,
                "lng": 48.5
            },
            "Rantabe": {
                "lat": -15.7,
                "lng": 49.65
            },
            "Mandiavato": {
                "lat": -19.0833,
                "lng": 47.0167
            },
            "Ambatosoratra": {
                "lat": -17.6167,
                "lng": 48.5167
            },
            "Tsararafa": {
                "lat": -22.7333,
                "lng": 47.8167
            },
            "Analapatsy": {
                "lat": -24.25,
                "lng": 47.2
            },
            "Tsarabaria": {
                "lat": -13.75,
                "lng": 49.95
            },
            "Soavina Antanety": {
                "lat": -19.8,
                "lng": 46.7333
            },
            "Bekatra Maromiandra": {
                "lat": -22.1,
                "lng": 47.7167
            },
            "Vasiana": {
                "lat": -19.6333,
                "lng": 46.2333
            },
            "Ampefy": {
                "lat": -19.0419,
                "lng": 46.7328
            },
            "Doany": {
                "lat": -14.3667,
                "lng": 49.5167
            },
            "Nosy Varika": {
                "lat": -20.5833,
                "lng": 48.5333
            },
            "Andaingo Gara": {
                "lat": -18.2,
                "lng": 48.2833
            },
            "Gogogogo": {
                "lat": -24.2667,
                "lng": 44.8
            },
            "Amboanana": {
                "lat": -19.15,
                "lng": 47.1667
            },
            "Ambatomirahavavy": {
                "lat": -18.9333,
                "lng": 47.3833
            },
            "Ampanety": {
                "lat": -18.975,
                "lng": 47.4944
            },
            "Itaosy": {
                "lat": -18.9167,
                "lng": 47.4667
            },
            "Befandriana Atsimo": {
                "lat": -22.1,
                "lng": 43.8833
            },
            "Anjahabe": {
                "lat": -16.3667,
                "lng": 46.6167
            },
            "Ambalanirana": {
                "lat": -18.75,
                "lng": 46.5
            },
            "Ambatomena": {
                "lat": -19.85,
                "lng": 47.25
            },
            "Mitsinjo": {
                "lat": -16.0,
                "lng": 45.8667
            },
            "Belamoty": {
                "lat": -23.55,
                "lng": 44.8
            },
            "Vohimasy": {
                "lat": -22.1,
                "lng": 47.7833
            },
            "Ampataka": {
                "lat": -23.35,
                "lng": 47.4667
            },
            "Ifanirea": {
                "lat": -22.1833,
                "lng": 47.4667
            },
            "Tanambe": {
                "lat": -17.3667,
                "lng": 48.45
            },
            "Ambodinonoka": {
                "lat": -20.9833,
                "lng": 47.8833
            },
            "Miarinavaratra": {
                "lat": -20.2167,
                "lng": 47.5167
            },
            "Ambatosia": {
                "lat": -14.6667,
                "lng": 48.65
            },
            "Mahazoarivo": {
                "lat": -20.3833,
                "lng": 47.4333
            },
            "Manantenina": {
                "lat": -24.2833,
                "lng": 47.3167
            },
            "Bezaha": {
                "lat": -23.5,
                "lng": 44.5
            },
            "Ranomena": {
                "lat": -23.4167,
                "lng": 47.2667
            },
            "Fihaonana": {
                "lat": -18.6,
                "lng": 47.2
            },
            "Antotohazo": {
                "lat": -18.4667,
                "lng": 47.0833
            },
            "Tsivory": {
                "lat": -24.0683,
                "lng": 46.0756
            },
            "Antombana": {
                "lat": -15.0,
                "lng": 50.1667
            },
            "Talata-Volonondry": {
                "lat": -18.75,
                "lng": 47.65
            },
            "Kiangara": {
                "lat": -17.9667,
                "lng": 47.0333
            },
            "Antsampanimahazo": {
                "lat": -19.64,
                "lng": 47.158
            },
            "Antanambe": {
                "lat": -16.4333,
                "lng": 49.85
            },
            "Manampatrana": {
                "lat": -21.65,
                "lng": 47.5833
            },
            "Alakamisy": {
                "lat": -20.2,
                "lng": 47.3167
            },
            "Maroteza": {
                "lat": -22.3,
                "lng": 47.2
            },
            "Bemahatazana-Belobaka": {
                "lat": -19.35,
                "lng": 46.0
            },
            "Vohilengo": {
                "lat": -22.5333,
                "lng": 47.6833
            },
            "Amboahangibe": {
                "lat": -14.1333,
                "lng": 49.8167
            },
            "Mahazoma": {
                "lat": -17.1667,
                "lng": 46.5667
            },
            "Ambongo": {
                "lat": -23.45,
                "lng": 47.2667
            },
            "Alatsinainy Ialamarina": {
                "lat": -21.5333,
                "lng": 47.3
            },
            "Analaiva": {
                "lat": -20.3333,
                "lng": 44.5
            },
            "Maroaloka": {
                "lat": -25.1667,
                "lng": 46.2
            },
            "Belobaka": {
                "lat": -15.6833,
                "lng": 46.4167
            },
            "Ankofa": {
                "lat": -15.4,
                "lng": 49.65
            },
            "Matsakabanja": {
                "lat": -15.9167,
                "lng": 45.8333
            },
            "Ambovombe Afovoany": {
                "lat": -20.7667,
                "lng": 47.0833
            },
            "Tsianisiha": {
                "lat": -22.9,
                "lng": 43.5833
            },
            "Beantake": {
                "lat": -23.8,
                "lng": 44.3
            },
            "Behenjy": {
                "lat": -19.2,
                "lng": 47.4833
            },
            "Tranoroa": {
                "lat": -24.7,
                "lng": 45.0667
            },
            "Manambaro": {
                "lat": -25.0333,
                "lng": 46.8167
            },
            "Ambohitralanana": {
                "lat": -15.2333,
                "lng": 50.45
            },
            "Fanandrana": {
                "lat": -18.1944,
                "lng": 49.3792
            },
            "Ambolomadinika": {
                "lat": -21.95,
                "lng": 47.4333
            },
            "Morarano-Gara": {
                "lat": -18.71,
                "lng": 48.27
            },
            "Ambatolahy": {
                "lat": -19.7333,
                "lng": 47.2833
            },
            "Anosiarivo": {
                "lat": -19.9167,
                "lng": 47.0333
            },
            "Betroka": {
                "lat": -23.2683,
                "lng": 46.1044
            },
            "Ilaka Atsinanana": {
                "lat": -19.5531,
                "lng": 48.8408
            },
            "Tsararivotra": {
                "lat": -19.1714,
                "lng": 47.55
            },
            "Marosangy": {
                "lat": -21.0,
                "lng": 48.3
            },
            "Tolongoina": {
                "lat": -21.55,
                "lng": 47.5167
            },
            "Etrotroka": {
                "lat": -22.8833,
                "lng": 47.6
            },
            "Ilakatra": {
                "lat": -22.35,
                "lng": 47.6667
            },
            "Ambila": {
                "lat": -21.9833,
                "lng": 47.95
            },
            "Marofoty": {
                "lat": -22.9333,
                "lng": 43.5667
            },
            "Ambohitrolomahitsy": {
                "lat": -18.7,
                "lng": 47.6833
            },
            "Lazarivo": {
                "lat": -23.9,
                "lng": 44.95
            },
            "Milanoa": {
                "lat": -13.5833,
                "lng": 49.7833
            },
            "Isoanala": {
                "lat": -23.8333,
                "lng": 45.7167
            },
            "Amboanjo": {
                "lat": -22.0,
                "lng": 47.85
            },
            "Befotaka": {
                "lat": -20.8333,
                "lng": 45.6667
            },
            "Tsarahonenana": {
                "lat": -15.4833,
                "lng": 48.4833
            },
            "Antanimora Atsinanana": {
                "lat": -24.8167,
                "lng": 45.6667
            },
            "Ankarongana": {
                "lat": -15.4167,
                "lng": 48.9167
            },
            "Ambondromisotra": {
                "lat": -20.3333,
                "lng": 46.9167
            },
            "Ambohitsimanova": {
                "lat": -19.95,
                "lng": 47.1167
            },
            "Anosivelo": {
                "lat": -22.7333,
                "lng": 47.7667
            },
            "Manombo Atsimo": {
                "lat": -22.95,
                "lng": 43.4667
            },
            "Anosibe-Ifanja": {
                "lat": -18.8667,
                "lng": 46.75
            },
            "Beahitse": {
                "lat": -24.1667,
                "lng": 44.4333
            },
            "Antsahavaribe": {
                "lat": -13.85,
                "lng": 49.8667
            },
            "Ambohipihaonana": {
                "lat": -19.4333,
                "lng": 47.4167
            },
            "Mandritsara": {
                "lat": -19.55,
                "lng": 47.05
            },
            "Ambolidibe Atsinanana": {
                "lat": -15.1,
                "lng": 48.8
            },
            "Sadabe": {
                "lat": -18.6333,
                "lng": 47.7
            },
            "Analanampotsy": {
                "lat": -17.1667,
                "lng": 49.4833
            },
            "Andranofasika": {
                "lat": -16.3833,
                "lng": 46.9
            },
            "Fetromby": {
                "lat": -18.5833,
                "lng": 48.9167
            },
            "Antanambao Mahatsara": {
                "lat": -19.3167,
                "lng": 48.7833
            },
            "Sabotsy": {
                "lat": -19.2333,
                "lng": 47.6
            },
            "Sahatavy": {
                "lat": -17.4489,
                "lng": 48.9953
            },
            "Antsahanoro": {
                "lat": -14.8333,
                "lng": 50.1333
            },
            "Ambalabe": {
                "lat": -15.1667,
                "lng": 50.4167
            },
            "Ampahimanga": {
                "lat": -19.0833,
                "lng": 47.2
            },
            "Ambarakaraka": {
                "lat": -13.5,
                "lng": 48.8667
            },
            "Ranohira": {
                "lat": -22.4333,
                "lng": 45.35
            },
            "Mahavelona": {
                "lat": -19.1667,
                "lng": 46.5167
            },
            "Tsiningia": {
                "lat": -15.4833,
                "lng": 47.45
            },
            "Antindra": {
                "lat": -14.1333,
                "lng": 49.8167
            },
            "Andapafito": {
                "lat": -16.9167,
                "lng": 49.3833
            },
            "Manompana": {
                "lat": -16.6833,
                "lng": 49.7333
            },
            "Bevonotra": {
                "lat": -14.05,
                "lng": 49.6167
            },
            "Betanty": {
                "lat": -25.5667,
                "lng": 45.5167
            },
            "Mandrosonoro": {
                "lat": -20.5833,
                "lng": 46.0
            },
            "Ankazondandy": {
                "lat": -18.6833,
                "lng": 47.8167
            },
            "Androrangavola": {
                "lat": -21.5,
                "lng": 47.7167
            },
            "Alarobia": {
                "lat": -18.9667,
                "lng": 47.7333
            },
            "Inanantonana": {
                "lat": -19.65,
                "lng": 46.6167
            },
            "Ambalatany": {
                "lat": -22.5333,
                "lng": 47.6
            },
            "Andemaka": {
                "lat": -22.3,
                "lng": 47.75
            },
            "Vohiposa": {
                "lat": -20.9833,
                "lng": 47.15
            },
            "Anjangoveratra": {
                "lat": -14.1333,
                "lng": 50.0833
            },
            "Leanja": {
                "lat": -15.55,
                "lng": 47.9333
            },
            "Bekitro": {
                "lat": -24.55,
                "lng": 45.3
            },
            "Ambohibe": {
                "lat": -17.45,
                "lng": 49.1167
            },
            "Tangainony": {
                "lat": -22.7,
                "lng": 47.75
            },
            "Antsenavolo": {
                "lat": -21.4,
                "lng": 48.05
            },
            "Analila": {
                "lat": -14.45,
                "lng": 49.05
            },
            "Mangabe": {
                "lat": -16.95,
                "lng": 49.3333
            },
            "Ambohimalaza": {
                "lat": -18.9167,
                "lng": 47.65
            },
            "Antadinga": {
                "lat": -22.15,
                "lng": 47.4167
            },
            "Ambohidrapeto": {
                "lat": -18.9,
                "lng": 47.4667
            },
            "Androy": {
                "lat": -21.3333,
                "lng": 47.2667
            },
            "Sahambala": {
                "lat": -17.9417,
                "lng": 49.1083
            },
            "Vatomandry": {
                "lat": -19.3308,
                "lng": 48.9781
            },
            "Mandialaza": {
                "lat": -18.6167,
                "lng": 48.0333
            },
            "Ifanadiana": {
                "lat": -21.3,
                "lng": 47.6333
            },
            "Soavina": {
                "lat": -18.95,
                "lng": 47.4833
            },
            "Marivorahona": {
                "lat": -13.0833,
                "lng": 49.0833
            },
            "Isorana": {
                "lat": -21.3167,
                "lng": 46.9333
            },
            "Amboavory": {
                "lat": -17.3,
                "lng": 48.5
            },
            "Manohisoa": {
                "lat": -19.7833,
                "lng": 46.7667
            },
            "Andrakata": {
                "lat": -14.6167,
                "lng": 49.7167
            },
            "Ambongamarina": {
                "lat": -18.325,
                "lng": 47.9542
            },
            "Anosy Avaratra": {
                "lat": -18.8,
                "lng": 47.55
            },
            "Mahaly": {
                "lat": -24.1667,
                "lng": 46.35
            },
            "Imerimandroso": {
                "lat": -17.4333,
                "lng": 48.5833
            },
            "Ambohimierambe-Andranofito": {
                "lat": -19.7833,
                "lng": 47.4333
            },
            "Andondabe": {
                "lat": -17.7667,
                "lng": 49.3847
            },
            "Manambondro": {
                "lat": -23.8,
                "lng": 47.55
            },
            "Milenaka": {
                "lat": -22.8333,
                "lng": 43.5833
            },
            "Soalala": {
                "lat": -16.1,
                "lng": 45.3167
            },
            "Ambodiriana": {
                "lat": -17.8861,
                "lng": 49.1875
            },
            "Befasy": {
                "lat": -20.5667,
                "lng": 44.3667
            },
            "Kopoky": {
                "lat": -25.2,
                "lng": 45.2
            },
            "Ramainandro": {
                "lat": -19.3,
                "lng": 47.0
            },
            "Ambatomanjaka": {
                "lat": -18.8833,
                "lng": 46.8667
            },
            "Andranovelona": {
                "lat": -19.65,
                "lng": 47.6333
            },
            "Ianantsony": {
                "lat": -23.55,
                "lng": 43.7667
            },
            "Analamary": {
                "lat": -24.2333,
                "lng": 47.2167
            },
            "Imanombo": {
                "lat": -24.4333,
                "lng": 45.8167
            },
            "Beroy Atsimo": {
                "lat": -23.9833,
                "lng": 44.6667
            },
            "Alarobia Bemaha": {
                "lat": -20.2,
                "lng": 46.8833
            },
            "Talata Ampano": {
                "lat": -21.55,
                "lng": 47.0333
            },
            "Ambatoharanana": {
                "lat": -16.4333,
                "lng": 49.5833
            },
            "Sahave": {
                "lat": -21.0667,
                "lng": 47.1167
            },
            "Bevoay": {
                "lat": -24.4833,
                "lng": 47.0167
            },
            "Anahidrano": {
                "lat": -15.0833,
                "lng": 47.9333
            },
            "Ambahive": {
                "lat": -22.2,
                "lng": 47.7167
            },
            "Ifatsy": {
                "lat": -22.4,
                "lng": 47.75
            },
            "Ankisabe": {
                "lat": -19.2833,
                "lng": 46.4833
            },
            "Anjoma-Ramartina": {
                "lat": -19.6333,
                "lng": 45.9667
            },
            "Lokomby": {
                "lat": -22.1833,
                "lng": 47.75
            },
            "Behisatse": {
                "lat": -21.8,
                "lng": 45.1167
            },
            "Iharan\u0308a": {
                "lat": -13.35,
                "lng": 50.0
            },
            "Manandona": {
                "lat": -20.05,
                "lng": 47.0667
            },
            "Antanimenabaka": {
                "lat": -16.9333,
                "lng": 48.4833
            },
            "Marofototra": {
                "lat": -20.8667,
                "lng": 48.1667
            },
            "Tsiatajavona-Ankaratra": {
                "lat": -19.3833,
                "lng": 47.35
            },
            "Antsoso": {
                "lat": -19.8167,
                "lng": 46.8667
            },
            "Ambesisika": {
                "lat": -16.5167,
                "lng": 46.8667
            },
            "Ankilimivory": {
                "lat": -24.4667,
                "lng": 44.9667
            },
            "Anororo": {
                "lat": -17.5167,
                "lng": 48.4333
            },
            "Vinanivao": {
                "lat": -15.8833,
                "lng": 50.2667
            },
            "Sahakevo": {
                "lat": -20.2833,
                "lng": 47.8333
            },
            "Ankadinandriana": {
                "lat": -19.0667,
                "lng": 47.6667
            },
            "Mahatsinjo": {
                "lat": -22.8,
                "lng": 47.45
            },
            "Ranomafana": {
                "lat": -21.25,
                "lng": 47.45
            },
            "Mangamila": {
                "lat": -18.5667,
                "lng": 47.8583
            },
            "Solila": {
                "lat": -21.4167,
                "lng": 46.6167
            },
            "Iakora": {
                "lat": -23.1,
                "lng": 46.65
            },
            "Marofinaritra": {
                "lat": -15.0333,
                "lng": 50.1167
            },
            "Merimandroso": {
                "lat": -18.75,
                "lng": 47.5167
            },
            "Ankaramena": {
                "lat": -25.0167,
                "lng": 46.75
            },
            "Ampohibe": {
                "lat": -15.0333,
                "lng": 50.3
            },
            "Mantaly": {
                "lat": -13.1667,
                "lng": 48.9667
            },
            "Belazao": {
                "lat": -19.8833,
                "lng": 46.9667
            },
            "Ambohimanga Atsimo": {
                "lat": -20.8667,
                "lng": 47.6
            },
            "Mahavanona": {
                "lat": -12.45,
                "lng": 49.3667
            },
            "Beronono": {
                "lat": -18.2,
                "lng": 47.5167
            },
            "Zazafotsy": {
                "lat": -22.2,
                "lng": 46.35
            },
            "Antohobe": {
                "lat": -19.7667,
                "lng": 46.0167
            },
            "Beraketa": {
                "lat": -24.1833,
                "lng": 45.6833
            },
            "Ambahita": {
                "lat": -24.0,
                "lng": 45.2667
            },
            "Marotsiraka": {
                "lat": -24.2833,
                "lng": 45.9833
            },
            "Mahajamba": {
                "lat": -15.7,
                "lng": 47.1
            },
            "Ambatomarina": {
                "lat": -20.5833,
                "lng": 47.0167
            },
            "Antanambaobe": {
                "lat": -16.25,
                "lng": 49.6667
            },
            "Kalandy": {
                "lat": -15.75,
                "lng": 48.7333
            },
            "Bekipay": {
                "lat": -16.25,
                "lng": 46.1333
            },
            "Marokarima": {
                "lat": -21.2167,
                "lng": 48.1333
            },
            "Mahatsinjony": {
                "lat": -21.4167,
                "lng": 47.2
            },
            "Vanono": {
                "lat": -16.0333,
                "lng": 49.45
            },
            "Talata-Vohimena": {
                "lat": -20.85,
                "lng": 46.9667
            },
            "Vatolatsaka": {
                "lat": -23.3,
                "lng": 44.3
            },
            "Belambo": {
                "lat": -19.4,
                "lng": 47.5167
            },
            "Beharona": {
                "lat": -21.5167,
                "lng": 44.3
            },
            "Ambohimitombo": {
                "lat": -20.7167,
                "lng": 47.4333
            },
            "Tsarahasina": {
                "lat": -15.7667,
                "lng": 47.5833
            },
            "Bevato": {
                "lat": -18.6833,
                "lng": 46.25
            },
            "Ankiliabo": {
                "lat": -24.6833,
                "lng": 44.7167
            },
            "Amborondra": {
                "lat": -21.9167,
                "lng": 47.7667
            },
            "Bealanana": {
                "lat": -14.55,
                "lng": 48.7333
            },
            "Sahalanona": {
                "lat": -22.05,
                "lng": 47.6167
            },
            "Ambodihara": {
                "lat": -14.75,
                "lng": 48.9833
            },
            "Ambohimiera": {
                "lat": -21.05,
                "lng": 47.5
            },
            "Ampasinambo": {
                "lat": -20.5167,
                "lng": 48.0
            },
            "Amboasary-Gara": {
                "lat": -18.4333,
                "lng": 48.2667
            },
            "Ambodilazana": {
                "lat": -18.1194,
                "lng": 49.1208
            },
            "Ambohidronono": {
                "lat": -18.75,
                "lng": 48.0333
            },
            "Mahaboboka": {
                "lat": -22.9,
                "lng": 44.3333
            },
            "Betatao": {
                "lat": -18.2,
                "lng": 47.8833
            },
            "Ampanavoana": {
                "lat": -15.6667,
                "lng": 50.2
            },
            "Ambararata": {
                "lat": -15.0167,
                "lng": 48.6333
            },
            "Marovandrika": {
                "lat": -22.5167,
                "lng": 47.6667
            },
            "Andreba": {
                "lat": -14.5833,
                "lng": 48.1833
            },
            "Ampitasimo": {
                "lat": -17.7833,
                "lng": 48.3833
            },
            "Ambohimahavelona": {
                "lat": -23.4333,
                "lng": 43.8833
            },
            "Isaka-Ivondro": {
                "lat": -24.8,
                "lng": 46.85
            },
            "Ambodiampana": {
                "lat": -16.8167,
                "lng": 49.5667
            },
            "Andranomamy": {
                "lat": -16.6,
                "lng": 47.1333
            },
            "Ihorombe": {
                "lat": -23.0,
                "lng": 47.5333
            },
            "Antongomena-Bevary": {
                "lat": -15.95,
                "lng": 45.9333
            },
            "Antsirabe Afovoany": {
                "lat": -15.95,
                "lng": 48.9667
            },
            "Sahavalanina-Antenina": {
                "lat": -16.5667,
                "lng": 49.25
            },
            "Belemoka": {
                "lat": -21.7833,
                "lng": 47.6167
            },
            "Mavorano": {
                "lat": -21.7833,
                "lng": 47.9333
            },
            "Evato": {
                "lat": -22.6,
                "lng": 47.6833
            },
            "Tranovaho": {
                "lat": -25.3,
                "lng": 44.9667
            },
            "Amborompotsy": {
                "lat": -20.6,
                "lng": 46.25
            },
            "Ambalakindresy": {
                "lat": -21.1667,
                "lng": 47.3333
            },
            "Ambahoabe": {
                "lat": -16.7667,
                "lng": 49.5
            },
            "Vohitrindry": {
                "lat": -22.3833,
                "lng": 47.85
            },
            "Befandriana": {
                "lat": -15.2667,
                "lng": 48.5333
            },
            "Ampanefena": {
                "lat": -13.8667,
                "lng": 49.9667
            },
            "Ankilivalo": {
                "lat": -20.2833,
                "lng": 44.6333
            },
            "Anjiajia": {
                "lat": -16.4833,
                "lng": 46.9667
            },
            "Ambatondrakalavao": {
                "lat": -19.4333,
                "lng": 47.5
            },
            "Kirano": {
                "lat": -21.8333,
                "lng": 47.05
            },
            "Sakoabe": {
                "lat": -22.2,
                "lng": 47.8333
            },
            "Maroviro": {
                "lat": -21.2333,
                "lng": 44.7333
            },
            "Ambakireny": {
                "lat": -17.6667,
                "lng": 47.85
            },
            "Tsiately": {
                "lat": -23.3167,
                "lng": 47.6167
            },
            "Ambohitsilaozana": {
                "lat": -17.6833,
                "lng": 48.45
            },
            "Ambazoa": {
                "lat": -25.3139,
                "lng": 45.8972
            },
            "Ambodisakoana": {
                "lat": -15.4167,
                "lng": 47.9333
            },
            "Bemanevika": {
                "lat": -14.1333,
                "lng": 50.1
            },
            "Ambondrona": {
                "lat": -21.2667,
                "lng": 46.9833
            },
            "Bejofo": {
                "lat": -17.8333,
                "lng": 48.2333
            },
            "Manambolo": {
                "lat": -15.2833,
                "lng": 49.7
            },
            "Mangindrano": {
                "lat": -14.2833,
                "lng": 48.9667
            },
            "Ankilimalinika": {
                "lat": -22.95,
                "lng": 43.5667
            },
            "Sandrakatsy": {
                "lat": -16.3333,
                "lng": 49.6167
            },
            "Marojala": {
                "lat": -14.4833,
                "lng": 50.0333
            },
            "Ilafy": {
                "lat": -17.8833,
                "lng": 48.4167
            },
            "Morarano": {
                "lat": -19.4333,
                "lng": 47.4833
            },
            "Befeta": {
                "lat": -21.2333,
                "lng": 47.05
            },
            "Amboaboa": {
                "lat": -15.9333,
                "lng": 48.7167
            },
            "Manambidala": {
                "lat": -22.75,
                "lng": 47.2667
            },
            "Andohajango": {
                "lat": -15.9,
                "lng": 48.5
            },
            "Manalalondo": {
                "lat": -19.25,
                "lng": 47.1167
            },
            "Isalo": {
                "lat": -19.7333,
                "lng": 45.4333
            },
            "Ambalarondra": {
                "lat": -18.4667,
                "lng": 48.9833
            },
            "Sarahandrano": {
                "lat": -14.7833,
                "lng": 50.0167
            },
            "Antanandava": {
                "lat": -15.85,
                "lng": 48.8167
            },
            "Vohimarina": {
                "lat": -21.64,
                "lng": 46.8989
            },
            "Miary": {
                "lat": -23.3,
                "lng": 43.7333
            },
            "Anjahambe": {
                "lat": -17.3833,
                "lng": 49.1333
            },
            "Ambinanynony": {
                "lat": -18.6,
                "lng": 49.1167
            },
            "Ampasimbe": {
                "lat": -16.8167,
                "lng": 49.0
            },
            "Ambolomoty": {
                "lat": -16.1667,
                "lng": 46.7
            },
            "Fanambana": {
                "lat": -13.55,
                "lng": 49.9833
            },
            "Ivoamba": {
                "lat": -21.4,
                "lng": 47.1667
            },
            "Ankafina Tsarafidy": {
                "lat": -21.2,
                "lng": 47.25
            },
            "Andonabe": {
                "lat": -21.4667,
                "lng": 47.95
            },
            "Sorombo": {
                "lat": -22.1,
                "lng": 47.9167
            },
            "Itondy": {
                "lat": -19.05,
                "lng": 45.35
            },
            "Iarinarivo": {
                "lat": -18.9167,
                "lng": 47.4
            },
            "Saharefo": {
                "lat": -21.6667,
                "lng": 47.6833
            },
            "Vohindava": {
                "lat": -22.4167,
                "lng": 47.8667
            },
            "Beandrarezona": {
                "lat": -14.4833,
                "lng": 48.6833
            },
            "Andranovao": {
                "lat": -17.6167,
                "lng": 44.0667
            },
            "Sendrisoa": {
                "lat": -22.0,
                "lng": 46.95
            },
            "Maromiandra": {
                "lat": -21.6833,
                "lng": 47.5167
            },
            "Ambalavero": {
                "lat": -21.8,
                "lng": 47.7833
            },
            "Bekapaika": {
                "lat": -16.75,
                "lng": 47.5667
            },
            "Anorombato": {
                "lat": -22.0167,
                "lng": 47.7833
            },
            "Ambaliha": {
                "lat": -14.4667,
                "lng": 48.3
            },
            "Ambohinamboarina": {
                "lat": -21.0333,
                "lng": 47.05
            },
            "Anjanazana": {
                "lat": -15.3833,
                "lng": 49.75
            },
            "Andrembesoa": {
                "lat": -20.15,
                "lng": 46.65
            },
            "Anjiamangirana I": {
                "lat": -15.1667,
                "lng": 47.7833
            },
            "Nosiarina": {
                "lat": -14.2167,
                "lng": 50.05
            },
            "Antanamalaza": {
                "lat": -19.4,
                "lng": 47.6333
            },
            "Andramy": {
                "lat": -17.9635,
                "lng": 44.7855
            },
            "Basibasy": {
                "lat": -22.1667,
                "lng": 43.65
            },
            "Ampondra": {
                "lat": -13.4167,
                "lng": 49.95
            },
            "Antaritarika": {
                "lat": -25.4,
                "lng": 45.75
            },
            "Befody": {
                "lat": -20.7667,
                "lng": 47.9333
            },
            "Maromby": {
                "lat": -24.35,
                "lng": 46.5667
            },
            "Belampona": {
                "lat": -14.6833,
                "lng": 49.5167
            },
            "Befandefa": {
                "lat": -22.1333,
                "lng": 43.3
            },
            "Ambatofisaka II": {
                "lat": -20.0833,
                "lng": 47.9
            },
            "Soalandy": {
                "lat": -18.9903,
                "lng": 47.5
            },
            "Isaka": {
                "lat": -21.15,
                "lng": 47.0
            },
            "Ambohimahasoa": {
                "lat": -21.1064,
                "lng": 47.2161
            },
            "Lamosina": {
                "lat": -21.64,
                "lng": 46.8989
            },
            "Ramena": {
                "lat": -12.2333,
                "lng": 49.35
            },
            "Besalampy": {
                "lat": -16.75,
                "lng": 44.4833
            },
            "Langarivo": {
                "lat": -14.6,
                "lng": 50.05
            },
            "Antsambalahy": {
                "lat": -14.7167,
                "lng": 49.9167
            },
            "Begogo": {
                "lat": -23.4833,
                "lng": 46.7167
            },
            "Ambinanin\u2019i Sakaleona": {
                "lat": -20.5333,
                "lng": 48.55
            },
            "Ambotaka": {
                "lat": -21.75,
                "lng": 48.05
            },
            "Kalafotsy": {
                "lat": -22.2833,
                "lng": 47.3167
            },
            "Antsoha": {
                "lat": -15.7667,
                "lng": 48.85
            },
            "Maroambihy": {
                "lat": -14.4667,
                "lng": 49.7667
            },
            "Voloina": {
                "lat": -15.5667,
                "lng": 49.6167
            },
            "Ambatomasina": {
                "lat": -18.7333,
                "lng": 47.45
            },
            "Antsakanalabe": {
                "lat": -15.4167,
                "lng": 48.2833
            },
            "Antsahabe": {
                "lat": -14.8,
                "lng": 48.3833
            },
            "Antakotako": {
                "lat": -15.3167,
                "lng": 49.8
            },
            "Tsararano": {
                "lat": -17.3333,
                "lng": 46.35
            },
            "Mahazony": {
                "lat": -21.9833,
                "lng": 47.0167
            },
            "Fotsialanana": {
                "lat": -16.9333,
                "lng": 49.4833
            },
            "Ambinanindovoka": {
                "lat": -21.9167,
                "lng": 47.1
            },
            "Ankavandra": {
                "lat": -18.7722,
                "lng": 45.2931
            },
            "Manambolosy": {
                "lat": -16.0333,
                "lng": 49.6667
            },
            "Ambohidranandriana": {
                "lat": -19.8833,
                "lng": 47.15
            },
            "Tsinjomitondraka": {
                "lat": -15.6667,
                "lng": 47.1333
            },
            "Amporoforo": {
                "lat": -22.4667,
                "lng": 47.8
            },
            "Ambodimangavolo": {
                "lat": -17.5167,
                "lng": 48.95
            },
            "Analamitsivalana": {
                "lat": -20.3167,
                "lng": 44.6833
            },
            "Bevata": {
                "lat": -23.2833,
                "lng": 47.2833
            },
            "Antsambahara": {
                "lat": -14.5333,
                "lng": 49.95
            },
            "Androndrona Anava": {
                "lat": -15.75,
                "lng": 49.2
            },
            "Sampona": {
                "lat": -25.15,
                "lng": 46.3167
            },
            "Marolinta": {
                "lat": -25.1,
                "lng": 44.6167
            },
            "Andranomeva": {
                "lat": -15.8,
                "lng": 47.7667
            },
            "Ambodimanary": {
                "lat": -15.2167,
                "lng": 48.0833
            },
            "Maroamalona": {
                "lat": -15.3,
                "lng": 48.6667
            },
            "Marovantaza": {
                "lat": -15.3833,
                "lng": 47.6667
            },
            "Marotandrano": {
                "lat": -16.1667,
                "lng": 48.8333
            },
            "Efatsy-Anandroza": {
                "lat": -23.1167,
                "lng": 47.6
            },
            "Manandroy": {
                "lat": -21.1333,
                "lng": 47.2667
            },
            "Tranomaro": {
                "lat": -24.6,
                "lng": 46.4667
            },
            "Vinaninkarena": {
                "lat": -19.95,
                "lng": 47.05
            },
            "Soaserana": {
                "lat": -21.1167,
                "lng": 44.2167
            },
            "Soamanova": {
                "lat": -23.2833,
                "lng": 47.6
            },
            "Ankasakasabe": {
                "lat": -16.3,
                "lng": 44.85
            },
            "Ambohidanerana": {
                "lat": -19.1833,
                "lng": 46.7667
            },
            "Antanandehibe": {
                "lat": -19.6833,
                "lng": 48.3333
            },
            "Alakamisy Anativato": {
                "lat": -19.8833,
                "lng": 46.9
            },
            "Kelilalina": {
                "lat": -21.2833,
                "lng": 47.55
            },
            "Andovoranto": {
                "lat": -18.9544,
                "lng": 49.1094
            },
            "Ankarabato": {
                "lat": -16.35,
                "lng": 46.1833
            },
            "Ambohinihaonana": {
                "lat": -21.2,
                "lng": 47.9167
            },
            "Lohariandava": {
                "lat": -18.7833,
                "lng": 48.675
            },
            "Anoviara": {
                "lat": -14.7333,
                "lng": 49.8333
            },
            "Betsiaka": {
                "lat": -13.15,
                "lng": 49.2333
            },
            "Antsaravibe": {
                "lat": -13.05,
                "lng": 49.0167
            },
            "Ambato": {
                "lat": -18.6,
                "lng": 47.5
            },
            "Mantasoa": {
                "lat": -19.0167,
                "lng": 47.8333
            },
            "Ambolotarakely": {
                "lat": -18.2667,
                "lng": 47.4
            },
            "Marotaolana": {
                "lat": -14.0167,
                "lng": 48.6167
            },
            "Antenetibe": {
                "lat": -18.7833,
                "lng": 47.4167
            },
            "Andonabe Atsimo": {
                "lat": -19.8833,
                "lng": 48.2167
            },
            "Ambahy": {
                "lat": -20.7667,
                "lng": 48.4833
            },
            "Manakana": {
                "lat": -19.8167,
                "lng": 48.3667
            },
            "Antsahadinta": {
                "lat": -19.0167,
                "lng": 47.4583
            },
            "Fasintsara": {
                "lat": -20.7,
                "lng": 47.7
            },
            "Ankily": {
                "lat": -22.3833,
                "lng": 46.0833
            },
            "Marondry": {
                "lat": -18.4167,
                "lng": 47.0
            },
            "Antanananivo": {
                "lat": -16.3,
                "lng": 49.75
            },
            "Namorona": {
                "lat": -21.65,
                "lng": 48.2
            },
            "Soahany": {
                "lat": -18.4,
                "lng": 44.1333
            },
            "Belavabary": {
                "lat": -18.8333,
                "lng": 48.0
            },
            "Mahabako": {
                "lat": -21.75,
                "lng": 47.7167
            },
            "Boanamary": {
                "lat": -15.8333,
                "lng": 46.3167
            },
            "Esira": {
                "lat": -24.3333,
                "lng": 46.7
            },
            "Sandravinany": {
                "lat": -24.0333,
                "lng": 47.4333
            },
            "Bedidy": {
                "lat": -17.4667,
                "lng": 48.0167
            },
            "Vohitrafeno": {
                "lat": -21.6667,
                "lng": 47.2167
            },
            "Daraina": {
                "lat": -13.2,
                "lng": 49.6667
            },
            "Masiaboay": {
                "lat": -23.9,
                "lng": 44.4667
            },
            "Ambatomivary": {
                "lat": -23.8333,
                "lng": 45.5667
            },
            "Ambodisikidy": {
                "lat": -14.2333,
                "lng": 48.4833
            },
            "Ambohimiarivo": {
                "lat": -19.85,
                "lng": 47.15
            },
            "Bekodoka": {
                "lat": -16.9667,
                "lng": 45.1
            },
            "Maroharatra": {
                "lat": -20.7333,
                "lng": 47.7667
            },
            "Ambararatabe": {
                "lat": -18.99,
                "lng": 46.19
            },
            "Ambatomifanongoa": {
                "lat": -20.2667,
                "lng": 46.9167
            },
            "Ambohitrambo": {
                "lat": -18.9167,
                "lng": 47.1833
            },
            "Ebelo": {
                "lat": -24.4833,
                "lng": 46.0333
            },
            "Analalava": {
                "lat": -14.6333,
                "lng": 47.75
            },
            "Lohafary": {
                "lat": -23.25,
                "lng": 47.4333
            },
            "Antsoantany": {
                "lat": -19.7,
                "lng": 47.1667
            },
            "Ambovonomby": {
                "lat": -14.3667,
                "lng": 49.05
            },
            "Isahara": {
                "lat": -23.7167,
                "lng": 47.35
            },
            "Ambodivoara": {
                "lat": -14.4,
                "lng": 49.95
            },
            "Vodiriana": {
                "lat": -19.1667,
                "lng": 48.5667
            },
            "Ambohimahazo": {
                "lat": -20.6667,
                "lng": 47.0833
            },
            "Ambodimadiro": {
                "lat": -14.6,
                "lng": 48.0833
            },
            "Andranambolava": {
                "lat": -20.9,
                "lng": 48.2667
            },
            "Marosakoa": {
                "lat": -16.2333,
                "lng": 46.8333
            },
            "Soavimbahoaka": {
                "lat": -18.6833,
                "lng": 46.1
            },
            "Erada": {
                "lat": -25.2833,
                "lng": 45.9333
            },
            "Mahabe": {
                "lat": -17.0833,
                "lng": 45.3333
            },
            "Mahabo-Mananivo": {
                "lat": -23.1833,
                "lng": 47.6833
            },
            "Miary-Taheza": {
                "lat": -23.1333,
                "lng": 44.6167
            },
            "Ankadindambo": {
                "lat": -21.4667,
                "lng": 47.15
            },
            "Antaretra": {
                "lat": -21.0667,
                "lng": 47.85
            },
            "Betrandraka": {
                "lat": -17.0333,
                "lng": 47.55
            },
            "Amparihy": {
                "lat": -15.6667,
                "lng": 47.1167
            },
            "Tanamarina": {
                "lat": -21.5731,
                "lng": 45.94
            },
            "Sahanivotry-Manandona": {
                "lat": -20.1167,
                "lng": 47.0833
            },
            "Andranomenatsa": {
                "lat": -23.3167,
                "lng": 44.5833
            },
            "Ambodimandresy": {
                "lat": -14.7833,
                "lng": 48.0833
            },
            "Anontsibe-Sakalava": {
                "lat": -21.3667,
                "lng": 44.4667
            },
            "Amparihitsokatra": {
                "lat": -17.5167,
                "lng": 48.5667
            },
            "Ambatolava": {
                "lat": -23.5667,
                "lng": 47.3
            },
            "Ankerana": {
                "lat": -21.0333,
                "lng": 47.1167
            },
            "Sihanamaro": {
                "lat": -25.1833,
                "lng": 45.7667
            },
            "Vinanitelo": {
                "lat": -22.0167,
                "lng": 47.7333
            },
            "Ifarantsa": {
                "lat": -24.9333,
                "lng": 46.85
            },
            "Ampasimazava": {
                "lat": -17.6667,
                "lng": 49.1333
            },
            "Vohitany": {
                "lat": -24.1333,
                "lng": 44.7667
            },
            "Vohitsaoka": {
                "lat": -22.0333,
                "lng": 46.7167
            },
            "Andranopasy": {
                "lat": -21.2833,
                "lng": 43.7333
            },
            "Beheloka": {
                "lat": -23.9017,
                "lng": 43.7635
            },
            "Ankirondro": {
                "lat": -19.6333,
                "lng": 44.6
            },
            "Tamponala": {
                "lat": -19.3,
                "lng": 46.4167
            },
            "Katsepy": {
                "lat": -15.7667,
                "lng": 46.2333
            },
            "Vondrozo": {
                "lat": -22.8206,
                "lng": 47.3217
            },
            "Tanambao-Daoud": {
                "lat": -13.9833,
                "lng": 50.1333
            },
            "Sahatona-Tamboharivo": {
                "lat": -20.9667,
                "lng": 47.1
            },
            "Beanana": {
                "lat": -17.3667,
                "lng": 48.4333
            },
            "Soatanana": {
                "lat": -21.3833,
                "lng": 46.9167
            },
            "Ampitahana": {
                "lat": -21.1333,
                "lng": 47.2167
            },
            "Anosimparihy": {
                "lat": -21.5,
                "lng": 47.9833
            },
            "Vatana": {
                "lat": -22.2167,
                "lng": 47.75
            },
            "Ambalanjanakomby": {
                "lat": -16.7,
                "lng": 47.0833
            },
            "Zoma-Bealoka": {
                "lat": -18.85,
                "lng": 47.05
            },
            "Jangany": {
                "lat": -22.85,
                "lng": 45.7833
            },
            "Ianapera": {
                "lat": -23.6167,
                "lng": 45.1333
            },
            "Ambahatrazo": {
                "lat": -21.8667,
                "lng": 47.95
            },
            "Fanjakana": {
                "lat": -21.1833,
                "lng": 46.8833
            },
            "Miadanandriana": {
                "lat": -19.0333,
                "lng": 47.7833
            },
            "Ankazotsifantatra": {
                "lat": -19.95,
                "lng": 48.55
            },
            "Anjahamana": {
                "lat": -18.3667,
                "lng": 48.9667
            },
            "Djangoa": {
                "lat": -13.7833,
                "lng": 48.3167
            },
            "Ambinanintromby": {
                "lat": -21.6667,
                "lng": 47.5167
            },
            "Tsaramasoandro": {
                "lat": -17.9833,
                "lng": 47.2
            },
            "Andrainjato": {
                "lat": -21.4667,
                "lng": 47.0833
            },
            "Fandrandava": {
                "lat": -21.5167,
                "lng": 47.25
            },
            "Fitampito": {
                "lat": -20.9667,
                "lng": 46.3167
            },
            "Ankadimanga": {
                "lat": -18.9667,
                "lng": 47.75
            },
            "Analaroa": {
                "lat": -18.4083,
                "lng": 47.7083
            },
            "Talata-Angavo": {
                "lat": -18.2,
                "lng": 47.1
            },
            "Tanamarina-Sakay": {
                "lat": -21.4667,
                "lng": 46.6167
            },
            "Bemarivo": {
                "lat": -17.6333,
                "lng": 44.75
            },
            "Andilana Avaratra": {
                "lat": -17.35,
                "lng": 48.5333
            },
            "Ampasimpotsy-Gara": {
                "lat": -18.9667,
                "lng": 48.35
            },
            "Maropaika": {
                "lat": -22.7,
                "lng": 46.9833
            },
            "Soamahamanina": {
                "lat": -18.9833,
                "lng": 47.0167
            },
            "Ambodivoanio": {
                "lat": -16.1833,
                "lng": 49.6667
            },
            "Tsimafana": {
                "lat": -19.7167,
                "lng": 44.5833
            },
            "Sahatsiho-Ambohimanjaka": {
                "lat": -20.2,
                "lng": 47.0333
            },
            "Ranopiso": {
                "lat": -25.05,
                "lng": 46.6667
            },
            "Ivandrika": {
                "lat": -22.8667,
                "lng": 47.7333
            },
            "Marotolana": {
                "lat": -14.65,
                "lng": 48.9833
            },
            "Ambatoria": {
                "lat": -14.5,
                "lng": 48.95
            },
            "Lanivo": {
                "lat": -22.2833,
                "lng": 47.8167
            },
            "Sarasambo": {
                "lat": -25.1,
                "lng": 46.7833
            },
            "Antambohobe": {
                "lat": -22.3,
                "lng": 46.7667
            },
            "Ambalajia": {
                "lat": -17.4833,
                "lng": 46.1167
            },
            "Nato": {
                "lat": -22.3,
                "lng": 47.8167
            },
            "Mahamaibe": {
                "lat": -21.75,
                "lng": 47.8833
            },
            "Mitanty": {
                "lat": -21.7333,
                "lng": 48.0
            },
            "Salobe": {
                "lat": -23.5333,
                "lng": 44.7167
            },
            "Ambariokorano": {
                "lat": -15.8833,
                "lng": 48.9667
            },
            "Vatananto": {
                "lat": -23.6667,
                "lng": 47.25
            },
            "Iara": {
                "lat": -23.0833,
                "lng": 47.4333
            },
            "Ampary": {
                "lat": -19.1833,
                "lng": 46.5833
            },
            "Ambalaromba": {
                "lat": -14.6667,
                "lng": 49.3333
            },
            "Soamanonga": {
                "lat": -23.8667,
                "lng": 44.7833
            },
            "Bemaharivo": {
                "lat": -16.1333,
                "lng": 46.4
            },
            "Anteza": {
                "lat": -21.6667,
                "lng": 47.85
            },
            "Bekopaka": {
                "lat": -19.0167,
                "lng": 44.85
            },
            "Antaly": {
                "lat": -24.3833,
                "lng": 44.4
            },
            "Anjialava": {
                "lat": -14.0667,
                "lng": 49.7333
            },
            "Ankarana-Miraihina": {
                "lat": -23.0833,
                "lng": 47.6333
            },
            "Antsaidoha-Bebao": {
                "lat": -17.3667,
                "lng": 44.55
            },
            "Nosibe": {
                "lat": -13.15,
                "lng": 49.8
            },
            "Soanierana": {
                "lat": -25.0,
                "lng": 46.8833
            },
            "Soanenga": {
                "lat": -16.5,
                "lng": 44.6
            },
            "Manampaneva": {
                "lat": -16.05,
                "lng": 48.6833
            },
            "Manja": {
                "lat": -21.4333,
                "lng": 44.3333
            },
            "Ambinanin\u2019 Andravory": {
                "lat": -13.7667,
                "lng": 49.75
            },
            "Belinta": {
                "lat": -19.95,
                "lng": 45.1167
            },
            "Marovatolena": {
                "lat": -15.1833,
                "lng": 47.65
            },
            "Antseza": {
                "lat": -16.2167,
                "lng": 45.8667
            },
            "Andribavontsona": {
                "lat": -15.4,
                "lng": 47.5167
            },
            "Antanankambano": {
                "lat": -22.05,
                "lng": 47.4833
            },
            "Alakamisy-Ambohimahazo": {
                "lat": -20.4,
                "lng": 47.35
            },
            "Benato-Toby": {
                "lat": -23.0833,
                "lng": 45.8667
            },
            "Ankirihitra": {
                "lat": -16.7667,
                "lng": 46.45
            },
            "Antsatramidola": {
                "lat": -15.6333,
                "lng": 48.9667
            },
            "Amboronabo": {
                "lat": -22.6667,
                "lng": 44.4
            },
            "Manevy": {
                "lat": -24.3833,
                "lng": 46.8
            },
            "Beparasy": {
                "lat": -19.1667,
                "lng": 48.0333
            },
            "Tandrano": {
                "lat": -22.0833,
                "lng": 44.6333
            },
            "Ambarimaninga": {
                "lat": -16.5,
                "lng": 46.0
            },
            "Ambodimahabibo": {
                "lat": -15.7333,
                "lng": 48.0833
            },
            "Amberomanga": {
                "lat": -19.25,
                "lng": 46.6167
            },
            "Ankatafa": {
                "lat": -13.6167,
                "lng": 48.3667
            },
            "Anjahamarina": {
                "lat": -18.0167,
                "lng": 49.2
            },
            "Bekily": {
                "lat": -24.2162,
                "lng": 45.3166
            }
        }
    },
    "VE": {
        "name": "Venezuela",
        "cities": {
            "Caracas": {
                "lat": 10.4806,
                "lng": -66.9036
            },
            "Maracaibo": {
                "lat": 10.6333,
                "lng": -71.6333
            },
            "Valencia": {
                "lat": 10.1833,
                "lng": -68.0
            },
            "Barquisimeto": {
                "lat": 10.0636,
                "lng": -69.3347
            },
            "San Crist\u00f3bal": {
                "lat": 7.7667,
                "lng": -72.2333
            },
            "Barinas": {
                "lat": 8.6333,
                "lng": -70.2
            },
            "Ciudad Guayana": {
                "lat": 8.3667,
                "lng": -62.65
            },
            "Catia La Mar": {
                "lat": 10.6,
                "lng": -67.0333
            },
            "Ciudad Bol\u00edvar": {
                "lat": 8.1219,
                "lng": -63.55
            },
            "Santa Teresa del Tuy": {
                "lat": 10.2333,
                "lng": -66.6639
            },
            "Matur\u00edn": {
                "lat": 9.75,
                "lng": -63.183
            },
            "Barcelona": {
                "lat": 10.1403,
                "lng": -64.6833
            },
            "Cuman\u00e1": {
                "lat": 10.4564,
                "lng": -64.1675
            },
            "Petare": {
                "lat": 10.4833,
                "lng": -66.8167
            },
            "Cabimas": {
                "lat": 10.4,
                "lng": -71.45
            },
            "Puerto La Cruz": {
                "lat": 10.2,
                "lng": -64.6333
            },
            "Punto Fijo": {
                "lat": 11.7167,
                "lng": -70.1833
            },
            "Turmero": {
                "lat": 10.2283,
                "lng": -67.4725
            },
            "Los Teques": {
                "lat": 10.3333,
                "lng": -67.0417
            },
            "M\u00e9rida": {
                "lat": 8.48,
                "lng": -71.19
            },
            "Baruta": {
                "lat": 10.4322,
                "lng": -66.8739
            },
            "San Felipe": {
                "lat": 10.3406,
                "lng": -68.7369
            },
            "C\u00faa": {
                "lat": 10.1667,
                "lng": -66.8833
            },
            "La Victoria": {
                "lat": 10.2278,
                "lng": -67.3336
            },
            "El Tigre": {
                "lat": 8.8861,
                "lng": -64.2611
            },
            "Puerto Cabello": {
                "lat": 10.4667,
                "lng": -68.0167
            },
            "Guarenas": {
                "lat": 10.4667,
                "lng": -66.6167
            },
            "La Guaira": {
                "lat": 10.6,
                "lng": -66.9331
            },
            "Guacara": {
                "lat": 10.2536,
                "lng": -67.9
            },
            "Tocuyito": {
                "lat": 10.1144,
                "lng": -68.0656
            },
            "Acarigua": {
                "lat": 9.5597,
                "lng": -69.2019
            },
            "San Fernando": {
                "lat": 7.894,
                "lng": -67.473
            },
            "Guanare": {
                "lat": 9.0436,
                "lng": -69.7489
            },
            "Valera": {
                "lat": 9.3206,
                "lng": -70.6078
            },
            "Araure": {
                "lat": 9.5667,
                "lng": -69.2167
            },
            "Guatire": {
                "lat": 10.4717,
                "lng": -66.5406
            },
            "San Diego": {
                "lat": 10.254,
                "lng": -68.0109
            },
            "Car\u00fapano": {
                "lat": 10.6722,
                "lng": -63.2403
            },
            "Santa Rita": {
                "lat": 10.5367,
                "lng": -71.5108
            },
            "Calabozo": {
                "lat": 8.9333,
                "lng": -67.4333
            },
            "Anaco": {
                "lat": 9.4819,
                "lng": -64.4917
            },
            "Palo Negro": {
                "lat": 10.16,
                "lng": -67.5582
            },
            "Ocumare del Tuy": {
                "lat": 10.1136,
                "lng": -66.7814
            },
            "Coro": {
                "lat": 11.417,
                "lng": -69.67
            },
            "Villa de Cura": {
                "lat": 10.0383,
                "lng": -67.4892
            },
            "Yaritagua": {
                "lat": 10.0753,
                "lng": -69.1283
            },
            "Los Guayos": {
                "lat": 10.1833,
                "lng": -67.9333
            },
            "T\u00e1riba": {
                "lat": 7.8167,
                "lng": -72.2167
            },
            "Guasdualito": {
                "lat": 7.2467,
                "lng": -70.7292
            },
            "Machiques": {
                "lat": 10.0667,
                "lng": -72.5667
            },
            "Cagua": {
                "lat": 10.1875,
                "lng": -67.4611
            },
            "Ciudad Ojeda": {
                "lat": 10.2,
                "lng": -71.3
            },
            "Porlamar": {
                "lat": 10.95,
                "lng": -63.85
            },
            "San Carlos": {
                "lat": 9.65,
                "lng": -68.5833
            },
            "Charallave": {
                "lat": 10.2431,
                "lng": -66.8622
            },
            "La Asunci\u00f3n": {
                "lat": 11.0333,
                "lng": -63.8628
            },
            "Valle de La Pascua": {
                "lat": 9.2033,
                "lng": -66.0103
            },
            "Carora": {
                "lat": 10.1711,
                "lng": -70.0797
            },
            "Santa Luc\u00eda": {
                "lat": 10.2606,
                "lng": -66.6639
            },
            "Qu\u00edbor": {
                "lat": 9.9311,
                "lng": -69.6247
            },
            "Socop\u00f3": {
                "lat": 8.2322,
                "lng": -70.8206
            },
            "Tinaquillo": {
                "lat": 9.9167,
                "lng": -68.3
            },
            "Ejido": {
                "lat": 8.3333,
                "lng": -71.4
            },
            "El Lim\u00f3n": {
                "lat": 10.3003,
                "lng": -67.6336
            },
            "Bocon\u00f3": {
                "lat": 9.2461,
                "lng": -70.2617
            },
            "Rubio": {
                "lat": 7.7,
                "lng": -72.35
            },
            "Maracay": {
                "lat": 10.2469,
                "lng": -67.5961
            },
            "Cantaura": {
                "lat": 9.3111,
                "lng": -64.3594
            },
            "La Grita": {
                "lat": 8.1333,
                "lng": -71.9833
            },
            "Maiquet\u00eda": {
                "lat": 10.5958,
                "lng": -66.9772
            },
            "San Juan de los Morros": {
                "lat": 9.901,
                "lng": -67.354
            },
            "Tucupita": {
                "lat": 9.0592,
                "lng": -62.0681
            },
            "Puerto Ayacucho": {
                "lat": 5.6631,
                "lng": -67.6264
            },
            "San Carlos del Zulia": {
                "lat": 9.0,
                "lng": -71.9333
            },
            "San Antonio de Los Altos": {
                "lat": 10.3744,
                "lng": -66.9669
            },
            "Cabudare": {
                "lat": 10.0331,
                "lng": -69.2633
            },
            "Cede\u00f1o": {
                "lat": 7.6492,
                "lng": -66.1759
            },
            "Caripito": {
                "lat": 10.1167,
                "lng": -63.1
            },
            "Zaraza": {
                "lat": 9.3394,
                "lng": -65.3167
            },
            "San Antonio del T\u00e1chira": {
                "lat": 7.8167,
                "lng": -72.45
            },
            "G\u00fcig\u00fce": {
                "lat": 10.0833,
                "lng": -67.7833
            },
            "Chivacoa": {
                "lat": 10.16,
                "lng": -68.9097
            },
            "Nirgua": {
                "lat": 10.0833,
                "lng": -68.6667
            },
            "Carvajal": {
                "lat": 9.33,
                "lng": -70.5767
            },
            "Upata": {
                "lat": 8.0204,
                "lng": -62.41
            },
            "Sanare": {
                "lat": 9.7822,
                "lng": -69.7931
            },
            "Caraballeda": {
                "lat": 10.6131,
                "lng": -66.8506
            },
            "Pamp\u00e1n": {
                "lat": 9.4511,
                "lng": -70.4758
            },
            "El Valle del Esp\u00edritu Santo": {
                "lat": 10.9833,
                "lng": -63.8833
            },
            "El Consejo": {
                "lat": 10.2442,
                "lng": -67.245
            },
            "Santa Cruz de Los Taques": {
                "lat": 11.8217,
                "lng": -70.2561
            },
            "El Tocuyo": {
                "lat": 9.7822,
                "lng": -69.7931
            },
            "Ciudad Piar": {
                "lat": 7.4522,
                "lng": -63.32
            },
            "Altagracia de Orituco": {
                "lat": 9.8504,
                "lng": -66.38
            },
            "Cocorote": {
                "lat": 10.3217,
                "lng": -68.7825
            },
            "Ospino": {
                "lat": 9.3,
                "lng": -69.45
            },
            "Achaguas": {
                "lat": 7.7667,
                "lng": -68.2333
            },
            "Trujillo": {
                "lat": 9.417,
                "lng": -70.5
            },
            "San Mateo": {
                "lat": 9.74,
                "lng": -64.5531
            },
            "Ure\u00f1a": {
                "lat": 7.9183,
                "lng": -72.4467
            },
            "Col\u00f3n": {
                "lat": 8.0378,
                "lng": -72.2519
            },
            "Santa Ana": {
                "lat": 9.3056,
                "lng": -64.6548
            },
            "Lecher\u00edas": {
                "lat": 10.1889,
                "lng": -64.6951
            },
            "Pampatar": {
                "lat": 10.9994,
                "lng": -63.7944
            },
            "Caucagua": {
                "lat": 10.2822,
                "lng": -66.3772
            },
            "Guanta": {
                "lat": 10.2383,
                "lng": -64.5917
            },
            "Macuro": {
                "lat": 10.65,
                "lng": -61.9333
            },
            "Puerto Cumarebo": {
                "lat": 11.4861,
                "lng": -69.3503
            },
            "Las Mercedes": {
                "lat": 9.1104,
                "lng": -66.3961
            },
            "Escuque": {
                "lat": 9.25,
                "lng": -70.75
            },
            "Carache": {
                "lat": 9.7072,
                "lng": -70.1933
            },
            "Pampanito": {
                "lat": 9.4114,
                "lng": -70.4958
            },
            "Santa Cruz": {
                "lat": 10.1819,
                "lng": -67.5025
            },
            "Juangriego": {
                "lat": 11.0833,
                "lng": -63.9667
            },
            "Punceres": {
                "lat": 9.9898,
                "lng": -63.3366
            },
            "Sabaneta": {
                "lat": 8.7522,
                "lng": -69.9325
            },
            "Aragua de Barcelona": {
                "lat": 9.4575,
                "lng": -64.8261
            },
            "La Ceiba": {
                "lat": 9.4764,
                "lng": -71.0556
            },
            "Puerto P\u00edritu": {
                "lat": 10.0667,
                "lng": -65.05
            },
            "Araira": {
                "lat": 10.4525,
                "lng": -66.485
            },
            "Higuerote": {
                "lat": 10.4833,
                "lng": -66.1
            },
            "Montalb\u00e1n": {
                "lat": 10.2522,
                "lng": -68.3019
            },
            "P\u00edritu": {
                "lat": 10.0395,
                "lng": -65.0304
            },
            "El Callao": {
                "lat": 7.3463,
                "lng": -61.8271
            },
            "Guasipati": {
                "lat": 7.4667,
                "lng": -61.4667
            },
            "La Colonia Tovar": {
                "lat": 10.4056,
                "lng": -67.2894
            },
            "Motat\u00e1n": {
                "lat": 9.3931,
                "lng": -70.5933
            },
            "La Esmeralda": {
                "lat": 3.1739,
                "lng": -65.5467
            },
            "Chichiriviche": {
                "lat": 10.9343,
                "lng": -68.2771
            },
            "Barbacoas": {
                "lat": 9.4833,
                "lng": -66.9761
            },
            "Monte Carmelo": {
                "lat": 9.1875,
                "lng": -70.8131
            },
            "El Palmar": {
                "lat": 8.0244,
                "lng": -61.8819
            },
            "Chaguaramas": {
                "lat": 9.3333,
                "lng": -66.2667
            },
            "Clarines": {
                "lat": 9.9433,
                "lng": -65.1661
            },
            "El Socorro": {
                "lat": 8.9936,
                "lng": -65.7456
            },
            "Cojedes": {
                "lat": 9.6167,
                "lng": -68.9167
            },
            "Tucacas": {
                "lat": 10.7978,
                "lng": -68.3175
            },
            "R\u00edo Caribe": {
                "lat": 10.7008,
                "lng": -63.1086
            },
            "Santa Mar\u00eda de Ipire": {
                "lat": 8.8134,
                "lng": -65.3221
            },
            "Capaya": {
                "lat": 10.4286,
                "lng": -66.2717
            },
            "Isla Rat\u00f3n": {
                "lat": 5.1311,
                "lng": -67.8106
            },
            "Sarare": {
                "lat": 9.7839,
                "lng": -69.1611
            },
            "Aguasay": {
                "lat": 9.4249,
                "lng": -63.7308
            },
            "San Jos\u00e9 de Guaribe": {
                "lat": 9.8602,
                "lng": -65.8131
            },
            "Onoto": {
                "lat": 9.5958,
                "lng": -65.1897
            },
            "San Jos\u00e9 de Aerocuar": {
                "lat": 10.6027,
                "lng": -63.3273
            },
            "Santa B\u00e1rbara": {
                "lat": 9.6,
                "lng": -63.6
            },
            "Uracoa": {
                "lat": 8.9943,
                "lng": -62.352
            },
            "San Pedro de Coche": {
                "lat": 10.7772,
                "lng": -63.9311
            },
            "El Dorado": {
                "lat": 6.7167,
                "lng": -61.6167
            },
            "El Manteco": {
                "lat": 7.3483,
                "lng": -62.5395
            }
        }
    },
    "AT": {
        "name": "Austria",
        "cities": {
            "Vienna": {
                "lat": 48.2083,
                "lng": 16.3725
            },
            "Graz": {
                "lat": 47.0708,
                "lng": 15.4386
            },
            "Linz": {
                "lat": 48.3058,
                "lng": 14.2864
            },
            "Lochau": {
                "lat": 47.5167,
                "lng": 9.75
            },
            "Salzburg": {
                "lat": 47.8,
                "lng": 13.045
            },
            "Innsbruck": {
                "lat": 47.2683,
                "lng": 11.3933
            },
            "Klagenfurt": {
                "lat": 46.6167,
                "lng": 14.3
            },
            "Villach": {
                "lat": 46.6167,
                "lng": 13.85
            },
            "Wels": {
                "lat": 48.15,
                "lng": 14.0167
            },
            "Sankt P\u00f6lten": {
                "lat": 48.2,
                "lng": 15.6167
            },
            "Krems an der Donau": {
                "lat": 48.4167,
                "lng": 15.6167
            },
            "Dornbirn": {
                "lat": 47.4139,
                "lng": 9.7444
            },
            "Wiener Neustadt": {
                "lat": 47.8167,
                "lng": 16.25
            },
            "Steyr": {
                "lat": 48.0333,
                "lng": 14.4167
            },
            "Bregenz": {
                "lat": 47.505,
                "lng": 9.7492
            },
            "Leonding": {
                "lat": 48.2667,
                "lng": 14.25
            },
            "Klosterneuburg": {
                "lat": 48.3042,
                "lng": 16.3167
            },
            "Baden": {
                "lat": 48.0075,
                "lng": 16.2344
            },
            "Wolfsberg": {
                "lat": 46.85,
                "lng": 14.8333
            },
            "Leoben": {
                "lat": 47.3817,
                "lng": 15.0972
            },
            "Traun": {
                "lat": 48.2217,
                "lng": 14.2397
            },
            "Lustenau": {
                "lat": 47.4297,
                "lng": 9.6597
            },
            "Amstetten": {
                "lat": 48.1167,
                "lng": 14.8667
            },
            "Kapfenberg": {
                "lat": 47.4333,
                "lng": 15.2833
            },
            "Hallein": {
                "lat": 47.6831,
                "lng": 13.0969
            },
            "M\u00f6dling": {
                "lat": 48.0833,
                "lng": 16.2667
            },
            "Kufstein": {
                "lat": 47.5833,
                "lng": 12.1667
            },
            "Traiskirchen": {
                "lat": 48.0167,
                "lng": 16.3
            },
            "Schwechat": {
                "lat": 48.1411,
                "lng": 16.4786
            },
            "Braunau am Inn": {
                "lat": 48.2583,
                "lng": 13.035
            },
            "Hohenems": {
                "lat": 47.3667,
                "lng": 9.6667
            },
            "Stockerau": {
                "lat": 48.3833,
                "lng": 16.2167
            },
            "Saalfelden am Steinernen Meer": {
                "lat": 47.4269,
                "lng": 12.8483
            },
            "Ansfelden": {
                "lat": 48.2083,
                "lng": 14.2889
            },
            "Telfs": {
                "lat": 47.2833,
                "lng": 11.0667
            },
            "Bruck an der Mur": {
                "lat": 47.4167,
                "lng": 15.2667
            },
            "Spittal an der Drau": {
                "lat": 46.7833,
                "lng": 13.4833
            },
            "Perchtoldsdorf": {
                "lat": 48.1167,
                "lng": 16.2667
            },
            "Bludenz": {
                "lat": 47.1333,
                "lng": 9.8167
            },
            "Ternitz": {
                "lat": 47.7167,
                "lng": 16.0333
            },
            "Eisenstadt": {
                "lat": 47.85,
                "lng": 16.5167
            },
            "Feldkirchen": {
                "lat": 46.7167,
                "lng": 14.1
            },
            "Bad Ischl": {
                "lat": 47.7203,
                "lng": 13.6333
            },
            "Hall in Tirol": {
                "lat": 47.2833,
                "lng": 11.5
            },
            "W\u00f6rgl": {
                "lat": 47.4833,
                "lng": 12.0667
            },
            "Schwaz": {
                "lat": 47.35,
                "lng": 11.7
            },
            "Marchtrenk": {
                "lat": 48.1917,
                "lng": 14.1106
            },
            "Hard": {
                "lat": 47.4667,
                "lng": 9.6667
            },
            "Feldbach": {
                "lat": 46.95,
                "lng": 15.8833
            },
            "Gmunden": {
                "lat": 47.9181,
                "lng": 13.7994
            },
            "Leibnitz": {
                "lat": 46.7831,
                "lng": 15.545
            },
            "Korneuburg": {
                "lat": 48.3453,
                "lng": 16.3331
            },
            "Neunkirchen": {
                "lat": 47.7333,
                "lng": 16.0833
            },
            "Knittelfeld": {
                "lat": 47.215,
                "lng": 14.8294
            },
            "Sankt Veit an der Glan": {
                "lat": 46.7667,
                "lng": 14.3667
            },
            "V\u00f6cklabruck": {
                "lat": 48.0086,
                "lng": 13.6558
            },
            "Enns": {
                "lat": 48.2167,
                "lng": 14.475
            },
            "Lienz": {
                "lat": 46.8297,
                "lng": 12.7697
            },
            "Ried im Innkreis": {
                "lat": 48.21,
                "lng": 13.4894
            },
            "Brunn am Gebirge": {
                "lat": 48.1,
                "lng": 16.2833
            },
            "Rankweil": {
                "lat": 47.2667,
                "lng": 9.65
            },
            "Bad V\u00f6slau": {
                "lat": 47.9667,
                "lng": 16.2167
            },
            "Gro\u00df-Enzersdorf": {
                "lat": 48.2,
                "lng": 16.55
            },
            "Deutschlandsberg": {
                "lat": 46.8161,
                "lng": 15.215
            },
            "Hollabrunn": {
                "lat": 48.5667,
                "lng": 16.1
            },
            "Weiz": {
                "lat": 47.2169,
                "lng": 15.6331
            },
            "Mistelbach": {
                "lat": 48.5667,
                "lng": 16.5667
            },
            "G\u00f6tzis": {
                "lat": 47.3331,
                "lng": 9.6333
            },
            "G\u00e4nserndorf": {
                "lat": 48.3406,
                "lng": 16.7175
            },
            "Waidhofen an der Ybbs": {
                "lat": 47.9667,
                "lng": 14.7667
            },
            "Sankt Johann im Pongau": {
                "lat": 47.35,
                "lng": 13.2
            },
            "Gerasdorf bei Wien": {
                "lat": 48.295,
                "lng": 16.4675
            },
            "Trofaiach": {
                "lat": 47.426,
                "lng": 15.0067
            },
            "V\u00f6lkermarkt": {
                "lat": 46.6667,
                "lng": 14.6333
            },
            "Ebreichsdorf": {
                "lat": 47.9667,
                "lng": 16.4
            },
            "Gleisdorf": {
                "lat": 47.1039,
                "lng": 15.7083
            },
            "Zwettl": {
                "lat": 48.6033,
                "lng": 15.1689
            },
            "Bischofshofen": {
                "lat": 47.4172,
                "lng": 13.2194
            },
            "Imst": {
                "lat": 47.2394,
                "lng": 10.7381
            },
            "Lauterach": {
                "lat": 47.4772,
                "lng": 9.7314
            },
            "Strasshof an der Nordbahn": {
                "lat": 48.3194,
                "lng": 16.6475
            },
            "Judenburg": {
                "lat": 47.1725,
                "lng": 14.6603
            },
            "Sankt Andr\u00e4": {
                "lat": 46.7667,
                "lng": 14.8167
            },
            "K\u00f6flach": {
                "lat": 47.0667,
                "lng": 15.0833
            },
            "Laakirchen": {
                "lat": 47.9828,
                "lng": 13.8242
            },
            "Altm\u00fcnster": {
                "lat": 47.9014,
                "lng": 13.7514
            },
            "Zell am See": {
                "lat": 47.3167,
                "lng": 12.8
            },
            "Sankt Johann in Tirol": {
                "lat": 47.5225,
                "lng": 12.4256
            },
            "Purkersdorf": {
                "lat": 48.2092,
                "lng": 16.1792
            },
            "Wiener Neudorf": {
                "lat": 48.0833,
                "lng": 16.3167
            },
            "Voitsberg": {
                "lat": 47.0333,
                "lng": 15.15
            },
            "Sierning": {
                "lat": 48.0447,
                "lng": 14.31
            },
            "Sankt Valentin": {
                "lat": 48.1667,
                "lng": 14.5333
            },
            "Rum": {
                "lat": 47.2872,
                "lng": 11.4581
            },
            "Guntramsdorf": {
                "lat": 48.05,
                "lng": 16.3167
            },
            "Berndorf": {
                "lat": 47.9428,
                "lng": 16.1036
            },
            "Velden am W\u00f6rthersee": {
                "lat": 46.6,
                "lng": 14.0333
            },
            "Attnang-Puchheim": {
                "lat": 48.0167,
                "lng": 13.7197
            },
            "Maria Enzersdorf": {
                "lat": 48.1,
                "lng": 16.2833
            },
            "Engerwitzdorf": {
                "lat": 48.3397,
                "lng": 14.4256
            },
            "Stainz": {
                "lat": 46.8833,
                "lng": 15.2667
            },
            "Deutsch-Wagram": {
                "lat": 48.3,
                "lng": 16.5667
            },
            "F\u00fcrstenfeld": {
                "lat": 47.05,
                "lng": 16.0833
            }
        }
    },
    "ZW": {
        "name": "Zimbabwe",
        "cities": {
            "Harare": {
                "lat": -17.8292,
                "lng": 31.0522
            },
            "Masvingo": {
                "lat": -20.0744,
                "lng": 30.8328
            },
            "Bulawayo": {
                "lat": -20.17,
                "lng": 28.58
            },
            "Chitungwiza": {
                "lat": -17.9939,
                "lng": 31.0481
            },
            "Mutare": {
                "lat": -18.9667,
                "lng": 32.6333
            },
            "Gweru": {
                "lat": -19.4614,
                "lng": 29.8022
            },
            "Nkayi": {
                "lat": -19.0,
                "lng": 28.9
            },
            "Kwekwe": {
                "lat": -18.9167,
                "lng": 29.8167
            },
            "Norton": {
                "lat": -17.8833,
                "lng": 30.7
            },
            "Kadoma": {
                "lat": -18.34,
                "lng": 29.9
            },
            "Chegutu": {
                "lat": -18.14,
                "lng": 30.15
            },
            "Chinhoyi": {
                "lat": -17.3497,
                "lng": 30.1944
            },
            "Marondera": {
                "lat": -18.1897,
                "lng": 31.5467
            },
            "Sakubva": {
                "lat": -18.9833,
                "lng": 32.65
            },
            "Bindura": {
                "lat": -17.3,
                "lng": 31.3333
            },
            "Hwange": {
                "lat": -18.3647,
                "lng": 26.5
            },
            "Beitbridge": {
                "lat": -22.2167,
                "lng": 30.0
            },
            "Chiredzi": {
                "lat": -21.0389,
                "lng": 31.6778
            },
            "Rusape": {
                "lat": -18.5367,
                "lng": 32.1247
            },
            "Zvishavane": {
                "lat": -20.3333,
                "lng": 30.0333
            },
            "Chipinge": {
                "lat": -20.2,
                "lng": 32.62
            },
            "Karoi": {
                "lat": -16.81,
                "lng": 29.7
            },
            "Victoria Falls": {
                "lat": -17.9333,
                "lng": 25.8333
            },
            "Redcliff": {
                "lat": -19.0333,
                "lng": 29.7833
            },
            "Mukumbura": {
                "lat": -16.2,
                "lng": 31.6833
            },
            "Kariba": {
                "lat": -16.5167,
                "lng": 28.8
            },
            "Shurugwi": {
                "lat": -19.6667,
                "lng": 30.0
            },
            "Ruwa": {
                "lat": -17.8897,
                "lng": 31.2447
            },
            "Murehwa": {
                "lat": -17.65,
                "lng": 31.7833
            },
            "Mvurwi": {
                "lat": -17.0167,
                "lng": 30.85
            },
            "Kezi": {
                "lat": -20.9167,
                "lng": 28.4667
            },
            "Plumtree": {
                "lat": -20.4781,
                "lng": 27.7972
            },
            "Gwanda": {
                "lat": -20.9389,
                "lng": 29.0186
            },
            "Mutoko": {
                "lat": -17.4,
                "lng": 32.2167
            },
            "Mhangura": {
                "lat": -16.9,
                "lng": 30.15
            },
            "Chivhu": {
                "lat": -19.0,
                "lng": 30.9
            },
            "Mazoe": {
                "lat": -17.5167,
                "lng": 30.9667
            },
            "Shamva": {
                "lat": -17.3167,
                "lng": 31.55
            },
            "Lupane": {
                "lat": -18.9315,
                "lng": 27.807
            }
        }
    },
    "KH": {
        "name": "Cambodia",
        "cities": {
            "Phnom Penh": {
                "lat": 11.5694,
                "lng": 104.9211
            },
            "Siem Reap": {
                "lat": 13.3622,
                "lng": 103.8597
            },
            "Battambang": {
                "lat": 13.1,
                "lng": 103.2
            },
            "Kampong Cham": {
                "lat": 11.987,
                "lng": 105.462
            },
            "Sisophon": {
                "lat": 13.5833,
                "lng": 102.9833
            },
            "Paoy Paet": {
                "lat": 13.65,
                "lng": 102.5667
            },
            "Kampong Trach": {
                "lat": 10.55,
                "lng": 104.4667
            },
            "Sihanoukville": {
                "lat": 10.6333,
                "lng": 103.5
            },
            "Samraong": {
                "lat": 14.25,
                "lng": 103.5833
            },
            "Kratie": {
                "lat": 12.48,
                "lng": 106.03
            },
            "Pursat": {
                "lat": 12.5333,
                "lng": 103.9167
            },
            "Takeo": {
                "lat": 10.9833,
                "lng": 104.7833
            },
            "Chbar Mon": {
                "lat": 11.45,
                "lng": 104.5
            },
            "Kampong Chhnang": {
                "lat": 12.25,
                "lng": 104.6667
            },
            "Svay Rieng": {
                "lat": 11.0833,
                "lng": 105.8
            },
            "Kampot": {
                "lat": 10.6,
                "lng": 104.1667
            },
            "Prey Veng": {
                "lat": 11.4833,
                "lng": 105.3167
            },
            "Krong Kep": {
                "lat": 10.4875,
                "lng": 104.3208
            },
            "Suong": {
                "lat": 11.9167,
                "lng": 105.65
            },
            "Koh Kong": {
                "lat": 11.6167,
                "lng": 102.9833
            },
            "Kampong Speu": {
                "lat": 11.452,
                "lng": 104.519
            },
            "Kampong Thom": {
                "lat": 12.7,
                "lng": 104.9
            },
            "Banlung": {
                "lat": 13.7468,
                "lng": 107.0045
            },
            "Stung Treng": {
                "lat": 13.5167,
                "lng": 105.9667
            },
            "Tbeng Meanchey": {
                "lat": 13.8167,
                "lng": 104.9667
            },
            "Preah Vihear": {
                "lat": 13.8167,
                "lng": 104.9667
            },
            "Kampong Thum": {
                "lat": 12.712,
                "lng": 104.889
            },
            "Lumphat": {
                "lat": 13.507,
                "lng": 106.981
            },
            "Svay Pak": {
                "lat": 11.6467,
                "lng": 104.8715
            },
            "Sen Monorom": {
                "lat": 12.45,
                "lng": 107.2
            },
            "Pailin": {
                "lat": 12.8489,
                "lng": 102.6093
            },
            "Ta Khmau": {
                "lat": 11.4833,
                "lng": 104.95
            }
        }
    },
    "SE": {
        "name": "Sweden",
        "cities": {
            "Stockholm": {
                "lat": 59.3294,
                "lng": 18.0686
            },
            "Gothenburg": {
                "lat": 57.7075,
                "lng": 11.9675
            },
            "Malm\u00f6": {
                "lat": 55.6058,
                "lng": 13.0358
            },
            "Uppsala": {
                "lat": 59.8601,
                "lng": 17.64
            },
            "Norrk\u00f6ping": {
                "lat": 58.6,
                "lng": 16.2
            },
            "V\u00e4ster\u00e5s": {
                "lat": 59.6161,
                "lng": 16.5528
            },
            "\u00d6rebro": {
                "lat": 59.2739,
                "lng": 15.2075
            },
            "Link\u00f6ping": {
                "lat": 58.4158,
                "lng": 15.6253
            },
            "Bor\u00e5s": {
                "lat": 57.7211,
                "lng": 12.9403
            },
            "Helsingborg": {
                "lat": 56.05,
                "lng": 12.7167
            },
            "J\u00f6nk\u00f6ping": {
                "lat": 57.7828,
                "lng": 14.1606
            },
            "Sundsvall": {
                "lat": 62.4,
                "lng": 17.3167
            },
            "G\u00e4vle": {
                "lat": 60.6747,
                "lng": 17.1417
            },
            "Ume\u00e5": {
                "lat": 63.825,
                "lng": 20.2639
            },
            "Skellefte\u00e5": {
                "lat": 64.75,
                "lng": 20.95
            },
            "S\u00f6dert\u00e4lje": {
                "lat": 59.1958,
                "lng": 17.6281
            },
            "Halmstad": {
                "lat": 56.6739,
                "lng": 12.8572
            },
            "Eskilstuna": {
                "lat": 59.3708,
                "lng": 16.5097
            },
            "Karlskrona": {
                "lat": 56.1608,
                "lng": 15.5861
            },
            "V\u00e4xj\u00f6": {
                "lat": 56.8769,
                "lng": 14.8092
            },
            "Karlstad": {
                "lat": 59.3783,
                "lng": 13.5042
            },
            "T\u00e4by": {
                "lat": 59.4333,
                "lng": 18.0833
            },
            "Trollh\u00e4ttan": {
                "lat": 58.2828,
                "lng": 12.2892
            },
            "\u00d6stersund": {
                "lat": 63.1792,
                "lng": 14.6358
            },
            "Lule\u00e5": {
                "lat": 65.5844,
                "lng": 22.1539
            },
            "Upplands V\u00e4sby": {
                "lat": 59.5167,
                "lng": 17.9167
            },
            "Borl\u00e4nge": {
                "lat": 60.4856,
                "lng": 15.4364
            },
            "Trelleborg": {
                "lat": 55.3667,
                "lng": 13.1667
            },
            "\u00c4ngelholm": {
                "lat": 56.25,
                "lng": 12.85
            },
            "Kalmar": {
                "lat": 56.6614,
                "lng": 16.3628
            },
            "Lidk\u00f6ping": {
                "lat": 58.5,
                "lng": 13.1833
            },
            "Sk\u00f6vde": {
                "lat": 58.3833,
                "lng": 13.85
            },
            "Nyk\u00f6ping": {
                "lat": 58.7531,
                "lng": 17.0086
            },
            "Str\u00e4ngn\u00e4s": {
                "lat": 59.3667,
                "lng": 17.0333
            },
            "Alings\u00e5s": {
                "lat": 57.93,
                "lng": 12.5331
            },
            "Tumba": {
                "lat": 59.2,
                "lng": 17.8167
            },
            "Falun": {
                "lat": 60.6072,
                "lng": 15.6311
            },
            "M\u00f6lndal": {
                "lat": 57.65,
                "lng": 12.0167
            },
            "V\u00e4stervik": {
                "lat": 57.75,
                "lng": 16.6333
            },
            "Varberg": {
                "lat": 57.1167,
                "lng": 12.2167
            },
            "Uddevalla": {
                "lat": 58.3492,
                "lng": 11.9381
            },
            "Landskrona": {
                "lat": 55.8706,
                "lng": 12.8311
            },
            "\u00d6rnsk\u00f6ldsvik": {
                "lat": 63.2908,
                "lng": 18.7156
            },
            "K\u00e4vlinge": {
                "lat": 55.7939,
                "lng": 13.1133
            },
            "Kristianstad": {
                "lat": 56.0337,
                "lng": 14.1333
            },
            "Staffanstorp": {
                "lat": 55.6333,
                "lng": 13.2
            },
            "Liding\u00f6": {
                "lat": 59.3667,
                "lng": 18.15
            },
            "Motala": {
                "lat": 58.5333,
                "lng": 15.0333
            },
            "Vallentuna": {
                "lat": 59.5333,
                "lng": 18.0833
            },
            "Ystad": {
                "lat": 55.4167,
                "lng": 13.8333
            },
            "\u00c5kersberga": {
                "lat": 59.4833,
                "lng": 18.3
            },
            "Karlskoga": {
                "lat": 59.3333,
                "lng": 14.5167
            },
            "M\u00e4rsta": {
                "lat": 59.6167,
                "lng": 17.85
            },
            "Kiruna": {
                "lat": 67.8489,
                "lng": 20.3028
            },
            "Visby": {
                "lat": 57.6347,
                "lng": 18.2992
            },
            "Katrineholm": {
                "lat": 59.0,
                "lng": 16.2
            },
            "Boo": {
                "lat": 59.3167,
                "lng": 18.25
            },
            "V\u00e4nersborg": {
                "lat": 58.3806,
                "lng": 12.325
            },
            "Pite\u00e5": {
                "lat": 65.3167,
                "lng": 21.4833
            },
            "Kungsbacka": {
                "lat": 57.4833,
                "lng": 12.0667
            },
            "Sandviken": {
                "lat": 60.6167,
                "lng": 16.7833
            },
            "Kung\u00e4lv": {
                "lat": 57.8667,
                "lng": 11.9667
            },
            "Huskvarna": {
                "lat": 57.8,
                "lng": 14.2667
            },
            "Kumla": {
                "lat": 59.1167,
                "lng": 15.1333
            },
            "Enk\u00f6ping": {
                "lat": 59.6356,
                "lng": 17.0764
            },
            "Sj\u00f6bo": {
                "lat": 55.6333,
                "lng": 13.7167
            },
            "Karlshamn": {
                "lat": 56.1667,
                "lng": 14.85
            },
            "Skara": {
                "lat": 58.3833,
                "lng": 13.4333
            },
            "H\u00e4rn\u00f6sand": {
                "lat": 62.6361,
                "lng": 17.9411
            },
            "H\u00e4ssleholm": {
                "lat": 56.1667,
                "lng": 13.7667
            },
            "Falk\u00f6ping": {
                "lat": 58.175,
                "lng": 13.5531
            },
            "Kristinehamn": {
                "lat": 59.3,
                "lng": 14.1167
            },
            "Esl\u00f6v": {
                "lat": 55.8392,
                "lng": 13.3039
            },
            "K\u00f6ping": {
                "lat": 59.5167,
                "lng": 15.9833
            },
            "Norrt\u00e4lje": {
                "lat": 59.7667,
                "lng": 18.7
            },
            "Oskarshamn": {
                "lat": 57.265,
                "lng": 16.45
            },
            "Lerum": {
                "lat": 57.7667,
                "lng": 12.3
            },
            "Boden": {
                "lat": 65.8256,
                "lng": 21.6906
            },
            "Klippansbruk": {
                "lat": 56.1167,
                "lng": 13.15
            },
            "N\u00e4ssj\u00f6": {
                "lat": 57.65,
                "lng": 14.6833
            },
            "Mariestad": {
                "lat": 58.7,
                "lng": 13.8167
            },
            "H\u00f6gan\u00e4s": {
                "lat": 56.2,
                "lng": 12.5667
            },
            "Tullinge": {
                "lat": 59.2,
                "lng": 17.9
            },
            "M\u00f6lnlycke": {
                "lat": 57.6667,
                "lng": 12.1167
            },
            "Kinna": {
                "lat": 57.5167,
                "lng": 12.6833
            },
            "Bunkeflostrand": {
                "lat": 55.55,
                "lng": 12.9167
            },
            "Ljungby": {
                "lat": 56.8333,
                "lng": 13.9333
            },
            "Hallstahammar": {
                "lat": 59.6167,
                "lng": 16.25
            },
            "V\u00e4sterhaninge": {
                "lat": 59.1167,
                "lng": 18.0667
            },
            "Hudiksvall": {
                "lat": 61.7333,
                "lng": 17.1167
            },
            "Ludvika": {
                "lat": 60.1333,
                "lng": 15.1833
            },
            "Arvika": {
                "lat": 59.6542,
                "lng": 12.5914
            },
            "Tran\u00e5s": {
                "lat": 58.0333,
                "lng": 14.9667
            },
            "Arboga": {
                "lat": 59.3939,
                "lng": 15.8386
            },
            "Mj\u00f6lby": {
                "lat": 58.3333,
                "lng": 15.1167
            },
            "Lomma": {
                "lat": 55.6667,
                "lng": 13.0833
            },
            "Nybro": {
                "lat": 56.7333,
                "lng": 15.9
            },
            "Nyn\u00e4shamn": {
                "lat": 58.9,
                "lng": 17.95
            },
            "Skoghall": {
                "lat": 59.3333,
                "lng": 13.4333
            },
            "B\u00e5lsta": {
                "lat": 59.5833,
                "lng": 17.5
            },
            "Vetlanda": {
                "lat": 57.4333,
                "lng": 15.0667
            },
            "Sunne": {
                "lat": 59.8333,
                "lng": 13.1333
            },
            "H\u00f6\u00f6r": {
                "lat": 55.9361,
                "lng": 13.5472
            },
            "Mora": {
                "lat": 61.0167,
                "lng": 14.5333
            },
            "Finsp\u00e5ng": {
                "lat": 58.7,
                "lng": 15.8
            },
            "Bolln\u00e4s": {
                "lat": 61.3481,
                "lng": 16.3947
            },
            "Sala": {
                "lat": 59.9167,
                "lng": 16.6
            },
            "Hagfors": {
                "lat": 60.0333,
                "lng": 13.65
            },
            "Ronneby": {
                "lat": 56.2,
                "lng": 15.2833
            },
            "Avesta": {
                "lat": 60.1456,
                "lng": 16.1683
            },
            "S\u00f6derhamn": {
                "lat": 61.3,
                "lng": 17.0833
            },
            "Eker\u00f6": {
                "lat": 59.2833,
                "lng": 17.8
            },
            "Oxel\u00f6sund": {
                "lat": 58.6667,
                "lng": 17.1167
            },
            "Ulricehamn": {
                "lat": 57.7833,
                "lng": 13.4167
            },
            "Gustavsberg": {
                "lat": 59.3333,
                "lng": 18.3833
            },
            "S\u00e4ter": {
                "lat": 60.35,
                "lng": 15.75
            },
            "Fagersta": {
                "lat": 60.0042,
                "lng": 15.7933
            },
            "Lindome": {
                "lat": 57.5667,
                "lng": 12.1
            },
            "\u00c4lmhult": {
                "lat": 56.55,
                "lng": 14.1333
            },
            "Vimmerby": {
                "lat": 57.6667,
                "lng": 15.85
            },
            "Filipstad": {
                "lat": 59.7167,
                "lng": 14.1667
            },
            "Svedala": {
                "lat": 55.5,
                "lng": 13.2333
            },
            "H\u00f6llviken": {
                "lat": 55.4167,
                "lng": 12.95
            },
            "Timr\u00e5": {
                "lat": 62.4869,
                "lng": 17.3258
            },
            "Gagnef": {
                "lat": 60.5833,
                "lng": 15.0667
            },
            "\u00d6rkelljunga": {
                "lat": 56.2833,
                "lng": 13.2833
            },
            "Jordbro": {
                "lat": 59.15,
                "lng": 18.1333
            },
            "Billdal": {
                "lat": 57.5833,
                "lng": 11.9333
            },
            "Arl\u00f6v": {
                "lat": 55.6333,
                "lng": 13.0833
            },
            "Gislaved": {
                "lat": 57.3,
                "lng": 13.5333
            },
            "\u00c4lta": {
                "lat": 59.25,
                "lng": 18.1833
            },
            "Stenungsund": {
                "lat": 58.0833,
                "lng": 11.8167
            },
            "N\u00f6dinge-Nol": {
                "lat": 57.9,
                "lng": 12.0667
            },
            "Eksj\u00f6": {
                "lat": 57.6669,
                "lng": 14.9703
            },
            "Sigtuna": {
                "lat": 59.6167,
                "lng": 17.7167
            },
            "S\u00e4vja": {
                "lat": 59.8167,
                "lng": 17.7
            },
            "Lindesberg": {
                "lat": 59.5833,
                "lng": 15.25
            },
            "Bj\u00e4rred": {
                "lat": 55.7167,
                "lng": 13.0167
            },
            "Saltsj\u00f6baden": {
                "lat": 59.2861,
                "lng": 18.2872
            },
            "\u00c5storp": {
                "lat": 56.1347,
                "lng": 12.9472
            },
            "\u00c5hus": {
                "lat": 55.9167,
                "lng": 14.2833
            },
            "Kungs\u00e4ngen": {
                "lat": 59.4833,
                "lng": 17.75
            },
            "\u00c5m\u00e5l": {
                "lat": 59.05,
                "lng": 12.7
            },
            "S\u00e4ffle": {
                "lat": 59.1333,
                "lng": 12.9333
            },
            "Brevik": {
                "lat": 59.35,
                "lng": 18.2
            },
            "Habo": {
                "lat": 57.9167,
                "lng": 14.0667
            },
            "Sollefte\u00e5": {
                "lat": 63.1667,
                "lng": 17.2667
            },
            "Tibro": {
                "lat": 58.4167,
                "lng": 14.1667
            },
            "Lycksele": {
                "lat": 64.6,
                "lng": 18.6667
            }
        }
    },
    "CU": {
        "name": "Cuba",
        "cities": {
            "Havana": {
                "lat": 23.1367,
                "lng": -82.3589
            },
            "Santiago de Cuba": {
                "lat": 20.0217,
                "lng": -75.8294
            },
            "Holgu\u00edn": {
                "lat": 20.8869,
                "lng": -76.2592
            },
            "Camag\u00fcey": {
                "lat": 21.3839,
                "lng": -77.9075
            },
            "Santa Clara": {
                "lat": 22.4069,
                "lng": -79.9649
            },
            "Bayamo": {
                "lat": 20.3817,
                "lng": -76.6428
            },
            "Guant\u00e1namo": {
                "lat": 20.1367,
                "lng": -75.2139
            },
            "Carlos Manuel de C\u00e9spedes": {
                "lat": 21.5767,
                "lng": -78.2775
            },
            "Arroyo Naranjo": {
                "lat": 23.0436,
                "lng": -82.3328
            },
            "Las Tunas": {
                "lat": 20.9597,
                "lng": -76.9544
            },
            "Pinar del R\u00edo": {
                "lat": 22.4122,
                "lng": -83.6719
            },
            "Cienfuegos": {
                "lat": 22.1456,
                "lng": -80.4364
            },
            "San Miguel del Padr\u00f3n": {
                "lat": 23.0964,
                "lng": -82.3267
            },
            "Matanzas": {
                "lat": 23.0511,
                "lng": -81.5753
            },
            "Manzanillo": {
                "lat": 20.3397,
                "lng": -77.1086
            },
            "La Lisa": {
                "lat": 23.0247,
                "lng": -82.4631
            },
            "Ciego de \u00c1vila": {
                "lat": 21.8481,
                "lng": -78.7631
            },
            "Sancti Sp\u00edritus": {
                "lat": 21.9339,
                "lng": -79.4439
            },
            "Marianao": {
                "lat": 23.0833,
                "lng": -82.4333
            },
            "Centro Habana": {
                "lat": 23.1333,
                "lng": -82.3833
            },
            "Cerro": {
                "lat": 23.0881,
                "lng": -82.395
            },
            "Palma Soriano": {
                "lat": 20.2139,
                "lng": -75.9919
            },
            "Guanabacoa": {
                "lat": 23.2,
                "lng": -82.5
            },
            "Contramaestre": {
                "lat": 20.3,
                "lng": -76.2506
            },
            "Mayar\u00ed": {
                "lat": 20.6592,
                "lng": -75.6781
            },
            "Puerto Padre": {
                "lat": 21.195,
                "lng": -76.6017
            },
            "San Luis": {
                "lat": 22.2828,
                "lng": -83.7681
            },
            "Jag\u00fcey Grande": {
                "lat": 22.5292,
                "lng": -81.1325
            },
            "Consolaci\u00f3n del Sur": {
                "lat": 22.5083,
                "lng": -83.5172
            },
            "C\u00e1rdenas": {
                "lat": 23.0428,
                "lng": -81.2036
            },
            "San Jos\u00e9 de las Lajas": {
                "lat": 22.9678,
                "lng": -82.1558
            },
            "Baracoa": {
                "lat": 20.3486,
                "lng": -74.5106
            },
            "Trinidad": {
                "lat": 21.8042,
                "lng": -79.9831
            },
            "Cotorro": {
                "lat": 23.0261,
                "lng": -82.2475
            },
            "Moa": {
                "lat": 20.6397,
                "lng": -74.9175
            },
            "Col\u00f3n": {
                "lat": 22.7225,
                "lng": -80.9067
            },
            "Florida": {
                "lat": 21.5294,
                "lng": -78.2228
            },
            "Placetas": {
                "lat": 22.3158,
                "lng": -79.6556
            },
            "Gibara": {
                "lat": 21.1072,
                "lng": -76.1367
            },
            "G\u00fcines": {
                "lat": 22.8475,
                "lng": -82.0236
            },
            "Manicaragua": {
                "lat": 22.15,
                "lng": -79.9764
            },
            "Artemisa": {
                "lat": 22.8136,
                "lng": -82.7633
            },
            "Yara": {
                "lat": 20.2767,
                "lng": -76.9469
            },
            "Mor\u00f3n": {
                "lat": 22.1108,
                "lng": -78.6278
            },
            "Jiguan\u00ed": {
                "lat": 20.3731,
                "lng": -76.4222
            },
            "Jovellanos": {
                "lat": 22.8106,
                "lng": -81.1981
            },
            "Camajuan\u00ed": {
                "lat": 22.4678,
                "lng": -79.7239
            },
            "Bartolom\u00e9 Mas\u00f3": {
                "lat": 20.1686,
                "lng": -76.9428
            },
            "Santa Luc\u00eda": {
                "lat": 21.0281,
                "lng": -75.9964
            },
            "Yaguajay": {
                "lat": 22.3303,
                "lng": -79.2369
            },
            "Sagua la Grande": {
                "lat": 22.8086,
                "lng": -80.0711
            },
            "Ranchuelo": {
                "lat": 22.3764,
                "lng": -80.151
            },
            "Vertientes": {
                "lat": 21.2569,
                "lng": -78.1492
            },
            "B\u00e1guanos": {
                "lat": 20.7631,
                "lng": -76.0297
            },
            "Jobabo": {
                "lat": 20.9078,
                "lng": -77.2831
            },
            "Cumanayagua": {
                "lat": 22.1522,
                "lng": -80.2011
            },
            "Santo Domingo": {
                "lat": 22.5833,
                "lng": -80.2383
            },
            "Jes\u00fas Men\u00e9ndez": {
                "lat": 21.1633,
                "lng": -76.4775
            },
            "Salvador": {
                "lat": 20.2094,
                "lng": -75.2231
            },
            "Remedios": {
                "lat": 22.4922,
                "lng": -79.5458
            },
            "Nueva Gerona": {
                "lat": 21.8847,
                "lng": -82.8011
            },
            "R\u00edo Cauto": {
                "lat": 20.5636,
                "lng": -76.9172
            },
            "Sagua de T\u00e1namo": {
                "lat": 20.5858,
                "lng": -75.2417
            },
            "Guisa": {
                "lat": 20.2608,
                "lng": -76.5381
            },
            "San Juan y Mart\u00ednez": {
                "lat": 22.2667,
                "lng": -83.8333
            },
            "Regla": {
                "lat": 23.1317,
                "lng": -82.3364
            },
            "Bah\u00eda Honda": {
                "lat": 22.9064,
                "lng": -83.1639
            },
            "Jatibonico": {
                "lat": 21.9464,
                "lng": -79.1675
            },
            "Mariel": {
                "lat": 22.9936,
                "lng": -82.7539
            },
            "Campechuela": {
                "lat": 20.2333,
                "lng": -77.2792
            },
            "Niquero": {
                "lat": 20.0472,
                "lng": -77.5781
            },
            "Chambas": {
                "lat": 22.1967,
                "lng": -78.9133
            },
            "Majibacoa": {
                "lat": 20.9172,
                "lng": -76.8764
            },
            "Santa Cruz del Sur": {
                "lat": 20.7194,
                "lng": -77.9911
            },
            "Cacocum": {
                "lat": 20.7439,
                "lng": -76.3242
            },
            "Nuevitas": {
                "lat": 21.5403,
                "lng": -77.2644
            },
            "Los Palacios": {
                "lat": 22.5822,
                "lng": -83.2489
            },
            "Caibari\u00e9n": {
                "lat": 22.5158,
                "lng": -79.4722
            },
            "San Germ\u00e1n": {
                "lat": 20.6011,
                "lng": -76.1325
            },
            "G\u00fcira de Melena": {
                "lat": 22.8019,
                "lng": -82.5047
            },
            "Caimito": {
                "lat": 22.9575,
                "lng": -82.5964
            },
            "Amancio": {
                "lat": 20.8197,
                "lng": -77.5844
            },
            "Gu\u00e1imaro": {
                "lat": 21.0589,
                "lng": -77.3478
            },
            "Minas": {
                "lat": 21.4894,
                "lng": -77.605
            },
            "Guane": {
                "lat": 22.2006,
                "lng": -84.0839
            },
            "Guam\u00e1 Abajo": {
                "lat": 19.9758,
                "lng": -76.41
            },
            "Sandino": {
                "lat": 22.0803,
                "lng": -84.206
            },
            "La Palma": {
                "lat": 22.7472,
                "lng": -83.5525
            },
            "Uni\u00f3n de Reyes": {
                "lat": 22.8003,
                "lng": -81.5372
            },
            "Santa Cruz del Norte": {
                "lat": 23.1556,
                "lng": -81.9267
            },
            "Banes": {
                "lat": 20.9697,
                "lng": -75.7117
            },
            "San Antonio de los Ba\u00f1os": {
                "lat": 22.8883,
                "lng": -82.5058
            },
            "Mella": {
                "lat": 20.3694,
                "lng": -75.9111
            },
            "Taguasco": {
                "lat": 22.005,
                "lng": -79.265
            },
            "Baragu\u00e1": {
                "lat": 21.6819,
                "lng": -78.6244
            },
            "Rodas": {
                "lat": 22.3428,
                "lng": -80.5553
            },
            "Cabaigu\u00e1n": {
                "lat": 22.0839,
                "lng": -79.4953
            },
            "Palmira": {
                "lat": 22.2444,
                "lng": -80.3944
            },
            "Media Luna": {
                "lat": 20.1444,
                "lng": -77.4361
            },
            "Cueto": {
                "lat": 20.6481,
                "lng": -75.9317
            },
            "Encrucijada": {
                "lat": 22.6169,
                "lng": -79.8661
            },
            "Colombia": {
                "lat": 20.9906,
                "lng": -77.4158
            },
            "Aguada de Pasajeros": {
                "lat": 22.3847,
                "lng": -80.8461
            },
            "Fomento": {
                "lat": 22.1053,
                "lng": -79.7203
            },
            "Minas de Matahambre": {
                "lat": 22.5822,
                "lng": -83.9492
            },
            "Buey Arriba": {
                "lat": 20.1736,
                "lng": -76.7494
            },
            "Perico": {
                "lat": 22.7753,
                "lng": -81.0153
            },
            "Alqu\u00edzar": {
                "lat": 22.8067,
                "lng": -82.5828
            },
            "Pedro Betancourt": {
                "lat": 22.7303,
                "lng": -81.2908
            },
            "Quivic\u00e1n": {
                "lat": 22.8247,
                "lng": -82.3558
            },
            "Esmeralda": {
                "lat": 21.8561,
                "lng": -78.1114
            },
            "San Crist\u00f3bal": {
                "lat": 22.7169,
                "lng": -83.0511
            },
            "Primero de Enero": {
                "lat": 21.9453,
                "lng": -78.4189
            },
            "Cruces": {
                "lat": 22.3419,
                "lng": -80.2761
            },
            "Sibanic\u00fa": {
                "lat": 21.2389,
                "lng": -77.5211
            },
            "Manat\u00ed": {
                "lat": 21.3144,
                "lng": -76.9378
            },
            "Pil\u00f3n": {
                "lat": 19.9053,
                "lng": -77.3208
            },
            "Venezuela": {
                "lat": 21.7511,
                "lng": -78.7792
            },
            "Ciro Redondo": {
                "lat": 22.0189,
                "lng": -78.7031
            },
            "Bejucal": {
                "lat": 22.9328,
                "lng": -82.3869
            },
            "Vi\u00f1ales": {
                "lat": 22.6153,
                "lng": -83.7158
            },
            "Calimete": {
                "lat": 22.5339,
                "lng": -80.9097
            },
            "Majagua": {
                "lat": 21.9244,
                "lng": -78.9906
            },
            "Madruga": {
                "lat": 22.9164,
                "lng": -81.8572
            },
            "Cifuentes": {
                "lat": 22.6208,
                "lng": -80.0661
            },
            "San Antonio del Sur": {
                "lat": 20.0569,
                "lng": -74.8078
            },
            "Bataban\u00f3": {
                "lat": 22.6986,
                "lng": -82.2939
            },
            "Limonar": {
                "lat": 22.9561,
                "lng": -81.4086
            },
            "Corralillo": {
                "lat": 22.9856,
                "lng": -80.5831
            },
            "Nueva Paz": {
                "lat": 22.7633,
                "lng": -81.7581
            },
            "Jaruco": {
                "lat": 23.0428,
                "lng": -82.0094
            },
            "Bauta": {
                "lat": 22.9919,
                "lng": -82.5492
            },
            "Mantua": {
                "lat": 22.2908,
                "lng": -84.2875
            },
            "Abreus": {
                "lat": 22.2806,
                "lng": -80.5678
            },
            "Los Arabos": {
                "lat": 22.74,
                "lng": -80.7161
            },
            "Guanajay": {
                "lat": 22.9306,
                "lng": -82.6881
            },
            "Lajas": {
                "lat": 22.4164,
                "lng": -80.2906
            },
            "Cayo Mamb\u00ed": {
                "lat": 20.6647,
                "lng": -75.2814
            },
            "Mart\u00ed": {
                "lat": 22.9522,
                "lng": -80.9167
            },
            "San Nicol\u00e1s": {
                "lat": 22.7819,
                "lng": -81.9069
            },
            "Antilla": {
                "lat": 20.8411,
                "lng": -75.7206
            },
            "Manuel Tames": {
                "lat": 20.1803,
                "lng": -75.0514
            },
            "Varadero": {
                "lat": 23.1394,
                "lng": -81.2861
            },
            "Florencia": {
                "lat": 22.1475,
                "lng": -78.9669
            },
            "Melena del Sur": {
                "lat": 22.7881,
                "lng": -82.1512
            },
            "Quemado de G\u00fcines": {
                "lat": 22.79,
                "lng": -80.2561
            },
            "Cauto Cristo": {
                "lat": 20.5619,
                "lng": -76.4694
            },
            "Jimaguay\u00fa": {
                "lat": 21.2667,
                "lng": -77.8303
            },
            "Candelaria": {
                "lat": 22.7439,
                "lng": -82.9581
            },
            "La Sierpe": {
                "lat": 21.7606,
                "lng": -79.2433
            },
            "Najasa": {
                "lat": 21.0836,
                "lng": -77.7472
            },
            "Bolivia": {
                "lat": 22.075,
                "lng": -78.3503
            },
            "Jamaica": {
                "lat": 22.9775,
                "lng": -82.1697
            },
            "Guayos": {
                "lat": 22.0497,
                "lng": -79.4622
            },
            "San Antonio de las Vueltas": {
                "lat": 22.5167,
                "lng": -79.7021
            },
            "V\u00e1zquez": {
                "lat": 21.1399,
                "lng": -76.7294
            },
            "Esperanza": {
                "lat": 22.4472,
                "lng": -80.0966
            },
            "Caimanera": {
                "lat": 19.9947,
                "lng": -75.16
            },
            "Agramonte": {
                "lat": 22.6761,
                "lng": -81.1156
            },
            "Sola": {
                "lat": 21.6744,
                "lng": -77.6806
            },
            "Matagu\u00e1": {
                "lat": 22.237,
                "lng": -79.9992
            },
            "Im\u00edas": {
                "lat": 20.0767,
                "lng": -74.6519
            },
            "Australia": {
                "lat": 22.4995,
                "lng": -81.1359
            },
            "Falla": {
                "lat": 22.1704,
                "lng": -78.7872
            }
        }
    },
    "BY": {
        "name": "Belarus",
        "cities": {
            "Minsk": {
                "lat": 53.9,
                "lng": 27.5667
            },
            "Homyel\u2019": {
                "lat": 52.4453,
                "lng": 30.9842
            },
            "Vitsyebsk": {
                "lat": 55.1917,
                "lng": 30.2056
            },
            "Hrodna": {
                "lat": 53.6667,
                "lng": 23.8333
            },
            "Mahilyow": {
                "lat": 53.9167,
                "lng": 30.35
            },
            "Brest": {
                "lat": 52.1347,
                "lng": 23.6569
            },
            "Babruysk": {
                "lat": 53.15,
                "lng": 29.2333
            },
            "Baranavichy": {
                "lat": 53.1333,
                "lng": 26.0167
            },
            "Horad Barysaw": {
                "lat": 54.2279,
                "lng": 28.505
            },
            "Pinsk": {
                "lat": 52.1153,
                "lng": 26.1031
            },
            "Mazyr": {
                "lat": 52.05,
                "lng": 29.25
            },
            "Lida": {
                "lat": 53.8956,
                "lng": 25.2958
            },
            "Orsha": {
                "lat": 54.5092,
                "lng": 30.4258
            },
            "Salihorsk": {
                "lat": 52.8,
                "lng": 27.5333
            },
            "Navapolatsk": {
                "lat": 55.5333,
                "lng": 28.65
            },
            "Maladzyechna": {
                "lat": 54.3208,
                "lng": 26.8572
            },
            "Polatsk": {
                "lat": 55.4833,
                "lng": 28.8
            },
            "Zhlobin": {
                "lat": 52.9,
                "lng": 30.0333
            },
            "Rechytsa": {
                "lat": 52.3639,
                "lng": 30.3947
            },
            "Horad Zhodzina": {
                "lat": 54.1,
                "lng": 28.35
            },
            "Svyetlahorsk": {
                "lat": 52.6333,
                "lng": 29.7333
            },
            "Slutsk": {
                "lat": 53.0333,
                "lng": 27.5667
            },
            "Kobryn": {
                "lat": 52.2167,
                "lng": 24.3667
            },
            "Slonim": {
                "lat": 53.0833,
                "lng": 25.3167
            },
            "Vawkavysk": {
                "lat": 53.1667,
                "lng": 24.4667
            },
            "Kalinkavichy": {
                "lat": 52.125,
                "lng": 29.3333
            },
            "Smarhon": {
                "lat": 54.4836,
                "lng": 26.4
            },
            "Rahachow": {
                "lat": 53.1,
                "lng": 30.05
            },
            "Dzyarzhynsk": {
                "lat": 53.6833,
                "lng": 27.1333
            },
            "Asipovichy": {
                "lat": 53.2933,
                "lng": 28.4756
            },
            "Horki": {
                "lat": 54.2667,
                "lng": 30.9833
            },
            "Byaroza": {
                "lat": 52.55,
                "lng": 24.9667
            },
            "Navahrudak": {
                "lat": 53.5833,
                "lng": 25.8167
            },
            "Vilyeyka": {
                "lat": 54.498,
                "lng": 26.926
            },
            "Luninyets": {
                "lat": 52.25,
                "lng": 26.8
            },
            "Krychaw": {
                "lat": 53.7194,
                "lng": 31.7139
            },
            "Ivatsevichy": {
                "lat": 52.7167,
                "lng": 25.3333
            },
            "Horad Smalyavichy": {
                "lat": 54.1,
                "lng": 28.0667
            },
            "Mar\u2019\u2019ina Horka": {
                "lat": 53.5072,
                "lng": 28.1522
            },
            "Pruzhany": {
                "lat": 52.5567,
                "lng": 24.4644
            },
            "Pastavy": {
                "lat": 55.1167,
                "lng": 26.8333
            },
            "Dobrush": {
                "lat": 52.4167,
                "lng": 31.3167
            },
            "Fanipal\u2019": {
                "lat": 53.75,
                "lng": 27.3333
            },
            "Hlybokaye": {
                "lat": 55.1333,
                "lng": 27.6833
            },
            "Stowbtsy": {
                "lat": 53.4833,
                "lng": 26.7333
            },
            "Zaslawye": {
                "lat": 54.0083,
                "lng": 27.2847
            },
            "Lyepyel": {
                "lat": 54.875,
                "lng": 28.6944
            },
            "Ashmyany": {
                "lat": 54.425,
                "lng": 25.9375
            },
            "Bykhaw": {
                "lat": 53.5167,
                "lng": 30.25
            },
            "Ivanava": {
                "lat": 52.1333,
                "lng": 25.55
            },
            "Zhytkavichy": {
                "lat": 52.2333,
                "lng": 27.8667
            },
            "Nyasvizh": {
                "lat": 53.2167,
                "lng": 26.6667
            },
            "Shchuchyn": {
                "lat": 53.6167,
                "lng": 24.7333
            },
            "Lahoysk": {
                "lat": 54.2,
                "lng": 27.85
            },
            "Klimavichy": {
                "lat": 53.6167,
                "lng": 31.95
            },
            "Kastsyukovichy": {
                "lat": 53.3333,
                "lng": 32.05
            },
            "Shklow": {
                "lat": 54.2236,
                "lng": 30.2864
            },
            "Drahichyn": {
                "lat": 52.1833,
                "lng": 25.15
            },
            "Astravyets": {
                "lat": 54.6136,
                "lng": 25.9553
            },
            "Masty": {
                "lat": 53.417,
                "lng": 24.55
            },
            "Zhabinka": {
                "lat": 52.2006,
                "lng": 24.0233
            },
            "Stolin": {
                "lat": 51.8833,
                "lng": 26.85
            },
            "Hantsavichy": {
                "lat": 52.75,
                "lng": 26.4333
            },
            "Khoyniki": {
                "lat": 51.8892,
                "lng": 29.9644
            },
            "Malaryta": {
                "lat": 51.7833,
                "lng": 24.0833
            },
            "Mikashevichy": {
                "lat": 52.2203,
                "lng": 27.4736
            },
            "Lyelchytsy": {
                "lat": 51.7894,
                "lng": 28.3214
            },
            "Novalukoml\u2019": {
                "lat": 54.6569,
                "lng": 29.15
            },
            "Haradok": {
                "lat": 55.4667,
                "lng": 30.0
            },
            "Byerazino": {
                "lat": 53.8333,
                "lng": 28.9833
            },
            "Lyuban": {
                "lat": 52.7819,
                "lng": 28.0525
            },
            "Klyetsk": {
                "lat": 53.0636,
                "lng": 26.6372
            },
            "Byelaazyorsk": {
                "lat": 52.45,
                "lng": 25.1667
            },
            "Staryya Darohi": {
                "lat": 53.0394,
                "lng": 28.265
            },
            "Uzda": {
                "lat": 53.4661,
                "lng": 27.2244
            },
            "Lyakhavichy": {
                "lat": 53.0333,
                "lng": 26.2667
            },
            "Chervyen": {
                "lat": 53.7078,
                "lng": 28.4322
            },
            "Machulishchy": {
                "lat": 53.7814,
                "lng": 27.5958
            },
            "Pyetrykaw": {
                "lat": 52.1333,
                "lng": 28.5
            },
            "Baran": {
                "lat": 54.4833,
                "lng": 30.3333
            },
            "Kapyl": {
                "lat": 53.15,
                "lng": 27.0917
            },
            "Valozhyn": {
                "lat": 54.0833,
                "lng": 26.5167
            },
            "Mstsislaw": {
                "lat": 54.0167,
                "lng": 31.7167
            },
            "Chavusy": {
                "lat": 53.8075,
                "lng": 30.9714
            },
            "Byalynichy": {
                "lat": 53.9956,
                "lng": 29.7094
            },
            "Skidal\u2019": {
                "lat": 53.5861,
                "lng": 24.2519
            },
            "Talachyn": {
                "lat": 54.4167,
                "lng": 29.7
            },
            "Byarozawka": {
                "lat": 53.7167,
                "lng": 25.5
            },
            "Braslaw": {
                "lat": 55.6391,
                "lng": 27.0318
            },
            "Chachersk": {
                "lat": 52.9161,
                "lng": 30.9161
            },
            "Yelsk": {
                "lat": 51.8167,
                "lng": 29.15
            },
            "Vyetka": {
                "lat": 52.5667,
                "lng": 31.1833
            },
            "Buda-Kashalyova": {
                "lat": 52.7167,
                "lng": 30.5667
            }
        }
    },
    "NL": {
        "name": "Netherlands",
        "cities": {
            "Tilburg": {
                "lat": 51.55,
                "lng": 5.0833
            },
            "Amsterdam": {
                "lat": 52.3728,
                "lng": 4.8936
            },
            "Rotterdam": {
                "lat": 51.92,
                "lng": 4.48
            },
            "The Hague": {
                "lat": 52.08,
                "lng": 4.31
            },
            "Utrecht": {
                "lat": 52.0908,
                "lng": 5.1217
            },
            "Maastricht": {
                "lat": 50.85,
                "lng": 5.6833
            },
            "Eindhoven": {
                "lat": 51.4333,
                "lng": 5.4833
            },
            "Groningen": {
                "lat": 53.2189,
                "lng": 6.5675
            },
            "Almere": {
                "lat": 52.3667,
                "lng": 5.2167
            },
            "Breda": {
                "lat": 51.5889,
                "lng": 4.7758
            },
            "Nijmegen": {
                "lat": 51.8475,
                "lng": 5.8625
            },
            "Arnhem": {
                "lat": 51.9833,
                "lng": 5.9167
            },
            "Haarlem": {
                "lat": 52.3833,
                "lng": 4.6333
            },
            "Enschede": {
                "lat": 52.2225,
                "lng": 6.8925
            },
            "\u2019s-Hertogenbosch": {
                "lat": 51.6833,
                "lng": 5.3
            },
            "Amersfoort": {
                "lat": 52.15,
                "lng": 5.3833
            },
            "Zaanstad": {
                "lat": 52.4333,
                "lng": 4.8167
            },
            "Apeldoorn": {
                "lat": 52.2167,
                "lng": 5.9667
            },
            "Zwolle": {
                "lat": 52.5167,
                "lng": 6.1
            },
            "Zoetermeer": {
                "lat": 52.0667,
                "lng": 4.5
            },
            "Leeuwarden": {
                "lat": 53.2,
                "lng": 5.7833
            },
            "Leiden": {
                "lat": 52.16,
                "lng": 4.49
            },
            "Dordrecht": {
                "lat": 51.7958,
                "lng": 4.6783
            },
            "Alphen aan den Rijn": {
                "lat": 52.1333,
                "lng": 4.6667
            },
            "Alkmaar": {
                "lat": 52.6333,
                "lng": 4.75
            },
            "Delft": {
                "lat": 52.0117,
                "lng": 4.3592
            },
            "Emmen": {
                "lat": 52.7833,
                "lng": 6.9
            },
            "Deventer": {
                "lat": 52.25,
                "lng": 6.15
            },
            "Helmond": {
                "lat": 51.4833,
                "lng": 5.65
            },
            "Oss": {
                "lat": 51.7667,
                "lng": 5.5167
            },
            "Hilversum": {
                "lat": 52.2333,
                "lng": 5.1667
            },
            "Heerlen": {
                "lat": 50.8833,
                "lng": 5.9833
            },
            "Lelystad": {
                "lat": 52.5,
                "lng": 5.4833
            },
            "Purmerend": {
                "lat": 52.5,
                "lng": 4.95
            },
            "Hengelo": {
                "lat": 52.2653,
                "lng": 6.7931
            },
            "Schiedam": {
                "lat": 51.9167,
                "lng": 4.4
            },
            "Zaandam": {
                "lat": 52.4333,
                "lng": 4.8333
            },
            "Hoofddorp": {
                "lat": 52.3061,
                "lng": 4.6907
            },
            "Roosendaal": {
                "lat": 51.5333,
                "lng": 4.45
            },
            "Vlaardingen": {
                "lat": 51.9,
                "lng": 4.35
            },
            "Gouda": {
                "lat": 52.0111,
                "lng": 4.7111
            },
            "Hoorn": {
                "lat": 52.65,
                "lng": 5.0667
            },
            "Almelo": {
                "lat": 52.35,
                "lng": 6.6667
            },
            "Spijkenisse": {
                "lat": 51.85,
                "lng": 4.3333
            },
            "Ede": {
                "lat": 52.05,
                "lng": 5.6667
            },
            "Amstelveen": {
                "lat": 52.3,
                "lng": 4.85
            },
            "Assen": {
                "lat": 53.0,
                "lng": 6.5667
            },
            "Velsen-Zuid": {
                "lat": 52.4667,
                "lng": 4.6167
            },
            "Bergen op Zoom": {
                "lat": 51.5,
                "lng": 4.3
            },
            "Capelle aan den IJssel": {
                "lat": 51.9333,
                "lng": 4.5833
            },
            "Veenendaal": {
                "lat": 52.0167,
                "lng": 5.55
            },
            "Katwijk": {
                "lat": 52.2,
                "lng": 4.4167
            },
            "Zeist": {
                "lat": 52.0906,
                "lng": 5.2331
            },
            "Nieuwegein": {
                "lat": 52.0333,
                "lng": 5.0833
            },
            "Barneveld": {
                "lat": 52.1333,
                "lng": 5.5833
            },
            "Hardenberg": {
                "lat": 52.5667,
                "lng": 6.6167
            },
            "Scheveningen": {
                "lat": 52.1081,
                "lng": 4.2731
            },
            "Heerhugowaard": {
                "lat": 52.6667,
                "lng": 4.8333
            },
            "Doetinchem": {
                "lat": 51.9667,
                "lng": 6.3
            },
            "Roermond": {
                "lat": 51.2,
                "lng": 5.9833
            },
            "Den Helder": {
                "lat": 52.9333,
                "lng": 4.75
            },
            "Oosterhout": {
                "lat": 51.6333,
                "lng": 4.8667
            },
            "Hoogeveen": {
                "lat": 52.7333,
                "lng": 6.4833
            },
            "Rijswijk": {
                "lat": 52.0333,
                "lng": 4.3167
            },
            "Terneuzen": {
                "lat": 51.3333,
                "lng": 3.8333
            },
            "Kampen": {
                "lat": 52.55,
                "lng": 5.9167
            },
            "Woerden": {
                "lat": 52.0833,
                "lng": 4.8833
            },
            "Houten": {
                "lat": 52.0333,
                "lng": 5.1667
            },
            "Weert": {
                "lat": 51.25,
                "lng": 5.7
            },
            "Middelburg": {
                "lat": 51.5,
                "lng": 3.6167
            },
            "Waalwijk": {
                "lat": 51.6833,
                "lng": 5.0667
            },
            "Harderwijk": {
                "lat": 52.35,
                "lng": 5.6167
            },
            "Barendrecht": {
                "lat": 51.85,
                "lng": 4.5333
            },
            "IJmuiden": {
                "lat": 52.4586,
                "lng": 4.6194
            },
            "Zutphen": {
                "lat": 52.14,
                "lng": 6.195
            },
            "Soest": {
                "lat": 52.1833,
                "lng": 5.3
            },
            "Ridderkerk": {
                "lat": 51.8667,
                "lng": 4.6
            },
            "Schagen": {
                "lat": 52.7833,
                "lng": 4.8
            },
            "Veldhoven": {
                "lat": 51.42,
                "lng": 5.405
            },
            "Kerkrade": {
                "lat": 50.8667,
                "lng": 6.0667
            },
            "Drachten": {
                "lat": 53.1,
                "lng": 6.1
            },
            "Medemblik": {
                "lat": 52.6833,
                "lng": 5.0333
            },
            "Heusden": {
                "lat": 51.6833,
                "lng": 5.1333
            },
            "Zwijndrecht": {
                "lat": 51.8167,
                "lng": 4.65
            },
            "Vlissingen": {
                "lat": 51.45,
                "lng": 3.5667
            },
            "Zevenaar": {
                "lat": 51.9167,
                "lng": 6.0667
            },
            "Noordwijk": {
                "lat": 52.2333,
                "lng": 4.45
            },
            "Etten-Leur": {
                "lat": 51.5667,
                "lng": 4.6333
            },
            "Venray": {
                "lat": 51.5333,
                "lng": 5.9833
            },
            "Nijkerk": {
                "lat": 52.2333,
                "lng": 5.4833
            },
            "Rheden": {
                "lat": 52.0167,
                "lng": 6.05
            },
            "De Bilt": {
                "lat": 52.1167,
                "lng": 5.2
            },
            "Dronten": {
                "lat": 52.5167,
                "lng": 5.7167
            },
            "Tiel": {
                "lat": 51.8833,
                "lng": 5.4333
            },
            "Beverwijk": {
                "lat": 52.4833,
                "lng": 4.65
            },
            "Uden": {
                "lat": 51.65,
                "lng": 5.6167
            },
            "Wijchen": {
                "lat": 51.8,
                "lng": 5.7333
            },
            "Huizen": {
                "lat": 52.3,
                "lng": 5.25
            },
            "Hellevoetsluis": {
                "lat": 51.8167,
                "lng": 4.1333
            },
            "Maarssen": {
                "lat": 52.1351,
                "lng": 5.0413
            },
            "Wageningen": {
                "lat": 51.9667,
                "lng": 5.6667
            },
            "Heemskerk": {
                "lat": 52.5167,
                "lng": 4.6667
            },
            "Goes": {
                "lat": 51.5,
                "lng": 3.8833
            },
            "Veghel": {
                "lat": 51.6167,
                "lng": 5.55
            },
            "Raalte": {
                "lat": 52.3833,
                "lng": 6.2667
            },
            "Teijlingen": {
                "lat": 52.2167,
                "lng": 4.4833
            },
            "Venlo": {
                "lat": 51.3667,
                "lng": 6.1667
            },
            "Gorinchem": {
                "lat": 51.8306,
                "lng": 4.9742
            },
            "Landgraaf": {
                "lat": 50.9,
                "lng": 6.0167
            },
            "Moerdijk": {
                "lat": 51.65,
                "lng": 4.6
            },
            "Sittard": {
                "lat": 51.0,
                "lng": 5.8667
            },
            "Castricum": {
                "lat": 52.55,
                "lng": 4.6667
            },
            "Hellendoorn": {
                "lat": 52.3667,
                "lng": 6.4667
            },
            "Hoogvliet": {
                "lat": 51.8667,
                "lng": 4.35
            },
            "Coevorden": {
                "lat": 52.6667,
                "lng": 6.75
            },
            "Tynaarlo": {
                "lat": 53.0833,
                "lng": 6.5833
            },
            "Meppel": {
                "lat": 52.7,
                "lng": 6.1833
            },
            "Lochem": {
                "lat": 52.1615,
                "lng": 6.4152
            },
            "Sneek": {
                "lat": 53.0325,
                "lng": 5.66
            },
            "IJsselstein": {
                "lat": 52.0167,
                "lng": 5.0333
            },
            "Maassluis": {
                "lat": 51.9333,
                "lng": 4.2333
            },
            "Epe": {
                "lat": 52.35,
                "lng": 5.9833
            },
            "Boxtel": {
                "lat": 51.5833,
                "lng": 5.3333
            },
            "Deurne": {
                "lat": 51.4667,
                "lng": 5.8
            },
            "Bussum": {
                "lat": 52.2833,
                "lng": 5.1667
            },
            "Oisterwijk": {
                "lat": 51.5833,
                "lng": 5.2
            },
            "Papendrecht": {
                "lat": 51.8333,
                "lng": 4.6833
            },
            "Aalsmeer": {
                "lat": 52.2667,
                "lng": 4.75
            },
            "Stadskanaal": {
                "lat": 52.9833,
                "lng": 6.95
            },
            "Oldenzaal": {
                "lat": 52.3167,
                "lng": 6.9333
            },
            "Vught": {
                "lat": 51.65,
                "lng": 5.3
            },
            "Renkum": {
                "lat": 51.9833,
                "lng": 5.85
            },
            "Nieuw-Vennep": {
                "lat": 52.2644,
                "lng": 4.6347
            },
            "Waddinxveen": {
                "lat": 52.05,
                "lng": 4.65
            },
            "Diemen": {
                "lat": 52.3333,
                "lng": 4.9667
            },
            "Hendrik-Ido-Ambacht": {
                "lat": 51.85,
                "lng": 4.6333
            },
            "Valkenswaard": {
                "lat": 51.35,
                "lng": 5.4667
            },
            "Rosmalen": {
                "lat": 51.7167,
                "lng": 5.3667
            },
            "Leusden": {
                "lat": 52.1333,
                "lng": 5.4333
            },
            "Best": {
                "lat": 51.5167,
                "lng": 5.4
            },
            "Uithoorn": {
                "lat": 52.2333,
                "lng": 4.8333
            },
            "Oosterend": {
                "lat": 53.0667,
                "lng": 6.0833
            },
            "Heesch": {
                "lat": 51.7333,
                "lng": 5.5333
            },
            "Bergen": {
                "lat": 51.6,
                "lng": 6.05
            },
            "Sint-Michielsgestel": {
                "lat": 51.6333,
                "lng": 5.35
            },
            "Zaltbommel": {
                "lat": 51.8,
                "lng": 5.25
            },
            "Krimpen aan den IJssel": {
                "lat": 51.9167,
                "lng": 4.5833
            },
            "Nieuwkoop": {
                "lat": 52.1833,
                "lng": 4.7667
            },
            "Culemborg": {
                "lat": 51.9536,
                "lng": 5.2261
            },
            "Boxmeer": {
                "lat": 51.65,
                "lng": 5.95
            },
            "Winterswijk": {
                "lat": 51.9667,
                "lng": 6.7167
            },
            "Dalfsen": {
                "lat": 52.5,
                "lng": 6.25
            },
            "Heerenveen": {
                "lat": 52.9667,
                "lng": 5.9167
            },
            "Nunspeet": {
                "lat": 52.3833,
                "lng": 5.7833
            },
            "Geldrop": {
                "lat": 51.4222,
                "lng": 5.5578
            },
            "Langedijk": {
                "lat": 52.6833,
                "lng": 4.8
            },
            "Vleuten": {
                "lat": 52.1081,
                "lng": 5.015
            },
            "Brunssum": {
                "lat": 50.95,
                "lng": 5.9667
            },
            "Veendam": {
                "lat": 53.1,
                "lng": 6.8833
            },
            "Hulst": {
                "lat": 51.2833,
                "lng": 4.05
            },
            "Heemstede": {
                "lat": 52.35,
                "lng": 4.6167
            },
            "Leiderdorp": {
                "lat": 52.1667,
                "lng": 4.5333
            },
            "Blerick": {
                "lat": 51.3667,
                "lng": 6.15
            },
            "Drimmelen": {
                "lat": 51.6833,
                "lng": 4.8
            },
            "Pijnacker": {
                "lat": 52.0167,
                "lng": 4.4333
            },
            "Aalten": {
                "lat": 51.9333,
                "lng": 6.5833
            },
            "Ermelo": {
                "lat": 52.3,
                "lng": 5.6167
            },
            "Wassenaar": {
                "lat": 52.15,
                "lng": 4.4
            },
            "Werkendam": {
                "lat": 51.8,
                "lng": 4.9
            },
            "Dongen": {
                "lat": 51.6333,
                "lng": 4.9333
            },
            "Beuningen": {
                "lat": 51.8667,
                "lng": 5.7833
            },
            "Tholen": {
                "lat": 51.5333,
                "lng": 4.2
            },
            "Emmeloord": {
                "lat": 52.7097,
                "lng": 5.7508
            },
            "Voorschoten": {
                "lat": 52.1333,
                "lng": 4.45
            },
            "Sliedrecht": {
                "lat": 51.8167,
                "lng": 4.7667
            },
            "Veenoord": {
                "lat": 53.0,
                "lng": 6.3
            },
            "Duiven": {
                "lat": 51.95,
                "lng": 6.0333
            },
            "Oegstgeest": {
                "lat": 52.1833,
                "lng": 4.4667
            },
            "Steenbergen": {
                "lat": 51.5833,
                "lng": 4.3167
            },
            "Nijverdal": {
                "lat": 52.3667,
                "lng": 6.4667
            },
            "Cuijk": {
                "lat": 51.7296,
                "lng": 5.881
            },
            "Stein": {
                "lat": 50.9667,
                "lng": 5.7667
            },
            "Baarn": {
                "lat": 52.2167,
                "lng": 5.2833
            },
            "Voorst": {
                "lat": 52.2333,
                "lng": 6.1167
            },
            "Delfzijl": {
                "lat": 53.3333,
                "lng": 6.9167
            },
            "Wierden": {
                "lat": 52.35,
                "lng": 6.6
            },
            "Putten": {
                "lat": 52.2667,
                "lng": 5.6
            },
            "Oud-Beijerland": {
                "lat": 51.8167,
                "lng": 4.4
            },
            "Haaksbergen": {
                "lat": 52.15,
                "lng": 6.75
            },
            "Heiloo": {
                "lat": 52.6,
                "lng": 4.7167
            },
            "Goirle": {
                "lat": 51.5167,
                "lng": 5.0667
            },
            "Wijk bij Duurstede": {
                "lat": 51.9833,
                "lng": 5.3333
            },
            "Oldebroek": {
                "lat": 52.45,
                "lng": 5.9
            },
            "Borne": {
                "lat": 52.3,
                "lng": 6.75
            },
            "Loon op Zand": {
                "lat": 51.6667,
                "lng": 5.0333
            },
            "Bloemendaal": {
                "lat": 52.4,
                "lng": 4.6
            },
            "Elburg": {
                "lat": 52.45,
                "lng": 5.85
            },
            "Sluis": {
                "lat": 51.3083,
                "lng": 3.3861
            },
            "Rucphen": {
                "lat": 51.5333,
                "lng": 4.5833
            },
            "Lisse": {
                "lat": 52.25,
                "lng": 4.55
            },
            "Losser": {
                "lat": 52.2667,
                "lng": 7.0
            },
            "Zeewolde": {
                "lat": 52.3333,
                "lng": 5.55
            },
            "Beek en Donk": {
                "lat": 51.5347,
                "lng": 5.6303
            },
            "Volendam": {
                "lat": 52.4994,
                "lng": 5.0675
            },
            "Hillegom": {
                "lat": 52.2833,
                "lng": 4.5833
            },
            "\u2019s-Gravenzande": {
                "lat": 52.0,
                "lng": 4.1667
            },
            "Hoogezand": {
                "lat": 53.1572,
                "lng": 6.7533
            },
            "Woensdrecht": {
                "lat": 51.4167,
                "lng": 4.3333
            },
            "Zundert": {
                "lat": 51.4667,
                "lng": 4.6667
            },
            "Bilthoven": {
                "lat": 52.1283,
                "lng": 5.1986
            },
            "De Meern": {
                "lat": 52.0781,
                "lng": 5.0281
            },
            "Geertruidenberg": {
                "lat": 51.7,
                "lng": 4.8586
            },
            "Tubbergen": {
                "lat": 52.4,
                "lng": 6.7833
            },
            "Urk": {
                "lat": 52.6667,
                "lng": 5.6
            },
            "Cranendonck": {
                "lat": 51.3,
                "lng": 5.5833
            },
            "Brummen": {
                "lat": 52.0833,
                "lng": 6.15
            },
            "Nuenen": {
                "lat": 51.4733,
                "lng": 5.5467
            },
            "Bladel": {
                "lat": 51.3667,
                "lng": 5.2167
            },
            "Rhenen": {
                "lat": 51.9667,
                "lng": 5.5667
            },
            "Alblasserdam": {
                "lat": 51.8702,
                "lng": 4.6667
            },
            "Vianen": {
                "lat": 52.0,
                "lng": 5.1
            },
            "Eersel": {
                "lat": 51.35,
                "lng": 5.3167
            },
            "Someren": {
                "lat": 51.3833,
                "lng": 5.7167
            },
            "Weesp": {
                "lat": 52.3,
                "lng": 5.05
            },
            "Nootdorp": {
                "lat": 52.0333,
                "lng": 4.4
            },
            "Druten": {
                "lat": 51.8833,
                "lng": 5.6
            },
            "Krommenie": {
                "lat": 52.5,
                "lng": 4.7667
            },
            "Zuidhorn": {
                "lat": 53.25,
                "lng": 6.4
            },
            "Groesbeek": {
                "lat": 51.7833,
                "lng": 5.9333
            },
            "Naaldwijk": {
                "lat": 51.9931,
                "lng": 4.205
            },
            "Oirschot": {
                "lat": 51.5,
                "lng": 5.3
            },
            "Edam": {
                "lat": 52.5167,
                "lng": 5.05
            },
            "Heerde": {
                "lat": 52.3833,
                "lng": 6.05
            },
            "Bergeijk": {
                "lat": 51.3167,
                "lng": 5.35
            },
            "Meerssen": {
                "lat": 50.8833,
                "lng": 5.75
            },
            "Enkhuizen": {
                "lat": 52.7,
                "lng": 5.3
            },
            "Hardinxveld-Giessendam": {
                "lat": 51.8167,
                "lng": 4.8333
            },
            "Ommen": {
                "lat": 52.5167,
                "lng": 6.4167
            },
            "Middelharnis": {
                "lat": 51.75,
                "lng": 4.17
            },
            "Bodegraven": {
                "lat": 52.0822,
                "lng": 4.7504
            },
            "Haren": {
                "lat": 53.1667,
                "lng": 6.6
            },
            "Sint-Oedenrode": {
                "lat": 51.5667,
                "lng": 5.4667
            },
            "Son en Breugel": {
                "lat": 51.5167,
                "lng": 5.5
            },
            "Waalre": {
                "lat": 51.4,
                "lng": 5.4667
            },
            "Brielle": {
                "lat": 51.9,
                "lng": 4.1667
            },
            "Staphorst": {
                "lat": 52.65,
                "lng": 6.2167
            },
            "Nederweert": {
                "lat": 51.2833,
                "lng": 5.75
            },
            "Zandvoort": {
                "lat": 52.3667,
                "lng": 4.5333
            },
            "Gennep": {
                "lat": 51.7,
                "lng": 5.9667
            },
            "Gemert": {
                "lat": 51.5558,
                "lng": 5.6867
            },
            "Asten": {
                "lat": 51.4,
                "lng": 5.75
            },
            "Rijen": {
                "lat": 51.5833,
                "lng": 4.95
            },
            "Glanerbrug": {
                "lat": 52.215,
                "lng": 6.9742
            },
            "Noordwijkerhout": {
                "lat": 52.2667,
                "lng": 4.5
            },
            "Kaatsheuvel": {
                "lat": 51.6667,
                "lng": 5.0667
            },
            "Heumen": {
                "lat": 51.7833,
                "lng": 5.85
            },
            "Harenkarspel": {
                "lat": 52.73,
                "lng": 4.75
            },
            "Vlagtwedde": {
                "lat": 52.95,
                "lng": 7.15
            },
            "Schaesberg": {
                "lat": 50.9,
                "lng": 6.0167
            },
            "Beek": {
                "lat": 50.9333,
                "lng": 5.8
            },
            "Harlingen": {
                "lat": 53.1833,
                "lng": 5.4167
            },
            "Hilvarenbeek": {
                "lat": 51.4833,
                "lng": 5.1333
            },
            "Nuth": {
                "lat": 50.9167,
                "lng": 5.8833
            },
            "Bunnik": {
                "lat": 52.05,
                "lng": 5.2333
            },
            "Boskoop": {
                "lat": 52.0667,
                "lng": 4.65
            },
            "Westervoort": {
                "lat": 51.9667,
                "lng": 5.9667
            },
            "Sassenheim": {
                "lat": 52.2258,
                "lng": 4.5225
            },
            "Breukelen": {
                "lat": 52.1717,
                "lng": 5.0017
            },
            "Woudrichem": {
                "lat": 51.8167,
                "lng": 5.0
            },
            "Lopik": {
                "lat": 51.9667,
                "lng": 4.95
            },
            "Haaren": {
                "lat": 51.6,
                "lng": 5.2333
            },
            "Beilen": {
                "lat": 52.8567,
                "lng": 6.5111
            },
            "Montfoort": {
                "lat": 52.05,
                "lng": 4.95
            },
            "Julianadorp": {
                "lat": 52.8833,
                "lng": 4.7333
            },
            "Wezep": {
                "lat": 52.4625,
                "lng": 5.9983
            },
            "Woudenberg": {
                "lat": 52.0833,
                "lng": 5.4167
            },
            "Uitgeest": {
                "lat": 52.5333,
                "lng": 4.7167
            },
            "Winsum": {
                "lat": 53.3333,
                "lng": 6.5167
            },
            "Beesel": {
                "lat": 51.2833,
                "lng": 6.0333
            },
            "Margraten": {
                "lat": 50.8167,
                "lng": 5.8167
            },
            "Reeuwijk": {
                "lat": 52.05,
                "lng": 4.73
            },
            "Badhoevedorp": {
                "lat": 52.3333,
                "lng": 4.7833
            },
            "Wolvega": {
                "lat": 52.8761,
                "lng": 6.0014
            },
            "Joure": {
                "lat": 52.9667,
                "lng": 5.7833
            },
            "Horst": {
                "lat": 51.4514,
                "lng": 6.0536
            },
            "Schinnen": {
                "lat": 50.95,
                "lng": 5.8833
            },
            "Kapelle": {
                "lat": 51.4833,
                "lng": 3.95
            },
            "Dedemsvaart": {
                "lat": 52.6,
                "lng": 6.4667
            },
            "Franeker": {
                "lat": 53.1866,
                "lng": 5.5432
            },
            "Oudenbosch": {
                "lat": 51.5892,
                "lng": 4.5239
            },
            "Wieringerwerf": {
                "lat": 52.8506,
                "lng": 5.0228
            },
            "Grave": {
                "lat": 51.7667,
                "lng": 5.7333
            },
            "Made": {
                "lat": 51.6764,
                "lng": 4.7925
            },
            "Raamsdonksveer": {
                "lat": 51.6833,
                "lng": 4.8667
            },
            "Voerendaal": {
                "lat": 50.8833,
                "lng": 5.9333
            },
            "Rozenburg": {
                "lat": 51.9,
                "lng": 4.25
            },
            "Neerijnen": {
                "lat": 51.8333,
                "lng": 5.2833
            },
            "Dokkum": {
                "lat": 53.3269,
                "lng": 5.9981
            },
            "Hattem": {
                "lat": 52.4667,
                "lng": 6.0667
            },
            "Opmeer": {
                "lat": 52.7,
                "lng": 4.95
            },
            "Klazienaveen": {
                "lat": 52.7333,
                "lng": 6.9833
            },
            "Blaricum": {
                "lat": 52.2667,
                "lng": 5.25
            },
            "Schoonhoven": {
                "lat": 51.95,
                "lng": 4.85
            },
            "Appingedam": {
                "lat": 53.3167,
                "lng": 6.8667
            },
            "Sint Anthonis": {
                "lat": 51.6258,
                "lng": 5.8811
            },
            "Eijsden": {
                "lat": 50.7778,
                "lng": 5.7083
            },
            "Landsmeer": {
                "lat": 52.4333,
                "lng": 4.9167
            },
            "Laren": {
                "lat": 52.25,
                "lng": 5.2333
            },
            "Zierikzee": {
                "lat": 51.6497,
                "lng": 3.9164
            },
            "Koog aan de Zaan": {
                "lat": 52.4667,
                "lng": 4.8
            },
            "Leek": {
                "lat": 53.1667,
                "lng": 6.3833
            },
            "Mierlo": {
                "lat": 51.4411,
                "lng": 5.6194
            },
            "Doesburg": {
                "lat": 52.0167,
                "lng": 6.1333
            },
            "Boekel": {
                "lat": 51.6,
                "lng": 5.6667
            },
            "Malden": {
                "lat": 51.7833,
                "lng": 5.85
            },
            "Geldermalsen": {
                "lat": 51.8833,
                "lng": 5.2833
            },
            "Lieshout": {
                "lat": 51.5194,
                "lng": 5.5939
            },
            "Hoogland": {
                "lat": 52.1872,
                "lng": 5.3744
            },
            "Simpelveld": {
                "lat": 50.8333,
                "lng": 5.9833
            },
            "Leidschendam": {
                "lat": 52.0833,
                "lng": 4.4
            },
            "Ulft": {
                "lat": 51.8908,
                "lng": 6.3797
            },
            "Lemmer": {
                "lat": 52.8437,
                "lng": 5.7093
            },
            "Hoek van Holland": {
                "lat": 51.9811,
                "lng": 4.1286
            },
            "Oudewater": {
                "lat": 52.0167,
                "lng": 4.8667
            },
            "Scherpenzeel": {
                "lat": 52.0833,
                "lng": 5.4667
            },
            "Doorn": {
                "lat": 52.0333,
                "lng": 5.35
            },
            "Vaals": {
                "lat": 50.7667,
                "lng": 6.0167
            },
            "Zuidlaren": {
                "lat": 53.0942,
                "lng": 6.6844
            },
            "Roelofarendsveen": {
                "lat": 52.2,
                "lng": 4.6333
            },
            "Burgum": {
                "lat": 53.2,
                "lng": 6.0
            },
            "Bolsward": {
                "lat": 53.0667,
                "lng": 5.5333
            },
            "Heeze": {
                "lat": 51.3825,
                "lng": 5.5683
            },
            "Heerlerbaan": {
                "lat": 50.8692,
                "lng": 6.0103
            },
            "Eerbeek": {
                "lat": 52.1053,
                "lng": 6.0633
            },
            "Vlist": {
                "lat": 51.9667,
                "lng": 4.7667
            },
            "Bergambacht": {
                "lat": 51.9333,
                "lng": 4.7833
            },
            "Liesveld": {
                "lat": 51.9167,
                "lng": 4.825
            },
            "Genemuiden": {
                "lat": 52.6244,
                "lng": 6.0397
            },
            "Oostzaan": {
                "lat": 52.4333,
                "lng": 4.8833
            },
            "Nieuw-Lekkerland": {
                "lat": 51.8833,
                "lng": 4.6833
            },
            "Oosterwolde": {
                "lat": 52.9903,
                "lng": 6.2914
            },
            "Monnickendam": {
                "lat": 52.4667,
                "lng": 5.0333
            },
            "Sint Willebrord": {
                "lat": 51.5503,
                "lng": 4.5917
            },
            "Eemnes": {
                "lat": 52.25,
                "lng": 5.25
            },
            "Ubbergen": {
                "lat": 51.8333,
                "lng": 5.9167
            },
            "Nieuwleusen": {
                "lat": 52.5833,
                "lng": 6.2833
            },
            "Kudelstaart": {
                "lat": 52.2339,
                "lng": 4.7483
            },
            "Denekamp": {
                "lat": 52.3794,
                "lng": 7.0089
            },
            "Groenlo": {
                "lat": 52.0411,
                "lng": 6.6172
            },
            "\u2019s-Gravendeel": {
                "lat": 51.7833,
                "lng": 4.6167
            },
            "Zoeterwoude": {
                "lat": 52.1333,
                "lng": 4.5
            },
            "Strijen": {
                "lat": 51.75,
                "lng": 4.55
            },
            "Abcoude": {
                "lat": 52.27,
                "lng": 4.97
            },
            "Wervershoof": {
                "lat": 52.73,
                "lng": 5.17
            },
            "Ter Apel": {
                "lat": 52.8756,
                "lng": 7.0597
            },
            "Bedum": {
                "lat": 53.3,
                "lng": 6.6
            },
            "\u2019s-Heerenberg": {
                "lat": 51.8764,
                "lng": 6.2458
            },
            "Zaandijk": {
                "lat": 52.4667,
                "lng": 4.8
            },
            "Loenen": {
                "lat": 52.2167,
                "lng": 5.0167
            },
            "Reeuwijksebrug": {
                "lat": 52.0467,
                "lng": 4.7239
            }
        }
    },
    "KZ": {
        "name": "Kazakhstan",
        "cities": {
            "Almaty": {
                "lat": 43.24,
                "lng": 76.915
            },
            "Shymkent": {
                "lat": 42.3167,
                "lng": 69.5958
            },
            "Astana": {
                "lat": 51.1472,
                "lng": 71.4222
            },
            "Qaraghandy": {
                "lat": 49.8028,
                "lng": 73.1056
            },
            "\u00d6skemen": {
                "lat": 49.9833,
                "lng": 82.6167
            },
            "Aqt\u00f6be": {
                "lat": 50.2836,
                "lng": 57.2297
            },
            "Taraz": {
                "lat": 42.9,
                "lng": 71.3667
            },
            "Semey": {
                "lat": 50.4333,
                "lng": 80.2667
            },
            "Pavlodar": {
                "lat": 52.3,
                "lng": 76.95
            },
            "T\u00fcrkistan": {
                "lat": 43.3019,
                "lng": 68.2692
            },
            "Qyzylorda": {
                "lat": 44.85,
                "lng": 65.5167
            },
            "Oral": {
                "lat": 51.2225,
                "lng": 51.3725
            },
            "Qostanay": {
                "lat": 53.2,
                "lng": 63.62
            },
            "Petropavl": {
                "lat": 54.8833,
                "lng": 69.1667
            },
            "Temirta\u016b": {
                "lat": 50.05,
                "lng": 72.95
            },
            "Aqta\u016b": {
                "lat": 43.6525,
                "lng": 51.1575
            },
            "K\u00f6ksheta\u016b": {
                "lat": 53.2833,
                "lng": 69.3833
            },
            "Ekibastuz": {
                "lat": 51.6667,
                "lng": 75.3667
            },
            "Zhanga\u00f6zen": {
                "lat": 43.3378,
                "lng": 52.8553
            },
            "Atyra\u016b": {
                "lat": 47.1167,
                "lng": 51.8833
            },
            "Taldyqorghan": {
                "lat": 45.0167,
                "lng": 78.3667
            },
            "Rudnyy": {
                "lat": 52.9667,
                "lng": 63.1333
            },
            "Zhezqazghan": {
                "lat": 47.7833,
                "lng": 67.7
            },
            "Kenta\u016b": {
                "lat": 43.5167,
                "lng": 68.5167
            },
            "Balqash": {
                "lat": 46.8481,
                "lng": 74.995
            },
            "Aqs\u016b": {
                "lat": 52.4502,
                "lng": 71.9597
            },
            "S\u00e4tbayev": {
                "lat": 47.9,
                "lng": 67.5333
            },
            "Kapchagay": {
                "lat": 43.8833,
                "lng": 77.0833
            },
            "Qaskeleng": {
                "lat": 43.2,
                "lng": 76.62
            },
            "Ridder": {
                "lat": 50.35,
                "lng": 83.5167
            },
            "Zyryanovsk": {
                "lat": 49.7453,
                "lng": 84.2548
            },
            "Talghar": {
                "lat": 43.3,
                "lng": 77.2333
            },
            "Stepnogorsk": {
                "lat": 52.3497,
                "lng": 71.89
            },
            "Shch\u016bch\u012bnsk": {
                "lat": 52.9333,
                "lng": 70.2
            },
            "Qarabulaq": {
                "lat": 44.9089,
                "lng": 78.4922
            },
            "Zharkent": {
                "lat": 44.1667,
                "lng": 80.0
            },
            "Qapshaghay": {
                "lat": 43.8844,
                "lng": 77.0687
            },
            "Soran": {
                "lat": 49.7833,
                "lng": 72.85
            },
            "Ayag\u00f6z": {
                "lat": 47.9667,
                "lng": 80.4333
            },
            "Arys": {
                "lat": 42.4333,
                "lng": 68.8
            },
            "Beyne\u016b": {
                "lat": 45.3247,
                "lng": 55.1958
            },
            "Baikonur": {
                "lat": 45.6167,
                "lng": 63.3167
            },
            "Saryaghash": {
                "lat": 41.4667,
                "lng": 69.1667
            },
            "Shakht\u012bnsk": {
                "lat": 49.71,
                "lng": 72.5872
            },
            "Qulsary": {
                "lat": 46.9833,
                "lng": 54.0167
            },
            "Esik": {
                "lat": 43.35,
                "lng": 77.4667
            },
            "Zhetisay": {
                "lat": 40.7753,
                "lng": 68.3272
            },
            "Sh\u016b": {
                "lat": 43.5983,
                "lng": 73.7614
            },
            "Zhitiqara": {
                "lat": 52.1908,
                "lng": 61.2011
            },
            "Aksay": {
                "lat": 51.1678,
                "lng": 52.995
            },
            "Sayram": {
                "lat": 42.3,
                "lng": 69.7667
            },
            "Shiyeli": {
                "lat": 44.1789,
                "lng": 66.7328
            },
            "Staryy Beyneu": {
                "lat": 45.1834,
                "lng": 55.1
            },
            "Balyqshy": {
                "lat": 47.0667,
                "lng": 51.8667
            },
            "Tekeli": {
                "lat": 44.83,
                "lng": 78.8239
            },
            "Uzynaghash": {
                "lat": 43.2297,
                "lng": 76.31
            },
            "Qarata\u016b": {
                "lat": 43.1667,
                "lng": 70.45
            },
            "Aral": {
                "lat": 46.7833,
                "lng": 61.6667
            },
            "Atbasar": {
                "lat": 51.8,
                "lng": 68.3333
            },
            "Oktyabr\u2019sk": {
                "lat": 49.4556,
                "lng": 57.4161
            },
            "Sort\u00f6be": {
                "lat": 42.86,
                "lng": 75.23
            },
            "Arqalyq": {
                "lat": 50.2481,
                "lng": 66.9278
            },
            "Shalqar": {
                "lat": 47.8333,
                "lng": 59.6
            },
            "Boralday": {
                "lat": 43.3603,
                "lng": 76.8578
            },
            "Shelek": {
                "lat": 43.5972,
                "lng": 78.2511
            },
            "Abay": {
                "lat": 43.2092,
                "lng": 76.7603
            },
            "Lengir": {
                "lat": 42.1819,
                "lng": 69.8878
            },
            "\u00dcsht\u00f6be": {
                "lat": 45.2422,
                "lng": 77.9822
            },
            "Sarykemer": {
                "lat": 43.0106,
                "lng": 71.5172
            },
            "Oytal": {
                "lat": 42.9153,
                "lng": 73.2549
            },
            "Qarazhal": {
                "lat": 48.0253,
                "lng": 70.7999
            },
            "Khromta\u016b": {
                "lat": 50.2503,
                "lng": 58.4347
            },
            "Mangghysta\u016b": {
                "lat": 43.6956,
                "lng": 51.3261
            },
            "Zhangatas": {
                "lat": 43.5705,
                "lng": 69.7335
            },
            "Otegen Batyr": {
                "lat": 43.3333,
                "lng": 77.05
            },
            "Alga": {
                "lat": 49.9028,
                "lng": 57.3333
            },
            "Atas\u016b": {
                "lat": 48.6903,
                "lng": 71.6499
            },
            "T\u00f6le B\u012b": {
                "lat": 43.6768,
                "lng": 73.762
            },
            "Shemona\u012bkha": {
                "lat": 50.6269,
                "lng": 81.9109
            },
            "Zhosaly": {
                "lat": 45.4893,
                "lng": 64.0915
            },
            "Uryzhar": {
                "lat": 47.09,
                "lng": 81.6228
            },
            "Shongzhy": {
                "lat": 43.5417,
                "lng": 79.4703
            },
            "Mak\u012bnsk": {
                "lat": 52.6292,
                "lng": 70.4169
            },
            "\u00dcsharal": {
                "lat": 46.1697,
                "lng": 80.9394
            },
            "Bayserke": {
                "lat": 43.4797,
                "lng": 77.0336
            },
            "Maqat": {
                "lat": 47.65,
                "lng": 53.3167
            },
            "Qazyqurt": {
                "lat": 41.7598,
                "lng": 69.388
            },
            "Zaysan": {
                "lat": 47.4667,
                "lng": 84.8667
            },
            "Sarqan": {
                "lat": 45.41,
                "lng": 79.9186
            },
            "Eski \u012akan": {
                "lat": 43.1833,
                "lng": 68.5333
            },
            "Aqk\u00f6l": {
                "lat": 52.0,
                "lng": 70.9333
            },
            "Shamalgan": {
                "lat": 43.3708,
                "lng": 76.6236
            },
            "Masangshy": {
                "lat": 42.9289,
                "lng": 75.3019
            },
            "Shetpe": {
                "lat": 44.1667,
                "lng": 52.1167
            },
            "\u012anderbor": {
                "lat": 48.55,
                "lng": 51.7833
            },
            "Qulan": {
                "lat": 42.91,
                "lng": 72.715
            },
            "Balpyq B\u012b": {
                "lat": 44.8947,
                "lng": 78.2083
            },
            "Tuzdybasta\u016b": {
                "lat": 43.3189,
                "lng": 77.0594
            },
            "Ereymenta\u016b": {
                "lat": 51.6167,
                "lng": 73.1
            },
            "Ba\u016byrzhan Momyshuly": {
                "lat": 42.6189,
                "lng": 70.7597
            },
            "Taiynsha": {
                "lat": 53.8478,
                "lng": 69.7639
            },
            "Bayana\u016byl": {
                "lat": 50.7889,
                "lng": 75.6956
            },
            "Zhetibay": {
                "lat": 43.5942,
                "lng": 52.0789
            },
            "Novo\u012bsh\u012bmsk\u012by": {
                "lat": 53.1981,
                "lng": 66.7694
            },
            "Esil": {
                "lat": 51.9556,
                "lng": 66.4042
            },
            "Derbisek": {
                "lat": 41.5608,
                "lng": 69.3378
            },
            "Emba": {
                "lat": 48.8267,
                "lng": 58.1442
            },
            "L\u016bgovoy": {
                "lat": 42.9472,
                "lng": 72.7644
            },
            "Serebryansk": {
                "lat": 49.6819,
                "lng": 83.2847
            },
            "Len\u012bnsk\u012by": {
                "lat": 52.2528,
                "lng": 76.7789
            },
            "Krasnyy Yar": {
                "lat": 53.3239,
                "lng": 69.2525
            },
            "Qarabalyq": {
                "lat": 53.7506,
                "lng": 62.0502
            },
            "Aqadyr": {
                "lat": 48.2749,
                "lng": 72.8599
            },
            "Sa\u016bmalk\u00f6l": {
                "lat": 53.2914,
                "lng": 68.1094
            },
            "Borovskoy": {
                "lat": 53.7964,
                "lng": 64.1894
            },
            "Sholaqqorghan": {
                "lat": 43.765,
                "lng": 69.1758
            },
            "Qarqaraly": {
                "lat": 49.4167,
                "lng": 75.4167
            },
            "Kegen": {
                "lat": 43.0197,
                "lng": 79.2203
            },
            "Qashyr": {
                "lat": 53.0804,
                "lng": 76.09
            },
            "Makhambet": {
                "lat": 47.6667,
                "lng": 51.5833
            },
            "Chapaev": {
                "lat": 50.2,
                "lng": 51.1667
            },
            "B\u016blaevo": {
                "lat": 54.9056,
                "lng": 70.4439
            },
            "Shar": {
                "lat": 49.5858,
                "lng": 81.0478
            },
            "Qusmuryn": {
                "lat": 52.458,
                "lng": 64.6
            },
            "Osakarovka": {
                "lat": 50.5619,
                "lng": 72.5681
            },
            "Sharbaqty": {
                "lat": 52.48,
                "lng": 78.15
            },
            "Ertis": {
                "lat": 53.3333,
                "lng": 75.4572
            },
            "Qazaly": {
                "lat": 45.7667,
                "lng": 62.1
            },
            "Bayghan\u012bn": {
                "lat": 48.6917,
                "lng": 55.874
            },
            "Zh\u00e4nibek": {
                "lat": 49.4167,
                "lng": 46.85
            },
            "Best\u00f6be": {
                "lat": 52.4997,
                "lng": 73.0997
            },
            "Tobyl": {
                "lat": 52.698,
                "lng": 62.5749
            },
            "Zholymbet": {
                "lat": 51.7502,
                "lng": 71.7099
            },
            "Kishkenek\u00f6l": {
                "lat": 53.6394,
                "lng": 72.3439
            },
            "Derzhav\u012bnsk": {
                "lat": 51.1,
                "lng": 66.3167
            },
            "Fort-Shevchenko": {
                "lat": 44.5167,
                "lng": 50.2667
            },
            "Torghay": {
                "lat": 49.626,
                "lng": 63.499
            },
            "Otar": {
                "lat": 43.5375,
                "lng": 75.2089
            },
            "Saryshaghan": {
                "lat": 46.1167,
                "lng": 73.6167
            },
            "Zhaltyr": {
                "lat": 51.6324,
                "lng": 69.8328
            },
            "Burubaytal": {
                "lat": 44.935,
                "lng": 74.018
            }
        }
    },
    "MW": {
        "name": "Malawi",
        "cities": {
            "Blantyre": {
                "lat": -15.7861,
                "lng": 35.0058
            },
            "Lilongwe": {
                "lat": -13.9833,
                "lng": 33.7833
            },
            "Mzuzu": {
                "lat": -11.4581,
                "lng": 34.0151
            },
            "Zomba": {
                "lat": -15.3869,
                "lng": 35.3192
            },
            "Karonga": {
                "lat": -9.9333,
                "lng": 33.9333
            },
            "Kasungu": {
                "lat": -13.0333,
                "lng": 33.4833
            },
            "Mangochi": {
                "lat": -14.4667,
                "lng": 35.2667
            },
            "Salima": {
                "lat": -13.7833,
                "lng": 34.4333
            },
            "Liwonde": {
                "lat": -15.0667,
                "lng": 35.2333
            },
            "Dedza": {
                "lat": -14.3667,
                "lng": 34.3333
            },
            "Nkhotakota": {
                "lat": -12.9167,
                "lng": 34.3
            },
            "Mchinji": {
                "lat": -13.8167,
                "lng": 32.9
            },
            "Nsanje": {
                "lat": -16.9167,
                "lng": 35.2667
            },
            "Mzimba": {
                "lat": -11.9,
                "lng": 33.6
            },
            "Chiromo": {
                "lat": -16.55,
                "lng": 35.1333
            },
            "Mponela": {
                "lat": -13.5167,
                "lng": 33.7167
            },
            "Rumphi": {
                "lat": -11.0167,
                "lng": 33.8667
            },
            "Ntcheu": {
                "lat": -14.8333,
                "lng": 34.6667
            },
            "Mulanje": {
                "lat": -16.0258,
                "lng": 35.5081
            },
            "Mwanza": {
                "lat": -15.5986,
                "lng": 34.5178
            },
            "Chitipa": {
                "lat": -9.7019,
                "lng": 33.27
            },
            "Monkey Bay": {
                "lat": -14.0833,
                "lng": 34.9167
            },
            "Nkhata Bay": {
                "lat": -11.6,
                "lng": 34.3
            },
            "Luchenza": {
                "lat": -16.0167,
                "lng": 35.3
            },
            "Ntchisi": {
                "lat": -13.3667,
                "lng": 34.0
            },
            "Dowa": {
                "lat": -13.6667,
                "lng": 33.9167
            },
            "Thyolo": {
                "lat": -16.0667,
                "lng": 35.1333
            },
            "Phalombe": {
                "lat": -15.8033,
                "lng": 35.6533
            },
            "Chiradzulu": {
                "lat": -15.7,
                "lng": 35.1833
            },
            "Machinga": {
                "lat": -14.9667,
                "lng": 35.5167
            },
            "Balaka": {
                "lat": -14.9889,
                "lng": 34.9591
            },
            "Neno": {
                "lat": -15.3981,
                "lng": 34.6534
            },
            "Chikwawa": {
                "lat": -16.035,
                "lng": 34.801
            }
        }
    },
    "PL": {
        "name": "Poland",
        "cities": {
            "Warsaw": {
                "lat": 52.23,
                "lng": 21.0111
            },
            "Krak\u00f3w": {
                "lat": 50.0614,
                "lng": 19.9372
            },
            "Wroc\u0142aw": {
                "lat": 51.11,
                "lng": 17.0325
            },
            "\u0141\u00f3d\u017a": {
                "lat": 51.7769,
                "lng": 19.4547
            },
            "Pozna\u0144": {
                "lat": 52.4083,
                "lng": 16.9336
            },
            "Gda\u0144sk": {
                "lat": 54.3475,
                "lng": 18.6453
            },
            "Szczecin": {
                "lat": 53.4325,
                "lng": 14.5481
            },
            "Bydgoszcz": {
                "lat": 53.1219,
                "lng": 18.0003
            },
            "Lublin": {
                "lat": 51.25,
                "lng": 22.5667
            },
            "Bia\u0142ystok": {
                "lat": 53.1353,
                "lng": 23.1456
            },
            "Katowice": {
                "lat": 50.2625,
                "lng": 19.0217
            },
            "Gdynia": {
                "lat": 54.5175,
                "lng": 18.54
            },
            "Zielona G\u00f3ra": {
                "lat": 51.9333,
                "lng": 15.5
            },
            "Cz\u0119stochowa": {
                "lat": 50.8,
                "lng": 19.1167
            },
            "Radom": {
                "lat": 51.4036,
                "lng": 21.1567
            },
            "Toru\u0144": {
                "lat": 53.0222,
                "lng": 18.6111
            },
            "Rzesz\u00f3w": {
                "lat": 50.0333,
                "lng": 22.0
            },
            "Sosnowiec": {
                "lat": 50.2833,
                "lng": 19.1333
            },
            "Kielce": {
                "lat": 50.8742,
                "lng": 20.6333
            },
            "Gliwice": {
                "lat": 50.2833,
                "lng": 18.6667
            },
            "Olsztyn": {
                "lat": 53.7778,
                "lng": 20.4792
            },
            "Bielsko-Bia\u0142a": {
                "lat": 49.8225,
                "lng": 19.0444
            },
            "Zabrze": {
                "lat": 50.3025,
                "lng": 18.7781
            },
            "Bytom": {
                "lat": 50.3483,
                "lng": 18.9156
            },
            "Ruda \u015al\u0105ska": {
                "lat": 50.2628,
                "lng": 18.8536
            },
            "Rybnik": {
                "lat": 50.0833,
                "lng": 18.55
            },
            "Opole": {
                "lat": 50.6667,
                "lng": 17.9333
            },
            "Tychy": {
                "lat": 50.1236,
                "lng": 18.9867
            },
            "Gorz\u00f3w Wielkopolski": {
                "lat": 52.7333,
                "lng": 15.25
            },
            "D\u0105browa G\u00f3rnicza": {
                "lat": 50.3214,
                "lng": 19.1872
            },
            "Elbl\u0105g": {
                "lat": 54.1667,
                "lng": 19.4
            },
            "P\u0142ock": {
                "lat": 52.55,
                "lng": 19.7
            },
            "Tarn\u00f3w": {
                "lat": 50.0125,
                "lng": 20.9886
            },
            "Koszalin": {
                "lat": 54.2,
                "lng": 16.1833
            },
            "W\u0142oc\u0142awek": {
                "lat": 52.6592,
                "lng": 19.0681
            },
            "Wa\u0142brzych": {
                "lat": 50.7667,
                "lng": 16.2833
            },
            "Chorz\u00f3w": {
                "lat": 50.3,
                "lng": 18.95
            },
            "Kalisz": {
                "lat": 51.7575,
                "lng": 18.08
            },
            "Legnica": {
                "lat": 51.2083,
                "lng": 16.1603
            },
            "Grudzi\u0105dz": {
                "lat": 53.4875,
                "lng": 18.755
            },
            "Jaworzno": {
                "lat": 50.2044,
                "lng": 19.27
            },
            "S\u0142upsk": {
                "lat": 54.4658,
                "lng": 17.0292
            },
            "Jastrz\u0119bie-Zdr\u00f3j": {
                "lat": 49.95,
                "lng": 18.5833
            },
            "Nowy S\u0105cz": {
                "lat": 49.6239,
                "lng": 20.6972
            },
            "Skar\u017cysko-Kamienna": {
                "lat": 51.1167,
                "lng": 20.9167
            },
            "Jelenia G\u00f3ra": {
                "lat": 50.9033,
                "lng": 15.7344
            },
            "Siedlce": {
                "lat": 52.165,
                "lng": 22.2714
            },
            "Mys\u0142owice": {
                "lat": 50.2333,
                "lng": 19.1333
            },
            "Pi\u0142a": {
                "lat": 53.15,
                "lng": 16.7333
            },
            "Ostr\u00f3w Wielkopolski": {
                "lat": 51.6494,
                "lng": 17.8164
            },
            "Lubin": {
                "lat": 51.4,
                "lng": 16.2
            },
            "Konin": {
                "lat": 52.2167,
                "lng": 18.2667
            },
            "Inowroc\u0142aw": {
                "lat": 52.7931,
                "lng": 18.2611
            },
            "Piotrk\u00f3w Trybunalski": {
                "lat": 51.4,
                "lng": 19.6833
            },
            "Suwa\u0142ki": {
                "lat": 54.0989,
                "lng": 22.9286
            },
            "Stargard Szczeci\u0144ski": {
                "lat": 53.3333,
                "lng": 15.0333
            },
            "Gniezno": {
                "lat": 52.5358,
                "lng": 17.5958
            },
            "Pruszk\u00f3w": {
                "lat": 52.1667,
                "lng": 20.8
            },
            "Ostrowiec \u015awi\u0119tokrzyski": {
                "lat": 50.9333,
                "lng": 21.4
            },
            "Siemianowice \u015al\u0105skie": {
                "lat": 50.2758,
                "lng": 18.9858
            },
            "G\u0142og\u00f3w": {
                "lat": 51.6589,
                "lng": 16.0803
            },
            "Pabianice": {
                "lat": 51.65,
                "lng": 19.3833
            },
            "\u017bory": {
                "lat": 50.05,
                "lng": 18.7
            },
            "Leszno": {
                "lat": 51.8458,
                "lng": 16.5806
            },
            "Tarnowskie G\u00f3ry": {
                "lat": 50.4444,
                "lng": 18.8583
            },
            "\u0141om\u017ca": {
                "lat": 53.1764,
                "lng": 22.0731
            },
            "E\u0142k": {
                "lat": 53.8214,
                "lng": 22.3622
            },
            "Zamo\u015b\u0107": {
                "lat": 50.7167,
                "lng": 23.2528
            },
            "Che\u0142m": {
                "lat": 51.1322,
                "lng": 23.4778
            },
            "Tomasz\u00f3w Mazowiecki": {
                "lat": 51.5167,
                "lng": 20.0167
            },
            "Przemy\u015bl": {
                "lat": 49.7833,
                "lng": 22.7667
            },
            "Mielec": {
                "lat": 50.2833,
                "lng": 21.4333
            },
            "Tczew": {
                "lat": 54.0875,
                "lng": 18.7972
            },
            "Stalowa Wola": {
                "lat": 50.5667,
                "lng": 22.05
            },
            "K\u0119dzierzyn-Ko\u017ale": {
                "lat": 50.35,
                "lng": 18.2
            },
            "B\u0119dzin": {
                "lat": 50.3333,
                "lng": 19.1167
            },
            "Bia\u0142a Podlaska": {
                "lat": 52.0333,
                "lng": 23.1167
            },
            "Zgierz": {
                "lat": 51.85,
                "lng": 19.4167
            },
            "\u015awidnica": {
                "lat": 50.85,
                "lng": 16.4833
            },
            "Be\u0142chat\u00f3w": {
                "lat": 51.3667,
                "lng": 19.3667
            },
            "Legionowo": {
                "lat": 52.4,
                "lng": 20.8833
            },
            "Piekary \u015al\u0105skie": {
                "lat": 50.3833,
                "lng": 18.95
            },
            "Piaseczno": {
                "lat": 52.0667,
                "lng": 21.0167
            },
            "Rumia": {
                "lat": 54.5667,
                "lng": 18.4
            },
            "Racib\u00f3rz": {
                "lat": 50.0833,
                "lng": 18.2333
            },
            "Ostro\u0142\u0119ka": {
                "lat": 53.0667,
                "lng": 21.5667
            },
            "Zawiercie": {
                "lat": 50.5,
                "lng": 19.4167
            },
            "Wejherowo": {
                "lat": 54.6,
                "lng": 18.25
            },
            "\u015awi\u0119toch\u0142owice": {
                "lat": 50.2919,
                "lng": 18.9178
            },
            "Skierniewice": {
                "lat": 51.9528,
                "lng": 20.1417
            },
            "Starogard Gda\u0144ski": {
                "lat": 53.9667,
                "lng": 18.5333
            },
            "Starachowice": {
                "lat": 51.05,
                "lng": 21.0667
            },
            "Wodzis\u0142aw \u015al\u0105ski": {
                "lat": 50.0,
                "lng": 18.45
            },
            "Pu\u0142awy": {
                "lat": 51.4167,
                "lng": 21.9667
            },
            "Krosno": {
                "lat": 49.6833,
                "lng": 21.75
            },
            "Tarnobrzeg": {
                "lat": 50.5833,
                "lng": 21.6833
            },
            "Otwock": {
                "lat": 52.1167,
                "lng": 21.2667
            },
            "Radomsko": {
                "lat": 51.0667,
                "lng": 19.45
            },
            "Ko\u0142obrzeg": {
                "lat": 54.1761,
                "lng": 15.5761
            },
            "D\u0119bica": {
                "lat": 50.05,
                "lng": 21.4167
            },
            "Z\u0105bki": {
                "lat": 52.2928,
                "lng": 21.1161
            },
            "Ciechan\u00f3w": {
                "lat": 52.8667,
                "lng": 20.6333
            },
            "Kutno": {
                "lat": 52.2333,
                "lng": 19.3667
            },
            "Marki": {
                "lat": 52.3333,
                "lng": 21.1
            },
            "Nysa": {
                "lat": 50.4714,
                "lng": 17.3339
            },
            "Miko\u0142\u00f3w": {
                "lat": 50.1667,
                "lng": 18.9
            },
            "Mi\u0144sk Mazowiecki": {
                "lat": 52.1833,
                "lng": 21.5667
            },
            "\u015awinouj\u015bcie": {
                "lat": 53.9167,
                "lng": 14.25
            },
            "Sieradz": {
                "lat": 51.6,
                "lng": 18.75
            },
            "\u017byrard\u00f3w": {
                "lat": 52.05,
                "lng": 20.4333
            },
            "Chojnice": {
                "lat": 53.7,
                "lng": 17.55
            },
            "Szczecinek": {
                "lat": 53.7167,
                "lng": 16.6833
            },
            "\u015awidnik": {
                "lat": 51.2167,
                "lng": 22.7
            },
            "Kwidzyn": {
                "lat": 53.7358,
                "lng": 18.9308
            },
            "Malbork": {
                "lat": 54.0333,
                "lng": 19.0333
            },
            "Boles\u0142awiec": {
                "lat": 51.2667,
                "lng": 15.5667
            },
            "O\u015bwi\u0119cim": {
                "lat": 50.0381,
                "lng": 19.2214
            },
            "Nowa S\u00f3l": {
                "lat": 51.8,
                "lng": 15.7167
            },
            "Knur\u00f3w": {
                "lat": 50.2167,
                "lng": 18.6833
            },
            "Wo\u0142omin": {
                "lat": 52.35,
                "lng": 21.2333
            },
            "Jaros\u0142aw": {
                "lat": 50.0186,
                "lng": 22.6797
            },
            "\u017bary": {
                "lat": 51.6333,
                "lng": 15.1333
            },
            "Ole\u015bnica": {
                "lat": 51.2,
                "lng": 17.3833
            },
            "Sanok": {
                "lat": 49.55,
                "lng": 22.2167
            },
            "Czechowice-Dziedzice": {
                "lat": 49.9131,
                "lng": 19.0064
            },
            "Chrzan\u00f3w": {
                "lat": 50.1333,
                "lng": 19.4
            },
            "L\u0119bork": {
                "lat": 54.55,
                "lng": 17.75
            },
            "Brzeg": {
                "lat": 50.8667,
                "lng": 17.4833
            },
            "Sochaczew": {
                "lat": 52.2333,
                "lng": 20.2333
            },
            "Podg\u00f3rze": {
                "lat": 50.0417,
                "lng": 19.9833
            },
            "Cieszyn": {
                "lat": 49.7484,
                "lng": 18.6332
            },
            "Jas\u0142o": {
                "lat": 49.7478,
                "lng": 21.4714
            },
            "Grodzisk Mazowiecki": {
                "lat": 52.1089,
                "lng": 20.625
            },
            "Olkusz": {
                "lat": 50.2833,
                "lng": 19.5667
            },
            "Nowy Targ": {
                "lat": 49.4667,
                "lng": 20.0167
            },
            "O\u0142awa": {
                "lat": 50.9333,
                "lng": 17.3
            },
            "Lubo\u0144": {
                "lat": 52.3333,
                "lng": 16.8833
            },
            "Sopot": {
                "lat": 54.4333,
                "lng": 18.55
            },
            "I\u0142awa": {
                "lat": 53.5964,
                "lng": 19.5656
            },
            "Kra\u015bnik": {
                "lat": 50.9167,
                "lng": 22.2167
            },
            "Wrze\u015bnia": {
                "lat": 52.3333,
                "lng": 17.5833
            },
            "Dzier\u017coni\u00f3w": {
                "lat": 50.7281,
                "lng": 16.6511
            },
            "Pruszcz Gda\u0144ski": {
                "lat": 54.2667,
                "lng": 18.6333
            },
            "M\u0142awa": {
                "lat": 53.1167,
                "lng": 20.3667
            },
            "Czelad\u017a": {
                "lat": 50.3333,
                "lng": 19.0833
            },
            "Police": {
                "lat": 53.5333,
                "lng": 14.5667
            },
            "Myszk\u00f3w": {
                "lat": 50.5706,
                "lng": 19.3144
            },
            "\u017bywiec": {
                "lat": 49.6892,
                "lng": 19.2058
            },
            "Rury": {
                "lat": 51.2386,
                "lng": 22.5164
            },
            "Zgorzelec": {
                "lat": 51.15,
                "lng": 15.0083
            },
            "August\u00f3w": {
                "lat": 53.8436,
                "lng": 22.9794
            },
            "Swarz\u0119dz": {
                "lat": 52.4083,
                "lng": 17.0714
            },
            "Bochnia": {
                "lat": 49.9833,
                "lng": 20.4333
            },
            "Bielawa": {
                "lat": 50.7,
                "lng": 16.6167
            },
            "\u015arem": {
                "lat": 52.0886,
                "lng": 17.0147
            },
            "Krotoszyn": {
                "lat": 51.6833,
                "lng": 17.4333
            },
            "Nowy Dw\u00f3r Mazowiecki": {
                "lat": 52.4333,
                "lng": 20.7167
            },
            "Brodnica": {
                "lat": 53.2597,
                "lng": 19.3956
            },
            "Gi\u017cycko": {
                "lat": 54.04,
                "lng": 21.7589
            },
            "Reda": {
                "lat": 54.6167,
                "lng": 18.35
            },
            "\u0141uk\u00f3w": {
                "lat": 51.9272,
                "lng": 22.3833
            },
            "Koby\u0142ka": {
                "lat": 52.3333,
                "lng": 21.2
            },
            "\u0141owicz": {
                "lat": 52.1,
                "lng": 19.9333
            },
            "Wieliczka": {
                "lat": 49.9894,
                "lng": 20.0661
            },
            "Jarocin": {
                "lat": 51.9667,
                "lng": 17.5
            },
            "Gorlice": {
                "lat": 49.6547,
                "lng": 21.1597
            },
            "Wyszk\u00f3w": {
                "lat": 52.5928,
                "lng": 21.4584
            },
            "K\u0119trzyn": {
                "lat": 54.0833,
                "lng": 21.3833
            },
            "Halemba": {
                "lat": 50.2378,
                "lng": 18.8633
            },
            "W\u0105growiec": {
                "lat": 52.8,
                "lng": 17.2
            },
            "Zakopane": {
                "lat": 49.3,
                "lng": 19.95
            },
            "K\u0142odzko": {
                "lat": 50.4333,
                "lng": 16.65
            },
            "Pszczyna": {
                "lat": 49.9833,
                "lng": 18.95
            },
            "Bi\u0142goraj": {
                "lat": 50.55,
                "lng": 22.7333
            },
            "Turek": {
                "lat": 52.0167,
                "lng": 18.5
            },
            "\u015awiecie": {
                "lat": 53.4167,
                "lng": 18.4333
            },
            "Bielsk Podlaski": {
                "lat": 52.7667,
                "lng": 23.2
            },
            "\u017baga\u0144": {
                "lat": 51.6167,
                "lng": 15.3167
            },
            "\u015aroda Wielkopolska": {
                "lat": 52.2333,
                "lng": 17.2833
            },
            "Skawina": {
                "lat": 49.9833,
                "lng": 19.8333
            },
            "Wa\u0142cz": {
                "lat": 53.2667,
                "lng": 16.4667
            },
            "Ko\u015bcian": {
                "lat": 52.0833,
                "lng": 16.65
            },
            "Ko\u015bcierzyna": {
                "lat": 54.1167,
                "lng": 17.9833
            },
            "Lubliniec": {
                "lat": 50.6833,
                "lng": 18.6833
            },
            "Piast\u00f3w": {
                "lat": 52.1833,
                "lng": 20.85
            },
            "Bia\u0142ogard": {
                "lat": 54.0,
                "lng": 15.9833
            },
            "Zdu\u0144ska Wola": {
                "lat": 51.6,
                "lng": 18.9667
            },
            "Kluczbork": {
                "lat": 50.9833,
                "lng": 18.2167
            },
            "Szczytno": {
                "lat": 53.5628,
                "lng": 20.9853
            },
            "Bartoszyce": {
                "lat": 54.25,
                "lng": 20.8
            },
            "\u015awiebodzice": {
                "lat": 50.8667,
                "lng": 16.3333
            },
            "Sandomierz": {
                "lat": 50.6833,
                "lng": 21.75
            },
            "Goleni\u00f3w": {
                "lat": 53.5636,
                "lng": 14.8281
            },
            "Aleksandr\u00f3w \u0141\u00f3dzki": {
                "lat": 51.8194,
                "lng": 19.3039
            },
            "Ostr\u00f3w Mazowiecka": {
                "lat": 52.8,
                "lng": 21.9
            },
            "P\u0142o\u0144sk": {
                "lat": 52.6333,
                "lng": 20.3833
            },
            "Polkowice": {
                "lat": 51.5,
                "lng": 16.0667
            },
            "Orzesze": {
                "lat": 50.15,
                "lng": 18.75
            },
            "\u0141aziska G\u00f3rne": {
                "lat": 50.15,
                "lng": 18.85
            },
            "Jawor": {
                "lat": 51.05,
                "lng": 16.2
            },
            "\u015awiedbodzin": {
                "lat": 52.25,
                "lng": 15.5333
            },
            "Grajewo": {
                "lat": 53.65,
                "lng": 22.45
            },
            "Zambr\u00f3w": {
                "lat": 52.9833,
                "lng": 22.25
            },
            "Mr\u0105gowo": {
                "lat": 53.8667,
                "lng": 21.3
            },
            "Wielu\u0144": {
                "lat": 51.2206,
                "lng": 18.57
            },
            "Nowa Ruda": {
                "lat": 50.5833,
                "lng": 16.5
            },
            "Sulej\u00f3wek": {
                "lat": 52.2442,
                "lng": 21.28
            },
            "J\u00f3zef\u00f3w": {
                "lat": 52.1356,
                "lng": 21.2369
            },
            "Dzia\u0142dowo": {
                "lat": 53.2333,
                "lng": 20.1833
            },
            "Lubart\u00f3w": {
                "lat": 51.4667,
                "lng": 22.6
            },
            "Ko\u0142o": {
                "lat": 52.2,
                "lng": 18.6333
            },
            "Rawicz": {
                "lat": 51.6092,
                "lng": 16.8575
            },
            "Gryfino": {
                "lat": 53.2531,
                "lng": 14.4875
            },
            "Gosty\u0144": {
                "lat": 51.8792,
                "lng": 17.0125
            },
            "Luba\u0144": {
                "lat": 51.1181,
                "lng": 15.2889
            },
            "Prudnik": {
                "lat": 50.3228,
                "lng": 17.5767
            },
            "Opoczno": {
                "lat": 51.3772,
                "lng": 20.2869
            },
            "Hajn\u00f3wka": {
                "lat": 52.7333,
                "lng": 23.5667
            },
            "Bieru\u0144": {
                "lat": 50.1333,
                "lng": 19.1
            },
            "Pu\u0142tusk": {
                "lat": 52.7,
                "lng": 21.0833
            },
            "Andrych\u00f3w": {
                "lat": 49.855,
                "lng": 19.3414
            },
            "Trzebinia": {
                "lat": 50.1667,
                "lng": 19.4833
            },
            "Bieru\u0144 Stary": {
                "lat": 50.0897,
                "lng": 19.0928
            },
            "Konstantyn\u00f3w \u0141\u00f3dzki": {
                "lat": 51.75,
                "lng": 19.3333
            },
            "Che\u0142mno": {
                "lat": 53.3492,
                "lng": 18.4231
            },
            "Z\u0142ot\u00f3w": {
                "lat": 53.3603,
                "lng": 17.0408
            },
            "Ozork\u00f3w": {
                "lat": 51.9667,
                "lng": 19.2833
            },
            "Szamotu\u0142y": {
                "lat": 52.6,
                "lng": 16.5833
            },
            "Tomasz\u00f3w Lubelski": {
                "lat": 50.45,
                "lng": 23.4167
            },
            "Soko\u0142\u00f3w Podlaski": {
                "lat": 52.4067,
                "lng": 22.2464
            },
            "Giszowiec": {
                "lat": 50.2236,
                "lng": 19.0694
            },
            "Pisz": {
                "lat": 53.6167,
                "lng": 21.8
            },
            "\u0141\u0119czna": {
                "lat": 51.3,
                "lng": 22.8833
            },
            "Zielonka": {
                "lat": 52.3008,
                "lng": 21.1586
            },
            "Ko\u0144skie": {
                "lat": 51.2,
                "lng": 20.4167
            },
            "\u0141omianki": {
                "lat": 52.3333,
                "lng": 20.8833
            },
            "K\u0119ty": {
                "lat": 49.9,
                "lng": 19.2167
            },
            "My\u015blenice": {
                "lat": 49.8347,
                "lng": 19.9389
            },
            "Chodzie\u017c": {
                "lat": 52.9833,
                "lng": 16.9
            },
            "Jasien": {
                "lat": 54.3667,
                "lng": 18.6333
            },
            "Kamienna G\u00f3ra": {
                "lat": 50.7833,
                "lng": 16.0333
            },
            "Wadowice": {
                "lat": 49.8833,
                "lng": 19.5
            },
            "Kostrzyn nad Odr\u0105": {
                "lat": 52.5883,
                "lng": 14.6667
            },
            "\u0141a\u0144cut": {
                "lat": 50.0667,
                "lng": 22.2333
            },
            "Oborniki": {
                "lat": 52.65,
                "lng": 16.8167
            },
            "Gostynin": {
                "lat": 52.4167,
                "lng": 19.4667
            },
            "Nak\u0142o nad Noteci\u0105": {
                "lat": 53.1403,
                "lng": 17.5928
            },
            "Sok\u00f3\u0142ka": {
                "lat": 53.4,
                "lng": 23.5
            },
            "Krasnystaw": {
                "lat": 51.0,
                "lng": 23.1667
            },
            "Strzelce Opolskie": {
                "lat": 50.5,
                "lng": 18.2833
            },
            "Mi\u0119dzyrzecz": {
                "lat": 52.4483,
                "lng": 15.5883
            },
            "Pyskowice": {
                "lat": 50.3833,
                "lng": 18.6167
            },
            "Sierpc": {
                "lat": 52.8833,
                "lng": 19.6667
            },
            "Garwolin": {
                "lat": 51.9,
                "lng": 21.6333
            },
            "Konstancin-Jeziorna": {
                "lat": 52.0833,
                "lng": 21.1167
            },
            "Gr\u00f3jec": {
                "lat": 51.8656,
                "lng": 20.8675
            },
            "Namys\u0142\u00f3w": {
                "lat": 51.0728,
                "lng": 17.7069
            },
            "Pleszew": {
                "lat": 51.8833,
                "lng": 17.7833
            },
            "Hrubiesz\u00f3w": {
                "lat": 50.8167,
                "lng": 23.8833
            },
            "Trzcianka": {
                "lat": 53.05,
                "lng": 16.4667
            },
            "Pionki": {
                "lat": 51.4833,
                "lng": 21.45
            },
            "Byt\u00f3w": {
                "lat": 54.1333,
                "lng": 17.5
            },
            "Przasnysz": {
                "lat": 53.0167,
                "lng": 20.8833
            },
            "Bogatynia": {
                "lat": 50.9069,
                "lng": 14.9569
            },
            "\u0141ask": {
                "lat": 51.5903,
                "lng": 19.1333
            },
            "Bogucice": {
                "lat": 50.2668,
                "lng": 19.0397
            },
            "S\u0142ubice": {
                "lat": 52.35,
                "lng": 14.5667
            },
            "Milan\u00f3wek": {
                "lat": 52.1167,
                "lng": 20.65
            },
            "Braniewo": {
                "lat": 54.3833,
                "lng": 19.8333
            },
            "Rawa Mazowiecka": {
                "lat": 51.7658,
                "lng": 20.2533
            },
            "Libi\u0105\u017c": {
                "lat": 50.1,
                "lng": 19.3167
            },
            "Radzionk\u00f3w Nowy": {
                "lat": 50.3833,
                "lng": 18.8833
            },
            "Brzesko": {
                "lat": 49.9667,
                "lng": 20.6167
            },
            "Kozienice": {
                "lat": 51.5833,
                "lng": 21.5667
            },
            "Ustro\u0144": {
                "lat": 49.7194,
                "lng": 18.8119
            },
            "Gubin": {
                "lat": 51.95,
                "lng": 14.7167
            },
            "Nowogard": {
                "lat": 53.6667,
                "lng": 15.1167
            },
            "Olecko": {
                "lat": 54.0333,
                "lng": 22.5
            },
            "Czernica": {
                "lat": 51.0464,
                "lng": 17.2439
            },
            "Sulech\u00f3w": {
                "lat": 52.0833,
                "lng": 15.6167
            },
            "Gryfice": {
                "lat": 53.9147,
                "lng": 15.1986
            },
            "Osowa": {
                "lat": 54.4272,
                "lng": 18.4708
            },
            "Rypin": {
                "lat": 53.0667,
                "lng": 19.45
            },
            "Mi\u0119dzyrzec Podlaski": {
                "lat": 51.9833,
                "lng": 22.7833
            },
            "Ropczyce": {
                "lat": 50.0861,
                "lng": 21.6333
            },
            "Krapkowice": {
                "lat": 50.4667,
                "lng": 17.9667
            },
            "Niepo\u0142omice": {
                "lat": 50.0339,
                "lng": 20.2172
            },
            "Solec Kujawski": {
                "lat": 53.0833,
                "lng": 18.2333
            },
            "Jelcz-Laskowice": {
                "lat": 51.0333,
                "lng": 17.3333
            },
            "Strzegom": {
                "lat": 50.9611,
                "lng": 16.3444
            },
            "Grodzisk Wielkopolski": {
                "lat": 52.2333,
                "lng": 16.3667
            },
            "Busko-Zdr\u00f3j": {
                "lat": 50.4667,
                "lng": 20.7167
            },
            "\u015awidwin": {
                "lat": 53.7833,
                "lng": 15.7667
            },
            "Przeworsk": {
                "lat": 50.0667,
                "lng": 22.5
            },
            "Lidzbark Warmi\u0144ski": {
                "lat": 54.1167,
                "lng": 20.5833
            },
            "Nisko": {
                "lat": 50.52,
                "lng": 22.1394
            },
            "J\u00f3zefos\u0142aw": {
                "lat": 52.1069,
                "lng": 21.0392
            },
            "Radzy\u0144 Podlaski": {
                "lat": 51.7828,
                "lng": 22.6242
            },
            "D\u0119blin": {
                "lat": 51.5667,
                "lng": 21.8614
            },
            "\u0141apy": {
                "lat": 52.9833,
                "lng": 22.8833
            },
            "Z\u0142otoryja": {
                "lat": 51.1333,
                "lng": 15.9167
            },
            "Bogusz\u00f3w-Gorce": {
                "lat": 50.7667,
                "lng": 16.2
            },
            "Limanowa": {
                "lat": 49.7006,
                "lng": 20.4267
            },
            "J\u0119drzej\u00f3w": {
                "lat": 50.6333,
                "lng": 20.3
            },
            "Z\u0105bkowice \u015al\u0105skie": {
                "lat": 50.5833,
                "lng": 16.8167
            },
            "Mosina": {
                "lat": 52.2467,
                "lng": 16.845
            },
            "Brwin\u00f3w": {
                "lat": 52.1417,
                "lng": 20.7167
            },
            "Choszczno": {
                "lat": 53.1667,
                "lng": 15.4
            },
            "O\u017car\u00f3w Mazowiecki": {
                "lat": 52.2167,
                "lng": 20.8
            },
            "Ustka": {
                "lat": 54.5833,
                "lng": 16.85
            },
            "Radzymin": {
                "lat": 52.4167,
                "lng": 21.1833
            },
            "Bia\u0142y Kamie\u0144": {
                "lat": 50.7814,
                "lng": 16.2539
            },
            "Kartuzy": {
                "lat": 54.3333,
                "lng": 18.2
            },
            "Plewiska": {
                "lat": 52.3664,
                "lng": 16.8089
            },
            "Stasz\u00f3w": {
                "lat": 50.5633,
                "lng": 21.1717
            },
            "Nowy Tomy\u015bl": {
                "lat": 52.3167,
                "lng": 16.1333
            },
            "Che\u0142m\u017ca": {
                "lat": 53.1847,
                "lng": 18.6042
            },
            "Ostrzesz\u00f3w": {
                "lat": 51.4167,
                "lng": 17.9333
            },
            "K\u0119pno": {
                "lat": 51.2833,
                "lng": 17.9833
            },
            "Skocz\u00f3w": {
                "lat": 49.8006,
                "lng": 18.7883
            },
            "Siemiatycze": {
                "lat": 52.4272,
                "lng": 22.8625
            },
            "Lipno": {
                "lat": 52.85,
                "lng": 19.1667
            },
            "Trzebnica": {
                "lat": 51.305,
                "lng": 17.0614
            },
            "\u0141\u0119czyca": {
                "lat": 52.05,
                "lng": 19.2
            },
            "Wschowa": {
                "lat": 51.8,
                "lng": 16.3
            },
            "G\u0142owno": {
                "lat": 51.9642,
                "lng": 19.7117
            },
            "Tuchola": {
                "lat": 53.6,
                "lng": 17.85
            },
            "Zawodzie": {
                "lat": 50.2585,
                "lng": 19.0449
            },
            "Go\u0142dap": {
                "lat": 54.3161,
                "lng": 22.3094
            },
            "S\u0142upca": {
                "lat": 52.3,
                "lng": 17.8667
            },
            "Cz\u0142uch\u00f3w": {
                "lat": 53.65,
                "lng": 17.3667
            },
            "Barlinek": {
                "lat": 53.0,
                "lng": 15.2
            },
            "Mor\u0105g": {
                "lat": 53.9167,
                "lng": 19.9333
            },
            "W\u0105brze\u017ano": {
                "lat": 53.2833,
                "lng": 18.95
            },
            "D\u0119bno": {
                "lat": 52.7333,
                "lng": 14.7
            },
            "\u017bnin": {
                "lat": 52.85,
                "lng": 17.7
            },
            "Lubsko": {
                "lat": 51.7833,
                "lng": 14.9667
            },
            "Le\u017cajsk": {
                "lat": 50.2667,
                "lng": 22.4333
            },
            "G\u0142ucho\u0142azy": {
                "lat": 50.3131,
                "lng": 17.3742
            },
            "Kozy": {
                "lat": 49.845,
                "lng": 19.1417
            },
            "Dar\u0142owo": {
                "lat": 54.4208,
                "lng": 16.4097
            },
            "Nidzica": {
                "lat": 53.3583,
                "lng": 20.425
            },
            "Sidlice": {
                "lat": 54.3471,
                "lng": 18.6171
            },
            "Chojn\u00f3w": {
                "lat": 51.2667,
                "lng": 15.9333
            },
            "Brzeg Dolny": {
                "lat": 51.2708,
                "lng": 16.7208
            },
            "Koch\u0142owice": {
                "lat": 50.2538,
                "lng": 18.9015
            },
            "Chropacz\u00f3w": {
                "lat": 50.3131,
                "lng": 18.915
            },
            "K\u0142obuck": {
                "lat": 50.9167,
                "lng": 18.9333
            },
            "Wolsztyn": {
                "lat": 52.1167,
                "lng": 16.1167
            },
            "W\u0142odawa": {
                "lat": 51.55,
                "lng": 23.55
            },
            "Koszutka": {
                "lat": 50.2711,
                "lng": 19.0211
            },
            "Koluszki": {
                "lat": 51.75,
                "lng": 19.8
            },
            "Z\u0142ocieniec": {
                "lat": 53.5269,
                "lng": 16.0122
            },
            "S\u0119dzisz\u00f3w Ma\u0142opolski": {
                "lat": 50.0694,
                "lng": 21.7014
            },
            "Wo\u0142\u00f3w": {
                "lat": 51.3414,
                "lng": 16.6283
            },
            "B\u0142onie": {
                "lat": 52.2,
                "lng": 20.6167
            },
            "Wasilk\u00f3w": {
                "lat": 53.205,
                "lng": 23.2044
            },
            "Strzelin": {
                "lat": 50.7833,
                "lng": 17.0667
            },
            "Brzeziny": {
                "lat": 51.8,
                "lng": 19.75
            },
            "G\u0142ubczyce": {
                "lat": 50.2011,
                "lng": 17.8247
            },
            "Giera\u0142towice": {
                "lat": 50.2167,
                "lng": 18.7167
            },
            "Pas\u0142\u0119k": {
                "lat": 54.05,
                "lng": 19.6667
            },
            "Pyrzyce": {
                "lat": 53.1333,
                "lng": 14.8833
            },
            "S\u0142awno": {
                "lat": 54.3667,
                "lng": 16.6833
            },
            "Warka": {
                "lat": 51.7833,
                "lng": 21.2
            },
            "W\u0119gr\u00f3w": {
                "lat": 52.4,
                "lng": 22.0167
            },
            "G\u00f3ra Kalwaria": {
                "lat": 51.9733,
                "lng": 21.2144
            },
            "Golub-Dobrzy\u0144": {
                "lat": 53.1,
                "lng": 19.05
            },
            "Aleksandr\u00f3w Kujawski": {
                "lat": 52.8767,
                "lng": 18.6936
            },
            "Lubacz\u00f3w": {
                "lat": 50.1667,
                "lng": 23.1167
            },
            "D\u0105browa Tarnowska": {
                "lat": 50.1667,
                "lng": 20.9833
            },
            "Drawsko Pomorskie": {
                "lat": 53.5333,
                "lng": 15.8
            },
            "Mogilno": {
                "lat": 52.65,
                "lng": 17.95
            },
            "Jan\u00f3w Lubelski": {
                "lat": 50.7167,
                "lng": 22.4167
            },
            "Miech\u00f3w": {
                "lat": 50.3578,
                "lng": 20.0325
            },
            "G\u00f3ra": {
                "lat": 51.6667,
                "lng": 16.55
            },
            "Szprotawa": {
                "lat": 51.5667,
                "lng": 15.5
            },
            "Brzeszcze": {
                "lat": 50.0,
                "lng": 19.15
            },
            "Milicz": {
                "lat": 51.5333,
                "lng": 17.2833
            },
            "Komorniki": {
                "lat": 52.3361,
                "lng": 16.8069
            },
            "Koronowo": {
                "lat": 53.3167,
                "lng": 17.9333
            },
            "Szyd\u0142owiec": {
                "lat": 51.2333,
                "lng": 20.85
            },
            "Rogo\u017ano": {
                "lat": 52.7492,
                "lng": 16.9997
            },
            "Wronki": {
                "lat": 52.7,
                "lng": 16.3833
            },
            "Krosno Odrza\u0144skie": {
                "lat": 52.0333,
                "lng": 15.1
            },
            "Wis\u0142a": {
                "lat": 49.6549,
                "lng": 18.8595
            },
            "W\u0119gorzewo": {
                "lat": 54.2167,
                "lng": 21.75
            },
            "Puck": {
                "lat": 54.7,
                "lng": 18.4
            },
            "My\u015blib\u00f3rz": {
                "lat": 52.9333,
                "lng": 14.8667
            },
            "Nowa D\u0119ba": {
                "lat": 50.4167,
                "lng": 21.7667
            },
            "Lubawa": {
                "lat": 53.5,
                "lng": 19.75
            },
            "Kie\u0142cz\u00f3w": {
                "lat": 51.1406,
                "lng": 17.1783
            },
            "Parczew": {
                "lat": 51.6333,
                "lng": 22.8667
            },
            "Nowe Miasto Lubawskie": {
                "lat": 53.4256,
                "lng": 19.5878
            },
            "Wapienica": {
                "lat": 49.8083,
                "lng": 18.9839
            },
            "Ciechocinek": {
                "lat": 52.8833,
                "lng": 18.7833
            },
            "Suchanino": {
                "lat": 54.3667,
                "lng": 18.65
            },
            "Zdzieszowice": {
                "lat": 50.4192,
                "lng": 18.1236
            },
            "Czarnk\u00f3w": {
                "lat": 52.9,
                "lng": 16.5667
            },
            "Mi\u0119dzych\u00f3d": {
                "lat": 52.6,
                "lng": 15.8833
            },
            "Murowana Go\u015blina": {
                "lat": 52.5667,
                "lng": 17.0167
            },
            "G\u0142og\u00f3w Ma\u0142opolski": {
                "lat": 50.1667,
                "lng": 21.9667
            },
            "Kowary": {
                "lat": 50.7917,
                "lng": 15.8333
            },
            "Za\u0142\u0119\u017ce": {
                "lat": 50.2672,
                "lng": 18.9892
            },
            "Biskupiec": {
                "lat": 53.85,
                "lng": 20.95
            },
            "Komorowice": {
                "lat": 49.8544,
                "lng": 19.0406
            },
            "Syc\u00f3w": {
                "lat": 51.31,
                "lng": 17.7236
            },
            "Pi\u0144cz\u00f3w": {
                "lat": 50.5333,
                "lng": 20.5333
            },
            "Mikuszowice": {
                "lat": 49.7833,
                "lng": 19.0703
            },
            "Krynica": {
                "lat": 49.4117,
                "lng": 20.955
            },
            "Kolno": {
                "lat": 53.4106,
                "lng": 21.9339
            },
            "Czersk Pomorski": {
                "lat": 53.7928,
                "lng": 17.9739
            },
            "Sul\u0119cin": {
                "lat": 52.45,
                "lng": 15.1167
            },
            "Siechnice": {
                "lat": 51.0367,
                "lng": 17.1525
            },
            "Strzelce Kraje\u0144skie": {
                "lat": 52.8756,
                "lng": 15.5319
            },
            "Krzeszowice": {
                "lat": 50.1333,
                "lng": 19.6333
            },
            "Kostrzy\u0144": {
                "lat": 52.3941,
                "lng": 17.2221
            },
            "Banino": {
                "lat": 54.3933,
                "lng": 18.4083
            },
            "Drezdenko": {
                "lat": 52.8333,
                "lng": 15.8333
            },
            "\u0141obez": {
                "lat": 53.6333,
                "lng": 15.6167
            },
            "Bukowno": {
                "lat": 50.2681,
                "lng": 19.4631
            },
            "Miastko": {
                "lat": 54.0167,
                "lng": 16.9833
            },
            "Gaszowice": {
                "lat": 50.1089,
                "lng": 18.4308
            },
            "Pobiedziska": {
                "lat": 52.4833,
                "lng": 17.4833
            },
            "W\u0142oszczowa": {
                "lat": 50.8542,
                "lng": 19.9669
            },
            "Go\u0142uch\u00f3w": {
                "lat": 51.8494,
                "lng": 17.9314
            },
            "Kokoszki": {
                "lat": 54.3541,
                "lng": 18.4915
            },
            "Mo\u0144ki": {
                "lat": 53.4,
                "lng": 22.8
            },
            "Dobre Miasto": {
                "lat": 53.9875,
                "lng": 20.3958
            },
            "Kudowa-Zdr\u00f3j": {
                "lat": 50.4383,
                "lng": 16.2397
            },
            "Nowy Dw\u00f3r Gda\u0144ski": {
                "lat": 54.2167,
                "lng": 19.1167
            },
            "W\u0142adys\u0142awowo": {
                "lat": 54.8339,
                "lng": 18.3156
            },
            "Trzebiat\u00f3w": {
                "lat": 54.0572,
                "lng": 15.2786
            },
            "Karczew": {
                "lat": 52.0833,
                "lng": 21.25
            },
            "Szubin": {
                "lat": 53.0167,
                "lng": 17.75
            },
            "\u015aroda \u015al\u0105ska": {
                "lat": 51.15,
                "lng": 16.5833
            },
            "Sztum": {
                "lat": 53.9217,
                "lng": 19.0336
            },
            "Puszczykowo": {
                "lat": 52.2817,
                "lng": 16.8542
            },
            "Bystrzyca K\u0142odzka": {
                "lat": 50.3,
                "lng": 16.65
            },
            "Ostr\u00f3da": {
                "lat": 53.7,
                "lng": 19.9667
            },
            "Opalenica": {
                "lat": 52.3078,
                "lng": 16.4136
            },
            "Blachownia": {
                "lat": 50.7833,
                "lng": 18.9667
            },
            "Imielin": {
                "lat": 50.1478,
                "lng": 19.1814
            },
            "Kruszwica": {
                "lat": 52.6772,
                "lng": 18.3292
            },
            "Skwierzyna": {
                "lat": 52.6,
                "lng": 15.5
            },
            "Ryki": {
                "lat": 51.6333,
                "lng": 21.9333
            },
            "Mak\u00f3w Mazowiecki": {
                "lat": 52.8667,
                "lng": 21.1
            },
            "Wysokie Mazowieckie": {
                "lat": 52.9192,
                "lng": 22.5144
            },
            "Olesno": {
                "lat": 50.875,
                "lng": 18.4167
            },
            "Oborniki \u015al\u0105skie": {
                "lat": 51.2986,
                "lng": 16.9017
            },
            "Ko\u017cuch\u00f3w": {
                "lat": 51.75,
                "lng": 15.6
            },
            "Sucha Beskidzka": {
                "lat": 49.7403,
                "lng": 19.5886
            },
            "Czarna Bia\u0142ostocka": {
                "lat": 53.3,
                "lng": 23.2833
            },
            "S\u0119p\u00f3lno Kraje\u0144skie": {
                "lat": 53.45,
                "lng": 17.53
            },
            "Bolszewo": {
                "lat": 54.6242,
                "lng": 18.1753
            },
            "Stary S\u0105cz": {
                "lat": 49.5625,
                "lng": 20.6364
            },
            "Ustrzyki Dolne": {
                "lat": 49.4297,
                "lng": 22.5867
            },
            "Kolbuszowa": {
                "lat": 50.25,
                "lng": 21.7667
            },
            "Goworowo": {
                "lat": 52.9006,
                "lng": 21.5544
            },
            "Che\u0142mek": {
                "lat": 50.1167,
                "lng": 19.25
            },
            "Ko\u015bcielisko": {
                "lat": 49.2908,
                "lng": 19.8883
            },
            "\u017buromin": {
                "lat": 53.0667,
                "lng": 19.9
            },
            "Kamie\u0144 Pomorski": {
                "lat": 53.97,
                "lng": 14.7725
            },
            "Poniatowa": {
                "lat": 51.1928,
                "lng": 22.0647
            },
            "Wierusz\u00f3w": {
                "lat": 51.3,
                "lng": 18.15
            }
        }
    },
    "PR": {
        "name": "Puerto Rico",
        "cities": {
            "San Juan": {
                "lat": 18.3985,
                "lng": -66.061
            },
            "Aguadilla": {
                "lat": 18.4382,
                "lng": -67.1537
            },
            "Bayam\u00f3n": {
                "lat": 18.3794,
                "lng": -66.1635
            },
            "Carolina": {
                "lat": 18.4054,
                "lng": -65.9792
            },
            "Arecibo": {
                "lat": 18.4491,
                "lng": -66.7387
            },
            "Ponce": {
                "lat": 18.0127,
                "lng": -66.6212
            },
            "San Germ\u00e1n": {
                "lat": 18.0827,
                "lng": -67.046
            },
            "Mayag\u00fcez": {
                "lat": 18.2003,
                "lng": -67.1397
            },
            "Caguas": {
                "lat": 18.2319,
                "lng": -66.0388
            },
            "Guaynabo": {
                "lat": 18.3832,
                "lng": -66.1134
            },
            "Juana D\u00edaz": {
                "lat": 18.0532,
                "lng": -66.5044
            },
            "Fajardo": {
                "lat": 18.3331,
                "lng": -65.6589
            },
            "Trujillo Alto": {
                "lat": 18.3599,
                "lng": -66.0096
            },
            "Vega Baja": {
                "lat": 18.4406,
                "lng": -66.3997
            },
            "Cata\u00f1o": {
                "lat": 18.4375,
                "lng": -66.144
            },
            "Guayama": {
                "lat": 17.9743,
                "lng": -66.1104
            },
            "Humacao": {
                "lat": 18.152,
                "lng": -65.8204
            },
            "Yauco": {
                "lat": 18.0344,
                "lng": -66.8615
            },
            "Cayey": {
                "lat": 18.115,
                "lng": -66.163
            },
            "Candelaria": {
                "lat": 18.4042,
                "lng": -66.2175
            },
            "Dorado": {
                "lat": 18.4657,
                "lng": -66.2726
            },
            "Can\u00f3vanas": {
                "lat": 18.3693,
                "lng": -65.9009
            },
            "R\u00edo Grande": {
                "lat": 18.3789,
                "lng": -65.8388
            },
            "Ca\u00f1o Martin Pe\u00f1a": {
                "lat": 18.4309,
                "lng": -66.0507
            },
            "Hormigueros": {
                "lat": 18.1437,
                "lng": -67.1199
            },
            "Manat\u00ed": {
                "lat": 18.4283,
                "lng": -66.4823
            },
            "Sabana Grande": {
                "lat": 18.0832,
                "lng": -66.9645
            },
            "Cabo Rojo": {
                "lat": 18.0867,
                "lng": -67.1482
            },
            "Isabela": {
                "lat": 18.4991,
                "lng": -67.022
            },
            "P\u00e1jaros": {
                "lat": 18.361,
                "lng": -66.2184
            },
            "Corozal": {
                "lat": 18.341,
                "lng": -66.3124
            },
            "Coamo": {
                "lat": 18.0765,
                "lng": -66.3638
            },
            "Vega Alta": {
                "lat": 18.4151,
                "lng": -66.3211
            },
            "San Sebasti\u00e1n": {
                "lat": 18.3356,
                "lng": -66.9948
            },
            "Juncos": {
                "lat": 18.2256,
                "lng": -65.9161
            },
            "Aibonito": {
                "lat": 18.1398,
                "lng": -66.2659
            },
            "San Lorenzo": {
                "lat": 18.1875,
                "lng": -65.968
            }
        }
    },
    "CG": {
        "name": "Congo (Brazzaville)",
        "cities": {
            "Brazzaville": {
                "lat": -4.2694,
                "lng": 15.2711
            },
            "Pointe-Noire": {
                "lat": -4.7975,
                "lng": 11.8503
            },
            "Tchibota": {
                "lat": -4.1794,
                "lng": 11.3731
            },
            "Dolisie": {
                "lat": -4.2006,
                "lng": 12.6792
            },
            "Kayes": {
                "lat": -4.1683,
                "lng": 13.2933
            },
            "Madingou": {
                "lat": -4.1642,
                "lng": 13.5517
            },
            "Impfondo": {
                "lat": 1.6186,
                "lng": 18.0622
            },
            "Hinda": {
                "lat": -4.6167,
                "lng": 12.0406
            },
            "Sibiti": {
                "lat": -3.685,
                "lng": 13.3511
            },
            "Ou\u00e9sso": {
                "lat": 1.6106,
                "lng": 16.0514
            },
            "Lout\u00e9t\u00e9": {
                "lat": -4.2972,
                "lng": 13.8556
            },
            "Gamboma": {
                "lat": -1.8711,
                "lng": 15.8781
            },
            "Owando": {
                "lat": -0.4833,
                "lng": 15.8975
            },
            "Mossaka": {
                "lat": -1.2258,
                "lng": 16.7947
            },
            "Mindouli": {
                "lat": -4.2747,
                "lng": 14.3575
            },
            "Makoua": {
                "lat": -0.0067,
                "lng": 15.6181
            },
            "Loudima Poste": {
                "lat": -4.1122,
                "lng": 13.06
            },
            "Bouansa": {
                "lat": -4.2178,
                "lng": 13.7628
            },
            "Mossendjo": {
                "lat": -2.9453,
                "lng": 12.7156
            },
            "Djambala": {
                "lat": -2.54,
                "lng": 14.7519
            },
            "Makabana": {
                "lat": -3.4833,
                "lng": 12.6067
            },
            "Boloso": {
                "lat": 2.0333,
                "lng": 15.2
            },
            "Kinkala": {
                "lat": -4.3567,
                "lng": 14.7589
            },
            "Etoumbi": {
                "lat": 0.0208,
                "lng": 14.8933
            },
            "Boundji": {
                "lat": -1.0264,
                "lng": 15.3478
            },
            "Dongou": {
                "lat": 2.0447,
                "lng": 18.0525
            },
            "Ewo": {
                "lat": -0.8742,
                "lng": 14.8167
            },
            "K\u00e9ll\u00e9": {
                "lat": -0.0608,
                "lng": 14.4878
            },
            "S\u00e9mb\u00e9": {
                "lat": 1.6553,
                "lng": 14.5708
            },
            "Loango": {
                "lat": -4.6561,
                "lng": 11.8114
            }
        }
    },
    "OM": {
        "name": "Oman",
        "cities": {
            "Masqa\u0163": {
                "lat": 23.5889,
                "lng": 58.4083
            },
            "Muscat": {
                "lat": 23.6139,
                "lng": 58.5922
            },
            "Bawshar": {
                "lat": 23.5333,
                "lng": 58.3833
            },
            "\u015eal\u0101lah": {
                "lat": 17.0197,
                "lng": 54.0897
            },
            "As S\u012bb": {
                "lat": 23.6703,
                "lng": 58.1891
            },
            "Ma\u0163ra\u1e29": {
                "lat": 23.6167,
                "lng": 58.5667
            },
            "As Suwayq": {
                "lat": 23.8494,
                "lng": 57.4386
            },
            "A\u015f \u015euway\u1e29irah as S\u0101\u1e29il": {
                "lat": 24.362,
                "lng": 56.7344
            },
            "Ar Rust\u0101q": {
                "lat": 23.3908,
                "lng": 57.4244
            },
            "Al Mu\u1e11ayb\u012b": {
                "lat": 22.5667,
                "lng": 58.1167
            },
            "\u2018Ibr\u012b": {
                "lat": 23.2325,
                "lng": 56.4973
            },
            "Bahl\u0101\u2019": {
                "lat": 22.968,
                "lng": 57.298
            },
            "Nizw\u00e1": {
                "lat": 22.9333,
                "lng": 57.5333
            },
            "Sam\u0101\u2019il": {
                "lat": 23.3,
                "lng": 57.9833
            },
            "Al \u2018\u0100mir\u0101t": {
                "lat": 23.5242,
                "lng": 58.4989
            },
            "Al Buraym\u012b": {
                "lat": 24.2592,
                "lng": 55.7839
            },
            "Qurayy\u0101t": {
                "lat": 23.32,
                "lng": 58.908
            },
            "Al Madrah Sam\u0101\u2019il": {
                "lat": 23.3103,
                "lng": 57.9455
            },
            "Shin\u0101\u015f": {
                "lat": 24.75,
                "lng": 56.4667
            },
            "Izk\u012b": {
                "lat": 22.9339,
                "lng": 57.775
            },
            "Ibr\u0101\u2019": {
                "lat": 22.6833,
                "lng": 58.55
            },
            "Nakhal": {
                "lat": 23.395,
                "lng": 57.829
            },
            "\u1e10ank": {
                "lat": 23.55,
                "lng": 56.25
            },
            "\u0162\u0101qah": {
                "lat": 17.0372,
                "lng": 54.4036
            },
            "Kha\u015fab": {
                "lat": 26.1833,
                "lng": 56.25
            },
            "Fanj\u0101\u2019": {
                "lat": 23.4675,
                "lng": 58.1061
            },
            "Al Mazy\u016bnah": {
                "lat": 17.8486,
                "lng": 52.62
            },
            "Mirb\u0101\u0163": {
                "lat": 16.9886,
                "lng": 54.6922
            },
            "\u015e\u016br": {
                "lat": 22.5667,
                "lng": 59.5289
            },
            "Haym\u0101\u2019": {
                "lat": 19.9569,
                "lng": 56.2758
            },
            "\u015eu\u1e29\u0101r": {
                "lat": 24.342,
                "lng": 56.7299
            }
        }
    },
    "UY": {
        "name": "Uruguay",
        "cities": {
            "Montevideo": {
                "lat": -34.8836,
                "lng": -56.1819
            },
            "Maldonado": {
                "lat": -34.9,
                "lng": -54.95
            },
            "Salto": {
                "lat": -31.3833,
                "lng": -57.95
            },
            "Ciudad de la Costa": {
                "lat": -34.8167,
                "lng": -55.95
            },
            "Paysand\u00fa": {
                "lat": -32.3214,
                "lng": -58.0756
            },
            "Las Piedras": {
                "lat": -34.7167,
                "lng": -56.2167
            },
            "Rivera": {
                "lat": -30.9025,
                "lng": -55.5506
            },
            "Tacuaremb\u00f3": {
                "lat": -31.7333,
                "lng": -55.9833
            },
            "Melo": {
                "lat": -32.3667,
                "lng": -54.1833
            },
            "Mercedes": {
                "lat": -33.25,
                "lng": -58.0333
            },
            "Artigas": {
                "lat": -30.4667,
                "lng": -56.4667
            },
            "Minas": {
                "lat": -34.3667,
                "lng": -55.2333
            },
            "San Jos\u00e9": {
                "lat": -34.3333,
                "lng": -56.7167
            },
            "Durazno": {
                "lat": -33.3667,
                "lng": -56.5167
            },
            "Florida": {
                "lat": -34.1,
                "lng": -56.2167
            },
            "Barros Blancos": {
                "lat": -34.7542,
                "lng": -56.0042
            },
            "Ciudad del Plata": {
                "lat": -34.7667,
                "lng": -56.3806
            },
            "San Carlos": {
                "lat": -34.8,
                "lng": -54.9167
            },
            "Colonia del Sacramento": {
                "lat": -34.4714,
                "lng": -57.8442
            },
            "Pando": {
                "lat": -34.7167,
                "lng": -55.9583
            },
            "Treinta y Tres": {
                "lat": -33.2333,
                "lng": -54.3833
            },
            "Rocha": {
                "lat": -34.4833,
                "lng": -54.35
            },
            "Fray Bentos": {
                "lat": -33.1333,
                "lng": -58.3
            },
            "Trinidad": {
                "lat": -33.5333,
                "lng": -56.8833
            },
            "El Pinar": {
                "lat": -34.7972,
                "lng": -55.9097
            },
            "La Paz": {
                "lat": -34.7617,
                "lng": -56.2233
            },
            "Canelones": {
                "lat": -34.5167,
                "lng": -56.2833
            },
            "Bella Uni\u00f3n": {
                "lat": -30.25,
                "lng": -57.5833
            },
            "Carmelo": {
                "lat": -34.0,
                "lng": -58.2833
            },
            "Young": {
                "lat": -32.7,
                "lng": -57.6333
            },
            "Santa Luc\u00eda": {
                "lat": -34.4525,
                "lng": -56.3964
            },
            "Progreso": {
                "lat": -34.665,
                "lng": -56.2194
            },
            "Paso de Carrasco": {
                "lat": -34.8714,
                "lng": -56.0272
            },
            "Dolores": {
                "lat": -33.5333,
                "lng": -58.2167
            },
            "R\u00edo Branco": {
                "lat": -32.5972,
                "lng": -53.3833
            },
            "Tala": {
                "lat": -34.35,
                "lng": -55.7667
            },
            "Paso de los Toros": {
                "lat": -32.8112,
                "lng": -56.5086
            },
            "Juan L. Lacaze": {
                "lat": -34.4311,
                "lng": -57.4483
            },
            "Libertad": {
                "lat": -34.6333,
                "lng": -56.6192
            },
            "Nueva Helvecia": {
                "lat": -34.2833,
                "lng": -57.2
            },
            "Nueva Palmira": {
                "lat": -33.8833,
                "lng": -58.4167
            },
            "Chuy": {
                "lat": -33.6964,
                "lng": -53.46
            },
            "Colonia Nicolich": {
                "lat": -34.8167,
                "lng": -56.0167
            },
            "Rosario": {
                "lat": -34.3139,
                "lng": -57.3525
            },
            "Punta del Este": {
                "lat": -34.9667,
                "lng": -54.95
            },
            "Piri\u00e1polis": {
                "lat": -34.9,
                "lng": -55.2833
            },
            "Salinas": {
                "lat": -34.7833,
                "lng": -55.8333
            },
            "Nuevo Paysand\u00fa": {
                "lat": -32.2667,
                "lng": -58.0667
            },
            "Lascano": {
                "lat": -33.6739,
                "lng": -54.2075
            },
            "Castillos": {
                "lat": -34.1989,
                "lng": -53.8575
            },
            "Tranqueras": {
                "lat": -31.1833,
                "lng": -55.7667
            },
            "Vergara": {
                "lat": -32.95,
                "lng": -53.9333
            },
            "La Paloma": {
                "lat": -34.65,
                "lng": -54.1667
            },
            "Baltasar Brum": {
                "lat": -30.7167,
                "lng": -57.3333
            },
            "Aigu\u00e1": {
                "lat": -34.2,
                "lng": -54.75
            },
            "Jos\u00e9 Batlle y Ord\u00f3\u00f1ez": {
                "lat": -33.4667,
                "lng": -55.15
            }
        }
    },
    "HN": {
        "name": "Honduras",
        "cities": {
            "Tegucigalpa": {
                "lat": 14.1058,
                "lng": -87.2047
            },
            "Comayag\u00fcela": {
                "lat": 14.098,
                "lng": -87.21
            },
            "San Pedro Sula": {
                "lat": 15.5,
                "lng": -88.0333
            },
            "Choloma": {
                "lat": 15.6333,
                "lng": -88.0
            },
            "La Ceiba": {
                "lat": 15.7792,
                "lng": -86.7931
            },
            "El Progreso": {
                "lat": 15.4,
                "lng": -87.8
            },
            "Villanueva": {
                "lat": 15.3167,
                "lng": -88.0167
            },
            "Ciudad Choluteca": {
                "lat": 13.3028,
                "lng": -87.1833
            },
            "Comayagua": {
                "lat": 14.4522,
                "lng": -87.6383
            },
            "Juticalpa": {
                "lat": 14.6664,
                "lng": -86.2186
            },
            "Puerto Cort\u00e9s": {
                "lat": 15.8833,
                "lng": -87.95
            },
            "Catacamas": {
                "lat": 14.8,
                "lng": -85.9
            },
            "Olanchito": {
                "lat": 15.4833,
                "lng": -86.5833
            },
            "Tela": {
                "lat": 15.7833,
                "lng": -87.4667
            },
            "Siguatepeque": {
                "lat": 14.6,
                "lng": -87.8333
            },
            "Danl\u00ed": {
                "lat": 14.05,
                "lng": -86.5833
            },
            "Yoro": {
                "lat": 15.1333,
                "lng": -87.1333
            },
            "La Lima": {
                "lat": 15.433,
                "lng": -87.917
            },
            "Tocoa": {
                "lat": 15.6833,
                "lng": -86.0
            },
            "Santa Rosa de Cop\u00e1n": {
                "lat": 14.7667,
                "lng": -88.7833
            },
            "Trujillo": {
                "lat": 15.9167,
                "lng": -86.0
            },
            "Nacaome": {
                "lat": 13.5167,
                "lng": -87.4833
            },
            "Intibuc\u00e1": {
                "lat": 14.3121,
                "lng": -88.1781
            },
            "San Manuel": {
                "lat": 15.3333,
                "lng": -87.9167
            },
            "Gracias": {
                "lat": 14.5889,
                "lng": -88.5819
            },
            "Puerto Lempira": {
                "lat": 15.2667,
                "lng": -83.7667
            },
            "La Paz": {
                "lat": 14.317,
                "lng": -87.683
            },
            "Sonaguera": {
                "lat": 15.6333,
                "lng": -86.3333
            },
            "Santa B\u00e1rbara": {
                "lat": 14.9167,
                "lng": -88.2333
            },
            "Cop\u00e1n": {
                "lat": 14.8375,
                "lng": -89.1425
            },
            "San Lorenzo": {
                "lat": 13.3667,
                "lng": -87.2667
            },
            "Talanga": {
                "lat": 14.4,
                "lng": -87.0833
            },
            "Santa Rita": {
                "lat": 15.2,
                "lng": -87.8833
            },
            "Bonito Oriental": {
                "lat": 15.7333,
                "lng": -85.7333
            },
            "Guaimaca": {
                "lat": 14.5333,
                "lng": -86.8167
            },
            "Potrerillos": {
                "lat": 15.2167,
                "lng": -87.95
            },
            "Azacualpa": {
                "lat": 15.3441,
                "lng": -88.5515
            },
            "Cedros": {
                "lat": 14.6,
                "lng": -87.1167
            },
            "Iriona": {
                "lat": 15.9333,
                "lng": -85.1833
            },
            "San Marcos": {
                "lat": 14.4,
                "lng": -88.95
            },
            "El Para\u00edso": {
                "lat": 15.0167,
                "lng": -88.9833
            },
            "Campamento": {
                "lat": 14.55,
                "lng": -86.65
            },
            "Cofrad\u00eda": {
                "lat": 15.4168,
                "lng": -88.1603
            },
            "Sulaco": {
                "lat": 14.9167,
                "lng": -87.2667
            },
            "Santa Ana": {
                "lat": 14.0667,
                "lng": -87.95
            },
            "Yorito": {
                "lat": 15.06,
                "lng": -87.29
            },
            "Guajiquiro": {
                "lat": 14.1167,
                "lng": -87.8333
            },
            "San Francisco": {
                "lat": 14.1167,
                "lng": -88.3667
            },
            "Yuscar\u00e1n": {
                "lat": 13.933,
                "lng": -86.85
            },
            "La Entrada": {
                "lat": 15.0611,
                "lng": -88.7519
            },
            "San Rafael": {
                "lat": 14.7269,
                "lng": -88.4194
            },
            "San Juan": {
                "lat": 14.4,
                "lng": -88.4167
            },
            "Brus Laguna": {
                "lat": 15.75,
                "lng": -84.4833
            },
            "Santa Elena": {
                "lat": 14.0833,
                "lng": -88.1167
            },
            "La Esperanza": {
                "lat": 14.3066,
                "lng": -88.1787
            },
            "Gualcince": {
                "lat": 14.1167,
                "lng": -88.55
            },
            "La Jigua": {
                "lat": 15.04,
                "lng": -88.8
            },
            "Marale": {
                "lat": 14.9167,
                "lng": -87.035
            },
            "San Ignacio": {
                "lat": 14.6667,
                "lng": -87.0333
            },
            "Nueva Ocotepeque": {
                "lat": 14.4333,
                "lng": -89.1833
            },
            "Roat\u00e1n": {
                "lat": 16.323,
                "lng": -86.537
            }
        }
    },
    "UG": {
        "name": "Uganda",
        "cities": {
            "Kampala": {
                "lat": 0.3136,
                "lng": 32.5811
            },
            "Mbale": {
                "lat": 1.0806,
                "lng": 34.175
            },
            "Nansana": {
                "lat": 0.3639,
                "lng": 32.5286
            },
            "Kasangati": {
                "lat": 0.4378,
                "lng": 32.6025
            },
            "Mbarara": {
                "lat": -0.6133,
                "lng": 30.6583
            },
            "Njeru": {
                "lat": 0.4311,
                "lng": 33.1478
            },
            "Mukono": {
                "lat": 0.3533,
                "lng": 32.7553
            },
            "Gulu": {
                "lat": 2.7817,
                "lng": 32.2992
            },
            "Hoima": {
                "lat": 1.4319,
                "lng": 31.3525
            },
            "Lira": {
                "lat": 2.2472,
                "lng": 32.9
            },
            "Kasese": {
                "lat": 0.1867,
                "lng": 30.0881
            },
            "Lugazi": {
                "lat": 0.3833,
                "lng": 32.9242
            },
            "Masindi": {
                "lat": 1.6836,
                "lng": 31.7222
            },
            "Mityana": {
                "lat": 0.4006,
                "lng": 32.0422
            },
            "Masaka": {
                "lat": -0.3411,
                "lng": 31.7361
            },
            "Mubende": {
                "lat": 0.5575,
                "lng": 31.395
            },
            "Wakiso": {
                "lat": 0.3981,
                "lng": 32.4781
            },
            "Jinja": {
                "lat": 0.4233,
                "lng": 33.2039
            },
            "Entebbe": {
                "lat": 0.05,
                "lng": 32.46
            },
            "Kireka": {
                "lat": 0.3467,
                "lng": 32.5
            },
            "Kamuli": {
                "lat": 0.945,
                "lng": 33.125
            },
            "Apac": {
                "lat": 1.985,
                "lng": 32.535
            },
            "Busia": {
                "lat": 0.4669,
                "lng": 34.09
            },
            "Arua": {
                "lat": 3.0353,
                "lng": 30.9108
            },
            "Soroti": {
                "lat": 1.715,
                "lng": 33.6111
            },
            "Fort Portal": {
                "lat": 0.6544,
                "lng": 30.2744
            },
            "Mpondwe": {
                "lat": 0.04,
                "lng": 29.725
            },
            "Nyenga": {
                "lat": 0.38,
                "lng": 33.15
            },
            "Iganga": {
                "lat": 0.615,
                "lng": 33.485
            },
            "Kabale": {
                "lat": -1.25,
                "lng": 29.99
            },
            "Kyaliwajjala": {
                "lat": 0.38,
                "lng": 32.6467
            },
            "Mpigi": {
                "lat": 0.23,
                "lng": 32.33
            },
            "Luwero": {
                "lat": 0.8331,
                "lng": 32.4994
            },
            "Yumbe": {
                "lat": 3.465,
                "lng": 31.245
            },
            "Kitgum": {
                "lat": 3.2889,
                "lng": 32.8778
            },
            "Bushenyi": {
                "lat": -0.5417,
                "lng": 30.1878
            },
            "Pallisa": {
                "lat": 1.1675,
                "lng": 33.71
            },
            "Tororo": {
                "lat": 0.6928,
                "lng": 34.1811
            },
            "Nebbi": {
                "lat": 2.4792,
                "lng": 31.09
            },
            "Ishaka": {
                "lat": -0.545,
                "lng": 30.1383
            },
            "Koboko": {
                "lat": 3.41,
                "lng": 30.96
            },
            "Rukungiri": {
                "lat": -0.79,
                "lng": 29.925
            },
            "Adjumani": {
                "lat": 3.3772,
                "lng": 31.7906
            },
            "Bugiri": {
                "lat": 0.5694,
                "lng": 33.7486
            },
            "Isingiro": {
                "lat": -0.795,
                "lng": 30.8153
            },
            "Paidha": {
                "lat": 2.4167,
                "lng": 30.9875
            },
            "Bugembe": {
                "lat": 0.4675,
                "lng": 33.2414
            },
            "Kakira": {
                "lat": 0.5036,
                "lng": 33.2825
            },
            "Kiryandongo": {
                "lat": 1.9525,
                "lng": 32.1389
            },
            "Kalisizo": {
                "lat": -0.535,
                "lng": 31.6225
            },
            "Ibanda": {
                "lat": -0.1347,
                "lng": 30.495
            },
            "Wobulenzi": {
                "lat": 0.72,
                "lng": 32.53
            },
            "Kayunga": {
                "lat": 0.7033,
                "lng": 32.9036
            },
            "Kyegegwa": {
                "lat": 0.4803,
                "lng": 31.0547
            },
            "Budaka": {
                "lat": 1.0167,
                "lng": 33.945
            },
            "Pakwach": {
                "lat": 2.4619,
                "lng": 31.4983
            },
            "Kakiri": {
                "lat": 0.42,
                "lng": 32.39
            },
            "Bombo": {
                "lat": 0.5778,
                "lng": 32.545
            },
            "Kamonkoli": {
                "lat": 1.075,
                "lng": 34.0956
            },
            "Kibuku": {
                "lat": 1.0375,
                "lng": 33.84
            },
            "Abim": {
                "lat": 2.7019,
                "lng": 33.66
            },
            "Lukaya": {
                "lat": -0.1508,
                "lng": 31.8744
            },
            "Buwenge": {
                "lat": 0.6503,
                "lng": 33.1703
            },
            "Butaleja": {
                "lat": 0.925,
                "lng": 33.945
            },
            "Kyenjojo": {
                "lat": 0.61,
                "lng": 30.6442
            },
            "Buyende": {
                "lat": 1.1475,
                "lng": 33.1611
            },
            "Kotido": {
                "lat": 3.0061,
                "lng": 34.1125
            },
            "Kagadi": {
                "lat": 0.9411,
                "lng": 30.8108
            },
            "Moyo": {
                "lat": 3.6504,
                "lng": 31.72
            },
            "Kiboga": {
                "lat": 0.92,
                "lng": 31.76
            },
            "Bundibugyo": {
                "lat": 0.7125,
                "lng": 30.06
            },
            "Namayumba": {
                "lat": 0.5281,
                "lng": 32.2506
            },
            "Kigumba": {
                "lat": 1.815,
                "lng": 32.01
            },
            "Makulubita": {
                "lat": 0.5122,
                "lng": 32.3664
            },
            "Malaba": {
                "lat": 0.6444,
                "lng": 34.2606
            },
            "Kihihi": {
                "lat": -0.7489,
                "lng": 29.7003
            },
            "Dokolo": {
                "lat": 1.9186,
                "lng": 33.17
            },
            "Kamwenge": {
                "lat": 0.1861,
                "lng": 30.4539
            },
            "Bukungu": {
                "lat": 1.4361,
                "lng": 32.8686
            },
            "Sironko": {
                "lat": 1.2306,
                "lng": 34.2481
            },
            "Buikwe": {
                "lat": 0.3442,
                "lng": 33.03
            },
            "Kikube": {
                "lat": 1.3328,
                "lng": 31.2075
            },
            "Ngora": {
                "lat": 1.4575,
                "lng": 33.7764
            },
            "Kisoro": {
                "lat": -1.285,
                "lng": 29.685
            },
            "Namayingo": {
                "lat": 0.2398,
                "lng": 33.8849
            },
            "Mayuge": {
                "lat": 0.4578,
                "lng": 33.48
            },
            "Busolwe": {
                "lat": 0.8492,
                "lng": 33.9269
            },
            "Kaliro": {
                "lat": 0.8944,
                "lng": 33.4994
            },
            "Katosi": {
                "lat": 0.1528,
                "lng": 32.8014
            },
            "Ntungamo": {
                "lat": -0.8819,
                "lng": 30.2653
            },
            "Moroto": {
                "lat": 2.53,
                "lng": 34.67
            },
            "Lyantonde": {
                "lat": -0.4069,
                "lng": 31.1575
            },
            "Manafwa": {
                "lat": 0.9564,
                "lng": 34.2844
            },
            "Buwama": {
                "lat": 0.0633,
                "lng": 32.1064
            },
            "Kimwanyi": {
                "lat": 0.4533,
                "lng": 32.6347
            },
            "Kyazanga": {
                "lat": -0.3864,
                "lng": 31.3186
            },
            "Lwengo": {
                "lat": -0.3911,
                "lng": 31.4606
            },
            "Gombe": {
                "lat": 0.1811,
                "lng": 32.1142
            },
            "Kanungu": {
                "lat": -0.8969,
                "lng": 29.7756
            },
            "Kalongo": {
                "lat": 3.04,
                "lng": 33.3711
            },
            "Matuga": {
                "lat": 0.4603,
                "lng": 32.5286
            },
            "Zombo": {
                "lat": 2.5131,
                "lng": 30.9081
            },
            "Mutukula": {
                "lat": -0.9997,
                "lng": 31.4167
            },
            "Hima": {
                "lat": 0.2906,
                "lng": 30.1775
            },
            "Lumino": {
                "lat": 0.325,
                "lng": 33.9958
            },
            "Oyam": {
                "lat": 2.235,
                "lng": 32.385
            },
            "Busembatia": {
                "lat": 0.775,
                "lng": 33.625
            },
            "Nagongera": {
                "lat": 0.77,
                "lng": 34.0261
            },
            "Bukedea": {
                "lat": 1.3475,
                "lng": 34.0444
            },
            "Kaabong": {
                "lat": 3.52,
                "lng": 34.12
            },
            "Kumi": {
                "lat": 1.4608,
                "lng": 33.9361
            },
            "Kibiito": {
                "lat": 0.4775,
                "lng": 30.1925
            },
            "Amolatar": {
                "lat": 1.6517,
                "lng": 32.825
            },
            "Kapchorwa": {
                "lat": 1.4,
                "lng": 34.45
            },
            "Kyotera": {
                "lat": -0.6317,
                "lng": 31.5433
            },
            "Serere": {
                "lat": 1.5,
                "lng": 33.55
            },
            "Kanoni": {
                "lat": 0.1728,
                "lng": 31.9058
            },
            "Karagwe": {
                "lat": 0.6769,
                "lng": 30.1967
            },
            "Nakasongola": {
                "lat": 1.315,
                "lng": 32.465
            },
            "Bugongi": {
                "lat": -0.6356,
                "lng": 30.2528
            },
            "Majanji": {
                "lat": 0.2408,
                "lng": 33.9914
            },
            "Kiwoko": {
                "lat": 0.8442,
                "lng": 32.3614
            },
            "Semuto": {
                "lat": 0.62,
                "lng": 32.3278
            },
            "Amuru": {
                "lat": 2.8186,
                "lng": 31.8642
            },
            "Aduku": {
                "lat": 2.0194,
                "lng": 32.72
            },
            "Lwakhakha": {
                "lat": 0.7967,
                "lng": 34.3781
            },
            "Masindi Port": {
                "lat": 1.6983,
                "lng": 32.0778
            },
            "Kazo": {
                "lat": -0.0528,
                "lng": 30.7569
            },
            "Nkokonjeru": {
                "lat": 0.2394,
                "lng": 32.9231
            },
            "Kadama": {
                "lat": 1.0167,
                "lng": 33.8803
            },
            "Bukomansimbi": {
                "lat": -0.1667,
                "lng": 31.6186
            },
            "Bulisa": {
                "lat": 2.1217,
                "lng": 31.4156
            },
            "Katakwi": {
                "lat": 1.915,
                "lng": 33.955
            },
            "Amuria": {
                "lat": 2.03,
                "lng": 33.6431
            },
            "Bududa": {
                "lat": 1.01,
                "lng": 34.3317
            },
            "Nakaseke": {
                "lat": 0.73,
                "lng": 32.415
            },
            "Rakai": {
                "lat": -0.71,
                "lng": 31.405
            },
            "Rubirizi": {
                "lat": -0.2661,
                "lng": 30.1069
            },
            "Alebtong": {
                "lat": 2.25,
                "lng": 33.315
            },
            "Sembabule": {
                "lat": -0.08,
                "lng": 31.46
            },
            "Kibale": {
                "lat": 0.7911,
                "lng": 31.075
            },
            "Kiruhura": {
                "lat": -0.21,
                "lng": 30.83
            },
            "Mitoma": {
                "lat": -0.615,
                "lng": 30.045
            },
            "Napak": {
                "lat": 2.1156,
                "lng": 34.2267
            },
            "Kalangala": {
                "lat": -0.3214,
                "lng": 32.2919
            },
            "Nwoya": {
                "lat": 2.635,
                "lng": 32.0
            },
            "Butalangu": {
                "lat": 0.8228,
                "lng": 32.2428
            },
            "Kaberamaido": {
                "lat": 1.7667,
                "lng": 33.1522
            },
            "Katwe": {
                "lat": -0.1296,
                "lng": 29.92
            },
            "Amudat": {
                "lat": 1.9522,
                "lng": 34.9444
            },
            "Namutumba": {
                "lat": 0.8363,
                "lng": 33.6858
            },
            "Maracha": {
                "lat": 3.2883,
                "lng": 30.9408
            },
            "Luuka Town": {
                "lat": 0.7642,
                "lng": 33.3319
            },
            "Kasanda": {
                "lat": 0.5467,
                "lng": 31.8197
            },
            "Kinoni": {
                "lat": -0.6583,
                "lng": 30.4581
            },
            "Busesa": {
                "lat": 0.6263,
                "lng": 33.6003
            },
            "Bulambuli": {
                "lat": 1.16,
                "lng": 34.3967
            },
            "Ntoroko": {
                "lat": 1.05,
                "lng": 30.535
            },
            "Otuke": {
                "lat": 2.4442,
                "lng": 33.5028
            },
            "Bupoto": {
                "lat": 0.9061,
                "lng": 34.3578
            },
            "Agago": {
                "lat": 2.9847,
                "lng": 33.3306
            },
            "Kitamilo": {
                "lat": 0.2367,
                "lng": 33.27
            },
            "Nsiika": {
                "lat": -0.2958,
                "lng": 30.3433
            },
            "Kalaki": {
                "lat": 1.816,
                "lng": 33.337
            },
            "Kasaali": {
                "lat": -0.6167,
                "lng": 31.55
            },
            "Nakapiripirit": {
                "lat": 1.9167,
                "lng": 34.7833
            },
            "Pader": {
                "lat": 3.05,
                "lng": 33.2167
            },
            "Kakumiro": {
                "lat": 0.7811,
                "lng": 31.3231
            },
            "Mparo": {
                "lat": -1.1844,
                "lng": 30.0544
            },
            "Lamwo": {
                "lat": 3.53,
                "lng": 32.8
            },
            "Kyankwanzi": {
                "lat": 1.2,
                "lng": 31.8
            },
            "Ntara": {
                "lat": 0.0047,
                "lng": 30.3661
            },
            "Bukwo": {
                "lat": 1.2928,
                "lng": 34.7531
            },
            "Butebo": {
                "lat": 1.1944,
                "lng": 33.9222
            },
            "Binyin": {
                "lat": 1.4175,
                "lng": 34.5344
            },
            "Palenga": {
                "lat": 2.5764,
                "lng": 32.3544
            },
            "Kibingo": {
                "lat": -0.57,
                "lng": 30.415
            },
            "Kole": {
                "lat": 2.4286,
                "lng": 32.8011
            },
            "Nabilatuk": {
                "lat": 2.0525,
                "lng": 34.5734
            },
            "Rubanda": {
                "lat": -1.1864,
                "lng": 29.8433
            },
            "Kalungu": {
                "lat": -0.1681,
                "lng": 31.76
            }
        }
    },
    "GN": {
        "name": "Guinea",
        "cities": {
            "Conakry": {
                "lat": 9.5092,
                "lng": -13.7122
            },
            "Mamou": {
                "lat": 10.3833,
                "lng": -12.0833
            },
            "Gu\u00e9ck\u00e9dou": {
                "lat": 8.5667,
                "lng": -10.1333
            },
            "Kankan": {
                "lat": 10.3833,
                "lng": -9.3
            },
            "N\u2019Z\u00e9r\u00e9kor\u00e9": {
                "lat": 7.75,
                "lng": -8.8167
            },
            "Siguiri": {
                "lat": 11.4167,
                "lng": -9.1667
            },
            "Kindia": {
                "lat": 10.0497,
                "lng": -12.8542
            },
            "Bok\u00e9": {
                "lat": 10.94,
                "lng": -14.3
            },
            "Lab\u00e9": {
                "lat": 11.3167,
                "lng": -12.2833
            },
            "Faranah": {
                "lat": 10.0333,
                "lng": -10.7333
            },
            "Kamsar": {
                "lat": 10.65,
                "lng": -14.6167
            },
            "Kissidougou": {
                "lat": 9.1905,
                "lng": -10.12
            },
            "Macenta": {
                "lat": 8.5504,
                "lng": -9.48
            },
            "Dabola": {
                "lat": 10.75,
                "lng": -11.1167
            },
            "K\u00e9ni\u00e9ran": {
                "lat": 11.1167,
                "lng": -8.9333
            },
            "T\u00e9lim\u00e9l\u00e9": {
                "lat": 10.905,
                "lng": -13.043
            },
            "Yomou": {
                "lat": 7.566,
                "lng": -9.2533
            },
            "Tougu\u00e9": {
                "lat": 11.44,
                "lng": -11.67
            },
            "Fria": {
                "lat": 10.3804,
                "lng": -13.55
            },
            "Pita": {
                "lat": 11.08,
                "lng": -12.401
            },
            "Doura": {
                "lat": 12.0167,
                "lng": -9.0167
            },
            "K\u00e9rouan\u00e9": {
                "lat": 9.2704,
                "lng": -9.02
            },
            "Kouroussa": {
                "lat": 10.653,
                "lng": -9.892
            },
            "Koundara": {
                "lat": 12.48,
                "lng": -13.296
            },
            "Beyla": {
                "lat": 8.6833,
                "lng": -8.6333
            },
            "For\u00e9cariah": {
                "lat": 9.43,
                "lng": -13.098
            },
            "Sansal\u00e9": {
                "lat": 11.1167,
                "lng": -14.85
            },
            "Gaoual": {
                "lat": 11.754,
                "lng": -13.213
            },
            "Dalaba": {
                "lat": 10.656,
                "lng": -12.272
            },
            "Dinguiraye": {
                "lat": 11.299,
                "lng": -10.726
            },
            "Mali": {
                "lat": 12.084,
                "lng": -12.301
            },
            "Boffa": {
                "lat": 10.185,
                "lng": -14.043
            }
        }
    },
    "BG": {
        "name": "Bulgaria",
        "cities": {
            "Sofia": {
                "lat": 42.7,
                "lng": 23.33
            },
            "Varna": {
                "lat": 43.2167,
                "lng": 27.9167
            },
            "Plovdiv": {
                "lat": 42.15,
                "lng": 24.75
            },
            "Burgas": {
                "lat": 42.503,
                "lng": 27.4702
            },
            "Ruse": {
                "lat": 43.8231,
                "lng": 25.9539
            },
            "Stara Zagora": {
                "lat": 42.4256,
                "lng": 25.6344
            },
            "Pleven": {
                "lat": 43.4078,
                "lng": 24.6203
            },
            "Sliven": {
                "lat": 42.6833,
                "lng": 26.3333
            },
            "Pernik": {
                "lat": 42.6,
                "lng": 23.0333
            },
            "Pazardzhik": {
                "lat": 42.2,
                "lng": 24.3333
            },
            "Dobrich": {
                "lat": 43.5667,
                "lng": 27.8333
            },
            "Shumen": {
                "lat": 43.2833,
                "lng": 26.9333
            },
            "Haskovo": {
                "lat": 41.9333,
                "lng": 25.5667
            },
            "Veliko Tarnovo": {
                "lat": 43.0778,
                "lng": 25.6167
            },
            "Blagoevgrad": {
                "lat": 42.0119,
                "lng": 23.0897
            },
            "Yambol": {
                "lat": 42.4833,
                "lng": 26.5
            },
            "Kazanlak": {
                "lat": 42.6167,
                "lng": 25.4
            },
            "Asenovgrad": {
                "lat": 42.0167,
                "lng": 24.8667
            },
            "Kyustendil": {
                "lat": 42.2833,
                "lng": 22.6833
            },
            "Vratsa": {
                "lat": 43.2,
                "lng": 23.55
            },
            "Gabrovo": {
                "lat": 42.8667,
                "lng": 25.3333
            },
            "Targovishte": {
                "lat": 43.25,
                "lng": 26.5833
            },
            "Kardzhali": {
                "lat": 41.65,
                "lng": 25.3667
            },
            "Vidin": {
                "lat": 44.0,
                "lng": 22.8667
            },
            "Razgrad": {
                "lat": 43.5333,
                "lng": 26.5167
            },
            "Svishtov": {
                "lat": 43.6167,
                "lng": 25.35
            },
            "Silistra": {
                "lat": 44.1172,
                "lng": 27.2606
            },
            "Dupnitsa": {
                "lat": 42.2645,
                "lng": 23.1164
            },
            "Montana": {
                "lat": 43.4075,
                "lng": 23.2217
            },
            "Lovech": {
                "lat": 43.1347,
                "lng": 24.7172
            },
            "Sandanski": {
                "lat": 41.5667,
                "lng": 23.2833
            },
            "Samokov": {
                "lat": 42.3333,
                "lng": 23.55
            },
            "Dimitrovgrad": {
                "lat": 42.05,
                "lng": 25.6
            },
            "Smolyan": {
                "lat": 41.5833,
                "lng": 24.7
            },
            "Gorna Oryahovitsa": {
                "lat": 43.1333,
                "lng": 25.7
            },
            "Petrich": {
                "lat": 41.3953,
                "lng": 23.2069
            },
            "Gotse Delchev": {
                "lat": 41.5667,
                "lng": 23.7333
            },
            "Troyan": {
                "lat": 42.8833,
                "lng": 24.7167
            },
            "Aytos": {
                "lat": 42.7,
                "lng": 27.25
            },
            "Rakovski": {
                "lat": 42.3,
                "lng": 24.9667
            },
            "Omurtag": {
                "lat": 43.1,
                "lng": 26.417
            },
            "Velingrad": {
                "lat": 42.0167,
                "lng": 24.0
            },
            "Popovo": {
                "lat": 43.35,
                "lng": 26.2333
            },
            "Byala Slatina": {
                "lat": 43.4667,
                "lng": 23.9333
            },
            "Isperih": {
                "lat": 43.7167,
                "lng": 26.8333
            },
            "Karnobat": {
                "lat": 42.6333,
                "lng": 26.9833
            },
            "Sevlievo": {
                "lat": 43.0256,
                "lng": 25.1133
            },
            "Nova Zagora": {
                "lat": 42.4911,
                "lng": 26.0144
            },
            "Lom": {
                "lat": 43.8256,
                "lng": 23.2375
            },
            "Karlovo": {
                "lat": 42.6436,
                "lng": 24.8072
            },
            "Mezdra": {
                "lat": 43.15,
                "lng": 23.7
            },
            "Panagyurishte": {
                "lat": 42.5,
                "lng": 24.1833
            },
            "Teteven": {
                "lat": 42.9167,
                "lng": 24.2667
            },
            "Botevgrad": {
                "lat": 42.9083,
                "lng": 23.7919
            },
            "Peshtera": {
                "lat": 42.0333,
                "lng": 24.3
            },
            "Harmanli": {
                "lat": 41.9333,
                "lng": 25.9
            },
            "Berkovitsa": {
                "lat": 43.2333,
                "lng": 23.1167
            },
            "Lukovit": {
                "lat": 43.2,
                "lng": 24.1667
            },
            "Svilengrad": {
                "lat": 41.7667,
                "lng": 26.2
            },
            "Kavarna": {
                "lat": 43.4331,
                "lng": 28.3383
            },
            "Chirpan": {
                "lat": 42.1998,
                "lng": 25.3244
            },
            "Veliki Preslav": {
                "lat": 43.1667,
                "lng": 26.817
            },
            "Sozopol": {
                "lat": 42.4167,
                "lng": 27.7
            },
            "Pomorie": {
                "lat": 42.5683,
                "lng": 27.6167
            },
            "Nesebar": {
                "lat": 42.65,
                "lng": 27.7333
            },
            "Lyaskovets": {
                "lat": 43.1,
                "lng": 25.7167
            },
            "Ihtiman": {
                "lat": 42.4333,
                "lng": 23.8167
            },
            "Novi Iskar": {
                "lat": 42.8,
                "lng": 23.35
            },
            "Parvomay": {
                "lat": 42.1,
                "lng": 25.2167
            },
            "Radomir": {
                "lat": 42.55,
                "lng": 22.95
            },
            "Novi Pazar": {
                "lat": 43.35,
                "lng": 27.2
            },
            "Balchik": {
                "lat": 43.4269,
                "lng": 28.1617
            },
            "Provadia": {
                "lat": 43.1833,
                "lng": 27.4333
            },
            "Razlog": {
                "lat": 41.8833,
                "lng": 23.4667
            },
            "Zlatograd": {
                "lat": 41.3833,
                "lng": 25.1
            },
            "Tryavna": {
                "lat": 42.8669,
                "lng": 25.5
            },
            "Kozloduy": {
                "lat": 43.7833,
                "lng": 23.7333
            },
            "Radnevo": {
                "lat": 42.3,
                "lng": 25.9333
            },
            "Kostinbrod": {
                "lat": 42.8167,
                "lng": 23.2167
            },
            "Bankya": {
                "lat": 42.7,
                "lng": 23.1333
            },
            "Stamboliyski": {
                "lat": 42.1333,
                "lng": 24.5333
            },
            "Etropole": {
                "lat": 42.8333,
                "lng": 24.0
            },
            "Knezha": {
                "lat": 43.5,
                "lng": 24.0833
            },
            "Levski": {
                "lat": 43.3667,
                "lng": 25.1333
            },
            "Pavlikeni": {
                "lat": 43.2428,
                "lng": 25.3217
            },
            "Elhovo": {
                "lat": 42.1667,
                "lng": 26.5667
            },
            "Momchilgrad": {
                "lat": 41.5333,
                "lng": 25.4167
            },
            "Dospat": {
                "lat": 41.65,
                "lng": 24.1667
            },
            "Tutrakan": {
                "lat": 44.05,
                "lng": 26.6167
            },
            "Devnya": {
                "lat": 43.2167,
                "lng": 27.5667
            },
            "Rakitovo": {
                "lat": 41.9833,
                "lng": 24.0833
            },
            "Sredets": {
                "lat": 42.35,
                "lng": 27.2
            },
            "Sopot": {
                "lat": 42.65,
                "lng": 24.75
            }
        }
    },
    "CR": {
        "name": "Costa Rica",
        "cities": {
            "San Jos\u00e9": {
                "lat": 9.9325,
                "lng": -84.08
            },
            "San Marcos": {
                "lat": 9.6703,
                "lng": -84.0092
            },
            "San Ram\u00f3n": {
                "lat": 10.2182,
                "lng": -84.5992
            },
            "Puerto Lim\u00f3n": {
                "lat": 10.0022,
                "lng": -83.084
            },
            "Sarapiqu\u00ed": {
                "lat": 10.4853,
                "lng": -83.9362
            },
            "Mora": {
                "lat": 10.0088,
                "lng": -84.0216
            },
            "Santa Cruz": {
                "lat": 10.2358,
                "lng": -85.6408
            },
            "Liberia": {
                "lat": 10.6333,
                "lng": -85.4333
            },
            "Nicoya": {
                "lat": 10.1024,
                "lng": -85.4431
            },
            "Alajuela": {
                "lat": 10.164,
                "lng": -84.2645
            },
            "San Rafael": {
                "lat": 10.0417,
                "lng": -84.075
            },
            "San Isidro": {
                "lat": 9.9737,
                "lng": -83.9861
            },
            "Naranjo": {
                "lat": 10.1053,
                "lng": -84.3885
            },
            "Quesada": {
                "lat": 10.3441,
                "lng": -84.4199
            },
            "Palmares": {
                "lat": 10.0466,
                "lng": -84.4373
            },
            "Puntarenas": {
                "lat": 9.9667,
                "lng": -84.8333
            },
            "Gu\u00e1cimo": {
                "lat": 10.2025,
                "lng": -83.6223
            },
            "Santo Domingo": {
                "lat": 9.988,
                "lng": -84.0686
            },
            "Coto Brus": {
                "lat": 8.889,
                "lng": -82.9092
            },
            "Matina": {
                "lat": 10.0099,
                "lng": -83.3096
            },
            "Gu\u00e1piles": {
                "lat": 10.207,
                "lng": -83.8581
            },
            "Cariari": {
                "lat": 10.4349,
                "lng": -83.6851
            },
            "Desamparados": {
                "lat": 10.0315,
                "lng": -84.1877
            },
            "La Uruca": {
                "lat": 9.9575,
                "lng": -84.1327
            },
            "Siquirres": {
                "lat": 10.0901,
                "lng": -83.5016
            },
            "San Vicente": {
                "lat": 9.9636,
                "lng": -84.0454
            },
            "Buenos Aires": {
                "lat": 9.1985,
                "lng": -83.2816
            },
            "Po\u00e1s": {
                "lat": 10.106,
                "lng": -84.2475
            },
            "Curridabat": {
                "lat": 9.916,
                "lng": -84.0348
            },
            "Esparza": {
                "lat": 9.9959,
                "lng": -84.6467
            },
            "San Pablo": {
                "lat": 9.9918,
                "lng": -84.0937
            },
            "Purral": {
                "lat": 9.9594,
                "lng": -84.0165
            },
            "Turrialba": {
                "lat": 9.8897,
                "lng": -83.6807
            },
            "Ca\u00f1as": {
                "lat": 10.4457,
                "lng": -85.0992
            },
            "San Juan": {
                "lat": 9.9609,
                "lng": -84.0731
            },
            "Horquetas": {
                "lat": 10.3195,
                "lng": -83.9647
            },
            "Rita": {
                "lat": 10.4337,
                "lng": -83.753
            },
            "San Pedro": {
                "lat": 9.9332,
                "lng": -84.047
            },
            "San Rafael Abajo": {
                "lat": 9.8943,
                "lng": -84.0837
            },
            "Cartago": {
                "lat": 9.8667,
                "lng": -83.9167
            },
            "Guadalupe": {
                "lat": 9.9494,
                "lng": -84.0512
            },
            "Para\u00edso": {
                "lat": 9.8432,
                "lng": -83.8463
            },
            "Sartalillo": {
                "lat": 9.9759,
                "lng": -84.0232
            },
            "Aguas Zarcas": {
                "lat": 10.4223,
                "lng": -84.3554
            },
            "San Francisco": {
                "lat": 9.9083,
                "lng": -84.0582
            },
            "Puerto Viejo": {
                "lat": 10.5737,
                "lng": -83.9548
            },
            "Gu\u00e1cima": {
                "lat": 9.9613,
                "lng": -84.2588
            },
            "Quepos": {
                "lat": 9.4571,
                "lng": -84.1387
            },
            "Bagaces": {
                "lat": 10.5157,
                "lng": -85.2478
            },
            "San Juan de Dios": {
                "lat": 9.88,
                "lng": -84.0914
            },
            "Heredia": {
                "lat": 9.9985,
                "lng": -84.1169
            },
            "Zapote": {
                "lat": 9.9203,
                "lng": -84.0592
            },
            "Abangaritos": {
                "lat": 10.2492,
                "lng": -85.0268
            },
            "Col\u00f3n": {
                "lat": 9.9096,
                "lng": -84.262
            },
            "Pital": {
                "lat": 10.6024,
                "lng": -84.2474
            },
            "Roxana": {
                "lat": 10.3586,
                "lng": -83.6738
            },
            "Bat\u00e1n": {
                "lat": 10.1041,
                "lng": -83.3529
            },
            "Upala": {
                "lat": 10.8645,
                "lng": -84.9942
            },
            "Parrita": {
                "lat": 9.5471,
                "lng": -84.3464
            },
            "Pozos": {
                "lat": 9.9536,
                "lng": -84.1926
            },
            "Fortuna": {
                "lat": 10.4483,
                "lng": -84.6421
            },
            "San Rafael Arriba": {
                "lat": 9.8778,
                "lng": -84.0708
            },
            "Florencia": {
                "lat": 10.3665,
                "lng": -84.4814
            },
            "Sardinal": {
                "lat": 10.5343,
                "lng": -85.6955
            },
            "Grecia": {
                "lat": 10.0693,
                "lng": -84.3136
            },
            "San Vito": {
                "lat": 8.84,
                "lng": -82.9784
            },
            "Copalch\u00ed": {
                "lat": 9.8473,
                "lng": -84.0834
            },
            "Los Chiles": {
                "lat": 10.9639,
                "lng": -84.599
            },
            "Santa Ana": {
                "lat": 9.932,
                "lng": -84.176
            },
            "Matinilla": {
                "lat": 9.9246,
                "lng": -84.0713
            },
            "Puerto Jim\u00e9nez": {
                "lat": 8.5338,
                "lng": -83.461
            },
            "Santiago": {
                "lat": 9.8291,
                "lng": -84.3044
            },
            "Alajuelita": {
                "lat": 9.9035,
                "lng": -84.0996
            },
            "Escaz\u00fa": {
                "lat": 9.916,
                "lng": -84.1459
            },
            "Patarr\u00e1": {
                "lat": 9.8637,
                "lng": -84.0248
            },
            "Sixaola": {
                "lat": 9.5579,
                "lng": -82.6698
            },
            "Jac\u00f3": {
                "lat": 9.62,
                "lng": -84.6044
            },
            "San Josecito": {
                "lat": 9.8885,
                "lng": -84.1055
            },
            "Golfito": {
                "lat": 8.6526,
                "lng": -83.1733
            },
            "Nandayure": {
                "lat": 9.9014,
                "lng": -85.3036
            },
            "R\u00edo Cuarto": {
                "lat": 10.4076,
                "lng": -84.2135
            },
            "Sabalito": {
                "lat": 8.8828,
                "lng": -82.8314
            },
            "R\u00edo Segundo": {
                "lat": 10.0013,
                "lng": -84.1937
            },
            "Puente de Piedra": {
                "lat": 10.0298,
                "lng": -84.3332
            },
            "La Virgen": {
                "lat": 10.4312,
                "lng": -84.0772
            },
            "Jim\u00e9nez": {
                "lat": 10.1797,
                "lng": -83.7382
            },
            "San Antonio": {
                "lat": 9.9781,
                "lng": -84.1879
            },
            "Dulce Nombre de Jes\u00fas": {
                "lat": 10.0838,
                "lng": -83.981
            },
            "Orotina": {
                "lat": 9.9024,
                "lng": -84.525
            },
            "Venecia": {
                "lat": 10.3357,
                "lng": -84.2859
            },
            "Canoas": {
                "lat": 8.5333,
                "lng": -82.8333
            },
            "Tres R\u00edos": {
                "lat": 9.9072,
                "lng": -83.9865
            },
            "Carrillos": {
                "lat": 10.0369,
                "lng": -84.274
            },
            "La Cruz": {
                "lat": 11.0845,
                "lng": -85.6436
            },
            "San Ignacio": {
                "lat": 9.7997,
                "lng": -84.163
            },
            "Pacuarito": {
                "lat": 10.1093,
                "lng": -83.4091
            },
            "R\u00edo Jim\u00e9nez": {
                "lat": 10.2556,
                "lng": -83.5629
            },
            "Tilar\u00e1n": {
                "lat": 10.4709,
                "lng": -84.9674
            },
            "Ciudad Cort\u00e9s": {
                "lat": 9.0221,
                "lng": -83.5751
            }
        }
    },
    "RW": {
        "name": "Rwanda",
        "cities": {
            "Kigali": {
                "lat": -1.9439,
                "lng": 30.0594
            },
            "Muhanga": {
                "lat": -2.0845,
                "lng": 29.7527
            },
            "Gitarama": {
                "lat": -2.0696,
                "lng": 29.76
            },
            "Kabarore": {
                "lat": -1.6211,
                "lng": 30.385
            },
            "Cyuve": {
                "lat": -1.4833,
                "lng": 29.65
            },
            "Ruhengeri": {
                "lat": -1.5,
                "lng": 29.6333
            },
            "Gisenyi": {
                "lat": -1.7,
                "lng": 29.25
            },
            "Gahini": {
                "lat": -1.8422,
                "lng": 30.4781
            },
            "Shyorongi": {
                "lat": -1.8544,
                "lng": 29.9625
            },
            "Gatunda": {
                "lat": -1.435,
                "lng": 30.0211
            },
            "Rubengera": {
                "lat": -2.0519,
                "lng": 29.4144
            },
            "Kabuga": {
                "lat": -1.9667,
                "lng": 30.2167
            },
            "Ngororero": {
                "lat": -1.865,
                "lng": 29.625
            },
            "Byumba": {
                "lat": -1.5761,
                "lng": 30.0675
            },
            "Nyamata": {
                "lat": -2.205,
                "lng": 30.145
            },
            "Ndora": {
                "lat": -2.6033,
                "lng": 29.8336
            },
            "Bugarama": {
                "lat": -2.6972,
                "lng": 29.0083
            },
            "Cyangugu": {
                "lat": -2.4833,
                "lng": 28.8967
            },
            "Kibeho": {
                "lat": -2.6333,
                "lng": 29.55
            },
            "Nyanza": {
                "lat": -2.35,
                "lng": 29.7333
            },
            "Butare": {
                "lat": -2.6,
                "lng": 29.75
            },
            "Rwamagana": {
                "lat": -1.95,
                "lng": 30.4333
            },
            "Murambi": {
                "lat": -1.8133,
                "lng": 30.0456
            },
            "Ruhango": {
                "lat": -2.2325,
                "lng": 29.7803
            },
            "Gikongoro": {
                "lat": -2.4723,
                "lng": 29.5815
            },
            "Busogo": {
                "lat": -1.5572,
                "lng": 29.5442
            },
            "Kibuye": {
                "lat": -2.0617,
                "lng": 29.3483
            },
            "Kibungo": {
                "lat": -2.1583,
                "lng": 30.5458
            }
        }
    },
    "PA": {
        "name": "Panama",
        "cities": {
            "Panama City": {
                "lat": 8.9833,
                "lng": -79.5167
            },
            "San Miguelito": {
                "lat": 9.033,
                "lng": -79.5
            },
            "David": {
                "lat": 8.4333,
                "lng": -82.4333
            },
            "Col\u00f3n": {
                "lat": 9.3572,
                "lng": -79.8986
            },
            "Tocumen": {
                "lat": 9.08,
                "lng": -79.38
            },
            "La Chorrera": {
                "lat": 8.8792,
                "lng": -79.7822
            },
            "Balboa Heights": {
                "lat": 8.95,
                "lng": -79.55
            },
            "Chilibre": {
                "lat": 9.15,
                "lng": -79.62
            },
            "Pacora": {
                "lat": 9.08,
                "lng": -79.28
            },
            "Aguadulce": {
                "lat": 8.24,
                "lng": -80.54
            },
            "Chitr\u00e9": {
                "lat": 7.9667,
                "lng": -80.4333
            },
            "Santiago": {
                "lat": 8.1004,
                "lng": -80.9833
            },
            "Alcalde D\u00edaz": {
                "lat": 9.12,
                "lng": -79.55
            },
            "Arraij\u00e1n": {
                "lat": 8.95,
                "lng": -79.65
            },
            "Cativ\u00e1": {
                "lat": 9.36,
                "lng": -79.84
            },
            "Kusap\u00edn": {
                "lat": 9.18,
                "lng": -81.89
            },
            "Changuinola": {
                "lat": 9.4333,
                "lng": -82.5167
            },
            "Anc\u00f3n": {
                "lat": 8.97,
                "lng": -79.55
            },
            "Nuevo Arraij\u00e1n": {
                "lat": 8.92,
                "lng": -79.72
            },
            "Penonom\u00e9": {
                "lat": 8.5187,
                "lng": -80.3553
            },
            "Chepo": {
                "lat": 9.17,
                "lng": -79.1
            },
            "Puerto Armuelles": {
                "lat": 8.2833,
                "lng": -82.8667
            },
            "El Coco": {
                "lat": 8.8834,
                "lng": -79.8332
            },
            "Sabanitas": {
                "lat": 9.34,
                "lng": -79.8
            },
            "Bajo Boquete": {
                "lat": 8.78,
                "lng": -82.44
            },
            "Puerto Caimito": {
                "lat": 8.87,
                "lng": -79.72
            },
            "Puerto Pil\u00f3n": {
                "lat": 9.36,
                "lng": -79.79
            },
            "Bocas del Toro": {
                "lat": 9.3333,
                "lng": -82.25
            },
            "Almirante": {
                "lat": 9.3,
                "lng": -82.4
            },
            "Paso Canoas": {
                "lat": 8.5333,
                "lng": -82.8333
            },
            "Las Tablas": {
                "lat": 7.7667,
                "lng": -80.2833
            },
            "La Palma": {
                "lat": 8.41,
                "lng": -78.15
            },
            "Jaqu\u00e9": {
                "lat": 7.5181,
                "lng": -78.1625
            },
            "Buabidi": {
                "lat": 8.4667,
                "lng": -81.7
            },
            "Gaigirgordub": {
                "lat": 9.5586,
                "lng": -78.9484
            },
            "Uni\u00f3n Choc\u00f3": {
                "lat": 8.1021,
                "lng": -77.6058
            },
            "Sieyik": {
                "lat": 9.3833,
                "lng": -82.6522
            }
        }
    },
    "SN": {
        "name": "Senegal",
        "cities": {
            "Dakar": {
                "lat": 14.6928,
                "lng": -17.4467
            },
            "Pikine": {
                "lat": 14.75,
                "lng": -17.4
            },
            "Touba": {
                "lat": 14.8667,
                "lng": -15.8833
            },
            "Thi\u00e8s": {
                "lat": 14.7833,
                "lng": -16.9167
            },
            "Gu\u00e9diawaye": {
                "lat": 14.7833,
                "lng": -17.4
            },
            "Rufisque": {
                "lat": 14.7167,
                "lng": -17.2667
            },
            "Kaolack": {
                "lat": 14.1389,
                "lng": -16.0764
            },
            "Saint-Louis": {
                "lat": 16.0333,
                "lng": -16.5
            },
            "Diourbel": {
                "lat": 14.655,
                "lng": -16.2314
            },
            "Richard-Toll": {
                "lat": 16.4667,
                "lng": -15.6833
            },
            "Louga": {
                "lat": 15.6167,
                "lng": -16.2167
            },
            "Tambacounda": {
                "lat": 13.7689,
                "lng": -13.6672
            },
            "Ziguinchor": {
                "lat": 12.5833,
                "lng": -16.2667
            },
            "Mbak\u00e9": {
                "lat": 14.7917,
                "lng": -15.9083
            },
            "Kolda": {
                "lat": 12.8833,
                "lng": -14.95
            },
            "Bargny": {
                "lat": 14.6939,
                "lng": -17.2336
            },
            "Joal-Fadiout": {
                "lat": 14.1667,
                "lng": -16.8333
            },
            "Paoskoto": {
                "lat": 13.7833,
                "lng": -15.8167
            },
            "Tivaouane": {
                "lat": 14.95,
                "lng": -16.8167
            },
            "Kaffrine": {
                "lat": 14.1014,
                "lng": -15.5432
            },
            "Dara": {
                "lat": 15.35,
                "lng": -15.6
            },
            "Mal\u00e8me Hodar": {
                "lat": 14.0883,
                "lng": -15.2944
            },
            "Sali": {
                "lat": 14.4383,
                "lng": -17.0125
            },
            "S\u00e9bikhotane": {
                "lat": 14.7469,
                "lng": -17.1367
            },
            "V\u00e9lingara": {
                "lat": 13.15,
                "lng": -14.1167
            },
            "Pout": {
                "lat": 14.7739,
                "lng": -17.0603
            },
            "M\u00e9kh\u00e9": {
                "lat": 15.1167,
                "lng": -16.6333
            },
            "Bignona": {
                "lat": 12.8167,
                "lng": -16.2333
            },
            "Sal\u00e9mata": {
                "lat": 12.6333,
                "lng": -12.8167
            },
            "Kobilo": {
                "lat": 15.9251,
                "lng": -13.5242
            },
            "Ngu\u00e9khokh": {
                "lat": 14.5128,
                "lng": -17.05
            },
            "K\u00e9b\u00e9mer": {
                "lat": 15.3667,
                "lng": -16.4333
            },
            "Fatick": {
                "lat": 14.3333,
                "lng": -16.4069
            },
            "Dagana": {
                "lat": 16.4833,
                "lng": -15.6
            },
            "Matam": {
                "lat": 15.6167,
                "lng": -13.3333
            },
            "Nioro du Rip": {
                "lat": 13.75,
                "lng": -15.8
            },
            "Guinguin\u00e9o": {
                "lat": 14.2667,
                "lng": -15.95
            },
            "Lingu\u00e8re": {
                "lat": 15.3944,
                "lng": -15.1167
            },
            "Koungheul": {
                "lat": 13.9833,
                "lng": -14.8
            },
            "Bakel": {
                "lat": 14.9042,
                "lng": -12.4583
            },
            "Sokone": {
                "lat": 13.8833,
                "lng": -16.3667
            },
            "Goudomp": {
                "lat": 12.5778,
                "lng": -15.8722
            },
            "Kanel": {
                "lat": 15.4833,
                "lng": -13.1667
            },
            "Khombole": {
                "lat": 14.7667,
                "lng": -16.6833
            },
            "Diamniadio": {
                "lat": 14.7219,
                "lng": -17.1789
            },
            "Tiadiaye": {
                "lat": 14.4167,
                "lng": -16.7
            },
            "Mboro": {
                "lat": 15.15,
                "lng": -16.9
            },
            "Ross-B\u00e9tio": {
                "lat": 16.2667,
                "lng": -16.1333
            },
            "K\u00e9dougou": {
                "lat": 12.55,
                "lng": -12.1833
            },
            "Gu\u00e9oul": {
                "lat": 15.4833,
                "lng": -16.35
            },
            "Thilogne": {
                "lat": 15.9239,
                "lng": -13.6578
            },
            "Kidira": {
                "lat": 14.4167,
                "lng": -12.25
            },
            "Diabougou": {
                "lat": 14.5431,
                "lng": -12.2186
            },
            "Koumpentoum": {
                "lat": 13.9833,
                "lng": -14.5667
            },
            "Ngaparou": {
                "lat": 14.4631,
                "lng": -17.0581
            },
            "Tionk Essil": {
                "lat": 12.7856,
                "lng": -16.5217
            },
            "S\u00e9dhiou": {
                "lat": 12.7,
                "lng": -15.55
            }
        }
    },
    "MN": {
        "name": "Mongolia",
        "cities": {
            "Ulaanbaatar": {
                "lat": 47.9203,
                "lng": 106.9172
            },
            "M\u00f6r\u00f6n": {
                "lat": 49.6356,
                "lng": 100.1556
            },
            "Erdenet": {
                "lat": 49.0278,
                "lng": 104.0444
            },
            "Darhan": {
                "lat": 49.6167,
                "lng": 106.35
            },
            "\u00d6lgiy": {
                "lat": 48.9683,
                "lng": 89.9686
            },
            "Choybalsan": {
                "lat": 48.0783,
                "lng": 114.535
            },
            "Nalayh": {
                "lat": 47.7725,
                "lng": 107.2536
            },
            "Ulaangom": {
                "lat": 49.9833,
                "lng": 92.0667
            },
            "Bayanhongor": {
                "lat": 46.1917,
                "lng": 100.7178
            },
            "Hovd": {
                "lat": 48.0042,
                "lng": 91.6406
            },
            "Uliastay": {
                "lat": 47.7428,
                "lng": 96.8433
            },
            "\u00d6nd\u00f6rhaan": {
                "lat": 47.3233,
                "lng": 110.6611
            },
            "Arvayheer": {
                "lat": 46.2661,
                "lng": 102.7778
            },
            "Saynshand": {
                "lat": 44.8917,
                "lng": 110.1367
            },
            "Dalanzadgad": {
                "lat": 43.57,
                "lng": 104.4258
            },
            "S\u00fchbaatar": {
                "lat": 50.2364,
                "lng": 106.2064
            },
            "Dz\u00fc\u00fcnharaa": {
                "lat": 48.8666,
                "lng": 106.4666
            },
            "Tsetserleg": {
                "lat": 47.4769,
                "lng": 101.4503
            },
            "Altay": {
                "lat": 46.3728,
                "lng": 96.2572
            },
            "Harhorin": {
                "lat": 47.1972,
                "lng": 102.8239
            },
            "Dzuunmod": {
                "lat": 47.7069,
                "lng": 106.9494
            },
            "Baruun-Urt": {
                "lat": 46.6814,
                "lng": 113.2803
            },
            "Mandalgov\u012d": {
                "lat": 45.7667,
                "lng": 106.2697
            },
            "Bulgan": {
                "lat": 48.8119,
                "lng": 103.5336
            },
            "Choyr": {
                "lat": 46.3606,
                "lng": 108.365
            },
            "H\u00f6sh\u00f6\u00f6t": {
                "lat": 48.9408,
                "lng": 89.1383
            },
            "Ulaan-Uul": {
                "lat": 44.3337,
                "lng": 111.2333
            },
            "Dalandzadgad": {
                "lat": 43.5708,
                "lng": 104.425
            }
        }
    },
    "IL": {
        "name": "Israel",
        "cities": {
            "Tel Aviv-Yafo": {
                "lat": 32.08,
                "lng": 34.78
            },
            "Jerusalem": {
                "lat": 31.7789,
                "lng": 35.2256
            },
            "Haifa": {
                "lat": 32.8192,
                "lng": 34.9992
            },
            "Rishon Le\u1e94iyyon": {
                "lat": 31.95,
                "lng": 34.8
            },
            "Peta\u1e96 Tiqwa": {
                "lat": 32.0889,
                "lng": 34.8864
            },
            "Ashdod": {
                "lat": 31.8,
                "lng": 34.65
            },
            "Netanya": {
                "lat": 32.3286,
                "lng": 34.8567
            },
            "Beersheba": {
                "lat": 31.2589,
                "lng": 34.7997
            },
            "Holon": {
                "lat": 32.0167,
                "lng": 34.7667
            },
            "Ben\u00e9 Beraq": {
                "lat": 32.0833,
                "lng": 34.8333
            },
            "Ramat Gan": {
                "lat": 32.07,
                "lng": 34.8236
            },
            "Ashqelon": {
                "lat": 31.6667,
                "lng": 34.5667
            },
            "Re\u1e96ovot": {
                "lat": 31.8969,
                "lng": 34.8167
            },
            "Bat Yam": {
                "lat": 32.0167,
                "lng": 34.75
            },
            "Bet Shemesh": {
                "lat": 31.7456,
                "lng": 34.9867
            },
            "Kefar Sava": {
                "lat": 32.1714,
                "lng": 34.9083
            },
            "Modi\u2018in Makkabbim Re\u2018ut": {
                "lat": 31.9077,
                "lng": 35.0076
            },
            "Hadera": {
                "lat": 32.45,
                "lng": 34.9167
            },
            "Her\u1e95liyya": {
                "lat": 32.1653,
                "lng": 34.8458
            },
            "Nazareth": {
                "lat": 32.7019,
                "lng": 35.3033
            },
            "Lod": {
                "lat": 31.9519,
                "lng": 34.8881
            },
            "Ramla": {
                "lat": 31.9275,
                "lng": 34.8625
            },
            "Ra\u2018ananna": {
                "lat": 32.1833,
                "lng": 34.8667
            },
            "Qiryat Gat": {
                "lat": 31.6061,
                "lng": 34.7717
            },
            "Rahat": {
                "lat": 31.3925,
                "lng": 34.7544
            },
            "Afula": {
                "lat": 32.6064,
                "lng": 35.2881
            },
            "Nahariyya": {
                "lat": 33.0058,
                "lng": 35.0989
            },
            "Givatayim": {
                "lat": 32.0714,
                "lng": 34.81
            },
            "Hod HaSharon": {
                "lat": 32.15,
                "lng": 34.8833
            },
            "Rosh Ha\u2018Ayin": {
                "lat": 32.0956,
                "lng": 34.9567
            },
            "Qiryat Ata": {
                "lat": 32.8,
                "lng": 35.1
            },
            "Umm el Fa\u1e25m": {
                "lat": 32.5194,
                "lng": 35.1536
            },
            "Eilat": {
                "lat": 29.5569,
                "lng": 34.9517
            },
            "Nes \u1e94iyyona": {
                "lat": 31.9333,
                "lng": 34.8
            },
            "\u2018Akko": {
                "lat": 32.9278,
                "lng": 35.0817
            },
            "El\u2018ad": {
                "lat": 32.0522,
                "lng": 34.9511
            },
            "Ramat HaSharon": {
                "lat": 32.15,
                "lng": 34.8333
            },
            "Karmiel": {
                "lat": 32.9136,
                "lng": 35.2961
            },
            "Tiberias": {
                "lat": 32.7944,
                "lng": 35.5333
            },
            "E\u1e6d \u1e6caiyiba": {
                "lat": 32.2667,
                "lng": 35.0103
            },
            "Ben Zakkay": {
                "lat": 31.8558,
                "lng": 34.73
            },
            "Pard\u00e9s H\u0331anna Karkur": {
                "lat": 32.4711,
                "lng": 34.9675
            },
            "Qiryat Mo\u1e95qin": {
                "lat": 32.8333,
                "lng": 35.0833
            },
            "Qiryat Ono": {
                "lat": 32.0636,
                "lng": 34.8553
            },
            "Shefar\u2018am": {
                "lat": 32.8056,
                "lng": 35.1694
            },
            "Qiryat Bialik": {
                "lat": 32.8333,
                "lng": 35.0833
            },
            "Qiryat Yam": {
                "lat": 32.8333,
                "lng": 35.0667
            },
            "Or Yehuda": {
                "lat": 32.0306,
                "lng": 34.8533
            },
            "\u1e94efat": {
                "lat": 32.9658,
                "lng": 35.4983
            },
            "Dimona": {
                "lat": 31.0667,
                "lng": 35.0333
            },
            "Tamra": {
                "lat": 32.8536,
                "lng": 35.1978
            },
            "Netivot": {
                "lat": 31.4167,
                "lng": 34.5833
            },
            "Sakhn\u012bn": {
                "lat": 32.8667,
                "lng": 35.3
            },
            "Be\u2019er Ya\u2018aqov": {
                "lat": 31.9425,
                "lng": 34.8336
            },
            "Yehud": {
                "lat": 32.0333,
                "lng": 34.8833
            },
            "Ofaqim": {
                "lat": 31.3167,
                "lng": 34.6167
            },
            "Kefar Yona": {
                "lat": 32.3171,
                "lng": 34.9358
            },
            "Gedera": {
                "lat": 31.8119,
                "lng": 34.7772
            },
            "E\u1e6d \u1e6c\u012bra": {
                "lat": 32.2322,
                "lng": 34.9483
            },
            "Migdal Ha\u2018Emeq": {
                "lat": 32.6714,
                "lng": 35.2406
            },
            "Giv\u2018at Shemu\u2019\u00e9l": {
                "lat": 32.0781,
                "lng": 34.8475
            },
            "Arad": {
                "lat": 31.2611,
                "lng": 35.2153
            },
            "Yoqne\u2018am \u2018Illit": {
                "lat": 32.6594,
                "lng": 35.11
            },
            "Nesher": {
                "lat": 32.7711,
                "lng": 35.0394
            },
            "Ma\u2018alot Tarsh\u012b\u1e25\u0101": {
                "lat": 33.0167,
                "lng": 35.2708
            },
            "Qiryat Mal\u2019akhi": {
                "lat": 31.7292,
                "lng": 34.7461
            },
            "Sederot": {
                "lat": 31.5228,
                "lng": 34.5953
            },
            "Qiryat Shemona": {
                "lat": 33.2075,
                "lng": 35.5697
            },
            "Gan Yavne": {
                "lat": 31.7822,
                "lng": 34.7053
            },
            "Tirat Karmel": {
                "lat": 32.7667,
                "lng": 34.9667
            },
            "Kafr Q\u0101sim": {
                "lat": 32.1151,
                "lng": 34.9751
            },
            "Qalansuwa": {
                "lat": 32.2822,
                "lng": 34.9833
            },
            "Bet She\u2019an": {
                "lat": 32.5,
                "lng": 35.5
            },
            "Or \u2018Aqiva": {
                "lat": 32.5,
                "lng": 34.9167
            },
            "Jisr ez Zarq\u0101": {
                "lat": 32.5381,
                "lng": 34.9122
            },
            "Majdal Shams": {
                "lat": 33.2667,
                "lng": 35.7667
            },
            "Atlit": {
                "lat": 32.6872,
                "lng": 34.9383
            }
        }
    },
    "DK": {
        "name": "Denmark",
        "cities": {
            "Copenhagen": {
                "lat": 55.6761,
                "lng": 12.5683
            },
            "Aarhus": {
                "lat": 56.1572,
                "lng": 10.2107
            },
            "Odense": {
                "lat": 55.3958,
                "lng": 10.3886
            },
            "Aalborg": {
                "lat": 57.05,
                "lng": 9.9167
            },
            "Esbjerg": {
                "lat": 55.4833,
                "lng": 8.45
            },
            "Randers": {
                "lat": 56.457,
                "lng": 10.039
            },
            "Horsens": {
                "lat": 55.8583,
                "lng": 9.85
            },
            "Kolding": {
                "lat": 55.4917,
                "lng": 9.5
            },
            "Vejle": {
                "lat": 55.7167,
                "lng": 9.5333
            },
            "Roskilde": {
                "lat": 55.65,
                "lng": 12.0833
            },
            "Herning": {
                "lat": 56.1333,
                "lng": 8.9833
            },
            "Silkeborg": {
                "lat": 56.1833,
                "lng": 9.5517
            },
            "H\u00f8rsholm": {
                "lat": 55.8803,
                "lng": 12.5081
            },
            "Helsing\u00f8r": {
                "lat": 56.0361,
                "lng": 12.6083
            },
            "N\u00e6stved": {
                "lat": 55.225,
                "lng": 11.7583
            },
            "Viborg": {
                "lat": 56.4333,
                "lng": 9.4
            },
            "Fredericia": {
                "lat": 55.5667,
                "lng": 9.75
            },
            "K\u00f8ge": {
                "lat": 55.4561,
                "lng": 12.1797
            },
            "Holstebro": {
                "lat": 56.3572,
                "lng": 8.6153
            },
            "Taastrup": {
                "lat": 55.65,
                "lng": 12.3
            },
            "Hiller\u00f8d": {
                "lat": 55.9333,
                "lng": 12.3167
            },
            "Slagelse": {
                "lat": 55.4049,
                "lng": 11.3531
            },
            "Holb\u00e6k": {
                "lat": 55.7156,
                "lng": 11.7225
            },
            "S\u00f8nderborg": {
                "lat": 54.9138,
                "lng": 9.7922
            },
            "Svendborg": {
                "lat": 55.0594,
                "lng": 10.6083
            },
            "Hj\u00f8rring": {
                "lat": 57.4636,
                "lng": 9.9814
            },
            "N\u00f8rresundby": {
                "lat": 57.0667,
                "lng": 9.9167
            },
            "Ringsted": {
                "lat": 55.4425,
                "lng": 11.79
            },
            "Frederikshavn": {
                "lat": 57.441,
                "lng": 10.534
            },
            "Haderslev": {
                "lat": 55.2428,
                "lng": 9.525
            },
            "Birker\u00f8d": {
                "lat": 55.8333,
                "lng": 12.4333
            },
            "Farum": {
                "lat": 55.8083,
                "lng": 12.3581
            },
            "Skive": {
                "lat": 56.5667,
                "lng": 9.0333
            },
            "Nyk\u00f8bing Falster": {
                "lat": 54.7654,
                "lng": 11.8755
            },
            "Skanderborg": {
                "lat": 56.0381,
                "lng": 9.9253
            },
            "Sm\u00f8rumnedre": {
                "lat": 55.7333,
                "lng": 12.3
            },
            "Nyborg": {
                "lat": 55.3122,
                "lng": 10.7897
            },
            "Vordingborg": {
                "lat": 55.0,
                "lng": 11.9
            },
            "Solr\u00f8d Strand": {
                "lat": 55.5167,
                "lng": 12.2167
            },
            "Frederikssund": {
                "lat": 55.8333,
                "lng": 12.0666
            },
            "Liller\u00f8d": {
                "lat": 55.8681,
                "lng": 12.3417
            },
            "Middelfart": {
                "lat": 55.4986,
                "lng": 9.7444
            },
            "Kalundborg": {
                "lat": 55.6814,
                "lng": 11.085
            },
            "Ikast": {
                "lat": 56.1333,
                "lng": 9.15
            },
            "Aabenraa": {
                "lat": 55.0444,
                "lng": 9.4181
            },
            "Hedehusene": {
                "lat": 55.6547,
                "lng": 12.1953
            },
            "Kors\u00f8r": {
                "lat": 55.3336,
                "lng": 11.1397
            },
            "Grenaa": {
                "lat": 56.4161,
                "lng": 10.8923
            },
            "Varde": {
                "lat": 55.62,
                "lng": 8.4806
            },
            "R\u00f8nne": {
                "lat": 55.0986,
                "lng": 14.7014
            },
            "Thisted": {
                "lat": 56.9569,
                "lng": 8.6944
            },
            "V\u00e6rl\u00f8se": {
                "lat": 55.7819,
                "lng": 12.3731
            },
            "Odder": {
                "lat": 55.9725,
                "lng": 10.1497
            },
            "Br\u00f8nderslev": {
                "lat": 57.2694,
                "lng": 9.9472
            },
            "Frederiksv\u00e6rk": {
                "lat": 55.9667,
                "lng": 12.0167
            },
            "Hedensted": {
                "lat": 55.7725,
                "lng": 9.7017
            },
            "Nakskov": {
                "lat": 54.8333,
                "lng": 11.15
            },
            "Drag\u00f8r": {
                "lat": 55.5833,
                "lng": 12.6667
            },
            "Haslev": {
                "lat": 55.3333,
                "lng": 11.9667
            },
            "Hobro": {
                "lat": 56.6333,
                "lng": 9.8
            },
            "Jyllinge": {
                "lat": 55.7511,
                "lng": 12.1064
            },
            "Lystrup": {
                "lat": 56.2353,
                "lng": 10.2375
            },
            "Vejen": {
                "lat": 55.4774,
                "lng": 9.1379
            },
            "Struer": {
                "lat": 56.4856,
                "lng": 8.5897
            },
            "Ringk\u00f8bing": {
                "lat": 56.0897,
                "lng": 8.2383
            },
            "Humleb\u00e6k": {
                "lat": 55.9611,
                "lng": 12.525
            },
            "St\u00f8vring": {
                "lat": 56.8867,
                "lng": 9.8286
            },
            "Galten": {
                "lat": 56.1533,
                "lng": 9.9069
            },
            "Nyk\u00f8bing Mors": {
                "lat": 56.7953,
                "lng": 8.8592
            },
            "Fredensborg": {
                "lat": 55.975,
                "lng": 12.4056
            },
            "Helsinge": {
                "lat": 56.0222,
                "lng": 12.1972
            },
            "S\u00e6by": {
                "lat": 57.3294,
                "lng": 10.5322
            },
            "M\u00e5l\u00f8v": {
                "lat": 55.75,
                "lng": 12.3333
            },
            "Aars": {
                "lat": 56.8032,
                "lng": 9.5177
            },
            "L\u00f8gten": {
                "lat": 56.1643,
                "lng": 10.1857
            },
            "Hundested": {
                "lat": 55.9667,
                "lng": 11.85
            },
            "Sor\u00f8": {
                "lat": 55.4333,
                "lng": 11.5667
            }
        }
    },
    "FI": {
        "name": "Finland",
        "cities": {
            "Helsinki": {
                "lat": 60.1708,
                "lng": 24.9375
            },
            "Tampere": {
                "lat": 61.4981,
                "lng": 23.76
            },
            "Espoo": {
                "lat": 60.2056,
                "lng": 24.6556
            },
            "Turku": {
                "lat": 60.45,
                "lng": 22.2667
            },
            "Vantaa": {
                "lat": 60.2944,
                "lng": 25.0403
            },
            "Oulu": {
                "lat": 65.0142,
                "lng": 25.4719
            },
            "Jyv\u00e4skyl\u00e4": {
                "lat": 62.2417,
                "lng": 25.7417
            },
            "Kuopio": {
                "lat": 62.8925,
                "lng": 27.6783
            },
            "Lahti": {
                "lat": 60.9833,
                "lng": 25.65
            },
            "Kouvola": {
                "lat": 60.8681,
                "lng": 26.7042
            },
            "Pori": {
                "lat": 61.4833,
                "lng": 21.8
            },
            "Joensuu": {
                "lat": 62.6,
                "lng": 29.75
            },
            "Lappeenranta": {
                "lat": 61.0667,
                "lng": 28.1833
            },
            "H\u00e4meenlinna": {
                "lat": 60.9944,
                "lng": 24.4667
            },
            "Vaasa": {
                "lat": 63.1,
                "lng": 21.6167
            },
            "Sein\u00e4joki": {
                "lat": 62.7917,
                "lng": 22.8417
            },
            "Rovaniemi": {
                "lat": 66.5,
                "lng": 25.7333
            },
            "Mikkeli": {
                "lat": 61.689,
                "lng": 27.272
            },
            "Salo": {
                "lat": 60.3861,
                "lng": 23.125
            },
            "Kotka": {
                "lat": 60.4667,
                "lng": 26.9417
            },
            "Porvoo": {
                "lat": 60.3944,
                "lng": 25.6639
            },
            "Kokkola": {
                "lat": 63.8367,
                "lng": 23.1333
            },
            "Lohja": {
                "lat": 60.25,
                "lng": 24.0667
            },
            "Hyvink\u00e4\u00e4": {
                "lat": 60.6333,
                "lng": 24.85
            },
            "Kirkkonummi": {
                "lat": 60.1167,
                "lng": 24.4333
            },
            "J\u00e4rvenp\u00e4\u00e4": {
                "lat": 60.475,
                "lng": 25.0917
            },
            "Rauma": {
                "lat": 61.1333,
                "lng": 21.5
            },
            "Tuusula": {
                "lat": 60.4333,
                "lng": 25.05
            },
            "Kajaani": {
                "lat": 64.2311,
                "lng": 27.7194
            },
            "Savonlinna": {
                "lat": 61.8681,
                "lng": 28.8861
            },
            "Kerava": {
                "lat": 60.4,
                "lng": 25.1
            },
            "Nokia": {
                "lat": 61.4767,
                "lng": 23.5053
            },
            "Kaarina": {
                "lat": 60.4,
                "lng": 22.3667
            },
            "Yl\u00f6j\u00e4rvi": {
                "lat": 61.55,
                "lng": 23.5833
            },
            "Kangasala": {
                "lat": 61.4667,
                "lng": 24.0667
            },
            "Jyv\u00e4skyl\u00e4n Maalaiskunta": {
                "lat": 62.2889,
                "lng": 25.7417
            },
            "Riihim\u00e4ki": {
                "lat": 60.7333,
                "lng": 24.7667
            },
            "Raseborg": {
                "lat": 59.975,
                "lng": 23.4333
            },
            "Imatra": {
                "lat": 61.1833,
                "lng": 28.7667
            },
            "Sastamala": {
                "lat": 61.3417,
                "lng": 22.9083
            },
            "Raahe": {
                "lat": 64.6833,
                "lng": 24.4667
            },
            "Raisio": {
                "lat": 60.4858,
                "lng": 22.1692
            },
            "Hollola": {
                "lat": 60.9867,
                "lng": 25.5167
            },
            "Lemp\u00e4\u00e4l\u00e4": {
                "lat": 61.3167,
                "lng": 23.75
            },
            "Tornio": {
                "lat": 65.85,
                "lng": 24.15
            },
            "Siilinj\u00e4rvi": {
                "lat": 63.075,
                "lng": 27.66
            },
            "Kurikka": {
                "lat": 62.6167,
                "lng": 22.4
            },
            "Iisalmi": {
                "lat": 63.5567,
                "lng": 27.1883
            },
            "Varkaus": {
                "lat": 62.3167,
                "lng": 27.8917
            },
            "Klaukkala": {
                "lat": 60.382,
                "lng": 24.7492
            },
            "Valkeakoski": {
                "lat": 61.2667,
                "lng": 24.0333
            },
            "M\u00e4nts\u00e4l\u00e4": {
                "lat": 60.6331,
                "lng": 25.3167
            },
            "\u00c4\u00e4nekoski": {
                "lat": 62.6,
                "lng": 25.725
            },
            "Hamina": {
                "lat": 60.5667,
                "lng": 27.2
            },
            "Kuusankoski": {
                "lat": 60.9083,
                "lng": 26.6236
            },
            "Korsholm": {
                "lat": 63.1125,
                "lng": 21.6778
            },
            "Lieto": {
                "lat": 60.505,
                "lng": 22.4583
            },
            "Heinola": {
                "lat": 61.2,
                "lng": 26.0333
            },
            "Kemi": {
                "lat": 65.7361,
                "lng": 24.5636
            },
            "Sipoo": {
                "lat": 60.375,
                "lng": 25.2667
            },
            "J\u00e4ms\u00e4": {
                "lat": 61.8667,
                "lng": 25.1833
            },
            "Jakobstad": {
                "lat": 63.6667,
                "lng": 22.7
            },
            "Naantali": {
                "lat": 60.4667,
                "lng": 22.0333
            },
            "Haukipudas": {
                "lat": 65.1833,
                "lng": 25.35
            },
            "Laukaa": {
                "lat": 62.4167,
                "lng": 25.95
            },
            "Pirkkala": {
                "lat": 61.4667,
                "lng": 23.65
            },
            "Pieks\u00e4m\u00e4ki": {
                "lat": 62.3003,
                "lng": 27.1642
            },
            "Kempele": {
                "lat": 64.9125,
                "lng": 25.5083
            },
            "Forssa": {
                "lat": 60.8167,
                "lng": 23.625
            },
            "Janakkala": {
                "lat": 60.9167,
                "lng": 24.65
            },
            "Kauhava": {
                "lat": 63.1,
                "lng": 23.0667
            },
            "Orimattila": {
                "lat": 60.8042,
                "lng": 25.7333
            },
            "Pielisj\u00e4rvi": {
                "lat": 63.3,
                "lng": 30.05
            },
            "Loimaa": {
                "lat": 60.85,
                "lng": 23.0583
            },
            "Uusikaupunki": {
                "lat": 60.8,
                "lng": 21.4167
            },
            "Sippola": {
                "lat": 60.7392,
                "lng": 27.0003
            },
            "Vammala": {
                "lat": 61.3417,
                "lng": 22.9083
            },
            "Kontiolahti": {
                "lat": 62.7667,
                "lng": 29.85
            },
            "Kuusamo": {
                "lat": 65.9667,
                "lng": 29.1833
            },
            "Pargas": {
                "lat": 60.3,
                "lng": 22.3
            },
            "Ylivieska": {
                "lat": 64.075,
                "lng": 24.5333
            },
            "Nastola": {
                "lat": 60.95,
                "lng": 25.9333
            },
            "Lapua": {
                "lat": 62.97,
                "lng": 23.0083
            },
            "Loviisa": {
                "lat": 60.4583,
                "lng": 26.2333
            },
            "Kauhajoki": {
                "lat": 62.4319,
                "lng": 22.1794
            },
            "Kiiminki": {
                "lat": 65.1333,
                "lng": 25.775
            },
            "Ulvila": {
                "lat": 61.4333,
                "lng": 21.9
            },
            "Ilmajoki": {
                "lat": 62.7333,
                "lng": 22.5833
            },
            "Kalajoki": {
                "lat": 64.2667,
                "lng": 23.95
            },
            "Liperi": {
                "lat": 62.5333,
                "lng": 29.3833
            },
            "Eura": {
                "lat": 61.1333,
                "lng": 22.1333
            },
            "Alavus": {
                "lat": 62.5917,
                "lng": 23.6167
            },
            "Mikkelin Maalaiskunta": {
                "lat": 61.6776,
                "lng": 27.2021
            },
            "Vehkalahti": {
                "lat": 60.5756,
                "lng": 27.1439
            },
            "Kankaanp\u00e4\u00e4": {
                "lat": 61.8,
                "lng": 22.4
            },
            "S\u00e4\u00e4minki": {
                "lat": 61.8675,
                "lng": 28.8786
            },
            "Mariehamn": {
                "lat": 60.1,
                "lng": 19.9333
            },
            "Lieksa": {
                "lat": 63.3167,
                "lng": 30.0167
            },
            "Valkeala": {
                "lat": 60.9389,
                "lng": 26.7972
            },
            "Peders\u00f6re": {
                "lat": 63.6642,
                "lng": 22.7006
            },
            "Nivala": {
                "lat": 63.925,
                "lng": 24.975
            },
            "Nurmo": {
                "lat": 62.8278,
                "lng": 22.9083
            },
            "Kivist\u00f6": {
                "lat": 60.3236,
                "lng": 24.8453
            },
            "Joutseno": {
                "lat": 61.123,
                "lng": 28.5015
            },
            "Paimio": {
                "lat": 60.45,
                "lng": 22.7
            },
            "Sotkamo": {
                "lat": 64.1333,
                "lng": 28.3833
            },
            "H\u00e4meenkyr\u00f6": {
                "lat": 61.6333,
                "lng": 23.2
            },
            "Huittinen": {
                "lat": 61.175,
                "lng": 22.7
            },
            "Liminka": {
                "lat": 64.8083,
                "lng": 25.4167
            },
            "Muurame": {
                "lat": 62.1333,
                "lng": 25.675
            },
            "Alaj\u00e4rvi": {
                "lat": 63.0,
                "lng": 23.8167
            },
            "Lapinlahti": {
                "lat": 63.3667,
                "lng": 27.3833
            },
            "Lepp\u00e4virta": {
                "lat": 62.5,
                "lng": 27.7833
            },
            "Saarij\u00e4rvi": {
                "lat": 62.705,
                "lng": 25.2583
            },
            "Ii": {
                "lat": 65.3167,
                "lng": 25.3667
            },
            "Oulunsalo": {
                "lat": 64.9333,
                "lng": 25.4167
            },
            "Kitee": {
                "lat": 62.1,
                "lng": 30.1333
            },
            "Masku": {
                "lat": 60.5667,
                "lng": 22.1
            },
            "Kauniainen": {
                "lat": 60.2167,
                "lng": 24.7333
            },
            "Eurajoki": {
                "lat": 61.2,
                "lng": 21.7333
            },
            "Orivesi": {
                "lat": 61.675,
                "lng": 24.3583
            },
            "N\u00e4rpes": {
                "lat": 62.4667,
                "lng": 21.3333
            },
            "Hattula": {
                "lat": 61.05,
                "lng": 24.3667
            },
            "Keuruu": {
                "lat": 62.2583,
                "lng": 24.7083
            },
            "Muhos": {
                "lat": 64.8,
                "lng": 26.0
            },
            "Somero": {
                "lat": 60.6333,
                "lng": 23.5167
            },
            "Halikko": {
                "lat": 60.3972,
                "lng": 23.0778
            },
            "Karis": {
                "lat": 60.0708,
                "lng": 23.6625
            },
            "Sodankyl\u00e4": {
                "lat": 67.4167,
                "lng": 26.5833
            },
            "Karkkila": {
                "lat": 60.5333,
                "lng": 24.2167
            },
            "P\u00f6yty\u00e4": {
                "lat": 60.7167,
                "lng": 22.6
            },
            "Laitila": {
                "lat": 60.8833,
                "lng": 21.7
            },
            "Hanko": {
                "lat": 59.8283,
                "lng": 22.9658
            },
            "Hausj\u00e4rvi": {
                "lat": 60.7867,
                "lng": 25.025
            },
            "Kemij\u00e4rvi": {
                "lat": 66.7167,
                "lng": 27.4333
            }
        }
    },
    "CZ": {
        "name": "Czechia",
        "cities": {
            "Prague": {
                "lat": 50.0875,
                "lng": 14.4214
            },
            "Brno": {
                "lat": 49.1925,
                "lng": 16.6083
            },
            "Ostrava": {
                "lat": 49.8356,
                "lng": 18.2925
            },
            "Plze\u0148": {
                "lat": 49.7475,
                "lng": 13.3775
            },
            "Liberec": {
                "lat": 50.7667,
                "lng": 15.0667
            },
            "Olomouc": {
                "lat": 49.5939,
                "lng": 17.2508
            },
            "\u010cesk\u00e9 Bud\u011bjovice": {
                "lat": 48.9747,
                "lng": 14.4747
            },
            "Hradec Kr\u00e1lov\u00e9": {
                "lat": 50.2092,
                "lng": 15.8322
            },
            "Pardubice": {
                "lat": 50.0386,
                "lng": 15.7792
            },
            "\u00dast\u00ed nad Labem": {
                "lat": 50.6583,
                "lng": 14.0417
            },
            "Zl\u00edn": {
                "lat": 49.2331,
                "lng": 17.6669
            },
            "Hav\u00ed\u0159ov": {
                "lat": 49.7831,
                "lng": 18.4228
            },
            "Kladno": {
                "lat": 50.1431,
                "lng": 14.1053
            },
            "Most": {
                "lat": 50.5031,
                "lng": 13.6367
            },
            "Opava": {
                "lat": 49.9381,
                "lng": 17.9044
            },
            "Fr\u00fddek-M\u00edstek": {
                "lat": 49.6856,
                "lng": 18.3506
            },
            "Jihlava": {
                "lat": 49.4003,
                "lng": 15.5906
            },
            "Teplice": {
                "lat": 50.6444,
                "lng": 13.8319
            },
            "Karvin\u00e1": {
                "lat": 49.8542,
                "lng": 18.5428
            },
            "Karlovy Vary": {
                "lat": 50.2306,
                "lng": 12.8725
            },
            "D\u011b\u010d\u00edn": {
                "lat": 50.7736,
                "lng": 14.1961
            },
            "Chomutov": {
                "lat": 50.4611,
                "lng": 13.4167
            },
            "Jablonec nad Nisou": {
                "lat": 50.7244,
                "lng": 15.1681
            },
            "Mlad\u00e1 Boleslav": {
                "lat": 50.4125,
                "lng": 14.9044
            },
            "Prost\u011bjov": {
                "lat": 49.4722,
                "lng": 17.1106
            },
            "P\u0159erov": {
                "lat": 49.4556,
                "lng": 17.4511
            },
            "\u010cesk\u00e1 L\u00edpa": {
                "lat": 50.6886,
                "lng": 14.5386
            },
            "T\u0159eb\u00ed\u010d": {
                "lat": 49.215,
                "lng": 15.8817
            },
            "T\u0159inec": {
                "lat": 49.6778,
                "lng": 18.6728
            },
            "T\u00e1bor": {
                "lat": 49.4144,
                "lng": 14.6578
            },
            "Znojmo": {
                "lat": 48.8556,
                "lng": 16.0489
            },
            "Kol\u00edn": {
                "lat": 50.0281,
                "lng": 15.2006
            },
            "P\u0159\u00edbram": {
                "lat": 49.6883,
                "lng": 14.0092
            },
            "Cheb": {
                "lat": 50.0794,
                "lng": 12.3706
            },
            "P\u00edsek": {
                "lat": 49.3089,
                "lng": 14.1475
            },
            "Trutnov": {
                "lat": 50.5606,
                "lng": 15.9131
            },
            "Krom\u011b\u0159\u00ed\u017e": {
                "lat": 49.2989,
                "lng": 17.3931
            },
            "Orlov\u00e1": {
                "lat": 49.8453,
                "lng": 18.4303
            },
            "L\u00ed\u0161e\u0148": {
                "lat": 49.2075,
                "lng": 16.6861
            },
            "B\u0159evnov": {
                "lat": 50.0833,
                "lng": 14.3579
            },
            "Vset\u00edn": {
                "lat": 49.3386,
                "lng": 17.9961
            },
            "\u0160umperk": {
                "lat": 49.9653,
                "lng": 16.9706
            },
            "Uhersk\u00e9 Hradi\u0161t\u011b": {
                "lat": 49.0697,
                "lng": 17.4597
            },
            "Bystrc": {
                "lat": 49.2247,
                "lng": 16.5239
            },
            "B\u0159eclav": {
                "lat": 48.7589,
                "lng": 16.8819
            },
            "Hodon\u00edn": {
                "lat": 48.8489,
                "lng": 17.1325
            },
            "\u010cesk\u00fd T\u011b\u0161\u00edn": {
                "lat": 49.7461,
                "lng": 18.6261
            },
            "Chrudim": {
                "lat": 49.9511,
                "lng": 15.7956
            },
            "Havl\u00ed\u010dk\u016dv Brod": {
                "lat": 49.6078,
                "lng": 15.5808
            },
            "Litom\u011b\u0159ice": {
                "lat": 50.5342,
                "lng": 14.1328
            },
            "Nov\u00fd Ji\u010d\u00edn": {
                "lat": 49.5944,
                "lng": 18.0103
            },
            "Krnov": {
                "lat": 50.0906,
                "lng": 17.6986
            },
            "Litv\u00ednov": {
                "lat": 50.6008,
                "lng": 13.6111
            },
            "Vala\u0161sk\u00e9 Mezi\u0159\u00ed\u010d\u00ed": {
                "lat": 49.4717,
                "lng": 17.9711
            },
            "Strakonice": {
                "lat": 49.2614,
                "lng": 13.9025
            },
            "Klatovy": {
                "lat": 49.3953,
                "lng": 13.295
            },
            "Sokolov": {
                "lat": 50.1814,
                "lng": 12.6403
            },
            "Kop\u0159ivnice": {
                "lat": 49.5994,
                "lng": 18.1447
            },
            "Kutn\u00e1 Hora": {
                "lat": 49.9483,
                "lng": 15.2683
            },
            "Jind\u0159ich\u016dv Hradec": {
                "lat": 49.1442,
                "lng": 15.0031
            },
            "Beroun": {
                "lat": 49.9642,
                "lng": 14.0739
            },
            "Bohum\u00edn": {
                "lat": 49.9042,
                "lng": 18.3575
            },
            "\u017d\u010f\u00e1r nad S\u00e1zavou": {
                "lat": 49.5628,
                "lng": 15.9392
            },
            "Vy\u0161kov": {
                "lat": 49.2775,
                "lng": 16.9989
            },
            "M\u011bln\u00edk": {
                "lat": 50.3519,
                "lng": 14.475
            },
            "Blansko": {
                "lat": 49.3631,
                "lng": 16.6431
            },
            "N\u00e1chod": {
                "lat": 50.4169,
                "lng": 16.1631
            },
            "Brand\u00fds nad Labem-Star\u00e1 Boleslav": {
                "lat": 50.1894,
                "lng": 14.6672
            },
            "Jirkov": {
                "lat": 50.5003,
                "lng": 13.4419
            },
            "\u017datec": {
                "lat": 50.33,
                "lng": 13.5444
            },
            "Kralupy nad Vltavou": {
                "lat": 50.2408,
                "lng": 14.3067
            },
            "Kada\u0148": {
                "lat": 50.3761,
                "lng": 13.2714
            },
            "Louny": {
                "lat": 50.3572,
                "lng": 13.7969
            },
            "Hranice": {
                "lat": 49.5525,
                "lng": 17.7683
            },
            "Otrokovice": {
                "lat": 49.21,
                "lng": 17.5308
            },
            "Bene\u0161ov": {
                "lat": 49.7828,
                "lng": 14.6886
            },
            "\u0158\u00ed\u010dany": {
                "lat": 49.9919,
                "lng": 14.6556
            },
            "Slan\u00fd": {
                "lat": 50.2306,
                "lng": 14.0869
            },
            "Pelh\u0159imov": {
                "lat": 49.4314,
                "lng": 15.2233
            },
            "Uhersk\u00fd Brod": {
                "lat": 49.025,
                "lng": 17.6472
            },
            "Neratovice": {
                "lat": 50.2594,
                "lng": 14.5178
            },
            "Ji\u010d\u00edn": {
                "lat": 50.4367,
                "lng": 15.3517
            },
            "Ro\u017enov pod Radho\u0161t\u011bm": {
                "lat": 49.4586,
                "lng": 18.1431
            },
            "Svitavy": {
                "lat": 49.7556,
                "lng": 16.4694
            },
            "Ostrov": {
                "lat": 50.3083,
                "lng": 12.9478
            },
            "Rakovn\u00edk": {
                "lat": 50.1039,
                "lng": 13.7333
            },
            "Nymburk": {
                "lat": 50.1861,
                "lng": 15.0417
            },
            "Brunt\u00e1l": {
                "lat": 49.9883,
                "lng": 17.4647
            },
            "Dv\u016fr Kr\u00e1lov\u00e9 nad Labem": {
                "lat": 50.4331,
                "lng": 15.8122
            },
            "\u010cesk\u00e1 T\u0159ebov\u00e1": {
                "lat": 49.9019,
                "lng": 16.4472
            },
            "Pod\u011bbrady": {
                "lat": 50.1425,
                "lng": 15.1189
            },
            "Varnsdorf": {
                "lat": 50.9117,
                "lng": 14.6183
            },
            "B\u00edlina": {
                "lat": 50.5481,
                "lng": 13.7761
            },
            "Turnov": {
                "lat": 50.5872,
                "lng": 15.1569
            },
            "Kl\u00e1\u0161terec nad Oh\u0159\u00ed": {
                "lat": 50.3903,
                "lng": 13.1719
            },
            "Rokycany": {
                "lat": 49.7425,
                "lng": 13.5956
            },
            "\u00dast\u00ed nad Orlic\u00ed": {
                "lat": 49.9739,
                "lng": 16.3936
            },
            "Tachov": {
                "lat": 49.7953,
                "lng": 12.6331
            },
            "Mari\u00e1nsk\u00e9 L\u00e1zn\u011b": {
                "lat": 49.9647,
                "lng": 12.7011
            },
            "Milovice": {
                "lat": 50.2289,
                "lng": 14.8906
            },
            "Hlu\u010d\u00edn": {
                "lat": 49.8967,
                "lng": 18.1931
            },
            "Z\u00e1b\u0159eh": {
                "lat": 49.8825,
                "lng": 16.8722
            },
            "\u0160ternberk": {
                "lat": 49.7306,
                "lng": 17.2989
            },
            "\u010cesk\u00fd Krumlov": {
                "lat": 48.8111,
                "lng": 14.3153
            },
            "A\u0161": {
                "lat": 50.2239,
                "lng": 12.195
            },
            "Roudnice nad Labem": {
                "lat": 50.4236,
                "lng": 14.2539
            },
            "Chodov": {
                "lat": 50.2414,
                "lng": 12.7439
            },
            "Krupka": {
                "lat": 50.6844,
                "lng": 13.8583
            },
            "Jarom\u011b\u0159": {
                "lat": 50.3503,
                "lng": 15.9183
            },
            "\u010cel\u00e1kovice": {
                "lat": 50.1606,
                "lng": 14.75
            },
            "Vysok\u00e9 M\u00fdto": {
                "lat": 49.9533,
                "lng": 16.1617
            },
            "Vrchlab\u00ed": {
                "lat": 50.6278,
                "lng": 15.6103
            },
            "Boskovice": {
                "lat": 49.4875,
                "lng": 16.66
            },
            "Hole\u0161ov": {
                "lat": 49.3333,
                "lng": 17.5783
            },
            "Velk\u00e9 Mezi\u0159\u00ed\u010d\u00ed": {
                "lat": 49.3553,
                "lng": 16.0122
            },
            "Nov\u00fd Bor": {
                "lat": 50.7553,
                "lng": 14.5536
            },
            "Vla\u0161im": {
                "lat": 49.7064,
                "lng": 14.8989
            },
            "Humpolec": {
                "lat": 49.5417,
                "lng": 15.3572
            },
            "Ku\u0159im": {
                "lat": 49.2986,
                "lng": 16.5314
            },
            "Prachatice": {
                "lat": 49.0131,
                "lng": 13.9975
            },
            "Rychnov nad Kn\u011b\u017enou": {
                "lat": 50.1631,
                "lng": 16.2744
            },
            "Uni\u010dov": {
                "lat": 49.7708,
                "lng": 17.1214
            },
            "Doma\u017elice": {
                "lat": 49.4406,
                "lng": 12.9272
            },
            "Rumburk": {
                "lat": 50.9517,
                "lng": 14.5572
            },
            "Kyjov": {
                "lat": 49.0103,
                "lng": 17.1225
            },
            "Su\u0161ice": {
                "lat": 49.2311,
                "lng": 13.5203
            },
            "Fren\u0161t\u00e1t pod Radho\u0161t\u011bm": {
                "lat": 49.5483,
                "lng": 18.2108
            },
            "Jesen\u00edk": {
                "lat": 50.2297,
                "lng": 17.2047
            },
            "Vesel\u00ed nad Moravou": {
                "lat": 48.9536,
                "lng": 17.3764
            },
            "\u010c\u00e1slav": {
                "lat": 49.9117,
                "lng": 15.395
            },
            "Litomy\u0161l": {
                "lat": 49.8719,
                "lng": 16.3106
            },
            "Kr\u00e1l\u016dv Dv\u016dr": {
                "lat": 49.9467,
                "lng": 14.035
            },
            "Jesenice": {
                "lat": 49.9683,
                "lng": 14.5136
            },
            "Lys\u00e1 nad Labem": {
                "lat": 50.2014,
                "lng": 14.8328
            },
            "P\u0159elou\u010d": {
                "lat": 50.04,
                "lng": 15.5603
            },
            "Nov\u00e9 M\u011bsto na Morav\u011b": {
                "lat": 49.5614,
                "lng": 16.0742
            },
            "Fr\u00fddlant nad Ostravic\u00ed": {
                "lat": 49.5928,
                "lng": 18.3597
            },
            "Ivan\u010dice": {
                "lat": 49.1014,
                "lng": 16.3775
            },
            "Litovel": {
                "lat": 49.7011,
                "lng": 17.0761
            },
            "Lan\u0161kroun": {
                "lat": 49.9122,
                "lng": 16.6122
            },
            "Moravsk\u00e1 T\u0159ebov\u00e1": {
                "lat": 49.7581,
                "lng": 16.6642
            },
            "Hlinsko": {
                "lat": 49.7622,
                "lng": 15.9075
            },
            "Mohelnice": {
                "lat": 49.7769,
                "lng": 16.9194
            },
            "Nov\u00e9 M\u011bsto nad Metuj\u00ed": {
                "lat": 50.3447,
                "lng": 16.1517
            },
            "Stud\u00e9nka": {
                "lat": 49.7233,
                "lng": 18.0786
            },
            "Ti\u0161nov": {
                "lat": 49.3486,
                "lng": 16.4244
            },
            "Hostivice": {
                "lat": 50.0814,
                "lng": 14.2525
            },
            "Chot\u011bbo\u0159": {
                "lat": 49.7208,
                "lng": 15.6703
            },
            "Nov\u00e1 Paka": {
                "lat": 50.4897,
                "lng": 15.5136
            },
            "Roztoky": {
                "lat": 50.1614,
                "lng": 14.3869
            },
            "Mnichovo Hradi\u0161t\u011b": {
                "lat": 50.5214,
                "lng": 14.9736
            },
            "Poli\u010dka": {
                "lat": 49.715,
                "lng": 16.2675
            },
            "Dob\u0159\u00ed\u0161": {
                "lat": 49.7811,
                "lng": 14.1672
            },
            "Lovosice": {
                "lat": 50.515,
                "lng": 14.0511
            },
            "Choce\u0148": {
                "lat": 50.0017,
                "lng": 16.2231
            },
            "Duchcov": {
                "lat": 50.6039,
                "lng": 13.7464
            },
            "\u0160t\u011bt\u00ed": {
                "lat": 50.4531,
                "lng": 14.3742
            },
            "Ho\u0159ice": {
                "lat": 50.3675,
                "lng": 15.6314
            }
        }
    },
    "NZ": {
        "name": "New Zealand",
        "cities": {
            "Auckland": {
                "lat": -36.8406,
                "lng": 174.74
            },
            "Christchurch": {
                "lat": -43.531,
                "lng": 172.6365
            },
            "Manukau City": {
                "lat": -37.0,
                "lng": 174.885
            },
            "Wellington": {
                "lat": -41.2889,
                "lng": 174.7772
            },
            "Northcote": {
                "lat": -36.8019,
                "lng": 174.7494
            },
            "Hamilton": {
                "lat": -37.7833,
                "lng": 175.2833
            },
            "Tauranga": {
                "lat": -37.6833,
                "lng": 176.1667
            },
            "Lower Hutt": {
                "lat": -41.2167,
                "lng": 174.9167
            },
            "Dunedin": {
                "lat": -45.8742,
                "lng": 170.5036
            },
            "Palmerston North": {
                "lat": -40.355,
                "lng": 175.6117
            },
            "Napier": {
                "lat": -39.4903,
                "lng": 176.9178
            },
            "New Plymouth": {
                "lat": -39.0578,
                "lng": 174.0742
            },
            "Porirua": {
                "lat": -41.1333,
                "lng": 174.85
            },
            "Rotorua": {
                "lat": -38.1378,
                "lng": 176.2514
            },
            "Whangarei": {
                "lat": -35.725,
                "lng": 174.3236
            },
            "Invercargill": {
                "lat": -46.4131,
                "lng": 168.3475
            },
            "Nelson": {
                "lat": -41.2708,
                "lng": 173.2839
            },
            "Upper Hutt": {
                "lat": -41.1333,
                "lng": 175.05
            },
            "Whanganui": {
                "lat": -39.9325,
                "lng": 175.0519
            },
            "Gisborne": {
                "lat": -38.6625,
                "lng": 178.0178
            },
            "Paraparaumu": {
                "lat": -40.9144,
                "lng": 175.0056
            },
            "Timaru": {
                "lat": -44.3931,
                "lng": 171.2508
            },
            "Blenheim": {
                "lat": -41.514,
                "lng": 173.96
            },
            "Taupo": {
                "lat": -38.6875,
                "lng": 176.0694
            },
            "Masterton": {
                "lat": -40.97,
                "lng": 175.65
            },
            "Cambridge": {
                "lat": -37.8833,
                "lng": 175.4667
            },
            "Feilding": {
                "lat": -40.2167,
                "lng": 175.5667
            },
            "Ashton": {
                "lat": -44.0333,
                "lng": 171.7667
            },
            "Levin": {
                "lat": -40.6219,
                "lng": 175.2867
            },
            "Macetown": {
                "lat": -44.8667,
                "lng": 168.8333
            },
            "Rolleston": {
                "lat": -43.5833,
                "lng": 172.3833
            },
            "Whakatane": {
                "lat": -37.96,
                "lng": 176.98
            },
            "Richmond": {
                "lat": -41.3333,
                "lng": 173.1833
            },
            "Havelock North": {
                "lat": -39.6667,
                "lng": 176.8833
            },
            "Tokoroa": {
                "lat": -38.2167,
                "lng": 175.8667
            },
            "Mosgiel": {
                "lat": -45.875,
                "lng": 170.3486
            },
            "Te Awamutu": {
                "lat": -38.0167,
                "lng": 175.3167
            },
            "Waikanae": {
                "lat": -40.875,
                "lng": 175.0639
            },
            "Hawera": {
                "lat": -39.5933,
                "lng": 174.2783
            },
            "Glencoe": {
                "lat": -46.1833,
                "lng": 168.6833
            },
            "Waiuku": {
                "lat": -37.249,
                "lng": 174.73
            },
            "Paraparaumu Beach": {
                "lat": -40.8938,
                "lng": 174.9794
            },
            "Wanaka": {
                "lat": -44.7,
                "lng": 169.15
            },
            "Greymouth": {
                "lat": -42.45,
                "lng": 171.2075
            },
            "Thames": {
                "lat": -37.1383,
                "lng": 175.5375
            },
            "Kerikeri": {
                "lat": -35.2244,
                "lng": 173.9514
            },
            "Kaitaia": {
                "lat": -35.1125,
                "lng": 173.2628
            },
            "Stratford": {
                "lat": -39.3333,
                "lng": 174.2833
            },
            "Westport": {
                "lat": -41.7581,
                "lng": 171.6022
            },
            "Turangi": {
                "lat": -38.9889,
                "lng": 175.8083
            },
            "Hokitika": {
                "lat": -42.7156,
                "lng": 170.9681
            },
            "Te Anau": {
                "lat": -45.4167,
                "lng": 167.7167
            },
            "Kaikoura": {
                "lat": -42.4,
                "lng": 173.68
            },
            "Pukekohe East": {
                "lat": -37.2,
                "lng": 174.9
            },
            "Waitakere": {
                "lat": -36.85,
                "lng": 174.543
            },
            "Waitangi": {
                "lat": -43.9514,
                "lng": -176.5611
            },
            "Halfmoon Bay": {
                "lat": -46.9,
                "lng": 168.1333
            },
            "Kairaki": {
                "lat": -43.386,
                "lng": 172.703
            }
        }
    },
    "DO": {
        "name": "Dominican Republic",
        "cities": {
            "Santiago": {
                "lat": 19.4572,
                "lng": -70.6889
            },
            "Santo Domingo": {
                "lat": 18.4667,
                "lng": -69.95
            },
            "Santo Domingo Este": {
                "lat": 18.4855,
                "lng": -69.8734
            },
            "Puerto Plata": {
                "lat": 19.8,
                "lng": -70.6833
            },
            "Los Alcarrizos": {
                "lat": 18.5167,
                "lng": -70.0167
            },
            "Hig\u00fcey": {
                "lat": 18.6167,
                "lng": -68.7
            },
            "San Crist\u00f3bal": {
                "lat": 18.4167,
                "lng": -70.1056
            },
            "La Vega": {
                "lat": 19.22,
                "lng": -70.53
            },
            "San Pedro de Macor\u00eds": {
                "lat": 18.45,
                "lng": -69.3
            },
            "San Francisco de Macor\u00eds": {
                "lat": 19.3,
                "lng": -70.25
            },
            "B\u00e1varo": {
                "lat": 18.7167,
                "lng": -68.45
            },
            "Ban\u00ed": {
                "lat": 18.29,
                "lng": -70.33
            },
            "Boca Chica": {
                "lat": 18.4539,
                "lng": -69.6064
            },
            "La Romana": {
                "lat": 18.43,
                "lng": -68.97
            },
            "Pantanal": {
                "lat": 18.5333,
                "lng": -68.3667
            },
            "San Juan": {
                "lat": 18.81,
                "lng": -71.23
            },
            "Gurabo al Medio": {
                "lat": 19.4739,
                "lng": -70.6727
            },
            "Bonao": {
                "lat": 18.95,
                "lng": -70.41
            },
            "Bajos de Haina": {
                "lat": 18.4167,
                "lng": -70.0333
            },
            "Los Minas": {
                "lat": 18.5,
                "lng": -69.9833
            },
            "Moca": {
                "lat": 19.3833,
                "lng": -70.5167
            },
            "Azua": {
                "lat": 18.46,
                "lng": -70.74
            },
            "Villa Altagracia": {
                "lat": 18.6667,
                "lng": -70.1667
            },
            "Barahona": {
                "lat": 18.2,
                "lng": -71.1
            },
            "Nagua": {
                "lat": 19.38,
                "lng": -69.85
            },
            "Mao": {
                "lat": 19.5667,
                "lng": -71.0833
            },
            "Cotu\u00ed": {
                "lat": 19.06,
                "lng": -70.15
            },
            "Pedro Brand": {
                "lat": 18.5667,
                "lng": -70.0911
            },
            "Esperanza": {
                "lat": 19.58,
                "lng": -70.99
            },
            "Jarabacoa": {
                "lat": 19.1167,
                "lng": -70.6333
            },
            "El Seibo": {
                "lat": 18.763,
                "lng": -69.042
            },
            "Hato Mayor": {
                "lat": 18.7667,
                "lng": -69.25
            },
            "Consuelito": {
                "lat": 18.56,
                "lng": -69.3
            },
            "Guerra": {
                "lat": 18.55,
                "lng": -69.7
            },
            "Constanza": {
                "lat": 18.91,
                "lng": -70.75
            },
            "Saman\u00e1": {
                "lat": 19.2,
                "lng": -69.3167
            },
            "Monte Plata": {
                "lat": 18.81,
                "lng": -69.79
            },
            "San Luis": {
                "lat": 18.55,
                "lng": -69.7
            },
            "Yamas\u00e1": {
                "lat": 18.7667,
                "lng": -70.0167
            },
            "Tamboril": {
                "lat": 19.48,
                "lng": -70.6
            },
            "Sos\u00faa": {
                "lat": 19.75,
                "lng": -70.52
            },
            "La Caleta": {
                "lat": 18.4639,
                "lng": -69.6806
            },
            "Pu\u00f1al": {
                "lat": 19.4,
                "lng": -70.6167
            },
            "Las Matas de Farf\u00e1n": {
                "lat": 18.87,
                "lng": -71.52
            },
            "Comendador": {
                "lat": 18.8833,
                "lng": -71.7
            },
            "La Mata": {
                "lat": 19.1,
                "lng": -70.1681
            },
            "Yaguate": {
                "lat": 18.33,
                "lng": -70.18
            },
            "Villa Bison\u00f3": {
                "lat": 19.56,
                "lng": -70.87
            },
            "Padre Las Casas": {
                "lat": 18.7333,
                "lng": -70.9333
            },
            "Salcedo": {
                "lat": 19.378,
                "lng": -70.418
            },
            "San Jos\u00e9 de Ocoa": {
                "lat": 18.55,
                "lng": -70.5
            },
            "San Jos\u00e9 de Las Matas": {
                "lat": 19.33,
                "lng": -70.93
            },
            "Guayacanes": {
                "lat": 18.4533,
                "lng": -69.45
            },
            "Gaspar Hern\u00e1ndez": {
                "lat": 19.62,
                "lng": -70.28
            },
            "Villa Gonz\u00e1lez": {
                "lat": 19.5333,
                "lng": -70.7833
            },
            "Neiba": {
                "lat": 18.49,
                "lng": -71.42
            },
            "San Lorenzo de Guayub\u00edn": {
                "lat": 19.62,
                "lng": -71.33
            },
            "Matanzas": {
                "lat": 18.2428,
                "lng": -70.4192
            },
            "Sabaneta": {
                "lat": 19.4833,
                "lng": -71.35
            },
            "R\u00edo Verde Arriba": {
                "lat": 19.32,
                "lng": -70.57
            },
            "Villa Riva": {
                "lat": 19.1667,
                "lng": -69.9
            },
            "Bayaguana": {
                "lat": 18.75,
                "lng": -69.6333
            },
            "Sabana Grande de Boy\u00e1": {
                "lat": 18.95,
                "lng": -69.8
            },
            "Cambita Garabitos": {
                "lat": 18.45,
                "lng": -70.2
            },
            "San Gregorio de Nigua": {
                "lat": 18.3833,
                "lng": -70.0833
            },
            "Jima Abajo": {
                "lat": 19.13,
                "lng": -70.38
            },
            "Dajab\u00f3n": {
                "lat": 19.5667,
                "lng": -71.71
            },
            "Tenares": {
                "lat": 19.37,
                "lng": -70.35
            },
            "Nizao": {
                "lat": 18.2441,
                "lng": -70.1984
            },
            "Tamayo": {
                "lat": 18.4,
                "lng": -71.2
            },
            "Licey al Medio": {
                "lat": 19.43,
                "lng": -70.62
            },
            "Quisqueya": {
                "lat": 18.5542,
                "lng": -69.4056
            },
            "Villa Tapia": {
                "lat": 19.3,
                "lng": -70.42
            },
            "Monte Cristi": {
                "lat": 19.8667,
                "lng": -71.65
            },
            "El Factor": {
                "lat": 19.32,
                "lng": -69.88
            },
            "Cabrera": {
                "lat": 19.63,
                "lng": -69.9
            },
            "Laguna Salada": {
                "lat": 19.65,
                "lng": -71.0833
            },
            "Y\u00e1sica Arriba": {
                "lat": 19.6333,
                "lng": -70.6
            },
            "Los Llanos": {
                "lat": 18.6167,
                "lng": -69.5
            },
            "Fantino": {
                "lat": 19.12,
                "lng": -70.3
            },
            "Imbert": {
                "lat": 19.75,
                "lng": -70.83
            },
            "San Rafael del Yuma": {
                "lat": 18.4333,
                "lng": -68.6667
            },
            "Vicente Noble": {
                "lat": 18.3833,
                "lng": -71.1833
            },
            "San V\u00edctor Abajo": {
                "lat": 19.45,
                "lng": -70.53
            },
            "Piedra Blanca": {
                "lat": 18.8438,
                "lng": -70.3157
            },
            "Esperalvillo": {
                "lat": 18.82,
                "lng": -70.03
            },
            "S\u00e1nchez": {
                "lat": 19.2282,
                "lng": -69.6128
            },
            "Cercado Abajo": {
                "lat": 18.73,
                "lng": -71.52
            },
            "Miches": {
                "lat": 18.98,
                "lng": -69.05
            },
            "Jicom\u00e9": {
                "lat": 19.65,
                "lng": -70.95
            },
            "Luper\u00f3n": {
                "lat": 19.9,
                "lng": -70.95
            },
            "R\u00edo Grande": {
                "lat": 19.73,
                "lng": -70.8
            },
            "Altamira": {
                "lat": 19.6667,
                "lng": -70.8333
            },
            "Las Terrenas": {
                "lat": 19.32,
                "lng": -69.53
            },
            "Maim\u00f3n": {
                "lat": 18.77,
                "lng": -70.33
            },
            "Pimentel": {
                "lat": 19.1833,
                "lng": -70.1
            },
            "Cenov\u00ed": {
                "lat": 19.25,
                "lng": -70.37
            },
            "T\u00e1bara Arriba": {
                "lat": 18.5694,
                "lng": -70.8792
            },
            "Yayas de Viajama": {
                "lat": 18.6,
                "lng": -70.92
            },
            "Villa Isabela": {
                "lat": 19.82,
                "lng": -71.05
            },
            "Santo Tom\u00e1s de J\u00e1nico": {
                "lat": 19.4,
                "lng": -70.8
            },
            "Guaymate": {
                "lat": 18.58,
                "lng": -68.98
            },
            "Ram\u00f3n Santana": {
                "lat": 18.55,
                "lng": -69.1833
            },
            "Cayetano Germos\u00e9n": {
                "lat": 19.33,
                "lng": -70.48
            },
            "Sabana de La Mar": {
                "lat": 19.07,
                "lng": -69.39
            },
            "Galv\u00e1n": {
                "lat": 18.5039,
                "lng": -71.34
            },
            "Castillo": {
                "lat": 19.22,
                "lng": -70.03
            },
            "Loma de Cabrera": {
                "lat": 19.422,
                "lng": -71.615
            },
            "Sabana Grande de Palenque": {
                "lat": 18.2667,
                "lng": -70.15
            },
            "Jamao al Norte": {
                "lat": 19.65,
                "lng": -70.6167
            },
            "R\u00edo San Juan": {
                "lat": 19.64,
                "lng": -70.08
            },
            "Casta\u00f1uelas": {
                "lat": 19.7,
                "lng": -71.5
            },
            "Cabral": {
                "lat": 18.25,
                "lng": -71.2167
            },
            "Las Gu\u00e1ranas": {
                "lat": 19.2,
                "lng": -70.22
            },
            "Cabarete": {
                "lat": 19.7511,
                "lng": -70.4083
            },
            "Para\u00edso": {
                "lat": 18.0,
                "lng": -71.1667
            },
            "Villa V\u00e1squez": {
                "lat": 19.74,
                "lng": -71.45
            },
            "Arenoso": {
                "lat": 19.18,
                "lng": -69.85
            },
            "Pedernales": {
                "lat": 18.0333,
                "lng": -71.75
            },
            "Duverg\u00e9": {
                "lat": 18.38,
                "lng": -71.3633
            },
            "Sabana Iglesia": {
                "lat": 19.33,
                "lng": -70.75
            },
            "Juan de Herrera": {
                "lat": 18.8667,
                "lng": -71.2333
            },
            "Los Hidalgos": {
                "lat": 19.73,
                "lng": -71.03
            },
            "Cevicos": {
                "lat": 19.0,
                "lng": -69.98
            },
            "Los Bellosos": {
                "lat": 19.8333,
                "lng": -71.0167
            },
            "Vallejuelo": {
                "lat": 18.65,
                "lng": -71.33
            },
            "Baitoa": {
                "lat": 19.32,
                "lng": -70.7
            },
            "Monci\u00f3n": {
                "lat": 19.4167,
                "lng": -71.1667
            },
            "Jiman\u00ed": {
                "lat": 18.49,
                "lng": -71.85
            },
            "Sabana Yegua": {
                "lat": 18.72,
                "lng": -71.02
            },
            "Las Charcas": {
                "lat": 18.45,
                "lng": -70.62
            },
            "Pueblo Viejo": {
                "lat": 18.4,
                "lng": -70.7667
            },
            "Los Alm\u00e1cigos": {
                "lat": 19.4083,
                "lng": -71.4417
            },
            "Villa Jaragua": {
                "lat": 18.48,
                "lng": -71.5
            },
            "Las Matas de Santa Cruz": {
                "lat": 19.67,
                "lng": -71.5
            },
            "Sabaneta de Y\u00e1sica": {
                "lat": 19.6667,
                "lng": -70.3833
            },
            "Rancho Arriba": {
                "lat": 18.7147,
                "lng": -70.4618
            },
            "Enriquillo": {
                "lat": 17.9,
                "lng": -71.2333
            },
            "Los R\u00edos": {
                "lat": 18.5219,
                "lng": -71.59
            },
            "Sabana Larga": {
                "lat": 18.585,
                "lng": -70.4982
            },
            "Bohech\u00edo": {
                "lat": 18.77,
                "lng": -70.98
            },
            "El Cacao": {
                "lat": 18.5264,
                "lng": -70.2954
            },
            "Maizal": {
                "lat": 19.65,
                "lng": -71.03
            },
            "Pepillo Salcedo": {
                "lat": 19.7,
                "lng": -71.75
            },
            "El Valle": {
                "lat": 18.9764,
                "lng": -69.3792
            },
            "Peralta": {
                "lat": 18.5167,
                "lng": -70.7833
            }
        }
    },
    "PT": {
        "name": "Portugal",
        "cities": {
            "Porto": {
                "lat": 41.1621,
                "lng": -8.622
            },
            "Lisbon": {
                "lat": 38.7253,
                "lng": -9.15
            },
            "Aves": {
                "lat": 41.44,
                "lng": -8.3
            },
            "Sintra": {
                "lat": 38.7992,
                "lng": -9.3883
            },
            "Vila Nova de Gaia": {
                "lat": 41.1333,
                "lng": -8.6167
            },
            "Cascais": {
                "lat": 38.7,
                "lng": -9.4167
            },
            "Loures": {
                "lat": 38.8333,
                "lng": -9.1667
            },
            "Seixal": {
                "lat": 38.65,
                "lng": -9.1
            },
            "Braga": {
                "lat": 41.5503,
                "lng": -8.42
            },
            "Almada": {
                "lat": 38.6803,
                "lng": -9.1583
            },
            "Matosinhos": {
                "lat": 41.1833,
                "lng": -8.7
            },
            "Amadora": {
                "lat": 38.7333,
                "lng": -9.2333
            },
            "Oeiras": {
                "lat": 38.6833,
                "lng": -9.3167
            },
            "Gondomar": {
                "lat": 41.15,
                "lng": -8.5333
            },
            "Guimar\u00e3es": {
                "lat": 41.45,
                "lng": -8.3
            },
            "Odivelas": {
                "lat": 38.7903,
                "lng": -9.1797
            },
            "Coimbra": {
                "lat": 40.2028,
                "lng": -8.4139
            },
            "Vila Franca de Xira": {
                "lat": 38.95,
                "lng": -8.9833
            },
            "Maia": {
                "lat": 41.2333,
                "lng": -8.6167
            },
            "Famalic\u00e3o": {
                "lat": 41.4,
                "lng": -8.5167
            },
            "Leiria": {
                "lat": 39.75,
                "lng": -8.8
            },
            "Barcelos": {
                "lat": 41.5167,
                "lng": -8.6167
            },
            "Viseu": {
                "lat": 40.6667,
                "lng": -7.9167
            },
            "Valongo": {
                "lat": 41.1833,
                "lng": -8.5
            },
            "Viana do Castelo": {
                "lat": 41.7,
                "lng": -8.8333
            },
            "Paredes": {
                "lat": 41.2064,
                "lng": -8.3333
            },
            "Vila do Conde": {
                "lat": 41.3528,
                "lng": -8.7444
            },
            "Torres Vedras": {
                "lat": 39.0833,
                "lng": -9.2667
            },
            "Aveiro": {
                "lat": 40.6333,
                "lng": -8.65
            },
            "Barreiro": {
                "lat": 38.6667,
                "lng": -9.0667
            },
            "Queluz": {
                "lat": 38.7514,
                "lng": -9.2664
            },
            "Mafra": {
                "lat": 38.9411,
                "lng": -9.3319
            },
            "Penafiel": {
                "lat": 41.206,
                "lng": -8.284
            },
            "Santo Tirso": {
                "lat": 41.3333,
                "lng": -8.4667
            },
            "Loul\u00e9": {
                "lat": 37.15,
                "lng": -8.0
            },
            "Ponta Delgada": {
                "lat": 37.7411,
                "lng": -25.6806
            },
            "Moita": {
                "lat": 38.65,
                "lng": -8.9833
            },
            "Faro": {
                "lat": 37.0161,
                "lng": -7.935
            },
            "P\u00f3voa de Varzim": {
                "lat": 41.38,
                "lng": -8.7608
            },
            "Palmela": {
                "lat": 38.5667,
                "lng": -8.9
            },
            "Santar\u00e9m": {
                "lat": 39.2339,
                "lng": -8.6861
            },
            "Figueira da Foz": {
                "lat": 40.15,
                "lng": -8.85
            },
            "Felgueiras": {
                "lat": 41.3667,
                "lng": -8.2
            },
            "Pa\u00e7os de Ferreira": {
                "lat": 41.2667,
                "lng": -8.4
            },
            "Amarante": {
                "lat": 41.2703,
                "lng": -8.0808
            },
            "Castelo Branco": {
                "lat": 39.8167,
                "lng": -7.5
            },
            "Portim\u00e3o": {
                "lat": 37.1369,
                "lng": -8.5383
            },
            "Ovar": {
                "lat": 40.8667,
                "lng": -8.6333
            },
            "Pombal": {
                "lat": 39.9161,
                "lng": -8.6281
            },
            "Alcoba\u00e7a": {
                "lat": 39.5528,
                "lng": -8.9778
            },
            "\u00c9vora": {
                "lat": 38.5667,
                "lng": -7.9
            },
            "Marco de Canavezes": {
                "lat": 41.1833,
                "lng": -8.15
            },
            "Vila Real": {
                "lat": 41.2958,
                "lng": -7.7461
            },
            "Covilh\u00e3": {
                "lat": 40.2833,
                "lng": -7.5
            },
            "Caldas da Rainha": {
                "lat": 39.4072,
                "lng": -9.1358
            },
            "Montijo": {
                "lat": 38.6833,
                "lng": -8.9
            },
            "Rio Tinto": {
                "lat": 41.178,
                "lng": -8.56
            },
            "Fafe": {
                "lat": 41.45,
                "lng": -8.1667
            },
            "Sesimbra": {
                "lat": 38.4437,
                "lng": -9.0996
            },
            "Amora": {
                "lat": 38.623,
                "lng": -9.12
            },
            "Vila Verde": {
                "lat": 41.65,
                "lng": -8.4333
            },
            "\u00c1gueda": {
                "lat": 40.5744,
                "lng": -8.4481
            },
            "Vale de Cavalos": {
                "lat": 38.65,
                "lng": -9.15
            },
            "Lousada": {
                "lat": 41.3,
                "lng": -8.24
            },
            "Rio de Mouro": {
                "lat": 38.784,
                "lng": -9.322
            },
            "Corroios": {
                "lat": 38.65,
                "lng": -9.15
            },
            "Our\u00e9m": {
                "lat": 39.65,
                "lng": -8.5667
            },
            "Olh\u00e3o": {
                "lat": 37.026,
                "lng": -7.841
            },
            "Albufeira": {
                "lat": 37.0897,
                "lng": -8.2458
            },
            "Ponte de Lima": {
                "lat": 41.7667,
                "lng": -8.5667
            },
            "Alenquer": {
                "lat": 39.05,
                "lng": -9.0167
            },
            "Santa Cruz": {
                "lat": 32.6833,
                "lng": -16.8
            },
            "Guarda": {
                "lat": 40.5333,
                "lng": -7.3333
            },
            "Chaves": {
                "lat": 41.7406,
                "lng": -7.4714
            },
            "Tomar": {
                "lat": 39.6,
                "lng": -8.4167
            },
            "Abrantes": {
                "lat": 39.4633,
                "lng": -8.1975
            },
            "Marinha Grande": {
                "lat": 39.75,
                "lng": -8.9333
            },
            "Trofa": {
                "lat": 41.35,
                "lng": -8.55
            },
            "Mafamude": {
                "lat": 41.135,
                "lng": -8.617
            },
            "Ermezinde": {
                "lat": 41.217,
                "lng": -8.55
            },
            "Silves": {
                "lat": 37.1869,
                "lng": -8.4389
            },
            "Torres Novas": {
                "lat": 39.4667,
                "lng": -8.5333
            },
            "Cantanhede": {
                "lat": 40.35,
                "lng": -8.5833
            },
            "Beja": {
                "lat": 38.0333,
                "lng": -7.8833
            },
            "Agualva": {
                "lat": 38.77,
                "lng": -9.2988
            },
            "C\u00e2mara de Lobos": {
                "lat": 32.6953,
                "lng": -16.9781
            },
            "Angra do Hero\u00edsmo": {
                "lat": 38.6556,
                "lng": -27.2194
            },
            "Bragan\u00e7a": {
                "lat": 41.8067,
                "lng": -6.7589
            },
            "Esposende": {
                "lat": 41.5333,
                "lng": -8.7833
            },
            "Ribeira Grande": {
                "lat": 37.8225,
                "lng": -25.5181
            },
            "\u00cdlhavo": {
                "lat": 40.6,
                "lng": -8.6667
            },
            "Lagos": {
                "lat": 37.1028,
                "lng": -8.6728
            },
            "Espinho": {
                "lat": 41.0,
                "lng": -8.6333
            },
            "Charneca": {
                "lat": 38.63,
                "lng": -9.2
            },
            "Santiago do Cac\u00e9m": {
                "lat": 38.0167,
                "lng": -8.7
            },
            "Perafita": {
                "lat": 41.224,
                "lng": -8.698
            },
            "Funchal": {
                "lat": 32.65,
                "lng": -16.9167
            },
            "P\u00f3voa de Santa Iria": {
                "lat": 38.862,
                "lng": -9.069
            },
            "Fund\u00e3o": {
                "lat": 40.1333,
                "lng": -7.5
            },
            "Anadia": {
                "lat": 40.4408,
                "lng": -8.435
            },
            "Benavente": {
                "lat": 38.9667,
                "lng": -8.8
            },
            "Senhora da Hora": {
                "lat": 41.186,
                "lng": -8.651
            },
            "Tondela": {
                "lat": 40.5167,
                "lng": -8.0833
            },
            "Arrentela": {
                "lat": 38.627,
                "lng": -9.103
            },
            "Massam\u00e1": {
                "lat": 38.757,
                "lng": -9.275
            },
            "\u00c1guas Santas": {
                "lat": 41.209,
                "lng": -8.589
            },
            "Estarreja": {
                "lat": 40.75,
                "lng": -8.5667
            },
            "Lamego": {
                "lat": 41.1008,
                "lng": -7.81
            },
            "Peniche": {
                "lat": 39.35,
                "lng": -9.3667
            },
            "Estoril": {
                "lat": 38.7042,
                "lng": -9.3983
            },
            "Montemor-o-Velho": {
                "lat": 40.1667,
                "lng": -8.6833
            },
            "Tavira": {
                "lat": 37.1167,
                "lng": -7.65
            },
            "Odemira": {
                "lat": 37.5833,
                "lng": -8.6333
            },
            "Lourinh\u00e3": {
                "lat": 39.2333,
                "lng": -9.3
            },
            "Albergaria-a-Velha": {
                "lat": 40.6933,
                "lng": -8.4811
            },
            "Pinhal Novo": {
                "lat": 38.631,
                "lng": -8.914
            },
            "Portalegre": {
                "lat": 39.3167,
                "lng": -7.4167
            },
            "Cartaxo": {
                "lat": 39.15,
                "lng": -8.7833
            },
            "Porto de M\u00f3s": {
                "lat": 39.6017,
                "lng": -8.8175
            },
            "Pontinha": {
                "lat": 38.7769,
                "lng": -9.2039
            },
            "Mirandela": {
                "lat": 41.4853,
                "lng": -7.1817
            },
            "Vizela": {
                "lat": 41.3667,
                "lng": -8.2667
            },
            "Almeirim": {
                "lat": 39.2,
                "lng": -8.6167
            },
            "Cani\u00e7o": {
                "lat": 32.651,
                "lng": -16.841
            },
            "S\u00e3o Mamede de Infesta": {
                "lat": 41.2,
                "lng": -8.6
            },
            "F\u00e2nzeres": {
                "lat": 41.1667,
                "lng": -8.5333
            },
            "Oliveira do Bairro": {
                "lat": 40.5167,
                "lng": -8.5
            },
            "Lagoa": {
                "lat": 37.7486,
                "lng": -25.5403
            },
            "Vale de Cambra": {
                "lat": 40.85,
                "lng": -8.4
            },
            "Vagos": {
                "lat": 40.55,
                "lng": -8.6833
            },
            "Arcos de Valdevez": {
                "lat": 41.8472,
                "lng": -8.4186
            },
            "Arouca": {
                "lat": 40.9167,
                "lng": -8.25
            },
            "Lordelo do Ouro": {
                "lat": 41.16,
                "lng": -8.658
            },
            "Salvaterra de Magos": {
                "lat": 39.0167,
                "lng": -8.7833
            },
            "Meixedo": {
                "lat": 41.807,
                "lng": -6.754
            },
            "P\u00f3voa de Lanhoso": {
                "lat": 41.5667,
                "lng": -8.2667
            },
            "Machico": {
                "lat": 32.7,
                "lng": -16.7667
            },
            "Azambuja": {
                "lat": 39.0667,
                "lng": -8.8667
            },
            "Seia": {
                "lat": 40.4167,
                "lng": -7.7
            },
            "S\u00e3o Jo\u00e3o da Madeira": {
                "lat": 40.8972,
                "lng": -8.4889
            },
            "Bougado": {
                "lat": 41.337,
                "lng": -8.559
            },
            "Cac\u00e9m": {
                "lat": 38.7704,
                "lng": -9.3081
            },
            "Vialonga": {
                "lat": 38.874,
                "lng": -9.082
            },
            "Rio Maior": {
                "lat": 39.3333,
                "lng": -8.9333
            },
            "Belas": {
                "lat": 38.7772,
                "lng": -9.2608
            },
            "Praia da Vit\u00f3ria": {
                "lat": 38.7333,
                "lng": -27.0664
            },
            "Laranjeira": {
                "lat": 38.657,
                "lng": -9.15
            },
            "Oliveira do Hospital": {
                "lat": 40.3589,
                "lng": -7.8625
            },
            "Ul": {
                "lat": 40.84,
                "lng": -8.475
            },
            "Oliveira de Azemeis": {
                "lat": 40.84,
                "lng": -8.475
            },
            "Elvas": {
                "lat": 38.8667,
                "lng": -7.15
            },
            "Bai\u00e3o": {
                "lat": 41.1667,
                "lng": -8.0333
            },
            "Costas de C\u00e3o": {
                "lat": 38.664,
                "lng": -9.2
            },
            "Caparica": {
                "lat": 38.664,
                "lng": -9.2
            },
            "Mealhada": {
                "lat": 40.3833,
                "lng": -8.45
            },
            "Cinf\u00e3es": {
                "lat": 41.1,
                "lng": -8.1333
            },
            "Entroncamento": {
                "lat": 39.4653,
                "lng": -8.4681
            },
            "Celorico de Basto": {
                "lat": 41.3833,
                "lng": -8.0
            },
            "Alfena": {
                "lat": 41.238,
                "lng": -8.525
            },
            "Coruche": {
                "lat": 38.9569,
                "lng": -8.5269
            },
            "Piedade": {
                "lat": 38.674,
                "lng": -9.156
            },
            "Soure": {
                "lat": 40.05,
                "lng": -8.6333
            },
            "Mon\u00e7\u00e3o": {
                "lat": 42.0764,
                "lng": -8.4819
            },
            "Vila Real de Santo Ant\u00f3nio": {
                "lat": 37.1939,
                "lng": -7.4158
            },
            "Amares": {
                "lat": 41.6167,
                "lng": -8.3333
            },
            "Camarate": {
                "lat": 38.798,
                "lng": -9.1339
            },
            "Sacav\u00e9m": {
                "lat": 38.7944,
                "lng": -9.1053
            },
            "Mangualde": {
                "lat": 40.6042,
                "lng": -7.7611
            },
            "Santa Iria da Az\u00f3ia": {
                "lat": 38.8464,
                "lng": -9.0881
            },
            "S\u00e3o Tiago de Custoias": {
                "lat": 41.2,
                "lng": -8.633
            },
            "Parede": {
                "lat": 38.6933,
                "lng": -9.3569
            },
            "Lous\u00e3": {
                "lat": 40.1167,
                "lng": -8.25
            },
            "Alcochete": {
                "lat": 38.75,
                "lng": -8.9667
            },
            "Montemor-o-Novo": {
                "lat": 38.65,
                "lng": -8.2167
            },
            "Peso da R\u00e9gua": {
                "lat": 41.1653,
                "lng": -7.7764
            },
            "Condeixa-a-Nova": {
                "lat": 40.1167,
                "lng": -8.5
            },
            "S\u00e3o Pedro do Sul": {
                "lat": 40.75,
                "lng": -8.0667
            },
            "Sobrado de Paiva": {
                "lat": 41.0667,
                "lng": -8.25
            },
            "Ponte de S\u00f4r": {
                "lat": 39.25,
                "lng": -8.0167
            },
            "Cabeceiras de Basto": {
                "lat": 41.5333,
                "lng": -8.0167
            },
            "Quarteira": {
                "lat": 37.069,
                "lng": -8.103
            },
            "Sert\u00e3": {
                "lat": 39.8,
                "lng": -8.1
            },
            "Batalha": {
                "lat": 39.65,
                "lng": -8.8
            },
            "Macedo de Cavaleiros": {
                "lat": 41.5389,
                "lng": -6.9608
            },
            "Ramada": {
                "lat": 38.804,
                "lng": -9.191
            },
            "Serpa": {
                "lat": 37.9447,
                "lng": -7.5989
            },
            "Castro Daire": {
                "lat": 40.9,
                "lng": -7.9333
            },
            "Penacova": {
                "lat": 40.2706,
                "lng": -8.2833
            },
            "Moura": {
                "lat": 38.1333,
                "lng": -7.45
            },
            "Sobreda": {
                "lat": 38.648,
                "lng": -9.185
            },
            "Nazar\u00e9": {
                "lat": 39.6011,
                "lng": -9.0706
            },
            "Gr\u00e2ndola": {
                "lat": 38.17,
                "lng": -8.56
            },
            "Anta": {
                "lat": 41.0073,
                "lng": -8.625
            },
            "Falagueira": {
                "lat": 38.7589,
                "lng": -9.2261
            },
            "Cadaval": {
                "lat": 39.2333,
                "lng": -9.1
            },
            "Valbom": {
                "lat": 41.1333,
                "lng": -8.5667
            },
            "Horta": {
                "lat": 38.5794,
                "lng": -28.7164
            },
            "Estremoz": {
                "lat": 38.85,
                "lng": -7.5833
            },
            "Sines": {
                "lat": 37.9547,
                "lng": -8.8644
            },
            "S\u00e3o Martinho do Bispo": {
                "lat": 40.2131,
                "lng": -8.4694
            },
            "Valen\u00e7a": {
                "lat": 42.0167,
                "lng": -8.6333
            },
            "Gouveia": {
                "lat": 40.5,
                "lng": -7.6
            },
            "Nelas": {
                "lat": 40.5167,
                "lng": -7.85
            },
            "Gafanha da Nazar\u00e9": {
                "lat": 40.635,
                "lng": -8.714
            },
            "Alcanena": {
                "lat": 39.4667,
                "lng": -8.6667
            },
            "Arruda dos Vinhos": {
                "lat": 38.9833,
                "lng": -9.0667
            },
            "Ribeira Brava": {
                "lat": 32.6833,
                "lng": -17.05
            },
            "Aldeia de Paio Pires": {
                "lat": 38.627,
                "lng": -9.076
            },
            "F\u00e1tima": {
                "lat": 39.6256,
                "lng": -8.6658
            },
            "Bombarral": {
                "lat": 39.2681,
                "lng": -9.1553
            },
            "Vila Pouca de Aguiar": {
                "lat": 41.5006,
                "lng": -7.6422
            },
            "Miranda do Corvo": {
                "lat": 40.1,
                "lng": -8.3333
            },
            "Lama\u00e7\u00e3es": {
                "lat": 41.528,
                "lng": -8.413
            },
            "Alc\u00e1cer do Sal": {
                "lat": 38.3725,
                "lng": -8.5136
            },
            "Vieira do Minho": {
                "lat": 41.6333,
                "lng": -8.1333
            },
            "Moreira": {
                "lat": 41.249,
                "lng": -8.65
            },
            "Aldoar": {
                "lat": 41.171,
                "lng": -8.671
            },
            "S\u00e3o F\u00e9lix da Marinha": {
                "lat": 41.034,
                "lng": -8.623
            },
            "Sabugal": {
                "lat": 40.35,
                "lng": -7.0833
            },
            "Mira": {
                "lat": 40.4333,
                "lng": -8.7333
            },
            "S\u00e1t\u00e3o": {
                "lat": 40.7333,
                "lng": -7.7333
            },
            "Arcozelo": {
                "lat": 41.05,
                "lng": -8.646
            },
            "Sever do Vouga": {
                "lat": 40.7333,
                "lng": -8.3667
            },
            "Arganil": {
                "lat": 40.218,
                "lng": -8.054
            },
            "T\u00e1bua": {
                "lat": 40.3603,
                "lng": -8.0292
            },
            "Ponte da Barca": {
                "lat": 41.8,
                "lng": -8.4
            },
            "Eiras": {
                "lat": 40.235,
                "lng": -8.4178
            },
            "Alij\u00f3": {
                "lat": 41.2761,
                "lng": -7.475
            },
            "Vendas Novas": {
                "lat": 38.6833,
                "lng": -8.45
            },
            "Portela": {
                "lat": 38.7847,
                "lng": -9.1136
            },
            "Costa de Caparica": {
                "lat": 38.645,
                "lng": -9.237
            },
            "\u00d3bidos": {
                "lat": 39.3581,
                "lng": -9.1578
            },
            "Ansi\u00e3o": {
                "lat": 39.9167,
                "lng": -8.4333
            },
            "Santa Comba D\u00e3o": {
                "lat": 40.4,
                "lng": -8.1167
            },
            "Calheta": {
                "lat": 32.7258,
                "lng": -17.1658
            },
            "Esmoriz": {
                "lat": 40.955,
                "lng": -8.627
            },
            "S\u00e3o Jo\u00e3o das Lampas": {
                "lat": 38.87,
                "lng": -9.421
            },
            "Resende": {
                "lat": 41.106,
                "lng": -7.964
            },
            "Vila Frescainha": {
                "lat": 41.535,
                "lng": -8.615
            },
            "Feira": {
                "lat": 40.93,
                "lng": -8.552
            },
            "Avintes": {
                "lat": 41.107,
                "lng": -8.551
            },
            "Reguengos de Monsaraz": {
                "lat": 38.4167,
                "lng": -7.5333
            },
            "Oliveirinha": {
                "lat": 40.603,
                "lng": -8.605
            },
            "Valadares": {
                "lat": 41.1,
                "lng": -8.64
            },
            "S\u00e3o Br\u00e1s de Alportel": {
                "lat": 37.15,
                "lng": -7.8833
            },
            "Santo Andr\u00e9": {
                "lat": 38.05,
                "lng": -8.75
            },
            "Murtosa": {
                "lat": 40.7333,
                "lng": -8.6333
            },
            "S\u00e3o Jo\u00e3o de Ver": {
                "lat": 40.954,
                "lng": -8.551
            },
            "Grij\u00f3": {
                "lat": 41.0333,
                "lng": -8.5833
            },
            "Vouzela": {
                "lat": 40.7167,
                "lng": -8.0833
            },
            "Montalegre": {
                "lat": 41.8231,
                "lng": -7.7917
            },
            "Cunha Alta": {
                "lat": 40.61,
                "lng": -7.77
            },
            "Melres": {
                "lat": 41.0667,
                "lng": -8.4
            },
            "Vila Franca do Campo": {
                "lat": 37.7167,
                "lng": -25.4333
            },
            "Estreito de C\u00e2mara de Lobos": {
                "lat": 32.6758,
                "lng": -16.9772
            },
            "Oliveira de Frades": {
                "lat": 40.7167,
                "lng": -8.1833
            },
            "Ericeira": {
                "lat": 38.962,
                "lng": -9.418
            },
            "Moimenta da Beira": {
                "lat": 40.9797,
                "lng": -7.6136
            },
            "Sobral de Monte Agra\u00e7o": {
                "lat": 39.018,
                "lng": -9.152
            },
            "Set\u00fabal": {
                "lat": 38.5243,
                "lng": -8.8926
            },
            "Praia do Carvoeiro": {
                "lat": 37.12,
                "lng": -8.46
            },
            "Santa Clara": {
                "lat": 40.1908,
                "lng": -8.4383
            },
            "Maceira": {
                "lat": 39.6833,
                "lng": -8.9
            },
            "Trancoso": {
                "lat": 40.7833,
                "lng": -7.35
            },
            "Carregal do Sal": {
                "lat": 40.4333,
                "lng": -8.0
            },
            "Idanha-a-Nova": {
                "lat": 39.9167,
                "lng": -7.2333
            },
            "Guif\u00f5es": {
                "lat": 41.2011,
                "lng": -8.665
            },
            "Pinhel": {
                "lat": 40.7833,
                "lng": -7.0667
            },
            "Mort\u00e1gua": {
                "lat": 40.4333,
                "lng": -8.2333
            },
            "Tavarede": {
                "lat": 40.167,
                "lng": -8.845
            },
            "Aljustrel": {
                "lat": 37.9167,
                "lng": -8.1667
            },
            "Melga\u00e7o": {
                "lat": 42.1,
                "lng": -8.25
            },
            "Paredes de Coura": {
                "lat": 41.9,
                "lng": -8.55
            },
            "Gondizalves": {
                "lat": 41.536,
                "lng": -8.44
            },
            "Rebordosa": {
                "lat": 41.22,
                "lng": -8.412
            },
            "Vinhais": {
                "lat": 41.8167,
                "lng": -7.0
            },
            "Carregado": {
                "lat": 39.0167,
                "lng": -8.9667
            },
            "Caxias": {
                "lat": 38.702,
                "lng": -9.276
            },
            "Almargem": {
                "lat": 38.8475,
                "lng": -9.2714
            },
            "Aver-o-Mar": {
                "lat": 41.4,
                "lng": -8.7667
            },
            "Vila Nova de Cerveira": {
                "lat": 41.9667,
                "lng": -8.6833
            },
            "Rabo de Peixe": {
                "lat": 37.8103,
                "lng": -25.5861
            },
            "Ponta do Sol": {
                "lat": 32.6806,
                "lng": -17.1042
            },
            "Lourosa": {
                "lat": 40.988,
                "lng": -8.546
            },
            "Ferreira do Z\u00eazere": {
                "lat": 39.6833,
                "lng": -8.2833
            },
            "Torre de Moncorvo": {
                "lat": 41.2,
                "lng": -7.1333
            },
            "Chamusca": {
                "lat": 39.35,
                "lng": -8.4833
            }
        }
    },
    "IE": {
        "name": "Ireland",
        "cities": {
            "Dublin": {
                "lat": 53.35,
                "lng": -6.2603
            },
            "Finglas": {
                "lat": 53.4597,
                "lng": -6.2181
            },
            "Cork": {
                "lat": 51.8972,
                "lng": -8.47
            },
            "Galway": {
                "lat": 53.2719,
                "lng": -9.0489
            },
            "Tallaght": {
                "lat": 53.2886,
                "lng": -6.3572
            },
            "Limerick": {
                "lat": 52.6653,
                "lng": -8.6238
            },
            "Lucan": {
                "lat": 53.3544,
                "lng": -6.4486
            },
            "Waterford": {
                "lat": 52.2567,
                "lng": -7.1292
            },
            "Clondalkin": {
                "lat": 53.3203,
                "lng": -6.3947
            },
            "D\u00fan Dealgan": {
                "lat": 54.0044,
                "lng": -6.4003
            },
            "Drogheda": {
                "lat": 53.715,
                "lng": -6.3525
            },
            "Swords": {
                "lat": 53.4597,
                "lng": -6.2181
            },
            "Navan": {
                "lat": 53.6528,
                "lng": -6.6814
            },
            "Blackrock": {
                "lat": 53.3015,
                "lng": -6.1778
            },
            "Ennis": {
                "lat": 52.8463,
                "lng": -8.9807
            },
            "Douglas": {
                "lat": 51.8764,
                "lng": -8.4358
            },
            "Tralee": {
                "lat": 52.2675,
                "lng": -9.6962
            },
            "Carlow": {
                "lat": 52.8306,
                "lng": -6.9317
            },
            "Dunleary": {
                "lat": 53.3,
                "lng": -6.14
            },
            "Port Laoise": {
                "lat": 53.0308,
                "lng": -7.3008
            },
            "Rathfarnham": {
                "lat": 53.2988,
                "lng": -6.2854
            },
            "Baile \u00c1tha Luain": {
                "lat": 53.4236,
                "lng": -7.9425
            },
            "Ashtown": {
                "lat": 53.3754,
                "lng": -6.3326
            },
            "Mullingar": {
                "lat": 53.5224,
                "lng": -7.3378
            },
            "Letterkenny": {
                "lat": 54.9566,
                "lng": -7.7203
            },
            "Kilkenny": {
                "lat": 52.6477,
                "lng": -7.2561
            },
            "Greystones": {
                "lat": 53.144,
                "lng": -6.072
            },
            "Wexford": {
                "lat": 52.3342,
                "lng": -6.4575
            },
            "Naas": {
                "lat": 53.217,
                "lng": -6.663
            },
            "Celbridge": {
                "lat": 53.338,
                "lng": -6.5388
            },
            "Ballyfermot": {
                "lat": 53.3423,
                "lng": -6.3487
            },
            "Sligo": {
                "lat": 54.2667,
                "lng": -8.4833
            },
            "Ballincollig": {
                "lat": 51.8879,
                "lng": -8.5893
            },
            "Mullach \u00cdde": {
                "lat": 53.4508,
                "lng": -6.1544
            },
            "Clonmel": {
                "lat": 52.3539,
                "lng": -7.7116
            },
            "Carrigaline": {
                "lat": 51.8166,
                "lng": -8.3905
            },
            "Stillorgan": {
                "lat": 53.2888,
                "lng": -6.1958
            },
            "Terenure": {
                "lat": 53.3098,
                "lng": -6.2835
            },
            "Maigh Nuad": {
                "lat": 53.3816,
                "lng": -6.591
            },
            "Leixlip": {
                "lat": 53.3643,
                "lng": -6.4881
            },
            "Baile an Bhiataigh": {
                "lat": 53.7014,
                "lng": -6.2461
            },
            "Donacarney": {
                "lat": 53.7094,
                "lng": -6.2783
            },
            "Tullamore": {
                "lat": 53.2667,
                "lng": -7.5
            },
            "Killarney": {
                "lat": 52.0588,
                "lng": -9.5072
            },
            "Midleton": {
                "lat": 51.916,
                "lng": -8.175
            },
            "Arklow": {
                "lat": 52.7941,
                "lng": -6.1649
            },
            "Castlebar": {
                "lat": 53.8608,
                "lng": -9.2988
            },
            "Wicklow": {
                "lat": 52.9779,
                "lng": -6.033
            },
            "Cobh": {
                "lat": 51.851,
                "lng": -8.2967
            },
            "Enniscorthy": {
                "lat": 52.5021,
                "lng": -6.5659
            },
            "An Cabh\u00e1n": {
                "lat": 53.991,
                "lng": -7.3601
            },
            "Gorey": {
                "lat": 52.677,
                "lng": -6.292
            },
            "Tramore": {
                "lat": 52.1588,
                "lng": -7.1463
            },
            "Palmerston": {
                "lat": 53.35,
                "lng": -6.379
            },
            "Athy": {
                "lat": 52.992,
                "lng": -6.987
            },
            "Longford": {
                "lat": 53.727,
                "lng": -7.8
            },
            "Rush": {
                "lat": 53.522,
                "lng": -6.089
            },
            "Na Sceir\u00ed": {
                "lat": 53.5828,
                "lng": -6.1083
            },
            "Killiney": {
                "lat": 53.2651,
                "lng": -6.1137
            },
            "R\u00e1th T\u00f3": {
                "lat": 53.506,
                "lng": -6.463
            },
            "Glanmire": {
                "lat": 51.9167,
                "lng": -8.4
            },
            "Nenagh": {
                "lat": 52.8632,
                "lng": -8.1995
            },
            "Donabate": {
                "lat": 53.487,
                "lng": -6.152
            },
            "Tuam": {
                "lat": 53.515,
                "lng": -8.851
            },
            "Shannon": {
                "lat": 52.7137,
                "lng": -8.8686
            },
            "Lusca": {
                "lat": 53.526,
                "lng": -6.167
            },
            "Kilcock": {
                "lat": 53.4004,
                "lng": -6.6681
            },
            "New Ross": {
                "lat": 52.396,
                "lng": -6.945
            },
            "Youghal": {
                "lat": 51.9517,
                "lng": -7.8456
            },
            "Monaghan": {
                "lat": 54.2478,
                "lng": -6.9708
            },
            "Ros Com\u00e1in": {
                "lat": 53.6333,
                "lng": -8.1833
            },
            "Carrick on Shannon": {
                "lat": 53.944,
                "lng": -8.095
            },
            "Donegal": {
                "lat": 54.654,
                "lng": -8.11
            },
            "Lifford": {
                "lat": 54.834,
                "lng": -7.486
            },
            "Trim": {
                "lat": 53.555,
                "lng": -6.7917
            }
        }
    },
    "BE": {
        "name": "Belgium",
        "cities": {
            "Brussels": {
                "lat": 50.8467,
                "lng": 4.3525
            },
            "Antwerp": {
                "lat": 51.2178,
                "lng": 4.4003
            },
            "Gent": {
                "lat": 51.0536,
                "lng": 3.7253
            },
            "Charleroi": {
                "lat": 50.4,
                "lng": 4.4333
            },
            "Li\u00e8ge": {
                "lat": 50.6397,
                "lng": 5.5706
            },
            "Schaarbeek": {
                "lat": 50.8675,
                "lng": 4.3736
            },
            "Anderlecht": {
                "lat": 50.8333,
                "lng": 4.3333
            },
            "Bruges": {
                "lat": 51.2089,
                "lng": 3.2242
            },
            "Namur": {
                "lat": 50.4667,
                "lng": 4.8667
            },
            "Moortebeek": {
                "lat": 50.8547,
                "lng": 4.3386
            },
            "Mons": {
                "lat": 50.455,
                "lng": 3.952
            },
            "Aalst": {
                "lat": 50.9383,
                "lng": 4.0392
            },
            "Mechelen": {
                "lat": 51.0278,
                "lng": 4.4806
            },
            "Deurne": {
                "lat": 51.2247,
                "lng": 4.4625
            },
            "La Louvi\u00e8re": {
                "lat": 50.4667,
                "lng": 4.1833
            },
            "Sint-Niklaas": {
                "lat": 51.1667,
                "lng": 4.1333
            },
            "Kortrijk": {
                "lat": 50.8275,
                "lng": 3.2658
            },
            "Hasselt": {
                "lat": 50.93,
                "lng": 5.3375
            },
            "Ostend": {
                "lat": 51.2258,
                "lng": 2.9194
            },
            "Tournai": {
                "lat": 50.6056,
                "lng": 3.3881
            },
            "Genk": {
                "lat": 50.9667,
                "lng": 5.5
            },
            "Roeselare": {
                "lat": 50.9333,
                "lng": 3.1167
            },
            "Seraing": {
                "lat": 50.5833,
                "lng": 5.5
            },
            "Laeken": {
                "lat": 50.8778,
                "lng": 4.3558
            },
            "Mouscron": {
                "lat": 50.7333,
                "lng": 3.2167
            },
            "Forest": {
                "lat": 50.8131,
                "lng": 4.3247
            },
            "Verviers": {
                "lat": 50.5833,
                "lng": 5.85
            },
            "Jette": {
                "lat": 50.8667,
                "lng": 4.3333
            },
            "Beveren": {
                "lat": 51.2,
                "lng": 4.25
            },
            "Etterbeek": {
                "lat": 50.8333,
                "lng": 4.3833
            },
            "Beringen": {
                "lat": 51.0489,
                "lng": 5.2261
            },
            "Borgerhout": {
                "lat": 51.2117,
                "lng": 4.4407
            },
            "Dendermonde": {
                "lat": 51.0333,
                "lng": 4.1
            },
            "Merksem": {
                "lat": 51.2428,
                "lng": 4.4414
            },
            "Deinze": {
                "lat": 50.9833,
                "lng": 3.5333
            },
            "Turnhout": {
                "lat": 51.3167,
                "lng": 4.95
            },
            "Vilvoorde": {
                "lat": 50.9333,
                "lng": 4.4167
            },
            "Berchem": {
                "lat": 51.1833,
                "lng": 4.4167
            },
            "Heist-op-den-Berg": {
                "lat": 51.0833,
                "lng": 4.7167
            },
            "Lokeren": {
                "lat": 51.1,
                "lng": 3.9833
            },
            "Evere": {
                "lat": 50.8667,
                "lng": 4.4
            },
            "Dilbeek": {
                "lat": 50.85,
                "lng": 4.2667
            },
            "Hoboken": {
                "lat": 51.1667,
                "lng": 4.3667
            },
            "Geel": {
                "lat": 51.1667,
                "lng": 5.0
            },
            "Sint-Truiden": {
                "lat": 50.8,
                "lng": 5.1833
            },
            "Herstal": {
                "lat": 50.6667,
                "lng": 5.6333
            },
            "Braine-l\u2019Alleud": {
                "lat": 50.6833,
                "lng": 4.3667
            },
            "Ninove": {
                "lat": 50.8333,
                "lng": 4.0167
            },
            "Halle": {
                "lat": 50.7361,
                "lng": 4.2372
            },
            "Waregem": {
                "lat": 50.8833,
                "lng": 3.4167
            },
            "Wilrijk": {
                "lat": 51.1667,
                "lng": 4.3833
            },
            "Brasschaat": {
                "lat": 51.3,
                "lng": 4.5
            },
            "Mechelen-aan-de-Maas": {
                "lat": 50.9667,
                "lng": 5.7
            },
            "Grimbergen": {
                "lat": 50.9333,
                "lng": 4.3833
            },
            "Mol": {
                "lat": 51.1833,
                "lng": 5.1167
            },
            "Lierre": {
                "lat": 51.1333,
                "lng": 4.5667
            },
            "Ch\u00e2telet": {
                "lat": 50.4,
                "lng": 4.5167
            },
            "Evergem": {
                "lat": 51.1167,
                "lng": 3.7
            },
            "Zaventem": {
                "lat": 50.8667,
                "lng": 4.4667
            },
            "Ieper": {
                "lat": 50.8508,
                "lng": 2.885
            },
            "Tienen": {
                "lat": 50.8,
                "lng": 4.9333
            },
            "Schoten": {
                "lat": 51.25,
                "lng": 4.5
            },
            "Wavre": {
                "lat": 50.7167,
                "lng": 4.6
            },
            "Lommel": {
                "lat": 51.2333,
                "lng": 5.3
            },
            "Sint-Pieters-Leeuw": {
                "lat": 50.7833,
                "lng": 4.25
            },
            "Menen": {
                "lat": 50.8,
                "lng": 3.1167
            },
            "Binche": {
                "lat": 50.4,
                "lng": 4.1667
            },
            "Geraardsbergen": {
                "lat": 50.7667,
                "lng": 3.8667
            },
            "Knokke-Heist": {
                "lat": 51.3333,
                "lng": 3.2833
            },
            "Asse": {
                "lat": 50.9,
                "lng": 4.2
            },
            "Bilzen": {
                "lat": 50.8667,
                "lng": 5.5167
            },
            "Sint-Amandsberg": {
                "lat": 51.0539,
                "lng": 3.7492
            },
            "Oudenaarde": {
                "lat": 50.85,
                "lng": 3.6
            },
            "Wevelgem": {
                "lat": 50.8,
                "lng": 3.1667
            },
            "Courcelles": {
                "lat": 50.4667,
                "lng": 4.3667
            },
            "Tongeren": {
                "lat": 50.7833,
                "lng": 5.4667
            },
            "Arlon": {
                "lat": 49.6833,
                "lng": 5.8167
            },
            "Kessel-Lo": {
                "lat": 50.8853,
                "lng": 4.7358
            },
            "Waterloo": {
                "lat": 50.7175,
                "lng": 4.3979
            },
            "Aarschot": {
                "lat": 50.9833,
                "lng": 4.8333
            },
            "Temse": {
                "lat": 51.1167,
                "lng": 4.2
            },
            "Brecht": {
                "lat": 51.35,
                "lng": 4.6333
            },
            "Ath": {
                "lat": 50.6167,
                "lng": 3.7667
            },
            "Izegem": {
                "lat": 50.9167,
                "lng": 3.2
            },
            "Nivelles": {
                "lat": 50.5833,
                "lng": 4.3167
            },
            "Herentals": {
                "lat": 51.1833,
                "lng": 4.8333
            },
            "Ans": {
                "lat": 50.6667,
                "lng": 5.5167
            },
            "Harelbeke": {
                "lat": 50.85,
                "lng": 3.3
            },
            "Soignies": {
                "lat": 50.5667,
                "lng": 4.0667
            },
            "Andenne": {
                "lat": 50.4833,
                "lng": 5.1
            },
            "Sint-Joost-ten-Node": {
                "lat": 50.85,
                "lng": 4.3833
            },
            "Kapellen": {
                "lat": 51.3167,
                "lng": 4.4333
            },
            "Ronse": {
                "lat": 50.75,
                "lng": 3.6
            },
            "Zottegem": {
                "lat": 50.8667,
                "lng": 3.8
            },
            "Willebroek": {
                "lat": 51.0667,
                "lng": 4.3667
            },
            "Fl\u00e9malle-Haute": {
                "lat": 50.6,
                "lng": 5.4667
            },
            "Mortsel": {
                "lat": 51.1667,
                "lng": 4.4667
            },
            "Gembloux": {
                "lat": 50.5667,
                "lng": 4.6833
            },
            "Tubize": {
                "lat": 50.693,
                "lng": 4.2047
            },
            "Lanaken": {
                "lat": 50.8833,
                "lng": 5.65
            },
            "Wetteren": {
                "lat": 51.0,
                "lng": 3.8833
            },
            "Oupeye": {
                "lat": 50.7,
                "lng": 5.65
            },
            "Ganshoren": {
                "lat": 50.8667,
                "lng": 4.3167
            },
            "Maaseik": {
                "lat": 51.1,
                "lng": 5.8
            },
            "Overijse": {
                "lat": 50.7667,
                "lng": 4.5333
            },
            "Beersel": {
                "lat": 50.7667,
                "lng": 4.3
            },
            "Westerlo": {
                "lat": 51.0833,
                "lng": 4.9167
            },
            "Hamme": {
                "lat": 51.0983,
                "lng": 4.1369
            },
            "Merelbeke": {
                "lat": 51.0,
                "lng": 3.75
            },
            "Zwevegem": {
                "lat": 50.8,
                "lng": 3.3333
            },
            "Saint-Nicolas": {
                "lat": 50.6333,
                "lng": 5.5333
            },
            "Diest": {
                "lat": 50.9833,
                "lng": 5.05
            },
            "Maldegem": {
                "lat": 51.2,
                "lng": 3.4333
            },
            "Oostkamp": {
                "lat": 51.15,
                "lng": 3.2333
            },
            "Heverlee": {
                "lat": 50.86,
                "lng": 4.6897
            },
            "Saint-Ghislain": {
                "lat": 50.45,
                "lng": 3.8167
            },
            "Zemst": {
                "lat": 50.9722,
                "lng": 4.4222
            },
            "Manage": {
                "lat": 50.5,
                "lng": 4.2333
            },
            "Scherpenheuvel": {
                "lat": 51.0,
                "lng": 4.9667
            },
            "Nijlen": {
                "lat": 51.1667,
                "lng": 4.65
            },
            "Marcinelle": {
                "lat": 50.4,
                "lng": 4.4486
            },
            "Ekeren": {
                "lat": 51.2833,
                "lng": 4.4167
            },
            "Balen": {
                "lat": 51.1667,
                "lng": 5.15
            },
            "Zedelgem": {
                "lat": 51.1333,
                "lng": 3.1333
            },
            "Fleurus": {
                "lat": 50.4833,
                "lng": 4.5333
            },
            "Rixensart": {
                "lat": 50.7167,
                "lng": 4.5333
            },
            "Lochristi": {
                "lat": 51.1,
                "lng": 3.8333
            },
            "Braine-le-Comte": {
                "lat": 50.6,
                "lng": 4.1333
            },
            "Edegem": {
                "lat": 51.15,
                "lng": 4.45
            },
            "Tervuren": {
                "lat": 50.8167,
                "lng": 4.5
            },
            "Koekelberg": {
                "lat": 50.8667,
                "lng": 4.3333
            },
            "Koksijde": {
                "lat": 51.1,
                "lng": 2.65
            },
            "Frameries": {
                "lat": 50.4,
                "lng": 3.9
            },
            "Zoersel": {
                "lat": 51.2667,
                "lng": 4.7
            },
            "Herent": {
                "lat": 50.9,
                "lng": 4.6667
            },
            "Hoogstraten": {
                "lat": 51.4,
                "lng": 4.7667
            },
            "Bornem": {
                "lat": 51.1,
                "lng": 4.2333
            },
            "Huy": {
                "lat": 50.5167,
                "lng": 5.2333
            },
            "Zonhoven": {
                "lat": 50.9833,
                "lng": 5.35
            },
            "Kontich": {
                "lat": 51.1333,
                "lng": 4.45
            },
            "Zele": {
                "lat": 51.0667,
                "lng": 4.0333
            },
            "Chaudfontaine": {
                "lat": 50.5833,
                "lng": 5.6333
            },
            "Eeklo": {
                "lat": 51.1833,
                "lng": 3.55
            },
            "Sint-Katelijne-Waver": {
                "lat": 51.0667,
                "lng": 4.5333
            },
            "Colfontaine": {
                "lat": 50.4167,
                "lng": 3.85
            },
            "Wuustwezel": {
                "lat": 51.3833,
                "lng": 4.6
            },
            "Torhout": {
                "lat": 51.05,
                "lng": 3.1
            },
            "Tielt": {
                "lat": 51.0,
                "lng": 3.3167
            },
            "Blankenberge": {
                "lat": 51.3,
                "lng": 3.1167
            },
            "Kortenberg": {
                "lat": 50.8833,
                "lng": 4.5333
            },
            "Denderleeuw": {
                "lat": 50.8833,
                "lng": 4.0667
            },
            "Boussu": {
                "lat": 50.4333,
                "lng": 3.8
            },
            "Sint-Andries": {
                "lat": 51.1972,
                "lng": 3.1803
            },
            "Poperinge": {
                "lat": 50.85,
                "lng": 2.7333
            },
            "Assebroek": {
                "lat": 51.1964,
                "lng": 3.2536
            },
            "Schilde": {
                "lat": 51.2333,
                "lng": 4.5667
            },
            "Eupen": {
                "lat": 50.6333,
                "lng": 6.0333
            },
            "Middelkerke": {
                "lat": 51.1833,
                "lng": 2.8167
            },
            "Sint-Gillis-Waas": {
                "lat": 51.2167,
                "lng": 4.1167
            },
            "Lebbeke": {
                "lat": 51.0,
                "lng": 4.1167
            },
            "Ranst": {
                "lat": 51.2,
                "lng": 4.55
            },
            "Meise": {
                "lat": 50.9333,
                "lng": 4.3333
            },
            "Essen": {
                "lat": 51.4667,
                "lng": 4.4667
            },
            "Diepenbeek": {
                "lat": 50.9,
                "lng": 5.4
            },
            "Wervik": {
                "lat": 50.7833,
                "lng": 3.0333
            },
            "Morlanwelz-Mariemont": {
                "lat": 50.45,
                "lng": 4.2333
            },
            "Quaregnon": {
                "lat": 50.4333,
                "lng": 3.85
            },
            "Zwijndrecht": {
                "lat": 51.2167,
                "lng": 4.3333
            },
            "Kasterlee": {
                "lat": 51.25,
                "lng": 4.95
            },
            "Kalmthout": {
                "lat": 51.3833,
                "lng": 4.4667
            },
            "Lede": {
                "lat": 50.9667,
                "lng": 3.9833
            },
            "Londerzeel": {
                "lat": 51.0,
                "lng": 4.3
            },
            "Boom": {
                "lat": 51.0833,
                "lng": 4.3667
            },
            "Lessines": {
                "lat": 50.7167,
                "lng": 3.8333
            },
            "Stabroek": {
                "lat": 51.3333,
                "lng": 4.3667
            },
            "Tessenderlo": {
                "lat": 51.0667,
                "lng": 5.0833
            },
            "Haaltert": {
                "lat": 50.9,
                "lng": 4.0
            },
            "Walcourt": {
                "lat": 50.25,
                "lng": 4.4167
            },
            "Stekene": {
                "lat": 51.2,
                "lng": 4.0333
            },
            "Sint-Genesius-Rode": {
                "lat": 50.75,
                "lng": 4.35
            },
            "Beerse": {
                "lat": 51.3167,
                "lng": 4.8667
            },
            "Destelbergen": {
                "lat": 51.05,
                "lng": 3.8
            },
            "Bredene": {
                "lat": 51.2333,
                "lng": 2.9667
            },
            "Fontaine-l\u2019\u00c9v\u00eaque": {
                "lat": 50.4167,
                "lng": 4.3167
            },
            "Vis\u00e9": {
                "lat": 50.7333,
                "lng": 5.7
            },
            "Herzele": {
                "lat": 50.8833,
                "lng": 3.8833
            },
            "Duffel": {
                "lat": 51.1,
                "lng": 4.5167
            },
            "Herve": {
                "lat": 50.6333,
                "lng": 5.8
            },
            "Neder-Over-Heembeek": {
                "lat": 50.9,
                "lng": 4.3833
            },
            "Putte": {
                "lat": 51.05,
                "lng": 4.6333
            },
            "Marche-en-Famenne": {
                "lat": 50.2167,
                "lng": 5.3333
            },
            "Puurs": {
                "lat": 51.0761,
                "lng": 4.2803
            },
            "Pont-\u00e0-Celles": {
                "lat": 50.5,
                "lng": 4.35
            },
            "Mariakerke": {
                "lat": 51.0728,
                "lng": 3.6761
            },
            "Neerpelt": {
                "lat": 51.2333,
                "lng": 5.4333
            },
            "P\u00e9ruwelz": {
                "lat": 50.5167,
                "lng": 3.5833
            },
            "Wondelgem": {
                "lat": 51.0889,
                "lng": 3.7131
            },
            "Aubange": {
                "lat": 49.5667,
                "lng": 5.805
            },
            "Soumagne": {
                "lat": 50.6167,
                "lng": 5.75
            },
            "Diksmuide": {
                "lat": 51.0333,
                "lng": 2.865
            },
            "Dour": {
                "lat": 50.3979,
                "lng": 3.7807
            },
            "Rotselaar": {
                "lat": 50.95,
                "lng": 4.7167
            },
            "Riemst": {
                "lat": 50.8,
                "lng": 5.6
            },
            "Kruibeke": {
                "lat": 51.1667,
                "lng": 4.3
            },
            "Lille": {
                "lat": 51.2333,
                "lng": 4.8333
            },
            "Ledeberg": {
                "lat": 51.0375,
                "lng": 3.7425
            },
            "Fl\u00e9ron": {
                "lat": 50.6167,
                "lng": 5.6833
            },
            "Ciney": {
                "lat": 50.3,
                "lng": 5.1
            },
            "Hannut": {
                "lat": 50.6667,
                "lng": 5.0833
            },
            "Peer": {
                "lat": 51.1333,
                "lng": 5.4667
            },
            "Wemmel": {
                "lat": 50.9078,
                "lng": 4.3067
            },
            "Bree": {
                "lat": 51.1333,
                "lng": 5.6
            },
            "Merchtem": {
                "lat": 50.9667,
                "lng": 4.2333
            },
            "\u00c9ghez\u00e9e": {
                "lat": 50.5833,
                "lng": 4.9167
            },
            "Landen": {
                "lat": 50.75,
                "lng": 5.0833
            },
            "Gentbrugge": {
                "lat": 51.0314,
                "lng": 3.7642
            },
            "Bastogne": {
                "lat": 50.0042,
                "lng": 5.72
            },
            "Sint-Kruis": {
                "lat": 51.2139,
                "lng": 3.2503
            },
            "Zulte": {
                "lat": 50.9167,
                "lng": 3.4333
            },
            "Beernem": {
                "lat": 51.1333,
                "lng": 3.3333
            },
            "Leopoldsburg": {
                "lat": 51.1192,
                "lng": 5.2608
            },
            "Bissegem": {
                "lat": 50.8167,
                "lng": 3.2167
            },
            "Ternat": {
                "lat": 50.8667,
                "lng": 4.1833
            },
            "Overpelt": {
                "lat": 51.2167,
                "lng": 5.4167
            },
            "Machelen": {
                "lat": 50.9167,
                "lng": 4.4333
            },
            "Dison": {
                "lat": 50.6167,
                "lng": 5.85
            },
            "Genappe": {
                "lat": 50.6,
                "lng": 4.45
            },
            "Waremme": {
                "lat": 50.6975,
                "lng": 5.2556
            },
            "Rumst": {
                "lat": 51.0833,
                "lng": 4.4167
            },
            "Bonheiden": {
                "lat": 51.0333,
                "lng": 4.5333
            },
            "Chapelle-lez-Herlaimont": {
                "lat": 50.4667,
                "lng": 4.2833
            },
            "Ravels": {
                "lat": 51.3667,
                "lng": 5.0
            },
            "Berlare": {
                "lat": 51.025,
                "lng": 4.0025
            },
            "Tremelo": {
                "lat": 50.9833,
                "lng": 4.7
            },
            "Lummen": {
                "lat": 50.9833,
                "lng": 5.2
            },
            "Thuin": {
                "lat": 50.3333,
                "lng": 4.2833
            },
            "Sprimont": {
                "lat": 50.5,
                "lng": 5.6667
            },
            "Anzegem": {
                "lat": 50.8333,
                "lng": 3.4667
            },
            "Herselt": {
                "lat": 51.05,
                "lng": 4.8833
            },
            "Buggenhout": {
                "lat": 51.0,
                "lng": 4.2
            },
            "Haacht": {
                "lat": 50.9833,
                "lng": 4.6333
            },
            "Aartselaar": {
                "lat": 51.1333,
                "lng": 4.3833
            },
            "Lubbeek": {
                "lat": 50.8817,
                "lng": 4.8414
            },
            "Opwijk": {
                "lat": 50.9667,
                "lng": 4.1833
            },
            "Amay": {
                "lat": 50.55,
                "lng": 5.3167
            },
            "Wingene": {
                "lat": 51.05,
                "lng": 3.2667
            },
            "Assenede": {
                "lat": 51.2333,
                "lng": 3.75
            },
            "Jodoigne": {
                "lat": 50.7167,
                "lng": 4.8667
            },
            "Oostakker": {
                "lat": 51.1003,
                "lng": 3.7633
            },
            "Beloeil": {
                "lat": 50.5333,
                "lng": 3.7167
            },
            "Wezembeek-Oppem": {
                "lat": 50.85,
                "lng": 4.4833
            },
            "Oud-Turnhout": {
                "lat": 51.3167,
                "lng": 4.9833
            },
            "Ichtegem": {
                "lat": 51.0928,
                "lng": 3.0106
            },
            "Jabbeke": {
                "lat": 51.15,
                "lng": 3.2333
            },
            "Couvin": {
                "lat": 50.05,
                "lng": 4.4833
            },
            "Enghien": {
                "lat": 50.6956,
                "lng": 4.0411
            },
            "Kraainem": {
                "lat": 50.8667,
                "lng": 4.4667
            },
            "Wanze": {
                "lat": 50.5333,
                "lng": 5.2167
            },
            "Oosterzele": {
                "lat": 50.95,
                "lng": 3.8
            },
            "Dinant": {
                "lat": 50.2667,
                "lng": 4.9167
            },
            "Boechout": {
                "lat": 51.15,
                "lng": 4.4833
            },
            "Grez-Doiceau": {
                "lat": 50.7333,
                "lng": 4.7
            },
            "Kuurne": {
                "lat": 50.85,
                "lng": 3.2833
            },
            "Arendonk": {
                "lat": 51.3167,
                "lng": 5.0833
            },
            "Blegny": {
                "lat": 50.6667,
                "lng": 5.7333
            },
            "Liedekerke": {
                "lat": 50.8667,
                "lng": 4.0833
            },
            "Bocholt": {
                "lat": 51.1667,
                "lng": 5.5833
            },
            "Drongen": {
                "lat": 51.0503,
                "lng": 3.6628
            },
            "Mettet": {
                "lat": 50.3167,
                "lng": 4.6667
            },
            "Zandhoven": {
                "lat": 51.2167,
                "lng": 4.65
            },
            "Sint-Gillis-bij-Dendermonde": {
                "lat": 51.0189,
                "lng": 4.1058
            },
            "Wommelgem": {
                "lat": 51.2,
                "lng": 4.5167
            },
            "Esneux": {
                "lat": 50.5333,
                "lng": 5.5667
            },
            "Gavere": {
                "lat": 50.9333,
                "lng": 3.6667
            },
            "Keerbergen": {
                "lat": 51.0,
                "lng": 4.6167
            },
            "Zelzate": {
                "lat": 51.2,
                "lng": 3.8167
            },
            "Herk-de-Stad": {
                "lat": 50.9333,
                "lng": 5.1667
            },
            "Gerpinnes": {
                "lat": 50.3333,
                "lng": 4.5167
            },
            "Malm\u00e9dy": {
                "lat": 50.4167,
                "lng": 6.0167
            },
            "De Haan": {
                "lat": 51.2667,
                "lng": 3.0333
            },
            "Kortemark": {
                "lat": 51.0333,
                "lng": 3.0333
            },
            "Olen": {
                "lat": 51.15,
                "lng": 4.85
            },
            "Rochefort": {
                "lat": 50.1667,
                "lng": 5.2167
            },
            "Laarne": {
                "lat": 51.0167,
                "lng": 3.85
            },
            "Zonnebeke": {
                "lat": 50.8667,
                "lng": 2.9833
            },
            "Sint-Michiels": {
                "lat": 51.1886,
                "lng": 3.2117
            },
            "Aywaille": {
                "lat": 50.4667,
                "lng": 5.6667
            },
            "Boortmeerbeek": {
                "lat": 50.9833,
                "lng": 4.5667
            },
            "Veurne": {
                "lat": 51.0667,
                "lng": 2.65
            },
            "Anderlues": {
                "lat": 50.4,
                "lng": 4.2667
            },
            "Kinrooi": {
                "lat": 51.15,
                "lng": 5.75
            },
            "Profondeville": {
                "lat": 50.3833,
                "lng": 4.8667
            },
            "Nevele": {
                "lat": 51.0333,
                "lng": 3.55
            },
            "Gistel": {
                "lat": 51.15,
                "lng": 2.9667
            },
            "Steenokkerzeel": {
                "lat": 50.9167,
                "lng": 4.5167
            },
            "Paal": {
                "lat": 51.0392,
                "lng": 5.1728
            },
            "Theux": {
                "lat": 50.5333,
                "lng": 5.8167
            },
            "Beyne-Heusay": {
                "lat": 50.6167,
                "lng": 5.65
            },
            "Rijkevorsel": {
                "lat": 51.35,
                "lng": 4.7667
            },
            "Kampenhout": {
                "lat": 50.95,
                "lng": 4.55
            },
            "Bernissart": {
                "lat": 50.4833,
                "lng": 3.65
            },
            "Deerlijk": {
                "lat": 50.85,
                "lng": 3.35
            },
            "Alken": {
                "lat": 50.8761,
                "lng": 5.3078
            },
            "Chaumont-Gistoux": {
                "lat": 50.6769,
                "lng": 4.7196
            },
            "Berlaar": {
                "lat": 51.1167,
                "lng": 4.65
            },
            "Durbuy": {
                "lat": 50.3522,
                "lng": 5.4563
            },
            "Hemiksem": {
                "lat": 51.15,
                "lng": 4.35
            },
            "Wichelen": {
                "lat": 51.0,
                "lng": 3.9667
            },
            "Nazareth": {
                "lat": 50.9667,
                "lng": 3.6
            },
            "Melle": {
                "lat": 51.0,
                "lng": 3.8
            },
            "Nieuwpoort": {
                "lat": 51.1167,
                "lng": 2.75
            },
            "Strombeek-Bever": {
                "lat": 50.91,
                "lng": 4.35
            },
            "Florennes": {
                "lat": 50.25,
                "lng": 4.6167
            },
            "Staden": {
                "lat": 50.9833,
                "lng": 3.0167
            },
            "Virton": {
                "lat": 49.5675,
                "lng": 5.5325
            },
            "Seneffe": {
                "lat": 50.5167,
                "lng": 4.25
            },
            "Farciennes": {
                "lat": 50.4333,
                "lng": 4.55
            },
            "Retie": {
                "lat": 51.2667,
                "lng": 5.0667
            },
            "Hoeilaart": {
                "lat": 50.7667,
                "lng": 4.4667
            },
            "Grobbendonk": {
                "lat": 51.2,
                "lng": 4.7167
            },
            "Vosselaar": {
                "lat": 51.3167,
                "lng": 4.8833
            },
            "\u00c9caussinnes-Lalaing": {
                "lat": 50.5667,
                "lng": 4.1833
            },
            "De Panne": {
                "lat": 51.1,
                "lng": 2.5833
            },
            "Oud-Heverlee": {
                "lat": 50.8333,
                "lng": 4.6667
            },
            "Melsele": {
                "lat": 51.2209,
                "lng": 4.2815
            },
            "Moorslede": {
                "lat": 50.8833,
                "lng": 3.0667
            },
            "La Calamine": {
                "lat": 50.7,
                "lng": 6.0
            },
            "De Pinte": {
                "lat": 51.0,
                "lng": 3.65
            },
            "Damme": {
                "lat": 51.25,
                "lng": 3.2667
            },
            "Borsbeek": {
                "lat": 51.2,
                "lng": 4.4833
            },
            "Ingelmunster": {
                "lat": 50.9167,
                "lng": 3.25
            },
            "Meulebeke": {
                "lat": 50.95,
                "lng": 3.2833
            },
            "Waasmunster": {
                "lat": 51.1097,
                "lng": 4.0847
            },
            "Villers-la-Ville": {
                "lat": 50.5833,
                "lng": 4.5333
            },
            "Louvain-la-Neuve": {
                "lat": 50.6667,
                "lng": 4.5
            },
            "Raeren": {
                "lat": 50.6833,
                "lng": 6.1167
            },
            "Borgloon": {
                "lat": 50.8,
                "lng": 5.3333
            },
            "Niel": {
                "lat": 51.1167,
                "lng": 4.3333
            },
            "Heule": {
                "lat": 50.8333,
                "lng": 3.2333
            },
            "Ertvelde": {
                "lat": 51.1783,
                "lng": 3.7447
            },
            "Court-Saint-\u00c9tienne": {
                "lat": 50.6333,
                "lng": 4.5667
            },
            "Hulshout": {
                "lat": 51.0833,
                "lng": 4.7833
            },
            "Braine-le-Ch\u00e2teau": {
                "lat": 50.6833,
                "lng": 4.2667
            },
            "Estaimpuis": {
                "lat": 50.7,
                "lng": 3.2667
            },
            "Jurbise": {
                "lat": 50.5333,
                "lng": 3.9333
            },
            "Opglabbeek": {
                "lat": 51.05,
                "lng": 5.5833
            },
            "Plombi\u00e8res": {
                "lat": 50.7333,
                "lng": 5.95
            },
            "Spa": {
                "lat": 50.4925,
                "lng": 5.8642
            },
            "Meerhout": {
                "lat": 51.1333,
                "lng": 5.0833
            },
            "Sint-Lievens-Houtem": {
                "lat": 50.9167,
                "lng": 3.8667
            },
            "Montignies-le-Tilleul": {
                "lat": 50.3833,
                "lng": 4.3833
            },
            "Avelgem": {
                "lat": 50.7667,
                "lng": 3.4333
            },
            "Begijnendijk": {
                "lat": 51.0167,
                "lng": 4.7833
            },
            "Houthulst": {
                "lat": 50.9833,
                "lng": 2.95
            },
            "Bierbeek": {
                "lat": 50.8333,
                "lng": 4.7667
            },
            "Cuesmes": {
                "lat": 50.4361,
                "lng": 3.9206
            },
            "Bertem": {
                "lat": 50.85,
                "lng": 4.6167
            },
            "Hooglede": {
                "lat": 50.9833,
                "lng": 3.0833
            },
            "Erquelinnes": {
                "lat": 50.3,
                "lng": 4.1167
            },
            "Welkenraedt": {
                "lat": 50.65,
                "lng": 5.9667
            },
            "Holsbeek": {
                "lat": 50.9167,
                "lng": 4.7667
            },
            "Wilsele": {
                "lat": 50.9,
                "lng": 4.7
            },
            "Huldenberg": {
                "lat": 50.7833,
                "lng": 4.5833
            },
            "Chimay": {
                "lat": 50.05,
                "lng": 4.3167
            },
            "Pepinster": {
                "lat": 50.5667,
                "lng": 5.8167
            },
            "Oudenburg": {
                "lat": 51.1833,
                "lng": 3.0
            },
            "Ledegem": {
                "lat": 50.85,
                "lng": 3.1167
            },
            "Wijnegem": {
                "lat": 51.2333,
                "lng": 4.5333
            },
            "Lovendegem": {
                "lat": 51.1,
                "lng": 3.6
            },
            "Hoeselt": {
                "lat": 50.85,
                "lng": 5.4833
            },
            "Saint-Vith": {
                "lat": 50.2667,
                "lng": 6.1167
            },
            "Dessel": {
                "lat": 51.2333,
                "lng": 5.1167
            },
            "Wielsbeke": {
                "lat": 50.9,
                "lng": 3.3667
            },
            "Halen": {
                "lat": 50.9481,
                "lng": 5.1144
            },
            "Kapelle-op-den-Bos": {
                "lat": 51.0167,
                "lng": 4.3667
            },
            "Awans": {
                "lat": 50.6667,
                "lng": 5.4667
            },
            "Perwez": {
                "lat": 50.6167,
                "lng": 4.8167
            },
            "Juprelle": {
                "lat": 50.7167,
                "lng": 5.5333
            },
            "Gooik": {
                "lat": 50.8,
                "lng": 4.1167
            },
            "Philippeville": {
                "lat": 50.2,
                "lng": 4.55
            },
            "La Bruy\u00e8re": {
                "lat": 50.5,
                "lng": 4.8
            },
            "Roux": {
                "lat": 50.4412,
                "lng": 4.3926
            },
            "Yvoir": {
                "lat": 50.3333,
                "lng": 4.8833
            },
            "Beauraing": {
                "lat": 50.1092,
                "lng": 4.9567
            },
            "Herenthout": {
                "lat": 51.15,
                "lng": 4.7667
            },
            "Sint-Martens-Lennik": {
                "lat": 50.8,
                "lng": 4.15
            },
            "Ardooie": {
                "lat": 50.9667,
                "lng": 3.1833
            },
            "Bassenge": {
                "lat": 50.7667,
                "lng": 5.6
            },
            "Lichtervelde": {
                "lat": 51.0333,
                "lng": 3.1333
            },
            "Koekelare": {
                "lat": 51.0833,
                "lng": 2.9667
            },
            "Bertrix": {
                "lat": 49.8542,
                "lng": 5.2533
            },
            "Galmaarden": {
                "lat": 50.75,
                "lng": 3.99
            },
            "Heusden": {
                "lat": 51.0281,
                "lng": 3.8
            },
            "Sint-Job-in-\u2019t-Goor": {
                "lat": 51.3,
                "lng": 4.5667
            },
            "Lint": {
                "lat": 51.1167,
                "lng": 4.4833
            },
            "Jalhay": {
                "lat": 50.5667,
                "lng": 5.9667
            },
            "Merksplas": {
                "lat": 51.3667,
                "lng": 4.8667
            }
        }
    },
    "RS": {
        "name": "Serbia",
        "cities": {
            "Belgrade": {
                "lat": 44.82,
                "lng": 20.46
            },
            "Novi Sad": {
                "lat": 45.2542,
                "lng": 19.8425
            },
            "Ni\u0161": {
                "lat": 43.3208,
                "lng": 21.8958
            },
            "Zemun": {
                "lat": 44.85,
                "lng": 20.4
            },
            "Kragujevac": {
                "lat": 44.0101,
                "lng": 20.9172
            },
            "\u0160abac": {
                "lat": 44.75,
                "lng": 19.7
            },
            "Smederevo": {
                "lat": 44.6667,
                "lng": 20.9333
            },
            "Valjevo": {
                "lat": 44.2667,
                "lng": 19.8833
            },
            "Loznica": {
                "lat": 44.5333,
                "lng": 19.2258
            },
            "Pan\u010devo": {
                "lat": 44.8706,
                "lng": 20.6403
            },
            "Sremska Mitrovica": {
                "lat": 44.97,
                "lng": 19.6125
            },
            "Stara Pazova": {
                "lat": 44.9833,
                "lng": 20.1667
            },
            "\u010ca\u010dak": {
                "lat": 43.8833,
                "lng": 20.35
            },
            "Po\u017earevac": {
                "lat": 44.6167,
                "lng": 21.1833
            },
            "Zrenjanin": {
                "lat": 45.3833,
                "lng": 20.3894
            },
            "Kraljevo": {
                "lat": 43.7236,
                "lng": 20.6875
            },
            "Novi Pazar": {
                "lat": 43.1378,
                "lng": 20.5161
            },
            "U\u017eice": {
                "lat": 43.85,
                "lng": 19.85
            },
            "Kru\u0161evac": {
                "lat": 43.5806,
                "lng": 21.3306
            },
            "Lazarevac": {
                "lat": 44.3667,
                "lng": 20.25
            },
            "Vranje": {
                "lat": 42.55,
                "lng": 21.9
            },
            "Ruma": {
                "lat": 45.0,
                "lng": 19.8333
            },
            "Para\u0107in": {
                "lat": 43.8667,
                "lng": 21.4167
            },
            "Sombor": {
                "lat": 45.7833,
                "lng": 19.1167
            },
            "Pirot": {
                "lat": 43.1667,
                "lng": 22.6
            },
            "Mladenovac": {
                "lat": 44.4333,
                "lng": 20.7
            },
            "Zaje\u010dar": {
                "lat": 43.9167,
                "lng": 22.3
            },
            "Batajnica": {
                "lat": 44.9,
                "lng": 20.2833
            },
            "In\u0111ija": {
                "lat": 45.05,
                "lng": 20.0833
            },
            "Aran\u0111elovac": {
                "lat": 44.3,
                "lng": 20.5667
            },
            "Bor\u010da": {
                "lat": 44.87,
                "lng": 20.45
            },
            "Sur\u010din": {
                "lat": 44.7919,
                "lng": 20.2723
            },
            "Aleksinac": {
                "lat": 43.55,
                "lng": 21.7
            },
            "Smederevska Palanka": {
                "lat": 44.3663,
                "lng": 20.9565
            },
            "Bujanovac": {
                "lat": 42.4667,
                "lng": 21.7667
            },
            "Velika Plana": {
                "lat": 44.334,
                "lng": 21.0768
            },
            "Gornji Milanovac": {
                "lat": 44.0242,
                "lng": 20.46
            },
            "Prokuplje": {
                "lat": 43.2361,
                "lng": 21.5903
            },
            "Be\u010dej": {
                "lat": 45.6167,
                "lng": 20.0333
            },
            "Prijepolje": {
                "lat": 43.3833,
                "lng": 19.6333
            },
            "Vr\u0161ac": {
                "lat": 45.1167,
                "lng": 21.3033
            },
            "Kula": {
                "lat": 45.6,
                "lng": 19.5333
            },
            "Negotin": {
                "lat": 44.2292,
                "lng": 22.5306
            },
            "Jagodina": {
                "lat": 43.9667,
                "lng": 21.25
            },
            "\u0160id": {
                "lat": 45.1167,
                "lng": 19.2333
            },
            "Bor": {
                "lat": 44.0833,
                "lng": 22.1
            },
            "Kovin": {
                "lat": 44.75,
                "lng": 20.9667
            },
            "Tutin": {
                "lat": 42.9897,
                "lng": 20.3364
            },
            "Knja\u017eevac": {
                "lat": 43.5675,
                "lng": 22.2575
            },
            "Petrovac na Mlavi": {
                "lat": 44.3783,
                "lng": 21.4194
            },
            "Subotica": {
                "lat": 46.1003,
                "lng": 19.6656
            },
            "Vlasotince": {
                "lat": 42.9667,
                "lng": 22.1333
            },
            "Kikinda": {
                "lat": 45.8333,
                "lng": 20.45
            },
            "Temerin": {
                "lat": 45.4167,
                "lng": 19.8833
            },
            "Ivanjica": {
                "lat": 43.5811,
                "lng": 20.2297
            },
            "Kalu\u0111erica": {
                "lat": 44.75,
                "lng": 20.55
            },
            "Aleksandrovac": {
                "lat": 43.4589,
                "lng": 21.0464
            },
            "Bajina Ba\u0161ta": {
                "lat": 43.95,
                "lng": 19.55
            },
            "Ba\u010dka Palanka": {
                "lat": 45.25,
                "lng": 19.4
            },
            "Ra\u0161ka": {
                "lat": 43.2919,
                "lng": 20.6156
            },
            "Vrbas": {
                "lat": 45.5667,
                "lng": 19.65
            },
            "Svilajnac": {
                "lat": 44.2356,
                "lng": 21.1964
            },
            "Apatin": {
                "lat": 45.6667,
                "lng": 18.9833
            },
            "Despotovac": {
                "lat": 44.0833,
                "lng": 21.4333
            },
            "Topola": {
                "lat": 44.25,
                "lng": 20.7
            },
            "Surdulica": {
                "lat": 42.6833,
                "lng": 22.1667
            },
            "Lebane": {
                "lat": 42.9167,
                "lng": 21.7333
            },
            "Srem\u010dica": {
                "lat": 44.6767,
                "lng": 20.391
            },
            "Vladi\u010din Han": {
                "lat": 42.7,
                "lng": 22.0667
            },
            "Leskovac": {
                "lat": 43.0,
                "lng": 21.95
            },
            "Lu\u010dani": {
                "lat": 43.8667,
                "lng": 20.1333
            },
            "Kladovo": {
                "lat": 44.6067,
                "lng": 22.6131
            },
            "Senta": {
                "lat": 45.9314,
                "lng": 20.09
            },
            "Pe\u0107inci": {
                "lat": 44.9,
                "lng": 19.9667
            },
            "Arilje": {
                "lat": 43.7514,
                "lng": 20.0986
            },
            "Majdanpek": {
                "lat": 44.4167,
                "lng": 21.9333
            },
            "Futog": {
                "lat": 45.2333,
                "lng": 19.7167
            },
            "Varvarin": {
                "lat": 43.7167,
                "lng": 21.3667
            },
            "Veliko Gradi\u0161te": {
                "lat": 44.75,
                "lng": 21.5167
            },
            "Vladimirci": {
                "lat": 44.6167,
                "lng": 19.7833
            },
            "Veternik": {
                "lat": 45.2533,
                "lng": 19.7608
            },
            "Krupanj": {
                "lat": 44.3667,
                "lng": 19.3667
            },
            "Trstenik": {
                "lat": 43.6167,
                "lng": 20.9833
            },
            "Nova Pazova": {
                "lat": 44.95,
                "lng": 20.2167
            },
            "\u017ditora\u0111a": {
                "lat": 43.1833,
                "lng": 21.7167
            },
            "Srbobran": {
                "lat": 45.5333,
                "lng": 19.7833
            },
            "Brus": {
                "lat": 43.3814,
                "lng": 21.0378
            },
            "Kur\u0161umlija": {
                "lat": 43.15,
                "lng": 21.2667
            },
            "Titel": {
                "lat": 45.2047,
                "lng": 20.3
            },
            "Ku\u010devo": {
                "lat": 44.4833,
                "lng": 21.6667
            },
            "Lajkovac": {
                "lat": 44.3667,
                "lng": 20.1667
            },
            "Petrovaradin": {
                "lat": 45.25,
                "lng": 19.8667
            },
            "\u010cajetina": {
                "lat": 43.75,
                "lng": 19.7167
            },
            "Mionica": {
                "lat": 44.25,
                "lng": 20.0833
            },
            "Svrljig": {
                "lat": 43.4167,
                "lng": 22.1167
            },
            "Kni\u0107": {
                "lat": 43.9264,
                "lng": 20.7194
            },
            "Ni\u0161ka Banja": {
                "lat": 43.2933,
                "lng": 22.0061
            },
            "Sjenica": {
                "lat": 43.2667,
                "lng": 20.0
            },
            "Mero\u0161ina": {
                "lat": 43.2833,
                "lng": 21.7167
            },
            "Kostolac": {
                "lat": 44.7147,
                "lng": 21.17
            },
            "Nova Varo\u0161": {
                "lat": 43.4604,
                "lng": 19.8154
            },
            "Se\u010danj": {
                "lat": 45.3667,
                "lng": 20.7725
            },
            "Novi Be\u010dej": {
                "lat": 45.6,
                "lng": 20.1167
            },
            "Ose\u010dina": {
                "lat": 44.3667,
                "lng": 19.6
            },
            "Bela Palanka": {
                "lat": 43.2167,
                "lng": 22.3167
            },
            "Kosjeri\u0107": {
                "lat": 44.0,
                "lng": 19.9167
            },
            "Sremska Kamenica": {
                "lat": 45.2206,
                "lng": 19.8392
            },
            "Ba\u010dka Topola": {
                "lat": 45.8167,
                "lng": 19.6333
            },
            "Bato\u010dina": {
                "lat": 44.15,
                "lng": 21.0833
            },
            "Blace": {
                "lat": 43.2958,
                "lng": 21.2859
            },
            "Ra\u010da": {
                "lat": 44.2333,
                "lng": 20.9833
            },
            "Malo Crni\u0107e": {
                "lat": 44.5667,
                "lng": 21.2833
            },
            "Ka\u0107": {
                "lat": 45.3,
                "lng": 19.95
            },
            "Rekovac": {
                "lat": 43.8667,
                "lng": 21.1333
            },
            "Ugrinovci": {
                "lat": 44.8783,
                "lng": 20.1869
            },
            "Bela Crkva": {
                "lat": 44.8975,
                "lng": 21.4169
            },
            "La\u0107arak": {
                "lat": 45.0,
                "lng": 19.5667
            },
            "Ada": {
                "lat": 45.8,
                "lng": 20.1333
            },
            "Vrnja\u010dka Banja": {
                "lat": 43.6236,
                "lng": 20.8936
            },
            "Kanji\u017ea": {
                "lat": 46.0667,
                "lng": 20.05
            },
            "\u0106uprija": {
                "lat": 43.9333,
                "lng": 21.3667
            },
            "\u0106i\u0107evac": {
                "lat": 43.7167,
                "lng": 21.45
            },
            "Novi Banovci": {
                "lat": 44.95,
                "lng": 20.2833
            },
            "Padinska Skela": {
                "lat": 44.95,
                "lng": 20.4333
            },
            "\u017dabalj": {
                "lat": 45.3667,
                "lng": 20.0667
            },
            "Ra\u017eanj": {
                "lat": 43.6667,
                "lng": 21.55
            },
            "Od\u017eaci": {
                "lat": 45.5167,
                "lng": 19.2667
            },
            "Crvenka": {
                "lat": 45.6583,
                "lng": 19.4556
            },
            "Sremski Karlovci": {
                "lat": 45.2,
                "lng": 19.9333
            },
            "Dobanovci": {
                "lat": 44.8333,
                "lng": 20.2333
            },
            "Gad\u017ein Han": {
                "lat": 43.2228,
                "lng": 22.0322
            },
            "Golubac": {
                "lat": 44.65,
                "lng": 21.6333
            },
            "Beo\u010din": {
                "lat": 45.2,
                "lng": 19.7333
            },
            "Dimitrovgrad": {
                "lat": 43.0167,
                "lng": 22.7833
            },
            "Sokobanja": {
                "lat": 43.65,
                "lng": 21.8667
            },
            "Ba\u010dki Petrovac": {
                "lat": 45.3606,
                "lng": 19.5917
            },
            "Lapovo": {
                "lat": 44.1833,
                "lng": 21.1
            },
            "Novi Kne\u017eevac": {
                "lat": 46.05,
                "lng": 20.1
            },
            "Bogati\u0107": {
                "lat": 44.8333,
                "lng": 19.4833
            },
            "Medve\u0111a": {
                "lat": 42.8431,
                "lng": 21.5847
            },
            "Ub": {
                "lat": 44.4561,
                "lng": 20.0739
            },
            "Ba\u010d": {
                "lat": 45.3833,
                "lng": 19.2333
            },
            "Mali I\u0111o\u0161": {
                "lat": 45.7069,
                "lng": 19.6644
            },
            "Kova\u010dica": {
                "lat": 45.1117,
                "lng": 20.6214
            },
            "Irig": {
                "lat": 45.1,
                "lng": 19.85
            },
            "\u010coka": {
                "lat": 45.9333,
                "lng": 20.15
            },
            "Opovo": {
                "lat": 45.0519,
                "lng": 20.4303
            },
            "Plandi\u0161te": {
                "lat": 45.2269,
                "lng": 21.1217
            },
            "Alibunar": {
                "lat": 45.0806,
                "lng": 20.9656
            },
            "\u017diti\u0161te": {
                "lat": 45.4833,
                "lng": 20.55
            },
            "Nova Crnja": {
                "lat": 45.6667,
                "lng": 20.6
            },
            "Crna Trava": {
                "lat": 42.8101,
                "lng": 22.299
            },
            "Bosilegrad": {
                "lat": 42.5005,
                "lng": 22.4728
            },
            "\u017dagubica": {
                "lat": 44.1979,
                "lng": 21.7902
            },
            "Po\u017eega": {
                "lat": 43.8459,
                "lng": 20.0368
            },
            "Doljevac": {
                "lat": 43.1968,
                "lng": 21.8334
            },
            "Boljevac": {
                "lat": 43.8247,
                "lng": 21.9519
            },
            "Ljubovija": {
                "lat": 44.1869,
                "lng": 19.3728
            },
            "Babu\u0161nica": {
                "lat": 43.068,
                "lng": 22.4115
            },
            "Pre\u0161evo": {
                "lat": 42.3067,
                "lng": 21.65
            },
            "Ljig": {
                "lat": 44.2213,
                "lng": 20.2375
            },
            "Mali Zvornik": {
                "lat": 44.3992,
                "lng": 19.1214
            },
            "Priboj": {
                "lat": 43.5836,
                "lng": 19.5258
            },
            "Bojnik": {
                "lat": 43.0142,
                "lng": 21.718
            },
            "Koceljeva": {
                "lat": 44.4708,
                "lng": 19.807
            },
            "\u017dabari": {
                "lat": 44.3562,
                "lng": 21.2143
            },
            "Trgovi\u0161te": {
                "lat": 42.3514,
                "lng": 22.0921
            }
        }
    },
    "QA": {
        "name": "Qatar",
        "cities": {
            "Doha": {
                "lat": 25.2867,
                "lng": 51.5333
            },
            "Ar Rayy\u0101n": {
                "lat": 25.25,
                "lng": 51.4
            },
            "Laq\u0163ah": {
                "lat": 25.4175,
                "lng": 51.5075
            },
            "Ash Sh\u012b\u1e29\u0101n\u012byah": {
                "lat": 25.3722,
                "lng": 51.2047
            },
            "Al Wakrah": {
                "lat": 25.18,
                "lng": 51.61
            },
            "Umm Qur\u016bn": {
                "lat": 25.25,
                "lng": 51.3732
            },
            "Al Khawr": {
                "lat": 25.69,
                "lng": 51.51
            },
            "Nu\u2018ayjah": {
                "lat": 25.2525,
                "lng": 51.5592
            },
            "Adh Dhakh\u012brah": {
                "lat": 25.7347,
                "lng": 51.5475
            },
            "Al Jumayl\u012byah": {
                "lat": 25.6208,
                "lng": 51.0819
            },
            "Az\u0327 Z\u0327a\u2018\u0101yin": {
                "lat": 25.5669,
                "lng": 51.4847
            },
            "Umm \u015eal\u0101l \u2018Al\u012b": {
                "lat": 25.4697,
                "lng": 51.3975
            },
            "Mad\u012bnat ash Sham\u0101l": {
                "lat": 26.1167,
                "lng": 51.2167
            }
        }
    },
    "LY": {
        "name": "Libya",
        "cities": {
            "Tripoli": {
                "lat": 32.8872,
                "lng": 13.1914
            },
            "Benghazi": {
                "lat": 32.1167,
                "lng": 20.0667
            },
            "Ajd\u0101biy\u0101": {
                "lat": 30.7556,
                "lng": 20.2253
            },
            "Mi\u015fr\u0101tah": {
                "lat": 32.3775,
                "lng": 15.092
            },
            "Al Bay\u1e11\u0101\u2019": {
                "lat": 32.7664,
                "lng": 21.7417
            },
            "Al Khums": {
                "lat": 32.6497,
                "lng": 14.2644
            },
            "Az Z\u0101w\u012byah": {
                "lat": 32.7522,
                "lng": 12.7278
            },
            "Ghary\u0101n": {
                "lat": 32.1697,
                "lng": 13.0167
            },
            "Al Marj": {
                "lat": 32.5005,
                "lng": 20.83
            },
            "Tobruk": {
                "lat": 32.0761,
                "lng": 23.9614
            },
            "\u015eabr\u0101tah": {
                "lat": 32.7922,
                "lng": 12.4842
            },
            "Al Jumayl": {
                "lat": 32.8528,
                "lng": 12.0611
            },
            "Darnah": {
                "lat": 32.7636,
                "lng": 22.6361
            },
            "Janz\u016br": {
                "lat": 32.8172,
                "lng": 13.0111
            },
            "Zuw\u0101rah": {
                "lat": 32.9333,
                "lng": 12.0833
            },
            "Masall\u0101tah": {
                "lat": 32.5822,
                "lng": 14.04
            },
            "Surt": {
                "lat": 31.205,
                "lng": 16.5886
            },
            "Yafran": {
                "lat": 32.0629,
                "lng": 12.5267
            },
            "N\u0101l\u016bt": {
                "lat": 31.8685,
                "lng": 10.9812
            },
            "Ban\u012b Wal\u012bd": {
                "lat": 31.7619,
                "lng": 14.0139
            },
            "T\u0101j\u016br\u0101\u2019": {
                "lat": 32.8818,
                "lng": 13.3399
            },
            "Bir\u0101k": {
                "lat": 27.5333,
                "lng": 14.2834
            },
            "Sha\u1e29\u1e29\u0101t": {
                "lat": 32.825,
                "lng": 21.8581
            },
            "Murzuq": {
                "lat": 25.9,
                "lng": 13.9
            },
            "Awb\u0101r\u012b": {
                "lat": 26.5833,
                "lng": 12.7667
            },
            "Qa\u015fr al Qarab\u016bll\u012b": {
                "lat": 32.75,
                "lng": 13.7167
            },
            "Wadd\u0101n": {
                "lat": 29.1611,
                "lng": 16.1436
            },
            "Al Qubbah": {
                "lat": 32.7667,
                "lng": 22.25
            },
            "Al \u2018Az\u012bz\u012byah": {
                "lat": 32.5308,
                "lng": 13.0211
            },
            "Mizdah": {
                "lat": 31.445,
                "lng": 12.9831
            },
            "T\u016bkrah": {
                "lat": 32.5322,
                "lng": 20.5722
            },
            "Gh\u0101t": {
                "lat": 24.9644,
                "lng": 10.1781
            },
            "Az Zuwayt\u012bnah": {
                "lat": 30.9522,
                "lng": 20.1203
            },
            "H\u016bn": {
                "lat": 29.1268,
                "lng": 15.9477
            },
            "Qaryat al Q\u012b\u2018\u0101n": {
                "lat": 32.0167,
                "lng": 11.5
            },
            "Al Jawf": {
                "lat": 24.2167,
                "lng": 23.3
            },
            "Zal\u0163an": {
                "lat": 32.95,
                "lng": 11.8658
            },
            "Az Zint\u0101n": {
                "lat": 31.9306,
                "lng": 12.2483
            },
            "Qaryat Sul\u016bq": {
                "lat": 31.6686,
                "lng": 20.2503
            },
            "Tarh\u016bnah": {
                "lat": 32.4339,
                "lng": 13.6344
            },
            "Umm ar Rizam": {
                "lat": 32.5325,
                "lng": 23.0053
            },
            "Qam\u012bnis": {
                "lat": 31.6572,
                "lng": 20.0144
            },
            "Kiklah": {
                "lat": 32.0683,
                "lng": 12.6944
            },
            "Ghad\u0101mis": {
                "lat": 30.1333,
                "lng": 9.5
            },
            "S\u016bknah": {
                "lat": 29.0669,
                "lng": 15.7847
            },
            "As Sidrah": {
                "lat": 30.6294,
                "lng": 18.3503
            },
            "Al Bard\u012byah": {
                "lat": 31.76,
                "lng": 25.075
            },
            "Al Abraq": {
                "lat": 32.7867,
                "lng": 21.9978
            },
            "Awjilah": {
                "lat": 29.1081,
                "lng": 21.2869
            },
            "Al Jaghb\u016bb": {
                "lat": 29.7425,
                "lng": 24.5169
            },
            "Mar\u0101dah": {
                "lat": 29.2333,
                "lng": 19.2167
            },
            "Tajarh\u012b": {
                "lat": 24.2622,
                "lng": 14.5603
            },
            "Daraj": {
                "lat": 30.15,
                "lng": 10.45
            },
            "Tmassah": {
                "lat": 26.3667,
                "lng": 15.8
            },
            "Umm al \u2018Ab\u012bd": {
                "lat": 27.517,
                "lng": 15.0333
            },
            "Al \u2018Uqaylah": {
                "lat": 30.2558,
                "lng": 19.1994
            },
            "Zillah": {
                "lat": 28.5489,
                "lng": 17.5511
            },
            "Sabh\u0101": {
                "lat": 27.0389,
                "lng": 14.4264
            },
            "Idr\u012b": {
                "lat": 27.4471,
                "lng": 13.0517
            }
        }
    },
    "BI": {
        "name": "Burundi",
        "cities": {
            "Bujumbura": {
                "lat": -3.3833,
                "lng": 29.3667
            },
            "Gitega": {
                "lat": -3.426,
                "lng": 29.8436
            },
            "Ngozi": {
                "lat": -2.9083,
                "lng": 29.8269
            },
            "Rumonge": {
                "lat": -3.9667,
                "lng": 29.4333
            },
            "Zanandore": {
                "lat": -3.25,
                "lng": 30.1167
            },
            "Cibitoke": {
                "lat": -2.8886,
                "lng": 29.12
            },
            "Kayanza": {
                "lat": -2.9167,
                "lng": 29.6167
            },
            "Bubanza": {
                "lat": -3.0833,
                "lng": 29.4
            },
            "Gatumba": {
                "lat": -3.3333,
                "lng": 29.25
            },
            "Karuzi": {
                "lat": -3.1,
                "lng": 30.1667
            },
            "Kirundo": {
                "lat": -2.59,
                "lng": 30.09
            },
            "Muyinga": {
                "lat": -2.85,
                "lng": 30.3333
            },
            "Makamba": {
                "lat": -4.1333,
                "lng": 29.8
            },
            "Ruyigi": {
                "lat": -3.4833,
                "lng": 30.25
            },
            "Rutana": {
                "lat": -3.9167,
                "lng": 30.0
            },
            "Muramvya": {
                "lat": -3.25,
                "lng": 29.6
            },
            "Bururi": {
                "lat": -3.95,
                "lng": 29.6167
            },
            "Cankuzo": {
                "lat": -3.2194,
                "lng": 30.5528
            },
            "Isale": {
                "lat": -3.3444,
                "lng": 29.4833
            }
        }
    },
    "MZ": {
        "name": "Mozambique",
        "cities": {
            "Maputo": {
                "lat": -25.9667,
                "lng": 32.5833
            },
            "Matola": {
                "lat": -25.9667,
                "lng": 32.4667
            },
            "Nampula": {
                "lat": -15.1167,
                "lng": 39.2667
            },
            "Beira": {
                "lat": -19.8333,
                "lng": 34.85
            },
            "Ant\u00f3nio Enes": {
                "lat": -16.2333,
                "lng": 39.9167
            },
            "Chimoio": {
                "lat": -19.1167,
                "lng": 33.45
            },
            "Cidade de Nacala": {
                "lat": -14.5428,
                "lng": 40.6728
            },
            "Malema": {
                "lat": -14.9486,
                "lng": 37.4144
            },
            "Quelimane": {
                "lat": -17.8764,
                "lng": 36.8872
            },
            "Tete": {
                "lat": -16.1667,
                "lng": 33.6
            },
            "Vila Junqueiro": {
                "lat": -15.4667,
                "lng": 36.9833
            },
            "Lichinga": {
                "lat": -13.3167,
                "lng": 35.2333
            },
            "Pemba": {
                "lat": -12.9667,
                "lng": 40.55
            },
            "Xai-Xai": {
                "lat": -25.05,
                "lng": 33.65
            },
            "Maxixe": {
                "lat": -23.8667,
                "lng": 35.35
            },
            "Cuamba": {
                "lat": -14.8167,
                "lng": 36.55
            },
            "Montepuez": {
                "lat": -13.1167,
                "lng": 39.0
            },
            "Dondo": {
                "lat": -19.6167,
                "lng": 34.75
            },
            "Mocuba": {
                "lat": -16.8496,
                "lng": 38.26
            },
            "Inhambane": {
                "lat": -23.865,
                "lng": 35.3833
            },
            "Chokw\u00e9": {
                "lat": -24.5253,
                "lng": 33.0086
            },
            "Manhi\u00e7a": {
                "lat": -25.4,
                "lng": 32.8
            },
            "Pebane": {
                "lat": -17.2667,
                "lng": 38.1667
            },
            "Chibuto": {
                "lat": -24.6833,
                "lng": 33.5333
            },
            "Moatize": {
                "lat": -16.1167,
                "lng": 33.7333
            },
            "Songo": {
                "lat": -15.6142,
                "lng": 32.7709
            },
            "Macomia": {
                "lat": -12.2377,
                "lng": 40.119
            },
            "Zumbo": {
                "lat": -15.6167,
                "lng": 30.45
            },
            "Moc\u00edmboa da Praia": {
                "lat": -11.35,
                "lng": 40.3333
            },
            "Namaacha": {
                "lat": -25.9667,
                "lng": 32.0333
            },
            "Catandica": {
                "lat": -18.0564,
                "lng": 33.1747
            },
            "Moc\u00edmboa": {
                "lat": -11.3196,
                "lng": 40.35
            },
            "Manjacaze": {
                "lat": -24.7117,
                "lng": 33.8828
            },
            "Palma": {
                "lat": -10.7758,
                "lng": 40.4667
            },
            "Ulongu\u00e9": {
                "lat": -14.7167,
                "lng": 34.3667
            },
            "Catembe": {
                "lat": -26.005,
                "lng": 32.5597
            },
            "Vilankulo": {
                "lat": -22.0,
                "lng": 35.3167
            },
            "Macia": {
                "lat": -25.0333,
                "lng": 33.1
            },
            "Mapai": {
                "lat": -22.7306,
                "lng": 32.0583
            },
            "Chinde": {
                "lat": -18.5833,
                "lng": 36.4667
            },
            "Ilha de Mo\u00e7ambique": {
                "lat": -15.0367,
                "lng": 40.7328
            },
            "Ancuabe": {
                "lat": -12.9833,
                "lng": 39.85
            },
            "Inhassoro": {
                "lat": -21.5333,
                "lng": 35.2
            },
            "Z\u00e1vora": {
                "lat": -24.5167,
                "lng": 35.2
            },
            "Marrupa": {
                "lat": -13.1833,
                "lng": 37.5
            },
            "Nicoadala": {
                "lat": -17.6077,
                "lng": 36.8197
            },
            "Manica": {
                "lat": -18.9344,
                "lng": 32.8756
            },
            "Ligonha": {
                "lat": -15.1757,
                "lng": 37.74
            },
            "Regedor Quissico": {
                "lat": -24.7257,
                "lng": 34.766
            },
            "Massangena": {
                "lat": -21.5378,
                "lng": 32.9564
            },
            "Panda": {
                "lat": -24.0629,
                "lng": 34.7303
            },
            "Chiramba": {
                "lat": -16.8921,
                "lng": 34.6559
            },
            "Espungabera": {
                "lat": -20.4531,
                "lng": 32.7725
            }
        }
    },
    "KG": {
        "name": "Kyrgyzstan",
        "cities": {
            "Bishkek": {
                "lat": 42.8747,
                "lng": 74.6122
            },
            "Osh": {
                "lat": 40.53,
                "lng": 72.8
            },
            "Jalal-Abad": {
                "lat": 40.9333,
                "lng": 73.0
            },
            "Karakol": {
                "lat": 42.4903,
                "lng": 78.3917
            },
            "Tokmok": {
                "lat": 42.8333,
                "lng": 75.2833
            },
            "Kyzyl-Kyya": {
                "lat": 40.2611,
                "lng": 72.1306
            },
            "\u00d6zg\u00f6n": {
                "lat": 40.7667,
                "lng": 73.3
            },
            "Kara-Balta": {
                "lat": 42.8333,
                "lng": 73.85
            },
            "Balykchy": {
                "lat": 42.4611,
                "lng": 76.1803
            },
            "Bazar-Korgon": {
                "lat": 41.0417,
                "lng": 72.7472
            },
            "Talas": {
                "lat": 42.5167,
                "lng": 72.2333
            },
            "Naryn": {
                "lat": 41.1306,
                "lng": 72.0806
            },
            "Isfana": {
                "lat": 39.8372,
                "lng": 69.5333
            },
            "Aravan": {
                "lat": 40.515,
                "lng": 72.4992
            },
            "Sokuluk": {
                "lat": 42.86,
                "lng": 74.3
            },
            "Suzak": {
                "lat": 40.8981,
                "lng": 72.9047
            },
            "Batken": {
                "lat": 40.0667,
                "lng": 70.8167
            },
            "Iradan": {
                "lat": 40.26,
                "lng": 72.1
            },
            "Novopokrovka": {
                "lat": 42.8708,
                "lng": 74.7508
            },
            "Kant": {
                "lat": 42.8833,
                "lng": 74.85
            },
            "Belovodskoe": {
                "lat": 42.83,
                "lng": 74.1
            },
            "Shark": {
                "lat": 40.5569,
                "lng": 72.8264
            },
            "Jangy-Nookat": {
                "lat": 40.25,
                "lng": 72.55
            },
            "Kara-Suu": {
                "lat": 40.7,
                "lng": 72.8833
            },
            "Lebedinovka": {
                "lat": 42.88,
                "lng": 74.68
            },
            "Toktogul": {
                "lat": 41.8722,
                "lng": 72.9389
            },
            "Kurshab": {
                "lat": 40.6917,
                "lng": 73.1889
            },
            "Kashkar-Kyshtak": {
                "lat": 40.62,
                "lng": 72.83
            },
            "Mayluu-Suu": {
                "lat": 41.2457,
                "lng": 72.448
            },
            "Voyenno-Antonovka": {
                "lat": 42.8722,
                "lng": 74.45
            },
            "Massy": {
                "lat": 41.0608,
                "lng": 72.6328
            },
            "Tash-K\u00f6m\u00fcr": {
                "lat": 41.35,
                "lng": 72.2167
            },
            "Kara-K\u00f6l": {
                "lat": 41.6311,
                "lng": 72.6686
            },
            "Novopavlovka": {
                "lat": 42.87,
                "lng": 74.48
            },
            "Eski-Nookat": {
                "lat": 40.2658,
                "lng": 72.6181
            },
            "Ivanovka": {
                "lat": 42.8864,
                "lng": 75.0822
            },
            "Myrza-Ake": {
                "lat": 40.75,
                "lng": 73.41
            },
            "Kara-Kulja": {
                "lat": 40.6333,
                "lng": 73.5925
            },
            "Kyzyl-Suu": {
                "lat": 42.3425,
                "lng": 78.0047
            },
            "Ala-Buka": {
                "lat": 41.4083,
                "lng": 71.4806
            },
            "Kochkor-Ata": {
                "lat": 41.0319,
                "lng": 72.4833
            },
            "Uch-Korgon": {
                "lat": 40.23,
                "lng": 72.06
            },
            "At-Bashy": {
                "lat": 41.17,
                "lng": 75.8
            },
            "Aleksandrovka": {
                "lat": 42.8528,
                "lng": 74.2194
            },
            "B\u00f6k\u00f6nbaev": {
                "lat": 42.11,
                "lng": 76.99
            },
            "Kerben": {
                "lat": 41.5,
                "lng": 71.75
            },
            "Teploklyuchenka": {
                "lat": 42.5,
                "lng": 78.525
            },
            "Frunze": {
                "lat": 40.1267,
                "lng": 71.7236
            },
            "G\u00fclch\u00f6": {
                "lat": 40.3167,
                "lng": 73.4333
            },
            "Kyzyl-Adyr": {
                "lat": 42.62,
                "lng": 71.59
            },
            "Dyykan-Kyshtak": {
                "lat": 40.51,
                "lng": 72.78
            },
            "S\u00fcl\u00fckt\u00fc": {
                "lat": 39.94,
                "lng": 69.56
            },
            "Sovetskoe": {
                "lat": 41.06,
                "lng": 72.73
            },
            "Kara-Kyshtak": {
                "lat": 40.11,
                "lng": 72.1
            },
            "Ch\u00fcy": {
                "lat": 42.81,
                "lng": 75.28
            },
            "T\u00fcp": {
                "lat": 42.73,
                "lng": 78.36
            },
            "Shamaldy-Say": {
                "lat": 41.1972,
                "lng": 72.1803
            },
            "K\u00f6k-Janggak": {
                "lat": 41.0333,
                "lng": 73.2
            },
            "Nariman": {
                "lat": 40.5972,
                "lng": 72.7972
            },
            "Kyzyl-Kyshtak": {
                "lat": 40.5444,
                "lng": 72.7739
            },
            "Alamedin": {
                "lat": 42.89,
                "lng": 74.63
            },
            "Jangy-Kyshtak": {
                "lat": 40.55,
                "lng": 72.7306
            },
            "Cholpon-Ata": {
                "lat": 42.65,
                "lng": 77.0833
            },
            "Bek-Abad": {
                "lat": 40.8472,
                "lng": 72.975
            },
            "Buzhum": {
                "lat": 40.01,
                "lng": 70.83
            },
            "Kochkor": {
                "lat": 42.2158,
                "lng": 75.7556
            },
            "Arslanbob": {
                "lat": 41.3333,
                "lng": 72.9333
            },
            "Kazarman": {
                "lat": 41.4,
                "lng": 74.05
            },
            "Imeni Chapayeva": {
                "lat": 40.2778,
                "lng": 72.6361
            },
            "Petrovka": {
                "lat": 42.8389,
                "lng": 74.0194
            },
            "Kara-Tash": {
                "lat": 40.2167,
                "lng": 72.6306
            },
            "G\u00fclbaar": {
                "lat": 40.48,
                "lng": 72.65
            },
            "Baetov": {
                "lat": 41.21,
                "lng": 74.9
            },
            "Leninskoe": {
                "lat": 42.9806,
                "lng": 74.6722
            },
            "Sadovoye": {
                "lat": 42.8528,
                "lng": 74.1722
            },
            "Maevka": {
                "lat": 42.925,
                "lng": 74.5694
            },
            "Kemin": {
                "lat": 42.7861,
                "lng": 75.6917
            },
            "Aydarken": {
                "lat": 39.94,
                "lng": 71.33
            },
            "Chong-Aryk": {
                "lat": 42.8139,
                "lng": 74.5725
            },
            "Kulundu": {
                "lat": 40.1069,
                "lng": 69.6431
            },
            "Ak-Suu": {
                "lat": 42.8,
                "lng": 74.1
            },
            "Kayyngdy": {
                "lat": 42.83,
                "lng": 73.68
            },
            "Barskoon": {
                "lat": 42.1561,
                "lng": 77.6039
            },
            "Anan\u2019evo": {
                "lat": 42.73,
                "lng": 77.66
            },
            "Tepe-Korgon": {
                "lat": 40.6,
                "lng": 72.4
            },
            "Kara-Bak": {
                "lat": 40.1583,
                "lng": 70.8167
            },
            "Ladan Kara": {
                "lat": 40.9264,
                "lng": 72.9569
            },
            "Shopokov": {
                "lat": 42.84,
                "lng": 74.31
            },
            "Karavan": {
                "lat": 40.2944,
                "lng": 72.1861
            },
            "Panfilovka": {
                "lat": 42.7917,
                "lng": 73.6778
            },
            "Vostochnyy": {
                "lat": 39.9222,
                "lng": 69.65
            },
            "Pokrovka": {
                "lat": 42.75,
                "lng": 71.6
            },
            "Saruu": {
                "lat": 42.3222,
                "lng": 77.9222
            }
        }
    },
    "GE": {
        "name": "Georgia",
        "cities": {
            "Tbilisi": {
                "lat": 41.7225,
                "lng": 44.7925
            },
            "Batumi": {
                "lat": 41.6458,
                "lng": 41.6417
            },
            "Kutaisi": {
                "lat": 42.25,
                "lng": 42.7
            },
            "Rustavi": {
                "lat": 41.5436,
                "lng": 45.0117
            },
            "Sokhumi": {
                "lat": 43.0,
                "lng": 41.0167
            },
            "Iormughanlo": {
                "lat": 41.5961,
                "lng": 45.5294
            },
            "Gori": {
                "lat": 41.9667,
                "lng": 44.1
            },
            "Poti": {
                "lat": 42.15,
                "lng": 41.6667
            },
            "Zugdidi": {
                "lat": 42.5083,
                "lng": 41.8667
            },
            "Tskhinvali": {
                "lat": 42.225,
                "lng": 43.97
            },
            "Kobuleti": {
                "lat": 41.8111,
                "lng": 41.7753
            },
            "Marneuli": {
                "lat": 41.4969,
                "lng": 44.8108
            },
            "Khashuri": {
                "lat": 41.9975,
                "lng": 43.5986
            },
            "Samtredia": {
                "lat": 42.1625,
                "lng": 42.3417
            },
            "Senaki": {
                "lat": 42.2689,
                "lng": 42.0792
            },
            "Zestaponi": {
                "lat": 42.1083,
                "lng": 43.0417
            },
            "Telavi": {
                "lat": 41.9167,
                "lng": 45.4833
            },
            "Chiatura": {
                "lat": 42.2903,
                "lng": 43.2819
            },
            "Kaspi": {
                "lat": 41.925,
                "lng": 44.4222
            },
            "Borjomi": {
                "lat": 41.8389,
                "lng": 43.3792
            },
            "Sagarejo": {
                "lat": 41.7333,
                "lng": 45.3333
            },
            "Akhaltsikhe": {
                "lat": 41.6389,
                "lng": 42.9861
            },
            "Mtskheta": {
                "lat": 41.8464,
                "lng": 44.7194
            },
            "Ambrolauri": {
                "lat": 42.5194,
                "lng": 43.15
            },
            "Ozurgeti": {
                "lat": 41.9406,
                "lng": 41.9906
            }
        }
    },
    "TD": {
        "name": "Chad",
        "cities": {
            "N\u2019Djamena": {
                "lat": 12.11,
                "lng": 15.05
            },
            "Moundou": {
                "lat": 8.5667,
                "lng": 16.0833
            },
            "Sarh": {
                "lat": 9.15,
                "lng": 18.3833
            },
            "Yao": {
                "lat": 12.8508,
                "lng": 17.5608
            },
            "L\u00e9r\u00e9": {
                "lat": 9.6572,
                "lng": 14.2283
            },
            "Ab\u00e9ch\u00e9": {
                "lat": 13.8331,
                "lng": 20.8347
            },
            "Goz-Beida": {
                "lat": 12.2236,
                "lng": 21.4144
            },
            "Ch\u00e8ddra": {
                "lat": 13.4428,
                "lng": 16.0508
            },
            "D\u00e9ressia": {
                "lat": 9.7603,
                "lng": 16.2697
            },
            "Faya": {
                "lat": 17.9169,
                "lng": 19.1167
            },
            "Kelo": {
                "lat": 9.31,
                "lng": 15.81
            },
            "Fianga": {
                "lat": 9.9153,
                "lng": 15.1375
            },
            "Koumra": {
                "lat": 8.91,
                "lng": 17.55
            },
            "Pala Oua": {
                "lat": 9.3625,
                "lng": 14.9106
            },
            "Am-Timan": {
                "lat": 11.0333,
                "lng": 20.2833
            },
            "Iriba": {
                "lat": 15.1167,
                "lng": 22.25
            },
            "Bongor": {
                "lat": 10.2806,
                "lng": 15.3722
            },
            "Mongo": {
                "lat": 12.1837,
                "lng": 18.7
            },
            "Oum Hadjer": {
                "lat": 13.2944,
                "lng": 19.6914
            },
            "Doba": {
                "lat": 8.66,
                "lng": 16.85
            },
            "Fada": {
                "lat": 17.1833,
                "lng": 21.5833
            },
            "Amdjarass": {
                "lat": 16.0658,
                "lng": 22.8431
            },
            "Ati": {
                "lat": 13.2133,
                "lng": 18.3381
            },
            "Mao": {
                "lat": 14.1194,
                "lng": 15.3133
            },
            "Massaguet": {
                "lat": 12.4742,
                "lng": 15.4428
            },
            "Bitkine": {
                "lat": 11.9817,
                "lng": 18.2125
            },
            "Dourbali": {
                "lat": 11.805,
                "lng": 15.8647
            },
            "Moussoro": {
                "lat": 13.6431,
                "lng": 16.4919
            },
            "Guelendeng": {
                "lat": 10.9183,
                "lng": 15.5486
            },
            "Massakory": {
                "lat": 13.0,
                "lng": 15.7333
            },
            "Kyab\u00e9": {
                "lat": 9.4514,
                "lng": 18.9447
            },
            "Bokoro": {
                "lat": 12.3667,
                "lng": 17.05
            },
            "B\u00e9r\u00e9": {
                "lat": 9.3156,
                "lng": 16.1597
            },
            "Bousso": {
                "lat": 10.4825,
                "lng": 16.7161
            },
            "La\u00ef": {
                "lat": 9.4,
                "lng": 16.3
            },
            "Mata": {
                "lat": 14.0436,
                "lng": 21.1611
            },
            "Adr\u00e9": {
                "lat": 13.4667,
                "lng": 22.2
            },
            "Benoy": {
                "lat": 8.9908,
                "lng": 16.3169
            },
            "Biltine": {
                "lat": 14.5275,
                "lng": 20.9267
            },
            "Bol": {
                "lat": 13.46,
                "lng": 14.74
            },
            "Mangalm\u00e9": {
                "lat": 12.3547,
                "lng": 19.6139
            },
            "Zouar": {
                "lat": 20.45,
                "lng": 16.5167
            },
            "Massenya": {
                "lat": 11.4,
                "lng": 16.1667
            },
            "Pala": {
                "lat": 9.3646,
                "lng": 14.9073
            },
            "Barda\u00ef": {
                "lat": 21.3533,
                "lng": 17.0003
            }
        }
    },
    "MR": {
        "name": "Mauritania",
        "cities": {
            "Nouakchott": {
                "lat": 18.0858,
                "lng": -15.9785
            },
            "Arafat": {
                "lat": 18.0464,
                "lng": -15.9719
            },
            "Dar Naim": {
                "lat": 18.0333,
                "lng": -15.9667
            },
            "Nouadhibou": {
                "lat": 20.9333,
                "lng": -17.0333
            },
            "Kiffa": {
                "lat": 16.63,
                "lng": -11.4
            },
            "Rosso": {
                "lat": 16.5128,
                "lng": -15.805
            },
            "Ka\u00e9di": {
                "lat": 16.1506,
                "lng": -13.5056
            },
            "Zouerate": {
                "lat": 22.7333,
                "lng": -12.4667
            },
            "Ksar": {
                "lat": 18.1022,
                "lng": -15.955
            },
            "S\u00e9libaby": {
                "lat": 15.1592,
                "lng": -12.1833
            },
            "Aioun": {
                "lat": 16.6667,
                "lng": -9.6167
            },
            "Atar": {
                "lat": 20.5167,
                "lng": -13.05
            },
            "Gu\u00e9rou": {
                "lat": 16.8167,
                "lng": -11.8333
            },
            "N\u00e9ma": {
                "lat": 16.6167,
                "lng": -7.25
            },
            "Timbedgha": {
                "lat": 16.25,
                "lng": -8.1667
            },
            "Tidjikja": {
                "lat": 18.55,
                "lng": -11.4333
            },
            "Aleg": {
                "lat": 17.05,
                "lng": -13.9167
            },
            "Boutilimit": {
                "lat": 17.5504,
                "lng": -14.7
            },
            "Akjoujt": {
                "lat": 19.75,
                "lng": -14.3833
            },
            "Bogu\u00e9": {
                "lat": 16.5904,
                "lng": -14.27
            },
            "Fd\u00e9rik": {
                "lat": 22.6783,
                "lng": -12.7114
            },
            "B\u00eer Mogre\u00efn": {
                "lat": 25.2167,
                "lng": -11.6167
            },
            "Chegga": {
                "lat": 25.3733,
                "lng": -5.7873
            },
            "Tevragh Zeina": {
                "lat": 18.0994,
                "lng": -15.9761
            }
        }
    },
    "AM": {
        "name": "Armenia",
        "cities": {
            "Yerevan": {
                "lat": 40.1814,
                "lng": 44.5144
            },
            "Gyumri": {
                "lat": 40.7894,
                "lng": 43.8475
            },
            "Vanadzor": {
                "lat": 40.8128,
                "lng": 44.4883
            },
            "Ejmiatsin": {
                "lat": 40.1728,
                "lng": 44.2925
            },
            "Hrazdan": {
                "lat": 40.5,
                "lng": 44.7667
            },
            "Abovyan": {
                "lat": 40.2739,
                "lng": 44.6256
            },
            "Kapan": {
                "lat": 39.2011,
                "lng": 46.415
            },
            "Armavir": {
                "lat": 40.15,
                "lng": 44.04
            },
            "Charentsavan": {
                "lat": 40.4097,
                "lng": 44.6431
            },
            "Stepanavan": {
                "lat": 41.0096,
                "lng": 44.3841
            },
            "Sevan": {
                "lat": 40.555,
                "lng": 44.9536
            },
            "Ashtarak": {
                "lat": 40.2975,
                "lng": 44.3617
            },
            "Masis": {
                "lat": 40.0633,
                "lng": 44.4408
            },
            "Artashat": {
                "lat": 39.9539,
                "lng": 44.5506
            },
            "Ararat": {
                "lat": 39.8303,
                "lng": 44.7025
            },
            "Gavarr": {
                "lat": 40.3589,
                "lng": 45.1267
            },
            "Goris": {
                "lat": 39.5078,
                "lng": 46.3386
            },
            "Ijevan": {
                "lat": 40.8792,
                "lng": 45.1472
            },
            "Artik": {
                "lat": 40.6172,
                "lng": 43.9758
            },
            "Dilijan": {
                "lat": 40.7408,
                "lng": 44.8631
            },
            "Spitak": {
                "lat": 40.8372,
                "lng": 44.2675
            },
            "Sisian": {
                "lat": 39.5208,
                "lng": 46.0322
            },
            "Vedi": {
                "lat": 39.9106,
                "lng": 44.7278
            },
            "Alaverdi": {
                "lat": 41.1333,
                "lng": 44.65
            },
            "Vardenis": {
                "lat": 40.1806,
                "lng": 45.72
            },
            "Martuni": {
                "lat": 40.14,
                "lng": 45.3064
            },
            "Yeghvard": {
                "lat": 40.3217,
                "lng": 44.4814
            },
            "Vardenik": {
                "lat": 40.1331,
                "lng": 45.4367
            },
            "Metsamor": {
                "lat": 40.1428,
                "lng": 44.1164
            },
            "Berd": {
                "lat": 40.8808,
                "lng": 45.3917
            },
            "Byureghavan": {
                "lat": 40.3147,
                "lng": 44.5936
            },
            "Nor Hachn": {
                "lat": 40.3019,
                "lng": 44.5831
            },
            "Tashir": {
                "lat": 41.1244,
                "lng": 44.2819
            },
            "Nerk\u2019in Getashen": {
                "lat": 40.1467,
                "lng": 45.2642
            },
            "Yeghegnadzor": {
                "lat": 39.7611,
                "lng": 45.3333
            }
        }
    },
    "NO": {
        "name": "Norway",
        "cities": {
            "Oslo": {
                "lat": 59.9133,
                "lng": 10.7389
            },
            "Bergen": {
                "lat": 60.3894,
                "lng": 5.33
            },
            "Stavanger": {
                "lat": 58.97,
                "lng": 5.7314
            },
            "Sandnes": {
                "lat": 58.8517,
                "lng": 5.7361
            },
            "Trondheim": {
                "lat": 63.4297,
                "lng": 10.3933
            },
            "Kristiansand": {
                "lat": 58.1472,
                "lng": 7.9972
            },
            "Sandvika": {
                "lat": 59.8833,
                "lng": 10.5167
            },
            "Drammen": {
                "lat": 59.7378,
                "lng": 10.205
            },
            "Fredrikstad": {
                "lat": 59.2053,
                "lng": 10.95
            },
            "Asker": {
                "lat": 59.8353,
                "lng": 10.435
            },
            "Nordre F\u00e5le": {
                "lat": 59.75,
                "lng": 10.8667
            },
            "Sarpsborg": {
                "lat": 59.2858,
                "lng": 11.1119
            },
            "T\u00f8nsberg": {
                "lat": 59.2675,
                "lng": 10.4076
            },
            "\u00c5lesund": {
                "lat": 62.474,
                "lng": 6.1582
            },
            "Skien": {
                "lat": 59.2081,
                "lng": 9.5528
            },
            "L\u00f8renskog": {
                "lat": 59.8989,
                "lng": 10.9642
            },
            "Arendal": {
                "lat": 58.4608,
                "lng": 8.7664
            },
            "Haugesund": {
                "lat": 59.4464,
                "lng": 5.2983
            },
            "Sandefjord": {
                "lat": 59.1306,
                "lng": 10.2167
            },
            "Bod\u00f8": {
                "lat": 67.2827,
                "lng": 14.3751
            },
            "Troms\u00f8": {
                "lat": 69.6828,
                "lng": 18.9428
            },
            "Porsgrunn": {
                "lat": 59.1156,
                "lng": 9.71
            },
            "Ringsaker": {
                "lat": 61.0242,
                "lng": 10.8019
            },
            "Halden": {
                "lat": 59.1264,
                "lng": 11.4828
            },
            "Ask\u00f8y": {
                "lat": 60.4714,
                "lng": 5.1606
            },
            "Ski": {
                "lat": 59.7419,
                "lng": 10.8939
            },
            "Lier": {
                "lat": 59.7938,
                "lng": 10.266
            },
            "Hamar": {
                "lat": 60.7945,
                "lng": 11.0679
            },
            "Oppeg\u00e5rd": {
                "lat": 59.7925,
                "lng": 10.7903
            },
            "Kongsberg": {
                "lat": 59.6694,
                "lng": 9.6517
            },
            "Sand": {
                "lat": 59.1343,
                "lng": 10.2244
            },
            "Eidsvoll": {
                "lat": 60.35,
                "lng": 11.25
            },
            "Larvik": {
                "lat": 59.0811,
                "lng": 10.0164
            },
            "Stj\u00f8rdal": {
                "lat": 63.475,
                "lng": 11.1708
            },
            "Borre": {
                "lat": 59.3819,
                "lng": 10.438
            },
            "Helleland": {
                "lat": 58.8922,
                "lng": 5.7336
            },
            "R\u00f8yken": {
                "lat": 59.7336,
                "lng": 10.4289
            },
            "N\u00f8tter\u00f8y": {
                "lat": 59.2011,
                "lng": 10.4078
            },
            "Elverum": {
                "lat": 60.8819,
                "lng": 11.5623
            },
            "Nedre Eiker": {
                "lat": 59.7647,
                "lng": 10.0333
            },
            "Stange": {
                "lat": 60.7158,
                "lng": 11.1903
            },
            "Molde": {
                "lat": 62.7375,
                "lng": 7.1591
            },
            "Harstad": {
                "lat": 68.8011,
                "lng": 16.5441
            },
            "\u00c5s": {
                "lat": 59.6603,
                "lng": 10.7836
            },
            "Kleppe": {
                "lat": 58.7772,
                "lng": 5.5967
            },
            "Gj\u00f8vik": {
                "lat": 60.7957,
                "lng": 10.6915
            },
            "Lillehammer": {
                "lat": 61.1167,
                "lng": 10.4667
            },
            "Horten": {
                "lat": 59.4208,
                "lng": 10.4328
            },
            "Fr\u00f8yland": {
                "lat": 58.9167,
                "lng": 5.85
            },
            "H\u00f8yland": {
                "lat": 58.8308,
                "lng": 5.7506
            },
            "Nesoddtangen": {
                "lat": 59.8086,
                "lng": 10.6556
            },
            "Time": {
                "lat": 58.7228,
                "lng": 5.7653
            },
            "Os": {
                "lat": 60.2269,
                "lng": 5.4758
            },
            "Nittedal": {
                "lat": 60.0731,
                "lng": 10.8722
            },
            "Stord": {
                "lat": 59.8081,
                "lng": 5.4664
            },
            "Mo i Rana": {
                "lat": 66.3128,
                "lng": 14.1428
            },
            "Kristiansund": {
                "lat": 63.1104,
                "lng": 7.7279
            },
            "\u00c5sane": {
                "lat": 60.4711,
                "lng": 5.3272
            },
            "Kosvik": {
                "lat": 58.1418,
                "lng": 8.0802
            },
            "Tromsdalen": {
                "lat": 69.6442,
                "lng": 18.9992
            },
            "H\u00f8nefoss": {
                "lat": 60.1667,
                "lng": 10.3
            },
            "Rygge": {
                "lat": 59.3747,
                "lng": 10.7147
            },
            "Voss": {
                "lat": 60.7025,
                "lng": 6.4231
            },
            "Frogn": {
                "lat": 59.6989,
                "lng": 10.6553
            },
            "Lind\u00e5s": {
                "lat": 60.6247,
                "lng": 5.3283
            },
            "Nannestad": {
                "lat": 60.2456,
                "lng": 10.9528
            },
            "Alta": {
                "lat": 69.9689,
                "lng": 23.2716
            },
            "Vennesla": {
                "lat": 58.3106,
                "lng": 7.8569
            },
            "Verdal": {
                "lat": 63.793,
                "lng": 11.48
            },
            "Malvik": {
                "lat": 63.3728,
                "lng": 10.7508
            },
            "Askim": {
                "lat": 59.5861,
                "lng": 11.17
            },
            "Bamble": {
                "lat": 59.0197,
                "lng": 9.5608
            },
            "Narvik": {
                "lat": 68.4363,
                "lng": 17.3983
            },
            "Leirvik": {
                "lat": 59.7798,
                "lng": 5.5005
            },
            "Gran": {
                "lat": 60.4411,
                "lng": 10.4956
            },
            "Strand": {
                "lat": 59.0633,
                "lng": 6.0278
            },
            "Dr\u00f8bak": {
                "lat": 59.6667,
                "lng": 10.6333
            },
            "F\u00e5berg": {
                "lat": 61.1684,
                "lng": 10.4048
            },
            "Grimstad": {
                "lat": 58.3405,
                "lng": 8.5934
            },
            "Vestby": {
                "lat": 59.575,
                "lng": 10.7319
            },
            "Stj\u00f8rdalshalsen": {
                "lat": 63.4712,
                "lng": 10.9189
            },
            "Steinkjer": {
                "lat": 64.0148,
                "lng": 11.4954
            },
            "S\u00f8rum": {
                "lat": 60.0058,
                "lng": 11.2594
            },
            "Bryne": {
                "lat": 58.7354,
                "lng": 5.6477
            },
            "Sogndal": {
                "lat": 61.2422,
                "lng": 7.055
            },
            "B\u00f8mlo": {
                "lat": 59.7794,
                "lng": 5.2183
            },
            "Kongsvinger": {
                "lat": 60.1905,
                "lng": 11.9977
            },
            "Randaberg": {
                "lat": 59.0017,
                "lng": 5.6153
            },
            "Stokke": {
                "lat": 59.24,
                "lng": 10.2708
            },
            "Lenvik": {
                "lat": 69.3836,
                "lng": 17.9675
            },
            "Kopervik": {
                "lat": 59.2801,
                "lng": 5.3016
            },
            "Egersund": {
                "lat": 58.4497,
                "lng": 6.0087
            },
            "Tysv\u00e6r": {
                "lat": 59.3617,
                "lng": 5.5428
            },
            "Enebakk": {
                "lat": 59.7742,
                "lng": 11.1019
            },
            "S\u00f8gne": {
                "lat": 58.0942,
                "lng": 7.7725
            },
            "Lommedalen": {
                "lat": 59.95,
                "lng": 10.4667
            },
            "Mandal": {
                "lat": 58.0267,
                "lng": 7.4533
            },
            "Brumunddal": {
                "lat": 60.8836,
                "lng": 10.9449
            },
            "Volda": {
                "lat": 62.0878,
                "lng": 6.0142
            },
            "\u00d8rsta": {
                "lat": 62.2003,
                "lng": 6.1322
            },
            "Lyngdal": {
                "lat": 58.1692,
                "lng": 7.0558
            },
            "Krager\u00f8": {
                "lat": 58.8869,
                "lng": 9.3469
            },
            "F\u00f8rde": {
                "lat": 61.4522,
                "lng": 5.8572
            },
            "Levanger": {
                "lat": 63.7465,
                "lng": 11.2987
            },
            "Eidsberg": {
                "lat": 59.5369,
                "lng": 11.3603
            },
            "Farsund": {
                "lat": 58.0947,
                "lng": 6.8044
            },
            "Mosj\u00f8en": {
                "lat": 65.837,
                "lng": 13.1913
            },
            "Sande": {
                "lat": 59.5972,
                "lng": 10.2236
            },
            "Fr\u00f8n": {
                "lat": 61.5901,
                "lng": 9.7764
            },
            "Fauske": {
                "lat": 67.2594,
                "lng": 15.3933
            },
            "Notodden": {
                "lat": 59.6294,
                "lng": 9.1914
            },
            "Lunner": {
                "lat": 60.2528,
                "lng": 10.6672
            },
            "Flor\u00f8": {
                "lat": 61.5996,
                "lng": 5.0328
            },
            "Ulstein": {
                "lat": 62.3564,
                "lng": 5.8539
            },
            "Kaldsletta": {
                "lat": 69.6956,
                "lng": 18.8836
            },
            "Namsos": {
                "lat": 64.4656,
                "lng": 11.4978
            },
            "Hammerfest": {
                "lat": 70.6634,
                "lng": 23.6821
            },
            "Moss": {
                "lat": 59.4592,
                "lng": 10.7008
            },
            "Vossevangen": {
                "lat": 60.63,
                "lng": 6.441
            },
            "Vads\u00f8": {
                "lat": 70.0803,
                "lng": 29.7315
            },
            "Svolv\u00e6r": {
                "lat": 68.2353,
                "lng": 14.5636
            },
            "Finnsnes": {
                "lat": 69.2294,
                "lng": 17.9811
            },
            "Kirkenes": {
                "lat": 69.7269,
                "lng": 30.0456
            },
            "R\u00f8rvik": {
                "lat": 64.8619,
                "lng": 11.2397
            }
        }
    },
    "NI": {
        "name": "Nicaragua",
        "cities": {
            "Managua": {
                "lat": 12.1364,
                "lng": -86.2514
            },
            "Le\u00f3n": {
                "lat": 12.4333,
                "lng": -86.8867
            },
            "Tipitapa": {
                "lat": 12.2,
                "lng": -86.1
            },
            "Masaya": {
                "lat": 11.9667,
                "lng": -86.1
            },
            "Matagalpa": {
                "lat": 12.9167,
                "lng": -85.9167
            },
            "Ciudad Sandino": {
                "lat": 12.1655,
                "lng": -86.3579
            },
            "Chinandega": {
                "lat": 12.6167,
                "lng": -87.15
            },
            "Estel\u00ed": {
                "lat": 13.0833,
                "lng": -86.35
            },
            "Granada": {
                "lat": 11.9333,
                "lng": -85.95
            },
            "Jinotega": {
                "lat": 13.0884,
                "lng": -85.9994
            },
            "Bilwi": {
                "lat": 14.0281,
                "lng": -83.3808
            },
            "El Viejo": {
                "lat": 12.6667,
                "lng": -87.1667
            },
            "Nueva Guinea": {
                "lat": 11.6833,
                "lng": -84.45
            },
            "Siuna": {
                "lat": 13.7333,
                "lng": -84.7667
            },
            "Mateare": {
                "lat": 12.2333,
                "lng": -86.4333
            },
            "Diriamba": {
                "lat": 11.85,
                "lng": -86.2333
            },
            "Wiwil\u00ed": {
                "lat": 13.6167,
                "lng": -85.8333
            },
            "Bluefields": {
                "lat": 12.0,
                "lng": -83.75
            },
            "Jalapa": {
                "lat": 13.9167,
                "lng": -86.1333
            },
            "Juigalpa": {
                "lat": 12.1,
                "lng": -85.3667
            },
            "El Rama": {
                "lat": 12.1615,
                "lng": -84.2203
            },
            "Boaco": {
                "lat": 12.4667,
                "lng": -85.6667
            },
            "Mulukuk\u00fa": {
                "lat": 13.1503,
                "lng": -84.97
            },
            "Ocotal": {
                "lat": 13.6333,
                "lng": -86.4833
            },
            "Wasp\u00e1n": {
                "lat": 14.7333,
                "lng": -83.9667
            },
            "Chichigalpa": {
                "lat": 12.5667,
                "lng": -87.0333
            },
            "El Cu\u00e1": {
                "lat": 13.3675,
                "lng": -85.6733
            },
            "San Rafael del Sur": {
                "lat": 11.85,
                "lng": -86.45
            },
            "Jinotepe": {
                "lat": 11.85,
                "lng": -86.2
            },
            "San Jos\u00e9 de Bocay": {
                "lat": 13.5333,
                "lng": -85.5333
            },
            "Matigu\u00e1s": {
                "lat": 12.8333,
                "lng": -85.4667
            },
            "Rivas": {
                "lat": 11.4393,
                "lng": -85.827
            },
            "Ciudad Dar\u00edo": {
                "lat": 12.7167,
                "lng": -86.1167
            },
            "Nindir\u00ed": {
                "lat": 12.0047,
                "lng": -86.1194
            },
            "San Carlos": {
                "lat": 11.1333,
                "lng": -84.7833
            },
            "Camoapa": {
                "lat": 12.3833,
                "lng": -85.5167
            },
            "Nandaime": {
                "lat": 11.75,
                "lng": -86.05
            },
            "Somoto": {
                "lat": 13.4833,
                "lng": -86.5833
            },
            "Nagarote": {
                "lat": 12.2667,
                "lng": -86.5667
            },
            "S\u00e9baco": {
                "lat": 12.85,
                "lng": -86.1
            },
            "La Concepci\u00f3n": {
                "lat": 11.9333,
                "lng": -86.1833
            },
            "Bocana de Paiwas": {
                "lat": 12.7861,
                "lng": -85.1231
            },
            "Masatepe": {
                "lat": 11.9167,
                "lng": -86.15
            },
            "R\u00edo Blanco": {
                "lat": 12.9333,
                "lng": -85.2167
            },
            "San Ram\u00f3n": {
                "lat": 12.9167,
                "lng": -85.8333
            },
            "Waslala": {
                "lat": 13.2333,
                "lng": -85.3833
            },
            "Somotillo": {
                "lat": 13.0333,
                "lng": -86.9167
            },
            "San Marcos": {
                "lat": 11.9167,
                "lng": -86.2
            },
            "Condega": {
                "lat": 13.35,
                "lng": -86.4
            },
            "La Paz Centro": {
                "lat": 12.34,
                "lng": -86.6753
            },
            "El Sauce": {
                "lat": 12.8833,
                "lng": -86.5333
            },
            "Larreynaga": {
                "lat": 12.6744,
                "lng": -86.5708
            },
            "Villa El Carmen": {
                "lat": 11.9833,
                "lng": -86.5167
            },
            "Ticuantepe": {
                "lat": 12.0167,
                "lng": -86.2
            },
            "San Sebasti\u00e1n de Yal\u00ed": {
                "lat": 13.3,
                "lng": -86.1833
            },
            "Quilal\u00ed": {
                "lat": 13.5667,
                "lng": -86.0333
            },
            "Teustepe": {
                "lat": 12.4167,
                "lng": -85.8
            },
            "Rancho Grande": {
                "lat": 13.2536,
                "lng": -85.5528
            },
            "El J\u00edcaro": {
                "lat": 13.7167,
                "lng": -86.1333
            },
            "Villanueva": {
                "lat": 12.9636,
                "lng": -86.8147
            },
            "San Lorenzo": {
                "lat": 12.3667,
                "lng": -85.6667
            },
            "La Cruz de R\u00edo Grande": {
                "lat": 13.1167,
                "lng": -84.1833
            },
            "Telica": {
                "lat": 12.5167,
                "lng": -86.8667
            },
            "Rosita": {
                "lat": 13.8833,
                "lng": -84.4
            },
            "La Trinidad": {
                "lat": 12.9677,
                "lng": -86.2346
            },
            "Diriomo": {
                "lat": 11.8667,
                "lng": -86.05
            },
            "El Tortuguero": {
                "lat": 12.8167,
                "lng": -84.2
            },
            "Muelle de los Bueyes": {
                "lat": 12.0667,
                "lng": -84.5333
            },
            "Tola": {
                "lat": 11.4333,
                "lng": -85.9333
            },
            "San Juan de R\u00edo Coco": {
                "lat": 13.55,
                "lng": -86.1667
            },
            "Pueblo Nuevo": {
                "lat": 13.3833,
                "lng": -86.4833
            },
            "Altagracia": {
                "lat": 11.5667,
                "lng": -85.5833
            },
            "El Castillo de La Concepci\u00f3n": {
                "lat": 11.0333,
                "lng": -84.4667
            },
            "Telpaneca": {
                "lat": 13.5333,
                "lng": -86.2833
            },
            "Bonanza": {
                "lat": 14.0167,
                "lng": -84.5833
            },
            "Corinto": {
                "lat": 12.4833,
                "lng": -87.1833
            },
            "San Rafael del Norte": {
                "lat": 13.2167,
                "lng": -86.1167
            },
            "San Isidro": {
                "lat": 12.9319,
                "lng": -86.1953
            },
            "San Miguelito": {
                "lat": 11.4,
                "lng": -84.9
            },
            "Acoyapa": {
                "lat": 11.9667,
                "lng": -85.1667
            },
            "Santa Teresa": {
                "lat": 11.7333,
                "lng": -86.2167
            },
            "Posoltega": {
                "lat": 12.55,
                "lng": -86.9833
            },
            "Bel\u00e9n": {
                "lat": 11.5,
                "lng": -85.8833
            },
            "San Dionisio": {
                "lat": 12.75,
                "lng": -85.85
            },
            "Prinzapolka": {
                "lat": 13.4,
                "lng": -83.5667
            },
            "Esquipulas": {
                "lat": 12.6667,
                "lng": -85.7833
            },
            "Niquinohomo": {
                "lat": 11.9,
                "lng": -86.1
            },
            "San Juan del Sur": {
                "lat": 11.25,
                "lng": -85.8667
            },
            "Muy Muy": {
                "lat": 12.7667,
                "lng": -85.6333
            },
            "Murra": {
                "lat": 13.7667,
                "lng": -86.0167
            },
            "Achuapa": {
                "lat": 13.05,
                "lng": -86.5833
            },
            "El Crucero": {
                "lat": 11.9833,
                "lng": -86.3167
            },
            "San Juan de Limay": {
                "lat": 13.1667,
                "lng": -86.6167
            },
            "El Almendro": {
                "lat": 11.6833,
                "lng": -84.7
            },
            "Puerto Moraz\u00e1n": {
                "lat": 12.85,
                "lng": -87.1833
            },
            "Villa Sandino": {
                "lat": 12.05,
                "lng": -84.9833
            },
            "San Lucas": {
                "lat": 13.4167,
                "lng": -86.6
            },
            "Palacag\u00fcina": {
                "lat": 13.45,
                "lng": -86.4
            },
            "Terrabona": {
                "lat": 12.7333,
                "lng": -85.9667
            },
            "El Ayote": {
                "lat": 12.5,
                "lng": -84.8167
            },
            "Santo Domingo": {
                "lat": 12.25,
                "lng": -85.0667
            },
            "Totogalpa": {
                "lat": 13.5667,
                "lng": -86.5
            },
            "Comalapa": {
                "lat": 12.2833,
                "lng": -85.5
            },
            "La Libertad": {
                "lat": 12.2167,
                "lng": -85.1667
            },
            "Potos\u00ed": {
                "lat": 11.5,
                "lng": -85.8833
            },
            "Nandasmo": {
                "lat": 11.9333,
                "lng": -86.1167
            },
            "Tisma": {
                "lat": 12.0833,
                "lng": -86.0167
            },
            "El Jicaral": {
                "lat": 12.7333,
                "lng": -86.3833
            },
            "Moyogalpa": {
                "lat": 11.5333,
                "lng": -85.7
            },
            "Yalag\u00fcina": {
                "lat": 13.4833,
                "lng": -86.4833
            },
            "Santa Rosa del Pe\u00f1\u00f3n": {
                "lat": 12.8,
                "lng": -86.3667
            },
            "San Francisco Libre": {
                "lat": 12.5,
                "lng": -86.3
            },
            "El Realejo": {
                "lat": 12.5333,
                "lng": -87.1667
            },
            "Kukrahill": {
                "lat": 12.25,
                "lng": -83.75
            },
            "Quezalguaque": {
                "lat": 12.5061,
                "lng": -86.9033
            },
            "San Fernando": {
                "lat": 13.6833,
                "lng": -86.3167
            },
            "Greytown": {
                "lat": 10.9167,
                "lng": -83.7
            }
        }
    },
    "TM": {
        "name": "Turkmenistan",
        "cities": {
            "Ashgabat": {
                "lat": 37.9375,
                "lng": 58.38
            },
            "T\u00fcrkmenabat": {
                "lat": 39.0833,
                "lng": 63.5667
            },
            "Da\u015foguz": {
                "lat": 41.8333,
                "lng": 59.9667
            },
            "Balkanabat": {
                "lat": 39.5167,
                "lng": 54.3667
            },
            "Mary": {
                "lat": 37.6,
                "lng": 61.8333
            },
            "Koytendag": {
                "lat": 37.5,
                "lng": 66.0
            },
            "Serdar": {
                "lat": 38.9833,
                "lng": 56.2833
            },
            "Bayramaly": {
                "lat": 37.6167,
                "lng": 62.15
            },
            "T\u00fcrkmenba\u015fy": {
                "lat": 40.0167,
                "lng": 52.9667
            },
            "Tejen": {
                "lat": 37.3833,
                "lng": 60.5
            },
            "Abadan": {
                "lat": 38.0517,
                "lng": 58.21
            },
            "Kaka": {
                "lat": 37.35,
                "lng": 59.6
            },
            "Atamyrat": {
                "lat": 37.8531,
                "lng": 65.2369
            },
            "Yol\u00f6ten": {
                "lat": 37.3,
                "lng": 62.35
            },
            "K\u00f6ne\u00fcrgench": {
                "lat": 42.3333,
                "lng": 59.15
            },
            "Gumdag": {
                "lat": 39.2061,
                "lng": 54.5906
            },
            "Gyzylgaya": {
                "lat": 40.6219,
                "lng": 55.4656
            },
            "\u00c4new": {
                "lat": 37.8833,
                "lng": 58.5333
            },
            "Hazar": {
                "lat": 39.445,
                "lng": 53.115
            },
            "Bereket": {
                "lat": 39.2431,
                "lng": 55.5169
            },
            "Gazojak": {
                "lat": 41.1833,
                "lng": 61.4
            },
            "Baharly": {
                "lat": 38.4303,
                "lng": 57.4369
            },
            "Hojambaz": {
                "lat": 38.1167,
                "lng": 65.0
            },
            "G\u00f6kdepe": {
                "lat": 38.1578,
                "lng": 57.9664
            },
            "Seydi": {
                "lat": 39.4167,
                "lng": 62.9
            },
            "Akdepe": {
                "lat": 42.05,
                "lng": 59.4
            },
            "Serhetabat": {
                "lat": 35.2833,
                "lng": 62.35
            },
            "Farap": {
                "lat": 39.1667,
                "lng": 63.6
            },
            "Murgap": {
                "lat": 37.4964,
                "lng": 61.9739
            },
            "Sarahs": {
                "lat": 36.5333,
                "lng": 61.2167
            },
            "\u00c7eleken": {
                "lat": 39.4362,
                "lng": 53.1226
            }
        }
    },
    "NE": {
        "name": "Niger",
        "cities": {
            "Niamey": {
                "lat": 13.515,
                "lng": 2.1175
            },
            "Maradi": {
                "lat": 13.4833,
                "lng": 7.1
            },
            "Zinder": {
                "lat": 13.8053,
                "lng": 8.9883
            },
            "Tahoua": {
                "lat": 14.8903,
                "lng": 5.2642
            },
            "Agadez": {
                "lat": 16.9959,
                "lng": 7.9828
            },
            "Sabara Bangou": {
                "lat": 15.1177,
                "lng": 1.996
            },
            "Arlit": {
                "lat": 18.7333,
                "lng": 7.3833
            },
            "Birnin Konni": {
                "lat": 13.7904,
                "lng": 5.2599
            },
            "Matamey": {
                "lat": 13.4,
                "lng": 8.6
            },
            "Dosso": {
                "lat": 13.0444,
                "lng": 3.1947
            },
            "Kour\u00e9": {
                "lat": 13.3106,
                "lng": 2.576
            },
            "Gaya": {
                "lat": 11.8878,
                "lng": 3.4467
            },
            "Ayorou": {
                "lat": 14.735,
                "lng": 0.92
            },
            "Kollo": {
                "lat": 13.0167,
                "lng": 1.8833
            },
            "Diffa": {
                "lat": 13.3171,
                "lng": 12.6089
            },
            "Madaoua": {
                "lat": 14.0762,
                "lng": 5.9586
            },
            "Dabaga": {
                "lat": 17.2667,
                "lng": 8.1
            },
            "Tillab\u00e9ri": {
                "lat": 14.212,
                "lng": 1.4531
            },
            "Nguigmi": {
                "lat": 14.2532,
                "lng": 13.1108
            },
            "Guidan Roumdji": {
                "lat": 13.6575,
                "lng": 6.6958
            },
            "Bal\u00e9yara": {
                "lat": 13.784,
                "lng": 2.95
            },
            "Gour\u00e9": {
                "lat": 13.9874,
                "lng": 10.27
            },
            "Galmi": {
                "lat": 13.966,
                "lng": 5.675
            },
            "Tounfafi": {
                "lat": 14.0464,
                "lng": 5.9812
            },
            "Bandio": {
                "lat": 13.8888,
                "lng": 1.0896
            },
            "Kawara": {
                "lat": 14.0706,
                "lng": 5.6715
            },
            "Toulou": {
                "lat": 14.1688,
                "lng": 5.199
            },
            "Gidan Id\u00e8r": {
                "lat": 14.0131,
                "lng": 5.3185
            },
            "Taki\u00e9ta": {
                "lat": 13.6806,
                "lng": 8.5292
            },
            "Djado": {
                "lat": 21.015,
                "lng": 12.3075
            }
        }
    },
    "LR": {
        "name": "Liberia",
        "cities": {
            "Monrovia": {
                "lat": 6.3133,
                "lng": -10.8014
            },
            "New Kru Town": {
                "lat": 6.3733,
                "lng": -10.7928
            },
            "Upper Buchanan": {
                "lat": 5.9161,
                "lng": -10.0525
            },
            "Ganta": {
                "lat": 7.2375,
                "lng": -8.9814
            },
            "Buchanan": {
                "lat": 5.8808,
                "lng": -10.0467
            },
            "Gbarnga": {
                "lat": 6.998,
                "lng": -9.473
            },
            "Kakata": {
                "lat": 6.53,
                "lng": -10.3517
            },
            "Voinjama": {
                "lat": 8.4167,
                "lng": -9.75
            },
            "Harbel": {
                "lat": 6.2833,
                "lng": -10.35
            },
            "Zwedru": {
                "lat": 6.0667,
                "lng": -8.1333
            },
            "Foya Tangia": {
                "lat": 8.2713,
                "lng": -10.2283
            },
            "Harper": {
                "lat": 4.3667,
                "lng": -7.7167
            },
            "Greenville": {
                "lat": 5.0167,
                "lng": -9.0333
            },
            "Tubmanburg": {
                "lat": 6.8667,
                "lng": -10.8167
            },
            "Saclepea": {
                "lat": 7.1167,
                "lng": -8.8333
            },
            "Sanniquellie": {
                "lat": 7.3622,
                "lng": -8.7061
            },
            "Bensonville": {
                "lat": 6.4456,
                "lng": -10.6097
            },
            "Robertsport": {
                "lat": 6.75,
                "lng": -11.3667
            },
            "Fish Town": {
                "lat": 5.1964,
                "lng": -7.8756
            },
            "Bopolu": {
                "lat": 7.0667,
                "lng": -10.4875
            },
            "Barclayville": {
                "lat": 4.6797,
                "lng": -8.2339
            },
            "Cestos City": {
                "lat": 5.4667,
                "lng": -9.5833
            }
        }
    },
    "HT": {
        "name": "Haiti",
        "cities": {
            "Port-au-Prince": {
                "lat": 18.5333,
                "lng": -72.3333
            },
            "Carrefour": {
                "lat": 18.5344,
                "lng": -72.4094
            },
            "P\u00e9tion-Ville": {
                "lat": 18.5128,
                "lng": -72.2864
            },
            "Gona\u00efves": {
                "lat": 19.4456,
                "lng": -72.6883
            },
            "Croix-des-Bouquets": {
                "lat": 18.5761,
                "lng": -72.2269
            },
            "Delmas": {
                "lat": 18.55,
                "lng": -72.3
            },
            "Cap-Ha\u00eftien": {
                "lat": 19.76,
                "lng": -72.2
            },
            "Petite Rivi\u00e8re de l\u2019Artibonite": {
                "lat": 19.1333,
                "lng": -72.4833
            },
            "Saint-Marc": {
                "lat": 19.1167,
                "lng": -72.7
            },
            "Jean-Rabel": {
                "lat": 19.85,
                "lng": -73.2
            },
            "Jacmel": {
                "lat": 18.2353,
                "lng": -72.5367
            },
            "Arcahaie": {
                "lat": 18.7667,
                "lng": -72.5167
            },
            "Tabarre": {
                "lat": 18.5833,
                "lng": -72.2667
            },
            "Dessalines": {
                "lat": 19.2833,
                "lng": -72.5
            },
            "Port-de-Paix": {
                "lat": 19.95,
                "lng": -72.8333
            },
            "Aquin": {
                "lat": 18.2833,
                "lng": -73.4
            },
            "Hinche": {
                "lat": 19.15,
                "lng": -72.0167
            },
            "Saint-Michel de l\u2019Atalaye": {
                "lat": 19.3667,
                "lng": -72.3333
            },
            "L\u00e9og\u00e2ne": {
                "lat": 18.5108,
                "lng": -72.6339
            },
            "Les Cayes": {
                "lat": 18.2,
                "lng": -73.75
            },
            "Ganthier": {
                "lat": 18.5333,
                "lng": -72.0667
            },
            "Saint-Louis du Nord": {
                "lat": 19.9333,
                "lng": -72.7167
            },
            "Limonade": {
                "lat": 19.6667,
                "lng": -72.1167
            },
            "Limb\u00e9": {
                "lat": 19.7056,
                "lng": -72.4147
            },
            "Torbeck": {
                "lat": 18.1667,
                "lng": -73.8167
            },
            "Anse \u00e0 Galets": {
                "lat": 18.8333,
                "lng": -72.8667
            },
            "Bainet": {
                "lat": 18.1833,
                "lng": -72.75
            },
            "Cabaret": {
                "lat": 18.7333,
                "lng": -72.4167
            },
            "Bellad\u00e8re": {
                "lat": 18.8667,
                "lng": -71.7667
            },
            "Saint-Louis du Sud": {
                "lat": 18.2667,
                "lng": -73.55
            },
            "Mirago\u00e2ne": {
                "lat": 18.4458,
                "lng": -73.09
            },
            "Acul du Nord": {
                "lat": 19.6833,
                "lng": -72.3167
            },
            "Cornillon": {
                "lat": 18.6667,
                "lng": -71.95
            },
            "Thomazeau": {
                "lat": 18.65,
                "lng": -72.1
            },
            "Belle-Anse": {
                "lat": 18.2333,
                "lng": -72.0667
            },
            "Cerca la Source": {
                "lat": 19.1667,
                "lng": -71.7833
            },
            "Marigot": {
                "lat": 18.2333,
                "lng": -72.3167
            },
            "Bas Limb\u00e9": {
                "lat": 19.8,
                "lng": -72.3833
            },
            "Plaisance": {
                "lat": 19.6,
                "lng": -72.4667
            },
            "Verrettes": {
                "lat": 19.05,
                "lng": -72.4667
            },
            "Boucan Carr\u00e9": {
                "lat": 18.9667,
                "lng": -72.2
            },
            "Borgne": {
                "lat": 19.85,
                "lng": -72.5333
            },
            "Cavaillon": {
                "lat": 18.3,
                "lng": -73.65
            },
            "Camp Perrin": {
                "lat": 18.3167,
                "lng": -73.8667
            },
            "Casale": {
                "lat": 18.799,
                "lng": -72.3854
            },
            "Anse Rouge": {
                "lat": 19.6333,
                "lng": -73.05
            },
            "Ma\u00efssade": {
                "lat": 19.1667,
                "lng": -72.1333
            },
            "Thomassique": {
                "lat": 19.0833,
                "lng": -71.8333
            },
            "Pilate": {
                "lat": 19.6667,
                "lng": -72.55
            },
            "Fonds Verrettes": {
                "lat": 18.3833,
                "lng": -71.85
            },
            "L\u2019Asile": {
                "lat": 18.3833,
                "lng": -73.4167
            },
            "Les Abricots": {
                "lat": 18.6333,
                "lng": -74.3
            },
            "Trou du Nord": {
                "lat": 19.6333,
                "lng": -72.0167
            },
            "Port-Margot": {
                "lat": 19.75,
                "lng": -72.4333
            },
            "Anse d\u2019Hainault": {
                "lat": 18.5,
                "lng": -74.45
            },
            "La Vall\u00e9e de Jacmel": {
                "lat": 18.2667,
                "lng": -72.6667
            },
            "Petit-Trou de Nippes": {
                "lat": 18.5333,
                "lng": -73.5167
            },
            "Pestel": {
                "lat": 18.5333,
                "lng": -73.8
            },
            "Bombardopolis": {
                "lat": 19.7,
                "lng": -73.3333
            },
            "Ville Bonheur": {
                "lat": 18.8167,
                "lng": -72.2
            },
            "Anse-\u00e0-Veau": {
                "lat": 18.5167,
                "lng": -73.35
            },
            "Fort Libert\u00e9": {
                "lat": 19.6678,
                "lng": -71.8397
            },
            "Bassin Bleu": {
                "lat": 19.7833,
                "lng": -72.8
            },
            "C\u00f4tes de Fer": {
                "lat": 18.1833,
                "lng": -73.0
            },
            "Saintard": {
                "lat": 18.824,
                "lng": -72.55
            },
            "Milot": {
                "lat": 19.6047,
                "lng": -72.2186
            },
            "Barad\u00e8res": {
                "lat": 18.4825,
                "lng": -73.6386
            },
            "Ennery": {
                "lat": 19.4833,
                "lng": -72.4833
            },
            "Chantal": {
                "lat": 18.2,
                "lng": -73.8833
            },
            "J\u00e9r\u00e9mie": {
                "lat": 18.65,
                "lng": -74.1167
            },
            "Anse-\u00e0-Foleur": {
                "lat": 19.9,
                "lng": -72.6167
            },
            "Anse \u00e0 Pitre": {
                "lat": 18.05,
                "lng": -71.75
            },
            "Les Palmes": {
                "lat": 18.3333,
                "lng": -72.85
            },
            "Petite Rivi\u00e8re de Nippes": {
                "lat": 18.4833,
                "lng": -73.25
            },
            "Savanette": {
                "lat": 18.6833,
                "lng": -72.05
            },
            "Pignon": {
                "lat": 19.3333,
                "lng": -72.1167
            },
            "La Source": {
                "lat": 18.15,
                "lng": -73.8833
            },
            "Arniquet": {
                "lat": 18.15,
                "lng": -73.8833
            },
            "Roseaux": {
                "lat": 18.6,
                "lng": -74.0167
            },
            "Plaine du Nord": {
                "lat": 19.6833,
                "lng": -72.2667
            },
            "Les Anglais": {
                "lat": 18.3,
                "lng": -74.2167
            },
            "Dame-Marie": {
                "lat": 18.5667,
                "lng": -74.4167
            },
            "Gressier": {
                "lat": 18.55,
                "lng": -72.5167
            },
            "Dondon": {
                "lat": 19.5333,
                "lng": -72.2333
            },
            "Chardonni\u00e8res": {
                "lat": 18.2667,
                "lng": -74.1667
            },
            "Mombin Crochu": {
                "lat": 19.3667,
                "lng": -71.9833
            },
            "Moron": {
                "lat": 18.56,
                "lng": -74.2579
            },
            "Les Irois": {
                "lat": 18.4,
                "lng": -74.45
            },
            "Thiotte": {
                "lat": 18.25,
                "lng": -71.85
            },
            "Pointe \u00e0 Raquettes": {
                "lat": 18.7833,
                "lng": -73.0667
            },
            "Corail": {
                "lat": 18.5667,
                "lng": -73.8833
            },
            "Maniche": {
                "lat": 18.35,
                "lng": -73.7667
            },
            "Sainte-Suzanne": {
                "lat": 19.5833,
                "lng": -72.0833
            },
            "Terrier Rouge": {
                "lat": 19.6333,
                "lng": -71.95
            },
            "Tiburon": {
                "lat": 18.3333,
                "lng": -74.4
            },
            "Arnaud": {
                "lat": 18.45,
                "lng": -73.3833
            },
            "Les C\u00f4teaux": {
                "lat": 18.2,
                "lng": -74.0333
            },
            "Quartier Morin": {
                "lat": 19.7,
                "lng": -72.15
            },
            "Fond des Blancs": {
                "lat": 18.2833,
                "lng": -73.1333
            },
            "Liancourt": {
                "lat": 19.1356,
                "lng": -72.5333
            },
            "Montrouis": {
                "lat": 18.9506,
                "lng": -72.7044
            },
            "Ranquitte": {
                "lat": 19.4167,
                "lng": -72.0833
            },
            "Chapelle": {
                "lat": 19.4167,
                "lng": -72.55
            },
            "Cerca Carvajal": {
                "lat": 19.2667,
                "lng": -71.95
            },
            "Valli\u00e8res": {
                "lat": 19.4333,
                "lng": -71.9167
            },
            "Bahon": {
                "lat": 19.4696,
                "lng": -72.1147
            },
            "Baie de Henne": {
                "lat": 19.6667,
                "lng": -73.2
            },
            "Port-\u00e0-Piment": {
                "lat": 18.25,
                "lng": -74.1
            },
            "Mont-Organis\u00e9": {
                "lat": 19.4,
                "lng": -71.7833
            },
            "Terre Neuve": {
                "lat": 19.6,
                "lng": -72.7833
            },
            "Chambellan": {
                "lat": 18.5667,
                "lng": -74.3167
            },
            "Laferri\u00e8re": {
                "lat": 18.5667,
                "lng": -74.3167
            },
            "Roche-\u00e0-Bateau": {
                "lat": 18.1833,
                "lng": -74.0
            },
            "Capotille": {
                "lat": 19.45,
                "lng": -71.7
            },
            "Grande Saline": {
                "lat": 19.25,
                "lng": -72.7833
            },
            "Ferrier": {
                "lat": 19.6167,
                "lng": -71.7833
            },
            "Beaumont": {
                "lat": 18.4833,
                "lng": -73.9667
            },
            "Petit-Go\u00e2ve": {
                "lat": 18.4314,
                "lng": -72.8669
            },
            "Perches": {
                "lat": 19.5167,
                "lng": -71.9167
            },
            "Grand Gosier": {
                "lat": 18.1833,
                "lng": -71.9167
            },
            "Carice": {
                "lat": 19.3833,
                "lng": -71.8333
            },
            "Pont Sond\u00e9": {
                "lat": 19.1493,
                "lng": -72.6147
            },
            "La Pointe": {
                "lat": 19.95,
                "lng": -72.7833
            },
            "Chansolme": {
                "lat": 19.8833,
                "lng": -72.8333
            }
        }
    },
    "ER": {
        "name": "Eritrea",
        "cities": {
            "Asmara": {
                "lat": 15.3228,
                "lng": 38.925
            },
            "Keren": {
                "lat": 15.7778,
                "lng": 38.4581
            },
            "Gwelej": {
                "lat": 14.75,
                "lng": 36.7167
            },
            "Assab": {
                "lat": 13.0167,
                "lng": 42.7333
            },
            "Massawa": {
                "lat": 15.6097,
                "lng": 39.45
            },
            "Mendefera": {
                "lat": 14.8833,
                "lng": 38.8167
            },
            "Dbarwa": {
                "lat": 15.1,
                "lng": 38.8333
            },
            "Barentu": {
                "lat": 15.1167,
                "lng": 37.6
            },
            "B\u0113ylul": {
                "lat": 13.2644,
                "lng": 42.3342
            },
            "Adi Keyh": {
                "lat": 14.8333,
                "lng": 39.3667
            },
            "Ghinda\u2019e": {
                "lat": 15.45,
                "lng": 39.0833
            },
            "Edd": {
                "lat": 13.9333,
                "lng": 41.7
            },
            "Dek\u2019emh\u0101re": {
                "lat": 15.0667,
                "lng": 39.0333
            },
            "Ak\u2019ordat": {
                "lat": 15.55,
                "lng": 37.8833
            },
            "Nefas\u012bt": {
                "lat": 15.3333,
                "lng": 39.0619
            },
            "Teseney": {
                "lat": 15.11,
                "lng": 36.6575
            }
        }
    },
    "SL": {
        "name": "Sierra Leone",
        "cities": {
            "Freetown": {
                "lat": 8.4844,
                "lng": -13.2344
            },
            "Bo": {
                "lat": 7.9564,
                "lng": -11.74
            },
            "Kenema": {
                "lat": 7.8758,
                "lng": -11.1908
            },
            "Koidu": {
                "lat": 8.6439,
                "lng": -10.9717
            },
            "Koidu-Bulma": {
                "lat": 8.4405,
                "lng": -10.85
            },
            "Port Loko": {
                "lat": 8.7667,
                "lng": -12.7875
            },
            "Tongoma": {
                "lat": 8.65,
                "lng": -11.0667
            },
            "Magburaka": {
                "lat": 8.7169,
                "lng": -11.9433
            },
            "Kambia": {
                "lat": 9.1167,
                "lng": -12.9167
            },
            "Lunsar": {
                "lat": 8.6833,
                "lng": -12.5333
            },
            "Newton": {
                "lat": 8.3333,
                "lng": -13.0667
            },
            "Motema": {
                "lat": 8.6167,
                "lng": -11.1667
            },
            "Kailahun": {
                "lat": 8.2772,
                "lng": -10.5739
            },
            "Benguema": {
                "lat": 8.3333,
                "lng": -13.0667
            },
            "Pendembu": {
                "lat": 8.1,
                "lng": -10.7
            },
            "Pujehun": {
                "lat": 7.3506,
                "lng": -11.7181
            },
            "Kabala": {
                "lat": 9.5833,
                "lng": -11.55
            },
            "Leicester": {
                "lat": 8.45,
                "lng": -13.2167
            },
            "Segbwema": {
                "lat": 8.0,
                "lng": -10.95
            },
            "Bumpe": {
                "lat": 7.8919,
                "lng": -11.9025
            },
            "Mongeri": {
                "lat": 8.3167,
                "lng": -11.7333
            },
            "Binkolo": {
                "lat": 8.95,
                "lng": -11.9833
            },
            "Yengema": {
                "lat": 8.6167,
                "lng": -11.1667
            },
            "Rokupr": {
                "lat": 9.0167,
                "lng": -12.95
            },
            "Makeni": {
                "lat": 8.8817,
                "lng": -12.0442
            },
            "Gandorhun": {
                "lat": 7.5564,
                "lng": -11.6925
            },
            "Moyamba": {
                "lat": 8.1606,
                "lng": -12.4333
            },
            "Bonthe": {
                "lat": 7.5264,
                "lng": -12.505
            }
        }
    },
    "LA": {
        "name": "Laos",
        "cities": {
            "Vientiane": {
                "lat": 17.98,
                "lng": 102.63
            },
            "Xiangkhoang": {
                "lat": 19.4167,
                "lng": 103.5
            },
            "Savannakhet": {
                "lat": 16.55,
                "lng": 104.75
            },
            "Salavan": {
                "lat": 15.7167,
                "lng": 106.4167
            },
            "Xai": {
                "lat": 20.6914,
                "lng": 101.9861
            },
            "Pakx\u00e9": {
                "lat": 15.1167,
                "lng": 105.7833
            },
            "Xaignabouli": {
                "lat": 19.25,
                "lng": 101.75
            },
            "Ban Houayxay": {
                "lat": 20.2631,
                "lng": 100.4336
            },
            "Thakh\u00e8k": {
                "lat": 17.4,
                "lng": 104.8
            },
            "Champasak": {
                "lat": 14.8569,
                "lng": 105.7533
            },
            "Xam Nua": {
                "lat": 20.415,
                "lng": 104.048
            },
            "Louang Namtha": {
                "lat": 20.95,
                "lng": 101.4
            },
            "Louangphabang": {
                "lat": 19.89,
                "lng": 102.1347
            },
            "Pakxan": {
                "lat": 18.3964,
                "lng": 103.6558
            },
            "Ph\u00f4nsavan": {
                "lat": 19.46,
                "lng": 103.18
            },
            "Vangviang": {
                "lat": 18.9267,
                "lng": 102.4494
            },
            "Muang Sing": {
                "lat": 21.2,
                "lng": 101.15
            },
            "Ph\u00f4ngsali": {
                "lat": 21.6833,
                "lng": 102.1
            },
            "Attapu": {
                "lat": 14.8,
                "lng": 106.8333
            },
            "Anouv\u00f4ng": {
                "lat": 18.8989,
                "lng": 103.0919
            },
            "X\u00e9kong": {
                "lat": 15.3503,
                "lng": 106.7286
            },
            "Ph\u00f4n-H\u00f4ng": {
                "lat": 18.4953,
                "lng": 102.4153
            }
        }
    },
    "LV": {
        "name": "Latvia",
        "cities": {
            "Riga": {
                "lat": 56.9489,
                "lng": 24.1064
            },
            "Daugavpils": {
                "lat": 55.875,
                "lng": 26.5356
            },
            "Liep\u0101ja": {
                "lat": 56.5117,
                "lng": 21.0139
            },
            "Jelgava": {
                "lat": 56.6483,
                "lng": 23.7139
            },
            "J\u016brmala": {
                "lat": 56.9681,
                "lng": 23.7703
            },
            "Ventspils": {
                "lat": 57.3906,
                "lng": 21.5733
            },
            "R\u0113zekne": {
                "lat": 56.5127,
                "lng": 27.3427
            },
            "Ogre": {
                "lat": 56.8186,
                "lng": 24.6056
            },
            "Valmiera": {
                "lat": 57.55,
                "lng": 25.4
            },
            "J\u0113kabpils": {
                "lat": 56.4994,
                "lng": 25.8783
            },
            "Salaspils": {
                "lat": 56.8667,
                "lng": 24.35
            },
            "Tukums": {
                "lat": 56.9667,
                "lng": 23.15
            },
            "M\u0101rupe": {
                "lat": 56.9069,
                "lng": 24.0583
            },
            "C\u0113sis": {
                "lat": 57.3167,
                "lng": 25.2667
            },
            "Sigulda": {
                "lat": 57.15,
                "lng": 24.8667
            },
            "Olaine": {
                "lat": 56.7833,
                "lng": 23.9333
            },
            "Kuld\u012bga": {
                "lat": 56.9667,
                "lng": 21.9667
            },
            "Bauska": {
                "lat": 56.4083,
                "lng": 24.1931
            },
            "Saldus": {
                "lat": 56.6667,
                "lng": 22.5
            },
            "Talsi": {
                "lat": 57.2444,
                "lng": 22.5867
            },
            "Dobele": {
                "lat": 56.6167,
                "lng": 23.2667
            },
            "Ludza": {
                "lat": 56.55,
                "lng": 27.7167
            },
            "\u0100da\u017ei": {
                "lat": 57.0667,
                "lng": 24.3333
            },
            "Kr\u0101slava": {
                "lat": 55.8833,
                "lng": 27.1667
            },
            "Aizkraukle": {
                "lat": 56.6042,
                "lng": 25.2539
            },
            "L\u012bv\u0101ni": {
                "lat": 56.3667,
                "lng": 26.1833
            },
            "Gulbene": {
                "lat": 57.1667,
                "lng": 26.75
            },
            "Limba\u017ei": {
                "lat": 57.5167,
                "lng": 24.7167
            },
            "Madona": {
                "lat": 56.85,
                "lng": 26.2167
            },
            "Al\u016bksne": {
                "lat": 57.4167,
                "lng": 27.05
            },
            "Prei\u013ci": {
                "lat": 56.3,
                "lng": 26.7167
            },
            "Balvi": {
                "lat": 57.1333,
                "lng": 27.25
            },
            "Smiltene": {
                "lat": 57.4333,
                "lng": 25.9
            },
            "\u0136ekava": {
                "lat": 56.8333,
                "lng": 24.2333
            },
            "Valka": {
                "lat": 57.7667,
                "lng": 26.0
            },
            "Saulkrasti": {
                "lat": 57.25,
                "lng": 24.4167
            },
            "Ulbroka": {
                "lat": 56.9333,
                "lng": 24.2833
            },
            "Varak\u013c\u0101ni": {
                "lat": 56.6,
                "lng": 26.75
            },
            "Ropa\u017ei": {
                "lat": 56.9718,
                "lng": 24.6318
            }
        }
    },
    "CF": {
        "name": "Central African Republic",
        "cities": {
            "Bangui": {
                "lat": 4.3733,
                "lng": 18.5628
            },
            "Bimo": {
                "lat": 4.3137,
                "lng": 18.3065
            },
            "Mamb\u00e9r\u00e9": {
                "lat": 5.5,
                "lng": 15.5
            },
            "Bimbo": {
                "lat": 4.2558,
                "lng": 18.4042
            },
            "Berb\u00e9rati": {
                "lat": 4.2614,
                "lng": 15.7894
            },
            "Bocaranga": {
                "lat": 6.9844,
                "lng": 15.6408
            },
            "Carnot": {
                "lat": 4.94,
                "lng": 15.87
            },
            "Nola": {
                "lat": 3.5333,
                "lng": 16.0667
            },
            "Bambari": {
                "lat": 5.7653,
                "lng": 20.6742
            },
            "Bouar": {
                "lat": 5.95,
                "lng": 15.6
            },
            "Bossangoa": {
                "lat": 6.4833,
                "lng": 17.45
            },
            "Bria": {
                "lat": 6.5369,
                "lng": 21.9919
            },
            "Bangassou": {
                "lat": 4.7374,
                "lng": 22.8195
            },
            "Kaga Bandoro": {
                "lat": 7.0,
                "lng": 19.1833
            },
            "Sibut": {
                "lat": 5.7333,
                "lng": 19.0833
            },
            "Mba\u00efki": {
                "lat": 3.8833,
                "lng": 18.0
            },
            "Bozoum": {
                "lat": 6.3172,
                "lng": 16.3783
            },
            "Zemio": {
                "lat": 5.0333,
                "lng": 25.1333
            },
            "Bakouma": {
                "lat": 5.6986,
                "lng": 22.7833
            },
            "Paoua": {
                "lat": 7.25,
                "lng": 16.4333
            },
            "Ippy": {
                "lat": 6.25,
                "lng": 21.2
            },
            "Grimari": {
                "lat": 5.7167,
                "lng": 20.05
            },
            "Bobangui": {
                "lat": 4.05,
                "lng": 18.1167
            },
            "Batangafo": {
                "lat": 7.3,
                "lng": 18.3
            },
            "Kabo": {
                "lat": 7.6994,
                "lng": 18.6292
            },
            "Alindao": {
                "lat": 5.0333,
                "lng": 21.2167
            },
            "Rafa\u00ef": {
                "lat": 4.9731,
                "lng": 23.9319
            },
            "Nd\u00e9l\u00e9": {
                "lat": 8.4092,
                "lng": 20.6531
            },
            "Gamboula": {
                "lat": 4.1333,
                "lng": 15.15
            },
            "Bouca": {
                "lat": 6.5,
                "lng": 18.2833
            },
            "Birao": {
                "lat": 10.294,
                "lng": 22.782
            },
            "Mboki": {
                "lat": 5.316,
                "lng": 25.9571
            },
            "Boali": {
                "lat": 4.8,
                "lng": 18.1167
            },
            "Bossembele": {
                "lat": 5.2667,
                "lng": 17.65
            },
            "Obo": {
                "lat": 5.4,
                "lng": 26.5
            },
            "Mobaye": {
                "lat": 4.3167,
                "lng": 21.1833
            },
            "Ouadda": {
                "lat": 8.0667,
                "lng": 22.4
            },
            "Yakossi": {
                "lat": 5.617,
                "lng": 23.3167
            }
        }
    },
    "TJ": {
        "name": "Tajikistan",
        "cities": {
            "Dushanbe": {
                "lat": 38.5367,
                "lng": 68.78
            },
            "Kh\u016djand": {
                "lat": 40.2833,
                "lng": 69.6333
            },
            "Konibodom": {
                "lat": 40.2833,
                "lng": 70.4167
            },
            "K\u016dlob": {
                "lat": 37.9092,
                "lng": 69.7819
            },
            "Istaravshan": {
                "lat": 39.9108,
                "lng": 69.0064
            },
            "Tursunzoda": {
                "lat": 38.5108,
                "lng": 68.2303
            },
            "Panjakent": {
                "lat": 39.5,
                "lng": 67.6167
            },
            "Isfara": {
                "lat": 40.1167,
                "lng": 70.6333
            },
            "Gh\u016dlakandoz": {
                "lat": 40.1617,
                "lng": 69.4678
            },
            "Zargar": {
                "lat": 37.8,
                "lng": 68.65
            },
            "Levakant": {
                "lat": 37.8667,
                "lng": 68.9167
            },
            "Chimteppa": {
                "lat": 38.4667,
                "lng": 68.7333
            },
            "Roghun": {
                "lat": 38.6978,
                "lng": 69.7522
            },
            "Vahdat": {
                "lat": 38.5531,
                "lng": 69.0206
            },
            "Bokhtar": {
                "lat": 37.8364,
                "lng": 68.7803
            },
            "Isfisor": {
                "lat": 40.2433,
                "lng": 69.7447
            },
            "Sarikishty": {
                "lat": 38.4667,
                "lng": 68.7
            },
            "Navgilem": {
                "lat": 40.1333,
                "lng": 70.65
            },
            "Qaratog": {
                "lat": 38.55,
                "lng": 68.2833
            },
            "Obburdon": {
                "lat": 40.4278,
                "lng": 69.3047
            },
            "Chork\u016dh": {
                "lat": 39.9739,
                "lng": 70.5822
            },
            "Yovon": {
                "lat": 38.3167,
                "lng": 69.05
            },
            "Simiganj": {
                "lat": 38.6525,
                "lng": 69.0064
            },
            "Rokhaty": {
                "lat": 38.6,
                "lng": 68.9333
            },
            "Chorbog": {
                "lat": 38.6667,
                "lng": 68.7667
            },
            "Norak": {
                "lat": 38.3833,
                "lng": 69.3167
            },
            "Danghara": {
                "lat": 38.0983,
                "lng": 69.3467
            },
            "Khorugh": {
                "lat": 37.4833,
                "lng": 71.55
            },
            "Dahana": {
                "lat": 38.0583,
                "lng": 69.8667
            },
            "Hisor": {
                "lat": 38.5264,
                "lng": 68.5381
            },
            "Pongoz": {
                "lat": 40.75,
                "lng": 70.25
            },
            "Bakhor": {
                "lat": 38.55,
                "lng": 68.9833
            },
            "Muchun": {
                "lat": 39.8833,
                "lng": 69.1833
            },
            "Navabad": {
                "lat": 38.5278,
                "lng": 68.675
            },
            "Jilik\u016dl": {
                "lat": 37.4928,
                "lng": 68.5286
            },
            "Farkhor": {
                "lat": 37.5,
                "lng": 69.4
            },
            "Somoniyon": {
                "lat": 38.4422,
                "lng": 68.7769
            },
            "Hulbuk": {
                "lat": 37.805,
                "lng": 69.6464
            },
            "Firuzoba": {
                "lat": 40.3,
                "lng": 70.4
            },
            "Dalyoni Bolo": {
                "lat": 39.7422,
                "lng": 68.9944
            },
            "Moskva": {
                "lat": 37.6567,
                "lng": 69.6292
            },
            "Kulkent": {
                "lat": 40.15,
                "lng": 70.7
            },
            "Mehnatobod": {
                "lat": 40.2,
                "lng": 68.95
            },
            "Lohut\u00ed": {
                "lat": 40.25,
                "lng": 70.1333
            },
            "Qal\u2019acha": {
                "lat": 40.1333,
                "lng": 69.6833
            },
            "Naryn": {
                "lat": 38.2,
                "lng": 68.9
            },
            "Almasi": {
                "lat": 38.6833,
                "lng": 68.6
            },
            "Oshoba": {
                "lat": 40.7347,
                "lng": 70.4417
            },
            "Paldorak": {
                "lat": 40.4833,
                "lng": 69.3
            },
            "Ghafurov": {
                "lat": 40.22,
                "lng": 69.73
            },
            "\u0116sanboy": {
                "lat": 38.0833,
                "lng": 68.3833
            },
            "Durbat": {
                "lat": 38.5333,
                "lng": 68.6333
            },
            "Lohur": {
                "lat": 38.3833,
                "lng": 68.7
            },
            "Poshkent": {
                "lat": 39.8833,
                "lng": 69.0333
            },
            "Mogiyon": {
                "lat": 39.25,
                "lng": 67.65
            },
            "Dashtigulho": {
                "lat": 37.7,
                "lng": 69.5
            },
            "Kosatarosh": {
                "lat": 39.4,
                "lng": 67.75
            },
            "Abdurahmoni Jom\u00ed": {
                "lat": 37.9458,
                "lng": 68.8117
            },
            "D\u016dst\u00ed": {
                "lat": 37.3486,
                "lng": 68.6733
            },
            "Chubek": {
                "lat": 37.6167,
                "lng": 69.7
            },
            "Navraftor": {
                "lat": 37.7333,
                "lng": 68.7667
            },
            "Patar": {
                "lat": 40.3333,
                "lng": 70.45
            },
            "Gazantarak": {
                "lat": 39.9667,
                "lng": 69.1333
            },
            "Mujikharf": {
                "lat": 38.85,
                "lng": 69.8833
            },
            "Shahritus": {
                "lat": 37.2667,
                "lng": 68.1333
            },
            "Ghonch\u00ed": {
                "lat": 39.9589,
                "lng": 69.1369
            },
            "Sujina": {
                "lat": 39.4833,
                "lng": 67.7167
            },
            "Chorbogh": {
                "lat": 39.8667,
                "lng": 69.0833
            },
            "Mastchoh": {
                "lat": 40.3667,
                "lng": 69.3333
            },
            "Shahriston": {
                "lat": 39.7667,
                "lng": 68.8167
            },
            "Sultonobod": {
                "lat": 38.45,
                "lng": 68.95
            },
            "Chilgaz\u00ed": {
                "lat": 40.15,
                "lng": 70.7333
            },
            "Adrasmon": {
                "lat": 40.6486,
                "lng": 69.9856
            },
            "B\u016dston": {
                "lat": 40.5217,
                "lng": 69.3308
            },
            "Romit": {
                "lat": 38.7167,
                "lng": 69.3167
            },
            "Seshambe": {
                "lat": 38.5333,
                "lng": 68.25
            },
            "Vakhsh": {
                "lat": 37.7108,
                "lng": 68.8311
            },
            "Ayn\u00ed": {
                "lat": 39.3975,
                "lng": 68.5406
            },
            "Dehmo\u00ed": {
                "lat": 40.2167,
                "lng": 69.5167
            },
            "M\u016d\u2019minobod": {
                "lat": 38.1083,
                "lng": 70.0292
            },
            "Ayni": {
                "lat": 38.6667,
                "lng": 68.8167
            },
            "Qibla\u00ed": {
                "lat": 38.6167,
                "lng": 68.8333
            },
            "Amondara": {
                "lat": 39.5178,
                "lng": 67.8131
            },
            "Pushing": {
                "lat": 38.1833,
                "lng": 69.4333
            },
            "Rabot": {
                "lat": 38.6167,
                "lng": 68.1833
            },
            "Sebiston": {
                "lat": 38.25,
                "lng": 69.2833
            },
            "Sangt\u016dda": {
                "lat": 38.0333,
                "lng": 69.0833
            },
            "Panj": {
                "lat": 37.2353,
                "lng": 69.0972
            },
            "Gulshan": {
                "lat": 37.5833,
                "lng": 69.4333
            },
            "Dehqonobod": {
                "lat": 37.6333,
                "lng": 69.45
            },
            "Obikiik": {
                "lat": 38.1586,
                "lng": 68.6722
            },
            "Khonobod": {
                "lat": 40.2,
                "lng": 70.6
            },
            "Kalaidasht": {
                "lat": 38.6333,
                "lng": 69.5
            },
            "Varzobkala": {
                "lat": 38.7667,
                "lng": 68.8167
            },
            "Vanj": {
                "lat": 38.3731,
                "lng": 71.4553
            },
            "Shahrinav": {
                "lat": 38.5667,
                "lng": 68.3333
            },
            "Shing": {
                "lat": 39.2833,
                "lng": 67.8
            },
            "Sh\u016drobod": {
                "lat": 37.8403,
                "lng": 70.0419
            },
            "Khovaling": {
                "lat": 38.3386,
                "lng": 69.9728
            },
            "Khurmi": {
                "lat": 39.5167,
                "lng": 67.5833
            },
            "Kalanak": {
                "lat": 39.0833,
                "lng": 70.5
            },
            "Fayzobod": {
                "lat": 38.55,
                "lng": 69.3167
            },
            "Nijon\u00ed": {
                "lat": 39.9667,
                "lng": 68.9167
            },
            "Chavkandak": {
                "lat": 39.9,
                "lng": 68.9333
            },
            "Rasht": {
                "lat": 39.0167,
                "lng": 70.3833
            },
            "Pakhtaobod": {
                "lat": 38.4667,
                "lng": 68.1333
            },
            "Langar": {
                "lat": 39.45,
                "lng": 69.55
            },
            "Rarz": {
                "lat": 39.3803,
                "lng": 68.7442
            },
            "Buved": {
                "lat": 37.5833,
                "lng": 71.5167
            },
            "Farob": {
                "lat": 39.2408,
                "lng": 67.47
            }
        }
    },
    "NP": {
        "name": "Nepal",
        "cities": {
            "Kathmandu": {
                "lat": 27.71,
                "lng": 85.32
            },
            "Bharatpur": {
                "lat": 27.6833,
                "lng": 84.4333
            },
            "Pokhara": {
                "lat": 28.2083,
                "lng": 83.9889
            },
            "Jitpur": {
                "lat": 27.6666,
                "lng": 85.3333
            },
            "Bir\u0101\u1e6dnagar": {
                "lat": 26.4542,
                "lng": 87.2797
            },
            "Bu\u1e0dh\u0101nilkan\u1e6dha": {
                "lat": 27.7667,
                "lng": 85.3667
            },
            "Ghor\u0101h\u012b": {
                "lat": 28.0333,
                "lng": 82.4833
            },
            "Birga\u00f1j": {
                "lat": 27.0,
                "lng": 84.8667
            },
            "Tokha": {
                "lat": 27.7592,
                "lng": 85.3283
            },
            "Butw\u0101l": {
                "lat": 27.7,
                "lng": 83.466
            },
            "Dhar\u0101n": {
                "lat": 26.8167,
                "lng": 87.2833
            },
            "Lah\u0101n": {
                "lat": 26.7083,
                "lng": 86.4917
            },
            "Dhanga\u1e0dhi\u0307\u0304": {
                "lat": 28.7136,
                "lng": 80.5692
            },
            "Janakpur": {
                "lat": 26.7286,
                "lng": 85.925
            },
            "He\u1e6dau\u1e0d\u0101": {
                "lat": 27.4167,
                "lng": 85.0333
            },
            "Bhaktapur": {
                "lat": 27.6722,
                "lng": 85.4278
            },
            "God\u0101wari\u0307\u0304": {
                "lat": 28.91,
                "lng": 80.59
            },
            "\u012a\u1e6dahari\u0307\u0304": {
                "lat": 26.6631,
                "lng": 87.2744
            },
            "Sanwal": {
                "lat": 27.6061,
                "lng": 83.6606
            },
            "Nep\u0101lga\u00f1j": {
                "lat": 28.05,
                "lng": 81.6167
            },
            "Ratnanagar": {
                "lat": 27.6167,
                "lng": 84.5
            },
            "Bardi\u0307\u0304b\u0101s": {
                "lat": 26.98,
                "lng": 85.9
            },
            "Banep\u0101": {
                "lat": 27.6297,
                "lng": 85.5203
            },
            "Kirtipur": {
                "lat": 27.6781,
                "lng": 85.2769
            },
            "Bhair\u0101haw\u0101": {
                "lat": 27.5,
                "lng": 83.45
            },
            "Tilakpur": {
                "lat": 28.525,
                "lng": 81.1208
            },
            "Gulariy\u0101": {
                "lat": 28.2331,
                "lng": 81.3331
            },
            "Gorkh\u0101": {
                "lat": 28.0,
                "lng": 84.6333
            },
            "Bhajani": {
                "lat": 28.4957,
                "lng": 80.9816
            },
            "Tuls\u012bpur": {
                "lat": 28.1278,
                "lng": 82.2956
            },
            "W\u0101li\u1e45": {
                "lat": 27.9889,
                "lng": 83.7675
            },
            "Simraunga\u1e0dh": {
                "lat": 26.8894,
                "lng": 85.1169
            },
            "Birendranagar": {
                "lat": 28.6,
                "lng": 81.6333
            },
            "Kalaiy\u0101": {
                "lat": 27.0333,
                "lng": 85.0
            },
            "P\u0101\u0303chkh\u0101l": {
                "lat": 27.65,
                "lng": 85.6167
            },
            "Ti\u0307\u0304npiple": {
                "lat": 27.65,
                "lng": 85.6167
            },
            "Galkot": {
                "lat": 28.2,
                "lng": 83.4167
            },
            "Shambhun\u0101th": {
                "lat": 26.63,
                "lng": 86.67
            },
            "Hanum\u0101nnagar": {
                "lat": 26.5406,
                "lng": 86.7486
            },
            "Ratnapur": {
                "lat": 27.86,
                "lng": 83.89
            },
            "B\u0101rhadashi\u0307\u0304": {
                "lat": 26.55,
                "lng": 87.92
            },
            "Musiko\u1e6d-Khala\u1e45g\u0101": {
                "lat": 28.63,
                "lng": 82.49
            },
            "Udaypur Ga\u1e0dhi\u0307\u0304": {
                "lat": 26.9333,
                "lng": 86.5167
            },
            "B\u0101glung": {
                "lat": 28.2667,
                "lng": 83.6
            },
            "Shikharpur": {
                "lat": 27.7467,
                "lng": 85.5097
            },
            "P\u0101lp\u0101": {
                "lat": 27.8667,
                "lng": 83.55
            },
            "Siraha": {
                "lat": 26.6528,
                "lng": 86.2075
            },
            "Panauti": {
                "lat": 27.5833,
                "lng": 85.5167
            },
            "Bidur": {
                "lat": 27.89,
                "lng": 85.1597
            },
            "Kh\u0101\u0303db\u0101ri\u0307\u0304": {
                "lat": 27.3667,
                "lng": 87.2167
            },
            "Mala\u1e45gaw\u0101": {
                "lat": 26.8667,
                "lng": 85.5667
            },
            "Dak\u1e63i\u1e47k\u0101li\u0307\u0304": {
                "lat": 27.5833,
                "lng": 85.25
            },
            "Jaleshwar": {
                "lat": 26.6333,
                "lng": 85.8
            },
            "Bhairi\u0307\u0304": {
                "lat": 28.93,
                "lng": 81.63
            },
            "Dadeldhur\u0101": {
                "lat": 29.3,
                "lng": 80.6
            },
            "Bhadrapur": {
                "lat": 26.5444,
                "lng": 88.0944
            },
            "Bar\u012bkot": {
                "lat": 29.04,
                "lng": 82.33
            },
            "\u012al\u0101m": {
                "lat": 26.908,
                "lng": 87.926
            },
            "R\u0101jga\u1e0dh": {
                "lat": 26.5,
                "lng": 87.9333
            },
            "R\u0101mechh\u0101p": {
                "lat": 27.326,
                "lng": 86.087
            },
            "Bhimphedi": {
                "lat": 27.551,
                "lng": 85.13
            },
            "Salyan": {
                "lat": 28.35,
                "lng": 82.1833
            },
            "I\u1e6dahar\u0101": {
                "lat": 26.55,
                "lng": 87.62
            },
            "Bandi\u0307\u0304pur": {
                "lat": 27.9333,
                "lng": 84.4167
            },
            "Pragatinagar": {
                "lat": 27.68,
                "lng": 84.19
            },
            "Lib\u0101nggaon": {
                "lat": 28.3,
                "lng": 82.6333
            },
            "Manahari\u0307\u0304": {
                "lat": 27.55,
                "lng": 84.79
            },
            "Sarkegh\u0101\u1e6d": {
                "lat": 29.7833,
                "lng": 81.9333
            },
            "Haraiy\u0101": {
                "lat": 27.09,
                "lng": 85.09
            },
            "Amritpur": {
                "lat": 28.1167,
                "lng": 81.6
            },
            "Juml\u0101": {
                "lat": 29.25,
                "lng": 82.2167
            },
            "Kalaun": {
                "lat": 29.84,
                "lng": 80.54
            }
        }
    },
    "GA": {
        "name": "Gabon",
        "cities": {
            "Libreville": {
                "lat": 0.3903,
                "lng": 9.4542
            },
            "Port-Gentil": {
                "lat": -0.7167,
                "lng": 8.7833
            },
            "Franceville": {
                "lat": -1.6333,
                "lng": 13.5833
            },
            "Owendo": {
                "lat": 0.2833,
                "lng": 9.5
            },
            "Oyem": {
                "lat": 1.6,
                "lng": 11.5667
            },
            "Ntoum": {
                "lat": 0.3844,
                "lng": 9.7539
            },
            "Moanda": {
                "lat": -1.5667,
                "lng": 13.2
            },
            "Lambar\u00e9n\u00e9": {
                "lat": -0.6883,
                "lng": 10.2319
            },
            "Bitam": {
                "lat": 2.0833,
                "lng": 11.4833
            },
            "Tchibanga": {
                "lat": -2.9333,
                "lng": 11.0
            },
            "Mouila": {
                "lat": -1.8639,
                "lng": 11.0575
            },
            "Koulamoutou": {
                "lat": -1.1333,
                "lng": 12.4833
            },
            "Makokou": {
                "lat": 0.5667,
                "lng": 12.8667
            },
            "Okondja": {
                "lat": -0.6519,
                "lng": 13.6778
            },
            "Ndend\u00e9": {
                "lat": -2.4014,
                "lng": 11.3589
            },
            "Mayumba": {
                "lat": -3.4167,
                "lng": 10.65
            },
            "Mitzic": {
                "lat": 0.7833,
                "lng": 11.5667
            },
            "M\u00e9kambo": {
                "lat": 1.0167,
                "lng": 13.9333
            },
            "Ombou\u00e9": {
                "lat": -1.5667,
                "lng": 9.25
            },
            "Bifoun": {
                "lat": -0.3333,
                "lng": 10.3833
            },
            "Gamba": {
                "lat": -2.725,
                "lng": 10.0167
            }
        }
    },
    "HR": {
        "name": "Croatia",
        "cities": {
            "Zagreb": {
                "lat": 45.8131,
                "lng": 15.9775
            },
            "Split": {
                "lat": 43.51,
                "lng": 16.44
            },
            "Rijeka": {
                "lat": 45.3272,
                "lng": 14.4411
            },
            "Osijek": {
                "lat": 45.5556,
                "lng": 18.6944
            },
            "Sesvete": {
                "lat": 45.8269,
                "lng": 16.1111
            },
            "Zadar": {
                "lat": 44.1194,
                "lng": 15.2319
            },
            "Velika Gorica": {
                "lat": 45.7,
                "lng": 16.0667
            },
            "Pula": {
                "lat": 44.8703,
                "lng": 13.8456
            },
            "Karlovac": {
                "lat": 45.4833,
                "lng": 15.55
            },
            "Slavonski Brod": {
                "lat": 45.1667,
                "lng": 18.0167
            },
            "Vara\u017edin": {
                "lat": 46.3081,
                "lng": 16.3378
            },
            "\u0160ibenik": {
                "lat": 43.735,
                "lng": 15.8906
            },
            "Dubrovnik": {
                "lat": 42.6403,
                "lng": 18.1083
            },
            "Sisak": {
                "lat": 45.4872,
                "lng": 16.3761
            },
            "Ka\u0161tel Stari": {
                "lat": 43.55,
                "lng": 16.35
            },
            "Samobor": {
                "lat": 45.8,
                "lng": 15.7167
            },
            "Bjelovar": {
                "lat": 45.9,
                "lng": 16.8333
            },
            "Vinkovci": {
                "lat": 45.2911,
                "lng": 18.8011
            },
            "Koprivnica": {
                "lat": 46.15,
                "lng": 16.8167
            },
            "\u010cakovec": {
                "lat": 46.3858,
                "lng": 16.4333
            },
            "Zapre\u0161i\u0107": {
                "lat": 45.8572,
                "lng": 15.805
            },
            "Solin": {
                "lat": 43.5394,
                "lng": 16.4831
            },
            "\u00d0akovo": {
                "lat": 45.31,
                "lng": 18.41
            },
            "Sinj": {
                "lat": 43.7,
                "lng": 16.6333
            },
            "Vukovar": {
                "lat": 45.3444,
                "lng": 19.0025
            },
            "Po\u017eega": {
                "lat": 45.3331,
                "lng": 17.6736
            },
            "Petrinja": {
                "lat": 45.4406,
                "lng": 16.2783
            },
            "Kutina": {
                "lat": 45.4667,
                "lng": 16.7833
            },
            "Virovitica": {
                "lat": 45.8333,
                "lng": 17.3833
            },
            "Kri\u017eevci": {
                "lat": 46.0258,
                "lng": 16.5425
            },
            "Dugo Selo": {
                "lat": 45.8058,
                "lng": 16.2331
            },
            "Pore\u010d": {
                "lat": 45.2272,
                "lng": 13.5958
            },
            "Vi\u0161kovo": {
                "lat": 45.3778,
                "lng": 14.3861
            },
            "Metkovi\u0107": {
                "lat": 43.05,
                "lng": 17.65
            },
            "Sveti Ivan Zelina": {
                "lat": 45.9596,
                "lng": 16.2431
            },
            "Jastrebarsko": {
                "lat": 45.6719,
                "lng": 15.6511
            },
            "Na\u0161ice": {
                "lat": 45.4833,
                "lng": 18.1
            },
            "Omi\u0161": {
                "lat": 43.4333,
                "lng": 16.6833
            },
            "Makarska": {
                "lat": 43.3,
                "lng": 17.0333
            },
            "Ivani\u0107-Grad": {
                "lat": 45.7081,
                "lng": 16.3947
            },
            "Vrbovec": {
                "lat": 45.8833,
                "lng": 16.4333
            },
            "Rovinj": {
                "lat": 45.0833,
                "lng": 13.6333
            },
            "Ivanec": {
                "lat": 46.2264,
                "lng": 16.1253
            },
            "Umag": {
                "lat": 45.4333,
                "lng": 13.5167
            },
            "Trogir": {
                "lat": 43.5169,
                "lng": 16.2514
            },
            "Ogulin": {
                "lat": 45.2669,
                "lng": 15.2248
            },
            "Novi Marof": {
                "lat": 46.1667,
                "lng": 16.3333
            },
            "Nova Gradi\u0161ka": {
                "lat": 45.25,
                "lng": 17.3833
            },
            "Knin": {
                "lat": 44.0414,
                "lng": 16.1986
            },
            "Krapina": {
                "lat": 46.16,
                "lng": 15.87
            },
            "Slatina": {
                "lat": 45.7022,
                "lng": 17.7011
            },
            "Gospi\u0107": {
                "lat": 44.546,
                "lng": 15.375
            },
            "Novska": {
                "lat": 45.3333,
                "lng": 16.9833
            },
            "Nedeli\u0161\u0107e": {
                "lat": 46.3833,
                "lng": 16.3833
            },
            "Matulji": {
                "lat": 45.3667,
                "lng": 14.3167
            },
            "Brdovec": {
                "lat": 45.8667,
                "lng": 15.7667
            },
            "Opatija": {
                "lat": 45.3333,
                "lng": 14.3
            },
            "Labin": {
                "lat": 45.0833,
                "lng": 14.1167
            },
            "Podstrana": {
                "lat": 43.48,
                "lng": 16.58
            },
            "Popova\u010da": {
                "lat": 45.5697,
                "lng": 16.625
            },
            "Duga Resa": {
                "lat": 45.4472,
                "lng": 15.4958
            },
            "Kastav": {
                "lat": 45.375,
                "lng": 14.349
            },
            "Daruvar": {
                "lat": 45.5929,
                "lng": 17.2237
            },
            "Crikvenica": {
                "lat": 45.1833,
                "lng": 14.7
            },
            "Valpovo": {
                "lat": 45.6667,
                "lng": 18.4167
            },
            "Benkovac": {
                "lat": 44.0344,
                "lng": 15.6128
            },
            "\u010cepin": {
                "lat": 45.5167,
                "lng": 18.5667
            },
            "\u017dupanja": {
                "lat": 45.07,
                "lng": 18.7
            },
            "Imotski": {
                "lat": 43.45,
                "lng": 17.2167
            },
            "Pleternica": {
                "lat": 45.2833,
                "lng": 17.8
            },
            "Beli\u0161\u0107e": {
                "lat": 45.6833,
                "lng": 18.4
            },
            "Zabok": {
                "lat": 46.0333,
                "lng": 15.9167
            },
            "Vodice": {
                "lat": 43.75,
                "lng": 15.7667
            },
            "Gare\u0161nica": {
                "lat": 45.5667,
                "lng": 16.9333
            },
            "Pazin": {
                "lat": 45.2403,
                "lng": 13.9367
            }
        }
    },
    "LT": {
        "name": "Lithuania",
        "cities": {
            "Vilnius": {
                "lat": 54.6872,
                "lng": 25.28
            },
            "Kaunas": {
                "lat": 54.8972,
                "lng": 23.8861
            },
            "Klaip\u0117da": {
                "lat": 55.7125,
                "lng": 21.135
            },
            "Panev\u0117\u017eys": {
                "lat": 55.7333,
                "lng": 24.35
            },
            "\u0160iauliai": {
                "lat": 55.9333,
                "lng": 23.3167
            },
            "Alytus": {
                "lat": 54.4014,
                "lng": 24.0492
            },
            "Marijampol\u0117": {
                "lat": 54.5472,
                "lng": 23.35
            },
            "Ma\u017eeikiai": {
                "lat": 56.3167,
                "lng": 22.3333
            },
            "Jonava": {
                "lat": 55.0722,
                "lng": 24.2806
            },
            "Utena": {
                "lat": 55.5,
                "lng": 25.6
            },
            "K\u0117dainiai": {
                "lat": 55.2833,
                "lng": 23.9667
            },
            "Tel\u0161iai": {
                "lat": 55.9833,
                "lng": 22.25
            },
            "Taurag\u0117": {
                "lat": 55.2522,
                "lng": 22.2897
            },
            "Ukmerg\u0117": {
                "lat": 55.25,
                "lng": 24.75
            },
            "Visaginas": {
                "lat": 55.598,
                "lng": 26.438
            },
            "Palanga": {
                "lat": 55.9167,
                "lng": 21.0667
            },
            "Plung\u0117": {
                "lat": 55.9167,
                "lng": 21.85
            },
            "Kretinga": {
                "lat": 55.89,
                "lng": 21.2422
            },
            "\u0160ilut\u0117": {
                "lat": 55.35,
                "lng": 21.4833
            },
            "Garg\u017edai": {
                "lat": 55.7128,
                "lng": 21.4033
            },
            "Radvili\u0161kis": {
                "lat": 55.8,
                "lng": 23.55
            },
            "Druskininkai": {
                "lat": 54.0167,
                "lng": 23.9667
            },
            "Elektr\u0117nai": {
                "lat": 54.7878,
                "lng": 24.6711
            },
            "Roki\u0161kis": {
                "lat": 55.9667,
                "lng": 25.5833
            },
            "Auk\u0161tieji Paneriai": {
                "lat": 54.6262,
                "lng": 25.181
            },
            "Kur\u0161\u0117nai": {
                "lat": 55.9833,
                "lng": 22.9167
            },
            "Jurbarkas": {
                "lat": 55.0833,
                "lng": 22.7667
            },
            "Liki\u0161kiai": {
                "lat": 54.4,
                "lng": 23.981
            },
            "Bir\u017eai": {
                "lat": 56.2,
                "lng": 24.75
            },
            "Vilkavi\u0161kis": {
                "lat": 54.65,
                "lng": 23.0333
            },
            "Garliava": {
                "lat": 54.8167,
                "lng": 23.8667
            },
            "Grigi\u0161k\u0117s": {
                "lat": 54.6694,
                "lng": 25.0917
            },
            "Raseiniai": {
                "lat": 55.3667,
                "lng": 23.1167
            },
            "Lentvaris": {
                "lat": 54.65,
                "lng": 25.0667
            },
            "Prienai": {
                "lat": 54.6333,
                "lng": 23.9417
            },
            "Anyk\u0161\u010diai": {
                "lat": 55.5333,
                "lng": 25.1
            },
            "Joni\u0161kis": {
                "lat": 56.2333,
                "lng": 23.6
            },
            "Kai\u0161iadorys": {
                "lat": 54.8667,
                "lng": 24.45
            },
            "Naujoji Akmen\u0117": {
                "lat": 56.3167,
                "lng": 22.9
            },
            "Var\u0117na": {
                "lat": 54.2111,
                "lng": 24.5722
            },
            "Kelm\u0117": {
                "lat": 55.6333,
                "lng": 22.9333
            },
            "\u0160al\u010dininkai": {
                "lat": 54.3167,
                "lng": 25.3833
            },
            "Pasvalys": {
                "lat": 56.0594,
                "lng": 24.4036
            },
            "Kupi\u0161kis": {
                "lat": 55.8333,
                "lng": 24.9667
            },
            "Zarasai": {
                "lat": 55.7333,
                "lng": 26.25
            },
            "Trakai": {
                "lat": 54.6333,
                "lng": 24.9333
            },
            "\u0160irvintos": {
                "lat": 55.0361,
                "lng": 24.9694
            },
            "Mol\u0117tai": {
                "lat": 55.2333,
                "lng": 25.4167
            },
            "Kazl\u0173 R\u016bda": {
                "lat": 54.75,
                "lng": 23.5
            },
            "\u0160akiai": {
                "lat": 54.95,
                "lng": 23.05
            },
            "Skuodas": {
                "lat": 56.2667,
                "lng": 21.5333
            },
            "Ignalina": {
                "lat": 55.35,
                "lng": 26.1667
            },
            "\u0160ilal\u0117": {
                "lat": 55.4833,
                "lng": 22.1833
            },
            "Pakruojis": {
                "lat": 55.9667,
                "lng": 23.8667
            },
            "\u0160ven\u010dionys": {
                "lat": 55.1333,
                "lng": 26.1556
            },
            "Kalvarija": {
                "lat": 54.4167,
                "lng": 23.2167
            },
            "Lazdijai": {
                "lat": 54.2333,
                "lng": 23.5167
            },
            "Rietavas": {
                "lat": 55.7167,
                "lng": 21.9333
            },
            "Bir\u0161tonas": {
                "lat": 54.6028,
                "lng": 24.0206
            },
            "Nida": {
                "lat": 55.3033,
                "lng": 21.0056
            },
            "Pag\u0117giai": {
                "lat": 55.1333,
                "lng": 21.9167
            }
        }
    },
    "MD": {
        "name": "Moldova",
        "cities": {
            "Chisinau": {
                "lat": 47.0228,
                "lng": 28.8353
            },
            "Tiraspol": {
                "lat": 46.8403,
                "lng": 29.6433
            },
            "B\u0103l\u0163i": {
                "lat": 47.7667,
                "lng": 27.9167
            },
            "Bender": {
                "lat": 46.8333,
                "lng": 29.4833
            },
            "R\u00eebni\u0163a": {
                "lat": 47.7667,
                "lng": 29.0
            },
            "Ungheni": {
                "lat": 47.2167,
                "lng": 27.8167
            },
            "Cahul": {
                "lat": 45.9167,
                "lng": 28.1836
            },
            "Dub\u0103sari": {
                "lat": 47.2667,
                "lng": 29.1667
            },
            "Soroca": {
                "lat": 48.1667,
                "lng": 28.3
            },
            "Orhei": {
                "lat": 47.3833,
                "lng": 28.8167
            },
            "Comrat": {
                "lat": 46.3167,
                "lng": 28.6667
            },
            "Slobozia": {
                "lat": 46.7333,
                "lng": 29.7
            },
            "Stra\u015feni": {
                "lat": 47.1333,
                "lng": 28.6167
            },
            "Durle\u015fti": {
                "lat": 47.0333,
                "lng": 28.95
            },
            "Cead\u00eer-Lunga": {
                "lat": 46.05,
                "lng": 28.8333
            },
            "Codru": {
                "lat": 46.9753,
                "lng": 28.8194
            },
            "Edine\u0163": {
                "lat": 48.1667,
                "lng": 27.3167
            },
            "Drochia": {
                "lat": 48.0333,
                "lng": 27.75
            },
            "C\u0103u\u015feni": {
                "lat": 46.6333,
                "lng": 29.4
            },
            "Ialoveni": {
                "lat": 46.95,
                "lng": 28.7833
            },
            "H\u00eence\u015fti": {
                "lat": 46.8167,
                "lng": 28.5833
            },
            "S\u00eengerei": {
                "lat": 47.6333,
                "lng": 28.15
            },
            "Taraclia": {
                "lat": 45.9,
                "lng": 28.6689
            },
            "Vulc\u0103ne\u015fti": {
                "lat": 45.6833,
                "lng": 28.4028
            },
            "F\u0103le\u015fti": {
                "lat": 47.5722,
                "lng": 27.7139
            },
            "Flore\u015fti": {
                "lat": 47.8933,
                "lng": 28.3014
            },
            "Cimi\u015flia": {
                "lat": 46.5167,
                "lng": 28.7833
            },
            "Congaz": {
                "lat": 46.1083,
                "lng": 28.5972
            },
            "Rezina": {
                "lat": 47.7333,
                "lng": 28.95
            },
            "Coste\u015fti": {
                "lat": 46.8678,
                "lng": 28.7689
            },
            "Anenii Noi": {
                "lat": 46.8833,
                "lng": 29.2167
            },
            "C\u0103l\u0103ra\u015fi": {
                "lat": 47.25,
                "lng": 28.3
            },
            "Cricova": {
                "lat": 47.1333,
                "lng": 28.8667
            },
            "Dnestrovsc": {
                "lat": 46.6167,
                "lng": 29.9167
            },
            "Tru\u015feni": {
                "lat": 47.0667,
                "lng": 28.6833
            },
            "B\u0103cioi": {
                "lat": 46.9122,
                "lng": 28.8839
            },
            "S\u00eengera": {
                "lat": 46.9139,
                "lng": 28.9708
            },
            "Borogani": {
                "lat": 46.3667,
                "lng": 28.5167
            },
            "Grigoriopol": {
                "lat": 47.1503,
                "lng": 29.2925
            },
            "R\u00ee\u015fcani": {
                "lat": 47.9572,
                "lng": 27.5539
            },
            "Copceac": {
                "lat": 45.85,
                "lng": 28.6944
            },
            "Peresecina": {
                "lat": 47.25,
                "lng": 28.7667
            },
            "Chi\u0163cani": {
                "lat": 46.7833,
                "lng": 29.6167
            },
            "Camenca": {
                "lat": 48.0167,
                "lng": 28.7167
            },
            "St\u0103uceni": {
                "lat": 47.0875,
                "lng": 28.8703
            },
            "Glodeni": {
                "lat": 47.7667,
                "lng": 27.5167
            },
            "Basarabeasca": {
                "lat": 46.3336,
                "lng": 28.9614
            },
            "Nisporeni": {
                "lat": 47.0833,
                "lng": 28.1833
            },
            "Leova": {
                "lat": 46.4833,
                "lng": 28.25
            },
            "Briceni": {
                "lat": 48.3611,
                "lng": 27.0839
            },
            "Telene\u015fti": {
                "lat": 47.5028,
                "lng": 28.3667
            },
            "Dondu\u015feni": {
                "lat": 48.2167,
                "lng": 27.5833
            },
            "\u015etefan Vod\u0103": {
                "lat": 46.5153,
                "lng": 29.6631
            },
            "Criuleni": {
                "lat": 47.2167,
                "lng": 29.1667
            },
            "\u015eold\u0103ne\u015fti": {
                "lat": 47.8167,
                "lng": 28.8
            },
            "Cantemir": {
                "lat": 46.2667,
                "lng": 28.2167
            },
            "Ocni\u0163a": {
                "lat": 48.3853,
                "lng": 27.4392
            },
            "Cocieri": {
                "lat": 47.3,
                "lng": 29.1167
            }
        }
    },
    "PG": {
        "name": "Papua New Guinea",
        "cities": {
            "Port Moresby": {
                "lat": -9.4789,
                "lng": 147.1494
            },
            "Lae": {
                "lat": -6.7303,
                "lng": 147.0008
            },
            "Popondetta": {
                "lat": -8.7656,
                "lng": 148.2347
            },
            "Mendi": {
                "lat": -6.1478,
                "lng": 143.6572
            },
            "Arawa": {
                "lat": -6.225,
                "lng": 155.5667
            },
            "Mount Hagen": {
                "lat": -5.86,
                "lng": 144.24
            },
            "Madang": {
                "lat": -5.2167,
                "lng": 145.8
            },
            "Kimbe": {
                "lat": -5.5575,
                "lng": 150.155
            },
            "Wewak": {
                "lat": -3.55,
                "lng": 143.6333
            },
            "Goroka": {
                "lat": -6.0833,
                "lng": 145.3833
            },
            "Kokopo": {
                "lat": -4.35,
                "lng": 152.2736
            },
            "Bulolo": {
                "lat": -7.2,
                "lng": 146.6467
            },
            "Kavieng": {
                "lat": -2.5667,
                "lng": 150.8
            },
            "Alotau": {
                "lat": -10.3167,
                "lng": 150.4333
            },
            "Tari": {
                "lat": -5.8489,
                "lng": 142.9506
            },
            "Daru": {
                "lat": -9.0833,
                "lng": 143.2
            },
            "Vanimo": {
                "lat": -2.6667,
                "lng": 141.2833
            },
            "Kundiawa": {
                "lat": -6.0167,
                "lng": 144.9667
            },
            "Tabubil": {
                "lat": -5.275,
                "lng": 141.2264
            },
            "Wau": {
                "lat": -7.3389,
                "lng": 146.7167
            },
            "Rabaul": {
                "lat": -4.2,
                "lng": 152.1833
            },
            "Kieta": {
                "lat": -6.2158,
                "lng": 155.6328
            },
            "Kerema": {
                "lat": -7.9667,
                "lng": 145.7667
            },
            "Lorengau": {
                "lat": -2.0306,
                "lng": 147.2722
            },
            "Wabag": {
                "lat": -5.4919,
                "lng": 143.7217
            },
            "Sohano": {
                "lat": -5.4297,
                "lng": 154.6711
            },
            "Hoskins": {
                "lat": -5.4746,
                "lng": 150.41
            },
            "Amau": {
                "lat": -10.0426,
                "lng": 148.565
            },
            "Kurumul": {
                "lat": -5.855,
                "lng": 144.6311
            },
            "Buka": {
                "lat": -5.4219,
                "lng": 154.6728
            }
        }
    },
    "BJ": {
        "name": "Benin",
        "cities": {
            "Cotonou": {
                "lat": 6.3667,
                "lng": 2.4333
            },
            "Abomey-Calavi": {
                "lat": 6.4486,
                "lng": 2.3556
            },
            "Godom\u00e8": {
                "lat": 6.3667,
                "lng": 2.35
            },
            "Banikoara": {
                "lat": 11.3,
                "lng": 2.4333
            },
            "Djougou": {
                "lat": 9.7,
                "lng": 1.6667
            },
            "Tchaourou": {
                "lat": 8.8833,
                "lng": 2.6
            },
            "Parakou": {
                "lat": 9.35,
                "lng": 2.6167
            },
            "Kandi": {
                "lat": 11.1286,
                "lng": 2.9369
            },
            "Aplahou\u00e9": {
                "lat": 6.9333,
                "lng": 1.6833
            },
            "Kalal\u00e9": {
                "lat": 10.2953,
                "lng": 3.3786
            },
            "Malanville": {
                "lat": 11.8667,
                "lng": 3.3833
            },
            "K\u00e9tou": {
                "lat": 7.3581,
                "lng": 2.6075
            },
            "Bohicon": {
                "lat": 7.2,
                "lng": 2.0667
            },
            "Djakotom\u00e9": {
                "lat": 6.9,
                "lng": 1.7167
            },
            "Porto-Novo": {
                "lat": 6.4972,
                "lng": 2.605
            },
            "Zagnanado": {
                "lat": 7.2667,
                "lng": 2.35
            },
            "Bassila": {
                "lat": 9.0167,
                "lng": 1.6667
            },
            "Avrankou": {
                "lat": 6.55,
                "lng": 2.6667
            },
            "Allada": {
                "lat": 6.65,
                "lng": 2.15
            },
            "Miss\u00e9r\u00e9t\u00e9": {
                "lat": 6.5625,
                "lng": 2.5853
            },
            "Glazou\u00e9": {
                "lat": 7.9736,
                "lng": 2.24
            },
            "Pob\u00e9": {
                "lat": 6.9667,
                "lng": 2.6833
            },
            "Djidja": {
                "lat": 7.3333,
                "lng": 1.9333
            },
            "Lalo": {
                "lat": 6.9167,
                "lng": 1.8833
            },
            "Gogounou": {
                "lat": 10.8386,
                "lng": 2.8361
            },
            "Adjaou\u00e8r\u00e8": {
                "lat": 7.0,
                "lng": 2.6167
            },
            "Sak\u00e9t\u00e9": {
                "lat": 6.7364,
                "lng": 2.6581
            },
            "Mat\u00e9ri": {
                "lat": 10.6978,
                "lng": 1.0633
            },
            "Ifanhim": {
                "lat": 6.6667,
                "lng": 2.7167
            },
            "Ndali": {
                "lat": 9.8608,
                "lng": 2.7181
            },
            "Dassa-Zoum\u00e9": {
                "lat": 7.75,
                "lng": 2.1833
            },
            "Kouand\u00e9": {
                "lat": 10.3317,
                "lng": 1.6914
            },
            "Bant\u00e9": {
                "lat": 8.4167,
                "lng": 1.8833
            },
            "Lokossa": {
                "lat": 6.6333,
                "lng": 1.7167
            },
            "Natitingou": {
                "lat": 10.3,
                "lng": 1.3667
            },
            "Tota": {
                "lat": 6.8,
                "lng": 1.7833
            },
            "Adjarra": {
                "lat": 6.5333,
                "lng": 2.2667
            },
            "Ouidah": {
                "lat": 6.3667,
                "lng": 2.0833
            },
            "Sinend\u00e9": {
                "lat": 10.3447,
                "lng": 2.3792
            },
            "Abomey": {
                "lat": 7.1856,
                "lng": 1.9881
            },
            "S\u00e9gbana": {
                "lat": 10.9278,
                "lng": 3.6944
            },
            "Toviklin": {
                "lat": 6.8333,
                "lng": 1.8167
            },
            "Boukoumb\u00e9": {
                "lat": 10.1833,
                "lng": 1.1
            },
            "P\u00e8r\u00e8r\u00e8": {
                "lat": 9.7994,
                "lng": 2.9928
            },
            "P\u00e9honko": {
                "lat": 10.2283,
                "lng": 2.0019
            },
            "So-Awa": {
                "lat": 6.4667,
                "lng": 2.4167
            },
            "Toffo": {
                "lat": 6.85,
                "lng": 2.0833
            },
            "Tangui\u00e9ta": {
                "lat": 10.6167,
                "lng": 1.2667
            },
            "P\u00e9da-Hou\u00e9yogb\u00e9": {
                "lat": 6.45,
                "lng": 1.9333
            },
            "Ouak\u00e9": {
                "lat": 9.6617,
                "lng": 1.3847
            },
            "Zogbodom\u00e9": {
                "lat": 7.0833,
                "lng": 2.1
            },
            "Kopargo": {
                "lat": 9.8375,
                "lng": 1.5481
            },
            "Bopa": {
                "lat": 6.5833,
                "lng": 1.9833
            },
            "Nikki": {
                "lat": 9.9333,
                "lng": 3.2083
            },
            "Karimama": {
                "lat": 12.0667,
                "lng": 3.1833
            },
            "Dangbo": {
                "lat": 6.5,
                "lng": 2.6833
            },
            "Adjohon": {
                "lat": 6.7,
                "lng": 2.4667
            },
            "Ouinhri": {
                "lat": 7.0,
                "lng": 2.45
            },
            "Grand-Popo": {
                "lat": 6.2833,
                "lng": 1.8333
            },
            "Athi\u00e9m\u00e9": {
                "lat": 6.5833,
                "lng": 1.6667
            },
            "Agbangnizoun": {
                "lat": 7.0667,
                "lng": 1.9667
            },
            "Tori-Bossito": {
                "lat": 6.5031,
                "lng": 2.145
            },
            "Cov\u00e9": {
                "lat": 7.2333,
                "lng": 2.3
            },
            "Toukountouna": {
                "lat": 10.4986,
                "lng": 1.3756
            },
            "K\u00e9rou": {
                "lat": 10.825,
                "lng": 2.1094
            },
            "Savalou": {
                "lat": 7.9333,
                "lng": 1.9667
            },
            "Ekp\u00e9": {
                "lat": 6.4,
                "lng": 2.5333
            },
            "Com\u00e9": {
                "lat": 6.4,
                "lng": 1.8833
            },
            "Sav\u00e9": {
                "lat": 8.0333,
                "lng": 2.4833
            },
            "Bemb\u00e8r\u00e8k\u00e8": {
                "lat": 10.225,
                "lng": 2.6681
            },
            "Agblangandan": {
                "lat": 6.3667,
                "lng": 2.5167
            },
            "Founougo": {
                "lat": 11.4808,
                "lng": 2.5322
            },
            "Bonou": {
                "lat": 6.9,
                "lng": 2.45
            },
            "Golo-Djigb\u00e9": {
                "lat": 6.5403,
                "lng": 2.3253
            },
            "Bouka": {
                "lat": 10.2167,
                "lng": 3.1333
            },
            "Paouignan": {
                "lat": 7.6937,
                "lng": 2.2673
            },
            "Idigny": {
                "lat": 7.4833,
                "lng": 2.7
            },
            "Gu\u00e9n\u00e9": {
                "lat": 11.7306,
                "lng": 3.2242
            },
            "Goumori": {
                "lat": 11.1833,
                "lng": 2.2833
            },
            "Azov\u00e9": {
                "lat": 6.95,
                "lng": 1.7
            },
            "Doutou": {
                "lat": 6.55,
                "lng": 1.8833
            },
            "Sori": {
                "lat": 10.7281,
                "lng": 2.7825
            },
            "Bagou": {
                "lat": 10.8147,
                "lng": 2.7164
            },
            "Vakon": {
                "lat": 6.5167,
                "lng": 2.5667
            },
            "Kissa": {
                "lat": 7.0333,
                "lng": 1.7833
            },
            "Issaba": {
                "lat": 7.0833,
                "lng": 2.6167
            },
            "Atchoupa": {
                "lat": 6.5333,
                "lng": 2.6333
            },
            "Bouanri": {
                "lat": 10.2,
                "lng": 2.8667
            },
            "Togba": {
                "lat": 7.1,
                "lng": 1.6667
            },
            "Zinvi\u00e9": {
                "lat": 6.6167,
                "lng": 2.35
            },
            "Biro": {
                "lat": 9.9,
                "lng": 2.9333
            },
            "Tohou\u00e9": {
                "lat": 6.3967,
                "lng": 2.5853
            },
            "Lobogo": {
                "lat": 6.6333,
                "lng": 1.9
            },
            "Kolokond\u00e9": {
                "lat": 9.9,
                "lng": 1.7667
            },
            "Akassato": {
                "lat": 6.5,
                "lng": 2.3667
            },
            "Tobr\u00e9": {
                "lat": 10.2,
                "lng": 2.1333
            },
            "Mass\u00e9": {
                "lat": 7.1578,
                "lng": 2.5436
            },
            "Aklanpa": {
                "lat": 8.1684,
                "lng": 2.232
            },
            "Azaouriss\u00e8": {
                "lat": 6.6944,
                "lng": 2.5075
            },
            "Tant\u00e9ga": {
                "lat": 10.85,
                "lng": 1.0333
            },
            "Tiahounkossi": {
                "lat": 10.8167,
                "lng": 1.0667
            },
            "Ina": {
                "lat": 9.9833,
                "lng": 2.7167
            },
            "Adjahom\u00e9": {
                "lat": 7.0618,
                "lng": 1.8368
            },
            "Angara-D\u00e9bou": {
                "lat": 11.3289,
                "lng": 3.0406
            },
            "Garou": {
                "lat": 11.8053,
                "lng": 3.4739
            },
            "S\u00e9gou": {
                "lat": 6.6167,
                "lng": 2.2167
            },
            "Banigb\u00e9": {
                "lat": 6.9,
                "lng": 2.65
            },
            "S\u00e9rarou": {
                "lat": 9.5833,
                "lng": 2.65
            },
            "Dassari": {
                "lat": 10.8158,
                "lng": 1.1406
            },
            "Toui": {
                "lat": 8.6833,
                "lng": 2.6
            },
            "B\u00e9t\u00e9rou": {
                "lat": 9.2,
                "lng": 2.2667
            },
            "Sakabansi": {
                "lat": 10.0442,
                "lng": 3.3821
            },
            "Briniamaro": {
                "lat": 10.7411,
                "lng": 2.0731
            },
            "Sinmp\u00e9r\u00e9kou": {
                "lat": 11.2333,
                "lng": 2.4167
            },
            "Onklou": {
                "lat": 9.5,
                "lng": 1.9833
            },
            "Dah\u00e9": {
                "lat": 6.5167,
                "lng": 1.95
            },
            "Pahou": {
                "lat": 6.3833,
                "lng": 2.2167
            },
            "Ayomi": {
                "lat": 6.7833,
                "lng": 1.7167
            },
            "Adakplam\u00e9": {
                "lat": 7.45,
                "lng": 2.55
            },
            "M\u00e9ridjonou": {
                "lat": 6.4619,
                "lng": 2.6774
            },
            "Gouka": {
                "lat": 8.1333,
                "lng": 1.9667
            },
            "Manta": {
                "lat": 10.3564,
                "lng": 1.1056
            },
            "Doum\u00e9": {
                "lat": 8.0167,
                "lng": 1.6333
            },
            "Atom\u00e9-Av\u00e9gam\u00e9": {
                "lat": 7.2333,
                "lng": 1.65
            },
            "H\u00e9vi\u00e9": {
                "lat": 6.4167,
                "lng": 2.25
            },
            "Toura": {
                "lat": 11.2436,
                "lng": 2.3831
            },
            "Gouand\u00e9": {
                "lat": 10.7828,
                "lng": 0.9186
            },
            "Gomparou": {
                "lat": 11.3,
                "lng": 2.4489
            },
            "Donwari": {
                "lat": 11.1197,
                "lng": 2.8558
            },
            "Kouti": {
                "lat": 6.5542,
                "lng": 2.6616
            },
            "Birni": {
                "lat": 9.9892,
                "lng": 1.5269
            },
            "Siki": {
                "lat": 10.1833,
                "lng": 2.3833
            },
            "Kika": {
                "lat": 9.3,
                "lng": 2.2667
            },
            "Pira": {
                "lat": 8.5,
                "lng": 1.7333
            },
            "Soklogbo": {
                "lat": 7.6937,
                "lng": 2.2673
            },
            "Godohou": {
                "lat": 7.0333,
                "lng": 1.7833
            },
            "Ou\u00e9nou": {
                "lat": 9.787,
                "lng": 2.6375
            },
            "Katagon": {
                "lat": 6.6333,
                "lng": 2.5833
            },
            "Sagon": {
                "lat": 7.15,
                "lng": 2.4167
            },
            "Gbanhi": {
                "lat": 8.4497,
                "lng": 2.4737
            },
            "Sam": {
                "lat": 11.0333,
                "lng": 2.7333
            },
            "Sokouhou\u00e9": {
                "lat": 6.9,
                "lng": 1.6667
            },
            "Kountouri": {
                "lat": 10.405,
                "lng": 0.9425
            },
            "Tori-Cada": {
                "lat": 6.5833,
                "lng": 2.2
            },
            "Couss\u00e9": {
                "lat": 6.85,
                "lng": 2.1333
            },
            "Lissegazoun": {
                "lat": 6.6167,
                "lng": 2.0833
            },
            "Gb\u00e9roubou\u00e8": {
                "lat": 10.5333,
                "lng": 2.7333
            },
            "P\u00e9onga": {
                "lat": 10.3333,
                "lng": 3.2667
            },
            "D\u00e9rassi": {
                "lat": 10.1667,
                "lng": 3.2667
            },
            "Kaboua": {
                "lat": 8.25,
                "lng": 2.6833
            },
            "Agam\u00e9": {
                "lat": 6.7333,
                "lng": 1.7667
            },
            "Guinagourou": {
                "lat": 9.5667,
                "lng": 2.95
            },
            "Ni\u00e9masson": {
                "lat": 10.3078,
                "lng": 2.0378
            },
            "S\u00e9r\u00e9kali": {
                "lat": 9.9186,
                "lng": 3.0434
            },
            "Sanson": {
                "lat": 9.2833,
                "lng": 2.4333
            },
            "Gounarou": {
                "lat": 10.8667,
                "lng": 2.85
            },
            "Agouna": {
                "lat": 7.5667,
                "lng": 1.7
            },
            "F\u00f4-Bour\u00e9": {
                "lat": 10.1167,
                "lng": 2.4
            },
            "Adohoun": {
                "lat": 6.6333,
                "lng": 1.6667
            },
            "Kilibo": {
                "lat": 8.5717,
                "lng": 2.6017
            },
            "Ou\u00e9do-Agu\u00e9ko": {
                "lat": 6.4963,
                "lng": 2.4268
            },
            "Yoko": {
                "lat": 6.7,
                "lng": 2.6167
            },
            "Lonkly": {
                "lat": 7.1333,
                "lng": 1.6833
            },
            "Takon": {
                "lat": 6.65,
                "lng": 2.6167
            },
            "Dougba": {
                "lat": 8.4497,
                "lng": 2.4737
            },
            "Libant\u00e9": {
                "lat": 10.7936,
                "lng": 3.5828
            },
            "Dan": {
                "lat": 7.3167,
                "lng": 2.0667
            },
            "Firou": {
                "lat": 10.9192,
                "lng": 1.9386
            },
            "Ahogbeya": {
                "lat": 7.0333,
                "lng": 1.9167
            },
            "Ganvi\u00e9": {
                "lat": 6.4667,
                "lng": 2.4167
            },
            "Pab\u00e9gou": {
                "lat": 9.8333,
                "lng": 1.5492
            },
            "Hozin": {
                "lat": 6.5333,
                "lng": 2.55
            },
            "Ou\u00e9do": {
                "lat": 6.4833,
                "lng": 2.45
            },
            "Massi": {
                "lat": 9.9167,
                "lng": 1.4667
            },
            "Tomboutou": {
                "lat": 11.855,
                "lng": 3.2892
            },
            "Birni Lafia": {
                "lat": 11.9783,
                "lng": 3.2225
            },
            "Basso": {
                "lat": 10.5,
                "lng": 3.6833
            },
            "Kouarfa": {
                "lat": 10.4833,
                "lng": 1.5167
            },
            "Agatogba": {
                "lat": 6.4,
                "lng": 1.9
            },
            "Tayakou": {
                "lat": 10.55,
                "lng": 1.2
            },
            "Zoudjam\u00e9": {
                "lat": 6.8167,
                "lng": 1.8667
            },
            "Datori": {
                "lat": 10.4017,
                "lng": 0.7828
            },
            "Dasso": {
                "lat": 7.0167,
                "lng": 2.4667
            },
            "Agou\u00e9": {
                "lat": 6.25,
                "lng": 1.6833
            },
            "Bougou": {
                "lat": 9.4333,
                "lng": 1.6167
            },
            "Sonsoro": {
                "lat": 11.0875,
                "lng": 2.7597
            },
            "Dagb\u00e9": {
                "lat": 6.5667,
                "lng": 2.6833
            },
            "Sokotindji": {
                "lat": 10.8528,
                "lng": 3.4297
            },
            "Barei": {
                "lat": 9.6833,
                "lng": 1.5667
            },
            "Tasso": {
                "lat": 9.6737,
                "lng": 3.2333
            },
            "Lagb\u00e9": {
                "lat": 6.6833,
                "lng": 2.6833
            },
            "Lanta": {
                "lat": 7.1,
                "lng": 1.8667
            },
            "Dodji-Bata": {
                "lat": 6.6833,
                "lng": 2.2833
            },
            "Ou\u00e9d\u00e9m\u00e8": {
                "lat": 6.7,
                "lng": 1.6833
            },
            "Adjido": {
                "lat": 7.0333,
                "lng": 1.9833
            },
            "Akpassi": {
                "lat": 8.45,
                "lng": 1.8833
            },
            "Takouta": {
                "lat": 10.3,
                "lng": 1.2
            },
            "Mousoulou": {
                "lat": 7.3899,
                "lng": 1.9367
            },
            "Igana": {
                "lat": 7.0333,
                "lng": 2.7
            },
            "Ouando": {
                "lat": 6.5542,
                "lng": 2.6616
            },
            "Avabodji": {
                "lat": 6.4533,
                "lng": 2.5275
            },
            "D\u00e9kanm\u00e9": {
                "lat": 7.1333,
                "lng": 1.9667
            },
            "Locogahou\u00e9": {
                "lat": 6.8,
                "lng": 1.783
            },
            "S\u00e8m\u00e8r\u00e8": {
                "lat": 9.6268,
                "lng": 1.4515
            },
            "Tanmp\u00e8gr\u00e9": {
                "lat": 10.48,
                "lng": 1.45
            },
            "Dogbo": {
                "lat": 6.8167,
                "lng": 1.7833
            }
        }
    },
    "BH": {
        "name": "Bahrain",
        "cities": {
            "Manama": {
                "lat": 26.225,
                "lng": 50.5775
            },
            "Al Mu\u1e29arraq": {
                "lat": 26.25,
                "lng": 50.6167
            },
            "Mad\u012bnat \u1e28amad": {
                "lat": 26.1128,
                "lng": 50.5139
            },
            "Jidd \u1e28af\u015f": {
                "lat": 26.219,
                "lng": 50.538
            },
            "Mad\u012bnat \u2018\u012as\u00e1": {
                "lat": 26.1736,
                "lng": 50.5478
            },
            "Sitrah": {
                "lat": 26.12,
                "lng": 50.65
            },
            "Al Hamalah": {
                "lat": 26.1497,
                "lng": 50.4653
            },
            "Ad Dir\u0101z": {
                "lat": 26.2186,
                "lng": 50.4756
            },
            "Al M\u0101lik\u012byah": {
                "lat": 26.1008,
                "lng": 50.4878
            },
            "Karr\u0101nah": {
                "lat": 26.2306,
                "lng": 50.5108
            }
        }
    },
    "EE": {
        "name": "Estonia",
        "cities": {
            "Tallinn": {
                "lat": 59.4372,
                "lng": 24.7453
            },
            "Tartu": {
                "lat": 58.3833,
                "lng": 26.7167
            },
            "Narva": {
                "lat": 59.3758,
                "lng": 28.1961
            },
            "P\u00e4rnu": {
                "lat": 58.3833,
                "lng": 24.5
            },
            "Kohtla-J\u00e4rve": {
                "lat": 59.3978,
                "lng": 27.2572
            },
            "Viljandi": {
                "lat": 58.3667,
                "lng": 25.6
            },
            "Maardu": {
                "lat": 59.4781,
                "lng": 25.0161
            },
            "Rakvere": {
                "lat": 59.35,
                "lng": 26.35
            },
            "Kuressaare": {
                "lat": 58.25,
                "lng": 22.4833
            },
            "Sillam\u00e4e": {
                "lat": 59.3931,
                "lng": 27.7742
            },
            "Valga": {
                "lat": 57.7833,
                "lng": 26.0333
            },
            "V\u00f5ru": {
                "lat": 57.8486,
                "lng": 26.9928
            },
            "Keila": {
                "lat": 59.3086,
                "lng": 24.4225
            },
            "J\u00f5hvi": {
                "lat": 59.3575,
                "lng": 27.4269
            },
            "Haapsalu": {
                "lat": 58.9394,
                "lng": 23.5408
            },
            "Paide": {
                "lat": 58.8833,
                "lng": 25.5572
            },
            "P\u00f5lva": {
                "lat": 58.0536,
                "lng": 27.0556
            },
            "Rapla": {
                "lat": 58.9944,
                "lng": 24.8011
            },
            "J\u00f5geva": {
                "lat": 58.7469,
                "lng": 26.4
            },
            "K\u00e4rdla": {
                "lat": 58.9981,
                "lng": 22.7467
            }
        }
    },
    "DJ": {
        "name": "Djibouti",
        "cities": {
            "Djibouti": {
                "lat": 11.5883,
                "lng": 43.145
            },
            "Arta": {
                "lat": 11.5236,
                "lng": 42.8472
            },
            "Ali Sabieh": {
                "lat": 11.15,
                "lng": 42.7167
            },
            "Dikhil": {
                "lat": 11.1167,
                "lng": 42.3667
            },
            "Obock": {
                "lat": 11.9667,
                "lng": 43.2833
            },
            "Yoboki": {
                "lat": 11.5167,
                "lng": 42.1
            },
            "Tadjourah": {
                "lat": 11.7833,
                "lng": 42.8833
            }
        }
    },
    "TN": {
        "name": "Tunisia",
        "cities": {
            "Tunis": {
                "lat": 36.8064,
                "lng": 10.1817
            },
            "Sidi Bouzid": {
                "lat": 35.0381,
                "lng": 9.4858
            },
            "Sfax": {
                "lat": 34.74,
                "lng": 10.76
            },
            "Sousse": {
                "lat": 35.8333,
                "lng": 10.6333
            },
            "Kairouan": {
                "lat": 35.6772,
                "lng": 10.1008
            },
            "M\u00e9touia": {
                "lat": 33.9667,
                "lng": 10.0
            },
            "Kebili": {
                "lat": 33.705,
                "lng": 8.965
            },
            "Sukrah": {
                "lat": 36.8833,
                "lng": 10.25
            },
            "Gab\u00e8s": {
                "lat": 33.8833,
                "lng": 10.1167
            },
            "Ariana": {
                "lat": 36.8625,
                "lng": 10.1956
            },
            "Sakiet ed Daier": {
                "lat": 34.8,
                "lng": 10.78
            },
            "Gafsa": {
                "lat": 34.4225,
                "lng": 8.7842
            },
            "Msaken": {
                "lat": 35.7333,
                "lng": 10.5833
            },
            "Medenine": {
                "lat": 33.3547,
                "lng": 10.5053
            },
            "B\u00e9ja": {
                "lat": 36.7333,
                "lng": 9.1833
            },
            "Kasserine": {
                "lat": 35.1667,
                "lng": 8.8333
            },
            "Rad\u00e8s": {
                "lat": 36.7667,
                "lng": 10.2833
            },
            "Hammamet": {
                "lat": 36.4,
                "lng": 10.6167
            },
            "Tataouine": {
                "lat": 32.9306,
                "lng": 10.45
            },
            "Monastir": {
                "lat": 35.7694,
                "lng": 10.8194
            },
            "La Marsa": {
                "lat": 36.8764,
                "lng": 10.3253
            },
            "Ben Arous": {
                "lat": 36.7472,
                "lng": 10.3333
            },
            "Sakiet ez Zit": {
                "lat": 34.8,
                "lng": 10.77
            },
            "Zarzis": {
                "lat": 33.5,
                "lng": 11.1167
            },
            "Ben Gardane": {
                "lat": 33.1389,
                "lng": 11.2167
            },
            "Mahdia": {
                "lat": 35.5,
                "lng": 11.0667
            },
            "Houmt Souk": {
                "lat": 33.8667,
                "lng": 10.85
            },
            "Fouchana": {
                "lat": 36.7,
                "lng": 10.1667
            },
            "Le Kram": {
                "lat": 36.8333,
                "lng": 10.3167
            },
            "El Kef": {
                "lat": 36.1822,
                "lng": 8.7147
            },
            "El Hamma": {
                "lat": 33.8864,
                "lng": 9.7951
            },
            "Nabeul": {
                "lat": 36.4542,
                "lng": 10.7347
            },
            "Le Bardo": {
                "lat": 36.8092,
                "lng": 10.1406
            },
            "Djemmal": {
                "lat": 35.64,
                "lng": 10.76
            },
            "Korba": {
                "lat": 36.5667,
                "lng": 10.8667
            },
            "Menzel Temime": {
                "lat": 36.7833,
                "lng": 10.9833
            },
            "Ghardimaou": {
                "lat": 36.45,
                "lng": 8.4333
            },
            "Midoun": {
                "lat": 33.8,
                "lng": 11.0
            },
            "Menzel Bourguiba": {
                "lat": 37.15,
                "lng": 9.7833
            },
            "Manouba": {
                "lat": 36.8078,
                "lng": 10.1011
            },
            "K\u00e9libia": {
                "lat": 36.85,
                "lng": 11.1
            },
            "Rass el Djebel": {
                "lat": 37.215,
                "lng": 10.12
            },
            "Oued Lill": {
                "lat": 36.8333,
                "lng": 10.05
            },
            "Moknine": {
                "lat": 35.6333,
                "lng": 10.9
            },
            "Bir Ali Ben Khalifa": {
                "lat": 34.7339,
                "lng": 10.1
            },
            "Kelaa Kebira": {
                "lat": 35.8667,
                "lng": 10.5333
            },
            "El Jem": {
                "lat": 35.2967,
                "lng": 10.7128
            },
            "Tebourba": {
                "lat": 36.8333,
                "lng": 9.8333
            },
            "Ksar Hellal": {
                "lat": 35.6429,
                "lng": 10.8911
            },
            "Douz": {
                "lat": 33.45,
                "lng": 9.0167
            },
            "Bizerte": {
                "lat": 37.2778,
                "lng": 9.8639
            },
            "Jendouba": {
                "lat": 36.5,
                "lng": 8.7833
            },
            "La Goulette": {
                "lat": 36.8181,
                "lng": 10.305
            },
            "Jede\u00efda": {
                "lat": 36.8333,
                "lng": 9.9167
            },
            "Soliman": {
                "lat": 36.7,
                "lng": 10.4833
            },
            "Hammam Sousse": {
                "lat": 35.8589,
                "lng": 10.5939
            },
            "Sbiba": {
                "lat": 35.5467,
                "lng": 9.0736
            },
            "Tabarka": {
                "lat": 36.9544,
                "lng": 8.7581
            },
            "Sejenane": {
                "lat": 37.0564,
                "lng": 9.2383
            },
            "Metlaoui": {
                "lat": 34.3333,
                "lng": 8.4
            },
            "Hammam-Lif": {
                "lat": 36.7333,
                "lng": 10.3333
            },
            "Teboulba": {
                "lat": 35.67,
                "lng": 10.9
            },
            "Tozeur": {
                "lat": 33.9167,
                "lng": 8.1333
            },
            "Beni Khiar": {
                "lat": 36.4667,
                "lng": 10.7833
            },
            "Dar Chabanne": {
                "lat": 36.47,
                "lng": 10.75
            },
            "A\u00efne Draham": {
                "lat": 36.7833,
                "lng": 8.7
            },
            "Bou Salem": {
                "lat": 36.6167,
                "lng": 8.9667
            },
            "Ez Zahra": {
                "lat": 36.7439,
                "lng": 10.3083
            },
            "Kalaa Srira": {
                "lat": 35.8236,
                "lng": 10.5583
            },
            "Skhira": {
                "lat": 34.3006,
                "lng": 10.0708
            },
            "Akouda": {
                "lat": 35.8714,
                "lng": 10.5697
            },
            "El Ksar": {
                "lat": 34.39,
                "lng": 8.8
            },
            "Mateur": {
                "lat": 37.04,
                "lng": 9.665
            },
            "Siliana": {
                "lat": 36.0819,
                "lng": 9.3747
            },
            "Rhennouch": {
                "lat": 33.93,
                "lng": 10.07
            },
            "Dahmani": {
                "lat": 35.95,
                "lng": 8.8333
            },
            "El Alia": {
                "lat": 37.1667,
                "lng": 10.0333
            },
            "Ar Rudayyif": {
                "lat": 34.3833,
                "lng": 8.15
            },
            "M\u00e9grine": {
                "lat": 36.7667,
                "lng": 10.2333
            },
            "Ksour Essaf": {
                "lat": 35.43,
                "lng": 10.98
            },
            "Zaouiet Sousse": {
                "lat": 35.7833,
                "lng": 10.6333
            },
            "Chebba": {
                "lat": 35.2372,
                "lng": 11.115
            },
            "Grombalia": {
                "lat": 36.6,
                "lng": 10.5
            },
            "Ajim": {
                "lat": 33.7167,
                "lng": 10.75
            },
            "Feriana": {
                "lat": 34.95,
                "lng": 8.5667
            },
            "El Maknassi": {
                "lat": 34.6042,
                "lng": 9.6056
            },
            "Beni Khalled": {
                "lat": 36.65,
                "lng": 10.6
            },
            "Medjez el Bab": {
                "lat": 36.6436,
                "lng": 9.6042
            },
            "Takelsa": {
                "lat": 36.7833,
                "lng": 10.6333
            },
            "Tabursuq": {
                "lat": 36.4572,
                "lng": 9.2483
            },
            "Ouardenine": {
                "lat": 35.72,
                "lng": 10.67
            },
            "Moular\u00e8s": {
                "lat": 34.4833,
                "lng": 8.2667
            },
            "Nefta": {
                "lat": 33.8833,
                "lng": 7.8833
            },
            "Carthage": {
                "lat": 36.8528,
                "lng": 10.3233
            },
            "Tinja": {
                "lat": 37.1667,
                "lng": 9.7667
            },
            "Zaghouan": {
                "lat": 36.4,
                "lng": 10.15
            },
            "Sbeitla": {
                "lat": 35.2297,
                "lng": 9.1294
            },
            "La Mornaghia": {
                "lat": 36.7667,
                "lng": 10.0167
            },
            "Rhar el Melah": {
                "lat": 37.1667,
                "lng": 10.1833
            },
            "Tajerouine": {
                "lat": 35.8833,
                "lng": 8.55
            },
            "Menzel Abderhaman": {
                "lat": 37.23,
                "lng": 9.86
            },
            "Sahline": {
                "lat": 35.7506,
                "lng": 10.7122
            },
            "Mennzel Bou Zelfa": {
                "lat": 36.6817,
                "lng": 10.5847
            },
            "Galaat el Andeless": {
                "lat": 37.0625,
                "lng": 10.1183
            },
            "Banbalah": {
                "lat": 35.7,
                "lng": 10.8
            },
            "Bekalta": {
                "lat": 35.6167,
                "lng": 11.0
            },
            "Mareth": {
                "lat": 33.6333,
                "lng": 10.3
            },
            "Ghomrassen": {
                "lat": 33.0592,
                "lng": 10.34
            },
            "Mahires": {
                "lat": 34.5333,
                "lng": 10.5
            },
            "Goubellat": {
                "lat": 36.5333,
                "lng": 9.6667
            },
            "Gammarth": {
                "lat": 36.9097,
                "lng": 10.2867
            },
            "Bennane": {
                "lat": 35.6833,
                "lng": 10.8333
            },
            "El Guetar": {
                "lat": 34.3372,
                "lng": 8.9528
            },
            "Thala": {
                "lat": 35.5667,
                "lng": 8.6667
            },
            "Hammam el Rhezez": {
                "lat": 36.89,
                "lng": 11.12
            },
            "Testour": {
                "lat": 36.55,
                "lng": 9.45
            },
            "Bou Arada": {
                "lat": 36.35,
                "lng": 9.6167
            },
            "Ksibet el Mediouni": {
                "lat": 35.69,
                "lng": 10.85
            },
            "Maktar": {
                "lat": 35.8606,
                "lng": 9.2058
            },
            "Messadine": {
                "lat": 35.7619,
                "lng": 10.5953
            },
            "M\u2019dhilla": {
                "lat": 34.25,
                "lng": 8.75
            },
            "Se\u00efada": {
                "lat": 35.67,
                "lng": 10.9
            },
            "Wadhraf": {
                "lat": 33.9833,
                "lng": 9.9667
            },
            "Bou Arkoub": {
                "lat": 36.54,
                "lng": 10.55
            },
            "As Sars": {
                "lat": 36.0833,
                "lng": 9.0167
            },
            "Zriba-Village": {
                "lat": 36.3333,
                "lng": 10.25
            },
            "Bou Merd\u00e8s": {
                "lat": 35.45,
                "lng": 10.7333
            },
            "Er Regueb": {
                "lat": 34.8667,
                "lng": 9.7833
            },
            "Sidi Tabet": {
                "lat": 36.9139,
                "lng": 10.0361
            },
            "Enfida": {
                "lat": 36.1353,
                "lng": 10.3808
            },
            "Rejiche": {
                "lat": 35.4667,
                "lng": 11.0333
            },
            "Ousseltia": {
                "lat": 35.84,
                "lng": 9.58
            },
            "Hajeb el A\u00efoun": {
                "lat": 35.39,
                "lng": 9.54
            },
            "Sidi Bou Ali": {
                "lat": 35.9561,
                "lng": 10.4722
            },
            "Gafour": {
                "lat": 36.34,
                "lng": 9.32
            },
            "Remada": {
                "lat": 32.3061,
                "lng": 10.3822
            },
            "Khunays": {
                "lat": 35.7122,
                "lng": 10.8167
            },
            "Jerissa": {
                "lat": 35.85,
                "lng": 8.6333
            },
            "El Haouaria": {
                "lat": 37.05,
                "lng": 11.0167
            },
            "Agareb": {
                "lat": 34.7414,
                "lng": 10.528
            },
            "Menzel Kamel": {
                "lat": 35.6333,
                "lng": 10.6667
            },
            "Tazarka": {
                "lat": 36.55,
                "lng": 10.8333
            },
            "Harqalah": {
                "lat": 36.0333,
                "lng": 10.5
            },
            "Beni Hassane": {
                "lat": 35.57,
                "lng": 10.82
            },
            "Dehiba": {
                "lat": 32.0167,
                "lng": 10.7
            }
        }
    },
    "XG": {
        "name": "Gaza Strip",
        "cities": {
            "Gaza": {
                "lat": 31.5069,
                "lng": 34.456
            },
            "Kh\u0101n Y\u016bnis": {
                "lat": 31.3444,
                "lng": 34.3031
            },
            "Jab\u0101ly\u0101": {
                "lat": 31.5281,
                "lng": 34.4831
            },
            "Rafa\u1e29": {
                "lat": 31.2725,
                "lng": 34.2586
            },
            "Bayt L\u0101hy\u0101": {
                "lat": 31.55,
                "lng": 34.5
            },
            "Dayr al Bala\u1e29": {
                "lat": 31.4189,
                "lng": 34.3517
            },
            "Ban\u012b Suhayl\u0101": {
                "lat": 31.3428,
                "lng": 34.3253
            },
            "\u2018Abas\u0101n al Kab\u012brah": {
                "lat": 31.3237,
                "lng": 34.3476
            },
            "Az Zuw\u0101ydah": {
                "lat": 31.4306,
                "lng": 34.3717
            }
        }
    },
    "JM": {
        "name": "Jamaica",
        "cities": {
            "Kingston": {
                "lat": 17.9714,
                "lng": -76.7931
            },
            "Portmore": {
                "lat": 17.95,
                "lng": -76.8799
            },
            "Spanish Town": {
                "lat": 17.9959,
                "lng": -76.9551
            },
            "Montego Bay": {
                "lat": 18.4667,
                "lng": -77.9167
            },
            "Half Way Tree": {
                "lat": 18.0106,
                "lng": -76.7847
            },
            "May Pen": {
                "lat": 17.965,
                "lng": -77.245
            },
            "Mandeville": {
                "lat": 18.0333,
                "lng": -77.5
            },
            "Old Harbour": {
                "lat": 17.9333,
                "lng": -77.1167
            },
            "Linstead": {
                "lat": 18.1368,
                "lng": -77.0317
            },
            "Savanna-la-Mar": {
                "lat": 18.2167,
                "lng": -78.1333
            },
            "Ocho Rios": {
                "lat": 18.4167,
                "lng": -77.1167
            },
            "Port Antonio": {
                "lat": 18.1757,
                "lng": -76.4503
            },
            "Bog Walk": {
                "lat": 18.102,
                "lng": -77.0054
            },
            "Ewarton": {
                "lat": 18.1833,
                "lng": -77.0833
            },
            "Saint Ann\u2019s Bay": {
                "lat": 18.436,
                "lng": -77.202
            },
            "Port Morant": {
                "lat": 17.9,
                "lng": -76.3167
            },
            "Morant Bay": {
                "lat": 17.8819,
                "lng": -76.4074
            },
            "Port Maria": {
                "lat": 18.3702,
                "lng": -76.8903
            },
            "Falmouth": {
                "lat": 18.49,
                "lng": -77.661
            },
            "Lucea": {
                "lat": 18.45,
                "lng": -78.1833
            },
            "Black River": {
                "lat": 18.0257,
                "lng": -77.8509
            }
        }
    },
    "MO": {
        "name": "Macau",
        "cities": {
            "Macau": {
                "lat": 22.2006,
                "lng": 113.5461
            }
        }
    },
    "MK": {
        "name": "North Macedonia",
        "cities": {
            "Skopje": {
                "lat": 41.9961,
                "lng": 21.4317
            },
            "Kumanovo": {
                "lat": 42.1322,
                "lng": 21.7144
            },
            "Bitola": {
                "lat": 41.0319,
                "lng": 21.3347
            },
            "Prilep": {
                "lat": 41.3464,
                "lng": 21.5542
            },
            "Tetovo": {
                "lat": 42.0103,
                "lng": 20.9714
            },
            "\u0160tip": {
                "lat": 41.7358,
                "lng": 22.1914
            },
            "Veles": {
                "lat": 41.7153,
                "lng": 21.7753
            },
            "Ohrid": {
                "lat": 41.1169,
                "lng": 20.8019
            },
            "Strumica": {
                "lat": 41.4375,
                "lng": 22.6431
            },
            "Gostivar": {
                "lat": 41.8,
                "lng": 20.9167
            },
            "Ko\u010dani": {
                "lat": 41.9167,
                "lng": 22.4125
            },
            "Dra\u010devo": {
                "lat": 41.9367,
                "lng": 21.5217
            },
            "Struga": {
                "lat": 41.1775,
                "lng": 20.6789
            },
            "Debar": {
                "lat": 41.525,
                "lng": 20.5272
            },
            "Kriva Palanka": {
                "lat": 42.2017,
                "lng": 22.3317
            },
            "Negotino": {
                "lat": 41.4839,
                "lng": 22.0892
            },
            "Sveti Nikole": {
                "lat": 41.865,
                "lng": 21.9425
            },
            "Probi\u0161tip": {
                "lat": 41.9936,
                "lng": 22.1767
            },
            "Del\u010devo": {
                "lat": 41.9661,
                "lng": 22.7747
            },
            "Vinica": {
                "lat": 41.8828,
                "lng": 22.5092
            },
            "Ara\u010dinovo": {
                "lat": 42.0264,
                "lng": 21.5617
            },
            "Ki\u010devo": {
                "lat": 41.5142,
                "lng": 20.9631
            },
            "Kavadarci": {
                "lat": 41.4328,
                "lng": 22.0117
            },
            "Berovo": {
                "lat": 41.7078,
                "lng": 22.8564
            },
            "Kratovo": {
                "lat": 42.0783,
                "lng": 22.175
            },
            "Gevgelija": {
                "lat": 41.1392,
                "lng": 22.5025
            },
            "Vrap\u010di\u0161te": {
                "lat": 41.8337,
                "lng": 20.8851
            },
            "Radovi\u0161": {
                "lat": 41.6381,
                "lng": 22.4644
            },
            "Kru\u0161evo": {
                "lat": 41.37,
                "lng": 21.2483
            },
            "Makedonski Brod": {
                "lat": 41.5133,
                "lng": 21.2153
            },
            "Demir Kapija": {
                "lat": 41.4114,
                "lng": 22.2422
            },
            "Tearce": {
                "lat": 42.0775,
                "lng": 21.0519
            },
            "Bogdanci": {
                "lat": 41.2031,
                "lng": 22.5728
            },
            "Rosoman": {
                "lat": 41.5161,
                "lng": 21.9497
            },
            "Demir Hisar": {
                "lat": 41.2208,
                "lng": 21.2031
            },
            "Vev\u010dani": {
                "lat": 41.2403,
                "lng": 20.5931
            },
            "Gradsko": {
                "lat": 41.5775,
                "lng": 21.9428
            },
            "Valandovo": {
                "lat": 41.3169,
                "lng": 22.5611
            },
            "Krivoga\u0161tani": {
                "lat": 41.3358,
                "lng": 21.3331
            },
            "Peh\u010devo": {
                "lat": 41.7592,
                "lng": 22.8906
            },
            "Plasnica": {
                "lat": 41.4667,
                "lng": 21.1167
            },
            "Zrnovci": {
                "lat": 41.8542,
                "lng": 22.4442
            },
            "Novaci": {
                "lat": 41.0419,
                "lng": 21.4561
            },
            "Bosilovo": {
                "lat": 41.4406,
                "lng": 22.7278
            },
            "Mogila": {
                "lat": 41.1083,
                "lng": 21.3786
            },
            "Novo Selo": {
                "lat": 41.4128,
                "lng": 22.88
            },
            "Kon\u010de": {
                "lat": 41.4958,
                "lng": 22.3825
            },
            "Rostu\u0161a": {
                "lat": 41.61,
                "lng": 20.6
            },
            "Jegunovce": {
                "lat": 42.0731,
                "lng": 21.1231
            },
            "Rankovce": {
                "lat": 42.1719,
                "lng": 22.1167
            },
            "Sopi\u0161te": {
                "lat": 41.95,
                "lng": 21.4333
            },
            "Oble\u0161evo": {
                "lat": 41.8833,
                "lng": 22.3339
            },
            "Vasilevo": {
                "lat": 41.4758,
                "lng": 22.6417
            },
            "Karbinci": {
                "lat": 41.8167,
                "lng": 22.2375
            },
            "Lozovo": {
                "lat": 41.7817,
                "lng": 21.9025
            },
            "Staro Nagori\u010dane": {
                "lat": 42.2,
                "lng": 21.83
            },
            "Brvenica": {
                "lat": 41.9672,
                "lng": 20.9808
            },
            "Centar \u017dupa": {
                "lat": 41.4775,
                "lng": 20.5578
            },
            "Dolneni": {
                "lat": 41.4264,
                "lng": 21.4536
            },
            "Bel\u010di\u0161ta": {
                "lat": 41.3028,
                "lng": 20.8303
            },
            "\u010cu\u010der-Sandevo": {
                "lat": 42.0975,
                "lng": 21.3877
            },
            "Zelenikovo": {
                "lat": 41.8867,
                "lng": 21.5869
            },
            "Petrovec": {
                "lat": 41.9389,
                "lng": 21.615
            },
            "Star Dojran": {
                "lat": 41.1865,
                "lng": 22.7203
            },
            "\u017delino": {
                "lat": 41.9794,
                "lng": 21.0619
            },
            "Studeni\u010dani": {
                "lat": 41.9158,
                "lng": 21.5306
            },
            "Bogovinje": {
                "lat": 41.9233,
                "lng": 20.9133
            },
            "Lipkovo": {
                "lat": 42.1553,
                "lng": 21.5875
            },
            "Ilinden": {
                "lat": 41.9945,
                "lng": 21.58
            },
            "Resen": {
                "lat": 41.0893,
                "lng": 21.0109
            },
            "Makedonska Kamenica": {
                "lat": 42.0208,
                "lng": 22.5876
            }
        }
    },
    "GW": {
        "name": "Guinea-Bissau",
        "cities": {
            "Bissau": {
                "lat": 11.85,
                "lng": -15.5667
            },
            "Farim": {
                "lat": 12.4833,
                "lng": -15.2167
            },
            "Quinh\u00e1mel": {
                "lat": 11.8833,
                "lng": -15.85
            },
            "Gab\u00fa": {
                "lat": 12.2833,
                "lng": -14.2167
            },
            "Bambadinca": {
                "lat": 12.0333,
                "lng": -14.8667
            },
            "Cati\u00f3": {
                "lat": 11.2833,
                "lng": -15.25
            },
            "Bafat\u00e1": {
                "lat": 12.1719,
                "lng": -14.6575
            },
            "Cai\u00f3": {
                "lat": 11.9278,
                "lng": -16.2028
            },
            "Madina do Bo\u00e9": {
                "lat": 11.75,
                "lng": -14.2167
            },
            "Bissor\u00e3": {
                "lat": 12.04,
                "lng": -15.4281
            },
            "Buba": {
                "lat": 11.5833,
                "lng": -15.0
            },
            "Cacheu": {
                "lat": 12.2667,
                "lng": -16.1667
            },
            "Bolama": {
                "lat": 11.5767,
                "lng": -15.4828
            },
            "Fulacunda": {
                "lat": 11.773,
                "lng": -15.195
            }
        }
    },
    "MT": {
        "name": "Malta",
        "cities": {
            "Valletta": {
                "lat": 35.8983,
                "lng": 14.5125
            },
            "Qormi": {
                "lat": 35.8794,
                "lng": 14.4722
            },
            "Birkirkara": {
                "lat": 35.8967,
                "lng": 14.4625
            },
            "Saint Paul\u2019s Bay": {
                "lat": 35.9483,
                "lng": 14.4017
            },
            "Mosta": {
                "lat": 35.9097,
                "lng": 14.4261
            },
            "Sliema": {
                "lat": 35.9122,
                "lng": 14.5042
            },
            "\u017babbar": {
                "lat": 35.8772,
                "lng": 14.5381
            },
            "Naxxar": {
                "lat": 35.915,
                "lng": 14.4447
            },
            "San \u0120wann": {
                "lat": 35.9094,
                "lng": 14.4786
            },
            "San \u0120iljan": {
                "lat": 35.9186,
                "lng": 14.49
            },
            "Marsaskala": {
                "lat": 35.8625,
                "lng": 14.5675
            },
            "\u017bebbu\u0121": {
                "lat": 36.0708,
                "lng": 14.2369
            },
            "Fgura": {
                "lat": 35.8725,
                "lng": 14.5228
            },
            "\u017bejtun": {
                "lat": 35.8556,
                "lng": 14.5333
            },
            "Rabat": {
                "lat": 35.8817,
                "lng": 14.3989
            },
            "Attard": {
                "lat": 35.8928,
                "lng": 14.4383
            },
            "\u017burrieq": {
                "lat": 35.8292,
                "lng": 14.4758
            },
            "Mellie\u0127a": {
                "lat": 35.9564,
                "lng": 14.3664
            },
            "Swieqi": {
                "lat": 35.9208,
                "lng": 14.48
            },
            "Bir\u017cebbu\u0121a": {
                "lat": 35.8256,
                "lng": 14.5281
            },
            "\u0126amrun": {
                "lat": 35.8861,
                "lng": 14.4894
            },
            "Si\u0121\u0121iewi": {
                "lat": 35.8542,
                "lng": 14.4383
            },
            "Paola": {
                "lat": 35.8728,
                "lng": 14.5075
            },
            "Tarxien": {
                "lat": 35.8658,
                "lng": 14.5119
            },
            "Imsida": {
                "lat": 35.8978,
                "lng": 14.4894
            },
            "G\u017cira": {
                "lat": 35.905,
                "lng": 14.4944
            },
            "Santa Venera": {
                "lat": 35.8897,
                "lng": 14.4778
            },
            "Victoria": {
                "lat": 36.0436,
                "lng": 14.2431
            },
            "Luqa": {
                "lat": 35.8597,
                "lng": 14.4892
            },
            "Cospicua": {
                "lat": 35.8822,
                "lng": 14.5222
            },
            "Xag\u0127ra": {
                "lat": 36.0503,
                "lng": 14.2675
            },
            "G\u0127axaq": {
                "lat": 35.8483,
                "lng": 14.5172
            },
            "Nadur": {
                "lat": 36.0381,
                "lng": 14.295
            },
            "Marsa": {
                "lat": 35.8833,
                "lng": 14.4947
            },
            "Piet\u00e0": {
                "lat": 35.8931,
                "lng": 14.4936
            },
            "Balzan": {
                "lat": 35.8981,
                "lng": 14.4533
            },
            "Pembroke": {
                "lat": 35.9264,
                "lng": 14.4808
            },
            "Im\u0121arr": {
                "lat": 35.9197,
                "lng": 14.3664
            },
            "Dingli": {
                "lat": 35.8603,
                "lng": 14.3814
            },
            "Marsaxlokk": {
                "lat": 35.8417,
                "lng": 14.5447
            },
            "Imqabba": {
                "lat": 35.8442,
                "lng": 14.4669
            },
            "Xewkija": {
                "lat": 36.0331,
                "lng": 14.2583
            },
            "G\u0127ajnsielem": {
                "lat": 36.0269,
                "lng": 14.2903
            },
            "Iklin": {
                "lat": 35.9042,
                "lng": 14.4544
            },
            "Lija": {
                "lat": 35.9014,
                "lng": 14.4472
            },
            "Kalkara": {
                "lat": 35.8892,
                "lng": 14.5294
            },
            "Gudja": {
                "lat": 35.8483,
                "lng": 14.5025
            },
            "Senglea": {
                "lat": 35.8878,
                "lng": 14.5169
            },
            "G\u0127arg\u0127ur": {
                "lat": 35.9241,
                "lng": 14.4534
            },
            "Qrendi": {
                "lat": 35.8342,
                "lng": 14.4589
            },
            "Vittoriosa": {
                "lat": 35.8881,
                "lng": 14.5225
            },
            "Imtarfa": {
                "lat": 35.8908,
                "lng": 14.3969
            },
            "Qala": {
                "lat": 36.0353,
                "lng": 14.3103
            },
            "Floriana": {
                "lat": 35.8933,
                "lng": 14.5058
            },
            "Kirkop": {
                "lat": 35.8419,
                "lng": 14.485
            },
            "Safi": {
                "lat": 35.8333,
                "lng": 14.485
            },
            "Sannat": {
                "lat": 36.0244,
                "lng": 14.2458
            },
            "Ker\u010bem": {
                "lat": 36.0406,
                "lng": 14.2297
            },
            "Ta\u2019 Xbiex": {
                "lat": 35.8992,
                "lng": 14.4981
            },
            "Xg\u0127ajra": {
                "lat": 35.8864,
                "lng": 14.5472
            },
            "G\u0127arb": {
                "lat": 36.0611,
                "lng": 14.2092
            },
            "Munxar": {
                "lat": 36.0303,
                "lng": 14.2353
            },
            "Fontana": {
                "lat": 36.0364,
                "lng": 14.2422
            },
            "San Lawrenz": {
                "lat": 36.055,
                "lng": 14.2042
            },
            "G\u0127asri": {
                "lat": 36.0583,
                "lng": 14.2278
            },
            "Imdina": {
                "lat": 35.8858,
                "lng": 14.4031
            },
            "Santa Lu\u010bija": {
                "lat": 36.0431,
                "lng": 14.2172
            }
        }
    },
    "PY": {
        "name": "Paraguay",
        "cities": {
            "Asunci\u00f3n": {
                "lat": -25.3,
                "lng": -57.6333
            },
            "Ciudad del Este": {
                "lat": -25.5167,
                "lng": -54.6167
            },
            "San Lorenzo": {
                "lat": -25.34,
                "lng": -57.52
            },
            "Luque": {
                "lat": -25.27,
                "lng": -57.4872
            },
            "Capiat\u00e1": {
                "lat": -25.35,
                "lng": -57.42
            },
            "Fernando de la Mora": {
                "lat": -25.32,
                "lng": -57.54
            },
            "\u00d1emby": {
                "lat": -25.3935,
                "lng": -57.5443
            },
            "Pedro Juan Caballero": {
                "lat": -22.53,
                "lng": -55.75
            },
            "Presidente Franco": {
                "lat": -25.5333,
                "lng": -54.6167
            },
            "Itaugu\u00e1": {
                "lat": -25.3833,
                "lng": -57.3333
            },
            "Mariano Roque Alonso": {
                "lat": -25.2122,
                "lng": -57.5331
            },
            "Limpio": {
                "lat": -25.1683,
                "lng": -57.4942
            },
            "It\u00e1": {
                "lat": -25.4833,
                "lng": -57.35
            },
            "Villa Elisa": {
                "lat": -25.5075,
                "lng": -57.5725
            },
            "San Antonio": {
                "lat": -25.3797,
                "lng": -57.6097
            },
            "Encarnaci\u00f3n": {
                "lat": -27.33,
                "lng": -55.88
            },
            "Aregu\u00e1": {
                "lat": -25.2953,
                "lng": -57.4147
            },
            "Ayolas": {
                "lat": -27.4,
                "lng": -56.9
            },
            "Villa Curuguaty": {
                "lat": -24.48,
                "lng": -55.71
            },
            "Horqueta": {
                "lat": -23.3442,
                "lng": -57.0436
            },
            "Villa Hayes": {
                "lat": -25.1,
                "lng": -57.5667
            },
            "Caacup\u00e9": {
                "lat": -25.3861,
                "lng": -57.1408
            },
            "Villarrica": {
                "lat": -25.75,
                "lng": -56.4333
            },
            "San Estanislao": {
                "lat": -24.65,
                "lng": -56.4333
            },
            "Coronel Oviedo": {
                "lat": -25.4167,
                "lng": -56.45
            },
            "San Ignacio": {
                "lat": -26.8867,
                "lng": -57.0283
            },
            "Concepci\u00f3n": {
                "lat": -23.4,
                "lng": -57.43
            },
            "Salto del Guair\u00e1": {
                "lat": -24.02,
                "lng": -54.34
            },
            "Villeta": {
                "lat": -25.51,
                "lng": -57.56
            },
            "San Pedro de Ycuamandiy\u00fa": {
                "lat": -24.1,
                "lng": -57.0833
            },
            "Ypan\u00e9": {
                "lat": -25.45,
                "lng": -57.53
            },
            "Yh\u00fa": {
                "lat": -25.06,
                "lng": -55.93
            },
            "Guarambar\u00e9": {
                "lat": -25.49,
                "lng": -57.46
            },
            "Carapegu\u00e1": {
                "lat": -25.769,
                "lng": -57.247
            },
            "Pilar": {
                "lat": -26.87,
                "lng": -58.3
            },
            "Yaguar\u00f3n": {
                "lat": -25.5667,
                "lng": -57.2833
            },
            "Yby Ya\u00fa": {
                "lat": -22.9631,
                "lng": -56.5358
            },
            "Ypacara\u00ed": {
                "lat": -25.3833,
                "lng": -57.2667
            },
            "Teniente Primero Manuel Irala Fern\u00e1ndez": {
                "lat": -22.814,
                "lng": -59.586
            },
            "San Juan Nepomuceno": {
                "lat": -26.1167,
                "lng": -55.93
            },
            "Caazap\u00e1": {
                "lat": -26.2,
                "lng": -56.38
            },
            "Tobat\u00ed": {
                "lat": -25.2667,
                "lng": -57.3167
            },
            "Paraguar\u00ed": {
                "lat": -25.6333,
                "lng": -57.15
            },
            "Arroyito": {
                "lat": -23.1733,
                "lng": -56.7747
            },
            "San Bernardino": {
                "lat": -25.3106,
                "lng": -57.2961
            },
            "Santa Rosa": {
                "lat": -26.8667,
                "lng": -56.85
            },
            "Loreto": {
                "lat": -23.2667,
                "lng": -57.3167
            },
            "Filadelfia": {
                "lat": -22.34,
                "lng": -60.03
            },
            "Tres de Mayo": {
                "lat": -26.4794,
                "lng": -56.0961
            },
            "Estancia Pozo Colorado": {
                "lat": -23.4136,
                "lng": -58.9144
            },
            "Piray\u00fa": {
                "lat": -25.48,
                "lng": -57.23
            },
            "San Juan Bautista": {
                "lat": -26.6333,
                "lng": -57.1667
            },
            "Loma Plata": {
                "lat": -22.3833,
                "lng": -59.8333
            },
            "Acahay": {
                "lat": -25.91,
                "lng": -57.11
            },
            "Benjam\u00edn Aceval": {
                "lat": -24.9703,
                "lng": -57.5667
            },
            "Quiindy": {
                "lat": -25.973,
                "lng": -57.237
            },
            "Atyr\u00e1": {
                "lat": -25.2786,
                "lng": -57.1706
            },
            "Mbuyapey": {
                "lat": -26.2,
                "lng": -56.75
            },
            "Doctor Juan Eulogio Estigarribia": {
                "lat": -25.37,
                "lng": -55.7
            },
            "Coronel Bogado": {
                "lat": -27.17,
                "lng": -56.25
            },
            "Altos": {
                "lat": -25.2333,
                "lng": -57.25
            },
            "Emboscada": {
                "lat": -25.1233,
                "lng": -57.3553
            },
            "Caraguatay": {
                "lat": -25.2333,
                "lng": -56.8167
            },
            "San L\u00e1zaro": {
                "lat": -22.1083,
                "lng": -57.97
            },
            "Bel\u00e9n": {
                "lat": -23.466,
                "lng": -57.262
            },
            "Capit\u00e1n Bado": {
                "lat": -23.26,
                "lng": -55.54
            },
            "Itacurub\u00ed de la Cordillera": {
                "lat": -25.45,
                "lng": -56.85
            },
            "Lima": {
                "lat": -23.9,
                "lng": -56.3333
            },
            "Repatriaci\u00f3n": {
                "lat": -25.53,
                "lng": -55.95
            },
            "Alberdi": {
                "lat": -26.19,
                "lng": -58.13
            },
            "Capit\u00e1n Mauricio Jos\u00e9 Troche": {
                "lat": -25.75,
                "lng": -56.4333
            },
            "Borja": {
                "lat": -25.9528,
                "lng": -56.4972
            },
            "Veinticinco de Diciembre": {
                "lat": -24.7,
                "lng": -56.55
            },
            "Francisco Caballero \u00c1lvarez": {
                "lat": -24.1543,
                "lng": -54.6659
            },
            "Katuet\u00e9": {
                "lat": -24.2481,
                "lng": -54.7575
            },
            "Bella Vista": {
                "lat": -22.1167,
                "lng": -56.5167
            },
            "Hohenau": {
                "lat": -27.0796,
                "lng": -55.75
            },
            "Villa del Rosario": {
                "lat": -24.4167,
                "lng": -57.1167
            },
            "Puerto Casado": {
                "lat": -22.2896,
                "lng": -57.94
            },
            "Fuerte Olimpo": {
                "lat": -21.0375,
                "lng": -57.8708
            },
            "Villa Ygatim\u00ed": {
                "lat": -24.08,
                "lng": -55.5
            },
            "Arroyos y Esteros": {
                "lat": -25.05,
                "lng": -57.09
            },
            "Aba\u00ed": {
                "lat": -26.0296,
                "lng": -55.94
            },
            "Mariscal Jos\u00e9 F\u00e9lix Estigarribia": {
                "lat": -22.0333,
                "lng": -60.6333
            },
            "Ypejh\u00fa": {
                "lat": -23.91,
                "lng": -55.46
            },
            "Pozo Colorado": {
                "lat": -23.43,
                "lng": -58.86
            },
            "Capit\u00e1n Pablo Lagerenza": {
                "lat": -19.92,
                "lng": -60.79
            },
            "\u00d1acunday": {
                "lat": -26.02,
                "lng": -54.77
            },
            "General Eugenio A. Garay": {
                "lat": -20.52,
                "lng": -62.21
            },
            "Puerto Pinasco": {
                "lat": -22.7167,
                "lng": -57.8333
            }
        }
    },
    "SK": {
        "name": "Slovakia",
        "cities": {
            "Bratislava": {
                "lat": 48.1439,
                "lng": 17.1097
            },
            "Ko\u0161ice": {
                "lat": 48.7167,
                "lng": 21.25
            },
            "Petr\u017ealka": {
                "lat": 48.1333,
                "lng": 17.1167
            },
            "\u017dilina": {
                "lat": 49.2228,
                "lng": 18.74
            },
            "Pre\u0161ov": {
                "lat": 49.0017,
                "lng": 21.2394
            },
            "Nitra": {
                "lat": 48.3069,
                "lng": 18.0864
            },
            "Bansk\u00e1 Bystrica": {
                "lat": 48.7353,
                "lng": 19.1453
            },
            "Trnava": {
                "lat": 48.3775,
                "lng": 17.5883
            },
            "Tren\u010d\u00edn": {
                "lat": 48.8919,
                "lng": 18.0367
            },
            "Martin": {
                "lat": 49.065,
                "lng": 18.9219
            },
            "Poprad": {
                "lat": 49.0594,
                "lng": 20.2975
            },
            "Prievidza": {
                "lat": 48.7711,
                "lng": 18.6217
            },
            "Zvolen": {
                "lat": 48.5706,
                "lng": 19.1175
            },
            "Pova\u017esk\u00e1 Bystrica": {
                "lat": 49.1139,
                "lng": 18.4417
            },
            "Nov\u00e9 Z\u00e1mky": {
                "lat": 47.9856,
                "lng": 18.1578
            },
            "Michalovce": {
                "lat": 48.7553,
                "lng": 21.9133
            },
            "Spi\u0161sk\u00e1 Nov\u00e1 Ves": {
                "lat": 48.9439,
                "lng": 20.5675
            },
            "Kom\u00e1rno": {
                "lat": 47.7633,
                "lng": 18.1283
            },
            "Levice": {
                "lat": 48.2164,
                "lng": 18.6081
            },
            "Humenn\u00e9": {
                "lat": 48.9358,
                "lng": 21.9067
            },
            "Liptovsk\u00fd Mikul\u00e1\u0161": {
                "lat": 49.0842,
                "lng": 19.6022
            },
            "Bardejov": {
                "lat": 49.2933,
                "lng": 21.2761
            },
            "Ru\u017eomberok": {
                "lat": 49.0786,
                "lng": 19.3083
            },
            "Pie\u0161\u0165any": {
                "lat": 48.5842,
                "lng": 17.8336
            },
            "Lu\u010denec": {
                "lat": 48.3286,
                "lng": 19.6692
            },
            "Topo\u013e\u010dany": {
                "lat": 48.5542,
                "lng": 18.1769
            },
            "Pezinok": {
                "lat": 48.2919,
                "lng": 17.2661
            },
            "\u010cadca": {
                "lat": 49.4358,
                "lng": 18.7922
            },
            "Dunajsk\u00e1 Streda": {
                "lat": 47.9944,
                "lng": 17.6194
            },
            "Trebi\u0161ov": {
                "lat": 48.6278,
                "lng": 21.7172
            },
            "Rimavsk\u00e1 Sobota": {
                "lat": 48.3811,
                "lng": 20.0144
            },
            "Dubnica nad V\u00e1hom": {
                "lat": 48.9606,
                "lng": 18.1739
            },
            "Partiz\u00e1nske": {
                "lat": 48.6258,
                "lng": 18.3728
            },
            "\u0160a\u013ea": {
                "lat": 48.1503,
                "lng": 17.8775
            },
            "Vranov nad Top\u013eou": {
                "lat": 48.8808,
                "lng": 21.6733
            },
            "Hlohovec": {
                "lat": 48.4333,
                "lng": 17.8033
            },
            "Senec": {
                "lat": 48.2189,
                "lng": 17.3997
            },
            "Brezno": {
                "lat": 48.8039,
                "lng": 19.6436
            },
            "Senica": {
                "lat": 48.6806,
                "lng": 17.3667
            },
            "Nov\u00e9 Mesto nad V\u00e1hom": {
                "lat": 48.7547,
                "lng": 17.8308
            },
            "Malacky": {
                "lat": 48.4358,
                "lng": 17.0178
            },
            "Snina": {
                "lat": 48.9881,
                "lng": 22.1567
            },
            "Doln\u00fd Kub\u00edn": {
                "lat": 49.2106,
                "lng": 19.2975
            },
            "\u017diar nad Hronom": {
                "lat": 48.5842,
                "lng": 18.8608
            },
            "Ro\u017e\u0148ava": {
                "lat": 48.6583,
                "lng": 20.5308
            },
            "P\u00fachov": {
                "lat": 49.12,
                "lng": 18.3306
            },
            "B\u00e1novce nad Bebravou": {
                "lat": 48.7186,
                "lng": 18.2581
            },
            "Handlov\u00e1": {
                "lat": 48.7272,
                "lng": 18.7619
            },
            "Star\u00e1 \u013dubov\u0148a": {
                "lat": 49.3094,
                "lng": 20.6789
            },
            "Sered\u2019": {
                "lat": 48.2886,
                "lng": 17.7314
            },
            "Ke\u017emarok": {
                "lat": 49.1336,
                "lng": 20.4264
            },
            "Skalica": {
                "lat": 48.8422,
                "lng": 17.2267
            },
            "Galanta": {
                "lat": 48.1889,
                "lng": 17.7264
            },
            "Kysuck\u00e9 Nov\u00e9 Mesto": {
                "lat": 49.2983,
                "lng": 18.7867
            },
            "Levo\u010da": {
                "lat": 49.0253,
                "lng": 20.5881
            },
            "Detva": {
                "lat": 48.5514,
                "lng": 19.4183
            },
            "\u0160amor\u00edn": {
                "lat": 48.0267,
                "lng": 17.3117
            },
            "Stupava": {
                "lat": 48.2747,
                "lng": 17.0317
            },
            "Sabinov": {
                "lat": 49.1061,
                "lng": 21.0856
            },
            "Zlat\u00e9 Moravce": {
                "lat": 48.3783,
                "lng": 18.3967
            },
            "Rev\u00faca": {
                "lat": 48.6831,
                "lng": 20.1133
            },
            "Byt\u010da": {
                "lat": 49.2242,
                "lng": 18.5642
            },
            "Hol\u00ed\u010d": {
                "lat": 48.8122,
                "lng": 17.1606
            },
            "Ve\u013ek\u00fd Krt\u00ed\u0161": {
                "lat": 48.215,
                "lng": 19.3381
            },
            "Myjava": {
                "lat": 48.7492,
                "lng": 17.5644
            },
            "Kol\u00e1rovo": {
                "lat": 47.9169,
                "lng": 17.9956
            },
            "Nov\u00e1 Dubnica": {
                "lat": 48.9331,
                "lng": 18.1439
            },
            "Moldava nad Bodvou": {
                "lat": 48.6064,
                "lng": 21.0003
            },
            "Svidn\u00edk": {
                "lat": 49.3056,
                "lng": 21.5678
            },
            "Stropkov": {
                "lat": 49.205,
                "lng": 21.6514
            },
            "Fi\u013eakovo": {
                "lat": 48.2683,
                "lng": 19.8247
            },
            "\u0160t\u00farovo": {
                "lat": 47.7992,
                "lng": 18.7181
            },
            "Bansk\u00e1 \u0160tiavnica": {
                "lat": 48.4581,
                "lng": 18.8964
            },
            "\u0160urany": {
                "lat": 48.0872,
                "lng": 18.1861
            },
            "Modra": {
                "lat": 48.3317,
                "lng": 17.3089
            },
            "Tvrdo\u0161\u00edn": {
                "lat": 49.3343,
                "lng": 19.5548
            },
            "Smi\u017eany": {
                "lat": 48.9556,
                "lng": 20.5292
            },
            "Bernol\u00e1kovo": {
                "lat": 48.2083,
                "lng": 17.2917
            },
            "Ve\u013ek\u00e9 Kapu\u0161any": {
                "lat": 48.5503,
                "lng": 22.0847
            },
            "Krompachy": {
                "lat": 48.9139,
                "lng": 20.8739
            },
            "Star\u00e1 Tur\u00e1": {
                "lat": 48.7767,
                "lng": 17.6961
            },
            "Vr\u00e1ble": {
                "lat": 48.2408,
                "lng": 18.3086
            },
            "Se\u010dovce": {
                "lat": 48.7025,
                "lng": 21.6567
            }
        }
    },
    "SS": {
        "name": "South Sudan",
        "cities": {
            "Juba": {
                "lat": 4.85,
                "lng": 31.6
            },
            "Bor": {
                "lat": 6.2125,
                "lng": 31.5608
            },
            "Kajo Kaji": {
                "lat": 3.8492,
                "lng": 31.6578
            },
            "Yei": {
                "lat": 4.0904,
                "lng": 30.68
            },
            "Magwe": {
                "lat": 4.1333,
                "lng": 32.3
            },
            "Wau": {
                "lat": 7.7,
                "lng": 28.0
            },
            "Maridi": {
                "lat": 4.91,
                "lng": 29.45
            },
            "Malakal": {
                "lat": 9.55,
                "lng": 31.65
            },
            "Leer": {
                "lat": 8.2979,
                "lng": 30.1475
            },
            "Kuacjok": {
                "lat": 8.31,
                "lng": 27.99
            },
            "Renk": {
                "lat": 11.83,
                "lng": 32.8
            },
            "Farajok": {
                "lat": 3.8724,
                "lng": 32.4792
            },
            "Yambio": {
                "lat": 4.565,
                "lng": 28.375
            },
            "Nimule": {
                "lat": 3.5961,
                "lng": 32.0636
            },
            "Gogrial": {
                "lat": 8.53,
                "lng": 28.1
            },
            "Aweil": {
                "lat": 8.78,
                "lng": 27.4
            },
            "Rumbek": {
                "lat": 6.806,
                "lng": 29.676
            },
            "Ikoto": {
                "lat": 4.0783,
                "lng": 33.1089
            },
            "Torit": {
                "lat": 4.4081,
                "lng": 32.575
            },
            "Tonj": {
                "lat": 7.28,
                "lng": 28.68
            },
            "Kafia Kingi": {
                "lat": 9.2731,
                "lng": 24.4174
            },
            "Yirol": {
                "lat": 6.56,
                "lng": 30.51
            },
            "Tambura": {
                "lat": 5.59,
                "lng": 27.47
            },
            "Bentiu": {
                "lat": 9.26,
                "lng": 29.8
            },
            "Kapoeta": {
                "lat": 4.775,
                "lng": 33.59
            },
            "Melut": {
                "lat": 10.4404,
                "lng": 32.2015
            },
            "Nasir": {
                "lat": 8.6,
                "lng": 33.0667
            }
        }
    },
    "CH": {
        "name": "Switzerland",
        "cities": {
            "Z\u00fcrich": {
                "lat": 47.3744,
                "lng": 8.5411
            },
            "Geneva": {
                "lat": 46.2017,
                "lng": 6.1469
            },
            "Basel": {
                "lat": 47.5547,
                "lng": 7.5906
            },
            "Lausanne": {
                "lat": 46.5198,
                "lng": 6.6335
            },
            "Bern": {
                "lat": 46.9481,
                "lng": 7.4475
            },
            "Winterthur": {
                "lat": 47.4989,
                "lng": 8.7286
            },
            "Lucerne": {
                "lat": 47.05,
                "lng": 8.3
            },
            "Sankt Gallen": {
                "lat": 47.4242,
                "lng": 9.3708
            },
            "Lugano": {
                "lat": 46.005,
                "lng": 8.9525
            },
            "Biel/Bienne": {
                "lat": 47.1333,
                "lng": 7.25
            },
            "Bellinzona": {
                "lat": 46.1954,
                "lng": 9.0297
            },
            "Thun": {
                "lat": 46.7667,
                "lng": 7.6333
            },
            "K\u00f6niz": {
                "lat": 46.9333,
                "lng": 7.4
            },
            "La Chaux-de-Fonds": {
                "lat": 47.1013,
                "lng": 6.825
            },
            "Fribourg": {
                "lat": 46.8,
                "lng": 7.15
            },
            "Uster": {
                "lat": 47.35,
                "lng": 8.7167
            },
            "Schaffhausen": {
                "lat": 47.7,
                "lng": 8.6333
            },
            "Chur": {
                "lat": 46.85,
                "lng": 9.5333
            },
            "Vernier": {
                "lat": 46.2,
                "lng": 6.1
            },
            "Sion": {
                "lat": 46.2311,
                "lng": 7.3594
            },
            "Neuch\u00e2tel": {
                "lat": 47.0,
                "lng": 6.9333
            },
            "Landecy": {
                "lat": 46.1834,
                "lng": 6.1166
            },
            "Zug": {
                "lat": 47.1681,
                "lng": 8.5169
            },
            "Yverdon-les-Bains": {
                "lat": 46.7785,
                "lng": 6.6408
            },
            "Emmen": {
                "lat": 47.0833,
                "lng": 8.3
            },
            "D\u00fcbendorf": {
                "lat": 47.4167,
                "lng": 8.6167
            },
            "Kriens": {
                "lat": 47.0333,
                "lng": 8.2833
            },
            "Rapperswil-Jona": {
                "lat": 47.2167,
                "lng": 8.8167
            },
            "Dietikon": {
                "lat": 47.4,
                "lng": 8.4
            },
            "Montreux": {
                "lat": 46.435,
                "lng": 6.9125
            },
            "Baar": {
                "lat": 47.2,
                "lng": 8.5167
            },
            "Wetzikon": {
                "lat": 47.3167,
                "lng": 8.8
            },
            "Meyrin": {
                "lat": 46.2167,
                "lng": 6.0667
            },
            "Wil": {
                "lat": 47.4667,
                "lng": 9.05
            },
            "Horgen": {
                "lat": 47.2608,
                "lng": 8.5975
            },
            "Bulle": {
                "lat": 46.6167,
                "lng": 7.05
            },
            "Carouge": {
                "lat": 46.1833,
                "lng": 6.1333
            },
            "Frauenfeld": {
                "lat": 47.55,
                "lng": 8.9
            },
            "Kreuzlingen": {
                "lat": 47.6333,
                "lng": 9.1667
            },
            "Oberwingert": {
                "lat": 47.517,
                "lng": 8.769
            },
            "W\u00e4denswil": {
                "lat": 47.2333,
                "lng": 8.6667
            },
            "Aarau": {
                "lat": 47.4,
                "lng": 8.05
            },
            "Riehen": {
                "lat": 47.5833,
                "lng": 7.6333
            },
            "Allschwil": {
                "lat": 47.55,
                "lng": 7.5333
            },
            "Renens": {
                "lat": 46.5333,
                "lng": 6.5833
            },
            "Wettingen": {
                "lat": 47.4667,
                "lng": 8.3333
            },
            "Nyon": {
                "lat": 46.3833,
                "lng": 6.2333
            },
            "B\u00fclach": {
                "lat": 47.5167,
                "lng": 8.5333
            },
            "Vevey": {
                "lat": 46.4667,
                "lng": 6.85
            },
            "Opfikon": {
                "lat": 47.4333,
                "lng": 8.5667
            },
            "Kloten": {
                "lat": 47.45,
                "lng": 8.5833
            },
            "Reinach": {
                "lat": 47.4833,
                "lng": 7.5833
            },
            "Baden": {
                "lat": 47.4667,
                "lng": 8.3
            },
            "Onex": {
                "lat": 46.1833,
                "lng": 6.1
            },
            "Adliswil": {
                "lat": 47.3167,
                "lng": 8.5333
            },
            "Schlieren": {
                "lat": 47.4,
                "lng": 8.45
            },
            "Volketswil": {
                "lat": 47.3833,
                "lng": 8.7
            },
            "Thalwil": {
                "lat": 47.2833,
                "lng": 8.5667
            },
            "Olten": {
                "lat": 47.35,
                "lng": 7.9
            },
            "Pully": {
                "lat": 46.5167,
                "lng": 6.6667
            },
            "Regensdorf": {
                "lat": 47.4333,
                "lng": 8.4667
            },
            "Gossau": {
                "lat": 47.3167,
                "lng": 8.75
            },
            "Muttenz": {
                "lat": 47.5167,
                "lng": 7.65
            },
            "Monthey": {
                "lat": 46.25,
                "lng": 6.95
            },
            "Ostermundigen": {
                "lat": 46.955,
                "lng": 7.4894
            },
            "Stegen": {
                "lat": 47.476,
                "lng": 8.77
            },
            "Littau": {
                "lat": 47.05,
                "lng": 8.2667
            },
            "Martigny": {
                "lat": 46.1,
                "lng": 7.0667
            },
            "Grenchen": {
                "lat": 47.1906,
                "lng": 7.3964
            },
            "Sierre": {
                "lat": 46.3,
                "lng": 7.5333
            },
            "Solothurn": {
                "lat": 47.2167,
                "lng": 7.5333
            },
            "Pratteln": {
                "lat": 47.5167,
                "lng": 7.7
            },
            "Burgdorf": {
                "lat": 47.05,
                "lng": 7.6167
            },
            "Freienbach": {
                "lat": 47.2,
                "lng": 8.75
            },
            "Wallisellen": {
                "lat": 47.4167,
                "lng": 8.6
            },
            "Wohlen": {
                "lat": 47.35,
                "lng": 8.2833
            },
            "Locarno": {
                "lat": 46.1667,
                "lng": 8.8
            },
            "Herisau": {
                "lat": 47.3833,
                "lng": 9.2667
            },
            "Steffisburg": {
                "lat": 46.7667,
                "lng": 7.6333
            },
            "Morges": {
                "lat": 46.5167,
                "lng": 6.5
            },
            "Binningen": {
                "lat": 47.5333,
                "lng": 7.5667
            },
            "Langenthal": {
                "lat": 47.2167,
                "lng": 7.7833
            },
            "Einsiedeln": {
                "lat": 47.1167,
                "lng": 8.7333
            },
            "Lyss": {
                "lat": 47.0667,
                "lng": 7.3
            },
            "Schwyz": {
                "lat": 47.0167,
                "lng": 8.65
            },
            "W\u00fclflingen": {
                "lat": 47.51,
                "lng": 8.683
            },
            "Mendrisio": {
                "lat": 45.8667,
                "lng": 8.9833
            },
            "Arbon": {
                "lat": 47.5167,
                "lng": 9.4333
            },
            "Liestal": {
                "lat": 47.4667,
                "lng": 7.7333
            },
            "St\u00e4fa": {
                "lat": 47.24,
                "lng": 8.73
            },
            "K\u00fcsnacht": {
                "lat": 47.3167,
                "lng": 8.5833
            },
            "Horw": {
                "lat": 47.0167,
                "lng": 8.3167
            },
            "Th\u00f4nex": {
                "lat": 46.1833,
                "lng": 6.1833
            },
            "Meilen": {
                "lat": 47.2667,
                "lng": 8.6333
            },
            "Oftringen": {
                "lat": 47.3167,
                "lng": 7.9167
            },
            "Amriswil": {
                "lat": 47.55,
                "lng": 9.3
            },
            "Rheinfelden": {
                "lat": 47.55,
                "lng": 7.8
            },
            "Versoix": {
                "lat": 46.2833,
                "lng": 6.1667
            },
            "Richterswil": {
                "lat": 47.2167,
                "lng": 8.7
            },
            "Zollikon": {
                "lat": 47.3333,
                "lng": 8.5833
            },
            "Brig-Glis": {
                "lat": 46.3167,
                "lng": 7.9667
            },
            "Gland": {
                "lat": 46.42,
                "lng": 6.27
            },
            "K\u00fcssnacht": {
                "lat": 47.0667,
                "lng": 8.4333
            },
            "Muri": {
                "lat": 46.9333,
                "lng": 7.4833
            },
            "Uzwil": {
                "lat": 47.4369,
                "lng": 9.1333
            },
            "Ecublens": {
                "lat": 46.5333,
                "lng": 6.5667
            },
            "Spiez": {
                "lat": 46.6833,
                "lng": 7.6667
            },
            "Del\u00e9mont": {
                "lat": 47.3667,
                "lng": 7.35
            },
            "Buchs": {
                "lat": 47.1667,
                "lng": 9.4667
            },
            "Prilly": {
                "lat": 46.5333,
                "lng": 6.6
            },
            "Affoltern am Albis": {
                "lat": 47.2833,
                "lng": 8.45
            },
            "Ch\u00eane-Bougeries": {
                "lat": 46.1833,
                "lng": 6.1833
            },
            "R\u00fcti": {
                "lat": 47.2667,
                "lng": 8.85
            },
            "Le Grand-Saconnex": {
                "lat": 46.2333,
                "lng": 6.1167
            },
            "Mattenhof": {
                "lat": 47.487,
                "lng": 8.747
            },
            "M\u00fcnchenstein": {
                "lat": 47.5167,
                "lng": 7.6167
            },
            "Villars-sur-Gl\u00e2ne": {
                "lat": 46.7833,
                "lng": 7.1167
            },
            "Arth": {
                "lat": 47.0633,
                "lng": 8.5218
            },
            "Pf\u00e4ffikon": {
                "lat": 47.3667,
                "lng": 8.7833
            },
            "La Tour-de-Peilz": {
                "lat": 46.45,
                "lng": 6.8667
            },
            "Spreitenbach": {
                "lat": 47.4167,
                "lng": 8.3667
            },
            "Altst\u00e4tten": {
                "lat": 47.3833,
                "lng": 9.55
            },
            "Waltikon": {
                "lat": 47.3667,
                "lng": 8.5833
            },
            "Zofingen": {
                "lat": 47.2833,
                "lng": 7.9333
            },
            "Veyrier": {
                "lat": 46.1667,
                "lng": 6.1833
            },
            "Bassersdorf": {
                "lat": 47.45,
                "lng": 8.6333
            },
            "Weinfelden": {
                "lat": 47.5667,
                "lng": 9.1
            },
            "Belp": {
                "lat": 46.8833,
                "lng": 7.5
            },
            "Worb": {
                "lat": 46.9333,
                "lng": 7.5667
            },
            "M\u00e4nnedorf": {
                "lat": 47.25,
                "lng": 8.6833
            },
            "Hinwil": {
                "lat": 47.3,
                "lng": 8.85
            },
            "Romanshorn": {
                "lat": 47.5667,
                "lng": 9.3833
            },
            "Oberwil": {
                "lat": 47.5167,
                "lng": 7.55
            },
            "Brugg": {
                "lat": 47.4864,
                "lng": 8.2067
            },
            "M\u00f6hlin": {
                "lat": 47.55,
                "lng": 7.85
            },
            "Davos": {
                "lat": 46.8,
                "lng": 9.8333
            },
            "Risch": {
                "lat": 47.1333,
                "lng": 8.4667
            },
            "Plan-les-Ouates": {
                "lat": 46.1667,
                "lng": 6.1167
            },
            "Lenzburg": {
                "lat": 47.3833,
                "lng": 8.1833
            },
            "Flawil": {
                "lat": 47.4167,
                "lng": 9.2
            },
            "Neuhausen am Rheinfall": {
                "lat": 47.6833,
                "lng": 8.6167
            },
            "Le Locle": {
                "lat": 47.0532,
                "lng": 6.7482
            },
            "Suhr": {
                "lat": 47.3667,
                "lng": 8.0833
            },
            "Sarnen": {
                "lat": 46.8961,
                "lng": 8.2456
            },
            "Aesch": {
                "lat": 47.4667,
                "lng": 7.5833
            },
            "Birsfelden": {
                "lat": 47.5533,
                "lng": 7.6233
            },
            "Lutry": {
                "lat": 46.5,
                "lng": 6.6833
            },
            "Bernex": {
                "lat": 46.1667,
                "lng": 6.0667
            },
            "Maur": {
                "lat": 47.35,
                "lng": 8.6667
            },
            "Aigle": {
                "lat": 46.3167,
                "lng": 6.9667
            },
            "Naters": {
                "lat": 46.3167,
                "lng": 7.9833
            },
            "Steinhaus": {
                "lat": 47.2,
                "lng": 8.4833
            },
            "Payerne": {
                "lat": 46.8167,
                "lng": 6.9333
            },
            "Sursee": {
                "lat": 47.1667,
                "lng": 8.1167
            },
            "Therwil": {
                "lat": 47.5,
                "lng": 7.55
            },
            "Hochdorf": {
                "lat": 47.1667,
                "lng": 8.2833
            },
            "Urdorf": {
                "lat": 47.3833,
                "lng": 8.4333
            },
            "Wald": {
                "lat": 47.2833,
                "lng": 8.9167
            },
            "Estavayer-le-Lac": {
                "lat": 46.85,
                "lng": 6.8472
            },
            "Wittenbach": {
                "lat": 47.4667,
                "lng": 9.3667
            },
            "Widnau": {
                "lat": 47.4,
                "lng": 9.6333
            },
            "Epalinges": {
                "lat": 46.55,
                "lng": 6.6667
            },
            "Eschenbach": {
                "lat": 47.2333,
                "lng": 8.9167
            },
            "Rorschach": {
                "lat": 47.4667,
                "lng": 9.5
            },
            "Embrach": {
                "lat": 47.5104,
                "lng": 8.5938
            },
            "Altdorf": {
                "lat": 46.8667,
                "lng": 8.6333
            },
            "Langnau": {
                "lat": 46.95,
                "lng": 7.7833
            },
            "Goldach": {
                "lat": 47.4833,
                "lng": 9.4667
            },
            "Niederhasli": {
                "lat": 47.4833,
                "lng": 8.4833
            },
            "Sch\u00fcbelbach": {
                "lat": 47.1667,
                "lng": 8.9333
            },
            "Mels": {
                "lat": 47.05,
                "lng": 9.4167
            },
            "Arlesheim": {
                "lat": 47.4833,
                "lng": 7.6167
            },
            "Rothrist": {
                "lat": 47.3,
                "lng": 7.8833
            },
            "Kirchberg": {
                "lat": 47.4,
                "lng": 9.0333
            },
            "Aadorf": {
                "lat": 47.5,
                "lng": 8.9
            },
            "Ingenbohl": {
                "lat": 46.9833,
                "lng": 8.6
            },
            "Oberriet": {
                "lat": 47.3167,
                "lng": 9.5667
            },
            "Zuchwil": {
                "lat": 47.2,
                "lng": 7.5667
            },
            "Unter\u00e4geri": {
                "lat": 47.1333,
                "lng": 8.5833
            },
            "Kilchberg": {
                "lat": 47.3167,
                "lng": 8.55
            },
            "Landquart": {
                "lat": 46.9688,
                "lng": 9.5503
            },
            "Neuenhof": {
                "lat": 47.45,
                "lng": 8.3167
            },
            "H\u00fcnenberg": {
                "lat": 47.1833,
                "lng": 8.4333
            },
            "Fully": {
                "lat": 46.1333,
                "lng": 7.1167
            },
            "Hombrechtikon": {
                "lat": 47.25,
                "lng": 8.7667
            },
            "Lachen": {
                "lat": 47.1833,
                "lng": 8.85
            },
            "Wattwil": {
                "lat": 47.3,
                "lng": 9.0833
            },
            "Bussy": {
                "lat": 46.55,
                "lng": 6.55
            },
            "Conthey": {
                "lat": 46.2167,
                "lng": 7.3
            },
            "Bremgarten": {
                "lat": 47.35,
                "lng": 8.3333
            },
            "Egg": {
                "lat": 47.3019,
                "lng": 8.6906
            },
            "F\u00e4llanden": {
                "lat": 47.3667,
                "lng": 8.6333
            },
            "Biberist": {
                "lat": 47.1833,
                "lng": 7.5667
            },
            "Obersiggenthal": {
                "lat": 47.4833,
                "lng": 8.3
            },
            "Le Mont-sur-Lausanne": {
                "lat": 46.5217,
                "lng": 6.6269
            },
            "Stans": {
                "lat": 46.95,
                "lng": 8.35
            },
            "Glarus": {
                "lat": 47.0333,
                "lng": 9.0667
            },
            "Appenzell": {
                "lat": 47.3333,
                "lng": 9.4167
            }
        }
    },
    "NaN": {
        "name": "Namibia",
        "cities": {
            "Windhoek": {
                "lat": -22.57,
                "lng": 17.0836
            },
            "Walvisbaai": {
                "lat": -22.9561,
                "lng": 14.5081
            },
            "Rundu": {
                "lat": -17.9167,
                "lng": 19.7667
            },
            "Oshakati": {
                "lat": -17.7833,
                "lng": 15.6995
            },
            "Swakopmund": {
                "lat": -22.6833,
                "lng": 14.5333
            },
            "Rehoboth": {
                "lat": -23.3167,
                "lng": 17.0833
            },
            "Katima Mulilo": {
                "lat": -17.5039,
                "lng": 24.275
            },
            "Grootfontein": {
                "lat": -19.5658,
                "lng": 18.1036
            },
            "Ondangwa": {
                "lat": -17.9167,
                "lng": 15.95
            },
            "Okahandja": {
                "lat": -21.9833,
                "lng": 16.9167
            },
            "Opuwo": {
                "lat": -18.0556,
                "lng": 13.8406
            },
            "Otjiwarongo": {
                "lat": -20.4642,
                "lng": 16.6528
            },
            "Tsumeb": {
                "lat": -19.25,
                "lng": 17.7
            },
            "Gobabis": {
                "lat": -22.4333,
                "lng": 18.9667
            },
            "Keetmanshoop": {
                "lat": -26.5786,
                "lng": 18.1333
            },
            "L\u00fcderitz": {
                "lat": -26.6458,
                "lng": 15.1539
            },
            "Mariental": {
                "lat": -24.6333,
                "lng": 17.9667
            },
            "Oshikango": {
                "lat": -17.4,
                "lng": 15.8833
            },
            "Omatjete": {
                "lat": -21.05,
                "lng": 15.5
            },
            "Ongwediva": {
                "lat": -17.7833,
                "lng": 15.7667
            },
            "Outjo": {
                "lat": -20.1089,
                "lng": 16.1547
            },
            "Omaruru": {
                "lat": -21.4333,
                "lng": 15.9333
            },
            "Maltah\u00f6he": {
                "lat": -24.8333,
                "lng": 16.9833
            },
            "Eenhana": {
                "lat": -17.4658,
                "lng": 16.3369
            },
            "Otavi": {
                "lat": -19.65,
                "lng": 17.3333
            },
            "Karibib": {
                "lat": -21.9381,
                "lng": 15.8544
            },
            "Outapi": {
                "lat": -17.5167,
                "lng": 15.0
            },
            "Karasburg": {
                "lat": -28.0167,
                "lng": 18.75
            },
            "Oranjemund": {
                "lat": -28.5517,
                "lng": 16.4264
            },
            "Bethanie": {
                "lat": -26.4833,
                "lng": 17.15
            },
            "Omuthiya": {
                "lat": -18.3606,
                "lng": 16.5812
            },
            "Nkurenkuru": {
                "lat": -17.6167,
                "lng": 18.6
            },
            "Usakos": {
                "lat": -21.9995,
                "lng": 15.58
            }
        }
    },
    "BA": {
        "name": "Bosnia and Herzegovina",
        "cities": {
            "Sarajevo": {
                "lat": 43.8564,
                "lng": 18.4131
            },
            "Banja Luka": {
                "lat": 44.7725,
                "lng": 17.1925
            },
            "Bijeljina": {
                "lat": 44.7569,
                "lng": 19.2161
            },
            "Mostar": {
                "lat": 43.3436,
                "lng": 17.8075
            },
            "Tuzla": {
                "lat": 44.5381,
                "lng": 18.6761
            },
            "Prijedor": {
                "lat": 44.9808,
                "lng": 16.7133
            },
            "Zenica": {
                "lat": 44.2039,
                "lng": 17.9078
            },
            "Doboj": {
                "lat": 44.7314,
                "lng": 18.0844
            },
            "Ilid\u017ea": {
                "lat": 43.8167,
                "lng": 18.3
            },
            "Cazin": {
                "lat": 44.9667,
                "lng": 15.9333
            },
            "Zvornik": {
                "lat": 44.3842,
                "lng": 19.1025
            },
            "\u017divinice": {
                "lat": 44.45,
                "lng": 18.65
            },
            "Biha\u0107": {
                "lat": 44.8167,
                "lng": 15.8667
            },
            "Travnik": {
                "lat": 44.2264,
                "lng": 17.6597
            },
            "Gradi\u0161ka": {
                "lat": 45.1458,
                "lng": 17.2539
            },
            "Sanski Most": {
                "lat": 44.7667,
                "lng": 16.6667
            },
            "Lukavac": {
                "lat": 44.55,
                "lng": 18.5167
            },
            "Gra\u010danica": {
                "lat": 44.6892,
                "lng": 18.3022
            },
            "Te\u0161anj": {
                "lat": 44.6142,
                "lng": 17.9894
            },
            "Visoko": {
                "lat": 43.9833,
                "lng": 18.1667
            },
            "Br\u010dko": {
                "lat": 44.8772,
                "lng": 18.8111
            },
            "Srebrenik": {
                "lat": 44.7,
                "lng": 18.4833
            },
            "Grada\u010dac": {
                "lat": 44.8789,
                "lng": 18.4258
            },
            "Donji Kakanj": {
                "lat": 44.1311,
                "lng": 18.0972
            },
            "Stara Gora": {
                "lat": 43.8667,
                "lng": 18.4333
            },
            "Maglaj": {
                "lat": 44.55,
                "lng": 18.1
            },
            "Bugojno": {
                "lat": 44.05,
                "lng": 17.45
            },
            "\u017dep\u010de": {
                "lat": 44.4333,
                "lng": 18.0333
            },
            "Trebinje": {
                "lat": 42.7119,
                "lng": 18.3461
            },
            "\u0160iroki Brijeg": {
                "lat": 43.3831,
                "lng": 17.5927
            },
            "Ljubu\u0161ki": {
                "lat": 43.1981,
                "lng": 17.5467
            },
            "\u010capljina": {
                "lat": 43.1118,
                "lng": 17.7055
            },
            "Novi Grad": {
                "lat": 45.0481,
                "lng": 16.3769
            },
            "Vogo\u0161\u0107a": {
                "lat": 43.9,
                "lng": 18.35
            },
            "Vitez": {
                "lat": 44.1585,
                "lng": 17.7885
            },
            "Novi Travnik": {
                "lat": 44.1748,
                "lng": 17.6634
            },
            "Had\u017ei\u0107i": {
                "lat": 43.8217,
                "lng": 18.2017
            },
            "Gornji Vakuf": {
                "lat": 43.9333,
                "lng": 17.5833
            },
            "Pale": {
                "lat": 43.8119,
                "lng": 18.5711
            },
            "Kiseljak": {
                "lat": 43.9431,
                "lng": 18.0775
            },
            "Posu\u0161je": {
                "lat": 43.472,
                "lng": 17.3297
            },
            "Bratunac": {
                "lat": 44.1839,
                "lng": 19.3308
            },
            "Ora\u0161je": {
                "lat": 45.0361,
                "lng": 18.6933
            },
            "Ilija\u0161": {
                "lat": 43.95,
                "lng": 18.2667
            },
            "Od\u017eak": {
                "lat": 45.0106,
                "lng": 18.3264
            },
            "Klju\u010d": {
                "lat": 44.5333,
                "lng": 16.7667
            },
            "Busova\u010da": {
                "lat": 44.1,
                "lng": 17.8833
            },
            "\u010citluk": {
                "lat": 43.2,
                "lng": 17.7
            },
            "Srbac": {
                "lat": 45.0958,
                "lng": 17.5192
            },
            "Banovi\u0107i": {
                "lat": 44.4,
                "lng": 18.5333
            },
            "Brod": {
                "lat": 45.1333,
                "lng": 17.9833
            },
            "Lopare": {
                "lat": 44.6361,
                "lng": 18.8444
            },
            "Stolac": {
                "lat": 43.0825,
                "lng": 17.9558
            },
            "Breza": {
                "lat": 44.0167,
                "lng": 18.2611
            },
            "Donji Vakuf": {
                "lat": 44.15,
                "lng": 17.4
            },
            "Srebrenica": {
                "lat": 44.1042,
                "lng": 19.2972
            },
            "Derventa": {
                "lat": 44.9775,
                "lng": 17.9075
            },
            "Gora\u017ede": {
                "lat": 43.6667,
                "lng": 18.9833
            },
            "Fojnica": {
                "lat": 43.9667,
                "lng": 17.9
            },
            "Kladanj": {
                "lat": 44.2256,
                "lng": 18.6925
            },
            "Fo\u010da": {
                "lat": 43.5064,
                "lng": 18.7747
            },
            "Sapna": {
                "lat": 44.4917,
                "lng": 19.0028
            },
            "Jajce": {
                "lat": 44.3417,
                "lng": 17.2694
            },
            "Vlasenica": {
                "lat": 44.1833,
                "lng": 18.9333
            },
            "Mili\u0107i": {
                "lat": 44.1661,
                "lng": 19.075
            },
            "Bile\u0107a": {
                "lat": 42.8667,
                "lng": 18.4333
            },
            "Rogatica": {
                "lat": 43.7986,
                "lng": 19.0036
            },
            "Jablanica": {
                "lat": 43.65,
                "lng": 17.75
            },
            "\u010celi\u0107": {
                "lat": 44.7167,
                "lng": 18.8167
            },
            "Bosanska Krupa": {
                "lat": 44.8833,
                "lng": 16.15
            },
            "Olovo": {
                "lat": 44.1275,
                "lng": 18.58
            },
            "Vare\u0161": {
                "lat": 44.1619,
                "lng": 18.3269
            },
            "Livno": {
                "lat": 43.8253,
                "lng": 17.0058
            },
            "Gacko": {
                "lat": 43.1667,
                "lng": 18.5333
            }
        }
    },
    "AL": {
        "name": "Albania",
        "cities": {
            "Tirana": {
                "lat": 41.3289,
                "lng": 19.8178
            },
            "Durr\u00ebs": {
                "lat": 41.3133,
                "lng": 19.4458
            },
            "Vlor\u00eb": {
                "lat": 40.4667,
                "lng": 19.4833
            },
            "Kam\u00ebz": {
                "lat": 41.3833,
                "lng": 19.7667
            },
            "Fier": {
                "lat": 40.725,
                "lng": 19.5572
            },
            "Shkod\u00ebr": {
                "lat": 42.0681,
                "lng": 19.5119
            },
            "Elbasan": {
                "lat": 41.1111,
                "lng": 20.0806
            },
            "Kor\u00e7\u00eb": {
                "lat": 40.6167,
                "lng": 20.7667
            },
            "Sarand\u00eb": {
                "lat": 39.875,
                "lng": 20.01
            },
            "Berat": {
                "lat": 40.7022,
                "lng": 19.9583
            },
            "Lushnj\u00eb": {
                "lat": 40.9333,
                "lng": 19.7
            },
            "Kavaj\u00eb": {
                "lat": 41.1842,
                "lng": 19.5619
            },
            "Gjirokast\u00ebr": {
                "lat": 40.0667,
                "lng": 20.1333
            },
            "Pogradec": {
                "lat": 40.9,
                "lng": 20.65
            },
            "Fush\u00eb-Kruj\u00eb": {
                "lat": 41.4833,
                "lng": 19.7167
            },
            "La\u00e7": {
                "lat": 41.6353,
                "lng": 19.7131
            },
            "Kuk\u00ebs": {
                "lat": 42.0833,
                "lng": 20.4167
            },
            "Sukth": {
                "lat": 41.3833,
                "lng": 19.5333
            },
            "Bu\u00e7imas": {
                "lat": 40.8917,
                "lng": 20.6806
            },
            "Lezh\u00eb": {
                "lat": 41.7819,
                "lng": 19.6444
            },
            "Patos": {
                "lat": 40.6833,
                "lng": 19.6167
            },
            "Peshkopi": {
                "lat": 41.6833,
                "lng": 20.4281
            },
            "Librazhd-Qend\u00ebr": {
                "lat": 41.1969,
                "lng": 20.3356
            },
            "Ku\u00e7ov\u00eb": {
                "lat": 40.8039,
                "lng": 19.9144
            },
            "Kruj\u00eb": {
                "lat": 41.5108,
                "lng": 19.7925
            },
            "Burrel": {
                "lat": 41.6083,
                "lng": 20.0111
            },
            "Perondi": {
                "lat": 40.7833,
                "lng": 19.9333
            },
            "Libonik": {
                "lat": 40.75,
                "lng": 20.7167
            },
            "Rr\u00ebshen": {
                "lat": 41.7667,
                "lng": 19.8833
            },
            "Belsh": {
                "lat": 40.9833,
                "lng": 19.8833
            },
            "Gramsh": {
                "lat": 40.8667,
                "lng": 20.1833
            },
            "Librazhd": {
                "lat": 41.1833,
                "lng": 20.3167
            },
            "P\u00ebrmet": {
                "lat": 40.2333,
                "lng": 20.35
            },
            "Bajram Curri": {
                "lat": 42.3583,
                "lng": 20.0761
            },
            "Tepelen\u00eb": {
                "lat": 40.2967,
                "lng": 20.0189
            },
            "\u00c7orovod\u00eb": {
                "lat": 40.5,
                "lng": 20.2167
            },
            "Ersek\u00eb": {
                "lat": 40.3333,
                "lng": 20.6833
            },
            "Puk\u00eb": {
                "lat": 42.05,
                "lng": 19.9
            }
        }
    },
    "GM": {
        "name": "Gambia, The",
        "cities": {
            "Banjul": {
                "lat": 13.4531,
                "lng": -16.5775
            },
            "Serekunda": {
                "lat": 13.4333,
                "lng": -16.6667
            },
            "Brikama": {
                "lat": 13.2667,
                "lng": -16.65
            },
            "Bakau": {
                "lat": 13.4833,
                "lng": -16.6833
            },
            "Sukuta": {
                "lat": 13.4167,
                "lng": -16.7
            },
            "Sareh Mowndeh": {
                "lat": 13.4036,
                "lng": -16.6833
            },
            "Farafenni": {
                "lat": 13.5667,
                "lng": -15.6
            },
            "Mansa Konko": {
                "lat": 13.4667,
                "lng": -15.55
            },
            "Basse Santa Su": {
                "lat": 13.3167,
                "lng": -14.2167
            },
            "Gunjur": {
                "lat": 13.1833,
                "lng": -16.7667
            },
            "Essau": {
                "lat": 13.4833,
                "lng": -16.5333
            },
            "Busumbala": {
                "lat": 13.3333,
                "lng": -16.6667
            },
            "Brikama Ba": {
                "lat": 13.5333,
                "lng": -14.9333
            },
            "Gambissara": {
                "lat": 13.2333,
                "lng": -14.3167
            },
            "Diabugu": {
                "lat": 13.3833,
                "lng": -14.4
            },
            "Bansang": {
                "lat": 13.4333,
                "lng": -14.65
            },
            "Janjanbureh": {
                "lat": 13.5341,
                "lng": -14.7662
            },
            "Kerewan": {
                "lat": 13.5,
                "lng": -16.0833
            },
            "Kanifing": {
                "lat": 13.45,
                "lng": -16.6667
            }
        }
    },
    "LS": {
        "name": "Lesotho",
        "cities": {
            "Maseru": {
                "lat": -29.31,
                "lng": 27.48
            },
            "Teyateyaneng": {
                "lat": -29.1511,
                "lng": 27.7425
            },
            "Mafeteng": {
                "lat": -29.8167,
                "lng": 27.25
            },
            "Leribe": {
                "lat": -28.8734,
                "lng": 28.0416
            },
            "Maputsoe": {
                "lat": -28.895,
                "lng": 27.907
            },
            "Mazenod": {
                "lat": -29.4642,
                "lng": 27.4969
            },
            "Ratau": {
                "lat": -29.3828,
                "lng": 27.7892
            },
            "Mohale\u2019s Hoek": {
                "lat": -30.15,
                "lng": 27.4667
            },
            "Qiloane": {
                "lat": -29.3625,
                "lng": 27.6319
            },
            "Mapoteng": {
                "lat": -29.1097,
                "lng": 27.9739
            },
            "Senekane": {
                "lat": -29.2525,
                "lng": 27.7433
            },
            "Kueneng": {
                "lat": -29.0161,
                "lng": 27.9981
            },
            "Maghalleen": {
                "lat": -30.0942,
                "lng": 27.4189
            },
            "Pitseng": {
                "lat": -29.0097,
                "lng": 28.2575
            },
            "Siloe": {
                "lat": -29.9836,
                "lng": 27.2792
            },
            "Peka": {
                "lat": -28.9667,
                "lng": 27.7667
            },
            "Quthing": {
                "lat": -30.4001,
                "lng": 27.7002
            },
            "Nako": {
                "lat": -29.6481,
                "lng": 27.7336
            },
            "Ramabitsa": {
                "lat": -29.7625,
                "lng": 27.6139
            },
            "Nyakosoba": {
                "lat": -29.5047,
                "lng": 27.8692
            },
            "Rasebetsane": {
                "lat": -29.8992,
                "lng": 27.6628
            },
            "Butha-Buthe": {
                "lat": -28.7833,
                "lng": 28.2333
            },
            "Mohlanapeng": {
                "lat": -29.6975,
                "lng": 28.6947
            },
            "Seforong": {
                "lat": -30.1028,
                "lng": 28.1864
            },
            "Mokhotlong": {
                "lat": -29.2885,
                "lng": 29.0656
            },
            "Thaba-Tseka": {
                "lat": -29.5333,
                "lng": 28.6
            },
            "Qacha\u2019s Nek": {
                "lat": -30.1167,
                "lng": 28.6833
            }
        }
    },
    "CY": {
        "name": "Cyprus",
        "cities": {
            "Nicosia": {
                "lat": 35.1725,
                "lng": 33.365
            },
            "Limassol": {
                "lat": 34.6747,
                "lng": 33.0442
            },
            "Larnaca": {
                "lat": 34.9167,
                "lng": 33.6333
            },
            "Paphos": {
                "lat": 34.7667,
                "lng": 32.4167
            },
            "Famagusta": {
                "lat": 35.125,
                "lng": 33.9417
            },
            "Kyrenia": {
                "lat": 35.3403,
                "lng": 33.3192
            },
            "K\u00e1to Polem\u00eddia": {
                "lat": 34.6931,
                "lng": 32.9992
            },
            "Arad\u00edppou": {
                "lat": 34.9528,
                "lng": 33.59
            },
            "Latsia": {
                "lat": 35.1,
                "lng": 33.3667
            },
            "Paral\u00edmni": {
                "lat": 35.0333,
                "lng": 33.9833
            },
            "\u00c1gios Athan\u00e1sios": {
                "lat": 34.7086,
                "lng": 33.0503
            },
            "M\u00e9sa Geitoni\u00e1": {
                "lat": 34.7022,
                "lng": 33.0453
            },
            "Germas\u00f3geia": {
                "lat": 34.7181,
                "lng": 33.0856
            },
            "D\u00e1li": {
                "lat": 35.0211,
                "lng": 33.42
            }
        }
    },
    "SV": {
        "name": "El Salvador",
        "cities": {
            "San Salvador": {
                "lat": 13.6989,
                "lng": -89.1914
            },
            "Santa Ana": {
                "lat": 13.995,
                "lng": -89.5561
            },
            "Soyapango": {
                "lat": 13.7333,
                "lng": -89.15
            },
            "San Miguel": {
                "lat": 13.4814,
                "lng": -88.1775
            },
            "Santa Tecla": {
                "lat": 13.6731,
                "lng": -89.2406
            },
            "Mejicanos": {
                "lat": 13.7333,
                "lng": -89.2167
            },
            "Apopa": {
                "lat": 13.8,
                "lng": -89.1833
            },
            "Delgado": {
                "lat": 13.7167,
                "lng": -89.1667
            },
            "Ahuachap\u00e1n": {
                "lat": 13.9167,
                "lng": -89.85
            },
            "Ilopango": {
                "lat": 13.7,
                "lng": -89.1167
            },
            "Col\u00f3n": {
                "lat": 13.7167,
                "lng": -89.3667
            },
            "Tonacatepeque": {
                "lat": 13.7833,
                "lng": -89.1167
            },
            "San Juan Opico": {
                "lat": 13.8833,
                "lng": -89.35
            },
            "Chalchuapa": {
                "lat": 13.9833,
                "lng": -89.6833
            },
            "Usulut\u00e1n": {
                "lat": 13.35,
                "lng": -88.45
            },
            "San Mart\u00edn": {
                "lat": 13.7333,
                "lng": -89.05
            },
            "Sonsonate": {
                "lat": 13.7167,
                "lng": -89.7167
            },
            "Izalco": {
                "lat": 13.7333,
                "lng": -89.6667
            },
            "Cuscatancingo": {
                "lat": 13.7333,
                "lng": -89.1833
            },
            "Zacatecoluca": {
                "lat": 13.5,
                "lng": -88.8667
            },
            "San Marcos": {
                "lat": 13.5833,
                "lng": -89.2167
            },
            "Ilobasco": {
                "lat": 13.84,
                "lng": -88.85
            },
            "Ciudad Arce": {
                "lat": 13.8333,
                "lng": -89.4333
            },
            "Metap\u00e1n": {
                "lat": 14.3314,
                "lng": -89.4428
            },
            "San Vicente": {
                "lat": 13.6453,
                "lng": -88.7842
            },
            "Quezaltepeque": {
                "lat": 13.8333,
                "lng": -89.2667
            },
            "Acajutla": {
                "lat": 13.59,
                "lng": -89.8336
            },
            "Cojutepeque": {
                "lat": 13.7167,
                "lng": -88.9333
            },
            "Nahuizalco": {
                "lat": 13.7833,
                "lng": -89.7333
            },
            "Jiquilisco": {
                "lat": 13.3167,
                "lng": -88.5833
            },
            "San Pedro Perulap\u00e1n": {
                "lat": 13.7667,
                "lng": -89.0333
            },
            "San Francisco Men\u00e9ndez": {
                "lat": 13.8425,
                "lng": -90.0167
            },
            "Panchimalco": {
                "lat": 13.6167,
                "lng": -89.1833
            },
            "Sensuntepeque": {
                "lat": 13.8667,
                "lng": -88.6333
            },
            "Santiago Nonualco": {
                "lat": 13.5167,
                "lng": -88.95
            },
            "Conchagua": {
                "lat": 13.3,
                "lng": -87.8667
            },
            "Coatepeque": {
                "lat": 13.9167,
                "lng": -89.5
            },
            "La Libertad": {
                "lat": 13.4833,
                "lng": -89.3167
            },
            "Armenia": {
                "lat": 13.75,
                "lng": -89.5
            },
            "Ayutuxtepeque": {
                "lat": 13.7353,
                "lng": -89.2001
            },
            "La Uni\u00f3n": {
                "lat": 13.3369,
                "lng": -87.8439
            },
            "Antiguo Cuscatl\u00e1n": {
                "lat": 13.6731,
                "lng": -89.2406
            },
            "Atiquizaya": {
                "lat": 13.9667,
                "lng": -89.75
            },
            "Tacuba": {
                "lat": 13.9,
                "lng": -89.9333
            },
            "Olocuilta": {
                "lat": 13.5667,
                "lng": -89.1167
            },
            "Nejapa": {
                "lat": 13.8167,
                "lng": -89.2333
            },
            "Chalatenango": {
                "lat": 14.0333,
                "lng": -88.9333
            },
            "Nueva Concepci\u00f3n": {
                "lat": 14.1333,
                "lng": -89.3
            },
            "Jujutla": {
                "lat": 13.7833,
                "lng": -89.85
            },
            "Santa Rosa de Lima": {
                "lat": 13.6167,
                "lng": -87.9
            },
            "San Antonio del Monte": {
                "lat": 13.7167,
                "lng": -89.7333
            },
            "San Pedro Masahuat": {
                "lat": 13.55,
                "lng": -89.0333
            },
            "Santo Tom\u00e1s": {
                "lat": 13.6333,
                "lng": -89.1333
            },
            "Sonzacate": {
                "lat": 13.7356,
                "lng": -89.7161
            },
            "Ciudad Barrios": {
                "lat": 13.7667,
                "lng": -88.2667
            },
            "Suchitoto": {
                "lat": 13.9333,
                "lng": -89.0333
            },
            "Juay\u00faa": {
                "lat": 13.8333,
                "lng": -89.7333
            },
            "El Congo": {
                "lat": 13.9,
                "lng": -89.4833
            },
            "Tecoluca": {
                "lat": 13.5333,
                "lng": -88.7833
            },
            "Candelaria de La Frontera": {
                "lat": 14.1167,
                "lng": -89.65
            },
            "Moncagua": {
                "lat": 13.5333,
                "lng": -88.25
            },
            "Zaragoza": {
                "lat": 13.5833,
                "lng": -89.2833
            },
            "Chinameca": {
                "lat": 13.5,
                "lng": -88.35
            },
            "San Luis Talpa": {
                "lat": 13.4667,
                "lng": -89.0833
            },
            "Aguilares": {
                "lat": 13.95,
                "lng": -89.1833
            },
            "San Francisco": {
                "lat": 13.7,
                "lng": -88.1
            },
            "San Pablo Tacachico": {
                "lat": 13.9833,
                "lng": -89.3333
            },
            "Chirilagua": {
                "lat": 13.2167,
                "lng": -88.1386
            },
            "Santiago Texacuangos": {
                "lat": 13.65,
                "lng": -89.1167
            },
            "Guaymango": {
                "lat": 13.75,
                "lng": -89.8333
            },
            "San Juli\u00e1n": {
                "lat": 13.6976,
                "lng": -89.5596
            },
            "San Sebasti\u00e1n Salitrillo": {
                "lat": 13.95,
                "lng": -89.6333
            },
            "Jucuapa": {
                "lat": 13.5167,
                "lng": -88.3833
            },
            "El Tr\u00e1nsito": {
                "lat": 13.35,
                "lng": -88.35
            },
            "Apastepeque": {
                "lat": 13.6667,
                "lng": -88.7667
            },
            "Santiago de Mar\u00eda": {
                "lat": 13.4833,
                "lng": -88.4667
            },
            "Texistepeque": {
                "lat": 14.1333,
                "lng": -89.5
            },
            "Berl\u00edn": {
                "lat": 13.5,
                "lng": -88.5333
            },
            "San Alejo": {
                "lat": 13.4333,
                "lng": -87.9667
            },
            "Santa Elena": {
                "lat": 13.3833,
                "lng": -88.4167
            },
            "San Juan Nonualco": {
                "lat": 13.5072,
                "lng": -88.8997
            },
            "San Rafael Cedros": {
                "lat": 13.7333,
                "lng": -88.8833
            },
            "El Rosario": {
                "lat": 13.5,
                "lng": -89.0333
            },
            "Puerto El Triunfo": {
                "lat": 13.2833,
                "lng": -88.55
            },
            "Pasaquina": {
                "lat": 13.5844,
                "lng": -87.8411
            },
            "Corinto": {
                "lat": 13.8167,
                "lng": -87.9667
            },
            "Lolotique": {
                "lat": 13.55,
                "lng": -88.35
            },
            "Anamor\u00f3s": {
                "lat": 13.7333,
                "lng": -87.8667
            },
            "El Paisnal": {
                "lat": 13.9667,
                "lng": -89.2167
            },
            "Huiz\u00facar": {
                "lat": 13.5833,
                "lng": -89.2333
            },
            "San Sebasti\u00e1n": {
                "lat": 13.7333,
                "lng": -88.8333
            },
            "Tepecoyo": {
                "lat": 13.7003,
                "lng": -89.4678
            },
            "Tejutla": {
                "lat": 14.1667,
                "lng": -89.1
            },
            "San Jos\u00e9 Villanueva": {
                "lat": 13.5833,
                "lng": -89.2667
            },
            "Tamanique": {
                "lat": 13.6,
                "lng": -89.4167
            },
            "Jucuar\u00e1n": {
                "lat": 13.2544,
                "lng": -88.2489
            },
            "Lislique": {
                "lat": 13.8,
                "lng": -87.8833
            },
            "El Carmen": {
                "lat": 13.35,
                "lng": -88.0
            },
            "San Rafael Oriente": {
                "lat": 13.3833,
                "lng": -88.35
            },
            "Mercedes Uma\u00f1a": {
                "lat": 13.5667,
                "lng": -88.5
            },
            "Concepci\u00f3n de Ataco": {
                "lat": 13.8667,
                "lng": -89.85
            },
            "Cuisnahuat": {
                "lat": 13.6333,
                "lng": -89.6
            },
            "Victoria": {
                "lat": 13.95,
                "lng": -88.6333
            },
            "Ozatl\u00e1n": {
                "lat": 13.3833,
                "lng": -88.5
            },
            "Teotepeque": {
                "lat": 13.5853,
                "lng": -89.5183
            },
            "Sacacoyo": {
                "lat": 13.7333,
                "lng": -89.4667
            },
            "La Palma": {
                "lat": 14.3167,
                "lng": -89.1667
            },
            "Concepci\u00f3n Batres": {
                "lat": 13.3416,
                "lng": -88.3715
            },
            "Comasagua": {
                "lat": 13.6333,
                "lng": -89.3833
            },
            "Santa Cruz Michapa": {
                "lat": 13.7333,
                "lng": -88.9667
            },
            "Guatajiagua": {
                "lat": 13.6667,
                "lng": -88.2
            },
            "Alegr\u00eda": {
                "lat": 13.5,
                "lng": -88.4833
            },
            "Sociedad": {
                "lat": 13.7,
                "lng": -88.0167
            },
            "Rosario de Mora": {
                "lat": 13.5833,
                "lng": -89.2167
            },
            "Jayaque": {
                "lat": 13.6667,
                "lng": -89.4333
            },
            "Cacaopera": {
                "lat": 13.7667,
                "lng": -88.0833
            },
            "Chiltiup\u00e1n": {
                "lat": 13.5833,
                "lng": -89.4667
            },
            "Santa Mar\u00eda": {
                "lat": 13.35,
                "lng": -88.4333
            },
            "Chapeltique": {
                "lat": 13.6333,
                "lng": -88.2667
            },
            "Sesori": {
                "lat": 13.7167,
                "lng": -88.3667
            },
            "El Para\u00edso": {
                "lat": 14.0833,
                "lng": -89.0833
            },
            "Nahulingo": {
                "lat": 13.7,
                "lng": -89.7167
            },
            "El Refugio": {
                "lat": 13.975,
                "lng": -89.7072
            },
            "Candelaria": {
                "lat": 13.689,
                "lng": -88.9755
            },
            "Santa Isabel Ishuat\u00e1n": {
                "lat": 13.6167,
                "lng": -89.5833
            },
            "Monte San Juan": {
                "lat": 13.7667,
                "lng": -88.95
            },
            "San Sim\u00f3n": {
                "lat": 13.8333,
                "lng": -88.2333
            },
            "Santa Catarina Masahuat": {
                "lat": 13.7833,
                "lng": -89.7667
            },
            "Jocoro": {
                "lat": 13.6167,
                "lng": -88.0167
            },
            "San Rafael Obrajuelo": {
                "lat": 13.5,
                "lng": -88.9333
            },
            "Polor\u00f3s": {
                "lat": 13.5844,
                "lng": -87.8411
            },
            "Chilanga": {
                "lat": 13.7167,
                "lng": -88.1167
            },
            "Nueva Esparta": {
                "lat": 13.7833,
                "lng": -87.8333
            },
            "La Reina": {
                "lat": 14.1833,
                "lng": -89.15
            },
            "San Jos\u00e9 Guayabal": {
                "lat": 13.85,
                "lng": -89.1
            },
            "San Pedro Nonualco": {
                "lat": 13.6,
                "lng": -88.9333
            },
            "San Lorenzo": {
                "lat": 14.0333,
                "lng": -89.7833
            },
            "Caluco": {
                "lat": 13.7167,
                "lng": -89.65
            },
            "San Jorge": {
                "lat": 13.6833,
                "lng": -88.3833
            },
            "Estanzuelas": {
                "lat": 13.65,
                "lng": -88.5
            },
            "Tur\u00edn": {
                "lat": 13.9667,
                "lng": -89.8167
            },
            "Dulce Nombre de Mar\u00eda": {
                "lat": 14.15,
                "lng": -89.0167
            },
            "Osicala": {
                "lat": 13.8,
                "lng": -88.15
            },
            "Nueva Guadalupe": {
                "lat": 13.5333,
                "lng": -88.35
            },
            "San Ignacio": {
                "lat": 14.3333,
                "lng": -89.1833
            }
        }
    },
    "RE": {
        "name": "Reunion",
        "cities": {
            "Saint-Denis": {
                "lat": -20.8789,
                "lng": 55.4481
            },
            "Saint-Beno\u00eet": {
                "lat": -21.0339,
                "lng": 55.7128
            }
        }
    },
    "GQ": {
        "name": "Equatorial Guinea",
        "cities": {
            "Malabo": {
                "lat": 3.7456,
                "lng": 8.7744
            },
            "Bata": {
                "lat": 1.865,
                "lng": 9.77
            },
            "Sevilla de Niefang": {
                "lat": 1.85,
                "lng": 10.25
            },
            "Ebebiy\u00edn": {
                "lat": 2.15,
                "lng": 11.3167
            },
            "Santiago de Baney": {
                "lat": 3.7,
                "lng": 8.9167
            },
            "A\u00f1isoc": {
                "lat": 1.85,
                "lng": 10.7667
            },
            "Bengonbeyene": {
                "lat": 1.6931,
                "lng": 11.0342
            },
            "Mbini": {
                "lat": 1.5833,
                "lng": 9.6167
            },
            "Aconibe": {
                "lat": 1.3,
                "lng": 10.9333
            },
            "Rebola": {
                "lat": 3.7192,
                "lng": 8.8531
            },
            "Luba": {
                "lat": 3.45,
                "lng": 8.55
            },
            "Mongomo": {
                "lat": 1.6287,
                "lng": 11.3168
            },
            "Pal\u00e9": {
                "lat": -1.4069,
                "lng": 5.6322
            },
            "Evinayong": {
                "lat": 1.45,
                "lng": 10.5667
            },
            "Djibloho": {
                "lat": 1.5889,
                "lng": 10.8225
            },
            "Calatrava": {
                "lat": 1.1164,
                "lng": 9.4186
            }
        }
    },
    "SI": {
        "name": "Slovenia",
        "cities": {
            "Ljubljana": {
                "lat": 46.0514,
                "lng": 14.5061
            },
            "Maribor": {
                "lat": 46.5575,
                "lng": 15.6456
            },
            "Celje": {
                "lat": 46.2358,
                "lng": 15.2675
            },
            "Kranj": {
                "lat": 46.2333,
                "lng": 14.3667
            },
            "Koper": {
                "lat": 45.55,
                "lng": 13.7333
            },
            "Velenje": {
                "lat": 46.3625,
                "lng": 15.1144
            },
            "Novo Mesto": {
                "lat": 45.8,
                "lng": 15.1667
            },
            "Ptuj": {
                "lat": 46.4194,
                "lng": 15.8694
            },
            "Trbovlje": {
                "lat": 46.15,
                "lng": 15.05
            },
            "Kamnik": {
                "lat": 46.2257,
                "lng": 14.612
            },
            "Nova Gorica": {
                "lat": 45.9558,
                "lng": 13.6433
            },
            "Izola": {
                "lat": 45.5344,
                "lng": 13.6675
            },
            "\u0160kofja Loka": {
                "lat": 46.1672,
                "lng": 14.303
            },
            "Murska Sobota": {
                "lat": 46.6667,
                "lng": 16.1667
            },
            "Postojna": {
                "lat": 45.7759,
                "lng": 14.2137
            },
            "Logatec": {
                "lat": 45.9167,
                "lng": 14.2297
            },
            "Vrhnika": {
                "lat": 45.9624,
                "lng": 14.2937
            },
            "Jesenice": {
                "lat": 46.4366,
                "lng": 14.0602
            },
            "Slovenska Bistrica": {
                "lat": 46.3903,
                "lng": 15.575
            },
            "Ko\u010devje": {
                "lat": 45.643,
                "lng": 14.8594
            },
            "Grosuplje": {
                "lat": 45.9551,
                "lng": 14.6562
            },
            "Ravne na Koro\u0161kem": {
                "lat": 46.5437,
                "lng": 14.9642
            },
            "Slovenj Gradec": {
                "lat": 46.5094,
                "lng": 15.0792
            },
            "Bre\u017eice": {
                "lat": 45.9048,
                "lng": 15.5922
            },
            "Ajdov\u0161\u010dina": {
                "lat": 45.8861,
                "lng": 13.9097
            },
            "Litija": {
                "lat": 46.0667,
                "lng": 14.8167
            },
            "Menge\u0161": {
                "lat": 46.1626,
                "lng": 14.568
            },
            "Se\u017eana": {
                "lat": 45.7034,
                "lng": 13.8706
            },
            "Zagorje": {
                "lat": 46.1342,
                "lng": 14.9942
            },
            "Radovljica": {
                "lat": 46.3425,
                "lng": 14.1721
            },
            "Idrija": {
                "lat": 46.0025,
                "lng": 14.0275
            },
            "\u010crnomelj": {
                "lat": 45.5711,
                "lng": 15.1889
            },
            "Medvode": {
                "lat": 46.1382,
                "lng": 14.4147
            },
            "Slovenske Konjice": {
                "lat": 46.3383,
                "lng": 15.4233
            },
            "Roga\u0161ka Slatina": {
                "lat": 46.2314,
                "lng": 15.6381
            },
            "\u017dalec": {
                "lat": 46.251,
                "lng": 15.1639
            },
            "Hrastnik": {
                "lat": 46.1479,
                "lng": 15.1528
            },
            "\u0160entjur": {
                "lat": 46.2176,
                "lng": 15.396
            },
            "Bled": {
                "lat": 46.3688,
                "lng": 14.114
            },
            "Prevalje": {
                "lat": 46.5438,
                "lng": 14.9119
            },
            "Sevnica": {
                "lat": 46.0092,
                "lng": 15.3041
            },
            "Ilirska Bistrica": {
                "lat": 45.5679,
                "lng": 14.2527
            },
            "Ru\u0161e": {
                "lat": 46.5386,
                "lng": 15.5154
            },
            "Cerknica": {
                "lat": 45.7964,
                "lng": 14.3581
            },
            "Trebnje": {
                "lat": 45.9104,
                "lng": 15.0086
            },
            "Trzin": {
                "lat": 46.1353,
                "lng": 14.56
            },
            "Miklav\u017e na Dravskem Polju": {
                "lat": 46.5057,
                "lng": 15.6993
            },
            "Piran": {
                "lat": 45.5283,
                "lng": 13.5683
            },
            "\u0160empeter pri Gorici": {
                "lat": 45.9284,
                "lng": 13.6378
            },
            "Tr\u017ei\u010d": {
                "lat": 46.3581,
                "lng": 14.293
            },
            "\u017diri": {
                "lat": 46.0469,
                "lng": 14.1097
            },
            "Ribnica": {
                "lat": 46.5372,
                "lng": 15.2733
            },
            "Tolmin": {
                "lat": 46.1857,
                "lng": 13.7352
            },
            "La\u0161ko": {
                "lat": 46.1563,
                "lng": 15.2386
            },
            "Lenart v Slovenskih Goricah": {
                "lat": 46.5742,
                "lng": 15.8322
            },
            "Ljutomer": {
                "lat": 46.5168,
                "lng": 16.2027
            },
            "Dom\u017eale": {
                "lat": 46.1394,
                "lng": 14.595
            },
            "Metlika": {
                "lat": 45.6517,
                "lng": 15.32
            },
            "Ankaran": {
                "lat": 45.5793,
                "lng": 13.7379
            },
            "Brezovica": {
                "lat": 46.0207,
                "lng": 14.4178
            },
            "Me\u017eica": {
                "lat": 46.5206,
                "lng": 14.8536
            },
            "\u0160en\u010dur": {
                "lat": 46.243,
                "lng": 14.4181
            },
            "Dravograd": {
                "lat": 46.5903,
                "lng": 15.0236
            },
            "Gornja Radgona": {
                "lat": 46.6752,
                "lng": 15.9926
            },
            "\u017delezniki": {
                "lat": 46.2182,
                "lng": 14.1592
            },
            "\u0160kofljica": {
                "lat": 45.9836,
                "lng": 14.5761
            },
            "Lendava": {
                "lat": 46.5631,
                "lng": 16.4519
            },
            "Roga\u0161ovci": {
                "lat": 46.8053,
                "lng": 16.0344
            },
            "\u0160o\u0161tanj": {
                "lat": 46.3798,
                "lng": 15.0482
            },
            "Zre\u010de": {
                "lat": 46.375,
                "lng": 15.3854
            },
            "Radlje ob Dravi": {
                "lat": 46.6152,
                "lng": 15.2264
            },
            "Ra\u010de": {
                "lat": 46.4529,
                "lng": 15.6812
            },
            "Borovnica": {
                "lat": 45.9197,
                "lng": 14.3572
            },
            "Vojnik": {
                "lat": 46.2933,
                "lng": 15.3052
            },
            "Beltinci": {
                "lat": 46.606,
                "lng": 16.2328
            },
            "Polzela": {
                "lat": 46.2809,
                "lng": 15.074
            },
            "Kr\u0161ko": {
                "lat": 45.95,
                "lng": 15.4833
            },
            "Muta": {
                "lat": 46.6167,
                "lng": 15.1667
            },
            "Sveta Ana": {
                "lat": 46.6497,
                "lng": 15.8453
            },
            "\u0160tore": {
                "lat": 46.2219,
                "lng": 15.3111
            },
            "Ig": {
                "lat": 45.9692,
                "lng": 14.5394
            },
            "Ivan\u010dna Gorica": {
                "lat": 45.9374,
                "lng": 14.8029
            },
            "\u010crna na Koro\u0161kem": {
                "lat": 46.4697,
                "lng": 14.849
            },
            "Radenci": {
                "lat": 46.6428,
                "lng": 16.0453
            },
            "Spodnje Ho\u010de": {
                "lat": 46.4996,
                "lng": 15.6471
            },
            "Vipava": {
                "lat": 45.8476,
                "lng": 13.9624
            },
            "Pivka": {
                "lat": 45.6833,
                "lng": 14.2
            },
            "Mozirje": {
                "lat": 46.3381,
                "lng": 14.9572
            },
            "Semi\u010d": {
                "lat": 45.6546,
                "lng": 15.1783
            },
            "Rade\u010de": {
                "lat": 46.0658,
                "lng": 15.1728
            },
            "Lovrenc na Pohorju": {
                "lat": 46.5381,
                "lng": 15.3844
            },
            "Ormo\u017e": {
                "lat": 46.4086,
                "lng": 16.1475
            },
            "Stra\u017ea": {
                "lat": 45.7864,
                "lng": 15.0745
            },
            "Mislinja": {
                "lat": 46.4431,
                "lng": 15.1931
            },
            "Naklo": {
                "lat": 46.2749,
                "lng": 14.3176
            },
            "Bohinjska Bistrica": {
                "lat": 46.2742,
                "lng": 13.9524
            },
            "\u0160marje": {
                "lat": 46.2281,
                "lng": 15.5182
            },
            "Diva\u010da": {
                "lat": 45.682,
                "lng": 13.9702
            },
            "Cerklje na Gorenjskem": {
                "lat": 46.2488,
                "lng": 14.4855
            },
            "Vodice": {
                "lat": 46.1897,
                "lng": 14.4944
            },
            "Spodnji Duplek": {
                "lat": 46.5053,
                "lng": 15.7467
            },
            "Tabor": {
                "lat": 46.2225,
                "lng": 15.0081
            },
            "Odranci": {
                "lat": 46.5856,
                "lng": 16.2753
            },
            "Prebold": {
                "lat": 46.2369,
                "lng": 15.0919
            },
            "Zgornja Kungota": {
                "lat": 46.6388,
                "lng": 15.6141
            },
            "Bovec": {
                "lat": 46.3378,
                "lng": 13.5522
            },
            "Rogatec": {
                "lat": 46.2243,
                "lng": 15.7052
            },
            "Vuzenica": {
                "lat": 46.5992,
                "lng": 15.1669
            },
            "Turni\u0161\u010de": {
                "lat": 46.6194,
                "lng": 16.3223
            },
            "Miren": {
                "lat": 45.8962,
                "lng": 13.6072
            },
            "Dragomer": {
                "lat": 46.0189,
                "lng": 14.382
            },
            "Kranjska Gora": {
                "lat": 46.4854,
                "lng": 13.7871
            },
            "\u0160entjernej": {
                "lat": 45.8389,
                "lng": 15.3361
            },
            "Cerkno": {
                "lat": 46.1278,
                "lng": 13.9875
            },
            "Oplotnica": {
                "lat": 46.386,
                "lng": 15.4458
            },
            "\u0160martno": {
                "lat": 46.3297,
                "lng": 15.0325
            },
            "Mirna": {
                "lat": 45.9476,
                "lng": 15.0664
            },
            "Selnica ob Dravi": {
                "lat": 46.5514,
                "lng": 15.4947
            },
            "Dornava": {
                "lat": 46.4299,
                "lng": 15.9488
            },
            "Kidri\u010devo": {
                "lat": 46.406,
                "lng": 15.7983
            },
            "Horjul": {
                "lat": 46.023,
                "lng": 14.2981
            },
            "\u010cren\u0161ovci": {
                "lat": 46.5747,
                "lng": 16.2937
            },
            "Kanal": {
                "lat": 46.088,
                "lng": 13.6347
            },
            "Gorenja Vas": {
                "lat": 46.1069,
                "lng": 14.1451
            },
            "Polj\u010dane": {
                "lat": 46.3135,
                "lng": 15.5791
            },
            "Ljubno": {
                "lat": 46.3333,
                "lng": 14.8333
            },
            "Kobarid": {
                "lat": 46.2464,
                "lng": 13.578
            },
            "Benedikt": {
                "lat": 46.6065,
                "lng": 15.8842
            },
            "Gornji Grad": {
                "lat": 46.2961,
                "lng": 14.8052
            },
            "Mirna Pe\u010d": {
                "lat": 45.858,
                "lng": 15.0876
            },
            "Sredi\u0161\u010de ob Dravi": {
                "lat": 46.3933,
                "lng": 16.2722
            },
            "Morav\u010de": {
                "lat": 46.1356,
                "lng": 14.7439
            },
            "Dobrovnik": {
                "lat": 46.6515,
                "lng": 16.3479
            },
            "Dobrova": {
                "lat": 46.0533,
                "lng": 14.4144
            },
            "Ver\u017eej": {
                "lat": 46.5818,
                "lng": 16.1623
            },
            "Komenda": {
                "lat": 46.2073,
                "lng": 14.539
            },
            "Dolenjske Toplice": {
                "lat": 45.7545,
                "lng": 15.0583
            },
            "Nazarje": {
                "lat": 46.3202,
                "lng": 14.9531
            },
            "Velika Polana": {
                "lat": 46.5719,
                "lng": 16.3472
            },
            "Vitanje": {
                "lat": 46.3825,
                "lng": 15.2956
            },
            "Gori\u0161nica": {
                "lat": 46.4122,
                "lng": 16.0131
            },
            "Pesnica": {
                "lat": 46.6099,
                "lng": 15.6714
            },
            "Sodra\u017eica": {
                "lat": 45.7616,
                "lng": 14.6363
            },
            "Stari Trg": {
                "lat": 45.7142,
                "lng": 14.4695
            },
            "Preddvor": {
                "lat": 46.3052,
                "lng": 14.4203
            },
            "Zgornja Hajdina": {
                "lat": 46.4057,
                "lng": 15.8385
            },
            "Star\u0161e": {
                "lat": 46.4664,
                "lng": 15.7675
            },
            "Sveta Trojica v Slovenskih Goricah": {
                "lat": 46.5756,
                "lng": 15.8769
            },
            "Moravske-Toplice": {
                "lat": 46.6927,
                "lng": 16.2281
            },
            "Velike La\u0161\u010de": {
                "lat": 45.8363,
                "lng": 14.6377
            },
            "Mokronog": {
                "lat": 45.9426,
                "lng": 15.149
            },
            "Kostanjevica na Krki": {
                "lat": 45.8463,
                "lng": 15.4249
            },
            "Komen": {
                "lat": 45.8159,
                "lng": 13.7507
            },
            "Kozje": {
                "lat": 46.074,
                "lng": 15.559
            },
            "Puconci": {
                "lat": 46.7039,
                "lng": 16.1572
            },
            "Grad": {
                "lat": 46.7977,
                "lng": 16.0984
            },
            "Maj\u0161perk": {
                "lat": 46.35,
                "lng": 15.7333
            },
            "Videm": {
                "lat": 45.8484,
                "lng": 14.6944
            },
            "Pod\u010detrtek": {
                "lat": 46.1573,
                "lng": 15.5974
            },
            "Zgornje Jezersko": {
                "lat": 46.3951,
                "lng": 14.4966
            },
            "Apa\u010de": {
                "lat": 46.6967,
                "lng": 15.9111
            },
            "Kobilje": {
                "lat": 46.6837,
                "lng": 16.3953
            },
            "Dobrna": {
                "lat": 46.3381,
                "lng": 15.2293
            },
            "Zgornje Gorje": {
                "lat": 46.3801,
                "lng": 14.0684
            },
            "Re\u010dica": {
                "lat": 46.3247,
                "lng": 14.9228
            },
            "Kri\u017eevci": {
                "lat": 46.5631,
                "lng": 16.1399
            },
            "Markovci": {
                "lat": 46.3956,
                "lng": 15.9314
            },
            "\u0160marje\u0161ke Toplice": {
                "lat": 45.8622,
                "lng": 15.2214
            },
            "Cirkulane": {
                "lat": 46.3436,
                "lng": 15.9941
            },
            "Videm pri Ptuju": {
                "lat": 46.3699,
                "lng": 15.8935
            },
            "Lukovica": {
                "lat": 46.1686,
                "lng": 14.6892
            },
            "Kuzma": {
                "lat": 46.8333,
                "lng": 16.0833
            },
            "Cankova": {
                "lat": 46.7193,
                "lng": 16.0179
            },
            "Gornji Petrovci": {
                "lat": 46.805,
                "lng": 16.2172
            },
            "Ti\u0161ina": {
                "lat": 46.6558,
                "lng": 16.0933
            },
            "Lu\u010de": {
                "lat": 46.3565,
                "lng": 14.7436
            },
            "\u0160alovci": {
                "lat": 46.825,
                "lng": 16.2981
            },
            "Jur\u0161inci": {
                "lat": 46.4853,
                "lng": 15.971
            },
            "Podlehnik": {
                "lat": 46.3361,
                "lng": 15.8786
            },
            "Braslov\u010de": {
                "lat": 46.2884,
                "lng": 15.0386
            },
            "Trnovska Vas": {
                "lat": 46.5231,
                "lng": 15.8978
            },
            "Jurovski Dol": {
                "lat": 46.6087,
                "lng": 15.7796
            },
            "Hrib-Lo\u0161ki Potok": {
                "lat": 45.7015,
                "lng": 14.5901
            },
            "Vitomarci": {
                "lat": 46.5248,
                "lng": 15.9346
            },
            "Dobrovo": {
                "lat": 45.9989,
                "lng": 13.5296
            },
            "\u0160entrupert": {
                "lat": 45.9769,
                "lng": 15.0897
            },
            "Podvelka": {
                "lat": 46.5864,
                "lng": 15.3286
            },
            "Nova Vas": {
                "lat": 45.7726,
                "lng": 14.5064
            },
            "Hodo\u0161": {
                "lat": 46.8285,
                "lng": 16.3239
            },
            "Dol": {
                "lat": 46.0884,
                "lng": 14.6425
            },
            "Toma\u017e pri Ormo\u017eu": {
                "lat": 46.4833,
                "lng": 16.0794
            },
            "Bistrica ob Sotli": {
                "lat": 46.0589,
                "lng": 15.6642
            },
            "\u0160kocjan": {
                "lat": 45.9069,
                "lng": 15.2914
            },
            "Makole": {
                "lat": 46.3174,
                "lng": 15.6669
            },
            "Sveti Jurij": {
                "lat": 46.5683,
                "lng": 16.0203
            },
            "Sol\u010dava": {
                "lat": 46.4201,
                "lng": 14.6918
            },
            "Destrnik": {
                "lat": 46.4923,
                "lng": 15.8801
            },
            "Cerkvenjak": {
                "lat": 46.566,
                "lng": 15.9429
            },
            "Razkri\u017eje": {
                "lat": 46.5182,
                "lng": 16.2882
            },
            "Dobje": {
                "lat": 46.1369,
                "lng": 15.3945
            },
            "Osilnica": {
                "lat": 45.5289,
                "lng": 14.6983
            },
            "Vransko": {
                "lat": 46.172,
                "lng": 14.8217
            },
            "Kostel": {
                "lat": 45.5088,
                "lng": 14.91
            },
            "\u017detale": {
                "lat": 46.275,
                "lng": 15.7939
            },
            "\u0160entilj": {
                "lat": 46.6817,
                "lng": 15.6481
            },
            "\u017du\u017eemberk": {
                "lat": 45.8339,
                "lng": 14.9292
            },
            "Zavr\u010d": {
                "lat": 46.3917,
                "lng": 16.0497
            }
        }
    },
    "BS": {
        "name": "Bahamas, The",
        "cities": {
            "Nassau": {
                "lat": 25.0781,
                "lng": -77.3386
            },
            "Lucaya": {
                "lat": 26.5117,
                "lng": -78.6431
            },
            "Freeport City": {
                "lat": 26.5286,
                "lng": -78.6967
            },
            "West End": {
                "lat": 26.6867,
                "lng": -78.975
            }
        }
    },
    "MQ": {
        "name": "Martinique",
        "cities": {
            "Fort-de-France": {
                "lat": 14.6,
                "lng": -61.0667
            }
        }
    },
    "GP": {
        "name": "Guadeloupe",
        "cities": {
            "Pointe-\u00e0-Pitre": {
                "lat": 16.2411,
                "lng": -61.5331
            },
            "Basse-Terre": {
                "lat": 16.0104,
                "lng": -61.7055
            }
        }
    },
    "BW": {
        "name": "Botswana",
        "cities": {
            "Gaborone": {
                "lat": -24.6581,
                "lng": 25.9122
            },
            "Francistown": {
                "lat": -21.1736,
                "lng": 27.5125
            },
            "Molepolole": {
                "lat": -24.4067,
                "lng": 25.495
            },
            "Mogoditshane": {
                "lat": -24.6333,
                "lng": 25.8667
            },
            "Maun": {
                "lat": -19.9833,
                "lng": 23.4167
            },
            "Selibe Phikwe": {
                "lat": -21.9758,
                "lng": 27.84
            },
            "Kanye": {
                "lat": -24.9833,
                "lng": 25.35
            },
            "Serowe": {
                "lat": -22.3833,
                "lng": 26.7167
            },
            "Mochudi": {
                "lat": -24.4167,
                "lng": 26.15
            },
            "Mahalapye": {
                "lat": -23.1,
                "lng": 26.8
            },
            "Palapye": {
                "lat": -22.55,
                "lng": 27.1333
            },
            "Tlokweng": {
                "lat": -24.6667,
                "lng": 25.9667
            },
            "Ramotswa": {
                "lat": -24.8667,
                "lng": 25.8167
            },
            "Lobatse": {
                "lat": -25.2167,
                "lng": 25.6667
            },
            "Ghanzi": {
                "lat": -21.7,
                "lng": 21.65
            },
            "Bobonong": {
                "lat": -21.9704,
                "lng": 28.4233
            },
            "Letlhakane": {
                "lat": -21.4167,
                "lng": 25.5833
            },
            "Mosopa": {
                "lat": -24.7792,
                "lng": 25.4183
            },
            "Thamaga": {
                "lat": -24.7167,
                "lng": 25.5333
            },
            "Jwaneng": {
                "lat": -24.6019,
                "lng": 24.7283
            },
            "Gabane": {
                "lat": -24.6667,
                "lng": 25.7833
            },
            "Mmopone": {
                "lat": -24.5669,
                "lng": 25.8733
            },
            "Mmadinare": {
                "lat": -21.8746,
                "lng": 27.7496
            },
            "Kopong": {
                "lat": -24.4686,
                "lng": 25.8922
            },
            "Kasane": {
                "lat": -17.8167,
                "lng": 25.15
            },
            "Tshabong": {
                "lat": -26.02,
                "lng": 22.4056
            },
            "Shoshong": {
                "lat": -23.0333,
                "lng": 26.5167
            },
            "Metsemotlhaba": {
                "lat": -24.5531,
                "lng": 25.8039
            },
            "Nata": {
                "lat": -20.2106,
                "lng": 26.1806
            },
            "Masunga": {
                "lat": -20.6667,
                "lng": 27.4167
            },
            "Mopipi": {
                "lat": -21.2019,
                "lng": 24.8683
            },
            "Sowa Town": {
                "lat": -20.5636,
                "lng": 26.2244
            },
            "Lehututu": {
                "lat": -23.9169,
                "lng": 21.8264
            },
            "Nokaneng": {
                "lat": -19.6639,
                "lng": 22.1917
            },
            "Lokwabe": {
                "lat": -24.0961,
                "lng": 21.7781
            },
            "Tsau": {
                "lat": -20.165,
                "lng": 22.4544
            },
            "Muhembo": {
                "lat": -18.2996,
                "lng": 21.8
            }
        }
    },
    "SR": {
        "name": "Suriname",
        "cities": {
            "Paramaribo": {
                "lat": 5.8522,
                "lng": -55.2039
            },
            "Cottica": {
                "lat": 3.8542,
                "lng": -54.2281
            },
            "Koewarasan": {
                "lat": 5.7697,
                "lng": -55.2922
            },
            "Lelydorp": {
                "lat": 5.6967,
                "lng": -55.2167
            },
            "Nieuw Nickerie": {
                "lat": 5.9333,
                "lng": -56.9833
            },
            "Meerzorg": {
                "lat": 5.8072,
                "lng": -55.1469
            },
            "Moengo": {
                "lat": 5.6167,
                "lng": -54.4
            },
            "Nieuw Amsterdam": {
                "lat": 5.8833,
                "lng": -55.0833
            },
            "Albina": {
                "lat": 5.5,
                "lng": -54.05
            },
            "Brownsweg": {
                "lat": 5.0164,
                "lng": -55.1669
            },
            "Groningen": {
                "lat": 5.8,
                "lng": -55.4667
            },
            "Brokopondo": {
                "lat": 5.0667,
                "lng": -54.9667
            },
            "Onverwacht": {
                "lat": 5.5931,
                "lng": -55.1939
            },
            "Totness": {
                "lat": 5.8775,
                "lng": -56.3292
            }
        }
    },
    "TL": {
        "name": "Timor-Leste",
        "cities": {
            "Dili": {
                "lat": -8.5536,
                "lng": 125.5783
            },
            "Maliana": {
                "lat": -8.9917,
                "lng": 125.2197
            },
            "Baucau": {
                "lat": -8.4667,
                "lng": 126.45
            },
            "Manatuto": {
                "lat": -8.5167,
                "lng": 126.0167
            },
            "Gleno": {
                "lat": -8.7239,
                "lng": 125.4361
            },
            "Viqueque": {
                "lat": -8.85,
                "lng": 126.3667
            },
            "Liqui\u00e7\u00e1": {
                "lat": -8.5935,
                "lng": 125.3273
            },
            "Ainaro": {
                "lat": -8.9833,
                "lng": 125.5
            },
            "Same": {
                "lat": -9.0,
                "lng": 125.65
            },
            "Lospalos": {
                "lat": -8.5167,
                "lng": 127.0333
            },
            "Aileu": {
                "lat": -8.7281,
                "lng": 125.5664
            },
            "Pante Macassar": {
                "lat": -9.2,
                "lng": 124.3833
            },
            "Suai": {
                "lat": -9.3129,
                "lng": 125.2565
            }
        }
    },
    "XK": {
        "name": "Kosovo",
        "cities": {
            "Prizren": {
                "lat": 42.2128,
                "lng": 20.7392
            },
            "Pristina": {
                "lat": 42.6633,
                "lng": 21.1622
            },
            "Ferizaj": {
                "lat": 42.3667,
                "lng": 21.1667
            },
            "Pej\u00eb": {
                "lat": 42.6603,
                "lng": 20.2917
            },
            "Gjakov\u00eb": {
                "lat": 42.3833,
                "lng": 20.4333
            },
            "Gjilan": {
                "lat": 42.4647,
                "lng": 21.4669
            },
            "Podujev\u00eb": {
                "lat": 42.9105,
                "lng": 21.1911
            },
            "Mitrovic\u00eb": {
                "lat": 42.8833,
                "lng": 20.8667
            },
            "Lipjan": {
                "lat": 42.53,
                "lng": 21.1386
            },
            "Vushtrri": {
                "lat": 42.8222,
                "lng": 20.9694
            },
            "Suharek\u00eb": {
                "lat": 42.38,
                "lng": 20.8219
            },
            "Gllogovc": {
                "lat": 42.6236,
                "lng": 20.8939
            },
            "Rahovec": {
                "lat": 42.3994,
                "lng": 20.6547
            },
            "Skenderaj": {
                "lat": 42.7333,
                "lng": 20.7833
            },
            "Viti": {
                "lat": 42.3167,
                "lng": 21.35
            },
            "Fush\u00eb Kosov\u00eb": {
                "lat": 42.63,
                "lng": 21.12
            },
            "Istog": {
                "lat": 42.7833,
                "lng": 20.4833
            },
            "Klin\u00eb": {
                "lat": 42.6167,
                "lng": 20.5667
            },
            "Kamenic\u00eb": {
                "lat": 42.5839,
                "lng": 21.575
            },
            "Dragash": {
                "lat": 42.0611,
                "lng": 20.6528
            },
            "Ka\u00e7anik": {
                "lat": 42.2467,
                "lng": 21.2553
            },
            "Shtime": {
                "lat": 42.4333,
                "lng": 21.0333
            },
            "Leposaviq": {
                "lat": 43.1,
                "lng": 20.8
            },
            "Zve\u00e7an": {
                "lat": 42.9,
                "lng": 20.8333
            },
            "Zubin Potok": {
                "lat": 42.9167,
                "lng": 20.6833
            },
            "Vitomiric\u00eb": {
                "lat": 42.6819,
                "lng": 20.3173
            },
            "Mati\u00e7an": {
                "lat": 42.6449,
                "lng": 21.1918
            },
            "Sht\u00ebrpc\u00eb": {
                "lat": 42.2333,
                "lng": 21.0167
            },
            "Gra\u00e7anic\u00eb": {
                "lat": 42.6,
                "lng": 21.2
            },
            "Hani i Elezit": {
                "lat": 42.1475,
                "lng": 21.2992
            },
            "Dushanov\u00eb": {
                "lat": 42.2347,
                "lng": 20.7091
            },
            "Zhur": {
                "lat": 42.1639,
                "lng": 20.6211
            },
            "Novob\u00ebrd\u00eb": {
                "lat": 42.6,
                "lng": 21.4333
            },
            "Obiliq": {
                "lat": 42.69,
                "lng": 21.0778
            },
            "Junik": {
                "lat": 42.4761,
                "lng": 20.2775
            },
            "Ranillug": {
                "lat": 42.492,
                "lng": 21.559
            },
            "Mamush\u00eb": {
                "lat": 42.3167,
                "lng": 20.7167
            },
            "De\u00e7an": {
                "lat": 42.5333,
                "lng": 20.2833
            },
            "Malishev\u00eb": {
                "lat": 42.4828,
                "lng": 20.7461
            },
            "Kllokot": {
                "lat": 42.3667,
                "lng": 21.3833
            },
            "Partesh": {
                "lat": 42.4019,
                "lng": 21.4336
            }
        }
    },
    "XW": {
        "name": "West Bank",
        "cities": {
            "Hebron": {
                "lat": 31.5286,
                "lng": 35.0969
            },
            "N\u0101blus": {
                "lat": 32.2222,
                "lng": 35.2611
            },
            "\u0162\u016blkarm": {
                "lat": 32.3125,
                "lng": 35.0267
            },
            "Yattir": {
                "lat": 31.4478,
                "lng": 35.09
            },
            "Qalq\u012blyah": {
                "lat": 32.1903,
                "lng": 34.9686
            },
            "Jan\u012bn": {
                "lat": 32.4611,
                "lng": 35.3
            },
            "Ramallah": {
                "lat": 31.9,
                "lng": 35.2
            },
            "Az\u0327 Z\u0327\u0101hir\u012byah": {
                "lat": 31.4078,
                "lng": 34.9722
            },
            "Bethlehem": {
                "lat": 31.7044,
                "lng": 35.2064
            },
            "Bayt\u016bny\u0101": {
                "lat": 31.8889,
                "lng": 35.1675
            },
            "Idhn\u0101": {
                "lat": 31.5586,
                "lng": 34.9761
            },
            "Qab\u0101\u0163\u012byah": {
                "lat": 32.4097,
                "lng": 35.2808
            },
            "Jericho": {
                "lat": 31.8561,
                "lng": 35.46
            },
            "Bayt \u016ammar": {
                "lat": 31.6214,
                "lng": 35.1022
            },
            "Ya\u2018bad": {
                "lat": 32.4467,
                "lng": 35.1703
            },
            "Bayt J\u0101l\u0101": {
                "lat": 31.715,
                "lng": 35.1872
            },
            "Bayt S\u0101\u1e29\u016br": {
                "lat": 31.7003,
                "lng": 35.225
            },
            "Ab\u016b D\u012bs": {
                "lat": 31.7625,
                "lng": 35.2658
            }
        }
    },
    "GY": {
        "name": "Guyana",
        "cities": {
            "Georgetown": {
                "lat": 6.8058,
                "lng": -58.1508
            },
            "Linden": {
                "lat": 6.0,
                "lng": -58.3
            },
            "New Amsterdam": {
                "lat": 6.25,
                "lng": -57.5167
            },
            "Anna Regina": {
                "lat": 7.2633,
                "lng": -58.4806
            },
            "Princetown": {
                "lat": 5.9049,
                "lng": -57.1391
            },
            "Bartica": {
                "lat": 6.4,
                "lng": -58.6167
            },
            "Lethem": {
                "lat": 3.3833,
                "lng": -59.8
            },
            "Mahdia": {
                "lat": 5.2825,
                "lng": -59.15
            },
            "Mabaruma": {
                "lat": 8.2,
                "lng": -59.7833
            },
            "Ituni": {
                "lat": 5.5333,
                "lng": -58.25
            },
            "Vreed-en-Hoop": {
                "lat": 6.8076,
                "lng": -58.1815
            },
            "Fort Wellington": {
                "lat": 6.4,
                "lng": -57.6167
            }
        }
    },
    "FJ": {
        "name": "Fiji",
        "cities": {
            "Suva": {
                "lat": -18.1416,
                "lng": 178.4419
            },
            "Nadi": {
                "lat": -17.8,
                "lng": 177.4167
            },
            "Nausori": {
                "lat": -18.0244,
                "lng": 178.5454
            },
            "Lautoka": {
                "lat": -17.6242,
                "lng": 177.4528
            },
            "Labasa": {
                "lat": -16.4311,
                "lng": 179.3703
            },
            "Lami": {
                "lat": -18.1167,
                "lng": 178.4167
            },
            "Nakasi": {
                "lat": -18.0667,
                "lng": 178.5167
            },
            "Ba": {
                "lat": -17.5333,
                "lng": 177.6833
            },
            "Sigatoka": {
                "lat": -18.1414,
                "lng": 177.5069
            }
        }
    },
    "NC": {
        "name": "New Caledonia",
        "cities": {
            "Noum\u00e9a": {
                "lat": -22.2758,
                "lng": 166.458
            },
            "Dumb\u00e9a": {
                "lat": -22.15,
                "lng": 166.45
            },
            "Mont-Dore": {
                "lat": -22.2157,
                "lng": 166.4665
            },
            "Pa\u00efta": {
                "lat": -22.1337,
                "lng": 166.3505
            },
            "Kon\u00e9": {
                "lat": -21.059,
                "lng": 164.8518
            },
            "W\u00e9": {
                "lat": -20.9167,
                "lng": 167.2653
            }
        }
    },
    "ME": {
        "name": "Montenegro",
        "cities": {
            "Podgorica": {
                "lat": 42.4414,
                "lng": 19.2628
            },
            "Nik\u0161i\u0107": {
                "lat": 42.7778,
                "lng": 18.95
            },
            "Ulcinj": {
                "lat": 41.92,
                "lng": 19.2
            },
            "Herceg Novi": {
                "lat": 42.453,
                "lng": 18.5312
            },
            "Pljevlja": {
                "lat": 43.3567,
                "lng": 19.3583
            },
            "Budva": {
                "lat": 42.2881,
                "lng": 18.8425
            },
            "Bar": {
                "lat": 42.1,
                "lng": 19.1
            },
            "Plav": {
                "lat": 42.6,
                "lng": 19.94
            },
            "Ro\u017eaje": {
                "lat": 42.84,
                "lng": 20.16
            },
            "Kotor": {
                "lat": 42.43,
                "lng": 18.77
            },
            "Bijelo Polje": {
                "lat": 43.04,
                "lng": 19.75
            },
            "Cetinje": {
                "lat": 42.38,
                "lng": 18.92
            },
            "Berane": {
                "lat": 42.84,
                "lng": 19.86
            },
            "Tivat": {
                "lat": 42.43,
                "lng": 18.7
            },
            "Petnjica": {
                "lat": 42.9089,
                "lng": 19.9644
            },
            "Danilovgrad": {
                "lat": 42.61,
                "lng": 19.05
            },
            "Tuzi": {
                "lat": 42.3656,
                "lng": 19.3314
            },
            "Mojkovac": {
                "lat": 42.96,
                "lng": 19.58
            },
            "Golubovci": {
                "lat": 42.3344,
                "lng": 19.2253
            },
            "Kola\u0161in": {
                "lat": 42.8236,
                "lng": 19.5225
            },
            "\u017dabljak": {
                "lat": 43.155,
                "lng": 19.1208
            },
            "Gusinje": {
                "lat": 42.5619,
                "lng": 19.8339
            },
            "Plu\u017eine": {
                "lat": 43.15,
                "lng": 18.83
            },
            "Andrijevica": {
                "lat": 42.73,
                "lng": 19.79
            },
            "\u0160avnik": {
                "lat": 42.95,
                "lng": 19.1
            }
        }
    },
    "CW": {
        "name": "Cura\u00e7ao",
        "cities": {
            "Willemstad": {
                "lat": 12.108,
                "lng": -68.935
            }
        }
    },
    "MU": {
        "name": "Mauritius",
        "cities": {
            "Port Louis": {
                "lat": -20.1644,
                "lng": 57.5042
            },
            "Curepipe": {
                "lat": -20.3188,
                "lng": 57.5263
            },
            "Quatre Bornes": {
                "lat": -20.2654,
                "lng": 57.4791
            },
            "Quartier Militaire": {
                "lat": -20.25,
                "lng": 57.55
            },
            "Goodlands": {
                "lat": -20.035,
                "lng": 57.6431
            },
            "Bel Air": {
                "lat": -20.2582,
                "lng": 57.7545
            },
            "Centre de Flacq": {
                "lat": -20.2002,
                "lng": 57.7177
            },
            "Bambous": {
                "lat": -20.26,
                "lng": 57.4148
            },
            "Le Hochet": {
                "lat": -20.135,
                "lng": 57.5211
            },
            "Baie du Tombeau": {
                "lat": -20.1138,
                "lng": 57.5066
            },
            "Rose Belle": {
                "lat": -20.4025,
                "lng": 57.6061
            },
            "Grand Baie": {
                "lat": -20.0131,
                "lng": 57.5844
            },
            "Lalmatie": {
                "lat": -20.0184,
                "lng": 57.5802
            },
            "Surinam": {
                "lat": -20.5139,
                "lng": 57.5111
            },
            "Plaine Magnien": {
                "lat": -20.4286,
                "lng": 57.6531
            },
            "Moka": {
                "lat": -20.219,
                "lng": 57.496
            }
        }
    },
    "IS": {
        "name": "Iceland",
        "cities": {
            "Reykjav\u00edk": {
                "lat": 64.1467,
                "lng": -21.94
            },
            "K\u00f3pavogur": {
                "lat": 64.1108,
                "lng": -21.9094
            },
            "Hafnarfj\u00f6r\u00f0ur": {
                "lat": 64.02,
                "lng": -21.97
            },
            "Akureyri": {
                "lat": 65.6833,
                "lng": -18.1
            },
            "Gar\u00f0ab\u00e6r": {
                "lat": 64.0902,
                "lng": -21.9264
            },
            "Selfoss": {
                "lat": 63.9333,
                "lng": -21.0
            },
            "\u00cdsafj\u00f6r\u00f0ur": {
                "lat": 66.0758,
                "lng": -23.1267
            },
            "Sau\u00f0\u00e1rkr\u00f3kur": {
                "lat": 65.7461,
                "lng": -19.6394
            },
            "H\u00f6fn": {
                "lat": 64.254,
                "lng": -15.212
            },
            "Egilssta\u00f0ir": {
                "lat": 65.2833,
                "lng": -14.3833
            },
            "Borgarnes": {
                "lat": 64.5333,
                "lng": -21.9167
            },
            "Keflav\u00edk": {
                "lat": 64.0167,
                "lng": -22.5667
            }
        }
    },
    "MV": {
        "name": "Maldives",
        "cities": {
            "Male": {
                "lat": 4.1753,
                "lng": 73.5089
            },
            "Kulhudhuffushi": {
                "lat": 6.6225,
                "lng": 73.0703
            },
            "Hithadhoo": {
                "lat": -0.6094,
                "lng": 73.0899
            },
            "Foammulah": {
                "lat": -0.2932,
                "lng": 73.4236
            },
            "Thinadhoo": {
                "lat": 0.5302,
                "lng": 72.9965
            },
            "Naifaru": {
                "lat": 5.4444,
                "lng": 73.3657
            },
            "Kudahuvadhoo": {
                "lat": 2.6711,
                "lng": 72.8935
            },
            "Mahibadhoo": {
                "lat": 3.7575,
                "lng": 72.9686
            },
            "Viligili": {
                "lat": 0.7569,
                "lng": 73.4361
            },
            "Eydhafushi": {
                "lat": 5.1038,
                "lng": 73.0704
            },
            "Funadhoo": {
                "lat": 6.1482,
                "lng": 73.29
            },
            "Manadhoo": {
                "lat": 5.7628,
                "lng": 73.4106
            },
            "Ungoofaaru": {
                "lat": 5.6681,
                "lng": 73.0306
            },
            "Veymandoo": {
                "lat": 2.1881,
                "lng": 73.0947
            },
            "Felidhoo": {
                "lat": 3.4717,
                "lng": 73.5472
            },
            "Rasdhoo": {
                "lat": 4.2631,
                "lng": 72.9919
            },
            "Muli": {
                "lat": 2.9217,
                "lng": 73.5811
            },
            "Dhihdhoo": {
                "lat": 6.8874,
                "lng": 73.114
            },
            "Fonadhoo": {
                "lat": 1.8342,
                "lng": 73.5031
            },
            "Nilandhoo": {
                "lat": 3.0567,
                "lng": 72.89
            },
            "Thulusdhoo": {
                "lat": 4.3742,
                "lng": 73.6517
            }
        }
    },
    "LU": {
        "name": "Luxembourg",
        "cities": {
            "Luxembourg": {
                "lat": 49.6117,
                "lng": 6.1319
            },
            "Esch-sur-Alzette": {
                "lat": 49.4969,
                "lng": 5.9806
            },
            "Dudelange": {
                "lat": 49.4833,
                "lng": 6.0833
            },
            "Differdange": {
                "lat": 49.5222,
                "lng": 5.8917
            },
            "Diekirch": {
                "lat": 49.8681,
                "lng": 6.1567
            },
            "Echternach": {
                "lat": 49.8117,
                "lng": 6.4217
            },
            "Wiltz": {
                "lat": 49.9661,
                "lng": 5.9325
            },
            "Grevenmacher": {
                "lat": 49.6806,
                "lng": 6.4417
            },
            "Remich": {
                "lat": 49.5444,
                "lng": 6.3667
            },
            "Vianden": {
                "lat": 49.9,
                "lng": 6.2
            },
            "Capellen": {
                "lat": 49.6444,
                "lng": 5.9897
            },
            "Clervaux": {
                "lat": 50.05,
                "lng": 6.0333
            },
            "Mersch": {
                "lat": 49.75,
                "lng": 6.1
            },
            "Redange-sur-Attert": {
                "lat": 49.7652,
                "lng": 5.8894
            }
        }
    },
    "PF": {
        "name": "French Polynesia",
        "cities": {
            "Papeete": {
                "lat": -17.5334,
                "lng": -149.5667
            }
        }
    },
    "GU": {
        "name": "Guam",
        "cities": {
            "Maina": {
                "lat": 13.4692,
                "lng": 144.7332
            },
            "Hag\u00e5t\u00f1a": {
                "lat": 13.4745,
                "lng": 144.7504
            }
        }
    },
    "BT": {
        "name": "Bhutan",
        "cities": {
            "Thimphu": {
                "lat": 27.4722,
                "lng": 89.6361
            },
            "Phuntsholing": {
                "lat": 26.85,
                "lng": 89.3833
            },
            "Paro": {
                "lat": 27.4333,
                "lng": 89.4167
            },
            "Geylegphug": {
                "lat": 26.8706,
                "lng": 90.4856
            },
            "Samdrup Jongkhar": {
                "lat": 26.8,
                "lng": 91.505
            },
            "Wangdue Phodrang": {
                "lat": 27.4833,
                "lng": 89.9
            },
            "Punakha": {
                "lat": 27.5833,
                "lng": 89.8583
            },
            "Jakar": {
                "lat": 27.55,
                "lng": 90.7333
            },
            "Samtse": {
                "lat": 27.0333,
                "lng": 89.0833
            },
            "Mongar": {
                "lat": 27.275,
                "lng": 91.24
            },
            "Trashigang": {
                "lat": 27.3326,
                "lng": 91.5528
            },
            "Trashi Yangtse": {
                "lat": 27.5833,
                "lng": 91.4667
            },
            "Trongsa": {
                "lat": 27.4994,
                "lng": 90.5047
            },
            "Zhemgang": {
                "lat": 27.2134,
                "lng": 90.655
            },
            "Daga": {
                "lat": 27.0667,
                "lng": 89.8833
            },
            "Sarpang": {
                "lat": 26.8639,
                "lng": 90.2674
            },
            "Pemagatshel": {
                "lat": 27.0333,
                "lng": 91.45
            },
            "Tsimasham": {
                "lat": 27.0989,
                "lng": 89.536
            },
            "Gasa": {
                "lat": 27.9167,
                "lng": 89.6833
            },
            "Haa": {
                "lat": 27.3825,
                "lng": 89.285
            },
            "Lhuentse": {
                "lat": 27.65,
                "lng": 91.15
            },
            "Tsirang": {
                "lat": 27.0219,
                "lng": 90.1229
            }
        }
    },
    "TG": {
        "name": "Togo",
        "cities": {
            "Sokod\u00e9": {
                "lat": 8.9833,
                "lng": 1.1333
            },
            "Kpalim\u00e9": {
                "lat": 6.9,
                "lng": 0.6333
            },
            "Atakpam\u00e9": {
                "lat": 7.5269,
                "lng": 1.1267
            },
            "Dapaong": {
                "lat": 10.8667,
                "lng": 0.2
            },
            "Ts\u00e9vi\u00e9": {
                "lat": 6.4333,
                "lng": 1.2167
            },
            "Nots\u00e9": {
                "lat": 6.9483,
                "lng": 1.1681
            },
            "Tabligbo": {
                "lat": 6.5833,
                "lng": 1.5
            },
            "Sansann\u00e9-Mango": {
                "lat": 10.3556,
                "lng": 0.4756
            },
            "Lom\u00e9": {
                "lat": 6.1319,
                "lng": 1.2228
            },
            "Sotouboua": {
                "lat": 8.5667,
                "lng": 0.9833
            },
            "An\u00e9ho": {
                "lat": 6.2333,
                "lng": 1.6
            },
            "Bafilo": {
                "lat": 9.35,
                "lng": 1.2667
            },
            "Bassar": {
                "lat": 9.25,
                "lng": 0.7833
            },
            "Niamtougou": {
                "lat": 9.7667,
                "lng": 1.1
            },
            "Afanyangan": {
                "lat": 6.4939,
                "lng": 1.6339
            },
            "K\u00e9v\u00e9": {
                "lat": 6.4278,
                "lng": 0.9245
            },
            "Badou": {
                "lat": 7.5833,
                "lng": 0.6
            },
            "Blitta": {
                "lat": 8.3167,
                "lng": 0.9833
            },
            "Sirka": {
                "lat": 9.5719,
                "lng": 1.3183
            },
            "Kara": {
                "lat": 9.5511,
                "lng": 1.1861
            }
        }
    },
    "SZ": {
        "name": "Eswatini",
        "cities": {
            "Manzini": {
                "lat": -26.4833,
                "lng": 31.3667
            },
            "Mbabane": {
                "lat": -26.3167,
                "lng": 31.1333
            },
            "Siteki": {
                "lat": -26.45,
                "lng": 31.95
            },
            "Lobamba": {
                "lat": -26.4465,
                "lng": 31.2064
            },
            "Piggs Peak": {
                "lat": -25.961,
                "lng": 31.247
            },
            "Lavumisa": {
                "lat": -27.3167,
                "lng": 31.9
            },
            "Hlatikulu": {
                "lat": -26.9667,
                "lng": 31.3167
            },
            "Nhlangano": {
                "lat": -27.1167,
                "lng": 31.2
            }
        }
    },
    "BB": {
        "name": "Barbados",
        "cities": {
            "Bridgetown": {
                "lat": 13.0975,
                "lng": -59.6167
            }
        }
    },
    "TT": {
        "name": "Trinidad and Tobago",
        "cities": {
            "Chaguanas": {
                "lat": 10.5167,
                "lng": -61.4
            },
            "San Fernando": {
                "lat": 10.2833,
                "lng": -61.4667
            },
            "Port of Spain": {
                "lat": 10.6667,
                "lng": -61.5167
            },
            "Arima": {
                "lat": 10.6333,
                "lng": -61.2833
            },
            "Couva": {
                "lat": 10.4167,
                "lng": -61.45
            },
            "Point Fortin": {
                "lat": 10.1667,
                "lng": -61.6667
            },
            "Princes Town": {
                "lat": 10.2667,
                "lng": -61.3833
            },
            "Diego Martin": {
                "lat": 10.7167,
                "lng": -61.5667
            },
            "Laventille": {
                "lat": 10.65,
                "lng": -61.4833
            },
            "Sangre Grande": {
                "lat": 10.5667,
                "lng": -61.1333
            },
            "Tunapuna": {
                "lat": 10.6333,
                "lng": -61.3833
            },
            "Scarborough": {
                "lat": 11.1833,
                "lng": -60.7375
            },
            "Gasparillo": {
                "lat": 10.3167,
                "lng": -61.4167
            },
            "Claxton Bay": {
                "lat": 10.3405,
                "lng": -61.4555
            },
            "Saint Joseph": {
                "lat": 10.6556,
                "lng": -61.4167
            },
            "San Juan": {
                "lat": 10.65,
                "lng": -61.45
            },
            "Fyzabad": {
                "lat": 10.1833,
                "lng": -61.55
            },
            "Marabella": {
                "lat": 10.3,
                "lng": -61.45
            },
            "Valencia": {
                "lat": 10.65,
                "lng": -61.2
            },
            "Penal": {
                "lat": 10.1667,
                "lng": -61.4667
            },
            "Freeport": {
                "lat": 10.45,
                "lng": -61.4167
            },
            "Arouca": {
                "lat": 10.6333,
                "lng": -61.3333
            },
            "Siparia": {
                "lat": 10.1333,
                "lng": -61.5
            },
            "Rio Claro": {
                "lat": 10.3042,
                "lng": -61.1708
            },
            "Cunupia": {
                "lat": 10.55,
                "lng": -61.3667
            },
            "Debe": {
                "lat": 10.2,
                "lng": -61.45
            },
            "Aranguez": {
                "lat": 10.6472,
                "lng": -61.4461
            }
        }
    },
    "SB": {
        "name": "Solomon Islands",
        "cities": {
            "Honiara": {
                "lat": -9.4319,
                "lng": 159.9556
            },
            "Auki": {
                "lat": -8.7667,
                "lng": 160.7
            },
            "Gizo": {
                "lat": -8.1056,
                "lng": 156.8389
            },
            "Kirakira": {
                "lat": -10.45,
                "lng": 161.9167
            },
            "Buala": {
                "lat": -8.1333,
                "lng": 159.5833
            },
            "Tulagi": {
                "lat": -9.1014,
                "lng": 160.1467
            },
            "Tigoa": {
                "lat": -11.5583,
                "lng": 160.0706
            },
            "Lata": {
                "lat": -10.7167,
                "lng": 165.8333
            },
            "Taro": {
                "lat": -6.7111,
                "lng": 156.3972
            }
        }
    },
    "YT": {
        "name": "Mayotte",
        "cities": {
            "Mamoudzou": {
                "lat": -12.7806,
                "lng": 45.2278
            },
            "Koungou": {
                "lat": -12.7385,
                "lng": 45.2071
            },
            "Dembeni": {
                "lat": -12.8339,
                "lng": 45.1725
            },
            "Bandraboua": {
                "lat": -12.7254,
                "lng": 45.1072
            },
            "Tsingoni": {
                "lat": -12.7858,
                "lng": 45.1113
            },
            "Sada": {
                "lat": -12.8511,
                "lng": 45.0997
            },
            "Bandrele": {
                "lat": -12.9092,
                "lng": 45.1944
            },
            "Ouangani": {
                "lat": -12.8487,
                "lng": 45.1446
            },
            "Chirongui": {
                "lat": -12.9344,
                "lng": 45.1511
            }
        }
    },
    "CV": {
        "name": "Cabo Verde",
        "cities": {
            "Mindelo": {
                "lat": 16.886,
                "lng": -24.988
            },
            "Praia": {
                "lat": 14.918,
                "lng": -23.509
            },
            "S\u00e3o Filipe": {
                "lat": 14.895,
                "lng": -24.498
            },
            "Ribeira Grande": {
                "lat": 17.183,
                "lng": -25.065
            },
            "Tarrafal": {
                "lat": 16.566,
                "lng": -24.357
            },
            "Porto Novo": {
                "lat": 17.019,
                "lng": -25.064
            },
            "Assomada": {
                "lat": 15.096,
                "lng": -23.667
            },
            "Pedra Badejo": {
                "lat": 15.137,
                "lng": -23.534
            },
            "Sal Rei": {
                "lat": 16.177,
                "lng": -22.918
            },
            "Espargos": {
                "lat": 16.756,
                "lng": -22.946
            },
            "Ponta do Sol": {
                "lat": 17.202,
                "lng": -25.093
            },
            "Calheta de S\u00e3o Miguel": {
                "lat": 15.186,
                "lng": -23.593
            },
            "Porto Ingl\u00eas": {
                "lat": 15.138,
                "lng": -23.211
            },
            "S\u00e3o Domingos": {
                "lat": 15.028,
                "lng": -23.563
            },
            "Cidade Velha": {
                "lat": 14.916,
                "lng": -23.606
            },
            "Picos": {
                "lat": 15.083,
                "lng": -23.632
            },
            "Jo\u00e3o Teves": {
                "lat": 15.068,
                "lng": -23.589
            },
            "Igreja": {
                "lat": 15.0339,
                "lng": -24.325
            },
            "Ribeira Brava": {
                "lat": 16.615,
                "lng": -24.299
            },
            "Nova Sintra": {
                "lat": 14.871,
                "lng": -24.696
            },
            "Pombas": {
                "lat": 17.149,
                "lng": -25.015
            },
            "Cova Figueira": {
                "lat": 14.89,
                "lng": -24.294
            }
        }
    },
    "LC": {
        "name": "Saint Lucia",
        "cities": {
            "Castries": {
                "lat": 14.0167,
                "lng": -60.9833
            },
            "Gros Islet": {
                "lat": 14.081,
                "lng": -60.953
            },
            "Micoud": {
                "lat": 13.819,
                "lng": -60.9
            },
            "Vieux Fort": {
                "lat": 13.728,
                "lng": -60.954
            },
            "Bisee": {
                "lat": 14.0243,
                "lng": -60.9744
            }
        }
    },
    "ST": {
        "name": "Sao Tome and Principe",
        "cities": {
            "S\u00e3o Tom\u00e9": {
                "lat": 0.3361,
                "lng": 6.7306
            },
            "Santana": {
                "lat": 0.2583,
                "lng": 6.7417
            },
            "Neves": {
                "lat": 0.3592,
                "lng": 6.5517
            },
            "Guadalupe": {
                "lat": 0.38,
                "lng": 6.6381
            },
            "Trindade": {
                "lat": 0.3,
                "lng": 6.6833
            },
            "S\u00e3o Jo\u00e3o dos Angolares": {
                "lat": 0.1333,
                "lng": 6.65
            },
            "Santo Ant\u00f3nio": {
                "lat": 1.6367,
                "lng": 7.4178
            }
        }
    },
    "GF": {
        "name": "French Guiana",
        "cities": {
            "Cayenne": {
                "lat": 4.933,
                "lng": -52.33
            },
            "Saint-Laurent-du-Maroni": {
                "lat": 5.4976,
                "lng": -54.0325
            },
            "Kourou": {
                "lat": 5.16,
                "lng": -52.6499
            },
            "Sinnamary": {
                "lat": 5.38,
                "lng": -52.96
            },
            "Saint-Georges": {
                "lat": 3.9105,
                "lng": -51.81
            },
            "Roura": {
                "lat": 4.73,
                "lng": -52.33
            },
            "Iracoubo": {
                "lat": 5.4804,
                "lng": -53.22
            }
        }
    },
    "BZ": {
        "name": "Belize",
        "cities": {
            "Belize City": {
                "lat": 17.4986,
                "lng": -88.1886
            },
            "San Ignacio": {
                "lat": 17.1588,
                "lng": -89.0696
            },
            "Belmopan": {
                "lat": 17.2514,
                "lng": -88.7669
            },
            "Orange Walk": {
                "lat": 18.075,
                "lng": -88.5583
            },
            "Corozal": {
                "lat": 18.4,
                "lng": -88.4
            },
            "San Pedro": {
                "lat": 17.9214,
                "lng": -87.9611
            },
            "Dangriga": {
                "lat": 16.9667,
                "lng": -88.2167
            },
            "Punta Gorda": {
                "lat": 16.1005,
                "lng": -88.8074
            }
        }
    },
    "BN": {
        "name": "Brunei",
        "cities": {
            "Bandar Seri Begawan": {
                "lat": 4.8903,
                "lng": 114.9422
            },
            "Seria": {
                "lat": 4.6141,
                "lng": 114.3302
            },
            "Kampong Tunah Jambu": {
                "lat": 4.9957,
                "lng": 115.0019
            },
            "Kuala Belait": {
                "lat": 4.5828,
                "lng": 114.1918
            },
            "Bangar": {
                "lat": 4.7086,
                "lng": 115.0739
            },
            "Tutong": {
                "lat": 4.8067,
                "lng": 114.6592
            }
        }
    },
    "VU": {
        "name": "Vanuatu",
        "cities": {
            "Port-Vila": {
                "lat": -17.7333,
                "lng": 168.3167
            },
            "Luganville": {
                "lat": -15.5333,
                "lng": 167.1667
            },
            "Isangel": {
                "lat": -19.53,
                "lng": 169.28
            },
            "Lakatoro": {
                "lat": -16.1069,
                "lng": 167.4208
            },
            "Sola": {
                "lat": -13.875,
                "lng": 167.55
            },
            "Saratamata": {
                "lat": -15.2875,
                "lng": 167.9903
            }
        }
    },
    "WS": {
        "name": "Samoa",
        "cities": {
            "Apia": {
                "lat": -13.8333,
                "lng": -171.75
            },
            "Afega": {
                "lat": -13.8019,
                "lng": -171.8217
            },
            "Safotu": {
                "lat": -13.4528,
                "lng": -172.4036
            },
            "Leulumoega": {
                "lat": -13.8167,
                "lng": -171.9167
            },
            "Asau": {
                "lat": -13.5194,
                "lng": -172.6372
            },
            "Lufilufi": {
                "lat": -13.85,
                "lng": -171.5833
            },
            "Vailoa": {
                "lat": -13.7558,
                "lng": -172.3064
            },
            "Saleaula": {
                "lat": -13.4501,
                "lng": -172.3335
            },
            "Samamea": {
                "lat": -13.9333,
                "lng": -171.5333
            },
            "Safotulafai": {
                "lat": -13.6817,
                "lng": -172.185
            },
            "Mulifanua": {
                "lat": -13.8333,
                "lng": -171.9833
            },
            "Satupa\u2018itea": {
                "lat": -13.7659,
                "lng": -172.3269
            }
        }
    },
    "MC": {
        "name": "Monaco",
        "cities": {
            "Monaco": {
                "lat": 43.7333,
                "lng": 7.4167
            }
        }
    },
    "AW": {
        "name": "Aruba",
        "cities": {
            "Oranjestad": {
                "lat": 12.5186,
                "lng": -70.0358
            },
            "Tanki Leendert": {
                "lat": 12.5418,
                "lng": -70.022
            }
        }
    },
    "GI": {
        "name": "Gibraltar",
        "cities": {
            "Gibraltar": {
                "lat": 36.14,
                "lng": -5.35
            }
        }
    },
    "JE": {
        "name": "Jersey",
        "cities": {
            "Saint Helier": {
                "lat": 49.1858,
                "lng": -2.11
            }
        }
    },
    "MH": {
        "name": "Marshall Islands",
        "cities": {
            "Majuro": {
                "lat": 7.0833,
                "lng": 171.3833
            }
        }
    },
    "KM": {
        "name": "Comoros",
        "cities": {
            "Mutsamudu": {
                "lat": -12.1675,
                "lng": 44.3939
            },
            "Ouani": {
                "lat": -12.135,
                "lng": 44.4275
            },
            "Mandza": {
                "lat": -11.4167,
                "lng": 43.3
            },
            "Fomboni": {
                "lat": -12.28,
                "lng": 43.7425
            },
            "Moroni": {
                "lat": -11.699,
                "lng": 43.256
            },
            "Domoni": {
                "lat": -12.2586,
                "lng": 44.5303
            },
            "Adda-Dou\u00e9ni": {
                "lat": -12.3,
                "lng": 44.5
            },
            "Kourani": {
                "lat": -11.8511,
                "lng": 43.4261
            },
            "Bazimini": {
                "lat": -12.1833,
                "lng": 44.45
            }
        }
    },
    "KI": {
        "name": "Kiribati",
        "cities": {
            "Tarawa": {
                "lat": 1.3382,
                "lng": 173.0176
            },
            "Betio": {
                "lat": 1.35,
                "lng": 172.9333
            }
        }
    },
    "IM": {
        "name": "Isle of Man",
        "cities": {
            "Douglas": {
                "lat": 54.15,
                "lng": -4.4775
            },
            "Onchan": {
                "lat": 54.175,
                "lng": -4.455
            }
        }
    },
    "KY": {
        "name": "Cayman Islands",
        "cities": {
            "George Town": {
                "lat": 19.2866,
                "lng": -81.3744
            }
        }
    },
    "SC": {
        "name": "Seychelles",
        "cities": {
            "Victoria": {
                "lat": -4.6231,
                "lng": 55.4525
            }
        }
    },
    "VC": {
        "name": "Saint Vincent and the Grenadines",
        "cities": {
            "Calliaqua": {
                "lat": 13.1308,
                "lng": -61.1928
            },
            "Kingstown": {
                "lat": 13.1578,
                "lng": -61.225
            }
        }
    },
    "TO": {
        "name": "Tonga",
        "cities": {
            "Nuku\u2018alofa": {
                "lat": -21.1333,
                "lng": -175.2
            },
            "Neiafu": {
                "lat": -18.6508,
                "lng": -173.9831
            }
        }
    },
    "AD": {
        "name": "Andorra",
        "cities": {
            "Andorra la Vella": {
                "lat": 42.5,
                "lng": 1.5
            },
            "Escaldes-Engordany": {
                "lat": 42.5089,
                "lng": 1.5408
            },
            "Encamp": {
                "lat": 42.5361,
                "lng": 1.5828
            },
            "La Massana": {
                "lat": 42.5444,
                "lng": 1.5144
            },
            "Sant Juli\u00e0 de L\u00f2ria": {
                "lat": 42.465,
                "lng": 1.4911
            },
            "Canillo": {
                "lat": 42.5667,
                "lng": 1.6
            },
            "Ordino": {
                "lat": 42.555,
                "lng": 1.5331
            }
        }
    },
    "AG": {
        "name": "Antigua and Barbuda",
        "cities": {
            "Saint John\u2019s": {
                "lat": 17.1167,
                "lng": -61.85
            }
        }
    },
    "GG": {
        "name": "Guernsey",
        "cities": {
            "Saint Peter Port": {
                "lat": 49.4555,
                "lng": -2.5368
            },
            "Saint Sampson": {
                "lat": 49.4819,
                "lng": -2.5411
            }
        }
    },
    "GL": {
        "name": "Greenland",
        "cities": {
            "Nuuk": {
                "lat": 64.1814,
                "lng": -51.6942
            },
            "Sisimiut": {
                "lat": 66.9389,
                "lng": -53.6722
            },
            "Ilulissat": {
                "lat": 69.2167,
                "lng": -51.1
            },
            "Aasiaat": {
                "lat": 68.7097,
                "lng": -52.8694
            },
            "Qaqortoq": {
                "lat": 60.7222,
                "lng": -46.0403
            },
            "Tasiilaq": {
                "lat": 65.6136,
                "lng": -37.6311
            },
            "Uummannaq": {
                "lat": 70.6747,
                "lng": -52.1264
            },
            "Paamiut": {
                "lat": 61.9944,
                "lng": -49.6667
            },
            "Upernavik": {
                "lat": 72.7869,
                "lng": -56.1472
            },
            "Qasigiannguit": {
                "lat": 68.8201,
                "lng": -51.1932
            },
            "Godhavn": {
                "lat": 69.2472,
                "lng": -53.5333
            },
            "Qaanaaq": {
                "lat": 77.4667,
                "lng": -69.2306
            },
            "Kullorsuaq": {
                "lat": 74.5792,
                "lng": -57.2181
            },
            "Scoresbysund": {
                "lat": 70.4853,
                "lng": -21.9667
            },
            "Tasiusaq": {
                "lat": 73.3689,
                "lng": -56.0556
            },
            "Kulusuk": {
                "lat": 65.5753,
                "lng": -37.1833
            },
            "Kraulshavn": {
                "lat": 74.1111,
                "lng": -57.0611
            },
            "Kangersuatsiaq": {
                "lat": 72.3797,
                "lng": -55.55
            },
            "Narsarsuaq": {
                "lat": 61.1458,
                "lng": -45.4347
            },
            "Savissivik": {
                "lat": 76.0194,
                "lng": -65.1139
            },
            "Nord": {
                "lat": 81.7166,
                "lng": -17.8
            },
            "Timmiarmiut": {
                "lat": 62.5333,
                "lng": -42.2167
            }
        }
    },
    "DM": {
        "name": "Dominica",
        "cities": {
            "Roseau": {
                "lat": 15.3014,
                "lng": -61.3883
            }
        }
    },
    "FM": {
        "name": "Micronesia, Federated States of",
        "cities": {
            "Weno": {
                "lat": 7.4417,
                "lng": 151.8583
            },
            "Palikir": {
                "lat": 6.9172,
                "lng": 158.1589
            },
            "Kolonia": {
                "lat": 6.9639,
                "lng": 158.2081
            },
            "Colonia": {
                "lat": 9.5167,
                "lng": 138.1333
            },
            "Tofol": {
                "lat": 5.3258,
                "lng": 163.0086
            }
        }
    },
    "FO": {
        "name": "Faroe Islands",
        "cities": {
            "T\u00f3rshavn": {
                "lat": 62.0,
                "lng": -6.7833
            },
            "Klaksv\u00edk": {
                "lat": 62.2375,
                "lng": -6.539
            },
            "Hvalba": {
                "lat": 61.6,
                "lng": -6.9556
            },
            "Skopun": {
                "lat": 61.9125,
                "lng": -6.8719
            },
            "Hvannasund": {
                "lat": 62.2833,
                "lng": -6.5167
            },
            "Porkeri": {
                "lat": 61.4814,
                "lng": -6.7458
            },
            "F\u00e1mjin": {
                "lat": 61.5264,
                "lng": -6.8769
            },
            "Sandur": {
                "lat": 61.8344,
                "lng": -6.8171
            },
            "Fuglafj\u00f8r\u00f0ur": {
                "lat": 62.2448,
                "lng": -6.815
            },
            "Hov": {
                "lat": 61.5068,
                "lng": -6.7599
            },
            "V\u00e1gur": {
                "lat": 61.4733,
                "lng": -6.8175
            },
            "Saltangar\u00e1": {
                "lat": 62.1156,
                "lng": -6.7206
            },
            "Kv\u00edv\u00edk": {
                "lat": 62.1186,
                "lng": -7.0737
            },
            "Sumba": {
                "lat": 61.4055,
                "lng": -6.709
            },
            "Vi\u00f0arei\u00f0i": {
                "lat": 62.36,
                "lng": -6.5313
            },
            "Nor\u00f0rag\u00f8ta": {
                "lat": 62.199,
                "lng": -6.7432
            },
            "Toftir": {
                "lat": 62.0978,
                "lng": -6.7369
            },
            "Kirkja": {
                "lat": 62.3263,
                "lng": -6.3238
            },
            "Ei\u00f0i": {
                "lat": 62.2995,
                "lng": -7.0924
            },
            "Sandav\u00e1gur": {
                "lat": 62.0537,
                "lng": -7.1498
            },
            "Sk\u00favoy": {
                "lat": 61.771,
                "lng": -6.805
            },
            "Sk\u00e1lav\u00edk": {
                "lat": 61.8314,
                "lng": -6.6623
            },
            "S\u00f8rv\u00e1gur": {
                "lat": 62.0717,
                "lng": -7.3066
            },
            "Vestmanna": {
                "lat": 62.1548,
                "lng": -7.169
            },
            "Strendur": {
                "lat": 62.1096,
                "lng": -6.7617
            },
            "Tv\u00f8royri": {
                "lat": 61.5544,
                "lng": -6.8063
            },
            "H\u00fasav\u00edk": {
                "lat": 61.8099,
                "lng": -6.6813
            },
            "Kunoy": {
                "lat": 62.2917,
                "lng": -6.6702
            },
            "Oyrarbakki": {
                "lat": 62.2079,
                "lng": -6.9997
            }
        }
    },
    "KN": {
        "name": "Saint Kitts and Nevis",
        "cities": {
            "Basseterre": {
                "lat": 17.3,
                "lng": -62.7333
            }
        }
    },
    "VG": {
        "name": "Virgin Islands, British",
        "cities": {
            "Road Town": {
                "lat": 18.4167,
                "lng": -64.6167
            }
        }
    },
    "AS": {
        "name": "American Samoa",
        "cities": {
            "Pago Pago": {
                "lat": -14.274,
                "lng": -170.7046
            }
        }
    },
    "GD": {
        "name": "Grenada",
        "cities": {
            "Saint David\u2019s": {
                "lat": 12.0444,
                "lng": -61.6806
            },
            "Saint George\u2019s": {
                "lat": 12.05,
                "lng": -61.75
            }
        }
    },
    "SM": {
        "name": "San Marino",
        "cities": {
            "Serravalle": {
                "lat": 43.9717,
                "lng": 12.4817
            },
            "Borgo Maggiore": {
                "lat": 43.945,
                "lng": 12.4497
            },
            "San Marino": {
                "lat": 43.9346,
                "lng": 12.4473
            },
            "Domagnano": {
                "lat": 43.95,
                "lng": 12.4667
            },
            "Fiorentino": {
                "lat": 43.9106,
                "lng": 12.4556
            },
            "Acquaviva": {
                "lat": 43.9457,
                "lng": 12.4185
            },
            "Faetano": {
                "lat": 43.9256,
                "lng": 12.4983
            },
            "Montegiardino": {
                "lat": 43.9089,
                "lng": 12.4844
            },
            "Chiesanuova": {
                "lat": 43.9061,
                "lng": 12.4214
            }
        }
    },
    "BQ": {
        "name": "Bonaire, Sint Eustatius, and Saba",
        "cities": {
            "Kralendijk": {
                "lat": 12.1444,
                "lng": -68.2656
            },
            "Oranjestad": {
                "lat": 17.4833,
                "lng": -62.9833
            },
            "The Bottom": {
                "lat": 17.6261,
                "lng": -63.2492
            }
        }
    },
    "PW": {
        "name": "Palau",
        "cities": {
            "Koror": {
                "lat": 7.3419,
                "lng": 134.4792
            },
            "Melekeok": {
                "lat": 7.5006,
                "lng": 134.6241
            },
            "Ngerulmud": {
                "lat": 7.5006,
                "lng": 134.6242
            }
        }
    },
    "TV": {
        "name": "Tuvalu",
        "cities": {
            "Funafuti": {
                "lat": -8.5167,
                "lng": 179.2
            }
        }
    },
    "LI": {
        "name": "Liechtenstein",
        "cities": {
            "Schaan": {
                "lat": 47.1667,
                "lng": 9.5097
            },
            "Vaduz": {
                "lat": 47.141,
                "lng": 9.521
            },
            "Triesen": {
                "lat": 47.1,
                "lng": 9.5333
            },
            "Balzers": {
                "lat": 47.0667,
                "lng": 9.5
            },
            "Eschen": {
                "lat": 47.2167,
                "lng": 9.5167
            },
            "Mauren": {
                "lat": 47.2167,
                "lng": 9.5333
            },
            "Triesenberg": {
                "lat": 47.1181,
                "lng": 9.5433
            },
            "Ruggell": {
                "lat": 47.245,
                "lng": 9.5332
            },
            "Gamprin": {
                "lat": 47.2167,
                "lng": 9.5
            },
            "Schellenberg": {
                "lat": 47.2336,
                "lng": 9.5481
            },
            "Planken": {
                "lat": 47.1833,
                "lng": 9.5333
            }
        }
    },
    "MF": {
        "name": "Saint Martin",
        "cities": {
            "Marigot": {
                "lat": 18.0706,
                "lng": -63.0847
            }
        }
    },
    "PM": {
        "name": "Saint Pierre and Miquelon",
        "cities": {
            "Saint-Pierre": {
                "lat": 46.7778,
                "lng": -56.1778
            }
        }
    },
    "CK": {
        "name": "Cook Islands",
        "cities": {
            "Avarua": {
                "lat": -21.207,
                "lng": -159.771
            }
        }
    },
    "TC": {
        "name": "Turks and Caicos Islands",
        "cities": {
            "Grand Turk": {
                "lat": 21.459,
                "lng": -71.139
            }
        }
    },
    "AI": {
        "name": "Anguilla",
        "cities": {
            "The Valley": {
                "lat": 18.2208,
                "lng": -63.0517
            }
        }
    },
    "MP": {
        "name": "Northern Mariana Islands",
        "cities": {
            "Capitol Hill": {
                "lat": 15.2137,
                "lng": 145.7546
            }
        }
    },
    "BL": {
        "name": "Saint Barthelemy",
        "cities": {
            "Gustavia": {
                "lat": 17.8979,
                "lng": -62.8506
            }
        }
    },
    "FK": {
        "name": "Falkland Islands (Islas Malvinas)",
        "cities": {
            "Stanley": {
                "lat": -51.7,
                "lng": -57.85
            }
        }
    },
    "SX": {
        "name": "Sint Maarten",
        "cities": {
            "Philipsburg": {
                "lat": 18.0237,
                "lng": -63.0458
            }
        }
    },
    "XR": {
        "name": "Svalbard",
        "cities": {
            "Longyearbyen": {
                "lat": 78.22,
                "lng": 15.65
            }
        }
    },
    "CX": {
        "name": "Christmas Island",
        "cities": {
            "Flying Fish Cove": {
                "lat": -10.4261,
                "lng": 105.6728
            }
        }
    },
    "WF": {
        "name": "Wallis and Futuna",
        "cities": {
            "Mata-Utu": {
                "lat": -13.2833,
                "lng": -176.1833
            },
            "Leava": {
                "lat": -14.2961,
                "lng": -178.1585
            }
        }
    },
    "BM": {
        "name": "Bermuda",
        "cities": {
            "Hamilton": {
                "lat": 32.293,
                "lng": -64.782
            }
        }
    },
    "VA": {
        "name": "Vatican City",
        "cities": {
            "Vatican City": {
                "lat": 41.904,
                "lng": 12.453
            }
        }
    },
    "NR": {
        "name": "Nauru",
        "cities": {
            "Yaren": {
                "lat": -0.5477,
                "lng": 166.9209
            }
        }
    },
    "SH": {
        "name": "Saint Helena, Ascension, and Tristan da Cunha",
        "cities": {
            "Jamestown": {
                "lat": -15.9251,
                "lng": -5.7179
            },
            "Edinburgh of the Seven Seas": {
                "lat": -37.0675,
                "lng": -12.3105
            },
            "Georgetown": {
                "lat": -7.9286,
                "lng": -14.4119
            }
        }
    },
    "NU": {
        "name": "Niue",
        "cities": {
            "Alofi": {
                "lat": -19.056,
                "lng": -169.921
            }
        }
    },
    "MS": {
        "name": "Montserrat",
        "cities": {
            "Brades": {
                "lat": 16.7928,
                "lng": -62.2106
            },
            "Plymouth": {
                "lat": 16.7064,
                "lng": -62.2158
            }
        }
    },
    "NF": {
        "name": "Norfolk Island",
        "cities": {
            "Kingston": {
                "lat": -29.0606,
                "lng": 167.9619
            }
        }
    },
    "GS": {
        "name": "South Georgia and South Sandwich Islands",
        "cities": {
            "Grytviken": {
                "lat": -54.2806,
                "lng": -36.508
            },
            "King Edward Point": {
                "lat": -54.2833,
                "lng": -36.5
            }
        }
    },
    "PN": {
        "name": "Pitcairn Islands",
        "cities": {
            "Adamstown": {
                "lat": -25.0667,
                "lng": -130.1
            }
        }
    },
    "VI": {
        "name": "U.S. Virgin Islands",
        "cities": {
            "Charlotte Amalie": {
                "lat": 18.342,
                "lng": -64.9331
            }
        }
    }
}