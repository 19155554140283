import React from "react";
import { useParams } from "react-router-dom";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import "./ProfileGuide.css";
import { usePublicProfile } from "../../hooks/usePublicProfile";
import Catalogue from "../../components/Catalogue/Catalogue";

interface ProfileGuideProps {
  children?: React.ReactNode;
}

type UserNameMapping = {
  temmie: string;
  tadeyinka: string;
  tedward: string;
  tkaty: string;
  tgerald: string;
  tveronica: string;
  tjoann: string;
  tlina: string;
  tmatthew: string;
  tanjali: string;
  tluna: string;
};

const ProfileGuide: React.FC<ProfileGuideProps> = ({ children }) => {
  const { learnerID } = useParams<{ learnerID: string }>();
  const username = learnerID || "";
  const { headerContent, footerContent } = useHeaderFooter(children);
  const { userInfo } = usePublicProfile(learnerID);

  const twitterLink =
    "social_links" in userInfo &&
    userInfo.social_links?.find((link) => link?.platform === "twitter")
      ?.social_link;
  const linkedInLink =
    "social_links" in userInfo &&
    userInfo.social_links?.find((link) => link?.platform === "linkedin")
      ?.social_link;

  const userNameMapping: UserNameMapping = {
    temmie: "Emmie Bidston",
    tadeyinka: "Adeyinka Adewale",
    tedward: "Edward Brooks",
    tkaty: "Katy Granville-Chapman",
    tgerald: "Gerald Chirinda",
    tveronica: "Verónica Fernández",
    tjoann: "JoAnn Flett",
    tlina: "Lina Tori Jan",
    tmatthew: "Matthew Lee",
    tanjali: "Anjali Sarker",
    tluna: "Luna Wei Wang",
  };

  const displayName =
    "name" in userInfo
      ? userInfo.name
      : username in userNameMapping
      ? userNameMapping[username as keyof UserNameMapping]
      : "";

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        defaultValue="profile-guide"
      />
      <div className="container-center-horizontal">
        <div className="profile-guide screen">
          <div className="overlap-group5">
            <div className="hero-image">
              <img
                className="graphic_r"
                src="/img/graphic-r.svg"
                alt="Graphic_R"
              />
              <h1 className="guide-mike-smith recklessneue-book-normal-white-100-1px">
                Guide
                <br />
                <span className="i-name">{displayName}</span>
              </h1>
              <img
                className="graphic_l"
                src="/img/graphic-l.svg"
                alt="Graphic_L"
              />
            </div>
            {headerContent}
          </div>
          <div className="page-nav page">
            <p className="about-presented-by-mike-smith recklessneue-book-normal-congress-blue-22-8px">
              About &gt; <a href="/presented-by">Presented By</a> &gt;{" "}
              <span className="i-name">{displayName}</span>
            </p>
            <div className="line-container">
              <img className="line-6" src="/img/line-6-1.svg" alt="Line 6" />
              <img className="line-7" src="/img/line-7-3.svg" alt="Line 7" />
            </div>
          </div>
          <div className="mentor-bio">
            <div className="headshot">
              <img
                className="chris-blonk-bn5cob0k"
                src={`${
                  "profile_image" in userInfo
                    ? userInfo.profile_image.has_image &&
                      userInfo.profile_image.image_url_full
                    : ""
                }`}
                alt="chris-blonk-Bn5cob0k9gE-unsplash_Extended 1"
              />
            </div>
            <div className="overlap-group">
              <div className="dflex">
                <div className="guide-bio">
                  <span className="instrumentserif-normal-congress-blue-48px">
                    Guide{" "}
                  </span>
                  <span className="span1">Bio</span>
                </div>
                <div className="socials">
                  <a
                    data-name="email"
                    href={`mailto:${"email" in userInfo ? userInfo.email : ""}`}
                  >
                    <img className="email" src="/img/email.svg" alt="Email" />
                  </a>
                  {twitterLink && (
                    <a
                      data-name="twitter"
                      href={twitterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="twitter"
                        src="/img/twitter.svg"
                        alt="Twitter"
                      />
                    </a>
                  )}
                  {linkedInLink && (
                    <a
                      data-name="linkedin"
                      href={linkedInLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="linkedin"
                        src="/img/linkedin.svg"
                        alt="Linkedin"
                      />
                    </a>
                  )}
                </div>
              </div>
              <p className="lorem-ipsum-dolor-si neuehaasgroteskdisplaypro-45-light-congress-blue-24px c-bio">
                {"bio" in userInfo ? userInfo.bio : ""}
              </p>
            </div>
          </div>
          <div className="as-seen-on">
            <div className="providing-insighton-these-courses recklessneue-book-normal-congress-blue-48px">
              Providing insight
              <br />
              in these modules
            </div>
            <div className="overlap-group-container">
              <div className="courses-slider" data-name="courses-in-progress">
                <Catalogue username={username} />
              </div>
            </div>
          </div>
          {footerContent}
        </div>
      </div>
      <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
        <section id="shadow-host-companion" />
      </div>
    </>
  );
};

export default ProfileGuide;
