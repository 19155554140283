const LeadingOthersDynamicSvg = ({overlayWidth}: {overlayWidth: number})=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
            <defs>
                <clipPath id="circleClip">
                    <rect x="1.04982" y="0.88734" width="89.717" height="89.717" rx="44.8585" />
                </clipPath>
            </defs>

           
            <rect x="1.04982" y="0.88734" width="89.717" height="89.717" rx="44.8585" fill="#DFDFDF" />

           
            <rect x="1.04982" y="0.88734" width={overlayWidth} height="89.717" fill="#0A3887" clip-path="url(#circleClip)" />

         
            <g clip-path="url(#clip0_2568_4832)">
                <path d="M57.7975 71.3641C57.4382 71.3641 57.1441 71.0612 57.1441 70.6909C57.1441 64.0112 51.7977 58.1544 45.7034 58.1544C39.6092 58.1544 34.2627 63.4428 34.2627 69.9466C34.2627 70.3169 33.9687 70.6198 33.6094 70.6198C33.2501 70.6198 32.9561 70.3169 32.9561 69.9466C32.9561 62.7022 38.6764 56.8117 45.7034 56.8117C52.7305 56.8117 58.4508 63.1697 58.4508 70.6946C58.4508 71.0649 58.1568 71.3678 57.7975 71.3678V71.3641Z" fill="#ACACAC" />
                <path d="M45.6982 54.6683C40.1557 54.6683 35.644 50.0232 35.644 44.3085C35.644 38.5937 40.1521 33.9486 45.6982 33.9486C51.2443 33.9486 55.7524 38.5937 55.7524 44.3085C55.7524 50.0232 51.2443 54.6683 45.6982 54.6683ZM45.6982 35.2988C40.8744 35.2988 36.9507 39.3417 36.9507 44.3122C36.9507 49.2827 40.8744 53.3256 45.6982 53.3256C50.522 53.3256 54.4457 49.2827 54.4457 44.3122C54.4457 39.3417 50.522 35.2988 45.6982 35.2988Z" fill="#ACACAC" />
                <path d="M45.6984 72.6557C44.9833 72.6557 44.3118 72.3677 43.8073 71.8478L40.537 68.4781C38.9436 66.8362 37.3538 65.1831 35.764 63.53C33.0272 60.6839 30.1997 57.7442 27.3794 54.8943C26.9765 54.4867 26.5664 54.079 26.1562 53.6676C24.9367 52.4521 23.6772 51.1917 22.5084 49.8865C19.866 46.9244 16.8171 42.7991 16.7663 37.1779C16.6864 28.1644 23.7207 20.4899 32.4501 20.0673C37.5062 19.8205 42.399 21.9934 45.6984 25.913C48.9977 21.9972 53.8869 19.8242 58.9466 20.0673C67.676 20.4899 74.7139 28.1644 74.6304 37.1741C74.5796 42.7991 71.5307 46.9244 68.881 49.8902C67.7123 51.1992 66.4528 52.4559 65.2332 53.6714C64.8231 54.079 64.4129 54.4867 64.01 54.8981C61.1934 57.748 58.3623 60.6876 55.6255 63.5338C54.0357 65.1869 52.4495 66.8362 50.8525 68.4818L47.5822 71.8516C47.0776 72.3714 46.4061 72.6594 45.6911 72.6594L45.6984 72.6557ZM33.2268 21.395C32.9909 21.395 32.7513 21.4025 32.5154 21.4137C24.4757 21.8027 18.0004 28.8676 18.073 37.1629C18.1202 42.298 20.8315 46.0193 23.4775 48.9776C24.6209 50.2567 25.8659 51.5021 27.0745 52.7027C27.4883 53.1141 27.8985 53.5255 28.305 53.9332C31.1289 56.7868 33.96 59.7339 36.7004 62.5838C38.2866 64.2332 39.8727 65.8862 41.4662 67.5281L44.7365 70.8979C45.2701 71.4477 46.1375 71.4477 46.6711 70.8979L49.9414 67.5281C51.5349 65.8862 53.121 64.2369 54.7072 62.5838C57.4476 59.7339 60.2787 56.7905 63.1026 53.9332C63.5091 53.5218 63.9193 53.1141 64.3294 52.7027C65.5345 51.4984 66.7831 50.2567 67.9264 48.9776C70.5725 46.0155 73.2838 42.298 73.331 37.1629C73.4072 28.8676 66.9283 21.8027 58.8922 21.41C53.9849 21.1744 49.2518 23.4034 46.221 27.3828L45.7056 28.056L45.1902 27.3828C42.301 23.6016 37.8728 21.395 33.2268 21.395Z" fill="#ACACAC" />
            </g>

           
            <rect x="1.04982" y="0.88734" width="89.717" height="89.717" rx="44.8585" stroke="#ACACAC" stroke-width="1.58791" fill="none" />

            <defs>
                <clipPath id="clip0_2568_4832">
                    <rect width="57.8678" height="52.6071" fill="white" transform="translate(16.7676 20.0484)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default LeadingOthersDynamicSvg