import React, {useState} from "react";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import {api, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import './Contact.css'
import {getContactMailTemplate, getFormattedDate} from "../../hooks/utils/helpers";

interface ContactProps {
    children?: React.ReactNode
}
// TODO : if user has submitted email, and the success message is show, remove it the moment the user starts to type into the input box again

const Contact: React.FC<ContactProps> = ({children}) => {

    const {headerContent, footerContent} = useHeaderFooter(children);

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        company: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        company: "",
        message: "",
    });

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const validateForm = () => {
        const newErrors = {
            fullName: "",
            email: "",
            phoneNumber: "",
            company: "",
            message: "",
        };
        let isValid = true;

        if (!formData.fullName.trim()) {
            newErrors.fullName = "This field is required.";
            isValid = false;
        }


        if (!formData.message.trim()) {
            newErrors.message = "This field is required.";
            isValid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = "This field is required.";
            isValid = false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                newErrors.email = "Please enter a valid email address.";
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = () => {
        if (!validateForm()) {
            scrollToForm();
            return;
        }

        const data = {
            ...formData,
            subject:"Contact Form Submission",
            service:"contact_us",
            edx_org_id:"",
            email: "colin.Webb@legatum.com",
            message:getContactMailTemplate(`
            <div class="content">  
    <p>Contact Form Submitted fields :</p>
    <p><strong>Full name:</strong> ${formData.fullName}</p>
    <p><strong>Email:</strong> ${formData.email}</p>
    <p><strong>Phone number:</strong> ${formData.phoneNumber || "N/A"}</p>
    <p><strong>Company:</strong> ${formData.company || "N/A"}</p>
    <p><strong>Message:</strong> ${formData.message}</p>
  </div>
            `)
        };

        setIsFormSubmitting(true);

        api.ibledxplatform.sendEmail(data, function (response: any) {
            // Handle response or error
            setIsFormSubmitted(true);
            setIsFormSubmitting(false);

            api.ibledxplatform.sendEmail({
                ...data,
                email:"maria.Horning@legatum.com"
            }, function (response: any) {
                // Handle response or error for the thank you email if necessary
            });

            // Trigger automatic thank you email to the user
            const thankYouEmailData = {
                ...formData,
                service:"contact_us",
                subject: "Thank you for contacting us!",
                edx_org_id:"",
                message: getContactMailTemplate(`
                <div class="content">
    <p>Dear ${formData.fullName},</p>
    <p>Thank you for your request. The following has been sent to our course administrators:</p>
    <p><strong>Full name:</strong> ${formData.fullName}</p>
    <p><strong>Email:</strong> ${formData.email}</p>
    <p><strong>Phone number:</strong> ${formData.phoneNumber || "N/A"}</p>
    <p><strong>Company:</strong> ${formData.company || "N/A"}</p>
    <p><strong>Message:</strong> ${formData.message}</p>
    <p>We will be in touch as soon as possible.</p>
    <p>Kind regards,<br>The Leading with Character Team</p>
  </div>
                `),
            };

            api.ibledxplatform.sendEmail(thankYouEmailData, function (response: any) {
                // Handle response or error for the thank you email if necessary
            });

            // Reset form data
            setFormData({
                fullName: "",
                email: "",
                phoneNumber: "",
                company: "",
                message: "",
            });
        });
    };


    const scrollToForm = () => {
        const formElement = document.querySelector<HTMLElement>(".contact-form");

        if (formElement) {
            window.scrollTo({
                top: formElement.offsetTop - 100,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about"/>
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <img
                                        className="overlap-group-item"
                                        src="/img/CONTACTUS-LWC.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                <span className="recklessneue-regular-normal-white-100-1px">
                  Contact{" "}
                </span>
                                        <span className="instrumentserif-normal-white-100-1px">
                  &amp; Support
                </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5">
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                                We welcome your valuable input and are eager to engage with our
                                community of learners. Your questions, insights, and feedback are
                                instrumental to our continuous growth and improvement.
                            </p>
                        </div>
                    </div>
                    <div className="legatum-container the-course contact-row">
                        <div className="c-section c-title">
                            <div className="leg-title">
                                Please contact us with your questions or support requests.
                            </div>
                            <div className="contact-footer">
                                <div className="hr"/>
                                <p className="f-first-p">Send us an email:</p>
                                <p className="f-email">
                                    <a href="mailto:support@leadingwithcharacter.com">
                                        support@leadingwithcharacter.com
                                    </a>
                                </p>
                                <a href="mailto:support@leadingwithcharacter.com"></a>
                                <div className="f-media">
                                    <a href="mailto:support@leadingwithcharacter.com"></a>
                                    <a href="mailto:support@leadingwithcharacter.com">
                                        <svg
                                            width={33}
                                            height={25}
                                            viewBox="0 0 33 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M30.9846 24.152L21.5679 12.788L17.8318 15.416C17.0304 15.98 15.9579 15.98 15.1446 15.416L11.4204 12.788L2.08607 24.188C2.53393 24.392 3.02893 24.5 3.53571 24.5H29.4643C29.9946 24.5 30.5132 24.38 30.9846 24.152ZM9.4875 11.42L0 4.724V20.9C0 21.476 0.129643 22.028 0.377143 22.532L9.4875 11.42ZM33 4.724L23.5243 11.408L32.6582 22.448C32.8821 21.968 33 21.44 33 20.9V4.724ZM0.542143 2.192L16.5 13.448L32.4579 2.192C32.3164 1.964 32.1514 1.748 31.9629 1.556C31.3029 0.884 30.3954 0.5 29.4643 0.5H3.53571C2.59286 0.5 1.69714 0.884 1.03714 1.556C0.848571 1.748 0.683571 1.964 0.542143 2.192Z"
                                                fill="#0A3887"
                                            />
                                        </svg>
                                    </a>
                                    <a href="https://x.com/LWCcourse" target="_blank">
                                        <img height={"25px"} src="/img/twitter-blue.png"/>
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/leadingwithcharacter/"
                                        target="_blank"
                                    >
                                        <svg
                                            width={32}
                                            height={31}
                                            viewBox="0 0 32 31"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            {" "}
                                            <path
                                                d="M31.75 2.38603V28.614C31.75 29.2136 31.506 29.7887 31.0716 30.2128C30.6372 30.6368 30.0481 30.875 29.4338 30.875H2.56618C1.95189 30.875 1.36276 30.6368 0.928392 30.2128C0.494025 29.7887 0.25 29.2136 0.25 28.614L0.25 2.38603C0.25 1.78637 0.494025 1.21127 0.928392 0.78724C1.36276 0.363215 1.95189 0.125 2.56618 0.125L29.4338 0.125C30.0481 0.125 30.6372 0.363215 31.0716 0.78724C31.506 1.21127 31.75 1.78637 31.75 2.38603ZM9.51471 11.8824H4.88235V26.3529H9.51471V11.8824ZM9.93162 6.90809C9.93406 6.56603 9.86746 6.22685 9.73562 5.90992C9.60378 5.59299 9.40928 5.30452 9.16323 5.06097C8.91718 4.81742 8.6244 4.62356 8.3016 4.49047C7.9788 4.35738 7.63231 4.28766 7.28191 4.28529H7.19853C6.48596 4.28529 5.80257 4.56162 5.2987 5.05349C4.79483 5.54536 4.51176 6.21248 4.51176 6.90809C4.51176 7.6037 4.79483 8.27081 5.2987 8.76268C5.80257 9.25455 6.48596 9.53088 7.19853 9.53088C7.54895 9.5393 7.89764 9.48023 8.22467 9.35705C8.5517 9.23387 8.85067 9.04899 9.10448 8.81298C9.35829 8.57697 9.56198 8.29445 9.7039 7.98157C9.84582 7.66869 9.9232 7.33157 9.93162 6.98949V6.90809ZM27.1176 17.5621C27.1176 13.2118 24.2826 11.5206 21.4662 11.5206C20.544 11.4755 19.6258 11.6672 18.8033 12.0767C17.9807 12.4861 17.2825 13.0989 16.7782 13.854H16.6485V11.8824H12.2941V26.3529H16.9265V18.6564C16.8595 17.8681 17.1139 17.0859 17.6343 16.4797C18.1547 15.8734 18.8991 15.4921 19.7059 15.4186H19.8819C21.355 15.4186 22.4482 16.323 22.4482 18.6021V26.3529H27.0806L27.1176 17.5621Z"
                                                fill="#0A3887"
                                            />
                                            {" "}
                                        </svg>
                                    </a>
                                    <a href="https://www.youtube.com/@LeadingWithCharacter" target="_blank">
                                        <svg
                                            width={35}
                                            height={25}
                                            viewBox="0 0 35 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M30.8112 1.25005C32.2805 1.64478 33.4428 2.80707 33.8376 4.27636C34.5612 6.9518 34.5832 12.5 34.5832 12.5C34.5832 12.5 34.5832 18.0702 33.8595 20.7237C33.4647 22.193 32.3025 23.3553 30.8332 23.7501C28.1797 24.4737 17.4998 24.4737 17.4998 24.4737C17.4998 24.4737 6.82001 24.4737 4.1665 23.7501C2.6972 23.3553 1.53493 22.193 1.14019 20.7237C0.416504 18.0483 0.416504 12.5 0.416504 12.5C0.416504 12.5 0.416504 6.9518 1.11826 4.2983C1.513 2.829 2.67528 1.66672 4.14457 1.27199C6.79808 0.548292 17.4779 0.526367 17.4779 0.526367C17.4779 0.526367 28.1577 0.526367 30.8112 1.25005ZM22.9384 12.5001L14.0788 17.6316V7.36847L22.9384 12.5001Z"
                                                fill="#0A3887"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form">
                            <div className="form-row">
                                <label htmlFor="">Full Name*</label>
                                <input
                                    name="fullName"
                                    type="text"
                                    placeholder="John Smith"
                                    required
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                />
                                <span className="form-error"></span>
                                {errors.fullName &&
                                    <span className="form-error form-error-custom">{errors.fullName}</span>}

                            </div>
                            <div className="form-row">
                                <label htmlFor="">Email*</label>
                                <input
                                    name="email"
                                    type="email"
                                    placeholder="youremail@address.com"
                                    required
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />

                                {errors.email && <span className="form-error form-error-custom">{errors.email}</span>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="">Phone Number</label>
                                <input
                                    name="phoneNumber"
                                    type="nunber"
                                    placeholder="Enter your phone number"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-row">
                                <label htmlFor="">Company/Organisation</label>
                                <input
                                    name="company"
                                    type="text"
                                    placeholder="Enter your company/organisation"
                                    value={formData.company}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-row">
                                <label htmlFor="">How can we help?</label>
                                <textarea
                                    name="message"
                                    id=""
                                    cols={30}
                                    rows={10}
                                    required
                                    placeholder="Tell us about your needs..."
                                    value={formData.message}
                                    onChange={handleInputChange}
                                />
                                {errors.message &&
                                    <span className="form-error form-error-custom">{errors.message}</span>}

                            </div>
                            <div className="hr"/>
                            <div className="form-button-row">
                                <div className="contact-btn" onClick={!isFormSubmitting ? handleSubmit : ()=>{}}>Send Message
                                    {isFormSubmitting &&
                                        <span style={{marginLeft:"20px"}}><IBLSpinLoader size={20} color={"#FFF"} /></span>} </div>
                            </div>
                            {isFormSubmitted && (
                                <div className="success-message">
            <span>
              Thank you for contacting us. We will get back to you shortly.
            </span>
                                </div>

                            )}
                        </div>


                    </div>
                    {footerContent}
                </div>
            </div>
        </>

    )
}
export default Contact;