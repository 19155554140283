import React, {useContext, useState, useRef} from "react";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import './Dashboard.css';
import {AppDataContext,  IBLSpinLoader} from "@iblai/ibl-web-react-common";
import {NavLink} from "react-router-dom";
import UserEditPopUp from "../../components/UserEditPopUp/UserEditPopUp";
import {setBirthDate, uploadImage} from "../../hooks/utils/helpers";
import Achievements from "../../components/Achievements/Achievements";
import CoursesInProgress from "../../components/CoursesInProgress/CoursesInProgress";
import AchievementDisplay from "../../components/AchievementDisplay/AchievementDisplay";


interface DashboardProps {
    children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);
    const {appData, setAppData} = useContext(AppDataContext);
    const [isProfileModalOpen, setProfileModalOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false)


    const handleProfileImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            // TODO : change to appdata.usermatada.yearofbirth logic
            if (!localStorage.getItem("birth_date_set")) {
                await setBirthDate(selectedFile);
                window.location.reload()
                return;
            }
            await uploadImage(selectedFile);
            window.location.reload()
        }
    };


    return (
        <>
            <input
                type="hidden"
                id="anPageName"
                name="page"
                defaultValue="user-dashboard"
            />
            <div className="container-center-horizontal">
                <div className="user-dashboard screen">
                    {headerContent}
                    <div className="page-nav recklessneue-book-normal-congress-blue-22-8px">
                        <div className="flex-col-1 flex-col-5">
                            <div className="dashboard">Dashboard</div>
                            <img className="line-6 line" src="/img/line-6-5.svg" alt="Line 6"/>
                        </div>
                        <div className="view-public-profile">
                            <NavLink
                                id="profile-link"
                                to={`/profile-learner`}
                            >
                                View Public Profile
                            </NavLink>
                        </div>
                    </div>
                    <div className="flex-row flex w-100 dashboard-info-container">
                        {appData.userMetaDataLoaded && (
                            <div className="personal-info">
                                <div className="overlap-group7">
                                    <div className="personal-information">

                                        <img className="edit" src="/img/edit.svg" alt="Edit"
                                             onClick={() => setProfileModalOpen(true)}
                                        />


                                        <p className="email-saanviklwc neuehaasgroteskdisplaypro-medium-congress-blue-24px">
                <span className="neuehaasgroteskdisplaypro-medium-congress-blue-24px">
                  Email:{" "}
                </span>
                                            <span
                                                className="neuehaasgroteskdisplaypro-light-congress-blue-24px c-email">
                  {appData.userData.user_email}
                </span>{" "}
                                            <br/>

                                            <span className="neuehaasgroteskdisplaypro-medium-congress-blue-24px">
                  Bio:{" "}
                </span>
                                            <span className="neuehaasgroteskdisplaypro-light-congress-blue-24px c-bio">
                  {appData?.userMetaData?.bio}
                </span>
                                            <br/>
                                            <span className="neuehaasgroteskdisplaypro-medium-congress-blue-24px">
                  Links:
                </span>
                                            <span className="neuehaasgroteskdisplaypro-light-congress-blue-24px">
    {" "}
                                                {appData?.userMetaData?.social_links?.find(link => link?.platform === "twitter")?.social_link && (
                                                    <>
                                                        <a
                                                            data-name="twitter"
                                                            href={appData?.userMetaData?.social_links?.find(link => link?.platform === "twitter")?.social_link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {" "}
                                                            Twitter{" "}
                                                        </a>
                                                        ,{" "}
                                                    </>
                                                )}
                                                {appData?.userMetaData?.social_links?.find(link => link?.platform === "linkedin")?.social_link && (
                                                    <a
                                                        data-name="linkedin"
                                                        href={appData?.userMetaData?.social_links?.find(link => link?.platform === "linkedin")?.social_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        LinkedIn{" "}
                                                    </a>
                                                )}
</span>


                                            <br/>
                                            <span className="neuehaasgroteskdisplaypro-medium-congress-blue-24px">
                  Profile:{" "}
                </span>
                                            <span className="neuehaasgroteskdisplaypro-light-congress-blue-24px">
                  Public
                </span>
                                        </p>
                                    </div>
                                    <h1 className="title c-name">{appData.userData.user_fullname}</h1>

                                    <div
                                        className="headshot public-profile-image-container"
                                        onClick={handleProfileImageClick}
                                        style={{visibility: "visible"}}>

                                        {isLoading ? (
                                            <IBLSpinLoader size={40} containerHeight="150px"/>

                                        ) : (
                                            <img
                                                className="image-9 user-img"
                                                src={`${appData?.userMetaData?.profile_image?.image_url_full}`}
                                                alt="Profile"
                                            />
                                        )}
                                        <div className={"profile-upload-btn"}>
                                            <img className={"profile-upload-btn-icon"} src={'/img/camera.svg'} />
                                        </div>

                                    </div>

                                    <input
                                        type="file"
                                        id="profileImageInput"
                                        name="imageInput"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{display: 'none'}}
                                        onChange={handleFileChange}
                                    />
                                    <img
                                        className="line-13 line"
                                        src="/img/line-13-1.svg"
                                        alt="Line 13"
                                    />
                                    <div className="my-personal-information inter-light-congress-blue-22px">
                                        My Personal Information
                                    </div>
                                </div>
                            </div>

                        )}
                        <div className={"achievement-container"}>
                            <div className="my-achievements">
                                <div className="overlap-group6">
                                    <AchievementDisplay textHelper={"Click on icons to access downloadable PDF certificate"}/>
                                </div>
                            </div>
                            <div className="my-achievements">
                                <div className="overlap-group6">
                                    <AchievementDisplay textHelper={"Hover over a badge to see details"} title={"Badges"} displayCertificates={false}/>
                                </div>
                            </div>

                        </div>


                    </div>
                    <CoursesInProgress/>
                    <CoursesInProgress showOnProgress={false} title={"Modules Completed"}/>
                    {footerContent}
                    {isProfileModalOpen && (
                        <UserEditPopUp appData={appData} setProfileModalOpen={setProfileModalOpen}
                                       setAppData={setAppData}/>
                    )}
                </div>
            </div>
        </>
    );
}

export default Dashboard;
