import React, {useRef, useState} from 'react';
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import {useDebounce} from "../../hooks/useDebounce";
import useNewsletter from "../../hooks/useNewsletter";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";

const Newsletter = () => {
    
    const {
        newsletterNotif,
        submitToNewsletter,
        submitting,
        setEmail,
        email
    } = useNewsletter()

    
    const notificationRef = useRef<any>(null)

    useDebounce(()=>{
        if(newsletterNotif?.msg && notificationRef?.current){
            if("toast" in  notificationRef.current){
                notificationRef.current?.toast()
            }
        }
    }, 200, [newsletterNotif])
    
    return (
        <>
            <SlAlert ref={notificationRef} variant={newsletterNotif?.success ? "success" : "danger"} duration={5000} closable>
                <SlIcon slot="icon" name={newsletterNotif?.success ? "check2-circle" : "exclamation-octagon"} />
                <strong>{newsletterNotif?.msg}</strong>
            </SlAlert>
            <div className="overlap-group2-1">
                <div className="overlap-group1-2">
                    <p className="sign-up-to-our-newsletter instrumentserif-normal-white-34px">
                        Sign up to our newsletter
                    </p>
                </div>
                <div className="email-input">
                    <div className="button">
                        <input
                            className="enter-email-adress instrumentsans-normal-white-26px"
                            placeholder="Enter email address"
                            required={true}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="button-1 button-4" onClick={submitting ? ()=>{} : ()=>submitToNewsletter(email)}>
                        {
                            !submitting ? (
                                <div className="text-13 instrumentsans-normal-congress-blue-26px">
                                    <svg
                                        width={19}
                                        height={18}
                                        viewBox="0 0 19 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                                            fill="#0A3887"
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <IBLSpinLoader size={25} color={"var(--cobalt-2)"} />
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default Newsletter;