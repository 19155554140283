import useHeaderFooter from "../../hooks/useHeaderFooter";
import React, {useEffect, useState} from "react";
import './CourseCatalogue.css';
import Catalogue from "../../components/Catalogue/Catalogue";                   
import styled from 'styled-components';
import CoursesInProgress from "../../components/CoursesInProgress/CoursesInProgress";
import {isLoggedIn} from "../../hooks/utils/helpers";


interface CourseCatalogue {
    children?: React.ReactNode
}

const StyledCourseContainer = styled.div`
                        &.course-container::before,
                        &.course-container::after {
                            background: transparent;
                        }
                    `;

const CourseCatalogue: React.FC<CourseCatalogue> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);
    const [loggedIn, setLoggedIn] = useState(!!isLoggedIn())

    return (
        <>
            <div className="container-center-horizontal">
                <div className="about screen courses_ab">
                    <div className="homepage">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <div className="grad"/>
                                    <img
                                        className="overlap-group-item"
                                        src="/img/MODULES-LIST-LWC.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px-custom">
              <span className="recklessneue-regular-normal-white-100-1px-custom">
                A journey of{" "}
              </span><br/>
                                        <span className="instrumentserif-normal-white-100-1px">
                Self Discovery
              </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5">
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                                Unleash your leadership potential with purpose, courage, love, and
                                hope. Dive deep in interactive modules and apply your learning through
                                practical 5-day challenges. Elevate your leadership to build a better
                                world.
                            </p>
                        </div>
                    </div>
                    <StyledCourseContainer className={`courses-grid course-catalogue course-container ${loggedIn ? "logged-in" : "not-logged-in"}`}>
                        {
                            loggedIn ? <CoursesInProgress showOnProgress={false} title={"  "} showAll={true} />  :
                                <Catalogue />
                        }
                    </StyledCourseContainer>
                    {footerContent}
                </div>
            </div>

        </>
    )
}

export default CourseCatalogue