import React from 'react';

const LeadingChangeDynamicSvg = ({ overlayWidth }: { overlayWidth: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="92" viewBox="0 0 93 92" fill="none">
            <defs>
                <clipPath id="circleClip">
                    <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" />
                </clipPath>
            </defs>

            <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" fill="#F5F5F5" />

            <rect x="1.68421" y="0.760874" width={overlayWidth} height="89.7832" fill="#0A3887" clip-path="url(#circleClip)" />

            <g clip-path="url(#clip0_2568_4813)">
                <path d="M76.5582 70.025H28.7651C28.303 70.025 27.8875 69.7864 27.6511 69.3851C27.4147 68.9838 27.4075 68.4994 27.6296 68.0908L51.528 24.1787C51.7536 23.7593 52.1906 23.5026 52.6599 23.5026C53.1327 23.5026 53.5661 23.7629 53.7953 24.1787L77.6901 68.0908C77.9122 68.4994 77.905 68.9838 77.6686 69.3851C77.4322 69.7864 77.0167 70.025 76.5546 70.025H76.5582ZM28.8761 68.6548H76.4508L52.6634 24.9415L28.8761 68.6512V68.6548Z" fill="#BCBCBC" />
                <path d="M44.1922 70.0251H18.3704C18.0086 70.0251 17.6827 69.8371 17.5 69.5226C17.3173 69.2081 17.3102 68.8321 17.4857 68.5103L30.3948 44.7863C30.5739 44.4609 30.9106 44.2585 31.2795 44.2585C31.6485 44.2585 31.9887 44.4609 32.1643 44.7899L45.0734 68.5139C45.2489 68.8321 45.2417 69.2117 45.0591 69.5262C44.8764 69.8407 44.5504 70.0287 44.1887 70.0287L44.1922 70.0251ZM18.9578 68.6549H43.6048L31.2795 46.0083L18.9578 68.6513V68.6549Z" fill="#BCBCBC" />
                <path d="M31.4265 54.0091L26.7951 53.3258C26.4226 53.2716 26.1683 52.9209 26.222 52.5485C26.2757 52.1725 26.6196 51.9158 26.9921 51.97L31.1364 52.581L34.4639 50.5022C34.7827 50.3034 35.2018 50.401 35.3988 50.7263C35.5958 51.0481 35.4991 51.4711 35.1767 51.67L31.4301 54.0091H31.4265Z" fill="#BCBCBC" />
                <path d="M43.8089 40.4697C43.5403 40.4697 43.286 40.307 43.1785 40.0394C43.0388 39.6887 43.2072 39.2874 43.5546 39.1464L49.5507 36.7025L54.7982 39.0994H61.4462C61.8223 39.0994 62.1267 39.4067 62.1267 39.7864C62.1267 40.166 61.8223 40.4733 61.4462 40.4733H54.508L49.5256 38.1992L44.0704 40.4263C43.988 40.4588 43.9021 40.4769 43.8161 40.4769L43.8089 40.4697Z" fill="#BCBCBC" />
                <path d="M33.4751 34.6923C30.1618 34.6923 27.4683 31.9735 27.4683 28.6293C27.4683 25.2851 30.1618 22.5663 33.4751 22.5663C36.7883 22.5663 39.4819 25.2851 39.4819 28.6293C39.4819 31.9735 36.7883 34.6923 33.4751 34.6923ZM33.4751 23.9366C30.9105 23.9366 28.8258 26.0407 28.8258 28.6293C28.8258 31.2179 30.9105 33.322 33.4751 33.322C36.0397 33.322 38.1244 31.2179 38.1244 28.6293C38.1244 26.0407 36.0397 23.9366 33.4751 23.9366Z" fill="#BCBCBC" />
                <path d="M52.6273 25.1948C52.2512 25.1948 51.9468 24.8875 51.9468 24.5079V18.2099C51.9468 17.7725 52.2978 17.4182 52.7312 17.4182H58.1864C58.5052 17.4182 58.7882 17.6098 58.91 17.9062C59.0318 18.2027 58.9637 18.5389 58.7416 18.7667L57.4199 20.1007L58.5912 21.283C58.8133 21.5107 58.8813 21.847 58.7631 22.1434C58.6449 22.4399 58.3619 22.6315 58.0467 22.6351L53.3115 22.6821V24.5079C53.3115 24.8875 53.007 25.1948 52.6309 25.1948H52.6273ZM53.3079 18.7884V21.3083L56.6605 21.2757L56.0516 20.6611C55.7472 20.3502 55.7472 19.8513 56.0516 19.544L56.8002 18.7884H53.3079Z" fill="#BCBCBC" />
            </g>

            <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" stroke="#BCBCBC" stroke-width="1.52175" fill="none" />

            <defs>
                <clipPath id="clip0_2568_4813">
                    <rect width="60.4981" height="52.6071" fill="white" transform="translate(17.3564 17.418)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LeadingChangeDynamicSvg;