import React, {useEffect, useState, MouseEvent, useRef} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CourseCatalogueItem, useCourseCatalogue } from "../../hooks/useCourseCatalogue";
import {getEnabledCourse, getNextCourseArrays} from "../../hooks/utils/helpers";
import { NavLink } from "react-router-dom";
import './CarouselCourseCard.css';
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import {useDebounce} from "../../hooks/useDebounce";

interface CarouselState {
    currentSlide: number;
    deviceType?: 'mobile' | 'tablet' | 'desktop';
}

interface CustomArrowProps {
    onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
    onMove?: boolean;
    carouselState: CarouselState;
}
const CarouselCourseCard = ({course_id=""}) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const [coursesData, courseDataLoaded] = useCourseCatalogue();
    const [filteredCourses, setFilteredCourses] = useState<CourseCatalogueItem[]>([]);
    const carouselRef = useRef<any>(null);
    const [coursesLoaded, setCoursesLoaded] = useState(false)

    useEffect(() => {
        if (courseDataLoaded && coursesData) {
            const enabledCourses = getEnabledCourse(coursesData);
            if(course_id){
                const  promise: Promise<CourseCatalogueItem[]> = new Promise((resolve)=>{
                    if (courseDataLoaded) {
                        const enabledCourses = getEnabledCourse(coursesData);
                        if (course_id) {
                            const nextCourseData = getNextCourseArrays(course_id, enabledCourses);
                            resolve(nextCourseData)

                        } else {
                            resolve(enabledCourses)
                        }
                    }
                })
                promise.then((updatedFilteredCourse)=>{
                    setFilteredCourses(updatedFilteredCourse)
                })
            }else{
                setFilteredCourses(enabledCourses);
            }

        }
    }, [courseDataLoaded, coursesData]);

    useDebounce(()=>{
        if(filteredCourses.length > 0 ){
            setCoursesLoaded(true)
        }
    }, 300, [filteredCourses])


    const CustomRightArrow: React.FC<CustomArrowProps> = ({
                                                                   onClick=()=>{},
                                                                   ...rest
                                                               }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className={"custom-arrow-btn __right"} onClick={() => onClick()}><img src={"/img/arrow-right.svg"}/></button>;
    };
    const CustomLeftArrow: React.FC<CustomArrowProps> = ({
                                                                   onClick=()=>{},
                                                                   ...rest
                                                               }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className={"custom-arrow-btn __left"} onClick={() => onClick()}><img src={"/img/arrow-left.svg"}/></button>;
    };
    return (
        <>
            {
                coursesLoaded ? (
                    <Carousel
                        partialVisible={true}
                        responsive={responsive}
                        ssr={false}
                        containerClass="course-container"
                        ref={carouselRef}
                        customLeftArrow={<CustomLeftArrow carouselState={carouselRef.current?.state || {} as CarouselState}  />}
                        customRightArrow={<CustomRightArrow carouselState={carouselRef.current?.state || {} as CarouselState}  />}
                    >
                        {filteredCourses.map((courseInstance, index) => (
                            <div className="course-item-custom carousel-course-item" key={`carousel-item-${index}`}>
                                <div className="top-c">
                                    <img
                                        src={`${process.env.REACT_APP_IBL_LMS_URL}${courseInstance?.metadata?.course_image_asset_path}`}
                                        alt={courseInstance?.search?._source?.name || "Course image"}
                                    />
                                </div>
                                <div className="c-name">{courseInstance?.search?._source?.name}</div>
                                <p className="fixed-height course-item-desc">
                                    {courseInstance?.metadata?.description || "No description available"}
                                </p>
                                <div className="home-btn">
                                    <NavLink
                                        to={`/course-overview/${courseInstance?.search?._source?.course_id}`}
                                        className={`course-btn ${index === 0 ? 'gs' : 'lm'}`}
                                    >
                            <span className={"btn-d-flex"}>{index === 0 ? 'Get Started' : 'Learn More'} {index === 0 &&
                                <img className={"carousel-btn-icon"} src={"/img/arrow-right.svg"}/>}</span>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : <IBLSpinLoader size={30} containerHeight={"200px"} color={"var(--cobalt)"} />
            }
        </>

    )
}

export default CarouselCourseCard;