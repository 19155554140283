import React, {useState} from 'react';
import Achievements from "../Achievements/Achievements";
import './AchievementDisplay.css'
import {BADGES_TOOLTIPS} from "../../hooks/utils/helpers";

const AchievementDisplay = ({displayCertificates=true, title="Certificates", textHelper=""}) => {
    const [enabled, setEnabled] = useState(true)
    return enabled ? (
        <>
            <div className="achievements">
                <div className="my-achievements-1 inter-light-congress-blue-22px">
                    {title}
                </div>
                <img className="line-13-1" src="/img/line-13.svg" alt="Line 13"/>
            </div>
            <div className="badges">
                <div className="badge_-container">
                    <Achievements displayCertificates={displayCertificates} setEnabled={setEnabled}/>
                </div>
            </div>
            {
                textHelper && (
                    <p className={"achievement-text-helper"}>{textHelper}</p>
                )
            }
        </>
    ) : <></>;
};

export default AchievementDisplay;