import React, {useState} from "react";
import {IAppData, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import {saveUserMetaData} from "../../hooks/utils/helpers";

interface userEditPopUpProps {
    appData: IAppData
    setProfileModalOpen: (isOpen: boolean) => void;
    setAppData: (appData: IAppData) => void;
}

const UserEditPopUp: React.FC<userEditPopUpProps> = ({appData, setProfileModalOpen, setAppData}) => {
    const [fullName, setFullName] = useState(appData.userData.user_fullname);
    const [title, setTitle] = useState<any>(("title" in appData.userMetaData) ? appData.userMetaData['title']  : "");
    const [bio, setBio] = useState(appData.userMetaData.bio);
    const [linkedin, setLinkedin] = useState(
        appData.userMetaData.social_links.find(link => link.platform === "linkedin")?.social_link || ""
    );
    const [twitter, setTwitter] = useState(
        appData.userMetaData.social_links.find(link => link.platform === "twitter")?.social_link || ""
    );

    const [displayProfile, setDisplayProfile] = useState(false);
    const [formError, setFormError] = useState("");
    const [isLoading, setIsLoading ] = useState(false)

    const handleSave = () => {
        if (!fullName) {
            window.alert("Full Name is required");
            return;
        }
        setFormError("");

        setIsLoading(true)

        const social_links = [
            {
                platform: "twitter",
                social_link: twitter,
            },
            {
                platform: "linkedin",
                social_link: linkedin,
            }
        ];


        const userMetaData: any = {
            username: appData.userMetaData.username,
            name: fullName,
            bio: bio,
            social_links: social_links,
            title
        };


        saveUserMetaData(userMetaData, (response: any) => {
            setAppData({
                ...appData,
                userMetaData: {
                    ...appData.userMetaData,
                    name: fullName,
                    bio: bio,
                    social_links: social_links

                },
            });
            setIsLoading(false)
            closePopup();
            window.alert("Profile Updated Successfully");

        }, (error) => {
            setIsLoading(false)
            closePopup();

            window.alert("An Error Occurred. Please try again later");
        });


    };


    const closePopup = () => {
        // Reset form or handle popup close
        setProfileModalOpen(false)

    };

    return (
        <>
            <div id="edit-popup" className="active">
                {isLoading ? (
                    <IBLSpinLoader size={40} containerHeight="150px"/>

                ) : (<div className="edit-profile">
                        <div className="top">
                            <div>Edit Profile Information</div>
                            <div>
                                <i className="fas fa-times close-edit-popup" onClick={closePopup}/>
                            </div>
                        </div>
                        <div className="edit-input">
                            <label htmlFor="fullName">Full Name*</label>
                            <input
                                id="fullName"
                                data-name="name"
                                required
                                type="text"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            {formError && <span className="form-error">{formError}</span>}
                        </div>
                        <div className="edit-input em-display">
                            <label htmlFor="displayProfile">Display on profile</label>
                            <input
                                data-name="display_email"
                                type="checkbox"
                                id="displayProfile"
                                name="displayProfile"
                                checked={displayProfile}
                                onChange={() => setDisplayProfile(!displayProfile)}
                            />
                        </div>
                        <div className="edit-input">
                            <label htmlFor="title">Title</label>
                            <input
                                id="title"
                                data-name="title"
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="edit-input">
                            <label htmlFor="bio">About</label>
                            <textarea
                                id="bio"
                                data-name="bio"
                                cols={30}
                                rows={6}
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                            />
                        </div>
                        <div className="edit-input">
                            <label htmlFor="linkedin">LinkedIn</label>
                            <input
                                id="linkedin"
                                data-type="social"
                                data-name="linkedin"
                                type="text"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                            />
                        </div>
                        <div className="edit-input">
                            <label htmlFor="twitter">Twitter</label>
                            <input
                                id="twitter"
                                data-type="social"
                                data-name="twitter"
                                type="text"
                                value={twitter}
                                onChange={(e) => setTwitter(e.target.value)}
                            />
                        </div>
                        <div className="bottom">
                            <div className="cancel close-edit-popup" onClick={closePopup}>Cancel</div>
                            <div className="save" onClick={handleSave}>Save &amp; Exit</div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};

export default UserEditPopUp;
