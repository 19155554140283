import React from "react";

const CourseSkeleton = () => {
    return (
        <>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
            <div className="course-item skeleton-item" style={{display: "block"}}>
                <div className="top-c">
                    <div className="skeleton-img"/>
                </div>
                <div className="c-name skeleton-row"></div>
                <p className="skeleton-p">
          <span>
            Explore the importance of character in leadership. Learn how to
            develop your character, setting the direction for your 30-day
            leadership journey.
          </span>
                </p>
                <div className="c-details skeleton-details">
                    <span className="it">Time :</span> <span className="reg"/>{" "}
                    <span className="c_line"/> <span className="it">Access :</span>{" "}
                    <span className="reg"/>
                    <a href="#" className="c_button"></a>
                </div>
            </div>
        </>
    )
}

export default CourseSkeleton