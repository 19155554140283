import { useEffect, useRef, useState } from 'react';
import {api} from "@iblai/ibl-web-react-common";

interface  userInfoData {
    "name" : string,
    "username": string,
    "email" : string,
    "bio": string,

    "social_links":
        {
            "platform": string,
            "social_link": string
        }[]
    ,

    "profile_image": {
        "has_image": boolean,
        "image_url_full": string,
        "image_url_large": string,
        "image_url_medium": string,
        "image_url_small": string
    }
}


function usePublicProfile(learnerID : string | undefined) {
    const [userInfo, setUserInfo] = useState<userInfoData | {}>({});
    const [userInfoLoaded, setUserInfoLoaded] = useState(false);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (learnerID){
            if (!hasFetchedData.current) {
                api.ibledxusers.getUsersInfo(
                    {
                        username: learnerID,
                    },
                    (data : any) => successCB(data),
                    (error:any ) => errorCB(error)
                )
                hasFetchedData.current = true;
            }
        }

    }, [learnerID]);

    const successCB = (data : userInfoData) => {
        setUserInfo(data);
        setUserInfoLoaded(true);
    };

    const errorCB = (error : any) => {
        setUserInfo({})
        setUserInfoLoaded(true);

    }

    return {
        userInfo ,
        userInfoLoaded,
    };
}

export { usePublicProfile };
