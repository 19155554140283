import {NavLink, useNavigate} from "react-router-dom";
import {enrollUserToCourse} from "../../hooks/utils/helpers";
import React, {useState} from "react";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";

interface CourseAccessButtonProps {
    courseInfo: any;
    courseID: string | undefined;
    eligibleToEnroll: boolean;
    Loaded: boolean;
    courseCompleted?: boolean;
}


const CourseAccessButton: React.FC<CourseAccessButtonProps> = ({courseInfo, courseID, eligibleToEnroll, Loaded, courseCompleted}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading ] = useState(false)

    const enrollUser = () => {
        setIsLoading(true)
        enrollUserToCourse(courseID || "", (data)=>{
            setIsLoading(false)
            navigate(`/course-working/${courseID}`)

        })

    }


    return (
        <div className="course-info-panel">
            <div className="time-1-2-hours">
            <span className="instrumentserif-normal-congress-blue-22-8px">
              Time :
            </span>
                <span className="span1 course-time">{courseInfo?.duration}</span>
            </div>
            <div className="frame-21">
                <img className="line-8" src="/img/line-7.svg" alt="Line 8"/>
                <div className="access-open">
              <span className="instrumentserif-normal-congress-blue-22-8px">
                Access :{" "}
              </span>
                    <span className="span1 access-status">{Loaded && eligibleToEnroll ? (courseCompleted ? "Complete" : ("Available Now") ): ("Complete Previous Module")}</span>
                </div>
            </div>
            <div className="course-cta">
                <div>
                    {eligibleToEnroll && (
                        isLoading ? (
                            <IBLSpinLoader size={40} containerHeight="150px"/>

                        ) : (
                            <NavLink
                                onClick={() => enrollUser()}
                                to="#"
                                className="align-self-flex-start"
                            >
                                <div className="button">
                                    <div className="text-2 instrumentsans-normal-white-26px">
                                        <svg
                                            width={19}
                                            height={18}
                                            viewBox="0 0 19 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </NavLink>
                        )
                    )}
                </div>

            </div>

        </div>

    )
}

export default CourseAccessButton;