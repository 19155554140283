// src/routes.tsx
import React from 'react';
import {
    isRouteErrorResponse,
    useRouteError,
} from 'react-router-dom';
import {createBrowserRouter, RouteObject} from 'react-router-dom';

import {
    getUserDataFromCookie, inIframe,
    Logout,
} from '@iblai/ibl-web-react-common';
import Error404 from "./views/Error404/Error404";
import Error403 from "./views/Error403/Error403";
import IndexView from "./views/IndexView/IndexView";
import HomePage from "./views/HomePage/HomePage";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import CourseOverview from "./views/CourseOverview/CourseOverview";
import CourseCatalogue from "./views/CourseCatalogue/CourseCatalogue";
import Contact from "./views/Contact/Contact";
import ProfileLearner from "./views/ProfileLearner/ProfileLearner";
import Dashboard from "./views/Dashboard/Dashboard";
import CourseWorking from "./views/CourseWorking/CourseWorking";
import PresentedBy from "./components/PresentedBy/PresentedBy";
import AboutUs from "./views/AboutUs/AboutUs";
import BroughtToYou from "./views/BroughtToYou/BroughtToYou";
import SsoLoginWrapper from "./components/SsoLoginWrapper/SsoLoginWrapper";
import ProfileGuide from "./views/ProfileGuide/ProfileGuide";
import CourseProgress from "./views/CourseProgress/CourseProgress";
import Certificate from './views/Certificate/Certificate';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';


const ErrorPage = (): React.ReactElement | null => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            //TODO create a proper error page (404)
            window.location.href="/"
            //return <Error404 redirect={"/"}/>;
        } else if (error.status === 403) {
            //TODO create a proper error page (403)
            window.location.href="/"
           //return <Error403 redirect={"/"}/>;
        } else if (error.status === 401) {
            if (JSON.parse(error.data)?.shouldBeLoggedIn) {
                localStorage.setItem('redirect-path', window.location.pathname);
                window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
            }
        }
    }
    return null; // Ensure the function always returns a value
};

const authCheckFn = (shouldBeAdmin: boolean, shouldBeLoggedIn: boolean, appData: any) => {
    return () => {
        if (
            shouldBeAdmin &&
            (!appData.hasOwnProperty('current_tenant') ||
                !appData.current_tenant.is_admin)
        ) {
            throw new Response('Not Admin', {status: 403});
        }
        if (!shouldBeLoggedIn) {
            return [];
        }
        const userDataFromCookie = getUserDataFromCookie();
        if (
            shouldBeLoggedIn &&
            (!appData?.axd_token ||
                !appData?.dm_token ||
                !userDataFromCookie ||
                userDataFromCookie?.user_id !== appData.userData?.user_id)
        ) {
            if (!inIframe()) {
                throw new Response(JSON.stringify({shouldBeLoggedIn}), {
                    status: 401,
                });
            } else {
                return [];
            }
        }
        return [];
    };
};


const createRoutes = (appData: any): RouteObject[] => [
    {
        path: '',
        element: <IndexView/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: '',
                element: <HomePage>
                    <Header/>
                    <Footer/>
                </HomePage>,
                loader: authCheckFn(false, false, appData),

            },

            {
                path: '/course-overview/:courseID',
                element: <CourseOverview>
                    <Header/>
                    <Footer/>
                </CourseOverview>,
                loader: authCheckFn(false, true, appData),

            },

            {
                path: '/course-catalogue',
                element: <CourseCatalogue>
                    <Header/>
                    <Footer/>
                </CourseCatalogue>,
                loader: authCheckFn(false, false, appData),

            },

            {
                path: '/contact',
                element: <Contact>
                    <Header/>
                    <Footer/>
                </Contact>,
                loader: authCheckFn(false, false, appData),

            },

            {
                path: '/profile-learner',
                element: <ProfileLearner>
                    <Header/>
                    <Footer/>
                </ProfileLearner>,
                loader: authCheckFn(false, true, appData),

            },

            {
                path: '/user-dashboard',
                element: <Dashboard>
                    <Header/>
                    <Footer/> 
                </Dashboard>,
                loader: authCheckFn(false, true, appData),

            },

            {
                path: '/course-working/:courseID/:unitID?',
                element: <CourseWorking>
                    <Header/>
                    <Footer/>
                </CourseWorking>,
                loader: authCheckFn(false, true ,appData),

            },

            // {
            //     path: '/course-progress/:courseID',
            //     element: <CourseProgress>
            //         <Header/>
            //         <Footer/>
            //     </CourseProgress>,
            //     loader: authCheckFn(false, true, appData),

            // },

            {
                path: '/course-notes/:courseID',
                element: <CourseProgress>
                    <Header/>
                    <Footer/>
                </CourseProgress>,
                loader: authCheckFn(false, true, appData),

            },

            {
                path: '/profile-guide/:learnerID',
                element: <ProfileGuide>
                    <Header/>
                    <Footer/>
                </ProfileGuide>,
                loader: authCheckFn(false, true, appData),

            },

            {
                path: '/presented-by',
                element: <PresentedBy>
                    <Header/>
                    <Footer/>
                </PresentedBy>,
                loader: authCheckFn(false, false, appData),

            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy>
                    <Header />
                    <Footer />
                </PrivacyPolicy>,
                loader: authCheckFn(false, false, appData),

            },

            {
                path: '/course-overview',
                element: <AboutUs>
                    <Header/>
                    <Footer/>
                </AboutUs>,
                loader: authCheckFn(false, false, appData),

            },
            {
                path: '/certificate/:credentialID',
                element: <Certificate/>,
                loader: authCheckFn(false, false, appData),

            },
            {
                path: '/brought-to-you-by',
                element: <BroughtToYou>
                    <Header/>
                    <Footer/>
                </BroughtToYou>,
                loader: authCheckFn(false, false, appData),

            },
            {
                path: 'sso-login',
                element: <SsoLoginWrapper />,
                loader: authCheckFn(false, false, appData),
            },


        ]

    },


];

const routes = (appData: any) => createBrowserRouter(createRoutes(appData));

export default routes;