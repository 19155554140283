const WhyLeadDynamicSvg = ({overlayWidth}: {overlayWidth: number}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="92" viewBox="0 0 93 92" fill="none">
            <defs>
                <clipPath id="circleClip">
                    <rect x="1.56593" y="0.88734" width="89.717" height="89.717" rx="44.8585" />
                </clipPath>
            </defs>

          
            <rect x="1.56593" y="0.88734" width="89.717" height="89.717" rx="44.8585" fill="#DFDFDF" />

          
            <rect x="1.56593" y="0.88734" width={overlayWidth} height="89.717" fill="#0A3887" clip-path="url(#circleClip)" />

         
            <g clip-path="url(#clip0_2568_4847)">
                <path d="M66.4245 73.2886C66.0779 73.2886 65.7942 72.9991 65.7942 72.6453C65.7942 67.173 63.6477 61.6651 59.9043 57.5296C56.0489 53.2691 51.024 50.9244 45.7469 50.9244C34.6954 50.9244 25.703 60.1031 25.703 71.3835C25.703 71.7374 25.4194 72.0269 25.0727 72.0269C24.726 72.0269 24.4424 71.7374 24.4424 71.3835C24.4424 59.3918 33.9986 49.6376 45.7469 49.6376C51.3776 49.6376 56.7318 52.1289 60.8288 56.6575C64.7822 61.0289 67.0513 66.8549 67.0513 72.6488C67.0513 73.0027 66.7677 73.2922 66.421 73.2922L66.4245 73.2886Z" fill="#ACACAC" />
                <path d="M46.0397 56.8115C45.6931 56.8115 45.4094 56.5219 45.4094 56.1681C45.4094 52.147 47.7136 48.5942 51.2783 47.1109C56.8075 44.809 60.2742 39.1653 59.9031 33.0675C59.4688 25.9583 53.8906 20.2609 46.9257 19.8213C43.0423 19.5711 39.3479 20.9293 36.5256 23.6386C33.6997 26.3479 32.1449 30.0187 32.1449 33.9754C32.1449 34.3293 31.8613 34.6188 31.5146 34.6188C31.1679 34.6188 30.8843 34.3293 30.8843 33.9754C30.8843 29.7256 32.6246 25.6152 35.6606 22.7021C38.7456 19.7462 42.7726 18.27 47.0062 18.5381C54.6049 19.0206 60.6909 25.2327 61.1637 32.9889C61.5699 39.6406 57.788 45.7955 51.7545 48.3047C48.666 49.5878 46.67 52.676 46.67 56.1717C46.67 56.5255 46.3864 56.815 46.0397 56.815V56.8115Z" fill="#ACACAC" />
                <path d="M46.0404 71.1619C43.7573 71.1619 41.9014 69.2675 41.9014 66.9371C41.9014 64.6066 43.7573 62.7123 46.0404 62.7123C48.3235 62.7123 50.1794 64.6066 50.1794 66.9371C50.1794 69.2675 48.3235 71.1619 46.0404 71.1619ZM46.0404 64.0026C44.4541 64.0026 43.162 65.3215 43.162 66.9406C43.162 68.5598 44.4541 69.8787 46.0404 69.8787C47.6267 69.8787 48.9188 68.5598 48.9188 66.9406C48.9188 65.3215 47.6267 64.0026 46.0404 64.0026Z" fill="#ACACAC" />
                <path d="M45.7493 43.5644C40.4652 43.5644 36.1616 39.1752 36.1616 33.778C36.1616 28.3809 40.4617 23.9952 45.7493 23.9952C51.0369 23.9952 55.3371 28.3845 55.3371 33.778C55.3371 39.1716 51.0369 43.5644 45.7493 43.5644ZM45.7493 25.282C41.1586 25.282 37.4222 29.0957 37.4222 33.778C37.4222 38.4603 41.1586 42.2777 45.7493 42.2777C50.3401 42.2777 54.0764 38.4639 54.0764 33.778C54.0764 29.0922 50.3401 25.282 45.7493 25.282Z" fill="#ACACAC" />
            </g>

           
            <rect x="1.56593" y="0.88734" width="89.717" height="89.717" rx="44.8585" stroke="#ACACAC" stroke-width="1.58791" fill="none" />

            <defs>
                <clipPath id="clip0_2568_4847">
                    <rect width="42.609" height="54.783" fill="white" transform="translate(24.4448 18.5059)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WhyLeadDynamicSvg;