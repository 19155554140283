import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {getEnabledCourse, isEligibleToEnrollV2} from "../../hooks/utils/helpers";
import CourseSkeleton from "../CourseSkeleton/CourseSkeleton";
import {CourseCatalogueItem, useCourseCatalogue} from "../../hooks/useCourseCatalogue";

const Catalogue = ({ username }: { username?: string }) => {
    const [coursesData, courseDataLoaded] = useCourseCatalogue();
    const [eligibilityStatus, setEligibilityStatus] = useState<{ [key: string]: boolean }>({});
    const [completedStatus, setCompletedStatus] = useState<{ [key: string]: boolean }>({});
    const [loadingStatus, setLoadingStatus] = useState<{ [key: string]: boolean }>({});
    const [detailsLoaded, setDetailsLoaded] = useState<{ [key: string]: boolean }>({});
    const [enabledCoursse, setEnabledCourses] = useState<CourseCatalogueItem[]>([])




    useEffect(() => {
        const fetchedEnabledCourses = getEnabledCourse(coursesData, username)
        setEnabledCourses(fetchedEnabledCourses)
        fetchedEnabledCourses.forEach((courseInstance) => {
            const courseId = courseInstance.search?._source?.course_id;
            if (courseId) {
                setLoadingStatus((prev) => ({
                    ...prev,
                    [courseId]: true,
                }));


                /*if (courseId === "course-v1:Legatum+100+2023"){
                    setEligibilityStatus((prev) => ({
                        ...prev,
                        [courseId]: true,
                    }));
                    setCompletedStatus((prev) => ({
                        ...prev,
                        [courseId]: eligibleData?.completed,
                    }));
                    setLoadingStatus((prev) => ({
                        ...prev,
                        [courseId]: false,
                    }));
                    setDetailsLoaded((prev) => ({
                        ...prev,
                        [courseId]: true,
                    }));

                    return
                }*/

                isEligibleToEnrollV2(courseInstance?.metadata?.custom?.prerequisite, null, true).then((eligibleData : boolean | {eligible:boolean, completed:boolean}) => {
                    if (typeof eligibleData !== "boolean") {
                        setEligibilityStatus((prev) => ({
                            ...prev,
                            [courseId]: courseId === "course-v1:Legatum+100+2023" ? true : eligibleData.eligible,
                        }));
                    }
                    setLoadingStatus((prev) => ({
                        ...prev,
                        [courseId]: false,
                    }));
                    setDetailsLoaded((prev) => ({
                        ...prev,
                        [courseId]: true,
                    }));
                });
                isEligibleToEnrollV2(courseId, null, true).then((eligibleData : boolean | {eligible:boolean, completed:boolean}) => {
                    if (typeof eligibleData !== "boolean") {
                        setCompletedStatus((prev) => ({
                            ...prev,
                            [courseId]: eligibleData.completed,
                        }));
                    }
                });
            }
        });
    }, [coursesData, courseDataLoaded, username]);

    const enrollmentLink = (courseId: string) => (
        <NavLink to={`/course-overview/${courseId}`} className="c_button">
            <svg
                width={19}
                height={18}
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.0150782 7.704H17.1231L16.2131 8.432L7.89308 0.0599985H10.4931L18.9951 8.64L10.4671 17.22H7.86708L16.2131 8.848L17.1231 9.576H0.0150782V7.704Z"
                    fill="#fff"
                />
            </svg>
        </NavLink>
    );

    return (
        <>
            {courseDataLoaded ? (
                enabledCoursse.map((courseInstance) => {
                    const courseId = courseInstance?.search?._source?.course_id || "";

                    return (
                        <div
                            className="course-item"
                            data-id={courseId}
                            style={{ display: "block" }}
                            key={courseId}
                        >
                            <div className="top-c">
                                <img
                                    src={`${process.env.REACT_APP_IBL_LMS_URL}${courseInstance?.metadata?.course_image_asset_path}`}
                                    alt={courseInstance.search?._source.name}
                                />
                            </div>
                            <div className="c-name">{courseInstance.search?._source.name}</div>
                            <p>{courseInstance?.metadata?.description}</p>
                            <div className="c-details">
                                <span className="it">Time :</span>{" "}
                                <span className="reg">{courseInstance?.metadata?.duration}</span>{" "}
                                {detailsLoaded[courseId] && (
                                    <>
                                        <span className="c_line" /> <span className="it">Access :</span>{" "}
                                        {eligibilityStatus[courseId] ? (
                                            <>
                                                <span id="access" className="reg">{completedStatus[courseId] ? "Complete" : "Available Now" }</span>
                                                {enrollmentLink(courseId)}
                                            </>
                                        ) : (
                                            <>
                                                <span id="access" className="reg">Complete Previous Module</span>
                                                {enrollmentLink(courseId)}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })

            ) : (
                <CourseSkeleton />
            )}
        </>
    );
};

export default Catalogue;
