import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  getParameterByName, getFirstAvailableUnit} from "@iblai/ibl-web-react-common";
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import './CourseMap.css';
import CourseCompletionCheckMark from "../../views/CourseWorking/CourseCompletionCheckMark";

interface CourseMapProps {
    courseOutlineData: any;
    unitID: string | undefined;
    courseID: string | undefined;
}

const CourseSideItemContext = React.createContext<CourseSideItemContextType | null>(null);
const CourseSideItemProvider = CourseSideItemContext.Provider;

interface CourseSideItemContextType {
    handleOutlineClick: (selectedUnitID: string) => void;
    setHighlightOnMenu: () => void;
    activeCourseID: string;
    activeSecondLevelOutline: string | null;
    activeFirstLevelOutline: string | null;
    setActiveCourseID: React.Dispatch<React.SetStateAction<string>>;
    setActiveSecondLevelOutline: React.Dispatch<React.SetStateAction<string | null>>;
    setActiveFirstLevelOutline: React.Dispatch<React.SetStateAction<string | null>>;
}

const CourseMap: React.FC<CourseMapProps> = ({ courseOutlineData, unitID, courseID }) => {
    const navigate = useNavigate();

    const [activeCourseID, setActiveCourseID] = useState(unitID || '');
    const [activeSecondLevelOutline, setActiveSecondLevelOutline] = useState<string | null>(null);
    const [activeFirstLevelOutline, setActiveFirstLevelOutline] = useState<string | null>(null);
    const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

    const handleOutlineClick = (selectedUnitID: string) => {
        navigate(`/course-working/${courseID}/${selectedUnitID}`);
    };

    const handleAccordionClick = (index: string) => {
        setExpandedSections((prevExpandedSections) => ({
            ...prevExpandedSections,
            [index]: !prevExpandedSections[index],
        }));
    };

  

    const setHighlightOnMenu = () => {
        const unitId =
            getParameterByName('unit_id') || unitID || getFirstAvailableUnit(courseOutlineData).id;
        setActiveCourseID(unitId);
    };

    useEffect(() => {
        setHighlightOnMenu();
    }, [courseOutlineData, courseID, unitID]);

    return (
        <CourseSideItemProvider value={{
            handleOutlineClick,
            setHighlightOnMenu,
            activeCourseID,
            activeSecondLevelOutline,
            activeFirstLevelOutline,
            setActiveCourseID,
            setActiveSecondLevelOutline,
            setActiveFirstLevelOutline
        }}>
            {courseOutlineData?.children?.map((item: any, index: number) => (
                <div className="faq-question-wrap-3" key={index}>
                    <CourseOutline defaultOpen={index === 0} key={`course-outline-${index}`} item={item} index={index} />
                </div>
            ))}
        </CourseSideItemProvider>
    );
};

const CourseOutline: React.FC<{ item: any; index: number; defaultOpen: boolean }> = ({ item, index, defaultOpen = false }) => {
    const [open, setIsOpen] = useState(defaultOpen);

    const handleToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsOpen(!open);
    };

    return (
        <div className="faq-question-wrap-3" key={index}>
            {/*<a
                data-collapsed={'true'}
                href="#"
                className="first-level-outline faq-question-bar-3 w-inline-block"
                onClick={handleToggle}
            >
                <div className="div-block-632 w-clearfix">
                    {
                        open ? <FaChevronDown size={15} color="#0A3887" /> :
                            <FaChevronRight size={15} color="#0A3887" />
                    }
                    <div className="question-title-4 f">{item.display_name}</div>
                </div>
            </a>*/}
            {
                Array.isArray(item.children) &&
                item.children.map((childItem: any, childIndex: number) => {
                    return Array.isArray(childItem.children) &&
                        childItem.children.length > 0 &&
                        <SecondLevelOutline defaultOpen={childIndex === 0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} innerChildItem={childItem} />
                })
            }
        </div>
    );
};

const SecondLevelOutline: React.FC<{ innerChildItem: any; index: string; defaultOpen: boolean }> = ({ innerChildItem, index, defaultOpen = false }) => {
    const [open, setIsOpen] = useState(defaultOpen);

    const handleToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsOpen(!open);
    };

    return (
        <>
            {/*<a
                data-collapsed={'true'}
                href="#"
                className="second-level-outline faq-question-bar-3 w-inline-block"
                onClick={handleToggle}
            >
                <div className="div-block-632 w-clearfix">
                    {
                        open ? <FaChevronDown size={15} color="#0A3887" /> :
                            <FaChevronRight size={15} color="#0A3887" />
                    }
                    <div className="question-title-4 f">{innerChildItem.display_name}</div>
                </div>
            </a>*/}
            {
                /*open &&*/ Array.isArray(innerChildItem.children) &&
                innerChildItem.children.map((innerGrandChildItem: any, grandChildIndex: number) => (
                    <ThirdLevelOutline defaultOpen={grandChildIndex === 0} index={grandChildIndex} key={`${index}-${grandChildIndex}`} data={innerGrandChildItem} />
                ))
            }
        </>
    );
};

const ThirdLevelOutline: React.FC<{ data: any; index: number; defaultOpen: boolean }> = ({ data, index, defaultOpen = false }) => {
    const [open, setIsOpen] = useState(defaultOpen);
    const context = useContext(CourseSideItemContext);

    if (!context) {
        throw new Error("ThirdLevelOutline must be used within a CourseSideItemProvider");
    }

    const { handleOutlineClick, activeCourseID } = context;

    return (
        <div className={`third-level-outline faq-content-3 open`}>
            <div className={`inner open`}>
                <div
                    data-id={data.id}
                    className={`module_row ${activeCourseID === data.id ? "active" : ""}`}
                    onClick={() => handleOutlineClick(data.id)}
                >
                    <div className="div-block-632 w-clearfix">
                        <div className="inner_text">
                            {
                                data?.complete ?
                                    <IoCheckmarkCircle color="#0A3887" size={17} style={{
                                        minWidth: "17px",
                                    }} />
                                    :
                                    <CourseCompletionCheckMark courseData={data} />
                            }
                            <a href="#" className={`c_l`}>
                                {data.display_name}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseMap;
