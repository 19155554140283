import React, {Dispatch, SetStateAction, useEffect, useState, useRef, RefObject} from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import {SlRequestCloseEvent} from "@shoelace-style/shoelace/cdn/events/sl-request-close";
import Select from 'react-select';
import {
    getCountriesList,
    CountryListItem,
    CityListItem,
    getCityNamesByCountryCode, LOCATION_DATA, getCityLatLng
} from "../../hooks/utils/location-helper";
import {ActionMeta, OnChangeValue} from "react-select/dist/declarations/src/types";
import {getUserName} from "@iblai/ibl-web-react-common";
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import { useDebounce } from '../../hooks/useDebounce';
import {CustomElements, SlAlertProps} from "@shoelace-style/shoelace/cdn/types/vue";
import useUserLocation from "../../hooks/useUserLocation";

interface UserLocationSettingsProps{
    userHasRegisteredLocation:boolean,
    setHasUserRegisteredLocation:Dispatch<SetStateAction<boolean>>
}

type NotificationRefType = {
    toast: () => void;
};
const UserLocationSettings:React.FC<UserLocationSettingsProps>= ({userHasRegisteredLocation, setHasUserRegisteredLocation }) => {

    const {handleSaveLocation} = useUserLocation()

    // Prevent the dialog from closing when the user clicks on the overlay
    function handleRequestClose(event:SlRequestCloseEvent) {
        if (event.detail.source === 'overlay') {
            event.preventDefault();
        }
    }

    const [countries, setCountries] = useState<CountryListItem[]>([])
    const [cities, setCities] = useState<CityListItem[]>([])
    const [selectedCountry, setSelectedCountry] = useState<CountryListItem | null>(null)
    const [selectedCity, setSelectedCity] = useState<CityListItem | null>(null)
    const [submitting, setSubmitting] = useState(false)

    const [notification, setNotification] = useState({
        success:false,
        msg:""
    })
    const notificationRef = useRef<any>(null)
    const citySelectRef = useRef<any>(null)
    const handleSetNotification = (success:boolean, msg:string, callback=()=>{}) => {
        setNotification({
            msg: msg,
            success
        })
        setTimeout(()=>{
            callback()
        }, 1500)
    }

    useDebounce(()=>{
        if(notification?.msg && notificationRef?.current){
            if("toast" in  notificationRef.current){
                notificationRef.current?.toast()
            }

        }
    }, 200, [notification])

    useEffect(()=>{
       setCountries(getCountriesList())
    }, [])

    useEffect(() => {
        if(selectedCountry){
            setCities(getCityNamesByCountryCode(selectedCountry.value))
        }
    }, [selectedCountry]);

    const handleCountryChange = (option: CountryListItem | null, actionMeta: ActionMeta<CountryListItem>) =>{
        setCities([])
        setSelectedCountry(option)
        citySelectRef?.current?.clearValue();
        setSelectedCity(null)
    }

    const handleCityChange = (option: CountryListItem | null, actionMeta: ActionMeta<CountryListItem>) =>{
        setSelectedCity(option)
    }

    const closeModal = () =>{
        setHasUserRegisteredLocation(true)
    }

    const handleSubmit = () => {
        if(selectedCountry && selectedCity){
            setSubmitting(true)
            const data={
                "country_code": selectedCountry?.value,
                "country_name": selectedCountry?.label,
                "city": selectedCity?.label,
                "city_gps": getCityLatLng(selectedCountry.value, selectedCity.label)
            }
            handleSaveLocation(data, ()=>{
                handleSetNotification(true, "Location data submitted successfully.", ()=>{
                    closeModal()
                })
            }, ()=>{
                setSubmitting(false)
                handleSetNotification(false, "An error occurred while saving location data.")
            })
        }
    }


    return (
        <>
            <SlAlert ref={notificationRef} variant={notification?.success ? "success" : "danger"} duration={5000} closable>
                <SlIcon slot="icon" name={notification?.success ? "check2-circle" : "exclamation-octagon"} />
                <strong>{notification?.msg}</strong>
            </SlAlert>
            <SlDialog className={"location-dialog"} label="Location settings" open={!userHasRegisteredLocation} onSlRequestClose={handleRequestClose} onSlAfterHide={() => closeModal()}>
                <div className={"w-100"}>
                    Fill in the form below to set your country & city location.
                    <form className={"w-100 user-location-form"}>
                        <div className={"w-100 user-location-form-container"}>
                            <label>Country *</label>
                            <div className={"w-100"}>
                                <Select
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    name="country"
                                    options={countries} // Static options go here
                                    onChange={handleCountryChange}
                                    isSearchable={true}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            position: "absolute",
                                        }),
                                    }}
                                />
                            </div>

                        </div>
                        <div className={"w-100 user-location-form-container city-container"}>
                            <label>City *</label>
                            <div className={"w-100"}>
                                <Select
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    name="city"
                                    options={cities} // Static options go here
                                    onChange={handleCityChange}
                                    isSearchable={true}
                                    ref={citySelectRef}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            position: "absolute",
                                        }),
                                    }}
                                />
                            </div>

                        </div>
                    </form>
                </div>
                <SlButton disabled={!selectedCity} className={"legatum-btn"} slot="footer" loading={submitting}
                          onClick={() => handleSubmit() }>
                    Save
                </SlButton>
            </SlDialog>
        </>
    );
};

export default UserLocationSettings;