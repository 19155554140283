import useHeaderFooter from "../../hooks/useHeaderFooter";
import './PrivacyPolicy.css'

const PrivacyPolicy = ({children}: {children: React.ReactNode}) => {
    const { headerContent, footerContent } = useHeaderFooter(children);
    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about" />
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <div
                                        className="overlap-group-item"
                                        style={{
                                            backgroundImage: 'url("/img/privacy-policy_.jpg")',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            backgroundBlendMode: 'overlay',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        role="img"
                                        aria-label="Privacy policy background"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                                        <span className="recklessneue-regular-normal-white-100-1px">
                                            Privacy Policy{" "}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5 pres-overlap">
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px pres-desc">
                                Legatum Limited and its affiliates (together “we”, “us”, “our” or “Legatum”) are committed to respecting your privacy. This privacy policy explains how Legatum uses the personal and course data we collect from you when you use the Leading with Character website.
                            </p>
                        </div>
                    </div>
                    <div className="legatum-container the-course">
                        <div className="c-section">
                            <div className="leg-title">What data do we collect?</div>
                            <p className="leg-p">
                                We may collect personal information from you as you use our website, complete the course, or when you contact or request information from us. The personal information that we process includes:
                                <ul style={{ margin: '5px' }}>
                                    <li style={{ margin: '5px' }}>basic information, such as your name (including name, prefix or title), the company you work for, your job title or position;</li>
                                    <li style={{ margin: '5px' }}>contact information, such as your email address and phone number;</li>
                                    <li style={{ margin: '5px' }}>financial information, such as payment-related information;</li>
                                    <li style={{ margin: '5px' }}>technical information, such as information from your visits to our website or applications, or in relation to materials and communications we send to you electronically;</li>
                                    <li style={{ margin: '5px' }}>responses to select course questions, such as your goals and achievements within the course, ways to improve the course, and ratings at the start and conclusion of each module;</li>
                                    <li style={{ margin: '5px' }}>any other information relating to you which you may provide to us.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">How do we collect your data?</div>
                            <p className="leg-p">
                                The majority of the information we hold about you will have been provided directly by you. This means that we collect your personal information upon course registration, when you submit a contact form or interact with us directly, or when you submit responses to the course prompts, including discussion forums, rating scale questions, and open response assessments.
                            </p>
                            <p className="leg-p">
                                In some cases, we may collect or receive information about you from other sources, including supplementing the information you have given us with data from elsewhere. Examples include adding social, economic, financial, or demographic data to our visitor information and improving or correcting contact details using publicly available sources. Sources of data could include information gathered from a news article or online media (including social media) and publicly available directories. We may also obtain information about people entirely new to us. Examples include (but are not limited to) if an existing contributor suggests we get in touch to see if you might be interested in becoming involved with us.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">How will we use your data?</div>
                            <p className="leg-p">
                                We collect and process personal information about you in a number of ways, including through your use of our website and the Leading with Character course. We use that information to:
                                <ul style={{ margin: '5px' }}>
                                    <li style={{ margin: '5px' }}>provide and improve this website and the Leading with Character course, including auditing and monitoring its use;</li>
                                    <li style={{ margin: '5px' }}>provide and improve our services to you;</li>
                                    <li style={{ margin: '5px' }}>provide information requested by you;</li>
                                    <li style={{ margin: '5px' }}>develop additional content and courses;</li>
                                    <li style={{ margin: '5px' }}>promote our services and market the course, including sending updates, publications, and details of events and other offerings. Direct quotes from your responses may be used in these promotional materials, but all personally identifiable data will be redacted to protect your confidentiality;</li>
                                    <li style={{ margin: '5px' }}>manage and administer our relationship with you (including administering correspondence with you);</li>
                                    <li style={{ margin: '5px' }}>provide anonymized responses to our research partners, such as the Oxford Character Project at the University of Oxford and the Human Flourishing Program at Harvard University, to process and analyze the impact of the Leading with Character online course.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">Where will we process your data?</div>
                            <p className="leg-p">
                                We process your data at our head offices and in any other places where the parties involved in the processing are located. Depending on your location, data transfers may involve transferring your data to a country other than your own. You are also entitled to learn about the legal basis of data transfers to a jurisdiction outside the Dubai International Financial Centre and about the security measures taken by us to safeguard your data.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">Protecting your data</div>
                            <p className="leg-p">
                                We keep your data secure in our database with appropriate security mechanisms in place. We may share your personal data with other organizations where it is necessary for the purpose for which you have given us the data or as described in this notice. For example, we may pass data to other organizations, known as Data Processors, to provide specific services to us. A contract is always in place with a Data Processor, and they are not allowed to do anything with your data other than that which we have requested. In these cases, we never lose legal control of your data.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">What are your data protection rights?</div>
                            <p className="leg-p">
                                Legatum would like to make sure you are fully aware of your data protection rights. Every user is entitled to the following:
                                <ul style={{ margin: '5px' }}>
                                    <li style={{ margin: '5px' }}>The right to access. You have the right to request copies of your personal data from us;</li>
                                    <li style={{ margin: '5px' }}>The right to rectification. You have the right to request that we correct any information you believe is inaccurate. You also have the right to request we complete information you believe is incomplete;</li>
                                    <li style={{ margin: '5px' }}>The right to erasure. You have the right to request that we erase your personal data, under certain conditions;</li>
                                    <li style={{ margin: '5px' }}>The right to restrict processing. You have the right to request that we restrict the processing of your personal data, under certain conditions;</li>
                                    <li style={{ margin: '5px' }}>The right to object to processing. You have the right to object to us processing your personal data, under certain conditions;</li>
                                    <li style={{ margin: '5px' }}>The right to data portability. You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">How long do we retain your data for?</div>
                            <p className="leg-p">
                                Data shall be processed and stored for as long as required by the purpose it has been collected for. We may be allowed to retain data for a longer period whenever you have given consent to such processing, provided that such consent is not withdrawn. Furthermore, we may be obliged to retain data for a longer period, whenever required to do so, for the performance of a legal obligation or upon the order of a legal or regulatory authority. Once the retention period expires, your data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the retention period expires.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">Privacy policies of other websites</div>
                            <p className="leg-p">
                                This website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy carefully.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">Changes to our privacy policy</div>
                            <p className="leg-p">
                                We keep our privacy policy under regular review and place any updates on this web page. This privacy policy was last updated on 30 September 2024.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">How to contact us</div>
                            <p className="leg-p">
                                If you have any questions about Legatum’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
                                <br />
                                Email us at: <a href="mailto:support@leadingwithcharacter.com">support@leadingwithcharacter.com</a>
                                <br />
                                Call us at: <a href="tel:+97143175800">+971 4 317 5800</a>
                                <br />
                                Or write to us: Leading with Character, Level 2, Legatum Plaza, DIFC, PO Box 506625, Dubai, UAE
                            </p>
                        </div>


                        
                    </div>

                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>

    )
}

export default PrivacyPolicy;