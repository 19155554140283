import { useCredential } from "@iblai/ibl-web-react-common";
import { useParams } from "react-router-dom";
import './Certificate.css';
import React, { useEffect } from 'react';


const Certificate = () => {
    const { credentialID } = useParams();
    const [credentialData, credentialDataLoaded] = useCredential(credentialID || "");


 

    useEffect(() => {
        if (credentialDataLoaded && Object.keys(credentialData).length > 0) {
            const certificateContainer = document.getElementById('student-certificate');
            if (certificateContainer) {
                certificateContainer.style.display = 'block';
            }
        }
    }, [credentialData, credentialDataLoaded]);


    const getCourseName = (credentialsCourseName: string): string => {
        const courseNameMap: { [key: string]: string } = {
            "Why Lead": "Why Lead",
            "Courage": "Leading Self",
            "Love": "Leading Together",
            "Hope": "Leading Change",
            "Forward": "Leading with Character"
        };

        for (const [key, value] of Object.entries(courseNameMap)) {
            if (credentialsCourseName.includes(key)) {
                return value;
            }
        }
        return credentialsCourseName; // Default case: return the original name
    }

    const setBGImage = (courseName: string) => {
        const elements = {
            certificateContainer: document.querySelector('.certificate-container') as HTMLElement,
            bodyOverlay: document.querySelector('.body-overlay') as HTMLElement,
            certWrapper: document.querySelector('.cert-wrapper') as HTMLElement,
            body: document.body
        };

        const backgroundImages: { [key: string]: string } = {
            "Why Lead": "/img/william-bout-7cdFZmLlWOM-unsplash 1.png",
            "Leading Self": "/img/neom-xhMz5xIbhRg-unsplash 1.png",
            "Leading Together": "/img/getty-images-u7xBPiZH-5I-unsplash 1.png",
            "Leading Change": "/img/oskars-sylwan-TaDYbj5q1Gc-unsplash 1.png"
        };

        if (elements.certificateContainer) {
            if (backgroundImages[courseName]) {
                elements.certificateContainer.style.backgroundImage = `url("${backgroundImages[courseName]}")`;
                if (elements.bodyOverlay && ["Leading Together", "Leading Change"].includes(courseName)) {
                    elements.bodyOverlay.style.display = 'block';
                }
            } else if (courseName === "Leading with Character") {
                elements.certificateContainer.style.backgroundColor = "#fff";
                if (elements.certWrapper) elements.certWrapper.style.backgroundColor = "#fff";
                if (elements.bodyOverlay) elements.bodyOverlay.style.display = 'block';
            }
        }
    }

    const setJourneySVG = (courseName: string) => {
        const svgPaths: { [key: string]: string } = {
            "Why Lead": "/img/certSVG/why-lead.svg",
            "Leading Self": "/img/certSVG/leading-self.svg",
            "Leading Together": "/img/certSVG/leading-others.svg",
            "Leading Change": "/img/certSVG/leading-change.svg",
            "Leading with Character": "/img/certSVG/leading-forward.svg"
        };

        const svgPath = svgPaths[courseName];
        if (svgPath) {
            fetch(svgPath)
                .then(response => response.text())
                .then(data => {
                    const parser = new DOMParser();
                    const svgDocument = parser.parseFromString(data, "image/svg+xml");
                    const svgRootElement = svgDocument.querySelector("svg");
                    const jsvgElement = document.querySelector(".j-svg");
                    if (svgRootElement && jsvgElement) {
                        jsvgElement.innerHTML = '';
                        jsvgElement.appendChild(svgRootElement);
                    }
                })
                .catch(error => console.error("Error loading SVG:", error));
        }
    }

    const setCertificateData = (courseName: string, credentialData: any) => {
        const elements = {
            logoH: document.querySelector('.logo-h') as HTMLImageElement,
            certDescription: document.querySelector('.cert-description') as HTMLElement,
            certWrapper: document.querySelector('.cert-wrapper') as HTMLElement,
            svgImgOverlay: document.querySelector('.svg-img-overlay') as HTMLElement,
            bodyOverlay: document.querySelector('.body-overlay') as HTMLElement,
            body: document.querySelector('body') as HTMLElement,
            certificateContainer: document.querySelector('.certificate-container') as HTMLElement,
            svgImgOverlayBlue: document.querySelector('.svg-img-overlay-blue') as HTMLElement,
            goldBadge: document.querySelector('.gold-badge') as HTMLElement
        };

        if (courseName === "Leading with Character") {
            if (elements.logoH) elements.logoH.src = '/img/certSVG/legatum-blue-logo.svg';
            if (elements.certDescription) elements.certDescription.style.color = '#0A3887';
            if (elements.certWrapper) elements.certWrapper.style.backgroundColor = '#fff';
            if (elements.svgImgOverlay) elements.svgImgOverlay.style.display = 'none';
            if (elements.bodyOverlay) elements.bodyOverlay.classList.add('xs-white-color');
            if (elements.body) elements.body.classList.add('xs-white-color');
            if (elements.certWrapper) elements.certWrapper.classList.add('leading-forward');
            if (elements.certificateContainer) elements.certificateContainer.classList.add('leading-forward');
        } else {
            if (elements.logoH) elements.logoH.src = '/img/certSVG/Logo.svg';
            if (elements.svgImgOverlay) elements.svgImgOverlay.setAttribute('src', '/img/certSVG/Graphic.svg');
            if (elements.svgImgOverlayBlue) elements.svgImgOverlayBlue.style.display = 'none';
            if (elements.goldBadge) elements.goldBadge.style.display = 'none';
        }

        const nameElement = document.querySelector<HTMLElement>(".name");
        if (nameElement) {
            nameElement.innerHTML = credentialData?.recipient?.name;
        }

        const courseNameElement = document.querySelector<HTMLElement>(".course-name");
        if (courseNameElement) {
            courseNameElement.innerHTML = courseName;
        }
    }


    const PrintCertificate = (): void => {
        window.print();
    };



    useEffect(() => {
        if (credentialDataLoaded) {
            try {
                const courseName = getCourseName(credentialData?.course?.name);
                setBGImage(courseName);
                setJourneySVG(courseName);
                setCertificateData(courseName, credentialData);
            } catch (e) {
                console.log(e)
            }

        }
    }, [credentialDataLoaded, credentialData]);


    return (
        <>
            {credentialDataLoaded && (
                <>
                    <div className="certificate-container" id="student-certificate" style={{display: "none"}}>
                        <img src="/img/certSVG/Graphic.svg" className="svg-img-overlay" />
                        <img src="/img/certSVG/Graphic-blue.svg" className="svg-img-overlay-blue" />
                        <img src="/img/certSVG/Gold-Badge.svg" className="gold-badge" />
                        <div className="download-btn-3 download-cert" onClick={PrintCertificate}>
                            <a className="link-block-9 w-inline-block" >
                                <div className="link-block soc lnkd">
                                    <i className="fas fa-download" aria-hidden="true" />
                                </div>
                                <div className="button social_btn lnk">Download</div>
                            </a>
                        </div>
                        <div className="cert-wrapper">
                            <img className="logo-h" alt="" />
                            <div>
                                <h1 className="cert-description">Certificate of Achievement</h1>
                            </div>
                            <div className="cert-details">
                                <div>This certificate is proudly presented to</div>
                                <div className="user_full name" />
                                <div>For successful completion of the module</div>
                                <div className="module_name course-name" />
                                <div>Course Journey</div>
                                <div className="j-svg" />
                                <div className="l-w-c">
                                    Leading with Character is a 30-day leadership journey designed to help
                                    leaders build a clear leadership vision, cultivate their character,
                                    and use their influence for good
                                </div>
                                <div className="website-text">www.leadingwithcharacter.com</div>
                                <div className="c-logos">
                                    <img src="/img/c-logo/certificate.png" alt="legatum logo" className="c-logo-image-1" />
                                    <img src="/img/c-logo/oxford.png" alt="oxford logo" className="c-logo-image-2" />
                                    <img src="/img/c-logo/harvard_tree.png" alt="harvard logo" className="c-logo-image-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body-overlay" />
                </>


            )}

        </>

    )
}

export default Certificate;