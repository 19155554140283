import React from 'react';
import './ModuleOutline.css';
import {isCourseA5DayChallenge} from "../../hooks/utils/helpers";
import {useParams} from "react-router-dom";

interface ModuleOutlineProps {
    courseInfo: any;
    courseInfoLoaded: boolean;
}

const ModuleOutline: React.FC<ModuleOutlineProps> = ({ courseInfo, courseInfoLoaded }) => {
    const {courseID} = useParams();
    if (!courseInfoLoaded || !courseInfo?.course_outline?.[0]?.children?.[0]?.children?.length) {
        return <></>;
    }

    return (
        <div className="course-info-panel-2 course-info-panel-3">
            <div className="overlap-group nimg overlap-group-custom">
                {courseInfo.course_outline[0].children[0].children.map((outlineData: { name: string }, index: number) => {
                    const match = outlineData.name.match(/^(\d+(?:\.\d+)?)\s*(.*)$/);
                    const unitNumber = /*match ? match[1] : */`1.${index + 1}`;
                    const unitTitle = match ? isCourseA5DayChallenge(String(courseID))  ? match[1] + match[2] : match[2] : outlineData.name;
                    
                    return (
                        <div key={index} className="frame-21-custom">
                            <div className="unit-item a-leader-in-all-of-us instrumentserif-normal-congress-blue-37-5px">
                                <span className="unit-number">{unitNumber}</span>
                                <div className="unit-content">
                                    <span className="unit-dot"></span>
                                    <span className="unit-title">{unitTitle}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ModuleOutline;