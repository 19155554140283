const LeadingFowardDynamicSvg = ({overlayWidth}: {overlayWidth: number}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
            <defs>
                <clipPath id="circleClip">
                    <rect x="1.45619" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" />
                </clipPath>
            </defs>

            <rect x="1.45619" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" fill="#DFDFDF" />

            <rect x="1.45619" y="0.760874" width={overlayWidth} height="89.7832" fill="#0A3887" clip-path="url(#circleClip)" />

            <g clip-path="url(#clip0_2568_4808)">
                <path d="M58.8896 72.5609C58.4912 72.5609 58.1652 72.2272 58.1652 71.8194C58.1652 64.4656 52.2377 58.01 45.4813 58.01C38.7248 58.01 32.7973 63.8353 32.7973 70.9954C32.7973 71.4033 32.4714 71.737 32.073 71.737C31.6746 71.737 31.3486 71.4033 31.3486 70.9954C31.3486 63.0155 37.6906 56.5269 45.4813 56.5269C53.2719 56.5269 59.6139 63.5305 59.6139 71.8194C59.6139 72.2272 59.288 72.5609 58.8896 72.5609Z" fill="#BCBCBC" />
                <path d="M45.4783 54.1707C39.3335 54.1707 34.3315 49.054 34.3315 42.7591C34.3315 36.4641 39.3295 31.3474 45.4783 31.3474C51.6271 31.3474 56.6251 36.4641 56.6251 42.7591C56.6251 49.054 51.6271 54.1707 45.4783 54.1707ZM45.4783 32.8346C40.1303 32.8346 35.7802 37.2881 35.7802 42.7632C35.7802 48.2383 40.1303 52.6918 45.4783 52.6918C50.8263 52.6918 55.1764 48.2383 55.1764 42.7632C55.1764 37.2881 50.8263 32.8346 45.4783 32.8346Z" fill="#BCBCBC" />
                <path d="M70.564 65.2984H20.3915C19.9931 65.2984 19.6229 65.1007 19.3935 64.767C19.1641 64.4333 19.1118 64.0048 19.2486 63.6258L32.3793 27.1785H27.0031C26.4075 27.1785 25.9166 26.7624 25.8079 26.165C25.6993 25.5677 26.0091 24.995 26.5604 24.7767L45.0391 17.4106C45.3248 17.2953 45.6347 17.2953 45.9204 17.4106L64.395 24.7726C64.9504 24.995 65.2602 25.5636 65.1475 26.1609C65.0349 26.7583 64.5479 27.1744 63.9524 27.1744H58.5762L71.7068 63.6217C71.8436 64.0048 71.7913 64.4291 71.562 64.7629C71.3366 65.0965 70.9624 65.2943 70.564 65.2943V65.2984ZM20.7255 63.8153H70.23L57.1034 27.368C56.9665 26.9849 57.0189 26.5605 57.2482 26.2268C57.4776 25.8931 57.8478 25.6954 58.2462 25.6954H62.717L45.4777 18.8237L28.2425 25.6954H32.7133C33.1117 25.6954 33.4859 25.8931 33.7113 26.2268C33.9366 26.5605 33.9929 26.989 33.8561 27.368L20.7255 63.8153Z" fill="#BCBCBC" />
            </g>

            <rect x="1.45619" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" stroke="#ACACAC" stroke-width="1.52175" fill="none" />

            <defs>
                <clipPath id="clip0_2568_4808">
                    <rect width="52.6071" height="55.2374" fill="white" transform="translate(19.1768 17.3246)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default LeadingFowardDynamicSvg;
