import useHeaderFooter from "../../hooks/useHeaderFooter";
import React from "react";
interface BroughtToYouProps {
    children?: React.ReactNode
}
const BroughtToYou : React.FC<BroughtToYouProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);

    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about" />
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <img
                                        className="overlap-group-item"
                                        src="/img/BROUGHT-TO-YOU-BY-Header.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                <span className="recklessneue-regular-normal-white-100-1px">
                  Brought to you by{" "}
                </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5">
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                                This course was developed in collaboration between Legatum and the
                                Oxford Character Project, at the University of Oxford, and the Human
                                Flourishing Program, at the institute for Quantitative Social
                                Sciences at Harvard University. A global working group of character
                                and leadership scholar-practitioners also advised on the course
                                structure and content.
                            </p>
                        </div>
                    </div>
                    <div className="brought-row">
                        <div className="brought-box">
                            <div className="b-logo-div">
                                <img
                                    src="/img/Legatum_Primary_Logo 1.png"
                                    alt=""
                                    style={{ maxHeight: 130 }}
                                />
                            </div>
                            <div className="brought-line" />
                            <div className="brought-name">Legatum</div>
                            <p>
                                Legatum’s ambition is to improve people’s lives by increasing
                                prosperity across the world. To build a better world, we need
                                leaders in all sectors at all levels committed to living out the
                                virtues in their leadership. Legatum supports and creates
                                initiatives that develop leaders of character, supporting
                                individuals on their lifelong journey of personal transformation.
                            </p>
                            <div className="arrow">
                                <a href="https://www.legatum.com/" target="_blank">
                                    <svg
                                        width={48}
                                        height={48}
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.449707"
                                            y="0.16748"
                                            width="47.0427"
                                            height="47.0427"
                                            rx="23.5214"
                                            fill="#0A3887"
                                        />
                                        <path
                                            d="M13.7605 23.8192H31.2563L30.3256 24.5637L21.817 16.002H24.476L33.1707 24.7765L24.4494 33.551H21.7905L30.3256 24.9892L31.2563 25.7337H13.7605V23.8192Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="brought-box">
                            <div className="b-logo-div">
                                <img src="/img/Oxford-Logo.png" alt="" />
                            </div>
                            <div className="brought-line" />
                            <div className="brought-name">The Oxford Character Project</div>
                            <p>
                                The Oxford Character Project (OCP) aims to help leaders develop the
                                character they need to navigate the challenges of our time and make
                                an impact for good. Through research and leadership development
                                programmes, OCP enables students and professionals to lead across
                                boundaries and contribute positively to organisations and society.
                            </p>
                            <div className="arrow">
                                <a href="https://www.oxfordcharacter.org/" target="_blank">
                                    <svg
                                        width={48}
                                        height={48}
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.449707"
                                            y="0.16748"
                                            width="47.0427"
                                            height="47.0427"
                                            rx="23.5214"
                                            fill="#0A3887"
                                        />
                                        <path
                                            d="M13.7605 23.8192H31.2563L30.3256 24.5637L21.817 16.002H24.476L33.1707 24.7765L24.4494 33.551H21.7905L30.3256 24.9892L31.2563 25.7337H13.7605V23.8192Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="brought-box">
                            <div className="b-logo-div">
                                <img src="/img/harvard-humar-flourishing-prog.png" alt="" style={{ maxHeight: "100%" }} />
                            </div>
                            <div className="brought-line" />
                            <div className="brought-name">Harvard Human Flourishing</div>
                            <p>
                                The Human Flourishing Program at Harvard studies and promotes human
                                flourishing through interdisciplinary research, conferences, and
                                programs.
                            </p>
                            <div className="arrow">
                                <a href="https://hfh.fas.harvard.edu/" target="_blank">
                                    <svg
                                        width={48}
                                        height={48}
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="0.449707"
                                            y="0.16748"
                                            width="47.0427"
                                            height="47.0427"
                                            rx="23.5214"
                                            fill="#0A3887"
                                        />
                                        <path
                                            d="M13.7605 23.8192H31.2563L30.3256 24.5637L21.817 16.002H24.476L33.1707 24.7765L24.4494 33.551H21.7905L30.3256 24.9892L31.2563 25.7337H13.7605V23.8192Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>

    );
}

export default BroughtToYou;