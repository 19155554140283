import React, {useState} from 'react';

const useNewsletter = () => {

    const [email, setEmail] = React.useState("");

    const [newsletterNotif, setNewsletterNotif] = useState({
        success:false,
        msg:""
    })

    const [submitting, setSubmitting] = useState(false);

     const validateEmail = (email:string) =>{
        // Updated regex pattern to validate email format with domain and extension
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Additional check to ensure the domain part contains at least one dot
        const domainPart = email.split('@')[1];

        if (!domainPart || domainPart.split('.').length < 2) {
            return false;
        }

        return regex.test(email);
    }

    const submitToNewsletter = (email:string) => {
         if(!validateEmail(email)) {
             setNewsletterNotif({
                 success:false,
                 msg:"Please enter valid email",
             })
             return
         }
        setSubmitting(true)
         const url = `${process.env.REACT_APP_IBL_LMS_URL}/ibl/api/edx/custom/emails/newsletter/subscribe`
        fetch(url, {
            method:"POST",
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({email})
        }).then((response)=>{
            if(!response.ok){
                throw new Error("Failed to subscribe to newsletter");
            }
            if(response.ok){
                setEmail('')
                setNewsletterNotif({
                    success:true,
                    msg:"Successfully subscribed to the newsletter.",
                })
            }
        }).catch((err)=>{
            setNewsletterNotif({
                success:false,
                msg: err.message
            })
        }).finally(()=>{
            setSubmitting(false)
        })
    }
    return {
        newsletterNotif,
        submitToNewsletter,
        submitting,
        setEmail,
        email
    }
};

export default useNewsletter;