import useHeaderFooter from "../../hooks/useHeaderFooter";
import React from "react";
import {NavLink} from "react-router-dom";
interface AboutUsProps {
    children?: React.ReactNode
}
const AboutUs : React.FC<AboutUsProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);

    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about" />
            <div className="container-center-horizontal">
                <div className="about screen">
                    <div className="homepage">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <img
                                        className="overlap-group-item"
                                        src="/img/ABOUTUS-OVERVIEW-LWC.jpg"
                                        alt="robert-bock-cV4qkkorDFY-unsplash 1"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                <span className="recklessneue-regular-normal-white-100-1px" style={{fontStyle :"italic"}}>
                  Course{" "}
                </span>
                                        <span className="instrumentserif-normal-white-100-1px">Overview</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-overview-text">
                        <div className="overlap-group5">
                            <div className="built-to-flourish recklessneue-book-normal-congress-blue-48px">
                                Look within, lead beyond
                            </div>
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                                At the heart of every remarkable leader lies an unwavering
                                foundation of character. We firmly believe that good leadership
                                begins with character and involves a steadfast commitment to
                                courage, love, and hope.
                            </p>
                            <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px">
                                Leading with Character is a 30-day leadership journey designed to
                                help you clarify what it means to lead in your context and build
                                habits that expand your ability to influence positive change.
                            </p>
                        </div>
                    </div>
                    <div className="legatum-container the-course">
                        <div className="c-section">
                            <div className="leg-title">The course</div>
                            <p className="leg-p">
                                Developed in collaboration between Legatum and academic departments
                                within the University of Oxford and Harvard University, our course
                                is accessible to everyone at no cost, enabling you to learn and
                                evolve at your own pace.
                            </p>
                            <p className="leg-p">
                                The course has four central modules, each moving from research and
                                practitioner insight to practical exercises that focus on habit
                                development. Each module contains two to three hours of interactive
                                online content followed by five days of practical exercises, taking
                                approximately 20 minutes a day. The whole course can be completed
                                within 30 days.
                            </p>
                        </div>
                        <div className="wayfinder-img-container">
                            <img
                                alt="Wayfinder"
                                className="w-100"
                                src="/img/297178083-3bd30d37-1fc8-472e-b825-b0e8919a506c.png"
                            />
                        </div>
                        <div className="c-section">
                            <div className="leg-title">Why is it needed?</div>
                            <p className="leg-p">
                                In today’s rapidly changing, uncertain, and interconnected world,
                                the need for leaders of character is more important than ever. We
                                need leaders who possess good judgment, courage, love, and hope, and
                                who inspire others to build a better future together. By embodying
                                integrity, wisdom, and compassion, such leaders build trust, leading
                                to deeper engagement and commitment among followers and
                                stakeholders.
                            </p>
                            <p className="leg-p">
                                Character is critical to leadership effectiveness, across industries
                                and at all levels, including the highest positions of influence.
                                However, it often remains overlooked in leadership development. Most
                                leadership theories lack in-depth consideration of character and
                                therefore miss out on the foundational principle that a leader’s
                                essence shapes their actions. Similarly, most leadership development
                                programmes emphasise specific, tactical skills, rather than the
                                growth of the whole person.
                            </p>
                            <p className="leg-p">
                                Leading with Character addresses this gap by incorporating
                                research-based strategies for intentional character development in
                                your leadership journey, strengthening your ability to lead in
                                dynamic, evolving contexts.
                            </p>
                        </div>
                        <div className="c-section">
                            <div className="leg-title">Who is this for?</div>
                            <p className="leg-p">
                                This course is designed for individuals from all backgrounds who
                                possess a thirst for personal growth and a desire to elevate their
                                own leadership. Whether you are a seasoned executive, an emerging
                                manager, or someone who aspires to inspire change, this course is a
                                stepping stone towards becoming a more effective leader.
                            </p>
                            <p className="leg-p">
                                Building character and becoming a better leader requires deliberate,
                                sustained effort and consistent practice. Are you ready to take the
                                first step towards becoming the leader you aspire to be? Sign up
                                today and begin the journey of character-based leadership.
                            </p>
                            <p className="leg-p">
                                Together, we will shape the future, one leader at a time.
                            </p>
                        </div>
                        <div className="items-center space-bottom">
                            <div className="leg-primary-button">
                                <NavLink to="/course-overview/course-v1:Legatum+100+2023">
                                    Enroll
                                </NavLink>
                            </div>
                        </div>
                        <div className="c-section">
                            <div className="leg-title">Course Overview</div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={80}
                                        height={80}
                                        viewBox="0 0 17 21"
                                        fill="#0a3887"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1633_1367)">
                                            <path
                                                d="M15.9597 20.8272C15.8279 20.8272 15.7201 20.7171 15.7201 20.5826C15.7201 18.5022 14.904 16.4082 13.4809 14.836C12.0152 13.2162 10.1048 12.3248 8.09856 12.3248C3.89706 12.3248 0.478343 15.8143 0.478343 20.1029C0.478343 20.2374 0.37051 20.3475 0.238714 20.3475C0.106918 20.3475 -0.000915527 20.2374 -0.000915527 20.1029C-0.000915527 15.5439 3.63213 11.8356 8.09856 11.8356C10.2393 11.8356 12.2748 12.7827 13.8324 14.5044C15.3354 16.1663 16.198 18.3812 16.198 20.5839C16.198 20.7185 16.0902 20.8285 15.9584 20.8285L15.9597 20.8272Z"
                                                fill="#ECD8FB"
                                            />
                                            <path
                                                d="M8.21 14.563C8.0782 14.563 7.97037 14.4529 7.97037 14.3184C7.97037 12.7897 8.84635 11.4389 10.2016 10.875C12.3037 9.99992 13.6216 7.85428 13.4805 5.53607C13.3154 2.8333 11.1947 0.667277 8.54681 0.500137C7.07043 0.405017 5.66593 0.921384 4.59293 1.9514C3.51859 2.98141 2.9275 4.37696 2.9275 5.88122C2.9275 6.01574 2.81967 6.12581 2.68787 6.12581C2.55608 6.12581 2.44824 6.01574 2.44824 5.88122C2.44824 4.26553 3.10989 2.70285 4.2641 1.59538C5.43695 0.471601 6.96792 -0.089607 8.57743 0.0123074C11.4663 0.195753 13.7801 2.55745 13.9598 5.50617C14.1142 8.03501 12.6764 10.375 10.3826 11.3289C9.20846 11.8167 8.44963 12.9908 8.44963 14.3197C8.44963 14.4543 8.3418 14.5643 8.21 14.5643V14.563Z"
                                                fill="#ECD8FB"
                                            />
                                            <path
                                                d="M8.20998 20.0186C7.34199 20.0186 6.63641 19.2984 6.63641 18.4124C6.63641 17.5264 7.34199 16.8062 8.20998 16.8062C9.07797 16.8062 9.78355 17.5264 9.78355 18.4124C9.78355 19.2984 9.07797 20.0186 8.20998 20.0186ZM8.20998 17.2968C7.60691 17.2968 7.11567 17.7982 7.11567 18.4138C7.11567 19.0293 7.60691 19.5308 8.20998 19.5308C8.81305 19.5308 9.30429 19.0293 9.30429 18.4138C9.30429 17.7982 8.81305 17.2968 8.20998 17.2968Z"
                                                fill="#ECD8FB"
                                            />
                                            <path
                                                d="M8.0995 9.52671C6.0906 9.52671 4.45447 7.85804 4.45447 5.80616C4.45447 3.75428 6.08927 2.08697 8.0995 2.08697C10.1097 2.08697 11.7445 3.75564 11.7445 5.80616C11.7445 7.85668 10.1097 9.52671 8.0995 9.52671ZM8.0995 2.57615C6.3542 2.57615 4.93373 4.02606 4.93373 5.80616C4.93373 7.58627 6.3542 9.03753 8.0995 9.03753C9.8448 9.03753 11.2653 7.58762 11.2653 5.80616C11.2653 4.0247 9.8448 2.57615 8.0995 2.57615Z"
                                                fill="#ECD8FB"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1633_1367">
                                                <rect width="16.199" height="20.8272" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Character: The heart of good leadership
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>Explore the fundamentals of Leading with Character</li>
                                            <li>Consider our leadership philosophy</li>
                                            <li>Experience the transformative power of character</li>
                                            <li>Discover how to develop character</li>
                                            <li>
                                                Introduce yourself to fellow learners from across the globe
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={80}
                                        height={80}
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1633_1328)">
                                            <path
                                                d="M15.0979 20.9996C14.9465 20.9996 14.8226 20.8727 14.8226 20.7177C14.8226 17.9219 12.5691 15.4677 10.0004 15.4677C7.43176 15.4677 5.17826 17.6823 5.17826 20.4044C5.17826 20.5595 5.05434 20.6863 4.90288 20.6863C4.75142 20.6863 4.6275 20.5595 4.6275 20.4044C4.6275 17.3706 7.03858 14.9038 10.0004 14.9038C12.9622 14.9038 15.3733 17.5664 15.3733 20.7177C15.3733 20.8727 15.2494 20.9996 15.0979 20.9996Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M9.99934 14.0081C7.66323 14.0081 5.7616 12.0628 5.7616 9.66961C5.7616 7.27641 7.6617 5.33115 9.99934 5.33115C12.337 5.33115 14.2371 7.27641 14.2371 9.66961C14.2371 12.0628 12.337 14.0081 9.99934 14.0081ZM9.99934 5.89656C7.96614 5.89656 6.31235 7.58966 6.31235 9.67117C6.31235 11.7527 7.96614 13.4458 9.99934 13.4458C12.0325 13.4458 13.6863 11.7527 13.6863 9.67117C13.6863 7.58966 12.0325 5.89656 9.99934 5.89656Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M19.5361 18.2385H0.461705C0.310248 18.2385 0.1695 18.1633 0.0822969 18.0365C-0.00490572 17.9096 -0.024794 17.7467 0.0272216 17.6026L5.01919 3.74621H2.97528C2.74886 3.74621 2.56222 3.58802 2.52091 3.36092C2.4796 3.13381 2.5974 2.91611 2.807 2.8331L9.83216 0.032681C9.94078 -0.0111734 10.0586 -0.0111734 10.1672 0.032681L17.1908 2.83153C17.402 2.91611 17.5198 3.13225 17.4769 3.35935C17.4341 3.58645 17.249 3.74464 17.0226 3.74464H14.9786L19.9706 17.6011C20.0226 17.7467 20.0027 17.9081 19.9155 18.0349C19.8299 18.1618 19.6876 18.237 19.5361 18.237V18.2385ZM0.588684 17.6747H19.4092L14.4187 3.81826C14.3667 3.6726 14.3866 3.51128 14.4738 3.38441C14.561 3.25755 14.7017 3.18237 14.8532 3.18237H16.5529L9.99892 0.569898L3.44648 3.18237H5.14617C5.29763 3.18237 5.4399 3.25755 5.52558 3.38441C5.61125 3.51128 5.63267 3.67416 5.58065 3.81826L0.588684 17.6747Z"
                                                fill="#0A3887"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1633_1328">
                                                <rect width={20} height={21} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Purpose: Clarify your leadership direction and drive
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>Begin with the essential question: “Why do you lead?”</li>
                                            <li>Gain insights from purpose-driven leaders</li>
                                            <li>Identify your unique personal purpose</li>
                                            <li>
                                                Engage in a 5-day challenge, taking daily actions towards
                                                your purpose
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={80}
                                        height={80}
                                        viewBox="0 0 23 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1633_1359)">
                                            <path
                                                d="M22.5071 20H4.3372C4.16153 20 4.00357 19.9093 3.91369 19.7567C3.82381 19.6042 3.82109 19.42 3.90552 19.2647L12.9911 2.57027C13.0769 2.41083 13.2431 2.31324 13.4214 2.31324C13.6012 2.31324 13.766 2.41221 13.8531 2.57027L22.9374 19.2647C23.0218 19.42 23.0191 19.6042 22.9292 19.7567C22.8393 19.9093 22.6814 20 22.5057 20H22.5071ZM4.37941 19.4791H22.4662L13.4228 2.86028L4.37941 19.4777V19.4791Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M10.2022 20H0.385351C0.247814 20 0.123895 19.9285 0.0544455 19.8089C-0.0150039 19.6894 -0.0177274 19.5464 0.0489985 19.4241L4.95676 10.4048C5.02484 10.2811 5.15285 10.2041 5.29311 10.2041C5.43337 10.2041 5.56274 10.2811 5.62946 10.4062L10.5372 19.4255C10.6039 19.5464 10.6012 19.6907 10.5318 19.8103C10.4623 19.9299 10.3384 20.0014 10.2009 20.0014L10.2022 20ZM0.608679 19.4791H9.9789L5.29311 10.8694L0.608679 19.4777V19.4791Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M5.34896 13.9111L3.58822 13.6513C3.44659 13.6307 3.34991 13.4974 3.37034 13.3558C3.39076 13.2128 3.52149 13.1152 3.66311 13.1359L5.23866 13.3682L6.50373 12.5778C6.62493 12.5022 6.78425 12.5393 6.85915 12.663C6.93404 12.7854 6.89728 12.9462 6.77472 13.0218L5.35033 13.9111H5.34896Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M10.0566 8.76366C9.95443 8.76366 9.85775 8.70181 9.8169 8.6001C9.76379 8.46677 9.82779 8.31421 9.95988 8.2606L12.2395 7.33146L14.2344 8.24273H16.7618C16.9048 8.24273 17.0206 8.35956 17.0206 8.50388C17.0206 8.6482 16.9048 8.76503 16.7618 8.76503H14.1241L12.2299 7.90049L10.156 8.74717C10.1247 8.75954 10.092 8.76641 10.0593 8.76641L10.0566 8.76366Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M6.1279 6.56725C4.86828 6.56725 3.84424 5.53364 3.84424 4.26225C3.84424 2.99086 4.86828 1.95725 6.1279 1.95725C7.38752 1.95725 8.41156 2.99086 8.41156 4.26225C8.41156 5.53364 7.38752 6.56725 6.1279 6.56725ZM6.1279 2.47818C5.15288 2.47818 4.36034 3.27812 4.36034 4.26225C4.36034 5.24637 5.15288 6.04632 6.1279 6.04632C7.10291 6.04632 7.89545 5.24637 7.89545 4.26225C7.89545 3.27812 7.10291 2.47818 6.1279 2.47818Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M13.4092 2.9565C13.2662 2.9565 13.1505 2.83967 13.1505 2.69535V0.30101C13.1505 0.134699 13.2839 0 13.4487 0H15.5226C15.6438 0 15.7514 0.0728472 15.7977 0.185554C15.844 0.298261 15.8181 0.426088 15.7337 0.51268L15.2312 1.01986L15.6765 1.46931C15.7609 1.55591 15.7868 1.68373 15.7419 1.79644C15.6969 1.90915 15.5894 1.98199 15.4695 1.98337L13.6693 2.00124V2.69535C13.6693 2.83967 13.5535 2.9565 13.4105 2.9565H13.4092ZM13.6679 0.520926V1.47894L14.9425 1.46657L14.711 1.2329C14.5953 1.1147 14.5953 0.925022 14.711 0.808192L14.9956 0.520926H13.6679Z"
                                                fill="#0A3887"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1633_1359">
                                                <rect width={23} height={20} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Courage: Fortify your commitment to courageous action
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>
                                                Understand the pivotal role of courage and self-leadership
                                            </li>
                                            <li>Explore the diverse forms of courage</li>
                                            <li>Identify your personal values</li>
                                            <li>
                                                Reflect on your experiences of acting in alignment with your
                                                values
                                            </li>
                                            <li>Ground your courage in humility</li>
                                            <li>
                                                Embrace a 5-day challenge, practising courageous leadership
                                                daily
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={80}
                                        height={80}
                                        viewBox="0 0 22 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1633_1352)">
                                            <path
                                                d="M15.5985 19.5091C15.4619 19.5091 15.3501 19.3939 15.3501 19.2532C15.3501 16.7137 13.3175 14.4871 11.0006 14.4871C8.68374 14.4871 6.65113 16.4976 6.65113 18.9702C6.65113 19.111 6.53935 19.2262 6.40274 19.2262C6.26613 19.2262 6.15436 19.111 6.15436 18.9702C6.15436 16.2161 8.32911 13.9766 11.0006 13.9766C13.6721 13.9766 15.8469 16.3938 15.8469 19.2546C15.8469 19.3954 15.7351 19.5105 15.5985 19.5105V19.5091Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M10.999 13.1618C8.89187 13.1618 7.17664 11.3958 7.17664 9.22319C7.17664 7.05057 8.89049 5.28461 10.999 5.28461C13.1075 5.28461 14.8214 7.05057 14.8214 9.22319C14.8214 11.3958 13.1075 13.1618 10.999 13.1618ZM10.999 5.7979C9.16509 5.7979 7.67341 7.33494 7.67341 9.22461C7.67341 11.1143 9.16509 12.6513 10.999 12.6513C12.8329 12.6513 14.3246 11.1143 14.3246 9.22461C14.3246 7.33494 12.8329 5.7979 10.999 5.7979Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M10.9989 20C10.7271 20 10.4718 19.8905 10.28 19.6929L9.03665 18.4118C8.43087 17.7876 7.82647 17.1591 7.22206 16.5307C6.18161 15.4486 5.10665 14.331 4.03446 13.2476C3.88129 13.0926 3.72536 12.9376 3.56943 12.7812C3.10578 12.3191 2.62694 11.8399 2.18261 11.3437C1.17803 10.2176 0.0189034 8.64925 -0.000415455 6.51218C-0.0307736 3.08548 2.6435 0.167806 5.9622 0.00713484C7.88442 -0.0867085 9.74455 0.739397 10.9989 2.22952C12.2532 0.740819 14.112 -0.0852866 16.0356 0.00713484C19.3543 0.167806 22.0299 3.08548 21.9982 6.51076C21.9789 8.64925 20.8198 10.2176 19.8124 11.3451C19.3681 11.8428 18.8893 12.3205 18.4256 12.7826C18.2697 12.9376 18.1137 13.0926 17.9606 13.249C16.8898 14.3325 15.8134 15.45 14.773 16.5321C14.1686 17.1606 13.5655 17.7876 12.9584 18.4132L11.7151 19.6943C11.5233 19.892 11.268 20.0014 10.9961 20.0014L10.9989 20ZM6.2575 0.511898C6.16781 0.511898 6.07673 0.514742 5.98704 0.519008C2.93053 0.666882 0.468756 3.35279 0.496354 6.5065C0.514293 8.45872 1.54509 9.87348 2.55105 10.9982C2.98572 11.4845 3.45903 11.9579 3.91855 12.4144C4.07586 12.5708 4.23179 12.7272 4.38634 12.8822C5.45991 13.967 6.53625 15.0875 7.57808 16.1709C8.18111 16.798 8.78413 17.4264 9.38991 18.0506L10.6332 19.3317C10.8361 19.5408 11.1659 19.5408 11.3687 19.3317L12.612 18.0506C13.2178 17.4264 13.8208 16.7994 14.4238 16.1709C15.4657 15.0875 16.542 13.9685 17.6156 12.8822C17.7701 12.7257 17.9261 12.5708 18.082 12.4144C18.5401 11.9565 19.0148 11.4845 19.4495 10.9982C20.4555 9.87206 21.4863 8.45872 21.5042 6.5065C21.5332 3.35279 19.07 0.666882 16.0149 0.517586C14.1492 0.428008 12.3498 1.27544 11.1976 2.78831L11.0017 3.04425L10.8057 2.78831C9.70729 1.3508 8.0238 0.511898 6.2575 0.511898Z"
                                                fill="#0A3887"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1633_1352">
                                                <rect width={22} height={20} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Love: Cultivate the relational skills to lead with love
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>Consider a relational approach to leadership</li>
                                            <li>
                                                Investigate the virtue of generative love in leadership
                                            </li>
                                            <li>Practise being a giver, not a taker</li>
                                            <li>Foster a culture of love with psychological safety</li>
                                            <li>
                                                Reflect on your experiences with high-performing teams
                                            </li>
                                            <li>Hone your skills in effective listening</li>
                                            <li>
                                                Embark on a 5-day challenge to expand your capacity for
                                                leadership with love
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={80}
                                        height={80}
                                        viewBox="0 0 22 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_1633_1333)">
                                            <path
                                                d="M7.80904 20H2.94835C2.82015 20 2.71733 19.8933 2.71733 19.7634C2.71733 19.6334 2.82149 19.5267 2.94835 19.5267H7.34567V15.3974H0.230895C0.1027 15.3974 -0.00012207 15.2907 -0.00012207 15.1607C-0.00012207 15.0308 0.104036 14.9241 0.230895 14.9241H7.80771V20.0014L7.80904 20Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M11.628 19.3927C11.5586 19.3927 11.5092 19.39 11.4865 19.3886C11.4745 19.3886 11.4611 19.3886 11.4477 19.3886H7.57655C7.44435 19.3886 7.33618 19.2778 7.33618 19.1424C7.33618 19.007 7.44435 18.8962 7.57655 18.8962H11.4477C11.4691 18.8962 11.4905 18.8962 11.5118 18.8976C11.6801 18.9071 13.1971 18.9577 14.8436 17.8115L20.6857 14.0911C20.874 13.9707 21.0036 13.7833 21.053 13.5631C21.1024 13.3429 21.0636 13.1159 20.9461 12.9244C20.7071 12.5386 20.2037 12.4196 19.8231 12.6563L15.2495 15.515C15.136 15.5861 14.9878 15.5492 14.9197 15.4329C14.8502 15.3167 14.8863 15.1648 14.9998 15.0951L19.5734 12.2364C20.1756 11.8602 20.9742 12.0503 21.3521 12.6604C21.5404 12.964 21.6005 13.3238 21.5217 13.6726C21.4442 14.0227 21.2372 14.3195 20.9395 14.5083L15.1053 18.2232C13.5643 19.2956 12.1021 19.3913 11.628 19.3913V19.3927Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M14.02 16.9347H7.57686C7.44466 16.9347 7.3365 16.8239 7.3365 16.6885C7.3365 16.5531 7.44466 16.4423 7.57686 16.4423H14.02C14.4112 16.4423 14.729 16.1168 14.729 15.716C14.729 15.3153 14.4112 14.9911 14.02 14.9911H8.95629C8.7226 14.9911 8.49559 15.0608 8.30063 15.1949L7.7104 15.5984C7.59956 15.6736 7.45134 15.6435 7.37656 15.53C7.30311 15.4165 7.33249 15.2646 7.44333 15.188L8.03355 14.7845C8.3073 14.5972 8.62645 14.4987 8.95629 14.4987H14.02C14.6756 14.4987 15.2084 15.0444 15.2084 15.716C15.2084 16.3876 14.6756 16.9347 14.02 16.9347Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M13.5751 13.5561C13.4469 13.5561 13.3441 13.4495 13.3441 13.3195V3.68062C13.3441 3.54931 13.4482 3.44399 13.5751 3.44399C13.7019 3.44399 13.8061 3.55068 13.8061 3.68062V13.3182C13.8061 13.4495 13.7019 13.5548 13.5751 13.5548V13.5561Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M13.5754 13.7012C13.5754 13.7012 13.57 13.7012 13.5687 13.7012C13.4405 13.6971 13.3403 13.5877 13.3443 13.4577C13.3484 13.3169 13.3924 10.0943 10.1155 9.01649C9.99394 8.97683 9.92717 8.84278 9.96589 8.71831C10.0046 8.59384 10.1355 8.52545 10.257 8.56512C13.8745 9.75511 13.809 13.4345 13.8077 13.4714C13.8037 13.6 13.7009 13.7012 13.5767 13.7012H13.5754Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M13.5753 13.701C13.4525 13.701 13.3496 13.6012 13.3443 13.474C13.3416 13.4138 13.2855 11.9694 13.6648 10.3595C14.1762 8.18607 15.2432 6.77586 16.7535 6.27935C16.875 6.23968 17.0058 6.30807 17.0446 6.43254C17.0833 6.55701 17.0165 6.69106 16.895 6.73072C13.6114 7.80992 13.805 13.396 13.8077 13.4535C13.813 13.5848 13.7142 13.6942 13.586 13.701C13.582 13.701 13.5793 13.701 13.5753 13.701Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M13.5725 5.95245C13.4136 5.95245 13.2694 5.87038 13.1853 5.7336L11.6483 3.22642C11.5548 3.07459 11.5548 2.879 11.6483 2.72717L13.184 0.218622C13.2681 0.0818416 13.4136 -0.000226498 13.5712 -0.000226498C13.7288 -0.000226498 13.8743 0.0818416 13.9585 0.218622L15.4955 2.7258C15.5889 2.87763 15.5889 3.07322 15.4955 3.22505L13.9585 5.73223C13.8743 5.86901 13.7288 5.95108 13.5712 5.95108L13.5725 5.95245ZM13.5765 5.48056L15.1055 2.97338L13.5685 0.471665L12.0396 2.97885L13.5765 5.48056Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M11.9168 10.3814C11.8821 10.3814 11.8474 10.3773 11.814 10.3691L8.99641 9.70981C8.82549 9.67015 8.69062 9.532 8.65189 9.35692L8.00825 6.47086C7.97353 6.31219 8.01893 6.14942 8.1311 6.03453C8.24327 5.91963 8.40218 5.87313 8.55708 5.90869L11.3747 6.56797C11.5443 6.60764 11.6805 6.74579 11.7192 6.92086L12.3628 9.80693C12.3976 9.96559 12.3522 10.1284 12.24 10.2433C12.1532 10.3322 12.037 10.3814 11.9168 10.3814ZM8.45292 6.37101L9.10324 9.2516L11.9182 9.90678L11.2679 7.02618L8.45426 6.37101H8.45292Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M15.4229 7.95764C15.3028 7.95764 15.1879 7.90977 15.0998 7.81949C14.9876 7.7046 14.9422 7.54183 14.9769 7.38316L15.6206 4.4971C15.6593 4.32339 15.7942 4.18387 15.9651 4.14421L18.7827 3.48493C18.9376 3.448 19.0965 3.49587 19.2087 3.61076C19.3209 3.72566 19.3663 3.88843 19.3315 4.04709L18.6879 6.93316C18.6492 7.10687 18.5143 7.24638 18.3434 7.28605L15.5258 7.94533C15.491 7.95354 15.4563 7.95764 15.4229 7.95764ZM18.8815 3.94177L16.0693 4.60789L15.4296 7.49122L18.2419 6.8251L18.8815 3.94314V3.94177Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M7.57678 3.54762C7.44859 3.54762 7.34576 3.44093 7.34576 3.31099V1.3564C7.34576 1.22509 7.44992 1.11977 7.57678 1.11977C7.70364 1.11977 7.8078 1.22646 7.8078 1.3564V3.31099C7.8078 3.4423 7.70364 3.54762 7.57678 3.54762Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M8.15768 2.57142H6.99725C6.86906 2.57142 6.76624 2.46473 6.76624 2.33479C6.76624 2.20485 6.87039 2.09816 6.99725 2.09816H8.15768C8.28587 2.09816 8.38869 2.20485 8.38869 2.33479C8.38869 2.46473 8.28454 2.57142 8.15768 2.57142Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M6.61535 12.7273C6.48716 12.7273 6.38434 12.6206 6.38434 12.4907V10.5361C6.38434 10.4048 6.4885 10.2995 6.61535 10.2995C6.74221 10.2995 6.84637 10.4061 6.84637 10.5361V12.4907C6.84637 12.622 6.74221 12.7273 6.61535 12.7273Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M7.19448 11.7505H6.03406C5.90586 11.7505 5.80304 11.6438 5.80304 11.5139C5.80304 11.3839 5.9072 11.2772 6.03406 11.2772H7.19448C7.32268 11.2772 7.4255 11.3839 7.4255 11.5139C7.4255 11.6438 7.32134 11.7505 7.19448 11.7505Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M21.1895 4.19093C21.0613 4.19093 20.9585 4.08424 20.9585 3.9543V1.99971C20.9585 1.8684 21.0627 1.76308 21.1895 1.76308C21.3164 1.76308 21.4205 1.86977 21.4205 1.99971V3.9543C21.4205 4.08561 21.3164 4.19093 21.1895 4.19093Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M21.7686 3.2129H20.6082C20.48 3.2129 20.3772 3.10621 20.3772 2.97627C20.3772 2.84633 20.4814 2.73964 20.6082 2.73964H21.7686C21.8968 2.73964 21.9997 2.84633 21.9997 2.97627C21.9997 3.10621 21.8955 3.2129 21.7686 3.2129Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M20.0287 10.7724C19.9006 10.7724 19.7977 10.6657 19.7977 10.5357V8.58113C19.7977 8.44982 19.9019 8.3445 20.0287 8.3445C20.1556 8.3445 20.2598 8.45119 20.2598 8.58113V10.5357C20.2598 10.667 20.1556 10.7724 20.0287 10.7724Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M20.6085 9.79615H19.4481C19.3199 9.79615 19.217 9.68947 19.217 9.55952C19.217 9.42958 19.3212 9.3229 19.4481 9.3229H20.6085C20.7367 9.3229 20.8395 9.42958 20.8395 9.55952C20.8395 9.68947 20.7353 9.79615 20.6085 9.79615Z"
                                                fill="#0A3887"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1633_1333">
                                                <rect width={22} height={20} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Hope: Fuel your pursuit of a brighter future
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>
                                                Recognise the significance of hope in sustaining long-term
                                                goals
                                            </li>
                                            <li>
                                                Draw inspiration from leaders who have upheld hope in
                                                adversity
                                            </li>
                                            <li>Become a beacon of hope for others</li>
                                            <li>
                                                Explore the role of practical wisdom in steering present
                                                actions toward future horizons
                                            </li>
                                            <li>
                                                Take on a 5-day challenge: Inject hope into your team’s
                                                pursuit of purpose, mission, and strategy
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-box space-top">
                                <div className="icon">
                                    <svg
                                        width={180}
                                        height={180}
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            style={{ display: "none" }}
                                            cx="17.9999"
                                            cy="17.9999"
                                            r="17.4276"
                                            transform="rotate(-45 17.9999 17.9999)"
                                            fill="#D1B9E3"
                                            stroke="#0A3887"
                                            strokeWidth="0.5"
                                        />
                                        <g clipPath="url(#clip0_1400_125)">
                                            <path
                                                d="M17.9994 27.9999C14.6914 27.9999 12 25.3738 12 22.1448C12 18.9159 14.6914 16.291 17.9994 16.291C21.3073 16.291 24 18.9172 24 22.1448C24 25.3725 21.3086 27.9999 17.9994 27.9999ZM17.9994 16.7384C14.9436 16.7384 12.4573 19.1645 12.4573 22.1448C12.4573 25.1252 14.9436 27.5525 17.9994 27.5525C21.0551 27.5525 23.5415 25.1265 23.5415 22.1448C23.5415 19.1632 21.0551 16.7384 17.9994 16.7384Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M17.9994 25.994C15.8251 25.994 14.0558 24.2676 14.0558 22.1461C14.0558 20.0245 15.8251 18.2982 17.9994 18.2982C20.1737 18.2982 21.9429 20.0245 21.9429 22.1461C21.9429 24.2676 20.1737 25.994 17.9994 25.994ZM17.9994 18.7456C16.0773 18.7456 14.5144 20.2706 14.5144 22.1461C14.5144 24.0215 16.0773 25.5465 17.9994 25.5465C19.9215 25.5465 21.4844 24.0215 21.4844 22.1461C21.4844 20.2706 19.9215 18.7456 17.9994 18.7456Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M17.8732 16.7906L13.6278 13.8252V7.99994H22.1186V13.8252L17.8732 16.7906ZM14.0864 13.5953L17.8732 16.2401L21.6601 13.5953V8.44736H14.0864V13.5953Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M16.2251 15.6224C16.099 15.6224 15.9958 15.5217 15.9958 15.3986V8.22365C15.9958 8.10061 16.099 7.99994 16.2251 7.99994C16.3512 7.99994 16.4543 8.10061 16.4543 8.22365V15.3986C16.4543 15.5217 16.3512 15.6224 16.2251 15.6224Z"
                                                fill="#0A3887"
                                            />
                                            <path
                                                d="M19.5215 15.6224C19.3954 15.6224 19.2922 15.5217 19.2922 15.3986V8.22365C19.2922 8.10061 19.3954 7.99994 19.5215 7.99994C19.6476 7.99994 19.7508 8.10061 19.7508 8.22365V15.3986C19.7508 15.5217 19.6476 15.6224 19.5215 15.6224Z"
                                                fill="#0A3887"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1400_125">
                                                <rect
                                                    width={30}
                                                    height={30}
                                                    fill="white"
                                                    transform="translate(3 3)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip1_1400_125">
                                                <rect
                                                    width={12}
                                                    height={20}
                                                    fill="white"
                                                    transform="translate(12 8)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="overview-text">
                                    <div className="ov-title">
                                        Onward: Plan for ongoing growth and purposeful leadership
                                    </div>
                                    <div className="ov-list">
                                        <ul>
                                            <li>
                                                Find renewed inspiration to commit to continuous leadership
                                                in your unique context
                                            </li>
                                            <li>
                                                Craft a personalised plan to integrate character development
                                                into your daily life
                                            </li>
                                            <li>Celebrate your course completion</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>

    );
}

export default AboutUs