import { useEffect, useRef, useState } from 'react';

import {getCourseCatalogue, getUserCoursesMetaData, searchCourseData, searchDataReturn} from './utils/helpers';
import { CourseData } from './utils/interfaces';

export interface CourseCatalogueItem {
  metadata: CourseData | null;
  search: searchCourseData | null;
}

export function useCourseCatalogue() {
  const hasFetchedData = useRef(false);
  const [coursesData, setCoursesData] = useState<CourseCatalogueItem[]>([]);
  const [courseDataLoaded, setCourseDataLoaded] = useState(false);
  const [courseMetaDataLoaded, setCourseMetaDataLoaded] = useState(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getCourseCatalogue()
          .then((searchCourseData: searchDataReturn) => {
            // Initialize courses data with empty metadata and search data
            const initialCoursesData: CourseCatalogueItem[] = searchCourseData.results.map((course) => ({
              metadata: null,
              search: course,
            }));
            setCoursesData(initialCoursesData);
            setCourseDataLoaded(true);

            // Fetch metadata and search data for each course
            searchCourseData.results.forEach((course, index) => {
              getUserCoursesMetaData(course?._source.course_id).then((metadata: CourseData) => {
                // Update the specific course's metadata
                setCoursesData((currentData) =>
                    currentData.map((item, idx) =>
                        idx === index ? { ...item, metadata: metadata } : item
                    )
                );
                if(index===searchCourseData.results.length - 1){
                    setCourseMetaDataLoaded(true)
                }
              });
            });

            hasFetchedData.current = true;
          })
          .catch((error) => {
            // TODO: Safely return empty course data to the API
            console.error('Error fetching user courses:', error);
          });
    }
  }, []);

  return [coursesData, courseDataLoaded, courseMetaDataLoaded] as const;
}
