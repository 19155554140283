import React from "react";
import {NavLink} from "react-router-dom";
interface CourseNavigationProps{
    courseID : string | undefined
}
const CourseNavigation : React.FC<CourseNavigationProps>= ({courseID})=> {
    return (
        <div className="course-extras-nav">

            <div className="course">
                <div
                    className="course-1 recklessneue-book-normal-congress-blue-22-8px "
                    data-name="module"
                >
                    <NavLink
                        className="a-nav"
                        data-name="module"
                        to={`/course-working/${courseID}`}
                    >
                        Module
                    </NavLink>
                </div>
            </div>
{/* 
            <div className="notes">
                <div
                    className="notes-1 recklessneue-book-normal-congress-blue-22-8px a-nav"
                    data-name="progress"
                >
                    <NavLink
                        className="a-nav"
                        data-name="progress"
                        to={`/course-progress/${courseID}`}
                    >
                        Progress
                    </NavLink>
                </div>
            </div> */}
            <div className="notes">
                <div
                    className="notes-1 recklessneue-book-normal-congress-blue-22-8px a-nav"
                    data-name="notes"
                >
                    <NavLink
                        className="a-nav"
                        data-name="notes"
                        to={`/course-notes/${courseID}`}
                    >
                        Notes
                    </NavLink>
                </div>
            </div>

        </div>

    )
}

export default CourseNavigation