const LeadingChangeDynamicSvg = ({overlayWidth}: {overlayWidth: number})=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="92" viewBox="0 0 93 92" fill="none">
            <defs>
                <clipPath id="circleClip">
                    <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" />
                </clipPath>
            </defs>

            <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" fill="#DFDFDF" />

            <rect x="1.68421" y="0.760874" width={overlayWidth} height="89.7832" fill="#0A3887" clip-path="url(#circleClip)" />

            <g clip-path="url(#clip0_2568_4813)">
                <path d="M35.015 72.5621H22.2296C21.8924 72.5621 21.622 72.2814 21.622 71.9397C21.622 71.5979 21.8959 71.3172 22.2296 71.3172H33.7962V60.4555H15.0818C14.7446 60.4555 14.4741 60.1748 14.4741 59.8331C14.4741 59.4913 14.7481 59.2106 15.0818 59.2106H35.0115V72.5657L35.015 72.5621Z" fill="#BCBCBC" />
                <path d="M45.0605 70.9647C44.8779 70.9647 44.7479 70.9575 44.6882 70.9539C44.6566 70.9539 44.6215 70.9539 44.5863 70.9539H34.4037C34.056 70.9539 33.7715 70.6625 33.7715 70.3063C33.7715 69.9501 34.056 69.6587 34.4037 69.6587H44.5863C44.6425 69.6587 44.6987 69.6587 44.7549 69.6623C45.1975 69.6875 49.1877 69.8206 53.5185 66.8056L68.8855 57.0196C69.3808 56.703 69.7215 56.2101 69.8515 55.6309C69.9814 55.0516 69.8796 54.4544 69.5705 53.9507C68.9417 52.9361 67.6175 52.6231 66.6165 53.2455L54.5863 60.7649C54.2878 60.952 53.8979 60.8549 53.7187 60.5491C53.5361 60.2433 53.6309 59.8439 53.9295 59.6604L65.9597 52.141C67.5438 51.1516 69.6442 51.6517 70.6383 53.2563C71.1335 54.055 71.2916 55.0013 71.0843 55.9187C70.8806 56.8397 70.3362 57.6205 69.5529 58.1169L54.207 67.8886C50.1536 70.7093 46.3075 70.9611 45.0605 70.9611V70.9647Z" fill="#BCBCBC" />
                <path d="M51.3522 64.4994H34.4046C34.0568 64.4994 33.7723 64.2079 33.7723 63.8518C33.7723 63.4956 34.0568 63.2042 34.4046 63.2042H51.3522C52.3813 63.2042 53.2173 62.3479 53.2173 61.2937C53.2173 60.2396 52.3813 59.3869 51.3522 59.3869H38.0329C37.4182 59.3869 36.8211 59.5704 36.3083 59.923L34.7558 60.9843C34.4643 61.1822 34.0744 61.103 33.8777 60.8044C33.6845 60.5058 33.7618 60.1064 34.0533 59.905L35.6058 58.8436C36.3259 58.3507 37.1654 58.0917 38.0329 58.0917H51.3522C53.0768 58.0917 54.4783 59.5272 54.4783 61.2937C54.4783 63.0602 53.0768 64.4994 51.3522 64.4994Z" fill="#BCBCBC" />
                <path d="M50.1814 55.6125C49.8442 55.6125 49.5737 55.3318 49.5737 54.9901V29.6363C49.5737 29.2909 49.8477 29.0139 50.1814 29.0139C50.5151 29.0139 50.789 29.2945 50.789 29.6363V54.9865C50.789 55.3318 50.5151 55.6089 50.1814 55.6089V55.6125Z" fill="#BCBCBC" />
                <path d="M50.1828 55.994C50.1828 55.994 50.1687 55.994 50.1652 55.994C49.828 55.9832 49.5646 55.6954 49.5751 55.3536C49.5857 54.983 49.7016 46.5066 41.082 43.6716C40.7624 43.5672 40.5867 43.2146 40.6886 42.8872C40.7905 42.5598 41.1347 42.3799 41.4543 42.4843C50.9696 45.6144 50.7975 55.2925 50.7939 55.3896C50.7834 55.7278 50.5129 55.994 50.1863 55.994H50.1828Z" fill="#BCBCBC" />
                <path d="M50.1822 55.9936C49.8591 55.9936 49.5886 55.731 49.5746 55.3964C49.5675 55.2381 49.42 51.4388 50.4175 47.2042C51.7628 41.4873 54.5693 37.778 58.5419 36.472C58.8615 36.3676 59.2057 36.5475 59.3076 36.8749C59.4094 37.2023 59.2338 37.5549 58.9142 37.6592C50.277 40.4979 50.7864 55.1913 50.7934 55.3424C50.8074 55.6878 50.5475 55.9756 50.2103 55.9936C50.1998 55.9936 50.1927 55.9936 50.1822 55.9936Z" fill="#BCBCBC" />
                <path d="M50.1746 35.612C49.7566 35.612 49.3772 35.3962 49.156 35.0364L45.1131 28.4416C44.8672 28.0423 44.8672 27.5278 45.1131 27.1284L49.1524 20.5301C49.3737 20.1703 49.7566 19.9544 50.1711 19.9544C50.5855 19.9544 50.9684 20.1703 51.1897 20.5301L55.2325 27.1248C55.4784 27.5242 55.4784 28.0387 55.2325 28.438L51.1897 35.0328C50.9684 35.3926 50.5855 35.6084 50.1711 35.6084L50.1746 35.612ZM50.1851 34.3708L54.2069 27.776L50.164 21.1956L46.1423 27.7904L50.1851 34.3708Z" fill="#BCBCBC" />
                <path d="M45.8198 47.2617C45.7285 47.2617 45.6372 47.2509 45.5493 47.2293L38.1381 45.4952C37.6885 45.3909 37.3337 45.0275 37.2318 44.567L35.5388 36.9756C35.4475 36.5583 35.5669 36.1301 35.862 35.8279C36.157 35.5257 36.575 35.4034 36.9825 35.4969L44.3937 37.231C44.8398 37.3354 45.1981 37.6988 45.3 38.1593L46.993 45.7506C47.0843 46.168 46.9649 46.5961 46.6698 46.8983C46.4415 47.1322 46.1359 47.2617 45.8198 47.2617ZM36.7085 36.713L38.4191 44.2899L45.8233 46.0133L44.1127 38.4363L36.712 36.713H36.7085Z" fill="#BCBCBC" />
                <path d="M55.0421 40.8865C54.726 40.8865 54.4239 40.7605 54.1921 40.5231C53.897 40.2209 53.7776 39.7927 53.8689 39.3754L55.5619 31.784C55.6638 31.3271 56.0185 30.9601 56.4681 30.8558L63.8794 29.1216C64.2869 29.0245 64.7049 29.1504 64.9999 29.4526C65.2949 29.7549 65.4144 30.183 65.323 30.6003L63.63 38.1917C63.5282 38.6486 63.1734 39.0156 62.7238 39.1199L55.3125 40.8541C55.2212 40.8757 55.1299 40.8865 55.0421 40.8865ZM64.1393 30.3233L56.7421 32.0754L55.0596 39.6596L62.4569 37.9075L64.1393 30.3269V30.3233Z" fill="#BCBCBC" />
                <path d="M34.404 29.2865C34.0668 29.2865 33.7964 29.0059 33.7964 28.6641V23.5228C33.7964 23.1774 34.0704 22.9004 34.404 22.9004C34.7377 22.9004 35.0117 23.181 35.0117 23.5228V28.6641C35.0117 29.0095 34.7377 29.2865 34.404 29.2865Z" fill="#BCBCBC" />
                <path d="M35.932 26.7188H32.8796C32.5424 26.7188 32.272 26.4382 32.272 26.0964C32.272 25.7546 32.5459 25.474 32.8796 25.474H35.932C36.2692 25.474 36.5396 25.7546 36.5396 26.0964C36.5396 26.4382 36.2656 26.7188 35.932 26.7188Z" fill="#BCBCBC" />
                <path d="M31.8747 53.4324C31.5375 53.4324 31.2671 53.1517 31.2671 52.8099V47.6687C31.2671 47.3233 31.5411 47.0463 31.8747 47.0463C32.2084 47.0463 32.4824 47.3269 32.4824 47.6687V52.8099C32.4824 53.1553 32.2084 53.4324 31.8747 53.4324Z" fill="#BCBCBC" />
                <path d="M33.3978 50.863H30.3454C30.0083 50.863 29.7378 50.5824 29.7378 50.2406C29.7378 49.8988 30.0118 49.6182 30.3454 49.6182H33.3978C33.735 49.6182 34.0054 49.8988 34.0054 50.2406C34.0054 50.5824 33.7315 50.863 33.3978 50.863Z" fill="#BCBCBC" />
                <path d="M70.2102 30.9787C69.873 30.9787 69.6025 30.6981 69.6025 30.3563V25.215C69.6025 24.8696 69.8765 24.5926 70.2102 24.5926C70.5439 24.5926 70.8179 24.8732 70.8179 25.215V30.3563C70.8179 30.7017 70.5439 30.9787 70.2102 30.9787Z" fill="#BCBCBC" />
                <path d="M71.7337 28.406H68.6814C68.3442 28.406 68.0737 28.1254 68.0737 27.7836C68.0737 27.4418 68.3477 27.1612 68.6814 27.1612H71.7337C72.0709 27.1612 72.3414 27.4418 72.3414 27.7836C72.3414 28.1254 72.0674 28.406 71.7337 28.406Z" fill="#BCBCBC" />
                <path d="M67.1575 48.2902C66.8203 48.2902 66.5498 48.0095 66.5498 47.6677V42.5265C66.5498 42.1811 66.8238 41.9041 67.1575 41.9041C67.4911 41.9041 67.7651 42.1847 67.7651 42.5265V47.6677C67.7651 48.0131 67.4911 48.2902 67.1575 48.2902Z" fill="#BCBCBC" />
                <path d="M68.682 45.7224H65.6296C65.2924 45.7224 65.022 45.4417 65.022 45.1C65.022 44.7582 65.2959 44.4775 65.6296 44.4775H68.682C69.0192 44.4775 69.2896 44.7582 69.2896 45.1C69.2896 45.4417 69.0156 45.7224 68.682 45.7224Z" fill="#BCBCBC" />
            </g>
            <rect x="1.68421" y="0.760874" width="89.7832" height="89.7832" rx="44.8916" stroke="#ACACAC" stroke-width="1.52175" fill="none" />

            <defs>
                <clipPath id="clip0_2568_4813">
                    <rect width="57.8678" height="52.6071" fill="white" transform="translate(14.4741 19.955)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default LeadingChangeDynamicSvg