import React, {ReactNode} from "react";


function useHeaderFooter(children: React.ReactNode): { headerContent: ReactNode, footerContent: ReactNode } {
    let headerContent: ReactNode = null;
    let footerContent: ReactNode = null;

    React.Children.forEach(children, (child, index) => {
        if (index === 0) {
            headerContent = child;
        } else {
            footerContent = child;
        }
    });

    return { headerContent, footerContent };
}

export default useHeaderFooter;
