import {Outlet} from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import {useHome} from "../../hooks/useHome";
import {useContext, useEffect, useRef, useState} from "react";
import {AppDataContext, IAppData} from "@iblai/ibl-web-react-common";
import {isLoggedIn} from "../../hooks/utils/helpers";
import useUserLocation from "../../hooks/useUserLocation";
import UserLocationSettings from "../../components/UserLocationSettings/UserLocationSettings";

const IndexView = () => {
    const { appData, setAppData } = useContext(AppDataContext);
    const { userMetadataData, userMetadataDataLoaded } = useHome();
    const hasMounted = useRef(false);
    const [userHasRegisteredLocation, setHasUserRegisteredLocation] = useState(true)
    const {handleCheckIfUserHasRegisteredLocation} = useUserLocation()

    useEffect(() => {
        if(localStorage.getItem('locationDataRegistered')==="true"){
            return
        }
        handleCheckIfUserHasRegisteredLocation((response)=>{
            if(response.status===404){
                setHasUserRegisteredLocation(false)
            }else if(response.ok){
                localStorage.setItem('locationDataRegistered', "true")
            }
        })
    }, []);

    useEffect(() => {
        if (isLoggedIn()) {
            if (hasMounted.current) {
                setAppData({
                    ...appData,
                    userMetaData: userMetadataData,
                    userMetaDataLoaded: userMetadataDataLoaded,
                } as IAppData);
            } else {
                hasMounted.current = true;
            }
        }
    }, [userMetadataDataLoaded, isLoggedIn]);

    return (
        <>
            <ScrollToTop />
            <UserLocationSettings userHasRegisteredLocation={userHasRegisteredLocation} setHasUserRegisteredLocation={setHasUserRegisteredLocation} />
            <Outlet/>
        </>


    )
}

export default IndexView