import React, {useEffect, useState} from 'react';
import {MdOutlineRadioButtonUnchecked} from "react-icons/md";
import { RiProgress1Line, RiProgress2Line, RiProgress3Line, RiProgress4Line, RiProgress5Line, RiProgress6Line, RiProgress7Line,   } from "react-icons/ri";

interface CourseDataProps {
    courseData: any;
}

const CourseCompletionCheckMark : React.FC<CourseDataProps> = ({courseData}) => {

    const [overallCompletion, setOverallCompletion] = useState<number>(0)
    const [overallCompletionLoaded, setOverallCompletionLoaded] = useState<boolean>(false)
    const MAX_CHECKMARK_POINT = 7

    useEffect(() => {
        handleCheckSubUnitCompletion()
    }, []);

    const handleCheckSubUnitCompletion = () => {
        if(Array.isArray(courseData?.children) && courseData?.children.length > 0) {
            const totalSubUnits = courseData?.children.length
            const totalScore = courseData.children.reduce((accumulator:any, current:any)=>accumulator + (current?.completion ?? 0), 0)
            console.log({
                totalSubUnits,
                totalScore
            })
            setOverallCompletion(Math.round((totalScore * MAX_CHECKMARK_POINT) / totalSubUnits))
            setOverallCompletionLoaded(true)
        }
    }
    return (
        <>
            {
                overallCompletionLoaded && overallCompletion > 0 ?
                    (
                        <>
                            {
                                overallCompletion === 1 && <RiProgress1Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 2 && <RiProgress2Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 3 && <RiProgress3Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 4 && <RiProgress4Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 5 && <RiProgress5Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 6 && <RiProgress6Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }
                            {
                                overallCompletion === 7 && <RiProgress7Line color="#0A3887" size={17}
                                                                            style={{
                                                                                minWidth: "17px",
                                                                            }} />
                            }

                        </>
                    )

                    :
                    <MdOutlineRadioButtonUnchecked color="#0A3887" size={17}
                                                   style={{
                                                       minWidth: "17px",
                                                   }}
                    />
            }
        </>
    )
};

export default CourseCompletionCheckMark;