import { useEffect, useRef, useState } from 'react';
import { getUserCourseCompletions, userCourseCompletion } from "./utils/helpers";



function useCourseCompletion(unitID: string | null = null, courseID: string | null = null) {
    const [userCourseCompletions, setUserCourseCompletions] = useState<{}| userCourseCompletion>({});
    const [userCourseCompletionLoaded, setUserCourseCompletionLoaded] = useState(false);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!hasFetchedData.current) {
            getUserCourseCompletions(userCourseCompletionsCallback)

            hasFetchedData.current = true;
        }
    }, [unitID, courseID]);

    const userCourseCompletionsCallback = (data: {} | userCourseCompletion) => {
        setUserCourseCompletions(data);
        setUserCourseCompletionLoaded(true);
    };

    return {
        userCourseCompletions,
        userCourseCompletionLoaded,
    };
}

export default useCourseCompletion;
