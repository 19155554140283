import React, {useEffect, useState} from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import routes from "./routes";
import {
    api, inIframe, isJSON, AppDataContext,
} from "@iblai/ibl-web-react-common";
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.18.0/cdn/');

const initiateAppContextData = () => {
    return {
        ...localStorage,
        userData: isJSON(localStorage['userData'])
            ? JSON.parse(localStorage['userData'])
            : null,
        ibl_user_public_metadata: isJSON(
            localStorage.getItem('ibl_user_public_metadata') || ""
        )
            ? JSON.parse(localStorage.getItem('ibl_user_public_metadata') || "")
            : null,
        userMetaData: {},
        userMetaDataLoaded: false,
        userInContext: '',
        inIframe: inIframe(),
    };
};


function App() {
    const [appData, setAppData] = useState<any>(initiateAppContextData);

    useEffect(() => {
        api.initiateAxdClientsWithToken(appData.axd_token);
    }, []);

    return (
        <AppDataContext.Provider value={{appData: {...appData}, setAppData}}>
            <>
                <RouterProvider router={routes(appData)}/>
            </>
        </AppDataContext.Provider>
    )
}

export default App;
