import './CourseOverview.css';
import useHeaderFooter from "../../hooks/useHeaderFooter";
import React, {useEffect, useState} from "react";
import {
    addInstructorsGridControl,
    BannerImageMapping,
    enrollUserToCourse,
    isEligibleToEnrollV2
} from "../../hooks/utils/helpers";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import useCoursePreview from "../../hooks/useCoursePreview";
import CourseAccessButton from "../../components/CourseAccessButton/CourseAccessButton";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import ModuleOutline from '../../components/ModuleOutline/ModuleOutline';
import CourseCard from '../../components/CourseCard/CourseCard';
import styled from 'styled-components';
import CarouselCourseCard from "../../components/CarouselCourseCard/CarouselCourseCard";


interface CourseOverviewProps {
    children?: React.ReactNode
}

const StyledCourseContainer = styled.div`
                        &.course-container::before,
                        &.course-container::after {
                            background: transparent;
                        }
                    `;

const CourseOverview: React.FC<CourseOverviewProps> = ({children}) => {
    const {headerContent, footerContent} = useHeaderFooter(children);
    const navigate = useNavigate();
    const LMS_BASE_URL = process.env.REACT_APP_IBL_LMS_URL

    const {courseID} = useParams();
    const {
        courseInfo,
        courseInfoLoaded,
    } = useCoursePreview(courseID);

    const [bannerImageUrl, setBannerImageUrl] = useState("")
    const [formattedCourseName, setFormattedCourseName] = useState<string[]>([])

    const handleCourseNameFormatting = () => {
        const courseName = courseInfo?.display_name
        const _seperator = "5 Day"
        if(String(courseName).includes(_seperator)){
            setFormattedCourseName(String(courseName).split(_seperator))
        }
    }

    useEffect(() => {
        if (courseInfoLoaded){
            handleCourseNameFormatting()
            try {
                addInstructorsGridControl()

            } catch (e) {
                console.log('failed adding instructor slider controls')
            }
        }
    }, [courseID, courseInfo, courseInfoLoaded]);

    const [eligibleToEnroll, setEligibleToEnroll] = useState(false)
    const [courseCompleted, setCourseCompleted] = useState(false)
    const [Loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading ] = useState(false)

    const enrollUser = () => {
        setIsLoading(true)
        enrollUserToCourse(courseID || "", (data)=>{
            setIsLoading(false)
            navigate(`/course-working/${courseID}`)

        })

    }

    useEffect(() => {
        if (courseID === "course-v1:Legatum+100+2023"){
            setEligibleToEnroll(true);
            setLoaded(true)
        }else{
            isEligibleToEnrollV2(courseInfo?.custom?.prerequisite, setLoaded, true).then((eligibleData)=>{
                if (typeof eligibleData !== "boolean") {
                    setEligibleToEnroll(eligibleData.eligible)
                    //setCourseCompleted(eligibleData.completed)
                }
            });
        }
        isEligibleToEnrollV2(courseID, setLoaded, true).then((eligibleData)=>{
            if (typeof eligibleData !== "boolean") {
                //setEligibleToEnroll(eligibleData.eligible)
                setCourseCompleted(eligibleData.completed)
            }
        });
        

    }, [courseID, courseInfo]);
    const getBannerImage = (): string => {
        if (courseID) {
            // @ts-ignore
            return BannerImageMapping[courseID] || "/img/default-image.jpg";
        }

        return "/img/default-image.jpg";
    }


    return (<>
            <input
                type="hidden"
                id="anPageName"
                name="page"
                defaultValue="course-overview"
            />
            <div className="container-center-horizontal">
                <div className="course-overview screen">
                    <div className="homepage">
                        <div className="overlap-group8">
                            <div className="hero-image">
                                <div
                                    className="overlap-group"
                                    style={{
                                        backgroundImage: `url("${LMS_BASE_URL}${courseInfo?.banner_image_asset_path}")`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center"
                                    }}
                                >
                                    <div className="rectangle-20 rectangle"/>
                                </div>
                            </div>
                            <div className="headline">
                                <h1 className="course-01-why-lead instrumentserif-normal-white-100-1px-2" style={{minHeight: "auto"}}>
              <span className="instrumentserif-normal-white-100-1px d-block">
                Module{" "}
              </span>
                                    {
                                        formattedCourseName.length > 0 ? (
                                            <span className="recklessneue-regular-normal-white-100-1px course-name" dangerouslySetInnerHTML={{
                                                __html:`<span>${formattedCourseName[0]} <br>5 Day ${formattedCourseName[1]}</span>`
                                            }}></span>
                                        ) : (
                                            <span className="recklessneue-regular-normal-white-100-1px course-name">
                {courseInfo?.display_name}
              </span>
                                        )
                                    }
                                </h1>
                            </div>
                            {headerContent}
                        </div>
                    </div>
                    <div className="page-nav-custom">
                        <div className="flex-col-1 flex-col-8" style={{margin : "auto"}}>
                            <p className="modules-01-why-lead recklessneue-book-normal-congress-blue-22-8px">
                                Modules &gt;
                                {
                                    formattedCourseName.length > 0 ? (
                                        <span
                                            className="course-name" dangerouslySetInnerHTML={{
                                            __html:`<span>${formattedCourseName[0]} <br>5 Day ${formattedCourseName[1]}</span>`
                                        }}></span>
                                        ) : (
                                        <span
                                            className="course-name">{courseInfo?.display_name}</span>
                                    )
                                }
                            </p>{" "}
                            <img className="line-6" src="/img/line-6-12.svg" alt="Line 6"/>
                        </div>

                        {courseInfoLoaded && (
                            <CourseAccessButton courseInfo={courseInfo} courseID={courseID} Loaded={Loaded}
                                                eligibleToEnroll={eligibleToEnroll} courseCompleted={courseCompleted}/>)}
                    </div>
                    <div className="course-error-message">
                        <div className="error-box">
                            This content will become available once you have completed all
                            activities in the following module: <a className="previous_name"> </a>
                        </div>
                    </div>
                <div className="course-overview-text course-overview-text-custom">
                        <div className="intro-text-01">
                            <div className="x01-why-lead-overview recklessneue-book-normal-congress-blue-48px">
                                <span className="course-name">  {courseInfo?.display_name} </span>
                                <br/>
                                Overview
                            </div>
                            <p className="in-this-module-you neuehaasgroteskdisplaypro-45-light-congress-blue-24px  what-p about-p">
                                <div dangerouslySetInnerHTML={{__html: courseInfo?.overview}}/>

                            </p>
                        </div>
                        <div className="overlap-group-1">
                            <p className="the-4-pillars-of-suc neuehaasgroteskdisplaypro-45-light-congress-blue-24px course-material"></p>
                            <p className="what-you-will-learn-in-this-course recklessneue-book-normal-congress-blue-48px">
                                In this module, you will
                            </p>
                            <p className="identify-your-influe neuehaasgroteskdisplaypro-45-light-congress-blue-24px what-p learning-p"/>
                            <ul className="l-outcome">
                                {courseInfoLoaded && (
                                    courseInfo?.learning_info?.map((outcome: string, index: number) => (
                                        <li key={index}>
                                            {outcome}
                                        </li>
                                    ))
                                )

                                }

                            </ul>
                            <p/>
                        </div>
                    </div>
                    <div className="tutors">
                        <div className="flex-row flex">
                        <div className="overlap-group7 overlap-group7-custom">
                                <p className="meet-your-guidesfor-this-course recklessneue-book-normal-congress-blue-48px">
                                    Meet your guides
                                    <br/>
                                    for this course
                                </p>
                                <div className="tutors-full">
                                <div className="tutors-grid tutors-grid-custom">
                                        {courseInfoLoaded && courseInfo?.instructor_info?.instructors?.map((instructor: any, index: number) => (
                                            <div>
                                                <NavLink to={`/profile-guide/${instructor.title}`}>
                                                    <div className="badge-01 badge">
                                                        <div
                                                            className="frame-30"
                                                            style={{
                                                                backgroundImage: `url("${instructor.title === "tluna" ? `/img/tluna-pic.jpeg` : `${process.env.REACT_APP_IBL_LMS_URL}${instructor.image}` }")`
                                                            }}
                                                        />

                                                        <div
                                                            className="name neuehaasgroteskdisplaypro-45-light-congress-blue-38px">
                                                            {instructor.name}
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        ))}


                                    </div>
                                    <div className="courses-slider-control">
                                        <div className="overlap-group-2">
                                            <img className="line-5" src="/img/line-5-3.svg" alt="Line 5" style={{display: "none"}}/>
                                            <div
                                                className="rectangle-7 rectangle"
                                                style={{width: "25%"}}
                                            />
                                        </div>
                                        <div className="arrow-container">
                                            <img
                                                className="arrow previous_tutor disabled"
                                                src="/img/arrow-2-1.svg"
                                                alt="Arrow 1"
                                            />
                                            <img
                                                className="arrow next_tutor"
                                                src="/img/arrow-2-1.svg"
                                                alt="Arrow 2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="start-module-btn-container">
                        {
                            Loaded && eligibleToEnroll && (
                                isLoading ? (
                                    <IBLSpinLoader size={40} containerHeight="150px"/>

                                ) : (
                                    <NavLink
                                        to="#"
                                        onClick={()=>{enrollUser()}}
                                        className="course-btn"
                                    >
                                        Start The Module
                                    </NavLink>
                                )
                            )
                        }


                    </div>
                    <div className="course-chapters">
                        <div className="overlap-group6">
                            <div className="syl-cont">
                                <p className="x01-why-lead-course-chapters">
              <span className="instrumentserif-normal-congress-blue-48px">
                Module:{" "}
              </span>
                                    <span className="span1 course-name">{courseInfo?.display_name} </span>
                                </p>

                            <ModuleOutline courseInfo={courseInfo} courseInfoLoaded={courseInfoLoaded}/>


                            </div>
                        </div>
                    </div>


                    <div className="qoute-block">
                        <div className="overlap-group5">
                            <div className="overlap-group4">
                                <div className="overlap-group3">
                                    <p className="the-course-has-reall recklessneue-book-normal-congress-blue-48px">
                                        These character themes, courage, love, and hope, are pivotal for the transformation that workplaces and leaders need in current times and into the future.
                                    </p>
                                </div>
                                <div className="test-2">
                                    <img src="/img/SimbeKadarake.jpg"  alt="Simbe Kadarake" style={{borderRadius:"50%", marginBottom:"20px"}} />
                                    <div className="simbe-kadarake instrumentserif-normal-congress-blue-48px">
                                        -Deborah Miremba
                                    </div>
                                </div>
                            </div>
                            <img className="layer_17" src="/img/layer-17.svg" alt="Layer_17"/>
                        </div>
                    </div>
                {courseID !== "course-v1:Legatum+107+2023" && (
                    <div className="overlap-group9">
                        <div className="following-chapters">
                            <div className="next-courses recklessneue-book-normal-congress-blue-48px">
                                Next Modules
                            </div>
                       
                            <div className="courses">
                            <StyledCourseContainer className="course-container">
                                <CarouselCourseCard course_id={courseID} />
                            </StyledCourseContainer>
                                {/*<div className="courses-slider-control">
                                    <div className="overlap-group-2">
                                        <img className="line-5" src="img/line-5.svg" alt="Line 5"/>
                                        <div className="rectangle-7"/>
                                    </div>
                                    <div className="arrow-container">
                                        <img
                                            className="arrow previous_course"
                                            src="/img/arrow-2-1.svg"
                                            alt="Arrow 1"
                                        />
                                        <img
                                            className="arrow next_course"
                                            src="/img/arrow-2-2.svg"
                                            alt="Arrow 2"
                                        />
                                    </div>
                                </div>*/}
                            </div>
                   


                        </div>
                    </div>

                )}
                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion"/>
            </div>
        </>


    );

}
export default CourseOverview;